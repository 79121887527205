import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Typography } from "@mui/material";
import Chip from "@mui/material/Chip";
import { type Address, PostNumber } from "packages/buildings";
import type { CompanyId } from "packages/companies";
import { useFieldValue } from "packages/dataAccess";
import { EnergyClass, unique } from "packages/energy-class";
import type { InstallationDataset } from "packages/installations";
import { FormatCurrencyType, formatCurrency } from "packages/utils";
import { type ReactElement, useMemo } from "react";
import { Link, useParams } from "react-router-dom";

export const Header = ({
  selectedInstallationDataset,
  address,
}: {
  selectedInstallationDataset: InstallationDataset;
  address: Address;
}): ReactElement => {
  const { company: companyId } = useParams<{ company: CompanyId }>();

  const [firstRowOfData] = selectedInstallationDataset;
  const [, priceData] = firstRowOfData;
  const getFieldValue = useFieldValue();

  const energyClasses = useMemo(
    () =>
      selectedInstallationDataset.map(([, data]) =>
        String(getFieldValue(data, "out:Energy Class")),
      ),
    [selectedInstallationDataset, getFieldValue],
  );

  const uniqueEnergyClasses = useMemo(
    () => unique(energyClasses),
    [energyClasses],
  );

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        padding: "30px",
      }}
    >
      <div>
        <Link to={`/${companyId}`}>
          <ArrowBackIcon
            style={{
              fontSize: "40px",
              color: "#5b5b5b",
              marginRight: "15px",
            }}
          />
        </Link>
      </div>

      <div style={{ marginRight: "15px" }}>
        <Typography variant="h5" fontWeight={500}>
          {address}
        </Typography>
        <Typography fontWeight={500} color="GrayText">
          {PostNumber[address]}
        </Typography>
      </div>
      <EnergyClass energyClasses={uniqueEnergyClasses} />
      <div
        style={{
          flex: 1,
          display: "flex",
          gap: 8,
          justifyContent: "center",
        }}
      >
        <Chip
          color="primary"
          label={`Uppvärmning: ${formatCurrency(
            getFieldValue(priceData, "out:Uppvarmning (kr/kWh)"),
            { type: FormatCurrencyType.Decimal, maximumFractionDigits: 1 },
          )}`}
        />
        <Chip
          color="primary"
          label={`El (kr/kWh): ${formatCurrency(
            getFieldValue(priceData, "out:El (kr/kWh)"),
            { type: FormatCurrencyType.Decimal, maximumFractionDigits: 1 },
          )}`}
        />
        <Chip
          color="primary"
          label={` Såld El (kr/kWh): ${formatCurrency(
            getFieldValue(priceData, "out:Sold El (kr/kWh)"),
            { type: FormatCurrencyType.Decimal, maximumFractionDigits: 1 },
          )}`}
        />
      </div>
    </div>
  );
};
