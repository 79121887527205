import { uniqueListOfCompanies } from "packages/companies";
import type { ReactElement } from "react";
import { Link } from "react-router-dom";

export const CompanySelector = (): ReactElement => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        gap: "1rem",
        height: "100vh",
      }}
    >
      {uniqueListOfCompanies.map(([company, companyId]) => {
        return (
          <div key={companyId}>
            <Link to={`/${companyId}`}>{company}</Link>
          </div>
        );
      })}
    </div>
  );
};
