export default [
  {
    "ID": "Z00000000000000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 127.871325,
    "out:DHW (DH)": 41.7,
    "out:Property Electricity": 12.57,
    "out:Electricity (inc PV)": 12.57,
    "out:Total Energy Use Pre PV": 182.57,
    "out:Total Energy Use Post PV": 182.57,
    "out:Primary Energy": 139.581475,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 0,
    "out:CO2 Operational/m2": 62.856621,
    "out:Total CO2/m2": 62.856621,
    "out:Total CO2 (tons)": 200.135421,
    "out:Klimatpaverkan": 0,
    "out:Initial Cost/MSEK": 0,
    "out:Running cost/(Apt SEK y)": 36483.212766,
    "out:Running Cost over RSP/MSEK": 13.40472,
    "out:LCP/MSEK": 0,
    "out:ROI% old": 0,
    "out:Payback discounted": 1,
    "out:Atemp": 3184,
    "out:Umean": 1,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 183,
    "out:Energy use kWh/m2": 183,
    "out:Energy savings %": 0,
    "out:Op energy cost/MSEK": 98,
    "out:El price/MSEK": 59,
    "out:Heating price/MSEK": 33,
    "out:DH+ DHW/kWh/m2": 170,
    "out:El bought/kWh/m2": 879.9,
    "out:Return %": 100,
    "out:Return/kSEK/y": 0,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 41.7,
    "out:EAHP (El)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 0,
    "out:Payback Time": 0,
    "out:DH kWh savings": 0,
    "out:EL kWh savings": 2.2624e-11,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 0,
    "out:DH kr savings": 0,
    "out:El kr savings": 4.524734779920436e-11,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 407142.177573,
    "out:% savings (space heating)": 0,
    "out:Total_Water Heating (DH)": 132772.760051,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 127.87,
    "out:Etvv": 25,
    "out:Ef": 12.57,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 0,
    "out:Seasonal Variation ROI (%)": 0,
    "out:Seasonal Variation Payback": 0,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 61.19728,
    "out:EL CO2": 1.659341,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 1.659341,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00000000000000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 122.33172,
    "out:DHW (DH)": 41.7,
    "out:Property Electricity": 12.57,
    "out:Electricity (inc PV)": 12.57,
    "out:Total Energy Use Pre PV": 176.57,
    "out:Total Energy Use Post PV": 176.57,
    "out:Primary Energy": 135.272893,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 0,
    "out:CO2 Operational/m2": 60.696717,
    "out:Total CO2/m2": 60.696717,
    "out:Total CO2 (tons)": 193.258289,
    "out:Klimatpaverkan": -6.877132,
    "out:Initial Cost/MSEK": 0.079599975,
    "out:Running cost/(Apt SEK y)": 35255.680851,
    "out:Running Cost over RSP/MSEK": 12.95346,
    "out:LCP/MSEK": 0.37166,
    "out:ROI% old": 80.987104,
    "out:Payback discounted": 2,
    "out:Atemp": 3184,
    "out:Umean": 1,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 177,
    "out:Energy use kWh/m2": 177,
    "out:Energy savings %": 3.4,
    "out:Op energy cost/MSEK": 95,
    "out:El price/MSEK": 57,
    "out:Heating price/MSEK": 32,
    "out:DH+ DHW/kWh/m2": 164,
    "out:El bought/kWh/m2": 879.9,
    "out:Return %": 72,
    "out:Return/kSEK/y": 58,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 41.7,
    "out:EAHP (El)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 22,
    "out:Payback Time": 4.545455,
    "out:DH kWh savings": 17638.099242,
    "out:EL kWh savings": 2.2624e-11,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 79599.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 17638.1,
    "out:DH kr savings": 17638.09924185777,
    "out:El kr savings": 4.524734779920436e-11,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 389504.078331,
    "out:% savings (space heating)": 4.332172,
    "out:Total_Water Heating (DH)": 132772.760051,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 122.33,
    "out:Etvv": 25,
    "out:Ef": 12.57,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 79599.975,
    "out:Seasonal Variation ROI (%)": 20,
    "out:Seasonal Variation Payback": 5,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 59.037376,
    "out:EL CO2": 1.659341,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 1.659341,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00000000000001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 127.871325,
    "out:DHW (DH)": 35.445,
    "out:Property Electricity": 12.57,
    "out:Electricity (inc PV)": 12.57,
    "out:Total Energy Use Pre PV": 175.57,
    "out:Total Energy Use Post PV": 175.57,
    "out:Primary Energy": 136.956475,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 0.708543,
    "out:CO2 Operational/m2": 60.336733,
    "out:Total CO2/m2": 61.045276,
    "out:Total CO2 (tons)": 194.3681,
    "out:Klimatpaverkan": -5.767321,
    "out:Initial Cost/MSEK": 0.273216,
    "out:Running cost/(Apt SEK y)": 35051.085106,
    "out:Running Cost over RSP/MSEK": 12.87824,
    "out:LCP/MSEK": 0.253264,
    "out:ROI% old": 27.528193,
    "out:Payback discounted": 5,
    "out:Atemp": 3184,
    "out:Umean": 1,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 176,
    "out:Energy use kWh/m2": 176,
    "out:Energy savings %": 4,
    "out:Op energy cost/MSEK": 94,
    "out:El price/MSEK": 57,
    "out:Heating price/MSEK": 32,
    "out:DH+ DHW/kWh/m2": 163,
    "out:El bought/kWh/m2": 879.9,
    "out:Return %": 25,
    "out:Return/kSEK/y": 67,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 35.445,
    "out:EAHP (El)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 7,
    "out:Payback Time": 14.285714,
    "out:DH kWh savings": 19915.914008,
    "out:EL kWh savings": 2.2624e-11,
    "out:Sewage heat recovery cost": 273216,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 19915.91,
    "out:DH kr savings": 19915.914007709984,
    "out:El kr savings": 4.524734779920436e-11,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 407142.177573,
    "out:% savings (space heating)": 0,
    "out:Total_Water Heating (DH)": 112856.846044,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 127.87,
    "out:Etvv": 21.25,
    "out:Ef": 12.57,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 273216,
    "out:Seasonal Variation ROI (%)": 7,
    "out:Seasonal Variation Payback": 14.285714,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 58.677392,
    "out:EL CO2": 1.659341,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 1.659341,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00000000000001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 122.33172,
    "out:DHW (DH)": 35.445,
    "out:Property Electricity": 12.57,
    "out:Electricity (inc PV)": 12.57,
    "out:Total Energy Use Pre PV": 170.57,
    "out:Total Energy Use Post PV": 170.57,
    "out:Primary Energy": 132.647893,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 0.708543,
    "out:CO2 Operational/m2": 58.536813,
    "out:Total CO2/m2": 59.245356,
    "out:Total CO2 (tons)": 188.637157,
    "out:Klimatpaverkan": -11.498264,
    "out:Initial Cost/MSEK": 0.352815975,
    "out:Running cost/(Apt SEK y)": 34028.148936,
    "out:Running Cost over RSP/MSEK": 12.50219,
    "out:LCP/MSEK": 0.549714,
    "out:ROI% old": 36.54394,
    "out:Payback discounted": 4,
    "out:Atemp": 3184,
    "out:Umean": 1,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 171,
    "out:Energy use kWh/m2": 171,
    "out:Energy savings %": 7,
    "out:Op energy cost/MSEK": 91,
    "out:El price/MSEK": 56,
    "out:Heating price/MSEK": 31,
    "out:DH+ DHW/kWh/m2": 158,
    "out:El bought/kWh/m2": 879.9,
    "out:Return %": 33,
    "out:Return/kSEK/y": 115,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 35.445,
    "out:EAHP (El)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 11,
    "out:Payback Time": 9.090909,
    "out:DH kWh savings": 37554.01325,
    "out:EL kWh savings": 2.2624e-11,
    "out:Sewage heat recovery cost": 273216,
    "out:Balancing hydronic system cost": 79599.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 37554.01,
    "out:DH kr savings": 37554.01324956775,
    "out:El kr savings": 4.524734779920436e-11,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 389504.078331,
    "out:% savings (space heating)": 4.332172,
    "out:Total_Water Heating (DH)": 112856.846044,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 122.33,
    "out:Etvv": 21.25,
    "out:Ef": 12.57,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 352815.975,
    "out:Seasonal Variation ROI (%)": 10,
    "out:Seasonal Variation Payback": 10,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 56.877472,
    "out:EL CO2": 1.659341,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 1.659341,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00000000000010000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 1,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 127.871325,
    "out:DHW (DH)": 41.7,
    "out:Property Electricity": 12.57,
    "out:Electricity (inc PV)": 12.57,
    "out:Total Energy Use Pre PV": 182.57,
    "out:Total Energy Use Post PV": 182.57,
    "out:Primary Energy": 139.581475,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 0,
    "out:CO2 Operational/m2": 62.856621,
    "out:Total CO2/m2": 62.856621,
    "out:Total CO2 (tons)": 200.135421,
    "out:Klimatpaverkan": 0,
    "out:Initial Cost/MSEK": 0,
    "out:Running cost/(Apt SEK y)": 36483.212766,
    "out:Running Cost over RSP/MSEK": 13.40472,
    "out:LCP/MSEK": 0,
    "out:ROI% old": 0,
    "out:Payback discounted": 1,
    "out:Atemp": 3184,
    "out:Umean": 1,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 183,
    "out:Energy use kWh/m2": 183,
    "out:Energy savings %": 0,
    "out:Op energy cost/MSEK": 98,
    "out:El price/MSEK": 59,
    "out:Heating price/MSEK": 33,
    "out:DH+ DHW/kWh/m2": 170,
    "out:El bought/kWh/m2": 879.9,
    "out:Return %": 100,
    "out:Return/kSEK/y": 0,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 41.7,
    "out:EAHP (El)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 0,
    "out:Payback Time": 0,
    "out:DH kWh savings": 0,
    "out:EL kWh savings": 2.2624e-11,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 0,
    "out:DH kr savings": 0,
    "out:El kr savings": 4.524734779920436e-11,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 407142.177573,
    "out:% savings (space heating)": 0,
    "out:Total_Water Heating (DH)": 132772.760051,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 127.87,
    "out:Etvv": 25,
    "out:Ef": 12.57,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 0,
    "out:Seasonal Variation ROI (%)": 0,
    "out:Seasonal Variation Payback": 0,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 61.19728,
    "out:EL CO2": 1.659341,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 1.659341,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00000000000010100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 1,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 122.33172,
    "out:DHW (DH)": 41.7,
    "out:Property Electricity": 12.57,
    "out:Electricity (inc PV)": 12.57,
    "out:Total Energy Use Pre PV": 176.57,
    "out:Total Energy Use Post PV": 176.57,
    "out:Primary Energy": 135.272893,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 0,
    "out:CO2 Operational/m2": 60.696717,
    "out:Total CO2/m2": 60.696717,
    "out:Total CO2 (tons)": 193.258289,
    "out:Klimatpaverkan": -6.877132,
    "out:Initial Cost/MSEK": 0.079599975,
    "out:Running cost/(Apt SEK y)": 35255.680851,
    "out:Running Cost over RSP/MSEK": 12.95346,
    "out:LCP/MSEK": 0.37166,
    "out:ROI% old": 80.987104,
    "out:Payback discounted": 2,
    "out:Atemp": 3184,
    "out:Umean": 1,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 177,
    "out:Energy use kWh/m2": 177,
    "out:Energy savings %": 3.4,
    "out:Op energy cost/MSEK": 95,
    "out:El price/MSEK": 57,
    "out:Heating price/MSEK": 32,
    "out:DH+ DHW/kWh/m2": 164,
    "out:El bought/kWh/m2": 879.9,
    "out:Return %": 72,
    "out:Return/kSEK/y": 58,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 41.7,
    "out:EAHP (El)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 22,
    "out:Payback Time": 4.545455,
    "out:DH kWh savings": 17638.099242,
    "out:EL kWh savings": 2.2624e-11,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 79599.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 17638.1,
    "out:DH kr savings": 17638.09924185777,
    "out:El kr savings": 4.524734779920436e-11,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 389504.078331,
    "out:% savings (space heating)": 4.332172,
    "out:Total_Water Heating (DH)": 132772.760051,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 122.33,
    "out:Etvv": 25,
    "out:Ef": 12.57,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 79599.975,
    "out:Seasonal Variation ROI (%)": 20,
    "out:Seasonal Variation Payback": 5,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 59.037376,
    "out:EL CO2": 1.659341,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 1.659341,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00000000000011000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 1,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 127.871325,
    "out:DHW (DH)": 35.445,
    "out:Property Electricity": 12.57,
    "out:Electricity (inc PV)": 12.57,
    "out:Total Energy Use Pre PV": 175.57,
    "out:Total Energy Use Post PV": 175.57,
    "out:Primary Energy": 136.956475,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 0.708543,
    "out:CO2 Operational/m2": 60.336733,
    "out:Total CO2/m2": 61.045276,
    "out:Total CO2 (tons)": 194.3681,
    "out:Klimatpaverkan": -5.767321,
    "out:Initial Cost/MSEK": 0.273216,
    "out:Running cost/(Apt SEK y)": 35051.085106,
    "out:Running Cost over RSP/MSEK": 12.87824,
    "out:LCP/MSEK": 0.253264,
    "out:ROI% old": 27.528193,
    "out:Payback discounted": 5,
    "out:Atemp": 3184,
    "out:Umean": 1,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 176,
    "out:Energy use kWh/m2": 176,
    "out:Energy savings %": 4,
    "out:Op energy cost/MSEK": 94,
    "out:El price/MSEK": 57,
    "out:Heating price/MSEK": 32,
    "out:DH+ DHW/kWh/m2": 163,
    "out:El bought/kWh/m2": 879.9,
    "out:Return %": 25,
    "out:Return/kSEK/y": 67,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 35.445,
    "out:EAHP (El)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 7,
    "out:Payback Time": 14.285714,
    "out:DH kWh savings": 19915.914008,
    "out:EL kWh savings": 2.2624e-11,
    "out:Sewage heat recovery cost": 273216,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 19915.91,
    "out:DH kr savings": 19915.914007709984,
    "out:El kr savings": 4.524734779920436e-11,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 407142.177573,
    "out:% savings (space heating)": 0,
    "out:Total_Water Heating (DH)": 112856.846044,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 127.87,
    "out:Etvv": 21.25,
    "out:Ef": 12.57,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 273216,
    "out:Seasonal Variation ROI (%)": 7,
    "out:Seasonal Variation Payback": 14.285714,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 58.677392,
    "out:EL CO2": 1.659341,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 1.659341,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00000000000011100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 1,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 122.33172,
    "out:DHW (DH)": 35.445,
    "out:Property Electricity": 12.57,
    "out:Electricity (inc PV)": 12.57,
    "out:Total Energy Use Pre PV": 170.57,
    "out:Total Energy Use Post PV": 170.57,
    "out:Primary Energy": 132.647893,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 0.708543,
    "out:CO2 Operational/m2": 58.536813,
    "out:Total CO2/m2": 59.245356,
    "out:Total CO2 (tons)": 188.637157,
    "out:Klimatpaverkan": -11.498264,
    "out:Initial Cost/MSEK": 0.352815975,
    "out:Running cost/(Apt SEK y)": 34028.148936,
    "out:Running Cost over RSP/MSEK": 12.50219,
    "out:LCP/MSEK": 0.549714,
    "out:ROI% old": 36.54394,
    "out:Payback discounted": 4,
    "out:Atemp": 3184,
    "out:Umean": 1,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 171,
    "out:Energy use kWh/m2": 171,
    "out:Energy savings %": 7,
    "out:Op energy cost/MSEK": 91,
    "out:El price/MSEK": 56,
    "out:Heating price/MSEK": 31,
    "out:DH+ DHW/kWh/m2": 158,
    "out:El bought/kWh/m2": 879.9,
    "out:Return %": 33,
    "out:Return/kSEK/y": 115,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 35.445,
    "out:EAHP (El)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 11,
    "out:Payback Time": 9.090909,
    "out:DH kWh savings": 37554.01325,
    "out:EL kWh savings": 2.2624e-11,
    "out:Sewage heat recovery cost": 273216,
    "out:Balancing hydronic system cost": 79599.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 37554.01,
    "out:DH kr savings": 37554.01324956775,
    "out:El kr savings": 4.524734779920436e-11,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 389504.078331,
    "out:% savings (space heating)": 4.332172,
    "out:Total_Water Heating (DH)": 112856.846044,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 122.33,
    "out:Etvv": 21.25,
    "out:Ef": 12.57,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 352815.975,
    "out:Seasonal Variation ROI (%)": 10,
    "out:Seasonal Variation Payback": 10,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 56.877472,
    "out:EL CO2": 1.659341,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 1.659341,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00000000000100000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 1,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 127.871325,
    "out:DHW (DH)": 41.7,
    "out:Property Electricity": 9.976115,
    "out:Electricity (inc PV)": 9.976115,
    "out:Total Energy Use Pre PV": 179.976115,
    "out:Total Energy Use Post PV": 179.976115,
    "out:Primary Energy": 134.912482,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 0.184502,
    "out:CO2 Operational/m2": 62.514207,
    "out:Total CO2/m2": 62.698709,
    "out:Total CO2 (tons)": 199.632629,
    "out:Klimatpaverkan": -0.502792,
    "out:Initial Cost/MSEK": 0.14679287151982498,
    "out:Running cost/(Apt SEK y)": 36131.765957,
    "out:Running Cost over RSP/MSEK": 13.27702,
    "out:LCP/MSEK": -0.019093,
    "out:ROI% old": 12.427618,
    "out:Payback discounted": 10,
    "out:Atemp": 3184,
    "out:Umean": 1,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 180,
    "out:Energy use kWh/m2": 180,
    "out:Energy savings %": 1.7,
    "out:Op energy cost/MSEK": 97,
    "out:El price/MSEK": 59,
    "out:Heating price/MSEK": 33,
    "out:DH+ DHW/kWh/m2": 170,
    "out:El bought/kWh/m2": 698.32805,
    "out:Return %": 11,
    "out:Return/kSEK/y": 17,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 41.7,
    "out:EAHP (El)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 11,
    "out:Payback Time": 9.090909,
    "out:DH kWh savings": 0,
    "out:EL kWh savings": 8258.927355,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 16517.85,
    "out:DH kr savings": 0,
    "out:El kr savings": 16517.854710116386,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 407142.177573,
    "out:% savings (space heating)": 0,
    "out:Total_Water Heating (DH)": 132772.760051,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 127.87,
    "out:Etvv": 25,
    "out:Ef": 9.98,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 146792.87151982498,
    "out:Seasonal Variation ROI (%)": 11,
    "out:Seasonal Variation Payback": 9.090909,
    "out:New AHU Cost/SEK": 146792.87151982498,
    "out:DH CO2": 61.19728,
    "out:EL CO2": 1.316927,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 1.316927,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00000000000000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 127.871325,
    "out:DHW (DH)": 41.7,
    "out:Property Electricity": 9.976115,
    "out:Electricity (inc PV)": 9.976115,
    "out:Total Energy Use Pre PV": 179.976115,
    "out:Total Energy Use Post PV": 179.976115,
    "out:Primary Energy": 134.912482,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 0,
    "out:CO2 Operational/m2": 62.514207,
    "out:Total CO2/m2": 62.514207,
    "out:Total CO2 (tons)": 199.045175,
    "out:Klimatpaverkan": -1.090246,
    "out:Initial Cost/MSEK": 0.14679287151982498,
    "out:Running cost/(Apt SEK y)": 36131.765957,
    "out:Running Cost over RSP/MSEK": 13.27702,
    "out:LCP/MSEK": -0.019093,
    "out:ROI% old": 12.427618,
    "out:Payback discounted": 10,
    "out:Atemp": 3184,
    "out:Umean": 1,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 180,
    "out:Energy use kWh/m2": 180,
    "out:Energy savings %": 1.7,
    "out:Op energy cost/MSEK": 97,
    "out:El price/MSEK": 59,
    "out:Heating price/MSEK": 33,
    "out:DH+ DHW/kWh/m2": 170,
    "out:El bought/kWh/m2": 698.32805,
    "out:Return %": 11,
    "out:Return/kSEK/y": 17,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 41.7,
    "out:EAHP (El)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 11,
    "out:Payback Time": 9.090909,
    "out:DH kWh savings": 0,
    "out:EL kWh savings": 8258.927355,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 16517.85,
    "out:DH kr savings": 0,
    "out:El kr savings": 16517.854710116386,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 407142.177573,
    "out:% savings (space heating)": 0,
    "out:Total_Water Heating (DH)": 132772.760051,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 127.87,
    "out:Etvv": 25,
    "out:Ef": 9.98,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 146792.87151982498,
    "out:Seasonal Variation ROI (%)": 11,
    "out:Seasonal Variation Payback": 9.090909,
    "out:New AHU Cost/SEK": 146792.87151982498,
    "out:DH CO2": 61.19728,
    "out:EL CO2": 1.316927,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 1.316927,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00000000000000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 127.871325,
    "out:DHW (DH)": 41.7,
    "out:Property Electricity": 12.57,
    "out:Electricity (inc PV)": 12.57,
    "out:Total Energy Use Pre PV": 182.57,
    "out:Total Energy Use Post PV": 182.57,
    "out:Primary Energy": 139.581475,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 0,
    "out:CO2 Operational/m2": 62.856621,
    "out:Total CO2/m2": 62.856621,
    "out:Total CO2 (tons)": 200.135421,
    "out:Klimatpaverkan": 0,
    "out:Initial Cost/MSEK": 0,
    "out:Running cost/(Apt SEK y)": 36483.212766,
    "out:Running Cost over RSP/MSEK": 13.40472,
    "out:LCP/MSEK": 0,
    "out:ROI% old": 0,
    "out:Payback discounted": 1,
    "out:Atemp": 3184,
    "out:Umean": 1,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 183,
    "out:Energy use kWh/m2": 183,
    "out:Energy savings %": 0,
    "out:Op energy cost/MSEK": 98,
    "out:El price/MSEK": 59,
    "out:Heating price/MSEK": 33,
    "out:DH+ DHW/kWh/m2": 170,
    "out:El bought/kWh/m2": 879.9,
    "out:Return %": 100,
    "out:Return/kSEK/y": 0,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 41.7,
    "out:EAHP (El)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 0,
    "out:Payback Time": 0,
    "out:DH kWh savings": 0,
    "out:EL kWh savings": 2.2624e-11,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 0,
    "out:DH kr savings": 0,
    "out:El kr savings": 4.524734779920436e-11,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 407142.177573,
    "out:% savings (space heating)": 0,
    "out:Total_Water Heating (DH)": 132772.760051,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 127.87,
    "out:Etvv": 25,
    "out:Ef": 12.57,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 0,
    "out:Seasonal Variation ROI (%)": 0,
    "out:Seasonal Variation Payback": 0,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 61.19728,
    "out:EL CO2": 1.659341,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 1.659341,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00000000000000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 127.871325,
    "out:DHW (DH)": 41.7,
    "out:Property Electricity": 12.57,
    "out:Electricity (inc PV)": 12.57,
    "out:Total Energy Use Pre PV": 182.57,
    "out:Total Energy Use Post PV": 182.57,
    "out:Primary Energy": 139.581475,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 0,
    "out:CO2 Operational/m2": 62.856621,
    "out:Total CO2/m2": 62.856621,
    "out:Total CO2 (tons)": 200.135421,
    "out:Klimatpaverkan": 0,
    "out:Initial Cost/MSEK": 0,
    "out:Running cost/(Apt SEK y)": 36483.212766,
    "out:Running Cost over RSP/MSEK": 13.40472,
    "out:LCP/MSEK": 0,
    "out:ROI% old": 0,
    "out:Payback discounted": 1,
    "out:Atemp": 3184,
    "out:Umean": 1,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 183,
    "out:Energy use kWh/m2": 183,
    "out:Energy savings %": 0,
    "out:Op energy cost/MSEK": 98,
    "out:El price/MSEK": 59,
    "out:Heating price/MSEK": 33,
    "out:DH+ DHW/kWh/m2": 170,
    "out:El bought/kWh/m2": 879.9,
    "out:Return %": 100,
    "out:Return/kSEK/y": 0,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 41.7,
    "out:EAHP (El)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 0,
    "out:Payback Time": 0,
    "out:DH kWh savings": 0,
    "out:EL kWh savings": 2.2624e-11,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 0,
    "out:DH kr savings": 0,
    "out:El kr savings": 4.524734779920436e-11,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 407142.177573,
    "out:% savings (space heating)": 0,
    "out:Total_Water Heating (DH)": 132772.760051,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 127.87,
    "out:Etvv": 25,
    "out:Ef": 12.57,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 0,
    "out:Seasonal Variation ROI (%)": 0,
    "out:Seasonal Variation Payback": 0,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 61.19728,
    "out:EL CO2": 1.659341,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 1.659341,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00000000000000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 127.871325,
    "out:DHW (DH)": 41.7,
    "out:Property Electricity": 12.57,
    "out:Electricity (inc PV)": 12.57,
    "out:Total Energy Use Pre PV": 182.57,
    "out:Total Energy Use Post PV": 182.57,
    "out:Primary Energy": 139.581475,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 0,
    "out:CO2 Operational/m2": 62.856621,
    "out:Total CO2/m2": 62.856621,
    "out:Total CO2 (tons)": 200.135421,
    "out:Klimatpaverkan": 0,
    "out:Initial Cost/MSEK": 0,
    "out:Running cost/(Apt SEK y)": 36483.212766,
    "out:Running Cost over RSP/MSEK": 13.40472,
    "out:LCP/MSEK": 0,
    "out:ROI% old": 0,
    "out:Payback discounted": 1,
    "out:Atemp": 3184,
    "out:Umean": 1,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 183,
    "out:Energy use kWh/m2": 183,
    "out:Energy savings %": 0,
    "out:Op energy cost/MSEK": 98,
    "out:El price/MSEK": 59,
    "out:Heating price/MSEK": 33,
    "out:DH+ DHW/kWh/m2": 170,
    "out:El bought/kWh/m2": 879.9,
    "out:Return %": 100,
    "out:Return/kSEK/y": 0,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 41.7,
    "out:EAHP (El)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 0,
    "out:Payback Time": 0,
    "out:DH kWh savings": 0,
    "out:EL kWh savings": 2.2624e-11,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 0,
    "out:DH kr savings": 0,
    "out:El kr savings": 4.524734779920436e-11,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 407142.177573,
    "out:% savings (space heating)": 0,
    "out:Total_Water Heating (DH)": 132772.760051,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 127.87,
    "out:Etvv": 25,
    "out:Ef": 12.57,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 0,
    "out:Seasonal Variation ROI (%)": 0,
    "out:Seasonal Variation Payback": 0,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 61.19728,
    "out:EL CO2": 1.659341,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 1.659341,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00000000000000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 122.33172,
    "out:DHW (DH)": 41.7,
    "out:Property Electricity": 12.57,
    "out:Electricity (inc PV)": 12.57,
    "out:Total Energy Use Pre PV": 176.57,
    "out:Total Energy Use Post PV": 176.57,
    "out:Primary Energy": 135.272893,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 0,
    "out:CO2 Operational/m2": 60.696717,
    "out:Total CO2/m2": 60.696717,
    "out:Total CO2 (tons)": 193.258289,
    "out:Klimatpaverkan": -6.877132,
    "out:Initial Cost/MSEK": 0.079599975,
    "out:Running cost/(Apt SEK y)": 35255.680851,
    "out:Running Cost over RSP/MSEK": 12.95346,
    "out:LCP/MSEK": 0.37166,
    "out:ROI% old": 80.987104,
    "out:Payback discounted": 2,
    "out:Atemp": 3184,
    "out:Umean": 1,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 177,
    "out:Energy use kWh/m2": 177,
    "out:Energy savings %": 3.4,
    "out:Op energy cost/MSEK": 95,
    "out:El price/MSEK": 57,
    "out:Heating price/MSEK": 32,
    "out:DH+ DHW/kWh/m2": 164,
    "out:El bought/kWh/m2": 879.9,
    "out:Return %": 72,
    "out:Return/kSEK/y": 58,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 41.7,
    "out:EAHP (El)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 22,
    "out:Payback Time": 4.545455,
    "out:DH kWh savings": 17638.099242,
    "out:EL kWh savings": 2.2624e-11,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 79599.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 17638.1,
    "out:DH kr savings": 17638.09924185777,
    "out:El kr savings": 4.524734779920436e-11,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 389504.078331,
    "out:% savings (space heating)": 4.332172,
    "out:Total_Water Heating (DH)": 132772.760051,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 122.33,
    "out:Etvv": 25,
    "out:Ef": 12.57,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 79599.975,
    "out:Seasonal Variation ROI (%)": 20,
    "out:Seasonal Variation Payback": 5,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 59.037376,
    "out:EL CO2": 1.659341,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 1.659341,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00000000000001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 127.871325,
    "out:DHW (DH)": 35.445,
    "out:Property Electricity": 12.57,
    "out:Electricity (inc PV)": 12.57,
    "out:Total Energy Use Pre PV": 175.57,
    "out:Total Energy Use Post PV": 175.57,
    "out:Primary Energy": 136.956475,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 0.708543,
    "out:CO2 Operational/m2": 60.336733,
    "out:Total CO2/m2": 61.045276,
    "out:Total CO2 (tons)": 194.3681,
    "out:Klimatpaverkan": -5.767321,
    "out:Initial Cost/MSEK": 0.273216,
    "out:Running cost/(Apt SEK y)": 35051.085106,
    "out:Running Cost over RSP/MSEK": 12.87824,
    "out:LCP/MSEK": 0.253264,
    "out:ROI% old": 27.528193,
    "out:Payback discounted": 5,
    "out:Atemp": 3184,
    "out:Umean": 1,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 176,
    "out:Energy use kWh/m2": 176,
    "out:Energy savings %": 4,
    "out:Op energy cost/MSEK": 94,
    "out:El price/MSEK": 57,
    "out:Heating price/MSEK": 32,
    "out:DH+ DHW/kWh/m2": 163,
    "out:El bought/kWh/m2": 879.9,
    "out:Return %": 25,
    "out:Return/kSEK/y": 67,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 35.445,
    "out:EAHP (El)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 7,
    "out:Payback Time": 14.285714,
    "out:DH kWh savings": 19915.914008,
    "out:EL kWh savings": 2.2624e-11,
    "out:Sewage heat recovery cost": 273216,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 19915.91,
    "out:DH kr savings": 19915.914007709984,
    "out:El kr savings": 4.524734779920436e-11,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 407142.177573,
    "out:% savings (space heating)": 0,
    "out:Total_Water Heating (DH)": 112856.846044,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 127.87,
    "out:Etvv": 21.25,
    "out:Ef": 12.57,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 273216,
    "out:Seasonal Variation ROI (%)": 7,
    "out:Seasonal Variation Payback": 14.285714,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 58.677392,
    "out:EL CO2": 1.659341,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 1.659341,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00000000000001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 122.33172,
    "out:DHW (DH)": 35.445,
    "out:Property Electricity": 12.57,
    "out:Electricity (inc PV)": 12.57,
    "out:Total Energy Use Pre PV": 170.57,
    "out:Total Energy Use Post PV": 170.57,
    "out:Primary Energy": 132.647893,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 0.708543,
    "out:CO2 Operational/m2": 58.536813,
    "out:Total CO2/m2": 59.245356,
    "out:Total CO2 (tons)": 188.637157,
    "out:Klimatpaverkan": -11.498264,
    "out:Initial Cost/MSEK": 0.352815975,
    "out:Running cost/(Apt SEK y)": 34028.148936,
    "out:Running Cost over RSP/MSEK": 12.50219,
    "out:LCP/MSEK": 0.549714,
    "out:ROI% old": 36.54394,
    "out:Payback discounted": 4,
    "out:Atemp": 3184,
    "out:Umean": 1,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 171,
    "out:Energy use kWh/m2": 171,
    "out:Energy savings %": 7,
    "out:Op energy cost/MSEK": 91,
    "out:El price/MSEK": 56,
    "out:Heating price/MSEK": 31,
    "out:DH+ DHW/kWh/m2": 158,
    "out:El bought/kWh/m2": 879.9,
    "out:Return %": 33,
    "out:Return/kSEK/y": 115,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 35.445,
    "out:EAHP (El)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 11,
    "out:Payback Time": 9.090909,
    "out:DH kWh savings": 37554.01325,
    "out:EL kWh savings": 2.2624e-11,
    "out:Sewage heat recovery cost": 273216,
    "out:Balancing hydronic system cost": 79599.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 37554.01,
    "out:DH kr savings": 37554.01324956775,
    "out:El kr savings": 4.524734779920436e-11,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 389504.078331,
    "out:% savings (space heating)": 4.332172,
    "out:Total_Water Heating (DH)": 112856.846044,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 122.33,
    "out:Etvv": 21.25,
    "out:Ef": 12.57,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 352815.975,
    "out:Seasonal Variation ROI (%)": 10,
    "out:Seasonal Variation Payback": 10,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 56.877472,
    "out:EL CO2": 1.659341,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 1.659341,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00000000000100000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 1,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 127.871325,
    "out:DHW (DH)": 41.7,
    "out:Property Electricity": 9.976115,
    "out:Electricity (inc PV)": 9.976115,
    "out:Total Energy Use Pre PV": 179.976115,
    "out:Total Energy Use Post PV": 179.976115,
    "out:Primary Energy": 134.912482,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 0.184502,
    "out:CO2 Operational/m2": 62.514207,
    "out:Total CO2/m2": 62.698709,
    "out:Total CO2 (tons)": 199.632629,
    "out:Klimatpaverkan": -0.502792,
    "out:Initial Cost/MSEK": 0.14679287151982498,
    "out:Running cost/(Apt SEK y)": 36131.765957,
    "out:Running Cost over RSP/MSEK": 13.27702,
    "out:LCP/MSEK": -0.019093,
    "out:ROI% old": 12.427618,
    "out:Payback discounted": 10,
    "out:Atemp": 3184,
    "out:Umean": 1,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 180,
    "out:Energy use kWh/m2": 180,
    "out:Energy savings %": 1.7,
    "out:Op energy cost/MSEK": 97,
    "out:El price/MSEK": 59,
    "out:Heating price/MSEK": 33,
    "out:DH+ DHW/kWh/m2": 170,
    "out:El bought/kWh/m2": 698.32805,
    "out:Return %": 11,
    "out:Return/kSEK/y": 17,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 41.7,
    "out:EAHP (El)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 11,
    "out:Payback Time": 9.090909,
    "out:DH kWh savings": 0,
    "out:EL kWh savings": 8258.927355,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 16517.85,
    "out:DH kr savings": 0,
    "out:El kr savings": 16517.854710116386,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 407142.177573,
    "out:% savings (space heating)": 0,
    "out:Total_Water Heating (DH)": 132772.760051,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 127.87,
    "out:Etvv": 25,
    "out:Ef": 9.98,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 146792.87151982498,
    "out:Seasonal Variation ROI (%)": 11,
    "out:Seasonal Variation Payback": 9.090909,
    "out:New AHU Cost/SEK": 146792.87151982498,
    "out:DH CO2": 61.19728,
    "out:EL CO2": 1.316927,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 1.316927,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00000000000100100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 1,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 122.33172,
    "out:DHW (DH)": 41.7,
    "out:Property Electricity": 9.976115,
    "out:Electricity (inc PV)": 9.976115,
    "out:Total Energy Use Pre PV": 173.976115,
    "out:Total Energy Use Post PV": 173.976115,
    "out:Primary Energy": 130.6039,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 0.184502,
    "out:CO2 Operational/m2": 60.354303,
    "out:Total CO2/m2": 60.538805,
    "out:Total CO2 (tons)": 192.755497,
    "out:Klimatpaverkan": -7.379924,
    "out:Initial Cost/MSEK": 0.226392846519825,
    "out:Running cost/(Apt SEK y)": 34904.234043,
    "out:Running Cost over RSP/MSEK": 12.82576,
    "out:LCP/MSEK": 0.352567,
    "out:ROI% old": 36.533209,
    "out:Payback discounted": 4,
    "out:Atemp": 3184,
    "out:Umean": 1,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 174,
    "out:Energy use kWh/m2": 174,
    "out:Energy savings %": 5.2,
    "out:Op energy cost/MSEK": 94,
    "out:El price/MSEK": 57,
    "out:Heating price/MSEK": 32,
    "out:DH+ DHW/kWh/m2": 164,
    "out:El bought/kWh/m2": 698.32805,
    "out:Return %": 33,
    "out:Return/kSEK/y": 74,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 41.7,
    "out:EAHP (El)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 15,
    "out:Payback Time": 6.666667,
    "out:DH kWh savings": 17638.099242,
    "out:EL kWh savings": 8258.927355,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 79599.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 34155.95,
    "out:DH kr savings": 17638.09924185777,
    "out:El kr savings": 16517.854710116386,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 389504.078331,
    "out:% savings (space heating)": 4.332172,
    "out:Total_Water Heating (DH)": 132772.760051,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 122.33,
    "out:Etvv": 25,
    "out:Ef": 9.98,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 226392.846519825,
    "out:Seasonal Variation ROI (%)": 14,
    "out:Seasonal Variation Payback": 7.142857,
    "out:New AHU Cost/SEK": 146792.87151982498,
    "out:DH CO2": 59.037376,
    "out:EL CO2": 1.316927,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 1.316927,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00000000000101000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 1,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 127.871325,
    "out:DHW (DH)": 35.445,
    "out:Property Electricity": 9.976115,
    "out:Electricity (inc PV)": 9.976115,
    "out:Total Energy Use Pre PV": 172.976115,
    "out:Total Energy Use Post PV": 172.976115,
    "out:Primary Energy": 132.287482,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 0.893045,
    "out:CO2 Operational/m2": 59.994319,
    "out:Total CO2/m2": 60.887364,
    "out:Total CO2 (tons)": 193.865309,
    "out:Klimatpaverkan": -6.270112,
    "out:Initial Cost/MSEK": 0.42000887151982497,
    "out:Running cost/(Apt SEK y)": 34699.638298,
    "out:Running Cost over RSP/MSEK": 12.75055,
    "out:LCP/MSEK": 0.234161,
    "out:ROI% old": 22.25021,
    "out:Payback discounted": 6,
    "out:Atemp": 3184,
    "out:Umean": 1,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 173,
    "out:Energy use kWh/m2": 173,
    "out:Energy savings %": 5.8,
    "out:Op energy cost/MSEK": 93,
    "out:El price/MSEK": 56,
    "out:Heating price/MSEK": 32,
    "out:DH+ DHW/kWh/m2": 163,
    "out:El bought/kWh/m2": 698.32805,
    "out:Return %": 20,
    "out:Return/kSEK/y": 84,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 35.445,
    "out:EAHP (El)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 9,
    "out:Payback Time": 11.111111,
    "out:DH kWh savings": 19915.914008,
    "out:EL kWh savings": 8258.927355,
    "out:Sewage heat recovery cost": 273216,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 36433.77,
    "out:DH kr savings": 19915.914007709984,
    "out:El kr savings": 16517.854710116386,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 407142.177573,
    "out:% savings (space heating)": 0,
    "out:Total_Water Heating (DH)": 112856.846044,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 127.87,
    "out:Etvv": 21.25,
    "out:Ef": 9.98,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 420008.87151982496,
    "out:Seasonal Variation ROI (%)": 8,
    "out:Seasonal Variation Payback": 12.5,
    "out:New AHU Cost/SEK": 146792.87151982498,
    "out:DH CO2": 58.677392,
    "out:EL CO2": 1.316927,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 1.316927,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00000000000101100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 1,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 122.33172,
    "out:DHW (DH)": 35.445,
    "out:Property Electricity": 9.976115,
    "out:Electricity (inc PV)": 9.976115,
    "out:Total Energy Use Pre PV": 167.976115,
    "out:Total Energy Use Post PV": 167.976115,
    "out:Primary Energy": 127.9789,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 0.893045,
    "out:CO2 Operational/m2": 58.194399,
    "out:Total CO2/m2": 59.087444,
    "out:Total CO2 (tons)": 188.134365,
    "out:Klimatpaverkan": -12.001056,
    "out:Initial Cost/MSEK": 0.49960884651982496,
    "out:Running cost/(Apt SEK y)": 33676.702128,
    "out:Running Cost over RSP/MSEK": 12.37449,
    "out:LCP/MSEK": 0.530621,
    "out:ROI% old": 29.458188,
    "out:Payback discounted": 4,
    "out:Atemp": 3184,
    "out:Umean": 1,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 168,
    "out:Energy use kWh/m2": 168,
    "out:Energy savings %": 8.9,
    "out:Op energy cost/MSEK": 91,
    "out:El price/MSEK": 55,
    "out:Heating price/MSEK": 31,
    "out:DH+ DHW/kWh/m2": 158,
    "out:El bought/kWh/m2": 698.32805,
    "out:Return %": 26,
    "out:Return/kSEK/y": 132,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 35.445,
    "out:EAHP (El)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 11,
    "out:Payback Time": 9.090909,
    "out:DH kWh savings": 37554.01325,
    "out:EL kWh savings": 8258.927355,
    "out:Sewage heat recovery cost": 273216,
    "out:Balancing hydronic system cost": 79599.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 54071.87,
    "out:DH kr savings": 37554.01324956775,
    "out:El kr savings": 16517.854710116386,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 389504.078331,
    "out:% savings (space heating)": 4.332172,
    "out:Total_Water Heating (DH)": 112856.846044,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 122.33,
    "out:Etvv": 21.25,
    "out:Ef": 9.98,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 499608.84651982493,
    "out:Seasonal Variation ROI (%)": 10,
    "out:Seasonal Variation Payback": 10,
    "out:New AHU Cost/SEK": 146792.87151982498,
    "out:DH CO2": 56.877472,
    "out:EL CO2": 1.316927,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 1.316927,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00000000010000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 127.871325,
    "out:DHW (DH)": 41.7,
    "out:Property Electricity": 12.57,
    "out:Electricity (inc PV)": 8.051517,
    "out:Total Energy Use Pre PV": 182.57,
    "out:Total Energy Use Post PV": 178.051517,
    "out:Primary Energy": 131.448206,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 14.7391,
    "out:CO2 Operational/m2": 17.595959,
    "out:Total CO2/m2": 32.335059,
    "out:Total CO2 (tons)": 102.954797,
    "out:Klimatpaverkan": -97.180624,
    "out:Initial Cost/MSEK": 0.7902203942225,
    "out:Running cost/(Apt SEK y)": 34975,
    "out:Running Cost over RSP/MSEK": 12.86535,
    "out:LCP/MSEK": -0.25085,
    "out:ROI% old": 9.750806,
    "out:Payback discounted": 13,
    "out:Atemp": 3184,
    "out:Umean": 1,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 178,
    "out:Energy use kWh/m2": 178,
    "out:Energy savings %": 2.8,
    "out:Op energy cost/MSEK": 95,
    "out:El price/MSEK": 56,
    "out:Heating price/MSEK": 33,
    "out:DH+ DHW/kWh/m2": 170,
    "out:El bought/kWh/m2": 568.258452,
    "out:Return %": 9,
    "out:Return/kSEK/y": 71,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 41.7,
    "out:EAHP (El)": 0,
    "out:PV (kr/kWp)": 17461.56255567083,
    "out:PV (kWp installed)": 45.25485,
    "out:PV (m2 panels)": 237.433632,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 42461.284501,
    "out:PV (kWh sold)": 28074.439514,
    "out:PV (% sold (El))": 66.117735,
    "out:PV (kWh self-consumed)": 14386.844987,
    "out:PV (ratio sold/self-consumed)": 1.951397,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 9,
    "out:Payback Time": 11.111111,
    "out:DH kWh savings": 0,
    "out:EL kWh savings": 14386.845543,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 70885.35,
    "out:DH kr savings": 0,
    "out:El kr savings": 28773.691086586638,
    "out:El kr sold": 42111.65927092506,
    "out:El kr saved": 28773.689974,
    "out:El kr return": 70885.34924492506,
    "out:% solar/total": 36,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 407142.177573,
    "out:% savings (space heating)": 0,
    "out:Total_Water Heating (DH)": 132772.760051,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 127.87,
    "out:Etvv": 25,
    "out:Ef": 8.05,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 790220.3942225,
    "out:Seasonal Variation ROI (%)": 9,
    "out:Seasonal Variation Payback": 11.111111,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 61.19728,
    "out:EL CO2": -43.601321,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -44.666212,
    "out:Bought CO2": 1.064891,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00000000010000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 122.33172,
    "out:DHW (DH)": 41.7,
    "out:Property Electricity": 12.57,
    "out:Electricity (inc PV)": 8.051517,
    "out:Total Energy Use Pre PV": 176.57,
    "out:Total Energy Use Post PV": 172.051517,
    "out:Primary Energy": 127.139624,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 14.7391,
    "out:CO2 Operational/m2": 15.436055,
    "out:Total CO2/m2": 30.175155,
    "out:Total CO2 (tons)": 96.077665,
    "out:Klimatpaverkan": -104.057756,
    "out:Initial Cost/MSEK": 0.8698203692225001,
    "out:Running cost/(Apt SEK y)": 33747.468085,
    "out:Running Cost over RSP/MSEK": 12.41408,
    "out:LCP/MSEK": 0.12082,
    "out:ROI% old": 16.270026,
    "out:Payback discounted": 7,
    "out:Atemp": 3184,
    "out:Umean": 1,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 172,
    "out:Energy use kWh/m2": 172,
    "out:Energy savings %": 6.4,
    "out:Op energy cost/MSEK": 91,
    "out:El price/MSEK": 54,
    "out:Heating price/MSEK": 32,
    "out:DH+ DHW/kWh/m2": 164,
    "out:El bought/kWh/m2": 568.258452,
    "out:Return %": 15,
    "out:Return/kSEK/y": 129,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 41.7,
    "out:EAHP (El)": 0,
    "out:PV (kr/kWp)": 17461.56255567083,
    "out:PV (kWp installed)": 45.25485,
    "out:PV (m2 panels)": 237.433632,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 42461.284501,
    "out:PV (kWh sold)": 28074.439514,
    "out:PV (% sold (El))": 66.117735,
    "out:PV (kWh self-consumed)": 14386.844987,
    "out:PV (ratio sold/self-consumed)": 1.951397,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 10,
    "out:Payback Time": 10,
    "out:DH kWh savings": 17638.099242,
    "out:EL kWh savings": 14386.845543,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 79599.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 88523.45,
    "out:DH kr savings": 17638.09924185777,
    "out:El kr savings": 28773.691086586638,
    "out:El kr sold": 42111.65927092506,
    "out:El kr saved": 28773.689974,
    "out:El kr return": 70885.34924492506,
    "out:% solar/total": 36,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 389504.078331,
    "out:% savings (space heating)": 4.332172,
    "out:Total_Water Heating (DH)": 132772.760051,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 122.33,
    "out:Etvv": 25,
    "out:Ef": 8.05,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 869820.3692225,
    "out:Seasonal Variation ROI (%)": 10,
    "out:Seasonal Variation Payback": 10,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 59.037376,
    "out:EL CO2": -43.601321,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -44.666212,
    "out:Bought CO2": 1.064891,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00000000010001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 127.871325,
    "out:DHW (DH)": 35.445,
    "out:Property Electricity": 12.57,
    "out:Electricity (inc PV)": 8.051517,
    "out:Total Energy Use Pre PV": 175.57,
    "out:Total Energy Use Post PV": 171.051517,
    "out:Primary Energy": 128.823206,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 15.447643,
    "out:CO2 Operational/m2": 15.076071,
    "out:Total CO2/m2": 30.523714,
    "out:Total CO2 (tons)": 97.187476,
    "out:Klimatpaverkan": -102.947945,
    "out:Initial Cost/MSEK": 1.0634363942225,
    "out:Running cost/(Apt SEK y)": 33542.893617,
    "out:Running Cost over RSP/MSEK": 12.33887,
    "out:LCP/MSEK": 0.002414,
    "out:ROI% old": 14.318138,
    "out:Payback discounted": 8,
    "out:Atemp": 3184,
    "out:Umean": 1,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 171,
    "out:Energy use kWh/m2": 171,
    "out:Energy savings %": 7,
    "out:Op energy cost/MSEK": 91,
    "out:El price/MSEK": 54,
    "out:Heating price/MSEK": 32,
    "out:DH+ DHW/kWh/m2": 163,
    "out:El bought/kWh/m2": 568.258452,
    "out:Return %": 13,
    "out:Return/kSEK/y": 138,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 35.445,
    "out:EAHP (El)": 0,
    "out:PV (kr/kWp)": 17461.56255567083,
    "out:PV (kWp installed)": 45.25485,
    "out:PV (m2 panels)": 237.433632,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 42461.284501,
    "out:PV (kWh sold)": 28074.439514,
    "out:PV (% sold (El))": 66.117735,
    "out:PV (kWh self-consumed)": 14386.844987,
    "out:PV (ratio sold/self-consumed)": 1.951397,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 9,
    "out:Payback Time": 11.111111,
    "out:DH kWh savings": 19915.914008,
    "out:EL kWh savings": 14386.845543,
    "out:Sewage heat recovery cost": 273216,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 90801.26,
    "out:DH kr savings": 19915.914007709984,
    "out:El kr savings": 28773.691086586638,
    "out:El kr sold": 42111.65927092506,
    "out:El kr saved": 28773.689974,
    "out:El kr return": 70885.34924492506,
    "out:% solar/total": 36,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 407142.177573,
    "out:% savings (space heating)": 0,
    "out:Total_Water Heating (DH)": 112856.846044,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 127.87,
    "out:Etvv": 21.25,
    "out:Ef": 8.05,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 1063436.3942225,
    "out:Seasonal Variation ROI (%)": 8,
    "out:Seasonal Variation Payback": 12.5,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 58.677392,
    "out:EL CO2": -43.601321,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -44.666212,
    "out:Bought CO2": 1.064891,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00000000010001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 122.33172,
    "out:DHW (DH)": 35.445,
    "out:Property Electricity": 12.57,
    "out:Electricity (inc PV)": 8.051517,
    "out:Total Energy Use Pre PV": 170.57,
    "out:Total Energy Use Post PV": 166.051517,
    "out:Primary Energy": 124.514624,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 15.447643,
    "out:CO2 Operational/m2": 13.276151,
    "out:Total CO2/m2": 28.723794,
    "out:Total CO2 (tons)": 91.456533,
    "out:Klimatpaverkan": -108.678888,
    "out:Initial Cost/MSEK": 1.1430363692225,
    "out:Running cost/(Apt SEK y)": 32519.93617,
    "out:Running Cost over RSP/MSEK": 11.96282,
    "out:LCP/MSEK": 0.298864,
    "out:ROI% old": 18.020924,
    "out:Payback discounted": 7,
    "out:Atemp": 3184,
    "out:Umean": 1,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 166,
    "out:Energy use kWh/m2": 166,
    "out:Energy savings %": 10.2,
    "out:Op energy cost/MSEK": 88,
    "out:El price/MSEK": 52,
    "out:Heating price/MSEK": 31,
    "out:DH+ DHW/kWh/m2": 158,
    "out:El bought/kWh/m2": 568.258452,
    "out:Return %": 16,
    "out:Return/kSEK/y": 186,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 35.445,
    "out:EAHP (El)": 0,
    "out:PV (kr/kWp)": 17461.56255567083,
    "out:PV (kWp installed)": 45.25485,
    "out:PV (m2 panels)": 237.433632,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 42461.284501,
    "out:PV (kWh sold)": 28074.439514,
    "out:PV (% sold (El))": 66.117735,
    "out:PV (kWh self-consumed)": 14386.844987,
    "out:PV (ratio sold/self-consumed)": 1.951397,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 9,
    "out:Payback Time": 11.111111,
    "out:DH kWh savings": 37554.01325,
    "out:EL kWh savings": 14386.845543,
    "out:Sewage heat recovery cost": 273216,
    "out:Balancing hydronic system cost": 79599.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 108439.36,
    "out:DH kr savings": 37554.01324956775,
    "out:El kr savings": 28773.691086586638,
    "out:El kr sold": 42111.65927092506,
    "out:El kr saved": 28773.689974,
    "out:El kr return": 70885.34924492506,
    "out:% solar/total": 36,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 389504.078331,
    "out:% savings (space heating)": 4.332172,
    "out:Total_Water Heating (DH)": 112856.846044,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 122.33,
    "out:Etvv": 21.25,
    "out:Ef": 8.05,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 1143036.3692225001,
    "out:Seasonal Variation ROI (%)": 9,
    "out:Seasonal Variation Payback": 11.111111,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 56.877472,
    "out:EL CO2": -43.601321,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -44.666212,
    "out:Bought CO2": 1.064891,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00000000010100000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 1,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 127.871325,
    "out:DHW (DH)": 41.7,
    "out:Property Electricity": 9.976115,
    "out:Electricity (inc PV)": 6.231337,
    "out:Total Energy Use Pre PV": 179.976115,
    "out:Total Energy Use Post PV": 176.231337,
    "out:Primary Energy": 128.171882,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 14.923602,
    "out:CO2 Operational/m2": 13.365831,
    "out:Total CO2/m2": 28.289433,
    "out:Total CO2 (tons)": 90.073528,
    "out:Klimatpaverkan": -110.061893,
    "out:Initial Cost/MSEK": 0.937013265742325,
    "out:Running cost/(Apt SEK y)": 34649.765957,
    "out:Running Cost over RSP/MSEK": 12.74712,
    "out:LCP/MSEK": -0.279413,
    "out:ROI% old": 10.025777,
    "out:Payback discounted": 12,
    "out:Atemp": 3184,
    "out:Umean": 1,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 176,
    "out:Energy use kWh/m2": 176,
    "out:Energy savings %": 4,
    "out:Op energy cost/MSEK": 94,
    "out:El price/MSEK": 55,
    "out:Heating price/MSEK": 33,
    "out:DH+ DHW/kWh/m2": 170,
    "out:El bought/kWh/m2": 439.497378,
    "out:Return %": 9,
    "out:Return/kSEK/y": 86,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 41.7,
    "out:EAHP (El)": 0,
    "out:PV (kr/kWp)": 17461.56255567083,
    "out:PV (kWp installed)": 45.25485,
    "out:PV (m2 panels)": 237.433632,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 42461.284501,
    "out:PV (kWh sold)": 30537.914073,
    "out:PV (% sold (El))": 71.919431,
    "out:PV (kWh self-consumed)": 11923.370428,
    "out:PV (ratio sold/self-consumed)": 2.561181,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 9,
    "out:Payback Time": 11.111111,
    "out:DH kWh savings": 0,
    "out:EL kWh savings": 20182.29692,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 86171.46,
    "out:DH kr savings": 0,
    "out:El kr savings": 40364.59383912171,
    "out:El kr sold": 45806.87111012268,
    "out:El kr saved": 23846.740856,
    "out:El kr return": 69653.61196612268,
    "out:% solar/total": 30,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 407142.177573,
    "out:% savings (space heating)": 0,
    "out:Total_Water Heating (DH)": 132772.760051,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 127.87,
    "out:Etvv": 25,
    "out:Ef": 6.23,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 937013.265742325,
    "out:Seasonal Variation ROI (%)": 9,
    "out:Seasonal Variation Payback": 11.111111,
    "out:New AHU Cost/SEK": 146792.87151982498,
    "out:DH CO2": 61.19728,
    "out:EL CO2": -47.831449,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -48.655512,
    "out:Bought CO2": 0.824063,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00000000010100100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 1,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 122.33172,
    "out:DHW (DH)": 41.7,
    "out:Property Electricity": 9.976115,
    "out:Electricity (inc PV)": 6.231337,
    "out:Total Energy Use Pre PV": 173.976115,
    "out:Total Energy Use Post PV": 170.231337,
    "out:Primary Energy": 123.8633,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 14.923602,
    "out:CO2 Operational/m2": 11.205927,
    "out:Total CO2/m2": 26.129529,
    "out:Total CO2 (tons)": 83.196395,
    "out:Klimatpaverkan": -116.939026,
    "out:Initial Cost/MSEK": 1.016613240742325,
    "out:Running cost/(Apt SEK y)": 33422.234043,
    "out:Running Cost over RSP/MSEK": 12.29586,
    "out:LCP/MSEK": 0.092247,
    "out:ROI% old": 15.58199,
    "out:Payback discounted": 8,
    "out:Atemp": 3184,
    "out:Umean": 1,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 170,
    "out:Energy use kWh/m2": 170,
    "out:Energy savings %": 7.6,
    "out:Op energy cost/MSEK": 91,
    "out:El price/MSEK": 53,
    "out:Heating price/MSEK": 32,
    "out:DH+ DHW/kWh/m2": 164,
    "out:El bought/kWh/m2": 439.497378,
    "out:Return %": 14,
    "out:Return/kSEK/y": 144,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 41.7,
    "out:EAHP (El)": 0,
    "out:PV (kr/kWp)": 17461.56255567083,
    "out:PV (kWp installed)": 45.25485,
    "out:PV (m2 panels)": 237.433632,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 42461.284501,
    "out:PV (kWh sold)": 30537.914073,
    "out:PV (% sold (El))": 71.919431,
    "out:PV (kWh self-consumed)": 11923.370428,
    "out:PV (ratio sold/self-consumed)": 2.561181,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 10,
    "out:Payback Time": 10,
    "out:DH kWh savings": 17638.099242,
    "out:EL kWh savings": 20182.29692,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 79599.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 103809.56,
    "out:DH kr savings": 17638.09924185777,
    "out:El kr savings": 40364.59383912171,
    "out:El kr sold": 45806.87111012268,
    "out:El kr saved": 23846.740856,
    "out:El kr return": 69653.61196612268,
    "out:% solar/total": 30,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 389504.078331,
    "out:% savings (space heating)": 4.332172,
    "out:Total_Water Heating (DH)": 132772.760051,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 122.33,
    "out:Etvv": 25,
    "out:Ef": 6.23,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 1016613.240742325,
    "out:Seasonal Variation ROI (%)": 10,
    "out:Seasonal Variation Payback": 10,
    "out:New AHU Cost/SEK": 146792.87151982498,
    "out:DH CO2": 59.037376,
    "out:EL CO2": -47.831449,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -48.655512,
    "out:Bought CO2": 0.824063,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00000000010101000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 1,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 127.871325,
    "out:DHW (DH)": 35.445,
    "out:Property Electricity": 9.976115,
    "out:Electricity (inc PV)": 6.231337,
    "out:Total Energy Use Pre PV": 172.976115,
    "out:Total Energy Use Post PV": 169.231337,
    "out:Primary Energy": 125.546882,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 15.632145,
    "out:CO2 Operational/m2": 10.845943,
    "out:Total CO2/m2": 26.478088,
    "out:Total CO2 (tons)": 84.306207,
    "out:Klimatpaverkan": -115.829214,
    "out:Initial Cost/MSEK": 1.210229265742325,
    "out:Running cost/(Apt SEK y)": 33217.659574,
    "out:Running Cost over RSP/MSEK": 12.22065,
    "out:LCP/MSEK": -0.026159,
    "out:ROI% old": 13.976927,
    "out:Payback discounted": 9,
    "out:Atemp": 3184,
    "out:Umean": 1,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 169,
    "out:Energy use kWh/m2": 169,
    "out:Energy savings %": 8.3,
    "out:Op energy cost/MSEK": 90,
    "out:El price/MSEK": 53,
    "out:Heating price/MSEK": 32,
    "out:DH+ DHW/kWh/m2": 163,
    "out:El bought/kWh/m2": 439.497378,
    "out:Return %": 13,
    "out:Return/kSEK/y": 153,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 35.445,
    "out:EAHP (El)": 0,
    "out:PV (kr/kWp)": 17461.56255567083,
    "out:PV (kWp installed)": 45.25485,
    "out:PV (m2 panels)": 237.433632,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 42461.284501,
    "out:PV (kWh sold)": 30537.914073,
    "out:PV (% sold (El))": 71.919431,
    "out:PV (kWh self-consumed)": 11923.370428,
    "out:PV (ratio sold/self-consumed)": 2.561181,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 9,
    "out:Payback Time": 11.111111,
    "out:DH kWh savings": 19915.914008,
    "out:EL kWh savings": 20182.29692,
    "out:Sewage heat recovery cost": 273216,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 106087.38,
    "out:DH kr savings": 19915.914007709984,
    "out:El kr savings": 40364.59383912171,
    "out:El kr sold": 45806.87111012268,
    "out:El kr saved": 23846.740856,
    "out:El kr return": 69653.61196612268,
    "out:% solar/total": 30,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 407142.177573,
    "out:% savings (space heating)": 0,
    "out:Total_Water Heating (DH)": 112856.846044,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 127.87,
    "out:Etvv": 21.25,
    "out:Ef": 6.23,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 1210229.265742325,
    "out:Seasonal Variation ROI (%)": 9,
    "out:Seasonal Variation Payback": 11.111111,
    "out:New AHU Cost/SEK": 146792.87151982498,
    "out:DH CO2": 58.677392,
    "out:EL CO2": -47.831449,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -48.655512,
    "out:Bought CO2": 0.824063,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00000000010101100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 1,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 122.33172,
    "out:DHW (DH)": 35.445,
    "out:Property Electricity": 9.976115,
    "out:Electricity (inc PV)": 6.231337,
    "out:Total Energy Use Pre PV": 167.976115,
    "out:Total Energy Use Post PV": 164.231337,
    "out:Primary Energy": 121.2383,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 15.632145,
    "out:CO2 Operational/m2": 9.046023,
    "out:Total CO2/m2": 24.678168,
    "out:Total CO2 (tons)": 78.575263,
    "out:Klimatpaverkan": -121.560158,
    "out:Initial Cost/MSEK": 1.2898292407423249,
    "out:Running cost/(Apt SEK y)": 32194.702128,
    "out:Running Cost over RSP/MSEK": 11.84459,
    "out:LCP/MSEK": 0.270301,
    "out:ROI% old": 17.279474,
    "out:Payback discounted": 7,
    "out:Atemp": 3184,
    "out:Umean": 1,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 164,
    "out:Energy use kWh/m2": 164,
    "out:Energy savings %": 11.6,
    "out:Op energy cost/MSEK": 87,
    "out:El price/MSEK": 51,
    "out:Heating price/MSEK": 31,
    "out:DH+ DHW/kWh/m2": 158,
    "out:El bought/kWh/m2": 439.497378,
    "out:Return %": 16,
    "out:Return/kSEK/y": 202,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 35.445,
    "out:EAHP (El)": 0,
    "out:PV (kr/kWp)": 17461.56255567083,
    "out:PV (kWp installed)": 45.25485,
    "out:PV (m2 panels)": 237.433632,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 42461.284501,
    "out:PV (kWh sold)": 30537.914073,
    "out:PV (% sold (El))": 71.919431,
    "out:PV (kWh self-consumed)": 11923.370428,
    "out:PV (ratio sold/self-consumed)": 2.561181,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 10,
    "out:Payback Time": 10,
    "out:DH kWh savings": 37554.01325,
    "out:EL kWh savings": 20182.29692,
    "out:Sewage heat recovery cost": 273216,
    "out:Balancing hydronic system cost": 79599.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 123725.48,
    "out:DH kr savings": 37554.01324956775,
    "out:El kr savings": 40364.59383912171,
    "out:El kr sold": 45806.87111012268,
    "out:El kr saved": 23846.740856,
    "out:El kr return": 69653.61196612268,
    "out:% solar/total": 30,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 389504.078331,
    "out:% savings (space heating)": 4.332172,
    "out:Total_Water Heating (DH)": 112856.846044,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 122.33,
    "out:Etvv": 21.25,
    "out:Ef": 6.23,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 1289829.2407423249,
    "out:Seasonal Variation ROI (%)": 9,
    "out:Seasonal Variation Payback": 11.111111,
    "out:New AHU Cost/SEK": 146792.87151982498,
    "out:DH CO2": 56.877472,
    "out:EL CO2": -47.831449,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -48.655512,
    "out:Bought CO2": 0.824063,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00000000020000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 127.871325,
    "out:DHW (DH)": 41.7,
    "out:Property Electricity": 12.57,
    "out:Electricity (inc PV)": 7.530921,
    "out:Total Energy Use Pre PV": 182.57,
    "out:Total Energy Use Post PV": 177.530921,
    "out:Primary Energy": 130.511133,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 29.478199,
    "out:CO2 Operational/m2": -47.759358,
    "out:Total CO2/m2": -18.281159,
    "out:Total CO2 (tons)": -58.207193,
    "out:Klimatpaverkan": -258.342614,
    "out:Initial Cost/MSEK": 1.58043374594375,
    "out:Running cost/(Apt SEK y)": 33602.234043,
    "out:Running Cost over RSP/MSEK": 12.37492,
    "out:LCP/MSEK": -0.550634,
    "out:ROI% old": 9.308475,
    "out:Payback discounted": 13,
    "out:Atemp": 3184,
    "out:Umean": 1,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 178,
    "out:Energy use kWh/m2": 178,
    "out:Energy savings %": 2.8,
    "out:Op energy cost/MSEK": 92,
    "out:El price/MSEK": 53,
    "out:Heating price/MSEK": 33,
    "out:DH+ DHW/kWh/m2": 170,
    "out:El bought/kWh/m2": 530.25352,
    "out:Return %": 9,
    "out:Return/kSEK/y": 135,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 41.7,
    "out:EAHP (El)": 0,
    "out:PV (kr/kWp)": 17461.48474631725,
    "out:PV (kWp installed)": 90.5097,
    "out:PV (m2 panels)": 474.867263,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 84922.568824,
    "out:PV (kWh sold)": 68878.145463,
    "out:PV (% sold (El))": 81.106997,
    "out:PV (kWh self-consumed)": 16044.423361,
    "out:PV (ratio sold/self-consumed)": 4.292965,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 9,
    "out:Payback Time": 11.111111,
    "out:DH kWh savings": 0,
    "out:EL kWh savings": 16044.422709,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 135406.06,
    "out:DH kr savings": 0,
    "out:El kr savings": 32088.845417124598,
    "out:El kr sold": 103317.21819422109,
    "out:El kr saved": 32088.846722,
    "out:El kr return": 135406.06491622108,
    "out:% solar/total": 40,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 407142.177573,
    "out:% savings (space heating)": 0,
    "out:Total_Water Heating (DH)": 132772.760051,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 127.87,
    "out:Etvv": 25,
    "out:Ef": 7.53,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 1580433.74594375,
    "out:Seasonal Variation ROI (%)": 9,
    "out:Seasonal Variation Payback": 11.111111,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 61.19728,
    "out:EL CO2": -108.956638,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -109.952146,
    "out:Bought CO2": 0.995508,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00000000020000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 122.33172,
    "out:DHW (DH)": 41.7,
    "out:Property Electricity": 12.57,
    "out:Electricity (inc PV)": 7.530921,
    "out:Total Energy Use Pre PV": 176.57,
    "out:Total Energy Use Post PV": 171.530921,
    "out:Primary Energy": 126.202551,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 29.478199,
    "out:CO2 Operational/m2": -49.919262,
    "out:Total CO2/m2": -20.441063,
    "out:Total CO2 (tons)": -65.084325,
    "out:Klimatpaverkan": -265.219746,
    "out:Initial Cost/MSEK": 1.66003372094375,
    "out:Running cost/(Apt SEK y)": 32374.702128,
    "out:Running Cost over RSP/MSEK": 11.92365,
    "out:LCP/MSEK": -0.178964,
    "out:ROI% old": 12.74561,
    "out:Payback discounted": 10,
    "out:Atemp": 3184,
    "out:Umean": 1,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 172,
    "out:Energy use kWh/m2": 172,
    "out:Energy savings %": 6.4,
    "out:Op energy cost/MSEK": 88,
    "out:El price/MSEK": 51,
    "out:Heating price/MSEK": 32,
    "out:DH+ DHW/kWh/m2": 164,
    "out:El bought/kWh/m2": 530.25352,
    "out:Return %": 12,
    "out:Return/kSEK/y": 193,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 41.7,
    "out:EAHP (El)": 0,
    "out:PV (kr/kWp)": 17461.48474631725,
    "out:PV (kWp installed)": 90.5097,
    "out:PV (m2 panels)": 474.867263,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 84922.568824,
    "out:PV (kWh sold)": 68878.145463,
    "out:PV (% sold (El))": 81.106997,
    "out:PV (kWh self-consumed)": 16044.423361,
    "out:PV (ratio sold/self-consumed)": 4.292965,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 9,
    "out:Payback Time": 11.111111,
    "out:DH kWh savings": 17638.099242,
    "out:EL kWh savings": 16044.422709,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 79599.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 153044.16,
    "out:DH kr savings": 17638.09924185777,
    "out:El kr savings": 32088.845417124598,
    "out:El kr sold": 103317.21819422109,
    "out:El kr saved": 32088.846722,
    "out:El kr return": 135406.06491622108,
    "out:% solar/total": 40,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 389504.078331,
    "out:% savings (space heating)": 4.332172,
    "out:Total_Water Heating (DH)": 132772.760051,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 122.33,
    "out:Etvv": 25,
    "out:Ef": 7.53,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 1660033.7209437499,
    "out:Seasonal Variation ROI (%)": 9,
    "out:Seasonal Variation Payback": 11.111111,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 59.037376,
    "out:EL CO2": -108.956638,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -109.952146,
    "out:Bought CO2": 0.995508,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00000000020001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 127.871325,
    "out:DHW (DH)": 35.445,
    "out:Property Electricity": 12.57,
    "out:Electricity (inc PV)": 7.530921,
    "out:Total Energy Use Pre PV": 175.57,
    "out:Total Energy Use Post PV": 170.530921,
    "out:Primary Energy": 127.886133,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 30.186742,
    "out:CO2 Operational/m2": -50.279246,
    "out:Total CO2/m2": -20.092504,
    "out:Total CO2 (tons)": -63.974513,
    "out:Klimatpaverkan": -264.109935,
    "out:Initial Cost/MSEK": 1.85364974594375,
    "out:Running cost/(Apt SEK y)": 32170.106383,
    "out:Running Cost over RSP/MSEK": 11.84844,
    "out:LCP/MSEK": -0.29737,
    "out:ROI% old": 11.993944,
    "out:Payback discounted": 10,
    "out:Atemp": 3184,
    "out:Umean": 1,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 171,
    "out:Energy use kWh/m2": 171,
    "out:Energy savings %": 7,
    "out:Op energy cost/MSEK": 88,
    "out:El price/MSEK": 51,
    "out:Heating price/MSEK": 32,
    "out:DH+ DHW/kWh/m2": 163,
    "out:El bought/kWh/m2": 530.25352,
    "out:Return %": 11,
    "out:Return/kSEK/y": 203,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 35.445,
    "out:EAHP (El)": 0,
    "out:PV (kr/kWp)": 17461.48474631725,
    "out:PV (kWp installed)": 90.5097,
    "out:PV (m2 panels)": 474.867263,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 84922.568824,
    "out:PV (kWh sold)": 68878.145463,
    "out:PV (% sold (El))": 81.106997,
    "out:PV (kWh self-consumed)": 16044.423361,
    "out:PV (ratio sold/self-consumed)": 4.292965,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 8,
    "out:Payback Time": 12.5,
    "out:DH kWh savings": 19915.914008,
    "out:EL kWh savings": 16044.422709,
    "out:Sewage heat recovery cost": 273216,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 155321.98,
    "out:DH kr savings": 19915.914007709984,
    "out:El kr savings": 32088.845417124598,
    "out:El kr sold": 103317.21819422109,
    "out:El kr saved": 32088.846722,
    "out:El kr return": 135406.06491622108,
    "out:% solar/total": 40,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 407142.177573,
    "out:% savings (space heating)": 0,
    "out:Total_Water Heating (DH)": 112856.846044,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 127.87,
    "out:Etvv": 21.25,
    "out:Ef": 7.53,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 1853649.74594375,
    "out:Seasonal Variation ROI (%)": 8,
    "out:Seasonal Variation Payback": 12.5,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 58.677392,
    "out:EL CO2": -108.956638,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -109.952146,
    "out:Bought CO2": 0.995508,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00000000020001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 122.33172,
    "out:DHW (DH)": 35.445,
    "out:Property Electricity": 12.57,
    "out:Electricity (inc PV)": 7.530921,
    "out:Total Energy Use Pre PV": 170.57,
    "out:Total Energy Use Post PV": 165.530921,
    "out:Primary Energy": 123.577551,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 30.186742,
    "out:CO2 Operational/m2": -52.079166,
    "out:Total CO2/m2": -21.892424,
    "out:Total CO2 (tons)": -69.705457,
    "out:Klimatpaverkan": -269.840878,
    "out:Initial Cost/MSEK": 1.9332497209437498,
    "out:Running cost/(Apt SEK y)": 31147.170213,
    "out:Running Cost over RSP/MSEK": 11.47239,
    "out:LCP/MSEK": -0.00092,
    "out:ROI% old": 14.278918,
    "out:Payback discounted": 9,
    "out:Atemp": 3184,
    "out:Umean": 1,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 166,
    "out:Energy use kWh/m2": 166,
    "out:Energy savings %": 10.2,
    "out:Op energy cost/MSEK": 85,
    "out:El price/MSEK": 49,
    "out:Heating price/MSEK": 31,
    "out:DH+ DHW/kWh/m2": 158,
    "out:El bought/kWh/m2": 530.25352,
    "out:Return %": 13,
    "out:Return/kSEK/y": 251,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 35.445,
    "out:EAHP (El)": 0,
    "out:PV (kr/kWp)": 17461.48474631725,
    "out:PV (kWp installed)": 90.5097,
    "out:PV (m2 panels)": 474.867263,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 84922.568824,
    "out:PV (kWh sold)": 68878.145463,
    "out:PV (% sold (El))": 81.106997,
    "out:PV (kWh self-consumed)": 16044.423361,
    "out:PV (ratio sold/self-consumed)": 4.292965,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 9,
    "out:Payback Time": 11.111111,
    "out:DH kWh savings": 37554.01325,
    "out:EL kWh savings": 16044.422709,
    "out:Sewage heat recovery cost": 273216,
    "out:Balancing hydronic system cost": 79599.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 172960.08,
    "out:DH kr savings": 37554.01324956775,
    "out:El kr savings": 32088.845417124598,
    "out:El kr sold": 103317.21819422109,
    "out:El kr saved": 32088.846722,
    "out:El kr return": 135406.06491622108,
    "out:% solar/total": 40,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 389504.078331,
    "out:% savings (space heating)": 4.332172,
    "out:Total_Water Heating (DH)": 112856.846044,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 122.33,
    "out:Etvv": 21.25,
    "out:Ef": 7.53,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 1933249.7209437499,
    "out:Seasonal Variation ROI (%)": 9,
    "out:Seasonal Variation Payback": 11.111111,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 56.877472,
    "out:EL CO2": -108.956638,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -109.952146,
    "out:Bought CO2": 0.995508,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00000000020100000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 1,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 127.871325,
    "out:DHW (DH)": 41.7,
    "out:Property Electricity": 9.976115,
    "out:Electricity (inc PV)": 5.879188,
    "out:Total Energy Use Pre PV": 179.976115,
    "out:Total Energy Use Post PV": 175.879188,
    "out:Primary Energy": 127.538013,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 29.662701,
    "out:CO2 Operational/m2": -52.845084,
    "out:Total CO2/m2": -23.182383,
    "out:Total CO2 (tons)": -73.812685,
    "out:Klimatpaverkan": -273.948106,
    "out:Initial Cost/MSEK": 1.7272266174635753,
    "out:Running cost/(Apt SEK y)": 33282.702128,
    "out:Running Cost over RSP/MSEK": 12.25878,
    "out:LCP/MSEK": -0.581287,
    "out:ROI% old": 9.477952,
    "out:Payback discounted": 13,
    "out:Atemp": 3184,
    "out:Umean": 1,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 176,
    "out:Energy use kWh/m2": 176,
    "out:Energy savings %": 4,
    "out:Op energy cost/MSEK": 91,
    "out:El price/MSEK": 52,
    "out:Heating price/MSEK": 33,
    "out:DH+ DHW/kWh/m2": 170,
    "out:El bought/kWh/m2": 413.65837,
    "out:Return %": 9,
    "out:Return/kSEK/y": 150,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 41.7,
    "out:EAHP (El)": 0,
    "out:PV (kr/kWp)": 17461.48474631725,
    "out:PV (kWp installed)": 90.5097,
    "out:PV (m2 panels)": 474.867263,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 84922.568824,
    "out:PV (kWh sold)": 71877.957046,
    "out:PV (% sold (El))": 84.639405,
    "out:PV (kWh self-consumed)": 13044.611778,
    "out:PV (ratio sold/self-consumed)": 5.510165,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 9,
    "out:Payback Time": 11.111111,
    "out:DH kWh savings": 0,
    "out:EL kWh savings": 21303.538998,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 150424.01,
    "out:DH kr savings": 0,
    "out:El kr savings": 42607.077996404216,
    "out:El kr sold": 107816.93556907076,
    "out:El kr saved": 26089.223556,
    "out:El kr return": 133906.15912507076,
    "out:% solar/total": 33,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 407142.177573,
    "out:% savings (space heating)": 0,
    "out:Total_Water Heating (DH)": 132772.760051,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 127.87,
    "out:Etvv": 25,
    "out:Ef": 5.88,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 1727226.6174635752,
    "out:Seasonal Variation ROI (%)": 9,
    "out:Seasonal Variation Payback": 11.111111,
    "out:New AHU Cost/SEK": 146792.87151982498,
    "out:DH CO2": 61.19728,
    "out:EL CO2": -114.042364,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -114.819396,
    "out:Bought CO2": 0.777032,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00000000020100100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 1,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 122.33172,
    "out:DHW (DH)": 41.7,
    "out:Property Electricity": 9.976115,
    "out:Electricity (inc PV)": 5.879188,
    "out:Total Energy Use Pre PV": 173.976115,
    "out:Total Energy Use Post PV": 169.879188,
    "out:Primary Energy": 123.229431,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 29.662701,
    "out:CO2 Operational/m2": -55.004988,
    "out:Total CO2/m2": -25.342287,
    "out:Total CO2 (tons)": -80.689818,
    "out:Klimatpaverkan": -280.825239,
    "out:Initial Cost/MSEK": 1.8068265924635751,
    "out:Running cost/(Apt SEK y)": 32055.170213,
    "out:Running Cost over RSP/MSEK": 11.80751,
    "out:LCP/MSEK": -0.209617,
    "out:ROI% old": 12.628376,
    "out:Payback discounted": 10,
    "out:Atemp": 3184,
    "out:Umean": 1,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 170,
    "out:Energy use kWh/m2": 170,
    "out:Energy savings %": 7.6,
    "out:Op energy cost/MSEK": 88,
    "out:El price/MSEK": 50,
    "out:Heating price/MSEK": 32,
    "out:DH+ DHW/kWh/m2": 164,
    "out:El bought/kWh/m2": 413.65837,
    "out:Return %": 12,
    "out:Return/kSEK/y": 208,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 41.7,
    "out:EAHP (El)": 0,
    "out:PV (kr/kWp)": 17461.48474631725,
    "out:PV (kWp installed)": 90.5097,
    "out:PV (m2 panels)": 474.867263,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 84922.568824,
    "out:PV (kWh sold)": 71877.957046,
    "out:PV (% sold (El))": 84.639405,
    "out:PV (kWh self-consumed)": 13044.611778,
    "out:PV (ratio sold/self-consumed)": 5.510165,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 9,
    "out:Payback Time": 11.111111,
    "out:DH kWh savings": 17638.099242,
    "out:EL kWh savings": 21303.538998,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 79599.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 168062.11,
    "out:DH kr savings": 17638.09924185777,
    "out:El kr savings": 42607.077996404216,
    "out:El kr sold": 107816.93556907076,
    "out:El kr saved": 26089.223556,
    "out:El kr return": 133906.15912507076,
    "out:% solar/total": 33,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 389504.078331,
    "out:% savings (space heating)": 4.332172,
    "out:Total_Water Heating (DH)": 132772.760051,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 122.33,
    "out:Etvv": 25,
    "out:Ef": 5.88,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 1806826.592463575,
    "out:Seasonal Variation ROI (%)": 9,
    "out:Seasonal Variation Payback": 11.111111,
    "out:New AHU Cost/SEK": 146792.87151982498,
    "out:DH CO2": 59.037376,
    "out:EL CO2": -114.042364,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -114.819396,
    "out:Bought CO2": 0.777032,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00000000020101000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 1,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 127.871325,
    "out:DHW (DH)": 35.445,
    "out:Property Electricity": 9.976115,
    "out:Electricity (inc PV)": 5.879188,
    "out:Total Energy Use Pre PV": 172.976115,
    "out:Total Energy Use Post PV": 168.879188,
    "out:Primary Energy": 124.913013,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 30.371244,
    "out:CO2 Operational/m2": -55.364972,
    "out:Total CO2/m2": -24.993728,
    "out:Total CO2 (tons)": -79.580006,
    "out:Klimatpaverkan": -279.715427,
    "out:Initial Cost/MSEK": 2.000442617463575,
    "out:Running cost/(Apt SEK y)": 31850.574468,
    "out:Running Cost over RSP/MSEK": 11.7323,
    "out:LCP/MSEK": -0.328023,
    "out:ROI% old": 11.943214,
    "out:Payback discounted": 10,
    "out:Atemp": 3184,
    "out:Umean": 1,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 169,
    "out:Energy use kWh/m2": 169,
    "out:Energy savings %": 8.3,
    "out:Op energy cost/MSEK": 87,
    "out:El price/MSEK": 50,
    "out:Heating price/MSEK": 32,
    "out:DH+ DHW/kWh/m2": 163,
    "out:El bought/kWh/m2": 413.65837,
    "out:Return %": 11,
    "out:Return/kSEK/y": 218,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 35.445,
    "out:EAHP (El)": 0,
    "out:PV (kr/kWp)": 17461.48474631725,
    "out:PV (kWp installed)": 90.5097,
    "out:PV (m2 panels)": 474.867263,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 84922.568824,
    "out:PV (kWh sold)": 71877.957046,
    "out:PV (% sold (El))": 84.639405,
    "out:PV (kWh self-consumed)": 13044.611778,
    "out:PV (ratio sold/self-consumed)": 5.510165,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 9,
    "out:Payback Time": 11.111111,
    "out:DH kWh savings": 19915.914008,
    "out:EL kWh savings": 21303.538998,
    "out:Sewage heat recovery cost": 273216,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 170339.93,
    "out:DH kr savings": 19915.914007709984,
    "out:El kr savings": 42607.077996404216,
    "out:El kr sold": 107816.93556907076,
    "out:El kr saved": 26089.223556,
    "out:El kr return": 133906.15912507076,
    "out:% solar/total": 33,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 407142.177573,
    "out:% savings (space heating)": 0,
    "out:Total_Water Heating (DH)": 112856.846044,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 127.87,
    "out:Etvv": 21.25,
    "out:Ef": 5.88,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 2000442.617463575,
    "out:Seasonal Variation ROI (%)": 8,
    "out:Seasonal Variation Payback": 12.5,
    "out:New AHU Cost/SEK": 146792.87151982498,
    "out:DH CO2": 58.677392,
    "out:EL CO2": -114.042364,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -114.819396,
    "out:Bought CO2": 0.777032,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00000000020101100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 1,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 122.33172,
    "out:DHW (DH)": 35.445,
    "out:Property Electricity": 9.976115,
    "out:Electricity (inc PV)": 5.879188,
    "out:Total Energy Use Pre PV": 167.976115,
    "out:Total Energy Use Post PV": 163.879188,
    "out:Primary Energy": 120.604431,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 30.371244,
    "out:CO2 Operational/m2": -57.164892,
    "out:Total CO2/m2": -26.793648,
    "out:Total CO2 (tons)": -85.31095,
    "out:Klimatpaverkan": -285.446371,
    "out:Initial Cost/MSEK": 2.0800425924635753,
    "out:Running cost/(Apt SEK y)": 30827.638298,
    "out:Running Cost over RSP/MSEK": 11.35624,
    "out:LCP/MSEK": -0.031563,
    "out:ROI% old": 14.068943,
    "out:Payback discounted": 9,
    "out:Atemp": 3184,
    "out:Umean": 1,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 164,
    "out:Energy use kWh/m2": 164,
    "out:Energy savings %": 11.6,
    "out:Op energy cost/MSEK": 84,
    "out:El price/MSEK": 48,
    "out:Heating price/MSEK": 31,
    "out:DH+ DHW/kWh/m2": 158,
    "out:El bought/kWh/m2": 413.65837,
    "out:Return %": 13,
    "out:Return/kSEK/y": 266,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 35.445,
    "out:EAHP (El)": 0,
    "out:PV (kr/kWp)": 17461.48474631725,
    "out:PV (kWp installed)": 90.5097,
    "out:PV (m2 panels)": 474.867263,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 84922.568824,
    "out:PV (kWh sold)": 71877.957046,
    "out:PV (% sold (El))": 84.639405,
    "out:PV (kWh self-consumed)": 13044.611778,
    "out:PV (ratio sold/self-consumed)": 5.510165,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 9,
    "out:Payback Time": 11.111111,
    "out:DH kWh savings": 37554.01325,
    "out:EL kWh savings": 21303.538998,
    "out:Sewage heat recovery cost": 273216,
    "out:Balancing hydronic system cost": 79599.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 187978.03,
    "out:DH kr savings": 37554.01324956775,
    "out:El kr savings": 42607.077996404216,
    "out:El kr sold": 107816.93556907076,
    "out:El kr saved": 26089.223556,
    "out:El kr return": 133906.15912507076,
    "out:% solar/total": 33,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 389504.078331,
    "out:% savings (space heating)": 4.332172,
    "out:Total_Water Heating (DH)": 112856.846044,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 122.33,
    "out:Etvv": 21.25,
    "out:Ef": 5.88,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 2080042.5924635753,
    "out:Seasonal Variation ROI (%)": 9,
    "out:Seasonal Variation Payback": 11.111111,
    "out:New AHU Cost/SEK": 146792.87151982498,
    "out:DH CO2": 56.877472,
    "out:EL CO2": -114.042364,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -114.819396,
    "out:Bought CO2": 0.777032,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00000000100000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 127.871325,
    "out:DHW (DH)": 33.36,
    "out:Property Electricity": 12.57,
    "out:Electricity (inc PV)": 12.57,
    "out:Total Energy Use Pre PV": 173.57,
    "out:Total Energy Use Post PV": 173.57,
    "out:Primary Energy": 136.081475,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 0,
    "out:CO2 Operational/m2": 59.616765,
    "out:Total CO2/m2": 59.616765,
    "out:Total CO2 (tons)": 189.819723,
    "out:Klimatpaverkan": -10.315698,
    "out:Initial Cost/MSEK": 0.1240476875,
    "out:Running cost/(Apt SEK y)": 34641.914894,
    "out:Running Cost over RSP/MSEK": 12.72782,
    "out:LCP/MSEK": 0.552852,
    "out:ROI% old": 77.953892,
    "out:Payback discounted": 2,
    "out:Atemp": 3184,
    "out:Umean": 1,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 174,
    "out:Energy use kWh/m2": 174,
    "out:Energy savings %": 5.2,
    "out:Op energy cost/MSEK": 93,
    "out:El price/MSEK": 56,
    "out:Heating price/MSEK": 31,
    "out:DH+ DHW/kWh/m2": 161,
    "out:El bought/kWh/m2": 879.9,
    "out:Return %": 70,
    "out:Return/kSEK/y": 87,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 33.36,
    "out:EAHP (El)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 21,
    "out:Payback Time": 4.761905,
    "out:DH kWh savings": 26554.55201,
    "out:EL kWh savings": 2.2624e-11,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 26554.55,
    "out:DH kr savings": 26554.55201028001,
    "out:El kr savings": 4.524734779920436e-11,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 407142.177573,
    "out:% savings (space heating)": 0,
    "out:Total_Water Heating (DH)": 106218.208041,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 127.87,
    "out:Etvv": 20,
    "out:Ef": 12.57,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 124047.6875,
    "out:Seasonal Variation ROI (%)": 19,
    "out:Seasonal Variation Payback": 5.263158,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 57.957424,
    "out:EL CO2": 1.659341,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 1.659341,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00000000100000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 122.33172,
    "out:DHW (DH)": 33.36,
    "out:Property Electricity": 12.57,
    "out:Electricity (inc PV)": 12.57,
    "out:Total Energy Use Pre PV": 168.57,
    "out:Total Energy Use Post PV": 168.57,
    "out:Primary Energy": 131.772893,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 0,
    "out:CO2 Operational/m2": 57.816845,
    "out:Total CO2/m2": 57.816845,
    "out:Total CO2 (tons)": 184.088779,
    "out:Klimatpaverkan": -16.046642,
    "out:Initial Cost/MSEK": 0.2036476625,
    "out:Running cost/(Apt SEK y)": 33618.957447,
    "out:Running Cost over RSP/MSEK": 12.35177,
    "out:LCP/MSEK": 0.849302,
    "out:ROI% old": 73.863567,
    "out:Payback discounted": 2,
    "out:Atemp": 3184,
    "out:Umean": 1,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 169,
    "out:Energy use kWh/m2": 169,
    "out:Energy savings %": 8.3,
    "out:Op energy cost/MSEK": 90,
    "out:El price/MSEK": 55,
    "out:Heating price/MSEK": 30,
    "out:DH+ DHW/kWh/m2": 156,
    "out:El bought/kWh/m2": 879.9,
    "out:Return %": 66,
    "out:Return/kSEK/y": 135,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 33.36,
    "out:EAHP (El)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 22,
    "out:Payback Time": 4.545455,
    "out:DH kWh savings": 44192.651252,
    "out:EL kWh savings": 2.2624e-11,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 79599.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 44192.65,
    "out:DH kr savings": 44192.65125213769,
    "out:El kr savings": 4.524734779920436e-11,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 389504.078331,
    "out:% savings (space heating)": 4.332172,
    "out:Total_Water Heating (DH)": 106218.208041,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 122.33,
    "out:Etvv": 20,
    "out:Ef": 12.57,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 203647.6625,
    "out:Seasonal Variation ROI (%)": 20,
    "out:Seasonal Variation Payback": 5,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 56.157504,
    "out:EL CO2": 1.659341,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 1.659341,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00000000100001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 127.871325,
    "out:DHW (DH)": 28.356,
    "out:Property Electricity": 12.57,
    "out:Electricity (inc PV)": 12.57,
    "out:Total Energy Use Pre PV": 168.57,
    "out:Total Energy Use Post PV": 168.57,
    "out:Primary Energy": 133.981475,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 0.708543,
    "out:CO2 Operational/m2": 57.816845,
    "out:Total CO2/m2": 58.525388,
    "out:Total CO2 (tons)": 186.344779,
    "out:Klimatpaverkan": -13.790642,
    "out:Initial Cost/MSEK": 0.39726368749999996,
    "out:Running cost/(Apt SEK y)": 33618.957447,
    "out:Running Cost over RSP/MSEK": 12.35177,
    "out:LCP/MSEK": 0.655686,
    "out:ROI% old": 37.864379,
    "out:Payback discounted": 3,
    "out:Atemp": 3184,
    "out:Umean": 1,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 169,
    "out:Energy use kWh/m2": 169,
    "out:Energy savings %": 8.3,
    "out:Op energy cost/MSEK": 90,
    "out:El price/MSEK": 55,
    "out:Heating price/MSEK": 30,
    "out:DH+ DHW/kWh/m2": 156,
    "out:El bought/kWh/m2": 879.9,
    "out:Return %": 34,
    "out:Return/kSEK/y": 135,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 28.356,
    "out:EAHP (El)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 11,
    "out:Payback Time": 9.090909,
    "out:DH kWh savings": 42487.283216,
    "out:EL kWh savings": 2.2624e-11,
    "out:Sewage heat recovery cost": 273216,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 42487.28,
    "out:DH kr savings": 42487.28321644798,
    "out:El kr savings": 4.524734779920436e-11,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 407142.177573,
    "out:% savings (space heating)": 0,
    "out:Total_Water Heating (DH)": 90285.476835,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 127.87,
    "out:Etvv": 17,
    "out:Ef": 12.57,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 397263.68749999994,
    "out:Seasonal Variation ROI (%)": 10,
    "out:Seasonal Variation Payback": 10,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 56.157504,
    "out:EL CO2": 1.659341,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 1.659341,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00000000100001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 122.33172,
    "out:DHW (DH)": 28.356,
    "out:Property Electricity": 12.57,
    "out:Electricity (inc PV)": 12.57,
    "out:Total Energy Use Pre PV": 163.57,
    "out:Total Energy Use Post PV": 163.57,
    "out:Primary Energy": 129.672893,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 0.708543,
    "out:CO2 Operational/m2": 56.016925,
    "out:Total CO2/m2": 56.725468,
    "out:Total CO2 (tons)": 180.613836,
    "out:Klimatpaverkan": -19.521585,
    "out:Initial Cost/MSEK": 0.4768636624999999,
    "out:Running cost/(Apt SEK y)": 32596.021277,
    "out:Running Cost over RSP/MSEK": 11.97571,
    "out:LCP/MSEK": 0.952146,
    "out:ROI% old": 42.80978,
    "out:Payback discounted": 3,
    "out:Atemp": 3184,
    "out:Umean": 1,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 164,
    "out:Energy use kWh/m2": 164,
    "out:Energy savings %": 11.6,
    "out:Op energy cost/MSEK": 88,
    "out:El price/MSEK": 53,
    "out:Heating price/MSEK": 29,
    "out:DH+ DHW/kWh/m2": 151,
    "out:El bought/kWh/m2": 879.9,
    "out:Return %": 38,
    "out:Return/kSEK/y": 183,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 28.356,
    "out:EAHP (El)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 13,
    "out:Payback Time": 7.692308,
    "out:DH kWh savings": 60125.382458,
    "out:EL kWh savings": 2.2624e-11,
    "out:Sewage heat recovery cost": 273216,
    "out:Balancing hydronic system cost": 79599.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 60125.38,
    "out:DH kr savings": 60125.38245830575,
    "out:El kr savings": 4.524734779920436e-11,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 389504.078331,
    "out:% savings (space heating)": 4.332172,
    "out:Total_Water Heating (DH)": 90285.476835,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 122.33,
    "out:Etvv": 17,
    "out:Ef": 12.57,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 476863.6624999999,
    "out:Seasonal Variation ROI (%)": 11,
    "out:Seasonal Variation Payback": 9.090909,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 54.357584,
    "out:EL CO2": 1.659341,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 1.659341,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00000000100100000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 1,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 127.871325,
    "out:DHW (DH)": 33.36,
    "out:Property Electricity": 9.976115,
    "out:Electricity (inc PV)": 9.976115,
    "out:Total Energy Use Pre PV": 170.976115,
    "out:Total Energy Use Post PV": 170.976115,
    "out:Primary Energy": 131.412482,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 0.184502,
    "out:CO2 Operational/m2": 59.274351,
    "out:Total CO2/m2": 59.458853,
    "out:Total CO2 (tons)": 189.316931,
    "out:Klimatpaverkan": -10.81849,
    "out:Initial Cost/MSEK": 0.27084055901982496,
    "out:Running cost/(Apt SEK y)": 34290.468085,
    "out:Running Cost over RSP/MSEK": 12.60012,
    "out:LCP/MSEK": 0.533759,
    "out:ROI% old": 42.439307,
    "out:Payback discounted": 3,
    "out:Atemp": 3184,
    "out:Umean": 1,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 171,
    "out:Energy use kWh/m2": 171,
    "out:Energy savings %": 7,
    "out:Op energy cost/MSEK": 92,
    "out:El price/MSEK": 56,
    "out:Heating price/MSEK": 31,
    "out:DH+ DHW/kWh/m2": 161,
    "out:El bought/kWh/m2": 698.32805,
    "out:Return %": 38,
    "out:Return/kSEK/y": 103,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 33.36,
    "out:EAHP (El)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 16,
    "out:Payback Time": 6.25,
    "out:DH kWh savings": 26554.55201,
    "out:EL kWh savings": 8258.927355,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 43072.41,
    "out:DH kr savings": 26554.55201028001,
    "out:El kr savings": 16517.854710116386,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 407142.177573,
    "out:% savings (space heating)": 0,
    "out:Total_Water Heating (DH)": 106218.208041,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 127.87,
    "out:Etvv": 20,
    "out:Ef": 9.98,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 270840.55901982496,
    "out:Seasonal Variation ROI (%)": 15,
    "out:Seasonal Variation Payback": 6.666667,
    "out:New AHU Cost/SEK": 146792.87151982498,
    "out:DH CO2": 57.957424,
    "out:EL CO2": 1.316927,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 1.316927,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00000000100100100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 1,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 122.33172,
    "out:DHW (DH)": 33.36,
    "out:Property Electricity": 9.976115,
    "out:Electricity (inc PV)": 9.976115,
    "out:Total Energy Use Pre PV": 165.976115,
    "out:Total Energy Use Post PV": 165.976115,
    "out:Primary Energy": 127.1039,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 0.184502,
    "out:CO2 Operational/m2": 57.474431,
    "out:Total CO2/m2": 57.658933,
    "out:Total CO2 (tons)": 183.585987,
    "out:Klimatpaverkan": -16.549434,
    "out:Initial Cost/MSEK": 0.350440534019825,
    "out:Running cost/(Apt SEK y)": 33267.531915,
    "out:Running Cost over RSP/MSEK": 12.22407,
    "out:LCP/MSEK": 0.830209,
    "out:ROI% old": 48.129217,
    "out:Payback discounted": 3,
    "out:Atemp": 3184,
    "out:Umean": 1,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 166,
    "out:Energy use kWh/m2": 166,
    "out:Energy savings %": 10.2,
    "out:Op energy cost/MSEK": 89,
    "out:El price/MSEK": 54,
    "out:Heating price/MSEK": 30,
    "out:DH+ DHW/kWh/m2": 156,
    "out:El bought/kWh/m2": 698.32805,
    "out:Return %": 43,
    "out:Return/kSEK/y": 151,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 33.36,
    "out:EAHP (El)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 17,
    "out:Payback Time": 5.882353,
    "out:DH kWh savings": 44192.651252,
    "out:EL kWh savings": 8258.927355,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 79599.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 60710.51,
    "out:DH kr savings": 44192.65125213769,
    "out:El kr savings": 16517.854710116386,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 389504.078331,
    "out:% savings (space heating)": 4.332172,
    "out:Total_Water Heating (DH)": 106218.208041,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 122.33,
    "out:Etvv": 20,
    "out:Ef": 9.98,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 350440.534019825,
    "out:Seasonal Variation ROI (%)": 16,
    "out:Seasonal Variation Payback": 6.25,
    "out:New AHU Cost/SEK": 146792.87151982498,
    "out:DH CO2": 56.157504,
    "out:EL CO2": 1.316927,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 1.316927,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00000000100101000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 1,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 127.871325,
    "out:DHW (DH)": 28.356,
    "out:Property Electricity": 9.976115,
    "out:Electricity (inc PV)": 9.976115,
    "out:Total Energy Use Pre PV": 165.976115,
    "out:Total Energy Use Post PV": 165.976115,
    "out:Primary Energy": 129.312482,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 0.893045,
    "out:CO2 Operational/m2": 57.474431,
    "out:Total CO2/m2": 58.367476,
    "out:Total CO2 (tons)": 185.841988,
    "out:Klimatpaverkan": -14.293433,
    "out:Initial Cost/MSEK": 0.544056559019825,
    "out:Running cost/(Apt SEK y)": 33267.531915,
    "out:Running Cost over RSP/MSEK": 12.22407,
    "out:LCP/MSEK": 0.636593,
    "out:ROI% old": 31.001241,
    "out:Payback discounted": 4,
    "out:Atemp": 3184,
    "out:Umean": 1,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 166,
    "out:Energy use kWh/m2": 166,
    "out:Energy savings %": 10.2,
    "out:Op energy cost/MSEK": 89,
    "out:El price/MSEK": 54,
    "out:Heating price/MSEK": 30,
    "out:DH+ DHW/kWh/m2": 156,
    "out:El bought/kWh/m2": 698.32805,
    "out:Return %": 28,
    "out:Return/kSEK/y": 151,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 28.356,
    "out:EAHP (El)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 11,
    "out:Payback Time": 9.090909,
    "out:DH kWh savings": 42487.283216,
    "out:EL kWh savings": 8258.927355,
    "out:Sewage heat recovery cost": 273216,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 59005.14,
    "out:DH kr savings": 42487.28321644798,
    "out:El kr savings": 16517.854710116386,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 407142.177573,
    "out:% savings (space heating)": 0,
    "out:Total_Water Heating (DH)": 90285.476835,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 127.87,
    "out:Etvv": 17,
    "out:Ef": 9.98,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 544056.559019825,
    "out:Seasonal Variation ROI (%)": 10,
    "out:Seasonal Variation Payback": 10,
    "out:New AHU Cost/SEK": 146792.87151982498,
    "out:DH CO2": 56.157504,
    "out:EL CO2": 1.316927,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 1.316927,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00000000100101100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 1,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 122.33172,
    "out:DHW (DH)": 28.356,
    "out:Property Electricity": 9.976115,
    "out:Electricity (inc PV)": 9.976115,
    "out:Total Energy Use Pre PV": 160.976115,
    "out:Total Energy Use Post PV": 160.976115,
    "out:Primary Energy": 125.0039,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 0.893045,
    "out:CO2 Operational/m2": 55.674511,
    "out:Total CO2/m2": 56.567556,
    "out:Total CO2 (tons)": 180.111044,
    "out:Klimatpaverkan": -20.024377,
    "out:Initial Cost/MSEK": 0.6236565340198249,
    "out:Running cost/(Apt SEK y)": 32244.574468,
    "out:Running Cost over RSP/MSEK": 11.84801,
    "out:LCP/MSEK": 0.933053,
    "out:ROI% old": 35.658593,
    "out:Payback discounted": 4,
    "out:Atemp": 3184,
    "out:Umean": 1,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 161,
    "out:Energy use kWh/m2": 161,
    "out:Energy savings %": 13.7,
    "out:Op energy cost/MSEK": 87,
    "out:El price/MSEK": 52,
    "out:Heating price/MSEK": 29,
    "out:DH+ DHW/kWh/m2": 151,
    "out:El bought/kWh/m2": 698.32805,
    "out:Return %": 32,
    "out:Return/kSEK/y": 199,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 28.356,
    "out:EAHP (El)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 12,
    "out:Payback Time": 8.333333,
    "out:DH kWh savings": 60125.382458,
    "out:EL kWh savings": 8258.927355,
    "out:Sewage heat recovery cost": 273216,
    "out:Balancing hydronic system cost": 79599.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 76643.24,
    "out:DH kr savings": 60125.38245830575,
    "out:El kr savings": 16517.854710116386,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 389504.078331,
    "out:% savings (space heating)": 4.332172,
    "out:Total_Water Heating (DH)": 90285.476835,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 122.33,
    "out:Etvv": 17,
    "out:Ef": 9.98,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 623656.5340198249,
    "out:Seasonal Variation ROI (%)": 11,
    "out:Seasonal Variation Payback": 9.090909,
    "out:New AHU Cost/SEK": 146792.87151982498,
    "out:DH CO2": 54.357584,
    "out:EL CO2": 1.316927,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 1.316927,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00000000110000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 127.871325,
    "out:DHW (DH)": 33.36,
    "out:Property Electricity": 12.57,
    "out:Electricity (inc PV)": 8.051517,
    "out:Total Energy Use Pre PV": 173.57,
    "out:Total Energy Use Post PV": 169.051517,
    "out:Primary Energy": 127.948206,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 14.7391,
    "out:CO2 Operational/m2": 14.356103,
    "out:Total CO2/m2": 29.095203,
    "out:Total CO2 (tons)": 92.639098,
    "out:Klimatpaverkan": -107.496323,
    "out:Initial Cost/MSEK": 0.9142680817225001,
    "out:Running cost/(Apt SEK y)": 33133.702128,
    "out:Running Cost over RSP/MSEK": 12.18845,
    "out:LCP/MSEK": 0.302002,
    "out:ROI% old": 19.004585,
    "out:Payback discounted": 6,
    "out:Atemp": 3184,
    "out:Umean": 1,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 169,
    "out:Energy use kWh/m2": 169,
    "out:Energy savings %": 8.3,
    "out:Op energy cost/MSEK": 90,
    "out:El price/MSEK": 53,
    "out:Heating price/MSEK": 31,
    "out:DH+ DHW/kWh/m2": 161,
    "out:El bought/kWh/m2": 568.258452,
    "out:Return %": 17,
    "out:Return/kSEK/y": 157,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 33.36,
    "out:EAHP (El)": 0,
    "out:PV (kr/kWp)": 17461.56255567083,
    "out:PV (kWp installed)": 45.25485,
    "out:PV (m2 panels)": 237.433632,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 42461.284501,
    "out:PV (kWh sold)": 28074.439514,
    "out:PV (% sold (El))": 66.117735,
    "out:PV (kWh self-consumed)": 14386.844987,
    "out:PV (ratio sold/self-consumed)": 1.951397,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 11,
    "out:Payback Time": 9.090909,
    "out:DH kWh savings": 26554.55201,
    "out:EL kWh savings": 14386.845543,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 97439.9,
    "out:DH kr savings": 26554.55201028001,
    "out:El kr savings": 28773.691086586638,
    "out:El kr sold": 42111.65927092506,
    "out:El kr saved": 28773.689974,
    "out:El kr return": 70885.34924492506,
    "out:% solar/total": 36,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 407142.177573,
    "out:% savings (space heating)": 0,
    "out:Total_Water Heating (DH)": 106218.208041,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 127.87,
    "out:Etvv": 20,
    "out:Ef": 8.05,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 914268.0817225,
    "out:Seasonal Variation ROI (%)": 10,
    "out:Seasonal Variation Payback": 10,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 57.957424,
    "out:EL CO2": -43.601321,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -44.666212,
    "out:Bought CO2": 1.064891,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00000000110000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 122.33172,
    "out:DHW (DH)": 33.36,
    "out:Property Electricity": 12.57,
    "out:Electricity (inc PV)": 8.051517,
    "out:Total Energy Use Pre PV": 168.57,
    "out:Total Energy Use Post PV": 164.051517,
    "out:Primary Energy": 123.639624,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 14.7391,
    "out:CO2 Operational/m2": 12.556183,
    "out:Total CO2/m2": 27.295283,
    "out:Total CO2 (tons)": 86.908155,
    "out:Klimatpaverkan": -113.227266,
    "out:Initial Cost/MSEK": 0.9938680567225,
    "out:Running cost/(Apt SEK y)": 32110.765957,
    "out:Running Cost over RSP/MSEK": 11.8124,
    "out:LCP/MSEK": 0.598452,
    "out:ROI% old": 22.887775,
    "out:Payback discounted": 5,
    "out:Atemp": 3184,
    "out:Umean": 1,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 164,
    "out:Energy use kWh/m2": 164,
    "out:Energy savings %": 11.6,
    "out:Op energy cost/MSEK": 87,
    "out:El price/MSEK": 52,
    "out:Heating price/MSEK": 30,
    "out:DH+ DHW/kWh/m2": 156,
    "out:El bought/kWh/m2": 568.258452,
    "out:Return %": 21,
    "out:Return/kSEK/y": 206,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 33.36,
    "out:EAHP (El)": 0,
    "out:PV (kr/kWp)": 17461.56255567083,
    "out:PV (kWp installed)": 45.25485,
    "out:PV (m2 panels)": 237.433632,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 42461.284501,
    "out:PV (kWh sold)": 28074.439514,
    "out:PV (% sold (El))": 66.117735,
    "out:PV (kWh self-consumed)": 14386.844987,
    "out:PV (ratio sold/self-consumed)": 1.951397,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 12,
    "out:Payback Time": 8.333333,
    "out:DH kWh savings": 44192.651252,
    "out:EL kWh savings": 14386.845543,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 79599.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 115078,
    "out:DH kr savings": 44192.65125213769,
    "out:El kr savings": 28773.691086586638,
    "out:El kr sold": 42111.65927092506,
    "out:El kr saved": 28773.689974,
    "out:El kr return": 70885.34924492506,
    "out:% solar/total": 36,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 389504.078331,
    "out:% savings (space heating)": 4.332172,
    "out:Total_Water Heating (DH)": 106218.208041,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 122.33,
    "out:Etvv": 20,
    "out:Ef": 8.05,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 993868.0567225,
    "out:Seasonal Variation ROI (%)": 11,
    "out:Seasonal Variation Payback": 9.090909,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 56.157504,
    "out:EL CO2": -43.601321,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -44.666212,
    "out:Bought CO2": 1.064891,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00000000110001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 127.871325,
    "out:DHW (DH)": 28.356,
    "out:Property Electricity": 12.57,
    "out:Electricity (inc PV)": 8.051517,
    "out:Total Energy Use Pre PV": 168.57,
    "out:Total Energy Use Post PV": 164.051517,
    "out:Primary Energy": 125.848206,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 15.447643,
    "out:CO2 Operational/m2": 12.556183,
    "out:Total CO2/m2": 28.003826,
    "out:Total CO2 (tons)": 89.164155,
    "out:Klimatpaverkan": -110.971266,
    "out:Initial Cost/MSEK": 1.1874840817225,
    "out:Running cost/(Apt SEK y)": 32110.765957,
    "out:Running Cost over RSP/MSEK": 11.8124,
    "out:LCP/MSEK": 0.404836,
    "out:ROI% old": 19.155986,
    "out:Payback discounted": 6,
    "out:Atemp": 3184,
    "out:Umean": 1,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 164,
    "out:Energy use kWh/m2": 164,
    "out:Energy savings %": 11.6,
    "out:Op energy cost/MSEK": 87,
    "out:El price/MSEK": 52,
    "out:Heating price/MSEK": 30,
    "out:DH+ DHW/kWh/m2": 156,
    "out:El bought/kWh/m2": 568.258452,
    "out:Return %": 17,
    "out:Return/kSEK/y": 206,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 28.356,
    "out:EAHP (El)": 0,
    "out:PV (kr/kWp)": 17461.56255567083,
    "out:PV (kWp installed)": 45.25485,
    "out:PV (m2 panels)": 237.433632,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 42461.284501,
    "out:PV (kWh sold)": 28074.439514,
    "out:PV (% sold (El))": 66.117735,
    "out:PV (kWh self-consumed)": 14386.844987,
    "out:PV (ratio sold/self-consumed)": 1.951397,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 10,
    "out:Payback Time": 10,
    "out:DH kWh savings": 42487.283216,
    "out:EL kWh savings": 14386.845543,
    "out:Sewage heat recovery cost": 273216,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 113372.63,
    "out:DH kr savings": 42487.28321644798,
    "out:El kr savings": 28773.691086586638,
    "out:El kr sold": 42111.65927092506,
    "out:El kr saved": 28773.689974,
    "out:El kr return": 70885.34924492506,
    "out:% solar/total": 36,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 407142.177573,
    "out:% savings (space heating)": 0,
    "out:Total_Water Heating (DH)": 90285.476835,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 127.87,
    "out:Etvv": 17,
    "out:Ef": 8.05,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 1187484.0817225,
    "out:Seasonal Variation ROI (%)": 9,
    "out:Seasonal Variation Payback": 11.111111,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 56.157504,
    "out:EL CO2": -43.601321,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -44.666212,
    "out:Bought CO2": 1.064891,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00000000110001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 122.33172,
    "out:DHW (DH)": 28.356,
    "out:Property Electricity": 12.57,
    "out:Electricity (inc PV)": 8.051517,
    "out:Total Energy Use Pre PV": 163.57,
    "out:Total Energy Use Post PV": 159.051517,
    "out:Primary Energy": 121.539624,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 15.447643,
    "out:CO2 Operational/m2": 10.756263,
    "out:Total CO2/m2": 26.203906,
    "out:Total CO2 (tons)": 83.433212,
    "out:Klimatpaverkan": -116.702209,
    "out:Initial Cost/MSEK": 1.2670840567225,
    "out:Running cost/(Apt SEK y)": 31087.829787,
    "out:Running Cost over RSP/MSEK": 11.43634,
    "out:LCP/MSEK": 0.701296,
    "out:ROI% old": 22.192462,
    "out:Payback discounted": 6,
    "out:Atemp": 3184,
    "out:Umean": 1,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 159,
    "out:Energy use kWh/m2": 159,
    "out:Energy savings %": 15.1,
    "out:Op energy cost/MSEK": 84,
    "out:El price/MSEK": 50,
    "out:Heating price/MSEK": 29,
    "out:DH+ DHW/kWh/m2": 151,
    "out:El bought/kWh/m2": 568.258452,
    "out:Return %": 20,
    "out:Return/kSEK/y": 254,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 28.356,
    "out:EAHP (El)": 0,
    "out:PV (kr/kWp)": 17461.56255567083,
    "out:PV (kWp installed)": 45.25485,
    "out:PV (m2 panels)": 237.433632,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 42461.284501,
    "out:PV (kWh sold)": 28074.439514,
    "out:PV (% sold (El))": 66.117735,
    "out:PV (kWh self-consumed)": 14386.844987,
    "out:PV (ratio sold/self-consumed)": 1.951397,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 10,
    "out:Payback Time": 10,
    "out:DH kWh savings": 60125.382458,
    "out:EL kWh savings": 14386.845543,
    "out:Sewage heat recovery cost": 273216,
    "out:Balancing hydronic system cost": 79599.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 131010.73,
    "out:DH kr savings": 60125.38245830575,
    "out:El kr savings": 28773.691086586638,
    "out:El kr sold": 42111.65927092506,
    "out:El kr saved": 28773.689974,
    "out:El kr return": 70885.34924492506,
    "out:% solar/total": 36,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 389504.078331,
    "out:% savings (space heating)": 4.332172,
    "out:Total_Water Heating (DH)": 90285.476835,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 122.33,
    "out:Etvv": 17,
    "out:Ef": 8.05,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 1267084.0567225,
    "out:Seasonal Variation ROI (%)": 10,
    "out:Seasonal Variation Payback": 10,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 54.357584,
    "out:EL CO2": -43.601321,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -44.666212,
    "out:Bought CO2": 1.064891,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00000000110100000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 1,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 127.871325,
    "out:DHW (DH)": 33.36,
    "out:Property Electricity": 9.976115,
    "out:Electricity (inc PV)": 6.231337,
    "out:Total Energy Use Pre PV": 170.976115,
    "out:Total Energy Use Post PV": 167.231337,
    "out:Primary Energy": 124.671882,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 14.923602,
    "out:CO2 Operational/m2": 10.125975,
    "out:Total CO2/m2": 25.049577,
    "out:Total CO2 (tons)": 79.757829,
    "out:Klimatpaverkan": -120.377592,
    "out:Initial Cost/MSEK": 1.061060953242325,
    "out:Running cost/(Apt SEK y)": 32808.468085,
    "out:Running Cost over RSP/MSEK": 12.07022,
    "out:LCP/MSEK": 0.273439,
    "out:ROI% old": 17.967192,
    "out:Payback discounted": 7,
    "out:Atemp": 3184,
    "out:Umean": 1,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 167,
    "out:Energy use kWh/m2": 167,
    "out:Energy savings %": 9.6,
    "out:Op energy cost/MSEK": 89,
    "out:El price/MSEK": 52,
    "out:Heating price/MSEK": 31,
    "out:DH+ DHW/kWh/m2": 161,
    "out:El bought/kWh/m2": 439.497378,
    "out:Return %": 16,
    "out:Return/kSEK/y": 173,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 33.36,
    "out:EAHP (El)": 0,
    "out:PV (kr/kWp)": 17461.56255567083,
    "out:PV (kWp installed)": 45.25485,
    "out:PV (m2 panels)": 237.433632,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 42461.284501,
    "out:PV (kWh sold)": 30537.914073,
    "out:PV (% sold (El))": 71.919431,
    "out:PV (kWh self-consumed)": 11923.370428,
    "out:PV (ratio sold/self-consumed)": 2.561181,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 11,
    "out:Payback Time": 9.090909,
    "out:DH kWh savings": 26554.55201,
    "out:EL kWh savings": 20182.29692,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 112726.02,
    "out:DH kr savings": 26554.55201028001,
    "out:El kr savings": 40364.59383912171,
    "out:El kr sold": 45806.87111012268,
    "out:El kr saved": 23846.740856,
    "out:El kr return": 69653.61196612268,
    "out:% solar/total": 30,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 407142.177573,
    "out:% savings (space heating)": 0,
    "out:Total_Water Heating (DH)": 106218.208041,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 127.87,
    "out:Etvv": 20,
    "out:Ef": 6.23,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 1061060.953242325,
    "out:Seasonal Variation ROI (%)": 10,
    "out:Seasonal Variation Payback": 10,
    "out:New AHU Cost/SEK": 146792.87151982498,
    "out:DH CO2": 57.957424,
    "out:EL CO2": -47.831449,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -48.655512,
    "out:Bought CO2": 0.824063,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00000000110100100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 1,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 122.33172,
    "out:DHW (DH)": 33.36,
    "out:Property Electricity": 9.976115,
    "out:Electricity (inc PV)": 6.231337,
    "out:Total Energy Use Pre PV": 165.976115,
    "out:Total Energy Use Post PV": 162.231337,
    "out:Primary Energy": 120.3633,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 14.923602,
    "out:CO2 Operational/m2": 8.326055,
    "out:Total CO2/m2": 23.249657,
    "out:Total CO2 (tons)": 74.026886,
    "out:Klimatpaverkan": -126.108535,
    "out:Initial Cost/MSEK": 1.140660928242325,
    "out:Running cost/(Apt SEK y)": 31785.531915,
    "out:Running Cost over RSP/MSEK": 11.69417,
    "out:LCP/MSEK": 0.569889,
    "out:ROI% old": 21.423043,
    "out:Payback discounted": 6,
    "out:Atemp": 3184,
    "out:Umean": 1,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 162,
    "out:Energy use kWh/m2": 162,
    "out:Energy savings %": 13,
    "out:Op energy cost/MSEK": 86,
    "out:El price/MSEK": 51,
    "out:Heating price/MSEK": 30,
    "out:DH+ DHW/kWh/m2": 156,
    "out:El bought/kWh/m2": 439.497378,
    "out:Return %": 19,
    "out:Return/kSEK/y": 221,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 33.36,
    "out:EAHP (El)": 0,
    "out:PV (kr/kWp)": 17461.56255567083,
    "out:PV (kWp installed)": 45.25485,
    "out:PV (m2 panels)": 237.433632,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 42461.284501,
    "out:PV (kWh sold)": 30537.914073,
    "out:PV (% sold (El))": 71.919431,
    "out:PV (kWh self-consumed)": 11923.370428,
    "out:PV (ratio sold/self-consumed)": 2.561181,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 11,
    "out:Payback Time": 9.090909,
    "out:DH kWh savings": 44192.651252,
    "out:EL kWh savings": 20182.29692,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 79599.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 130364.12,
    "out:DH kr savings": 44192.65125213769,
    "out:El kr savings": 40364.59383912171,
    "out:El kr sold": 45806.87111012268,
    "out:El kr saved": 23846.740856,
    "out:El kr return": 69653.61196612268,
    "out:% solar/total": 30,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 389504.078331,
    "out:% savings (space heating)": 4.332172,
    "out:Total_Water Heating (DH)": 106218.208041,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 122.33,
    "out:Etvv": 20,
    "out:Ef": 6.23,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 1140660.928242325,
    "out:Seasonal Variation ROI (%)": 11,
    "out:Seasonal Variation Payback": 9.090909,
    "out:New AHU Cost/SEK": 146792.87151982498,
    "out:DH CO2": 56.157504,
    "out:EL CO2": -47.831449,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -48.655512,
    "out:Bought CO2": 0.824063,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00000000110101000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 1,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 127.871325,
    "out:DHW (DH)": 28.356,
    "out:Property Electricity": 9.976115,
    "out:Electricity (inc PV)": 6.231337,
    "out:Total Energy Use Pre PV": 165.976115,
    "out:Total Energy Use Post PV": 162.231337,
    "out:Primary Energy": 122.571882,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 15.632145,
    "out:CO2 Operational/m2": 8.326055,
    "out:Total CO2/m2": 23.9582,
    "out:Total CO2 (tons)": 76.282886,
    "out:Klimatpaverkan": -123.852535,
    "out:Initial Cost/MSEK": 1.3342769532423253,
    "out:Running cost/(Apt SEK y)": 31785.531915,
    "out:Running Cost over RSP/MSEK": 11.69417,
    "out:LCP/MSEK": 0.376273,
    "out:ROI% old": 18.31436,
    "out:Payback discounted": 7,
    "out:Atemp": 3184,
    "out:Umean": 1,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 162,
    "out:Energy use kWh/m2": 162,
    "out:Energy savings %": 13,
    "out:Op energy cost/MSEK": 86,
    "out:El price/MSEK": 51,
    "out:Heating price/MSEK": 30,
    "out:DH+ DHW/kWh/m2": 156,
    "out:El bought/kWh/m2": 439.497378,
    "out:Return %": 17,
    "out:Return/kSEK/y": 221,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 28.356,
    "out:EAHP (El)": 0,
    "out:PV (kr/kWp)": 17461.56255567083,
    "out:PV (kWp installed)": 45.25485,
    "out:PV (m2 panels)": 237.433632,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 42461.284501,
    "out:PV (kWh sold)": 30537.914073,
    "out:PV (% sold (El))": 71.919431,
    "out:PV (kWh self-consumed)": 11923.370428,
    "out:PV (ratio sold/self-consumed)": 2.561181,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 10,
    "out:Payback Time": 10,
    "out:DH kWh savings": 42487.283216,
    "out:EL kWh savings": 20182.29692,
    "out:Sewage heat recovery cost": 273216,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 128658.75,
    "out:DH kr savings": 42487.28321644798,
    "out:El kr savings": 40364.59383912171,
    "out:El kr sold": 45806.87111012268,
    "out:El kr saved": 23846.740856,
    "out:El kr return": 69653.61196612268,
    "out:% solar/total": 30,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 407142.177573,
    "out:% savings (space heating)": 0,
    "out:Total_Water Heating (DH)": 90285.476835,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 127.87,
    "out:Etvv": 17,
    "out:Ef": 6.23,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 1334276.9532423252,
    "out:Seasonal Variation ROI (%)": 9,
    "out:Seasonal Variation Payback": 11.111111,
    "out:New AHU Cost/SEK": 146792.87151982498,
    "out:DH CO2": 56.157504,
    "out:EL CO2": -47.831449,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -48.655512,
    "out:Bought CO2": 0.824063,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00000000110101100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 1,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 122.33172,
    "out:DHW (DH)": 28.356,
    "out:Property Electricity": 9.976115,
    "out:Electricity (inc PV)": 6.231337,
    "out:Total Energy Use Pre PV": 160.976115,
    "out:Total Energy Use Post PV": 157.231337,
    "out:Primary Energy": 118.2633,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 15.632145,
    "out:CO2 Operational/m2": 6.526135,
    "out:Total CO2/m2": 22.15828,
    "out:Total CO2 (tons)": 70.551942,
    "out:Klimatpaverkan": -129.583479,
    "out:Initial Cost/MSEK": 1.4138769282423251,
    "out:Running cost/(Apt SEK y)": 30762.595745,
    "out:Running Cost over RSP/MSEK": 11.31811,
    "out:LCP/MSEK": 0.672733,
    "out:ROI% old": 21.082963,
    "out:Payback discounted": 6,
    "out:Atemp": 3184,
    "out:Umean": 1,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 157,
    "out:Energy use kWh/m2": 157,
    "out:Energy savings %": 16.6,
    "out:Op energy cost/MSEK": 83,
    "out:El price/MSEK": 49,
    "out:Heating price/MSEK": 29,
    "out:DH+ DHW/kWh/m2": 151,
    "out:El bought/kWh/m2": 439.497378,
    "out:Return %": 19,
    "out:Return/kSEK/y": 269,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 28.356,
    "out:EAHP (El)": 0,
    "out:PV (kr/kWp)": 17461.56255567083,
    "out:PV (kWp installed)": 45.25485,
    "out:PV (m2 panels)": 237.433632,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 42461.284501,
    "out:PV (kWh sold)": 30537.914073,
    "out:PV (% sold (El))": 71.919431,
    "out:PV (kWh self-consumed)": 11923.370428,
    "out:PV (ratio sold/self-consumed)": 2.561181,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 10,
    "out:Payback Time": 10,
    "out:DH kWh savings": 60125.382458,
    "out:EL kWh savings": 20182.29692,
    "out:Sewage heat recovery cost": 273216,
    "out:Balancing hydronic system cost": 79599.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 146296.85,
    "out:DH kr savings": 60125.38245830575,
    "out:El kr savings": 40364.59383912171,
    "out:El kr sold": 45806.87111012268,
    "out:El kr saved": 23846.740856,
    "out:El kr return": 69653.61196612268,
    "out:% solar/total": 30,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 389504.078331,
    "out:% savings (space heating)": 4.332172,
    "out:Total_Water Heating (DH)": 90285.476835,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 122.33,
    "out:Etvv": 17,
    "out:Ef": 6.23,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 1413876.928242325,
    "out:Seasonal Variation ROI (%)": 10,
    "out:Seasonal Variation Payback": 10,
    "out:New AHU Cost/SEK": 146792.87151982498,
    "out:DH CO2": 54.357584,
    "out:EL CO2": -47.831449,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -48.655512,
    "out:Bought CO2": 0.824063,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00000000120000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 127.871325,
    "out:DHW (DH)": 33.36,
    "out:Property Electricity": 12.57,
    "out:Electricity (inc PV)": 7.530921,
    "out:Total Energy Use Pre PV": 173.57,
    "out:Total Energy Use Post PV": 168.530921,
    "out:Primary Energy": 127.011133,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 29.478199,
    "out:CO2 Operational/m2": -50.999214,
    "out:Total CO2/m2": -21.521015,
    "out:Total CO2 (tons)": -68.522891,
    "out:Klimatpaverkan": -268.658312,
    "out:Initial Cost/MSEK": 1.7044814334437497,
    "out:Running cost/(Apt SEK y)": 31760.93617,
    "out:Running Cost over RSP/MSEK": 11.69802,
    "out:LCP/MSEK": 0.002219,
    "out:ROI% old": 14.304309,
    "out:Payback discounted": 8,
    "out:Atemp": 3184,
    "out:Umean": 1,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 169,
    "out:Energy use kWh/m2": 169,
    "out:Energy savings %": 8.3,
    "out:Op energy cost/MSEK": 87,
    "out:El price/MSEK": 50,
    "out:Heating price/MSEK": 31,
    "out:DH+ DHW/kWh/m2": 161,
    "out:El bought/kWh/m2": 530.25352,
    "out:Return %": 13,
    "out:Return/kSEK/y": 222,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 33.36,
    "out:EAHP (El)": 0,
    "out:PV (kr/kWp)": 17461.48474631725,
    "out:PV (kWp installed)": 90.5097,
    "out:PV (m2 panels)": 474.867263,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 84922.568824,
    "out:PV (kWh sold)": 68878.145463,
    "out:PV (% sold (El))": 81.106997,
    "out:PV (kWh self-consumed)": 16044.423361,
    "out:PV (ratio sold/self-consumed)": 4.292965,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 10,
    "out:Payback Time": 10,
    "out:DH kWh savings": 26554.55201,
    "out:EL kWh savings": 16044.422709,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 161960.62,
    "out:DH kr savings": 26554.55201028001,
    "out:El kr savings": 32088.845417124598,
    "out:El kr sold": 103317.21819422109,
    "out:El kr saved": 32088.846722,
    "out:El kr return": 135406.06491622108,
    "out:% solar/total": 40,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 407142.177573,
    "out:% savings (space heating)": 0,
    "out:Total_Water Heating (DH)": 106218.208041,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 127.87,
    "out:Etvv": 20,
    "out:Ef": 7.53,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 1704481.4334437498,
    "out:Seasonal Variation ROI (%)": 9,
    "out:Seasonal Variation Payback": 11.111111,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 57.957424,
    "out:EL CO2": -108.956638,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -109.952146,
    "out:Bought CO2": 0.995508,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00000000120000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 122.33172,
    "out:DHW (DH)": 33.36,
    "out:Property Electricity": 12.57,
    "out:Electricity (inc PV)": 7.530921,
    "out:Total Energy Use Pre PV": 168.57,
    "out:Total Energy Use Post PV": 163.530921,
    "out:Primary Energy": 122.702551,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 29.478199,
    "out:CO2 Operational/m2": -52.799134,
    "out:Total CO2/m2": -23.320935,
    "out:Total CO2 (tons)": -74.253835,
    "out:Klimatpaverkan": -274.389256,
    "out:Initial Cost/MSEK": 1.78408140844375,
    "out:Running cost/(Apt SEK y)": 30737.978723,
    "out:Running Cost over RSP/MSEK": 11.32196,
    "out:LCP/MSEK": 0.298679,
    "out:ROI% old": 16.67733,
    "out:Payback discounted": 7,
    "out:Atemp": 3184,
    "out:Umean": 1,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 164,
    "out:Energy use kWh/m2": 164,
    "out:Energy savings %": 11.6,
    "out:Op energy cost/MSEK": 84,
    "out:El price/MSEK": 49,
    "out:Heating price/MSEK": 30,
    "out:DH+ DHW/kWh/m2": 156,
    "out:El bought/kWh/m2": 530.25352,
    "out:Return %": 15,
    "out:Return/kSEK/y": 270,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 33.36,
    "out:EAHP (El)": 0,
    "out:PV (kr/kWp)": 17461.48474631725,
    "out:PV (kWp installed)": 90.5097,
    "out:PV (m2 panels)": 474.867263,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 84922.568824,
    "out:PV (kWh sold)": 68878.145463,
    "out:PV (% sold (El))": 81.106997,
    "out:PV (kWh self-consumed)": 16044.423361,
    "out:PV (ratio sold/self-consumed)": 4.292965,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 10,
    "out:Payback Time": 10,
    "out:DH kWh savings": 44192.651252,
    "out:EL kWh savings": 16044.422709,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 79599.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 179598.71,
    "out:DH kr savings": 44192.65125213769,
    "out:El kr savings": 32088.845417124598,
    "out:El kr sold": 103317.21819422109,
    "out:El kr saved": 32088.846722,
    "out:El kr return": 135406.06491622108,
    "out:% solar/total": 40,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 389504.078331,
    "out:% savings (space heating)": 4.332172,
    "out:Total_Water Heating (DH)": 106218.208041,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 122.33,
    "out:Etvv": 20,
    "out:Ef": 7.53,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 1784081.4084437499,
    "out:Seasonal Variation ROI (%)": 10,
    "out:Seasonal Variation Payback": 10,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 56.157504,
    "out:EL CO2": -108.956638,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -109.952146,
    "out:Bought CO2": 0.995508,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00000000120001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 127.871325,
    "out:DHW (DH)": 28.356,
    "out:Property Electricity": 12.57,
    "out:Electricity (inc PV)": 7.530921,
    "out:Total Energy Use Pre PV": 168.57,
    "out:Total Energy Use Post PV": 163.530921,
    "out:Primary Energy": 124.911133,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 30.186742,
    "out:CO2 Operational/m2": -52.799134,
    "out:Total CO2/m2": -22.612392,
    "out:Total CO2 (tons)": -71.997834,
    "out:Klimatpaverkan": -272.133256,
    "out:Initial Cost/MSEK": 1.9776974334437498,
    "out:Running cost/(Apt SEK y)": 30737.978723,
    "out:Running Cost over RSP/MSEK": 11.32196,
    "out:LCP/MSEK": 0.105063,
    "out:ROI% old": 15.044624,
    "out:Payback discounted": 8,
    "out:Atemp": 3184,
    "out:Umean": 1,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 164,
    "out:Energy use kWh/m2": 164,
    "out:Energy savings %": 11.6,
    "out:Op energy cost/MSEK": 84,
    "out:El price/MSEK": 49,
    "out:Heating price/MSEK": 30,
    "out:DH+ DHW/kWh/m2": 156,
    "out:El bought/kWh/m2": 530.25352,
    "out:Return %": 14,
    "out:Return/kSEK/y": 270,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 28.356,
    "out:EAHP (El)": 0,
    "out:PV (kr/kWp)": 17461.48474631725,
    "out:PV (kWp installed)": 90.5097,
    "out:PV (m2 panels)": 474.867263,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 84922.568824,
    "out:PV (kWh sold)": 68878.145463,
    "out:PV (% sold (El))": 81.106997,
    "out:PV (kWh self-consumed)": 16044.423361,
    "out:PV (ratio sold/self-consumed)": 4.292965,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 9,
    "out:Payback Time": 11.111111,
    "out:DH kWh savings": 42487.283216,
    "out:EL kWh savings": 16044.422709,
    "out:Sewage heat recovery cost": 273216,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 177893.35,
    "out:DH kr savings": 42487.28321644798,
    "out:El kr savings": 32088.845417124598,
    "out:El kr sold": 103317.21819422109,
    "out:El kr saved": 32088.846722,
    "out:El kr return": 135406.06491622108,
    "out:% solar/total": 40,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 407142.177573,
    "out:% savings (space heating)": 0,
    "out:Total_Water Heating (DH)": 90285.476835,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 127.87,
    "out:Etvv": 17,
    "out:Ef": 7.53,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 1977697.4334437498,
    "out:Seasonal Variation ROI (%)": 9,
    "out:Seasonal Variation Payback": 11.111111,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 56.157504,
    "out:EL CO2": -108.956638,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -109.952146,
    "out:Bought CO2": 0.995508,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00000000120001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 122.33172,
    "out:DHW (DH)": 28.356,
    "out:Property Electricity": 12.57,
    "out:Electricity (inc PV)": 7.530921,
    "out:Total Energy Use Pre PV": 163.57,
    "out:Total Energy Use Post PV": 158.530921,
    "out:Primary Energy": 120.602551,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 30.186742,
    "out:CO2 Operational/m2": -54.599054,
    "out:Total CO2/m2": -24.412312,
    "out:Total CO2 (tons)": -77.728778,
    "out:Klimatpaverkan": -277.864199,
    "out:Initial Cost/MSEK": 2.05729740844375,
    "out:Running cost/(Apt SEK y)": 29715.042553,
    "out:Running Cost over RSP/MSEK": 10.94591,
    "out:LCP/MSEK": 0.401513,
    "out:ROI% old": 17.073787,
    "out:Payback discounted": 7,
    "out:Atemp": 3184,
    "out:Umean": 1,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 159,
    "out:Energy use kWh/m2": 159,
    "out:Energy savings %": 15.1,
    "out:Op energy cost/MSEK": 81,
    "out:El price/MSEK": 47,
    "out:Heating price/MSEK": 29,
    "out:DH+ DHW/kWh/m2": 151,
    "out:El bought/kWh/m2": 530.25352,
    "out:Return %": 15,
    "out:Return/kSEK/y": 318,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 28.356,
    "out:EAHP (El)": 0,
    "out:PV (kr/kWp)": 17461.48474631725,
    "out:PV (kWp installed)": 90.5097,
    "out:PV (m2 panels)": 474.867263,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 84922.568824,
    "out:PV (kWh sold)": 68878.145463,
    "out:PV (% sold (El))": 81.106997,
    "out:PV (kWh self-consumed)": 16044.423361,
    "out:PV (ratio sold/self-consumed)": 4.292965,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 10,
    "out:Payback Time": 10,
    "out:DH kWh savings": 60125.382458,
    "out:EL kWh savings": 16044.422709,
    "out:Sewage heat recovery cost": 273216,
    "out:Balancing hydronic system cost": 79599.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 195531.45,
    "out:DH kr savings": 60125.38245830575,
    "out:El kr savings": 32088.845417124598,
    "out:El kr sold": 103317.21819422109,
    "out:El kr saved": 32088.846722,
    "out:El kr return": 135406.06491622108,
    "out:% solar/total": 40,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 389504.078331,
    "out:% savings (space heating)": 4.332172,
    "out:Total_Water Heating (DH)": 90285.476835,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 122.33,
    "out:Etvv": 17,
    "out:Ef": 7.53,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 2057297.40844375,
    "out:Seasonal Variation ROI (%)": 9,
    "out:Seasonal Variation Payback": 11.111111,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 54.357584,
    "out:EL CO2": -108.956638,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -109.952146,
    "out:Bought CO2": 0.995508,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00000000120100000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 1,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 127.871325,
    "out:DHW (DH)": 33.36,
    "out:Property Electricity": 9.976115,
    "out:Electricity (inc PV)": 5.879188,
    "out:Total Energy Use Pre PV": 170.976115,
    "out:Total Energy Use Post PV": 166.879188,
    "out:Primary Energy": 124.038013,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 29.662701,
    "out:CO2 Operational/m2": -56.08494,
    "out:Total CO2/m2": -26.422239,
    "out:Total CO2 (tons)": -84.128384,
    "out:Klimatpaverkan": -284.263805,
    "out:Initial Cost/MSEK": 1.851274304963575,
    "out:Running cost/(Apt SEK y)": 31441.404255,
    "out:Running Cost over RSP/MSEK": 11.58188,
    "out:LCP/MSEK": -0.028434,
    "out:ROI% old": 14.066296,
    "out:Payback discounted": 9,
    "out:Atemp": 3184,
    "out:Umean": 1,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 167,
    "out:Energy use kWh/m2": 167,
    "out:Energy savings %": 9.6,
    "out:Op energy cost/MSEK": 86,
    "out:El price/MSEK": 49,
    "out:Heating price/MSEK": 31,
    "out:DH+ DHW/kWh/m2": 161,
    "out:El bought/kWh/m2": 413.65837,
    "out:Return %": 13,
    "out:Return/kSEK/y": 237,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 33.36,
    "out:EAHP (El)": 0,
    "out:PV (kr/kWp)": 17461.48474631725,
    "out:PV (kWp installed)": 90.5097,
    "out:PV (m2 panels)": 474.867263,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 84922.568824,
    "out:PV (kWh sold)": 71877.957046,
    "out:PV (% sold (El))": 84.639405,
    "out:PV (kWh self-consumed)": 13044.611778,
    "out:PV (ratio sold/self-consumed)": 5.510165,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 10,
    "out:Payback Time": 10,
    "out:DH kWh savings": 26554.55201,
    "out:EL kWh savings": 21303.538998,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 176978.57,
    "out:DH kr savings": 26554.55201028001,
    "out:El kr savings": 42607.077996404216,
    "out:El kr sold": 107816.93556907076,
    "out:El kr saved": 26089.223556,
    "out:El kr return": 133906.15912507076,
    "out:% solar/total": 33,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 407142.177573,
    "out:% savings (space heating)": 0,
    "out:Total_Water Heating (DH)": 106218.208041,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 127.87,
    "out:Etvv": 20,
    "out:Ef": 5.88,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 1851274.304963575,
    "out:Seasonal Variation ROI (%)": 9,
    "out:Seasonal Variation Payback": 11.111111,
    "out:New AHU Cost/SEK": 146792.87151982498,
    "out:DH CO2": 57.957424,
    "out:EL CO2": -114.042364,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -114.819396,
    "out:Bought CO2": 0.777032,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00000000120100100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 1,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 122.33172,
    "out:DHW (DH)": 33.36,
    "out:Property Electricity": 9.976115,
    "out:Electricity (inc PV)": 5.879188,
    "out:Total Energy Use Pre PV": 165.976115,
    "out:Total Energy Use Post PV": 161.879188,
    "out:Primary Energy": 119.729431,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 29.662701,
    "out:CO2 Operational/m2": -57.88486,
    "out:Total CO2/m2": -28.222159,
    "out:Total CO2 (tons)": -89.859327,
    "out:Klimatpaverkan": -289.994748,
    "out:Initial Cost/MSEK": 1.9308742799635747,
    "out:Running cost/(Apt SEK y)": 30418.446809,
    "out:Running Cost over RSP/MSEK": 11.20582,
    "out:LCP/MSEK": 0.268026,
    "out:ROI% old": 16.268722,
    "out:Payback discounted": 7,
    "out:Atemp": 3184,
    "out:Umean": 1,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 162,
    "out:Energy use kWh/m2": 162,
    "out:Energy savings %": 13,
    "out:Op energy cost/MSEK": 83,
    "out:El price/MSEK": 48,
    "out:Heating price/MSEK": 30,
    "out:DH+ DHW/kWh/m2": 156,
    "out:El bought/kWh/m2": 413.65837,
    "out:Return %": 15,
    "out:Return/kSEK/y": 285,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 33.36,
    "out:EAHP (El)": 0,
    "out:PV (kr/kWp)": 17461.48474631725,
    "out:PV (kWp installed)": 90.5097,
    "out:PV (m2 panels)": 474.867263,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 84922.568824,
    "out:PV (kWh sold)": 71877.957046,
    "out:PV (% sold (El))": 84.639405,
    "out:PV (kWh self-consumed)": 13044.611778,
    "out:PV (ratio sold/self-consumed)": 5.510165,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 10,
    "out:Payback Time": 10,
    "out:DH kWh savings": 44192.651252,
    "out:EL kWh savings": 21303.538998,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 79599.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 194616.66,
    "out:DH kr savings": 44192.65125213769,
    "out:El kr savings": 42607.077996404216,
    "out:El kr sold": 107816.93556907076,
    "out:El kr saved": 26089.223556,
    "out:El kr return": 133906.15912507076,
    "out:% solar/total": 33,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 389504.078331,
    "out:% savings (space heating)": 4.332172,
    "out:Total_Water Heating (DH)": 106218.208041,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 122.33,
    "out:Etvv": 20,
    "out:Ef": 5.88,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 1930874.2799635748,
    "out:Seasonal Variation ROI (%)": 10,
    "out:Seasonal Variation Payback": 10,
    "out:New AHU Cost/SEK": 146792.87151982498,
    "out:DH CO2": 56.157504,
    "out:EL CO2": -114.042364,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -114.819396,
    "out:Bought CO2": 0.777032,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00000000120101000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 1,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 127.871325,
    "out:DHW (DH)": 28.356,
    "out:Property Electricity": 9.976115,
    "out:Electricity (inc PV)": 5.879188,
    "out:Total Energy Use Pre PV": 165.976115,
    "out:Total Energy Use Post PV": 161.879188,
    "out:Primary Energy": 121.938013,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 30.371244,
    "out:CO2 Operational/m2": -57.88486,
    "out:Total CO2/m2": -27.513616,
    "out:Total CO2 (tons)": -87.603327,
    "out:Klimatpaverkan": -287.738748,
    "out:Initial Cost/MSEK": 2.124490304963575,
    "out:Running cost/(Apt SEK y)": 30418.446809,
    "out:Running Cost over RSP/MSEK": 11.20582,
    "out:LCP/MSEK": 0.07441,
    "out:ROI% old": 14.786068,
    "out:Payback discounted": 8,
    "out:Atemp": 3184,
    "out:Umean": 1,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 162,
    "out:Energy use kWh/m2": 162,
    "out:Energy savings %": 13,
    "out:Op energy cost/MSEK": 83,
    "out:El price/MSEK": 48,
    "out:Heating price/MSEK": 30,
    "out:DH+ DHW/kWh/m2": 156,
    "out:El bought/kWh/m2": 413.65837,
    "out:Return %": 13,
    "out:Return/kSEK/y": 285,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 28.356,
    "out:EAHP (El)": 0,
    "out:PV (kr/kWp)": 17461.48474631725,
    "out:PV (kWp installed)": 90.5097,
    "out:PV (m2 panels)": 474.867263,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 84922.568824,
    "out:PV (kWh sold)": 71877.957046,
    "out:PV (% sold (El))": 84.639405,
    "out:PV (kWh self-consumed)": 13044.611778,
    "out:PV (ratio sold/self-consumed)": 5.510165,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 9,
    "out:Payback Time": 11.111111,
    "out:DH kWh savings": 42487.283216,
    "out:EL kWh savings": 21303.538998,
    "out:Sewage heat recovery cost": 273216,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 192911.3,
    "out:DH kr savings": 42487.28321644798,
    "out:El kr savings": 42607.077996404216,
    "out:El kr sold": 107816.93556907076,
    "out:El kr saved": 26089.223556,
    "out:El kr return": 133906.15912507076,
    "out:% solar/total": 33,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 407142.177573,
    "out:% savings (space heating)": 0,
    "out:Total_Water Heating (DH)": 90285.476835,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 127.87,
    "out:Etvv": 17,
    "out:Ef": 5.88,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 2124490.304963575,
    "out:Seasonal Variation ROI (%)": 9,
    "out:Seasonal Variation Payback": 11.111111,
    "out:New AHU Cost/SEK": 146792.87151982498,
    "out:DH CO2": 56.157504,
    "out:EL CO2": -114.042364,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -114.819396,
    "out:Bought CO2": 0.777032,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00000000120101100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 1,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 122.33172,
    "out:DHW (DH)": 28.356,
    "out:Property Electricity": 9.976115,
    "out:Electricity (inc PV)": 5.879188,
    "out:Total Energy Use Pre PV": 160.976115,
    "out:Total Energy Use Post PV": 156.879188,
    "out:Primary Energy": 117.629431,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 30.371244,
    "out:CO2 Operational/m2": -59.68478,
    "out:Total CO2/m2": -29.313536,
    "out:Total CO2 (tons)": -93.334271,
    "out:Klimatpaverkan": -293.469692,
    "out:Initial Cost/MSEK": 2.204090279963575,
    "out:Running cost/(Apt SEK y)": 29395.510638,
    "out:Running Cost over RSP/MSEK": 10.82976,
    "out:LCP/MSEK": 0.37087,
    "out:ROI% old": 16.68949,
    "out:Payback discounted": 7,
    "out:Atemp": 3184,
    "out:Umean": 1,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 157,
    "out:Energy use kWh/m2": 157,
    "out:Energy savings %": 16.6,
    "out:Op energy cost/MSEK": 81,
    "out:El price/MSEK": 46,
    "out:Heating price/MSEK": 29,
    "out:DH+ DHW/kWh/m2": 151,
    "out:El bought/kWh/m2": 413.65837,
    "out:Return %": 15,
    "out:Return/kSEK/y": 333,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 28.356,
    "out:EAHP (El)": 0,
    "out:PV (kr/kWp)": 17461.48474631725,
    "out:PV (kWp installed)": 90.5097,
    "out:PV (m2 panels)": 474.867263,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 84922.568824,
    "out:PV (kWh sold)": 71877.957046,
    "out:PV (% sold (El))": 84.639405,
    "out:PV (kWh self-consumed)": 13044.611778,
    "out:PV (ratio sold/self-consumed)": 5.510165,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 10,
    "out:Payback Time": 10,
    "out:DH kWh savings": 60125.382458,
    "out:EL kWh savings": 21303.538998,
    "out:Sewage heat recovery cost": 273216,
    "out:Balancing hydronic system cost": 79599.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 210549.4,
    "out:DH kr savings": 60125.38245830575,
    "out:El kr savings": 42607.077996404216,
    "out:El kr sold": 107816.93556907076,
    "out:El kr saved": 26089.223556,
    "out:El kr return": 133906.15912507076,
    "out:% solar/total": 33,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 389504.078331,
    "out:% savings (space heating)": 4.332172,
    "out:Total_Water Heating (DH)": 90285.476835,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 122.33,
    "out:Etvv": 17,
    "out:Ef": 5.88,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 2204090.279963575,
    "out:Seasonal Variation ROI (%)": 9,
    "out:Seasonal Variation Payback": 11.111111,
    "out:New AHU Cost/SEK": 146792.87151982498,
    "out:DH CO2": 54.357584,
    "out:EL CO2": -114.042364,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -114.819396,
    "out:Bought CO2": 0.777032,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00000001000000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 83.839835,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 40.84874,
    "out:Electricity (inc PV)": 40.84874,
    "out:Total Energy Use Pre PV": 124.84874,
    "out:Total Energy Use Post PV": 124.84874,
    "out:Primary Energy": 130.441124,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 3.752943,
    "out:CO2 Operational/m2": 35.631016,
    "out:Total CO2/m2": 39.383959,
    "out:Total CO2 (tons)": 125.398488,
    "out:Klimatpaverkan": -74.736933,
    "out:Initial Cost/MSEK": 2.9842494110739923,
    "out:Running cost/(Apt SEK y)": 22720.042553,
    "out:Running Cost over RSP/MSEK": 8.32871,
    "out:LCP/MSEK": 2.091761,
    "out:ROI% old": 24.299051,
    "out:Payback discounted": 5,
    "out:Atemp": 3184,
    "out:Umean": 1,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 125,
    "out:Energy use kWh/m2": 125,
    "out:Energy savings %": 46.4,
    "out:Op energy cost/MSEK": 60,
    "out:El price/MSEK": 41,
    "out:Heating price/MSEK": 16,
    "out:DH+ DHW/kWh/m2": 84,
    "out:El bought/kWh/m2": 2859.4118,
    "out:Return %": 22,
    "out:Return/kSEK/y": 647,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.6502792112,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 41.7,
    "out:EAHP (El)": 25.97924,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 272968.983303,
    "out:EL kWh savings": -90039.481069,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 92890.02,
    "out:DH kr savings": 272968.98330283223,
    "out:El kr savings": -180078.9621379341,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 266945.954321,
    "out:% savings (space heating)": 34.434218,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 997124.4110739925,
    "out:Eupp": 73.17,
    "out:Etvv": 0,
    "out:Ef": 39.02,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 2984249.4110739925,
    "out:Seasonal Variation ROI (%)": 2,
    "out:Seasonal Variation Payback": 50,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 30.238656,
    "out:EL CO2": 5.39236,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 5.39236,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00000001000000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 78.501833,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 40.787648,
    "out:Electricity (inc PV)": 40.787648,
    "out:Total Energy Use Pre PV": 119.787648,
    "out:Total Energy Use Post PV": 119.787648,
    "out:Primary Energy": 126.260043,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 3.752943,
    "out:CO2 Operational/m2": 33.823032,
    "out:Total CO2/m2": 37.575975,
    "out:Total CO2 (tons)": 119.641868,
    "out:Klimatpaverkan": -80.493553,
    "out:Initial Cost/MSEK": 3.0638493860739926,
    "out:Running cost/(Apt SEK y)": 21688.808511,
    "out:Running Cost over RSP/MSEK": 7.94965,
    "out:LCP/MSEK": 2.391221,
    "out:ROI% old": 25.435184,
    "out:Payback discounted": 5,
    "out:Atemp": 3184,
    "out:Umean": 1,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 120,
    "out:Energy use kWh/m2": 120,
    "out:Energy savings %": 52.5,
    "out:Op energy cost/MSEK": 57,
    "out:El price/MSEK": 39,
    "out:Heating price/MSEK": 15,
    "out:DH+ DHW/kWh/m2": 79,
    "out:El bought/kWh/m2": 2855.13536,
    "out:Return %": 23,
    "out:Return/kSEK/y": 695,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.6502792112,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 41.7,
    "out:EAHP (El)": 25.918148,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 289965.176557,
    "out:EL kWh savings": -89844.964199,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 79599.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 110275.25,
    "out:DH kr savings": 289965.1765570263,
    "out:El kr savings": -179689.92839898638,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 249949.761067,
    "out:% savings (space heating)": 38.608728,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 997124.4110739925,
    "out:Eupp": 67.94,
    "out:Etvv": 0,
    "out:Ef": 38.93,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 3063849.3860739926,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 28.438736,
    "out:EL CO2": 5.384296,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 5.384296,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00000001000001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 79.774518,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 40.185199,
    "out:Electricity (inc PV)": 40.185199,
    "out:Total Energy Use Pre PV": 120.185199,
    "out:Total Energy Use Post PV": 120.185199,
    "out:Primary Energy": 127.448693,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 4.461486,
    "out:CO2 Operational/m2": 34.103488,
    "out:Total CO2/m2": 38.564974,
    "out:Total CO2 (tons)": 122.79084,
    "out:Klimatpaverkan": -77.344581,
    "out:Initial Cost/MSEK": 3.257465411073993,
    "out:Running cost/(Apt SEK y)": 21811.765957,
    "out:Running Cost over RSP/MSEK": 7.99521,
    "out:LCP/MSEK": 2.152045,
    "out:ROI% old": 23.723572,
    "out:Payback discounted": 5,
    "out:Atemp": 3184,
    "out:Umean": 1,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 120,
    "out:Energy use kWh/m2": 120,
    "out:Energy savings %": 52.5,
    "out:Op energy cost/MSEK": 57,
    "out:El price/MSEK": 39,
    "out:Heating price/MSEK": 16,
    "out:DH+ DHW/kWh/m2": 80,
    "out:El bought/kWh/m2": 2812.96393,
    "out:Return %": 21,
    "out:Return/kSEK/y": 690,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.6502792112,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 35.445,
    "out:EAHP (El)": 25.315699,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 285912.948736,
    "out:EL kWh savings": -87926.767161,
    "out:Sewage heat recovery cost": 273216,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 110059.41,
    "out:DH kr savings": 285912.94873625855,
    "out:El kr savings": -175853.53432127868,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 254001.988888,
    "out:% savings (space heating)": 37.613442,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 997124.4110739925,
    "out:Eupp": 70.86,
    "out:Etvv": 0,
    "out:Ef": 38.58,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 3257465.411073993,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 28.79872,
    "out:EL CO2": 5.304768,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 5.304768,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00000001000001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 74.470727,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 40.11374,
    "out:Electricity (inc PV)": 40.11374,
    "out:Total Energy Use Pre PV": 114.11374,
    "out:Total Energy Use Post PV": 114.11374,
    "out:Primary Energy": 123.2677,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 4.461486,
    "out:CO2 Operational/m2": 31.934151,
    "out:Total CO2/m2": 36.395637,
    "out:Total CO2 (tons)": 115.883673,
    "out:Klimatpaverkan": -84.251748,
    "out:Initial Cost/MSEK": 3.3370653860739927,
    "out:Running cost/(Apt SEK y)": 20574.553191,
    "out:Running Cost over RSP/MSEK": 7.54042,
    "out:LCP/MSEK": 2.527235,
    "out:ROI% old": 25.104607,
    "out:Payback discounted": 5,
    "out:Atemp": 3184,
    "out:Umean": 1,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 114,
    "out:Energy use kWh/m2": 114,
    "out:Energy savings %": 60.5,
    "out:Op energy cost/MSEK": 54,
    "out:El price/MSEK": 37,
    "out:Heating price/MSEK": 14,
    "out:DH+ DHW/kWh/m2": 74,
    "out:El bought/kWh/m2": 2807.9618,
    "out:Return %": 22,
    "out:Return/kSEK/y": 748,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.6502792112,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 35.445,
    "out:EAHP (El)": 25.24424,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 302800.214199,
    "out:EL kWh savings": -87699.241773,
    "out:Sewage heat recovery cost": 273216,
    "out:Balancing hydronic system cost": 79599.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 127401.73,
    "out:DH kr savings": 302800.21419922676,
    "out:El kr savings": -175398.4835461942,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 237114.723425,
    "out:% savings (space heating)": 41.761199,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 997124.4110739925,
    "out:Eupp": 65.65,
    "out:Etvv": 0,
    "out:Ef": 38.48,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 3337065.3860739926,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 26.638816,
    "out:EL CO2": 5.295335,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 5.295335,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00000001010000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 83.839835,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 40.84874,
    "out:Electricity (inc PV)": 30.749674,
    "out:Total Energy Use Pre PV": 124.84874,
    "out:Total Energy Use Post PV": 114.749674,
    "out:Primary Energy": 112.262805,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 18.492043,
    "out:CO2 Operational/m2": 18.260908,
    "out:Total CO2/m2": 36.752951,
    "out:Total CO2 (tons)": 117.021361,
    "out:Klimatpaverkan": -83.11406,
    "out:Initial Cost/MSEK": 3.7744698052964925,
    "out:Running cost/(Apt SEK y)": 21022.808511,
    "out:Running Cost over RSP/MSEK": 7.72144,
    "out:LCP/MSEK": 1.90881,
    "out:ROI% old": 21.51023,
    "out:Payback discounted": 6,
    "out:Atemp": 3184,
    "out:Umean": 1,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 115,
    "out:Energy use kWh/m2": 115,
    "out:Energy savings %": 59.1,
    "out:Op energy cost/MSEK": 56,
    "out:El price/MSEK": 37,
    "out:Heating price/MSEK": 16,
    "out:DH+ DHW/kWh/m2": 84,
    "out:El bought/kWh/m2": 2174.506485,
    "out:Return %": 19,
    "out:Return/kSEK/y": 727,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.6502792112,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 41.7,
    "out:EAHP (El)": 25.97924,
    "out:PV (kr/kWp)": 17461.56255567083,
    "out:PV (kWp installed)": 45.25485,
    "out:PV (m2 panels)": 237.433632,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 42461.284501,
    "out:PV (kWh sold)": 10305.86865,
    "out:PV (% sold (El))": 24.271213,
    "out:PV (kWh self-consumed)": 32155.415852,
    "out:PV (ratio sold/self-consumed)": 0.320502,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 272968.983303,
    "out:EL kWh savings": -57884.0646,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 172659.66,
    "out:DH kr savings": 272968.98330283223,
    "out:El kr savings": -115768.12919974461,
    "out:El kr sold": 15458.802974419454,
    "out:El kr saved": 64310.831704,
    "out:El kr return": 79769.63467841945,
    "out:% solar/total": 80,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 266945.954321,
    "out:% savings (space heating)": 34.434218,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 997124.4110739925,
    "out:Eupp": 73.17,
    "out:Etvv": 0,
    "out:Ef": 29.18,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 3774469.8052964923,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 30.238656,
    "out:EL CO2": -11.977748,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -16.046816,
    "out:Bought CO2": 4.069068,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00000001010000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 78.501833,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 40.787648,
    "out:Electricity (inc PV)": 30.696874,
    "out:Total Energy Use Pre PV": 119.787648,
    "out:Total Energy Use Post PV": 109.696874,
    "out:Primary Energy": 108.09665,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 18.492043,
    "out:CO2 Operational/m2": 16.411809,
    "out:Total CO2/m2": 34.903852,
    "out:Total CO2 (tons)": 111.133831,
    "out:Klimatpaverkan": -89.00159,
    "out:Initial Cost/MSEK": 3.8540697802964923,
    "out:Running cost/(Apt SEK y)": 19991.87234,
    "out:Running Cost over RSP/MSEK": 7.34248,
    "out:LCP/MSEK": 2.20817,
    "out:ROI% old": 22.470644,
    "out:Payback discounted": 6,
    "out:Atemp": 3184,
    "out:Umean": 1,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 110,
    "out:Energy use kWh/m2": 110,
    "out:Energy savings %": 66.4,
    "out:Op energy cost/MSEK": 53,
    "out:El price/MSEK": 35,
    "out:Heating price/MSEK": 15,
    "out:DH+ DHW/kWh/m2": 79,
    "out:El bought/kWh/m2": 2170.769756,
    "out:Return %": 20,
    "out:Return/kSEK/y": 775,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.6502792112,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 41.7,
    "out:EAHP (El)": 25.918148,
    "out:PV (kr/kWp)": 17461.56255567083,
    "out:PV (kWp installed)": 45.25485,
    "out:PV (m2 panels)": 237.433632,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 42461.284501,
    "out:PV (kWh sold)": 10332.269203,
    "out:PV (% sold (El))": 24.333388,
    "out:PV (kWh self-consumed)": 32129.015299,
    "out:PV (ratio sold/self-consumed)": 0.321587,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 289965.176557,
    "out:EL kWh savings": -57715.94945,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 79599.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 190031.68,
    "out:DH kr savings": 289965.1765570263,
    "out:El kr savings": -115431.89890090947,
    "out:El kr sold": 15498.403803838602,
    "out:El kr saved": 64258.030598,
    "out:El kr return": 79756.4344018386,
    "out:% solar/total": 80,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 249949.761067,
    "out:% savings (space heating)": 38.608728,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 997124.4110739925,
    "out:Eupp": 67.94,
    "out:Etvv": 0,
    "out:Ef": 29.1,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 3854069.7802964924,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 28.438736,
    "out:EL CO2": -12.026927,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -16.089005,
    "out:Bought CO2": 4.062078,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00000001010001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 79.774518,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 40.185199,
    "out:Electricity (inc PV)": 30.176913,
    "out:Total Energy Use Pre PV": 120.185199,
    "out:Total Energy Use Post PV": 110.176913,
    "out:Primary Energy": 109.433778,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 19.200586,
    "out:CO2 Operational/m2": 16.28311,
    "out:Total CO2/m2": 35.483696,
    "out:Total CO2 (tons)": 112.980054,
    "out:Klimatpaverkan": -87.155367,
    "out:Initial Cost/MSEK": 4.047685805296492,
    "out:Running cost/(Apt SEK y)": 20117.617021,
    "out:Running Cost over RSP/MSEK": 7.38903,
    "out:LCP/MSEK": 1.968004,
    "out:ROI% old": 21.231497,
    "out:Payback discounted": 6,
    "out:Atemp": 3184,
    "out:Umean": 1,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 110,
    "out:Energy use kWh/m2": 110,
    "out:Energy savings %": 66.4,
    "out:Op energy cost/MSEK": 54,
    "out:El price/MSEK": 35,
    "out:Heating price/MSEK": 16,
    "out:DH+ DHW/kWh/m2": 80,
    "out:El bought/kWh/m2": 2133.968623,
    "out:Return %": 19,
    "out:Return/kSEK/y": 769,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.6502792112,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 35.445,
    "out:EAHP (El)": 25.315699,
    "out:PV (kr/kWp)": 17461.56255567083,
    "out:PV (kWp installed)": 45.25485,
    "out:PV (m2 panels)": 237.433632,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 42461.284501,
    "out:PV (kWh sold)": 10594.909979,
    "out:PV (% sold (El))": 24.95193,
    "out:PV (kWh self-consumed)": 31866.374523,
    "out:PV (ratio sold/self-consumed)": 0.332479,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 285912.948736,
    "out:EL kWh savings": -56060.394125,
    "out:Sewage heat recovery cost": 273216,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 189684.53,
    "out:DH kr savings": 285912.94873625855,
    "out:El kr savings": -112120.78824915468,
    "out:El kr sold": 15892.36496788718,
    "out:El kr saved": 63732.749046,
    "out:El kr return": 79625.11401388719,
    "out:% solar/total": 80,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 254001.988888,
    "out:% savings (space heating)": 37.613442,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 997124.4110739925,
    "out:Eupp": 70.86,
    "out:Etvv": 0,
    "out:Ef": 28.8,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 4047685.8052964923,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 28.79872,
    "out:EL CO2": -12.51561,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -16.508854,
    "out:Bought CO2": 3.993244,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00000001010001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 74.470727,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 40.11374,
    "out:Electricity (inc PV)": 30.115326,
    "out:Total Energy Use Pre PV": 114.11374,
    "out:Total Energy Use Post PV": 104.115326,
    "out:Primary Energy": 105.270555,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 19.200586,
    "out:CO2 Operational/m2": 14.064791,
    "out:Total CO2/m2": 33.265377,
    "out:Total CO2 (tons)": 105.916929,
    "out:Klimatpaverkan": -94.218493,
    "out:Initial Cost/MSEK": 4.127285780296492,
    "out:Running cost/(Apt SEK y)": 18880.744681,
    "out:Running Cost over RSP/MSEK": 6.93436,
    "out:LCP/MSEK": 2.343074,
    "out:ROI% old": 22.395763,
    "out:Payback discounted": 6,
    "out:Atemp": 3184,
    "out:Umean": 1,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 104,
    "out:Energy use kWh/m2": 104,
    "out:Energy savings %": 76,
    "out:Op energy cost/MSEK": 50,
    "out:El price/MSEK": 33,
    "out:Heating price/MSEK": 14,
    "out:DH+ DHW/kWh/m2": 74,
    "out:El bought/kWh/m2": 2129.609306,
    "out:Return %": 20,
    "out:Return/kSEK/y": 827,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.6502792112,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 35.445,
    "out:EAHP (El)": 25.24424,
    "out:PV (kr/kWp)": 17461.56255567083,
    "out:PV (kWp installed)": 45.25485,
    "out:PV (m2 panels)": 237.433632,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 42461.284501,
    "out:PV (kWh sold)": 10626.343693,
    "out:PV (% sold (El))": 25.025959,
    "out:PV (kWh self-consumed)": 31834.940808,
    "out:PV (ratio sold/self-consumed)": 0.333795,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 302800.214199,
    "out:EL kWh savings": -55864.301176,
    "out:Sewage heat recovery cost": 273216,
    "out:Balancing hydronic system cost": 79599.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 207011.13,
    "out:DH kr savings": 302800.21419922676,
    "out:El kr savings": -111728.60235115526,
    "out:El kr sold": 15939.51553963424,
    "out:El kr saved": 63669.881616,
    "out:El kr return": 79609.39715563424,
    "out:% solar/total": 80,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 237114.723425,
    "out:% savings (space heating)": 41.761199,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 997124.4110739925,
    "out:Eupp": 65.65,
    "out:Etvv": 0,
    "out:Ef": 28.72,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 4127285.780296492,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 26.638816,
    "out:EL CO2": -12.574025,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -16.559116,
    "out:Bought CO2": 3.985091,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00000001020000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 83.839835,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 40.84874,
    "out:Electricity (inc PV)": 27.839959,
    "out:Total Energy Use Pre PV": 124.84874,
    "out:Total Energy Use Post PV": 111.839959,
    "out:Primary Energy": 107.025318,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 33.231142,
    "out:CO2 Operational/m2": -34.992085,
    "out:Total CO2/m2": -1.760943,
    "out:Total CO2 (tons)": -5.606841,
    "out:Klimatpaverkan": -205.742262,
    "out:Initial Cost/MSEK": 4.564683157017742,
    "out:Running cost/(Apt SEK y)": 19569.106383,
    "out:Running Cost over RSP/MSEK": 7.20153,
    "out:LCP/MSEK": 1.638507,
    "out:ROI% old": 19.413615,
    "out:Payback discounted": 6,
    "out:Atemp": 3184,
    "out:Umean": 1,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 112,
    "out:Energy use kWh/m2": 112,
    "out:Energy savings %": 63.4,
    "out:Op energy cost/MSEK": 53,
    "out:El price/MSEK": 34,
    "out:Heating price/MSEK": 16,
    "out:DH+ DHW/kWh/m2": 84,
    "out:El bought/kWh/m2": 1967.614869,
    "out:Return %": 17,
    "out:Return/kSEK/y": 795,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.6502792112,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 41.7,
    "out:EAHP (El)": 25.97924,
    "out:PV (kr/kWp)": 17461.48474631725,
    "out:PV (kWp installed)": 90.5097,
    "out:PV (m2 panels)": 474.867263,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 84922.568824,
    "out:PV (kWh sold)": 43502.62184,
    "out:PV (% sold (El))": 51.22622,
    "out:PV (kWh self-consumed)": 41419.946984,
    "out:PV (ratio sold/self-consumed)": 1.050282,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 272968.983303,
    "out:EL kWh savings": -48619.534827,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 240983.85,
    "out:DH kr savings": 272968.98330283223,
    "out:El kr savings": -97239.06965475851,
    "out:El kr sold": 65253.9327602331,
    "out:El kr saved": 82839.893968,
    "out:El kr return": 148093.8267282331,
    "out:% solar/total": 103,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 266945.954321,
    "out:% savings (space heating)": 34.434218,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 997124.4110739925,
    "out:Eupp": 73.17,
    "out:Etvv": 0,
    "out:Ef": 26.43,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 4564683.157017742,
    "out:Seasonal Variation ROI (%)": 5,
    "out:Seasonal Variation Payback": 20,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 30.238656,
    "out:EL CO2": -65.230741,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -68.914239,
    "out:Bought CO2": 3.683498,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00000001020000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 78.501833,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 40.787648,
    "out:Electricity (inc PV)": 27.792583,
    "out:Total Energy Use Pre PV": 119.787648,
    "out:Total Energy Use Post PV": 106.792583,
    "out:Primary Energy": 102.868926,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 33.231142,
    "out:CO2 Operational/m2": -36.868676,
    "out:Total CO2/m2": -3.637534,
    "out:Total CO2 (tons)": -11.581905,
    "out:Klimatpaverkan": -211.717326,
    "out:Initial Cost/MSEK": 4.644283132017743,
    "out:Running cost/(Apt SEK y)": 18538.340426,
    "out:Running Cost over RSP/MSEK": 6.82263,
    "out:LCP/MSEK": 1.937807,
    "out:ROI% old": 20.246366,
    "out:Payback discounted": 6,
    "out:Atemp": 3184,
    "out:Umean": 1,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 107,
    "out:Energy use kWh/m2": 107,
    "out:Energy savings %": 71,
    "out:Op energy cost/MSEK": 50,
    "out:El price/MSEK": 32,
    "out:Heating price/MSEK": 15,
    "out:DH+ DHW/kWh/m2": 79,
    "out:El bought/kWh/m2": 1964.259673,
    "out:Return %": 18,
    "out:Return/kSEK/y": 843,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.6502792112,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 41.7,
    "out:EAHP (El)": 25.918148,
    "out:PV (kr/kWp)": 17461.48474631725,
    "out:PV (kWp installed)": 90.5097,
    "out:PV (m2 panels)": 474.867263,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 84922.568824,
    "out:PV (kWh sold)": 43546.295361,
    "out:PV (% sold (El))": 51.277647,
    "out:PV (kWh self-consumed)": 41376.273463,
    "out:PV (ratio sold/self-consumed)": 1.052446,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 289965.176557,
    "out:EL kWh savings": -48468.689689,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 79599.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 258347.24,
    "out:DH kr savings": 289965.1765570263,
    "out:El kr savings": -96937.37937753102,
    "out:El kr sold": 65319.443042062994,
    "out:El kr saved": 82752.546926,
    "out:El kr return": 148071.989968063,
    "out:% solar/total": 103,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 249949.761067,
    "out:% savings (space heating)": 38.608728,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 997124.4110739925,
    "out:Eupp": 67.94,
    "out:Etvv": 0,
    "out:Ef": 26.36,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 4644283.132017743,
    "out:Seasonal Variation ROI (%)": 5,
    "out:Seasonal Variation Payback": 20,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 28.438736,
    "out:EL CO2": -65.307412,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -68.984638,
    "out:Bought CO2": 3.677226,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00000001020001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 79.774518,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 40.185199,
    "out:Electricity (inc PV)": 27.326222,
    "out:Total Energy Use Pre PV": 120.185199,
    "out:Total Energy Use Post PV": 107.326222,
    "out:Primary Energy": 104.302535,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 33.939685,
    "out:CO2 Operational/m2": -37.268032,
    "out:Total CO2/m2": -3.328347,
    "out:Total CO2 (tons)": -10.597454,
    "out:Klimatpaverkan": -210.732875,
    "out:Initial Cost/MSEK": 4.837899157017742,
    "out:Running cost/(Apt SEK y)": 18665.914894,
    "out:Running Cost over RSP/MSEK": 6.86984,
    "out:LCP/MSEK": 1.696981,
    "out:ROI% old": 19.296688,
    "out:Payback discounted": 6,
    "out:Atemp": 3184,
    "out:Umean": 1,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 107,
    "out:Energy use kWh/m2": 107,
    "out:Energy savings %": 71,
    "out:Op energy cost/MSEK": 50,
    "out:El price/MSEK": 32,
    "out:Heating price/MSEK": 16,
    "out:DH+ DHW/kWh/m2": 80,
    "out:El bought/kWh/m2": 1931.220492,
    "out:Return %": 17,
    "out:Return/kSEK/y": 837,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.6502792112,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 35.445,
    "out:EAHP (El)": 25.315699,
    "out:PV (kr/kWp)": 17461.48474631725,
    "out:PV (kWp installed)": 90.5097,
    "out:PV (m2 panels)": 474.867263,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 84922.568824,
    "out:PV (kWh sold)": 43979.597828,
    "out:PV (% sold (El))": 51.78788,
    "out:PV (kWh self-consumed)": 40942.970996,
    "out:PV (ratio sold/self-consumed)": 1.074167,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 285912.948736,
    "out:EL kWh savings": -46983.796712,
    "out:Sewage heat recovery cost": 273216,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 257914.75,
    "out:DH kr savings": 285912.94873625855,
    "out:El kr savings": -93967.59342307856,
    "out:El kr sold": 65969.39674158931,
    "out:El kr saved": 81885.941992,
    "out:El kr return": 147855.3387335893,
    "out:% solar/total": 102,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 254001.988888,
    "out:% savings (space heating)": 37.613442,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 997124.4110739925,
    "out:Eupp": 70.86,
    "out:Etvv": 0,
    "out:Ef": 26.09,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 4837899.157017742,
    "out:Seasonal Variation ROI (%)": 5,
    "out:Seasonal Variation Payback": 20,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 28.79872,
    "out:EL CO2": -66.066752,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -69.682215,
    "out:Bought CO2": 3.615463,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00000001020001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 74.470727,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 40.11374,
    "out:Electricity (inc PV)": 27.270996,
    "out:Total Energy Use Pre PV": 114.11374,
    "out:Total Energy Use Post PV": 101.270996,
    "out:Primary Energy": 100.150761,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 33.939685,
    "out:CO2 Operational/m2": -39.518449,
    "out:Total CO2/m2": -5.578764,
    "out:Total CO2 (tons)": -17.762779,
    "out:Klimatpaverkan": -217.8982,
    "out:Initial Cost/MSEK": 4.917499132017743,
    "out:Running cost/(Apt SEK y)": 17429.255319,
    "out:Running Cost over RSP/MSEK": 6.41525,
    "out:LCP/MSEK": 2.071971,
    "out:ROI% old": 20.304949,
    "out:Payback discounted": 6,
    "out:Atemp": 3184,
    "out:Umean": 1,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 101,
    "out:Energy use kWh/m2": 101,
    "out:Energy savings %": 81.2,
    "out:Op energy cost/MSEK": 47,
    "out:El price/MSEK": 30,
    "out:Heating price/MSEK": 14,
    "out:DH+ DHW/kWh/m2": 74,
    "out:El bought/kWh/m2": 1927.307436,
    "out:Return %": 18,
    "out:Return/kSEK/y": 896,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.6502792112,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 35.445,
    "out:EAHP (El)": 25.24424,
    "out:PV (kr/kWp)": 17461.48474631725,
    "out:PV (kWp installed)": 90.5097,
    "out:PV (m2 panels)": 474.867263,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 84922.568824,
    "out:PV (kWh sold)": 44031.28566,
    "out:PV (% sold (El))": 51.848744,
    "out:PV (kWh self-consumed)": 40891.283164,
    "out:PV (ratio sold/self-consumed)": 1.076789,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 302800.214199,
    "out:EL kWh savings": -46807.95718,
    "out:Sewage heat recovery cost": 273216,
    "out:Balancing hydronic system cost": 79599.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 275231.23,
    "out:DH kr savings": 302800.21419922676,
    "out:El kr savings": -93615.91436089163,
    "out:El kr sold": 66046.9284902621,
    "out:El kr saved": 81782.566328,
    "out:El kr return": 147829.49481826212,
    "out:% solar/total": 102,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 237114.723425,
    "out:% savings (space heating)": 41.761199,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 997124.4110739925,
    "out:Eupp": 65.65,
    "out:Etvv": 0,
    "out:Ef": 26.02,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 4917499.132017743,
    "out:Seasonal Variation ROI (%)": 5,
    "out:Seasonal Variation Payback": 20,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 26.638816,
    "out:EL CO2": -66.157265,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -69.765414,
    "out:Bought CO2": 3.608149,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00000001100000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 78.436537,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 39.95883,
    "out:Electricity (inc PV)": 39.95883,
    "out:Total Energy Use Pre PV": 117.95883,
    "out:Total Energy Use Post PV": 117.95883,
    "out:Primary Energy": 126.4464,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 3.752943,
    "out:CO2 Operational/m2": 33.353637,
    "out:Total CO2/m2": 37.10658,
    "out:Total CO2 (tons)": 118.147315,
    "out:Klimatpaverkan": -81.988106,
    "out:Initial Cost/MSEK": 3.108297098573992,
    "out:Running cost/(Apt SEK y)": 21371.93617,
    "out:Running Cost over RSP/MSEK": 7.83364,
    "out:LCP/MSEK": 2.462783,
    "out:ROI% old": 25.604649,
    "out:Payback discounted": 5,
    "out:Atemp": 3184,
    "out:Umean": 1,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 118,
    "out:Energy use kWh/m2": 118,
    "out:Energy savings %": 55.1,
    "out:Op energy cost/MSEK": 56,
    "out:El price/MSEK": 38,
    "out:Heating price/MSEK": 15,
    "out:DH+ DHW/kWh/m2": 78,
    "out:El bought/kWh/m2": 2797.1181,
    "out:Return %": 23,
    "out:Return/kSEK/y": 710,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.6502792112,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 33.36,
    "out:EAHP (El)": 25.08933,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 290173.078958,
    "out:EL kWh savings": -87206.008482,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 115761.06,
    "out:DH kr savings": 290173.0789584727,
    "out:El kr savings": -174412.01696300175,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 249741.858666,
    "out:% savings (space heating)": 38.659792,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 997124.4110739925,
    "out:Eupp": 70.1,
    "out:Etvv": 0,
    "out:Ef": 38.44,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 3108297.098573992,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 28.078752,
    "out:EL CO2": 5.274885,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 5.274885,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00000001100000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 73.14821,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 39.882685,
    "out:Electricity (inc PV)": 39.882685,
    "out:Total Energy Use Pre PV": 112.882685,
    "out:Total Energy Use Post PV": 112.882685,
    "out:Primary Energy": 122.264302,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 3.752943,
    "out:CO2 Operational/m2": 31.543665,
    "out:Total CO2/m2": 35.296608,
    "out:Total CO2 (tons)": 112.384366,
    "out:Klimatpaverkan": -87.751055,
    "out:Initial Cost/MSEK": 3.187897073573992,
    "out:Running cost/(Apt SEK y)": 20338.659574,
    "out:Running Cost over RSP/MSEK": 7.45383,
    "out:LCP/MSEK": 2.762993,
    "out:ROI% old": 26.667333,
    "out:Payback discounted": 5,
    "out:Atemp": 3184,
    "out:Umean": 1,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 113,
    "out:Energy use kWh/m2": 113,
    "out:Energy savings %": 61.9,
    "out:Op energy cost/MSEK": 53,
    "out:El price/MSEK": 37,
    "out:Heating price/MSEK": 14,
    "out:DH+ DHW/kWh/m2": 73,
    "out:El bought/kWh/m2": 2791.78795,
    "out:Return %": 24,
    "out:Return/kSEK/y": 759,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.6502792112,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 33.36,
    "out:EAHP (El)": 25.013185,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 307011.10706,
    "out:EL kWh savings": -86963.562874,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 79599.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 133083.98,
    "out:DH kr savings": 307011.1070602554,
    "out:El kr savings": -173927.1257488956,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 232903.830564,
    "out:% savings (space heating)": 42.795455,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 997124.4110739925,
    "out:Eupp": 64.9,
    "out:Etvv": 0,
    "out:Ef": 38.33,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 3187897.073573992,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 26.278832,
    "out:EL CO2": 5.264833,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 5.264833,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00000001100001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 75.267875,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 39.402667,
    "out:Electricity (inc PV)": 39.402667,
    "out:Total Energy Use Pre PV": 114.402667,
    "out:Total Energy Use Post PV": 114.402667,
    "out:Primary Energy": 124.028383,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 4.461486,
    "out:CO2 Operational/m2": 32.200267,
    "out:Total CO2/m2": 36.661753,
    "out:Total CO2 (tons)": 116.730986,
    "out:Klimatpaverkan": -83.404435,
    "out:Initial Cost/MSEK": 3.3815130985739925,
    "out:Running cost/(Apt SEK y)": 20682.808511,
    "out:Running Cost over RSP/MSEK": 7.58062,
    "out:LCP/MSEK": 2.442587,
    "out:ROI% old": 24.604793,
    "out:Payback discounted": 5,
    "out:Atemp": 3184,
    "out:Umean": 1,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 114,
    "out:Energy use kWh/m2": 114,
    "out:Energy savings %": 60.5,
    "out:Op energy cost/MSEK": 54,
    "out:El price/MSEK": 37,
    "out:Heating price/MSEK": 15,
    "out:DH+ DHW/kWh/m2": 75,
    "out:El bought/kWh/m2": 2758.18669,
    "out:Return %": 22,
    "out:Return/kSEK/y": 743,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.6502792112,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 28.356,
    "out:EAHP (El)": 24.533167,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 300262.095731,
    "out:EL kWh savings": -85435.186022,
    "out:Sewage heat recovery cost": 273216,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 129391.72,
    "out:DH kr savings": 300262.09573089454,
    "out:El kr savings": -170870.37204461006,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 239652.841893,
    "out:% savings (space heating)": 41.1378,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 997124.4110739925,
    "out:Eupp": 68.28,
    "out:Etvv": 0,
    "out:Ef": 38.08,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 3381513.0985739925,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 26.9988,
    "out:EL CO2": 5.201467,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 5.201467,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00000001100001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 70.011418,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 39.316864,
    "out:Electricity (inc PV)": 39.316864,
    "out:Total Energy Use Pre PV": 109.316864,
    "out:Total Energy Use Post PV": 109.316864,
    "out:Primary Energy": 119.847729,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 4.461486,
    "out:CO2 Operational/m2": 30.389021,
    "out:Total CO2/m2": 34.850507,
    "out:Total CO2 (tons)": 110.963981,
    "out:Klimatpaverkan": -89.17144,
    "out:Initial Cost/MSEK": 3.461113073573993,
    "out:Running cost/(Apt SEK y)": 19648.234043,
    "out:Running Cost over RSP/MSEK": 7.20035,
    "out:LCP/MSEK": 2.743257,
    "out:ROI% old": 25.608484,
    "out:Payback discounted": 5,
    "out:Atemp": 3184,
    "out:Umean": 1,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 109,
    "out:Energy use kWh/m2": 109,
    "out:Energy savings %": 67.9,
    "out:Op energy cost/MSEK": 51,
    "out:El price/MSEK": 36,
    "out:Heating price/MSEK": 14,
    "out:DH+ DHW/kWh/m2": 70,
    "out:El bought/kWh/m2": 2752.18048,
    "out:Return %": 23,
    "out:Return/kSEK/y": 791,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.6502792112,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 28.356,
    "out:EAHP (El)": 24.447364,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 316998.649783,
    "out:EL kWh savings": -85161.989353,
    "out:Sewage heat recovery cost": 273216,
    "out:Balancing hydronic system cost": 79599.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 146674.67,
    "out:DH kr savings": 316998.6497832087,
    "out:El kr savings": -170323.97870500863,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 222916.287841,
    "out:% savings (space heating)": 45.24854,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 997124.4110739925,
    "out:Eupp": 63.1,
    "out:Etvv": 0,
    "out:Ef": 37.97,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 3461113.0735739926,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 25.19888,
    "out:EL CO2": 5.190141,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 5.190141,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00000001110000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 78.436537,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 39.95883,
    "out:Electricity (inc PV)": 29.981879,
    "out:Total Energy Use Pre PV": 117.95883,
    "out:Total Energy Use Post PV": 107.981879,
    "out:Primary Energy": 108.487888,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 18.492043,
    "out:CO2 Operational/m2": 15.377771,
    "out:Total CO2/m2": 33.869814,
    "out:Total CO2 (tons)": 107.841455,
    "out:Klimatpaverkan": -92.293966,
    "out:Initial Cost/MSEK": 3.8985174927964925,
    "out:Running cost/(Apt SEK y)": 19678.829787,
    "out:Running Cost over RSP/MSEK": 7.22784,
    "out:LCP/MSEK": 2.278363,
    "out:ROI% old": 22.634538,
    "out:Payback discounted": 6,
    "out:Atemp": 3184,
    "out:Umean": 1,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 108,
    "out:Energy use kWh/m2": 108,
    "out:Energy savings %": 69.4,
    "out:Op energy cost/MSEK": 52,
    "out:El price/MSEK": 35,
    "out:Heating price/MSEK": 15,
    "out:DH+ DHW/kWh/m2": 78,
    "out:El bought/kWh/m2": 2120.163223,
    "out:Return %": 20,
    "out:Return/kSEK/y": 790,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.6502792112,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 33.36,
    "out:EAHP (El)": 25.08933,
    "out:PV (kr/kWp)": 17461.56255567083,
    "out:PV (kWp installed)": 45.25485,
    "out:PV (m2 panels)": 237.433632,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 42461.284501,
    "out:PV (kWh sold)": 10694.680683,
    "out:PV (% sold (El))": 25.186899,
    "out:PV (kWh self-consumed)": 31766.603818,
    "out:PV (ratio sold/self-consumed)": 0.336664,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 290173.078958,
    "out:EL kWh savings": -55439.406055,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 195336.29,
    "out:DH kr savings": 290173.0789584727,
    "out:El kr savings": -110878.81211083978,
    "out:El kr sold": 16042.021024693666,
    "out:El kr saved": 63533.207636,
    "out:El kr return": 79575.22866069367,
    "out:% solar/total": 79,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 249741.858666,
    "out:% savings (space heating)": 38.659792,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 997124.4110739925,
    "out:Eupp": 70.1,
    "out:Etvv": 0,
    "out:Ef": 28.68,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 3898517.4927964923,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 28.078752,
    "out:EL CO2": -12.700981,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -16.668406,
    "out:Bought CO2": 3.967425,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00000001110000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 73.14821,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 39.882685,
    "out:Electricity (inc PV)": 29.916324,
    "out:Total Energy Use Pre PV": 112.882685,
    "out:Total Energy Use Post PV": 102.916324,
    "out:Primary Energy": 104.324852,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 18.492043,
    "out:CO2 Operational/m2": 13.515272,
    "out:Total CO2/m2": 32.007315,
    "out:Total CO2 (tons)": 101.91126,
    "out:Klimatpaverkan": -98.224161,
    "out:Initial Cost/MSEK": 3.9781174677964923,
    "out:Running cost/(Apt SEK y)": 18645.93617,
    "out:Running Cost over RSP/MSEK": 6.84816,
    "out:LCP/MSEK": 2.578443,
    "out:ROI% old": 23.545092,
    "out:Payback discounted": 5,
    "out:Atemp": 3184,
    "out:Umean": 1,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 103,
    "out:Energy use kWh/m2": 103,
    "out:Energy savings %": 77.7,
    "out:Op energy cost/MSEK": 50,
    "out:El price/MSEK": 33,
    "out:Heating price/MSEK": 14,
    "out:DH+ DHW/kWh/m2": 73,
    "out:El bought/kWh/m2": 2115.522258,
    "out:Return %": 21,
    "out:Return/kSEK/y": 838,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.6502792112,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 33.36,
    "out:EAHP (El)": 25.013185,
    "out:PV (kr/kWp)": 17461.56255567083,
    "out:PV (kWp installed)": 45.25485,
    "out:PV (m2 panels)": 237.433632,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 42461.284501,
    "out:PV (kWh sold)": 10728.400419,
    "out:PV (% sold (El))": 25.266312,
    "out:PV (kWh self-consumed)": 31732.884082,
    "out:PV (ratio sold/self-consumed)": 0.338085,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 307011.10706,
    "out:EL kWh savings": -55230.678998,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 79599.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 212642.35,
    "out:DH kr savings": 307011.1070602554,
    "out:El kr savings": -110461.35799644323,
    "out:El kr sold": 16092.600629233355,
    "out:El kr saved": 63465.768164,
    "out:El kr return": 79558.36879323336,
    "out:% solar/total": 79,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 232903.830564,
    "out:% savings (space heating)": 42.795455,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 997124.4110739925,
    "out:Eupp": 64.9,
    "out:Etvv": 0,
    "out:Ef": 28.59,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 3978117.4677964924,
    "out:Seasonal Variation ROI (%)": 5,
    "out:Seasonal Variation Payback": 20,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 26.278832,
    "out:EL CO2": -12.76356,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -16.722306,
    "out:Bought CO2": 3.958746,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00000001110001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 75.267875,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 39.402667,
    "out:Electricity (inc PV)": 29.503614,
    "out:Total Energy Use Pre PV": 114.402667,
    "out:Total Energy Use Post PV": 104.503614,
    "out:Primary Energy": 106.210087,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 19.200586,
    "out:CO2 Operational/m2": 13.838087,
    "out:Total CO2/m2": 33.038673,
    "out:Total CO2 (tons)": 105.195103,
    "out:Klimatpaverkan": -94.940318,
    "out:Initial Cost/MSEK": 4.171733492796492,
    "out:Running cost/(Apt SEK y)": 18992.361702,
    "out:Running Cost over RSP/MSEK": 6.97577,
    "out:LCP/MSEK": 2.257217,
    "out:ROI% old": 22.015343,
    "out:Payback discounted": 6,
    "out:Atemp": 3184,
    "out:Umean": 1,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 105,
    "out:Energy use kWh/m2": 105,
    "out:Energy savings %": 74.3,
    "out:Op energy cost/MSEK": 51,
    "out:El price/MSEK": 34,
    "out:Heating price/MSEK": 15,
    "out:DH+ DHW/kWh/m2": 75,
    "out:El bought/kWh/m2": 2086.29811,
    "out:Return %": 20,
    "out:Return/kSEK/y": 822,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.6502792112,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 28.356,
    "out:EAHP (El)": 24.533167,
    "out:PV (kr/kWp)": 17461.56255567083,
    "out:PV (kWp installed)": 45.25485,
    "out:PV (m2 panels)": 237.433632,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 42461.284501,
    "out:PV (kWh sold)": 10942.710421,
    "out:PV (% sold (El))": 25.77103,
    "out:PV (kWh self-consumed)": 31518.574081,
    "out:PV (ratio sold/self-consumed)": 0.347183,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 300262.095731,
    "out:EL kWh savings": -53916.610754,
    "out:Sewage heat recovery cost": 273216,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 208842.94,
    "out:DH kr savings": 300262.09573089454,
    "out:El kr savings": -107833.22150719556,
    "out:El kr sold": 16414.065630831654,
    "out:El kr saved": 63037.148162,
    "out:El kr return": 79451.21379283165,
    "out:% solar/total": 79,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 239652.841893,
    "out:% savings (space heating)": 41.1378,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 997124.4110739925,
    "out:Eupp": 68.28,
    "out:Etvv": 0,
    "out:Ef": 28.37,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 4171733.4927964923,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 26.9988,
    "out:EL CO2": -13.160713,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -17.064817,
    "out:Bought CO2": 3.904104,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00000001110001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 70.011418,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 39.316864,
    "out:Electricity (inc PV)": 29.429926,
    "out:Total Energy Use Pre PV": 109.316864,
    "out:Total Energy Use Post PV": 99.429926,
    "out:Primary Energy": 102.051241,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 19.200586,
    "out:CO2 Operational/m2": 11.9667,
    "out:Total CO2/m2": 31.167286,
    "out:Total CO2 (tons)": 99.236609,
    "out:Klimatpaverkan": -100.898812,
    "out:Initial Cost/MSEK": 4.251333467796492,
    "out:Running cost/(Apt SEK y)": 17958.191489,
    "out:Running Cost over RSP/MSEK": 6.59563,
    "out:LCP/MSEK": 2.557757,
    "out:ROI% old": 22.880519,
    "out:Payback discounted": 5,
    "out:Atemp": 3184,
    "out:Umean": 1,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 99,
    "out:Energy use kWh/m2": 99,
    "out:Energy savings %": 84.8,
    "out:Op energy cost/MSEK": 48,
    "out:El price/MSEK": 32,
    "out:Heating price/MSEK": 14,
    "out:DH+ DHW/kWh/m2": 70,
    "out:El bought/kWh/m2": 2081.080327,
    "out:Return %": 20,
    "out:Return/kSEK/y": 871,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.6502792112,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 28.356,
    "out:EAHP (El)": 24.447364,
    "out:PV (kr/kWp)": 17461.56255567083,
    "out:PV (kWp installed)": 45.25485,
    "out:PV (m2 panels)": 237.433632,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 42461.284501,
    "out:PV (kWh sold)": 10981.281809,
    "out:PV (% sold (El))": 25.861869,
    "out:PV (kWh self-consumed)": 31480.002693,
    "out:PV (ratio sold/self-consumed)": 0.348834,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 316998.649783,
    "out:EL kWh savings": -53681.988232,
    "out:Sewage heat recovery cost": 273216,
    "out:Balancing hydronic system cost": 79599.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 226106.6,
    "out:DH kr savings": 316998.6497832087,
    "out:El kr savings": -107363.97646438175,
    "out:El kr sold": 16471.92271290141,
    "out:El kr saved": 62960.005386,
    "out:El kr return": 79431.92809890141,
    "out:% solar/total": 79,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 222916.287841,
    "out:% savings (space heating)": 45.24854,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 997124.4110739925,
    "out:Eupp": 63.1,
    "out:Etvv": 0,
    "out:Ef": 28.28,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 4251333.467796492,
    "out:Seasonal Variation ROI (%)": 5,
    "out:Seasonal Variation Payback": 20,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 25.19888,
    "out:EL CO2": -13.23218,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -17.126529,
    "out:Bought CO2": 3.894349,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00000001120000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 78.436537,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 39.95883,
    "out:Electricity (inc PV)": 27.151315,
    "out:Total Energy Use Pre PV": 117.95883,
    "out:Total Energy Use Post PV": 105.151315,
    "out:Primary Energy": 103.392873,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 33.231142,
    "out:CO2 Operational/m2": -38.275029,
    "out:Total CO2/m2": -5.043887,
    "out:Total CO2 (tons)": -16.059731,
    "out:Klimatpaverkan": -216.195152,
    "out:Initial Cost/MSEK": 4.6887308445177425,
    "out:Running cost/(Apt SEK y)": 18227.808511,
    "out:Running Cost over RSP/MSEK": 6.7089,
    "out:LCP/MSEK": 2.007089,
    "out:ROI% old": 20.400952,
    "out:Payback discounted": 6,
    "out:Atemp": 3184,
    "out:Umean": 1,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 105,
    "out:Energy use kWh/m2": 105,
    "out:Energy savings %": 74.3,
    "out:Op energy cost/MSEK": 49,
    "out:El price/MSEK": 31,
    "out:Heating price/MSEK": 15,
    "out:DH+ DHW/kWh/m2": 78,
    "out:El bought/kWh/m2": 1918.828823,
    "out:Return %": 18,
    "out:Return/kSEK/y": 858,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.6502792112,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 33.36,
    "out:EAHP (El)": 25.08933,
    "out:PV (kr/kWp)": 17461.48474631725,
    "out:PV (kWp installed)": 90.5097,
    "out:PV (m2 panels)": 474.867263,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 84922.568824,
    "out:PV (kWh sold)": 44143.452983,
    "out:PV (% sold (El))": 51.980826,
    "out:PV (kWh self-consumed)": 40779.115841,
    "out:PV (ratio sold/self-consumed)": 1.082501,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 290173.078958,
    "out:EL kWh savings": -46426.892991,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 263534.47,
    "out:DH kr savings": 290173.0789584727,
    "out:El kr savings": -92853.78598220034,
    "out:El kr sold": 66215.17947483633,
    "out:El kr saved": 81558.231682,
    "out:El kr return": 147773.41115683632,
    "out:% solar/total": 102,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 249741.858666,
    "out:% savings (space heating)": 38.659792,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 997124.4110739925,
    "out:Eupp": 70.1,
    "out:Etvv": 0,
    "out:Ef": 25.98,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 4688730.844517742,
    "out:Seasonal Variation ROI (%)": 5,
    "out:Seasonal Variation Payback": 20,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 28.078752,
    "out:EL CO2": -66.353781,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -69.946081,
    "out:Bought CO2": 3.5923,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00000001120000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 73.14821,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 39.882685,
    "out:Electricity (inc PV)": 27.092518,
    "out:Total Energy Use Pre PV": 112.882685,
    "out:Total Energy Use Post PV": 100.092518,
    "out:Primary Energy": 99.242001,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 33.231142,
    "out:CO2 Operational/m2": -40.171723,
    "out:Total CO2/m2": -6.940581,
    "out:Total CO2 (tons)": -22.098803,
    "out:Klimatpaverkan": -222.234224,
    "out:Initial Cost/MSEK": 4.768330819517743,
    "out:Running cost/(Apt SEK y)": 17195.12766,
    "out:Running Cost over RSP/MSEK": 6.3293,
    "out:LCP/MSEK": 2.307089,
    "out:ROI% old": 21.197654,
    "out:Payback discounted": 6,
    "out:Atemp": 3184,
    "out:Umean": 1,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 100,
    "out:Energy use kWh/m2": 100,
    "out:Energy savings %": 83,
    "out:Op energy cost/MSEK": 46,
    "out:El price/MSEK": 30,
    "out:Heating price/MSEK": 14,
    "out:DH+ DHW/kWh/m2": 73,
    "out:El bought/kWh/m2": 1914.663453,
    "out:Return %": 19,
    "out:Return/kSEK/y": 907,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.6502792112,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 33.36,
    "out:EAHP (El)": 25.013185,
    "out:PV (kr/kWp)": 17461.48474631725,
    "out:PV (kWp installed)": 90.5097,
    "out:PV (m2 panels)": 474.867263,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 84922.568824,
    "out:PV (kWh sold)": 44198.688535,
    "out:PV (% sold (El))": 52.045869,
    "out:PV (kWh self-consumed)": 40723.880289,
    "out:PV (ratio sold/self-consumed)": 1.085326,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 307011.10706,
    "out:EL kWh savings": -46239.683399,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 79599.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 280829.77,
    "out:DH kr savings": 307011.1070602554,
    "out:El kr savings": -92479.36679885541,
    "out:El kr sold": 66298.03280263998,
    "out:El kr saved": 81447.760578,
    "out:El kr return": 147745.79338063998,
    "out:% solar/total": 102,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 232903.830564,
    "out:% savings (space heating)": 42.795455,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 997124.4110739925,
    "out:Eupp": 64.9,
    "out:Etvv": 0,
    "out:Ef": 25.9,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 4768330.819517743,
    "out:Seasonal Variation ROI (%)": 5,
    "out:Seasonal Variation Payback": 20,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 26.278832,
    "out:EL CO2": -66.450555,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -70.03507,
    "out:Bought CO2": 3.584515,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00000001120001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 75.267875,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 39.402667,
    "out:Electricity (inc PV)": 26.722352,
    "out:Total Energy Use Pre PV": 114.402667,
    "out:Total Energy Use Post PV": 101.722352,
    "out:Primary Energy": 101.203816,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 33.939685,
    "out:CO2 Operational/m2": -40.064268,
    "out:Total CO2/m2": -6.124583,
    "out:Total CO2 (tons)": -19.500666,
    "out:Klimatpaverkan": -219.636087,
    "out:Initial Cost/MSEK": 4.961946844517742,
    "out:Running cost/(Apt SEK y)": 17543,
    "out:Running Cost over RSP/MSEK": 6.45743,
    "out:LCP/MSEK": 1.985343,
    "out:ROI% old": 20.001625,
    "out:Payback discounted": 6,
    "out:Atemp": 3184,
    "out:Umean": 1,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 102,
    "out:Energy use kWh/m2": 102,
    "out:Energy savings %": 79.4,
    "out:Op energy cost/MSEK": 47,
    "out:El price/MSEK": 30,
    "out:Heating price/MSEK": 15,
    "out:DH+ DHW/kWh/m2": 75,
    "out:El bought/kWh/m2": 1888.436546,
    "out:Return %": 18,
    "out:Return/kSEK/y": 890,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.6502792112,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 28.356,
    "out:EAHP (El)": 24.533167,
    "out:PV (kr/kWp)": 17461.48474631725,
    "out:PV (kWp installed)": 90.5097,
    "out:PV (m2 panels)": 474.867263,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 84922.568824,
    "out:PV (kWh sold)": 44548.457714,
    "out:PV (% sold (El))": 52.457737,
    "out:PV (kWh self-consumed)": 40374.11111,
    "out:PV (ratio sold/self-consumed)": 1.103392,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 300262.095731,
    "out:EL kWh savings": -45061.07521,
    "out:Sewage heat recovery cost": 273216,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 276962.63,
    "out:DH kr savings": 300262.09573089454,
    "out:El kr savings": -90122.15042009357,
    "out:El kr sold": 66822.68657087034,
    "out:El kr saved": 80748.22222,
    "out:El kr return": 147570.90879087034,
    "out:% solar/total": 101,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 239652.841893,
    "out:% savings (space heating)": 41.1378,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 997124.4110739925,
    "out:Eupp": 68.28,
    "out:Etvv": 0,
    "out:Ef": 25.71,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 4961946.844517742,
    "out:Seasonal Variation ROI (%)": 5,
    "out:Seasonal Variation Payback": 20,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 26.9988,
    "out:EL CO2": -67.063068,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -70.598567,
    "out:Bought CO2": 3.535499,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00000001120001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 70.011418,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 39.316864,
    "out:Electricity (inc PV)": 26.656278,
    "out:Total Energy Use Pre PV": 109.316864,
    "out:Total Energy Use Post PV": 96.656278,
    "out:Primary Energy": 97.058674,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 33.939685,
    "out:CO2 Operational/m2": -41.974166,
    "out:Total CO2/m2": -8.034481,
    "out:Total CO2 (tons)": -25.58178,
    "out:Klimatpaverkan": -225.717201,
    "out:Initial Cost/MSEK": 5.041546819517742,
    "out:Running cost/(Apt SEK y)": 16509.106383,
    "out:Running Cost over RSP/MSEK": 6.07739,
    "out:LCP/MSEK": 2.285783,
    "out:ROI% old": 20.762704,
    "out:Payback discounted": 6,
    "out:Atemp": 3184,
    "out:Umean": 1,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 97,
    "out:Energy use kWh/m2": 97,
    "out:Energy savings %": 88.7,
    "out:Op energy cost/MSEK": 45,
    "out:El price/MSEK": 29,
    "out:Heating price/MSEK": 14,
    "out:DH+ DHW/kWh/m2": 70,
    "out:El bought/kWh/m2": 1883.754318,
    "out:Return %": 19,
    "out:Return/kSEK/y": 939,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.6502792112,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 28.356,
    "out:EAHP (El)": 24.447364,
    "out:PV (kr/kWp)": 17461.48474631725,
    "out:PV (kWp installed)": 90.5097,
    "out:PV (m2 panels)": 474.867263,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 84922.568824,
    "out:PV (kWh sold)": 44611.275009,
    "out:PV (% sold (El))": 52.531707,
    "out:PV (kWh self-consumed)": 40311.293815,
    "out:PV (ratio sold/self-consumed)": 1.106669,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 316998.649783,
    "out:EL kWh savings": -44850.695657,
    "out:Sewage heat recovery cost": 273216,
    "out:Balancing hydronic system cost": 79599.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 294214.17,
    "out:DH kr savings": 316998.6497832087,
    "out:El kr savings": -89701.39131469125,
    "out:El kr sold": 66916.91251416088,
    "out:El kr saved": 80622.58763,
    "out:El kr return": 147539.50014416088,
    "out:% solar/total": 101,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 222916.287841,
    "out:% savings (space heating)": 45.24854,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 997124.4110739925,
    "out:Eupp": 63.1,
    "out:Etvv": 0,
    "out:Ef": 25.62,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 5041546.819517742,
    "out:Seasonal Variation ROI (%)": 5,
    "out:Seasonal Variation Payback": 20,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 25.19888,
    "out:EL CO2": -67.173046,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -70.699796,
    "out:Bought CO2": 3.52675,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00000002000000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 29.765009,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 57.235051,
    "out:Electricity (inc PV)": 57.235051,
    "out:Total Energy Use Pre PV": 87.235051,
    "out:Total Energy Use Post PV": 87.235051,
    "out:Primary Energy": 120.77193,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 4.296675,
    "out:CO2 Operational/m2": 18.355005,
    "out:Total CO2/m2": 22.65168,
    "out:Total CO2 (tons)": 72.122927,
    "out:Klimatpaverkan": -128.012494,
    "out:Initial Cost/MSEK": 4.575374411073992,
    "out:Running cost/(Apt SEK y)": 13892.404255,
    "out:Running Cost over RSP/MSEK": 5.074,
    "out:LCP/MSEK": 3.755346,
    "out:ROI% old": 26.011049,
    "out:Payback discounted": 5,
    "out:Atemp": 3184,
    "out:Umean": 1,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 87,
    "out:Energy use kWh/m2": 87,
    "out:Energy savings %": 110.3,
    "out:Op energy cost/MSEK": 35,
    "out:El price/MSEK": 28,
    "out:Heating price/MSEK": 6,
    "out:DH+ DHW/kWh/m2": 30,
    "out:El bought/kWh/m2": 4006.45357,
    "out:Return %": 23,
    "out:Return/kSEK/y": 1062,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 1774800,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 88740,
    "out:EAHP (replacement cost)": 1325203.921671023,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 41.7,
    "out:EAHP (El)": 42.365551,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 445143.177483,
    "out:EL kWh savings": -142213.479595,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 160716.22,
    "out:DH kr savings": 445143.17748314893,
    "out:El kr savings": -284426.95918976224,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 94771.760141,
    "out:% savings (space heating)": 76.722687,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 997124.4110739925,
    "out:Eupp": 22.82,
    "out:Etvv": 0,
    "out:Ef": 54.28,
    "out:heatPumpQuantity": 12,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 4575374.411073992,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 10.79952,
    "out:EL CO2": 7.555485,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 7.555485,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00000002000000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 25.479751,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 56.854945,
    "out:Electricity (inc PV)": 56.854945,
    "out:Total Energy Use Pre PV": 81.854945,
    "out:Total Energy Use Post PV": 81.854945,
    "out:Primary Energy": 117.025547,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 4.296675,
    "out:CO2 Operational/m2": 16.504908,
    "out:Total CO2/m2": 20.801583,
    "out:Total CO2 (tons)": 66.23222,
    "out:Klimatpaverkan": -133.903201,
    "out:Initial Cost/MSEK": 4.6549743860739925,
    "out:Running cost/(Apt SEK y)": 12817.957447,
    "out:Running Cost over RSP/MSEK": 4.67924,
    "out:LCP/MSEK": 4.070506,
    "out:ROI% old": 26.777744,
    "out:Payback discounted": 5,
    "out:Atemp": 3184,
    "out:Umean": 1,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 82,
    "out:Energy use kWh/m2": 82,
    "out:Energy savings %": 123.2,
    "out:Op energy cost/MSEK": 32,
    "out:El price/MSEK": 27,
    "out:Heating price/MSEK": 5,
    "out:DH+ DHW/kWh/m2": 25,
    "out:El bought/kWh/m2": 3979.84615,
    "out:Return %": 24,
    "out:Return/kSEK/y": 1112,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 1774800,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 88740,
    "out:EAHP (replacement cost)": 1325203.921671023,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 41.7,
    "out:EAHP (El)": 41.985445,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 458787.43485,
    "out:EL kWh savings": -141003.222455,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 79599.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 176780.99,
    "out:DH kr savings": 458787.43484987173,
    "out:El kr savings": -282006.44491004536,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 81127.502774,
    "out:% savings (space heating)": 80.073913,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 997124.4110739925,
    "out:Eupp": 18.88,
    "out:Etvv": 0,
    "out:Ef": 53.79,
    "out:heatPumpQuantity": 12,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 4654974.386073993,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 8.9996,
    "out:EL CO2": 7.505308,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 7.505308,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00000002000001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 27.074414,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 56.154928,
    "out:Electricity (inc PV)": 56.154928,
    "out:Total Energy Use Pre PV": 83.154928,
    "out:Total Energy Use Post PV": 83.154928,
    "out:Primary Energy": 117.694218,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 5.005218,
    "out:CO2 Operational/m2": 17.132468,
    "out:Total CO2/m2": 22.137686,
    "out:Total CO2 (tons)": 70.486371,
    "out:Klimatpaverkan": -129.64905,
    "out:Initial Cost/MSEK": 4.848590411073992,
    "out:Running cost/(Apt SEK y)": 13132.297872,
    "out:Running Cost over RSP/MSEK": 4.7952,
    "out:LCP/MSEK": 3.76093,
    "out:ROI% old": 25.366783,
    "out:Payback discounted": 5,
    "out:Atemp": 3184,
    "out:Umean": 1,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 83,
    "out:Energy use kWh/m2": 83,
    "out:Energy savings %": 120.5,
    "out:Op energy cost/MSEK": 33,
    "out:El price/MSEK": 27,
    "out:Heating price/MSEK": 5,
    "out:DH+ DHW/kWh/m2": 27,
    "out:El bought/kWh/m2": 3930.84496,
    "out:Return %": 23,
    "out:Return/kSEK/y": 1097,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 1774800,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 88740,
    "out:EAHP (replacement cost)": 1325203.921671023,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 35.445,
    "out:EAHP (El)": 41.285428,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 453710.029386,
    "out:EL kWh savings": -138774.368998,
    "out:Sewage heat recovery cost": 273216,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 176161.29,
    "out:DH kr savings": 453710.0293855589,
    "out:El kr savings": -277548.7379952779,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 86204.908239,
    "out:% savings (space heating)": 78.826829,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 997124.4110739925,
    "out:Eupp": 21.36,
    "out:Etvv": 0,
    "out:Ef": 53.58,
    "out:heatPumpQuantity": 12,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 4848590.411073992,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 9.719568,
    "out:EL CO2": 7.4129,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 7.4129,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00000002000001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 22.914642,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 55.736796,
    "out:Electricity (inc PV)": 55.736796,
    "out:Total Energy Use Pre PV": 78.736796,
    "out:Total Energy Use Post PV": 78.736796,
    "out:Primary Energy": 113.962438,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 5.005218,
    "out:CO2 Operational/m2": 15.637335,
    "out:Total CO2/m2": 20.642553,
    "out:Total CO2 (tons)": 65.725869,
    "out:Klimatpaverkan": -134.409552,
    "out:Initial Cost/MSEK": 4.928190386073991,
    "out:Running cost/(Apt SEK y)": 12257.276596,
    "out:Running Cost over RSP/MSEK": 4.47377,
    "out:LCP/MSEK": 4.00276,
    "out:ROI% old": 25.888813,
    "out:Payback discounted": 5,
    "out:Atemp": 3184,
    "out:Umean": 1,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 79,
    "out:Energy use kWh/m2": 79,
    "out:Energy savings %": 131.6,
    "out:Op energy cost/MSEK": 31,
    "out:El price/MSEK": 26,
    "out:Heating price/MSEK": 4,
    "out:DH+ DHW/kWh/m2": 23,
    "out:El bought/kWh/m2": 3901.57572,
    "out:Return %": 23,
    "out:Return/kSEK/y": 1139,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 1774800,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 88740,
    "out:EAHP (replacement cost)": 1325203.921671023,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 35.445,
    "out:EAHP (El)": 40.867296,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 466954.739448,
    "out:EL kWh savings": -137443.03711,
    "out:Sewage heat recovery cost": 273216,
    "out:Balancing hydronic system cost": 79599.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 192068.67,
    "out:DH kr savings": 466954.73944849736,
    "out:El kr savings": -274886.0742204189,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 72960.198176,
    "out:% savings (space heating)": 82.079921,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 997124.4110739925,
    "out:Eupp": 17.53,
    "out:Etvv": 0,
    "out:Ef": 53.07,
    "out:heatPumpQuantity": 12,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 4928190.386073992,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 8.279632,
    "out:EL CO2": 7.357703,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 7.357703,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00000002010000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 29.765009,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 57.235051,
    "out:Electricity (inc PV)": 45.35344,
    "out:Total Energy Use Pre PV": 87.235051,
    "out:Total Energy Use Post PV": 75.35344,
    "out:Primary Energy": 99.38503,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 19.035775,
    "out:CO2 Operational/m2": 9.772254,
    "out:Total CO2/m2": 28.808029,
    "out:Total CO2 (tons)": 91.724737,
    "out:Klimatpaverkan": -108.410684,
    "out:Initial Cost/MSEK": 5.365594805296492,
    "out:Running cost/(Apt SEK y)": 12134.808511,
    "out:Running Cost over RSP/MSEK": 4.44535,
    "out:LCP/MSEK": 3.593775,
    "out:ROI% old": 23.854019,
    "out:Payback discounted": 5,
    "out:Atemp": 3184,
    "out:Umean": 1,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 75,
    "out:Energy use kWh/m2": 75,
    "out:Energy savings %": 144,
    "out:Op energy cost/MSEK": 31,
    "out:El price/MSEK": 25,
    "out:Heating price/MSEK": 6,
    "out:DH+ DHW/kWh/m2": 30,
    "out:El bought/kWh/m2": 3209.37895,
    "out:Return %": 21,
    "out:Return/kSEK/y": 1144,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 1774800,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 88740,
    "out:EAHP (replacement cost)": 1325203.921671023,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 41.7,
    "out:EAHP (El)": 42.365551,
    "out:PV (kr/kWp)": 17461.56255567083,
    "out:PV (kWp installed)": 45.25485,
    "out:PV (m2 panels)": 237.433632,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 42461.284501,
    "out:PV (kWh sold)": 4630.247339,
    "out:PV (% sold (El))": 10.904633,
    "out:PV (kWh self-consumed)": 37831.037162,
    "out:PV (ratio sold/self-consumed)": 0.122393,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 445143.177483,
    "out:EL kWh savings": -104382.441553,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 243323.67,
    "out:DH kr savings": 445143.17748314893,
    "out:El kr savings": -208764.883106929,
    "out:El kr sold": 6945.37100859154,
    "out:El kr saved": 75662.074324,
    "out:El kr return": 82607.44533259154,
    "out:% solar/total": 95,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 94771.760141,
    "out:% savings (space heating)": 76.722687,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 997124.4110739925,
    "out:Eupp": 22.82,
    "out:Etvv": 0,
    "out:Ef": 42.66,
    "out:heatPumpQuantity": 12,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 5365594.805296493,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 10.79952,
    "out:EL CO2": -1.027266,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -7.02977,
    "out:Bought CO2": 6.002504,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00000002010000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 25.479751,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 56.854945,
    "out:Electricity (inc PV)": 45.004877,
    "out:Total Energy Use Pre PV": 81.854945,
    "out:Total Energy Use Post PV": 70.004877,
    "out:Primary Energy": 95.695425,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 19.035775,
    "out:CO2 Operational/m2": 7.768658,
    "out:Total CO2/m2": 26.804433,
    "out:Total CO2 (tons)": 85.345289,
    "out:Klimatpaverkan": -114.790132,
    "out:Initial Cost/MSEK": 5.445194780296492,
    "out:Running cost/(Apt SEK y)": 11061.425532,
    "out:Running Cost over RSP/MSEK": 4.05096,
    "out:LCP/MSEK": 3.908565,
    "out:ROI% old": 24.540012,
    "out:Payback discounted": 5,
    "out:Atemp": 3184,
    "out:Umean": 1,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 70,
    "out:Energy use kWh/m2": 70,
    "out:Energy savings %": 161.4,
    "out:Op energy cost/MSEK": 28,
    "out:El price/MSEK": 23,
    "out:Heating price/MSEK": 5,
    "out:DH+ DHW/kWh/m2": 25,
    "out:El bought/kWh/m2": 3184.679175,
    "out:Return %": 22,
    "out:Return/kSEK/y": 1195,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 1774800,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 88740,
    "out:EAHP (replacement cost)": 1325203.921671023,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 41.7,
    "out:EAHP (El)": 41.985445,
    "out:PV (kr/kWp)": 17461.56255567083,
    "out:PV (kWp installed)": 45.25485,
    "out:PV (m2 panels)": 237.433632,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 42461.284501,
    "out:PV (kWh sold)": 4730.679666,
    "out:PV (% sold (El))": 11.14116,
    "out:PV (kWh self-consumed)": 37730.604835,
    "out:PV (ratio sold/self-consumed)": 0.12538,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 458787.43485,
    "out:EL kWh savings": -103272.617295,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 79599.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 259338.22,
    "out:DH kr savings": 458787.43484987173,
    "out:El kr savings": -206545.23459077562,
    "out:El kr sold": 7096.019499527005,
    "out:El kr saved": 75461.20967,
    "out:El kr return": 82557.229169527,
    "out:% solar/total": 94,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 81127.502774,
    "out:% savings (space heating)": 80.073913,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 997124.4110739925,
    "out:Eupp": 18.88,
    "out:Etvv": 0,
    "out:Ef": 42.21,
    "out:heatPumpQuantity": 12,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 5445194.780296492,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 8.9996,
    "out:EL CO2": -1.230942,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -7.187282,
    "out:Bought CO2": 5.95634,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00000002010001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 27.074414,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 56.154928,
    "out:Electricity (inc PV)": 44.364224,
    "out:Total Energy Use Pre PV": 83.154928,
    "out:Total Energy Use Post PV": 71.364224,
    "out:Primary Energy": 96.470951,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 19.744318,
    "out:CO2 Operational/m2": 8.107144,
    "out:Total CO2/m2": 27.851462,
    "out:Total CO2 (tons)": 88.679028,
    "out:Klimatpaverkan": -111.456393,
    "out:Initial Cost/MSEK": 5.638810805296493,
    "out:Running cost/(Apt SEK y)": 11377.765957,
    "out:Running Cost over RSP/MSEK": 4.16762,
    "out:LCP/MSEK": 3.598289,
    "out:ROI% old": 23.401844,
    "out:Payback discounted": 5,
    "out:Atemp": 3184,
    "out:Umean": 1,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 71,
    "out:Energy use kWh/m2": 71,
    "out:Energy savings %": 157.7,
    "out:Op energy cost/MSEK": 29,
    "out:El price/MSEK": 23,
    "out:Heating price/MSEK": 5,
    "out:DH+ DHW/kWh/m2": 27,
    "out:El bought/kWh/m2": 3139.269374,
    "out:Return %": 21,
    "out:Return/kSEK/y": 1180,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 1774800,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 88740,
    "out:EAHP (replacement cost)": 1325203.921671023,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 35.445,
    "out:EAHP (El)": 41.285428,
    "out:PV (kr/kWp)": 17461.56255567083,
    "out:PV (kWp installed)": 45.25485,
    "out:PV (m2 panels)": 237.433632,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 42461.284501,
    "out:PV (kWh sold)": 4919.695851,
    "out:PV (% sold (El))": 11.58631,
    "out:PV (kWh self-consumed)": 37541.58865,
    "out:PV (ratio sold/self-consumed)": 0.131047,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 453710.029386,
    "out:EL kWh savings": -101232.778757,
    "out:Sewage heat recovery cost": 273216,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 258624.02,
    "out:DH kr savings": 453710.0293855589,
    "out:El kr savings": -202465.55751426687,
    "out:El kr sold": 7379.5437763617565,
    "out:El kr saved": 75083.1773,
    "out:El kr return": 82462.72107636175,
    "out:% solar/total": 94,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 86204.908239,
    "out:% savings (space heating)": 78.826829,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 997124.4110739925,
    "out:Eupp": 21.36,
    "out:Etvv": 0,
    "out:Ef": 42.02,
    "out:heatPumpQuantity": 12,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 5638810.805296493,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 9.719568,
    "out:EL CO2": -1.612424,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -7.483914,
    "out:Bought CO2": 5.87149,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00000002010001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 22.914642,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 55.736796,
    "out:Electricity (inc PV)": 43.982361,
    "out:Total Energy Use Pre PV": 78.736796,
    "out:Total Energy Use Post PV": 66.982361,
    "out:Primary Energy": 92.804455,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 19.744318,
    "out:CO2 Operational/m2": 6.435484,
    "out:Total CO2/m2": 26.179802,
    "out:Total CO2 (tons)": 83.356464,
    "out:Klimatpaverkan": -116.778957,
    "out:Initial Cost/MSEK": 5.718410780296493,
    "out:Running cost/(Apt SEK y)": 10503.978723,
    "out:Running Cost over RSP/MSEK": 3.84662,
    "out:LCP/MSEK": 3.839689,
    "out:ROI% old": 23.878013,
    "out:Payback discounted": 5,
    "out:Atemp": 3184,
    "out:Umean": 1,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 67,
    "out:Energy use kWh/m2": 67,
    "out:Energy savings %": 173.1,
    "out:Op energy cost/MSEK": 27,
    "out:El price/MSEK": 22,
    "out:Heating price/MSEK": 4,
    "out:DH+ DHW/kWh/m2": 23,
    "out:El bought/kWh/m2": 3112.193667,
    "out:Return %": 21,
    "out:Return/kSEK/y": 1221,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 1774800,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 88740,
    "out:EAHP (replacement cost)": 1325203.921671023,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 35.445,
    "out:EAHP (El)": 40.867296,
    "out:PV (kr/kWp)": 17461.56255567083,
    "out:PV (kWp installed)": 45.25485,
    "out:PV (m2 panels)": 237.433632,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 42461.284501,
    "out:PV (kWh sold)": 5035.175976,
    "out:PV (% sold (El))": 11.858275,
    "out:PV (kWh self-consumed)": 37426.108526,
    "out:PV (ratio sold/self-consumed)": 0.134536,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 466954.739448,
    "out:EL kWh savings": -100016.927331,
    "out:Sewage heat recovery cost": 273216,
    "out:Balancing hydronic system cost": 79599.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 274473.65,
    "out:DH kr savings": 466954.73944849736,
    "out:El kr savings": -200033.8546619163,
    "out:El kr sold": 7552.763963527106,
    "out:El kr saved": 74852.217052,
    "out:El kr return": 82404.98101552711,
    "out:% solar/total": 94,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 72960.198176,
    "out:% savings (space heating)": 82.079921,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 997124.4110739925,
    "out:Eupp": 17.53,
    "out:Etvv": 0,
    "out:Ef": 41.56,
    "out:heatPumpQuantity": 12,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 5718410.780296492,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 8.279632,
    "out:EL CO2": -1.844148,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -7.665056,
    "out:Bought CO2": 5.820908,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00000002020000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 29.765009,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 57.235051,
    "out:Electricity (inc PV)": 40.926319,
    "out:Total Energy Use Pre PV": 87.235051,
    "out:Total Energy Use Post PV": 70.926319,
    "out:Primary Energy": 91.416212,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 33.774874,
    "out:CO2 Operational/m2": -35.765521,
    "out:Total CO2/m2": -1.990647,
    "out:Total CO2 (tons)": -6.338218,
    "out:Klimatpaverkan": -206.473639,
    "out:Initial Cost/MSEK": 6.155808157017743,
    "out:Running cost/(Apt SEK y)": 10629.702128,
    "out:Running Cost over RSP/MSEK": 3.90664,
    "out:LCP/MSEK": 3.342272,
    "out:ROI% old": 22.042087,
    "out:Payback discounted": 6,
    "out:Atemp": 3184,
    "out:Umean": 1,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 71,
    "out:Energy use kWh/m2": 71,
    "out:Energy savings %": 157.7,
    "out:Op energy cost/MSEK": 28,
    "out:El price/MSEK": 21,
    "out:Heating price/MSEK": 6,
    "out:DH+ DHW/kWh/m2": 30,
    "out:El bought/kWh/m2": 2893.365456,
    "out:Return %": 20,
    "out:Return/kSEK/y": 1215,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 1774800,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 88740,
    "out:EAHP (replacement cost)": 1325203.921671023,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 41.7,
    "out:EAHP (El)": 42.365551,
    "out:PV (kr/kWp)": 17461.48474631725,
    "out:PV (kWp installed)": 90.5097,
    "out:PV (m2 panels)": 474.867263,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 84922.568824,
    "out:PV (kWh sold)": 32995.582944,
    "out:PV (% sold (El))": 38.853727,
    "out:PV (kWh self-consumed)": 51926.98588,
    "out:PV (ratio sold/self-consumed)": 0.635423,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 445143.177483,
    "out:EL kWh savings": -90286.492531,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 314063.57,
    "out:DH kr savings": 445143.17748314893,
    "out:El kr savings": -180572.98506129283,
    "out:El kr sold": 49493.37441609535,
    "out:El kr saved": 103853.97176,
    "out:El kr return": 153347.34617609537,
    "out:% solar/total": 130,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 94771.760141,
    "out:% savings (space heating)": 76.722687,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 997124.4110739925,
    "out:Eupp": 22.82,
    "out:Etvv": 0,
    "out:Ef": 38.52,
    "out:heatPumpQuantity": 12,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 6155808.157017743,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 10.79952,
    "out:EL CO2": -46.565041,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -51.980418,
    "out:Bought CO2": 5.415377,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00000002020000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 25.479751,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 56.854945,
    "out:Electricity (inc PV)": 40.61568,
    "out:Total Energy Use Pre PV": 81.854945,
    "out:Total Energy Use Post PV": 65.61568,
    "out:Primary Energy": 87.79487,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 33.774874,
    "out:CO2 Operational/m2": -37.961645,
    "out:Total CO2/m2": -4.186771,
    "out:Total CO2 (tons)": -13.330675,
    "out:Klimatpaverkan": -213.466096,
    "out:Initial Cost/MSEK": 6.235408132017743,
    "out:Running cost/(Apt SEK y)": 9557.595745,
    "out:Running Cost over RSP/MSEK": 3.51271,
    "out:LCP/MSEK": 3.656602,
    "out:ROI% old": 22.66322,
    "out:Payback discounted": 5,
    "out:Atemp": 3184,
    "out:Umean": 1,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 66,
    "out:Energy use kWh/m2": 66,
    "out:Energy savings %": 177.3,
    "out:Op energy cost/MSEK": 25,
    "out:El price/MSEK": 19,
    "out:Heating price/MSEK": 5,
    "out:DH+ DHW/kWh/m2": 25,
    "out:El bought/kWh/m2": 2871.382879,
    "out:Return %": 20,
    "out:Return/kSEK/y": 1266,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 1774800,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 88740,
    "out:EAHP (replacement cost)": 1325203.921671023,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 41.7,
    "out:EAHP (El)": 41.985445,
    "out:PV (kr/kWp)": 17461.48474631725,
    "out:PV (kWp installed)": 90.5097,
    "out:PV (m2 panels)": 474.867263,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 84922.568824,
    "out:PV (kWh sold)": 33216.764737,
    "out:PV (% sold (El))": 39.114178,
    "out:PV (kWh self-consumed)": 51705.804087,
    "out:PV (ratio sold/self-consumed)": 0.642418,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 458787.43485,
    "out:EL kWh savings": -89297.418252,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 79599.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 330017.75,
    "out:DH kr savings": 458787.43484987173,
    "out:El kr savings": -178594.8365044771,
    "out:El kr sold": 49825.14710592262,
    "out:El kr saved": 103411.608174,
    "out:El kr return": 153236.75527992263,
    "out:% solar/total": 129,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 81127.502774,
    "out:% savings (space heating)": 80.073913,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 997124.4110739925,
    "out:Eupp": 18.88,
    "out:Etvv": 0,
    "out:Ef": 38.13,
    "out:heatPumpQuantity": 12,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 6235408.132017743,
    "out:Seasonal Variation ROI (%)": 5,
    "out:Seasonal Variation Payback": 20,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 8.9996,
    "out:EL CO2": -46.961245,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -52.335488,
    "out:Bought CO2": 5.374243,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00000002020001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 27.074414,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 56.154928,
    "out:Electricity (inc PV)": 40.044378,
    "out:Total Energy Use Pre PV": 83.154928,
    "out:Total Energy Use Post PV": 67.044378,
    "out:Primary Energy": 88.695228,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 34.483417,
    "out:CO2 Operational/m2": -37.976319,
    "out:Total CO2/m2": -3.492902,
    "out:Total CO2 (tons)": -11.121397,
    "out:Klimatpaverkan": -211.256818,
    "out:Initial Cost/MSEK": 6.429024157017743,
    "out:Running cost/(Apt SEK y)": 9876.297872,
    "out:Running Cost over RSP/MSEK": 3.63024,
    "out:LCP/MSEK": 3.345456,
    "out:ROI% old": 21.719537,
    "out:Payback discounted": 6,
    "out:Atemp": 3184,
    "out:Umean": 1,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 67,
    "out:Energy use kWh/m2": 67,
    "out:Energy savings %": 173.1,
    "out:Op energy cost/MSEK": 26,
    "out:El price/MSEK": 20,
    "out:Heating price/MSEK": 5,
    "out:DH+ DHW/kWh/m2": 27,
    "out:El bought/kWh/m2": 2830.957559,
    "out:Return %": 19,
    "out:Return/kSEK/y": 1251,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 1774800,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 88740,
    "out:EAHP (replacement cost)": 1325203.921671023,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 35.445,
    "out:EAHP (El)": 41.285428,
    "out:PV (kr/kWp)": 17461.48474631725,
    "out:PV (kWp installed)": 90.5097,
    "out:PV (m2 panels)": 474.867263,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 84922.568824,
    "out:PV (kWh sold)": 33626.593699,
    "out:PV (% sold (El))": 39.596769,
    "out:PV (kWh self-consumed)": 51295.975125,
    "out:PV (ratio sold/self-consumed)": 0.655541,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 453710.029386,
    "out:EL kWh savings": -87478.393232,
    "out:Sewage heat recovery cost": 273216,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 329193.13,
    "out:DH kr savings": 453710.0293855589,
    "out:El kr savings": -174956.7864630917,
    "out:El kr sold": 50439.89054797775,
    "out:El kr saved": 102591.95025,
    "out:El kr return": 153031.84079797775,
    "out:% solar/total": 128,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 86204.908239,
    "out:% savings (space heating)": 78.826829,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 997124.4110739925,
    "out:Eupp": 21.36,
    "out:Etvv": 0,
    "out:Ef": 37.96,
    "out:heatPumpQuantity": 12,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 6429024.157017743,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 9.719568,
    "out:EL CO2": -47.695887,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -52.994506,
    "out:Bought CO2": 5.298619,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00000002020001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 22.914642,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 55.736796,
    "out:Electricity (inc PV)": 39.703805,
    "out:Total Energy Use Pre PV": 78.736796,
    "out:Total Energy Use Post PV": 62.703805,
    "out:Primary Energy": 85.103054,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 34.483417,
    "out:CO2 Operational/m2": -39.858288,
    "out:Total CO2/m2": -5.374871,
    "out:Total CO2 (tons)": -17.113584,
    "out:Klimatpaverkan": -217.249005,
    "out:Initial Cost/MSEK": 6.508624132017744,
    "out:Running cost/(Apt SEK y)": 9003.914894,
    "out:Running Cost over RSP/MSEK": 3.30975,
    "out:LCP/MSEK": 3.586346,
    "out:ROI% old": 22.157349,
    "out:Payback discounted": 6,
    "out:Atemp": 3184,
    "out:Umean": 1,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 63,
    "out:Energy use kWh/m2": 63,
    "out:Energy savings %": 190.5,
    "out:Op energy cost/MSEK": 24,
    "out:El price/MSEK": 18,
    "out:Heating price/MSEK": 4,
    "out:DH+ DHW/kWh/m2": 23,
    "out:El bought/kWh/m2": 2806.848669,
    "out:Return %": 20,
    "out:Return/kSEK/y": 1292,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 1774800,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 88740,
    "out:EAHP (replacement cost)": 1325203.921671023,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 35.445,
    "out:EAHP (El)": 40.867296,
    "out:PV (kr/kWp)": 17461.48474631725,
    "out:PV (kWp installed)": 90.5097,
    "out:PV (m2 panels)": 474.867263,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 84922.568824,
    "out:PV (kWh sold)": 33873.541026,
    "out:PV (% sold (El))": 39.887561,
    "out:PV (kWh self-consumed)": 51049.027798,
    "out:PV (ratio sold/self-consumed)": 0.663549,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 466954.739448,
    "out:EL kWh savings": -86394.009126,
    "out:Sewage heat recovery cost": 273216,
    "out:Balancing hydronic system cost": 79599.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 344977.03,
    "out:DH kr savings": 466954.73944849736,
    "out:El kr savings": -172788.01825162963,
    "out:El kr sold": 50810.3115396686,
    "out:El kr saved": 102098.055596,
    "out:El kr return": 152908.3671356686,
    "out:% solar/total": 128,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 72960.198176,
    "out:% savings (space heating)": 82.079921,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 997124.4110739925,
    "out:Eupp": 17.53,
    "out:Etvv": 0,
    "out:Ef": 37.54,
    "out:heatPumpQuantity": 12,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 6508624.132017744,
    "out:Seasonal Variation ROI (%)": 5,
    "out:Seasonal Variation Payback": 20,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 8.279632,
    "out:EL CO2": -48.13792,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -53.391452,
    "out:Bought CO2": 5.253532,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00000002100000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 26.200854,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 55.787825,
    "out:Electricity (inc PV)": 55.787825,
    "out:Total Energy Use Pre PV": 81.787825,
    "out:Total Energy Use Post PV": 81.787825,
    "out:Primary Energy": 116.662703,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 4.296675,
    "out:CO2 Operational/m2": 16.724023,
    "out:Total CO2/m2": 21.020698,
    "out:Total CO2 (tons)": 66.929882,
    "out:Klimatpaverkan": -133.205539,
    "out:Initial Cost/MSEK": 4.699422098573992,
    "out:Running cost/(Apt SEK y)": 12877.957447,
    "out:Running Cost over RSP/MSEK": 4.70191,
    "out:LCP/MSEK": 4.003388,
    "out:ROI% old": 26.455563,
    "out:Payback discounted": 5,
    "out:Atemp": 3184,
    "out:Umean": 1,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 82,
    "out:Energy use kWh/m2": 82,
    "out:Energy savings %": 123.2,
    "out:Op energy cost/MSEK": 33,
    "out:El price/MSEK": 27,
    "out:Heating price/MSEK": 5,
    "out:DH+ DHW/kWh/m2": 26,
    "out:El bought/kWh/m2": 3905.14775,
    "out:Return %": 24,
    "out:Return/kSEK/y": 1109,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 1774800,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 88740,
    "out:EAHP (replacement cost)": 1325203.921671023,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 33.36,
    "out:EAHP (El)": 40.918325,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 456491.443589,
    "out:EL kWh savings": -137605.513397,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 181280.42,
    "out:DH kr savings": 456491.44358868845,
    "out:El kr savings": -275211.0267946473,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 83423.494036,
    "out:% savings (space heating)": 79.509985,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 997124.4110739925,
    "out:Eupp": 20.89,
    "out:Etvv": 0,
    "out:Ef": 53.35,
    "out:heatPumpQuantity": 12,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 4699422.098573992,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 9.359584,
    "out:EL CO2": 7.364439,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 7.364439,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00000002100000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 22.084656,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 55.356489,
    "out:Electricity (inc PV)": 55.356489,
    "out:Total Energy Use Pre PV": 77.356489,
    "out:Total Energy Use Post PV": 77.356489,
    "out:Primary Energy": 112.936554,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 4.296675,
    "out:CO2 Operational/m2": 15.227147,
    "out:Total CO2/m2": 19.523822,
    "out:Total CO2 (tons)": 62.163831,
    "out:Klimatpaverkan": -137.971591,
    "out:Initial Cost/MSEK": 4.779022073573993,
    "out:Running cost/(Apt SEK y)": 12001.170213,
    "out:Running Cost over RSP/MSEK": 4.37983,
    "out:LCP/MSEK": 4.245868,
    "out:ROI% old": 26.977695,
    "out:Payback discounted": 5,
    "out:Atemp": 3184,
    "out:Umean": 1,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 77,
    "out:Energy use kWh/m2": 77,
    "out:Energy savings %": 137.7,
    "out:Op energy cost/MSEK": 30,
    "out:El price/MSEK": 25,
    "out:Heating price/MSEK": 4,
    "out:DH+ DHW/kWh/m2": 22,
    "out:El bought/kWh/m2": 3874.95423,
    "out:Return %": 24,
    "out:Return/kSEK/y": 1151,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 1774800,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 88740,
    "out:EAHP (replacement cost)": 1325203.921671023,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 33.36,
    "out:EAHP (El)": 40.486989,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 469597.414077,
    "out:EL kWh savings": -136232.139987,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 79599.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 197133.13,
    "out:DH kr savings": 469597.4140773707,
    "out:El kr savings": -272464.27997308713,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 70317.523547,
    "out:% savings (space heating)": 82.729,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 997124.4110739925,
    "out:Eupp": 17.09,
    "out:Etvv": 0,
    "out:Ef": 52.82,
    "out:heatPumpQuantity": 12,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 4779022.073573993,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 7.919648,
    "out:EL CO2": 7.307499,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 7.307499,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00000002100001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 24.154392,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 54.891601,
    "out:Electricity (inc PV)": 54.891601,
    "out:Total Energy Use Pre PV": 78.891601,
    "out:Total Energy Use Post PV": 78.891601,
    "out:Primary Energy": 114.178501,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 5.005218,
    "out:CO2 Operational/m2": 15.885746,
    "out:Total CO2/m2": 20.890964,
    "out:Total CO2 (tons)": 66.516809,
    "out:Klimatpaverkan": -133.618612,
    "out:Initial Cost/MSEK": 4.972638098573992,
    "out:Running cost/(Apt SEK y)": 12347.361702,
    "out:Running Cost over RSP/MSEK": 4.50737,
    "out:LCP/MSEK": 3.924712,
    "out:ROI% old": 25.560879,
    "out:Payback discounted": 5,
    "out:Atemp": 3184,
    "out:Umean": 1,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 79,
    "out:Energy use kWh/m2": 79,
    "out:Energy savings %": 131.6,
    "out:Op energy cost/MSEK": 31,
    "out:El price/MSEK": 26,
    "out:Heating price/MSEK": 5,
    "out:DH+ DHW/kWh/m2": 24,
    "out:El bought/kWh/m2": 3842.41207,
    "out:Return %": 23,
    "out:Return/kSEK/y": 1134,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 1774800,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 88740,
    "out:EAHP (replacement cost)": 1325203.921671023,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 28.356,
    "out:EAHP (El)": 40.022101,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 463007.376636,
    "out:EL kWh savings": -134751.93704,
    "out:Sewage heat recovery cost": 273216,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 193503.5,
    "out:DH kr savings": 463007.3766361778,
    "out:El kr savings": -269503.87407981255,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 76907.560988,
    "out:% savings (space heating)": 81.110392,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 997124.4110739925,
    "out:Eupp": 19.77,
    "out:Etvv": 0,
    "out:Ef": 52.78,
    "out:heatPumpQuantity": 12,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 4972638.098573992,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 8.639616,
    "out:EL CO2": 7.24613,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 7.24613,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00000002100001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 20.139429,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 54.429588,
    "out:Electricity (inc PV)": 54.429588,
    "out:Total Energy Use Pre PV": 74.429588,
    "out:Total Energy Use Post PV": 74.429588,
    "out:Primary Energy": 110.464962,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 5.005218,
    "out:CO2 Operational/m2": 14.384821,
    "out:Total CO2/m2": 19.390039,
    "out:Total CO2 (tons)": 61.737866,
    "out:Klimatpaverkan": -138.397555,
    "out:Initial Cost/MSEK": 5.052238073573991,
    "out:Running cost/(Apt SEK y)": 11466.404255,
    "out:Running Cost over RSP/MSEK": 4.18378,
    "out:LCP/MSEK": 4.168702,
    "out:ROI% old": 26.073141,
    "out:Payback discounted": 5,
    "out:Atemp": 3184,
    "out:Umean": 1,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 74,
    "out:Energy use kWh/m2": 74,
    "out:Energy savings %": 147.3,
    "out:Op energy cost/MSEK": 29,
    "out:El price/MSEK": 24,
    "out:Heating price/MSEK": 4,
    "out:DH+ DHW/kWh/m2": 20,
    "out:El bought/kWh/m2": 3810.07116,
    "out:Return %": 23,
    "out:Return/kSEK/y": 1176,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 1774800,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 88740,
    "out:EAHP (replacement cost)": 1325203.921671023,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 28.356,
    "out:EAHP (El)": 39.560088,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 475791.014982,
    "out:EL kWh savings": -133280.888091,
    "out:Sewage heat recovery cost": 273216,
    "out:Balancing hydronic system cost": 79599.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 209229.24,
    "out:DH kr savings": 475791.01498184324,
    "out:El kr savings": -266561.77618102933,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 64123.922642,
    "out:% savings (space heating)": 84.250238,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 997124.4110739925,
    "out:Eupp": 16.06,
    "out:Etvv": 0,
    "out:Ef": 52.22,
    "out:heatPumpQuantity": 12,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 5052238.073573992,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 7.19968,
    "out:EL CO2": 7.185141,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 7.185141,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00000002110000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 26.200854,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 55.787825,
    "out:Electricity (inc PV)": 44.028924,
    "out:Total Energy Use Pre PV": 81.787825,
    "out:Total Energy Use Post PV": 70.028924,
    "out:Primary Energy": 95.496681,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 19.035775,
    "out:CO2 Operational/m2": 7.543885,
    "out:Total CO2/m2": 26.57966,
    "out:Total CO2 (tons)": 84.629612,
    "out:Klimatpaverkan": -115.505809,
    "out:Initial Cost/MSEK": 5.4896424927964915,
    "out:Running cost/(Apt SEK y)": 11124.510638,
    "out:Running Cost over RSP/MSEK": 4.07471,
    "out:LCP/MSEK": 3.840368,
    "out:ROI% old": 24.279515,
    "out:Payback discounted": 5,
    "out:Atemp": 3184,
    "out:Umean": 1,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 70,
    "out:Energy use kWh/m2": 70,
    "out:Energy savings %": 161.4,
    "out:Op energy cost/MSEK": 29,
    "out:El price/MSEK": 23,
    "out:Heating price/MSEK": 5,
    "out:DH+ DHW/kWh/m2": 26,
    "out:El bought/kWh/m2": 3115.495959,
    "out:Return %": 22,
    "out:Return/kSEK/y": 1192,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 1774800,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 88740,
    "out:EAHP (replacement cost)": 1325203.921671023,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 33.36,
    "out:EAHP (El)": 40.918325,
    "out:PV (kr/kWp)": 17461.56255567083,
    "out:PV (kWp installed)": 45.25485,
    "out:PV (m2 panels)": 237.433632,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 42461.284501,
    "out:PV (kWh sold)": 5020.955391,
    "out:PV (% sold (El))": 11.824785,
    "out:PV (kWh self-consumed)": 37440.329111,
    "out:PV (ratio sold/self-consumed)": 0.134106,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 456491.443589,
    "out:EL kWh savings": -100165.183878,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 263692.51,
    "out:DH kr savings": 456491.44358868845,
    "out:El kr savings": -200330.36775670163,
    "out:El kr sold": 7531.433086092061,
    "out:El kr saved": 74880.658222,
    "out:El kr return": 82412.09130809206,
    "out:% solar/total": 94,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 83423.494036,
    "out:% savings (space heating)": 79.509985,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 997124.4110739925,
    "out:Eupp": 20.89,
    "out:Etvv": 0,
    "out:Ef": 41.81,
    "out:heatPumpQuantity": 12,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 5489642.492796492,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 9.359584,
    "out:EL CO2": -1.815699,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -7.642776,
    "out:Bought CO2": 5.827077,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00000002110000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 22.084656,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 55.356489,
    "out:Electricity (inc PV)": 43.635602,
    "out:Total Energy Use Pre PV": 77.356489,
    "out:Total Energy Use Post PV": 65.635602,
    "out:Primary Energy": 91.838958,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 19.035775,
    "out:CO2 Operational/m2": 5.86207,
    "out:Total CO2/m2": 24.897845,
    "out:Total CO2 (tons)": 79.274715,
    "out:Klimatpaverkan": -120.860706,
    "out:Initial Cost/MSEK": 5.569242467796493,
    "out:Running cost/(Apt SEK y)": 10249,
    "out:Running Cost over RSP/MSEK": 3.75308,
    "out:LCP/MSEK": 4.082398,
    "out:ROI% old": 24.757509,
    "out:Payback discounted": 5,
    "out:Atemp": 3184,
    "out:Umean": 1,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 66,
    "out:Energy use kWh/m2": 66,
    "out:Energy savings %": 177.3,
    "out:Op energy cost/MSEK": 26,
    "out:El price/MSEK": 21,
    "out:Heating price/MSEK": 4,
    "out:DH+ DHW/kWh/m2": 22,
    "out:El bought/kWh/m2": 3087.599613,
    "out:Return %": 22,
    "out:Return/kSEK/y": 1233,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 1774800,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 88740,
    "out:EAHP (replacement cost)": 1325203.921671023,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 33.36,
    "out:EAHP (El)": 40.486989,
    "out:PV (kr/kWp)": 17461.56255567083,
    "out:PV (kWp installed)": 45.25485,
    "out:PV (m2 panels)": 237.433632,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 42461.284501,
    "out:PV (kWh sold)": 5141.993085,
    "out:PV (% sold (El))": 12.109839,
    "out:PV (kWh self-consumed)": 37319.291416,
    "out:PV (ratio sold/self-consumed)": 0.137784,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 469597.414077,
    "out:EL kWh savings": -98912.847007,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 79599.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 279484.71,
    "out:DH kr savings": 469597.4140773707,
    "out:El kr savings": -197825.6940143066,
    "out:El kr sold": 7712.989627659753,
    "out:El kr saved": 74638.582832,
    "out:El kr return": 82351.57245965976,
    "out:% solar/total": 93,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 70317.523547,
    "out:% savings (space heating)": 82.729,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 997124.4110739925,
    "out:Eupp": 17.09,
    "out:Etvv": 0,
    "out:Ef": 41.33,
    "out:heatPumpQuantity": 12,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 5569242.467796493,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 7.919648,
    "out:EL CO2": -2.057578,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -7.83255,
    "out:Bought CO2": 5.774972,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00000002110001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 24.154392,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 54.891601,
    "out:Electricity (inc PV)": 43.212147,
    "out:Total Energy Use Pre PV": 78.891601,
    "out:Total Energy Use Post PV": 67.212147,
    "out:Primary Energy": 93.155484,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 19.744318,
    "out:CO2 Operational/m2": 6.317844,
    "out:Total CO2/m2": 26.062162,
    "out:Total CO2 (tons)": 82.981899,
    "out:Klimatpaverkan": -117.153522,
    "out:Initial Cost/MSEK": 5.762858492796492,
    "out:Running cost/(Apt SEK y)": 10596.595745,
    "out:Running Cost over RSP/MSEK": 3.88111,
    "out:LCP/MSEK": 3.760752,
    "out:ROI% old": 23.608348,
    "out:Payback discounted": 5,
    "out:Atemp": 3184,
    "out:Umean": 1,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 67,
    "out:Energy use kWh/m2": 67,
    "out:Energy savings %": 173.1,
    "out:Op energy cost/MSEK": 27,
    "out:El price/MSEK": 22,
    "out:Heating price/MSEK": 5,
    "out:DH+ DHW/kWh/m2": 24,
    "out:El bought/kWh/m2": 3057.577678,
    "out:Return %": 21,
    "out:Return/kSEK/y": 1217,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 1774800,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 88740,
    "out:EAHP (replacement cost)": 1325203.921671023,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 28.356,
    "out:EAHP (El)": 40.022101,
    "out:PV (kr/kWp)": 17461.56255567083,
    "out:PV (kWp installed)": 45.25485,
    "out:PV (m2 panels)": 237.433632,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 42461.284501,
    "out:PV (kWh sold)": 5273.912599,
    "out:PV (% sold (El))": 12.420521,
    "out:PV (kWh self-consumed)": 37187.371903,
    "out:PV (ratio sold/self-consumed)": 0.14182,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 463007.376636,
    "out:EL kWh savings": -97564.566693,
    "out:Sewage heat recovery cost": 273216,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 275789.11,
    "out:DH kr savings": 463007.3766361778,
    "out:El kr savings": -195129.13338564627,
    "out:El kr sold": 7910.86889779213,
    "out:El kr saved": 74374.743806,
    "out:El kr return": 82285.61270379213,
    "out:% solar/total": 93,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 76907.560988,
    "out:% savings (space heating)": 81.110392,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 997124.4110739925,
    "out:Eupp": 19.77,
    "out:Etvv": 0,
    "out:Ef": 41.3,
    "out:heatPumpQuantity": 12,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 5762858.492796492,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 8.639616,
    "out:EL CO2": -2.321772,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -8.040678,
    "out:Bought CO2": 5.718906,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00000002110001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 20.139429,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 54.429588,
    "out:Electricity (inc PV)": 42.791954,
    "out:Total Energy Use Pre PV": 74.429588,
    "out:Total Energy Use Post PV": 62.791954,
    "out:Primary Energy": 89.517221,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 19.744318,
    "out:CO2 Operational/m2": 4.611953,
    "out:Total CO2/m2": 24.356271,
    "out:Total CO2 (tons)": 77.550344,
    "out:Klimatpaverkan": -122.585078,
    "out:Initial Cost/MSEK": 5.842458467796493,
    "out:Running cost/(Apt SEK y)": 9717.06383,
    "out:Running Cost over RSP/MSEK": 3.55802,
    "out:LCP/MSEK": 4.004242,
    "out:ROI% old": 24.076704,
    "out:Payback discounted": 5,
    "out:Atemp": 3184,
    "out:Umean": 1,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 63,
    "out:Energy use kWh/m2": 63,
    "out:Energy savings %": 190.5,
    "out:Op energy cost/MSEK": 25,
    "out:El price/MSEK": 20,
    "out:Heating price/MSEK": 4,
    "out:DH+ DHW/kWh/m2": 20,
    "out:El bought/kWh/m2": 3027.787035,
    "out:Return %": 22,
    "out:Return/kSEK/y": 1258,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 1774800,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 88740,
    "out:EAHP (replacement cost)": 1325203.921671023,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 28.356,
    "out:EAHP (El)": 39.560088,
    "out:PV (kr/kWp)": 17461.56255567083,
    "out:PV (kWp installed)": 45.25485,
    "out:PV (m2 panels)": 237.433632,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 42461.284501,
    "out:PV (kWh sold)": 5407.06752,
    "out:PV (% sold (El))": 12.734112,
    "out:PV (kWh self-consumed)": 37054.216981,
    "out:PV (ratio sold/self-consumed)": 0.145923,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 475791.014982,
    "out:EL kWh savings": -96226.672583,
    "out:Sewage heat recovery cost": 273216,
    "out:Balancing hydronic system cost": 79599.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 291448.27,
    "out:DH kr savings": 475791.01498184324,
    "out:El kr savings": -192453.34516673617,
    "out:El kr sold": 8110.601280673274,
    "out:El kr saved": 74108.433962,
    "out:El kr return": 82219.03524267327,
    "out:% solar/total": 93,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 64123.922642,
    "out:% savings (space heating)": 84.250238,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 997124.4110739925,
    "out:Eupp": 16.06,
    "out:Etvv": 0,
    "out:Ef": 40.8,
    "out:heatPumpQuantity": 12,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 5842458.467796492,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 7.19968,
    "out:EL CO2": -2.587727,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -8.251001,
    "out:Bought CO2": 5.663274,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00000002120000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 26.200854,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 55.787825,
    "out:Electricity (inc PV)": 39.745349,
    "out:Total Energy Use Pre PV": 81.787825,
    "out:Total Energy Use Post PV": 65.745349,
    "out:Primary Energy": 87.786246,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 33.774874,
    "out:CO2 Operational/m2": -38.724273,
    "out:Total CO2/m2": -4.949399,
    "out:Total CO2 (tons)": -15.758882,
    "out:Klimatpaverkan": -215.894303,
    "out:Initial Cost/MSEK": 6.279855844517743,
    "out:Running cost/(Apt SEK y)": 9624.276596,
    "out:Running Cost over RSP/MSEK": 3.53778,
    "out:LCP/MSEK": 3.587084,
    "out:ROI% old": 22.445784,
    "out:Payback discounted": 6,
    "out:Atemp": 3184,
    "out:Umean": 1,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 66,
    "out:Energy use kWh/m2": 66,
    "out:Energy savings %": 177.3,
    "out:Op energy cost/MSEK": 25,
    "out:El price/MSEK": 19,
    "out:Heating price/MSEK": 5,
    "out:DH+ DHW/kWh/m2": 26,
    "out:El bought/kWh/m2": 2809.789483,
    "out:Return %": 20,
    "out:Return/kSEK/y": 1262,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 1774800,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 88740,
    "out:EAHP (replacement cost)": 1325203.921671023,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 33.36,
    "out:EAHP (El)": 40.918325,
    "out:PV (kr/kWp)": 17461.48474631725,
    "out:PV (kWp installed)": 90.5097,
    "out:PV (m2 panels)": 474.867263,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 84922.568824,
    "out:PV (kWh sold)": 33843.341595,
    "out:PV (% sold (El))": 39.851999,
    "out:PV (kWh self-consumed)": 51079.227229,
    "out:PV (ratio sold/self-consumed)": 0.662566,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 456491.443589,
    "out:EL kWh savings": -86526.285182,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 334203.89,
    "out:DH kr savings": 456491.44358868845,
    "out:El kr savings": -173052.57036403133,
    "out:El kr sold": 50765.01239273079,
    "out:El kr saved": 102158.454458,
    "out:El kr return": 152923.46685073077,
    "out:% solar/total": 128,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 83423.494036,
    "out:% savings (space heating)": 79.509985,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 997124.4110739925,
    "out:Eupp": 20.89,
    "out:Etvv": 0,
    "out:Ef": 37.77,
    "out:heatPumpQuantity": 12,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 6279855.844517743,
    "out:Seasonal Variation ROI (%)": 5,
    "out:Seasonal Variation Payback": 20,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 9.359584,
    "out:EL CO2": -48.083857,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -53.342888,
    "out:Bought CO2": 5.259031,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00000002120000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 22.084656,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 55.356489,
    "out:Electricity (inc PV)": 39.394529,
    "out:Total Energy Use Pre PV": 77.356489,
    "out:Total Energy Use Post PV": 61.394529,
    "out:Primary Energy": 84.205026,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 33.774874,
    "out:CO2 Operational/m2": -40.622364,
    "out:Total CO2/m2": -6.84749,
    "out:Total CO2 (tons)": -21.802402,
    "out:Klimatpaverkan": -221.937823,
    "out:Initial Cost/MSEK": 6.359455819517745,
    "out:Running cost/(Apt SEK y)": 8750.212766,
    "out:Running Cost over RSP/MSEK": 3.21668,
    "out:LCP/MSEK": 3.828584,
    "out:ROI% old": 22.886145,
    "out:Payback discounted": 5,
    "out:Atemp": 3184,
    "out:Umean": 1,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 61,
    "out:Energy use kWh/m2": 61,
    "out:Energy savings %": 200,
    "out:Op energy cost/MSEK": 23,
    "out:El price/MSEK": 18,
    "out:Heating price/MSEK": 4,
    "out:DH+ DHW/kWh/m2": 22,
    "out:El bought/kWh/m2": 2784.945413,
    "out:Return %": 20,
    "out:Return/kSEK/y": 1303,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 1774800,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 88740,
    "out:EAHP (replacement cost)": 1325203.921671023,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 33.36,
    "out:EAHP (El)": 40.486989,
    "out:PV (kr/kWp)": 17461.48474631725,
    "out:PV (kWp installed)": 90.5097,
    "out:PV (m2 panels)": 474.867263,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 84922.568824,
    "out:PV (kWh sold)": 34099.705024,
    "out:PV (% sold (El))": 40.153878,
    "out:PV (kWh self-consumed)": 50822.8638,
    "out:PV (ratio sold/self-consumed)": 0.670952,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 469597.414077,
    "out:EL kWh savings": -85409.274638,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 79599.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 349928.42,
    "out:DH kr savings": 469597.4140773707,
    "out:El kr savings": -170818.54927620236,
    "out:El kr sold": 51149.55753632962,
    "out:El kr saved": 101645.7276,
    "out:El kr return": 152795.28513632962,
    "out:% solar/total": 127,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 70317.523547,
    "out:% savings (space heating)": 82.729,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 997124.4110739925,
    "out:Eupp": 17.09,
    "out:Etvv": 0,
    "out:Ef": 37.34,
    "out:heatPumpQuantity": 12,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 6359455.819517745,
    "out:Seasonal Variation ROI (%)": 5,
    "out:Seasonal Variation Payback": 20,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 7.919648,
    "out:EL CO2": -48.542012,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -53.754588,
    "out:Bought CO2": 5.212576,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00000002120001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 24.154392,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 54.891601,
    "out:Electricity (inc PV)": 39.016772,
    "out:Total Energy Use Pre PV": 78.891601,
    "out:Total Energy Use Post PV": 63.016772,
    "out:Primary Energy": 85.603809,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 34.483417,
    "out:CO2 Operational/m2": -40.398808,
    "out:Total CO2/m2": -5.915391,
    "out:Total CO2 (tons)": -18.834599,
    "out:Klimatpaverkan": -218.97002,
    "out:Initial Cost/MSEK": 6.553071844517743,
    "out:Running cost/(Apt SEK y)": 9099.340426,
    "out:Running Cost over RSP/MSEK": 3.34527,
    "out:LCP/MSEK": 3.506378,
    "out:ROI% old": 21.929628,
    "out:Payback discounted": 6,
    "out:Atemp": 3184,
    "out:Umean": 1,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 63,
    "out:Energy use kWh/m2": 63,
    "out:Energy savings %": 190.5,
    "out:Op energy cost/MSEK": 24,
    "out:El price/MSEK": 19,
    "out:Heating price/MSEK": 5,
    "out:DH+ DHW/kWh/m2": 24,
    "out:El bought/kWh/m2": 2758.203047,
    "out:Return %": 20,
    "out:Return/kSEK/y": 1287,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 1774800,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 88740,
    "out:EAHP (replacement cost)": 1325203.921671023,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 28.356,
    "out:EAHP (El)": 40.022101,
    "out:PV (kr/kWp)": 17461.48474631725,
    "out:PV (kWp installed)": 90.5097,
    "out:PV (m2 panels)": 474.867263,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 84922.568824,
    "out:PV (kWh sold)": 34377.129899,
    "out:PV (% sold (El))": 40.480558,
    "out:PV (kWh self-consumed)": 50545.438925,
    "out:PV (ratio sold/self-consumed)": 0.680123,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 463007.376636,
    "out:EL kWh savings": -84206.496712,
    "out:Sewage heat recovery cost": 273216,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 346160.08,
    "out:DH kr savings": 463007.3766361778,
    "out:El kr savings": -168412.99342398485,
    "out:El kr sold": 51565.69484783156,
    "out:El kr saved": 101090.87785,
    "out:El kr return": 152656.57269783155,
    "out:% solar/total": 126,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 76907.560988,
    "out:% savings (space heating)": 81.110392,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 997124.4110739925,
    "out:Eupp": 19.77,
    "out:Etvv": 0,
    "out:Ef": 37.31,
    "out:heatPumpQuantity": 12,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 6553071.844517743,
    "out:Seasonal Variation ROI (%)": 5,
    "out:Seasonal Variation Payback": 20,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 8.639616,
    "out:EL CO2": -49.038424,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -54.201,
    "out:Bought CO2": 5.162576,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00000002120001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 20.139429,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 54.429588,
    "out:Electricity (inc PV)": 38.641699,
    "out:Total Energy Use Pre PV": 74.429588,
    "out:Total Energy Use Post PV": 58.641699,
    "out:Primary Energy": 82.046762,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 34.483417,
    "out:CO2 Operational/m2": -42.334606,
    "out:Total CO2/m2": -7.851189,
    "out:Total CO2 (tons)": -24.998178,
    "out:Klimatpaverkan": -225.133599,
    "out:Initial Cost/MSEK": 6.632671819517744,
    "out:Running cost/(Apt SEK y)": 8221.340426,
    "out:Running Cost over RSP/MSEK": 3.02274,
    "out:LCP/MSEK": 3.749308,
    "out:ROI% old": 22.361124,
    "out:Payback discounted": 6,
    "out:Atemp": 3184,
    "out:Umean": 1,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 59,
    "out:Energy use kWh/m2": 59,
    "out:Energy savings %": 210.2,
    "out:Op energy cost/MSEK": 22,
    "out:El price/MSEK": 17,
    "out:Heating price/MSEK": 4,
    "out:DH+ DHW/kWh/m2": 20,
    "out:El bought/kWh/m2": 2731.661537,
    "out:Return %": 20,
    "out:Return/kSEK/y": 1328,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 1774800,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 88740,
    "out:EAHP (replacement cost)": 1325203.921671023,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 28.356,
    "out:EAHP (El)": 39.560088,
    "out:PV (kr/kWp)": 17461.48474631725,
    "out:PV (kWp installed)": 90.5097,
    "out:PV (m2 panels)": 474.867263,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 84922.568824,
    "out:PV (kWh sold)": 34653.944261,
    "out:PV (% sold (El))": 40.806519,
    "out:PV (kWh self-consumed)": 50268.624563,
    "out:PV (ratio sold/self-consumed)": 0.689375,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 475791.014982,
    "out:EL kWh savings": -83012.264639,
    "out:Sewage heat recovery cost": 273216,
    "out:Balancing hydronic system cost": 79599.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 361747.4,
    "out:DH kr savings": 475791.01498184324,
    "out:El kr savings": -166024.52927862483,
    "out:El kr sold": 51980.916391469946,
    "out:El kr saved": 100537.249126,
    "out:El kr return": 152518.16551746993,
    "out:% solar/total": 126,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 64123.922642,
    "out:% savings (space heating)": 84.250238,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 997124.4110739925,
    "out:Eupp": 16.06,
    "out:Etvv": 0,
    "out:Ef": 36.86,
    "out:heatPumpQuantity": 12,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 6632671.819517744,
    "out:Seasonal Variation ROI (%)": 5,
    "out:Seasonal Variation Payback": 20,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 7.19968,
    "out:EL CO2": -49.534286,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -54.647237,
    "out:Bought CO2": 5.112951,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00001000000000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 110.193654,
    "out:DHW (DH)": 41.7,
    "out:Property Electricity": 12.57,
    "out:Electricity (inc PV)": 12.57,
    "out:Total Energy Use Pre PV": 164.57,
    "out:Total Energy Use Post PV": 164.57,
    "out:Primary Energy": 125.832175,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 2.155882,
    "out:CO2 Operational/m2": 56.376909,
    "out:Total CO2/m2": 58.532791,
    "out:Total CO2 (tons)": 186.36835,
    "out:Klimatpaverkan": -13.767071,
    "out:Initial Cost/MSEK": 0.25721185300375,
    "out:Running cost/(Apt SEK y)": 32800.617021,
    "out:Running Cost over RSP/MSEK": 12.05092,
    "out:LCP/MSEK": 1.096588,
    "out:ROI% old": 75.190936,
    "out:Payback discounted": 2,
    "out:Atemp": 3184,
    "out:Umean": 0.82,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 165,
    "out:Energy use kWh/m2": 165,
    "out:Energy savings %": 10.9,
    "out:Op energy cost/MSEK": 88,
    "out:El price/MSEK": 54,
    "out:Heating price/MSEK": 30,
    "out:DH+ DHW/kWh/m2": 152,
    "out:El bought/kWh/m2": 879.9,
    "out:Return %": 67,
    "out:Return/kSEK/y": 173,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 41.7,
    "out:EAHP (El)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 22,
    "out:Payback Time": 4.545455,
    "out:DH kWh savings": 56285.687529,
    "out:EL kWh savings": 2.2624e-11,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 56285.69,
    "out:DH kr savings": 56285.68752879119,
    "out:El kr savings": 4.524734779920436e-11,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 350856.490044,
    "out:% savings (space heating)": 13.824578,
    "out:Total_Water Heating (DH)": 132772.760051,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 110.19,
    "out:Etvv": 25,
    "out:Ef": 12.57,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 257211.85300375,
    "out:Seasonal Variation ROI (%)": 20,
    "out:Seasonal Variation Payback": 5,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 54.717568,
    "out:EL CO2": 1.659341,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 1.659341,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00001000000000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 105.457579,
    "out:DHW (DH)": 41.7,
    "out:Property Electricity": 12.57,
    "out:Electricity (inc PV)": 12.57,
    "out:Total Energy Use Pre PV": 159.57,
    "out:Total Energy Use Post PV": 159.57,
    "out:Primary Energy": 122.148561,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 2.155882,
    "out:CO2 Operational/m2": 54.576989,
    "out:Total CO2/m2": 56.732871,
    "out:Total CO2 (tons)": 180.637407,
    "out:Klimatpaverkan": -19.498014,
    "out:Initial Cost/MSEK": 0.3368118280037499,
    "out:Running cost/(Apt SEK y)": 31777.659574,
    "out:Running Cost over RSP/MSEK": 11.67487,
    "out:LCP/MSEK": 1.393038,
    "out:ROI% old": 73.370769,
    "out:Payback discounted": 2,
    "out:Atemp": 3184,
    "out:Umean": 0.82,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 160,
    "out:Energy use kWh/m2": 160,
    "out:Energy savings %": 14.4,
    "out:Op energy cost/MSEK": 85,
    "out:El price/MSEK": 52,
    "out:Heating price/MSEK": 29,
    "out:DH+ DHW/kWh/m2": 147,
    "out:El bought/kWh/m2": 879.9,
    "out:Return %": 66,
    "out:Return/kSEK/y": 221,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 41.7,
    "out:EAHP (El)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 21,
    "out:Payback Time": 4.761905,
    "out:DH kWh savings": 71365.346428,
    "out:EL kWh savings": 2.2624e-11,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 79599.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 71365.35,
    "out:DH kr savings": 71365.3464284312,
    "out:El kr savings": 4.524734779920436e-11,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 335776.831144,
    "out:% savings (space heating)": 17.52836,
    "out:Total_Water Heating (DH)": 132772.760051,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 105.46,
    "out:Etvv": 25,
    "out:Ef": 12.57,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 336811.82800374995,
    "out:Seasonal Variation ROI (%)": 19,
    "out:Seasonal Variation Payback": 5.263158,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 52.917648,
    "out:EL CO2": 1.659341,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 1.659341,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00001000000001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 110.193654,
    "out:DHW (DH)": 35.445,
    "out:Property Electricity": 12.57,
    "out:Electricity (inc PV)": 12.57,
    "out:Total Energy Use Pre PV": 158.57,
    "out:Total Energy Use Post PV": 158.57,
    "out:Primary Energy": 123.207175,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 2.864425,
    "out:CO2 Operational/m2": 54.217005,
    "out:Total CO2/m2": 57.08143,
    "out:Total CO2 (tons)": 181.747218,
    "out:Klimatpaverkan": -18.388203,
    "out:Initial Cost/MSEK": 0.53042785300375,
    "out:Running cost/(Apt SEK y)": 31573.085106,
    "out:Running Cost over RSP/MSEK": 11.59965,
    "out:LCP/MSEK": 1.274642,
    "out:ROI% old": 48.614933,
    "out:Payback discounted": 3,
    "out:Atemp": 3184,
    "out:Umean": 0.82,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 159,
    "out:Energy use kWh/m2": 159,
    "out:Energy savings %": 15.1,
    "out:Op energy cost/MSEK": 85,
    "out:El price/MSEK": 52,
    "out:Heating price/MSEK": 28,
    "out:DH+ DHW/kWh/m2": 146,
    "out:El bought/kWh/m2": 879.9,
    "out:Return %": 44,
    "out:Return/kSEK/y": 231,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 35.445,
    "out:EAHP (El)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 14,
    "out:Payback Time": 7.142857,
    "out:DH kWh savings": 76201.601537,
    "out:EL kWh savings": 2.2624e-11,
    "out:Sewage heat recovery cost": 273216,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 76201.6,
    "out:DH kr savings": 76201.60153650118,
    "out:El kr savings": 4.524734779920436e-11,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 350856.490044,
    "out:% savings (space heating)": 13.824578,
    "out:Total_Water Heating (DH)": 112856.846044,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 110.19,
    "out:Etvv": 21.25,
    "out:Ef": 12.57,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 530427.85300375,
    "out:Seasonal Variation ROI (%)": 13,
    "out:Seasonal Variation Payback": 7.692308,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 52.557664,
    "out:EL CO2": 1.659341,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 1.659341,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00001000000001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 105.457579,
    "out:DHW (DH)": 35.445,
    "out:Property Electricity": 12.57,
    "out:Electricity (inc PV)": 12.57,
    "out:Total Energy Use Pre PV": 153.57,
    "out:Total Energy Use Post PV": 153.57,
    "out:Primary Energy": 119.523561,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 2.864425,
    "out:CO2 Operational/m2": 52.417085,
    "out:Total CO2/m2": 55.28151,
    "out:Total CO2 (tons)": 176.016275,
    "out:Klimatpaverkan": -24.119146,
    "out:Initial Cost/MSEK": 0.61002782800375,
    "out:Running cost/(Apt SEK y)": 30550.12766,
    "out:Running Cost over RSP/MSEK": 11.2236,
    "out:LCP/MSEK": 1.571092,
    "out:ROI% old": 51.077764,
    "out:Payback discounted": 3,
    "out:Atemp": 3184,
    "out:Umean": 0.82,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 154,
    "out:Energy use kWh/m2": 154,
    "out:Energy savings %": 18.8,
    "out:Op energy cost/MSEK": 82,
    "out:El price/MSEK": 50,
    "out:Heating price/MSEK": 27,
    "out:DH+ DHW/kWh/m2": 141,
    "out:El bought/kWh/m2": 879.9,
    "out:Return %": 46,
    "out:Return/kSEK/y": 279,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 35.445,
    "out:EAHP (El)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 15,
    "out:Payback Time": 6.666667,
    "out:DH kWh savings": 91281.260436,
    "out:EL kWh savings": 2.2624e-11,
    "out:Sewage heat recovery cost": 273216,
    "out:Balancing hydronic system cost": 79599.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 91281.26,
    "out:DH kr savings": 91281.26043614118,
    "out:El kr savings": 4.524734779920436e-11,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 335776.831144,
    "out:% savings (space heating)": 17.52836,
    "out:Total_Water Heating (DH)": 112856.846044,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 105.46,
    "out:Etvv": 21.25,
    "out:Ef": 12.57,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 610027.82800375,
    "out:Seasonal Variation ROI (%)": 14,
    "out:Seasonal Variation Payback": 7.142857,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 50.757744,
    "out:EL CO2": 1.659341,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 1.659341,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00001000000100000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 1,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 110.193654,
    "out:DHW (DH)": 41.7,
    "out:Property Electricity": 9.976115,
    "out:Electricity (inc PV)": 9.976115,
    "out:Total Energy Use Pre PV": 161.976115,
    "out:Total Energy Use Post PV": 161.976115,
    "out:Primary Energy": 121.163182,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 2.340384,
    "out:CO2 Operational/m2": 56.034495,
    "out:Total CO2/m2": 58.374879,
    "out:Total CO2 (tons)": 185.865559,
    "out:Klimatpaverkan": -14.269862,
    "out:Initial Cost/MSEK": 0.404004724523575,
    "out:Running cost/(Apt SEK y)": 32449.170213,
    "out:Running Cost over RSP/MSEK": 11.92322,
    "out:LCP/MSEK": 1.077495,
    "out:ROI% old": 52.386233,
    "out:Payback discounted": 3,
    "out:Atemp": 3184,
    "out:Umean": 0.82,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 162,
    "out:Energy use kWh/m2": 162,
    "out:Energy savings %": 13,
    "out:Op energy cost/MSEK": 87,
    "out:El price/MSEK": 53,
    "out:Heating price/MSEK": 30,
    "out:DH+ DHW/kWh/m2": 152,
    "out:El bought/kWh/m2": 698.32805,
    "out:Return %": 47,
    "out:Return/kSEK/y": 190,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 41.7,
    "out:EAHP (El)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 18,
    "out:Payback Time": 5.555556,
    "out:DH kWh savings": 56285.687529,
    "out:EL kWh savings": 8258.927355,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 72803.54,
    "out:DH kr savings": 56285.68752879119,
    "out:El kr savings": 16517.854710116386,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 350856.490044,
    "out:% savings (space heating)": 13.824578,
    "out:Total_Water Heating (DH)": 132772.760051,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 110.19,
    "out:Etvv": 25,
    "out:Ef": 9.98,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 404004.724523575,
    "out:Seasonal Variation ROI (%)": 17,
    "out:Seasonal Variation Payback": 5.882353,
    "out:New AHU Cost/SEK": 146792.87151982498,
    "out:DH CO2": 54.717568,
    "out:EL CO2": 1.316927,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 1.316927,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00001000000100100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 1,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 105.457579,
    "out:DHW (DH)": 41.7,
    "out:Property Electricity": 9.976115,
    "out:Electricity (inc PV)": 9.976115,
    "out:Total Energy Use Pre PV": 156.976115,
    "out:Total Energy Use Post PV": 156.976115,
    "out:Primary Energy": 117.479568,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 2.340384,
    "out:CO2 Operational/m2": 54.234575,
    "out:Total CO2/m2": 56.574959,
    "out:Total CO2 (tons)": 180.134615,
    "out:Klimatpaverkan": -20.000806,
    "out:Initial Cost/MSEK": 0.483604699523575,
    "out:Running cost/(Apt SEK y)": 31426.212766,
    "out:Running Cost over RSP/MSEK": 11.54717,
    "out:LCP/MSEK": 1.373945,
    "out:ROI% old": 54.872148,
    "out:Payback discounted": 3,
    "out:Atemp": 3184,
    "out:Umean": 0.82,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 157,
    "out:Energy use kWh/m2": 157,
    "out:Energy savings %": 16.6,
    "out:Op energy cost/MSEK": 85,
    "out:El price/MSEK": 51,
    "out:Heating price/MSEK": 29,
    "out:DH+ DHW/kWh/m2": 147,
    "out:El bought/kWh/m2": 698.32805,
    "out:Return %": 49,
    "out:Return/kSEK/y": 238,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 41.7,
    "out:EAHP (El)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 18,
    "out:Payback Time": 5.555556,
    "out:DH kWh savings": 71365.346428,
    "out:EL kWh savings": 8258.927355,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 79599.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 87883.2,
    "out:DH kr savings": 71365.3464284312,
    "out:El kr savings": 16517.854710116386,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 335776.831144,
    "out:% savings (space heating)": 17.52836,
    "out:Total_Water Heating (DH)": 132772.760051,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 105.46,
    "out:Etvv": 25,
    "out:Ef": 9.98,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 483604.69952357496,
    "out:Seasonal Variation ROI (%)": 17,
    "out:Seasonal Variation Payback": 5.882353,
    "out:New AHU Cost/SEK": 146792.87151982498,
    "out:DH CO2": 52.917648,
    "out:EL CO2": 1.316927,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 1.316927,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00001000000101000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 1,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 110.193654,
    "out:DHW (DH)": 35.445,
    "out:Property Electricity": 9.976115,
    "out:Electricity (inc PV)": 9.976115,
    "out:Total Energy Use Pre PV": 155.976115,
    "out:Total Energy Use Post PV": 155.976115,
    "out:Primary Energy": 118.538182,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 3.048927,
    "out:CO2 Operational/m2": 53.874591,
    "out:Total CO2/m2": 56.923518,
    "out:Total CO2 (tons)": 181.244427,
    "out:Klimatpaverkan": -18.890994,
    "out:Initial Cost/MSEK": 0.6772207245235751,
    "out:Running cost/(Apt SEK y)": 31221.638298,
    "out:Running Cost over RSP/MSEK": 11.47196,
    "out:LCP/MSEK": 1.255539,
    "out:ROI% old": 40.770839,
    "out:Payback discounted": 3,
    "out:Atemp": 3184,
    "out:Umean": 0.82,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 156,
    "out:Energy use kWh/m2": 156,
    "out:Energy savings %": 17.3,
    "out:Op energy cost/MSEK": 84,
    "out:El price/MSEK": 51,
    "out:Heating price/MSEK": 28,
    "out:DH+ DHW/kWh/m2": 146,
    "out:El bought/kWh/m2": 698.32805,
    "out:Return %": 37,
    "out:Return/kSEK/y": 247,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 35.445,
    "out:EAHP (El)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 14,
    "out:Payback Time": 7.142857,
    "out:DH kWh savings": 76201.601537,
    "out:EL kWh savings": 8258.927355,
    "out:Sewage heat recovery cost": 273216,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 92719.46,
    "out:DH kr savings": 76201.60153650118,
    "out:El kr savings": 16517.854710116386,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 350856.490044,
    "out:% savings (space heating)": 13.824578,
    "out:Total_Water Heating (DH)": 112856.846044,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 110.19,
    "out:Etvv": 21.25,
    "out:Ef": 9.98,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 677220.724523575,
    "out:Seasonal Variation ROI (%)": 13,
    "out:Seasonal Variation Payback": 7.692308,
    "out:New AHU Cost/SEK": 146792.87151982498,
    "out:DH CO2": 52.557664,
    "out:EL CO2": 1.316927,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 1.316927,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00001000000101100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 1,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 105.457579,
    "out:DHW (DH)": 35.445,
    "out:Property Electricity": 9.976115,
    "out:Electricity (inc PV)": 9.976115,
    "out:Total Energy Use Pre PV": 150.976115,
    "out:Total Energy Use Post PV": 150.976115,
    "out:Primary Energy": 114.854568,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 3.048927,
    "out:CO2 Operational/m2": 52.074671,
    "out:Total CO2/m2": 55.123598,
    "out:Total CO2 (tons)": 175.513483,
    "out:Klimatpaverkan": -24.621938,
    "out:Initial Cost/MSEK": 0.756820699523575,
    "out:Running cost/(Apt SEK y)": 30198.680851,
    "out:Running Cost over RSP/MSEK": 11.0959,
    "out:LCP/MSEK": 1.551999,
    "out:ROI% old": 43.581185,
    "out:Payback discounted": 3,
    "out:Atemp": 3184,
    "out:Umean": 0.82,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 151,
    "out:Energy use kWh/m2": 151,
    "out:Energy savings %": 21.2,
    "out:Op energy cost/MSEK": 81,
    "out:El price/MSEK": 49,
    "out:Heating price/MSEK": 27,
    "out:DH+ DHW/kWh/m2": 141,
    "out:El bought/kWh/m2": 698.32805,
    "out:Return %": 39,
    "out:Return/kSEK/y": 295,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 35.445,
    "out:EAHP (El)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 14,
    "out:Payback Time": 7.142857,
    "out:DH kWh savings": 91281.260436,
    "out:EL kWh savings": 8258.927355,
    "out:Sewage heat recovery cost": 273216,
    "out:Balancing hydronic system cost": 79599.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 107799.12,
    "out:DH kr savings": 91281.26043614118,
    "out:El kr savings": 16517.854710116386,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 335776.831144,
    "out:% savings (space heating)": 17.52836,
    "out:Total_Water Heating (DH)": 112856.846044,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 105.46,
    "out:Etvv": 21.25,
    "out:Ef": 9.98,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 756820.699523575,
    "out:Seasonal Variation ROI (%)": 13,
    "out:Seasonal Variation Payback": 7.692308,
    "out:New AHU Cost/SEK": 146792.87151982498,
    "out:DH CO2": 50.757744,
    "out:EL CO2": 1.316927,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 1.316927,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00001000010000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 110.193654,
    "out:DHW (DH)": 41.7,
    "out:Property Electricity": 12.57,
    "out:Electricity (inc PV)": 8.051517,
    "out:Total Energy Use Pre PV": 164.57,
    "out:Total Energy Use Post PV": 160.051517,
    "out:Primary Energy": 117.698906,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 16.894982,
    "out:CO2 Operational/m2": 11.116247,
    "out:Total CO2/m2": 28.011229,
    "out:Total CO2 (tons)": 89.187726,
    "out:Klimatpaverkan": -110.947695,
    "out:Initial Cost/MSEK": 1.04743224722625,
    "out:Running cost/(Apt SEK y)": 31292.404255,
    "out:Running Cost over RSP/MSEK": 11.51155,
    "out:LCP/MSEK": 0.845738,
    "out:ROI% old": 25.820559,
    "out:Payback discounted": 5,
    "out:Atemp": 3184,
    "out:Umean": 0.82,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 160,
    "out:Energy use kWh/m2": 160,
    "out:Energy savings %": 14.4,
    "out:Op energy cost/MSEK": 85,
    "out:El price/MSEK": 50,
    "out:Heating price/MSEK": 30,
    "out:DH+ DHW/kWh/m2": 152,
    "out:El bought/kWh/m2": 568.258452,
    "out:Return %": 23,
    "out:Return/kSEK/y": 244,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 41.7,
    "out:EAHP (El)": 0,
    "out:PV (kr/kWp)": 17461.56255567083,
    "out:PV (kWp installed)": 45.25485,
    "out:PV (m2 panels)": 237.433632,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 42461.284501,
    "out:PV (kWh sold)": 28074.439514,
    "out:PV (% sold (El))": 66.117735,
    "out:PV (kWh self-consumed)": 14386.844987,
    "out:PV (ratio sold/self-consumed)": 1.951397,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 12,
    "out:Payback Time": 8.333333,
    "out:DH kWh savings": 56285.687529,
    "out:EL kWh savings": 14386.845543,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 127171.04,
    "out:DH kr savings": 56285.68752879119,
    "out:El kr savings": 28773.691086586638,
    "out:El kr sold": 42111.65927092506,
    "out:El kr saved": 28773.689974,
    "out:El kr return": 70885.34924492506,
    "out:% solar/total": 36,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 350856.490044,
    "out:% savings (space heating)": 13.824578,
    "out:Total_Water Heating (DH)": 132772.760051,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 110.19,
    "out:Etvv": 25,
    "out:Ef": 8.05,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 1047432.24722625,
    "out:Seasonal Variation ROI (%)": 12,
    "out:Seasonal Variation Payback": 8.333333,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 54.717568,
    "out:EL CO2": -43.601321,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -44.666212,
    "out:Bought CO2": 1.064891,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00001000010000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 105.457579,
    "out:DHW (DH)": 41.7,
    "out:Property Electricity": 12.57,
    "out:Electricity (inc PV)": 8.051517,
    "out:Total Energy Use Pre PV": 159.57,
    "out:Total Energy Use Post PV": 155.051517,
    "out:Primary Energy": 114.015292,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 16.894982,
    "out:CO2 Operational/m2": 9.316327,
    "out:Total CO2/m2": 26.211309,
    "out:Total CO2 (tons)": 83.456783,
    "out:Klimatpaverkan": -116.678638,
    "out:Initial Cost/MSEK": 1.1270322222262499,
    "out:Running cost/(Apt SEK y)": 30269.468085,
    "out:Running Cost over RSP/MSEK": 11.1355,
    "out:LCP/MSEK": 1.142188,
    "out:ROI% old": 28.763533,
    "out:Payback discounted": 4,
    "out:Atemp": 3184,
    "out:Umean": 0.82,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 155,
    "out:Energy use kWh/m2": 155,
    "out:Energy savings %": 18.1,
    "out:Op energy cost/MSEK": 82,
    "out:El price/MSEK": 49,
    "out:Heating price/MSEK": 29,
    "out:DH+ DHW/kWh/m2": 147,
    "out:El bought/kWh/m2": 568.258452,
    "out:Return %": 26,
    "out:Return/kSEK/y": 292,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 41.7,
    "out:EAHP (El)": 0,
    "out:PV (kr/kWp)": 17461.56255567083,
    "out:PV (kWp installed)": 45.25485,
    "out:PV (m2 panels)": 237.433632,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 42461.284501,
    "out:PV (kWh sold)": 28074.439514,
    "out:PV (% sold (El))": 66.117735,
    "out:PV (kWh self-consumed)": 14386.844987,
    "out:PV (ratio sold/self-consumed)": 1.951397,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 13,
    "out:Payback Time": 7.692308,
    "out:DH kWh savings": 71365.346428,
    "out:EL kWh savings": 14386.845543,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 79599.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 142250.7,
    "out:DH kr savings": 71365.3464284312,
    "out:El kr savings": 28773.691086586638,
    "out:El kr sold": 42111.65927092506,
    "out:El kr saved": 28773.689974,
    "out:El kr return": 70885.34924492506,
    "out:% solar/total": 36,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 335776.831144,
    "out:% savings (space heating)": 17.52836,
    "out:Total_Water Heating (DH)": 132772.760051,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 105.46,
    "out:Etvv": 25,
    "out:Ef": 8.05,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 1127032.22222625,
    "out:Seasonal Variation ROI (%)": 12,
    "out:Seasonal Variation Payback": 8.333333,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 52.917648,
    "out:EL CO2": -43.601321,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -44.666212,
    "out:Bought CO2": 1.064891,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00001000010001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 110.193654,
    "out:DHW (DH)": 35.445,
    "out:Property Electricity": 12.57,
    "out:Electricity (inc PV)": 8.051517,
    "out:Total Energy Use Pre PV": 158.57,
    "out:Total Energy Use Post PV": 154.051517,
    "out:Primary Energy": 115.073906,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 17.603525,
    "out:CO2 Operational/m2": 8.956343,
    "out:Total CO2/m2": 26.559868,
    "out:Total CO2 (tons)": 84.566594,
    "out:Klimatpaverkan": -115.568827,
    "out:Initial Cost/MSEK": 1.32064824722625,
    "out:Running cost/(Apt SEK y)": 30064.87234,
    "out:Running Cost over RSP/MSEK": 11.06028,
    "out:LCP/MSEK": 1.023792,
    "out:ROI% old": 25.360273,
    "out:Payback discounted": 5,
    "out:Atemp": 3184,
    "out:Umean": 0.82,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 154,
    "out:Energy use kWh/m2": 154,
    "out:Energy savings %": 18.8,
    "out:Op energy cost/MSEK": 82,
    "out:El price/MSEK": 48,
    "out:Heating price/MSEK": 28,
    "out:DH+ DHW/kWh/m2": 146,
    "out:El bought/kWh/m2": 568.258452,
    "out:Return %": 23,
    "out:Return/kSEK/y": 302,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 35.445,
    "out:EAHP (El)": 0,
    "out:PV (kr/kWp)": 17461.56255567083,
    "out:PV (kWp installed)": 45.25485,
    "out:PV (m2 panels)": 237.433632,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 42461.284501,
    "out:PV (kWh sold)": 28074.439514,
    "out:PV (% sold (El))": 66.117735,
    "out:PV (kWh self-consumed)": 14386.844987,
    "out:PV (ratio sold/self-consumed)": 1.951397,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 11,
    "out:Payback Time": 9.090909,
    "out:DH kWh savings": 76201.601537,
    "out:EL kWh savings": 14386.845543,
    "out:Sewage heat recovery cost": 273216,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 147086.95,
    "out:DH kr savings": 76201.60153650118,
    "out:El kr savings": 28773.691086586638,
    "out:El kr sold": 42111.65927092506,
    "out:El kr saved": 28773.689974,
    "out:El kr return": 70885.34924492506,
    "out:% solar/total": 36,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 350856.490044,
    "out:% savings (space heating)": 13.824578,
    "out:Total_Water Heating (DH)": 112856.846044,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 110.19,
    "out:Etvv": 21.25,
    "out:Ef": 8.05,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 1320648.24722625,
    "out:Seasonal Variation ROI (%)": 11,
    "out:Seasonal Variation Payback": 9.090909,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 52.557664,
    "out:EL CO2": -43.601321,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -44.666212,
    "out:Bought CO2": 1.064891,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00001000010001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 105.457579,
    "out:DHW (DH)": 35.445,
    "out:Property Electricity": 12.57,
    "out:Electricity (inc PV)": 8.051517,
    "out:Total Energy Use Pre PV": 153.57,
    "out:Total Energy Use Post PV": 149.051517,
    "out:Primary Energy": 111.390292,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 17.603525,
    "out:CO2 Operational/m2": 7.156423,
    "out:Total CO2/m2": 24.759948,
    "out:Total CO2 (tons)": 78.835651,
    "out:Klimatpaverkan": -121.29977,
    "out:Initial Cost/MSEK": 1.40024822222625,
    "out:Running cost/(Apt SEK y)": 29041.93617,
    "out:Running Cost over RSP/MSEK": 10.68423,
    "out:LCP/MSEK": 1.320242,
    "out:ROI% old": 27.755181,
    "out:Payback discounted": 5,
    "out:Atemp": 3184,
    "out:Umean": 0.82,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 149,
    "out:Energy use kWh/m2": 149,
    "out:Energy savings %": 22.8,
    "out:Op energy cost/MSEK": 79,
    "out:El price/MSEK": 47,
    "out:Heating price/MSEK": 27,
    "out:DH+ DHW/kWh/m2": 141,
    "out:El bought/kWh/m2": 568.258452,
    "out:Return %": 25,
    "out:Return/kSEK/y": 350,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 35.445,
    "out:EAHP (El)": 0,
    "out:PV (kr/kWp)": 17461.56255567083,
    "out:PV (kWp installed)": 45.25485,
    "out:PV (m2 panels)": 237.433632,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 42461.284501,
    "out:PV (kWh sold)": 28074.439514,
    "out:PV (% sold (El))": 66.117735,
    "out:PV (kWh self-consumed)": 14386.844987,
    "out:PV (ratio sold/self-consumed)": 1.951397,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 12,
    "out:Payback Time": 8.333333,
    "out:DH kWh savings": 91281.260436,
    "out:EL kWh savings": 14386.845543,
    "out:Sewage heat recovery cost": 273216,
    "out:Balancing hydronic system cost": 79599.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 162166.61,
    "out:DH kr savings": 91281.26043614118,
    "out:El kr savings": 28773.691086586638,
    "out:El kr sold": 42111.65927092506,
    "out:El kr saved": 28773.689974,
    "out:El kr return": 70885.34924492506,
    "out:% solar/total": 36,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 335776.831144,
    "out:% savings (space heating)": 17.52836,
    "out:Total_Water Heating (DH)": 112856.846044,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 105.46,
    "out:Etvv": 21.25,
    "out:Ef": 8.05,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 1400248.22222625,
    "out:Seasonal Variation ROI (%)": 11,
    "out:Seasonal Variation Payback": 9.090909,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 50.757744,
    "out:EL CO2": -43.601321,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -44.666212,
    "out:Bought CO2": 1.064891,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00001000010100000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 1,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 110.193654,
    "out:DHW (DH)": 41.7,
    "out:Property Electricity": 9.976115,
    "out:Electricity (inc PV)": 6.231337,
    "out:Total Energy Use Pre PV": 161.976115,
    "out:Total Energy Use Post PV": 158.231337,
    "out:Primary Energy": 114.422582,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 17.079484,
    "out:CO2 Operational/m2": 6.886119,
    "out:Total CO2/m2": 23.965603,
    "out:Total CO2 (tons)": 76.306457,
    "out:Klimatpaverkan": -123.828964,
    "out:Initial Cost/MSEK": 1.194225118746075,
    "out:Running cost/(Apt SEK y)": 30967.170213,
    "out:Running Cost over RSP/MSEK": 11.39332,
    "out:LCP/MSEK": 0.817175,
    "out:ROI% old": 24.061029,
    "out:Payback discounted": 5,
    "out:Atemp": 3184,
    "out:Umean": 0.82,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 158,
    "out:Energy use kWh/m2": 158,
    "out:Energy savings %": 15.8,
    "out:Op energy cost/MSEK": 84,
    "out:El price/MSEK": 49,
    "out:Heating price/MSEK": 30,
    "out:DH+ DHW/kWh/m2": 152,
    "out:El bought/kWh/m2": 439.497378,
    "out:Return %": 22,
    "out:Return/kSEK/y": 259,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 41.7,
    "out:EAHP (El)": 0,
    "out:PV (kr/kWp)": 17461.56255567083,
    "out:PV (kWp installed)": 45.25485,
    "out:PV (m2 panels)": 237.433632,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 42461.284501,
    "out:PV (kWh sold)": 30537.914073,
    "out:PV (% sold (El))": 71.919431,
    "out:PV (kWh self-consumed)": 11923.370428,
    "out:PV (ratio sold/self-consumed)": 2.561181,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 12,
    "out:Payback Time": 8.333333,
    "out:DH kWh savings": 56285.687529,
    "out:EL kWh savings": 20182.29692,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 142457.15,
    "out:DH kr savings": 56285.68752879119,
    "out:El kr savings": 40364.59383912171,
    "out:El kr sold": 45806.87111012268,
    "out:El kr saved": 23846.740856,
    "out:El kr return": 69653.61196612268,
    "out:% solar/total": 30,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 350856.490044,
    "out:% savings (space heating)": 13.824578,
    "out:Total_Water Heating (DH)": 132772.760051,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 110.19,
    "out:Etvv": 25,
    "out:Ef": 6.23,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 1194225.118746075,
    "out:Seasonal Variation ROI (%)": 12,
    "out:Seasonal Variation Payback": 8.333333,
    "out:New AHU Cost/SEK": 146792.87151982498,
    "out:DH CO2": 54.717568,
    "out:EL CO2": -47.831449,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -48.655512,
    "out:Bought CO2": 0.824063,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00001000010100100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 1,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 105.457579,
    "out:DHW (DH)": 41.7,
    "out:Property Electricity": 9.976115,
    "out:Electricity (inc PV)": 6.231337,
    "out:Total Energy Use Pre PV": 156.976115,
    "out:Total Energy Use Post PV": 153.231337,
    "out:Primary Energy": 110.738968,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 17.079484,
    "out:CO2 Operational/m2": 5.086199,
    "out:Total CO2/m2": 22.165683,
    "out:Total CO2 (tons)": 70.575513,
    "out:Klimatpaverkan": -129.559908,
    "out:Initial Cost/MSEK": 1.2738250937460749,
    "out:Running cost/(Apt SEK y)": 29944.234043,
    "out:Running Cost over RSP/MSEK": 11.01727,
    "out:LCP/MSEK": 1.113625,
    "out:ROI% old": 26.774813,
    "out:Payback discounted": 5,
    "out:Atemp": 3184,
    "out:Umean": 0.82,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 153,
    "out:Energy use kWh/m2": 153,
    "out:Energy savings %": 19.6,
    "out:Op energy cost/MSEK": 81,
    "out:El price/MSEK": 48,
    "out:Heating price/MSEK": 29,
    "out:DH+ DHW/kWh/m2": 147,
    "out:El bought/kWh/m2": 439.497378,
    "out:Return %": 24,
    "out:Return/kSEK/y": 307,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 41.7,
    "out:EAHP (El)": 0,
    "out:PV (kr/kWp)": 17461.56255567083,
    "out:PV (kWp installed)": 45.25485,
    "out:PV (m2 panels)": 237.433632,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 42461.284501,
    "out:PV (kWh sold)": 30537.914073,
    "out:PV (% sold (El))": 71.919431,
    "out:PV (kWh self-consumed)": 11923.370428,
    "out:PV (ratio sold/self-consumed)": 2.561181,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 12,
    "out:Payback Time": 8.333333,
    "out:DH kWh savings": 71365.346428,
    "out:EL kWh savings": 20182.29692,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 79599.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 157536.81,
    "out:DH kr savings": 71365.3464284312,
    "out:El kr savings": 40364.59383912171,
    "out:El kr sold": 45806.87111012268,
    "out:El kr saved": 23846.740856,
    "out:El kr return": 69653.61196612268,
    "out:% solar/total": 30,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 335776.831144,
    "out:% savings (space heating)": 17.52836,
    "out:Total_Water Heating (DH)": 132772.760051,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 105.46,
    "out:Etvv": 25,
    "out:Ef": 6.23,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 1273825.093746075,
    "out:Seasonal Variation ROI (%)": 12,
    "out:Seasonal Variation Payback": 8.333333,
    "out:New AHU Cost/SEK": 146792.87151982498,
    "out:DH CO2": 52.917648,
    "out:EL CO2": -47.831449,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -48.655512,
    "out:Bought CO2": 0.824063,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00001000010101000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 1,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 110.193654,
    "out:DHW (DH)": 35.445,
    "out:Property Electricity": 9.976115,
    "out:Electricity (inc PV)": 6.231337,
    "out:Total Energy Use Pre PV": 155.976115,
    "out:Total Energy Use Post PV": 152.231337,
    "out:Primary Energy": 111.797582,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 17.788027,
    "out:CO2 Operational/m2": 4.726215,
    "out:Total CO2/m2": 22.514242,
    "out:Total CO2 (tons)": 71.685325,
    "out:Klimatpaverkan": -128.450096,
    "out:Initial Cost/MSEK": 1.4674411187460752,
    "out:Running cost/(Apt SEK y)": 29739.638298,
    "out:Running Cost over RSP/MSEK": 10.94206,
    "out:LCP/MSEK": 0.995219,
    "out:ROI% old": 23.974289,
    "out:Payback discounted": 5,
    "out:Atemp": 3184,
    "out:Umean": 0.82,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 152,
    "out:Energy use kWh/m2": 152,
    "out:Energy savings %": 20.4,
    "out:Op energy cost/MSEK": 81,
    "out:El price/MSEK": 48,
    "out:Heating price/MSEK": 28,
    "out:DH+ DHW/kWh/m2": 146,
    "out:El bought/kWh/m2": 439.497378,
    "out:Return %": 22,
    "out:Return/kSEK/y": 317,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 35.445,
    "out:EAHP (El)": 0,
    "out:PV (kr/kWp)": 17461.56255567083,
    "out:PV (kWp installed)": 45.25485,
    "out:PV (m2 panels)": 237.433632,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 42461.284501,
    "out:PV (kWh sold)": 30537.914073,
    "out:PV (% sold (El))": 71.919431,
    "out:PV (kWh self-consumed)": 11923.370428,
    "out:PV (ratio sold/self-consumed)": 2.561181,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 11,
    "out:Payback Time": 9.090909,
    "out:DH kWh savings": 76201.601537,
    "out:EL kWh savings": 20182.29692,
    "out:Sewage heat recovery cost": 273216,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 162373.07,
    "out:DH kr savings": 76201.60153650118,
    "out:El kr savings": 40364.59383912171,
    "out:El kr sold": 45806.87111012268,
    "out:El kr saved": 23846.740856,
    "out:El kr return": 69653.61196612268,
    "out:% solar/total": 30,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 350856.490044,
    "out:% savings (space heating)": 13.824578,
    "out:Total_Water Heating (DH)": 112856.846044,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 110.19,
    "out:Etvv": 21.25,
    "out:Ef": 6.23,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 1467441.118746075,
    "out:Seasonal Variation ROI (%)": 11,
    "out:Seasonal Variation Payback": 9.090909,
    "out:New AHU Cost/SEK": 146792.87151982498,
    "out:DH CO2": 52.557664,
    "out:EL CO2": -47.831449,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -48.655512,
    "out:Bought CO2": 0.824063,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00001000010101100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 1,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 105.457579,
    "out:DHW (DH)": 35.445,
    "out:Property Electricity": 9.976115,
    "out:Electricity (inc PV)": 6.231337,
    "out:Total Energy Use Pre PV": 150.976115,
    "out:Total Energy Use Post PV": 147.231337,
    "out:Primary Energy": 108.113968,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 17.788027,
    "out:CO2 Operational/m2": 2.926295,
    "out:Total CO2/m2": 20.714322,
    "out:Total CO2 (tons)": 65.954381,
    "out:Klimatpaverkan": -134.18104,
    "out:Initial Cost/MSEK": 1.547041093746075,
    "out:Running cost/(Apt SEK y)": 28716.702128,
    "out:Running Cost over RSP/MSEK": 10.566,
    "out:LCP/MSEK": 1.291679,
    "out:ROI% old": 26.213359,
    "out:Payback discounted": 5,
    "out:Atemp": 3184,
    "out:Umean": 0.82,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 147,
    "out:Energy use kWh/m2": 147,
    "out:Energy savings %": 24.5,
    "out:Op energy cost/MSEK": 78,
    "out:El price/MSEK": 46,
    "out:Heating price/MSEK": 27,
    "out:DH+ DHW/kWh/m2": 141,
    "out:El bought/kWh/m2": 439.497378,
    "out:Return %": 24,
    "out:Return/kSEK/y": 365,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 35.445,
    "out:EAHP (El)": 0,
    "out:PV (kr/kWp)": 17461.56255567083,
    "out:PV (kWp installed)": 45.25485,
    "out:PV (m2 panels)": 237.433632,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 42461.284501,
    "out:PV (kWh sold)": 30537.914073,
    "out:PV (% sold (El))": 71.919431,
    "out:PV (kWh self-consumed)": 11923.370428,
    "out:PV (ratio sold/self-consumed)": 2.561181,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 11,
    "out:Payback Time": 9.090909,
    "out:DH kWh savings": 91281.260436,
    "out:EL kWh savings": 20182.29692,
    "out:Sewage heat recovery cost": 273216,
    "out:Balancing hydronic system cost": 79599.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 177452.73,
    "out:DH kr savings": 91281.26043614118,
    "out:El kr savings": 40364.59383912171,
    "out:El kr sold": 45806.87111012268,
    "out:El kr saved": 23846.740856,
    "out:El kr return": 69653.61196612268,
    "out:% solar/total": 30,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 335776.831144,
    "out:% savings (space heating)": 17.52836,
    "out:Total_Water Heating (DH)": 112856.846044,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 105.46,
    "out:Etvv": 21.25,
    "out:Ef": 6.23,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 1547041.093746075,
    "out:Seasonal Variation ROI (%)": 11,
    "out:Seasonal Variation Payback": 9.090909,
    "out:New AHU Cost/SEK": 146792.87151982498,
    "out:DH CO2": 50.757744,
    "out:EL CO2": -47.831449,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -48.655512,
    "out:Bought CO2": 0.824063,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00001000020000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 110.193654,
    "out:DHW (DH)": 41.7,
    "out:Property Electricity": 12.57,
    "out:Electricity (inc PV)": 7.530921,
    "out:Total Energy Use Pre PV": 164.57,
    "out:Total Energy Use Post PV": 159.530921,
    "out:Primary Energy": 116.761833,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 31.634081,
    "out:CO2 Operational/m2": -54.23907,
    "out:Total CO2/m2": -22.604989,
    "out:Total CO2 (tons)": -71.974263,
    "out:Klimatpaverkan": -272.109684,
    "out:Initial Cost/MSEK": 1.8376455989475,
    "out:Running cost/(Apt SEK y)": 29919.638298,
    "out:Running Cost over RSP/MSEK": 11.02112,
    "out:LCP/MSEK": 0.545954,
    "out:ROI% old": 18.529921,
    "out:Payback discounted": 7,
    "out:Atemp": 3184,
    "out:Umean": 0.82,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 160,
    "out:Energy use kWh/m2": 160,
    "out:Energy savings %": 14.4,
    "out:Op energy cost/MSEK": 82,
    "out:El price/MSEK": 47,
    "out:Heating price/MSEK": 30,
    "out:DH+ DHW/kWh/m2": 152,
    "out:El bought/kWh/m2": 530.25352,
    "out:Return %": 17,
    "out:Return/kSEK/y": 308,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 41.7,
    "out:EAHP (El)": 0,
    "out:PV (kr/kWp)": 17461.48474631725,
    "out:PV (kWp installed)": 90.5097,
    "out:PV (m2 panels)": 474.867263,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 84922.568824,
    "out:PV (kWh sold)": 68878.145463,
    "out:PV (% sold (El))": 81.106997,
    "out:PV (kWh self-consumed)": 16044.423361,
    "out:PV (ratio sold/self-consumed)": 4.292965,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 10,
    "out:Payback Time": 10,
    "out:DH kWh savings": 56285.687529,
    "out:EL kWh savings": 16044.422709,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 191691.75,
    "out:DH kr savings": 56285.68752879119,
    "out:El kr savings": 32088.845417124598,
    "out:El kr sold": 103317.21819422109,
    "out:El kr saved": 32088.846722,
    "out:El kr return": 135406.06491622108,
    "out:% solar/total": 40,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 350856.490044,
    "out:% savings (space heating)": 13.824578,
    "out:Total_Water Heating (DH)": 132772.760051,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 110.19,
    "out:Etvv": 25,
    "out:Ef": 7.53,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 1837645.5989475,
    "out:Seasonal Variation ROI (%)": 10,
    "out:Seasonal Variation Payback": 10,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 54.717568,
    "out:EL CO2": -108.956638,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -109.952146,
    "out:Bought CO2": 0.995508,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00001000020000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 105.457579,
    "out:DHW (DH)": 41.7,
    "out:Property Electricity": 12.57,
    "out:Electricity (inc PV)": 7.530921,
    "out:Total Energy Use Pre PV": 159.57,
    "out:Total Energy Use Post PV": 154.530921,
    "out:Primary Energy": 113.078219,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 31.634081,
    "out:CO2 Operational/m2": -56.03899,
    "out:Total CO2/m2": -24.404909,
    "out:Total CO2 (tons)": -77.705207,
    "out:Klimatpaverkan": -277.840628,
    "out:Initial Cost/MSEK": 1.9172455739475,
    "out:Running cost/(Apt SEK y)": 28896.680851,
    "out:Running Cost over RSP/MSEK": 10.64506,
    "out:LCP/MSEK": 0.842414,
    "out:ROI% old": 20.562684,
    "out:Payback discounted": 6,
    "out:Atemp": 3184,
    "out:Umean": 0.82,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 155,
    "out:Energy use kWh/m2": 155,
    "out:Energy savings %": 18.1,
    "out:Op energy cost/MSEK": 79,
    "out:El price/MSEK": 46,
    "out:Heating price/MSEK": 29,
    "out:DH+ DHW/kWh/m2": 147,
    "out:El bought/kWh/m2": 530.25352,
    "out:Return %": 19,
    "out:Return/kSEK/y": 357,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 41.7,
    "out:EAHP (El)": 0,
    "out:PV (kr/kWp)": 17461.48474631725,
    "out:PV (kWp installed)": 90.5097,
    "out:PV (m2 panels)": 474.867263,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 84922.568824,
    "out:PV (kWh sold)": 68878.145463,
    "out:PV (% sold (El))": 81.106997,
    "out:PV (kWh self-consumed)": 16044.423361,
    "out:PV (ratio sold/self-consumed)": 4.292965,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 11,
    "out:Payback Time": 9.090909,
    "out:DH kWh savings": 71365.346428,
    "out:EL kWh savings": 16044.422709,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 79599.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 206771.41,
    "out:DH kr savings": 71365.3464284312,
    "out:El kr savings": 32088.845417124598,
    "out:El kr sold": 103317.21819422109,
    "out:El kr saved": 32088.846722,
    "out:El kr return": 135406.06491622108,
    "out:% solar/total": 40,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 335776.831144,
    "out:% savings (space heating)": 17.52836,
    "out:Total_Water Heating (DH)": 132772.760051,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 105.46,
    "out:Etvv": 25,
    "out:Ef": 7.53,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 1917245.5739475,
    "out:Seasonal Variation ROI (%)": 10,
    "out:Seasonal Variation Payback": 10,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 52.917648,
    "out:EL CO2": -108.956638,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -109.952146,
    "out:Bought CO2": 0.995508,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00001000020001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 110.193654,
    "out:DHW (DH)": 35.445,
    "out:Property Electricity": 12.57,
    "out:Electricity (inc PV)": 7.530921,
    "out:Total Energy Use Pre PV": 158.57,
    "out:Total Energy Use Post PV": 153.530921,
    "out:Primary Energy": 114.136833,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 32.342624,
    "out:CO2 Operational/m2": -56.398974,
    "out:Total CO2/m2": -24.05635,
    "out:Total CO2 (tons)": -76.595395,
    "out:Klimatpaverkan": -276.730816,
    "out:Initial Cost/MSEK": 2.1108615989475004,
    "out:Running cost/(Apt SEK y)": 28692.106383,
    "out:Running Cost over RSP/MSEK": 10.56985,
    "out:LCP/MSEK": 0.724008,
    "out:ROI% old": 19.185598,
    "out:Payback discounted": 6,
    "out:Atemp": 3184,
    "out:Umean": 0.82,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 154,
    "out:Energy use kWh/m2": 154,
    "out:Energy savings %": 18.8,
    "out:Op energy cost/MSEK": 79,
    "out:El price/MSEK": 45,
    "out:Heating price/MSEK": 28,
    "out:DH+ DHW/kWh/m2": 146,
    "out:El bought/kWh/m2": 530.25352,
    "out:Return %": 17,
    "out:Return/kSEK/y": 366,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 35.445,
    "out:EAHP (El)": 0,
    "out:PV (kr/kWp)": 17461.48474631725,
    "out:PV (kWp installed)": 90.5097,
    "out:PV (m2 panels)": 474.867263,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 84922.568824,
    "out:PV (kWh sold)": 68878.145463,
    "out:PV (% sold (El))": 81.106997,
    "out:PV (kWh self-consumed)": 16044.423361,
    "out:PV (ratio sold/self-consumed)": 4.292965,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 10,
    "out:Payback Time": 10,
    "out:DH kWh savings": 76201.601537,
    "out:EL kWh savings": 16044.422709,
    "out:Sewage heat recovery cost": 273216,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 211607.67,
    "out:DH kr savings": 76201.60153650118,
    "out:El kr savings": 32088.845417124598,
    "out:El kr sold": 103317.21819422109,
    "out:El kr saved": 32088.846722,
    "out:El kr return": 135406.06491622108,
    "out:% solar/total": 40,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 350856.490044,
    "out:% savings (space heating)": 13.824578,
    "out:Total_Water Heating (DH)": 112856.846044,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 110.19,
    "out:Etvv": 21.25,
    "out:Ef": 7.53,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 2110861.5989475003,
    "out:Seasonal Variation ROI (%)": 10,
    "out:Seasonal Variation Payback": 10,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 52.557664,
    "out:EL CO2": -108.956638,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -109.952146,
    "out:Bought CO2": 0.995508,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00001000020001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 105.457579,
    "out:DHW (DH)": 35.445,
    "out:Property Electricity": 12.57,
    "out:Electricity (inc PV)": 7.530921,
    "out:Total Energy Use Pre PV": 153.57,
    "out:Total Energy Use Post PV": 148.530921,
    "out:Primary Energy": 110.453219,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 32.342624,
    "out:CO2 Operational/m2": -58.198894,
    "out:Total CO2/m2": -25.85627,
    "out:Total CO2 (tons)": -82.326339,
    "out:Klimatpaverkan": -282.46176,
    "out:Initial Cost/MSEK": 2.1904615739475,
    "out:Running cost/(Apt SEK y)": 27669.148936,
    "out:Running Cost over RSP/MSEK": 10.19379,
    "out:LCP/MSEK": 1.020468,
    "out:ROI% old": 20.940988,
    "out:Payback discounted": 6,
    "out:Atemp": 3184,
    "out:Umean": 0.82,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 149,
    "out:Energy use kWh/m2": 149,
    "out:Energy savings %": 22.8,
    "out:Op energy cost/MSEK": 76,
    "out:El price/MSEK": 44,
    "out:Heating price/MSEK": 27,
    "out:DH+ DHW/kWh/m2": 141,
    "out:El bought/kWh/m2": 530.25352,
    "out:Return %": 19,
    "out:Return/kSEK/y": 414,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 35.445,
    "out:EAHP (El)": 0,
    "out:PV (kr/kWp)": 17461.48474631725,
    "out:PV (kWp installed)": 90.5097,
    "out:PV (m2 panels)": 474.867263,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 84922.568824,
    "out:PV (kWh sold)": 68878.145463,
    "out:PV (% sold (El))": 81.106997,
    "out:PV (kWh self-consumed)": 16044.423361,
    "out:PV (ratio sold/self-consumed)": 4.292965,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 10,
    "out:Payback Time": 10,
    "out:DH kWh savings": 91281.260436,
    "out:EL kWh savings": 16044.422709,
    "out:Sewage heat recovery cost": 273216,
    "out:Balancing hydronic system cost": 79599.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 226687.32,
    "out:DH kr savings": 91281.26043614118,
    "out:El kr savings": 32088.845417124598,
    "out:El kr sold": 103317.21819422109,
    "out:El kr saved": 32088.846722,
    "out:El kr return": 135406.06491622108,
    "out:% solar/total": 40,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 335776.831144,
    "out:% savings (space heating)": 17.52836,
    "out:Total_Water Heating (DH)": 112856.846044,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 105.46,
    "out:Etvv": 21.25,
    "out:Ef": 7.53,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 2190461.5739475,
    "out:Seasonal Variation ROI (%)": 10,
    "out:Seasonal Variation Payback": 10,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 50.757744,
    "out:EL CO2": -108.956638,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -109.952146,
    "out:Bought CO2": 0.995508,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00001000020100000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 1,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 110.193654,
    "out:DHW (DH)": 41.7,
    "out:Property Electricity": 9.976115,
    "out:Electricity (inc PV)": 5.879188,
    "out:Total Energy Use Pre PV": 161.976115,
    "out:Total Energy Use Post PV": 157.879188,
    "out:Primary Energy": 113.788713,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 31.818583,
    "out:CO2 Operational/m2": -59.324796,
    "out:Total CO2/m2": -27.506213,
    "out:Total CO2 (tons)": -87.579756,
    "out:Klimatpaverkan": -287.715177,
    "out:Initial Cost/MSEK": 1.9844384704673248,
    "out:Running cost/(Apt SEK y)": 29600.106383,
    "out:Running Cost over RSP/MSEK": 10.90498,
    "out:LCP/MSEK": 0.515302,
    "out:ROI% old": 17.995303,
    "out:Payback discounted": 7,
    "out:Atemp": 3184,
    "out:Umean": 0.82,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 158,
    "out:Energy use kWh/m2": 158,
    "out:Energy savings %": 15.8,
    "out:Op energy cost/MSEK": 81,
    "out:El price/MSEK": 47,
    "out:Heating price/MSEK": 30,
    "out:DH+ DHW/kWh/m2": 152,
    "out:El bought/kWh/m2": 413.65837,
    "out:Return %": 16,
    "out:Return/kSEK/y": 324,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 41.7,
    "out:EAHP (El)": 0,
    "out:PV (kr/kWp)": 17461.48474631725,
    "out:PV (kWp installed)": 90.5097,
    "out:PV (m2 panels)": 474.867263,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 84922.568824,
    "out:PV (kWh sold)": 71877.957046,
    "out:PV (% sold (El))": 84.639405,
    "out:PV (kWh self-consumed)": 13044.611778,
    "out:PV (ratio sold/self-consumed)": 5.510165,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 10,
    "out:Payback Time": 10,
    "out:DH kWh savings": 56285.687529,
    "out:EL kWh savings": 21303.538998,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 206709.7,
    "out:DH kr savings": 56285.68752879119,
    "out:El kr savings": 42607.077996404216,
    "out:El kr sold": 107816.93556907076,
    "out:El kr saved": 26089.223556,
    "out:El kr return": 133906.15912507076,
    "out:% solar/total": 33,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 350856.490044,
    "out:% savings (space heating)": 13.824578,
    "out:Total_Water Heating (DH)": 132772.760051,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 110.19,
    "out:Etvv": 25,
    "out:Ef": 5.88,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 1984438.4704673248,
    "out:Seasonal Variation ROI (%)": 10,
    "out:Seasonal Variation Payback": 10,
    "out:New AHU Cost/SEK": 146792.87151982498,
    "out:DH CO2": 54.717568,
    "out:EL CO2": -114.042364,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -114.819396,
    "out:Bought CO2": 0.777032,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00001000020100100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 1,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 105.457579,
    "out:DHW (DH)": 41.7,
    "out:Property Electricity": 9.976115,
    "out:Electricity (inc PV)": 5.879188,
    "out:Total Energy Use Pre PV": 156.976115,
    "out:Total Energy Use Post PV": 152.879188,
    "out:Primary Energy": 110.105099,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 31.818583,
    "out:CO2 Operational/m2": -61.124716,
    "out:Total CO2/m2": -29.306133,
    "out:Total CO2 (tons)": -93.310699,
    "out:Klimatpaverkan": -293.44612,
    "out:Initial Cost/MSEK": 2.064038445467325,
    "out:Running cost/(Apt SEK y)": 28577.148936,
    "out:Running Cost over RSP/MSEK": 10.52892,
    "out:LCP/MSEK": 0.811762,
    "out:ROI% old": 19.904114,
    "out:Payback discounted": 6,
    "out:Atemp": 3184,
    "out:Umean": 0.82,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 153,
    "out:Energy use kWh/m2": 153,
    "out:Energy savings %": 19.6,
    "out:Op energy cost/MSEK": 78,
    "out:El price/MSEK": 45,
    "out:Heating price/MSEK": 29,
    "out:DH+ DHW/kWh/m2": 147,
    "out:El bought/kWh/m2": 413.65837,
    "out:Return %": 18,
    "out:Return/kSEK/y": 372,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 41.7,
    "out:EAHP (El)": 0,
    "out:PV (kr/kWp)": 17461.48474631725,
    "out:PV (kWp installed)": 90.5097,
    "out:PV (m2 panels)": 474.867263,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 84922.568824,
    "out:PV (kWh sold)": 71877.957046,
    "out:PV (% sold (El))": 84.639405,
    "out:PV (kWh self-consumed)": 13044.611778,
    "out:PV (ratio sold/self-consumed)": 5.510165,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 11,
    "out:Payback Time": 9.090909,
    "out:DH kWh savings": 71365.346428,
    "out:EL kWh savings": 21303.538998,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 79599.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 221789.36,
    "out:DH kr savings": 71365.3464284312,
    "out:El kr savings": 42607.077996404216,
    "out:El kr sold": 107816.93556907076,
    "out:El kr saved": 26089.223556,
    "out:El kr return": 133906.15912507076,
    "out:% solar/total": 33,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 335776.831144,
    "out:% savings (space heating)": 17.52836,
    "out:Total_Water Heating (DH)": 132772.760051,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 105.46,
    "out:Etvv": 25,
    "out:Ef": 5.88,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 2064038.445467325,
    "out:Seasonal Variation ROI (%)": 10,
    "out:Seasonal Variation Payback": 10,
    "out:New AHU Cost/SEK": 146792.87151982498,
    "out:DH CO2": 52.917648,
    "out:EL CO2": -114.042364,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -114.819396,
    "out:Bought CO2": 0.777032,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00001000020101000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 1,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 110.193654,
    "out:DHW (DH)": 35.445,
    "out:Property Electricity": 9.976115,
    "out:Electricity (inc PV)": 5.879188,
    "out:Total Energy Use Pre PV": 155.976115,
    "out:Total Energy Use Post PV": 151.879188,
    "out:Primary Energy": 111.163713,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 32.527126,
    "out:CO2 Operational/m2": -61.4847,
    "out:Total CO2/m2": -28.957574,
    "out:Total CO2 (tons)": -92.200888,
    "out:Klimatpaverkan": -292.336309,
    "out:Initial Cost/MSEK": 2.2576544704673247,
    "out:Running cost/(Apt SEK y)": 28372.574468,
    "out:Running Cost over RSP/MSEK": 10.45371,
    "out:LCP/MSEK": 0.693356,
    "out:ROI% old": 18.673046,
    "out:Payback discounted": 7,
    "out:Atemp": 3184,
    "out:Umean": 0.82,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 152,
    "out:Energy use kWh/m2": 152,
    "out:Energy savings %": 20.4,
    "out:Op energy cost/MSEK": 78,
    "out:El price/MSEK": 45,
    "out:Heating price/MSEK": 28,
    "out:DH+ DHW/kWh/m2": 146,
    "out:El bought/kWh/m2": 413.65837,
    "out:Return %": 17,
    "out:Return/kSEK/y": 381,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 35.445,
    "out:EAHP (El)": 0,
    "out:PV (kr/kWp)": 17461.48474631725,
    "out:PV (kWp installed)": 90.5097,
    "out:PV (m2 panels)": 474.867263,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 84922.568824,
    "out:PV (kWh sold)": 71877.957046,
    "out:PV (% sold (El))": 84.639405,
    "out:PV (kWh self-consumed)": 13044.611778,
    "out:PV (ratio sold/self-consumed)": 5.510165,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 10,
    "out:Payback Time": 10,
    "out:DH kWh savings": 76201.601537,
    "out:EL kWh savings": 21303.538998,
    "out:Sewage heat recovery cost": 273216,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 226625.62,
    "out:DH kr savings": 76201.60153650118,
    "out:El kr savings": 42607.077996404216,
    "out:El kr sold": 107816.93556907076,
    "out:El kr saved": 26089.223556,
    "out:El kr return": 133906.15912507076,
    "out:% solar/total": 33,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 350856.490044,
    "out:% savings (space heating)": 13.824578,
    "out:Total_Water Heating (DH)": 112856.846044,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 110.19,
    "out:Etvv": 21.25,
    "out:Ef": 5.88,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 2257654.470467325,
    "out:Seasonal Variation ROI (%)": 10,
    "out:Seasonal Variation Payback": 10,
    "out:New AHU Cost/SEK": 146792.87151982498,
    "out:DH CO2": 52.557664,
    "out:EL CO2": -114.042364,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -114.819396,
    "out:Bought CO2": 0.777032,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00001000020101100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 1,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 105.457579,
    "out:DHW (DH)": 35.445,
    "out:Property Electricity": 9.976115,
    "out:Electricity (inc PV)": 5.879188,
    "out:Total Energy Use Pre PV": 150.976115,
    "out:Total Energy Use Post PV": 146.879188,
    "out:Primary Energy": 107.480099,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 32.527126,
    "out:CO2 Operational/m2": -63.28462,
    "out:Total CO2/m2": -30.757494,
    "out:Total CO2 (tons)": -97.931831,
    "out:Klimatpaverkan": -298.067252,
    "out:Initial Cost/MSEK": 2.337254445467325,
    "out:Running cost/(Apt SEK y)": 27349.617021,
    "out:Running Cost over RSP/MSEK": 10.07765,
    "out:LCP/MSEK": 0.989816,
    "out:ROI% old": 20.335643,
    "out:Payback discounted": 6,
    "out:Atemp": 3184,
    "out:Umean": 0.82,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 147,
    "out:Energy use kWh/m2": 147,
    "out:Energy savings %": 24.5,
    "out:Op energy cost/MSEK": 75,
    "out:El price/MSEK": 43,
    "out:Heating price/MSEK": 27,
    "out:DH+ DHW/kWh/m2": 141,
    "out:El bought/kWh/m2": 413.65837,
    "out:Return %": 18,
    "out:Return/kSEK/y": 429,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 35.445,
    "out:EAHP (El)": 0,
    "out:PV (kr/kWp)": 17461.48474631725,
    "out:PV (kWp installed)": 90.5097,
    "out:PV (m2 panels)": 474.867263,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 84922.568824,
    "out:PV (kWh sold)": 71877.957046,
    "out:PV (% sold (El))": 84.639405,
    "out:PV (kWh self-consumed)": 13044.611778,
    "out:PV (ratio sold/self-consumed)": 5.510165,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 10,
    "out:Payback Time": 10,
    "out:DH kWh savings": 91281.260436,
    "out:EL kWh savings": 21303.538998,
    "out:Sewage heat recovery cost": 273216,
    "out:Balancing hydronic system cost": 79599.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 241705.27,
    "out:DH kr savings": 91281.26043614118,
    "out:El kr savings": 42607.077996404216,
    "out:El kr sold": 107816.93556907076,
    "out:El kr saved": 26089.223556,
    "out:El kr return": 133906.15912507076,
    "out:% solar/total": 33,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 335776.831144,
    "out:% savings (space heating)": 17.52836,
    "out:Total_Water Heating (DH)": 112856.846044,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 105.46,
    "out:Etvv": 21.25,
    "out:Ef": 5.88,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 2337254.445467325,
    "out:Seasonal Variation ROI (%)": 10,
    "out:Seasonal Variation Payback": 10,
    "out:New AHU Cost/SEK": 146792.87151982498,
    "out:DH CO2": 50.757744,
    "out:EL CO2": -114.042364,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -114.819396,
    "out:Bought CO2": 0.777032,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00001000100000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 110.193654,
    "out:DHW (DH)": 33.36,
    "out:Property Electricity": 12.57,
    "out:Electricity (inc PV)": 12.57,
    "out:Total Energy Use Pre PV": 156.57,
    "out:Total Energy Use Post PV": 156.57,
    "out:Primary Energy": 122.332175,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 2.155882,
    "out:CO2 Operational/m2": 53.497037,
    "out:Total CO2/m2": 55.652919,
    "out:Total CO2 (tons)": 177.198841,
    "out:Klimatpaverkan": -22.93658,
    "out:Initial Cost/MSEK": 0.38125954050375005,
    "out:Running cost/(Apt SEK y)": 31163.893617,
    "out:Running Cost over RSP/MSEK": 11.44923,
    "out:LCP/MSEK": 1.57423,
    "out:ROI% old": 73.271796,
    "out:Payback discounted": 2,
    "out:Atemp": 3184,
    "out:Umean": 0.82,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 157,
    "out:Energy use kWh/m2": 157,
    "out:Energy savings %": 16.6,
    "out:Op energy cost/MSEK": 84,
    "out:El price/MSEK": 51,
    "out:Heating price/MSEK": 28,
    "out:DH+ DHW/kWh/m2": 144,
    "out:El bought/kWh/m2": 879.9,
    "out:Return %": 66,
    "out:Return/kSEK/y": 250,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 33.36,
    "out:EAHP (El)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 22,
    "out:Payback Time": 4.545455,
    "out:DH kWh savings": 82840.239539,
    "out:EL kWh savings": 2.2624e-11,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 82840.24,
    "out:DH kr savings": 82840.2395390712,
    "out:El kr savings": 4.524734779920436e-11,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 350856.490044,
    "out:% savings (space heating)": 13.824578,
    "out:Total_Water Heating (DH)": 106218.208041,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 110.19,
    "out:Etvv": 20,
    "out:Ef": 12.57,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 381259.54050375003,
    "out:Seasonal Variation ROI (%)": 20,
    "out:Seasonal Variation Payback": 5,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 51.837696,
    "out:EL CO2": 1.659341,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 1.659341,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00001000100000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 105.457579,
    "out:DHW (DH)": 33.36,
    "out:Property Electricity": 12.57,
    "out:Electricity (inc PV)": 12.57,
    "out:Total Energy Use Pre PV": 151.57,
    "out:Total Energy Use Post PV": 151.57,
    "out:Primary Energy": 118.648561,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 2.155882,
    "out:CO2 Operational/m2": 51.697117,
    "out:Total CO2/m2": 53.852999,
    "out:Total CO2 (tons)": 171.467897,
    "out:Klimatpaverkan": -28.667524,
    "out:Initial Cost/MSEK": 0.46085951550375,
    "out:Running cost/(Apt SEK y)": 30140.957447,
    "out:Running Cost over RSP/MSEK": 11.07317,
    "out:LCP/MSEK": 1.87069,
    "out:ROI% old": 72.273341,
    "out:Payback discounted": 2,
    "out:Atemp": 3184,
    "out:Umean": 0.82,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 152,
    "out:Energy use kWh/m2": 152,
    "out:Energy savings %": 20.4,
    "out:Op energy cost/MSEK": 81,
    "out:El price/MSEK": 49,
    "out:Heating price/MSEK": 27,
    "out:DH+ DHW/kWh/m2": 139,
    "out:El bought/kWh/m2": 879.9,
    "out:Return %": 65,
    "out:Return/kSEK/y": 298,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 33.36,
    "out:EAHP (El)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 21,
    "out:Payback Time": 4.761905,
    "out:DH kWh savings": 97919.898439,
    "out:EL kWh savings": 2.2624e-11,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 79599.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 97919.9,
    "out:DH kr savings": 97919.89843871113,
    "out:El kr savings": 4.524734779920436e-11,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 335776.831144,
    "out:% savings (space heating)": 17.52836,
    "out:Total_Water Heating (DH)": 106218.208041,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 105.46,
    "out:Etvv": 20,
    "out:Ef": 12.57,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 460859.51550375,
    "out:Seasonal Variation ROI (%)": 19,
    "out:Seasonal Variation Payback": 5.263158,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 50.037776,
    "out:EL CO2": 1.659341,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 1.659341,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00001000100001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 110.193654,
    "out:DHW (DH)": 28.356,
    "out:Property Electricity": 12.57,
    "out:Electricity (inc PV)": 12.57,
    "out:Total Energy Use Pre PV": 151.57,
    "out:Total Energy Use Post PV": 151.57,
    "out:Primary Energy": 120.232175,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 2.864425,
    "out:CO2 Operational/m2": 51.697117,
    "out:Total CO2/m2": 54.561542,
    "out:Total CO2 (tons)": 173.723897,
    "out:Klimatpaverkan": -26.411524,
    "out:Initial Cost/MSEK": 0.65447554050375,
    "out:Running cost/(Apt SEK y)": 30140.957447,
    "out:Running Cost over RSP/MSEK": 11.07317,
    "out:LCP/MSEK": 1.677074,
    "out:ROI% old": 50.89244,
    "out:Payback discounted": 3,
    "out:Atemp": 3184,
    "out:Umean": 0.82,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 152,
    "out:Energy use kWh/m2": 152,
    "out:Energy savings %": 20.4,
    "out:Op energy cost/MSEK": 81,
    "out:El price/MSEK": 49,
    "out:Heating price/MSEK": 27,
    "out:DH+ DHW/kWh/m2": 139,
    "out:El bought/kWh/m2": 879.9,
    "out:Return %": 46,
    "out:Return/kSEK/y": 298,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 28.356,
    "out:EAHP (El)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 15,
    "out:Payback Time": 6.666667,
    "out:DH kWh savings": 98772.970745,
    "out:EL kWh savings": 2.2624e-11,
    "out:Sewage heat recovery cost": 273216,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 98772.97,
    "out:DH kr savings": 98772.97074523917,
    "out:El kr savings": 4.524734779920436e-11,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 350856.490044,
    "out:% savings (space heating)": 13.824578,
    "out:Total_Water Heating (DH)": 90285.476835,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 110.19,
    "out:Etvv": 17,
    "out:Ef": 12.57,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 654475.54050375,
    "out:Seasonal Variation ROI (%)": 14,
    "out:Seasonal Variation Payback": 7.142857,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 50.037776,
    "out:EL CO2": 1.659341,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 1.659341,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00001000100001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 105.457579,
    "out:DHW (DH)": 28.356,
    "out:Property Electricity": 12.57,
    "out:Electricity (inc PV)": 12.57,
    "out:Total Energy Use Pre PV": 146.57,
    "out:Total Energy Use Post PV": 146.57,
    "out:Primary Energy": 116.548561,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 2.864425,
    "out:CO2 Operational/m2": 49.897197,
    "out:Total CO2/m2": 52.761622,
    "out:Total CO2 (tons)": 167.992954,
    "out:Klimatpaverkan": -32.142467,
    "out:Initial Cost/MSEK": 0.73407551550375,
    "out:Running cost/(Apt SEK y)": 29118,
    "out:Running Cost over RSP/MSEK": 10.69712,
    "out:LCP/MSEK": 1.973524,
    "out:ROI% old": 52.692127,
    "out:Payback discounted": 3,
    "out:Atemp": 3184,
    "out:Umean": 0.82,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 147,
    "out:Energy use kWh/m2": 147,
    "out:Energy savings %": 24.5,
    "out:Op energy cost/MSEK": 78,
    "out:El price/MSEK": 48,
    "out:Heating price/MSEK": 26,
    "out:DH+ DHW/kWh/m2": 134,
    "out:El bought/kWh/m2": 879.9,
    "out:Return %": 47,
    "out:Return/kSEK/y": 346,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 28.356,
    "out:EAHP (El)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 16,
    "out:Payback Time": 6.25,
    "out:DH kWh savings": 113852.629645,
    "out:EL kWh savings": 2.2624e-11,
    "out:Sewage heat recovery cost": 273216,
    "out:Balancing hydronic system cost": 79599.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 113852.63,
    "out:DH kr savings": 113852.62964487918,
    "out:El kr savings": 4.524734779920436e-11,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 335776.831144,
    "out:% savings (space heating)": 17.52836,
    "out:Total_Water Heating (DH)": 90285.476835,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 105.46,
    "out:Etvv": 17,
    "out:Ef": 12.57,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 734075.5155037501,
    "out:Seasonal Variation ROI (%)": 14,
    "out:Seasonal Variation Payback": 7.142857,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 48.237856,
    "out:EL CO2": 1.659341,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 1.659341,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00001000100100000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 1,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 110.193654,
    "out:DHW (DH)": 33.36,
    "out:Property Electricity": 9.976115,
    "out:Electricity (inc PV)": 9.976115,
    "out:Total Energy Use Pre PV": 153.976115,
    "out:Total Energy Use Post PV": 153.976115,
    "out:Primary Energy": 117.663182,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 2.340384,
    "out:CO2 Operational/m2": 53.154623,
    "out:Total CO2/m2": 55.495007,
    "out:Total CO2 (tons)": 176.696049,
    "out:Klimatpaverkan": -23.439372,
    "out:Initial Cost/MSEK": 0.528052412023575,
    "out:Running cost/(Apt SEK y)": 30812.446809,
    "out:Running Cost over RSP/MSEK": 11.32153,
    "out:LCP/MSEK": 1.555138,
    "out:ROI% old": 56.357771,
    "out:Payback discounted": 2,
    "out:Atemp": 3184,
    "out:Umean": 0.82,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 154,
    "out:Energy use kWh/m2": 154,
    "out:Energy savings %": 18.8,
    "out:Op energy cost/MSEK": 83,
    "out:El price/MSEK": 50,
    "out:Heating price/MSEK": 28,
    "out:DH+ DHW/kWh/m2": 144,
    "out:El bought/kWh/m2": 698.32805,
    "out:Return %": 50,
    "out:Return/kSEK/y": 267,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 33.36,
    "out:EAHP (El)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 19,
    "out:Payback Time": 5.263158,
    "out:DH kWh savings": 82840.239539,
    "out:EL kWh savings": 8258.927355,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 99358.09,
    "out:DH kr savings": 82840.2395390712,
    "out:El kr savings": 16517.854710116386,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 350856.490044,
    "out:% savings (space heating)": 13.824578,
    "out:Total_Water Heating (DH)": 106218.208041,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 110.19,
    "out:Etvv": 20,
    "out:Ef": 9.98,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 528052.412023575,
    "out:Seasonal Variation ROI (%)": 17,
    "out:Seasonal Variation Payback": 5.882353,
    "out:New AHU Cost/SEK": 146792.87151982498,
    "out:DH CO2": 51.837696,
    "out:EL CO2": 1.316927,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 1.316927,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00001000100100100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 1,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 105.457579,
    "out:DHW (DH)": 33.36,
    "out:Property Electricity": 9.976115,
    "out:Electricity (inc PV)": 9.976115,
    "out:Total Energy Use Pre PV": 148.976115,
    "out:Total Energy Use Post PV": 148.976115,
    "out:Primary Energy": 113.979568,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 2.340384,
    "out:CO2 Operational/m2": 51.354703,
    "out:Total CO2/m2": 53.695087,
    "out:Total CO2 (tons)": 170.965106,
    "out:Klimatpaverkan": -29.170315,
    "out:Initial Cost/MSEK": 0.607652387023575,
    "out:Running cost/(Apt SEK y)": 29789.510638,
    "out:Running Cost over RSP/MSEK": 10.94548,
    "out:LCP/MSEK": 1.851588,
    "out:ROI% old": 57.81595,
    "out:Payback discounted": 2,
    "out:Atemp": 3184,
    "out:Umean": 0.82,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 149,
    "out:Energy use kWh/m2": 149,
    "out:Energy savings %": 22.8,
    "out:Op energy cost/MSEK": 80,
    "out:El price/MSEK": 48,
    "out:Heating price/MSEK": 27,
    "out:DH+ DHW/kWh/m2": 139,
    "out:El bought/kWh/m2": 698.32805,
    "out:Return %": 52,
    "out:Return/kSEK/y": 315,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 33.36,
    "out:EAHP (El)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 19,
    "out:Payback Time": 5.263158,
    "out:DH kWh savings": 97919.898439,
    "out:EL kWh savings": 8258.927355,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 79599.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 114437.75,
    "out:DH kr savings": 97919.89843871113,
    "out:El kr savings": 16517.854710116386,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 335776.831144,
    "out:% savings (space heating)": 17.52836,
    "out:Total_Water Heating (DH)": 106218.208041,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 105.46,
    "out:Etvv": 20,
    "out:Ef": 9.98,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 607652.387023575,
    "out:Seasonal Variation ROI (%)": 17,
    "out:Seasonal Variation Payback": 5.882353,
    "out:New AHU Cost/SEK": 146792.87151982498,
    "out:DH CO2": 50.037776,
    "out:EL CO2": 1.316927,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 1.316927,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00001000100101000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 1,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 110.193654,
    "out:DHW (DH)": 28.356,
    "out:Property Electricity": 9.976115,
    "out:Electricity (inc PV)": 9.976115,
    "out:Total Energy Use Pre PV": 148.976115,
    "out:Total Energy Use Post PV": 148.976115,
    "out:Primary Energy": 115.563182,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 3.048927,
    "out:CO2 Operational/m2": 51.354703,
    "out:Total CO2/m2": 54.40363,
    "out:Total CO2 (tons)": 173.221106,
    "out:Klimatpaverkan": -26.914315,
    "out:Initial Cost/MSEK": 0.8012684120235749,
    "out:Running cost/(Apt SEK y)": 29789.510638,
    "out:Running Cost over RSP/MSEK": 10.94548,
    "out:LCP/MSEK": 1.657972,
    "out:ROI% old": 43.845482,
    "out:Payback discounted": 3,
    "out:Atemp": 3184,
    "out:Umean": 0.82,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 149,
    "out:Energy use kWh/m2": 149,
    "out:Energy savings %": 22.8,
    "out:Op energy cost/MSEK": 80,
    "out:El price/MSEK": 48,
    "out:Heating price/MSEK": 27,
    "out:DH+ DHW/kWh/m2": 139,
    "out:El bought/kWh/m2": 698.32805,
    "out:Return %": 39,
    "out:Return/kSEK/y": 315,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 28.356,
    "out:EAHP (El)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 14,
    "out:Payback Time": 7.142857,
    "out:DH kWh savings": 98772.970745,
    "out:EL kWh savings": 8258.927355,
    "out:Sewage heat recovery cost": 273216,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 115290.83,
    "out:DH kr savings": 98772.97074523917,
    "out:El kr savings": 16517.854710116386,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 350856.490044,
    "out:% savings (space heating)": 13.824578,
    "out:Total_Water Heating (DH)": 90285.476835,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 110.19,
    "out:Etvv": 17,
    "out:Ef": 9.98,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 801268.4120235749,
    "out:Seasonal Variation ROI (%)": 13,
    "out:Seasonal Variation Payback": 7.692308,
    "out:New AHU Cost/SEK": 146792.87151982498,
    "out:DH CO2": 50.037776,
    "out:EL CO2": 1.316927,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 1.316927,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00001000100101100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 1,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 105.457579,
    "out:DHW (DH)": 28.356,
    "out:Property Electricity": 9.976115,
    "out:Electricity (inc PV)": 9.976115,
    "out:Total Energy Use Pre PV": 143.976115,
    "out:Total Energy Use Post PV": 143.976115,
    "out:Primary Energy": 111.879568,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 3.048927,
    "out:CO2 Operational/m2": 49.554783,
    "out:Total CO2/m2": 52.60371,
    "out:Total CO2 (tons)": 167.490162,
    "out:Klimatpaverkan": -32.645259,
    "out:Initial Cost/MSEK": 0.880868387023575,
    "out:Running cost/(Apt SEK y)": 28766.574468,
    "out:Running Cost over RSP/MSEK": 10.56942,
    "out:LCP/MSEK": 1.954432,
    "out:ROI% old": 45.982222,
    "out:Payback discounted": 3,
    "out:Atemp": 3184,
    "out:Umean": 0.82,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 144,
    "out:Energy use kWh/m2": 144,
    "out:Energy savings %": 27.1,
    "out:Op energy cost/MSEK": 77,
    "out:El price/MSEK": 47,
    "out:Heating price/MSEK": 26,
    "out:DH+ DHW/kWh/m2": 134,
    "out:El bought/kWh/m2": 698.32805,
    "out:Return %": 41,
    "out:Return/kSEK/y": 363,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 28.356,
    "out:EAHP (El)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 15,
    "out:Payback Time": 6.666667,
    "out:DH kWh savings": 113852.629645,
    "out:EL kWh savings": 8258.927355,
    "out:Sewage heat recovery cost": 273216,
    "out:Balancing hydronic system cost": 79599.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 130370.48,
    "out:DH kr savings": 113852.62964487918,
    "out:El kr savings": 16517.854710116386,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 335776.831144,
    "out:% savings (space heating)": 17.52836,
    "out:Total_Water Heating (DH)": 90285.476835,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 105.46,
    "out:Etvv": 17,
    "out:Ef": 9.98,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 880868.3870235749,
    "out:Seasonal Variation ROI (%)": 14,
    "out:Seasonal Variation Payback": 7.142857,
    "out:New AHU Cost/SEK": 146792.87151982498,
    "out:DH CO2": 48.237856,
    "out:EL CO2": 1.316927,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 1.316927,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00001000110000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 110.193654,
    "out:DHW (DH)": 33.36,
    "out:Property Electricity": 12.57,
    "out:Electricity (inc PV)": 8.051517,
    "out:Total Energy Use Pre PV": 156.57,
    "out:Total Energy Use Post PV": 152.051517,
    "out:Primary Energy": 114.198906,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 16.894982,
    "out:CO2 Operational/m2": 8.236375,
    "out:Total CO2/m2": 25.131357,
    "out:Total CO2 (tons)": 80.018217,
    "out:Klimatpaverkan": -120.117204,
    "out:Initial Cost/MSEK": 1.1714799347262501,
    "out:Running cost/(Apt SEK y)": 29655.702128,
    "out:Running Cost over RSP/MSEK": 10.90986,
    "out:LCP/MSEK": 1.32338,
    "out:ROI% old": 30.423788,
    "out:Payback discounted": 4,
    "out:Atemp": 3184,
    "out:Umean": 0.82,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 152,
    "out:Energy use kWh/m2": 152,
    "out:Energy savings %": 20.4,
    "out:Op energy cost/MSEK": 80,
    "out:El price/MSEK": 48,
    "out:Heating price/MSEK": 28,
    "out:DH+ DHW/kWh/m2": 144,
    "out:El bought/kWh/m2": 568.258452,
    "out:Return %": 27,
    "out:Return/kSEK/y": 321,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 33.36,
    "out:EAHP (El)": 0,
    "out:PV (kr/kWp)": 17461.56255567083,
    "out:PV (kWp installed)": 45.25485,
    "out:PV (m2 panels)": 237.433632,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 42461.284501,
    "out:PV (kWh sold)": 28074.439514,
    "out:PV (% sold (El))": 66.117735,
    "out:PV (kWh self-consumed)": 14386.844987,
    "out:PV (ratio sold/self-consumed)": 1.951397,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 13,
    "out:Payback Time": 7.692308,
    "out:DH kWh savings": 82840.239539,
    "out:EL kWh savings": 14386.845543,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 153725.59,
    "out:DH kr savings": 82840.2395390712,
    "out:El kr savings": 28773.691086586638,
    "out:El kr sold": 42111.65927092506,
    "out:El kr saved": 28773.689974,
    "out:El kr return": 70885.34924492506,
    "out:% solar/total": 36,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 350856.490044,
    "out:% savings (space heating)": 13.824578,
    "out:Total_Water Heating (DH)": 106218.208041,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 110.19,
    "out:Etvv": 20,
    "out:Ef": 8.05,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 1171479.9347262501,
    "out:Seasonal Variation ROI (%)": 12,
    "out:Seasonal Variation Payback": 8.333333,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 51.837696,
    "out:EL CO2": -43.601321,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -44.666212,
    "out:Bought CO2": 1.064891,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00001000110000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 105.457579,
    "out:DHW (DH)": 33.36,
    "out:Property Electricity": 12.57,
    "out:Electricity (inc PV)": 8.051517,
    "out:Total Energy Use Pre PV": 151.57,
    "out:Total Energy Use Post PV": 147.051517,
    "out:Primary Energy": 110.515292,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 16.894982,
    "out:CO2 Operational/m2": 6.436455,
    "out:Total CO2/m2": 23.331437,
    "out:Total CO2 (tons)": 74.287273,
    "out:Klimatpaverkan": -125.848148,
    "out:Initial Cost/MSEK": 1.25107990972625,
    "out:Running cost/(Apt SEK y)": 28632.765957,
    "out:Running Cost over RSP/MSEK": 10.53381,
    "out:LCP/MSEK": 1.61983,
    "out:ROI% old": 32.782079,
    "out:Payback discounted": 4,
    "out:Atemp": 3184,
    "out:Umean": 0.82,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 147,
    "out:Energy use kWh/m2": 147,
    "out:Energy savings %": 24.5,
    "out:Op energy cost/MSEK": 78,
    "out:El price/MSEK": 46,
    "out:Heating price/MSEK": 27,
    "out:DH+ DHW/kWh/m2": 139,
    "out:El bought/kWh/m2": 568.258452,
    "out:Return %": 29,
    "out:Return/kSEK/y": 369,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 33.36,
    "out:EAHP (El)": 0,
    "out:PV (kr/kWp)": 17461.56255567083,
    "out:PV (kWp installed)": 45.25485,
    "out:PV (m2 panels)": 237.433632,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 42461.284501,
    "out:PV (kWh sold)": 28074.439514,
    "out:PV (% sold (El))": 66.117735,
    "out:PV (kWh self-consumed)": 14386.844987,
    "out:PV (ratio sold/self-consumed)": 1.951397,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 13,
    "out:Payback Time": 7.692308,
    "out:DH kWh savings": 97919.898439,
    "out:EL kWh savings": 14386.845543,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 79599.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 168805.25,
    "out:DH kr savings": 97919.89843871113,
    "out:El kr savings": 28773.691086586638,
    "out:El kr sold": 42111.65927092506,
    "out:El kr saved": 28773.689974,
    "out:El kr return": 70885.34924492506,
    "out:% solar/total": 36,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 335776.831144,
    "out:% savings (space heating)": 17.52836,
    "out:Total_Water Heating (DH)": 106218.208041,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 105.46,
    "out:Etvv": 20,
    "out:Ef": 8.05,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 1251079.90972625,
    "out:Seasonal Variation ROI (%)": 13,
    "out:Seasonal Variation Payback": 7.692308,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 50.037776,
    "out:EL CO2": -43.601321,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -44.666212,
    "out:Bought CO2": 1.064891,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00001000110001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 110.193654,
    "out:DHW (DH)": 28.356,
    "out:Property Electricity": 12.57,
    "out:Electricity (inc PV)": 8.051517,
    "out:Total Energy Use Pre PV": 151.57,
    "out:Total Energy Use Post PV": 147.051517,
    "out:Primary Energy": 112.098906,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 17.603525,
    "out:CO2 Operational/m2": 6.436455,
    "out:Total CO2/m2": 24.03998,
    "out:Total CO2 (tons)": 76.543273,
    "out:Klimatpaverkan": -123.592148,
    "out:Initial Cost/MSEK": 1.44469593472625,
    "out:Running cost/(Apt SEK y)": 28632.765957,
    "out:Running Cost over RSP/MSEK": 10.53381,
    "out:LCP/MSEK": 1.426214,
    "out:ROI% old": 28.388673,
    "out:Payback discounted": 4,
    "out:Atemp": 3184,
    "out:Umean": 0.82,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 147,
    "out:Energy use kWh/m2": 147,
    "out:Energy savings %": 24.5,
    "out:Op energy cost/MSEK": 78,
    "out:El price/MSEK": 46,
    "out:Heating price/MSEK": 27,
    "out:DH+ DHW/kWh/m2": 139,
    "out:El bought/kWh/m2": 568.258452,
    "out:Return %": 26,
    "out:Return/kSEK/y": 369,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 28.356,
    "out:EAHP (El)": 0,
    "out:PV (kr/kWp)": 17461.56255567083,
    "out:PV (kWp installed)": 45.25485,
    "out:PV (m2 panels)": 237.433632,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 42461.284501,
    "out:PV (kWh sold)": 28074.439514,
    "out:PV (% sold (El))": 66.117735,
    "out:PV (kWh self-consumed)": 14386.844987,
    "out:PV (ratio sold/self-consumed)": 1.951397,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 12,
    "out:Payback Time": 8.333333,
    "out:DH kWh savings": 98772.970745,
    "out:EL kWh savings": 14386.845543,
    "out:Sewage heat recovery cost": 273216,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 169658.32,
    "out:DH kr savings": 98772.97074523917,
    "out:El kr savings": 28773.691086586638,
    "out:El kr sold": 42111.65927092506,
    "out:El kr saved": 28773.689974,
    "out:El kr return": 70885.34924492506,
    "out:% solar/total": 36,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 350856.490044,
    "out:% savings (space heating)": 13.824578,
    "out:Total_Water Heating (DH)": 90285.476835,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 110.19,
    "out:Etvv": 17,
    "out:Ef": 8.05,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 1444695.9347262501,
    "out:Seasonal Variation ROI (%)": 11,
    "out:Seasonal Variation Payback": 9.090909,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 50.037776,
    "out:EL CO2": -43.601321,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -44.666212,
    "out:Bought CO2": 1.064891,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00001000110001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 105.457579,
    "out:DHW (DH)": 28.356,
    "out:Property Electricity": 12.57,
    "out:Electricity (inc PV)": 8.051517,
    "out:Total Energy Use Pre PV": 146.57,
    "out:Total Energy Use Post PV": 142.051517,
    "out:Primary Energy": 108.415292,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 17.603525,
    "out:CO2 Operational/m2": 4.636535,
    "out:Total CO2/m2": 22.24006,
    "out:Total CO2 (tons)": 70.81233,
    "out:Klimatpaverkan": -129.323091,
    "out:Initial Cost/MSEK": 1.52429590972625,
    "out:Running cost/(Apt SEK y)": 27609.808511,
    "out:Running Cost over RSP/MSEK": 10.15775,
    "out:LCP/MSEK": 1.722674,
    "out:ROI% old": 30.430631,
    "out:Payback discounted": 4,
    "out:Atemp": 3184,
    "out:Umean": 0.82,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 142,
    "out:Energy use kWh/m2": 142,
    "out:Energy savings %": 28.9,
    "out:Op energy cost/MSEK": 75,
    "out:El price/MSEK": 44,
    "out:Heating price/MSEK": 26,
    "out:DH+ DHW/kWh/m2": 134,
    "out:El bought/kWh/m2": 568.258452,
    "out:Return %": 27,
    "out:Return/kSEK/y": 417,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 28.356,
    "out:EAHP (El)": 0,
    "out:PV (kr/kWp)": 17461.56255567083,
    "out:PV (kWp installed)": 45.25485,
    "out:PV (m2 panels)": 237.433632,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 42461.284501,
    "out:PV (kWh sold)": 28074.439514,
    "out:PV (% sold (El))": 66.117735,
    "out:PV (kWh self-consumed)": 14386.844987,
    "out:PV (ratio sold/self-consumed)": 1.951397,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 12,
    "out:Payback Time": 8.333333,
    "out:DH kWh savings": 113852.629645,
    "out:EL kWh savings": 14386.845543,
    "out:Sewage heat recovery cost": 273216,
    "out:Balancing hydronic system cost": 79599.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 184737.98,
    "out:DH kr savings": 113852.62964487918,
    "out:El kr savings": 28773.691086586638,
    "out:El kr sold": 42111.65927092506,
    "out:El kr saved": 28773.689974,
    "out:El kr return": 70885.34924492506,
    "out:% solar/total": 36,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 335776.831144,
    "out:% savings (space heating)": 17.52836,
    "out:Total_Water Heating (DH)": 90285.476835,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 105.46,
    "out:Etvv": 17,
    "out:Ef": 8.05,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 1524295.90972625,
    "out:Seasonal Variation ROI (%)": 11,
    "out:Seasonal Variation Payback": 9.090909,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 48.237856,
    "out:EL CO2": -43.601321,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -44.666212,
    "out:Bought CO2": 1.064891,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00001000110100000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 1,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 110.193654,
    "out:DHW (DH)": 33.36,
    "out:Property Electricity": 9.976115,
    "out:Electricity (inc PV)": 6.231337,
    "out:Total Energy Use Pre PV": 153.976115,
    "out:Total Energy Use Post PV": 150.231337,
    "out:Primary Energy": 110.922582,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 17.079484,
    "out:CO2 Operational/m2": 4.006247,
    "out:Total CO2/m2": 21.085731,
    "out:Total CO2 (tons)": 67.136947,
    "out:Klimatpaverkan": -132.998474,
    "out:Initial Cost/MSEK": 1.3182728062460753,
    "out:Running cost/(Apt SEK y)": 29330.468085,
    "out:Running Cost over RSP/MSEK": 10.79163,
    "out:LCP/MSEK": 1.294817,
    "out:ROI% old": 28.317247,
    "out:Payback discounted": 4,
    "out:Atemp": 3184,
    "out:Umean": 0.82,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 150,
    "out:Energy use kWh/m2": 150,
    "out:Energy savings %": 22,
    "out:Op energy cost/MSEK": 80,
    "out:El price/MSEK": 47,
    "out:Heating price/MSEK": 28,
    "out:DH+ DHW/kWh/m2": 144,
    "out:El bought/kWh/m2": 439.497378,
    "out:Return %": 26,
    "out:Return/kSEK/y": 336,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 33.36,
    "out:EAHP (El)": 0,
    "out:PV (kr/kWp)": 17461.56255567083,
    "out:PV (kWp installed)": 45.25485,
    "out:PV (m2 panels)": 237.433632,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 42461.284501,
    "out:PV (kWh sold)": 30537.914073,
    "out:PV (% sold (El))": 71.919431,
    "out:PV (kWh self-consumed)": 11923.370428,
    "out:PV (ratio sold/self-consumed)": 2.561181,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 13,
    "out:Payback Time": 7.692308,
    "out:DH kWh savings": 82840.239539,
    "out:EL kWh savings": 20182.29692,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 169011.7,
    "out:DH kr savings": 82840.2395390712,
    "out:El kr savings": 40364.59383912171,
    "out:El kr sold": 45806.87111012268,
    "out:El kr saved": 23846.740856,
    "out:El kr return": 69653.61196612268,
    "out:% solar/total": 30,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 350856.490044,
    "out:% savings (space heating)": 13.824578,
    "out:Total_Water Heating (DH)": 106218.208041,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 110.19,
    "out:Etvv": 20,
    "out:Ef": 6.23,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 1318272.8062460753,
    "out:Seasonal Variation ROI (%)": 12,
    "out:Seasonal Variation Payback": 8.333333,
    "out:New AHU Cost/SEK": 146792.87151982498,
    "out:DH CO2": 51.837696,
    "out:EL CO2": -47.831449,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -48.655512,
    "out:Bought CO2": 0.824063,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00001000110100100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 1,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 105.457579,
    "out:DHW (DH)": 33.36,
    "out:Property Electricity": 9.976115,
    "out:Electricity (inc PV)": 6.231337,
    "out:Total Energy Use Pre PV": 148.976115,
    "out:Total Energy Use Post PV": 145.231337,
    "out:Primary Energy": 107.238968,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 17.079484,
    "out:CO2 Operational/m2": 2.206327,
    "out:Total CO2/m2": 19.285811,
    "out:Total CO2 (tons)": 61.406004,
    "out:Klimatpaverkan": -138.729417,
    "out:Initial Cost/MSEK": 1.3978727812460752,
    "out:Running cost/(Apt SEK y)": 28307.510638,
    "out:Running Cost over RSP/MSEK": 10.41558,
    "out:LCP/MSEK": 1.591267,
    "out:ROI% old": 30.547844,
    "out:Payback discounted": 4,
    "out:Atemp": 3184,
    "out:Umean": 0.82,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 145,
    "out:Energy use kWh/m2": 145,
    "out:Energy savings %": 26.2,
    "out:Op energy cost/MSEK": 77,
    "out:El price/MSEK": 45,
    "out:Heating price/MSEK": 27,
    "out:DH+ DHW/kWh/m2": 139,
    "out:El bought/kWh/m2": 439.497378,
    "out:Return %": 27,
    "out:Return/kSEK/y": 384,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 33.36,
    "out:EAHP (El)": 0,
    "out:PV (kr/kWp)": 17461.56255567083,
    "out:PV (kWp installed)": 45.25485,
    "out:PV (m2 panels)": 237.433632,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 42461.284501,
    "out:PV (kWh sold)": 30537.914073,
    "out:PV (% sold (El))": 71.919431,
    "out:PV (kWh self-consumed)": 11923.370428,
    "out:PV (ratio sold/self-consumed)": 2.561181,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 13,
    "out:Payback Time": 7.692308,
    "out:DH kWh savings": 97919.898439,
    "out:EL kWh savings": 20182.29692,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 79599.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 184091.36,
    "out:DH kr savings": 97919.89843871113,
    "out:El kr savings": 40364.59383912171,
    "out:El kr sold": 45806.87111012268,
    "out:El kr saved": 23846.740856,
    "out:El kr return": 69653.61196612268,
    "out:% solar/total": 30,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 335776.831144,
    "out:% savings (space heating)": 17.52836,
    "out:Total_Water Heating (DH)": 106218.208041,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 105.46,
    "out:Etvv": 20,
    "out:Ef": 6.23,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 1397872.7812460752,
    "out:Seasonal Variation ROI (%)": 13,
    "out:Seasonal Variation Payback": 7.692308,
    "out:New AHU Cost/SEK": 146792.87151982498,
    "out:DH CO2": 50.037776,
    "out:EL CO2": -47.831449,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -48.655512,
    "out:Bought CO2": 0.824063,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00001000110101000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 1,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 110.193654,
    "out:DHW (DH)": 28.356,
    "out:Property Electricity": 9.976115,
    "out:Electricity (inc PV)": 6.231337,
    "out:Total Energy Use Pre PV": 148.976115,
    "out:Total Energy Use Post PV": 145.231337,
    "out:Primary Energy": 108.822582,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 17.788027,
    "out:CO2 Operational/m2": 2.206327,
    "out:Total CO2/m2": 19.994354,
    "out:Total CO2 (tons)": 63.662004,
    "out:Klimatpaverkan": -136.473417,
    "out:Initial Cost/MSEK": 1.5914888062460752,
    "out:Running cost/(Apt SEK y)": 28307.510638,
    "out:Running Cost over RSP/MSEK": 10.41558,
    "out:LCP/MSEK": 1.397651,
    "out:ROI% old": 26.83148,
    "out:Payback discounted": 5,
    "out:Atemp": 3184,
    "out:Umean": 0.82,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 145,
    "out:Energy use kWh/m2": 145,
    "out:Energy savings %": 26.2,
    "out:Op energy cost/MSEK": 77,
    "out:El price/MSEK": 45,
    "out:Heating price/MSEK": 27,
    "out:DH+ DHW/kWh/m2": 139,
    "out:El bought/kWh/m2": 439.497378,
    "out:Return %": 24,
    "out:Return/kSEK/y": 384,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 28.356,
    "out:EAHP (El)": 0,
    "out:PV (kr/kWp)": 17461.56255567083,
    "out:PV (kWp installed)": 45.25485,
    "out:PV (m2 panels)": 237.433632,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 42461.284501,
    "out:PV (kWh sold)": 30537.914073,
    "out:PV (% sold (El))": 71.919431,
    "out:PV (kWh self-consumed)": 11923.370428,
    "out:PV (ratio sold/self-consumed)": 2.561181,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 12,
    "out:Payback Time": 8.333333,
    "out:DH kWh savings": 98772.970745,
    "out:EL kWh savings": 20182.29692,
    "out:Sewage heat recovery cost": 273216,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 184944.44,
    "out:DH kr savings": 98772.97074523917,
    "out:El kr savings": 40364.59383912171,
    "out:El kr sold": 45806.87111012268,
    "out:El kr saved": 23846.740856,
    "out:El kr return": 69653.61196612268,
    "out:% solar/total": 30,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 350856.490044,
    "out:% savings (space heating)": 13.824578,
    "out:Total_Water Heating (DH)": 90285.476835,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 110.19,
    "out:Etvv": 17,
    "out:Ef": 6.23,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 1591488.8062460753,
    "out:Seasonal Variation ROI (%)": 11,
    "out:Seasonal Variation Payback": 9.090909,
    "out:New AHU Cost/SEK": 146792.87151982498,
    "out:DH CO2": 50.037776,
    "out:EL CO2": -47.831449,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -48.655512,
    "out:Bought CO2": 0.824063,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00001000110101100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 1,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 105.457579,
    "out:DHW (DH)": 28.356,
    "out:Property Electricity": 9.976115,
    "out:Electricity (inc PV)": 6.231337,
    "out:Total Energy Use Pre PV": 143.976115,
    "out:Total Energy Use Post PV": 140.231337,
    "out:Primary Energy": 105.138968,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 17.788027,
    "out:CO2 Operational/m2": 0.406407,
    "out:Total CO2/m2": 18.194434,
    "out:Total CO2 (tons)": 57.93106,
    "out:Klimatpaverkan": -142.204361,
    "out:Initial Cost/MSEK": 1.671088781246075,
    "out:Running cost/(Apt SEK y)": 27284.574468,
    "out:Running Cost over RSP/MSEK": 10.03952,
    "out:LCP/MSEK": 1.694111,
    "out:ROI% old": 28.768242,
    "out:Payback discounted": 4,
    "out:Atemp": 3184,
    "out:Umean": 0.82,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 140,
    "out:Energy use kWh/m2": 140,
    "out:Energy savings %": 30.7,
    "out:Op energy cost/MSEK": 74,
    "out:El price/MSEK": 44,
    "out:Heating price/MSEK": 26,
    "out:DH+ DHW/kWh/m2": 134,
    "out:El bought/kWh/m2": 439.497378,
    "out:Return %": 26,
    "out:Return/kSEK/y": 432,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 28.356,
    "out:EAHP (El)": 0,
    "out:PV (kr/kWp)": 17461.56255567083,
    "out:PV (kWp installed)": 45.25485,
    "out:PV (m2 panels)": 237.433632,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 42461.284501,
    "out:PV (kWh sold)": 30537.914073,
    "out:PV (% sold (El))": 71.919431,
    "out:PV (kWh self-consumed)": 11923.370428,
    "out:PV (ratio sold/self-consumed)": 2.561181,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 12,
    "out:Payback Time": 8.333333,
    "out:DH kWh savings": 113852.629645,
    "out:EL kWh savings": 20182.29692,
    "out:Sewage heat recovery cost": 273216,
    "out:Balancing hydronic system cost": 79599.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 200024.09,
    "out:DH kr savings": 113852.62964487918,
    "out:El kr savings": 40364.59383912171,
    "out:El kr sold": 45806.87111012268,
    "out:El kr saved": 23846.740856,
    "out:El kr return": 69653.61196612268,
    "out:% solar/total": 30,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 335776.831144,
    "out:% savings (space heating)": 17.52836,
    "out:Total_Water Heating (DH)": 90285.476835,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 105.46,
    "out:Etvv": 17,
    "out:Ef": 6.23,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 1671088.7812460752,
    "out:Seasonal Variation ROI (%)": 11,
    "out:Seasonal Variation Payback": 9.090909,
    "out:New AHU Cost/SEK": 146792.87151982498,
    "out:DH CO2": 48.237856,
    "out:EL CO2": -47.831449,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -48.655512,
    "out:Bought CO2": 0.824063,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00001000120000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 110.193654,
    "out:DHW (DH)": 33.36,
    "out:Property Electricity": 12.57,
    "out:Electricity (inc PV)": 7.530921,
    "out:Total Energy Use Pre PV": 156.57,
    "out:Total Energy Use Post PV": 151.530921,
    "out:Primary Energy": 113.261833,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 31.634081,
    "out:CO2 Operational/m2": -57.118942,
    "out:Total CO2/m2": -25.484861,
    "out:Total CO2 (tons)": -81.143773,
    "out:Klimatpaverkan": -281.279194,
    "out:Initial Cost/MSEK": 1.9616932864474999,
    "out:Running cost/(Apt SEK y)": 28282.914894,
    "out:Running Cost over RSP/MSEK": 10.41943,
    "out:LCP/MSEK": 1.023597,
    "out:ROI% old": 21.739892,
    "out:Payback discounted": 6,
    "out:Atemp": 3184,
    "out:Umean": 0.82,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 152,
    "out:Energy use kWh/m2": 152,
    "out:Energy savings %": 20.4,
    "out:Op energy cost/MSEK": 77,
    "out:El price/MSEK": 45,
    "out:Heating price/MSEK": 28,
    "out:DH+ DHW/kWh/m2": 144,
    "out:El bought/kWh/m2": 530.25352,
    "out:Return %": 20,
    "out:Return/kSEK/y": 385,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 33.36,
    "out:EAHP (El)": 0,
    "out:PV (kr/kWp)": 17461.48474631725,
    "out:PV (kWp installed)": 90.5097,
    "out:PV (m2 panels)": 474.867263,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 84922.568824,
    "out:PV (kWh sold)": 68878.145463,
    "out:PV (% sold (El))": 81.106997,
    "out:PV (kWh self-consumed)": 16044.423361,
    "out:PV (ratio sold/self-consumed)": 4.292965,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 11,
    "out:Payback Time": 9.090909,
    "out:DH kWh savings": 82840.239539,
    "out:EL kWh savings": 16044.422709,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 218246.3,
    "out:DH kr savings": 82840.2395390712,
    "out:El kr savings": 32088.845417124598,
    "out:El kr sold": 103317.21819422109,
    "out:El kr saved": 32088.846722,
    "out:El kr return": 135406.06491622108,
    "out:% solar/total": 40,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 350856.490044,
    "out:% savings (space heating)": 13.824578,
    "out:Total_Water Heating (DH)": 106218.208041,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 110.19,
    "out:Etvv": 20,
    "out:Ef": 7.53,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 1961693.2864474999,
    "out:Seasonal Variation ROI (%)": 11,
    "out:Seasonal Variation Payback": 9.090909,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 51.837696,
    "out:EL CO2": -108.956638,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -109.952146,
    "out:Bought CO2": 0.995508,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00001000120000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 105.457579,
    "out:DHW (DH)": 33.36,
    "out:Property Electricity": 12.57,
    "out:Electricity (inc PV)": 7.530921,
    "out:Total Energy Use Pre PV": 151.57,
    "out:Total Energy Use Post PV": 146.530921,
    "out:Primary Energy": 109.578219,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 31.634081,
    "out:CO2 Operational/m2": -58.918862,
    "out:Total CO2/m2": -27.284781,
    "out:Total CO2 (tons)": -86.874717,
    "out:Klimatpaverkan": -287.010138,
    "out:Initial Cost/MSEK": 2.0412932614475,
    "out:Running cost/(Apt SEK y)": 27259.978723,
    "out:Running Cost over RSP/MSEK": 10.04337,
    "out:LCP/MSEK": 1.320057,
    "out:ROI% old": 23.523952,
    "out:Payback discounted": 5,
    "out:Atemp": 3184,
    "out:Umean": 0.82,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 147,
    "out:Energy use kWh/m2": 147,
    "out:Energy savings %": 24.5,
    "out:Op energy cost/MSEK": 75,
    "out:El price/MSEK": 43,
    "out:Heating price/MSEK": 27,
    "out:DH+ DHW/kWh/m2": 139,
    "out:El bought/kWh/m2": 530.25352,
    "out:Return %": 21,
    "out:Return/kSEK/y": 433,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 33.36,
    "out:EAHP (El)": 0,
    "out:PV (kr/kWp)": 17461.48474631725,
    "out:PV (kWp installed)": 90.5097,
    "out:PV (m2 panels)": 474.867263,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 84922.568824,
    "out:PV (kWh sold)": 68878.145463,
    "out:PV (% sold (El))": 81.106997,
    "out:PV (kWh self-consumed)": 16044.423361,
    "out:PV (ratio sold/self-consumed)": 4.292965,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 11,
    "out:Payback Time": 9.090909,
    "out:DH kWh savings": 97919.898439,
    "out:EL kWh savings": 16044.422709,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 79599.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 233325.96,
    "out:DH kr savings": 97919.89843871113,
    "out:El kr savings": 32088.845417124598,
    "out:El kr sold": 103317.21819422109,
    "out:El kr saved": 32088.846722,
    "out:El kr return": 135406.06491622108,
    "out:% solar/total": 40,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 335776.831144,
    "out:% savings (space heating)": 17.52836,
    "out:Total_Water Heating (DH)": 106218.208041,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 105.46,
    "out:Etvv": 20,
    "out:Ef": 7.53,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 2041293.2614475002,
    "out:Seasonal Variation ROI (%)": 11,
    "out:Seasonal Variation Payback": 9.090909,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 50.037776,
    "out:EL CO2": -108.956638,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -109.952146,
    "out:Bought CO2": 0.995508,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00001000120001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 110.193654,
    "out:DHW (DH)": 28.356,
    "out:Property Electricity": 12.57,
    "out:Electricity (inc PV)": 7.530921,
    "out:Total Energy Use Pre PV": 151.57,
    "out:Total Energy Use Post PV": 146.530921,
    "out:Primary Energy": 111.161833,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 32.342624,
    "out:CO2 Operational/m2": -58.918862,
    "out:Total CO2/m2": -26.576238,
    "out:Total CO2 (tons)": -84.618716,
    "out:Klimatpaverkan": -284.754137,
    "out:Initial Cost/MSEK": 2.2349092864475,
    "out:Running cost/(Apt SEK y)": 27259.978723,
    "out:Running Cost over RSP/MSEK": 10.04337,
    "out:LCP/MSEK": 1.126441,
    "out:ROI% old": 21.486011,
    "out:Payback discounted": 6,
    "out:Atemp": 3184,
    "out:Umean": 0.82,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 147,
    "out:Energy use kWh/m2": 147,
    "out:Energy savings %": 24.5,
    "out:Op energy cost/MSEK": 75,
    "out:El price/MSEK": 43,
    "out:Heating price/MSEK": 27,
    "out:DH+ DHW/kWh/m2": 139,
    "out:El bought/kWh/m2": 530.25352,
    "out:Return %": 19,
    "out:Return/kSEK/y": 433,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 28.356,
    "out:EAHP (El)": 0,
    "out:PV (kr/kWp)": 17461.48474631725,
    "out:PV (kWp installed)": 90.5097,
    "out:PV (m2 panels)": 474.867263,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 84922.568824,
    "out:PV (kWh sold)": 68878.145463,
    "out:PV (% sold (El))": 81.106997,
    "out:PV (kWh self-consumed)": 16044.423361,
    "out:PV (ratio sold/self-consumed)": 4.292965,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 10,
    "out:Payback Time": 10,
    "out:DH kWh savings": 98772.970745,
    "out:EL kWh savings": 16044.422709,
    "out:Sewage heat recovery cost": 273216,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 234179.03,
    "out:DH kr savings": 98772.97074523917,
    "out:El kr savings": 32088.845417124598,
    "out:El kr sold": 103317.21819422109,
    "out:El kr saved": 32088.846722,
    "out:El kr return": 135406.06491622108,
    "out:% solar/total": 40,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 350856.490044,
    "out:% savings (space heating)": 13.824578,
    "out:Total_Water Heating (DH)": 90285.476835,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 110.19,
    "out:Etvv": 17,
    "out:Ef": 7.53,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 2234909.2864475,
    "out:Seasonal Variation ROI (%)": 10,
    "out:Seasonal Variation Payback": 10,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 50.037776,
    "out:EL CO2": -108.956638,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -109.952146,
    "out:Bought CO2": 0.995508,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00001000120001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 105.457579,
    "out:DHW (DH)": 28.356,
    "out:Property Electricity": 12.57,
    "out:Electricity (inc PV)": 7.530921,
    "out:Total Energy Use Pre PV": 146.57,
    "out:Total Energy Use Post PV": 141.530921,
    "out:Primary Energy": 107.478219,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 32.342624,
    "out:CO2 Operational/m2": -60.718782,
    "out:Total CO2/m2": -28.376158,
    "out:Total CO2 (tons)": -90.34966,
    "out:Klimatpaverkan": -290.485081,
    "out:Initial Cost/MSEK": 2.3145092614475,
    "out:Running cost/(Apt SEK y)": 26237.021277,
    "out:Running Cost over RSP/MSEK": 9.66732,
    "out:LCP/MSEK": 1.422891,
    "out:ROI% old": 23.068142,
    "out:Payback discounted": 5,
    "out:Atemp": 3184,
    "out:Umean": 0.82,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 142,
    "out:Energy use kWh/m2": 142,
    "out:Energy savings %": 28.9,
    "out:Op energy cost/MSEK": 72,
    "out:El price/MSEK": 41,
    "out:Heating price/MSEK": 26,
    "out:DH+ DHW/kWh/m2": 134,
    "out:El bought/kWh/m2": 530.25352,
    "out:Return %": 21,
    "out:Return/kSEK/y": 482,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 28.356,
    "out:EAHP (El)": 0,
    "out:PV (kr/kWp)": 17461.48474631725,
    "out:PV (kWp installed)": 90.5097,
    "out:PV (m2 panels)": 474.867263,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 84922.568824,
    "out:PV (kWh sold)": 68878.145463,
    "out:PV (% sold (El))": 81.106997,
    "out:PV (kWh self-consumed)": 16044.423361,
    "out:PV (ratio sold/self-consumed)": 4.292965,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 11,
    "out:Payback Time": 9.090909,
    "out:DH kWh savings": 113852.629645,
    "out:EL kWh savings": 16044.422709,
    "out:Sewage heat recovery cost": 273216,
    "out:Balancing hydronic system cost": 79599.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 249258.69,
    "out:DH kr savings": 113852.62964487918,
    "out:El kr savings": 32088.845417124598,
    "out:El kr sold": 103317.21819422109,
    "out:El kr saved": 32088.846722,
    "out:El kr return": 135406.06491622108,
    "out:% solar/total": 40,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 335776.831144,
    "out:% savings (space heating)": 17.52836,
    "out:Total_Water Heating (DH)": 90285.476835,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 105.46,
    "out:Etvv": 17,
    "out:Ef": 7.53,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 2314509.2614475,
    "out:Seasonal Variation ROI (%)": 10,
    "out:Seasonal Variation Payback": 10,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 48.237856,
    "out:EL CO2": -108.956638,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -109.952146,
    "out:Bought CO2": 0.995508,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00001000120100000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 1,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 110.193654,
    "out:DHW (DH)": 33.36,
    "out:Property Electricity": 9.976115,
    "out:Electricity (inc PV)": 5.879188,
    "out:Total Energy Use Pre PV": 153.976115,
    "out:Total Energy Use Post PV": 149.879188,
    "out:Primary Energy": 110.288713,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 31.818583,
    "out:CO2 Operational/m2": -62.204668,
    "out:Total CO2/m2": -30.386085,
    "out:Total CO2 (tons)": -96.749266,
    "out:Klimatpaverkan": -296.884687,
    "out:Initial Cost/MSEK": 2.1084861579673246,
    "out:Running cost/(Apt SEK y)": 27963.382979,
    "out:Running Cost over RSP/MSEK": 10.30329,
    "out:LCP/MSEK": 0.992944,
    "out:ROI% old": 21.013248,
    "out:Payback discounted": 6,
    "out:Atemp": 3184,
    "out:Umean": 0.82,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 150,
    "out:Energy use kWh/m2": 150,
    "out:Energy savings %": 22,
    "out:Op energy cost/MSEK": 77,
    "out:El price/MSEK": 44,
    "out:Heating price/MSEK": 28,
    "out:DH+ DHW/kWh/m2": 144,
    "out:El bought/kWh/m2": 413.65837,
    "out:Return %": 19,
    "out:Return/kSEK/y": 400,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 33.36,
    "out:EAHP (El)": 0,
    "out:PV (kr/kWp)": 17461.48474631725,
    "out:PV (kWp installed)": 90.5097,
    "out:PV (m2 panels)": 474.867263,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 84922.568824,
    "out:PV (kWh sold)": 71877.957046,
    "out:PV (% sold (El))": 84.639405,
    "out:PV (kWh self-consumed)": 13044.611778,
    "out:PV (ratio sold/self-consumed)": 5.510165,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 11,
    "out:Payback Time": 9.090909,
    "out:DH kWh savings": 82840.239539,
    "out:EL kWh savings": 21303.538998,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 233264.25,
    "out:DH kr savings": 82840.2395390712,
    "out:El kr savings": 42607.077996404216,
    "out:El kr sold": 107816.93556907076,
    "out:El kr saved": 26089.223556,
    "out:El kr return": 133906.15912507076,
    "out:% solar/total": 33,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 350856.490044,
    "out:% savings (space heating)": 13.824578,
    "out:Total_Water Heating (DH)": 106218.208041,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 110.19,
    "out:Etvv": 20,
    "out:Ef": 5.88,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 2108486.157967325,
    "out:Seasonal Variation ROI (%)": 11,
    "out:Seasonal Variation Payback": 9.090909,
    "out:New AHU Cost/SEK": 146792.87151982498,
    "out:DH CO2": 51.837696,
    "out:EL CO2": -114.042364,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -114.819396,
    "out:Bought CO2": 0.777032,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00001000120100100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 1,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 105.457579,
    "out:DHW (DH)": 33.36,
    "out:Property Electricity": 9.976115,
    "out:Electricity (inc PV)": 5.879188,
    "out:Total Energy Use Pre PV": 148.976115,
    "out:Total Energy Use Post PV": 144.879188,
    "out:Primary Energy": 106.605099,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 31.818583,
    "out:CO2 Operational/m2": -64.004588,
    "out:Total CO2/m2": -32.186005,
    "out:Total CO2 (tons)": -102.480209,
    "out:Klimatpaverkan": -302.61563,
    "out:Initial Cost/MSEK": 2.188086132967325,
    "out:Running cost/(Apt SEK y)": 26940.446809,
    "out:Running Cost over RSP/MSEK": 9.92723,
    "out:LCP/MSEK": 1.289404,
    "out:ROI% old": 22.704055,
    "out:Payback discounted": 5,
    "out:Atemp": 3184,
    "out:Umean": 0.82,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 145,
    "out:Energy use kWh/m2": 145,
    "out:Energy savings %": 26.2,
    "out:Op energy cost/MSEK": 74,
    "out:El price/MSEK": 42,
    "out:Heating price/MSEK": 27,
    "out:DH+ DHW/kWh/m2": 139,
    "out:El bought/kWh/m2": 413.65837,
    "out:Return %": 20,
    "out:Return/kSEK/y": 449,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 33.36,
    "out:EAHP (El)": 0,
    "out:PV (kr/kWp)": 17461.48474631725,
    "out:PV (kWp installed)": 90.5097,
    "out:PV (m2 panels)": 474.867263,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 84922.568824,
    "out:PV (kWh sold)": 71877.957046,
    "out:PV (% sold (El))": 84.639405,
    "out:PV (kWh self-consumed)": 13044.611778,
    "out:PV (ratio sold/self-consumed)": 5.510165,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 11,
    "out:Payback Time": 9.090909,
    "out:DH kWh savings": 97919.898439,
    "out:EL kWh savings": 21303.538998,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 79599.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 248343.91,
    "out:DH kr savings": 97919.89843871113,
    "out:El kr savings": 42607.077996404216,
    "out:El kr sold": 107816.93556907076,
    "out:El kr saved": 26089.223556,
    "out:El kr return": 133906.15912507076,
    "out:% solar/total": 33,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 335776.831144,
    "out:% savings (space heating)": 17.52836,
    "out:Total_Water Heating (DH)": 106218.208041,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 105.46,
    "out:Etvv": 20,
    "out:Ef": 5.88,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 2188086.132967325,
    "out:Seasonal Variation ROI (%)": 11,
    "out:Seasonal Variation Payback": 9.090909,
    "out:New AHU Cost/SEK": 146792.87151982498,
    "out:DH CO2": 50.037776,
    "out:EL CO2": -114.042364,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -114.819396,
    "out:Bought CO2": 0.777032,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00001000120101000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 1,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 110.193654,
    "out:DHW (DH)": 28.356,
    "out:Property Electricity": 9.976115,
    "out:Electricity (inc PV)": 5.879188,
    "out:Total Energy Use Pre PV": 148.976115,
    "out:Total Energy Use Post PV": 144.879188,
    "out:Primary Energy": 108.188713,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 32.527126,
    "out:CO2 Operational/m2": -64.004588,
    "out:Total CO2/m2": -31.477462,
    "out:Total CO2 (tons)": -100.224209,
    "out:Klimatpaverkan": -300.35963,
    "out:Initial Cost/MSEK": 2.3817021579673248,
    "out:Running cost/(Apt SEK y)": 26940.446809,
    "out:Running Cost over RSP/MSEK": 9.92723,
    "out:LCP/MSEK": 1.095788,
    "out:ROI% old": 20.858372,
    "out:Payback discounted": 6,
    "out:Atemp": 3184,
    "out:Umean": 0.82,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 145,
    "out:Energy use kWh/m2": 145,
    "out:Energy savings %": 26.2,
    "out:Op energy cost/MSEK": 74,
    "out:El price/MSEK": 42,
    "out:Heating price/MSEK": 27,
    "out:DH+ DHW/kWh/m2": 139,
    "out:El bought/kWh/m2": 413.65837,
    "out:Return %": 19,
    "out:Return/kSEK/y": 449,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 28.356,
    "out:EAHP (El)": 0,
    "out:PV (kr/kWp)": 17461.48474631725,
    "out:PV (kWp installed)": 90.5097,
    "out:PV (m2 panels)": 474.867263,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 84922.568824,
    "out:PV (kWh sold)": 71877.957046,
    "out:PV (% sold (El))": 84.639405,
    "out:PV (kWh self-consumed)": 13044.611778,
    "out:PV (ratio sold/self-consumed)": 5.510165,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 10,
    "out:Payback Time": 10,
    "out:DH kWh savings": 98772.970745,
    "out:EL kWh savings": 21303.538998,
    "out:Sewage heat recovery cost": 273216,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 249196.98,
    "out:DH kr savings": 98772.97074523917,
    "out:El kr savings": 42607.077996404216,
    "out:El kr sold": 107816.93556907076,
    "out:El kr saved": 26089.223556,
    "out:El kr return": 133906.15912507076,
    "out:% solar/total": 33,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 350856.490044,
    "out:% savings (space heating)": 13.824578,
    "out:Total_Water Heating (DH)": 90285.476835,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 110.19,
    "out:Etvv": 17,
    "out:Ef": 5.88,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 2381702.157967325,
    "out:Seasonal Variation ROI (%)": 10,
    "out:Seasonal Variation Payback": 10,
    "out:New AHU Cost/SEK": 146792.87151982498,
    "out:DH CO2": 50.037776,
    "out:EL CO2": -114.042364,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -114.819396,
    "out:Bought CO2": 0.777032,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00001000120101100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 1,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 105.457579,
    "out:DHW (DH)": 28.356,
    "out:Property Electricity": 9.976115,
    "out:Electricity (inc PV)": 5.879188,
    "out:Total Energy Use Pre PV": 143.976115,
    "out:Total Energy Use Post PV": 139.879188,
    "out:Primary Energy": 104.505099,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 32.527126,
    "out:CO2 Operational/m2": -65.804508,
    "out:Total CO2/m2": -33.277382,
    "out:Total CO2 (tons)": -105.955152,
    "out:Klimatpaverkan": -306.090573,
    "out:Initial Cost/MSEK": 2.461302132967325,
    "out:Running cost/(Apt SEK y)": 25917.489362,
    "out:Running Cost over RSP/MSEK": 9.55117,
    "out:LCP/MSEK": 1.392248,
    "out:ROI% old": 22.3665,
    "out:Payback discounted": 6,
    "out:Atemp": 3184,
    "out:Umean": 0.82,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 140,
    "out:Energy use kWh/m2": 140,
    "out:Energy savings %": 30.7,
    "out:Op energy cost/MSEK": 71,
    "out:El price/MSEK": 41,
    "out:Heating price/MSEK": 26,
    "out:DH+ DHW/kWh/m2": 134,
    "out:El bought/kWh/m2": 413.65837,
    "out:Return %": 20,
    "out:Return/kSEK/y": 497,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 28.356,
    "out:EAHP (El)": 0,
    "out:PV (kr/kWp)": 17461.48474631725,
    "out:PV (kWp installed)": 90.5097,
    "out:PV (m2 panels)": 474.867263,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 84922.568824,
    "out:PV (kWh sold)": 71877.957046,
    "out:PV (% sold (El))": 84.639405,
    "out:PV (kWh self-consumed)": 13044.611778,
    "out:PV (ratio sold/self-consumed)": 5.510165,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 11,
    "out:Payback Time": 9.090909,
    "out:DH kWh savings": 113852.629645,
    "out:EL kWh savings": 21303.538998,
    "out:Sewage heat recovery cost": 273216,
    "out:Balancing hydronic system cost": 79599.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 264276.64,
    "out:DH kr savings": 113852.62964487918,
    "out:El kr savings": 42607.077996404216,
    "out:El kr sold": 107816.93556907076,
    "out:El kr saved": 26089.223556,
    "out:El kr return": 133906.15912507076,
    "out:% solar/total": 33,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 335776.831144,
    "out:% savings (space heating)": 17.52836,
    "out:Total_Water Heating (DH)": 90285.476835,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 105.46,
    "out:Etvv": 17,
    "out:Ef": 5.88,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 2461302.132967325,
    "out:Seasonal Variation ROI (%)": 10,
    "out:Seasonal Variation Payback": 10,
    "out:New AHU Cost/SEK": 146792.87151982498,
    "out:DH CO2": 48.237856,
    "out:EL CO2": -114.042364,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -114.819396,
    "out:Bought CO2": 0.777032,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00001001000000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 67.236256,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 40.523257,
    "out:Electricity (inc PV)": 40.523257,
    "out:Total Energy Use Pre PV": 107.523257,
    "out:Total Energy Use Post PV": 107.523257,
    "out:Primary Energy": 117.260046,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 5.908825,
    "out:CO2 Operational/m2": 29.468322,
    "out:Total CO2/m2": 35.377147,
    "out:Total CO2 (tons)": 112.640802,
    "out:Klimatpaverkan": -87.494619,
    "out:Initial Cost/MSEK": 3.241461264077742,
    "out:Running cost/(Apt SEK y)": 19197.914894,
    "out:Running Cost over RSP/MSEK": 7.0341,
    "out:LCP/MSEK": 3.129159,
    "out:ROI% old": 28.076491,
    "out:Payback discounted": 5,
    "out:Atemp": 3184,
    "out:Umean": 0.82,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 108,
    "out:Energy use kWh/m2": 108,
    "out:Energy savings %": 69.4,
    "out:Op energy cost/MSEK": 50,
    "out:El price/MSEK": 35,
    "out:Heating price/MSEK": 13,
    "out:DH+ DHW/kWh/m2": 67,
    "out:El bought/kWh/m2": 2836.62799,
    "out:Return %": 25,
    "out:Return/kSEK/y": 812,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.6502792112,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 41.7,
    "out:EAHP (El)": 25.653757,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 325834.762933,
    "out:EL kWh savings": -89003.143509,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 147828.48,
    "out:DH kr savings": 325834.76293260354,
    "out:El kr savings": -178006.28701755957,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 214080.174692,
    "out:% savings (space heating)": 47.418817,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 997124.4110739925,
    "out:Eupp": 56.98,
    "out:Etvv": 0,
    "out:Ef": 38.57,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 3241461.264077742,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 24.118928,
    "out:EL CO2": 5.349394,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 5.349394,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00001001000000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 62.70657,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 40.460715,
    "out:Electricity (inc PV)": 40.460715,
    "out:Total Energy Use Pre PV": 103.460715,
    "out:Total Energy Use Post PV": 103.460715,
    "out:Primary Energy": 113.718209,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 5.908825,
    "out:CO2 Operational/m2": 28.02013,
    "out:Total CO2/m2": 33.928955,
    "out:Total CO2 (tons)": 108.02976,
    "out:Klimatpaverkan": -92.105661,
    "out:Initial Cost/MSEK": 3.3210612390777423,
    "out:Running cost/(Apt SEK y)": 18371.085106,
    "out:Running Cost over RSP/MSEK": 6.73018,
    "out:LCP/MSEK": 3.353479,
    "out:ROI% old": 28.710874,
    "out:Payback discounted": 4,
    "out:Atemp": 3184,
    "out:Umean": 0.82,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 103,
    "out:Energy use kWh/m2": 103,
    "out:Energy savings %": 77.7,
    "out:Op energy cost/MSEK": 48,
    "out:El price/MSEK": 34,
    "out:Heating price/MSEK": 12,
    "out:DH+ DHW/kWh/m2": 63,
    "out:El bought/kWh/m2": 2832.25005,
    "out:Return %": 26,
    "out:Return/kSEK/y": 851,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.6502792112,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 41.7,
    "out:EAHP (El)": 25.591215,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 340257.278817,
    "out:EL kWh savings": -88804.009841,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 79599.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 162649.26,
    "out:DH kr savings": 340257.27881716436,
    "out:El kr savings": -177608.01968139,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 199657.658807,
    "out:% savings (space heating)": 50.961195,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 997124.4110739925,
    "out:Eupp": 52.57,
    "out:Etvv": 0,
    "out:Ef": 38.47,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 3321061.239077742,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 22.678992,
    "out:EL CO2": 5.341138,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 5.341138,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00001001000001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 63.316172,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 39.815707,
    "out:Electricity (inc PV)": 39.815707,
    "out:Total Energy Use Pre PV": 102.815707,
    "out:Total Energy Use Post PV": 102.815707,
    "out:Primary Energy": 114.272792,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 6.617368,
    "out:CO2 Operational/m2": 27.934984,
    "out:Total CO2/m2": 34.552352,
    "out:Total CO2 (tons)": 110.014656,
    "out:Klimatpaverkan": -90.120765,
    "out:Initial Cost/MSEK": 3.5146772640777426,
    "out:Running cost/(Apt SEK y)": 18283.702128,
    "out:Running Cost over RSP/MSEK": 6.69842,
    "out:LCP/MSEK": 3.191623,
    "out:ROI% old": 27.258345,
    "out:Payback discounted": 5,
    "out:Atemp": 3184,
    "out:Umean": 0.82,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 103,
    "out:Energy use kWh/m2": 103,
    "out:Energy savings %": 77.7,
    "out:Op energy cost/MSEK": 48,
    "out:El price/MSEK": 33,
    "out:Heating price/MSEK": 12,
    "out:DH+ DHW/kWh/m2": 63,
    "out:El bought/kWh/m2": 2787.09949,
    "out:Return %": 24,
    "out:Return/kSEK/y": 855,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.6502792112,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 35.445,
    "out:EAHP (El)": 24.946207,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 338316.306633,
    "out:EL kWh savings": -86750.304987,
    "out:Sewage heat recovery cost": 273216,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 164815.7,
    "out:DH kr savings": 338316.30663316307,
    "out:El kr savings": -173500.6099732254,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 201598.630991,
    "out:% savings (space heating)": 50.484464,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 997124.4110739925,
    "out:Eupp": 54.78,
    "out:Etvv": 0,
    "out:Ef": 38.1,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 3514677.2640777426,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 22.678992,
    "out:EL CO2": 5.255992,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 5.255992,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00001001000001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 58.826312,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 39.741096,
    "out:Electricity (inc PV)": 39.741096,
    "out:Total Energy Use Pre PV": 98.741096,
    "out:Total Energy Use Post PV": 98.741096,
    "out:Primary Energy": 110.733663,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 6.617368,
    "out:CO2 Operational/m2": 26.485199,
    "out:Total CO2/m2": 33.102567,
    "out:Total CO2 (tons)": 105.398542,
    "out:Klimatpaverkan": -94.736879,
    "out:Initial Cost/MSEK": 3.594277239077743,
    "out:Running cost/(Apt SEK y)": 17455.234043,
    "out:Running Cost over RSP/MSEK": 6.39391,
    "out:LCP/MSEK": 3.416533,
    "out:ROI% old": 27.86497,
    "out:Payback discounted": 5,
    "out:Atemp": 3184,
    "out:Umean": 0.82,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 99,
    "out:Energy use kWh/m2": 99,
    "out:Energy savings %": 84.8,
    "out:Op energy cost/MSEK": 46,
    "out:El price/MSEK": 32,
    "out:Heating price/MSEK": 11,
    "out:DH+ DHW/kWh/m2": 59,
    "out:El bought/kWh/m2": 2781.87672,
    "out:Return %": 25,
    "out:Return/kSEK/y": 894,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.6502792112,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 35.445,
    "out:EAHP (El)": 24.871596,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 352612.016572,
    "out:EL kWh savings": -86512.743634,
    "out:Sewage heat recovery cost": 273216,
    "out:Balancing hydronic system cost": 79599.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 179586.53,
    "out:DH kr savings": 352612.0165718772,
    "out:El kr savings": -173025.48726818006,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 187302.921052,
    "out:% savings (space heating)": 53.995697,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 997124.4110739925,
    "out:Eupp": 50.4,
    "out:Etvv": 0,
    "out:Ef": 37.99,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 3594277.2390777427,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 21.239056,
    "out:EL CO2": 5.246143,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 5.246143,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00001001010000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 67.236256,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 40.523257,
    "out:Electricity (inc PV)": 30.4685,
    "out:Total Energy Use Pre PV": 107.523257,
    "out:Total Energy Use Post PV": 97.4685,
    "out:Primary Energy": 99.161483,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 20.647925,
    "out:CO2 Operational/m2": 11.87834,
    "out:Total CO2/m2": 32.526265,
    "out:Total CO2 (tons)": 103.563597,
    "out:Klimatpaverkan": -96.571824,
    "out:Initial Cost/MSEK": 4.031681658300243,
    "out:Running cost/(Apt SEK y)": 17502.191489,
    "out:Running Cost over RSP/MSEK": 6.42737,
    "out:LCP/MSEK": 2.945668,
    "out:ROI% old": 24.723288,
    "out:Payback discounted": 5,
    "out:Atemp": 3184,
    "out:Umean": 0.82,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 97,
    "out:Energy use kWh/m2": 97,
    "out:Energy savings %": 88.7,
    "out:Op energy cost/MSEK": 47,
    "out:El price/MSEK": 31,
    "out:Heating price/MSEK": 13,
    "out:DH+ DHW/kWh/m2": 67,
    "out:El bought/kWh/m2": 2154.608619,
    "out:Return %": 22,
    "out:Return/kSEK/y": 892,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.6502792112,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 41.7,
    "out:EAHP (El)": 25.653757,
    "out:PV (kr/kWp)": 17461.56255567083,
    "out:PV (kWp installed)": 45.25485,
    "out:PV (m2 panels)": 237.433632,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 42461.284501,
    "out:PV (kWh sold)": 10446.946353,
    "out:PV (% sold (El))": 24.603463,
    "out:PV (kWh self-consumed)": 32014.338148,
    "out:PV (ratio sold/self-consumed)": 0.326321,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 325834.762933,
    "out:EL kWh savings": -56988.806853,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 227527.57,
    "out:DH kr savings": 325834.76293260354,
    "out:El kr savings": -113977.61370647403,
    "out:El kr sold": 15670.419530062798,
    "out:El kr saved": 64028.676296,
    "out:El kr return": 79699.0958260628,
    "out:% solar/total": 80,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 214080.174692,
    "out:% savings (space heating)": 47.418817,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 997124.4110739925,
    "out:Eupp": 56.98,
    "out:Etvv": 0,
    "out:Ef": 28.79,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 4031681.6583002424,
    "out:Seasonal Variation ROI (%)": 5,
    "out:Seasonal Variation Payback": 20,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 24.118928,
    "out:EL CO2": -12.240588,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -16.272437,
    "out:Bought CO2": 4.031849,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00001001010000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 62.70657,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 40.460715,
    "out:Electricity (inc PV)": 30.414509,
    "out:Total Energy Use Pre PV": 103.460715,
    "out:Total Energy Use Post PV": 93.414509,
    "out:Primary Energy": 95.635038,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 20.647925,
    "out:CO2 Operational/m2": 10.387679,
    "out:Total CO2/m2": 31.035604,
    "out:Total CO2 (tons)": 98.817333,
    "out:Klimatpaverkan": -101.318088,
    "out:Initial Cost/MSEK": 4.111281633300242,
    "out:Running cost/(Apt SEK y)": 16675.659574,
    "out:Running Cost over RSP/MSEK": 6.12355,
    "out:LCP/MSEK": 3.169888,
    "out:ROI% old": 25.300314,
    "out:Payback discounted": 5,
    "out:Atemp": 3184,
    "out:Umean": 0.82,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 93,
    "out:Energy use kWh/m2": 93,
    "out:Energy savings %": 96.8,
    "out:Op energy cost/MSEK": 44,
    "out:El price/MSEK": 30,
    "out:Heating price/MSEK": 12,
    "out:DH+ DHW/kWh/m2": 63,
    "out:El bought/kWh/m2": 2150.788122,
    "out:Return %": 23,
    "out:Return/kSEK/y": 931,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.6502792112,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 41.7,
    "out:EAHP (El)": 25.591215,
    "out:PV (kr/kWp)": 17461.56255567083,
    "out:PV (kWp installed)": 45.25485,
    "out:PV (m2 panels)": 237.433632,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 42461.284501,
    "out:PV (kWh sold)": 10474.174479,
    "out:PV (% sold (El))": 24.667587,
    "out:PV (kWh self-consumed)": 31987.110022,
    "out:PV (ratio sold/self-consumed)": 0.32745,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 340257.278817,
    "out:EL kWh savings": -56816.899561,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 79599.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 242334.74,
    "out:DH kr savings": 340257.27881716436,
    "out:El kr savings": -113633.7991219208,
    "out:El kr sold": 15711.261718957598,
    "out:El kr saved": 63974.220044,
    "out:El kr return": 79685.4817629576,
    "out:% solar/total": 80,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 199657.658807,
    "out:% savings (space heating)": 50.961195,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 997124.4110739925,
    "out:Eupp": 52.57,
    "out:Etvv": 0,
    "out:Ef": 28.71,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 4111281.6333002425,
    "out:Seasonal Variation ROI (%)": 5,
    "out:Seasonal Variation Payback": 20,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 22.678992,
    "out:EL CO2": -12.291313,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -16.316015,
    "out:Bought CO2": 4.024702,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00001001010001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 63.316172,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 39.815707,
    "out:Electricity (inc PV)": 29.858678,
    "out:Total Energy Use Pre PV": 102.815707,
    "out:Total Energy Use Post PV": 92.858678,
    "out:Primary Energy": 96.350139,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 21.356468,
    "out:CO2 Operational/m2": 9.860298,
    "out:Total CO2/m2": 31.216766,
    "out:Total CO2 (tons)": 99.394153,
    "out:Klimatpaverkan": -100.741268,
    "out:Initial Cost/MSEK": 4.304897658300242,
    "out:Running cost/(Apt SEK y)": 16591.276596,
    "out:Running Cost over RSP/MSEK": 6.09287,
    "out:LCP/MSEK": 3.006952,
    "out:ROI% old": 24.264224,
    "out:Payback discounted": 5,
    "out:Atemp": 3184,
    "out:Umean": 0.82,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 93,
    "out:Energy use kWh/m2": 93,
    "out:Energy savings %": 96.8,
    "out:Op energy cost/MSEK": 44,
    "out:El price/MSEK": 30,
    "out:Heating price/MSEK": 12,
    "out:DH+ DHW/kWh/m2": 63,
    "out:El bought/kWh/m2": 2111.441066,
    "out:Return %": 22,
    "out:Return/kSEK/y": 935,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.6502792112,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 35.445,
    "out:EAHP (El)": 24.946207,
    "out:PV (kr/kWp)": 17461.56255567083,
    "out:PV (kWp installed)": 45.25485,
    "out:PV (m2 panels)": 237.433632,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 42461.284501,
    "out:PV (kWh sold)": 10758.112832,
    "out:PV (% sold (El))": 25.336287,
    "out:PV (kWh self-consumed)": 31703.17167,
    "out:PV (ratio sold/self-consumed)": 0.339339,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 338316.306633,
    "out:EL kWh savings": -55047.134189,
    "out:Sewage heat recovery cost": 273216,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 244359.21,
    "out:DH kr savings": 338316.30663316307,
    "out:El kr savings": -110094.26837889293,
    "out:El kr sold": 16137.16924747681,
    "out:El kr saved": 63406.34334,
    "out:El kr return": 79543.51258747681,
    "out:% solar/total": 79,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 201598.630991,
    "out:% savings (space heating)": 50.484464,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 997124.4110739925,
    "out:Eupp": 54.78,
    "out:Etvv": 0,
    "out:Ef": 28.39,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 4304897.658300242,
    "out:Seasonal Variation ROI (%)": 5,
    "out:Seasonal Variation Payback": 20,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 22.678992,
    "out:EL CO2": -12.818694,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -16.769808,
    "out:Bought CO2": 3.951114,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00001001010001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 58.826312,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 39.741096,
    "out:Electricity (inc PV)": 29.794483,
    "out:Total Energy Use Pre PV": 98.741096,
    "out:Total Energy Use Post PV": 88.794483,
    "out:Primary Energy": 92.829759,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 21.356468,
    "out:CO2 Operational/m2": 8.358836,
    "out:Total CO2/m2": 29.715304,
    "out:Total CO2 (tons)": 94.613499,
    "out:Klimatpaverkan": -105.521922,
    "out:Initial Cost/MSEK": 4.384497633300242,
    "out:Running cost/(Apt SEK y)": 15763.170213,
    "out:Running Cost over RSP/MSEK": 5.78847,
    "out:LCP/MSEK": 3.231752,
    "out:ROI% old": 24.815516,
    "out:Payback discounted": 5,
    "out:Atemp": 3184,
    "out:Umean": 0.82,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 89,
    "out:Energy use kWh/m2": 89,
    "out:Energy savings %": 105.6,
    "out:Op energy cost/MSEK": 42,
    "out:El price/MSEK": 28,
    "out:Heating price/MSEK": 11,
    "out:DH+ DHW/kWh/m2": 59,
    "out:El bought/kWh/m2": 2106.896173,
    "out:Return %": 22,
    "out:Return/kSEK/y": 974,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.6502792112,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 35.445,
    "out:EAHP (El)": 24.871596,
    "out:PV (kr/kWp)": 17461.56255567083,
    "out:PV (kWp installed)": 45.25485,
    "out:PV (m2 panels)": 237.433632,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 42461.284501,
    "out:PV (kWh sold)": 10791.278054,
    "out:PV (% sold (El))": 25.414394,
    "out:PV (kWh self-consumed)": 31670.006448,
    "out:PV (ratio sold/self-consumed)": 0.340741,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 352612.016572,
    "out:EL kWh savings": -54842.737371,
    "out:Sewage heat recovery cost": 273216,
    "out:Balancing hydronic system cost": 79599.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 259113.46,
    "out:DH kr savings": 352612.0165718772,
    "out:El kr savings": -109685.47474189047,
    "out:El kr sold": 16186.91708026382,
    "out:El kr saved": 63340.012896,
    "out:El kr return": 79526.92997626383,
    "out:% solar/total": 79,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 187302.921052,
    "out:% savings (space heating)": 53.995697,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 997124.4110739925,
    "out:Eupp": 50.4,
    "out:Etvv": 0,
    "out:Ef": 28.3,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 4384497.633300242,
    "out:Seasonal Variation ROI (%)": 5,
    "out:Seasonal Variation Payback": 20,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 21.239056,
    "out:EL CO2": -12.88022,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -16.822836,
    "out:Bought CO2": 3.942616,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00001001020000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 67.236256,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 40.523257,
    "out:Electricity (inc PV)": 27.587736,
    "out:Total Energy Use Pre PV": 107.523257,
    "out:Total Energy Use Post PV": 94.587736,
    "out:Primary Energy": 93.976108,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 35.387024,
    "out:CO2 Operational/m2": -41.520953,
    "out:Total CO2/m2": -6.133929,
    "out:Total CO2 (tons)": -19.530424,
    "out:Klimatpaverkan": -219.665845,
    "out:Initial Cost/MSEK": 4.821895010021493,
    "out:Running cost/(Apt SEK y)": 16049.468085,
    "out:Running Cost over RSP/MSEK": 5.90781,
    "out:LCP/MSEK": 2.675015,
    "out:ROI% old": 22.210918,
    "out:Payback discounted": 6,
    "out:Atemp": 3184,
    "out:Umean": 0.82,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 95,
    "out:Energy use kWh/m2": 95,
    "out:Energy savings %": 92.6,
    "out:Op energy cost/MSEK": 43,
    "out:El price/MSEK": 28,
    "out:Heating price/MSEK": 13,
    "out:DH+ DHW/kWh/m2": 67,
    "out:El bought/kWh/m2": 1949.749361,
    "out:Return %": 20,
    "out:Return/kSEK/y": 960,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.6502792112,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 41.7,
    "out:EAHP (El)": 25.653757,
    "out:PV (kr/kWp)": 17461.48474631725,
    "out:PV (kWp installed)": 90.5097,
    "out:PV (m2 panels)": 474.867263,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 84922.568824,
    "out:PV (kWh sold)": 43735.88136,
    "out:PV (% sold (El))": 51.500893,
    "out:PV (kWh self-consumed)": 41186.687464,
    "out:PV (ratio sold/self-consumed)": 1.061894,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 325834.762933,
    "out:EL kWh savings": -47816.457037,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 295805.67,
    "out:DH kr savings": 325834.76293260354,
    "out:El kr savings": -95632.91407401786,
    "out:El kr sold": 65603.82204047372,
    "out:El kr saved": 82373.374928,
    "out:El kr return": 147977.19696847373,
    "out:% solar/total": 103,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 214080.174692,
    "out:% savings (space heating)": 47.418817,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 997124.4110739925,
    "out:Eupp": 56.98,
    "out:Etvv": 0,
    "out:Ef": 26.08,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 4821895.010021493,
    "out:Seasonal Variation ROI (%)": 6,
    "out:Seasonal Variation Payback": 16.666667,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 24.118928,
    "out:EL CO2": -65.639881,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -69.289981,
    "out:Bought CO2": 3.6501,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00001001020000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 62.70657,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 40.460715,
    "out:Electricity (inc PV)": 27.539308,
    "out:Total Energy Use Pre PV": 103.460715,
    "out:Total Energy Use Post PV": 90.539308,
    "out:Primary Energy": 90.459676,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 35.387024,
    "out:CO2 Operational/m2": -43.039691,
    "out:Total CO2/m2": -7.652667,
    "out:Total CO2 (tons)": -24.366084,
    "out:Klimatpaverkan": -224.501505,
    "out:Initial Cost/MSEK": 4.901494985021492,
    "out:Running cost/(Apt SEK y)": 15223.12766,
    "out:Running Cost over RSP/MSEK": 5.60405,
    "out:LCP/MSEK": 2.899175,
    "out:ROI% old": 22.735542,
    "out:Payback discounted": 5,
    "out:Atemp": 3184,
    "out:Umean": 0.82,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 91,
    "out:Energy use kWh/m2": 91,
    "out:Energy savings %": 101.1,
    "out:Op energy cost/MSEK": 41,
    "out:El price/MSEK": 27,
    "out:Heating price/MSEK": 12,
    "out:DH+ DHW/kWh/m2": 63,
    "out:El bought/kWh/m2": 1946.319354,
    "out:Return %": 20,
    "out:Return/kSEK/y": 999,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.6502792112,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 41.7,
    "out:EAHP (El)": 25.591215,
    "out:PV (kr/kWp)": 17461.48474631725,
    "out:PV (kWp installed)": 90.5097,
    "out:PV (m2 panels)": 474.867263,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 84922.568824,
    "out:PV (kWh sold)": 43780.822844,
    "out:PV (% sold (El))": 51.553814,
    "out:PV (kWh self-consumed)": 41141.74598,
    "out:PV (ratio sold/self-consumed)": 1.064146,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 340257.278817,
    "out:EL kWh savings": -47662.262331,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 79599.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 310603.99,
    "out:DH kr savings": 340257.27881716436,
    "out:El kr savings": -95324.5246628059,
    "out:El kr sold": 65671.23426667444,
    "out:El kr saved": 82283.49196,
    "out:El kr return": 147954.72622667445,
    "out:% solar/total": 103,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 199657.658807,
    "out:% savings (space heating)": 50.961195,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 997124.4110739925,
    "out:Eupp": 52.57,
    "out:Etvv": 0,
    "out:Ef": 26.01,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 4901494.9850214925,
    "out:Seasonal Variation ROI (%)": 6,
    "out:Seasonal Variation Payback": 16.666667,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 22.678992,
    "out:EL CO2": -65.718683,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -69.362371,
    "out:Bought CO2": 3.643688,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00001001020001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 63.316172,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 39.815707,
    "out:Electricity (inc PV)": 27.040813,
    "out:Total Energy Use Pre PV": 102.815707,
    "out:Total Energy Use Post PV": 90.040813,
    "out:Primary Energy": 91.277982,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 36.095567,
    "out:CO2 Operational/m2": -43.856762,
    "out:Total CO2/m2": -7.761195,
    "out:Total CO2 (tons)": -24.711637,
    "out:Klimatpaverkan": -224.847058,
    "out:Initial Cost/MSEK": 5.095111010021492,
    "out:Running cost/(Apt SEK y)": 15140.702128,
    "out:Running Cost over RSP/MSEK": 5.57408,
    "out:LCP/MSEK": 2.735529,
    "out:ROI% old": 21.955613,
    "out:Payback discounted": 6,
    "out:Atemp": 3184,
    "out:Umean": 0.82,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 90,
    "out:Energy use kWh/m2": 90,
    "out:Energy savings %": 103.3,
    "out:Op energy cost/MSEK": 41,
    "out:El price/MSEK": 27,
    "out:Heating price/MSEK": 12,
    "out:DH+ DHW/kWh/m2": 63,
    "out:El bought/kWh/m2": 1911.000618,
    "out:Return %": 20,
    "out:Return/kSEK/y": 1003,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.6502792112,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 35.445,
    "out:EAHP (El)": 24.946207,
    "out:PV (kr/kWp)": 17461.48474631725,
    "out:PV (kWp installed)": 90.5097,
    "out:PV (m2 panels)": 474.867263,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 84922.568824,
    "out:PV (kWh sold)": 44247.320045,
    "out:PV (% sold (El))": 52.103134,
    "out:PV (kWh self-consumed)": 40675.248779,
    "out:PV (ratio sold/self-consumed)": 1.087819,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 338316.306633,
    "out:EL kWh savings": -46075.054729,
    "out:Sewage heat recovery cost": 273216,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 312537.18,
    "out:DH kr savings": 338316.30663316307,
    "out:El kr savings": -92150.1094579222,
    "out:El kr sold": 66370.98006690374,
    "out:El kr saved": 81350.497558,
    "out:El kr return": 147721.47762490372,
    "out:% solar/total": 102,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 201598.630991,
    "out:% savings (space heating)": 50.484464,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 997124.4110739925,
    "out:Eupp": 54.78,
    "out:Etvv": 0,
    "out:Ef": 25.72,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 5095111.010021492,
    "out:Seasonal Variation ROI (%)": 6,
    "out:Seasonal Variation Payback": 16.666667,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 22.678992,
    "out:EL CO2": -66.535754,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -70.113423,
    "out:Bought CO2": 3.577669,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00001001020001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 58.826312,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 39.741096,
    "out:Electricity (inc PV)": 26.983237,
    "out:Total Energy Use Pre PV": 98.741096,
    "out:Total Energy Use Post PV": 85.983237,
    "out:Primary Energy": 87.769517,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 36.095567,
    "out:CO2 Operational/m2": -45.39171,
    "out:Total CO2/m2": -9.296143,
    "out:Total CO2 (tons)": -29.59891,
    "out:Klimatpaverkan": -229.734331,
    "out:Initial Cost/MSEK": 5.174710985021492,
    "out:Running cost/(Apt SEK y)": 14312.808511,
    "out:Running Cost over RSP/MSEK": 5.26977,
    "out:LCP/MSEK": 2.960239,
    "out:ROI% old": 22.457983,
    "out:Payback discounted": 6,
    "out:Atemp": 3184,
    "out:Umean": 0.82,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 86,
    "out:Energy use kWh/m2": 86,
    "out:Energy savings %": 112.8,
    "out:Op energy cost/MSEK": 39,
    "out:El price/MSEK": 25,
    "out:Heating price/MSEK": 11,
    "out:DH+ DHW/kWh/m2": 59,
    "out:El bought/kWh/m2": 1906.921706,
    "out:Return %": 20,
    "out:Return/kSEK/y": 1042,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.6502792112,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 35.445,
    "out:EAHP (El)": 24.871596,
    "out:PV (kr/kWp)": 17461.48474631725,
    "out:PV (kWp installed)": 90.5097,
    "out:PV (m2 panels)": 474.867263,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 84922.568824,
    "out:PV (kWh sold)": 44301.558068,
    "out:PV (% sold (El))": 52.167002,
    "out:PV (kWh self-consumed)": 40621.010756,
    "out:PV (ratio sold/self-consumed)": 1.090607,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 352612.016572,
    "out:EL kWh savings": -45891.7328,
    "out:Sewage heat recovery cost": 273216,
    "out:Balancing hydronic system cost": 79599.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 327280.89,
    "out:DH kr savings": 352612.0165718772,
    "out:El kr savings": -91783.46560023792,
    "out:El kr sold": 66452.33710215926,
    "out:El kr saved": 81242.021512,
    "out:El kr return": 147694.35861415928,
    "out:% solar/total": 101,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 187302.921052,
    "out:% savings (space heating)": 53.995697,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 997124.4110739925,
    "out:Eupp": 50.4,
    "out:Etvv": 0,
    "out:Ef": 25.64,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 5174710.9850214925,
    "out:Seasonal Variation ROI (%)": 6,
    "out:Seasonal Variation Payback": 16.666667,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 21.239056,
    "out:EL CO2": -66.630766,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -70.200811,
    "out:Bought CO2": 3.570045,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00001001100000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 62.031128,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 39.573296,
    "out:Electricity (inc PV)": 39.573296,
    "out:Total Energy Use Pre PV": 101.573296,
    "out:Total Energy Use Post PV": 101.573296,
    "out:Primary Energy": 113.271495,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 5.908825,
    "out:CO2 Operational/m2": 27.543,
    "out:Total CO2/m2": 33.451825,
    "out:Total CO2 (tons)": 106.510579,
    "out:Klimatpaverkan": -93.624842,
    "out:Initial Cost/MSEK": 3.365508951577742,
    "out:Running cost/(Apt SEK y)": 18046.276596,
    "out:Running Cost over RSP/MSEK": 6.61128,
    "out:LCP/MSEK": 3.427931,
    "out:ROI% old": 28.836394,
    "out:Payback discounted": 4,
    "out:Atemp": 3184,
    "out:Umean": 0.82,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 102,
    "out:Energy use kWh/m2": 102,
    "out:Energy savings %": 79.4,
    "out:Op energy cost/MSEK": 47,
    "out:El price/MSEK": 33,
    "out:Heating price/MSEK": 12,
    "out:DH+ DHW/kWh/m2": 62,
    "out:El bought/kWh/m2": 2770.13072,
    "out:Return %": 26,
    "out:Return/kSEK/y": 867,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.6502792112,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 33.36,
    "out:EAHP (El)": 24.703796,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 342407.885498,
    "out:EL kWh savings": -85978.468595,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 170450.95,
    "out:DH kr savings": 342407.88549809094,
    "out:El kr savings": -171956.93718968486,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 197507.052126,
    "out:% savings (space heating)": 51.489415,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 997124.4110739925,
    "out:Eupp": 54.05,
    "out:Etvv": 0,
    "out:Ef": 37.94,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 3365508.951577742,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 22.319008,
    "out:EL CO2": 5.223992,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 5.223992,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00001001100000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 57.553888,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 39.494861,
    "out:Electricity (inc PV)": 39.494861,
    "out:Total Energy Use Pre PV": 97.494861,
    "out:Total Energy Use Post PV": 97.494861,
    "out:Primary Energy": 109.734597,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 5.908825,
    "out:CO2 Operational/m2": 26.09271,
    "out:Total CO2/m2": 32.001535,
    "out:Total CO2 (tons)": 101.892857,
    "out:Klimatpaverkan": -98.242564,
    "out:Initial Cost/MSEK": 3.4451089265777424,
    "out:Running cost/(Apt SEK y)": 17217.276596,
    "out:Running Cost over RSP/MSEK": 6.30657,
    "out:LCP/MSEK": 3.653041,
    "out:ROI% old": 29.433654,
    "out:Payback discounted": 4,
    "out:Atemp": 3184,
    "out:Umean": 0.82,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 97,
    "out:Energy use kWh/m2": 97,
    "out:Energy savings %": 88.7,
    "out:Op energy cost/MSEK": 45,
    "out:El price/MSEK": 32,
    "out:Heating price/MSEK": 11,
    "out:DH+ DHW/kWh/m2": 58,
    "out:El bought/kWh/m2": 2764.64027,
    "out:Return %": 26,
    "out:Return/kSEK/y": 905,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.6502792112,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 33.36,
    "out:EAHP (El)": 24.625361,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 356663.413369,
    "out:EL kWh savings": -85728.73163,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 79599.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 185205.95,
    "out:DH kr savings": 356663.41336889495,
    "out:El kr savings": -171457.46325996632,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 183251.524255,
    "out:% savings (space heating)": 54.990779,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 997124.4110739925,
    "out:Eupp": 49.68,
    "out:Etvv": 0,
    "out:Ef": 37.83,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 3445108.926577742,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 20.879072,
    "out:EL CO2": 5.213638,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 5.213638,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00001001100001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 58.986784,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 38.979461,
    "out:Electricity (inc PV)": 38.979461,
    "out:Total Energy Use Pre PV": 97.979461,
    "out:Total Energy Use Post PV": 97.979461,
    "out:Primary Energy": 110.859285,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 6.617368,
    "out:CO2 Operational/m2": 26.384657,
    "out:Total CO2/m2": 33.002025,
    "out:Total CO2 (tons)": 105.078416,
    "out:Klimatpaverkan": -95.057005,
    "out:Initial Cost/MSEK": 3.638724951577742,
    "out:Running cost/(Apt SEK y)": 17352.042553,
    "out:Running Cost over RSP/MSEK": 6.35641,
    "out:LCP/MSEK": 3.409585,
    "out:ROI% old": 27.67182,
    "out:Payback discounted": 5,
    "out:Atemp": 3184,
    "out:Umean": 0.82,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 98,
    "out:Energy use kWh/m2": 98,
    "out:Energy savings %": 86.7,
    "out:Op energy cost/MSEK": 45,
    "out:El price/MSEK": 32,
    "out:Heating price/MSEK": 11,
    "out:DH+ DHW/kWh/m2": 59,
    "out:El bought/kWh/m2": 2728.56227,
    "out:Return %": 25,
    "out:Return/kSEK/y": 899,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.6502792112,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 28.356,
    "out:EAHP (El)": 24.109961,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 352101.073878,
    "out:EL kWh savings": -84087.698524,
    "out:Sewage heat recovery cost": 273216,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 183925.68,
    "out:DH kr savings": 352101.07387760933,
    "out:El kr savings": -168175.3970474727,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 187813.863747,
    "out:% savings (space heating)": 53.870202,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 997124.4110739925,
    "out:Eupp": 52.32,
    "out:Etvv": 0,
    "out:Ef": 37.56,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 3638724.9515777417,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 21.239056,
    "out:EL CO2": 5.145601,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 5.145601,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00001001100001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 54.54649,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 38.88983,
    "out:Electricity (inc PV)": 38.88983,
    "out:Total Energy Use Pre PV": 93.88983,
    "out:Total Energy Use Post PV": 93.88983,
    "out:Primary Energy": 107.327015,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 6.617368,
    "out:CO2 Operational/m2": 24.932889,
    "out:Total CO2/m2": 31.550257,
    "out:Total CO2 (tons)": 100.455988,
    "out:Klimatpaverkan": -99.679433,
    "out:Initial Cost/MSEK": 3.7183249265777416,
    "out:Running cost/(Apt SEK y)": 16521.553191,
    "out:Running Cost over RSP/MSEK": 6.05115,
    "out:LCP/MSEK": 3.635245,
    "out:ROI% old": 28.252238,
    "out:Payback discounted": 4,
    "out:Atemp": 3184,
    "out:Umean": 0.82,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 94,
    "out:Energy use kWh/m2": 94,
    "out:Energy savings %": 94.7,
    "out:Op energy cost/MSEK": 43,
    "out:El price/MSEK": 31,
    "out:Heating price/MSEK": 11,
    "out:DH+ DHW/kWh/m2": 55,
    "out:El bought/kWh/m2": 2722.2881,
    "out:Return %": 25,
    "out:Return/kSEK/y": 938,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.6502792112,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 28.356,
    "out:EAHP (El)": 24.02033,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 366238.96572,
    "out:EL kWh savings": -83802.313506,
    "out:Sewage heat recovery cost": 273216,
    "out:Balancing hydronic system cost": 79599.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 198634.34,
    "out:DH kr savings": 366238.96571980766,
    "out:El kr savings": -167604.62701120574,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 173675.971904,
    "out:% savings (space heating)": 57.342673,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 997124.4110739925,
    "out:Eupp": 47.99,
    "out:Etvv": 0,
    "out:Ef": 37.44,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 3718324.9265777417,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 19.79912,
    "out:EL CO2": 5.133769,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 5.133769,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00001001110000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 62.031128,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 39.573296,
    "out:Electricity (inc PV)": 29.65021,
    "out:Total Energy Use Pre PV": 101.573296,
    "out:Total Energy Use Post PV": 91.65021,
    "out:Primary Energy": 95.40994,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 20.647925,
    "out:CO2 Operational/m2": 9.300001,
    "out:Total CO2/m2": 29.947926,
    "out:Total CO2 (tons)": 95.354168,
    "out:Klimatpaverkan": -104.781253,
    "out:Initial Cost/MSEK": 4.155729345800243,
    "out:Running cost/(Apt SEK y)": 16355,
    "out:Running Cost over RSP/MSEK": 6.00613,
    "out:LCP/MSEK": 3.242861,
    "out:ROI% old": 25.433356,
    "out:Payback discounted": 5,
    "out:Atemp": 3184,
    "out:Umean": 0.82,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 92,
    "out:Energy use kWh/m2": 92,
    "out:Energy savings %": 98.9,
    "out:Op energy cost/MSEK": 43,
    "out:El price/MSEK": 29,
    "out:Heating price/MSEK": 12,
    "out:DH+ DHW/kWh/m2": 62,
    "out:El bought/kWh/m2": 2096.679665,
    "out:Return %": 23,
    "out:Return/kSEK/y": 946,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.6502792112,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 33.36,
    "out:EAHP (El)": 24.703796,
    "out:PV (kr/kWp)": 17461.56255567083,
    "out:PV (kWp installed)": 45.25485,
    "out:PV (m2 panels)": 237.433632,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 42461.284501,
    "out:PV (kWh sold)": 10866.189144,
    "out:PV (% sold (El))": 25.590816,
    "out:PV (kWh self-consumed)": 31595.095357,
    "out:PV (ratio sold/self-consumed)": 0.34392,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 342407.885498,
    "out:EL kWh savings": -54383.372277,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 249940.42,
    "out:DH kr savings": 342407.88549809094,
    "out:El kr savings": -108766.74455431756,
    "out:El kr sold": 16299.283716485817,
    "out:El kr saved": 63190.190714,
    "out:El kr return": 79489.47443048582,
    "out:% solar/total": 79,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 197507.052126,
    "out:% savings (space heating)": 51.489415,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 997124.4110739925,
    "out:Eupp": 54.05,
    "out:Etvv": 0,
    "out:Ef": 28.26,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 4155729.345800243,
    "out:Seasonal Variation ROI (%)": 5,
    "out:Seasonal Variation Payback": 20,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 22.319008,
    "out:EL CO2": -13.019007,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -16.94252,
    "out:Bought CO2": 3.923513,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00001001110000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 57.553888,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 39.494861,
    "out:Electricity (inc PV)": 29.582814,
    "out:Total Energy Use Pre PV": 97.494861,
    "out:Total Energy Use Post PV": 87.582814,
    "out:Primary Energy": 91.892912,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 20.647925,
    "out:CO2 Operational/m2": 7.79499,
    "out:Total CO2/m2": 28.442915,
    "out:Total CO2 (tons)": 90.562214,
    "out:Klimatpaverkan": -109.573207,
    "out:Initial Cost/MSEK": 4.235329320800242,
    "out:Running cost/(Apt SEK y)": 15526.404255,
    "out:Running Cost over RSP/MSEK": 5.70156,
    "out:LCP/MSEK": 3.467831,
    "out:ROI% old": 25.982665,
    "out:Payback discounted": 5,
    "out:Atemp": 3184,
    "out:Umean": 0.82,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 88,
    "out:Energy use kWh/m2": 88,
    "out:Energy savings %": 108,
    "out:Op energy cost/MSEK": 41,
    "out:El price/MSEK": 28,
    "out:Heating price/MSEK": 11,
    "out:DH+ DHW/kWh/m2": 58,
    "out:El bought/kWh/m2": 2091.906638,
    "out:Return %": 23,
    "out:Return/kSEK/y": 985,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.6502792112,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 33.36,
    "out:EAHP (El)": 24.625361,
    "out:PV (kr/kWp)": 17461.56255567083,
    "out:PV (kWp installed)": 45.25485,
    "out:PV (m2 panels)": 237.433632,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 42461.284501,
    "out:PV (kWh sold)": 10901.334822,
    "out:PV (% sold (El))": 25.673587,
    "out:PV (kWh self-consumed)": 31559.949679,
    "out:PV (ratio sold/self-consumed)": 0.345417,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 356663.413369,
    "out:EL kWh savings": -54168.783478,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 79599.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 264677.85,
    "out:DH kr savings": 356663.41336889495,
    "out:El kr savings": -108337.56695544836,
    "out:El kr sold": 16352.002232964946,
    "out:El kr saved": 63119.899358,
    "out:El kr return": 79471.90159096495,
    "out:% solar/total": 79,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 183251.524255,
    "out:% savings (space heating)": 54.990779,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 997124.4110739925,
    "out:Eupp": 49.68,
    "out:Etvv": 0,
    "out:Ef": 28.16,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 4235329.320800242,
    "out:Seasonal Variation ROI (%)": 5,
    "out:Seasonal Variation Payback": 20,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 20.879072,
    "out:EL CO2": -13.084082,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -16.998671,
    "out:Bought CO2": 3.914589,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00001001110001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 58.986784,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 38.979461,
    "out:Electricity (inc PV)": 29.140505,
    "out:Total Energy Use Pre PV": 97.979461,
    "out:Total Energy Use Post PV": 88.140505,
    "out:Primary Energy": 93.149164,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 21.356468,
    "out:CO2 Operational/m2": 7.724399,
    "out:Total CO2/m2": 29.080867,
    "out:Total CO2 (tons)": 92.593453,
    "out:Klimatpaverkan": -107.541968,
    "out:Initial Cost/MSEK": 4.4289453458002415,
    "out:Running cost/(Apt SEK y)": 15663.638298,
    "out:Running Cost over RSP/MSEK": 5.75228,
    "out:LCP/MSEK": 3.223495,
    "out:ROI% old": 24.683206,
    "out:Payback discounted": 5,
    "out:Atemp": 3184,
    "out:Umean": 0.82,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 88,
    "out:Energy use kWh/m2": 88,
    "out:Energy savings %": 108,
    "out:Op energy cost/MSEK": 42,
    "out:El price/MSEK": 28,
    "out:Heating price/MSEK": 11,
    "out:DH+ DHW/kWh/m2": 59,
    "out:El bought/kWh/m2": 2060.580271,
    "out:Return %": 22,
    "out:Return/kSEK/y": 979,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.6502792112,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 28.356,
    "out:EAHP (El)": 24.109961,
    "out:PV (kr/kWp)": 17461.56255567083,
    "out:PV (kWp installed)": 45.25485,
    "out:PV (m2 panels)": 237.433632,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 42461.284501,
    "out:PV (kWh sold)": 11134.059301,
    "out:PV (% sold (El))": 26.221673,
    "out:PV (kWh self-consumed)": 31327.2252,
    "out:PV (ratio sold/self-consumed)": 0.355412,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 352101.073878,
    "out:EL kWh savings": -52760.472045,
    "out:Sewage heat recovery cost": 273216,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 263281.22,
    "out:DH kr savings": 352101.07387760933,
    "out:El kr savings": -105520.94409091244,
    "out:El kr sold": 16701.08895159728,
    "out:El kr saved": 62654.4504,
    "out:El kr return": 79355.53935159728,
    "out:% solar/total": 78,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 187813.863747,
    "out:% savings (space heating)": 53.870202,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 997124.4110739925,
    "out:Eupp": 52.32,
    "out:Etvv": 0,
    "out:Ef": 27.93,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 4428945.345800241,
    "out:Seasonal Variation ROI (%)": 5,
    "out:Seasonal Variation Payback": 20,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 21.239056,
    "out:EL CO2": -13.514657,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -17.370685,
    "out:Bought CO2": 3.856028,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00001001110001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 54.54649,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 38.88983,
    "out:Electricity (inc PV)": 29.063733,
    "out:Total Energy Use Pre PV": 93.88983,
    "out:Total Energy Use Post PV": 84.063733,
    "out:Primary Energy": 89.64004,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 21.356468,
    "out:CO2 Operational/m2": 6.208998,
    "out:Total CO2/m2": 27.565466,
    "out:Total CO2 (tons)": 87.768417,
    "out:Klimatpaverkan": -112.367004,
    "out:Initial Cost/MSEK": 4.508545320800242,
    "out:Running cost/(Apt SEK y)": 14833.574468,
    "out:Running Cost over RSP/MSEK": 5.44718,
    "out:LCP/MSEK": 3.448995,
    "out:ROI% old": 25.214151,
    "out:Payback discounted": 5,
    "out:Atemp": 3184,
    "out:Umean": 0.82,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 84,
    "out:Energy use kWh/m2": 84,
    "out:Energy savings %": 117.9,
    "out:Op energy cost/MSEK": 39,
    "out:El price/MSEK": 27,
    "out:Heating price/MSEK": 11,
    "out:DH+ DHW/kWh/m2": 55,
    "out:El bought/kWh/m2": 2055.139503,
    "out:Return %": 23,
    "out:Return/kSEK/y": 1018,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.6502792112,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 28.356,
    "out:EAHP (El)": 24.02033,
    "out:PV (kr/kWp)": 17461.56255567083,
    "out:PV (kWp installed)": 45.25485,
    "out:PV (m2 panels)": 237.433632,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 42461.284501,
    "out:PV (kWh sold)": 11175.001406,
    "out:PV (% sold (El))": 26.318096,
    "out:PV (kWh self-consumed)": 31286.283095,
    "out:PV (ratio sold/self-consumed)": 0.357185,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 366238.96572,
    "out:EL kWh savings": -52516.030071,
    "out:Sewage heat recovery cost": 273216,
    "out:Balancing hydronic system cost": 79599.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 277969.41,
    "out:DH kr savings": 366238.96571980766,
    "out:El kr savings": -105032.06014200757,
    "out:El kr sold": 16762.502109020905,
    "out:El kr saved": 62572.56619,
    "out:El kr return": 79335.0682990209,
    "out:% solar/total": 78,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 173675.971904,
    "out:% savings (space heating)": 57.342673,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 997124.4110739925,
    "out:Eupp": 47.99,
    "out:Etvv": 0,
    "out:Ef": 27.82,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 4508545.320800242,
    "out:Seasonal Variation ROI (%)": 5,
    "out:Seasonal Variation Payback": 20,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 19.79912,
    "out:EL CO2": -13.590122,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -17.435981,
    "out:Bought CO2": 3.845859,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00001001120000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 62.031128,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 39.573296,
    "out:Electricity (inc PV)": 26.853804,
    "out:Total Energy Use Pre PV": 101.573296,
    "out:Total Energy Use Post PV": 88.853804,
    "out:Primary Energy": 90.376409,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 35.387024,
    "out:CO2 Operational/m2": -44.525807,
    "out:Total CO2/m2": -9.138783,
    "out:Total CO2 (tons)": -29.097876,
    "out:Klimatpaverkan": -229.233297,
    "out:Initial Cost/MSEK": 4.945942697521493,
    "out:Running cost/(Apt SEK y)": 14905.12766,
    "out:Running Cost over RSP/MSEK": 5.48761,
    "out:LCP/MSEK": 2.971167,
    "out:ROI% old": 22.867546,
    "out:Payback discounted": 5,
    "out:Atemp": 3184,
    "out:Umean": 0.82,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 89,
    "out:Energy use kWh/m2": 89,
    "out:Energy savings %": 105.6,
    "out:Op energy cost/MSEK": 40,
    "out:El price/MSEK": 26,
    "out:Heating price/MSEK": 12,
    "out:DH+ DHW/kWh/m2": 62,
    "out:El bought/kWh/m2": 1897.752983,
    "out:Return %": 21,
    "out:Return/kSEK/y": 1014,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.6502792112,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 33.36,
    "out:EAHP (El)": 24.703796,
    "out:PV (kr/kWp)": 17461.48474631725,
    "out:PV (kWp installed)": 90.5097,
    "out:PV (m2 panels)": 474.867263,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 84922.568824,
    "out:PV (kWh sold)": 44423.718033,
    "out:PV (% sold (El))": 52.310851,
    "out:PV (kWh self-consumed)": 40498.850791,
    "out:PV (ratio sold/self-consumed)": 1.096913,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 342407.885498,
    "out:EL kWh savings": -45479.618252,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 318084.23,
    "out:DH kr savings": 342407.88549809094,
    "out:El kr savings": -90959.2365042315,
    "out:El kr sold": 66635.57704913415,
    "out:El kr saved": 80997.701582,
    "out:El kr return": 147633.27863113413,
    "out:% solar/total": 101,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 197507.052126,
    "out:% savings (space heating)": 51.489415,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 997124.4110739925,
    "out:Eupp": 54.05,
    "out:Etvv": 0,
    "out:Ef": 25.6,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 4945942.697521493,
    "out:Seasonal Variation ROI (%)": 6,
    "out:Seasonal Variation Payback": 16.666667,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 22.319008,
    "out:EL CO2": -66.844815,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -70.397724,
    "out:Bought CO2": 3.552909,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00001001120000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 57.553888,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 39.494861,
    "out:Electricity (inc PV)": 26.793364,
    "out:Total Energy Use Pre PV": 97.494861,
    "out:Total Energy Use Post PV": 84.793364,
    "out:Primary Energy": 86.871902,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 35.387024,
    "out:CO2 Operational/m2": -46.066008,
    "out:Total CO2/m2": -10.678984,
    "out:Total CO2 (tons)": -34.001875,
    "out:Klimatpaverkan": -234.137296,
    "out:Initial Cost/MSEK": 5.0255426725214924,
    "out:Running cost/(Apt SEK y)": 14076.765957,
    "out:Running Cost over RSP/MSEK": 5.18312,
    "out:LCP/MSEK": 3.196057,
    "out:ROI% old": 23.370895,
    "out:Payback discounted": 5,
    "out:Atemp": 3184,
    "out:Umean": 0.82,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 85,
    "out:Energy use kWh/m2": 85,
    "out:Energy savings %": 115.3,
    "out:Op energy cost/MSEK": 38,
    "out:El price/MSEK": 25,
    "out:Heating price/MSEK": 11,
    "out:DH+ DHW/kWh/m2": 58,
    "out:El bought/kWh/m2": 1893.469588,
    "out:Return %": 21,
    "out:Return/kSEK/y": 1053,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.6502792112,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 33.36,
    "out:EAHP (El)": 24.625361,
    "out:PV (kr/kWp)": 17461.48474631725,
    "out:PV (kWp installed)": 90.5097,
    "out:PV (m2 panels)": 474.867263,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 84922.568824,
    "out:PV (kWh sold)": 44481.01372,
    "out:PV (% sold (El))": 52.378319,
    "out:PV (kWh self-consumed)": 40441.555104,
    "out:PV (ratio sold/self-consumed)": 1.099884,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 7,
    "out:Payback Time": 14.285714,
    "out:DH kWh savings": 356663.413369,
    "out:EL kWh savings": -45287.17735,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 79599.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 332810.58,
    "out:DH kr savings": 356663.41336889495,
    "out:El kr savings": -90574.35470003456,
    "out:El kr sold": 66721.52057945672,
    "out:El kr saved": 80883.110208,
    "out:El kr return": 147604.6307874567,
    "out:% solar/total": 101,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 183251.524255,
    "out:% savings (space heating)": 54.990779,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 997124.4110739925,
    "out:Eupp": 49.68,
    "out:Etvv": 0,
    "out:Ef": 25.52,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 5025542.6725214925,
    "out:Seasonal Variation ROI (%)": 6,
    "out:Seasonal Variation Payback": 16.666667,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 20.879072,
    "out:EL CO2": -66.94508,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -70.489984,
    "out:Bought CO2": 3.544904,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00001001120001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 58.986784,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 38.979461,
    "out:Electricity (inc PV)": 26.396751,
    "out:Total Energy Use Pre PV": 97.979461,
    "out:Total Energy Use Post PV": 85.396751,
    "out:Primary Energy": 88.210407,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 36.095567,
    "out:CO2 Operational/m2": -46.367786,
    "out:Total CO2/m2": -10.272219,
    "out:Total CO2 (tons)": -32.706735,
    "out:Klimatpaverkan": -232.842157,
    "out:Initial Cost/MSEK": 5.219158697521492,
    "out:Running cost/(Apt SEK y)": 14215.553191,
    "out:Running Cost over RSP/MSEK": 5.23441,
    "out:LCP/MSEK": 2.951151,
    "out:ROI% old": 22.363511,
    "out:Payback discounted": 6,
    "out:Atemp": 3184,
    "out:Umean": 0.82,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 85,
    "out:Energy use kWh/m2": 85,
    "out:Energy savings %": 115.3,
    "out:Op energy cost/MSEK": 38,
    "out:El price/MSEK": 25,
    "out:Heating price/MSEK": 11,
    "out:DH+ DHW/kWh/m2": 59,
    "out:El bought/kWh/m2": 1865.359525,
    "out:Return %": 20,
    "out:Return/kSEK/y": 1047,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.6502792112,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 28.356,
    "out:EAHP (El)": 24.109961,
    "out:PV (kr/kWp)": 17461.48474631725,
    "out:PV (kWp installed)": 90.5097,
    "out:PV (m2 panels)": 474.867263,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 84922.568824,
    "out:PV (kWh sold)": 44859.232859,
    "out:PV (% sold (El))": 52.823688,
    "out:PV (kWh self-consumed)": 40063.335965,
    "out:PV (ratio sold/self-consumed)": 1.119708,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 352101.073878,
    "out:EL kWh savings": -44024.361938,
    "out:Sewage heat recovery cost": 273216,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 331341.2,
    "out:DH kr savings": 352101.07387760933,
    "out:El kr savings": -88048.72387594504,
    "out:El kr sold": 67288.8492888633,
    "out:El kr saved": 80126.67193,
    "out:El kr return": 147415.52121886332,
    "out:% solar/total": 100,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 187813.863747,
    "out:% savings (space heating)": 53.870202,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 997124.4110739925,
    "out:Eupp": 52.32,
    "out:Etvv": 0,
    "out:Ef": 25.31,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 5219158.697521492,
    "out:Seasonal Variation ROI (%)": 6,
    "out:Seasonal Variation Payback": 16.666667,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 21.239056,
    "out:EL CO2": -67.606842,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -71.099225,
    "out:Bought CO2": 3.492383,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00001001120001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 54.54649,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 38.88983,
    "out:Electricity (inc PV)": 26.327859,
    "out:Total Energy Use Pre PV": 93.88983,
    "out:Total Energy Use Post PV": 81.327859,
    "out:Primary Energy": 84.715467,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 36.095567,
    "out:CO2 Operational/m2": -47.923332,
    "out:Total CO2/m2": -11.827765,
    "out:Total CO2 (tons)": -37.659592,
    "out:Klimatpaverkan": -237.795013,
    "out:Initial Cost/MSEK": 5.298758672521492,
    "out:Running cost/(Apt SEK y)": 13385.744681,
    "out:Running Cost over RSP/MSEK": 4.9294,
    "out:LCP/MSEK": 3.176561,
    "out:ROI% old": 22.84988,
    "out:Payback discounted": 5,
    "out:Atemp": 3184,
    "out:Umean": 0.82,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 81,
    "out:Energy use kWh/m2": 81,
    "out:Energy savings %": 125.9,
    "out:Op energy cost/MSEK": 36,
    "out:El price/MSEK": 24,
    "out:Heating price/MSEK": 11,
    "out:DH+ DHW/kWh/m2": 55,
    "out:El bought/kWh/m2": 1860.477717,
    "out:Return %": 20,
    "out:Return/kSEK/y": 1086,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.6502792112,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 28.356,
    "out:EAHP (El)": 24.02033,
    "out:PV (kr/kWp)": 17461.48474631725,
    "out:PV (kWp installed)": 90.5097,
    "out:PV (m2 panels)": 474.867263,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 84922.568824,
    "out:PV (kWh sold)": 44925.265074,
    "out:PV (% sold (El))": 52.901444,
    "out:PV (kWh self-consumed)": 39997.30375,
    "out:PV (ratio sold/self-consumed)": 1.123207,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 7,
    "out:Payback Time": 14.285714,
    "out:DH kWh savings": 366238.96572,
    "out:EL kWh savings": -43805.009876,
    "out:Sewage heat recovery cost": 273216,
    "out:Balancing hydronic system cost": 79599.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 346016.84,
    "out:DH kr savings": 366238.96571980766,
    "out:El kr savings": -87610.01975194218,
    "out:El kr sold": 67387.8976104098,
    "out:El kr saved": 79994.6075,
    "out:El kr return": 147382.5051104098,
    "out:% solar/total": 100,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 173675.971904,
    "out:% savings (space heating)": 57.342673,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 997124.4110739925,
    "out:Eupp": 47.99,
    "out:Etvv": 0,
    "out:Ef": 25.21,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 5298758.6725214925,
    "out:Seasonal Variation ROI (%)": 6,
    "out:Seasonal Variation Payback": 16.666667,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 19.79912,
    "out:EL CO2": -67.722452,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -71.205714,
    "out:Bought CO2": 3.483262,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00001002000000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 17.20789,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 55.683368,
    "out:Electricity (inc PV)": 55.683368,
    "out:Total Energy Use Pre PV": 72.683368,
    "out:Total Energy Use Post PV": 72.683368,
    "out:Primary Energy": 109.3109,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 6.452557,
    "out:CO2 Operational/m2": 13.470378,
    "out:Total CO2/m2": 19.922935,
    "out:Total CO2 (tons)": 63.434606,
    "out:Klimatpaverkan": -136.700815,
    "out:Initial Cost/MSEK": 4.832586264077743,
    "out:Running cost/(Apt SEK y)": 11022.510638,
    "out:Running Cost over RSP/MSEK": 4.01987,
    "out:LCP/MSEK": 4.552264,
    "out:ROI% old": 27.742761,
    "out:Payback discounted": 5,
    "out:Atemp": 3184,
    "out:Umean": 0.82,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 73,
    "out:Energy use kWh/m2": 73,
    "out:Energy savings %": 150.7,
    "out:Op energy cost/MSEK": 28,
    "out:El price/MSEK": 24,
    "out:Heating price/MSEK": 3,
    "out:DH+ DHW/kWh/m2": 17,
    "out:El bought/kWh/m2": 3897.83576,
    "out:Return %": 25,
    "out:Return/kSEK/y": 1197,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 1774800,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 88740,
    "out:EAHP (replacement cost)": 1325203.921671023,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 41.7,
    "out:EAHP (El)": 40.813868,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 485125.032349,
    "out:EL kWh savings": -137272.922409,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 210579.19,
    "out:DH kr savings": 485125.0323494289,
    "out:El kr savings": -274545.8448187869,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 54789.905275,
    "out:% savings (space heating)": 86.542808,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 997124.4110739925,
    "out:Eupp": 11.68,
    "out:Etvv": 0,
    "out:Ef": 52.3,
    "out:heatPumpQuantity": 12,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 4832586.264077743,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 6.119728,
    "out:EL CO2": 7.35065,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 7.35065,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00001002000000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 14.02907,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 55.211472,
    "out:Electricity (inc PV)": 55.211472,
    "out:Total Energy Use Pre PV": 69.211472,
    "out:Total Energy Use Post PV": 69.211472,
    "out:Primary Energy": 106.386301,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 6.452557,
    "out:CO2 Operational/m2": 12.328132,
    "out:Total CO2/m2": 18.780689,
    "out:Total CO2 (tons)": 59.797696,
    "out:Klimatpaverkan": -140.337725,
    "out:Initial Cost/MSEK": 4.912186239077743,
    "out:Running cost/(Apt SEK y)": 10344.808511,
    "out:Running Cost over RSP/MSEK": 3.77101,
    "out:LCP/MSEK": 4.721524,
    "out:ROI% old": 28.01694,
    "out:Payback discounted": 5,
    "out:Atemp": 3184,
    "out:Umean": 0.82,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 69,
    "out:Energy use kWh/m2": 69,
    "out:Energy savings %": 165.2,
    "out:Op energy cost/MSEK": 26,
    "out:El price/MSEK": 23,
    "out:Heating price/MSEK": 3,
    "out:DH+ DHW/kWh/m2": 14,
    "out:El bought/kWh/m2": 3864.80304,
    "out:Return %": 25,
    "out:Return/kSEK/y": 1229,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 1774800,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 88740,
    "out:EAHP (replacement cost)": 1325203.921671023,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 41.7,
    "out:EAHP (El)": 40.341972,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 495246.392184,
    "out:EL kWh savings": -135770.405997,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 79599.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 223705.58,
    "out:DH kr savings": 495246.3921841194,
    "out:El kr savings": -271540.81199493964,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 44668.54544,
    "out:% savings (space heating)": 89.02876,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 997124.4110739925,
    "out:Eupp": 9.01,
    "out:Etvv": 0,
    "out:Ef": 51.67,
    "out:heatPumpQuantity": 12,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 4912186.239077742,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 5.039776,
    "out:EL CO2": 7.288356,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 7.288356,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00001002000001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 15.003565,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 54.455891,
    "out:Electricity (inc PV)": 54.455891,
    "out:Total Energy Use Pre PV": 69.455891,
    "out:Total Energy Use Post PV": 69.455891,
    "out:Primary Energy": 106.252168,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 7.1611,
    "out:CO2 Operational/m2": 12.588373,
    "out:Total CO2/m2": 19.749473,
    "out:Total CO2 (tons)": 62.882303,
    "out:Klimatpaverkan": -137.253118,
    "out:Initial Cost/MSEK": 5.105802264077742,
    "out:Running cost/(Apt SEK y)": 10447.021277,
    "out:Running Cost over RSP/MSEK": 3.80902,
    "out:LCP/MSEK": 4.489898,
    "out:ROI% old": 26.848166,
    "out:Payback discounted": 5,
    "out:Atemp": 3184,
    "out:Umean": 0.82,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 69,
    "out:Energy use kWh/m2": 69,
    "out:Energy savings %": 165.2,
    "out:Op energy cost/MSEK": 26,
    "out:El price/MSEK": 23,
    "out:Heating price/MSEK": 3,
    "out:DH+ DHW/kWh/m2": 15,
    "out:El bought/kWh/m2": 3811.91237,
    "out:Return %": 24,
    "out:Return/kSEK/y": 1224,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 1774800,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 88740,
    "out:EAHP (replacement cost)": 1325203.921671023,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 35.445,
    "out:EAHP (El)": 39.586391,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 492143.601038,
    "out:EL kWh savings": -133364.636817,
    "out:Sewage heat recovery cost": 273216,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 225414.33,
    "out:DH kr savings": 492143.6010376855,
    "out:El kr savings": -266729.2736346329,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 47771.336587,
    "out:% savings (space heating)": 88.26667,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 997124.4110739925,
    "out:Eupp": 10.58,
    "out:Etvv": 0,
    "out:Ef": 51.49,
    "out:heatPumpQuantity": 12,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 5105802.264077742,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 5.39976,
    "out:EL CO2": 7.188613,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 7.188613,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00001002000001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 12.013518,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 53.926791,
    "out:Electricity (inc PV)": 53.926791,
    "out:Total Energy Use Pre PV": 65.926791,
    "out:Total Energy Use Post PV": 65.926791,
    "out:Primary Energy": 103.328692,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 7.1611,
    "out:CO2 Operational/m2": 11.438576,
    "out:Total CO2/m2": 18.599676,
    "out:Total CO2 (tons)": 59.221351,
    "out:Klimatpaverkan": -140.91407,
    "out:Initial Cost/MSEK": 5.185402239077742,
    "out:Running cost/(Apt SEK y)": 9761.574468,
    "out:Running Cost over RSP/MSEK": 3.55734,
    "out:LCP/MSEK": 4.661978,
    "out:ROI% old": 27.129401,
    "out:Payback discounted": 5,
    "out:Atemp": 3184,
    "out:Umean": 0.82,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 66,
    "out:Energy use kWh/m2": 66,
    "out:Energy savings %": 177.3,
    "out:Op energy cost/MSEK": 24,
    "out:El price/MSEK": 21,
    "out:Heating price/MSEK": 2,
    "out:DH+ DHW/kWh/m2": 12,
    "out:El bought/kWh/m2": 3774.87537,
    "out:Return %": 24,
    "out:Return/kSEK/y": 1256,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 1774800,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 88740,
    "out:EAHP (replacement cost)": 1325203.921671023,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 35.445,
    "out:EAHP (El)": 39.057291,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 501663.907821,
    "out:EL kWh savings": -131679.982924,
    "out:Sewage heat recovery cost": 273216,
    "out:Balancing hydronic system cost": 79599.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 238303.94,
    "out:DH kr savings": 501663.9078212205,
    "out:El kr savings": -263359.9658483885,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 38251.029803,
    "out:% savings (space heating)": 90.604995,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 997124.4110739925,
    "out:Eupp": 8.05,
    "out:Etvv": 0,
    "out:Ef": 50.83,
    "out:heatPumpQuantity": 12,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 5185402.239077742,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 4.319808,
    "out:EL CO2": 7.118768,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 7.118768,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00001002010000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 17.20789,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 55.683368,
    "out:Electricity (inc PV)": 43.933617,
    "out:Total Energy Use Pre PV": 72.683368,
    "out:Total Energy Use Post PV": 60.933617,
    "out:Primary Energy": 88.161349,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 21.191657,
    "out:CO2 Operational/m2": 4.245753,
    "out:Total CO2/m2": 25.43741,
    "out:Total CO2 (tons)": 80.992689,
    "out:Klimatpaverkan": -119.142732,
    "out:Initial Cost/MSEK": 5.622806658300243,
    "out:Running cost/(Apt SEK y)": 9269.382979,
    "out:Running Cost over RSP/MSEK": 3.39278,
    "out:LCP/MSEK": 4.389133,
    "out:ROI% old": 25.437068,
    "out:Payback discounted": 5,
    "out:Atemp": 3184,
    "out:Umean": 0.82,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 61,
    "out:Energy use kWh/m2": 61,
    "out:Energy savings %": 200,
    "out:Op energy cost/MSEK": 24,
    "out:El price/MSEK": 20,
    "out:Heating price/MSEK": 3,
    "out:DH+ DHW/kWh/m2": 17,
    "out:El bought/kWh/m2": 3108.736646,
    "out:Return %": 23,
    "out:Return/kSEK/y": 1279,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 1774800,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 88740,
    "out:EAHP (replacement cost)": 1325203.921671023,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 41.7,
    "out:EAHP (El)": 40.813868,
    "out:PV (kr/kWp)": 17461.56255567083,
    "out:PV (kWp installed)": 45.25485,
    "out:PV (m2 panels)": 237.433632,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 42461.284501,
    "out:PV (kWh sold)": 5050.087815,
    "out:PV (% sold (El))": 11.893394,
    "out:PV (kWh self-consumed)": 37411.196686,
    "out:PV (ratio sold/self-consumed)": 0.134989,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 485125.032349,
    "out:EL kWh savings": -99861.726482,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 292976.71,
    "out:DH kr savings": 485125.0323494289,
    "out:El kr savings": -199723.4529633098,
    "out:El kr sold": 7575.13172245301,
    "out:El kr saved": 74822.393372,
    "out:El kr return": 82397.52509445301,
    "out:% solar/total": 93,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 54789.905275,
    "out:% savings (space heating)": 86.542808,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 997124.4110739925,
    "out:Eupp": 11.68,
    "out:Etvv": 0,
    "out:Ef": 40.86,
    "out:heatPumpQuantity": 12,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 5622806.658300243,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 6.119728,
    "out:EL CO2": -1.873975,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -7.688426,
    "out:Bought CO2": 5.814451,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00001002010000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 14.02907,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 55.211472,
    "out:Electricity (inc PV)": 43.503485,
    "out:Total Energy Use Pre PV": 69.211472,
    "out:Total Energy Use Post PV": 57.503485,
    "out:Primary Energy": 85.311924,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 21.191657,
    "out:CO2 Operational/m2": 2.900142,
    "out:Total CO2/m2": 24.091799,
    "out:Total CO2 (tons)": 76.708265,
    "out:Klimatpaverkan": -123.427156,
    "out:Initial Cost/MSEK": 5.702406633300242,
    "out:Running cost/(Apt SEK y)": 8593.085106,
    "out:Running Cost over RSP/MSEK": 3.14441,
    "out:LCP/MSEK": 4.557903,
    "out:ROI% old": 25.70421,
    "out:Payback discounted": 5,
    "out:Atemp": 3184,
    "out:Umean": 0.82,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 58,
    "out:Energy use kWh/m2": 58,
    "out:Energy savings %": 215.5,
    "out:Op energy cost/MSEK": 22,
    "out:El price/MSEK": 19,
    "out:Heating price/MSEK": 3,
    "out:DH+ DHW/kWh/m2": 14,
    "out:El bought/kWh/m2": 3078.229731,
    "out:Return %": 23,
    "out:Return/kSEK/y": 1311,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 1774800,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 88740,
    "out:EAHP (replacement cost)": 1325203.921671023,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 41.7,
    "out:EAHP (El)": 40.341972,
    "out:PV (kr/kWp)": 17461.56255567083,
    "out:PV (kWp installed)": 45.25485,
    "out:PV (m2 panels)": 237.433632,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 42461.284501,
    "out:PV (kWh sold)": 5183.065096,
    "out:PV (% sold (El))": 12.206567,
    "out:PV (kWh self-consumed)": 37278.219406,
    "out:PV (ratio sold/self-consumed)": 0.139037,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 495246.392184,
    "out:EL kWh savings": -98492.186606,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 79599.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 306036.62,
    "out:DH kr savings": 495246.3921841194,
    "out:El kr savings": -196984.37321144273,
    "out:El kr sold": 7774.597643433739,
    "out:El kr saved": 74556.438812,
    "out:El kr return": 82331.03645543373,
    "out:% solar/total": 93,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 44668.54544,
    "out:% savings (space heating)": 89.02876,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 997124.4110739925,
    "out:Eupp": 9.01,
    "out:Etvv": 0,
    "out:Ef": 40.3,
    "out:heatPumpQuantity": 12,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 5702406.633300242,
    "out:Seasonal Variation ROI (%)": 5,
    "out:Seasonal Variation Payback": 20,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 5.039776,
    "out:EL CO2": -2.139634,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -7.897108,
    "out:Bought CO2": 5.757474,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00001002010001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 15.003565,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 54.455891,
    "out:Electricity (inc PV)": 42.815854,
    "out:Total Energy Use Pre PV": 69.455891,
    "out:Total Energy Use Post PV": 57.815854,
    "out:Primary Energy": 85.300101,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 21.9002,
    "out:CO2 Operational/m2": 2.827269,
    "out:Total CO2/m2": 24.727469,
    "out:Total CO2 (tons)": 78.732238,
    "out:Klimatpaverkan": -121.403183,
    "out:Initial Cost/MSEK": 5.896022658300242,
    "out:Running cost/(Apt SEK y)": 8697.595745,
    "out:Running Cost over RSP/MSEK": 3.18323,
    "out:LCP/MSEK": 4.325467,
    "out:ROI% old": 24.766066,
    "out:Payback discounted": 5,
    "out:Atemp": 3184,
    "out:Umean": 0.82,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 58,
    "out:Energy use kWh/m2": 58,
    "out:Energy savings %": 215.5,
    "out:Op energy cost/MSEK": 22,
    "out:El price/MSEK": 19,
    "out:Heating price/MSEK": 3,
    "out:DH+ DHW/kWh/m2": 15,
    "out:El bought/kWh/m2": 3029.481833,
    "out:Return %": 22,
    "out:Return/kSEK/y": 1306,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 1774800,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 88740,
    "out:EAHP (replacement cost)": 1325203.921671023,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 35.445,
    "out:EAHP (El)": 39.586391,
    "out:PV (kr/kWp)": 17461.56255567083,
    "out:PV (kWp installed)": 45.25485,
    "out:PV (m2 panels)": 237.433632,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 42461.284501,
    "out:PV (kWh sold)": 5399.419235,
    "out:PV (% sold (El))": 12.7161,
    "out:PV (kWh self-consumed)": 37061.865267,
    "out:PV (ratio sold/self-consumed)": 0.145687,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 492143.601038,
    "out:EL kWh savings": -96302.77016,
    "out:Sewage heat recovery cost": 273216,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 307637.19,
    "out:DH kr savings": 492143.6010376855,
    "out:El kr savings": -192605.54032094375,
    "out:El kr sold": 8099.128852344262,
    "out:El kr saved": 74123.730534,
    "out:El kr return": 82222.85938634427,
    "out:% solar/total": 93,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 47771.336587,
    "out:% savings (space heating)": 88.26667,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 997124.4110739925,
    "out:Eupp": 10.58,
    "out:Etvv": 0,
    "out:Ef": 40.14,
    "out:heatPumpQuantity": 12,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 5896022.658300242,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 5.39976,
    "out:EL CO2": -2.572491,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -8.23893,
    "out:Bought CO2": 5.666439,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00001002010001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 12.013518,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 53.926791,
    "out:Electricity (inc PV)": 42.335333,
    "out:Total Energy Use Pre PV": 65.926791,
    "out:Total Energy Use Post PV": 54.335333,
    "out:Primary Energy": 82.464067,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 21.9002,
    "out:CO2 Operational/m2": 1.438885,
    "out:Total CO2/m2": 23.339085,
    "out:Total CO2 (tons)": 74.311624,
    "out:Klimatpaverkan": -125.823797,
    "out:Initial Cost/MSEK": 5.975622633300242,
    "out:Running cost/(Apt SEK y)": 8013.787234,
    "out:Running Cost over RSP/MSEK": 2.93213,
    "out:LCP/MSEK": 4.496967,
    "out:ROI% old": 25.036459,
    "out:Payback discounted": 5,
    "out:Atemp": 3184,
    "out:Umean": 0.82,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 54,
    "out:Energy use kWh/m2": 54,
    "out:Energy savings %": 238.9,
    "out:Op energy cost/MSEK": 20,
    "out:El price/MSEK": 18,
    "out:Heating price/MSEK": 2,
    "out:DH+ DHW/kWh/m2": 12,
    "out:El bought/kWh/m2": 2995.418235,
    "out:Return %": 22,
    "out:Return/kSEK/y": 1338,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 1774800,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 88740,
    "out:EAHP (replacement cost)": 1325203.921671023,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 35.445,
    "out:EAHP (El)": 39.057291,
    "out:PV (kr/kWp)": 17461.56255567083,
    "out:PV (kWp installed)": 45.25485,
    "out:PV (m2 panels)": 237.433632,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 42461.284501,
    "out:PV (kWh sold)": 5554.093385,
    "out:PV (% sold (El))": 13.080371,
    "out:PV (kWh self-consumed)": 36907.191117,
    "out:PV (ratio sold/self-consumed)": 0.150488,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 501663.907821,
    "out:EL kWh savings": -94772.791757,
    "out:Sewage heat recovery cost": 273216,
    "out:Balancing hydronic system cost": 79599.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 320449.46,
    "out:DH kr savings": 501663.9078212205,
    "out:El kr savings": -189545.5835136219,
    "out:El kr sold": 8331.140076837868,
    "out:El kr saved": 73814.382234,
    "out:El kr return": 82145.52231083787,
    "out:% solar/total": 92,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 38251.029803,
    "out:% savings (space heating)": 90.604995,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 997124.4110739925,
    "out:Eupp": 8.05,
    "out:Etvv": 0,
    "out:Ef": 39.54,
    "out:heatPumpQuantity": 12,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 5975622.633300242,
    "out:Seasonal Variation ROI (%)": 5,
    "out:Seasonal Variation Payback": 20,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 4.319808,
    "out:EL CO2": -2.880923,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -8.483753,
    "out:Bought CO2": 5.60283,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00001002020000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 17.20789,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 55.683368,
    "out:Electricity (inc PV)": 39.660322,
    "out:Total Energy Use Pre PV": 72.683368,
    "out:Total Energy Use Post PV": 56.660322,
    "out:Primary Energy": 80.469418,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 35.930756,
    "out:CO2 Operational/m2": -42.074841,
    "out:Total CO2/m2": -6.144085,
    "out:Total CO2 (tons)": -19.562761,
    "out:Klimatpaverkan": -219.698182,
    "out:Initial Cost/MSEK": 6.4130200100214925,
    "out:Running cost/(Apt SEK y)": 7769.489362,
    "out:Running Cost over RSP/MSEK": 2.85597,
    "out:LCP/MSEK": 4.13573,
    "out:ROI% old": 23.498512,
    "out:Payback discounted": 5,
    "out:Atemp": 3184,
    "out:Umean": 0.82,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 57,
    "out:Energy use kWh/m2": 57,
    "out:Energy savings %": 221.1,
    "out:Op energy cost/MSEK": 20,
    "out:El price/MSEK": 17,
    "out:Heating price/MSEK": 3,
    "out:DH+ DHW/kWh/m2": 17,
    "out:El bought/kWh/m2": 2803.770101,
    "out:Return %": 21,
    "out:Return/kSEK/y": 1350,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 1774800,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 88740,
    "out:EAHP (replacement cost)": 1325203.921671023,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 41.7,
    "out:EAHP (El)": 40.813868,
    "out:PV (kr/kWp)": 17461.48474631725,
    "out:PV (kWp installed)": 90.5097,
    "out:PV (m2 panels)": 474.867263,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 84922.568824,
    "out:PV (kWh sold)": 33905.206433,
    "out:PV (% sold (El))": 39.924848,
    "out:PV (kWh self-consumed)": 51017.362391,
    "out:PV (ratio sold/self-consumed)": 0.664582,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 485125.032349,
    "out:EL kWh savings": -86255.559295,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 363471.72,
    "out:DH kr savings": 485125.0323494289,
    "out:El kr savings": -172511.11859094308,
    "out:El kr sold": 50857.809649791,
    "out:El kr saved": 102034.724782,
    "out:El kr return": 152892.534431791,
    "out:% solar/total": 127,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 54789.905275,
    "out:% savings (space heating)": 86.542808,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 997124.4110739925,
    "out:Eupp": 11.68,
    "out:Etvv": 0,
    "out:Ef": 36.92,
    "out:heatPumpQuantity": 12,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 6413020.010021493,
    "out:Seasonal Variation ROI (%)": 5,
    "out:Seasonal Variation Payback": 20,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 6.119728,
    "out:EL CO2": -48.194569,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -53.442344,
    "out:Bought CO2": 5.247775,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00001002020000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 14.02907,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 55.211472,
    "out:Electricity (inc PV)": 39.276662,
    "out:Total Energy Use Pre PV": 69.211472,
    "out:Total Energy Use Post PV": 53.276662,
    "out:Primary Energy": 77.703643,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 35.930756,
    "out:CO2 Operational/m2": -43.656814,
    "out:Total CO2/m2": -7.726058,
    "out:Total CO2 (tons)": -24.599761,
    "out:Klimatpaverkan": -224.735182,
    "out:Initial Cost/MSEK": 6.492619985021493,
    "out:Running cost/(Apt SEK y)": 7094.765957,
    "out:Running Cost over RSP/MSEK": 2.60818,
    "out:LCP/MSEK": 4.30392,
    "out:ROI% old": 23.755631,
    "out:Payback discounted": 5,
    "out:Atemp": 3184,
    "out:Umean": 0.82,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 53,
    "out:Energy use kWh/m2": 53,
    "out:Energy savings %": 245.3,
    "out:Op energy cost/MSEK": 19,
    "out:El price/MSEK": 15,
    "out:Heating price/MSEK": 3,
    "out:DH+ DHW/kWh/m2": 14,
    "out:El bought/kWh/m2": 2776.599634,
    "out:Return %": 21,
    "out:Return/kSEK/y": 1381,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 1774800,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 88740,
    "out:EAHP (replacement cost)": 1325203.921671023,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 41.7,
    "out:EAHP (El)": 40.341972,
    "out:PV (kr/kWp)": 17461.48474631725,
    "out:PV (kWp installed)": 90.5097,
    "out:PV (m2 panels)": 474.867263,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 84922.568824,
    "out:PV (kWh sold)": 34186.148374,
    "out:PV (% sold (El))": 40.255669,
    "out:PV (kWh self-consumed)": 50736.42045,
    "out:PV (ratio sold/self-consumed)": 0.673799,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 495246.392184,
    "out:EL kWh savings": -85033.986223,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 79599.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 376457.64,
    "out:DH kr savings": 495246.3921841194,
    "out:El kr savings": -170067.97244603562,
    "out:El kr sold": 51279.22256065852,
    "out:El kr saved": 101472.8409,
    "out:El kr return": 152752.0634606585,
    "out:% solar/total": 127,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 44668.54544,
    "out:% savings (space heating)": 89.02876,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 997124.4110739925,
    "out:Eupp": 9.01,
    "out:Etvv": 0,
    "out:Ef": 36.41,
    "out:heatPumpQuantity": 12,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 6492619.985021493,
    "out:Seasonal Variation ROI (%)": 5,
    "out:Seasonal Variation Payback": 20,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 5.039776,
    "out:EL CO2": -48.69659,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -53.893562,
    "out:Bought CO2": 5.196972,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00001002020001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 15.003565,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 54.455891,
    "out:Electricity (inc PV)": 38.663034,
    "out:Total Energy Use Pre PV": 69.455891,
    "out:Total Energy Use Post PV": 53.663034,
    "out:Primary Energy": 77.825025,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 36.639299,
    "out:CO2 Operational/m2": -44.106224,
    "out:Total CO2/m2": -7.466925,
    "out:Total CO2 (tons)": -23.774682,
    "out:Klimatpaverkan": -223.910103,
    "out:Initial Cost/MSEK": 6.686236010021493,
    "out:Running cost/(Apt SEK y)": 7201.787234,
    "out:Running Cost over RSP/MSEK": 2.64792,
    "out:LCP/MSEK": 4.070564,
    "out:ROI% old": 22.982822,
    "out:Payback discounted": 5,
    "out:Atemp": 3184,
    "out:Umean": 0.82,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 54,
    "out:Energy use kWh/m2": 54,
    "out:Energy savings %": 238.9,
    "out:Op energy cost/MSEK": 19,
    "out:El price/MSEK": 15,
    "out:Heating price/MSEK": 3,
    "out:DH+ DHW/kWh/m2": 15,
    "out:El bought/kWh/m2": 2733.171603,
    "out:Return %": 21,
    "out:Return/kSEK/y": 1376,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 1774800,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 88740,
    "out:EAHP (replacement cost)": 1325203.921671023,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 35.445,
    "out:EAHP (El)": 39.586391,
    "out:PV (kr/kWp)": 17461.48474631725,
    "out:PV (kWp installed)": 90.5097,
    "out:PV (m2 panels)": 474.867263,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 84922.568824,
    "out:PV (kWh sold)": 34638.128651,
    "out:PV (% sold (El))": 40.787896,
    "out:PV (kWh self-consumed)": 50284.440173,
    "out:PV (ratio sold/self-consumed)": 0.688844,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 492143.601038,
    "out:EL kWh savings": -83080.195259,
    "out:Sewage heat recovery cost": 273216,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 377940.4,
    "out:DH kr savings": 492143.6010376855,
    "out:El kr savings": -166160.39051774683,
    "out:El kr sold": 51957.19297614839,
    "out:El kr saved": 100568.880346,
    "out:El kr return": 152526.0733221484,
    "out:% solar/total": 126,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 47771.336587,
    "out:% savings (space heating)": 88.26667,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 997124.4110739925,
    "out:Eupp": 10.58,
    "out:Etvv": 0,
    "out:Ef": 36.27,
    "out:heatPumpQuantity": 12,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 6686236.010021493,
    "out:Seasonal Variation ROI (%)": 5,
    "out:Seasonal Variation Payback": 20,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 5.39976,
    "out:EL CO2": -49.505984,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -54.621759,
    "out:Bought CO2": 5.115775,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00001002020001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 12.013518,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 53.926791,
    "out:Electricity (inc PV)": 38.23404,
    "out:Total Energy Use Pre PV": 65.926791,
    "out:Total Energy Use Post PV": 50.23404,
    "out:Primary Energy": 75.08174,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 36.639299,
    "out:CO2 Operational/m2": -45.756953,
    "out:Total CO2/m2": -9.117654,
    "out:Total CO2 (tons)": -29.030602,
    "out:Klimatpaverkan": -229.166023,
    "out:Initial Cost/MSEK": 6.765835985021494,
    "out:Running cost/(Apt SEK y)": 6519.723404,
    "out:Running Cost over RSP/MSEK": 2.39746,
    "out:LCP/MSEK": 4.241424,
    "out:ROI% old": 23.241263,
    "out:Payback discounted": 5,
    "out:Atemp": 3184,
    "out:Umean": 0.82,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 50,
    "out:Energy use kWh/m2": 50,
    "out:Energy savings %": 266,
    "out:Op energy cost/MSEK": 17,
    "out:El price/MSEK": 14,
    "out:Heating price/MSEK": 2,
    "out:DH+ DHW/kWh/m2": 12,
    "out:El bought/kWh/m2": 2702.817275,
    "out:Return %": 21,
    "out:Return/kSEK/y": 1408,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 1774800,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 88740,
    "out:EAHP (replacement cost)": 1325203.921671023,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 35.445,
    "out:EAHP (El)": 39.057291,
    "out:PV (kr/kWp)": 17461.48474631725,
    "out:PV (kWp installed)": 90.5097,
    "out:PV (m2 panels)": 474.867263,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 84922.568824,
    "out:PV (kWh sold)": 34956.863717,
    "out:PV (% sold (El))": 41.16322,
    "out:PV (kWh self-consumed)": 49965.705107,
    "out:PV (ratio sold/self-consumed)": 0.699617,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 501663.907821,
    "out:EL kWh savings": -81714.278774,
    "out:Sewage heat recovery cost": 273216,
    "out:Balancing hydronic system cost": 79599.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 390670.65,
    "out:DH kr savings": 501663.9078212205,
    "out:El kr savings": -163428.5575476993,
    "out:El kr sold": 52435.29557540221,
    "out:El kr saved": 99931.410214,
    "out:El kr return": 152366.70578940221,
    "out:% solar/total": 125,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 38251.029803,
    "out:% savings (space heating)": 90.604995,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 997124.4110739925,
    "out:Eupp": 8.05,
    "out:Etvv": 0,
    "out:Ef": 35.73,
    "out:heatPumpQuantity": 12,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 6765835.985021493,
    "out:Seasonal Variation ROI (%)": 5,
    "out:Seasonal Variation Payback": 20,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 4.319808,
    "out:EL CO2": -50.076761,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -55.135781,
    "out:Bought CO2": 5.05902,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00001002100000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 14.304659,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 54.035862,
    "out:Electricity (inc PV)": 54.035862,
    "out:Total Energy Use Pre PV": 68.035862,
    "out:Total Energy Use Post PV": 68.035862,
    "out:Primary Energy": 105.224145,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 6.452557,
    "out:CO2 Operational/m2": 12.172942,
    "out:Total CO2/m2": 18.625499,
    "out:Total CO2 (tons)": 59.303571,
    "out:Klimatpaverkan": -140.83185,
    "out:Initial Cost/MSEK": 4.956633951577742,
    "out:Running cost/(Apt SEK y)": 10185.531915,
    "out:Running Cost over RSP/MSEK": 3.71313,
    "out:LCP/MSEK": 4.734956,
    "out:ROI% old": 27.932522,
    "out:Payback discounted": 5,
    "out:Atemp": 3184,
    "out:Umean": 0.82,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 68,
    "out:Energy use kWh/m2": 68,
    "out:Energy savings %": 169.1,
    "out:Op energy cost/MSEK": 25,
    "out:El price/MSEK": 22,
    "out:Heating price/MSEK": 3,
    "out:DH+ DHW/kWh/m2": 14,
    "out:El bought/kWh/m2": 3782.51034,
    "out:Return %": 25,
    "out:Return/kSEK/y": 1236,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 1774800,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 88740,
    "out:EAHP (replacement cost)": 1325203.921671023,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 33.36,
    "out:EAHP (El)": 39.166362,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 494368.917072,
    "out:EL kWh savings": -132027.264884,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 230314.39,
    "out:DH kr savings": 494368.9170721336,
    "out:El kr savings": -264054.52976740844,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 45546.020552,
    "out:% savings (space heating)": 88.813239,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 997124.4110739925,
    "out:Eupp": 10.23,
    "out:Etvv": 0,
    "out:Ef": 51.22,
    "out:heatPumpQuantity": 12,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 4956633.951577742,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 5.039776,
    "out:EL CO2": 7.133166,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 7.133166,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00001002100000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 11.375019,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 53.488458,
    "out:Electricity (inc PV)": 53.488458,
    "out:Total Energy Use Pre PV": 64.488458,
    "out:Total Energy Use Post PV": 64.488458,
    "out:Primary Energy": 102.301188,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 6.452557,
    "out:CO2 Operational/m2": 11.020728,
    "out:Total CO2/m2": 17.473285,
    "out:Total CO2 (tons)": 55.634923,
    "out:Klimatpaverkan": -144.500498,
    "out:Initial Cost/MSEK": 5.036233926577742,
    "out:Running cost/(Apt SEK y)": 9497.595745,
    "out:Running Cost over RSP/MSEK": 3.46055,
    "out:LCP/MSEK": 4.907936,
    "out:ROI% old": 28.207501,
    "out:Payback discounted": 5,
    "out:Atemp": 3184,
    "out:Umean": 0.82,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 64,
    "out:Energy use kWh/m2": 64,
    "out:Energy savings %": 185.9,
    "out:Op energy cost/MSEK": 23,
    "out:El price/MSEK": 21,
    "out:Heating price/MSEK": 2,
    "out:DH+ DHW/kWh/m2": 11,
    "out:El bought/kWh/m2": 3744.19206,
    "out:Return %": 25,
    "out:Return/kSEK/y": 1268,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 1774800,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 88740,
    "out:EAHP (replacement cost)": 1325203.921671023,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 33.36,
    "out:EAHP (El)": 38.618958,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 503696.888026,
    "out:EL kWh savings": -130284.331072,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 79599.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 243128.23,
    "out:DH kr savings": 503696.8880255385,
    "out:El kr savings": -260568.66214423452,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 36218.049599,
    "out:% savings (space heating)": 91.104324,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 997124.4110739925,
    "out:Eupp": 7.74,
    "out:Etvv": 0,
    "out:Ef": 50.54,
    "out:heatPumpQuantity": 12,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 5036233.926577742,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 3.959824,
    "out:EL CO2": 7.060904,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 7.060904,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00001002100001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 12.700909,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 53.005483,
    "out:Electricity (inc PV)": 53.005483,
    "out:Total Energy Use Pre PV": 66.005483,
    "out:Total Energy Use Post PV": 66.005483,
    "out:Primary Energy": 102.740251,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 7.1611,
    "out:CO2 Operational/m2": 11.67694,
    "out:Total CO2/m2": 18.83804,
    "out:Total CO2 (tons)": 59.980301,
    "out:Klimatpaverkan": -140.15512,
    "out:Initial Cost/MSEK": 5.229849951577742,
    "out:Running cost/(Apt SEK y)": 9841.340426,
    "out:Running Cost over RSP/MSEK": 3.58719,
    "out:LCP/MSEK": 4.58768,
    "out:ROI% old": 26.817295,
    "out:Payback discounted": 5,
    "out:Atemp": 3184,
    "out:Umean": 0.82,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 66,
    "out:Energy use kWh/m2": 66,
    "out:Energy savings %": 177.3,
    "out:Op energy cost/MSEK": 24,
    "out:El price/MSEK": 21,
    "out:Heating price/MSEK": 3,
    "out:DH+ DHW/kWh/m2": 13,
    "out:El bought/kWh/m2": 3710.38381,
    "out:Return %": 24,
    "out:Return/kSEK/y": 1252,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 1774800,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 88740,
    "out:EAHP (replacement cost)": 1325203.921671023,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 28.356,
    "out:EAHP (El)": 38.135983,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 499475.255536,
    "out:EL kWh savings": -128746.539135,
    "out:Sewage heat recovery cost": 273216,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 241982.18,
    "out:DH kr savings": 499475.2555357411,
    "out:El kr savings": -257493.07826961455,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 40439.682089,
    "out:% savings (space heating)": 90.06743,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 997124.4110739925,
    "out:Eupp": 9.42,
    "out:Etvv": 0,
    "out:Ef": 50.56,
    "out:heatPumpQuantity": 12,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 5229849.951577742,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 4.679792,
    "out:EL CO2": 6.997148,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 6.997148,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00001002100001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 9.917068,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 52.413897,
    "out:Electricity (inc PV)": 52.413897,
    "out:Total Energy Use Pre PV": 62.413897,
    "out:Total Energy Use Post PV": 62.413897,
    "out:Primary Energy": 99.81516,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 7.1611,
    "out:CO2 Operational/m2": 10.518894,
    "out:Total CO2/m2": 17.679994,
    "out:Total CO2 (tons)": 56.293084,
    "out:Klimatpaverkan": -143.842337,
    "out:Initial Cost/MSEK": 5.309449926577742,
    "out:Running cost/(Apt SEK y)": 9147.404255,
    "out:Running Cost over RSP/MSEK": 3.33244,
    "out:LCP/MSEK": 4.76283,
    "out:ROI% old": 27.100682,
    "out:Payback discounted": 5,
    "out:Atemp": 3184,
    "out:Umean": 0.82,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 62,
    "out:Energy use kWh/m2": 62,
    "out:Energy savings %": 195.2,
    "out:Op energy cost/MSEK": 23,
    "out:El price/MSEK": 20,
    "out:Heating price/MSEK": 2,
    "out:DH+ DHW/kWh/m2": 10,
    "out:El bought/kWh/m2": 3668.97279,
    "out:Return %": 24,
    "out:Return/kSEK/y": 1285,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 1774800,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 88740,
    "out:EAHP (replacement cost)": 1325203.921671023,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 28.356,
    "out:EAHP (El)": 37.544397,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 508339.002613,
    "out:EL kWh savings": -126862.929878,
    "out:Sewage heat recovery cost": 273216,
    "out:Balancing hydronic system cost": 79599.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 254613.14,
    "out:DH kr savings": 508339.0026128214,
    "out:El kr savings": -253725.85975509332,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 31575.935011,
    "out:% savings (space heating)": 92.244494,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 997124.4110739925,
    "out:Eupp": 7.03,
    "out:Etvv": 0,
    "out:Ef": 49.85,
    "out:heatPumpQuantity": 12,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 5309449.926577742,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 3.59984,
    "out:EL CO2": 6.919054,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 6.919054,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00001002110000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 14.304659,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 54.035862,
    "out:Electricity (inc PV)": 42.434328,
    "out:Total Energy Use Pre PV": 68.035862,
    "out:Total Energy Use Post PV": 56.434328,
    "out:Primary Energy": 84.341383,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 21.191657,
    "out:CO2 Operational/m2": 2.222782,
    "out:Total CO2/m2": 23.414439,
    "out:Total CO2 (tons)": 74.551551,
    "out:Klimatpaverkan": -125.58387,
    "out:Initial Cost/MSEK": 5.746854345800243,
    "out:Running cost/(Apt SEK y)": 8437.404255,
    "out:Running Cost over RSP/MSEK": 3.08781,
    "out:LCP/MSEK": 4.570056,
    "out:ROI% old": 25.646105,
    "out:Payback discounted": 5,
    "out:Atemp": 3184,
    "out:Umean": 0.82,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 56,
    "out:Energy use kWh/m2": 56,
    "out:Energy savings %": 226.8,
    "out:Op energy cost/MSEK": 21,
    "out:El price/MSEK": 18,
    "out:Heating price/MSEK": 3,
    "out:DH+ DHW/kWh/m2": 14,
    "out:El bought/kWh/m2": 3002.435367,
    "out:Return %": 23,
    "out:Return/kSEK/y": 1318,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 1774800,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 88740,
    "out:EAHP (replacement cost)": 1325203.921671023,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 33.36,
    "out:EAHP (El)": 39.166362,
    "out:PV (kr/kWp)": 17461.56255567083,
    "out:PV (kWp installed)": 45.25485,
    "out:PV (m2 panels)": 237.433632,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 42461.284501,
    "out:PV (kWh sold)": 5522.011862,
    "out:PV (% sold (El))": 13.004816,
    "out:PV (kWh self-consumed)": 36939.272639,
    "out:PV (ratio sold/self-consumed)": 0.149489,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 494368.917072,
    "out:EL kWh savings": -95087.991742,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 312475.95,
    "out:DH kr savings": 494368.9170721336,
    "out:El kr savings": -190175.9834839475,
    "out:El kr sold": 8283.017793674804,
    "out:El kr saved": 73878.545278,
    "out:El kr return": 82161.56307167481,
    "out:% solar/total": 92,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 45546.020552,
    "out:% savings (space heating)": 88.813239,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 997124.4110739925,
    "out:Eupp": 10.23,
    "out:Etvv": 0,
    "out:Ef": 39.89,
    "out:heatPumpQuantity": 12,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 5746854.345800242,
    "out:Seasonal Variation ROI (%)": 5,
    "out:Seasonal Variation Payback": 20,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 5.039776,
    "out:EL CO2": -2.816994,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -8.432928,
    "out:Bought CO2": 5.615934,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00001002110000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 11.375019,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 53.488458,
    "out:Electricity (inc PV)": 41.937999,
    "out:Total Energy Use Pre PV": 64.488458,
    "out:Total Energy Use Post PV": 52.937999,
    "out:Primary Energy": 81.510362,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 21.191657,
    "out:CO2 Operational/m2": 0.820093,
    "out:Total CO2/m2": 22.01175,
    "out:Total CO2 (tons)": 70.085391,
    "out:Klimatpaverkan": -130.05003,
    "out:Initial Cost/MSEK": 5.826454320800243,
    "out:Running cost/(Apt SEK y)": 7751.212766,
    "out:Running Cost over RSP/MSEK": 2.83583,
    "out:LCP/MSEK": 4.742436,
    "out:ROI% old": 25.913555,
    "out:Payback discounted": 5,
    "out:Atemp": 3184,
    "out:Umean": 0.82,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 53,
    "out:Energy use kWh/m2": 53,
    "out:Energy savings %": 245.3,
    "out:Op energy cost/MSEK": 20,
    "out:El price/MSEK": 17,
    "out:Heating price/MSEK": 2,
    "out:DH+ DHW/kWh/m2": 11,
    "out:El bought/kWh/m2": 2967.244038,
    "out:Return %": 23,
    "out:Return/kSEK/y": 1350,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 1774800,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 88740,
    "out:EAHP (replacement cost)": 1325203.921671023,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 33.36,
    "out:EAHP (El)": 38.618958,
    "out:PV (kr/kWp)": 17461.56255567083,
    "out:PV (kWp installed)": 45.25485,
    "out:PV (m2 panels)": 237.433632,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 42461.284501,
    "out:PV (kWh sold)": 5684.635391,
    "out:PV (% sold (El))": 13.387808,
    "out:PV (kWh self-consumed)": 36776.64911,
    "out:PV (ratio sold/self-consumed)": 0.154572,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 503696.888026,
    "out:EL kWh savings": -93507.680681,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 79599.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 325208.48,
    "out:DH kr savings": 503696.8880255385,
    "out:El kr savings": -187015.36136291386,
    "out:El kr sold": 8526.953086972902,
    "out:El kr saved": 73553.29822,
    "out:El kr return": 82080.2513069729,
    "out:% solar/total": 92,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 36218.049599,
    "out:% savings (space heating)": 91.104324,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 997124.4110739925,
    "out:Eupp": 7.74,
    "out:Etvv": 0,
    "out:Ef": 39.28,
    "out:heatPumpQuantity": 12,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 5826454.320800243,
    "out:Seasonal Variation ROI (%)": 5,
    "out:Seasonal Variation Payback": 20,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 3.959824,
    "out:EL CO2": -3.139731,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -8.689951,
    "out:Bought CO2": 5.55022,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00001002110001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 12.700909,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 53.005483,
    "out:Electricity (inc PV)": 41.500978,
    "out:Total Energy Use Pre PV": 66.005483,
    "out:Total Energy Use Post PV": 54.500978,
    "out:Primary Energy": 82.032142,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 21.9002,
    "out:CO2 Operational/m2": 1.2514,
    "out:Total CO2/m2": 23.1516,
    "out:Total CO2 (tons)": 73.714672,
    "out:Klimatpaverkan": -126.420749,
    "out:Initial Cost/MSEK": 6.020070345800241,
    "out:Running cost/(Apt SEK y)": 8096.510638,
    "out:Running Cost over RSP/MSEK": 2.96302,
    "out:LCP/MSEK": 4.42163,
    "out:ROI% old": 24.778306,
    "out:Payback discounted": 5,
    "out:Atemp": 3184,
    "out:Umean": 0.82,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 55,
    "out:Energy use kWh/m2": 55,
    "out:Energy savings %": 232.7,
    "out:Op energy cost/MSEK": 21,
    "out:El price/MSEK": 18,
    "out:Heating price/MSEK": 3,
    "out:DH+ DHW/kWh/m2": 13,
    "out:El bought/kWh/m2": 2936.250388,
    "out:Return %": 22,
    "out:Return/kSEK/y": 1334,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 1774800,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 88740,
    "out:EAHP (replacement cost)": 1325203.921671023,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 28.356,
    "out:EAHP (El)": 38.135983,
    "out:PV (kr/kWp)": 17461.56255567083,
    "out:PV (kWp installed)": 45.25485,
    "out:PV (m2 panels)": 237.433632,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 42461.284501,
    "out:PV (kWh sold)": 5830.952333,
    "out:PV (% sold (El))": 13.732397,
    "out:PV (kWh self-consumed)": 36630.332168,
    "out:PV (ratio sold/self-consumed)": 0.159184,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 499475.255536,
    "out:EL kWh savings": -92116.206236,
    "out:Sewage heat recovery cost": 273216,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 323989.27,
    "out:DH kr savings": 499475.2555357411,
    "out:El kr savings": -184232.41247224616,
    "out:El kr sold": 8746.428499888509,
    "out:El kr saved": 73260.664336,
    "out:El kr return": 82007.09283588851,
    "out:% solar/total": 92,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 40439.682089,
    "out:% savings (space heating)": 90.06743,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 997124.4110739925,
    "out:Eupp": 9.42,
    "out:Etvv": 0,
    "out:Ef": 39.3,
    "out:heatPumpQuantity": 12,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 6020070.345800241,
    "out:Seasonal Variation ROI (%)": 5,
    "out:Seasonal Variation Payback": 20,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 4.679792,
    "out:EL CO2": -3.428392,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -8.920734,
    "out:Bought CO2": 5.492342,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00001002110001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 9.917068,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 52.413897,
    "out:Electricity (inc PV)": 40.967038,
    "out:Total Energy Use Pre PV": 62.413897,
    "out:Total Energy Use Post PV": 50.967038,
    "out:Primary Energy": 79.210813,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 21.9002,
    "out:CO2 Operational/m2": -0.187772,
    "out:Total CO2/m2": 21.712428,
    "out:Total CO2 (tons)": 69.13235,
    "out:Klimatpaverkan": -131.003071,
    "out:Initial Cost/MSEK": 6.099670320800243,
    "out:Running cost/(Apt SEK y)": 7404.531915,
    "out:Running Cost over RSP/MSEK": 2.70895,
    "out:LCP/MSEK": 4.5961,
    "out:ROI% old": 25.049996,
    "out:Payback discounted": 5,
    "out:Atemp": 3184,
    "out:Umean": 0.82,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 51,
    "out:Energy use kWh/m2": 51,
    "out:Energy savings %": 258.8,
    "out:Op energy cost/MSEK": 19,
    "out:El price/MSEK": 16,
    "out:Heating price/MSEK": 2,
    "out:DH+ DHW/kWh/m2": 10,
    "out:El bought/kWh/m2": 2898.360186,
    "out:Return %": 22,
    "out:Return/kSEK/y": 1367,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 1774800,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 88740,
    "out:EAHP (replacement cost)": 1325203.921671023,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 28.356,
    "out:EAHP (El)": 37.544397,
    "out:PV (kr/kWp)": 17461.56255567083,
    "out:PV (kWp installed)": 45.25485,
    "out:PV (m2 panels)": 237.433632,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 42461.284501,
    "out:PV (kWh sold)": 6014.496912,
    "out:PV (% sold (El))": 14.164661,
    "out:PV (kWh self-consumed)": 36446.787589,
    "out:PV (ratio sold/self-consumed)": 0.165021,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 508339.002613,
    "out:EL kWh savings": -90416.141788,
    "out:Sewage heat recovery cost": 273216,
    "out:Balancing hydronic system cost": 79599.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 336528.46,
    "out:DH kr savings": 508339.0026128214,
    "out:El kr savings": -180832.28357527516,
    "out:El kr sold": 9021.74536812473,
    "out:El kr saved": 72893.575178,
    "out:El kr return": 81915.32054612473,
    "out:% solar/total": 91,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 31575.935011,
    "out:% savings (space heating)": 92.244494,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 997124.4110739925,
    "out:Eupp": 7.03,
    "out:Etvv": 0,
    "out:Ef": 38.66,
    "out:heatPumpQuantity": 12,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 6099670.320800243,
    "out:Seasonal Variation ROI (%)": 5,
    "out:Seasonal Variation Payback": 20,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 3.59984,
    "out:EL CO2": -3.787612,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -9.20921,
    "out:Bought CO2": 5.421598,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00001002120000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 14.304659,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 54.035862,
    "out:Electricity (inc PV)": 38.322457,
    "out:Total Energy Use Pre PV": 68.035862,
    "out:Total Energy Use Post PV": 52.322457,
    "out:Primary Energy": 76.940016,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 35.930756,
    "out:CO2 Operational/m2": -44.919067,
    "out:Total CO2/m2": -8.988311,
    "out:Total CO2 (tons)": -28.618774,
    "out:Klimatpaverkan": -228.754195,
    "out:Initial Cost/MSEK": 6.537067697521493,
    "out:Running cost/(Apt SEK y)": 6942.978723,
    "out:Running Cost over RSP/MSEK": 2.553,
    "out:LCP/MSEK": 4.314652,
    "out:ROI% old": 23.714696,
    "out:Payback discounted": 5,
    "out:Atemp": 3184,
    "out:Umean": 0.82,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 52,
    "out:Energy use kWh/m2": 52,
    "out:Energy savings %": 251.9,
    "out:Op energy cost/MSEK": 18,
    "out:El price/MSEK": 15,
    "out:Heating price/MSEK": 3,
    "out:DH+ DHW/kWh/m2": 14,
    "out:El bought/kWh/m2": 2709.070792,
    "out:Return %": 21,
    "out:Return/kSEK/y": 1388,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 1774800,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 88740,
    "out:EAHP (replacement cost)": 1325203.921671023,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 33.36,
    "out:EAHP (El)": 39.166362,
    "out:PV (kr/kWp)": 17461.48474631725,
    "out:PV (kWp installed)": 90.5097,
    "out:PV (m2 panels)": 474.867263,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 84922.568824,
    "out:PV (kWh sold)": 34891.101679,
    "out:PV (% sold (El))": 41.085782,
    "out:PV (kWh self-consumed)": 50031.467145,
    "out:PV (ratio sold/self-consumed)": 0.697383,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 494368.917072,
    "out:EL kWh savings": -81995.798417,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 382713.97,
    "out:DH kr savings": 494368.9170721336,
    "out:El kr savings": -163991.5968342923,
    "out:El kr sold": 52336.652519123265,
    "out:El kr saved": 100062.93429,
    "out:El kr return": 152399.58680912328,
    "out:% solar/total": 125,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 45546.020552,
    "out:% savings (space heating)": 88.813239,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 997124.4110739925,
    "out:Eupp": 10.23,
    "out:Etvv": 0,
    "out:Ef": 36.05,
    "out:heatPumpQuantity": 12,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 6537067.697521494,
    "out:Seasonal Variation ROI (%)": 5,
    "out:Seasonal Variation Payback": 20,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 5.039776,
    "out:EL CO2": -49.958843,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -55.029556,
    "out:Bought CO2": 5.070713,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00001002120000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 11.375019,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 53.488458,
    "out:Electricity (inc PV)": 37.878905,
    "out:Total Energy Use Pre PV": 64.488458,
    "out:Total Energy Use Post PV": 48.878905,
    "out:Primary Energy": 74.203993,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 35.930756,
    "out:CO2 Operational/m2": -46.591908,
    "out:Total CO2/m2": -10.661152,
    "out:Total CO2 (tons)": -33.945098,
    "out:Klimatpaverkan": -234.080519,
    "out:Initial Cost/MSEK": 6.616667672521494,
    "out:Running cost/(Apt SEK y)": 6258.574468,
    "out:Running Cost over RSP/MSEK": 2.30168,
    "out:LCP/MSEK": 4.486372,
    "out:ROI% old": 23.972015,
    "out:Payback discounted": 5,
    "out:Atemp": 3184,
    "out:Umean": 0.82,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 49,
    "out:Energy use kWh/m2": 49,
    "out:Energy savings %": 273.5,
    "out:Op energy cost/MSEK": 16,
    "out:El price/MSEK": 14,
    "out:Heating price/MSEK": 2,
    "out:DH+ DHW/kWh/m2": 11,
    "out:El bought/kWh/m2": 2677.704954,
    "out:Return %": 21,
    "out:Return/kSEK/y": 1421,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 1774800,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 88740,
    "out:EAHP (replacement cost)": 1325203.921671023,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 33.36,
    "out:EAHP (El)": 38.618958,
    "out:PV (kr/kWp)": 17461.48474631725,
    "out:PV (kWp installed)": 90.5097,
    "out:PV (m2 panels)": 474.867263,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 84922.568824,
    "out:PV (kWh sold)": 35221.767501,
    "out:PV (% sold (El))": 41.475156,
    "out:PV (kWh self-consumed)": 49700.801323,
    "out:PV (ratio sold/self-consumed)": 0.708676,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 503696.888026,
    "out:EL kWh savings": -80583.529274,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 79599.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 395362.48,
    "out:DH kr savings": 503696.8880255385,
    "out:El kr savings": -161167.05854813804,
    "out:El kr sold": 52832.65125132822,
    "out:El kr saved": 99401.602646,
    "out:El kr return": 152234.25389732822,
    "out:% solar/total": 124,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 36218.049599,
    "out:% savings (space heating)": 91.104324,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 997124.4110739925,
    "out:Eupp": 7.74,
    "out:Etvv": 0,
    "out:Ef": 35.5,
    "out:heatPumpQuantity": 12,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 6616667.672521494,
    "out:Seasonal Variation ROI (%)": 5,
    "out:Seasonal Variation Payback": 20,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 3.959824,
    "out:EL CO2": -50.551732,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -55.563788,
    "out:Bought CO2": 5.012056,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00001002120001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 12.700909,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 53.005483,
    "out:Electricity (inc PV)": 37.487926,
    "out:Total Energy Use Pre PV": 66.005483,
    "out:Total Energy Use Post PV": 50.487926,
    "out:Primary Energy": 74.808649,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 36.639299,
    "out:CO2 Operational/m2": -46.397161,
    "out:Total CO2/m2": -9.757862,
    "out:Total CO2 (tons)": -31.069023,
    "out:Klimatpaverkan": -231.204444,
    "out:Initial Cost/MSEK": 6.810283697521493,
    "out:Running cost/(Apt SEK y)": 6605.425532,
    "out:Running Cost over RSP/MSEK": 2.42945,
    "out:LCP/MSEK": 4.164986,
    "out:ROI% old": 23.022473,
    "out:Payback discounted": 5,
    "out:Atemp": 3184,
    "out:Umean": 0.82,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 50,
    "out:Energy use kWh/m2": 50,
    "out:Energy savings %": 266,
    "out:Op energy cost/MSEK": 17,
    "out:El price/MSEK": 14,
    "out:Heating price/MSEK": 3,
    "out:DH+ DHW/kWh/m2": 13,
    "out:El bought/kWh/m2": 2650.071161,
    "out:Return %": 21,
    "out:Return/kSEK/y": 1404,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 1774800,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 88740,
    "out:EAHP (replacement cost)": 1325203.921671023,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 28.356,
    "out:EAHP (El)": 38.135983,
    "out:PV (kr/kWp)": 17461.48474631725,
    "out:PV (kWp installed)": 90.5097,
    "out:PV (m2 panels)": 474.867263,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 84922.568824,
    "out:PV (kWh sold)": 35514.681422,
    "out:PV (% sold (El))": 41.820074,
    "out:PV (kWh self-consumed)": 49407.887402,
    "out:PV (ratio sold/self-consumed)": 0.718806,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 499475.255536,
    "out:EL kWh savings": -79338.652513,
    "out:Sewage heat recovery cost": 273216,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 394069.97,
    "out:DH kr savings": 499475.2555357411,
    "out:El kr savings": -158677.3050252538,
    "out:El kr sold": 53272.022132453974,
    "out:El kr saved": 98815.774804,
    "out:El kr return": 152087.79693645396,
    "out:% solar/total": 123,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 40439.682089,
    "out:% savings (space heating)": 90.06743,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 997124.4110739925,
    "out:Eupp": 9.42,
    "out:Etvv": 0,
    "out:Ef": 35.51,
    "out:heatPumpQuantity": 12,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 6810283.697521493,
    "out:Seasonal Variation ROI (%)": 5,
    "out:Seasonal Variation Payback": 20,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 4.679792,
    "out:EL CO2": -51.076953,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -56.03731,
    "out:Bought CO2": 4.960357,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00001002120001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 9.917068,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 52.413897,
    "out:Electricity (inc PV)": 37.009682,
    "out:Total Energy Use Pre PV": 62.413897,
    "out:Total Energy Use Post PV": 47.009682,
    "out:Primary Energy": 72.087573,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 36.639299,
    "out:CO2 Operational/m2": -48.123189,
    "out:Total CO2/m2": -11.48389,
    "out:Total CO2 (tons)": -36.564695,
    "out:Klimatpaverkan": -236.700116,
    "out:Initial Cost/MSEK": 6.889883672521495,
    "out:Running cost/(Apt SEK y)": 5915.340426,
    "out:Running Cost over RSP/MSEK": 2.17608,
    "out:LCP/MSEK": 4.338756,
    "out:ROI% old": 23.281836,
    "out:Payback discounted": 5,
    "out:Atemp": 3184,
    "out:Umean": 0.82,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 47,
    "out:Energy use kWh/m2": 47,
    "out:Energy savings %": 289.4,
    "out:Op energy cost/MSEK": 15,
    "out:El price/MSEK": 13,
    "out:Heating price/MSEK": 2,
    "out:DH+ DHW/kWh/m2": 10,
    "out:El bought/kWh/m2": 2616.273151,
    "out:Return %": 21,
    "out:Return/kSEK/y": 1437,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 1774800,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 88740,
    "out:EAHP (replacement cost)": 1325203.921671023,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 28.356,
    "out:EAHP (El)": 37.544397,
    "out:PV (kr/kWp)": 17461.48474631725,
    "out:PV (kWp installed)": 90.5097,
    "out:PV (m2 panels)": 474.867263,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 84922.568824,
    "out:PV (kWh sold)": 35875.561806,
    "out:PV (% sold (El))": 42.245027,
    "out:PV (kWh self-consumed)": 49047.007018,
    "out:PV (ratio sold/self-consumed)": 0.731453,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 508339.002613,
    "out:EL kWh savings": -77815.924075,
    "out:Sewage heat recovery cost": 273216,
    "out:Balancing hydronic system cost": 79599.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 406520.5,
    "out:DH kr savings": 508339.0026128214,
    "out:El kr savings": -155631.8481495692,
    "out:El kr sold": 53813.342708880824,
    "out:El kr saved": 98094.014036,
    "out:El kr return": 151907.35674488082,
    "out:% solar/total": 123,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 31575.935011,
    "out:% savings (space heating)": 92.244494,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 997124.4110739925,
    "out:Eupp": 7.03,
    "out:Etvv": 0,
    "out:Ef": 34.94,
    "out:heatPumpQuantity": 12,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 6889883.672521494,
    "out:Seasonal Variation ROI (%)": 5,
    "out:Seasonal Variation Payback": 20,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 3.59984,
    "out:EL CO2": -51.723029,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -56.620134,
    "out:Bought CO2": 4.897105,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01000000000000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 100.956054,
    "out:DHW (DH)": 41.7,
    "out:Property Electricity": 12.57,
    "out:Electricity (inc PV)": 12.57,
    "out:Total Energy Use Pre PV": 155.57,
    "out:Total Energy Use Post PV": 155.57,
    "out:Primary Energy": 118.647375,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 11.211929,
    "out:CO2 Operational/m2": 53.137053,
    "out:Total CO2/m2": 64.348982,
    "out:Total CO2 (tons)": 204.887097,
    "out:Klimatpaverkan": 4.751676,
    "out:Initial Cost/MSEK": 2.576375,
    "out:Running cost/(Apt SEK y)": 30959.319149,
    "out:Running Cost over RSP/MSEK": 11.37402,
    "out:LCP/MSEK": -0.545675,
    "out:ROI% old": 11.260007,
    "out:Payback discounted": 11,
    "out:Atemp": 3184,
    "out:Umean": 0.65,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 156,
    "out:Energy use kWh/m2": 156,
    "out:Energy savings %": 17.3,
    "out:Op energy cost/MSEK": 83,
    "out:El price/MSEK": 51,
    "out:Heating price/MSEK": 28,
    "out:DH+ DHW/kWh/m2": 143,
    "out:El bought/kWh/m2": 879.9,
    "out:Return %": 10,
    "out:Return/kSEK/y": 260,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 41.7,
    "out:EAHP (El)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 85698.199945,
    "out:EL kWh savings": 2.2624e-11,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 85698.2,
    "out:DH kr savings": 85698.1999447695,
    "out:El kr savings": 4.524734779920436e-11,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 321443.977628,
    "out:% savings (space heating)": 21.048716,
    "out:Total_Water Heating (DH)": 132772.760051,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 100.96,
    "out:Etvv": 25,
    "out:Ef": 12.57,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 2576375,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 51.477712,
    "out:EL CO2": 1.659341,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 1.659341,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01000000000000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 96.639869,
    "out:DHW (DH)": 41.7,
    "out:Property Electricity": 12.57,
    "out:Electricity (inc PV)": 12.57,
    "out:Total Energy Use Pre PV": 150.57,
    "out:Total Energy Use Post PV": 150.57,
    "out:Primary Energy": 115.290343,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 11.211929,
    "out:CO2 Operational/m2": 51.337133,
    "out:Total CO2/m2": 62.549062,
    "out:Total CO2 (tons)": 199.156153,
    "out:Klimatpaverkan": -0.979268,
    "out:Initial Cost/MSEK": 2.655974975,
    "out:Running cost/(Apt SEK y)": 29936.361702,
    "out:Running Cost over RSP/MSEK": 10.99796,
    "out:LCP/MSEK": -0.249215,
    "out:ROI% old": 12.94526,
    "out:Payback discounted": 9,
    "out:Atemp": 3184,
    "out:Umean": 0.65,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 151,
    "out:Energy use kWh/m2": 151,
    "out:Energy savings %": 21.2,
    "out:Op energy cost/MSEK": 80,
    "out:El price/MSEK": 49,
    "out:Heating price/MSEK": 27,
    "out:DH+ DHW/kWh/m2": 138,
    "out:El bought/kWh/m2": 879.9,
    "out:Return %": 12,
    "out:Return/kSEK/y": 308,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 41.7,
    "out:EAHP (El)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 99440.926462,
    "out:EL kWh savings": 2.2624e-11,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 79599.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 99440.93,
    "out:DH kr savings": 99440.92646186505,
    "out:El kr savings": 4.524734779920436e-11,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 307701.251111,
    "out:% savings (space heating)": 24.424128,
    "out:Total_Water Heating (DH)": 132772.760051,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 96.64,
    "out:Etvv": 25,
    "out:Ef": 12.57,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 2655974.975,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 49.677792,
    "out:EL CO2": 1.659341,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 1.659341,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01000000000001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 100.956054,
    "out:DHW (DH)": 35.445,
    "out:Property Electricity": 12.57,
    "out:Electricity (inc PV)": 12.57,
    "out:Total Energy Use Pre PV": 148.57,
    "out:Total Energy Use Post PV": 148.57,
    "out:Primary Energy": 116.022375,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 11.920472,
    "out:CO2 Operational/m2": 50.617165,
    "out:Total CO2/m2": 62.537637,
    "out:Total CO2 (tons)": 199.119776,
    "out:Klimatpaverkan": -1.015645,
    "out:Initial Cost/MSEK": 2.849591,
    "out:Running cost/(Apt SEK y)": 29527.191489,
    "out:Running Cost over RSP/MSEK": 10.84754,
    "out:LCP/MSEK": -0.292411,
    "out:ROI% old": 12.819785,
    "out:Payback discounted": 9,
    "out:Atemp": 3184,
    "out:Umean": 0.65,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 149,
    "out:Energy use kWh/m2": 149,
    "out:Energy savings %": 22.8,
    "out:Op energy cost/MSEK": 79,
    "out:El price/MSEK": 48,
    "out:Heating price/MSEK": 27,
    "out:DH+ DHW/kWh/m2": 136,
    "out:El bought/kWh/m2": 879.9,
    "out:Return %": 11,
    "out:Return/kSEK/y": 327,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 35.445,
    "out:EAHP (El)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 105614.113952,
    "out:EL kWh savings": 2.2624e-11,
    "out:Sewage heat recovery cost": 273216,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 105614.11,
    "out:DH kr savings": 105614.11395247949,
    "out:El kr savings": 4.524734779920436e-11,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 321443.977628,
    "out:% savings (space heating)": 21.048716,
    "out:Total_Water Heating (DH)": 112856.846044,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 100.96,
    "out:Etvv": 21.25,
    "out:Ef": 12.57,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 2849591,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 48.957824,
    "out:EL CO2": 1.659341,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 1.659341,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01000000000001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 96.639869,
    "out:DHW (DH)": 35.445,
    "out:Property Electricity": 12.57,
    "out:Electricity (inc PV)": 12.57,
    "out:Total Energy Use Pre PV": 144.57,
    "out:Total Energy Use Post PV": 144.57,
    "out:Primary Energy": 112.665343,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 11.920472,
    "out:CO2 Operational/m2": 49.177229,
    "out:Total CO2/m2": 61.097701,
    "out:Total CO2 (tons)": 194.535021,
    "out:Klimatpaverkan": -5.6004,
    "out:Initial Cost/MSEK": 2.9291909749999996,
    "out:Running cost/(Apt SEK y)": 28708.829787,
    "out:Running Cost over RSP/MSEK": 10.5467,
    "out:LCP/MSEK": -0.071171,
    "out:ROI% old": 13.938612,
    "out:Payback discounted": 9,
    "out:Atemp": 3184,
    "out:Umean": 0.65,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 145,
    "out:Energy use kWh/m2": 145,
    "out:Energy savings %": 26.2,
    "out:Op energy cost/MSEK": 77,
    "out:El price/MSEK": 47,
    "out:Heating price/MSEK": 26,
    "out:DH+ DHW/kWh/m2": 132,
    "out:El bought/kWh/m2": 879.9,
    "out:Return %": 12,
    "out:Return/kSEK/y": 365,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 35.445,
    "out:EAHP (El)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 119356.84047,
    "out:EL kWh savings": 2.2624e-11,
    "out:Sewage heat recovery cost": 273216,
    "out:Balancing hydronic system cost": 79599.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 119356.84,
    "out:DH kr savings": 119356.84046957504,
    "out:El kr savings": 4.524734779920436e-11,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 307701.251111,
    "out:% savings (space heating)": 24.424128,
    "out:Total_Water Heating (DH)": 112856.846044,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 96.64,
    "out:Etvv": 21.25,
    "out:Ef": 12.57,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 2929190.9749999996,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 47.517888,
    "out:EL CO2": 1.659341,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 1.659341,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01000000000100000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 1,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 100.956054,
    "out:DHW (DH)": 41.7,
    "out:Property Electricity": 9.976115,
    "out:Electricity (inc PV)": 9.976115,
    "out:Total Energy Use Pre PV": 152.976115,
    "out:Total Energy Use Post PV": 152.976115,
    "out:Primary Energy": 113.978382,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 11.396431,
    "out:CO2 Operational/m2": 52.794639,
    "out:Total CO2/m2": 64.19107,
    "out:Total CO2 (tons)": 204.384305,
    "out:Klimatpaverkan": 4.248884,
    "out:Initial Cost/MSEK": 2.723167871519825,
    "out:Running cost/(Apt SEK y)": 30607.87234,
    "out:Running Cost over RSP/MSEK": 11.24632,
    "out:LCP/MSEK": -0.564768,
    "out:ROI% old": 11.322947,
    "out:Payback discounted": 11,
    "out:Atemp": 3184,
    "out:Umean": 0.65,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 153,
    "out:Energy use kWh/m2": 153,
    "out:Energy savings %": 19.6,
    "out:Op energy cost/MSEK": 82,
    "out:El price/MSEK": 50,
    "out:Heating price/MSEK": 28,
    "out:DH+ DHW/kWh/m2": 143,
    "out:El bought/kWh/m2": 698.32805,
    "out:Return %": 10,
    "out:Return/kSEK/y": 276,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 41.7,
    "out:EAHP (El)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 85698.199945,
    "out:EL kWh savings": 8258.927355,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 102216.05,
    "out:DH kr savings": 85698.1999447695,
    "out:El kr savings": 16517.854710116386,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 321443.977628,
    "out:% savings (space heating)": 21.048716,
    "out:Total_Water Heating (DH)": 132772.760051,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 100.96,
    "out:Etvv": 25,
    "out:Ef": 9.98,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 2723167.871519825,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333,
    "out:New AHU Cost/SEK": 146792.87151982498,
    "out:DH CO2": 51.477712,
    "out:EL CO2": 1.316927,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 1.316927,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01000000000100100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 1,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 96.639869,
    "out:DHW (DH)": 41.7,
    "out:Property Electricity": 9.976115,
    "out:Electricity (inc PV)": 9.976115,
    "out:Total Energy Use Pre PV": 147.976115,
    "out:Total Energy Use Post PV": 147.976115,
    "out:Primary Energy": 110.62135,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 11.396431,
    "out:CO2 Operational/m2": 50.994719,
    "out:Total CO2/m2": 62.39115,
    "out:Total CO2 (tons)": 198.653362,
    "out:Klimatpaverkan": -1.482059,
    "out:Initial Cost/MSEK": 2.802767846519825,
    "out:Running cost/(Apt SEK y)": 29584.914894,
    "out:Running Cost over RSP/MSEK": 10.87027,
    "out:LCP/MSEK": -0.268318,
    "out:ROI% old": 12.918098,
    "out:Payback discounted": 9,
    "out:Atemp": 3184,
    "out:Umean": 0.65,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 148,
    "out:Energy use kWh/m2": 148,
    "out:Energy savings %": 23.6,
    "out:Op energy cost/MSEK": 80,
    "out:El price/MSEK": 48,
    "out:Heating price/MSEK": 27,
    "out:DH+ DHW/kWh/m2": 138,
    "out:El bought/kWh/m2": 698.32805,
    "out:Return %": 12,
    "out:Return/kSEK/y": 324,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 41.7,
    "out:EAHP (El)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 99440.926462,
    "out:EL kWh savings": 8258.927355,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 79599.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 115958.78,
    "out:DH kr savings": 99440.92646186505,
    "out:El kr savings": 16517.854710116386,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 307701.251111,
    "out:% savings (space heating)": 24.424128,
    "out:Total_Water Heating (DH)": 132772.760051,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 96.64,
    "out:Etvv": 25,
    "out:Ef": 9.98,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 2802767.846519825,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25,
    "out:New AHU Cost/SEK": 146792.87151982498,
    "out:DH CO2": 49.677792,
    "out:EL CO2": 1.316927,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 1.316927,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01000000000101000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 1,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 100.956054,
    "out:DHW (DH)": 35.445,
    "out:Property Electricity": 9.976115,
    "out:Electricity (inc PV)": 9.976115,
    "out:Total Energy Use Pre PV": 145.976115,
    "out:Total Energy Use Post PV": 145.976115,
    "out:Primary Energy": 111.353382,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 12.104974,
    "out:CO2 Operational/m2": 50.274751,
    "out:Total CO2/m2": 62.379725,
    "out:Total CO2 (tons)": 198.616985,
    "out:Klimatpaverkan": -1.518436,
    "out:Initial Cost/MSEK": 2.9963838715198245,
    "out:Running cost/(Apt SEK y)": 29175.744681,
    "out:Running Cost over RSP/MSEK": 10.71985,
    "out:LCP/MSEK": -0.311514,
    "out:ROI% old": 12.800525,
    "out:Payback discounted": 9,
    "out:Atemp": 3184,
    "out:Umean": 0.65,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 146,
    "out:Energy use kWh/m2": 146,
    "out:Energy savings %": 25.3,
    "out:Op energy cost/MSEK": 78,
    "out:El price/MSEK": 48,
    "out:Heating price/MSEK": 27,
    "out:DH+ DHW/kWh/m2": 136,
    "out:El bought/kWh/m2": 698.32805,
    "out:Return %": 11,
    "out:Return/kSEK/y": 343,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 35.445,
    "out:EAHP (El)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 105614.113952,
    "out:EL kWh savings": 8258.927355,
    "out:Sewage heat recovery cost": 273216,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 122131.97,
    "out:DH kr savings": 105614.11395247949,
    "out:El kr savings": 16517.854710116386,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 321443.977628,
    "out:% savings (space heating)": 21.048716,
    "out:Total_Water Heating (DH)": 112856.846044,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 100.96,
    "out:Etvv": 21.25,
    "out:Ef": 9.98,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 2996383.8715198245,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25,
    "out:New AHU Cost/SEK": 146792.87151982498,
    "out:DH CO2": 48.957824,
    "out:EL CO2": 1.316927,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 1.316927,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01000000000101100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 1,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 96.639869,
    "out:DHW (DH)": 35.445,
    "out:Property Electricity": 9.976115,
    "out:Electricity (inc PV)": 9.976115,
    "out:Total Energy Use Pre PV": 141.976115,
    "out:Total Energy Use Post PV": 141.976115,
    "out:Primary Energy": 107.99635,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 12.104974,
    "out:CO2 Operational/m2": 48.834815,
    "out:Total CO2/m2": 60.939789,
    "out:Total CO2 (tons)": 194.03223,
    "out:Klimatpaverkan": -6.103191,
    "out:Initial Cost/MSEK": 3.0759838465198244,
    "out:Running cost/(Apt SEK y)": 28357.382979,
    "out:Running Cost over RSP/MSEK": 10.419,
    "out:LCP/MSEK": -0.090264,
    "out:ROI% old": 13.866504,
    "out:Payback discounted": 9,
    "out:Atemp": 3184,
    "out:Umean": 0.65,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 142,
    "out:Energy use kWh/m2": 142,
    "out:Energy savings %": 28.9,
    "out:Op energy cost/MSEK": 76,
    "out:El price/MSEK": 46,
    "out:Heating price/MSEK": 26,
    "out:DH+ DHW/kWh/m2": 132,
    "out:El bought/kWh/m2": 698.32805,
    "out:Return %": 12,
    "out:Return/kSEK/y": 382,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 35.445,
    "out:EAHP (El)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 119356.84047,
    "out:EL kWh savings": 8258.927355,
    "out:Sewage heat recovery cost": 273216,
    "out:Balancing hydronic system cost": 79599.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 135874.7,
    "out:DH kr savings": 119356.84046957504,
    "out:El kr savings": 16517.854710116386,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 307701.251111,
    "out:% savings (space heating)": 24.424128,
    "out:Total_Water Heating (DH)": 112856.846044,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 96.64,
    "out:Etvv": 21.25,
    "out:Ef": 9.98,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 3075983.8465198246,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25,
    "out:New AHU Cost/SEK": 146792.87151982498,
    "out:DH CO2": 47.517888,
    "out:EL CO2": 1.316927,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 1.316927,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01000000010000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 100.956054,
    "out:DHW (DH)": 41.7,
    "out:Property Electricity": 12.57,
    "out:Electricity (inc PV)": 8.051517,
    "out:Total Energy Use Pre PV": 155.57,
    "out:Total Energy Use Post PV": 151.051517,
    "out:Primary Energy": 110.514106,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 25.951029,
    "out:CO2 Operational/m2": 7.876391,
    "out:Total CO2/m2": 33.82742,
    "out:Total CO2 (tons)": 107.706473,
    "out:Klimatpaverkan": -92.428948,
    "out:Initial Cost/MSEK": 3.3665953942224998,
    "out:Running cost/(Apt SEK y)": 29451.106383,
    "out:Running Cost over RSP/MSEK": 10.83465,
    "out:LCP/MSEK": -0.796525,
    "out:ROI% old": 10.905761,
    "out:Payback discounted": 11,
    "out:Atemp": 3184,
    "out:Umean": 0.65,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 151,
    "out:Energy use kWh/m2": 151,
    "out:Energy savings %": 21.2,
    "out:Op energy cost/MSEK": 80,
    "out:El price/MSEK": 47,
    "out:Heating price/MSEK": 28,
    "out:DH+ DHW/kWh/m2": 143,
    "out:El bought/kWh/m2": 568.258452,
    "out:Return %": 10,
    "out:Return/kSEK/y": 331,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 41.7,
    "out:EAHP (El)": 0,
    "out:PV (kr/kWp)": 17461.56255567083,
    "out:PV (kWp installed)": 45.25485,
    "out:PV (m2 panels)": 237.433632,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 42461.284501,
    "out:PV (kWh sold)": 28074.439514,
    "out:PV (% sold (El))": 66.117735,
    "out:PV (kWh self-consumed)": 14386.844987,
    "out:PV (ratio sold/self-consumed)": 1.951397,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 85698.199945,
    "out:EL kWh savings": 14386.845543,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 156583.55,
    "out:DH kr savings": 85698.1999447695,
    "out:El kr savings": 28773.691086586638,
    "out:El kr sold": 42111.65927092506,
    "out:El kr saved": 28773.689974,
    "out:El kr return": 70885.34924492506,
    "out:% solar/total": 36,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 321443.977628,
    "out:% savings (space heating)": 21.048716,
    "out:Total_Water Heating (DH)": 132772.760051,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 100.96,
    "out:Etvv": 25,
    "out:Ef": 8.05,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 3366595.3942225,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 51.477712,
    "out:EL CO2": -43.601321,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -44.666212,
    "out:Bought CO2": 1.064891,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01000000010000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 96.639869,
    "out:DHW (DH)": 41.7,
    "out:Property Electricity": 12.57,
    "out:Electricity (inc PV)": 8.051517,
    "out:Total Energy Use Pre PV": 150.57,
    "out:Total Energy Use Post PV": 146.051517,
    "out:Primary Energy": 107.157074,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 25.951029,
    "out:CO2 Operational/m2": 6.076471,
    "out:Total CO2/m2": 32.0275,
    "out:Total CO2 (tons)": 101.975529,
    "out:Klimatpaverkan": -98.159892,
    "out:Initial Cost/MSEK": 3.4461953692225,
    "out:Running cost/(Apt SEK y)": 28428.170213,
    "out:Running Cost over RSP/MSEK": 10.4586,
    "out:LCP/MSEK": -0.500075,
    "out:ROI% old": 12.212723,
    "out:Payback discounted": 10,
    "out:Atemp": 3184,
    "out:Umean": 0.65,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 146,
    "out:Energy use kWh/m2": 146,
    "out:Energy savings %": 25.3,
    "out:Op energy cost/MSEK": 77,
    "out:El price/MSEK": 46,
    "out:Heating price/MSEK": 27,
    "out:DH+ DHW/kWh/m2": 138,
    "out:El bought/kWh/m2": 568.258452,
    "out:Return %": 11,
    "out:Return/kSEK/y": 379,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 41.7,
    "out:EAHP (El)": 0,
    "out:PV (kr/kWp)": 17461.56255567083,
    "out:PV (kWp installed)": 45.25485,
    "out:PV (m2 panels)": 237.433632,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 42461.284501,
    "out:PV (kWh sold)": 28074.439514,
    "out:PV (% sold (El))": 66.117735,
    "out:PV (kWh self-consumed)": 14386.844987,
    "out:PV (ratio sold/self-consumed)": 1.951397,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 99440.926462,
    "out:EL kWh savings": 14386.845543,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 79599.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 170326.28,
    "out:DH kr savings": 99440.92646186505,
    "out:El kr savings": 28773.691086586638,
    "out:El kr sold": 42111.65927092506,
    "out:El kr saved": 28773.689974,
    "out:El kr return": 70885.34924492506,
    "out:% solar/total": 36,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 307701.251111,
    "out:% savings (space heating)": 24.424128,
    "out:Total_Water Heating (DH)": 132772.760051,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 96.64,
    "out:Etvv": 25,
    "out:Ef": 8.05,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 3446195.3692225,
    "out:Seasonal Variation ROI (%)": 5,
    "out:Seasonal Variation Payback": 20,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 49.677792,
    "out:EL CO2": -43.601321,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -44.666212,
    "out:Bought CO2": 1.064891,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01000000010001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 100.956054,
    "out:DHW (DH)": 35.445,
    "out:Property Electricity": 12.57,
    "out:Electricity (inc PV)": 8.051517,
    "out:Total Energy Use Pre PV": 148.57,
    "out:Total Energy Use Post PV": 144.051517,
    "out:Primary Energy": 107.889106,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 26.659572,
    "out:CO2 Operational/m2": 5.356503,
    "out:Total CO2/m2": 32.016075,
    "out:Total CO2 (tons)": 101.939152,
    "out:Klimatpaverkan": -98.196269,
    "out:Initial Cost/MSEK": 3.6398113942225,
    "out:Running cost/(Apt SEK y)": 28018.978723,
    "out:Running Cost over RSP/MSEK": 10.30817,
    "out:LCP/MSEK": -0.543261,
    "out:ROI% old": 12.153495,
    "out:Payback discounted": 10,
    "out:Atemp": 3184,
    "out:Umean": 0.65,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 144,
    "out:Energy use kWh/m2": 144,
    "out:Energy savings %": 27.1,
    "out:Op energy cost/MSEK": 76,
    "out:El price/MSEK": 45,
    "out:Heating price/MSEK": 27,
    "out:DH+ DHW/kWh/m2": 136,
    "out:El bought/kWh/m2": 568.258452,
    "out:Return %": 11,
    "out:Return/kSEK/y": 398,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 35.445,
    "out:EAHP (El)": 0,
    "out:PV (kr/kWp)": 17461.56255567083,
    "out:PV (kWp installed)": 45.25485,
    "out:PV (m2 panels)": 237.433632,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 42461.284501,
    "out:PV (kWh sold)": 28074.439514,
    "out:PV (% sold (El))": 66.117735,
    "out:PV (kWh self-consumed)": 14386.844987,
    "out:PV (ratio sold/self-consumed)": 1.951397,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 105614.113952,
    "out:EL kWh savings": 14386.845543,
    "out:Sewage heat recovery cost": 273216,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 176499.46,
    "out:DH kr savings": 105614.11395247949,
    "out:El kr savings": 28773.691086586638,
    "out:El kr sold": 42111.65927092506,
    "out:El kr saved": 28773.689974,
    "out:El kr return": 70885.34924492506,
    "out:% solar/total": 36,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 321443.977628,
    "out:% savings (space heating)": 21.048716,
    "out:Total_Water Heating (DH)": 112856.846044,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 100.96,
    "out:Etvv": 21.25,
    "out:Ef": 8.05,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 3639811.3942225,
    "out:Seasonal Variation ROI (%)": 5,
    "out:Seasonal Variation Payback": 20,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 48.957824,
    "out:EL CO2": -43.601321,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -44.666212,
    "out:Bought CO2": 1.064891,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01000000010001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 96.639869,
    "out:DHW (DH)": 35.445,
    "out:Property Electricity": 12.57,
    "out:Electricity (inc PV)": 8.051517,
    "out:Total Energy Use Pre PV": 144.57,
    "out:Total Energy Use Post PV": 140.051517,
    "out:Primary Energy": 104.532074,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 26.659572,
    "out:CO2 Operational/m2": 3.916567,
    "out:Total CO2/m2": 30.576139,
    "out:Total CO2 (tons)": 97.354397,
    "out:Klimatpaverkan": -102.781024,
    "out:Initial Cost/MSEK": 3.7194113692224997,
    "out:Running cost/(Apt SEK y)": 27200.638298,
    "out:Running Cost over RSP/MSEK": 10.00733,
    "out:LCP/MSEK": -0.322021,
    "out:ROI% old": 13.048877,
    "out:Payback discounted": 9,
    "out:Atemp": 3184,
    "out:Umean": 0.65,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 140,
    "out:Energy use kWh/m2": 140,
    "out:Energy savings %": 30.7,
    "out:Op energy cost/MSEK": 74,
    "out:El price/MSEK": 44,
    "out:Heating price/MSEK": 26,
    "out:DH+ DHW/kWh/m2": 132,
    "out:El bought/kWh/m2": 568.258452,
    "out:Return %": 12,
    "out:Return/kSEK/y": 436,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 35.445,
    "out:EAHP (El)": 0,
    "out:PV (kr/kWp)": 17461.56255567083,
    "out:PV (kWp installed)": 45.25485,
    "out:PV (m2 panels)": 237.433632,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 42461.284501,
    "out:PV (kWh sold)": 28074.439514,
    "out:PV (% sold (El))": 66.117735,
    "out:PV (kWh self-consumed)": 14386.844987,
    "out:PV (ratio sold/self-consumed)": 1.951397,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 119356.84047,
    "out:EL kWh savings": 14386.845543,
    "out:Sewage heat recovery cost": 273216,
    "out:Balancing hydronic system cost": 79599.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 190242.19,
    "out:DH kr savings": 119356.84046957504,
    "out:El kr savings": 28773.691086586638,
    "out:El kr sold": 42111.65927092506,
    "out:El kr saved": 28773.689974,
    "out:El kr return": 70885.34924492506,
    "out:% solar/total": 36,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 307701.251111,
    "out:% savings (space heating)": 24.424128,
    "out:Total_Water Heating (DH)": 112856.846044,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 96.64,
    "out:Etvv": 21.25,
    "out:Ef": 8.05,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 3719411.3692225,
    "out:Seasonal Variation ROI (%)": 5,
    "out:Seasonal Variation Payback": 20,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 47.517888,
    "out:EL CO2": -43.601321,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -44.666212,
    "out:Bought CO2": 1.064891,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01000000010100000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 1,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 100.956054,
    "out:DHW (DH)": 41.7,
    "out:Property Electricity": 9.976115,
    "out:Electricity (inc PV)": 6.231337,
    "out:Total Energy Use Pre PV": 152.976115,
    "out:Total Energy Use Post PV": 149.231337,
    "out:Primary Energy": 107.237782,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 26.135531,
    "out:CO2 Operational/m2": 3.646263,
    "out:Total CO2/m2": 29.781794,
    "out:Total CO2 (tons)": 94.825204,
    "out:Klimatpaverkan": -105.310217,
    "out:Initial Cost/MSEK": 3.5133882657423245,
    "out:Running cost/(Apt SEK y)": 29125.87234,
    "out:Running Cost over RSP/MSEK": 10.71642,
    "out:LCP/MSEK": -0.825088,
    "out:ROI% old": 10.93084,
    "out:Payback discounted": 11,
    "out:Atemp": 3184,
    "out:Umean": 0.65,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 149,
    "out:Energy use kWh/m2": 149,
    "out:Energy savings %": 22.8,
    "out:Op energy cost/MSEK": 79,
    "out:El price/MSEK": 47,
    "out:Heating price/MSEK": 28,
    "out:DH+ DHW/kWh/m2": 143,
    "out:El bought/kWh/m2": 439.497378,
    "out:Return %": 10,
    "out:Return/kSEK/y": 346,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 41.7,
    "out:EAHP (El)": 0,
    "out:PV (kr/kWp)": 17461.56255567083,
    "out:PV (kWp installed)": 45.25485,
    "out:PV (m2 panels)": 237.433632,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 42461.284501,
    "out:PV (kWh sold)": 30537.914073,
    "out:PV (% sold (El))": 71.919431,
    "out:PV (kWh self-consumed)": 11923.370428,
    "out:PV (ratio sold/self-consumed)": 2.561181,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 85698.199945,
    "out:EL kWh savings": 20182.29692,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 171869.66,
    "out:DH kr savings": 85698.1999447695,
    "out:El kr savings": 40364.59383912171,
    "out:El kr sold": 45806.87111012268,
    "out:El kr saved": 23846.740856,
    "out:El kr return": 69653.61196612268,
    "out:% solar/total": 30,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 321443.977628,
    "out:% savings (space heating)": 21.048716,
    "out:Total_Water Heating (DH)": 132772.760051,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 100.96,
    "out:Etvv": 25,
    "out:Ef": 6.23,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 3513388.2657423248,
    "out:Seasonal Variation ROI (%)": 5,
    "out:Seasonal Variation Payback": 20,
    "out:New AHU Cost/SEK": 146792.87151982498,
    "out:DH CO2": 51.477712,
    "out:EL CO2": -47.831449,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -48.655512,
    "out:Bought CO2": 0.824063,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01000000010100100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 1,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 96.639869,
    "out:DHW (DH)": 41.7,
    "out:Property Electricity": 9.976115,
    "out:Electricity (inc PV)": 6.231337,
    "out:Total Energy Use Pre PV": 147.976115,
    "out:Total Energy Use Post PV": 144.231337,
    "out:Primary Energy": 103.88075,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 26.135531,
    "out:CO2 Operational/m2": 1.846343,
    "out:Total CO2/m2": 27.981874,
    "out:Total CO2 (tons)": 89.09426,
    "out:Klimatpaverkan": -111.041161,
    "out:Initial Cost/MSEK": 3.592988240742325,
    "out:Running cost/(Apt SEK y)": 28102.93617,
    "out:Running Cost over RSP/MSEK": 10.34037,
    "out:LCP/MSEK": -0.528638,
    "out:ROI% old": 12.18385,
    "out:Payback discounted": 10,
    "out:Atemp": 3184,
    "out:Umean": 0.65,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 144,
    "out:Energy use kWh/m2": 144,
    "out:Energy savings %": 27.1,
    "out:Op energy cost/MSEK": 76,
    "out:El price/MSEK": 45,
    "out:Heating price/MSEK": 27,
    "out:DH+ DHW/kWh/m2": 138,
    "out:El bought/kWh/m2": 439.497378,
    "out:Return %": 11,
    "out:Return/kSEK/y": 394,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 41.7,
    "out:EAHP (El)": 0,
    "out:PV (kr/kWp)": 17461.56255567083,
    "out:PV (kWp installed)": 45.25485,
    "out:PV (m2 panels)": 237.433632,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 42461.284501,
    "out:PV (kWh sold)": 30537.914073,
    "out:PV (% sold (El))": 71.919431,
    "out:PV (kWh self-consumed)": 11923.370428,
    "out:PV (ratio sold/self-consumed)": 2.561181,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 99440.926462,
    "out:EL kWh savings": 20182.29692,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 79599.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 185612.39,
    "out:DH kr savings": 99440.92646186505,
    "out:El kr savings": 40364.59383912171,
    "out:El kr sold": 45806.87111012268,
    "out:El kr saved": 23846.740856,
    "out:El kr return": 69653.61196612268,
    "out:% solar/total": 30,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 307701.251111,
    "out:% savings (space heating)": 24.424128,
    "out:Total_Water Heating (DH)": 132772.760051,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 96.64,
    "out:Etvv": 25,
    "out:Ef": 6.23,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 3592988.240742325,
    "out:Seasonal Variation ROI (%)": 5,
    "out:Seasonal Variation Payback": 20,
    "out:New AHU Cost/SEK": 146792.87151982498,
    "out:DH CO2": 49.677792,
    "out:EL CO2": -47.831449,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -48.655512,
    "out:Bought CO2": 0.824063,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01000000010101000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 1,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 100.956054,
    "out:DHW (DH)": 35.445,
    "out:Property Electricity": 9.976115,
    "out:Electricity (inc PV)": 6.231337,
    "out:Total Energy Use Pre PV": 145.976115,
    "out:Total Energy Use Post PV": 142.231337,
    "out:Primary Energy": 104.612782,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 26.844074,
    "out:CO2 Operational/m2": 1.126375,
    "out:Total CO2/m2": 27.970449,
    "out:Total CO2 (tons)": 89.057883,
    "out:Klimatpaverkan": -111.077538,
    "out:Initial Cost/MSEK": 3.7866042657423247,
    "out:Running cost/(Apt SEK y)": 27693.744681,
    "out:Running Cost over RSP/MSEK": 10.18994,
    "out:LCP/MSEK": -0.571824,
    "out:ROI% old": 12.128394,
    "out:Payback discounted": 10,
    "out:Atemp": 3184,
    "out:Umean": 0.65,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 142,
    "out:Energy use kWh/m2": 142,
    "out:Energy savings %": 28.9,
    "out:Op energy cost/MSEK": 75,
    "out:El price/MSEK": 44,
    "out:Heating price/MSEK": 27,
    "out:DH+ DHW/kWh/m2": 136,
    "out:El bought/kWh/m2": 439.497378,
    "out:Return %": 11,
    "out:Return/kSEK/y": 413,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 35.445,
    "out:EAHP (El)": 0,
    "out:PV (kr/kWp)": 17461.56255567083,
    "out:PV (kWp installed)": 45.25485,
    "out:PV (m2 panels)": 237.433632,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 42461.284501,
    "out:PV (kWh sold)": 30537.914073,
    "out:PV (% sold (El))": 71.919431,
    "out:PV (kWh self-consumed)": 11923.370428,
    "out:PV (ratio sold/self-consumed)": 2.561181,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 105614.113952,
    "out:EL kWh savings": 20182.29692,
    "out:Sewage heat recovery cost": 273216,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 191785.58,
    "out:DH kr savings": 105614.11395247949,
    "out:El kr savings": 40364.59383912171,
    "out:El kr sold": 45806.87111012268,
    "out:El kr saved": 23846.740856,
    "out:El kr return": 69653.61196612268,
    "out:% solar/total": 30,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 321443.977628,
    "out:% savings (space heating)": 21.048716,
    "out:Total_Water Heating (DH)": 112856.846044,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 100.96,
    "out:Etvv": 21.25,
    "out:Ef": 6.23,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 3786604.2657423248,
    "out:Seasonal Variation ROI (%)": 5,
    "out:Seasonal Variation Payback": 20,
    "out:New AHU Cost/SEK": 146792.87151982498,
    "out:DH CO2": 48.957824,
    "out:EL CO2": -47.831449,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -48.655512,
    "out:Bought CO2": 0.824063,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01000000010101100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 1,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 96.639869,
    "out:DHW (DH)": 35.445,
    "out:Property Electricity": 9.976115,
    "out:Electricity (inc PV)": 6.231337,
    "out:Total Energy Use Pre PV": 141.976115,
    "out:Total Energy Use Post PV": 138.231337,
    "out:Primary Energy": 101.25575,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 26.844074,
    "out:CO2 Operational/m2": -0.313561,
    "out:Total CO2/m2": 26.530513,
    "out:Total CO2 (tons)": 84.473128,
    "out:Klimatpaverkan": -115.662293,
    "out:Initial Cost/MSEK": 3.866204240742325,
    "out:Running cost/(Apt SEK y)": 26875.404255,
    "out:Running Cost over RSP/MSEK": 9.8891,
    "out:LCP/MSEK": -0.350584,
    "out:ROI% old": 12.990297,
    "out:Payback discounted": 9,
    "out:Atemp": 3184,
    "out:Umean": 0.65,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 138,
    "out:Energy use kWh/m2": 138,
    "out:Energy savings %": 32.6,
    "out:Op energy cost/MSEK": 73,
    "out:El price/MSEK": 43,
    "out:Heating price/MSEK": 26,
    "out:DH+ DHW/kWh/m2": 132,
    "out:El bought/kWh/m2": 439.497378,
    "out:Return %": 12,
    "out:Return/kSEK/y": 452,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 35.445,
    "out:EAHP (El)": 0,
    "out:PV (kr/kWp)": 17461.56255567083,
    "out:PV (kWp installed)": 45.25485,
    "out:PV (m2 panels)": 237.433632,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 42461.284501,
    "out:PV (kWh sold)": 30537.914073,
    "out:PV (% sold (El))": 71.919431,
    "out:PV (kWh self-consumed)": 11923.370428,
    "out:PV (ratio sold/self-consumed)": 2.561181,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 119356.84047,
    "out:EL kWh savings": 20182.29692,
    "out:Sewage heat recovery cost": 273216,
    "out:Balancing hydronic system cost": 79599.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 205528.31,
    "out:DH kr savings": 119356.84046957504,
    "out:El kr savings": 40364.59383912171,
    "out:El kr sold": 45806.87111012268,
    "out:El kr saved": 23846.740856,
    "out:El kr return": 69653.61196612268,
    "out:% solar/total": 30,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 307701.251111,
    "out:% savings (space heating)": 24.424128,
    "out:Total_Water Heating (DH)": 112856.846044,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 96.64,
    "out:Etvv": 21.25,
    "out:Ef": 6.23,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 3866204.240742325,
    "out:Seasonal Variation ROI (%)": 5,
    "out:Seasonal Variation Payback": 20,
    "out:New AHU Cost/SEK": 146792.87151982498,
    "out:DH CO2": 47.517888,
    "out:EL CO2": -47.831449,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -48.655512,
    "out:Bought CO2": 0.824063,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01000000020000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 100.956054,
    "out:DHW (DH)": 41.7,
    "out:Property Electricity": 12.57,
    "out:Electricity (inc PV)": 7.530921,
    "out:Total Energy Use Pre PV": 155.57,
    "out:Total Energy Use Post PV": 150.530921,
    "out:Primary Energy": 109.577033,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 40.690128,
    "out:CO2 Operational/m2": -57.478926,
    "out:Total CO2/m2": -16.788798,
    "out:Total CO2 (tons)": -53.455517,
    "out:Klimatpaverkan": -253.590938,
    "out:Initial Cost/MSEK": 4.15680874594375,
    "out:Running cost/(Apt SEK y)": 28078.319149,
    "out:Running Cost over RSP/MSEK": 10.34422,
    "out:LCP/MSEK": -1.096309,
    "out:ROI% old": 10.518027,
    "out:Payback discounted": 12,
    "out:Atemp": 3184,
    "out:Umean": 0.65,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 151,
    "out:Energy use kWh/m2": 151,
    "out:Energy savings %": 21.2,
    "out:Op energy cost/MSEK": 77,
    "out:El price/MSEK": 44,
    "out:Heating price/MSEK": 28,
    "out:DH+ DHW/kWh/m2": 143,
    "out:El bought/kWh/m2": 530.25352,
    "out:Return %": 10,
    "out:Return/kSEK/y": 395,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 41.7,
    "out:EAHP (El)": 0,
    "out:PV (kr/kWp)": 17461.48474631725,
    "out:PV (kWp installed)": 90.5097,
    "out:PV (m2 panels)": 474.867263,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 84922.568824,
    "out:PV (kWh sold)": 68878.145463,
    "out:PV (% sold (El))": 81.106997,
    "out:PV (kWh self-consumed)": 16044.423361,
    "out:PV (ratio sold/self-consumed)": 4.292965,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 85698.199945,
    "out:EL kWh savings": 16044.422709,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 221104.26,
    "out:DH kr savings": 85698.1999447695,
    "out:El kr savings": 32088.845417124598,
    "out:El kr sold": 103317.21819422109,
    "out:El kr saved": 32088.846722,
    "out:El kr return": 135406.06491622108,
    "out:% solar/total": 40,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 321443.977628,
    "out:% savings (space heating)": 21.048716,
    "out:Total_Water Heating (DH)": 132772.760051,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 100.96,
    "out:Etvv": 25,
    "out:Ef": 7.53,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 4156808.7459437503,
    "out:Seasonal Variation ROI (%)": 5,
    "out:Seasonal Variation Payback": 20,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 51.477712,
    "out:EL CO2": -108.956638,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -109.952146,
    "out:Bought CO2": 0.995508,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01000000020000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 96.639869,
    "out:DHW (DH)": 41.7,
    "out:Property Electricity": 12.57,
    "out:Electricity (inc PV)": 7.530921,
    "out:Total Energy Use Pre PV": 150.57,
    "out:Total Energy Use Post PV": 145.530921,
    "out:Primary Energy": 106.220001,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 40.690128,
    "out:CO2 Operational/m2": -59.278846,
    "out:Total CO2/m2": -18.588718,
    "out:Total CO2 (tons)": -59.18646,
    "out:Klimatpaverkan": -259.321881,
    "out:Initial Cost/MSEK": 4.23640872094375,
    "out:Running cost/(Apt SEK y)": 27055.382979,
    "out:Running Cost over RSP/MSEK": 9.96816,
    "out:LCP/MSEK": -0.799849,
    "out:ROI% old": 11.588522,
    "out:Payback discounted": 10,
    "out:Atemp": 3184,
    "out:Umean": 0.65,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 146,
    "out:Energy use kWh/m2": 146,
    "out:Energy savings %": 25.3,
    "out:Op energy cost/MSEK": 74,
    "out:El price/MSEK": 43,
    "out:Heating price/MSEK": 27,
    "out:DH+ DHW/kWh/m2": 138,
    "out:El bought/kWh/m2": 530.25352,
    "out:Return %": 10,
    "out:Return/kSEK/y": 443,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 41.7,
    "out:EAHP (El)": 0,
    "out:PV (kr/kWp)": 17461.48474631725,
    "out:PV (kWp installed)": 90.5097,
    "out:PV (m2 panels)": 474.867263,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 84922.568824,
    "out:PV (kWh sold)": 68878.145463,
    "out:PV (% sold (El))": 81.106997,
    "out:PV (kWh self-consumed)": 16044.423361,
    "out:PV (ratio sold/self-consumed)": 4.292965,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 99440.926462,
    "out:EL kWh savings": 16044.422709,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 79599.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 234846.99,
    "out:DH kr savings": 99440.92646186505,
    "out:El kr savings": 32088.845417124598,
    "out:El kr sold": 103317.21819422109,
    "out:El kr saved": 32088.846722,
    "out:El kr return": 135406.06491622108,
    "out:% solar/total": 40,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 307701.251111,
    "out:% savings (space heating)": 24.424128,
    "out:Total_Water Heating (DH)": 132772.760051,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 96.64,
    "out:Etvv": 25,
    "out:Ef": 7.53,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 4236408.72094375,
    "out:Seasonal Variation ROI (%)": 5,
    "out:Seasonal Variation Payback": 20,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 49.677792,
    "out:EL CO2": -108.956638,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -109.952146,
    "out:Bought CO2": 0.995508,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01000000020001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 100.956054,
    "out:DHW (DH)": 35.445,
    "out:Property Electricity": 12.57,
    "out:Electricity (inc PV)": 7.530921,
    "out:Total Energy Use Pre PV": 148.57,
    "out:Total Energy Use Post PV": 143.530921,
    "out:Primary Energy": 106.952033,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 41.398671,
    "out:CO2 Operational/m2": -59.998814,
    "out:Total CO2/m2": -18.600143,
    "out:Total CO2 (tons)": -59.222837,
    "out:Klimatpaverkan": -259.358259,
    "out:Initial Cost/MSEK": 4.43002474594375,
    "out:Running cost/(Apt SEK y)": 26646.212766,
    "out:Running Cost over RSP/MSEK": 9.81774,
    "out:LCP/MSEK": -0.843045,
    "out:ROI% old": 11.567107,
    "out:Payback discounted": 10,
    "out:Atemp": 3184,
    "out:Umean": 0.65,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 144,
    "out:Energy use kWh/m2": 144,
    "out:Energy savings %": 27.1,
    "out:Op energy cost/MSEK": 73,
    "out:El price/MSEK": 42,
    "out:Heating price/MSEK": 27,
    "out:DH+ DHW/kWh/m2": 136,
    "out:El bought/kWh/m2": 530.25352,
    "out:Return %": 10,
    "out:Return/kSEK/y": 462,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 35.445,
    "out:EAHP (El)": 0,
    "out:PV (kr/kWp)": 17461.48474631725,
    "out:PV (kWp installed)": 90.5097,
    "out:PV (m2 panels)": 474.867263,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 84922.568824,
    "out:PV (kWh sold)": 68878.145463,
    "out:PV (% sold (El))": 81.106997,
    "out:PV (kWh self-consumed)": 16044.423361,
    "out:PV (ratio sold/self-consumed)": 4.292965,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 105614.113952,
    "out:EL kWh savings": 16044.422709,
    "out:Sewage heat recovery cost": 273216,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 241020.18,
    "out:DH kr savings": 105614.11395247949,
    "out:El kr savings": 32088.845417124598,
    "out:El kr sold": 103317.21819422109,
    "out:El kr saved": 32088.846722,
    "out:El kr return": 135406.06491622108,
    "out:% solar/total": 40,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 321443.977628,
    "out:% savings (space heating)": 21.048716,
    "out:Total_Water Heating (DH)": 112856.846044,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 100.96,
    "out:Etvv": 21.25,
    "out:Ef": 7.53,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 4430024.74594375,
    "out:Seasonal Variation ROI (%)": 5,
    "out:Seasonal Variation Payback": 20,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 48.957824,
    "out:EL CO2": -108.956638,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -109.952146,
    "out:Bought CO2": 0.995508,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01000000020001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 96.639869,
    "out:DHW (DH)": 35.445,
    "out:Property Electricity": 12.57,
    "out:Electricity (inc PV)": 7.530921,
    "out:Total Energy Use Pre PV": 144.57,
    "out:Total Energy Use Post PV": 139.530921,
    "out:Primary Energy": 103.595001,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 41.398671,
    "out:CO2 Operational/m2": -61.43875,
    "out:Total CO2/m2": -20.040079,
    "out:Total CO2 (tons)": -63.807592,
    "out:Klimatpaverkan": -263.943013,
    "out:Initial Cost/MSEK": 4.50962472094375,
    "out:Running cost/(Apt SEK y)": 25827.851064,
    "out:Running Cost over RSP/MSEK": 9.5169,
    "out:LCP/MSEK": -0.621805,
    "out:ROI% old": 12.315944,
    "out:Payback discounted": 10,
    "out:Atemp": 3184,
    "out:Umean": 0.65,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 140,
    "out:Energy use kWh/m2": 140,
    "out:Energy savings %": 30.7,
    "out:Op energy cost/MSEK": 71,
    "out:El price/MSEK": 41,
    "out:Heating price/MSEK": 26,
    "out:DH+ DHW/kWh/m2": 132,
    "out:El bought/kWh/m2": 530.25352,
    "out:Return %": 11,
    "out:Return/kSEK/y": 501,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 35.445,
    "out:EAHP (El)": 0,
    "out:PV (kr/kWp)": 17461.48474631725,
    "out:PV (kWp installed)": 90.5097,
    "out:PV (m2 panels)": 474.867263,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 84922.568824,
    "out:PV (kWh sold)": 68878.145463,
    "out:PV (% sold (El))": 81.106997,
    "out:PV (kWh self-consumed)": 16044.423361,
    "out:PV (ratio sold/self-consumed)": 4.292965,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 119356.84047,
    "out:EL kWh savings": 16044.422709,
    "out:Sewage heat recovery cost": 273216,
    "out:Balancing hydronic system cost": 79599.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 254762.9,
    "out:DH kr savings": 119356.84046957504,
    "out:El kr savings": 32088.845417124598,
    "out:El kr sold": 103317.21819422109,
    "out:El kr saved": 32088.846722,
    "out:El kr return": 135406.06491622108,
    "out:% solar/total": 40,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 307701.251111,
    "out:% savings (space heating)": 24.424128,
    "out:Total_Water Heating (DH)": 112856.846044,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 96.64,
    "out:Etvv": 21.25,
    "out:Ef": 7.53,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 4509624.72094375,
    "out:Seasonal Variation ROI (%)": 5,
    "out:Seasonal Variation Payback": 20,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 47.517888,
    "out:EL CO2": -108.956638,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -109.952146,
    "out:Bought CO2": 0.995508,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01000000020100000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 1,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 100.956054,
    "out:DHW (DH)": 41.7,
    "out:Property Electricity": 9.976115,
    "out:Electricity (inc PV)": 5.879188,
    "out:Total Energy Use Pre PV": 152.976115,
    "out:Total Energy Use Post PV": 148.879188,
    "out:Primary Energy": 106.603913,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 40.87463,
    "out:CO2 Operational/m2": -62.564652,
    "out:Total CO2/m2": -21.690022,
    "out:Total CO2 (tons)": -69.061009,
    "out:Klimatpaverkan": -269.19643,
    "out:Initial Cost/MSEK": 4.303601617463575,
    "out:Running cost/(Apt SEK y)": 27758.808511,
    "out:Running Cost over RSP/MSEK": 10.22807,
    "out:LCP/MSEK": -1.126952,
    "out:ROI% old": 10.544822,
    "out:Payback discounted": 11,
    "out:Atemp": 3184,
    "out:Umean": 0.65,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 149,
    "out:Energy use kWh/m2": 149,
    "out:Energy savings %": 22.8,
    "out:Op energy cost/MSEK": 76,
    "out:El price/MSEK": 44,
    "out:Heating price/MSEK": 28,
    "out:DH+ DHW/kWh/m2": 143,
    "out:El bought/kWh/m2": 413.65837,
    "out:Return %": 10,
    "out:Return/kSEK/y": 410,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 41.7,
    "out:EAHP (El)": 0,
    "out:PV (kr/kWp)": 17461.48474631725,
    "out:PV (kWp installed)": 90.5097,
    "out:PV (m2 panels)": 474.867263,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 84922.568824,
    "out:PV (kWh sold)": 71877.957046,
    "out:PV (% sold (El))": 84.639405,
    "out:PV (kWh self-consumed)": 13044.611778,
    "out:PV (ratio sold/self-consumed)": 5.510165,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 85698.199945,
    "out:EL kWh savings": 21303.538998,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 236122.21,
    "out:DH kr savings": 85698.1999447695,
    "out:El kr savings": 42607.077996404216,
    "out:El kr sold": 107816.93556907076,
    "out:El kr saved": 26089.223556,
    "out:El kr return": 133906.15912507076,
    "out:% solar/total": 33,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 321443.977628,
    "out:% savings (space heating)": 21.048716,
    "out:Total_Water Heating (DH)": 132772.760051,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 100.96,
    "out:Etvv": 25,
    "out:Ef": 5.88,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 4303601.617463575,
    "out:Seasonal Variation ROI (%)": 5,
    "out:Seasonal Variation Payback": 20,
    "out:New AHU Cost/SEK": 146792.87151982498,
    "out:DH CO2": 51.477712,
    "out:EL CO2": -114.042364,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -114.819396,
    "out:Bought CO2": 0.777032,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01000000020100100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 1,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 96.639869,
    "out:DHW (DH)": 41.7,
    "out:Property Electricity": 9.976115,
    "out:Electricity (inc PV)": 5.879188,
    "out:Total Energy Use Pre PV": 147.976115,
    "out:Total Energy Use Post PV": 143.879188,
    "out:Primary Energy": 103.246881,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 40.87463,
    "out:CO2 Operational/m2": -64.364572,
    "out:Total CO2/m2": -23.489942,
    "out:Total CO2 (tons)": -74.791953,
    "out:Klimatpaverkan": -274.927374,
    "out:Initial Cost/MSEK": 4.383201592463576,
    "out:Running cost/(Apt SEK y)": 26735.851064,
    "out:Running Cost over RSP/MSEK": 9.85202,
    "out:LCP/MSEK": -0.830502,
    "out:ROI% old": 11.578947,
    "out:Payback discounted": 10,
    "out:Atemp": 3184,
    "out:Umean": 0.65,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 144,
    "out:Energy use kWh/m2": 144,
    "out:Energy savings %": 27.1,
    "out:Op energy cost/MSEK": 73,
    "out:El price/MSEK": 42,
    "out:Heating price/MSEK": 27,
    "out:DH+ DHW/kWh/m2": 138,
    "out:El bought/kWh/m2": 413.65837,
    "out:Return %": 10,
    "out:Return/kSEK/y": 458,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 41.7,
    "out:EAHP (El)": 0,
    "out:PV (kr/kWp)": 17461.48474631725,
    "out:PV (kWp installed)": 90.5097,
    "out:PV (m2 panels)": 474.867263,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 84922.568824,
    "out:PV (kWh sold)": 71877.957046,
    "out:PV (% sold (El))": 84.639405,
    "out:PV (kWh self-consumed)": 13044.611778,
    "out:PV (ratio sold/self-consumed)": 5.510165,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 99440.926462,
    "out:EL kWh savings": 21303.538998,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 79599.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 249864.94,
    "out:DH kr savings": 99440.92646186505,
    "out:El kr savings": 42607.077996404216,
    "out:El kr sold": 107816.93556907076,
    "out:El kr saved": 26089.223556,
    "out:El kr return": 133906.15912507076,
    "out:% solar/total": 33,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 307701.251111,
    "out:% savings (space heating)": 24.424128,
    "out:Total_Water Heating (DH)": 132772.760051,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 96.64,
    "out:Etvv": 25,
    "out:Ef": 5.88,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 4383201.592463575,
    "out:Seasonal Variation ROI (%)": 5,
    "out:Seasonal Variation Payback": 20,
    "out:New AHU Cost/SEK": 146792.87151982498,
    "out:DH CO2": 49.677792,
    "out:EL CO2": -114.042364,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -114.819396,
    "out:Bought CO2": 0.777032,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01000000020101000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 1,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 100.956054,
    "out:DHW (DH)": 35.445,
    "out:Property Electricity": 9.976115,
    "out:Electricity (inc PV)": 5.879188,
    "out:Total Energy Use Pre PV": 145.976115,
    "out:Total Energy Use Post PV": 141.879188,
    "out:Primary Energy": 103.978913,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 41.583173,
    "out:CO2 Operational/m2": -65.08454,
    "out:Total CO2/m2": -23.501367,
    "out:Total CO2 (tons)": -74.82833,
    "out:Klimatpaverkan": -274.963751,
    "out:Initial Cost/MSEK": 4.576817617463575,
    "out:Running cost/(Apt SEK y)": 26326.680851,
    "out:Running Cost over RSP/MSEK": 9.7016,
    "out:LCP/MSEK": -0.873698,
    "out:ROI% old": 11.558624,
    "out:Payback discounted": 10,
    "out:Atemp": 3184,
    "out:Umean": 0.65,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 142,
    "out:Energy use kWh/m2": 142,
    "out:Energy savings %": 28.9,
    "out:Op energy cost/MSEK": 72,
    "out:El price/MSEK": 41,
    "out:Heating price/MSEK": 27,
    "out:DH+ DHW/kWh/m2": 136,
    "out:El bought/kWh/m2": 413.65837,
    "out:Return %": 10,
    "out:Return/kSEK/y": 477,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 35.445,
    "out:EAHP (El)": 0,
    "out:PV (kr/kWp)": 17461.48474631725,
    "out:PV (kWp installed)": 90.5097,
    "out:PV (m2 panels)": 474.867263,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 84922.568824,
    "out:PV (kWh sold)": 71877.957046,
    "out:PV (% sold (El))": 84.639405,
    "out:PV (kWh self-consumed)": 13044.611778,
    "out:PV (ratio sold/self-consumed)": 5.510165,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 105614.113952,
    "out:EL kWh savings": 21303.538998,
    "out:Sewage heat recovery cost": 273216,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 256038.13,
    "out:DH kr savings": 105614.11395247949,
    "out:El kr savings": 42607.077996404216,
    "out:El kr sold": 107816.93556907076,
    "out:El kr saved": 26089.223556,
    "out:El kr return": 133906.15912507076,
    "out:% solar/total": 33,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 321443.977628,
    "out:% savings (space heating)": 21.048716,
    "out:Total_Water Heating (DH)": 112856.846044,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 100.96,
    "out:Etvv": 21.25,
    "out:Ef": 5.88,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 4576817.617463575,
    "out:Seasonal Variation ROI (%)": 5,
    "out:Seasonal Variation Payback": 20,
    "out:New AHU Cost/SEK": 146792.87151982498,
    "out:DH CO2": 48.957824,
    "out:EL CO2": -114.042364,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -114.819396,
    "out:Bought CO2": 0.777032,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01000000020101100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 1,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 96.639869,
    "out:DHW (DH)": 35.445,
    "out:Property Electricity": 9.976115,
    "out:Electricity (inc PV)": 5.879188,
    "out:Total Energy Use Pre PV": 141.976115,
    "out:Total Energy Use Post PV": 137.879188,
    "out:Primary Energy": 100.621881,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 41.583173,
    "out:CO2 Operational/m2": -66.524476,
    "out:Total CO2/m2": -24.941303,
    "out:Total CO2 (tons)": -79.413085,
    "out:Klimatpaverkan": -279.548506,
    "out:Initial Cost/MSEK": 4.656417592463575,
    "out:Running cost/(Apt SEK y)": 25508.319149,
    "out:Running Cost over RSP/MSEK": 9.40075,
    "out:LCP/MSEK": -0.652448,
    "out:ROI% old": 12.28403,
    "out:Payback discounted": 10,
    "out:Atemp": 3184,
    "out:Umean": 0.65,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 138,
    "out:Energy use kWh/m2": 138,
    "out:Energy savings %": 32.6,
    "out:Op energy cost/MSEK": 70,
    "out:El price/MSEK": 40,
    "out:Heating price/MSEK": 26,
    "out:DH+ DHW/kWh/m2": 132,
    "out:El bought/kWh/m2": 413.65837,
    "out:Return %": 11,
    "out:Return/kSEK/y": 516,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 35.445,
    "out:EAHP (El)": 0,
    "out:PV (kr/kWp)": 17461.48474631725,
    "out:PV (kWp installed)": 90.5097,
    "out:PV (m2 panels)": 474.867263,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 84922.568824,
    "out:PV (kWh sold)": 71877.957046,
    "out:PV (% sold (El))": 84.639405,
    "out:PV (kWh self-consumed)": 13044.611778,
    "out:PV (ratio sold/self-consumed)": 5.510165,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 119356.84047,
    "out:EL kWh savings": 21303.538998,
    "out:Sewage heat recovery cost": 273216,
    "out:Balancing hydronic system cost": 79599.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 269780.85,
    "out:DH kr savings": 119356.84046957504,
    "out:El kr savings": 42607.077996404216,
    "out:El kr sold": 107816.93556907076,
    "out:El kr saved": 26089.223556,
    "out:El kr return": 133906.15912507076,
    "out:% solar/total": 33,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 307701.251111,
    "out:% savings (space heating)": 24.424128,
    "out:Total_Water Heating (DH)": 112856.846044,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 96.64,
    "out:Etvv": 21.25,
    "out:Ef": 5.88,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 4656417.592463575,
    "out:Seasonal Variation ROI (%)": 6,
    "out:Seasonal Variation Payback": 16.666667,
    "out:New AHU Cost/SEK": 146792.87151982498,
    "out:DH CO2": 47.517888,
    "out:EL CO2": -114.042364,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -114.819396,
    "out:Bought CO2": 0.777032,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01000000100000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 100.956054,
    "out:DHW (DH)": 33.36,
    "out:Property Electricity": 12.57,
    "out:Electricity (inc PV)": 12.57,
    "out:Total Energy Use Pre PV": 146.57,
    "out:Total Energy Use Post PV": 146.57,
    "out:Primary Energy": 115.147375,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 11.211929,
    "out:CO2 Operational/m2": 49.897197,
    "out:Total CO2/m2": 61.109126,
    "out:Total CO2 (tons)": 194.571399,
    "out:Klimatpaverkan": -5.564022,
    "out:Initial Cost/MSEK": 2.7004226875,
    "out:Running cost/(Apt SEK y)": 29118,
    "out:Running Cost over RSP/MSEK": 10.69712,
    "out:LCP/MSEK": 0.007177,
    "out:ROI% old": 14.323684,
    "out:Payback discounted": 8,
    "out:Atemp": 3184,
    "out:Umean": 0.65,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 147,
    "out:Energy use kWh/m2": 147,
    "out:Energy savings %": 24.5,
    "out:Op energy cost/MSEK": 78,
    "out:El price/MSEK": 48,
    "out:Heating price/MSEK": 26,
    "out:DH+ DHW/kWh/m2": 134,
    "out:El bought/kWh/m2": 879.9,
    "out:Return %": 13,
    "out:Return/kSEK/y": 346,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 33.36,
    "out:EAHP (El)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 112252.751955,
    "out:EL kWh savings": 2.2624e-11,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 112252.75,
    "out:DH kr savings": 112252.7519550495,
    "out:El kr savings": 4.524734779920436e-11,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 321443.977628,
    "out:% savings (space heating)": 21.048716,
    "out:Total_Water Heating (DH)": 106218.208041,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 100.96,
    "out:Etvv": 20,
    "out:Ef": 12.57,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 2700422.6875,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 48.237856,
    "out:EL CO2": 1.659341,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 1.659341,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01000000100000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 96.639869,
    "out:DHW (DH)": 33.36,
    "out:Property Electricity": 12.57,
    "out:Electricity (inc PV)": 12.57,
    "out:Total Energy Use Pre PV": 142.57,
    "out:Total Energy Use Post PV": 142.57,
    "out:Primary Energy": 111.790343,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 11.211929,
    "out:CO2 Operational/m2": 48.457261,
    "out:Total CO2/m2": 59.66919,
    "out:Total CO2 (tons)": 189.986644,
    "out:Klimatpaverkan": -10.148777,
    "out:Initial Cost/MSEK": 2.7800226624999995,
    "out:Running cost/(Apt SEK y)": 28299.659574,
    "out:Running Cost over RSP/MSEK": 10.39627,
    "out:LCP/MSEK": 0.228427,
    "out:ROI% old": 15.459535,
    "out:Payback discounted": 8,
    "out:Atemp": 3184,
    "out:Umean": 0.65,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 143,
    "out:Energy use kWh/m2": 143,
    "out:Energy savings %": 28,
    "out:Op energy cost/MSEK": 76,
    "out:El price/MSEK": 46,
    "out:Heating price/MSEK": 25,
    "out:DH+ DHW/kWh/m2": 130,
    "out:El bought/kWh/m2": 879.9,
    "out:Return %": 14,
    "out:Return/kSEK/y": 385,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 33.36,
    "out:EAHP (El)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 125995.478472,
    "out:EL kWh savings": 2.2624e-11,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 79599.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 125995.48,
    "out:DH kr savings": 125995.47847214497,
    "out:El kr savings": 4.524734779920436e-11,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 307701.251111,
    "out:% savings (space heating)": 24.424128,
    "out:Total_Water Heating (DH)": 106218.208041,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 96.64,
    "out:Etvv": 20,
    "out:Ef": 12.57,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 2780022.6624999996,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 46.79792,
    "out:EL CO2": 1.659341,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 1.659341,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01000000100001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 100.956054,
    "out:DHW (DH)": 28.356,
    "out:Property Electricity": 12.57,
    "out:Electricity (inc PV)": 12.57,
    "out:Total Energy Use Pre PV": 141.57,
    "out:Total Energy Use Post PV": 141.57,
    "out:Primary Energy": 113.047375,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 11.920472,
    "out:CO2 Operational/m2": 48.097277,
    "out:Total CO2/m2": 60.017749,
    "out:Total CO2 (tons)": 191.096455,
    "out:Klimatpaverkan": -9.038966,
    "out:Initial Cost/MSEK": 2.9736386874999994,
    "out:Running cost/(Apt SEK y)": 28095.06383,
    "out:Running Cost over RSP/MSEK": 10.32106,
    "out:LCP/MSEK": 0.110021,
    "out:ROI% old": 14.81427,
    "out:Payback discounted": 8,
    "out:Atemp": 3184,
    "out:Umean": 0.65,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 142,
    "out:Energy use kWh/m2": 142,
    "out:Energy savings %": 28.9,
    "out:Op energy cost/MSEK": 75,
    "out:El price/MSEK": 46,
    "out:Heating price/MSEK": 25,
    "out:DH+ DHW/kWh/m2": 129,
    "out:El bought/kWh/m2": 879.9,
    "out:Return %": 13,
    "out:Return/kSEK/y": 394,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 28.356,
    "out:EAHP (El)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 128185.483161,
    "out:EL kWh savings": 2.2624e-11,
    "out:Sewage heat recovery cost": 273216,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 128185.48,
    "out:DH kr savings": 128185.48316121747,
    "out:El kr savings": 4.524734779920436e-11,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 321443.977628,
    "out:% savings (space heating)": 21.048716,
    "out:Total_Water Heating (DH)": 90285.476835,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 100.96,
    "out:Etvv": 17,
    "out:Ef": 12.57,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 2973638.6874999995,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 46.437936,
    "out:EL CO2": 1.659341,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 1.659341,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01000000100001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 96.639869,
    "out:DHW (DH)": 28.356,
    "out:Property Electricity": 12.57,
    "out:Electricity (inc PV)": 12.57,
    "out:Total Energy Use Pre PV": 137.57,
    "out:Total Energy Use Post PV": 137.57,
    "out:Primary Energy": 109.690343,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 11.920472,
    "out:CO2 Operational/m2": 46.657341,
    "out:Total CO2/m2": 58.577813,
    "out:Total CO2 (tons)": 186.5117,
    "out:Klimatpaverkan": -13.623721,
    "out:Initial Cost/MSEK": 3.0532386624999996,
    "out:Running cost/(Apt SEK y)": 27276.702128,
    "out:Running Cost over RSP/MSEK": 10.02022,
    "out:LCP/MSEK": 0.331261,
    "out:ROI% old": 15.835644,
    "out:Payback discounted": 8,
    "out:Atemp": 3184,
    "out:Umean": 0.65,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 138,
    "out:Energy use kWh/m2": 138,
    "out:Energy savings %": 32.6,
    "out:Op energy cost/MSEK": 73,
    "out:El price/MSEK": 45,
    "out:Heating price/MSEK": 24,
    "out:DH+ DHW/kWh/m2": 125,
    "out:El bought/kWh/m2": 879.9,
    "out:Return %": 14,
    "out:Return/kSEK/y": 433,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 28.356,
    "out:EAHP (El)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 141928.209678,
    "out:EL kWh savings": 2.2624e-11,
    "out:Sewage heat recovery cost": 273216,
    "out:Balancing hydronic system cost": 79599.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 141928.21,
    "out:DH kr savings": 141928.20967831303,
    "out:El kr savings": 4.524734779920436e-11,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 307701.251111,
    "out:% savings (space heating)": 24.424128,
    "out:Total_Water Heating (DH)": 90285.476835,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 96.64,
    "out:Etvv": 17,
    "out:Ef": 12.57,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 3053238.6624999996,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 44.998,
    "out:EL CO2": 1.659341,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 1.659341,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01000000100100000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 1,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 100.956054,
    "out:DHW (DH)": 33.36,
    "out:Property Electricity": 9.976115,
    "out:Electricity (inc PV)": 9.976115,
    "out:Total Energy Use Pre PV": 143.976115,
    "out:Total Energy Use Post PV": 143.976115,
    "out:Primary Energy": 110.478382,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 11.396431,
    "out:CO2 Operational/m2": 49.554783,
    "out:Total CO2/m2": 60.951214,
    "out:Total CO2 (tons)": 194.068607,
    "out:Klimatpaverkan": -6.066814,
    "out:Initial Cost/MSEK": 2.8472155590198245,
    "out:Running cost/(Apt SEK y)": 28766.574468,
    "out:Running Cost over RSP/MSEK": 10.56942,
    "out:LCP/MSEK": -0.011916,
    "out:ROI% old": 14.225929,
    "out:Payback discounted": 9,
    "out:Atemp": 3184,
    "out:Umean": 0.65,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 144,
    "out:Energy use kWh/m2": 144,
    "out:Energy savings %": 27.1,
    "out:Op energy cost/MSEK": 77,
    "out:El price/MSEK": 47,
    "out:Heating price/MSEK": 26,
    "out:DH+ DHW/kWh/m2": 134,
    "out:El bought/kWh/m2": 698.32805,
    "out:Return %": 13,
    "out:Return/kSEK/y": 363,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 33.36,
    "out:EAHP (El)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 112252.751955,
    "out:EL kWh savings": 8258.927355,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 128770.61,
    "out:DH kr savings": 112252.7519550495,
    "out:El kr savings": 16517.854710116386,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 321443.977628,
    "out:% savings (space heating)": 21.048716,
    "out:Total_Water Heating (DH)": 106218.208041,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 100.96,
    "out:Etvv": 20,
    "out:Ef": 9.98,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 2847215.5590198245,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25,
    "out:New AHU Cost/SEK": 146792.87151982498,
    "out:DH CO2": 48.237856,
    "out:EL CO2": 1.316927,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 1.316927,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01000000100100100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 1,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 96.639869,
    "out:DHW (DH)": 33.36,
    "out:Property Electricity": 9.976115,
    "out:Electricity (inc PV)": 9.976115,
    "out:Total Energy Use Pre PV": 139.976115,
    "out:Total Energy Use Post PV": 139.976115,
    "out:Primary Energy": 107.12135,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 11.396431,
    "out:CO2 Operational/m2": 48.114847,
    "out:Total CO2/m2": 59.511278,
    "out:Total CO2 (tons)": 189.483852,
    "out:Klimatpaverkan": -10.651569,
    "out:Initial Cost/MSEK": 2.9268155340198247,
    "out:Running cost/(Apt SEK y)": 27948.212766,
    "out:Running Cost over RSP/MSEK": 10.26858,
    "out:LCP/MSEK": 0.209324,
    "out:ROI% old": 15.307422,
    "out:Payback discounted": 8,
    "out:Atemp": 3184,
    "out:Umean": 0.65,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 140,
    "out:Energy use kWh/m2": 140,
    "out:Energy savings %": 30.7,
    "out:Op energy cost/MSEK": 75,
    "out:El price/MSEK": 46,
    "out:Heating price/MSEK": 25,
    "out:DH+ DHW/kWh/m2": 130,
    "out:El bought/kWh/m2": 698.32805,
    "out:Return %": 14,
    "out:Return/kSEK/y": 401,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 33.36,
    "out:EAHP (El)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 125995.478472,
    "out:EL kWh savings": 8258.927355,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 79599.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 142513.33,
    "out:DH kr savings": 125995.47847214497,
    "out:El kr savings": 16517.854710116386,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 307701.251111,
    "out:% savings (space heating)": 24.424128,
    "out:Total_Water Heating (DH)": 106218.208041,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 96.64,
    "out:Etvv": 20,
    "out:Ef": 9.98,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 2926815.5340198246,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25,
    "out:New AHU Cost/SEK": 146792.87151982498,
    "out:DH CO2": 46.79792,
    "out:EL CO2": 1.316927,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 1.316927,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01000000100101000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 1,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 100.956054,
    "out:DHW (DH)": 28.356,
    "out:Property Electricity": 9.976115,
    "out:Electricity (inc PV)": 9.976115,
    "out:Total Energy Use Pre PV": 138.976115,
    "out:Total Energy Use Post PV": 138.976115,
    "out:Primary Energy": 108.378382,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 12.104974,
    "out:CO2 Operational/m2": 47.754863,
    "out:Total CO2/m2": 59.859837,
    "out:Total CO2 (tons)": 190.593664,
    "out:Klimatpaverkan": -9.541757,
    "out:Initial Cost/MSEK": 3.1204315590198246,
    "out:Running cost/(Apt SEK y)": 27743.617021,
    "out:Running Cost over RSP/MSEK": 10.19337,
    "out:LCP/MSEK": 0.090918,
    "out:ROI% old": 14.70195,
    "out:Payback discounted": 8,
    "out:Atemp": 3184,
    "out:Umean": 0.65,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 139,
    "out:Energy use kWh/m2": 139,
    "out:Energy savings %": 31.7,
    "out:Op energy cost/MSEK": 75,
    "out:El price/MSEK": 45,
    "out:Heating price/MSEK": 25,
    "out:DH+ DHW/kWh/m2": 129,
    "out:El bought/kWh/m2": 698.32805,
    "out:Return %": 13,
    "out:Return/kSEK/y": 411,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 28.356,
    "out:EAHP (El)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 128185.483161,
    "out:EL kWh savings": 8258.927355,
    "out:Sewage heat recovery cost": 273216,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 144703.34,
    "out:DH kr savings": 128185.48316121747,
    "out:El kr savings": 16517.854710116386,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 321443.977628,
    "out:% savings (space heating)": 21.048716,
    "out:Total_Water Heating (DH)": 90285.476835,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 100.96,
    "out:Etvv": 17,
    "out:Ef": 9.98,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 3120431.5590198245,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25,
    "out:New AHU Cost/SEK": 146792.87151982498,
    "out:DH CO2": 46.437936,
    "out:EL CO2": 1.316927,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 1.316927,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01000000100101100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 1,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 96.639869,
    "out:DHW (DH)": 28.356,
    "out:Property Electricity": 9.976115,
    "out:Electricity (inc PV)": 9.976115,
    "out:Total Energy Use Pre PV": 134.976115,
    "out:Total Energy Use Post PV": 134.976115,
    "out:Primary Energy": 105.02135,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 12.104974,
    "out:CO2 Operational/m2": 46.314927,
    "out:Total CO2/m2": 58.419901,
    "out:Total CO2 (tons)": 186.008909,
    "out:Klimatpaverkan": -14.126512,
    "out:Initial Cost/MSEK": 3.2000315340198244,
    "out:Running cost/(Apt SEK y)": 26925.276596,
    "out:Running Cost over RSP/MSEK": 9.89252,
    "out:LCP/MSEK": 0.312168,
    "out:ROI% old": 15.67931,
    "out:Payback discounted": 8,
    "out:Atemp": 3184,
    "out:Umean": 0.65,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 135,
    "out:Energy use kWh/m2": 135,
    "out:Energy savings %": 35.6,
    "out:Op energy cost/MSEK": 72,
    "out:El price/MSEK": 44,
    "out:Heating price/MSEK": 24,
    "out:DH+ DHW/kWh/m2": 125,
    "out:El bought/kWh/m2": 698.32805,
    "out:Return %": 14,
    "out:Return/kSEK/y": 449,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 28.356,
    "out:EAHP (El)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 141928.209678,
    "out:EL kWh savings": 8258.927355,
    "out:Sewage heat recovery cost": 273216,
    "out:Balancing hydronic system cost": 79599.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 158446.06,
    "out:DH kr savings": 141928.20967831303,
    "out:El kr savings": 16517.854710116386,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 307701.251111,
    "out:% savings (space heating)": 24.424128,
    "out:Total_Water Heating (DH)": 90285.476835,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 96.64,
    "out:Etvv": 17,
    "out:Ef": 9.98,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 3200031.5340198246,
    "out:Seasonal Variation ROI (%)": 5,
    "out:Seasonal Variation Payback": 20,
    "out:New AHU Cost/SEK": 146792.87151982498,
    "out:DH CO2": 44.998,
    "out:EL CO2": 1.316927,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 1.316927,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01000000110000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 100.956054,
    "out:DHW (DH)": 33.36,
    "out:Property Electricity": 12.57,
    "out:Electricity (inc PV)": 8.051517,
    "out:Total Energy Use Pre PV": 146.57,
    "out:Total Energy Use Post PV": 142.051517,
    "out:Primary Energy": 107.014106,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 25.951029,
    "out:CO2 Operational/m2": 4.636535,
    "out:Total CO2/m2": 30.587564,
    "out:Total CO2 (tons)": 97.390774,
    "out:Klimatpaverkan": -102.744647,
    "out:Initial Cost/MSEK": 3.4906430817225,
    "out:Running cost/(Apt SEK y)": 27609.808511,
    "out:Running Cost over RSP/MSEK": 10.15775,
    "out:LCP/MSEK": -0.243673,
    "out:ROI% old": 13.288464,
    "out:Payback discounted": 9,
    "out:Atemp": 3184,
    "out:Umean": 0.65,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 142,
    "out:Energy use kWh/m2": 142,
    "out:Energy savings %": 28.9,
    "out:Op energy cost/MSEK": 75,
    "out:El price/MSEK": 44,
    "out:Heating price/MSEK": 26,
    "out:DH+ DHW/kWh/m2": 134,
    "out:El bought/kWh/m2": 568.258452,
    "out:Return %": 12,
    "out:Return/kSEK/y": 417,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 33.36,
    "out:EAHP (El)": 0,
    "out:PV (kr/kWp)": 17461.56255567083,
    "out:PV (kWp installed)": 45.25485,
    "out:PV (m2 panels)": 237.433632,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 42461.284501,
    "out:PV (kWh sold)": 28074.439514,
    "out:PV (% sold (El))": 66.117735,
    "out:PV (kWh self-consumed)": 14386.844987,
    "out:PV (ratio sold/self-consumed)": 1.951397,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 112252.751955,
    "out:EL kWh savings": 14386.845543,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 183138.1,
    "out:DH kr savings": 112252.7519550495,
    "out:El kr savings": 28773.691086586638,
    "out:El kr sold": 42111.65927092506,
    "out:El kr saved": 28773.689974,
    "out:El kr return": 70885.34924492506,
    "out:% solar/total": 36,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 321443.977628,
    "out:% savings (space heating)": 21.048716,
    "out:Total_Water Heating (DH)": 106218.208041,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 100.96,
    "out:Etvv": 20,
    "out:Ef": 8.05,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 3490643.0817225,
    "out:Seasonal Variation ROI (%)": 5,
    "out:Seasonal Variation Payback": 20,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 48.237856,
    "out:EL CO2": -43.601321,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -44.666212,
    "out:Bought CO2": 1.064891,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01000000110000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 96.639869,
    "out:DHW (DH)": 33.36,
    "out:Property Electricity": 12.57,
    "out:Electricity (inc PV)": 8.051517,
    "out:Total Energy Use Pre PV": 142.57,
    "out:Total Energy Use Post PV": 138.051517,
    "out:Primary Energy": 103.657074,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 25.951029,
    "out:CO2 Operational/m2": 3.196599,
    "out:Total CO2/m2": 29.147628,
    "out:Total CO2 (tons)": 92.80602,
    "out:Klimatpaverkan": -107.329401,
    "out:Initial Cost/MSEK": 3.5702430567225,
    "out:Running cost/(Apt SEK y)": 26791.446809,
    "out:Running Cost over RSP/MSEK": 9.8569,
    "out:LCP/MSEK": -0.022423,
    "out:ROI% old": 14.195992,
    "out:Payback discounted": 9,
    "out:Atemp": 3184,
    "out:Umean": 0.65,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 138,
    "out:Energy use kWh/m2": 138,
    "out:Energy savings %": 32.6,
    "out:Op energy cost/MSEK": 73,
    "out:El price/MSEK": 43,
    "out:Heating price/MSEK": 25,
    "out:DH+ DHW/kWh/m2": 130,
    "out:El bought/kWh/m2": 568.258452,
    "out:Return %": 13,
    "out:Return/kSEK/y": 456,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 33.36,
    "out:EAHP (El)": 0,
    "out:PV (kr/kWp)": 17461.56255567083,
    "out:PV (kWp installed)": 45.25485,
    "out:PV (m2 panels)": 237.433632,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 42461.284501,
    "out:PV (kWh sold)": 28074.439514,
    "out:PV (% sold (El))": 66.117735,
    "out:PV (kWh self-consumed)": 14386.844987,
    "out:PV (ratio sold/self-consumed)": 1.951397,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 125995.478472,
    "out:EL kWh savings": 14386.845543,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 79599.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 196880.83,
    "out:DH kr savings": 125995.47847214497,
    "out:El kr savings": 28773.691086586638,
    "out:El kr sold": 42111.65927092506,
    "out:El kr saved": 28773.689974,
    "out:El kr return": 70885.34924492506,
    "out:% solar/total": 36,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 307701.251111,
    "out:% savings (space heating)": 24.424128,
    "out:Total_Water Heating (DH)": 106218.208041,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 96.64,
    "out:Etvv": 20,
    "out:Ef": 8.05,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 3570243.0567225,
    "out:Seasonal Variation ROI (%)": 5,
    "out:Seasonal Variation Payback": 20,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 46.79792,
    "out:EL CO2": -43.601321,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -44.666212,
    "out:Bought CO2": 1.064891,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01000000110001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 100.956054,
    "out:DHW (DH)": 28.356,
    "out:Property Electricity": 12.57,
    "out:Electricity (inc PV)": 8.051517,
    "out:Total Energy Use Pre PV": 141.57,
    "out:Total Energy Use Post PV": 137.051517,
    "out:Primary Energy": 104.914106,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 26.659572,
    "out:CO2 Operational/m2": 2.836615,
    "out:Total CO2/m2": 29.496187,
    "out:Total CO2 (tons)": 93.915831,
    "out:Klimatpaverkan": -106.21959,
    "out:Initial Cost/MSEK": 3.7638590817225,
    "out:Running cost/(Apt SEK y)": 26586.87234,
    "out:Running Cost over RSP/MSEK": 9.78169,
    "out:LCP/MSEK": -0.140829,
    "out:ROI% old": 13.751198,
    "out:Payback discounted": 9,
    "out:Atemp": 3184,
    "out:Umean": 0.65,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 137,
    "out:Energy use kWh/m2": 137,
    "out:Energy savings %": 33.6,
    "out:Op energy cost/MSEK": 72,
    "out:El price/MSEK": 43,
    "out:Heating price/MSEK": 25,
    "out:DH+ DHW/kWh/m2": 129,
    "out:El bought/kWh/m2": 568.258452,
    "out:Return %": 12,
    "out:Return/kSEK/y": 465,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 28.356,
    "out:EAHP (El)": 0,
    "out:PV (kr/kWp)": 17461.56255567083,
    "out:PV (kWp installed)": 45.25485,
    "out:PV (m2 panels)": 237.433632,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 42461.284501,
    "out:PV (kWh sold)": 28074.439514,
    "out:PV (% sold (El))": 66.117735,
    "out:PV (kWh self-consumed)": 14386.844987,
    "out:PV (ratio sold/self-consumed)": 1.951397,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 128185.483161,
    "out:EL kWh savings": 14386.845543,
    "out:Sewage heat recovery cost": 273216,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 199070.83,
    "out:DH kr savings": 128185.48316121747,
    "out:El kr savings": 28773.691086586638,
    "out:El kr sold": 42111.65927092506,
    "out:El kr saved": 28773.689974,
    "out:El kr return": 70885.34924492506,
    "out:% solar/total": 36,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 321443.977628,
    "out:% savings (space heating)": 21.048716,
    "out:Total_Water Heating (DH)": 90285.476835,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 100.96,
    "out:Etvv": 17,
    "out:Ef": 8.05,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 3763859.0817225,
    "out:Seasonal Variation ROI (%)": 5,
    "out:Seasonal Variation Payback": 20,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 46.437936,
    "out:EL CO2": -43.601321,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -44.666212,
    "out:Bought CO2": 1.064891,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01000000110001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 96.639869,
    "out:DHW (DH)": 28.356,
    "out:Property Electricity": 12.57,
    "out:Electricity (inc PV)": 8.051517,
    "out:Total Energy Use Pre PV": 137.57,
    "out:Total Energy Use Post PV": 133.051517,
    "out:Primary Energy": 101.557074,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 26.659572,
    "out:CO2 Operational/m2": 1.396679,
    "out:Total CO2/m2": 28.056251,
    "out:Total CO2 (tons)": 89.331076,
    "out:Klimatpaverkan": -110.804345,
    "out:Initial Cost/MSEK": 3.8434590567224993,
    "out:Running cost/(Apt SEK y)": 25768.510638,
    "out:Running Cost over RSP/MSEK": 9.48085,
    "out:LCP/MSEK": 0.080411,
    "out:ROI% old": 14.584593,
    "out:Payback discounted": 8,
    "out:Atemp": 3184,
    "out:Umean": 0.65,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 133,
    "out:Energy use kWh/m2": 133,
    "out:Energy savings %": 37.6,
    "out:Op energy cost/MSEK": 70,
    "out:El price/MSEK": 41,
    "out:Heating price/MSEK": 24,
    "out:DH+ DHW/kWh/m2": 125,
    "out:El bought/kWh/m2": 568.258452,
    "out:Return %": 13,
    "out:Return/kSEK/y": 504,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 28.356,
    "out:EAHP (El)": 0,
    "out:PV (kr/kWp)": 17461.56255567083,
    "out:PV (kWp installed)": 45.25485,
    "out:PV (m2 panels)": 237.433632,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 42461.284501,
    "out:PV (kWh sold)": 28074.439514,
    "out:PV (% sold (El))": 66.117735,
    "out:PV (kWh self-consumed)": 14386.844987,
    "out:PV (ratio sold/self-consumed)": 1.951397,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 141928.209678,
    "out:EL kWh savings": 14386.845543,
    "out:Sewage heat recovery cost": 273216,
    "out:Balancing hydronic system cost": 79599.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 212813.56,
    "out:DH kr savings": 141928.20967831303,
    "out:El kr savings": 28773.691086586638,
    "out:El kr sold": 42111.65927092506,
    "out:El kr saved": 28773.689974,
    "out:El kr return": 70885.34924492506,
    "out:% solar/total": 36,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 307701.251111,
    "out:% savings (space heating)": 24.424128,
    "out:Total_Water Heating (DH)": 90285.476835,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 96.64,
    "out:Etvv": 17,
    "out:Ef": 8.05,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 3843459.0567224994,
    "out:Seasonal Variation ROI (%)": 5,
    "out:Seasonal Variation Payback": 20,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 44.998,
    "out:EL CO2": -43.601321,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -44.666212,
    "out:Bought CO2": 1.064891,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01000000110100000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 1,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 100.956054,
    "out:DHW (DH)": 33.36,
    "out:Property Electricity": 9.976115,
    "out:Electricity (inc PV)": 6.231337,
    "out:Total Energy Use Pre PV": 143.976115,
    "out:Total Energy Use Post PV": 140.231337,
    "out:Primary Energy": 103.737782,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 26.135531,
    "out:CO2 Operational/m2": 0.406407,
    "out:Total CO2/m2": 26.541938,
    "out:Total CO2 (tons)": 84.509505,
    "out:Klimatpaverkan": -115.625916,
    "out:Initial Cost/MSEK": 3.6374359532423246,
    "out:Running cost/(Apt SEK y)": 27284.574468,
    "out:Running Cost over RSP/MSEK": 10.03952,
    "out:LCP/MSEK": -0.272236,
    "out:ROI% old": 13.216531,
    "out:Payback discounted": 9,
    "out:Atemp": 3184,
    "out:Umean": 0.65,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 140,
    "out:Energy use kWh/m2": 140,
    "out:Energy savings %": 30.7,
    "out:Op energy cost/MSEK": 74,
    "out:El price/MSEK": 44,
    "out:Heating price/MSEK": 26,
    "out:DH+ DHW/kWh/m2": 134,
    "out:El bought/kWh/m2": 439.497378,
    "out:Return %": 12,
    "out:Return/kSEK/y": 432,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 33.36,
    "out:EAHP (El)": 0,
    "out:PV (kr/kWp)": 17461.56255567083,
    "out:PV (kWp installed)": 45.25485,
    "out:PV (m2 panels)": 237.433632,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 42461.284501,
    "out:PV (kWh sold)": 30537.914073,
    "out:PV (% sold (El))": 71.919431,
    "out:PV (kWh self-consumed)": 11923.370428,
    "out:PV (ratio sold/self-consumed)": 2.561181,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 112252.751955,
    "out:EL kWh savings": 20182.29692,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 198424.22,
    "out:DH kr savings": 112252.7519550495,
    "out:El kr savings": 40364.59383912171,
    "out:El kr sold": 45806.87111012268,
    "out:El kr saved": 23846.740856,
    "out:El kr return": 69653.61196612268,
    "out:% solar/total": 30,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 321443.977628,
    "out:% savings (space heating)": 21.048716,
    "out:Total_Water Heating (DH)": 106218.208041,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 100.96,
    "out:Etvv": 20,
    "out:Ef": 6.23,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 3637435.9532423248,
    "out:Seasonal Variation ROI (%)": 5,
    "out:Seasonal Variation Payback": 20,
    "out:New AHU Cost/SEK": 146792.87151982498,
    "out:DH CO2": 48.237856,
    "out:EL CO2": -47.831449,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -48.655512,
    "out:Bought CO2": 0.824063,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01000000110100100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 1,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 96.639869,
    "out:DHW (DH)": 33.36,
    "out:Property Electricity": 9.976115,
    "out:Electricity (inc PV)": 6.231337,
    "out:Total Energy Use Pre PV": 139.976115,
    "out:Total Energy Use Post PV": 136.231337,
    "out:Primary Energy": 100.38075,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 26.135531,
    "out:CO2 Operational/m2": -1.033529,
    "out:Total CO2/m2": 25.102002,
    "out:Total CO2 (tons)": 79.92475,
    "out:Klimatpaverkan": -120.210671,
    "out:Initial Cost/MSEK": 3.717035928242325,
    "out:Running cost/(Apt SEK y)": 26466.212766,
    "out:Running Cost over RSP/MSEK": 9.73868,
    "out:LCP/MSEK": -0.050996,
    "out:ROI% old": 14.089721,
    "out:Payback discounted": 9,
    "out:Atemp": 3184,
    "out:Umean": 0.65,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 136,
    "out:Energy use kWh/m2": 136,
    "out:Energy savings %": 34.6,
    "out:Op energy cost/MSEK": 72,
    "out:El price/MSEK": 42,
    "out:Heating price/MSEK": 25,
    "out:DH+ DHW/kWh/m2": 130,
    "out:El bought/kWh/m2": 439.497378,
    "out:Return %": 13,
    "out:Return/kSEK/y": 471,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 33.36,
    "out:EAHP (El)": 0,
    "out:PV (kr/kWp)": 17461.56255567083,
    "out:PV (kWp installed)": 45.25485,
    "out:PV (m2 panels)": 237.433632,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 42461.284501,
    "out:PV (kWh sold)": 30537.914073,
    "out:PV (% sold (El))": 71.919431,
    "out:PV (kWh self-consumed)": 11923.370428,
    "out:PV (ratio sold/self-consumed)": 2.561181,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 125995.478472,
    "out:EL kWh savings": 20182.29692,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 79599.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 212166.94,
    "out:DH kr savings": 125995.47847214497,
    "out:El kr savings": 40364.59383912171,
    "out:El kr sold": 45806.87111012268,
    "out:El kr saved": 23846.740856,
    "out:El kr return": 69653.61196612268,
    "out:% solar/total": 30,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 307701.251111,
    "out:% savings (space heating)": 24.424128,
    "out:Total_Water Heating (DH)": 106218.208041,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 96.64,
    "out:Etvv": 20,
    "out:Ef": 6.23,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 3717035.928242325,
    "out:Seasonal Variation ROI (%)": 5,
    "out:Seasonal Variation Payback": 20,
    "out:New AHU Cost/SEK": 146792.87151982498,
    "out:DH CO2": 46.79792,
    "out:EL CO2": -47.831449,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -48.655512,
    "out:Bought CO2": 0.824063,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01000000110101000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 1,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 100.956054,
    "out:DHW (DH)": 28.356,
    "out:Property Electricity": 9.976115,
    "out:Electricity (inc PV)": 6.231337,
    "out:Total Energy Use Pre PV": 138.976115,
    "out:Total Energy Use Post PV": 135.231337,
    "out:Primary Energy": 101.637782,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 26.844074,
    "out:CO2 Operational/m2": -1.393513,
    "out:Total CO2/m2": 25.450561,
    "out:Total CO2 (tons)": 81.034562,
    "out:Klimatpaverkan": -119.100859,
    "out:Initial Cost/MSEK": 3.9106519532423243,
    "out:Running cost/(Apt SEK y)": 26261.638298,
    "out:Running Cost over RSP/MSEK": 9.66346,
    "out:LCP/MSEK": -0.169392,
    "out:ROI% old": 13.666921,
    "out:Payback discounted": 9,
    "out:Atemp": 3184,
    "out:Umean": 0.65,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 135,
    "out:Energy use kWh/m2": 135,
    "out:Energy savings %": 35.6,
    "out:Op energy cost/MSEK": 71,
    "out:El price/MSEK": 42,
    "out:Heating price/MSEK": 25,
    "out:DH+ DHW/kWh/m2": 129,
    "out:El bought/kWh/m2": 439.497378,
    "out:Return %": 12,
    "out:Return/kSEK/y": 480,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 28.356,
    "out:EAHP (El)": 0,
    "out:PV (kr/kWp)": 17461.56255567083,
    "out:PV (kWp installed)": 45.25485,
    "out:PV (m2 panels)": 237.433632,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 42461.284501,
    "out:PV (kWh sold)": 30537.914073,
    "out:PV (% sold (El))": 71.919431,
    "out:PV (kWh self-consumed)": 11923.370428,
    "out:PV (ratio sold/self-consumed)": 2.561181,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 128185.483161,
    "out:EL kWh savings": 20182.29692,
    "out:Sewage heat recovery cost": 273216,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 214356.95,
    "out:DH kr savings": 128185.48316121747,
    "out:El kr savings": 40364.59383912171,
    "out:El kr sold": 45806.87111012268,
    "out:El kr saved": 23846.740856,
    "out:El kr return": 69653.61196612268,
    "out:% solar/total": 30,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 321443.977628,
    "out:% savings (space heating)": 21.048716,
    "out:Total_Water Heating (DH)": 90285.476835,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 100.96,
    "out:Etvv": 17,
    "out:Ef": 6.23,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 3910651.9532423243,
    "out:Seasonal Variation ROI (%)": 5,
    "out:Seasonal Variation Payback": 20,
    "out:New AHU Cost/SEK": 146792.87151982498,
    "out:DH CO2": 46.437936,
    "out:EL CO2": -47.831449,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -48.655512,
    "out:Bought CO2": 0.824063,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01000000110101100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 1,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 96.639869,
    "out:DHW (DH)": 28.356,
    "out:Property Electricity": 9.976115,
    "out:Electricity (inc PV)": 6.231337,
    "out:Total Energy Use Pre PV": 134.976115,
    "out:Total Energy Use Post PV": 131.231337,
    "out:Primary Energy": 98.28075,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 26.844074,
    "out:CO2 Operational/m2": -2.833449,
    "out:Total CO2/m2": 24.010625,
    "out:Total CO2 (tons)": 76.449807,
    "out:Klimatpaverkan": -123.685614,
    "out:Initial Cost/MSEK": 3.9902519282423246,
    "out:Running cost/(Apt SEK y)": 25443.276596,
    "out:Running Cost over RSP/MSEK": 9.36262,
    "out:LCP/MSEK": 0.051848,
    "out:ROI% old": 14.471338,
    "out:Payback discounted": 8,
    "out:Atemp": 3184,
    "out:Umean": 0.65,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 131,
    "out:Energy use kWh/m2": 131,
    "out:Energy savings %": 39.7,
    "out:Op energy cost/MSEK": 69,
    "out:El price/MSEK": 41,
    "out:Heating price/MSEK": 24,
    "out:DH+ DHW/kWh/m2": 125,
    "out:El bought/kWh/m2": 439.497378,
    "out:Return %": 13,
    "out:Return/kSEK/y": 519,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 28.356,
    "out:EAHP (El)": 0,
    "out:PV (kr/kWp)": 17461.56255567083,
    "out:PV (kWp installed)": 45.25485,
    "out:PV (m2 panels)": 237.433632,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 42461.284501,
    "out:PV (kWh sold)": 30537.914073,
    "out:PV (% sold (El))": 71.919431,
    "out:PV (kWh self-consumed)": 11923.370428,
    "out:PV (ratio sold/self-consumed)": 2.561181,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 141928.209678,
    "out:EL kWh savings": 20182.29692,
    "out:Sewage heat recovery cost": 273216,
    "out:Balancing hydronic system cost": 79599.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 228099.67,
    "out:DH kr savings": 141928.20967831303,
    "out:El kr savings": 40364.59383912171,
    "out:El kr sold": 45806.87111012268,
    "out:El kr saved": 23846.740856,
    "out:El kr return": 69653.61196612268,
    "out:% solar/total": 30,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 307701.251111,
    "out:% savings (space heating)": 24.424128,
    "out:Total_Water Heating (DH)": 90285.476835,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 96.64,
    "out:Etvv": 17,
    "out:Ef": 6.23,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 3990251.9282423244,
    "out:Seasonal Variation ROI (%)": 5,
    "out:Seasonal Variation Payback": 20,
    "out:New AHU Cost/SEK": 146792.87151982498,
    "out:DH CO2": 44.998,
    "out:EL CO2": -47.831449,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -48.655512,
    "out:Bought CO2": 0.824063,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01000000120000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 100.956054,
    "out:DHW (DH)": 33.36,
    "out:Property Electricity": 12.57,
    "out:Electricity (inc PV)": 7.530921,
    "out:Total Energy Use Pre PV": 146.57,
    "out:Total Energy Use Post PV": 141.530921,
    "out:Primary Energy": 106.077033,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 40.690128,
    "out:CO2 Operational/m2": -60.718782,
    "out:Total CO2/m2": -20.028654,
    "out:Total CO2 (tons)": -63.771215,
    "out:Klimatpaverkan": -263.906636,
    "out:Initial Cost/MSEK": 4.28085643344375,
    "out:Running cost/(Apt SEK y)": 26237.021277,
    "out:Running Cost over RSP/MSEK": 9.66732,
    "out:LCP/MSEK": -0.543456,
    "out:ROI% old": 12.472137,
    "out:Payback discounted": 10,
    "out:Atemp": 3184,
    "out:Umean": 0.65,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 142,
    "out:Energy use kWh/m2": 142,
    "out:Energy savings %": 28.9,
    "out:Op energy cost/MSEK": 72,
    "out:El price/MSEK": 41,
    "out:Heating price/MSEK": 26,
    "out:DH+ DHW/kWh/m2": 134,
    "out:El bought/kWh/m2": 530.25352,
    "out:Return %": 11,
    "out:Return/kSEK/y": 482,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 33.36,
    "out:EAHP (El)": 0,
    "out:PV (kr/kWp)": 17461.48474631725,
    "out:PV (kWp installed)": 90.5097,
    "out:PV (m2 panels)": 474.867263,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 84922.568824,
    "out:PV (kWh sold)": 68878.145463,
    "out:PV (% sold (El))": 81.106997,
    "out:PV (kWh self-consumed)": 16044.423361,
    "out:PV (ratio sold/self-consumed)": 4.292965,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 112252.751955,
    "out:EL kWh savings": 16044.422709,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 247658.82,
    "out:DH kr savings": 112252.7519550495,
    "out:El kr savings": 32088.845417124598,
    "out:El kr sold": 103317.21819422109,
    "out:El kr saved": 32088.846722,
    "out:El kr return": 135406.06491622108,
    "out:% solar/total": 40,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 321443.977628,
    "out:% savings (space heating)": 21.048716,
    "out:Total_Water Heating (DH)": 106218.208041,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 100.96,
    "out:Etvv": 20,
    "out:Ef": 7.53,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 4280856.43344375,
    "out:Seasonal Variation ROI (%)": 6,
    "out:Seasonal Variation Payback": 16.666667,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 48.237856,
    "out:EL CO2": -108.956638,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -109.952146,
    "out:Bought CO2": 0.995508,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01000000120000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 96.639869,
    "out:DHW (DH)": 33.36,
    "out:Property Electricity": 12.57,
    "out:Electricity (inc PV)": 7.530921,
    "out:Total Energy Use Pre PV": 142.57,
    "out:Total Energy Use Post PV": 137.530921,
    "out:Primary Energy": 102.720001,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 40.690128,
    "out:CO2 Operational/m2": -62.158718,
    "out:Total CO2/m2": -21.46859,
    "out:Total CO2 (tons)": -68.35597,
    "out:Klimatpaverkan": -268.491391,
    "out:Initial Cost/MSEK": 4.36045640844375,
    "out:Running cost/(Apt SEK y)": 25418.680851,
    "out:Running Cost over RSP/MSEK": 9.36647,
    "out:LCP/MSEK": -0.322206,
    "out:ROI% old": 13.230103,
    "out:Payback discounted": 9,
    "out:Atemp": 3184,
    "out:Umean": 0.65,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 138,
    "out:Energy use kWh/m2": 138,
    "out:Energy savings %": 32.6,
    "out:Op energy cost/MSEK": 70,
    "out:El price/MSEK": 40,
    "out:Heating price/MSEK": 25,
    "out:DH+ DHW/kWh/m2": 130,
    "out:El bought/kWh/m2": 530.25352,
    "out:Return %": 12,
    "out:Return/kSEK/y": 520,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 33.36,
    "out:EAHP (El)": 0,
    "out:PV (kr/kWp)": 17461.48474631725,
    "out:PV (kWp installed)": 90.5097,
    "out:PV (m2 panels)": 474.867263,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 84922.568824,
    "out:PV (kWh sold)": 68878.145463,
    "out:PV (% sold (El))": 81.106997,
    "out:PV (kWh self-consumed)": 16044.423361,
    "out:PV (ratio sold/self-consumed)": 4.292965,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 125995.478472,
    "out:EL kWh savings": 16044.422709,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 79599.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 261401.54,
    "out:DH kr savings": 125995.47847214497,
    "out:El kr savings": 32088.845417124598,
    "out:El kr sold": 103317.21819422109,
    "out:El kr saved": 32088.846722,
    "out:El kr return": 135406.06491622108,
    "out:% solar/total": 40,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 307701.251111,
    "out:% savings (space heating)": 24.424128,
    "out:Total_Water Heating (DH)": 106218.208041,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 96.64,
    "out:Etvv": 20,
    "out:Ef": 7.53,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 4360456.40844375,
    "out:Seasonal Variation ROI (%)": 6,
    "out:Seasonal Variation Payback": 16.666667,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 46.79792,
    "out:EL CO2": -108.956638,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -109.952146,
    "out:Bought CO2": 0.995508,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01000000120001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 100.956054,
    "out:DHW (DH)": 28.356,
    "out:Property Electricity": 12.57,
    "out:Electricity (inc PV)": 7.530921,
    "out:Total Energy Use Pre PV": 141.57,
    "out:Total Energy Use Post PV": 136.530921,
    "out:Primary Energy": 103.977033,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 41.398671,
    "out:CO2 Operational/m2": -62.518702,
    "out:Total CO2/m2": -21.120031,
    "out:Total CO2 (tons)": -67.246158,
    "out:Klimatpaverkan": -267.38158,
    "out:Initial Cost/MSEK": 4.55407243344375,
    "out:Running cost/(Apt SEK y)": 25214.085106,
    "out:Running Cost over RSP/MSEK": 9.29126,
    "out:LCP/MSEK": -0.440612,
    "out:ROI% old": 12.903553,
    "out:Payback discounted": 9,
    "out:Atemp": 3184,
    "out:Umean": 0.65,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 137,
    "out:Energy use kWh/m2": 137,
    "out:Energy savings %": 33.6,
    "out:Op energy cost/MSEK": 69,
    "out:El price/MSEK": 40,
    "out:Heating price/MSEK": 25,
    "out:DH+ DHW/kWh/m2": 129,
    "out:El bought/kWh/m2": 530.25352,
    "out:Return %": 12,
    "out:Return/kSEK/y": 530,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 28.356,
    "out:EAHP (El)": 0,
    "out:PV (kr/kWp)": 17461.48474631725,
    "out:PV (kWp installed)": 90.5097,
    "out:PV (m2 panels)": 474.867263,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 84922.568824,
    "out:PV (kWh sold)": 68878.145463,
    "out:PV (% sold (El))": 81.106997,
    "out:PV (kWh self-consumed)": 16044.423361,
    "out:PV (ratio sold/self-consumed)": 4.292965,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 128185.483161,
    "out:EL kWh savings": 16044.422709,
    "out:Sewage heat recovery cost": 273216,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 263591.55,
    "out:DH kr savings": 128185.48316121747,
    "out:El kr savings": 32088.845417124598,
    "out:El kr sold": 103317.21819422109,
    "out:El kr saved": 32088.846722,
    "out:El kr return": 135406.06491622108,
    "out:% solar/total": 40,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 321443.977628,
    "out:% savings (space heating)": 21.048716,
    "out:Total_Water Heating (DH)": 90285.476835,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 100.96,
    "out:Etvv": 17,
    "out:Ef": 7.53,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 4554072.43344375,
    "out:Seasonal Variation ROI (%)": 6,
    "out:Seasonal Variation Payback": 16.666667,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 46.437936,
    "out:EL CO2": -108.956638,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -109.952146,
    "out:Bought CO2": 0.995508,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01000000120001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 96.639869,
    "out:DHW (DH)": 28.356,
    "out:Property Electricity": 12.57,
    "out:Electricity (inc PV)": 7.530921,
    "out:Total Energy Use Pre PV": 137.57,
    "out:Total Energy Use Post PV": 132.530921,
    "out:Primary Energy": 100.620001,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 41.398671,
    "out:CO2 Operational/m2": -63.958638,
    "out:Total CO2/m2": -22.559967,
    "out:Total CO2 (tons)": -71.830913,
    "out:Klimatpaverkan": -271.966334,
    "out:Initial Cost/MSEK": 4.63367240844375,
    "out:Running cost/(Apt SEK y)": 24395.723404,
    "out:Running Cost over RSP/MSEK": 8.99042,
    "out:LCP/MSEK": -0.219372,
    "out:ROI% old": 13.609384,
    "out:Payback discounted": 9,
    "out:Atemp": 3184,
    "out:Umean": 0.65,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 133,
    "out:Energy use kWh/m2": 133,
    "out:Energy savings %": 37.6,
    "out:Op energy cost/MSEK": 67,
    "out:El price/MSEK": 38,
    "out:Heating price/MSEK": 24,
    "out:DH+ DHW/kWh/m2": 125,
    "out:El bought/kWh/m2": 530.25352,
    "out:Return %": 12,
    "out:Return/kSEK/y": 568,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 28.356,
    "out:EAHP (El)": 0,
    "out:PV (kr/kWp)": 17461.48474631725,
    "out:PV (kWp installed)": 90.5097,
    "out:PV (m2 panels)": 474.867263,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 84922.568824,
    "out:PV (kWh sold)": 68878.145463,
    "out:PV (% sold (El))": 81.106997,
    "out:PV (kWh self-consumed)": 16044.423361,
    "out:PV (ratio sold/self-consumed)": 4.292965,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 141928.209678,
    "out:EL kWh savings": 16044.422709,
    "out:Sewage heat recovery cost": 273216,
    "out:Balancing hydronic system cost": 79599.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 277334.27,
    "out:DH kr savings": 141928.20967831303,
    "out:El kr savings": 32088.845417124598,
    "out:El kr sold": 103317.21819422109,
    "out:El kr saved": 32088.846722,
    "out:El kr return": 135406.06491622108,
    "out:% solar/total": 40,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 307701.251111,
    "out:% savings (space heating)": 24.424128,
    "out:Total_Water Heating (DH)": 90285.476835,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 96.64,
    "out:Etvv": 17,
    "out:Ef": 7.53,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 4633672.40844375,
    "out:Seasonal Variation ROI (%)": 6,
    "out:Seasonal Variation Payback": 16.666667,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 44.998,
    "out:EL CO2": -108.956638,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -109.952146,
    "out:Bought CO2": 0.995508,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01000000120100000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 1,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 100.956054,
    "out:DHW (DH)": 33.36,
    "out:Property Electricity": 9.976115,
    "out:Electricity (inc PV)": 5.879188,
    "out:Total Energy Use Pre PV": 143.976115,
    "out:Total Energy Use Post PV": 139.879188,
    "out:Primary Energy": 103.103913,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 40.87463,
    "out:CO2 Operational/m2": -65.804508,
    "out:Total CO2/m2": -24.929878,
    "out:Total CO2 (tons)": -79.376708,
    "out:Klimatpaverkan": -279.512129,
    "out:Initial Cost/MSEK": 4.427649304963575,
    "out:Running cost/(Apt SEK y)": 25917.489362,
    "out:Running Cost over RSP/MSEK": 9.55117,
    "out:LCP/MSEK": -0.574099,
    "out:ROI% old": 12.433395,
    "out:Payback discounted": 10,
    "out:Atemp": 3184,
    "out:Umean": 0.65,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 140,
    "out:Energy use kWh/m2": 140,
    "out:Energy savings %": 30.7,
    "out:Op energy cost/MSEK": 71,
    "out:El price/MSEK": 41,
    "out:Heating price/MSEK": 26,
    "out:DH+ DHW/kWh/m2": 134,
    "out:El bought/kWh/m2": 413.65837,
    "out:Return %": 11,
    "out:Return/kSEK/y": 497,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 33.36,
    "out:EAHP (El)": 0,
    "out:PV (kr/kWp)": 17461.48474631725,
    "out:PV (kWp installed)": 90.5097,
    "out:PV (m2 panels)": 474.867263,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 84922.568824,
    "out:PV (kWh sold)": 71877.957046,
    "out:PV (% sold (El))": 84.639405,
    "out:PV (kWh self-consumed)": 13044.611778,
    "out:PV (ratio sold/self-consumed)": 5.510165,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 112252.751955,
    "out:EL kWh savings": 21303.538998,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 262676.77,
    "out:DH kr savings": 112252.7519550495,
    "out:El kr savings": 42607.077996404216,
    "out:El kr sold": 107816.93556907076,
    "out:El kr saved": 26089.223556,
    "out:El kr return": 133906.15912507076,
    "out:% solar/total": 33,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 321443.977628,
    "out:% savings (space heating)": 21.048716,
    "out:Total_Water Heating (DH)": 106218.208041,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 100.96,
    "out:Etvv": 20,
    "out:Ef": 5.88,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 4427649.304963575,
    "out:Seasonal Variation ROI (%)": 6,
    "out:Seasonal Variation Payback": 16.666667,
    "out:New AHU Cost/SEK": 146792.87151982498,
    "out:DH CO2": 48.237856,
    "out:EL CO2": -114.042364,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -114.819396,
    "out:Bought CO2": 0.777032,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01000000120100100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 1,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 96.639869,
    "out:DHW (DH)": 33.36,
    "out:Property Electricity": 9.976115,
    "out:Electricity (inc PV)": 5.879188,
    "out:Total Energy Use Pre PV": 139.976115,
    "out:Total Energy Use Post PV": 135.879188,
    "out:Primary Energy": 99.746881,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 40.87463,
    "out:CO2 Operational/m2": -67.244444,
    "out:Total CO2/m2": -26.369814,
    "out:Total CO2 (tons)": -83.961463,
    "out:Klimatpaverkan": -284.096884,
    "out:Initial Cost/MSEK": 4.507249279963576,
    "out:Running cost/(Apt SEK y)": 25099.148936,
    "out:Running Cost over RSP/MSEK": 9.25033,
    "out:LCP/MSEK": -0.352859,
    "out:ROI% old": 13.167328,
    "out:Payback discounted": 9,
    "out:Atemp": 3184,
    "out:Umean": 0.65,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 136,
    "out:Energy use kWh/m2": 136,
    "out:Energy savings %": 34.6,
    "out:Op energy cost/MSEK": 69,
    "out:El price/MSEK": 39,
    "out:Heating price/MSEK": 25,
    "out:DH+ DHW/kWh/m2": 130,
    "out:El bought/kWh/m2": 413.65837,
    "out:Return %": 12,
    "out:Return/kSEK/y": 535,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 33.36,
    "out:EAHP (El)": 0,
    "out:PV (kr/kWp)": 17461.48474631725,
    "out:PV (kWp installed)": 90.5097,
    "out:PV (m2 panels)": 474.867263,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 84922.568824,
    "out:PV (kWh sold)": 71877.957046,
    "out:PV (% sold (El))": 84.639405,
    "out:PV (kWh self-consumed)": 13044.611778,
    "out:PV (ratio sold/self-consumed)": 5.510165,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 125995.478472,
    "out:EL kWh savings": 21303.538998,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 79599.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 276419.49,
    "out:DH kr savings": 125995.47847214497,
    "out:El kr savings": 42607.077996404216,
    "out:El kr sold": 107816.93556907076,
    "out:El kr saved": 26089.223556,
    "out:El kr return": 133906.15912507076,
    "out:% solar/total": 33,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 307701.251111,
    "out:% savings (space heating)": 24.424128,
    "out:Total_Water Heating (DH)": 106218.208041,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 96.64,
    "out:Etvv": 20,
    "out:Ef": 5.88,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 4507249.279963575,
    "out:Seasonal Variation ROI (%)": 6,
    "out:Seasonal Variation Payback": 16.666667,
    "out:New AHU Cost/SEK": 146792.87151982498,
    "out:DH CO2": 46.79792,
    "out:EL CO2": -114.042364,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -114.819396,
    "out:Bought CO2": 0.777032,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01000000120101000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 1,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 100.956054,
    "out:DHW (DH)": 28.356,
    "out:Property Electricity": 9.976115,
    "out:Electricity (inc PV)": 5.879188,
    "out:Total Energy Use Pre PV": 138.976115,
    "out:Total Energy Use Post PV": 134.879188,
    "out:Primary Energy": 101.003913,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 41.583173,
    "out:CO2 Operational/m2": -67.604428,
    "out:Total CO2/m2": -26.021255,
    "out:Total CO2 (tons)": -82.851651,
    "out:Klimatpaverkan": -282.987072,
    "out:Initial Cost/MSEK": 4.700865304963575,
    "out:Running cost/(Apt SEK y)": 24894.553191,
    "out:Running Cost over RSP/MSEK": 9.17512,
    "out:LCP/MSEK": -0.471265,
    "out:ROI% old": 12.853561,
    "out:Payback discounted": 9,
    "out:Atemp": 3184,
    "out:Umean": 0.65,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 135,
    "out:Energy use kWh/m2": 135,
    "out:Energy savings %": 35.6,
    "out:Op energy cost/MSEK": 68,
    "out:El price/MSEK": 39,
    "out:Heating price/MSEK": 25,
    "out:DH+ DHW/kWh/m2": 129,
    "out:El bought/kWh/m2": 413.65837,
    "out:Return %": 12,
    "out:Return/kSEK/y": 545,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 28.356,
    "out:EAHP (El)": 0,
    "out:PV (kr/kWp)": 17461.48474631725,
    "out:PV (kWp installed)": 90.5097,
    "out:PV (m2 panels)": 474.867263,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 84922.568824,
    "out:PV (kWh sold)": 71877.957046,
    "out:PV (% sold (El))": 84.639405,
    "out:PV (kWh self-consumed)": 13044.611778,
    "out:PV (ratio sold/self-consumed)": 5.510165,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 128185.483161,
    "out:EL kWh savings": 21303.538998,
    "out:Sewage heat recovery cost": 273216,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 278609.5,
    "out:DH kr savings": 128185.48316121747,
    "out:El kr savings": 42607.077996404216,
    "out:El kr sold": 107816.93556907076,
    "out:El kr saved": 26089.223556,
    "out:El kr return": 133906.15912507076,
    "out:% solar/total": 33,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 321443.977628,
    "out:% savings (space heating)": 21.048716,
    "out:Total_Water Heating (DH)": 90285.476835,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 100.96,
    "out:Etvv": 17,
    "out:Ef": 5.88,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 4700865.304963575,
    "out:Seasonal Variation ROI (%)": 6,
    "out:Seasonal Variation Payback": 16.666667,
    "out:New AHU Cost/SEK": 146792.87151982498,
    "out:DH CO2": 46.437936,
    "out:EL CO2": -114.042364,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -114.819396,
    "out:Bought CO2": 0.777032,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01000000120101100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 1,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 96.639869,
    "out:DHW (DH)": 28.356,
    "out:Property Electricity": 9.976115,
    "out:Electricity (inc PV)": 5.879188,
    "out:Total Energy Use Pre PV": 134.976115,
    "out:Total Energy Use Post PV": 130.879188,
    "out:Primary Energy": 97.646881,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 41.583173,
    "out:CO2 Operational/m2": -69.044364,
    "out:Total CO2/m2": -27.461191,
    "out:Total CO2 (tons)": -87.436406,
    "out:Klimatpaverkan": -287.571827,
    "out:Initial Cost/MSEK": 4.7804652799635745,
    "out:Running cost/(Apt SEK y)": 24076.191489,
    "out:Running Cost over RSP/MSEK": 8.87427,
    "out:LCP/MSEK": -0.250015,
    "out:ROI% old": 13.538581,
    "out:Payback discounted": 9,
    "out:Atemp": 3184,
    "out:Umean": 0.65,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 131,
    "out:Energy use kWh/m2": 131,
    "out:Energy savings %": 39.7,
    "out:Op energy cost/MSEK": 66,
    "out:El price/MSEK": 38,
    "out:Heating price/MSEK": 24,
    "out:DH+ DHW/kWh/m2": 125,
    "out:El bought/kWh/m2": 413.65837,
    "out:Return %": 12,
    "out:Return/kSEK/y": 583,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 28.356,
    "out:EAHP (El)": 0,
    "out:PV (kr/kWp)": 17461.48474631725,
    "out:PV (kWp installed)": 90.5097,
    "out:PV (m2 panels)": 474.867263,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 84922.568824,
    "out:PV (kWh sold)": 71877.957046,
    "out:PV (% sold (El))": 84.639405,
    "out:PV (kWh self-consumed)": 13044.611778,
    "out:PV (ratio sold/self-consumed)": 5.510165,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 141928.209678,
    "out:EL kWh savings": 21303.538998,
    "out:Sewage heat recovery cost": 273216,
    "out:Balancing hydronic system cost": 79599.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 292352.22,
    "out:DH kr savings": 141928.20967831303,
    "out:El kr savings": 42607.077996404216,
    "out:El kr sold": 107816.93556907076,
    "out:El kr saved": 26089.223556,
    "out:El kr return": 133906.15912507076,
    "out:% solar/total": 33,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 307701.251111,
    "out:% savings (space heating)": 24.424128,
    "out:Total_Water Heating (DH)": 90285.476835,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 96.64,
    "out:Etvv": 17,
    "out:Ef": 5.88,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 4780465.279963574,
    "out:Seasonal Variation ROI (%)": 6,
    "out:Seasonal Variation Payback": 16.666667,
    "out:New AHU Cost/SEK": 146792.87151982498,
    "out:DH CO2": 44.998,
    "out:EL CO2": -114.042364,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -114.819396,
    "out:Bought CO2": 0.777032,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01000001000000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 58.471431,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 40.379992,
    "out:Electricity (inc PV)": 40.379992,
    "out:Total Energy Use Pre PV": 98.379992,
    "out:Total Energy Use Post PV": 98.379992,
    "out:Primary Energy": 110.398621,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 14.964872,
    "out:CO2 Operational/m2": 26.209554,
    "out:Total CO2/m2": 41.174426,
    "out:Total CO2 (tons)": 131.099333,
    "out:Klimatpaverkan": -69.036088,
    "out:Initial Cost/MSEK": 5.560624411073993,
    "out:Running cost/(Apt SEK y)": 17337.212766,
    "out:Running Cost over RSP/MSEK": 6.35015,
    "out:LCP/MSEK": 1.493946,
    "out:ROI% old": 18.123787,
    "out:Payback discounted": 7,
    "out:Atemp": 3184,
    "out:Umean": 0.65,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 98,
    "out:Energy use kWh/m2": 98,
    "out:Energy savings %": 86.7,
    "out:Op energy cost/MSEK": 45,
    "out:El price/MSEK": 32,
    "out:Heating price/MSEK": 11,
    "out:DH+ DHW/kWh/m2": 58,
    "out:El bought/kWh/m2": 2826.59944,
    "out:Return %": 16,
    "out:Return/kSEK/y": 900,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.6502792112,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 41.7,
    "out:EAHP (El)": 25.510492,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 353741.957336,
    "out:EL kWh savings": -88546.987886,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 176647.98,
    "out:DH kr savings": 353741.9573359012,
    "out:El kr savings": -177093.97577205533,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 186172.980288,
    "out:% savings (space heating)": 54.273227,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 997124.4110739925,
    "out:Eupp": 48.49,
    "out:Etvv": 0,
    "out:Ef": 38.34,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 5560624.411073993,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 20.879072,
    "out:EL CO2": 5.330482,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 5.330482,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01000001000000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 54.375376,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 40.313286,
    "out:Electricity (inc PV)": 40.313286,
    "out:Total Energy Use Pre PV": 94.313286,
    "out:Total Energy Use Post PV": 94.313286,
    "out:Primary Energy": 107.193324,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 14.964872,
    "out:CO2 Operational/m2": 24.760812,
    "out:Total CO2/m2": 39.725684,
    "out:Total CO2 (tons)": 126.48654,
    "out:Klimatpaverkan": -73.648881,
    "out:Initial Cost/MSEK": 5.640224386073992,
    "out:Running cost/(Apt SEK y)": 16509.808511,
    "out:Running Cost over RSP/MSEK": 6.04602,
    "out:LCP/MSEK": 1.718476,
    "out:ROI% old": 18.638316,
    "out:Payback discounted": 7,
    "out:Atemp": 3184,
    "out:Umean": 0.65,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 94,
    "out:Energy use kWh/m2": 94,
    "out:Energy savings %": 94.7,
    "out:Op energy cost/MSEK": 43,
    "out:El price/MSEK": 31,
    "out:Heating price/MSEK": 11,
    "out:DH+ DHW/kWh/m2": 54,
    "out:El bought/kWh/m2": 2821.93002,
    "out:Return %": 17,
    "out:Return/kSEK/y": 939,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.6502792112,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 41.7,
    "out:EAHP (El)": 25.443786,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 366783.792532,
    "out:EL kWh savings": -88334.596046,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 79599.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 190114.6,
    "out:DH kr savings": 366783.7925318805,
    "out:El kr savings": -176669.19209186404,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 173131.145092,
    "out:% savings (space heating)": 57.47649,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 997124.4110739925,
    "out:Eupp": 44.52,
    "out:Etvv": 0,
    "out:Ef": 38.24,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 5640224.386073993,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 19.439136,
    "out:EL CO2": 5.321676,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 5.321676,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01000001000001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 54.646814,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 39.643512,
    "out:Electricity (inc PV)": 39.643512,
    "out:Total Energy Use Pre PV": 94.643512,
    "out:Total Energy Use Post PV": 94.643512,
    "out:Primary Energy": 107.413234,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 15.673415,
    "out:CO2 Operational/m2": 25.032381,
    "out:Total CO2/m2": 40.705796,
    "out:Total CO2 (tons)": 129.607215,
    "out:Klimatpaverkan": -70.528206,
    "out:Initial Cost/MSEK": 5.833840411073993,
    "out:Running cost/(Apt SEK y)": 16623.659574,
    "out:Running Cost over RSP/MSEK": 6.08826,
    "out:LCP/MSEK": 1.48262,
    "out:ROI% old": 17.916304,
    "out:Payback discounted": 7,
    "out:Atemp": 3184,
    "out:Umean": 0.65,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 95,
    "out:Energy use kWh/m2": 95,
    "out:Energy savings %": 92.6,
    "out:Op energy cost/MSEK": 43,
    "out:El price/MSEK": 31,
    "out:Heating price/MSEK": 11,
    "out:DH+ DHW/kWh/m2": 55,
    "out:El bought/kWh/m2": 2775.04584,
    "out:Return %": 16,
    "out:Return/kSEK/y": 933,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.6502792112,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 35.445,
    "out:EAHP (El)": 24.774012,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 365919.5342,
    "out:EL kWh savings": -86202.036272,
    "out:Sewage heat recovery cost": 273216,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 193515.46,
    "out:DH kr savings": 365919.5341999181,
    "out:El kr savings": -172404.072543151,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 173995.403424,
    "out:% savings (space heating)": 57.264216,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 997124.4110739925,
    "out:Eupp": 46.36,
    "out:Etvv": 0,
    "out:Ef": 37.85,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 5833840.411073993,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 19.79912,
    "out:EL CO2": 5.233261,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 5.233261,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01000001000001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 50.593123,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 39.563969,
    "out:Electricity (inc PV)": 39.563969,
    "out:Total Energy Use Pre PV": 90.563969,
    "out:Total Energy Use Post PV": 90.563969,
    "out:Primary Energy": 104.215715,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 15.673415,
    "out:CO2 Operational/m2": 23.581944,
    "out:Total CO2/m2": 39.255359,
    "out:Total CO2 (tons)": 124.989025,
    "out:Klimatpaverkan": -75.146396,
    "out:Initial Cost/MSEK": 5.913440386073992,
    "out:Running cost/(Apt SEK y)": 15794.531915,
    "out:Running Cost over RSP/MSEK": 5.7835,
    "out:LCP/MSEK": 1.70778,
    "out:ROI% old": 18.411375,
    "out:Payback discounted": 7,
    "out:Atemp": 3184,
    "out:Umean": 0.65,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 91,
    "out:Energy use kWh/m2": 91,
    "out:Energy savings %": 101.1,
    "out:Op energy cost/MSEK": 41,
    "out:El price/MSEK": 29,
    "out:Heating price/MSEK": 10,
    "out:DH+ DHW/kWh/m2": 51,
    "out:El bought/kWh/m2": 2769.47783,
    "out:Return %": 16,
    "out:Return/kSEK/y": 972,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.6502792112,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 35.445,
    "out:EAHP (El)": 24.694469,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 378826.48246,
    "out:EL kWh savings": -85948.771436,
    "out:Sewage heat recovery cost": 273216,
    "out:Balancing hydronic system cost": 79599.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 206928.94,
    "out:DH kr savings": 378826.48246048216,
    "out:El kr savings": -171897.5428715554,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 161088.455164,
    "out:% savings (space heating)": 60.434348,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 997124.4110739925,
    "out:Eupp": 42.43,
    "out:Etvv": 0,
    "out:Ef": 37.74,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 5913440.386073992,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 18.359184,
    "out:EL CO2": 5.22276,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 5.22276,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01000001010000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 58.471431,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 40.379992,
    "out:Electricity (inc PV)": 30.344858,
    "out:Total Energy Use Pre PV": 98.379992,
    "out:Total Energy Use Post PV": 88.344858,
    "out:Primary Energy": 92.335379,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 29.703972,
    "out:CO2 Operational/m2": 8.522201,
    "out:Total CO2/m2": 38.226173,
    "out:Total CO2 (tons)": 121.712098,
    "out:Klimatpaverkan": -78.423323,
    "out:Initial Cost/MSEK": 6.350844805296493,
    "out:Running cost/(Apt SEK y)": 15642.148936,
    "out:Running Cost over RSP/MSEK": 5.74365,
    "out:LCP/MSEK": 1.310225,
    "out:ROI% old": 17.23296,
    "out:Payback discounted": 7,
    "out:Atemp": 3184,
    "out:Umean": 0.65,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 88,
    "out:Energy use kWh/m2": 88,
    "out:Energy savings %": 108,
    "out:Op energy cost/MSEK": 41,
    "out:El price/MSEK": 28,
    "out:Heating price/MSEK": 11,
    "out:DH+ DHW/kWh/m2": 58,
    "out:El bought/kWh/m2": 2145.858265,
    "out:Return %": 15,
    "out:Return/kSEK/y": 980,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.6502792112,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 41.7,
    "out:EAHP (El)": 25.510492,
    "out:PV (kr/kWp)": 17461.56255567083,
    "out:PV (kWp installed)": 45.25485,
    "out:PV (m2 panels)": 237.433632,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 42461.284501,
    "out:PV (kWh sold)": 10509.427713,
    "out:PV (% sold (El))": 24.750612,
    "out:PV (kWh self-consumed)": 31951.856789,
    "out:PV (ratio sold/self-consumed)": 0.328914,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 353741.957336,
    "out:EL kWh savings": -56595.130844,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 256315.84,
    "out:DH kr savings": 353741.9573359012,
    "out:El kr savings": -113190.26168737208,
    "out:El kr sold": 15764.14156885914,
    "out:El kr saved": 63903.713578,
    "out:El kr return": 79667.85514685915,
    "out:% solar/total": 80,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 186172.980288,
    "out:% savings (space heating)": 54.273227,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 997124.4110739925,
    "out:Eupp": 48.49,
    "out:Etvv": 0,
    "out:Ef": 28.6,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 6350844.805296493,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 20.879072,
    "out:EL CO2": -12.356871,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -16.372352,
    "out:Bought CO2": 4.015481,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01000001010000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 54.375376,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 40.313286,
    "out:Electricity (inc PV)": 30.287331,
    "out:Total Energy Use Pre PV": 94.313286,
    "out:Total Energy Use Post PV": 84.287331,
    "out:Primary Energy": 89.146605,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 29.703972,
    "out:CO2 Operational/m2": 7.027981,
    "out:Total CO2/m2": 36.731953,
    "out:Total CO2 (tons)": 116.954503,
    "out:Klimatpaverkan": -83.180918,
    "out:Initial Cost/MSEK": 6.430444780296494,
    "out:Running cost/(Apt SEK y)": 14815.06383,
    "out:Running Cost over RSP/MSEK": 5.43963,
    "out:LCP/MSEK": 1.534645,
    "out:ROI% old": 17.695043,
    "out:Payback discounted": 7,
    "out:Atemp": 3184,
    "out:Umean": 0.65,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 84,
    "out:Energy use kWh/m2": 84,
    "out:Energy savings %": 117.9,
    "out:Op energy cost/MSEK": 39,
    "out:El price/MSEK": 27,
    "out:Heating price/MSEK": 11,
    "out:DH+ DHW/kWh/m2": 54,
    "out:El bought/kWh/m2": 2141.785739,
    "out:Return %": 16,
    "out:Return/kSEK/y": 1018,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.6502792112,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 41.7,
    "out:EAHP (El)": 25.443786,
    "out:PV (kr/kWp)": 17461.56255567083,
    "out:PV (kWp installed)": 45.25485,
    "out:PV (m2 panels)": 237.433632,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 42461.284501,
    "out:PV (kWh sold)": 10538.653748,
    "out:PV (% sold (El))": 24.819442,
    "out:PV (kWh self-consumed)": 31922.630754,
    "out:PV (ratio sold/self-consumed)": 0.330131,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 366783.792532,
    "out:EL kWh savings": -56411.964931,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 79599.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 269767.84,
    "out:DH kr savings": 366783.7925318805,
    "out:El kr savings": -112823.92986159376,
    "out:El kr sold": 15807.980621530418,
    "out:El kr saved": 63845.261508,
    "out:El kr return": 79653.24212953042,
    "out:% solar/total": 80,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 173131.145092,
    "out:% savings (space heating)": 57.47649,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 997124.4110739925,
    "out:Eupp": 44.52,
    "out:Etvv": 0,
    "out:Ef": 28.51,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 6430444.780296493,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 19.439136,
    "out:EL CO2": -12.411155,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -16.419019,
    "out:Bought CO2": 4.007864,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01000001010001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 54.646814,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 39.643512,
    "out:Electricity (inc PV)": 29.710569,
    "out:Total Energy Use Pre PV": 94.643512,
    "out:Total Energy Use Post PV": 84.710569,
    "out:Primary Energy": 89.533936,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 30.412515,
    "out:CO2 Operational/m2": 6.83825,
    "out:Total CO2/m2": 37.250765,
    "out:Total CO2 (tons)": 118.6064,
    "out:Klimatpaverkan": -81.529021,
    "out:Initial Cost/MSEK": 6.624060805296493,
    "out:Running cost/(Apt SEK y)": 14932.06383,
    "out:Running Cost over RSP/MSEK": 5.48299,
    "out:LCP/MSEK": 1.297669,
    "out:ROI% old": 17.08432,
    "out:Payback discounted": 7,
    "out:Atemp": 3184,
    "out:Umean": 0.65,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 85,
    "out:Energy use kWh/m2": 85,
    "out:Energy savings %": 115.3,
    "out:Op energy cost/MSEK": 40,
    "out:El price/MSEK": 27,
    "out:Heating price/MSEK": 11,
    "out:DH+ DHW/kWh/m2": 55,
    "out:El bought/kWh/m2": 2100.953949,
    "out:Return %": 15,
    "out:Return/kSEK/y": 1013,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.6502792112,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 35.445,
    "out:EAHP (El)": 24.774012,
    "out:PV (kr/kWp)": 17461.56255567083,
    "out:PV (kWp installed)": 45.25485,
    "out:PV (m2 panels)": 237.433632,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 42461.284501,
    "out:PV (kWh sold)": 10834.802278,
    "out:PV (% sold (El))": 25.516897,
    "out:PV (kWh self-consumed)": 31626.482224,
    "out:PV (ratio sold/self-consumed)": 0.342586,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 365919.5342,
    "out:EL kWh savings": -54575.555275,
    "out:Sewage heat recovery cost": 273216,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 273020.63,
    "out:DH kr savings": 365919.5341999181,
    "out:El kr savings": -109151.11055066975,
    "out:El kr sold": 16252.203416780445,
    "out:El kr saved": 63252.964448,
    "out:El kr return": 79505.16786478044,
    "out:% solar/total": 79,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 173995.403424,
    "out:% savings (space heating)": 57.264216,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 997124.4110739925,
    "out:Eupp": 46.36,
    "out:Etvv": 0,
    "out:Ef": 28.18,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 6624060.805296493,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 19.79912,
    "out:EL CO2": -12.96087,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -16.892375,
    "out:Bought CO2": 3.931505,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01000001010001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 50.593123,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 39.563969,
    "out:Electricity (inc PV)": 29.642194,
    "out:Total Energy Use Pre PV": 90.563969,
    "out:Total Energy Use Post PV": 80.642194,
    "out:Primary Energy": 86.35652,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 30.412515,
    "out:CO2 Operational/m2": 5.332446,
    "out:Total CO2/m2": 35.744961,
    "out:Total CO2 (tons)": 113.811922,
    "out:Klimatpaverkan": -86.323499,
    "out:Initial Cost/MSEK": 6.703660780296493,
    "out:Running cost/(Apt SEK y)": 14103.297872,
    "out:Running Cost over RSP/MSEK": 5.17836,
    "out:LCP/MSEK": 1.522699,
    "out:ROI% old": 17.530635,
    "out:Payback discounted": 7,
    "out:Atemp": 3184,
    "out:Umean": 0.65,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 81,
    "out:Energy use kWh/m2": 81,
    "out:Energy savings %": 125.9,
    "out:Op energy cost/MSEK": 37,
    "out:El price/MSEK": 26,
    "out:Heating price/MSEK": 10,
    "out:DH+ DHW/kWh/m2": 51,
    "out:El bought/kWh/m2": 2096.112004,
    "out:Return %": 16,
    "out:Return/kSEK/y": 1052,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.6502792112,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 35.445,
    "out:EAHP (El)": 24.694469,
    "out:PV (kr/kWp)": 17461.56255567083,
    "out:PV (kWp installed)": 45.25485,
    "out:PV (m2 panels)": 237.433632,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 42461.284501,
    "out:PV (kWh sold)": 10870.362341,
    "out:PV (% sold (El))": 25.600644,
    "out:PV (kWh self-consumed)": 31590.92216,
    "out:PV (ratio sold/self-consumed)": 0.344098,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 378826.48246,
    "out:EL kWh savings": -54357.849341,
    "out:Sewage heat recovery cost": 273216,
    "out:Balancing hydronic system cost": 79599.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 286416.33,
    "out:DH kr savings": 378826.48246048216,
    "out:El kr savings": -108715.69868167631,
    "out:El kr sold": 16305.543511487307,
    "out:El kr saved": 63181.84432,
    "out:El kr return": 79487.3878314873,
    "out:% solar/total": 79,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 161088.455164,
    "out:% savings (space heating)": 60.434348,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 997124.4110739925,
    "out:Eupp": 42.43,
    "out:Etvv": 0,
    "out:Ef": 28.08,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 6703660.780296493,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 18.359184,
    "out:EL CO2": -13.026738,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -16.94919,
    "out:Bought CO2": 3.922452,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01000001020000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 58.471431,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 40.379992,
    "out:Electricity (inc PV)": 27.476838,
    "out:Total Energy Use Pre PV": 98.379992,
    "out:Total Energy Use Post PV": 85.476838,
    "out:Primary Energy": 87.172943,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 44.443071,
    "out:CO2 Operational/m2": -44.941443,
    "out:Total CO2/m2": -0.498372,
    "out:Total CO2 (tons)": -1.586816,
    "out:Klimatpaverkan": -201.722237,
    "out:Initial Cost/MSEK": 7.141058157017743,
    "out:Running cost/(Apt SEK y)": 14189.851064,
    "out:Running Cost over RSP/MSEK": 5.22425,
    "out:LCP/MSEK": 1.039412,
    "out:ROI% old": 16.365062,
    "out:Payback discounted": 7,
    "out:Atemp": 3184,
    "out:Umean": 0.65,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 85,
    "out:Energy use kWh/m2": 85,
    "out:Energy savings %": 115.3,
    "out:Op energy cost/MSEK": 38,
    "out:El price/MSEK": 25,
    "out:Heating price/MSEK": 11,
    "out:DH+ DHW/kWh/m2": 58,
    "out:El bought/kWh/m2": 1941.893637,
    "out:Return %": 15,
    "out:Return/kSEK/y": 1048,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.6502792112,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 41.7,
    "out:EAHP (El)": 25.510492,
    "out:PV (kr/kWp)": 17461.48474631725,
    "out:PV (kWp installed)": 90.5097,
    "out:PV (m2 panels)": 474.867263,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 84922.568824,
    "out:PV (kWh sold)": 43838.939546,
    "out:PV (% sold (El))": 51.622249,
    "out:PV (kWh self-consumed)": 41083.629278,
    "out:PV (ratio sold/self-consumed)": 1.067066,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 353741.957336,
    "out:EL kWh savings": -47463.357911,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 324573.65,
    "out:DH kr savings": 353741.9573359012,
    "out:El kr savings": -94926.71582249839,
    "out:El kr sold": 65758.40931945562,
    "out:El kr saved": 82167.258556,
    "out:El kr return": 147925.66787545563,
    "out:% solar/total": 103,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 186172.980288,
    "out:% savings (space heating)": 54.273227,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 997124.4110739925,
    "out:Eupp": 48.49,
    "out:Etvv": 0,
    "out:Ef": 25.91,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 7141058.157017743,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 20.879072,
    "out:EL CO2": -65.820515,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -69.45593,
    "out:Bought CO2": 3.635415,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01000001020000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 54.375376,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 40.313286,
    "out:Electricity (inc PV)": 27.425247,
    "out:Total Energy Use Pre PV": 94.313286,
    "out:Total Energy Use Post PV": 81.425247,
    "out:Primary Energy": 83.994854,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 44.443071,
    "out:CO2 Operational/m2": -46.465631,
    "out:Total CO2/m2": -2.02256,
    "out:Total CO2 (tons)": -6.439829,
    "out:Klimatpaverkan": -206.57525,
    "out:Initial Cost/MSEK": 7.220658132017744,
    "out:Running cost/(Apt SEK y)": 13362.978723,
    "out:Running Cost over RSP/MSEK": 4.9203,
    "out:LCP/MSEK": 1.263762,
    "out:ROI% old": 16.786005,
    "out:Payback discounted": 7,
    "out:Atemp": 3184,
    "out:Umean": 0.65,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 81,
    "out:Energy use kWh/m2": 81,
    "out:Energy savings %": 125.9,
    "out:Op energy cost/MSEK": 36,
    "out:El price/MSEK": 24,
    "out:Heating price/MSEK": 11,
    "out:DH+ DHW/kWh/m2": 54,
    "out:El bought/kWh/m2": 1938.237616,
    "out:Return %": 15,
    "out:Return/kSEK/y": 1087,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.6502792112,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 41.7,
    "out:EAHP (El)": 25.443786,
    "out:PV (kr/kWp)": 17461.48474631725,
    "out:PV (kWp installed)": 90.5097,
    "out:PV (m2 panels)": 474.867263,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 84922.568824,
    "out:PV (kWh sold)": 43887.065716,
    "out:PV (% sold (El))": 51.678919,
    "out:PV (kWh self-consumed)": 41035.503108,
    "out:PV (ratio sold/self-consumed)": 1.06949,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 366783.792532,
    "out:EL kWh savings": -47299.092217,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 79599.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 338016.21,
    "out:DH kr savings": 366783.7925318805,
    "out:El kr savings": -94598.18443334664,
    "out:El kr sold": 65830.59857403793,
    "out:El kr saved": 82071.006216,
    "out:El kr return": 147901.6047900379,
    "out:% solar/total": 103,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 173131.145092,
    "out:% savings (space heating)": 57.47649,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 997124.4110739925,
    "out:Eupp": 44.52,
    "out:Etvv": 0,
    "out:Ef": 25.83,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 7220658.132017744,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 19.439136,
    "out:EL CO2": -65.904767,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -69.533347,
    "out:Bought CO2": 3.62858,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01000001020001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 54.646814,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 39.643512,
    "out:Electricity (inc PV)": 26.907955,
    "out:Total Energy Use Pre PV": 94.643512,
    "out:Total Energy Use Post PV": 81.907955,
    "out:Primary Energy": 84.489231,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 45.151614,
    "out:CO2 Operational/m2": -46.956064,
    "out:Total CO2/m2": -1.80445,
    "out:Total CO2 (tons)": -5.745367,
    "out:Klimatpaverkan": -205.880788,
    "out:Initial Cost/MSEK": 7.4142741570177435,
    "out:Running cost/(Apt SEK y)": 13481.978723,
    "out:Running Cost over RSP/MSEK": 4.96439,
    "out:LCP/MSEK": 1.026056,
    "out:ROI% old": 16.262704,
    "out:Payback discounted": 8,
    "out:Atemp": 3184,
    "out:Umean": 0.65,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 82,
    "out:Energy use kWh/m2": 82,
    "out:Energy savings %": 123.2,
    "out:Op energy cost/MSEK": 36,
    "out:El price/MSEK": 24,
    "out:Heating price/MSEK": 11,
    "out:DH+ DHW/kWh/m2": 55,
    "out:El bought/kWh/m2": 1901.588827,
    "out:Return %": 15,
    "out:Return/kSEK/y": 1081,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.6502792112,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 35.445,
    "out:EAHP (El)": 24.774012,
    "out:PV (kr/kWp)": 17461.48474631725,
    "out:PV (kWp installed)": 90.5097,
    "out:PV (m2 panels)": 474.867263,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 84922.568824,
    "out:PV (kWh sold)": 44372.568274,
    "out:PV (% sold (El))": 52.250619,
    "out:PV (kWh self-consumed)": 40550.00055,
    "out:PV (ratio sold/self-consumed)": 1.094268,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 365919.5342,
    "out:EL kWh savings": -45652.034984,
    "out:Sewage heat recovery cost": 273216,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 341174.32,
    "out:DH kr savings": 365919.5341999181,
    "out:El kr savings": -91304.06996847823,
    "out:El kr sold": 66558.85241145037,
    "out:El kr saved": 81100.0011,
    "out:El kr return": 147658.85351145035,
    "out:% solar/total": 101,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 173995.403424,
    "out:% savings (space heating)": 57.264216,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 997124.4110739925,
    "out:Eupp": 46.36,
    "out:Etvv": 0,
    "out:Ef": 25.53,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 7414274.157017743,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 19.79912,
    "out:EL CO2": -66.755184,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -70.315262,
    "out:Bought CO2": 3.560078,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01000001020001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 50.593123,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 39.563969,
    "out:Electricity (inc PV)": 26.846615,
    "out:Total Energy Use Pre PV": 90.563969,
    "out:Total Energy Use Post PV": 77.846615,
    "out:Primary Energy": 81.324478,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 45.151614,
    "out:CO2 Operational/m2": -48.497545,
    "out:Total CO2/m2": -3.345931,
    "out:Total CO2 (tons)": -10.653441,
    "out:Klimatpaverkan": -210.788862,
    "out:Initial Cost/MSEK": 7.493874132017744,
    "out:Running cost/(Apt SEK y)": 12653.468085,
    "out:Running Cost over RSP/MSEK": 4.65985,
    "out:LCP/MSEK": 1.250996,
    "out:ROI% old": 16.670511,
    "out:Payback discounted": 7,
    "out:Atemp": 3184,
    "out:Umean": 0.65,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 78,
    "out:Energy use kWh/m2": 78,
    "out:Energy savings %": 134.6,
    "out:Op energy cost/MSEK": 34,
    "out:El price/MSEK": 23,
    "out:Heating price/MSEK": 10,
    "out:DH+ DHW/kWh/m2": 51,
    "out:El bought/kWh/m2": 1897.243543,
    "out:Return %": 15,
    "out:Return/kSEK/y": 1120,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.6502792112,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 35.445,
    "out:EAHP (El)": 24.694469,
    "out:PV (kr/kWp)": 17461.48474631725,
    "out:PV (kWp installed)": 90.5097,
    "out:PV (m2 panels)": 474.867263,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 84922.568824,
    "out:PV (kWh sold)": 44430.525937,
    "out:PV (% sold (El))": 52.318867,
    "out:PV (kWh self-consumed)": 40492.042887,
    "out:PV (ratio sold/self-consumed)": 1.097266,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 378826.48246,
    "out:EL kWh savings": -45456.728483,
    "out:Sewage heat recovery cost": 273216,
    "out:Balancing hydronic system cost": 79599.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 354558.81,
    "out:DH kr savings": 378826.48246048216,
    "out:El kr savings": -90913.45696600566,
    "out:El kr sold": 66645.7889051329,
    "out:El kr saved": 80984.085774,
    "out:El kr return": 147629.8746791329,
    "out:% solar/total": 101,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 161088.455164,
    "out:% savings (space heating)": 60.434348,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 997124.4110739925,
    "out:Eupp": 42.43,
    "out:Etvv": 0,
    "out:Ef": 25.44,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 7493874.132017744,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 18.359184,
    "out:EL CO2": -66.856729,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -70.408686,
    "out:Bought CO2": 3.551957,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01000001100000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 53.39455,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 39.391168,
    "out:Electricity (inc PV)": 39.391168,
    "out:Total Energy Use Pre PV": 92.391168,
    "out:Total Energy Use Post PV": 92.391168,
    "out:Primary Energy": 106.413518,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 14.964872,
    "out:CO2 Operational/m2": 24.279101,
    "out:Total CO2/m2": 39.243973,
    "out:Total CO2 (tons)": 124.952772,
    "out:Klimatpaverkan": -75.182649,
    "out:Initial Cost/MSEK": 5.6846720985739925,
    "out:Running cost/(Apt SEK y)": 16180.297872,
    "out:Running Cost over RSP/MSEK": 5.92541,
    "out:LCP/MSEK": 1.794638,
    "out:ROI% old": 18.795681,
    "out:Payback discounted": 7,
    "out:Atemp": 3184,
    "out:Umean": 0.65,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 92,
    "out:Energy use kWh/m2": 92,
    "out:Energy savings %": 98.9,
    "out:Op energy cost/MSEK": 42,
    "out:El price/MSEK": 30,
    "out:Heating price/MSEK": 10,
    "out:DH+ DHW/kWh/m2": 53,
    "out:El bought/kWh/m2": 2757.38176,
    "out:Return %": 17,
    "out:Return/kSEK/y": 954,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.6502792112,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 33.36,
    "out:EAHP (El)": 24.521668,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 369906.741576,
    "out:EL kWh savings": -85398.573217,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 199109.6,
    "out:DH kr savings": 369906.74157624913,
    "out:El kr savings": -170797.14643464205,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 170008.196048,
    "out:% savings (space heating)": 58.243531,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 997124.4110739925,
    "out:Eupp": 45.65,
    "out:Etvv": 0,
    "out:Ef": 37.69,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 5684672.098573992,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 19.079152,
    "out:EL CO2": 5.199949,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 5.199949,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01000001100000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 49.357405,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 39.30661,
    "out:Electricity (inc PV)": 39.30661,
    "out:Total Energy Use Pre PV": 88.30661,
    "out:Total Energy Use Post PV": 88.30661,
    "out:Primary Energy": 103.217443,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 14.964872,
    "out:CO2 Operational/m2": 22.828003,
    "out:Total CO2/m2": 37.792875,
    "out:Total CO2 (tons)": 120.332478,
    "out:Klimatpaverkan": -79.802943,
    "out:Initial Cost/MSEK": 5.764272073573992,
    "out:Running cost/(Apt SEK y)": 15350.489362,
    "out:Running Cost over RSP/MSEK": 5.6204,
    "out:LCP/MSEK": 2.020048,
    "out:ROI% old": 19.292041,
    "out:Payback discounted": 6,
    "out:Atemp": 3184,
    "out:Umean": 0.65,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 88,
    "out:Energy use kWh/m2": 88,
    "out:Energy savings %": 108,
    "out:Op energy cost/MSEK": 40,
    "out:El price/MSEK": 29,
    "out:Heating price/MSEK": 10,
    "out:DH+ DHW/kWh/m2": 49,
    "out:El bought/kWh/m2": 2751.4627,
    "out:Return %": 17,
    "out:Return/kSEK/y": 993,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.6502792112,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 33.36,
    "out:EAHP (El)": 24.43711,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 382761.007389,
    "out:EL kWh savings": -85129.340626,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 79599.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 212502.33,
    "out:DH kr savings": 382761.0073886643,
    "out:El kr savings": -170258.68125265528,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 157153.930236,
    "out:% savings (space heating)": 61.400725,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 997124.4110739925,
    "out:Eupp": 41.74,
    "out:Etvv": 0,
    "out:Ef": 37.57,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 5764272.073573993,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 17.639216,
    "out:EL CO2": 5.188787,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 5.188787,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01000001100001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 50.438835,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 38.770476,
    "out:Electricity (inc PV)": 38.770476,
    "out:Total Energy Use Pre PV": 88.770476,
    "out:Total Energy Use Post PV": 88.770476,
    "out:Primary Energy": 104.002701,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 15.673415,
    "out:CO2 Operational/m2": 23.117213,
    "out:Total CO2/m2": 38.790628,
    "out:Total CO2 (tons)": 123.509322,
    "out:Klimatpaverkan": -76.626099,
    "out:Initial Cost/MSEK": 5.957888098573992,
    "out:Running cost/(Apt SEK y)": 15482.425532,
    "out:Running Cost over RSP/MSEK": 5.66922,
    "out:LCP/MSEK": 1.777612,
    "out:ROI% old": 18.548039,
    "out:Payback discounted": 7,
    "out:Atemp": 3184,
    "out:Umean": 0.65,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 89,
    "out:Energy use kWh/m2": 89,
    "out:Energy savings %": 105.6,
    "out:Op energy cost/MSEK": 40,
    "out:El price/MSEK": 29,
    "out:Heating price/MSEK": 10,
    "out:DH+ DHW/kWh/m2": 50,
    "out:El bought/kWh/m2": 2713.93332,
    "out:Return %": 17,
    "out:Return/kSEK/y": 987,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.6502792112,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 28.356,
    "out:EAHP (El)": 23.900976,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 379317.735305,
    "out:EL kWh savings": -83422.290484,
    "out:Sewage heat recovery cost": 273216,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 212473.15,
    "out:DH kr savings": 379317.7353046742,
    "out:El kr savings": -166844.580967888,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 160597.20232,
    "out:% savings (space heating)": 60.555007,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 997124.4110739925,
    "out:Eupp": 43.99,
    "out:Etvv": 0,
    "out:Ef": 37.28,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 5957888.098573992,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 17.9992,
    "out:EL CO2": 5.118013,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 5.118013,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01000001100001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 46.437735,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 38.674995,
    "out:Electricity (inc PV)": 38.674995,
    "out:Total Energy Use Pre PV": 84.674995,
    "out:Total Energy Use Post PV": 84.674995,
    "out:Primary Energy": 100.810986,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 15.673415,
    "out:CO2 Operational/m2": 21.664673,
    "out:Total CO2/m2": 37.338088,
    "out:Total CO2 (tons)": 118.884436,
    "out:Klimatpaverkan": -81.250985,
    "out:Initial Cost/MSEK": 6.037488073573993,
    "out:Running cost/(Apt SEK y)": 14651.12766,
    "out:Running Cost over RSP/MSEK": 5.36368,
    "out:LCP/MSEK": 2.003552,
    "out:ROI% old": 19.026456,
    "out:Payback discounted": 6,
    "out:Atemp": 3184,
    "out:Umean": 0.65,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 85,
    "out:Energy use kWh/m2": 85,
    "out:Energy savings %": 115.3,
    "out:Op energy cost/MSEK": 38,
    "out:El price/MSEK": 28,
    "out:Heating price/MSEK": 9,
    "out:DH+ DHW/kWh/m2": 46,
    "out:El bought/kWh/m2": 2707.24965,
    "out:Return %": 17,
    "out:Return/kSEK/y": 1026,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.6502792112,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 28.356,
    "out:EAHP (El)": 23.805495,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 392057.233872,
    "out:EL kWh savings": -83118.279071,
    "out:Sewage heat recovery cost": 273216,
    "out:Balancing hydronic system cost": 79599.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 225820.68,
    "out:DH kr savings": 392057.2338716204,
    "out:El kr savings": -166236.55814282954,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 147857.703753,
    "out:% savings (space heating)": 63.684012,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 997124.4110739925,
    "out:Eupp": 40.11,
    "out:Etvv": 0,
    "out:Ef": 37.15,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 6037488.073573993,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 16.559264,
    "out:EL CO2": 5.105409,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 5.105409,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01000001110000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 53.39455,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 39.391168,
    "out:Electricity (inc PV)": 29.493738,
    "out:Total Energy Use Pre PV": 92.391168,
    "out:Total Energy Use Post PV": 82.493738,
    "out:Primary Energy": 88.598144,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 29.703972,
    "out:CO2 Operational/m2": 5.908871,
    "out:Total CO2/m2": 35.612843,
    "out:Total CO2 (tons)": 113.391258,
    "out:Klimatpaverkan": -86.744163,
    "out:Initial Cost/MSEK": 6.474892492796494,
    "out:Running cost/(Apt SEK y)": 14489.893617,
    "out:Running Cost over RSP/MSEK": 5.32057,
    "out:LCP/MSEK": 1.609258,
    "out:ROI% old": 17.836259,
    "out:Payback discounted": 7,
    "out:Atemp": 3184,
    "out:Umean": 0.65,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 82,
    "out:Energy use kWh/m2": 82,
    "out:Energy savings %": 123.2,
    "out:Op energy cost/MSEK": 38,
    "out:El price/MSEK": 26,
    "out:Heating price/MSEK": 10,
    "out:DH+ DHW/kWh/m2": 53,
    "out:El bought/kWh/m2": 2085.598729,
    "out:Return %": 16,
    "out:Return/kSEK/y": 1034,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.6502792112,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 33.36,
    "out:EAHP (El)": 24.521668,
    "out:PV (kr/kWp)": 17461.56255567083,
    "out:PV (kWp installed)": 45.25485,
    "out:PV (m2 panels)": 237.433632,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 42461.284501,
    "out:PV (kWh sold)": 10947.876337,
    "out:PV (% sold (El))": 25.783196,
    "out:PV (kWh self-consumed)": 31513.408165,
    "out:PV (ratio sold/self-consumed)": 0.347404,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 369906.741576,
    "out:EL kWh savings": -53885.165579,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 278558.22,
    "out:DH kr savings": 369906.74157624913,
    "out:El kr savings": -107770.33115811803,
    "out:El kr sold": 16421.814505311137,
    "out:El kr saved": 63026.81633,
    "out:El kr return": 79448.63083531114,
    "out:% solar/total": 79,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 170008.196048,
    "out:% savings (space heating)": 58.243531,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 997124.4110739925,
    "out:Eupp": 45.65,
    "out:Etvv": 0,
    "out:Ef": 28.04,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 6474892.492796494,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 19.079152,
    "out:EL CO2": -13.170281,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -17.073077,
    "out:Bought CO2": 3.902796,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01000001110000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 49.357405,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 39.30661,
    "out:Electricity (inc PV)": 29.421123,
    "out:Total Energy Use Pre PV": 88.30661,
    "out:Total Energy Use Post PV": 78.421123,
    "out:Primary Energy": 85.423566,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 29.703972,
    "out:CO2 Operational/m2": 4.398482,
    "out:Total CO2/m2": 34.102454,
    "out:Total CO2 (tons)": 108.582181,
    "out:Klimatpaverkan": -91.55324,
    "out:Initial Cost/MSEK": 6.554492467796495,
    "out:Running cost/(Apt SEK y)": 13660.489362,
    "out:Running Cost over RSP/MSEK": 5.01571,
    "out:LCP/MSEK": 1.834518,
    "out:ROI% old": 18.284101,
    "out:Payback discounted": 7,
    "out:Atemp": 3184,
    "out:Umean": 0.65,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 78,
    "out:Energy use kWh/m2": 78,
    "out:Energy savings %": 134.6,
    "out:Op energy cost/MSEK": 36,
    "out:El price/MSEK": 25,
    "out:Heating price/MSEK": 10,
    "out:DH+ DHW/kWh/m2": 49,
    "out:El bought/kWh/m2": 2080.456892,
    "out:Return %": 16,
    "out:Return/kSEK/y": 1073,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.6502792112,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 33.36,
    "out:EAHP (El)": 24.43711,
    "out:PV (kr/kWp)": 17461.56255567083,
    "out:PV (kWp installed)": 45.25485,
    "out:PV (m2 panels)": 237.433632,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 42461.284501,
    "out:PV (kWh sold)": 10985.903318,
    "out:PV (% sold (El))": 25.872753,
    "out:PV (kWh self-consumed)": 31475.381184,
    "out:PV (ratio sold/self-consumed)": 0.349032,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 382761.007389,
    "out:EL kWh savings": -53653.959489,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 79599.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 291931.94,
    "out:DH kr savings": 382761.0073886643,
    "out:El kr savings": -107307.9189772483,
    "out:El kr sold": 16478.854976678616,
    "out:El kr saved": 62950.762368,
    "out:El kr return": 79429.61734467861,
    "out:% solar/total": 79,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 157153.930236,
    "out:% savings (space heating)": 61.400725,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 997124.4110739925,
    "out:Eupp": 41.74,
    "out:Etvv": 0,
    "out:Ef": 27.93,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 6554492.467796494,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 17.639216,
    "out:EL CO2": -13.240734,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -17.133917,
    "out:Bought CO2": 3.893183,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01000001110001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 50.438835,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 38.770476,
    "out:Electricity (inc PV)": 28.961544,
    "out:Total Energy Use Pre PV": 88.770476,
    "out:Total Energy Use Post PV": 78.961544,
    "out:Primary Energy": 86.346623,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 30.412515,
    "out:CO2 Operational/m2": 4.308341,
    "out:Total CO2/m2": 34.720856,
    "out:Total CO2 (tons)": 110.551172,
    "out:Klimatpaverkan": -89.584249,
    "out:Initial Cost/MSEK": 6.748108492796494,
    "out:Running cost/(Apt SEK y)": 13795.021277,
    "out:Running Cost over RSP/MSEK": 5.06545,
    "out:LCP/MSEK": 1.591162,
    "out:ROI% old": 17.654196,
    "out:Payback discounted": 7,
    "out:Atemp": 3184,
    "out:Umean": 0.65,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 79,
    "out:Energy use kWh/m2": 79,
    "out:Energy savings %": 131.6,
    "out:Op energy cost/MSEK": 37,
    "out:El price/MSEK": 25,
    "out:Heating price/MSEK": 10,
    "out:DH+ DHW/kWh/m2": 50,
    "out:El bought/kWh/m2": 2047.897624,
    "out:Return %": 16,
    "out:Return/kSEK/y": 1066,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.6502792112,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 28.356,
    "out:EAHP (El)": 23.900976,
    "out:PV (kr/kWp)": 17461.56255567083,
    "out:PV (kWp installed)": 45.25485,
    "out:PV (m2 panels)": 237.433632,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 42461.284501,
    "out:PV (kWh sold)": 11229.655123,
    "out:PV (% sold (El))": 26.44681,
    "out:PV (kWh self-consumed)": 31231.629379,
    "out:PV (ratio sold/self-consumed)": 0.35956,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 379317.735305,
    "out:EL kWh savings": -52190.660393,
    "out:Sewage heat recovery cost": 273216,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 291780.9,
    "out:DH kr savings": 379317.7353046742,
    "out:El kr savings": -104381.32078580171,
    "out:El kr sold": 16844.48268434011,
    "out:El kr saved": 62463.258758,
    "out:El kr return": 79307.7414423401,
    "out:% solar/total": 78,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 160597.20232,
    "out:% savings (space heating)": 60.555007,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 997124.4110739925,
    "out:Eupp": 43.99,
    "out:Etvv": 0,
    "out:Ef": 27.69,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 6748108.492796494,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 17.9992,
    "out:EL CO2": -13.690859,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -17.523183,
    "out:Bought CO2": 3.832324,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01000001110001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 46.437735,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 38.674995,
    "out:Electricity (inc PV)": 28.879849,
    "out:Total Energy Use Pre PV": 84.674995,
    "out:Total Energy Use Post PV": 74.879849,
    "out:Primary Energy": 83.179723,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 30.412515,
    "out:CO2 Operational/m2": 2.78757,
    "out:Total CO2/m2": 33.200085,
    "out:Total CO2 (tons)": 105.709039,
    "out:Klimatpaverkan": -94.426382,
    "out:Initial Cost/MSEK": 6.827708467796493,
    "out:Running cost/(Apt SEK y)": 12964.212766,
    "out:Running Cost over RSP/MSEK": 4.76007,
    "out:LCP/MSEK": 1.816942,
    "out:ROI% old": 18.087328,
    "out:Payback discounted": 7,
    "out:Atemp": 3184,
    "out:Umean": 0.65,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 75,
    "out:Energy use kWh/m2": 75,
    "out:Energy savings %": 144,
    "out:Op energy cost/MSEK": 34,
    "out:El price/MSEK": 24,
    "out:Heating price/MSEK": 9,
    "out:DH+ DHW/kWh/m2": 46,
    "out:El bought/kWh/m2": 2042.107155,
    "out:Return %": 16,
    "out:Return/kSEK/y": 1105,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.6502792112,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 28.356,
    "out:EAHP (El)": 23.805495,
    "out:PV (kr/kWp)": 17461.56255567083,
    "out:PV (kWp installed)": 45.25485,
    "out:PV (m2 panels)": 237.433632,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 42461.284501,
    "out:PV (kWh sold)": 11273.550419,
    "out:PV (% sold (El))": 26.550187,
    "out:PV (kWh self-consumed)": 31187.734083,
    "out:PV (ratio sold/self-consumed)": 0.361474,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 392057.233872,
    "out:EL kWh savings": -51930.543591,
    "out:Sewage heat recovery cost": 273216,
    "out:Balancing hydronic system cost": 79599.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 305106.47,
    "out:DH kr savings": 392057.2338716204,
    "out:El kr savings": -103861.08718232936,
    "out:El kr sold": 16910.325628242183,
    "out:El kr saved": 62375.468166,
    "out:El kr return": 79285.79379424218,
    "out:% solar/total": 78,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 147857.703753,
    "out:% savings (space heating)": 63.684012,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 997124.4110739925,
    "out:Eupp": 40.11,
    "out:Etvv": 0,
    "out:Ef": 27.58,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 6827708.467796493,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 16.559264,
    "out:EL CO2": -13.771694,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -17.593197,
    "out:Bought CO2": 3.821503,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01000001120000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 53.39455,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 39.391168,
    "out:Electricity (inc PV)": 26.713495,
    "out:Total Energy Use Pre PV": 92.391168,
    "out:Total Energy Use Post PV": 79.713495,
    "out:Primary Energy": 83.593707,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 44.443071,
    "out:CO2 Operational/m2": -47.998644,
    "out:Total CO2/m2": -3.555573,
    "out:Total CO2 (tons)": -11.320941,
    "out:Klimatpaverkan": -211.456362,
    "out:Initial Cost/MSEK": 7.265105844517743,
    "out:Running cost/(Apt SEK y)": 13040.574468,
    "out:Running Cost over RSP/MSEK": 4.80225,
    "out:LCP/MSEK": 1.337364,
    "out:ROI% old": 16.915435,
    "out:Payback discounted": 7,
    "out:Atemp": 3184,
    "out:Umean": 0.65,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 80,
    "out:Energy use kWh/m2": 80,
    "out:Energy savings %": 128.8,
    "out:Op energy cost/MSEK": 35,
    "out:El price/MSEK": 23,
    "out:Heating price/MSEK": 10,
    "out:DH+ DHW/kWh/m2": 53,
    "out:El bought/kWh/m2": 1887.808942,
    "out:Return %": 15,
    "out:Return/kSEK/y": 1102,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.6502792112,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 33.36,
    "out:EAHP (El)": 24.521668,
    "out:PV (kr/kWp)": 17461.48474631725,
    "out:PV (kWp installed)": 90.5097,
    "out:PV (m2 panels)": 474.867263,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 84922.568824,
    "out:PV (kWh sold)": 44556.871136,
    "out:PV (% sold (El))": 52.467644,
    "out:PV (kWh self-consumed)": 40365.697688,
    "out:PV (ratio sold/self-consumed)": 1.10383,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 369906.741576,
    "out:EL kWh savings": -45032.874531,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 346676.3,
    "out:DH kr savings": 369906.74157624913,
    "out:El kr savings": -90065.74906106354,
    "out:El kr sold": 66835.30670382126,
    "out:El kr saved": 80731.395376,
    "out:El kr return": 147566.70207982126,
    "out:% solar/total": 101,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 170008.196048,
    "out:% savings (space heating)": 58.243531,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 997124.4110739925,
    "out:Eupp": 45.65,
    "out:Etvv": 0,
    "out:Ef": 25.41,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 7265105.844517743,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 19.079152,
    "out:EL CO2": -67.077796,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -70.612122,
    "out:Bought CO2": 3.534326,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01000001120000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 49.357405,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 39.30661,
    "out:Electricity (inc PV)": 26.648385,
    "out:Total Energy Use Pre PV": 88.30661,
    "out:Total Energy Use Post PV": 75.648385,
    "out:Primary Energy": 80.432638,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 44.443071,
    "out:CO2 Operational/m2": -49.546982,
    "out:Total CO2/m2": -5.103911,
    "out:Total CO2 (tons)": -16.250848,
    "out:Klimatpaverkan": -216.386269,
    "out:Initial Cost/MSEK": 7.344705819517745,
    "out:Running cost/(Apt SEK y)": 12211.425532,
    "out:Running Cost over RSP/MSEK": 4.49748,
    "out:LCP/MSEK": 1.562534,
    "out:ROI% old": 17.324899,
    "out:Payback discounted": 7,
    "out:Atemp": 3184,
    "out:Umean": 0.65,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 76,
    "out:Energy use kWh/m2": 76,
    "out:Energy savings %": 140.8,
    "out:Op energy cost/MSEK": 33,
    "out:El price/MSEK": 22,
    "out:Heating price/MSEK": 10,
    "out:DH+ DHW/kWh/m2": 49,
    "out:El bought/kWh/m2": 1883.194888,
    "out:Return %": 16,
    "out:Return/kSEK/y": 1141,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.6502792112,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 33.36,
    "out:EAHP (El)": 24.43711,
    "out:PV (kr/kWp)": 17461.48474631725,
    "out:PV (kWp installed)": 90.5097,
    "out:PV (m2 panels)": 474.867263,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 84922.568824,
    "out:PV (kWh sold)": 44618.792416,
    "out:PV (% sold (El))": 52.540559,
    "out:PV (kWh self-consumed)": 40303.776408,
    "out:PV (ratio sold/self-consumed)": 1.107062,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 382761.007389,
    "out:EL kWh savings": -44825.564353,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 79599.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 360038.07,
    "out:DH kr savings": 382761.0073886643,
    "out:El kr savings": -89651.12870581428,
    "out:El kr sold": 66928.1886238697,
    "out:El kr saved": 80607.552816,
    "out:El kr return": 147535.7414398697,
    "out:% solar/total": 101,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 157153.930236,
    "out:% savings (space heating)": 61.400725,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 997124.4110739925,
    "out:Eupp": 41.74,
    "out:Etvv": 0,
    "out:Ef": 25.31,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 7344705.819517745,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 17.639216,
    "out:EL CO2": -67.186198,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -70.711903,
    "out:Bought CO2": 3.525705,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01000001120001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 50.438835,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 38.770476,
    "out:Electricity (inc PV)": 26.236161,
    "out:Total Energy Use Pre PV": 88.770476,
    "out:Total Energy Use Post PV": 76.236161,
    "out:Primary Energy": 81.440934,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 45.151614,
    "out:CO2 Operational/m2": -49.877391,
    "out:Total CO2/m2": -4.725777,
    "out:Total CO2 (tons)": -15.046869,
    "out:Klimatpaverkan": -215.18229,
    "out:Initial Cost/MSEK": 7.538321844517744,
    "out:Running cost/(Apt SEK y)": 12347.574468,
    "out:Running Cost over RSP/MSEK": 4.54781,
    "out:LCP/MSEK": 1.318588,
    "out:ROI% old": 16.784543,
    "out:Payback discounted": 7,
    "out:Atemp": 3184,
    "out:Umean": 0.65,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 76,
    "out:Energy use kWh/m2": 76,
    "out:Energy savings %": 140.8,
    "out:Op energy cost/MSEK": 33,
    "out:El price/MSEK": 22,
    "out:Heating price/MSEK": 10,
    "out:DH+ DHW/kWh/m2": 50,
    "out:El bought/kWh/m2": 1853.979973,
    "out:Return %": 15,
    "out:Return/kSEK/y": 1134,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.6502792112,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 28.356,
    "out:EAHP (El)": 23.900976,
    "out:PV (kr/kWp)": 17461.48474631725,
    "out:PV (kWp installed)": 90.5097,
    "out:PV (m2 panels)": 474.867263,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 84922.568824,
    "out:PV (kWh sold)": 45013.322673,
    "out:PV (% sold (El))": 53.005135,
    "out:PV (kWh self-consumed)": 39909.246151,
    "out:PV (ratio sold/self-consumed)": 1.127892,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 379317.735305,
    "out:EL kWh savings": -43513.043532,
    "out:Sewage heat recovery cost": 273216,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 359811.63,
    "out:DH kr savings": 379317.7353046742,
    "out:El kr savings": -87026.0870636355,
    "out:El kr sold": 67519.98400884654,
    "out:El kr saved": 79818.492302,
    "out:El kr return": 147338.47631084654,
    "out:% solar/total": 100,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 160597.20232,
    "out:% savings (space heating)": 60.555007,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 997124.4110739925,
    "out:Eupp": 43.99,
    "out:Etvv": 0,
    "out:Ef": 25.1,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 7538321.844517743,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 17.9992,
    "out:EL CO2": -67.876591,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -71.347714,
    "out:Bought CO2": 3.471123,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01000001120001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 46.437735,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 38.674995,
    "out:Electricity (inc PV)": 26.162854,
    "out:Total Energy Use Pre PV": 84.674995,
    "out:Total Energy Use Post PV": 72.162854,
    "out:Primary Energy": 78.289132,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 45.151614,
    "out:CO2 Operational/m2": -51.44084,
    "out:Total CO2/m2": -6.289226,
    "out:Total CO2 (tons)": -20.02489,
    "out:Klimatpaverkan": -220.160311,
    "out:Initial Cost/MSEK": 7.617921819517743,
    "out:Running cost/(Apt SEK y)": 11517.021277,
    "out:Running Cost over RSP/MSEK": 4.24253,
    "out:LCP/MSEK": 1.544268,
    "out:ROI% old": 17.181645,
    "out:Payback discounted": 7,
    "out:Atemp": 3184,
    "out:Umean": 0.65,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 72,
    "out:Energy use kWh/m2": 72,
    "out:Energy savings %": 154.2,
    "out:Op energy cost/MSEK": 31,
    "out:El price/MSEK": 21,
    "out:Heating price/MSEK": 9,
    "out:DH+ DHW/kWh/m2": 46,
    "out:El bought/kWh/m2": 1848.784575,
    "out:Return %": 15,
    "out:Return/kSEK/y": 1173,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.6502792112,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 28.356,
    "out:EAHP (El)": 23.805495,
    "out:PV (kr/kWp)": 17461.48474631725,
    "out:PV (kWp installed)": 90.5097,
    "out:PV (m2 panels)": 474.867263,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 84922.568824,
    "out:PV (kWh sold)": 45083.924458,
    "out:PV (% sold (El))": 53.088272,
    "out:PV (kWh self-consumed)": 39838.644366,
    "out:PV (ratio sold/self-consumed)": 1.131663,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 392057.233872,
    "out:EL kWh savings": -43279.634114,
    "out:Sewage heat recovery cost": 273216,
    "out:Balancing hydronic system cost": 79599.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 373123.85,
    "out:DH kr savings": 392057.2338716204,
    "out:El kr savings": -86559.2682280918,
    "out:El kr sold": 67625.88668686338,
    "out:El kr saved": 79677.288732,
    "out:El kr return": 147303.17541886336,
    "out:% solar/total": 100,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 147857.703753,
    "out:% savings (space heating)": 63.684012,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 997124.4110739925,
    "out:Eupp": 40.11,
    "out:Etvv": 0,
    "out:Ef": 25,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 7617921.819517744,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 16.559264,
    "out:EL CO2": -68.000104,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -71.461521,
    "out:Bought CO2": 3.461417,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01000002000000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 11.291079,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 54.677068,
    "out:Electricity (inc PV)": 54.677068,
    "out:Total Energy Use Pre PV": 65.677068,
    "out:Total Energy Use Post PV": 65.677068,
    "out:Primary Energy": 103.723308,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 15.508604,
    "out:CO2 Operational/m2": 11.177634,
    "out:Total CO2/m2": 26.686238,
    "out:Total CO2 (tons)": 84.968956,
    "out:Klimatpaverkan": -115.166465,
    "out:Initial Cost/MSEK": 7.151749411073993,
    "out:Running cost/(Apt SEK y)": 9658.638298,
    "out:Running Cost over RSP/MSEK": 3.51906,
    "out:LCP/MSEK": 2.733911,
    "out:ROI% old": 19.746737,
    "out:Payback discounted": 6,
    "out:Atemp": 3184,
    "out:Umean": 0.65,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 66,
    "out:Energy use kWh/m2": 66,
    "out:Energy savings %": 177.3,
    "out:Op energy cost/MSEK": 24,
    "out:El price/MSEK": 21,
    "out:Heating price/MSEK": 2,
    "out:DH+ DHW/kWh/m2": 11,
    "out:El bought/kWh/m2": 3827.39476,
    "out:Return %": 18,
    "out:Return/kSEK/y": 1261,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 1774800,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 88740,
    "out:EAHP (replacement cost)": 1325203.921671023,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 41.7,
    "out:EAHP (El)": 39.807568,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 503964.152905,
    "out:EL kWh savings": -134068.864173,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 235826.42,
    "out:DH kr savings": 503964.15290512395,
    "out:El kr savings": -268137.72834685765,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 35950.784719,
    "out:% savings (space heating)": 91.169968,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 997124.4110739925,
    "out:Eupp": 6.82,
    "out:Etvv": 0,
    "out:Ef": 50.97,
    "out:heatPumpQuantity": 12,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 7151749.411073993,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 3.959824,
    "out:EL CO2": 7.21781,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 7.21781,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01000002000000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 8.798784,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 54.124374,
    "out:Electricity (inc PV)": 54.124374,
    "out:Total Energy Use Pre PV": 63.124374,
    "out:Total Energy Use Post PV": 63.124374,
    "out:Primary Energy": 101.236187,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 15.508604,
    "out:CO2 Operational/m2": 10.384706,
    "out:Total CO2/m2": 25.89331,
    "out:Total CO2 (tons)": 82.444274,
    "out:Klimatpaverkan": -117.691147,
    "out:Initial Cost/MSEK": 7.231349386073994,
    "out:Running cost/(Apt SEK y)": 9174.574468,
    "out:Running Cost over RSP/MSEK": 3.34143,
    "out:LCP/MSEK": 2.831941,
    "out:ROI% old": 19.880285,
    "out:Payback discounted": 6,
    "out:Atemp": 3184,
    "out:Umean": 0.65,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 63,
    "out:Energy use kWh/m2": 63,
    "out:Energy savings %": 190.5,
    "out:Op energy cost/MSEK": 23,
    "out:El price/MSEK": 21,
    "out:Heating price/MSEK": 2,
    "out:DH+ DHW/kWh/m2": 9,
    "out:El bought/kWh/m2": 3788.70618,
    "out:Return %": 18,
    "out:Return/kSEK/y": 1284,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 1774800,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 88740,
    "out:EAHP (replacement cost)": 1325203.921671023,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 41.7,
    "out:EAHP (El)": 39.254874,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 511899.617798,
    "out:EL kWh savings": -132309.087007,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 79599.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 247281.44,
    "out:DH kr savings": 511899.6177975053,
    "out:El kr savings": -264618.1740138194,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 28015.319827,
    "out:% savings (space heating)": 93.119033,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 997124.4110739925,
    "out:Eupp": 4.9,
    "out:Etvv": 0,
    "out:Ef": 50.24,
    "out:heatPumpQuantity": 12,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 7231349.3860739935,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 3.239856,
    "out:EL CO2": 7.14485,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 7.14485,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01000002000001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 9.467002,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 53.334364,
    "out:Electricity (inc PV)": 53.334364,
    "out:Total Energy Use Pre PV": 62.334364,
    "out:Total Energy Use Post PV": 62.334364,
    "out:Primary Energy": 100.657816,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 16.217147,
    "out:CO2 Operational/m2": 10.280419,
    "out:Total CO2/m2": 26.497566,
    "out:Total CO2 (tons)": 84.368225,
    "out:Klimatpaverkan": -115.767196,
    "out:Initial Cost/MSEK": 7.424965411073993,
    "out:Running cost/(Apt SEK y)": 9067.531915,
    "out:Running Cost over RSP/MSEK": 3.30254,
    "out:LCP/MSEK": 2.677215,
    "out:ROI% old": 19.436704,
    "out:Payback discounted": 6,
    "out:Atemp": 3184,
    "out:Umean": 0.65,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 62,
    "out:Energy use kWh/m2": 62,
    "out:Energy savings %": 195.2,
    "out:Op energy cost/MSEK": 22,
    "out:El price/MSEK": 20,
    "out:Heating price/MSEK": 2,
    "out:DH+ DHW/kWh/m2": 9,
    "out:El bought/kWh/m2": 3733.40548,
    "out:Return %": 17,
    "out:Return/kSEK/y": 1289,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 1774800,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 88740,
    "out:EAHP (replacement cost)": 1325203.921671023,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 35.445,
    "out:EAHP (El)": 38.464864,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 509772.012326,
    "out:EL kWh savings": -129793.695924,
    "out:Sewage heat recovery cost": 273216,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 250184.62,
    "out:DH kr savings": 509772.0123256582,
    "out:El kr savings": -259587.39184747858,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 30142.925299,
    "out:% savings (space heating)": 92.596462,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 997124.4110739925,
    "out:Eupp": 5.99,
    "out:Etvv": 0,
    "out:Ef": 50.09,
    "out:heatPumpQuantity": 12,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 7424965.411073993,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 3.239856,
    "out:EL CO2": 7.040563,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 7.040563,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01000002000001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 7.183742,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 52.718327,
    "out:Electricity (inc PV)": 52.718327,
    "out:Total Energy Use Pre PV": 59.718327,
    "out:Total Energy Use Post PV": 59.718327,
    "out:Primary Energy": 98.177023,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 16.217147,
    "out:CO2 Operational/m2": 9.479129,
    "out:Total CO2/m2": 25.696276,
    "out:Total CO2 (tons)": 81.816918,
    "out:Klimatpaverkan": -118.318503,
    "out:Initial Cost/MSEK": 7.504565386073994,
    "out:Running cost/(Apt SEK y)": 8574.893617,
    "out:Running Cost over RSP/MSEK": 3.12179,
    "out:LCP/MSEK": 2.778365,
    "out:ROI% old": 19.574618,
    "out:Payback discounted": 6,
    "out:Atemp": 3184,
    "out:Umean": 0.65,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 60,
    "out:Energy use kWh/m2": 60,
    "out:Energy savings %": 205,
    "out:Op energy cost/MSEK": 21,
    "out:El price/MSEK": 19,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 7,
    "out:El bought/kWh/m2": 3690.28289,
    "out:Return %": 17,
    "out:Return/kSEK/y": 1312,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 1774800,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 88740,
    "out:EAHP (replacement cost)": 1325203.921671023,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 35.445,
    "out:EAHP (El)": 37.848827,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 517041.909978,
    "out:EL kWh savings": -127832.234706,
    "out:Sewage heat recovery cost": 273216,
    "out:Balancing hydronic system cost": 79599.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 261377.44,
    "out:DH kr savings": 517041.90997829515,
    "out:El kr savings": -255664.46941180548,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 22873.027646,
    "out:% savings (space heating)": 94.382054,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 997124.4110739925,
    "out:Eupp": 4.22,
    "out:Etvv": 0,
    "out:Ef": 49.31,
    "out:heatPumpQuantity": 12,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 7504565.3860739935,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 2.519888,
    "out:EL CO2": 6.959241,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 6.959241,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01000002010000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 11.291079,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 54.677068,
    "out:Electricity (inc PV)": 43.016936,
    "out:Total Energy Use Pre PV": 65.677068,
    "out:Total Energy Use Post PV": 54.016936,
    "out:Primary Energy": 82.735071,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 30.247704,
    "out:CO2 Operational/m2": 1.514978,
    "out:Total CO2/m2": 31.762682,
    "out:Total CO2 (tons)": 101.132349,
    "out:Klimatpaverkan": -99.003072,
    "out:Initial Cost/MSEK": 7.941969805296493,
    "out:Running cost/(Apt SEK y)": 7908.531915,
    "out:Running Cost over RSP/MSEK": 2.89304,
    "out:LCP/MSEK": 2.56971,
    "out:ROI% old": 18.908012,
    "out:Payback discounted": 7,
    "out:Atemp": 3184,
    "out:Umean": 0.65,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 54,
    "out:Energy use kWh/m2": 54,
    "out:Energy savings %": 238.9,
    "out:Op energy cost/MSEK": 20,
    "out:El price/MSEK": 18,
    "out:Heating price/MSEK": 2,
    "out:DH+ DHW/kWh/m2": 11,
    "out:El bought/kWh/m2": 3043.73894,
    "out:Return %": 17,
    "out:Return/kSEK/y": 1343,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 1774800,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 88740,
    "out:EAHP (replacement cost)": 1325203.921671023,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 41.7,
    "out:EAHP (El)": 39.807568,
    "out:PV (kr/kWp)": 17461.56255567083,
    "out:PV (kWp installed)": 45.25485,
    "out:PV (m2 panels)": 237.433632,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 42461.284501,
    "out:PV (kWh sold)": 5335.435102,
    "out:PV (% sold (El))": 12.565411,
    "out:PV (kWh self-consumed)": 37125.8494,
    "out:PV (ratio sold/self-consumed)": 0.143712,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 503964.152905,
    "out:EL kWh savings": -96943.015056,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 318081.28,
    "out:DH kr savings": 503964.15290512395,
    "out:El kr savings": -193886.03011167073,
    "out:El kr sold": 8003.152652750803,
    "out:El kr saved": 74251.6988,
    "out:El kr return": 82254.8514527508,
    "out:% solar/total": 93,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 35950.784719,
    "out:% savings (space heating)": 91.169968,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 997124.4110739925,
    "out:Eupp": 6.82,
    "out:Etvv": 0,
    "out:Ef": 39.67,
    "out:heatPumpQuantity": 12,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 7941969.805296493,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 3.959824,
    "out:EL CO2": -2.444846,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -8.137909,
    "out:Bought CO2": 5.693063,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01000002010000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 8.798784,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 54.124374,
    "out:Electricity (inc PV)": 42.514691,
    "out:Total Energy Use Pre PV": 63.124374,
    "out:Total Energy Use Post PV": 51.514691,
    "out:Primary Energy": 80.338758,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 30.247704,
    "out:CO2 Operational/m2": 0.474601,
    "out:Total CO2/m2": 30.722305,
    "out:Total CO2 (tons)": 97.81979,
    "out:Klimatpaverkan": -102.315631,
    "out:Initial Cost/MSEK": 8.021569780296494,
    "out:Running cost/(Apt SEK y)": 7426.170213,
    "out:Running Cost over RSP/MSEK": 2.71601,
    "out:LCP/MSEK": 2.66714,
    "out:ROI% old": 19.035658,
    "out:Payback discounted": 6,
    "out:Atemp": 3184,
    "out:Umean": 0.65,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 52,
    "out:Energy use kWh/m2": 52,
    "out:Energy savings %": 251.9,
    "out:Op energy cost/MSEK": 19,
    "out:El price/MSEK": 17,
    "out:Heating price/MSEK": 2,
    "out:DH+ DHW/kWh/m2": 9,
    "out:El bought/kWh/m2": 3008.131726,
    "out:Return %": 17,
    "out:Return/kSEK/y": 1366,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 1774800,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 88740,
    "out:EAHP (replacement cost)": 1325203.921671023,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 41.7,
    "out:EAHP (El)": 39.254874,
    "out:PV (kr/kWp)": 17461.56255567083,
    "out:PV (kWp installed)": 45.25485,
    "out:PV (m2 panels)": 237.433632,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 42461.284501,
    "out:PV (kWh sold)": 5496.065603,
    "out:PV (% sold (El))": 12.94371,
    "out:PV (kWh self-consumed)": 36965.218898,
    "out:PV (ratio sold/self-consumed)": 0.148682,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 511899.617798,
    "out:EL kWh savings": -95343.867457,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 79599.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 329455.98,
    "out:DH kr savings": 511899.6177975053,
    "out:El kr savings": -190687.73491397203,
    "out:El kr sold": 8244.09840492153,
    "out:El kr saved": 73930.437796,
    "out:El kr return": 82174.53620092153,
    "out:% solar/total": 92,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 28015.319827,
    "out:% savings (space heating)": 93.119033,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 997124.4110739925,
    "out:Eupp": 4.9,
    "out:Etvv": 0,
    "out:Ef": 39.02,
    "out:heatPumpQuantity": 12,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 8021569.780296493,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 3.239856,
    "out:EL CO2": -2.765255,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -8.391826,
    "out:Bought CO2": 5.626571,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01000002010001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 9.467002,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 53.334364,
    "out:Electricity (inc PV)": 41.798492,
    "out:Total Energy Use Pre PV": 62.334364,
    "out:Total Energy Use Post PV": 50.798492,
    "out:Primary Energy": 79.893247,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 30.956247,
    "out:CO2 Operational/m2": 0.008422,
    "out:Total CO2/m2": 30.964669,
    "out:Total CO2 (tons)": 98.591476,
    "out:Klimatpaverkan": -101.543945,
    "out:Initial Cost/MSEK": 8.215185805296493,
    "out:Running cost/(Apt SEK y)": 7321.638298,
    "out:Running Cost over RSP/MSEK": 2.678,
    "out:LCP/MSEK": 2.511534,
    "out:ROI% old": 18.653121,
    "out:Payback discounted": 7,
    "out:Atemp": 3184,
    "out:Umean": 0.65,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 51,
    "out:Energy use kWh/m2": 51,
    "out:Energy savings %": 258.8,
    "out:Op energy cost/MSEK": 19,
    "out:El price/MSEK": 16,
    "out:Heating price/MSEK": 2,
    "out:DH+ DHW/kWh/m2": 9,
    "out:El bought/kWh/m2": 2957.349773,
    "out:Return %": 17,
    "out:Return/kSEK/y": 1371,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 1774800,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 88740,
    "out:EAHP (replacement cost)": 1325203.921671023,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 35.445,
    "out:EAHP (El)": 38.464864,
    "out:PV (kr/kWp)": 17461.56255567083,
    "out:PV (kWp installed)": 45.25485,
    "out:PV (m2 panels)": 237.433632,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 42461.284501,
    "out:PV (kWh sold)": 5731.080609,
    "out:PV (% sold (El))": 13.497191,
    "out:PV (kWh self-consumed)": 36730.203893,
    "out:PV (ratio sold/self-consumed)": 0.156032,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 509772.012326,
    "out:EL kWh savings": -93063.490527,
    "out:Sewage heat recovery cost": 273216,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 332241.65,
    "out:DH kr savings": 509772.0123256582,
    "out:El kr savings": -186126.98105420935,
    "out:El kr sold": 8596.62091312071,
    "out:El kr saved": 73460.407786,
    "out:El kr return": 82057.02869912071,
    "out:% solar/total": 92,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 30142.925299,
    "out:% savings (space heating)": 92.596462,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 997124.4110739925,
    "out:Eupp": 5.99,
    "out:Etvv": 0,
    "out:Ef": 38.88,
    "out:heatPumpQuantity": 12,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 8215185.805296493,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 3.239856,
    "out:EL CO2": -3.231434,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -8.763178,
    "out:Bought CO2": 5.531744,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01000002010001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 7.183742,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 52.718327,
    "out:Electricity (inc PV)": 41.241605,
    "out:Total Energy Use Pre PV": 59.718327,
    "out:Total Energy Use Post PV": 48.241605,
    "out:Primary Energy": 77.518923,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 30.956247,
    "out:CO2 Operational/m2": -1.082049,
    "out:Total CO2/m2": 29.874198,
    "out:Total CO2 (tons)": 95.119418,
    "out:Klimatpaverkan": -105.016003,
    "out:Initial Cost/MSEK": 8.294785780296493,
    "out:Running cost/(Apt SEK y)": 6831,
    "out:Running Cost over RSP/MSEK": 2.49795,
    "out:LCP/MSEK": 2.611984,
    "out:ROI% old": 18.78421,
    "out:Payback discounted": 7,
    "out:Atemp": 3184,
    "out:Umean": 0.65,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 48,
    "out:Energy use kWh/m2": 48,
    "out:Energy savings %": 281.2,
    "out:Op energy cost/MSEK": 17,
    "out:El price/MSEK": 16,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 7,
    "out:El bought/kWh/m2": 2917.848151,
    "out:Return %": 17,
    "out:Return/kSEK/y": 1394,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 1774800,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 88740,
    "out:EAHP (replacement cost)": 1325203.921671023,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 35.445,
    "out:EAHP (El)": 37.848827,
    "out:PV (kr/kWp)": 17461.56255567083,
    "out:PV (kWp installed)": 45.25485,
    "out:PV (m2 panels)": 237.433632,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 42461.284501,
    "out:PV (kWh sold)": 5919.41125,
    "out:PV (% sold (El))": 13.940726,
    "out:PV (kWh self-consumed)": 36541.873251,
    "out:PV (ratio sold/self-consumed)": 0.16199,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 517041.909978,
    "out:EL kWh savings": -91290.362853,
    "out:Sewage heat recovery cost": 273216,
    "out:Balancing hydronic system cost": 79599.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 343340.3,
    "out:DH kr savings": 517041.90997829515,
    "out:El kr savings": -182580.72570520485,
    "out:El kr sold": 8879.116875209058,
    "out:El kr saved": 73083.746502,
    "out:El kr return": 81962.86337720905,
    "out:% solar/total": 91,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 22873.027646,
    "out:% savings (space heating)": 94.382054,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 997124.4110739925,
    "out:Eupp": 4.22,
    "out:Etvv": 0,
    "out:Ef": 38.19,
    "out:heatPumpQuantity": 12,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 8294785.780296493,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 2.519888,
    "out:EL CO2": -3.601937,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -9.059918,
    "out:Bought CO2": 5.457981,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01000002020000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 11.291079,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 54.677068,
    "out:Electricity (inc PV)": 38.842552,
    "out:Total Energy Use Pre PV": 65.677068,
    "out:Total Energy Use Post PV": 49.842552,
    "out:Primary Energy": 75.22118,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 44.986803,
    "out:CO2 Operational/m2": -45.308546,
    "out:Total CO2/m2": -0.321743,
    "out:Total CO2 (tons)": -1.024429,
    "out:Klimatpaverkan": -201.15985,
    "out:Initial Cost/MSEK": 8.732183157017744,
    "out:Running cost/(Apt SEK y)": 6412,
    "out:Running Cost over RSP/MSEK": 2.35746,
    "out:LCP/MSEK": 2.315077,
    "out:ROI% old": 18.073144,
    "out:Payback discounted": 7,
    "out:Atemp": 3184,
    "out:Umean": 0.65,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 50,
    "out:Energy use kWh/m2": 50,
    "out:Energy savings %": 266,
    "out:Op energy cost/MSEK": 17,
    "out:El price/MSEK": 14,
    "out:Heating price/MSEK": 2,
    "out:DH+ DHW/kWh/m2": 11,
    "out:El bought/kWh/m2": 2745.874262,
    "out:Return %": 16,
    "out:Return/kSEK/y": 1413,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 1774800,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 88740,
    "out:EAHP (replacement cost)": 1325203.921671023,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 41.7,
    "out:EAHP (El)": 39.807568,
    "out:PV (kr/kWp)": 17461.48474631725,
    "out:PV (kWp installed)": 90.5097,
    "out:PV (m2 panels)": 474.867263,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 84922.568824,
    "out:PV (kWh sold)": 34505.485787,
    "out:PV (% sold (El))": 40.631703,
    "out:PV (kWh self-consumed)": 50417.083037,
    "out:PV (ratio sold/self-consumed)": 0.684401,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 503964.152905,
    "out:EL kWh savings": -83651.780399,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 388418.82,
    "out:DH kr savings": 503964.15290512395,
    "out:El kr savings": -167303.56079779044,
    "out:El kr sold": 51758.228679891734,
    "out:El kr saved": 100834.166074,
    "out:El kr return": 152592.39475389174,
    "out:% solar/total": 126,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 35950.784719,
    "out:% savings (space heating)": 91.169968,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 997124.4110739925,
    "out:Eupp": 6.82,
    "out:Etvv": 0,
    "out:Ef": 35.85,
    "out:heatPumpQuantity": 12,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 8732183.157017743,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 3.959824,
    "out:EL CO2": -49.26837,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -54.407895,
    "out:Bought CO2": 5.139525,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01000002020000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 8.798784,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 54.124374,
    "out:Electricity (inc PV)": 38.394216,
    "out:Total Energy Use Pre PV": 63.124374,
    "out:Total Energy Use Post PV": 47.394216,
    "out:Primary Energy": 72.921903,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 44.986803,
    "out:CO2 Operational/m2": -46.623405,
    "out:Total CO2/m2": -1.636602,
    "out:Total CO2 (tons)": -5.210939,
    "out:Klimatpaverkan": -205.34636,
    "out:Initial Cost/MSEK": 8.811783132017743,
    "out:Running cost/(Apt SEK y)": 5931.468085,
    "out:Running Cost over RSP/MSEK": 2.18109,
    "out:LCP/MSEK": 2.411847,
    "out:ROI% old": 18.195815,
    "out:Payback discounted": 7,
    "out:Atemp": 3184,
    "out:Umean": 0.65,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 47,
    "out:Energy use kWh/m2": 47,
    "out:Energy savings %": 289.4,
    "out:Op energy cost/MSEK": 15,
    "out:El price/MSEK": 13,
    "out:Heating price/MSEK": 2,
    "out:DH+ DHW/kWh/m2": 9,
    "out:El bought/kWh/m2": 2714.147193,
    "out:Return %": 16,
    "out:Return/kSEK/y": 1436,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 1774800,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 88740,
    "out:EAHP (replacement cost)": 1325203.921671023,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 41.7,
    "out:EAHP (El)": 39.254874,
    "out:PV (kr/kWp)": 17461.48474631725,
    "out:PV (kWp installed)": 90.5097,
    "out:PV (m2 panels)": 474.867263,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 84922.568824,
    "out:PV (kWh sold)": 34837.761373,
    "out:PV (% sold (El))": 41.022972,
    "out:PV (kWh self-consumed)": 50084.807451,
    "out:PV (ratio sold/self-consumed)": 0.695575,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 511899.617798,
    "out:EL kWh savings": -82224.279004,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 79599.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 399707.7,
    "out:DH kr savings": 511899.6177975053,
    "out:El kr savings": -164448.55800880215,
    "out:El kr sold": 52256.64206017208,
    "out:El kr saved": 100169.614902,
    "out:El kr return": 152426.25696217208,
    "out:% solar/total": 125,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 28015.319827,
    "out:% savings (space heating)": 93.119033,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 997124.4110739925,
    "out:Eupp": 4.9,
    "out:Etvv": 0,
    "out:Ef": 35.26,
    "out:heatPumpQuantity": 12,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 8811783.132017743,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 3.239856,
    "out:EL CO2": -49.863261,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -54.943466,
    "out:Bought CO2": 5.080205,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01000002020001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 9.467002,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 53.334364,
    "out:Electricity (inc PV)": 37.754119,
    "out:Total Energy Use Pre PV": 62.334364,
    "out:Total Energy Use Post PV": 46.754119,
    "out:Primary Energy": 72.613375,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 45.695346,
    "out:CO2 Operational/m2": -47.479245,
    "out:Total CO2/m2": -1.783899,
    "out:Total CO2 (tons)": -5.679933,
    "out:Klimatpaverkan": -205.815354,
    "out:Initial Cost/MSEK": 9.005399157017743,
    "out:Running cost/(Apt SEK y)": 5829.510638,
    "out:Running Cost over RSP/MSEK": 2.14403,
    "out:LCP/MSEK": 2.255291,
    "out:ROI% old": 17.863395,
    "out:Payback discounted": 7,
    "out:Atemp": 3184,
    "out:Umean": 0.65,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 47,
    "out:Energy use kWh/m2": 47,
    "out:Energy savings %": 289.4,
    "out:Op energy cost/MSEK": 15,
    "out:El price/MSEK": 13,
    "out:Heating price/MSEK": 2,
    "out:DH+ DHW/kWh/m2": 9,
    "out:El bought/kWh/m2": 2668.884472,
    "out:Return %": 16,
    "out:Return/kSEK/y": 1441,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 1774800,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 88740,
    "out:EAHP (replacement cost)": 1325203.921671023,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 35.445,
    "out:EAHP (El)": 38.464864,
    "out:PV (kr/kWp)": 17461.48474631725,
    "out:PV (kWp installed)": 90.5097,
    "out:PV (m2 panels)": 474.867263,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 84922.568824,
    "out:PV (kWh sold)": 35315.083143,
    "out:PV (% sold (El))": 41.585039,
    "out:PV (kWh self-consumed)": 49607.485681,
    "out:PV (ratio sold/self-consumed)": 0.71189,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 509772.012326,
    "out:EL kWh savings": -80186.21077,
    "out:Sewage heat recovery cost": 273216,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 402372.22,
    "out:DH kr savings": 509772.0123256582,
    "out:El kr savings": -160372.42153922797,
    "out:El kr sold": 52972.62471492658,
    "out:El kr saved": 99214.971362,
    "out:El kr return": 152187.59607692657,
    "out:% solar/total": 124,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 30142.925299,
    "out:% savings (space heating)": 92.596462,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 997124.4110739925,
    "out:Eupp": 5.99,
    "out:Etvv": 0,
    "out:Ef": 35.14,
    "out:heatPumpQuantity": 12,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 9005399.157017743,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 3.239856,
    "out:EL CO2": -50.719101,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -55.714657,
    "out:Bought CO2": 4.995556,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01000002020001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 7.183742,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 52.718327,
    "out:Electricity (inc PV)": 37.255636,
    "out:Total Energy Use Pre PV": 59.718327,
    "out:Total Energy Use Post PV": 44.255636,
    "out:Primary Energy": 70.344179,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 45.695346,
    "out:CO2 Operational/m2": -48.870245,
    "out:Total CO2/m2": -3.174899,
    "out:Total CO2 (tons)": -10.108875,
    "out:Klimatpaverkan": -210.244296,
    "out:Initial Cost/MSEK": 9.084999132017742,
    "out:Running cost/(Apt SEK y)": 5340.829787,
    "out:Running Cost over RSP/MSEK": 1.96472,
    "out:LCP/MSEK": 2.355001,
    "out:ROI% old": 17.988837,
    "out:Payback discounted": 7,
    "out:Atemp": 3184,
    "out:Umean": 0.65,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 44,
    "out:Energy use kWh/m2": 44,
    "out:Energy savings %": 315.9,
    "out:Op energy cost/MSEK": 14,
    "out:El price/MSEK": 12,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 7,
    "out:El bought/kWh/m2": 2633.658579,
    "out:Return %": 16,
    "out:Return/kSEK/y": 1464,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 1774800,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 88740,
    "out:EAHP (replacement cost)": 1325203.921671023,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 35.445,
    "out:EAHP (El)": 37.848827,
    "out:PV (kr/kWp)": 17461.48474631725,
    "out:PV (kWp installed)": 90.5097,
    "out:PV (m2 panels)": 474.867263,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 84922.568824,
    "out:PV (kWh sold)": 35689.374287,
    "out:PV (% sold (El))": 42.025783,
    "out:PV (kWh self-consumed)": 49233.194537,
    "out:PV (ratio sold/self-consumed)": 0.724905,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 517041.909978,
    "out:EL kWh savings": -78599.041375,
    "out:Sewage heat recovery cost": 273216,
    "out:Balancing hydronic system cost": 79599.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 413377.89,
    "out:DH kr savings": 517041.90997829515,
    "out:El kr savings": -157198.0827503214,
    "out:El kr sold": 53534.06143084238,
    "out:El kr saved": 98466.389074,
    "out:El kr return": 152000.4505048424,
    "out:% solar/total": 123,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 22873.027646,
    "out:% savings (space heating)": 94.382054,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 997124.4110739925,
    "out:Eupp": 4.22,
    "out:Etvv": 0,
    "out:Ef": 34.52,
    "out:heatPumpQuantity": 12,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 9084999.132017743,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 2.519888,
    "out:EL CO2": -51.390133,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -56.319777,
    "out:Bought CO2": 4.929644,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01000002100000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 8.899345,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 52.874563,
    "out:Electricity (inc PV)": 52.874563,
    "out:Total Energy Use Pre PV": 61.874563,
    "out:Total Energy Use Post PV": 61.874563,
    "out:Primary Energy": 99.626019,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 15.508604,
    "out:CO2 Operational/m2": 10.219721,
    "out:Total CO2/m2": 25.728325,
    "out:Total CO2 (tons)": 81.918962,
    "out:Klimatpaverkan": -118.216459,
    "out:Initial Cost/MSEK": 7.275797098573994,
    "out:Running cost/(Apt SEK y)": 9005.234043,
    "out:Running Cost over RSP/MSEK": 3.2799,
    "out:LCP/MSEK": 2.849023,
    "out:ROI% old": 19.879648,
    "out:Payback discounted": 6,
    "out:Atemp": 3184,
    "out:Umean": 0.65,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 62,
    "out:Energy use kWh/m2": 62,
    "out:Energy savings %": 195.2,
    "out:Op energy cost/MSEK": 22,
    "out:El price/MSEK": 20,
    "out:Heating price/MSEK": 2,
    "out:DH+ DHW/kWh/m2": 9,
    "out:El bought/kWh/m2": 3701.21941,
    "out:Return %": 18,
    "out:Return/kSEK/y": 1291,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 1774800,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 88740,
    "out:EAHP (replacement cost)": 1325203.921671023,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 33.36,
    "out:EAHP (El)": 38.005063,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 511579.43167,
    "out:EL kWh savings": -128329.68998,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 254920.05,
    "out:DH kr savings": 511579.43166984274,
    "out:El kr savings": -256659.3799604573,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 28335.505954,
    "out:% savings (space heating)": 93.04039,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 997124.4110739925,
    "out:Eupp": 5.72,
    "out:Etvv": 0,
    "out:Ef": 49.79,
    "out:heatPumpQuantity": 12,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 7275797.098573994,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 3.239856,
    "out:EL CO2": 6.979865,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 6.979865,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01000002100000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 6.687238,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 52.236964,
    "out:Electricity (inc PV)": 52.236964,
    "out:Total Energy Use Pre PV": 59.236964,
    "out:Total Energy Use Post PV": 59.236964,
    "out:Primary Energy": 97.1468,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 15.508604,
    "out:CO2 Operational/m2": 9.415585,
    "out:Total CO2/m2": 24.924189,
    "out:Total CO2 (tons)": 79.358594,
    "out:Klimatpaverkan": -120.776827,
    "out:Initial Cost/MSEK": 7.355397073573993,
    "out:Running cost/(Apt SEK y)": 8509.680851,
    "out:Running Cost over RSP/MSEK": 3.09809,
    "out:LCP/MSEK": 2.951233,
    "out:ROI% old": 20.017624,
    "out:Payback discounted": 6,
    "out:Atemp": 3184,
    "out:Umean": 0.65,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 59,
    "out:Energy use kWh/m2": 59,
    "out:Energy savings %": 210.2,
    "out:Op energy cost/MSEK": 21,
    "out:El price/MSEK": 19,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 7,
    "out:El bought/kWh/m2": 3656.58748,
    "out:Return %": 18,
    "out:Return/kSEK/y": 1315,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 1774800,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 88740,
    "out:EAHP (replacement cost)": 1325203.921671023,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 33.36,
    "out:EAHP (El)": 37.367464,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 518622.778239,
    "out:EL kWh savings": -126299.575375,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 79599.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 266023.63,
    "out:DH kr savings": 518622.77823864424,
    "out:El kr savings": -252599.15075009697,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 21292.159386,
    "out:% savings (space heating)": 94.770338,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 997124.4110739925,
    "out:Eupp": 4.01,
    "out:Etvv": 0,
    "out:Ef": 49,
    "out:heatPumpQuantity": 12,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 7355397.0735739935,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 2.519888,
    "out:EL CO2": 6.895697,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 6.895697,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01000002100001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 7.616152,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 51.747046,
    "out:Electricity (inc PV)": 51.747046,
    "out:Total Energy Use Pre PV": 59.747046,
    "out:Total Energy Use Post PV": 59.747046,
    "out:Primary Energy": 97.132089,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 16.217147,
    "out:CO2 Operational/m2": 9.710896,
    "out:Total CO2/m2": 25.928043,
    "out:Total CO2 (tons)": 82.554864,
    "out:Klimatpaverkan": -117.580557,
    "out:Initial Cost/MSEK": 7.549013098573993,
    "out:Running cost/(Apt SEK y)": 8647.893617,
    "out:Running Cost over RSP/MSEK": 3.14918,
    "out:LCP/MSEK": 2.706527,
    "out:ROI% old": 19.407532,
    "out:Payback discounted": 6,
    "out:Atemp": 3184,
    "out:Umean": 0.65,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 60,
    "out:Energy use kWh/m2": 60,
    "out:Energy savings %": 205,
    "out:Op energy cost/MSEK": 21,
    "out:El price/MSEK": 19,
    "out:Heating price/MSEK": 2,
    "out:DH+ DHW/kWh/m2": 8,
    "out:El bought/kWh/m2": 3622.29322,
    "out:Return %": 17,
    "out:Return/kSEK/y": 1308,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 1774800,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 88740,
    "out:EAHP (replacement cost)": 1325203.921671023,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 28.356,
    "out:EAHP (El)": 36.877546,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 515665.116953,
    "out:EL kWh savings": -124739.676932,
    "out:Sewage heat recovery cost": 273216,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 266185.76,
    "out:DH kr savings": 515665.1169525439,
    "out:El kr savings": -249479.35386477984,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 24249.820672,
    "out:% savings (space heating)": 94.043894,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 997124.4110739925,
    "out:Eupp": 5.13,
    "out:Etvv": 0,
    "out:Ef": 49.06,
    "out:heatPumpQuantity": 12,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 7549013.098573993,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 2.879872,
    "out:EL CO2": 6.831024,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 6.831024,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01000002100001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 5.574765,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 51.057714,
    "out:Electricity (inc PV)": 51.057714,
    "out:Total Energy Use Pre PV": 57.057714,
    "out:Total Energy Use Post PV": 57.057714,
    "out:Primary Energy": 94.656423,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 16.217147,
    "out:CO2 Operational/m2": 8.899931,
    "out:Total CO2/m2": 25.117078,
    "out:Total CO2 (tons)": 79.972752,
    "out:Klimatpaverkan": -120.162669,
    "out:Initial Cost/MSEK": 7.628613073573994,
    "out:Running cost/(Apt SEK y)": 8145.297872,
    "out:Running Cost over RSP/MSEK": 2.96483,
    "out:LCP/MSEK": 2.811277,
    "out:ROI% old": 19.550249,
    "out:Payback discounted": 6,
    "out:Atemp": 3184,
    "out:Umean": 0.65,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 57,
    "out:Energy use kWh/m2": 57,
    "out:Energy savings %": 221.1,
    "out:Op energy cost/MSEK": 20,
    "out:El price/MSEK": 19,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 6,
    "out:El bought/kWh/m2": 3574.03998,
    "out:Return %": 17,
    "out:Return/kSEK/y": 1332,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 1774800,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 88740,
    "out:EAHP (replacement cost)": 1325203.921671023,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 28.356,
    "out:EAHP (El)": 36.188214,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 522164.891205,
    "out:EL kWh savings": -122544.844505,
    "out:Sewage heat recovery cost": 273216,
    "out:Balancing hydronic system cost": 79599.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 277075.2,
    "out:DH kr savings": 522164.8912048951,
    "out:El kr savings": -245089.68900953996,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 17750.046419,
    "out:% savings (space heating)": 95.640332,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 997124.4110739925,
    "out:Eupp": 3.54,
    "out:Etvv": 0,
    "out:Ef": 48.23,
    "out:heatPumpQuantity": 12,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 7628613.0735739935,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 2.159904,
    "out:EL CO2": 6.740027,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 6.740027,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01000002110000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 8.899345,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 52.874563,
    "out:Electricity (inc PV)": 41.382668,
    "out:Total Energy Use Pre PV": 61.874563,
    "out:Total Energy Use Post PV": 50.382668,
    "out:Primary Energy": 78.940608,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 30.247704,
    "out:CO2 Operational/m2": -0.267472,
    "out:Total CO2/m2": 29.980232,
    "out:Total CO2 (tons)": 95.45703,
    "out:Klimatpaverkan": -104.678391,
    "out:Initial Cost/MSEK": 8.066017492796494,
    "out:Running cost/(Apt SEK y)": 7260.829787,
    "out:Running Cost over RSP/MSEK": 2.65589,
    "out:LCP/MSEK": 2.682813,
    "out:ROI% old": 19.03724,
    "out:Payback discounted": 6,
    "out:Atemp": 3184,
    "out:Umean": 0.65,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 50,
    "out:Energy use kWh/m2": 50,
    "out:Energy savings %": 266,
    "out:Op energy cost/MSEK": 18,
    "out:El price/MSEK": 16,
    "out:Heating price/MSEK": 2,
    "out:DH+ DHW/kWh/m2": 9,
    "out:El bought/kWh/m2": 2927.858048,
    "out:Return %": 17,
    "out:Return/kSEK/y": 1373,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 1774800,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 88740,
    "out:EAHP (replacement cost)": 1325203.921671023,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 33.36,
    "out:EAHP (El)": 38.005063,
    "out:PV (kr/kWp)": 17461.56255567083,
    "out:PV (kWp installed)": 45.25485,
    "out:PV (m2 panels)": 237.433632,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 42461.284501,
    "out:PV (kWh sold)": 5871.102957,
    "out:PV (% sold (El))": 13.826956,
    "out:PV (kWh self-consumed)": 36590.181545,
    "out:PV (ratio sold/self-consumed)": 0.160456,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 511579.43167,
    "out:EL kWh savings": -91739.507309,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 336907.07,
    "out:DH kr savings": 511579.43166984274,
    "out:El kr savings": -183479.0146189281,
    "out:El kr sold": 8806.654435220877,
    "out:El kr saved": 73180.36309,
    "out:El kr return": 81987.01752522087,
    "out:% solar/total": 91,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 28335.505954,
    "out:% savings (space heating)": 93.04039,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 997124.4110739925,
    "out:Eupp": 5.72,
    "out:Etvv": 0,
    "out:Ef": 38.61,
    "out:heatPumpQuantity": 12,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 8066017.492796494,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 3.239856,
    "out:EL CO2": -3.507328,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -8.984,
    "out:Bought CO2": 5.476672,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01000002110000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 6.687238,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 52.236964,
    "out:Electricity (inc PV)": 40.807577,
    "out:Total Energy Use Pre PV": 59.236964,
    "out:Total Energy Use Post PV": 47.807577,
    "out:Primary Energy": 76.573904,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 30.247704,
    "out:CO2 Operational/m2": -1.376432,
    "out:Total CO2/m2": 28.871272,
    "out:Total CO2 (tons)": 91.926102,
    "out:Klimatpaverkan": -108.209319,
    "out:Initial Cost/MSEK": 8.145617467796495,
    "out:Running cost/(Apt SEK y)": 6767.382979,
    "out:Running Cost over RSP/MSEK": 2.47481,
    "out:LCP/MSEK": 2.784293,
    "out:ROI% old": 19.168783,
    "out:Payback discounted": 6,
    "out:Atemp": 3184,
    "out:Umean": 0.65,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 48,
    "out:Energy use kWh/m2": 48,
    "out:Energy savings %": 281.2,
    "out:Op energy cost/MSEK": 17,
    "out:El price/MSEK": 15,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 7,
    "out:El bought/kWh/m2": 2887.044153,
    "out:Return %": 17,
    "out:Return/kSEK/y": 1397,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 1774800,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 88740,
    "out:EAHP (replacement cost)": 1325203.921671023,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 33.36,
    "out:EAHP (El)": 37.367464,
    "out:PV (kr/kWp)": 17461.56255567083,
    "out:PV (kWp installed)": 45.25485,
    "out:PV (m2 panels)": 237.433632,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 42461.284501,
    "out:PV (kWh sold)": 6070.125808,
    "out:PV (% sold (El))": 14.295672,
    "out:PV (kWh self-consumed)": 36391.158694,
    "out:PV (ratio sold/self-consumed)": 0.166802,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 518622.778239,
    "out:EL kWh savings": -89908.418116,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 79599.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 347911.13,
    "out:DH kr savings": 518622.77823864424,
    "out:El kr savings": -179816.8362328025,
    "out:El kr sold": 9105.188711806775,
    "out:El kr saved": 72782.317388,
    "out:El kr return": 81887.50609980678,
    "out:% solar/total": 91,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 21292.159386,
    "out:% savings (space heating)": 94.770338,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 997124.4110739925,
    "out:Eupp": 4.01,
    "out:Etvv": 0,
    "out:Ef": 37.9,
    "out:heatPumpQuantity": 12,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 8145617.467796494,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 2.519888,
    "out:EL CO2": -3.89632,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -9.296792,
    "out:Bought CO2": 5.400472,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01000002110001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 7.616152,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 51.747046,
    "out:Electricity (inc PV)": 40.366545,
    "out:Total Energy Use Pre PV": 59.747046,
    "out:Total Energy Use Post PV": 48.366545,
    "out:Primary Energy": 76.647187,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 30.956247,
    "out:CO2 Operational/m2": -1.320512,
    "out:Total CO2/m2": 29.635735,
    "out:Total CO2 (tons)": 94.360152,
    "out:Klimatpaverkan": -105.775269,
    "out:Initial Cost/MSEK": 8.339233492796494,
    "out:Running cost/(Apt SEK y)": 6907.255319,
    "out:Running Cost over RSP/MSEK": 2.52649,
    "out:LCP/MSEK": 2.538997,
    "out:ROI% old": 18.6352,
    "out:Payback discounted": 7,
    "out:Atemp": 3184,
    "out:Umean": 0.65,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 48,
    "out:Energy use kWh/m2": 48,
    "out:Energy savings %": 281.2,
    "out:Op energy cost/MSEK": 17,
    "out:El price/MSEK": 16,
    "out:Heating price/MSEK": 2,
    "out:DH+ DHW/kWh/m2": 8,
    "out:El bought/kWh/m2": 2855.74967,
    "out:Return %": 17,
    "out:Return/kSEK/y": 1390,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 1774800,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 88740,
    "out:EAHP (replacement cost)": 1325203.921671023,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 28.356,
    "out:EAHP (El)": 36.877546,
    "out:PV (kr/kWp)": 17461.56255567083,
    "out:PV (kWp installed)": 45.25485,
    "out:PV (m2 panels)": 237.433632,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 42461.284501,
    "out:PV (kWh sold)": 6225.780009,
    "out:PV (% sold (El))": 14.662251,
    "out:PV (kWh self-consumed)": 36235.504493,
    "out:PV (ratio sold/self-consumed)": 0.171814,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 515665.116953,
    "out:EL kWh savings": -88504.172651,
    "out:Sewage heat recovery cost": 273216,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 347995.44,
    "out:DH kr savings": 515665.1169525439,
    "out:El kr savings": -177008.3453018198,
    "out:El kr sold": 9338.67001282659,
    "out:El kr saved": 72471.008986,
    "out:El kr return": 81809.6789988266,
    "out:% solar/total": 91,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 24249.820672,
    "out:% savings (space heating)": 94.043894,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 997124.4110739925,
    "out:Eupp": 5.13,
    "out:Etvv": 0,
    "out:Ef": 37.96,
    "out:heatPumpQuantity": 12,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 8339233.492796494,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 2.879872,
    "out:EL CO2": -4.200384,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -9.542442,
    "out:Bought CO2": 5.342058,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01000002110001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 5.574765,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 51.057714,
    "out:Electricity (inc PV)": 39.746898,
    "out:Total Energy Use Pre PV": 57.057714,
    "out:Total Energy Use Post PV": 45.746898,
    "out:Primary Energy": 74.296954,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 30.956247,
    "out:CO2 Operational/m2": -2.475152,
    "out:Total CO2/m2": 28.481095,
    "out:Total CO2 (tons)": 90.683779,
    "out:Klimatpaverkan": -109.451642,
    "out:Initial Cost/MSEK": 8.418833467796492,
    "out:Running cost/(Apt SEK y)": 6407.042553,
    "out:Running Cost over RSP/MSEK": 2.34297,
    "out:LCP/MSEK": 2.642917,
    "out:ROI% old": 18.770415,
    "out:Payback discounted": 7,
    "out:Atemp": 3184,
    "out:Umean": 0.65,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 46,
    "out:Energy use kWh/m2": 46,
    "out:Energy savings %": 297.8,
    "out:Op energy cost/MSEK": 16,
    "out:El price/MSEK": 15,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 6,
    "out:El bought/kWh/m2": 2811.814584,
    "out:Return %": 17,
    "out:Return/kSEK/y": 1414,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 1774800,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 88740,
    "out:EAHP (replacement cost)": 1325203.921671023,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 28.356,
    "out:EAHP (El)": 36.188214,
    "out:PV (kr/kWp)": 17461.56255567083,
    "out:PV (kWp installed)": 45.25485,
    "out:PV (m2 panels)": 237.433632,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 42461.284501,
    "out:PV (kWh sold)": 6447.656808,
    "out:PV (% sold (El))": 15.18479,
    "out:PV (kWh self-consumed)": 36013.627693,
    "out:PV (ratio sold/self-consumed)": 0.179034,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 522164.891205,
    "out:EL kWh savings": -86531.217197,
    "out:Sewage heat recovery cost": 273216,
    "out:Balancing hydronic system cost": 79599.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 358773.94,
    "out:DH kr savings": 522164.8912048951,
    "out:El kr savings": -173062.4343930634,
    "out:El kr sold": 9671.485212432897,
    "out:El kr saved": 72027.255386,
    "out:El kr return": 81698.7405984329,
    "out:% solar/total": 90,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 17750.046419,
    "out:% savings (space heating)": 95.640332,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 997124.4110739925,
    "out:Eupp": 3.54,
    "out:Etvv": 0,
    "out:Ef": 37.22,
    "out:heatPumpQuantity": 12,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 8418833.467796493,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 2.159904,
    "out:EL CO2": -4.635056,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -9.8951,
    "out:Bought CO2": 5.260044,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01000002120000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 8.899345,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 52.874563,
    "out:Electricity (inc PV)": 37.381997,
    "out:Total Energy Use Pre PV": 61.874563,
    "out:Total Energy Use Post PV": 46.381997,
    "out:Primary Energy": 71.739401,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 44.986803,
    "out:CO2 Operational/m2": -47.979784,
    "out:Total CO2/m2": -2.992981,
    "out:Total CO2 (tons)": -9.529649,
    "out:Klimatpaverkan": -209.66507,
    "out:Initial Cost/MSEK": 8.856230844517743,
    "out:Running cost/(Apt SEK y)": 5770.170213,
    "out:Running Cost over RSP/MSEK": 2.12247,
    "out:LCP/MSEK": 2.426019,
    "out:ROI% old": 18.199051,
    "out:Payback discounted": 7,
    "out:Atemp": 3184,
    "out:Umean": 0.65,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 46,
    "out:Energy use kWh/m2": 46,
    "out:Energy savings %": 297.8,
    "out:Op energy cost/MSEK": 15,
    "out:El price/MSEK": 13,
    "out:Heating price/MSEK": 2,
    "out:DH+ DHW/kWh/m2": 9,
    "out:El bought/kWh/m2": 2642.586811,
    "out:Return %": 16,
    "out:Return/kSEK/y": 1444,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 1774800,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 88740,
    "out:EAHP (replacement cost)": 1325203.921671023,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 33.36,
    "out:EAHP (El)": 38.005063,
    "out:PV (kr/kWp)": 17461.48474631725,
    "out:PV (kWp installed)": 90.5097,
    "out:PV (m2 panels)": 474.867263,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 84922.568824,
    "out:PV (kWh sold)": 35594.252053,
    "out:PV (% sold (El))": 41.913772,
    "out:PV (kWh self-consumed)": 49328.316771,
    "out:PV (ratio sold/self-consumed)": 0.721578,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 511579.43167,
    "out:EL kWh savings": -79001.374678,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 406968.06,
    "out:DH kr savings": 511579.43166984274,
    "out:El kr savings": -158002.74935621378,
    "out:El kr sold": 53391.378079027454,
    "out:El kr saved": 98656.633542,
    "out:El kr return": 152048.01162102746,
    "out:% solar/total": 123,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 28335.505954,
    "out:% savings (space heating)": 93.04039,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 997124.4110739925,
    "out:Eupp": 5.72,
    "out:Etvv": 0,
    "out:Ef": 34.9,
    "out:heatPumpQuantity": 12,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 8856230.844517743,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 3.239856,
    "out:EL CO2": -51.21964,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -56.165992,
    "out:Bought CO2": 4.946352,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01000002120000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 6.687238,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 52.236964,
    "out:Electricity (inc PV)": 36.86685,
    "out:Total Energy Use Pre PV": 59.236964,
    "out:Total Energy Use Post PV": 43.86685,
    "out:Primary Energy": 69.480595,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 44.986803,
    "out:CO2 Operational/m2": -49.39704,
    "out:Total CO2/m2": -4.410237,
    "out:Total CO2 (tons)": -14.04219,
    "out:Klimatpaverkan": -214.177611,
    "out:Initial Cost/MSEK": 8.935830819517745,
    "out:Running cost/(Apt SEK y)": 5278.765957,
    "out:Running Cost over RSP/MSEK": 1.94215,
    "out:LCP/MSEK": 2.526739,
    "out:ROI% old": 18.325213,
    "out:Payback discounted": 7,
    "out:Atemp": 3184,
    "out:Umean": 0.65,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 44,
    "out:Energy use kWh/m2": 44,
    "out:Energy savings %": 315.9,
    "out:Op energy cost/MSEK": 14,
    "out:El price/MSEK": 12,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 7,
    "out:El bought/kWh/m2": 2606.175836,
    "out:Return %": 16,
    "out:Return/kSEK/y": 1467,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 1774800,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 88740,
    "out:EAHP (replacement cost)": 1325203.921671023,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 33.36,
    "out:EAHP (El)": 37.367464,
    "out:PV (kr/kWp)": 17461.48474631725,
    "out:PV (kWp installed)": 90.5097,
    "out:PV (m2 panels)": 474.867263,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 84922.568824,
    "out:PV (kWh sold)": 35984.141572,
    "out:PV (% sold (El))": 42.372884,
    "out:PV (kWh self-consumed)": 48938.427252,
    "out:PV (ratio sold/self-consumed)": 0.735294,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 518622.778239,
    "out:EL kWh savings": -77361.147124,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 79599.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 417876.7,
    "out:DH kr savings": 518622.77823864424,
    "out:El kr savings": -154722.2942472354,
    "out:El kr sold": 53976.21235758668,
    "out:El kr saved": 97876.854504,
    "out:El kr return": 151853.06686158667,
    "out:% solar/total": 122,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 21292.159386,
    "out:% savings (space heating)": 94.770338,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 997124.4110739925,
    "out:Eupp": 4.01,
    "out:Etvv": 0,
    "out:Ef": 34.26,
    "out:heatPumpQuantity": 12,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 8935830.819517745,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 2.519888,
    "out:EL CO2": -51.916928,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -56.795133,
    "out:Bought CO2": 4.878205,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01000002120001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 7.616152,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 51.747046,
    "out:Electricity (inc PV)": 36.47167,
    "out:Total Energy Use Pre PV": 59.747046,
    "out:Total Energy Use Post PV": 44.47167,
    "out:Primary Energy": 69.636412,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 45.695346,
    "out:CO2 Operational/m2": -49.575586,
    "out:Total CO2/m2": -3.88024,
    "out:Total CO2 (tons)": -12.35468,
    "out:Klimatpaverkan": -212.490101,
    "out:Initial Cost/MSEK": 9.129446844517743,
    "out:Running cost/(Apt SEK y)": 5420.170213,
    "out:Running Cost over RSP/MSEK": 1.9944,
    "out:LCP/MSEK": 2.280873,
    "out:ROI% old": 17.854814,
    "out:Payback discounted": 7,
    "out:Atemp": 3184,
    "out:Umean": 0.65,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 44,
    "out:Energy use kWh/m2": 44,
    "out:Energy savings %": 315.9,
    "out:Op energy cost/MSEK": 14,
    "out:El price/MSEK": 12,
    "out:Heating price/MSEK": 2,
    "out:DH+ DHW/kWh/m2": 8,
    "out:El bought/kWh/m2": 2578.243451,
    "out:Return %": 16,
    "out:Return/kSEK/y": 1460,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 1774800,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 88740,
    "out:EAHP (replacement cost)": 1325203.921671023,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 28.356,
    "out:EAHP (El)": 36.877546,
    "out:PV (kr/kWp)": 17461.48474631725,
    "out:PV (kWp installed)": 90.5097,
    "out:PV (m2 panels)": 474.867263,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 84922.568824,
    "out:PV (kWh sold)": 36285.78477,
    "out:PV (% sold (El))": 42.728082,
    "out:PV (kWh self-consumed)": 48636.784054,
    "out:PV (ratio sold/self-consumed)": 0.746056,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 515665.116953,
    "out:EL kWh savings": -76102.894382,
    "out:Sewage heat recovery cost": 273216,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 417888.01,
    "out:DH kr savings": 515665.1169525439,
    "out:El kr savings": -152205.7887644003,
    "out:El kr sold": 54428.677154668054,
    "out:El kr saved": 97273.568108,
    "out:El kr return": 151702.24526266806,
    "out:% solar/total": 122,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 24249.820672,
    "out:% savings (space heating)": 94.043894,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 997124.4110739925,
    "out:Eupp": 5.13,
    "out:Etvv": 0,
    "out:Ef": 34.31,
    "out:heatPumpQuantity": 12,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 9129446.844517743,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 2.879872,
    "out:EL CO2": -52.455458,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -57.281373,
    "out:Bought CO2": 4.825915,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01000002120001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 5.574765,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 51.057714,
    "out:Electricity (inc PV)": 35.916577,
    "out:Total Energy Use Pre PV": 57.057714,
    "out:Total Energy Use Post PV": 41.916577,
    "out:Primary Energy": 67.402377,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 45.695346,
    "out:CO2 Operational/m2": -51.057806,
    "out:Total CO2/m2": -5.36246,
    "out:Total CO2 (tons)": -17.074068,
    "out:Klimatpaverkan": -217.209489,
    "out:Initial Cost/MSEK": 9.209046819517745,
    "out:Running cost/(Apt SEK y)": 4922.148936,
    "out:Running Cost over RSP/MSEK": 1.81167,
    "out:LCP/MSEK": 2.384003,
    "out:ROI% old": 17.983946,
    "out:Payback discounted": 7,
    "out:Atemp": 3184,
    "out:Umean": 0.65,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 42,
    "out:Energy use kWh/m2": 42,
    "out:Energy savings %": 335.7,
    "out:Op energy cost/MSEK": 13,
    "out:El price/MSEK": 11,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 6,
    "out:El bought/kWh/m2": 2539.009776,
    "out:Return %": 16,
    "out:Return/kSEK/y": 1483,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 1774800,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 88740,
    "out:EAHP (replacement cost)": 1325203.921671023,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 28.356,
    "out:EAHP (El)": 36.188214,
    "out:PV (kr/kWp)": 17461.48474631725,
    "out:PV (kWp installed)": 90.5097,
    "out:PV (m2 panels)": 474.867263,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 84922.568824,
    "out:PV (kWh sold)": 36713.204473,
    "out:PV (% sold (El))": 43.231387,
    "out:PV (kWh self-consumed)": 48209.364351,
    "out:PV (ratio sold/self-consumed)": 0.761537,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 522164.891205,
    "out:EL kWh savings": -74335.478802,
    "out:Sewage heat recovery cost": 273216,
    "out:Balancing hydronic system cost": 79599.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 428563.74,
    "out:DH kr savings": 522164.8912048951,
    "out:El kr savings": -148670.95760395844,
    "out:El kr sold": 55069.80670907872,
    "out:El kr saved": 96418.728702,
    "out:El kr return": 151488.53541107872,
    "out:% solar/total": 120,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 17750.046419,
    "out:% savings (space heating)": 95.640332,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 997124.4110739925,
    "out:Eupp": 3.54,
    "out:Etvv": 0,
    "out:Ef": 33.65,
    "out:heatPumpQuantity": 12,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 9209046.819517745,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 2.159904,
    "out:EL CO2": -53.21771,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -57.970168,
    "out:Bought CO2": 4.752458,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01001000000000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 88.927327,
    "out:DHW (DH)": 41.7,
    "out:Property Electricity": 12.57,
    "out:Electricity (inc PV)": 12.57,
    "out:Total Energy Use Pre PV": 143.57,
    "out:Total Energy Use Post PV": 143.57,
    "out:Primary Energy": 109.291699,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 12.630439,
    "out:CO2 Operational/m2": 48.817245,
    "out:Total CO2/m2": 61.447684,
    "out:Total CO2 (tons)": 195.649367,
    "out:Klimatpaverkan": -4.486054,
    "out:Initial Cost/MSEK": 2.83358685300375,
    "out:Running cost/(Apt SEK y)": 28504.234043,
    "out:Running Cost over RSP/MSEK": 10.47149,
    "out:LCP/MSEK": 0.099643,
    "out:ROI% old": 14.788072,
    "out:Payback discounted": 8,
    "out:Atemp": 3184,
    "out:Umean": 0.47,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 144,
    "out:Energy use kWh/m2": 144,
    "out:Energy savings %": 27.1,
    "out:Op energy cost/MSEK": 77,
    "out:El price/MSEK": 47,
    "out:Heating price/MSEK": 26,
    "out:DH+ DHW/kWh/m2": 131,
    "out:El bought/kWh/m2": 879.9,
    "out:Return %": 13,
    "out:Return/kSEK/y": 375,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 41.7,
    "out:EAHP (El)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 123997.653916,
    "out:EL kWh savings": 2.2624e-11,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 123997.65,
    "out:DH kr savings": 123997.65391564941,
    "out:El kr savings": 4.524734779920436e-11,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 283144.523657,
    "out:% savings (space heating)": 30.455615,
    "out:Total_Water Heating (DH)": 132772.760051,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 88.93,
    "out:Etvv": 25,
    "out:Ef": 12.57,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 2833586.8530037496,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 47.157904,
    "out:EL CO2": 1.659341,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 1.659341,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01001000000000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 85.157903,
    "out:DHW (DH)": 41.7,
    "out:Property Electricity": 12.57,
    "out:Electricity (inc PV)": 12.57,
    "out:Total Energy Use Pre PV": 139.57,
    "out:Total Energy Use Post PV": 139.57,
    "out:Primary Energy": 106.359925,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 12.630439,
    "out:CO2 Operational/m2": 47.377309,
    "out:Total CO2/m2": 60.007748,
    "out:Total CO2 (tons)": 191.064612,
    "out:Klimatpaverkan": -9.070809,
    "out:Initial Cost/MSEK": 2.9131868280037496,
    "out:Running cost/(Apt SEK y)": 27685.893617,
    "out:Running Cost over RSP/MSEK": 10.17064,
    "out:LCP/MSEK": 0.320893,
    "out:ROI% old": 15.859313,
    "out:Payback discounted": 8,
    "out:Atemp": 3184,
    "out:Umean": 0.47,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 140,
    "out:Energy use kWh/m2": 140,
    "out:Energy savings %": 30.7,
    "out:Op energy cost/MSEK": 74,
    "out:El price/MSEK": 45,
    "out:Heating price/MSEK": 25,
    "out:DH+ DHW/kWh/m2": 127,
    "out:El bought/kWh/m2": 879.9,
    "out:Return %": 14,
    "out:Return/kSEK/y": 413,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 41.7,
    "out:EAHP (El)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 135999.496161,
    "out:EL kWh savings": 2.2624e-11,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 79599.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 135999.5,
    "out:DH kr savings": 135999.4961613413,
    "out:El kr savings": 4.524734779920436e-11,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 271142.681412,
    "out:% savings (space heating)": 33.403441,
    "out:Total_Water Heating (DH)": 132772.760051,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 85.16,
    "out:Etvv": 25,
    "out:Ef": 12.57,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 2913186.8280037497,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 45.717968,
    "out:EL CO2": 1.659341,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 1.659341,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01001000000001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 88.927327,
    "out:DHW (DH)": 35.445,
    "out:Property Electricity": 12.57,
    "out:Electricity (inc PV)": 12.57,
    "out:Total Energy Use Pre PV": 136.57,
    "out:Total Energy Use Post PV": 136.57,
    "out:Primary Energy": 106.666699,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 13.338982,
    "out:CO2 Operational/m2": 46.297357,
    "out:Total CO2/m2": 59.636339,
    "out:Total CO2 (tons)": 189.882046,
    "out:Klimatpaverkan": -10.253375,
    "out:Initial Cost/MSEK": 3.1068028530037495,
    "out:Running cost/(Apt SEK y)": 27072.12766,
    "out:Running Cost over RSP/MSEK": 9.94501,
    "out:LCP/MSEK": 0.352907,
    "out:ROI% old": 15.908453,
    "out:Payback discounted": 8,
    "out:Atemp": 3184,
    "out:Umean": 0.47,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 137,
    "out:Energy use kWh/m2": 137,
    "out:Energy savings %": 33.6,
    "out:Op energy cost/MSEK": 73,
    "out:El price/MSEK": 44,
    "out:Heating price/MSEK": 24,
    "out:DH+ DHW/kWh/m2": 124,
    "out:El bought/kWh/m2": 879.9,
    "out:Return %": 14,
    "out:Return/kSEK/y": 442,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 35.445,
    "out:EAHP (El)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 143913.567923,
    "out:EL kWh savings": 2.2624e-11,
    "out:Sewage heat recovery cost": 273216,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 143913.57,
    "out:DH kr savings": 143913.5679233594,
    "out:El kr savings": 4.524734779920436e-11,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 283144.523657,
    "out:% savings (space heating)": 30.455615,
    "out:Total_Water Heating (DH)": 112856.846044,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 88.93,
    "out:Etvv": 21.25,
    "out:Ef": 12.57,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 3106802.8530037496,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 44.638016,
    "out:EL CO2": 1.659341,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 1.659341,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01001000000001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 85.157903,
    "out:DHW (DH)": 35.445,
    "out:Property Electricity": 12.57,
    "out:Electricity (inc PV)": 12.57,
    "out:Total Energy Use Pre PV": 133.57,
    "out:Total Energy Use Post PV": 133.57,
    "out:Primary Energy": 103.734925,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 13.338982,
    "out:CO2 Operational/m2": 45.217405,
    "out:Total CO2/m2": 58.556387,
    "out:Total CO2 (tons)": 186.44348,
    "out:Klimatpaverkan": -13.691941,
    "out:Initial Cost/MSEK": 3.1864028280037497,
    "out:Running cost/(Apt SEK y)": 26458.361702,
    "out:Running Cost over RSP/MSEK": 9.71937,
    "out:LCP/MSEK": 0.498947,
    "out:ROI% old": 16.522662,
    "out:Payback discounted": 7,
    "out:Atemp": 3184,
    "out:Umean": 0.47,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 134,
    "out:Energy use kWh/m2": 134,
    "out:Energy savings %": 36.6,
    "out:Op energy cost/MSEK": 71,
    "out:El price/MSEK": 43,
    "out:Heating price/MSEK": 24,
    "out:DH+ DHW/kWh/m2": 121,
    "out:El bought/kWh/m2": 879.9,
    "out:Return %": 15,
    "out:Return/kSEK/y": 471,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 35.445,
    "out:EAHP (El)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 155915.410169,
    "out:EL kWh savings": 2.2624e-11,
    "out:Sewage heat recovery cost": 273216,
    "out:Balancing hydronic system cost": 79599.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 155915.41,
    "out:DH kr savings": 155915.41016905126,
    "out:El kr savings": 4.524734779920436e-11,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 271142.681412,
    "out:% savings (space heating)": 33.403441,
    "out:Total_Water Heating (DH)": 112856.846044,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 85.16,
    "out:Etvv": 21.25,
    "out:Ef": 12.57,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 3186402.8280037497,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 43.558064,
    "out:EL CO2": 1.659341,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 1.659341,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01001000000100000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 1,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 88.927327,
    "out:DHW (DH)": 41.7,
    "out:Property Electricity": 9.976115,
    "out:Electricity (inc PV)": 9.976115,
    "out:Total Energy Use Pre PV": 140.976115,
    "out:Total Energy Use Post PV": 140.976115,
    "out:Primary Energy": 104.622706,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 12.814941,
    "out:CO2 Operational/m2": 48.474831,
    "out:Total CO2/m2": 61.289772,
    "out:Total CO2 (tons)": 195.146575,
    "out:Klimatpaverkan": -4.988846,
    "out:Initial Cost/MSEK": 2.9803797245235746,
    "out:Running cost/(Apt SEK y)": 28152.808511,
    "out:Running Cost over RSP/MSEK": 10.34379,
    "out:LCP/MSEK": 0.08055,
    "out:ROI% old": 14.671812,
    "out:Payback discounted": 8,
    "out:Atemp": 3184,
    "out:Umean": 0.47,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 141,
    "out:Energy use kWh/m2": 141,
    "out:Energy savings %": 29.8,
    "out:Op energy cost/MSEK": 76,
    "out:El price/MSEK": 46,
    "out:Heating price/MSEK": 26,
    "out:DH+ DHW/kWh/m2": 131,
    "out:El bought/kWh/m2": 698.32805,
    "out:Return %": 13,
    "out:Return/kSEK/y": 392,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 41.7,
    "out:EAHP (El)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 123997.653916,
    "out:EL kWh savings": 8258.927355,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 140515.51,
    "out:DH kr savings": 123997.65391564941,
    "out:El kr savings": 16517.854710116386,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 283144.523657,
    "out:% savings (space heating)": 30.455615,
    "out:Total_Water Heating (DH)": 132772.760051,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 88.93,
    "out:Etvv": 25,
    "out:Ef": 9.98,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 2980379.7245235746,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25,
    "out:New AHU Cost/SEK": 146792.87151982498,
    "out:DH CO2": 47.157904,
    "out:EL CO2": 1.316927,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 1.316927,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01001000000100100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 1,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 85.157903,
    "out:DHW (DH)": 41.7,
    "out:Property Electricity": 9.976115,
    "out:Electricity (inc PV)": 9.976115,
    "out:Total Energy Use Pre PV": 136.976115,
    "out:Total Energy Use Post PV": 136.976115,
    "out:Primary Energy": 101.690932,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 12.814941,
    "out:CO2 Operational/m2": 47.034895,
    "out:Total CO2/m2": 59.849836,
    "out:Total CO2 (tons)": 190.56182,
    "out:Klimatpaverkan": -9.573601,
    "out:Initial Cost/MSEK": 3.059979699523575,
    "out:Running cost/(Apt SEK y)": 27334.446809,
    "out:Running Cost over RSP/MSEK": 10.04294,
    "out:LCP/MSEK": 0.3018,
    "out:ROI% old": 15.694689,
    "out:Payback discounted": 8,
    "out:Atemp": 3184,
    "out:Umean": 0.47,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 137,
    "out:Energy use kWh/m2": 137,
    "out:Energy savings %": 33.6,
    "out:Op energy cost/MSEK": 73,
    "out:El price/MSEK": 45,
    "out:Heating price/MSEK": 25,
    "out:DH+ DHW/kWh/m2": 127,
    "out:El bought/kWh/m2": 698.32805,
    "out:Return %": 14,
    "out:Return/kSEK/y": 430,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 41.7,
    "out:EAHP (El)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 135999.496161,
    "out:EL kWh savings": 8258.927355,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 79599.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 152517.35,
    "out:DH kr savings": 135999.4961613413,
    "out:El kr savings": 16517.854710116386,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 271142.681412,
    "out:% savings (space heating)": 33.403441,
    "out:Total_Water Heating (DH)": 132772.760051,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 85.16,
    "out:Etvv": 25,
    "out:Ef": 9.98,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 3059979.6995235747,
    "out:Seasonal Variation ROI (%)": 5,
    "out:Seasonal Variation Payback": 20,
    "out:New AHU Cost/SEK": 146792.87151982498,
    "out:DH CO2": 45.717968,
    "out:EL CO2": 1.316927,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 1.316927,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01001000000101000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 1,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 88.927327,
    "out:DHW (DH)": 35.445,
    "out:Property Electricity": 9.976115,
    "out:Electricity (inc PV)": 9.976115,
    "out:Total Energy Use Pre PV": 133.976115,
    "out:Total Energy Use Post PV": 133.976115,
    "out:Primary Energy": 101.997706,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 13.523484,
    "out:CO2 Operational/m2": 45.954943,
    "out:Total CO2/m2": 59.478427,
    "out:Total CO2 (tons)": 189.379255,
    "out:Klimatpaverkan": -10.756166,
    "out:Initial Cost/MSEK": 3.2535957245235747,
    "out:Running cost/(Apt SEK y)": 26720.680851,
    "out:Running Cost over RSP/MSEK": 9.81731,
    "out:LCP/MSEK": 0.333814,
    "out:ROI% old": 15.751408,
    "out:Payback discounted": 8,
    "out:Atemp": 3184,
    "out:Umean": 0.47,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 134,
    "out:Energy use kWh/m2": 134,
    "out:Energy savings %": 36.6,
    "out:Op energy cost/MSEK": 72,
    "out:El price/MSEK": 44,
    "out:Heating price/MSEK": 24,
    "out:DH+ DHW/kWh/m2": 124,
    "out:El bought/kWh/m2": 698.32805,
    "out:Return %": 14,
    "out:Return/kSEK/y": 459,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 35.445,
    "out:EAHP (El)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 143913.567923,
    "out:EL kWh savings": 8258.927355,
    "out:Sewage heat recovery cost": 273216,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 160431.42,
    "out:DH kr savings": 143913.5679233594,
    "out:El kr savings": 16517.854710116386,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 283144.523657,
    "out:% savings (space heating)": 30.455615,
    "out:Total_Water Heating (DH)": 112856.846044,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 88.93,
    "out:Etvv": 21.25,
    "out:Ef": 9.98,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 3253595.7245235746,
    "out:Seasonal Variation ROI (%)": 5,
    "out:Seasonal Variation Payback": 20,
    "out:New AHU Cost/SEK": 146792.87151982498,
    "out:DH CO2": 44.638016,
    "out:EL CO2": 1.316927,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 1.316927,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01001000000101100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 1,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 85.157903,
    "out:DHW (DH)": 35.445,
    "out:Property Electricity": 9.976115,
    "out:Electricity (inc PV)": 9.976115,
    "out:Total Energy Use Pre PV": 130.976115,
    "out:Total Energy Use Post PV": 130.976115,
    "out:Primary Energy": 99.065932,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 13.523484,
    "out:CO2 Operational/m2": 44.874991,
    "out:Total CO2/m2": 58.398475,
    "out:Total CO2 (tons)": 185.940688,
    "out:Klimatpaverkan": -14.194733,
    "out:Initial Cost/MSEK": 3.3331956995235745,
    "out:Running cost/(Apt SEK y)": 26106.914894,
    "out:Running Cost over RSP/MSEK": 9.59168,
    "out:LCP/MSEK": 0.479844,
    "out:ROI% old": 16.342275,
    "out:Payback discounted": 7,
    "out:Atemp": 3184,
    "out:Umean": 0.47,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 131,
    "out:Energy use kWh/m2": 131,
    "out:Energy savings %": 39.7,
    "out:Op energy cost/MSEK": 70,
    "out:El price/MSEK": 43,
    "out:Heating price/MSEK": 24,
    "out:DH+ DHW/kWh/m2": 121,
    "out:El bought/kWh/m2": 698.32805,
    "out:Return %": 15,
    "out:Return/kSEK/y": 488,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 35.445,
    "out:EAHP (El)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 155915.410169,
    "out:EL kWh savings": 8258.927355,
    "out:Sewage heat recovery cost": 273216,
    "out:Balancing hydronic system cost": 79599.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 172433.26,
    "out:DH kr savings": 155915.41016905126,
    "out:El kr savings": 16517.854710116386,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 271142.681412,
    "out:% savings (space heating)": 33.403441,
    "out:Total_Water Heating (DH)": 112856.846044,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 85.16,
    "out:Etvv": 21.25,
    "out:Ef": 9.98,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 3333195.6995235747,
    "out:Seasonal Variation ROI (%)": 5,
    "out:Seasonal Variation Payback": 20,
    "out:New AHU Cost/SEK": 146792.87151982498,
    "out:DH CO2": 43.558064,
    "out:EL CO2": 1.316927,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 1.316927,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01001000010000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 88.927327,
    "out:DHW (DH)": 41.7,
    "out:Property Electricity": 12.57,
    "out:Electricity (inc PV)": 8.051517,
    "out:Total Energy Use Pre PV": 143.57,
    "out:Total Energy Use Post PV": 139.051517,
    "out:Primary Energy": 101.15843,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 27.369539,
    "out:CO2 Operational/m2": 3.556583,
    "out:Total CO2/m2": 30.926122,
    "out:Total CO2 (tons)": 98.468743,
    "out:Klimatpaverkan": -101.666678,
    "out:Initial Cost/MSEK": 3.62380724722625,
    "out:Running cost/(Apt SEK y)": 26996.042553,
    "out:Running Cost over RSP/MSEK": 9.93212,
    "out:LCP/MSEK": -0.151207,
    "out:ROI% old": 13.689628,
    "out:Payback discounted": 9,
    "out:Atemp": 3184,
    "out:Umean": 0.47,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 139,
    "out:Energy use kWh/m2": 139,
    "out:Energy savings %": 31.7,
    "out:Op energy cost/MSEK": 73,
    "out:El price/MSEK": 43,
    "out:Heating price/MSEK": 26,
    "out:DH+ DHW/kWh/m2": 131,
    "out:El bought/kWh/m2": 568.258452,
    "out:Return %": 12,
    "out:Return/kSEK/y": 446,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 41.7,
    "out:EAHP (El)": 0,
    "out:PV (kr/kWp)": 17461.56255567083,
    "out:PV (kWp installed)": 45.25485,
    "out:PV (m2 panels)": 237.433632,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 42461.284501,
    "out:PV (kWh sold)": 28074.439514,
    "out:PV (% sold (El))": 66.117735,
    "out:PV (kWh self-consumed)": 14386.844987,
    "out:PV (ratio sold/self-consumed)": 1.951397,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 123997.653916,
    "out:EL kWh savings": 14386.845543,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 194883,
    "out:DH kr savings": 123997.65391564941,
    "out:El kr savings": 28773.691086586638,
    "out:El kr sold": 42111.65927092506,
    "out:El kr saved": 28773.689974,
    "out:El kr return": 70885.34924492506,
    "out:% solar/total": 36,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 283144.523657,
    "out:% savings (space heating)": 30.455615,
    "out:Total_Water Heating (DH)": 132772.760051,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 88.93,
    "out:Etvv": 25,
    "out:Ef": 8.05,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 3623807.24722625,
    "out:Seasonal Variation ROI (%)": 5,
    "out:Seasonal Variation Payback": 20,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 47.157904,
    "out:EL CO2": -43.601321,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -44.666212,
    "out:Bought CO2": 1.064891,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01001000010000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 85.157903,
    "out:DHW (DH)": 41.7,
    "out:Property Electricity": 12.57,
    "out:Electricity (inc PV)": 8.051517,
    "out:Total Energy Use Pre PV": 139.57,
    "out:Total Energy Use Post PV": 135.051517,
    "out:Primary Energy": 98.226656,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 27.369539,
    "out:CO2 Operational/m2": 2.116647,
    "out:Total CO2/m2": 29.486186,
    "out:Total CO2 (tons)": 93.883988,
    "out:Klimatpaverkan": -106.251433,
    "out:Initial Cost/MSEK": 3.70340722222625,
    "out:Running cost/(Apt SEK y)": 26177.680851,
    "out:Running Cost over RSP/MSEK": 9.63127,
    "out:LCP/MSEK": 0.070043,
    "out:ROI% old": 14.555901,
    "out:Payback discounted": 8,
    "out:Atemp": 3184,
    "out:Umean": 0.47,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 135,
    "out:Energy use kWh/m2": 135,
    "out:Energy savings %": 35.6,
    "out:Op energy cost/MSEK": 71,
    "out:El price/MSEK": 42,
    "out:Heating price/MSEK": 25,
    "out:DH+ DHW/kWh/m2": 127,
    "out:El bought/kWh/m2": 568.258452,
    "out:Return %": 13,
    "out:Return/kSEK/y": 484,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 41.7,
    "out:EAHP (El)": 0,
    "out:PV (kr/kWp)": 17461.56255567083,
    "out:PV (kWp installed)": 45.25485,
    "out:PV (m2 panels)": 237.433632,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 42461.284501,
    "out:PV (kWh sold)": 28074.439514,
    "out:PV (% sold (El))": 66.117735,
    "out:PV (kWh self-consumed)": 14386.844987,
    "out:PV (ratio sold/self-consumed)": 1.951397,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 135999.496161,
    "out:EL kWh savings": 14386.845543,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 79599.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 206884.85,
    "out:DH kr savings": 135999.4961613413,
    "out:El kr savings": 28773.691086586638,
    "out:El kr sold": 42111.65927092506,
    "out:El kr saved": 28773.689974,
    "out:El kr return": 70885.34924492506,
    "out:% solar/total": 36,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 271142.681412,
    "out:% savings (space heating)": 33.403441,
    "out:Total_Water Heating (DH)": 132772.760051,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 85.16,
    "out:Etvv": 25,
    "out:Ef": 8.05,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 3703407.22222625,
    "out:Seasonal Variation ROI (%)": 5,
    "out:Seasonal Variation Payback": 20,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 45.717968,
    "out:EL CO2": -43.601321,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -44.666212,
    "out:Bought CO2": 1.064891,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01001000010001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 88.927327,
    "out:DHW (DH)": 35.445,
    "out:Property Electricity": 12.57,
    "out:Electricity (inc PV)": 8.051517,
    "out:Total Energy Use Pre PV": 136.57,
    "out:Total Energy Use Post PV": 132.051517,
    "out:Primary Energy": 98.53343,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 28.078082,
    "out:CO2 Operational/m2": 1.036695,
    "out:Total CO2/m2": 29.114777,
    "out:Total CO2 (tons)": 92.701422,
    "out:Klimatpaverkan": -107.433999,
    "out:Initial Cost/MSEK": 3.8970232472262496,
    "out:Running cost/(Apt SEK y)": 25563.914894,
    "out:Running Cost over RSP/MSEK": 9.40564,
    "out:LCP/MSEK": 0.102057,
    "out:ROI% old": 14.659834,
    "out:Payback discounted": 8,
    "out:Atemp": 3184,
    "out:Umean": 0.47,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 132,
    "out:Energy use kWh/m2": 132,
    "out:Energy savings %": 38.6,
    "out:Op energy cost/MSEK": 69,
    "out:El price/MSEK": 41,
    "out:Heating price/MSEK": 24,
    "out:DH+ DHW/kWh/m2": 124,
    "out:El bought/kWh/m2": 568.258452,
    "out:Return %": 13,
    "out:Return/kSEK/y": 513,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 35.445,
    "out:EAHP (El)": 0,
    "out:PV (kr/kWp)": 17461.56255567083,
    "out:PV (kWp installed)": 45.25485,
    "out:PV (m2 panels)": 237.433632,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 42461.284501,
    "out:PV (kWh sold)": 28074.439514,
    "out:PV (% sold (El))": 66.117735,
    "out:PV (kWh self-consumed)": 14386.844987,
    "out:PV (ratio sold/self-consumed)": 1.951397,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 143913.567923,
    "out:EL kWh savings": 14386.845543,
    "out:Sewage heat recovery cost": 273216,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 214798.92,
    "out:DH kr savings": 143913.5679233594,
    "out:El kr savings": 28773.691086586638,
    "out:El kr sold": 42111.65927092506,
    "out:El kr saved": 28773.689974,
    "out:El kr return": 70885.34924492506,
    "out:% solar/total": 36,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 283144.523657,
    "out:% savings (space heating)": 30.455615,
    "out:Total_Water Heating (DH)": 112856.846044,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 88.93,
    "out:Etvv": 21.25,
    "out:Ef": 8.05,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 3897023.2472262494,
    "out:Seasonal Variation ROI (%)": 5,
    "out:Seasonal Variation Payback": 20,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 44.638016,
    "out:EL CO2": -43.601321,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -44.666212,
    "out:Bought CO2": 1.064891,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01001000010001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 85.157903,
    "out:DHW (DH)": 35.445,
    "out:Property Electricity": 12.57,
    "out:Electricity (inc PV)": 8.051517,
    "out:Total Energy Use Pre PV": 133.57,
    "out:Total Energy Use Post PV": 129.051517,
    "out:Primary Energy": 95.601656,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 28.078082,
    "out:CO2 Operational/m2": -0.043257,
    "out:Total CO2/m2": 28.034825,
    "out:Total CO2 (tons)": 89.262856,
    "out:Klimatpaverkan": -110.872565,
    "out:Initial Cost/MSEK": 3.9766232222262494,
    "out:Running cost/(Apt SEK y)": 24950.148936,
    "out:Running Cost over RSP/MSEK": 9.18001,
    "out:LCP/MSEK": 0.248087,
    "out:ROI% old": 15.176947,
    "out:Payback discounted": 8,
    "out:Atemp": 3184,
    "out:Umean": 0.47,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 129,
    "out:Energy use kWh/m2": 129,
    "out:Energy savings %": 41.9,
    "out:Op energy cost/MSEK": 68,
    "out:El price/MSEK": 40,
    "out:Heating price/MSEK": 24,
    "out:DH+ DHW/kWh/m2": 121,
    "out:El bought/kWh/m2": 568.258452,
    "out:Return %": 14,
    "out:Return/kSEK/y": 542,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 35.445,
    "out:EAHP (El)": 0,
    "out:PV (kr/kWp)": 17461.56255567083,
    "out:PV (kWp installed)": 45.25485,
    "out:PV (m2 panels)": 237.433632,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 42461.284501,
    "out:PV (kWh sold)": 28074.439514,
    "out:PV (% sold (El))": 66.117735,
    "out:PV (kWh self-consumed)": 14386.844987,
    "out:PV (ratio sold/self-consumed)": 1.951397,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 155915.410169,
    "out:EL kWh savings": 14386.845543,
    "out:Sewage heat recovery cost": 273216,
    "out:Balancing hydronic system cost": 79599.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 226800.76,
    "out:DH kr savings": 155915.41016905126,
    "out:El kr savings": 28773.691086586638,
    "out:El kr sold": 42111.65927092506,
    "out:El kr saved": 28773.689974,
    "out:El kr return": 70885.34924492506,
    "out:% solar/total": 36,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 271142.681412,
    "out:% savings (space heating)": 33.403441,
    "out:Total_Water Heating (DH)": 112856.846044,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 85.16,
    "out:Etvv": 21.25,
    "out:Ef": 8.05,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 3976623.2222262495,
    "out:Seasonal Variation ROI (%)": 5,
    "out:Seasonal Variation Payback": 20,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 43.558064,
    "out:EL CO2": -43.601321,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -44.666212,
    "out:Bought CO2": 1.064891,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01001000010100000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 1,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 88.927327,
    "out:DHW (DH)": 41.7,
    "out:Property Electricity": 9.976115,
    "out:Electricity (inc PV)": 6.231337,
    "out:Total Energy Use Pre PV": 140.976115,
    "out:Total Energy Use Post PV": 137.231337,
    "out:Primary Energy": 97.882106,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 27.554041,
    "out:CO2 Operational/m2": -0.673545,
    "out:Total CO2/m2": 26.880496,
    "out:Total CO2 (tons)": 85.587474,
    "out:Klimatpaverkan": -114.547948,
    "out:Initial Cost/MSEK": 3.7706001187460747,
    "out:Running cost/(Apt SEK y)": 26670.808511,
    "out:Running Cost over RSP/MSEK": 9.81389,
    "out:LCP/MSEK": -0.17977,
    "out:ROI% old": 13.604617,
    "out:Payback discounted": 9,
    "out:Atemp": 3184,
    "out:Umean": 0.47,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 137,
    "out:Energy use kWh/m2": 137,
    "out:Energy savings %": 33.6,
    "out:Op energy cost/MSEK": 72,
    "out:El price/MSEK": 43,
    "out:Heating price/MSEK": 26,
    "out:DH+ DHW/kWh/m2": 131,
    "out:El bought/kWh/m2": 439.497378,
    "out:Return %": 12,
    "out:Return/kSEK/y": 461,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 41.7,
    "out:EAHP (El)": 0,
    "out:PV (kr/kWp)": 17461.56255567083,
    "out:PV (kWp installed)": 45.25485,
    "out:PV (m2 panels)": 237.433632,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 42461.284501,
    "out:PV (kWh sold)": 30537.914073,
    "out:PV (% sold (El))": 71.919431,
    "out:PV (kWh self-consumed)": 11923.370428,
    "out:PV (ratio sold/self-consumed)": 2.561181,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 123997.653916,
    "out:EL kWh savings": 20182.29692,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 210169.12,
    "out:DH kr savings": 123997.65391564941,
    "out:El kr savings": 40364.59383912171,
    "out:El kr sold": 45806.87111012268,
    "out:El kr saved": 23846.740856,
    "out:El kr return": 69653.61196612268,
    "out:% solar/total": 30,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 283144.523657,
    "out:% savings (space heating)": 30.455615,
    "out:Total_Water Heating (DH)": 132772.760051,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 88.93,
    "out:Etvv": 25,
    "out:Ef": 6.23,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 3770600.118746075,
    "out:Seasonal Variation ROI (%)": 5,
    "out:Seasonal Variation Payback": 20,
    "out:New AHU Cost/SEK": 146792.87151982498,
    "out:DH CO2": 47.157904,
    "out:EL CO2": -47.831449,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -48.655512,
    "out:Bought CO2": 0.824063,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01001000010100100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 1,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 85.157903,
    "out:DHW (DH)": 41.7,
    "out:Property Electricity": 9.976115,
    "out:Electricity (inc PV)": 6.231337,
    "out:Total Energy Use Pre PV": 136.976115,
    "out:Total Energy Use Post PV": 133.231337,
    "out:Primary Energy": 94.950332,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 27.554041,
    "out:CO2 Operational/m2": -2.113481,
    "out:Total CO2/m2": 25.44056,
    "out:Total CO2 (tons)": 81.002719,
    "out:Klimatpaverkan": -119.132702,
    "out:Initial Cost/MSEK": 3.8502000937460745,
    "out:Running cost/(Apt SEK y)": 25852.446809,
    "out:Running Cost over RSP/MSEK": 9.51304,
    "out:LCP/MSEK": 0.04148,
    "out:ROI% old": 14.439621,
    "out:Payback discounted": 8,
    "out:Atemp": 3184,
    "out:Umean": 0.47,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 133,
    "out:Energy use kWh/m2": 133,
    "out:Energy savings %": 37.6,
    "out:Op energy cost/MSEK": 70,
    "out:El price/MSEK": 41,
    "out:Heating price/MSEK": 25,
    "out:DH+ DHW/kWh/m2": 127,
    "out:El bought/kWh/m2": 439.497378,
    "out:Return %": 13,
    "out:Return/kSEK/y": 500,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 41.7,
    "out:EAHP (El)": 0,
    "out:PV (kr/kWp)": 17461.56255567083,
    "out:PV (kWp installed)": 45.25485,
    "out:PV (m2 panels)": 237.433632,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 42461.284501,
    "out:PV (kWh sold)": 30537.914073,
    "out:PV (% sold (El))": 71.919431,
    "out:PV (kWh self-consumed)": 11923.370428,
    "out:PV (ratio sold/self-consumed)": 2.561181,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 135999.496161,
    "out:EL kWh savings": 20182.29692,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 79599.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 222170.96,
    "out:DH kr savings": 135999.4961613413,
    "out:El kr savings": 40364.59383912171,
    "out:El kr sold": 45806.87111012268,
    "out:El kr saved": 23846.740856,
    "out:El kr return": 69653.61196612268,
    "out:% solar/total": 30,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 271142.681412,
    "out:% savings (space heating)": 33.403441,
    "out:Total_Water Heating (DH)": 132772.760051,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 85.16,
    "out:Etvv": 25,
    "out:Ef": 6.23,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 3850200.0937460745,
    "out:Seasonal Variation ROI (%)": 5,
    "out:Seasonal Variation Payback": 20,
    "out:New AHU Cost/SEK": 146792.87151982498,
    "out:DH CO2": 45.717968,
    "out:EL CO2": -47.831449,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -48.655512,
    "out:Bought CO2": 0.824063,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01001000010101000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 1,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 88.927327,
    "out:DHW (DH)": 35.445,
    "out:Property Electricity": 9.976115,
    "out:Electricity (inc PV)": 6.231337,
    "out:Total Energy Use Pre PV": 133.976115,
    "out:Total Energy Use Post PV": 130.231337,
    "out:Primary Energy": 95.257106,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 28.262584,
    "out:CO2 Operational/m2": -3.193433,
    "out:Total CO2/m2": 25.069151,
    "out:Total CO2 (tons)": 79.820153,
    "out:Klimatpaverkan": -120.315268,
    "out:Initial Cost/MSEK": 4.043816118746074,
    "out:Running cost/(Apt SEK y)": 25238.680851,
    "out:Running Cost over RSP/MSEK": 9.28741,
    "out:LCP/MSEK": 0.073494,
    "out:ROI% old": 14.545348,
    "out:Payback discounted": 8,
    "out:Atemp": 3184,
    "out:Umean": 0.47,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 130,
    "out:Energy use kWh/m2": 130,
    "out:Energy savings %": 40.8,
    "out:Op energy cost/MSEK": 69,
    "out:El price/MSEK": 40,
    "out:Heating price/MSEK": 24,
    "out:DH+ DHW/kWh/m2": 124,
    "out:El bought/kWh/m2": 439.497378,
    "out:Return %": 13,
    "out:Return/kSEK/y": 528,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 35.445,
    "out:EAHP (El)": 0,
    "out:PV (kr/kWp)": 17461.56255567083,
    "out:PV (kWp installed)": 45.25485,
    "out:PV (m2 panels)": 237.433632,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 42461.284501,
    "out:PV (kWh sold)": 30537.914073,
    "out:PV (% sold (El))": 71.919431,
    "out:PV (kWh self-consumed)": 11923.370428,
    "out:PV (ratio sold/self-consumed)": 2.561181,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 143913.567923,
    "out:EL kWh savings": 20182.29692,
    "out:Sewage heat recovery cost": 273216,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 230085.03,
    "out:DH kr savings": 143913.5679233594,
    "out:El kr savings": 40364.59383912171,
    "out:El kr sold": 45806.87111012268,
    "out:El kr saved": 23846.740856,
    "out:El kr return": 69653.61196612268,
    "out:% solar/total": 30,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 283144.523657,
    "out:% savings (space heating)": 30.455615,
    "out:Total_Water Heating (DH)": 112856.846044,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 88.93,
    "out:Etvv": 21.25,
    "out:Ef": 6.23,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 4043816.118746074,
    "out:Seasonal Variation ROI (%)": 5,
    "out:Seasonal Variation Payback": 20,
    "out:New AHU Cost/SEK": 146792.87151982498,
    "out:DH CO2": 44.638016,
    "out:EL CO2": -47.831449,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -48.655512,
    "out:Bought CO2": 0.824063,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01001000010101100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 1,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 85.157903,
    "out:DHW (DH)": 35.445,
    "out:Property Electricity": 9.976115,
    "out:Electricity (inc PV)": 6.231337,
    "out:Total Energy Use Pre PV": 130.976115,
    "out:Total Energy Use Post PV": 127.231337,
    "out:Primary Energy": 92.325332,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 28.262584,
    "out:CO2 Operational/m2": -4.273385,
    "out:Total CO2/m2": 23.989199,
    "out:Total CO2 (tons)": 76.381587,
    "out:Klimatpaverkan": -123.753834,
    "out:Initial Cost/MSEK": 4.123416093746075,
    "out:Running cost/(Apt SEK y)": 24624.914894,
    "out:Running Cost over RSP/MSEK": 9.06178,
    "out:LCP/MSEK": 0.219524,
    "out:ROI% old": 15.046262,
    "out:Payback discounted": 8,
    "out:Atemp": 3184,
    "out:Umean": 0.47,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 127,
    "out:Energy use kWh/m2": 127,
    "out:Energy savings %": 44.1,
    "out:Op energy cost/MSEK": 67,
    "out:El price/MSEK": 39,
    "out:Heating price/MSEK": 24,
    "out:DH+ DHW/kWh/m2": 121,
    "out:El bought/kWh/m2": 439.497378,
    "out:Return %": 14,
    "out:Return/kSEK/y": 557,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 35.445,
    "out:EAHP (El)": 0,
    "out:PV (kr/kWp)": 17461.56255567083,
    "out:PV (kWp installed)": 45.25485,
    "out:PV (m2 panels)": 237.433632,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 42461.284501,
    "out:PV (kWh sold)": 30537.914073,
    "out:PV (% sold (El))": 71.919431,
    "out:PV (kWh self-consumed)": 11923.370428,
    "out:PV (ratio sold/self-consumed)": 2.561181,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 155915.410169,
    "out:EL kWh savings": 20182.29692,
    "out:Sewage heat recovery cost": 273216,
    "out:Balancing hydronic system cost": 79599.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 242086.88,
    "out:DH kr savings": 155915.41016905126,
    "out:El kr savings": 40364.59383912171,
    "out:El kr sold": 45806.87111012268,
    "out:El kr saved": 23846.740856,
    "out:El kr return": 69653.61196612268,
    "out:% solar/total": 30,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 271142.681412,
    "out:% savings (space heating)": 33.403441,
    "out:Total_Water Heating (DH)": 112856.846044,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 85.16,
    "out:Etvv": 21.25,
    "out:Ef": 6.23,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 4123416.0937460745,
    "out:Seasonal Variation ROI (%)": 6,
    "out:Seasonal Variation Payback": 16.666667,
    "out:New AHU Cost/SEK": 146792.87151982498,
    "out:DH CO2": 43.558064,
    "out:EL CO2": -47.831449,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -48.655512,
    "out:Bought CO2": 0.824063,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01001000020000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 88.927327,
    "out:DHW (DH)": 41.7,
    "out:Property Electricity": 12.57,
    "out:Electricity (inc PV)": 7.530921,
    "out:Total Energy Use Pre PV": 143.57,
    "out:Total Energy Use Post PV": 138.530921,
    "out:Primary Energy": 100.221357,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 42.108638,
    "out:CO2 Operational/m2": -61.798734,
    "out:Total CO2/m2": -19.690096,
    "out:Total CO2 (tons)": -62.693247,
    "out:Klimatpaverkan": -262.828668,
    "out:Initial Cost/MSEK": 4.4140205989475,
    "out:Running cost/(Apt SEK y)": 25623.255319,
    "out:Running Cost over RSP/MSEK": 9.44168,
    "out:LCP/MSEK": -0.450981,
    "out:ROI% old": 12.826142,
    "out:Payback discounted": 9,
    "out:Atemp": 3184,
    "out:Umean": 0.47,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 139,
    "out:Energy use kWh/m2": 139,
    "out:Energy savings %": 31.7,
    "out:Op energy cost/MSEK": 70,
    "out:El price/MSEK": 40,
    "out:Heating price/MSEK": 26,
    "out:DH+ DHW/kWh/m2": 131,
    "out:El bought/kWh/m2": 530.25352,
    "out:Return %": 12,
    "out:Return/kSEK/y": 510,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 41.7,
    "out:EAHP (El)": 0,
    "out:PV (kr/kWp)": 17461.48474631725,
    "out:PV (kWp installed)": 90.5097,
    "out:PV (m2 panels)": 474.867263,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 84922.568824,
    "out:PV (kWh sold)": 68878.145463,
    "out:PV (% sold (El))": 81.106997,
    "out:PV (kWh self-consumed)": 16044.423361,
    "out:PV (ratio sold/self-consumed)": 4.292965,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 123997.653916,
    "out:EL kWh savings": 16044.422709,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 259403.72,
    "out:DH kr savings": 123997.65391564941,
    "out:El kr savings": 32088.845417124598,
    "out:El kr sold": 103317.21819422109,
    "out:El kr saved": 32088.846722,
    "out:El kr return": 135406.06491622108,
    "out:% solar/total": 40,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 283144.523657,
    "out:% savings (space heating)": 30.455615,
    "out:Total_Water Heating (DH)": 132772.760051,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 88.93,
    "out:Etvv": 25,
    "out:Ef": 7.53,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 4414020.5989475,
    "out:Seasonal Variation ROI (%)": 6,
    "out:Seasonal Variation Payback": 16.666667,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 47.157904,
    "out:EL CO2": -108.956638,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -109.952146,
    "out:Bought CO2": 0.995508,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01001000020000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 85.157903,
    "out:DHW (DH)": 41.7,
    "out:Property Electricity": 12.57,
    "out:Electricity (inc PV)": 7.530921,
    "out:Total Energy Use Pre PV": 139.57,
    "out:Total Energy Use Post PV": 134.530921,
    "out:Primary Energy": 97.289583,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 42.108638,
    "out:CO2 Operational/m2": -63.23867,
    "out:Total CO2/m2": -21.130032,
    "out:Total CO2 (tons)": -67.278002,
    "out:Klimatpaverkan": -267.413423,
    "out:Initial Cost/MSEK": 4.4936205739475,
    "out:Running cost/(Apt SEK y)": 24804.914894,
    "out:Running Cost over RSP/MSEK": 9.14084,
    "out:LCP/MSEK": -0.229741,
    "out:ROI% old": 13.555344,
    "out:Payback discounted": 9,
    "out:Atemp": 3184,
    "out:Umean": 0.47,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 135,
    "out:Energy use kWh/m2": 135,
    "out:Energy savings %": 35.6,
    "out:Op energy cost/MSEK": 68,
    "out:El price/MSEK": 39,
    "out:Heating price/MSEK": 25,
    "out:DH+ DHW/kWh/m2": 127,
    "out:El bought/kWh/m2": 530.25352,
    "out:Return %": 12,
    "out:Return/kSEK/y": 549,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 41.7,
    "out:EAHP (El)": 0,
    "out:PV (kr/kWp)": 17461.48474631725,
    "out:PV (kWp installed)": 90.5097,
    "out:PV (m2 panels)": 474.867263,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 84922.568824,
    "out:PV (kWh sold)": 68878.145463,
    "out:PV (% sold (El))": 81.106997,
    "out:PV (kWh self-consumed)": 16044.423361,
    "out:PV (ratio sold/self-consumed)": 4.292965,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 135999.496161,
    "out:EL kWh savings": 16044.422709,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 79599.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 271405.56,
    "out:DH kr savings": 135999.4961613413,
    "out:El kr savings": 32088.845417124598,
    "out:El kr sold": 103317.21819422109,
    "out:El kr saved": 32088.846722,
    "out:El kr return": 135406.06491622108,
    "out:% solar/total": 40,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 271142.681412,
    "out:% savings (space heating)": 33.403441,
    "out:Total_Water Heating (DH)": 132772.760051,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 85.16,
    "out:Etvv": 25,
    "out:Ef": 7.53,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 4493620.5739475,
    "out:Seasonal Variation ROI (%)": 6,
    "out:Seasonal Variation Payback": 16.666667,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 45.717968,
    "out:EL CO2": -108.956638,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -109.952146,
    "out:Bought CO2": 0.995508,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01001000020001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 88.927327,
    "out:DHW (DH)": 35.445,
    "out:Property Electricity": 12.57,
    "out:Electricity (inc PV)": 7.530921,
    "out:Total Energy Use Pre PV": 136.57,
    "out:Total Energy Use Post PV": 131.530921,
    "out:Primary Energy": 97.596357,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 42.817181,
    "out:CO2 Operational/m2": -64.318622,
    "out:Total CO2/m2": -21.501441,
    "out:Total CO2 (tons)": -68.460568,
    "out:Klimatpaverkan": -268.595989,
    "out:Initial Cost/MSEK": 4.6872365989475,
    "out:Running cost/(Apt SEK y)": 24191.148936,
    "out:Running Cost over RSP/MSEK": 8.91521,
    "out:LCP/MSEK": -0.197727,
    "out:ROI% old": 13.683085,
    "out:Payback discounted": 9,
    "out:Atemp": 3184,
    "out:Umean": 0.47,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 132,
    "out:Energy use kWh/m2": 132,
    "out:Energy savings %": 38.6,
    "out:Op energy cost/MSEK": 66,
    "out:El price/MSEK": 38,
    "out:Heating price/MSEK": 24,
    "out:DH+ DHW/kWh/m2": 124,
    "out:El bought/kWh/m2": 530.25352,
    "out:Return %": 12,
    "out:Return/kSEK/y": 578,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 35.445,
    "out:EAHP (El)": 0,
    "out:PV (kr/kWp)": 17461.48474631725,
    "out:PV (kWp installed)": 90.5097,
    "out:PV (m2 panels)": 474.867263,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 84922.568824,
    "out:PV (kWh sold)": 68878.145463,
    "out:PV (% sold (El))": 81.106997,
    "out:PV (kWh self-consumed)": 16044.423361,
    "out:PV (ratio sold/self-consumed)": 4.292965,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 143913.567923,
    "out:EL kWh savings": 16044.422709,
    "out:Sewage heat recovery cost": 273216,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 279319.63,
    "out:DH kr savings": 143913.5679233594,
    "out:El kr savings": 32088.845417124598,
    "out:El kr sold": 103317.21819422109,
    "out:El kr saved": 32088.846722,
    "out:El kr return": 135406.06491622108,
    "out:% solar/total": 40,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 283144.523657,
    "out:% savings (space heating)": 30.455615,
    "out:Total_Water Heating (DH)": 112856.846044,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 88.93,
    "out:Etvv": 21.25,
    "out:Ef": 7.53,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 4687236.5989475,
    "out:Seasonal Variation ROI (%)": 6,
    "out:Seasonal Variation Payback": 16.666667,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 44.638016,
    "out:EL CO2": -108.956638,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -109.952146,
    "out:Bought CO2": 0.995508,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01001000020001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 85.157903,
    "out:DHW (DH)": 35.445,
    "out:Property Electricity": 12.57,
    "out:Electricity (inc PV)": 7.530921,
    "out:Total Energy Use Pre PV": 133.57,
    "out:Total Energy Use Post PV": 128.530921,
    "out:Primary Energy": 94.664583,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 42.817181,
    "out:CO2 Operational/m2": -65.398574,
    "out:Total CO2/m2": -22.581393,
    "out:Total CO2 (tons)": -71.899134,
    "out:Klimatpaverkan": -272.034555,
    "out:Initial Cost/MSEK": 4.7668365739475,
    "out:Running cost/(Apt SEK y)": 23577.382979,
    "out:Running Cost over RSP/MSEK": 8.68957,
    "out:LCP/MSEK": -0.051687,
    "out:ROI% old": 14.130815,
    "out:Payback discounted": 9,
    "out:Atemp": 3184,
    "out:Umean": 0.47,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 129,
    "out:Energy use kWh/m2": 129,
    "out:Energy savings %": 41.9,
    "out:Op energy cost/MSEK": 65,
    "out:El price/MSEK": 37,
    "out:Heating price/MSEK": 24,
    "out:DH+ DHW/kWh/m2": 121,
    "out:El bought/kWh/m2": 530.25352,
    "out:Return %": 13,
    "out:Return/kSEK/y": 607,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 35.445,
    "out:EAHP (El)": 0,
    "out:PV (kr/kWp)": 17461.48474631725,
    "out:PV (kWp installed)": 90.5097,
    "out:PV (m2 panels)": 474.867263,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 84922.568824,
    "out:PV (kWh sold)": 68878.145463,
    "out:PV (% sold (El))": 81.106997,
    "out:PV (kWh self-consumed)": 16044.423361,
    "out:PV (ratio sold/self-consumed)": 4.292965,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 155915.410169,
    "out:EL kWh savings": 16044.422709,
    "out:Sewage heat recovery cost": 273216,
    "out:Balancing hydronic system cost": 79599.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 291321.47,
    "out:DH kr savings": 155915.41016905126,
    "out:El kr savings": 32088.845417124598,
    "out:El kr sold": 103317.21819422109,
    "out:El kr saved": 32088.846722,
    "out:El kr return": 135406.06491622108,
    "out:% solar/total": 40,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 271142.681412,
    "out:% savings (space heating)": 33.403441,
    "out:Total_Water Heating (DH)": 112856.846044,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 85.16,
    "out:Etvv": 21.25,
    "out:Ef": 7.53,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 4766836.5739474995,
    "out:Seasonal Variation ROI (%)": 6,
    "out:Seasonal Variation Payback": 16.666667,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 43.558064,
    "out:EL CO2": -108.956638,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -109.952146,
    "out:Bought CO2": 0.995508,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01001000020100000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 1,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 88.927327,
    "out:DHW (DH)": 41.7,
    "out:Property Electricity": 9.976115,
    "out:Electricity (inc PV)": 5.879188,
    "out:Total Energy Use Pre PV": 140.976115,
    "out:Total Energy Use Post PV": 136.879188,
    "out:Primary Energy": 97.248237,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 42.29314,
    "out:CO2 Operational/m2": -66.88446,
    "out:Total CO2/m2": -24.59132,
    "out:Total CO2 (tons)": -78.298739,
    "out:Klimatpaverkan": -278.43416,
    "out:Initial Cost/MSEK": 4.560813470467325,
    "out:Running cost/(Apt SEK y)": 25303.723404,
    "out:Running Cost over RSP/MSEK": 9.32554,
    "out:LCP/MSEK": -0.481633,
    "out:ROI% old": 12.777107,
    "out:Payback discounted": 9,
    "out:Atemp": 3184,
    "out:Umean": 0.47,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 137,
    "out:Energy use kWh/m2": 137,
    "out:Energy savings %": 33.6,
    "out:Op energy cost/MSEK": 69,
    "out:El price/MSEK": 40,
    "out:Heating price/MSEK": 26,
    "out:DH+ DHW/kWh/m2": 131,
    "out:El bought/kWh/m2": 413.65837,
    "out:Return %": 12,
    "out:Return/kSEK/y": 525,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 41.7,
    "out:EAHP (El)": 0,
    "out:PV (kr/kWp)": 17461.48474631725,
    "out:PV (kWp installed)": 90.5097,
    "out:PV (m2 panels)": 474.867263,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 84922.568824,
    "out:PV (kWh sold)": 71877.957046,
    "out:PV (% sold (El))": 84.639405,
    "out:PV (kWh self-consumed)": 13044.611778,
    "out:PV (ratio sold/self-consumed)": 5.510165,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 123997.653916,
    "out:EL kWh savings": 21303.538998,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 274421.67,
    "out:DH kr savings": 123997.65391564941,
    "out:El kr savings": 42607.077996404216,
    "out:El kr sold": 107816.93556907076,
    "out:El kr saved": 26089.223556,
    "out:El kr return": 133906.15912507076,
    "out:% solar/total": 33,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 283144.523657,
    "out:% savings (space heating)": 30.455615,
    "out:Total_Water Heating (DH)": 132772.760051,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 88.93,
    "out:Etvv": 25,
    "out:Ef": 5.88,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 4560813.470467325,
    "out:Seasonal Variation ROI (%)": 6,
    "out:Seasonal Variation Payback": 16.666667,
    "out:New AHU Cost/SEK": 146792.87151982498,
    "out:DH CO2": 47.157904,
    "out:EL CO2": -114.042364,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -114.819396,
    "out:Bought CO2": 0.777032,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01001000020100100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 1,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 85.157903,
    "out:DHW (DH)": 41.7,
    "out:Property Electricity": 9.976115,
    "out:Electricity (inc PV)": 5.879188,
    "out:Total Energy Use Pre PV": 136.976115,
    "out:Total Energy Use Post PV": 132.879188,
    "out:Primary Energy": 94.316463,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 42.29314,
    "out:CO2 Operational/m2": -68.324396,
    "out:Total CO2/m2": -26.031256,
    "out:Total CO2 (tons)": -82.883494,
    "out:Klimatpaverkan": -283.018915,
    "out:Initial Cost/MSEK": 4.640413445467325,
    "out:Running cost/(Apt SEK y)": 24485.382979,
    "out:Running Cost over RSP/MSEK": 9.02469,
    "out:LCP/MSEK": -0.260383,
    "out:ROI% old": 13.484113,
    "out:Payback discounted": 9,
    "out:Atemp": 3184,
    "out:Umean": 0.47,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 133,
    "out:Energy use kWh/m2": 133,
    "out:Energy savings %": 37.6,
    "out:Op energy cost/MSEK": 67,
    "out:El price/MSEK": 38,
    "out:Heating price/MSEK": 25,
    "out:DH+ DHW/kWh/m2": 127,
    "out:El bought/kWh/m2": 413.65837,
    "out:Return %": 12,
    "out:Return/kSEK/y": 564,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 41.7,
    "out:EAHP (El)": 0,
    "out:PV (kr/kWp)": 17461.48474631725,
    "out:PV (kWp installed)": 90.5097,
    "out:PV (m2 panels)": 474.867263,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 84922.568824,
    "out:PV (kWh sold)": 71877.957046,
    "out:PV (% sold (El))": 84.639405,
    "out:PV (kWh self-consumed)": 13044.611778,
    "out:PV (ratio sold/self-consumed)": 5.510165,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 135999.496161,
    "out:EL kWh savings": 21303.538998,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 79599.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 286423.51,
    "out:DH kr savings": 135999.4961613413,
    "out:El kr savings": 42607.077996404216,
    "out:El kr sold": 107816.93556907076,
    "out:El kr saved": 26089.223556,
    "out:El kr return": 133906.15912507076,
    "out:% solar/total": 33,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 271142.681412,
    "out:% savings (space heating)": 33.403441,
    "out:Total_Water Heating (DH)": 132772.760051,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 85.16,
    "out:Etvv": 25,
    "out:Ef": 5.88,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 4640413.445467325,
    "out:Seasonal Variation ROI (%)": 6,
    "out:Seasonal Variation Payback": 16.666667,
    "out:New AHU Cost/SEK": 146792.87151982498,
    "out:DH CO2": 45.717968,
    "out:EL CO2": -114.042364,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -114.819396,
    "out:Bought CO2": 0.777032,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01001000020101000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 1,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 88.927327,
    "out:DHW (DH)": 35.445,
    "out:Property Electricity": 9.976115,
    "out:Electricity (inc PV)": 5.879188,
    "out:Total Energy Use Pre PV": 133.976115,
    "out:Total Energy Use Post PV": 129.879188,
    "out:Primary Energy": 94.623237,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 43.001683,
    "out:CO2 Operational/m2": -69.404348,
    "out:Total CO2/m2": -26.402665,
    "out:Total CO2 (tons)": -84.06606,
    "out:Klimatpaverkan": -284.201481,
    "out:Initial Cost/MSEK": 4.834029470467325,
    "out:Running cost/(Apt SEK y)": 23871.617021,
    "out:Running Cost over RSP/MSEK": 8.79906,
    "out:LCP/MSEK": -0.228369,
    "out:ROI% old": 13.610828,
    "out:Payback discounted": 9,
    "out:Atemp": 3184,
    "out:Umean": 0.47,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 130,
    "out:Energy use kWh/m2": 130,
    "out:Energy savings %": 40.8,
    "out:Op energy cost/MSEK": 66,
    "out:El price/MSEK": 37,
    "out:Heating price/MSEK": 24,
    "out:DH+ DHW/kWh/m2": 124,
    "out:El bought/kWh/m2": 413.65837,
    "out:Return %": 12,
    "out:Return/kSEK/y": 593,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 35.445,
    "out:EAHP (El)": 0,
    "out:PV (kr/kWp)": 17461.48474631725,
    "out:PV (kWp installed)": 90.5097,
    "out:PV (m2 panels)": 474.867263,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 84922.568824,
    "out:PV (kWh sold)": 71877.957046,
    "out:PV (% sold (El))": 84.639405,
    "out:PV (kWh self-consumed)": 13044.611778,
    "out:PV (ratio sold/self-consumed)": 5.510165,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 143913.567923,
    "out:EL kWh savings": 21303.538998,
    "out:Sewage heat recovery cost": 273216,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 294337.58,
    "out:DH kr savings": 143913.5679233594,
    "out:El kr savings": 42607.077996404216,
    "out:El kr sold": 107816.93556907076,
    "out:El kr saved": 26089.223556,
    "out:El kr return": 133906.15912507076,
    "out:% solar/total": 33,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 283144.523657,
    "out:% savings (space heating)": 30.455615,
    "out:Total_Water Heating (DH)": 112856.846044,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 88.93,
    "out:Etvv": 21.25,
    "out:Ef": 5.88,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 4834029.470467324,
    "out:Seasonal Variation ROI (%)": 6,
    "out:Seasonal Variation Payback": 16.666667,
    "out:New AHU Cost/SEK": 146792.87151982498,
    "out:DH CO2": 44.638016,
    "out:EL CO2": -114.042364,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -114.819396,
    "out:Bought CO2": 0.777032,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01001000020101100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 1,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 85.157903,
    "out:DHW (DH)": 35.445,
    "out:Property Electricity": 9.976115,
    "out:Electricity (inc PV)": 5.879188,
    "out:Total Energy Use Pre PV": 130.976115,
    "out:Total Energy Use Post PV": 126.879188,
    "out:Primary Energy": 91.691463,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 43.001683,
    "out:CO2 Operational/m2": -70.4843,
    "out:Total CO2/m2": -27.482617,
    "out:Total CO2 (tons)": -87.504626,
    "out:Klimatpaverkan": -287.640047,
    "out:Initial Cost/MSEK": 4.913629445467325,
    "out:Running cost/(Apt SEK y)": 23257.851064,
    "out:Running Cost over RSP/MSEK": 8.57343,
    "out:LCP/MSEK": -0.082339,
    "out:ROI% old": 14.046323,
    "out:Payback discounted": 9,
    "out:Atemp": 3184,
    "out:Umean": 0.47,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 127,
    "out:Energy use kWh/m2": 127,
    "out:Energy savings %": 44.1,
    "out:Op energy cost/MSEK": 64,
    "out:El price/MSEK": 36,
    "out:Heating price/MSEK": 24,
    "out:DH+ DHW/kWh/m2": 121,
    "out:El bought/kWh/m2": 413.65837,
    "out:Return %": 13,
    "out:Return/kSEK/y": 622,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 35.445,
    "out:EAHP (El)": 0,
    "out:PV (kr/kWp)": 17461.48474631725,
    "out:PV (kWp installed)": 90.5097,
    "out:PV (m2 panels)": 474.867263,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 84922.568824,
    "out:PV (kWh sold)": 71877.957046,
    "out:PV (% sold (El))": 84.639405,
    "out:PV (kWh self-consumed)": 13044.611778,
    "out:PV (ratio sold/self-consumed)": 5.510165,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 155915.410169,
    "out:EL kWh savings": 21303.538998,
    "out:Sewage heat recovery cost": 273216,
    "out:Balancing hydronic system cost": 79599.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 306339.42,
    "out:DH kr savings": 155915.41016905126,
    "out:El kr savings": 42607.077996404216,
    "out:El kr sold": 107816.93556907076,
    "out:El kr saved": 26089.223556,
    "out:El kr return": 133906.15912507076,
    "out:% solar/total": 33,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 271142.681412,
    "out:% savings (space heating)": 33.403441,
    "out:Total_Water Heating (DH)": 112856.846044,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 85.16,
    "out:Etvv": 21.25,
    "out:Ef": 5.88,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 4913629.445467325,
    "out:Seasonal Variation ROI (%)": 6,
    "out:Seasonal Variation Payback": 16.666667,
    "out:New AHU Cost/SEK": 146792.87151982498,
    "out:DH CO2": 43.558064,
    "out:EL CO2": -114.042364,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -114.819396,
    "out:Bought CO2": 0.777032,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01001000100000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 88.927327,
    "out:DHW (DH)": 33.36,
    "out:Property Electricity": 12.57,
    "out:Electricity (inc PV)": 12.57,
    "out:Total Energy Use Pre PV": 134.57,
    "out:Total Energy Use Post PV": 134.57,
    "out:Primary Energy": 105.791699,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 12.630439,
    "out:CO2 Operational/m2": 45.577389,
    "out:Total CO2/m2": 58.207828,
    "out:Total CO2 (tons)": 185.333669,
    "out:Klimatpaverkan": -14.801752,
    "out:Initial Cost/MSEK": 2.9576345405037494,
    "out:Running cost/(Apt SEK y)": 26662.93617,
    "out:Running Cost over RSP/MSEK": 9.79458,
    "out:LCP/MSEK": 0.652505,
    "out:ROI% old": 17.437391,
    "out:Payback discounted": 7,
    "out:Atemp": 3184,
    "out:Umean": 0.47,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 135,
    "out:Energy use kWh/m2": 135,
    "out:Energy savings %": 35.6,
    "out:Op energy cost/MSEK": 72,
    "out:El price/MSEK": 44,
    "out:Heating price/MSEK": 24,
    "out:DH+ DHW/kWh/m2": 122,
    "out:El bought/kWh/m2": 879.9,
    "out:Return %": 16,
    "out:Return/kSEK/y": 462,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 33.36,
    "out:EAHP (El)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 150552.205926,
    "out:EL kWh savings": 2.2624e-11,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 150552.21,
    "out:DH kr savings": 150552.20592592942,
    "out:El kr savings": 4.524734779920436e-11,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 283144.523657,
    "out:% savings (space heating)": 30.455615,
    "out:Total_Water Heating (DH)": 106218.208041,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 88.93,
    "out:Etvv": 20,
    "out:Ef": 12.57,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 2957634.5405037496,
    "out:Seasonal Variation ROI (%)": 5,
    "out:Seasonal Variation Payback": 20,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 43.918048,
    "out:EL CO2": 1.659341,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 1.659341,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01001000100000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 85.157903,
    "out:DHW (DH)": 33.36,
    "out:Property Electricity": 12.57,
    "out:Electricity (inc PV)": 12.57,
    "out:Total Energy Use Pre PV": 131.57,
    "out:Total Energy Use Post PV": 131.57,
    "out:Primary Energy": 102.859925,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 12.630439,
    "out:CO2 Operational/m2": 44.497437,
    "out:Total CO2/m2": 57.127876,
    "out:Total CO2 (tons)": 181.895102,
    "out:Klimatpaverkan": -18.240319,
    "out:Initial Cost/MSEK": 3.0372345155037497,
    "out:Running cost/(Apt SEK y)": 26049.170213,
    "out:Running Cost over RSP/MSEK": 9.56895,
    "out:LCP/MSEK": 0.798535,
    "out:ROI% old": 18.041647,
    "out:Payback discounted": 7,
    "out:Atemp": 3184,
    "out:Umean": 0.47,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 132,
    "out:Energy use kWh/m2": 132,
    "out:Energy savings %": 38.6,
    "out:Op energy cost/MSEK": 70,
    "out:El price/MSEK": 43,
    "out:Heating price/MSEK": 23,
    "out:DH+ DHW/kWh/m2": 119,
    "out:El bought/kWh/m2": 879.9,
    "out:Return %": 16,
    "out:Return/kSEK/y": 490,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 33.36,
    "out:EAHP (El)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 162554.048172,
    "out:EL kWh savings": 2.2624e-11,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 79599.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 162554.05,
    "out:DH kr savings": 162554.04817162128,
    "out:El kr savings": 4.524734779920436e-11,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 271142.681412,
    "out:% savings (space heating)": 33.403441,
    "out:Total_Water Heating (DH)": 106218.208041,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 85.16,
    "out:Etvv": 20,
    "out:Ef": 12.57,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 3037234.5155037497,
    "out:Seasonal Variation ROI (%)": 5,
    "out:Seasonal Variation Payback": 20,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 42.838096,
    "out:EL CO2": 1.659341,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 1.659341,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01001000100001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 88.927327,
    "out:DHW (DH)": 28.356,
    "out:Property Electricity": 12.57,
    "out:Electricity (inc PV)": 12.57,
    "out:Total Energy Use Pre PV": 129.57,
    "out:Total Energy Use Post PV": 129.57,
    "out:Primary Energy": 103.691699,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 13.338982,
    "out:CO2 Operational/m2": 43.777469,
    "out:Total CO2/m2": 57.116451,
    "out:Total CO2 (tons)": 181.858725,
    "out:Klimatpaverkan": -18.276696,
    "out:Initial Cost/MSEK": 3.2308505405037495,
    "out:Running cost/(Apt SEK y)": 25640,
    "out:Running Cost over RSP/MSEK": 9.41853,
    "out:LCP/MSEK": 0.755339,
    "out:ROI% old": 17.625567,
    "out:Payback discounted": 7,
    "out:Atemp": 3184,
    "out:Umean": 0.47,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 130,
    "out:Energy use kWh/m2": 130,
    "out:Energy savings %": 40.8,
    "out:Op energy cost/MSEK": 69,
    "out:El price/MSEK": 42,
    "out:Heating price/MSEK": 23,
    "out:DH+ DHW/kWh/m2": 117,
    "out:El bought/kWh/m2": 879.9,
    "out:Return %": 16,
    "out:Return/kSEK/y": 510,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 28.356,
    "out:EAHP (El)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 166484.937132,
    "out:EL kWh savings": 2.2624e-11,
    "out:Sewage heat recovery cost": 273216,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 166484.94,
    "out:DH kr savings": 166484.9371320974,
    "out:El kr savings": 4.524734779920436e-11,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 283144.523657,
    "out:% savings (space heating)": 30.455615,
    "out:Total_Water Heating (DH)": 90285.476835,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 88.93,
    "out:Etvv": 17,
    "out:Ef": 12.57,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 3230850.5405037496,
    "out:Seasonal Variation ROI (%)": 5,
    "out:Seasonal Variation Payback": 20,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 42.118128,
    "out:EL CO2": 1.659341,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 1.659341,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01001000100001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 85.157903,
    "out:DHW (DH)": 28.356,
    "out:Property Electricity": 12.57,
    "out:Electricity (inc PV)": 12.57,
    "out:Total Energy Use Pre PV": 126.57,
    "out:Total Energy Use Post PV": 126.57,
    "out:Primary Energy": 100.759925,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 13.338982,
    "out:CO2 Operational/m2": 42.697517,
    "out:Total CO2/m2": 56.036499,
    "out:Total CO2 (tons)": 178.420159,
    "out:Klimatpaverkan": -21.715262,
    "out:Initial Cost/MSEK": 3.3104505155037494,
    "out:Running cost/(Apt SEK y)": 25026.234043,
    "out:Running Cost over RSP/MSEK": 9.1929,
    "out:LCP/MSEK": 0.901369,
    "out:ROI% old": 18.175429,
    "out:Payback discounted": 7,
    "out:Atemp": 3184,
    "out:Umean": 0.47,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 127,
    "out:Energy use kWh/m2": 127,
    "out:Energy savings %": 44.1,
    "out:Op energy cost/MSEK": 67,
    "out:El price/MSEK": 41,
    "out:Heating price/MSEK": 22,
    "out:DH+ DHW/kWh/m2": 114,
    "out:El bought/kWh/m2": 879.9,
    "out:Return %": 16,
    "out:Return/kSEK/y": 538,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 28.356,
    "out:EAHP (El)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 178486.779378,
    "out:EL kWh savings": 2.2624e-11,
    "out:Sewage heat recovery cost": 273216,
    "out:Balancing hydronic system cost": 79599.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 178486.78,
    "out:DH kr savings": 178486.77937778927,
    "out:El kr savings": 4.524734779920436e-11,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 271142.681412,
    "out:% savings (space heating)": 33.403441,
    "out:Total_Water Heating (DH)": 90285.476835,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 85.16,
    "out:Etvv": 17,
    "out:Ef": 12.57,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 3310450.5155037493,
    "out:Seasonal Variation ROI (%)": 5,
    "out:Seasonal Variation Payback": 20,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 41.038176,
    "out:EL CO2": 1.659341,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 1.659341,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01001000100100000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 1,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 88.927327,
    "out:DHW (DH)": 33.36,
    "out:Property Electricity": 9.976115,
    "out:Electricity (inc PV)": 9.976115,
    "out:Total Energy Use Pre PV": 131.976115,
    "out:Total Energy Use Post PV": 131.976115,
    "out:Primary Energy": 101.122706,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 12.814941,
    "out:CO2 Operational/m2": 45.234975,
    "out:Total CO2/m2": 58.049916,
    "out:Total CO2 (tons)": 184.830877,
    "out:Klimatpaverkan": -15.304544,
    "out:Initial Cost/MSEK": 3.1044274120235746,
    "out:Running cost/(Apt SEK y)": 26311.489362,
    "out:Running Cost over RSP/MSEK": 9.66689,
    "out:LCP/MSEK": 0.633403,
    "out:ROI% old": 17.200457,
    "out:Payback discounted": 7,
    "out:Atemp": 3184,
    "out:Umean": 0.47,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 132,
    "out:Energy use kWh/m2": 132,
    "out:Energy savings %": 38.6,
    "out:Op energy cost/MSEK": 71,
    "out:El price/MSEK": 43,
    "out:Heating price/MSEK": 24,
    "out:DH+ DHW/kWh/m2": 122,
    "out:El bought/kWh/m2": 698.32805,
    "out:Return %": 15,
    "out:Return/kSEK/y": 478,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 33.36,
    "out:EAHP (El)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 150552.205926,
    "out:EL kWh savings": 8258.927355,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 167070.06,
    "out:DH kr savings": 150552.20592592942,
    "out:El kr savings": 16517.854710116386,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 283144.523657,
    "out:% savings (space heating)": 30.455615,
    "out:Total_Water Heating (DH)": 106218.208041,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 88.93,
    "out:Etvv": 20,
    "out:Ef": 9.98,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 3104427.4120235746,
    "out:Seasonal Variation ROI (%)": 5,
    "out:Seasonal Variation Payback": 20,
    "out:New AHU Cost/SEK": 146792.87151982498,
    "out:DH CO2": 43.918048,
    "out:EL CO2": 1.316927,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 1.316927,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01001000100100100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 1,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 85.157903,
    "out:DHW (DH)": 33.36,
    "out:Property Electricity": 9.976115,
    "out:Electricity (inc PV)": 9.976115,
    "out:Total Energy Use Pre PV": 128.976115,
    "out:Total Energy Use Post PV": 128.976115,
    "out:Primary Energy": 98.190932,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 12.814941,
    "out:CO2 Operational/m2": 44.155023,
    "out:Total CO2/m2": 56.969964,
    "out:Total CO2 (tons)": 181.392311,
    "out:Klimatpaverkan": -18.74311,
    "out:Initial Cost/MSEK": 3.1840273870235745,
    "out:Running cost/(Apt SEK y)": 25697.723404,
    "out:Running Cost over RSP/MSEK": 9.44125,
    "out:LCP/MSEK": 0.779443,
    "out:ROI% old": 17.782824,
    "out:Payback discounted": 7,
    "out:Atemp": 3184,
    "out:Umean": 0.47,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 129,
    "out:Energy use kWh/m2": 129,
    "out:Energy savings %": 41.9,
    "out:Op energy cost/MSEK": 69,
    "out:El price/MSEK": 42,
    "out:Heating price/MSEK": 23,
    "out:DH+ DHW/kWh/m2": 119,
    "out:El bought/kWh/m2": 698.32805,
    "out:Return %": 16,
    "out:Return/kSEK/y": 507,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 33.36,
    "out:EAHP (El)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 162554.048172,
    "out:EL kWh savings": 8258.927355,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 79599.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 179071.9,
    "out:DH kr savings": 162554.04817162128,
    "out:El kr savings": 16517.854710116386,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 271142.681412,
    "out:% savings (space heating)": 33.403441,
    "out:Total_Water Heating (DH)": 106218.208041,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 85.16,
    "out:Etvv": 20,
    "out:Ef": 9.98,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 3184027.3870235747,
    "out:Seasonal Variation ROI (%)": 5,
    "out:Seasonal Variation Payback": 20,
    "out:New AHU Cost/SEK": 146792.87151982498,
    "out:DH CO2": 42.838096,
    "out:EL CO2": 1.316927,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 1.316927,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01001000100101000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 1,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 88.927327,
    "out:DHW (DH)": 28.356,
    "out:Property Electricity": 9.976115,
    "out:Electricity (inc PV)": 9.976115,
    "out:Total Energy Use Pre PV": 126.976115,
    "out:Total Energy Use Post PV": 126.976115,
    "out:Primary Energy": 99.022706,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 13.523484,
    "out:CO2 Operational/m2": 43.435055,
    "out:Total CO2/m2": 56.958539,
    "out:Total CO2 (tons)": 181.355934,
    "out:Klimatpaverkan": -18.779487,
    "out:Initial Cost/MSEK": 3.3776434120235743,
    "out:Running cost/(Apt SEK y)": 25288.553191,
    "out:Running Cost over RSP/MSEK": 9.29083,
    "out:LCP/MSEK": 0.736247,
    "out:ROI% old": 17.399663,
    "out:Payback discounted": 7,
    "out:Atemp": 3184,
    "out:Umean": 0.47,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 127,
    "out:Energy use kWh/m2": 127,
    "out:Energy savings %": 44.1,
    "out:Op energy cost/MSEK": 68,
    "out:El price/MSEK": 41,
    "out:Heating price/MSEK": 23,
    "out:DH+ DHW/kWh/m2": 117,
    "out:El bought/kWh/m2": 698.32805,
    "out:Return %": 16,
    "out:Return/kSEK/y": 526,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 28.356,
    "out:EAHP (El)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 166484.937132,
    "out:EL kWh savings": 8258.927355,
    "out:Sewage heat recovery cost": 273216,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 183002.79,
    "out:DH kr savings": 166484.9371320974,
    "out:El kr savings": 16517.854710116386,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 283144.523657,
    "out:% savings (space heating)": 30.455615,
    "out:Total_Water Heating (DH)": 90285.476835,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 88.93,
    "out:Etvv": 17,
    "out:Ef": 9.98,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 3377643.412023574,
    "out:Seasonal Variation ROI (%)": 5,
    "out:Seasonal Variation Payback": 20,
    "out:New AHU Cost/SEK": 146792.87151982498,
    "out:DH CO2": 42.118128,
    "out:EL CO2": 1.316927,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 1.316927,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01001000100101100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 1,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 85.157903,
    "out:DHW (DH)": 28.356,
    "out:Property Electricity": 9.976115,
    "out:Electricity (inc PV)": 9.976115,
    "out:Total Energy Use Pre PV": 123.976115,
    "out:Total Energy Use Post PV": 123.976115,
    "out:Primary Energy": 96.090932,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 13.523484,
    "out:CO2 Operational/m2": 42.355103,
    "out:Total CO2/m2": 55.878587,
    "out:Total CO2 (tons)": 177.917367,
    "out:Klimatpaverkan": -22.218054,
    "out:Initial Cost/MSEK": 3.457243387023574,
    "out:Running cost/(Apt SEK y)": 24674.787234,
    "out:Running Cost over RSP/MSEK": 9.0652,
    "out:LCP/MSEK": 0.882277,
    "out:ROI% old": 17.931379,
    "out:Payback discounted": 7,
    "out:Atemp": 3184,
    "out:Umean": 0.47,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 124,
    "out:Energy use kWh/m2": 124,
    "out:Energy savings %": 47.6,
    "out:Op energy cost/MSEK": 66,
    "out:El price/MSEK": 40,
    "out:Heating price/MSEK": 22,
    "out:DH+ DHW/kWh/m2": 114,
    "out:El bought/kWh/m2": 698.32805,
    "out:Return %": 16,
    "out:Return/kSEK/y": 555,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 28.356,
    "out:EAHP (El)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 178486.779378,
    "out:EL kWh savings": 8258.927355,
    "out:Sewage heat recovery cost": 273216,
    "out:Balancing hydronic system cost": 79599.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 195004.63,
    "out:DH kr savings": 178486.77937778927,
    "out:El kr savings": 16517.854710116386,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 271142.681412,
    "out:% savings (space heating)": 33.403441,
    "out:Total_Water Heating (DH)": 90285.476835,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 85.16,
    "out:Etvv": 17,
    "out:Ef": 9.98,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 3457243.387023574,
    "out:Seasonal Variation ROI (%)": 5,
    "out:Seasonal Variation Payback": 20,
    "out:New AHU Cost/SEK": 146792.87151982498,
    "out:DH CO2": 41.038176,
    "out:EL CO2": 1.316927,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 1.316927,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01001000110000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 88.927327,
    "out:DHW (DH)": 33.36,
    "out:Property Electricity": 12.57,
    "out:Electricity (inc PV)": 8.051517,
    "out:Total Energy Use Pre PV": 134.57,
    "out:Total Energy Use Post PV": 130.051517,
    "out:Primary Energy": 97.65843,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 27.369539,
    "out:CO2 Operational/m2": 0.316727,
    "out:Total CO2/m2": 27.686266,
    "out:Total CO2 (tons)": 88.153044,
    "out:Klimatpaverkan": -111.982377,
    "out:Initial Cost/MSEK": 3.74785493472625,
    "out:Running cost/(Apt SEK y)": 25154.744681,
    "out:Running Cost over RSP/MSEK": 9.25521,
    "out:LCP/MSEK": 0.401655,
    "out:ROI% old": 15.816705,
    "out:Payback discounted": 8,
    "out:Atemp": 3184,
    "out:Umean": 0.47,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 130,
    "out:Energy use kWh/m2": 130,
    "out:Energy savings %": 40.8,
    "out:Op energy cost/MSEK": 68,
    "out:El price/MSEK": 40,
    "out:Heating price/MSEK": 24,
    "out:DH+ DHW/kWh/m2": 122,
    "out:El bought/kWh/m2": 568.258452,
    "out:Return %": 14,
    "out:Return/kSEK/y": 532,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 33.36,
    "out:EAHP (El)": 0,
    "out:PV (kr/kWp)": 17461.56255567083,
    "out:PV (kWp installed)": 45.25485,
    "out:PV (m2 panels)": 237.433632,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 42461.284501,
    "out:PV (kWh sold)": 28074.439514,
    "out:PV (% sold (El))": 66.117735,
    "out:PV (kWh self-consumed)": 14386.844987,
    "out:PV (ratio sold/self-consumed)": 1.951397,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 150552.205926,
    "out:EL kWh savings": 14386.845543,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 221437.56,
    "out:DH kr savings": 150552.20592592942,
    "out:El kr savings": 28773.691086586638,
    "out:El kr sold": 42111.65927092506,
    "out:El kr saved": 28773.689974,
    "out:El kr return": 70885.34924492506,
    "out:% solar/total": 36,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 283144.523657,
    "out:% savings (space heating)": 30.455615,
    "out:Total_Water Heating (DH)": 106218.208041,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 88.93,
    "out:Etvv": 20,
    "out:Ef": 8.05,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 3747854.93472625,
    "out:Seasonal Variation ROI (%)": 6,
    "out:Seasonal Variation Payback": 16.666667,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 43.918048,
    "out:EL CO2": -43.601321,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -44.666212,
    "out:Bought CO2": 1.064891,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01001000110000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 85.157903,
    "out:DHW (DH)": 33.36,
    "out:Property Electricity": 12.57,
    "out:Electricity (inc PV)": 8.051517,
    "out:Total Energy Use Pre PV": 131.57,
    "out:Total Energy Use Post PV": 127.051517,
    "out:Primary Energy": 94.726656,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 27.369539,
    "out:CO2 Operational/m2": -0.763225,
    "out:Total CO2/m2": 26.606314,
    "out:Total CO2 (tons)": 84.714478,
    "out:Klimatpaverkan": -115.420943,
    "out:Initial Cost/MSEK": 3.82745490972625,
    "out:Running cost/(Apt SEK y)": 24540.978723,
    "out:Running Cost over RSP/MSEK": 9.02958,
    "out:LCP/MSEK": 0.547685,
    "out:ROI% old": 16.329912,
    "out:Payback discounted": 7,
    "out:Atemp": 3184,
    "out:Umean": 0.47,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 127,
    "out:Energy use kWh/m2": 127,
    "out:Energy savings %": 44.1,
    "out:Op energy cost/MSEK": 67,
    "out:El price/MSEK": 40,
    "out:Heating price/MSEK": 23,
    "out:DH+ DHW/kWh/m2": 119,
    "out:El bought/kWh/m2": 568.258452,
    "out:Return %": 15,
    "out:Return/kSEK/y": 561,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 33.36,
    "out:EAHP (El)": 0,
    "out:PV (kr/kWp)": 17461.56255567083,
    "out:PV (kWp installed)": 45.25485,
    "out:PV (m2 panels)": 237.433632,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 42461.284501,
    "out:PV (kWh sold)": 28074.439514,
    "out:PV (% sold (El))": 66.117735,
    "out:PV (kWh self-consumed)": 14386.844987,
    "out:PV (ratio sold/self-consumed)": 1.951397,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 162554.048172,
    "out:EL kWh savings": 14386.845543,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 79599.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 233439.4,
    "out:DH kr savings": 162554.04817162128,
    "out:El kr savings": 28773.691086586456,
    "out:El kr sold": 42111.65927092506,
    "out:El kr saved": 28773.689974,
    "out:El kr return": 70885.34924492506,
    "out:% solar/total": 36,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 271142.681412,
    "out:% savings (space heating)": 33.403441,
    "out:Total_Water Heating (DH)": 106218.208041,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 85.16,
    "out:Etvv": 20,
    "out:Ef": 8.05,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 3827454.90972625,
    "out:Seasonal Variation ROI (%)": 6,
    "out:Seasonal Variation Payback": 16.666667,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 42.838096,
    "out:EL CO2": -43.601321,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -44.666212,
    "out:Bought CO2": 1.064891,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01001000110001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 88.927327,
    "out:DHW (DH)": 28.356,
    "out:Property Electricity": 12.57,
    "out:Electricity (inc PV)": 8.051517,
    "out:Total Energy Use Pre PV": 129.57,
    "out:Total Energy Use Post PV": 125.051517,
    "out:Primary Energy": 95.55843,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 28.078082,
    "out:CO2 Operational/m2": -1.483193,
    "out:Total CO2/m2": 26.594889,
    "out:Total CO2 (tons)": 84.678101,
    "out:Klimatpaverkan": -115.45732,
    "out:Initial Cost/MSEK": 4.021070934726249,
    "out:Running cost/(Apt SEK y)": 24131.808511,
    "out:Running Cost over RSP/MSEK": 8.87916,
    "out:LCP/MSEK": 0.504489,
    "out:ROI% old": 16.07802,
    "out:Payback discounted": 8,
    "out:Atemp": 3184,
    "out:Umean": 0.47,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 125,
    "out:Energy use kWh/m2": 125,
    "out:Energy savings %": 46.4,
    "out:Op energy cost/MSEK": 65,
    "out:El price/MSEK": 39,
    "out:Heating price/MSEK": 23,
    "out:DH+ DHW/kWh/m2": 117,
    "out:El bought/kWh/m2": 568.258452,
    "out:Return %": 14,
    "out:Return/kSEK/y": 581,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 28.356,
    "out:EAHP (El)": 0,
    "out:PV (kr/kWp)": 17461.56255567083,
    "out:PV (kWp installed)": 45.25485,
    "out:PV (m2 panels)": 237.433632,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 42461.284501,
    "out:PV (kWh sold)": 28074.439514,
    "out:PV (% sold (El))": 66.117735,
    "out:PV (kWh self-consumed)": 14386.844987,
    "out:PV (ratio sold/self-consumed)": 1.951397,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 166484.937132,
    "out:EL kWh savings": 14386.845543,
    "out:Sewage heat recovery cost": 273216,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 237370.29,
    "out:DH kr savings": 166484.9371320974,
    "out:El kr savings": 28773.691086586456,
    "out:El kr sold": 42111.65927092506,
    "out:El kr saved": 28773.689974,
    "out:El kr return": 70885.34924492506,
    "out:% solar/total": 36,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 283144.523657,
    "out:% savings (space heating)": 30.455615,
    "out:Total_Water Heating (DH)": 90285.476835,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 88.93,
    "out:Etvv": 17,
    "out:Ef": 8.05,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 4021070.9347262494,
    "out:Seasonal Variation ROI (%)": 6,
    "out:Seasonal Variation Payback": 16.666667,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 42.118128,
    "out:EL CO2": -43.601321,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -44.666212,
    "out:Bought CO2": 1.064891,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01001000110001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 85.157903,
    "out:DHW (DH)": 28.356,
    "out:Property Electricity": 12.57,
    "out:Electricity (inc PV)": 8.051517,
    "out:Total Energy Use Pre PV": 126.57,
    "out:Total Energy Use Post PV": 122.051517,
    "out:Primary Energy": 92.626656,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 28.078082,
    "out:CO2 Operational/m2": -2.563145,
    "out:Total CO2/m2": 25.514937,
    "out:Total CO2 (tons)": 81.239535,
    "out:Klimatpaverkan": -118.895886,
    "out:Initial Cost/MSEK": 4.10067090972625,
    "out:Running cost/(Apt SEK y)": 23518.042553,
    "out:Running Cost over RSP/MSEK": 8.65353,
    "out:LCP/MSEK": 0.650519,
    "out:ROI% old": 16.55196,
    "out:Payback discounted": 7,
    "out:Atemp": 3184,
    "out:Umean": 0.47,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 122,
    "out:Energy use kWh/m2": 122,
    "out:Energy savings %": 50,
    "out:Op energy cost/MSEK": 64,
    "out:El price/MSEK": 38,
    "out:Heating price/MSEK": 22,
    "out:DH+ DHW/kWh/m2": 114,
    "out:El bought/kWh/m2": 568.258452,
    "out:Return %": 15,
    "out:Return/kSEK/y": 609,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 28.356,
    "out:EAHP (El)": 0,
    "out:PV (kr/kWp)": 17461.56255567083,
    "out:PV (kWp installed)": 45.25485,
    "out:PV (m2 panels)": 237.433632,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 42461.284501,
    "out:PV (kWh sold)": 28074.439514,
    "out:PV (% sold (El))": 66.117735,
    "out:PV (kWh self-consumed)": 14386.844987,
    "out:PV (ratio sold/self-consumed)": 1.951397,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 178486.779378,
    "out:EL kWh savings": 14386.845543,
    "out:Sewage heat recovery cost": 273216,
    "out:Balancing hydronic system cost": 79599.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 249372.13,
    "out:DH kr savings": 178486.77937778927,
    "out:El kr savings": 28773.691086586456,
    "out:El kr sold": 42111.65927092506,
    "out:El kr saved": 28773.689974,
    "out:El kr return": 70885.34924492506,
    "out:% solar/total": 36,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 271142.681412,
    "out:% savings (space heating)": 33.403441,
    "out:Total_Water Heating (DH)": 90285.476835,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 85.16,
    "out:Etvv": 17,
    "out:Ef": 8.05,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 4100670.90972625,
    "out:Seasonal Variation ROI (%)": 6,
    "out:Seasonal Variation Payback": 16.666667,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 41.038176,
    "out:EL CO2": -43.601321,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -44.666212,
    "out:Bought CO2": 1.064891,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01001000110100000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 1,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 88.927327,
    "out:DHW (DH)": 33.36,
    "out:Property Electricity": 9.976115,
    "out:Electricity (inc PV)": 6.231337,
    "out:Total Energy Use Pre PV": 131.976115,
    "out:Total Energy Use Post PV": 128.231337,
    "out:Primary Energy": 94.382106,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 27.554041,
    "out:CO2 Operational/m2": -3.913401,
    "out:Total CO2/m2": 23.64064,
    "out:Total CO2 (tons)": 75.271775,
    "out:Klimatpaverkan": -124.863646,
    "out:Initial Cost/MSEK": 3.8946478062460748,
    "out:Running cost/(Apt SEK y)": 24829.510638,
    "out:Running Cost over RSP/MSEK": 9.13699,
    "out:LCP/MSEK": 0.373082,
    "out:ROI% old": 15.654194,
    "out:Payback discounted": 8,
    "out:Atemp": 3184,
    "out:Umean": 0.47,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 128,
    "out:Energy use kWh/m2": 128,
    "out:Energy savings %": 43,
    "out:Op energy cost/MSEK": 67,
    "out:El price/MSEK": 40,
    "out:Heating price/MSEK": 24,
    "out:DH+ DHW/kWh/m2": 122,
    "out:El bought/kWh/m2": 439.497378,
    "out:Return %": 14,
    "out:Return/kSEK/y": 548,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 33.36,
    "out:EAHP (El)": 0,
    "out:PV (kr/kWp)": 17461.56255567083,
    "out:PV (kWp installed)": 45.25485,
    "out:PV (m2 panels)": 237.433632,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 42461.284501,
    "out:PV (kWh sold)": 30537.914073,
    "out:PV (% sold (El))": 71.919431,
    "out:PV (kWh self-consumed)": 11923.370428,
    "out:PV (ratio sold/self-consumed)": 2.561181,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 150552.205926,
    "out:EL kWh savings": 20182.29692,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 236723.67,
    "out:DH kr savings": 150552.20592592942,
    "out:El kr savings": 40364.59383912171,
    "out:El kr sold": 45806.87111012268,
    "out:El kr saved": 23846.740856,
    "out:El kr return": 69653.61196612268,
    "out:% solar/total": 30,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 283144.523657,
    "out:% savings (space heating)": 30.455615,
    "out:Total_Water Heating (DH)": 106218.208041,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 88.93,
    "out:Etvv": 20,
    "out:Ef": 6.23,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 3894647.806246075,
    "out:Seasonal Variation ROI (%)": 6,
    "out:Seasonal Variation Payback": 16.666667,
    "out:New AHU Cost/SEK": 146792.87151982498,
    "out:DH CO2": 43.918048,
    "out:EL CO2": -47.831449,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -48.655512,
    "out:Bought CO2": 0.824063,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01001000110100100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 1,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 85.157903,
    "out:DHW (DH)": 33.36,
    "out:Property Electricity": 9.976115,
    "out:Electricity (inc PV)": 6.231337,
    "out:Total Energy Use Pre PV": 128.976115,
    "out:Total Energy Use Post PV": 125.231337,
    "out:Primary Energy": 91.450332,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 27.554041,
    "out:CO2 Operational/m2": -4.993353,
    "out:Total CO2/m2": 22.560688,
    "out:Total CO2 (tons)": 71.833209,
    "out:Klimatpaverkan": -128.302212,
    "out:Initial Cost/MSEK": 3.9742477812460746,
    "out:Running cost/(Apt SEK y)": 24215.744681,
    "out:Running Cost over RSP/MSEK": 8.91135,
    "out:LCP/MSEK": 0.519122,
    "out:ROI% old": 16.151736,
    "out:Payback discounted": 8,
    "out:Atemp": 3184,
    "out:Umean": 0.47,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 125,
    "out:Energy use kWh/m2": 125,
    "out:Energy savings %": 46.4,
    "out:Op energy cost/MSEK": 66,
    "out:El price/MSEK": 39,
    "out:Heating price/MSEK": 23,
    "out:DH+ DHW/kWh/m2": 119,
    "out:El bought/kWh/m2": 439.497378,
    "out:Return %": 15,
    "out:Return/kSEK/y": 577,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 33.36,
    "out:EAHP (El)": 0,
    "out:PV (kr/kWp)": 17461.56255567083,
    "out:PV (kWp installed)": 45.25485,
    "out:PV (m2 panels)": 237.433632,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 42461.284501,
    "out:PV (kWh sold)": 30537.914073,
    "out:PV (% sold (El))": 71.919431,
    "out:PV (kWh self-consumed)": 11923.370428,
    "out:PV (ratio sold/self-consumed)": 2.561181,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 162554.048172,
    "out:EL kWh savings": 20182.29692,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 79599.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 248725.51,
    "out:DH kr savings": 162554.04817162128,
    "out:El kr savings": 40364.59383912171,
    "out:El kr sold": 45806.87111012268,
    "out:El kr saved": 23846.740856,
    "out:El kr return": 69653.61196612268,
    "out:% solar/total": 30,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 271142.681412,
    "out:% savings (space heating)": 33.403441,
    "out:Total_Water Heating (DH)": 106218.208041,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 85.16,
    "out:Etvv": 20,
    "out:Ef": 6.23,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 3974247.7812460745,
    "out:Seasonal Variation ROI (%)": 6,
    "out:Seasonal Variation Payback": 16.666667,
    "out:New AHU Cost/SEK": 146792.87151982498,
    "out:DH CO2": 42.838096,
    "out:EL CO2": -47.831449,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -48.655512,
    "out:Bought CO2": 0.824063,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01001000110101000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 1,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 88.927327,
    "out:DHW (DH)": 28.356,
    "out:Property Electricity": 9.976115,
    "out:Electricity (inc PV)": 6.231337,
    "out:Total Energy Use Pre PV": 126.976115,
    "out:Total Energy Use Post PV": 123.231337,
    "out:Primary Energy": 92.282106,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 28.262584,
    "out:CO2 Operational/m2": -5.713321,
    "out:Total CO2/m2": 22.549263,
    "out:Total CO2 (tons)": 71.796832,
    "out:Klimatpaverkan": -128.338589,
    "out:Initial Cost/MSEK": 4.167863806246074,
    "out:Running cost/(Apt SEK y)": 23806.553191,
    "out:Running Cost over RSP/MSEK": 8.76093,
    "out:LCP/MSEK": 0.475926,
    "out:ROI% old": 15.916993,
    "out:Payback discounted": 8,
    "out:Atemp": 3184,
    "out:Umean": 0.47,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 123,
    "out:Energy use kWh/m2": 123,
    "out:Energy savings %": 48.8,
    "out:Op energy cost/MSEK": 65,
    "out:El price/MSEK": 38,
    "out:Heating price/MSEK": 23,
    "out:DH+ DHW/kWh/m2": 117,
    "out:El bought/kWh/m2": 439.497378,
    "out:Return %": 14,
    "out:Return/kSEK/y": 596,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 28.356,
    "out:EAHP (El)": 0,
    "out:PV (kr/kWp)": 17461.56255567083,
    "out:PV (kWp installed)": 45.25485,
    "out:PV (m2 panels)": 237.433632,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 42461.284501,
    "out:PV (kWh sold)": 30537.914073,
    "out:PV (% sold (El))": 71.919431,
    "out:PV (kWh self-consumed)": 11923.370428,
    "out:PV (ratio sold/self-consumed)": 2.561181,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 166484.937132,
    "out:EL kWh savings": 20182.29692,
    "out:Sewage heat recovery cost": 273216,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 252656.4,
    "out:DH kr savings": 166484.9371320974,
    "out:El kr savings": 40364.59383912171,
    "out:El kr sold": 45806.87111012268,
    "out:El kr saved": 23846.740856,
    "out:El kr return": 69653.61196612268,
    "out:% solar/total": 30,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 283144.523657,
    "out:% savings (space heating)": 30.455615,
    "out:Total_Water Heating (DH)": 90285.476835,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 88.93,
    "out:Etvv": 17,
    "out:Ef": 6.23,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 4167863.806246074,
    "out:Seasonal Variation ROI (%)": 6,
    "out:Seasonal Variation Payback": 16.666667,
    "out:New AHU Cost/SEK": 146792.87151982498,
    "out:DH CO2": 42.118128,
    "out:EL CO2": -47.831449,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -48.655512,
    "out:Bought CO2": 0.824063,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01001000110101100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 1,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 85.157903,
    "out:DHW (DH)": 28.356,
    "out:Property Electricity": 9.976115,
    "out:Electricity (inc PV)": 6.231337,
    "out:Total Energy Use Pre PV": 123.976115,
    "out:Total Energy Use Post PV": 120.231337,
    "out:Primary Energy": 89.350332,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 28.262584,
    "out:CO2 Operational/m2": -6.793273,
    "out:Total CO2/m2": 21.469311,
    "out:Total CO2 (tons)": 68.358266,
    "out:Klimatpaverkan": -131.777155,
    "out:Initial Cost/MSEK": 4.247463781246075,
    "out:Running cost/(Apt SEK y)": 23192.787234,
    "out:Running Cost over RSP/MSEK": 8.5353,
    "out:LCP/MSEK": 0.621956,
    "out:ROI% old": 16.377572,
    "out:Payback discounted": 7,
    "out:Atemp": 3184,
    "out:Umean": 0.47,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 120,
    "out:Energy use kWh/m2": 120,
    "out:Energy savings %": 52.5,
    "out:Op energy cost/MSEK": 63,
    "out:El price/MSEK": 37,
    "out:Heating price/MSEK": 22,
    "out:DH+ DHW/kWh/m2": 114,
    "out:El bought/kWh/m2": 439.497378,
    "out:Return %": 15,
    "out:Return/kSEK/y": 625,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 28.356,
    "out:EAHP (El)": 0,
    "out:PV (kr/kWp)": 17461.56255567083,
    "out:PV (kWp installed)": 45.25485,
    "out:PV (m2 panels)": 237.433632,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 42461.284501,
    "out:PV (kWh sold)": 30537.914073,
    "out:PV (% sold (El))": 71.919431,
    "out:PV (kWh self-consumed)": 11923.370428,
    "out:PV (ratio sold/self-consumed)": 2.561181,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 178486.779378,
    "out:EL kWh savings": 20182.29692,
    "out:Sewage heat recovery cost": 273216,
    "out:Balancing hydronic system cost": 79599.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 264658.24,
    "out:DH kr savings": 178486.77937778927,
    "out:El kr savings": 40364.59383912171,
    "out:El kr sold": 45806.87111012268,
    "out:El kr saved": 23846.740856,
    "out:El kr return": 69653.61196612268,
    "out:% solar/total": 30,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 271142.681412,
    "out:% savings (space heating)": 33.403441,
    "out:Total_Water Heating (DH)": 90285.476835,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 85.16,
    "out:Etvv": 17,
    "out:Ef": 6.23,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 4247463.7812460745,
    "out:Seasonal Variation ROI (%)": 6,
    "out:Seasonal Variation Payback": 16.666667,
    "out:New AHU Cost/SEK": 146792.87151982498,
    "out:DH CO2": 41.038176,
    "out:EL CO2": -47.831449,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -48.655512,
    "out:Bought CO2": 0.824063,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01001000120000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 88.927327,
    "out:DHW (DH)": 33.36,
    "out:Property Electricity": 12.57,
    "out:Electricity (inc PV)": 7.530921,
    "out:Total Energy Use Pre PV": 134.57,
    "out:Total Energy Use Post PV": 129.530921,
    "out:Primary Energy": 96.721357,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 42.108638,
    "out:CO2 Operational/m2": -65.03859,
    "out:Total CO2/m2": -22.929952,
    "out:Total CO2 (tons)": -73.008945,
    "out:Klimatpaverkan": -273.144366,
    "out:Initial Cost/MSEK": 4.5380682864474995,
    "out:Running cost/(Apt SEK y)": 23781.957447,
    "out:Running Cost over RSP/MSEK": 8.76478,
    "out:LCP/MSEK": 0.101872,
    "out:ROI% old": 14.606404,
    "out:Payback discounted": 8,
    "out:Atemp": 3184,
    "out:Umean": 0.47,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 130,
    "out:Energy use kWh/m2": 130,
    "out:Energy savings %": 40.8,
    "out:Op energy cost/MSEK": 65,
    "out:El price/MSEK": 38,
    "out:Heating price/MSEK": 24,
    "out:DH+ DHW/kWh/m2": 122,
    "out:El bought/kWh/m2": 530.25352,
    "out:Return %": 13,
    "out:Return/kSEK/y": 597,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 33.36,
    "out:EAHP (El)": 0,
    "out:PV (kr/kWp)": 17461.48474631725,
    "out:PV (kWp installed)": 90.5097,
    "out:PV (m2 panels)": 474.867263,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 84922.568824,
    "out:PV (kWh sold)": 68878.145463,
    "out:PV (% sold (El))": 81.106997,
    "out:PV (kWh self-consumed)": 16044.423361,
    "out:PV (ratio sold/self-consumed)": 4.292965,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 150552.205926,
    "out:EL kWh savings": 16044.422709,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 285958.27,
    "out:DH kr savings": 150552.20592592942,
    "out:El kr savings": 32088.845417124598,
    "out:El kr sold": 103317.21819422109,
    "out:El kr saved": 32088.846722,
    "out:El kr return": 135406.06491622108,
    "out:% solar/total": 40,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 283144.523657,
    "out:% savings (space heating)": 30.455615,
    "out:Total_Water Heating (DH)": 106218.208041,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 88.93,
    "out:Etvv": 20,
    "out:Ef": 7.53,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 4538068.2864475,
    "out:Seasonal Variation ROI (%)": 6,
    "out:Seasonal Variation Payback": 16.666667,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 43.918048,
    "out:EL CO2": -108.956638,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -109.952146,
    "out:Bought CO2": 0.995508,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01001000120000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 85.157903,
    "out:DHW (DH)": 33.36,
    "out:Property Electricity": 12.57,
    "out:Electricity (inc PV)": 7.530921,
    "out:Total Energy Use Pre PV": 131.57,
    "out:Total Energy Use Post PV": 126.530921,
    "out:Primary Energy": 93.789583,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 42.108638,
    "out:CO2 Operational/m2": -66.118542,
    "out:Total CO2/m2": -24.009904,
    "out:Total CO2 (tons)": -76.447511,
    "out:Klimatpaverkan": -276.582932,
    "out:Initial Cost/MSEK": 4.6176682614475,
    "out:Running cost/(Apt SEK y)": 23168.191489,
    "out:Running Cost over RSP/MSEK": 8.53915,
    "out:LCP/MSEK": 0.247902,
    "out:ROI% old": 15.05265,
    "out:Payback discounted": 8,
    "out:Atemp": 3184,
    "out:Umean": 0.47,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 127,
    "out:Energy use kWh/m2": 127,
    "out:Energy savings %": 44.1,
    "out:Op energy cost/MSEK": 64,
    "out:El price/MSEK": 37,
    "out:Heating price/MSEK": 23,
    "out:DH+ DHW/kWh/m2": 119,
    "out:El bought/kWh/m2": 530.25352,
    "out:Return %": 14,
    "out:Return/kSEK/y": 626,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 33.36,
    "out:EAHP (El)": 0,
    "out:PV (kr/kWp)": 17461.48474631725,
    "out:PV (kWp installed)": 90.5097,
    "out:PV (m2 panels)": 474.867263,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 84922.568824,
    "out:PV (kWh sold)": 68878.145463,
    "out:PV (% sold (El))": 81.106997,
    "out:PV (kWh self-consumed)": 16044.423361,
    "out:PV (ratio sold/self-consumed)": 4.292965,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 162554.048172,
    "out:EL kWh savings": 16044.422709,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 79599.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 297960.11,
    "out:DH kr savings": 162554.04817162128,
    "out:El kr savings": 32088.845417124598,
    "out:El kr sold": 103317.21819422109,
    "out:El kr saved": 32088.846722,
    "out:El kr return": 135406.06491622108,
    "out:% solar/total": 40,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 271142.681412,
    "out:% savings (space heating)": 33.403441,
    "out:Total_Water Heating (DH)": 106218.208041,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 85.16,
    "out:Etvv": 20,
    "out:Ef": 7.53,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 4617668.2614475,
    "out:Seasonal Variation ROI (%)": 6,
    "out:Seasonal Variation Payback": 16.666667,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 42.838096,
    "out:EL CO2": -108.956638,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -109.952146,
    "out:Bought CO2": 0.995508,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01001000120001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 88.927327,
    "out:DHW (DH)": 28.356,
    "out:Property Electricity": 12.57,
    "out:Electricity (inc PV)": 7.530921,
    "out:Total Energy Use Pre PV": 129.57,
    "out:Total Energy Use Post PV": 124.530921,
    "out:Primary Energy": 94.621357,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 42.817181,
    "out:CO2 Operational/m2": -66.83851,
    "out:Total CO2/m2": -24.021329,
    "out:Total CO2 (tons)": -76.483889,
    "out:Klimatpaverkan": -276.61931,
    "out:Initial Cost/MSEK": 4.8112842864475,
    "out:Running cost/(Apt SEK y)": 22759.021277,
    "out:Running Cost over RSP/MSEK": 8.38873,
    "out:LCP/MSEK": 0.204706,
    "out:ROI% old": 14.893529,
    "out:Payback discounted": 8,
    "out:Atemp": 3184,
    "out:Umean": 0.47,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 125,
    "out:Energy use kWh/m2": 125,
    "out:Energy savings %": 46.4,
    "out:Op energy cost/MSEK": 62,
    "out:El price/MSEK": 36,
    "out:Heating price/MSEK": 23,
    "out:DH+ DHW/kWh/m2": 117,
    "out:El bought/kWh/m2": 530.25352,
    "out:Return %": 13,
    "out:Return/kSEK/y": 645,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 28.356,
    "out:EAHP (El)": 0,
    "out:PV (kr/kWp)": 17461.48474631725,
    "out:PV (kWp installed)": 90.5097,
    "out:PV (m2 panels)": 474.867263,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 84922.568824,
    "out:PV (kWh sold)": 68878.145463,
    "out:PV (% sold (El))": 81.106997,
    "out:PV (kWh self-consumed)": 16044.423361,
    "out:PV (ratio sold/self-consumed)": 4.292965,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 166484.937132,
    "out:EL kWh savings": 16044.422709,
    "out:Sewage heat recovery cost": 273216,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 301891,
    "out:DH kr savings": 166484.9371320974,
    "out:El kr savings": 32088.845417124598,
    "out:El kr sold": 103317.21819422109,
    "out:El kr saved": 32088.846722,
    "out:El kr return": 135406.06491622108,
    "out:% solar/total": 40,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 283144.523657,
    "out:% savings (space heating)": 30.455615,
    "out:Total_Water Heating (DH)": 90285.476835,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 88.93,
    "out:Etvv": 17,
    "out:Ef": 7.53,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 4811284.2864475,
    "out:Seasonal Variation ROI (%)": 6,
    "out:Seasonal Variation Payback": 16.666667,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 42.118128,
    "out:EL CO2": -108.956638,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -109.952146,
    "out:Bought CO2": 0.995508,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01001000120001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 85.157903,
    "out:DHW (DH)": 28.356,
    "out:Property Electricity": 12.57,
    "out:Electricity (inc PV)": 7.530921,
    "out:Total Energy Use Pre PV": 126.57,
    "out:Total Energy Use Post PV": 121.530921,
    "out:Primary Energy": 91.689583,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 42.817181,
    "out:CO2 Operational/m2": -67.918462,
    "out:Total CO2/m2": -25.101281,
    "out:Total CO2 (tons)": -79.922455,
    "out:Klimatpaverkan": -280.057876,
    "out:Initial Cost/MSEK": 4.8908842614475,
    "out:Running cost/(Apt SEK y)": 22145.255319,
    "out:Running Cost over RSP/MSEK": 8.16309,
    "out:LCP/MSEK": 0.350746,
    "out:ROI% old": 15.310203,
    "out:Payback discounted": 8,
    "out:Atemp": 3184,
    "out:Umean": 0.47,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 122,
    "out:Energy use kWh/m2": 122,
    "out:Energy savings %": 50,
    "out:Op energy cost/MSEK": 61,
    "out:El price/MSEK": 35,
    "out:Heating price/MSEK": 22,
    "out:DH+ DHW/kWh/m2": 114,
    "out:El bought/kWh/m2": 530.25352,
    "out:Return %": 14,
    "out:Return/kSEK/y": 674,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 28.356,
    "out:EAHP (El)": 0,
    "out:PV (kr/kWp)": 17461.48474631725,
    "out:PV (kWp installed)": 90.5097,
    "out:PV (m2 panels)": 474.867263,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 84922.568824,
    "out:PV (kWh sold)": 68878.145463,
    "out:PV (% sold (El))": 81.106997,
    "out:PV (kWh self-consumed)": 16044.423361,
    "out:PV (ratio sold/self-consumed)": 4.292965,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 178486.779378,
    "out:EL kWh savings": 16044.422709,
    "out:Sewage heat recovery cost": 273216,
    "out:Balancing hydronic system cost": 79599.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 313892.84,
    "out:DH kr savings": 178486.77937778927,
    "out:El kr savings": 32088.845417124598,
    "out:El kr sold": 103317.21819422109,
    "out:El kr saved": 32088.846722,
    "out:El kr return": 135406.06491622108,
    "out:% solar/total": 40,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 271142.681412,
    "out:% savings (space heating)": 33.403441,
    "out:Total_Water Heating (DH)": 90285.476835,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 85.16,
    "out:Etvv": 17,
    "out:Ef": 7.53,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 4890884.2614474995,
    "out:Seasonal Variation ROI (%)": 6,
    "out:Seasonal Variation Payback": 16.666667,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 41.038176,
    "out:EL CO2": -108.956638,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -109.952146,
    "out:Bought CO2": 0.995508,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01001000120100000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 1,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 88.927327,
    "out:DHW (DH)": 33.36,
    "out:Property Electricity": 9.976115,
    "out:Electricity (inc PV)": 5.879188,
    "out:Total Energy Use Pre PV": 131.976115,
    "out:Total Energy Use Post PV": 127.879188,
    "out:Primary Energy": 93.748237,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 42.29314,
    "out:CO2 Operational/m2": -70.124316,
    "out:Total CO2/m2": -27.831176,
    "out:Total CO2 (tons)": -88.614438,
    "out:Klimatpaverkan": -288.749859,
    "out:Initial Cost/MSEK": 4.684861157967325,
    "out:Running cost/(Apt SEK y)": 23462.425532,
    "out:Running Cost over RSP/MSEK": 8.64864,
    "out:LCP/MSEK": 0.071219,
    "out:ROI% old": 14.502884,
    "out:Payback discounted": 8,
    "out:Atemp": 3184,
    "out:Umean": 0.47,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 128,
    "out:Energy use kWh/m2": 128,
    "out:Energy savings %": 43,
    "out:Op energy cost/MSEK": 64,
    "out:El price/MSEK": 37,
    "out:Heating price/MSEK": 24,
    "out:DH+ DHW/kWh/m2": 122,
    "out:El bought/kWh/m2": 413.65837,
    "out:Return %": 13,
    "out:Return/kSEK/y": 612,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 33.36,
    "out:EAHP (El)": 0,
    "out:PV (kr/kWp)": 17461.48474631725,
    "out:PV (kWp installed)": 90.5097,
    "out:PV (m2 panels)": 474.867263,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 84922.568824,
    "out:PV (kWh sold)": 71877.957046,
    "out:PV (% sold (El))": 84.639405,
    "out:PV (kWh self-consumed)": 13044.611778,
    "out:PV (ratio sold/self-consumed)": 5.510165,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 150552.205926,
    "out:EL kWh savings": 21303.538998,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 300976.22,
    "out:DH kr savings": 150552.20592592942,
    "out:El kr savings": 42607.077996404216,
    "out:El kr sold": 107816.93556907076,
    "out:El kr saved": 26089.223556,
    "out:El kr return": 133906.15912507076,
    "out:% solar/total": 33,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 283144.523657,
    "out:% savings (space heating)": 30.455615,
    "out:Total_Water Heating (DH)": 106218.208041,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 88.93,
    "out:Etvv": 20,
    "out:Ef": 5.88,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 4684861.157967325,
    "out:Seasonal Variation ROI (%)": 6,
    "out:Seasonal Variation Payback": 16.666667,
    "out:New AHU Cost/SEK": 146792.87151982498,
    "out:DH CO2": 43.918048,
    "out:EL CO2": -114.042364,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -114.819396,
    "out:Bought CO2": 0.777032,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01001000120100100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 1,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 85.157903,
    "out:DHW (DH)": 33.36,
    "out:Property Electricity": 9.976115,
    "out:Electricity (inc PV)": 5.879188,
    "out:Total Energy Use Pre PV": 128.976115,
    "out:Total Energy Use Post PV": 124.879188,
    "out:Primary Energy": 90.816463,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 42.29314,
    "out:CO2 Operational/m2": -71.204268,
    "out:Total CO2/m2": -28.911128,
    "out:Total CO2 (tons)": -92.053004,
    "out:Klimatpaverkan": -292.188425,
    "out:Initial Cost/MSEK": 4.764461132967325,
    "out:Running cost/(Apt SEK y)": 22848.659574,
    "out:Running Cost over RSP/MSEK": 8.42301,
    "out:LCP/MSEK": 0.217249,
    "out:ROI% old": 14.937111,
    "out:Payback discounted": 8,
    "out:Atemp": 3184,
    "out:Umean": 0.47,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 125,
    "out:Energy use kWh/m2": 125,
    "out:Energy savings %": 46.4,
    "out:Op energy cost/MSEK": 63,
    "out:El price/MSEK": 36,
    "out:Heating price/MSEK": 23,
    "out:DH+ DHW/kWh/m2": 119,
    "out:El bought/kWh/m2": 413.65837,
    "out:Return %": 13,
    "out:Return/kSEK/y": 641,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 33.36,
    "out:EAHP (El)": 0,
    "out:PV (kr/kWp)": 17461.48474631725,
    "out:PV (kWp installed)": 90.5097,
    "out:PV (m2 panels)": 474.867263,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 84922.568824,
    "out:PV (kWh sold)": 71877.957046,
    "out:PV (% sold (El))": 84.639405,
    "out:PV (kWh self-consumed)": 13044.611778,
    "out:PV (ratio sold/self-consumed)": 5.510165,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 7,
    "out:Payback Time": 14.285714,
    "out:DH kWh savings": 162554.048172,
    "out:EL kWh savings": 21303.538998,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 79599.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 312978.06,
    "out:DH kr savings": 162554.04817162128,
    "out:El kr savings": 42607.077996404216,
    "out:El kr sold": 107816.93556907076,
    "out:El kr saved": 26089.223556,
    "out:El kr return": 133906.15912507076,
    "out:% solar/total": 33,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 271142.681412,
    "out:% savings (space heating)": 33.403441,
    "out:Total_Water Heating (DH)": 106218.208041,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 85.16,
    "out:Etvv": 20,
    "out:Ef": 5.88,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 4764461.132967325,
    "out:Seasonal Variation ROI (%)": 6,
    "out:Seasonal Variation Payback": 16.666667,
    "out:New AHU Cost/SEK": 146792.87151982498,
    "out:DH CO2": 42.838096,
    "out:EL CO2": -114.042364,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -114.819396,
    "out:Bought CO2": 0.777032,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01001000120101000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 1,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 88.927327,
    "out:DHW (DH)": 28.356,
    "out:Property Electricity": 9.976115,
    "out:Electricity (inc PV)": 5.879188,
    "out:Total Energy Use Pre PV": 126.976115,
    "out:Total Energy Use Post PV": 122.879188,
    "out:Primary Energy": 91.648237,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 43.001683,
    "out:CO2 Operational/m2": -71.924236,
    "out:Total CO2/m2": -28.922553,
    "out:Total CO2 (tons)": -92.089381,
    "out:Klimatpaverkan": -292.224802,
    "out:Initial Cost/MSEK": 4.958077157967324,
    "out:Running cost/(Apt SEK y)": 22439.489362,
    "out:Running Cost over RSP/MSEK": 8.27258,
    "out:LCP/MSEK": 0.174063,
    "out:ROI% old": 14.787242,
    "out:Payback discounted": 8,
    "out:Atemp": 3184,
    "out:Umean": 0.47,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 123,
    "out:Energy use kWh/m2": 123,
    "out:Energy savings %": 48.8,
    "out:Op energy cost/MSEK": 62,
    "out:El price/MSEK": 35,
    "out:Heating price/MSEK": 23,
    "out:DH+ DHW/kWh/m2": 117,
    "out:El bought/kWh/m2": 413.65837,
    "out:Return %": 13,
    "out:Return/kSEK/y": 660,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 28.356,
    "out:EAHP (El)": 0,
    "out:PV (kr/kWp)": 17461.48474631725,
    "out:PV (kWp installed)": 90.5097,
    "out:PV (m2 panels)": 474.867263,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 84922.568824,
    "out:PV (kWh sold)": 71877.957046,
    "out:PV (% sold (El))": 84.639405,
    "out:PV (kWh self-consumed)": 13044.611778,
    "out:PV (ratio sold/self-consumed)": 5.510165,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 166484.937132,
    "out:EL kWh savings": 21303.538998,
    "out:Sewage heat recovery cost": 273216,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 316908.95,
    "out:DH kr savings": 166484.9371320974,
    "out:El kr savings": 42607.077996404216,
    "out:El kr sold": 107816.93556907076,
    "out:El kr saved": 26089.223556,
    "out:El kr return": 133906.15912507076,
    "out:% solar/total": 33,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 283144.523657,
    "out:% savings (space heating)": 30.455615,
    "out:Total_Water Heating (DH)": 90285.476835,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 88.93,
    "out:Etvv": 17,
    "out:Ef": 5.88,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 4958077.157967324,
    "out:Seasonal Variation ROI (%)": 6,
    "out:Seasonal Variation Payback": 16.666667,
    "out:New AHU Cost/SEK": 146792.87151982498,
    "out:DH CO2": 42.118128,
    "out:EL CO2": -114.042364,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -114.819396,
    "out:Bought CO2": 0.777032,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01001000120101100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 1,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 85.157903,
    "out:DHW (DH)": 28.356,
    "out:Property Electricity": 9.976115,
    "out:Electricity (inc PV)": 5.879188,
    "out:Total Energy Use Pre PV": 123.976115,
    "out:Total Energy Use Post PV": 119.879188,
    "out:Primary Energy": 88.716463,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 43.001683,
    "out:CO2 Operational/m2": -73.004188,
    "out:Total CO2/m2": -30.002505,
    "out:Total CO2 (tons)": -95.527947,
    "out:Klimatpaverkan": -295.663368,
    "out:Initial Cost/MSEK": 5.037677132967325,
    "out:Running cost/(Apt SEK y)": 21825.723404,
    "out:Running Cost over RSP/MSEK": 8.04695,
    "out:LCP/MSEK": 0.320093,
    "out:ROI% old": 15.193425,
    "out:Payback discounted": 8,
    "out:Atemp": 3184,
    "out:Umean": 0.47,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 120,
    "out:Energy use kWh/m2": 120,
    "out:Energy savings %": 52.5,
    "out:Op energy cost/MSEK": 60,
    "out:El price/MSEK": 34,
    "out:Heating price/MSEK": 22,
    "out:DH+ DHW/kWh/m2": 114,
    "out:El bought/kWh/m2": 413.65837,
    "out:Return %": 14,
    "out:Return/kSEK/y": 689,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 28.356,
    "out:EAHP (El)": 0,
    "out:PV (kr/kWp)": 17461.48474631725,
    "out:PV (kWp installed)": 90.5097,
    "out:PV (m2 panels)": 474.867263,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 84922.568824,
    "out:PV (kWh sold)": 71877.957046,
    "out:PV (% sold (El))": 84.639405,
    "out:PV (kWh self-consumed)": 13044.611778,
    "out:PV (ratio sold/self-consumed)": 5.510165,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 7,
    "out:Payback Time": 14.285714,
    "out:DH kWh savings": 178486.779378,
    "out:EL kWh savings": 21303.538998,
    "out:Sewage heat recovery cost": 273216,
    "out:Balancing hydronic system cost": 79599.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 328910.79,
    "out:DH kr savings": 178486.77937778927,
    "out:El kr savings": 42607.077996404216,
    "out:El kr sold": 107816.93556907076,
    "out:El kr saved": 26089.223556,
    "out:El kr return": 133906.15912507076,
    "out:% solar/total": 33,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 271142.681412,
    "out:% savings (space heating)": 33.403441,
    "out:Total_Water Heating (DH)": 90285.476835,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 85.16,
    "out:Etvv": 17,
    "out:Ef": 5.88,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 5037677.132967325,
    "out:Seasonal Variation ROI (%)": 6,
    "out:Seasonal Variation Payback": 16.666667,
    "out:New AHU Cost/SEK": 146792.87151982498,
    "out:DH CO2": 41.038176,
    "out:EL CO2": -114.042364,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -114.819396,
    "out:Bought CO2": 0.777032,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01001001000000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 47.39869,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 40.090299,
    "out:Electricity (inc PV)": 40.090299,
    "out:Total Energy Use Pre PV": 87.090299,
    "out:Total Energy Use Post PV": 87.090299,
    "out:Primary Energy": 101.64139,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 16.383382,
    "out:CO2 Operational/m2": 22.211488,
    "out:Total CO2/m2": 38.59487,
    "out:Total CO2 (tons)": 122.886029,
    "out:Klimatpaverkan": -77.249392,
    "out:Initial Cost/MSEK": 5.817836264077743,
    "out:Running cost/(Apt SEK y)": 15047.489362,
    "out:Running Cost over RSP/MSEK": 5.50856,
    "out:LCP/MSEK": 2.078324,
    "out:ROI% old": 19.389044,
    "out:Payback discounted": 6,
    "out:Atemp": 3184,
    "out:Umean": 0.47,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 87,
    "out:Energy use kWh/m2": 87,
    "out:Energy savings %": 110.3,
    "out:Op energy cost/MSEK": 39,
    "out:El price/MSEK": 28,
    "out:Heating price/MSEK": 9,
    "out:DH+ DHW/kWh/m2": 47,
    "out:El bought/kWh/m2": 2806.32093,
    "out:Return %": 17,
    "out:Return/kSEK/y": 1007,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.6502792112,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 41.7,
    "out:EAHP (El)": 25.220799,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 388997.554072,
    "out:EL kWh savings": -87624.605652,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 213748.34,
    "out:DH kr savings": 388997.5540722153,
    "out:El kr savings": -175249.21130310712,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 150917.383552,
    "out:% savings (space heating)": 62.932511,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 997124.4110739925,
    "out:Eupp": 37.9,
    "out:Etvv": 0,
    "out:Ef": 37.91,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 5817836.264077743,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 16.919248,
    "out:EL CO2": 5.29224,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 5.29224,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01001001000000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 43.861527,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 40.019917,
    "out:Electricity (inc PV)": 40.019917,
    "out:Total Energy Use Pre PV": 84.019917,
    "out:Total Energy Use Post PV": 84.019917,
    "out:Primary Energy": 98.884386,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 16.383382,
    "out:CO2 Operational/m2": 21.122245,
    "out:Total CO2/m2": 37.505627,
    "out:Total CO2 (tons)": 119.41788,
    "out:Klimatpaverkan": -80.717541,
    "out:Initial Cost/MSEK": 5.897436239077742,
    "out:Running cost/(Apt SEK y)": 14424.191489,
    "out:Running Cost over RSP/MSEK": 5.27947,
    "out:LCP/MSEK": 2.227814,
    "out:ROI% old": 19.682281,
    "out:Payback discounted": 6,
    "out:Atemp": 3184,
    "out:Umean": 0.47,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 84,
    "out:Energy use kWh/m2": 84,
    "out:Energy savings %": 117.9,
    "out:Op energy cost/MSEK": 37,
    "out:El price/MSEK": 27,
    "out:Heating price/MSEK": 9,
    "out:DH+ DHW/kWh/m2": 44,
    "out:El bought/kWh/m2": 2801.39419,
    "out:Return %": 18,
    "out:Return/kSEK/y": 1037,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.6502792112,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 41.7,
    "out:EAHP (El)": 25.150417,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 400259.877676,
    "out:EL kWh savings": -87400.509431,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 79599.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 225458.86,
    "out:DH kr savings": 400259.87767561316,
    "out:El kr savings": -174801.01886195896,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 139655.059949,
    "out:% savings (space heating)": 65.6987,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 997124.4110739925,
    "out:Eupp": 34.52,
    "out:Etvv": 0,
    "out:Ef": 37.79,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 5897436.239077742,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 15.839296,
    "out:EL CO2": 5.282949,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 5.282949,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01001001000001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 43.730187,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 39.306512,
    "out:Electricity (inc PV)": 39.306512,
    "out:Total Energy Use Pre PV": 83.306512,
    "out:Total Energy Use Post PV": 83.306512,
    "out:Primary Energy": 98.668322,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 17.091925,
    "out:CO2 Operational/m2": 21.02807,
    "out:Total CO2/m2": 38.119995,
    "out:Total CO2 (tons)": 121.374028,
    "out:Klimatpaverkan": -78.761393,
    "out:Initial Cost/MSEK": 6.091052264077742,
    "out:Running cost/(Apt SEK y)": 14327.531915,
    "out:Running Cost over RSP/MSEK": 5.24434,
    "out:LCP/MSEK": 2.069328,
    "out:ROI% old": 19.139034,
    "out:Payback discounted": 6,
    "out:Atemp": 3184,
    "out:Umean": 0.47,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 83,
    "out:Energy use kWh/m2": 83,
    "out:Energy savings %": 120.5,
    "out:Op energy cost/MSEK": 37,
    "out:El price/MSEK": 27,
    "out:Heating price/MSEK": 9,
    "out:DH+ DHW/kWh/m2": 44,
    "out:El bought/kWh/m2": 2751.45584,
    "out:Return %": 17,
    "out:Return/kSEK/y": 1041,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.6502792112,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 35.445,
    "out:EAHP (El)": 24.437012,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 400678.06411,
    "out:EL kWh savings": -85129.028594,
    "out:Sewage heat recovery cost": 273216,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 230420.01,
    "out:DH kr savings": 400678.06410978944,
    "out:El kr savings": -170258.057188843,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 139236.873514,
    "out:% savings (space heating)": 65.801413,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 997124.4110739925,
    "out:Eupp": 35.89,
    "out:Etvv": 0,
    "out:Ef": 37.38,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 6091052.264077743,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 15.839296,
    "out:EL CO2": 5.188774,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 5.188774,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01001001000001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 40.246895,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 39.219805,
    "out:Electricity (inc PV)": 39.219805,
    "out:Total Energy Use Pre PV": 79.219805,
    "out:Total Energy Use Post PV": 79.219805,
    "out:Primary Energy": 95.910045,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 17.091925,
    "out:CO2 Operational/m2": 19.576688,
    "out:Total CO2/m2": 36.668613,
    "out:Total CO2 (tons)": 116.752829,
    "out:Klimatpaverkan": -83.382592,
    "out:Initial Cost/MSEK": 6.170652239077742,
    "out:Running cost/(Apt SEK y)": 13497.425532,
    "out:Running Cost over RSP/MSEK": 4.93923,
    "out:LCP/MSEK": 2.294838,
    "out:ROI% old": 19.598507,
    "out:Payback discounted": 6,
    "out:Atemp": 3184,
    "out:Umean": 0.47,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 79,
    "out:Energy use kWh/m2": 79,
    "out:Energy savings %": 131.6,
    "out:Op energy cost/MSEK": 35,
    "out:El price/MSEK": 26,
    "out:Heating price/MSEK": 8,
    "out:DH+ DHW/kWh/m2": 40,
    "out:El bought/kWh/m2": 2745.38635,
    "out:Return %": 18,
    "out:Return/kSEK/y": 1080,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.6502792112,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 35.445,
    "out:EAHP (El)": 24.350305,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 411768.862501,
    "out:EL kWh savings": -84852.953589,
    "out:Sewage heat recovery cost": 273216,
    "out:Balancing hydronic system cost": 79599.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 242062.96,
    "out:DH kr savings": 411768.8625007957,
    "out:El kr savings": -169705.90717897366,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 128146.075123,
    "out:% savings (space heating)": 68.525473,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 997124.4110739925,
    "out:Eupp": 32.55,
    "out:Etvv": 0,
    "out:Ef": 37.25,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 6170652.239077742,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 14.39936,
    "out:EL CO2": 5.177328,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 5.177328,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01001001010000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 47.39869,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 40.090299,
    "out:Electricity (inc PV)": 30.095128,
    "out:Total Energy Use Pre PV": 87.090299,
    "out:Total Energy Use Post PV": 77.095128,
    "out:Primary Energy": 83.650082,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 31.122482,
    "out:CO2 Operational/m2": 4.32604,
    "out:Total CO2/m2": 35.448522,
    "out:Total CO2 (tons)": 112.86806,
    "out:Klimatpaverkan": -87.267361,
    "out:Initial Cost/MSEK": 6.608056658300243,
    "out:Running cost/(Apt SEK y)": 13353.787234,
    "out:Running Cost over RSP/MSEK": 4.90255,
    "out:LCP/MSEK": 1.894113,
    "out:ROI% old": 18.380528,
    "out:Payback discounted": 7,
    "out:Atemp": 3184,
    "out:Umean": 0.47,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 77,
    "out:Energy use kWh/m2": 77,
    "out:Energy savings %": 137.7,
    "out:Op energy cost/MSEK": 35,
    "out:El price/MSEK": 25,
    "out:Heating price/MSEK": 9,
    "out:DH+ DHW/kWh/m2": 47,
    "out:El bought/kWh/m2": 2128.179559,
    "out:Return %": 16,
    "out:Return/kSEK/y": 1087,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.6502792112,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 41.7,
    "out:EAHP (El)": 25.220799,
    "out:PV (kr/kWp)": 17461.56255567083,
    "out:PV (kWp installed)": 45.25485,
    "out:PV (m2 panels)": 237.433632,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 42461.284501,
    "out:PV (kWh sold)": 10636.670061,
    "out:PV (% sold (El))": 25.050279,
    "out:PV (kWh self-consumed)": 31824.614441,
    "out:PV (ratio sold/self-consumed)": 0.334228,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 388997.554072,
    "out:EL kWh savings": -55799.990763,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 293352.58,
    "out:DH kr savings": 388997.5540722153,
    "out:El kr savings": -111599.98152585476,
    "out:El kr sold": 15955.00509088609,
    "out:El kr saved": 63649.228882,
    "out:El kr return": 79604.2339728861,
    "out:% solar/total": 80,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 150917.383552,
    "out:% savings (space heating)": 62.932511,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 997124.4110739925,
    "out:Eupp": 37.9,
    "out:Etvv": 0,
    "out:Ef": 28.22,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 6608056.658300243,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 16.919248,
    "out:EL CO2": -12.593208,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -16.575625,
    "out:Bought CO2": 3.982417,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01001001010000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 43.861527,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 40.019917,
    "out:Electricity (inc PV)": 30.034494,
    "out:Total Energy Use Pre PV": 84.019917,
    "out:Total Energy Use Post PV": 74.034494,
    "out:Primary Energy": 80.910624,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 31.122482,
    "out:CO2 Operational/m2": 3.188431,
    "out:Total CO2/m2": 34.310913,
    "out:Total CO2 (tons)": 109.245914,
    "out:Klimatpaverkan": -90.889507,
    "out:Initial Cost/MSEK": 6.687656633300243,
    "out:Running cost/(Apt SEK y)": 12730.808511,
    "out:Running Cost over RSP/MSEK": 4.67357,
    "out:LCP/MSEK": 2.043493,
    "out:ROI% old": 18.650885,
    "out:Payback discounted": 7,
    "out:Atemp": 3184,
    "out:Umean": 0.47,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 74,
    "out:Energy use kWh/m2": 74,
    "out:Energy savings %": 147.3,
    "out:Op energy cost/MSEK": 34,
    "out:El price/MSEK": 24,
    "out:Heating price/MSEK": 9,
    "out:DH+ DHW/kWh/m2": 44,
    "out:El bought/kWh/m2": 2123.887455,
    "out:Return %": 17,
    "out:Return/kSEK/y": 1116,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.6502792112,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 41.7,
    "out:EAHP (El)": 25.150417,
    "out:PV (kr/kWp)": 17461.56255567083,
    "out:PV (kWp installed)": 45.25485,
    "out:PV (m2 panels)": 237.433632,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 42461.284501,
    "out:PV (kWh sold)": 10667.708413,
    "out:PV (% sold (El))": 25.123377,
    "out:PV (kWh self-consumed)": 31793.576089,
    "out:PV (ratio sold/self-consumed)": 0.33553,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 400259.877676,
    "out:EL kWh savings": -55606.932165,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 79599.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 305047.58,
    "out:DH kr savings": 400259.87767561316,
    "out:El kr savings": -111213.86433002946,
    "out:El kr sold": 16001.56261880909,
    "out:El kr saved": 63587.152178,
    "out:El kr return": 79588.71479680909,
    "out:% solar/total": 79,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 139655.059949,
    "out:% savings (space heating)": 65.6987,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 997124.4110739925,
    "out:Eupp": 34.52,
    "out:Etvv": 0,
    "out:Ef": 28.12,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 6687656.633300243,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 15.839296,
    "out:EL CO2": -12.650865,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -16.625255,
    "out:Bought CO2": 3.97439,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01001001010001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 43.730187,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 39.306512,
    "out:Electricity (inc PV)": 29.421039,
    "out:Total Energy Use Pre PV": 83.306512,
    "out:Total Energy Use Post PV": 73.421039,
    "out:Primary Energy": 80.87447,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 31.831025,
    "out:CO2 Operational/m2": 2.598481,
    "out:Total CO2/m2": 34.429506,
    "out:Total CO2 (tons)": 109.623514,
    "out:Klimatpaverkan": -90.511907,
    "out:Initial Cost/MSEK": 6.881272658300243,
    "out:Running cost/(Apt SEK y)": 12637.531915,
    "out:Running Cost over RSP/MSEK": 4.63965,
    "out:LCP/MSEK": 1.883797,
    "out:ROI% old": 18.19653,
    "out:Payback discounted": 7,
    "out:Atemp": 3184,
    "out:Umean": 0.47,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 73,
    "out:Energy use kWh/m2": 73,
    "out:Energy savings %": 150.7,
    "out:Op energy cost/MSEK": 33,
    "out:El price/MSEK": 23,
    "out:Heating price/MSEK": 9,
    "out:DH+ DHW/kWh/m2": 44,
    "out:El bought/kWh/m2": 2080.450934,
    "out:Return %": 16,
    "out:Return/kSEK/y": 1121,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.6502792112,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 35.445,
    "out:EAHP (El)": 24.437012,
    "out:PV (kr/kWp)": 17461.56255567083,
    "out:PV (kWp installed)": 45.25485,
    "out:PV (m2 panels)": 237.433632,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 42461.284501,
    "out:PV (kWh sold)": 10985.947487,
    "out:PV (% sold (El))": 25.872857,
    "out:PV (kWh self-consumed)": 31475.337015,
    "out:PV (ratio sold/self-consumed)": 0.349034,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 400678.06411,
    "out:EL kWh savings": -53653.692033,
    "out:Sewage heat recovery cost": 273216,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 309849.6,
    "out:DH kr savings": 400678.06410978944,
    "out:El kr savings": -107307.38406540932,
    "out:El kr sold": 16478.921230028573,
    "out:El kr saved": 62950.67403,
    "out:El kr return": 79429.59526002858,
    "out:% solar/total": 79,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 139236.873514,
    "out:% savings (space heating)": 65.801413,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 997124.4110739925,
    "out:Eupp": 35.89,
    "out:Etvv": 0,
    "out:Ef": 27.78,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 6881272.658300242,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 15.839296,
    "out:EL CO2": -13.240815,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -17.133987,
    "out:Bought CO2": 3.893172,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01001001010001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 40.246895,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 39.219805,
    "out:Electricity (inc PV)": 29.346617,
    "out:Total Energy Use Pre PV": 79.219805,
    "out:Total Energy Use Post PV": 69.346617,
    "out:Primary Energy": 78.138307,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 31.831025,
    "out:CO2 Operational/m2": 1.086153,
    "out:Total CO2/m2": 32.917178,
    "out:Total CO2 (tons)": 104.808263,
    "out:Klimatpaverkan": -95.327158,
    "out:Initial Cost/MSEK": 6.960872633300243,
    "out:Running cost/(Apt SEK y)": 11807.851064,
    "out:Running Cost over RSP/MSEK": 4.33469,
    "out:LCP/MSEK": 2.109157,
    "out:ROI% old": 18.614312,
    "out:Payback discounted": 7,
    "out:Atemp": 3184,
    "out:Umean": 0.47,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 69,
    "out:Energy use kWh/m2": 69,
    "out:Energy savings %": 165.2,
    "out:Op energy cost/MSEK": 31,
    "out:El price/MSEK": 22,
    "out:Heating price/MSEK": 8,
    "out:DH+ DHW/kWh/m2": 40,
    "out:El bought/kWh/m2": 2075.180193,
    "out:Return %": 17,
    "out:Return/kSEK/y": 1160,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.6502792112,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 35.445,
    "out:EAHP (El)": 24.350305,
    "out:PV (kr/kWp)": 17461.56255567083,
    "out:PV (kWp installed)": 45.25485,
    "out:PV (m2 panels)": 237.433632,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 42461.284501,
    "out:PV (kWh sold)": 11025.063653,
    "out:PV (% sold (El))": 25.964979,
    "out:PV (kWh self-consumed)": 31436.220848,
    "out:PV (ratio sold/self-consumed)": 0.350712,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 411768.862501,
    "out:EL kWh savings": -53416.732456,
    "out:Sewage heat recovery cost": 273216,
    "out:Balancing hydronic system cost": 79599.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 321472.99,
    "out:DH kr savings": 411768.8625007957,
    "out:El kr savings": -106833.4649120017,
    "out:El kr sold": 16537.595479819927,
    "out:El kr saved": 62872.441696,
    "out:El kr return": 79410.03717581993,
    "out:% solar/total": 79,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 128146.075123,
    "out:% savings (space heating)": 68.525473,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 997124.4110739925,
    "out:Eupp": 32.55,
    "out:Etvv": 0,
    "out:Ef": 27.67,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 6960872.633300243,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 14.39936,
    "out:EL CO2": -13.313207,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -17.196526,
    "out:Bought CO2": 3.883319,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01001001020000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 47.39869,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 40.090299,
    "out:Electricity (inc PV)": 27.252883,
    "out:Total Energy Use Pre PV": 87.090299,
    "out:Total Energy Use Post PV": 74.252883,
    "out:Primary Energy": 78.534041,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 45.861581,
    "out:CO2 Operational/m2": -49.267726,
    "out:Total CO2/m2": -3.406145,
    "out:Total CO2 (tons)": -10.845162,
    "out:Klimatpaverkan": -210.980583,
    "out:Initial Cost/MSEK": 7.398270010021493,
    "out:Running cost/(Apt SEK y)": 11902.361702,
    "out:Running Cost over RSP/MSEK": 4.38346,
    "out:LCP/MSEK": 1.62299,
    "out:ROI% old": 17.419632,
    "out:Payback discounted": 7,
    "out:Atemp": 3184,
    "out:Umean": 0.47,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 74,
    "out:Energy use kWh/m2": 74,
    "out:Energy savings %": 147.3,
    "out:Op energy cost/MSEK": 32,
    "out:El price/MSEK": 21,
    "out:Heating price/MSEK": 9,
    "out:DH+ DHW/kWh/m2": 47,
    "out:El bought/kWh/m2": 1926.024099,
    "out:Return %": 16,
    "out:Return/kSEK/y": 1155,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.6502792112,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 41.7,
    "out:EAHP (El)": 25.220799,
    "out:PV (kr/kWp)": 17461.48474631725,
    "out:PV (kWp installed)": 90.5097,
    "out:PV (m2 panels)": 474.867263,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 84922.568824,
    "out:PV (kWh sold)": 44048.24919,
    "out:PV (% sold (El))": 51.86872,
    "out:PV (kWh self-consumed)": 40874.319634,
    "out:PV (ratio sold/self-consumed)": 1.077651,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 388997.554072,
    "out:EL kWh savings": -46750.285406,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 361569.36,
    "out:DH kr savings": 388997.5540722153,
    "out:El kr savings": -93500.57081159615,
    "out:El kr sold": 66072.37378443894,
    "out:El kr saved": 81748.639268,
    "out:El kr return": 147821.01305243894,
    "out:% solar/total": 102,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 150917.383552,
    "out:% savings (space heating)": 62.932511,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 997124.4110739925,
    "out:Eupp": 37.9,
    "out:Etvv": 0,
    "out:Ef": 25.57,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 7398270.010021493,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 16.919248,
    "out:EL CO2": -66.186974,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -69.792724,
    "out:Bought CO2": 3.60575,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01001001020000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 43.861527,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 40.019917,
    "out:Electricity (inc PV)": 27.1985,
    "out:Total Energy Use Pre PV": 84.019917,
    "out:Total Energy Use Post PV": 71.1985,
    "out:Primary Energy": 75.805835,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 45.861581,
    "out:CO2 Operational/m2": -50.436935,
    "out:Total CO2/m2": -4.575354,
    "out:Total CO2 (tons)": -14.567923,
    "out:Klimatpaverkan": -214.703344,
    "out:Initial Cost/MSEK": 7.477869985021494,
    "out:Running cost/(Apt SEK y)": 11279.595745,
    "out:Running Cost over RSP/MSEK": 4.15456,
    "out:LCP/MSEK": 1.77229,
    "out:ROI% old": 17.671495,
    "out:Payback discounted": 7,
    "out:Atemp": 3184,
    "out:Umean": 0.47,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 71,
    "out:Energy use kWh/m2": 71,
    "out:Energy savings %": 157.7,
    "out:Op energy cost/MSEK": 30,
    "out:El price/MSEK": 20,
    "out:Heating price/MSEK": 9,
    "out:DH+ DHW/kWh/m2": 44,
    "out:El bought/kWh/m2": 1922.171553,
    "out:Return %": 16,
    "out:Return/kSEK/y": 1185,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.6502792112,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 41.7,
    "out:EAHP (El)": 25.150417,
    "out:PV (kr/kWp)": 17461.48474631725,
    "out:PV (kWp installed)": 90.5097,
    "out:PV (m2 panels)": 474.867263,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 84922.568824,
    "out:PV (kWh sold)": 44099.188153,
    "out:PV (% sold (El))": 51.928703,
    "out:PV (kWh self-consumed)": 40823.380671,
    "out:PV (ratio sold/self-consumed)": 1.080243,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 400259.877676,
    "out:EL kWh savings": -46577.129986,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 79599.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 373254.4,
    "out:DH kr savings": 400259.87767561316,
    "out:El kr savings": -93154.25997179397,
    "out:El kr sold": 66148.78222892903,
    "out:El kr saved": 81646.761342,
    "out:El kr return": 147795.54357092903,
    "out:% solar/total": 102,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 139655.059949,
    "out:% savings (space heating)": 65.6987,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 997124.4110739925,
    "out:Eupp": 34.52,
    "out:Etvv": 0,
    "out:Ef": 25.48,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 7477869.985021493,
    "out:Seasonal Variation ROI (%)": 5,
    "out:Seasonal Variation Payback": 20,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 15.839296,
    "out:EL CO2": -66.276231,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -69.87478,
    "out:Bought CO2": 3.598549,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01001001020001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 43.730187,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 39.306512,
    "out:Electricity (inc PV)": 26.64831,
    "out:Total Energy Use Pre PV": 83.306512,
    "out:Total Energy Use Post PV": 70.64831,
    "out:Primary Energy": 75.883558,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 46.570124,
    "out:CO2 Operational/m2": -51.34703,
    "out:Total CO2/m2": -4.776906,
    "out:Total CO2 (tons)": -15.209664,
    "out:Klimatpaverkan": -215.345085,
    "out:Initial Cost/MSEK": 7.671486010021493,
    "out:Running cost/(Apt SEK y)": 11188.468085,
    "out:Running Cost over RSP/MSEK": 4.12142,
    "out:LCP/MSEK": 1.611814,
    "out:ROI% old": 17.287208,
    "out:Payback discounted": 7,
    "out:Atemp": 3184,
    "out:Umean": 0.47,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 71,
    "out:Energy use kWh/m2": 71,
    "out:Energy savings %": 157.7,
    "out:Op energy cost/MSEK": 30,
    "out:El price/MSEK": 20,
    "out:Heating price/MSEK": 9,
    "out:DH+ DHW/kWh/m2": 44,
    "out:El bought/kWh/m2": 1883.189541,
    "out:Return %": 15,
    "out:Return/kSEK/y": 1189,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.6502792112,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 35.445,
    "out:EAHP (El)": 24.437012,
    "out:PV (kr/kWp)": 17461.48474631725,
    "out:PV (kWp installed)": 90.5097,
    "out:PV (m2 panels)": 474.867263,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 84922.568824,
    "out:PV (kWh sold)": 44618.864262,
    "out:PV (% sold (El))": 52.540644,
    "out:PV (kWh self-consumed)": 40303.704562,
    "out:PV (ratio sold/self-consumed)": 1.107066,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 400678.06411,
    "out:EL kWh savings": -44825.325553,
    "out:Sewage heat recovery cost": 273216,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 377955.71,
    "out:DH kr savings": 400678.06410978944,
    "out:El kr savings": -89650.65110595801,
    "out:El kr sold": 66928.29639242339,
    "out:El kr saved": 80607.409124,
    "out:El kr return": 147535.7055164234,
    "out:% solar/total": 101,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 139236.873514,
    "out:% savings (space heating)": 65.801413,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 997124.4110739925,
    "out:Eupp": 35.89,
    "out:Etvv": 0,
    "out:Ef": 25.17,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 7671486.010021493,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 15.839296,
    "out:EL CO2": -67.186326,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -70.712021,
    "out:Bought CO2": 3.525695,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01001001020001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 40.246895,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 39.219805,
    "out:Electricity (inc PV)": 26.581584,
    "out:Total Energy Use Pre PV": 79.219805,
    "out:Total Energy Use Post PV": 66.581584,
    "out:Primary Energy": 73.161247,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 46.570124,
    "out:CO2 Operational/m2": -52.898251,
    "out:Total CO2/m2": -6.328127,
    "out:Total CO2 (tons)": -20.14875,
    "out:Klimatpaverkan": -220.284171,
    "out:Initial Cost/MSEK": 7.7510859850214935,
    "out:Running cost/(Apt SEK y)": 10359.042553,
    "out:Running Cost over RSP/MSEK": 3.81655,
    "out:LCP/MSEK": 1.837084,
    "out:ROI% old": 17.67157,
    "out:Payback discounted": 7,
    "out:Atemp": 3184,
    "out:Umean": 0.47,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 67,
    "out:Energy use kWh/m2": 67,
    "out:Energy savings %": 173.1,
    "out:Op energy cost/MSEK": 28,
    "out:El price/MSEK": 19,
    "out:Heating price/MSEK": 8,
    "out:DH+ DHW/kWh/m2": 40,
    "out:El bought/kWh/m2": 1878.459904,
    "out:Return %": 16,
    "out:Return/kSEK/y": 1228,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.6502792112,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 35.445,
    "out:EAHP (El)": 24.350305,
    "out:PV (kr/kWp)": 17461.48474631725,
    "out:PV (kWp installed)": 90.5097,
    "out:PV (m2 panels)": 474.867263,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 84922.568824,
    "out:PV (kWh sold)": 44682.484026,
    "out:PV (% sold (El))": 52.615559,
    "out:PV (kWh self-consumed)": 40240.084798,
    "out:PV (ratio sold/self-consumed)": 1.110397,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 411768.862501,
    "out:EL kWh savings": -44612.870033,
    "out:Sewage heat recovery cost": 273216,
    "out:Balancing hydronic system cost": 79599.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 389566.85,
    "out:DH kr savings": 411768.8625007957,
    "out:El kr savings": -89225.740065805,
    "out:El kr sold": 67023.72603913718,
    "out:El kr saved": 80480.169596,
    "out:El kr return": 147503.8956351372,
    "out:% solar/total": 101,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 128146.075123,
    "out:% savings (space heating)": 68.525473,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 997124.4110739925,
    "out:Eupp": 32.55,
    "out:Etvv": 0,
    "out:Ef": 25.07,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 7751085.985021493,
    "out:Seasonal Variation ROI (%)": 5,
    "out:Seasonal Variation Payback": 20,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 14.39936,
    "out:EL CO2": -67.297611,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -70.814469,
    "out:Bought CO2": 3.516858,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01001001100000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 42.536828,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 39.036318,
    "out:Electricity (inc PV)": 39.036318,
    "out:Total Energy Use Pre PV": 82.036318,
    "out:Total Energy Use Post PV": 82.036318,
    "out:Primary Energy": 97.668459,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 16.383382,
    "out:CO2 Operational/m2": 20.632418,
    "out:Total CO2/m2": 37.0158,
    "out:Total CO2 (tons)": 117.858272,
    "out:Klimatpaverkan": -82.277149,
    "out:Initial Cost/MSEK": 5.941883951577742,
    "out:Running cost/(Apt SEK y)": 14086.319149,
    "out:Running Cost over RSP/MSEK": 5.15583,
    "out:LCP/MSEK": 2.307006,
    "out:ROI% old": 19.83231,
    "out:Payback discounted": 6,
    "out:Atemp": 3184,
    "out:Umean": 0.47,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 82,
    "out:Energy use kWh/m2": 82,
    "out:Energy savings %": 123.2,
    "out:Op energy cost/MSEK": 36,
    "out:El price/MSEK": 27,
    "out:Heating price/MSEK": 8,
    "out:DH+ DHW/kWh/m2": 43,
    "out:El bought/kWh/m2": 2732.54226,
    "out:Return %": 18,
    "out:Return/kSEK/y": 1053,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.6502792112,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 33.36,
    "out:EAHP (El)": 24.166818,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 404477.718023,
    "out:EL kWh savings": -84268.731157,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 235940.26,
    "out:DH kr savings": 404477.7180225515,
    "out:El kr savings": -168537.46231453467,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 135437.219602,
    "out:% savings (space heating)": 66.734662,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 997124.4110739925,
    "out:Eupp": 35.23,
    "out:Etvv": 0,
    "out:Ef": 37.2,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 5941883.951577742,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 15.479312,
    "out:EL CO2": 5.153106,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 5.153106,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01001001100000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 39.074673,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 38.943206,
    "out:Electricity (inc PV)": 38.943206,
    "out:Total Energy Use Pre PV": 77.943206,
    "out:Total Energy Use Post PV": 77.943206,
    "out:Primary Energy": 94.907682,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 16.383382,
    "out:CO2 Operational/m2": 19.180191,
    "out:Total CO2/m2": 35.563573,
    "out:Total CO2 (tons)": 113.234382,
    "out:Klimatpaverkan": -86.901039,
    "out:Initial Cost/MSEK": 6.021483926577743,
    "out:Running cost/(Apt SEK y)": 13255.361702,
    "out:Running Cost over RSP/MSEK": 4.8504,
    "out:LCP/MSEK": 2.532836,
    "out:ROI% old": 20.29476,
    "out:Payback discounted": 6,
    "out:Atemp": 3184,
    "out:Umean": 0.47,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 78,
    "out:Energy use kWh/m2": 78,
    "out:Energy savings %": 134.6,
    "out:Op energy cost/MSEK": 34,
    "out:El price/MSEK": 25,
    "out:Heating price/MSEK": 8,
    "out:DH+ DHW/kWh/m2": 39,
    "out:El bought/kWh/m2": 2726.02442,
    "out:Return %": 18,
    "out:Return/kSEK/y": 1092,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.6502792112,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 33.36,
    "out:EAHP (El)": 24.073706,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 415501.216226,
    "out:EL kWh savings": -83972.262638,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 79599.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 247556.69,
    "out:DH kr savings": 415501.21622580703,
    "out:El kr savings": -167944.52527693732,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 124413.721398,
    "out:% savings (space heating)": 69.442193,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 997124.4110739925,
    "out:Eupp": 31.9,
    "out:Etvv": 0,
    "out:Ef": 37.07,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 6021483.926577743,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 14.039376,
    "out:EL CO2": 5.140815,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 5.140815,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01001001100001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 39.736358,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 38.368581,
    "out:Electricity (inc PV)": 38.368581,
    "out:Total Energy Use Pre PV": 78.368581,
    "out:Total Energy Use Post PV": 78.368581,
    "out:Primary Energy": 95.247448,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 17.091925,
    "out:CO2 Operational/m2": 19.46432,
    "out:Total CO2/m2": 36.556245,
    "out:Total CO2 (tons)": 116.395049,
    "out:Klimatpaverkan": -83.740372,
    "out:Initial Cost/MSEK": 6.215099951577741,
    "out:Running cost/(Apt SEK y)": 13382.085106,
    "out:Running Cost over RSP/MSEK": 4.89732,
    "out:LCP/MSEK": 2.2923,
    "out:ROI% old": 19.554679,
    "out:Payback discounted": 6,
    "out:Atemp": 3184,
    "out:Umean": 0.47,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 78,
    "out:Energy use kWh/m2": 78,
    "out:Energy savings %": 134.6,
    "out:Op energy cost/MSEK": 35,
    "out:El price/MSEK": 26,
    "out:Heating price/MSEK": 8,
    "out:DH+ DHW/kWh/m2": 40,
    "out:El bought/kWh/m2": 2685.80067,
    "out:Return %": 17,
    "out:Return/kSEK/y": 1086,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.6502792112,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 28.356,
    "out:EAHP (El)": 23.499081,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 413394.41182,
    "out:EL kWh savings": -82142.657189,
    "out:Sewage heat recovery cost": 273216,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 249109.1,
    "out:DH kr savings": 413394.41181970126,
    "out:El kr savings": -164285.31437791884,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 126520.525805,
    "out:% savings (space heating)": 68.924731,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 997124.4110739925,
    "out:Eupp": 33.67,
    "out:Etvv": 0,
    "out:Ef": 36.77,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 6215099.951577742,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 14.39936,
    "out:EL CO2": 5.06496,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 5.06496,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01001001100001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 36.32098,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 38.261295,
    "out:Electricity (inc PV)": 38.261295,
    "out:Total Energy Use Pre PV": 74.261295,
    "out:Total Energy Use Post PV": 74.261295,
    "out:Primary Energy": 92.490087,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 17.091925,
    "out:CO2 Operational/m2": 18.010221,
    "out:Total CO2/m2": 35.102146,
    "out:Total CO2 (tons)": 111.765199,
    "out:Klimatpaverkan": -88.370222,
    "out:Initial Cost/MSEK": 6.294699926577742,
    "out:Running cost/(Apt SEK y)": 12549.191489,
    "out:Running Cost over RSP/MSEK": 4.5912,
    "out:LCP/MSEK": 2.51882,
    "out:ROI% old": 20.002134,
    "out:Payback discounted": 6,
    "out:Atemp": 3184,
    "out:Umean": 0.47,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 74,
    "out:Energy use kWh/m2": 74,
    "out:Energy savings %": 147.3,
    "out:Op energy cost/MSEK": 32,
    "out:El price/MSEK": 24,
    "out:Heating price/MSEK": 7,
    "out:DH+ DHW/kWh/m2": 36,
    "out:El bought/kWh/m2": 2678.29065,
    "out:Return %": 18,
    "out:Return/kSEK/y": 1125,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.6502792112,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 28.356,
    "out:EAHP (El)": 23.391795,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 424268.9721,
    "out:EL kWh savings": -81801.058668,
    "out:Sewage heat recovery cost": 273216,
    "out:Balancing hydronic system cost": 79599.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 260666.85,
    "out:DH kr savings": 424268.97209976916,
    "out:El kr savings": -163602.11733547872,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 115645.965525,
    "out:% savings (space heating)": 71.59568,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 997124.4110739925,
    "out:Eupp": 30.37,
    "out:Etvv": 0,
    "out:Ef": 36.62,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 6294699.926577742,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 12.959424,
    "out:EL CO2": 5.050797,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 5.050797,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01001001110000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 42.536828,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 39.036318,
    "out:Electricity (inc PV)": 29.189233,
    "out:Total Energy Use Pre PV": 82.036318,
    "out:Total Energy Use Post PV": 72.189233,
    "out:Primary Energy": 79.943706,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 31.122482,
    "out:CO2 Operational/m2": 2.012417,
    "out:Total CO2/m2": 33.134899,
    "out:Total CO2 (tons)": 105.501487,
    "out:Klimatpaverkan": -94.633934,
    "out:Initial Cost/MSEK": 6.732104345800243,
    "out:Running cost/(Apt SEK y)": 12397.638298,
    "out:Running Cost over RSP/MSEK": 4.5516,
    "out:LCP/MSEK": 2.121016,
    "out:ROI% old": 18.786569,
    "out:Payback discounted": 7,
    "out:Atemp": 3184,
    "out:Umean": 0.47,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 72,
    "out:Energy use kWh/m2": 72,
    "out:Energy savings %": 154.2,
    "out:Op energy cost/MSEK": 33,
    "out:El price/MSEK": 23,
    "out:Heating price/MSEK": 8,
    "out:DH+ DHW/kWh/m2": 43,
    "out:El bought/kWh/m2": 2064.03281,
    "out:Return %": 17,
    "out:Return/kSEK/y": 1132,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.6502792112,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 33.36,
    "out:EAHP (El)": 24.166818,
    "out:PV (kr/kWp)": 17461.56255567083,
    "out:PV (kWp installed)": 45.25485,
    "out:PV (m2 panels)": 237.433632,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 42461.284501,
    "out:PV (kWh sold)": 11108.17537,
    "out:PV (% sold (El))": 26.160714,
    "out:PV (kWh self-consumed)": 31353.109131,
    "out:PV (ratio sold/self-consumed)": 0.354293,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 404477.718023,
    "out:EL kWh savings": -52915.621951,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 315308.74,
    "out:DH kr savings": 404477.7180225515,
    "out:El kr savings": -105831.24390154958,
    "out:El kr sold": 16662.26305558694,
    "out:El kr saved": 62706.218262,
    "out:El kr return": 79368.48131758693,
    "out:% solar/total": 78,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 135437.219602,
    "out:% savings (space heating)": 66.734662,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 997124.4110739925,
    "out:Eupp": 35.23,
    "out:Etvv": 0,
    "out:Ef": 27.62,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 6732104.345800243,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 15.479312,
    "out:EL CO2": -13.466895,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -17.329376,
    "out:Bought CO2": 3.862481,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01001001110000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 39.074673,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 38.943206,
    "out:Electricity (inc PV)": 29.109445,
    "out:Total Energy Use Pre PV": 77.943206,
    "out:Total Energy Use Post PV": 68.109445,
    "out:Primary Energy": 77.206912,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 31.122482,
    "out:CO2 Operational/m2": 0.494224,
    "out:Total CO2/m2": 31.616706,
    "out:Total CO2 (tons)": 100.667562,
    "out:Klimatpaverkan": -99.467859,
    "out:Initial Cost/MSEK": 6.811704320800244,
    "out:Running cost/(Apt SEK y)": 11567.106383,
    "out:Running Cost over RSP/MSEK": 4.24633,
    "out:LCP/MSEK": 2.346686,
    "out:ROI% old": 19.207255,
    "out:Payback discounted": 6,
    "out:Atemp": 3184,
    "out:Umean": 0.47,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 68,
    "out:Energy use kWh/m2": 68,
    "out:Energy savings %": 169.1,
    "out:Op energy cost/MSEK": 31,
    "out:El price/MSEK": 22,
    "out:Heating price/MSEK": 8,
    "out:DH+ DHW/kWh/m2": 39,
    "out:El bought/kWh/m2": 2058.379257,
    "out:Return %": 17,
    "out:Return/kSEK/y": 1171,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.6502792112,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 33.36,
    "out:EAHP (El)": 24.073706,
    "out:PV (kr/kWp)": 17461.56255567083,
    "out:PV (kWp installed)": 45.25485,
    "out:PV (m2 panels)": 237.433632,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 42461.284501,
    "out:PV (kWh sold)": 11150.597787,
    "out:PV (% sold (El))": 26.260623,
    "out:PV (kWh self-consumed)": 31310.686714,
    "out:PV (ratio sold/self-consumed)": 0.356128,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 415501.216226,
    "out:EL kWh savings": -52661.577035,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 79599.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 326903.96,
    "out:DH kr savings": 415501.21622580703,
    "out:El kr savings": -105323.15407042333,
    "out:El kr sold": 16725.896680751328,
    "out:El kr saved": 62621.373428,
    "out:El kr return": 79347.27010875133,
    "out:% solar/total": 78,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 124413.721398,
    "out:% savings (space heating)": 69.442193,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 997124.4110739925,
    "out:Eupp": 31.9,
    "out:Etvv": 0,
    "out:Ef": 27.51,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 6811704.320800244,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 14.039376,
    "out:EL CO2": -13.545152,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -17.397065,
    "out:Bought CO2": 3.851913,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01001001110001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 39.736358,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 38.368581,
    "out:Electricity (inc PV)": 28.617987,
    "out:Total Energy Use Pre PV": 78.368581,
    "out:Total Energy Use Post PV": 68.617987,
    "out:Primary Energy": 77.696379,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 31.831025,
    "out:CO2 Operational/m2": 0.366878,
    "out:Total CO2/m2": 32.197903,
    "out:Total CO2 (tons)": 102.518092,
    "out:Klimatpaverkan": -97.617329,
    "out:Initial Cost/MSEK": 7.005320345800243,
    "out:Running cost/(Apt SEK y)": 11696.659574,
    "out:Running Cost over RSP/MSEK": 4.29426,
    "out:LCP/MSEK": 2.10514,
    "out:ROI% old": 18.578655,
    "out:Payback discounted": 7,
    "out:Atemp": 3184,
    "out:Umean": 0.47,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 69,
    "out:Energy use kWh/m2": 69,
    "out:Energy savings %": 165.2,
    "out:Op energy cost/MSEK": 31,
    "out:El price/MSEK": 22,
    "out:Heating price/MSEK": 8,
    "out:DH+ DHW/kWh/m2": 40,
    "out:El bought/kWh/m2": 2023.541091,
    "out:Return %": 17,
    "out:Return/kSEK/y": 1165,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.6502792112,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 28.356,
    "out:EAHP (El)": 23.499081,
    "out:PV (kr/kWp)": 17461.56255567083,
    "out:PV (kWp installed)": 45.25485,
    "out:PV (m2 panels)": 237.433632,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 42461.284501,
    "out:PV (kWh sold)": 11415.403018,
    "out:PV (% sold (El))": 26.884262,
    "out:PV (kWh self-consumed)": 31045.881483,
    "out:PV (ratio sold/self-consumed)": 0.367695,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 413394.41182,
    "out:EL kWh savings": -51096.775234,
    "out:Sewage heat recovery cost": 273216,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 328323.97,
    "out:DH kr savings": 413394.41181970126,
    "out:El kr savings": -102193.5504680569,
    "out:El kr sold": 17123.10452688149,
    "out:El kr saved": 62091.762966,
    "out:El kr return": 79214.86749288149,
    "out:% solar/total": 78,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 126520.525805,
    "out:% savings (space heating)": 68.924731,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 997124.4110739925,
    "out:Eupp": 33.67,
    "out:Etvv": 0,
    "out:Ef": 27.26,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 7005320.345800243,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 14.39936,
    "out:EL CO2": -14.032482,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -17.819295,
    "out:Bought CO2": 3.786813,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01001001110001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 36.32098,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 38.261295,
    "out:Electricity (inc PV)": 28.52639,
    "out:Total Energy Use Pre PV": 74.261295,
    "out:Total Energy Use Post PV": 64.52639,
    "out:Primary Energy": 74.967258,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 31.831025,
    "out:CO2 Operational/m2": -1.164905,
    "out:Total CO2/m2": 30.66612,
    "out:Total CO2 (tons)": 97.640897,
    "out:Klimatpaverkan": -102.494524,
    "out:Initial Cost/MSEK": 7.084920320800244,
    "out:Running cost/(Apt SEK y)": 10864.297872,
    "out:Running Cost over RSP/MSEK": 3.98832,
    "out:LCP/MSEK": 2.33148,
    "out:ROI% old": 18.986805,
    "out:Payback discounted": 6,
    "out:Atemp": 3184,
    "out:Umean": 0.47,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 65,
    "out:Energy use kWh/m2": 65,
    "out:Energy savings %": 181.5,
    "out:Op energy cost/MSEK": 29,
    "out:El price/MSEK": 20,
    "out:Heating price/MSEK": 7,
    "out:DH+ DHW/kWh/m2": 36,
    "out:El bought/kWh/m2": 2017.046716,
    "out:Return %": 17,
    "out:Return/kSEK/y": 1204,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.6502792112,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 28.356,
    "out:EAHP (El)": 23.391795,
    "out:PV (kr/kWp)": 17461.56255567083,
    "out:PV (kWp installed)": 45.25485,
    "out:PV (m2 panels)": 237.433632,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 42461.284501,
    "out:PV (kWh sold)": 11465.356847,
    "out:PV (% sold (El))": 27.001908,
    "out:PV (kWh self-consumed)": 30995.927654,
    "out:PV (ratio sold/self-consumed)": 0.369899,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 424268.9721,
    "out:EL kWh savings": -50805.130474,
    "out:Sewage heat recovery cost": 273216,
    "out:Balancing hydronic system cost": 79599.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 339856.75,
    "out:DH kr savings": 424268.97209976916,
    "out:El kr savings": -101610.2609475567,
    "out:El kr sold": 17198.035271101842,
    "out:El kr saved": 61991.855308,
    "out:El kr return": 79189.89057910183,
    "out:% solar/total": 77,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 115645.965525,
    "out:% savings (space heating)": 71.59568,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 997124.4110739925,
    "out:Eupp": 30.37,
    "out:Etvv": 0,
    "out:Ef": 27.13,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 7084920.320800244,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 12.959424,
    "out:EL CO2": -14.124329,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -17.89901,
    "out:Bought CO2": 3.774681,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01001001120000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 42.536828,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 39.036318,
    "out:Electricity (inc PV)": 26.440465,
    "out:Total Energy Use Pre PV": 82.036318,
    "out:Total Energy Use Post PV": 69.440465,
    "out:Primary Energy": 74.995924,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 45.861581,
    "out:CO2 Operational/m2": -52.054288,
    "out:Total CO2/m2": -6.192707,
    "out:Total CO2 (tons)": -19.717573,
    "out:Klimatpaverkan": -219.852994,
    "out:Initial Cost/MSEK": 7.522317697521494,
    "out:Running cost/(Apt SEK y)": 10949.382979,
    "out:Running Cost over RSP/MSEK": 4.03367,
    "out:LCP/MSEK": 1.848732,
    "out:ROI% old": 17.796662,
    "out:Payback discounted": 7,
    "out:Atemp": 3184,
    "out:Umean": 0.47,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 69,
    "out:Energy use kWh/m2": 69,
    "out:Energy savings %": 165.2,
    "out:Op energy cost/MSEK": 30,
    "out:El price/MSEK": 20,
    "out:Heating price/MSEK": 8,
    "out:DH+ DHW/kWh/m2": 43,
    "out:El bought/kWh/m2": 1868.457348,
    "out:Return %": 16,
    "out:Return/kSEK/y": 1200,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.6502792112,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 33.36,
    "out:EAHP (El)": 24.166818,
    "out:PV (kr/kWp)": 17461.48474631725,
    "out:PV (kWp installed)": 90.5097,
    "out:PV (m2 panels)": 474.867263,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 84922.568824,
    "out:PV (kWh sold)": 44817.383742,
    "out:PV (% sold (El))": 52.774409,
    "out:PV (kWh self-consumed)": 40105.185082,
    "out:PV (ratio sold/self-consumed)": 1.117496,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 404477.718023,
    "out:EL kWh savings": -44163.547272,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 383376.7,
    "out:DH kr savings": 404477.7180225515,
    "out:El kr savings": -88327.09454418917,
    "out:El kr sold": 67226.07561312127,
    "out:El kr saved": 80210.370164,
    "out:El kr return": 147436.4457771213,
    "out:% solar/total": 100,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 135437.219602,
    "out:% savings (space heating)": 66.734662,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 997124.4110739925,
    "out:Eupp": 35.23,
    "out:Etvv": 0,
    "out:Ef": 25.03,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 7522317.697521494,
    "out:Seasonal Variation ROI (%)": 5,
    "out:Seasonal Variation Payback": 20,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 15.479312,
    "out:EL CO2": -67.5336,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -71.03177,
    "out:Bought CO2": 3.49817,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01001001120000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 39.074673,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 38.943206,
    "out:Electricity (inc PV)": 26.368884,
    "out:Total Energy Use Pre PV": 77.943206,
    "out:Total Energy Use Post PV": 65.368884,
    "out:Primary Energy": 72.273902,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 45.861581,
    "out:CO2 Operational/m2": -53.614211,
    "out:Total CO2/m2": -7.75263,
    "out:Total CO2 (tons)": -24.684366,
    "out:Klimatpaverkan": -224.819788,
    "out:Initial Cost/MSEK": 7.601917672521495,
    "out:Running cost/(Apt SEK y)": 10119.148936,
    "out:Running Cost over RSP/MSEK": 3.7285,
    "out:LCP/MSEK": 2.074302,
    "out:ROI% old": 18.183795,
    "out:Payback discounted": 7,
    "out:Atemp": 3184,
    "out:Umean": 0.47,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 65,
    "out:Energy use kWh/m2": 65,
    "out:Energy savings %": 181.5,
    "out:Op energy cost/MSEK": 27,
    "out:El price/MSEK": 18,
    "out:Heating price/MSEK": 8,
    "out:DH+ DHW/kWh/m2": 39,
    "out:El bought/kWh/m2": 1863.384631,
    "out:Return %": 16,
    "out:Return/kSEK/y": 1239,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.6502792112,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 33.36,
    "out:EAHP (El)": 24.073706,
    "out:PV (kr/kWp)": 17461.48474631725,
    "out:PV (kWp installed)": 90.5097,
    "out:PV (m2 panels)": 474.867263,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 84922.568824,
    "out:PV (kWh sold)": 44885.940086,
    "out:PV (% sold (El))": 52.855137,
    "out:PV (kWh self-consumed)": 40036.628738,
    "out:PV (ratio sold/self-consumed)": 1.121122,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 415501.216226,
    "out:EL kWh savings": -43935.633437,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 79599.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 394958.86,
    "out:DH kr savings": 415501.21622580703,
    "out:El kr savings": -87871.2668733383,
    "out:El kr sold": 67328.91012884855,
    "out:El kr saved": 80073.257476,
    "out:El kr return": 147402.16760484857,
    "out:% solar/total": 100,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 124413.721398,
    "out:% savings (space heating)": 69.442193,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 997124.4110739925,
    "out:Eupp": 31.9,
    "out:Etvv": 0,
    "out:Ef": 24.93,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 7601917.672521494,
    "out:Seasonal Variation ROI (%)": 5,
    "out:Seasonal Variation Payback": 20,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 14.039376,
    "out:EL CO2": -67.653587,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -71.14228,
    "out:Bought CO2": 3.488693,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01001001120001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 39.736358,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 38.368581,
    "out:Electricity (inc PV)": 25.927782,
    "out:Total Energy Use Pre PV": 78.368581,
    "out:Total Energy Use Post PV": 65.927782,
    "out:Primary Energy": 72.85401,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 46.570124,
    "out:CO2 Operational/m2": -53.9982,
    "out:Total CO2/m2": -7.428076,
    "out:Total CO2 (tons)": -23.650987,
    "out:Klimatpaverkan": -223.786408,
    "out:Initial Cost/MSEK": 7.795533697521493,
    "out:Running cost/(Apt SEK y)": 10250.404255,
    "out:Running Cost over RSP/MSEK": 3.77705,
    "out:LCP/MSEK": 1.832136,
    "out:ROI% old": 17.643198,
    "out:Payback discounted": 7,
    "out:Atemp": 3184,
    "out:Umean": 0.47,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 66,
    "out:Energy use kWh/m2": 66,
    "out:Energy savings %": 177.3,
    "out:Op energy cost/MSEK": 28,
    "out:El price/MSEK": 19,
    "out:Heating price/MSEK": 8,
    "out:DH+ DHW/kWh/m2": 40,
    "out:El bought/kWh/m2": 1832.126869,
    "out:Return %": 16,
    "out:Return/kSEK/y": 1233,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.6502792112,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 28.356,
    "out:EAHP (El)": 23.499081,
    "out:PV (kr/kWp)": 17461.48474631725,
    "out:PV (kWp installed)": 90.5097,
    "out:PV (m2 panels)": 474.867263,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 84922.568824,
    "out:PV (kWh sold)": 45311.076033,
    "out:PV (% sold (El))": 53.355753,
    "out:PV (kWh self-consumed)": 39611.492791,
    "out:PV (ratio sold/self-consumed)": 1.143887,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 413394.41182,
    "out:EL kWh savings": -42531.165091,
    "out:Sewage heat recovery cost": 273216,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 396298.7,
    "out:DH kr savings": 413394.41181970126,
    "out:El kr savings": -85062.33018248974,
    "out:El kr sold": 67966.61404900311,
    "out:El kr saved": 79222.985582,
    "out:El kr return": 147189.5996310031,
    "out:% solar/total": 99,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 126520.525805,
    "out:% savings (space heating)": 68.924731,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 997124.4110739925,
    "out:Eupp": 33.67,
    "out:Etvv": 0,
    "out:Ef": 24.7,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 7795533.697521493,
    "out:Seasonal Variation ROI (%)": 5,
    "out:Seasonal Variation Payback": 20,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 14.39936,
    "out:EL CO2": -68.39756,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -71.827857,
    "out:Bought CO2": 3.430297,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01001001120001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 36.32098,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 38.261295,
    "out:Electricity (inc PV)": 25.845512,
    "out:Total Energy Use Pre PV": 74.261295,
    "out:Total Energy Use Post PV": 61.845512,
    "out:Primary Energy": 70.141678,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 46.570124,
    "out:CO2 Operational/m2": -55.577678,
    "out:Total CO2/m2": -9.007554,
    "out:Total CO2 (tons)": -28.680043,
    "out:Klimatpaverkan": -228.815464,
    "out:Initial Cost/MSEK": 7.875133672521494,
    "out:Running cost/(Apt SEK y)": 9418.340426,
    "out:Running Cost over RSP/MSEK": 3.47123,
    "out:LCP/MSEK": 2.058356,
    "out:ROI% old": 18.019631,
    "out:Payback discounted": 7,
    "out:Atemp": 3184,
    "out:Umean": 0.47,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 62,
    "out:Energy use kWh/m2": 62,
    "out:Energy savings %": 195.2,
    "out:Op energy cost/MSEK": 25,
    "out:El price/MSEK": 17,
    "out:Heating price/MSEK": 7,
    "out:DH+ DHW/kWh/m2": 36,
    "out:El bought/kWh/m2": 1826.299946,
    "out:Return %": 16,
    "out:Return/kSEK/y": 1272,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.6502792112,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 28.356,
    "out:EAHP (El)": 23.391795,
    "out:PV (kr/kWp)": 17461.48474631725,
    "out:PV (kWp installed)": 90.5097,
    "out:PV (m2 panels)": 474.867263,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 84922.568824,
    "out:PV (kWh sold)": 45390.727665,
    "out:PV (% sold (El))": 53.449546,
    "out:PV (kWh self-consumed)": 39531.841159,
    "out:PV (ratio sold/self-consumed)": 1.148207,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 424268.9721,
    "out:EL kWh savings": -42269.21749,
    "out:Sewage heat recovery cost": 273216,
    "out:Balancing hydronic system cost": 79599.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 407816.63,
    "out:DH kr savings": 424268.97209976916,
    "out:El kr savings": -84538.43498011908,
    "out:El kr sold": 68086.09149762098,
    "out:El kr saved": 79063.682318,
    "out:El kr return": 147149.77381562098,
    "out:% solar/total": 99,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 115645.965525,
    "out:% savings (space heating)": 71.59568,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 997124.4110739925,
    "out:Eupp": 30.37,
    "out:Etvv": 0,
    "out:Ef": 24.6,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 7875133.672521494,
    "out:Seasonal Variation ROI (%)": 5,
    "out:Seasonal Variation Payback": 20,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 12.959424,
    "out:EL CO2": -68.537102,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -71.956513,
    "out:Bought CO2": 3.419411,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01001002000000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 5.344287,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 52.834057,
    "out:Electricity (inc PV)": 52.834057,
    "out:Total Energy Use Pre PV": 57.834057,
    "out:Total Energy Use Post PV": 57.834057,
    "out:Primary Energy": 97.096392,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 16.927114,
    "out:CO2 Operational/m2": 8.774438,
    "out:Total CO2/m2": 25.701552,
    "out:Total CO2 (tons)": 81.833717,
    "out:Klimatpaverkan": -118.301704,
    "out:Initial Cost/MSEK": 7.4089612640777425,
    "out:Running cost/(Apt SEK y)": 8181.404255,
    "out:Running Cost over RSP/MSEK": 2.97707,
    "out:LCP/MSEK": 3.018689,
    "out:ROI% old": 20.10625,
    "out:Payback discounted": 6,
    "out:Atemp": 3184,
    "out:Umean": 0.47,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 58,
    "out:Energy use kWh/m2": 58,
    "out:Energy savings %": 215.5,
    "out:Op energy cost/MSEK": 20,
    "out:El price/MSEK": 19,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 5,
    "out:El bought/kWh/m2": 3698.38399,
    "out:Return %": 18,
    "out:Return/kSEK/y": 1330,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 1774800,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 88740,
    "out:EAHP (replacement cost)": 1325203.921671023,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 41.7,
    "out:EAHP (El)": 37.964557,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 522898.732936,
    "out:EL kWh savings": -128200.718915,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 266497.3,
    "out:DH kr savings": 522898.7329360972,
    "out:El kr savings": -256401.43783006677,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 17016.204688,
    "out:% savings (space heating)": 95.820574,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 997124.4110739925,
    "out:Eupp": 2.57,
    "out:Etvv": 0,
    "out:Ef": 48.62,
    "out:heatPumpQuantity": 12,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 7408961.264077743,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 1.79992,
    "out:EL CO2": 6.974518,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 6.974518,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01001002000000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 3.794442,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 52.161458,
    "out:Electricity (inc PV)": 52.161458,
    "out:Total Energy Use Pre PV": 56.161458,
    "out:Total Energy Use Post PV": 56.161458,
    "out:Primary Energy": 95.179332,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 16.927114,
    "out:CO2 Operational/m2": 8.325666,
    "out:Total CO2/m2": 25.25278,
    "out:Total CO2 (tons)": 80.404827,
    "out:Klimatpaverkan": -119.730594,
    "out:Initial Cost/MSEK": 7.488561239077743,
    "out:Running cost/(Apt SEK y)": 7885.680851,
    "out:Running Cost over RSP/MSEK": 2.86874,
    "out:LCP/MSEK": 3.047419,
    "out:ROI% old": 20.099188,
    "out:Payback discounted": 6,
    "out:Atemp": 3184,
    "out:Umean": 0.47,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 56,
    "out:Energy use kWh/m2": 56,
    "out:Energy savings %": 226.8,
    "out:Op energy cost/MSEK": 19,
    "out:El price/MSEK": 18,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 4,
    "out:El bought/kWh/m2": 3651.30206,
    "out:Return %": 18,
    "out:Return/kSEK/y": 1344,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 1774800,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 88740,
    "out:EAHP (replacement cost)": 1325203.921671023,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 41.7,
    "out:EAHP (El)": 37.291958,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 527833.437931,
    "out:EL kWh savings": -126059.164343,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 79599.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 275715.11,
    "out:DH kr savings": 527833.4379313457,
    "out:El kr savings": -252118.32868676647,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 12081.499693,
    "out:% savings (space heating)": 97.032609,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 997124.4110739925,
    "out:Eupp": 1.66,
    "out:Etvv": 0,
    "out:Ef": 47.75,
    "out:heatPumpQuantity": 12,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 7488561.239077743,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 1.439936,
    "out:EL CO2": 6.88573,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 6.88573,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01001002000001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 4.132331,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 51.305862,
    "out:Electricity (inc PV)": 51.305862,
    "out:Total Energy Use Pre PV": 55.305862,
    "out:Total Energy Use Post PV": 55.305862,
    "out:Primary Energy": 94.011129,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 17.635657,
    "out:CO2 Operational/m2": 8.21272,
    "out:Total CO2/m2": 25.848377,
    "out:Total CO2 (tons)": 82.301208,
    "out:Klimatpaverkan": -117.834213,
    "out:Initial Cost/MSEK": 7.682177264077742,
    "out:Running cost/(Apt SEK y)": 7769.744681,
    "out:Running Cost over RSP/MSEK": 2.82662,
    "out:LCP/MSEK": 2.895923,
    "out:ROI% old": 19.670949,
    "out:Payback discounted": 6,
    "out:Atemp": 3184,
    "out:Umean": 0.47,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 55,
    "out:Energy use kWh/m2": 55,
    "out:Energy savings %": 232.7,
    "out:Op energy cost/MSEK": 19,
    "out:El price/MSEK": 18,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 4,
    "out:El bought/kWh/m2": 3591.41034,
    "out:Return %": 18,
    "out:Return/kSEK/y": 1350,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 1774800,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 88740,
    "out:EAHP (replacement cost)": 1325203.921671023,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 35.445,
    "out:EAHP (El)": 36.436362,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 526757.599679,
    "out:EL kWh savings": -123334.947499,
    "out:Sewage heat recovery cost": 273216,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 280087.7,
    "out:DH kr savings": 526757.5996790434,
    "out:El kr savings": -246669.8949980884,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 13157.337945,
    "out:% savings (space heating)": 96.768368,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 997124.4110739925,
    "out:Eupp": 2.14,
    "out:Etvv": 0,
    "out:Ef": 47.61,
    "out:heatPumpQuantity": 12,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 7682177.264077743,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 1.439936,
    "out:EL CO2": 6.772784,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 6.772784,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01001002000001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 2.825415,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 50.559648,
    "out:Electricity (inc PV)": 50.559648,
    "out:Total Energy Use Pre PV": 53.559648,
    "out:Total Energy Use Post PV": 53.559648,
    "out:Primary Energy": 92.065167,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 17.635657,
    "out:CO2 Operational/m2": 7.75423,
    "out:Total CO2/m2": 25.389887,
    "out:Total CO2 (tons)": 80.841376,
    "out:Klimatpaverkan": -119.294045,
    "out:Initial Cost/MSEK": 7.761777239077743,
    "out:Running cost/(Apt SEK y)": 7464.06383,
    "out:Running Cost over RSP/MSEK": 2.71467,
    "out:LCP/MSEK": 2.928273,
    "out:ROI% old": 19.675262,
    "out:Payback discounted": 6,
    "out:Atemp": 3184,
    "out:Umean": 0.47,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 54,
    "out:Energy use kWh/m2": 54,
    "out:Energy savings %": 238.9,
    "out:Op energy cost/MSEK": 18,
    "out:El price/MSEK": 17,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 3,
    "out:El bought/kWh/m2": 3539.17536,
    "out:Return %": 18,
    "out:Return/kSEK/y": 1364,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 1774800,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 88740,
    "out:EAHP (replacement cost)": 1325203.921671023,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 35.445,
    "out:EAHP (El)": 35.690148,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 530918.818971,
    "out:EL kWh savings": -120959.002838,
    "out:Sewage heat recovery cost": 273216,
    "out:Balancing hydronic system cost": 79599.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 289000.81,
    "out:DH kr savings": 530918.8189710178,
    "out:El kr savings": -241918.00567583443,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 8996.118653,
    "out:% savings (space heating)": 97.790423,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 997124.4110739925,
    "out:Eupp": 1.36,
    "out:Etvv": 0,
    "out:Ef": 46.7,
    "out:heatPumpQuantity": 12,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 7761777.239077743,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 1.079952,
    "out:EL CO2": 6.674278,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 6.674278,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01001002010000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 5.344287,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 52.834057,
    "out:Electricity (inc PV)": 41.346088,
    "out:Total Energy Use Pre PV": 57.834057,
    "out:Total Energy Use Post PV": 46.346088,
    "out:Primary Energy": 76.418047,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 31.666214,
    "out:CO2 Operational/m2": -1.731905,
    "out:Total CO2/m2": 29.934309,
    "out:Total CO2 (tons)": 95.310811,
    "out:Klimatpaverkan": -104.82461,
    "out:Initial Cost/MSEK": 8.199181658300244,
    "out:Running cost/(Apt SEK y)": 6437.12766,
    "out:Running Cost over RSP/MSEK": 2.35309,
    "out:LCP/MSEK": 2.852448,
    "out:ROI% old": 19.255632,
    "out:Payback discounted": 6,
    "out:Atemp": 3184,
    "out:Umean": 0.47,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 46,
    "out:Energy use kWh/m2": 46,
    "out:Energy savings %": 297.8,
    "out:Op energy cost/MSEK": 16,
    "out:El price/MSEK": 15,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 5,
    "out:El bought/kWh/m2": 2925.262343,
    "out:Return %": 17,
    "out:Return/kSEK/y": 1412,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 1774800,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 88740,
    "out:EAHP (replacement cost)": 1325203.921671023,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 41.7,
    "out:EAHP (El)": 37.964557,
    "out:PV (kr/kWp)": 17461.56255567083,
    "out:PV (kWp installed)": 45.25485,
    "out:PV (m2 panels)": 237.433632,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 42461.284501,
    "out:PV (kWh sold)": 5883.60255,
    "out:PV (% sold (El))": 13.856393,
    "out:PV (kWh self-consumed)": 36577.681952,
    "out:PV (ratio sold/self-consumed)": 0.160852,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 522898.732936,
    "out:EL kWh savings": -91623.036625,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 348478.06,
    "out:DH kr savings": 522898.7329360972,
    "out:El kr savings": -183246.07324901543,
    "out:El kr sold": 8825.403824664529,
    "out:El kr saved": 73155.363904,
    "out:El kr return": 81980.76772866453,
    "out:% solar/total": 91,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 17016.204688,
    "out:% savings (space heating)": 95.820574,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 997124.4110739925,
    "out:Eupp": 2.57,
    "out:Etvv": 0,
    "out:Ef": 37.56,
    "out:heatPumpQuantity": 12,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 8199181.658300244,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 1.79992,
    "out:EL CO2": -3.531825,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -9.00365,
    "out:Bought CO2": 5.471825,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01001002010000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 3.794442,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 52.161458,
    "out:Electricity (inc PV)": 40.739554,
    "out:Total Energy Use Pre PV": 56.161458,
    "out:Total Energy Use Post PV": 44.739554,
    "out:Primary Energy": 74.619905,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 31.666214,
    "out:CO2 Operational/m2": -2.502945,
    "out:Total CO2/m2": 29.163269,
    "out:Total CO2 (tons)": 92.855821,
    "out:Klimatpaverkan": -107.2796,
    "out:Initial Cost/MSEK": 8.278781633300243,
    "out:Running cost/(Apt SEK y)": 6143.638298,
    "out:Running Cost over RSP/MSEK": 2.24555,
    "out:LCP/MSEK": 2.880388,
    "out:ROI% old": 19.25606,
    "out:Payback discounted": 6,
    "out:Atemp": 3184,
    "out:Umean": 0.47,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 45,
    "out:Energy use kWh/m2": 45,
    "out:Energy savings %": 306.7,
    "out:Op energy cost/MSEK": 15,
    "out:El price/MSEK": 14,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 4,
    "out:El bought/kWh/m2": 2882.217176,
    "out:Return %": 17,
    "out:Return/kSEK/y": 1426,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 1774800,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 88740,
    "out:EAHP (replacement cost)": 1325203.921671023,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 41.7,
    "out:EAHP (El)": 37.291958,
    "out:PV (kr/kWp)": 17461.56255567083,
    "out:PV (kWp installed)": 45.25485,
    "out:PV (m2 panels)": 237.433632,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 42461.284501,
    "out:PV (kWh sold)": 6093.954499,
    "out:PV (% sold (El))": 14.35179,
    "out:PV (kWh self-consumed)": 36367.330003,
    "out:PV (ratio sold/self-consumed)": 0.167567,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 527833.437931,
    "out:EL kWh savings": -89691.83295,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 79599.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 357590.7,
    "out:DH kr savings": 527833.4379313457,
    "out:El kr savings": -179383.66589913456,
    "out:El kr sold": 9140.931748039846,
    "out:El kr saved": 72734.660006,
    "out:El kr return": 81875.59175403985,
    "out:% solar/total": 91,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 12081.499693,
    "out:% savings (space heating)": 97.032609,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 997124.4110739925,
    "out:Eupp": 1.66,
    "out:Etvv": 0,
    "out:Ef": 36.79,
    "out:heatPumpQuantity": 12,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 8278781.633300243,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 1.439936,
    "out:EL CO2": -3.942881,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -9.334343,
    "out:Bought CO2": 5.391462,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01001002010001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 4.132331,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 51.305862,
    "out:Electricity (inc PV)": 39.969812,
    "out:Total Energy Use Pre PV": 55.305862,
    "out:Total Energy Use Post PV": 43.969812,
    "out:Primary Energy": 73.606239,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 32.374757,
    "out:CO2 Operational/m2": -3.037763,
    "out:Total CO2/m2": 29.336994,
    "out:Total CO2 (tons)": 93.408961,
    "out:Klimatpaverkan": -106.72646,
    "out:Initial Cost/MSEK": 8.472397658300244,
    "out:Running cost/(Apt SEK y)": 6030.617021,
    "out:Running Cost over RSP/MSEK": 2.20446,
    "out:LCP/MSEK": 2.727862,
    "out:ROI% old": 18.885293,
    "out:Payback discounted": 7,
    "out:Atemp": 3184,
    "out:Umean": 0.47,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 44,
    "out:Energy use kWh/m2": 44,
    "out:Energy savings %": 315.9,
    "out:Op energy cost/MSEK": 15,
    "out:El price/MSEK": 14,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 4,
    "out:El bought/kWh/m2": 2827.617201,
    "out:Return %": 17,
    "out:Return/kSEK/y": 1431,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 1774800,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 88740,
    "out:EAHP (replacement cost)": 1325203.921671023,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 35.445,
    "out:EAHP (El)": 36.436362,
    "out:PV (kr/kWp)": 17461.56255567083,
    "out:PV (kWp installed)": 45.25485,
    "out:PV (m2 panels)": 237.433632,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 42461.284501,
    "out:PV (kWh sold)": 6367.313011,
    "out:PV (% sold (El))": 14.995573,
    "out:PV (kWh self-consumed)": 36093.97149,
    "out:PV (ratio sold/self-consumed)": 0.176409,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 526757.599679,
    "out:EL kWh savings": -87240.975159,
    "out:Sewage heat recovery cost": 273216,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 361826.62,
    "out:DH kr savings": 526757.5996790434,
    "out:El kr savings": -174481.95031796017,
    "out:El kr sold": 9550.969516907095,
    "out:El kr saved": 72187.94298,
    "out:El kr return": 81738.9124969071,
    "out:% solar/total": 90,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 13157.337945,
    "out:% savings (space heating)": 96.768368,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 997124.4110739925,
    "out:Eupp": 2.14,
    "out:Etvv": 0,
    "out:Ef": 36.67,
    "out:heatPumpQuantity": 12,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 8472397.658300243,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 1.439936,
    "out:EL CO2": -4.477699,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -9.767243,
    "out:Bought CO2": 5.289544,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01001002010001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 2.825415,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 50.559648,
    "out:Electricity (inc PV)": 39.299907,
    "out:Total Energy Use Pre PV": 53.559648,
    "out:Total Energy Use Post PV": 42.299907,
    "out:Primary Energy": 71.797634,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 32.374757,
    "out:CO2 Operational/m2": -3.873663,
    "out:Total CO2/m2": 28.501094,
    "out:Total CO2 (tons)": 90.747456,
    "out:Klimatpaverkan": -109.387965,
    "out:Initial Cost/MSEK": 8.551997633300243,
    "out:Running cost/(Apt SEK y)": 5727.510638,
    "out:Running Cost over RSP/MSEK": 2.09343,
    "out:LCP/MSEK": 2.759292,
    "out:ROI% old": 18.894984,
    "out:Payback discounted": 7,
    "out:Atemp": 3184,
    "out:Umean": 0.47,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 42,
    "out:Energy use kWh/m2": 42,
    "out:Energy savings %": 335.7,
    "out:Op energy cost/MSEK": 14,
    "out:El price/MSEK": 14,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 3,
    "out:El bought/kWh/m2": 2780.140109,
    "out:Return %": 17,
    "out:Return/kSEK/y": 1446,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 1774800,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 88740,
    "out:EAHP (replacement cost)": 1325203.921671023,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 35.445,
    "out:EAHP (El)": 35.690148,
    "out:PV (kr/kWp)": 17461.56255567083,
    "out:PV (kWp installed)": 45.25485,
    "out:PV (m2 panels)": 237.433632,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 42461.284501,
    "out:PV (kWh sold)": 6610.281135,
    "out:PV (% sold (El))": 15.567784,
    "out:PV (kWh self-consumed)": 35851.003367,
    "out:PV (ratio sold/self-consumed)": 0.184382,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 530918.818971,
    "out:EL kWh savings": -85107.998281,
    "out:Sewage heat recovery cost": 273216,
    "out:Balancing hydronic system cost": 79599.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 370618.24,
    "out:DH kr savings": 530918.8189710178,
    "out:El kr savings": -170215.99656149818,
    "out:El kr sold": 9915.421701949947,
    "out:El kr saved": 71702.006734,
    "out:El kr return": 81617.42843594994,
    "out:% solar/total": 90,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 8996.118653,
    "out:% savings (space heating)": 97.790423,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 997124.4110739925,
    "out:Eupp": 1.36,
    "out:Etvv": 0,
    "out:Ef": 35.87,
    "out:heatPumpQuantity": 12,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 8551997.633300243,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 1.079952,
    "out:EL CO2": -4.953615,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -10.154518,
    "out:Bought CO2": 5.200903,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01001002020000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 5.344287,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 52.834057,
    "out:Electricity (inc PV)": 37.349226,
    "out:Total Energy Use Pre PV": 57.834057,
    "out:Total Energy Use Post PV": 42.349226,
    "out:Primary Energy": 69.223696,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 46.405313,
    "out:CO2 Operational/m2": -49.463899,
    "out:Total CO2/m2": -3.058586,
    "out:Total CO2 (tons)": -9.738535,
    "out:Klimatpaverkan": -209.873956,
    "out:Initial Cost/MSEK": 8.989395010021493,
    "out:Running cost/(Apt SEK y)": 4946.595745,
    "out:Running Cost over RSP/MSEK": 1.81972,
    "out:LCP/MSEK": 2.595605,
    "out:ROI% old": 18.410583,
    "out:Payback discounted": 7,
    "out:Atemp": 3184,
    "out:Umean": 0.47,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 42,
    "out:Energy use kWh/m2": 42,
    "out:Energy savings %": 335.7,
    "out:Op energy cost/MSEK": 13,
    "out:El price/MSEK": 12,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 5,
    "out:El bought/kWh/m2": 2640.271749,
    "out:Return %": 16,
    "out:Return/kSEK/y": 1482,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 1774800,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 88740,
    "out:EAHP (replacement cost)": 1325203.921671023,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 41.7,
    "out:EAHP (El)": 37.964557,
    "out:PV (kr/kWp)": 17461.48474631725,
    "out:PV (kWp installed)": 90.5097,
    "out:PV (m2 panels)": 474.867263,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 84922.568824,
    "out:PV (kWh sold)": 35618.883171,
    "out:PV (% sold (El))": 41.942776,
    "out:PV (kWh self-consumed)": 49303.685653,
    "out:PV (ratio sold/self-consumed)": 0.722439,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 522898.732936,
    "out:EL kWh savings": -78897.031846,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 418532.99,
    "out:DH kr savings": 522898.7329360972,
    "out:El kr savings": -157794.063691003,
    "out:El kr sold": 53428.32475575219,
    "out:El kr saved": 98607.371306,
    "out:El kr return": 152035.69606175218,
    "out:% solar/total": 123,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 17016.204688,
    "out:% savings (space heating)": 95.820574,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 997124.4110739925,
    "out:Eupp": 2.57,
    "out:Etvv": 0,
    "out:Ef": 33.96,
    "out:heatPumpQuantity": 12,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 8989395.010021493,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 1.79992,
    "out:EL CO2": -51.263819,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -56.205838,
    "out:Bought CO2": 4.942019,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01001002020000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 3.794442,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 52.161458,
    "out:Electricity (inc PV)": 36.80592,
    "out:Total Energy Use Pre PV": 56.161458,
    "out:Total Energy Use Post PV": 40.80592,
    "out:Primary Energy": 67.539364,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 46.405313,
    "out:CO2 Operational/m2": -50.559829,
    "out:Total CO2/m2": -4.154516,
    "out:Total CO2 (tons)": -13.227975,
    "out:Klimatpaverkan": -213.363396,
    "out:Initial Cost/MSEK": 9.068994985021494,
    "out:Running cost/(Apt SEK y)": 4655.255319,
    "out:Running Cost over RSP/MSEK": 1.71298,
    "out:LCP/MSEK": 2.622745,
    "out:ROI% old": 18.41713,
    "out:Payback discounted": 7,
    "out:Atemp": 3184,
    "out:Umean": 0.47,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 41,
    "out:Energy use kWh/m2": 41,
    "out:Energy savings %": 346.3,
    "out:Op energy cost/MSEK": 12,
    "out:El price/MSEK": 11,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 4,
    "out:El bought/kWh/m2": 2601.868233,
    "out:Return %": 16,
    "out:Return/kSEK/y": 1496,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 1774800,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 88740,
    "out:EAHP (replacement cost)": 1325203.921671023,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 41.7,
    "out:EAHP (El)": 37.291958,
    "out:PV (kr/kWp)": 17461.48474631725,
    "out:PV (kWp installed)": 90.5097,
    "out:PV (m2 panels)": 474.867263,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 84922.568824,
    "out:PV (kWh sold)": 36030.549683,
    "out:PV (% sold (El))": 42.427532,
    "out:PV (kWh self-consumed)": 48892.019141,
    "out:PV (ratio sold/self-consumed)": 0.736941,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 527833.437931,
    "out:EL kWh savings": -77167.146062,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 79599.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 427544.97,
    "out:DH kr savings": 527833.4379313457,
    "out:El kr savings": -154334.29212397724,
    "out:El kr sold": 54045.82452446102,
    "out:El kr saved": 97784.038282,
    "out:El kr return": 151829.86280646102,
    "out:% solar/total": 122,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 12081.499693,
    "out:% savings (space heating)": 97.032609,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 997124.4110739925,
    "out:Eupp": 1.66,
    "out:Etvv": 0,
    "out:Ef": 33.27,
    "out:heatPumpQuantity": 12,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 9068994.985021494,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 1.439936,
    "out:EL CO2": -51.999765,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -56.869907,
    "out:Bought CO2": 4.870142,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01001002020001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 4.132331,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 51.305862,
    "out:Electricity (inc PV)": 36.116273,
    "out:Total Energy Use Pre PV": 55.305862,
    "out:Total Energy Use Post PV": 40.116273,
    "out:Primary Energy": 66.669868,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 47.113856,
    "out:CO2 Operational/m2": -51.502726,
    "out:Total CO2/m2": -4.38887,
    "out:Total CO2 (tons)": -13.974158,
    "out:Klimatpaverkan": -214.109579,
    "out:Initial Cost/MSEK": 9.262611010021493,
    "out:Running cost/(Apt SEK y)": 4544.957447,
    "out:Running Cost over RSP/MSEK": 1.67288,
    "out:LCP/MSEK": 2.469229,
    "out:ROI% old": 18.094003,
    "out:Payback discounted": 7,
    "out:Atemp": 3184,
    "out:Umean": 0.47,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 40,
    "out:Energy use kWh/m2": 40,
    "out:Energy savings %": 357.5,
    "out:Op energy cost/MSEK": 12,
    "out:El price/MSEK": 11,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 4,
    "out:El bought/kWh/m2": 2553.12394,
    "out:Return %": 16,
    "out:Return/kSEK/y": 1501,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 1774800,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 88740,
    "out:EAHP (replacement cost)": 1325203.921671023,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 35.445,
    "out:EAHP (El)": 36.436362,
    "out:PV (kr/kWp)": 17461.48474631725,
    "out:PV (kWp installed)": 90.5097,
    "out:PV (m2 panels)": 474.867263,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 84922.568824,
    "out:PV (kWh sold)": 36558.932002,
    "out:PV (% sold (El))": 43.049725,
    "out:PV (kWh self-consumed)": 48363.636822,
    "out:PV (ratio sold/self-consumed)": 0.755918,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 526757.599679,
    "out:EL kWh savings": -74971.310675,
    "out:Sewage heat recovery cost": 273216,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 431653.38,
    "out:DH kr savings": 526757.5996790434,
    "out:El kr savings": -149942.62134934092,
    "out:El kr sold": 54838.39800341529,
    "out:El kr saved": 96727.273644,
    "out:El kr return": 151565.6716474153,
    "out:% solar/total": 121,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 13157.337945,
    "out:% savings (space heating)": 96.768368,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 997124.4110739925,
    "out:Eupp": 2.14,
    "out:Etvv": 0,
    "out:Ef": 33.16,
    "out:heatPumpQuantity": 12,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 9262611.010021493,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 1.439936,
    "out:EL CO2": -52.942662,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -57.721546,
    "out:Bought CO2": 4.778884,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01001002020001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 2.825415,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 50.559648,
    "out:Electricity (inc PV)": 35.516175,
    "out:Total Energy Use Pre PV": 53.559648,
    "out:Total Energy Use Post PV": 38.516175,
    "out:Primary Energy": 64.986916,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 47.113856,
    "out:CO2 Operational/m2": -52.692086,
    "out:Total CO2/m2": -5.57823,
    "out:Total CO2 (tons)": -17.761079,
    "out:Klimatpaverkan": -217.8965,
    "out:Initial Cost/MSEK": 9.342210985021495,
    "out:Running cost/(Apt SEK y)": 4244.212766,
    "out:Running Cost over RSP/MSEK": 1.56271,
    "out:LCP/MSEK": 2.499799,
    "out:ROI% old": 18.108301,
    "out:Payback discounted": 7,
    "out:Atemp": 3184,
    "out:Umean": 0.47,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 39,
    "out:Energy use kWh/m2": 39,
    "out:Energy savings %": 369.2,
    "out:Op energy cost/MSEK": 11,
    "out:El price/MSEK": 10,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 3,
    "out:El bought/kWh/m2": 2510.71278,
    "out:Return %": 16,
    "out:Return/kSEK/y": 1515,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 1774800,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 88740,
    "out:EAHP (replacement cost)": 1325203.921671023,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 35.445,
    "out:EAHP (El)": 35.690148,
    "out:PV (kr/kWp)": 17461.48474631725,
    "out:PV (kWp installed)": 90.5097,
    "out:PV (m2 panels)": 474.867263,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 84922.568824,
    "out:PV (kWh sold)": 37024.163978,
    "out:PV (% sold (El))": 43.597555,
    "out:PV (kWh self-consumed)": 47898.404846,
    "out:PV (ratio sold/self-consumed)": 0.772973,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 530918.818971,
    "out:EL kWh savings": -73060.599218,
    "out:Sewage heat recovery cost": 273216,
    "out:Balancing hydronic system cost": 79599.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 440333.87,
    "out:DH kr savings": 530918.8189710178,
    "out:El kr savings": -146121.19843512867,
    "out:El kr sold": 55536.24596759738,
    "out:El kr saved": 95796.809692,
    "out:El kr return": 151333.0556595974,
    "out:% solar/total": 120,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 8996.118653,
    "out:% savings (space heating)": 97.790423,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 997124.4110739925,
    "out:Eupp": 1.36,
    "out:Etvv": 0,
    "out:Ef": 32.43,
    "out:heatPumpQuantity": 12,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 9342210.985021494,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 1.079952,
    "out:EL CO2": -53.772038,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -58.471515,
    "out:Bought CO2": 4.699477,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01001002100000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 3.778076,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 50.781394,
    "out:Electricity (inc PV)": 50.781394,
    "out:Total Energy Use Pre PV": 54.781394,
    "out:Total Energy Use Post PV": 54.781394,
    "out:Primary Energy": 92.968745,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 16.927114,
    "out:CO2 Operational/m2": 8.143486,
    "out:Total CO2/m2": 25.0706,
    "out:Total CO2 (tons)": 79.824766,
    "out:Klimatpaverkan": -120.310655,
    "out:Initial Cost/MSEK": 7.533008951577743,
    "out:Running cost/(Apt SEK y)": 7698.702128,
    "out:Running Cost over RSP/MSEK": 2.8008,
    "out:LCP/MSEK": 3.070911,
    "out:ROI% old": 20.109437,
    "out:Payback discounted": 6,
    "out:Atemp": 3184,
    "out:Umean": 0.47,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 55,
    "out:Energy use kWh/m2": 55,
    "out:Energy savings %": 232.7,
    "out:Op energy cost/MSEK": 19,
    "out:El price/MSEK": 18,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 4,
    "out:El bought/kWh/m2": 3554.69758,
    "out:Return %": 18,
    "out:Return/kSEK/y": 1353,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 1774800,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 88740,
    "out:EAHP (replacement cost)": 1325203.921671023,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 33.36,
    "out:EAHP (El)": 35.911894,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 527885.54726,
    "out:EL kWh savings": -121665.041889,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 284555.46,
    "out:DH kr savings": 527885.5472596671,
    "out:El kr savings": -243330.08377896907,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 12029.390365,
    "out:% savings (space heating)": 97.045408,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 997124.4110739925,
    "out:Eupp": 2.01,
    "out:Etvv": 0,
    "out:Ef": 47.27,
    "out:heatPumpQuantity": 12,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 7533008.9515777435,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 1.439936,
    "out:EL CO2": 6.70355,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 6.70355,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01001002100000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 2.545217,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 50.012738,
    "out:Electricity (inc PV)": 50.012738,
    "out:Total Energy Use Pre PV": 53.012738,
    "out:Total Energy Use Post PV": 53.012738,
    "out:Primary Energy": 91.012555,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 16.927114,
    "out:CO2 Operational/m2": 7.682034,
    "out:Total CO2/m2": 24.609148,
    "out:Total CO2 (tons)": 78.355504,
    "out:Klimatpaverkan": -121.779917,
    "out:Initial Cost/MSEK": 7.612608926577744,
    "out:Running cost/(Apt SEK y)": 7389.957447,
    "out:Running Cost over RSP/MSEK": 2.68775,
    "out:LCP/MSEK": 3.104361,
    "out:ROI% old": 20.111314,
    "out:Payback discounted": 6,
    "out:Atemp": 3184,
    "out:Umean": 0.47,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 53,
    "out:Energy use kWh/m2": 53,
    "out:Energy savings %": 245.3,
    "out:Op energy cost/MSEK": 18,
    "out:El price/MSEK": 17,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 3,
    "out:El bought/kWh/m2": 3500.89166,
    "out:Return %": 18,
    "out:Return/kSEK/y": 1367,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 1774800,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 88740,
    "out:EAHP (replacement cost)": 1325203.921671023,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 33.36,
    "out:EAHP (El)": 35.143238,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 531810.969135,
    "out:EL kWh savings": -119217.641922,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 79599.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 293375.69,
    "out:DH kr savings": 531810.9691345881,
    "out:El kr savings": -238435.283843714,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 8103.96849,
    "out:% savings (space heating)": 98.009548,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 997124.4110739925,
    "out:Eupp": 1.27,
    "out:Etvv": 0,
    "out:Ef": 46.35,
    "out:heatPumpQuantity": 12,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 7612608.926577744,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 1.079952,
    "out:EL CO2": 6.602082,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 6.602082,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01001002100001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 3.012238,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 49.497103,
    "out:Electricity (inc PV)": 49.497103,
    "out:Total Energy Use Pre PV": 52.497103,
    "out:Total Energy Use Post PV": 52.497103,
    "out:Primary Energy": 90.441963,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 17.635657,
    "out:CO2 Operational/m2": 7.613966,
    "out:Total CO2/m2": 25.249623,
    "out:Total CO2 (tons)": 80.394775,
    "out:Klimatpaverkan": -119.740646,
    "out:Initial Cost/MSEK": 7.806224951577743,
    "out:Running cost/(Apt SEK y)": 7320.085106,
    "out:Running Cost over RSP/MSEK": 2.66236,
    "out:LCP/MSEK": 2.936135,
    "out:ROI% old": 19.658963,
    "out:Payback discounted": 6,
    "out:Atemp": 3184,
    "out:Umean": 0.47,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 52,
    "out:Energy use kWh/m2": 52,
    "out:Energy savings %": 251.9,
    "out:Op energy cost/MSEK": 18,
    "out:El price/MSEK": 17,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 3,
    "out:El bought/kWh/m2": 3464.79721,
    "out:Return %": 18,
    "out:Return/kSEK/y": 1371,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 1774800,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 88740,
    "out:EAHP (replacement cost)": 1325203.921671023,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 28.356,
    "out:EAHP (El)": 34.627603,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 530323.974718,
    "out:EL kWh savings": -117575.860576,
    "out:Sewage heat recovery cost": 273216,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 295172.25,
    "out:DH kr savings": 530323.9747179943,
    "out:El kr savings": -235151.72115167067,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 9590.962906,
    "out:% savings (space heating)": 97.644321,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 997124.4110739925,
    "out:Eupp": 1.73,
    "out:Etvv": 0,
    "out:Ef": 46.44,
    "out:heatPumpQuantity": 12,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 7806224.9515777435,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 1.079952,
    "out:EL CO2": 6.534014,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 6.534014,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01001002100001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 1.970522,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 48.670524,
    "out:Electricity (inc PV)": 48.670524,
    "out:Total Energy Use Pre PV": 50.670524,
    "out:Total Energy Use Post PV": 50.670524,
    "out:Primary Energy": 88.44418,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 17.635657,
    "out:CO2 Operational/m2": 7.144867,
    "out:Total CO2/m2": 24.780524,
    "out:Total CO2 (tons)": 78.901165,
    "out:Klimatpaverkan": -121.234256,
    "out:Initial Cost/MSEK": 7.885824926577743,
    "out:Running cost/(Apt SEK y)": 7003.510638,
    "out:Running Cost over RSP/MSEK": 2.54646,
    "out:LCP/MSEK": 2.972435,
    "out:ROI% old": 19.670485,
    "out:Payback discounted": 6,
    "out:Atemp": 3184,
    "out:Umean": 0.47,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 51,
    "out:Energy use kWh/m2": 51,
    "out:Energy savings %": 258.8,
    "out:Op energy cost/MSEK": 17,
    "out:El price/MSEK": 16,
    "out:Heating price/MSEK": 0,
    "out:DH+ DHW/kWh/m2": 2,
    "out:El bought/kWh/m2": 3406.93668,
    "out:Return %": 18,
    "out:Return/kSEK/y": 1386,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 1774800,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 88740,
    "out:EAHP (replacement cost)": 1325203.921671023,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 28.356,
    "out:EAHP (El)": 33.801024,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 533640.797464,
    "out:EL kWh savings": -114944.033832,
    "out:Sewage heat recovery cost": 273216,
    "out:Balancing hydronic system cost": 79599.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 303752.73,
    "out:DH kr savings": 533640.7974640303,
    "out:El kr savings": -229888.067663396,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 6274.14016,
    "out:% savings (space heating)": 98.458981,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 997124.4110739925,
    "out:Eupp": 1.08,
    "out:Etvv": 0,
    "out:Ef": 45.5,
    "out:heatPumpQuantity": 12,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 7885824.926577743,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 0.719968,
    "out:EL CO2": 6.424899,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 6.424899,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01001002110000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 3.778076,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 50.781394,
    "out:Electricity (inc PV)": 39.498829,
    "out:Total Energy Use Pre PV": 54.781394,
    "out:Total Energy Use Post PV": 43.498829,
    "out:Primary Energy": 72.660128,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 31.666214,
    "out:CO2 Operational/m2": -3.37143,
    "out:Total CO2/m2": 28.294784,
    "out:Total CO2 (tons)": 90.090565,
    "out:Klimatpaverkan": -110.044856,
    "out:Initial Cost/MSEK": 8.323229345800243,
    "out:Running cost/(Apt SEK y)": 5961.382979,
    "out:Running Cost over RSP/MSEK": 2.17928,
    "out:LCP/MSEK": 2.902211,
    "out:ROI% old": 19.266972,
    "out:Payback discounted": 6,
    "out:Atemp": 3184,
    "out:Umean": 0.47,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 43,
    "out:Energy use kWh/m2": 43,
    "out:Energy savings %": 325.6,
    "out:Op energy cost/MSEK": 15,
    "out:El price/MSEK": 14,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 4,
    "out:El bought/kWh/m2": 2794.234958,
    "out:Return %": 17,
    "out:Return/kSEK/y": 1435,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 1774800,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 88740,
    "out:EAHP (replacement cost)": 1325203.921671023,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 33.36,
    "out:EAHP (El)": 35.911894,
    "out:PV (kr/kWp)": 17461.56255567083,
    "out:PV (kWp installed)": 45.25485,
    "out:PV (m2 panels)": 237.433632,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 42461.284501,
    "out:PV (kWh sold)": 6537.607921,
    "out:PV (% sold (El))": 15.396632,
    "out:PV (kWh self-consumed)": 35923.676581,
    "out:PV (ratio sold/self-consumed)": 0.181986,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 527885.54726,
    "out:EL kWh savings": -85741.365738,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 366209.23,
    "out:DH kr savings": 527885.5472596671,
    "out:El kr savings": -171482.73147636367,
    "out:El kr sold": 9806.411881148633,
    "out:El kr saved": 71847.353162,
    "out:El kr return": 81653.76504314863,
    "out:% solar/total": 90,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 12029.390365,
    "out:% savings (space heating)": 97.045408,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 997124.4110739925,
    "out:Eupp": 2.01,
    "out:Etvv": 0,
    "out:Ef": 36.37,
    "out:heatPumpQuantity": 12,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 8323229.345800243,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 1.439936,
    "out:EL CO2": -4.811366,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -10.038588,
    "out:Bought CO2": 5.227222,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01001002110000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 2.545217,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 50.012738,
    "out:Electricity (inc PV)": 38.809872,
    "out:Total Energy Use Pre PV": 53.012738,
    "out:Total Energy Use Post PV": 41.809872,
    "out:Primary Energy": 70.847397,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 31.666214,
    "out:CO2 Operational/m2": -4.227264,
    "out:Total CO2/m2": 27.43895,
    "out:Total CO2 (tons)": 87.365591,
    "out:Klimatpaverkan": -112.769831,
    "out:Initial Cost/MSEK": 8.402829320800246,
    "out:Running cost/(Apt SEK y)": 5655.340426,
    "out:Running Cost over RSP/MSEK": 2.06719,
    "out:LCP/MSEK": 2.934701,
    "out:ROI% old": 19.275021,
    "out:Payback discounted": 6,
    "out:Atemp": 3184,
    "out:Umean": 0.47,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 42,
    "out:Energy use kWh/m2": 42,
    "out:Energy savings %": 335.7,
    "out:Op energy cost/MSEK": 14,
    "out:El price/MSEK": 13,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 3,
    "out:El bought/kWh/m2": 2745.426383,
    "out:Return %": 17,
    "out:Return/kSEK/y": 1449,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 1774800,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 88740,
    "out:EAHP (replacement cost)": 1325203.921671023,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 33.36,
    "out:EAHP (El)": 35.143238,
    "out:PV (kr/kWp)": 17461.56255567083,
    "out:PV (kWp installed)": 45.25485,
    "out:PV (m2 panels)": 237.433632,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 42461.284501,
    "out:PV (kWh sold)": 6791.36862,
    "out:PV (% sold (El))": 15.994261,
    "out:PV (kWh self-consumed)": 35669.915882,
    "out:PV (ratio sold/self-consumed)": 0.190395,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 531810.969135,
    "out:EL kWh savings": -83547.72731,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 79599.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 374902.57,
    "out:DH kr savings": 531810.9691345881,
    "out:El kr savings": -167095.45462040525,
    "out:El kr sold": 10187.052929491503,
    "out:El kr saved": 71339.831764,
    "out:El kr return": 81526.8846934915,
    "out:% solar/total": 89,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 8103.96849,
    "out:% savings (space heating)": 98.009548,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 997124.4110739925,
    "out:Eupp": 1.27,
    "out:Etvv": 0,
    "out:Ef": 35.55,
    "out:heatPumpQuantity": 12,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 8402829.320800245,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 1.079952,
    "out:EL CO2": -5.307216,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -10.443277,
    "out:Bought CO2": 5.136061,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01001002110001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 3.012238,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 49.497103,
    "out:Electricity (inc PV)": 38.348697,
    "out:Total Energy Use Pre PV": 52.497103,
    "out:Total Energy Use Post PV": 41.348697,
    "out:Primary Energy": 70.374833,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 32.374757,
    "out:CO2 Operational/m2": -4.564295,
    "out:Total CO2/m2": 27.810462,
    "out:Total CO2 (tons)": 88.548484,
    "out:Klimatpaverkan": -111.586937,
    "out:Initial Cost/MSEK": 8.596445345800243,
    "out:Running cost/(Apt SEK y)": 5587.319149,
    "out:Running Cost over RSP/MSEK": 2.04246,
    "out:LCP/MSEK": 2.765815,
    "out:ROI% old": 18.881991,
    "out:Payback discounted": 7,
    "out:Atemp": 3184,
    "out:Umean": 0.47,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 41,
    "out:Energy use kWh/m2": 41,
    "out:Energy savings %": 346.3,
    "out:Op energy cost/MSEK": 14,
    "out:El price/MSEK": 13,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 3,
    "out:El bought/kWh/m2": 2712.761646,
    "out:Return %": 17,
    "out:Return/kSEK/y": 1452,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 1774800,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 88740,
    "out:EAHP (replacement cost)": 1325203.921671023,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 28.356,
    "out:EAHP (El)": 34.627603,
    "out:PV (kr/kWp)": 17461.56255567083,
    "out:PV (kWp installed)": 45.25485,
    "out:PV (m2 panels)": 237.433632,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 42461.284501,
    "out:PV (kWh sold)": 6964.770773,
    "out:PV (% sold (El))": 16.402638,
    "out:PV (kWh self-consumed)": 35496.513728,
    "out:PV (ratio sold/self-consumed)": 0.19621,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 530323.974718,
    "out:EL kWh savings": -82079.346552,
    "out:Sewage heat recovery cost": 273216,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 376612.44,
    "out:DH kr savings": 530323.9747179943,
    "out:El kr savings": -164158.6931040165,
    "out:El kr sold": 10447.1561595365,
    "out:El kr saved": 70993.027456,
    "out:El kr return": 81440.1836155365,
    "out:% solar/total": 89,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 9590.962906,
    "out:% savings (space heating)": 97.644321,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 997124.4110739925,
    "out:Eupp": 1.73,
    "out:Etvv": 0,
    "out:Ef": 35.64,
    "out:heatPumpQuantity": 12,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 8596445.345800243,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 1.079952,
    "out:EL CO2": -5.644247,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -10.71927,
    "out:Bought CO2": 5.075023,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01001002110001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 1.970522,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 48.670524,
    "out:Electricity (inc PV)": 37.611289,
    "out:Total Energy Use Pre PV": 50.670524,
    "out:Total Energy Use Post PV": 39.611289,
    "out:Primary Energy": 68.537557,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 32.374757,
    "out:CO2 Operational/m2": -5.47296,
    "out:Total CO2/m2": 26.901797,
    "out:Total CO2 (tons)": 85.655296,
    "out:Klimatpaverkan": -114.480125,
    "out:Initial Cost/MSEK": 8.676045320800243,
    "out:Running cost/(Apt SEK y)": 5273.765957,
    "out:Running Cost over RSP/MSEK": 1.92763,
    "out:LCP/MSEK": 2.801045,
    "out:ROI% old": 18.89783,
    "out:Payback discounted": 7,
    "out:Atemp": 3184,
    "out:Umean": 0.47,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 40,
    "out:Energy use kWh/m2": 40,
    "out:Energy savings %": 357.5,
    "out:Op energy cost/MSEK": 13,
    "out:El price/MSEK": 13,
    "out:Heating price/MSEK": 0,
    "out:DH+ DHW/kWh/m2": 2,
    "out:El bought/kWh/m2": 2660.528345,
    "out:Return %": 17,
    "out:Return/kSEK/y": 1467,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 1774800,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 88740,
    "out:EAHP (replacement cost)": 1325203.921671023,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 28.356,
    "out:EAHP (El)": 33.801024,
    "out:PV (kr/kWp)": 17461.56255567083,
    "out:PV (kWp installed)": 45.25485,
    "out:PV (m2 panels)": 237.433632,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 42461.284501,
    "out:PV (kWh sold)": 7248.691273,
    "out:PV (% sold (El))": 17.071295,
    "out:PV (kWh self-consumed)": 35212.593228,
    "out:PV (ratio sold/self-consumed)": 0.205855,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 533640.797464,
    "out:EL kWh savings": -79731.440186,
    "out:Sewage heat recovery cost": 273216,
    "out:Balancing hydronic system cost": 79599.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 385050.95,
    "out:DH kr savings": 533640.7974640303,
    "out:El kr savings": -159462.88037289027,
    "out:El kr sold": 10873.036910059094,
    "out:El kr saved": 70425.186456,
    "out:El kr return": 81298.22336605909,
    "out:% solar/total": 88,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 6274.14016,
    "out:% savings (space heating)": 98.458981,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 997124.4110739925,
    "out:Eupp": 1.08,
    "out:Etvv": 0,
    "out:Ef": 34.81,
    "out:heatPumpQuantity": 12,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 8676045.320800243,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 0.719968,
    "out:EL CO2": -6.192928,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -11.170326,
    "out:Bought CO2": 4.977398,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01001002120000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 3.778076,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 50.781394,
    "out:Electricity (inc PV)": 35.694379,
    "out:Total Energy Use Pre PV": 54.781394,
    "out:Total Energy Use Post PV": 39.694379,
    "out:Primary Energy": 65.812118,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 46.405313,
    "out:CO2 Operational/m2": -52.084932,
    "out:Total CO2/m2": -5.679619,
    "out:Total CO2 (tons)": -18.083901,
    "out:Klimatpaverkan": -218.219323,
    "out:Initial Cost/MSEK": 9.113442697521492,
    "out:Running cost/(Apt SEK y)": 4477.361702,
    "out:Running Cost over RSP/MSEK": 1.64831,
    "out:LCP/MSEK": 2.642967,
    "out:ROI% old": 18.428679,
    "out:Payback discounted": 7,
    "out:Atemp": 3184,
    "out:Umean": 0.47,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 40,
    "out:Energy use kWh/m2": 40,
    "out:Energy savings %": 357.5,
    "out:Op energy cost/MSEK": 12,
    "out:El price/MSEK": 11,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 4,
    "out:El bought/kWh/m2": 2523.305777,
    "out:Return %": 17,
    "out:Return/kSEK/y": 1504,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 1774800,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 88740,
    "out:EAHP (replacement cost)": 1325203.921671023,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 33.36,
    "out:EAHP (El)": 35.911894,
    "out:PV (kr/kWp)": 17461.48474631725,
    "out:PV (kWp installed)": 90.5097,
    "out:PV (m2 panels)": 474.867263,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 84922.568824,
    "out:PV (kWh sold)": 36885.527122,
    "out:PV (% sold (El))": 43.434304,
    "out:PV (kWh self-consumed)": 48037.041702,
    "out:PV (ratio sold/self-consumed)": 0.767856,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 527885.54726,
    "out:EL kWh savings": -73628.000583,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 435957.84,
    "out:DH kr savings": 527885.5472596671,
    "out:El kr savings": -147256.00116568987,
    "out:El kr sold": 55328.29068247013,
    "out:El kr saved": 96074.083404,
    "out:El kr return": 151402.37408647014,
    "out:% solar/total": 120,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 12029.390365,
    "out:% savings (space heating)": 97.045408,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 997124.4110739925,
    "out:Eupp": 2.01,
    "out:Etvv": 0,
    "out:Ef": 32.88,
    "out:heatPumpQuantity": 12,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 9113442.697521493,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 1.439936,
    "out:EL CO2": -53.524868,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -58.247923,
    "out:Bought CO2": 4.723055,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01001002120000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 2.545217,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 50.012738,
    "out:Electricity (inc PV)": 35.077006,
    "out:Total Energy Use Pre PV": 53.012738,
    "out:Total Energy Use Post PV": 38.077006,
    "out:Primary Energy": 64.128238,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 46.405313,
    "out:CO2 Operational/m2": -53.30396,
    "out:Total CO2/m2": -6.898647,
    "out:Total CO2 (tons)": -21.965285,
    "out:Klimatpaverkan": -222.100706,
    "out:Initial Cost/MSEK": 9.193042672521495,
    "out:Running cost/(Apt SEK y)": 4173.765957,
    "out:Running Cost over RSP/MSEK": 1.5371,
    "out:LCP/MSEK": 2.674577,
    "out:ROI% old": 18.441928,
    "out:Payback discounted": 7,
    "out:Atemp": 3184,
    "out:Umean": 0.47,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 38,
    "out:Energy use kWh/m2": 38,
    "out:Energy savings %": 381.6,
    "out:Op energy cost/MSEK": 11,
    "out:El price/MSEK": 10,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 3,
    "out:El bought/kWh/m2": 2479.687819,
    "out:Return %": 17,
    "out:Return/kSEK/y": 1519,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 1774800,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 88740,
    "out:EAHP (replacement cost)": 1325203.921671023,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 33.36,
    "out:EAHP (El)": 35.143238,
    "out:PV (kr/kWp)": 17461.48474631725,
    "out:PV (kWp installed)": 90.5097,
    "out:PV (m2 panels)": 474.867263,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 84922.568824,
    "out:PV (kWh sold)": 37367.212034,
    "out:PV (% sold (El))": 44.001509,
    "out:PV (kWh self-consumed)": 47555.35679,
    "out:PV (ratio sold/self-consumed)": 0.785762,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 531810.969135,
    "out:EL kWh savings": -71662.285542,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 79599.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 444537.22,
    "out:DH kr savings": 531810.9691345881,
    "out:El kr savings": -143324.57108457648,
    "out:El kr sold": 56050.818050521964,
    "out:El kr saved": 95110.71358,
    "out:El kr return": 151161.53163052196,
    "out:% solar/total": 119,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 8103.96849,
    "out:% savings (space heating)": 98.009548,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 997124.4110739925,
    "out:Eupp": 1.27,
    "out:Etvv": 0,
    "out:Ef": 32.15,
    "out:heatPumpQuantity": 12,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 9193042.672521494,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 1.079952,
    "out:EL CO2": -54.383912,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -59.025295,
    "out:Bought CO2": 4.641383,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01001002120001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 3.012238,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 49.497103,
    "out:Electricity (inc PV)": 34.66362,
    "out:Total Energy Use Pre PV": 52.497103,
    "out:Total Energy Use Post PV": 37.66362,
    "out:Primary Energy": 63.741694,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 47.113856,
    "out:CO2 Operational/m2": -53.883883,
    "out:Total CO2/m2": -6.770027,
    "out:Total CO2 (tons)": -21.555759,
    "out:Klimatpaverkan": -221.691181,
    "out:Initial Cost/MSEK": 9.386658697521494,
    "out:Running cost/(Apt SEK y)": 4107.361702,
    "out:Running Cost over RSP/MSEK": 1.51296,
    "out:LCP/MSEK": 2.505101,
    "out:ROI% old": 18.09827,
    "out:Payback discounted": 7,
    "out:Atemp": 3184,
    "out:Umean": 0.47,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 38,
    "out:Energy use kWh/m2": 38,
    "out:Energy savings %": 381.6,
    "out:Op energy cost/MSEK": 11,
    "out:El price/MSEK": 10,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 3,
    "out:El bought/kWh/m2": 2450.480642,
    "out:Return %": 16,
    "out:Return/kSEK/y": 1522,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 1774800,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 88740,
    "out:EAHP (replacement cost)": 1325203.921671023,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 28.356,
    "out:EAHP (El)": 34.627603,
    "out:PV (kr/kWp)": 17461.48474631725,
    "out:PV (kWp installed)": 90.5097,
    "out:PV (m2 panels)": 474.867263,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 84922.568824,
    "out:PV (kWh sold)": 37692.772145,
    "out:PV (% sold (El))": 44.38487,
    "out:PV (kWh self-consumed)": 47229.796679,
    "out:PV (ratio sold/self-consumed)": 0.798072,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 530323.974718,
    "out:EL kWh savings": -70346.064914,
    "out:Sewage heat recovery cost": 273216,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 446171,
    "out:DH kr savings": 530323.9747179943,
    "out:El kr savings": -140692.12982862408,
    "out:El kr sold": 56539.15821778022,
    "out:El kr saved": 94459.593358,
    "out:El kr return": 150998.75157578022,
    "out:% solar/total": 118,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 9590.962906,
    "out:% savings (space heating)": 97.644321,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 997124.4110739925,
    "out:Eupp": 1.73,
    "out:Etvv": 0,
    "out:Ef": 32.23,
    "out:heatPumpQuantity": 12,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 9386658.697521493,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 1.079952,
    "out:EL CO2": -54.963835,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -59.550526,
    "out:Bought CO2": 4.586691,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01001002120001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 1.970522,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 48.670524,
    "out:Electricity (inc PV)": 34.002103,
    "out:Total Energy Use Pre PV": 50.670524,
    "out:Total Energy Use Post PV": 36.002103,
    "out:Primary Energy": 62.041022,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 47.113856,
    "out:CO2 Operational/m2": -55.179591,
    "out:Total CO2/m2": -8.065735,
    "out:Total CO2 (tons)": -25.681293,
    "out:Klimatpaverkan": -225.816714,
    "out:Initial Cost/MSEK": 9.466258672521494,
    "out:Running cost/(Apt SEK y)": 3796.361702,
    "out:Running Cost over RSP/MSEK": 1.39907,
    "out:LCP/MSEK": 2.539391,
    "out:ROI% old": 18.117959,
    "out:Payback discounted": 7,
    "out:Atemp": 3184,
    "out:Umean": 0.47,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 36,
    "out:Energy use kWh/m2": 36,
    "out:Energy savings %": 408.3,
    "out:Op energy cost/MSEK": 10,
    "out:El price/MSEK": 9,
    "out:Heating price/MSEK": 0,
    "out:DH+ DHW/kWh/m2": 2,
    "out:El bought/kWh/m2": 2403.749309,
    "out:Return %": 16,
    "out:Return/kSEK/y": 1536,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 1774800,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 88740,
    "out:EAHP (replacement cost)": 1325203.921671023,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 28.356,
    "out:EAHP (El)": 33.801024,
    "out:PV (kr/kWp)": 17461.48474631725,
    "out:PV (kWp installed)": 90.5097,
    "out:PV (m2 panels)": 474.867263,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 84922.568824,
    "out:PV (kWh sold)": 38218.3291,
    "out:PV (% sold (El))": 45.003736,
    "out:PV (kWh self-consumed)": 46704.239724,
    "out:PV (ratio sold/self-consumed)": 0.818305,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 533640.797464,
    "out:EL kWh savings": -68239.79542,
    "out:Sewage heat recovery cost": 273216,
    "out:Balancing hydronic system cost": 79599.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 454488.7,
    "out:DH kr savings": 533640.7974640303,
    "out:El kr savings": -136479.59084009068,
    "out:El kr sold": 57327.49364925356,
    "out:El kr saved": 93408.479448,
    "out:El kr return": 150735.97309725356,
    "out:% solar/total": 117,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 6274.14016,
    "out:% savings (space heating)": 98.458981,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 997124.4110739925,
    "out:Eupp": 1.08,
    "out:Etvv": 0,
    "out:Ef": 31.48,
    "out:heatPumpQuantity": 12,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 9466258.672521494,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 0.719968,
    "out:EL CO2": -55.899559,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -60.398735,
    "out:Bought CO2": 4.499176,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02000000000000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 93.793075,
    "out:DHW (DH)": 41.7,
    "out:Property Electricity": 12.57,
    "out:Electricity (inc PV)": 12.57,
    "out:Total Energy Use Pre PV": 147.57,
    "out:Total Energy Use Post PV": 147.57,
    "out:Primary Energy": 113.076169,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 13.761201,
    "out:CO2 Operational/m2": 50.257181,
    "out:Total CO2/m2": 64.018382,
    "out:Total CO2 (tons)": 203.834467,
    "out:Klimatpaverkan": 3.699046,
    "out:Initial Cost/MSEK": 3.1645,
    "out:Running cost/(Apt SEK y)": 29322.595745,
    "out:Running Cost over RSP/MSEK": 10.77233,
    "out:LCP/MSEK": -0.53211,
    "out:ROI% old": 11.883574,
    "out:Payback discounted": 10,
    "out:Atemp": 3184,
    "out:Umean": 0.55,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 148,
    "out:Energy use kWh/m2": 148,
    "out:Energy savings %": 23.6,
    "out:Op energy cost/MSEK": 79,
    "out:El price/MSEK": 48,
    "out:Heating price/MSEK": 26,
    "out:DH+ DHW/kWh/m2": 135,
    "out:El bought/kWh/m2": 879.9,
    "out:Return %": 11,
    "out:Return/kSEK/y": 337,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 41.7,
    "out:EAHP (El)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 108505.1179,
    "out:EL kWh savings": 2.2624e-11,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 108505.12,
    "out:DH kr savings": 108505.11790023575,
    "out:El kr savings": 4.524734779920436e-11,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 298637.059673,
    "out:% savings (space heating)": 26.650424,
    "out:Total_Water Heating (DH)": 132772.760051,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 93.79,
    "out:Etvv": 25,
    "out:Ef": 12.57,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 3164500,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 48.59784,
    "out:EL CO2": 1.659341,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 1.659341,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02000000000000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 89.80248,
    "out:DHW (DH)": 41.7,
    "out:Property Electricity": 12.57,
    "out:Electricity (inc PV)": 12.57,
    "out:Total Energy Use Pre PV": 144.57,
    "out:Total Energy Use Post PV": 144.57,
    "out:Primary Energy": 109.972373,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 13.761201,
    "out:CO2 Operational/m2": 49.177229,
    "out:Total CO2/m2": 62.93843,
    "out:Total CO2 (tons)": 200.395901,
    "out:Klimatpaverkan": 0.26048,
    "out:Initial Cost/MSEK": 3.244099975,
    "out:Running cost/(Apt SEK y)": 28708.829787,
    "out:Running Cost over RSP/MSEK": 10.5467,
    "out:LCP/MSEK": -0.38608,
    "out:ROI% old": 12.585573,
    "out:Payback discounted": 10,
    "out:Atemp": 3184,
    "out:Umean": 0.55,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 145,
    "out:Energy use kWh/m2": 145,
    "out:Energy savings %": 26.2,
    "out:Op energy cost/MSEK": 77,
    "out:El price/MSEK": 47,
    "out:Heating price/MSEK": 26,
    "out:DH+ DHW/kWh/m2": 132,
    "out:El bought/kWh/m2": 879.9,
    "out:Return %": 11,
    "out:Return/kSEK/y": 365,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 41.7,
    "out:EAHP (El)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 121211.166328,
    "out:EL kWh savings": 2.2624e-11,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 79599.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 121211.17,
    "out:DH kr savings": 121211.1663284464,
    "out:El kr savings": 4.524734779920436e-11,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 285931.011244,
    "out:% savings (space heating)": 29.771213,
    "out:Total_Water Heating (DH)": 132772.760051,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 89.8,
    "out:Etvv": 25,
    "out:Ef": 12.57,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 3244099.975,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 47.517888,
    "out:EL CO2": 1.659341,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 1.659341,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02000000000001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 93.793075,
    "out:DHW (DH)": 35.445,
    "out:Property Electricity": 12.57,
    "out:Electricity (inc PV)": 12.57,
    "out:Total Energy Use Pre PV": 141.57,
    "out:Total Energy Use Post PV": 141.57,
    "out:Primary Energy": 110.451169,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 14.469744,
    "out:CO2 Operational/m2": 48.097277,
    "out:Total CO2/m2": 62.567021,
    "out:Total CO2 (tons)": 199.213335,
    "out:Klimatpaverkan": -0.922086,
    "out:Initial Cost/MSEK": 3.437716,
    "out:Running cost/(Apt SEK y)": 28095.06383,
    "out:Running Cost over RSP/MSEK": 10.32106,
    "out:LCP/MSEK": -0.354056,
    "out:ROI% old": 12.814405,
    "out:Payback discounted": 9,
    "out:Atemp": 3184,
    "out:Umean": 0.55,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 142,
    "out:Energy use kWh/m2": 142,
    "out:Energy savings %": 28.9,
    "out:Op energy cost/MSEK": 75,
    "out:El price/MSEK": 46,
    "out:Heating price/MSEK": 25,
    "out:DH+ DHW/kWh/m2": 129,
    "out:El bought/kWh/m2": 879.9,
    "out:Return %": 11,
    "out:Return/kSEK/y": 394,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 35.445,
    "out:EAHP (El)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 128421.031908,
    "out:EL kWh savings": 2.2624e-11,
    "out:Sewage heat recovery cost": 273216,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 128421.03,
    "out:DH kr savings": 128421.03190794574,
    "out:El kr savings": 4.524734779920436e-11,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 298637.059673,
    "out:% savings (space heating)": 26.650424,
    "out:Total_Water Heating (DH)": 112856.846044,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 93.79,
    "out:Etvv": 21.25,
    "out:Ef": 12.57,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 3437716,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 46.437936,
    "out:EL CO2": 1.659341,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 1.659341,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02000000000001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 89.80248,
    "out:DHW (DH)": 35.445,
    "out:Property Electricity": 12.57,
    "out:Electricity (inc PV)": 12.57,
    "out:Total Energy Use Pre PV": 137.57,
    "out:Total Energy Use Post PV": 137.57,
    "out:Primary Energy": 107.347373,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 14.469744,
    "out:CO2 Operational/m2": 46.657341,
    "out:Total CO2/m2": 61.127085,
    "out:Total CO2 (tons)": 194.62858,
    "out:Klimatpaverkan": -5.506841,
    "out:Initial Cost/MSEK": 3.517315975,
    "out:Running cost/(Apt SEK y)": 27276.702128,
    "out:Running Cost over RSP/MSEK": 10.02022,
    "out:LCP/MSEK": -0.132816,
    "out:ROI% old": 13.746277,
    "out:Payback discounted": 9,
    "out:Atemp": 3184,
    "out:Umean": 0.55,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 138,
    "out:Energy use kWh/m2": 138,
    "out:Energy savings %": 32.6,
    "out:Op energy cost/MSEK": 73,
    "out:El price/MSEK": 45,
    "out:Heating price/MSEK": 24,
    "out:DH+ DHW/kWh/m2": 125,
    "out:El bought/kWh/m2": 879.9,
    "out:Return %": 12,
    "out:Return/kSEK/y": 433,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 35.445,
    "out:EAHP (El)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 141127.080336,
    "out:EL kWh savings": 2.2624e-11,
    "out:Sewage heat recovery cost": 273216,
    "out:Balancing hydronic system cost": 79599.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 141127.08,
    "out:DH kr savings": 141127.0803361564,
    "out:El kr savings": 4.524734779920436e-11,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 285931.011244,
    "out:% savings (space heating)": 29.771213,
    "out:Total_Water Heating (DH)": 112856.846044,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 89.8,
    "out:Etvv": 21.25,
    "out:Ef": 12.57,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 3517315.9749999996,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 44.998,
    "out:EL CO2": 1.659341,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 1.659341,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02000000000100000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 1,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 93.793075,
    "out:DHW (DH)": 41.7,
    "out:Property Electricity": 9.976115,
    "out:Electricity (inc PV)": 9.976115,
    "out:Total Energy Use Pre PV": 144.976115,
    "out:Total Energy Use Post PV": 144.976115,
    "out:Primary Energy": 108.407176,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 13.945703,
    "out:CO2 Operational/m2": 49.914767,
    "out:Total CO2/m2": 63.86047,
    "out:Total CO2 (tons)": 203.331675,
    "out:Klimatpaverkan": 3.196254,
    "out:Initial Cost/MSEK": 3.311292871519825,
    "out:Running cost/(Apt SEK y)": 28971.148936,
    "out:Running Cost over RSP/MSEK": 10.64463,
    "out:LCP/MSEK": -0.551203,
    "out:ROI% old": 11.907692,
    "out:Payback discounted": 10,
    "out:Atemp": 3184,
    "out:Umean": 0.55,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 145,
    "out:Energy use kWh/m2": 145,
    "out:Energy savings %": 26.2,
    "out:Op energy cost/MSEK": 78,
    "out:El price/MSEK": 47,
    "out:Heating price/MSEK": 26,
    "out:DH+ DHW/kWh/m2": 135,
    "out:El bought/kWh/m2": 698.32805,
    "out:Return %": 11,
    "out:Return/kSEK/y": 353,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 41.7,
    "out:EAHP (El)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 108505.1179,
    "out:EL kWh savings": 8258.927355,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 125022.97,
    "out:DH kr savings": 108505.11790023575,
    "out:El kr savings": 16517.854710116386,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 298637.059673,
    "out:% savings (space heating)": 26.650424,
    "out:Total_Water Heating (DH)": 132772.760051,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 93.79,
    "out:Etvv": 25,
    "out:Ef": 9.98,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 3311292.871519825,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333,
    "out:New AHU Cost/SEK": 146792.87151982498,
    "out:DH CO2": 48.59784,
    "out:EL CO2": 1.316927,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 1.316927,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02000000000100100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 1,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 89.80248,
    "out:DHW (DH)": 41.7,
    "out:Property Electricity": 9.976115,
    "out:Electricity (inc PV)": 9.976115,
    "out:Total Energy Use Pre PV": 141.976115,
    "out:Total Energy Use Post PV": 141.976115,
    "out:Primary Energy": 105.30338,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 13.945703,
    "out:CO2 Operational/m2": 48.834815,
    "out:Total CO2/m2": 62.780518,
    "out:Total CO2 (tons)": 199.893109,
    "out:Klimatpaverkan": -0.242312,
    "out:Initial Cost/MSEK": 3.390892846519825,
    "out:Running cost/(Apt SEK y)": 28357.382979,
    "out:Running Cost over RSP/MSEK": 10.419,
    "out:LCP/MSEK": -0.405173,
    "out:ROI% old": 12.578735,
    "out:Payback discounted": 10,
    "out:Atemp": 3184,
    "out:Umean": 0.55,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 142,
    "out:Energy use kWh/m2": 142,
    "out:Energy savings %": 28.9,
    "out:Op energy cost/MSEK": 76,
    "out:El price/MSEK": 46,
    "out:Heating price/MSEK": 26,
    "out:DH+ DHW/kWh/m2": 132,
    "out:El bought/kWh/m2": 698.32805,
    "out:Return %": 11,
    "out:Return/kSEK/y": 382,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 41.7,
    "out:EAHP (El)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 121211.166328,
    "out:EL kWh savings": 8258.927355,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 79599.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 137729.02,
    "out:DH kr savings": 121211.1663284464,
    "out:El kr savings": 16517.854710116386,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 285931.011244,
    "out:% savings (space heating)": 29.771213,
    "out:Total_Water Heating (DH)": 132772.760051,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 89.8,
    "out:Etvv": 25,
    "out:Ef": 9.98,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 3390892.846519825,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25,
    "out:New AHU Cost/SEK": 146792.87151982498,
    "out:DH CO2": 47.517888,
    "out:EL CO2": 1.316927,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 1.316927,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02000000000101000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 1,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 93.793075,
    "out:DHW (DH)": 35.445,
    "out:Property Electricity": 9.976115,
    "out:Electricity (inc PV)": 9.976115,
    "out:Total Energy Use Pre PV": 138.976115,
    "out:Total Energy Use Post PV": 138.976115,
    "out:Primary Energy": 105.782176,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 14.654246,
    "out:CO2 Operational/m2": 47.754863,
    "out:Total CO2/m2": 62.409109,
    "out:Total CO2 (tons)": 198.710543,
    "out:Klimatpaverkan": -1.424878,
    "out:Initial Cost/MSEK": 3.5845088715198243,
    "out:Running cost/(Apt SEK y)": 27743.617021,
    "out:Running Cost over RSP/MSEK": 10.19337,
    "out:LCP/MSEK": -0.373159,
    "out:ROI% old": 12.798526,
    "out:Payback discounted": 9,
    "out:Atemp": 3184,
    "out:Umean": 0.55,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 139,
    "out:Energy use kWh/m2": 139,
    "out:Energy savings %": 31.7,
    "out:Op energy cost/MSEK": 75,
    "out:El price/MSEK": 45,
    "out:Heating price/MSEK": 25,
    "out:DH+ DHW/kWh/m2": 129,
    "out:El bought/kWh/m2": 698.32805,
    "out:Return %": 11,
    "out:Return/kSEK/y": 411,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 35.445,
    "out:EAHP (El)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 128421.031908,
    "out:EL kWh savings": 8258.927355,
    "out:Sewage heat recovery cost": 273216,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 144938.89,
    "out:DH kr savings": 128421.03190794574,
    "out:El kr savings": 16517.854710116386,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 298637.059673,
    "out:% savings (space heating)": 26.650424,
    "out:Total_Water Heating (DH)": 112856.846044,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 93.79,
    "out:Etvv": 21.25,
    "out:Ef": 9.98,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 3584508.8715198245,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25,
    "out:New AHU Cost/SEK": 146792.87151982498,
    "out:DH CO2": 46.437936,
    "out:EL CO2": 1.316927,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 1.316927,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02000000000101100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 1,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 89.80248,
    "out:DHW (DH)": 35.445,
    "out:Property Electricity": 9.976115,
    "out:Electricity (inc PV)": 9.976115,
    "out:Total Energy Use Pre PV": 134.976115,
    "out:Total Energy Use Post PV": 134.976115,
    "out:Primary Energy": 102.67838,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 14.654246,
    "out:CO2 Operational/m2": 46.314927,
    "out:Total CO2/m2": 60.969173,
    "out:Total CO2 (tons)": 194.125788,
    "out:Klimatpaverkan": -6.009633,
    "out:Initial Cost/MSEK": 3.6641088465198246,
    "out:Running cost/(Apt SEK y)": 26925.276596,
    "out:Running Cost over RSP/MSEK": 9.89252,
    "out:LCP/MSEK": -0.151909,
    "out:ROI% old": 13.693448,
    "out:Payback discounted": 9,
    "out:Atemp": 3184,
    "out:Umean": 0.55,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 135,
    "out:Energy use kWh/m2": 135,
    "out:Energy savings %": 35.6,
    "out:Op energy cost/MSEK": 72,
    "out:El price/MSEK": 44,
    "out:Heating price/MSEK": 24,
    "out:DH+ DHW/kWh/m2": 125,
    "out:El bought/kWh/m2": 698.32805,
    "out:Return %": 12,
    "out:Return/kSEK/y": 449,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 35.445,
    "out:EAHP (El)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 141127.080336,
    "out:EL kWh savings": 8258.927355,
    "out:Sewage heat recovery cost": 273216,
    "out:Balancing hydronic system cost": 79599.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 157644.94,
    "out:DH kr savings": 141127.0803361564,
    "out:El kr savings": 16517.854710116386,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 285931.011244,
    "out:% savings (space heating)": 29.771213,
    "out:Total_Water Heating (DH)": 112856.846044,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 89.8,
    "out:Etvv": 21.25,
    "out:Ef": 9.98,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 3664108.8465198246,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25,
    "out:New AHU Cost/SEK": 146792.87151982498,
    "out:DH CO2": 44.998,
    "out:EL CO2": 1.316927,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 1.316927,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02000000010000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 93.793075,
    "out:DHW (DH)": 41.7,
    "out:Property Electricity": 12.57,
    "out:Electricity (inc PV)": 8.051517,
    "out:Total Energy Use Pre PV": 147.57,
    "out:Total Energy Use Post PV": 143.051517,
    "out:Primary Energy": 104.9429,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 28.500301,
    "out:CO2 Operational/m2": 4.996519,
    "out:Total CO2/m2": 33.49682,
    "out:Total CO2 (tons)": 106.653843,
    "out:Klimatpaverkan": -93.481578,
    "out:Initial Cost/MSEK": 3.9547203942225,
    "out:Running cost/(Apt SEK y)": 27814.404255,
    "out:Running Cost over RSP/MSEK": 10.23296,
    "out:LCP/MSEK": -0.78296,
    "out:ROI% old": 11.457411,
    "out:Payback discounted": 11,
    "out:Atemp": 3184,
    "out:Umean": 0.55,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 143,
    "out:Energy use kWh/m2": 143,
    "out:Energy savings %": 28,
    "out:Op energy cost/MSEK": 75,
    "out:El price/MSEK": 45,
    "out:Heating price/MSEK": 26,
    "out:DH+ DHW/kWh/m2": 135,
    "out:El bought/kWh/m2": 568.258452,
    "out:Return %": 10,
    "out:Return/kSEK/y": 407,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 41.7,
    "out:EAHP (El)": 0,
    "out:PV (kr/kWp)": 17461.56255567083,
    "out:PV (kWp installed)": 45.25485,
    "out:PV (m2 panels)": 237.433632,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 42461.284501,
    "out:PV (kWh sold)": 28074.439514,
    "out:PV (% sold (El))": 66.117735,
    "out:PV (kWh self-consumed)": 14386.844987,
    "out:PV (ratio sold/self-consumed)": 1.951397,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 108505.1179,
    "out:EL kWh savings": 14386.845543,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 179390.47,
    "out:DH kr savings": 108505.11790023575,
    "out:El kr savings": 28773.691086586638,
    "out:El kr sold": 42111.65927092506,
    "out:El kr saved": 28773.689974,
    "out:El kr return": 70885.34924492506,
    "out:% solar/total": 36,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 298637.059673,
    "out:% savings (space heating)": 26.650424,
    "out:Total_Water Heating (DH)": 132772.760051,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 93.79,
    "out:Etvv": 25,
    "out:Ef": 8.05,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 3954720.3942225,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 48.59784,
    "out:EL CO2": -43.601321,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -44.666212,
    "out:Bought CO2": 1.064891,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02000000010000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 89.80248,
    "out:DHW (DH)": 41.7,
    "out:Property Electricity": 12.57,
    "out:Electricity (inc PV)": 8.051517,
    "out:Total Energy Use Pre PV": 144.57,
    "out:Total Energy Use Post PV": 140.051517,
    "out:Primary Energy": 101.839104,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 28.500301,
    "out:CO2 Operational/m2": 3.916567,
    "out:Total CO2/m2": 32.416868,
    "out:Total CO2 (tons)": 103.215277,
    "out:Klimatpaverkan": -96.920144,
    "out:Initial Cost/MSEK": 4.0343203692225,
    "out:Running cost/(Apt SEK y)": 27200.638298,
    "out:Running Cost over RSP/MSEK": 10.00733,
    "out:LCP/MSEK": -0.63693,
    "out:ROI% old": 12.030315,
    "out:Payback discounted": 10,
    "out:Atemp": 3184,
    "out:Umean": 0.55,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 140,
    "out:Energy use kWh/m2": 140,
    "out:Energy savings %": 30.7,
    "out:Op energy cost/MSEK": 74,
    "out:El price/MSEK": 44,
    "out:Heating price/MSEK": 26,
    "out:DH+ DHW/kWh/m2": 132,
    "out:El bought/kWh/m2": 568.258452,
    "out:Return %": 11,
    "out:Return/kSEK/y": 436,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 41.7,
    "out:EAHP (El)": 0,
    "out:PV (kr/kWp)": 17461.56255567083,
    "out:PV (kWp installed)": 45.25485,
    "out:PV (m2 panels)": 237.433632,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 42461.284501,
    "out:PV (kWh sold)": 28074.439514,
    "out:PV (% sold (El))": 66.117735,
    "out:PV (kWh self-consumed)": 14386.844987,
    "out:PV (ratio sold/self-consumed)": 1.951397,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 121211.166328,
    "out:EL kWh savings": 14386.845543,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 79599.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 192096.52,
    "out:DH kr savings": 121211.1663284464,
    "out:El kr savings": 28773.691086586638,
    "out:El kr sold": 42111.65927092506,
    "out:El kr saved": 28773.689974,
    "out:El kr return": 70885.34924492506,
    "out:% solar/total": 36,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 285931.011244,
    "out:% savings (space heating)": 29.771213,
    "out:Total_Water Heating (DH)": 132772.760051,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 89.8,
    "out:Etvv": 25,
    "out:Ef": 8.05,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 4034320.3692225,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 47.517888,
    "out:EL CO2": -43.601321,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -44.666212,
    "out:Bought CO2": 1.064891,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02000000010001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 93.793075,
    "out:DHW (DH)": 35.445,
    "out:Property Electricity": 12.57,
    "out:Electricity (inc PV)": 8.051517,
    "out:Total Energy Use Pre PV": 141.57,
    "out:Total Energy Use Post PV": 137.051517,
    "out:Primary Energy": 102.3179,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 29.208844,
    "out:CO2 Operational/m2": 2.836615,
    "out:Total CO2/m2": 32.045459,
    "out:Total CO2 (tons)": 102.032711,
    "out:Klimatpaverkan": -98.10271,
    "out:Initial Cost/MSEK": 4.2279363942225,
    "out:Running cost/(Apt SEK y)": 26586.87234,
    "out:Running Cost over RSP/MSEK": 9.78169,
    "out:LCP/MSEK": -0.604906,
    "out:ROI% old": 12.241805,
    "out:Payback discounted": 10,
    "out:Atemp": 3184,
    "out:Umean": 0.55,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 137,
    "out:Energy use kWh/m2": 137,
    "out:Energy savings %": 33.6,
    "out:Op energy cost/MSEK": 72,
    "out:El price/MSEK": 43,
    "out:Heating price/MSEK": 25,
    "out:DH+ DHW/kWh/m2": 129,
    "out:El bought/kWh/m2": 568.258452,
    "out:Return %": 11,
    "out:Return/kSEK/y": 465,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 35.445,
    "out:EAHP (El)": 0,
    "out:PV (kr/kWp)": 17461.56255567083,
    "out:PV (kWp installed)": 45.25485,
    "out:PV (m2 panels)": 237.433632,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 42461.284501,
    "out:PV (kWh sold)": 28074.439514,
    "out:PV (% sold (El))": 66.117735,
    "out:PV (kWh self-consumed)": 14386.844987,
    "out:PV (ratio sold/self-consumed)": 1.951397,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 128421.031908,
    "out:EL kWh savings": 14386.845543,
    "out:Sewage heat recovery cost": 273216,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 199306.38,
    "out:DH kr savings": 128421.03190794574,
    "out:El kr savings": 28773.691086586638,
    "out:El kr sold": 42111.65927092506,
    "out:El kr saved": 28773.689974,
    "out:El kr return": 70885.34924492506,
    "out:% solar/total": 36,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 298637.059673,
    "out:% savings (space heating)": 26.650424,
    "out:Total_Water Heating (DH)": 112856.846044,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 93.79,
    "out:Etvv": 21.25,
    "out:Ef": 8.05,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 4227936.3942225,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 46.437936,
    "out:EL CO2": -43.601321,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -44.666212,
    "out:Bought CO2": 1.064891,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02000000010001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 89.80248,
    "out:DHW (DH)": 35.445,
    "out:Property Electricity": 12.57,
    "out:Electricity (inc PV)": 8.051517,
    "out:Total Energy Use Pre PV": 137.57,
    "out:Total Energy Use Post PV": 133.051517,
    "out:Primary Energy": 99.214104,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 29.208844,
    "out:CO2 Operational/m2": 1.396679,
    "out:Total CO2/m2": 30.605523,
    "out:Total CO2 (tons)": 97.447956,
    "out:Klimatpaverkan": -102.687465,
    "out:Initial Cost/MSEK": 4.3075363692224995,
    "out:Running cost/(Apt SEK y)": 25768.510638,
    "out:Running Cost over RSP/MSEK": 9.48085,
    "out:LCP/MSEK": -0.383666,
    "out:ROI% old": 13.013305,
    "out:Payback discounted": 9,
    "out:Atemp": 3184,
    "out:Umean": 0.55,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 133,
    "out:Energy use kWh/m2": 133,
    "out:Energy savings %": 37.6,
    "out:Op energy cost/MSEK": 70,
    "out:El price/MSEK": 41,
    "out:Heating price/MSEK": 24,
    "out:DH+ DHW/kWh/m2": 125,
    "out:El bought/kWh/m2": 568.258452,
    "out:Return %": 12,
    "out:Return/kSEK/y": 504,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 35.445,
    "out:EAHP (El)": 0,
    "out:PV (kr/kWp)": 17461.56255567083,
    "out:PV (kWp installed)": 45.25485,
    "out:PV (m2 panels)": 237.433632,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 42461.284501,
    "out:PV (kWh sold)": 28074.439514,
    "out:PV (% sold (El))": 66.117735,
    "out:PV (kWh self-consumed)": 14386.844987,
    "out:PV (ratio sold/self-consumed)": 1.951397,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 141127.080336,
    "out:EL kWh savings": 14386.845543,
    "out:Sewage heat recovery cost": 273216,
    "out:Balancing hydronic system cost": 79599.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 212012.43,
    "out:DH kr savings": 141127.0803361564,
    "out:El kr savings": 28773.691086586638,
    "out:El kr sold": 42111.65927092506,
    "out:El kr saved": 28773.689974,
    "out:El kr return": 70885.34924492506,
    "out:% solar/total": 36,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 285931.011244,
    "out:% savings (space heating)": 29.771213,
    "out:Total_Water Heating (DH)": 112856.846044,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 89.8,
    "out:Etvv": 21.25,
    "out:Ef": 8.05,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 4307536.369222499,
    "out:Seasonal Variation ROI (%)": 5,
    "out:Seasonal Variation Payback": 20,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 44.998,
    "out:EL CO2": -43.601321,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -44.666212,
    "out:Bought CO2": 1.064891,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02000000010100000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 1,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 93.793075,
    "out:DHW (DH)": 41.7,
    "out:Property Electricity": 9.976115,
    "out:Electricity (inc PV)": 6.231337,
    "out:Total Energy Use Pre PV": 144.976115,
    "out:Total Energy Use Post PV": 141.231337,
    "out:Primary Energy": 101.666576,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 28.684803,
    "out:CO2 Operational/m2": 0.766391,
    "out:Total CO2/m2": 29.451194,
    "out:Total CO2 (tons)": 93.772573,
    "out:Klimatpaverkan": -106.362848,
    "out:Initial Cost/MSEK": 4.101513265742325,
    "out:Running cost/(Apt SEK y)": 27489.170213,
    "out:Running Cost over RSP/MSEK": 10.11473,
    "out:LCP/MSEK": -0.811523,
    "out:ROI% old": 11.45915,
    "out:Payback discounted": 11,
    "out:Atemp": 3184,
    "out:Umean": 0.55,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 141,
    "out:Energy use kWh/m2": 141,
    "out:Energy savings %": 29.8,
    "out:Op energy cost/MSEK": 75,
    "out:El price/MSEK": 44,
    "out:Heating price/MSEK": 26,
    "out:DH+ DHW/kWh/m2": 135,
    "out:El bought/kWh/m2": 439.497378,
    "out:Return %": 10,
    "out:Return/kSEK/y": 423,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 41.7,
    "out:EAHP (El)": 0,
    "out:PV (kr/kWp)": 17461.56255567083,
    "out:PV (kWp installed)": 45.25485,
    "out:PV (m2 panels)": 237.433632,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 42461.284501,
    "out:PV (kWh sold)": 30537.914073,
    "out:PV (% sold (El))": 71.919431,
    "out:PV (kWh self-consumed)": 11923.370428,
    "out:PV (ratio sold/self-consumed)": 2.561181,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 108505.1179,
    "out:EL kWh savings": 20182.29692,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 194676.58,
    "out:DH kr savings": 108505.11790023575,
    "out:El kr savings": 40364.59383912171,
    "out:El kr sold": 45806.87111012268,
    "out:El kr saved": 23846.740856,
    "out:El kr return": 69653.61196612268,
    "out:% solar/total": 30,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 298637.059673,
    "out:% savings (space heating)": 26.650424,
    "out:Total_Water Heating (DH)": 132772.760051,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 93.79,
    "out:Etvv": 25,
    "out:Ef": 6.23,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 4101513.2657423248,
    "out:Seasonal Variation ROI (%)": 5,
    "out:Seasonal Variation Payback": 20,
    "out:New AHU Cost/SEK": 146792.87151982498,
    "out:DH CO2": 48.59784,
    "out:EL CO2": -47.831449,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -48.655512,
    "out:Bought CO2": 0.824063,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02000000010100100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 1,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 89.80248,
    "out:DHW (DH)": 41.7,
    "out:Property Electricity": 9.976115,
    "out:Electricity (inc PV)": 6.231337,
    "out:Total Energy Use Pre PV": 141.976115,
    "out:Total Energy Use Post PV": 138.231337,
    "out:Primary Energy": 98.56278,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 28.684803,
    "out:CO2 Operational/m2": -0.313561,
    "out:Total CO2/m2": 28.371242,
    "out:Total CO2 (tons)": 90.334007,
    "out:Klimatpaverkan": -109.801414,
    "out:Initial Cost/MSEK": 4.181113240742325,
    "out:Running cost/(Apt SEK y)": 26875.404255,
    "out:Running Cost over RSP/MSEK": 9.8891,
    "out:LCP/MSEK": -0.665493,
    "out:ROI% old": 12.011907,
    "out:Payback discounted": 10,
    "out:Atemp": 3184,
    "out:Umean": 0.55,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 138,
    "out:Energy use kWh/m2": 138,
    "out:Energy savings %": 32.6,
    "out:Op energy cost/MSEK": 73,
    "out:El price/MSEK": 43,
    "out:Heating price/MSEK": 26,
    "out:DH+ DHW/kWh/m2": 132,
    "out:El bought/kWh/m2": 439.497378,
    "out:Return %": 11,
    "out:Return/kSEK/y": 452,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 41.7,
    "out:EAHP (El)": 0,
    "out:PV (kr/kWp)": 17461.56255567083,
    "out:PV (kWp installed)": 45.25485,
    "out:PV (m2 panels)": 237.433632,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 42461.284501,
    "out:PV (kWh sold)": 30537.914073,
    "out:PV (% sold (El))": 71.919431,
    "out:PV (kWh self-consumed)": 11923.370428,
    "out:PV (ratio sold/self-consumed)": 2.561181,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 121211.166328,
    "out:EL kWh savings": 20182.29692,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 79599.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 207382.63,
    "out:DH kr savings": 121211.1663284464,
    "out:El kr savings": 40364.59383912171,
    "out:El kr sold": 45806.87111012268,
    "out:El kr saved": 23846.740856,
    "out:El kr return": 69653.61196612268,
    "out:% solar/total": 30,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 285931.011244,
    "out:% savings (space heating)": 29.771213,
    "out:Total_Water Heating (DH)": 132772.760051,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 89.8,
    "out:Etvv": 25,
    "out:Ef": 6.23,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 4181113.2407423244,
    "out:Seasonal Variation ROI (%)": 5,
    "out:Seasonal Variation Payback": 20,
    "out:New AHU Cost/SEK": 146792.87151982498,
    "out:DH CO2": 47.517888,
    "out:EL CO2": -47.831449,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -48.655512,
    "out:Bought CO2": 0.824063,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02000000010101000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 1,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 93.793075,
    "out:DHW (DH)": 35.445,
    "out:Property Electricity": 9.976115,
    "out:Electricity (inc PV)": 6.231337,
    "out:Total Energy Use Pre PV": 138.976115,
    "out:Total Energy Use Post PV": 135.231337,
    "out:Primary Energy": 99.041576,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 29.393346,
    "out:CO2 Operational/m2": -1.393513,
    "out:Total CO2/m2": 27.999833,
    "out:Total CO2 (tons)": 89.151441,
    "out:Klimatpaverkan": -110.98398,
    "out:Initial Cost/MSEK": 4.3747292657423245,
    "out:Running cost/(Apt SEK y)": 26261.638298,
    "out:Running Cost over RSP/MSEK": 9.66346,
    "out:LCP/MSEK": -0.633469,
    "out:ROI% old": 12.217115,
    "out:Payback discounted": 10,
    "out:Atemp": 3184,
    "out:Umean": 0.55,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 135,
    "out:Energy use kWh/m2": 135,
    "out:Energy savings %": 35.6,
    "out:Op energy cost/MSEK": 71,
    "out:El price/MSEK": 42,
    "out:Heating price/MSEK": 25,
    "out:DH+ DHW/kWh/m2": 129,
    "out:El bought/kWh/m2": 439.497378,
    "out:Return %": 11,
    "out:Return/kSEK/y": 480,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 35.445,
    "out:EAHP (El)": 0,
    "out:PV (kr/kWp)": 17461.56255567083,
    "out:PV (kWp installed)": 45.25485,
    "out:PV (m2 panels)": 237.433632,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 42461.284501,
    "out:PV (kWh sold)": 30537.914073,
    "out:PV (% sold (El))": 71.919431,
    "out:PV (kWh self-consumed)": 11923.370428,
    "out:PV (ratio sold/self-consumed)": 2.561181,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 128421.031908,
    "out:EL kWh savings": 20182.29692,
    "out:Sewage heat recovery cost": 273216,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 214592.5,
    "out:DH kr savings": 128421.03190794574,
    "out:El kr savings": 40364.59383912171,
    "out:El kr sold": 45806.87111012268,
    "out:El kr saved": 23846.740856,
    "out:El kr return": 69653.61196612268,
    "out:% solar/total": 30,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 298637.059673,
    "out:% savings (space heating)": 26.650424,
    "out:Total_Water Heating (DH)": 112856.846044,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 93.79,
    "out:Etvv": 21.25,
    "out:Ef": 6.23,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 4374729.265742324,
    "out:Seasonal Variation ROI (%)": 5,
    "out:Seasonal Variation Payback": 20,
    "out:New AHU Cost/SEK": 146792.87151982498,
    "out:DH CO2": 46.437936,
    "out:EL CO2": -47.831449,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -48.655512,
    "out:Bought CO2": 0.824063,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02000000010101100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 1,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 89.80248,
    "out:DHW (DH)": 35.445,
    "out:Property Electricity": 9.976115,
    "out:Electricity (inc PV)": 6.231337,
    "out:Total Energy Use Pre PV": 134.976115,
    "out:Total Energy Use Post PV": 131.231337,
    "out:Primary Energy": 95.93778,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 29.393346,
    "out:CO2 Operational/m2": -2.833449,
    "out:Total CO2/m2": 26.559897,
    "out:Total CO2 (tons)": 84.566687,
    "out:Klimatpaverkan": -115.568734,
    "out:Initial Cost/MSEK": 4.454329240742325,
    "out:Running cost/(Apt SEK y)": 25443.276596,
    "out:Running Cost over RSP/MSEK": 9.36262,
    "out:LCP/MSEK": -0.412229,
    "out:ROI% old": 12.963632,
    "out:Payback discounted": 9,
    "out:Atemp": 3184,
    "out:Umean": 0.55,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 131,
    "out:Energy use kWh/m2": 131,
    "out:Energy savings %": 39.7,
    "out:Op energy cost/MSEK": 69,
    "out:El price/MSEK": 41,
    "out:Heating price/MSEK": 24,
    "out:DH+ DHW/kWh/m2": 125,
    "out:El bought/kWh/m2": 439.497378,
    "out:Return %": 12,
    "out:Return/kSEK/y": 519,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 35.445,
    "out:EAHP (El)": 0,
    "out:PV (kr/kWp)": 17461.56255567083,
    "out:PV (kWp installed)": 45.25485,
    "out:PV (m2 panels)": 237.433632,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 42461.284501,
    "out:PV (kWh sold)": 30537.914073,
    "out:PV (% sold (El))": 71.919431,
    "out:PV (kWh self-consumed)": 11923.370428,
    "out:PV (ratio sold/self-consumed)": 2.561181,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 141127.080336,
    "out:EL kWh savings": 20182.29692,
    "out:Sewage heat recovery cost": 273216,
    "out:Balancing hydronic system cost": 79599.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 227298.55,
    "out:DH kr savings": 141127.0803361564,
    "out:El kr savings": 40364.59383912171,
    "out:El kr sold": 45806.87111012268,
    "out:El kr saved": 23846.740856,
    "out:El kr return": 69653.61196612268,
    "out:% solar/total": 30,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 285931.011244,
    "out:% savings (space heating)": 29.771213,
    "out:Total_Water Heating (DH)": 112856.846044,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 89.8,
    "out:Etvv": 21.25,
    "out:Ef": 6.23,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 4454329.240742325,
    "out:Seasonal Variation ROI (%)": 5,
    "out:Seasonal Variation Payback": 20,
    "out:New AHU Cost/SEK": 146792.87151982498,
    "out:DH CO2": 44.998,
    "out:EL CO2": -47.831449,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -48.655512,
    "out:Bought CO2": 0.824063,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02000000020000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 93.793075,
    "out:DHW (DH)": 41.7,
    "out:Property Electricity": 12.57,
    "out:Electricity (inc PV)": 7.530921,
    "out:Total Energy Use Pre PV": 147.57,
    "out:Total Energy Use Post PV": 142.530921,
    "out:Primary Energy": 104.005827,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 43.2394,
    "out:CO2 Operational/m2": -60.358798,
    "out:Total CO2/m2": -17.119398,
    "out:Total CO2 (tons)": -54.508147,
    "out:Klimatpaverkan": -254.643568,
    "out:Initial Cost/MSEK": 4.74493374594375,
    "out:Running cost/(Apt SEK y)": 26441.617021,
    "out:Running Cost over RSP/MSEK": 9.74253,
    "out:LCP/MSEK": -1.082744,
    "out:ROI% old": 11.025865,
    "out:Payback discounted": 11,
    "out:Atemp": 3184,
    "out:Umean": 0.55,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 143,
    "out:Energy use kWh/m2": 143,
    "out:Energy savings %": 28,
    "out:Op energy cost/MSEK": 72,
    "out:El price/MSEK": 42,
    "out:Heating price/MSEK": 26,
    "out:DH+ DHW/kWh/m2": 135,
    "out:El bought/kWh/m2": 530.25352,
    "out:Return %": 10,
    "out:Return/kSEK/y": 472,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 41.7,
    "out:EAHP (El)": 0,
    "out:PV (kr/kWp)": 17461.48474631725,
    "out:PV (kWp installed)": 90.5097,
    "out:PV (m2 panels)": 474.867263,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 84922.568824,
    "out:PV (kWh sold)": 68878.145463,
    "out:PV (% sold (El))": 81.106997,
    "out:PV (kWh self-consumed)": 16044.423361,
    "out:PV (ratio sold/self-consumed)": 4.292965,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 108505.1179,
    "out:EL kWh savings": 16044.422709,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 243911.18,
    "out:DH kr savings": 108505.11790023575,
    "out:El kr savings": 32088.845417124598,
    "out:El kr sold": 103317.21819422109,
    "out:El kr saved": 32088.846722,
    "out:El kr return": 135406.06491622108,
    "out:% solar/total": 40,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 298637.059673,
    "out:% savings (space heating)": 26.650424,
    "out:Total_Water Heating (DH)": 132772.760051,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 93.79,
    "out:Etvv": 25,
    "out:Ef": 7.53,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 4744933.74594375,
    "out:Seasonal Variation ROI (%)": 5,
    "out:Seasonal Variation Payback": 20,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 48.59784,
    "out:EL CO2": -108.956638,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -109.952146,
    "out:Bought CO2": 0.995508,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02000000020000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 89.80248,
    "out:DHW (DH)": 41.7,
    "out:Property Electricity": 12.57,
    "out:Electricity (inc PV)": 7.530921,
    "out:Total Energy Use Pre PV": 144.57,
    "out:Total Energy Use Post PV": 139.530921,
    "out:Primary Energy": 100.902031,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 43.2394,
    "out:CO2 Operational/m2": -61.43875,
    "out:Total CO2/m2": -18.19935,
    "out:Total CO2 (tons)": -57.946713,
    "out:Klimatpaverkan": -258.082134,
    "out:Initial Cost/MSEK": 4.82453372094375,
    "out:Running cost/(Apt SEK y)": 25827.851064,
    "out:Running Cost over RSP/MSEK": 9.5169,
    "out:LCP/MSEK": -0.936714,
    "out:ROI% old": 11.512053,
    "out:Payback discounted": 11,
    "out:Atemp": 3184,
    "out:Umean": 0.55,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 140,
    "out:Energy use kWh/m2": 140,
    "out:Energy savings %": 30.7,
    "out:Op energy cost/MSEK": 71,
    "out:El price/MSEK": 41,
    "out:Heating price/MSEK": 26,
    "out:DH+ DHW/kWh/m2": 132,
    "out:El bought/kWh/m2": 530.25352,
    "out:Return %": 10,
    "out:Return/kSEK/y": 501,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 41.7,
    "out:EAHP (El)": 0,
    "out:PV (kr/kWp)": 17461.48474631725,
    "out:PV (kWp installed)": 90.5097,
    "out:PV (m2 panels)": 474.867263,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 84922.568824,
    "out:PV (kWh sold)": 68878.145463,
    "out:PV (% sold (El))": 81.106997,
    "out:PV (kWh self-consumed)": 16044.423361,
    "out:PV (ratio sold/self-consumed)": 4.292965,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 121211.166328,
    "out:EL kWh savings": 16044.422709,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 79599.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 256617.23,
    "out:DH kr savings": 121211.1663284464,
    "out:El kr savings": 32088.845417124598,
    "out:El kr sold": 103317.21819422109,
    "out:El kr saved": 32088.846722,
    "out:El kr return": 135406.06491622108,
    "out:% solar/total": 40,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 285931.011244,
    "out:% savings (space heating)": 29.771213,
    "out:Total_Water Heating (DH)": 132772.760051,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 89.8,
    "out:Etvv": 25,
    "out:Ef": 7.53,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 4824533.72094375,
    "out:Seasonal Variation ROI (%)": 5,
    "out:Seasonal Variation Payback": 20,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 47.517888,
    "out:EL CO2": -108.956638,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -109.952146,
    "out:Bought CO2": 0.995508,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02000000020001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 93.793075,
    "out:DHW (DH)": 35.445,
    "out:Property Electricity": 12.57,
    "out:Electricity (inc PV)": 7.530921,
    "out:Total Energy Use Pre PV": 141.57,
    "out:Total Energy Use Post PV": 136.530921,
    "out:Primary Energy": 101.380827,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 43.947943,
    "out:CO2 Operational/m2": -62.518702,
    "out:Total CO2/m2": -18.570759,
    "out:Total CO2 (tons)": -59.129279,
    "out:Klimatpaverkan": -259.2647,
    "out:Initial Cost/MSEK": 5.0181497459437505,
    "out:Running cost/(Apt SEK y)": 25214.085106,
    "out:Running Cost over RSP/MSEK": 9.29126,
    "out:LCP/MSEK": -0.90469,
    "out:ROI% old": 11.710235,
    "out:Payback discounted": 10,
    "out:Atemp": 3184,
    "out:Umean": 0.55,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 137,
    "out:Energy use kWh/m2": 137,
    "out:Energy savings %": 33.6,
    "out:Op energy cost/MSEK": 69,
    "out:El price/MSEK": 40,
    "out:Heating price/MSEK": 25,
    "out:DH+ DHW/kWh/m2": 129,
    "out:El bought/kWh/m2": 530.25352,
    "out:Return %": 11,
    "out:Return/kSEK/y": 530,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 35.445,
    "out:EAHP (El)": 0,
    "out:PV (kr/kWp)": 17461.48474631725,
    "out:PV (kWp installed)": 90.5097,
    "out:PV (m2 panels)": 474.867263,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 84922.568824,
    "out:PV (kWh sold)": 68878.145463,
    "out:PV (% sold (El))": 81.106997,
    "out:PV (kWh self-consumed)": 16044.423361,
    "out:PV (ratio sold/self-consumed)": 4.292965,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 128421.031908,
    "out:EL kWh savings": 16044.422709,
    "out:Sewage heat recovery cost": 273216,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 263827.1,
    "out:DH kr savings": 128421.03190794574,
    "out:El kr savings": 32088.845417124598,
    "out:El kr sold": 103317.21819422109,
    "out:El kr saved": 32088.846722,
    "out:El kr return": 135406.06491622108,
    "out:% solar/total": 40,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 298637.059673,
    "out:% savings (space heating)": 26.650424,
    "out:Total_Water Heating (DH)": 112856.846044,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 93.79,
    "out:Etvv": 21.25,
    "out:Ef": 7.53,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 5018149.74594375,
    "out:Seasonal Variation ROI (%)": 5,
    "out:Seasonal Variation Payback": 20,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 46.437936,
    "out:EL CO2": -108.956638,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -109.952146,
    "out:Bought CO2": 0.995508,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02000000020001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 89.80248,
    "out:DHW (DH)": 35.445,
    "out:Property Electricity": 12.57,
    "out:Electricity (inc PV)": 7.530921,
    "out:Total Energy Use Pre PV": 137.57,
    "out:Total Energy Use Post PV": 132.530921,
    "out:Primary Energy": 98.277031,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 43.947943,
    "out:CO2 Operational/m2": -63.958638,
    "out:Total CO2/m2": -20.010695,
    "out:Total CO2 (tons)": -63.714034,
    "out:Klimatpaverkan": -263.849455,
    "out:Initial Cost/MSEK": 5.0977497209437495,
    "out:Running cost/(Apt SEK y)": 24395.723404,
    "out:Running Cost over RSP/MSEK": 8.99042,
    "out:LCP/MSEK": -0.68345,
    "out:ROI% old": 12.370444,
    "out:Payback discounted": 10,
    "out:Atemp": 3184,
    "out:Umean": 0.55,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 133,
    "out:Energy use kWh/m2": 133,
    "out:Energy savings %": 37.6,
    "out:Op energy cost/MSEK": 67,
    "out:El price/MSEK": 38,
    "out:Heating price/MSEK": 24,
    "out:DH+ DHW/kWh/m2": 125,
    "out:El bought/kWh/m2": 530.25352,
    "out:Return %": 11,
    "out:Return/kSEK/y": 568,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 35.445,
    "out:EAHP (El)": 0,
    "out:PV (kr/kWp)": 17461.48474631725,
    "out:PV (kWp installed)": 90.5097,
    "out:PV (m2 panels)": 474.867263,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 84922.568824,
    "out:PV (kWh sold)": 68878.145463,
    "out:PV (% sold (El))": 81.106997,
    "out:PV (kWh self-consumed)": 16044.423361,
    "out:PV (ratio sold/self-consumed)": 4.292965,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 141127.080336,
    "out:EL kWh savings": 16044.422709,
    "out:Sewage heat recovery cost": 273216,
    "out:Balancing hydronic system cost": 79599.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 276533.14,
    "out:DH kr savings": 141127.0803361564,
    "out:El kr savings": 32088.845417124598,
    "out:El kr sold": 103317.21819422109,
    "out:El kr saved": 32088.846722,
    "out:El kr return": 135406.06491622108,
    "out:% solar/total": 40,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 285931.011244,
    "out:% savings (space heating)": 29.771213,
    "out:Total_Water Heating (DH)": 112856.846044,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 89.8,
    "out:Etvv": 21.25,
    "out:Ef": 7.53,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 5097749.72094375,
    "out:Seasonal Variation ROI (%)": 5,
    "out:Seasonal Variation Payback": 20,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 44.998,
    "out:EL CO2": -108.956638,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -109.952146,
    "out:Bought CO2": 0.995508,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02000000020100000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 1,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 93.793075,
    "out:DHW (DH)": 41.7,
    "out:Property Electricity": 9.976115,
    "out:Electricity (inc PV)": 5.879188,
    "out:Total Energy Use Pre PV": 144.976115,
    "out:Total Energy Use Post PV": 140.879188,
    "out:Primary Energy": 101.032707,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 43.423902,
    "out:CO2 Operational/m2": -65.444524,
    "out:Total CO2/m2": -22.020622,
    "out:Total CO2 (tons)": -70.113639,
    "out:Klimatpaverkan": -270.24906,
    "out:Initial Cost/MSEK": 4.891726617463575,
    "out:Running cost/(Apt SEK y)": 26122.085106,
    "out:Running Cost over RSP/MSEK": 9.62638,
    "out:LCP/MSEK": -1.113387,
    "out:ROI% old": 11.034199,
    "out:Payback discounted": 11,
    "out:Atemp": 3184,
    "out:Umean": 0.55,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 141,
    "out:Energy use kWh/m2": 141,
    "out:Energy savings %": 29.8,
    "out:Op energy cost/MSEK": 72,
    "out:El price/MSEK": 41,
    "out:Heating price/MSEK": 26,
    "out:DH+ DHW/kWh/m2": 135,
    "out:El bought/kWh/m2": 413.65837,
    "out:Return %": 10,
    "out:Return/kSEK/y": 487,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 41.7,
    "out:EAHP (El)": 0,
    "out:PV (kr/kWp)": 17461.48474631725,
    "out:PV (kWp installed)": 90.5097,
    "out:PV (m2 panels)": 474.867263,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 84922.568824,
    "out:PV (kWh sold)": 71877.957046,
    "out:PV (% sold (El))": 84.639405,
    "out:PV (kWh self-consumed)": 13044.611778,
    "out:PV (ratio sold/self-consumed)": 5.510165,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 108505.1179,
    "out:EL kWh savings": 21303.538998,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 258929.13,
    "out:DH kr savings": 108505.11790023575,
    "out:El kr savings": 42607.077996404216,
    "out:El kr sold": 107816.93556907076,
    "out:El kr saved": 26089.223556,
    "out:El kr return": 133906.15912507076,
    "out:% solar/total": 33,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 298637.059673,
    "out:% savings (space heating)": 26.650424,
    "out:Total_Water Heating (DH)": 132772.760051,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 93.79,
    "out:Etvv": 25,
    "out:Ef": 5.88,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 4891726.617463575,
    "out:Seasonal Variation ROI (%)": 5,
    "out:Seasonal Variation Payback": 20,
    "out:New AHU Cost/SEK": 146792.87151982498,
    "out:DH CO2": 48.59784,
    "out:EL CO2": -114.042364,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -114.819396,
    "out:Bought CO2": 0.777032,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02000000020100100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 1,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 89.80248,
    "out:DHW (DH)": 41.7,
    "out:Property Electricity": 9.976115,
    "out:Electricity (inc PV)": 5.879188,
    "out:Total Energy Use Pre PV": 141.976115,
    "out:Total Energy Use Post PV": 137.879188,
    "out:Primary Energy": 97.928911,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 43.423902,
    "out:CO2 Operational/m2": -66.524476,
    "out:Total CO2/m2": -23.100574,
    "out:Total CO2 (tons)": -73.552205,
    "out:Klimatpaverkan": -273.687627,
    "out:Initial Cost/MSEK": 4.9713265924635754,
    "out:Running cost/(Apt SEK y)": 25508.319149,
    "out:Running Cost over RSP/MSEK": 9.40075,
    "out:LCP/MSEK": -0.967357,
    "out:ROI% old": 11.505897,
    "out:Payback discounted": 11,
    "out:Atemp": 3184,
    "out:Umean": 0.55,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 138,
    "out:Energy use kWh/m2": 138,
    "out:Energy savings %": 32.6,
    "out:Op energy cost/MSEK": 70,
    "out:El price/MSEK": 40,
    "out:Heating price/MSEK": 26,
    "out:DH+ DHW/kWh/m2": 132,
    "out:El bought/kWh/m2": 413.65837,
    "out:Return %": 10,
    "out:Return/kSEK/y": 516,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 41.7,
    "out:EAHP (El)": 0,
    "out:PV (kr/kWp)": 17461.48474631725,
    "out:PV (kWp installed)": 90.5097,
    "out:PV (m2 panels)": 474.867263,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 84922.568824,
    "out:PV (kWh sold)": 71877.957046,
    "out:PV (% sold (El))": 84.639405,
    "out:PV (kWh self-consumed)": 13044.611778,
    "out:PV (ratio sold/self-consumed)": 5.510165,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 121211.166328,
    "out:EL kWh savings": 21303.538998,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 79599.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 271635.18,
    "out:DH kr savings": 121211.1663284464,
    "out:El kr savings": 42607.077996404216,
    "out:El kr sold": 107816.93556907076,
    "out:El kr saved": 26089.223556,
    "out:El kr return": 133906.15912507076,
    "out:% solar/total": 33,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 285931.011244,
    "out:% savings (space heating)": 29.771213,
    "out:Total_Water Heating (DH)": 132772.760051,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 89.8,
    "out:Etvv": 25,
    "out:Ef": 5.88,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 4971326.592463575,
    "out:Seasonal Variation ROI (%)": 5,
    "out:Seasonal Variation Payback": 20,
    "out:New AHU Cost/SEK": 146792.87151982498,
    "out:DH CO2": 47.517888,
    "out:EL CO2": -114.042364,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -114.819396,
    "out:Bought CO2": 0.777032,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02000000020101000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 1,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 93.793075,
    "out:DHW (DH)": 35.445,
    "out:Property Electricity": 9.976115,
    "out:Electricity (inc PV)": 5.879188,
    "out:Total Energy Use Pre PV": 138.976115,
    "out:Total Energy Use Post PV": 134.879188,
    "out:Primary Energy": 98.407707,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 44.132445,
    "out:CO2 Operational/m2": -67.604428,
    "out:Total CO2/m2": -23.471983,
    "out:Total CO2 (tons)": -74.734771,
    "out:Klimatpaverkan": -274.870192,
    "out:Initial Cost/MSEK": 5.164942617463574,
    "out:Running cost/(Apt SEK y)": 24894.553191,
    "out:Running Cost over RSP/MSEK": 9.17512,
    "out:LCP/MSEK": -0.935343,
    "out:ROI% old": 11.69865,
    "out:Payback discounted": 10,
    "out:Atemp": 3184,
    "out:Umean": 0.55,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 135,
    "out:Energy use kWh/m2": 135,
    "out:Energy savings %": 35.6,
    "out:Op energy cost/MSEK": 68,
    "out:El price/MSEK": 39,
    "out:Heating price/MSEK": 25,
    "out:DH+ DHW/kWh/m2": 129,
    "out:El bought/kWh/m2": 413.65837,
    "out:Return %": 11,
    "out:Return/kSEK/y": 545,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 35.445,
    "out:EAHP (El)": 0,
    "out:PV (kr/kWp)": 17461.48474631725,
    "out:PV (kWp installed)": 90.5097,
    "out:PV (m2 panels)": 474.867263,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 84922.568824,
    "out:PV (kWh sold)": 71877.957046,
    "out:PV (% sold (El))": 84.639405,
    "out:PV (kWh self-consumed)": 13044.611778,
    "out:PV (ratio sold/self-consumed)": 5.510165,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 128421.031908,
    "out:EL kWh savings": 21303.538998,
    "out:Sewage heat recovery cost": 273216,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 278845.05,
    "out:DH kr savings": 128421.03190794574,
    "out:El kr savings": 42607.077996404216,
    "out:El kr sold": 107816.93556907076,
    "out:El kr saved": 26089.223556,
    "out:El kr return": 133906.15912507076,
    "out:% solar/total": 33,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 298637.059673,
    "out:% savings (space heating)": 26.650424,
    "out:Total_Water Heating (DH)": 112856.846044,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 93.79,
    "out:Etvv": 21.25,
    "out:Ef": 5.88,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 5164942.617463575,
    "out:Seasonal Variation ROI (%)": 5,
    "out:Seasonal Variation Payback": 20,
    "out:New AHU Cost/SEK": 146792.87151982498,
    "out:DH CO2": 46.437936,
    "out:EL CO2": -114.042364,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -114.819396,
    "out:Bought CO2": 0.777032,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02000000020101100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 1,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 89.80248,
    "out:DHW (DH)": 35.445,
    "out:Property Electricity": 9.976115,
    "out:Electricity (inc PV)": 5.879188,
    "out:Total Energy Use Pre PV": 134.976115,
    "out:Total Energy Use Post PV": 130.879188,
    "out:Primary Energy": 95.303911,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 44.132445,
    "out:CO2 Operational/m2": -69.044364,
    "out:Total CO2/m2": -24.911919,
    "out:Total CO2 (tons)": -79.319526,
    "out:Klimatpaverkan": -279.454947,
    "out:Initial Cost/MSEK": 5.244542592463575,
    "out:Running cost/(Apt SEK y)": 24076.191489,
    "out:Running Cost over RSP/MSEK": 8.87427,
    "out:LCP/MSEK": -0.714093,
    "out:ROI% old": 12.340583,
    "out:Payback discounted": 10,
    "out:Atemp": 3184,
    "out:Umean": 0.55,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 131,
    "out:Energy use kWh/m2": 131,
    "out:Energy savings %": 39.7,
    "out:Op energy cost/MSEK": 66,
    "out:El price/MSEK": 38,
    "out:Heating price/MSEK": 24,
    "out:DH+ DHW/kWh/m2": 125,
    "out:El bought/kWh/m2": 413.65837,
    "out:Return %": 11,
    "out:Return/kSEK/y": 583,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 35.445,
    "out:EAHP (El)": 0,
    "out:PV (kr/kWp)": 17461.48474631725,
    "out:PV (kWp installed)": 90.5097,
    "out:PV (m2 panels)": 474.867263,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 84922.568824,
    "out:PV (kWh sold)": 71877.957046,
    "out:PV (% sold (El))": 84.639405,
    "out:PV (kWh self-consumed)": 13044.611778,
    "out:PV (ratio sold/self-consumed)": 5.510165,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 141127.080336,
    "out:EL kWh savings": 21303.538998,
    "out:Sewage heat recovery cost": 273216,
    "out:Balancing hydronic system cost": 79599.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 291551.09,
    "out:DH kr savings": 141127.0803361564,
    "out:El kr savings": 42607.077996404216,
    "out:El kr sold": 107816.93556907076,
    "out:El kr saved": 26089.223556,
    "out:El kr return": 133906.15912507076,
    "out:% solar/total": 33,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 285931.011244,
    "out:% savings (space heating)": 29.771213,
    "out:Total_Water Heating (DH)": 112856.846044,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 89.8,
    "out:Etvv": 21.25,
    "out:Ef": 5.88,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 5244542.592463575,
    "out:Seasonal Variation ROI (%)": 5,
    "out:Seasonal Variation Payback": 20,
    "out:New AHU Cost/SEK": 146792.87151982498,
    "out:DH CO2": 44.998,
    "out:EL CO2": -114.042364,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -114.819396,
    "out:Bought CO2": 0.777032,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02000000100000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 93.793075,
    "out:DHW (DH)": 33.36,
    "out:Property Electricity": 12.57,
    "out:Electricity (inc PV)": 12.57,
    "out:Total Energy Use Pre PV": 139.57,
    "out:Total Energy Use Post PV": 139.57,
    "out:Primary Energy": 109.576169,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 13.761201,
    "out:CO2 Operational/m2": 47.377309,
    "out:Total CO2/m2": 61.13851,
    "out:Total CO2 (tons)": 194.664957,
    "out:Klimatpaverkan": -5.470464,
    "out:Initial Cost/MSEK": 3.2885476875,
    "out:Running cost/(Apt SEK y)": 27685.893617,
    "out:Running Cost over RSP/MSEK": 10.17064,
    "out:LCP/MSEK": -0.054468,
    "out:ROI% old": 14.049102,
    "out:Payback discounted": 9,
    "out:Atemp": 3184,
    "out:Umean": 0.55,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 140,
    "out:Energy use kWh/m2": 140,
    "out:Energy savings %": 30.7,
    "out:Op energy cost/MSEK": 74,
    "out:El price/MSEK": 45,
    "out:Heating price/MSEK": 25,
    "out:DH+ DHW/kWh/m2": 127,
    "out:El bought/kWh/m2": 879.9,
    "out:Return %": 13,
    "out:Return/kSEK/y": 413,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 33.36,
    "out:EAHP (El)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 135059.669911,
    "out:EL kWh savings": 2.2624e-11,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 135059.67,
    "out:DH kr savings": 135059.66991051572,
    "out:El kr savings": 4.524734779920436e-11,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 298637.059673,
    "out:% savings (space heating)": 26.650424,
    "out:Total_Water Heating (DH)": 106218.208041,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 93.79,
    "out:Etvv": 20,
    "out:Ef": 12.57,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 3288547.6875,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 45.717968,
    "out:EL CO2": 1.659341,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 1.659341,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02000000100000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 89.80248,
    "out:DHW (DH)": 33.36,
    "out:Property Electricity": 12.57,
    "out:Electricity (inc PV)": 12.57,
    "out:Total Energy Use Pre PV": 135.57,
    "out:Total Energy Use Post PV": 135.57,
    "out:Primary Energy": 106.472373,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 13.761201,
    "out:CO2 Operational/m2": 45.937373,
    "out:Total CO2/m2": 59.698574,
    "out:Total CO2 (tons)": 190.080202,
    "out:Klimatpaverkan": -10.055219,
    "out:Initial Cost/MSEK": 3.3681476624999998,
    "out:Running cost/(Apt SEK y)": 26867.531915,
    "out:Running Cost over RSP/MSEK": 9.8698,
    "out:LCP/MSEK": 0.166772,
    "out:ROI% old": 14.993065,
    "out:Payback discounted": 8,
    "out:Atemp": 3184,
    "out:Umean": 0.55,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 136,
    "out:Energy use kWh/m2": 136,
    "out:Energy savings %": 34.6,
    "out:Op energy cost/MSEK": 72,
    "out:El price/MSEK": 44,
    "out:Heating price/MSEK": 24,
    "out:DH+ DHW/kWh/m2": 123,
    "out:El bought/kWh/m2": 879.9,
    "out:Return %": 13,
    "out:Return/kSEK/y": 452,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 33.36,
    "out:EAHP (El)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 147765.718339,
    "out:EL kWh savings": 2.2624e-11,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 79599.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 147765.72,
    "out:DH kr savings": 147765.7183387264,
    "out:El kr savings": 4.524734779920436e-11,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 285931.011244,
    "out:% savings (space heating)": 29.771213,
    "out:Total_Water Heating (DH)": 106218.208041,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 89.8,
    "out:Etvv": 20,
    "out:Ef": 12.57,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 3368147.6624999996,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 44.278032,
    "out:EL CO2": 1.659341,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 1.659341,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02000000100001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 93.793075,
    "out:DHW (DH)": 28.356,
    "out:Property Electricity": 12.57,
    "out:Electricity (inc PV)": 12.57,
    "out:Total Energy Use Pre PV": 134.57,
    "out:Total Energy Use Post PV": 134.57,
    "out:Primary Energy": 107.476169,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 14.469744,
    "out:CO2 Operational/m2": 45.577389,
    "out:Total CO2/m2": 60.047133,
    "out:Total CO2 (tons)": 191.190014,
    "out:Klimatpaverkan": -8.945407,
    "out:Initial Cost/MSEK": 3.5617636874999996,
    "out:Running cost/(Apt SEK y)": 26662.93617,
    "out:Running Cost over RSP/MSEK": 9.79458,
    "out:LCP/MSEK": 0.048376,
    "out:ROI% old": 14.479745,
    "out:Payback discounted": 8,
    "out:Atemp": 3184,
    "out:Umean": 0.55,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 135,
    "out:Energy use kWh/m2": 135,
    "out:Energy savings %": 35.6,
    "out:Op energy cost/MSEK": 72,
    "out:El price/MSEK": 44,
    "out:Heating price/MSEK": 24,
    "out:DH+ DHW/kWh/m2": 122,
    "out:El bought/kWh/m2": 879.9,
    "out:Return %": 13,
    "out:Return/kSEK/y": 462,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 28.356,
    "out:EAHP (El)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 150992.401117,
    "out:EL kWh savings": 2.2624e-11,
    "out:Sewage heat recovery cost": 273216,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 150992.4,
    "out:DH kr savings": 150992.40111668373,
    "out:El kr savings": 4.524734779920436e-11,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 298637.059673,
    "out:% savings (space heating)": 26.650424,
    "out:Total_Water Heating (DH)": 90285.476835,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 93.79,
    "out:Etvv": 17,
    "out:Ef": 12.57,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 3561763.6874999995,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 43.918048,
    "out:EL CO2": 1.659341,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 1.659341,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02000000100001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 89.80248,
    "out:DHW (DH)": 28.356,
    "out:Property Electricity": 12.57,
    "out:Electricity (inc PV)": 12.57,
    "out:Total Energy Use Pre PV": 130.57,
    "out:Total Energy Use Post PV": 130.57,
    "out:Primary Energy": 104.372373,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 14.469744,
    "out:CO2 Operational/m2": 44.137453,
    "out:Total CO2/m2": 58.607197,
    "out:Total CO2 (tons)": 186.605259,
    "out:Klimatpaverkan": -13.530162,
    "out:Initial Cost/MSEK": 3.6413636624999994,
    "out:Running cost/(Apt SEK y)": 25844.595745,
    "out:Running Cost over RSP/MSEK": 9.49374,
    "out:LCP/MSEK": 0.269616,
    "out:ROI% old": 15.343467,
    "out:Payback discounted": 8,
    "out:Atemp": 3184,
    "out:Umean": 0.55,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 131,
    "out:Energy use kWh/m2": 131,
    "out:Energy savings %": 39.7,
    "out:Op energy cost/MSEK": 69,
    "out:El price/MSEK": 43,
    "out:Heating price/MSEK": 23,
    "out:DH+ DHW/kWh/m2": 118,
    "out:El bought/kWh/m2": 879.9,
    "out:Return %": 14,
    "out:Return/kSEK/y": 500,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 28.356,
    "out:EAHP (El)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 163698.449545,
    "out:EL kWh savings": 2.2624e-11,
    "out:Sewage heat recovery cost": 273216,
    "out:Balancing hydronic system cost": 79599.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 163698.45,
    "out:DH kr savings": 163698.4495448944,
    "out:El kr savings": 4.524734779920436e-11,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 285931.011244,
    "out:% savings (space heating)": 29.771213,
    "out:Total_Water Heating (DH)": 90285.476835,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 89.8,
    "out:Etvv": 17,
    "out:Ef": 12.57,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 3641363.6624999996,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 42.478112,
    "out:EL CO2": 1.659341,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 1.659341,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02000000100100000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 1,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 93.793075,
    "out:DHW (DH)": 33.36,
    "out:Property Electricity": 9.976115,
    "out:Electricity (inc PV)": 9.976115,
    "out:Total Energy Use Pre PV": 136.976115,
    "out:Total Energy Use Post PV": 136.976115,
    "out:Primary Energy": 104.907176,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 13.945703,
    "out:CO2 Operational/m2": 47.034895,
    "out:Total CO2/m2": 60.980598,
    "out:Total CO2 (tons)": 194.162166,
    "out:Klimatpaverkan": -5.973255,
    "out:Initial Cost/MSEK": 3.4353405590198243,
    "out:Running cost/(Apt SEK y)": 27334.446809,
    "out:Running Cost over RSP/MSEK": 10.04294,
    "out:LCP/MSEK": -0.073561,
    "out:ROI% old": 13.979816,
    "out:Payback discounted": 9,
    "out:Atemp": 3184,
    "out:Umean": 0.55,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 137,
    "out:Energy use kWh/m2": 137,
    "out:Energy savings %": 33.6,
    "out:Op energy cost/MSEK": 73,
    "out:El price/MSEK": 45,
    "out:Heating price/MSEK": 25,
    "out:DH+ DHW/kWh/m2": 127,
    "out:El bought/kWh/m2": 698.32805,
    "out:Return %": 13,
    "out:Return/kSEK/y": 430,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 33.36,
    "out:EAHP (El)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 135059.669911,
    "out:EL kWh savings": 8258.927355,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 151577.52,
    "out:DH kr savings": 135059.66991051572,
    "out:El kr savings": 16517.854710116386,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 298637.059673,
    "out:% savings (space heating)": 26.650424,
    "out:Total_Water Heating (DH)": 106218.208041,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 93.79,
    "out:Etvv": 20,
    "out:Ef": 9.98,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 3435340.5590198245,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25,
    "out:New AHU Cost/SEK": 146792.87151982498,
    "out:DH CO2": 45.717968,
    "out:EL CO2": 1.316927,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 1.316927,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02000000100100100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 1,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 89.80248,
    "out:DHW (DH)": 33.36,
    "out:Property Electricity": 9.976115,
    "out:Electricity (inc PV)": 9.976115,
    "out:Total Energy Use Pre PV": 132.976115,
    "out:Total Energy Use Post PV": 132.976115,
    "out:Primary Energy": 101.80338,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 13.945703,
    "out:CO2 Operational/m2": 45.594959,
    "out:Total CO2/m2": 59.540662,
    "out:Total CO2 (tons)": 189.577411,
    "out:Klimatpaverkan": -10.55801,
    "out:Initial Cost/MSEK": 3.5149405340198245,
    "out:Running cost/(Apt SEK y)": 26516.085106,
    "out:Running Cost over RSP/MSEK": 9.7421,
    "out:LCP/MSEK": 0.147679,
    "out:ROI% old": 14.885925,
    "out:Payback discounted": 8,
    "out:Atemp": 3184,
    "out:Umean": 0.55,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 133,
    "out:Energy use kWh/m2": 133,
    "out:Energy savings %": 37.6,
    "out:Op energy cost/MSEK": 71,
    "out:El price/MSEK": 43,
    "out:Heating price/MSEK": 24,
    "out:DH+ DHW/kWh/m2": 123,
    "out:El bought/kWh/m2": 698.32805,
    "out:Return %": 13,
    "out:Return/kSEK/y": 468,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 33.36,
    "out:EAHP (El)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 147765.718339,
    "out:EL kWh savings": 8258.927355,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 79599.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 164283.57,
    "out:DH kr savings": 147765.7183387264,
    "out:El kr savings": 16517.854710116386,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 285931.011244,
    "out:% savings (space heating)": 29.771213,
    "out:Total_Water Heating (DH)": 106218.208041,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 89.8,
    "out:Etvv": 20,
    "out:Ef": 9.98,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 3514940.5340198246,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25,
    "out:New AHU Cost/SEK": 146792.87151982498,
    "out:DH CO2": 44.278032,
    "out:EL CO2": 1.316927,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 1.316927,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02000000100101000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 1,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 93.793075,
    "out:DHW (DH)": 28.356,
    "out:Property Electricity": 9.976115,
    "out:Electricity (inc PV)": 9.976115,
    "out:Total Energy Use Pre PV": 131.976115,
    "out:Total Energy Use Post PV": 131.976115,
    "out:Primary Energy": 102.807176,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 14.654246,
    "out:CO2 Operational/m2": 45.234975,
    "out:Total CO2/m2": 59.889221,
    "out:Total CO2 (tons)": 190.687222,
    "out:Klimatpaverkan": -9.448199,
    "out:Initial Cost/MSEK": 3.7085565590198244,
    "out:Running cost/(Apt SEK y)": 26311.489362,
    "out:Running Cost over RSP/MSEK": 9.66689,
    "out:LCP/MSEK": 0.029273,
    "out:ROI% old": 14.398478,
    "out:Payback discounted": 8,
    "out:Atemp": 3184,
    "out:Umean": 0.55,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 132,
    "out:Energy use kWh/m2": 132,
    "out:Energy savings %": 38.6,
    "out:Op energy cost/MSEK": 71,
    "out:El price/MSEK": 43,
    "out:Heating price/MSEK": 24,
    "out:DH+ DHW/kWh/m2": 122,
    "out:El bought/kWh/m2": 698.32805,
    "out:Return %": 13,
    "out:Return/kSEK/y": 478,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 28.356,
    "out:EAHP (El)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 150992.401117,
    "out:EL kWh savings": 8258.927355,
    "out:Sewage heat recovery cost": 273216,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 167510.26,
    "out:DH kr savings": 150992.40111668373,
    "out:El kr savings": 16517.854710116386,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 298637.059673,
    "out:% savings (space heating)": 26.650424,
    "out:Total_Water Heating (DH)": 90285.476835,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 93.79,
    "out:Etvv": 17,
    "out:Ef": 9.98,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 3708556.5590198245,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25,
    "out:New AHU Cost/SEK": 146792.87151982498,
    "out:DH CO2": 43.918048,
    "out:EL CO2": 1.316927,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 1.316927,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02000000100101100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 1,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 89.80248,
    "out:DHW (DH)": 28.356,
    "out:Property Electricity": 9.976115,
    "out:Electricity (inc PV)": 9.976115,
    "out:Total Energy Use Pre PV": 127.976115,
    "out:Total Energy Use Post PV": 127.976115,
    "out:Primary Energy": 99.70338,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 14.654246,
    "out:CO2 Operational/m2": 43.795039,
    "out:Total CO2/m2": 58.449285,
    "out:Total CO2 (tons)": 186.102467,
    "out:Klimatpaverkan": -14.032954,
    "out:Initial Cost/MSEK": 3.7881565340198247,
    "out:Running cost/(Apt SEK y)": 25493.148936,
    "out:Running Cost over RSP/MSEK": 9.36604,
    "out:LCP/MSEK": 0.250523,
    "out:ROI% old": 15.230476,
    "out:Payback discounted": 8,
    "out:Atemp": 3184,
    "out:Umean": 0.55,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 128,
    "out:Energy use kWh/m2": 128,
    "out:Energy savings %": 43,
    "out:Op energy cost/MSEK": 68,
    "out:El price/MSEK": 42,
    "out:Heating price/MSEK": 23,
    "out:DH+ DHW/kWh/m2": 118,
    "out:El bought/kWh/m2": 698.32805,
    "out:Return %": 14,
    "out:Return/kSEK/y": 517,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 28.356,
    "out:EAHP (El)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 163698.449545,
    "out:EL kWh savings": 8258.927355,
    "out:Sewage heat recovery cost": 273216,
    "out:Balancing hydronic system cost": 79599.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 180216.3,
    "out:DH kr savings": 163698.4495448944,
    "out:El kr savings": 16517.854710116386,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 285931.011244,
    "out:% savings (space heating)": 29.771213,
    "out:Total_Water Heating (DH)": 90285.476835,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 89.8,
    "out:Etvv": 17,
    "out:Ef": 9.98,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 3788156.5340198246,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25,
    "out:New AHU Cost/SEK": 146792.87151982498,
    "out:DH CO2": 42.478112,
    "out:EL CO2": 1.316927,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 1.316927,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02000000110000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 93.793075,
    "out:DHW (DH)": 33.36,
    "out:Property Electricity": 12.57,
    "out:Electricity (inc PV)": 8.051517,
    "out:Total Energy Use Pre PV": 139.57,
    "out:Total Energy Use Post PV": 135.051517,
    "out:Primary Energy": 101.4429,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 28.500301,
    "out:CO2 Operational/m2": 2.116647,
    "out:Total CO2/m2": 30.616948,
    "out:Total CO2 (tons)": 97.484333,
    "out:Klimatpaverkan": -102.651088,
    "out:Initial Cost/MSEK": 4.0787680817225,
    "out:Running cost/(Apt SEK y)": 26177.680851,
    "out:Running Cost over RSP/MSEK": 9.63127,
    "out:LCP/MSEK": -0.305318,
    "out:ROI% old": 13.21635,
    "out:Payback discounted": 9,
    "out:Atemp": 3184,
    "out:Umean": 0.55,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 135,
    "out:Energy use kWh/m2": 135,
    "out:Energy savings %": 35.6,
    "out:Op energy cost/MSEK": 71,
    "out:El price/MSEK": 42,
    "out:Heating price/MSEK": 25,
    "out:DH+ DHW/kWh/m2": 127,
    "out:El bought/kWh/m2": 568.258452,
    "out:Return %": 12,
    "out:Return/kSEK/y": 484,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 33.36,
    "out:EAHP (El)": 0,
    "out:PV (kr/kWp)": 17461.56255567083,
    "out:PV (kWp installed)": 45.25485,
    "out:PV (m2 panels)": 237.433632,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 42461.284501,
    "out:PV (kWh sold)": 28074.439514,
    "out:PV (% sold (El))": 66.117735,
    "out:PV (kWh self-consumed)": 14386.844987,
    "out:PV (ratio sold/self-consumed)": 1.951397,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 135059.669911,
    "out:EL kWh savings": 14386.845543,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 205945.02,
    "out:DH kr savings": 135059.66991051572,
    "out:El kr savings": 28773.691086586638,
    "out:El kr sold": 42111.65927092506,
    "out:El kr saved": 28773.689974,
    "out:El kr return": 70885.34924492506,
    "out:% solar/total": 36,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 298637.059673,
    "out:% savings (space heating)": 26.650424,
    "out:Total_Water Heating (DH)": 106218.208041,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 93.79,
    "out:Etvv": 20,
    "out:Ef": 8.05,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 4078768.0817225003,
    "out:Seasonal Variation ROI (%)": 5,
    "out:Seasonal Variation Payback": 20,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 45.717968,
    "out:EL CO2": -43.601321,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -44.666212,
    "out:Bought CO2": 1.064891,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02000000110000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 89.80248,
    "out:DHW (DH)": 33.36,
    "out:Property Electricity": 12.57,
    "out:Electricity (inc PV)": 8.051517,
    "out:Total Energy Use Pre PV": 135.57,
    "out:Total Energy Use Post PV": 131.051517,
    "out:Primary Energy": 98.339104,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 28.500301,
    "out:CO2 Operational/m2": 0.676711,
    "out:Total CO2/m2": 29.177012,
    "out:Total CO2 (tons)": 92.899578,
    "out:Klimatpaverkan": -107.235843,
    "out:Initial Cost/MSEK": 4.1583680567225,
    "out:Running cost/(Apt SEK y)": 25359.340426,
    "out:Running Cost over RSP/MSEK": 9.33043,
    "out:LCP/MSEK": -0.084078,
    "out:ROI% old": 13.996871,
    "out:Payback discounted": 9,
    "out:Atemp": 3184,
    "out:Umean": 0.55,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 131,
    "out:Energy use kWh/m2": 131,
    "out:Energy savings %": 39.7,
    "out:Op energy cost/MSEK": 69,
    "out:El price/MSEK": 41,
    "out:Heating price/MSEK": 24,
    "out:DH+ DHW/kWh/m2": 123,
    "out:El bought/kWh/m2": 568.258452,
    "out:Return %": 13,
    "out:Return/kSEK/y": 523,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 33.36,
    "out:EAHP (El)": 0,
    "out:PV (kr/kWp)": 17461.56255567083,
    "out:PV (kWp installed)": 45.25485,
    "out:PV (m2 panels)": 237.433632,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 42461.284501,
    "out:PV (kWh sold)": 28074.439514,
    "out:PV (% sold (El))": 66.117735,
    "out:PV (kWh self-consumed)": 14386.844987,
    "out:PV (ratio sold/self-consumed)": 1.951397,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 147765.718339,
    "out:EL kWh savings": 14386.845543,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 79599.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 218651.07,
    "out:DH kr savings": 147765.7183387264,
    "out:El kr savings": 28773.691086586638,
    "out:El kr sold": 42111.65927092506,
    "out:El kr saved": 28773.689974,
    "out:El kr return": 70885.34924492506,
    "out:% solar/total": 36,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 285931.011244,
    "out:% savings (space heating)": 29.771213,
    "out:Total_Water Heating (DH)": 106218.208041,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 89.8,
    "out:Etvv": 20,
    "out:Ef": 8.05,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 4158368.0567225,
    "out:Seasonal Variation ROI (%)": 5,
    "out:Seasonal Variation Payback": 20,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 44.278032,
    "out:EL CO2": -43.601321,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -44.666212,
    "out:Bought CO2": 1.064891,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02000000110001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 93.793075,
    "out:DHW (DH)": 28.356,
    "out:Property Electricity": 12.57,
    "out:Electricity (inc PV)": 8.051517,
    "out:Total Energy Use Pre PV": 134.57,
    "out:Total Energy Use Post PV": 130.051517,
    "out:Primary Energy": 99.3429,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 29.208844,
    "out:CO2 Operational/m2": 0.316727,
    "out:Total CO2/m2": 29.525571,
    "out:Total CO2 (tons)": 94.00939,
    "out:Klimatpaverkan": -106.126031,
    "out:Initial Cost/MSEK": 4.3519840817225,
    "out:Running cost/(Apt SEK y)": 25154.744681,
    "out:Running Cost over RSP/MSEK": 9.25521,
    "out:LCP/MSEK": -0.202474,
    "out:ROI% old": 13.621078,
    "out:Payback discounted": 9,
    "out:Atemp": 3184,
    "out:Umean": 0.55,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 130,
    "out:Energy use kWh/m2": 130,
    "out:Energy savings %": 40.8,
    "out:Op energy cost/MSEK": 68,
    "out:El price/MSEK": 40,
    "out:Heating price/MSEK": 24,
    "out:DH+ DHW/kWh/m2": 122,
    "out:El bought/kWh/m2": 568.258452,
    "out:Return %": 12,
    "out:Return/kSEK/y": 532,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 28.356,
    "out:EAHP (El)": 0,
    "out:PV (kr/kWp)": 17461.56255567083,
    "out:PV (kWp installed)": 45.25485,
    "out:PV (m2 panels)": 237.433632,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 42461.284501,
    "out:PV (kWh sold)": 28074.439514,
    "out:PV (% sold (El))": 66.117735,
    "out:PV (kWh self-consumed)": 14386.844987,
    "out:PV (ratio sold/self-consumed)": 1.951397,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 150992.401117,
    "out:EL kWh savings": 14386.845543,
    "out:Sewage heat recovery cost": 273216,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 221877.75,
    "out:DH kr savings": 150992.40111668373,
    "out:El kr savings": 28773.691086586638,
    "out:El kr sold": 42111.65927092506,
    "out:El kr saved": 28773.689974,
    "out:El kr return": 70885.34924492506,
    "out:% solar/total": 36,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 298637.059673,
    "out:% savings (space heating)": 26.650424,
    "out:Total_Water Heating (DH)": 90285.476835,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 93.79,
    "out:Etvv": 17,
    "out:Ef": 8.05,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 4351984.0817225,
    "out:Seasonal Variation ROI (%)": 5,
    "out:Seasonal Variation Payback": 20,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 43.918048,
    "out:EL CO2": -43.601321,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -44.666212,
    "out:Bought CO2": 1.064891,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02000000110001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 89.80248,
    "out:DHW (DH)": 28.356,
    "out:Property Electricity": 12.57,
    "out:Electricity (inc PV)": 8.051517,
    "out:Total Energy Use Pre PV": 130.57,
    "out:Total Energy Use Post PV": 126.051517,
    "out:Primary Energy": 96.239104,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 29.208844,
    "out:CO2 Operational/m2": -1.123209,
    "out:Total CO2/m2": 28.085635,
    "out:Total CO2 (tons)": 89.424635,
    "out:Klimatpaverkan": -110.710786,
    "out:Initial Cost/MSEK": 4.4315840567224996,
    "out:Running cost/(Apt SEK y)": 24336.382979,
    "out:Running Cost over RSP/MSEK": 8.95437,
    "out:LCP/MSEK": 0.018766,
    "out:ROI% old": 14.346208,
    "out:Payback discounted": 8,
    "out:Atemp": 3184,
    "out:Umean": 0.55,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 126,
    "out:Energy use kWh/m2": 126,
    "out:Energy savings %": 45.2,
    "out:Op energy cost/MSEK": 66,
    "out:El price/MSEK": 39,
    "out:Heating price/MSEK": 23,
    "out:DH+ DHW/kWh/m2": 118,
    "out:El bought/kWh/m2": 568.258452,
    "out:Return %": 13,
    "out:Return/kSEK/y": 571,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 28.356,
    "out:EAHP (El)": 0,
    "out:PV (kr/kWp)": 17461.56255567083,
    "out:PV (kWp installed)": 45.25485,
    "out:PV (m2 panels)": 237.433632,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 42461.284501,
    "out:PV (kWh sold)": 28074.439514,
    "out:PV (% sold (El))": 66.117735,
    "out:PV (kWh self-consumed)": 14386.844987,
    "out:PV (ratio sold/self-consumed)": 1.951397,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 163698.449545,
    "out:EL kWh savings": 14386.845543,
    "out:Sewage heat recovery cost": 273216,
    "out:Balancing hydronic system cost": 79599.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 234583.8,
    "out:DH kr savings": 163698.4495448944,
    "out:El kr savings": 28773.691086586456,
    "out:El kr sold": 42111.65927092506,
    "out:El kr saved": 28773.689974,
    "out:El kr return": 70885.34924492506,
    "out:% solar/total": 36,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 285931.011244,
    "out:% savings (space heating)": 29.771213,
    "out:Total_Water Heating (DH)": 90285.476835,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 89.8,
    "out:Etvv": 17,
    "out:Ef": 8.05,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 4431584.056722499,
    "out:Seasonal Variation ROI (%)": 5,
    "out:Seasonal Variation Payback": 20,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 42.478112,
    "out:EL CO2": -43.601321,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -44.666212,
    "out:Bought CO2": 1.064891,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02000000110100000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 1,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 93.793075,
    "out:DHW (DH)": 33.36,
    "out:Property Electricity": 9.976115,
    "out:Electricity (inc PV)": 6.231337,
    "out:Total Energy Use Pre PV": 136.976115,
    "out:Total Energy Use Post PV": 133.231337,
    "out:Primary Energy": 98.166576,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 28.684803,
    "out:CO2 Operational/m2": -2.113481,
    "out:Total CO2/m2": 26.571322,
    "out:Total CO2 (tons)": 84.603064,
    "out:Klimatpaverkan": -115.532357,
    "out:Initial Cost/MSEK": 4.225560953242324,
    "out:Running cost/(Apt SEK y)": 25852.446809,
    "out:Running Cost over RSP/MSEK": 9.51304,
    "out:LCP/MSEK": -0.333881,
    "out:ROI% old": 13.156934,
    "out:Payback discounted": 9,
    "out:Atemp": 3184,
    "out:Umean": 0.55,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 133,
    "out:Energy use kWh/m2": 133,
    "out:Energy savings %": 37.6,
    "out:Op energy cost/MSEK": 70,
    "out:El price/MSEK": 41,
    "out:Heating price/MSEK": 25,
    "out:DH+ DHW/kWh/m2": 127,
    "out:El bought/kWh/m2": 439.497378,
    "out:Return %": 12,
    "out:Return/kSEK/y": 500,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 33.36,
    "out:EAHP (El)": 0,
    "out:PV (kr/kWp)": 17461.56255567083,
    "out:PV (kWp installed)": 45.25485,
    "out:PV (m2 panels)": 237.433632,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 42461.284501,
    "out:PV (kWh sold)": 30537.914073,
    "out:PV (% sold (El))": 71.919431,
    "out:PV (kWh self-consumed)": 11923.370428,
    "out:PV (ratio sold/self-consumed)": 2.561181,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 135059.669911,
    "out:EL kWh savings": 20182.29692,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 221231.13,
    "out:DH kr savings": 135059.66991051572,
    "out:El kr savings": 40364.59383912171,
    "out:El kr sold": 45806.87111012268,
    "out:El kr saved": 23846.740856,
    "out:El kr return": 69653.61196612268,
    "out:% solar/total": 30,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 298637.059673,
    "out:% savings (space heating)": 26.650424,
    "out:Total_Water Heating (DH)": 106218.208041,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 93.79,
    "out:Etvv": 20,
    "out:Ef": 6.23,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 4225560.953242324,
    "out:Seasonal Variation ROI (%)": 5,
    "out:Seasonal Variation Payback": 20,
    "out:New AHU Cost/SEK": 146792.87151982498,
    "out:DH CO2": 45.717968,
    "out:EL CO2": -47.831449,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -48.655512,
    "out:Bought CO2": 0.824063,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02000000110100100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 1,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 89.80248,
    "out:DHW (DH)": 33.36,
    "out:Property Electricity": 9.976115,
    "out:Electricity (inc PV)": 6.231337,
    "out:Total Energy Use Pre PV": 132.976115,
    "out:Total Energy Use Post PV": 129.231337,
    "out:Primary Energy": 95.06278,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 28.684803,
    "out:CO2 Operational/m2": -3.553417,
    "out:Total CO2/m2": 25.131386,
    "out:Total CO2 (tons)": 80.018309,
    "out:Klimatpaverkan": -120.117112,
    "out:Initial Cost/MSEK": 4.305160928242325,
    "out:Running cost/(Apt SEK y)": 25034.106383,
    "out:Running Cost over RSP/MSEK": 9.2122,
    "out:LCP/MSEK": -0.112641,
    "out:ROI% old": 13.911941,
    "out:Payback discounted": 9,
    "out:Atemp": 3184,
    "out:Umean": 0.55,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 129,
    "out:Energy use kWh/m2": 129,
    "out:Energy savings %": 41.9,
    "out:Op energy cost/MSEK": 68,
    "out:El price/MSEK": 40,
    "out:Heating price/MSEK": 24,
    "out:DH+ DHW/kWh/m2": 123,
    "out:El bought/kWh/m2": 439.497378,
    "out:Return %": 12,
    "out:Return/kSEK/y": 538,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 33.36,
    "out:EAHP (El)": 0,
    "out:PV (kr/kWp)": 17461.56255567083,
    "out:PV (kWp installed)": 45.25485,
    "out:PV (m2 panels)": 237.433632,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 42461.284501,
    "out:PV (kWh sold)": 30537.914073,
    "out:PV (% sold (El))": 71.919431,
    "out:PV (kWh self-consumed)": 11923.370428,
    "out:PV (ratio sold/self-consumed)": 2.561181,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 147765.718339,
    "out:EL kWh savings": 20182.29692,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 79599.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 233937.18,
    "out:DH kr savings": 147765.7183387264,
    "out:El kr savings": 40364.59383912171,
    "out:El kr sold": 45806.87111012268,
    "out:El kr saved": 23846.740856,
    "out:El kr return": 69653.61196612268,
    "out:% solar/total": 30,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 285931.011244,
    "out:% savings (space heating)": 29.771213,
    "out:Total_Water Heating (DH)": 106218.208041,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 89.8,
    "out:Etvv": 20,
    "out:Ef": 6.23,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 4305160.928242325,
    "out:Seasonal Variation ROI (%)": 5,
    "out:Seasonal Variation Payback": 20,
    "out:New AHU Cost/SEK": 146792.87151982498,
    "out:DH CO2": 44.278032,
    "out:EL CO2": -47.831449,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -48.655512,
    "out:Bought CO2": 0.824063,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02000000110101000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 1,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 93.793075,
    "out:DHW (DH)": 28.356,
    "out:Property Electricity": 9.976115,
    "out:Electricity (inc PV)": 6.231337,
    "out:Total Energy Use Pre PV": 131.976115,
    "out:Total Energy Use Post PV": 128.231337,
    "out:Primary Energy": 96.066576,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 29.393346,
    "out:CO2 Operational/m2": -3.913401,
    "out:Total CO2/m2": 25.479945,
    "out:Total CO2 (tons)": 81.12812,
    "out:Klimatpaverkan": -119.007301,
    "out:Initial Cost/MSEK": 4.4987769532423245,
    "out:Running cost/(Apt SEK y)": 24829.510638,
    "out:Running Cost over RSP/MSEK": 9.13699,
    "out:LCP/MSEK": -0.231047,
    "out:ROI% old": 13.552032,
    "out:Payback discounted": 9,
    "out:Atemp": 3184,
    "out:Umean": 0.55,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 128,
    "out:Energy use kWh/m2": 128,
    "out:Energy savings %": 43,
    "out:Op energy cost/MSEK": 67,
    "out:El price/MSEK": 40,
    "out:Heating price/MSEK": 24,
    "out:DH+ DHW/kWh/m2": 122,
    "out:El bought/kWh/m2": 439.497378,
    "out:Return %": 12,
    "out:Return/kSEK/y": 548,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 28.356,
    "out:EAHP (El)": 0,
    "out:PV (kr/kWp)": 17461.56255567083,
    "out:PV (kWp installed)": 45.25485,
    "out:PV (m2 panels)": 237.433632,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 42461.284501,
    "out:PV (kWh sold)": 30537.914073,
    "out:PV (% sold (El))": 71.919431,
    "out:PV (kWh self-consumed)": 11923.370428,
    "out:PV (ratio sold/self-consumed)": 2.561181,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 150992.401117,
    "out:EL kWh savings": 20182.29692,
    "out:Sewage heat recovery cost": 273216,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 237163.87,
    "out:DH kr savings": 150992.40111668373,
    "out:El kr savings": 40364.59383912171,
    "out:El kr sold": 45806.87111012268,
    "out:El kr saved": 23846.740856,
    "out:El kr return": 69653.61196612268,
    "out:% solar/total": 30,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 298637.059673,
    "out:% savings (space heating)": 26.650424,
    "out:Total_Water Heating (DH)": 90285.476835,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 93.79,
    "out:Etvv": 17,
    "out:Ef": 6.23,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 4498776.953242324,
    "out:Seasonal Variation ROI (%)": 5,
    "out:Seasonal Variation Payback": 20,
    "out:New AHU Cost/SEK": 146792.87151982498,
    "out:DH CO2": 43.918048,
    "out:EL CO2": -47.831449,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -48.655512,
    "out:Bought CO2": 0.824063,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02000000110101100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 1,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 89.80248,
    "out:DHW (DH)": 28.356,
    "out:Property Electricity": 9.976115,
    "out:Electricity (inc PV)": 6.231337,
    "out:Total Energy Use Pre PV": 127.976115,
    "out:Total Energy Use Post PV": 124.231337,
    "out:Primary Energy": 92.96278,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 29.393346,
    "out:CO2 Operational/m2": -5.353337,
    "out:Total CO2/m2": 24.040009,
    "out:Total CO2 (tons)": 76.543366,
    "out:Klimatpaverkan": -123.592055,
    "out:Initial Cost/MSEK": 4.578376928242325,
    "out:Running cost/(Apt SEK y)": 24011.148936,
    "out:Running Cost over RSP/MSEK": 8.83614,
    "out:LCP/MSEK": -0.009797,
    "out:ROI% old": 14.255145,
    "out:Payback discounted": 9,
    "out:Atemp": 3184,
    "out:Umean": 0.55,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 124,
    "out:Energy use kWh/m2": 124,
    "out:Energy savings %": 47.6,
    "out:Op energy cost/MSEK": 65,
    "out:El price/MSEK": 38,
    "out:Heating price/MSEK": 23,
    "out:DH+ DHW/kWh/m2": 118,
    "out:El bought/kWh/m2": 439.497378,
    "out:Return %": 13,
    "out:Return/kSEK/y": 586,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 28.356,
    "out:EAHP (El)": 0,
    "out:PV (kr/kWp)": 17461.56255567083,
    "out:PV (kWp installed)": 45.25485,
    "out:PV (m2 panels)": 237.433632,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 42461.284501,
    "out:PV (kWh sold)": 30537.914073,
    "out:PV (% sold (El))": 71.919431,
    "out:PV (kWh self-consumed)": 11923.370428,
    "out:PV (ratio sold/self-consumed)": 2.561181,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 163698.449545,
    "out:EL kWh savings": 20182.29692,
    "out:Sewage heat recovery cost": 273216,
    "out:Balancing hydronic system cost": 79599.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 249869.91,
    "out:DH kr savings": 163698.4495448944,
    "out:El kr savings": 40364.59383912171,
    "out:El kr sold": 45806.87111012268,
    "out:El kr saved": 23846.740856,
    "out:El kr return": 69653.61196612268,
    "out:% solar/total": 30,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 285931.011244,
    "out:% savings (space heating)": 29.771213,
    "out:Total_Water Heating (DH)": 90285.476835,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 89.8,
    "out:Etvv": 17,
    "out:Ef": 6.23,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 4578376.928242325,
    "out:Seasonal Variation ROI (%)": 5,
    "out:Seasonal Variation Payback": 20,
    "out:New AHU Cost/SEK": 146792.87151982498,
    "out:DH CO2": 42.478112,
    "out:EL CO2": -47.831449,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -48.655512,
    "out:Bought CO2": 0.824063,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02000000120000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 93.793075,
    "out:DHW (DH)": 33.36,
    "out:Property Electricity": 12.57,
    "out:Electricity (inc PV)": 7.530921,
    "out:Total Energy Use Pre PV": 139.57,
    "out:Total Energy Use Post PV": 134.530921,
    "out:Primary Energy": 100.505827,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 43.2394,
    "out:CO2 Operational/m2": -63.23867,
    "out:Total CO2/m2": -19.99927,
    "out:Total CO2 (tons)": -63.677657,
    "out:Klimatpaverkan": -263.813078,
    "out:Initial Cost/MSEK": 4.86898143344375,
    "out:Running cost/(Apt SEK y)": 24804.914894,
    "out:Running Cost over RSP/MSEK": 9.14084,
    "out:LCP/MSEK": -0.605101,
    "out:ROI% old": 12.510331,
    "out:Payback discounted": 10,
    "out:Atemp": 3184,
    "out:Umean": 0.55,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 135,
    "out:Energy use kWh/m2": 135,
    "out:Energy savings %": 35.6,
    "out:Op energy cost/MSEK": 68,
    "out:El price/MSEK": 39,
    "out:Heating price/MSEK": 25,
    "out:DH+ DHW/kWh/m2": 127,
    "out:El bought/kWh/m2": 530.25352,
    "out:Return %": 11,
    "out:Return/kSEK/y": 549,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 33.36,
    "out:EAHP (El)": 0,
    "out:PV (kr/kWp)": 17461.48474631725,
    "out:PV (kWp installed)": 90.5097,
    "out:PV (m2 panels)": 474.867263,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 84922.568824,
    "out:PV (kWh sold)": 68878.145463,
    "out:PV (% sold (El))": 81.106997,
    "out:PV (kWh self-consumed)": 16044.423361,
    "out:PV (ratio sold/self-consumed)": 4.292965,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 135059.669911,
    "out:EL kWh savings": 16044.422709,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 270465.73,
    "out:DH kr savings": 135059.66991051572,
    "out:El kr savings": 32088.845417124598,
    "out:El kr sold": 103317.21819422109,
    "out:El kr saved": 32088.846722,
    "out:El kr return": 135406.06491622108,
    "out:% solar/total": 40,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 298637.059673,
    "out:% savings (space heating)": 26.650424,
    "out:Total_Water Heating (DH)": 106218.208041,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 93.79,
    "out:Etvv": 20,
    "out:Ef": 7.53,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 4868981.43344375,
    "out:Seasonal Variation ROI (%)": 5,
    "out:Seasonal Variation Payback": 20,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 45.717968,
    "out:EL CO2": -108.956638,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -109.952146,
    "out:Bought CO2": 0.995508,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02000000120000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 89.80248,
    "out:DHW (DH)": 33.36,
    "out:Property Electricity": 12.57,
    "out:Electricity (inc PV)": 7.530921,
    "out:Total Energy Use Pre PV": 135.57,
    "out:Total Energy Use Post PV": 130.530921,
    "out:Primary Energy": 97.402031,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 43.2394,
    "out:CO2 Operational/m2": -64.678606,
    "out:Total CO2/m2": -21.439206,
    "out:Total CO2 (tons)": -68.262411,
    "out:Klimatpaverkan": -268.397832,
    "out:Initial Cost/MSEK": 4.94858140844375,
    "out:Running cost/(Apt SEK y)": 23986.553191,
    "out:Running Cost over RSP/MSEK": 8.84,
    "out:LCP/MSEK": -0.383861,
    "out:ROI% old": 13.177572,
    "out:Payback discounted": 9,
    "out:Atemp": 3184,
    "out:Umean": 0.55,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 131,
    "out:Energy use kWh/m2": 131,
    "out:Energy savings %": 39.7,
    "out:Op energy cost/MSEK": 66,
    "out:El price/MSEK": 38,
    "out:Heating price/MSEK": 24,
    "out:DH+ DHW/kWh/m2": 123,
    "out:El bought/kWh/m2": 530.25352,
    "out:Return %": 12,
    "out:Return/kSEK/y": 587,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 33.36,
    "out:EAHP (El)": 0,
    "out:PV (kr/kWp)": 17461.48474631725,
    "out:PV (kWp installed)": 90.5097,
    "out:PV (m2 panels)": 474.867263,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 84922.568824,
    "out:PV (kWh sold)": 68878.145463,
    "out:PV (% sold (El))": 81.106997,
    "out:PV (kWh self-consumed)": 16044.423361,
    "out:PV (ratio sold/self-consumed)": 4.292965,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 147765.718339,
    "out:EL kWh savings": 16044.422709,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 79599.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 283171.78,
    "out:DH kr savings": 147765.7183387264,
    "out:El kr savings": 32088.845417124598,
    "out:El kr sold": 103317.21819422109,
    "out:El kr saved": 32088.846722,
    "out:El kr return": 135406.06491622108,
    "out:% solar/total": 40,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 285931.011244,
    "out:% savings (space heating)": 29.771213,
    "out:Total_Water Heating (DH)": 106218.208041,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 89.8,
    "out:Etvv": 20,
    "out:Ef": 7.53,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 4948581.40844375,
    "out:Seasonal Variation ROI (%)": 5,
    "out:Seasonal Variation Payback": 20,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 44.278032,
    "out:EL CO2": -108.956638,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -109.952146,
    "out:Bought CO2": 0.995508,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02000000120001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 93.793075,
    "out:DHW (DH)": 28.356,
    "out:Property Electricity": 12.57,
    "out:Electricity (inc PV)": 7.530921,
    "out:Total Energy Use Pre PV": 134.57,
    "out:Total Energy Use Post PV": 129.530921,
    "out:Primary Energy": 98.405827,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 43.947943,
    "out:CO2 Operational/m2": -65.03859,
    "out:Total CO2/m2": -21.090647,
    "out:Total CO2 (tons)": -67.1526,
    "out:Klimatpaverkan": -267.288021,
    "out:Initial Cost/MSEK": 5.142197433443751,
    "out:Running cost/(Apt SEK y)": 23781.957447,
    "out:Running Cost over RSP/MSEK": 8.76478,
    "out:LCP/MSEK": -0.502257,
    "out:ROI% old": 12.890376,
    "out:Payback discounted": 9,
    "out:Atemp": 3184,
    "out:Umean": 0.55,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 130,
    "out:Energy use kWh/m2": 130,
    "out:Energy savings %": 40.8,
    "out:Op energy cost/MSEK": 65,
    "out:El price/MSEK": 38,
    "out:Heating price/MSEK": 24,
    "out:DH+ DHW/kWh/m2": 122,
    "out:El bought/kWh/m2": 530.25352,
    "out:Return %": 12,
    "out:Return/kSEK/y": 597,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 28.356,
    "out:EAHP (El)": 0,
    "out:PV (kr/kWp)": 17461.48474631725,
    "out:PV (kWp installed)": 90.5097,
    "out:PV (m2 panels)": 474.867263,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 84922.568824,
    "out:PV (kWh sold)": 68878.145463,
    "out:PV (% sold (El))": 81.106997,
    "out:PV (kWh self-consumed)": 16044.423361,
    "out:PV (ratio sold/self-consumed)": 4.292965,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 150992.401117,
    "out:EL kWh savings": 16044.422709,
    "out:Sewage heat recovery cost": 273216,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 286398.46,
    "out:DH kr savings": 150992.40111668373,
    "out:El kr savings": 32088.845417124598,
    "out:El kr sold": 103317.21819422109,
    "out:El kr saved": 32088.846722,
    "out:El kr return": 135406.06491622108,
    "out:% solar/total": 40,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 298637.059673,
    "out:% savings (space heating)": 26.650424,
    "out:Total_Water Heating (DH)": 90285.476835,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 93.79,
    "out:Etvv": 17,
    "out:Ef": 7.53,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 5142197.43344375,
    "out:Seasonal Variation ROI (%)": 5,
    "out:Seasonal Variation Payback": 20,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 43.918048,
    "out:EL CO2": -108.956638,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -109.952146,
    "out:Bought CO2": 0.995508,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02000000120001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 89.80248,
    "out:DHW (DH)": 28.356,
    "out:Property Electricity": 12.57,
    "out:Electricity (inc PV)": 7.530921,
    "out:Total Energy Use Pre PV": 130.57,
    "out:Total Energy Use Post PV": 125.530921,
    "out:Primary Energy": 95.302031,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 43.947943,
    "out:CO2 Operational/m2": -66.478526,
    "out:Total CO2/m2": -22.530583,
    "out:Total CO2 (tons)": -71.737355,
    "out:Klimatpaverkan": -271.872776,
    "out:Initial Cost/MSEK": 5.2217974084437495,
    "out:Running cost/(Apt SEK y)": 22963.617021,
    "out:Running Cost over RSP/MSEK": 8.46394,
    "out:LCP/MSEK": -0.281017,
    "out:ROI% old": 13.516911,
    "out:Payback discounted": 9,
    "out:Atemp": 3184,
    "out:Umean": 0.55,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 126,
    "out:Energy use kWh/m2": 126,
    "out:Energy savings %": 45.2,
    "out:Op energy cost/MSEK": 63,
    "out:El price/MSEK": 36,
    "out:Heating price/MSEK": 23,
    "out:DH+ DHW/kWh/m2": 118,
    "out:El bought/kWh/m2": 530.25352,
    "out:Return %": 12,
    "out:Return/kSEK/y": 635,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 28.356,
    "out:EAHP (El)": 0,
    "out:PV (kr/kWp)": 17461.48474631725,
    "out:PV (kWp installed)": 90.5097,
    "out:PV (m2 panels)": 474.867263,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 84922.568824,
    "out:PV (kWh sold)": 68878.145463,
    "out:PV (% sold (El))": 81.106997,
    "out:PV (kWh self-consumed)": 16044.423361,
    "out:PV (ratio sold/self-consumed)": 4.292965,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 163698.449545,
    "out:EL kWh savings": 16044.422709,
    "out:Sewage heat recovery cost": 273216,
    "out:Balancing hydronic system cost": 79599.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 299104.51,
    "out:DH kr savings": 163698.4495448944,
    "out:El kr savings": 32088.845417124598,
    "out:El kr sold": 103317.21819422109,
    "out:El kr saved": 32088.846722,
    "out:El kr return": 135406.06491622108,
    "out:% solar/total": 40,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 285931.011244,
    "out:% savings (space heating)": 29.771213,
    "out:Total_Water Heating (DH)": 90285.476835,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 89.8,
    "out:Etvv": 17,
    "out:Ef": 7.53,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 5221797.40844375,
    "out:Seasonal Variation ROI (%)": 5,
    "out:Seasonal Variation Payback": 20,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 42.478112,
    "out:EL CO2": -108.956638,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -109.952146,
    "out:Bought CO2": 0.995508,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02000000120100000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 1,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 93.793075,
    "out:DHW (DH)": 33.36,
    "out:Property Electricity": 9.976115,
    "out:Electricity (inc PV)": 5.879188,
    "out:Total Energy Use Pre PV": 136.976115,
    "out:Total Energy Use Post PV": 132.879188,
    "out:Primary Energy": 97.532707,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 43.423902,
    "out:CO2 Operational/m2": -68.324396,
    "out:Total CO2/m2": -24.900494,
    "out:Total CO2 (tons)": -79.283149,
    "out:Klimatpaverkan": -279.41857,
    "out:Initial Cost/MSEK": 5.015774304963575,
    "out:Running cost/(Apt SEK y)": 24485.382979,
    "out:Running Cost over RSP/MSEK": 9.02469,
    "out:LCP/MSEK": -0.635744,
    "out:ROI% old": 12.475014,
    "out:Payback discounted": 10,
    "out:Atemp": 3184,
    "out:Umean": 0.55,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 133,
    "out:Energy use kWh/m2": 133,
    "out:Energy savings %": 37.6,
    "out:Op energy cost/MSEK": 67,
    "out:El price/MSEK": 38,
    "out:Heating price/MSEK": 25,
    "out:DH+ DHW/kWh/m2": 127,
    "out:El bought/kWh/m2": 413.65837,
    "out:Return %": 11,
    "out:Return/kSEK/y": 564,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 33.36,
    "out:EAHP (El)": 0,
    "out:PV (kr/kWp)": 17461.48474631725,
    "out:PV (kWp installed)": 90.5097,
    "out:PV (m2 panels)": 474.867263,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 84922.568824,
    "out:PV (kWh sold)": 71877.957046,
    "out:PV (% sold (El))": 84.639405,
    "out:PV (kWh self-consumed)": 13044.611778,
    "out:PV (ratio sold/self-consumed)": 5.510165,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 135059.669911,
    "out:EL kWh savings": 21303.538998,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 285483.68,
    "out:DH kr savings": 135059.66991051572,
    "out:El kr savings": 42607.077996404216,
    "out:El kr sold": 107816.93556907076,
    "out:El kr saved": 26089.223556,
    "out:El kr return": 133906.15912507076,
    "out:% solar/total": 33,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 298637.059673,
    "out:% savings (space heating)": 26.650424,
    "out:Total_Water Heating (DH)": 106218.208041,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 93.79,
    "out:Etvv": 20,
    "out:Ef": 5.88,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 5015774.304963575,
    "out:Seasonal Variation ROI (%)": 5,
    "out:Seasonal Variation Payback": 20,
    "out:New AHU Cost/SEK": 146792.87151982498,
    "out:DH CO2": 45.717968,
    "out:EL CO2": -114.042364,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -114.819396,
    "out:Bought CO2": 0.777032,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02000000120100100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 1,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 89.80248,
    "out:DHW (DH)": 33.36,
    "out:Property Electricity": 9.976115,
    "out:Electricity (inc PV)": 5.879188,
    "out:Total Energy Use Pre PV": 132.976115,
    "out:Total Energy Use Post PV": 128.879188,
    "out:Primary Energy": 94.428911,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 43.423902,
    "out:CO2 Operational/m2": -69.764332,
    "out:Total CO2/m2": -26.34043,
    "out:Total CO2 (tons)": -83.867904,
    "out:Klimatpaverkan": -284.003325,
    "out:Initial Cost/MSEK": 5.0953742799635755,
    "out:Running cost/(Apt SEK y)": 23667.021277,
    "out:Running Cost over RSP/MSEK": 8.72385,
    "out:LCP/MSEK": -0.414504,
    "out:ROI% old": 13.123584,
    "out:Payback discounted": 9,
    "out:Atemp": 3184,
    "out:Umean": 0.55,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 129,
    "out:Energy use kWh/m2": 129,
    "out:Energy savings %": 41.9,
    "out:Op energy cost/MSEK": 65,
    "out:El price/MSEK": 37,
    "out:Heating price/MSEK": 24,
    "out:DH+ DHW/kWh/m2": 123,
    "out:El bought/kWh/m2": 413.65837,
    "out:Return %": 12,
    "out:Return/kSEK/y": 602,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 33.36,
    "out:EAHP (El)": 0,
    "out:PV (kr/kWp)": 17461.48474631725,
    "out:PV (kWp installed)": 90.5097,
    "out:PV (m2 panels)": 474.867263,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 84922.568824,
    "out:PV (kWh sold)": 71877.957046,
    "out:PV (% sold (El))": 84.639405,
    "out:PV (kWh self-consumed)": 13044.611778,
    "out:PV (ratio sold/self-consumed)": 5.510165,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 147765.718339,
    "out:EL kWh savings": 21303.538998,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 79599.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 298189.73,
    "out:DH kr savings": 147765.7183387264,
    "out:El kr savings": 42607.077996404216,
    "out:El kr sold": 107816.93556907076,
    "out:El kr saved": 26089.223556,
    "out:El kr return": 133906.15912507076,
    "out:% solar/total": 33,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 285931.011244,
    "out:% savings (space heating)": 29.771213,
    "out:Total_Water Heating (DH)": 106218.208041,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 89.8,
    "out:Etvv": 20,
    "out:Ef": 5.88,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 5095374.279963575,
    "out:Seasonal Variation ROI (%)": 6,
    "out:Seasonal Variation Payback": 16.666667,
    "out:New AHU Cost/SEK": 146792.87151982498,
    "out:DH CO2": 44.278032,
    "out:EL CO2": -114.042364,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -114.819396,
    "out:Bought CO2": 0.777032,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02000000120101000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 1,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 93.793075,
    "out:DHW (DH)": 28.356,
    "out:Property Electricity": 9.976115,
    "out:Electricity (inc PV)": 5.879188,
    "out:Total Energy Use Pre PV": 131.976115,
    "out:Total Energy Use Post PV": 127.879188,
    "out:Primary Energy": 95.432707,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 44.132445,
    "out:CO2 Operational/m2": -70.124316,
    "out:Total CO2/m2": -25.991871,
    "out:Total CO2 (tons)": -82.758092,
    "out:Klimatpaverkan": -282.893513,
    "out:Initial Cost/MSEK": 5.2889903049635745,
    "out:Running cost/(Apt SEK y)": 23462.425532,
    "out:Running Cost over RSP/MSEK": 8.64864,
    "out:LCP/MSEK": -0.53291,
    "out:ROI% old": 12.846308,
    "out:Payback discounted": 9,
    "out:Atemp": 3184,
    "out:Umean": 0.55,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 128,
    "out:Energy use kWh/m2": 128,
    "out:Energy savings %": 43,
    "out:Op energy cost/MSEK": 64,
    "out:El price/MSEK": 37,
    "out:Heating price/MSEK": 24,
    "out:DH+ DHW/kWh/m2": 122,
    "out:El bought/kWh/m2": 413.65837,
    "out:Return %": 12,
    "out:Return/kSEK/y": 612,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 28.356,
    "out:EAHP (El)": 0,
    "out:PV (kr/kWp)": 17461.48474631725,
    "out:PV (kWp installed)": 90.5097,
    "out:PV (m2 panels)": 474.867263,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 84922.568824,
    "out:PV (kWh sold)": 71877.957046,
    "out:PV (% sold (El))": 84.639405,
    "out:PV (kWh self-consumed)": 13044.611778,
    "out:PV (ratio sold/self-consumed)": 5.510165,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 150992.401117,
    "out:EL kWh savings": 21303.538998,
    "out:Sewage heat recovery cost": 273216,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 301416.41,
    "out:DH kr savings": 150992.40111668373,
    "out:El kr savings": 42607.077996404216,
    "out:El kr sold": 107816.93556907076,
    "out:El kr saved": 26089.223556,
    "out:El kr return": 133906.15912507076,
    "out:% solar/total": 33,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 298637.059673,
    "out:% savings (space heating)": 26.650424,
    "out:Total_Water Heating (DH)": 90285.476835,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 93.79,
    "out:Etvv": 17,
    "out:Ef": 5.88,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 5288990.304963575,
    "out:Seasonal Variation ROI (%)": 5,
    "out:Seasonal Variation Payback": 20,
    "out:New AHU Cost/SEK": 146792.87151982498,
    "out:DH CO2": 43.918048,
    "out:EL CO2": -114.042364,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -114.819396,
    "out:Bought CO2": 0.777032,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02000000120101100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 1,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 89.80248,
    "out:DHW (DH)": 28.356,
    "out:Property Electricity": 9.976115,
    "out:Electricity (inc PV)": 5.879188,
    "out:Total Energy Use Pre PV": 127.976115,
    "out:Total Energy Use Post PV": 123.879188,
    "out:Primary Energy": 92.328911,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 44.132445,
    "out:CO2 Operational/m2": -71.564252,
    "out:Total CO2/m2": -27.431807,
    "out:Total CO2 (tons)": -87.342847,
    "out:Klimatpaverkan": -287.478268,
    "out:Initial Cost/MSEK": 5.368590279963575,
    "out:Running cost/(Apt SEK y)": 22644.085106,
    "out:Running Cost over RSP/MSEK": 8.34779,
    "out:LCP/MSEK": -0.31166,
    "out:ROI% old": 13.456392,
    "out:Payback discounted": 9,
    "out:Atemp": 3184,
    "out:Umean": 0.55,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 124,
    "out:Energy use kWh/m2": 124,
    "out:Energy savings %": 47.6,
    "out:Op energy cost/MSEK": 62,
    "out:El price/MSEK": 35,
    "out:Heating price/MSEK": 23,
    "out:DH+ DHW/kWh/m2": 118,
    "out:El bought/kWh/m2": 413.65837,
    "out:Return %": 12,
    "out:Return/kSEK/y": 650,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 28.356,
    "out:EAHP (El)": 0,
    "out:PV (kr/kWp)": 17461.48474631725,
    "out:PV (kWp installed)": 90.5097,
    "out:PV (m2 panels)": 474.867263,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 84922.568824,
    "out:PV (kWh sold)": 71877.957046,
    "out:PV (% sold (El))": 84.639405,
    "out:PV (kWh self-consumed)": 13044.611778,
    "out:PV (ratio sold/self-consumed)": 5.510165,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 163698.449545,
    "out:EL kWh savings": 21303.538998,
    "out:Sewage heat recovery cost": 273216,
    "out:Balancing hydronic system cost": 79599.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 314122.46,
    "out:DH kr savings": 163698.4495448944,
    "out:El kr savings": 42607.077996404216,
    "out:El kr sold": 107816.93556907076,
    "out:El kr saved": 26089.223556,
    "out:El kr return": 133906.15912507076,
    "out:% solar/total": 33,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 285931.011244,
    "out:% savings (space heating)": 29.771213,
    "out:Total_Water Heating (DH)": 90285.476835,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 89.8,
    "out:Etvv": 17,
    "out:Ef": 5.88,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 5368590.279963575,
    "out:Seasonal Variation ROI (%)": 6,
    "out:Seasonal Variation Payback": 16.666667,
    "out:New AHU Cost/SEK": 146792.87151982498,
    "out:DH CO2": 42.478112,
    "out:EL CO2": -114.042364,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -114.819396,
    "out:Bought CO2": 0.777032,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02000001000000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 51.507758,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 40.319596,
    "out:Electricity (inc PV)": 40.319596,
    "out:Total Energy Use Pre PV": 92.319596,
    "out:Total Energy Use Post PV": 92.319596,
    "out:Primary Energy": 105.043801,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 17.514144,
    "out:CO2 Operational/m2": 24.041677,
    "out:Total CO2/m2": 41.555821,
    "out:Total CO2 (tons)": 132.313694,
    "out:Klimatpaverkan": -67.821727,
    "out:Initial Cost/MSEK": 6.148749411073993,
    "out:Running cost/(Apt SEK y)": 16101.489362,
    "out:Running Cost over RSP/MSEK": 5.89591,
    "out:LCP/MSEK": 1.360061,
    "out:ROI% old": 17.445615,
    "out:Payback discounted": 7,
    "out:Atemp": 3184,
    "out:Umean": 0.55,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 92,
    "out:Energy use kWh/m2": 92,
    "out:Energy savings %": 98.9,
    "out:Op energy cost/MSEK": 42,
    "out:El price/MSEK": 30,
    "out:Heating price/MSEK": 10,
    "out:DH+ DHW/kWh/m2": 52,
    "out:El bought/kWh/m2": 2822.37172,
    "out:Return %": 16,
    "out:Return/kSEK/y": 958,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.6502792112,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 41.7,
    "out:EAHP (El)": 25.450096,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 375914.285497,
    "out:EL kWh savings": -88354.68708,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 199204.91,
    "out:DH kr savings": 375914.2854967025,
    "out:El kr savings": -176709.374159774,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 164000.652128,
    "out:% savings (space heating)": 59.719071,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 997124.4110739925,
    "out:Eupp": 41.75,
    "out:Etvv": 0,
    "out:Ef": 38.22,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 6148749.411073993,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 18.719168,
    "out:EL CO2": 5.322509,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 5.322509,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02000001000000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 47.751085,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 40.248711,
    "out:Electricity (inc PV)": 40.248711,
    "out:Total Energy Use Pre PV": 88.248711,
    "out:Total Energy Use Post PV": 88.248711,
    "out:Primary Energy": 102.111273,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 17.514144,
    "out:CO2 Operational/m2": 22.592384,
    "out:Total CO2/m2": 40.106528,
    "out:Total CO2 (tons)": 127.699147,
    "out:Klimatpaverkan": -72.436274,
    "out:Initial Cost/MSEK": 6.228349386073993,
    "out:Running cost/(Apt SEK y)": 15273.531915,
    "out:Running Cost over RSP/MSEK": 5.59157,
    "out:LCP/MSEK": 1.584801,
    "out:ROI% old": 17.920708,
    "out:Payback discounted": 7,
    "out:Atemp": 3184,
    "out:Umean": 0.55,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 88,
    "out:Energy use kWh/m2": 88,
    "out:Energy savings %": 108,
    "out:Op energy cost/MSEK": 40,
    "out:El price/MSEK": 29,
    "out:Heating price/MSEK": 9,
    "out:DH+ DHW/kWh/m2": 48,
    "out:El bought/kWh/m2": 2817.40977,
    "out:Return %": 16,
    "out:Return/kSEK/y": 997,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.6502792112,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 41.7,
    "out:EAHP (El)": 25.379211,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 387875.52873,
    "out:EL kWh savings": -88128.989308,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 79599.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 211617.55,
    "out:DH kr savings": 387875.5287298097,
    "out:El kr savings": -176257.97861558973,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 152039.408894,
    "out:% savings (space heating)": 62.656925,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 997124.4110739925,
    "out:Eupp": 38.14,
    "out:Etvv": 0,
    "out:Ef": 38.1,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 6228349.386073993,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 17.279232,
    "out:EL CO2": 5.313152,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 5.313152,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02000001000001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 47.750199,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 39.562796,
    "out:Electricity (inc PV)": 39.562796,
    "out:Total Energy Use Pre PV": 87.562796,
    "out:Total Energy Use Post PV": 87.562796,
    "out:Primary Energy": 102.07233,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 18.222687,
    "out:CO2 Operational/m2": 22.501838,
    "out:Total CO2/m2": 40.724525,
    "out:Total CO2 (tons)": 129.666849,
    "out:Klimatpaverkan": -70.468572,
    "out:Initial Cost/MSEK": 6.421965411073993,
    "out:Running cost/(Apt SEK y)": 15180.595745,
    "out:Running Cost over RSP/MSEK": 5.55781,
    "out:LCP/MSEK": 1.424945,
    "out:ROI% old": 17.455515,
    "out:Payback discounted": 7,
    "out:Atemp": 3184,
    "out:Umean": 0.55,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 88,
    "out:Energy use kWh/m2": 88,
    "out:Energy savings %": 108,
    "out:Op energy cost/MSEK": 39,
    "out:El price/MSEK": 29,
    "out:Heating price/MSEK": 9,
    "out:DH+ DHW/kWh/m2": 48,
    "out:El bought/kWh/m2": 2769.39572,
    "out:Return %": 16,
    "out:Return/kSEK/y": 1001,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.6502792112,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 35.445,
    "out:EAHP (El)": 24.693296,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 387878.349753,
    "out:EL kWh savings": -85945.036605,
    "out:Sewage heat recovery cost": 273216,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 215988.28,
    "out:DH kr savings": 387878.3497529609,
    "out:El kr savings": -171890.0732098028,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 152036.587871,
    "out:% savings (space heating)": 62.657618,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 997124.4110739925,
    "out:Eupp": 39.68,
    "out:Etvv": 0,
    "out:Ef": 37.71,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 6421965.411073993,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 17.279232,
    "out:EL CO2": 5.222606,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 5.222606,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02000001000001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 44.04108,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 39.4775,
    "out:Electricity (inc PV)": 39.4775,
    "out:Total Energy Use Pre PV": 83.4775,
    "out:Total Energy Use Post PV": 83.4775,
    "out:Primary Energy": 99.142735,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 18.222687,
    "out:CO2 Operational/m2": 21.050642,
    "out:Total CO2/m2": 39.273329,
    "out:Total CO2 (tons)": 125.046242,
    "out:Klimatpaverkan": -75.089179,
    "out:Initial Cost/MSEK": 6.5015653860739935,
    "out:Running cost/(Apt SEK y)": 14350.680851,
    "out:Running Cost over RSP/MSEK": 5.25276,
    "out:LCP/MSEK": 1.650395,
    "out:ROI% old": 17.912082,
    "out:Payback discounted": 7,
    "out:Atemp": 3184,
    "out:Umean": 0.55,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 83,
    "out:Energy use kWh/m2": 83,
    "out:Energy savings %": 120.5,
    "out:Op energy cost/MSEK": 37,
    "out:El price/MSEK": 27,
    "out:Heating price/MSEK": 9,
    "out:DH+ DHW/kWh/m2": 44,
    "out:El bought/kWh/m2": 2763.425,
    "out:Return %": 16,
    "out:Return/kSEK/y": 1040,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.6502792112,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 35.445,
    "out:EAHP (El)": 24.608,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 399688.181096,
    "out:EL kWh savings": -85673.454223,
    "out:Sewage heat recovery cost": 273216,
    "out:Balancing hydronic system cost": 79599.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 228341.27,
    "out:DH kr savings": 399688.1810956249,
    "out:El kr savings": -171346.90844522996,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 140226.756529,
    "out:% savings (space heating)": 65.558283,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 997124.4110739925,
    "out:Eupp": 36.11,
    "out:Etvv": 0,
    "out:Ef": 37.58,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 6501565.3860739935,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 15.839296,
    "out:EL CO2": 5.211346,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 5.211346,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02000001010000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 51.507758,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 40.319596,
    "out:Electricity (inc PV)": 30.292772,
    "out:Total Energy Use Pre PV": 92.319596,
    "out:Total Energy Use Post PV": 82.292772,
    "out:Primary Energy": 86.995518,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 32.253244,
    "out:CO2 Operational/m2": 6.313151,
    "out:Total CO2/m2": 38.566395,
    "out:Total CO2 (tons)": 122.795365,
    "out:Klimatpaverkan": -77.340056,
    "out:Initial Cost/MSEK": 6.938969805296493,
    "out:Running cost/(Apt SEK y)": 14406.723404,
    "out:Running Cost over RSP/MSEK": 5.28951,
    "out:LCP/MSEK": 1.17624,
    "out:ROI% old": 16.707317,
    "out:Payback discounted": 7,
    "out:Atemp": 3184,
    "out:Umean": 0.55,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 82,
    "out:Energy use kWh/m2": 82,
    "out:Energy savings %": 123.2,
    "out:Op energy cost/MSEK": 38,
    "out:El price/MSEK": 26,
    "out:Heating price/MSEK": 10,
    "out:DH+ DHW/kWh/m2": 52,
    "out:El bought/kWh/m2": 2142.170938,
    "out:Return %": 15,
    "out:Return/kSEK/y": 1038,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.6502792112,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 41.7,
    "out:EAHP (El)": 25.450096,
    "out:PV (kr/kWp)": 17461.56255567083,
    "out:PV (kWp installed)": 45.25485,
    "out:PV (m2 panels)": 237.433632,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 42461.284501,
    "out:PV (kWh sold)": 10535.88779,
    "out:PV (% sold (El))": 24.812928,
    "out:PV (kWh self-consumed)": 31925.396711,
    "out:PV (ratio sold/self-consumed)": 0.330016,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 375914.285497,
    "out:EL kWh savings": -56429.28907,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 278859.54,
    "out:DH kr savings": 375914.2854967025,
    "out:El kr savings": -112858.57813916892,
    "out:El kr sold": 15803.831685408873,
    "out:El kr saved": 63850.793422,
    "out:El kr return": 79654.62510740888,
    "out:% solar/total": 80,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 164000.652128,
    "out:% savings (space heating)": 59.719071,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 997124.4110739925,
    "out:Eupp": 41.75,
    "out:Etvv": 0,
    "out:Ef": 28.49,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 6938969.805296493,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 18.719168,
    "out:EL CO2": -12.406017,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -16.414602,
    "out:Bought CO2": 4.008585,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02000001010000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 47.751085,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 40.248711,
    "out:Electricity (inc PV)": 30.231656,
    "out:Total Energy Use Pre PV": 88.248711,
    "out:Total Energy Use Post PV": 78.231656,
    "out:Primary Energy": 84.080574,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 32.253244,
    "out:CO2 Operational/m2": 4.81545,
    "out:Total CO2/m2": 37.068694,
    "out:Total CO2 (tons)": 118.026686,
    "out:Klimatpaverkan": -82.108735,
    "out:Initial Cost/MSEK": 7.0185697802964935,
    "out:Running cost/(Apt SEK y)": 13579.085106,
    "out:Running Cost over RSP/MSEK": 4.98529,
    "out:LCP/MSEK": 1.40086,
    "out:ROI% old": 17.137049,
    "out:Payback discounted": 7,
    "out:Atemp": 3184,
    "out:Umean": 0.55,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 78,
    "out:Energy use kWh/m2": 78,
    "out:Energy savings %": 134.6,
    "out:Op energy cost/MSEK": 36,
    "out:El price/MSEK": 25,
    "out:Heating price/MSEK": 9,
    "out:DH+ DHW/kWh/m2": 48,
    "out:El bought/kWh/m2": 2137.844288,
    "out:Return %": 15,
    "out:Return/kSEK/y": 1076,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.6502792112,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 41.7,
    "out:EAHP (El)": 25.379211,
    "out:PV (kr/kWp)": 17461.56255567083,
    "out:PV (kWp installed)": 45.25485,
    "out:PV (m2 panels)": 237.433632,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 42461.284501,
    "out:PV (kWh sold)": 10566.992417,
    "out:PV (% sold (El))": 24.886182,
    "out:PV (kWh self-consumed)": 31894.292084,
    "out:PV (ratio sold/self-consumed)": 0.331313,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 387875.52873,
    "out:EL kWh savings": -56234.695784,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 79599.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 291256.63,
    "out:DH kr savings": 387875.5287298097,
    "out:El kr savings": -112469.3915682671,
    "out:El kr sold": 15850.488626203569,
    "out:El kr saved": 63788.584168,
    "out:El kr return": 79639.07279420357,
    "out:% solar/total": 80,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 152039.408894,
    "out:% savings (space heating)": 62.656925,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 997124.4110739925,
    "out:Eupp": 38.14,
    "out:Etvv": 0,
    "out:Ef": 28.39,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 7018569.780296493,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 17.279232,
    "out:EL CO2": -12.463782,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -16.464274,
    "out:Bought CO2": 4.000492,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02000001010001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 47.750199,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 39.562796,
    "out:Electricity (inc PV)": 29.641186,
    "out:Total Energy Use Pre PV": 87.562796,
    "out:Total Energy Use Post PV": 77.641186,
    "out:Primary Energy": 84.213432,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 32.961787,
    "out:CO2 Operational/m2": 4.251522,
    "out:Total CO2/m2": 37.213309,
    "out:Total CO2 (tons)": 118.48714,
    "out:Klimatpaverkan": -81.648281,
    "out:Initial Cost/MSEK": 7.2121858052964924,
    "out:Running cost/(Apt SEK y)": 13489.382979,
    "out:Running Cost over RSP/MSEK": 4.95268,
    "out:LCP/MSEK": 1.239854,
    "out:ROI% old": 16.741586,
    "out:Payback discounted": 7,
    "out:Atemp": 3184,
    "out:Umean": 0.55,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 78,
    "out:Energy use kWh/m2": 78,
    "out:Energy savings %": 134.6,
    "out:Op energy cost/MSEK": 36,
    "out:El price/MSEK": 25,
    "out:Heating price/MSEK": 9,
    "out:DH+ DHW/kWh/m2": 48,
    "out:El bought/kWh/m2": 2096.040615,
    "out:Return %": 15,
    "out:Return/kSEK/y": 1081,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.6502792112,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 35.445,
    "out:EAHP (El)": 24.693296,
    "out:PV (kr/kWp)": 17461.56255567083,
    "out:PV (kWp installed)": 45.25485,
    "out:PV (m2 panels)": 237.433632,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 42461.284501,
    "out:PV (kWh sold)": 10870.887179,
    "out:PV (% sold (El))": 25.60188,
    "out:PV (kWh self-consumed)": 31590.397323,
    "out:PV (ratio sold/self-consumed)": 0.34412,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 387878.349753,
    "out:EL kWh savings": -54354.63987,
    "out:Sewage heat recovery cost": 273216,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 295475.4,
    "out:DH kr savings": 387878.3497529609,
    "out:El kr savings": -108709.27973960765,
    "out:El kr sold": 16306.330767790148,
    "out:El kr saved": 63180.794646,
    "out:El kr return": 79487.12541379016,
    "out:% solar/total": 79,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 152036.587871,
    "out:% savings (space heating)": 62.657618,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 997124.4110739925,
    "out:Eupp": 39.68,
    "out:Etvv": 0,
    "out:Ef": 28.05,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 7212185.805296493,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 17.279232,
    "out:EL CO2": -13.02771,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -16.950028,
    "out:Bought CO2": 3.922318,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02000001010001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 44.04108,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 39.4775,
    "out:Electricity (inc PV)": 29.567898,
    "out:Total Energy Use Pre PV": 83.4775,
    "out:Total Energy Use Post PV": 73.567898,
    "out:Primary Energy": 81.305451,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 32.961787,
    "out:CO2 Operational/m2": 2.740793,
    "out:Total CO2/m2": 35.70258,
    "out:Total CO2 (tons)": 113.676981,
    "out:Klimatpaverkan": -86.458441,
    "out:Initial Cost/MSEK": 7.291785780296493,
    "out:Running cost/(Apt SEK y)": 12659.87234,
    "out:Running Cost over RSP/MSEK": 4.64778,
    "out:LCP/MSEK": 1.465154,
    "out:ROI% old": 17.156174,
    "out:Payback discounted": 7,
    "out:Atemp": 3184,
    "out:Umean": 0.55,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 74,
    "out:Energy use kWh/m2": 74,
    "out:Energy savings %": 147.3,
    "out:Op energy cost/MSEK": 33,
    "out:El price/MSEK": 23,
    "out:Heating price/MSEK": 9,
    "out:DH+ DHW/kWh/m2": 44,
    "out:El bought/kWh/m2": 2090.85037,
    "out:Return %": 15,
    "out:Return/kSEK/y": 1120,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.6502792112,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 35.445,
    "out:EAHP (El)": 24.608,
    "out:PV (kr/kWp)": 17461.56255567083,
    "out:PV (kWp installed)": 45.25485,
    "out:PV (m2 panels)": 237.433632,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 42461.284501,
    "out:PV (kWh sold)": 10909.121617,
    "out:PV (% sold (El))": 25.691926,
    "out:PV (kWh self-consumed)": 31552.162885,
    "out:PV (ratio sold/self-consumed)": 0.345749,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 399688.181096,
    "out:EL kWh savings": -54121.290948,
    "out:Sewage heat recovery cost": 273216,
    "out:Balancing hydronic system cost": 79599.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 307809.28,
    "out:DH kr savings": 399688.1810956249,
    "out:El kr savings": -108242.58189602752,
    "out:El kr sold": 16363.682424864273,
    "out:El kr saved": 63104.32577,
    "out:El kr return": 79468.00819486428,
    "out:% solar/total": 79,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 140226.756529,
    "out:% savings (space heating)": 65.558283,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 997124.4110739925,
    "out:Eupp": 36.11,
    "out:Etvv": 0,
    "out:Ef": 27.95,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 7291785.780296493,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 15.839296,
    "out:EL CO2": -13.098503,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -17.011117,
    "out:Bought CO2": 3.912614,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02000001020000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 51.507758,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 40.319596,
    "out:Electricity (inc PV)": 27.430126,
    "out:Total Energy Use Pre PV": 92.319596,
    "out:Total Energy Use Post PV": 79.430126,
    "out:Primary Energy": 81.842755,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 46.992343,
    "out:CO2 Operational/m2": -47.177626,
    "out:Total CO2/m2": -0.185283,
    "out:Total CO2 (tons)": -0.589941,
    "out:Klimatpaverkan": -200.725362,
    "out:Initial Cost/MSEK": 7.729183157017744,
    "out:Running cost/(Apt SEK y)": 12954.595745,
    "out:Running Cost over RSP/MSEK": 4.77017,
    "out:LCP/MSEK": 0.905367,
    "out:ROI% old": 15.959088,
    "out:Payback discounted": 8,
    "out:Atemp": 3184,
    "out:Umean": 0.55,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 79,
    "out:Energy use kWh/m2": 79,
    "out:Energy savings %": 131.6,
    "out:Op energy cost/MSEK": 35,
    "out:El price/MSEK": 23,
    "out:Heating price/MSEK": 10,
    "out:DH+ DHW/kWh/m2": 52,
    "out:El bought/kWh/m2": 1938.5834,
    "out:Return %": 14,
    "out:Return/kSEK/y": 1106,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.6502792112,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 41.7,
    "out:EAHP (El)": 25.450096,
    "out:PV (kr/kWp)": 17461.48474631725,
    "out:PV (kWp installed)": 90.5097,
    "out:PV (m2 panels)": 474.867263,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 84922.568824,
    "out:PV (kWh sold)": 43882.508537,
    "out:PV (% sold (El))": 51.673553,
    "out:PV (kWh self-consumed)": 41040.060287,
    "out:PV (ratio sold/self-consumed)": 1.06926,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 375914.285497,
    "out:EL kWh savings": -47314.626948,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 347108.79,
    "out:DH kr savings": 375914.2854967025,
    "out:El kr savings": -94629.25389599858,
    "out:El kr sold": 65823.76280614962,
    "out:El kr saved": 82080.120574,
    "out:El kr return": 147903.8833801496,
    "out:% solar/total": 103,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 164000.652128,
    "out:% savings (space heating)": 59.719071,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 997124.4110739925,
    "out:Eupp": 41.75,
    "out:Etvv": 0,
    "out:Ef": 25.81,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 7729183.157017743,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 18.719168,
    "out:EL CO2": -65.896794,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -69.526021,
    "out:Bought CO2": 3.629227,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02000001020000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 47.751085,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 40.248711,
    "out:Electricity (inc PV)": 27.37532,
    "out:Total Energy Use Pre PV": 88.248711,
    "out:Total Energy Use Post PV": 75.37532,
    "out:Primary Energy": 78.939169,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 46.992343,
    "out:CO2 Operational/m2": -48.707157,
    "out:Total CO2/m2": -1.714814,
    "out:Total CO2 (tons)": -5.459966,
    "out:Klimatpaverkan": -205.595387,
    "out:Initial Cost/MSEK": 7.808783132017743,
    "out:Running cost/(Apt SEK y)": 12127.191489,
    "out:Running Cost over RSP/MSEK": 4.46603,
    "out:LCP/MSEK": 1.129907,
    "out:ROI% old": 16.352813,
    "out:Payback discounted": 7,
    "out:Atemp": 3184,
    "out:Umean": 0.55,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 75,
    "out:Energy use kWh/m2": 75,
    "out:Energy savings %": 144,
    "out:Op energy cost/MSEK": 33,
    "out:El price/MSEK": 22,
    "out:Heating price/MSEK": 9,
    "out:DH+ DHW/kWh/m2": 48,
    "out:El bought/kWh/m2": 1934.699434,
    "out:Return %": 15,
    "out:Return/kSEK/y": 1145,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.6502792112,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 41.7,
    "out:EAHP (El)": 25.379211,
    "out:PV (kr/kWp)": 17461.48474631725,
    "out:PV (kWp installed)": 90.5097,
    "out:PV (m2 panels)": 474.867263,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 84922.568824,
    "out:PV (kWh sold)": 43933.705288,
    "out:PV (% sold (El))": 51.733839,
    "out:PV (kWh self-consumed)": 40988.863535,
    "out:PV (ratio sold/self-consumed)": 1.071845,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 387875.52873,
    "out:EL kWh savings": -47140.124697,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 79599.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 359495.84,
    "out:DH kr savings": 387875.5287298097,
    "out:El kr savings": -94280.24939300689,
    "out:El kr sold": 65900.55793271783,
    "out:El kr saved": 81977.72707,
    "out:El kr return": 147878.2850027178,
    "out:% solar/total": 102,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 152039.408894,
    "out:% savings (space heating)": 62.656925,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 997124.4110739925,
    "out:Eupp": 38.14,
    "out:Etvv": 0,
    "out:Ef": 25.72,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 7808783.132017744,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 17.279232,
    "out:EL CO2": -65.986389,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -69.608355,
    "out:Bought CO2": 3.621966,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02000001020001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 47.750199,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 39.562796,
    "out:Electricity (inc PV)": 26.845711,
    "out:Total Energy Use Pre PV": 87.562796,
    "out:Total Energy Use Post PV": 74.845711,
    "out:Primary Energy": 79.181577,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 47.700886,
    "out:CO2 Operational/m2": -49.578996,
    "out:Total CO2/m2": -1.87811,
    "out:Total CO2 (tons)": -5.9799,
    "out:Klimatpaverkan": -206.115321,
    "out:Initial Cost/MSEK": 8.002399157017743,
    "out:Running cost/(Apt SEK y)": 12039.553191,
    "out:Running Cost over RSP/MSEK": 4.43417,
    "out:LCP/MSEK": 0.968151,
    "out:ROI% old": 16.014037,
    "out:Payback discounted": 8,
    "out:Atemp": 3184,
    "out:Umean": 0.55,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 75,
    "out:Energy use kWh/m2": 75,
    "out:Energy savings %": 144,
    "out:Op energy cost/MSEK": 33,
    "out:El price/MSEK": 22,
    "out:Heating price/MSEK": 9,
    "out:DH+ DHW/kWh/m2": 48,
    "out:El bought/kWh/m2": 1897.179476,
    "out:Return %": 14,
    "out:Return/kSEK/y": 1149,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.6502792112,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 35.445,
    "out:EAHP (El)": 24.693296,
    "out:PV (kr/kWp)": 17461.48474631725,
    "out:PV (kWp installed)": 90.5097,
    "out:PV (m2 panels)": 474.867263,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 84922.568824,
    "out:PV (kWh sold)": 44431.382475,
    "out:PV (% sold (El))": 52.319876,
    "out:PV (kWh self-consumed)": 40491.186349,
    "out:PV (ratio sold/self-consumed)": 1.09731,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 387878.349753,
    "out:EL kWh savings": -45453.850148,
    "out:Sewage heat recovery cost": 273216,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 363617.72,
    "out:DH kr savings": 387878.3497529609,
    "out:El kr savings": -90907.70029573768,
    "out:El kr sold": 66647.07371253555,
    "out:El kr saved": 80982.372698,
    "out:El kr return": 147629.44641053554,
    "out:% solar/total": 101,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 152036.587871,
    "out:% savings (space heating)": 62.657618,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 997124.4110739925,
    "out:Eupp": 39.68,
    "out:Etvv": 0,
    "out:Ef": 25.42,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 8002399.157017743,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 17.279232,
    "out:EL CO2": -66.858228,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -70.410065,
    "out:Bought CO2": 3.551837,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02000001020001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 44.04108,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 39.4775,
    "out:Electricity (inc PV)": 26.779989,
    "out:Total Energy Use Pre PV": 83.4775,
    "out:Total Energy Use Post PV": 70.779989,
    "out:Primary Energy": 76.287215,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 47.700886,
    "out:CO2 Operational/m2": -51.127988,
    "out:Total CO2/m2": -3.427102,
    "out:Total CO2 (tons)": -10.911889,
    "out:Klimatpaverkan": -211.047311,
    "out:Initial Cost/MSEK": 8.081999132017744,
    "out:Running cost/(Apt SEK y)": 11210.297872,
    "out:Running Cost over RSP/MSEK": 4.12936,
    "out:LCP/MSEK": 1.193361,
    "out:ROI% old": 16.395095,
    "out:Payback discounted": 7,
    "out:Atemp": 3184,
    "out:Umean": 0.55,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 71,
    "out:Energy use kWh/m2": 71,
    "out:Energy savings %": 157.7,
    "out:Op energy cost/MSEK": 30,
    "out:El price/MSEK": 20,
    "out:Heating price/MSEK": 9,
    "out:DH+ DHW/kWh/m2": 44,
    "out:El bought/kWh/m2": 1892.521676,
    "out:Return %": 15,
    "out:Return/kSEK/y": 1188,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.6502792112,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 35.445,
    "out:EAHP (El)": 24.608,
    "out:PV (kr/kWp)": 17461.48474631725,
    "out:PV (kWp installed)": 90.5097,
    "out:PV (m2 panels)": 474.867263,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 84922.568824,
    "out:PV (kWh sold)": 44493.706543,
    "out:PV (% sold (El))": 52.393265,
    "out:PV (kWh self-consumed)": 40428.862281,
    "out:PV (ratio sold/self-consumed)": 1.100543,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 399688.181096,
    "out:EL kWh savings": -45244.591363,
    "out:Sewage heat recovery cost": 273216,
    "out:Balancing hydronic system cost": 79599.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 375939.56,
    "out:DH kr savings": 399688.1810956249,
    "out:El kr savings": -90489.18272566107,
    "out:El kr sold": 66740.55981484457,
    "out:El kr saved": 80857.724562,
    "out:El kr return": 147598.2843768446,
    "out:% solar/total": 101,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 140226.756529,
    "out:% savings (space heating)": 65.558283,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 997124.4110739925,
    "out:Eupp": 36.11,
    "out:Etvv": 0,
    "out:Ef": 25.32,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 8081999.132017744,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 15.839296,
    "out:EL CO2": -66.967284,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -70.510417,
    "out:Bought CO2": 3.543133,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02000001100000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 46.522525,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 39.303,
    "out:Electricity (inc PV)": 39.303,
    "out:Total Energy Use Pre PV": 86.303,
    "out:Total Energy Use Post PV": 86.303,
    "out:Primary Energy": 101.075638,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 17.514144,
    "out:CO2 Operational/m2": 22.107558,
    "out:Total CO2/m2": 39.621702,
    "out:Total CO2 (tons)": 126.155461,
    "out:Klimatpaverkan": -73.97996,
    "out:Initial Cost/MSEK": 6.272797098573994,
    "out:Running cost/(Apt SEK y)": 14940.808511,
    "out:Running Cost over RSP/MSEK": 5.4698,
    "out:LCP/MSEK": 1.662123,
    "out:ROI% old": 18.071045,
    "out:Payback discounted": 7,
    "out:Atemp": 3184,
    "out:Umean": 0.55,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 86,
    "out:Energy use kWh/m2": 86,
    "out:Energy savings %": 112.8,
    "out:Op energy cost/MSEK": 39,
    "out:El price/MSEK": 28,
    "out:Heating price/MSEK": 9,
    "out:DH+ DHW/kWh/m2": 47,
    "out:El bought/kWh/m2": 2751.21,
    "out:Return %": 16,
    "out:Return/kSEK/y": 1012,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.6502792112,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 33.36,
    "out:EAHP (El)": 24.4335,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 391787.262593,
    "out:EL kWh savings": -85117.84639,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 221551.57,
    "out:DH kr savings": 391787.2625928492,
    "out:El kr savings": -170235.69277957198,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 148127.675031,
    "out:% savings (space heating)": 63.617703,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 997124.4110739925,
    "out:Eupp": 39,
    "out:Etvv": 0,
    "out:Ef": 37.54,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 6272797.098573994,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 16.919248,
    "out:EL CO2": 5.18831,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 5.18831,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02000001100000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 42.8319,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 39.2121,
    "out:Electricity (inc PV)": 39.2121,
    "out:Total Energy Use Pre PV": 82.2121,
    "out:Total Energy Use Post PV": 82.2121,
    "out:Primary Energy": 98.14585,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 17.514144,
    "out:CO2 Operational/m2": 20.655623,
    "out:Total CO2/m2": 38.169767,
    "out:Total CO2 (tons)": 121.532502,
    "out:Klimatpaverkan": -78.602919,
    "out:Initial Cost/MSEK": 6.352397073573994,
    "out:Running cost/(Apt SEK y)": 14110.148936,
    "out:Running Cost over RSP/MSEK": 5.16448,
    "out:LCP/MSEK": 1.887843,
    "out:ROI% old": 18.531227,
    "out:Payback discounted": 7,
    "out:Atemp": 3184,
    "out:Umean": 0.55,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 82,
    "out:Energy use kWh/m2": 82,
    "out:Energy savings %": 123.2,
    "out:Op energy cost/MSEK": 37,
    "out:El price/MSEK": 27,
    "out:Heating price/MSEK": 8,
    "out:DH+ DHW/kWh/m2": 43,
    "out:El bought/kWh/m2": 2744.847,
    "out:Return %": 17,
    "out:Return/kSEK/y": 1052,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.6502792112,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 33.36,
    "out:EAHP (El)": 24.3426,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 403538.209057,
    "out:EL kWh savings": -84828.420877,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 79599.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 233881.37,
    "out:DH kr savings": 403538.2090572305,
    "out:El kr savings": -169656.84175373634,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 136376.728567,
    "out:% savings (space heating)": 66.503905,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 997124.4110739925,
    "out:Eupp": 35.44,
    "out:Etvv": 0,
    "out:Ef": 37.4,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 6352397.0735739935,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 15.479312,
    "out:EL CO2": 5.176311,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 5.176311,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02000001100001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 43.633755,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 38.662021,
    "out:Electricity (inc PV)": 38.662021,
    "out:Total Energy Use Pre PV": 82.662021,
    "out:Total Energy Use Post PV": 82.662021,
    "out:Primary Energy": 98.666511,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 18.222687,
    "out:CO2 Operational/m2": 20.942992,
    "out:Total CO2/m2": 39.165679,
    "out:Total CO2 (tons)": 124.703484,
    "out:Klimatpaverkan": -75.431937,
    "out:Initial Cost/MSEK": 6.546013098573993,
    "out:Running cost/(Apt SEK y)": 14240.191489,
    "out:Running Cost over RSP/MSEK": 5.21261,
    "out:LCP/MSEK": 1.646097,
    "out:ROI% old": 17.87808,
    "out:Payback discounted": 7,
    "out:Atemp": 3184,
    "out:Umean": 0.55,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 83,
    "out:Energy use kWh/m2": 83,
    "out:Energy savings %": 120.5,
    "out:Op energy cost/MSEK": 37,
    "out:El price/MSEK": 27,
    "out:Heating price/MSEK": 9,
    "out:DH+ DHW/kWh/m2": 44,
    "out:El bought/kWh/m2": 2706.34147,
    "out:Return %": 16,
    "out:Return/kSEK/y": 1045,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.6502792112,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 28.356,
    "out:EAHP (El)": 23.792521,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 400985.103505,
    "out:EL kWh savings": -83076.969868,
    "out:Sewage heat recovery cost": 273216,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 234831.16,
    "out:DH kr savings": 400985.1035054075,
    "out:El kr savings": -166153.93973568783,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 138929.834119,
    "out:% savings (space heating)": 65.876826,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 997124.4110739925,
    "out:Eupp": 37.38,
    "out:Etvv": 0,
    "out:Ef": 37.12,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 6546013.098573993,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 15.839296,
    "out:EL CO2": 5.103696,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 5.103696,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02000001100001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 39.99438,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 38.555591,
    "out:Electricity (inc PV)": 38.555591,
    "out:Total Energy Use Pre PV": 78.555591,
    "out:Total Energy Use Post PV": 78.555591,
    "out:Primary Energy": 95.734417,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 18.222687,
    "out:CO2 Operational/m2": 19.489006,
    "out:Total CO2/m2": 37.711693,
    "out:Total CO2 (tons)": 120.073994,
    "out:Klimatpaverkan": -80.061427,
    "out:Initial Cost/MSEK": 6.6256130735739935,
    "out:Running cost/(Apt SEK y)": 13407.425532,
    "out:Running Cost over RSP/MSEK": 4.90653,
    "out:LCP/MSEK": 1.872577,
    "out:ROI% old": 18.323242,
    "out:Payback discounted": 7,
    "out:Atemp": 3184,
    "out:Umean": 0.55,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 79,
    "out:Energy use kWh/m2": 79,
    "out:Energy savings %": 131.6,
    "out:Op energy cost/MSEK": 35,
    "out:El price/MSEK": 26,
    "out:Heating price/MSEK": 8,
    "out:DH+ DHW/kWh/m2": 40,
    "out:El bought/kWh/m2": 2698.89137,
    "out:Return %": 16,
    "out:Return/kSEK/y": 1085,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.6502792112,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 28.356,
    "out:EAHP (El)": 23.686091,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 412572.870019,
    "out:EL kWh savings": -82738.09685,
    "out:Sewage heat recovery cost": 273216,
    "out:Balancing hydronic system cost": 79599.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 247096.68,
    "out:DH kr savings": 412572.8700188863,
    "out:El kr savings": -165476.1936996076,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 127342.067605,
    "out:% savings (space heating)": 68.722949,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 997124.4110739925,
    "out:Eupp": 33.86,
    "out:Etvv": 0,
    "out:Ef": 36.97,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 6625613.0735739935,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 14.39936,
    "out:EL CO2": 5.089646,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 5.089646,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02000001110000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 46.522525,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 39.303,
    "out:Electricity (inc PV)": 29.418024,
    "out:Total Energy Use Pre PV": 86.303,
    "out:Total Energy Use Post PV": 76.418024,
    "out:Primary Energy": 83.282681,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 32.253244,
    "out:CO2 Operational/m2": 3.675502,
    "out:Total CO2/m2": 35.928746,
    "out:Total CO2 (tons)": 114.397093,
    "out:Klimatpaverkan": -85.738328,
    "out:Initial Cost/MSEK": 7.063017492796494,
    "out:Running cost/(Apt SEK y)": 13250.829787,
    "out:Running Cost over RSP/MSEK": 4.86512,
    "out:LCP/MSEK": 1.476583,
    "out:ROI% old": 17.272262,
    "out:Payback discounted": 7,
    "out:Atemp": 3184,
    "out:Umean": 0.55,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 76,
    "out:Energy use kWh/m2": 76,
    "out:Energy savings %": 140.8,
    "out:Op energy cost/MSEK": 35,
    "out:El price/MSEK": 24,
    "out:Heating price/MSEK": 9,
    "out:DH+ DHW/kWh/m2": 47,
    "out:El bought/kWh/m2": 2080.237413,
    "out:Return %": 15,
    "out:Return/kSEK/y": 1092,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.6502792112,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 33.36,
    "out:EAHP (El)": 24.4335,
    "out:PV (kr/kWp)": 17461.56255567083,
    "out:PV (kWp installed)": 45.25485,
    "out:PV (m2 panels)": 237.433632,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 42461.284501,
    "out:PV (kWh sold)": 10987.530356,
    "out:PV (% sold (El))": 25.876585,
    "out:PV (kWh self-consumed)": 31473.754146,
    "out:PV (ratio sold/self-consumed)": 0.349101,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 391787.262593,
    "out:EL kWh savings": -53644.092276,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 300980.37,
    "out:DH kr savings": 391787.2625928492,
    "out:El kr savings": -107288.18455118603,
    "out:El kr sold": 16481.295533752498,
    "out:El kr saved": 62947.508292,
    "out:El kr return": 79428.80382575249,
    "out:% solar/total": 79,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 148127.675031,
    "out:% savings (space heating)": 63.617703,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 997124.4110739925,
    "out:Eupp": 39,
    "out:Etvv": 0,
    "out:Ef": 27.91,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 7063017.492796494,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 16.919248,
    "out:EL CO2": -13.243746,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -17.136519,
    "out:Bought CO2": 3.892773,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02000001110000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 42.8319,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 39.2121,
    "out:Electricity (inc PV)": 29.340005,
    "out:Total Energy Use Pre PV": 82.2121,
    "out:Total Energy Use Post PV": 72.340005,
    "out:Primary Energy": 80.376079,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 32.253244,
    "out:CO2 Operational/m2": 2.159665,
    "out:Total CO2/m2": 34.412909,
    "out:Total CO2 (tons)": 109.570669,
    "out:Klimatpaverkan": -90.564752,
    "out:Initial Cost/MSEK": 7.142617467796494,
    "out:Running cost/(Apt SEK y)": 12420.595745,
    "out:Running Cost over RSP/MSEK": 4.55995,
    "out:LCP/MSEK": 1.702153,
    "out:ROI% old": 17.690134,
    "out:Payback discounted": 7,
    "out:Atemp": 3184,
    "out:Umean": 0.55,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 72,
    "out:Energy use kWh/m2": 72,
    "out:Energy savings %": 154.2,
    "out:Op energy cost/MSEK": 33,
    "out:El price/MSEK": 23,
    "out:Heating price/MSEK": 8,
    "out:DH+ DHW/kWh/m2": 43,
    "out:El bought/kWh/m2": 2074.711902,
    "out:Return %": 16,
    "out:Return/kSEK/y": 1131,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.6502792112,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 33.36,
    "out:EAHP (El)": 24.3426,
    "out:PV (kr/kWp)": 17461.56255567083,
    "out:PV (kWp installed)": 45.25485,
    "out:PV (m2 panels)": 237.433632,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 42461.284501,
    "out:PV (kWh sold)": 11028.541921,
    "out:PV (% sold (El))": 25.973171,
    "out:PV (kWh self-consumed)": 31432.74258,
    "out:PV (ratio sold/self-consumed)": 0.350862,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 403538.209057,
    "out:EL kWh savings": -53395.679854,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 79599.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 313289.66,
    "out:DH kr savings": 403538.2090572305,
    "out:El kr savings": -106791.35970867054,
    "out:El kr sold": 16542.812882058355,
    "out:El kr saved": 62865.48516,
    "out:El kr return": 79408.29804205835,
    "out:% solar/total": 79,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 136376.728567,
    "out:% savings (space heating)": 66.503905,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 997124.4110739925,
    "out:Eupp": 35.44,
    "out:Etvv": 0,
    "out:Ef": 27.8,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 7142617.467796494,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 15.479312,
    "out:EL CO2": -13.319647,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -17.202091,
    "out:Bought CO2": 3.882444,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02000001110001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 43.633755,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 38.662021,
    "out:Electricity (inc PV)": 28.868753,
    "out:Total Energy Use Pre PV": 82.662021,
    "out:Total Energy Use Post PV": 72.868753,
    "out:Primary Energy": 81.038628,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 32.961787,
    "out:CO2 Operational/m2": 2.056602,
    "out:Total CO2/m2": 35.018389,
    "out:Total CO2 (tons)": 111.498517,
    "out:Klimatpaverkan": -88.636904,
    "out:Initial Cost/MSEK": 7.336233492796493,
    "out:Running cost/(Apt SEK y)": 12553.340426,
    "out:Running Cost over RSP/MSEK": 4.60903,
    "out:LCP/MSEK": 1.459457,
    "out:ROI% old": 17.127687,
    "out:Payback discounted": 7,
    "out:Atemp": 3184,
    "out:Umean": 0.55,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 73,
    "out:Energy use kWh/m2": 73,
    "out:Energy savings %": 150.7,
    "out:Op energy cost/MSEK": 33,
    "out:El price/MSEK": 23,
    "out:Heating price/MSEK": 9,
    "out:DH+ DHW/kWh/m2": 44,
    "out:El bought/kWh/m2": 2041.320511,
    "out:Return %": 15,
    "out:Return/kSEK/y": 1125,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.6502792112,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 28.356,
    "out:EAHP (El)": 23.792521,
    "out:PV (kr/kWp)": 17461.56255567083,
    "out:PV (kWp installed)": 45.25485,
    "out:PV (m2 panels)": 237.433632,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 42461.284501,
    "out:PV (kWh sold)": 11279.529879,
    "out:PV (% sold (El))": 26.564269,
    "out:PV (kWh self-consumed)": 31181.754623,
    "out:PV (ratio sold/self-consumed)": 0.361735,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 400985.103505,
    "out:EL kWh savings": -51895.213938,
    "out:Sewage heat recovery cost": 273216,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 314113.97,
    "out:DH kr savings": 400985.1035054075,
    "out:El kr savings": -103790.42787558923,
    "out:El kr sold": 16919.29481833254,
    "out:El kr saved": 62363.509246,
    "out:El kr return": 79282.80406433254,
    "out:% solar/total": 78,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 138929.834119,
    "out:% savings (space heating)": 65.876826,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 997124.4110739925,
    "out:Eupp": 37.38,
    "out:Etvv": 0,
    "out:Ef": 27.55,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 7336233.492796494,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 15.839296,
    "out:EL CO2": -13.782694,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -17.602727,
    "out:Bought CO2": 3.820033,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02000001110001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 39.99438,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 38.555591,
    "out:Electricity (inc PV)": 28.777755,
    "out:Total Energy Use Pre PV": 78.555591,
    "out:Total Energy Use Post PV": 68.777755,
    "out:Primary Energy": 78.134312,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 32.961787,
    "out:CO2 Operational/m2": 0.526315,
    "out:Total CO2/m2": 33.488102,
    "out:Total CO2 (tons)": 106.626085,
    "out:Klimatpaverkan": -93.509336,
    "out:Initial Cost/MSEK": 7.415833467796493,
    "out:Running cost/(Apt SEK y)": 11721.085106,
    "out:Running Cost over RSP/MSEK": 4.30314,
    "out:LCP/MSEK": 1.685747,
    "out:ROI% old": 17.533103,
    "out:Payback discounted": 7,
    "out:Atemp": 3184,
    "out:Umean": 0.55,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 69,
    "out:Energy use kWh/m2": 69,
    "out:Energy savings %": 165.2,
    "out:Op energy cost/MSEK": 31,
    "out:El price/MSEK": 22,
    "out:Heating price/MSEK": 8,
    "out:DH+ DHW/kWh/m2": 40,
    "out:El bought/kWh/m2": 2034.869173,
    "out:Return %": 16,
    "out:Return/kSEK/y": 1164,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.6502792112,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 28.356,
    "out:EAHP (El)": 23.686091,
    "out:PV (kr/kWp)": 17461.56255567083,
    "out:PV (kWp installed)": 45.25485,
    "out:PV (m2 panels)": 237.433632,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 42461.284501,
    "out:PV (kWh sold)": 11328.665355,
    "out:PV (% sold (El))": 26.679987,
    "out:PV (kWh self-consumed)": 31132.619146,
    "out:PV (ratio sold/self-consumed)": 0.363884,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 412572.870019,
    "out:EL kWh savings": -51605.476393,
    "out:Sewage heat recovery cost": 273216,
    "out:Balancing hydronic system cost": 79599.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 326354.92,
    "out:DH kr savings": 412572.8700188863,
    "out:El kr savings": -103210.9527859415,
    "out:El kr sold": 16992.998032368218,
    "out:El kr saved": 62265.238292,
    "out:El kr return": 79258.23632436822,
    "out:% solar/total": 78,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 127342.067605,
    "out:% savings (space heating)": 68.722949,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 997124.4110739925,
    "out:Eupp": 33.86,
    "out:Etvv": 0,
    "out:Ef": 27.43,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 7415833.467796493,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 14.39936,
    "out:EL CO2": -13.873045,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -17.681023,
    "out:Bought CO2": 3.807978,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02000001120000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 46.522525,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 39.303,
    "out:Electricity (inc PV)": 26.645606,
    "out:Total Energy Use Pre PV": 86.303,
    "out:Total Energy Use Post PV": 73.645606,
    "out:Primary Energy": 78.292329,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 46.992343,
    "out:CO2 Operational/m2": -50.271581,
    "out:Total CO2/m2": -3.279238,
    "out:Total CO2 (tons)": -10.441091,
    "out:Klimatpaverkan": -210.576512,
    "out:Initial Cost/MSEK": 7.853230844517743,
    "out:Running cost/(Apt SEK y)": 11801.787234,
    "out:Running Cost over RSP/MSEK": 4.34689,
    "out:LCP/MSEK": 1.204599,
    "out:ROI% old": 16.476986,
    "out:Payback discounted": 7,
    "out:Atemp": 3184,
    "out:Umean": 0.55,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 74,
    "out:Energy use kWh/m2": 74,
    "out:Energy savings %": 147.3,
    "out:Op energy cost/MSEK": 32,
    "out:El price/MSEK": 21,
    "out:Heating price/MSEK": 9,
    "out:DH+ DHW/kWh/m2": 47,
    "out:El bought/kWh/m2": 1882.997939,
    "out:Return %": 15,
    "out:Return/kSEK/y": 1160,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.6502792112,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 33.36,
    "out:EAHP (El)": 24.4335,
    "out:PV (kr/kWp)": 17461.48474631725,
    "out:PV (kWp installed)": 90.5097,
    "out:PV (m2 panels)": 474.867263,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 84922.568824,
    "out:PV (kWh sold)": 44621.438977,
    "out:PV (% sold (El))": 52.543675,
    "out:PV (kWh self-consumed)": 40301.129847,
    "out:PV (ratio sold/self-consumed)": 1.107201,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 391787.262593,
    "out:EL kWh savings": -44816.71602,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 369085.99,
    "out:DH kr savings": 391787.2625928492,
    "out:El kr savings": -89633.4320391389,
    "out:El kr sold": 66932.15846549699,
    "out:El kr saved": 80602.259694,
    "out:El kr return": 147534.41815949697,
    "out:% solar/total": 101,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 148127.675031,
    "out:% savings (space heating)": 63.617703,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 997124.4110739925,
    "out:Eupp": 39,
    "out:Etvv": 0,
    "out:Ef": 25.29,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 7853230.844517743,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 16.919248,
    "out:EL CO2": -67.190829,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -70.716166,
    "out:Bought CO2": 3.525337,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02000001120000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 42.8319,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 39.2121,
    "out:Electricity (inc PV)": 26.575656,
    "out:Total Energy Use Pre PV": 82.2121,
    "out:Total Energy Use Post PV": 69.575656,
    "out:Primary Energy": 75.400251,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 46.992343,
    "out:CO2 Operational/m2": -51.828195,
    "out:Total CO2/m2": -4.835852,
    "out:Total CO2 (tons)": -15.397348,
    "out:Klimatpaverkan": -215.532769,
    "out:Initial Cost/MSEK": 7.932830819517744,
    "out:Running cost/(Apt SEK y)": 10971.829787,
    "out:Running Cost over RSP/MSEK": 4.04183,
    "out:LCP/MSEK": 1.430059,
    "out:ROI% old": 16.861014,
    "out:Payback discounted": 7,
    "out:Atemp": 3184,
    "out:Umean": 0.55,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 70,
    "out:Energy use kWh/m2": 70,
    "out:Energy savings %": 161.4,
    "out:Op energy cost/MSEK": 30,
    "out:El price/MSEK": 20,
    "out:Heating price/MSEK": 8,
    "out:DH+ DHW/kWh/m2": 43,
    "out:El bought/kWh/m2": 1878.039712,
    "out:Return %": 15,
    "out:Return/kSEK/y": 1199,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.6502792112,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 33.36,
    "out:EAHP (El)": 24.3426,
    "out:PV (kr/kWp)": 17461.48474631725,
    "out:PV (kWp installed)": 90.5097,
    "out:PV (m2 panels)": 474.867263,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 84922.568824,
    "out:PV (kWh sold)": 44688.143913,
    "out:PV (% sold (El))": 52.622223,
    "out:PV (kWh self-consumed)": 40234.424911,
    "out:PV (ratio sold/self-consumed)": 1.110694,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 403538.209057,
    "out:EL kWh savings": -44593.995287,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 79599.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 381382.43,
    "out:DH kr savings": 403538.2090572305,
    "out:El kr savings": -89187.99057316316,
    "out:El kr sold": 67032.21586999379,
    "out:El kr saved": 80468.849822,
    "out:El kr return": 147501.0656919938,
    "out:% solar/total": 101,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 136376.728567,
    "out:% savings (space heating)": 66.503905,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 997124.4110739925,
    "out:Eupp": 35.44,
    "out:Etvv": 0,
    "out:Ef": 25.19,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 7932830.819517745,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 15.479312,
    "out:EL CO2": -67.307507,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -70.82358,
    "out:Bought CO2": 3.516073,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02000001120001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 43.633755,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 38.662021,
    "out:Electricity (inc PV)": 26.152896,
    "out:Total Energy Use Pre PV": 82.662021,
    "out:Total Energy Use Post PV": 70.152896,
    "out:Primary Energy": 76.150086,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 47.700886,
    "out:CO2 Operational/m2": -52.177603,
    "out:Total CO2/m2": -4.476717,
    "out:Total CO2 (tons)": -14.253863,
    "out:Klimatpaverkan": -214.389284,
    "out:Initial Cost/MSEK": 8.126446844517742,
    "out:Running cost/(Apt SEK y)": 11106.191489,
    "out:Running Cost over RSP/MSEK": 4.09151,
    "out:LCP/MSEK": 1.186763,
    "out:ROI% old": 16.371959,
    "out:Payback discounted": 7,
    "out:Atemp": 3184,
    "out:Umean": 0.55,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 70,
    "out:Energy use kWh/m2": 70,
    "out:Energy savings %": 161.4,
    "out:Op energy cost/MSEK": 30,
    "out:El price/MSEK": 20,
    "out:Heating price/MSEK": 9,
    "out:DH+ DHW/kWh/m2": 44,
    "out:El bought/kWh/m2": 1848.078783,
    "out:Return %": 15,
    "out:Return/kSEK/y": 1193,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.6502792112,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 28.356,
    "out:EAHP (El)": 23.792521,
    "out:PV (kr/kWp)": 17461.48474631725,
    "out:PV (kWp installed)": 90.5097,
    "out:PV (m2 panels)": 474.867263,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 84922.568824,
    "out:PV (kWh sold)": 45093.525547,
    "out:PV (% sold (El))": 53.099578,
    "out:PV (kWh self-consumed)": 39829.043277,
    "out:PV (ratio sold/self-consumed)": 1.132177,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 400985.103505,
    "out:EL kWh savings": -43247.927852,
    "out:Sewage heat recovery cost": 273216,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 382129.54,
    "out:DH kr savings": 400985.1035054075,
    "out:El kr savings": -86495.85570317126,
    "out:El kr sold": 67640.28832016315,
    "out:El kr saved": 79658.086554,
    "out:El kr return": 147298.37487416313,
    "out:% solar/total": 100,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 138929.834119,
    "out:% savings (space heating)": 65.876826,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 997124.4110739925,
    "out:Eupp": 37.38,
    "out:Etvv": 0,
    "out:Ef": 24.97,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 8126446.844517742,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 15.839296,
    "out:EL CO2": -68.016899,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -71.476997,
    "out:Bought CO2": 3.460098,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02000001120001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 39.99438,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 38.555591,
    "out:Electricity (inc PV)": 26.071232,
    "out:Total Energy Use Pre PV": 78.555591,
    "out:Total Energy Use Post PV": 66.071232,
    "out:Primary Energy": 73.262571,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 47.700886,
    "out:CO2 Operational/m2": -53.755427,
    "out:Total CO2/m2": -6.054541,
    "out:Total CO2 (tons)": -19.277653,
    "out:Klimatpaverkan": -219.413074,
    "out:Initial Cost/MSEK": 8.206046819517743,
    "out:Running cost/(Apt SEK y)": 10274.255319,
    "out:Running Cost over RSP/MSEK": 3.78573,
    "out:LCP/MSEK": 1.412943,
    "out:ROI% old": 16.745474,
    "out:Payback discounted": 7,
    "out:Atemp": 3184,
    "out:Umean": 0.55,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 66,
    "out:Energy use kWh/m2": 66,
    "out:Energy savings %": 177.3,
    "out:Op energy cost/MSEK": 28,
    "out:El price/MSEK": 19,
    "out:Heating price/MSEK": 8,
    "out:DH+ DHW/kWh/m2": 40,
    "out:El bought/kWh/m2": 1842.290608,
    "out:Return %": 15,
    "out:Return/kSEK/y": 1232,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.6502792112,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 28.356,
    "out:EAHP (El)": 23.686091,
    "out:PV (kr/kWp)": 17461.48474631725,
    "out:PV (kWp installed)": 90.5097,
    "out:PV (m2 panels)": 474.867263,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 84922.568824,
    "out:PV (kWh sold)": 45172.380581,
    "out:PV (% sold (El))": 53.192433,
    "out:PV (kWh self-consumed)": 39750.188243,
    "out:PV (ratio sold/self-consumed)": 1.136407,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 412572.870019,
    "out:EL kWh savings": -42987.909754,
    "out:Sewage heat recovery cost": 273216,
    "out:Balancing hydronic system cost": 79599.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 394355.62,
    "out:DH kr savings": 412572.8700188863,
    "out:El kr savings": -85975.81950763954,
    "out:El kr sold": 67758.5708710637,
    "out:El kr saved": 79500.376486,
    "out:El kr return": 147258.9473570637,
    "out:% solar/total": 99,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 127342.067605,
    "out:% savings (space heating)": 68.722949,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 997124.4110739925,
    "out:Eupp": 33.86,
    "out:Etvv": 0,
    "out:Ef": 24.86,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 8206046.819517743,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 14.39936,
    "out:EL CO2": -68.154787,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -71.604072,
    "out:Bought CO2": 3.449285,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02000002000000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 7.14209,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 53.763738,
    "out:Electricity (inc PV)": 53.763738,
    "out:Total Energy Use Pre PV": 60.763738,
    "out:Total Energy Use Post PV": 60.763738,
    "out:Primary Energy": 99.678019,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 18.057876,
    "out:CO2 Operational/m2": 9.617132,
    "out:Total CO2/m2": 27.675008,
    "out:Total CO2 (tons)": 88.117199,
    "out:Klimatpaverkan": -112.018222,
    "out:Initial Cost/MSEK": 7.739874411073993,
    "out:Running cost/(Apt SEK y)": 8716.531915,
    "out:Running Cost over RSP/MSEK": 3.17325,
    "out:LCP/MSEK": 2.491596,
    "out:ROI% old": 18.884526,
    "out:Payback discounted": 7,
    "out:Atemp": 3184,
    "out:Umean": 0.55,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 61,
    "out:Energy use kWh/m2": 61,
    "out:Energy savings %": 200,
    "out:Op energy cost/MSEK": 21,
    "out:El price/MSEK": 20,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 7,
    "out:El bought/kWh/m2": 3763.46166,
    "out:Return %": 17,
    "out:Return/kSEK/y": 1305,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 1774800,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 88740,
    "out:EAHP (replacement cost)": 1325203.921671023,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 41.7,
    "out:EAHP (El)": 38.894238,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 517174.529906,
    "out:EL kWh savings": -131160.822328,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 254852.89,
    "out:DH kr savings": 517174.5299063925,
    "out:El kr savings": -262321.644656798,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 22740.407718,
    "out:% savings (space heating)": 94.414627,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 997124.4110739925,
    "out:Eupp": 3.73,
    "out:Etvv": 0,
    "out:Ef": 49.74,
    "out:heatPumpQuantity": 12,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 7739874.411073993,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 2.519888,
    "out:EL CO2": 7.097244,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 7.097244,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02000002000000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 5.248216,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 53.128368,
    "out:Electricity (inc PV)": 53.128368,
    "out:Total Energy Use Pre PV": 58.128368,
    "out:Total Energy Use Post PV": 58.128368,
    "out:Primary Energy": 97.565969,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 18.057876,
    "out:CO2 Operational/m2": 8.81329,
    "out:Total CO2/m2": 26.871166,
    "out:Total CO2 (tons)": 85.557767,
    "out:Klimatpaverkan": -114.577654,
    "out:Initial Cost/MSEK": 7.819474386073994,
    "out:Running cost/(Apt SEK y)": 8221.276596,
    "out:Running Cost over RSP/MSEK": 2.99155,
    "out:LCP/MSEK": 2.593696,
    "out:ROI% old": 19.024242,
    "out:Payback discounted": 6,
    "out:Atemp": 3184,
    "out:Umean": 0.55,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 58,
    "out:Energy use kWh/m2": 58,
    "out:Energy savings %": 215.5,
    "out:Op energy cost/MSEK": 20,
    "out:El price/MSEK": 19,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 5,
    "out:El bought/kWh/m2": 3718.98576,
    "out:Return %": 17,
    "out:Return/kSEK/y": 1328,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 1774800,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 88740,
    "out:EAHP (replacement cost)": 1325203.921671023,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 41.7,
    "out:EAHP (El)": 38.258868,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 523204.622908,
    "out:EL kWh savings": -129137.804857,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 79599.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 264929.01,
    "out:DH kr savings": 523204.62290806114,
    "out:El kr savings": -258275.6097141669,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 16710.314716,
    "out:% savings (space heating)": 95.895705,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 997124.4110739925,
    "out:Eupp": 2.49,
    "out:Etvv": 0,
    "out:Ef": 48.9,
    "out:heatPumpQuantity": 12,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 7819474.3860739935,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 1.79992,
    "out:EL CO2": 7.01337,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 7.01337,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02000002000001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 5.69198,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 52.30771,
    "out:Electricity (inc PV)": 52.30771,
    "out:Total Energy Use Pre PV": 58.30771,
    "out:Total Energy Use Post PV": 58.30771,
    "out:Primary Energy": 96.615171,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 18.766419,
    "out:CO2 Operational/m2": 9.06494,
    "out:Total CO2/m2": 27.831359,
    "out:Total CO2 (tons)": 88.61502,
    "out:Klimatpaverkan": -111.520401,
    "out:Initial Cost/MSEK": 8.013090411073993,
    "out:Running cost/(Apt SEK y)": 8314.659574,
    "out:Running Cost over RSP/MSEK": 3.02636,
    "out:LCP/MSEK": 2.36527,
    "out:ROI% old": 18.50251,
    "out:Payback discounted": 7,
    "out:Atemp": 3184,
    "out:Umean": 0.55,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 58,
    "out:Energy use kWh/m2": 58,
    "out:Energy savings %": 215.5,
    "out:Op energy cost/MSEK": 20,
    "out:El price/MSEK": 19,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 6,
    "out:El bought/kWh/m2": 3661.5397,
    "out:Return %": 17,
    "out:Return/kSEK/y": 1324,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 1774800,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 88740,
    "out:EAHP (replacement cost)": 1325203.921671023,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 35.445,
    "out:EAHP (El)": 37.43821,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 521791.678757,
    "out:EL kWh savings": -126524.830571,
    "out:Sewage heat recovery cost": 273216,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 268742.02,
    "out:DH kr savings": 521791.6787571871,
    "out:El kr savings": -253049.66114254764,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 18123.258867,
    "out:% savings (space heating)": 95.548666,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 997124.4110739925,
    "out:Eupp": 3.16,
    "out:Etvv": 0,
    "out:Ef": 48.77,
    "out:heatPumpQuantity": 12,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 8013090.411073993,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 2.159904,
    "out:EL CO2": 6.905036,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 6.905036,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02000002000001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 4.038245,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 51.599571,
    "out:Electricity (inc PV)": 51.599571,
    "out:Total Energy Use Pre PV": 55.599571,
    "out:Total Energy Use Post PV": 55.599571,
    "out:Primary Energy": 94.486693,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 18.766419,
    "out:CO2 Operational/m2": 8.251492,
    "out:Total CO2/m2": 27.017911,
    "out:Total CO2 (tons)": 86.025003,
    "out:Klimatpaverkan": -114.110418,
    "out:Initial Cost/MSEK": 8.092690386073993,
    "out:Running cost/(Apt SEK y)": 7809.553191,
    "out:Running Cost over RSP/MSEK": 2.84108,
    "out:LCP/MSEK": 2.47095,
    "out:ROI% old": 18.647586,
    "out:Payback discounted": 7,
    "out:Atemp": 3184,
    "out:Umean": 0.55,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 56,
    "out:Energy use kWh/m2": 56,
    "out:Energy savings %": 226.8,
    "out:Op energy cost/MSEK": 19,
    "out:El price/MSEK": 18,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 4,
    "out:El bought/kWh/m2": 3611.96997,
    "out:Return %": 17,
    "out:Return/kSEK/y": 1348,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 1774800,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 88740,
    "out:EAHP (replacement cost)": 1325203.921671023,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 35.445,
    "out:EAHP (El)": 36.730071,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 527057.169413,
    "out:EL kWh savings": -124270.116674,
    "out:Sewage heat recovery cost": 273216,
    "out:Balancing hydronic system cost": 79599.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 278516.94,
    "out:DH kr savings": 527057.1694129091,
    "out:El kr savings": -248540.23334734194,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 12857.768211,
    "out:% savings (space heating)": 96.841946,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 997124.4110739925,
    "out:Eupp": 2.07,
    "out:Etvv": 0,
    "out:Ef": 47.9,
    "out:heatPumpQuantity": 12,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 8092690.3860739935,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 1.439936,
    "out:EL CO2": 6.811556,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 6.811556,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02000002010000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 7.14209,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 53.763738,
    "out:Electricity (inc PV)": 42.18745,
    "out:Total Energy Use Pre PV": 60.763738,
    "out:Total Energy Use Post PV": 49.18745,
    "out:Primary Energy": 78.840701,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 32.796976,
    "out:CO2 Operational/m2": -0.456957,
    "out:Total CO2/m2": 32.340019,
    "out:Total CO2 (tons)": 102.97059,
    "out:Klimatpaverkan": -97.164832,
    "out:Initial Cost/MSEK": 8.530094805296494,
    "out:Running cost/(Apt SEK y)": 6969.276596,
    "out:Running Cost over RSP/MSEK": 2.54823,
    "out:LCP/MSEK": 2.326395,
    "out:ROI% old": 18.181828,
    "out:Payback discounted": 7,
    "out:Atemp": 3184,
    "out:Umean": 0.55,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 49,
    "out:Energy use kWh/m2": 49,
    "out:Energy savings %": 273.5,
    "out:Op energy cost/MSEK": 18,
    "out:El price/MSEK": 16,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 7,
    "out:El bought/kWh/m2": 2984.932932,
    "out:Return %": 16,
    "out:Return/kSEK/y": 1387,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 1774800,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 88740,
    "out:EAHP (replacement cost)": 1325203.921671023,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 41.7,
    "out:EAHP (El)": 38.894238,
    "out:PV (kr/kWp)": 17461.56255567083,
    "out:PV (kWp installed)": 45.25485,
    "out:PV (m2 panels)": 237.433632,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 42461.284501,
    "out:PV (kWh sold)": 5602.394304,
    "out:PV (% sold (El))": 13.194123,
    "out:PV (kWh self-consumed)": 36858.890198,
    "out:PV (ratio sold/self-consumed)": 0.151996,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 517174.529906,
    "out:EL kWh savings": -94301.932426,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 336974.26,
    "out:DH kr savings": 517174.5299063925,
    "out:El kr savings": -188603.86485296584,
    "out:El kr sold": 8403.5914555808,
    "out:El kr saved": 73717.780396,
    "out:El kr return": 82121.3718515808,
    "out:% solar/total": 92,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 22740.407718,
    "out:% savings (space heating)": 94.414627,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 997124.4110739925,
    "out:Eupp": 3.73,
    "out:Etvv": 0,
    "out:Ef": 38.56,
    "out:heatPumpQuantity": 12,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 8530094.805296494,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 2.519888,
    "out:EL CO2": -2.976845,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -8.560096,
    "out:Bought CO2": 5.583251,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02000002010000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 5.248216,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 53.128368,
    "out:Electricity (inc PV)": 41.612103,
    "out:Total Energy Use Pre PV": 58.128368,
    "out:Total Energy Use Post PV": 46.612103,
    "out:Primary Energy": 76.836692,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 32.796976,
    "out:CO2 Operational/m2": -1.554677,
    "out:Total CO2/m2": 31.242299,
    "out:Total CO2 (tons)": 99.47545,
    "out:Klimatpaverkan": -100.659971,
    "out:Initial Cost/MSEK": 8.609694780296493,
    "out:Running cost/(Apt SEK y)": 6476.042553,
    "out:Running Cost over RSP/MSEK": 2.36724,
    "out:LCP/MSEK": 2.427785,
    "out:ROI% old": 18.314039,
    "out:Payback discounted": 7,
    "out:Atemp": 3184,
    "out:Umean": 0.55,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 47,
    "out:Energy use kWh/m2": 47,
    "out:Energy savings %": 289.4,
    "out:Op energy cost/MSEK": 16,
    "out:El price/MSEK": 15,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 5,
    "out:El bought/kWh/m2": 2944.131234,
    "out:Return %": 16,
    "out:Return/kSEK/y": 1410,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 1774800,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 88740,
    "out:EAHP (replacement cost)": 1325203.921671023,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 41.7,
    "out:EAHP (El)": 38.258868,
    "out:PV (kr/kWp)": 17461.56255567083,
    "out:PV (kWp installed)": 45.25485,
    "out:PV (m2 panels)": 237.433632,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 42461.284501,
    "out:PV (kWh sold)": 5793.509276,
    "out:PV (% sold (El))": 13.644216,
    "out:PV (kWh self-consumed)": 36667.775226,
    "out:PV (ratio sold/self-consumed)": 0.158,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 523204.622908,
    "out:EL kWh savings": -92470.02813,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 79599.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 346954.83,
    "out:DH kr savings": 523204.62290806114,
    "out:El kr savings": -184940.05625933062,
    "out:El kr sold": 8690.263913716597,
    "out:El kr saved": 73335.550452,
    "out:El kr return": 82025.8143657166,
    "out:% solar/total": 92,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 16710.314716,
    "out:% savings (space heating)": 95.895705,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 997124.4110739925,
    "out:Eupp": 2.49,
    "out:Etvv": 0,
    "out:Ef": 37.82,
    "out:heatPumpQuantity": 12,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 8609694.780296493,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 1.79992,
    "out:EL CO2": -3.354597,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -8.861656,
    "out:Bought CO2": 5.507059,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02000002010001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 5.69198,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 52.30771,
    "out:Electricity (inc PV)": 40.871324,
    "out:Total Energy Use Pre PV": 58.30771,
    "out:Total Energy Use Post PV": 46.871324,
    "out:Primary Energy": 76.029676,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 33.505519,
    "out:CO2 Operational/m2": -1.692883,
    "out:Total CO2/m2": 31.812636,
    "out:Total CO2 (tons)": 101.291403,
    "out:Klimatpaverkan": -98.844018,
    "out:Initial Cost/MSEK": 8.803310805296492,
    "out:Running cost/(Apt SEK y)": 6572.148936,
    "out:Running Cost over RSP/MSEK": 2.403,
    "out:LCP/MSEK": 2.198409,
    "out:ROI% old": 17.853218,
    "out:Payback discounted": 7,
    "out:Atemp": 3184,
    "out:Umean": 0.55,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 47,
    "out:Energy use kWh/m2": 47,
    "out:Energy savings %": 289.4,
    "out:Op energy cost/MSEK": 17,
    "out:El price/MSEK": 15,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 6,
    "out:El bought/kWh/m2": 2891.567924,
    "out:Return %": 16,
    "out:Return/kSEK/y": 1406,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 1774800,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 88740,
    "out:EAHP (replacement cost)": 1325203.921671023,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 35.445,
    "out:EAHP (El)": 37.43821,
    "out:PV (kr/kWp)": 17461.56255567083,
    "out:PV (kWp installed)": 45.25485,
    "out:PV (m2 panels)": 237.433632,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 42461.284501,
    "out:PV (kWh sold)": 6047.841483,
    "out:PV (% sold (El))": 14.24319,
    "out:PV (kWh self-consumed)": 36413.443018,
    "out:PV (ratio sold/self-consumed)": 0.166088,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 521791.678757,
    "out:EL kWh savings": -90111.388503,
    "out:Sewage heat recovery cost": 273216,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 350640.66,
    "out:DH kr savings": 521791.6787571871,
    "out:El kr savings": -180222.7770066632,
    "out:El kr sold": 9071.762224631586,
    "out:El kr saved": 72826.886036,
    "out:El kr return": 81898.64826063158,
    "out:% solar/total": 91,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 18123.258867,
    "out:% savings (space heating)": 95.548666,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 997124.4110739925,
    "out:Eupp": 3.16,
    "out:Etvv": 0,
    "out:Ef": 37.7,
    "out:heatPumpQuantity": 12,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 8803310.805296492,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 2.159904,
    "out:EL CO2": -3.852787,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -9.261704,
    "out:Bought CO2": 5.408917,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02000002010001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 4.038245,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 51.599571,
    "out:Electricity (inc PV)": 40.233869,
    "out:Total Energy Use Pre PV": 55.599571,
    "out:Total Energy Use Post PV": 44.233869,
    "out:Primary Energy": 74.028429,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 33.505519,
    "out:CO2 Operational/m2": -2.852781,
    "out:Total CO2/m2": 30.652738,
    "out:Total CO2 (tons)": 97.598288,
    "out:Klimatpaverkan": -102.537133,
    "out:Initial Cost/MSEK": 8.882910780296493,
    "out:Running cost/(Apt SEK y)": 6069.425532,
    "out:Running Cost over RSP/MSEK": 2.21856,
    "out:LCP/MSEK": 2.303249,
    "out:ROI% old": 17.989856,
    "out:Payback discounted": 7,
    "out:Atemp": 3184,
    "out:Umean": 0.55,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 44,
    "out:Energy use kWh/m2": 44,
    "out:Energy savings %": 315.9,
    "out:Op energy cost/MSEK": 15,
    "out:El price/MSEK": 14,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 4,
    "out:El bought/kWh/m2": 2846.340476,
    "out:Return %": 16,
    "out:Return/kSEK/y": 1429,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 1774800,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 88740,
    "out:EAHP (replacement cost)": 1325203.921671023,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 35.445,
    "out:EAHP (El)": 36.730071,
    "out:PV (kr/kWp)": 17461.56255567083,
    "out:PV (kWp installed)": 45.25485,
    "out:PV (m2 panels)": 237.433632,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 42461.284501,
    "out:PV (kWh sold)": 6272.900574,
    "out:PV (% sold (El))": 14.773224,
    "out:PV (kWh self-consumed)": 36188.383928,
    "out:PV (ratio sold/self-consumed)": 0.17334,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 527057.169413,
    "out:EL kWh savings": -88081.732394,
    "out:Sewage heat recovery cost": 273216,
    "out:Balancing hydronic system cost": 79599.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 360303.06,
    "out:DH kr savings": 527057.1694129091,
    "out:El kr savings": -176163.464788027,
    "out:El kr sold": 9409.350860440532,
    "out:El kr saved": 72376.767856,
    "out:El kr return": 81786.11871644054,
    "out:% solar/total": 90,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 12857.768211,
    "out:% savings (space heating)": 96.841946,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 997124.4110739925,
    "out:Eupp": 2.07,
    "out:Etvv": 0,
    "out:Ef": 36.92,
    "out:heatPumpQuantity": 12,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 8882910.780296493,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 1.439936,
    "out:EL CO2": -4.292717,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -9.617212,
    "out:Bought CO2": 5.324495,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02000002020000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 7.14209,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 53.763738,
    "out:Electricity (inc PV)": 38.101894,
    "out:Total Energy Use Pre PV": 60.763738,
    "out:Total Energy Use Post PV": 45.101894,
    "out:Primary Energy": 71.4867,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 47.536075,
    "out:CO2 Operational/m2": -47.733335,
    "out:Total CO2/m2": -0.19726,
    "out:Total CO2 (tons)": -0.628076,
    "out:Klimatpaverkan": -200.763497,
    "out:Initial Cost/MSEK": 9.320308157017744,
    "out:Running cost/(Apt SEK y)": 5475.744681,
    "out:Running Cost over RSP/MSEK": 2.01375,
    "out:LCP/MSEK": 2.070662,
    "out:ROI% old": 17.459524,
    "out:Payback discounted": 7,
    "out:Atemp": 3184,
    "out:Umean": 0.55,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 45,
    "out:Energy use kWh/m2": 45,
    "out:Energy savings %": 306.7,
    "out:Op energy cost/MSEK": 14,
    "out:El price/MSEK": 13,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 7,
    "out:El bought/kWh/m2": 2693.472163,
    "out:Return %": 16,
    "out:Return/kSEK/y": 1457,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 1774800,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 88740,
    "out:EAHP (replacement cost)": 1325203.921671023,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 41.7,
    "out:EAHP (El)": 38.894238,
    "out:PV (kr/kWp)": 17461.48474631725,
    "out:PV (kWp installed)": 90.5097,
    "out:PV (m2 panels)": 474.867263,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 84922.568824,
    "out:PV (kWh sold)": 35055.273672,
    "out:PV (% sold (El))": 41.279102,
    "out:PV (kWh self-consumed)": 49867.295152,
    "out:PV (ratio sold/self-consumed)": 0.702971,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 517174.529906,
    "out:EL kWh savings": -81293.526036,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 407170.39,
    "out:DH kr savings": 517174.5299063925,
    "out:El kr savings": -162587.05207289115,
    "out:El kr sold": 52582.910507799475,
    "out:El kr saved": 99734.590304,
    "out:El kr return": 152317.5008117995,
    "out:% solar/total": 125,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 22740.407718,
    "out:% savings (space heating)": 94.414627,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 997124.4110739925,
    "out:Eupp": 3.73,
    "out:Etvv": 0,
    "out:Ef": 34.86,
    "out:heatPumpQuantity": 12,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 9320308.157017743,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 2.519888,
    "out:EL CO2": -50.253223,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -55.294767,
    "out:Bought CO2": 5.041544,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02000002020000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 5.248216,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 53.128368,
    "out:Electricity (inc PV)": 37.587361,
    "out:Total Energy Use Pre PV": 58.128368,
    "out:Total Energy Use Post PV": 42.587361,
    "out:Primary Energy": 69.592157,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 47.536075,
    "out:CO2 Operational/m2": -49.14321,
    "out:Total CO2/m2": -1.607135,
    "out:Total CO2 (tons)": -5.117116,
    "out:Klimatpaverkan": -205.252537,
    "out:Initial Cost/MSEK": 9.399908132017742,
    "out:Running cost/(Apt SEK y)": 4984.574468,
    "out:Running Cost over RSP/MSEK": 1.83353,
    "out:LCP/MSEK": 2.171282,
    "out:ROI% old": 17.585567,
    "out:Payback discounted": 7,
    "out:Atemp": 3184,
    "out:Umean": 0.55,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 43,
    "out:Energy use kWh/m2": 43,
    "out:Energy savings %": 325.6,
    "out:Op energy cost/MSEK": 13,
    "out:El price/MSEK": 12,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 5,
    "out:El bought/kWh/m2": 2657.098662,
    "out:Return %": 16,
    "out:Return/kSEK/y": 1480,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 1774800,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 88740,
    "out:EAHP (replacement cost)": 1325203.921671023,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 41.7,
    "out:EAHP (El)": 38.258868,
    "out:PV (kr/kWp)": 17461.48474631725,
    "out:PV (kWp installed)": 90.5097,
    "out:PV (m2 panels)": 474.867263,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 84922.568824,
    "out:PV (kWh sold)": 35440.016022,
    "out:PV (% sold (El))": 41.732153,
    "out:PV (kWh self-consumed)": 49482.552802,
    "out:PV (ratio sold/self-consumed)": 0.716212,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 523204.622908,
    "out:EL kWh savings": -79655.253457,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 79599.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 417054.14,
    "out:DH kr savings": 523204.62290806114,
    "out:El kr savings": -159310.50691473638,
    "out:El kr sold": 53160.02403270562,
    "out:El kr saved": 98965.105604,
    "out:El kr return": 152125.12963670562,
    "out:% solar/total": 124,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 16710.314716,
    "out:% savings (space heating)": 95.895705,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 997124.4110739925,
    "out:Eupp": 2.49,
    "out:Etvv": 0,
    "out:Ef": 34.19,
    "out:heatPumpQuantity": 12,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 9399908.132017743,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 1.79992,
    "out:EL CO2": -50.94313,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -55.916636,
    "out:Bought CO2": 4.973506,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02000002020001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 5.69198,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 52.30771,
    "out:Electricity (inc PV)": 36.923945,
    "out:Total Energy Use Pre PV": 58.30771,
    "out:Total Energy Use Post PV": 42.923945,
    "out:Primary Energy": 68.924394,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 48.244618,
    "out:CO2 Operational/m2": -49.679443,
    "out:Total CO2/m2": -1.434825,
    "out:Total CO2 (tons)": -4.568481,
    "out:Klimatpaverkan": -204.703902,
    "out:Initial Cost/MSEK": 9.593524157017743,
    "out:Running cost/(Apt SEK y)": 5083.297872,
    "out:Running Cost over RSP/MSEK": 1.87025,
    "out:LCP/MSEK": 1.940946,
    "out:ROI% old": 17.175976,
    "out:Payback discounted": 7,
    "out:Atemp": 3184,
    "out:Umean": 0.55,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 43,
    "out:Energy use kWh/m2": 43,
    "out:Energy savings %": 325.6,
    "out:Op energy cost/MSEK": 13,
    "out:El price/MSEK": 12,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 6,
    "out:El bought/kWh/m2": 2610.21265,
    "out:Return %": 15,
    "out:Return/kSEK/y": 1476,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 1774800,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 88740,
    "out:EAHP (replacement cost)": 1325203.921671023,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 35.445,
    "out:EAHP (El)": 37.43821,
    "out:PV (kr/kWp)": 17461.48474631725,
    "out:PV (kWp installed)": 90.5097,
    "out:PV (m2 panels)": 474.867263,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 84922.568824,
    "out:PV (kWh sold)": 35940.67494,
    "out:PV (% sold (El))": 42.3217,
    "out:PV (kWh self-consumed)": 48981.893884,
    "out:PV (ratio sold/self-consumed)": 0.733754,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 521791.678757,
    "out:EL kWh savings": -77542.937549,
    "out:Sewage heat recovery cost": 273216,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 420616.82,
    "out:DH kr savings": 521791.6787571871,
    "out:El kr savings": -155085.8750978414,
    "out:El kr sold": 53911.01240986294,
    "out:El kr saved": 97963.787768,
    "out:El kr return": 151874.80017786293,
    "out:% solar/total": 122,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 18123.258867,
    "out:% savings (space heating)": 95.548666,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 997124.4110739925,
    "out:Eupp": 3.16,
    "out:Etvv": 0,
    "out:Ef": 34.08,
    "out:heatPumpQuantity": 12,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 9593524.157017743,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 2.159904,
    "out:EL CO2": -51.839347,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -56.725108,
    "out:Bought CO2": 4.885761,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02000002020001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 4.038245,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 51.599571,
    "out:Electricity (inc PV)": 36.352814,
    "out:Total Energy Use Pre PV": 55.599571,
    "out:Total Energy Use Post PV": 40.352814,
    "out:Primary Energy": 67.04253,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 48.244618,
    "out:CO2 Operational/m2": -51.17814,
    "out:Total CO2/m2": -2.933522,
    "out:Total CO2 (tons)": -9.340331,
    "out:Klimatpaverkan": -209.475752,
    "out:Initial Cost/MSEK": 9.673124132017742,
    "out:Running cost/(Apt SEK y)": 4582.808511,
    "out:Running Cost over RSP/MSEK": 1.68664,
    "out:LCP/MSEK": 2.044956,
    "out:ROI% old": 17.305799,
    "out:Payback discounted": 7,
    "out:Atemp": 3184,
    "out:Umean": 0.55,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 40,
    "out:Energy use kWh/m2": 40,
    "out:Energy savings %": 357.5,
    "out:Op energy cost/MSEK": 12,
    "out:El price/MSEK": 11,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 4,
    "out:El bought/kWh/m2": 2569.843046,
    "out:Return %": 15,
    "out:Return/kSEK/y": 1499,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 1774800,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 88740,
    "out:EAHP (replacement cost)": 1325203.921671023,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 35.445,
    "out:EAHP (El)": 36.730071,
    "out:PV (kr/kWp)": 17461.48474631725,
    "out:PV (kWp installed)": 90.5097,
    "out:PV (m2 panels)": 474.867263,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 84922.568824,
    "out:PV (kWh sold)": 36376.910037,
    "out:PV (% sold (El))": 42.835386,
    "out:PV (kWh self-consumed)": 48545.658787,
    "out:PV (ratio sold/self-consumed)": 0.749334,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 527057.169413,
    "out:EL kWh savings": -75724.456992,
    "out:Sewage heat recovery cost": 273216,
    "out:Balancing hydronic system cost": 79599.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 430173.62,
    "out:DH kr savings": 527057.1694129091,
    "out:El kr savings": -151448.91398412833,
    "out:El kr sold": 54565.36505603056,
    "out:El kr saved": 97091.317574,
    "out:El kr return": 151656.68263003055,
    "out:% solar/total": 121,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 12857.768211,
    "out:% savings (space heating)": 96.841946,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 997124.4110739925,
    "out:Eupp": 2.07,
    "out:Etvv": 0,
    "out:Ef": 33.38,
    "out:heatPumpQuantity": 12,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 9673124.132017743,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 1.439936,
    "out:EL CO2": -52.618076,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -57.428263,
    "out:Bought CO2": 4.810187,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02000002100000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 5.251879,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 51.809256,
    "out:Electricity (inc PV)": 51.809256,
    "out:Total Energy Use Pre PV": 56.809256,
    "out:Total Energy Use Post PV": 56.809256,
    "out:Primary Energy": 95.58117,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 18.057876,
    "out:CO2 Operational/m2": 8.639156,
    "out:Total CO2/m2": 26.697032,
    "out:Total CO2 (tons)": 85.003324,
    "out:Klimatpaverkan": -115.132097,
    "out:Initial Cost/MSEK": 7.863922098573994,
    "out:Running cost/(Apt SEK y)": 8042.553191,
    "out:Running Cost over RSP/MSEK": 2.92661,
    "out:LCP/MSEK": 2.614188,
    "out:ROI% old": 19.034686,
    "out:Payback discounted": 6,
    "out:Atemp": 3184,
    "out:Umean": 0.55,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 57,
    "out:Energy use kWh/m2": 57,
    "out:Energy savings %": 221.1,
    "out:Op energy cost/MSEK": 20,
    "out:El price/MSEK": 18,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 5,
    "out:El bought/kWh/m2": 3626.64792,
    "out:Return %": 17,
    "out:Return/kSEK/y": 1337,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 1774800,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 88740,
    "out:EAHP (replacement cost)": 1325203.921671023,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 33.36,
    "out:EAHP (El)": 36.939756,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 523192.95992,
    "out:EL kWh savings": -124937.753513,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 273317.45,
    "out:DH kr savings": 523192.95991957036,
    "out:El kr savings": -249875.50702558548,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 16721.977705,
    "out:% savings (space heating)": 95.892841,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 997124.4110739925,
    "out:Eupp": 2.99,
    "out:Etvv": 0,
    "out:Ef": 48.45,
    "out:heatPumpQuantity": 12,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 7863922.098573994,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 1.79992,
    "out:EL CO2": 6.839236,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 6.839236,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02000002100000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 3.685808,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 51.074552,
    "out:Electricity (inc PV)": 51.074552,
    "out:Total Energy Use Pre PV": 55.074552,
    "out:Total Energy Use Post PV": 55.074552,
    "out:Primary Energy": 93.446077,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 18.057876,
    "out:CO2 Operational/m2": 8.182185,
    "out:Total CO2/m2": 26.240061,
    "out:Total CO2 (tons)": 83.548329,
    "out:Klimatpaverkan": -116.587092,
    "out:Initial Cost/MSEK": 7.943522073573994,
    "out:Running cost/(Apt SEK y)": 7738.404255,
    "out:Running Cost over RSP/MSEK": 2.81523,
    "out:LCP/MSEK": 2.645968,
    "out:ROI% old": 19.044251,
    "out:Payback discounted": 6,
    "out:Atemp": 3184,
    "out:Umean": 0.55,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 55,
    "out:Energy use kWh/m2": 55,
    "out:Energy savings %": 232.7,
    "out:Op energy cost/MSEK": 19,
    "out:El price/MSEK": 18,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 4,
    "out:El bought/kWh/m2": 3575.21864,
    "out:Return %": 17,
    "out:Return/kSEK/y": 1351,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 1774800,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 88740,
    "out:EAHP (replacement cost)": 1325203.921671023,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 33.36,
    "out:EAHP (El)": 36.205052,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 528179.328483,
    "out:EL kWh savings": -122598.456681,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 79599.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 282982.42,
    "out:DH kr savings": 528179.3284832743,
    "out:El kr savings": -245196.91336127833,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 11735.609141,
    "out:% savings (space heating)": 97.117565,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 997124.4110739925,
    "out:Eupp": 1.94,
    "out:Etvv": 0,
    "out:Ef": 47.55,
    "out:heatPumpQuantity": 12,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 7943522.0735739935,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 1.439936,
    "out:EL CO2": 6.742249,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 6.742249,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02000002100001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 4.295816,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 50.582609,
    "out:Electricity (inc PV)": 50.582609,
    "out:Total Energy Use Pre PV": 54.582609,
    "out:Total Energy Use Post PV": 54.582609,
    "out:Primary Energy": 93.071636,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 18.766419,
    "out:CO2 Operational/m2": 8.117245,
    "out:Total CO2/m2": 26.883664,
    "out:Total CO2 (tons)": 85.59756,
    "out:Klimatpaverkan": -114.537861,
    "out:Initial Cost/MSEK": 8.137138098573994,
    "out:Running cost/(Apt SEK y)": 7671.765957,
    "out:Running Cost over RSP/MSEK": 2.79102,
    "out:LCP/MSEK": 2.476562,
    "out:ROI% old": 18.633613,
    "out:Payback discounted": 7,
    "out:Atemp": 3184,
    "out:Umean": 0.55,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 55,
    "out:Energy use kWh/m2": 55,
    "out:Energy savings %": 232.7,
    "out:Op energy cost/MSEK": 19,
    "out:El price/MSEK": 18,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 4,
    "out:El bought/kWh/m2": 3540.78263,
    "out:Return %": 17,
    "out:Return/kSEK/y": 1354,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 1774800,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 88740,
    "out:EAHP (replacement cost)": 1325203.921671023,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 28.356,
    "out:EAHP (El)": 35.713109,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 526237.063596,
    "out:EL kWh savings": -121032.11064,
    "out:Sewage heat recovery cost": 273216,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 284172.84,
    "out:DH kr savings": 526237.063595662,
    "out:El kr savings": -242064.22127984115,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 13677.874029,
    "out:% savings (space heating)": 96.640517,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 997124.4110739925,
    "out:Eupp": 2.6,
    "out:Etvv": 0,
    "out:Ef": 47.66,
    "out:heatPumpQuantity": 12,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 8137138.098573994,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 1.439936,
    "out:EL CO2": 6.677309,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 6.677309,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02000002100001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 2.926861,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 49.788173,
    "out:Electricity (inc PV)": 49.788173,
    "out:Total Energy Use Pre PV": 52.788173,
    "out:Total Energy Use Post PV": 52.788173,
    "out:Primary Energy": 90.920224,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 18.766419,
    "out:CO2 Operational/m2": 7.652389,
    "out:Total CO2/m2": 26.418808,
    "out:Total CO2 (tons)": 84.117459,
    "out:Klimatpaverkan": -116.017962,
    "out:Initial Cost/MSEK": 8.216738073573994,
    "out:Running cost/(Apt SEK y)": 7359.531915,
    "out:Running Cost over RSP/MSEK": 2.67669,
    "out:LCP/MSEK": 2.511292,
    "out:ROI% old": 18.651875,
    "out:Payback discounted": 7,
    "out:Atemp": 3184,
    "out:Umean": 0.55,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 53,
    "out:Energy use kWh/m2": 53,
    "out:Energy savings %": 245.3,
    "out:Op energy cost/MSEK": 18,
    "out:El price/MSEK": 17,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 3,
    "out:El bought/kWh/m2": 3485.17211,
    "out:Return %": 17,
    "out:Return/kSEK/y": 1369,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 1774800,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 88740,
    "out:EAHP (replacement cost)": 1325203.921671023,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 28.356,
    "out:EAHP (El)": 34.918673,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 530595.815004,
    "out:EL kWh savings": -118502.627177,
    "out:Sewage heat recovery cost": 273216,
    "out:Balancing hydronic system cost": 79599.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 293590.56,
    "out:DH kr savings": 530595.8150042031,
    "out:El kr savings": -237005.25435398053,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 9319.12262,
    "out:% savings (space heating)": 97.711089,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 997124.4110739925,
    "out:Eupp": 1.67,
    "out:Etvv": 0,
    "out:Ef": 46.73,
    "out:heatPumpQuantity": 12,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 8216738.073573994,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 1.079952,
    "out:EL CO2": 6.572437,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 6.572437,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02000002110000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 5.251879,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 51.809256,
    "out:Electricity (inc PV)": 40.42252,
    "out:Total Energy Use Pre PV": 56.809256,
    "out:Total Energy Use Post PV": 45.42252,
    "out:Primary Energy": 75.085045,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 32.796976,
    "out:CO2 Operational/m2": -2.361614,
    "out:Total CO2/m2": 30.435362,
    "out:Total CO2 (tons)": 96.906163,
    "out:Klimatpaverkan": -103.229258,
    "out:Initial Cost/MSEK": 8.654142492796494,
    "out:Running cost/(Apt SEK y)": 6301.702128,
    "out:Running Cost over RSP/MSEK": 2.30384,
    "out:LCP/MSEK": 2.446738,
    "out:ROI% old": 18.324635,
    "out:Payback discounted": 7,
    "out:Atemp": 3184,
    "out:Umean": 0.55,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 45,
    "out:Energy use kWh/m2": 45,
    "out:Energy savings %": 306.7,
    "out:Op energy cost/MSEK": 16,
    "out:El price/MSEK": 15,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 5,
    "out:El bought/kWh/m2": 2859.720211,
    "out:Return %": 16,
    "out:Return/kSEK/y": 1419,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 1774800,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 88740,
    "out:EAHP (replacement cost)": 1325203.921671023,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 33.36,
    "out:EAHP (El)": 36.939756,
    "out:PV (kr/kWp)": 17461.56255567083,
    "out:PV (kWp installed)": 45.25485,
    "out:PV (m2 panels)": 237.433632,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 42461.284501,
    "out:PV (kWh sold)": 6205.928803,
    "out:PV (% sold (El))": 14.615499,
    "out:PV (kWh self-consumed)": 36255.355698,
    "out:PV (ratio sold/self-consumed)": 0.171173,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 523192.95992,
    "out:EL kWh savings": -88682.396997,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 355137.06,
    "out:DH kr savings": 523192.95991957036,
    "out:El kr savings": -177364.79399457166,
    "out:El kr sold": 9308.893204940432,
    "out:El kr saved": 72510.711396,
    "out:El kr return": 81819.60460094042,
    "out:% solar/total": 91,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 16721.977705,
    "out:% savings (space heating)": 95.892841,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 997124.4110739925,
    "out:Eupp": 2.99,
    "out:Etvv": 0,
    "out:Ef": 37.41,
    "out:heatPumpQuantity": 12,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 8654142.492796494,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 1.79992,
    "out:EL CO2": -4.161534,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -9.511003,
    "out:Bought CO2": 5.349469,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02000002110000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 3.685808,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 51.074552,
    "out:Electricity (inc PV)": 39.762021,
    "out:Total Energy Use Pre PV": 55.074552,
    "out:Total Energy Use Post PV": 43.762021,
    "out:Primary Energy": 73.083521,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 32.796976,
    "out:CO2 Operational/m2": -3.184416,
    "out:Total CO2/m2": 29.61256,
    "out:Total CO2 (tons)": 94.286363,
    "out:Klimatpaverkan": -105.849058,
    "out:Initial Cost/MSEK": 8.733742467796493,
    "out:Running cost/(Apt SEK y)": 6000.085106,
    "out:Running Cost over RSP/MSEK": 2.19335,
    "out:LCP/MSEK": 2.477628,
    "out:ROI% old": 18.33835,
    "out:Payback discounted": 7,
    "out:Atemp": 3184,
    "out:Umean": 0.55,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 44,
    "out:Energy use kWh/m2": 44,
    "out:Energy savings %": 315.9,
    "out:Op energy cost/MSEK": 15,
    "out:El price/MSEK": 14,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 4,
    "out:El bought/kWh/m2": 2812.886392,
    "out:Return %": 16,
    "out:Return/kSEK/y": 1433,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 1774800,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 88740,
    "out:EAHP (replacement cost)": 1325203.921671023,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 33.36,
    "out:EAHP (El)": 36.205052,
    "out:PV (kr/kWp)": 17461.56255567083,
    "out:PV (kWp installed)": 45.25485,
    "out:PV (m2 panels)": 237.433632,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 42461.284501,
    "out:PV (kWh sold)": 6442.195828,
    "out:PV (% sold (El))": 15.171929,
    "out:PV (kWh self-consumed)": 36019.088674,
    "out:PV (ratio sold/self-consumed)": 0.178855,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 528179.328483,
    "out:EL kWh savings": -86579.368814,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 79599.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 364683.88,
    "out:DH kr savings": 528179.3284832743,
    "out:El kr savings": -173158.7376280877,
    "out:El kr sold": 9663.29374139442,
    "out:El kr saved": 72038.177348,
    "out:El kr return": 81701.47108939441,
    "out:% solar/total": 90,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 11735.609141,
    "out:% savings (space heating)": 97.117565,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 997124.4110739925,
    "out:Eupp": 1.94,
    "out:Etvv": 0,
    "out:Ef": 36.62,
    "out:heatPumpQuantity": 12,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 8733742.467796493,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 1.439936,
    "out:EL CO2": -4.624352,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -9.886397,
    "out:Bought CO2": 5.262045,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02000002110001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 4.295816,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 50.582609,
    "out:Electricity (inc PV)": 39.320497,
    "out:Total Energy Use Pre PV": 54.582609,
    "out:Total Energy Use Post PV": 43.320497,
    "out:Primary Energy": 72.799835,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 33.505519,
    "out:CO2 Operational/m2": -3.49892,
    "out:Total CO2/m2": 30.006599,
    "out:Total CO2 (tons)": 95.540982,
    "out:Klimatpaverkan": -104.594439,
    "out:Initial Cost/MSEK": 8.927358492796493,
    "out:Running cost/(Apt SEK y)": 5935.12766,
    "out:Running Cost over RSP/MSEK": 2.16974,
    "out:LCP/MSEK": 2.307622,
    "out:ROI% old": 17.97841,
    "out:Payback discounted": 7,
    "out:Atemp": 3184,
    "out:Umean": 0.55,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 43,
    "out:Energy use kWh/m2": 43,
    "out:Energy savings %": 325.6,
    "out:Op energy cost/MSEK": 15,
    "out:El price/MSEK": 14,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 4,
    "out:El bought/kWh/m2": 2781.599039,
    "out:Return %": 16,
    "out:Return/kSEK/y": 1436,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 1774800,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 88740,
    "out:EAHP (replacement cost)": 1325203.921671023,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 28.356,
    "out:EAHP (El)": 35.713109,
    "out:PV (kr/kWp)": 17461.56255567083,
    "out:PV (kWp installed)": 45.25485,
    "out:PV (m2 panels)": 237.433632,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 42461.284501,
    "out:PV (kWh sold)": 6602.729577,
    "out:PV (% sold (El))": 15.55,
    "out:PV (kWh self-consumed)": 35858.554925,
    "out:PV (ratio sold/self-consumed)": 0.184133,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 526237.063596,
    "out:EL kWh savings": -85173.556821,
    "out:Sewage heat recovery cost": 273216,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 365794.04,
    "out:DH kr savings": 526237.063595662,
    "out:El kr savings": -170347.1136420478,
    "out:El kr sold": 9904.094364932542,
    "out:El kr saved": 71717.10985,
    "out:El kr return": 81621.20421493254,
    "out:% solar/total": 90,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 13677.874029,
    "out:% savings (space heating)": 96.640517,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 997124.4110739925,
    "out:Eupp": 2.6,
    "out:Etvv": 0,
    "out:Ef": 36.71,
    "out:heatPumpQuantity": 12,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 8927358.492796494,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 1.439936,
    "out:EL CO2": -4.938856,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -10.142483,
    "out:Bought CO2": 5.203627,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02000002110001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 2.926861,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 49.788173,
    "out:Electricity (inc PV)": 38.608895,
    "out:Total Energy Use Pre PV": 52.788173,
    "out:Total Energy Use Post PV": 41.608895,
    "out:Primary Energy": 70.797524,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 33.505519,
    "out:CO2 Operational/m2": -4.373561,
    "out:Total CO2/m2": 29.131958,
    "out:Total CO2 (tons)": 92.756126,
    "out:Klimatpaverkan": -107.379295,
    "out:Initial Cost/MSEK": 9.006958467796494,
    "out:Running cost/(Apt SEK y)": 5625.723404,
    "out:Running Cost over RSP/MSEK": 2.05642,
    "out:LCP/MSEK": 2.341342,
    "out:ROI% old": 17.999258,
    "out:Payback discounted": 7,
    "out:Atemp": 3184,
    "out:Umean": 0.55,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 42,
    "out:Energy use kWh/m2": 42,
    "out:Energy savings %": 335.7,
    "out:Op energy cost/MSEK": 14,
    "out:El price/MSEK": 13,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 3,
    "out:El bought/kWh/m2": 2731.19278,
    "out:Return %": 16,
    "out:Return/kSEK/y": 1450,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 1774800,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 88740,
    "out:EAHP (replacement cost)": 1325203.921671023,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 28.356,
    "out:EAHP (El)": 34.918673,
    "out:PV (kr/kWp)": 17461.56255567083,
    "out:PV (kWp installed)": 45.25485,
    "out:PV (m2 panels)": 237.433632,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 42461.284501,
    "out:PV (kWh sold)": 6866.474768,
    "out:PV (% sold (El))": 16.171142,
    "out:PV (kWh self-consumed)": 35594.809733,
    "out:PV (ratio sold/self-consumed)": 0.192907,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 530595.815004,
    "out:EL kWh savings": -82907.816735,
    "out:Sewage heat recovery cost": 273216,
    "out:Balancing hydronic system cost": 79599.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 375079.89,
    "out:DH kr savings": 530595.8150042031,
    "out:El kr savings": -165815.63346947712,
    "out:El kr sold": 10299.712151906322,
    "out:El kr saved": 71189.619466,
    "out:El kr return": 81489.33161790633,
    "out:% solar/total": 89,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 9319.12262,
    "out:% savings (space heating)": 97.711089,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 997124.4110739925,
    "out:Eupp": 1.67,
    "out:Etvv": 0,
    "out:Ef": 35.89,
    "out:heatPumpQuantity": 12,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 9006958.467796493,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 1.079952,
    "out:EL CO2": -5.453513,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -10.562979,
    "out:Bought CO2": 5.109466,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02000002120000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 5.251879,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 51.809256,
    "out:Electricity (inc PV)": 36.521825,
    "out:Total Energy Use Pre PV": 56.809256,
    "out:Total Energy Use Post PV": 41.521825,
    "out:Primary Energy": 68.063794,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 47.536075,
    "out:CO2 Operational/m2": -50.587007,
    "out:Total CO2/m2": -3.050932,
    "out:Total CO2 (tons)": -9.714165,
    "out:Klimatpaverkan": -209.849586,
    "out:Initial Cost/MSEK": 9.444355844517743,
    "out:Running cost/(Apt SEK y)": 4814.425532,
    "out:Running Cost over RSP/MSEK": 1.77168,
    "out:LCP/MSEK": 2.188684,
    "out:ROI% old": 17.59636,
    "out:Payback discounted": 7,
    "out:Atemp": 3184,
    "out:Umean": 0.55,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 42,
    "out:Energy use kWh/m2": 42,
    "out:Energy savings %": 335.7,
    "out:Op energy cost/MSEK": 13,
    "out:El price/MSEK": 11,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 5,
    "out:El bought/kWh/m2": 2581.788044,
    "out:Return %": 16,
    "out:Return/kSEK/y": 1488,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 1774800,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 88740,
    "out:EAHP (replacement cost)": 1325203.921671023,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 33.36,
    "out:EAHP (El)": 36.939756,
    "out:PV (kr/kWp)": 17461.48474631725,
    "out:PV (kWp installed)": 90.5097,
    "out:PV (m2 panels)": 474.867263,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 84922.568824,
    "out:PV (kWh sold)": 36247.401654,
    "out:PV (% sold (El))": 42.682884,
    "out:PV (kWh self-consumed)": 48675.16717,
    "out:PV (ratio sold/self-consumed)": 0.74468,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 523192.95992,
    "out:EL kWh savings": -76262.587854,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 425038.89,
    "out:DH kr savings": 523192.95991957036,
    "out:El kr savings": -152525.1757083033,
    "out:El kr sold": 54371.10248057436,
    "out:El kr saved": 97350.33434,
    "out:El kr return": 151721.43682057437,
    "out:% solar/total": 122,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 16721.977705,
    "out:% savings (space heating)": 95.892841,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 997124.4110739925,
    "out:Eupp": 2.99,
    "out:Etvv": 0,
    "out:Ef": 33.82,
    "out:heatPumpQuantity": 12,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 9444355.844517743,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 1.79992,
    "out:EL CO2": -52.386927,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -57.219478,
    "out:Bought CO2": 4.832551,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02000002120000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 3.685808,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 51.074552,
    "out:Electricity (inc PV)": 35.930123,
    "out:Total Energy Use Pre PV": 55.074552,
    "out:Total Energy Use Post PV": 39.930123,
    "out:Primary Energy": 66.186104,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 47.536075,
    "out:CO2 Operational/m2": -51.759092,
    "out:Total CO2/m2": -4.223017,
    "out:Total CO2 (tons)": -13.446082,
    "out:Klimatpaverkan": -213.581503,
    "out:Initial Cost/MSEK": 9.523955819517745,
    "out:Running cost/(Apt SEK y)": 4515.148936,
    "out:Running Cost over RSP/MSEK": 1.66204,
    "out:LCP/MSEK": 2.218724,
    "out:ROI% old": 17.613749,
    "out:Payback discounted": 7,
    "out:Atemp": 3184,
    "out:Umean": 0.55,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 40,
    "out:Energy use kWh/m2": 40,
    "out:Energy savings %": 357.5,
    "out:Op energy cost/MSEK": 12,
    "out:El price/MSEK": 11,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 4,
    "out:El bought/kWh/m2": 2539.967149,
    "out:Return %": 16,
    "out:Return/kSEK/y": 1502,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 1774800,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 88740,
    "out:EAHP (replacement cost)": 1325203.921671023,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 33.36,
    "out:EAHP (El)": 36.205052,
    "out:PV (kr/kWp)": 17461.48474631725,
    "out:PV (kWp installed)": 90.5097,
    "out:PV (m2 panels)": 474.867263,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 84922.568824,
    "out:PV (kWh sold)": 36702.720722,
    "out:PV (% sold (El))": 43.219042,
    "out:PV (kWh self-consumed)": 48219.848102,
    "out:PV (ratio sold/self-consumed)": 0.761154,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 528179.328483,
    "out:EL kWh savings": -74378.609253,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 79599.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 434476.19,
    "out:DH kr savings": 528179.3284832743,
    "out:El kr savings": -148757.21850600428,
    "out:El kr sold": 55054.08108283492,
    "out:El kr saved": 96439.696204,
    "out:El kr return": 151493.77728683493,
    "out:% solar/total": 120,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 11735.609141,
    "out:% savings (space heating)": 97.117565,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 997124.4110739925,
    "out:Eupp": 1.94,
    "out:Etvv": 0,
    "out:Ef": 33.11,
    "out:heatPumpQuantity": 12,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 9523955.819517745,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 1.439936,
    "out:EL CO2": -53.199028,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -57.953279,
    "out:Bought CO2": 4.754251,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02000002120001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 4.295816,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 50.582609,
    "out:Electricity (inc PV)": 35.534625,
    "out:Total Energy Use Pre PV": 54.582609,
    "out:Total Energy Use Post PV": 39.534625,
    "out:Primary Energy": 65.985265,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 48.244618,
    "out:CO2 Operational/m2": -52.306486,
    "out:Total CO2/m2": -4.061868,
    "out:Total CO2 (tons)": -12.932984,
    "out:Klimatpaverkan": -213.068405,
    "out:Initial Cost/MSEK": 9.717571844517742,
    "out:Running cost/(Apt SEK y)": 4451.744681,
    "out:Running Cost over RSP/MSEK": 1.639,
    "out:LCP/MSEK": 2.048148,
    "out:ROI% old": 17.296678,
    "out:Payback discounted": 7,
    "out:Atemp": 3184,
    "out:Umean": 0.55,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 40,
    "out:Energy use kWh/m2": 40,
    "out:Energy savings %": 357.5,
    "out:Op energy cost/MSEK": 12,
    "out:El price/MSEK": 11,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 4,
    "out:El bought/kWh/m2": 2512.016371,
    "out:Return %": 15,
    "out:Return/kSEK/y": 1505,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 1774800,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 88740,
    "out:EAHP (replacement cost)": 1325203.921671023,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 28.356,
    "out:EAHP (El)": 35.713109,
    "out:PV (kr/kWp)": 17461.48474631725,
    "out:PV (kWp installed)": 90.5097,
    "out:PV (m2 panels)": 474.867263,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 84922.568824,
    "out:PV (kWh sold)": 37009.801133,
    "out:PV (% sold (El))": 43.580643,
    "out:PV (kWh self-consumed)": 47912.767691,
    "out:PV (ratio sold/self-consumed)": 0.772441,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 526237.063596,
    "out:EL kWh savings": -73119.344,
    "out:Sewage heat recovery cost": 273216,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 435513.08,
    "out:DH kr savings": 526237.063595662,
    "out:El kr savings": -146238.68799977849,
    "out:El kr sold": 55514.70169923303,
    "out:El kr saved": 95825.535382,
    "out:El kr return": 151340.23708123303,
    "out:% solar/total": 120,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 13677.874029,
    "out:% savings (space heating)": 96.640517,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 997124.4110739925,
    "out:Eupp": 2.6,
    "out:Etvv": 0,
    "out:Ef": 33.19,
    "out:heatPumpQuantity": 12,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 9717571.844517743,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 1.439936,
    "out:EL CO2": -53.746422,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -58.44834,
    "out:Bought CO2": 4.701918,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02000002120001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 2.926861,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 49.788173,
    "out:Electricity (inc PV)": 34.896877,
    "out:Total Energy Use Pre PV": 52.788173,
    "out:Total Energy Use Post PV": 37.896877,
    "out:Primary Energy": 64.115892,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 48.244618,
    "out:CO2 Operational/m2": -53.556147,
    "out:Total CO2/m2": -5.311529,
    "out:Total CO2 (tons)": -16.911903,
    "out:Klimatpaverkan": -217.047324,
    "out:Initial Cost/MSEK": 9.797171819517745,
    "out:Running cost/(Apt SEK y)": 4144.829787,
    "out:Running Cost over RSP/MSEK": 1.52658,
    "out:LCP/MSEK": 2.080968,
    "out:ROI% old": 17.320071,
    "out:Payback discounted": 7,
    "out:Atemp": 3184,
    "out:Umean": 0.55,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 38,
    "out:Energy use kWh/m2": 38,
    "out:Energy savings %": 381.6,
    "out:Op energy cost/MSEK": 11,
    "out:El price/MSEK": 10,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 3,
    "out:El bought/kWh/m2": 2466.962528,
    "out:Return %": 16,
    "out:Return/kSEK/y": 1520,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 1774800,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 88740,
    "out:EAHP (replacement cost)": 1325203.921671023,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 28.356,
    "out:EAHP (El)": 34.918673,
    "out:PV (kr/kWp)": 17461.48474631725,
    "out:PV (kWp installed)": 90.5097,
    "out:PV (m2 panels)": 474.867263,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 84922.568824,
    "out:PV (kWh sold)": 37508.696797,
    "out:PV (% sold (El))": 44.168114,
    "out:PV (kWh self-consumed)": 47413.872026,
    "out:PV (ratio sold/self-consumed)": 0.791091,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 530595.815004,
    "out:EL kWh savings": -71088.754979,
    "out:Sewage heat recovery cost": 273216,
    "out:Balancing hydronic system cost": 79599.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 444681.35,
    "out:DH kr savings": 530595.8150042031,
    "out:El kr savings": -142177.50995770367,
    "out:El kr sold": 56263.04519623241,
    "out:El kr saved": 94827.744052,
    "out:El kr return": 151090.7892482324,
    "out:% solar/total": 118,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 9319.12262,
    "out:% savings (space heating)": 97.711089,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 997124.4110739925,
    "out:Eupp": 1.67,
    "out:Etvv": 0,
    "out:Ef": 32.45,
    "out:heatPumpQuantity": 12,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 9797171.819517745,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 1.079952,
    "out:EL CO2": -54.636099,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -59.253653,
    "out:Bought CO2": 4.617554,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02001000000000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 75.882932,
    "out:DHW (DH)": 41.7,
    "out:Property Electricity": 12.57,
    "out:Electricity (inc PV)": 12.57,
    "out:Total Energy Use Pre PV": 130.57,
    "out:Total Energy Use Post PV": 130.57,
    "out:Primary Energy": 99.146058,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 15.179711,
    "out:CO2 Operational/m2": 44.137453,
    "out:Total CO2/m2": 59.317164,
    "out:Total CO2 (tons)": 188.865793,
    "out:Klimatpaverkan": -11.269628,
    "out:Initial Cost/MSEK": 3.4217118530037496,
    "out:Running cost/(Apt SEK y)": 25844.595745,
    "out:Running Cost over RSP/MSEK": 9.49374,
    "out:LCP/MSEK": 0.489268,
    "out:ROI% old": 16.328418,
    "out:Payback discounted": 7,
    "out:Atemp": 3184,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 131,
    "out:Energy use kWh/m2": 131,
    "out:Energy savings %": 39.7,
    "out:Op energy cost/MSEK": 69,
    "out:El price/MSEK": 43,
    "out:Heating price/MSEK": 23,
    "out:DH+ DHW/kWh/m2": 118,
    "out:El bought/kWh/m2": 879.9,
    "out:Return %": 15,
    "out:Return/kSEK/y": 500,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 41.7,
    "out:EAHP (El)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 165530.995418,
    "out:EL kWh savings": 2.2624e-11,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 165531,
    "out:DH kr savings": 165530.99541751892,
    "out:El kr savings": 4.524734779920436e-11,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 241611.182155,
    "out:% savings (space heating)": 40.656804,
    "out:Total_Water Heating (DH)": 132772.760051,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 75.88,
    "out:Etvv": 25,
    "out:Ef": 12.57,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 3421711.8530037496,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 42.478112,
    "out:EL CO2": 1.659341,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 1.659341,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02001000000000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 72.706435,
    "out:DHW (DH)": 41.7,
    "out:Property Electricity": 12.57,
    "out:Electricity (inc PV)": 12.57,
    "out:Total Energy Use Pre PV": 126.57,
    "out:Total Energy Use Post PV": 126.57,
    "out:Primary Energy": 96.675449,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 15.179711,
    "out:CO2 Operational/m2": 42.697517,
    "out:Total CO2/m2": 57.877228,
    "out:Total CO2 (tons)": 184.281039,
    "out:Klimatpaverkan": -15.854383,
    "out:Initial Cost/MSEK": 3.50131182800375,
    "out:Running cost/(Apt SEK y)": 25026.234043,
    "out:Running Cost over RSP/MSEK": 9.1929,
    "out:LCP/MSEK": 0.710508,
    "out:ROI% old": 17.184661,
    "out:Payback discounted": 7,
    "out:Atemp": 3184,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 127,
    "out:Energy use kWh/m2": 127,
    "out:Energy savings %": 44.1,
    "out:Op energy cost/MSEK": 67,
    "out:El price/MSEK": 41,
    "out:Heating price/MSEK": 22,
    "out:DH+ DHW/kWh/m2": 114,
    "out:El bought/kWh/m2": 879.9,
    "out:Return %": 15,
    "out:Return/kSEK/y": 538,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 41.7,
    "out:EAHP (El)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 175644.958504,
    "out:EL kWh savings": 2.2624e-11,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 79599.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 175644.96,
    "out:DH kr savings": 175644.9585040349,
    "out:El kr savings": 4.524734779920436e-11,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 231497.219069,
    "out:% savings (space heating)": 43.140939,
    "out:Total_Water Heating (DH)": 132772.760051,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 72.71,
    "out:Etvv": 25,
    "out:Ef": 12.57,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 3501311.8280037497,
    "out:Seasonal Variation ROI (%)": 5,
    "out:Seasonal Variation Payback": 20,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 41.038176,
    "out:EL CO2": 1.659341,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 1.659341,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02001000000001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 75.882932,
    "out:DHW (DH)": 35.445,
    "out:Property Electricity": 12.57,
    "out:Electricity (inc PV)": 12.57,
    "out:Total Energy Use Pre PV": 123.57,
    "out:Total Energy Use Post PV": 123.57,
    "out:Primary Energy": 96.521058,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 15.888254,
    "out:CO2 Operational/m2": 41.617565,
    "out:Total CO2/m2": 57.505819,
    "out:Total CO2 (tons)": 183.098473,
    "out:Klimatpaverkan": -17.036948,
    "out:Initial Cost/MSEK": 3.6949278530037497,
    "out:Running cost/(Apt SEK y)": 24412.468085,
    "out:Running Cost over RSP/MSEK": 8.96726,
    "out:LCP/MSEK": 0.742532,
    "out:ROI% old": 17.156569,
    "out:Payback discounted": 7,
    "out:Atemp": 3184,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 124,
    "out:Energy use kWh/m2": 124,
    "out:Energy savings %": 47.6,
    "out:Op energy cost/MSEK": 65,
    "out:El price/MSEK": 40,
    "out:Heating price/MSEK": 22,
    "out:DH+ DHW/kWh/m2": 111,
    "out:El bought/kWh/m2": 879.9,
    "out:Return %": 15,
    "out:Return/kSEK/y": 567,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 35.445,
    "out:EAHP (El)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 185446.909425,
    "out:EL kWh savings": 2.2624e-11,
    "out:Sewage heat recovery cost": 273216,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 185446.91,
    "out:DH kr savings": 185446.9094252289,
    "out:El kr savings": 4.524734779920436e-11,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 241611.182155,
    "out:% savings (space heating)": 40.656804,
    "out:Total_Water Heating (DH)": 112856.846044,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 75.88,
    "out:Etvv": 21.25,
    "out:Ef": 12.57,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 3694927.8530037496,
    "out:Seasonal Variation ROI (%)": 5,
    "out:Seasonal Variation Payback": 20,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 39.958224,
    "out:EL CO2": 1.659341,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 1.659341,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02001000000001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 72.706435,
    "out:DHW (DH)": 35.445,
    "out:Property Electricity": 12.57,
    "out:Electricity (inc PV)": 12.57,
    "out:Total Energy Use Pre PV": 120.57,
    "out:Total Energy Use Post PV": 120.57,
    "out:Primary Energy": 94.050449,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 15.888254,
    "out:CO2 Operational/m2": 40.537613,
    "out:Total CO2/m2": 56.425867,
    "out:Total CO2 (tons)": 179.659906,
    "out:Klimatpaverkan": -20.475515,
    "out:Initial Cost/MSEK": 3.7745278280037495,
    "out:Running cost/(Apt SEK y)": 23798.702128,
    "out:Running Cost over RSP/MSEK": 8.74163,
    "out:LCP/MSEK": 0.888562,
    "out:ROI% old": 17.648716,
    "out:Payback discounted": 7,
    "out:Atemp": 3184,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 121,
    "out:Energy use kWh/m2": 121,
    "out:Energy savings %": 51.2,
    "out:Op energy cost/MSEK": 64,
    "out:El price/MSEK": 39,
    "out:Heating price/MSEK": 21,
    "out:DH+ DHW/kWh/m2": 108,
    "out:El bought/kWh/m2": 879.9,
    "out:Return %": 16,
    "out:Return/kSEK/y": 596,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 35.445,
    "out:EAHP (El)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 195560.872512,
    "out:EL kWh savings": 2.2624e-11,
    "out:Sewage heat recovery cost": 273216,
    "out:Balancing hydronic system cost": 79599.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 195560.87,
    "out:DH kr savings": 195560.87251174494,
    "out:El kr savings": 4.524734779920436e-11,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 231497.219069,
    "out:% savings (space heating)": 43.140939,
    "out:Total_Water Heating (DH)": 112856.846044,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 72.71,
    "out:Etvv": 21.25,
    "out:Ef": 12.57,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 3774527.8280037497,
    "out:Seasonal Variation ROI (%)": 5,
    "out:Seasonal Variation Payback": 20,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 38.878272,
    "out:EL CO2": 1.659341,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 1.659341,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02001000000100000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 1,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 75.882932,
    "out:DHW (DH)": 41.7,
    "out:Property Electricity": 9.976115,
    "out:Electricity (inc PV)": 9.976115,
    "out:Total Energy Use Pre PV": 127.976115,
    "out:Total Energy Use Post PV": 127.976115,
    "out:Primary Energy": 94.477065,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 15.364213,
    "out:CO2 Operational/m2": 43.795039,
    "out:Total CO2/m2": 59.159252,
    "out:Total CO2 (tons)": 188.363002,
    "out:Klimatpaverkan": -11.772419,
    "out:Initial Cost/MSEK": 3.5685047245235744,
    "out:Running cost/(Apt SEK y)": 25493.148936,
    "out:Running Cost over RSP/MSEK": 9.36604,
    "out:LCP/MSEK": 0.470175,
    "out:ROI% old": 16.167956,
    "out:Payback discounted": 8,
    "out:Atemp": 3184,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 128,
    "out:Energy use kWh/m2": 128,
    "out:Energy savings %": 43,
    "out:Op energy cost/MSEK": 68,
    "out:El price/MSEK": 42,
    "out:Heating price/MSEK": 23,
    "out:DH+ DHW/kWh/m2": 118,
    "out:El bought/kWh/m2": 698.32805,
    "out:Return %": 14,
    "out:Return/kSEK/y": 517,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 41.7,
    "out:EAHP (El)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 165530.995418,
    "out:EL kWh savings": 8258.927355,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 182048.85,
    "out:DH kr savings": 165530.99541751892,
    "out:El kr savings": 16517.854710116386,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 241611.182155,
    "out:% savings (space heating)": 40.656804,
    "out:Total_Water Heating (DH)": 132772.760051,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 75.88,
    "out:Etvv": 25,
    "out:Ef": 9.98,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 3568504.7245235746,
    "out:Seasonal Variation ROI (%)": 5,
    "out:Seasonal Variation Payback": 20,
    "out:New AHU Cost/SEK": 146792.87151982498,
    "out:DH CO2": 42.478112,
    "out:EL CO2": 1.316927,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 1.316927,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02001000000100100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 1,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 72.706435,
    "out:DHW (DH)": 41.7,
    "out:Property Electricity": 9.976115,
    "out:Electricity (inc PV)": 9.976115,
    "out:Total Energy Use Pre PV": 123.976115,
    "out:Total Energy Use Post PV": 123.976115,
    "out:Primary Energy": 92.006456,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 15.364213,
    "out:CO2 Operational/m2": 42.355103,
    "out:Total CO2/m2": 57.719316,
    "out:Total CO2 (tons)": 183.778247,
    "out:Klimatpaverkan": -16.357174,
    "out:Initial Cost/MSEK": 3.6481046995235746,
    "out:Running cost/(Apt SEK y)": 24674.787234,
    "out:Running Cost over RSP/MSEK": 9.0652,
    "out:LCP/MSEK": 0.691415,
    "out:ROI% old": 16.993247,
    "out:Payback discounted": 7,
    "out:Atemp": 3184,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 124,
    "out:Energy use kWh/m2": 124,
    "out:Energy savings %": 47.6,
    "out:Op energy cost/MSEK": 66,
    "out:El price/MSEK": 40,
    "out:Heating price/MSEK": 22,
    "out:DH+ DHW/kWh/m2": 114,
    "out:El bought/kWh/m2": 698.32805,
    "out:Return %": 15,
    "out:Return/kSEK/y": 555,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 41.7,
    "out:EAHP (El)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 175644.958504,
    "out:EL kWh savings": 8258.927355,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 79599.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 192162.81,
    "out:DH kr savings": 175644.9585040349,
    "out:El kr savings": 16517.854710116386,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 231497.219069,
    "out:% savings (space heating)": 43.140939,
    "out:Total_Water Heating (DH)": 132772.760051,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 72.71,
    "out:Etvv": 25,
    "out:Ef": 9.98,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 3648104.6995235747,
    "out:Seasonal Variation ROI (%)": 5,
    "out:Seasonal Variation Payback": 20,
    "out:New AHU Cost/SEK": 146792.87151982498,
    "out:DH CO2": 41.038176,
    "out:EL CO2": 1.316927,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 1.316927,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02001000000101000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 1,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 75.882932,
    "out:DHW (DH)": 35.445,
    "out:Property Electricity": 9.976115,
    "out:Electricity (inc PV)": 9.976115,
    "out:Total Energy Use Pre PV": 120.976115,
    "out:Total Energy Use Post PV": 120.976115,
    "out:Primary Energy": 91.852065,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 16.072756,
    "out:CO2 Operational/m2": 41.275151,
    "out:Total CO2/m2": 57.347907,
    "out:Total CO2 (tons)": 182.595681,
    "out:Klimatpaverkan": -17.53974,
    "out:Initial Cost/MSEK": 3.8417207245235745,
    "out:Running cost/(Apt SEK y)": 24061.021277,
    "out:Running Cost over RSP/MSEK": 8.83957,
    "out:LCP/MSEK": 0.723429,
    "out:ROI% old": 16.975838,
    "out:Payback discounted": 7,
    "out:Atemp": 3184,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 121,
    "out:Energy use kWh/m2": 121,
    "out:Energy savings %": 51.2,
    "out:Op energy cost/MSEK": 65,
    "out:El price/MSEK": 39,
    "out:Heating price/MSEK": 22,
    "out:DH+ DHW/kWh/m2": 111,
    "out:El bought/kWh/m2": 698.32805,
    "out:Return %": 15,
    "out:Return/kSEK/y": 584,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 35.445,
    "out:EAHP (El)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 185446.909425,
    "out:EL kWh savings": 8258.927355,
    "out:Sewage heat recovery cost": 273216,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 201964.76,
    "out:DH kr savings": 185446.9094252289,
    "out:El kr savings": 16517.854710116386,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 241611.182155,
    "out:% savings (space heating)": 40.656804,
    "out:Total_Water Heating (DH)": 112856.846044,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 75.88,
    "out:Etvv": 21.25,
    "out:Ef": 9.98,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 3841720.7245235746,
    "out:Seasonal Variation ROI (%)": 5,
    "out:Seasonal Variation Payback": 20,
    "out:New AHU Cost/SEK": 146792.87151982498,
    "out:DH CO2": 39.958224,
    "out:EL CO2": 1.316927,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 1.316927,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02001000000101100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 1,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 72.706435,
    "out:DHW (DH)": 35.445,
    "out:Property Electricity": 9.976115,
    "out:Electricity (inc PV)": 9.976115,
    "out:Total Energy Use Pre PV": 117.976115,
    "out:Total Energy Use Post PV": 117.976115,
    "out:Primary Energy": 89.381456,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 16.072756,
    "out:CO2 Operational/m2": 40.195199,
    "out:Total CO2/m2": 56.267955,
    "out:Total CO2 (tons)": 179.157115,
    "out:Klimatpaverkan": -20.978306,
    "out:Initial Cost/MSEK": 3.9213206995235748,
    "out:Running cost/(Apt SEK y)": 23447.255319,
    "out:Running Cost over RSP/MSEK": 8.61393,
    "out:LCP/MSEK": 0.869469,
    "out:ROI% old": 17.453267,
    "out:Payback discounted": 7,
    "out:Atemp": 3184,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 118,
    "out:Energy use kWh/m2": 118,
    "out:Energy savings %": 55.1,
    "out:Op energy cost/MSEK": 63,
    "out:El price/MSEK": 38,
    "out:Heating price/MSEK": 21,
    "out:DH+ DHW/kWh/m2": 108,
    "out:El bought/kWh/m2": 698.32805,
    "out:Return %": 16,
    "out:Return/kSEK/y": 613,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 35.445,
    "out:EAHP (El)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 195560.872512,
    "out:EL kWh savings": 8258.927355,
    "out:Sewage heat recovery cost": 273216,
    "out:Balancing hydronic system cost": 79599.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 212078.73,
    "out:DH kr savings": 195560.87251174494,
    "out:El kr savings": 16517.854710116386,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 231497.219069,
    "out:% savings (space heating)": 43.140939,
    "out:Total_Water Heating (DH)": 112856.846044,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 72.71,
    "out:Etvv": 21.25,
    "out:Ef": 9.98,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 3921320.6995235747,
    "out:Seasonal Variation ROI (%)": 5,
    "out:Seasonal Variation Payback": 20,
    "out:New AHU Cost/SEK": 146792.87151982498,
    "out:DH CO2": 38.878272,
    "out:EL CO2": 1.316927,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 1.316927,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02001000010000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 75.882932,
    "out:DHW (DH)": 41.7,
    "out:Property Electricity": 12.57,
    "out:Electricity (inc PV)": 8.051517,
    "out:Total Energy Use Pre PV": 130.57,
    "out:Total Energy Use Post PV": 126.051517,
    "out:Primary Energy": 91.012789,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 29.918811,
    "out:CO2 Operational/m2": -1.123209,
    "out:Total CO2/m2": 28.795602,
    "out:Total CO2 (tons)": 91.685169,
    "out:Klimatpaverkan": -108.450252,
    "out:Initial Cost/MSEK": 4.211932247226249,
    "out:Running cost/(Apt SEK y)": 24336.382979,
    "out:Running Cost over RSP/MSEK": 8.95437,
    "out:LCP/MSEK": 0.238418,
    "out:ROI% old": 15.094362,
    "out:Payback discounted": 8,
    "out:Atemp": 3184,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 126,
    "out:Energy use kWh/m2": 126,
    "out:Energy savings %": 45.2,
    "out:Op energy cost/MSEK": 66,
    "out:El price/MSEK": 39,
    "out:Heating price/MSEK": 23,
    "out:DH+ DHW/kWh/m2": 118,
    "out:El bought/kWh/m2": 568.258452,
    "out:Return %": 14,
    "out:Return/kSEK/y": 571,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 41.7,
    "out:EAHP (El)": 0,
    "out:PV (kr/kWp)": 17461.56255567083,
    "out:PV (kWp installed)": 45.25485,
    "out:PV (m2 panels)": 237.433632,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 42461.284501,
    "out:PV (kWh sold)": 28074.439514,
    "out:PV (% sold (El))": 66.117735,
    "out:PV (kWh self-consumed)": 14386.844987,
    "out:PV (ratio sold/self-consumed)": 1.951397,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 165530.995418,
    "out:EL kWh savings": 14386.845543,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 236416.35,
    "out:DH kr savings": 165530.99541751892,
    "out:El kr savings": 28773.691086586456,
    "out:El kr sold": 42111.65927092506,
    "out:El kr saved": 28773.689974,
    "out:El kr return": 70885.34924492506,
    "out:% solar/total": 36,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 241611.182155,
    "out:% savings (space heating)": 40.656804,
    "out:Total_Water Heating (DH)": 132772.760051,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 75.88,
    "out:Etvv": 25,
    "out:Ef": 8.05,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 4211932.247226249,
    "out:Seasonal Variation ROI (%)": 5,
    "out:Seasonal Variation Payback": 20,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 42.478112,
    "out:EL CO2": -43.601321,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -44.666212,
    "out:Bought CO2": 1.064891,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02001000010000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 72.706435,
    "out:DHW (DH)": 41.7,
    "out:Property Electricity": 12.57,
    "out:Electricity (inc PV)": 8.051517,
    "out:Total Energy Use Pre PV": 126.57,
    "out:Total Energy Use Post PV": 122.051517,
    "out:Primary Energy": 88.54218,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 29.918811,
    "out:CO2 Operational/m2": -2.563145,
    "out:Total CO2/m2": 27.355666,
    "out:Total CO2 (tons)": 87.100414,
    "out:Klimatpaverkan": -113.035007,
    "out:Initial Cost/MSEK": 4.29153222222625,
    "out:Running cost/(Apt SEK y)": 23518.042553,
    "out:Running Cost over RSP/MSEK": 8.65353,
    "out:LCP/MSEK": 0.459658,
    "out:ROI% old": 15.81583,
    "out:Payback discounted": 8,
    "out:Atemp": 3184,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 122,
    "out:Energy use kWh/m2": 122,
    "out:Energy savings %": 50,
    "out:Op energy cost/MSEK": 64,
    "out:El price/MSEK": 38,
    "out:Heating price/MSEK": 22,
    "out:DH+ DHW/kWh/m2": 114,
    "out:El bought/kWh/m2": 568.258452,
    "out:Return %": 14,
    "out:Return/kSEK/y": 609,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 41.7,
    "out:EAHP (El)": 0,
    "out:PV (kr/kWp)": 17461.56255567083,
    "out:PV (kWp installed)": 45.25485,
    "out:PV (m2 panels)": 237.433632,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 42461.284501,
    "out:PV (kWh sold)": 28074.439514,
    "out:PV (% sold (El))": 66.117735,
    "out:PV (kWh self-consumed)": 14386.844987,
    "out:PV (ratio sold/self-consumed)": 1.951397,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 175644.958504,
    "out:EL kWh savings": 14386.845543,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 79599.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 246530.31,
    "out:DH kr savings": 175644.9585040349,
    "out:El kr savings": 28773.691086586456,
    "out:El kr sold": 42111.65927092506,
    "out:El kr saved": 28773.689974,
    "out:El kr return": 70885.34924492506,
    "out:% solar/total": 36,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 231497.219069,
    "out:% savings (space heating)": 43.140939,
    "out:Total_Water Heating (DH)": 132772.760051,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 72.71,
    "out:Etvv": 25,
    "out:Ef": 8.05,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 4291532.22222625,
    "out:Seasonal Variation ROI (%)": 5,
    "out:Seasonal Variation Payback": 20,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 41.038176,
    "out:EL CO2": -43.601321,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -44.666212,
    "out:Bought CO2": 1.064891,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02001000010001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 75.882932,
    "out:DHW (DH)": 35.445,
    "out:Property Electricity": 12.57,
    "out:Electricity (inc PV)": 8.051517,
    "out:Total Energy Use Pre PV": 123.57,
    "out:Total Energy Use Post PV": 119.051517,
    "out:Primary Energy": 88.387789,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 30.627354,
    "out:CO2 Operational/m2": -3.643097,
    "out:Total CO2/m2": 26.984257,
    "out:Total CO2 (tons)": 85.917848,
    "out:Klimatpaverkan": -114.217573,
    "out:Initial Cost/MSEK": 4.48514824722625,
    "out:Running cost/(Apt SEK y)": 22904.276596,
    "out:Running Cost over RSP/MSEK": 8.42789,
    "out:LCP/MSEK": 0.491682,
    "out:ROI% old": 15.851777,
    "out:Payback discounted": 8,
    "out:Atemp": 3184,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 119,
    "out:Energy use kWh/m2": 119,
    "out:Energy savings %": 53.8,
    "out:Op energy cost/MSEK": 62,
    "out:El price/MSEK": 37,
    "out:Heating price/MSEK": 22,
    "out:DH+ DHW/kWh/m2": 111,
    "out:El bought/kWh/m2": 568.258452,
    "out:Return %": 14,
    "out:Return/kSEK/y": 638,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 35.445,
    "out:EAHP (El)": 0,
    "out:PV (kr/kWp)": 17461.56255567083,
    "out:PV (kWp installed)": 45.25485,
    "out:PV (m2 panels)": 237.433632,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 42461.284501,
    "out:PV (kWh sold)": 28074.439514,
    "out:PV (% sold (El))": 66.117735,
    "out:PV (kWh self-consumed)": 14386.844987,
    "out:PV (ratio sold/self-consumed)": 1.951397,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 185446.909425,
    "out:EL kWh savings": 14386.845543,
    "out:Sewage heat recovery cost": 273216,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 256332.26,
    "out:DH kr savings": 185446.9094252289,
    "out:El kr savings": 28773.691086586456,
    "out:El kr sold": 42111.65927092506,
    "out:El kr saved": 28773.689974,
    "out:El kr return": 70885.34924492506,
    "out:% solar/total": 36,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 241611.182155,
    "out:% savings (space heating)": 40.656804,
    "out:Total_Water Heating (DH)": 112856.846044,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 75.88,
    "out:Etvv": 21.25,
    "out:Ef": 8.05,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 4485148.247226249,
    "out:Seasonal Variation ROI (%)": 5,
    "out:Seasonal Variation Payback": 20,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 39.958224,
    "out:EL CO2": -43.601321,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -44.666212,
    "out:Bought CO2": 1.064891,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02001000010001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 72.706435,
    "out:DHW (DH)": 35.445,
    "out:Property Electricity": 12.57,
    "out:Electricity (inc PV)": 8.051517,
    "out:Total Energy Use Pre PV": 120.57,
    "out:Total Energy Use Post PV": 116.051517,
    "out:Primary Energy": 85.91718,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 30.627354,
    "out:CO2 Operational/m2": -4.723049,
    "out:Total CO2/m2": 25.904305,
    "out:Total CO2 (tons)": 82.479282,
    "out:Klimatpaverkan": -117.656139,
    "out:Initial Cost/MSEK": 4.56474822222625,
    "out:Running cost/(Apt SEK y)": 22290.510638,
    "out:Running Cost over RSP/MSEK": 8.20226,
    "out:LCP/MSEK": 0.637712,
    "out:ROI% old": 16.28148,
    "out:Payback discounted": 7,
    "out:Atemp": 3184,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 116,
    "out:Energy use kWh/m2": 116,
    "out:Energy savings %": 57.8,
    "out:Op energy cost/MSEK": 60,
    "out:El price/MSEK": 36,
    "out:Heating price/MSEK": 21,
    "out:DH+ DHW/kWh/m2": 108,
    "out:El bought/kWh/m2": 568.258452,
    "out:Return %": 15,
    "out:Return/kSEK/y": 667,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 35.445,
    "out:EAHP (El)": 0,
    "out:PV (kr/kWp)": 17461.56255567083,
    "out:PV (kWp installed)": 45.25485,
    "out:PV (m2 panels)": 237.433632,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 42461.284501,
    "out:PV (kWh sold)": 28074.439514,
    "out:PV (% sold (El))": 66.117735,
    "out:PV (kWh self-consumed)": 14386.844987,
    "out:PV (ratio sold/self-consumed)": 1.951397,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 195560.872512,
    "out:EL kWh savings": 14386.845543,
    "out:Sewage heat recovery cost": 273216,
    "out:Balancing hydronic system cost": 79599.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 266446.22,
    "out:DH kr savings": 195560.87251174494,
    "out:El kr savings": 28773.691086586456,
    "out:El kr sold": 42111.65927092506,
    "out:El kr saved": 28773.689974,
    "out:El kr return": 70885.34924492506,
    "out:% solar/total": 36,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 231497.219069,
    "out:% savings (space heating)": 43.140939,
    "out:Total_Water Heating (DH)": 112856.846044,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 72.71,
    "out:Etvv": 21.25,
    "out:Ef": 8.05,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 4564748.22222625,
    "out:Seasonal Variation ROI (%)": 5,
    "out:Seasonal Variation Payback": 20,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 38.878272,
    "out:EL CO2": -43.601321,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -44.666212,
    "out:Bought CO2": 1.064891,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02001000010100000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 1,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 75.882932,
    "out:DHW (DH)": 41.7,
    "out:Property Electricity": 9.976115,
    "out:Electricity (inc PV)": 6.231337,
    "out:Total Energy Use Pre PV": 127.976115,
    "out:Total Energy Use Post PV": 124.231337,
    "out:Primary Energy": 87.736465,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 30.103313,
    "out:CO2 Operational/m2": -5.353337,
    "out:Total CO2/m2": 24.749976,
    "out:Total CO2 (tons)": 78.8039,
    "out:Klimatpaverkan": -121.331521,
    "out:Initial Cost/MSEK": 4.358725118746075,
    "out:Running cost/(Apt SEK y)": 24011.148936,
    "out:Running Cost over RSP/MSEK": 8.83614,
    "out:LCP/MSEK": 0.209855,
    "out:ROI% old": 14.973513,
    "out:Payback discounted": 8,
    "out:Atemp": 3184,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 124,
    "out:Energy use kWh/m2": 124,
    "out:Energy savings %": 47.6,
    "out:Op energy cost/MSEK": 65,
    "out:El price/MSEK": 38,
    "out:Heating price/MSEK": 23,
    "out:DH+ DHW/kWh/m2": 118,
    "out:El bought/kWh/m2": 439.497378,
    "out:Return %": 13,
    "out:Return/kSEK/y": 586,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 41.7,
    "out:EAHP (El)": 0,
    "out:PV (kr/kWp)": 17461.56255567083,
    "out:PV (kWp installed)": 45.25485,
    "out:PV (m2 panels)": 237.433632,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 42461.284501,
    "out:PV (kWh sold)": 30537.914073,
    "out:PV (% sold (El))": 71.919431,
    "out:PV (kWh self-consumed)": 11923.370428,
    "out:PV (ratio sold/self-consumed)": 2.561181,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 165530.995418,
    "out:EL kWh savings": 20182.29692,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 251702.46,
    "out:DH kr savings": 165530.99541751892,
    "out:El kr savings": 40364.59383912171,
    "out:El kr sold": 45806.87111012268,
    "out:El kr saved": 23846.740856,
    "out:El kr return": 69653.61196612268,
    "out:% solar/total": 30,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 241611.182155,
    "out:% savings (space heating)": 40.656804,
    "out:Total_Water Heating (DH)": 132772.760051,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 75.88,
    "out:Etvv": 25,
    "out:Ef": 6.23,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 4358725.118746075,
    "out:Seasonal Variation ROI (%)": 5,
    "out:Seasonal Variation Payback": 20,
    "out:New AHU Cost/SEK": 146792.87151982498,
    "out:DH CO2": 42.478112,
    "out:EL CO2": -47.831449,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -48.655512,
    "out:Bought CO2": 0.824063,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02001000010100100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 1,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 72.706435,
    "out:DHW (DH)": 41.7,
    "out:Property Electricity": 9.976115,
    "out:Electricity (inc PV)": 6.231337,
    "out:Total Energy Use Pre PV": 123.976115,
    "out:Total Energy Use Post PV": 120.231337,
    "out:Primary Energy": 85.265856,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 30.103313,
    "out:CO2 Operational/m2": -6.793273,
    "out:Total CO2/m2": 23.31004,
    "out:Total CO2 (tons)": 74.219145,
    "out:Klimatpaverkan": -125.916276,
    "out:Initial Cost/MSEK": 4.438325093746075,
    "out:Running cost/(Apt SEK y)": 23192.787234,
    "out:Running Cost over RSP/MSEK": 8.5353,
    "out:LCP/MSEK": 0.431095,
    "out:ROI% old": 15.673287,
    "out:Payback discounted": 8,
    "out:Atemp": 3184,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 120,
    "out:Energy use kWh/m2": 120,
    "out:Energy savings %": 52.5,
    "out:Op energy cost/MSEK": 63,
    "out:El price/MSEK": 37,
    "out:Heating price/MSEK": 22,
    "out:DH+ DHW/kWh/m2": 114,
    "out:El bought/kWh/m2": 439.497378,
    "out:Return %": 14,
    "out:Return/kSEK/y": 625,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 41.7,
    "out:EAHP (El)": 0,
    "out:PV (kr/kWp)": 17461.56255567083,
    "out:PV (kWp installed)": 45.25485,
    "out:PV (m2 panels)": 237.433632,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 42461.284501,
    "out:PV (kWh sold)": 30537.914073,
    "out:PV (% sold (El))": 71.919431,
    "out:PV (kWh self-consumed)": 11923.370428,
    "out:PV (ratio sold/self-consumed)": 2.561181,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 175644.958504,
    "out:EL kWh savings": 20182.29692,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 79599.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 261816.42,
    "out:DH kr savings": 175644.9585040349,
    "out:El kr savings": 40364.59383912171,
    "out:El kr sold": 45806.87111012268,
    "out:El kr saved": 23846.740856,
    "out:El kr return": 69653.61196612268,
    "out:% solar/total": 30,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 231497.219069,
    "out:% savings (space heating)": 43.140939,
    "out:Total_Water Heating (DH)": 132772.760051,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 72.71,
    "out:Etvv": 25,
    "out:Ef": 6.23,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 4438325.0937460745,
    "out:Seasonal Variation ROI (%)": 6,
    "out:Seasonal Variation Payback": 16.666667,
    "out:New AHU Cost/SEK": 146792.87151982498,
    "out:DH CO2": 41.038176,
    "out:EL CO2": -47.831449,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -48.655512,
    "out:Bought CO2": 0.824063,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02001000010101000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 1,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 75.882932,
    "out:DHW (DH)": 35.445,
    "out:Property Electricity": 9.976115,
    "out:Electricity (inc PV)": 6.231337,
    "out:Total Energy Use Pre PV": 120.976115,
    "out:Total Energy Use Post PV": 117.231337,
    "out:Primary Energy": 85.111465,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 30.811856,
    "out:CO2 Operational/m2": -7.873225,
    "out:Total CO2/m2": 22.938631,
    "out:Total CO2 (tons)": 73.036579,
    "out:Klimatpaverkan": -127.098842,
    "out:Initial Cost/MSEK": 4.631941118746075,
    "out:Running cost/(Apt SEK y)": 22579.021277,
    "out:Running Cost over RSP/MSEK": 8.30966,
    "out:LCP/MSEK": 0.463119,
    "out:ROI% old": 15.714054,
    "out:Payback discounted": 8,
    "out:Atemp": 3184,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 117,
    "out:Energy use kWh/m2": 117,
    "out:Energy savings %": 56.4,
    "out:Op energy cost/MSEK": 61,
    "out:El price/MSEK": 36,
    "out:Heating price/MSEK": 22,
    "out:DH+ DHW/kWh/m2": 111,
    "out:El bought/kWh/m2": 439.497378,
    "out:Return %": 14,
    "out:Return/kSEK/y": 653,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 35.445,
    "out:EAHP (El)": 0,
    "out:PV (kr/kWp)": 17461.56255567083,
    "out:PV (kWp installed)": 45.25485,
    "out:PV (m2 panels)": 237.433632,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 42461.284501,
    "out:PV (kWh sold)": 30537.914073,
    "out:PV (% sold (El))": 71.919431,
    "out:PV (kWh self-consumed)": 11923.370428,
    "out:PV (ratio sold/self-consumed)": 2.561181,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 185446.909425,
    "out:EL kWh savings": 20182.29692,
    "out:Sewage heat recovery cost": 273216,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 271618.37,
    "out:DH kr savings": 185446.9094252289,
    "out:El kr savings": 40364.59383912171,
    "out:El kr sold": 45806.87111012268,
    "out:El kr saved": 23846.740856,
    "out:El kr return": 69653.61196612268,
    "out:% solar/total": 30,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 241611.182155,
    "out:% savings (space heating)": 40.656804,
    "out:Total_Water Heating (DH)": 112856.846044,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 75.88,
    "out:Etvv": 21.25,
    "out:Ef": 6.23,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 4631941.118746075,
    "out:Seasonal Variation ROI (%)": 6,
    "out:Seasonal Variation Payback": 16.666667,
    "out:New AHU Cost/SEK": 146792.87151982498,
    "out:DH CO2": 39.958224,
    "out:EL CO2": -47.831449,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -48.655512,
    "out:Bought CO2": 0.824063,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02001000010101100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 1,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 72.706435,
    "out:DHW (DH)": 35.445,
    "out:Property Electricity": 9.976115,
    "out:Electricity (inc PV)": 6.231337,
    "out:Total Energy Use Pre PV": 117.976115,
    "out:Total Energy Use Post PV": 114.231337,
    "out:Primary Energy": 82.640856,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 30.811856,
    "out:CO2 Operational/m2": -8.953177,
    "out:Total CO2/m2": 21.858679,
    "out:Total CO2 (tons)": 69.598013,
    "out:Klimatpaverkan": -130.537408,
    "out:Initial Cost/MSEK": 4.711541093746074,
    "out:Running cost/(Apt SEK y)": 21965.255319,
    "out:Running Cost over RSP/MSEK": 8.08403,
    "out:LCP/MSEK": 0.609149,
    "out:ROI% old": 16.132695,
    "out:Payback discounted": 8,
    "out:Atemp": 3184,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 114,
    "out:Energy use kWh/m2": 114,
    "out:Energy savings %": 60.5,
    "out:Op energy cost/MSEK": 60,
    "out:El price/MSEK": 35,
    "out:Heating price/MSEK": 21,
    "out:DH+ DHW/kWh/m2": 108,
    "out:El bought/kWh/m2": 439.497378,
    "out:Return %": 14,
    "out:Return/kSEK/y": 682,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 35.445,
    "out:EAHP (El)": 0,
    "out:PV (kr/kWp)": 17461.56255567083,
    "out:PV (kWp installed)": 45.25485,
    "out:PV (m2 panels)": 237.433632,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 42461.284501,
    "out:PV (kWh sold)": 30537.914073,
    "out:PV (% sold (El))": 71.919431,
    "out:PV (kWh self-consumed)": 11923.370428,
    "out:PV (ratio sold/self-consumed)": 2.561181,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 195560.872512,
    "out:EL kWh savings": 20182.29692,
    "out:Sewage heat recovery cost": 273216,
    "out:Balancing hydronic system cost": 79599.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 281732.34,
    "out:DH kr savings": 195560.87251174494,
    "out:El kr savings": 40364.59383912171,
    "out:El kr sold": 45806.87111012268,
    "out:El kr saved": 23846.740856,
    "out:El kr return": 69653.61196612268,
    "out:% solar/total": 30,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 231497.219069,
    "out:% savings (space heating)": 43.140939,
    "out:Total_Water Heating (DH)": 112856.846044,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 72.71,
    "out:Etvv": 21.25,
    "out:Ef": 6.23,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 4711541.0937460745,
    "out:Seasonal Variation ROI (%)": 6,
    "out:Seasonal Variation Payback": 16.666667,
    "out:New AHU Cost/SEK": 146792.87151982498,
    "out:DH CO2": 38.878272,
    "out:EL CO2": -47.831449,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -48.655512,
    "out:Bought CO2": 0.824063,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02001000020000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 75.882932,
    "out:DHW (DH)": 41.7,
    "out:Property Electricity": 12.57,
    "out:Electricity (inc PV)": 7.530921,
    "out:Total Energy Use Pre PV": 130.57,
    "out:Total Energy Use Post PV": 125.530921,
    "out:Primary Energy": 90.075716,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 44.65791,
    "out:CO2 Operational/m2": -66.478526,
    "out:Total CO2/m2": -21.820616,
    "out:Total CO2 (tons)": -69.47682,
    "out:Klimatpaverkan": -269.612241,
    "out:Initial Cost/MSEK": 5.0021455989475,
    "out:Running cost/(Apt SEK y)": 22963.617021,
    "out:Running Cost over RSP/MSEK": 8.46394,
    "out:LCP/MSEK": -0.061366,
    "out:ROI% old": 14.110459,
    "out:Payback discounted": 9,
    "out:Atemp": 3184,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 126,
    "out:Energy use kWh/m2": 126,
    "out:Energy savings %": 45.2,
    "out:Op energy cost/MSEK": 63,
    "out:El price/MSEK": 36,
    "out:Heating price/MSEK": 23,
    "out:DH+ DHW/kWh/m2": 118,
    "out:El bought/kWh/m2": 530.25352,
    "out:Return %": 13,
    "out:Return/kSEK/y": 635,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 41.7,
    "out:EAHP (El)": 0,
    "out:PV (kr/kWp)": 17461.48474631725,
    "out:PV (kWp installed)": 90.5097,
    "out:PV (m2 panels)": 474.867263,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 84922.568824,
    "out:PV (kWh sold)": 68878.145463,
    "out:PV (% sold (El))": 81.106997,
    "out:PV (kWh self-consumed)": 16044.423361,
    "out:PV (ratio sold/self-consumed)": 4.292965,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 165530.995418,
    "out:EL kWh savings": 16044.422709,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 300937.06,
    "out:DH kr savings": 165530.99541751892,
    "out:El kr savings": 32088.845417124598,
    "out:El kr sold": 103317.21819422109,
    "out:El kr saved": 32088.846722,
    "out:El kr return": 135406.06491622108,
    "out:% solar/total": 40,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 241611.182155,
    "out:% savings (space heating)": 40.656804,
    "out:Total_Water Heating (DH)": 132772.760051,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 75.88,
    "out:Etvv": 25,
    "out:Ef": 7.53,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 5002145.5989475,
    "out:Seasonal Variation ROI (%)": 6,
    "out:Seasonal Variation Payback": 16.666667,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 42.478112,
    "out:EL CO2": -108.956638,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -109.952146,
    "out:Bought CO2": 0.995508,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02001000020000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 72.706435,
    "out:DHW (DH)": 41.7,
    "out:Property Electricity": 12.57,
    "out:Electricity (inc PV)": 7.530921,
    "out:Total Energy Use Pre PV": 126.57,
    "out:Total Energy Use Post PV": 121.530921,
    "out:Primary Energy": 87.605107,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 44.65791,
    "out:CO2 Operational/m2": -67.918462,
    "out:Total CO2/m2": -23.260552,
    "out:Total CO2 (tons)": -74.061575,
    "out:Klimatpaverkan": -274.196996,
    "out:Initial Cost/MSEK": 5.081745573947501,
    "out:Running cost/(Apt SEK y)": 22145.255319,
    "out:Running Cost over RSP/MSEK": 8.16309,
    "out:LCP/MSEK": 0.159884,
    "out:ROI% old": 14.735179,
    "out:Payback discounted": 8,
    "out:Atemp": 3184,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 122,
    "out:Energy use kWh/m2": 122,
    "out:Energy savings %": 50,
    "out:Op energy cost/MSEK": 61,
    "out:El price/MSEK": 35,
    "out:Heating price/MSEK": 22,
    "out:DH+ DHW/kWh/m2": 114,
    "out:El bought/kWh/m2": 530.25352,
    "out:Return %": 13,
    "out:Return/kSEK/y": 674,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 41.7,
    "out:EAHP (El)": 0,
    "out:PV (kr/kWp)": 17461.48474631725,
    "out:PV (kWp installed)": 90.5097,
    "out:PV (m2 panels)": 474.867263,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 84922.568824,
    "out:PV (kWh sold)": 68878.145463,
    "out:PV (% sold (El))": 81.106997,
    "out:PV (kWh self-consumed)": 16044.423361,
    "out:PV (ratio sold/self-consumed)": 4.292965,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 175644.958504,
    "out:EL kWh savings": 16044.422709,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 79599.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 311051.02,
    "out:DH kr savings": 175644.9585040349,
    "out:El kr savings": 32088.845417124598,
    "out:El kr sold": 103317.21819422109,
    "out:El kr saved": 32088.846722,
    "out:El kr return": 135406.06491622108,
    "out:% solar/total": 40,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 231497.219069,
    "out:% savings (space heating)": 43.140939,
    "out:Total_Water Heating (DH)": 132772.760051,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 72.71,
    "out:Etvv": 25,
    "out:Ef": 7.53,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 5081745.5739475,
    "out:Seasonal Variation ROI (%)": 6,
    "out:Seasonal Variation Payback": 16.666667,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 41.038176,
    "out:EL CO2": -108.956638,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -109.952146,
    "out:Bought CO2": 0.995508,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02001000020001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 75.882932,
    "out:DHW (DH)": 35.445,
    "out:Property Electricity": 12.57,
    "out:Electricity (inc PV)": 7.530921,
    "out:Total Energy Use Pre PV": 123.57,
    "out:Total Energy Use Post PV": 118.530921,
    "out:Primary Energy": 87.450716,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 45.366453,
    "out:CO2 Operational/m2": -68.998414,
    "out:Total CO2/m2": -23.631961,
    "out:Total CO2 (tons)": -75.244141,
    "out:Klimatpaverkan": -275.379562,
    "out:Initial Cost/MSEK": 5.2753615989475,
    "out:Running cost/(Apt SEK y)": 21531.489362,
    "out:Running Cost over RSP/MSEK": 7.93746,
    "out:LCP/MSEK": 0.191898,
    "out:ROI% old": 14.805376,
    "out:Payback discounted": 8,
    "out:Atemp": 3184,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 119,
    "out:Energy use kWh/m2": 119,
    "out:Energy savings %": 53.8,
    "out:Op energy cost/MSEK": 59,
    "out:El price/MSEK": 34,
    "out:Heating price/MSEK": 22,
    "out:DH+ DHW/kWh/m2": 111,
    "out:El bought/kWh/m2": 530.25352,
    "out:Return %": 13,
    "out:Return/kSEK/y": 703,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 35.445,
    "out:EAHP (El)": 0,
    "out:PV (kr/kWp)": 17461.48474631725,
    "out:PV (kWp installed)": 90.5097,
    "out:PV (m2 panels)": 474.867263,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 84922.568824,
    "out:PV (kWh sold)": 68878.145463,
    "out:PV (% sold (El))": 81.106997,
    "out:PV (kWh self-consumed)": 16044.423361,
    "out:PV (ratio sold/self-consumed)": 4.292965,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 185446.909425,
    "out:EL kWh savings": 16044.422709,
    "out:Sewage heat recovery cost": 273216,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 320852.97,
    "out:DH kr savings": 185446.9094252289,
    "out:El kr savings": 32088.845417124598,
    "out:El kr sold": 103317.21819422109,
    "out:El kr saved": 32088.846722,
    "out:El kr return": 135406.06491622108,
    "out:% solar/total": 40,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 241611.182155,
    "out:% savings (space heating)": 40.656804,
    "out:Total_Water Heating (DH)": 112856.846044,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 75.88,
    "out:Etvv": 21.25,
    "out:Ef": 7.53,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 5275361.5989475,
    "out:Seasonal Variation ROI (%)": 6,
    "out:Seasonal Variation Payback": 16.666667,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 39.958224,
    "out:EL CO2": -108.956638,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -109.952146,
    "out:Bought CO2": 0.995508,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02001000020001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 72.706435,
    "out:DHW (DH)": 35.445,
    "out:Property Electricity": 12.57,
    "out:Electricity (inc PV)": 7.530921,
    "out:Total Energy Use Pre PV": 120.57,
    "out:Total Energy Use Post PV": 115.530921,
    "out:Primary Energy": 84.980107,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 45.366453,
    "out:CO2 Operational/m2": -70.078366,
    "out:Total CO2/m2": -24.711913,
    "out:Total CO2 (tons)": -78.682707,
    "out:Klimatpaverkan": -278.818128,
    "out:Initial Cost/MSEK": 5.3549615739475005,
    "out:Running cost/(Apt SEK y)": 20917.723404,
    "out:Running Cost over RSP/MSEK": 7.71183,
    "out:LCP/MSEK": 0.337928,
    "out:ROI% old": 15.187224,
    "out:Payback discounted": 8,
    "out:Atemp": 3184,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 116,
    "out:Energy use kWh/m2": 116,
    "out:Energy savings %": 57.8,
    "out:Op energy cost/MSEK": 58,
    "out:El price/MSEK": 33,
    "out:Heating price/MSEK": 21,
    "out:DH+ DHW/kWh/m2": 108,
    "out:El bought/kWh/m2": 530.25352,
    "out:Return %": 14,
    "out:Return/kSEK/y": 732,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 35.445,
    "out:EAHP (El)": 0,
    "out:PV (kr/kWp)": 17461.48474631725,
    "out:PV (kWp installed)": 90.5097,
    "out:PV (m2 panels)": 474.867263,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 84922.568824,
    "out:PV (kWh sold)": 68878.145463,
    "out:PV (% sold (El))": 81.106997,
    "out:PV (kWh self-consumed)": 16044.423361,
    "out:PV (ratio sold/self-consumed)": 4.292965,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 195560.872512,
    "out:EL kWh savings": 16044.422709,
    "out:Sewage heat recovery cost": 273216,
    "out:Balancing hydronic system cost": 79599.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 330966.94,
    "out:DH kr savings": 195560.87251174494,
    "out:El kr savings": 32088.845417124598,
    "out:El kr sold": 103317.21819422109,
    "out:El kr saved": 32088.846722,
    "out:El kr return": 135406.06491622108,
    "out:% solar/total": 40,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 231497.219069,
    "out:% savings (space heating)": 43.140939,
    "out:Total_Water Heating (DH)": 112856.846044,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 72.71,
    "out:Etvv": 21.25,
    "out:Ef": 7.53,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 5354961.5739475,
    "out:Seasonal Variation ROI (%)": 6,
    "out:Seasonal Variation Payback": 16.666667,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 38.878272,
    "out:EL CO2": -108.956638,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -109.952146,
    "out:Bought CO2": 0.995508,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02001000020100000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 1,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 75.882932,
    "out:DHW (DH)": 41.7,
    "out:Property Electricity": 9.976115,
    "out:Electricity (inc PV)": 5.879188,
    "out:Total Energy Use Pre PV": 127.976115,
    "out:Total Energy Use Post PV": 123.879188,
    "out:Primary Energy": 87.102596,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 44.842412,
    "out:CO2 Operational/m2": -71.564252,
    "out:Total CO2/m2": -26.72184,
    "out:Total CO2 (tons)": -85.082313,
    "out:Klimatpaverkan": -285.217734,
    "out:Initial Cost/MSEK": 5.148938470467325,
    "out:Running cost/(Apt SEK y)": 22644.085106,
    "out:Running Cost over RSP/MSEK": 8.34779,
    "out:LCP/MSEK": -0.092008,
    "out:ROI% old": 14.030437,
    "out:Payback discounted": 9,
    "out:Atemp": 3184,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 124,
    "out:Energy use kWh/m2": 124,
    "out:Energy savings %": 47.6,
    "out:Op energy cost/MSEK": 62,
    "out:El price/MSEK": 35,
    "out:Heating price/MSEK": 23,
    "out:DH+ DHW/kWh/m2": 118,
    "out:El bought/kWh/m2": 413.65837,
    "out:Return %": 13,
    "out:Return/kSEK/y": 650,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 41.7,
    "out:EAHP (El)": 0,
    "out:PV (kr/kWp)": 17461.48474631725,
    "out:PV (kWp installed)": 90.5097,
    "out:PV (m2 panels)": 474.867263,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 84922.568824,
    "out:PV (kWh sold)": 71877.957046,
    "out:PV (% sold (El))": 84.639405,
    "out:PV (kWh self-consumed)": 13044.611778,
    "out:PV (ratio sold/self-consumed)": 5.510165,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 165530.995418,
    "out:EL kWh savings": 21303.538998,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 315955.01,
    "out:DH kr savings": 165530.99541751892,
    "out:El kr savings": 42607.077996404216,
    "out:El kr sold": 107816.93556907076,
    "out:El kr saved": 26089.223556,
    "out:El kr return": 133906.15912507076,
    "out:% solar/total": 33,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 241611.182155,
    "out:% savings (space heating)": 40.656804,
    "out:Total_Water Heating (DH)": 132772.760051,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 75.88,
    "out:Etvv": 25,
    "out:Ef": 5.88,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 5148938.470467325,
    "out:Seasonal Variation ROI (%)": 6,
    "out:Seasonal Variation Payback": 16.666667,
    "out:New AHU Cost/SEK": 146792.87151982498,
    "out:DH CO2": 42.478112,
    "out:EL CO2": -114.042364,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -114.819396,
    "out:Bought CO2": 0.777032,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02001000020100100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 1,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 72.706435,
    "out:DHW (DH)": 41.7,
    "out:Property Electricity": 9.976115,
    "out:Electricity (inc PV)": 5.879188,
    "out:Total Energy Use Pre PV": 123.976115,
    "out:Total Energy Use Post PV": 119.879188,
    "out:Primary Energy": 84.631987,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 44.842412,
    "out:CO2 Operational/m2": -73.004188,
    "out:Total CO2/m2": -28.161776,
    "out:Total CO2 (tons)": -89.667068,
    "out:Klimatpaverkan": -289.802489,
    "out:Initial Cost/MSEK": 5.228538445467325,
    "out:Running cost/(Apt SEK y)": 21825.723404,
    "out:Running Cost over RSP/MSEK": 8.04695,
    "out:LCP/MSEK": 0.129232,
    "out:ROI% old": 14.638808,
    "out:Payback discounted": 8,
    "out:Atemp": 3184,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 120,
    "out:Energy use kWh/m2": 120,
    "out:Energy savings %": 52.5,
    "out:Op energy cost/MSEK": 60,
    "out:El price/MSEK": 34,
    "out:Heating price/MSEK": 22,
    "out:DH+ DHW/kWh/m2": 114,
    "out:El bought/kWh/m2": 413.65837,
    "out:Return %": 13,
    "out:Return/kSEK/y": 689,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 41.7,
    "out:EAHP (El)": 0,
    "out:PV (kr/kWp)": 17461.48474631725,
    "out:PV (kWp installed)": 90.5097,
    "out:PV (m2 panels)": 474.867263,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 84922.568824,
    "out:PV (kWh sold)": 71877.957046,
    "out:PV (% sold (El))": 84.639405,
    "out:PV (kWh self-consumed)": 13044.611778,
    "out:PV (ratio sold/self-consumed)": 5.510165,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 175644.958504,
    "out:EL kWh savings": 21303.538998,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 79599.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 326068.97,
    "out:DH kr savings": 175644.9585040349,
    "out:El kr savings": 42607.077996404216,
    "out:El kr sold": 107816.93556907076,
    "out:El kr saved": 26089.223556,
    "out:El kr return": 133906.15912507076,
    "out:% solar/total": 33,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 231497.219069,
    "out:% savings (space heating)": 43.140939,
    "out:Total_Water Heating (DH)": 132772.760051,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 72.71,
    "out:Etvv": 25,
    "out:Ef": 5.88,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 5228538.445467325,
    "out:Seasonal Variation ROI (%)": 6,
    "out:Seasonal Variation Payback": 16.666667,
    "out:New AHU Cost/SEK": 146792.87151982498,
    "out:DH CO2": 41.038176,
    "out:EL CO2": -114.042364,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -114.819396,
    "out:Bought CO2": 0.777032,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02001000020101000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 1,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 75.882932,
    "out:DHW (DH)": 35.445,
    "out:Property Electricity": 9.976115,
    "out:Electricity (inc PV)": 5.879188,
    "out:Total Energy Use Pre PV": 120.976115,
    "out:Total Energy Use Post PV": 116.879188,
    "out:Primary Energy": 84.477596,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 45.550955,
    "out:CO2 Operational/m2": -74.08414,
    "out:Total CO2/m2": -28.533185,
    "out:Total CO2 (tons)": -90.849634,
    "out:Klimatpaverkan": -290.985055,
    "out:Initial Cost/MSEK": 5.4221544704673255,
    "out:Running cost/(Apt SEK y)": 21211.957447,
    "out:Running Cost over RSP/MSEK": 7.82132,
    "out:LCP/MSEK": 0.161246,
    "out:ROI% old": 14.710547,
    "out:Payback discounted": 8,
    "out:Atemp": 3184,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 117,
    "out:Energy use kWh/m2": 117,
    "out:Energy savings %": 56.4,
    "out:Op energy cost/MSEK": 58,
    "out:El price/MSEK": 33,
    "out:Heating price/MSEK": 22,
    "out:DH+ DHW/kWh/m2": 111,
    "out:El bought/kWh/m2": 413.65837,
    "out:Return %": 13,
    "out:Return/kSEK/y": 718,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 35.445,
    "out:EAHP (El)": 0,
    "out:PV (kr/kWp)": 17461.48474631725,
    "out:PV (kWp installed)": 90.5097,
    "out:PV (m2 panels)": 474.867263,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 84922.568824,
    "out:PV (kWh sold)": 71877.957046,
    "out:PV (% sold (El))": 84.639405,
    "out:PV (kWh self-consumed)": 13044.611778,
    "out:PV (ratio sold/self-consumed)": 5.510165,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 185446.909425,
    "out:EL kWh savings": 21303.538998,
    "out:Sewage heat recovery cost": 273216,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 335870.92,
    "out:DH kr savings": 185446.9094252289,
    "out:El kr savings": 42607.077996404216,
    "out:El kr sold": 107816.93556907076,
    "out:El kr saved": 26089.223556,
    "out:El kr return": 133906.15912507076,
    "out:% solar/total": 33,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 241611.182155,
    "out:% savings (space heating)": 40.656804,
    "out:Total_Water Heating (DH)": 112856.846044,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 75.88,
    "out:Etvv": 21.25,
    "out:Ef": 5.88,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 5422154.470467325,
    "out:Seasonal Variation ROI (%)": 6,
    "out:Seasonal Variation Payback": 16.666667,
    "out:New AHU Cost/SEK": 146792.87151982498,
    "out:DH CO2": 39.958224,
    "out:EL CO2": -114.042364,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -114.819396,
    "out:Bought CO2": 0.777032,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02001000020101100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 1,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 72.706435,
    "out:DHW (DH)": 35.445,
    "out:Property Electricity": 9.976115,
    "out:Electricity (inc PV)": 5.879188,
    "out:Total Energy Use Pre PV": 117.976115,
    "out:Total Energy Use Post PV": 113.879188,
    "out:Primary Energy": 82.006987,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 45.550955,
    "out:CO2 Operational/m2": -75.164092,
    "out:Total CO2/m2": -29.613137,
    "out:Total CO2 (tons)": -94.2882,
    "out:Klimatpaverkan": -294.423621,
    "out:Initial Cost/MSEK": 5.501754445467326,
    "out:Running cost/(Apt SEK y)": 20598.191489,
    "out:Running Cost over RSP/MSEK": 7.59568,
    "out:LCP/MSEK": 0.307286,
    "out:ROI% old": 15.083604,
    "out:Payback discounted": 8,
    "out:Atemp": 3184,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 114,
    "out:Energy use kWh/m2": 114,
    "out:Energy savings %": 60.5,
    "out:Op energy cost/MSEK": 57,
    "out:El price/MSEK": 32,
    "out:Heating price/MSEK": 21,
    "out:DH+ DHW/kWh/m2": 108,
    "out:El bought/kWh/m2": 413.65837,
    "out:Return %": 14,
    "out:Return/kSEK/y": 747,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 35.445,
    "out:EAHP (El)": 0,
    "out:PV (kr/kWp)": 17461.48474631725,
    "out:PV (kWp installed)": 90.5097,
    "out:PV (m2 panels)": 474.867263,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 84922.568824,
    "out:PV (kWh sold)": 71877.957046,
    "out:PV (% sold (El))": 84.639405,
    "out:PV (kWh self-consumed)": 13044.611778,
    "out:PV (ratio sold/self-consumed)": 5.510165,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 195560.872512,
    "out:EL kWh savings": 21303.538998,
    "out:Sewage heat recovery cost": 273216,
    "out:Balancing hydronic system cost": 79599.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 345984.89,
    "out:DH kr savings": 195560.87251174494,
    "out:El kr savings": 42607.077996404216,
    "out:El kr sold": 107816.93556907076,
    "out:El kr saved": 26089.223556,
    "out:El kr return": 133906.15912507076,
    "out:% solar/total": 33,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 231497.219069,
    "out:% savings (space heating)": 43.140939,
    "out:Total_Water Heating (DH)": 112856.846044,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 72.71,
    "out:Etvv": 21.25,
    "out:Ef": 5.88,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 5501754.445467326,
    "out:Seasonal Variation ROI (%)": 6,
    "out:Seasonal Variation Payback": 16.666667,
    "out:New AHU Cost/SEK": 146792.87151982498,
    "out:DH CO2": 38.878272,
    "out:EL CO2": -114.042364,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -114.819396,
    "out:Bought CO2": 0.777032,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02001000100000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 75.882932,
    "out:DHW (DH)": 33.36,
    "out:Property Electricity": 12.57,
    "out:Electricity (inc PV)": 12.57,
    "out:Total Energy Use Pre PV": 121.57,
    "out:Total Energy Use Post PV": 121.57,
    "out:Primary Energy": 95.646058,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 15.179711,
    "out:CO2 Operational/m2": 40.897597,
    "out:Total CO2/m2": 56.077308,
    "out:Total CO2 (tons)": 178.550095,
    "out:Klimatpaverkan": -21.585326,
    "out:Initial Cost/MSEK": 3.5457595405037496,
    "out:Running cost/(Apt SEK y)": 24003.297872,
    "out:Running Cost over RSP/MSEK": 8.81684,
    "out:LCP/MSEK": 1.04212,
    "out:ROI% old": 18.484373,
    "out:Payback discounted": 7,
    "out:Atemp": 3184,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 122,
    "out:Energy use kWh/m2": 122,
    "out:Energy savings %": 50,
    "out:Op energy cost/MSEK": 64,
    "out:El price/MSEK": 40,
    "out:Heating price/MSEK": 21,
    "out:DH+ DHW/kWh/m2": 109,
    "out:El bought/kWh/m2": 879.9,
    "out:Return %": 17,
    "out:Return/kSEK/y": 587,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 33.36,
    "out:EAHP (El)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 192085.547428,
    "out:EL kWh savings": 2.2624e-11,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 192085.55,
    "out:DH kr savings": 192085.5474277989,
    "out:El kr savings": 4.524734779920436e-11,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 241611.182155,
    "out:% savings (space heating)": 40.656804,
    "out:Total_Water Heating (DH)": 106218.208041,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 75.88,
    "out:Etvv": 20,
    "out:Ef": 12.57,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 3545759.5405037496,
    "out:Seasonal Variation ROI (%)": 5,
    "out:Seasonal Variation Payback": 20,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 39.238256,
    "out:EL CO2": 1.659341,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 1.659341,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02001000100000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 72.706435,
    "out:DHW (DH)": 33.36,
    "out:Property Electricity": 12.57,
    "out:Electricity (inc PV)": 12.57,
    "out:Total Energy Use Pre PV": 118.57,
    "out:Total Energy Use Post PV": 118.57,
    "out:Primary Energy": 93.175449,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 15.179711,
    "out:CO2 Operational/m2": 39.817645,
    "out:Total CO2/m2": 54.997356,
    "out:Total CO2 (tons)": 175.111529,
    "out:Klimatpaverkan": -25.023892,
    "out:Initial Cost/MSEK": 3.62535951550375,
    "out:Running cost/(Apt SEK y)": 23389.531915,
    "out:Running Cost over RSP/MSEK": 8.59121,
    "out:LCP/MSEK": 1.18815,
    "out:ROI% old": 18.967616,
    "out:Payback discounted": 6,
    "out:Atemp": 3184,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 119,
    "out:Energy use kWh/m2": 119,
    "out:Energy savings %": 53.8,
    "out:Op energy cost/MSEK": 63,
    "out:El price/MSEK": 39,
    "out:Heating price/MSEK": 21,
    "out:DH+ DHW/kWh/m2": 106,
    "out:El bought/kWh/m2": 879.9,
    "out:Return %": 17,
    "out:Return/kSEK/y": 615,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 33.36,
    "out:EAHP (El)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 202199.510514,
    "out:EL kWh savings": 2.2624e-11,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 79599.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 202199.51,
    "out:DH kr savings": 202199.5105143149,
    "out:El kr savings": 4.524734779920436e-11,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 231497.219069,
    "out:% savings (space heating)": 43.140939,
    "out:Total_Water Heating (DH)": 106218.208041,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 72.71,
    "out:Etvv": 20,
    "out:Ef": 12.57,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 3625359.5155037497,
    "out:Seasonal Variation ROI (%)": 5,
    "out:Seasonal Variation Payback": 20,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 38.158304,
    "out:EL CO2": 1.659341,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 1.659341,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02001000100001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 75.882932,
    "out:DHW (DH)": 28.356,
    "out:Property Electricity": 12.57,
    "out:Electricity (inc PV)": 12.57,
    "out:Total Energy Use Pre PV": 116.57,
    "out:Total Energy Use Post PV": 116.57,
    "out:Primary Energy": 93.546058,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 15.888254,
    "out:CO2 Operational/m2": 39.097677,
    "out:Total CO2/m2": 54.985931,
    "out:Total CO2 (tons)": 175.075152,
    "out:Klimatpaverkan": -25.060269,
    "out:Initial Cost/MSEK": 3.8189755405037498,
    "out:Running cost/(Apt SEK y)": 22980.340426,
    "out:Running Cost over RSP/MSEK": 8.44078,
    "out:LCP/MSEK": 1.144964,
    "out:ROI% old": 18.568705,
    "out:Payback discounted": 7,
    "out:Atemp": 3184,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 117,
    "out:Energy use kWh/m2": 117,
    "out:Energy savings %": 56.4,
    "out:Op energy cost/MSEK": 62,
    "out:El price/MSEK": 38,
    "out:Heating price/MSEK": 20,
    "out:DH+ DHW/kWh/m2": 104,
    "out:El bought/kWh/m2": 879.9,
    "out:Return %": 17,
    "out:Return/kSEK/y": 635,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 28.356,
    "out:EAHP (El)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 208018.278634,
    "out:EL kWh savings": 2.2624e-11,
    "out:Sewage heat recovery cost": 273216,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 208018.28,
    "out:DH kr savings": 208018.2786339669,
    "out:El kr savings": 4.524734779920436e-11,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 241611.182155,
    "out:% savings (space heating)": 40.656804,
    "out:Total_Water Heating (DH)": 90285.476835,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 75.88,
    "out:Etvv": 17,
    "out:Ef": 12.57,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 3818975.5405037496,
    "out:Seasonal Variation ROI (%)": 5,
    "out:Seasonal Variation Payback": 20,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 37.438336,
    "out:EL CO2": 1.659341,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 1.659341,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02001000100001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 72.706435,
    "out:DHW (DH)": 28.356,
    "out:Property Electricity": 12.57,
    "out:Electricity (inc PV)": 12.57,
    "out:Total Energy Use Pre PV": 113.57,
    "out:Total Energy Use Post PV": 113.57,
    "out:Primary Energy": 91.075449,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 15.888254,
    "out:CO2 Operational/m2": 38.017725,
    "out:Total CO2/m2": 53.905979,
    "out:Total CO2 (tons)": 171.636585,
    "out:Klimatpaverkan": -28.498836,
    "out:Initial Cost/MSEK": 3.898575515503749,
    "out:Running cost/(Apt SEK y)": 22366.574468,
    "out:Running Cost over RSP/MSEK": 8.21515,
    "out:LCP/MSEK": 1.290994,
    "out:ROI% old": 19.01636,
    "out:Payback discounted": 6,
    "out:Atemp": 3184,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 114,
    "out:Energy use kWh/m2": 114,
    "out:Energy savings %": 60.5,
    "out:Op energy cost/MSEK": 60,
    "out:El price/MSEK": 37,
    "out:Heating price/MSEK": 20,
    "out:DH+ DHW/kWh/m2": 101,
    "out:El bought/kWh/m2": 879.9,
    "out:Return %": 17,
    "out:Return/kSEK/y": 663,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 28.356,
    "out:EAHP (El)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 218132.24172,
    "out:EL kWh savings": 2.2624e-11,
    "out:Sewage heat recovery cost": 273216,
    "out:Balancing hydronic system cost": 79599.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 218132.24,
    "out:DH kr savings": 218132.2417204829,
    "out:El kr savings": 4.524734779920436e-11,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 231497.219069,
    "out:% savings (space heating)": 43.140939,
    "out:Total_Water Heating (DH)": 90285.476835,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 72.71,
    "out:Etvv": 17,
    "out:Ef": 12.57,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 3898575.5155037493,
    "out:Seasonal Variation ROI (%)": 5,
    "out:Seasonal Variation Payback": 20,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 36.358384,
    "out:EL CO2": 1.659341,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 1.659341,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02001000100100000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 1,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 75.882932,
    "out:DHW (DH)": 33.36,
    "out:Property Electricity": 9.976115,
    "out:Electricity (inc PV)": 9.976115,
    "out:Total Energy Use Pre PV": 118.976115,
    "out:Total Energy Use Post PV": 118.976115,
    "out:Primary Energy": 90.977065,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 15.364213,
    "out:CO2 Operational/m2": 40.555183,
    "out:Total CO2/m2": 55.919396,
    "out:Total CO2 (tons)": 178.047303,
    "out:Klimatpaverkan": -22.088118,
    "out:Initial Cost/MSEK": 3.6925524120235744,
    "out:Running cost/(Apt SEK y)": 23651.851064,
    "out:Running Cost over RSP/MSEK": 8.68914,
    "out:LCP/MSEK": 1.023028,
    "out:ROI% old": 18.243594,
    "out:Payback discounted": 7,
    "out:Atemp": 3184,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 119,
    "out:Energy use kWh/m2": 119,
    "out:Energy savings %": 53.8,
    "out:Op energy cost/MSEK": 64,
    "out:El price/MSEK": 39,
    "out:Heating price/MSEK": 21,
    "out:DH+ DHW/kWh/m2": 109,
    "out:El bought/kWh/m2": 698.32805,
    "out:Return %": 16,
    "out:Return/kSEK/y": 603,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 33.36,
    "out:EAHP (El)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 192085.547428,
    "out:EL kWh savings": 8258.927355,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 208603.4,
    "out:DH kr savings": 192085.5474277989,
    "out:El kr savings": 16517.854710116386,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 241611.182155,
    "out:% savings (space heating)": 40.656804,
    "out:Total_Water Heating (DH)": 106218.208041,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 75.88,
    "out:Etvv": 20,
    "out:Ef": 9.98,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 3692552.4120235746,
    "out:Seasonal Variation ROI (%)": 5,
    "out:Seasonal Variation Payback": 20,
    "out:New AHU Cost/SEK": 146792.87151982498,
    "out:DH CO2": 39.238256,
    "out:EL CO2": 1.316927,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 1.316927,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02001000100100100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 1,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 72.706435,
    "out:DHW (DH)": 33.36,
    "out:Property Electricity": 9.976115,
    "out:Electricity (inc PV)": 9.976115,
    "out:Total Energy Use Pre PV": 115.976115,
    "out:Total Energy Use Post PV": 115.976115,
    "out:Primary Energy": 88.506456,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 15.364213,
    "out:CO2 Operational/m2": 39.475231,
    "out:Total CO2/m2": 54.839444,
    "out:Total CO2 (tons)": 174.608737,
    "out:Klimatpaverkan": -25.526684,
    "out:Initial Cost/MSEK": 3.7721523870235747,
    "out:Running cost/(Apt SEK y)": 23038.085106,
    "out:Running Cost over RSP/MSEK": 8.46351,
    "out:LCP/MSEK": 1.169058,
    "out:ROI% old": 18.713113,
    "out:Payback discounted": 7,
    "out:Atemp": 3184,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 116,
    "out:Energy use kWh/m2": 116,
    "out:Energy savings %": 57.8,
    "out:Op energy cost/MSEK": 62,
    "out:El price/MSEK": 38,
    "out:Heating price/MSEK": 21,
    "out:DH+ DHW/kWh/m2": 106,
    "out:El bought/kWh/m2": 698.32805,
    "out:Return %": 17,
    "out:Return/kSEK/y": 632,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 33.36,
    "out:EAHP (El)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 202199.510514,
    "out:EL kWh savings": 8258.927355,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 79599.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 218717.37,
    "out:DH kr savings": 202199.5105143149,
    "out:El kr savings": 16517.854710116386,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 231497.219069,
    "out:% savings (space heating)": 43.140939,
    "out:Total_Water Heating (DH)": 106218.208041,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 72.71,
    "out:Etvv": 20,
    "out:Ef": 9.98,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 3772152.3870235747,
    "out:Seasonal Variation ROI (%)": 5,
    "out:Seasonal Variation Payback": 20,
    "out:New AHU Cost/SEK": 146792.87151982498,
    "out:DH CO2": 38.158304,
    "out:EL CO2": 1.316927,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 1.316927,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02001000100101000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 1,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 75.882932,
    "out:DHW (DH)": 28.356,
    "out:Property Electricity": 9.976115,
    "out:Electricity (inc PV)": 9.976115,
    "out:Total Energy Use Pre PV": 113.976115,
    "out:Total Energy Use Post PV": 113.976115,
    "out:Primary Energy": 88.877065,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 16.072756,
    "out:CO2 Operational/m2": 38.755263,
    "out:Total CO2/m2": 54.828019,
    "out:Total CO2 (tons)": 174.57236,
    "out:Klimatpaverkan": -25.563061,
    "out:Initial Cost/MSEK": 3.965768412023574,
    "out:Running cost/(Apt SEK y)": 22628.893617,
    "out:Running Cost over RSP/MSEK": 8.31309,
    "out:LCP/MSEK": 1.125862,
    "out:ROI% old": 18.341356,
    "out:Payback discounted": 7,
    "out:Atemp": 3184,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 114,
    "out:Energy use kWh/m2": 114,
    "out:Energy savings %": 60.5,
    "out:Op energy cost/MSEK": 61,
    "out:El price/MSEK": 37,
    "out:Heating price/MSEK": 20,
    "out:DH+ DHW/kWh/m2": 104,
    "out:El bought/kWh/m2": 698.32805,
    "out:Return %": 16,
    "out:Return/kSEK/y": 651,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 28.356,
    "out:EAHP (El)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 208018.278634,
    "out:EL kWh savings": 8258.927355,
    "out:Sewage heat recovery cost": 273216,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 224536.13,
    "out:DH kr savings": 208018.2786339669,
    "out:El kr savings": 16517.854710116386,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 241611.182155,
    "out:% savings (space heating)": 40.656804,
    "out:Total_Water Heating (DH)": 90285.476835,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 75.88,
    "out:Etvv": 17,
    "out:Ef": 9.98,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 3965768.412023574,
    "out:Seasonal Variation ROI (%)": 5,
    "out:Seasonal Variation Payback": 20,
    "out:New AHU Cost/SEK": 146792.87151982498,
    "out:DH CO2": 37.438336,
    "out:EL CO2": 1.316927,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 1.316927,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02001000100101100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 1,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 72.706435,
    "out:DHW (DH)": 28.356,
    "out:Property Electricity": 9.976115,
    "out:Electricity (inc PV)": 9.976115,
    "out:Total Energy Use Pre PV": 110.976115,
    "out:Total Energy Use Post PV": 110.976115,
    "out:Primary Energy": 86.406456,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 16.072756,
    "out:CO2 Operational/m2": 37.675311,
    "out:Total CO2/m2": 53.748067,
    "out:Total CO2 (tons)": 171.133794,
    "out:Klimatpaverkan": -29.001627,
    "out:Initial Cost/MSEK": 4.045368387023574,
    "out:Running cost/(Apt SEK y)": 22015.12766,
    "out:Running Cost over RSP/MSEK": 8.08745,
    "out:LCP/MSEK": 1.271902,
    "out:ROI% old": 18.777276,
    "out:Payback discounted": 7,
    "out:Atemp": 3184,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 111,
    "out:Energy use kWh/m2": 111,
    "out:Energy savings %": 64.9,
    "out:Op energy cost/MSEK": 59,
    "out:El price/MSEK": 36,
    "out:Heating price/MSEK": 20,
    "out:DH+ DHW/kWh/m2": 101,
    "out:El bought/kWh/m2": 698.32805,
    "out:Return %": 17,
    "out:Return/kSEK/y": 680,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 28.356,
    "out:EAHP (El)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 218132.24172,
    "out:EL kWh savings": 8258.927355,
    "out:Sewage heat recovery cost": 273216,
    "out:Balancing hydronic system cost": 79599.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 234650.1,
    "out:DH kr savings": 218132.2417204829,
    "out:El kr savings": 16517.854710116386,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 231497.219069,
    "out:% savings (space heating)": 43.140939,
    "out:Total_Water Heating (DH)": 90285.476835,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 72.71,
    "out:Etvv": 17,
    "out:Ef": 9.98,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 4045368.3870235737,
    "out:Seasonal Variation ROI (%)": 5,
    "out:Seasonal Variation Payback": 20,
    "out:New AHU Cost/SEK": 146792.87151982498,
    "out:DH CO2": 36.358384,
    "out:EL CO2": 1.316927,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 1.316927,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02001000110000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 75.882932,
    "out:DHW (DH)": 33.36,
    "out:Property Electricity": 12.57,
    "out:Electricity (inc PV)": 8.051517,
    "out:Total Energy Use Pre PV": 121.57,
    "out:Total Energy Use Post PV": 117.051517,
    "out:Primary Energy": 87.512789,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 29.918811,
    "out:CO2 Operational/m2": -4.363065,
    "out:Total CO2/m2": 25.555746,
    "out:Total CO2 (tons)": 81.369471,
    "out:Klimatpaverkan": -118.76595,
    "out:Initial Cost/MSEK": 4.335979934726249,
    "out:Running cost/(Apt SEK y)": 22495.085106,
    "out:Running Cost over RSP/MSEK": 8.27747,
    "out:LCP/MSEK": 0.79127,
    "out:ROI% old": 16.892705,
    "out:Payback discounted": 7,
    "out:Atemp": 3184,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 117,
    "out:Energy use kWh/m2": 117,
    "out:Energy savings %": 56.4,
    "out:Op energy cost/MSEK": 61,
    "out:El price/MSEK": 36,
    "out:Heating price/MSEK": 21,
    "out:DH+ DHW/kWh/m2": 109,
    "out:El bought/kWh/m2": 568.258452,
    "out:Return %": 15,
    "out:Return/kSEK/y": 657,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 33.36,
    "out:EAHP (El)": 0,
    "out:PV (kr/kWp)": 17461.56255567083,
    "out:PV (kWp installed)": 45.25485,
    "out:PV (m2 panels)": 237.433632,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 42461.284501,
    "out:PV (kWh sold)": 28074.439514,
    "out:PV (% sold (El))": 66.117735,
    "out:PV (kWh self-consumed)": 14386.844987,
    "out:PV (ratio sold/self-consumed)": 1.951397,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 192085.547428,
    "out:EL kWh savings": 14386.845543,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 262970.9,
    "out:DH kr savings": 192085.5474277989,
    "out:El kr savings": 28773.691086586456,
    "out:El kr sold": 42111.65927092506,
    "out:El kr saved": 28773.689974,
    "out:El kr return": 70885.34924492506,
    "out:% solar/total": 36,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 241611.182155,
    "out:% savings (space heating)": 40.656804,
    "out:Total_Water Heating (DH)": 106218.208041,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 75.88,
    "out:Etvv": 20,
    "out:Ef": 8.05,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 4335979.934726249,
    "out:Seasonal Variation ROI (%)": 6,
    "out:Seasonal Variation Payback": 16.666667,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 39.238256,
    "out:EL CO2": -43.601321,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -44.666212,
    "out:Bought CO2": 1.064891,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02001000110000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 72.706435,
    "out:DHW (DH)": 33.36,
    "out:Property Electricity": 12.57,
    "out:Electricity (inc PV)": 8.051517,
    "out:Total Energy Use Pre PV": 118.57,
    "out:Total Energy Use Post PV": 114.051517,
    "out:Primary Energy": 85.04218,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 29.918811,
    "out:CO2 Operational/m2": -5.443017,
    "out:Total CO2/m2": 24.475794,
    "out:Total CO2 (tons)": 77.930905,
    "out:Klimatpaverkan": -122.204516,
    "out:Initial Cost/MSEK": 4.41557990972625,
    "out:Running cost/(Apt SEK y)": 21881.319149,
    "out:Running Cost over RSP/MSEK": 8.05184,
    "out:LCP/MSEK": 0.9373,
    "out:ROI% old": 17.318159,
    "out:Payback discounted": 7,
    "out:Atemp": 3184,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 114,
    "out:Energy use kWh/m2": 114,
    "out:Energy savings %": 60.5,
    "out:Op energy cost/MSEK": 59,
    "out:El price/MSEK": 35,
    "out:Heating price/MSEK": 21,
    "out:DH+ DHW/kWh/m2": 106,
    "out:El bought/kWh/m2": 568.258452,
    "out:Return %": 16,
    "out:Return/kSEK/y": 686,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 33.36,
    "out:EAHP (El)": 0,
    "out:PV (kr/kWp)": 17461.56255567083,
    "out:PV (kWp installed)": 45.25485,
    "out:PV (m2 panels)": 237.433632,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 42461.284501,
    "out:PV (kWh sold)": 28074.439514,
    "out:PV (% sold (El))": 66.117735,
    "out:PV (kWh self-consumed)": 14386.844987,
    "out:PV (ratio sold/self-consumed)": 1.951397,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 202199.510514,
    "out:EL kWh savings": 14386.845543,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 79599.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 273084.86,
    "out:DH kr savings": 202199.5105143149,
    "out:El kr savings": 28773.691086586456,
    "out:El kr sold": 42111.65927092506,
    "out:El kr saved": 28773.689974,
    "out:El kr return": 70885.34924492506,
    "out:% solar/total": 36,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 231497.219069,
    "out:% savings (space heating)": 43.140939,
    "out:Total_Water Heating (DH)": 106218.208041,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 72.71,
    "out:Etvv": 20,
    "out:Ef": 8.05,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 4415579.90972625,
    "out:Seasonal Variation ROI (%)": 6,
    "out:Seasonal Variation Payback": 16.666667,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 38.158304,
    "out:EL CO2": -43.601321,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -44.666212,
    "out:Bought CO2": 1.064891,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02001000110001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 75.882932,
    "out:DHW (DH)": 28.356,
    "out:Property Electricity": 12.57,
    "out:Electricity (inc PV)": 8.051517,
    "out:Total Energy Use Pre PV": 116.57,
    "out:Total Energy Use Post PV": 112.051517,
    "out:Primary Energy": 85.412789,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 30.627354,
    "out:CO2 Operational/m2": -6.162985,
    "out:Total CO2/m2": 24.464369,
    "out:Total CO2 (tons)": 77.894527,
    "out:Klimatpaverkan": -122.240894,
    "out:Initial Cost/MSEK": 4.609195934726249,
    "out:Running cost/(Apt SEK y)": 21472.148936,
    "out:Running Cost over RSP/MSEK": 7.90141,
    "out:LCP/MSEK": 0.894114,
    "out:ROI% old": 17.056926,
    "out:Payback discounted": 7,
    "out:Atemp": 3184,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 112,
    "out:Energy use kWh/m2": 112,
    "out:Energy savings %": 63.4,
    "out:Op energy cost/MSEK": 58,
    "out:El price/MSEK": 35,
    "out:Heating price/MSEK": 20,
    "out:DH+ DHW/kWh/m2": 104,
    "out:El bought/kWh/m2": 568.258452,
    "out:Return %": 15,
    "out:Return/kSEK/y": 706,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 28.356,
    "out:EAHP (El)": 0,
    "out:PV (kr/kWp)": 17461.56255567083,
    "out:PV (kWp installed)": 45.25485,
    "out:PV (m2 panels)": 237.433632,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 42461.284501,
    "out:PV (kWh sold)": 28074.439514,
    "out:PV (% sold (El))": 66.117735,
    "out:PV (kWh self-consumed)": 14386.844987,
    "out:PV (ratio sold/self-consumed)": 1.951397,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 208018.278634,
    "out:EL kWh savings": 14386.845543,
    "out:Sewage heat recovery cost": 273216,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 278903.63,
    "out:DH kr savings": 208018.2786339669,
    "out:El kr savings": 28773.691086586456,
    "out:El kr sold": 42111.65927092506,
    "out:El kr saved": 28773.689974,
    "out:El kr return": 70885.34924492506,
    "out:% solar/total": 36,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 241611.182155,
    "out:% savings (space heating)": 40.656804,
    "out:Total_Water Heating (DH)": 90285.476835,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 75.88,
    "out:Etvv": 17,
    "out:Ef": 8.05,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 4609195.934726249,
    "out:Seasonal Variation ROI (%)": 6,
    "out:Seasonal Variation Payback": 16.666667,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 37.438336,
    "out:EL CO2": -43.601321,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -44.666212,
    "out:Bought CO2": 1.064891,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02001000110001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 72.706435,
    "out:DHW (DH)": 28.356,
    "out:Property Electricity": 12.57,
    "out:Electricity (inc PV)": 8.051517,
    "out:Total Energy Use Pre PV": 113.57,
    "out:Total Energy Use Post PV": 109.051517,
    "out:Primary Energy": 82.94218,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 30.627354,
    "out:CO2 Operational/m2": -7.242937,
    "out:Total CO2/m2": 23.384417,
    "out:Total CO2 (tons)": 74.455961,
    "out:Klimatpaverkan": -125.67946,
    "out:Initial Cost/MSEK": 4.688795909726249,
    "out:Running cost/(Apt SEK y)": 20858.382979,
    "out:Running Cost over RSP/MSEK": 7.67578,
    "out:LCP/MSEK": 1.040144,
    "out:ROI% old": 17.454801,
    "out:Payback discounted": 7,
    "out:Atemp": 3184,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 109,
    "out:Energy use kWh/m2": 109,
    "out:Energy savings %": 67.9,
    "out:Op energy cost/MSEK": 57,
    "out:El price/MSEK": 34,
    "out:Heating price/MSEK": 20,
    "out:DH+ DHW/kWh/m2": 101,
    "out:El bought/kWh/m2": 568.258452,
    "out:Return %": 16,
    "out:Return/kSEK/y": 734,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 28.356,
    "out:EAHP (El)": 0,
    "out:PV (kr/kWp)": 17461.56255567083,
    "out:PV (kWp installed)": 45.25485,
    "out:PV (m2 panels)": 237.433632,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 42461.284501,
    "out:PV (kWh sold)": 28074.439514,
    "out:PV (% sold (El))": 66.117735,
    "out:PV (kWh self-consumed)": 14386.844987,
    "out:PV (ratio sold/self-consumed)": 1.951397,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 218132.24172,
    "out:EL kWh savings": 14386.845543,
    "out:Sewage heat recovery cost": 273216,
    "out:Balancing hydronic system cost": 79599.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 289017.59,
    "out:DH kr savings": 218132.2417204829,
    "out:El kr savings": 28773.691086586456,
    "out:El kr sold": 42111.65927092506,
    "out:El kr saved": 28773.689974,
    "out:El kr return": 70885.34924492506,
    "out:% solar/total": 36,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 231497.219069,
    "out:% savings (space heating)": 43.140939,
    "out:Total_Water Heating (DH)": 90285.476835,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 72.71,
    "out:Etvv": 17,
    "out:Ef": 8.05,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 4688795.909726249,
    "out:Seasonal Variation ROI (%)": 6,
    "out:Seasonal Variation Payback": 16.666667,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 36.358384,
    "out:EL CO2": -43.601321,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -44.666212,
    "out:Bought CO2": 1.064891,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02001000110100000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 1,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 75.882932,
    "out:DHW (DH)": 33.36,
    "out:Property Electricity": 9.976115,
    "out:Electricity (inc PV)": 6.231337,
    "out:Total Energy Use Pre PV": 118.976115,
    "out:Total Energy Use Post PV": 115.231337,
    "out:Primary Energy": 84.236465,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 30.103313,
    "out:CO2 Operational/m2": -8.593193,
    "out:Total CO2/m2": 21.51012,
    "out:Total CO2 (tons)": 68.488201,
    "out:Klimatpaverkan": -131.64722,
    "out:Initial Cost/MSEK": 4.482772806246075,
    "out:Running cost/(Apt SEK y)": 22169.851064,
    "out:Running Cost over RSP/MSEK": 8.15924,
    "out:LCP/MSEK": 0.762707,
    "out:ROI% old": 16.716312,
    "out:Payback discounted": 7,
    "out:Atemp": 3184,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 115,
    "out:Energy use kWh/m2": 115,
    "out:Energy savings %": 59.1,
    "out:Op energy cost/MSEK": 60,
    "out:El price/MSEK": 35,
    "out:Heating price/MSEK": 21,
    "out:DH+ DHW/kWh/m2": 109,
    "out:El bought/kWh/m2": 439.497378,
    "out:Return %": 15,
    "out:Return/kSEK/y": 673,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 33.36,
    "out:EAHP (El)": 0,
    "out:PV (kr/kWp)": 17461.56255567083,
    "out:PV (kWp installed)": 45.25485,
    "out:PV (m2 panels)": 237.433632,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 42461.284501,
    "out:PV (kWh sold)": 30537.914073,
    "out:PV (% sold (El))": 71.919431,
    "out:PV (kWh self-consumed)": 11923.370428,
    "out:PV (ratio sold/self-consumed)": 2.561181,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 192085.547428,
    "out:EL kWh savings": 20182.29692,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 278257.01,
    "out:DH kr savings": 192085.5474277989,
    "out:El kr savings": 40364.59383912171,
    "out:El kr sold": 45806.87111012268,
    "out:El kr saved": 23846.740856,
    "out:El kr return": 69653.61196612268,
    "out:% solar/total": 30,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 241611.182155,
    "out:% savings (space heating)": 40.656804,
    "out:Total_Water Heating (DH)": 106218.208041,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 75.88,
    "out:Etvv": 20,
    "out:Ef": 6.23,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 4482772.806246075,
    "out:Seasonal Variation ROI (%)": 6,
    "out:Seasonal Variation Payback": 16.666667,
    "out:New AHU Cost/SEK": 146792.87151982498,
    "out:DH CO2": 39.238256,
    "out:EL CO2": -47.831449,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -48.655512,
    "out:Bought CO2": 0.824063,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02001000110100100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 1,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 72.706435,
    "out:DHW (DH)": 33.36,
    "out:Property Electricity": 9.976115,
    "out:Electricity (inc PV)": 6.231337,
    "out:Total Energy Use Pre PV": 115.976115,
    "out:Total Energy Use Post PV": 112.231337,
    "out:Primary Energy": 81.765856,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 30.103313,
    "out:CO2 Operational/m2": -9.673145,
    "out:Total CO2/m2": 20.430168,
    "out:Total CO2 (tons)": 65.049635,
    "out:Klimatpaverkan": -135.085786,
    "out:Initial Cost/MSEK": 4.562372781246075,
    "out:Running cost/(Apt SEK y)": 21556.085106,
    "out:Running Cost over RSP/MSEK": 7.93361,
    "out:LCP/MSEK": 0.908737,
    "out:ROI% old": 17.131155,
    "out:Payback discounted": 7,
    "out:Atemp": 3184,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 112,
    "out:Energy use kWh/m2": 112,
    "out:Energy savings %": 63.4,
    "out:Op energy cost/MSEK": 59,
    "out:El price/MSEK": 35,
    "out:Heating price/MSEK": 21,
    "out:DH+ DHW/kWh/m2": 106,
    "out:El bought/kWh/m2": 439.497378,
    "out:Return %": 15,
    "out:Return/kSEK/y": 702,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 33.36,
    "out:EAHP (El)": 0,
    "out:PV (kr/kWp)": 17461.56255567083,
    "out:PV (kWp installed)": 45.25485,
    "out:PV (m2 panels)": 237.433632,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 42461.284501,
    "out:PV (kWh sold)": 30537.914073,
    "out:PV (% sold (El))": 71.919431,
    "out:PV (kWh self-consumed)": 11923.370428,
    "out:PV (ratio sold/self-consumed)": 2.561181,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 202199.510514,
    "out:EL kWh savings": 20182.29692,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 79599.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 288370.98,
    "out:DH kr savings": 202199.5105143149,
    "out:El kr savings": 40364.59383912171,
    "out:El kr sold": 45806.87111012268,
    "out:El kr saved": 23846.740856,
    "out:El kr return": 69653.61196612268,
    "out:% solar/total": 30,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 231497.219069,
    "out:% savings (space heating)": 43.140939,
    "out:Total_Water Heating (DH)": 106218.208041,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 72.71,
    "out:Etvv": 20,
    "out:Ef": 6.23,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 4562372.7812460745,
    "out:Seasonal Variation ROI (%)": 6,
    "out:Seasonal Variation Payback": 16.666667,
    "out:New AHU Cost/SEK": 146792.87151982498,
    "out:DH CO2": 38.158304,
    "out:EL CO2": -47.831449,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -48.655512,
    "out:Bought CO2": 0.824063,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02001000110101000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 1,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 75.882932,
    "out:DHW (DH)": 28.356,
    "out:Property Electricity": 9.976115,
    "out:Electricity (inc PV)": 6.231337,
    "out:Total Energy Use Pre PV": 113.976115,
    "out:Total Energy Use Post PV": 110.231337,
    "out:Primary Energy": 82.136465,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 30.811856,
    "out:CO2 Operational/m2": -10.393113,
    "out:Total CO2/m2": 20.418743,
    "out:Total CO2 (tons)": 65.013258,
    "out:Klimatpaverkan": -135.122163,
    "out:Initial Cost/MSEK": 4.755988806246074,
    "out:Running cost/(Apt SEK y)": 21146.914894,
    "out:Running Cost over RSP/MSEK": 7.78319,
    "out:LCP/MSEK": 0.865541,
    "out:ROI% old": 16.885568,
    "out:Payback discounted": 7,
    "out:Atemp": 3184,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 110,
    "out:Energy use kWh/m2": 110,
    "out:Energy savings %": 66.4,
    "out:Op energy cost/MSEK": 57,
    "out:El price/MSEK": 34,
    "out:Heating price/MSEK": 20,
    "out:DH+ DHW/kWh/m2": 104,
    "out:El bought/kWh/m2": 439.497378,
    "out:Return %": 15,
    "out:Return/kSEK/y": 721,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 28.356,
    "out:EAHP (El)": 0,
    "out:PV (kr/kWp)": 17461.56255567083,
    "out:PV (kWp installed)": 45.25485,
    "out:PV (m2 panels)": 237.433632,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 42461.284501,
    "out:PV (kWh sold)": 30537.914073,
    "out:PV (% sold (El))": 71.919431,
    "out:PV (kWh self-consumed)": 11923.370428,
    "out:PV (ratio sold/self-consumed)": 2.561181,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 208018.278634,
    "out:EL kWh savings": 20182.29692,
    "out:Sewage heat recovery cost": 273216,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 294189.74,
    "out:DH kr savings": 208018.2786339669,
    "out:El kr savings": 40364.59383912171,
    "out:El kr sold": 45806.87111012268,
    "out:El kr saved": 23846.740856,
    "out:El kr return": 69653.61196612268,
    "out:% solar/total": 30,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 241611.182155,
    "out:% savings (space heating)": 40.656804,
    "out:Total_Water Heating (DH)": 90285.476835,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 75.88,
    "out:Etvv": 17,
    "out:Ef": 6.23,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 4755988.806246074,
    "out:Seasonal Variation ROI (%)": 6,
    "out:Seasonal Variation Payback": 16.666667,
    "out:New AHU Cost/SEK": 146792.87151982498,
    "out:DH CO2": 37.438336,
    "out:EL CO2": -47.831449,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -48.655512,
    "out:Bought CO2": 0.824063,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02001000110101100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 1,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 72.706435,
    "out:DHW (DH)": 28.356,
    "out:Property Electricity": 9.976115,
    "out:Electricity (inc PV)": 6.231337,
    "out:Total Energy Use Pre PV": 110.976115,
    "out:Total Energy Use Post PV": 107.231337,
    "out:Primary Energy": 79.665856,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 30.811856,
    "out:CO2 Operational/m2": -11.473065,
    "out:Total CO2/m2": 19.338791,
    "out:Total CO2 (tons)": 61.574692,
    "out:Klimatpaverkan": -138.560729,
    "out:Initial Cost/MSEK": 4.8355887812460745,
    "out:Running cost/(Apt SEK y)": 20533.148936,
    "out:Running Cost over RSP/MSEK": 7.55755,
    "out:LCP/MSEK": 1.011581,
    "out:ROI% old": 17.274215,
    "out:Payback discounted": 7,
    "out:Atemp": 3184,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 107,
    "out:Energy use kWh/m2": 107,
    "out:Energy savings %": 71,
    "out:Op energy cost/MSEK": 56,
    "out:El price/MSEK": 33,
    "out:Heating price/MSEK": 20,
    "out:DH+ DHW/kWh/m2": 101,
    "out:El bought/kWh/m2": 439.497378,
    "out:Return %": 16,
    "out:Return/kSEK/y": 750,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 28.356,
    "out:EAHP (El)": 0,
    "out:PV (kr/kWp)": 17461.56255567083,
    "out:PV (kWp installed)": 45.25485,
    "out:PV (m2 panels)": 237.433632,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 42461.284501,
    "out:PV (kWh sold)": 30537.914073,
    "out:PV (% sold (El))": 71.919431,
    "out:PV (kWh self-consumed)": 11923.370428,
    "out:PV (ratio sold/self-consumed)": 2.561181,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 218132.24172,
    "out:EL kWh savings": 20182.29692,
    "out:Sewage heat recovery cost": 273216,
    "out:Balancing hydronic system cost": 79599.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 304303.71,
    "out:DH kr savings": 218132.2417204829,
    "out:El kr savings": 40364.59383912171,
    "out:El kr sold": 45806.87111012268,
    "out:El kr saved": 23846.740856,
    "out:El kr return": 69653.61196612268,
    "out:% solar/total": 30,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 231497.219069,
    "out:% savings (space heating)": 43.140939,
    "out:Total_Water Heating (DH)": 90285.476835,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 72.71,
    "out:Etvv": 17,
    "out:Ef": 6.23,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 4835588.7812460745,
    "out:Seasonal Variation ROI (%)": 6,
    "out:Seasonal Variation Payback": 16.666667,
    "out:New AHU Cost/SEK": 146792.87151982498,
    "out:DH CO2": 36.358384,
    "out:EL CO2": -47.831449,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -48.655512,
    "out:Bought CO2": 0.824063,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02001000120000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 75.882932,
    "out:DHW (DH)": 33.36,
    "out:Property Electricity": 12.57,
    "out:Electricity (inc PV)": 7.530921,
    "out:Total Energy Use Pre PV": 121.57,
    "out:Total Energy Use Post PV": 116.530921,
    "out:Primary Energy": 86.575716,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 44.65791,
    "out:CO2 Operational/m2": -69.718382,
    "out:Total CO2/m2": -25.060472,
    "out:Total CO2 (tons)": -79.792519,
    "out:Klimatpaverkan": -279.92794,
    "out:Initial Cost/MSEK": 5.1261932864475,
    "out:Running cost/(Apt SEK y)": 21122.297872,
    "out:Running Cost over RSP/MSEK": 7.78704,
    "out:LCP/MSEK": 0.491487,
    "out:ROI% old": 15.655393,
    "out:Payback discounted": 8,
    "out:Atemp": 3184,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 117,
    "out:Energy use kWh/m2": 117,
    "out:Energy savings %": 56.4,
    "out:Op energy cost/MSEK": 58,
    "out:El price/MSEK": 33,
    "out:Heating price/MSEK": 21,
    "out:DH+ DHW/kWh/m2": 109,
    "out:El bought/kWh/m2": 530.25352,
    "out:Return %": 14,
    "out:Return/kSEK/y": 722,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 33.36,
    "out:EAHP (El)": 0,
    "out:PV (kr/kWp)": 17461.48474631725,
    "out:PV (kWp installed)": 90.5097,
    "out:PV (m2 panels)": 474.867263,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 84922.568824,
    "out:PV (kWh sold)": 68878.145463,
    "out:PV (% sold (El))": 81.106997,
    "out:PV (kWh self-consumed)": 16044.423361,
    "out:PV (ratio sold/self-consumed)": 4.292965,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 192085.547428,
    "out:EL kWh savings": 16044.422709,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 327491.61,
    "out:DH kr savings": 192085.5474277989,
    "out:El kr savings": 32088.845417124598,
    "out:El kr sold": 103317.21819422109,
    "out:El kr saved": 32088.846722,
    "out:El kr return": 135406.06491622108,
    "out:% solar/total": 40,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 241611.182155,
    "out:% savings (space heating)": 40.656804,
    "out:Total_Water Heating (DH)": 106218.208041,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 75.88,
    "out:Etvv": 20,
    "out:Ef": 7.53,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 5126193.2864475,
    "out:Seasonal Variation ROI (%)": 6,
    "out:Seasonal Variation Payback": 16.666667,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 39.238256,
    "out:EL CO2": -108.956638,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -109.952146,
    "out:Bought CO2": 0.995508,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02001000120000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 72.706435,
    "out:DHW (DH)": 33.36,
    "out:Property Electricity": 12.57,
    "out:Electricity (inc PV)": 7.530921,
    "out:Total Energy Use Pre PV": 118.57,
    "out:Total Energy Use Post PV": 113.530921,
    "out:Primary Energy": 84.105107,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 44.65791,
    "out:CO2 Operational/m2": -70.798334,
    "out:Total CO2/m2": -26.140424,
    "out:Total CO2 (tons)": -83.231085,
    "out:Klimatpaverkan": -283.366506,
    "out:Initial Cost/MSEK": 5.205793261447501,
    "out:Running cost/(Apt SEK y)": 20508.531915,
    "out:Running Cost over RSP/MSEK": 7.5614,
    "out:LCP/MSEK": 0.637527,
    "out:ROI% old": 16.035212,
    "out:Payback discounted": 8,
    "out:Atemp": 3184,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 114,
    "out:Energy use kWh/m2": 114,
    "out:Energy savings %": 60.5,
    "out:Op energy cost/MSEK": 56,
    "out:El price/MSEK": 32,
    "out:Heating price/MSEK": 21,
    "out:DH+ DHW/kWh/m2": 106,
    "out:El bought/kWh/m2": 530.25352,
    "out:Return %": 14,
    "out:Return/kSEK/y": 751,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 33.36,
    "out:EAHP (El)": 0,
    "out:PV (kr/kWp)": 17461.48474631725,
    "out:PV (kWp installed)": 90.5097,
    "out:PV (m2 panels)": 474.867263,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 84922.568824,
    "out:PV (kWh sold)": 68878.145463,
    "out:PV (% sold (El))": 81.106997,
    "out:PV (kWh self-consumed)": 16044.423361,
    "out:PV (ratio sold/self-consumed)": 4.292965,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 202199.510514,
    "out:EL kWh savings": 16044.422709,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 79599.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 337605.57,
    "out:DH kr savings": 202199.5105143149,
    "out:El kr savings": 32088.845417124598,
    "out:El kr sold": 103317.21819422109,
    "out:El kr saved": 32088.846722,
    "out:El kr return": 135406.06491622108,
    "out:% solar/total": 40,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 231497.219069,
    "out:% savings (space heating)": 43.140939,
    "out:Total_Water Heating (DH)": 106218.208041,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 72.71,
    "out:Etvv": 20,
    "out:Ef": 7.53,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 5205793.2614475,
    "out:Seasonal Variation ROI (%)": 6,
    "out:Seasonal Variation Payback": 16.666667,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 38.158304,
    "out:EL CO2": -108.956638,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -109.952146,
    "out:Bought CO2": 0.995508,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02001000120001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 75.882932,
    "out:DHW (DH)": 28.356,
    "out:Property Electricity": 12.57,
    "out:Electricity (inc PV)": 7.530921,
    "out:Total Energy Use Pre PV": 116.57,
    "out:Total Energy Use Post PV": 111.530921,
    "out:Primary Energy": 84.475716,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 45.366453,
    "out:CO2 Operational/m2": -71.518302,
    "out:Total CO2/m2": -26.151849,
    "out:Total CO2 (tons)": -83.267462,
    "out:Klimatpaverkan": -283.402883,
    "out:Initial Cost/MSEK": 5.399409286447501,
    "out:Running cost/(Apt SEK y)": 20099.361702,
    "out:Running Cost over RSP/MSEK": 7.41098,
    "out:LCP/MSEK": 0.594331,
    "out:ROI% old": 15.85819,
    "out:Payback discounted": 8,
    "out:Atemp": 3184,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 112,
    "out:Energy use kWh/m2": 112,
    "out:Energy savings %": 63.4,
    "out:Op energy cost/MSEK": 55,
    "out:El price/MSEK": 32,
    "out:Heating price/MSEK": 20,
    "out:DH+ DHW/kWh/m2": 104,
    "out:El bought/kWh/m2": 530.25352,
    "out:Return %": 14,
    "out:Return/kSEK/y": 770,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 28.356,
    "out:EAHP (El)": 0,
    "out:PV (kr/kWp)": 17461.48474631725,
    "out:PV (kWp installed)": 90.5097,
    "out:PV (m2 panels)": 474.867263,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 84922.568824,
    "out:PV (kWh sold)": 68878.145463,
    "out:PV (% sold (El))": 81.106997,
    "out:PV (kWh self-consumed)": 16044.423361,
    "out:PV (ratio sold/self-consumed)": 4.292965,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 208018.278634,
    "out:EL kWh savings": 16044.422709,
    "out:Sewage heat recovery cost": 273216,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 343424.34,
    "out:DH kr savings": 208018.2786339669,
    "out:El kr savings": 32088.845417124598,
    "out:El kr sold": 103317.21819422109,
    "out:El kr saved": 32088.846722,
    "out:El kr return": 135406.06491622108,
    "out:% solar/total": 40,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 241611.182155,
    "out:% savings (space heating)": 40.656804,
    "out:Total_Water Heating (DH)": 90285.476835,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 75.88,
    "out:Etvv": 17,
    "out:Ef": 7.53,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 5399409.286447501,
    "out:Seasonal Variation ROI (%)": 6,
    "out:Seasonal Variation Payback": 16.666667,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 37.438336,
    "out:EL CO2": -108.956638,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -109.952146,
    "out:Bought CO2": 0.995508,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02001000120001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 72.706435,
    "out:DHW (DH)": 28.356,
    "out:Property Electricity": 12.57,
    "out:Electricity (inc PV)": 7.530921,
    "out:Total Energy Use Pre PV": 113.57,
    "out:Total Energy Use Post PV": 108.530921,
    "out:Primary Energy": 82.005107,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 45.366453,
    "out:CO2 Operational/m2": -72.598254,
    "out:Total CO2/m2": -27.231801,
    "out:Total CO2 (tons)": -86.706028,
    "out:Klimatpaverkan": -286.841449,
    "out:Initial Cost/MSEK": 5.479009261447501,
    "out:Running cost/(Apt SEK y)": 19485.595745,
    "out:Running Cost over RSP/MSEK": 7.18535,
    "out:LCP/MSEK": 0.740361,
    "out:ROI% old": 16.216096,
    "out:Payback discounted": 8,
    "out:Atemp": 3184,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 109,
    "out:Energy use kWh/m2": 109,
    "out:Energy savings %": 67.9,
    "out:Op energy cost/MSEK": 54,
    "out:El price/MSEK": 31,
    "out:Heating price/MSEK": 20,
    "out:DH+ DHW/kWh/m2": 101,
    "out:El bought/kWh/m2": 530.25352,
    "out:Return %": 15,
    "out:Return/kSEK/y": 799,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 28.356,
    "out:EAHP (El)": 0,
    "out:PV (kr/kWp)": 17461.48474631725,
    "out:PV (kWp installed)": 90.5097,
    "out:PV (m2 panels)": 474.867263,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 84922.568824,
    "out:PV (kWh sold)": 68878.145463,
    "out:PV (% sold (El))": 81.106997,
    "out:PV (kWh self-consumed)": 16044.423361,
    "out:PV (ratio sold/self-consumed)": 4.292965,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 218132.24172,
    "out:EL kWh savings": 16044.422709,
    "out:Sewage heat recovery cost": 273216,
    "out:Balancing hydronic system cost": 79599.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 353538.31,
    "out:DH kr savings": 218132.2417204829,
    "out:El kr savings": 32088.845417124598,
    "out:El kr sold": 103317.21819422109,
    "out:El kr saved": 32088.846722,
    "out:El kr return": 135406.06491622108,
    "out:% solar/total": 40,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 231497.219069,
    "out:% savings (space heating)": 43.140939,
    "out:Total_Water Heating (DH)": 90285.476835,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 72.71,
    "out:Etvv": 17,
    "out:Ef": 7.53,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 5479009.2614475,
    "out:Seasonal Variation ROI (%)": 6,
    "out:Seasonal Variation Payback": 16.666667,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 36.358384,
    "out:EL CO2": -108.956638,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -109.952146,
    "out:Bought CO2": 0.995508,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02001000120100000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 1,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 75.882932,
    "out:DHW (DH)": 33.36,
    "out:Property Electricity": 9.976115,
    "out:Electricity (inc PV)": 5.879188,
    "out:Total Energy Use Pre PV": 118.976115,
    "out:Total Energy Use Post PV": 114.879188,
    "out:Primary Energy": 83.602596,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 44.842412,
    "out:CO2 Operational/m2": -74.804108,
    "out:Total CO2/m2": -29.961696,
    "out:Total CO2 (tons)": -95.398011,
    "out:Klimatpaverkan": -295.533432,
    "out:Initial Cost/MSEK": 5.272986157967325,
    "out:Running cost/(Apt SEK y)": 20802.787234,
    "out:Running Cost over RSP/MSEK": 7.67089,
    "out:LCP/MSEK": 0.460844,
    "out:ROI% old": 15.534245,
    "out:Payback discounted": 8,
    "out:Atemp": 3184,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 115,
    "out:Energy use kWh/m2": 115,
    "out:Energy savings %": 59.1,
    "out:Op energy cost/MSEK": 57,
    "out:El price/MSEK": 33,
    "out:Heating price/MSEK": 21,
    "out:DH+ DHW/kWh/m2": 109,
    "out:El bought/kWh/m2": 413.65837,
    "out:Return %": 14,
    "out:Return/kSEK/y": 737,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 33.36,
    "out:EAHP (El)": 0,
    "out:PV (kr/kWp)": 17461.48474631725,
    "out:PV (kWp installed)": 90.5097,
    "out:PV (m2 panels)": 474.867263,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 84922.568824,
    "out:PV (kWh sold)": 71877.957046,
    "out:PV (% sold (El))": 84.639405,
    "out:PV (kWh self-consumed)": 13044.611778,
    "out:PV (ratio sold/self-consumed)": 5.510165,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 192085.547428,
    "out:EL kWh savings": 21303.538998,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 342509.56,
    "out:DH kr savings": 192085.5474277989,
    "out:El kr savings": 42607.077996404216,
    "out:El kr sold": 107816.93556907076,
    "out:El kr saved": 26089.223556,
    "out:El kr return": 133906.15912507076,
    "out:% solar/total": 33,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 241611.182155,
    "out:% savings (space heating)": 40.656804,
    "out:Total_Water Heating (DH)": 106218.208041,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 75.88,
    "out:Etvv": 20,
    "out:Ef": 5.88,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 5272986.157967325,
    "out:Seasonal Variation ROI (%)": 6,
    "out:Seasonal Variation Payback": 16.666667,
    "out:New AHU Cost/SEK": 146792.87151982498,
    "out:DH CO2": 39.238256,
    "out:EL CO2": -114.042364,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -114.819396,
    "out:Bought CO2": 0.777032,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02001000120100100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 1,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 72.706435,
    "out:DHW (DH)": 33.36,
    "out:Property Electricity": 9.976115,
    "out:Electricity (inc PV)": 5.879188,
    "out:Total Energy Use Pre PV": 115.976115,
    "out:Total Energy Use Post PV": 111.879188,
    "out:Primary Energy": 81.131987,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 44.842412,
    "out:CO2 Operational/m2": -75.88406,
    "out:Total CO2/m2": -31.041648,
    "out:Total CO2 (tons)": -98.836577,
    "out:Klimatpaverkan": -298.971999,
    "out:Initial Cost/MSEK": 5.352586132967325,
    "out:Running cost/(Apt SEK y)": 20189,
    "out:Running Cost over RSP/MSEK": 7.44526,
    "out:LCP/MSEK": 0.606874,
    "out:ROI% old": 15.905422,
    "out:Payback discounted": 8,
    "out:Atemp": 3184,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 112,
    "out:Energy use kWh/m2": 112,
    "out:Energy savings %": 63.4,
    "out:Op energy cost/MSEK": 56,
    "out:El price/MSEK": 32,
    "out:Heating price/MSEK": 21,
    "out:DH+ DHW/kWh/m2": 106,
    "out:El bought/kWh/m2": 413.65837,
    "out:Return %": 14,
    "out:Return/kSEK/y": 766,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 33.36,
    "out:EAHP (El)": 0,
    "out:PV (kr/kWp)": 17461.48474631725,
    "out:PV (kWp installed)": 90.5097,
    "out:PV (m2 panels)": 474.867263,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 84922.568824,
    "out:PV (kWh sold)": 71877.957046,
    "out:PV (% sold (El))": 84.639405,
    "out:PV (kWh self-consumed)": 13044.611778,
    "out:PV (ratio sold/self-consumed)": 5.510165,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 7,
    "out:Payback Time": 14.285714,
    "out:DH kWh savings": 202199.510514,
    "out:EL kWh savings": 21303.538998,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 79599.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 352623.52,
    "out:DH kr savings": 202199.5105143149,
    "out:El kr savings": 42607.077996404216,
    "out:El kr sold": 107816.93556907076,
    "out:El kr saved": 26089.223556,
    "out:El kr return": 133906.15912507076,
    "out:% solar/total": 33,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 231497.219069,
    "out:% savings (space heating)": 43.140939,
    "out:Total_Water Heating (DH)": 106218.208041,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 72.71,
    "out:Etvv": 20,
    "out:Ef": 5.88,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 5352586.132967325,
    "out:Seasonal Variation ROI (%)": 6,
    "out:Seasonal Variation Payback": 16.666667,
    "out:New AHU Cost/SEK": 146792.87151982498,
    "out:DH CO2": 38.158304,
    "out:EL CO2": -114.042364,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -114.819396,
    "out:Bought CO2": 0.777032,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02001000120101000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 1,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 75.882932,
    "out:DHW (DH)": 28.356,
    "out:Property Electricity": 9.976115,
    "out:Electricity (inc PV)": 5.879188,
    "out:Total Energy Use Pre PV": 113.976115,
    "out:Total Energy Use Post PV": 109.879188,
    "out:Primary Energy": 81.502596,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 45.550955,
    "out:CO2 Operational/m2": -76.604028,
    "out:Total CO2/m2": -31.053073,
    "out:Total CO2 (tons)": -98.872955,
    "out:Klimatpaverkan": -299.008376,
    "out:Initial Cost/MSEK": 5.546202157967325,
    "out:Running cost/(Apt SEK y)": 19779.829787,
    "out:Running Cost over RSP/MSEK": 7.29484,
    "out:LCP/MSEK": 0.563678,
    "out:ROI% old": 15.737616,
    "out:Payback discounted": 8,
    "out:Atemp": 3184,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 110,
    "out:Energy use kWh/m2": 110,
    "out:Energy savings %": 66.4,
    "out:Op energy cost/MSEK": 55,
    "out:El price/MSEK": 31,
    "out:Heating price/MSEK": 20,
    "out:DH+ DHW/kWh/m2": 104,
    "out:El bought/kWh/m2": 413.65837,
    "out:Return %": 14,
    "out:Return/kSEK/y": 785,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 28.356,
    "out:EAHP (El)": 0,
    "out:PV (kr/kWp)": 17461.48474631725,
    "out:PV (kWp installed)": 90.5097,
    "out:PV (m2 panels)": 474.867263,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 84922.568824,
    "out:PV (kWh sold)": 71877.957046,
    "out:PV (% sold (El))": 84.639405,
    "out:PV (kWh self-consumed)": 13044.611778,
    "out:PV (ratio sold/self-consumed)": 5.510165,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 208018.278634,
    "out:EL kWh savings": 21303.538998,
    "out:Sewage heat recovery cost": 273216,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 358442.29,
    "out:DH kr savings": 208018.2786339669,
    "out:El kr savings": 42607.077996404216,
    "out:El kr sold": 107816.93556907076,
    "out:El kr saved": 26089.223556,
    "out:El kr return": 133906.15912507076,
    "out:% solar/total": 33,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 241611.182155,
    "out:% savings (space heating)": 40.656804,
    "out:Total_Water Heating (DH)": 90285.476835,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 75.88,
    "out:Etvv": 17,
    "out:Ef": 5.88,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 5546202.157967324,
    "out:Seasonal Variation ROI (%)": 6,
    "out:Seasonal Variation Payback": 16.666667,
    "out:New AHU Cost/SEK": 146792.87151982498,
    "out:DH CO2": 37.438336,
    "out:EL CO2": -114.042364,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -114.819396,
    "out:Bought CO2": 0.777032,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02001000120101100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 1,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 72.706435,
    "out:DHW (DH)": 28.356,
    "out:Property Electricity": 9.976115,
    "out:Electricity (inc PV)": 5.879188,
    "out:Total Energy Use Pre PV": 110.976115,
    "out:Total Energy Use Post PV": 106.879188,
    "out:Primary Energy": 79.031987,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 45.550955,
    "out:CO2 Operational/m2": -77.68398,
    "out:Total CO2/m2": -32.133025,
    "out:Total CO2 (tons)": -102.311521,
    "out:Klimatpaverkan": -302.446942,
    "out:Initial Cost/MSEK": 5.625802132967325,
    "out:Running cost/(Apt SEK y)": 19166.06383,
    "out:Running Cost over RSP/MSEK": 7.0692,
    "out:LCP/MSEK": 0.709718,
    "out:ROI% old": 16.087915,
    "out:Payback discounted": 8,
    "out:Atemp": 3184,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 107,
    "out:Energy use kWh/m2": 107,
    "out:Energy savings %": 71,
    "out:Op energy cost/MSEK": 53,
    "out:El price/MSEK": 30,
    "out:Heating price/MSEK": 20,
    "out:DH+ DHW/kWh/m2": 101,
    "out:El bought/kWh/m2": 413.65837,
    "out:Return %": 14,
    "out:Return/kSEK/y": 814,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 28.356,
    "out:EAHP (El)": 0,
    "out:PV (kr/kWp)": 17461.48474631725,
    "out:PV (kWp installed)": 90.5097,
    "out:PV (m2 panels)": 474.867263,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 84922.568824,
    "out:PV (kWh sold)": 71877.957046,
    "out:PV (% sold (El))": 84.639405,
    "out:PV (kWh self-consumed)": 13044.611778,
    "out:PV (ratio sold/self-consumed)": 5.510165,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 7,
    "out:Payback Time": 14.285714,
    "out:DH kWh savings": 218132.24172,
    "out:EL kWh savings": 21303.538998,
    "out:Sewage heat recovery cost": 273216,
    "out:Balancing hydronic system cost": 79599.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 368556.26,
    "out:DH kr savings": 218132.2417204829,
    "out:El kr savings": 42607.077996404216,
    "out:El kr sold": 107816.93556907076,
    "out:El kr saved": 26089.223556,
    "out:El kr return": 133906.15912507076,
    "out:% solar/total": 33,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 231497.219069,
    "out:% savings (space heating)": 43.140939,
    "out:Total_Water Heating (DH)": 90285.476835,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 72.71,
    "out:Etvv": 17,
    "out:Ef": 5.88,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 5625802.132967325,
    "out:Seasonal Variation ROI (%)": 6,
    "out:Seasonal Variation Payback": 16.666667,
    "out:New AHU Cost/SEK": 146792.87151982498,
    "out:DH CO2": 36.358384,
    "out:EL CO2": -114.042364,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -114.819396,
    "out:Bought CO2": 0.777032,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02001001000000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 35.337669,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 39.792307,
    "out:Electricity (inc PV)": 39.792307,
    "out:Total Energy Use Pre PV": 74.792307,
    "out:Total Energy Use Post PV": 74.792307,
    "out:Primary Energy": 92.21267,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 18.932654,
    "out:CO2 Operational/m2": 17.852343,
    "out:Total CO2/m2": 36.784997,
    "out:Total CO2 (tons)": 117.123395,
    "out:Klimatpaverkan": -83.012026,
    "out:Initial Cost/MSEK": 6.405961264077742,
    "out:Running cost/(Apt SEK y)": 12552.042553,
    "out:Running Cost over RSP/MSEK": 4.59136,
    "out:LCP/MSEK": 2.407399,
    "out:ROI% old": 19.654372,
    "out:Payback discounted": 6,
    "out:Atemp": 3184,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 75,
    "out:Energy use kWh/m2": 75,
    "out:Energy savings %": 144,
    "out:Op energy cost/MSEK": 32,
    "out:El price/MSEK": 24,
    "out:Heating price/MSEK": 7,
    "out:DH+ DHW/kWh/m2": 35,
    "out:El bought/kWh/m2": 2785.46149,
    "out:Return %": 18,
    "out:Return/kSEK/y": 1125,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.6502792112,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 41.7,
    "out:EAHP (El)": 24.922807,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 427399.833382,
    "out:EL kWh savings": -86675.799409,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 254048.23,
    "out:DH kr savings": 427399.83338175714,
    "out:El kr savings": -173351.59881805975,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 112515.104243,
    "out:% savings (space heating)": 72.364665,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 997124.4110739925,
    "out:Eupp": 26.47,
    "out:Etvv": 0,
    "out:Ef": 37.42,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 6405961.264077743,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 12.59944,
    "out:EL CO2": 5.252903,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 5.252903,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02001001000000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 32.434624,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 39.709443,
    "out:Electricity (inc PV)": 39.709443,
    "out:Total Energy Use Pre PV": 71.709443,
    "out:Total Energy Use Post PV": 71.709443,
    "out:Primary Energy": 89.942548,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 18.932654,
    "out:CO2 Operational/m2": 16.761452,
    "out:Total CO2/m2": 35.694106,
    "out:Total CO2 (tons)": 113.649999,
    "out:Klimatpaverkan": -86.485422,
    "out:Initial Cost/MSEK": 6.485561239077743,
    "out:Running cost/(Apt SEK y)": 11927.042553,
    "out:Running Cost over RSP/MSEK": 4.36165,
    "out:LCP/MSEK": 2.557509,
    "out:ROI% old": 19.919126,
    "out:Payback discounted": 6,
    "out:Atemp": 3184,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 72,
    "out:Energy use kWh/m2": 72,
    "out:Energy savings %": 154.2,
    "out:Op energy cost/MSEK": 31,
    "out:El price/MSEK": 23,
    "out:Heating price/MSEK": 6,
    "out:DH+ DHW/kWh/m2": 32,
    "out:El bought/kWh/m2": 2779.66101,
    "out:Return %": 18,
    "out:Return/kSEK/y": 1154,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.6502792112,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 41.7,
    "out:EAHP (El)": 24.839943,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 436643.125881,
    "out:EL kWh savings": -86411.960512,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 79599.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 263819.2,
    "out:DH kr savings": 436643.12588064,
    "out:El kr savings": -172823.92102482717,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 103271.811744,
    "out:% savings (space heating)": 74.634951,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 997124.4110739925,
    "out:Eupp": 23.74,
    "out:Etvv": 0,
    "out:Ef": 37.28,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 6485561.239077743,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 11.519488,
    "out:EL CO2": 5.241964,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 5.241964,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02001001000001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 31.90218,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 38.937909,
    "out:Electricity (inc PV)": 38.937909,
    "out:Total Energy Use Pre PV": 70.937909,
    "out:Total Energy Use Post PV": 70.937909,
    "out:Primary Energy": 89.240195,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 19.641197,
    "out:CO2 Operational/m2": 16.659603,
    "out:Total CO2/m2": 36.3008,
    "out:Total CO2 (tons)": 115.581712,
    "out:Klimatpaverkan": -84.553709,
    "out:Initial Cost/MSEK": 6.679177264077743,
    "out:Running cost/(Apt SEK y)": 11822.510638,
    "out:Running Cost over RSP/MSEK": 4.32366,
    "out:LCP/MSEK": 2.401883,
    "out:ROI% old": 19.422965,
    "out:Payback discounted": 6,
    "out:Atemp": 3184,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 71,
    "out:Energy use kWh/m2": 71,
    "out:Energy savings %": 157.7,
    "out:Op energy cost/MSEK": 30,
    "out:El price/MSEK": 23,
    "out:Heating price/MSEK": 6,
    "out:DH+ DHW/kWh/m2": 32,
    "out:El bought/kWh/m2": 2725.65363,
    "out:Return %": 17,
    "out:Return/kSEK/y": 1159,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.6502792112,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 35.445,
    "out:EAHP (El)": 24.068409,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 438338.427067,
    "out:EL kWh savings": -83955.396996,
    "out:Sewage heat recovery cost": 273216,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 270427.63,
    "out:DH kr savings": 438338.4270665587,
    "out:El kr savings": -167910.79399108628,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 101576.510558,
    "out:% savings (space heating)": 75.051342,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 997124.4110739925,
    "out:Eupp": 24.62,
    "out:Etvv": 0,
    "out:Ef": 36.84,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 6679177.264077743,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 11.519488,
    "out:EL CO2": 5.140115,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 5.140115,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02001001000001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 29.053165,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 38.838673,
    "out:Electricity (inc PV)": 38.838673,
    "out:Total Energy Use Pre PV": 67.838673,
    "out:Total Energy Use Post PV": 67.838673,
    "out:Primary Energy": 86.973222,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 19.641197,
    "out:CO2 Operational/m2": 15.566552,
    "out:Total CO2/m2": 35.207749,
    "out:Total CO2 (tons)": 112.101439,
    "out:Klimatpaverkan": -88.033982,
    "out:Initial Cost/MSEK": 6.758777239077743,
    "out:Running cost/(Apt SEK y)": 11195.297872,
    "out:Running Cost over RSP/MSEK": 4.09314,
    "out:LCP/MSEK": 2.552803,
    "out:ROI% old": 19.681455,
    "out:Payback discounted": 6,
    "out:Atemp": 3184,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 68,
    "out:Energy use kWh/m2": 68,
    "out:Energy savings %": 169.1,
    "out:Op energy cost/MSEK": 29,
    "out:El price/MSEK": 22,
    "out:Heating price/MSEK": 6,
    "out:DH+ DHW/kWh/m2": 29,
    "out:El bought/kWh/m2": 2718.70711,
    "out:Return %": 18,
    "out:Return/kSEK/y": 1189,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.6502792112,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 35.445,
    "out:EAHP (El)": 23.969173,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 447409.688097,
    "out:EL kWh savings": -83639.429667,
    "out:Sewage heat recovery cost": 273216,
    "out:Balancing hydronic system cost": 79599.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 280130.83,
    "out:DH kr savings": 447409.68809720234,
    "out:El kr savings": -167278.85933322253,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 92505.249527,
    "out:% savings (space heating)": 77.279374,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 997124.4110739925,
    "out:Eupp": 21.94,
    "out:Etvv": 0,
    "out:Ef": 36.69,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 6758777.239077743,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 10.439536,
    "out:EL CO2": 5.127016,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 5.127016,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02001001010000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 35.337669,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 39.792307,
    "out:Electricity (inc PV)": 29.838539,
    "out:Total Energy Use Pre PV": 74.792307,
    "out:Total Energy Use Post PV": 64.838539,
    "out:Primary Energy": 74.295887,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 33.671754,
    "out:CO2 Operational/m2": -0.238533,
    "out:Total CO2/m2": 33.433221,
    "out:Total CO2 (tons)": 106.451344,
    "out:Klimatpaverkan": -93.684077,
    "out:Initial Cost/MSEK": 7.196181658300243,
    "out:Running cost/(Apt SEK y)": 10859.744681,
    "out:Running Cost over RSP/MSEK": 3.98584,
    "out:LCP/MSEK": 2.222698,
    "out:ROI% old": 18.69817,
    "out:Payback discounted": 7,
    "out:Atemp": 3184,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 65,
    "out:Energy use kWh/m2": 65,
    "out:Energy savings %": 181.5,
    "out:Op energy cost/MSEK": 29,
    "out:El price/MSEK": 21,
    "out:Heating price/MSEK": 7,
    "out:DH+ DHW/kWh/m2": 35,
    "out:El bought/kWh/m2": 2110.015497,
    "out:Return %": 17,
    "out:Return/kSEK/y": 1204,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.6502792112,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 41.7,
    "out:EAHP (El)": 24.922807,
    "out:PV (kr/kWp)": 17461.56255567083,
    "out:PV (kWp installed)": 45.25485,
    "out:PV (m2 panels)": 237.433632,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 42461.284501,
    "out:PV (kWh sold)": 10768.497684,
    "out:PV (% sold (El))": 25.360744,
    "out:PV (kWh self-consumed)": 31692.786817,
    "out:PV (ratio sold/self-consumed)": 0.339778,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 427399.833382,
    "out:EL kWh savings": -54983.011633,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 333586.56,
    "out:DH kr savings": 427399.83338175714,
    "out:El kr savings": -109966.02326547925,
    "out:El kr sold": 16152.746526625535,
    "out:El kr saved": 63385.573634,
    "out:El kr return": 79538.32016062553,
    "out:% solar/total": 79,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 112515.104243,
    "out:% savings (space heating)": 72.364665,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 997124.4110739925,
    "out:Eupp": 26.47,
    "out:Etvv": 0,
    "out:Ef": 27.81,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 7196181.658300243,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 12.59944,
    "out:EL CO2": -12.837973,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -16.786422,
    "out:Bought CO2": 3.948449,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02001001010000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 32.434624,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 39.709443,
    "out:Electricity (inc PV)": 29.767264,
    "out:Total Energy Use Pre PV": 71.709443,
    "out:Total Energy Use Post PV": 61.767264,
    "out:Primary Energy": 72.046626,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 33.671754,
    "out:CO2 Operational/m2": -1.386871,
    "out:Total CO2/m2": 32.284883,
    "out:Total CO2 (tons)": 102.795037,
    "out:Klimatpaverkan": -97.340385,
    "out:Initial Cost/MSEK": 7.275781633300243,
    "out:Running cost/(Apt SEK y)": 10235.12766,
    "out:Running Cost over RSP/MSEK": 3.75627,
    "out:LCP/MSEK": 2.372668,
    "out:ROI% old": 18.944356,
    "out:Payback discounted": 6,
    "out:Atemp": 3184,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 62,
    "out:Energy use kWh/m2": 62,
    "out:Energy savings %": 195.2,
    "out:Op energy cost/MSEK": 27,
    "out:El price/MSEK": 20,
    "out:Heating price/MSEK": 6,
    "out:DH+ DHW/kWh/m2": 32,
    "out:El bought/kWh/m2": 2104.968468,
    "out:Return %": 17,
    "out:Return/kSEK/y": 1234,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.6502792112,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 41.7,
    "out:EAHP (El)": 24.839943,
    "out:PV (kr/kWp)": 17461.56255567083,
    "out:PV (kWp installed)": 45.25485,
    "out:PV (m2 panels)": 237.433632,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 42461.284501,
    "out:PV (kWh sold)": 10805.394593,
    "out:PV (% sold (El))": 25.447639,
    "out:PV (kWh self-consumed)": 31655.889908,
    "out:PV (ratio sold/self-consumed)": 0.341339,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 436643.125881,
    "out:EL kWh savings": -54756.072101,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 79599.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 343339.07,
    "out:DH kr savings": 436643.12588064,
    "out:El kr savings": -109512.14420204224,
    "out:El kr sold": 16208.091890246189,
    "out:El kr saved": 63311.779816,
    "out:El kr return": 79519.8717062462,
    "out:% solar/total": 79,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 103271.811744,
    "out:% savings (space heating)": 74.634951,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 997124.4110739925,
    "out:Eupp": 23.74,
    "out:Etvv": 0,
    "out:Ef": 27.69,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 7275781.633300243,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 11.519488,
    "out:EL CO2": -12.906359,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -16.84537,
    "out:Bought CO2": 3.939011,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02001001010001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 31.90218,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 38.937909,
    "out:Electricity (inc PV)": 29.104908,
    "out:Total Energy Use Pre PV": 70.937909,
    "out:Total Energy Use Post PV": 61.104908,
    "out:Primary Energy": 71.540793,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 34.380297,
    "out:CO2 Operational/m2": -2.030123,
    "out:Total CO2/m2": 32.350174,
    "out:Total CO2 (tons)": 103.002923,
    "out:Klimatpaverkan": -97.132498,
    "out:Initial Cost/MSEK": 7.469397658300243,
    "out:Running cost/(Apt SEK y)": 10134.297872,
    "out:Running Cost over RSP/MSEK": 3.7196,
    "out:LCP/MSEK": 2.215722,
    "out:ROI% old": 18.523429,
    "out:Payback discounted": 7,
    "out:Atemp": 3184,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 61,
    "out:Energy use kWh/m2": 61,
    "out:Energy savings %": 200,
    "out:Op energy cost/MSEK": 27,
    "out:El price/MSEK": 19,
    "out:Heating price/MSEK": 6,
    "out:DH+ DHW/kWh/m2": 32,
    "out:El bought/kWh/m2": 2058.057707,
    "out:Return %": 17,
    "out:Return/kSEK/y": 1238,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.6502792112,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 35.445,
    "out:EAHP (El)": 24.068409,
    "out:PV (kr/kWp)": 17461.56255567083,
    "out:PV (kWp installed)": 45.25485,
    "out:PV (m2 panels)": 237.433632,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 42461.284501,
    "out:PV (kWh sold)": 11153.017891,
    "out:PV (% sold (El))": 26.266322,
    "out:PV (kWh self-consumed)": 31308.26661,
    "out:PV (ratio sold/self-consumed)": 0.356232,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 438338.427067,
    "out:EL kWh savings": -52647.131232,
    "out:Sewage heat recovery cost": 273216,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 349773.69,
    "out:DH kr savings": 438338.4270665587,
    "out:El kr savings": -105294.26246311623,
    "out:El kr sold": 16729.526837113866,
    "out:El kr saved": 62616.53322,
    "out:El kr return": 79346.06005711386,
    "out:% solar/total": 78,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 101576.510558,
    "out:% savings (space heating)": 75.051342,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 997124.4110739925,
    "out:Eupp": 24.62,
    "out:Etvv": 0,
    "out:Ef": 27.32,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 7469397.658300242,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 11.519488,
    "out:EL CO2": -13.549611,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -17.400923,
    "out:Bought CO2": 3.851312,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02001001010001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 29.053165,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 38.838673,
    "out:Electricity (inc PV)": 29.019926,
    "out:Total Energy Use Pre PV": 67.838673,
    "out:Total Energy Use Post PV": 58.019926,
    "out:Primary Energy": 69.299478,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 34.380297,
    "out:CO2 Operational/m2": -3.193738,
    "out:Total CO2/m2": 31.186559,
    "out:Total CO2 (tons)": 99.297974,
    "out:Klimatpaverkan": -100.837447,
    "out:Initial Cost/MSEK": 7.548997633300243,
    "out:Running cost/(Apt SEK y)": 9507.574468,
    "out:Running Cost over RSP/MSEK": 3.48926,
    "out:LCP/MSEK": 2.366462,
    "out:ROI% old": 18.764005,
    "out:Payback discounted": 7,
    "out:Atemp": 3184,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 58,
    "out:Energy use kWh/m2": 58,
    "out:Energy savings %": 215.5,
    "out:Op energy cost/MSEK": 25,
    "out:El price/MSEK": 18,
    "out:Heating price/MSEK": 6,
    "out:DH+ DHW/kWh/m2": 29,
    "out:El bought/kWh/m2": 2052.035075,
    "out:Return %": 17,
    "out:Return/kSEK/y": 1268,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.6502792112,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 35.445,
    "out:EAHP (El)": 23.969173,
    "out:PV (kr/kWp)": 17461.56255567083,
    "out:PV (kWp installed)": 45.25485,
    "out:PV (m2 panels)": 237.433632,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 42461.284501,
    "out:PV (kWh sold)": 11198.403741,
    "out:PV (% sold (El))": 26.37321,
    "out:PV (kWh self-consumed)": 31262.880761,
    "out:PV (ratio sold/self-consumed)": 0.358201,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 447409.688097,
    "out:EL kWh savings": -52376.548625,
    "out:Sewage heat recovery cost": 273216,
    "out:Balancing hydronic system cost": 79599.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 359454.2,
    "out:DH kr savings": 447409.68809720234,
    "out:El kr savings": -104753.09724994177,
    "out:El kr sold": 16797.605610763218,
    "out:El kr saved": 62525.761522,
    "out:El kr return": 79323.36713276322,
    "out:% solar/total": 78,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 92505.249527,
    "out:% savings (space heating)": 77.279374,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 997124.4110739925,
    "out:Eupp": 21.94,
    "out:Etvv": 0,
    "out:Ef": 27.19,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 7548997.633300243,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 10.439536,
    "out:EL CO2": -13.633274,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -17.473331,
    "out:Bought CO2": 3.840057,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02001001020000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 35.337669,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 39.792307,
    "out:Electricity (inc PV)": 27.022754,
    "out:Total Energy Use Pre PV": 74.792307,
    "out:Total Energy Use Post PV": 62.022754,
    "out:Primary Energy": 69.227474,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 48.410853,
    "out:CO2 Operational/m2": -53.966102,
    "out:Total CO2/m2": -5.555249,
    "out:Total CO2 (tons)": -17.687907,
    "out:Klimatpaverkan": -217.823329,
    "out:Initial Cost/MSEK": 7.986395010021493,
    "out:Running cost/(Apt SEK y)": 9409.212766,
    "out:Running Cost over RSP/MSEK": 3.46708,
    "out:LCP/MSEK": 1.951245,
    "out:ROI% old": 17.776016,
    "out:Payback discounted": 7,
    "out:Atemp": 3184,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 62,
    "out:Energy use kWh/m2": 62,
    "out:Energy savings %": 195.2,
    "out:Op energy cost/MSEK": 25,
    "out:El price/MSEK": 17,
    "out:Heating price/MSEK": 7,
    "out:DH+ DHW/kWh/m2": 35,
    "out:El bought/kWh/m2": 1909.721232,
    "out:Return %": 16,
    "out:Return/kSEK/y": 1272,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.6502792112,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 41.7,
    "out:EAHP (El)": 24.922807,
    "out:PV (kr/kWp)": 17461.48474631725,
    "out:PV (kWp installed)": 90.5097,
    "out:PV (m2 panels)": 474.867263,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 84922.568824,
    "out:PV (kWh sold)": 44264.324368,
    "out:PV (% sold (El))": 52.123158,
    "out:PV (kWh self-consumed)": 40658.244456,
    "out:PV (ratio sold/self-consumed)": 1.088692,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 427399.833382,
    "out:EL kWh savings": -46017.55489,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 401761.21,
    "out:DH kr savings": 427399.83338175714,
    "out:El kr savings": -92035.10978052337,
    "out:El kr sold": 66396.48655193817,
    "out:El kr saved": 81316.488912,
    "out:El kr return": 147712.97546393817,
    "out:% solar/total": 102,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 112515.104243,
    "out:% savings (space heating)": 72.364665,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 997124.4110739925,
    "out:Eupp": 26.47,
    "out:Etvv": 0,
    "out:Ef": 25.2,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 7986395.010021493,
    "out:Seasonal Variation ROI (%)": 5,
    "out:Seasonal Variation Payback": 20,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 12.59944,
    "out:EL CO2": -66.565542,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -70.140819,
    "out:Bought CO2": 3.575277,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02001001020000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 32.434624,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 39.709443,
    "out:Electricity (inc PV)": 26.958818,
    "out:Total Energy Use Pre PV": 71.709443,
    "out:Total Energy Use Post PV": 58.958818,
    "out:Primary Energy": 66.991423,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 48.410853,
    "out:CO2 Operational/m2": -55.151624,
    "out:Total CO2/m2": -6.740771,
    "out:Total CO2 (tons)": -21.462608,
    "out:Klimatpaverkan": -221.598029,
    "out:Initial Cost/MSEK": 8.065994985021494,
    "out:Running cost/(Apt SEK y)": 8784.851064,
    "out:Running Cost over RSP/MSEK": 3.2376,
    "out:LCP/MSEK": 2.101125,
    "out:ROI% old": 18.007025,
    "out:Payback discounted": 7,
    "out:Atemp": 3184,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 59,
    "out:Energy use kWh/m2": 59,
    "out:Energy savings %": 210.2,
    "out:Op energy cost/MSEK": 24,
    "out:El price/MSEK": 16,
    "out:Heating price/MSEK": 6,
    "out:DH+ DHW/kWh/m2": 32,
    "out:El bought/kWh/m2": 1905.191687,
    "out:Return %": 16,
    "out:Return/kSEK/y": 1302,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.6502792112,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 41.7,
    "out:EAHP (El)": 24.839943,
    "out:PV (kr/kWp)": 17461.48474631725,
    "out:PV (kWp installed)": 90.5097,
    "out:PV (m2 panels)": 474.867263,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 84922.568824,
    "out:PV (kWh sold)": 44324.590784,
    "out:PV (% sold (El))": 52.194124,
    "out:PV (kWh self-consumed)": 40597.97804,
    "out:PV (ratio sold/self-consumed)": 1.091793,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 436643.125881,
    "out:EL kWh savings": -45813.982728,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 79599.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 411502.05,
    "out:DH kr savings": 436643.12588064,
    "out:El kr savings": -91627.96545502477,
    "out:El kr sold": 66486.88617640603,
    "out:El kr saved": 81195.95608,
    "out:El kr return": 147682.84225640603,
    "out:% solar/total": 101,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 103271.811744,
    "out:% savings (space heating)": 74.634951,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 997124.4110739925,
    "out:Eupp": 23.74,
    "out:Etvv": 0,
    "out:Ef": 25.1,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 8065994.985021493,
    "out:Seasonal Variation ROI (%)": 5,
    "out:Seasonal Variation Payback": 20,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 11.519488,
    "out:EL CO2": -66.671112,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -70.237924,
    "out:Bought CO2": 3.566812,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02001001020001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 31.90218,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 38.937909,
    "out:Electricity (inc PV)": 26.364813,
    "out:Total Energy Use Pre PV": 70.937909,
    "out:Total Energy Use Post PV": 58.364813,
    "out:Primary Energy": 66.608622,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 49.119396,
    "out:CO2 Operational/m2": -56.140932,
    "out:Total CO2/m2": -7.021536,
    "out:Total CO2 (tons)": -22.356564,
    "out:Klimatpaverkan": -222.491985,
    "out:Initial Cost/MSEK": 8.259611010021493,
    "out:Running cost/(Apt SEK y)": 8686.340426,
    "out:Running Cost over RSP/MSEK": 3.20177,
    "out:LCP/MSEK": 1.943339,
    "out:ROI% old": 17.646888,
    "out:Payback discounted": 7,
    "out:Atemp": 3184,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 58,
    "out:Energy use kWh/m2": 58,
    "out:Energy savings %": 215.5,
    "out:Op energy cost/MSEK": 23,
    "out:El price/MSEK": 16,
    "out:Heating price/MSEK": 6,
    "out:DH+ DHW/kWh/m2": 32,
    "out:El bought/kWh/m2": 1863.096123,
    "out:Return %": 16,
    "out:Return/kSEK/y": 1306,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.6502792112,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 35.445,
    "out:EAHP (El)": 24.068409,
    "out:PV (kr/kWp)": 17461.48474631725,
    "out:PV (kWp installed)": 90.5097,
    "out:PV (m2 panels)": 474.867263,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 84922.568824,
    "out:PV (kWh sold)": 44889.842672,
    "out:PV (% sold (El))": 52.859732,
    "out:PV (kWh self-consumed)": 40032.726152,
    "out:PV (ratio sold/self-consumed)": 1.121329,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 438338.427067,
    "out:EL kWh savings": -43922.671377,
    "out:Sewage heat recovery cost": 273216,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 417827.85,
    "out:DH kr savings": 438338.4270665587,
    "out:El kr savings": -87845.34275313828,
    "out:El kr sold": 67334.76400867903,
    "out:El kr saved": 80065.452304,
    "out:El kr return": 147400.21631267905,
    "out:% solar/total": 100,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 101576.510558,
    "out:% savings (space heating)": 75.051342,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 997124.4110739925,
    "out:Eupp": 24.62,
    "out:Etvv": 0,
    "out:Ef": 24.76,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 8259611.010021493,
    "out:Seasonal Variation ROI (%)": 5,
    "out:Seasonal Variation Payback": 20,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 11.519488,
    "out:EL CO2": -67.66042,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -71.148574,
    "out:Bought CO2": 3.488154,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02001001020001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 29.053165,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 38.838673,
    "out:Electricity (inc PV)": 26.288547,
    "out:Total Energy Use Pre PV": 67.838673,
    "out:Total Energy Use Post PV": 55.288547,
    "out:Primary Energy": 64.382996,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 49.119396,
    "out:CO2 Operational/m2": -57.348951,
    "out:Total CO2/m2": -8.229555,
    "out:Total CO2 (tons)": -26.202895,
    "out:Klimatpaverkan": -226.338316,
    "out:Initial Cost/MSEK": 8.339210985021493,
    "out:Running cost/(Apt SEK y)": 8059.914894,
    "out:Running Cost over RSP/MSEK": 2.97154,
    "out:LCP/MSEK": 2.093969,
    "out:ROI% old": 17.872845,
    "out:Payback discounted": 7,
    "out:Atemp": 3184,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 55,
    "out:Energy use kWh/m2": 55,
    "out:Energy savings %": 232.7,
    "out:Op energy cost/MSEK": 22,
    "out:El price/MSEK": 15,
    "out:Heating price/MSEK": 6,
    "out:DH+ DHW/kWh/m2": 29,
    "out:El bought/kWh/m2": 1857.692219,
    "out:Return %": 16,
    "out:Return/kSEK/y": 1336,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.6502792112,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 35.445,
    "out:EAHP (El)": 23.969173,
    "out:PV (kr/kWp)": 17461.48474631725,
    "out:PV (kWp installed)": 90.5097,
    "out:PV (m2 panels)": 474.867263,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 84922.568824,
    "out:PV (kWh sold)": 44962.981178,
    "out:PV (% sold (El))": 52.945856,
    "out:PV (kWh self-consumed)": 39959.587646,
    "out:PV (ratio sold/self-consumed)": 1.125211,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 447409.688097,
    "out:EL kWh savings": -43679.840506,
    "out:Sewage heat recovery cost": 273216,
    "out:Balancing hydronic system cost": 79599.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 427494.48,
    "out:DH kr savings": 447409.68809720234,
    "out:El kr savings": -87359.6810112639,
    "out:El kr sold": 67444.47176654307,
    "out:El kr saved": 79919.175292,
    "out:El kr return": 147363.64705854305,
    "out:% solar/total": 100,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 92505.249527,
    "out:% savings (space heating)": 77.279374,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 997124.4110739925,
    "out:Eupp": 21.94,
    "out:Etvv": 0,
    "out:Ef": 24.65,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 8339210.985021493,
    "out:Seasonal Variation ROI (%)": 5,
    "out:Seasonal Variation Payback": 20,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 10.439536,
    "out:EL CO2": -67.788487,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -71.266546,
    "out:Bought CO2": 3.478059,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02001001100000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 30.785827,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 38.64438,
    "out:Electricity (inc PV)": 38.64438,
    "out:Total Energy Use Pre PV": 69.64438,
    "out:Total Energy Use Post PV": 69.64438,
    "out:Primary Energy": 88.241612,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 18.932654,
    "out:CO2 Operational/m2": 16.260871,
    "out:Total CO2/m2": 35.193525,
    "out:Total CO2 (tons)": 112.05615,
    "out:Klimatpaverkan": -88.079271,
    "out:Initial Cost/MSEK": 6.530008951577743,
    "out:Running cost/(Apt SEK y)": 11578.148936,
    "out:Running Cost over RSP/MSEK": 4.234,
    "out:LCP/MSEK": 2.640711,
    "out:ROI% old": 20.062803,
    "out:Payback discounted": 6,
    "out:Atemp": 3184,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 70,
    "out:Energy use kWh/m2": 70,
    "out:Energy savings %": 161.4,
    "out:Op energy cost/MSEK": 30,
    "out:El price/MSEK": 23,
    "out:Heating price/MSEK": 6,
    "out:DH+ DHW/kWh/m2": 31,
    "out:El bought/kWh/m2": 2705.1066,
    "out:Return %": 18,
    "out:Return/kSEK/y": 1171,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.6502792112,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 33.36,
    "out:EAHP (El)": 23.77488,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 441892.893949,
    "out:EL kWh savings": -83020.800941,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 275851.29,
    "out:DH kr savings": 441892.8939490925,
    "out:El kr savings": -166041.6018814879,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 98022.043675,
    "out:% savings (space heating)": 75.92437,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 997124.4110739925,
    "out:Eupp": 24.02,
    "out:Etvv": 0,
    "out:Ef": 36.65,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 6530008.9515777435,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 11.159504,
    "out:EL CO2": 5.101367,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 5.101367,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02001001100000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 27.956227,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 38.53926,
    "out:Electricity (inc PV)": 38.53926,
    "out:Total Energy Use Pre PV": 66.53926,
    "out:Total Energy Use Post PV": 66.53926,
    "out:Primary Energy": 85.976126,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 18.932654,
    "out:CO2 Operational/m2": 15.167043,
    "out:Total CO2/m2": 34.099697,
    "out:Total CO2 (tons)": 108.573403,
    "out:Klimatpaverkan": -91.562018,
    "out:Initial Cost/MSEK": 6.609608926577744,
    "out:Running cost/(Apt SEK y)": 10950.148936,
    "out:Running Cost over RSP/MSEK": 4.00319,
    "out:LCP/MSEK": 2.791921,
    "out:ROI% old": 20.320048,
    "out:Payback discounted": 6,
    "out:Atemp": 3184,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 67,
    "out:Energy use kWh/m2": 67,
    "out:Energy savings %": 173.1,
    "out:Op energy cost/MSEK": 28,
    "out:El price/MSEK": 22,
    "out:Heating price/MSEK": 5,
    "out:DH+ DHW/kWh/m2": 28,
    "out:El bought/kWh/m2": 2697.7482,
    "out:Return %": 18,
    "out:Return/kSEK/y": 1200,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.6502792112,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 33.36,
    "out:EAHP (El)": 23.66976,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 450902.337638,
    "out:EL kWh savings": -82686.098961,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 79599.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 285530.14,
    "out:DH kr savings": 450902.33763833577,
    "out:El kr savings": -165372.19792289782,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 89012.599986,
    "out:% savings (space heating)": 78.13722,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 997124.4110739925,
    "out:Eupp": 21.35,
    "out:Etvv": 0,
    "out:Ef": 36.49,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 6609608.926577744,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 10.079552,
    "out:EL CO2": 5.087491,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 5.087491,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02001001100001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 28.168168,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 37.921246,
    "out:Electricity (inc PV)": 37.921246,
    "out:Total Energy Use Pre PV": 65.921246,
    "out:Total Energy Use Post PV": 65.921246,
    "out:Primary Energy": 85.829029,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 19.641197,
    "out:CO2 Operational/m2": 15.08546,
    "out:Total CO2/m2": 34.726657,
    "out:Total CO2 (tons)": 110.569643,
    "out:Klimatpaverkan": -89.565778,
    "out:Initial Cost/MSEK": 6.803224951577744,
    "out:Running cost/(Apt SEK y)": 10866.404255,
    "out:Running Cost over RSP/MSEK": 3.97277,
    "out:LCP/MSEK": 2.628725,
    "out:ROI% old": 19.805628,
    "out:Payback discounted": 6,
    "out:Atemp": 3184,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 66,
    "out:Energy use kWh/m2": 66,
    "out:Energy savings %": 177.3,
    "out:Op energy cost/MSEK": 28,
    "out:El price/MSEK": 21,
    "out:Heating price/MSEK": 5,
    "out:DH+ DHW/kWh/m2": 28,
    "out:El bought/kWh/m2": 2654.48722,
    "out:Return %": 18,
    "out:Return/kSEK/y": 1204,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.6502792112,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 28.356,
    "out:EAHP (El)": 23.051746,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 450227.517697,
    "out:EL kWh savings": -80718.342978,
    "out:Sewage heat recovery cost": 273216,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 288790.83,
    "out:DH kr savings": 450227.5176973752,
    "out:El kr savings": -161436.68595501262,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 89687.419927,
    "out:% savings (space heating)": 77.971474,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 997124.4110739925,
    "out:Eupp": 22.59,
    "out:Etvv": 0,
    "out:Ef": 36.17,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 6803224.9515777435,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 10.079552,
    "out:EL CO2": 5.005908,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 5.005908,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02001001100001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 25.396621,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 37.798534,
    "out:Electricity (inc PV)": 37.798534,
    "out:Total Energy Use Pre PV": 62.798534,
    "out:Total Energy Use Post PV": 62.798534,
    "out:Primary Energy": 83.564423,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 19.641197,
    "out:CO2 Operational/m2": 13.989309,
    "out:Total CO2/m2": 33.630506,
    "out:Total CO2 (tons)": 107.079499,
    "out:Klimatpaverkan": -93.055922,
    "out:Initial Cost/MSEK": 6.882824926577743,
    "out:Running cost/(Apt SEK y)": 10236.021277,
    "out:Running Cost over RSP/MSEK": 3.74109,
    "out:LCP/MSEK": 2.780805,
    "out:ROI% old": 20.057441,
    "out:Payback discounted": 6,
    "out:Atemp": 3184,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 63,
    "out:Energy use kWh/m2": 63,
    "out:Energy savings %": 190.5,
    "out:Op energy cost/MSEK": 26,
    "out:El price/MSEK": 20,
    "out:Heating price/MSEK": 5,
    "out:DH+ DHW/kWh/m2": 25,
    "out:El bought/kWh/m2": 2645.89738,
    "out:Return %": 18,
    "out:Return/kSEK/y": 1234,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.6502792112,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 28.356,
    "out:EAHP (El)": 22.929034,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 459052.12069,
    "out:EL kWh savings": -80327.628087,
    "out:Sewage heat recovery cost": 273216,
    "out:Balancing hydronic system cost": 79599.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 298396.86,
    "out:DH kr savings": 459052.12069023313,
    "out:El kr savings": -160655.25617412882,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 80862.816934,
    "out:% savings (space heating)": 80.138924,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 997124.4110739925,
    "out:Eupp": 19.96,
    "out:Etvv": 0,
    "out:Ef": 36,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 6882824.926577743,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 8.9996,
    "out:EL CO2": 4.989709,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 4.989709,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02001001110000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 30.785827,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 38.64438,
    "out:Electricity (inc PV)": 28.853666,
    "out:Total Energy Use Pre PV": 69.64438,
    "out:Total Energy Use Post PV": 59.853666,
    "out:Primary Energy": 70.618327,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 33.671754,
    "out:CO2 Operational/m2": -2.638152,
    "out:Total CO2/m2": 31.033602,
    "out:Total CO2 (tons)": 98.810959,
    "out:Klimatpaverkan": -101.324462,
    "out:Initial Cost/MSEK": 7.320229345800243,
    "out:Running cost/(Apt SEK y)": 9891.382979,
    "out:Running Cost over RSP/MSEK": 3.63045,
    "out:LCP/MSEK": 2.454041,
    "out:ROI% old": 19.074871,
    "out:Payback discounted": 6,
    "out:Atemp": 3184,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 60,
    "out:Energy use kWh/m2": 60,
    "out:Energy savings %": 205,
    "out:Op energy cost/MSEK": 26,
    "out:El price/MSEK": 19,
    "out:Heating price/MSEK": 6,
    "out:DH+ DHW/kWh/m2": 31,
    "out:El bought/kWh/m2": 2040.250978,
    "out:Return %": 17,
    "out:Return/kSEK/y": 1250,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.6502792112,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 33.36,
    "out:EAHP (El)": 23.77488,
    "out:PV (kr/kWp)": 17461.56255567083,
    "out:PV (kWp installed)": 45.25485,
    "out:PV (m2 panels)": 237.433632,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 42461.284501,
    "out:PV (kWh sold)": 11287.660267,
    "out:PV (% sold (El))": 26.583417,
    "out:PV (kWh self-consumed)": 31173.624234,
    "out:PV (ratio sold/self-consumed)": 0.36209,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 441892.893949,
    "out:EL kWh savings": -51847.176944,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 355130.03,
    "out:DH kr savings": 441892.8939490925,
    "out:El kr savings": -103694.35388849597,
    "out:El kr sold": 16931.490400449493,
    "out:El kr saved": 62347.248468,
    "out:El kr return": 79278.7388684495,
    "out:% solar/total": 78,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 98022.043675,
    "out:% savings (space heating)": 75.92437,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 997124.4110739925,
    "out:Eupp": 24.02,
    "out:Etvv": 0,
    "out:Ef": 27.15,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 7320229.345800243,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 11.159504,
    "out:EL CO2": -13.797656,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -17.615691,
    "out:Bought CO2": 3.818035,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02001001110000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 27.956227,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 38.53926,
    "out:Electricity (inc PV)": 28.763798,
    "out:Total Energy Use Pre PV": 66.53926,
    "out:Total Energy Use Post PV": 56.763798,
    "out:Primary Energy": 68.380294,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 33.671754,
    "out:CO2 Operational/m2": -3.807386,
    "out:Total CO2/m2": 29.864368,
    "out:Total CO2 (tons)": 95.088119,
    "out:Klimatpaverkan": -105.047302,
    "out:Initial Cost/MSEK": 7.399829320800244,
    "out:Running cost/(Apt SEK y)": 9263.87234,
    "out:Running Cost over RSP/MSEK": 3.39982,
    "out:LCP/MSEK": 2.605071,
    "out:ROI% old": 19.314924,
    "out:Payback discounted": 6,
    "out:Atemp": 3184,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 57,
    "out:Energy use kWh/m2": 57,
    "out:Energy savings %": 221.1,
    "out:Op energy cost/MSEK": 24,
    "out:El price/MSEK": 18,
    "out:Heating price/MSEK": 5,
    "out:DH+ DHW/kWh/m2": 28,
    "out:El bought/kWh/m2": 2033.879558,
    "out:Return %": 17,
    "out:Return/kSEK/y": 1279,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.6502792112,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 33.36,
    "out:EAHP (El)": 23.66976,
    "out:PV (kr/kWp)": 17461.56255567083,
    "out:PV (kWp installed)": 45.25485,
    "out:PV (m2 panels)": 237.433632,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 42461.284501,
    "out:PV (kWh sold)": 11336.221669,
    "out:PV (% sold (El))": 26.697783,
    "out:PV (kWh self-consumed)": 31125.062832,
    "out:PV (ratio sold/self-consumed)": 0.364215,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 450902.337638,
    "out:EL kWh savings": -51561.037318,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 79599.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 364784.6,
    "out:DH kr savings": 450902.33763833577,
    "out:El kr savings": -103122.07463668298,
    "out:El kr sold": 17004.332503579786,
    "out:El kr saved": 62250.125664,
    "out:El kr return": 79254.45816757978,
    "out:% solar/total": 78,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 89012.599986,
    "out:% savings (space heating)": 78.13722,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 997124.4110739925,
    "out:Eupp": 21.35,
    "out:Etvv": 0,
    "out:Ef": 27.02,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 7399829.320800244,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 10.079552,
    "out:EL CO2": -13.886938,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -17.693067,
    "out:Bought CO2": 3.806129,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02001001110001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 28.168168,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 37.921246,
    "out:Electricity (inc PV)": 28.236438,
    "out:Total Energy Use Pre PV": 65.921246,
    "out:Total Energy Use Post PV": 56.236438,
    "out:Primary Energy": 68.396374,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 34.380297,
    "out:CO2 Operational/m2": -4.337639,
    "out:Total CO2/m2": 30.042658,
    "out:Total CO2 (tons)": 95.655794,
    "out:Klimatpaverkan": -104.479627,
    "out:Initial Cost/MSEK": 7.5934453458002436,
    "out:Running cost/(Apt SEK y)": 9183.212766,
    "out:Running Cost over RSP/MSEK": 3.37049,
    "out:LCP/MSEK": 2.440785,
    "out:ROI% old": 18.877616,
    "out:Payback discounted": 7,
    "out:Atemp": 3184,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 56,
    "out:Energy use kWh/m2": 56,
    "out:Energy savings %": 226.8,
    "out:Op energy cost/MSEK": 24,
    "out:El price/MSEK": 18,
    "out:Heating price/MSEK": 5,
    "out:DH+ DHW/kWh/m2": 28,
    "out:El bought/kWh/m2": 1996.483816,
    "out:Return %": 17,
    "out:Return/kSEK/y": 1283,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.6502792112,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 28.356,
    "out:EAHP (El)": 23.051746,
    "out:PV (kr/kWp)": 17461.56255567083,
    "out:PV (kWp installed)": 45.25485,
    "out:PV (m2 panels)": 237.433632,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 42461.284501,
    "out:PV (kWh sold)": 11624.864314,
    "out:PV (% sold (El))": 27.377562,
    "out:PV (kWh self-consumed)": 30836.420188,
    "out:PV (ratio sold/self-consumed)": 0.376985,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 450227.517697,
    "out:EL kWh savings": -49881.923584,
    "out:Sewage heat recovery cost": 273216,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 367900.97,
    "out:DH kr savings": 450227.5176973752,
    "out:El kr savings": -99763.84716710474,
    "out:El kr sold": 17437.29647032992,
    "out:El kr saved": 61672.840376,
    "out:El kr return": 79110.13684632991,
    "out:% solar/total": 77,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 89687.419927,
    "out:% savings (space heating)": 77.971474,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 997124.4110739925,
    "out:Eupp": 22.59,
    "out:Etvv": 0,
    "out:Ef": 26.75,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 7593445.345800243,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 10.079552,
    "out:EL CO2": -14.417191,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -18.153464,
    "out:Bought CO2": 3.736273,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02001001110001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 25.396621,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 37.798534,
    "out:Electricity (inc PV)": 28.131919,
    "out:Total Energy Use Pre PV": 62.798534,
    "out:Total Energy Use Post PV": 53.131919,
    "out:Primary Energy": 66.164516,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 34.380297,
    "out:CO2 Operational/m2": -5.523955,
    "out:Total CO2/m2": 28.856342,
    "out:Total CO2 (tons)": 91.878565,
    "out:Klimatpaverkan": -108.256856,
    "out:Initial Cost/MSEK": 7.673045320800244,
    "out:Running cost/(Apt SEK y)": 8553.446809,
    "out:Running Cost over RSP/MSEK": 3.13904,
    "out:LCP/MSEK": 2.592635,
    "out:ROI% old": 19.112695,
    "out:Payback discounted": 6,
    "out:Atemp": 3184,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 53,
    "out:Energy use kWh/m2": 53,
    "out:Energy savings %": 245.3,
    "out:Op energy cost/MSEK": 22,
    "out:El price/MSEK": 17,
    "out:Heating price/MSEK": 5,
    "out:DH+ DHW/kWh/m2": 25,
    "out:El bought/kWh/m2": 1989.071492,
    "out:Return %": 17,
    "out:Return/kSEK/y": 1313,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.6502792112,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 28.356,
    "out:EAHP (El)": 22.929034,
    "out:PV (kr/kWp)": 17461.56255567083,
    "out:PV (kWp installed)": 45.25485,
    "out:PV (m2 panels)": 237.433632,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 42461.284501,
    "out:PV (kWh sold)": 11682.791247,
    "out:PV (% sold (El))": 27.513985,
    "out:PV (kWh self-consumed)": 30778.493254,
    "out:PV (ratio sold/self-consumed)": 0.379576,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 459052.12069,
    "out:EL kWh savings": -49549.135188,
    "out:Sewage heat recovery cost": 273216,
    "out:Balancing hydronic system cost": 79599.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 377478.04,
    "out:DH kr savings": 459052.12069023313,
    "out:El kr savings": -99098.27037536321,
    "out:El kr sold": 17524.186870722224,
    "out:El kr saved": 61556.986508,
    "out:El kr return": 79081.17337872222,
    "out:% solar/total": 77,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 80862.816934,
    "out:% savings (space heating)": 80.138924,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 997124.4110739925,
    "out:Eupp": 19.96,
    "out:Etvv": 0,
    "out:Ef": 26.61,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 7673045.320800244,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 8.9996,
    "out:EL CO2": -14.523555,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -18.245985,
    "out:Bought CO2": 3.72243,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02001001120000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 30.785827,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 38.64438,
    "out:Electricity (inc PV)": 26.139357,
    "out:Total Energy Use Pre PV": 69.64438,
    "out:Total Energy Use Post PV": 57.139357,
    "out:Primary Energy": 65.732571,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 48.410853,
    "out:CO2 Operational/m2": -56.880233,
    "out:Total CO2/m2": -8.46938,
    "out:Total CO2 (tons)": -26.966498,
    "out:Klimatpaverkan": -227.101919,
    "out:Initial Cost/MSEK": 8.110442697521494,
    "out:Running cost/(Apt SEK y)": 8444.276596,
    "out:Running Cost over RSP/MSEK": 3.11294,
    "out:LCP/MSEK": 2.181337,
    "out:ROI% old": 18.127917,
    "out:Payback discounted": 7,
    "out:Atemp": 3184,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 57,
    "out:Energy use kWh/m2": 57,
    "out:Energy savings %": 221.1,
    "out:Op energy cost/MSEK": 23,
    "out:El price/MSEK": 16,
    "out:Heating price/MSEK": 6,
    "out:DH+ DHW/kWh/m2": 31,
    "out:El bought/kWh/m2": 1847.119174,
    "out:Return %": 16,
    "out:Return/kSEK/y": 1318,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.6502792112,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 33.36,
    "out:EAHP (El)": 23.77488,
    "out:PV (kr/kWp)": 17461.48474631725,
    "out:PV (kWp installed)": 90.5097,
    "out:PV (m2 panels)": 474.867263,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 84922.568824,
    "out:PV (kWh sold)": 45106.586108,
    "out:PV (% sold (El))": 53.114957,
    "out:PV (kWh self-consumed)": 39815.982716,
    "out:PV (ratio sold/self-consumed)": 1.132876,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 441892.893949,
    "out:EL kWh savings": -43204.819689,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 423143.13,
    "out:DH kr savings": 441892.8939490925,
    "out:El kr savings": -86409.639377112,
    "out:El kr sold": 67659.8791625153,
    "out:El kr saved": 79631.965432,
    "out:El kr return": 147291.84459451528,
    "out:% solar/total": 99,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 98022.043675,
    "out:% savings (space heating)": 75.92437,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 997124.4110739925,
    "out:Eupp": 24.02,
    "out:Etvv": 0,
    "out:Ef": 24.61,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 8110442.697521494,
    "out:Seasonal Variation ROI (%)": 5,
    "out:Seasonal Variation Payback": 20,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 11.159504,
    "out:EL CO2": -68.039737,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -71.498043,
    "out:Bought CO2": 3.458306,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02001001120000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 27.956227,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 38.53926,
    "out:Electricity (inc PV)": 26.058704,
    "out:Total Energy Use Pre PV": 66.53926,
    "out:Total Energy Use Post PV": 54.058704,
    "out:Primary Energy": 63.511125,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 48.410853,
    "out:CO2 Operational/m2": -58.096415,
    "out:Total CO2/m2": -9.685562,
    "out:Total CO2 (tons)": -30.83882,
    "out:Klimatpaverkan": -230.974241,
    "out:Initial Cost/MSEK": 8.190042672521495,
    "out:Running cost/(Apt SEK y)": 7817.106383,
    "out:Running Cost over RSP/MSEK": 2.88243,
    "out:LCP/MSEK": 2.332247,
    "out:ROI% old": 18.353803,
    "out:Payback discounted": 7,
    "out:Atemp": 3184,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 54,
    "out:Energy use kWh/m2": 54,
    "out:Energy savings %": 238.9,
    "out:Op energy cost/MSEK": 21,
    "out:El price/MSEK": 15,
    "out:Heating price/MSEK": 5,
    "out:DH+ DHW/kWh/m2": 28,
    "out:El bought/kWh/m2": 1841.402728,
    "out:Return %": 16,
    "out:Return/kSEK/y": 1347,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.6502792112,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 33.36,
    "out:EAHP (El)": 23.66976,
    "out:PV (kr/kWp)": 17461.48474631725,
    "out:PV (kWp installed)": 90.5097,
    "out:PV (m2 panels)": 474.867263,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 84922.568824,
    "out:PV (kWh sold)": 45184.489678,
    "out:PV (% sold (El))": 53.206692,
    "out:PV (kWh self-consumed)": 39738.079146,
    "out:PV (ratio sold/self-consumed)": 1.137058,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 450902.337638,
    "out:EL kWh savings": -42948.020614,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 79599.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 432783.03,
    "out:DH kr savings": 450902.33763833577,
    "out:El kr savings": -85896.04122764307,
    "out:El kr sold": 67776.73451699625,
    "out:El kr saved": 79476.158292,
    "out:El kr return": 147252.89280899626,
    "out:% solar/total": 99,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 89012.599986,
    "out:% savings (space heating)": 78.13722,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 997124.4110739925,
    "out:Eupp": 21.35,
    "out:Etvv": 0,
    "out:Ef": 24.5,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 8190042.672521494,
    "out:Seasonal Variation ROI (%)": 5,
    "out:Seasonal Variation Payback": 20,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 10.079552,
    "out:EL CO2": -68.175967,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -71.623593,
    "out:Bought CO2": 3.447626,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02001001120001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 28.168168,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 37.921246,
    "out:Electricity (inc PV)": 25.584994,
    "out:Total Energy Use Pre PV": 65.921246,
    "out:Total Energy Use Post PV": 53.584994,
    "out:Primary Energy": 63.623775,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 49.119396,
    "out:CO2 Operational/m2": -58.901089,
    "out:Total CO2/m2": -9.781693,
    "out:Total CO2 (tons)": -31.144901,
    "out:Klimatpaverkan": -231.280322,
    "out:Initial Cost/MSEK": 8.383658697521494,
    "out:Running cost/(Apt SEK y)": 7738.255319,
    "out:Running Cost over RSP/MSEK": 2.85377,
    "out:LCP/MSEK": 2.167291,
    "out:ROI% old": 17.978768,
    "out:Payback discounted": 7,
    "out:Atemp": 3184,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 54,
    "out:Energy use kWh/m2": 54,
    "out:Energy savings %": 238.9,
    "out:Op energy cost/MSEK": 21,
    "out:El price/MSEK": 15,
    "out:Heating price/MSEK": 5,
    "out:DH+ DHW/kWh/m2": 28,
    "out:El bought/kWh/m2": 1807.850153,
    "out:Return %": 16,
    "out:Return/kSEK/y": 1351,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.6502792112,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 28.356,
    "out:EAHP (El)": 23.051746,
    "out:PV (kr/kWp)": 17461.48474631725,
    "out:PV (kWp installed)": 90.5097,
    "out:PV (m2 panels)": 474.867263,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 84922.568824,
    "out:PV (kWh sold)": 45643.955602,
    "out:PV (% sold (El))": 53.747733,
    "out:PV (kWh self-consumed)": 39278.613222,
    "out:PV (ratio sold/self-consumed)": 1.162056,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 450227.517697,
    "out:EL kWh savings": -41439.728428,
    "out:Sewage heat recovery cost": 273216,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 435813.99,
    "out:DH kr savings": 450227.5176973752,
    "out:El kr savings": -82879.45685527146,
    "out:El kr sold": 68465.93340342028,
    "out:El kr saved": 78557.226444,
    "out:El kr return": 147023.1598474203,
    "out:% solar/total": 98,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 89687.419927,
    "out:% savings (space heating)": 77.971474,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 997124.4110739925,
    "out:Eupp": 22.59,
    "out:Etvv": 0,
    "out:Ef": 24.25,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 8383658.697521494,
    "out:Seasonal Variation ROI (%)": 5,
    "out:Seasonal Variation Payback": 20,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 10.079552,
    "out:EL CO2": -68.980641,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -72.36558,
    "out:Bought CO2": 3.384939,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02001001120001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 25.396621,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 37.798534,
    "out:Electricity (inc PV)": 25.491103,
    "out:Total Energy Use Pre PV": 62.798534,
    "out:Total Energy Use Post PV": 50.491103,
    "out:Primary Energy": 61.411047,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 49.119396,
    "out:CO2 Operational/m2": -60.141578,
    "out:Total CO2/m2": -11.022182,
    "out:Total CO2 (tons)": -35.094617,
    "out:Klimatpaverkan": -235.230038,
    "out:Initial Cost/MSEK": 8.463258672521494,
    "out:Running cost/(Apt SEK y)": 7108.851064,
    "out:Running Cost over RSP/MSEK": 2.62244,
    "out:LCP/MSEK": 2.319021,
    "out:ROI% old": 18.200149,
    "out:Payback discounted": 7,
    "out:Atemp": 3184,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 50,
    "out:Energy use kWh/m2": 50,
    "out:Energy savings %": 266,
    "out:Op energy cost/MSEK": 19,
    "out:El price/MSEK": 14,
    "out:Heating price/MSEK": 5,
    "out:DH+ DHW/kWh/m2": 25,
    "out:El bought/kWh/m2": 1801.199378,
    "out:Return %": 16,
    "out:Return/kSEK/y": 1381,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.6502792112,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 28.356,
    "out:EAHP (El)": 22.929034,
    "out:PV (kr/kWp)": 17461.48474631725,
    "out:PV (kWp installed)": 90.5097,
    "out:PV (m2 panels)": 474.867263,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 84922.568824,
    "out:PV (kWh sold)": 45735.721463,
    "out:PV (% sold (El))": 53.855791,
    "out:PV (kWh self-consumed)": 39186.847361,
    "out:PV (ratio sold/self-consumed)": 1.167119,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 459052.12069,
    "out:EL kWh savings": -41140.779574,
    "out:Sewage heat recovery cost": 273216,
    "out:Balancing hydronic system cost": 79599.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 445374.14,
    "out:DH kr savings": 459052.12069023313,
    "out:El kr savings": -82281.55914716667,
    "out:El kr sold": 68603.58219519915,
    "out:El kr saved": 78373.694722,
    "out:El kr return": 146977.27691719914,
    "out:% solar/total": 98,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 80862.816934,
    "out:% savings (space heating)": 80.138924,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 997124.4110739925,
    "out:Eupp": 19.96,
    "out:Etvv": 0,
    "out:Ef": 24.12,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 8463258.672521494,
    "out:Seasonal Variation ROI (%)": 5,
    "out:Seasonal Variation Payback": 20,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 8.9996,
    "out:EL CO2": -69.141178,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -72.51369,
    "out:Bought CO2": 3.372512,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02001002000000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 1.321757,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 50.100159,
    "out:Electricity (inc PV)": 50.100159,
    "out:Total Energy Use Pre PV": 51.100159,
    "out:Total Energy Use Post PV": 51.100159,
    "out:Primary Energy": 90.540152,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 19.476386,
    "out:CO2 Operational/m2": 6.973606,
    "out:Total CO2/m2": 26.449992,
    "out:Total CO2 (tons)": 84.216749,
    "out:Klimatpaverkan": -115.918672,
    "out:Initial Cost/MSEK": 7.997086264077742,
    "out:Running cost/(Apt SEK y)": 6992.617021,
    "out:Running Cost over RSP/MSEK": 2.54163,
    "out:LCP/MSEK": 2.866004,
    "out:ROI% old": 19.405443,
    "out:Payback discounted": 6,
    "out:Atemp": 3184,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 51,
    "out:Energy use kWh/m2": 51,
    "out:Energy savings %": 258.8,
    "out:Op energy cost/MSEK": 17,
    "out:El price/MSEK": 17,
    "out:Heating price/MSEK": 0,
    "out:DH+ DHW/kWh/m2": 1,
    "out:El bought/kWh/m2": 3507.01113,
    "out:Return %": 17,
    "out:Return/kSEK/y": 1386,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 1774800,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 88740,
    "out:EAHP (replacement cost)": 1325203.921671023,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 41.7,
    "out:EAHP (El)": 35.230659,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 535706.464603,
    "out:EL kWh savings": -119495.990302,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 296714.48,
    "out:DH kr savings": 535706.4646025135,
    "out:El kr savings": -238991.98060421532,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 4208.473022,
    "out:% savings (space heating)": 98.966338,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 997124.4110739925,
    "out:Eupp": 0.46,
    "out:Etvv": 0,
    "out:Ef": 45.3,
    "out:heatPumpQuantity": 12,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 7997086.264077743,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 0.359984,
    "out:EL CO2": 6.613622,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 6.613622,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02001002000000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 0.817205,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 49.290478,
    "out:Electricity (inc PV)": 49.290478,
    "out:Total Energy Use Pre PV": 50.290478,
    "out:Total Energy Use Post PV": 50.290478,
    "out:Primary Energy": 88.924255,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 19.476386,
    "out:CO2 Operational/m2": 6.866721,
    "out:Total CO2/m2": 26.343107,
    "out:Total CO2 (tons)": 83.876427,
    "out:Klimatpaverkan": -116.258994,
    "out:Initial Cost/MSEK": 8.076686239077743,
    "out:Running cost/(Apt SEK y)": 6882.914894,
    "out:Running Cost over RSP/MSEK": 2.50177,
    "out:LCP/MSEK": 2.826264,
    "out:ROI% old": 19.284695,
    "out:Payback discounted": 6,
    "out:Atemp": 3184,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 50,
    "out:Energy use kWh/m2": 50,
    "out:Energy savings %": 266,
    "out:Op energy cost/MSEK": 17,
    "out:El price/MSEK": 16,
    "out:Heating price/MSEK": 0,
    "out:DH+ DHW/kWh/m2": 1,
    "out:El bought/kWh/m2": 3450.33346,
    "out:Return %": 17,
    "out:Return/kSEK/y": 1391,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 1774800,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 88740,
    "out:EAHP (replacement cost)": 1325203.921671023,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 41.7,
    "out:EAHP (El)": 34.420978,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 537312.957687,
    "out:EL kWh savings": -116917.966774,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 79599.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 303477.02,
    "out:DH kr savings": 537312.9576871527,
    "out:El kr savings": -233835.93354756414,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 2601.979937,
    "out:% savings (space heating)": 99.360916,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 997124.4110739925,
    "out:Eupp": 0.26,
    "out:Etvv": 0,
    "out:Ef": 44.4,
    "out:heatPumpQuantity": 12,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 8076686.239077743,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 0.359984,
    "out:EL CO2": 6.506737,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 6.506737,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02001002000001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 0.907313,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 48.330293,
    "out:Electricity (inc PV)": 48.330293,
    "out:Total Energy Use Pre PV": 49.330293,
    "out:Total Energy Use Post PV": 49.330293,
    "out:Primary Energy": 87.277431,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 20.184929,
    "out:CO2 Operational/m2": 6.739969,
    "out:Total CO2/m2": 26.924898,
    "out:Total CO2 (tons)": 85.728849,
    "out:Klimatpaverkan": -114.406572,
    "out:Initial Cost/MSEK": 8.270302264077742,
    "out:Running cost/(Apt SEK y)": 6752.829787,
    "out:Running Cost over RSP/MSEK": 2.4545,
    "out:LCP/MSEK": 2.679918,
    "out:ROI% old": 18.914873,
    "out:Payback discounted": 7,
    "out:Atemp": 3184,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 49,
    "out:Energy use kWh/m2": 49,
    "out:Energy savings %": 273.5,
    "out:Op energy cost/MSEK": 16,
    "out:El price/MSEK": 16,
    "out:Heating price/MSEK": 0,
    "out:DH+ DHW/kWh/m2": 1,
    "out:El bought/kWh/m2": 3383.12051,
    "out:Return %": 17,
    "out:Return/kSEK/y": 1397,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 1774800,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 88740,
    "out:EAHP (replacement cost)": 1325203.921671023,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 35.445,
    "out:EAHP (El)": 33.460793,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 537026.053901,
    "out:EL kWh savings": -113860.738654,
    "out:Sewage heat recovery cost": 273216,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 309304.58,
    "out:DH kr savings": 537026.0539014762,
    "out:El kr savings": -227721.47730727858,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 2888.883723,
    "out:% savings (space heating)": 99.290448,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 997124.4110739925,
    "out:Eupp": 0.36,
    "out:Etvv": 0,
    "out:Ef": 44.19,
    "out:heatPumpQuantity": 12,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 8270302.264077742,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 0.359984,
    "out:EL CO2": 6.379985,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 6.379985,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02001002000001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 0.533807,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 47.480902,
    "out:Electricity (inc PV)": 47.480902,
    "out:Total Energy Use Pre PV": 48.480902,
    "out:Total Energy Use Post PV": 48.480902,
    "out:Primary Energy": 85.614036,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 20.184929,
    "out:CO2 Operational/m2": 6.627843,
    "out:Total CO2/m2": 26.812772,
    "out:Total CO2 (tons)": 85.37184,
    "out:Klimatpaverkan": -114.763581,
    "out:Initial Cost/MSEK": 8.349902239077743,
    "out:Running cost/(Apt SEK y)": 6637.744681,
    "out:Running Cost over RSP/MSEK": 2.41268,
    "out:LCP/MSEK": 2.642138,
    "out:ROI% old": 18.806106,
    "out:Payback discounted": 7,
    "out:Atemp": 3184,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 48,
    "out:Energy use kWh/m2": 48,
    "out:Energy savings %": 281.2,
    "out:Op energy cost/MSEK": 16,
    "out:El price/MSEK": 16,
    "out:Heating price/MSEK": 0,
    "out:DH+ DHW/kWh/m2": 1,
    "out:El bought/kWh/m2": 3323.66314,
    "out:Return %": 17,
    "out:Return/kSEK/y": 1403,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 1774800,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 88740,
    "out:EAHP (replacement cost)": 1325203.921671023,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 35.445,
    "out:EAHP (El)": 32.611402,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 538215.296648,
    "out:EL kWh savings": -111156.278523,
    "out:Sewage heat recovery cost": 273216,
    "out:Balancing hydronic system cost": 79599.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 315902.74,
    "out:DH kr savings": 538215.2966476574,
    "out:El kr savings": -222312.55704671176,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 1699.640977,
    "out:% savings (space heating)": 99.582544,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 997124.4110739925,
    "out:Eupp": 0.19,
    "out:Etvv": 0,
    "out:Ef": 43.28,
    "out:heatPumpQuantity": 12,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 8349902.239077742,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 0.359984,
    "out:EL CO2": 6.267859,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 6.267859,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02001002010000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 1.321757,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 50.100159,
    "out:Electricity (inc PV)": 38.888157,
    "out:Total Energy Use Pre PV": 51.100159,
    "out:Total Energy Use Post PV": 39.888157,
    "out:Primary Energy": 70.358549,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 34.215486,
    "out:CO2 Operational/m2": -4.890462,
    "out:Total CO2/m2": 29.325024,
    "out:Total CO2 (tons)": 93.370848,
    "out:Klimatpaverkan": -106.764573,
    "out:Initial Cost/MSEK": 8.787306658300244,
    "out:Running cost/(Apt SEK y)": 5257.702128,
    "out:Running Cost over RSP/MSEK": 1.92096,
    "out:LCP/MSEK": 2.696453,
    "out:ROI% old": 18.669397,
    "out:Payback discounted": 7,
    "out:Atemp": 3184,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 40,
    "out:Energy use kWh/m2": 40,
    "out:Energy savings %": 357.5,
    "out:Op energy cost/MSEK": 13,
    "out:El price/MSEK": 13,
    "out:Heating price/MSEK": 0,
    "out:DH+ DHW/kWh/m2": 1,
    "out:El bought/kWh/m2": 2750.9705,
    "out:Return %": 17,
    "out:Return/kSEK/y": 1468,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 1774800,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 88740,
    "out:EAHP (replacement cost)": 1325203.921671023,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 41.7,
    "out:EAHP (El)": 35.230659,
    "out:PV (kr/kWp)": 17461.56255567083,
    "out:PV (kWp installed)": 45.25485,
    "out:PV (m2 panels)": 237.433632,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 42461.284501,
    "out:PV (kWh sold)": 6762.280024,
    "out:PV (% sold (El))": 15.925755,
    "out:PV (kWh self-consumed)": 35699.004477,
    "out:PV (ratio sold/self-consumed)": 0.189425,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 535706.464603,
    "out:EL kWh savings": -83796.986675,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 378255.91,
    "out:DH kr savings": 535706.4646025135,
    "out:El kr savings": -167593.9733504112,
    "out:El kr sold": 10143.420036687408,
    "out:El kr saved": 71398.008954,
    "out:El kr return": 81541.42899068742,
    "out:% solar/total": 89,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 4208.473022,
    "out:% savings (space heating)": 98.966338,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 997124.4110739925,
    "out:Eupp": 0.46,
    "out:Etvv": 0,
    "out:Ef": 34.63,
    "out:heatPumpQuantity": 12,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 8787306.658300243,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 0.359984,
    "out:EL CO2": -5.250446,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -10.396865,
    "out:Bought CO2": 5.146419,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02001002010000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 0.817205,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 49.290478,
    "out:Electricity (inc PV)": 38.164177,
    "out:Total Energy Use Pre PV": 50.290478,
    "out:Total Energy Use Post PV": 39.164177,
    "out:Primary Energy": 68.896914,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 34.215486,
    "out:CO2 Operational/m2": -5.420418,
    "out:Total CO2/m2": 28.795068,
    "out:Total CO2 (tons)": 91.683469,
    "out:Klimatpaverkan": -108.451952,
    "out:Initial Cost/MSEK": 8.866906633300243,
    "out:Running cost/(Apt SEK y)": 5150.893617,
    "out:Running Cost over RSP/MSEK": 1.88213,
    "out:LCP/MSEK": 2.655683,
    "out:ROI% old": 18.564358,
    "out:Payback discounted": 7,
    "out:Atemp": 3184,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 39,
    "out:Energy use kWh/m2": 39,
    "out:Energy savings %": 369.2,
    "out:Op energy cost/MSEK": 13,
    "out:El price/MSEK": 13,
    "out:Heating price/MSEK": 0,
    "out:DH+ DHW/kWh/m2": 1,
    "out:El bought/kWh/m2": 2699.689562,
    "out:Return %": 17,
    "out:Return/kSEK/y": 1473,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 1774800,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 88740,
    "out:EAHP (replacement cost)": 1325203.921671023,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 41.7,
    "out:EAHP (El)": 34.420978,
    "out:PV (kr/kWp)": 17461.56255567083,
    "out:PV (kWp installed)": 45.25485,
    "out:PV (m2 panels)": 237.433632,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 42461.284501,
    "out:PV (kWh sold)": 7035.152603,
    "out:PV (% sold (El))": 16.568393,
    "out:PV (kWh self-consumed)": 35426.131898,
    "out:PV (ratio sold/self-consumed)": 0.198587,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 537312.957687,
    "out:EL kWh savings": -81491.835049,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 79599.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 384882.02,
    "out:DH kr savings": 537312.9576871527,
    "out:El kr savings": -162983.67009755687,
    "out:El kr sold": 10552.728904426513,
    "out:El kr saved": 70852.263796,
    "out:El kr return": 81404.99270042652,
    "out:% solar/total": 89,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 2601.979937,
    "out:% savings (space heating)": 99.360916,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 997124.4110739925,
    "out:Eupp": 0.26,
    "out:Etvv": 0,
    "out:Ef": 33.84,
    "out:heatPumpQuantity": 12,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 8866906.633300243,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 0.359984,
    "out:EL CO2": -5.780402,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -10.830995,
    "out:Bought CO2": 5.050593,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02001002010001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 0.907313,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 48.330293,
    "out:Electricity (inc PV)": 37.308416,
    "out:Total Energy Use Pre PV": 49.330293,
    "out:Total Energy Use Post PV": 38.308416,
    "out:Primary Energy": 67.438053,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 34.924029,
    "out:CO2 Operational/m2": -6.061975,
    "out:Total CO2/m2": 28.862054,
    "out:Total CO2 (tons)": 91.896752,
    "out:Klimatpaverkan": -108.238669,
    "out:Initial Cost/MSEK": 9.060522658300243,
    "out:Running cost/(Apt SEK y)": 5024.340426,
    "out:Running Cost over RSP/MSEK": 1.83611,
    "out:LCP/MSEK": 2.508087,
    "out:ROI% old": 18.240212,
    "out:Payback discounted": 7,
    "out:Atemp": 3184,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 38,
    "out:Energy use kWh/m2": 38,
    "out:Energy savings %": 381.6,
    "out:Op energy cost/MSEK": 12,
    "out:El price/MSEK": 12,
    "out:Heating price/MSEK": 0,
    "out:DH+ DHW/kWh/m2": 1,
    "out:El bought/kWh/m2": 2639.074316,
    "out:Return %": 16,
    "out:Return/kSEK/y": 1479,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 1774800,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 88740,
    "out:EAHP (replacement cost)": 1325203.921671023,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 35.445,
    "out:EAHP (El)": 33.460793,
    "out:PV (kr/kWp)": 17461.56255567083,
    "out:PV (kWp installed)": 45.25485,
    "out:PV (m2 panels)": 237.433632,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 42461.284501,
    "out:PV (kWh sold)": 7367.638903,
    "out:PV (% sold (El))": 17.351427,
    "out:PV (kWh self-consumed)": 35093.645598,
    "out:PV (ratio sold/self-consumed)": 0.209942,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 537026.053901,
    "out:EL kWh savings": -78767.092845,
    "out:Sewage heat recovery cost": 273216,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 390543.33,
    "out:DH kr savings": 537026.0539014762,
    "out:El kr savings": -157534.185689195,
    "out:El kr sold": 11051.458354691513,
    "out:El kr saved": 70187.291196,
    "out:El kr return": 81238.74955069151,
    "out:% solar/total": 88,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 2888.883723,
    "out:% savings (space heating)": 99.290448,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 997124.4110739925,
    "out:Eupp": 0.36,
    "out:Etvv": 0,
    "out:Ef": 33.66,
    "out:heatPumpQuantity": 12,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 9060522.658300243,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 0.359984,
    "out:EL CO2": -6.421959,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -11.359256,
    "out:Bought CO2": 4.937297,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02001002010001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 0.533807,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 47.480902,
    "out:Electricity (inc PV)": 36.55418,
    "out:Total Energy Use Pre PV": 48.480902,
    "out:Total Energy Use Post PV": 37.55418,
    "out:Primary Energy": 65.945936,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 34.924029,
    "out:CO2 Operational/m2": -6.642731,
    "out:Total CO2/m2": 28.281298,
    "out:Total CO2 (tons)": 90.047626,
    "out:Klimatpaverkan": -110.087795,
    "out:Initial Cost/MSEK": 9.140122633300242,
    "out:Running cost/(Apt SEK y)": 4912.489362,
    "out:Running Cost over RSP/MSEK": 1.79544,
    "out:LCP/MSEK": 2.469157,
    "out:ROI% old": 18.144927,
    "out:Payback discounted": 7,
    "out:Atemp": 3184,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 38,
    "out:Energy use kWh/m2": 38,
    "out:Energy savings %": 381.6,
    "out:Op energy cost/MSEK": 12,
    "out:El price/MSEK": 12,
    "out:Heating price/MSEK": 0,
    "out:DH+ DHW/kWh/m2": 1,
    "out:El bought/kWh/m2": 2585.633458,
    "out:Return %": 16,
    "out:Return/kSEK/y": 1484,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 1774800,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 88740,
    "out:EAHP (replacement cost)": 1325203.921671023,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 35.445,
    "out:EAHP (El)": 32.611402,
    "out:PV (kr/kWp)": 17461.56255567083,
    "out:PV (kWp installed)": 45.25485,
    "out:PV (m2 panels)": 237.433632,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 42461.284501,
    "out:PV (kWh sold)": 7670.612603,
    "out:PV (% sold (El))": 18.064957,
    "out:PV (kWh self-consumed)": 34790.671899,
    "out:PV (ratio sold/self-consumed)": 0.220479,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 538215.296648,
    "out:EL kWh savings": -76365.606143,
    "out:Sewage heat recovery cost": 273216,
    "out:Balancing hydronic system cost": 79599.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 396990,
    "out:DH kr savings": 538215.2966476574,
    "out:El kr savings": -152731.21228631114,
    "out:El kr sold": 11505.918904064845,
    "out:El kr saved": 69581.343798,
    "out:El kr return": 81087.26270206485,
    "out:% solar/total": 87,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 1699.640977,
    "out:% savings (space heating)": 99.582544,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 997124.4110739925,
    "out:Eupp": 0.19,
    "out:Etvv": 0,
    "out:Ef": 32.86,
    "out:heatPumpQuantity": 12,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 9140122.633300243,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 0.359984,
    "out:EL CO2": -7.002715,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -11.840135,
    "out:Bought CO2": 4.83742,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02001002020000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 1.321757,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 50.100159,
    "out:Electricity (inc PV)": 35.147175,
    "out:Total Energy Use Pre PV": 51.100159,
    "out:Total Energy Use Post PV": 36.147175,
    "out:Primary Energy": 63.624781,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 48.954585,
    "out:CO2 Operational/m2": -53.925917,
    "out:Total CO2/m2": -4.971332,
    "out:Total CO2 (tons)": -15.828716,
    "out:Klimatpaverkan": -215.964137,
    "out:Initial Cost/MSEK": 9.577520010021493,
    "out:Running cost/(Apt SEK y)": 3775.829787,
    "out:Running Cost over RSP/MSEK": 1.39077,
    "out:LCP/MSEK": 2.43643,
    "out:ROI% old": 17.919864,
    "out:Payback discounted": 7,
    "out:Atemp": 3184,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 36,
    "out:Energy use kWh/m2": 36,
    "out:Energy savings %": 408.3,
    "out:Op energy cost/MSEK": 10,
    "out:El price/MSEK": 10,
    "out:Heating price/MSEK": 0,
    "out:DH+ DHW/kWh/m2": 1,
    "out:El bought/kWh/m2": 2484.643854,
    "out:Return %": 16,
    "out:Return/kSEK/y": 1537,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 1774800,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 88740,
    "out:EAHP (replacement cost)": 1325203.921671023,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 41.7,
    "out:EAHP (El)": 35.230659,
    "out:PV (kr/kWp)": 17461.48474631725,
    "out:PV (kWp installed)": 90.5097,
    "out:PV (m2 panels)": 474.867263,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 84922.568824,
    "out:PV (kWh sold)": 37312.282791,
    "out:PV (% sold (El))": 43.936828,
    "out:PV (kWh self-consumed)": 47610.286033,
    "out:PV (ratio sold/self-consumed)": 0.783702,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 535706.464603,
    "out:EL kWh savings": -71885.703571,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 447903.48,
    "out:DH kr savings": 535706.4646025135,
    "out:El kr savings": -143771.40714213267,
    "out:El kr sold": 55968.42418635494,
    "out:El kr saved": 95220.572066,
    "out:El kr return": 151188.99625235493,
    "out:% solar/total": 119,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 4208.473022,
    "out:% savings (space heating)": 98.966338,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 997124.4110739925,
    "out:Eupp": 0.46,
    "out:Etvv": 0,
    "out:Ef": 31.32,
    "out:heatPumpQuantity": 12,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 9577520.010021493,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 0.359984,
    "out:EL CO2": -54.285901,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -58.936565,
    "out:Bought CO2": 4.650664,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02001002020000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 0.817205,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 49.290478,
    "out:Electricity (inc PV)": 34.498107,
    "out:Total Energy Use Pre PV": 50.290478,
    "out:Total Energy Use Post PV": 35.498107,
    "out:Primary Energy": 62.297988,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 48.954585,
    "out:CO2 Operational/m2": -54.837061,
    "out:Total CO2/m2": -5.882476,
    "out:Total CO2 (tons)": -18.729798,
    "out:Klimatpaverkan": -218.865219,
    "out:Initial Cost/MSEK": 9.657119985021493,
    "out:Running cost/(Apt SEK y)": 3671.574468,
    "out:Running Cost over RSP/MSEK": 1.35287,
    "out:LCP/MSEK": 2.39473,
    "out:ROI% old": 17.828223,
    "out:Payback discounted": 7,
    "out:Atemp": 3184,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 35,
    "out:Energy use kWh/m2": 35,
    "out:Energy savings %": 422.9,
    "out:Op energy cost/MSEK": 10,
    "out:El price/MSEK": 9,
    "out:Heating price/MSEK": 0,
    "out:DH+ DHW/kWh/m2": 1,
    "out:El bought/kWh/m2": 2438.788675,
    "out:Return %": 16,
    "out:Return/kSEK/y": 1542,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 1774800,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 88740,
    "out:EAHP (replacement cost)": 1325203.921671023,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 41.7,
    "out:EAHP (El)": 34.420978,
    "out:PV (kr/kWp)": 17461.48474631725,
    "out:PV (kWp installed)": 90.5097,
    "out:PV (m2 panels)": 474.867263,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 84922.568824,
    "out:PV (kWh sold)": 37823.673844,
    "out:PV (% sold (El))": 44.539013,
    "out:PV (kWh self-consumed)": 47098.89498,
    "out:PV (ratio sold/self-consumed)": 0.803069,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 537312.957687,
    "out:EL kWh savings": -69819.071681,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 79599.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 454410.33,
    "out:DH kr savings": 537312.9576871527,
    "out:El kr savings": -139638.14336174697,
    "out:El kr sold": 56735.510765874176,
    "out:El kr saved": 94197.78996,
    "out:El kr return": 150933.30072587417,
    "out:% solar/total": 118,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 2601.979937,
    "out:% savings (space heating)": 99.360916,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 997124.4110739925,
    "out:Eupp": 0.26,
    "out:Etvv": 0,
    "out:Ef": 30.61,
    "out:heatPumpQuantity": 12,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 9657119.985021494,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 0.359984,
    "out:EL CO2": -55.197045,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -59.761841,
    "out:Bought CO2": 4.564796,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02001002020001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 0.907313,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 48.330293,
    "out:Electricity (inc PV)": 33.730242,
    "out:Total Energy Use Pre PV": 49.330293,
    "out:Total Energy Use Post PV": 34.730242,
    "out:Primary Energy": 60.99734,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 49.663128,
    "out:CO2 Operational/m2": -55.926907,
    "out:Total CO2/m2": -6.263779,
    "out:Total CO2 (tons)": -19.943866,
    "out:Klimatpaverkan": -220.079287,
    "out:Initial Cost/MSEK": 9.850736010021492,
    "out:Running cost/(Apt SEK y)": 3548,
    "out:Running Cost over RSP/MSEK": 1.30794,
    "out:LCP/MSEK": 2.246044,
    "out:ROI% old": 17.542968,
    "out:Payback discounted": 7,
    "out:Atemp": 3184,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 35,
    "out:Energy use kWh/m2": 35,
    "out:Energy savings %": 422.9,
    "out:Op energy cost/MSEK": 9,
    "out:El price/MSEK": 9,
    "out:Heating price/MSEK": 0,
    "out:DH+ DHW/kWh/m2": 1,
    "out:El bought/kWh/m2": 2384.54515,
    "out:Return %": 16,
    "out:Return/kSEK/y": 1548,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 1774800,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 88740,
    "out:EAHP (replacement cost)": 1325203.921671023,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 35.445,
    "out:EAHP (El)": 33.460793,
    "out:PV (kr/kWp)": 17461.48474631725,
    "out:PV (kWp installed)": 90.5097,
    "out:PV (m2 panels)": 474.867263,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 84922.568824,
    "out:PV (kWh sold)": 38436.019077,
    "out:PV (% sold (El))": 45.260076,
    "out:PV (kWh self-consumed)": 46486.549747,
    "out:PV (ratio sold/self-consumed)": 0.82682,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 537026.053901,
    "out:EL kWh savings": -67374.190256,
    "out:Sewage heat recovery cost": 273216,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 459931.7,
    "out:DH kr savings": 537026.0539014762,
    "out:El kr savings": -134748.38051297626,
    "out:El kr sold": 57654.02861579145,
    "out:El kr saved": 92973.099494,
    "out:El kr return": 150627.12810979143,
    "out:% solar/total": 116,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 2888.883723,
    "out:% savings (space heating)": 99.290448,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 997124.4110739925,
    "out:Eupp": 0.36,
    "out:Etvv": 0,
    "out:Ef": 30.45,
    "out:heatPumpQuantity": 12,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 9850736.010021493,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 0.359984,
    "out:EL CO2": -56.286891,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -60.750102,
    "out:Bought CO2": 4.463211,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02001002020001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 0.533807,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 47.480902,
    "out:Electricity (inc PV)": 33.052728,
    "out:Total Energy Use Pre PV": 48.480902,
    "out:Total Energy Use Post PV": 34.052728,
    "out:Primary Energy": 59.643322,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 49.663128,
    "out:CO2 Operational/m2": -56.899676,
    "out:Total CO2/m2": -7.236548,
    "out:Total CO2 (tons)": -23.041162,
    "out:Klimatpaverkan": -223.176583,
    "out:Initial Cost/MSEK": 9.930335985021495,
    "out:Running cost/(Apt SEK y)": 3438.723404,
    "out:Running Cost over RSP/MSEK": 1.26822,
    "out:LCP/MSEK": 2.206164,
    "out:ROI% old": 17.459487,
    "out:Payback discounted": 7,
    "out:Atemp": 3184,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 34,
    "out:Energy use kWh/m2": 34,
    "out:Energy savings %": 438.2,
    "out:Op energy cost/MSEK": 9,
    "out:El price/MSEK": 9,
    "out:Heating price/MSEK": 0,
    "out:DH+ DHW/kWh/m2": 1,
    "out:El bought/kWh/m2": 2336.679263,
    "out:Return %": 16,
    "out:Return/kSEK/y": 1553,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 1774800,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 88740,
    "out:EAHP (replacement cost)": 1325203.921671023,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 35.445,
    "out:EAHP (El)": 32.611402,
    "out:PV (kr/kWp)": 17461.48474631725,
    "out:PV (kWp installed)": 90.5097,
    "out:PV (m2 panels)": 474.867263,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 84922.568824,
    "out:PV (kWh sold)": 38983.278222,
    "out:PV (% sold (El))": 45.904497,
    "out:PV (kWh self-consumed)": 45939.290602,
    "out:PV (ratio sold/self-consumed)": 0.848583,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 538215.296648,
    "out:EL kWh savings": -65216.98633,
    "out:Sewage heat recovery cost": 273216,
    "out:Balancing hydronic system cost": 79599.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 466256.24,
    "out:DH kr savings": 538215.2966476574,
    "out:El kr savings": -130433.97265909315,
    "out:El kr sold": 58474.917332854195,
    "out:El kr saved": 91878.581204,
    "out:El kr return": 150353.4985368542,
    "out:% solar/total": 115,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 1699.640977,
    "out:% savings (space heating)": 99.582544,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 997124.4110739925,
    "out:Eupp": 0.19,
    "out:Etvv": 0,
    "out:Ef": 29.74,
    "out:heatPumpQuantity": 12,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 9930335.985021494,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 0.359984,
    "out:EL CO2": -57.25966,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -61.633234,
    "out:Bought CO2": 4.373574,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02001002100000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 0.792722,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 47.7332,
    "out:Electricity (inc PV)": 47.7332,
    "out:Total Energy Use Pre PV": 48.7332,
    "out:Total Energy Use Post PV": 48.7332,
    "out:Primary Energy": 86.179966,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 19.476386,
    "out:CO2 Operational/m2": 6.661148,
    "out:Total CO2/m2": 26.137534,
    "out:Total CO2 (tons)": 83.221883,
    "out:Klimatpaverkan": -116.913538,
    "out:Initial Cost/MSEK": 8.121133951577743,
    "out:Running cost/(Apt SEK y)": 6671.93617,
    "out:Running Cost over RSP/MSEK": 2.42511,
    "out:LCP/MSEK": 2.858476,
    "out:ROI% old": 19.313999,
    "out:Payback discounted": 6,
    "out:Atemp": 3184,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 49,
    "out:Energy use kWh/m2": 49,
    "out:Energy savings %": 273.5,
    "out:Op energy cost/MSEK": 16,
    "out:El price/MSEK": 16,
    "out:Heating price/MSEK": 0,
    "out:DH+ DHW/kWh/m2": 1,
    "out:El bought/kWh/m2": 3341.324,
    "out:Return %": 17,
    "out:Return/kSEK/y": 1401,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 1774800,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 88740,
    "out:EAHP (replacement cost)": 1325203.921671023,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 33.36,
    "out:EAHP (El)": 32.8637,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 537390.911536,
    "out:EL kWh savings": -111959.595114,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 313471.72,
    "out:DH kr savings": 537390.911535698,
    "out:El kr savings": -223919.19022730878,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 2524.026089,
    "out:% savings (space heating)": 99.380063,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 997124.4110739925,
    "out:Eupp": 0.33,
    "out:Etvv": 0,
    "out:Ef": 43.82,
    "out:heatPumpQuantity": 12,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 8121133.9515777435,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 0.359984,
    "out:EL CO2": 6.301164,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 6.301164,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02001002100000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 0.464515,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 46.870082,
    "out:Electricity (inc PV)": 46.870082,
    "out:Total Energy Use Pre PV": 46.870082,
    "out:Total Energy Use Post PV": 46.870082,
    "out:Primary Energy": 84.49915,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 19.476386,
    "out:CO2 Operational/m2": 6.187226,
    "out:Total CO2/m2": 25.663612,
    "out:Total CO2 (tons)": 81.712916,
    "out:Klimatpaverkan": -118.422505,
    "out:Initial Cost/MSEK": 8.200733926577744,
    "out:Running cost/(Apt SEK y)": 6350.404255,
    "out:Running Cost over RSP/MSEK": 2.3074,
    "out:LCP/MSEK": 2.896586,
    "out:ROI% old": 19.33158,
    "out:Payback discounted": 6,
    "out:Atemp": 3184,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 47,
    "out:Energy use kWh/m2": 47,
    "out:Energy savings %": 289.4,
    "out:Op energy cost/MSEK": 15,
    "out:El price/MSEK": 15,
    "out:Heating price/MSEK": 0,
    "out:DH+ DHW/kWh/m2": 0,
    "out:El bought/kWh/m2": 3280.90574,
    "out:Return %": 17,
    "out:Return/kSEK/y": 1416,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 1774800,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 88740,
    "out:EAHP (replacement cost)": 1325203.921671023,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 33.36,
    "out:EAHP (El)": 32.000582,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 538435.922309,
    "out:EL kWh savings": -109211.428229,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 79599.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 320013.07,
    "out:DH kr savings": 538435.9223092756,
    "out:El kr savings": -218422.85645704286,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 1479.015315,
    "out:% savings (space heating)": 99.636732,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 997124.4110739925,
    "out:Eupp": 0.17,
    "out:Etvv": 0,
    "out:Ef": 42.91,
    "out:heatPumpQuantity": 12,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 8200733.926577744,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 0,
    "out:EL CO2": 6.187226,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 6.187226,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02001002100001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 0.572524,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 46.283563,
    "out:Electricity (inc PV)": 46.283563,
    "out:Total Energy Use Pre PV": 47.283563,
    "out:Total Energy Use Post PV": 47.283563,
    "out:Primary Energy": 83.520729,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 20.184929,
    "out:CO2 Operational/m2": 6.469785,
    "out:Total CO2/m2": 26.654714,
    "out:Total CO2 (tons)": 84.868584,
    "out:Klimatpaverkan": -115.266837,
    "out:Initial Cost/MSEK": 8.394349951577743,
    "out:Running cost/(Apt SEK y)": 6475.510638,
    "out:Running Cost over RSP/MSEK": 2.35374,
    "out:LCP/MSEK": 2.65663,
    "out:ROI% old": 18.806834,
    "out:Payback discounted": 7,
    "out:Atemp": 3184,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 47,
    "out:Energy use kWh/m2": 47,
    "out:Energy savings %": 289.4,
    "out:Op energy cost/MSEK": 16,
    "out:El price/MSEK": 15,
    "out:Heating price/MSEK": 0,
    "out:DH+ DHW/kWh/m2": 1,
    "out:El bought/kWh/m2": 3239.84941,
    "out:Return %": 17,
    "out:Return/kSEK/y": 1410,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 1774800,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 88740,
    "out:EAHP (replacement cost)": 1325203.921671023,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 28.356,
    "out:EAHP (El)": 31.414063,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 538092.021757,
    "out:EL kWh savings": -107343.952294,
    "out:Sewage heat recovery cost": 273216,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 323404.12,
    "out:DH kr savings": 538092.0217567483,
    "out:El kr savings": -214687.90458881328,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 1822.915868,
    "out:% savings (space heating)": 99.552266,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 997124.4110739925,
    "out:Eupp": 0.27,
    "out:Etvv": 0,
    "out:Ef": 42.93,
    "out:heatPumpQuantity": 12,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 8394349.951577744,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 0.359984,
    "out:EL CO2": 6.109801,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 6.109801,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02001002100001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 0.331629,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 45.393987,
    "out:Electricity (inc PV)": 45.393987,
    "out:Total Energy Use Pre PV": 45.393987,
    "out:Total Energy Use Post PV": 45.393987,
    "out:Primary Energy": 81.810385,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 20.184929,
    "out:CO2 Operational/m2": 5.992369,
    "out:Total CO2/m2": 26.177298,
    "out:Total CO2 (tons)": 83.348492,
    "out:Klimatpaverkan": -116.786929,
    "out:Initial Cost/MSEK": 8.473949926577744,
    "out:Running cost/(Apt SEK y)": 6150.404255,
    "out:Running Cost over RSP/MSEK": 2.23474,
    "out:LCP/MSEK": 2.69603,
    "out:ROI% old": 18.830787,
    "out:Payback discounted": 7,
    "out:Atemp": 3184,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 45,
    "out:Energy use kWh/m2": 45,
    "out:Energy savings %": 306.7,
    "out:Op energy cost/MSEK": 15,
    "out:El price/MSEK": 15,
    "out:Heating price/MSEK": 0,
    "out:DH+ DHW/kWh/m2": 0,
    "out:El bought/kWh/m2": 3177.57909,
    "out:Return %": 17,
    "out:Return/kSEK/y": 1426,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 1774800,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 88740,
    "out:EAHP (replacement cost)": 1325203.921671023,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 28.356,
    "out:EAHP (El)": 30.524487,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 538859.031206,
    "out:EL kWh savings": -104511.543163,
    "out:Sewage heat recovery cost": 273216,
    "out:Balancing hydronic system cost": 79599.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 329835.94,
    "out:DH kr savings": 538859.0312059709,
    "out:El kr savings": -209023.08632524093,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 1055.906418,
    "out:% savings (space heating)": 99.740654,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 997124.4110739925,
    "out:Eupp": 0.13,
    "out:Etvv": 0,
    "out:Ef": 42.01,
    "out:heatPumpQuantity": 12,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 8473949.926577743,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 0,
    "out:EL CO2": 5.992369,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 5.992369,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02001002110000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 0.792722,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 47.7332,
    "out:Electricity (inc PV)": 36.777958,
    "out:Total Energy Use Pre PV": 48.7332,
    "out:Total Energy Use Post PV": 37.777958,
    "out:Primary Energy": 66.46053,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 34.215486,
    "out:CO2 Operational/m2": -6.468886,
    "out:Total CO2/m2": 27.7466,
    "out:Total CO2 (tons)": 88.345148,
    "out:Klimatpaverkan": -111.790273,
    "out:Initial Cost/MSEK": 8.911354345800243,
    "out:Running cost/(Apt SEK y)": 4945.702128,
    "out:Running Cost over RSP/MSEK": 1.80752,
    "out:LCP/MSEK": 2.685846,
    "out:ROI% old": 18.59137,
    "out:Payback discounted": 7,
    "out:Atemp": 3184,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 38,
    "out:Energy use kWh/m2": 38,
    "out:Energy savings %": 381.6,
    "out:Op energy cost/MSEK": 12,
    "out:El price/MSEK": 12,
    "out:Heating price/MSEK": 0,
    "out:DH+ DHW/kWh/m2": 1,
    "out:El bought/kWh/m2": 2601.489207,
    "out:Return %": 17,
    "out:Return/kSEK/y": 1482,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 1774800,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 88740,
    "out:EAHP (replacement cost)": 1325203.921671023,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 33.36,
    "out:EAHP (El)": 32.8637,
    "out:PV (kr/kWp)": 17461.56255567083,
    "out:PV (kWp installed)": 45.25485,
    "out:PV (m2 panels)": 237.433632,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 42461.284501,
    "out:PV (kWh sold)": 7579.802932,
    "out:PV (% sold (El))": 17.851092,
    "out:PV (kWh self-consumed)": 34881.481569,
    "out:PV (ratio sold/self-consumed)": 0.217302,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 537390.911536,
    "out:EL kWh savings": -77078.115081,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 394604.39,
    "out:DH kr savings": 537390.911535698,
    "out:El kr savings": -154156.23016155246,
    "out:El kr sold": 11369.704398435646,
    "out:El kr saved": 69762.963138,
    "out:El kr return": 81132.66753643565,
    "out:% solar/total": 87,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 2524.026089,
    "out:% savings (space heating)": 99.380063,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 997124.4110739925,
    "out:Eupp": 0.33,
    "out:Etvv": 0,
    "out:Ef": 33.34,
    "out:heatPumpQuantity": 12,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 8911354.345800243,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 0.359984,
    "out:EL CO2": -6.82887,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -11.695922,
    "out:Bought CO2": 4.867052,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02001002110000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 0.464515,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 46.870082,
    "out:Electricity (inc PV)": 36.013741,
    "out:Total Energy Use Pre PV": 46.870082,
    "out:Total Energy Use Post PV": 36.013741,
    "out:Primary Energy": 64.957736,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 34.215486,
    "out:CO2 Operational/m2": -7.428823,
    "out:Total CO2/m2": 26.786663,
    "out:Total CO2 (tons)": 85.288709,
    "out:Klimatpaverkan": -114.846712,
    "out:Initial Cost/MSEK": 8.990954320800245,
    "out:Running cost/(Apt SEK y)": 4627.510638,
    "out:Running Cost over RSP/MSEK": 1.691,
    "out:LCP/MSEK": 2.722766,
    "out:ROI% old": 18.611913,
    "out:Payback discounted": 7,
    "out:Atemp": 3184,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 36,
    "out:Energy use kWh/m2": 36,
    "out:Energy savings %": 408.3,
    "out:Op energy cost/MSEK": 11,
    "out:El price/MSEK": 11,
    "out:Heating price/MSEK": 0,
    "out:DH+ DHW/kWh/m2": 0,
    "out:El bought/kWh/m2": 2547.31151,
    "out:Return %": 17,
    "out:Return/kSEK/y": 1497,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 1774800,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 88740,
    "out:EAHP (replacement cost)": 1325203.921671023,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 33.36,
    "out:EAHP (El)": 32.000582,
    "out:PV (kr/kWp)": 17461.56255567083,
    "out:PV (kWp installed)": 45.25485,
    "out:PV (m2 panels)": 237.433632,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 42461.284501,
    "out:PV (kWh sold)": 7894.703838,
    "out:PV (% sold (El))": 18.592711,
    "out:PV (kWh self-consumed)": 34566.580663,
    "out:PV (ratio sold/self-consumed)": 0.228391,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 538435.922309,
    "out:EL kWh savings": -74644.848885,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 79599.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 400988.28,
    "out:DH kr savings": 538435.9223092756,
    "out:El kr savings": -149289.6977697923,
    "out:El kr sold": 11842.055757524195,
    "out:El kr saved": 69133.161326,
    "out:El kr return": 80975.2170835242,
    "out:% solar/total": 86,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 1479.015315,
    "out:% savings (space heating)": 99.636732,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 997124.4110739925,
    "out:Eupp": 0.17,
    "out:Etvv": 0,
    "out:Ef": 32.54,
    "out:heatPumpQuantity": 12,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 8990954.320800245,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 0,
    "out:EL CO2": -7.428823,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -12.19464,
    "out:Bought CO2": 4.765817,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02001002110001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 0.572524,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 46.283563,
    "out:Electricity (inc PV)": 35.496224,
    "out:Total Energy Use Pre PV": 47.283563,
    "out:Total Energy Use Post PV": 36.496224,
    "out:Primary Energy": 64.103519,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 34.924029,
    "out:CO2 Operational/m2": -7.485288,
    "out:Total CO2/m2": 27.438741,
    "out:Total CO2 (tons)": 87.364925,
    "out:Klimatpaverkan": -112.770496,
    "out:Initial Cost/MSEK": 9.184570345800243,
    "out:Running cost/(Apt SEK y)": 4754.978723,
    "out:Running Cost over RSP/MSEK": 1.73816,
    "out:LCP/MSEK": 2.48199,
    "out:ROI% old": 18.14621,
    "out:Payback discounted": 7,
    "out:Atemp": 3184,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 36,
    "out:Energy use kWh/m2": 36,
    "out:Energy savings %": 408.3,
    "out:Op energy cost/MSEK": 12,
    "out:El price/MSEK": 12,
    "out:Heating price/MSEK": 0,
    "out:DH+ DHW/kWh/m2": 1,
    "out:El bought/kWh/m2": 2510.60319,
    "out:Return %": 16,
    "out:Return/kSEK/y": 1491,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 1774800,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 88740,
    "out:EAHP (replacement cost)": 1325203.921671023,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 28.356,
    "out:EAHP (El)": 31.414063,
    "out:PV (kr/kWp)": 17461.56255567083,
    "out:PV (kWp installed)": 45.25485,
    "out:PV (m2 panels)": 237.433632,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 42461.284501,
    "out:PV (kWh sold)": 8114.406499,
    "out:PV (% sold (El))": 19.11013,
    "out:PV (kWh self-consumed)": 34346.878003,
    "out:PV (ratio sold/self-consumed)": 0.236249,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 538092.021757,
    "out:EL kWh savings": -72997.075253,
    "out:Sewage heat recovery cost": 273216,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 404269.48,
    "out:DH kr savings": 538092.0217567483,
    "out:El kr savings": -145994.1505053548,
    "out:El kr sold": 12171.609748023382,
    "out:El kr saved": 68693.756006,
    "out:El kr return": 80865.36575402338,
    "out:% solar/total": 86,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 1822.915868,
    "out:% savings (space heating)": 99.552266,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 997124.4110739925,
    "out:Eupp": 0.27,
    "out:Etvv": 0,
    "out:Ef": 32.56,
    "out:heatPumpQuantity": 12,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 9184570.345800243,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 0.359984,
    "out:EL CO2": -7.845272,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -12.542526,
    "out:Bought CO2": 4.697254,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02001002110001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 0.331629,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 45.393987,
    "out:Electricity (inc PV)": 34.713276,
    "out:Total Energy Use Pre PV": 45.393987,
    "out:Total Energy Use Post PV": 34.713276,
    "out:Primary Energy": 62.585105,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 34.924029,
    "out:CO2 Operational/m2": -8.489845,
    "out:Total CO2/m2": 26.434184,
    "out:Total CO2 (tons)": 84.166417,
    "out:Klimatpaverkan": -115.969005,
    "out:Initial Cost/MSEK": 9.264170320800243,
    "out:Running cost/(Apt SEK y)": 4433.468085,
    "out:Running Cost over RSP/MSEK": 1.62043,
    "out:LCP/MSEK": 2.52012,
    "out:ROI% old": 18.171838,
    "out:Payback discounted": 7,
    "out:Atemp": 3184,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 35,
    "out:Energy use kWh/m2": 35,
    "out:Energy savings %": 422.9,
    "out:Op energy cost/MSEK": 11,
    "out:El price/MSEK": 11,
    "out:Heating price/MSEK": 0,
    "out:DH+ DHW/kWh/m2": 0,
    "out:El bought/kWh/m2": 2455.090745,
    "out:Return %": 16,
    "out:Return/kSEK/y": 1506,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 1774800,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 88740,
    "out:EAHP (replacement cost)": 1325203.921671023,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 28.356,
    "out:EAHP (El)": 30.524487,
    "out:PV (kr/kWp)": 17461.56255567083,
    "out:PV (kWp installed)": 45.25485,
    "out:PV (m2 panels)": 237.433632,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 42461.284501,
    "out:PV (kWh sold)": 8453.911816,
    "out:PV (% sold (El))": 19.909694,
    "out:PV (kWh self-consumed)": 34007.372685,
    "out:PV (ratio sold/self-consumed)": 0.248591,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 538859.031206,
    "out:EL kWh savings": -70504.169571,
    "out:Sewage heat recovery cost": 273216,
    "out:Balancing hydronic system cost": 79599.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 410531.56,
    "out:DH kr savings": 538859.0312059709,
    "out:El kr savings": -141008.3391414832,
    "out:El kr sold": 12680.867724500851,
    "out:El kr saved": 68014.74537,
    "out:El kr return": 80695.61309450085,
    "out:% solar/total": 85,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 1055.906418,
    "out:% savings (space heating)": 99.740654,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 997124.4110739925,
    "out:Eupp": 0.13,
    "out:Etvv": 0,
    "out:Ef": 31.76,
    "out:heatPumpQuantity": 12,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 9264170.320800243,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 0,
    "out:EL CO2": -8.489845,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -13.083447,
    "out:Bought CO2": 4.593602,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02001002120000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 0.792722,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 47.7332,
    "out:Electricity (inc PV)": 33.253757,
    "out:Total Energy Use Pre PV": 48.7332,
    "out:Total Energy Use Post PV": 34.253757,
    "out:Primary Energy": 60.116969,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 48.954585,
    "out:CO2 Operational/m2": -56.609864,
    "out:Total CO2/m2": -7.655279,
    "out:Total CO2 (tons)": -24.374401,
    "out:Klimatpaverkan": -224.509822,
    "out:Initial Cost/MSEK": 9.701567697521492,
    "out:Running cost/(Apt SEK y)": 3471.170213,
    "out:Running Cost over RSP/MSEK": 1.28002,
    "out:LCP/MSEK": 2.423132,
    "out:ROI% old": 17.853816,
    "out:Payback discounted": 7,
    "out:Atemp": 3184,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 34,
    "out:Energy use kWh/m2": 34,
    "out:Energy savings %": 438.2,
    "out:Op energy cost/MSEK": 9,
    "out:El price/MSEK": 9,
    "out:Heating price/MSEK": 0,
    "out:DH+ DHW/kWh/m2": 1,
    "out:El bought/kWh/m2": 2350.885225,
    "out:Return %": 16,
    "out:Return/kSEK/y": 1552,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 1774800,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 88740,
    "out:EAHP (replacement cost)": 1325203.921671023,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 33.36,
    "out:EAHP (El)": 32.8637,
    "out:PV (kr/kWp)": 17461.48474631725,
    "out:PV (kWp installed)": 90.5097,
    "out:PV (m2 panels)": 474.867263,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 84922.568824,
    "out:PV (kWh sold)": 38820.036689,
    "out:PV (% sold (El))": 45.712273,
    "out:PV (kWh self-consumed)": 46102.532135,
    "out:PV (ratio sold/self-consumed)": 0.842037,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 537390.911536,
    "out:EL kWh savings": -65857.062473,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 463906.84,
    "out:DH kr savings": 537390.911535698,
    "out:El kr savings": -131714.12494592153,
    "out:El kr sold": 58230.055034162826,
    "out:El kr saved": 92205.06427,
    "out:El kr return": 150435.11930416283,
    "out:% solar/total": 115,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 2524.026089,
    "out:% savings (space heating)": 99.380063,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 997124.4110739925,
    "out:Eupp": 0.33,
    "out:Etvv": 0,
    "out:Ef": 30.16,
    "out:heatPumpQuantity": 12,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 9701567.697521493,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 0.359984,
    "out:EL CO2": -56.969848,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -61.370025,
    "out:Bought CO2": 4.400177,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02001002120000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 0.464515,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 46.870082,
    "out:Electricity (inc PV)": 32.566866,
    "out:Total Energy Use Pre PV": 46.870082,
    "out:Total Energy Use Post PV": 32.566866,
    "out:Primary Energy": 58.753361,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 48.954585,
    "out:CO2 Operational/m2": -57.964772,
    "out:Total CO2/m2": -9.010187,
    "out:Total CO2 (tons)": -28.688427,
    "out:Klimatpaverkan": -228.823848,
    "out:Initial Cost/MSEK": 9.781167672521494,
    "out:Running cost/(Apt SEK y)": 3155.617021,
    "out:Running Cost over RSP/MSEK": 1.16446,
    "out:LCP/MSEK": 2.459092,
    "out:ROI% old": 17.877299,
    "out:Payback discounted": 7,
    "out:Atemp": 3184,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 33,
    "out:Energy use kWh/m2": 33,
    "out:Energy savings %": 454.5,
    "out:Op energy cost/MSEK": 8,
    "out:El price/MSEK": 8,
    "out:Heating price/MSEK": 0,
    "out:DH+ DHW/kWh/m2": 0,
    "out:El bought/kWh/m2": 2302.329344,
    "out:Return %": 16,
    "out:Return/kSEK/y": 1566,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 1774800,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 88740,
    "out:EAHP (replacement cost)": 1325203.921671023,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 33.36,
    "out:EAHP (El)": 32.000582,
    "out:PV (kr/kWp)": 17461.48474631725,
    "out:PV (kWp installed)": 90.5097,
    "out:PV (m2 panels)": 474.867263,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 84922.568824,
    "out:PV (kWh sold)": 39381.143216,
    "out:PV (% sold (El))": 46.373,
    "out:PV (kWh self-consumed)": 45541.425608,
    "out:PV (ratio sold/self-consumed)": 0.864732,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 538435.922309,
    "out:EL kWh savings": -63670.002187,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 79599.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 470167.63,
    "out:DH kr savings": 538435.9223092756,
    "out:El kr savings": -127340.00437400477,
    "out:El kr sold": 59071.714823576294,
    "out:El kr saved": 91082.851216,
    "out:El kr return": 150154.56603957628,
    "out:% solar/total": 114,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 1479.015315,
    "out:% savings (space heating)": 99.636732,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 997124.4110739925,
    "out:Eupp": 0.17,
    "out:Etvv": 0,
    "out:Ef": 29.45,
    "out:heatPumpQuantity": 12,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 9781167.672521494,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 0,
    "out:EL CO2": -57.964772,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -62.274032,
    "out:Bought CO2": 4.30926,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02001002120001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 0.572524,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 46.283563,
    "out:Electricity (inc PV)": 32.101433,
    "out:Total Energy Use Pre PV": 47.283563,
    "out:Total Energy Use Post PV": 33.101433,
    "out:Primary Energy": 57.992895,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 49.663128,
    "out:CO2 Operational/m2": -58.286871,
    "out:Total CO2/m2": -8.623743,
    "out:Total CO2 (tons)": -27.457989,
    "out:Klimatpaverkan": -227.59341,
    "out:Initial Cost/MSEK": 9.974783697521493,
    "out:Running cost/(Apt SEK y)": 3284.851064,
    "out:Running Cost over RSP/MSEK": 1.21227,
    "out:LCP/MSEK": 2.217666,
    "out:ROI% old": 17.461818,
    "out:Payback discounted": 7,
    "out:Atemp": 3184,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 33,
    "out:Energy use kWh/m2": 33,
    "out:Energy savings %": 454.5,
    "out:Op energy cost/MSEK": 9,
    "out:El price/MSEK": 8,
    "out:Heating price/MSEK": 0,
    "out:DH+ DHW/kWh/m2": 1,
    "out:El bought/kWh/m2": 2269.406473,
    "out:Return %": 16,
    "out:Return/kSEK/y": 1560,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 1774800,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 88740,
    "out:EAHP (replacement cost)": 1325203.921671023,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 28.356,
    "out:EAHP (El)": 31.414063,
    "out:PV (kr/kWp)": 17461.48474631725,
    "out:PV (kWp installed)": 90.5097,
    "out:PV (m2 panels)": 474.867263,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 84922.568824,
    "out:PV (kWh sold)": 39766.680421,
    "out:PV (% sold (El))": 46.826987,
    "out:PV (kWh self-consumed)": 45155.888403,
    "out:PV (ratio sold/self-consumed)": 0.880653,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 538092.021757,
    "out:EL kWh savings": -62188.063961,
    "out:Sewage heat recovery cost": 273216,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 473365.91,
    "out:DH kr savings": 538092.0217567483,
    "out:El kr savings": -124376.12792177437,
    "out:El kr sold": 59650.020631047606,
    "out:El kr saved": 90311.776806,
    "out:El kr return": 149961.7974370476,
    "out:% solar/total": 113,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 1822.915868,
    "out:% savings (space heating)": 99.552266,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 997124.4110739925,
    "out:Eupp": 0.27,
    "out:Etvv": 0,
    "out:Ef": 29.46,
    "out:heatPumpQuantity": 12,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 9974783.697521493,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 0.359984,
    "out:EL CO2": -58.646855,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -62.894491,
    "out:Bought CO2": 4.247636,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02001002120001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 0.331629,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 45.393987,
    "out:Electricity (inc PV)": 31.397608,
    "out:Total Energy Use Pre PV": 45.393987,
    "out:Total Energy Use Post PV": 31.397608,
    "out:Primary Energy": 56.616902,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 49.663128,
    "out:CO2 Operational/m2": -59.691635,
    "out:Total CO2/m2": -10.028507,
    "out:Total CO2 (tons)": -31.930757,
    "out:Klimatpaverkan": -232.066178,
    "out:Initial Cost/MSEK": 10.054383672521494,
    "out:Running cost/(Apt SEK y)": 2966.021277,
    "out:Running Cost over RSP/MSEK": 1.09552,
    "out:LCP/MSEK": 2.254816,
    "out:ROI% old": 17.489457,
    "out:Payback discounted": 7,
    "out:Atemp": 3184,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 31,
    "out:Energy use kWh/m2": 31,
    "out:Energy savings %": 490.3,
    "out:Op energy cost/MSEK": 8,
    "out:El price/MSEK": 8,
    "out:Heating price/MSEK": 0,
    "out:DH+ DHW/kWh/m2": 0,
    "out:El bought/kWh/m2": 2219.59266,
    "out:Return %": 16,
    "out:Return/kSEK/y": 1575,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 1774800,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 88740,
    "out:EAHP (replacement cost)": 1325203.921671023,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 28.356,
    "out:EAHP (El)": 30.524487,
    "out:PV (kr/kWp)": 17461.48474631725,
    "out:PV (kWp installed)": 90.5097,
    "out:PV (m2 panels)": 474.867263,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 84922.568824,
    "out:PV (kWh sold)": 40358.110184,
    "out:PV (% sold (El))": 47.523421,
    "out:PV (kWh self-consumed)": 44564.45864,
    "out:PV (ratio sold/self-consumed)": 0.905612,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 538859.031206,
    "out:EL kWh savings": -59947.085835,
    "out:Sewage heat recovery cost": 273216,
    "out:Balancing hydronic system cost": 79599.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 479502.02,
    "out:DH kr savings": 538859.0312059709,
    "out:El kr savings": -119894.17167030305,
    "out:El kr sold": 60537.16527603964,
    "out:El kr saved": 89128.91728,
    "out:El kr return": 149666.08255603962,
    "out:% solar/total": 111,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 1055.906418,
    "out:% savings (space heating)": 99.740654,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 997124.4110739925,
    "out:Eupp": 0.13,
    "out:Etvv": 0,
    "out:Ef": 28.75,
    "out:heatPumpQuantity": 12,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 10054383.672521494,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 0,
    "out:EL CO2": -59.691635,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -63.846074,
    "out:Bought CO2": 4.154439,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00000000000000010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 102.683363,
    "out:DHW (DH)": 41.7,
    "out:Property Electricity": 17.169,
    "out:Electricity (inc PV)": 17.169,
    "out:Total Energy Use Pre PV": 161.169,
    "out:Total Energy Use Post PV": 161.169,
    "out:Primary Energy": 128.269038,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 2.970127,
    "out:CO2 Operational/m2": 54.104141,
    "out:Total CO2/m2": 57.074268,
    "out:Total CO2 (tons)": 181.724415,
    "out:Klimatpaverkan": -18.411006,
    "out:Initial Cost/MSEK": 2.684792300368186,
    "out:Running cost/(Apt SEK y)": 31787.021277,
    "out:Running Cost over RSP/MSEK": 11.67564,
    "out:LCP/MSEK": -0.955712,
    "out:ROI% old": 9.200392,
    "out:Payback discounted": 13,
    "out:Atemp": 3184,
    "out:Umean": 1,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 161,
    "out:Energy use kWh/m2": 161,
    "out:Energy savings %": 13.7,
    "out:Op energy cost/MSEK": 85,
    "out:El price/MSEK": 52,
    "out:Heating price/MSEK": 28,
    "out:DH+ DHW/kWh/m2": 144,
    "out:El bought/kWh/m2": 1201.83,
    "out:Return %": 8,
    "out:Return/kSEK/y": 221,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 41.7,
    "out:EAHP (El)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 978564.8401067974,
    "out:FTX (Diffusers)": 206476.5625,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 2,
    "out:Payback Time": 50,
    "out:DH kWh savings": 80198.445535,
    "out:EL kWh savings": -14643.211594,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 50912.02,
    "out:DH kr savings": 80198.44553498505,
    "out:El kr savings": -29286.42318831607,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 326943.730695,
    "out:% savings (space heating)": 19.697897,
    "out:Total_Water Heating (DH)": 132772.760051,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 997124.4110739925,
    "out:Eupp": 102.68,
    "out:Etvv": 25,
    "out:Ef": 17.17,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 2684792.300368186,
    "out:Seasonal Variation ROI (%)": -1,
    "out:Seasonal Variation Payback": -999,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 51.837696,
    "out:EL CO2": 2.266445,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 2.266445,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00000000000000110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 97.143758,
    "out:DHW (DH)": 41.7,
    "out:Property Electricity": 17.169,
    "out:Electricity (inc PV)": 17.169,
    "out:Total Energy Use Pre PV": 156.169,
    "out:Total Energy Use Post PV": 156.169,
    "out:Primary Energy": 123.960456,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 2.970127,
    "out:CO2 Operational/m2": 52.304221,
    "out:Total CO2/m2": 55.274348,
    "out:Total CO2 (tons)": 175.993471,
    "out:Klimatpaverkan": -24.14195,
    "out:Initial Cost/MSEK": 2.7643922753681855,
    "out:Running cost/(Apt SEK y)": 30764.06383,
    "out:Running Cost over RSP/MSEK": 11.29958,
    "out:LCP/MSEK": -0.659252,
    "out:ROI% old": 10.878857,
    "out:Payback discounted": 11,
    "out:Atemp": 3184,
    "out:Umean": 1,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 156,
    "out:Energy use kWh/m2": 156,
    "out:Energy savings %": 17.3,
    "out:Op energy cost/MSEK": 82,
    "out:El price/MSEK": 51,
    "out:Heating price/MSEK": 27,
    "out:DH+ DHW/kWh/m2": 139,
    "out:El bought/kWh/m2": 1201.83,
    "out:Return %": 10,
    "out:Return/kSEK/y": 269,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 41.7,
    "out:EAHP (El)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 978564.8401067974,
    "out:FTX (Diffusers)": 206476.5625,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 2,
    "out:Payback Time": 50,
    "out:DH kWh savings": 97836.544777,
    "out:EL kWh savings": -14643.211594,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 79599.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 68550.12,
    "out:DH kr savings": 97836.54477684283,
    "out:El kr savings": -29286.42318831607,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 309305.631453,
    "out:% savings (space heating)": 24.030069,
    "out:Total_Water Heating (DH)": 132772.760051,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 997124.4110739925,
    "out:Eupp": 97.14,
    "out:Etvv": 25,
    "out:Ef": 17.17,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 2764392.2753681857,
    "out:Seasonal Variation ROI (%)": 0,
    "out:Seasonal Variation Payback": -999,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 50.037776,
    "out:EL CO2": 2.266445,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 2.266445,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00000000000001010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 102.683363,
    "out:DHW (DH)": 35.445,
    "out:Property Electricity": 17.169,
    "out:Electricity (inc PV)": 17.169,
    "out:Total Energy Use Pre PV": 155.169,
    "out:Total Energy Use Post PV": 155.169,
    "out:Primary Energy": 125.644038,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 3.67867,
    "out:CO2 Operational/m2": 51.944237,
    "out:Total CO2/m2": 55.622907,
    "out:Total CO2 (tons)": 177.103283,
    "out:Klimatpaverkan": -23.032138,
    "out:Initial Cost/MSEK": 2.9580083003681863,
    "out:Running cost/(Apt SEK y)": 30559.489362,
    "out:Running Cost over RSP/MSEK": 11.22437,
    "out:LCP/MSEK": -0.777658,
    "out:ROI% old": 10.53001,
    "out:Payback discounted": 11,
    "out:Atemp": 3184,
    "out:Umean": 1,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 155,
    "out:Energy use kWh/m2": 155,
    "out:Energy savings %": 18.1,
    "out:Op energy cost/MSEK": 82,
    "out:El price/MSEK": 51,
    "out:Heating price/MSEK": 27,
    "out:DH+ DHW/kWh/m2": 138,
    "out:El bought/kWh/m2": 1201.83,
    "out:Return %": 9,
    "out:Return/kSEK/y": 278,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 35.445,
    "out:EAHP (El)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 978564.8401067974,
    "out:FTX (Diffusers)": 206476.5625,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 2,
    "out:Payback Time": 50,
    "out:DH kWh savings": 100114.359543,
    "out:EL kWh savings": -14643.211594,
    "out:Sewage heat recovery cost": 273216,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 70827.94,
    "out:DH kr savings": 100114.35954269503,
    "out:El kr savings": -29286.42318831607,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 326943.730695,
    "out:% savings (space heating)": 19.697897,
    "out:Total_Water Heating (DH)": 112856.846044,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 997124.4110739925,
    "out:Eupp": 102.68,
    "out:Etvv": 21.25,
    "out:Ef": 17.17,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 2958008.300368186,
    "out:Seasonal Variation ROI (%)": 0,
    "out:Seasonal Variation Payback": -999,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 49.677792,
    "out:EL CO2": 2.266445,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 2.266445,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00000000000001110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 97.143758,
    "out:DHW (DH)": 35.445,
    "out:Property Electricity": 17.169,
    "out:Electricity (inc PV)": 17.169,
    "out:Total Energy Use Pre PV": 150.169,
    "out:Total Energy Use Post PV": 150.169,
    "out:Primary Energy": 121.335456,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 3.67867,
    "out:CO2 Operational/m2": 50.144317,
    "out:Total CO2/m2": 53.822987,
    "out:Total CO2 (tons)": 171.372339,
    "out:Klimatpaverkan": -28.763082,
    "out:Initial Cost/MSEK": 3.037608275368186,
    "out:Running cost/(Apt SEK y)": 29536.531915,
    "out:Running Cost over RSP/MSEK": 10.84831,
    "out:LCP/MSEK": -0.481198,
    "out:ROI% old": 12.022664,
    "out:Payback discounted": 10,
    "out:Atemp": 3184,
    "out:Umean": 1,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 150,
    "out:Energy use kWh/m2": 150,
    "out:Energy savings %": 22,
    "out:Op energy cost/MSEK": 79,
    "out:El price/MSEK": 49,
    "out:Heating price/MSEK": 26,
    "out:DH+ DHW/kWh/m2": 133,
    "out:El bought/kWh/m2": 1201.83,
    "out:Return %": 11,
    "out:Return/kSEK/y": 326,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 35.445,
    "out:EAHP (El)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 978564.8401067974,
    "out:FTX (Diffusers)": 206476.5625,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 117752.458785,
    "out:EL kWh savings": -14643.211594,
    "out:Sewage heat recovery cost": 273216,
    "out:Balancing hydronic system cost": 79599.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 88466.04,
    "out:DH kr savings": 117752.45878455281,
    "out:El kr savings": -29286.42318831607,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 309305.631453,
    "out:% savings (space heating)": 24.030069,
    "out:Total_Water Heating (DH)": 112856.846044,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 997124.4110739925,
    "out:Eupp": 97.14,
    "out:Etvv": 21.25,
    "out:Ef": 17.17,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 3037608.275368186,
    "out:Seasonal Variation ROI (%)": 0,
    "out:Seasonal Variation Payback": -999,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 47.877872,
    "out:EL CO2": 2.266445,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 2.266445,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00000000010000010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 102.683363,
    "out:DHW (DH)": 41.7,
    "out:Property Electricity": 17.169,
    "out:Electricity (inc PV)": 11.433081,
    "out:Total Energy Use Pre PV": 161.169,
    "out:Total Energy Use Post PV": 155.433081,
    "out:Primary Energy": 117.944384,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 17.709227,
    "out:CO2 Operational/m2": 14.946223,
    "out:Total CO2/m2": 32.65545,
    "out:Total CO2 (tons)": 103.974922,
    "out:Klimatpaverkan": -96.1605,
    "out:Initial Cost/MSEK": 3.475012694590686,
    "out:Running cost/(Apt SEK y)": 30237.574468,
    "out:Running Cost over RSP/MSEK": 11.12141,
    "out:LCP/MSEK": -1.191703,
    "out:ROI% old": 9.386646,
    "out:Payback discounted": 13,
    "out:Atemp": 3184,
    "out:Umean": 1,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 155,
    "out:Energy use kWh/m2": 155,
    "out:Energy savings %": 18.1,
    "out:Op energy cost/MSEK": 82,
    "out:El price/MSEK": 49,
    "out:Heating price/MSEK": 28,
    "out:DH+ DHW/kWh/m2": 144,
    "out:El bought/kWh/m2": 807.631491,
    "out:Return %": 8,
    "out:Return/kSEK/y": 294,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 41.7,
    "out:EAHP (El)": 0,
    "out:PV (kr/kWp)": 17461.56255567083,
    "out:PV (kWp installed)": 45.25485,
    "out:PV (m2 panels)": 237.433632,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 42461.284501,
    "out:PV (kWh sold)": 24198.124428,
    "out:PV (% sold (El))": 56.988677,
    "out:PV (kWh self-consumed)": 18263.160073,
    "out:PV (ratio sold/self-consumed)": 1.324969,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 978564.8401067974,
    "out:FTX (Diffusers)": 206476.5625,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 80198.445535,
    "out:EL kWh savings": 3619.949007,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 123735.53,
    "out:DH kr savings": 80198.44553498505,
    "out:El kr savings": 7239.898013663279,
    "out:El kr sold": 36297.18664221638,
    "out:El kr saved": 36526.320146,
    "out:El kr return": 72823.50678821639,
    "out:% solar/total": 46,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 326943.730695,
    "out:% savings (space heating)": 19.697897,
    "out:Total_Water Heating (DH)": 132772.760051,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 997124.4110739925,
    "out:Eupp": 102.68,
    "out:Etvv": 25,
    "out:Ef": 11.43,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 3475012.694590686,
    "out:Seasonal Variation ROI (%)": 1,
    "out:Seasonal Variation Payback": 100,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 51.837696,
    "out:EL CO2": -36.891473,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -38.403999,
    "out:Bought CO2": 1.512526,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00000000010000110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 97.143758,
    "out:DHW (DH)": 41.7,
    "out:Property Electricity": 17.169,
    "out:Electricity (inc PV)": 11.433081,
    "out:Total Energy Use Pre PV": 156.169,
    "out:Total Energy Use Post PV": 150.433081,
    "out:Primary Energy": 113.635802,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 17.709227,
    "out:CO2 Operational/m2": 13.146303,
    "out:Total CO2/m2": 30.85553,
    "out:Total CO2 (tons)": 98.243978,
    "out:Klimatpaverkan": -101.891443,
    "out:Initial Cost/MSEK": 3.554612669590686,
    "out:Running cost/(Apt SEK y)": 29214.638298,
    "out:Running Cost over RSP/MSEK": 10.74535,
    "out:LCP/MSEK": -0.895243,
    "out:ROI% old": 10.687803,
    "out:Payback discounted": 11,
    "out:Atemp": 3184,
    "out:Umean": 1,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 150,
    "out:Energy use kWh/m2": 150,
    "out:Energy savings %": 22,
    "out:Op energy cost/MSEK": 79,
    "out:El price/MSEK": 47,
    "out:Heating price/MSEK": 27,
    "out:DH+ DHW/kWh/m2": 139,
    "out:El bought/kWh/m2": 807.631491,
    "out:Return %": 10,
    "out:Return/kSEK/y": 342,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 41.7,
    "out:EAHP (El)": 0,
    "out:PV (kr/kWp)": 17461.56255567083,
    "out:PV (kWp installed)": 45.25485,
    "out:PV (m2 panels)": 237.433632,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 42461.284501,
    "out:PV (kWh sold)": 24198.124428,
    "out:PV (% sold (El))": 56.988677,
    "out:PV (kWh self-consumed)": 18263.160073,
    "out:PV (ratio sold/self-consumed)": 1.324969,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 978564.8401067974,
    "out:FTX (Diffusers)": 206476.5625,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 97836.544777,
    "out:EL kWh savings": 3619.949007,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 79599.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 141373.63,
    "out:DH kr savings": 97836.54477684283,
    "out:El kr savings": 7239.898013663279,
    "out:El kr sold": 36297.18664221638,
    "out:El kr saved": 36526.320146,
    "out:El kr return": 72823.50678821639,
    "out:% solar/total": 46,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 309305.631453,
    "out:% savings (space heating)": 24.030069,
    "out:Total_Water Heating (DH)": 132772.760051,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 997124.4110739925,
    "out:Eupp": 97.14,
    "out:Etvv": 25,
    "out:Ef": 11.43,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 3554612.669590686,
    "out:Seasonal Variation ROI (%)": 2,
    "out:Seasonal Variation Payback": 50,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 50.037776,
    "out:EL CO2": -36.891473,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -38.403999,
    "out:Bought CO2": 1.512526,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00000000010001010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 102.683363,
    "out:DHW (DH)": 35.445,
    "out:Property Electricity": 17.169,
    "out:Electricity (inc PV)": 11.433081,
    "out:Total Energy Use Pre PV": 155.169,
    "out:Total Energy Use Post PV": 149.433081,
    "out:Primary Energy": 115.319384,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 18.41777,
    "out:CO2 Operational/m2": 12.786319,
    "out:Total CO2/m2": 31.204089,
    "out:Total CO2 (tons)": 99.353789,
    "out:Klimatpaverkan": -100.781632,
    "out:Initial Cost/MSEK": 3.748228694590686,
    "out:Running cost/(Apt SEK y)": 29010.042553,
    "out:Running Cost over RSP/MSEK": 10.67014,
    "out:LCP/MSEK": -1.013649,
    "out:ROI% old": 10.422371,
    "out:Payback discounted": 12,
    "out:Atemp": 3184,
    "out:Umean": 1,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 149,
    "out:Energy use kWh/m2": 149,
    "out:Energy savings %": 22.8,
    "out:Op energy cost/MSEK": 78,
    "out:El price/MSEK": 47,
    "out:Heating price/MSEK": 27,
    "out:DH+ DHW/kWh/m2": 138,
    "out:El bought/kWh/m2": 807.631491,
    "out:Return %": 9,
    "out:Return/kSEK/y": 351,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 35.445,
    "out:EAHP (El)": 0,
    "out:PV (kr/kWp)": 17461.56255567083,
    "out:PV (kWp installed)": 45.25485,
    "out:PV (m2 panels)": 237.433632,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 42461.284501,
    "out:PV (kWh sold)": 24198.124428,
    "out:PV (% sold (El))": 56.988677,
    "out:PV (kWh self-consumed)": 18263.160073,
    "out:PV (ratio sold/self-consumed)": 1.324969,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 978564.8401067974,
    "out:FTX (Diffusers)": 206476.5625,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 100114.359543,
    "out:EL kWh savings": 3619.949007,
    "out:Sewage heat recovery cost": 273216,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 143651.44,
    "out:DH kr savings": 100114.35954269503,
    "out:El kr savings": 7239.898013663279,
    "out:El kr sold": 36297.18664221638,
    "out:El kr saved": 36526.320146,
    "out:El kr return": 72823.50678821639,
    "out:% solar/total": 46,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 326943.730695,
    "out:% savings (space heating)": 19.697897,
    "out:Total_Water Heating (DH)": 112856.846044,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 997124.4110739925,
    "out:Eupp": 102.68,
    "out:Etvv": 21.25,
    "out:Ef": 11.43,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 3748228.694590686,
    "out:Seasonal Variation ROI (%)": 2,
    "out:Seasonal Variation Payback": 50,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 49.677792,
    "out:EL CO2": -36.891473,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -38.403999,
    "out:Bought CO2": 1.512526,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00000000010001110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 97.143758,
    "out:DHW (DH)": 35.445,
    "out:Property Electricity": 17.169,
    "out:Electricity (inc PV)": 11.433081,
    "out:Total Energy Use Pre PV": 150.169,
    "out:Total Energy Use Post PV": 144.433081,
    "out:Primary Energy": 111.010802,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 18.41777,
    "out:CO2 Operational/m2": 10.986399,
    "out:Total CO2/m2": 29.404169,
    "out:Total CO2 (tons)": 93.622846,
    "out:Klimatpaverkan": -106.512575,
    "out:Initial Cost/MSEK": 3.8278286695906862,
    "out:Running cost/(Apt SEK y)": 27987.106383,
    "out:Running Cost over RSP/MSEK": 10.29409,
    "out:LCP/MSEK": -0.717199,
    "out:ROI% old": 11.609081,
    "out:Payback discounted": 10,
    "out:Atemp": 3184,
    "out:Umean": 1,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 144,
    "out:Energy use kWh/m2": 144,
    "out:Energy savings %": 27.1,
    "out:Op energy cost/MSEK": 76,
    "out:El price/MSEK": 45,
    "out:Heating price/MSEK": 26,
    "out:DH+ DHW/kWh/m2": 133,
    "out:El bought/kWh/m2": 807.631491,
    "out:Return %": 10,
    "out:Return/kSEK/y": 399,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 35.445,
    "out:EAHP (El)": 0,
    "out:PV (kr/kWp)": 17461.56255567083,
    "out:PV (kWp installed)": 45.25485,
    "out:PV (m2 panels)": 237.433632,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 42461.284501,
    "out:PV (kWh sold)": 24198.124428,
    "out:PV (% sold (El))": 56.988677,
    "out:PV (kWh self-consumed)": 18263.160073,
    "out:PV (ratio sold/self-consumed)": 1.324969,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 978564.8401067974,
    "out:FTX (Diffusers)": 206476.5625,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 117752.458785,
    "out:EL kWh savings": 3619.949007,
    "out:Sewage heat recovery cost": 273216,
    "out:Balancing hydronic system cost": 79599.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 161289.54,
    "out:DH kr savings": 117752.45878455281,
    "out:El kr savings": 7239.898013663279,
    "out:El kr sold": 36297.18664221638,
    "out:El kr saved": 36526.320146,
    "out:El kr return": 72823.50678821639,
    "out:% solar/total": 46,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 309305.631453,
    "out:% savings (space heating)": 24.030069,
    "out:Total_Water Heating (DH)": 112856.846044,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 997124.4110739925,
    "out:Eupp": 97.14,
    "out:Etvv": 21.25,
    "out:Ef": 11.43,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 3827828.6695906864,
    "out:Seasonal Variation ROI (%)": 2,
    "out:Seasonal Variation Payback": 50,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 47.877872,
    "out:EL CO2": -36.891473,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -38.403999,
    "out:Bought CO2": 1.512526,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00000000020000010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 102.683363,
    "out:DHW (DH)": 41.7,
    "out:Property Electricity": 17.169,
    "out:Electricity (inc PV)": 10.564609,
    "out:Total Energy Use Pre PV": 161.169,
    "out:Total Energy Use Post PV": 154.564609,
    "out:Primary Energy": 116.381134,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 32.448326,
    "out:CO2 Operational/m2": -48.637426,
    "out:Total CO2/m2": -16.1891,
    "out:Total CO2 (tons)": -51.546079,
    "out:Klimatpaverkan": -251.6815,
    "out:Initial Cost/MSEK": 4.265226046311937,
    "out:Running cost/(Apt SEK y)": 28853.021277,
    "out:Running Cost over RSP/MSEK": 10.62666,
    "out:LCP/MSEK": -1.487166,
    "out:ROI% old": 9.304682,
    "out:Payback discounted": 13,
    "out:Atemp": 3184,
    "out:Umean": 1,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 155,
    "out:Energy use kWh/m2": 155,
    "out:Energy savings %": 18.1,
    "out:Op energy cost/MSEK": 79,
    "out:El price/MSEK": 46,
    "out:Heating price/MSEK": 28,
    "out:DH+ DHW/kWh/m2": 144,
    "out:El bought/kWh/m2": 744.654907,
    "out:Return %": 8,
    "out:Return/kSEK/y": 359,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 41.7,
    "out:EAHP (El)": 0,
    "out:PV (kr/kWp)": 17461.48474631725,
    "out:PV (kWp installed)": 90.5097,
    "out:PV (m2 panels)": 474.867263,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 84922.568824,
    "out:PV (kWh sold)": 63894.192746,
    "out:PV (% sold (El))": 75.238177,
    "out:PV (kWh self-consumed)": 21028.376078,
    "out:PV (ratio sold/self-consumed)": 3.038475,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 978564.8401067974,
    "out:FTX (Diffusers)": 206476.5625,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 80198.445535,
    "out:EL kWh savings": 6385.163023,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 188810.06,
    "out:DH kr savings": 80198.44553498505,
    "out:El kr savings": 12770.326045670909,
    "out:El kr sold": 95841.28911946694,
    "out:El kr saved": 42056.752156,
    "out:El kr return": 137898.04127546694,
    "out:% solar/total": 53,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 326943.730695,
    "out:% savings (space heating)": 19.697897,
    "out:Total_Water Heating (DH)": 132772.760051,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 997124.4110739925,
    "out:Eupp": 102.68,
    "out:Etvv": 25,
    "out:Ef": 10.56,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 4265226.046311936,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 51.837696,
    "out:EL CO2": -100.475122,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -101.871982,
    "out:Bought CO2": 1.39686,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00000000020000110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 97.143758,
    "out:DHW (DH)": 41.7,
    "out:Property Electricity": 17.169,
    "out:Electricity (inc PV)": 10.564609,
    "out:Total Energy Use Pre PV": 156.169,
    "out:Total Energy Use Post PV": 149.564609,
    "out:Primary Energy": 112.072552,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 32.448326,
    "out:CO2 Operational/m2": -50.437346,
    "out:Total CO2/m2": -17.98902,
    "out:Total CO2 (tons)": -57.277022,
    "out:Klimatpaverkan": -257.412443,
    "out:Initial Cost/MSEK": 4.344826021311936,
    "out:Running cost/(Apt SEK y)": 27830.06383,
    "out:Running Cost over RSP/MSEK": 10.2506,
    "out:LCP/MSEK": -1.190706,
    "out:ROI% old": 10.370693,
    "out:Payback discounted": 12,
    "out:Atemp": 3184,
    "out:Umean": 1,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 150,
    "out:Energy use kWh/m2": 150,
    "out:Energy savings %": 22,
    "out:Op energy cost/MSEK": 76,
    "out:El price/MSEK": 44,
    "out:Heating price/MSEK": 27,
    "out:DH+ DHW/kWh/m2": 139,
    "out:El bought/kWh/m2": 744.654907,
    "out:Return %": 9,
    "out:Return/kSEK/y": 407,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 41.7,
    "out:EAHP (El)": 0,
    "out:PV (kr/kWp)": 17461.48474631725,
    "out:PV (kWp installed)": 90.5097,
    "out:PV (m2 panels)": 474.867263,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 84922.568824,
    "out:PV (kWh sold)": 63894.192746,
    "out:PV (% sold (El))": 75.238177,
    "out:PV (kWh self-consumed)": 21028.376078,
    "out:PV (ratio sold/self-consumed)": 3.038475,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 978564.8401067974,
    "out:FTX (Diffusers)": 206476.5625,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 97836.544777,
    "out:EL kWh savings": 6385.163023,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 79599.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 206448.16,
    "out:DH kr savings": 97836.54477684283,
    "out:El kr savings": 12770.326045670909,
    "out:El kr sold": 95841.28911946694,
    "out:El kr saved": 42056.752156,
    "out:El kr return": 137898.04127546694,
    "out:% solar/total": 53,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 309305.631453,
    "out:% savings (space heating)": 24.030069,
    "out:Total_Water Heating (DH)": 132772.760051,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 997124.4110739925,
    "out:Eupp": 97.14,
    "out:Etvv": 25,
    "out:Ef": 10.56,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 4344826.021311936,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 50.037776,
    "out:EL CO2": -100.475122,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -101.871982,
    "out:Bought CO2": 1.39686,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00000000020001010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 102.683363,
    "out:DHW (DH)": 35.445,
    "out:Property Electricity": 17.169,
    "out:Electricity (inc PV)": 10.564609,
    "out:Total Energy Use Pre PV": 155.169,
    "out:Total Energy Use Post PV": 148.564609,
    "out:Primary Energy": 113.756134,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 33.156869,
    "out:CO2 Operational/m2": -50.79733,
    "out:Total CO2/m2": -17.640461,
    "out:Total CO2 (tons)": -56.167211,
    "out:Klimatpaverkan": -256.302632,
    "out:Initial Cost/MSEK": 4.538442046311936,
    "out:Running cost/(Apt SEK y)": 27625.489362,
    "out:Running Cost over RSP/MSEK": 10.17539,
    "out:LCP/MSEK": -1.309112,
    "out:ROI% old": 10.165005,
    "out:Payback discounted": 12,
    "out:Atemp": 3184,
    "out:Umean": 1,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 149,
    "out:Energy use kWh/m2": 149,
    "out:Energy savings %": 22.8,
    "out:Op energy cost/MSEK": 75,
    "out:El price/MSEK": 44,
    "out:Heating price/MSEK": 27,
    "out:DH+ DHW/kWh/m2": 138,
    "out:El bought/kWh/m2": 744.654907,
    "out:Return %": 9,
    "out:Return/kSEK/y": 416,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 35.445,
    "out:EAHP (El)": 0,
    "out:PV (kr/kWp)": 17461.48474631725,
    "out:PV (kWp installed)": 90.5097,
    "out:PV (m2 panels)": 474.867263,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 84922.568824,
    "out:PV (kWh sold)": 63894.192746,
    "out:PV (% sold (El))": 75.238177,
    "out:PV (kWh self-consumed)": 21028.376078,
    "out:PV (ratio sold/self-consumed)": 3.038475,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 978564.8401067974,
    "out:FTX (Diffusers)": 206476.5625,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 100114.359543,
    "out:EL kWh savings": 6385.163023,
    "out:Sewage heat recovery cost": 273216,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 208725.97,
    "out:DH kr savings": 100114.35954269503,
    "out:El kr savings": 12770.326045670909,
    "out:El kr sold": 95841.28911946694,
    "out:El kr saved": 42056.752156,
    "out:El kr return": 137898.04127546694,
    "out:% solar/total": 53,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 326943.730695,
    "out:% savings (space heating)": 19.697897,
    "out:Total_Water Heating (DH)": 112856.846044,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 997124.4110739925,
    "out:Eupp": 102.68,
    "out:Etvv": 21.25,
    "out:Ef": 10.56,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 4538442.046311936,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 49.677792,
    "out:EL CO2": -100.475122,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -101.871982,
    "out:Bought CO2": 1.39686,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00000000020001110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 97.143758,
    "out:DHW (DH)": 35.445,
    "out:Property Electricity": 17.169,
    "out:Electricity (inc PV)": 10.564609,
    "out:Total Energy Use Pre PV": 150.169,
    "out:Total Energy Use Post PV": 143.564609,
    "out:Primary Energy": 109.447552,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 33.156869,
    "out:CO2 Operational/m2": -52.59725,
    "out:Total CO2/m2": -19.440381,
    "out:Total CO2 (tons)": -61.898154,
    "out:Klimatpaverkan": -262.033576,
    "out:Initial Cost/MSEK": 4.618042021311936,
    "out:Running cost/(Apt SEK y)": 26602.531915,
    "out:Running Cost over RSP/MSEK": 9.79933,
    "out:LCP/MSEK": -1.012652,
    "out:ROI% old": 11.153119,
    "out:Payback discounted": 11,
    "out:Atemp": 3184,
    "out:Umean": 1,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 144,
    "out:Energy use kWh/m2": 144,
    "out:Energy savings %": 27.1,
    "out:Op energy cost/MSEK": 73,
    "out:El price/MSEK": 42,
    "out:Heating price/MSEK": 26,
    "out:DH+ DHW/kWh/m2": 133,
    "out:El bought/kWh/m2": 744.654907,
    "out:Return %": 10,
    "out:Return/kSEK/y": 464,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 35.445,
    "out:EAHP (El)": 0,
    "out:PV (kr/kWp)": 17461.48474631725,
    "out:PV (kWp installed)": 90.5097,
    "out:PV (m2 panels)": 474.867263,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 84922.568824,
    "out:PV (kWh sold)": 63894.192746,
    "out:PV (% sold (El))": 75.238177,
    "out:PV (kWh self-consumed)": 21028.376078,
    "out:PV (ratio sold/self-consumed)": 3.038475,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 978564.8401067974,
    "out:FTX (Diffusers)": 206476.5625,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 117752.458785,
    "out:EL kWh savings": 6385.163023,
    "out:Sewage heat recovery cost": 273216,
    "out:Balancing hydronic system cost": 79599.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 226364.07,
    "out:DH kr savings": 117752.45878455281,
    "out:El kr savings": 12770.326045670909,
    "out:El kr sold": 95841.28911946694,
    "out:El kr saved": 42056.752156,
    "out:El kr return": 137898.04127546694,
    "out:% solar/total": 53,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 309305.631453,
    "out:% savings (space heating)": 24.030069,
    "out:Total_Water Heating (DH)": 112856.846044,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 997124.4110739925,
    "out:Eupp": 97.14,
    "out:Etvv": 21.25,
    "out:Ef": 10.56,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 4618042.021311936,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 47.877872,
    "out:EL CO2": -100.475122,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -101.871982,
    "out:Bought CO2": 1.39686,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00000000100000010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 102.683363,
    "out:DHW (DH)": 33.36,
    "out:Property Electricity": 17.169,
    "out:Electricity (inc PV)": 17.169,
    "out:Total Energy Use Pre PV": 153.169,
    "out:Total Energy Use Post PV": 153.169,
    "out:Primary Energy": 124.769038,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 2.970127,
    "out:CO2 Operational/m2": 51.224269,
    "out:Total CO2/m2": 54.194396,
    "out:Total CO2 (tons)": 172.554905,
    "out:Klimatpaverkan": -27.580516,
    "out:Initial Cost/MSEK": 2.8088399878681862,
    "out:Running cost/(Apt SEK y)": 30150.297872,
    "out:Running Cost over RSP/MSEK": 11.07395,
    "out:LCP/MSEK": -0.47807,
    "out:ROI% old": 11.854258,
    "out:Payback discounted": 10,
    "out:Atemp": 3184,
    "out:Umean": 1,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 153,
    "out:Energy use kWh/m2": 153,
    "out:Energy savings %": 19.6,
    "out:Op energy cost/MSEK": 81,
    "out:El price/MSEK": 50,
    "out:Heating price/MSEK": 27,
    "out:DH+ DHW/kWh/m2": 136,
    "out:El bought/kWh/m2": 1201.83,
    "out:Return %": 11,
    "out:Return/kSEK/y": 298,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 33.36,
    "out:EAHP (El)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 978564.8401067974,
    "out:FTX (Diffusers)": 206476.5625,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 106752.997545,
    "out:EL kWh savings": -14643.211594,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 77466.57,
    "out:DH kr savings": 106752.99754526507,
    "out:El kr savings": -29286.42318831607,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 326943.730695,
    "out:% savings (space heating)": 19.697897,
    "out:Total_Water Heating (DH)": 106218.208041,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 997124.4110739925,
    "out:Eupp": 102.68,
    "out:Etvv": 20,
    "out:Ef": 17.17,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 2808839.987868186,
    "out:Seasonal Variation ROI (%)": 0,
    "out:Seasonal Variation Payback": -999,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 48.957824,
    "out:EL CO2": 2.266445,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 2.266445,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00000000100000110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 97.143758,
    "out:DHW (DH)": 33.36,
    "out:Property Electricity": 17.169,
    "out:Electricity (inc PV)": 17.169,
    "out:Total Energy Use Pre PV": 148.169,
    "out:Total Energy Use Post PV": 148.169,
    "out:Primary Energy": 120.460456,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 2.970127,
    "out:CO2 Operational/m2": 49.424349,
    "out:Total CO2/m2": 52.394476,
    "out:Total CO2 (tons)": 166.823961,
    "out:Klimatpaverkan": -33.31146,
    "out:Initial Cost/MSEK": 2.8884399628681856,
    "out:Running cost/(Apt SEK y)": 29127.361702,
    "out:Running Cost over RSP/MSEK": 10.69789,
    "out:LCP/MSEK": -0.18161,
    "out:ROI% old": 13.387503,
    "out:Payback discounted": 9,
    "out:Atemp": 3184,
    "out:Umean": 1,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 148,
    "out:Energy use kWh/m2": 148,
    "out:Energy savings %": 23.6,
    "out:Op energy cost/MSEK": 78,
    "out:El price/MSEK": 48,
    "out:Heating price/MSEK": 26,
    "out:DH+ DHW/kWh/m2": 131,
    "out:El bought/kWh/m2": 1201.83,
    "out:Return %": 12,
    "out:Return/kSEK/y": 346,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 33.36,
    "out:EAHP (El)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 978564.8401067974,
    "out:FTX (Diffusers)": 206476.5625,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 124391.096787,
    "out:EL kWh savings": -14643.211594,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 79599.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 95104.67,
    "out:DH kr savings": 124391.09678712275,
    "out:El kr savings": -29286.42318831607,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 309305.631453,
    "out:% savings (space heating)": 24.030069,
    "out:Total_Water Heating (DH)": 106218.208041,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 997124.4110739925,
    "out:Eupp": 97.14,
    "out:Etvv": 20,
    "out:Ef": 17.17,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 2888439.9628681857,
    "out:Seasonal Variation ROI (%)": 0,
    "out:Seasonal Variation Payback": -999,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 47.157904,
    "out:EL CO2": 2.266445,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 2.266445,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00000000100001010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 102.683363,
    "out:DHW (DH)": 28.356,
    "out:Property Electricity": 17.169,
    "out:Electricity (inc PV)": 17.169,
    "out:Total Energy Use Pre PV": 148.169,
    "out:Total Energy Use Post PV": 148.169,
    "out:Primary Energy": 122.669038,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 3.67867,
    "out:CO2 Operational/m2": 49.424349,
    "out:Total CO2/m2": 53.103019,
    "out:Total CO2 (tons)": 169.079962,
    "out:Klimatpaverkan": -31.055459,
    "out:Initial Cost/MSEK": 3.082055987868186,
    "out:Running cost/(Apt SEK y)": 29127.361702,
    "out:Running Cost over RSP/MSEK": 10.69789,
    "out:LCP/MSEK": -0.375226,
    "out:ROI% old": 12.546495,
    "out:Payback discounted": 10,
    "out:Atemp": 3184,
    "out:Umean": 1,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 148,
    "out:Energy use kWh/m2": 148,
    "out:Energy savings %": 23.6,
    "out:Op energy cost/MSEK": 78,
    "out:El price/MSEK": 48,
    "out:Heating price/MSEK": 26,
    "out:DH+ DHW/kWh/m2": 131,
    "out:El bought/kWh/m2": 1201.83,
    "out:Return %": 11,
    "out:Return/kSEK/y": 346,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 28.356,
    "out:EAHP (El)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 978564.8401067974,
    "out:FTX (Diffusers)": 206476.5625,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 122685.728751,
    "out:EL kWh savings": -14643.211594,
    "out:Sewage heat recovery cost": 273216,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 93399.31,
    "out:DH kr savings": 122685.72875143304,
    "out:El kr savings": -29286.42318831607,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 326943.730695,
    "out:% savings (space heating)": 19.697897,
    "out:Total_Water Heating (DH)": 90285.476835,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 997124.4110739925,
    "out:Eupp": 102.68,
    "out:Etvv": 17,
    "out:Ef": 17.17,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 3082055.987868186,
    "out:Seasonal Variation ROI (%)": 0,
    "out:Seasonal Variation Payback": -999,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 47.157904,
    "out:EL CO2": 2.266445,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 2.266445,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00000000100001110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 97.143758,
    "out:DHW (DH)": 28.356,
    "out:Property Electricity": 17.169,
    "out:Electricity (inc PV)": 17.169,
    "out:Total Energy Use Pre PV": 142.169,
    "out:Total Energy Use Post PV": 142.169,
    "out:Primary Energy": 118.360456,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 3.67867,
    "out:CO2 Operational/m2": 47.264445,
    "out:Total CO2/m2": 50.943115,
    "out:Total CO2 (tons)": 162.202829,
    "out:Klimatpaverkan": -37.932592,
    "out:Initial Cost/MSEK": 3.161655962868186,
    "out:Running cost/(Apt SEK y)": 27899.829787,
    "out:Running Cost over RSP/MSEK": 10.24663,
    "out:LCP/MSEK": -0.003566,
    "out:ROI% old": 14.269602,
    "out:Payback discounted": 9,
    "out:Atemp": 3184,
    "out:Umean": 1,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 142,
    "out:Energy use kWh/m2": 142,
    "out:Energy savings %": 28.9,
    "out:Op energy cost/MSEK": 75,
    "out:El price/MSEK": 46,
    "out:Heating price/MSEK": 24,
    "out:DH+ DHW/kWh/m2": 125,
    "out:El bought/kWh/m2": 1201.83,
    "out:Return %": 13,
    "out:Return/kSEK/y": 403,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 28.356,
    "out:EAHP (El)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 978564.8401067974,
    "out:FTX (Diffusers)": 206476.5625,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 140323.827993,
    "out:EL kWh savings": -14643.211594,
    "out:Sewage heat recovery cost": 273216,
    "out:Balancing hydronic system cost": 79599.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 111037.4,
    "out:DH kr savings": 140323.8279932908,
    "out:El kr savings": -29286.42318831607,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 309305.631453,
    "out:% savings (space heating)": 24.030069,
    "out:Total_Water Heating (DH)": 90285.476835,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 997124.4110739925,
    "out:Eupp": 97.14,
    "out:Etvv": 17,
    "out:Ef": 17.17,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 3161655.962868186,
    "out:Seasonal Variation ROI (%)": 1,
    "out:Seasonal Variation Payback": 100,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 44.998,
    "out:EL CO2": 2.266445,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 2.266445,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00000000110000010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 102.683363,
    "out:DHW (DH)": 33.36,
    "out:Property Electricity": 17.169,
    "out:Electricity (inc PV)": 11.433081,
    "out:Total Energy Use Pre PV": 153.169,
    "out:Total Energy Use Post PV": 147.433081,
    "out:Primary Energy": 114.444384,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 17.709227,
    "out:CO2 Operational/m2": 12.066351,
    "out:Total CO2/m2": 29.775578,
    "out:Total CO2 (tons)": 94.805412,
    "out:Klimatpaverkan": -105.330009,
    "out:Initial Cost/MSEK": 3.599060382090686,
    "out:Running cost/(Apt SEK y)": 28600.87234,
    "out:Running Cost over RSP/MSEK": 10.51972,
    "out:LCP/MSEK": -0.71406,
    "out:ROI% old": 11.451402,
    "out:Payback discounted": 11,
    "out:Atemp": 3184,
    "out:Umean": 1,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 147,
    "out:Energy use kWh/m2": 147,
    "out:Energy savings %": 24.5,
    "out:Op energy cost/MSEK": 77,
    "out:El price/MSEK": 46,
    "out:Heating price/MSEK": 27,
    "out:DH+ DHW/kWh/m2": 136,
    "out:El bought/kWh/m2": 807.631491,
    "out:Return %": 10,
    "out:Return/kSEK/y": 370,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 33.36,
    "out:EAHP (El)": 0,
    "out:PV (kr/kWp)": 17461.56255567083,
    "out:PV (kWp installed)": 45.25485,
    "out:PV (m2 panels)": 237.433632,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 42461.284501,
    "out:PV (kWh sold)": 24198.124428,
    "out:PV (% sold (El))": 56.988677,
    "out:PV (kWh self-consumed)": 18263.160073,
    "out:PV (ratio sold/self-consumed)": 1.324969,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 978564.8401067974,
    "out:FTX (Diffusers)": 206476.5625,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 106752.997545,
    "out:EL kWh savings": 3619.949007,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 150290.08,
    "out:DH kr savings": 106752.99754526507,
    "out:El kr savings": 7239.898013663279,
    "out:El kr sold": 36297.18664221638,
    "out:El kr saved": 36526.320146,
    "out:El kr return": 72823.50678821639,
    "out:% solar/total": 46,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 326943.730695,
    "out:% savings (space heating)": 19.697897,
    "out:Total_Water Heating (DH)": 106218.208041,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 997124.4110739925,
    "out:Eupp": 102.68,
    "out:Etvv": 20,
    "out:Ef": 11.43,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 3599060.382090686,
    "out:Seasonal Variation ROI (%)": 2,
    "out:Seasonal Variation Payback": 50,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 48.957824,
    "out:EL CO2": -36.891473,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -38.403999,
    "out:Bought CO2": 1.512526,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00000000110000110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 97.143758,
    "out:DHW (DH)": 33.36,
    "out:Property Electricity": 17.169,
    "out:Electricity (inc PV)": 11.433081,
    "out:Total Energy Use Pre PV": 148.169,
    "out:Total Energy Use Post PV": 142.433081,
    "out:Primary Energy": 110.135802,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 17.709227,
    "out:CO2 Operational/m2": 10.266431,
    "out:Total CO2/m2": 27.975658,
    "out:Total CO2 (tons)": 89.074468,
    "out:Klimatpaverkan": -111.060953,
    "out:Initial Cost/MSEK": 3.678660357090686,
    "out:Running cost/(Apt SEK y)": 27577.914894,
    "out:Running Cost over RSP/MSEK": 10.14366,
    "out:LCP/MSEK": -0.4176,
    "out:ROI% old": 12.664005,
    "out:Payback discounted": 10,
    "out:Atemp": 3184,
    "out:Umean": 1,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 142,
    "out:Energy use kWh/m2": 142,
    "out:Energy savings %": 28.9,
    "out:Op energy cost/MSEK": 75,
    "out:El price/MSEK": 45,
    "out:Heating price/MSEK": 26,
    "out:DH+ DHW/kWh/m2": 131,
    "out:El bought/kWh/m2": 807.631491,
    "out:Return %": 11,
    "out:Return/kSEK/y": 419,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 33.36,
    "out:EAHP (El)": 0,
    "out:PV (kr/kWp)": 17461.56255567083,
    "out:PV (kWp installed)": 45.25485,
    "out:PV (m2 panels)": 237.433632,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 42461.284501,
    "out:PV (kWh sold)": 24198.124428,
    "out:PV (% sold (El))": 56.988677,
    "out:PV (kWh self-consumed)": 18263.160073,
    "out:PV (ratio sold/self-consumed)": 1.324969,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 978564.8401067974,
    "out:FTX (Diffusers)": 206476.5625,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 124391.096787,
    "out:EL kWh savings": 3619.949007,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 79599.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 167928.18,
    "out:DH kr savings": 124391.09678712275,
    "out:El kr savings": 7239.898013663279,
    "out:El kr sold": 36297.18664221638,
    "out:El kr saved": 36526.320146,
    "out:El kr return": 72823.50678821639,
    "out:% solar/total": 46,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 309305.631453,
    "out:% savings (space heating)": 24.030069,
    "out:Total_Water Heating (DH)": 106218.208041,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 997124.4110739925,
    "out:Eupp": 97.14,
    "out:Etvv": 20,
    "out:Ef": 11.43,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 3678660.357090686,
    "out:Seasonal Variation ROI (%)": 2,
    "out:Seasonal Variation Payback": 50,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 47.157904,
    "out:EL CO2": -36.891473,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -38.403999,
    "out:Bought CO2": 1.512526,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00000000110001010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 102.683363,
    "out:DHW (DH)": 28.356,
    "out:Property Electricity": 17.169,
    "out:Electricity (inc PV)": 11.433081,
    "out:Total Energy Use Pre PV": 148.169,
    "out:Total Energy Use Post PV": 142.433081,
    "out:Primary Energy": 112.344384,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 18.41777,
    "out:CO2 Operational/m2": 10.266431,
    "out:Total CO2/m2": 28.684201,
    "out:Total CO2 (tons)": 91.330469,
    "out:Klimatpaverkan": -108.804953,
    "out:Initial Cost/MSEK": 3.8722763820906865,
    "out:Running cost/(Apt SEK y)": 27577.914894,
    "out:Running Cost over RSP/MSEK": 10.14366,
    "out:LCP/MSEK": -0.611216,
    "out:ROI% old": 12.030797,
    "out:Payback discounted": 10,
    "out:Atemp": 3184,
    "out:Umean": 1,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 142,
    "out:Energy use kWh/m2": 142,
    "out:Energy savings %": 28.9,
    "out:Op energy cost/MSEK": 75,
    "out:El price/MSEK": 45,
    "out:Heating price/MSEK": 26,
    "out:DH+ DHW/kWh/m2": 131,
    "out:El bought/kWh/m2": 807.631491,
    "out:Return %": 11,
    "out:Return/kSEK/y": 419,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 28.356,
    "out:EAHP (El)": 0,
    "out:PV (kr/kWp)": 17461.56255567083,
    "out:PV (kWp installed)": 45.25485,
    "out:PV (m2 panels)": 237.433632,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 42461.284501,
    "out:PV (kWh sold)": 24198.124428,
    "out:PV (% sold (El))": 56.988677,
    "out:PV (kWh self-consumed)": 18263.160073,
    "out:PV (ratio sold/self-consumed)": 1.324969,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 978564.8401067974,
    "out:FTX (Diffusers)": 206476.5625,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 122685.728751,
    "out:EL kWh savings": 3619.949007,
    "out:Sewage heat recovery cost": 273216,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 166222.81,
    "out:DH kr savings": 122685.72875143304,
    "out:El kr savings": 7239.898013663279,
    "out:El kr sold": 36297.18664221638,
    "out:El kr saved": 36526.320146,
    "out:El kr return": 72823.50678821639,
    "out:% solar/total": 46,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 326943.730695,
    "out:% savings (space heating)": 19.697897,
    "out:Total_Water Heating (DH)": 90285.476835,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 997124.4110739925,
    "out:Eupp": 102.68,
    "out:Etvv": 17,
    "out:Ef": 11.43,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 3872276.3820906864,
    "out:Seasonal Variation ROI (%)": 2,
    "out:Seasonal Variation Payback": 50,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 47.157904,
    "out:EL CO2": -36.891473,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -38.403999,
    "out:Bought CO2": 1.512526,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00000000110001110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 97.143758,
    "out:DHW (DH)": 28.356,
    "out:Property Electricity": 17.169,
    "out:Electricity (inc PV)": 11.433081,
    "out:Total Energy Use Pre PV": 142.169,
    "out:Total Energy Use Post PV": 136.433081,
    "out:Primary Energy": 108.035802,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 18.41777,
    "out:CO2 Operational/m2": 8.106527,
    "out:Total CO2/m2": 26.524297,
    "out:Total CO2 (tons)": 84.453336,
    "out:Klimatpaverkan": -115.682085,
    "out:Initial Cost/MSEK": 3.9518763570906854,
    "out:Running cost/(Apt SEK y)": 26350.382979,
    "out:Running Cost over RSP/MSEK": 9.6924,
    "out:LCP/MSEK": -0.239556,
    "out:ROI% old": 13.419737,
    "out:Payback discounted": 9,
    "out:Atemp": 3184,
    "out:Umean": 1,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 136,
    "out:Energy use kWh/m2": 136,
    "out:Energy savings %": 34.6,
    "out:Op energy cost/MSEK": 71,
    "out:El price/MSEK": 43,
    "out:Heating price/MSEK": 24,
    "out:DH+ DHW/kWh/m2": 125,
    "out:El bought/kWh/m2": 807.631491,
    "out:Return %": 12,
    "out:Return/kSEK/y": 476,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 28.356,
    "out:EAHP (El)": 0,
    "out:PV (kr/kWp)": 17461.56255567083,
    "out:PV (kWp installed)": 45.25485,
    "out:PV (m2 panels)": 237.433632,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 42461.284501,
    "out:PV (kWh sold)": 24198.124428,
    "out:PV (% sold (El))": 56.988677,
    "out:PV (kWh self-consumed)": 18263.160073,
    "out:PV (ratio sold/self-consumed)": 1.324969,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 978564.8401067974,
    "out:FTX (Diffusers)": 206476.5625,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 140323.827993,
    "out:EL kWh savings": 3619.949007,
    "out:Sewage heat recovery cost": 273216,
    "out:Balancing hydronic system cost": 79599.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 183860.91,
    "out:DH kr savings": 140323.8279932908,
    "out:El kr savings": 7239.898013663279,
    "out:El kr sold": 36297.18664221638,
    "out:El kr saved": 36526.320146,
    "out:El kr return": 72823.50678821639,
    "out:% solar/total": 46,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 309305.631453,
    "out:% savings (space heating)": 24.030069,
    "out:Total_Water Heating (DH)": 90285.476835,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 997124.4110739925,
    "out:Eupp": 97.14,
    "out:Etvv": 17,
    "out:Ef": 11.43,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 3951876.3570906855,
    "out:Seasonal Variation ROI (%)": 2,
    "out:Seasonal Variation Payback": 50,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 44.998,
    "out:EL CO2": -36.891473,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -38.403999,
    "out:Bought CO2": 1.512526,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00000000120000010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 102.683363,
    "out:DHW (DH)": 33.36,
    "out:Property Electricity": 17.169,
    "out:Electricity (inc PV)": 10.564609,
    "out:Total Energy Use Pre PV": 153.169,
    "out:Total Energy Use Post PV": 146.564609,
    "out:Primary Energy": 112.881134,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 32.448326,
    "out:CO2 Operational/m2": -51.517298,
    "out:Total CO2/m2": -19.068972,
    "out:Total CO2 (tons)": -60.715589,
    "out:Klimatpaverkan": -260.85101,
    "out:Initial Cost/MSEK": 4.389273733811935,
    "out:Running cost/(Apt SEK y)": 27216.297872,
    "out:Running Cost over RSP/MSEK": 10.02497,
    "out:LCP/MSEK": -1.009524,
    "out:ROI% old": 11.00003,
    "out:Payback discounted": 11,
    "out:Atemp": 3184,
    "out:Umean": 1,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 147,
    "out:Energy use kWh/m2": 147,
    "out:Energy savings %": 24.5,
    "out:Op energy cost/MSEK": 74,
    "out:El price/MSEK": 43,
    "out:Heating price/MSEK": 27,
    "out:DH+ DHW/kWh/m2": 136,
    "out:El bought/kWh/m2": 744.654907,
    "out:Return %": 10,
    "out:Return/kSEK/y": 436,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 33.36,
    "out:EAHP (El)": 0,
    "out:PV (kr/kWp)": 17461.48474631725,
    "out:PV (kWp installed)": 90.5097,
    "out:PV (m2 panels)": 474.867263,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 84922.568824,
    "out:PV (kWh sold)": 63894.192746,
    "out:PV (% sold (El))": 75.238177,
    "out:PV (kWh self-consumed)": 21028.376078,
    "out:PV (ratio sold/self-consumed)": 3.038475,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 978564.8401067974,
    "out:FTX (Diffusers)": 206476.5625,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 106752.997545,
    "out:EL kWh savings": 6385.163023,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 215364.61,
    "out:DH kr savings": 106752.99754526507,
    "out:El kr savings": 12770.326045670909,
    "out:El kr sold": 95841.28911946694,
    "out:El kr saved": 42056.752156,
    "out:El kr return": 137898.04127546694,
    "out:% solar/total": 53,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 326943.730695,
    "out:% savings (space heating)": 19.697897,
    "out:Total_Water Heating (DH)": 106218.208041,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 997124.4110739925,
    "out:Eupp": 102.68,
    "out:Etvv": 20,
    "out:Ef": 10.56,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 4389273.733811935,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 48.957824,
    "out:EL CO2": -100.475122,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -101.871982,
    "out:Bought CO2": 1.39686,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00000000120000110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 97.143758,
    "out:DHW (DH)": 33.36,
    "out:Property Electricity": 17.169,
    "out:Electricity (inc PV)": 10.564609,
    "out:Total Energy Use Pre PV": 148.169,
    "out:Total Energy Use Post PV": 141.564609,
    "out:Primary Energy": 108.572552,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 32.448326,
    "out:CO2 Operational/m2": -53.317218,
    "out:Total CO2/m2": -20.868892,
    "out:Total CO2 (tons)": -66.446532,
    "out:Klimatpaverkan": -266.581953,
    "out:Initial Cost/MSEK": 4.468873708811936,
    "out:Running cost/(Apt SEK y)": 26193.361702,
    "out:Running Cost over RSP/MSEK": 9.64891,
    "out:LCP/MSEK": -0.713064,
    "out:ROI% old": 12.006253,
    "out:Payback discounted": 10,
    "out:Atemp": 3184,
    "out:Umean": 1,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 142,
    "out:Energy use kWh/m2": 142,
    "out:Energy savings %": 28.9,
    "out:Op energy cost/MSEK": 72,
    "out:El price/MSEK": 42,
    "out:Heating price/MSEK": 26,
    "out:DH+ DHW/kWh/m2": 131,
    "out:El bought/kWh/m2": 744.654907,
    "out:Return %": 11,
    "out:Return/kSEK/y": 484,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 33.36,
    "out:EAHP (El)": 0,
    "out:PV (kr/kWp)": 17461.48474631725,
    "out:PV (kWp installed)": 90.5097,
    "out:PV (m2 panels)": 474.867263,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 84922.568824,
    "out:PV (kWh sold)": 63894.192746,
    "out:PV (% sold (El))": 75.238177,
    "out:PV (kWh self-consumed)": 21028.376078,
    "out:PV (ratio sold/self-consumed)": 3.038475,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 978564.8401067974,
    "out:FTX (Diffusers)": 206476.5625,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 124391.096787,
    "out:EL kWh savings": 6385.163023,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 79599.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 233002.71,
    "out:DH kr savings": 124391.09678712275,
    "out:El kr savings": 12770.326045670909,
    "out:El kr sold": 95841.28911946694,
    "out:El kr saved": 42056.752156,
    "out:El kr return": 137898.04127546694,
    "out:% solar/total": 53,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 309305.631453,
    "out:% savings (space heating)": 24.030069,
    "out:Total_Water Heating (DH)": 106218.208041,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 997124.4110739925,
    "out:Eupp": 97.14,
    "out:Etvv": 20,
    "out:Ef": 10.56,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 4468873.708811936,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 47.157904,
    "out:EL CO2": -100.475122,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -101.871982,
    "out:Bought CO2": 1.39686,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00000000120001010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 102.683363,
    "out:DHW (DH)": 28.356,
    "out:Property Electricity": 17.169,
    "out:Electricity (inc PV)": 10.564609,
    "out:Total Energy Use Pre PV": 148.169,
    "out:Total Energy Use Post PV": 141.564609,
    "out:Primary Energy": 110.781134,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 33.156869,
    "out:CO2 Operational/m2": -53.317218,
    "out:Total CO2/m2": -20.160349,
    "out:Total CO2 (tons)": -64.190532,
    "out:Klimatpaverkan": -264.325953,
    "out:Initial Cost/MSEK": 4.6624897338119355,
    "out:Running cost/(Apt SEK y)": 26193.361702,
    "out:Running Cost over RSP/MSEK": 9.64891,
    "out:LCP/MSEK": -0.90668,
    "out:ROI% old": 11.507678,
    "out:Payback discounted": 11,
    "out:Atemp": 3184,
    "out:Umean": 1,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 142,
    "out:Energy use kWh/m2": 142,
    "out:Energy savings %": 28.9,
    "out:Op energy cost/MSEK": 72,
    "out:El price/MSEK": 42,
    "out:Heating price/MSEK": 26,
    "out:DH+ DHW/kWh/m2": 131,
    "out:El bought/kWh/m2": 744.654907,
    "out:Return %": 10,
    "out:Return/kSEK/y": 484,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 28.356,
    "out:EAHP (El)": 0,
    "out:PV (kr/kWp)": 17461.48474631725,
    "out:PV (kWp installed)": 90.5097,
    "out:PV (m2 panels)": 474.867263,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 84922.568824,
    "out:PV (kWh sold)": 63894.192746,
    "out:PV (% sold (El))": 75.238177,
    "out:PV (kWh self-consumed)": 21028.376078,
    "out:PV (ratio sold/self-consumed)": 3.038475,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 978564.8401067974,
    "out:FTX (Diffusers)": 206476.5625,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 122685.728751,
    "out:EL kWh savings": 6385.163023,
    "out:Sewage heat recovery cost": 273216,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 231297.34,
    "out:DH kr savings": 122685.72875143304,
    "out:El kr savings": 12770.326045670909,
    "out:El kr sold": 95841.28911946694,
    "out:El kr saved": 42056.752156,
    "out:El kr return": 137898.04127546694,
    "out:% solar/total": 53,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 326943.730695,
    "out:% savings (space heating)": 19.697897,
    "out:Total_Water Heating (DH)": 90285.476835,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 997124.4110739925,
    "out:Eupp": 102.68,
    "out:Etvv": 17,
    "out:Ef": 10.56,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 4662489.733811935,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 47.157904,
    "out:EL CO2": -100.475122,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -101.871982,
    "out:Bought CO2": 1.39686,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00000000120001110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 97.143758,
    "out:DHW (DH)": 28.356,
    "out:Property Electricity": 17.169,
    "out:Electricity (inc PV)": 10.564609,
    "out:Total Energy Use Pre PV": 142.169,
    "out:Total Energy Use Post PV": 135.564609,
    "out:Primary Energy": 106.472552,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 33.156869,
    "out:CO2 Operational/m2": -55.477122,
    "out:Total CO2/m2": -22.320253,
    "out:Total CO2 (tons)": -71.067664,
    "out:Klimatpaverkan": -271.203085,
    "out:Initial Cost/MSEK": 4.742089708811936,
    "out:Running cost/(Apt SEK y)": 24965.829787,
    "out:Running Cost over RSP/MSEK": 9.19765,
    "out:LCP/MSEK": -0.53502,
    "out:ROI% old": 12.673948,
    "out:Payback discounted": 10,
    "out:Atemp": 3184,
    "out:Umean": 1,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 136,
    "out:Energy use kWh/m2": 136,
    "out:Energy savings %": 34.6,
    "out:Op energy cost/MSEK": 68,
    "out:El price/MSEK": 40,
    "out:Heating price/MSEK": 24,
    "out:DH+ DHW/kWh/m2": 125,
    "out:El bought/kWh/m2": 744.654907,
    "out:Return %": 11,
    "out:Return/kSEK/y": 541,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 28.356,
    "out:EAHP (El)": 0,
    "out:PV (kr/kWp)": 17461.48474631725,
    "out:PV (kWp installed)": 90.5097,
    "out:PV (m2 panels)": 474.867263,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 84922.568824,
    "out:PV (kWh sold)": 63894.192746,
    "out:PV (% sold (El))": 75.238177,
    "out:PV (kWh self-consumed)": 21028.376078,
    "out:PV (ratio sold/self-consumed)": 3.038475,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 978564.8401067974,
    "out:FTX (Diffusers)": 206476.5625,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 140323.827993,
    "out:EL kWh savings": 6385.163023,
    "out:Sewage heat recovery cost": 273216,
    "out:Balancing hydronic system cost": 79599.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 248935.44,
    "out:DH kr savings": 140323.8279932908,
    "out:El kr savings": 12770.326045670909,
    "out:El kr sold": 95841.28911946694,
    "out:El kr saved": 42056.752156,
    "out:El kr return": 137898.04127546694,
    "out:% solar/total": 53,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 309305.631453,
    "out:% savings (space heating)": 24.030069,
    "out:Total_Water Heating (DH)": 90285.476835,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 997124.4110739925,
    "out:Eupp": 97.14,
    "out:Etvv": 17,
    "out:Ef": 10.56,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 4742089.708811936,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 44.998,
    "out:EL CO2": -100.475122,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -101.871982,
    "out:Bought CO2": 1.39686,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00001000000000010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 85.005692,
    "out:DHW (DH)": 41.7,
    "out:Property Electricity": 17.169,
    "out:Electricity (inc PV)": 17.169,
    "out:Total Energy Use Pre PV": 144.169,
    "out:Total Energy Use Post PV": 144.169,
    "out:Primary Energy": 114.519738,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 5.126009,
    "out:CO2 Operational/m2": 47.984413,
    "out:Total CO2/m2": 53.110422,
    "out:Total CO2 (tons)": 169.103533,
    "out:Klimatpaverkan": -31.031888,
    "out:Initial Cost/MSEK": 2.942004153371936,
    "out:Running cost/(Apt SEK y)": 28309,
    "out:Running Cost over RSP/MSEK": 10.39705,
    "out:LCP/MSEK": 0.065666,
    "out:ROI% old": 14.604573,
    "out:Payback discounted": 8,
    "out:Atemp": 3184,
    "out:Umean": 0.82,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 144,
    "out:Energy use kWh/m2": 144,
    "out:Energy savings %": 27.1,
    "out:Op energy cost/MSEK": 76,
    "out:El price/MSEK": 47,
    "out:Heating price/MSEK": 25,
    "out:DH+ DHW/kWh/m2": 127,
    "out:El bought/kWh/m2": 1201.83,
    "out:Return %": 13,
    "out:Return/kSEK/y": 384,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 41.7,
    "out:EAHP (El)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 978564.8401067974,
    "out:FTX (Diffusers)": 206476.5625,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 136484.133064,
    "out:EL kWh savings": -14643.211594,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 107197.71,
    "out:DH kr savings": 136484.13306377624,
    "out:El kr savings": -29286.42318831607,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 270658.043166,
    "out:% savings (space heating)": 33.522475,
    "out:Total_Water Heating (DH)": 132772.760051,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 997124.4110739925,
    "out:Eupp": 85.01,
    "out:Etvv": 25,
    "out:Ef": 17.17,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 2942004.1533719357,
    "out:Seasonal Variation ROI (%)": 1,
    "out:Seasonal Variation Payback": 100,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 45.717968,
    "out:EL CO2": 2.266445,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 2.266445,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00001000000000110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 80.269617,
    "out:DHW (DH)": 41.7,
    "out:Property Electricity": 17.169,
    "out:Electricity (inc PV)": 17.169,
    "out:Total Energy Use Pre PV": 139.169,
    "out:Total Energy Use Post PV": 139.169,
    "out:Primary Energy": 110.836124,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 5.126009,
    "out:CO2 Operational/m2": 46.184493,
    "out:Total CO2/m2": 51.310502,
    "out:Total CO2 (tons)": 163.372589,
    "out:Klimatpaverkan": -36.762832,
    "out:Initial Cost/MSEK": 3.0216041283719357,
    "out:Running cost/(Apt SEK y)": 27286.06383,
    "out:Running Cost over RSP/MSEK": 10.02099,
    "out:LCP/MSEK": 0.362126,
    "out:ROI% old": 15.997794,
    "out:Payback discounted": 8,
    "out:Atemp": 3184,
    "out:Umean": 0.82,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 139,
    "out:Energy use kWh/m2": 139,
    "out:Energy savings %": 31.7,
    "out:Op energy cost/MSEK": 73,
    "out:El price/MSEK": 45,
    "out:Heating price/MSEK": 24,
    "out:DH+ DHW/kWh/m2": 122,
    "out:El bought/kWh/m2": 1201.83,
    "out:Return %": 14,
    "out:Return/kSEK/y": 432,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 41.7,
    "out:EAHP (El)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 978564.8401067974,
    "out:FTX (Diffusers)": 206476.5625,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 151563.791963,
    "out:EL kWh savings": -14643.211594,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 79599.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 122277.37,
    "out:DH kr savings": 151563.7919634162,
    "out:El kr savings": -29286.42318831607,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 255578.384267,
    "out:% savings (space heating)": 37.226257,
    "out:Total_Water Heating (DH)": 132772.760051,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 997124.4110739925,
    "out:Eupp": 80.27,
    "out:Etvv": 25,
    "out:Ef": 17.17,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 3021604.128371936,
    "out:Seasonal Variation ROI (%)": 1,
    "out:Seasonal Variation Payback": 100,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 43.918048,
    "out:EL CO2": 2.266445,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 2.266445,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00001000000001010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 85.005692,
    "out:DHW (DH)": 35.445,
    "out:Property Electricity": 17.169,
    "out:Electricity (inc PV)": 17.169,
    "out:Total Energy Use Pre PV": 137.169,
    "out:Total Energy Use Post PV": 137.169,
    "out:Primary Energy": 111.894738,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 5.834552,
    "out:CO2 Operational/m2": 45.464525,
    "out:Total CO2/m2": 51.299077,
    "out:Total CO2 (tons)": 163.336212,
    "out:Klimatpaverkan": -36.799209,
    "out:Initial Cost/MSEK": 3.215220153371936,
    "out:Running cost/(Apt SEK y)": 26876.87234,
    "out:Running Cost over RSP/MSEK": 9.87057,
    "out:LCP/MSEK": 0.31893,
    "out:ROI% old": 15.702768,
    "out:Payback discounted": 8,
    "out:Atemp": 3184,
    "out:Umean": 0.82,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 137,
    "out:Energy use kWh/m2": 137,
    "out:Energy savings %": 33.6,
    "out:Op energy cost/MSEK": 72,
    "out:El price/MSEK": 45,
    "out:Heating price/MSEK": 23,
    "out:DH+ DHW/kWh/m2": 120,
    "out:El bought/kWh/m2": 1201.83,
    "out:Return %": 14,
    "out:Return/kSEK/y": 451,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 35.445,
    "out:EAHP (El)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 978564.8401067974,
    "out:FTX (Diffusers)": 206476.5625,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 156400.047071,
    "out:EL kWh savings": -14643.211594,
    "out:Sewage heat recovery cost": 273216,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 127113.62,
    "out:DH kr savings": 156400.04707148622,
    "out:El kr savings": -29286.42318831607,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 270658.043166,
    "out:% savings (space heating)": 33.522475,
    "out:Total_Water Heating (DH)": 112856.846044,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 997124.4110739925,
    "out:Eupp": 85.01,
    "out:Etvv": 21.25,
    "out:Ef": 17.17,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 3215220.153371936,
    "out:Seasonal Variation ROI (%)": 1,
    "out:Seasonal Variation Payback": 100,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 43.19808,
    "out:EL CO2": 2.266445,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 2.266445,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00001000000001110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 80.269617,
    "out:DHW (DH)": 35.445,
    "out:Property Electricity": 17.169,
    "out:Electricity (inc PV)": 17.169,
    "out:Total Energy Use Pre PV": 133.169,
    "out:Total Energy Use Post PV": 133.169,
    "out:Primary Energy": 108.211124,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 5.834552,
    "out:CO2 Operational/m2": 44.024589,
    "out:Total CO2/m2": 49.859141,
    "out:Total CO2 (tons)": 158.751457,
    "out:Klimatpaverkan": -41.383964,
    "out:Initial Cost/MSEK": 3.2948201283719363,
    "out:Running cost/(Apt SEK y)": 26058.531915,
    "out:Running Cost over RSP/MSEK": 9.56973,
    "out:LCP/MSEK": 0.54017,
    "out:ROI% old": 16.627788,
    "out:Payback discounted": 7,
    "out:Atemp": 3184,
    "out:Umean": 0.82,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 133,
    "out:Energy use kWh/m2": 133,
    "out:Energy savings %": 37.6,
    "out:Op energy cost/MSEK": 70,
    "out:El price/MSEK": 43,
    "out:Heating price/MSEK": 23,
    "out:DH+ DHW/kWh/m2": 116,
    "out:El bought/kWh/m2": 1201.83,
    "out:Return %": 15,
    "out:Return/kSEK/y": 490,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 35.445,
    "out:EAHP (El)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 978564.8401067974,
    "out:FTX (Diffusers)": 206476.5625,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 171479.705971,
    "out:EL kWh savings": -14643.211594,
    "out:Sewage heat recovery cost": 273216,
    "out:Balancing hydronic system cost": 79599.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 142193.28,
    "out:DH kr savings": 171479.70597112624,
    "out:El kr savings": -29286.42318831607,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 255578.384267,
    "out:% savings (space heating)": 37.226257,
    "out:Total_Water Heating (DH)": 112856.846044,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 997124.4110739925,
    "out:Eupp": 80.27,
    "out:Etvv": 21.25,
    "out:Ef": 17.17,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 3294820.1283719363,
    "out:Seasonal Variation ROI (%)": 2,
    "out:Seasonal Variation Payback": 50,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 41.758144,
    "out:EL CO2": 2.266445,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 2.266445,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00001000010000010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 85.005692,
    "out:DHW (DH)": 41.7,
    "out:Property Electricity": 17.169,
    "out:Electricity (inc PV)": 11.433081,
    "out:Total Energy Use Pre PV": 144.169,
    "out:Total Energy Use Post PV": 138.433081,
    "out:Primary Energy": 104.195084,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 19.865109,
    "out:CO2 Operational/m2": 8.826495,
    "out:Total CO2/m2": 28.691604,
    "out:Total CO2 (tons)": 91.35404,
    "out:Klimatpaverkan": -108.781381,
    "out:Initial Cost/MSEK": 3.732224547594436,
    "out:Running cost/(Apt SEK y)": 26759.574468,
    "out:Running Cost over RSP/MSEK": 9.84282,
    "out:LCP/MSEK": -0.170325,
    "out:ROI% old": 13.633769,
    "out:Payback discounted": 9,
    "out:Atemp": 3184,
    "out:Umean": 0.82,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 138,
    "out:Energy use kWh/m2": 138,
    "out:Energy savings %": 32.6,
    "out:Op energy cost/MSEK": 72,
    "out:El price/MSEK": 44,
    "out:Heating price/MSEK": 25,
    "out:DH+ DHW/kWh/m2": 127,
    "out:El bought/kWh/m2": 807.631491,
    "out:Return %": 12,
    "out:Return/kSEK/y": 457,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 41.7,
    "out:EAHP (El)": 0,
    "out:PV (kr/kWp)": 17461.56255567083,
    "out:PV (kWp installed)": 45.25485,
    "out:PV (m2 panels)": 237.433632,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 42461.284501,
    "out:PV (kWh sold)": 24198.124428,
    "out:PV (% sold (El))": 56.988677,
    "out:PV (kWh self-consumed)": 18263.160073,
    "out:PV (ratio sold/self-consumed)": 1.324969,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 978564.8401067974,
    "out:FTX (Diffusers)": 206476.5625,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 136484.133064,
    "out:EL kWh savings": 3619.949007,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 180021.22,
    "out:DH kr savings": 136484.13306377624,
    "out:El kr savings": 7239.898013663279,
    "out:El kr sold": 36297.18664221638,
    "out:El kr saved": 36526.320146,
    "out:El kr return": 72823.50678821639,
    "out:% solar/total": 46,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 270658.043166,
    "out:% savings (space heating)": 33.522475,
    "out:Total_Water Heating (DH)": 132772.760051,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 997124.4110739925,
    "out:Eupp": 85.01,
    "out:Etvv": 25,
    "out:Ef": 11.43,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 3732224.547594436,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 45.717968,
    "out:EL CO2": -36.891473,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -38.403999,
    "out:Bought CO2": 1.512526,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00001000010000110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 80.269617,
    "out:DHW (DH)": 41.7,
    "out:Property Electricity": 17.169,
    "out:Electricity (inc PV)": 11.433081,
    "out:Total Energy Use Pre PV": 139.169,
    "out:Total Energy Use Post PV": 133.433081,
    "out:Primary Energy": 100.51147,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 19.865109,
    "out:CO2 Operational/m2": 7.026575,
    "out:Total CO2/m2": 26.891684,
    "out:Total CO2 (tons)": 85.623096,
    "out:Klimatpaverkan": -114.512325,
    "out:Initial Cost/MSEK": 3.8118245225944363,
    "out:Running cost/(Apt SEK y)": 25736.617021,
    "out:Running Cost over RSP/MSEK": 9.46676,
    "out:LCP/MSEK": 0.126135,
    "out:ROI% old": 14.758437,
    "out:Payback discounted": 8,
    "out:Atemp": 3184,
    "out:Umean": 0.82,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 133,
    "out:Energy use kWh/m2": 133,
    "out:Energy savings %": 37.6,
    "out:Op energy cost/MSEK": 70,
    "out:El price/MSEK": 42,
    "out:Heating price/MSEK": 24,
    "out:DH+ DHW/kWh/m2": 122,
    "out:El bought/kWh/m2": 807.631491,
    "out:Return %": 13,
    "out:Return/kSEK/y": 505,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 41.7,
    "out:EAHP (El)": 0,
    "out:PV (kr/kWp)": 17461.56255567083,
    "out:PV (kWp installed)": 45.25485,
    "out:PV (m2 panels)": 237.433632,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 42461.284501,
    "out:PV (kWh sold)": 24198.124428,
    "out:PV (% sold (El))": 56.988677,
    "out:PV (kWh self-consumed)": 18263.160073,
    "out:PV (ratio sold/self-consumed)": 1.324969,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 978564.8401067974,
    "out:FTX (Diffusers)": 206476.5625,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 151563.791963,
    "out:EL kWh savings": 3619.949007,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 79599.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 195100.88,
    "out:DH kr savings": 151563.7919634162,
    "out:El kr savings": 7239.898013663279,
    "out:El kr sold": 36297.18664221638,
    "out:El kr saved": 36526.320146,
    "out:El kr return": 72823.50678821639,
    "out:% solar/total": 46,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 255578.384267,
    "out:% savings (space heating)": 37.226257,
    "out:Total_Water Heating (DH)": 132772.760051,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 997124.4110739925,
    "out:Eupp": 80.27,
    "out:Etvv": 25,
    "out:Ef": 11.43,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 3811824.522594436,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 43.918048,
    "out:EL CO2": -36.891473,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -38.403999,
    "out:Bought CO2": 1.512526,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00001000010001010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 85.005692,
    "out:DHW (DH)": 35.445,
    "out:Property Electricity": 17.169,
    "out:Electricity (inc PV)": 11.433081,
    "out:Total Energy Use Pre PV": 137.169,
    "out:Total Energy Use Post PV": 131.433081,
    "out:Primary Energy": 101.570084,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 20.573652,
    "out:CO2 Operational/m2": 6.306607,
    "out:Total CO2/m2": 26.880259,
    "out:Total CO2 (tons)": 85.586719,
    "out:Klimatpaverkan": -114.548702,
    "out:Initial Cost/MSEK": 4.005440547594436,
    "out:Running cost/(Apt SEK y)": 25327.446809,
    "out:Running Cost over RSP/MSEK": 9.31634,
    "out:LCP/MSEK": 0.082939,
    "out:ROI% old": 14.581524,
    "out:Payback discounted": 8,
    "out:Atemp": 3184,
    "out:Umean": 0.82,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 131,
    "out:Energy use kWh/m2": 131,
    "out:Energy savings %": 39.7,
    "out:Op energy cost/MSEK": 69,
    "out:El price/MSEK": 41,
    "out:Heating price/MSEK": 23,
    "out:DH+ DHW/kWh/m2": 120,
    "out:El bought/kWh/m2": 807.631491,
    "out:Return %": 13,
    "out:Return/kSEK/y": 524,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 35.445,
    "out:EAHP (El)": 0,
    "out:PV (kr/kWp)": 17461.56255567083,
    "out:PV (kWp installed)": 45.25485,
    "out:PV (m2 panels)": 237.433632,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 42461.284501,
    "out:PV (kWh sold)": 24198.124428,
    "out:PV (% sold (El))": 56.988677,
    "out:PV (kWh self-consumed)": 18263.160073,
    "out:PV (ratio sold/self-consumed)": 1.324969,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 978564.8401067974,
    "out:FTX (Diffusers)": 206476.5625,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 156400.047071,
    "out:EL kWh savings": 3619.949007,
    "out:Sewage heat recovery cost": 273216,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 199937.13,
    "out:DH kr savings": 156400.04707148622,
    "out:El kr savings": 7239.898013663279,
    "out:El kr sold": 36297.18664221638,
    "out:El kr saved": 36526.320146,
    "out:El kr return": 72823.50678821639,
    "out:% solar/total": 46,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 270658.043166,
    "out:% savings (space heating)": 33.522475,
    "out:Total_Water Heating (DH)": 112856.846044,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 997124.4110739925,
    "out:Eupp": 85.01,
    "out:Etvv": 21.25,
    "out:Ef": 11.43,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 4005440.5475944355,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 43.19808,
    "out:EL CO2": -36.891473,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -38.403999,
    "out:Bought CO2": 1.512526,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00001000010001110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 80.269617,
    "out:DHW (DH)": 35.445,
    "out:Property Electricity": 17.169,
    "out:Electricity (inc PV)": 11.433081,
    "out:Total Energy Use Pre PV": 133.169,
    "out:Total Energy Use Post PV": 127.433081,
    "out:Primary Energy": 97.88647,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 20.573652,
    "out:CO2 Operational/m2": 4.866671,
    "out:Total CO2/m2": 25.440323,
    "out:Total CO2 (tons)": 81.001964,
    "out:Klimatpaverkan": -119.133457,
    "out:Initial Cost/MSEK": 4.085040522594436,
    "out:Running cost/(Apt SEK y)": 24509.085106,
    "out:Running Cost over RSP/MSEK": 9.0155,
    "out:LCP/MSEK": 0.304179,
    "out:ROI% old": 15.349454,
    "out:Payback discounted": 8,
    "out:Atemp": 3184,
    "out:Umean": 0.82,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 127,
    "out:Energy use kWh/m2": 127,
    "out:Energy savings %": 44.1,
    "out:Op energy cost/MSEK": 66,
    "out:El price/MSEK": 40,
    "out:Heating price/MSEK": 23,
    "out:DH+ DHW/kWh/m2": 116,
    "out:El bought/kWh/m2": 807.631491,
    "out:Return %": 14,
    "out:Return/kSEK/y": 563,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 35.445,
    "out:EAHP (El)": 0,
    "out:PV (kr/kWp)": 17461.56255567083,
    "out:PV (kWp installed)": 45.25485,
    "out:PV (m2 panels)": 237.433632,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 42461.284501,
    "out:PV (kWh sold)": 24198.124428,
    "out:PV (% sold (El))": 56.988677,
    "out:PV (kWh self-consumed)": 18263.160073,
    "out:PV (ratio sold/self-consumed)": 1.324969,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 978564.8401067974,
    "out:FTX (Diffusers)": 206476.5625,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 171479.705971,
    "out:EL kWh savings": 3619.949007,
    "out:Sewage heat recovery cost": 273216,
    "out:Balancing hydronic system cost": 79599.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 215016.79,
    "out:DH kr savings": 171479.70597112624,
    "out:El kr savings": 7239.898013663279,
    "out:El kr sold": 36297.18664221638,
    "out:El kr saved": 36526.320146,
    "out:El kr return": 72823.50678821639,
    "out:% solar/total": 46,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 255578.384267,
    "out:% savings (space heating)": 37.226257,
    "out:Total_Water Heating (DH)": 112856.846044,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 997124.4110739925,
    "out:Eupp": 80.27,
    "out:Etvv": 21.25,
    "out:Ef": 11.43,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 4085040.5225944365,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 41.758144,
    "out:EL CO2": -36.891473,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -38.403999,
    "out:Bought CO2": 1.512526,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00001000020000010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 85.005692,
    "out:DHW (DH)": 41.7,
    "out:Property Electricity": 17.169,
    "out:Electricity (inc PV)": 10.564609,
    "out:Total Energy Use Pre PV": 144.169,
    "out:Total Energy Use Post PV": 137.564609,
    "out:Primary Energy": 102.631834,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 34.604208,
    "out:CO2 Operational/m2": -54.757154,
    "out:Total CO2/m2": -20.152946,
    "out:Total CO2 (tons)": -64.166961,
    "out:Klimatpaverkan": -264.302382,
    "out:Initial Cost/MSEK": 4.522437899315686,
    "out:Running cost/(Apt SEK y)": 25375,
    "out:Running Cost over RSP/MSEK": 9.34807,
    "out:LCP/MSEK": -0.465788,
    "out:ROI% old": 12.814359,
    "out:Payback discounted": 9,
    "out:Atemp": 3184,
    "out:Umean": 0.82,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 138,
    "out:Energy use kWh/m2": 138,
    "out:Energy savings %": 32.6,
    "out:Op energy cost/MSEK": 69,
    "out:El price/MSEK": 41,
    "out:Heating price/MSEK": 25,
    "out:DH+ DHW/kWh/m2": 127,
    "out:El bought/kWh/m2": 744.654907,
    "out:Return %": 12,
    "out:Return/kSEK/y": 522,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 41.7,
    "out:EAHP (El)": 0,
    "out:PV (kr/kWp)": 17461.48474631725,
    "out:PV (kWp installed)": 90.5097,
    "out:PV (m2 panels)": 474.867263,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 84922.568824,
    "out:PV (kWh sold)": 63894.192746,
    "out:PV (% sold (El))": 75.238177,
    "out:PV (kWh self-consumed)": 21028.376078,
    "out:PV (ratio sold/self-consumed)": 3.038475,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 978564.8401067974,
    "out:FTX (Diffusers)": 206476.5625,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 136484.133064,
    "out:EL kWh savings": 6385.163023,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 245095.75,
    "out:DH kr savings": 136484.13306377624,
    "out:El kr savings": 12770.326045670909,
    "out:El kr sold": 95841.28911946694,
    "out:El kr saved": 42056.752156,
    "out:El kr return": 137898.04127546694,
    "out:% solar/total": 53,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 270658.043166,
    "out:% savings (space heating)": 33.522475,
    "out:Total_Water Heating (DH)": 132772.760051,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 997124.4110739925,
    "out:Eupp": 85.01,
    "out:Etvv": 25,
    "out:Ef": 10.56,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 4522437.899315686,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 45.717968,
    "out:EL CO2": -100.475122,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -101.871982,
    "out:Bought CO2": 1.39686,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00001000020000110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 80.269617,
    "out:DHW (DH)": 41.7,
    "out:Property Electricity": 17.169,
    "out:Electricity (inc PV)": 10.564609,
    "out:Total Energy Use Pre PV": 139.169,
    "out:Total Energy Use Post PV": 132.564609,
    "out:Primary Energy": 98.94822,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 34.604208,
    "out:CO2 Operational/m2": -56.557074,
    "out:Total CO2/m2": -21.952866,
    "out:Total CO2 (tons)": -69.897904,
    "out:Klimatpaverkan": -270.033325,
    "out:Initial Cost/MSEK": 4.602037874315687,
    "out:Running cost/(Apt SEK y)": 24352.06383,
    "out:Running Cost over RSP/MSEK": 8.97201,
    "out:LCP/MSEK": -0.169328,
    "out:ROI% old": 13.760084,
    "out:Payback discounted": 9,
    "out:Atemp": 3184,
    "out:Umean": 0.82,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 133,
    "out:Energy use kWh/m2": 133,
    "out:Energy savings %": 37.6,
    "out:Op energy cost/MSEK": 67,
    "out:El price/MSEK": 39,
    "out:Heating price/MSEK": 24,
    "out:DH+ DHW/kWh/m2": 122,
    "out:El bought/kWh/m2": 744.654907,
    "out:Return %": 12,
    "out:Return/kSEK/y": 570,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 41.7,
    "out:EAHP (El)": 0,
    "out:PV (kr/kWp)": 17461.48474631725,
    "out:PV (kWp installed)": 90.5097,
    "out:PV (m2 panels)": 474.867263,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 84922.568824,
    "out:PV (kWh sold)": 63894.192746,
    "out:PV (% sold (El))": 75.238177,
    "out:PV (kWh self-consumed)": 21028.376078,
    "out:PV (ratio sold/self-consumed)": 3.038475,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 978564.8401067974,
    "out:FTX (Diffusers)": 206476.5625,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 151563.791963,
    "out:EL kWh savings": 6385.163023,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 79599.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 260175.41,
    "out:DH kr savings": 151563.7919634162,
    "out:El kr savings": 12770.326045670909,
    "out:El kr sold": 95841.28911946694,
    "out:El kr saved": 42056.752156,
    "out:El kr return": 137898.04127546694,
    "out:% solar/total": 53,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 255578.384267,
    "out:% savings (space heating)": 37.226257,
    "out:Total_Water Heating (DH)": 132772.760051,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 997124.4110739925,
    "out:Eupp": 80.27,
    "out:Etvv": 25,
    "out:Ef": 10.56,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 4602037.8743156865,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 43.918048,
    "out:EL CO2": -100.475122,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -101.871982,
    "out:Bought CO2": 1.39686,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00001000020001010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 85.005692,
    "out:DHW (DH)": 35.445,
    "out:Property Electricity": 17.169,
    "out:Electricity (inc PV)": 10.564609,
    "out:Total Energy Use Pre PV": 137.169,
    "out:Total Energy Use Post PV": 130.564609,
    "out:Primary Energy": 100.006834,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 35.312751,
    "out:CO2 Operational/m2": -57.277042,
    "out:Total CO2/m2": -21.964291,
    "out:Total CO2 (tons)": -69.934282,
    "out:Klimatpaverkan": -270.069703,
    "out:Initial Cost/MSEK": 4.795653899315686,
    "out:Running cost/(Apt SEK y)": 23942.87234,
    "out:Running Cost over RSP/MSEK": 8.82159,
    "out:LCP/MSEK": -0.212524,
    "out:ROI% old": 13.652629,
    "out:Payback discounted": 9,
    "out:Atemp": 3184,
    "out:Umean": 0.82,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 131,
    "out:Energy use kWh/m2": 131,
    "out:Energy savings %": 39.7,
    "out:Op energy cost/MSEK": 66,
    "out:El price/MSEK": 38,
    "out:Heating price/MSEK": 23,
    "out:DH+ DHW/kWh/m2": 120,
    "out:El bought/kWh/m2": 744.654907,
    "out:Return %": 12,
    "out:Return/kSEK/y": 589,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 35.445,
    "out:EAHP (El)": 0,
    "out:PV (kr/kWp)": 17461.48474631725,
    "out:PV (kWp installed)": 90.5097,
    "out:PV (m2 panels)": 474.867263,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 84922.568824,
    "out:PV (kWh sold)": 63894.192746,
    "out:PV (% sold (El))": 75.238177,
    "out:PV (kWh self-consumed)": 21028.376078,
    "out:PV (ratio sold/self-consumed)": 3.038475,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 978564.8401067974,
    "out:FTX (Diffusers)": 206476.5625,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 156400.047071,
    "out:EL kWh savings": 6385.163023,
    "out:Sewage heat recovery cost": 273216,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 265011.66,
    "out:DH kr savings": 156400.04707148622,
    "out:El kr savings": 12770.326045670909,
    "out:El kr sold": 95841.28911946694,
    "out:El kr saved": 42056.752156,
    "out:El kr return": 137898.04127546694,
    "out:% solar/total": 53,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 270658.043166,
    "out:% savings (space heating)": 33.522475,
    "out:Total_Water Heating (DH)": 112856.846044,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 997124.4110739925,
    "out:Eupp": 85.01,
    "out:Etvv": 21.25,
    "out:Ef": 10.56,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 4795653.899315686,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 43.19808,
    "out:EL CO2": -100.475122,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -101.871982,
    "out:Bought CO2": 1.39686,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00001000020001110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 80.269617,
    "out:DHW (DH)": 35.445,
    "out:Property Electricity": 17.169,
    "out:Electricity (inc PV)": 10.564609,
    "out:Total Energy Use Pre PV": 133.169,
    "out:Total Energy Use Post PV": 126.564609,
    "out:Primary Energy": 96.32322,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 35.312751,
    "out:CO2 Operational/m2": -58.716978,
    "out:Total CO2/m2": -23.404227,
    "out:Total CO2 (tons)": -74.519036,
    "out:Klimatpaverkan": -274.654457,
    "out:Initial Cost/MSEK": 4.875253874315686,
    "out:Running cost/(Apt SEK y)": 23124.531915,
    "out:Running Cost over RSP/MSEK": 8.52075,
    "out:LCP/MSEK": 0.008716,
    "out:ROI% old": 14.311255,
    "out:Payback discounted": 8,
    "out:Atemp": 3184,
    "out:Umean": 0.82,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 127,
    "out:Energy use kWh/m2": 127,
    "out:Energy savings %": 44.1,
    "out:Op energy cost/MSEK": 63,
    "out:El price/MSEK": 37,
    "out:Heating price/MSEK": 23,
    "out:DH+ DHW/kWh/m2": 116,
    "out:El bought/kWh/m2": 744.654907,
    "out:Return %": 13,
    "out:Return/kSEK/y": 628,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 35.445,
    "out:EAHP (El)": 0,
    "out:PV (kr/kWp)": 17461.48474631725,
    "out:PV (kWp installed)": 90.5097,
    "out:PV (m2 panels)": 474.867263,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 84922.568824,
    "out:PV (kWh sold)": 63894.192746,
    "out:PV (% sold (El))": 75.238177,
    "out:PV (kWh self-consumed)": 21028.376078,
    "out:PV (ratio sold/self-consumed)": 3.038475,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 978564.8401067974,
    "out:FTX (Diffusers)": 206476.5625,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 171479.705971,
    "out:EL kWh savings": 6385.163023,
    "out:Sewage heat recovery cost": 273216,
    "out:Balancing hydronic system cost": 79599.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 280091.32,
    "out:DH kr savings": 171479.70597112624,
    "out:El kr savings": 12770.326045670909,
    "out:El kr sold": 95841.28911946694,
    "out:El kr saved": 42056.752156,
    "out:El kr return": 137898.04127546694,
    "out:% solar/total": 53,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 255578.384267,
    "out:% savings (space heating)": 37.226257,
    "out:Total_Water Heating (DH)": 112856.846044,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 997124.4110739925,
    "out:Eupp": 80.27,
    "out:Etvv": 21.25,
    "out:Ef": 10.56,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 4875253.8743156865,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 41.758144,
    "out:EL CO2": -100.475122,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -101.871982,
    "out:Bought CO2": 1.39686,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00001000100000010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 85.005692,
    "out:DHW (DH)": 33.36,
    "out:Property Electricity": 17.169,
    "out:Electricity (inc PV)": 17.169,
    "out:Total Energy Use Pre PV": 135.169,
    "out:Total Energy Use Post PV": 135.169,
    "out:Primary Energy": 111.019738,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 5.126009,
    "out:CO2 Operational/m2": 44.744557,
    "out:Total CO2/m2": 49.870566,
    "out:Total CO2 (tons)": 158.787834,
    "out:Klimatpaverkan": -41.347587,
    "out:Initial Cost/MSEK": 3.0660518408719364,
    "out:Running cost/(Apt SEK y)": 26467.702128,
    "out:Running Cost over RSP/MSEK": 9.72015,
    "out:LCP/MSEK": 0.618518,
    "out:ROI% old": 17.167588,
    "out:Payback discounted": 7,
    "out:Atemp": 3184,
    "out:Umean": 0.82,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 135,
    "out:Energy use kWh/m2": 135,
    "out:Energy savings %": 35.6,
    "out:Op energy cost/MSEK": 71,
    "out:El price/MSEK": 44,
    "out:Heating price/MSEK": 23,
    "out:DH+ DHW/kWh/m2": 118,
    "out:El bought/kWh/m2": 1201.83,
    "out:Return %": 15,
    "out:Return/kSEK/y": 471,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 33.36,
    "out:EAHP (El)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 978564.8401067974,
    "out:FTX (Diffusers)": 206476.5625,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 163038.685074,
    "out:EL kWh savings": -14643.211594,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 133752.26,
    "out:DH kr savings": 163038.68507405627,
    "out:El kr savings": -29286.42318831607,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 270658.043166,
    "out:% savings (space heating)": 33.522475,
    "out:Total_Water Heating (DH)": 106218.208041,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 997124.4110739925,
    "out:Eupp": 85.01,
    "out:Etvv": 20,
    "out:Ef": 17.17,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 3066051.840871936,
    "out:Seasonal Variation ROI (%)": 2,
    "out:Seasonal Variation Payback": 50,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 42.478112,
    "out:EL CO2": 2.266445,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 2.266445,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00001000100000110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 80.269617,
    "out:DHW (DH)": 33.36,
    "out:Property Electricity": 17.169,
    "out:Electricity (inc PV)": 17.169,
    "out:Total Energy Use Pre PV": 131.169,
    "out:Total Energy Use Post PV": 131.169,
    "out:Primary Energy": 107.336124,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 5.126009,
    "out:CO2 Operational/m2": 43.304621,
    "out:Total CO2/m2": 48.43063,
    "out:Total CO2 (tons)": 154.20308,
    "out:Klimatpaverkan": -45.932342,
    "out:Initial Cost/MSEK": 3.145651815871936,
    "out:Running cost/(Apt SEK y)": 25649.340426,
    "out:Running Cost over RSP/MSEK": 9.4193,
    "out:LCP/MSEK": 0.839768,
    "out:ROI% old": 18.099451,
    "out:Payback discounted": 7,
    "out:Atemp": 3184,
    "out:Umean": 0.82,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 131,
    "out:Energy use kWh/m2": 131,
    "out:Energy savings %": 39.7,
    "out:Op energy cost/MSEK": 69,
    "out:El price/MSEK": 43,
    "out:Heating price/MSEK": 22,
    "out:DH+ DHW/kWh/m2": 114,
    "out:El bought/kWh/m2": 1201.83,
    "out:Return %": 16,
    "out:Return/kSEK/y": 509,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 33.36,
    "out:EAHP (El)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 978564.8401067974,
    "out:FTX (Diffusers)": 206476.5625,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 178118.343974,
    "out:EL kWh savings": -14643.211594,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 79599.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 148831.92,
    "out:DH kr savings": 178118.34397369623,
    "out:El kr savings": -29286.42318831607,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 255578.384267,
    "out:% savings (space heating)": 37.226257,
    "out:Total_Water Heating (DH)": 106218.208041,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 997124.4110739925,
    "out:Eupp": 80.27,
    "out:Etvv": 20,
    "out:Ef": 17.17,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 3145651.8158719363,
    "out:Seasonal Variation ROI (%)": 2,
    "out:Seasonal Variation Payback": 50,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 41.038176,
    "out:EL CO2": 2.266445,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 2.266445,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00001000100001010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 85.005692,
    "out:DHW (DH)": 28.356,
    "out:Property Electricity": 17.169,
    "out:Electricity (inc PV)": 17.169,
    "out:Total Energy Use Pre PV": 130.169,
    "out:Total Energy Use Post PV": 130.169,
    "out:Primary Energy": 108.919738,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 5.834552,
    "out:CO2 Operational/m2": 42.944637,
    "out:Total CO2/m2": 48.779189,
    "out:Total CO2 (tons)": 155.312891,
    "out:Klimatpaverkan": -44.82253,
    "out:Initial Cost/MSEK": 3.339267840871936,
    "out:Running cost/(Apt SEK y)": 25444.765957,
    "out:Running Cost over RSP/MSEK": 9.34409,
    "out:LCP/MSEK": 0.721362,
    "out:ROI% old": 17.371772,
    "out:Payback discounted": 7,
    "out:Atemp": 3184,
    "out:Umean": 0.82,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 130,
    "out:Energy use kWh/m2": 130,
    "out:Energy savings %": 40.8,
    "out:Op energy cost/MSEK": 68,
    "out:El price/MSEK": 42,
    "out:Heating price/MSEK": 22,
    "out:DH+ DHW/kWh/m2": 113,
    "out:El bought/kWh/m2": 1201.83,
    "out:Return %": 16,
    "out:Return/kSEK/y": 519,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 28.356,
    "out:EAHP (El)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 978564.8401067974,
    "out:FTX (Diffusers)": 206476.5625,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 178971.41628,
    "out:EL kWh savings": -14643.211594,
    "out:Sewage heat recovery cost": 273216,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 149684.99,
    "out:DH kr savings": 178971.41628022422,
    "out:El kr savings": -29286.42318831607,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 270658.043166,
    "out:% savings (space heating)": 33.522475,
    "out:Total_Water Heating (DH)": 90285.476835,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 997124.4110739925,
    "out:Eupp": 85.01,
    "out:Etvv": 17,
    "out:Ef": 17.17,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 3339267.840871936,
    "out:Seasonal Variation ROI (%)": 2,
    "out:Seasonal Variation Payback": 50,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 40.678192,
    "out:EL CO2": 2.266445,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 2.266445,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00001000100001110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 80.269617,
    "out:DHW (DH)": 28.356,
    "out:Property Electricity": 17.169,
    "out:Electricity (inc PV)": 17.169,
    "out:Total Energy Use Pre PV": 126.169,
    "out:Total Energy Use Post PV": 126.169,
    "out:Primary Energy": 105.236124,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 5.834552,
    "out:CO2 Operational/m2": 41.504701,
    "out:Total CO2/m2": 47.339253,
    "out:Total CO2 (tons)": 150.728136,
    "out:Klimatpaverkan": -49.407285,
    "out:Initial Cost/MSEK": 3.4188678158719363,
    "out:Running cost/(Apt SEK y)": 24626.404255,
    "out:Running Cost over RSP/MSEK": 9.04325,
    "out:LCP/MSEK": 0.942602,
    "out:ROI% old": 18.224371,
    "out:Payback discounted": 7,
    "out:Atemp": 3184,
    "out:Umean": 0.82,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 126,
    "out:Energy use kWh/m2": 126,
    "out:Energy savings %": 45.2,
    "out:Op energy cost/MSEK": 66,
    "out:El price/MSEK": 41,
    "out:Heating price/MSEK": 21,
    "out:DH+ DHW/kWh/m2": 109,
    "out:El bought/kWh/m2": 1201.83,
    "out:Return %": 16,
    "out:Return/kSEK/y": 557,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 28.356,
    "out:EAHP (El)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 978564.8401067974,
    "out:FTX (Diffusers)": 206476.5625,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 194051.07518,
    "out:EL kWh savings": -14643.211594,
    "out:Sewage heat recovery cost": 273216,
    "out:Balancing hydronic system cost": 79599.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 164764.65,
    "out:DH kr savings": 194051.07517986425,
    "out:El kr savings": -29286.423188315977,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 255578.384267,
    "out:% savings (space heating)": 37.226257,
    "out:Total_Water Heating (DH)": 90285.476835,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 997124.4110739925,
    "out:Eupp": 80.27,
    "out:Etvv": 17,
    "out:Ef": 17.17,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 3418867.8158719363,
    "out:Seasonal Variation ROI (%)": 2,
    "out:Seasonal Variation Payback": 50,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 39.238256,
    "out:EL CO2": 2.266445,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 2.266445,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00001000110000010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 85.005692,
    "out:DHW (DH)": 33.36,
    "out:Property Electricity": 17.169,
    "out:Electricity (inc PV)": 11.433081,
    "out:Total Energy Use Pre PV": 135.169,
    "out:Total Energy Use Post PV": 129.433081,
    "out:Primary Energy": 100.695084,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 19.865109,
    "out:CO2 Operational/m2": 5.586639,
    "out:Total CO2/m2": 25.451748,
    "out:Total CO2 (tons)": 81.038341,
    "out:Klimatpaverkan": -119.09708,
    "out:Initial Cost/MSEK": 3.8562722350944365,
    "out:Running cost/(Apt SEK y)": 24918.276596,
    "out:Running Cost over RSP/MSEK": 9.16592,
    "out:LCP/MSEK": 0.382528,
    "out:ROI% old": 15.702804,
    "out:Payback discounted": 8,
    "out:Atemp": 3184,
    "out:Umean": 0.82,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 129,
    "out:Energy use kWh/m2": 129,
    "out:Energy savings %": 41.9,
    "out:Op energy cost/MSEK": 67,
    "out:El price/MSEK": 41,
    "out:Heating price/MSEK": 23,
    "out:DH+ DHW/kWh/m2": 118,
    "out:El bought/kWh/m2": 807.631491,
    "out:Return %": 14,
    "out:Return/kSEK/y": 544,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 33.36,
    "out:EAHP (El)": 0,
    "out:PV (kr/kWp)": 17461.56255567083,
    "out:PV (kWp installed)": 45.25485,
    "out:PV (m2 panels)": 237.433632,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 42461.284501,
    "out:PV (kWh sold)": 24198.124428,
    "out:PV (% sold (El))": 56.988677,
    "out:PV (kWh self-consumed)": 18263.160073,
    "out:PV (ratio sold/self-consumed)": 1.324969,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 978564.8401067974,
    "out:FTX (Diffusers)": 206476.5625,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 163038.685074,
    "out:EL kWh savings": 3619.949007,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 206575.77,
    "out:DH kr savings": 163038.68507405627,
    "out:El kr savings": 7239.898013663279,
    "out:El kr sold": 36297.18664221638,
    "out:El kr saved": 36526.320146,
    "out:El kr return": 72823.50678821639,
    "out:% solar/total": 46,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 270658.043166,
    "out:% savings (space heating)": 33.522475,
    "out:Total_Water Heating (DH)": 106218.208041,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 997124.4110739925,
    "out:Eupp": 85.01,
    "out:Etvv": 20,
    "out:Ef": 11.43,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 3856272.2350944364,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 42.478112,
    "out:EL CO2": -36.891473,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -38.403999,
    "out:Bought CO2": 1.512526,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00001000110000110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 80.269617,
    "out:DHW (DH)": 33.36,
    "out:Property Electricity": 17.169,
    "out:Electricity (inc PV)": 11.433081,
    "out:Total Energy Use Pre PV": 131.169,
    "out:Total Energy Use Post PV": 125.433081,
    "out:Primary Energy": 97.01147,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 19.865109,
    "out:CO2 Operational/m2": 4.146703,
    "out:Total CO2/m2": 24.011812,
    "out:Total CO2 (tons)": 76.453586,
    "out:Klimatpaverkan": -123.681835,
    "out:Initial Cost/MSEK": 3.9358722100944354,
    "out:Running cost/(Apt SEK y)": 24099.914894,
    "out:Running Cost over RSP/MSEK": 8.86507,
    "out:LCP/MSEK": 0.603778,
    "out:ROI% old": 16.477197,
    "out:Payback discounted": 7,
    "out:Atemp": 3184,
    "out:Umean": 0.82,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 125,
    "out:Energy use kWh/m2": 125,
    "out:Energy savings %": 46.4,
    "out:Op energy cost/MSEK": 65,
    "out:El price/MSEK": 39,
    "out:Heating price/MSEK": 22,
    "out:DH+ DHW/kWh/m2": 114,
    "out:El bought/kWh/m2": 807.631491,
    "out:Return %": 15,
    "out:Return/kSEK/y": 582,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 33.36,
    "out:EAHP (El)": 0,
    "out:PV (kr/kWp)": 17461.56255567083,
    "out:PV (kWp installed)": 45.25485,
    "out:PV (m2 panels)": 237.433632,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 42461.284501,
    "out:PV (kWh sold)": 24198.124428,
    "out:PV (% sold (El))": 56.988677,
    "out:PV (kWh self-consumed)": 18263.160073,
    "out:PV (ratio sold/self-consumed)": 1.324969,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 978564.8401067974,
    "out:FTX (Diffusers)": 206476.5625,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 178118.343974,
    "out:EL kWh savings": 3619.949007,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 79599.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 221655.43,
    "out:DH kr savings": 178118.34397369623,
    "out:El kr savings": 7239.898013663279,
    "out:El kr sold": 36297.18664221638,
    "out:El kr saved": 36526.320146,
    "out:El kr return": 72823.50678821639,
    "out:% solar/total": 46,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 255578.384267,
    "out:% savings (space heating)": 37.226257,
    "out:Total_Water Heating (DH)": 106218.208041,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 997124.4110739925,
    "out:Eupp": 80.27,
    "out:Etvv": 20,
    "out:Ef": 11.43,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 3935872.2100944356,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 41.038176,
    "out:EL CO2": -36.891473,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -38.403999,
    "out:Bought CO2": 1.512526,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00001000110001010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 85.005692,
    "out:DHW (DH)": 28.356,
    "out:Property Electricity": 17.169,
    "out:Electricity (inc PV)": 11.433081,
    "out:Total Energy Use Pre PV": 130.169,
    "out:Total Energy Use Post PV": 124.433081,
    "out:Primary Energy": 98.595084,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 20.573652,
    "out:CO2 Operational/m2": 3.786719,
    "out:Total CO2/m2": 24.360371,
    "out:Total CO2 (tons)": 77.563398,
    "out:Klimatpaverkan": -122.572023,
    "out:Initial Cost/MSEK": 4.129488235094436,
    "out:Running cost/(Apt SEK y)": 23895.319149,
    "out:Running Cost over RSP/MSEK": 8.78986,
    "out:LCP/MSEK": 0.485372,
    "out:ROI% old": 15.964828,
    "out:Payback discounted": 8,
    "out:Atemp": 3184,
    "out:Umean": 0.82,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 124,
    "out:Energy use kWh/m2": 124,
    "out:Energy savings %": 47.6,
    "out:Op energy cost/MSEK": 65,
    "out:El price/MSEK": 39,
    "out:Heating price/MSEK": 22,
    "out:DH+ DHW/kWh/m2": 113,
    "out:El bought/kWh/m2": 807.631491,
    "out:Return %": 14,
    "out:Return/kSEK/y": 592,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 28.356,
    "out:EAHP (El)": 0,
    "out:PV (kr/kWp)": 17461.56255567083,
    "out:PV (kWp installed)": 45.25485,
    "out:PV (m2 panels)": 237.433632,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 42461.284501,
    "out:PV (kWh sold)": 24198.124428,
    "out:PV (% sold (El))": 56.988677,
    "out:PV (kWh self-consumed)": 18263.160073,
    "out:PV (ratio sold/self-consumed)": 1.324969,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 978564.8401067974,
    "out:FTX (Diffusers)": 206476.5625,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 178971.41628,
    "out:EL kWh savings": 3619.949007,
    "out:Sewage heat recovery cost": 273216,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 222508.5,
    "out:DH kr savings": 178971.41628022422,
    "out:El kr savings": 7239.898013663279,
    "out:El kr sold": 36297.18664221638,
    "out:El kr saved": 36526.320146,
    "out:El kr return": 72823.50678821639,
    "out:% solar/total": 46,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 270658.043166,
    "out:% savings (space heating)": 33.522475,
    "out:Total_Water Heating (DH)": 90285.476835,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 997124.4110739925,
    "out:Eupp": 85.01,
    "out:Etvv": 17,
    "out:Ef": 11.43,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 4129488.2350944355,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 40.678192,
    "out:EL CO2": -36.891473,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -38.403999,
    "out:Bought CO2": 1.512526,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00001000110001110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 80.269617,
    "out:DHW (DH)": 28.356,
    "out:Property Electricity": 17.169,
    "out:Electricity (inc PV)": 11.433081,
    "out:Total Energy Use Pre PV": 126.169,
    "out:Total Energy Use Post PV": 120.433081,
    "out:Primary Energy": 94.91147,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 20.573652,
    "out:CO2 Operational/m2": 2.346783,
    "out:Total CO2/m2": 22.920435,
    "out:Total CO2 (tons)": 72.978643,
    "out:Klimatpaverkan": -127.156778,
    "out:Initial Cost/MSEK": 4.2090882100944365,
    "out:Running cost/(Apt SEK y)": 23076.957447,
    "out:Running Cost over RSP/MSEK": 8.48902,
    "out:LCP/MSEK": 0.706612,
    "out:ROI% old": 16.683966,
    "out:Payback discounted": 7,
    "out:Atemp": 3184,
    "out:Umean": 0.82,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 120,
    "out:Energy use kWh/m2": 120,
    "out:Energy savings %": 52.5,
    "out:Op energy cost/MSEK": 62,
    "out:El price/MSEK": 38,
    "out:Heating price/MSEK": 21,
    "out:DH+ DHW/kWh/m2": 109,
    "out:El bought/kWh/m2": 807.631491,
    "out:Return %": 15,
    "out:Return/kSEK/y": 630,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 28.356,
    "out:EAHP (El)": 0,
    "out:PV (kr/kWp)": 17461.56255567083,
    "out:PV (kWp installed)": 45.25485,
    "out:PV (m2 panels)": 237.433632,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 42461.284501,
    "out:PV (kWh sold)": 24198.124428,
    "out:PV (% sold (El))": 56.988677,
    "out:PV (kWh self-consumed)": 18263.160073,
    "out:PV (ratio sold/self-consumed)": 1.324969,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 978564.8401067974,
    "out:FTX (Diffusers)": 206476.5625,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 194051.07518,
    "out:EL kWh savings": 3619.949007,
    "out:Sewage heat recovery cost": 273216,
    "out:Balancing hydronic system cost": 79599.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 237588.16,
    "out:DH kr savings": 194051.07517986425,
    "out:El kr savings": 7239.898013663189,
    "out:El kr sold": 36297.18664221638,
    "out:El kr saved": 36526.320146,
    "out:El kr return": 72823.50678821639,
    "out:% solar/total": 46,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 255578.384267,
    "out:% savings (space heating)": 37.226257,
    "out:Total_Water Heating (DH)": 90285.476835,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 997124.4110739925,
    "out:Eupp": 80.27,
    "out:Etvv": 17,
    "out:Ef": 11.43,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 4209088.210094436,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 39.238256,
    "out:EL CO2": -36.891473,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -38.403999,
    "out:Bought CO2": 1.512526,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00001000120000010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 85.005692,
    "out:DHW (DH)": 33.36,
    "out:Property Electricity": 17.169,
    "out:Electricity (inc PV)": 10.564609,
    "out:Total Energy Use Pre PV": 135.169,
    "out:Total Energy Use Post PV": 128.564609,
    "out:Primary Energy": 99.131834,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 34.604208,
    "out:CO2 Operational/m2": -57.99701,
    "out:Total CO2/m2": -23.392802,
    "out:Total CO2 (tons)": -74.482659,
    "out:Klimatpaverkan": -274.61808,
    "out:Initial Cost/MSEK": 4.646485586815686,
    "out:Running cost/(Apt SEK y)": 23533.702128,
    "out:Running Cost over RSP/MSEK": 8.67117,
    "out:LCP/MSEK": 0.087064,
    "out:ROI% old": 14.553396,
    "out:Payback discounted": 8,
    "out:Atemp": 3184,
    "out:Umean": 0.82,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 129,
    "out:Energy use kWh/m2": 129,
    "out:Energy savings %": 41.9,
    "out:Op energy cost/MSEK": 64,
    "out:El price/MSEK": 38,
    "out:Heating price/MSEK": 23,
    "out:DH+ DHW/kWh/m2": 118,
    "out:El bought/kWh/m2": 744.654907,
    "out:Return %": 13,
    "out:Return/kSEK/y": 609,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 33.36,
    "out:EAHP (El)": 0,
    "out:PV (kr/kWp)": 17461.48474631725,
    "out:PV (kWp installed)": 90.5097,
    "out:PV (m2 panels)": 474.867263,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 84922.568824,
    "out:PV (kWh sold)": 63894.192746,
    "out:PV (% sold (El))": 75.238177,
    "out:PV (kWh self-consumed)": 21028.376078,
    "out:PV (ratio sold/self-consumed)": 3.038475,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 978564.8401067974,
    "out:FTX (Diffusers)": 206476.5625,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 163038.685074,
    "out:EL kWh savings": 6385.163023,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 271650.3,
    "out:DH kr savings": 163038.68507405627,
    "out:El kr savings": 12770.326045670909,
    "out:El kr sold": 95841.28911946694,
    "out:El kr saved": 42056.752156,
    "out:El kr return": 137898.04127546694,
    "out:% solar/total": 53,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 270658.043166,
    "out:% savings (space heating)": 33.522475,
    "out:Total_Water Heating (DH)": 106218.208041,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 997124.4110739925,
    "out:Eupp": 85.01,
    "out:Etvv": 20,
    "out:Ef": 10.56,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 4646485.586815686,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 42.478112,
    "out:EL CO2": -100.475122,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -101.871982,
    "out:Bought CO2": 1.39686,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00001000120000110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 80.269617,
    "out:DHW (DH)": 33.36,
    "out:Property Electricity": 17.169,
    "out:Electricity (inc PV)": 10.564609,
    "out:Total Energy Use Pre PV": 131.169,
    "out:Total Energy Use Post PV": 124.564609,
    "out:Primary Energy": 95.44822,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 34.604208,
    "out:CO2 Operational/m2": -59.436946,
    "out:Total CO2/m2": -24.832738,
    "out:Total CO2 (tons)": -79.067414,
    "out:Klimatpaverkan": -279.202835,
    "out:Initial Cost/MSEK": 4.726085561815686,
    "out:Running cost/(Apt SEK y)": 22715.340426,
    "out:Running Cost over RSP/MSEK": 8.37032,
    "out:LCP/MSEK": 0.308314,
    "out:ROI% old": 15.217668,
    "out:Payback discounted": 8,
    "out:Atemp": 3184,
    "out:Umean": 0.82,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 125,
    "out:Energy use kWh/m2": 125,
    "out:Energy savings %": 46.4,
    "out:Op energy cost/MSEK": 62,
    "out:El price/MSEK": 36,
    "out:Heating price/MSEK": 22,
    "out:DH+ DHW/kWh/m2": 114,
    "out:El bought/kWh/m2": 744.654907,
    "out:Return %": 14,
    "out:Return/kSEK/y": 647,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 33.36,
    "out:EAHP (El)": 0,
    "out:PV (kr/kWp)": 17461.48474631725,
    "out:PV (kWp installed)": 90.5097,
    "out:PV (m2 panels)": 474.867263,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 84922.568824,
    "out:PV (kWh sold)": 63894.192746,
    "out:PV (% sold (El))": 75.238177,
    "out:PV (kWh self-consumed)": 21028.376078,
    "out:PV (ratio sold/self-consumed)": 3.038475,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 978564.8401067974,
    "out:FTX (Diffusers)": 206476.5625,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 178118.343974,
    "out:EL kWh savings": 6385.163023,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 79599.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 286729.96,
    "out:DH kr savings": 178118.34397369623,
    "out:El kr savings": 12770.326045670909,
    "out:El kr sold": 95841.28911946694,
    "out:El kr saved": 42056.752156,
    "out:El kr return": 137898.04127546694,
    "out:% solar/total": 53,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 255578.384267,
    "out:% savings (space heating)": 37.226257,
    "out:Total_Water Heating (DH)": 106218.208041,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 997124.4110739925,
    "out:Eupp": 80.27,
    "out:Etvv": 20,
    "out:Ef": 10.56,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 4726085.561815686,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 41.038176,
    "out:EL CO2": -100.475122,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -101.871982,
    "out:Bought CO2": 1.39686,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00001000120001010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 85.005692,
    "out:DHW (DH)": 28.356,
    "out:Property Electricity": 17.169,
    "out:Electricity (inc PV)": 10.564609,
    "out:Total Energy Use Pre PV": 130.169,
    "out:Total Energy Use Post PV": 123.564609,
    "out:Primary Energy": 97.031834,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 35.312751,
    "out:CO2 Operational/m2": -59.79693,
    "out:Total CO2/m2": -24.484179,
    "out:Total CO2 (tons)": -77.957602,
    "out:Klimatpaverkan": -278.093024,
    "out:Initial Cost/MSEK": 4.919701586815686,
    "out:Running cost/(Apt SEK y)": 22510.765957,
    "out:Running Cost over RSP/MSEK": 8.29511,
    "out:LCP/MSEK": 0.189908,
    "out:ROI% old": 14.837166,
    "out:Payback discounted": 8,
    "out:Atemp": 3184,
    "out:Umean": 0.82,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 124,
    "out:Energy use kWh/m2": 124,
    "out:Energy savings %": 47.6,
    "out:Op energy cost/MSEK": 62,
    "out:El price/MSEK": 36,
    "out:Heating price/MSEK": 22,
    "out:DH+ DHW/kWh/m2": 113,
    "out:El bought/kWh/m2": 744.654907,
    "out:Return %": 13,
    "out:Return/kSEK/y": 657,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 28.356,
    "out:EAHP (El)": 0,
    "out:PV (kr/kWp)": 17461.48474631725,
    "out:PV (kWp installed)": 90.5097,
    "out:PV (m2 panels)": 474.867263,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 84922.568824,
    "out:PV (kWh sold)": 63894.192746,
    "out:PV (% sold (El))": 75.238177,
    "out:PV (kWh self-consumed)": 21028.376078,
    "out:PV (ratio sold/self-consumed)": 3.038475,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 978564.8401067974,
    "out:FTX (Diffusers)": 206476.5625,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 178971.41628,
    "out:EL kWh savings": 6385.163023,
    "out:Sewage heat recovery cost": 273216,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 287583.03,
    "out:DH kr savings": 178971.41628022422,
    "out:El kr savings": 12770.326045670909,
    "out:El kr sold": 95841.28911946694,
    "out:El kr saved": 42056.752156,
    "out:El kr return": 137898.04127546694,
    "out:% solar/total": 53,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 270658.043166,
    "out:% savings (space heating)": 33.522475,
    "out:Total_Water Heating (DH)": 90285.476835,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 997124.4110739925,
    "out:Eupp": 85.01,
    "out:Etvv": 17,
    "out:Ef": 10.56,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 4919701.586815686,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 40.678192,
    "out:EL CO2": -100.475122,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -101.871982,
    "out:Bought CO2": 1.39686,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00001000120001110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 80.269617,
    "out:DHW (DH)": 28.356,
    "out:Property Electricity": 17.169,
    "out:Electricity (inc PV)": 10.564609,
    "out:Total Energy Use Pre PV": 126.169,
    "out:Total Energy Use Post PV": 119.564609,
    "out:Primary Energy": 93.34822,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 35.312751,
    "out:CO2 Operational/m2": -61.236866,
    "out:Total CO2/m2": -25.924115,
    "out:Total CO2 (tons)": -82.542357,
    "out:Klimatpaverkan": -282.677778,
    "out:Initial Cost/MSEK": 4.9993015618156855,
    "out:Running cost/(Apt SEK y)": 21692.404255,
    "out:Running Cost over RSP/MSEK": 7.99427,
    "out:LCP/MSEK": 0.411148,
    "out:ROI% old": 15.460588,
    "out:Payback discounted": 8,
    "out:Atemp": 3184,
    "out:Umean": 0.82,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 120,
    "out:Energy use kWh/m2": 120,
    "out:Energy savings %": 52.5,
    "out:Op energy cost/MSEK": 59,
    "out:El price/MSEK": 35,
    "out:Heating price/MSEK": 21,
    "out:DH+ DHW/kWh/m2": 109,
    "out:El bought/kWh/m2": 744.654907,
    "out:Return %": 14,
    "out:Return/kSEK/y": 695,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 28.356,
    "out:EAHP (El)": 0,
    "out:PV (kr/kWp)": 17461.48474631725,
    "out:PV (kWp installed)": 90.5097,
    "out:PV (m2 panels)": 474.867263,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 84922.568824,
    "out:PV (kWh sold)": 63894.192746,
    "out:PV (% sold (El))": 75.238177,
    "out:PV (kWh self-consumed)": 21028.376078,
    "out:PV (ratio sold/self-consumed)": 3.038475,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 978564.8401067974,
    "out:FTX (Diffusers)": 206476.5625,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 194051.07518,
    "out:EL kWh savings": 6385.163023,
    "out:Sewage heat recovery cost": 273216,
    "out:Balancing hydronic system cost": 79599.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 302662.69,
    "out:DH kr savings": 194051.07517986425,
    "out:El kr savings": 12770.326045670818,
    "out:El kr sold": 95841.28911946694,
    "out:El kr saved": 42056.752156,
    "out:El kr return": 137898.04127546694,
    "out:% solar/total": 53,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 255578.384267,
    "out:% savings (space heating)": 37.226257,
    "out:Total_Water Heating (DH)": 90285.476835,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 997124.4110739925,
    "out:Eupp": 80.27,
    "out:Etvv": 17,
    "out:Ef": 10.56,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 4999301.561815686,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 39.238256,
    "out:EL CO2": -100.475122,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -101.871982,
    "out:Bought CO2": 1.39686,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01000000000000010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 75.768092,
    "out:DHW (DH)": 41.7,
    "out:Property Electricity": 17.169,
    "out:Electricity (inc PV)": 17.169,
    "out:Total Energy Use Pre PV": 134.169,
    "out:Total Energy Use Post PV": 134.169,
    "out:Primary Energy": 107.334938,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 14.182056,
    "out:CO2 Operational/m2": 44.384573,
    "out:Total CO2/m2": 58.566629,
    "out:Total CO2 (tons)": 186.476091,
    "out:Klimatpaverkan": -13.65933,
    "out:Initial Cost/MSEK": 5.261167300368186,
    "out:Running cost/(Apt SEK y)": 26263.106383,
    "out:Running Cost over RSP/MSEK": 9.64494,
    "out:LCP/MSEK": -1.501387,
    "out:ROI% old": 10.208978,
    "out:Payback discounted": 12,
    "out:Atemp": 3184,
    "out:Umean": 0.65,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 134,
    "out:Energy use kWh/m2": 134,
    "out:Energy savings %": 36.6,
    "out:Op energy cost/MSEK": 70,
    "out:El price/MSEK": 44,
    "out:Heating price/MSEK": 23,
    "out:DH+ DHW/kWh/m2": 117,
    "out:El bought/kWh/m2": 1201.83,
    "out:Return %": 9,
    "out:Return/kSEK/y": 480,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 41.7,
    "out:EAHP (El)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 978564.8401067974,
    "out:FTX (Diffusers)": 206476.5625,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 165896.64548,
    "out:EL kWh savings": -14643.211594,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 136610.22,
    "out:DH kr savings": 165896.64547975454,
    "out:El kr savings": -29286.42318831607,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 241245.53075,
    "out:% savings (space heating)": 40.746613,
    "out:Total_Water Heating (DH)": 132772.760051,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 997124.4110739925,
    "out:Eupp": 75.77,
    "out:Etvv": 25,
    "out:Ef": 17.17,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 5261167.300368186,
    "out:Seasonal Variation ROI (%)": 1,
    "out:Seasonal Variation Payback": 100,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 42.118128,
    "out:EL CO2": 2.266445,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 2.266445,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01000000000000110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 71.451907,
    "out:DHW (DH)": 41.7,
    "out:Property Electricity": 17.169,
    "out:Electricity (inc PV)": 17.169,
    "out:Total Energy Use Pre PV": 130.169,
    "out:Total Energy Use Post PV": 130.169,
    "out:Primary Energy": 103.977905,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 14.182056,
    "out:CO2 Operational/m2": 42.944637,
    "out:Total CO2/m2": 57.126693,
    "out:Total CO2 (tons)": 181.891336,
    "out:Klimatpaverkan": -18.244085,
    "out:Initial Cost/MSEK": 5.340767275368186,
    "out:Running cost/(Apt SEK y)": 25444.765957,
    "out:Running Cost over RSP/MSEK": 9.34409,
    "out:LCP/MSEK": -1.280137,
    "out:ROI% old": 10.861548,
    "out:Payback discounted": 11,
    "out:Atemp": 3184,
    "out:Umean": 0.65,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 130,
    "out:Energy use kWh/m2": 130,
    "out:Energy savings %": 40.8,
    "out:Op energy cost/MSEK": 68,
    "out:El price/MSEK": 42,
    "out:Heating price/MSEK": 22,
    "out:DH+ DHW/kWh/m2": 113,
    "out:El bought/kWh/m2": 1201.83,
    "out:Return %": 10,
    "out:Return/kSEK/y": 519,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 41.7,
    "out:EAHP (El)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 978564.8401067974,
    "out:FTX (Diffusers)": 206476.5625,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 179639.371997,
    "out:EL kWh savings": -14643.211594,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 79599.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 150352.95,
    "out:DH kr savings": 179639.37199685007,
    "out:El kr savings": -29286.42318831607,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 227502.804233,
    "out:% savings (space heating)": 44.122025,
    "out:Total_Water Heating (DH)": 132772.760051,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 997124.4110739925,
    "out:Eupp": 71.45,
    "out:Etvv": 25,
    "out:Ef": 17.17,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 5340767.275368186,
    "out:Seasonal Variation ROI (%)": 1,
    "out:Seasonal Variation Payback": 100,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 40.678192,
    "out:EL CO2": 2.266445,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 2.266445,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01000000000001010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 75.768092,
    "out:DHW (DH)": 35.445,
    "out:Property Electricity": 17.169,
    "out:Electricity (inc PV)": 17.169,
    "out:Total Energy Use Pre PV": 128.169,
    "out:Total Energy Use Post PV": 128.169,
    "out:Primary Energy": 104.709938,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 14.890599,
    "out:CO2 Operational/m2": 42.224669,
    "out:Total CO2/m2": 57.115268,
    "out:Total CO2 (tons)": 181.854959,
    "out:Klimatpaverkan": -18.280462,
    "out:Initial Cost/MSEK": 5.534383300368186,
    "out:Running cost/(Apt SEK y)": 25035.574468,
    "out:Running Cost over RSP/MSEK": 9.19367,
    "out:LCP/MSEK": -1.323333,
    "out:ROI% old": 10.869839,
    "out:Payback discounted": 11,
    "out:Atemp": 3184,
    "out:Umean": 0.65,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 128,
    "out:Energy use kWh/m2": 128,
    "out:Energy savings %": 43,
    "out:Op energy cost/MSEK": 67,
    "out:El price/MSEK": 42,
    "out:Heating price/MSEK": 22,
    "out:DH+ DHW/kWh/m2": 111,
    "out:El bought/kWh/m2": 1201.83,
    "out:Return %": 10,
    "out:Return/kSEK/y": 538,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 35.445,
    "out:EAHP (El)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 978564.8401067974,
    "out:FTX (Diffusers)": 206476.5625,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 185812.559487,
    "out:EL kWh savings": -14643.211594,
    "out:Sewage heat recovery cost": 273216,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 156526.14,
    "out:DH kr savings": 185812.55948746455,
    "out:El kr savings": -29286.42318831607,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 241245.53075,
    "out:% savings (space heating)": 40.746613,
    "out:Total_Water Heating (DH)": 112856.846044,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 997124.4110739925,
    "out:Eupp": 75.77,
    "out:Etvv": 21.25,
    "out:Ef": 17.17,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 5534383.300368186,
    "out:Seasonal Variation ROI (%)": 1,
    "out:Seasonal Variation Payback": 100,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 39.958224,
    "out:EL CO2": 2.266445,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 2.266445,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01000000000001110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 71.451907,
    "out:DHW (DH)": 35.445,
    "out:Property Electricity": 17.169,
    "out:Electricity (inc PV)": 17.169,
    "out:Total Energy Use Pre PV": 124.169,
    "out:Total Energy Use Post PV": 124.169,
    "out:Primary Energy": 101.352905,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 14.890599,
    "out:CO2 Operational/m2": 40.784733,
    "out:Total CO2/m2": 55.675332,
    "out:Total CO2 (tons)": 177.270204,
    "out:Klimatpaverkan": -22.865217,
    "out:Initial Cost/MSEK": 5.613983275368185,
    "out:Running cost/(Apt SEK y)": 24217.234043,
    "out:Running Cost over RSP/MSEK": 8.89282,
    "out:LCP/MSEK": -1.102083,
    "out:ROI% old": 11.48128,
    "out:Payback discounted": 11,
    "out:Atemp": 3184,
    "out:Umean": 0.65,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 124,
    "out:Energy use kWh/m2": 124,
    "out:Energy savings %": 47.6,
    "out:Op energy cost/MSEK": 65,
    "out:El price/MSEK": 40,
    "out:Heating price/MSEK": 21,
    "out:DH+ DHW/kWh/m2": 107,
    "out:El bought/kWh/m2": 1201.83,
    "out:Return %": 10,
    "out:Return/kSEK/y": 577,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 35.445,
    "out:EAHP (El)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 978564.8401067974,
    "out:FTX (Diffusers)": 206476.5625,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 199555.286005,
    "out:EL kWh savings": -14643.211594,
    "out:Sewage heat recovery cost": 273216,
    "out:Balancing hydronic system cost": 79599.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 170268.86,
    "out:DH kr savings": 199555.2860045601,
    "out:El kr savings": -29286.423188315977,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 227502.804233,
    "out:% savings (space heating)": 44.122025,
    "out:Total_Water Heating (DH)": 112856.846044,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 997124.4110739925,
    "out:Eupp": 71.45,
    "out:Etvv": 21.25,
    "out:Ef": 17.17,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 5613983.275368186,
    "out:Seasonal Variation ROI (%)": 1,
    "out:Seasonal Variation Payback": 100,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 38.518288,
    "out:EL CO2": 2.266445,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 2.266445,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01000000010000010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 75.768092,
    "out:DHW (DH)": 41.7,
    "out:Property Electricity": 17.169,
    "out:Electricity (inc PV)": 11.433081,
    "out:Total Energy Use Pre PV": 134.169,
    "out:Total Energy Use Post PV": 128.433081,
    "out:Primary Energy": 97.010284,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 28.921156,
    "out:CO2 Operational/m2": 5.226655,
    "out:Total CO2/m2": 34.147811,
    "out:Total CO2 (tons)": 108.726598,
    "out:Klimatpaverkan": -91.408824,
    "out:Initial Cost/MSEK": 6.051387694590687,
    "out:Running cost/(Apt SEK y)": 24713.680851,
    "out:Running Cost over RSP/MSEK": 9.09071,
    "out:LCP/MSEK": -1.737378,
    "out:ROI% old": 10.184228,
    "out:Payback discounted": 12,
    "out:Atemp": 3184,
    "out:Umean": 0.65,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 128,
    "out:Energy use kWh/m2": 128,
    "out:Energy savings %": 43,
    "out:Op energy cost/MSEK": 67,
    "out:El price/MSEK": 40,
    "out:Heating price/MSEK": 23,
    "out:DH+ DHW/kWh/m2": 117,
    "out:El bought/kWh/m2": 807.631491,
    "out:Return %": 9,
    "out:Return/kSEK/y": 553,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 41.7,
    "out:EAHP (El)": 0,
    "out:PV (kr/kWp)": 17461.56255567083,
    "out:PV (kWp installed)": 45.25485,
    "out:PV (m2 panels)": 237.433632,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 42461.284501,
    "out:PV (kWh sold)": 24198.124428,
    "out:PV (% sold (El))": 56.988677,
    "out:PV (kWh self-consumed)": 18263.160073,
    "out:PV (ratio sold/self-consumed)": 1.324969,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 978564.8401067974,
    "out:FTX (Diffusers)": 206476.5625,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 165896.64548,
    "out:EL kWh savings": 3619.949007,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 209433.73,
    "out:DH kr savings": 165896.64547975454,
    "out:El kr savings": 7239.898013663279,
    "out:El kr sold": 36297.18664221638,
    "out:El kr saved": 36526.320146,
    "out:El kr return": 72823.50678821639,
    "out:% solar/total": 46,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 241245.53075,
    "out:% savings (space heating)": 40.746613,
    "out:Total_Water Heating (DH)": 132772.760051,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 997124.4110739925,
    "out:Eupp": 75.77,
    "out:Etvv": 25,
    "out:Ef": 11.43,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 6051387.694590687,
    "out:Seasonal Variation ROI (%)": 2,
    "out:Seasonal Variation Payback": 50,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 42.118128,
    "out:EL CO2": -36.891473,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -38.403999,
    "out:Bought CO2": 1.512526,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01000000010000110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 71.451907,
    "out:DHW (DH)": 41.7,
    "out:Property Electricity": 17.169,
    "out:Electricity (inc PV)": 11.433081,
    "out:Total Energy Use Pre PV": 130.169,
    "out:Total Energy Use Post PV": 124.433081,
    "out:Primary Energy": 93.653251,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 28.921156,
    "out:CO2 Operational/m2": 3.786719,
    "out:Total CO2/m2": 32.707875,
    "out:Total CO2 (tons)": 104.141843,
    "out:Klimatpaverkan": -95.993578,
    "out:Initial Cost/MSEK": 6.130987669590685,
    "out:Running cost/(Apt SEK y)": 23895.319149,
    "out:Running Cost over RSP/MSEK": 8.78986,
    "out:LCP/MSEK": -1.516128,
    "out:ROI% old": 10.75301,
    "out:Payback discounted": 11,
    "out:Atemp": 3184,
    "out:Umean": 0.65,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 124,
    "out:Energy use kWh/m2": 124,
    "out:Energy savings %": 47.6,
    "out:Op energy cost/MSEK": 65,
    "out:El price/MSEK": 39,
    "out:Heating price/MSEK": 22,
    "out:DH+ DHW/kWh/m2": 113,
    "out:El bought/kWh/m2": 807.631491,
    "out:Return %": 10,
    "out:Return/kSEK/y": 592,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 41.7,
    "out:EAHP (El)": 0,
    "out:PV (kr/kWp)": 17461.56255567083,
    "out:PV (kWp installed)": 45.25485,
    "out:PV (m2 panels)": 237.433632,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 42461.284501,
    "out:PV (kWh sold)": 24198.124428,
    "out:PV (% sold (El))": 56.988677,
    "out:PV (kWh self-consumed)": 18263.160073,
    "out:PV (ratio sold/self-consumed)": 1.324969,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 978564.8401067974,
    "out:FTX (Diffusers)": 206476.5625,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 179639.371997,
    "out:EL kWh savings": 3619.949007,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 79599.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 223176.46,
    "out:DH kr savings": 179639.37199685007,
    "out:El kr savings": 7239.898013663279,
    "out:El kr sold": 36297.18664221638,
    "out:El kr saved": 36526.320146,
    "out:El kr return": 72823.50678821639,
    "out:% solar/total": 46,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 227502.804233,
    "out:% savings (space heating)": 44.122025,
    "out:Total_Water Heating (DH)": 132772.760051,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 997124.4110739925,
    "out:Eupp": 71.45,
    "out:Etvv": 25,
    "out:Ef": 11.43,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 6130987.6695906855,
    "out:Seasonal Variation ROI (%)": 2,
    "out:Seasonal Variation Payback": 50,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 40.678192,
    "out:EL CO2": -36.891473,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -38.403999,
    "out:Bought CO2": 1.512526,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01000000010001010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 75.768092,
    "out:DHW (DH)": 35.445,
    "out:Property Electricity": 17.169,
    "out:Electricity (inc PV)": 11.433081,
    "out:Total Energy Use Pre PV": 128.169,
    "out:Total Energy Use Post PV": 122.433081,
    "out:Primary Energy": 94.385284,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 29.629699,
    "out:CO2 Operational/m2": 3.066751,
    "out:Total CO2/m2": 32.69645,
    "out:Total CO2 (tons)": 104.105465,
    "out:Klimatpaverkan": -96.029956,
    "out:Initial Cost/MSEK": 6.324603694590686,
    "out:Running cost/(Apt SEK y)": 23486.148936,
    "out:Running Cost over RSP/MSEK": 8.63944,
    "out:LCP/MSEK": -1.559324,
    "out:ROI% old": 10.763588,
    "out:Payback discounted": 11,
    "out:Atemp": 3184,
    "out:Umean": 0.65,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 122,
    "out:Energy use kWh/m2": 122,
    "out:Energy savings %": 50,
    "out:Op energy cost/MSEK": 64,
    "out:El price/MSEK": 38,
    "out:Heating price/MSEK": 22,
    "out:DH+ DHW/kWh/m2": 111,
    "out:El bought/kWh/m2": 807.631491,
    "out:Return %": 10,
    "out:Return/kSEK/y": 611,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 35.445,
    "out:EAHP (El)": 0,
    "out:PV (kr/kWp)": 17461.56255567083,
    "out:PV (kWp installed)": 45.25485,
    "out:PV (m2 panels)": 237.433632,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 42461.284501,
    "out:PV (kWh sold)": 24198.124428,
    "out:PV (% sold (El))": 56.988677,
    "out:PV (kWh self-consumed)": 18263.160073,
    "out:PV (ratio sold/self-consumed)": 1.324969,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 978564.8401067974,
    "out:FTX (Diffusers)": 206476.5625,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 185812.559487,
    "out:EL kWh savings": 3619.949007,
    "out:Sewage heat recovery cost": 273216,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 229349.64,
    "out:DH kr savings": 185812.55948746455,
    "out:El kr savings": 7239.898013663279,
    "out:El kr sold": 36297.18664221638,
    "out:El kr saved": 36526.320146,
    "out:El kr return": 72823.50678821639,
    "out:% solar/total": 46,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 241245.53075,
    "out:% savings (space heating)": 40.746613,
    "out:Total_Water Heating (DH)": 112856.846044,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 997124.4110739925,
    "out:Eupp": 75.77,
    "out:Etvv": 21.25,
    "out:Ef": 11.43,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 6324603.694590686,
    "out:Seasonal Variation ROI (%)": 2,
    "out:Seasonal Variation Payback": 50,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 39.958224,
    "out:EL CO2": -36.891473,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -38.403999,
    "out:Bought CO2": 1.512526,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01000000010001110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 71.451907,
    "out:DHW (DH)": 35.445,
    "out:Property Electricity": 17.169,
    "out:Electricity (inc PV)": 11.433081,
    "out:Total Energy Use Pre PV": 124.169,
    "out:Total Energy Use Post PV": 118.433081,
    "out:Primary Energy": 91.028251,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 29.629699,
    "out:CO2 Operational/m2": 1.626815,
    "out:Total CO2/m2": 31.256514,
    "out:Total CO2 (tons)": 99.520711,
    "out:Klimatpaverkan": -100.61471,
    "out:Initial Cost/MSEK": 6.404203669590688,
    "out:Running cost/(Apt SEK y)": 22667.787234,
    "out:Running Cost over RSP/MSEK": 8.3386,
    "out:LCP/MSEK": -1.338084,
    "out:ROI% old": 11.300881,
    "out:Payback discounted": 11,
    "out:Atemp": 3184,
    "out:Umean": 0.65,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 118,
    "out:Energy use kWh/m2": 118,
    "out:Energy savings %": 55.1,
    "out:Op energy cost/MSEK": 61,
    "out:El price/MSEK": 37,
    "out:Heating price/MSEK": 21,
    "out:DH+ DHW/kWh/m2": 107,
    "out:El bought/kWh/m2": 807.631491,
    "out:Return %": 10,
    "out:Return/kSEK/y": 649,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 35.445,
    "out:EAHP (El)": 0,
    "out:PV (kr/kWp)": 17461.56255567083,
    "out:PV (kWp installed)": 45.25485,
    "out:PV (m2 panels)": 237.433632,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 42461.284501,
    "out:PV (kWh sold)": 24198.124428,
    "out:PV (% sold (El))": 56.988677,
    "out:PV (kWh self-consumed)": 18263.160073,
    "out:PV (ratio sold/self-consumed)": 1.324969,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 978564.8401067974,
    "out:FTX (Diffusers)": 206476.5625,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 199555.286005,
    "out:EL kWh savings": 3619.949007,
    "out:Sewage heat recovery cost": 273216,
    "out:Balancing hydronic system cost": 79599.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 243092.37,
    "out:DH kr savings": 199555.2860045601,
    "out:El kr savings": 7239.898013663189,
    "out:El kr sold": 36297.18664221638,
    "out:El kr saved": 36526.320146,
    "out:El kr return": 72823.50678821639,
    "out:% solar/total": 46,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 227502.804233,
    "out:% savings (space heating)": 44.122025,
    "out:Total_Water Heating (DH)": 112856.846044,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 997124.4110739925,
    "out:Eupp": 71.45,
    "out:Etvv": 21.25,
    "out:Ef": 11.43,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 6404203.669590687,
    "out:Seasonal Variation ROI (%)": 2,
    "out:Seasonal Variation Payback": 50,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 38.518288,
    "out:EL CO2": -36.891473,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -38.403999,
    "out:Bought CO2": 1.512526,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01000000020000010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 75.768092,
    "out:DHW (DH)": 41.7,
    "out:Property Electricity": 17.169,
    "out:Electricity (inc PV)": 10.564609,
    "out:Total Energy Use Pre PV": 134.169,
    "out:Total Energy Use Post PV": 127.564609,
    "out:Primary Energy": 95.447034,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 43.660255,
    "out:CO2 Operational/m2": -58.356994,
    "out:Total CO2/m2": -14.696739,
    "out:Total CO2 (tons)": -46.794403,
    "out:Klimatpaverkan": -246.929824,
    "out:Initial Cost/MSEK": 6.841601046311937,
    "out:Running cost/(Apt SEK y)": 23329.106383,
    "out:Running Cost over RSP/MSEK": 8.59596,
    "out:LCP/MSEK": -2.032841,
    "out:ROI% old": 10.041008,
    "out:Payback discounted": 12,
    "out:Atemp": 3184,
    "out:Umean": 0.65,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 128,
    "out:Energy use kWh/m2": 128,
    "out:Energy savings %": 43,
    "out:Op energy cost/MSEK": 64,
    "out:El price/MSEK": 37,
    "out:Heating price/MSEK": 23,
    "out:DH+ DHW/kWh/m2": 117,
    "out:El bought/kWh/m2": 744.654907,
    "out:Return %": 9,
    "out:Return/kSEK/y": 618,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 41.7,
    "out:EAHP (El)": 0,
    "out:PV (kr/kWp)": 17461.48474631725,
    "out:PV (kWp installed)": 90.5097,
    "out:PV (m2 panels)": 474.867263,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 84922.568824,
    "out:PV (kWh sold)": 63894.192746,
    "out:PV (% sold (El))": 75.238177,
    "out:PV (kWh self-consumed)": 21028.376078,
    "out:PV (ratio sold/self-consumed)": 3.038475,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 978564.8401067974,
    "out:FTX (Diffusers)": 206476.5625,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 165896.64548,
    "out:EL kWh savings": 6385.163023,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 274508.26,
    "out:DH kr savings": 165896.64547975454,
    "out:El kr savings": 12770.326045670909,
    "out:El kr sold": 95841.28911946694,
    "out:El kr saved": 42056.752156,
    "out:El kr return": 137898.04127546694,
    "out:% solar/total": 53,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 241245.53075,
    "out:% savings (space heating)": 40.746613,
    "out:Total_Water Heating (DH)": 132772.760051,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 997124.4110739925,
    "out:Eupp": 75.77,
    "out:Etvv": 25,
    "out:Ef": 10.56,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 6841601.046311937,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 42.118128,
    "out:EL CO2": -100.475122,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -101.871982,
    "out:Bought CO2": 1.39686,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01000000020000110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 71.451907,
    "out:DHW (DH)": 41.7,
    "out:Property Electricity": 17.169,
    "out:Electricity (inc PV)": 10.564609,
    "out:Total Energy Use Pre PV": 130.169,
    "out:Total Energy Use Post PV": 123.564609,
    "out:Primary Energy": 92.090001,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 43.660255,
    "out:CO2 Operational/m2": -59.79693,
    "out:Total CO2/m2": -16.136675,
    "out:Total CO2 (tons)": -51.379158,
    "out:Klimatpaverkan": -251.514579,
    "out:Initial Cost/MSEK": 6.921201021311937,
    "out:Running cost/(Apt SEK y)": 22510.765957,
    "out:Running Cost over RSP/MSEK": 8.29511,
    "out:LCP/MSEK": -1.811591,
    "out:ROI% old": 10.546497,
    "out:Payback discounted": 11,
    "out:Atemp": 3184,
    "out:Umean": 0.65,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 124,
    "out:Energy use kWh/m2": 124,
    "out:Energy savings %": 47.6,
    "out:Op energy cost/MSEK": 62,
    "out:El price/MSEK": 36,
    "out:Heating price/MSEK": 22,
    "out:DH+ DHW/kWh/m2": 113,
    "out:El bought/kWh/m2": 744.654907,
    "out:Return %": 9,
    "out:Return/kSEK/y": 657,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 41.7,
    "out:EAHP (El)": 0,
    "out:PV (kr/kWp)": 17461.48474631725,
    "out:PV (kWp installed)": 90.5097,
    "out:PV (m2 panels)": 474.867263,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 84922.568824,
    "out:PV (kWh sold)": 63894.192746,
    "out:PV (% sold (El))": 75.238177,
    "out:PV (kWh self-consumed)": 21028.376078,
    "out:PV (ratio sold/self-consumed)": 3.038475,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 978564.8401067974,
    "out:FTX (Diffusers)": 206476.5625,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 179639.371997,
    "out:EL kWh savings": 6385.163023,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 79599.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 288250.99,
    "out:DH kr savings": 179639.37199685007,
    "out:El kr savings": 12770.326045670909,
    "out:El kr sold": 95841.28911946694,
    "out:El kr saved": 42056.752156,
    "out:El kr return": 137898.04127546694,
    "out:% solar/total": 53,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 227502.804233,
    "out:% savings (space heating)": 44.122025,
    "out:Total_Water Heating (DH)": 132772.760051,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 997124.4110739925,
    "out:Eupp": 71.45,
    "out:Etvv": 25,
    "out:Ef": 10.56,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 6921201.021311937,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 40.678192,
    "out:EL CO2": -100.475122,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -101.871982,
    "out:Bought CO2": 1.39686,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01000000020001010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 75.768092,
    "out:DHW (DH)": 35.445,
    "out:Property Electricity": 17.169,
    "out:Electricity (inc PV)": 10.564609,
    "out:Total Energy Use Pre PV": 128.169,
    "out:Total Energy Use Post PV": 121.564609,
    "out:Primary Energy": 92.822034,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 44.368798,
    "out:CO2 Operational/m2": -60.516898,
    "out:Total CO2/m2": -16.1481,
    "out:Total CO2 (tons)": -51.415535,
    "out:Klimatpaverkan": -251.550956,
    "out:Initial Cost/MSEK": 7.114817046311936,
    "out:Running cost/(Apt SEK y)": 22101.574468,
    "out:Running Cost over RSP/MSEK": 8.14469,
    "out:LCP/MSEK": -1.854787,
    "out:ROI% old": 10.56152,
    "out:Payback discounted": 11,
    "out:Atemp": 3184,
    "out:Umean": 0.65,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 122,
    "out:Energy use kWh/m2": 122,
    "out:Energy savings %": 50,
    "out:Op energy cost/MSEK": 61,
    "out:El price/MSEK": 35,
    "out:Heating price/MSEK": 22,
    "out:DH+ DHW/kWh/m2": 111,
    "out:El bought/kWh/m2": 744.654907,
    "out:Return %": 10,
    "out:Return/kSEK/y": 676,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 35.445,
    "out:EAHP (El)": 0,
    "out:PV (kr/kWp)": 17461.48474631725,
    "out:PV (kWp installed)": 90.5097,
    "out:PV (m2 panels)": 474.867263,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 84922.568824,
    "out:PV (kWh sold)": 63894.192746,
    "out:PV (% sold (El))": 75.238177,
    "out:PV (kWh self-consumed)": 21028.376078,
    "out:PV (ratio sold/self-consumed)": 3.038475,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 978564.8401067974,
    "out:FTX (Diffusers)": 206476.5625,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 185812.559487,
    "out:EL kWh savings": 6385.163023,
    "out:Sewage heat recovery cost": 273216,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 294424.17,
    "out:DH kr savings": 185812.55948746455,
    "out:El kr savings": 12770.326045670909,
    "out:El kr sold": 95841.28911946694,
    "out:El kr saved": 42056.752156,
    "out:El kr return": 137898.04127546694,
    "out:% solar/total": 53,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 241245.53075,
    "out:% savings (space heating)": 40.746613,
    "out:Total_Water Heating (DH)": 112856.846044,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 997124.4110739925,
    "out:Eupp": 75.77,
    "out:Etvv": 21.25,
    "out:Ef": 10.56,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 7114817.046311936,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 39.958224,
    "out:EL CO2": -100.475122,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -101.871982,
    "out:Bought CO2": 1.39686,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01000000020001110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 71.451907,
    "out:DHW (DH)": 35.445,
    "out:Property Electricity": 17.169,
    "out:Electricity (inc PV)": 10.564609,
    "out:Total Energy Use Pre PV": 124.169,
    "out:Total Energy Use Post PV": 117.564609,
    "out:Primary Energy": 89.465001,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 44.368798,
    "out:CO2 Operational/m2": -61.956834,
    "out:Total CO2/m2": -17.588036,
    "out:Total CO2 (tons)": -56.00029,
    "out:Klimatpaverkan": -256.135711,
    "out:Initial Cost/MSEK": 7.194417021311936,
    "out:Running cost/(Apt SEK y)": 21283.234043,
    "out:Running Cost over RSP/MSEK": 7.84385,
    "out:LCP/MSEK": -1.633547,
    "out:ROI% old": 11.042034,
    "out:Payback discounted": 11,
    "out:Atemp": 3184,
    "out:Umean": 0.65,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 118,
    "out:Energy use kWh/m2": 118,
    "out:Energy savings %": 55.1,
    "out:Op energy cost/MSEK": 58,
    "out:El price/MSEK": 34,
    "out:Heating price/MSEK": 21,
    "out:DH+ DHW/kWh/m2": 107,
    "out:El bought/kWh/m2": 744.654907,
    "out:Return %": 10,
    "out:Return/kSEK/y": 714,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 35.445,
    "out:EAHP (El)": 0,
    "out:PV (kr/kWp)": 17461.48474631725,
    "out:PV (kWp installed)": 90.5097,
    "out:PV (m2 panels)": 474.867263,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 84922.568824,
    "out:PV (kWh sold)": 63894.192746,
    "out:PV (% sold (El))": 75.238177,
    "out:PV (kWh self-consumed)": 21028.376078,
    "out:PV (ratio sold/self-consumed)": 3.038475,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 978564.8401067974,
    "out:FTX (Diffusers)": 206476.5625,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 199555.286005,
    "out:EL kWh savings": 6385.163023,
    "out:Sewage heat recovery cost": 273216,
    "out:Balancing hydronic system cost": 79599.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 308166.9,
    "out:DH kr savings": 199555.2860045601,
    "out:El kr savings": 12770.326045670818,
    "out:El kr sold": 95841.28911946694,
    "out:El kr saved": 42056.752156,
    "out:El kr return": 137898.04127546694,
    "out:% solar/total": 53,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 227502.804233,
    "out:% savings (space heating)": 44.122025,
    "out:Total_Water Heating (DH)": 112856.846044,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 997124.4110739925,
    "out:Eupp": 71.45,
    "out:Etvv": 21.25,
    "out:Ef": 10.56,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 7194417.021311936,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 38.518288,
    "out:EL CO2": -100.475122,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -101.871982,
    "out:Bought CO2": 1.39686,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01000000100000010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 75.768092,
    "out:DHW (DH)": 33.36,
    "out:Property Electricity": 17.169,
    "out:Electricity (inc PV)": 17.169,
    "out:Total Energy Use Pre PV": 126.169,
    "out:Total Energy Use Post PV": 126.169,
    "out:Primary Energy": 103.834938,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 14.182056,
    "out:CO2 Operational/m2": 41.504701,
    "out:Total CO2/m2": 55.686757,
    "out:Total CO2 (tons)": 177.306581,
    "out:Klimatpaverkan": -22.82884,
    "out:Initial Cost/MSEK": 5.385214987868186,
    "out:Running cost/(Apt SEK y)": 24626.404255,
    "out:Running Cost over RSP/MSEK": 9.04325,
    "out:LCP/MSEK": -1.023745,
    "out:ROI% old": 11.569959,
    "out:Payback discounted": 10,
    "out:Atemp": 3184,
    "out:Umean": 0.65,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 126,
    "out:Energy use kWh/m2": 126,
    "out:Energy savings %": 45.2,
    "out:Op energy cost/MSEK": 66,
    "out:El price/MSEK": 41,
    "out:Heating price/MSEK": 21,
    "out:DH+ DHW/kWh/m2": 109,
    "out:El bought/kWh/m2": 1201.83,
    "out:Return %": 10,
    "out:Return/kSEK/y": 557,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 33.36,
    "out:EAHP (El)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 978564.8401067974,
    "out:FTX (Diffusers)": 206476.5625,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 192451.19749,
    "out:EL kWh savings": -14643.211594,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 163164.77,
    "out:DH kr savings": 192451.19749003457,
    "out:El kr savings": -29286.423188315977,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 241245.53075,
    "out:% savings (space heating)": 40.746613,
    "out:Total_Water Heating (DH)": 106218.208041,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 997124.4110739925,
    "out:Eupp": 75.77,
    "out:Etvv": 20,
    "out:Ef": 17.17,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 5385214.987868186,
    "out:Seasonal Variation ROI (%)": 1,
    "out:Seasonal Variation Payback": 100,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 39.238256,
    "out:EL CO2": 2.266445,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 2.266445,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01000000100000110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 71.451907,
    "out:DHW (DH)": 33.36,
    "out:Property Electricity": 17.169,
    "out:Electricity (inc PV)": 17.169,
    "out:Total Energy Use Pre PV": 122.169,
    "out:Total Energy Use Post PV": 122.169,
    "out:Primary Energy": 100.477905,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 14.182056,
    "out:CO2 Operational/m2": 40.064765,
    "out:Total CO2/m2": 54.246821,
    "out:Total CO2 (tons)": 172.721826,
    "out:Klimatpaverkan": -27.413595,
    "out:Initial Cost/MSEK": 5.464814962868187,
    "out:Running cost/(Apt SEK y)": 23808.042553,
    "out:Running Cost over RSP/MSEK": 8.7424,
    "out:LCP/MSEK": -0.802495,
    "out:ROI% old": 12.187891,
    "out:Payback discounted": 10,
    "out:Atemp": 3184,
    "out:Umean": 0.65,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 122,
    "out:Energy use kWh/m2": 122,
    "out:Energy savings %": 50,
    "out:Op energy cost/MSEK": 64,
    "out:El price/MSEK": 40,
    "out:Heating price/MSEK": 20,
    "out:DH+ DHW/kWh/m2": 105,
    "out:El bought/kWh/m2": 1201.83,
    "out:Return %": 11,
    "out:Return/kSEK/y": 596,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 33.36,
    "out:EAHP (El)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 978564.8401067974,
    "out:FTX (Diffusers)": 206476.5625,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 206193.924007,
    "out:EL kWh savings": -14643.211594,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 79599.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 176907.5,
    "out:DH kr savings": 206193.92400713006,
    "out:El kr savings": -29286.423188315977,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 227502.804233,
    "out:% savings (space heating)": 44.122025,
    "out:Total_Water Heating (DH)": 106218.208041,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 997124.4110739925,
    "out:Eupp": 71.45,
    "out:Etvv": 20,
    "out:Ef": 17.17,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 5464814.962868187,
    "out:Seasonal Variation ROI (%)": 2,
    "out:Seasonal Variation Payback": 50,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 37.79832,
    "out:EL CO2": 2.266445,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 2.266445,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01000000100001010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 75.768092,
    "out:DHW (DH)": 28.356,
    "out:Property Electricity": 17.169,
    "out:Electricity (inc PV)": 17.169,
    "out:Total Energy Use Pre PV": 121.169,
    "out:Total Energy Use Post PV": 121.169,
    "out:Primary Energy": 101.734938,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 14.890599,
    "out:CO2 Operational/m2": 39.704781,
    "out:Total CO2/m2": 54.59538,
    "out:Total CO2 (tons)": 173.831638,
    "out:Klimatpaverkan": -26.303783,
    "out:Initial Cost/MSEK": 5.658430987868186,
    "out:Running cost/(Apt SEK y)": 23603.468085,
    "out:Running Cost over RSP/MSEK": 8.66719,
    "out:LCP/MSEK": -0.920901,
    "out:ROI% old": 11.960736,
    "out:Payback discounted": 10,
    "out:Atemp": 3184,
    "out:Umean": 0.65,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 121,
    "out:Energy use kWh/m2": 121,
    "out:Energy savings %": 51.2,
    "out:Op energy cost/MSEK": 63,
    "out:El price/MSEK": 39,
    "out:Heating price/MSEK": 20,
    "out:DH+ DHW/kWh/m2": 104,
    "out:El bought/kWh/m2": 1201.83,
    "out:Return %": 11,
    "out:Return/kSEK/y": 605,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 28.356,
    "out:EAHP (El)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 978564.8401067974,
    "out:FTX (Diffusers)": 206476.5625,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 208383.928696,
    "out:EL kWh savings": -14643.211594,
    "out:Sewage heat recovery cost": 273216,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 179097.51,
    "out:DH kr savings": 208383.92869620252,
    "out:El kr savings": -29286.423188315977,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 241245.53075,
    "out:% savings (space heating)": 40.746613,
    "out:Total_Water Heating (DH)": 90285.476835,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 997124.4110739925,
    "out:Eupp": 75.77,
    "out:Etvv": 17,
    "out:Ef": 17.17,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 5658430.987868186,
    "out:Seasonal Variation ROI (%)": 2,
    "out:Seasonal Variation Payback": 50,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 37.438336,
    "out:EL CO2": 2.266445,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 2.266445,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01000000100001110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 71.451907,
    "out:DHW (DH)": 28.356,
    "out:Property Electricity": 17.169,
    "out:Electricity (inc PV)": 17.169,
    "out:Total Energy Use Pre PV": 117.169,
    "out:Total Energy Use Post PV": 117.169,
    "out:Primary Energy": 98.377905,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 14.890599,
    "out:CO2 Operational/m2": 38.264845,
    "out:Total CO2/m2": 53.155444,
    "out:Total CO2 (tons)": 169.246883,
    "out:Klimatpaverkan": -30.888538,
    "out:Initial Cost/MSEK": 5.738030962868185,
    "out:Running cost/(Apt SEK y)": 22785.106383,
    "out:Running Cost over RSP/MSEK": 8.36635,
    "out:LCP/MSEK": -0.699661,
    "out:ROI% old": 12.5438,
    "out:Payback discounted": 10,
    "out:Atemp": 3184,
    "out:Umean": 0.65,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 117,
    "out:Energy use kWh/m2": 117,
    "out:Energy savings %": 56.4,
    "out:Op energy cost/MSEK": 61,
    "out:El price/MSEK": 38,
    "out:Heating price/MSEK": 19,
    "out:DH+ DHW/kWh/m2": 100,
    "out:El bought/kWh/m2": 1201.83,
    "out:Return %": 11,
    "out:Return/kSEK/y": 644,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 28.356,
    "out:EAHP (El)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 978564.8401067974,
    "out:FTX (Diffusers)": 206476.5625,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 222126.655213,
    "out:EL kWh savings": -14643.211594,
    "out:Sewage heat recovery cost": 273216,
    "out:Balancing hydronic system cost": 79599.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 192840.23,
    "out:DH kr savings": 222126.65521329807,
    "out:El kr savings": -29286.423188315977,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 227502.804233,
    "out:% savings (space heating)": 44.122025,
    "out:Total_Water Heating (DH)": 90285.476835,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 997124.4110739925,
    "out:Eupp": 71.45,
    "out:Etvv": 17,
    "out:Ef": 17.17,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 5738030.962868186,
    "out:Seasonal Variation ROI (%)": 2,
    "out:Seasonal Variation Payback": 50,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 35.9984,
    "out:EL CO2": 2.266445,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 2.266445,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01000000110000010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 75.768092,
    "out:DHW (DH)": 33.36,
    "out:Property Electricity": 17.169,
    "out:Electricity (inc PV)": 11.433081,
    "out:Total Energy Use Pre PV": 126.169,
    "out:Total Energy Use Post PV": 120.433081,
    "out:Primary Energy": 93.510284,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 28.921156,
    "out:CO2 Operational/m2": 2.346783,
    "out:Total CO2/m2": 31.267939,
    "out:Total CO2 (tons)": 99.557088,
    "out:Klimatpaverkan": -100.578333,
    "out:Initial Cost/MSEK": 6.1754353820906855,
    "out:Running cost/(Apt SEK y)": 23076.957447,
    "out:Running Cost over RSP/MSEK": 8.48902,
    "out:LCP/MSEK": -1.259735,
    "out:ROI% old": 11.371552,
    "out:Payback discounted": 11,
    "out:Atemp": 3184,
    "out:Umean": 0.65,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 120,
    "out:Energy use kWh/m2": 120,
    "out:Energy savings %": 52.5,
    "out:Op energy cost/MSEK": 62,
    "out:El price/MSEK": 38,
    "out:Heating price/MSEK": 21,
    "out:DH+ DHW/kWh/m2": 109,
    "out:El bought/kWh/m2": 807.631491,
    "out:Return %": 10,
    "out:Return/kSEK/y": 630,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 33.36,
    "out:EAHP (El)": 0,
    "out:PV (kr/kWp)": 17461.56255567083,
    "out:PV (kWp installed)": 45.25485,
    "out:PV (m2 panels)": 237.433632,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 42461.284501,
    "out:PV (kWh sold)": 24198.124428,
    "out:PV (% sold (El))": 56.988677,
    "out:PV (kWh self-consumed)": 18263.160073,
    "out:PV (ratio sold/self-consumed)": 1.324969,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 978564.8401067974,
    "out:FTX (Diffusers)": 206476.5625,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 192451.19749,
    "out:EL kWh savings": 3619.949007,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 235988.28,
    "out:DH kr savings": 192451.19749003457,
    "out:El kr savings": 7239.898013663189,
    "out:El kr sold": 36297.18664221638,
    "out:El kr saved": 36526.320146,
    "out:El kr return": 72823.50678821639,
    "out:% solar/total": 46,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 241245.53075,
    "out:% savings (space heating)": 40.746613,
    "out:Total_Water Heating (DH)": 106218.208041,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 997124.4110739925,
    "out:Eupp": 75.77,
    "out:Etvv": 20,
    "out:Ef": 11.43,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 6175435.382090686,
    "out:Seasonal Variation ROI (%)": 2,
    "out:Seasonal Variation Payback": 50,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 39.238256,
    "out:EL CO2": -36.891473,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -38.403999,
    "out:Bought CO2": 1.512526,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01000000110000110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 71.451907,
    "out:DHW (DH)": 33.36,
    "out:Property Electricity": 17.169,
    "out:Electricity (inc PV)": 11.433081,
    "out:Total Energy Use Pre PV": 122.169,
    "out:Total Energy Use Post PV": 116.433081,
    "out:Primary Energy": 90.153251,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 28.921156,
    "out:CO2 Operational/m2": 0.906847,
    "out:Total CO2/m2": 29.828003,
    "out:Total CO2 (tons)": 94.972333,
    "out:Klimatpaverkan": -105.163088,
    "out:Initial Cost/MSEK": 6.255035357090686,
    "out:Running cost/(Apt SEK y)": 22258.617021,
    "out:Running Cost over RSP/MSEK": 8.18817,
    "out:LCP/MSEK": -1.038485,
    "out:ROI% old": 11.913944,
    "out:Payback discounted": 10,
    "out:Atemp": 3184,
    "out:Umean": 0.65,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 116,
    "out:Energy use kWh/m2": 116,
    "out:Energy savings %": 57.8,
    "out:Op energy cost/MSEK": 60,
    "out:El price/MSEK": 36,
    "out:Heating price/MSEK": 20,
    "out:DH+ DHW/kWh/m2": 105,
    "out:El bought/kWh/m2": 807.631491,
    "out:Return %": 11,
    "out:Return/kSEK/y": 669,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 33.36,
    "out:EAHP (El)": 0,
    "out:PV (kr/kWp)": 17461.56255567083,
    "out:PV (kWp installed)": 45.25485,
    "out:PV (m2 panels)": 237.433632,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 42461.284501,
    "out:PV (kWh sold)": 24198.124428,
    "out:PV (% sold (El))": 56.988677,
    "out:PV (kWh self-consumed)": 18263.160073,
    "out:PV (ratio sold/self-consumed)": 1.324969,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 978564.8401067974,
    "out:FTX (Diffusers)": 206476.5625,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 206193.924007,
    "out:EL kWh savings": 3619.949007,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 79599.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 249731.01,
    "out:DH kr savings": 206193.92400713006,
    "out:El kr savings": 7239.898013663189,
    "out:El kr sold": 36297.18664221638,
    "out:El kr saved": 36526.320146,
    "out:El kr return": 72823.50678821639,
    "out:% solar/total": 46,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 227502.804233,
    "out:% savings (space heating)": 44.122025,
    "out:Total_Water Heating (DH)": 106218.208041,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 997124.4110739925,
    "out:Eupp": 71.45,
    "out:Etvv": 20,
    "out:Ef": 11.43,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 6255035.357090686,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 37.79832,
    "out:EL CO2": -36.891473,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -38.403999,
    "out:Bought CO2": 1.512526,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01000000110001010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 75.768092,
    "out:DHW (DH)": 28.356,
    "out:Property Electricity": 17.169,
    "out:Electricity (inc PV)": 11.433081,
    "out:Total Energy Use Pre PV": 121.169,
    "out:Total Energy Use Post PV": 115.433081,
    "out:Primary Energy": 91.410284,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 29.629699,
    "out:CO2 Operational/m2": 0.546863,
    "out:Total CO2/m2": 30.176562,
    "out:Total CO2 (tons)": 96.082144,
    "out:Klimatpaverkan": -104.053277,
    "out:Initial Cost/MSEK": 6.448651382090687,
    "out:Running cost/(Apt SEK y)": 22054.021277,
    "out:Running Cost over RSP/MSEK": 8.11296,
    "out:LCP/MSEK": -1.156891,
    "out:ROI% old": 11.72285,
    "out:Payback discounted": 10,
    "out:Atemp": 3184,
    "out:Umean": 0.65,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 115,
    "out:Energy use kWh/m2": 115,
    "out:Energy savings %": 59.1,
    "out:Op energy cost/MSEK": 60,
    "out:El price/MSEK": 36,
    "out:Heating price/MSEK": 20,
    "out:DH+ DHW/kWh/m2": 104,
    "out:El bought/kWh/m2": 807.631491,
    "out:Return %": 11,
    "out:Return/kSEK/y": 678,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 28.356,
    "out:EAHP (El)": 0,
    "out:PV (kr/kWp)": 17461.56255567083,
    "out:PV (kWp installed)": 45.25485,
    "out:PV (m2 panels)": 237.433632,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 42461.284501,
    "out:PV (kWh sold)": 24198.124428,
    "out:PV (% sold (El))": 56.988677,
    "out:PV (kWh self-consumed)": 18263.160073,
    "out:PV (ratio sold/self-consumed)": 1.324969,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 978564.8401067974,
    "out:FTX (Diffusers)": 206476.5625,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 208383.928696,
    "out:EL kWh savings": 3619.949007,
    "out:Sewage heat recovery cost": 273216,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 251921.01,
    "out:DH kr savings": 208383.92869620252,
    "out:El kr savings": 7239.898013663189,
    "out:El kr sold": 36297.18664221638,
    "out:El kr saved": 36526.320146,
    "out:El kr return": 72823.50678821639,
    "out:% solar/total": 46,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 241245.53075,
    "out:% savings (space heating)": 40.746613,
    "out:Total_Water Heating (DH)": 90285.476835,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 997124.4110739925,
    "out:Eupp": 75.77,
    "out:Etvv": 17,
    "out:Ef": 11.43,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 6448651.382090687,
    "out:Seasonal Variation ROI (%)": 2,
    "out:Seasonal Variation Payback": 50,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 37.438336,
    "out:EL CO2": -36.891473,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -38.403999,
    "out:Bought CO2": 1.512526,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01000000110001110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 71.451907,
    "out:DHW (DH)": 28.356,
    "out:Property Electricity": 17.169,
    "out:Electricity (inc PV)": 11.433081,
    "out:Total Energy Use Pre PV": 117.169,
    "out:Total Energy Use Post PV": 111.433081,
    "out:Primary Energy": 88.053251,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 29.629699,
    "out:CO2 Operational/m2": -0.893073,
    "out:Total CO2/m2": 28.736626,
    "out:Total CO2 (tons)": 91.49739,
    "out:Klimatpaverkan": -108.638031,
    "out:Initial Cost/MSEK": 6.528251357090687,
    "out:Running cost/(Apt SEK y)": 21235.659574,
    "out:Running Cost over RSP/MSEK": 7.81212,
    "out:LCP/MSEK": -0.935651,
    "out:ROI% old": 12.238237,
    "out:Payback discounted": 10,
    "out:Atemp": 3184,
    "out:Umean": 0.65,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 111,
    "out:Energy use kWh/m2": 111,
    "out:Energy savings %": 64.9,
    "out:Op energy cost/MSEK": 57,
    "out:El price/MSEK": 35,
    "out:Heating price/MSEK": 19,
    "out:DH+ DHW/kWh/m2": 100,
    "out:El bought/kWh/m2": 807.631491,
    "out:Return %": 11,
    "out:Return/kSEK/y": 717,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 28.356,
    "out:EAHP (El)": 0,
    "out:PV (kr/kWp)": 17461.56255567083,
    "out:PV (kWp installed)": 45.25485,
    "out:PV (m2 panels)": 237.433632,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 42461.284501,
    "out:PV (kWh sold)": 24198.124428,
    "out:PV (% sold (El))": 56.988677,
    "out:PV (kWh self-consumed)": 18263.160073,
    "out:PV (ratio sold/self-consumed)": 1.324969,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 978564.8401067974,
    "out:FTX (Diffusers)": 206476.5625,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 222126.655213,
    "out:EL kWh savings": 3619.949007,
    "out:Sewage heat recovery cost": 273216,
    "out:Balancing hydronic system cost": 79599.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 265663.74,
    "out:DH kr savings": 222126.65521329807,
    "out:El kr savings": 7239.898013663189,
    "out:El kr sold": 36297.18664221638,
    "out:El kr saved": 36526.320146,
    "out:El kr return": 72823.50678821639,
    "out:% solar/total": 46,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 227502.804233,
    "out:% savings (space heating)": 44.122025,
    "out:Total_Water Heating (DH)": 90285.476835,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 997124.4110739925,
    "out:Eupp": 71.45,
    "out:Etvv": 17,
    "out:Ef": 11.43,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 6528251.357090686,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 35.9984,
    "out:EL CO2": -36.891473,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -38.403999,
    "out:Bought CO2": 1.512526,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01000000120000010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 75.768092,
    "out:DHW (DH)": 33.36,
    "out:Property Electricity": 17.169,
    "out:Electricity (inc PV)": 10.564609,
    "out:Total Energy Use Pre PV": 126.169,
    "out:Total Energy Use Post PV": 119.564609,
    "out:Primary Energy": 91.947034,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 43.660255,
    "out:CO2 Operational/m2": -61.236866,
    "out:Total CO2/m2": -17.576611,
    "out:Total CO2 (tons)": -55.963913,
    "out:Klimatpaverkan": -256.099334,
    "out:Initial Cost/MSEK": 6.965648733811937,
    "out:Running cost/(Apt SEK y)": 21692.404255,
    "out:Running Cost over RSP/MSEK": 7.99427,
    "out:LCP/MSEK": -1.555199,
    "out:ROI% old": 11.096187,
    "out:Payback discounted": 11,
    "out:Atemp": 3184,
    "out:Umean": 0.65,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 120,
    "out:Energy use kWh/m2": 120,
    "out:Energy savings %": 52.5,
    "out:Op energy cost/MSEK": 59,
    "out:El price/MSEK": 35,
    "out:Heating price/MSEK": 21,
    "out:DH+ DHW/kWh/m2": 109,
    "out:El bought/kWh/m2": 744.654907,
    "out:Return %": 10,
    "out:Return/kSEK/y": 695,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 33.36,
    "out:EAHP (El)": 0,
    "out:PV (kr/kWp)": 17461.48474631725,
    "out:PV (kWp installed)": 90.5097,
    "out:PV (m2 panels)": 474.867263,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 84922.568824,
    "out:PV (kWh sold)": 63894.192746,
    "out:PV (% sold (El))": 75.238177,
    "out:PV (kWh self-consumed)": 21028.376078,
    "out:PV (ratio sold/self-consumed)": 3.038475,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 978564.8401067974,
    "out:FTX (Diffusers)": 206476.5625,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 192451.19749,
    "out:EL kWh savings": 6385.163023,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 301062.81,
    "out:DH kr savings": 192451.19749003457,
    "out:El kr savings": 12770.326045670818,
    "out:El kr sold": 95841.28911946694,
    "out:El kr saved": 42056.752156,
    "out:El kr return": 137898.04127546694,
    "out:% solar/total": 53,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 241245.53075,
    "out:% savings (space heating)": 40.746613,
    "out:Total_Water Heating (DH)": 106218.208041,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 997124.4110739925,
    "out:Eupp": 75.77,
    "out:Etvv": 20,
    "out:Ef": 10.56,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 6965648.733811937,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 39.238256,
    "out:EL CO2": -100.475122,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -101.871982,
    "out:Bought CO2": 1.39686,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01000000120000110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 71.451907,
    "out:DHW (DH)": 33.36,
    "out:Property Electricity": 17.169,
    "out:Electricity (inc PV)": 10.564609,
    "out:Total Energy Use Pre PV": 122.169,
    "out:Total Energy Use Post PV": 115.564609,
    "out:Primary Energy": 88.590001,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 43.660255,
    "out:CO2 Operational/m2": -62.676802,
    "out:Total CO2/m2": -19.016547,
    "out:Total CO2 (tons)": -60.548667,
    "out:Klimatpaverkan": -260.684088,
    "out:Initial Cost/MSEK": 7.045248708811937,
    "out:Running cost/(Apt SEK y)": 20874.042553,
    "out:Running Cost over RSP/MSEK": 7.69342,
    "out:LCP/MSEK": -1.333949,
    "out:ROI% old": 11.580854,
    "out:Payback discounted": 10,
    "out:Atemp": 3184,
    "out:Umean": 0.65,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 116,
    "out:Energy use kWh/m2": 116,
    "out:Energy savings %": 57.8,
    "out:Op energy cost/MSEK": 57,
    "out:El price/MSEK": 33,
    "out:Heating price/MSEK": 20,
    "out:DH+ DHW/kWh/m2": 105,
    "out:El bought/kWh/m2": 744.654907,
    "out:Return %": 10,
    "out:Return/kSEK/y": 734,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 33.36,
    "out:EAHP (El)": 0,
    "out:PV (kr/kWp)": 17461.48474631725,
    "out:PV (kWp installed)": 90.5097,
    "out:PV (m2 panels)": 474.867263,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 84922.568824,
    "out:PV (kWh sold)": 63894.192746,
    "out:PV (% sold (El))": 75.238177,
    "out:PV (kWh self-consumed)": 21028.376078,
    "out:PV (ratio sold/self-consumed)": 3.038475,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 978564.8401067974,
    "out:FTX (Diffusers)": 206476.5625,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 206193.924007,
    "out:EL kWh savings": 6385.163023,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 79599.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 314805.54,
    "out:DH kr savings": 206193.92400713006,
    "out:El kr savings": 12770.326045670818,
    "out:El kr sold": 95841.28911946694,
    "out:El kr saved": 42056.752156,
    "out:El kr return": 137898.04127546694,
    "out:% solar/total": 53,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 227502.804233,
    "out:% savings (space heating)": 44.122025,
    "out:Total_Water Heating (DH)": 106218.208041,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 997124.4110739925,
    "out:Eupp": 71.45,
    "out:Etvv": 20,
    "out:Ef": 10.56,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 7045248.708811937,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 37.79832,
    "out:EL CO2": -100.475122,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -101.871982,
    "out:Bought CO2": 1.39686,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01000000120001010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 75.768092,
    "out:DHW (DH)": 28.356,
    "out:Property Electricity": 17.169,
    "out:Electricity (inc PV)": 10.564609,
    "out:Total Energy Use Pre PV": 121.169,
    "out:Total Energy Use Post PV": 114.564609,
    "out:Primary Energy": 89.847034,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 44.368798,
    "out:CO2 Operational/m2": -63.036786,
    "out:Total CO2/m2": -18.667988,
    "out:Total CO2 (tons)": -59.438856,
    "out:Klimatpaverkan": -259.574277,
    "out:Initial Cost/MSEK": 7.238864733811935,
    "out:Running cost/(Apt SEK y)": 20669.468085,
    "out:Running Cost over RSP/MSEK": 7.61821,
    "out:LCP/MSEK": -1.452355,
    "out:ROI% old": 11.419529,
    "out:Payback discounted": 11,
    "out:Atemp": 3184,
    "out:Umean": 0.65,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 115,
    "out:Energy use kWh/m2": 115,
    "out:Energy savings %": 59.1,
    "out:Op energy cost/MSEK": 57,
    "out:El price/MSEK": 33,
    "out:Heating price/MSEK": 20,
    "out:DH+ DHW/kWh/m2": 104,
    "out:El bought/kWh/m2": 744.654907,
    "out:Return %": 10,
    "out:Return/kSEK/y": 743,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 28.356,
    "out:EAHP (El)": 0,
    "out:PV (kr/kWp)": 17461.48474631725,
    "out:PV (kWp installed)": 90.5097,
    "out:PV (m2 panels)": 474.867263,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 84922.568824,
    "out:PV (kWh sold)": 63894.192746,
    "out:PV (% sold (El))": 75.238177,
    "out:PV (kWh self-consumed)": 21028.376078,
    "out:PV (ratio sold/self-consumed)": 3.038475,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 978564.8401067974,
    "out:FTX (Diffusers)": 206476.5625,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 208383.928696,
    "out:EL kWh savings": 6385.163023,
    "out:Sewage heat recovery cost": 273216,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 316995.54,
    "out:DH kr savings": 208383.92869620252,
    "out:El kr savings": 12770.326045670818,
    "out:El kr sold": 95841.28911946694,
    "out:El kr saved": 42056.752156,
    "out:El kr return": 137898.04127546694,
    "out:% solar/total": 53,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 241245.53075,
    "out:% savings (space heating)": 40.746613,
    "out:Total_Water Heating (DH)": 90285.476835,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 997124.4110739925,
    "out:Eupp": 75.77,
    "out:Etvv": 17,
    "out:Ef": 10.56,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 7238864.733811935,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 37.438336,
    "out:EL CO2": -100.475122,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -101.871982,
    "out:Bought CO2": 1.39686,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01000000120001110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 71.451907,
    "out:DHW (DH)": 28.356,
    "out:Property Electricity": 17.169,
    "out:Electricity (inc PV)": 10.564609,
    "out:Total Energy Use Pre PV": 117.169,
    "out:Total Energy Use Post PV": 110.564609,
    "out:Primary Energy": 86.490001,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 44.368798,
    "out:CO2 Operational/m2": -64.476722,
    "out:Total CO2/m2": -20.107924,
    "out:Total CO2 (tons)": -64.023611,
    "out:Klimatpaverkan": -264.159032,
    "out:Initial Cost/MSEK": 7.318464708811937,
    "out:Running cost/(Apt SEK y)": 19851.106383,
    "out:Running Cost over RSP/MSEK": 7.31737,
    "out:LCP/MSEK": -1.231115,
    "out:ROI% old": 11.882566,
    "out:Payback discounted": 10,
    "out:Atemp": 3184,
    "out:Umean": 0.65,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 111,
    "out:Energy use kWh/m2": 111,
    "out:Energy savings %": 64.9,
    "out:Op energy cost/MSEK": 54,
    "out:El price/MSEK": 32,
    "out:Heating price/MSEK": 19,
    "out:DH+ DHW/kWh/m2": 100,
    "out:El bought/kWh/m2": 744.654907,
    "out:Return %": 11,
    "out:Return/kSEK/y": 782,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 28.356,
    "out:EAHP (El)": 0,
    "out:PV (kr/kWp)": 17461.48474631725,
    "out:PV (kWp installed)": 90.5097,
    "out:PV (m2 panels)": 474.867263,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 84922.568824,
    "out:PV (kWh sold)": 63894.192746,
    "out:PV (% sold (El))": 75.238177,
    "out:PV (kWh self-consumed)": 21028.376078,
    "out:PV (ratio sold/self-consumed)": 3.038475,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 978564.8401067974,
    "out:FTX (Diffusers)": 206476.5625,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 222126.655213,
    "out:EL kWh savings": 6385.163023,
    "out:Sewage heat recovery cost": 273216,
    "out:Balancing hydronic system cost": 79599.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 330738.27,
    "out:DH kr savings": 222126.65521329807,
    "out:El kr savings": 12770.326045670818,
    "out:El kr sold": 95841.28911946694,
    "out:El kr saved": 42056.752156,
    "out:El kr return": 137898.04127546694,
    "out:% solar/total": 53,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 227502.804233,
    "out:% savings (space heating)": 44.122025,
    "out:Total_Water Heating (DH)": 90285.476835,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 997124.4110739925,
    "out:Eupp": 71.45,
    "out:Etvv": 17,
    "out:Ef": 10.56,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 7318464.708811937,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 35.9984,
    "out:EL CO2": -100.475122,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -101.871982,
    "out:Bought CO2": 1.39686,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01001000000000010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 63.739365,
    "out:DHW (DH)": 41.7,
    "out:Property Electricity": 17.169,
    "out:Electricity (inc PV)": 17.169,
    "out:Total Energy Use Pre PV": 122.169,
    "out:Total Energy Use Post PV": 122.169,
    "out:Primary Energy": 97.979262,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 15.600566,
    "out:CO2 Operational/m2": 40.064765,
    "out:Total CO2/m2": 55.665331,
    "out:Total CO2 (tons)": 177.238361,
    "out:Klimatpaverkan": -22.89706,
    "out:Initial Cost/MSEK": 5.518379153371937,
    "out:Running cost/(Apt SEK y)": 23808.042553,
    "out:Running Cost over RSP/MSEK": 8.7424,
    "out:LCP/MSEK": -0.856059,
    "out:ROI% old": 12.06959,
    "out:Payback discounted": 10,
    "out:Atemp": 3184,
    "out:Umean": 0.47,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 122,
    "out:Energy use kWh/m2": 122,
    "out:Energy savings %": 50,
    "out:Op energy cost/MSEK": 64,
    "out:El price/MSEK": 40,
    "out:Heating price/MSEK": 20,
    "out:DH+ DHW/kWh/m2": 105,
    "out:El bought/kWh/m2": 1201.83,
    "out:Return %": 11,
    "out:Return/kSEK/y": 596,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 41.7,
    "out:EAHP (El)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 978564.8401067974,
    "out:FTX (Diffusers)": 206476.5625,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 204196.099451,
    "out:EL kWh savings": -14643.211594,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 174909.68,
    "out:DH kr savings": 204196.09945063447,
    "out:El kr savings": -29286.423188315977,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 202946.076779,
    "out:% savings (space heating)": 50.153512,
    "out:Total_Water Heating (DH)": 132772.760051,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 997124.4110739925,
    "out:Eupp": 63.74,
    "out:Etvv": 25,
    "out:Ef": 17.17,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 5518379.153371937,
    "out:Seasonal Variation ROI (%)": 2,
    "out:Seasonal Variation Payback": 50,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 37.79832,
    "out:EL CO2": 2.266445,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 2.266445,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01001000000000110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 59.969941,
    "out:DHW (DH)": 41.7,
    "out:Property Electricity": 17.169,
    "out:Electricity (inc PV)": 17.169,
    "out:Total Energy Use Pre PV": 119.169,
    "out:Total Energy Use Post PV": 119.169,
    "out:Primary Energy": 95.047487,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 15.600566,
    "out:CO2 Operational/m2": 38.984813,
    "out:Total CO2/m2": 54.585379,
    "out:Total CO2 (tons)": 173.799794,
    "out:Klimatpaverkan": -26.335627,
    "out:Initial Cost/MSEK": 5.597979128371935,
    "out:Running cost/(Apt SEK y)": 23194.276596,
    "out:Running Cost over RSP/MSEK": 8.51677,
    "out:LCP/MSEK": -0.710029,
    "out:ROI% old": 12.473762,
    "out:Payback discounted": 10,
    "out:Atemp": 3184,
    "out:Umean": 0.47,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 119,
    "out:Energy use kWh/m2": 119,
    "out:Energy savings %": 53.8,
    "out:Op energy cost/MSEK": 62,
    "out:El price/MSEK": 39,
    "out:Heating price/MSEK": 20,
    "out:DH+ DHW/kWh/m2": 102,
    "out:El bought/kWh/m2": 1201.83,
    "out:Return %": 11,
    "out:Return/kSEK/y": 625,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 41.7,
    "out:EAHP (El)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 978564.8401067974,
    "out:FTX (Diffusers)": 206476.5625,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 216197.941696,
    "out:EL kWh savings": -14643.211594,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 79599.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 186911.52,
    "out:DH kr savings": 216197.94169632634,
    "out:El kr savings": -29286.423188315977,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 190944.234534,
    "out:% savings (space heating)": 53.101338,
    "out:Total_Water Heating (DH)": 132772.760051,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 997124.4110739925,
    "out:Eupp": 59.97,
    "out:Etvv": 25,
    "out:Ef": 17.17,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 5597979.128371935,
    "out:Seasonal Variation ROI (%)": 2,
    "out:Seasonal Variation Payback": 50,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 36.718368,
    "out:EL CO2": 2.266445,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 2.266445,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01001000000001010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 63.739365,
    "out:DHW (DH)": 35.445,
    "out:Property Electricity": 17.169,
    "out:Electricity (inc PV)": 17.169,
    "out:Total Energy Use Pre PV": 116.169,
    "out:Total Energy Use Post PV": 116.169,
    "out:Primary Energy": 95.354262,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 16.309109,
    "out:CO2 Operational/m2": 37.904861,
    "out:Total CO2/m2": 54.21397,
    "out:Total CO2 (tons)": 172.617229,
    "out:Klimatpaverkan": -27.518193,
    "out:Initial Cost/MSEK": 5.791595153371936,
    "out:Running cost/(Apt SEK y)": 22580.510638,
    "out:Running Cost over RSP/MSEK": 8.29113,
    "out:LCP/MSEK": -0.678005,
    "out:ROI% old": 12.613327,
    "out:Payback discounted": 10,
    "out:Atemp": 3184,
    "out:Umean": 0.47,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 116,
    "out:Energy use kWh/m2": 116,
    "out:Energy savings %": 57.8,
    "out:Op energy cost/MSEK": 60,
    "out:El price/MSEK": 38,
    "out:Heating price/MSEK": 19,
    "out:DH+ DHW/kWh/m2": 99,
    "out:El bought/kWh/m2": 1201.83,
    "out:Return %": 11,
    "out:Return/kSEK/y": 653,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 35.445,
    "out:EAHP (El)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 978564.8401067974,
    "out:FTX (Diffusers)": 206476.5625,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 224112.013458,
    "out:EL kWh savings": -14643.211594,
    "out:Sewage heat recovery cost": 273216,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 194825.59,
    "out:DH kr savings": 224112.01345834445,
    "out:El kr savings": -29286.423188315977,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 202946.076779,
    "out:% savings (space heating)": 50.153512,
    "out:Total_Water Heating (DH)": 112856.846044,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 997124.4110739925,
    "out:Eupp": 63.74,
    "out:Etvv": 21.25,
    "out:Ef": 17.17,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 5791595.153371936,
    "out:Seasonal Variation ROI (%)": 2,
    "out:Seasonal Variation Payback": 50,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 35.638416,
    "out:EL CO2": 2.266445,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 2.266445,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01001000000001110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 59.969941,
    "out:DHW (DH)": 35.445,
    "out:Property Electricity": 17.169,
    "out:Electricity (inc PV)": 17.169,
    "out:Total Energy Use Pre PV": 112.169,
    "out:Total Energy Use Post PV": 112.169,
    "out:Primary Energy": 92.422487,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 16.309109,
    "out:CO2 Operational/m2": 36.464925,
    "out:Total CO2/m2": 52.774034,
    "out:Total CO2 (tons)": 168.032474,
    "out:Klimatpaverkan": -32.102947,
    "out:Initial Cost/MSEK": 5.8711951283719355,
    "out:Running cost/(Apt SEK y)": 21762.148936,
    "out:Running Cost over RSP/MSEK": 7.99029,
    "out:LCP/MSEK": -0.456765,
    "out:ROI% old": 13.174319,
    "out:Payback discounted": 9,
    "out:Atemp": 3184,
    "out:Umean": 0.47,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 112,
    "out:Energy use kWh/m2": 112,
    "out:Energy savings %": 63.4,
    "out:Op energy cost/MSEK": 58,
    "out:El price/MSEK": 37,
    "out:Heating price/MSEK": 19,
    "out:DH+ DHW/kWh/m2": 95,
    "out:El bought/kWh/m2": 1201.83,
    "out:Return %": 12,
    "out:Return/kSEK/y": 692,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 35.445,
    "out:EAHP (El)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 978564.8401067974,
    "out:FTX (Diffusers)": 206476.5625,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 236113.855704,
    "out:EL kWh savings": -14643.211594,
    "out:Sewage heat recovery cost": 273216,
    "out:Balancing hydronic system cost": 79599.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 206827.43,
    "out:DH kr savings": 236113.8557040363,
    "out:El kr savings": -29286.423188315977,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 190944.234534,
    "out:% savings (space heating)": 53.101338,
    "out:Total_Water Heating (DH)": 112856.846044,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 997124.4110739925,
    "out:Eupp": 59.97,
    "out:Etvv": 21.25,
    "out:Ef": 17.17,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 5871195.128371935,
    "out:Seasonal Variation ROI (%)": 2,
    "out:Seasonal Variation Payback": 50,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 34.19848,
    "out:EL CO2": 2.266445,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 2.266445,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01001000010000010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 63.739365,
    "out:DHW (DH)": 41.7,
    "out:Property Electricity": 17.169,
    "out:Electricity (inc PV)": 11.433081,
    "out:Total Energy Use Pre PV": 122.169,
    "out:Total Energy Use Post PV": 116.433081,
    "out:Primary Energy": 87.654608,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 30.339666,
    "out:CO2 Operational/m2": 0.906847,
    "out:Total CO2/m2": 31.246513,
    "out:Total CO2 (tons)": 99.488867,
    "out:Klimatpaverkan": -100.646554,
    "out:Initial Cost/MSEK": 6.3085995475944365,
    "out:Running cost/(Apt SEK y)": 22258.617021,
    "out:Running Cost over RSP/MSEK": 8.18817,
    "out:LCP/MSEK": -1.09205,
    "out:ROI% old": 11.812787,
    "out:Payback discounted": 10,
    "out:Atemp": 3184,
    "out:Umean": 0.47,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 116,
    "out:Energy use kWh/m2": 116,
    "out:Energy savings %": 57.8,
    "out:Op energy cost/MSEK": 60,
    "out:El price/MSEK": 36,
    "out:Heating price/MSEK": 20,
    "out:DH+ DHW/kWh/m2": 105,
    "out:El bought/kWh/m2": 807.631491,
    "out:Return %": 11,
    "out:Return/kSEK/y": 669,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 41.7,
    "out:EAHP (El)": 0,
    "out:PV (kr/kWp)": 17461.56255567083,
    "out:PV (kWp installed)": 45.25485,
    "out:PV (m2 panels)": 237.433632,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 42461.284501,
    "out:PV (kWh sold)": 24198.124428,
    "out:PV (% sold (El))": 56.988677,
    "out:PV (kWh self-consumed)": 18263.160073,
    "out:PV (ratio sold/self-consumed)": 1.324969,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 978564.8401067974,
    "out:FTX (Diffusers)": 206476.5625,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 204196.099451,
    "out:EL kWh savings": 3619.949007,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 247733.18,
    "out:DH kr savings": 204196.09945063447,
    "out:El kr savings": 7239.898013663189,
    "out:El kr sold": 36297.18664221638,
    "out:El kr saved": 36526.320146,
    "out:El kr return": 72823.50678821639,
    "out:% solar/total": 46,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 202946.076779,
    "out:% savings (space heating)": 50.153512,
    "out:Total_Water Heating (DH)": 132772.760051,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 997124.4110739925,
    "out:Eupp": 63.74,
    "out:Etvv": 25,
    "out:Ef": 11.43,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 6308599.547594436,
    "out:Seasonal Variation ROI (%)": 2,
    "out:Seasonal Variation Payback": 50,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 37.79832,
    "out:EL CO2": -36.891473,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -38.403999,
    "out:Bought CO2": 1.512526,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01001000010000110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 59.969941,
    "out:DHW (DH)": 41.7,
    "out:Property Electricity": 17.169,
    "out:Electricity (inc PV)": 11.433081,
    "out:Total Energy Use Pre PV": 119.169,
    "out:Total Energy Use Post PV": 113.433081,
    "out:Primary Energy": 84.722833,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 30.339666,
    "out:CO2 Operational/m2": -0.173105,
    "out:Total CO2/m2": 30.166561,
    "out:Total CO2 (tons)": 96.050301,
    "out:Klimatpaverkan": -104.08512,
    "out:Initial Cost/MSEK": 6.388199522594437,
    "out:Running cost/(Apt SEK y)": 21644.851064,
    "out:Running Cost over RSP/MSEK": 7.96254,
    "out:LCP/MSEK": -0.94602,
    "out:ROI% old": 12.170163,
    "out:Payback discounted": 10,
    "out:Atemp": 3184,
    "out:Umean": 0.47,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 113,
    "out:Energy use kWh/m2": 113,
    "out:Energy savings %": 61.9,
    "out:Op energy cost/MSEK": 59,
    "out:El price/MSEK": 35,
    "out:Heating price/MSEK": 20,
    "out:DH+ DHW/kWh/m2": 102,
    "out:El bought/kWh/m2": 807.631491,
    "out:Return %": 11,
    "out:Return/kSEK/y": 697,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 41.7,
    "out:EAHP (El)": 0,
    "out:PV (kr/kWp)": 17461.56255567083,
    "out:PV (kWp installed)": 45.25485,
    "out:PV (m2 panels)": 237.433632,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 42461.284501,
    "out:PV (kWh sold)": 24198.124428,
    "out:PV (% sold (El))": 56.988677,
    "out:PV (kWh self-consumed)": 18263.160073,
    "out:PV (ratio sold/self-consumed)": 1.324969,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 978564.8401067974,
    "out:FTX (Diffusers)": 206476.5625,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 216197.941696,
    "out:EL kWh savings": 3619.949007,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 79599.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 259735.03,
    "out:DH kr savings": 216197.94169632634,
    "out:El kr savings": 7239.898013663189,
    "out:El kr sold": 36297.18664221638,
    "out:El kr saved": 36526.320146,
    "out:El kr return": 72823.50678821639,
    "out:% solar/total": 46,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 190944.234534,
    "out:% savings (space heating)": 53.101338,
    "out:Total_Water Heating (DH)": 132772.760051,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 997124.4110739925,
    "out:Eupp": 59.97,
    "out:Etvv": 25,
    "out:Ef": 11.43,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 6388199.522594437,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 36.718368,
    "out:EL CO2": -36.891473,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -38.403999,
    "out:Bought CO2": 1.512526,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01001000010001010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 63.739365,
    "out:DHW (DH)": 35.445,
    "out:Property Electricity": 17.169,
    "out:Electricity (inc PV)": 11.433081,
    "out:Total Energy Use Pre PV": 116.169,
    "out:Total Energy Use Post PV": 110.433081,
    "out:Primary Energy": 85.029608,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 31.048209,
    "out:CO2 Operational/m2": -1.253057,
    "out:Total CO2/m2": 29.795152,
    "out:Total CO2 (tons)": 94.867735,
    "out:Klimatpaverkan": -105.267686,
    "out:Initial Cost/MSEK": 6.581815547594436,
    "out:Running cost/(Apt SEK y)": 21031.085106,
    "out:Running Cost over RSP/MSEK": 7.73691,
    "out:LCP/MSEK": -0.914006,
    "out:ROI% old": 12.301881,
    "out:Payback discounted": 10,
    "out:Atemp": 3184,
    "out:Umean": 0.47,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 110,
    "out:Energy use kWh/m2": 110,
    "out:Energy savings %": 66.4,
    "out:Op energy cost/MSEK": 57,
    "out:El price/MSEK": 34,
    "out:Heating price/MSEK": 19,
    "out:DH+ DHW/kWh/m2": 99,
    "out:El bought/kWh/m2": 807.631491,
    "out:Return %": 11,
    "out:Return/kSEK/y": 726,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 35.445,
    "out:EAHP (El)": 0,
    "out:PV (kr/kWp)": 17461.56255567083,
    "out:PV (kWp installed)": 45.25485,
    "out:PV (m2 panels)": 237.433632,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 42461.284501,
    "out:PV (kWh sold)": 24198.124428,
    "out:PV (% sold (El))": 56.988677,
    "out:PV (kWh self-consumed)": 18263.160073,
    "out:PV (ratio sold/self-consumed)": 1.324969,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 978564.8401067974,
    "out:FTX (Diffusers)": 206476.5625,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 224112.013458,
    "out:EL kWh savings": 3619.949007,
    "out:Sewage heat recovery cost": 273216,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 267649.1,
    "out:DH kr savings": 224112.01345834445,
    "out:El kr savings": 7239.898013663189,
    "out:El kr sold": 36297.18664221638,
    "out:El kr saved": 36526.320146,
    "out:El kr return": 72823.50678821639,
    "out:% solar/total": 46,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 202946.076779,
    "out:% savings (space heating)": 50.153512,
    "out:Total_Water Heating (DH)": 112856.846044,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 997124.4110739925,
    "out:Eupp": 63.74,
    "out:Etvv": 21.25,
    "out:Ef": 11.43,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 6581815.547594436,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 35.638416,
    "out:EL CO2": -36.891473,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -38.403999,
    "out:Bought CO2": 1.512526,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01001000010001110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 59.969941,
    "out:DHW (DH)": 35.445,
    "out:Property Electricity": 17.169,
    "out:Electricity (inc PV)": 11.433081,
    "out:Total Energy Use Pre PV": 112.169,
    "out:Total Energy Use Post PV": 106.433081,
    "out:Primary Energy": 82.097833,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 31.048209,
    "out:CO2 Operational/m2": -2.692993,
    "out:Total CO2/m2": 28.355216,
    "out:Total CO2 (tons)": 90.282981,
    "out:Klimatpaverkan": -109.85244,
    "out:Initial Cost/MSEK": 6.661415522594435,
    "out:Running cost/(Apt SEK y)": 20212.723404,
    "out:Running Cost over RSP/MSEK": 7.43606,
    "out:LCP/MSEK": -0.692756,
    "out:ROI% old": 12.800068,
    "out:Payback discounted": 9,
    "out:Atemp": 3184,
    "out:Umean": 0.47,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 106,
    "out:Energy use kWh/m2": 106,
    "out:Energy savings %": 72.6,
    "out:Op energy cost/MSEK": 55,
    "out:El price/MSEK": 33,
    "out:Heating price/MSEK": 19,
    "out:DH+ DHW/kWh/m2": 95,
    "out:El bought/kWh/m2": 807.631491,
    "out:Return %": 11,
    "out:Return/kSEK/y": 765,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 35.445,
    "out:EAHP (El)": 0,
    "out:PV (kr/kWp)": 17461.56255567083,
    "out:PV (kWp installed)": 45.25485,
    "out:PV (m2 panels)": 237.433632,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 42461.284501,
    "out:PV (kWh sold)": 24198.124428,
    "out:PV (% sold (El))": 56.988677,
    "out:PV (kWh self-consumed)": 18263.160073,
    "out:PV (ratio sold/self-consumed)": 1.324969,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 978564.8401067974,
    "out:FTX (Diffusers)": 206476.5625,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 236113.855704,
    "out:EL kWh savings": 3619.949007,
    "out:Sewage heat recovery cost": 273216,
    "out:Balancing hydronic system cost": 79599.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 279650.94,
    "out:DH kr savings": 236113.8557040363,
    "out:El kr savings": 7239.898013663189,
    "out:El kr sold": 36297.18664221638,
    "out:El kr saved": 36526.320146,
    "out:El kr return": 72823.50678821639,
    "out:% solar/total": 46,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 190944.234534,
    "out:% savings (space heating)": 53.101338,
    "out:Total_Water Heating (DH)": 112856.846044,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 997124.4110739925,
    "out:Eupp": 59.97,
    "out:Etvv": 21.25,
    "out:Ef": 11.43,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 6661415.522594435,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 34.19848,
    "out:EL CO2": -36.891473,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -38.403999,
    "out:Bought CO2": 1.512526,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01001000020000010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 63.739365,
    "out:DHW (DH)": 41.7,
    "out:Property Electricity": 17.169,
    "out:Electricity (inc PV)": 10.564609,
    "out:Total Energy Use Pre PV": 122.169,
    "out:Total Energy Use Post PV": 115.564609,
    "out:Primary Energy": 86.091358,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 45.078765,
    "out:CO2 Operational/m2": -62.676802,
    "out:Total CO2/m2": -17.598037,
    "out:Total CO2 (tons)": -56.032133,
    "out:Klimatpaverkan": -256.167554,
    "out:Initial Cost/MSEK": 7.0988128993156865,
    "out:Running cost/(Apt SEK y)": 20874.042553,
    "out:Running Cost over RSP/MSEK": 7.69342,
    "out:LCP/MSEK": -1.387513,
    "out:ROI% old": 11.493471,
    "out:Payback discounted": 11,
    "out:Atemp": 3184,
    "out:Umean": 0.47,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 116,
    "out:Energy use kWh/m2": 116,
    "out:Energy savings %": 57.8,
    "out:Op energy cost/MSEK": 57,
    "out:El price/MSEK": 33,
    "out:Heating price/MSEK": 20,
    "out:DH+ DHW/kWh/m2": 105,
    "out:El bought/kWh/m2": 744.654907,
    "out:Return %": 10,
    "out:Return/kSEK/y": 734,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 41.7,
    "out:EAHP (El)": 0,
    "out:PV (kr/kWp)": 17461.48474631725,
    "out:PV (kWp installed)": 90.5097,
    "out:PV (m2 panels)": 474.867263,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 84922.568824,
    "out:PV (kWh sold)": 63894.192746,
    "out:PV (% sold (El))": 75.238177,
    "out:PV (kWh self-consumed)": 21028.376078,
    "out:PV (ratio sold/self-consumed)": 3.038475,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 978564.8401067974,
    "out:FTX (Diffusers)": 206476.5625,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 204196.099451,
    "out:EL kWh savings": 6385.163023,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 312807.71,
    "out:DH kr savings": 204196.09945063447,
    "out:El kr savings": 12770.326045670818,
    "out:El kr sold": 95841.28911946694,
    "out:El kr saved": 42056.752156,
    "out:El kr return": 137898.04127546694,
    "out:% solar/total": 53,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 202946.076779,
    "out:% savings (space heating)": 50.153512,
    "out:Total_Water Heating (DH)": 132772.760051,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 997124.4110739925,
    "out:Eupp": 63.74,
    "out:Etvv": 25,
    "out:Ef": 10.56,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 7098812.899315687,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 37.79832,
    "out:EL CO2": -100.475122,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -101.871982,
    "out:Bought CO2": 1.39686,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01001000020000110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 59.969941,
    "out:DHW (DH)": 41.7,
    "out:Property Electricity": 17.169,
    "out:Electricity (inc PV)": 10.564609,
    "out:Total Energy Use Pre PV": 119.169,
    "out:Total Energy Use Post PV": 112.564609,
    "out:Primary Energy": 83.159583,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 45.078765,
    "out:CO2 Operational/m2": -63.756754,
    "out:Total CO2/m2": -18.677989,
    "out:Total CO2 (tons)": -59.470699,
    "out:Klimatpaverkan": -259.60612,
    "out:Initial Cost/MSEK": 7.178412874315686,
    "out:Running cost/(Apt SEK y)": 20260.276596,
    "out:Running Cost over RSP/MSEK": 7.46779,
    "out:LCP/MSEK": -1.241483,
    "out:ROI% old": 11.815047,
    "out:Payback discounted": 10,
    "out:Atemp": 3184,
    "out:Umean": 0.47,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 113,
    "out:Energy use kWh/m2": 113,
    "out:Energy savings %": 61.9,
    "out:Op energy cost/MSEK": 56,
    "out:El price/MSEK": 32,
    "out:Heating price/MSEK": 20,
    "out:DH+ DHW/kWh/m2": 102,
    "out:El bought/kWh/m2": 744.654907,
    "out:Return %": 11,
    "out:Return/kSEK/y": 762,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 41.7,
    "out:EAHP (El)": 0,
    "out:PV (kr/kWp)": 17461.48474631725,
    "out:PV (kWp installed)": 90.5097,
    "out:PV (m2 panels)": 474.867263,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 84922.568824,
    "out:PV (kWh sold)": 63894.192746,
    "out:PV (% sold (El))": 75.238177,
    "out:PV (kWh self-consumed)": 21028.376078,
    "out:PV (ratio sold/self-consumed)": 3.038475,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 978564.8401067974,
    "out:FTX (Diffusers)": 206476.5625,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 216197.941696,
    "out:EL kWh savings": 6385.163023,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 79599.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 324809.56,
    "out:DH kr savings": 216197.94169632634,
    "out:El kr savings": 12770.326045670818,
    "out:El kr sold": 95841.28911946694,
    "out:El kr saved": 42056.752156,
    "out:El kr return": 137898.04127546694,
    "out:% solar/total": 53,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 190944.234534,
    "out:% savings (space heating)": 53.101338,
    "out:Total_Water Heating (DH)": 132772.760051,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 997124.4110739925,
    "out:Eupp": 59.97,
    "out:Etvv": 25,
    "out:Ef": 10.56,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 7178412.8743156865,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 36.718368,
    "out:EL CO2": -100.475122,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -101.871982,
    "out:Bought CO2": 1.39686,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01001000020001010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 63.739365,
    "out:DHW (DH)": 35.445,
    "out:Property Electricity": 17.169,
    "out:Electricity (inc PV)": 10.564609,
    "out:Total Energy Use Pre PV": 116.169,
    "out:Total Energy Use Post PV": 109.564609,
    "out:Primary Energy": 83.466358,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 45.787308,
    "out:CO2 Operational/m2": -64.836706,
    "out:Total CO2/m2": -19.049398,
    "out:Total CO2 (tons)": -60.653265,
    "out:Klimatpaverkan": -260.788686,
    "out:Initial Cost/MSEK": 7.372028899315687,
    "out:Running cost/(Apt SEK y)": 19646.510638,
    "out:Running Cost over RSP/MSEK": 7.24216,
    "out:LCP/MSEK": -1.209469,
    "out:ROI% old": 11.941973,
    "out:Payback discounted": 10,
    "out:Atemp": 3184,
    "out:Umean": 0.47,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 110,
    "out:Energy use kWh/m2": 110,
    "out:Energy savings %": 66.4,
    "out:Op energy cost/MSEK": 54,
    "out:El price/MSEK": 31,
    "out:Heating price/MSEK": 19,
    "out:DH+ DHW/kWh/m2": 99,
    "out:El bought/kWh/m2": 744.654907,
    "out:Return %": 11,
    "out:Return/kSEK/y": 791,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 35.445,
    "out:EAHP (El)": 0,
    "out:PV (kr/kWp)": 17461.48474631725,
    "out:PV (kWp installed)": 90.5097,
    "out:PV (m2 panels)": 474.867263,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 84922.568824,
    "out:PV (kWh sold)": 63894.192746,
    "out:PV (% sold (El))": 75.238177,
    "out:PV (kWh self-consumed)": 21028.376078,
    "out:PV (ratio sold/self-consumed)": 3.038475,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 978564.8401067974,
    "out:FTX (Diffusers)": 206476.5625,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 224112.013458,
    "out:EL kWh savings": 6385.163023,
    "out:Sewage heat recovery cost": 273216,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 332723.63,
    "out:DH kr savings": 224112.01345834445,
    "out:El kr savings": 12770.326045670818,
    "out:El kr sold": 95841.28911946694,
    "out:El kr saved": 42056.752156,
    "out:El kr return": 137898.04127546694,
    "out:% solar/total": 53,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 202946.076779,
    "out:% savings (space heating)": 50.153512,
    "out:Total_Water Heating (DH)": 112856.846044,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 997124.4110739925,
    "out:Eupp": 63.74,
    "out:Etvv": 21.25,
    "out:Ef": 10.56,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 7372028.899315687,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 35.638416,
    "out:EL CO2": -100.475122,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -101.871982,
    "out:Bought CO2": 1.39686,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01001000020001110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 59.969941,
    "out:DHW (DH)": 35.445,
    "out:Property Electricity": 17.169,
    "out:Electricity (inc PV)": 10.564609,
    "out:Total Energy Use Pre PV": 112.169,
    "out:Total Energy Use Post PV": 105.564609,
    "out:Primary Energy": 80.534583,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 45.787308,
    "out:CO2 Operational/m2": -66.276642,
    "out:Total CO2/m2": -20.489334,
    "out:Total CO2 (tons)": -65.23802,
    "out:Klimatpaverkan": -265.373441,
    "out:Initial Cost/MSEK": 7.451628874315687,
    "out:Running cost/(Apt SEK y)": 18828.148936,
    "out:Running Cost over RSP/MSEK": 6.94131,
    "out:LCP/MSEK": -0.988219,
    "out:ROI% old": 12.391174,
    "out:Payback discounted": 10,
    "out:Atemp": 3184,
    "out:Umean": 0.47,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 106,
    "out:Energy use kWh/m2": 106,
    "out:Energy savings %": 72.6,
    "out:Op energy cost/MSEK": 52,
    "out:El price/MSEK": 30,
    "out:Heating price/MSEK": 19,
    "out:DH+ DHW/kWh/m2": 95,
    "out:El bought/kWh/m2": 744.654907,
    "out:Return %": 11,
    "out:Return/kSEK/y": 830,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 35.445,
    "out:EAHP (El)": 0,
    "out:PV (kr/kWp)": 17461.48474631725,
    "out:PV (kWp installed)": 90.5097,
    "out:PV (m2 panels)": 474.867263,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 84922.568824,
    "out:PV (kWh sold)": 63894.192746,
    "out:PV (% sold (El))": 75.238177,
    "out:PV (kWh self-consumed)": 21028.376078,
    "out:PV (ratio sold/self-consumed)": 3.038475,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 978564.8401067974,
    "out:FTX (Diffusers)": 206476.5625,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 236113.855704,
    "out:EL kWh savings": 6385.163023,
    "out:Sewage heat recovery cost": 273216,
    "out:Balancing hydronic system cost": 79599.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 344725.47,
    "out:DH kr savings": 236113.8557040363,
    "out:El kr savings": 12770.326045670818,
    "out:El kr sold": 95841.28911946694,
    "out:El kr saved": 42056.752156,
    "out:El kr return": 137898.04127546694,
    "out:% solar/total": 53,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 190944.234534,
    "out:% savings (space heating)": 53.101338,
    "out:Total_Water Heating (DH)": 112856.846044,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 997124.4110739925,
    "out:Eupp": 59.97,
    "out:Etvv": 21.25,
    "out:Ef": 10.56,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 7451628.8743156865,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 34.19848,
    "out:EL CO2": -100.475122,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -101.871982,
    "out:Bought CO2": 1.39686,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01001000100000010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 63.739365,
    "out:DHW (DH)": 33.36,
    "out:Property Electricity": 17.169,
    "out:Electricity (inc PV)": 17.169,
    "out:Total Energy Use Pre PV": 114.169,
    "out:Total Energy Use Post PV": 114.169,
    "out:Primary Energy": 94.479262,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 15.600566,
    "out:CO2 Operational/m2": 37.184893,
    "out:Total CO2/m2": 52.785459,
    "out:Total CO2 (tons)": 168.068851,
    "out:Klimatpaverkan": -32.06657,
    "out:Initial Cost/MSEK": 5.642426840871936,
    "out:Running cost/(Apt SEK y)": 22171.340426,
    "out:Running Cost over RSP/MSEK": 8.14071,
    "out:LCP/MSEK": -0.378417,
    "out:ROI% old": 13.327624,
    "out:Payback discounted": 9,
    "out:Atemp": 3184,
    "out:Umean": 0.47,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 114,
    "out:Energy use kWh/m2": 114,
    "out:Energy savings %": 60.5,
    "out:Op energy cost/MSEK": 59,
    "out:El price/MSEK": 37,
    "out:Heating price/MSEK": 19,
    "out:DH+ DHW/kWh/m2": 97,
    "out:El bought/kWh/m2": 1201.83,
    "out:Return %": 12,
    "out:Return/kSEK/y": 673,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 33.36,
    "out:EAHP (El)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 978564.8401067974,
    "out:FTX (Diffusers)": 206476.5625,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 230750.651461,
    "out:EL kWh savings": -14643.211594,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 201464.23,
    "out:DH kr savings": 230750.65146091447,
    "out:El kr savings": -29286.423188315977,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 202946.076779,
    "out:% savings (space heating)": 50.153512,
    "out:Total_Water Heating (DH)": 106218.208041,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 997124.4110739925,
    "out:Eupp": 63.74,
    "out:Etvv": 20,
    "out:Ef": 17.17,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 5642426.840871936,
    "out:Seasonal Variation ROI (%)": 2,
    "out:Seasonal Variation Payback": 50,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 34.918448,
    "out:EL CO2": 2.266445,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 2.266445,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01001000100000110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 59.969941,
    "out:DHW (DH)": 33.36,
    "out:Property Electricity": 17.169,
    "out:Electricity (inc PV)": 17.169,
    "out:Total Energy Use Pre PV": 110.169,
    "out:Total Energy Use Post PV": 110.169,
    "out:Primary Energy": 91.547487,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 15.600566,
    "out:CO2 Operational/m2": 35.744957,
    "out:Total CO2/m2": 51.345523,
    "out:Total CO2 (tons)": 163.484096,
    "out:Klimatpaverkan": -36.651325,
    "out:Initial Cost/MSEK": 5.722026815871936,
    "out:Running cost/(Apt SEK y)": 21352.978723,
    "out:Running Cost over RSP/MSEK": 7.83987,
    "out:LCP/MSEK": -0.157177,
    "out:ROI% old": 13.893304,
    "out:Payback discounted": 9,
    "out:Atemp": 3184,
    "out:Umean": 0.47,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 110,
    "out:Energy use kWh/m2": 110,
    "out:Energy savings %": 66.4,
    "out:Op energy cost/MSEK": 57,
    "out:El price/MSEK": 36,
    "out:Heating price/MSEK": 18,
    "out:DH+ DHW/kWh/m2": 93,
    "out:El bought/kWh/m2": 1201.83,
    "out:Return %": 12,
    "out:Return/kSEK/y": 711,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 33.36,
    "out:EAHP (El)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 978564.8401067974,
    "out:FTX (Diffusers)": 206476.5625,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 242752.493707,
    "out:EL kWh savings": -14643.211594,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 79599.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 213466.07,
    "out:DH kr savings": 242752.49370660633,
    "out:El kr savings": -29286.423188315977,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 190944.234534,
    "out:% savings (space heating)": 53.101338,
    "out:Total_Water Heating (DH)": 106218.208041,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 997124.4110739925,
    "out:Eupp": 59.97,
    "out:Etvv": 20,
    "out:Ef": 17.17,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 5722026.815871936,
    "out:Seasonal Variation ROI (%)": 2,
    "out:Seasonal Variation Payback": 50,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 33.478512,
    "out:EL CO2": 2.266445,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 2.266445,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01001000100001010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 63.739365,
    "out:DHW (DH)": 28.356,
    "out:Property Electricity": 17.169,
    "out:Electricity (inc PV)": 17.169,
    "out:Total Energy Use Pre PV": 109.169,
    "out:Total Energy Use Post PV": 109.169,
    "out:Primary Energy": 92.379262,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 16.309109,
    "out:CO2 Operational/m2": 35.384973,
    "out:Total CO2/m2": 51.694082,
    "out:Total CO2 (tons)": 164.593908,
    "out:Klimatpaverkan": -35.541513,
    "out:Initial Cost/MSEK": 5.915642840871936,
    "out:Running cost/(Apt SEK y)": 21148.382979,
    "out:Running Cost over RSP/MSEK": 7.76466,
    "out:LCP/MSEK": -0.275583,
    "out:ROI% old": 13.620208,
    "out:Payback discounted": 9,
    "out:Atemp": 3184,
    "out:Umean": 0.47,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 109,
    "out:Energy use kWh/m2": 109,
    "out:Energy savings %": 67.9,
    "out:Op energy cost/MSEK": 56,
    "out:El price/MSEK": 36,
    "out:Heating price/MSEK": 18,
    "out:DH+ DHW/kWh/m2": 92,
    "out:El bought/kWh/m2": 1201.83,
    "out:Return %": 12,
    "out:Return/kSEK/y": 721,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 28.356,
    "out:EAHP (El)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 978564.8401067974,
    "out:FTX (Diffusers)": 206476.5625,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 246683.382667,
    "out:EL kWh savings": -14643.211594,
    "out:Sewage heat recovery cost": 273216,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 217396.96,
    "out:DH kr savings": 246683.38266708245,
    "out:El kr savings": -29286.423188315977,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 202946.076779,
    "out:% savings (space heating)": 50.153512,
    "out:Total_Water Heating (DH)": 90285.476835,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 997124.4110739925,
    "out:Eupp": 63.74,
    "out:Etvv": 17,
    "out:Ef": 17.17,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 5915642.840871936,
    "out:Seasonal Variation ROI (%)": 2,
    "out:Seasonal Variation Payback": 50,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 33.118528,
    "out:EL CO2": 2.266445,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 2.266445,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01001000100001110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 59.969941,
    "out:DHW (DH)": 28.356,
    "out:Property Electricity": 17.169,
    "out:Electricity (inc PV)": 17.169,
    "out:Total Energy Use Pre PV": 105.169,
    "out:Total Energy Use Post PV": 105.169,
    "out:Primary Energy": 89.447487,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 16.309109,
    "out:CO2 Operational/m2": 33.945037,
    "out:Total CO2/m2": 50.254146,
    "out:Total CO2 (tons)": 160.009153,
    "out:Klimatpaverkan": -40.126268,
    "out:Initial Cost/MSEK": 5.995242815871936,
    "out:Running cost/(Apt SEK y)": 20330.042553,
    "out:Running Cost over RSP/MSEK": 7.46381,
    "out:LCP/MSEK": -0.054333,
    "out:ROI% old": 14.156248,
    "out:Payback discounted": 9,
    "out:Atemp": 3184,
    "out:Umean": 0.47,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 105,
    "out:Energy use kWh/m2": 105,
    "out:Energy savings %": 74.3,
    "out:Op energy cost/MSEK": 54,
    "out:El price/MSEK": 34,
    "out:Heating price/MSEK": 17,
    "out:DH+ DHW/kWh/m2": 88,
    "out:El bought/kWh/m2": 1201.83,
    "out:Return %": 13,
    "out:Return/kSEK/y": 759,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 28.356,
    "out:EAHP (El)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 978564.8401067974,
    "out:FTX (Diffusers)": 206476.5625,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 258685.224913,
    "out:EL kWh savings": -14643.211594,
    "out:Sewage heat recovery cost": 273216,
    "out:Balancing hydronic system cost": 79599.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 229398.8,
    "out:DH kr savings": 258685.2249127743,
    "out:El kr savings": -29286.423188315977,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 190944.234534,
    "out:% savings (space heating)": 53.101338,
    "out:Total_Water Heating (DH)": 90285.476835,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 997124.4110739925,
    "out:Eupp": 59.97,
    "out:Etvv": 17,
    "out:Ef": 17.17,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 5995242.815871936,
    "out:Seasonal Variation ROI (%)": 2,
    "out:Seasonal Variation Payback": 50,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 31.678592,
    "out:EL CO2": 2.266445,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 2.266445,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01001000110000010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 63.739365,
    "out:DHW (DH)": 33.36,
    "out:Property Electricity": 17.169,
    "out:Electricity (inc PV)": 11.433081,
    "out:Total Energy Use Pre PV": 114.169,
    "out:Total Energy Use Post PV": 108.433081,
    "out:Primary Energy": 84.154608,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 30.339666,
    "out:CO2 Operational/m2": -1.973025,
    "out:Total CO2/m2": 28.366641,
    "out:Total CO2 (tons)": 90.319358,
    "out:Klimatpaverkan": -109.816063,
    "out:Initial Cost/MSEK": 6.4326472350944375,
    "out:Running cost/(Apt SEK y)": 20621.893617,
    "out:Running Cost over RSP/MSEK": 7.58648,
    "out:LCP/MSEK": -0.614407,
    "out:ROI% old": 12.92123,
    "out:Payback discounted": 9,
    "out:Atemp": 3184,
    "out:Umean": 0.47,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 108,
    "out:Energy use kWh/m2": 108,
    "out:Energy savings %": 69.4,
    "out:Op energy cost/MSEK": 56,
    "out:El price/MSEK": 34,
    "out:Heating price/MSEK": 19,
    "out:DH+ DHW/kWh/m2": 97,
    "out:El bought/kWh/m2": 807.631491,
    "out:Return %": 12,
    "out:Return/kSEK/y": 745,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 33.36,
    "out:EAHP (El)": 0,
    "out:PV (kr/kWp)": 17461.56255567083,
    "out:PV (kWp installed)": 45.25485,
    "out:PV (m2 panels)": 237.433632,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 42461.284501,
    "out:PV (kWh sold)": 24198.124428,
    "out:PV (% sold (El))": 56.988677,
    "out:PV (kWh self-consumed)": 18263.160073,
    "out:PV (ratio sold/self-consumed)": 1.324969,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 978564.8401067974,
    "out:FTX (Diffusers)": 206476.5625,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 230750.651461,
    "out:EL kWh savings": 3619.949007,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 274287.74,
    "out:DH kr savings": 230750.65146091447,
    "out:El kr savings": 7239.898013663189,
    "out:El kr sold": 36297.18664221638,
    "out:El kr saved": 36526.320146,
    "out:El kr return": 72823.50678821639,
    "out:% solar/total": 46,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 202946.076779,
    "out:% savings (space heating)": 50.153512,
    "out:Total_Water Heating (DH)": 106218.208041,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 997124.4110739925,
    "out:Eupp": 63.74,
    "out:Etvv": 20,
    "out:Ef": 11.43,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 6432647.235094437,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 34.918448,
    "out:EL CO2": -36.891473,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -38.403999,
    "out:Bought CO2": 1.512526,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01001000110000110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 59.969941,
    "out:DHW (DH)": 33.36,
    "out:Property Electricity": 17.169,
    "out:Electricity (inc PV)": 11.433081,
    "out:Total Energy Use Pre PV": 110.169,
    "out:Total Energy Use Post PV": 104.433081,
    "out:Primary Energy": 81.222833,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 30.339666,
    "out:CO2 Operational/m2": -3.412961,
    "out:Total CO2/m2": 26.926705,
    "out:Total CO2 (tons)": 85.734603,
    "out:Klimatpaverkan": -114.400818,
    "out:Initial Cost/MSEK": 6.512247210094436,
    "out:Running cost/(Apt SEK y)": 19803.553191,
    "out:Running Cost over RSP/MSEK": 7.28564,
    "out:LCP/MSEK": -0.393167,
    "out:ROI% old": 13.423236,
    "out:Payback discounted": 9,
    "out:Atemp": 3184,
    "out:Umean": 0.47,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 104,
    "out:Energy use kWh/m2": 104,
    "out:Energy savings %": 76,
    "out:Op energy cost/MSEK": 54,
    "out:El price/MSEK": 32,
    "out:Heating price/MSEK": 18,
    "out:DH+ DHW/kWh/m2": 93,
    "out:El bought/kWh/m2": 807.631491,
    "out:Return %": 12,
    "out:Return/kSEK/y": 784,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 33.36,
    "out:EAHP (El)": 0,
    "out:PV (kr/kWp)": 17461.56255567083,
    "out:PV (kWp installed)": 45.25485,
    "out:PV (m2 panels)": 237.433632,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 42461.284501,
    "out:PV (kWh sold)": 24198.124428,
    "out:PV (% sold (El))": 56.988677,
    "out:PV (kWh self-consumed)": 18263.160073,
    "out:PV (ratio sold/self-consumed)": 1.324969,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 978564.8401067974,
    "out:FTX (Diffusers)": 206476.5625,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 242752.493707,
    "out:EL kWh savings": 3619.949007,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 79599.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 286289.58,
    "out:DH kr savings": 242752.49370660633,
    "out:El kr savings": 7239.898013663189,
    "out:El kr sold": 36297.18664221638,
    "out:El kr saved": 36526.320146,
    "out:El kr return": 72823.50678821639,
    "out:% solar/total": 46,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 190944.234534,
    "out:% savings (space heating)": 53.101338,
    "out:Total_Water Heating (DH)": 106218.208041,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 997124.4110739925,
    "out:Eupp": 59.97,
    "out:Etvv": 20,
    "out:Ef": 11.43,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 6512247.210094436,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 33.478512,
    "out:EL CO2": -36.891473,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -38.403999,
    "out:Bought CO2": 1.512526,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01001000110001010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 63.739365,
    "out:DHW (DH)": 28.356,
    "out:Property Electricity": 17.169,
    "out:Electricity (inc PV)": 11.433081,
    "out:Total Energy Use Pre PV": 109.169,
    "out:Total Energy Use Post PV": 103.433081,
    "out:Primary Energy": 82.054608,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 31.048209,
    "out:CO2 Operational/m2": -3.772945,
    "out:Total CO2/m2": 27.275264,
    "out:Total CO2 (tons)": 86.844414,
    "out:Klimatpaverkan": -113.291007,
    "out:Initial Cost/MSEK": 6.705863235094435,
    "out:Running cost/(Apt SEK y)": 19598.957447,
    "out:Running Cost over RSP/MSEK": 7.21043,
    "out:LCP/MSEK": -0.511573,
    "out:ROI% old": 13.195894,
    "out:Payback discounted": 9,
    "out:Atemp": 3184,
    "out:Umean": 0.47,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 103,
    "out:Energy use kWh/m2": 103,
    "out:Energy savings %": 77.7,
    "out:Op energy cost/MSEK": 53,
    "out:El price/MSEK": 32,
    "out:Heating price/MSEK": 18,
    "out:DH+ DHW/kWh/m2": 92,
    "out:El bought/kWh/m2": 807.631491,
    "out:Return %": 12,
    "out:Return/kSEK/y": 794,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 28.356,
    "out:EAHP (El)": 0,
    "out:PV (kr/kWp)": 17461.56255567083,
    "out:PV (kWp installed)": 45.25485,
    "out:PV (m2 panels)": 237.433632,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 42461.284501,
    "out:PV (kWh sold)": 24198.124428,
    "out:PV (% sold (El))": 56.988677,
    "out:PV (kWh self-consumed)": 18263.160073,
    "out:PV (ratio sold/self-consumed)": 1.324969,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 978564.8401067974,
    "out:FTX (Diffusers)": 206476.5625,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 246683.382667,
    "out:EL kWh savings": 3619.949007,
    "out:Sewage heat recovery cost": 273216,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 290220.47,
    "out:DH kr savings": 246683.38266708245,
    "out:El kr savings": 7239.898013663189,
    "out:El kr sold": 36297.18664221638,
    "out:El kr saved": 36526.320146,
    "out:El kr return": 72823.50678821639,
    "out:% solar/total": 46,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 202946.076779,
    "out:% savings (space heating)": 50.153512,
    "out:Total_Water Heating (DH)": 90285.476835,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 997124.4110739925,
    "out:Eupp": 63.74,
    "out:Etvv": 17,
    "out:Ef": 11.43,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 6705863.2350944355,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 33.118528,
    "out:EL CO2": -36.891473,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -38.403999,
    "out:Bought CO2": 1.512526,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01001000110001110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 59.969941,
    "out:DHW (DH)": 28.356,
    "out:Property Electricity": 17.169,
    "out:Electricity (inc PV)": 11.433081,
    "out:Total Energy Use Pre PV": 105.169,
    "out:Total Energy Use Post PV": 99.433081,
    "out:Primary Energy": 79.122833,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 31.048209,
    "out:CO2 Operational/m2": -5.212881,
    "out:Total CO2/m2": 25.835328,
    "out:Total CO2 (tons)": 82.25966,
    "out:Klimatpaverkan": -117.875761,
    "out:Initial Cost/MSEK": 6.785463210094436,
    "out:Running cost/(Apt SEK y)": 18780.595745,
    "out:Running Cost over RSP/MSEK": 6.90958,
    "out:LCP/MSEK": -0.290323,
    "out:ROI% old": 13.674485,
    "out:Payback discounted": 9,
    "out:Atemp": 3184,
    "out:Umean": 0.47,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 99,
    "out:Energy use kWh/m2": 99,
    "out:Energy savings %": 84.8,
    "out:Op energy cost/MSEK": 51,
    "out:El price/MSEK": 31,
    "out:Heating price/MSEK": 17,
    "out:DH+ DHW/kWh/m2": 88,
    "out:El bought/kWh/m2": 807.631491,
    "out:Return %": 12,
    "out:Return/kSEK/y": 832,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 28.356,
    "out:EAHP (El)": 0,
    "out:PV (kr/kWp)": 17461.56255567083,
    "out:PV (kWp installed)": 45.25485,
    "out:PV (m2 panels)": 237.433632,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 42461.284501,
    "out:PV (kWh sold)": 24198.124428,
    "out:PV (% sold (El))": 56.988677,
    "out:PV (kWh self-consumed)": 18263.160073,
    "out:PV (ratio sold/self-consumed)": 1.324969,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 978564.8401067974,
    "out:FTX (Diffusers)": 206476.5625,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 258685.224913,
    "out:EL kWh savings": 3619.949007,
    "out:Sewage heat recovery cost": 273216,
    "out:Balancing hydronic system cost": 79599.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 302222.31,
    "out:DH kr savings": 258685.2249127743,
    "out:El kr savings": 7239.898013663189,
    "out:El kr sold": 36297.18664221638,
    "out:El kr saved": 36526.320146,
    "out:El kr return": 72823.50678821639,
    "out:% solar/total": 46,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 190944.234534,
    "out:% savings (space heating)": 53.101338,
    "out:Total_Water Heating (DH)": 90285.476835,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 997124.4110739925,
    "out:Eupp": 59.97,
    "out:Etvv": 17,
    "out:Ef": 11.43,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 6785463.210094436,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 31.678592,
    "out:EL CO2": -36.891473,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -38.403999,
    "out:Bought CO2": 1.512526,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01001000120000010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 63.739365,
    "out:DHW (DH)": 33.36,
    "out:Property Electricity": 17.169,
    "out:Electricity (inc PV)": 10.564609,
    "out:Total Energy Use Pre PV": 114.169,
    "out:Total Energy Use Post PV": 107.564609,
    "out:Primary Energy": 82.591358,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 45.078765,
    "out:CO2 Operational/m2": -65.556674,
    "out:Total CO2/m2": -20.477909,
    "out:Total CO2 (tons)": -65.201643,
    "out:Klimatpaverkan": -265.337064,
    "out:Initial Cost/MSEK": 7.222860586815686,
    "out:Running cost/(Apt SEK y)": 19237.340426,
    "out:Running Cost over RSP/MSEK": 7.09173,
    "out:LCP/MSEK": -0.909871,
    "out:ROI% old": 12.486129,
    "out:Payback discounted": 10,
    "out:Atemp": 3184,
    "out:Umean": 0.47,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 108,
    "out:Energy use kWh/m2": 108,
    "out:Energy savings %": 69.4,
    "out:Op energy cost/MSEK": 53,
    "out:El price/MSEK": 31,
    "out:Heating price/MSEK": 19,
    "out:DH+ DHW/kWh/m2": 97,
    "out:El bought/kWh/m2": 744.654907,
    "out:Return %": 11,
    "out:Return/kSEK/y": 811,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 33.36,
    "out:EAHP (El)": 0,
    "out:PV (kr/kWp)": 17461.48474631725,
    "out:PV (kWp installed)": 90.5097,
    "out:PV (m2 panels)": 474.867263,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 84922.568824,
    "out:PV (kWh sold)": 63894.192746,
    "out:PV (% sold (El))": 75.238177,
    "out:PV (kWh self-consumed)": 21028.376078,
    "out:PV (ratio sold/self-consumed)": 3.038475,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 978564.8401067974,
    "out:FTX (Diffusers)": 206476.5625,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 230750.651461,
    "out:EL kWh savings": 6385.163023,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 339362.27,
    "out:DH kr savings": 230750.65146091447,
    "out:El kr savings": 12770.326045670818,
    "out:El kr sold": 95841.28911946694,
    "out:El kr saved": 42056.752156,
    "out:El kr return": 137898.04127546694,
    "out:% solar/total": 53,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 202946.076779,
    "out:% savings (space heating)": 50.153512,
    "out:Total_Water Heating (DH)": 106218.208041,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 997124.4110739925,
    "out:Eupp": 63.74,
    "out:Etvv": 20,
    "out:Ef": 10.56,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 7222860.586815686,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 34.918448,
    "out:EL CO2": -100.475122,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -101.871982,
    "out:Bought CO2": 1.39686,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01001000120000110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 59.969941,
    "out:DHW (DH)": 33.36,
    "out:Property Electricity": 17.169,
    "out:Electricity (inc PV)": 10.564609,
    "out:Total Energy Use Pre PV": 110.169,
    "out:Total Energy Use Post PV": 103.564609,
    "out:Primary Energy": 79.659583,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 45.078765,
    "out:CO2 Operational/m2": -66.99661,
    "out:Total CO2/m2": -21.917845,
    "out:Total CO2 (tons)": -69.786397,
    "out:Klimatpaverkan": -269.921819,
    "out:Initial Cost/MSEK": 7.302460561815686,
    "out:Running cost/(Apt SEK y)": 18418.978723,
    "out:Running Cost over RSP/MSEK": 6.79089,
    "out:LCP/MSEK": -0.688631,
    "out:ROI% old": 12.938555,
    "out:Payback discounted": 9,
    "out:Atemp": 3184,
    "out:Umean": 0.47,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 104,
    "out:Energy use kWh/m2": 104,
    "out:Energy savings %": 76,
    "out:Op energy cost/MSEK": 51,
    "out:El price/MSEK": 29,
    "out:Heating price/MSEK": 18,
    "out:DH+ DHW/kWh/m2": 93,
    "out:El bought/kWh/m2": 744.654907,
    "out:Return %": 12,
    "out:Return/kSEK/y": 849,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 33.36,
    "out:EAHP (El)": 0,
    "out:PV (kr/kWp)": 17461.48474631725,
    "out:PV (kWp installed)": 90.5097,
    "out:PV (m2 panels)": 474.867263,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 84922.568824,
    "out:PV (kWh sold)": 63894.192746,
    "out:PV (% sold (El))": 75.238177,
    "out:PV (kWh self-consumed)": 21028.376078,
    "out:PV (ratio sold/self-consumed)": 3.038475,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 978564.8401067974,
    "out:FTX (Diffusers)": 206476.5625,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 242752.493707,
    "out:EL kWh savings": 6385.163023,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 79599.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 351364.11,
    "out:DH kr savings": 242752.49370660633,
    "out:El kr savings": 12770.326045670818,
    "out:El kr sold": 95841.28911946694,
    "out:El kr saved": 42056.752156,
    "out:El kr return": 137898.04127546694,
    "out:% solar/total": 53,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 190944.234534,
    "out:% savings (space heating)": 53.101338,
    "out:Total_Water Heating (DH)": 106218.208041,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 997124.4110739925,
    "out:Eupp": 59.97,
    "out:Etvv": 20,
    "out:Ef": 10.56,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 7302460.5618156865,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 33.478512,
    "out:EL CO2": -100.475122,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -101.871982,
    "out:Bought CO2": 1.39686,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01001000120001010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 63.739365,
    "out:DHW (DH)": 28.356,
    "out:Property Electricity": 17.169,
    "out:Electricity (inc PV)": 10.564609,
    "out:Total Energy Use Pre PV": 109.169,
    "out:Total Energy Use Post PV": 102.564609,
    "out:Primary Energy": 80.491358,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 45.787308,
    "out:CO2 Operational/m2": -67.356594,
    "out:Total CO2/m2": -21.569286,
    "out:Total CO2 (tons)": -68.676586,
    "out:Klimatpaverkan": -268.812007,
    "out:Initial Cost/MSEK": 7.496076586815687,
    "out:Running cost/(Apt SEK y)": 18214.382979,
    "out:Running Cost over RSP/MSEK": 6.71568,
    "out:LCP/MSEK": -0.807037,
    "out:ROI% old": 12.747697,
    "out:Payback discounted": 9,
    "out:Atemp": 3184,
    "out:Umean": 0.47,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 103,
    "out:Energy use kWh/m2": 103,
    "out:Energy savings %": 77.7,
    "out:Op energy cost/MSEK": 50,
    "out:El price/MSEK": 29,
    "out:Heating price/MSEK": 18,
    "out:DH+ DHW/kWh/m2": 92,
    "out:El bought/kWh/m2": 744.654907,
    "out:Return %": 11,
    "out:Return/kSEK/y": 859,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 28.356,
    "out:EAHP (El)": 0,
    "out:PV (kr/kWp)": 17461.48474631725,
    "out:PV (kWp installed)": 90.5097,
    "out:PV (m2 panels)": 474.867263,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 84922.568824,
    "out:PV (kWh sold)": 63894.192746,
    "out:PV (% sold (El))": 75.238177,
    "out:PV (kWh self-consumed)": 21028.376078,
    "out:PV (ratio sold/self-consumed)": 3.038475,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 978564.8401067974,
    "out:FTX (Diffusers)": 206476.5625,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 246683.382667,
    "out:EL kWh savings": 6385.163023,
    "out:Sewage heat recovery cost": 273216,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 355295,
    "out:DH kr savings": 246683.38266708245,
    "out:El kr savings": 12770.326045670818,
    "out:El kr sold": 95841.28911946694,
    "out:El kr saved": 42056.752156,
    "out:El kr return": 137898.04127546694,
    "out:% solar/total": 53,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 202946.076779,
    "out:% savings (space heating)": 50.153512,
    "out:Total_Water Heating (DH)": 90285.476835,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 997124.4110739925,
    "out:Eupp": 63.74,
    "out:Etvv": 17,
    "out:Ef": 10.56,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 7496076.586815687,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 33.118528,
    "out:EL CO2": -100.475122,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -101.871982,
    "out:Bought CO2": 1.39686,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z01001000120001110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 59.969941,
    "out:DHW (DH)": 28.356,
    "out:Property Electricity": 17.169,
    "out:Electricity (inc PV)": 10.564609,
    "out:Total Energy Use Pre PV": 105.169,
    "out:Total Energy Use Post PV": 98.564609,
    "out:Primary Energy": 77.559583,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 45.787308,
    "out:CO2 Operational/m2": -68.79653,
    "out:Total CO2/m2": -23.009222,
    "out:Total CO2 (tons)": -73.261341,
    "out:Klimatpaverkan": -273.396762,
    "out:Initial Cost/MSEK": 7.575676561815687,
    "out:Running cost/(Apt SEK y)": 17396.042553,
    "out:Running Cost over RSP/MSEK": 6.41483,
    "out:LCP/MSEK": -0.585787,
    "out:ROI% old": 13.181076,
    "out:Payback discounted": 9,
    "out:Atemp": 3184,
    "out:Umean": 0.47,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 99,
    "out:Energy use kWh/m2": 99,
    "out:Energy savings %": 84.8,
    "out:Op energy cost/MSEK": 48,
    "out:El price/MSEK": 28,
    "out:Heating price/MSEK": 17,
    "out:DH+ DHW/kWh/m2": 88,
    "out:El bought/kWh/m2": 744.654907,
    "out:Return %": 12,
    "out:Return/kSEK/y": 897,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 28.356,
    "out:EAHP (El)": 0,
    "out:PV (kr/kWp)": 17461.48474631725,
    "out:PV (kWp installed)": 90.5097,
    "out:PV (m2 panels)": 474.867263,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 84922.568824,
    "out:PV (kWh sold)": 63894.192746,
    "out:PV (% sold (El))": 75.238177,
    "out:PV (kWh self-consumed)": 21028.376078,
    "out:PV (ratio sold/self-consumed)": 3.038475,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 978564.8401067974,
    "out:FTX (Diffusers)": 206476.5625,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 258685.224913,
    "out:EL kWh savings": 6385.163023,
    "out:Sewage heat recovery cost": 273216,
    "out:Balancing hydronic system cost": 79599.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 367296.84,
    "out:DH kr savings": 258685.2249127743,
    "out:El kr savings": 12770.326045670818,
    "out:El kr sold": 95841.28911946694,
    "out:El kr saved": 42056.752156,
    "out:El kr return": 137898.04127546694,
    "out:% solar/total": 53,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 190944.234534,
    "out:% savings (space heating)": 53.101338,
    "out:Total_Water Heating (DH)": 90285.476835,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 997124.4110739925,
    "out:Eupp": 59.97,
    "out:Etvv": 17,
    "out:Ef": 10.56,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 7575676.5618156865,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 31.678592,
    "out:EL CO2": -100.475122,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -101.871982,
    "out:Bought CO2": 1.39686,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02000000000000010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 68.605113,
    "out:DHW (DH)": 41.7,
    "out:Property Electricity": 17.169,
    "out:Electricity (inc PV)": 17.169,
    "out:Total Energy Use Pre PV": 127.169,
    "out:Total Energy Use Post PV": 127.169,
    "out:Primary Energy": 101.763732,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 16.731328,
    "out:CO2 Operational/m2": 41.864685,
    "out:Total CO2/m2": 58.596013,
    "out:Total CO2 (tons)": 186.569649,
    "out:Klimatpaverkan": -13.565772,
    "out:Initial Cost/MSEK": 5.8492923003681865,
    "out:Running cost/(Apt SEK y)": 24831,
    "out:Running Cost over RSP/MSEK": 9.11846,
    "out:LCP/MSEK": -1.563032,
    "out:ROI% old": 10.468324,
    "out:Payback discounted": 12,
    "out:Atemp": 3184,
    "out:Umean": 0.55,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 127,
    "out:Energy use kWh/m2": 127,
    "out:Energy savings %": 44.1,
    "out:Op energy cost/MSEK": 66,
    "out:El price/MSEK": 41,
    "out:Heating price/MSEK": 21,
    "out:DH+ DHW/kWh/m2": 110,
    "out:El bought/kWh/m2": 1201.83,
    "out:Return %": 9,
    "out:Return/kSEK/y": 548,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 41.7,
    "out:EAHP (El)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 978564.8401067974,
    "out:FTX (Diffusers)": 206476.5625,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 188703.563435,
    "out:EL kWh savings": -14643.211594,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 159417.14,
    "out:DH kr savings": 188703.56343522077,
    "out:El kr savings": -29286.423188315977,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 218438.612795,
    "out:% savings (space heating)": 46.348321,
    "out:Total_Water Heating (DH)": 132772.760051,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 997124.4110739925,
    "out:Eupp": 68.61,
    "out:Etvv": 25,
    "out:Ef": 17.17,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 5849292.300368186,
    "out:Seasonal Variation ROI (%)": 1,
    "out:Seasonal Variation Payback": 100,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 39.59824,
    "out:EL CO2": 2.266445,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 2.266445,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02000000000000110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 64.614518,
    "out:DHW (DH)": 41.7,
    "out:Property Electricity": 17.169,
    "out:Electricity (inc PV)": 17.169,
    "out:Total Energy Use Pre PV": 123.169,
    "out:Total Energy Use Post PV": 123.169,
    "out:Primary Energy": 98.659936,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 16.731328,
    "out:CO2 Operational/m2": 40.424749,
    "out:Total CO2/m2": 57.156077,
    "out:Total CO2 (tons)": 181.984894,
    "out:Klimatpaverkan": -18.150527,
    "out:Initial Cost/MSEK": 5.928892275368185,
    "out:Running cost/(Apt SEK y)": 24012.638298,
    "out:Running Cost over RSP/MSEK": 8.81761,
    "out:LCP/MSEK": -1.341782,
    "out:ROI% old": 11.052679,
    "out:Payback discounted": 11,
    "out:Atemp": 3184,
    "out:Umean": 0.55,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 123,
    "out:Energy use kWh/m2": 123,
    "out:Energy savings %": 48.8,
    "out:Op energy cost/MSEK": 64,
    "out:El price/MSEK": 40,
    "out:Heating price/MSEK": 21,
    "out:DH+ DHW/kWh/m2": 106,
    "out:El bought/kWh/m2": 1201.83,
    "out:Return %": 10,
    "out:Return/kSEK/y": 586,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 41.7,
    "out:EAHP (El)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 978564.8401067974,
    "out:FTX (Diffusers)": 206476.5625,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 201409.611863,
    "out:EL kWh savings": -14643.211594,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 79599.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 172123.19,
    "out:DH kr savings": 201409.61186343146,
    "out:El kr savings": -29286.423188315977,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 205732.564366,
    "out:% savings (space heating)": 49.46911,
    "out:Total_Water Heating (DH)": 132772.760051,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 997124.4110739925,
    "out:Eupp": 64.61,
    "out:Etvv": 25,
    "out:Ef": 17.17,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 5928892.275368186,
    "out:Seasonal Variation ROI (%)": 1,
    "out:Seasonal Variation Payback": 100,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 38.158304,
    "out:EL CO2": 2.266445,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 2.266445,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02000000000001010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 68.605113,
    "out:DHW (DH)": 35.445,
    "out:Property Electricity": 17.169,
    "out:Electricity (inc PV)": 17.169,
    "out:Total Energy Use Pre PV": 121.169,
    "out:Total Energy Use Post PV": 121.169,
    "out:Primary Energy": 99.138732,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 17.439871,
    "out:CO2 Operational/m2": 39.704781,
    "out:Total CO2/m2": 57.144652,
    "out:Total CO2 (tons)": 181.948517,
    "out:Klimatpaverkan": -18.186904,
    "out:Initial Cost/MSEK": 6.122508300368186,
    "out:Running cost/(Apt SEK y)": 23603.468085,
    "out:Running Cost over RSP/MSEK": 8.66719,
    "out:LCP/MSEK": -1.384978,
    "out:ROI% old": 11.05413,
    "out:Payback discounted": 11,
    "out:Atemp": 3184,
    "out:Umean": 0.55,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 121,
    "out:Energy use kWh/m2": 121,
    "out:Energy savings %": 51.2,
    "out:Op energy cost/MSEK": 63,
    "out:El price/MSEK": 39,
    "out:Heating price/MSEK": 20,
    "out:DH+ DHW/kWh/m2": 104,
    "out:El bought/kWh/m2": 1201.83,
    "out:Return %": 10,
    "out:Return/kSEK/y": 605,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 35.445,
    "out:EAHP (El)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 978564.8401067974,
    "out:FTX (Diffusers)": 206476.5625,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 208619.477443,
    "out:EL kWh savings": -14643.211594,
    "out:Sewage heat recovery cost": 273216,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 179333.05,
    "out:DH kr savings": 208619.4774429308,
    "out:El kr savings": -29286.423188315977,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 218438.612795,
    "out:% savings (space heating)": 46.348321,
    "out:Total_Water Heating (DH)": 112856.846044,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 997124.4110739925,
    "out:Eupp": 68.61,
    "out:Etvv": 21.25,
    "out:Ef": 17.17,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 6122508.300368186,
    "out:Seasonal Variation ROI (%)": 1,
    "out:Seasonal Variation Payback": 100,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 37.438336,
    "out:EL CO2": 2.266445,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 2.266445,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02000000000001110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 64.614518,
    "out:DHW (DH)": 35.445,
    "out:Property Electricity": 17.169,
    "out:Electricity (inc PV)": 17.169,
    "out:Total Energy Use Pre PV": 117.169,
    "out:Total Energy Use Post PV": 117.169,
    "out:Primary Energy": 96.034936,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 17.439871,
    "out:CO2 Operational/m2": 38.264845,
    "out:Total CO2/m2": 55.704716,
    "out:Total CO2 (tons)": 177.363762,
    "out:Klimatpaverkan": -22.771659,
    "out:Initial Cost/MSEK": 6.202108275368186,
    "out:Running cost/(Apt SEK y)": 22785.106383,
    "out:Running Cost over RSP/MSEK": 8.36635,
    "out:LCP/MSEK": -1.163738,
    "out:ROI% old": 11.605201,
    "out:Payback discounted": 10,
    "out:Atemp": 3184,
    "out:Umean": 0.55,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 117,
    "out:Energy use kWh/m2": 117,
    "out:Energy savings %": 56.4,
    "out:Op energy cost/MSEK": 61,
    "out:El price/MSEK": 38,
    "out:Heating price/MSEK": 19,
    "out:DH+ DHW/kWh/m2": 100,
    "out:El bought/kWh/m2": 1201.83,
    "out:Return %": 10,
    "out:Return/kSEK/y": 644,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 35.445,
    "out:EAHP (El)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 978564.8401067974,
    "out:FTX (Diffusers)": 206476.5625,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 221325.525871,
    "out:EL kWh savings": -14643.211594,
    "out:Sewage heat recovery cost": 273216,
    "out:Balancing hydronic system cost": 79599.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 192039.1,
    "out:DH kr savings": 221325.52587114144,
    "out:El kr savings": -29286.423188315977,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 205732.564366,
    "out:% savings (space heating)": 49.46911,
    "out:Total_Water Heating (DH)": 112856.846044,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 997124.4110739925,
    "out:Eupp": 64.61,
    "out:Etvv": 21.25,
    "out:Ef": 17.17,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 6202108.275368186,
    "out:Seasonal Variation ROI (%)": 2,
    "out:Seasonal Variation Payback": 50,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 35.9984,
    "out:EL CO2": 2.266445,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 2.266445,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02000000010000010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 68.605113,
    "out:DHW (DH)": 41.7,
    "out:Property Electricity": 17.169,
    "out:Electricity (inc PV)": 11.433081,
    "out:Total Energy Use Pre PV": 127.169,
    "out:Total Energy Use Post PV": 121.433081,
    "out:Primary Energy": 91.439078,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 31.470428,
    "out:CO2 Operational/m2": 2.706767,
    "out:Total CO2/m2": 34.177195,
    "out:Total CO2 (tons)": 108.820156,
    "out:Klimatpaverkan": -91.315265,
    "out:Initial Cost/MSEK": 6.639512694590687,
    "out:Running cost/(Apt SEK y)": 23281.553191,
    "out:Running Cost over RSP/MSEK": 8.56423,
    "out:LCP/MSEK": -1.799023,
    "out:ROI% old": 10.414899,
    "out:Payback discounted": 12,
    "out:Atemp": 3184,
    "out:Umean": 0.55,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 121,
    "out:Energy use kWh/m2": 121,
    "out:Energy savings %": 51.2,
    "out:Op energy cost/MSEK": 63,
    "out:El price/MSEK": 38,
    "out:Heating price/MSEK": 21,
    "out:DH+ DHW/kWh/m2": 110,
    "out:El bought/kWh/m2": 807.631491,
    "out:Return %": 9,
    "out:Return/kSEK/y": 620,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 41.7,
    "out:EAHP (El)": 0,
    "out:PV (kr/kWp)": 17461.56255567083,
    "out:PV (kWp installed)": 45.25485,
    "out:PV (m2 panels)": 237.433632,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 42461.284501,
    "out:PV (kWh sold)": 24198.124428,
    "out:PV (% sold (El))": 56.988677,
    "out:PV (kWh self-consumed)": 18263.160073,
    "out:PV (ratio sold/self-consumed)": 1.324969,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 978564.8401067974,
    "out:FTX (Diffusers)": 206476.5625,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 188703.563435,
    "out:EL kWh savings": 3619.949007,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 232240.65,
    "out:DH kr savings": 188703.56343522077,
    "out:El kr savings": 7239.898013663189,
    "out:El kr sold": 36297.18664221638,
    "out:El kr saved": 36526.320146,
    "out:El kr return": 72823.50678821639,
    "out:% solar/total": 46,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 218438.612795,
    "out:% savings (space heating)": 46.348321,
    "out:Total_Water Heating (DH)": 132772.760051,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 997124.4110739925,
    "out:Eupp": 68.61,
    "out:Etvv": 25,
    "out:Ef": 11.43,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 6639512.694590687,
    "out:Seasonal Variation ROI (%)": 2,
    "out:Seasonal Variation Payback": 50,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 39.59824,
    "out:EL CO2": -36.891473,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -38.403999,
    "out:Bought CO2": 1.512526,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02000000010000110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 64.614518,
    "out:DHW (DH)": 41.7,
    "out:Property Electricity": 17.169,
    "out:Electricity (inc PV)": 11.433081,
    "out:Total Energy Use Pre PV": 123.169,
    "out:Total Energy Use Post PV": 117.433081,
    "out:Primary Energy": 88.335282,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 31.470428,
    "out:CO2 Operational/m2": 1.266831,
    "out:Total CO2/m2": 32.737259,
    "out:Total CO2 (tons)": 104.235401,
    "out:Klimatpaverkan": -95.90002,
    "out:Initial Cost/MSEK": 6.719112669590685,
    "out:Running cost/(Apt SEK y)": 22463.191489,
    "out:Running Cost over RSP/MSEK": 8.26338,
    "out:LCP/MSEK": -1.577773,
    "out:ROI% old": 10.931163,
    "out:Payback discounted": 11,
    "out:Atemp": 3184,
    "out:Umean": 0.55,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 117,
    "out:Energy use kWh/m2": 117,
    "out:Energy savings %": 56.4,
    "out:Op energy cost/MSEK": 61,
    "out:El price/MSEK": 37,
    "out:Heating price/MSEK": 21,
    "out:DH+ DHW/kWh/m2": 106,
    "out:El bought/kWh/m2": 807.631491,
    "out:Return %": 10,
    "out:Return/kSEK/y": 659,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 41.7,
    "out:EAHP (El)": 0,
    "out:PV (kr/kWp)": 17461.56255567083,
    "out:PV (kWp installed)": 45.25485,
    "out:PV (m2 panels)": 237.433632,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 42461.284501,
    "out:PV (kWh sold)": 24198.124428,
    "out:PV (% sold (El))": 56.988677,
    "out:PV (kWh self-consumed)": 18263.160073,
    "out:PV (ratio sold/self-consumed)": 1.324969,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 978564.8401067974,
    "out:FTX (Diffusers)": 206476.5625,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 201409.611863,
    "out:EL kWh savings": 3619.949007,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 79599.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 244946.7,
    "out:DH kr savings": 201409.61186343146,
    "out:El kr savings": 7239.898013663189,
    "out:El kr sold": 36297.18664221638,
    "out:El kr saved": 36526.320146,
    "out:El kr return": 72823.50678821639,
    "out:% solar/total": 46,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 205732.564366,
    "out:% savings (space heating)": 49.46911,
    "out:Total_Water Heating (DH)": 132772.760051,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 997124.4110739925,
    "out:Eupp": 64.61,
    "out:Etvv": 25,
    "out:Ef": 11.43,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 6719112.6695906855,
    "out:Seasonal Variation ROI (%)": 2,
    "out:Seasonal Variation Payback": 50,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 38.158304,
    "out:EL CO2": -36.891473,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -38.403999,
    "out:Bought CO2": 1.512526,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02000000010001010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 68.605113,
    "out:DHW (DH)": 35.445,
    "out:Property Electricity": 17.169,
    "out:Electricity (inc PV)": 11.433081,
    "out:Total Energy Use Pre PV": 121.169,
    "out:Total Energy Use Post PV": 115.433081,
    "out:Primary Energy": 88.814078,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 32.178971,
    "out:CO2 Operational/m2": 0.546863,
    "out:Total CO2/m2": 32.725834,
    "out:Total CO2 (tons)": 104.199024,
    "out:Klimatpaverkan": -95.936397,
    "out:Initial Cost/MSEK": 6.912728694590686,
    "out:Running cost/(Apt SEK y)": 22054.021277,
    "out:Running Cost over RSP/MSEK": 8.11296,
    "out:LCP/MSEK": -1.620969,
    "out:ROI% old": 10.935851,
    "out:Payback discounted": 11,
    "out:Atemp": 3184,
    "out:Umean": 0.55,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 115,
    "out:Energy use kWh/m2": 115,
    "out:Energy savings %": 59.1,
    "out:Op energy cost/MSEK": 60,
    "out:El price/MSEK": 36,
    "out:Heating price/MSEK": 20,
    "out:DH+ DHW/kWh/m2": 104,
    "out:El bought/kWh/m2": 807.631491,
    "out:Return %": 10,
    "out:Return/kSEK/y": 678,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 35.445,
    "out:EAHP (El)": 0,
    "out:PV (kr/kWp)": 17461.56255567083,
    "out:PV (kWp installed)": 45.25485,
    "out:PV (m2 panels)": 237.433632,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 42461.284501,
    "out:PV (kWh sold)": 24198.124428,
    "out:PV (% sold (El))": 56.988677,
    "out:PV (kWh self-consumed)": 18263.160073,
    "out:PV (ratio sold/self-consumed)": 1.324969,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 978564.8401067974,
    "out:FTX (Diffusers)": 206476.5625,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 208619.477443,
    "out:EL kWh savings": 3619.949007,
    "out:Sewage heat recovery cost": 273216,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 252156.56,
    "out:DH kr savings": 208619.4774429308,
    "out:El kr savings": 7239.898013663189,
    "out:El kr sold": 36297.18664221638,
    "out:El kr saved": 36526.320146,
    "out:El kr return": 72823.50678821639,
    "out:% solar/total": 46,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 218438.612795,
    "out:% savings (space heating)": 46.348321,
    "out:Total_Water Heating (DH)": 112856.846044,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 997124.4110739925,
    "out:Eupp": 68.61,
    "out:Etvv": 21.25,
    "out:Ef": 11.43,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 6912728.694590686,
    "out:Seasonal Variation ROI (%)": 2,
    "out:Seasonal Variation Payback": 50,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 37.438336,
    "out:EL CO2": -36.891473,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -38.403999,
    "out:Bought CO2": 1.512526,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02000000010001110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 64.614518,
    "out:DHW (DH)": 35.445,
    "out:Property Electricity": 17.169,
    "out:Electricity (inc PV)": 11.433081,
    "out:Total Energy Use Pre PV": 117.169,
    "out:Total Energy Use Post PV": 111.433081,
    "out:Primary Energy": 85.710282,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 32.178971,
    "out:CO2 Operational/m2": -0.893073,
    "out:Total CO2/m2": 31.285898,
    "out:Total CO2 (tons)": 99.614269,
    "out:Klimatpaverkan": -100.521152,
    "out:Initial Cost/MSEK": 6.992328669590687,
    "out:Running cost/(Apt SEK y)": 21235.659574,
    "out:Running Cost over RSP/MSEK": 7.81212,
    "out:LCP/MSEK": -1.399729,
    "out:ROI% old": 11.425991,
    "out:Payback discounted": 11,
    "out:Atemp": 3184,
    "out:Umean": 0.55,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 111,
    "out:Energy use kWh/m2": 111,
    "out:Energy savings %": 64.9,
    "out:Op energy cost/MSEK": 57,
    "out:El price/MSEK": 35,
    "out:Heating price/MSEK": 19,
    "out:DH+ DHW/kWh/m2": 100,
    "out:El bought/kWh/m2": 807.631491,
    "out:Return %": 10,
    "out:Return/kSEK/y": 717,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 35.445,
    "out:EAHP (El)": 0,
    "out:PV (kr/kWp)": 17461.56255567083,
    "out:PV (kWp installed)": 45.25485,
    "out:PV (m2 panels)": 237.433632,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 42461.284501,
    "out:PV (kWh sold)": 24198.124428,
    "out:PV (% sold (El))": 56.988677,
    "out:PV (kWh self-consumed)": 18263.160073,
    "out:PV (ratio sold/self-consumed)": 1.324969,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 978564.8401067974,
    "out:FTX (Diffusers)": 206476.5625,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 221325.525871,
    "out:EL kWh savings": 3619.949007,
    "out:Sewage heat recovery cost": 273216,
    "out:Balancing hydronic system cost": 79599.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 264862.61,
    "out:DH kr savings": 221325.52587114144,
    "out:El kr savings": 7239.898013663189,
    "out:El kr sold": 36297.18664221638,
    "out:El kr saved": 36526.320146,
    "out:El kr return": 72823.50678821639,
    "out:% solar/total": 46,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 205732.564366,
    "out:% savings (space heating)": 49.46911,
    "out:Total_Water Heating (DH)": 112856.846044,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 997124.4110739925,
    "out:Eupp": 64.61,
    "out:Etvv": 21.25,
    "out:Ef": 11.43,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 6992328.669590687,
    "out:Seasonal Variation ROI (%)": 2,
    "out:Seasonal Variation Payback": 50,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 35.9984,
    "out:EL CO2": -36.891473,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -38.403999,
    "out:Bought CO2": 1.512526,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02000000020000010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 68.605113,
    "out:DHW (DH)": 41.7,
    "out:Property Electricity": 17.169,
    "out:Electricity (inc PV)": 10.564609,
    "out:Total Energy Use Pre PV": 127.169,
    "out:Total Energy Use Post PV": 120.564609,
    "out:Primary Energy": 89.875828,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 46.209527,
    "out:CO2 Operational/m2": -60.876882,
    "out:Total CO2/m2": -14.667355,
    "out:Total CO2 (tons)": -46.700844,
    "out:Klimatpaverkan": -246.836265,
    "out:Initial Cost/MSEK": 7.429726046311937,
    "out:Running cost/(Apt SEK y)": 21897,
    "out:Running Cost over RSP/MSEK": 8.06948,
    "out:LCP/MSEK": -2.094486,
    "out:ROI% old": 10.258482,
    "out:Payback discounted": 12,
    "out:Atemp": 3184,
    "out:Umean": 0.55,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 121,
    "out:Energy use kWh/m2": 121,
    "out:Energy savings %": 51.2,
    "out:Op energy cost/MSEK": 60,
    "out:El price/MSEK": 35,
    "out:Heating price/MSEK": 21,
    "out:DH+ DHW/kWh/m2": 110,
    "out:El bought/kWh/m2": 744.654907,
    "out:Return %": 9,
    "out:Return/kSEK/y": 686,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 41.7,
    "out:EAHP (El)": 0,
    "out:PV (kr/kWp)": 17461.48474631725,
    "out:PV (kWp installed)": 90.5097,
    "out:PV (m2 panels)": 474.867263,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 84922.568824,
    "out:PV (kWh sold)": 63894.192746,
    "out:PV (% sold (El))": 75.238177,
    "out:PV (kWh self-consumed)": 21028.376078,
    "out:PV (ratio sold/self-consumed)": 3.038475,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 978564.8401067974,
    "out:FTX (Diffusers)": 206476.5625,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 188703.563435,
    "out:EL kWh savings": 6385.163023,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 297315.18,
    "out:DH kr savings": 188703.56343522077,
    "out:El kr savings": 12770.326045670818,
    "out:El kr sold": 95841.28911946694,
    "out:El kr saved": 42056.752156,
    "out:El kr return": 137898.04127546694,
    "out:% solar/total": 53,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 218438.612795,
    "out:% savings (space heating)": 46.348321,
    "out:Total_Water Heating (DH)": 132772.760051,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 997124.4110739925,
    "out:Eupp": 68.61,
    "out:Etvv": 25,
    "out:Ef": 10.56,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 7429726.046311937,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 39.59824,
    "out:EL CO2": -100.475122,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -101.871982,
    "out:Bought CO2": 1.39686,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02000000020000110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 64.614518,
    "out:DHW (DH)": 41.7,
    "out:Property Electricity": 17.169,
    "out:Electricity (inc PV)": 10.564609,
    "out:Total Energy Use Pre PV": 123.169,
    "out:Total Energy Use Post PV": 116.564609,
    "out:Primary Energy": 86.772032,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 46.209527,
    "out:CO2 Operational/m2": -62.316818,
    "out:Total CO2/m2": -16.107291,
    "out:Total CO2 (tons)": -51.285599,
    "out:Klimatpaverkan": -251.42102,
    "out:Initial Cost/MSEK": 7.509326021311937,
    "out:Running cost/(Apt SEK y)": 21078.638298,
    "out:Running Cost over RSP/MSEK": 7.76864,
    "out:LCP/MSEK": -1.873246,
    "out:ROI% old": 10.722058,
    "out:Payback discounted": 11,
    "out:Atemp": 3184,
    "out:Umean": 0.55,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 117,
    "out:Energy use kWh/m2": 117,
    "out:Energy savings %": 56.4,
    "out:Op energy cost/MSEK": 58,
    "out:El price/MSEK": 34,
    "out:Heating price/MSEK": 21,
    "out:DH+ DHW/kWh/m2": 106,
    "out:El bought/kWh/m2": 744.654907,
    "out:Return %": 10,
    "out:Return/kSEK/y": 724,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 41.7,
    "out:EAHP (El)": 0,
    "out:PV (kr/kWp)": 17461.48474631725,
    "out:PV (kWp installed)": 90.5097,
    "out:PV (m2 panels)": 474.867263,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 84922.568824,
    "out:PV (kWh sold)": 63894.192746,
    "out:PV (% sold (El))": 75.238177,
    "out:PV (kWh self-consumed)": 21028.376078,
    "out:PV (ratio sold/self-consumed)": 3.038475,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 978564.8401067974,
    "out:FTX (Diffusers)": 206476.5625,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 201409.611863,
    "out:EL kWh savings": 6385.163023,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 79599.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 310021.23,
    "out:DH kr savings": 201409.61186343146,
    "out:El kr savings": 12770.326045670818,
    "out:El kr sold": 95841.28911946694,
    "out:El kr saved": 42056.752156,
    "out:El kr return": 137898.04127546694,
    "out:% solar/total": 53,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 205732.564366,
    "out:% savings (space heating)": 49.46911,
    "out:Total_Water Heating (DH)": 132772.760051,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 997124.4110739925,
    "out:Eupp": 64.61,
    "out:Etvv": 25,
    "out:Ef": 10.56,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 7509326.021311937,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 38.158304,
    "out:EL CO2": -100.475122,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -101.871982,
    "out:Bought CO2": 1.39686,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02000000020001010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 68.605113,
    "out:DHW (DH)": 35.445,
    "out:Property Electricity": 17.169,
    "out:Electricity (inc PV)": 10.564609,
    "out:Total Energy Use Pre PV": 121.169,
    "out:Total Energy Use Post PV": 114.564609,
    "out:Primary Energy": 87.250828,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 46.91807,
    "out:CO2 Operational/m2": -63.036786,
    "out:Total CO2/m2": -16.118716,
    "out:Total CO2 (tons)": -51.321976,
    "out:Klimatpaverkan": -251.457397,
    "out:Initial Cost/MSEK": 7.702942046311937,
    "out:Running cost/(Apt SEK y)": 20669.468085,
    "out:Running Cost over RSP/MSEK": 7.61821,
    "out:LCP/MSEK": -1.916432,
    "out:ROI% old": 10.73154,
    "out:Payback discounted": 11,
    "out:Atemp": 3184,
    "out:Umean": 0.55,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 115,
    "out:Energy use kWh/m2": 115,
    "out:Energy savings %": 59.1,
    "out:Op energy cost/MSEK": 57,
    "out:El price/MSEK": 33,
    "out:Heating price/MSEK": 20,
    "out:DH+ DHW/kWh/m2": 104,
    "out:El bought/kWh/m2": 744.654907,
    "out:Return %": 10,
    "out:Return/kSEK/y": 743,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 35.445,
    "out:EAHP (El)": 0,
    "out:PV (kr/kWp)": 17461.48474631725,
    "out:PV (kWp installed)": 90.5097,
    "out:PV (m2 panels)": 474.867263,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 84922.568824,
    "out:PV (kWh sold)": 63894.192746,
    "out:PV (% sold (El))": 75.238177,
    "out:PV (kWh self-consumed)": 21028.376078,
    "out:PV (ratio sold/self-consumed)": 3.038475,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 978564.8401067974,
    "out:FTX (Diffusers)": 206476.5625,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 208619.477443,
    "out:EL kWh savings": 6385.163023,
    "out:Sewage heat recovery cost": 273216,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 317231.09,
    "out:DH kr savings": 208619.4774429308,
    "out:El kr savings": 12770.326045670818,
    "out:El kr sold": 95841.28911946694,
    "out:El kr saved": 42056.752156,
    "out:El kr return": 137898.04127546694,
    "out:% solar/total": 53,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 218438.612795,
    "out:% savings (space heating)": 46.348321,
    "out:Total_Water Heating (DH)": 112856.846044,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 997124.4110739925,
    "out:Eupp": 68.61,
    "out:Etvv": 21.25,
    "out:Ef": 10.56,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 7702942.046311936,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 37.438336,
    "out:EL CO2": -100.475122,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -101.871982,
    "out:Bought CO2": 1.39686,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02000000020001110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 64.614518,
    "out:DHW (DH)": 35.445,
    "out:Property Electricity": 17.169,
    "out:Electricity (inc PV)": 10.564609,
    "out:Total Energy Use Pre PV": 117.169,
    "out:Total Energy Use Post PV": 110.564609,
    "out:Primary Energy": 84.147032,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 46.91807,
    "out:CO2 Operational/m2": -64.476722,
    "out:Total CO2/m2": -17.558652,
    "out:Total CO2 (tons)": -55.906731,
    "out:Klimatpaverkan": -256.042152,
    "out:Initial Cost/MSEK": 7.782542021311936,
    "out:Running cost/(Apt SEK y)": 19851.106383,
    "out:Running Cost over RSP/MSEK": 7.31737,
    "out:LCP/MSEK": -1.695192,
    "out:ROI% old": 11.174002,
    "out:Payback discounted": 11,
    "out:Atemp": 3184,
    "out:Umean": 0.55,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 111,
    "out:Energy use kWh/m2": 111,
    "out:Energy savings %": 64.9,
    "out:Op energy cost/MSEK": 54,
    "out:El price/MSEK": 32,
    "out:Heating price/MSEK": 19,
    "out:DH+ DHW/kWh/m2": 100,
    "out:El bought/kWh/m2": 744.654907,
    "out:Return %": 10,
    "out:Return/kSEK/y": 782,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 35.445,
    "out:EAHP (El)": 0,
    "out:PV (kr/kWp)": 17461.48474631725,
    "out:PV (kWp installed)": 90.5097,
    "out:PV (m2 panels)": 474.867263,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 84922.568824,
    "out:PV (kWh sold)": 63894.192746,
    "out:PV (% sold (El))": 75.238177,
    "out:PV (kWh self-consumed)": 21028.376078,
    "out:PV (ratio sold/self-consumed)": 3.038475,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 978564.8401067974,
    "out:FTX (Diffusers)": 206476.5625,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 221325.525871,
    "out:EL kWh savings": 6385.163023,
    "out:Sewage heat recovery cost": 273216,
    "out:Balancing hydronic system cost": 79599.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 329937.14,
    "out:DH kr savings": 221325.52587114144,
    "out:El kr savings": 12770.326045670818,
    "out:El kr sold": 95841.28911946694,
    "out:El kr saved": 42056.752156,
    "out:El kr return": 137898.04127546694,
    "out:% solar/total": 53,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 205732.564366,
    "out:% savings (space heating)": 49.46911,
    "out:Total_Water Heating (DH)": 112856.846044,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 997124.4110739925,
    "out:Eupp": 64.61,
    "out:Etvv": 21.25,
    "out:Ef": 10.56,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 7782542.021311936,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 35.9984,
    "out:EL CO2": -100.475122,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -101.871982,
    "out:Bought CO2": 1.39686,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02000000100000010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 68.605113,
    "out:DHW (DH)": 33.36,
    "out:Property Electricity": 17.169,
    "out:Electricity (inc PV)": 17.169,
    "out:Total Energy Use Pre PV": 119.169,
    "out:Total Energy Use Post PV": 119.169,
    "out:Primary Energy": 98.263732,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 16.731328,
    "out:CO2 Operational/m2": 38.984813,
    "out:Total CO2/m2": 55.716141,
    "out:Total CO2 (tons)": 177.40014,
    "out:Klimatpaverkan": -22.735281,
    "out:Initial Cost/MSEK": 5.973339987868186,
    "out:Running cost/(Apt SEK y)": 23194.276596,
    "out:Running Cost over RSP/MSEK": 8.51677,
    "out:LCP/MSEK": -1.08539,
    "out:ROI% old": 11.689918,
    "out:Payback discounted": 10,
    "out:Atemp": 3184,
    "out:Umean": 0.55,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 119,
    "out:Energy use kWh/m2": 119,
    "out:Energy savings %": 53.8,
    "out:Op energy cost/MSEK": 62,
    "out:El price/MSEK": 39,
    "out:Heating price/MSEK": 20,
    "out:DH+ DHW/kWh/m2": 102,
    "out:El bought/kWh/m2": 1201.83,
    "out:Return %": 10,
    "out:Return/kSEK/y": 625,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 33.36,
    "out:EAHP (El)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 978564.8401067974,
    "out:FTX (Diffusers)": 206476.5625,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 215258.115446,
    "out:EL kWh savings": -14643.211594,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 185971.69,
    "out:DH kr savings": 215258.11544550076,
    "out:El kr savings": -29286.423188315977,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 218438.612795,
    "out:% savings (space heating)": 46.348321,
    "out:Total_Water Heating (DH)": 106218.208041,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 997124.4110739925,
    "out:Eupp": 68.61,
    "out:Etvv": 20,
    "out:Ef": 17.17,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 5973339.987868186,
    "out:Seasonal Variation ROI (%)": 2,
    "out:Seasonal Variation Payback": 50,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 36.718368,
    "out:EL CO2": 2.266445,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 2.266445,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02000000100000110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 64.614518,
    "out:DHW (DH)": 33.36,
    "out:Property Electricity": 17.169,
    "out:Electricity (inc PV)": 17.169,
    "out:Total Energy Use Pre PV": 115.169,
    "out:Total Energy Use Post PV": 115.169,
    "out:Primary Energy": 95.159936,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 16.731328,
    "out:CO2 Operational/m2": 37.544877,
    "out:Total CO2/m2": 54.276205,
    "out:Total CO2 (tons)": 172.815385,
    "out:Klimatpaverkan": -27.320036,
    "out:Initial Cost/MSEK": 6.052939962868186,
    "out:Running cost/(Apt SEK y)": 22375.93617,
    "out:Running Cost over RSP/MSEK": 8.21592,
    "out:LCP/MSEK": -0.86414,
    "out:ROI% old": 12.246233,
    "out:Payback discounted": 10,
    "out:Atemp": 3184,
    "out:Umean": 0.55,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 115,
    "out:Energy use kWh/m2": 115,
    "out:Energy savings %": 59.1,
    "out:Op energy cost/MSEK": 60,
    "out:El price/MSEK": 37,
    "out:Heating price/MSEK": 19,
    "out:DH+ DHW/kWh/m2": 98,
    "out:El bought/kWh/m2": 1201.83,
    "out:Return %": 11,
    "out:Return/kSEK/y": 663,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 33.36,
    "out:EAHP (El)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 978564.8401067974,
    "out:FTX (Diffusers)": 206476.5625,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 227964.163874,
    "out:EL kWh savings": -14643.211594,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 79599.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 198677.74,
    "out:DH kr savings": 227964.16387371146,
    "out:El kr savings": -29286.423188315977,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 205732.564366,
    "out:% savings (space heating)": 49.46911,
    "out:Total_Water Heating (DH)": 106218.208041,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 997124.4110739925,
    "out:Eupp": 64.61,
    "out:Etvv": 20,
    "out:Ef": 17.17,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 6052939.962868187,
    "out:Seasonal Variation ROI (%)": 2,
    "out:Seasonal Variation Payback": 50,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 35.278432,
    "out:EL CO2": 2.266445,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 2.266445,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02000000100001010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 68.605113,
    "out:DHW (DH)": 28.356,
    "out:Property Electricity": 17.169,
    "out:Electricity (inc PV)": 17.169,
    "out:Total Energy Use Pre PV": 114.169,
    "out:Total Energy Use Post PV": 114.169,
    "out:Primary Energy": 96.163732,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 17.439871,
    "out:CO2 Operational/m2": 37.184893,
    "out:Total CO2/m2": 54.624764,
    "out:Total CO2 (tons)": 173.925196,
    "out:Klimatpaverkan": -26.210225,
    "out:Initial Cost/MSEK": 6.246555987868186,
    "out:Running cost/(Apt SEK y)": 22171.340426,
    "out:Running Cost over RSP/MSEK": 8.14071,
    "out:LCP/MSEK": -0.982546,
    "out:ROI% old": 12.038657,
    "out:Payback discounted": 10,
    "out:Atemp": 3184,
    "out:Umean": 0.55,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 114,
    "out:Energy use kWh/m2": 114,
    "out:Energy savings %": 60.5,
    "out:Op energy cost/MSEK": 59,
    "out:El price/MSEK": 37,
    "out:Heating price/MSEK": 19,
    "out:DH+ DHW/kWh/m2": 97,
    "out:El bought/kWh/m2": 1201.83,
    "out:Return %": 11,
    "out:Return/kSEK/y": 673,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 28.356,
    "out:EAHP (El)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 978564.8401067974,
    "out:FTX (Diffusers)": 206476.5625,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 231190.846652,
    "out:EL kWh savings": -14643.211594,
    "out:Sewage heat recovery cost": 273216,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 201904.42,
    "out:DH kr savings": 231190.84665166878,
    "out:El kr savings": -29286.423188315977,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 218438.612795,
    "out:% savings (space heating)": 46.348321,
    "out:Total_Water Heating (DH)": 90285.476835,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 997124.4110739925,
    "out:Eupp": 68.61,
    "out:Etvv": 17,
    "out:Ef": 17.17,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 6246555.987868186,
    "out:Seasonal Variation ROI (%)": 2,
    "out:Seasonal Variation Payback": 50,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 34.918448,
    "out:EL CO2": 2.266445,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 2.266445,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02000000100001110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 64.614518,
    "out:DHW (DH)": 28.356,
    "out:Property Electricity": 17.169,
    "out:Electricity (inc PV)": 17.169,
    "out:Total Energy Use Pre PV": 110.169,
    "out:Total Energy Use Post PV": 110.169,
    "out:Primary Energy": 93.059936,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 17.439871,
    "out:CO2 Operational/m2": 35.744957,
    "out:Total CO2/m2": 53.184828,
    "out:Total CO2 (tons)": 169.340441,
    "out:Klimatpaverkan": -30.79498,
    "out:Initial Cost/MSEK": 6.326155962868186,
    "out:Running cost/(Apt SEK y)": 21352.978723,
    "out:Running Cost over RSP/MSEK": 7.83987,
    "out:LCP/MSEK": -0.761306,
    "out:ROI% old": 12.566534,
    "out:Payback discounted": 10,
    "out:Atemp": 3184,
    "out:Umean": 0.55,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 110,
    "out:Energy use kWh/m2": 110,
    "out:Energy savings %": 66.4,
    "out:Op energy cost/MSEK": 57,
    "out:El price/MSEK": 36,
    "out:Heating price/MSEK": 18,
    "out:DH+ DHW/kWh/m2": 93,
    "out:El bought/kWh/m2": 1201.83,
    "out:Return %": 11,
    "out:Return/kSEK/y": 711,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 28.356,
    "out:EAHP (El)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 978564.8401067974,
    "out:FTX (Diffusers)": 206476.5625,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 243896.89508,
    "out:EL kWh savings": -14643.211594,
    "out:Sewage heat recovery cost": 273216,
    "out:Balancing hydronic system cost": 79599.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 214610.47,
    "out:DH kr savings": 243896.89507987944,
    "out:El kr savings": -29286.423188315977,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 205732.564366,
    "out:% savings (space heating)": 49.46911,
    "out:Total_Water Heating (DH)": 90285.476835,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 997124.4110739925,
    "out:Eupp": 64.61,
    "out:Etvv": 17,
    "out:Ef": 17.17,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 6326155.962868186,
    "out:Seasonal Variation ROI (%)": 2,
    "out:Seasonal Variation Payback": 50,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 33.478512,
    "out:EL CO2": 2.266445,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 2.266445,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02000000110000010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 68.605113,
    "out:DHW (DH)": 33.36,
    "out:Property Electricity": 17.169,
    "out:Electricity (inc PV)": 11.433081,
    "out:Total Energy Use Pre PV": 119.169,
    "out:Total Energy Use Post PV": 113.433081,
    "out:Primary Energy": 87.939078,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 31.470428,
    "out:CO2 Operational/m2": -0.173105,
    "out:Total CO2/m2": 31.297323,
    "out:Total CO2 (tons)": 99.650646,
    "out:Klimatpaverkan": -100.484775,
    "out:Initial Cost/MSEK": 6.763560382090686,
    "out:Running cost/(Apt SEK y)": 21644.851064,
    "out:Running Cost over RSP/MSEK": 7.96254,
    "out:LCP/MSEK": -1.32138,
    "out:ROI% old": 11.494749,
    "out:Payback discounted": 11,
    "out:Atemp": 3184,
    "out:Umean": 0.55,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 113,
    "out:Energy use kWh/m2": 113,
    "out:Energy savings %": 61.9,
    "out:Op energy cost/MSEK": 59,
    "out:El price/MSEK": 35,
    "out:Heating price/MSEK": 20,
    "out:DH+ DHW/kWh/m2": 102,
    "out:El bought/kWh/m2": 807.631491,
    "out:Return %": 10,
    "out:Return/kSEK/y": 697,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 33.36,
    "out:EAHP (El)": 0,
    "out:PV (kr/kWp)": 17461.56255567083,
    "out:PV (kWp installed)": 45.25485,
    "out:PV (m2 panels)": 237.433632,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 42461.284501,
    "out:PV (kWh sold)": 24198.124428,
    "out:PV (% sold (El))": 56.988677,
    "out:PV (kWh self-consumed)": 18263.160073,
    "out:PV (ratio sold/self-consumed)": 1.324969,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 978564.8401067974,
    "out:FTX (Diffusers)": 206476.5625,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 215258.115446,
    "out:EL kWh savings": 3619.949007,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 258795.2,
    "out:DH kr savings": 215258.11544550076,
    "out:El kr savings": 7239.898013663189,
    "out:El kr sold": 36297.18664221638,
    "out:El kr saved": 36526.320146,
    "out:El kr return": 72823.50678821639,
    "out:% solar/total": 46,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 218438.612795,
    "out:% savings (space heating)": 46.348321,
    "out:Total_Water Heating (DH)": 106218.208041,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 997124.4110739925,
    "out:Eupp": 68.61,
    "out:Etvv": 20,
    "out:Ef": 11.43,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 6763560.382090686,
    "out:Seasonal Variation ROI (%)": 2,
    "out:Seasonal Variation Payback": 50,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 36.718368,
    "out:EL CO2": -36.891473,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -38.403999,
    "out:Bought CO2": 1.512526,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02000000110000110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 64.614518,
    "out:DHW (DH)": 33.36,
    "out:Property Electricity": 17.169,
    "out:Electricity (inc PV)": 11.433081,
    "out:Total Energy Use Pre PV": 115.169,
    "out:Total Energy Use Post PV": 109.433081,
    "out:Primary Energy": 84.835282,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 31.470428,
    "out:CO2 Operational/m2": -1.613041,
    "out:Total CO2/m2": 29.857387,
    "out:Total CO2 (tons)": 95.065892,
    "out:Klimatpaverkan": -105.069529,
    "out:Initial Cost/MSEK": 6.843160357090686,
    "out:Running cost/(Apt SEK y)": 20826.489362,
    "out:Running Cost over RSP/MSEK": 7.66169,
    "out:LCP/MSEK": -1.10013,
    "out:ROI% old": 11.989093,
    "out:Payback discounted": 10,
    "out:Atemp": 3184,
    "out:Umean": 0.55,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 109,
    "out:Energy use kWh/m2": 109,
    "out:Energy savings %": 67.9,
    "out:Op energy cost/MSEK": 56,
    "out:El price/MSEK": 34,
    "out:Heating price/MSEK": 19,
    "out:DH+ DHW/kWh/m2": 98,
    "out:El bought/kWh/m2": 807.631491,
    "out:Return %": 11,
    "out:Return/kSEK/y": 736,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 33.36,
    "out:EAHP (El)": 0,
    "out:PV (kr/kWp)": 17461.56255567083,
    "out:PV (kWp installed)": 45.25485,
    "out:PV (m2 panels)": 237.433632,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 42461.284501,
    "out:PV (kWh sold)": 24198.124428,
    "out:PV (% sold (El))": 56.988677,
    "out:PV (kWh self-consumed)": 18263.160073,
    "out:PV (ratio sold/self-consumed)": 1.324969,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 978564.8401067974,
    "out:FTX (Diffusers)": 206476.5625,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 227964.163874,
    "out:EL kWh savings": 3619.949007,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 79599.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 271501.25,
    "out:DH kr savings": 227964.16387371146,
    "out:El kr savings": 7239.898013663189,
    "out:El kr sold": 36297.18664221638,
    "out:El kr saved": 36526.320146,
    "out:El kr return": 72823.50678821639,
    "out:% solar/total": 46,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 205732.564366,
    "out:% savings (space heating)": 49.46911,
    "out:Total_Water Heating (DH)": 106218.208041,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 997124.4110739925,
    "out:Eupp": 64.61,
    "out:Etvv": 20,
    "out:Ef": 11.43,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 6843160.357090686,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 35.278432,
    "out:EL CO2": -36.891473,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -38.403999,
    "out:Bought CO2": 1.512526,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02000000110001010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 68.605113,
    "out:DHW (DH)": 28.356,
    "out:Property Electricity": 17.169,
    "out:Electricity (inc PV)": 11.433081,
    "out:Total Energy Use Pre PV": 114.169,
    "out:Total Energy Use Post PV": 108.433081,
    "out:Primary Energy": 85.839078,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 32.178971,
    "out:CO2 Operational/m2": -1.973025,
    "out:Total CO2/m2": 30.205946,
    "out:Total CO2 (tons)": 96.175703,
    "out:Klimatpaverkan": -103.959718,
    "out:Initial Cost/MSEK": 7.036776382090687,
    "out:Running cost/(Apt SEK y)": 20621.893617,
    "out:Running Cost over RSP/MSEK": 7.58648,
    "out:LCP/MSEK": -1.218536,
    "out:ROI% old": 11.811902,
    "out:Payback discounted": 10,
    "out:Atemp": 3184,
    "out:Umean": 0.55,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 108,
    "out:Energy use kWh/m2": 108,
    "out:Energy savings %": 69.4,
    "out:Op energy cost/MSEK": 56,
    "out:El price/MSEK": 34,
    "out:Heating price/MSEK": 19,
    "out:DH+ DHW/kWh/m2": 97,
    "out:El bought/kWh/m2": 807.631491,
    "out:Return %": 11,
    "out:Return/kSEK/y": 745,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 28.356,
    "out:EAHP (El)": 0,
    "out:PV (kr/kWp)": 17461.56255567083,
    "out:PV (kWp installed)": 45.25485,
    "out:PV (m2 panels)": 237.433632,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 42461.284501,
    "out:PV (kWh sold)": 24198.124428,
    "out:PV (% sold (El))": 56.988677,
    "out:PV (kWh self-consumed)": 18263.160073,
    "out:PV (ratio sold/self-consumed)": 1.324969,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 978564.8401067974,
    "out:FTX (Diffusers)": 206476.5625,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 231190.846652,
    "out:EL kWh savings": 3619.949007,
    "out:Sewage heat recovery cost": 273216,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 274727.93,
    "out:DH kr savings": 231190.84665166878,
    "out:El kr savings": 7239.898013663189,
    "out:El kr sold": 36297.18664221638,
    "out:El kr saved": 36526.320146,
    "out:El kr return": 72823.50678821639,
    "out:% solar/total": 46,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 218438.612795,
    "out:% savings (space heating)": 46.348321,
    "out:Total_Water Heating (DH)": 90285.476835,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 997124.4110739925,
    "out:Eupp": 68.61,
    "out:Etvv": 17,
    "out:Ef": 11.43,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 7036776.382090687,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 34.918448,
    "out:EL CO2": -36.891473,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -38.403999,
    "out:Bought CO2": 1.512526,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02000000110001110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 64.614518,
    "out:DHW (DH)": 28.356,
    "out:Property Electricity": 17.169,
    "out:Electricity (inc PV)": 11.433081,
    "out:Total Energy Use Pre PV": 110.169,
    "out:Total Energy Use Post PV": 104.433081,
    "out:Primary Energy": 82.735282,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 32.178971,
    "out:CO2 Operational/m2": -3.412961,
    "out:Total CO2/m2": 28.76601,
    "out:Total CO2 (tons)": 91.590948,
    "out:Klimatpaverkan": -108.544473,
    "out:Initial Cost/MSEK": 7.116376357090687,
    "out:Running cost/(Apt SEK y)": 19803.553191,
    "out:Running Cost over RSP/MSEK": 7.28564,
    "out:LCP/MSEK": -0.997296,
    "out:ROI% old": 12.283699,
    "out:Payback discounted": 10,
    "out:Atemp": 3184,
    "out:Umean": 0.55,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 104,
    "out:Energy use kWh/m2": 104,
    "out:Energy savings %": 76,
    "out:Op energy cost/MSEK": 54,
    "out:El price/MSEK": 32,
    "out:Heating price/MSEK": 18,
    "out:DH+ DHW/kWh/m2": 93,
    "out:El bought/kWh/m2": 807.631491,
    "out:Return %": 11,
    "out:Return/kSEK/y": 784,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 28.356,
    "out:EAHP (El)": 0,
    "out:PV (kr/kWp)": 17461.56255567083,
    "out:PV (kWp installed)": 45.25485,
    "out:PV (m2 panels)": 237.433632,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 42461.284501,
    "out:PV (kWh sold)": 24198.124428,
    "out:PV (% sold (El))": 56.988677,
    "out:PV (kWh self-consumed)": 18263.160073,
    "out:PV (ratio sold/self-consumed)": 1.324969,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 978564.8401067974,
    "out:FTX (Diffusers)": 206476.5625,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 243896.89508,
    "out:EL kWh savings": 3619.949007,
    "out:Sewage heat recovery cost": 273216,
    "out:Balancing hydronic system cost": 79599.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 287433.98,
    "out:DH kr savings": 243896.89507987944,
    "out:El kr savings": 7239.898013663189,
    "out:El kr sold": 36297.18664221638,
    "out:El kr saved": 36526.320146,
    "out:El kr return": 72823.50678821639,
    "out:% solar/total": 46,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 205732.564366,
    "out:% savings (space heating)": 49.46911,
    "out:Total_Water Heating (DH)": 90285.476835,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 997124.4110739925,
    "out:Eupp": 64.61,
    "out:Etvv": 17,
    "out:Ef": 11.43,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 7116376.357090686,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 33.478512,
    "out:EL CO2": -36.891473,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -38.403999,
    "out:Bought CO2": 1.512526,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02000000120000010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 68.605113,
    "out:DHW (DH)": 33.36,
    "out:Property Electricity": 17.169,
    "out:Electricity (inc PV)": 10.564609,
    "out:Total Energy Use Pre PV": 119.169,
    "out:Total Energy Use Post PV": 112.564609,
    "out:Primary Energy": 86.375828,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 46.209527,
    "out:CO2 Operational/m2": -63.756754,
    "out:Total CO2/m2": -17.547227,
    "out:Total CO2 (tons)": -55.870354,
    "out:Klimatpaverkan": -256.005775,
    "out:Initial Cost/MSEK": 7.553773733811937,
    "out:Running cost/(Apt SEK y)": 20260.276596,
    "out:Running Cost over RSP/MSEK": 7.46779,
    "out:LCP/MSEK": -1.616844,
    "out:ROI% old": 11.227936,
    "out:Payback discounted": 11,
    "out:Atemp": 3184,
    "out:Umean": 0.55,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 113,
    "out:Energy use kWh/m2": 113,
    "out:Energy savings %": 61.9,
    "out:Op energy cost/MSEK": 56,
    "out:El price/MSEK": 32,
    "out:Heating price/MSEK": 20,
    "out:DH+ DHW/kWh/m2": 102,
    "out:El bought/kWh/m2": 744.654907,
    "out:Return %": 10,
    "out:Return/kSEK/y": 762,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 33.36,
    "out:EAHP (El)": 0,
    "out:PV (kr/kWp)": 17461.48474631725,
    "out:PV (kWp installed)": 90.5097,
    "out:PV (m2 panels)": 474.867263,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 84922.568824,
    "out:PV (kWh sold)": 63894.192746,
    "out:PV (% sold (El))": 75.238177,
    "out:PV (kWh self-consumed)": 21028.376078,
    "out:PV (ratio sold/self-consumed)": 3.038475,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 978564.8401067974,
    "out:FTX (Diffusers)": 206476.5625,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 215258.115446,
    "out:EL kWh savings": 6385.163023,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 323869.73,
    "out:DH kr savings": 215258.11544550076,
    "out:El kr savings": 12770.326045670818,
    "out:El kr sold": 95841.28911946694,
    "out:El kr saved": 42056.752156,
    "out:El kr return": 137898.04127546694,
    "out:% solar/total": 53,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 218438.612795,
    "out:% savings (space heating)": 46.348321,
    "out:Total_Water Heating (DH)": 106218.208041,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 997124.4110739925,
    "out:Eupp": 68.61,
    "out:Etvv": 20,
    "out:Ef": 10.56,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 7553773.733811937,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 36.718368,
    "out:EL CO2": -100.475122,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -101.871982,
    "out:Bought CO2": 1.39686,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02000000120000110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 64.614518,
    "out:DHW (DH)": 33.36,
    "out:Property Electricity": 17.169,
    "out:Electricity (inc PV)": 10.564609,
    "out:Total Energy Use Pre PV": 115.169,
    "out:Total Energy Use Post PV": 108.564609,
    "out:Primary Energy": 83.272032,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 46.209527,
    "out:CO2 Operational/m2": -65.19669,
    "out:Total CO2/m2": -18.987163,
    "out:Total CO2 (tons)": -60.455109,
    "out:Klimatpaverkan": -260.59053,
    "out:Initial Cost/MSEK": 7.633373708811937,
    "out:Running cost/(Apt SEK y)": 19441.914894,
    "out:Running Cost over RSP/MSEK": 7.16694,
    "out:LCP/MSEK": -1.395594,
    "out:ROI% old": 11.673887,
    "out:Payback discounted": 10,
    "out:Atemp": 3184,
    "out:Umean": 0.55,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 109,
    "out:Energy use kWh/m2": 109,
    "out:Energy savings %": 67.9,
    "out:Op energy cost/MSEK": 53,
    "out:El price/MSEK": 31,
    "out:Heating price/MSEK": 19,
    "out:DH+ DHW/kWh/m2": 98,
    "out:El bought/kWh/m2": 744.654907,
    "out:Return %": 10,
    "out:Return/kSEK/y": 801,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 33.36,
    "out:EAHP (El)": 0,
    "out:PV (kr/kWp)": 17461.48474631725,
    "out:PV (kWp installed)": 90.5097,
    "out:PV (m2 panels)": 474.867263,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 84922.568824,
    "out:PV (kWh sold)": 63894.192746,
    "out:PV (% sold (El))": 75.238177,
    "out:PV (kWh self-consumed)": 21028.376078,
    "out:PV (ratio sold/self-consumed)": 3.038475,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 978564.8401067974,
    "out:FTX (Diffusers)": 206476.5625,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 227964.163874,
    "out:EL kWh savings": 6385.163023,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 79599.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 336575.78,
    "out:DH kr savings": 227964.16387371146,
    "out:El kr savings": 12770.326045670818,
    "out:El kr sold": 95841.28911946694,
    "out:El kr saved": 42056.752156,
    "out:El kr return": 137898.04127546694,
    "out:% solar/total": 53,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 205732.564366,
    "out:% savings (space heating)": 49.46911,
    "out:Total_Water Heating (DH)": 106218.208041,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 997124.4110739925,
    "out:Eupp": 64.61,
    "out:Etvv": 20,
    "out:Ef": 10.56,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 7633373.708811937,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 35.278432,
    "out:EL CO2": -100.475122,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -101.871982,
    "out:Bought CO2": 1.39686,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02000000120001010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 68.605113,
    "out:DHW (DH)": 28.356,
    "out:Property Electricity": 17.169,
    "out:Electricity (inc PV)": 10.564609,
    "out:Total Energy Use Pre PV": 114.169,
    "out:Total Energy Use Post PV": 107.564609,
    "out:Primary Energy": 84.275828,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 46.91807,
    "out:CO2 Operational/m2": -65.556674,
    "out:Total CO2/m2": -18.638604,
    "out:Total CO2 (tons)": -59.345297,
    "out:Klimatpaverkan": -259.480718,
    "out:Initial Cost/MSEK": 7.826989733811935,
    "out:Running cost/(Apt SEK y)": 19237.340426,
    "out:Running Cost over RSP/MSEK": 7.09173,
    "out:LCP/MSEK": -1.514,
    "out:ROI% old": 11.522383,
    "out:Payback discounted": 10,
    "out:Atemp": 3184,
    "out:Umean": 0.55,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 108,
    "out:Energy use kWh/m2": 108,
    "out:Energy savings %": 69.4,
    "out:Op energy cost/MSEK": 53,
    "out:El price/MSEK": 31,
    "out:Heating price/MSEK": 19,
    "out:DH+ DHW/kWh/m2": 97,
    "out:El bought/kWh/m2": 744.654907,
    "out:Return %": 10,
    "out:Return/kSEK/y": 811,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 28.356,
    "out:EAHP (El)": 0,
    "out:PV (kr/kWp)": 17461.48474631725,
    "out:PV (kWp installed)": 90.5097,
    "out:PV (m2 panels)": 474.867263,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 84922.568824,
    "out:PV (kWh sold)": 63894.192746,
    "out:PV (% sold (El))": 75.238177,
    "out:PV (kWh self-consumed)": 21028.376078,
    "out:PV (ratio sold/self-consumed)": 3.038475,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 978564.8401067974,
    "out:FTX (Diffusers)": 206476.5625,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 231190.846652,
    "out:EL kWh savings": 6385.163023,
    "out:Sewage heat recovery cost": 273216,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 339802.46,
    "out:DH kr savings": 231190.84665166878,
    "out:El kr savings": 12770.326045670818,
    "out:El kr sold": 95841.28911946694,
    "out:El kr saved": 42056.752156,
    "out:El kr return": 137898.04127546694,
    "out:% solar/total": 53,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 218438.612795,
    "out:% savings (space heating)": 46.348321,
    "out:Total_Water Heating (DH)": 90285.476835,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 997124.4110739925,
    "out:Eupp": 68.61,
    "out:Etvv": 17,
    "out:Ef": 10.56,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 7826989.733811935,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 34.918448,
    "out:EL CO2": -100.475122,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -101.871982,
    "out:Bought CO2": 1.39686,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02000000120001110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 64.614518,
    "out:DHW (DH)": 28.356,
    "out:Property Electricity": 17.169,
    "out:Electricity (inc PV)": 10.564609,
    "out:Total Energy Use Pre PV": 110.169,
    "out:Total Energy Use Post PV": 103.564609,
    "out:Primary Energy": 81.172032,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 46.91807,
    "out:CO2 Operational/m2": -66.99661,
    "out:Total CO2/m2": -20.07854,
    "out:Total CO2 (tons)": -63.930052,
    "out:Klimatpaverkan": -264.065473,
    "out:Initial Cost/MSEK": 7.906589708811937,
    "out:Running cost/(Apt SEK y)": 18418.978723,
    "out:Running Cost over RSP/MSEK": 6.79089,
    "out:LCP/MSEK": -1.29276,
    "out:ROI% old": 11.949942,
    "out:Payback discounted": 10,
    "out:Atemp": 3184,
    "out:Umean": 0.55,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 104,
    "out:Energy use kWh/m2": 104,
    "out:Energy savings %": 76,
    "out:Op energy cost/MSEK": 51,
    "out:El price/MSEK": 29,
    "out:Heating price/MSEK": 18,
    "out:DH+ DHW/kWh/m2": 93,
    "out:El bought/kWh/m2": 744.654907,
    "out:Return %": 11,
    "out:Return/kSEK/y": 849,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 28.356,
    "out:EAHP (El)": 0,
    "out:PV (kr/kWp)": 17461.48474631725,
    "out:PV (kWp installed)": 90.5097,
    "out:PV (m2 panels)": 474.867263,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 84922.568824,
    "out:PV (kWh sold)": 63894.192746,
    "out:PV (% sold (El))": 75.238177,
    "out:PV (kWh self-consumed)": 21028.376078,
    "out:PV (ratio sold/self-consumed)": 3.038475,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 978564.8401067974,
    "out:FTX (Diffusers)": 206476.5625,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.67,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 243896.89508,
    "out:EL kWh savings": 6385.163023,
    "out:Sewage heat recovery cost": 273216,
    "out:Balancing hydronic system cost": 79599.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 352508.51,
    "out:DH kr savings": 243896.89507987944,
    "out:El kr savings": 12770.326045670818,
    "out:El kr sold": 95841.28911946694,
    "out:El kr saved": 42056.752156,
    "out:El kr return": 137898.04127546694,
    "out:% solar/total": 53,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 205732.564366,
    "out:% savings (space heating)": 49.46911,
    "out:Total_Water Heating (DH)": 90285.476835,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 997124.4110739925,
    "out:Eupp": 64.61,
    "out:Etvv": 17,
    "out:Ef": 10.56,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 7906589.708811937,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 33.478512,
    "out:EL CO2": -100.475122,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -101.871982,
    "out:Bought CO2": 1.39686,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02001000000000010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 50.69497,
    "out:DHW (DH)": 41.7,
    "out:Property Electricity": 17.169,
    "out:Electricity (inc PV)": 17.169,
    "out:Total Energy Use Pre PV": 109.169,
    "out:Total Energy Use Post PV": 109.169,
    "out:Primary Energy": 87.833621,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 18.149838,
    "out:CO2 Operational/m2": 35.384973,
    "out:Total CO2/m2": 53.534811,
    "out:Total CO2 (tons)": 170.454787,
    "out:Klimatpaverkan": -29.680634,
    "out:Initial Cost/MSEK": 6.106504153371937,
    "out:Running cost/(Apt SEK y)": 21148.382979,
    "out:Running Cost over RSP/MSEK": 7.76466,
    "out:LCP/MSEK": -0.466444,
    "out:ROI% old": 13.194503,
    "out:Payback discounted": 9,
    "out:Atemp": 3184,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 109,
    "out:Energy use kWh/m2": 109,
    "out:Energy savings %": 67.9,
    "out:Op energy cost/MSEK": 56,
    "out:El price/MSEK": 36,
    "out:Heating price/MSEK": 18,
    "out:DH+ DHW/kWh/m2": 92,
    "out:El bought/kWh/m2": 1201.83,
    "out:Return %": 12,
    "out:Return/kSEK/y": 721,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 41.7,
    "out:EAHP (El)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 978564.8401067974,
    "out:FTX (Diffusers)": 206476.5625,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 245729.440953,
    "out:EL kWh savings": -14643.211594,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 216443.02,
    "out:DH kr savings": 245729.44095250397,
    "out:El kr savings": -29286.423188315977,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 161412.735277,
    "out:% savings (space heating)": 60.354701,
    "out:Total_Water Heating (DH)": 132772.760051,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 997124.4110739925,
    "out:Eupp": 50.69,
    "out:Etvv": 25,
    "out:Ef": 17.17,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 6106504.153371937,
    "out:Seasonal Variation ROI (%)": 2,
    "out:Seasonal Variation Payback": 50,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 33.118528,
    "out:EL CO2": 2.266445,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 2.266445,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02001000000000110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 47.518473,
    "out:DHW (DH)": 41.7,
    "out:Property Electricity": 17.169,
    "out:Electricity (inc PV)": 17.169,
    "out:Total Energy Use Pre PV": 106.169,
    "out:Total Energy Use Post PV": 106.169,
    "out:Primary Energy": 85.363012,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 18.149838,
    "out:CO2 Operational/m2": 34.305021,
    "out:Total CO2/m2": 52.454859,
    "out:Total CO2 (tons)": 167.016221,
    "out:Klimatpaverkan": -33.1192,
    "out:Initial Cost/MSEK": 6.186104128371936,
    "out:Running cost/(Apt SEK y)": 20534.617021,
    "out:Running Cost over RSP/MSEK": 7.53902,
    "out:LCP/MSEK": -0.320404,
    "out:ROI% old": 13.545798,
    "out:Payback discounted": 9,
    "out:Atemp": 3184,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 106,
    "out:Energy use kWh/m2": 106,
    "out:Energy savings %": 72.6,
    "out:Op energy cost/MSEK": 55,
    "out:El price/MSEK": 35,
    "out:Heating price/MSEK": 17,
    "out:DH+ DHW/kWh/m2": 89,
    "out:El bought/kWh/m2": 1201.83,
    "out:Return %": 12,
    "out:Return/kSEK/y": 750,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 41.7,
    "out:EAHP (El)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 978564.8401067974,
    "out:FTX (Diffusers)": 206476.5625,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 255843.404039,
    "out:EL kWh savings": -14643.211594,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 79599.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 226556.98,
    "out:DH kr savings": 255843.40403901995,
    "out:El kr savings": -29286.423188315977,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 151298.772191,
    "out:% savings (space heating)": 62.838836,
    "out:Total_Water Heating (DH)": 132772.760051,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 997124.4110739925,
    "out:Eupp": 47.52,
    "out:Etvv": 25,
    "out:Ef": 17.17,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 6186104.128371935,
    "out:Seasonal Variation ROI (%)": 2,
    "out:Seasonal Variation Payback": 50,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 32.038576,
    "out:EL CO2": 2.266445,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 2.266445,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02001000000001010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 50.69497,
    "out:DHW (DH)": 35.445,
    "out:Property Electricity": 17.169,
    "out:Electricity (inc PV)": 17.169,
    "out:Total Energy Use Pre PV": 103.169,
    "out:Total Energy Use Post PV": 103.169,
    "out:Primary Energy": 85.208621,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 18.858381,
    "out:CO2 Operational/m2": 33.225069,
    "out:Total CO2/m2": 52.08345,
    "out:Total CO2 (tons)": 165.833655,
    "out:Klimatpaverkan": -34.301766,
    "out:Initial Cost/MSEK": 6.379720153371935,
    "out:Running cost/(Apt SEK y)": 19920.851064,
    "out:Running Cost over RSP/MSEK": 7.31339,
    "out:LCP/MSEK": -0.28839,
    "out:ROI% old": 13.63994,
    "out:Payback discounted": 9,
    "out:Atemp": 3184,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 103,
    "out:Energy use kWh/m2": 103,
    "out:Energy savings %": 77.7,
    "out:Op energy cost/MSEK": 53,
    "out:El price/MSEK": 34,
    "out:Heating price/MSEK": 17,
    "out:DH+ DHW/kWh/m2": 86,
    "out:El bought/kWh/m2": 1201.83,
    "out:Return %": 12,
    "out:Return/kSEK/y": 778,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 35.445,
    "out:EAHP (El)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 978564.8401067974,
    "out:FTX (Diffusers)": 206476.5625,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 265645.35496,
    "out:EL kWh savings": -14643.211594,
    "out:Sewage heat recovery cost": 273216,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 236358.93,
    "out:DH kr savings": 265645.35496021394,
    "out:El kr savings": -29286.423188315977,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 161412.735277,
    "out:% savings (space heating)": 60.354701,
    "out:Total_Water Heating (DH)": 112856.846044,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 997124.4110739925,
    "out:Eupp": 50.69,
    "out:Etvv": 21.25,
    "out:Ef": 17.17,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 6379720.153371936,
    "out:Seasonal Variation ROI (%)": 2,
    "out:Seasonal Variation Payback": 50,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 30.958624,
    "out:EL CO2": 2.266445,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 2.266445,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02001000000001110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 47.518473,
    "out:DHW (DH)": 35.445,
    "out:Property Electricity": 17.169,
    "out:Electricity (inc PV)": 17.169,
    "out:Total Energy Use Pre PV": 100.169,
    "out:Total Energy Use Post PV": 100.169,
    "out:Primary Energy": 82.738012,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 18.858381,
    "out:CO2 Operational/m2": 32.145117,
    "out:Total CO2/m2": 51.003498,
    "out:Total CO2 (tons)": 162.395089,
    "out:Klimatpaverkan": -37.740332,
    "out:Initial Cost/MSEK": 6.459320128371937,
    "out:Running cost/(Apt SEK y)": 19307.085106,
    "out:Running Cost over RSP/MSEK": 7.08775,
    "out:LCP/MSEK": -0.14235,
    "out:ROI% old": 13.970887,
    "out:Payback discounted": 9,
    "out:Atemp": 3184,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 100,
    "out:Energy use kWh/m2": 100,
    "out:Energy savings %": 83,
    "out:Op energy cost/MSEK": 51,
    "out:El price/MSEK": 33,
    "out:Heating price/MSEK": 16,
    "out:DH+ DHW/kWh/m2": 83,
    "out:El bought/kWh/m2": 1201.83,
    "out:Return %": 12,
    "out:Return/kSEK/y": 807,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 35.445,
    "out:EAHP (El)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 978564.8401067974,
    "out:FTX (Diffusers)": 206476.5625,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 275759.318047,
    "out:EL kWh savings": -14643.211594,
    "out:Sewage heat recovery cost": 273216,
    "out:Balancing hydronic system cost": 79599.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 246472.89,
    "out:DH kr savings": 275759.31804672995,
    "out:El kr savings": -29286.423188315977,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 151298.772191,
    "out:% savings (space heating)": 62.838836,
    "out:Total_Water Heating (DH)": 112856.846044,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 997124.4110739925,
    "out:Eupp": 47.52,
    "out:Etvv": 21.25,
    "out:Ef": 17.17,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 6459320.128371937,
    "out:Seasonal Variation ROI (%)": 2,
    "out:Seasonal Variation Payback": 50,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 29.878672,
    "out:EL CO2": 2.266445,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 2.266445,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02001000010000010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 50.69497,
    "out:DHW (DH)": 41.7,
    "out:Property Electricity": 17.169,
    "out:Electricity (inc PV)": 11.433081,
    "out:Total Energy Use Pre PV": 109.169,
    "out:Total Energy Use Post PV": 103.433081,
    "out:Primary Energy": 77.508967,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 32.888938,
    "out:CO2 Operational/m2": -3.772945,
    "out:Total CO2/m2": 29.115993,
    "out:Total CO2 (tons)": 92.705294,
    "out:Klimatpaverkan": -107.430127,
    "out:Initial Cost/MSEK": 6.896724547594436,
    "out:Running cost/(Apt SEK y)": 19598.957447,
    "out:Running Cost over RSP/MSEK": 7.21043,
    "out:LCP/MSEK": -0.702435,
    "out:ROI% old": 12.830708,
    "out:Payback discounted": 9,
    "out:Atemp": 3184,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 103,
    "out:Energy use kWh/m2": 103,
    "out:Energy savings %": 77.7,
    "out:Op energy cost/MSEK": 53,
    "out:El price/MSEK": 32,
    "out:Heating price/MSEK": 18,
    "out:DH+ DHW/kWh/m2": 92,
    "out:El bought/kWh/m2": 807.631491,
    "out:Return %": 12,
    "out:Return/kSEK/y": 794,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 41.7,
    "out:EAHP (El)": 0,
    "out:PV (kr/kWp)": 17461.56255567083,
    "out:PV (kWp installed)": 45.25485,
    "out:PV (m2 panels)": 237.433632,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 42461.284501,
    "out:PV (kWh sold)": 24198.124428,
    "out:PV (% sold (El))": 56.988677,
    "out:PV (kWh self-consumed)": 18263.160073,
    "out:PV (ratio sold/self-consumed)": 1.324969,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 978564.8401067974,
    "out:FTX (Diffusers)": 206476.5625,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 245729.440953,
    "out:EL kWh savings": 3619.949007,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 289266.53,
    "out:DH kr savings": 245729.44095250397,
    "out:El kr savings": 7239.898013663189,
    "out:El kr sold": 36297.18664221638,
    "out:El kr saved": 36526.320146,
    "out:El kr return": 72823.50678821639,
    "out:% solar/total": 46,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 161412.735277,
    "out:% savings (space heating)": 60.354701,
    "out:Total_Water Heating (DH)": 132772.760051,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 997124.4110739925,
    "out:Eupp": 50.69,
    "out:Etvv": 25,
    "out:Ef": 11.43,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 6896724.547594436,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 33.118528,
    "out:EL CO2": -36.891473,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -38.403999,
    "out:Bought CO2": 1.512526,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02001000010000110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 47.518473,
    "out:DHW (DH)": 41.7,
    "out:Property Electricity": 17.169,
    "out:Electricity (inc PV)": 11.433081,
    "out:Total Energy Use Pre PV": 106.169,
    "out:Total Energy Use Post PV": 100.433081,
    "out:Primary Energy": 75.038358,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 32.888938,
    "out:CO2 Operational/m2": -4.852897,
    "out:Total CO2/m2": 28.036041,
    "out:Total CO2 (tons)": 89.266728,
    "out:Klimatpaverkan": -110.868693,
    "out:Initial Cost/MSEK": 6.976324522594437,
    "out:Running cost/(Apt SEK y)": 18985.191489,
    "out:Running Cost over RSP/MSEK": 6.9848,
    "out:LCP/MSEK": -0.556405,
    "out:ROI% old": 13.146341,
    "out:Payback discounted": 9,
    "out:Atemp": 3184,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 100,
    "out:Energy use kWh/m2": 100,
    "out:Energy savings %": 83,
    "out:Op energy cost/MSEK": 51,
    "out:El price/MSEK": 31,
    "out:Heating price/MSEK": 17,
    "out:DH+ DHW/kWh/m2": 89,
    "out:El bought/kWh/m2": 807.631491,
    "out:Return %": 12,
    "out:Return/kSEK/y": 822,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 41.7,
    "out:EAHP (El)": 0,
    "out:PV (kr/kWp)": 17461.56255567083,
    "out:PV (kWp installed)": 45.25485,
    "out:PV (m2 panels)": 237.433632,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 42461.284501,
    "out:PV (kWh sold)": 24198.124428,
    "out:PV (% sold (El))": 56.988677,
    "out:PV (kWh self-consumed)": 18263.160073,
    "out:PV (ratio sold/self-consumed)": 1.324969,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 978564.8401067974,
    "out:FTX (Diffusers)": 206476.5625,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 255843.404039,
    "out:EL kWh savings": 3619.949007,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 79599.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 299380.49,
    "out:DH kr savings": 255843.40403901995,
    "out:El kr savings": 7239.898013663189,
    "out:El kr sold": 36297.18664221638,
    "out:El kr saved": 36526.320146,
    "out:El kr return": 72823.50678821639,
    "out:% solar/total": 46,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 151298.772191,
    "out:% savings (space heating)": 62.838836,
    "out:Total_Water Heating (DH)": 132772.760051,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 997124.4110739925,
    "out:Eupp": 47.52,
    "out:Etvv": 25,
    "out:Ef": 11.43,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 6976324.522594437,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 32.038576,
    "out:EL CO2": -36.891473,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -38.403999,
    "out:Bought CO2": 1.512526,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02001000010001010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 50.69497,
    "out:DHW (DH)": 35.445,
    "out:Property Electricity": 17.169,
    "out:Electricity (inc PV)": 11.433081,
    "out:Total Energy Use Pre PV": 103.169,
    "out:Total Energy Use Post PV": 97.433081,
    "out:Primary Energy": 74.883967,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 33.597481,
    "out:CO2 Operational/m2": -5.932849,
    "out:Total CO2/m2": 27.664632,
    "out:Total CO2 (tons)": 88.084162,
    "out:Klimatpaverkan": -112.051259,
    "out:Initial Cost/MSEK": 7.169940547594437,
    "out:Running cost/(Apt SEK y)": 18371.425532,
    "out:Running Cost over RSP/MSEK": 6.75916,
    "out:LCP/MSEK": -0.524381,
    "out:ROI% old": 13.240915,
    "out:Payback discounted": 9,
    "out:Atemp": 3184,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 97,
    "out:Energy use kWh/m2": 97,
    "out:Energy savings %": 88.7,
    "out:Op energy cost/MSEK": 50,
    "out:El price/MSEK": 30,
    "out:Heating price/MSEK": 17,
    "out:DH+ DHW/kWh/m2": 86,
    "out:El bought/kWh/m2": 807.631491,
    "out:Return %": 12,
    "out:Return/kSEK/y": 851,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 35.445,
    "out:EAHP (El)": 0,
    "out:PV (kr/kWp)": 17461.56255567083,
    "out:PV (kWp installed)": 45.25485,
    "out:PV (m2 panels)": 237.433632,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 42461.284501,
    "out:PV (kWh sold)": 24198.124428,
    "out:PV (% sold (El))": 56.988677,
    "out:PV (kWh self-consumed)": 18263.160073,
    "out:PV (ratio sold/self-consumed)": 1.324969,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 978564.8401067974,
    "out:FTX (Diffusers)": 206476.5625,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 265645.35496,
    "out:EL kWh savings": 3619.949007,
    "out:Sewage heat recovery cost": 273216,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 309182.44,
    "out:DH kr savings": 265645.35496021394,
    "out:El kr savings": 7239.898013663189,
    "out:El kr sold": 36297.18664221638,
    "out:El kr saved": 36526.320146,
    "out:El kr return": 72823.50678821639,
    "out:% solar/total": 46,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 161412.735277,
    "out:% savings (space heating)": 60.354701,
    "out:Total_Water Heating (DH)": 112856.846044,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 997124.4110739925,
    "out:Eupp": 50.69,
    "out:Etvv": 21.25,
    "out:Ef": 11.43,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 7169940.547594436,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 30.958624,
    "out:EL CO2": -36.891473,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -38.403999,
    "out:Bought CO2": 1.512526,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02001000010001110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 47.518473,
    "out:DHW (DH)": 35.445,
    "out:Property Electricity": 17.169,
    "out:Electricity (inc PV)": 11.433081,
    "out:Total Energy Use Pre PV": 100.169,
    "out:Total Energy Use Post PV": 94.433081,
    "out:Primary Energy": 72.413358,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 33.597481,
    "out:CO2 Operational/m2": -7.012801,
    "out:Total CO2/m2": 26.58468,
    "out:Total CO2 (tons)": 84.645596,
    "out:Klimatpaverkan": -115.489825,
    "out:Initial Cost/MSEK": 7.249540522594435,
    "out:Running cost/(Apt SEK y)": 17757.659574,
    "out:Running Cost over RSP/MSEK": 6.53353,
    "out:LCP/MSEK": -0.378351,
    "out:ROI% old": 13.540149,
    "out:Payback discounted": 9,
    "out:Atemp": 3184,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 94,
    "out:Energy use kWh/m2": 94,
    "out:Energy savings %": 94.7,
    "out:Op energy cost/MSEK": 48,
    "out:El price/MSEK": 29,
    "out:Heating price/MSEK": 16,
    "out:DH+ DHW/kWh/m2": 83,
    "out:El bought/kWh/m2": 807.631491,
    "out:Return %": 12,
    "out:Return/kSEK/y": 880,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 35.445,
    "out:EAHP (El)": 0,
    "out:PV (kr/kWp)": 17461.56255567083,
    "out:PV (kWp installed)": 45.25485,
    "out:PV (m2 panels)": 237.433632,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 42461.284501,
    "out:PV (kWh sold)": 24198.124428,
    "out:PV (% sold (El))": 56.988677,
    "out:PV (kWh self-consumed)": 18263.160073,
    "out:PV (ratio sold/self-consumed)": 1.324969,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 978564.8401067974,
    "out:FTX (Diffusers)": 206476.5625,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 275759.318047,
    "out:EL kWh savings": 3619.949007,
    "out:Sewage heat recovery cost": 273216,
    "out:Balancing hydronic system cost": 79599.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 319296.4,
    "out:DH kr savings": 275759.31804672995,
    "out:El kr savings": 7239.898013663189,
    "out:El kr sold": 36297.18664221638,
    "out:El kr saved": 36526.320146,
    "out:El kr return": 72823.50678821639,
    "out:% solar/total": 46,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 151298.772191,
    "out:% savings (space heating)": 62.838836,
    "out:Total_Water Heating (DH)": 112856.846044,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 997124.4110739925,
    "out:Eupp": 47.52,
    "out:Etvv": 21.25,
    "out:Ef": 11.43,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 7249540.522594435,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 29.878672,
    "out:EL CO2": -36.891473,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -38.403999,
    "out:Bought CO2": 1.512526,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02001000020000010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 50.69497,
    "out:DHW (DH)": 41.7,
    "out:Property Electricity": 17.169,
    "out:Electricity (inc PV)": 10.564609,
    "out:Total Energy Use Pre PV": 109.169,
    "out:Total Energy Use Post PV": 102.564609,
    "out:Primary Energy": 75.945717,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 47.628037,
    "out:CO2 Operational/m2": -67.356594,
    "out:Total CO2/m2": -19.728557,
    "out:Total CO2 (tons)": -62.815707,
    "out:Klimatpaverkan": -262.951128,
    "out:Initial Cost/MSEK": 7.686937899315687,
    "out:Running cost/(Apt SEK y)": 18214.382979,
    "out:Running Cost over RSP/MSEK": 6.71568,
    "out:LCP/MSEK": -0.997898,
    "out:ROI% old": 12.431181,
    "out:Payback discounted": 10,
    "out:Atemp": 3184,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 103,
    "out:Energy use kWh/m2": 103,
    "out:Energy savings %": 77.7,
    "out:Op energy cost/MSEK": 50,
    "out:El price/MSEK": 29,
    "out:Heating price/MSEK": 18,
    "out:DH+ DHW/kWh/m2": 92,
    "out:El bought/kWh/m2": 744.654907,
    "out:Return %": 11,
    "out:Return/kSEK/y": 859,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 41.7,
    "out:EAHP (El)": 0,
    "out:PV (kr/kWp)": 17461.48474631725,
    "out:PV (kWp installed)": 90.5097,
    "out:PV (m2 panels)": 474.867263,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 84922.568824,
    "out:PV (kWh sold)": 63894.192746,
    "out:PV (% sold (El))": 75.238177,
    "out:PV (kWh self-consumed)": 21028.376078,
    "out:PV (ratio sold/self-consumed)": 3.038475,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 978564.8401067974,
    "out:FTX (Diffusers)": 206476.5625,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 245729.440953,
    "out:EL kWh savings": 6385.163023,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 354341.06,
    "out:DH kr savings": 245729.44095250397,
    "out:El kr savings": 12770.326045670818,
    "out:El kr sold": 95841.28911946694,
    "out:El kr saved": 42056.752156,
    "out:El kr return": 137898.04127546694,
    "out:% solar/total": 53,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 161412.735277,
    "out:% savings (space heating)": 60.354701,
    "out:Total_Water Heating (DH)": 132772.760051,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 997124.4110739925,
    "out:Eupp": 50.69,
    "out:Etvv": 25,
    "out:Ef": 10.56,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 7686937.899315687,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 33.118528,
    "out:EL CO2": -100.475122,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -101.871982,
    "out:Bought CO2": 1.39686,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02001000020000110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 47.518473,
    "out:DHW (DH)": 41.7,
    "out:Property Electricity": 17.169,
    "out:Electricity (inc PV)": 10.564609,
    "out:Total Energy Use Pre PV": 106.169,
    "out:Total Energy Use Post PV": 99.564609,
    "out:Primary Energy": 73.475108,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 47.628037,
    "out:CO2 Operational/m2": -68.436546,
    "out:Total CO2/m2": -20.808509,
    "out:Total CO2 (tons)": -66.254273,
    "out:Klimatpaverkan": -266.389694,
    "out:Initial Cost/MSEK": 7.766537874315686,
    "out:Running cost/(Apt SEK y)": 17600.617021,
    "out:Running Cost over RSP/MSEK": 6.49005,
    "out:LCP/MSEK": -0.851868,
    "out:ROI% old": 12.718795,
    "out:Payback discounted": 10,
    "out:Atemp": 3184,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 100,
    "out:Energy use kWh/m2": 100,
    "out:Energy savings %": 83,
    "out:Op energy cost/MSEK": 48,
    "out:El price/MSEK": 28,
    "out:Heating price/MSEK": 17,
    "out:DH+ DHW/kWh/m2": 89,
    "out:El bought/kWh/m2": 744.654907,
    "out:Return %": 11,
    "out:Return/kSEK/y": 887,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 41.7,
    "out:EAHP (El)": 0,
    "out:PV (kr/kWp)": 17461.48474631725,
    "out:PV (kWp installed)": 90.5097,
    "out:PV (m2 panels)": 474.867263,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 84922.568824,
    "out:PV (kWh sold)": 63894.192746,
    "out:PV (% sold (El))": 75.238177,
    "out:PV (kWh self-consumed)": 21028.376078,
    "out:PV (ratio sold/self-consumed)": 3.038475,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 978564.8401067974,
    "out:FTX (Diffusers)": 206476.5625,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 255843.404039,
    "out:EL kWh savings": 6385.163023,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 79599.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 364455.02,
    "out:DH kr savings": 255843.40403901995,
    "out:El kr savings": 12770.326045670818,
    "out:El kr sold": 95841.28911946694,
    "out:El kr saved": 42056.752156,
    "out:El kr return": 137898.04127546694,
    "out:% solar/total": 53,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 151298.772191,
    "out:% savings (space heating)": 62.838836,
    "out:Total_Water Heating (DH)": 132772.760051,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 997124.4110739925,
    "out:Eupp": 47.52,
    "out:Etvv": 25,
    "out:Ef": 10.56,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 7766537.8743156865,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 32.038576,
    "out:EL CO2": -100.475122,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -101.871982,
    "out:Bought CO2": 1.39686,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02001000020001010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 50.69497,
    "out:DHW (DH)": 35.445,
    "out:Property Electricity": 17.169,
    "out:Electricity (inc PV)": 10.564609,
    "out:Total Energy Use Pre PV": 103.169,
    "out:Total Energy Use Post PV": 96.564609,
    "out:Primary Energy": 73.320717,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 48.33658,
    "out:CO2 Operational/m2": -69.516498,
    "out:Total CO2/m2": -21.179918,
    "out:Total CO2 (tons)": -67.436839,
    "out:Klimatpaverkan": -267.57226,
    "out:Initial Cost/MSEK": 7.960153899315687,
    "out:Running cost/(Apt SEK y)": 16986.851064,
    "out:Running Cost over RSP/MSEK": 6.26441,
    "out:LCP/MSEK": -0.819844,
    "out:ROI% old": 12.814379,
    "out:Payback discounted": 9,
    "out:Atemp": 3184,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 97,
    "out:Energy use kWh/m2": 97,
    "out:Energy savings %": 88.7,
    "out:Op energy cost/MSEK": 47,
    "out:El price/MSEK": 27,
    "out:Heating price/MSEK": 17,
    "out:DH+ DHW/kWh/m2": 86,
    "out:El bought/kWh/m2": 744.654907,
    "out:Return %": 12,
    "out:Return/kSEK/y": 916,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 35.445,
    "out:EAHP (El)": 0,
    "out:PV (kr/kWp)": 17461.48474631725,
    "out:PV (kWp installed)": 90.5097,
    "out:PV (m2 panels)": 474.867263,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 84922.568824,
    "out:PV (kWh sold)": 63894.192746,
    "out:PV (% sold (El))": 75.238177,
    "out:PV (kWh self-consumed)": 21028.376078,
    "out:PV (ratio sold/self-consumed)": 3.038475,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 978564.8401067974,
    "out:FTX (Diffusers)": 206476.5625,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 265645.35496,
    "out:EL kWh savings": 6385.163023,
    "out:Sewage heat recovery cost": 273216,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 374256.97,
    "out:DH kr savings": 265645.35496021394,
    "out:El kr savings": 12770.326045670818,
    "out:El kr sold": 95841.28911946694,
    "out:El kr saved": 42056.752156,
    "out:El kr return": 137898.04127546694,
    "out:% solar/total": 53,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 161412.735277,
    "out:% savings (space heating)": 60.354701,
    "out:Total_Water Heating (DH)": 112856.846044,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 997124.4110739925,
    "out:Eupp": 50.69,
    "out:Etvv": 21.25,
    "out:Ef": 10.56,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 7960153.899315687,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 30.958624,
    "out:EL CO2": -100.475122,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -101.871982,
    "out:Bought CO2": 1.39686,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02001000020001110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 47.518473,
    "out:DHW (DH)": 35.445,
    "out:Property Electricity": 17.169,
    "out:Electricity (inc PV)": 10.564609,
    "out:Total Energy Use Pre PV": 100.169,
    "out:Total Energy Use Post PV": 93.564609,
    "out:Primary Energy": 70.850108,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 48.33658,
    "out:CO2 Operational/m2": -70.59645,
    "out:Total CO2/m2": -22.25987,
    "out:Total CO2 (tons)": -70.875405,
    "out:Klimatpaverkan": -271.010826,
    "out:Initial Cost/MSEK": 8.039753874315686,
    "out:Running cost/(Apt SEK y)": 16373.085106,
    "out:Running Cost over RSP/MSEK": 6.03878,
    "out:LCP/MSEK": -0.673814,
    "out:ROI% old": 13.088425,
    "out:Payback discounted": 9,
    "out:Atemp": 3184,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 94,
    "out:Energy use kWh/m2": 94,
    "out:Energy savings %": 94.7,
    "out:Op energy cost/MSEK": 45,
    "out:El price/MSEK": 26,
    "out:Heating price/MSEK": 16,
    "out:DH+ DHW/kWh/m2": 83,
    "out:El bought/kWh/m2": 744.654907,
    "out:Return %": 12,
    "out:Return/kSEK/y": 945,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 35.445,
    "out:EAHP (El)": 0,
    "out:PV (kr/kWp)": 17461.48474631725,
    "out:PV (kWp installed)": 90.5097,
    "out:PV (m2 panels)": 474.867263,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 84922.568824,
    "out:PV (kWh sold)": 63894.192746,
    "out:PV (% sold (El))": 75.238177,
    "out:PV (kWh self-consumed)": 21028.376078,
    "out:PV (ratio sold/self-consumed)": 3.038475,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 978564.8401067974,
    "out:FTX (Diffusers)": 206476.5625,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 275759.318047,
    "out:EL kWh savings": 6385.163023,
    "out:Sewage heat recovery cost": 273216,
    "out:Balancing hydronic system cost": 79599.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 384370.93,
    "out:DH kr savings": 275759.31804672995,
    "out:El kr savings": 12770.326045670818,
    "out:El kr sold": 95841.28911946694,
    "out:El kr saved": 42056.752156,
    "out:El kr return": 137898.04127546694,
    "out:% solar/total": 53,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 151298.772191,
    "out:% savings (space heating)": 62.838836,
    "out:Total_Water Heating (DH)": 112856.846044,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 997124.4110739925,
    "out:Eupp": 47.52,
    "out:Etvv": 21.25,
    "out:Ef": 10.56,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 8039753.874315686,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 29.878672,
    "out:EL CO2": -100.475122,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -101.871982,
    "out:Bought CO2": 1.39686,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02001000100000010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 50.69497,
    "out:DHW (DH)": 33.36,
    "out:Property Electricity": 17.169,
    "out:Electricity (inc PV)": 17.169,
    "out:Total Energy Use Pre PV": 101.169,
    "out:Total Energy Use Post PV": 101.169,
    "out:Primary Energy": 84.333621,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 18.149838,
    "out:CO2 Operational/m2": 32.505101,
    "out:Total CO2/m2": 50.654939,
    "out:Total CO2 (tons)": 161.285277,
    "out:Klimatpaverkan": -38.850144,
    "out:Initial Cost/MSEK": 6.230551840871936,
    "out:Running cost/(Apt SEK y)": 19511.680851,
    "out:Running Cost over RSP/MSEK": 7.16297,
    "out:LCP/MSEK": 0.011198,
    "out:ROI% old": 14.31139,
    "out:Payback discounted": 8,
    "out:Atemp": 3184,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 101,
    "out:Energy use kWh/m2": 101,
    "out:Energy savings %": 81.2,
    "out:Op energy cost/MSEK": 52,
    "out:El price/MSEK": 33,
    "out:Heating price/MSEK": 16,
    "out:DH+ DHW/kWh/m2": 84,
    "out:El bought/kWh/m2": 1201.83,
    "out:Return %": 13,
    "out:Return/kSEK/y": 798,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 33.36,
    "out:EAHP (El)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 978564.8401067974,
    "out:FTX (Diffusers)": 206476.5625,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 272283.992963,
    "out:EL kWh savings": -14643.211594,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 242997.57,
    "out:DH kr savings": 272283.99296278396,
    "out:El kr savings": -29286.423188315977,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 161412.735277,
    "out:% savings (space heating)": 60.354701,
    "out:Total_Water Heating (DH)": 106218.208041,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 997124.4110739925,
    "out:Eupp": 50.69,
    "out:Etvv": 20,
    "out:Ef": 17.17,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 6230551.840871936,
    "out:Seasonal Variation ROI (%)": 2,
    "out:Seasonal Variation Payback": 50,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 30.238656,
    "out:EL CO2": 2.266445,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 2.266445,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02001000100000110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 47.518473,
    "out:DHW (DH)": 33.36,
    "out:Property Electricity": 17.169,
    "out:Electricity (inc PV)": 17.169,
    "out:Total Energy Use Pre PV": 98.169,
    "out:Total Energy Use Post PV": 98.169,
    "out:Primary Energy": 81.863012,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 18.149838,
    "out:CO2 Operational/m2": 31.425149,
    "out:Total CO2/m2": 49.574987,
    "out:Total CO2 (tons)": 157.846711,
    "out:Klimatpaverkan": -42.28871,
    "out:Initial Cost/MSEK": 6.3101518158719365,
    "out:Running cost/(Apt SEK y)": 18897.914894,
    "out:Running Cost over RSP/MSEK": 6.93733,
    "out:LCP/MSEK": 0.157238,
    "out:ROI% old": 14.64169,
    "out:Payback discounted": 8,
    "out:Atemp": 3184,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 98,
    "out:Energy use kWh/m2": 98,
    "out:Energy savings %": 86.7,
    "out:Op energy cost/MSEK": 50,
    "out:El price/MSEK": 32,
    "out:Heating price/MSEK": 16,
    "out:DH+ DHW/kWh/m2": 81,
    "out:El bought/kWh/m2": 1201.83,
    "out:Return %": 13,
    "out:Return/kSEK/y": 827,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 33.36,
    "out:EAHP (El)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 978564.8401067974,
    "out:FTX (Diffusers)": 206476.5625,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 282397.956049,
    "out:EL kWh savings": -14643.211594,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 79599.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 253111.53,
    "out:DH kr savings": 282397.9560493,
    "out:El kr savings": -29286.423188315977,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 151298.772191,
    "out:% savings (space heating)": 62.838836,
    "out:Total_Water Heating (DH)": 106218.208041,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 997124.4110739925,
    "out:Eupp": 47.52,
    "out:Etvv": 20,
    "out:Ef": 17.17,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 6310151.815871936,
    "out:Seasonal Variation ROI (%)": 2,
    "out:Seasonal Variation Payback": 50,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 29.158704,
    "out:EL CO2": 2.266445,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 2.266445,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02001000100001010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 50.69497,
    "out:DHW (DH)": 28.356,
    "out:Property Electricity": 17.169,
    "out:Electricity (inc PV)": 17.169,
    "out:Total Energy Use Pre PV": 96.169,
    "out:Total Energy Use Post PV": 96.169,
    "out:Primary Energy": 82.233621,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 18.858381,
    "out:CO2 Operational/m2": 30.705181,
    "out:Total CO2/m2": 49.563562,
    "out:Total CO2 (tons)": 157.810334,
    "out:Klimatpaverkan": -42.325087,
    "out:Initial Cost/MSEK": 6.503767840871936,
    "out:Running cost/(Apt SEK y)": 18488.744681,
    "out:Running Cost over RSP/MSEK": 6.78691,
    "out:LCP/MSEK": 0.114042,
    "out:ROI% old": 14.536211,
    "out:Payback discounted": 8,
    "out:Atemp": 3184,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 96,
    "out:Energy use kWh/m2": 96,
    "out:Energy savings %": 90.6,
    "out:Op energy cost/MSEK": 49,
    "out:El price/MSEK": 31,
    "out:Heating price/MSEK": 15,
    "out:DH+ DHW/kWh/m2": 79,
    "out:El bought/kWh/m2": 1201.83,
    "out:Return %": 13,
    "out:Return/kSEK/y": 846,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 28.356,
    "out:EAHP (El)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 978564.8401067974,
    "out:FTX (Diffusers)": 206476.5625,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 288216.724169,
    "out:EL kWh savings": -14643.211594,
    "out:Sewage heat recovery cost": 273216,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 258930.3,
    "out:DH kr savings": 288216.7241689519,
    "out:El kr savings": -29286.423188315977,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 161412.735277,
    "out:% savings (space heating)": 60.354701,
    "out:Total_Water Heating (DH)": 90285.476835,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 997124.4110739925,
    "out:Eupp": 50.69,
    "out:Etvv": 17,
    "out:Ef": 17.17,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 6503767.840871937,
    "out:Seasonal Variation ROI (%)": 2,
    "out:Seasonal Variation Payback": 50,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 28.438736,
    "out:EL CO2": 2.266445,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 2.266445,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02001000100001110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 47.518473,
    "out:DHW (DH)": 28.356,
    "out:Property Electricity": 17.169,
    "out:Electricity (inc PV)": 17.169,
    "out:Total Energy Use Pre PV": 93.169,
    "out:Total Energy Use Post PV": 93.169,
    "out:Primary Energy": 79.763012,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 18.858381,
    "out:CO2 Operational/m2": 29.625229,
    "out:Total CO2/m2": 48.48361,
    "out:Total CO2 (tons)": 154.371768,
    "out:Klimatpaverkan": -45.763653,
    "out:Initial Cost/MSEK": 6.583367815871936,
    "out:Running cost/(Apt SEK y)": 17874.978723,
    "out:Running Cost over RSP/MSEK": 6.56128,
    "out:LCP/MSEK": 0.260072,
    "out:ROI% old": 14.850063,
    "out:Payback discounted": 8,
    "out:Atemp": 3184,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 93,
    "out:Energy use kWh/m2": 93,
    "out:Energy savings %": 96.8,
    "out:Op energy cost/MSEK": 48,
    "out:El price/MSEK": 30,
    "out:Heating price/MSEK": 15,
    "out:DH+ DHW/kWh/m2": 76,
    "out:El bought/kWh/m2": 1201.83,
    "out:Return %": 13,
    "out:Return/kSEK/y": 875,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 28.356,
    "out:EAHP (El)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 978564.8401067974,
    "out:FTX (Diffusers)": 206476.5625,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 298330.687255,
    "out:EL kWh savings": -14643.211594,
    "out:Sewage heat recovery cost": 273216,
    "out:Balancing hydronic system cost": 79599.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 269044.26,
    "out:DH kr savings": 298330.687255468,
    "out:El kr savings": -29286.423188315977,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 151298.772191,
    "out:% savings (space heating)": 62.838836,
    "out:Total_Water Heating (DH)": 90285.476835,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 997124.4110739925,
    "out:Eupp": 47.52,
    "out:Etvv": 17,
    "out:Ef": 17.17,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 6583367.815871936,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 27.358784,
    "out:EL CO2": 2.266445,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 2.266445,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02001000110000010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 50.69497,
    "out:DHW (DH)": 33.36,
    "out:Property Electricity": 17.169,
    "out:Electricity (inc PV)": 11.433081,
    "out:Total Energy Use Pre PV": 101.169,
    "out:Total Energy Use Post PV": 95.433081,
    "out:Primary Energy": 74.008967,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 32.888938,
    "out:CO2 Operational/m2": -6.652817,
    "out:Total CO2/m2": 26.236121,
    "out:Total CO2 (tons)": 83.535784,
    "out:Klimatpaverkan": -116.599637,
    "out:Initial Cost/MSEK": 7.020772235094437,
    "out:Running cost/(Apt SEK y)": 17962.255319,
    "out:Running Cost over RSP/MSEK": 6.60874,
    "out:LCP/MSEK": -0.224792,
    "out:ROI% old": 13.828312,
    "out:Payback discounted": 9,
    "out:Atemp": 3184,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 95,
    "out:Energy use kWh/m2": 95,
    "out:Energy savings %": 92.6,
    "out:Op energy cost/MSEK": 49,
    "out:El price/MSEK": 30,
    "out:Heating price/MSEK": 16,
    "out:DH+ DHW/kWh/m2": 84,
    "out:El bought/kWh/m2": 807.631491,
    "out:Return %": 12,
    "out:Return/kSEK/y": 870,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 33.36,
    "out:EAHP (El)": 0,
    "out:PV (kr/kWp)": 17461.56255567083,
    "out:PV (kWp installed)": 45.25485,
    "out:PV (m2 panels)": 237.433632,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 42461.284501,
    "out:PV (kWh sold)": 24198.124428,
    "out:PV (% sold (El))": 56.988677,
    "out:PV (kWh self-consumed)": 18263.160073,
    "out:PV (ratio sold/self-consumed)": 1.324969,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 978564.8401067974,
    "out:FTX (Diffusers)": 206476.5625,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 272283.992963,
    "out:EL kWh savings": 3619.949007,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 315821.08,
    "out:DH kr savings": 272283.99296278396,
    "out:El kr savings": 7239.898013663189,
    "out:El kr sold": 36297.18664221638,
    "out:El kr saved": 36526.320146,
    "out:El kr return": 72823.50678821639,
    "out:% solar/total": 46,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 161412.735277,
    "out:% savings (space heating)": 60.354701,
    "out:Total_Water Heating (DH)": 106218.208041,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 997124.4110739925,
    "out:Eupp": 50.69,
    "out:Etvv": 20,
    "out:Ef": 11.43,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 7020772.235094437,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 30.238656,
    "out:EL CO2": -36.891473,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -38.403999,
    "out:Bought CO2": 1.512526,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02001000110000110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 47.518473,
    "out:DHW (DH)": 33.36,
    "out:Property Electricity": 17.169,
    "out:Electricity (inc PV)": 11.433081,
    "out:Total Energy Use Pre PV": 98.169,
    "out:Total Energy Use Post PV": 92.433081,
    "out:Primary Energy": 71.538358,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 32.888938,
    "out:CO2 Operational/m2": -7.732769,
    "out:Total CO2/m2": 25.156169,
    "out:Total CO2 (tons)": 80.097218,
    "out:Klimatpaverkan": -120.038203,
    "out:Initial Cost/MSEK": 7.100372210094436,
    "out:Running cost/(Apt SEK y)": 17348.468085,
    "out:Running Cost over RSP/MSEK": 6.3831,
    "out:LCP/MSEK": -0.078752,
    "out:ROI% old": 14.127267,
    "out:Payback discounted": 9,
    "out:Atemp": 3184,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 92,
    "out:Energy use kWh/m2": 92,
    "out:Energy savings %": 98.9,
    "out:Op energy cost/MSEK": 47,
    "out:El price/MSEK": 29,
    "out:Heating price/MSEK": 16,
    "out:DH+ DHW/kWh/m2": 81,
    "out:El bought/kWh/m2": 807.631491,
    "out:Return %": 13,
    "out:Return/kSEK/y": 899,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 33.36,
    "out:EAHP (El)": 0,
    "out:PV (kr/kWp)": 17461.56255567083,
    "out:PV (kWp installed)": 45.25485,
    "out:PV (m2 panels)": 237.433632,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 42461.284501,
    "out:PV (kWh sold)": 24198.124428,
    "out:PV (% sold (El))": 56.988677,
    "out:PV (kWh self-consumed)": 18263.160073,
    "out:PV (ratio sold/self-consumed)": 1.324969,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 978564.8401067974,
    "out:FTX (Diffusers)": 206476.5625,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 282397.956049,
    "out:EL kWh savings": 3619.949007,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 79599.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 325935.04,
    "out:DH kr savings": 282397.9560493,
    "out:El kr savings": 7239.898013663189,
    "out:El kr sold": 36297.18664221638,
    "out:El kr saved": 36526.320146,
    "out:El kr return": 72823.50678821639,
    "out:% solar/total": 46,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 151298.772191,
    "out:% savings (space heating)": 62.838836,
    "out:Total_Water Heating (DH)": 106218.208041,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 997124.4110739925,
    "out:Eupp": 47.52,
    "out:Etvv": 20,
    "out:Ef": 11.43,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 7100372.210094436,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 29.158704,
    "out:EL CO2": -36.891473,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -38.403999,
    "out:Bought CO2": 1.512526,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02001000110001010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 50.69497,
    "out:DHW (DH)": 28.356,
    "out:Property Electricity": 17.169,
    "out:Electricity (inc PV)": 11.433081,
    "out:Total Energy Use Pre PV": 96.169,
    "out:Total Energy Use Post PV": 90.433081,
    "out:Primary Energy": 71.908967,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 33.597481,
    "out:CO2 Operational/m2": -8.452737,
    "out:Total CO2/m2": 25.144744,
    "out:Total CO2 (tons)": 80.060841,
    "out:Klimatpaverkan": -120.07458,
    "out:Initial Cost/MSEK": 7.293988235094435,
    "out:Running cost/(Apt SEK y)": 16939.297872,
    "out:Running Cost over RSP/MSEK": 6.23268,
    "out:LCP/MSEK": -0.121948,
    "out:ROI% old": 14.046871,
    "out:Payback discounted": 9,
    "out:Atemp": 3184,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 90,
    "out:Energy use kWh/m2": 90,
    "out:Energy savings %": 103.3,
    "out:Op energy cost/MSEK": 46,
    "out:El price/MSEK": 28,
    "out:Heating price/MSEK": 15,
    "out:DH+ DHW/kWh/m2": 79,
    "out:El bought/kWh/m2": 807.631491,
    "out:Return %": 13,
    "out:Return/kSEK/y": 919,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 28.356,
    "out:EAHP (El)": 0,
    "out:PV (kr/kWp)": 17461.56255567083,
    "out:PV (kWp installed)": 45.25485,
    "out:PV (m2 panels)": 237.433632,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 42461.284501,
    "out:PV (kWh sold)": 24198.124428,
    "out:PV (% sold (El))": 56.988677,
    "out:PV (kWh self-consumed)": 18263.160073,
    "out:PV (ratio sold/self-consumed)": 1.324969,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 978564.8401067974,
    "out:FTX (Diffusers)": 206476.5625,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 288216.724169,
    "out:EL kWh savings": 3619.949007,
    "out:Sewage heat recovery cost": 273216,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 331753.81,
    "out:DH kr savings": 288216.7241689519,
    "out:El kr savings": 7239.898013663189,
    "out:El kr sold": 36297.18664221638,
    "out:El kr saved": 36526.320146,
    "out:El kr return": 72823.50678821639,
    "out:% solar/total": 46,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 161412.735277,
    "out:% savings (space heating)": 60.354701,
    "out:Total_Water Heating (DH)": 90285.476835,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 997124.4110739925,
    "out:Eupp": 50.69,
    "out:Etvv": 17,
    "out:Ef": 11.43,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 7293988.2350944355,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 28.438736,
    "out:EL CO2": -36.891473,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -38.403999,
    "out:Bought CO2": 1.512526,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02001000110001110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 47.518473,
    "out:DHW (DH)": 28.356,
    "out:Property Electricity": 17.169,
    "out:Electricity (inc PV)": 11.433081,
    "out:Total Energy Use Pre PV": 93.169,
    "out:Total Energy Use Post PV": 87.433081,
    "out:Primary Energy": 69.438358,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 33.597481,
    "out:CO2 Operational/m2": -9.532689,
    "out:Total CO2/m2": 24.064792,
    "out:Total CO2 (tons)": 76.622275,
    "out:Klimatpaverkan": -123.513146,
    "out:Initial Cost/MSEK": 7.373588210094436,
    "out:Running cost/(Apt SEK y)": 16325.531915,
    "out:Running Cost over RSP/MSEK": 6.00705,
    "out:LCP/MSEK": 0.024082,
    "out:ROI% old": 14.332371,
    "out:Payback discounted": 8,
    "out:Atemp": 3184,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 87,
    "out:Energy use kWh/m2": 87,
    "out:Energy savings %": 110.3,
    "out:Op energy cost/MSEK": 44,
    "out:El price/MSEK": 27,
    "out:Heating price/MSEK": 15,
    "out:DH+ DHW/kWh/m2": 76,
    "out:El bought/kWh/m2": 807.631491,
    "out:Return %": 13,
    "out:Return/kSEK/y": 947,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 28.356,
    "out:EAHP (El)": 0,
    "out:PV (kr/kWp)": 17461.56255567083,
    "out:PV (kWp installed)": 45.25485,
    "out:PV (m2 panels)": 237.433632,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 42461.284501,
    "out:PV (kWh sold)": 24198.124428,
    "out:PV (% sold (El))": 56.988677,
    "out:PV (kWh self-consumed)": 18263.160073,
    "out:PV (ratio sold/self-consumed)": 1.324969,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 978564.8401067974,
    "out:FTX (Diffusers)": 206476.5625,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 298330.687255,
    "out:EL kWh savings": 3619.949007,
    "out:Sewage heat recovery cost": 273216,
    "out:Balancing hydronic system cost": 79599.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 341867.77,
    "out:DH kr savings": 298330.687255468,
    "out:El kr savings": 7239.898013663189,
    "out:El kr sold": 36297.18664221638,
    "out:El kr saved": 36526.320146,
    "out:El kr return": 72823.50678821639,
    "out:% solar/total": 46,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 151298.772191,
    "out:% savings (space heating)": 62.838836,
    "out:Total_Water Heating (DH)": 90285.476835,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 997124.4110739925,
    "out:Eupp": 47.52,
    "out:Etvv": 17,
    "out:Ef": 11.43,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 7373588.210094436,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 27.358784,
    "out:EL CO2": -36.891473,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -38.403999,
    "out:Bought CO2": 1.512526,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02001000120000010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 50.69497,
    "out:DHW (DH)": 33.36,
    "out:Property Electricity": 17.169,
    "out:Electricity (inc PV)": 10.564609,
    "out:Total Energy Use Pre PV": 101.169,
    "out:Total Energy Use Post PV": 94.564609,
    "out:Primary Energy": 72.445717,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 47.628037,
    "out:CO2 Operational/m2": -70.236466,
    "out:Total CO2/m2": -22.608429,
    "out:Total CO2 (tons)": -71.985216,
    "out:Klimatpaverkan": -272.120637,
    "out:Initial Cost/MSEK": 7.810985586815686,
    "out:Running cost/(Apt SEK y)": 16577.680851,
    "out:Running Cost over RSP/MSEK": 6.11399,
    "out:LCP/MSEK": -0.520256,
    "out:ROI% old": 13.334205,
    "out:Payback discounted": 9,
    "out:Atemp": 3184,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 95,
    "out:Energy use kWh/m2": 95,
    "out:Energy savings %": 92.6,
    "out:Op energy cost/MSEK": 46,
    "out:El price/MSEK": 27,
    "out:Heating price/MSEK": 16,
    "out:DH+ DHW/kWh/m2": 84,
    "out:El bought/kWh/m2": 744.654907,
    "out:Return %": 12,
    "out:Return/kSEK/y": 936,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 33.36,
    "out:EAHP (El)": 0,
    "out:PV (kr/kWp)": 17461.48474631725,
    "out:PV (kWp installed)": 90.5097,
    "out:PV (m2 panels)": 474.867263,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 84922.568824,
    "out:PV (kWh sold)": 63894.192746,
    "out:PV (% sold (El))": 75.238177,
    "out:PV (kWh self-consumed)": 21028.376078,
    "out:PV (ratio sold/self-consumed)": 3.038475,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 978564.8401067974,
    "out:FTX (Diffusers)": 206476.5625,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 272283.992963,
    "out:EL kWh savings": 6385.163023,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 380895.61,
    "out:DH kr savings": 272283.99296278396,
    "out:El kr savings": 12770.326045670818,
    "out:El kr sold": 95841.28911946694,
    "out:El kr saved": 42056.752156,
    "out:El kr return": 137898.04127546694,
    "out:% solar/total": 53,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 161412.735277,
    "out:% savings (space heating)": 60.354701,
    "out:Total_Water Heating (DH)": 106218.208041,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 997124.4110739925,
    "out:Eupp": 50.69,
    "out:Etvv": 20,
    "out:Ef": 10.56,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 7810985.586815686,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 30.238656,
    "out:EL CO2": -100.475122,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -101.871982,
    "out:Bought CO2": 1.39686,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02001000120000110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 47.518473,
    "out:DHW (DH)": 33.36,
    "out:Property Electricity": 17.169,
    "out:Electricity (inc PV)": 10.564609,
    "out:Total Energy Use Pre PV": 98.169,
    "out:Total Energy Use Post PV": 91.564609,
    "out:Primary Energy": 69.975108,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 47.628037,
    "out:CO2 Operational/m2": -71.316418,
    "out:Total CO2/m2": -23.688381,
    "out:Total CO2 (tons)": -75.423782,
    "out:Klimatpaverkan": -275.559203,
    "out:Initial Cost/MSEK": 7.890585561815686,
    "out:Running cost/(Apt SEK y)": 15963.914894,
    "out:Running Cost over RSP/MSEK": 5.88836,
    "out:LCP/MSEK": -0.374226,
    "out:ROI% old": 13.608188,
    "out:Payback discounted": 9,
    "out:Atemp": 3184,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 92,
    "out:Energy use kWh/m2": 92,
    "out:Energy savings %": 98.9,
    "out:Op energy cost/MSEK": 44,
    "out:El price/MSEK": 26,
    "out:Heating price/MSEK": 16,
    "out:DH+ DHW/kWh/m2": 81,
    "out:El bought/kWh/m2": 744.654907,
    "out:Return %": 12,
    "out:Return/kSEK/y": 964,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 33.36,
    "out:EAHP (El)": 0,
    "out:PV (kr/kWp)": 17461.48474631725,
    "out:PV (kWp installed)": 90.5097,
    "out:PV (m2 panels)": 474.867263,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 84922.568824,
    "out:PV (kWh sold)": 63894.192746,
    "out:PV (% sold (El))": 75.238177,
    "out:PV (kWh self-consumed)": 21028.376078,
    "out:PV (ratio sold/self-consumed)": 3.038475,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 978564.8401067974,
    "out:FTX (Diffusers)": 206476.5625,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 282397.956049,
    "out:EL kWh savings": 6385.163023,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 79599.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 391009.57,
    "out:DH kr savings": 282397.9560493,
    "out:El kr savings": 12770.326045670818,
    "out:El kr sold": 95841.28911946694,
    "out:El kr saved": 42056.752156,
    "out:El kr return": 137898.04127546694,
    "out:% solar/total": 53,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 151298.772191,
    "out:% savings (space heating)": 62.838836,
    "out:Total_Water Heating (DH)": 106218.208041,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 997124.4110739925,
    "out:Eupp": 47.52,
    "out:Etvv": 20,
    "out:Ef": 10.56,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 7890585.5618156865,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 29.158704,
    "out:EL CO2": -100.475122,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -101.871982,
    "out:Bought CO2": 1.39686,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02001000120001010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 50.69497,
    "out:DHW (DH)": 28.356,
    "out:Property Electricity": 17.169,
    "out:Electricity (inc PV)": 10.564609,
    "out:Total Energy Use Pre PV": 96.169,
    "out:Total Energy Use Post PV": 89.564609,
    "out:Primary Energy": 70.345717,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 48.33658,
    "out:CO2 Operational/m2": -72.036386,
    "out:Total CO2/m2": -23.699806,
    "out:Total CO2 (tons)": -75.46016,
    "out:Klimatpaverkan": -275.595581,
    "out:Initial Cost/MSEK": 8.084201586815686,
    "out:Running cost/(Apt SEK y)": 15554.744681,
    "out:Running Cost over RSP/MSEK": 5.73793,
    "out:LCP/MSEK": -0.417412,
    "out:ROI% old": 13.5481,
    "out:Payback discounted": 9,
    "out:Atemp": 3184,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 90,
    "out:Energy use kWh/m2": 90,
    "out:Energy savings %": 103.3,
    "out:Op energy cost/MSEK": 43,
    "out:El price/MSEK": 25,
    "out:Heating price/MSEK": 15,
    "out:DH+ DHW/kWh/m2": 79,
    "out:El bought/kWh/m2": 744.654907,
    "out:Return %": 12,
    "out:Return/kSEK/y": 984,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 28.356,
    "out:EAHP (El)": 0,
    "out:PV (kr/kWp)": 17461.48474631725,
    "out:PV (kWp installed)": 90.5097,
    "out:PV (m2 panels)": 474.867263,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 84922.568824,
    "out:PV (kWh sold)": 63894.192746,
    "out:PV (% sold (El))": 75.238177,
    "out:PV (kWh self-consumed)": 21028.376078,
    "out:PV (ratio sold/self-consumed)": 3.038475,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 978564.8401067974,
    "out:FTX (Diffusers)": 206476.5625,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 288216.724169,
    "out:EL kWh savings": 6385.163023,
    "out:Sewage heat recovery cost": 273216,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 396828.34,
    "out:DH kr savings": 288216.7241689519,
    "out:El kr savings": 12770.326045670818,
    "out:El kr sold": 95841.28911946694,
    "out:El kr saved": 42056.752156,
    "out:El kr return": 137898.04127546694,
    "out:% solar/total": 53,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 161412.735277,
    "out:% savings (space heating)": 60.354701,
    "out:Total_Water Heating (DH)": 90285.476835,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 997124.4110739925,
    "out:Eupp": 50.69,
    "out:Etvv": 17,
    "out:Ef": 10.56,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 8084201.586815686,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 28.438736,
    "out:EL CO2": -100.475122,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -101.871982,
    "out:Bought CO2": 1.39686,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z02001000120001110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 47.518473,
    "out:DHW (DH)": 28.356,
    "out:Property Electricity": 17.169,
    "out:Electricity (inc PV)": 10.564609,
    "out:Total Energy Use Pre PV": 93.169,
    "out:Total Energy Use Post PV": 86.564609,
    "out:Primary Energy": 67.875108,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 48.33658,
    "out:CO2 Operational/m2": -73.116338,
    "out:Total CO2/m2": -24.779758,
    "out:Total CO2 (tons)": -78.898726,
    "out:Klimatpaverkan": -279.034147,
    "out:Initial Cost/MSEK": 8.163801561815687,
    "out:Running cost/(Apt SEK y)": 14940.978723,
    "out:Running Cost over RSP/MSEK": 5.5123,
    "out:LCP/MSEK": -0.271382,
    "out:ROI% old": 13.810828,
    "out:Payback discounted": 9,
    "out:Atemp": 3184,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 87,
    "out:Energy use kWh/m2": 87,
    "out:Energy savings %": 110.3,
    "out:Op energy cost/MSEK": 41,
    "out:El price/MSEK": 24,
    "out:Heating price/MSEK": 15,
    "out:DH+ DHW/kWh/m2": 76,
    "out:El bought/kWh/m2": 744.654907,
    "out:Return %": 12,
    "out:Return/kSEK/y": 1012,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 28.356,
    "out:EAHP (El)": 0,
    "out:PV (kr/kWp)": 17461.48474631725,
    "out:PV (kWp installed)": 90.5097,
    "out:PV (m2 panels)": 474.867263,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 84922.568824,
    "out:PV (kWh sold)": 63894.192746,
    "out:PV (% sold (El))": 75.238177,
    "out:PV (kWh self-consumed)": 21028.376078,
    "out:PV (ratio sold/self-consumed)": 3.038475,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 978564.8401067974,
    "out:FTX (Diffusers)": 206476.5625,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.14,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 270.825,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 298330.687255,
    "out:EL kWh savings": 6385.163023,
    "out:Sewage heat recovery cost": 273216,
    "out:Balancing hydronic system cost": 79599.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 406942.3,
    "out:DH kr savings": 298330.687255468,
    "out:El kr savings": 12770.326045670818,
    "out:El kr sold": 95841.28911946694,
    "out:El kr saved": 42056.752156,
    "out:El kr return": 137898.04127546694,
    "out:% solar/total": 53,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 151298.772191,
    "out:% savings (space heating)": 62.838836,
    "out:Total_Water Heating (DH)": 90285.476835,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 997124.4110739925,
    "out:Eupp": 47.52,
    "out:Etvv": 17,
    "out:Ef": 10.56,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 8163801.5618156865,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 27.358784,
    "out:EL CO2": -100.475122,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -101.871982,
    "out:Bought CO2": 1.39686,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00000000000000001",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 45.332944,
    "out:DHW (DH)": 41.7,
    "out:Property Electricity": 17.169,
    "out:Electricity (inc PV)": 17.169,
    "out:Total Energy Use Pre PV": 104.169,
    "out:Total Energy Use Post PV": 104.169,
    "out:Primary Energy": 83.663156,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 39.15988,
    "out:CO2 Operational/m2": 33.585053,
    "out:Total CO2/m2": 72.744933,
    "out:Total CO2 (tons)": 231.619797,
    "out:Klimatpaverkan": 31.484376,
    "out:Initial Cost/MSEK": 7.80910583344342,
    "out:Running cost/(Apt SEK y)": 20125.446809,
    "out:Running Cost over RSP/MSEK": 7.3886,
    "out:LCP/MSEK": -1.792986,
    "out:ROI% old": 11.005687,
    "out:Payback discounted": 11,
    "out:Atemp": 3184,
    "out:Umean": 0.35,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 104,
    "out:Energy use kWh/m2": 104,
    "out:Energy savings %": 76,
    "out:Op energy cost/MSEK": 54,
    "out:El price/MSEK": 34,
    "out:Heating price/MSEK": 17,
    "out:DH+ DHW/kWh/m2": 87,
    "out:El bought/kWh/m2": 1201.83,
    "out:Return %": 10,
    "out:Return/kSEK/y": 769,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 41.7,
    "out:EAHP (El)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 1362005.6417737305,
    "out:FTX (Diffusers)": 206476.5625,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.08,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 262802.126281,
    "out:EL kWh savings": -14643.211594,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 233515.7,
    "out:DH kr savings": 262802.12628128316,
    "out:El kr savings": -29286.423188315977,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 144340.049949,
    "out:% savings (space heating)": 64.547999,
    "out:Total_Water Heating (DH)": 132772.760051,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 997124.4110739925,
    "out:Eupp": 45.33,
    "out:Etvv": 25,
    "out:Ef": 17.17,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 7809105.83344342,
    "out:Seasonal Variation ROI (%)": 2,
    "out:Seasonal Variation Payback": 50,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 31.318608,
    "out:EL CO2": 2.266445,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 2.266445,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00000000000000101",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 42.400175,
    "out:DHW (DH)": 41.7,
    "out:Property Electricity": 17.169,
    "out:Electricity (inc PV)": 17.169,
    "out:Total Energy Use Pre PV": 101.169,
    "out:Total Energy Use Post PV": 101.169,
    "out:Primary Energy": 81.382114,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 39.15988,
    "out:CO2 Operational/m2": 32.505101,
    "out:Total CO2/m2": 71.664981,
    "out:Total CO2 (tons)": 228.181231,
    "out:Klimatpaverkan": 28.04581,
    "out:Initial Cost/MSEK": 7.88870580844342,
    "out:Running cost/(Apt SEK y)": 19511.680851,
    "out:Running Cost over RSP/MSEK": 7.16297,
    "out:LCP/MSEK": -1.646956,
    "out:ROI% old": 11.30323,
    "out:Payback discounted": 11,
    "out:Atemp": 3184,
    "out:Umean": 0.35,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 101,
    "out:Energy use kWh/m2": 101,
    "out:Energy savings %": 81.2,
    "out:Op energy cost/MSEK": 52,
    "out:El price/MSEK": 33,
    "out:Heating price/MSEK": 16,
    "out:DH+ DHW/kWh/m2": 84,
    "out:El bought/kWh/m2": 1201.83,
    "out:Return %": 10,
    "out:Return/kSEK/y": 798,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 41.7,
    "out:EAHP (El)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 1362005.6417737305,
    "out:FTX (Diffusers)": 206476.5625,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.08,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 272140.058216,
    "out:EL kWh savings": -14643.211594,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 79599.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 242853.64,
    "out:DH kr savings": 272140.058216491,
    "out:El kr savings": -29286.423188315977,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 135002.118013,
    "out:% savings (space heating)": 66.84153,
    "out:Total_Water Heating (DH)": 132772.760051,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 997124.4110739925,
    "out:Eupp": 42.4,
    "out:Etvv": 25,
    "out:Ef": 17.17,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 7888705.80844342,
    "out:Seasonal Variation ROI (%)": 2,
    "out:Seasonal Variation Payback": 50,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 30.238656,
    "out:EL CO2": 2.266445,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 2.266445,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00000000000001001",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 45.332944,
    "out:DHW (DH)": 35.445,
    "out:Property Electricity": 17.169,
    "out:Electricity (inc PV)": 17.169,
    "out:Total Energy Use Pre PV": 98.169,
    "out:Total Energy Use Post PV": 98.169,
    "out:Primary Energy": 81.038156,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 39.868423,
    "out:CO2 Operational/m2": 31.425149,
    "out:Total CO2/m2": 71.293572,
    "out:Total CO2 (tons)": 226.998665,
    "out:Klimatpaverkan": 26.863244,
    "out:Initial Cost/MSEK": 8.08232183344342,
    "out:Running cost/(Apt SEK y)": 18897.914894,
    "out:Running Cost over RSP/MSEK": 6.93733,
    "out:LCP/MSEK": -1.614932,
    "out:ROI% old": 11.43128,
    "out:Payback discounted": 11,
    "out:Atemp": 3184,
    "out:Umean": 0.35,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 98,
    "out:Energy use kWh/m2": 98,
    "out:Energy savings %": 86.7,
    "out:Op energy cost/MSEK": 50,
    "out:El price/MSEK": 32,
    "out:Heating price/MSEK": 16,
    "out:DH+ DHW/kWh/m2": 81,
    "out:El bought/kWh/m2": 1201.83,
    "out:Return %": 10,
    "out:Return/kSEK/y": 827,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 35.445,
    "out:EAHP (El)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 1362005.6417737305,
    "out:FTX (Diffusers)": 206476.5625,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.08,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 282718.040289,
    "out:EL kWh savings": -14643.211594,
    "out:Sewage heat recovery cost": 273216,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 253431.62,
    "out:DH kr savings": 282718.0402889932,
    "out:El kr savings": -29286.423188315977,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 144340.049949,
    "out:% savings (space heating)": 64.547999,
    "out:Total_Water Heating (DH)": 112856.846044,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 997124.4110739925,
    "out:Eupp": 45.33,
    "out:Etvv": 21.25,
    "out:Ef": 17.17,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 8082321.833443419,
    "out:Seasonal Variation ROI (%)": 2,
    "out:Seasonal Variation Payback": 50,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 29.158704,
    "out:EL CO2": 2.266445,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 2.266445,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00000000000001101",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 42.400175,
    "out:DHW (DH)": 35.445,
    "out:Property Electricity": 17.169,
    "out:Electricity (inc PV)": 17.169,
    "out:Total Energy Use Pre PV": 95.169,
    "out:Total Energy Use Post PV": 95.169,
    "out:Primary Energy": 78.757114,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 39.868423,
    "out:CO2 Operational/m2": 30.345197,
    "out:Total CO2/m2": 70.21362,
    "out:Total CO2 (tons)": 223.560099,
    "out:Klimatpaverkan": 23.424678,
    "out:Initial Cost/MSEK": 8.16192180844342,
    "out:Running cost/(Apt SEK y)": 18284.148936,
    "out:Running Cost over RSP/MSEK": 6.7117,
    "out:LCP/MSEK": -1.468902,
    "out:ROI% old": 11.714713,
    "out:Payback discounted": 10,
    "out:Atemp": 3184,
    "out:Umean": 0.35,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 95,
    "out:Energy use kWh/m2": 95,
    "out:Energy savings %": 92.6,
    "out:Op energy cost/MSEK": 49,
    "out:El price/MSEK": 31,
    "out:Heating price/MSEK": 15,
    "out:DH+ DHW/kWh/m2": 78,
    "out:El bought/kWh/m2": 1201.83,
    "out:Return %": 10,
    "out:Return/kSEK/y": 855,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 35.445,
    "out:EAHP (El)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 1362005.6417737305,
    "out:FTX (Diffusers)": 206476.5625,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.08,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 292055.972224,
    "out:EL kWh savings": -14643.211594,
    "out:Sewage heat recovery cost": 273216,
    "out:Balancing hydronic system cost": 79599.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 262769.55,
    "out:DH kr savings": 292055.972224201,
    "out:El kr savings": -29286.423188315977,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 135002.118013,
    "out:% savings (space heating)": 66.84153,
    "out:Total_Water Heating (DH)": 112856.846044,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 997124.4110739925,
    "out:Eupp": 42.4,
    "out:Etvv": 21.25,
    "out:Ef": 17.17,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 8161921.808443421,
    "out:Seasonal Variation ROI (%)": 2,
    "out:Seasonal Variation Payback": 50,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 28.078752,
    "out:EL CO2": 2.266445,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 2.266445,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00000000010000001",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 45.332944,
    "out:DHW (DH)": 41.7,
    "out:Property Electricity": 17.169,
    "out:Electricity (inc PV)": 11.433081,
    "out:Total Energy Use Pre PV": 104.169,
    "out:Total Energy Use Post PV": 98.433081,
    "out:Primary Energy": 73.338502,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 53.89898,
    "out:CO2 Operational/m2": -5.572865,
    "out:Total CO2/m2": 48.326115,
    "out:Total CO2 (tons)": 153.870304,
    "out:Klimatpaverkan": -46.265117,
    "out:Initial Cost/MSEK": 8.59932622766592,
    "out:Running cost/(Apt SEK y)": 18576.021277,
    "out:Running Cost over RSP/MSEK": 6.83437,
    "out:LCP/MSEK": -2.028976,
    "out:ROI% old": 10.915058,
    "out:Payback discounted": 11,
    "out:Atemp": 3184,
    "out:Umean": 0.35,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 98,
    "out:Energy use kWh/m2": 98,
    "out:Energy savings %": 86.7,
    "out:Op energy cost/MSEK": 50,
    "out:El price/MSEK": 31,
    "out:Heating price/MSEK": 17,
    "out:DH+ DHW/kWh/m2": 87,
    "out:El bought/kWh/m2": 807.631491,
    "out:Return %": 10,
    "out:Return/kSEK/y": 842,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 41.7,
    "out:EAHP (El)": 0,
    "out:PV (kr/kWp)": 17461.56255567083,
    "out:PV (kWp installed)": 45.25485,
    "out:PV (m2 panels)": 237.433632,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 42461.284501,
    "out:PV (kWh sold)": 24198.124428,
    "out:PV (% sold (El))": 56.988677,
    "out:PV (kWh self-consumed)": 18263.160073,
    "out:PV (ratio sold/self-consumed)": 1.324969,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 1362005.6417737305,
    "out:FTX (Diffusers)": 206476.5625,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.08,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 262802.126281,
    "out:EL kWh savings": 3619.949007,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 306339.21,
    "out:DH kr savings": 262802.12628128316,
    "out:El kr savings": 7239.898013663189,
    "out:El kr sold": 36297.18664221638,
    "out:El kr saved": 36526.320146,
    "out:El kr return": 72823.50678821639,
    "out:% solar/total": 46,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 144340.049949,
    "out:% savings (space heating)": 64.547999,
    "out:Total_Water Heating (DH)": 132772.760051,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 997124.4110739925,
    "out:Eupp": 45.33,
    "out:Etvv": 25,
    "out:Ef": 11.43,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 8599326.22766592,
    "out:Seasonal Variation ROI (%)": 2,
    "out:Seasonal Variation Payback": 50,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 31.318608,
    "out:EL CO2": -36.891473,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -38.403999,
    "out:Bought CO2": 1.512526,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00000000010000101",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 42.400175,
    "out:DHW (DH)": 41.7,
    "out:Property Electricity": 17.169,
    "out:Electricity (inc PV)": 11.433081,
    "out:Total Energy Use Pre PV": 101.169,
    "out:Total Energy Use Post PV": 95.433081,
    "out:Primary Energy": 71.05746,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 53.89898,
    "out:CO2 Operational/m2": -6.652817,
    "out:Total CO2/m2": 47.246163,
    "out:Total CO2 (tons)": 150.431738,
    "out:Klimatpaverkan": -49.703683,
    "out:Initial Cost/MSEK": 8.67892620266592,
    "out:Running cost/(Apt SEK y)": 17962.255319,
    "out:Running Cost over RSP/MSEK": 6.60874,
    "out:LCP/MSEK": -1.882946,
    "out:ROI% old": 11.186341,
    "out:Payback discounted": 11,
    "out:Atemp": 3184,
    "out:Umean": 0.35,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 95,
    "out:Energy use kWh/m2": 95,
    "out:Energy savings %": 92.6,
    "out:Op energy cost/MSEK": 49,
    "out:El price/MSEK": 30,
    "out:Heating price/MSEK": 16,
    "out:DH+ DHW/kWh/m2": 84,
    "out:El bought/kWh/m2": 807.631491,
    "out:Return %": 10,
    "out:Return/kSEK/y": 870,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 41.7,
    "out:EAHP (El)": 0,
    "out:PV (kr/kWp)": 17461.56255567083,
    "out:PV (kWp installed)": 45.25485,
    "out:PV (m2 panels)": 237.433632,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 42461.284501,
    "out:PV (kWh sold)": 24198.124428,
    "out:PV (% sold (El))": 56.988677,
    "out:PV (kWh self-consumed)": 18263.160073,
    "out:PV (ratio sold/self-consumed)": 1.324969,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 1362005.6417737305,
    "out:FTX (Diffusers)": 206476.5625,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.08,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 272140.058216,
    "out:EL kWh savings": 3619.949007,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 79599.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 315677.14,
    "out:DH kr savings": 272140.058216491,
    "out:El kr savings": 7239.898013663189,
    "out:El kr sold": 36297.18664221638,
    "out:El kr saved": 36526.320146,
    "out:El kr return": 72823.50678821639,
    "out:% solar/total": 46,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 135002.118013,
    "out:% savings (space heating)": 66.84153,
    "out:Total_Water Heating (DH)": 132772.760051,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 997124.4110739925,
    "out:Eupp": 42.4,
    "out:Etvv": 25,
    "out:Ef": 11.43,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 8678926.20266592,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 30.238656,
    "out:EL CO2": -36.891473,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -38.403999,
    "out:Bought CO2": 1.512526,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00000000010001001",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 45.332944,
    "out:DHW (DH)": 35.445,
    "out:Property Electricity": 17.169,
    "out:Electricity (inc PV)": 11.433081,
    "out:Total Energy Use Pre PV": 98.169,
    "out:Total Energy Use Post PV": 92.433081,
    "out:Primary Energy": 70.713502,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 54.607523,
    "out:CO2 Operational/m2": -7.732769,
    "out:Total CO2/m2": 46.874754,
    "out:Total CO2 (tons)": 149.249172,
    "out:Klimatpaverkan": -50.886249,
    "out:Initial Cost/MSEK": 8.87254222766592,
    "out:Running cost/(Apt SEK y)": 17348.468085,
    "out:Running Cost over RSP/MSEK": 6.3831,
    "out:LCP/MSEK": -1.850922,
    "out:ROI% old": 11.305537,
    "out:Payback discounted": 11,
    "out:Atemp": 3184,
    "out:Umean": 0.35,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 92,
    "out:Energy use kWh/m2": 92,
    "out:Energy savings %": 98.9,
    "out:Op energy cost/MSEK": 47,
    "out:El price/MSEK": 29,
    "out:Heating price/MSEK": 16,
    "out:DH+ DHW/kWh/m2": 81,
    "out:El bought/kWh/m2": 807.631491,
    "out:Return %": 10,
    "out:Return/kSEK/y": 899,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 35.445,
    "out:EAHP (El)": 0,
    "out:PV (kr/kWp)": 17461.56255567083,
    "out:PV (kWp installed)": 45.25485,
    "out:PV (m2 panels)": 237.433632,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 42461.284501,
    "out:PV (kWh sold)": 24198.124428,
    "out:PV (% sold (El))": 56.988677,
    "out:PV (kWh self-consumed)": 18263.160073,
    "out:PV (ratio sold/self-consumed)": 1.324969,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 1362005.6417737305,
    "out:FTX (Diffusers)": 206476.5625,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.08,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 282718.040289,
    "out:EL kWh savings": 3619.949007,
    "out:Sewage heat recovery cost": 273216,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 326255.12,
    "out:DH kr savings": 282718.0402889932,
    "out:El kr savings": 7239.898013663189,
    "out:El kr sold": 36297.18664221638,
    "out:El kr saved": 36526.320146,
    "out:El kr return": 72823.50678821639,
    "out:% solar/total": 46,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 144340.049949,
    "out:% savings (space heating)": 64.547999,
    "out:Total_Water Heating (DH)": 112856.846044,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 997124.4110739925,
    "out:Eupp": 45.33,
    "out:Etvv": 21.25,
    "out:Ef": 11.43,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 8872542.22766592,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 29.158704,
    "out:EL CO2": -36.891473,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -38.403999,
    "out:Bought CO2": 1.512526,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00000000010001101",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 42.400175,
    "out:DHW (DH)": 35.445,
    "out:Property Electricity": 17.169,
    "out:Electricity (inc PV)": 11.433081,
    "out:Total Energy Use Pre PV": 95.169,
    "out:Total Energy Use Post PV": 89.433081,
    "out:Primary Energy": 68.43246,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 54.607523,
    "out:CO2 Operational/m2": -8.812721,
    "out:Total CO2/m2": 45.794802,
    "out:Total CO2 (tons)": 145.810606,
    "out:Klimatpaverkan": -54.324815,
    "out:Initial Cost/MSEK": 8.95214220266592,
    "out:Running cost/(Apt SEK y)": 16734.702128,
    "out:Running Cost over RSP/MSEK": 6.15747,
    "out:LCP/MSEK": -1.704892,
    "out:ROI% old": 11.565069,
    "out:Payback discounted": 10,
    "out:Atemp": 3184,
    "out:Umean": 0.35,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 89,
    "out:Energy use kWh/m2": 89,
    "out:Energy savings %": 105.6,
    "out:Op energy cost/MSEK": 45,
    "out:El price/MSEK": 28,
    "out:Heating price/MSEK": 15,
    "out:DH+ DHW/kWh/m2": 78,
    "out:El bought/kWh/m2": 807.631491,
    "out:Return %": 10,
    "out:Return/kSEK/y": 928,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 35.445,
    "out:EAHP (El)": 0,
    "out:PV (kr/kWp)": 17461.56255567083,
    "out:PV (kWp installed)": 45.25485,
    "out:PV (m2 panels)": 237.433632,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 42461.284501,
    "out:PV (kWh sold)": 24198.124428,
    "out:PV (% sold (El))": 56.988677,
    "out:PV (kWh self-consumed)": 18263.160073,
    "out:PV (ratio sold/self-consumed)": 1.324969,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 1362005.6417737305,
    "out:FTX (Diffusers)": 206476.5625,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.08,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 292055.972224,
    "out:EL kWh savings": 3619.949007,
    "out:Sewage heat recovery cost": 273216,
    "out:Balancing hydronic system cost": 79599.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 335593.06,
    "out:DH kr savings": 292055.972224201,
    "out:El kr savings": 7239.898013663189,
    "out:El kr sold": 36297.18664221638,
    "out:El kr saved": 36526.320146,
    "out:El kr return": 72823.50678821639,
    "out:% solar/total": 46,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 135002.118013,
    "out:% savings (space heating)": 66.84153,
    "out:Total_Water Heating (DH)": 112856.846044,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 997124.4110739925,
    "out:Eupp": 42.4,
    "out:Etvv": 21.25,
    "out:Ef": 11.43,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 8952142.20266592,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 28.078752,
    "out:EL CO2": -36.891473,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -38.403999,
    "out:Bought CO2": 1.512526,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00000000020000001",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 45.332944,
    "out:DHW (DH)": 41.7,
    "out:Property Electricity": 17.169,
    "out:Electricity (inc PV)": 10.564609,
    "out:Total Energy Use Pre PV": 104.169,
    "out:Total Energy Use Post PV": 97.564609,
    "out:Primary Energy": 71.775252,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 68.638079,
    "out:CO2 Operational/m2": -69.156514,
    "out:Total CO2/m2": -0.518435,
    "out:Total CO2 (tons)": -1.650697,
    "out:Klimatpaverkan": -201.786118,
    "out:Initial Cost/MSEK": 9.389539579387169,
    "out:Running cost/(Apt SEK y)": 17191.446809,
    "out:Running Cost over RSP/MSEK": 6.33962,
    "out:LCP/MSEK": -2.32444,
    "out:ROI% old": 10.749196,
    "out:Payback discounted": 11,
    "out:Atemp": 3184,
    "out:Umean": 0.35,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 98,
    "out:Energy use kWh/m2": 98,
    "out:Energy savings %": 86.7,
    "out:Op energy cost/MSEK": 47,
    "out:El price/MSEK": 27,
    "out:Heating price/MSEK": 17,
    "out:DH+ DHW/kWh/m2": 87,
    "out:El bought/kWh/m2": 744.654907,
    "out:Return %": 10,
    "out:Return/kSEK/y": 907,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 41.7,
    "out:EAHP (El)": 0,
    "out:PV (kr/kWp)": 17461.48474631725,
    "out:PV (kWp installed)": 90.5097,
    "out:PV (m2 panels)": 474.867263,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 84922.568824,
    "out:PV (kWh sold)": 63894.192746,
    "out:PV (% sold (El))": 75.238177,
    "out:PV (kWh self-consumed)": 21028.376078,
    "out:PV (ratio sold/self-consumed)": 3.038475,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 1362005.6417737305,
    "out:FTX (Diffusers)": 206476.5625,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.08,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 262802.126281,
    "out:EL kWh savings": 6385.163023,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 371413.74,
    "out:DH kr savings": 262802.12628128316,
    "out:El kr savings": 12770.326045670818,
    "out:El kr sold": 95841.28911946694,
    "out:El kr saved": 42056.752156,
    "out:El kr return": 137898.04127546694,
    "out:% solar/total": 53,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 144340.049949,
    "out:% savings (space heating)": 64.547999,
    "out:Total_Water Heating (DH)": 132772.760051,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 997124.4110739925,
    "out:Eupp": 45.33,
    "out:Etvv": 25,
    "out:Ef": 10.56,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 9389539.57938717,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 31.318608,
    "out:EL CO2": -100.475122,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -101.871982,
    "out:Bought CO2": 1.39686,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00000000020000101",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 42.400175,
    "out:DHW (DH)": 41.7,
    "out:Property Electricity": 17.169,
    "out:Electricity (inc PV)": 10.564609,
    "out:Total Energy Use Pre PV": 101.169,
    "out:Total Energy Use Post PV": 94.564609,
    "out:Primary Energy": 69.49421,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 68.638079,
    "out:CO2 Operational/m2": -70.236466,
    "out:Total CO2/m2": -1.598387,
    "out:Total CO2 (tons)": -5.089263,
    "out:Klimatpaverkan": -205.224684,
    "out:Initial Cost/MSEK": 9.469139554387171,
    "out:Running cost/(Apt SEK y)": 16577.680851,
    "out:Running Cost over RSP/MSEK": 6.11399,
    "out:LCP/MSEK": -2.17841,
    "out:ROI% old": 10.999234,
    "out:Payback discounted": 11,
    "out:Atemp": 3184,
    "out:Umean": 0.35,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 95,
    "out:Energy use kWh/m2": 95,
    "out:Energy savings %": 92.6,
    "out:Op energy cost/MSEK": 46,
    "out:El price/MSEK": 27,
    "out:Heating price/MSEK": 16,
    "out:DH+ DHW/kWh/m2": 84,
    "out:El bought/kWh/m2": 744.654907,
    "out:Return %": 10,
    "out:Return/kSEK/y": 936,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 41.7,
    "out:EAHP (El)": 0,
    "out:PV (kr/kWp)": 17461.48474631725,
    "out:PV (kWp installed)": 90.5097,
    "out:PV (m2 panels)": 474.867263,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 84922.568824,
    "out:PV (kWh sold)": 63894.192746,
    "out:PV (% sold (El))": 75.238177,
    "out:PV (kWh self-consumed)": 21028.376078,
    "out:PV (ratio sold/self-consumed)": 3.038475,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 1362005.6417737305,
    "out:FTX (Diffusers)": 206476.5625,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.08,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 272140.058216,
    "out:EL kWh savings": 6385.163023,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 79599.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 380751.67,
    "out:DH kr savings": 272140.058216491,
    "out:El kr savings": 12770.326045670818,
    "out:El kr sold": 95841.28911946694,
    "out:El kr saved": 42056.752156,
    "out:El kr return": 137898.04127546694,
    "out:% solar/total": 53,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 135002.118013,
    "out:% savings (space heating)": 66.84153,
    "out:Total_Water Heating (DH)": 132772.760051,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 997124.4110739925,
    "out:Eupp": 42.4,
    "out:Etvv": 25,
    "out:Ef": 10.56,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 9469139.55438717,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 30.238656,
    "out:EL CO2": -100.475122,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -101.871982,
    "out:Bought CO2": 1.39686,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00000000020001001",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 45.332944,
    "out:DHW (DH)": 35.445,
    "out:Property Electricity": 17.169,
    "out:Electricity (inc PV)": 10.564609,
    "out:Total Energy Use Pre PV": 98.169,
    "out:Total Energy Use Post PV": 91.564609,
    "out:Primary Energy": 69.150252,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 69.346622,
    "out:CO2 Operational/m2": -71.316418,
    "out:Total CO2/m2": -1.969796,
    "out:Total CO2 (tons)": -6.271829,
    "out:Klimatpaverkan": -206.40725,
    "out:Initial Cost/MSEK": 9.66275557938717,
    "out:Running cost/(Apt SEK y)": 15963.914894,
    "out:Running Cost over RSP/MSEK": 5.88836,
    "out:LCP/MSEK": -2.146396,
    "out:ROI% old": 11.112417,
    "out:Payback discounted": 11,
    "out:Atemp": 3184,
    "out:Umean": 0.35,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 92,
    "out:Energy use kWh/m2": 92,
    "out:Energy savings %": 98.9,
    "out:Op energy cost/MSEK": 44,
    "out:El price/MSEK": 26,
    "out:Heating price/MSEK": 16,
    "out:DH+ DHW/kWh/m2": 81,
    "out:El bought/kWh/m2": 744.654907,
    "out:Return %": 10,
    "out:Return/kSEK/y": 964,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 35.445,
    "out:EAHP (El)": 0,
    "out:PV (kr/kWp)": 17461.48474631725,
    "out:PV (kWp installed)": 90.5097,
    "out:PV (m2 panels)": 474.867263,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 84922.568824,
    "out:PV (kWh sold)": 63894.192746,
    "out:PV (% sold (El))": 75.238177,
    "out:PV (kWh self-consumed)": 21028.376078,
    "out:PV (ratio sold/self-consumed)": 3.038475,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 1362005.6417737305,
    "out:FTX (Diffusers)": 206476.5625,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.08,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 282718.040289,
    "out:EL kWh savings": 6385.163023,
    "out:Sewage heat recovery cost": 273216,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 391329.66,
    "out:DH kr savings": 282718.0402889932,
    "out:El kr savings": 12770.326045670818,
    "out:El kr sold": 95841.28911946694,
    "out:El kr saved": 42056.752156,
    "out:El kr return": 137898.04127546694,
    "out:% solar/total": 53,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 144340.049949,
    "out:% savings (space heating)": 64.547999,
    "out:Total_Water Heating (DH)": 112856.846044,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 997124.4110739925,
    "out:Eupp": 45.33,
    "out:Etvv": 21.25,
    "out:Ef": 10.56,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 9662755.57938717,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 29.158704,
    "out:EL CO2": -100.475122,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -101.871982,
    "out:Bought CO2": 1.39686,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00000000020001101",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 42.400175,
    "out:DHW (DH)": 35.445,
    "out:Property Electricity": 17.169,
    "out:Electricity (inc PV)": 10.564609,
    "out:Total Energy Use Pre PV": 95.169,
    "out:Total Energy Use Post PV": 88.564609,
    "out:Primary Energy": 66.86921,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 69.346622,
    "out:CO2 Operational/m2": -72.39637,
    "out:Total CO2/m2": -3.049748,
    "out:Total CO2 (tons)": -9.710395,
    "out:Klimatpaverkan": -209.845816,
    "out:Initial Cost/MSEK": 9.74235555438717,
    "out:Running cost/(Apt SEK y)": 15350.148936,
    "out:Running Cost over RSP/MSEK": 5.66272,
    "out:LCP/MSEK": -2.000356,
    "out:ROI% old": 11.352491,
    "out:Payback discounted": 11,
    "out:Atemp": 3184,
    "out:Umean": 0.35,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 89,
    "out:Energy use kWh/m2": 89,
    "out:Energy savings %": 105.6,
    "out:Op energy cost/MSEK": 42,
    "out:El price/MSEK": 25,
    "out:Heating price/MSEK": 15,
    "out:DH+ DHW/kWh/m2": 78,
    "out:El bought/kWh/m2": 744.654907,
    "out:Return %": 10,
    "out:Return/kSEK/y": 993,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 35.445,
    "out:EAHP (El)": 0,
    "out:PV (kr/kWp)": 17461.48474631725,
    "out:PV (kWp installed)": 90.5097,
    "out:PV (m2 panels)": 474.867263,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 84922.568824,
    "out:PV (kWh sold)": 63894.192746,
    "out:PV (% sold (El))": 75.238177,
    "out:PV (kWh self-consumed)": 21028.376078,
    "out:PV (ratio sold/self-consumed)": 3.038475,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 1362005.6417737305,
    "out:FTX (Diffusers)": 206476.5625,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.08,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 292055.972224,
    "out:EL kWh savings": 6385.163023,
    "out:Sewage heat recovery cost": 273216,
    "out:Balancing hydronic system cost": 79599.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 400667.59,
    "out:DH kr savings": 292055.972224201,
    "out:El kr savings": 12770.326045670818,
    "out:El kr sold": 95841.28911946694,
    "out:El kr saved": 42056.752156,
    "out:El kr return": 137898.04127546694,
    "out:% solar/total": 53,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 135002.118013,
    "out:% savings (space heating)": 66.84153,
    "out:Total_Water Heating (DH)": 112856.846044,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 997124.4110739925,
    "out:Eupp": 42.4,
    "out:Etvv": 21.25,
    "out:Ef": 10.56,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 9742355.55438717,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 28.078752,
    "out:EL CO2": -100.475122,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -101.871982,
    "out:Bought CO2": 1.39686,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00000000100000001",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 45.332944,
    "out:DHW (DH)": 33.36,
    "out:Property Electricity": 17.169,
    "out:Electricity (inc PV)": 17.169,
    "out:Total Energy Use Pre PV": 96.169,
    "out:Total Energy Use Post PV": 96.169,
    "out:Primary Energy": 80.163156,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 39.15988,
    "out:CO2 Operational/m2": 30.705181,
    "out:Total CO2/m2": 69.865061,
    "out:Total CO2 (tons)": 222.450287,
    "out:Klimatpaverkan": 22.314866,
    "out:Initial Cost/MSEK": 7.93315352094342,
    "out:Running cost/(Apt SEK y)": 18488.744681,
    "out:Running Cost over RSP/MSEK": 6.78691,
    "out:LCP/MSEK": -1.315344,
    "out:ROI% old": 11.917095,
    "out:Payback discounted": 10,
    "out:Atemp": 3184,
    "out:Umean": 0.35,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 96,
    "out:Energy use kWh/m2": 96,
    "out:Energy savings %": 90.6,
    "out:Op energy cost/MSEK": 49,
    "out:El price/MSEK": 31,
    "out:Heating price/MSEK": 15,
    "out:DH+ DHW/kWh/m2": 79,
    "out:El bought/kWh/m2": 1201.83,
    "out:Return %": 11,
    "out:Return/kSEK/y": 846,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 33.36,
    "out:EAHP (El)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 1362005.6417737305,
    "out:FTX (Diffusers)": 206476.5625,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.08,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 289356.678292,
    "out:EL kWh savings": -14643.211594,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 260070.26,
    "out:DH kr savings": 289356.6782915632,
    "out:El kr savings": -29286.423188315977,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 144340.049949,
    "out:% savings (space heating)": 64.547999,
    "out:Total_Water Heating (DH)": 106218.208041,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 997124.4110739925,
    "out:Eupp": 45.33,
    "out:Etvv": 20,
    "out:Ef": 17.17,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 7933153.52094342,
    "out:Seasonal Variation ROI (%)": 2,
    "out:Seasonal Variation Payback": 50,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 28.438736,
    "out:EL CO2": 2.266445,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 2.266445,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00000000100000101",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 42.400175,
    "out:DHW (DH)": 33.36,
    "out:Property Electricity": 17.169,
    "out:Electricity (inc PV)": 17.169,
    "out:Total Energy Use Pre PV": 93.169,
    "out:Total Energy Use Post PV": 93.169,
    "out:Primary Energy": 77.882114,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 39.15988,
    "out:CO2 Operational/m2": 29.625229,
    "out:Total CO2/m2": 68.785109,
    "out:Total CO2 (tons)": 219.011721,
    "out:Klimatpaverkan": 18.8763,
    "out:Initial Cost/MSEK": 8.01275349594342,
    "out:Running cost/(Apt SEK y)": 17874.978723,
    "out:Running Cost over RSP/MSEK": 6.56128,
    "out:LCP/MSEK": -1.169313,
    "out:ROI% old": 12.200978,
    "out:Payback discounted": 10,
    "out:Atemp": 3184,
    "out:Umean": 0.35,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 93,
    "out:Energy use kWh/m2": 93,
    "out:Energy savings %": 96.8,
    "out:Op energy cost/MSEK": 48,
    "out:El price/MSEK": 30,
    "out:Heating price/MSEK": 15,
    "out:DH+ DHW/kWh/m2": 76,
    "out:El bought/kWh/m2": 1201.83,
    "out:Return %": 11,
    "out:Return/kSEK/y": 875,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 33.36,
    "out:EAHP (El)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 1362005.6417737305,
    "out:FTX (Diffusers)": 206476.5625,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.08,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 298694.610227,
    "out:EL kWh savings": -14643.211594,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 79599.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 269408.19,
    "out:DH kr savings": 298694.610226771,
    "out:El kr savings": -29286.423188315977,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 135002.118013,
    "out:% savings (space heating)": 66.84153,
    "out:Total_Water Heating (DH)": 106218.208041,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 997124.4110739925,
    "out:Eupp": 42.4,
    "out:Etvv": 20,
    "out:Ef": 17.17,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 8012753.49594342,
    "out:Seasonal Variation ROI (%)": 2,
    "out:Seasonal Variation Payback": 50,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 27.358784,
    "out:EL CO2": 2.266445,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 2.266445,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00000000100001001",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 45.332944,
    "out:DHW (DH)": 28.356,
    "out:Property Electricity": 17.169,
    "out:Electricity (inc PV)": 17.169,
    "out:Total Energy Use Pre PV": 91.169,
    "out:Total Energy Use Post PV": 91.169,
    "out:Primary Energy": 78.063156,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 39.868423,
    "out:CO2 Operational/m2": 28.905261,
    "out:Total CO2/m2": 68.773684,
    "out:Total CO2 (tons)": 218.975344,
    "out:Klimatpaverkan": 18.839923,
    "out:Initial Cost/MSEK": 8.20636952094342,
    "out:Running cost/(Apt SEK y)": 17465.787234,
    "out:Running Cost over RSP/MSEK": 6.41086,
    "out:LCP/MSEK": -1.21251,
    "out:ROI% old": 12.174968,
    "out:Payback discounted": 10,
    "out:Atemp": 3184,
    "out:Umean": 0.35,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 91,
    "out:Energy use kWh/m2": 91,
    "out:Energy savings %": 101.1,
    "out:Op energy cost/MSEK": 46,
    "out:El price/MSEK": 30,
    "out:Heating price/MSEK": 14,
    "out:DH+ DHW/kWh/m2": 74,
    "out:El bought/kWh/m2": 1201.83,
    "out:Return %": 11,
    "out:Return/kSEK/y": 894,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 28.356,
    "out:EAHP (El)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 1362005.6417737305,
    "out:FTX (Diffusers)": 206476.5625,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.08,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 305289.409498,
    "out:EL kWh savings": -14643.211594,
    "out:Sewage heat recovery cost": 273216,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 276002.99,
    "out:DH kr savings": 305289.4094977312,
    "out:El kr savings": -29286.423188315977,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 144340.049949,
    "out:% savings (space heating)": 64.547999,
    "out:Total_Water Heating (DH)": 90285.476835,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 997124.4110739925,
    "out:Eupp": 45.33,
    "out:Etvv": 17,
    "out:Ef": 17.17,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 8206369.52094342,
    "out:Seasonal Variation ROI (%)": 2,
    "out:Seasonal Variation Payback": 50,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 26.638816,
    "out:EL CO2": 2.266445,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 2.266445,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00000000100001101",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 42.400175,
    "out:DHW (DH)": 28.356,
    "out:Property Electricity": 17.169,
    "out:Electricity (inc PV)": 17.169,
    "out:Total Energy Use Pre PV": 88.169,
    "out:Total Energy Use Post PV": 88.169,
    "out:Primary Energy": 75.782114,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 39.868423,
    "out:CO2 Operational/m2": 27.825309,
    "out:Total CO2/m2": 67.693732,
    "out:Total CO2 (tons)": 215.536778,
    "out:Klimatpaverkan": 15.401357,
    "out:Initial Cost/MSEK": 8.285969495943421,
    "out:Running cost/(Apt SEK y)": 16852.021277,
    "out:Running Cost over RSP/MSEK": 6.18522,
    "out:LCP/MSEK": -1.066469,
    "out:ROI% old": 12.44703,
    "out:Payback discounted": 10,
    "out:Atemp": 3184,
    "out:Umean": 0.35,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 88,
    "out:Energy use kWh/m2": 88,
    "out:Energy savings %": 108,
    "out:Op energy cost/MSEK": 45,
    "out:El price/MSEK": 29,
    "out:Heating price/MSEK": 14,
    "out:DH+ DHW/kWh/m2": 71,
    "out:El bought/kWh/m2": 1201.83,
    "out:Return %": 11,
    "out:Return/kSEK/y": 923,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 28.356,
    "out:EAHP (El)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 1362005.6417737305,
    "out:FTX (Diffusers)": 206476.5625,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.08,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 314627.341433,
    "out:EL kWh savings": -14643.211594,
    "out:Sewage heat recovery cost": 273216,
    "out:Balancing hydronic system cost": 79599.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 285340.92,
    "out:DH kr savings": 314627.341432939,
    "out:El kr savings": -29286.423188315977,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 135002.118013,
    "out:% savings (space heating)": 66.84153,
    "out:Total_Water Heating (DH)": 90285.476835,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 997124.4110739925,
    "out:Eupp": 42.4,
    "out:Etvv": 17,
    "out:Ef": 17.17,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 8285969.4959434215,
    "out:Seasonal Variation ROI (%)": 2,
    "out:Seasonal Variation Payback": 50,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 25.558864,
    "out:EL CO2": 2.266445,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": 0,
    "out:Bought CO2": 2.266445,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00000000110000001",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 45.332944,
    "out:DHW (DH)": 33.36,
    "out:Property Electricity": 17.169,
    "out:Electricity (inc PV)": 11.433081,
    "out:Total Energy Use Pre PV": 96.169,
    "out:Total Energy Use Post PV": 90.433081,
    "out:Primary Energy": 69.838502,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 53.89898,
    "out:CO2 Operational/m2": -8.452737,
    "out:Total CO2/m2": 45.446243,
    "out:Total CO2 (tons)": 144.700794,
    "out:Klimatpaverkan": -55.434627,
    "out:Initial Cost/MSEK": 8.72337391516592,
    "out:Running cost/(Apt SEK y)": 16939.297872,
    "out:Running Cost over RSP/MSEK": 6.23268,
    "out:LCP/MSEK": -1.551334,
    "out:ROI% old": 11.745193,
    "out:Payback discounted": 10,
    "out:Atemp": 3184,
    "out:Umean": 0.35,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 90,
    "out:Energy use kWh/m2": 90,
    "out:Energy savings %": 103.3,
    "out:Op energy cost/MSEK": 46,
    "out:El price/MSEK": 28,
    "out:Heating price/MSEK": 15,
    "out:DH+ DHW/kWh/m2": 79,
    "out:El bought/kWh/m2": 807.631491,
    "out:Return %": 11,
    "out:Return/kSEK/y": 919,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 33.36,
    "out:EAHP (El)": 0,
    "out:PV (kr/kWp)": 17461.56255567083,
    "out:PV (kWp installed)": 45.25485,
    "out:PV (m2 panels)": 237.433632,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 42461.284501,
    "out:PV (kWh sold)": 24198.124428,
    "out:PV (% sold (El))": 56.988677,
    "out:PV (kWh self-consumed)": 18263.160073,
    "out:PV (ratio sold/self-consumed)": 1.324969,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 1362005.6417737305,
    "out:FTX (Diffusers)": 206476.5625,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.08,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 289356.678292,
    "out:EL kWh savings": 3619.949007,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 332893.76,
    "out:DH kr savings": 289356.6782915632,
    "out:El kr savings": 7239.898013663189,
    "out:El kr sold": 36297.18664221638,
    "out:El kr saved": 36526.320146,
    "out:El kr return": 72823.50678821639,
    "out:% solar/total": 46,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 144340.049949,
    "out:% savings (space heating)": 64.547999,
    "out:Total_Water Heating (DH)": 106218.208041,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 997124.4110739925,
    "out:Eupp": 45.33,
    "out:Etvv": 20,
    "out:Ef": 11.43,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 8723373.91516592,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 28.438736,
    "out:EL CO2": -36.891473,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -38.403999,
    "out:Bought CO2": 1.512526,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00000000110000101",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 42.400175,
    "out:DHW (DH)": 33.36,
    "out:Property Electricity": 17.169,
    "out:Electricity (inc PV)": 11.433081,
    "out:Total Energy Use Pre PV": 93.169,
    "out:Total Energy Use Post PV": 87.433081,
    "out:Primary Energy": 67.55746,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 53.89898,
    "out:CO2 Operational/m2": -9.532689,
    "out:Total CO2/m2": 44.366291,
    "out:Total CO2 (tons)": 141.262228,
    "out:Klimatpaverkan": -58.873193,
    "out:Initial Cost/MSEK": 8.802973890165921,
    "out:Running cost/(Apt SEK y)": 16325.531915,
    "out:Running Cost over RSP/MSEK": 6.00705,
    "out:LCP/MSEK": -1.405304,
    "out:ROI% old": 12.005148,
    "out:Payback discounted": 10,
    "out:Atemp": 3184,
    "out:Umean": 0.35,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 87,
    "out:Energy use kWh/m2": 87,
    "out:Energy savings %": 110.3,
    "out:Op energy cost/MSEK": 44,
    "out:El price/MSEK": 27,
    "out:Heating price/MSEK": 15,
    "out:DH+ DHW/kWh/m2": 76,
    "out:El bought/kWh/m2": 807.631491,
    "out:Return %": 11,
    "out:Return/kSEK/y": 947,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 33.36,
    "out:EAHP (El)": 0,
    "out:PV (kr/kWp)": 17461.56255567083,
    "out:PV (kWp installed)": 45.25485,
    "out:PV (m2 panels)": 237.433632,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 42461.284501,
    "out:PV (kWh sold)": 24198.124428,
    "out:PV (% sold (El))": 56.988677,
    "out:PV (kWh self-consumed)": 18263.160073,
    "out:PV (ratio sold/self-consumed)": 1.324969,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 1362005.6417737305,
    "out:FTX (Diffusers)": 206476.5625,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.08,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 298694.610227,
    "out:EL kWh savings": 3619.949007,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 79599.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 342231.69,
    "out:DH kr savings": 298694.610226771,
    "out:El kr savings": 7239.898013663189,
    "out:El kr sold": 36297.18664221638,
    "out:El kr saved": 36526.320146,
    "out:El kr return": 72823.50678821639,
    "out:% solar/total": 46,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 135002.118013,
    "out:% savings (space heating)": 66.84153,
    "out:Total_Water Heating (DH)": 106218.208041,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 997124.4110739925,
    "out:Eupp": 42.4,
    "out:Etvv": 20,
    "out:Ef": 11.43,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 8802973.890165921,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 27.358784,
    "out:EL CO2": -36.891473,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -38.403999,
    "out:Bought CO2": 1.512526,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00000000110001001",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 45.332944,
    "out:DHW (DH)": 28.356,
    "out:Property Electricity": 17.169,
    "out:Electricity (inc PV)": 11.433081,
    "out:Total Energy Use Pre PV": 91.169,
    "out:Total Energy Use Post PV": 85.433081,
    "out:Primary Energy": 67.738502,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 54.607523,
    "out:CO2 Operational/m2": -10.252657,
    "out:Total CO2/m2": 44.354866,
    "out:Total CO2 (tons)": 141.225851,
    "out:Klimatpaverkan": -58.90957,
    "out:Initial Cost/MSEK": 8.99658991516592,
    "out:Running cost/(Apt SEK y)": 15916.361702,
    "out:Running Cost over RSP/MSEK": 5.85663,
    "out:LCP/MSEK": -1.4485,
    "out:ROI% old": 11.985637,
    "out:Payback discounted": 10,
    "out:Atemp": 3184,
    "out:Umean": 0.35,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 85,
    "out:Energy use kWh/m2": 85,
    "out:Energy savings %": 115.3,
    "out:Op energy cost/MSEK": 43,
    "out:El price/MSEK": 26,
    "out:Heating price/MSEK": 14,
    "out:DH+ DHW/kWh/m2": 74,
    "out:El bought/kWh/m2": 807.631491,
    "out:Return %": 11,
    "out:Return/kSEK/y": 967,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 28.356,
    "out:EAHP (El)": 0,
    "out:PV (kr/kWp)": 17461.56255567083,
    "out:PV (kWp installed)": 45.25485,
    "out:PV (m2 panels)": 237.433632,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 42461.284501,
    "out:PV (kWh sold)": 24198.124428,
    "out:PV (% sold (El))": 56.988677,
    "out:PV (kWh self-consumed)": 18263.160073,
    "out:PV (ratio sold/self-consumed)": 1.324969,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 1362005.6417737305,
    "out:FTX (Diffusers)": 206476.5625,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.08,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 305289.409498,
    "out:EL kWh savings": 3619.949007,
    "out:Sewage heat recovery cost": 273216,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 348826.49,
    "out:DH kr savings": 305289.4094977312,
    "out:El kr savings": 7239.898013663189,
    "out:El kr sold": 36297.18664221638,
    "out:El kr saved": 36526.320146,
    "out:El kr return": 72823.50678821639,
    "out:% solar/total": 46,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 144340.049949,
    "out:% savings (space heating)": 64.547999,
    "out:Total_Water Heating (DH)": 90285.476835,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 997124.4110739925,
    "out:Eupp": 45.33,
    "out:Etvv": 17,
    "out:Ef": 11.43,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 8996589.91516592,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 26.638816,
    "out:EL CO2": -36.891473,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -38.403999,
    "out:Bought CO2": 1.512526,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00000000110001101",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 42.400175,
    "out:DHW (DH)": 28.356,
    "out:Property Electricity": 17.169,
    "out:Electricity (inc PV)": 11.433081,
    "out:Total Energy Use Pre PV": 88.169,
    "out:Total Energy Use Post PV": 82.433081,
    "out:Primary Energy": 65.45746,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 54.607523,
    "out:CO2 Operational/m2": -11.332609,
    "out:Total CO2/m2": 43.274914,
    "out:Total CO2 (tons)": 137.787285,
    "out:Klimatpaverkan": -62.348136,
    "out:Initial Cost/MSEK": 9.07618989016592,
    "out:Running cost/(Apt SEK y)": 15302.595745,
    "out:Running Cost over RSP/MSEK": 5.63099,
    "out:LCP/MSEK": -1.30246,
    "out:ROI% old": 12.235672,
    "out:Payback discounted": 10,
    "out:Atemp": 3184,
    "out:Umean": 0.35,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 82,
    "out:Energy use kWh/m2": 82,
    "out:Energy savings %": 123.2,
    "out:Op energy cost/MSEK": 41,
    "out:El price/MSEK": 25,
    "out:Heating price/MSEK": 14,
    "out:DH+ DHW/kWh/m2": 71,
    "out:El bought/kWh/m2": 807.631491,
    "out:Return %": 11,
    "out:Return/kSEK/y": 995,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 28.356,
    "out:EAHP (El)": 0,
    "out:PV (kr/kWp)": 17461.56255567083,
    "out:PV (kWp installed)": 45.25485,
    "out:PV (m2 panels)": 237.433632,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 42461.284501,
    "out:PV (kWh sold)": 24198.124428,
    "out:PV (% sold (El))": 56.988677,
    "out:PV (kWh self-consumed)": 18263.160073,
    "out:PV (ratio sold/self-consumed)": 1.324969,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 1362005.6417737305,
    "out:FTX (Diffusers)": 206476.5625,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.08,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 314627.341433,
    "out:EL kWh savings": 3619.949007,
    "out:Sewage heat recovery cost": 273216,
    "out:Balancing hydronic system cost": 79599.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 358164.43,
    "out:DH kr savings": 314627.341432939,
    "out:El kr savings": 7239.898013663189,
    "out:El kr sold": 36297.18664221638,
    "out:El kr saved": 36526.320146,
    "out:El kr return": 72823.50678821639,
    "out:% solar/total": 46,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 135002.118013,
    "out:% savings (space heating)": 66.84153,
    "out:Total_Water Heating (DH)": 90285.476835,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 997124.4110739925,
    "out:Eupp": 42.4,
    "out:Etvv": 17,
    "out:Ef": 11.43,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 9076189.890165921,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 25.558864,
    "out:EL CO2": -36.891473,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -38.403999,
    "out:Bought CO2": 1.512526,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00000000120000001",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 45.332944,
    "out:DHW (DH)": 33.36,
    "out:Property Electricity": 17.169,
    "out:Electricity (inc PV)": 10.564609,
    "out:Total Energy Use Pre PV": 96.169,
    "out:Total Energy Use Post PV": 89.564609,
    "out:Primary Energy": 68.275252,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 68.638079,
    "out:CO2 Operational/m2": -72.036386,
    "out:Total CO2/m2": -3.398307,
    "out:Total CO2 (tons)": -10.820206,
    "out:Klimatpaverkan": -210.955627,
    "out:Initial Cost/MSEK": 9.51358726688717,
    "out:Running cost/(Apt SEK y)": 15554.744681,
    "out:Running Cost over RSP/MSEK": 5.73793,
    "out:LCP/MSEK": -1.846797,
    "out:ROI% old": 11.512542,
    "out:Payback discounted": 10,
    "out:Atemp": 3184,
    "out:Umean": 0.35,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 90,
    "out:Energy use kWh/m2": 90,
    "out:Energy savings %": 103.3,
    "out:Op energy cost/MSEK": 43,
    "out:El price/MSEK": 25,
    "out:Heating price/MSEK": 15,
    "out:DH+ DHW/kWh/m2": 79,
    "out:El bought/kWh/m2": 744.654907,
    "out:Return %": 10,
    "out:Return/kSEK/y": 984,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 33.36,
    "out:EAHP (El)": 0,
    "out:PV (kr/kWp)": 17461.48474631725,
    "out:PV (kWp installed)": 90.5097,
    "out:PV (m2 panels)": 474.867263,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 84922.568824,
    "out:PV (kWh sold)": 63894.192746,
    "out:PV (% sold (El))": 75.238177,
    "out:PV (kWh self-consumed)": 21028.376078,
    "out:PV (ratio sold/self-consumed)": 3.038475,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 1362005.6417737305,
    "out:FTX (Diffusers)": 206476.5625,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.08,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 289356.678292,
    "out:EL kWh savings": 6385.163023,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 397968.29,
    "out:DH kr savings": 289356.6782915632,
    "out:El kr savings": 12770.326045670818,
    "out:El kr sold": 95841.28911946694,
    "out:El kr saved": 42056.752156,
    "out:El kr return": 137898.04127546694,
    "out:% solar/total": 53,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 144340.049949,
    "out:% savings (space heating)": 64.547999,
    "out:Total_Water Heating (DH)": 106218.208041,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 997124.4110739925,
    "out:Eupp": 45.33,
    "out:Etvv": 20,
    "out:Ef": 10.56,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 9513587.26688717,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 28.438736,
    "out:EL CO2": -100.475122,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -101.871982,
    "out:Bought CO2": 1.39686,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00000000120000101",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 42.400175,
    "out:DHW (DH)": 33.36,
    "out:Property Electricity": 17.169,
    "out:Electricity (inc PV)": 10.564609,
    "out:Total Energy Use Pre PV": 93.169,
    "out:Total Energy Use Post PV": 86.564609,
    "out:Primary Energy": 65.99421,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 68.638079,
    "out:CO2 Operational/m2": -73.116338,
    "out:Total CO2/m2": -4.478259,
    "out:Total CO2 (tons)": -14.258772,
    "out:Klimatpaverkan": -214.394193,
    "out:Initial Cost/MSEK": 9.59318724188717,
    "out:Running cost/(Apt SEK y)": 14940.978723,
    "out:Running Cost over RSP/MSEK": 5.5123,
    "out:LCP/MSEK": -1.700767,
    "out:ROI% old": 11.753013,
    "out:Payback discounted": 10,
    "out:Atemp": 3184,
    "out:Umean": 0.35,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 87,
    "out:Energy use kWh/m2": 87,
    "out:Energy savings %": 110.3,
    "out:Op energy cost/MSEK": 41,
    "out:El price/MSEK": 24,
    "out:Heating price/MSEK": 15,
    "out:DH+ DHW/kWh/m2": 76,
    "out:El bought/kWh/m2": 744.654907,
    "out:Return %": 11,
    "out:Return/kSEK/y": 1012,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 33.36,
    "out:EAHP (El)": 0,
    "out:PV (kr/kWp)": 17461.48474631725,
    "out:PV (kWp installed)": 90.5097,
    "out:PV (m2 panels)": 474.867263,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 84922.568824,
    "out:PV (kWh sold)": 63894.192746,
    "out:PV (% sold (El))": 75.238177,
    "out:PV (kWh self-consumed)": 21028.376078,
    "out:PV (ratio sold/self-consumed)": 3.038475,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 1362005.6417737305,
    "out:FTX (Diffusers)": 206476.5625,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.08,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 298694.610227,
    "out:EL kWh savings": 6385.163023,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 79599.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 407306.23,
    "out:DH kr savings": 298694.610226771,
    "out:El kr savings": 12770.326045670818,
    "out:El kr sold": 95841.28911946694,
    "out:El kr saved": 42056.752156,
    "out:El kr return": 137898.04127546694,
    "out:% solar/total": 53,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 135002.118013,
    "out:% savings (space heating)": 66.84153,
    "out:Total_Water Heating (DH)": 106218.208041,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 997124.4110739925,
    "out:Eupp": 42.4,
    "out:Etvv": 20,
    "out:Ef": 10.56,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 9593187.24188717,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 27.358784,
    "out:EL CO2": -100.475122,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -101.871982,
    "out:Bought CO2": 1.39686,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00000000120001001",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 45.332944,
    "out:DHW (DH)": 28.356,
    "out:Property Electricity": 17.169,
    "out:Electricity (inc PV)": 10.564609,
    "out:Total Energy Use Pre PV": 91.169,
    "out:Total Energy Use Post PV": 84.564609,
    "out:Primary Energy": 66.175252,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 69.346622,
    "out:CO2 Operational/m2": -73.836306,
    "out:Total CO2/m2": -4.489684,
    "out:Total CO2 (tons)": -14.29515,
    "out:Klimatpaverkan": -214.430571,
    "out:Initial Cost/MSEK": 9.78680326688717,
    "out:Running cost/(Apt SEK y)": 14531.787234,
    "out:Running Cost over RSP/MSEK": 5.36188,
    "out:LCP/MSEK": -1.743963,
    "out:ROI% old": 11.740066,
    "out:Payback discounted": 10,
    "out:Atemp": 3184,
    "out:Umean": 0.35,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 85,
    "out:Energy use kWh/m2": 85,
    "out:Energy savings %": 115.3,
    "out:Op energy cost/MSEK": 40,
    "out:El price/MSEK": 23,
    "out:Heating price/MSEK": 14,
    "out:DH+ DHW/kWh/m2": 74,
    "out:El bought/kWh/m2": 744.654907,
    "out:Return %": 11,
    "out:Return/kSEK/y": 1032,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 28.356,
    "out:EAHP (El)": 0,
    "out:PV (kr/kWp)": 17461.48474631725,
    "out:PV (kWp installed)": 90.5097,
    "out:PV (m2 panels)": 474.867263,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 84922.568824,
    "out:PV (kWh sold)": 63894.192746,
    "out:PV (% sold (El))": 75.238177,
    "out:PV (kWh self-consumed)": 21028.376078,
    "out:PV (ratio sold/self-consumed)": 3.038475,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 1362005.6417737305,
    "out:FTX (Diffusers)": 206476.5625,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.08,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 305289.409498,
    "out:EL kWh savings": 6385.163023,
    "out:Sewage heat recovery cost": 273216,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 413901.02,
    "out:DH kr savings": 305289.4094977312,
    "out:El kr savings": 12770.326045670818,
    "out:El kr sold": 95841.28911946694,
    "out:El kr saved": 42056.752156,
    "out:El kr return": 137898.04127546694,
    "out:% solar/total": 53,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 144340.049949,
    "out:% savings (space heating)": 64.547999,
    "out:Total_Water Heating (DH)": 90285.476835,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 997124.4110739925,
    "out:Eupp": 45.33,
    "out:Etvv": 17,
    "out:Ef": 10.56,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 9786803.26688717,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 26.638816,
    "out:EL CO2": -100.475122,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -101.871982,
    "out:Bought CO2": 1.39686,
    "out:Self-consume CO2": 0
  },
  {
    "ID": "Z00000000120001101",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 42.400175,
    "out:DHW (DH)": 28.356,
    "out:Property Electricity": 17.169,
    "out:Electricity (inc PV)": 10.564609,
    "out:Total Energy Use Pre PV": 88.169,
    "out:Total Energy Use Post PV": 81.564609,
    "out:Primary Energy": 63.89421,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 69.346622,
    "out:CO2 Operational/m2": -74.916258,
    "out:Total CO2/m2": -5.569636,
    "out:Total CO2 (tons)": -17.733716,
    "out:Klimatpaverkan": -217.869137,
    "out:Initial Cost/MSEK": 9.866403241887172,
    "out:Running cost/(Apt SEK y)": 13918.021277,
    "out:Running Cost over RSP/MSEK": 5.13624,
    "out:LCP/MSEK": -1.597923,
    "out:ROI% old": 11.972057,
    "out:Payback discounted": 10,
    "out:Atemp": 3184,
    "out:Umean": 0.35,
    "out:Roof surface/m2": 949.7345262132601,
    "out:Facade surface/m2": 1441.2072566522265,
    "out:Window surface/m2": 335.40564774025523,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 82,
    "out:Energy use kWh/m2": 82,
    "out:Energy savings %": 123.2,
    "out:Op energy cost/MSEK": 38,
    "out:El price/MSEK": 22,
    "out:Heating price/MSEK": 14,
    "out:DH+ DHW/kWh/m2": 71,
    "out:El bought/kWh/m2": 744.654907,
    "out:Return %": 11,
    "out:Return/kSEK/y": 1061,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 82997.90798763197,
    "out:EAHP (pipework)": 82744.175103975,
    "out:EAHP (natural ventilation grills)": 223749.375,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378887399541,
    "out:EAHP (S to F improvement cost)": 0,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 28.356,
    "out:EAHP (El)": 0,
    "out:PV (kr/kWp)": 17461.48474631725,
    "out:PV (kWp installed)": 90.5097,
    "out:PV (m2 panels)": 474.867263,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 84922.568824,
    "out:PV (kWh sold)": 63894.192746,
    "out:PV (% sold (El))": 75.238177,
    "out:PV (kWh self-consumed)": 21028.376078,
    "out:PV (ratio sold/self-consumed)": 3.038475,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 1362005.6417737305,
    "out:FTX (Diffusers)": 206476.5625,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153278.361687396,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 47,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 33.36,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 580450,
    "out:DH (pipework)": 646250,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.67,
    "out:Roof U-value (after)": 0.08,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.2750000000001,
    "out:FTX (Roof)": 801837.1169386499,
    "out:FTX (Facade)": 3939035.61446955,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 314627.341433,
    "out:EL kWh savings": 6385.163023,
    "out:Sewage heat recovery cost": 273216,
    "out:Balancing hydronic system cost": 79599.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 423238.96,
    "out:DH kr savings": 314627.341432939,
    "out:El kr savings": 12770.326045670818,
    "out:El kr sold": 95841.28911946694,
    "out:El kr saved": 42056.752156,
    "out:El kr return": 137898.04127546694,
    "out:% solar/total": 53,
    "out:Total_El consumption (without PV)": 40022.867958,
    "out:Total_Space Heating (DH)": 135002.118013,
    "out:% savings (space heating)": 66.84153,
    "out:Total_Water Heating (DH)": 90285.476835,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 12,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 997124.4110739925,
    "out:Eupp": 42.4,
    "out:Etvv": 17,
    "out:Ef": 10.56,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1,
    "out:Initial Cost/SEK": 9866403.24188717,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333,
    "out:New AHU Cost/SEK": 0,
    "out:DH CO2": 25.558864,
    "out:EL CO2": -100.475122,
    "out:Year": 0,
    "out:El CO2/kWh": 0.022,
    "out:DH CO2/kWh": 0.06,
    "out:Sold CO2": -101.871982,
    "out:Bought CO2": 1.39686,
    "out:Self-consume CO2": 0
  }
]