import CloseIcon from "@mui/icons-material/Close";
import InfoTwoToneIcon from "@mui/icons-material/InfoTwoTone";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemText from "@mui/material/ListItemText";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import { type ReactElement, useCallback, useMemo, useState } from "react";
import type { InstallationItem } from "../installations";
import { IconRenderer } from "./IconRenderer";
import { installationBreakdownFieldMapping } from "./installationBreakdownFieldMapping";

export interface EnergyBreakdownProps {
  installationName: InstallationItem;
  installationData: Record<string, string | number>;
}
export const EnergyBreakdown = ({
  installationData,
  installationName,
}: EnergyBreakdownProps): ReactElement => {
  const [open, setOpen] = useState(false);

  const handleClickOpen = useCallback(() => {
    setOpen(true);
  }, []);

  const handleClose = useCallback(() => {
    setOpen(false);
  }, []);

  const data = useMemo(
    () =>
      installationBreakdownFieldMapping[installationName].energy.breakdown.map(
        ([name, fieldName, bold]) => ({
          name,
          value: installationData[fieldName],
          bold,
        }),
      ),
    [installationData, installationName],
  );

  return (
    <List
      sx={{
        width: "100%",
        maxWidth: 360,
        bgcolor: "background.paper",
      }}
    >
      {installationBreakdownFieldMapping[installationName].energy.preview.map(
        ({ filedName, label, icon, format, breakdown }) => {
          const value = installationData[filedName];

          return (
            <ListItem key={filedName}>
              <ListItemAvatar>
                <IconRenderer icon={icon} />
              </ListItemAvatar>
              <ListItemText
                primary={label}
                secondary={
                  <Typography variant="h4">
                    {format != null && String(value ? format(value) : value)}
                  </Typography>
                }
              />
              {data.length > 0 && Boolean(breakdown) && (
                <ListItemAvatar>
                  <IconButton
                    aria-label="cost breakdown"
                    onClick={handleClickOpen}
                  >
                    <InfoTwoToneIcon fontSize="large" />
                  </IconButton>
                </ListItemAvatar>
              )}
            </ListItem>
          );
        },
      )}

      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Kostnadsfördelning</DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 400 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>{installationName}</TableCell>
                <TableCell align="right">Värde</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data.map((row) => (
                <TableRow
                  key={row.name}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    {row.bold === true ? <b>{row.name}</b> : row.name}
                  </TableCell>
                  <TableCell align="right">
                    {row.bold === true ? <b>{row.value}</b> : row.value}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Dialog>
    </List>
  );
};
