import type { ReactElement } from "react";

export const Arrow = (): ReactElement => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
  >
    <title>Arrow</title>
    <path
      d="M12.9881 9.4525L9.69646 6.16083C9.54841 6.00296 9.46759 5.79368 9.47111 5.57728C9.47462 5.36087 9.56219 5.15433 9.71528 5.00134C9.86838 4.84836 10.075 4.76093 10.2914 4.75757C10.5078 4.75421 10.717 4.83518 10.8748 4.98333L15.589 9.69667C15.6666 9.77388 15.7282 9.86568 15.7703 9.96679C15.8123 10.0679 15.834 10.1763 15.834 10.2858C15.834 10.3953 15.8123 10.5038 15.7703 10.6049C15.7282 10.706 15.6666 10.7978 15.589 10.875L10.8748 15.5892C10.7979 15.6688 10.706 15.7322 10.6043 15.7759C10.5026 15.8196 10.3933 15.8426 10.2826 15.8435C10.172 15.8445 10.0622 15.8234 9.95983 15.7815C9.85741 15.7396 9.76437 15.6777 9.68613 15.5995C9.60788 15.5213 9.546 15.4282 9.5041 15.3258C9.4622 15.2234 9.44112 15.1136 9.44208 15.003C9.44304 14.8924 9.46603 14.783 9.5097 14.6813C9.55338 14.5797 9.61686 14.4877 9.69646 14.4108L12.9881 11.1192H4.99979C4.77877 11.1192 4.56681 11.0314 4.41053 10.8751C4.25425 10.7188 4.16645 10.5068 4.16645 10.2858C4.16645 10.0648 4.25425 9.85286 4.41053 9.69658C4.56681 9.5403 4.77877 9.4525 4.99979 9.4525H12.9881Z"
      fill="black"
      fillOpacity="0.16"
    />
  </svg>
);
