export default [
  {
    "ID": "Z00000000000000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 128.05,
    "out:DHW (DH)": 43.5,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 15.9,
    "out:Total Energy Use Pre PV": 187.9,
    "out:Total Energy Use Post PV": 187.9,
    "out:Primary Energy": 135.76,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 0,
    "out:CO2 Operational/m2": 64.02,
    "out:Total CO2/m2": 64.02,
    "out:Total CO2 (tons)": 329.24,
    "out:Klimatpaverkan": 0,
    "out:Initial Cost/MSEK": 0,
    "out:Running cost/(Apt SEK y)": 58665,
    "out:Running Cost over RSP/MSEK": 19.269,
    "out:LCP/MSEK": 0,
    "out:ROI% old": 0,
    "out:Payback discounted": 3,
    "out:Atemp": 5143,
    "out:Umean": 0.82,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 188,
    "out:Energy use kWh/m2": 188,
    "out:Energy savings %": 0,
    "out:Op energy cost/MSEK": 141,
    "out:El price/MSEK": 84,
    "out:Heating price/MSEK": 49,
    "out:DH+ DHW/kWh/m2": 172,
    "out:El bought/kWh/m2": 1113,
    "out:Return %": 100,
    "out:Return/kSEK/y": 0,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 0,
    "out:Payback Time": 0,
    "out:DH kWh savings": 0,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 0,
    "out:DH kr savings": 0,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 658585,
    "out:% savings (space heating)": 0,
    "out:Total_Water Heating (DH)": 223720,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 128.05,
    "out:Etvv": 25,
    "out:Ef": 15.9,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z00000000000000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 122.51,
    "out:DHW (DH)": 43.5,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 15.9,
    "out:Total Energy Use Pre PV": 181.9,
    "out:Total Energy Use Post PV": 181.9,
    "out:Primary Energy": 131.87,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 0,
    "out:CO2 Operational/m2": 61.86,
    "out:Total CO2/m2": 61.86,
    "out:Total CO2 (tons)": 318.13,
    "out:Klimatpaverkan": -11.11,
    "out:Initial Cost/MSEK": 0.128575,
    "out:Running cost/(Apt SEK y)": 56734,
    "out:Running Cost over RSP/MSEK": 18.634,
    "out:LCP/MSEK": 0.506,
    "out:ROI% old": 70.52,
    "out:Payback discounted": 2,
    "out:Atemp": 5143,
    "out:Umean": 0.82,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 182,
    "out:Energy use kWh/m2": 182,
    "out:Energy savings %": 3.3,
    "out:Op energy cost/MSEK": 137,
    "out:El price/MSEK": 81,
    "out:Heating price/MSEK": 48,
    "out:DH+ DHW/kWh/m2": 166,
    "out:El bought/kWh/m2": 1113,
    "out:Return %": 63,
    "out:Return/kSEK/y": 81,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 22,
    "out:Payback Time": 4.545455,
    "out:DH kWh savings": 30858,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 128574.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 28081,
    "out:DH kr savings": 28081,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 630052,
    "out:% savings (space heating)": 4.33,
    "out:Total_Water Heating (DH)": 223720,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 122.51,
    "out:Etvv": 25,
    "out:Ef": 15.9,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z00000000000001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 128.05,
    "out:DHW (DH)": 36.98,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 15.9,
    "out:Total Energy Use Pre PV": 180.9,
    "out:Total Energy Use Post PV": 180.9,
    "out:Primary Energy": 133.13,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 0.4,
    "out:CO2 Operational/m2": 61.5,
    "out:Total CO2/m2": 61.9,
    "out:Total CO2 (tons)": 318.34,
    "out:Klimatpaverkan": -10.9,
    "out:Initial Cost/MSEK": 0.250448,
    "out:Running cost/(Apt SEK y)": 56412,
    "out:Running Cost over RSP/MSEK": 18.529,
    "out:LCP/MSEK": 0.49,
    "out:ROI% old": 42.24,
    "out:Payback discounted": 3,
    "out:Atemp": 5143,
    "out:Umean": 0.82,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 181,
    "out:Energy use kWh/m2": 181,
    "out:Energy savings %": 3.9,
    "out:Op energy cost/MSEK": 136,
    "out:El price/MSEK": 81,
    "out:Heating price/MSEK": 47,
    "out:DH+ DHW/kWh/m2": 165,
    "out:El bought/kWh/m2": 1113,
    "out:Return %": 38,
    "out:Return/kSEK/y": 95,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 13,
    "out:Payback Time": 7.692308,
    "out:DH kWh savings": 36001,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 250448,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 32761,
    "out:DH kr savings": 32761,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 658585,
    "out:% savings (space heating)": 0,
    "out:Total_Water Heating (DH)": 190162,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 128.05,
    "out:Etvv": 21.25,
    "out:Ef": 15.9,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z00000000000001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 122.51,
    "out:DHW (DH)": 36.98,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 15.9,
    "out:Total Energy Use Pre PV": 174.9,
    "out:Total Energy Use Post PV": 174.9,
    "out:Primary Energy": 129.25,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 0.4,
    "out:CO2 Operational/m2": 59.34,
    "out:Total CO2/m2": 59.74,
    "out:Total CO2 (tons)": 307.23,
    "out:Klimatpaverkan": -22.01,
    "out:Initial Cost/MSEK": 0.379023,
    "out:Running cost/(Apt SEK y)": 54481,
    "out:Running Cost over RSP/MSEK": 17.894,
    "out:LCP/MSEK": 0.996,
    "out:ROI% old": 51.83,
    "out:Payback discounted": 3,
    "out:Atemp": 5143,
    "out:Umean": 0.82,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 175,
    "out:Energy use kWh/m2": 175,
    "out:Energy savings %": 7.4,
    "out:Op energy cost/MSEK": 131,
    "out:El price/MSEK": 78,
    "out:Heating price/MSEK": 46,
    "out:DH+ DHW/kWh/m2": 159,
    "out:El bought/kWh/m2": 1113,
    "out:Return %": 46,
    "out:Return/kSEK/y": 176,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 16,
    "out:Payback Time": 6.25,
    "out:DH kWh savings": 66859,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 250448,
    "out:Balancing hydronic system cost": 128574.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 60842,
    "out:DH kr savings": 60842,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 630052,
    "out:% savings (space heating)": 4.33,
    "out:Total_Water Heating (DH)": 190162,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 122.51,
    "out:Etvv": 21.25,
    "out:Ef": 15.9,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z00000000010000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 128.05,
    "out:DHW (DH)": 43.5,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 10.73,
    "out:Total Energy Use Pre PV": 187.9,
    "out:Total Energy Use Post PV": 182.73,
    "out:Primary Energy": 126.45,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 12.35,
    "out:CO2 Operational/m2": 33.02,
    "out:Total CO2/m2": 45.37,
    "out:Total CO2 (tons)": 233.36,
    "out:Klimatpaverkan": -95.88,
    "out:Initial Cost/MSEK": 1.0698,
    "out:Running cost/(Apt SEK y)": 56650,
    "out:Running Cost over RSP/MSEK": 18.625,
    "out:LCP/MSEK": -0.426,
    "out:ROI% old": 8.6,
    "out:Payback discounted": 14,
    "out:Atemp": 5143,
    "out:Umean": 0.82,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 183,
    "out:Energy use kWh/m2": 183,
    "out:Energy savings %": 2.7,
    "out:Op energy cost/MSEK": 137,
    "out:El price/MSEK": 80,
    "out:Heating price/MSEK": 49,
    "out:DH+ DHW/kWh/m2": 172,
    "out:El bought/kWh/m2": 758.1,
    "out:Return %": 8,
    "out:Return/kSEK/y": 85,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 61.265983,
    "out:PV (m2 panels)": 321,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 57484.06,
    "out:PV (kWh sold)": 30886.05,
    "out:PV (% sold (El))": 53.73,
    "out:PV (kWh self-consumed)": 26598,
    "out:PV (ratio sold/self-consumed)": 1.16,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 8,
    "out:Payback Time": 12.5,
    "out:DH kWh savings": 0,
    "out:EL kWh savings": 26598,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 84596,
    "out:DH kr savings": 0,
    "out:El kr savings": 45217,
    "out:El kr sold": 39380,
    "out:El kr saved": 45217,
    "out:El kr return": 84596,
    "out:% solar/total": 33,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 658585,
    "out:% savings (space heating)": 0,
    "out:Total_Water Heating (DH)": 223720,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 128.05,
    "out:Etvv": 25,
    "out:Ef": 10.73,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z00000000010000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 122.51,
    "out:DHW (DH)": 43.5,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 10.73,
    "out:Total Energy Use Pre PV": 181.9,
    "out:Total Energy Use Post PV": 176.73,
    "out:Primary Energy": 122.57,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 12.35,
    "out:CO2 Operational/m2": 30.86,
    "out:Total CO2/m2": 43.21,
    "out:Total CO2 (tons)": 222.25,
    "out:Klimatpaverkan": -106.99,
    "out:Initial Cost/MSEK": 1.198375,
    "out:Running cost/(Apt SEK y)": 54719,
    "out:Running Cost over RSP/MSEK": 17.99,
    "out:LCP/MSEK": 0.08,
    "out:ROI% old": 15.24,
    "out:Payback discounted": 8,
    "out:Atemp": 5143,
    "out:Umean": 0.82,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 177,
    "out:Energy use kWh/m2": 177,
    "out:Energy savings %": 6.2,
    "out:Op energy cost/MSEK": 133,
    "out:El price/MSEK": 77,
    "out:Heating price/MSEK": 48,
    "out:DH+ DHW/kWh/m2": 166,
    "out:El bought/kWh/m2": 758.1,
    "out:Return %": 14,
    "out:Return/kSEK/y": 166,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 61.265983,
    "out:PV (m2 panels)": 321,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 57484.06,
    "out:PV (kWh sold)": 30886.05,
    "out:PV (% sold (El))": 53.73,
    "out:PV (kWh self-consumed)": 26598,
    "out:PV (ratio sold/self-consumed)": 1.16,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 9,
    "out:Payback Time": 11.111111,
    "out:DH kWh savings": 30858,
    "out:EL kWh savings": 26598,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 128574.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 112677,
    "out:DH kr savings": 28081,
    "out:El kr savings": 45217,
    "out:El kr sold": 39380,
    "out:El kr saved": 45217,
    "out:El kr return": 84596,
    "out:% solar/total": 33,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 630052,
    "out:% savings (space heating)": 4.33,
    "out:Total_Water Heating (DH)": 223720,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 122.51,
    "out:Etvv": 25,
    "out:Ef": 10.73,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z00000000010001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 128.05,
    "out:DHW (DH)": 36.98,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 10.73,
    "out:Total Energy Use Pre PV": 180.9,
    "out:Total Energy Use Post PV": 175.73,
    "out:Primary Energy": 123.82,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 12.76,
    "out:CO2 Operational/m2": 30.5,
    "out:Total CO2/m2": 43.26,
    "out:Total CO2 (tons)": 222.47,
    "out:Klimatpaverkan": -106.77,
    "out:Initial Cost/MSEK": 1.32025,
    "out:Running cost/(Apt SEK y)": 54398,
    "out:Running Cost over RSP/MSEK": 17.885,
    "out:LCP/MSEK": 0.064,
    "out:ROI% old": 14.98,
    "out:Payback discounted": 8,
    "out:Atemp": 5143,
    "out:Umean": 0.82,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 176,
    "out:Energy use kWh/m2": 176,
    "out:Energy savings %": 6.8,
    "out:Op energy cost/MSEK": 132,
    "out:El price/MSEK": 77,
    "out:Heating price/MSEK": 47,
    "out:DH+ DHW/kWh/m2": 165,
    "out:El bought/kWh/m2": 758.1,
    "out:Return %": 14,
    "out:Return/kSEK/y": 179,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 61.265983,
    "out:PV (m2 panels)": 321,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 57484.06,
    "out:PV (kWh sold)": 30886.05,
    "out:PV (% sold (El))": 53.73,
    "out:PV (kWh self-consumed)": 26598,
    "out:PV (ratio sold/self-consumed)": 1.16,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 9,
    "out:Payback Time": 11.111111,
    "out:DH kWh savings": 36001,
    "out:EL kWh savings": 26598,
    "out:Sewage heat recovery cost": 250448,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 117357,
    "out:DH kr savings": 32761,
    "out:El kr savings": 45217,
    "out:El kr sold": 39380,
    "out:El kr saved": 45217,
    "out:El kr return": 84596,
    "out:% solar/total": 33,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 658585,
    "out:% savings (space heating)": 0,
    "out:Total_Water Heating (DH)": 190162,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 128.05,
    "out:Etvv": 21.25,
    "out:Ef": 10.73,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z00000000010001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 122.51,
    "out:DHW (DH)": 36.98,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 10.73,
    "out:Total Energy Use Pre PV": 174.9,
    "out:Total Energy Use Post PV": 169.73,
    "out:Primary Energy": 119.94,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 12.76,
    "out:CO2 Operational/m2": 28.34,
    "out:Total CO2/m2": 41.1,
    "out:Total CO2 (tons)": 211.36,
    "out:Klimatpaverkan": -117.88,
    "out:Initial Cost/MSEK": 1.448875,
    "out:Running cost/(Apt SEK y)": 52467,
    "out:Running Cost over RSP/MSEK": 17.25,
    "out:LCP/MSEK": 0.57,
    "out:ROI% old": 19.91,
    "out:Payback discounted": 6,
    "out:Atemp": 5143,
    "out:Umean": 0.82,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 170,
    "out:Energy use kWh/m2": 170,
    "out:Energy savings %": 10.6,
    "out:Op energy cost/MSEK": 127,
    "out:El price/MSEK": 74,
    "out:Heating price/MSEK": 46,
    "out:DH+ DHW/kWh/m2": 159,
    "out:El bought/kWh/m2": 758.1,
    "out:Return %": 18,
    "out:Return/kSEK/y": 260,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 61.265983,
    "out:PV (m2 panels)": 321,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 57484.06,
    "out:PV (kWh sold)": 30886.05,
    "out:PV (% sold (El))": 53.73,
    "out:PV (kWh self-consumed)": 26598,
    "out:PV (ratio sold/self-consumed)": 1.16,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 10,
    "out:Payback Time": 10,
    "out:DH kWh savings": 66859,
    "out:EL kWh savings": 26598,
    "out:Sewage heat recovery cost": 250448,
    "out:Balancing hydronic system cost": 128574.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 145438,
    "out:DH kr savings": 60842,
    "out:El kr savings": 45217,
    "out:El kr sold": 39380,
    "out:El kr saved": 45217,
    "out:El kr return": 84596,
    "out:% solar/total": 33,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 630052,
    "out:% savings (space heating)": 4.33,
    "out:Total_Water Heating (DH)": 190162,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 122.51,
    "out:Etvv": 21.25,
    "out:Ef": 10.73,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z00000000020000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 128.05,
    "out:DHW (DH)": 43.5,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 9.88,
    "out:Total Energy Use Pre PV": 187.9,
    "out:Total Energy Use Post PV": 181.88,
    "out:Primary Energy": 124.92,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 24.71,
    "out:CO2 Operational/m2": -19.66,
    "out:Total CO2/m2": 5.05,
    "out:Total CO2 (tons)": 25.96,
    "out:Klimatpaverkan": -303.28,
    "out:Initial Cost/MSEK": 2.139625,
    "out:Running cost/(Apt SEK y)": 54861,
    "out:Running Cost over RSP/MSEK": 18.054,
    "out:LCP/MSEK": -0.924,
    "out:ROI% old": 8.11,
    "out:Payback discounted": 15,
    "out:Atemp": 5143,
    "out:Umean": 0.82,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 182,
    "out:Energy use kWh/m2": 182,
    "out:Energy savings %": 3.3,
    "out:Op energy cost/MSEK": 134,
    "out:El price/MSEK": 77,
    "out:Heating price/MSEK": 49,
    "out:DH+ DHW/kWh/m2": 172,
    "out:El bought/kWh/m2": 696.7,
    "out:Return %": 7,
    "out:Return/kSEK/y": 160,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.33,
    "out:PV (kWp installed)": 122.531965,
    "out:PV (m2 panels)": 643,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 114968.11,
    "out:PV (kWh sold)": 84004.99,
    "out:PV (% sold (El))": 73.07,
    "out:PV (kWh self-consumed)": 30963.12,
    "out:PV (ratio sold/self-consumed)": 2.71,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 7,
    "out:Payback Time": 14.285714,
    "out:DH kWh savings": 0,
    "out:EL kWh savings": 30963,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 159744,
    "out:DH kr savings": 0,
    "out:El kr savings": 52637,
    "out:El kr sold": 107106,
    "out:El kr saved": 52637,
    "out:El kr return": 159744,
    "out:% solar/total": 38,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 658585,
    "out:% savings (space heating)": 0,
    "out:Total_Water Heating (DH)": 223720,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 128.05,
    "out:Etvv": 25,
    "out:Ef": 9.88,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z00000000020000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 122.51,
    "out:DHW (DH)": 43.5,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 9.88,
    "out:Total Energy Use Pre PV": 181.9,
    "out:Total Energy Use Post PV": 175.88,
    "out:Primary Energy": 121.04,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 24.71,
    "out:CO2 Operational/m2": -21.82,
    "out:Total CO2/m2": 2.89,
    "out:Total CO2 (tons)": 14.85,
    "out:Klimatpaverkan": -314.39,
    "out:Initial Cost/MSEK": 2.268125,
    "out:Running cost/(Apt SEK y)": 52930,
    "out:Running Cost over RSP/MSEK": 17.419,
    "out:LCP/MSEK": -0.418,
    "out:ROI% old": 11.65,
    "out:Payback discounted": 10,
    "out:Atemp": 5143,
    "out:Umean": 0.82,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 176,
    "out:Energy use kWh/m2": 176,
    "out:Energy savings %": 6.8,
    "out:Op energy cost/MSEK": 129,
    "out:El price/MSEK": 74,
    "out:Heating price/MSEK": 48,
    "out:DH+ DHW/kWh/m2": 166,
    "out:El bought/kWh/m2": 696.7,
    "out:Return %": 11,
    "out:Return/kSEK/y": 241,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.33,
    "out:PV (kWp installed)": 122.531965,
    "out:PV (m2 panels)": 643,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 114968.11,
    "out:PV (kWh sold)": 84004.99,
    "out:PV (% sold (El))": 73.07,
    "out:PV (kWh self-consumed)": 30963.12,
    "out:PV (ratio sold/self-consumed)": 2.71,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 8,
    "out:Payback Time": 12.5,
    "out:DH kWh savings": 30858,
    "out:EL kWh savings": 30963,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 128574.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 187824,
    "out:DH kr savings": 28081,
    "out:El kr savings": 52637,
    "out:El kr sold": 107106,
    "out:El kr saved": 52637,
    "out:El kr return": 159744,
    "out:% solar/total": 38,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 630052,
    "out:% savings (space heating)": 4.33,
    "out:Total_Water Heating (DH)": 223720,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 122.51,
    "out:Etvv": 25,
    "out:Ef": 9.88,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z00000000020001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 128.05,
    "out:DHW (DH)": 36.98,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 9.88,
    "out:Total Energy Use Pre PV": 180.9,
    "out:Total Energy Use Post PV": 174.88,
    "out:Primary Energy": 122.3,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 25.11,
    "out:CO2 Operational/m2": -22.18,
    "out:Total CO2/m2": 2.93,
    "out:Total CO2 (tons)": 15.07,
    "out:Klimatpaverkan": -314.17,
    "out:Initial Cost/MSEK": 2.39,
    "out:Running cost/(Apt SEK y)": 52608,
    "out:Running Cost over RSP/MSEK": 17.313,
    "out:LCP/MSEK": -0.434,
    "out:ROI% old": 11.69,
    "out:Payback discounted": 10,
    "out:Atemp": 5143,
    "out:Umean": 0.82,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 175,
    "out:Energy use kWh/m2": 175,
    "out:Energy savings %": 7.4,
    "out:Op energy cost/MSEK": 129,
    "out:El price/MSEK": 73,
    "out:Heating price/MSEK": 47,
    "out:DH+ DHW/kWh/m2": 165,
    "out:El bought/kWh/m2": 696.7,
    "out:Return %": 11,
    "out:Return/kSEK/y": 254,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.33,
    "out:PV (kWp installed)": 122.531965,
    "out:PV (m2 panels)": 643,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 114968.11,
    "out:PV (kWh sold)": 84004.99,
    "out:PV (% sold (El))": 73.07,
    "out:PV (kWh self-consumed)": 30963.12,
    "out:PV (ratio sold/self-consumed)": 2.71,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 8,
    "out:Payback Time": 12.5,
    "out:DH kWh savings": 36001,
    "out:EL kWh savings": 30963,
    "out:Sewage heat recovery cost": 250448,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 192505,
    "out:DH kr savings": 32761,
    "out:El kr savings": 52637,
    "out:El kr sold": 107106,
    "out:El kr saved": 52637,
    "out:El kr return": 159744,
    "out:% solar/total": 38,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 658585,
    "out:% savings (space heating)": 0,
    "out:Total_Water Heating (DH)": 190162,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 128.05,
    "out:Etvv": 21.25,
    "out:Ef": 9.88,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z00000000020001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 122.51,
    "out:DHW (DH)": 36.98,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 9.88,
    "out:Total Energy Use Pre PV": 174.9,
    "out:Total Energy Use Post PV": 168.88,
    "out:Primary Energy": 118.41,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 25.11,
    "out:CO2 Operational/m2": -24.34,
    "out:Total CO2/m2": 0.77,
    "out:Total CO2 (tons)": 3.96,
    "out:Klimatpaverkan": -325.28,
    "out:Initial Cost/MSEK": 2.518625,
    "out:Running cost/(Apt SEK y)": 50677,
    "out:Running Cost over RSP/MSEK": 16.679,
    "out:LCP/MSEK": 0.072,
    "out:ROI% old": 14.69,
    "out:Payback discounted": 8,
    "out:Atemp": 5143,
    "out:Umean": 0.82,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 169,
    "out:Energy use kWh/m2": 169,
    "out:Energy savings %": 11.2,
    "out:Op energy cost/MSEK": 124,
    "out:El price/MSEK": 71,
    "out:Heating price/MSEK": 46,
    "out:DH+ DHW/kWh/m2": 159,
    "out:El bought/kWh/m2": 696.7,
    "out:Return %": 13,
    "out:Return/kSEK/y": 335,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.33,
    "out:PV (kWp installed)": 122.531965,
    "out:PV (m2 panels)": 643,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 114968.11,
    "out:PV (kWh sold)": 84004.99,
    "out:PV (% sold (El))": 73.07,
    "out:PV (kWh self-consumed)": 30963.12,
    "out:PV (ratio sold/self-consumed)": 2.71,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 9,
    "out:Payback Time": 11.111111,
    "out:DH kWh savings": 66859,
    "out:EL kWh savings": 30963,
    "out:Sewage heat recovery cost": 250448,
    "out:Balancing hydronic system cost": 128574.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 220585,
    "out:DH kr savings": 60842,
    "out:El kr savings": 52637,
    "out:El kr sold": 107106,
    "out:El kr saved": 52637,
    "out:El kr return": 159744,
    "out:% solar/total": 38,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 630052,
    "out:% savings (space heating)": 4.33,
    "out:Total_Water Heating (DH)": 190162,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 122.51,
    "out:Etvv": 21.25,
    "out:Ef": 9.88,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z00000000100000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 128.05,
    "out:DHW (DH)": 34.8,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 15.9,
    "out:Total Energy Use Pre PV": 178.9,
    "out:Total Energy Use Post PV": 178.9,
    "out:Primary Energy": 132.26,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 0,
    "out:CO2 Operational/m2": 60.78,
    "out:Total CO2/m2": 60.78,
    "out:Total CO2 (tons)": 312.57,
    "out:Klimatpaverkan": -16.67,
    "out:Initial Cost/MSEK": 0.110851,
    "out:Running cost/(Apt SEK y)": 55768,
    "out:Running Cost over RSP/MSEK": 18.317,
    "out:LCP/MSEK": 0.841,
    "out:ROI% old": 122.69,
    "out:Payback discounted": 1,
    "out:Atemp": 5143,
    "out:Umean": 0.82,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 179,
    "out:Energy use kWh/m2": 179,
    "out:Energy savings %": 5,
    "out:Op energy cost/MSEK": 134,
    "out:El price/MSEK": 80,
    "out:Heating price/MSEK": 47,
    "out:DH+ DHW/kWh/m2": 163,
    "out:El bought/kWh/m2": 1113,
    "out:Return %": 110,
    "out:Return/kSEK/y": 122,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 38,
    "out:Payback Time": 2.631579,
    "out:DH kWh savings": 46287,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 42121,
    "out:DH kr savings": 42121,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 658585,
    "out:% savings (space heating)": 0,
    "out:Total_Water Heating (DH)": 178976,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 128.05,
    "out:Etvv": 20,
    "out:Ef": 15.9,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z00000000100000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 122.51,
    "out:DHW (DH)": 34.8,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 15.9,
    "out:Total Energy Use Pre PV": 172.9,
    "out:Total Energy Use Post PV": 172.9,
    "out:Primary Energy": 128.37,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 0,
    "out:CO2 Operational/m2": 58.62,
    "out:Total CO2/m2": 58.62,
    "out:Total CO2 (tons)": 301.46,
    "out:Klimatpaverkan": -27.78,
    "out:Initial Cost/MSEK": 0.239426,
    "out:Running cost/(Apt SEK y)": 53837,
    "out:Running Cost over RSP/MSEK": 17.682,
    "out:LCP/MSEK": 1.347,
    "out:ROI% old": 94.67,
    "out:Payback discounted": 2,
    "out:Atemp": 5143,
    "out:Umean": 0.82,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 173,
    "out:Energy use kWh/m2": 173,
    "out:Energy savings %": 8.7,
    "out:Op energy cost/MSEK": 130,
    "out:El price/MSEK": 77,
    "out:Heating price/MSEK": 45,
    "out:DH+ DHW/kWh/m2": 157,
    "out:El bought/kWh/m2": 1113,
    "out:Return %": 85,
    "out:Return/kSEK/y": 203,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 29,
    "out:Payback Time": 3.448276,
    "out:DH kWh savings": 77145,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 128574.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 70202,
    "out:DH kr savings": 70202,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 630052,
    "out:% savings (space heating)": 4.33,
    "out:Total_Water Heating (DH)": 178976,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 122.51,
    "out:Etvv": 20,
    "out:Ef": 15.9,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z00000000100001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 128.05,
    "out:DHW (DH)": 29.58,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 15.9,
    "out:Total Energy Use Pre PV": 173.9,
    "out:Total Energy Use Post PV": 173.9,
    "out:Primary Energy": 130.16,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 0.4,
    "out:CO2 Operational/m2": 58.98,
    "out:Total CO2/m2": 59.38,
    "out:Total CO2 (tons)": 305.38,
    "out:Klimatpaverkan": -23.86,
    "out:Initial Cost/MSEK": 0.361299,
    "out:Running cost/(Apt SEK y)": 54159,
    "out:Running Cost over RSP/MSEK": 17.788,
    "out:LCP/MSEK": 1.12,
    "out:ROI% old": 58.56,
    "out:Payback discounted": 2,
    "out:Atemp": 5143,
    "out:Umean": 0.82,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 174,
    "out:Energy use kWh/m2": 174,
    "out:Energy savings %": 8,
    "out:Op energy cost/MSEK": 131,
    "out:El price/MSEK": 78,
    "out:Heating price/MSEK": 45,
    "out:DH+ DHW/kWh/m2": 158,
    "out:El bought/kWh/m2": 1113,
    "out:Return %": 52,
    "out:Return/kSEK/y": 189,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 18,
    "out:Payback Time": 5.555556,
    "out:DH kWh savings": 72002,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 250448,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 65522,
    "out:DH kr savings": 65522,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 658585,
    "out:% savings (space heating)": 0,
    "out:Total_Water Heating (DH)": 152130,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 128.05,
    "out:Etvv": 17,
    "out:Ef": 15.9,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z00000000100001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 122.51,
    "out:DHW (DH)": 29.58,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 15.9,
    "out:Total Energy Use Pre PV": 167.9,
    "out:Total Energy Use Post PV": 167.9,
    "out:Primary Energy": 126.27,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 0.4,
    "out:CO2 Operational/m2": 56.82,
    "out:Total CO2/m2": 57.22,
    "out:Total CO2 (tons)": 294.28,
    "out:Klimatpaverkan": -34.96,
    "out:Initial Cost/MSEK": 0.489874,
    "out:Running cost/(Apt SEK y)": 52228,
    "out:Running Cost over RSP/MSEK": 17.153,
    "out:LCP/MSEK": 1.626,
    "out:ROI% old": 61.7,
    "out:Payback discounted": 2,
    "out:Atemp": 5143,
    "out:Umean": 0.82,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 168,
    "out:Energy use kWh/m2": 168,
    "out:Energy savings %": 11.9,
    "out:Op energy cost/MSEK": 126,
    "out:El price/MSEK": 75,
    "out:Heating price/MSEK": 44,
    "out:DH+ DHW/kWh/m2": 152,
    "out:El bought/kWh/m2": 1113,
    "out:Return %": 55,
    "out:Return/kSEK/y": 270,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 19,
    "out:Payback Time": 5.263158,
    "out:DH kWh savings": 102860,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 250448,
    "out:Balancing hydronic system cost": 128574.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 93603,
    "out:DH kr savings": 93603,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 630052,
    "out:% savings (space heating)": 4.33,
    "out:Total_Water Heating (DH)": 152130,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 122.51,
    "out:Etvv": 17,
    "out:Ef": 15.9,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z00000000110000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 128.05,
    "out:DHW (DH)": 34.8,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 10.73,
    "out:Total Energy Use Pre PV": 178.9,
    "out:Total Energy Use Post PV": 173.73,
    "out:Primary Energy": 122.95,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 12.35,
    "out:CO2 Operational/m2": 29.78,
    "out:Total CO2/m2": 42.13,
    "out:Total CO2 (tons)": 216.7,
    "out:Klimatpaverkan": -112.54,
    "out:Initial Cost/MSEK": 1.180651,
    "out:Running cost/(Apt SEK y)": 53754,
    "out:Running Cost over RSP/MSEK": 17.673,
    "out:LCP/MSEK": 0.415,
    "out:ROI% old": 19.31,
    "out:Payback discounted": 6,
    "out:Atemp": 5143,
    "out:Umean": 0.82,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 174,
    "out:Energy use kWh/m2": 174,
    "out:Energy savings %": 8,
    "out:Op energy cost/MSEK": 130,
    "out:El price/MSEK": 76,
    "out:Heating price/MSEK": 47,
    "out:DH+ DHW/kWh/m2": 163,
    "out:El bought/kWh/m2": 758.1,
    "out:Return %": 17,
    "out:Return/kSEK/y": 206,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 61.265983,
    "out:PV (m2 panels)": 321,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 57484.06,
    "out:PV (kWh sold)": 30886.05,
    "out:PV (% sold (El))": 53.73,
    "out:PV (kWh self-consumed)": 26598,
    "out:PV (ratio sold/self-consumed)": 1.16,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 11,
    "out:Payback Time": 9.090909,
    "out:DH kWh savings": 46287,
    "out:EL kWh savings": 26598,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 126717,
    "out:DH kr savings": 42121,
    "out:El kr savings": 45217,
    "out:El kr sold": 39380,
    "out:El kr saved": 45217,
    "out:El kr return": 84596,
    "out:% solar/total": 33,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 658585,
    "out:% savings (space heating)": 0,
    "out:Total_Water Heating (DH)": 178976,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 128.05,
    "out:Etvv": 20,
    "out:Ef": 10.73,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z00000000110000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 122.51,
    "out:DHW (DH)": 34.8,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 10.73,
    "out:Total Energy Use Pre PV": 172.9,
    "out:Total Energy Use Post PV": 167.73,
    "out:Primary Energy": 119.07,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 12.35,
    "out:CO2 Operational/m2": 27.62,
    "out:Total CO2/m2": 39.97,
    "out:Total CO2 (tons)": 205.59,
    "out:Klimatpaverkan": -123.65,
    "out:Initial Cost/MSEK": 1.30925,
    "out:Running cost/(Apt SEK y)": 51823,
    "out:Running Cost over RSP/MSEK": 17.038,
    "out:LCP/MSEK": 0.921,
    "out:ROI% old": 24.34,
    "out:Payback discounted": 5,
    "out:Atemp": 5143,
    "out:Umean": 0.82,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 168,
    "out:Energy use kWh/m2": 168,
    "out:Energy savings %": 11.9,
    "out:Op energy cost/MSEK": 126,
    "out:El price/MSEK": 73,
    "out:Heating price/MSEK": 45,
    "out:DH+ DHW/kWh/m2": 157,
    "out:El bought/kWh/m2": 758.1,
    "out:Return %": 22,
    "out:Return/kSEK/y": 287,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 61.265983,
    "out:PV (m2 panels)": 321,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 57484.06,
    "out:PV (kWh sold)": 30886.05,
    "out:PV (% sold (El))": 53.73,
    "out:PV (kWh self-consumed)": 26598,
    "out:PV (ratio sold/self-consumed)": 1.16,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 12,
    "out:Payback Time": 8.333333,
    "out:DH kWh savings": 77145,
    "out:EL kWh savings": 26598,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 128574.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 154798,
    "out:DH kr savings": 70202,
    "out:El kr savings": 45217,
    "out:El kr sold": 39380,
    "out:El kr saved": 45217,
    "out:El kr return": 84596,
    "out:% solar/total": 33,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 630052,
    "out:% savings (space heating)": 4.33,
    "out:Total_Water Heating (DH)": 178976,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 122.51,
    "out:Etvv": 20,
    "out:Ef": 10.73,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z00000000110001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 128.05,
    "out:DHW (DH)": 29.58,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 10.73,
    "out:Total Energy Use Pre PV": 173.9,
    "out:Total Energy Use Post PV": 168.73,
    "out:Primary Energy": 120.85,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 12.76,
    "out:CO2 Operational/m2": 27.98,
    "out:Total CO2/m2": 40.74,
    "out:Total CO2 (tons)": 209.51,
    "out:Klimatpaverkan": -119.73,
    "out:Initial Cost/MSEK": 1.431125,
    "out:Running cost/(Apt SEK y)": 52145,
    "out:Running Cost over RSP/MSEK": 17.144,
    "out:LCP/MSEK": 0.694,
    "out:ROI% old": 21.21,
    "out:Payback discounted": 6,
    "out:Atemp": 5143,
    "out:Umean": 0.82,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 169,
    "out:Energy use kWh/m2": 169,
    "out:Energy savings %": 11.2,
    "out:Op energy cost/MSEK": 127,
    "out:El price/MSEK": 74,
    "out:Heating price/MSEK": 45,
    "out:DH+ DHW/kWh/m2": 158,
    "out:El bought/kWh/m2": 758.1,
    "out:Return %": 19,
    "out:Return/kSEK/y": 274,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 61.265983,
    "out:PV (m2 panels)": 321,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 57484.06,
    "out:PV (kWh sold)": 30886.05,
    "out:PV (% sold (El))": 53.73,
    "out:PV (kWh self-consumed)": 26598,
    "out:PV (ratio sold/self-consumed)": 1.16,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 10,
    "out:Payback Time": 10,
    "out:DH kWh savings": 72002,
    "out:EL kWh savings": 26598,
    "out:Sewage heat recovery cost": 250448,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 150118,
    "out:DH kr savings": 65522,
    "out:El kr savings": 45217,
    "out:El kr sold": 39380,
    "out:El kr saved": 45217,
    "out:El kr return": 84596,
    "out:% solar/total": 33,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 658585,
    "out:% savings (space heating)": 0,
    "out:Total_Water Heating (DH)": 152130,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 128.05,
    "out:Etvv": 17,
    "out:Ef": 10.73,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z00000000110001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 122.51,
    "out:DHW (DH)": 29.58,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 10.73,
    "out:Total Energy Use Pre PV": 167.9,
    "out:Total Energy Use Post PV": 162.73,
    "out:Primary Energy": 116.97,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 12.76,
    "out:CO2 Operational/m2": 25.82,
    "out:Total CO2/m2": 38.58,
    "out:Total CO2 (tons)": 198.4,
    "out:Klimatpaverkan": -130.84,
    "out:Initial Cost/MSEK": 1.559625,
    "out:Running cost/(Apt SEK y)": 50214,
    "out:Running Cost over RSP/MSEK": 16.51,
    "out:LCP/MSEK": 1.2,
    "out:ROI% old": 25.28,
    "out:Payback discounted": 5,
    "out:Atemp": 5143,
    "out:Umean": 0.82,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 163,
    "out:Energy use kWh/m2": 163,
    "out:Energy savings %": 15.3,
    "out:Op energy cost/MSEK": 122,
    "out:El price/MSEK": 71,
    "out:Heating price/MSEK": 44,
    "out:DH+ DHW/kWh/m2": 152,
    "out:El bought/kWh/m2": 758.1,
    "out:Return %": 23,
    "out:Return/kSEK/y": 355,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 61.265983,
    "out:PV (m2 panels)": 321,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 57484.06,
    "out:PV (kWh sold)": 30886.05,
    "out:PV (% sold (El))": 53.73,
    "out:PV (kWh self-consumed)": 26598,
    "out:PV (ratio sold/self-consumed)": 1.16,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 11,
    "out:Payback Time": 9.090909,
    "out:DH kWh savings": 102860,
    "out:EL kWh savings": 26598,
    "out:Sewage heat recovery cost": 250448,
    "out:Balancing hydronic system cost": 128574.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 178199,
    "out:DH kr savings": 93603,
    "out:El kr savings": 45217,
    "out:El kr sold": 39380,
    "out:El kr saved": 45217,
    "out:El kr return": 84596,
    "out:% solar/total": 33,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 630052,
    "out:% savings (space heating)": 4.33,
    "out:Total_Water Heating (DH)": 152130,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 122.51,
    "out:Etvv": 17,
    "out:Ef": 10.73,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z00000000120000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 128.05,
    "out:DHW (DH)": 34.8,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 9.88,
    "out:Total Energy Use Pre PV": 178.9,
    "out:Total Energy Use Post PV": 172.88,
    "out:Primary Energy": 121.42,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 24.71,
    "out:CO2 Operational/m2": -22.9,
    "out:Total CO2/m2": 1.81,
    "out:Total CO2 (tons)": 9.3,
    "out:Klimatpaverkan": -319.94,
    "out:Initial Cost/MSEK": 2.250375,
    "out:Running cost/(Apt SEK y)": 51965,
    "out:Running Cost over RSP/MSEK": 17.102,
    "out:LCP/MSEK": -0.083,
    "out:ROI% old": 13.76,
    "out:Payback discounted": 9,
    "out:Atemp": 5143,
    "out:Umean": 0.82,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 173,
    "out:Energy use kWh/m2": 173,
    "out:Energy savings %": 8.7,
    "out:Op energy cost/MSEK": 127,
    "out:El price/MSEK": 73,
    "out:Heating price/MSEK": 47,
    "out:DH+ DHW/kWh/m2": 163,
    "out:El bought/kWh/m2": 696.7,
    "out:Return %": 13,
    "out:Return/kSEK/y": 281,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.33,
    "out:PV (kWp installed)": 122.531965,
    "out:PV (m2 panels)": 643,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 114968.11,
    "out:PV (kWh sold)": 84004.99,
    "out:PV (% sold (El))": 73.07,
    "out:PV (kWh self-consumed)": 30963.12,
    "out:PV (ratio sold/self-consumed)": 2.71,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 9,
    "out:Payback Time": 11.111111,
    "out:DH kWh savings": 46287,
    "out:EL kWh savings": 30963,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 201865,
    "out:DH kr savings": 42121,
    "out:El kr savings": 52637,
    "out:El kr sold": 107106,
    "out:El kr saved": 52637,
    "out:El kr return": 159744,
    "out:% solar/total": 38,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 658585,
    "out:% savings (space heating)": 0,
    "out:Total_Water Heating (DH)": 178976,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 128.05,
    "out:Etvv": 20,
    "out:Ef": 9.88,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z00000000120000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 122.51,
    "out:DHW (DH)": 34.8,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 9.88,
    "out:Total Energy Use Pre PV": 172.9,
    "out:Total Energy Use Post PV": 166.88,
    "out:Primary Energy": 117.54,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 24.71,
    "out:CO2 Operational/m2": -25.06,
    "out:Total CO2/m2": -0.35,
    "out:Total CO2 (tons)": -1.81,
    "out:Klimatpaverkan": -331.05,
    "out:Initial Cost/MSEK": 2.379,
    "out:Running cost/(Apt SEK y)": 50034,
    "out:Running Cost over RSP/MSEK": 16.467,
    "out:LCP/MSEK": 0.423,
    "out:ROI% old": 16.83,
    "out:Payback discounted": 7,
    "out:Atemp": 5143,
    "out:Umean": 0.82,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 167,
    "out:Energy use kWh/m2": 167,
    "out:Energy savings %": 12.6,
    "out:Op energy cost/MSEK": 122,
    "out:El price/MSEK": 70,
    "out:Heating price/MSEK": 45,
    "out:DH+ DHW/kWh/m2": 157,
    "out:El bought/kWh/m2": 696.7,
    "out:Return %": 15,
    "out:Return/kSEK/y": 362,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.33,
    "out:PV (kWp installed)": 122.531965,
    "out:PV (m2 panels)": 643,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 114968.11,
    "out:PV (kWh sold)": 84004.99,
    "out:PV (% sold (El))": 73.07,
    "out:PV (kWh self-consumed)": 30963.12,
    "out:PV (ratio sold/self-consumed)": 2.71,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 10,
    "out:Payback Time": 10,
    "out:DH kWh savings": 77145,
    "out:EL kWh savings": 30963,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 128574.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 229946,
    "out:DH kr savings": 70202,
    "out:El kr savings": 52637,
    "out:El kr sold": 107106,
    "out:El kr saved": 52637,
    "out:El kr return": 159744,
    "out:% solar/total": 38,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 630052,
    "out:% savings (space heating)": 4.33,
    "out:Total_Water Heating (DH)": 178976,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 122.51,
    "out:Etvv": 20,
    "out:Ef": 9.88,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z00000000120001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 128.05,
    "out:DHW (DH)": 29.58,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 9.88,
    "out:Total Energy Use Pre PV": 173.9,
    "out:Total Energy Use Post PV": 167.88,
    "out:Primary Energy": 119.32,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 25.11,
    "out:CO2 Operational/m2": -24.7,
    "out:Total CO2/m2": 0.41,
    "out:Total CO2 (tons)": 2.11,
    "out:Klimatpaverkan": -327.13,
    "out:Initial Cost/MSEK": 2.500875,
    "out:Running cost/(Apt SEK y)": 50356,
    "out:Running Cost over RSP/MSEK": 16.573,
    "out:LCP/MSEK": 0.195,
    "out:ROI% old": 15.4,
    "out:Payback discounted": 8,
    "out:Atemp": 5143,
    "out:Umean": 0.82,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 168,
    "out:Energy use kWh/m2": 168,
    "out:Energy savings %": 11.9,
    "out:Op energy cost/MSEK": 123,
    "out:El price/MSEK": 70,
    "out:Heating price/MSEK": 45,
    "out:DH+ DHW/kWh/m2": 158,
    "out:El bought/kWh/m2": 696.7,
    "out:Return %": 14,
    "out:Return/kSEK/y": 349,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.33,
    "out:PV (kWp installed)": 122.531965,
    "out:PV (m2 panels)": 643,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 114968.11,
    "out:PV (kWh sold)": 84004.99,
    "out:PV (% sold (El))": 73.07,
    "out:PV (kWh self-consumed)": 30963.12,
    "out:PV (ratio sold/self-consumed)": 2.71,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 9,
    "out:Payback Time": 11.111111,
    "out:DH kWh savings": 72002,
    "out:EL kWh savings": 30963,
    "out:Sewage heat recovery cost": 250448,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 225265,
    "out:DH kr savings": 65522,
    "out:El kr savings": 52637,
    "out:El kr sold": 107106,
    "out:El kr saved": 52637,
    "out:El kr return": 159744,
    "out:% solar/total": 38,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 658585,
    "out:% savings (space heating)": 0,
    "out:Total_Water Heating (DH)": 152130,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 128.05,
    "out:Etvv": 17,
    "out:Ef": 9.88,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z00000000120001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 122.51,
    "out:DHW (DH)": 29.58,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 9.88,
    "out:Total Energy Use Pre PV": 167.9,
    "out:Total Energy Use Post PV": 161.88,
    "out:Primary Energy": 115.44,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 25.11,
    "out:CO2 Operational/m2": -26.86,
    "out:Total CO2/m2": -1.75,
    "out:Total CO2 (tons)": -9,
    "out:Klimatpaverkan": -338.24,
    "out:Initial Cost/MSEK": 2.6295,
    "out:Running cost/(Apt SEK y)": 48425,
    "out:Running Cost over RSP/MSEK": 15.938,
    "out:LCP/MSEK": 0.701,
    "out:ROI% old": 18.1,
    "out:Payback discounted": 7,
    "out:Atemp": 5143,
    "out:Umean": 0.82,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 162,
    "out:Energy use kWh/m2": 162,
    "out:Energy savings %": 16,
    "out:Op energy cost/MSEK": 118,
    "out:El price/MSEK": 68,
    "out:Heating price/MSEK": 44,
    "out:DH+ DHW/kWh/m2": 152,
    "out:El bought/kWh/m2": 696.7,
    "out:Return %": 16,
    "out:Return/kSEK/y": 430,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.33,
    "out:PV (kWp installed)": 122.531965,
    "out:PV (m2 panels)": 643,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 114968.11,
    "out:PV (kWh sold)": 84004.99,
    "out:PV (% sold (El))": 73.07,
    "out:PV (kWh self-consumed)": 30963.12,
    "out:PV (ratio sold/self-consumed)": 2.71,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 10,
    "out:Payback Time": 10,
    "out:DH kWh savings": 102860,
    "out:EL kWh savings": 30963,
    "out:Sewage heat recovery cost": 250448,
    "out:Balancing hydronic system cost": 128574.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 253346,
    "out:DH kr savings": 93603,
    "out:El kr savings": 52637,
    "out:El kr sold": 107106,
    "out:El kr saved": 52637,
    "out:El kr return": 159744,
    "out:% solar/total": 38,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 630052,
    "out:% savings (space heating)": 4.33,
    "out:Total_Water Heating (DH)": 152130,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 122.51,
    "out:Etvv": 17,
    "out:Ef": 9.88,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z00000001000000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 81.14,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 45.6,
    "out:Electricity (inc PV)": 43.68,
    "out:Total Energy Use Pre PV": 126.6,
    "out:Total Energy Use Post PV": 124.68,
    "out:Primary Energy": 126.9,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 2.85,
    "out:CO2 Operational/m2": 35.18,
    "out:Total CO2/m2": 38.03,
    "out:Total CO2 (tons)": 195.58,
    "out:Klimatpaverkan": -133.66,
    "out:Initial Cost/MSEK": 4.475875,
    "out:Running cost/(Apt SEK y)": 35561,
    "out:Running Cost over RSP/MSEK": 11.65,
    "out:LCP/MSEK": 3.143,
    "out:ROI% old": 24.32,
    "out:Payback discounted": 5,
    "out:Atemp": 5143,
    "out:Umean": 0.82,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 125,
    "out:Energy use kWh/m2": 125,
    "out:Energy savings %": 50.4,
    "out:Op energy cost/MSEK": 84,
    "out:El price/MSEK": 57,
    "out:Heating price/MSEK": 23,
    "out:DH+ DHW/kWh/m2": 81,
    "out:El bought/kWh/m2": 3191.9,
    "out:Return %": 22,
    "out:Return/kSEK/y": 970,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 1479000,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 73950,
    "out:EAHP (replacement cost)": 1105400,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 27.4,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 468013,
    "out:EL kWh savings": -142862,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 183027,
    "out:DH kr savings": 425892,
    "out:El kr savings": -242865,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 417280,
    "out:% savings (space heating)": 36.64,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 68.98,
    "out:Etvv": 0,
    "out:Ef": 43.68,
    "out:heatPumpQuantity": 10,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000001000000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 75.81,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 45.53,
    "out:Electricity (inc PV)": 43.56,
    "out:Total Energy Use Pre PV": 121.53,
    "out:Total Energy Use Post PV": 119.56,
    "out:Primary Energy": 123.08,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 2.85,
    "out:CO2 Operational/m2": 33.37,
    "out:Total CO2/m2": 36.22,
    "out:Total CO2 (tons)": 186.28,
    "out:Klimatpaverkan": -142.96,
    "out:Initial Cost/MSEK": 4.6045,
    "out:Running cost/(Apt SEK y)": 33937,
    "out:Running Cost over RSP/MSEK": 11.117,
    "out:LCP/MSEK": 3.548,
    "out:ROI% old": 25.29,
    "out:Payback discounted": 5,
    "out:Atemp": 5143,
    "out:Umean": 0.82,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 120,
    "out:Energy use kWh/m2": 120,
    "out:Energy savings %": 56.7,
    "out:Op energy cost/MSEK": 80,
    "out:El price/MSEK": 54,
    "out:Heating price/MSEK": 22,
    "out:DH+ DHW/kWh/m2": 76,
    "out:El bought/kWh/m2": 3187.2,
    "out:Return %": 23,
    "out:Return/kSEK/y": 1039,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 1479000,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 73950,
    "out:EAHP (replacement cost)": 1105400,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 27.33,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 493728,
    "out:EL kWh savings": -142250,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 128574.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 207468,
    "out:DH kr savings": 449292,
    "out:El kr savings": -241825,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 389907,
    "out:% savings (space heating)": 40.8,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 63.82,
    "out:Etvv": 0,
    "out:Ef": 43.56,
    "out:heatPumpQuantity": 10,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000001000001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 76.72,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 44.96,
    "out:Electricity (inc PV)": 43.25,
    "out:Total Energy Use Pre PV": 121.96,
    "out:Total Energy Use Post PV": 120.25,
    "out:Primary Energy": 124.5,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 3.25,
    "out:CO2 Operational/m2": 33.65,
    "out:Total CO2/m2": 36.91,
    "out:Total CO2 (tons)": 189.81,
    "out:Klimatpaverkan": -139.43,
    "out:Initial Cost/MSEK": 4.726375,
    "out:Running cost/(Apt SEK y)": 34140,
    "out:Running Cost over RSP/MSEK": 11.184,
    "out:LCP/MSEK": 3.359,
    "out:ROI% old": 24.44,
    "out:Payback discounted": 5,
    "out:Atemp": 5143,
    "out:Umean": 0.82,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 120,
    "out:Energy use kWh/m2": 120,
    "out:Energy savings %": 56.7,
    "out:Op energy cost/MSEK": 80,
    "out:El price/MSEK": 55,
    "out:Heating price/MSEK": 22,
    "out:DH+ DHW/kWh/m2": 77,
    "out:El bought/kWh/m2": 3147.2,
    "out:Return %": 22,
    "out:Return/kSEK/y": 1030,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 1479000,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 73950,
    "out:EAHP (replacement cost)": 1105400,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 26.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 488585,
    "out:EL kWh savings": -140663,
    "out:Sewage heat recovery cost": 250448,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 205486,
    "out:DH kr savings": 444612,
    "out:El kr savings": -239127,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 394576,
    "out:% savings (space heating)": 40.09,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 66.64,
    "out:Etvv": 0,
    "out:Ef": 43.25,
    "out:heatPumpQuantity": 10,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000001000001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 71.45,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 44.88,
    "out:Electricity (inc PV)": 43.12,
    "out:Total Energy Use Pre PV": 115.88,
    "out:Total Energy Use Post PV": 114.12,
    "out:Primary Energy": 120.68,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 3.25,
    "out:CO2 Operational/m2": 31.48,
    "out:Total CO2/m2": 34.74,
    "out:Total CO2 (tons)": 178.65,
    "out:Klimatpaverkan": -150.59,
    "out:Initial Cost/MSEK": 4.854875,
    "out:Running cost/(Apt SEK y)": 32191,
    "out:Running Cost over RSP/MSEK": 10.544,
    "out:LCP/MSEK": 3.871,
    "out:ROI% old": 25.68,
    "out:Payback discounted": 5,
    "out:Atemp": 5143,
    "out:Umean": 0.82,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 114,
    "out:Energy use kWh/m2": 114,
    "out:Energy savings %": 64.9,
    "out:Op energy cost/MSEK": 76,
    "out:El price/MSEK": 52,
    "out:Heating price/MSEK": 20,
    "out:DH+ DHW/kWh/m2": 71,
    "out:El bought/kWh/m2": 3141.3,
    "out:Return %": 23,
    "out:Return/kSEK/y": 1112,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 1479000,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 73950,
    "out:EAHP (replacement cost)": 1105400,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 26.68,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 519443,
    "out:EL kWh savings": -139998,
    "out:Sewage heat recovery cost": 250448,
    "out:Balancing hydronic system cost": 128574.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 234696,
    "out:DH kr savings": 472693,
    "out:El kr savings": -237997,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 367481,
    "out:% savings (space heating)": 44.2,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 61.52,
    "out:Etvv": 0,
    "out:Ef": 43.12,
    "out:heatPumpQuantity": 10,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000001010000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 81.14,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 45.6,
    "out:Electricity (inc PV)": 34.11,
    "out:Total Energy Use Pre PV": 126.6,
    "out:Total Energy Use Post PV": 115.11,
    "out:Primary Energy": 109.68,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 15.2,
    "out:CO2 Operational/m2": 26.98,
    "out:Total CO2/m2": 42.18,
    "out:Total CO2 (tons)": 216.94,
    "out:Klimatpaverkan": -112.3,
    "out:Initial Cost/MSEK": 5.54575,
    "out:Running cost/(Apt SEK y)": 33308,
    "out:Running Cost over RSP/MSEK": 10.93,
    "out:LCP/MSEK": 2.793,
    "out:ROI% old": 21.48,
    "out:Payback discounted": 6,
    "out:Atemp": 5143,
    "out:Umean": 0.82,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 115,
    "out:Energy use kWh/m2": 115,
    "out:Energy savings %": 63.5,
    "out:Op energy cost/MSEK": 79,
    "out:El price/MSEK": 52,
    "out:Heating price/MSEK": 23,
    "out:DH+ DHW/kWh/m2": 81,
    "out:El bought/kWh/m2": 2536.4,
    "out:Return %": 19,
    "out:Return/kSEK/y": 1065,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 1479000,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 73950,
    "out:EAHP (replacement cost)": 1105400,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 27.4,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 61.265983,
    "out:PV (m2 panels)": 321,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 57484.06,
    "out:PV (kWh sold)": 7329.36,
    "out:PV (% sold (El))": 12.75,
    "out:PV (kWh self-consumed)": 50154.69,
    "out:PV (ratio sold/self-consumed)": 0.15,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 468013,
    "out:EL kWh savings": -93648,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 276035,
    "out:DH kr savings": 425892,
    "out:El kr savings": -159202,
    "out:El kr sold": 9345,
    "out:El kr saved": 85263,
    "out:El kr return": 94608,
    "out:% solar/total": 61,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 417280,
    "out:% savings (space heating)": 36.64,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 68.98,
    "out:Etvv": 0,
    "out:Ef": 34.11,
    "out:heatPumpQuantity": 10,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000001010000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 75.81,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 45.53,
    "out:Electricity (inc PV)": 34,
    "out:Total Energy Use Pre PV": 121.53,
    "out:Total Energy Use Post PV": 110,
    "out:Primary Energy": 105.88,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 15.2,
    "out:CO2 Operational/m2": 25.14,
    "out:Total CO2/m2": 40.34,
    "out:Total CO2 (tons)": 207.48,
    "out:Klimatpaverkan": -121.76,
    "out:Initial Cost/MSEK": 5.67425,
    "out:Running cost/(Apt SEK y)": 31685,
    "out:Running Cost over RSP/MSEK": 10.397,
    "out:LCP/MSEK": 3.198,
    "out:ROI% old": 22.34,
    "out:Payback discounted": 6,
    "out:Atemp": 5143,
    "out:Umean": 0.82,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 110,
    "out:Energy use kWh/m2": 110,
    "out:Energy savings %": 70.9,
    "out:Op energy cost/MSEK": 75,
    "out:El price/MSEK": 50,
    "out:Heating price/MSEK": 22,
    "out:DH+ DHW/kWh/m2": 76,
    "out:El bought/kWh/m2": 2532,
    "out:Return %": 20,
    "out:Return/kSEK/y": 1133,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 1479000,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 73950,
    "out:EAHP (replacement cost)": 1105400,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 27.33,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 61.265983,
    "out:PV (m2 panels)": 321,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 57484.06,
    "out:PV (kWh sold)": 7361.45,
    "out:PV (% sold (El))": 12.81,
    "out:PV (kWh self-consumed)": 50122.6,
    "out:PV (ratio sold/self-consumed)": 0.15,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 493728,
    "out:EL kWh savings": -93097,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 128574.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 300413,
    "out:DH kr savings": 449292,
    "out:El kr savings": -158265,
    "out:El kr sold": 9386,
    "out:El kr saved": 85208,
    "out:El kr return": 94594,
    "out:% solar/total": 61,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 389907,
    "out:% savings (space heating)": 40.8,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 63.82,
    "out:Etvv": 0,
    "out:Ef": 34,
    "out:heatPumpQuantity": 10,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000001010001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 76.72,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 44.96,
    "out:Electricity (inc PV)": 33.72,
    "out:Total Energy Use Pre PV": 121.96,
    "out:Total Energy Use Post PV": 110.72,
    "out:Primary Energy": 107.35,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 15.61,
    "out:CO2 Operational/m2": 25.16,
    "out:Total CO2/m2": 40.77,
    "out:Total CO2 (tons)": 209.68,
    "out:Klimatpaverkan": -119.56,
    "out:Initial Cost/MSEK": 5.796125,
    "out:Running cost/(Apt SEK y)": 31891,
    "out:Running Cost over RSP/MSEK": 10.465,
    "out:LCP/MSEK": 3.008,
    "out:ROI% old": 21.7,
    "out:Payback discounted": 6,
    "out:Atemp": 5143,
    "out:Umean": 0.82,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 111,
    "out:Energy use kWh/m2": 111,
    "out:Energy savings %": 69.4,
    "out:Op energy cost/MSEK": 76,
    "out:El price/MSEK": 50,
    "out:Heating price/MSEK": 22,
    "out:DH+ DHW/kWh/m2": 77,
    "out:El bought/kWh/m2": 2495.3,
    "out:Return %": 19,
    "out:Return/kSEK/y": 1125,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 1479000,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 73950,
    "out:EAHP (replacement cost)": 1105400,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 26.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 61.265983,
    "out:PV (m2 panels)": 321,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 57484.06,
    "out:PV (kWh sold)": 7633.29,
    "out:PV (% sold (El))": 13.28,
    "out:PV (kWh self-consumed)": 49850.77,
    "out:PV (ratio sold/self-consumed)": 0.15,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 488585,
    "out:EL kWh savings": -91669,
    "out:Sewage heat recovery cost": 250448,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 298507,
    "out:DH kr savings": 444612,
    "out:El kr savings": -155838,
    "out:El kr sold": 9732,
    "out:El kr saved": 84746,
    "out:El kr return": 94479,
    "out:% solar/total": 61,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 394576,
    "out:% savings (space heating)": 40.09,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 66.64,
    "out:Etvv": 0,
    "out:Ef": 33.72,
    "out:heatPumpQuantity": 10,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000001010001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 71.45,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 44.88,
    "out:Electricity (inc PV)": 33.61,
    "out:Total Energy Use Pre PV": 115.88,
    "out:Total Energy Use Post PV": 104.61,
    "out:Primary Energy": 103.55,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 15.61,
    "out:CO2 Operational/m2": 22.95,
    "out:Total CO2/m2": 38.56,
    "out:Total CO2 (tons)": 198.31,
    "out:Klimatpaverkan": -130.93,
    "out:Initial Cost/MSEK": 5.92475,
    "out:Running cost/(Apt SEK y)": 29942,
    "out:Running Cost over RSP/MSEK": 9.825,
    "out:LCP/MSEK": 3.52,
    "out:ROI% old": 22.77,
    "out:Payback discounted": 5,
    "out:Atemp": 5143,
    "out:Umean": 0.82,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 105,
    "out:Energy use kWh/m2": 105,
    "out:Energy savings %": 79,
    "out:Op energy cost/MSEK": 71,
    "out:El price/MSEK": 47,
    "out:Heating price/MSEK": 20,
    "out:DH+ DHW/kWh/m2": 71,
    "out:El bought/kWh/m2": 2489.8,
    "out:Return %": 20,
    "out:Return/kSEK/y": 1206,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 1479000,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 73950,
    "out:EAHP (replacement cost)": 1105400,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 26.68,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 61.265983,
    "out:PV (m2 panels)": 321,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 57484.06,
    "out:PV (kWh sold)": 7674.16,
    "out:PV (% sold (El))": 13.35,
    "out:PV (kWh self-consumed)": 49809.89,
    "out:PV (ratio sold/self-consumed)": 0.15,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 519443,
    "out:EL kWh savings": -91072,
    "out:Sewage heat recovery cost": 250448,
    "out:Balancing hydronic system cost": 128574.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 327656,
    "out:DH kr savings": 472693,
    "out:El kr savings": -154822,
    "out:El kr sold": 9785,
    "out:El kr saved": 84677,
    "out:El kr return": 94461,
    "out:% solar/total": 61,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 367481,
    "out:% savings (space heating)": 44.2,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 61.52,
    "out:Etvv": 0,
    "out:Ef": 33.61,
    "out:heatPumpQuantity": 10,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000001020000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 81.14,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 45.6,
    "out:Electricity (inc PV)": 30.82,
    "out:Total Energy Use Pre PV": 126.6,
    "out:Total Energy Use Post PV": 111.82,
    "out:Primary Energy": 103.75,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 27.56,
    "out:CO2 Operational/m2": -12.38,
    "out:Total CO2/m2": 15.18,
    "out:Total CO2 (tons)": 78.06,
    "out:Klimatpaverkan": -251.18,
    "out:Initial Cost/MSEK": 6.6155,
    "out:Running cost/(Apt SEK y)": 31382,
    "out:Running Cost over RSP/MSEK": 10.314,
    "out:LCP/MSEK": 2.339,
    "out:ROI% old": 19.34,
    "out:Payback discounted": 6,
    "out:Atemp": 5143,
    "out:Umean": 0.82,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 112,
    "out:Energy use kWh/m2": 112,
    "out:Energy savings %": 67.9,
    "out:Op energy cost/MSEK": 75,
    "out:El price/MSEK": 48,
    "out:Heating price/MSEK": 23,
    "out:DH+ DHW/kWh/m2": 81,
    "out:El bought/kWh/m2": 2288.3,
    "out:Return %": 17,
    "out:Return/kSEK/y": 1146,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 1479000,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 73950,
    "out:EAHP (replacement cost)": 1105400,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 27.4,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.33,
    "out:PV (kWp installed)": 122.531965,
    "out:PV (m2 panels)": 643,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 114968.11,
    "out:PV (kWh sold)": 46933.69,
    "out:PV (% sold (El))": 40.82,
    "out:PV (kWh self-consumed)": 68034.42,
    "out:PV (ratio sold/self-consumed)": 0.69,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 468013,
    "out:EL kWh savings": -76712,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 355322,
    "out:DH kr savings": 425892,
    "out:El kr savings": -130410,
    "out:El kr sold": 59840,
    "out:El kr saved": 115659,
    "out:El kr return": 175499,
    "out:% solar/total": 83,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 417280,
    "out:% savings (space heating)": 36.64,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 68.98,
    "out:Etvv": 0,
    "out:Ef": 30.82,
    "out:heatPumpQuantity": 10,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000001020000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 75.81,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 45.53,
    "out:Electricity (inc PV)": 30.72,
    "out:Total Energy Use Pre PV": 121.53,
    "out:Total Energy Use Post PV": 106.72,
    "out:Primary Energy": 99.97,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 27.56,
    "out:CO2 Operational/m2": -14.25,
    "out:Total CO2/m2": 13.31,
    "out:Total CO2 (tons)": 68.43,
    "out:Klimatpaverkan": -260.81,
    "out:Initial Cost/MSEK": 6.744,
    "out:Running cost/(Apt SEK y)": 29759,
    "out:Running Cost over RSP/MSEK": 9.781,
    "out:LCP/MSEK": 2.744,
    "out:ROI% old": 20.1,
    "out:Payback discounted": 6,
    "out:Atemp": 5143,
    "out:Umean": 0.82,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 107,
    "out:Energy use kWh/m2": 107,
    "out:Energy savings %": 75.7,
    "out:Op energy cost/MSEK": 72,
    "out:El price/MSEK": 46,
    "out:Heating price/MSEK": 22,
    "out:DH+ DHW/kWh/m2": 76,
    "out:El bought/kWh/m2": 2284.4,
    "out:Return %": 18,
    "out:Return/kSEK/y": 1214,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 1479000,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 73950,
    "out:EAHP (replacement cost)": 1105400,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 27.33,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.33,
    "out:PV (kWp installed)": 122.531965,
    "out:PV (m2 panels)": 643,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 114968.11,
    "out:PV (kWh sold)": 47000.12,
    "out:PV (% sold (El))": 40.88,
    "out:PV (kWh self-consumed)": 67967.99,
    "out:PV (ratio sold/self-consumed)": 0.69,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 493728,
    "out:EL kWh savings": -76218,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 128574.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 379647,
    "out:DH kr savings": 449292,
    "out:El kr savings": -129571,
    "out:El kr sold": 59925,
    "out:El kr saved": 115546,
    "out:El kr return": 175471,
    "out:% solar/total": 83,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 389907,
    "out:% savings (space heating)": 40.8,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 63.82,
    "out:Etvv": 0,
    "out:Ef": 30.72,
    "out:heatPumpQuantity": 10,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000001020001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 76.72,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 44.96,
    "out:Electricity (inc PV)": 30.47,
    "out:Total Energy Use Pre PV": 121.96,
    "out:Total Energy Use Post PV": 107.47,
    "out:Primary Energy": 101.49,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 27.96,
    "out:CO2 Operational/m2": -14.51,
    "out:Total CO2/m2": 13.45,
    "out:Total CO2 (tons)": 69.17,
    "out:Klimatpaverkan": -260.07,
    "out:Initial Cost/MSEK": 6.865875,
    "out:Running cost/(Apt SEK y)": 29968,
    "out:Running Cost over RSP/MSEK": 9.85,
    "out:LCP/MSEK": 2.553,
    "out:ROI% old": 19.6,
    "out:Payback discounted": 6,
    "out:Atemp": 5143,
    "out:Umean": 0.82,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 107,
    "out:Energy use kWh/m2": 107,
    "out:Energy savings %": 75.7,
    "out:Op energy cost/MSEK": 72,
    "out:El price/MSEK": 46,
    "out:Heating price/MSEK": 22,
    "out:DH+ DHW/kWh/m2": 77,
    "out:El bought/kWh/m2": 2251.7,
    "out:Return %": 18,
    "out:Return/kSEK/y": 1205,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 1479000,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 73950,
    "out:EAHP (replacement cost)": 1105400,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 26.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.33,
    "out:PV (kWp installed)": 122.531965,
    "out:PV (m2 panels)": 643,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 114968.11,
    "out:PV (kWh sold)": 47556.59,
    "out:PV (% sold (El))": 41.37,
    "out:PV (kWh self-consumed)": 67411.52,
    "out:PV (ratio sold/self-consumed)": 0.71,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 488585,
    "out:EL kWh savings": -74938,
    "out:Sewage heat recovery cost": 250448,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 377852,
    "out:DH kr savings": 444612,
    "out:El kr savings": -127395,
    "out:El kr sold": 60635,
    "out:El kr saved": 114600,
    "out:El kr return": 175234,
    "out:% solar/total": 82,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 394576,
    "out:% savings (space heating)": 40.09,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 66.64,
    "out:Etvv": 0,
    "out:Ef": 30.47,
    "out:heatPumpQuantity": 10,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000001020001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 71.45,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 44.88,
    "out:Electricity (inc PV)": 30.37,
    "out:Total Energy Use Pre PV": 115.88,
    "out:Total Energy Use Post PV": 101.37,
    "out:Primary Energy": 97.72,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 27.96,
    "out:CO2 Operational/m2": -16.76,
    "out:Total CO2/m2": 11.2,
    "out:Total CO2 (tons)": 57.59,
    "out:Klimatpaverkan": -271.65,
    "out:Initial Cost/MSEK": 6.9945,
    "out:Running cost/(Apt SEK y)": 28020,
    "out:Running Cost over RSP/MSEK": 9.21,
    "out:LCP/MSEK": 3.065,
    "out:ROI% old": 20.54,
    "out:Payback discounted": 6,
    "out:Atemp": 5143,
    "out:Umean": 0.82,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 101,
    "out:Energy use kWh/m2": 101,
    "out:Energy savings %": 86.1,
    "out:Op energy cost/MSEK": 67,
    "out:El price/MSEK": 44,
    "out:Heating price/MSEK": 20,
    "out:DH+ DHW/kWh/m2": 71,
    "out:El bought/kWh/m2": 2246.8,
    "out:Return %": 18,
    "out:Return/kSEK/y": 1287,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 1479000,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 73950,
    "out:EAHP (replacement cost)": 1105400,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 26.68,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.33,
    "out:PV (kWp installed)": 122.531965,
    "out:PV (m2 panels)": 643,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 114968.11,
    "out:PV (kWh sold)": 47639.41,
    "out:PV (% sold (El))": 41.44,
    "out:PV (kWh self-consumed)": 67328.7,
    "out:PV (ratio sold/self-consumed)": 0.71,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 519443,
    "out:EL kWh savings": -74403,
    "out:Sewage heat recovery cost": 250448,
    "out:Balancing hydronic system cost": 128574.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 406948,
    "out:DH kr savings": 472693,
    "out:El kr savings": -126485,
    "out:El kr sold": 60740,
    "out:El kr saved": 114459,
    "out:El kr return": 175199,
    "out:% solar/total": 82,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 367481,
    "out:% savings (space heating)": 44.2,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 61.52,
    "out:Etvv": 0,
    "out:Ef": 30.37,
    "out:heatPumpQuantity": 10,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000001100000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 75.28,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 44.74,
    "out:Electricity (inc PV)": 43.11,
    "out:Total Energy Use Pre PV": 119.74,
    "out:Total Energy Use Post PV": 118.11,
    "out:Primary Energy": 123.7,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 2.85,
    "out:CO2 Operational/m2": 32.9,
    "out:Total CO2/m2": 35.76,
    "out:Total CO2 (tons)": 183.89,
    "out:Klimatpaverkan": -145.35,
    "out:Initial Cost/MSEK": 4.58675,
    "out:Running cost/(Apt SEK y)": 33450,
    "out:Running Cost over RSP/MSEK": 10.957,
    "out:LCP/MSEK": 3.725,
    "out:ROI% old": 25.89,
    "out:Payback discounted": 5,
    "out:Atemp": 5143,
    "out:Umean": 0.82,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 118,
    "out:Energy use kWh/m2": 118,
    "out:Energy savings %": 59.3,
    "out:Op energy cost/MSEK": 79,
    "out:El price/MSEK": 54,
    "out:Heating price/MSEK": 21,
    "out:DH+ DHW/kWh/m2": 75,
    "out:El bought/kWh/m2": 3131.6,
    "out:Return %": 23,
    "out:Return/kSEK/y": 1059,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 1479000,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 73950,
    "out:EAHP (replacement cost)": 1105400,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 26.54,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 498871,
    "out:EL kWh savings": -139918,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 216113,
    "out:DH kr savings": 453973,
    "out:El kr savings": -237860,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 387163,
    "out:% savings (space heating)": 41.21,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 65.87,
    "out:Etvv": 0,
    "out:Ef": 43.11,
    "out:heatPumpQuantity": 10,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000001100000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 70.03,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 44.65,
    "out:Electricity (inc PV)": 42.97,
    "out:Total Energy Use Pre PV": 114.65,
    "out:Total Energy Use Post PV": 112.97,
    "out:Primary Energy": 119.88,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 2.85,
    "out:CO2 Operational/m2": 31.09,
    "out:Total CO2/m2": 33.94,
    "out:Total CO2 (tons)": 174.57,
    "out:Klimatpaverkan": -154.67,
    "out:Initial Cost/MSEK": 4.715375,
    "out:Running cost/(Apt SEK y)": 31822,
    "out:Running Cost over RSP/MSEK": 10.422,
    "out:LCP/MSEK": 4.131,
    "out:ROI% old": 26.8,
    "out:Payback discounted": 5,
    "out:Atemp": 5143,
    "out:Umean": 0.82,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 113,
    "out:Energy use kWh/m2": 113,
    "out:Energy savings %": 66.4,
    "out:Op energy cost/MSEK": 75,
    "out:El price/MSEK": 51,
    "out:Heating price/MSEK": 20,
    "out:DH+ DHW/kWh/m2": 70,
    "out:El bought/kWh/m2": 3125.2,
    "out:Return %": 24,
    "out:Return/kSEK/y": 1127,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 1479000,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 73950,
    "out:EAHP (replacement cost)": 1105400,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 26.45,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 524586,
    "out:EL kWh savings": -139234,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 128574.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 240675,
    "out:DH kr savings": 477373,
    "out:El kr savings": -236698,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 360176,
    "out:% savings (space heating)": 45.31,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 60.76,
    "out:Etvv": 0,
    "out:Ef": 42.97,
    "out:heatPumpQuantity": 10,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000001100001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 71.89,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 44.18,
    "out:Electricity (inc PV)": 42.75,
    "out:Total Energy Use Pre PV": 116.18,
    "out:Total Energy Use Post PV": 114.75,
    "out:Primary Energy": 121.78,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 3.25,
    "out:CO2 Operational/m2": 31.75,
    "out:Total CO2/m2": 35,
    "out:Total CO2 (tons)": 180.03,
    "out:Klimatpaverkan": -149.21,
    "out:Initial Cost/MSEK": 4.83725,
    "out:Running cost/(Apt SEK y)": 32369,
    "out:Running Cost over RSP/MSEK": 10.603,
    "out:LCP/MSEK": 3.829,
    "out:ROI% old": 25.59,
    "out:Payback discounted": 5,
    "out:Atemp": 5143,
    "out:Umean": 0.82,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 115,
    "out:Energy use kWh/m2": 115,
    "out:Energy savings %": 63.5,
    "out:Op energy cost/MSEK": 76,
    "out:El price/MSEK": 52,
    "out:Heating price/MSEK": 21,
    "out:DH+ DHW/kWh/m2": 72,
    "out:El bought/kWh/m2": 3092.9,
    "out:Return %": 23,
    "out:Return/kSEK/y": 1104,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 1479000,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 73950,
    "out:EAHP (replacement cost)": 1105400,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 25.98,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 514300,
    "out:EL kWh savings": -138102,
    "out:Sewage heat recovery cost": 250448,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 233240,
    "out:DH kr savings": 468013,
    "out:El kr savings": -234773,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 369711,
    "out:% savings (space heating)": 43.86,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 64.03,
    "out:Etvv": 0,
    "out:Ef": 42.75,
    "out:heatPumpQuantity": 10,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000001100001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 66.69,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 44.08,
    "out:Electricity (inc PV)": 42.61,
    "out:Total Energy Use Pre PV": 111.08,
    "out:Total Energy Use Post PV": 109.61,
    "out:Primary Energy": 117.96,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 3.25,
    "out:CO2 Operational/m2": 29.94,
    "out:Total CO2/m2": 33.19,
    "out:Total CO2 (tons)": 170.7,
    "out:Klimatpaverkan": -158.54,
    "out:Initial Cost/MSEK": 4.96575,
    "out:Running cost/(Apt SEK y)": 30738,
    "out:Running Cost over RSP/MSEK": 10.067,
    "out:LCP/MSEK": 4.237,
    "out:ROI% old": 26.47,
    "out:Payback discounted": 5,
    "out:Atemp": 5143,
    "out:Umean": 0.82,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 110,
    "out:Energy use kWh/m2": 110,
    "out:Energy savings %": 70.9,
    "out:Op energy cost/MSEK": 72,
    "out:El price/MSEK": 50,
    "out:Heating price/MSEK": 19,
    "out:DH+ DHW/kWh/m2": 67,
    "out:El bought/kWh/m2": 3085.4,
    "out:Return %": 24,
    "out:Return/kSEK/y": 1173,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 1479000,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 73950,
    "out:EAHP (replacement cost)": 1105400,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 25.88,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 540015,
    "out:EL kWh savings": -137368,
    "out:Sewage heat recovery cost": 250448,
    "out:Balancing hydronic system cost": 128574.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 257888,
    "out:DH kr savings": 491414,
    "out:El kr savings": -233525,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 342978,
    "out:% savings (space heating)": 47.92,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 58.95,
    "out:Etvv": 0,
    "out:Ef": 42.61,
    "out:heatPumpQuantity": 10,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000001110000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 75.28,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 44.74,
    "out:Electricity (inc PV)": 33.59,
    "out:Total Energy Use Pre PV": 119.74,
    "out:Total Energy Use Post PV": 108.59,
    "out:Primary Energy": 106.57,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 15.2,
    "out:CO2 Operational/m2": 24.31,
    "out:Total CO2/m2": 39.52,
    "out:Total CO2 (tons)": 203.23,
    "out:Klimatpaverkan": -126.01,
    "out:Initial Cost/MSEK": 5.6565,
    "out:Running cost/(Apt SEK y)": 31202,
    "out:Running Cost over RSP/MSEK": 10.239,
    "out:LCP/MSEK": 3.374,
    "out:ROI% old": 22.81,
    "out:Payback discounted": 5,
    "out:Atemp": 5143,
    "out:Umean": 0.82,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 109,
    "out:Energy use kWh/m2": 109,
    "out:Energy savings %": 72.5,
    "out:Op energy cost/MSEK": 74,
    "out:El price/MSEK": 49,
    "out:Heating price/MSEK": 21,
    "out:DH+ DHW/kWh/m2": 75,
    "out:El bought/kWh/m2": 2481,
    "out:Return %": 20,
    "out:Return/kSEK/y": 1153,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 1479000,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 73950,
    "out:EAHP (replacement cost)": 1105400,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 26.54,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 61.265983,
    "out:PV (m2 panels)": 321,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 57484.06,
    "out:PV (kWh sold)": 7740.99,
    "out:PV (% sold (El))": 13.47,
    "out:PV (kWh self-consumed)": 49743.06,
    "out:PV (ratio sold/self-consumed)": 0.16,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 498871,
    "out:EL kWh savings": -90999,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 309144,
    "out:DH kr savings": 453973,
    "out:El kr savings": -154698,
    "out:El kr sold": 9870,
    "out:El kr saved": 84563,
    "out:El kr return": 94433,
    "out:% solar/total": 61,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 387163,
    "out:% savings (space heating)": 41.21,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 65.87,
    "out:Etvv": 0,
    "out:Ef": 33.59,
    "out:heatPumpQuantity": 10,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000001110000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 70.03,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 44.65,
    "out:Electricity (inc PV)": 33.47,
    "out:Total Energy Use Pre PV": 114.65,
    "out:Total Energy Use Post PV": 103.47,
    "out:Primary Energy": 102.78,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 15.2,
    "out:CO2 Operational/m2": 22.46,
    "out:Total CO2/m2": 37.66,
    "out:Total CO2 (tons)": 193.7,
    "out:Klimatpaverkan": -135.54,
    "out:Initial Cost/MSEK": 5.785125,
    "out:Running cost/(Apt SEK y)": 29574,
    "out:Running Cost over RSP/MSEK": 9.704,
    "out:LCP/MSEK": 3.78,
    "out:ROI% old": 23.62,
    "out:Payback discounted": 5,
    "out:Atemp": 5143,
    "out:Umean": 0.82,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 103,
    "out:Energy use kWh/m2": 103,
    "out:Energy savings %": 82.5,
    "out:Op energy cost/MSEK": 70,
    "out:El price/MSEK": 47,
    "out:Heating price/MSEK": 20,
    "out:DH+ DHW/kWh/m2": 70,
    "out:El bought/kWh/m2": 2475.1,
    "out:Return %": 21,
    "out:Return/kSEK/y": 1222,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 1479000,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 73950,
    "out:EAHP (replacement cost)": 1105400,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 26.45,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 61.265983,
    "out:PV (m2 panels)": 321,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 57484.06,
    "out:PV (kWh sold)": 7785.47,
    "out:PV (% sold (El))": 13.54,
    "out:PV (kWh self-consumed)": 49698.58,
    "out:PV (ratio sold/self-consumed)": 0.16,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 524586,
    "out:EL kWh savings": -90385,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 128574.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 333645,
    "out:DH kr savings": 477373,
    "out:El kr savings": -153654,
    "out:El kr sold": 9926,
    "out:El kr saved": 84488,
    "out:El kr return": 94414,
    "out:% solar/total": 61,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 360176,
    "out:% savings (space heating)": 45.31,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 60.76,
    "out:Etvv": 0,
    "out:Ef": 33.47,
    "out:heatPumpQuantity": 10,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000001110001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 71.89,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 44.18,
    "out:Electricity (inc PV)": 33.28,
    "out:Total Energy Use Pre PV": 116.18,
    "out:Total Energy Use Post PV": 105.28,
    "out:Primary Energy": 104.72,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 15.61,
    "out:CO2 Operational/m2": 22.9,
    "out:Total CO2/m2": 38.51,
    "out:Total CO2 (tons)": 198.03,
    "out:Klimatpaverkan": -131.21,
    "out:Initial Cost/MSEK": 5.907,
    "out:Running cost/(Apt SEK y)": 30124,
    "out:Running Cost over RSP/MSEK": 9.885,
    "out:LCP/MSEK": 3.477,
    "out:ROI% old": 22.7,
    "out:Payback discounted": 5,
    "out:Atemp": 5143,
    "out:Umean": 0.82,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 105,
    "out:Energy use kWh/m2": 105,
    "out:Energy savings %": 79,
    "out:Op energy cost/MSEK": 72,
    "out:El price/MSEK": 48,
    "out:Heating price/MSEK": 21,
    "out:DH+ DHW/kWh/m2": 72,
    "out:El bought/kWh/m2": 2445.5,
    "out:Return %": 20,
    "out:Return/kSEK/y": 1199,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 1479000,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 73950,
    "out:EAHP (replacement cost)": 1105400,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 25.98,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 61.265983,
    "out:PV (m2 panels)": 321,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 57484.06,
    "out:PV (kWh sold)": 8014.13,
    "out:PV (% sold (El))": 13.94,
    "out:PV (kWh self-consumed)": 49469.93,
    "out:PV (ratio sold/self-consumed)": 0.16,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 514300,
    "out:EL kWh savings": -89368,
    "out:Sewage heat recovery cost": 250448,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 326305,
    "out:DH kr savings": 468013,
    "out:El kr savings": -151926,
    "out:El kr sold": 10218,
    "out:El kr saved": 84099,
    "out:El kr return": 94317,
    "out:% solar/total": 60,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 369711,
    "out:% savings (space heating)": 43.86,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 64.03,
    "out:Etvv": 0,
    "out:Ef": 33.28,
    "out:heatPumpQuantity": 10,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000001110001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 66.69,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 44.08,
    "out:Electricity (inc PV)": 33.15,
    "out:Total Energy Use Pre PV": 111.08,
    "out:Total Energy Use Post PV": 100.15,
    "out:Primary Energy": 100.93,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 15.61,
    "out:CO2 Operational/m2": 21.03,
    "out:Total CO2/m2": 36.64,
    "out:Total CO2 (tons)": 188.45,
    "out:Klimatpaverkan": -140.79,
    "out:Initial Cost/MSEK": 6.035625,
    "out:Running cost/(Apt SEK y)": 28493,
    "out:Running Cost over RSP/MSEK": 9.349,
    "out:LCP/MSEK": 3.885,
    "out:ROI% old": 23.48,
    "out:Payback discounted": 5,
    "out:Atemp": 5143,
    "out:Umean": 0.82,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 100,
    "out:Energy use kWh/m2": 100,
    "out:Energy savings %": 88,
    "out:Op energy cost/MSEK": 68,
    "out:El price/MSEK": 45,
    "out:Heating price/MSEK": 19,
    "out:DH+ DHW/kWh/m2": 67,
    "out:El bought/kWh/m2": 2438.7,
    "out:Return %": 21,
    "out:Return/kSEK/y": 1267,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 1479000,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 73950,
    "out:EAHP (replacement cost)": 1105400,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 25.88,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 61.265983,
    "out:PV (m2 panels)": 321,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 57484.06,
    "out:PV (kWh sold)": 8067.4,
    "out:PV (% sold (El))": 14.03,
    "out:PV (kWh self-consumed)": 49416.66,
    "out:PV (ratio sold/self-consumed)": 0.16,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 540015,
    "out:EL kWh savings": -88709,
    "out:Sewage heat recovery cost": 250448,
    "out:Balancing hydronic system cost": 128574.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 350894,
    "out:DH kr savings": 491414,
    "out:El kr savings": -150805,
    "out:El kr sold": 10286,
    "out:El kr saved": 84008,
    "out:El kr return": 94294,
    "out:% solar/total": 60,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 342978,
    "out:% savings (space heating)": 47.92,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 58.95,
    "out:Etvv": 0,
    "out:Ef": 33.15,
    "out:heatPumpQuantity": 10,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000001120000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 75.28,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 44.74,
    "out:Electricity (inc PV)": 30.35,
    "out:Total Energy Use Pre PV": 119.74,
    "out:Total Energy Use Post PV": 105.35,
    "out:Primary Energy": 100.74,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 27.56,
    "out:CO2 Operational/m2": -15.47,
    "out:Total CO2/m2": 12.09,
    "out:Total CO2 (tons)": 62.16,
    "out:Klimatpaverkan": -267.08,
    "out:Initial Cost/MSEK": 6.726375,
    "out:Running cost/(Apt SEK y)": 29280,
    "out:Running Cost over RSP/MSEK": 9.624,
    "out:LCP/MSEK": 2.918,
    "out:ROI% old": 20.48,
    "out:Payback discounted": 6,
    "out:Atemp": 5143,
    "out:Umean": 0.82,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 105,
    "out:Energy use kWh/m2": 105,
    "out:Energy savings %": 79,
    "out:Op energy cost/MSEK": 70,
    "out:El price/MSEK": 45,
    "out:Heating price/MSEK": 21,
    "out:DH+ DHW/kWh/m2": 75,
    "out:El bought/kWh/m2": 2238.9,
    "out:Return %": 18,
    "out:Return/kSEK/y": 1234,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 1479000,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 73950,
    "out:EAHP (replacement cost)": 1105400,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 26.54,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.33,
    "out:PV (kWp installed)": 122.531965,
    "out:PV (m2 panels)": 643,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 114968.11,
    "out:PV (kWh sold)": 47773.96,
    "out:PV (% sold (El))": 41.55,
    "out:PV (kWh self-consumed)": 67194.15,
    "out:PV (ratio sold/self-consumed)": 0.71,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 498871,
    "out:EL kWh savings": -74338,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 388510,
    "out:DH kr savings": 453973,
    "out:El kr savings": -126375,
    "out:El kr sold": 60912,
    "out:El kr saved": 114230,
    "out:El kr return": 175142,
    "out:% solar/total": 82,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 387163,
    "out:% savings (space heating)": 41.21,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 65.87,
    "out:Etvv": 0,
    "out:Ef": 30.35,
    "out:heatPumpQuantity": 10,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000001120000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 70.03,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 44.65,
    "out:Electricity (inc PV)": 30.25,
    "out:Total Energy Use Pre PV": 114.65,
    "out:Total Energy Use Post PV": 100.25,
    "out:Primary Energy": 96.97,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 27.56,
    "out:CO2 Operational/m2": -17.37,
    "out:Total CO2/m2": 10.19,
    "out:Total CO2 (tons)": 52.39,
    "out:Klimatpaverkan": -276.85,
    "out:Initial Cost/MSEK": 6.854875,
    "out:Running cost/(Apt SEK y)": 27653,
    "out:Running Cost over RSP/MSEK": 9.089,
    "out:LCP/MSEK": 3.325,
    "out:ROI% old": 21.21,
    "out:Payback discounted": 6,
    "out:Atemp": 5143,
    "out:Umean": 0.82,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 100,
    "out:Energy use kWh/m2": 100,
    "out:Energy savings %": 88,
    "out:Op energy cost/MSEK": 66,
    "out:El price/MSEK": 43,
    "out:Heating price/MSEK": 20,
    "out:DH+ DHW/kWh/m2": 70,
    "out:El bought/kWh/m2": 2233.7,
    "out:Return %": 19,
    "out:Return/kSEK/y": 1302,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 1479000,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 73950,
    "out:EAHP (replacement cost)": 1105400,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 26.45,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.33,
    "out:PV (kWp installed)": 122.531965,
    "out:PV (m2 panels)": 643,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 114968.11,
    "out:PV (kWh sold)": 47863.16,
    "out:PV (% sold (El))": 41.63,
    "out:PV (kWh self-consumed)": 67104.95,
    "out:PV (ratio sold/self-consumed)": 0.71,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 524586,
    "out:EL kWh savings": -73788,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 128574.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 412959,
    "out:DH kr savings": 477373,
    "out:El kr savings": -125439,
    "out:El kr sold": 61026,
    "out:El kr saved": 114078,
    "out:El kr return": 175104,
    "out:% solar/total": 82,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 360176,
    "out:% savings (space heating)": 45.31,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 60.76,
    "out:Etvv": 0,
    "out:Ef": 30.25,
    "out:heatPumpQuantity": 10,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000001120001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 71.89,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 44.18,
    "out:Electricity (inc PV)": 30.07,
    "out:Total Energy Use Pre PV": 116.18,
    "out:Total Energy Use Post PV": 102.07,
    "out:Primary Energy": 98.95,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 27.96,
    "out:CO2 Operational/m2": -17.15,
    "out:Total CO2/m2": 10.81,
    "out:Total CO2 (tons)": 55.57,
    "out:Klimatpaverkan": -273.67,
    "out:Initial Cost/MSEK": 6.97675,
    "out:Running cost/(Apt SEK y)": 28205,
    "out:Running Cost over RSP/MSEK": 9.271,
    "out:LCP/MSEK": 3.021,
    "out:ROI% old": 20.47,
    "out:Payback discounted": 6,
    "out:Atemp": 5143,
    "out:Umean": 0.82,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 102,
    "out:Energy use kWh/m2": 102,
    "out:Energy savings %": 84.3,
    "out:Op energy cost/MSEK": 68,
    "out:El price/MSEK": 44,
    "out:Heating price/MSEK": 21,
    "out:DH+ DHW/kWh/m2": 72,
    "out:El bought/kWh/m2": 2207.3,
    "out:Return %": 18,
    "out:Return/kSEK/y": 1279,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 1479000,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 73950,
    "out:EAHP (replacement cost)": 1105400,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 25.98,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.33,
    "out:PV (kWp installed)": 122.531965,
    "out:PV (m2 panels)": 643,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 114968.11,
    "out:PV (kWh sold)": 48317.1,
    "out:PV (% sold (El))": 42.03,
    "out:PV (kWh self-consumed)": 66651.01,
    "out:PV (ratio sold/self-consumed)": 0.72,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 514300,
    "out:EL kWh savings": -72877,
    "out:Sewage heat recovery cost": 250448,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 405726,
    "out:DH kr savings": 468013,
    "out:El kr savings": -123891,
    "out:El kr sold": 61604,
    "out:El kr saved": 113307,
    "out:El kr return": 174911,
    "out:% solar/total": 82,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 369711,
    "out:% savings (space heating)": 43.86,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 64.03,
    "out:Etvv": 0,
    "out:Ef": 30.07,
    "out:heatPumpQuantity": 10,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000001120001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 66.69,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 44.08,
    "out:Electricity (inc PV)": 29.96,
    "out:Total Energy Use Pre PV": 111.08,
    "out:Total Energy Use Post PV": 96.96,
    "out:Primary Energy": 95.19,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 27.96,
    "out:CO2 Operational/m2": -19.07,
    "out:Total CO2/m2": 8.89,
    "out:Total CO2 (tons)": 45.72,
    "out:Klimatpaverkan": -283.52,
    "out:Initial Cost/MSEK": 7.105375,
    "out:Running cost/(Apt SEK y)": 26575,
    "out:Running Cost over RSP/MSEK": 8.735,
    "out:LCP/MSEK": 3.428,
    "out:ROI% old": 21.18,
    "out:Payback discounted": 6,
    "out:Atemp": 5143,
    "out:Umean": 0.82,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 97,
    "out:Energy use kWh/m2": 97,
    "out:Energy savings %": 93.8,
    "out:Op energy cost/MSEK": 64,
    "out:El price/MSEK": 41,
    "out:Heating price/MSEK": 19,
    "out:DH+ DHW/kWh/m2": 67,
    "out:El bought/kWh/m2": 2201.2,
    "out:Return %": 19,
    "out:Return/kSEK/y": 1348,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 1479000,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 73950,
    "out:EAHP (replacement cost)": 1105400,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 25.88,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.33,
    "out:PV (kWp installed)": 122.531965,
    "out:PV (m2 panels)": 643,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 114968.11,
    "out:PV (kWh sold)": 48421.75,
    "out:PV (% sold (El))": 42.12,
    "out:PV (kWh self-consumed)": 66546.36,
    "out:PV (ratio sold/self-consumed)": 0.73,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 540015,
    "out:EL kWh savings": -72287,
    "out:Sewage heat recovery cost": 250448,
    "out:Balancing hydronic system cost": 128574.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 430264,
    "out:DH kr savings": 491414,
    "out:El kr savings": -122887,
    "out:El kr sold": 61738,
    "out:El kr saved": 113129,
    "out:El kr return": 174867,
    "out:% solar/total": 81,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 342978,
    "out:% savings (space heating)": 47.92,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 58.95,
    "out:Etvv": 0,
    "out:Ef": 29.96,
    "out:heatPumpQuantity": 10,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000002000000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 25.56,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 62.44,
    "out:Electricity (inc PV)": 58.93,
    "out:Total Energy Use Pre PV": 88.44,
    "out:Total Energy Use Post PV": 84.93,
    "out:Primary Energy": 119.12,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 3.41,
    "out:CO2 Operational/m2": 17.6,
    "out:Total CO2/m2": 21.01,
    "out:Total CO2 (tons)": 108.08,
    "out:Klimatpaverkan": -221.16,
    "out:Initial Cost/MSEK": 7.127875,
    "out:Running cost/(Apt SEK y)": 21366,
    "out:Running Cost over RSP/MSEK": 6.971,
    "out:LCP/MSEK": 5.17,
    "out:ROI% old": 24.65,
    "out:Payback discounted": 5,
    "out:Atemp": 5143,
    "out:Umean": 0.82,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 85,
    "out:Energy use kWh/m2": 85,
    "out:Energy savings %": 121.2,
    "out:Op energy cost/MSEK": 48,
    "out:El price/MSEK": 40,
    "out:Heating price/MSEK": 7,
    "out:DH+ DHW/kWh/m2": 26,
    "out:El bought/kWh/m2": 4370.9,
    "out:Return %": 22,
    "out:Return/kSEK/y": 1567,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 2958000,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 147900,
    "out:EAHP (replacement cost)": 2204400,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 44.24,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 750878,
    "out:EL kWh savings": -221328,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 307042,
    "out:DH kr savings": 683299,
    "out:El kr savings": -376257,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 131435,
    "out:% savings (space heating)": 80.04,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 18.63,
    "out:Etvv": 0,
    "out:Ef": 58.93,
    "out:heatPumpQuantity": 20,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000002000000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 21.65,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 61.95,
    "out:Electricity (inc PV)": 58.31,
    "out:Total Energy Use Pre PV": 83.95,
    "out:Total Energy Use Post PV": 80.31,
    "out:Primary Energy": 115.56,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 3.41,
    "out:CO2 Operational/m2": 16.1,
    "out:Total CO2/m2": 19.51,
    "out:Total CO2 (tons)": 100.33,
    "out:Klimatpaverkan": -228.91,
    "out:Initial Cost/MSEK": 7.2565,
    "out:Running cost/(Apt SEK y)": 19975,
    "out:Running Cost over RSP/MSEK": 6.514,
    "out:LCP/MSEK": 5.498,
    "out:ROI% old": 25.11,
    "out:Payback discounted": 5,
    "out:Atemp": 5143,
    "out:Umean": 0.82,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 80,
    "out:Energy use kWh/m2": 80,
    "out:Energy savings %": 135,
    "out:Op energy cost/MSEK": 45,
    "out:El price/MSEK": 38,
    "out:Heating price/MSEK": 6,
    "out:DH+ DHW/kWh/m2": 22,
    "out:El bought/kWh/m2": 4336.2,
    "out:Return %": 22,
    "out:Return/kSEK/y": 1625,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 2958000,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 147900,
    "out:EAHP (replacement cost)": 2204400,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 43.75,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 771450,
    "out:EL kWh savings": -218122,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 128574.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 331212,
    "out:DH kr savings": 702019,
    "out:El kr savings": -370807,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 111322,
    "out:% savings (space heating)": 83.1,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 15.14,
    "out:Etvv": 0,
    "out:Ef": 58.31,
    "out:heatPumpQuantity": 20,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000002000001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 23,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 61.24,
    "out:Electricity (inc PV)": 58.19,
    "out:Total Energy Use Pre PV": 84.24,
    "out:Total Energy Use Post PV": 81.19,
    "out:Primary Energy": 116.88,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 3.81,
    "out:CO2 Operational/m2": 16.36,
    "out:Total CO2/m2": 20.18,
    "out:Total CO2 (tons)": 103.77,
    "out:Klimatpaverkan": -225.47,
    "out:Initial Cost/MSEK": 7.378375,
    "out:Running cost/(Apt SEK y)": 20150,
    "out:Running Cost over RSP/MSEK": 6.572,
    "out:LCP/MSEK": 5.319,
    "out:ROI% old": 24.58,
    "out:Payback discounted": 5,
    "out:Atemp": 5143,
    "out:Umean": 0.82,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 81,
    "out:Energy use kWh/m2": 81,
    "out:Energy savings %": 132.1,
    "out:Op energy cost/MSEK": 45,
    "out:El price/MSEK": 38,
    "out:Heating price/MSEK": 7,
    "out:DH+ DHW/kWh/m2": 23,
    "out:El bought/kWh/m2": 4286.7,
    "out:Return %": 22,
    "out:Return/kSEK/y": 1618,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 2958000,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 147900,
    "out:EAHP (replacement cost)": 2204400,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 43.04,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 766307,
    "out:EL kWh savings": -217483,
    "out:Sewage heat recovery cost": 250448,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 327618,
    "out:DH kr savings": 697339,
    "out:El kr savings": -369721,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 118298,
    "out:% savings (space heating)": 82.04,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 17.35,
    "out:Etvv": 0,
    "out:Ef": 58.19,
    "out:heatPumpQuantity": 20,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000002000001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 19.23,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 60.7,
    "out:Electricity (inc PV)": 57.53,
    "out:Total Energy Use Pre PV": 79.7,
    "out:Total Energy Use Post PV": 76.53,
    "out:Primary Energy": 113.33,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 3.81,
    "out:CO2 Operational/m2": 14.85,
    "out:Total CO2/m2": 18.67,
    "out:Total CO2 (tons)": 96,
    "out:Klimatpaverkan": -233.24,
    "out:Initial Cost/MSEK": 7.506875,
    "out:Running cost/(Apt SEK y)": 18751,
    "out:Running Cost over RSP/MSEK": 6.113,
    "out:LCP/MSEK": 5.65,
    "out:ROI% old": 25.04,
    "out:Payback discounted": 5,
    "out:Atemp": 5143,
    "out:Umean": 0.82,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 77,
    "out:Energy use kWh/m2": 77,
    "out:Energy savings %": 144.2,
    "out:Op energy cost/MSEK": 42,
    "out:El price/MSEK": 36,
    "out:Heating price/MSEK": 5,
    "out:DH+ DHW/kWh/m2": 19,
    "out:El bought/kWh/m2": 4249,
    "out:Return %": 22,
    "out:Return/kSEK/y": 1676,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 2958000,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 147900,
    "out:EAHP (replacement cost)": 2204400,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 42.5,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 786879,
    "out:EL kWh savings": -214127,
    "out:Sewage heat recovery cost": 250448,
    "out:Balancing hydronic system cost": 128574.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 352043,
    "out:DH kr savings": 716060,
    "out:El kr savings": -364017,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 98894,
    "out:% savings (space heating)": 84.98,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 13.95,
    "out:Etvv": 0,
    "out:Ef": 57.53,
    "out:heatPumpQuantity": 20,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000002010000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 25.56,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 62.44,
    "out:Electricity (inc PV)": 48.27,
    "out:Total Energy Use Pre PV": 88.44,
    "out:Total Energy Use Post PV": 74.27,
    "out:Primary Energy": 99.92,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 15.77,
    "out:CO2 Operational/m2": 14.59,
    "out:Total CO2/m2": 30.36,
    "out:Total CO2 (tons)": 156.12,
    "out:Klimatpaverkan": -173.12,
    "out:Initial Cost/MSEK": 8.19775,
    "out:Running cost/(Apt SEK y)": 19058,
    "out:Running Cost over RSP/MSEK": 6.233,
    "out:LCP/MSEK": 4.838,
    "out:ROI% old": 22.72,
    "out:Payback discounted": 5,
    "out:Atemp": 5143,
    "out:Umean": 0.82,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 74,
    "out:Energy use kWh/m2": 74,
    "out:Energy savings %": 154.1,
    "out:Op energy cost/MSEK": 44,
    "out:El price/MSEK": 35,
    "out:Heating price/MSEK": 7,
    "out:DH+ DHW/kWh/m2": 26,
    "out:El bought/kWh/m2": 3653.3,
    "out:Return %": 20,
    "out:Return/kSEK/y": 1663,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 2958000,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 147900,
    "out:EAHP (replacement cost)": 2204400,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 44.24,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 61.265983,
    "out:PV (m2 panels)": 321,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 57484.06,
    "out:PV (kWh sold)": 1810.97,
    "out:PV (% sold (El))": 3.15,
    "out:PV (kWh self-consumed)": 55673.09,
    "out:PV (ratio sold/self-consumed)": 0.03,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 750878,
    "out:EL kWh savings": -166456,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 402633,
    "out:DH kr savings": 683299,
    "out:El kr savings": -282975,
    "out:El kr sold": 2309,
    "out:El kr saved": 94644,
    "out:El kr return": 96953,
    "out:% solar/total": 68,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 131435,
    "out:% savings (space heating)": 80.04,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 18.63,
    "out:Etvv": 0,
    "out:Ef": 48.27,
    "out:heatPumpQuantity": 20,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000002010000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 21.65,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 61.95,
    "out:Electricity (inc PV)": 47.67,
    "out:Total Energy Use Pre PV": 83.95,
    "out:Total Energy Use Post PV": 69.67,
    "out:Primary Energy": 96.41,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 15.77,
    "out:CO2 Operational/m2": 12.99,
    "out:Total CO2/m2": 28.76,
    "out:Total CO2 (tons)": 147.9,
    "out:Klimatpaverkan": -181.34,
    "out:Initial Cost/MSEK": 8.32625,
    "out:Running cost/(Apt SEK y)": 17668,
    "out:Running Cost over RSP/MSEK": 5.777,
    "out:LCP/MSEK": 5.166,
    "out:ROI% old": 23.15,
    "out:Payback discounted": 5,
    "out:Atemp": 5143,
    "out:Umean": 0.82,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 70,
    "out:Energy use kWh/m2": 70,
    "out:Energy savings %": 168.6,
    "out:Op energy cost/MSEK": 40,
    "out:El price/MSEK": 33,
    "out:Heating price/MSEK": 6,
    "out:DH+ DHW/kWh/m2": 22,
    "out:El bought/kWh/m2": 3619.6,
    "out:Return %": 21,
    "out:Return/kSEK/y": 1722,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 2958000,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 147900,
    "out:EAHP (replacement cost)": 2204400,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 43.75,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 61.265983,
    "out:PV (m2 panels)": 321,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 57484.06,
    "out:PV (kWh sold)": 1913.21,
    "out:PV (% sold (El))": 3.33,
    "out:PV (kWh self-consumed)": 55570.85,
    "out:PV (ratio sold/self-consumed)": 0.03,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 771450,
    "out:EL kWh savings": -163409,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 128574.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 426663,
    "out:DH kr savings": 702019,
    "out:El kr savings": -277796,
    "out:El kr sold": 2439,
    "out:El kr saved": 94470,
    "out:El kr return": 96910,
    "out:% solar/total": 68,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 111322,
    "out:% savings (space heating)": 83.1,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 15.14,
    "out:Etvv": 0,
    "out:Ef": 47.67,
    "out:heatPumpQuantity": 20,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000002010001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 23,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 61.24,
    "out:Electricity (inc PV)": 47.56,
    "out:Total Energy Use Pre PV": 84.24,
    "out:Total Energy Use Post PV": 70.56,
    "out:Primary Energy": 97.74,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 16.17,
    "out:CO2 Operational/m2": 13.12,
    "out:Total CO2/m2": 29.29,
    "out:Total CO2 (tons)": 150.63,
    "out:Klimatpaverkan": -178.61,
    "out:Initial Cost/MSEK": 8.448125,
    "out:Running cost/(Apt SEK y)": 17844,
    "out:Running Cost over RSP/MSEK": 5.836,
    "out:LCP/MSEK": 4.985,
    "out:ROI% old": 22.72,
    "out:Payback discounted": 5,
    "out:Atemp": 5143,
    "out:Umean": 0.82,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 71,
    "out:Energy use kWh/m2": 71,
    "out:Energy savings %": 164.8,
    "out:Op energy cost/MSEK": 41,
    "out:El price/MSEK": 33,
    "out:Heating price/MSEK": 7,
    "out:DH+ DHW/kWh/m2": 23,
    "out:El bought/kWh/m2": 3571.6,
    "out:Return %": 20,
    "out:Return/kSEK/y": 1714,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 2958000,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 147900,
    "out:EAHP (replacement cost)": 2204400,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 43.04,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 61.265983,
    "out:PV (m2 panels)": 321,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 57484.06,
    "out:PV (kWh sold)": 2065.44,
    "out:PV (% sold (El))": 3.59,
    "out:PV (kWh self-consumed)": 55418.61,
    "out:PV (ratio sold/self-consumed)": 0.04,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 766307,
    "out:EL kWh savings": -162803,
    "out:Sewage heat recovery cost": 250448,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 423208,
    "out:DH kr savings": 697339,
    "out:El kr savings": -276764,
    "out:El kr sold": 2633,
    "out:El kr saved": 94212,
    "out:El kr return": 96845,
    "out:% solar/total": 68,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 118298,
    "out:% savings (space heating)": 82.04,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 17.35,
    "out:Etvv": 0,
    "out:Ef": 47.56,
    "out:heatPumpQuantity": 20,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000002010001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 19.23,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 60.7,
    "out:Electricity (inc PV)": 46.94,
    "out:Total Energy Use Pre PV": 79.7,
    "out:Total Energy Use Post PV": 65.94,
    "out:Primary Energy": 94.25,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 16.17,
    "out:CO2 Operational/m2": 11.5,
    "out:Total CO2/m2": 27.67,
    "out:Total CO2 (tons)": 142.29,
    "out:Klimatpaverkan": -186.95,
    "out:Initial Cost/MSEK": 8.57675,
    "out:Running cost/(Apt SEK y)": 16446,
    "out:Running Cost over RSP/MSEK": 5.376,
    "out:LCP/MSEK": 5.316,
    "out:ROI% old": 23.14,
    "out:Payback discounted": 5,
    "out:Atemp": 5143,
    "out:Umean": 0.82,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 66,
    "out:Energy use kWh/m2": 66,
    "out:Energy savings %": 184.8,
    "out:Op energy cost/MSEK": 37,
    "out:El price/MSEK": 31,
    "out:Heating price/MSEK": 5,
    "out:DH+ DHW/kWh/m2": 19,
    "out:El bought/kWh/m2": 3535.2,
    "out:Return %": 21,
    "out:Return/kSEK/y": 1773,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 2958000,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 147900,
    "out:EAHP (replacement cost)": 2204400,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 42.5,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 61.265983,
    "out:PV (m2 panels)": 321,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 57484.06,
    "out:PV (kWh sold)": 2186.27,
    "out:PV (% sold (El))": 3.8,
    "out:PV (kWh self-consumed)": 55297.79,
    "out:PV (ratio sold/self-consumed)": 0.04,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 786879,
    "out:EL kWh savings": -159620,
    "out:Sewage heat recovery cost": 250448,
    "out:Balancing hydronic system cost": 128574.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 447493,
    "out:DH kr savings": 716060,
    "out:El kr savings": -271355,
    "out:El kr sold": 2787,
    "out:El kr saved": 94006,
    "out:El kr return": 96794,
    "out:% solar/total": 68,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 98894,
    "out:% savings (space heating)": 84.98,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 13.95,
    "out:Etvv": 0,
    "out:Ef": 46.94,
    "out:heatPumpQuantity": 20,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000002020000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 25.56,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 62.44,
    "out:Electricity (inc PV)": 43.41,
    "out:Total Energy Use Pre PV": 88.44,
    "out:Total Energy Use Post PV": 69.41,
    "out:Primary Energy": 91.18,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 28.12,
    "out:CO2 Operational/m2": -15.79,
    "out:Total CO2/m2": 12.33,
    "out:Total CO2 (tons)": 63.41,
    "out:Klimatpaverkan": -265.83,
    "out:Initial Cost/MSEK": 9.2675,
    "out:Running cost/(Apt SEK y)": 17040,
    "out:Running Cost over RSP/MSEK": 5.587,
    "out:LCP/MSEK": 4.414,
    "out:ROI% old": 21.09,
    "out:Payback discounted": 6,
    "out:Atemp": 5143,
    "out:Umean": 0.82,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 69,
    "out:Energy use kWh/m2": 69,
    "out:Energy savings %": 172.5,
    "out:Op energy cost/MSEK": 40,
    "out:El price/MSEK": 31,
    "out:Heating price/MSEK": 7,
    "out:DH+ DHW/kWh/m2": 26,
    "out:El bought/kWh/m2": 3278.8,
    "out:Return %": 19,
    "out:Return/kSEK/y": 1748,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 2958000,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 147900,
    "out:EAHP (replacement cost)": 2204400,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 44.24,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.33,
    "out:PV (kWp installed)": 122.531965,
    "out:PV (m2 panels)": 643,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 114968.11,
    "out:PV (kWh sold)": 32340.77,
    "out:PV (% sold (El))": 28.13,
    "out:PV (kWh self-consumed)": 82627.34,
    "out:PV (ratio sold/self-consumed)": 0.39,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 750878,
    "out:EL kWh savings": -141488,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 484005,
    "out:DH kr savings": 683299,
    "out:El kr savings": -240529,
    "out:El kr sold": 41234,
    "out:El kr saved": 140466,
    "out:El kr return": 181701,
    "out:% solar/total": 101,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 131435,
    "out:% savings (space heating)": 80.04,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 18.63,
    "out:Etvv": 0,
    "out:Ef": 43.41,
    "out:heatPumpQuantity": 20,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000002020000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 21.65,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 61.95,
    "out:Electricity (inc PV)": 42.89,
    "out:Total Energy Use Pre PV": 83.95,
    "out:Total Energy Use Post PV": 64.89,
    "out:Primary Energy": 87.79,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 28.12,
    "out:CO2 Operational/m2": -17.67,
    "out:Total CO2/m2": 10.45,
    "out:Total CO2 (tons)": 53.75,
    "out:Klimatpaverkan": -275.49,
    "out:Initial Cost/MSEK": 9.396,
    "out:Running cost/(Apt SEK y)": 15653,
    "out:Running Cost over RSP/MSEK": 5.132,
    "out:LCP/MSEK": 4.741,
    "out:ROI% old": 21.49,
    "out:Payback discounted": 6,
    "out:Atemp": 5143,
    "out:Umean": 0.82,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 65,
    "out:Energy use kWh/m2": 65,
    "out:Energy savings %": 189.2,
    "out:Op energy cost/MSEK": 36,
    "out:El price/MSEK": 29,
    "out:Heating price/MSEK": 6,
    "out:DH+ DHW/kWh/m2": 22,
    "out:El bought/kWh/m2": 3248.9,
    "out:Return %": 19,
    "out:Return/kSEK/y": 1806,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 2958000,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 147900,
    "out:EAHP (replacement cost)": 2204400,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 43.75,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.33,
    "out:PV (kWp installed)": 122.531965,
    "out:PV (m2 panels)": 643,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 114968.11,
    "out:PV (kWh sold)": 32727.19,
    "out:PV (% sold (El))": 28.47,
    "out:PV (kWh self-consumed)": 82240.92,
    "out:PV (ratio sold/self-consumed)": 0.4,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 771450,
    "out:EL kWh savings": -138793,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 128574.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 507799,
    "out:DH kr savings": 702019,
    "out:El kr savings": -235947,
    "out:El kr sold": 41727,
    "out:El kr saved": 139810,
    "out:El kr return": 181537,
    "out:% solar/total": 101,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 111322,
    "out:% savings (space heating)": 83.1,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 15.14,
    "out:Etvv": 0,
    "out:Ef": 42.89,
    "out:heatPumpQuantity": 20,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000002020001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 23,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 61.24,
    "out:Electricity (inc PV)": 42.78,
    "out:Total Energy Use Pre PV": 84.24,
    "out:Total Energy Use Post PV": 65.78,
    "out:Primary Energy": 89.15,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 28.52,
    "out:CO2 Operational/m2": -17.94,
    "out:Total CO2/m2": 10.58,
    "out:Total CO2 (tons)": 54.42,
    "out:Klimatpaverkan": -274.82,
    "out:Initial Cost/MSEK": 9.517875,
    "out:Running cost/(Apt SEK y)": 15833,
    "out:Running Cost over RSP/MSEK": 5.192,
    "out:LCP/MSEK": 4.559,
    "out:ROI% old": 21.13,
    "out:Payback discounted": 6,
    "out:Atemp": 5143,
    "out:Umean": 0.82,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 66,
    "out:Energy use kWh/m2": 66,
    "out:Energy savings %": 184.8,
    "out:Op energy cost/MSEK": 37,
    "out:El price/MSEK": 29,
    "out:Heating price/MSEK": 7,
    "out:DH+ DHW/kWh/m2": 23,
    "out:El bought/kWh/m2": 3206.5,
    "out:Return %": 19,
    "out:Return/kSEK/y": 1799,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 2958000,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 147900,
    "out:EAHP (replacement cost)": 2204400,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 43.04,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.33,
    "out:PV (kWp installed)": 122.531965,
    "out:PV (m2 panels)": 643,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 114968.11,
    "out:PV (kWh sold)": 33281.2,
    "out:PV (% sold (El))": 28.95,
    "out:PV (kWh self-consumed)": 81686.91,
    "out:PV (ratio sold/self-consumed)": 0.41,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 766307,
    "out:EL kWh savings": -138256,
    "out:Sewage heat recovery cost": 250448,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 504738,
    "out:DH kr savings": 697339,
    "out:El kr savings": -235035,
    "out:El kr sold": 42434,
    "out:El kr saved": 138868,
    "out:El kr return": 181301,
    "out:% solar/total": 100,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 118298,
    "out:% savings (space heating)": 82.04,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 17.35,
    "out:Etvv": 0,
    "out:Ef": 42.78,
    "out:heatPumpQuantity": 20,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000002020001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 19.23,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 60.7,
    "out:Electricity (inc PV)": 42.23,
    "out:Total Energy Use Pre PV": 79.7,
    "out:Total Energy Use Post PV": 61.23,
    "out:Primary Energy": 85.79,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 28.52,
    "out:CO2 Operational/m2": -19.86,
    "out:Total CO2/m2": 8.66,
    "out:Total CO2 (tons)": 44.53,
    "out:Klimatpaverkan": -284.71,
    "out:Initial Cost/MSEK": 9.6465,
    "out:Running cost/(Apt SEK y)": 14438,
    "out:Running Cost over RSP/MSEK": 4.734,
    "out:LCP/MSEK": 4.889,
    "out:ROI% old": 21.53,
    "out:Payback discounted": 6,
    "out:Atemp": 5143,
    "out:Umean": 0.82,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 61,
    "out:Energy use kWh/m2": 61,
    "out:Energy savings %": 208.2,
    "out:Op energy cost/MSEK": 34,
    "out:El price/MSEK": 27,
    "out:Heating price/MSEK": 5,
    "out:DH+ DHW/kWh/m2": 19,
    "out:El bought/kWh/m2": 3174.2,
    "out:Return %": 19,
    "out:Return/kSEK/y": 1858,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 2958000,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 147900,
    "out:EAHP (replacement cost)": 2204400,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 42.5,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.33,
    "out:PV (kWp installed)": 122.531965,
    "out:PV (m2 panels)": 643,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 114968.11,
    "out:PV (kWh sold)": 33707.41,
    "out:PV (% sold (El))": 29.32,
    "out:PV (kWh self-consumed)": 81260.7,
    "out:PV (ratio sold/self-consumed)": 0.41,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 786879,
    "out:EL kWh savings": -135440,
    "out:Sewage heat recovery cost": 250448,
    "out:Balancing hydronic system cost": 128574.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 528789,
    "out:DH kr savings": 716060,
    "out:El kr savings": -230248,
    "out:El kr sold": 42977,
    "out:El kr saved": 138143,
    "out:El kr return": 181120,
    "out:% solar/total": 99,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 98894,
    "out:% savings (space heating)": 84.98,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 13.95,
    "out:Etvv": 0,
    "out:Ef": 42.23,
    "out:heatPumpQuantity": 20,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000002100000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 22.18,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 60.83,
    "out:Electricity (inc PV)": 57.94,
    "out:Total Energy Use Pre PV": 82.83,
    "out:Total Energy Use Post PV": 79.94,
    "out:Primary Energy": 116.13,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 3.41,
    "out:CO2 Operational/m2": 15.95,
    "out:Total CO2/m2": 19.36,
    "out:Total CO2 (tons)": 99.58,
    "out:Klimatpaverkan": -229.66,
    "out:Initial Cost/MSEK": 7.23875,
    "out:Running cost/(Apt SEK y)": 19743,
    "out:Running Cost over RSP/MSEK": 6.439,
    "out:LCP/MSEK": 5.592,
    "out:ROI% old": 25.32,
    "out:Payback discounted": 5,
    "out:Atemp": 5143,
    "out:Umean": 0.82,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 80,
    "out:Energy use kWh/m2": 80,
    "out:Energy savings %": 135,
    "out:Op energy cost/MSEK": 44,
    "out:El price/MSEK": 37,
    "out:Heating price/MSEK": 6,
    "out:DH+ DHW/kWh/m2": 22,
    "out:El bought/kWh/m2": 4258.1,
    "out:Return %": 23,
    "out:Return/kSEK/y": 1635,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 2958000,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 147900,
    "out:EAHP (replacement cost)": 2204400,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 42.63,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 771450,
    "out:EL kWh savings": -216187,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 334501,
    "out:DH kr savings": 702019,
    "out:El kr savings": -367518,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 114047,
    "out:% savings (space heating)": 82.68,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 16.93,
    "out:Etvv": 0,
    "out:Ef": 57.94,
    "out:heatPumpQuantity": 20,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000002100000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 18.45,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 60.28,
    "out:Electricity (inc PV)": 57.27,
    "out:Total Energy Use Pre PV": 78.28,
    "out:Total Energy Use Post PV": 75.27,
    "out:Primary Energy": 112.59,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 3.41,
    "out:CO2 Operational/m2": 14.44,
    "out:Total CO2/m2": 17.85,
    "out:Total CO2 (tons)": 91.79,
    "out:Klimatpaverkan": -237.45,
    "out:Initial Cost/MSEK": 7.367375,
    "out:Running cost/(Apt SEK y)": 18341,
    "out:Running Cost over RSP/MSEK": 5.978,
    "out:LCP/MSEK": 5.923,
    "out:ROI% old": 25.77,
    "out:Payback discounted": 5,
    "out:Atemp": 5143,
    "out:Umean": 0.82,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 75,
    "out:Energy use kWh/m2": 75,
    "out:Energy savings %": 150.7,
    "out:Op energy cost/MSEK": 41,
    "out:El price/MSEK": 35,
    "out:Heating price/MSEK": 5,
    "out:DH+ DHW/kWh/m2": 18,
    "out:El bought/kWh/m2": 4219.4,
    "out:Return %": 23,
    "out:Return/kSEK/y": 1694,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 2958000,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 147900,
    "out:EAHP (replacement cost)": 2204400,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 42.08,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 792022,
    "out:EL kWh savings": -212777,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 128574.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 359018,
    "out:DH kr savings": 720740,
    "out:El kr savings": -361722,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 94903,
    "out:% savings (space heating)": 85.59,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 13.57,
    "out:Etvv": 0,
    "out:Ef": 57.27,
    "out:heatPumpQuantity": 20,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000002100001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 20.25,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 59.83,
    "out:Electricity (inc PV)": 57.32,
    "out:Total Energy Use Pre PV": 79.83,
    "out:Total Energy Use Post PV": 77.32,
    "out:Primary Energy": 114.34,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 3.81,
    "out:CO2 Operational/m2": 15.1,
    "out:Total CO2/m2": 18.91,
    "out:Total CO2 (tons)": 97.26,
    "out:Klimatpaverkan": -231.98,
    "out:Initial Cost/MSEK": 7.48925,
    "out:Running cost/(Apt SEK y)": 18892,
    "out:Running Cost over RSP/MSEK": 6.16,
    "out:LCP/MSEK": 5.62,
    "out:ROI% old": 25.01,
    "out:Payback discounted": 5,
    "out:Atemp": 5143,
    "out:Umean": 0.82,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 77,
    "out:Energy use kWh/m2": 77,
    "out:Energy savings %": 144.2,
    "out:Op energy cost/MSEK": 42,
    "out:El price/MSEK": 36,
    "out:Heating price/MSEK": 6,
    "out:DH+ DHW/kWh/m2": 20,
    "out:El bought/kWh/m2": 4188.2,
    "out:Return %": 22,
    "out:Return/kSEK/y": 1670,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 2958000,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 147900,
    "out:EAHP (replacement cost)": 2204400,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 41.63,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 781736,
    "out:EL kWh savings": -213047,
    "out:Sewage heat recovery cost": 250448,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 349199,
    "out:DH kr savings": 711380,
    "out:El kr savings": -362181,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 104145,
    "out:% savings (space heating)": 84.19,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 15.94,
    "out:Etvv": 0,
    "out:Ef": 57.32,
    "out:heatPumpQuantity": 20,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000002100001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 16.65,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 59.24,
    "out:Electricity (inc PV)": 56.63,
    "out:Total Energy Use Pre PV": 76.24,
    "out:Total Energy Use Post PV": 73.63,
    "out:Primary Energy": 110.81,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 3.81,
    "out:CO2 Operational/m2": 13.94,
    "out:Total CO2/m2": 17.75,
    "out:Total CO2 (tons)": 91.31,
    "out:Klimatpaverkan": -237.93,
    "out:Initial Cost/MSEK": 7.61775,
    "out:Running cost/(Apt SEK y)": 17803,
    "out:Running Cost over RSP/MSEK": 5.802,
    "out:LCP/MSEK": 5.849,
    "out:ROI% old": 25.25,
    "out:Payback discounted": 5,
    "out:Atemp": 5143,
    "out:Umean": 0.82,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 74,
    "out:Energy use kWh/m2": 74,
    "out:Energy savings %": 154.1,
    "out:Op energy cost/MSEK": 40,
    "out:El price/MSEK": 34,
    "out:Heating price/MSEK": 5,
    "out:DH+ DHW/kWh/m2": 17,
    "out:El bought/kWh/m2": 4146.9,
    "out:Return %": 23,
    "out:Return/kSEK/y": 1716,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 2958000,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 147900,
    "out:EAHP (replacement cost)": 2204400,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 41.04,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 797165,
    "out:EL kWh savings": -209498,
    "out:Sewage heat recovery cost": 250448,
    "out:Balancing hydronic system cost": 128574.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 369273,
    "out:DH kr savings": 725420,
    "out:El kr savings": -356147,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 85634,
    "out:% savings (space heating)": 87,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 12.67,
    "out:Etvv": 0,
    "out:Ef": 56.63,
    "out:heatPumpQuantity": 20,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000002110000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 22.18,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 60.83,
    "out:Electricity (inc PV)": 47.32,
    "out:Total Energy Use Pre PV": 82.83,
    "out:Total Energy Use Post PV": 69.32,
    "out:Primary Energy": 97.02,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 15.77,
    "out:CO2 Operational/m2": 12.62,
    "out:Total CO2/m2": 28.39,
    "out:Total CO2 (tons)": 146,
    "out:Klimatpaverkan": -183.24,
    "out:Initial Cost/MSEK": 8.3085,
    "out:Running cost/(Apt SEK y)": 17438,
    "out:Running Cost over RSP/MSEK": 5.702,
    "out:LCP/MSEK": 5.258,
    "out:ROI% old": 23.33,
    "out:Payback discounted": 5,
    "out:Atemp": 5143,
    "out:Umean": 0.82,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 69,
    "out:Energy use kWh/m2": 69,
    "out:Energy savings %": 172.5,
    "out:Op energy cost/MSEK": 40,
    "out:El price/MSEK": 33,
    "out:Heating price/MSEK": 6,
    "out:DH+ DHW/kWh/m2": 22,
    "out:El bought/kWh/m2": 3543.9,
    "out:Return %": 21,
    "out:Return/kSEK/y": 1732,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 2958000,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 147900,
    "out:EAHP (replacement cost)": 2204400,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 42.63,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 61.265983,
    "out:PV (m2 panels)": 321,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 57484.06,
    "out:PV (kWh sold)": 2156.82,
    "out:PV (% sold (El))": 3.75,
    "out:PV (kWh self-consumed)": 55327.23,
    "out:PV (ratio sold/self-consumed)": 0.04,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 771450,
    "out:EL kWh savings": -161573,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 430095,
    "out:DH kr savings": 702019,
    "out:El kr savings": -274674,
    "out:El kr sold": 2750,
    "out:El kr saved": 94056,
    "out:El kr return": 96806,
    "out:% solar/total": 68,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 114047,
    "out:% savings (space heating)": 82.68,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 16.93,
    "out:Etvv": 0,
    "out:Ef": 47.32,
    "out:heatPumpQuantity": 20,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000002110000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 18.45,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 60.28,
    "out:Electricity (inc PV)": 46.69,
    "out:Total Energy Use Pre PV": 78.28,
    "out:Total Energy Use Post PV": 64.69,
    "out:Primary Energy": 93.53,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 15.77,
    "out:CO2 Operational/m2": 10.99,
    "out:Total CO2/m2": 26.76,
    "out:Total CO2 (tons)": 137.62,
    "out:Klimatpaverkan": -191.62,
    "out:Initial Cost/MSEK": 8.437125,
    "out:Running cost/(Apt SEK y)": 16037,
    "out:Running Cost over RSP/MSEK": 5.242,
    "out:LCP/MSEK": 5.59,
    "out:ROI% old": 23.75,
    "out:Payback discounted": 5,
    "out:Atemp": 5143,
    "out:Umean": 0.82,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 65,
    "out:Energy use kWh/m2": 65,
    "out:Energy savings %": 189.2,
    "out:Op energy cost/MSEK": 37,
    "out:El price/MSEK": 30,
    "out:Heating price/MSEK": 5,
    "out:DH+ DHW/kWh/m2": 18,
    "out:El bought/kWh/m2": 3506.5,
    "out:Return %": 21,
    "out:Return/kSEK/y": 1790,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 2958000,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 147900,
    "out:EAHP (replacement cost)": 2204400,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 42.08,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 61.265983,
    "out:PV (m2 panels)": 321,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 57484.06,
    "out:PV (kWh sold)": 2285.01,
    "out:PV (% sold (El))": 3.98,
    "out:PV (kWh self-consumed)": 55199.05,
    "out:PV (ratio sold/self-consumed)": 0.04,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 792022,
    "out:EL kWh savings": -158342,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 128574.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 454472,
    "out:DH kr savings": 720740,
    "out:El kr savings": -269181,
    "out:El kr sold": 2913,
    "out:El kr saved": 93838,
    "out:El kr return": 96752,
    "out:% solar/total": 68,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 94903,
    "out:% savings (space heating)": 85.59,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 13.57,
    "out:Etvv": 0,
    "out:Ef": 46.69,
    "out:heatPumpQuantity": 20,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000002110001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 20.25,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 59.83,
    "out:Electricity (inc PV)": 46.74,
    "out:Total Energy Use Pre PV": 79.83,
    "out:Total Energy Use Post PV": 66.74,
    "out:Primary Energy": 95.29,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 16.17,
    "out:CO2 Operational/m2": 11.56,
    "out:Total CO2/m2": 27.72,
    "out:Total CO2 (tons)": 142.58,
    "out:Klimatpaverkan": -186.66,
    "out:Initial Cost/MSEK": 8.559,
    "out:Running cost/(Apt SEK y)": 16589,
    "out:Running Cost over RSP/MSEK": 5.424,
    "out:LCP/MSEK": 5.286,
    "out:ROI% old": 23.11,
    "out:Payback discounted": 5,
    "out:Atemp": 5143,
    "out:Umean": 0.82,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 67,
    "out:Energy use kWh/m2": 67,
    "out:Energy savings %": 180.6,
    "out:Op energy cost/MSEK": 38,
    "out:El price/MSEK": 31,
    "out:Heating price/MSEK": 6,
    "out:DH+ DHW/kWh/m2": 20,
    "out:El bought/kWh/m2": 3476.4,
    "out:Return %": 21,
    "out:Return/kSEK/y": 1767,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 2958000,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 147900,
    "out:EAHP (replacement cost)": 2204400,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 41.63,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 61.265983,
    "out:PV (m2 panels)": 321,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 57484.06,
    "out:PV (kWh sold)": 2391.04,
    "out:PV (% sold (El))": 4.16,
    "out:PV (kWh self-consumed)": 55093.02,
    "out:PV (ratio sold/self-consumed)": 0.04,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 781736,
    "out:EL kWh savings": -158598,
    "out:Sewage heat recovery cost": 250448,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 444812,
    "out:DH kr savings": 711380,
    "out:El kr savings": -269616,
    "out:El kr sold": 3049,
    "out:El kr saved": 93658,
    "out:El kr return": 96707,
    "out:% solar/total": 67,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 104145,
    "out:% savings (space heating)": 84.19,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 15.94,
    "out:Etvv": 0,
    "out:Ef": 46.74,
    "out:heatPumpQuantity": 20,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000002110001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 16.65,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 59.24,
    "out:Electricity (inc PV)": 46.08,
    "out:Total Energy Use Pre PV": 76.24,
    "out:Total Energy Use Post PV": 63.08,
    "out:Primary Energy": 91.82,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 16.17,
    "out:CO2 Operational/m2": 10.26,
    "out:Total CO2/m2": 26.43,
    "out:Total CO2 (tons)": 135.94,
    "out:Klimatpaverkan": -193.3,
    "out:Initial Cost/MSEK": 8.687625,
    "out:Running cost/(Apt SEK y)": 15502,
    "out:Running Cost over RSP/MSEK": 5.067,
    "out:LCP/MSEK": 5.514,
    "out:ROI% old": 23.35,
    "out:Payback discounted": 5,
    "out:Atemp": 5143,
    "out:Umean": 0.82,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 63,
    "out:Energy use kWh/m2": 63,
    "out:Energy savings %": 198.4,
    "out:Op energy cost/MSEK": 35,
    "out:El price/MSEK": 30,
    "out:Heating price/MSEK": 5,
    "out:DH+ DHW/kWh/m2": 17,
    "out:El bought/kWh/m2": 3436.6,
    "out:Return %": 21,
    "out:Return/kSEK/y": 1813,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 2958000,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 147900,
    "out:EAHP (replacement cost)": 2204400,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 41.04,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 61.265983,
    "out:PV (m2 panels)": 321,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 57484.06,
    "out:PV (kWh sold)": 2535.96,
    "out:PV (% sold (El))": 4.41,
    "out:PV (kWh self-consumed)": 54948.1,
    "out:PV (ratio sold/self-consumed)": 0.05,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 797165,
    "out:EL kWh savings": -155240,
    "out:Sewage heat recovery cost": 250448,
    "out:Balancing hydronic system cost": 128574.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 464745,
    "out:DH kr savings": 725420,
    "out:El kr savings": -263909,
    "out:El kr sold": 3233,
    "out:El kr saved": 93412,
    "out:El kr return": 96645,
    "out:% solar/total": 67,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 85634,
    "out:% savings (space heating)": 87,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 12.67,
    "out:Etvv": 0,
    "out:Ef": 46.08,
    "out:heatPumpQuantity": 20,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000002120000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 22.18,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 60.83,
    "out:Electricity (inc PV)": 42.57,
    "out:Total Energy Use Pre PV": 82.83,
    "out:Total Energy Use Post PV": 64.57,
    "out:Primary Energy": 88.48,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 28.12,
    "out:CO2 Operational/m2": -18.67,
    "out:Total CO2/m2": 9.45,
    "out:Total CO2 (tons)": 48.62,
    "out:Klimatpaverkan": -280.62,
    "out:Initial Cost/MSEK": 9.378375,
    "out:Running cost/(Apt SEK y)": 15430,
    "out:Running Cost over RSP/MSEK": 5.06,
    "out:LCP/MSEK": 4.831,
    "out:ROI% old": 21.64,
    "out:Payback discounted": 6,
    "out:Atemp": 5143,
    "out:Umean": 0.82,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 65,
    "out:Energy use kWh/m2": 65,
    "out:Energy savings %": 189.2,
    "out:Op energy cost/MSEK": 36,
    "out:El price/MSEK": 29,
    "out:Heating price/MSEK": 6,
    "out:DH+ DHW/kWh/m2": 22,
    "out:El bought/kWh/m2": 3182,
    "out:Return %": 19,
    "out:Return/kSEK/y": 1816,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 2958000,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 147900,
    "out:EAHP (replacement cost)": 2204400,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 42.63,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.33,
    "out:PV (kWp installed)": 122.531965,
    "out:PV (m2 panels)": 643,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 114968.11,
    "out:PV (kWh sold)": 33604.7,
    "out:PV (% sold (El))": 29.23,
    "out:PV (kWh self-consumed)": 81363.41,
    "out:PV (ratio sold/self-consumed)": 0.41,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 771450,
    "out:EL kWh savings": -137168,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 511680,
    "out:DH kr savings": 702019,
    "out:El kr savings": -233186,
    "out:El kr sold": 42846,
    "out:El kr saved": 138318,
    "out:El kr return": 181164,
    "out:% solar/total": 99,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 114047,
    "out:% savings (space heating)": 82.68,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 16.93,
    "out:Etvv": 0,
    "out:Ef": 42.57,
    "out:heatPumpQuantity": 20,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000002120000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 18.45,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 60.28,
    "out:Electricity (inc PV)": 42.01,
    "out:Total Energy Use Pre PV": 78.28,
    "out:Total Energy Use Post PV": 60.01,
    "out:Primary Energy": 85.12,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 28.12,
    "out:CO2 Operational/m2": -20.61,
    "out:Total CO2/m2": 7.51,
    "out:Total CO2 (tons)": 38.64,
    "out:Klimatpaverkan": -290.6,
    "out:Initial Cost/MSEK": 9.506875,
    "out:Running cost/(Apt SEK y)": 14032,
    "out:Running Cost over RSP/MSEK": 4.6,
    "out:LCP/MSEK": 5.162,
    "out:ROI% old": 22.04,
    "out:Payback discounted": 6,
    "out:Atemp": 5143,
    "out:Umean": 0.82,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 60,
    "out:Energy use kWh/m2": 60,
    "out:Energy savings %": 213.3,
    "out:Op energy cost/MSEK": 33,
    "out:El price/MSEK": 27,
    "out:Heating price/MSEK": 5,
    "out:DH+ DHW/kWh/m2": 18,
    "out:El bought/kWh/m2": 3148.8,
    "out:Return %": 20,
    "out:Return/kSEK/y": 1875,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 2958000,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 147900,
    "out:EAHP (replacement cost)": 2204400,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 42.08,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.33,
    "out:PV (kWp installed)": 122.531965,
    "out:PV (m2 panels)": 643,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 114968.11,
    "out:PV (kWh sold)": 34045.12,
    "out:PV (% sold (El))": 29.61,
    "out:PV (kWh self-consumed)": 80922.99,
    "out:PV (ratio sold/self-consumed)": 0.42,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 792022,
    "out:EL kWh savings": -134308,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 128574.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 535824,
    "out:DH kr savings": 720740,
    "out:El kr savings": -228324,
    "out:El kr sold": 43408,
    "out:El kr saved": 137569,
    "out:El kr return": 180977,
    "out:% solar/total": 99,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 94903,
    "out:% savings (space heating)": 85.59,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 13.57,
    "out:Etvv": 0,
    "out:Ef": 42.01,
    "out:heatPumpQuantity": 20,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000002120001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 20.25,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 59.83,
    "out:Electricity (inc PV)": 42.06,
    "out:Total Energy Use Pre PV": 79.83,
    "out:Total Energy Use Post PV": 62.06,
    "out:Primary Energy": 86.86,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 28.52,
    "out:CO2 Operational/m2": -20.29,
    "out:Total CO2/m2": 8.23,
    "out:Total CO2 (tons)": 42.33,
    "out:Klimatpaverkan": -286.91,
    "out:Initial Cost/MSEK": 9.62875,
    "out:Running cost/(Apt SEK y)": 14586,
    "out:Running Cost over RSP/MSEK": 4.783,
    "out:LCP/MSEK": 4.857,
    "out:ROI% old": 21.49,
    "out:Payback discounted": 6,
    "out:Atemp": 5143,
    "out:Umean": 0.82,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 62,
    "out:Energy use kWh/m2": 62,
    "out:Energy savings %": 203.2,
    "out:Op energy cost/MSEK": 34,
    "out:El price/MSEK": 27,
    "out:Heating price/MSEK": 6,
    "out:DH+ DHW/kWh/m2": 20,
    "out:El bought/kWh/m2": 3122.2,
    "out:Return %": 19,
    "out:Return/kSEK/y": 1851,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 2958000,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 147900,
    "out:EAHP (replacement cost)": 2204400,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 41.63,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.33,
    "out:PV (kWp installed)": 122.531965,
    "out:PV (m2 panels)": 643,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 114968.11,
    "out:PV (kWh sold)": 34401.05,
    "out:PV (% sold (El))": 29.92,
    "out:PV (kWh self-consumed)": 80567.06,
    "out:PV (ratio sold/self-consumed)": 0.43,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 781736,
    "out:EL kWh savings": -134534,
    "out:Sewage heat recovery cost": 250448,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 526533,
    "out:DH kr savings": 711380,
    "out:El kr savings": -228708,
    "out:El kr sold": 43861,
    "out:El kr saved": 136964,
    "out:El kr return": 180825,
    "out:% solar/total": 99,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 104145,
    "out:% savings (space heating)": 84.19,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 15.94,
    "out:Etvv": 0,
    "out:Ef": 42.06,
    "out:heatPumpQuantity": 20,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000002120001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 16.65,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 59.24,
    "out:Electricity (inc PV)": 41.48,
    "out:Total Energy Use Pre PV": 76.24,
    "out:Total Energy Use Post PV": 58.48,
    "out:Primary Energy": 83.53,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 28.52,
    "out:CO2 Operational/m2": -21.91,
    "out:Total CO2/m2": 6.61,
    "out:Total CO2 (tons)": 34,
    "out:Klimatpaverkan": -295.24,
    "out:Initial Cost/MSEK": 9.757375,
    "out:Running cost/(Apt SEK y)": 13503,
    "out:Running Cost over RSP/MSEK": 4.427,
    "out:LCP/MSEK": 5.084,
    "out:ROI% old": 21.73,
    "out:Payback discounted": 6,
    "out:Atemp": 5143,
    "out:Umean": 0.82,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 58,
    "out:Energy use kWh/m2": 58,
    "out:Energy savings %": 224.1,
    "out:Op energy cost/MSEK": 31,
    "out:El price/MSEK": 26,
    "out:Heating price/MSEK": 5,
    "out:DH+ DHW/kWh/m2": 17,
    "out:El bought/kWh/m2": 3087,
    "out:Return %": 19,
    "out:Return/kSEK/y": 1897,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 2958000,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 147900,
    "out:EAHP (replacement cost)": 2204400,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 41.04,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.33,
    "out:PV (kWp installed)": 122.531965,
    "out:PV (m2 panels)": 643,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 114968.11,
    "out:PV (kWh sold)": 34878.11,
    "out:PV (% sold (El))": 30.34,
    "out:PV (kWh self-consumed)": 80090,
    "out:PV (ratio sold/self-consumed)": 0.44,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 797165,
    "out:EL kWh savings": -131560,
    "out:Sewage heat recovery cost": 250448,
    "out:Balancing hydronic system cost": 128574.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 546238,
    "out:DH kr savings": 725420,
    "out:El kr savings": -223652,
    "out:El kr sold": 44470,
    "out:El kr saved": 136153,
    "out:El kr return": 180623,
    "out:% solar/total": 98,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 85634,
    "out:% savings (space heating)": 87,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 12.67,
    "out:Etvv": 0,
    "out:Ef": 41.48,
    "out:heatPumpQuantity": 20,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000100000000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 117.08,
    "out:DHW (DH)": 43.5,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 15.9,
    "out:Total Energy Use Pre PV": 176.9,
    "out:Total Energy Use Post PV": 176.9,
    "out:Primary Energy": 128.08,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 2.02,
    "out:CO2 Operational/m2": 60.06,
    "out:Total CO2/m2": 62.08,
    "out:Total CO2 (tons)": 319.25,
    "out:Klimatpaverkan": -9.99,
    "out:Initial Cost/MSEK": 0.821504,
    "out:Running cost/(Apt SEK y)": 55124,
    "out:Running Cost over RSP/MSEK": 18.105,
    "out:LCP/MSEK": 0.342,
    "out:ROI% old": 20.23,
    "out:Payback discounted": 6,
    "out:Atemp": 5143,
    "out:Umean": 0.67,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "F",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 177,
    "out:Energy use kWh/m2": 177,
    "out:Energy savings %": 6.2,
    "out:Op energy cost/MSEK": 133,
    "out:El price/MSEK": 79,
    "out:Heating price/MSEK": 46,
    "out:DH+ DHW/kWh/m2": 161,
    "out:El bought/kWh/m2": 1113,
    "out:Return %": 18,
    "out:Return/kSEK/y": 149,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 56573,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 51481,
    "out:DH kr savings": 51481,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 602138,
    "out:% savings (space heating)": 8.57,
    "out:Total_Water Heating (DH)": 223720,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 117.08,
    "out:Etvv": 25,
    "out:Ef": 15.9,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z00000100000000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 112.03,
    "out:DHW (DH)": 43.5,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 15.9,
    "out:Total Energy Use Pre PV": 171.9,
    "out:Total Energy Use Post PV": 171.9,
    "out:Primary Energy": 124.54,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 2.02,
    "out:CO2 Operational/m2": 58.26,
    "out:Total CO2/m2": 60.28,
    "out:Total CO2 (tons)": 310,
    "out:Klimatpaverkan": -19.24,
    "out:Initial Cost/MSEK": 0.950079,
    "out:Running cost/(Apt SEK y)": 53515,
    "out:Running Cost over RSP/MSEK": 17.577,
    "out:LCP/MSEK": 0.742,
    "out:ROI% old": 25.45,
    "out:Payback discounted": 5,
    "out:Atemp": 5143,
    "out:Umean": 0.67,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "F",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 172,
    "out:Energy use kWh/m2": 172,
    "out:Energy savings %": 9.3,
    "out:Op energy cost/MSEK": 129,
    "out:El price/MSEK": 77,
    "out:Heating price/MSEK": 45,
    "out:DH+ DHW/kWh/m2": 156,
    "out:El bought/kWh/m2": 1113,
    "out:Return %": 23,
    "out:Return/kSEK/y": 216,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 8,
    "out:Payback Time": 12.5,
    "out:DH kWh savings": 82288,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 128574.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 74882,
    "out:DH kr savings": 74882,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 576171,
    "out:% savings (space heating)": 12.51,
    "out:Total_Water Heating (DH)": 223720,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 112.03,
    "out:Etvv": 25,
    "out:Ef": 15.9,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z00000100000001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 117.08,
    "out:DHW (DH)": 36.98,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 15.9,
    "out:Total Energy Use Pre PV": 169.9,
    "out:Total Energy Use Post PV": 169.9,
    "out:Primary Energy": 125.45,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 2.42,
    "out:CO2 Operational/m2": 57.54,
    "out:Total CO2/m2": 59.96,
    "out:Total CO2 (tons)": 308.36,
    "out:Klimatpaverkan": -20.88,
    "out:Initial Cost/MSEK": 1.071952,
    "out:Running cost/(Apt SEK y)": 52872,
    "out:Running Cost over RSP/MSEK": 17.365,
    "out:LCP/MSEK": 0.832,
    "out:ROI% old": 25.37,
    "out:Payback discounted": 5,
    "out:Atemp": 5143,
    "out:Umean": 0.67,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "F",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 170,
    "out:Energy use kWh/m2": 170,
    "out:Energy savings %": 10.6,
    "out:Op energy cost/MSEK": 127,
    "out:El price/MSEK": 76,
    "out:Heating price/MSEK": 44,
    "out:DH+ DHW/kWh/m2": 154,
    "out:El bought/kWh/m2": 1113,
    "out:Return %": 23,
    "out:Return/kSEK/y": 243,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 8,
    "out:Payback Time": 12.5,
    "out:DH kWh savings": 92574,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 250448,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 84242,
    "out:DH kr savings": 84242,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 602138,
    "out:% savings (space heating)": 8.57,
    "out:Total_Water Heating (DH)": 190162,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 117.08,
    "out:Etvv": 21.25,
    "out:Ef": 15.9,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z00000100000001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 112.03,
    "out:DHW (DH)": 36.98,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 15.9,
    "out:Total Energy Use Pre PV": 164.9,
    "out:Total Energy Use Post PV": 164.9,
    "out:Primary Energy": 121.92,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 2.42,
    "out:CO2 Operational/m2": 55.74,
    "out:Total CO2/m2": 58.16,
    "out:Total CO2 (tons)": 299.1,
    "out:Klimatpaverkan": -30.14,
    "out:Initial Cost/MSEK": 1.200527,
    "out:Running cost/(Apt SEK y)": 51262,
    "out:Running Cost over RSP/MSEK": 16.836,
    "out:LCP/MSEK": 1.232,
    "out:ROI% old": 28.95,
    "out:Payback discounted": 4,
    "out:Atemp": 5143,
    "out:Umean": 0.67,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "F",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 165,
    "out:Energy use kWh/m2": 165,
    "out:Energy savings %": 13.9,
    "out:Op energy cost/MSEK": 123,
    "out:El price/MSEK": 74,
    "out:Heating price/MSEK": 43,
    "out:DH+ DHW/kWh/m2": 149,
    "out:El bought/kWh/m2": 1113,
    "out:Return %": 26,
    "out:Return/kSEK/y": 311,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 9,
    "out:Payback Time": 11.111111,
    "out:DH kWh savings": 118289,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 250448,
    "out:Balancing hydronic system cost": 128574.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 107643,
    "out:DH kr savings": 107643,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 576171,
    "out:% savings (space heating)": 12.51,
    "out:Total_Water Heating (DH)": 190162,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 112.03,
    "out:Etvv": 21.25,
    "out:Ef": 15.9,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z00000100010000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 117.08,
    "out:DHW (DH)": 43.5,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 10.73,
    "out:Total Energy Use Pre PV": 176.9,
    "out:Total Energy Use Post PV": 171.73,
    "out:Primary Energy": 118.77,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 14.37,
    "out:CO2 Operational/m2": 29.06,
    "out:Total CO2/m2": 43.43,
    "out:Total CO2 (tons)": 223.38,
    "out:Klimatpaverkan": -105.86,
    "out:Initial Cost/MSEK": 1.89125,
    "out:Running cost/(Apt SEK y)": 53110,
    "out:Running Cost over RSP/MSEK": 17.462,
    "out:LCP/MSEK": -0.084,
    "out:ROI% old": 13.65,
    "out:Payback discounted": 9,
    "out:Atemp": 5143,
    "out:Umean": 0.67,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "F",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 172,
    "out:Energy use kWh/m2": 172,
    "out:Energy savings %": 9.3,
    "out:Op energy cost/MSEK": 129,
    "out:El price/MSEK": 75,
    "out:Heating price/MSEK": 46,
    "out:DH+ DHW/kWh/m2": 161,
    "out:El bought/kWh/m2": 758.1,
    "out:Return %": 12,
    "out:Return/kSEK/y": 233,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 61.265983,
    "out:PV (m2 panels)": 321,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 57484.06,
    "out:PV (kWh sold)": 30886.05,
    "out:PV (% sold (El))": 53.73,
    "out:PV (kWh self-consumed)": 26598,
    "out:PV (ratio sold/self-consumed)": 1.16,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 7,
    "out:Payback Time": 14.285714,
    "out:DH kWh savings": 56573,
    "out:EL kWh savings": 26598,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 136078,
    "out:DH kr savings": 51481,
    "out:El kr savings": 45217,
    "out:El kr sold": 39380,
    "out:El kr saved": 45217,
    "out:El kr return": 84596,
    "out:% solar/total": 33,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 602138,
    "out:% savings (space heating)": 8.57,
    "out:Total_Water Heating (DH)": 223720,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 117.08,
    "out:Etvv": 25,
    "out:Ef": 10.73,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z00000100010000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 112.03,
    "out:DHW (DH)": 43.5,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 10.73,
    "out:Total Energy Use Pre PV": 171.9,
    "out:Total Energy Use Post PV": 166.73,
    "out:Primary Energy": 115.23,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 14.37,
    "out:CO2 Operational/m2": 27.26,
    "out:Total CO2/m2": 41.63,
    "out:Total CO2 (tons)": 214.12,
    "out:Klimatpaverkan": -115.12,
    "out:Initial Cost/MSEK": 2.019875,
    "out:Running cost/(Apt SEK y)": 51501,
    "out:Running Cost over RSP/MSEK": 16.933,
    "out:LCP/MSEK": 0.316,
    "out:ROI% old": 16.52,
    "out:Payback discounted": 7,
    "out:Atemp": 5143,
    "out:Umean": 0.67,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "F",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 167,
    "out:Energy use kWh/m2": 167,
    "out:Energy savings %": 12.6,
    "out:Op energy cost/MSEK": 125,
    "out:El price/MSEK": 73,
    "out:Heating price/MSEK": 45,
    "out:DH+ DHW/kWh/m2": 156,
    "out:El bought/kWh/m2": 758.1,
    "out:Return %": 15,
    "out:Return/kSEK/y": 301,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 61.265983,
    "out:PV (m2 panels)": 321,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 57484.06,
    "out:PV (kWh sold)": 30886.05,
    "out:PV (% sold (El))": 53.73,
    "out:PV (kWh self-consumed)": 26598,
    "out:PV (ratio sold/self-consumed)": 1.16,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 8,
    "out:Payback Time": 12.5,
    "out:DH kWh savings": 82288,
    "out:EL kWh savings": 26598,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 128574.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 159478,
    "out:DH kr savings": 74882,
    "out:El kr savings": 45217,
    "out:El kr sold": 39380,
    "out:El kr saved": 45217,
    "out:El kr return": 84596,
    "out:% solar/total": 33,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 576171,
    "out:% savings (space heating)": 12.51,
    "out:Total_Water Heating (DH)": 223720,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 112.03,
    "out:Etvv": 25,
    "out:Ef": 10.73,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z00000100010001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 117.08,
    "out:DHW (DH)": 36.98,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 10.73,
    "out:Total Energy Use Pre PV": 169.9,
    "out:Total Energy Use Post PV": 164.73,
    "out:Primary Energy": 116.14,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 14.77,
    "out:CO2 Operational/m2": 26.54,
    "out:Total CO2/m2": 41.32,
    "out:Total CO2 (tons)": 212.49,
    "out:Klimatpaverkan": -116.75,
    "out:Initial Cost/MSEK": 2.14175,
    "out:Running cost/(Apt SEK y)": 50857,
    "out:Running Cost over RSP/MSEK": 16.721,
    "out:LCP/MSEK": 0.406,
    "out:ROI% old": 16.99,
    "out:Payback discounted": 7,
    "out:Atemp": 5143,
    "out:Umean": 0.67,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "F",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 165,
    "out:Energy use kWh/m2": 165,
    "out:Energy savings %": 13.9,
    "out:Op energy cost/MSEK": 123,
    "out:El price/MSEK": 72,
    "out:Heating price/MSEK": 44,
    "out:DH+ DHW/kWh/m2": 154,
    "out:El bought/kWh/m2": 758.1,
    "out:Return %": 15,
    "out:Return/kSEK/y": 328,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 61.265983,
    "out:PV (m2 panels)": 321,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 57484.06,
    "out:PV (kWh sold)": 30886.05,
    "out:PV (% sold (El))": 53.73,
    "out:PV (kWh self-consumed)": 26598,
    "out:PV (ratio sold/self-consumed)": 1.16,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 8,
    "out:Payback Time": 12.5,
    "out:DH kWh savings": 92574,
    "out:EL kWh savings": 26598,
    "out:Sewage heat recovery cost": 250448,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 168839,
    "out:DH kr savings": 84242,
    "out:El kr savings": 45217,
    "out:El kr sold": 39380,
    "out:El kr saved": 45217,
    "out:El kr return": 84596,
    "out:% solar/total": 33,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 602138,
    "out:% savings (space heating)": 8.57,
    "out:Total_Water Heating (DH)": 190162,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 117.08,
    "out:Etvv": 21.25,
    "out:Ef": 10.73,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z00000100010001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 112.03,
    "out:DHW (DH)": 36.98,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 10.73,
    "out:Total Energy Use Pre PV": 164.9,
    "out:Total Energy Use Post PV": 159.73,
    "out:Primary Energy": 112.61,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 14.77,
    "out:CO2 Operational/m2": 24.74,
    "out:Total CO2/m2": 39.52,
    "out:Total CO2 (tons)": 203.23,
    "out:Klimatpaverkan": -126.01,
    "out:Initial Cost/MSEK": 2.270375,
    "out:Running cost/(Apt SEK y)": 49248,
    "out:Running Cost over RSP/MSEK": 16.192,
    "out:LCP/MSEK": 0.806,
    "out:ROI% old": 19.36,
    "out:Payback discounted": 6,
    "out:Atemp": 5143,
    "out:Umean": 0.67,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "F",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 160,
    "out:Energy use kWh/m2": 160,
    "out:Energy savings %": 17.5,
    "out:Op energy cost/MSEK": 120,
    "out:El price/MSEK": 70,
    "out:Heating price/MSEK": 43,
    "out:DH+ DHW/kWh/m2": 149,
    "out:El bought/kWh/m2": 758.1,
    "out:Return %": 17,
    "out:Return/kSEK/y": 395,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 61.265983,
    "out:PV (m2 panels)": 321,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 57484.06,
    "out:PV (kWh sold)": 30886.05,
    "out:PV (% sold (El))": 53.73,
    "out:PV (kWh self-consumed)": 26598,
    "out:PV (ratio sold/self-consumed)": 1.16,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 8,
    "out:Payback Time": 12.5,
    "out:DH kWh savings": 118289,
    "out:EL kWh savings": 26598,
    "out:Sewage heat recovery cost": 250448,
    "out:Balancing hydronic system cost": 128574.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 192239,
    "out:DH kr savings": 107643,
    "out:El kr savings": 45217,
    "out:El kr sold": 39380,
    "out:El kr saved": 45217,
    "out:El kr return": 84596,
    "out:% solar/total": 33,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 576171,
    "out:% savings (space heating)": 12.51,
    "out:Total_Water Heating (DH)": 190162,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 112.03,
    "out:Etvv": 21.25,
    "out:Ef": 10.73,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z00000100020000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 117.08,
    "out:DHW (DH)": 43.5,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 9.88,
    "out:Total Energy Use Pre PV": 176.9,
    "out:Total Energy Use Post PV": 170.88,
    "out:Primary Energy": 117.24,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 26.73,
    "out:CO2 Operational/m2": -23.62,
    "out:Total CO2/m2": 3.11,
    "out:Total CO2 (tons)": 15.98,
    "out:Klimatpaverkan": -313.26,
    "out:Initial Cost/MSEK": 2.961125,
    "out:Running cost/(Apt SEK y)": 51321,
    "out:Running Cost over RSP/MSEK": 16.89,
    "out:LCP/MSEK": -0.582,
    "out:ROI% old": 11.48,
    "out:Payback discounted": 11,
    "out:Atemp": 5143,
    "out:Umean": 0.67,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "F",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 171,
    "out:Energy use kWh/m2": 171,
    "out:Energy savings %": 9.9,
    "out:Op energy cost/MSEK": 125,
    "out:El price/MSEK": 72,
    "out:Heating price/MSEK": 46,
    "out:DH+ DHW/kWh/m2": 161,
    "out:El bought/kWh/m2": 696.7,
    "out:Return %": 10,
    "out:Return/kSEK/y": 308,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.33,
    "out:PV (kWp installed)": 122.531965,
    "out:PV (m2 panels)": 643,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 114968.11,
    "out:PV (kWh sold)": 84004.99,
    "out:PV (% sold (El))": 73.07,
    "out:PV (kWh self-consumed)": 30963.12,
    "out:PV (ratio sold/self-consumed)": 2.71,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 7,
    "out:Payback Time": 14.285714,
    "out:DH kWh savings": 56573,
    "out:EL kWh savings": 30963,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 211225,
    "out:DH kr savings": 51481,
    "out:El kr savings": 52637,
    "out:El kr sold": 107106,
    "out:El kr saved": 52637,
    "out:El kr return": 159744,
    "out:% solar/total": 38,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 602138,
    "out:% savings (space heating)": 8.57,
    "out:Total_Water Heating (DH)": 223720,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 117.08,
    "out:Etvv": 25,
    "out:Ef": 9.88,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z00000100020000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 112.03,
    "out:DHW (DH)": 43.5,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 9.88,
    "out:Total Energy Use Pre PV": 171.9,
    "out:Total Energy Use Post PV": 165.88,
    "out:Primary Energy": 113.7,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 26.73,
    "out:CO2 Operational/m2": -25.42,
    "out:Total CO2/m2": 1.31,
    "out:Total CO2 (tons)": 6.72,
    "out:Klimatpaverkan": -322.52,
    "out:Initial Cost/MSEK": 3.089625,
    "out:Running cost/(Apt SEK y)": 49712,
    "out:Running Cost over RSP/MSEK": 16.361,
    "out:LCP/MSEK": -0.182,
    "out:ROI% old": 13.44,
    "out:Payback discounted": 9,
    "out:Atemp": 5143,
    "out:Umean": 0.67,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "F",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 166,
    "out:Energy use kWh/m2": 166,
    "out:Energy savings %": 13.3,
    "out:Op energy cost/MSEK": 122,
    "out:El price/MSEK": 69,
    "out:Heating price/MSEK": 45,
    "out:DH+ DHW/kWh/m2": 156,
    "out:El bought/kWh/m2": 696.7,
    "out:Return %": 12,
    "out:Return/kSEK/y": 376,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.33,
    "out:PV (kWp installed)": 122.531965,
    "out:PV (m2 panels)": 643,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 114968.11,
    "out:PV (kWh sold)": 84004.99,
    "out:PV (% sold (El))": 73.07,
    "out:PV (kWh self-consumed)": 30963.12,
    "out:PV (ratio sold/self-consumed)": 2.71,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 8,
    "out:Payback Time": 12.5,
    "out:DH kWh savings": 82288,
    "out:EL kWh savings": 30963,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 128574.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 234626,
    "out:DH kr savings": 74882,
    "out:El kr savings": 52637,
    "out:El kr sold": 107106,
    "out:El kr saved": 52637,
    "out:El kr return": 159744,
    "out:% solar/total": 38,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 576171,
    "out:% savings (space heating)": 12.51,
    "out:Total_Water Heating (DH)": 223720,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 112.03,
    "out:Etvv": 25,
    "out:Ef": 9.88,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z00000100020001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 117.08,
    "out:DHW (DH)": 36.98,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 9.88,
    "out:Total Energy Use Pre PV": 169.9,
    "out:Total Energy Use Post PV": 163.88,
    "out:Primary Energy": 114.61,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 27.13,
    "out:CO2 Operational/m2": -26.14,
    "out:Total CO2/m2": 0.99,
    "out:Total CO2 (tons)": 5.09,
    "out:Klimatpaverkan": -324.15,
    "out:Initial Cost/MSEK": 3.2115,
    "out:Running cost/(Apt SEK y)": 49068,
    "out:Running Cost over RSP/MSEK": 16.15,
    "out:LCP/MSEK": -0.092,
    "out:ROI% old": 13.88,
    "out:Payback discounted": 9,
    "out:Atemp": 5143,
    "out:Umean": 0.67,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "F",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 164,
    "out:Energy use kWh/m2": 164,
    "out:Energy savings %": 14.6,
    "out:Op energy cost/MSEK": 120,
    "out:El price/MSEK": 68,
    "out:Heating price/MSEK": 44,
    "out:DH+ DHW/kWh/m2": 154,
    "out:El bought/kWh/m2": 696.7,
    "out:Return %": 13,
    "out:Return/kSEK/y": 403,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.33,
    "out:PV (kWp installed)": 122.531965,
    "out:PV (m2 panels)": 643,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 114968.11,
    "out:PV (kWh sold)": 84004.99,
    "out:PV (% sold (El))": 73.07,
    "out:PV (kWh self-consumed)": 30963.12,
    "out:PV (ratio sold/self-consumed)": 2.71,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 8,
    "out:Payback Time": 12.5,
    "out:DH kWh savings": 92574,
    "out:EL kWh savings": 30963,
    "out:Sewage heat recovery cost": 250448,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 243986,
    "out:DH kr savings": 84242,
    "out:El kr savings": 52637,
    "out:El kr sold": 107106,
    "out:El kr saved": 52637,
    "out:El kr return": 159744,
    "out:% solar/total": 38,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 602138,
    "out:% savings (space heating)": 8.57,
    "out:Total_Water Heating (DH)": 190162,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 117.08,
    "out:Etvv": 21.25,
    "out:Ef": 9.88,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z00000100020001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 112.03,
    "out:DHW (DH)": 36.98,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 9.88,
    "out:Total Energy Use Pre PV": 164.9,
    "out:Total Energy Use Post PV": 158.88,
    "out:Primary Energy": 111.08,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 27.13,
    "out:CO2 Operational/m2": -27.94,
    "out:Total CO2/m2": -0.81,
    "out:Total CO2 (tons)": -4.17,
    "out:Klimatpaverkan": -333.41,
    "out:Initial Cost/MSEK": 3.340125,
    "out:Running cost/(Apt SEK y)": 47459,
    "out:Running Cost over RSP/MSEK": 15.621,
    "out:LCP/MSEK": 0.308,
    "out:ROI% old": 15.6,
    "out:Payback discounted": 8,
    "out:Atemp": 5143,
    "out:Umean": 0.67,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "F",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 159,
    "out:Energy use kWh/m2": 159,
    "out:Energy savings %": 18.2,
    "out:Op energy cost/MSEK": 116,
    "out:El price/MSEK": 66,
    "out:Heating price/MSEK": 43,
    "out:DH+ DHW/kWh/m2": 149,
    "out:El bought/kWh/m2": 696.7,
    "out:Return %": 14,
    "out:Return/kSEK/y": 471,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.33,
    "out:PV (kWp installed)": 122.531965,
    "out:PV (m2 panels)": 643,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 114968.11,
    "out:PV (kWh sold)": 84004.99,
    "out:PV (% sold (El))": 73.07,
    "out:PV (kWh self-consumed)": 30963.12,
    "out:PV (ratio sold/self-consumed)": 2.71,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 8,
    "out:Payback Time": 12.5,
    "out:DH kWh savings": 118289,
    "out:EL kWh savings": 30963,
    "out:Sewage heat recovery cost": 250448,
    "out:Balancing hydronic system cost": 128574.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 267387,
    "out:DH kr savings": 107643,
    "out:El kr savings": 52637,
    "out:El kr sold": 107106,
    "out:El kr saved": 52637,
    "out:El kr return": 159744,
    "out:% solar/total": 38,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 576171,
    "out:% savings (space heating)": 12.51,
    "out:Total_Water Heating (DH)": 190162,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 112.03,
    "out:Etvv": 21.25,
    "out:Ef": 9.88,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z00000100100000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 117.08,
    "out:DHW (DH)": 34.8,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 15.9,
    "out:Total Energy Use Pre PV": 167.9,
    "out:Total Energy Use Post PV": 167.9,
    "out:Primary Energy": 124.58,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 2.02,
    "out:CO2 Operational/m2": 56.82,
    "out:Total CO2/m2": 58.84,
    "out:Total CO2 (tons)": 302.59,
    "out:Klimatpaverkan": -26.65,
    "out:Initial Cost/MSEK": 0.932355,
    "out:Running cost/(Apt SEK y)": 52228,
    "out:Running Cost over RSP/MSEK": 17.153,
    "out:LCP/MSEK": 1.183,
    "out:ROI% old": 32.42,
    "out:Payback discounted": 4,
    "out:Atemp": 5143,
    "out:Umean": 0.67,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "F",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 168,
    "out:Energy use kWh/m2": 168,
    "out:Energy savings %": 11.9,
    "out:Op energy cost/MSEK": 126,
    "out:El price/MSEK": 75,
    "out:Heating price/MSEK": 44,
    "out:DH+ DHW/kWh/m2": 152,
    "out:El bought/kWh/m2": 1113,
    "out:Return %": 29,
    "out:Return/kSEK/y": 270,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 10,
    "out:Payback Time": 10,
    "out:DH kWh savings": 102860,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 93603,
    "out:DH kr savings": 93603,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 602138,
    "out:% savings (space heating)": 8.57,
    "out:Total_Water Heating (DH)": 178976,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 117.08,
    "out:Etvv": 20,
    "out:Ef": 15.9,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z00000100100000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 112.03,
    "out:DHW (DH)": 34.8,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 15.9,
    "out:Total Energy Use Pre PV": 162.9,
    "out:Total Energy Use Post PV": 162.9,
    "out:Primary Energy": 121.04,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 2.02,
    "out:CO2 Operational/m2": 55.02,
    "out:Total CO2/m2": 57.04,
    "out:Total CO2 (tons)": 293.33,
    "out:Klimatpaverkan": -35.91,
    "out:Initial Cost/MSEK": 1.06093,
    "out:Running cost/(Apt SEK y)": 50619,
    "out:Running Cost over RSP/MSEK": 16.625,
    "out:LCP/MSEK": 1.584,
    "out:ROI% old": 35.61,
    "out:Payback discounted": 4,
    "out:Atemp": 5143,
    "out:Umean": 0.67,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "F",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 163,
    "out:Energy use kWh/m2": 163,
    "out:Energy savings %": 15.3,
    "out:Op energy cost/MSEK": 122,
    "out:El price/MSEK": 73,
    "out:Heating price/MSEK": 42,
    "out:DH+ DHW/kWh/m2": 147,
    "out:El bought/kWh/m2": 1113,
    "out:Return %": 32,
    "out:Return/kSEK/y": 338,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 11,
    "out:Payback Time": 9.090909,
    "out:DH kWh savings": 128575,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 128574.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 117003,
    "out:DH kr savings": 117003,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 576171,
    "out:% savings (space heating)": 12.51,
    "out:Total_Water Heating (DH)": 178976,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 112.03,
    "out:Etvv": 20,
    "out:Ef": 15.9,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z00000100100001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 117.08,
    "out:DHW (DH)": 29.58,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 15.9,
    "out:Total Energy Use Pre PV": 162.9,
    "out:Total Energy Use Post PV": 162.9,
    "out:Primary Energy": 122.48,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 2.42,
    "out:CO2 Operational/m2": 55.02,
    "out:Total CO2/m2": 57.44,
    "out:Total CO2 (tons)": 295.4,
    "out:Klimatpaverkan": -33.84,
    "out:Initial Cost/MSEK": 1.182803,
    "out:Running cost/(Apt SEK y)": 50619,
    "out:Running Cost over RSP/MSEK": 16.625,
    "out:LCP/MSEK": 1.462,
    "out:ROI% old": 31.94,
    "out:Payback discounted": 4,
    "out:Atemp": 5143,
    "out:Umean": 0.67,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "F",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 163,
    "out:Energy use kWh/m2": 163,
    "out:Energy savings %": 15.3,
    "out:Op energy cost/MSEK": 122,
    "out:El price/MSEK": 73,
    "out:Heating price/MSEK": 42,
    "out:DH+ DHW/kWh/m2": 147,
    "out:El bought/kWh/m2": 1113,
    "out:Return %": 29,
    "out:Return/kSEK/y": 338,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 10,
    "out:Payback Time": 10,
    "out:DH kWh savings": 128575,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 250448,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 117003,
    "out:DH kr savings": 117003,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 602138,
    "out:% savings (space heating)": 8.57,
    "out:Total_Water Heating (DH)": 152130,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 117.08,
    "out:Etvv": 17,
    "out:Ef": 15.9,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z00000100100001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 112.03,
    "out:DHW (DH)": 29.58,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 15.9,
    "out:Total Energy Use Pre PV": 157.9,
    "out:Total Energy Use Post PV": 157.9,
    "out:Primary Energy": 118.94,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 2.42,
    "out:CO2 Operational/m2": 53.22,
    "out:Total CO2/m2": 55.64,
    "out:Total CO2 (tons)": 286.14,
    "out:Klimatpaverkan": -43.1,
    "out:Initial Cost/MSEK": 1.311375,
    "out:Running cost/(Apt SEK y)": 49010,
    "out:Running Cost over RSP/MSEK": 16.096,
    "out:LCP/MSEK": 1.862,
    "out:ROI% old": 34.57,
    "out:Payback discounted": 4,
    "out:Atemp": 5143,
    "out:Umean": 0.67,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "F",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 158,
    "out:Energy use kWh/m2": 158,
    "out:Energy savings %": 19,
    "out:Op energy cost/MSEK": 118,
    "out:El price/MSEK": 71,
    "out:Heating price/MSEK": 41,
    "out:DH+ DHW/kWh/m2": 142,
    "out:El bought/kWh/m2": 1113,
    "out:Return %": 31,
    "out:Return/kSEK/y": 406,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 11,
    "out:Payback Time": 9.090909,
    "out:DH kWh savings": 154290,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 250448,
    "out:Balancing hydronic system cost": 128574.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 140404,
    "out:DH kr savings": 140404,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 576171,
    "out:% savings (space heating)": 12.51,
    "out:Total_Water Heating (DH)": 152130,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 112.03,
    "out:Etvv": 17,
    "out:Ef": 15.9,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z00000100110000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 117.08,
    "out:DHW (DH)": 34.8,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 10.73,
    "out:Total Energy Use Pre PV": 167.9,
    "out:Total Energy Use Post PV": 162.73,
    "out:Primary Energy": 115.27,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 14.37,
    "out:CO2 Operational/m2": 25.82,
    "out:Total CO2/m2": 40.19,
    "out:Total CO2 (tons)": 206.71,
    "out:Klimatpaverkan": -122.53,
    "out:Initial Cost/MSEK": 2.002125,
    "out:Running cost/(Apt SEK y)": 50214,
    "out:Running Cost over RSP/MSEK": 16.51,
    "out:LCP/MSEK": 0.757,
    "out:ROI% old": 19.69,
    "out:Payback discounted": 6,
    "out:Atemp": 5143,
    "out:Umean": 0.67,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "F",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 163,
    "out:Energy use kWh/m2": 163,
    "out:Energy savings %": 15.3,
    "out:Op energy cost/MSEK": 122,
    "out:El price/MSEK": 71,
    "out:Heating price/MSEK": 44,
    "out:DH+ DHW/kWh/m2": 152,
    "out:El bought/kWh/m2": 758.1,
    "out:Return %": 18,
    "out:Return/kSEK/y": 355,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 61.265983,
    "out:PV (m2 panels)": 321,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 57484.06,
    "out:PV (kWh sold)": 30886.05,
    "out:PV (% sold (El))": 53.73,
    "out:PV (kWh self-consumed)": 26598,
    "out:PV (ratio sold/self-consumed)": 1.16,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 9,
    "out:Payback Time": 11.111111,
    "out:DH kWh savings": 102860,
    "out:EL kWh savings": 26598,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 178199,
    "out:DH kr savings": 93603,
    "out:El kr savings": 45217,
    "out:El kr sold": 39380,
    "out:El kr saved": 45217,
    "out:El kr return": 84596,
    "out:% solar/total": 33,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 602138,
    "out:% savings (space heating)": 8.57,
    "out:Total_Water Heating (DH)": 178976,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 117.08,
    "out:Etvv": 20,
    "out:Ef": 10.73,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z00000100110000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 112.03,
    "out:DHW (DH)": 34.8,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 10.73,
    "out:Total Energy Use Pre PV": 162.9,
    "out:Total Energy Use Post PV": 157.73,
    "out:Primary Energy": 111.73,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 14.37,
    "out:CO2 Operational/m2": 24.02,
    "out:Total CO2/m2": 38.39,
    "out:Total CO2 (tons)": 197.46,
    "out:Klimatpaverkan": -131.78,
    "out:Initial Cost/MSEK": 2.13075,
    "out:Running cost/(Apt SEK y)": 48605,
    "out:Running Cost over RSP/MSEK": 15.981,
    "out:LCP/MSEK": 1.158,
    "out:ROI% old": 22.05,
    "out:Payback discounted": 6,
    "out:Atemp": 5143,
    "out:Umean": 0.67,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "F",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 158,
    "out:Energy use kWh/m2": 158,
    "out:Energy savings %": 19,
    "out:Op energy cost/MSEK": 118,
    "out:El price/MSEK": 69,
    "out:Heating price/MSEK": 42,
    "out:DH+ DHW/kWh/m2": 147,
    "out:El bought/kWh/m2": 758.1,
    "out:Return %": 20,
    "out:Return/kSEK/y": 423,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 61.265983,
    "out:PV (m2 panels)": 321,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 57484.06,
    "out:PV (kWh sold)": 30886.05,
    "out:PV (% sold (El))": 53.73,
    "out:PV (kWh self-consumed)": 26598,
    "out:PV (ratio sold/self-consumed)": 1.16,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 9,
    "out:Payback Time": 11.111111,
    "out:DH kWh savings": 128575,
    "out:EL kWh savings": 26598,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 128574.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 201600,
    "out:DH kr savings": 117003,
    "out:El kr savings": 45217,
    "out:El kr sold": 39380,
    "out:El kr saved": 45217,
    "out:El kr return": 84596,
    "out:% solar/total": 33,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 576171,
    "out:% savings (space heating)": 12.51,
    "out:Total_Water Heating (DH)": 178976,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 112.03,
    "out:Etvv": 20,
    "out:Ef": 10.73,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z00000100110001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 117.08,
    "out:DHW (DH)": 29.58,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 10.73,
    "out:Total Energy Use Pre PV": 162.9,
    "out:Total Energy Use Post PV": 157.73,
    "out:Primary Energy": 113.17,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 14.77,
    "out:CO2 Operational/m2": 24.02,
    "out:Total CO2/m2": 38.8,
    "out:Total CO2 (tons)": 199.53,
    "out:Klimatpaverkan": -129.71,
    "out:Initial Cost/MSEK": 2.252625,
    "out:Running cost/(Apt SEK y)": 48605,
    "out:Running Cost over RSP/MSEK": 15.981,
    "out:LCP/MSEK": 1.036,
    "out:ROI% old": 20.85,
    "out:Payback discounted": 6,
    "out:Atemp": 5143,
    "out:Umean": 0.67,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "F",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 158,
    "out:Energy use kWh/m2": 158,
    "out:Energy savings %": 19,
    "out:Op energy cost/MSEK": 118,
    "out:El price/MSEK": 69,
    "out:Heating price/MSEK": 42,
    "out:DH+ DHW/kWh/m2": 147,
    "out:El bought/kWh/m2": 758.1,
    "out:Return %": 19,
    "out:Return/kSEK/y": 423,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 61.265983,
    "out:PV (m2 panels)": 321,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 57484.06,
    "out:PV (kWh sold)": 30886.05,
    "out:PV (% sold (El))": 53.73,
    "out:PV (kWh self-consumed)": 26598,
    "out:PV (ratio sold/self-consumed)": 1.16,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 9,
    "out:Payback Time": 11.111111,
    "out:DH kWh savings": 128575,
    "out:EL kWh savings": 26598,
    "out:Sewage heat recovery cost": 250448,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 201600,
    "out:DH kr savings": 117003,
    "out:El kr savings": 45217,
    "out:El kr sold": 39380,
    "out:El kr saved": 45217,
    "out:El kr return": 84596,
    "out:% solar/total": 33,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 602138,
    "out:% savings (space heating)": 8.57,
    "out:Total_Water Heating (DH)": 152130,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 117.08,
    "out:Etvv": 17,
    "out:Ef": 10.73,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z00000100110001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 112.03,
    "out:DHW (DH)": 29.58,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 10.73,
    "out:Total Energy Use Pre PV": 157.9,
    "out:Total Energy Use Post PV": 152.73,
    "out:Primary Energy": 109.63,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 14.77,
    "out:CO2 Operational/m2": 22.22,
    "out:Total CO2/m2": 37,
    "out:Total CO2 (tons)": 190.27,
    "out:Klimatpaverkan": -138.97,
    "out:Initial Cost/MSEK": 2.381125,
    "out:Running cost/(Apt SEK y)": 46995,
    "out:Running Cost over RSP/MSEK": 15.452,
    "out:LCP/MSEK": 1.436,
    "out:ROI% old": 22.9,
    "out:Payback discounted": 5,
    "out:Atemp": 5143,
    "out:Umean": 0.67,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "F",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 153,
    "out:Energy use kWh/m2": 153,
    "out:Energy savings %": 22.9,
    "out:Op energy cost/MSEK": 114,
    "out:El price/MSEK": 67,
    "out:Heating price/MSEK": 41,
    "out:DH+ DHW/kWh/m2": 142,
    "out:El bought/kWh/m2": 758.1,
    "out:Return %": 21,
    "out:Return/kSEK/y": 490,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 61.265983,
    "out:PV (m2 panels)": 321,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 57484.06,
    "out:PV (kWh sold)": 30886.05,
    "out:PV (% sold (El))": 53.73,
    "out:PV (kWh self-consumed)": 26598,
    "out:PV (ratio sold/self-consumed)": 1.16,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 9,
    "out:Payback Time": 11.111111,
    "out:DH kWh savings": 154290,
    "out:EL kWh savings": 26598,
    "out:Sewage heat recovery cost": 250448,
    "out:Balancing hydronic system cost": 128574.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 225000,
    "out:DH kr savings": 140404,
    "out:El kr savings": 45217,
    "out:El kr sold": 39380,
    "out:El kr saved": 45217,
    "out:El kr return": 84596,
    "out:% solar/total": 33,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 576171,
    "out:% savings (space heating)": 12.51,
    "out:Total_Water Heating (DH)": 152130,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 112.03,
    "out:Etvv": 17,
    "out:Ef": 10.73,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z00000100120000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 117.08,
    "out:DHW (DH)": 34.8,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 9.88,
    "out:Total Energy Use Pre PV": 167.9,
    "out:Total Energy Use Post PV": 161.88,
    "out:Primary Energy": 113.74,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 26.73,
    "out:CO2 Operational/m2": -26.86,
    "out:Total CO2/m2": -0.13,
    "out:Total CO2 (tons)": -0.68,
    "out:Klimatpaverkan": -329.92,
    "out:Initial Cost/MSEK": 3.071875,
    "out:Running cost/(Apt SEK y)": 48425,
    "out:Running Cost over RSP/MSEK": 15.938,
    "out:LCP/MSEK": 0.259,
    "out:ROI% old": 15.49,
    "out:Payback discounted": 8,
    "out:Atemp": 5143,
    "out:Umean": 0.67,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "F",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 162,
    "out:Energy use kWh/m2": 162,
    "out:Energy savings %": 16,
    "out:Op energy cost/MSEK": 118,
    "out:El price/MSEK": 68,
    "out:Heating price/MSEK": 44,
    "out:DH+ DHW/kWh/m2": 152,
    "out:El bought/kWh/m2": 696.7,
    "out:Return %": 14,
    "out:Return/kSEK/y": 430,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.33,
    "out:PV (kWp installed)": 122.531965,
    "out:PV (m2 panels)": 643,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 114968.11,
    "out:PV (kWh sold)": 84004.99,
    "out:PV (% sold (El))": 73.07,
    "out:PV (kWh self-consumed)": 30963.12,
    "out:PV (ratio sold/self-consumed)": 2.71,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 8,
    "out:Payback Time": 12.5,
    "out:DH kWh savings": 102860,
    "out:EL kWh savings": 30963,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 253346,
    "out:DH kr savings": 93603,
    "out:El kr savings": 52637,
    "out:El kr sold": 107106,
    "out:El kr saved": 52637,
    "out:El kr return": 159744,
    "out:% solar/total": 38,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 602138,
    "out:% savings (space heating)": 8.57,
    "out:Total_Water Heating (DH)": 178976,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 117.08,
    "out:Etvv": 20,
    "out:Ef": 9.88,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z00000100120000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 112.03,
    "out:DHW (DH)": 34.8,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 9.88,
    "out:Total Energy Use Pre PV": 162.9,
    "out:Total Energy Use Post PV": 156.88,
    "out:Primary Energy": 110.2,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 26.73,
    "out:CO2 Operational/m2": -28.66,
    "out:Total CO2/m2": -1.93,
    "out:Total CO2 (tons)": -9.94,
    "out:Klimatpaverkan": -339.18,
    "out:Initial Cost/MSEK": 3.2005,
    "out:Running cost/(Apt SEK y)": 46815,
    "out:Running Cost over RSP/MSEK": 15.409,
    "out:LCP/MSEK": 0.659,
    "out:ROI% old": 17.23,
    "out:Payback discounted": 7,
    "out:Atemp": 5143,
    "out:Umean": 0.67,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "F",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 157,
    "out:Energy use kWh/m2": 157,
    "out:Energy savings %": 19.7,
    "out:Op energy cost/MSEK": 114,
    "out:El price/MSEK": 65,
    "out:Heating price/MSEK": 42,
    "out:DH+ DHW/kWh/m2": 147,
    "out:El bought/kWh/m2": 696.7,
    "out:Return %": 16,
    "out:Return/kSEK/y": 498,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.33,
    "out:PV (kWp installed)": 122.531965,
    "out:PV (m2 panels)": 643,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 114968.11,
    "out:PV (kWh sold)": 84004.99,
    "out:PV (% sold (El))": 73.07,
    "out:PV (kWh self-consumed)": 30963.12,
    "out:PV (ratio sold/self-consumed)": 2.71,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 9,
    "out:Payback Time": 11.111111,
    "out:DH kWh savings": 128575,
    "out:EL kWh savings": 30963,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 128574.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 276747,
    "out:DH kr savings": 117003,
    "out:El kr savings": 52637,
    "out:El kr sold": 107106,
    "out:El kr saved": 52637,
    "out:El kr return": 159744,
    "out:% solar/total": 38,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 576171,
    "out:% savings (space heating)": 12.51,
    "out:Total_Water Heating (DH)": 178976,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 112.03,
    "out:Etvv": 20,
    "out:Ef": 9.88,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z00000100120001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 117.08,
    "out:DHW (DH)": 29.58,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 9.88,
    "out:Total Energy Use Pre PV": 162.9,
    "out:Total Energy Use Post PV": 156.88,
    "out:Primary Energy": 111.64,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 27.13,
    "out:CO2 Operational/m2": -28.66,
    "out:Total CO2/m2": -1.53,
    "out:Total CO2 (tons)": -7.87,
    "out:Klimatpaverkan": -337.11,
    "out:Initial Cost/MSEK": 3.322375,
    "out:Running cost/(Apt SEK y)": 46815,
    "out:Running Cost over RSP/MSEK": 15.409,
    "out:LCP/MSEK": 0.537,
    "out:ROI% old": 16.6,
    "out:Payback discounted": 7,
    "out:Atemp": 5143,
    "out:Umean": 0.67,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "F",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 157,
    "out:Energy use kWh/m2": 157,
    "out:Energy savings %": 19.7,
    "out:Op energy cost/MSEK": 114,
    "out:El price/MSEK": 65,
    "out:Heating price/MSEK": 42,
    "out:DH+ DHW/kWh/m2": 147,
    "out:El bought/kWh/m2": 696.7,
    "out:Return %": 15,
    "out:Return/kSEK/y": 498,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.33,
    "out:PV (kWp installed)": 122.531965,
    "out:PV (m2 panels)": 643,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 114968.11,
    "out:PV (kWh sold)": 84004.99,
    "out:PV (% sold (El))": 73.07,
    "out:PV (kWh self-consumed)": 30963.12,
    "out:PV (ratio sold/self-consumed)": 2.71,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 8,
    "out:Payback Time": 12.5,
    "out:DH kWh savings": 128575,
    "out:EL kWh savings": 30963,
    "out:Sewage heat recovery cost": 250448,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 276747,
    "out:DH kr savings": 117003,
    "out:El kr savings": 52637,
    "out:El kr sold": 107106,
    "out:El kr saved": 52637,
    "out:El kr return": 159744,
    "out:% solar/total": 38,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 602138,
    "out:% savings (space heating)": 8.57,
    "out:Total_Water Heating (DH)": 152130,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 117.08,
    "out:Etvv": 17,
    "out:Ef": 9.88,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z00000100120001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 112.03,
    "out:DHW (DH)": 29.58,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 9.88,
    "out:Total Energy Use Pre PV": 157.9,
    "out:Total Energy Use Post PV": 151.88,
    "out:Primary Energy": 108.1,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 27.13,
    "out:CO2 Operational/m2": -30.46,
    "out:Total CO2/m2": -3.33,
    "out:Total CO2 (tons)": -17.13,
    "out:Klimatpaverkan": -346.37,
    "out:Initial Cost/MSEK": 3.451,
    "out:Running cost/(Apt SEK y)": 45206,
    "out:Running Cost over RSP/MSEK": 14.88,
    "out:LCP/MSEK": 0.938,
    "out:ROI% old": 18.17,
    "out:Payback discounted": 7,
    "out:Atemp": 5143,
    "out:Umean": 0.67,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "F",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 152,
    "out:Energy use kWh/m2": 152,
    "out:Energy savings %": 23.7,
    "out:Op energy cost/MSEK": 111,
    "out:El price/MSEK": 63,
    "out:Heating price/MSEK": 41,
    "out:DH+ DHW/kWh/m2": 142,
    "out:El bought/kWh/m2": 696.7,
    "out:Return %": 16,
    "out:Return/kSEK/y": 565,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.33,
    "out:PV (kWp installed)": 122.531965,
    "out:PV (m2 panels)": 643,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 114968.11,
    "out:PV (kWh sold)": 84004.99,
    "out:PV (% sold (El))": 73.07,
    "out:PV (kWh self-consumed)": 30963.12,
    "out:PV (ratio sold/self-consumed)": 2.71,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 9,
    "out:Payback Time": 11.111111,
    "out:DH kWh savings": 154290,
    "out:EL kWh savings": 30963,
    "out:Sewage heat recovery cost": 250448,
    "out:Balancing hydronic system cost": 128574.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 300148,
    "out:DH kr savings": 140404,
    "out:El kr savings": 52637,
    "out:El kr sold": 107106,
    "out:El kr saved": 52637,
    "out:El kr return": 159744,
    "out:% solar/total": 38,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 576171,
    "out:% savings (space heating)": 12.51,
    "out:Total_Water Heating (DH)": 152130,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 112.03,
    "out:Etvv": 17,
    "out:Ef": 9.88,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z00000101000000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 70.87,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 45.38,
    "out:Electricity (inc PV)": 43.33,
    "out:Total Energy Use Pre PV": 116.38,
    "out:Total Energy Use Post PV": 114.33,
    "out:Primary Energy": 119.4,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 4.87,
    "out:CO2 Operational/m2": 31.55,
    "out:Total CO2/m2": 36.42,
    "out:Total CO2 (tons)": 187.31,
    "out:Klimatpaverkan": -141.93,
    "out:Initial Cost/MSEK": 5.297375,
    "out:Running cost/(Apt SEK y)": 32297,
    "out:Running Cost over RSP/MSEK": 10.578,
    "out:LCP/MSEK": 3.394,
    "out:ROI% old": 23.44,
    "out:Payback discounted": 5,
    "out:Atemp": 5143,
    "out:Umean": 0.67,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 114,
    "out:Energy use kWh/m2": 114,
    "out:Energy savings %": 64.9,
    "out:Op energy cost/MSEK": 76,
    "out:El price/MSEK": 52,
    "out:Heating price/MSEK": 20,
    "out:DH+ DHW/kWh/m2": 71,
    "out:El bought/kWh/m2": 3176.9,
    "out:Return %": 21,
    "out:Return/kSEK/y": 1107,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 1479000,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 73950,
    "out:EAHP (replacement cost)": 1105400,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 27.18,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 519443,
    "out:EL kWh savings": -141053,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 232902,
    "out:DH kr savings": 472693,
    "out:El kr savings": -239791,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 364490,
    "out:% savings (space heating)": 44.66,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 59.16,
    "out:Etvv": 0,
    "out:Ef": 43.33,
    "out:heatPumpQuantity": 10,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000101000000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 66.07,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 45.31,
    "out:Electricity (inc PV)": 43.2,
    "out:Total Energy Use Pre PV": 111.31,
    "out:Total Energy Use Post PV": 109.2,
    "out:Primary Energy": 115.93,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 4.87,
    "out:CO2 Operational/m2": 29.74,
    "out:Total CO2/m2": 34.61,
    "out:Total CO2 (tons)": 178,
    "out:Klimatpaverkan": -151.24,
    "out:Initial Cost/MSEK": 5.426,
    "out:Running cost/(Apt SEK y)": 30672,
    "out:Running Cost over RSP/MSEK": 10.044,
    "out:LCP/MSEK": 3.799,
    "out:ROI% old": 24.29,
    "out:Payback discounted": 5,
    "out:Atemp": 5143,
    "out:Umean": 0.67,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 109,
    "out:Energy use kWh/m2": 109,
    "out:Energy savings %": 72.5,
    "out:Op energy cost/MSEK": 72,
    "out:El price/MSEK": 50,
    "out:Heating price/MSEK": 19,
    "out:DH+ DHW/kWh/m2": 66,
    "out:El bought/kWh/m2": 3171.5,
    "out:Return %": 22,
    "out:Return/kSEK/y": 1176,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 1479000,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 73950,
    "out:EAHP (replacement cost)": 1105400,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 27.11,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 545158,
    "out:EL kWh savings": -140408,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 128574.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 257400,
    "out:DH kr savings": 496094,
    "out:El kr savings": -238694,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 339817,
    "out:% savings (space heating)": 48.4,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 54.53,
    "out:Etvv": 0,
    "out:Ef": 43.2,
    "out:heatPumpQuantity": 10,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000101000001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 66.62,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 44.69,
    "out:Electricity (inc PV)": 42.87,
    "out:Total Energy Use Pre PV": 111.69,
    "out:Total Energy Use Post PV": 109.87,
    "out:Primary Energy": 117.01,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 5.27,
    "out:CO2 Operational/m2": 30.02,
    "out:Total CO2/m2": 35.29,
    "out:Total CO2 (tons)": 181.5,
    "out:Klimatpaverkan": -147.74,
    "out:Initial Cost/MSEK": 5.547875,
    "out:Running cost/(Apt SEK y)": 30866,
    "out:Running Cost over RSP/MSEK": 10.108,
    "out:LCP/MSEK": 3.613,
    "out:ROI% old": 23.59,
    "out:Payback discounted": 5,
    "out:Atemp": 5143,
    "out:Umean": 0.67,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 110,
    "out:Energy use kWh/m2": 110,
    "out:Energy savings %": 70.9,
    "out:Op energy cost/MSEK": 72,
    "out:El price/MSEK": 50,
    "out:Heating price/MSEK": 19,
    "out:DH+ DHW/kWh/m2": 67,
    "out:El bought/kWh/m2": 3128.5,
    "out:Return %": 21,
    "out:Return/kSEK/y": 1168,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 1479000,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 73950,
    "out:EAHP (replacement cost)": 1105400,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 26.49,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 540015,
    "out:EL kWh savings": -138720,
    "out:Sewage heat recovery cost": 250448,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 255590,
    "out:DH kr savings": 491414,
    "out:El kr savings": -235824,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 342652,
    "out:% savings (space heating)": 47.97,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 56.91,
    "out:Etvv": 0,
    "out:Ef": 42.87,
    "out:heatPumpQuantity": 10,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000101000001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 61.88,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 44.6,
    "out:Electricity (inc PV)": 42.74,
    "out:Total Energy Use Pre PV": 106.6,
    "out:Total Energy Use Post PV": 104.74,
    "out:Primary Energy": 113.54,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 5.27,
    "out:CO2 Operational/m2": 28.21,
    "out:Total CO2/m2": 33.48,
    "out:Total CO2 (tons)": 172.18,
    "out:Klimatpaverkan": -157.06,
    "out:Initial Cost/MSEK": 5.676375,
    "out:Running cost/(Apt SEK y)": 29238,
    "out:Running Cost over RSP/MSEK": 9.573,
    "out:LCP/MSEK": 4.02,
    "out:ROI% old": 24.4,
    "out:Payback discounted": 5,
    "out:Atemp": 5143,
    "out:Umean": 0.67,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 105,
    "out:Energy use kWh/m2": 105,
    "out:Energy savings %": 79,
    "out:Op energy cost/MSEK": 68,
    "out:El price/MSEK": 48,
    "out:Heating price/MSEK": 18,
    "out:DH+ DHW/kWh/m2": 62,
    "out:El bought/kWh/m2": 3122,
    "out:Return %": 22,
    "out:Return/kSEK/y": 1236,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 1479000,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 73950,
    "out:EAHP (replacement cost)": 1105400,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 26.4,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 565730,
    "out:EL kWh savings": -138018,
    "out:Sewage heat recovery cost": 250448,
    "out:Balancing hydronic system cost": 128574.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 280183,
    "out:DH kr savings": 514814,
    "out:El kr savings": -234631,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 318272,
    "out:% savings (space heating)": 51.67,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 52.31,
    "out:Etvv": 0,
    "out:Ef": 42.74,
    "out:heatPumpQuantity": 10,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000101010000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 70.87,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 45.38,
    "out:Electricity (inc PV)": 33.79,
    "out:Total Energy Use Pre PV": 116.38,
    "out:Total Energy Use Post PV": 104.79,
    "out:Primary Energy": 102.24,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 17.22,
    "out:CO2 Operational/m2": 23.25,
    "out:Total CO2/m2": 40.48,
    "out:Total CO2 (tons)": 208.17,
    "out:Klimatpaverkan": -121.07,
    "out:Initial Cost/MSEK": 6.36725,
    "out:Running cost/(Apt SEK y)": 30046,
    "out:Running Cost over RSP/MSEK": 9.858,
    "out:LCP/MSEK": 3.044,
    "out:ROI% old": 21.11,
    "out:Payback discounted": 6,
    "out:Atemp": 5143,
    "out:Umean": 0.67,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 105,
    "out:Energy use kWh/m2": 105,
    "out:Energy savings %": 79,
    "out:Op energy cost/MSEK": 71,
    "out:El price/MSEK": 48,
    "out:Heating price/MSEK": 20,
    "out:DH+ DHW/kWh/m2": 71,
    "out:El bought/kWh/m2": 2522.5,
    "out:Return %": 19,
    "out:Return/kSEK/y": 1202,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 1479000,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 73950,
    "out:EAHP (replacement cost)": 1105400,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 27.18,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 61.265983,
    "out:PV (m2 panels)": 321,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 57484.06,
    "out:PV (kWh sold)": 7430.83,
    "out:PV (% sold (El))": 12.93,
    "out:PV (kWh self-consumed)": 50053.23,
    "out:PV (ratio sold/self-consumed)": 0.15,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 519443,
    "out:EL kWh savings": -92021,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 325732,
    "out:DH kr savings": 472693,
    "out:El kr savings": -156435,
    "out:El kr sold": 9474,
    "out:El kr saved": 85090,
    "out:El kr return": 94565,
    "out:% solar/total": 61,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 364490,
    "out:% savings (space heating)": 44.66,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 59.16,
    "out:Etvv": 0,
    "out:Ef": 33.79,
    "out:heatPumpQuantity": 10,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000101010000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 66.07,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 45.31,
    "out:Electricity (inc PV)": 33.68,
    "out:Total Energy Use Pre PV": 111.31,
    "out:Total Energy Use Post PV": 99.68,
    "out:Primary Energy": 98.79,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 17.22,
    "out:CO2 Operational/m2": 21.41,
    "out:Total CO2/m2": 38.63,
    "out:Total CO2 (tons)": 198.68,
    "out:Klimatpaverkan": -130.56,
    "out:Initial Cost/MSEK": 6.49575,
    "out:Running cost/(Apt SEK y)": 28421,
    "out:Running Cost over RSP/MSEK": 9.324,
    "out:LCP/MSEK": 3.449,
    "out:ROI% old": 21.87,
    "out:Payback discounted": 6,
    "out:Atemp": 5143,
    "out:Umean": 0.67,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 100,
    "out:Energy use kWh/m2": 100,
    "out:Energy savings %": 88,
    "out:Op energy cost/MSEK": 67,
    "out:El price/MSEK": 45,
    "out:Heating price/MSEK": 19,
    "out:DH+ DHW/kWh/m2": 66,
    "out:El bought/kWh/m2": 2517.6,
    "out:Return %": 20,
    "out:Return/kSEK/y": 1270,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 1479000,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 73950,
    "out:EAHP (replacement cost)": 1105400,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 27.11,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 61.265983,
    "out:PV (m2 panels)": 321,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 57484.06,
    "out:PV (kWh sold)": 7466.93,
    "out:PV (% sold (El))": 12.99,
    "out:PV (kWh self-consumed)": 50017.13,
    "out:PV (ratio sold/self-consumed)": 0.15,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 545158,
    "out:EL kWh savings": -91440,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 128574.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 350166,
    "out:DH kr savings": 496094,
    "out:El kr savings": -155448,
    "out:El kr sold": 9520,
    "out:El kr saved": 85029,
    "out:El kr return": 94549,
    "out:% solar/total": 61,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 339817,
    "out:% savings (space heating)": 48.4,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 54.53,
    "out:Etvv": 0,
    "out:Ef": 33.68,
    "out:heatPumpQuantity": 10,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000101010001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 66.62,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 44.69,
    "out:Electricity (inc PV)": 33.38,
    "out:Total Energy Use Pre PV": 111.69,
    "out:Total Energy Use Post PV": 100.38,
    "out:Primary Energy": 99.93,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 17.62,
    "out:CO2 Operational/m2": 21.41,
    "out:Total CO2/m2": 39.03,
    "out:Total CO2 (tons)": 200.74,
    "out:Klimatpaverkan": -128.5,
    "out:Initial Cost/MSEK": 6.617625,
    "out:Running cost/(Apt SEK y)": 28618,
    "out:Running Cost over RSP/MSEK": 9.39,
    "out:LCP/MSEK": 3.262,
    "out:ROI% old": 21.33,
    "out:Payback discounted": 6,
    "out:Atemp": 5143,
    "out:Umean": 0.67,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 100,
    "out:Energy use kWh/m2": 100,
    "out:Energy savings %": 88,
    "out:Op energy cost/MSEK": 68,
    "out:El price/MSEK": 46,
    "out:Heating price/MSEK": 19,
    "out:DH+ DHW/kWh/m2": 67,
    "out:El bought/kWh/m2": 2478.1,
    "out:Return %": 19,
    "out:Return/kSEK/y": 1262,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 1479000,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 73950,
    "out:EAHP (replacement cost)": 1105400,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 26.49,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 61.265983,
    "out:PV (m2 panels)": 321,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 57484.06,
    "out:PV (kWh sold)": 7762.55,
    "out:PV (% sold (El))": 13.5,
    "out:PV (kWh self-consumed)": 49721.51,
    "out:PV (ratio sold/self-consumed)": 0.16,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 540015,
    "out:EL kWh savings": -89923,
    "out:Sewage heat recovery cost": 250448,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 348442,
    "out:DH kr savings": 491414,
    "out:El kr savings": -152869,
    "out:El kr sold": 9897,
    "out:El kr saved": 84527,
    "out:El kr return": 94424,
    "out:% solar/total": 61,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 342652,
    "out:% savings (space heating)": 47.97,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 56.91,
    "out:Etvv": 0,
    "out:Ef": 33.38,
    "out:heatPumpQuantity": 10,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000101010001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 61.88,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 44.6,
    "out:Electricity (inc PV)": 33.26,
    "out:Total Energy Use Pre PV": 106.6,
    "out:Total Energy Use Post PV": 95.26,
    "out:Primary Energy": 96.49,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 17.62,
    "out:CO2 Operational/m2": 19.55,
    "out:Total CO2/m2": 37.18,
    "out:Total CO2 (tons)": 191.19,
    "out:Klimatpaverkan": -138.05,
    "out:Initial Cost/MSEK": 6.74625,
    "out:Running cost/(Apt SEK y)": 26990,
    "out:Running Cost over RSP/MSEK": 8.854,
    "out:LCP/MSEK": 3.668,
    "out:ROI% old": 22.05,
    "out:Payback discounted": 6,
    "out:Atemp": 5143,
    "out:Umean": 0.67,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 95,
    "out:Energy use kWh/m2": 95,
    "out:Energy savings %": 97.9,
    "out:Op energy cost/MSEK": 64,
    "out:El price/MSEK": 43,
    "out:Heating price/MSEK": 18,
    "out:DH+ DHW/kWh/m2": 62,
    "out:El bought/kWh/m2": 2472.1,
    "out:Return %": 20,
    "out:Return/kSEK/y": 1330,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 1479000,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 73950,
    "out:EAHP (replacement cost)": 1105400,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 26.4,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 61.265983,
    "out:PV (m2 panels)": 321,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 57484.06,
    "out:PV (kWh sold)": 7808.26,
    "out:PV (% sold (El))": 13.58,
    "out:PV (kWh self-consumed)": 49675.8,
    "out:PV (ratio sold/self-consumed)": 0.16,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 565730,
    "out:EL kWh savings": -89293,
    "out:Sewage heat recovery cost": 250448,
    "out:Balancing hydronic system cost": 128574.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 372972,
    "out:DH kr savings": 514814,
    "out:El kr savings": -151798,
    "out:El kr sold": 9956,
    "out:El kr saved": 84449,
    "out:El kr return": 94404,
    "out:% solar/total": 61,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 318272,
    "out:% savings (space heating)": 51.67,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 52.31,
    "out:Etvv": 0,
    "out:Ef": 33.26,
    "out:heatPumpQuantity": 10,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000101020000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 70.87,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 45.38,
    "out:Electricity (inc PV)": 30.53,
    "out:Total Energy Use Pre PV": 116.38,
    "out:Total Energy Use Post PV": 101.53,
    "out:Primary Energy": 96.37,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 29.58,
    "out:CO2 Operational/m2": -16.21,
    "out:Total CO2/m2": 13.37,
    "out:Total CO2 (tons)": 68.74,
    "out:Klimatpaverkan": -260.5,
    "out:Initial Cost/MSEK": 7.437,
    "out:Running cost/(Apt SEK y)": 28121,
    "out:Running Cost over RSP/MSEK": 9.243,
    "out:LCP/MSEK": 2.589,
    "out:ROI% old": 19.26,
    "out:Payback discounted": 6,
    "out:Atemp": 5143,
    "out:Umean": 0.67,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 102,
    "out:Energy use kWh/m2": 102,
    "out:Energy savings %": 84.3,
    "out:Op energy cost/MSEK": 68,
    "out:El price/MSEK": 44,
    "out:Heating price/MSEK": 20,
    "out:DH+ DHW/kWh/m2": 71,
    "out:El bought/kWh/m2": 2276,
    "out:Return %": 17,
    "out:Return/kSEK/y": 1283,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 1479000,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 73950,
    "out:EAHP (replacement cost)": 1105400,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 27.18,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.33,
    "out:PV (kWp installed)": 122.531965,
    "out:PV (m2 panels)": 643,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 114968.11,
    "out:PV (kWh sold)": 47143.3,
    "out:PV (% sold (El))": 41.01,
    "out:PV (kWh self-consumed)": 67824.81,
    "out:PV (ratio sold/self-consumed)": 0.7,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 519443,
    "out:EL kWh savings": -75253,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 404870,
    "out:DH kr savings": 472693,
    "out:El kr savings": -127931,
    "out:El kr sold": 60108,
    "out:El kr saved": 115302,
    "out:El kr return": 175410,
    "out:% solar/total": 83,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 364490,
    "out:% savings (space heating)": 44.66,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 59.16,
    "out:Etvv": 0,
    "out:Ef": 30.53,
    "out:heatPumpQuantity": 10,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000101020000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 66.07,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 45.31,
    "out:Electricity (inc PV)": 30.43,
    "out:Total Energy Use Pre PV": 111.31,
    "out:Total Energy Use Post PV": 96.43,
    "out:Primary Energy": 92.94,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 29.58,
    "out:CO2 Operational/m2": -18.09,
    "out:Total CO2/m2": 11.48,
    "out:Total CO2 (tons)": 59.06,
    "out:Klimatpaverkan": -270.18,
    "out:Initial Cost/MSEK": 7.5655,
    "out:Running cost/(Apt SEK y)": 26497,
    "out:Running Cost over RSP/MSEK": 8.709,
    "out:LCP/MSEK": 2.995,
    "out:ROI% old": 19.94,
    "out:Payback discounted": 6,
    "out:Atemp": 5143,
    "out:Umean": 0.67,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 96,
    "out:Energy use kWh/m2": 96,
    "out:Energy savings %": 95.8,
    "out:Op energy cost/MSEK": 64,
    "out:El price/MSEK": 42,
    "out:Heating price/MSEK": 19,
    "out:DH+ DHW/kWh/m2": 66,
    "out:El bought/kWh/m2": 2271.6,
    "out:Return %": 18,
    "out:Return/kSEK/y": 1351,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 1479000,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 73950,
    "out:EAHP (replacement cost)": 1105400,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 27.11,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.33,
    "out:PV (kWp installed)": 122.531965,
    "out:PV (m2 panels)": 643,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 114968.11,
    "out:PV (kWh sold)": 47217.54,
    "out:PV (% sold (El))": 41.07,
    "out:PV (kWh self-consumed)": 67750.57,
    "out:PV (ratio sold/self-consumed)": 0.7,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 545158,
    "out:EL kWh savings": -74733,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 128574.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 429250,
    "out:DH kr savings": 496094,
    "out:El kr savings": -127046,
    "out:El kr sold": 60202,
    "out:El kr saved": 115176,
    "out:El kr return": 175378,
    "out:% solar/total": 83,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 339817,
    "out:% savings (space heating)": 48.4,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 54.53,
    "out:Etvv": 0,
    "out:Ef": 30.43,
    "out:heatPumpQuantity": 10,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000101020001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 66.62,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 44.69,
    "out:Electricity (inc PV)": 30.17,
    "out:Total Energy Use Pre PV": 111.69,
    "out:Total Energy Use Post PV": 97.17,
    "out:Primary Energy": 94.14,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 29.98,
    "out:CO2 Operational/m2": -18.4,
    "out:Total CO2/m2": 11.58,
    "out:Total CO2 (tons)": 59.55,
    "out:Klimatpaverkan": -269.69,
    "out:Initial Cost/MSEK": 7.687375,
    "out:Running cost/(Apt SEK y)": 26697,
    "out:Running Cost over RSP/MSEK": 8.775,
    "out:LCP/MSEK": 2.807,
    "out:ROI% old": 19.5,
    "out:Payback discounted": 6,
    "out:Atemp": 5143,
    "out:Umean": 0.67,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 97,
    "out:Energy use kWh/m2": 97,
    "out:Energy savings %": 93.8,
    "out:Op energy cost/MSEK": 64,
    "out:El price/MSEK": 42,
    "out:Heating price/MSEK": 19,
    "out:DH+ DHW/kWh/m2": 67,
    "out:El bought/kWh/m2": 2236.4,
    "out:Return %": 17,
    "out:Return/kSEK/y": 1343,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 1479000,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 73950,
    "out:EAHP (replacement cost)": 1105400,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 26.49,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.33,
    "out:PV (kWp installed)": 122.531965,
    "out:PV (m2 panels)": 643,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 114968.11,
    "out:PV (kWh sold)": 47817.19,
    "out:PV (% sold (El))": 41.59,
    "out:PV (kWh self-consumed)": 67150.92,
    "out:PV (ratio sold/self-consumed)": 0.71,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 540015,
    "out:EL kWh savings": -73374,
    "out:Sewage heat recovery cost": 250448,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 427645,
    "out:DH kr savings": 491414,
    "out:El kr savings": -124736,
    "out:El kr sold": 60967,
    "out:El kr saved": 114157,
    "out:El kr return": 175123,
    "out:% solar/total": 82,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 342652,
    "out:% savings (space heating)": 47.97,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 56.91,
    "out:Etvv": 0,
    "out:Ef": 30.17,
    "out:heatPumpQuantity": 10,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000101020001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 61.88,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 44.6,
    "out:Electricity (inc PV)": 30.06,
    "out:Total Energy Use Pre PV": 106.6,
    "out:Total Energy Use Post PV": 92.06,
    "out:Primary Energy": 90.72,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 29.98,
    "out:CO2 Operational/m2": -20.3,
    "out:Total CO2/m2": 9.68,
    "out:Total CO2 (tons)": 49.77,
    "out:Klimatpaverkan": -279.47,
    "out:Initial Cost/MSEK": 7.816,
    "out:Running cost/(Apt SEK y)": 25069,
    "out:Running Cost over RSP/MSEK": 8.24,
    "out:LCP/MSEK": 3.213,
    "out:ROI% old": 20.16,
    "out:Payback discounted": 6,
    "out:Atemp": 5143,
    "out:Umean": 0.67,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 92,
    "out:Energy use kWh/m2": 92,
    "out:Energy savings %": 104.3,
    "out:Op energy cost/MSEK": 60,
    "out:El price/MSEK": 39,
    "out:Heating price/MSEK": 18,
    "out:DH+ DHW/kWh/m2": 62,
    "out:El bought/kWh/m2": 2231.1,
    "out:Return %": 18,
    "out:Return/kSEK/y": 1411,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 1479000,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 73950,
    "out:EAHP (replacement cost)": 1105400,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 26.4,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.33,
    "out:PV (kWp installed)": 122.531965,
    "out:PV (m2 panels)": 643,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 114968.11,
    "out:PV (kWh sold)": 47908.78,
    "out:PV (% sold (El))": 41.67,
    "out:PV (kWh self-consumed)": 67059.33,
    "out:PV (ratio sold/self-consumed)": 0.71,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 565730,
    "out:EL kWh savings": -72810,
    "out:Sewage heat recovery cost": 250448,
    "out:Balancing hydronic system cost": 128574.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 452121,
    "out:DH kr savings": 514814,
    "out:El kr savings": -123777,
    "out:El kr sold": 61084,
    "out:El kr saved": 114001,
    "out:El kr return": 175085,
    "out:% solar/total": 82,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 318272,
    "out:% savings (space heating)": 51.67,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 52.31,
    "out:Etvv": 0,
    "out:Ef": 30.06,
    "out:heatPumpQuantity": 10,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000101100000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 65.24,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 44.45,
    "out:Electricity (inc PV)": 42.72,
    "out:Total Energy Use Pre PV": 109.45,
    "out:Total Energy Use Post PV": 107.72,
    "out:Primary Energy": 116.21,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 4.87,
    "out:CO2 Operational/m2": 29.27,
    "out:Total CO2/m2": 34.14,
    "out:Total CO2 (tons)": 175.57,
    "out:Klimatpaverkan": -153.67,
    "out:Initial Cost/MSEK": 5.40825,
    "out:Running cost/(Apt SEK y)": 30173,
    "out:Running Cost over RSP/MSEK": 9.88,
    "out:LCP/MSEK": 3.98,
    "out:ROI% old": 24.8,
    "out:Payback discounted": 5,
    "out:Atemp": 5143,
    "out:Umean": 0.67,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 108,
    "out:Energy use kWh/m2": 108,
    "out:Energy savings %": 74.1,
    "out:Op energy cost/MSEK": 71,
    "out:El price/MSEK": 49,
    "out:Heating price/MSEK": 19,
    "out:DH+ DHW/kWh/m2": 65,
    "out:El bought/kWh/m2": 3111.8,
    "out:Return %": 22,
    "out:Return/kSEK/y": 1197,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 1479000,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 73950,
    "out:EAHP (replacement cost)": 1105400,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 26.25,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 550301,
    "out:EL kWh savings": -137928,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 266296,
    "out:DH kr savings": 500774,
    "out:El kr savings": -234478,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 335527,
    "out:% savings (space heating)": 49.05,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 56.17,
    "out:Etvv": 0,
    "out:Ef": 42.72,
    "out:heatPumpQuantity": 10,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000101100000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 60.52,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 44.36,
    "out:Electricity (inc PV)": 42.58,
    "out:Total Energy Use Pre PV": 105.36,
    "out:Total Energy Use Post PV": 103.58,
    "out:Primary Energy": 112.75,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 4.87,
    "out:CO2 Operational/m2": 27.81,
    "out:Total CO2/m2": 32.68,
    "out:Total CO2 (tons)": 168.09,
    "out:Klimatpaverkan": -161.15,
    "out:Initial Cost/MSEK": 5.536875,
    "out:Running cost/(Apt SEK y)": 28865,
    "out:Running Cost over RSP/MSEK": 9.451,
    "out:LCP/MSEK": 4.282,
    "out:ROI% old": 25.33,
    "out:Payback discounted": 5,
    "out:Atemp": 5143,
    "out:Umean": 0.67,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 104,
    "out:Energy use kWh/m2": 104,
    "out:Energy savings %": 80.8,
    "out:Op energy cost/MSEK": 67,
    "out:El price/MSEK": 47,
    "out:Heating price/MSEK": 17,
    "out:DH+ DHW/kWh/m2": 61,
    "out:El bought/kWh/m2": 3104.9,
    "out:Return %": 23,
    "out:Return/kSEK/y": 1252,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 1479000,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 73950,
    "out:EAHP (replacement cost)": 1105400,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 26.16,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 570873,
    "out:EL kWh savings": -137208,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 128574.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 286240,
    "out:DH kr savings": 519494,
    "out:El kr savings": -233254,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 311238,
    "out:% savings (space heating)": 52.74,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 51.58,
    "out:Etvv": 0,
    "out:Ef": 42.58,
    "out:heatPumpQuantity": 10,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000101100001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 61.97,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 43.86,
    "out:Electricity (inc PV)": 42.34,
    "out:Total Energy Use Pre PV": 105.86,
    "out:Total Energy Use Post PV": 104.34,
    "out:Primary Energy": 114.3,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 5.27,
    "out:CO2 Operational/m2": 28.11,
    "out:Total CO2/m2": 33.38,
    "out:Total CO2 (tons)": 171.68,
    "out:Klimatpaverkan": -157.56,
    "out:Initial Cost/MSEK": 5.65875,
    "out:Running cost/(Apt SEK y)": 29084,
    "out:Running Cost over RSP/MSEK": 9.523,
    "out:LCP/MSEK": 4.087,
    "out:ROI% old": 24.6,
    "out:Payback discounted": 5,
    "out:Atemp": 5143,
    "out:Umean": 0.67,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 104,
    "out:Energy use kWh/m2": 104,
    "out:Energy savings %": 80.8,
    "out:Op energy cost/MSEK": 68,
    "out:El price/MSEK": 47,
    "out:Heating price/MSEK": 18,
    "out:DH+ DHW/kWh/m2": 62,
    "out:El bought/kWh/m2": 3070.4,
    "out:Return %": 22,
    "out:Return/kSEK/y": 1242,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 1479000,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 73950,
    "out:EAHP (replacement cost)": 1105400,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 25.66,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 565730,
    "out:EL kWh savings": -136003,
    "out:Sewage heat recovery cost": 250448,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 283610,
    "out:DH kr savings": 514814,
    "out:El kr savings": -231204,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 318708,
    "out:% savings (space heating)": 51.61,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 54.4,
    "out:Etvv": 0,
    "out:Ef": 42.34,
    "out:heatPumpQuantity": 10,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000101100001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 57.29,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 43.75,
    "out:Electricity (inc PV)": 42.2,
    "out:Total Energy Use Pre PV": 100.75,
    "out:Total Energy Use Post PV": 99.2,
    "out:Primary Energy": 110.84,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 5.27,
    "out:CO2 Operational/m2": 26.29,
    "out:Total CO2/m2": 31.57,
    "out:Total CO2 (tons)": 162.35,
    "out:Klimatpaverkan": -166.89,
    "out:Initial Cost/MSEK": 5.78725,
    "out:Running cost/(Apt SEK y)": 27452,
    "out:Running Cost over RSP/MSEK": 8.987,
    "out:LCP/MSEK": 4.495,
    "out:ROI% old": 25.38,
    "out:Payback discounted": 5,
    "out:Atemp": 5143,
    "out:Umean": 0.67,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 99,
    "out:Energy use kWh/m2": 99,
    "out:Energy savings %": 89.9,
    "out:Op energy cost/MSEK": 64,
    "out:El price/MSEK": 45,
    "out:Heating price/MSEK": 16,
    "out:DH+ DHW/kWh/m2": 57,
    "out:El bought/kWh/m2": 3062.6,
    "out:Return %": 23,
    "out:Return/kSEK/y": 1311,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 1479000,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 73950,
    "out:EAHP (replacement cost)": 1105400,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 25.55,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 591445,
    "out:EL kWh savings": -135237,
    "out:Sewage heat recovery cost": 250448,
    "out:Balancing hydronic system cost": 128574.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 308312,
    "out:DH kr savings": 538215,
    "out:El kr savings": -229903,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 294640,
    "out:% savings (space heating)": 55.26,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 49.84,
    "out:Etvv": 0,
    "out:Ef": 42.2,
    "out:heatPumpQuantity": 10,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000101110000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 65.24,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 44.45,
    "out:Electricity (inc PV)": 33.25,
    "out:Total Energy Use Pre PV": 109.45,
    "out:Total Energy Use Post PV": 98.25,
    "out:Primary Energy": 99.16,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 17.22,
    "out:CO2 Operational/m2": 20.54,
    "out:Total CO2/m2": 37.77,
    "out:Total CO2 (tons)": 194.23,
    "out:Klimatpaverkan": -135.01,
    "out:Initial Cost/MSEK": 6.478,
    "out:Running cost/(Apt SEK y)": 27926,
    "out:Running Cost over RSP/MSEK": 9.162,
    "out:LCP/MSEK": 3.629,
    "out:ROI% old": 22.29,
    "out:Payback discounted": 6,
    "out:Atemp": 5143,
    "out:Umean": 0.67,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 98,
    "out:Energy use kWh/m2": 98,
    "out:Energy savings %": 91.8,
    "out:Op energy cost/MSEK": 66,
    "out:El price/MSEK": 45,
    "out:Heating price/MSEK": 19,
    "out:DH+ DHW/kWh/m2": 65,
    "out:El bought/kWh/m2": 2462.8,
    "out:Return %": 20,
    "out:Return/kSEK/y": 1291,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 1479000,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 73950,
    "out:EAHP (replacement cost)": 1105400,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 26.25,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 61.265983,
    "out:PV (m2 panels)": 321,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 57484.06,
    "out:PV (kWh sold)": 7879.86,
    "out:PV (% sold (El))": 13.71,
    "out:PV (kWh self-consumed)": 49604.2,
    "out:PV (ratio sold/self-consumed)": 0.16,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 550301,
    "out:EL kWh savings": -89212,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 359160,
    "out:DH kr savings": 500774,
    "out:El kr savings": -151660,
    "out:El kr sold": 10047,
    "out:El kr saved": 84327,
    "out:El kr return": 94374,
    "out:% solar/total": 61,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 335527,
    "out:% savings (space heating)": 49.05,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 56.17,
    "out:Etvv": 0,
    "out:Ef": 33.25,
    "out:heatPumpQuantity": 10,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000101110000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 60.52,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 44.36,
    "out:Electricity (inc PV)": 33.12,
    "out:Total Energy Use Pre PV": 105.36,
    "out:Total Energy Use Post PV": 94.12,
    "out:Primary Energy": 95.72,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 17.22,
    "out:CO2 Operational/m2": 19.04,
    "out:Total CO2/m2": 36.27,
    "out:Total CO2 (tons)": 186.52,
    "out:Klimatpaverkan": -142.72,
    "out:Initial Cost/MSEK": 6.606625,
    "out:Running cost/(Apt SEK y)": 26618,
    "out:Running Cost over RSP/MSEK": 8.733,
    "out:LCP/MSEK": 3.93,
    "out:ROI% old": 22.78,
    "out:Payback discounted": 5,
    "out:Atemp": 5143,
    "out:Umean": 0.67,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 94,
    "out:Energy use kWh/m2": 94,
    "out:Energy savings %": 100,
    "out:Op energy cost/MSEK": 63,
    "out:El price/MSEK": 43,
    "out:Heating price/MSEK": 17,
    "out:DH+ DHW/kWh/m2": 61,
    "out:El bought/kWh/m2": 2456.4,
    "out:Return %": 20,
    "out:Return/kSEK/y": 1346,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 1479000,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 73950,
    "out:EAHP (replacement cost)": 1105400,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 26.16,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 61.265983,
    "out:PV (m2 panels)": 321,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 57484.06,
    "out:PV (kWh sold)": 7928.73,
    "out:PV (% sold (El))": 13.79,
    "out:PV (kWh self-consumed)": 49555.33,
    "out:PV (ratio sold/self-consumed)": 0.16,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 570873,
    "out:EL kWh savings": -88566,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 128574.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 379042,
    "out:DH kr savings": 519494,
    "out:El kr savings": -150562,
    "out:El kr sold": 10109,
    "out:El kr saved": 84244,
    "out:El kr return": 94353,
    "out:% solar/total": 61,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 311238,
    "out:% savings (space heating)": 52.74,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 51.58,
    "out:Etvv": 0,
    "out:Ef": 33.12,
    "out:heatPumpQuantity": 10,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000101110001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 61.97,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 43.86,
    "out:Electricity (inc PV)": 32.91,
    "out:Total Energy Use Pre PV": 105.86,
    "out:Total Energy Use Post PV": 94.91,
    "out:Primary Energy": 97.32,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 17.62,
    "out:CO2 Operational/m2": 19.1,
    "out:Total CO2/m2": 36.73,
    "out:Total CO2 (tons)": 188.9,
    "out:Klimatpaverkan": -140.34,
    "out:Initial Cost/MSEK": 6.7285,
    "out:Running cost/(Apt SEK y)": 26840,
    "out:Running Cost over RSP/MSEK": 8.806,
    "out:LCP/MSEK": 3.735,
    "out:ROI% old": 22.22,
    "out:Payback discounted": 6,
    "out:Atemp": 5143,
    "out:Umean": 0.67,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 95,
    "out:Energy use kWh/m2": 95,
    "out:Energy savings %": 97.9,
    "out:Op energy cost/MSEK": 64,
    "out:El price/MSEK": 43,
    "out:Heating price/MSEK": 18,
    "out:DH+ DHW/kWh/m2": 62,
    "out:El bought/kWh/m2": 2424.9,
    "out:Return %": 20,
    "out:Return/kSEK/y": 1337,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 1479000,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 73950,
    "out:EAHP (replacement cost)": 1105400,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 25.66,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 61.265983,
    "out:PV (m2 panels)": 321,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 57484.06,
    "out:PV (kWh sold)": 8176.13,
    "out:PV (% sold (El))": 14.22,
    "out:PV (kWh self-consumed)": 49307.93,
    "out:PV (ratio sold/self-consumed)": 0.17,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 565730,
    "out:EL kWh savings": -87484,
    "out:Sewage heat recovery cost": 250448,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 376516,
    "out:DH kr savings": 514814,
    "out:El kr savings": -148723,
    "out:El kr sold": 10425,
    "out:El kr saved": 83823,
    "out:El kr return": 94248,
    "out:% solar/total": 60,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 318708,
    "out:% savings (space heating)": 51.61,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 54.4,
    "out:Etvv": 0,
    "out:Ef": 32.91,
    "out:heatPumpQuantity": 10,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000101110001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 57.29,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 43.75,
    "out:Electricity (inc PV)": 32.78,
    "out:Total Energy Use Pre PV": 100.75,
    "out:Total Energy Use Post PV": 89.78,
    "out:Primary Energy": 93.89,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 17.62,
    "out:CO2 Operational/m2": 17.23,
    "out:Total CO2/m2": 34.86,
    "out:Total CO2 (tons)": 179.28,
    "out:Klimatpaverkan": -149.96,
    "out:Initial Cost/MSEK": 6.857125,
    "out:Running cost/(Apt SEK y)": 25208,
    "out:Running Cost over RSP/MSEK": 8.27,
    "out:LCP/MSEK": 4.142,
    "out:ROI% old": 22.92,
    "out:Payback discounted": 5,
    "out:Atemp": 5143,
    "out:Umean": 0.67,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 90,
    "out:Energy use kWh/m2": 90,
    "out:Energy savings %": 108.9,
    "out:Op energy cost/MSEK": 60,
    "out:El price/MSEK": 41,
    "out:Heating price/MSEK": 16,
    "out:DH+ DHW/kWh/m2": 57,
    "out:El bought/kWh/m2": 2417.8,
    "out:Return %": 20,
    "out:Return/kSEK/y": 1405,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 1479000,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 73950,
    "out:EAHP (replacement cost)": 1105400,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 25.55,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 61.265983,
    "out:PV (m2 panels)": 321,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 57484.06,
    "out:PV (kWh sold)": 8233.05,
    "out:PV (% sold (El))": 14.32,
    "out:PV (kWh self-consumed)": 49251,
    "out:PV (ratio sold/self-consumed)": 0.17,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 591445,
    "out:EL kWh savings": -86798,
    "out:Sewage heat recovery cost": 250448,
    "out:Balancing hydronic system cost": 128574.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 401155,
    "out:DH kr savings": 538215,
    "out:El kr savings": -147557,
    "out:El kr sold": 10497,
    "out:El kr saved": 83727,
    "out:El kr return": 94224,
    "out:% solar/total": 60,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 294640,
    "out:% savings (space heating)": 55.26,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 49.84,
    "out:Etvv": 0,
    "out:Ef": 32.78,
    "out:heatPumpQuantity": 10,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000101120000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 65.24,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 44.45,
    "out:Electricity (inc PV)": 30.04,
    "out:Total Energy Use Pre PV": 109.45,
    "out:Total Energy Use Post PV": 95.04,
    "out:Primary Energy": 93.39,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 29.58,
    "out:CO2 Operational/m2": -19.38,
    "out:Total CO2/m2": 10.2,
    "out:Total CO2 (tons)": 52.44,
    "out:Klimatpaverkan": -276.8,
    "out:Initial Cost/MSEK": 7.547875,
    "out:Running cost/(Apt SEK y)": 26006,
    "out:Running Cost over RSP/MSEK": 8.548,
    "out:LCP/MSEK": 3.173,
    "out:ROI% old": 20.29,
    "out:Payback discounted": 6,
    "out:Atemp": 5143,
    "out:Umean": 0.67,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 95,
    "out:Energy use kWh/m2": 95,
    "out:Energy savings %": 97.9,
    "out:Op energy cost/MSEK": 62,
    "out:El price/MSEK": 41,
    "out:Heating price/MSEK": 19,
    "out:DH+ DHW/kWh/m2": 65,
    "out:El bought/kWh/m2": 2222.7,
    "out:Return %": 18,
    "out:Return/kSEK/y": 1372,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 1479000,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 73950,
    "out:EAHP (replacement cost)": 1105400,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 26.25,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.33,
    "out:PV (kWp installed)": 122.531965,
    "out:PV (m2 panels)": 643,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 114968.11,
    "out:PV (kWh sold)": 48051.75,
    "out:PV (% sold (El))": 41.8,
    "out:PV (kWh self-consumed)": 66916.36,
    "out:PV (ratio sold/self-consumed)": 0.72,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 550301,
    "out:EL kWh savings": -72737,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 438387,
    "out:DH kr savings": 500774,
    "out:El kr savings": -123653,
    "out:El kr sold": 61266,
    "out:El kr saved": 113758,
    "out:El kr return": 175024,
    "out:% solar/total": 82,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 335527,
    "out:% savings (space heating)": 49.05,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 56.17,
    "out:Etvv": 0,
    "out:Ef": 30.04,
    "out:heatPumpQuantity": 10,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000101120000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 60.52,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 44.36,
    "out:Electricity (inc PV)": 29.93,
    "out:Total Energy Use Pre PV": 105.36,
    "out:Total Energy Use Post PV": 90.93,
    "out:Primary Energy": 89.98,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 29.58,
    "out:CO2 Operational/m2": -20.93,
    "out:Total CO2/m2": 8.65,
    "out:Total CO2 (tons)": 44.48,
    "out:Klimatpaverkan": -284.76,
    "out:Initial Cost/MSEK": 7.676375,
    "out:Running cost/(Apt SEK y)": 24699,
    "out:Running Cost over RSP/MSEK": 8.119,
    "out:LCP/MSEK": 3.474,
    "out:ROI% old": 20.75,
    "out:Payback discounted": 6,
    "out:Atemp": 5143,
    "out:Umean": 0.67,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 91,
    "out:Energy use kWh/m2": 91,
    "out:Energy savings %": 106.6,
    "out:Op energy cost/MSEK": 59,
    "out:El price/MSEK": 39,
    "out:Heating price/MSEK": 17,
    "out:DH+ DHW/kWh/m2": 61,
    "out:El bought/kWh/m2": 2217.1,
    "out:Return %": 19,
    "out:Return/kSEK/y": 1427,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 1479000,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 73950,
    "out:EAHP (replacement cost)": 1105400,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 26.16,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.33,
    "out:PV (kWp installed)": 122.531965,
    "out:PV (m2 panels)": 643,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 114968.11,
    "out:PV (kWh sold)": 48148.83,
    "out:PV (% sold (El))": 41.88,
    "out:PV (kWh self-consumed)": 66819.28,
    "out:PV (ratio sold/self-consumed)": 0.72,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 570873,
    "out:EL kWh savings": -72158,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 128574.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 458215,
    "out:DH kr savings": 519494,
    "out:El kr savings": -122669,
    "out:El kr sold": 61390,
    "out:El kr saved": 113593,
    "out:El kr return": 174983,
    "out:% solar/total": 82,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 311238,
    "out:% savings (space heating)": 52.74,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 51.58,
    "out:Etvv": 0,
    "out:Ef": 29.93,
    "out:heatPumpQuantity": 10,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000101120001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 61.97,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 43.86,
    "out:Electricity (inc PV)": 29.74,
    "out:Total Energy Use Pre PV": 105.86,
    "out:Total Energy Use Post PV": 91.74,
    "out:Primary Energy": 91.62,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 29.98,
    "out:CO2 Operational/m2": -21.1,
    "out:Total CO2/m2": 8.87,
    "out:Total CO2 (tons)": 45.64,
    "out:Klimatpaverkan": -283.6,
    "out:Initial Cost/MSEK": 7.79825,
    "out:Running cost/(Apt SEK y)": 24923,
    "out:Running Cost over RSP/MSEK": 8.193,
    "out:LCP/MSEK": 3.278,
    "out:ROI% old": 20.29,
    "out:Payback discounted": 6,
    "out:Atemp": 5143,
    "out:Umean": 0.67,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 92,
    "out:Energy use kWh/m2": 92,
    "out:Energy savings %": 104.3,
    "out:Op energy cost/MSEK": 60,
    "out:El price/MSEK": 39,
    "out:Heating price/MSEK": 18,
    "out:DH+ DHW/kWh/m2": 62,
    "out:El bought/kWh/m2": 2189,
    "out:Return %": 18,
    "out:Return/kSEK/y": 1417,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 1479000,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 73950,
    "out:EAHP (replacement cost)": 1105400,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 25.66,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.33,
    "out:PV (kWp installed)": 122.531965,
    "out:PV (m2 panels)": 643,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 114968.11,
    "out:PV (kWh sold)": 48634.13,
    "out:PV (% sold (El))": 42.3,
    "out:PV (kWh self-consumed)": 66333.98,
    "out:PV (ratio sold/self-consumed)": 0.73,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 565730,
    "out:EL kWh savings": -71189,
    "out:Sewage heat recovery cost": 250448,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 455801,
    "out:DH kr savings": 514814,
    "out:El kr savings": -121022,
    "out:El kr sold": 62009,
    "out:El kr saved": 112768,
    "out:El kr return": 174776,
    "out:% solar/total": 81,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 318708,
    "out:% savings (space heating)": 51.61,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 54.4,
    "out:Etvv": 0,
    "out:Ef": 29.74,
    "out:heatPumpQuantity": 10,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000101120001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 57.29,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 43.75,
    "out:Electricity (inc PV)": 29.62,
    "out:Total Energy Use Pre PV": 100.75,
    "out:Total Energy Use Post PV": 86.62,
    "out:Primary Energy": 88.21,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 29.98,
    "out:CO2 Operational/m2": -23.03,
    "out:Total CO2/m2": 6.95,
    "out:Total CO2 (tons)": 35.75,
    "out:Klimatpaverkan": -293.49,
    "out:Initial Cost/MSEK": 7.926875,
    "out:Running cost/(Apt SEK y)": 23292,
    "out:Running Cost over RSP/MSEK": 7.657,
    "out:LCP/MSEK": 3.686,
    "out:ROI% old": 20.93,
    "out:Payback discounted": 6,
    "out:Atemp": 5143,
    "out:Umean": 0.67,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 87,
    "out:Energy use kWh/m2": 87,
    "out:Energy savings %": 116.1,
    "out:Op energy cost/MSEK": 56,
    "out:El price/MSEK": 37,
    "out:Heating price/MSEK": 16,
    "out:DH+ DHW/kWh/m2": 57,
    "out:El bought/kWh/m2": 2182.6,
    "out:Return %": 19,
    "out:Return/kSEK/y": 1486,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 1479000,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 73950,
    "out:EAHP (replacement cost)": 1105400,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 25.55,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.33,
    "out:PV (kWp installed)": 122.531965,
    "out:PV (m2 panels)": 643,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 114968.11,
    "out:PV (kWh sold)": 48745.16,
    "out:PV (% sold (El))": 42.4,
    "out:PV (kWh self-consumed)": 66222.95,
    "out:PV (ratio sold/self-consumed)": 0.74,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 591445,
    "out:EL kWh savings": -70575,
    "out:Sewage heat recovery cost": 250448,
    "out:Balancing hydronic system cost": 128574.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 480388,
    "out:DH kr savings": 538215,
    "out:El kr savings": -119977,
    "out:El kr sold": 62150,
    "out:El kr saved": 112579,
    "out:El kr return": 174729,
    "out:% solar/total": 81,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 294640,
    "out:% savings (space heating)": 55.26,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 49.84,
    "out:Etvv": 0,
    "out:Ef": 29.62,
    "out:heatPumpQuantity": 10,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000102000000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 18.4,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 61.28,
    "out:Electricity (inc PV)": 57.51,
    "out:Total Energy Use Pre PV": 79.28,
    "out:Total Energy Use Post PV": 75.51,
    "out:Primary Energy": 112.17,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 5.43,
    "out:CO2 Operational/m2": 14.57,
    "out:Total CO2/m2": 20,
    "out:Total CO2 (tons)": 102.86,
    "out:Klimatpaverkan": -226.38,
    "out:Initial Cost/MSEK": 7.949375,
    "out:Running cost/(Apt SEK y)": 18550,
    "out:Running Cost over RSP/MSEK": 6.046,
    "out:LCP/MSEK": 5.273,
    "out:ROI% old": 23.76,
    "out:Payback discounted": 5,
    "out:Atemp": 5143,
    "out:Umean": 0.67,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 76,
    "out:Energy use kWh/m2": 76,
    "out:Energy savings %": 147.4,
    "out:Op energy cost/MSEK": 41,
    "out:El price/MSEK": 35,
    "out:Heating price/MSEK": 5,
    "out:DH+ DHW/kWh/m2": 18,
    "out:El bought/kWh/m2": 4289.9,
    "out:Return %": 21,
    "out:Return/kSEK/y": 1685,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 2958000,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 147900,
    "out:EAHP (replacement cost)": 2204400,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 43.08,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 792022,
    "out:EL kWh savings": -213997,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 356944,
    "out:DH kr savings": 720740,
    "out:El kr savings": -363796,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 94629,
    "out:% savings (space heating)": 85.63,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 12.36,
    "out:Etvv": 0,
    "out:Ef": 57.51,
    "out:heatPumpQuantity": 20,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000102000000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 15.13,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 60.75,
    "out:Electricity (inc PV)": 56.81,
    "out:Total Energy Use Pre PV": 75.75,
    "out:Total Energy Use Post PV": 71.81,
    "out:Primary Energy": 108.99,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 5.43,
    "out:CO2 Operational/m2": 13.42,
    "out:Total CO2/m2": 18.85,
    "out:Total CO2 (tons)": 96.94,
    "out:Klimatpaverkan": -232.3,
    "out:Initial Cost/MSEK": 8.078,
    "out:Running cost/(Apt SEK y)": 17473,
    "out:Running Cost over RSP/MSEK": 5.693,
    "out:LCP/MSEK": 5.498,
    "out:ROI% old": 24.01,
    "out:Payback discounted": 5,
    "out:Atemp": 5143,
    "out:Umean": 0.67,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 72,
    "out:Energy use kWh/m2": 72,
    "out:Energy savings %": 161.1,
    "out:Op energy cost/MSEK": 39,
    "out:El price/MSEK": 34,
    "out:Heating price/MSEK": 4,
    "out:DH+ DHW/kWh/m2": 15,
    "out:El bought/kWh/m2": 4252.2,
    "out:Return %": 21,
    "out:Return/kSEK/y": 1730,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 2958000,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 147900,
    "out:EAHP (replacement cost)": 2204400,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 42.55,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 807451,
    "out:EL kWh savings": -210392,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 128574.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 377114,
    "out:DH kr savings": 734780,
    "out:El kr savings": -357667,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 77793,
    "out:% savings (space heating)": 88.19,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 9.62,
    "out:Etvv": 0,
    "out:Ef": 56.81,
    "out:heatPumpQuantity": 20,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000102000001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 16.13,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 59.99,
    "out:Electricity (inc PV)": 56.7,
    "out:Total Energy Use Pre PV": 75.99,
    "out:Total Energy Use Post PV": 72.7,
    "out:Primary Energy": 109.96,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 5.83,
    "out:CO2 Operational/m2": 13.68,
    "out:Total CO2/m2": 19.51,
    "out:Total CO2 (tons)": 100.35,
    "out:Klimatpaverkan": -228.89,
    "out:Initial Cost/MSEK": 8.199875,
    "out:Running cost/(Apt SEK y)": 17638,
    "out:Running Cost over RSP/MSEK": 5.748,
    "out:LCP/MSEK": 5.322,
    "out:ROI% old": 23.56,
    "out:Payback discounted": 5,
    "out:Atemp": 5143,
    "out:Umean": 0.67,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 73,
    "out:Energy use kWh/m2": 73,
    "out:Energy savings %": 157.5,
    "out:Op energy cost/MSEK": 39,
    "out:El price/MSEK": 34,
    "out:Heating price/MSEK": 5,
    "out:DH+ DHW/kWh/m2": 16,
    "out:El bought/kWh/m2": 4199.6,
    "out:Return %": 21,
    "out:Return/kSEK/y": 1723,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 2958000,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 147900,
    "out:EAHP (replacement cost)": 2204400,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 41.79,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 802308,
    "out:EL kWh savings": -209815,
    "out:Sewage heat recovery cost": 250448,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 373415,
    "out:DH kr savings": 730100,
    "out:El kr savings": -356685,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 82968,
    "out:% savings (space heating)": 87.4,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 11.29,
    "out:Etvv": 0,
    "out:Ef": 56.7,
    "out:heatPumpQuantity": 20,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000102000001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 13.02,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 59.41,
    "out:Electricity (inc PV)": 55.96,
    "out:Total Energy Use Pre PV": 72.41,
    "out:Total Energy Use Post PV": 68.96,
    "out:Primary Energy": 106.8,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 5.83,
    "out:CO2 Operational/m2": 12.52,
    "out:Total CO2/m2": 18.35,
    "out:Total CO2 (tons)": 94.4,
    "out:Klimatpaverkan": -234.84,
    "out:Initial Cost/MSEK": 8.328375,
    "out:Running cost/(Apt SEK y)": 16550,
    "out:Running Cost over RSP/MSEK": 5.39,
    "out:LCP/MSEK": 5.55,
    "out:ROI% old": 23.81,
    "out:Payback discounted": 5,
    "out:Atemp": 5143,
    "out:Umean": 0.67,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 69,
    "out:Energy use kWh/m2": 69,
    "out:Energy savings %": 172.5,
    "out:Op energy cost/MSEK": 37,
    "out:El price/MSEK": 32,
    "out:Heating price/MSEK": 4,
    "out:DH+ DHW/kWh/m2": 13,
    "out:El bought/kWh/m2": 4158.4,
    "out:Return %": 21,
    "out:Return/kSEK/y": 1769,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 2958000,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 147900,
    "out:EAHP (replacement cost)": 2204400,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 41.21,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 817737,
    "out:EL kWh savings": -206017,
    "out:Sewage heat recovery cost": 250448,
    "out:Balancing hydronic system cost": 128574.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 393912,
    "out:DH kr savings": 744141,
    "out:El kr savings": -350228,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 66987,
    "out:% savings (space heating)": 89.83,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 8.68,
    "out:Etvv": 0,
    "out:Ef": 55.96,
    "out:heatPumpQuantity": 20,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000102010000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 18.4,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 61.28,
    "out:Electricity (inc PV)": 46.91,
    "out:Total Energy Use Pre PV": 79.28,
    "out:Total Energy Use Post PV": 64.91,
    "out:Primary Energy": 93.09,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 17.78,
    "out:CO2 Operational/m2": 11.34,
    "out:Total CO2/m2": 29.12,
    "out:Total CO2 (tons)": 149.76,
    "out:Klimatpaverkan": -179.48,
    "out:Initial Cost/MSEK": 9.01925,
    "out:Running cost/(Apt SEK y)": 16244,
    "out:Running Cost over RSP/MSEK": 5.31,
    "out:LCP/MSEK": 4.94,
    "out:ROI% old": 22.11,
    "out:Payback discounted": 6,
    "out:Atemp": 5143,
    "out:Umean": 0.67,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 65,
    "out:Energy use kWh/m2": 65,
    "out:Energy savings %": 189.2,
    "out:Op energy cost/MSEK": 37,
    "out:El price/MSEK": 31,
    "out:Heating price/MSEK": 5,
    "out:DH+ DHW/kWh/m2": 18,
    "out:El bought/kWh/m2": 3574.7,
    "out:Return %": 20,
    "out:Return/kSEK/y": 1782,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 2958000,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 147900,
    "out:EAHP (replacement cost)": 2204400,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 43.08,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 61.265983,
    "out:PV (m2 panels)": 321,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 57484.06,
    "out:PV (kWh sold)": 2055.35,
    "out:PV (% sold (El))": 3.58,
    "out:PV (kWh self-consumed)": 55428.71,
    "out:PV (ratio sold/self-consumed)": 0.04,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 792022,
    "out:EL kWh savings": -159497,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 452215,
    "out:DH kr savings": 720740,
    "out:El kr savings": -271145,
    "out:El kr sold": 2621,
    "out:El kr saved": 94229,
    "out:El kr return": 96849,
    "out:% solar/total": 68,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 94629,
    "out:% savings (space heating)": 85.63,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 12.36,
    "out:Etvv": 0,
    "out:Ef": 46.91,
    "out:heatPumpQuantity": 20,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000102010000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 15.13,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 60.75,
    "out:Electricity (inc PV)": 46.25,
    "out:Total Energy Use Pre PV": 75.75,
    "out:Total Energy Use Post PV": 61.25,
    "out:Primary Energy": 89.98,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 17.78,
    "out:CO2 Operational/m2": 10.07,
    "out:Total CO2/m2": 27.86,
    "out:Total CO2 (tons)": 143.28,
    "out:Klimatpaverkan": -185.96,
    "out:Initial Cost/MSEK": 9.14775,
    "out:Running cost/(Apt SEK y)": 15168,
    "out:Running Cost over RSP/MSEK": 4.956,
    "out:LCP/MSEK": 5.165,
    "out:ROI% old": 22.35,
    "out:Payback discounted": 6,
    "out:Atemp": 5143,
    "out:Umean": 0.67,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 61,
    "out:Energy use kWh/m2": 61,
    "out:Energy savings %": 208.2,
    "out:Op energy cost/MSEK": 34,
    "out:El price/MSEK": 29,
    "out:Heating price/MSEK": 4,
    "out:DH+ DHW/kWh/m2": 15,
    "out:El bought/kWh/m2": 3538.3,
    "out:Return %": 20,
    "out:Return/kSEK/y": 1827,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 2958000,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 147900,
    "out:EAHP (replacement cost)": 2204400,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 42.55,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 61.265983,
    "out:PV (m2 panels)": 321,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 57484.06,
    "out:PV (kWh sold)": 2175.79,
    "out:PV (% sold (El))": 3.79,
    "out:PV (kWh self-consumed)": 55308.27,
    "out:PV (ratio sold/self-consumed)": 0.04,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 807451,
    "out:EL kWh savings": -156085,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 128574.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 472209,
    "out:DH kr savings": 734780,
    "out:El kr savings": -265345,
    "out:El kr sold": 2774,
    "out:El kr saved": 94024,
    "out:El kr return": 96798,
    "out:% solar/total": 68,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 77793,
    "out:% savings (space heating)": 88.19,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 9.62,
    "out:Etvv": 0,
    "out:Ef": 46.25,
    "out:heatPumpQuantity": 20,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000102010001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 16.13,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 59.99,
    "out:Electricity (inc PV)": 46.14,
    "out:Total Energy Use Pre PV": 75.99,
    "out:Total Energy Use Post PV": 62.14,
    "out:Primary Energy": 90.96,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 18.19,
    "out:CO2 Operational/m2": 10.17,
    "out:Total CO2/m2": 28.36,
    "out:Total CO2 (tons)": 145.85,
    "out:Klimatpaverkan": -183.39,
    "out:Initial Cost/MSEK": 9.269625,
    "out:Running cost/(Apt SEK y)": 15335,
    "out:Running Cost over RSP/MSEK": 5.012,
    "out:LCP/MSEK": 4.988,
    "out:ROI% old": 21.97,
    "out:Payback discounted": 6,
    "out:Atemp": 5143,
    "out:Umean": 0.67,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 62,
    "out:Energy use kWh/m2": 62,
    "out:Energy savings %": 203.2,
    "out:Op energy cost/MSEK": 35,
    "out:El price/MSEK": 29,
    "out:Heating price/MSEK": 5,
    "out:DH+ DHW/kWh/m2": 16,
    "out:El bought/kWh/m2": 3487.4,
    "out:Return %": 20,
    "out:Return/kSEK/y": 1820,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 2958000,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 147900,
    "out:EAHP (replacement cost)": 2204400,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 41.79,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 61.265983,
    "out:PV (m2 panels)": 321,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 57484.06,
    "out:PV (kWh sold)": 2351.86,
    "out:PV (% sold (El))": 4.09,
    "out:PV (kWh self-consumed)": 55132.2,
    "out:PV (ratio sold/self-consumed)": 0.04,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 802308,
    "out:EL kWh savings": -155540,
    "out:Sewage heat recovery cost": 250448,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 468682,
    "out:DH kr savings": 730100,
    "out:El kr savings": -264417,
    "out:El kr sold": 2999,
    "out:El kr saved": 93725,
    "out:El kr return": 96723,
    "out:% solar/total": 67,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 82968,
    "out:% savings (space heating)": 87.4,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 11.29,
    "out:Etvv": 0,
    "out:Ef": 46.14,
    "out:heatPumpQuantity": 20,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000102010001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 13.02,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 59.41,
    "out:Electricity (inc PV)": 45.45,
    "out:Total Energy Use Pre PV": 72.41,
    "out:Total Energy Use Post PV": 58.45,
    "out:Primary Energy": 87.88,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 18.19,
    "out:CO2 Operational/m2": 8.88,
    "out:Total CO2/m2": 27.07,
    "out:Total CO2 (tons)": 139.22,
    "out:Klimatpaverkan": -190.02,
    "out:Initial Cost/MSEK": 9.39825,
    "out:Running cost/(Apt SEK y)": 14249,
    "out:Running Cost over RSP/MSEK": 4.655,
    "out:LCP/MSEK": 5.216,
    "out:ROI% old": 22.21,
    "out:Payback discounted": 6,
    "out:Atemp": 5143,
    "out:Umean": 0.67,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 58,
    "out:Energy use kWh/m2": 58,
    "out:Energy savings %": 224.1,
    "out:Op energy cost/MSEK": 32,
    "out:El price/MSEK": 28,
    "out:Heating price/MSEK": 4,
    "out:DH+ DHW/kWh/m2": 13,
    "out:El bought/kWh/m2": 3447.7,
    "out:Return %": 20,
    "out:Return/kSEK/y": 1865,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 2958000,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 147900,
    "out:EAHP (replacement cost)": 2204400,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 41.21,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 61.265983,
    "out:PV (m2 panels)": 321,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 57484.06,
    "out:PV (kWh sold)": 2495.09,
    "out:PV (% sold (El))": 4.34,
    "out:PV (kWh self-consumed)": 54988.96,
    "out:PV (ratio sold/self-consumed)": 0.05,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 817737,
    "out:EL kWh savings": -151954,
    "out:Sewage heat recovery cost": 250448,
    "out:Balancing hydronic system cost": 128574.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 489000,
    "out:DH kr savings": 744141,
    "out:El kr savings": -258322,
    "out:El kr sold": 3181,
    "out:El kr saved": 93481,
    "out:El kr return": 96662,
    "out:% solar/total": 67,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 66987,
    "out:% savings (space heating)": 89.83,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 8.68,
    "out:Etvv": 0,
    "out:Ef": 45.45,
    "out:heatPumpQuantity": 20,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000102020000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 18.4,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 61.28,
    "out:Electricity (inc PV)": 42.21,
    "out:Total Energy Use Pre PV": 79.28,
    "out:Total Energy Use Post PV": 60.21,
    "out:Primary Energy": 84.63,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 30.14,
    "out:CO2 Operational/m2": -19.7,
    "out:Total CO2/m2": 10.44,
    "out:Total CO2 (tons)": 53.69,
    "out:Klimatpaverkan": -275.55,
    "out:Initial Cost/MSEK": 10.089,
    "out:Running cost/(Apt SEK y)": 14233,
    "out:Running Cost over RSP/MSEK": 4.666,
    "out:LCP/MSEK": 4.514,
    "out:ROI% old": 20.68,
    "out:Payback discounted": 6,
    "out:Atemp": 5143,
    "out:Umean": 0.67,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 60,
    "out:Energy use kWh/m2": 60,
    "out:Energy savings %": 213.3,
    "out:Op energy cost/MSEK": 33,
    "out:El price/MSEK": 27,
    "out:Heating price/MSEK": 5,
    "out:DH+ DHW/kWh/m2": 18,
    "out:El bought/kWh/m2": 3209.2,
    "out:Return %": 18,
    "out:Return/kSEK/y": 1866,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 2958000,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 147900,
    "out:EAHP (replacement cost)": 2204400,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 43.08,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.33,
    "out:PV (kWp installed)": 122.531965,
    "out:PV (m2 panels)": 643,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 114968.11,
    "out:PV (kWh sold)": 33244.96,
    "out:PV (% sold (El))": 28.92,
    "out:PV (kWh self-consumed)": 81723.15,
    "out:PV (ratio sold/self-consumed)": 0.41,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 792022,
    "out:EL kWh savings": -135331,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 533065,
    "out:DH kr savings": 720740,
    "out:El kr savings": -230063,
    "out:El kr sold": 42387,
    "out:El kr saved": 138929,
    "out:El kr return": 181317,
    "out:% solar/total": 100,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 94629,
    "out:% savings (space heating)": 85.63,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 12.36,
    "out:Etvv": 0,
    "out:Ef": 42.21,
    "out:heatPumpQuantity": 20,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000102020000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 15.13,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 60.75,
    "out:Electricity (inc PV)": 41.63,
    "out:Total Energy Use Pre PV": 75.75,
    "out:Total Energy Use Post PV": 56.63,
    "out:Primary Energy": 81.66,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 30.14,
    "out:CO2 Operational/m2": -21.26,
    "out:Total CO2/m2": 8.88,
    "out:Total CO2 (tons)": 45.65,
    "out:Klimatpaverkan": -283.59,
    "out:Initial Cost/MSEK": 10.2175,
    "out:Running cost/(Apt SEK y)": 13160,
    "out:Running Cost over RSP/MSEK": 4.314,
    "out:LCP/MSEK": 4.738,
    "out:ROI% old": 20.91,
    "out:Payback discounted": 6,
    "out:Atemp": 5143,
    "out:Umean": 0.67,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 57,
    "out:Energy use kWh/m2": 57,
    "out:Energy savings %": 229.8,
    "out:Op energy cost/MSEK": 30,
    "out:El price/MSEK": 25,
    "out:Heating price/MSEK": 4,
    "out:DH+ DHW/kWh/m2": 15,
    "out:El bought/kWh/m2": 3177,
    "out:Return %": 19,
    "out:Return/kSEK/y": 1911,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 2958000,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 147900,
    "out:EAHP (replacement cost)": 2204400,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 42.55,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.33,
    "out:PV (kWp installed)": 122.531965,
    "out:PV (m2 panels)": 643,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 114968.11,
    "out:PV (kWh sold)": 33670.98,
    "out:PV (% sold (El))": 29.29,
    "out:PV (kWh self-consumed)": 81297.13,
    "out:PV (ratio sold/self-consumed)": 0.41,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 807451,
    "out:EL kWh savings": -132309,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 128574.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 552786,
    "out:DH kr savings": 734780,
    "out:El kr savings": -224925,
    "out:El kr sold": 42931,
    "out:El kr saved": 138205,
    "out:El kr return": 181136,
    "out:% solar/total": 99,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 77793,
    "out:% savings (space heating)": 88.19,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 9.62,
    "out:Etvv": 0,
    "out:Ef": 41.63,
    "out:heatPumpQuantity": 20,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000102020001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 16.13,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 59.99,
    "out:Electricity (inc PV)": 41.53,
    "out:Total Energy Use Pre PV": 75.99,
    "out:Total Energy Use Post PV": 57.53,
    "out:Primary Energy": 82.66,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 30.54,
    "out:CO2 Operational/m2": -21.58,
    "out:Total CO2/m2": 8.96,
    "out:Total CO2 (tons)": 46.07,
    "out:Klimatpaverkan": -283.17,
    "out:Initial Cost/MSEK": 10.339375,
    "out:Running cost/(Apt SEK y)": 13331,
    "out:Running Cost over RSP/MSEK": 4.37,
    "out:LCP/MSEK": 4.559,
    "out:ROI% old": 20.59,
    "out:Payback discounted": 6,
    "out:Atemp": 5143,
    "out:Umean": 0.67,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 58,
    "out:Energy use kWh/m2": 58,
    "out:Energy savings %": 224.1,
    "out:Op energy cost/MSEK": 31,
    "out:El price/MSEK": 26,
    "out:Heating price/MSEK": 5,
    "out:DH+ DHW/kWh/m2": 16,
    "out:El bought/kWh/m2": 3131.9,
    "out:Return %": 18,
    "out:Return/kSEK/y": 1904,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 2958000,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 147900,
    "out:EAHP (replacement cost)": 2204400,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 41.79,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.33,
    "out:PV (kWp installed)": 122.531965,
    "out:PV (m2 panels)": 643,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 114968.11,
    "out:PV (kWh sold)": 34270.69,
    "out:PV (% sold (El))": 29.81,
    "out:PV (kWh self-consumed)": 80697.42,
    "out:PV (ratio sold/self-consumed)": 0.42,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 802308,
    "out:EL kWh savings": -131825,
    "out:Sewage heat recovery cost": 250448,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 549693,
    "out:DH kr savings": 730100,
    "out:El kr savings": -224103,
    "out:El kr sold": 43695,
    "out:El kr saved": 137186,
    "out:El kr return": 180881,
    "out:% solar/total": 99,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 82968,
    "out:% savings (space heating)": 87.4,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 11.29,
    "out:Etvv": 0,
    "out:Ef": 41.53,
    "out:heatPumpQuantity": 20,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000102020001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 13.02,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 59.41,
    "out:Electricity (inc PV)": 40.91,
    "out:Total Energy Use Pre PV": 72.41,
    "out:Total Energy Use Post PV": 53.91,
    "out:Primary Energy": 79.72,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 30.54,
    "out:CO2 Operational/m2": -23.2,
    "out:Total CO2/m2": 7.34,
    "out:Total CO2 (tons)": 37.76,
    "out:Klimatpaverkan": -291.48,
    "out:Initial Cost/MSEK": 10.468,
    "out:Running cost/(Apt SEK y)": 12248,
    "out:Running Cost over RSP/MSEK": 4.015,
    "out:LCP/MSEK": 4.786,
    "out:ROI% old": 20.82,
    "out:Payback discounted": 6,
    "out:Atemp": 5143,
    "out:Umean": 0.67,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 54,
    "out:Energy use kWh/m2": 54,
    "out:Energy savings %": 248.1,
    "out:Op energy cost/MSEK": 28,
    "out:El price/MSEK": 24,
    "out:Heating price/MSEK": 4,
    "out:DH+ DHW/kWh/m2": 13,
    "out:El bought/kWh/m2": 3096.8,
    "out:Return %": 19,
    "out:Return/kSEK/y": 1949,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 2958000,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 147900,
    "out:EAHP (replacement cost)": 2204400,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 41.21,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.33,
    "out:PV (kWp installed)": 122.531965,
    "out:PV (m2 panels)": 643,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 114968.11,
    "out:PV (kWh sold)": 34744.25,
    "out:PV (% sold (El))": 30.22,
    "out:PV (kWh self-consumed)": 80223.86,
    "out:PV (ratio sold/self-consumed)": 0.43,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 817737,
    "out:EL kWh savings": -128647,
    "out:Sewage heat recovery cost": 250448,
    "out:Balancing hydronic system cost": 128574.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 569739,
    "out:DH kr savings": 744141,
    "out:El kr savings": -218700,
    "out:El kr sold": 44299,
    "out:El kr saved": 136381,
    "out:El kr return": 180679,
    "out:% solar/total": 98,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 66987,
    "out:% savings (space heating)": 89.83,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 8.68,
    "out:Etvv": 0,
    "out:Ef": 40.91,
    "out:heatPumpQuantity": 20,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000102100000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 15.41,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 59.55,
    "out:Electricity (inc PV)": 56.42,
    "out:Total Energy Use Pre PV": 74.55,
    "out:Total Energy Use Post PV": 71.42,
    "out:Primary Energy": 109.22,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 5.43,
    "out:CO2 Operational/m2": 13.26,
    "out:Total CO2/m2": 18.69,
    "out:Total CO2 (tons)": 96.13,
    "out:Klimatpaverkan": -233.11,
    "out:Initial Cost/MSEK": 8.06025,
    "out:Running cost/(Apt SEK y)": 17225,
    "out:Running Cost over RSP/MSEK": 5.612,
    "out:LCP/MSEK": 5.597,
    "out:ROI% old": 24.21,
    "out:Payback discounted": 5,
    "out:Atemp": 5143,
    "out:Umean": 0.67,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 71,
    "out:Energy use kWh/m2": 71,
    "out:Energy savings %": 164.8,
    "out:Op energy cost/MSEK": 38,
    "out:El price/MSEK": 33,
    "out:Heating price/MSEK": 4,
    "out:DH+ DHW/kWh/m2": 15,
    "out:El bought/kWh/m2": 4168.8,
    "out:Return %": 22,
    "out:Return/kSEK/y": 1740,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 2958000,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 147900,
    "out:EAHP (replacement cost)": 2204400,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 41.36,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 807451,
    "out:EL kWh savings": -208402,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 380498,
    "out:DH kr savings": 734780,
    "out:El kr savings": -354283,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 79238,
    "out:% savings (space heating)": 87.97,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 10.95,
    "out:Etvv": 0,
    "out:Ef": 56.42,
    "out:heatPumpQuantity": 20,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000102100000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 12.36,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 58.95,
    "out:Electricity (inc PV)": 55.67,
    "out:Total Energy Use Pre PV": 70.95,
    "out:Total Energy Use Post PV": 67.67,
    "out:Primary Energy": 106.07,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 5.43,
    "out:CO2 Operational/m2": 12.1,
    "out:Total CO2/m2": 17.53,
    "out:Total CO2 (tons)": 90.17,
    "out:Klimatpaverkan": -239.07,
    "out:Initial Cost/MSEK": 8.188875,
    "out:Running cost/(Apt SEK y)": 16133,
    "out:Running Cost over RSP/MSEK": 5.254,
    "out:LCP/MSEK": 5.826,
    "out:ROI% old": 24.45,
    "out:Payback discounted": 5,
    "out:Atemp": 5143,
    "out:Umean": 0.67,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 68,
    "out:Energy use kWh/m2": 68,
    "out:Energy savings %": 176.5,
    "out:Op energy cost/MSEK": 36,
    "out:El price/MSEK": 32,
    "out:Heating price/MSEK": 3,
    "out:DH+ DHW/kWh/m2": 12,
    "out:El bought/kWh/m2": 4126.3,
    "out:Return %": 22,
    "out:Return/kSEK/y": 1786,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 2958000,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 147900,
    "out:EAHP (replacement cost)": 2204400,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 40.75,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 822880,
    "out:EL kWh savings": -204535,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 128574.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 401111,
    "out:DH kr savings": 748821,
    "out:El kr savings": -347710,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 63581,
    "out:% savings (space heating)": 90.35,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 8.38,
    "out:Etvv": 0,
    "out:Ef": 55.67,
    "out:heatPumpQuantity": 20,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000102100001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 13.74,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 58.48,
    "out:Electricity (inc PV)": 55.76,
    "out:Total Energy Use Pre PV": 72.48,
    "out:Total Energy Use Post PV": 69.76,
    "out:Primary Energy": 107.46,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 5.83,
    "out:CO2 Operational/m2": 12.76,
    "out:Total CO2/m2": 18.59,
    "out:Total CO2 (tons)": 95.62,
    "out:Klimatpaverkan": -233.62,
    "out:Initial Cost/MSEK": 8.31075,
    "out:Running cost/(Apt SEK y)": 16679,
    "out:Running Cost over RSP/MSEK": 5.434,
    "out:LCP/MSEK": 5.525,
    "out:ROI% old": 23.78,
    "out:Payback discounted": 5,
    "out:Atemp": 5143,
    "out:Umean": 0.67,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 70,
    "out:Energy use kWh/m2": 70,
    "out:Energy savings %": 168.6,
    "out:Op energy cost/MSEK": 37,
    "out:El price/MSEK": 32,
    "out:Heating price/MSEK": 4,
    "out:DH+ DHW/kWh/m2": 14,
    "out:El bought/kWh/m2": 4093.6,
    "out:Return %": 21,
    "out:Return/kSEK/y": 1763,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 2958000,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 147900,
    "out:EAHP (replacement cost)": 2204400,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 40.28,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 812594,
    "out:EL kWh savings": -204975,
    "out:Sewage heat recovery cost": 250448,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 391003,
    "out:DH kr savings": 739460,
    "out:El kr savings": -348457,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 70641,
    "out:% savings (space heating)": 89.27,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 10.15,
    "out:Etvv": 0,
    "out:Ef": 55.76,
    "out:heatPumpQuantity": 20,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000102100001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 10.85,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 57.82,
    "out:Electricity (inc PV)": 54.97,
    "out:Total Energy Use Pre PV": 68.82,
    "out:Total Energy Use Post PV": 65.97,
    "out:Primary Energy": 104.33,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 5.83,
    "out:CO2 Operational/m2": 11.59,
    "out:Total CO2/m2": 17.43,
    "out:Total CO2 (tons)": 89.62,
    "out:Klimatpaverkan": -239.62,
    "out:Initial Cost/MSEK": 8.43925,
    "out:Running cost/(Apt SEK y)": 15577,
    "out:Running Cost over RSP/MSEK": 5.072,
    "out:LCP/MSEK": 5.758,
    "out:ROI% old": 24.03,
    "out:Payback discounted": 5,
    "out:Atemp": 5143,
    "out:Umean": 0.67,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 66,
    "out:Energy use kWh/m2": 66,
    "out:Energy savings %": 184.8,
    "out:Op energy cost/MSEK": 34,
    "out:El price/MSEK": 31,
    "out:Heating price/MSEK": 3,
    "out:DH+ DHW/kWh/m2": 11,
    "out:El bought/kWh/m2": 4047.6,
    "out:Return %": 21,
    "out:Return/kSEK/y": 1810,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 2958000,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 147900,
    "out:EAHP (replacement cost)": 2204400,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 39.62,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 828023,
    "out:EL kWh savings": -200938,
    "out:Sewage heat recovery cost": 250448,
    "out:Balancing hydronic system cost": 128574.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 411907,
    "out:DH kr savings": 753501,
    "out:El kr savings": -341594,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 55822,
    "out:% savings (space heating)": 91.52,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 7.69,
    "out:Etvv": 0,
    "out:Ef": 54.97,
    "out:heatPumpQuantity": 20,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000102110000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 15.41,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 59.55,
    "out:Electricity (inc PV)": 45.88,
    "out:Total Energy Use Pre PV": 74.55,
    "out:Total Energy Use Post PV": 60.88,
    "out:Primary Energy": 90.25,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 17.78,
    "out:CO2 Operational/m2": 9.66,
    "out:Total CO2/m2": 27.44,
    "out:Total CO2 (tons)": 141.13,
    "out:Klimatpaverkan": -188.11,
    "out:Initial Cost/MSEK": 9.13,
    "out:Running cost/(Apt SEK y)": 14923,
    "out:Running Cost over RSP/MSEK": 4.877,
    "out:LCP/MSEK": 5.262,
    "out:ROI% old": 22.52,
    "out:Payback discounted": 6,
    "out:Atemp": 5143,
    "out:Umean": 0.67,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 61,
    "out:Energy use kWh/m2": 61,
    "out:Energy savings %": 208.2,
    "out:Op energy cost/MSEK": 34,
    "out:El price/MSEK": 29,
    "out:Heating price/MSEK": 4,
    "out:DH+ DHW/kWh/m2": 15,
    "out:El bought/kWh/m2": 3457.8,
    "out:Return %": 20,
    "out:Return/kSEK/y": 1837,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 2958000,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 147900,
    "out:EAHP (replacement cost)": 2204400,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 41.36,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 61.265983,
    "out:PV (m2 panels)": 321,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 57484.06,
    "out:PV (kWh sold)": 2458.45,
    "out:PV (% sold (El))": 4.28,
    "out:PV (kWh self-consumed)": 55025.6,
    "out:PV (ratio sold/self-consumed)": 0.04,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 807451,
    "out:EL kWh savings": -154205,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 475767,
    "out:DH kr savings": 734780,
    "out:El kr savings": -262148,
    "out:El kr sold": 3135,
    "out:El kr saved": 93544,
    "out:El kr return": 96678,
    "out:% solar/total": 67,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 79238,
    "out:% savings (space heating)": 87.97,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 10.95,
    "out:Etvv": 0,
    "out:Ef": 45.88,
    "out:heatPumpQuantity": 20,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000102110000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 12.36,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 58.95,
    "out:Electricity (inc PV)": 45.17,
    "out:Total Energy Use Pre PV": 70.95,
    "out:Total Energy Use Post PV": 57.17,
    "out:Primary Energy": 87.18,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 17.78,
    "out:CO2 Operational/m2": 8.36,
    "out:Total CO2/m2": 26.14,
    "out:Total CO2 (tons)": 134.44,
    "out:Klimatpaverkan": -194.8,
    "out:Initial Cost/MSEK": 9.258625,
    "out:Running cost/(Apt SEK y)": 13833,
    "out:Running Cost over RSP/MSEK": 4.519,
    "out:LCP/MSEK": 5.492,
    "out:ROI% old": 22.76,
    "out:Payback discounted": 5,
    "out:Atemp": 5143,
    "out:Umean": 0.67,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 57,
    "out:Energy use kWh/m2": 57,
    "out:Energy savings %": 229.8,
    "out:Op energy cost/MSEK": 31,
    "out:El price/MSEK": 27,
    "out:Heating price/MSEK": 3,
    "out:DH+ DHW/kWh/m2": 12,
    "out:El bought/kWh/m2": 3416.8,
    "out:Return %": 20,
    "out:Return/kSEK/y": 1883,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 2958000,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 147900,
    "out:EAHP (replacement cost)": 2204400,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 40.75,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 61.265983,
    "out:PV (m2 panels)": 321,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 57484.06,
    "out:PV (kWh sold)": 2609.36,
    "out:PV (% sold (El))": 4.54,
    "out:PV (kWh self-consumed)": 54874.69,
    "out:PV (ratio sold/self-consumed)": 0.05,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 822880,
    "out:EL kWh savings": -150558,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 128574.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 496199,
    "out:DH kr savings": 748821,
    "out:El kr savings": -255948,
    "out:El kr sold": 3327,
    "out:El kr saved": 93287,
    "out:El kr return": 96614,
    "out:% solar/total": 67,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 63581,
    "out:% savings (space heating)": 90.35,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 8.38,
    "out:Etvv": 0,
    "out:Ef": 45.17,
    "out:heatPumpQuantity": 20,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000102110001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 13.74,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 58.48,
    "out:Electricity (inc PV)": 45.25,
    "out:Total Energy Use Pre PV": 72.48,
    "out:Total Energy Use Post PV": 59.25,
    "out:Primary Energy": 88.56,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 18.19,
    "out:CO2 Operational/m2": 8.9,
    "out:Total CO2/m2": 27.09,
    "out:Total CO2 (tons)": 139.32,
    "out:Klimatpaverkan": -189.92,
    "out:Initial Cost/MSEK": 9.3805,
    "out:Running cost/(Apt SEK y)": 14380,
    "out:Running Cost over RSP/MSEK": 4.699,
    "out:LCP/MSEK": 5.189,
    "out:ROI% old": 22.19,
    "out:Payback discounted": 6,
    "out:Atemp": 5143,
    "out:Umean": 0.67,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 59,
    "out:Energy use kWh/m2": 59,
    "out:Energy savings %": 218.6,
    "out:Op energy cost/MSEK": 33,
    "out:El price/MSEK": 28,
    "out:Heating price/MSEK": 4,
    "out:DH+ DHW/kWh/m2": 14,
    "out:El bought/kWh/m2": 3385.3,
    "out:Return %": 20,
    "out:Return/kSEK/y": 1860,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 2958000,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 147900,
    "out:EAHP (replacement cost)": 2204400,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 40.28,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 61.265983,
    "out:PV (m2 panels)": 321,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 57484.06,
    "out:PV (kWh sold)": 2728.01,
    "out:PV (% sold (El))": 4.75,
    "out:PV (kWh self-consumed)": 54756.04,
    "out:PV (ratio sold/self-consumed)": 0.05,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 812594,
    "out:EL kWh savings": -150972,
    "out:Sewage heat recovery cost": 250448,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 486286,
    "out:DH kr savings": 739460,
    "out:El kr savings": -256653,
    "out:El kr sold": 3478,
    "out:El kr saved": 93085,
    "out:El kr return": 96563,
    "out:% solar/total": 67,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 70641,
    "out:% savings (space heating)": 89.27,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 10.15,
    "out:Etvv": 0,
    "out:Ef": 45.25,
    "out:heatPumpQuantity": 20,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000102110001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 10.85,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 57.82,
    "out:Electricity (inc PV)": 44.52,
    "out:Total Energy Use Pre PV": 68.82,
    "out:Total Energy Use Post PV": 55.52,
    "out:Primary Energy": 85.51,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 18.19,
    "out:CO2 Operational/m2": 7.58,
    "out:Total CO2/m2": 25.76,
    "out:Total CO2 (tons)": 132.49,
    "out:Klimatpaverkan": -196.75,
    "out:Initial Cost/MSEK": 9.509125,
    "out:Running cost/(Apt SEK y)": 13280,
    "out:Running Cost over RSP/MSEK": 4.338,
    "out:LCP/MSEK": 5.422,
    "out:ROI% old": 22.43,
    "out:Payback discounted": 6,
    "out:Atemp": 5143,
    "out:Umean": 0.67,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 56,
    "out:Energy use kWh/m2": 56,
    "out:Energy savings %": 235.7,
    "out:Op energy cost/MSEK": 30,
    "out:El price/MSEK": 26,
    "out:Heating price/MSEK": 3,
    "out:DH+ DHW/kWh/m2": 11,
    "out:El bought/kWh/m2": 3341.2,
    "out:Return %": 20,
    "out:Return/kSEK/y": 1906,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 2958000,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 147900,
    "out:EAHP (replacement cost)": 2204400,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 39.62,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 61.265983,
    "out:PV (m2 panels)": 321,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 57484.06,
    "out:PV (kWh sold)": 2899.57,
    "out:PV (% sold (El))": 5.04,
    "out:PV (kWh self-consumed)": 54584.48,
    "out:PV (ratio sold/self-consumed)": 0.05,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 828023,
    "out:EL kWh savings": -147172,
    "out:Sewage heat recovery cost": 250448,
    "out:Balancing hydronic system cost": 128574.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 507005,
    "out:DH kr savings": 753501,
    "out:El kr savings": -250193,
    "out:El kr sold": 3697,
    "out:El kr saved": 92794,
    "out:El kr return": 96491,
    "out:% solar/total": 67,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 55822,
    "out:% savings (space heating)": 91.52,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 7.69,
    "out:Etvv": 0,
    "out:Ef": 44.52,
    "out:heatPumpQuantity": 20,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000102120000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 15.41,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 59.55,
    "out:Electricity (inc PV)": 41.3,
    "out:Total Energy Use Pre PV": 74.55,
    "out:Total Energy Use Post PV": 56.3,
    "out:Primary Energy": 82.01,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 30.14,
    "out:CO2 Operational/m2": -22.34,
    "out:Total CO2/m2": 7.8,
    "out:Total CO2 (tons)": 40.09,
    "out:Klimatpaverkan": -289.15,
    "out:Initial Cost/MSEK": 10.199875,
    "out:Running cost/(Apt SEK y)": 12922,
    "out:Running Cost over RSP/MSEK": 4.236,
    "out:LCP/MSEK": 4.833,
    "out:ROI% old": 21.05,
    "out:Payback discounted": 6,
    "out:Atemp": 5143,
    "out:Umean": 0.67,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 56,
    "out:Energy use kWh/m2": 56,
    "out:Energy savings %": 235.7,
    "out:Op energy cost/MSEK": 30,
    "out:El price/MSEK": 25,
    "out:Heating price/MSEK": 4,
    "out:DH+ DHW/kWh/m2": 15,
    "out:El bought/kWh/m2": 3105.7,
    "out:Return %": 19,
    "out:Return/kSEK/y": 1921,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 2958000,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 147900,
    "out:EAHP (replacement cost)": 2204400,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 41.36,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.33,
    "out:PV (kWp installed)": 122.531965,
    "out:PV (m2 panels)": 643,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 114968.11,
    "out:PV (kWh sold)": 34623.67,
    "out:PV (% sold (El))": 30.12,
    "out:PV (kWh self-consumed)": 80344.44,
    "out:PV (ratio sold/self-consumed)": 0.43,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 807451,
    "out:EL kWh savings": -130642,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 556834,
    "out:DH kr savings": 734780,
    "out:El kr savings": -222092,
    "out:El kr sold": 44145,
    "out:El kr saved": 136586,
    "out:El kr return": 180731,
    "out:% solar/total": 98,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 79238,
    "out:% savings (space heating)": 87.97,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 10.95,
    "out:Etvv": 0,
    "out:Ef": 41.3,
    "out:heatPumpQuantity": 20,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000102120000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 12.36,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 58.95,
    "out:Electricity (inc PV)": 40.67,
    "out:Total Energy Use Pre PV": 70.95,
    "out:Total Energy Use Post PV": 52.67,
    "out:Primary Energy": 79.08,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 30.14,
    "out:CO2 Operational/m2": -23.98,
    "out:Total CO2/m2": 6.16,
    "out:Total CO2 (tons)": 31.67,
    "out:Klimatpaverkan": -297.57,
    "out:Initial Cost/MSEK": 10.328375,
    "out:Running cost/(Apt SEK y)": 11835,
    "out:Running Cost over RSP/MSEK": 3.879,
    "out:LCP/MSEK": 5.061,
    "out:ROI% old": 21.29,
    "out:Payback discounted": 6,
    "out:Atemp": 5143,
    "out:Umean": 0.67,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 53,
    "out:Energy use kWh/m2": 53,
    "out:Energy savings %": 254.7,
    "out:Op energy cost/MSEK": 27,
    "out:El price/MSEK": 23,
    "out:Heating price/MSEK": 3,
    "out:DH+ DHW/kWh/m2": 12,
    "out:El bought/kWh/m2": 3069.5,
    "out:Return %": 19,
    "out:Return/kSEK/y": 1967,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 2958000,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 147900,
    "out:EAHP (replacement cost)": 2204400,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 40.75,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.33,
    "out:PV (kWp installed)": 122.531965,
    "out:PV (m2 panels)": 643,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 114968.11,
    "out:PV (kWh sold)": 35117.82,
    "out:PV (% sold (El))": 30.55,
    "out:PV (kWh self-consumed)": 79850.29,
    "out:PV (ratio sold/self-consumed)": 0.44,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 822880,
    "out:EL kWh savings": -127409,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 128574.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 577001,
    "out:DH kr savings": 748821,
    "out:El kr savings": -216595,
    "out:El kr sold": 44775,
    "out:El kr saved": 135745,
    "out:El kr return": 180521,
    "out:% solar/total": 98,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 63581,
    "out:% savings (space heating)": 90.35,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 8.38,
    "out:Etvv": 0,
    "out:Ef": 40.67,
    "out:heatPumpQuantity": 20,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000102120001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 13.74,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 58.48,
    "out:Electricity (inc PV)": 40.74,
    "out:Total Energy Use Pre PV": 72.48,
    "out:Total Energy Use Post PV": 54.74,
    "out:Primary Energy": 80.44,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 30.54,
    "out:CO2 Operational/m2": -23.69,
    "out:Total CO2/m2": 6.85,
    "out:Total CO2 (tons)": 35.22,
    "out:Klimatpaverkan": -294.02,
    "out:Initial Cost/MSEK": 10.45025,
    "out:Running cost/(Apt SEK y)": 12385,
    "out:Running Cost over RSP/MSEK": 4.061,
    "out:LCP/MSEK": 4.758,
    "out:ROI% old": 20.79,
    "out:Payback discounted": 6,
    "out:Atemp": 5143,
    "out:Umean": 0.67,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 55,
    "out:Energy use kWh/m2": 55,
    "out:Energy savings %": 241.8,
    "out:Op energy cost/MSEK": 29,
    "out:El price/MSEK": 24,
    "out:Heating price/MSEK": 4,
    "out:DH+ DHW/kWh/m2": 14,
    "out:El bought/kWh/m2": 3041.6,
    "out:Return %": 19,
    "out:Return/kSEK/y": 1944,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 2958000,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 147900,
    "out:EAHP (replacement cost)": 2204400,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 40.28,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.33,
    "out:PV (kWp installed)": 122.531965,
    "out:PV (m2 panels)": 643,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 114968.11,
    "out:PV (kWh sold)": 35500.81,
    "out:PV (% sold (El))": 30.88,
    "out:PV (kWh self-consumed)": 79467.31,
    "out:PV (ratio sold/self-consumed)": 0.45,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 812594,
    "out:EL kWh savings": -127776,
    "out:Sewage heat recovery cost": 250448,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 567504,
    "out:DH kr savings": 739460,
    "out:El kr savings": -217220,
    "out:El kr sold": 45264,
    "out:El kr saved": 135094,
    "out:El kr return": 180358,
    "out:% solar/total": 97,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 70641,
    "out:% savings (space heating)": 89.27,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 10.15,
    "out:Etvv": 0,
    "out:Ef": 40.74,
    "out:heatPumpQuantity": 20,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000102120001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 10.85,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 57.82,
    "out:Electricity (inc PV)": 40.09,
    "out:Total Energy Use Pre PV": 68.82,
    "out:Total Energy Use Post PV": 51.09,
    "out:Primary Energy": 77.54,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 30.54,
    "out:CO2 Operational/m2": -25.38,
    "out:Total CO2/m2": 5.16,
    "out:Total CO2 (tons)": 26.52,
    "out:Klimatpaverkan": -302.72,
    "out:Initial Cost/MSEK": 10.578875,
    "out:Running cost/(Apt SEK y)": 11288,
    "out:Running Cost over RSP/MSEK": 3.701,
    "out:LCP/MSEK": 4.99,
    "out:ROI% old": 21.02,
    "out:Payback discounted": 6,
    "out:Atemp": 5143,
    "out:Umean": 0.67,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 51,
    "out:Energy use kWh/m2": 51,
    "out:Energy savings %": 268.6,
    "out:Op energy cost/MSEK": 26,
    "out:El price/MSEK": 22,
    "out:Heating price/MSEK": 3,
    "out:DH+ DHW/kWh/m2": 11,
    "out:El bought/kWh/m2": 3002.6,
    "out:Return %": 19,
    "out:Return/kSEK/y": 1990,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 2958000,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 147900,
    "out:EAHP (replacement cost)": 2204400,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 39.62,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.33,
    "out:PV (kWp installed)": 122.531965,
    "out:PV (m2 panels)": 643,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 114968.11,
    "out:PV (kWh sold)": 36042.19,
    "out:PV (% sold (El))": 31.35,
    "out:PV (kWh self-consumed)": 78925.92,
    "out:PV (ratio sold/self-consumed)": 0.46,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 828023,
    "out:EL kWh savings": -124405,
    "out:Sewage heat recovery cost": 250448,
    "out:Balancing hydronic system cost": 128574.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 587966,
    "out:DH kr savings": 753501,
    "out:El kr savings": -211489,
    "out:El kr sold": 45954,
    "out:El kr saved": 134174,
    "out:El kr return": 180128,
    "out:% solar/total": 97,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 55822,
    "out:% savings (space heating)": 91.52,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 7.69,
    "out:Etvv": 0,
    "out:Ef": 40.09,
    "out:heatPumpQuantity": 20,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000200000000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 113.81,
    "out:DHW (DH)": 43.5,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 15.9,
    "out:Total Energy Use Pre PV": 172.9,
    "out:Total Energy Use Post PV": 172.9,
    "out:Primary Energy": 125.78,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 7.98,
    "out:CO2 Operational/m2": 58.62,
    "out:Total CO2/m2": 66.6,
    "out:Total CO2 (tons)": 342.52,
    "out:Klimatpaverkan": 13.28,
    "out:Initial Cost/MSEK": 2.833,
    "out:Running cost/(Apt SEK y)": 53837,
    "out:Running Cost over RSP/MSEK": 17.682,
    "out:LCP/MSEK": -1.246,
    "out:ROI% old": 8,
    "out:Payback discounted": 15,
    "out:Atemp": 5143,
    "out:Umean": 0.62,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "F",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 173,
    "out:Energy use kWh/m2": 173,
    "out:Energy savings %": 8.7,
    "out:Op energy cost/MSEK": 130,
    "out:El price/MSEK": 77,
    "out:Heating price/MSEK": 45,
    "out:DH+ DHW/kWh/m2": 157,
    "out:El bought/kWh/m2": 1113,
    "out:Return %": 7,
    "out:Return/kSEK/y": 203,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 2,
    "out:Payback Time": 50,
    "out:DH kWh savings": 77145,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 70202,
    "out:DH kr savings": 70202,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 585302,
    "out:% savings (space heating)": 11.13,
    "out:Total_Water Heating (DH)": 223720,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 113.81,
    "out:Etvv": 25,
    "out:Ef": 15.9,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z00000200000000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 108.91,
    "out:DHW (DH)": 43.5,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 15.9,
    "out:Total Energy Use Pre PV": 167.9,
    "out:Total Energy Use Post PV": 167.9,
    "out:Primary Energy": 122.35,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 7.98,
    "out:CO2 Operational/m2": 56.82,
    "out:Total CO2/m2": 64.8,
    "out:Total CO2 (tons)": 333.26,
    "out:Klimatpaverkan": 4.02,
    "out:Initial Cost/MSEK": 2.961625,
    "out:Running cost/(Apt SEK y)": 52228,
    "out:Running Cost over RSP/MSEK": 17.153,
    "out:LCP/MSEK": -0.846,
    "out:ROI% old": 10.2,
    "out:Payback discounted": 12,
    "out:Atemp": 5143,
    "out:Umean": 0.62,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "F",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 168,
    "out:Energy use kWh/m2": 168,
    "out:Energy savings %": 11.9,
    "out:Op energy cost/MSEK": 126,
    "out:El price/MSEK": 75,
    "out:Heating price/MSEK": 44,
    "out:DH+ DHW/kWh/m2": 152,
    "out:El bought/kWh/m2": 1113,
    "out:Return %": 9,
    "out:Return/kSEK/y": 270,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 102860,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 128574.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 93603,
    "out:DH kr savings": 93603,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 560100,
    "out:% savings (space heating)": 14.95,
    "out:Total_Water Heating (DH)": 223720,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 108.91,
    "out:Etvv": 25,
    "out:Ef": 15.9,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z00000200000001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 113.81,
    "out:DHW (DH)": 36.98,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 15.9,
    "out:Total Energy Use Pre PV": 166.9,
    "out:Total Energy Use Post PV": 166.9,
    "out:Primary Energy": 123.16,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 8.38,
    "out:CO2 Operational/m2": 56.46,
    "out:Total CO2/m2": 64.84,
    "out:Total CO2 (tons)": 333.48,
    "out:Klimatpaverkan": 4.24,
    "out:Initial Cost/MSEK": 3.0835,
    "out:Running cost/(Apt SEK y)": 51906,
    "out:Running Cost over RSP/MSEK": 17.048,
    "out:LCP/MSEK": -0.862,
    "out:ROI% old": 10.29,
    "out:Payback discounted": 12,
    "out:Atemp": 5143,
    "out:Umean": 0.62,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "F",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 167,
    "out:Energy use kWh/m2": 167,
    "out:Energy savings %": 12.6,
    "out:Op energy cost/MSEK": 125,
    "out:El price/MSEK": 75,
    "out:Heating price/MSEK": 43,
    "out:DH+ DHW/kWh/m2": 151,
    "out:El bought/kWh/m2": 1113,
    "out:Return %": 9,
    "out:Return/kSEK/y": 284,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 108003,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 250448,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 98283,
    "out:DH kr savings": 98283,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 585302,
    "out:% savings (space heating)": 11.13,
    "out:Total_Water Heating (DH)": 190162,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 113.81,
    "out:Etvv": 21.25,
    "out:Ef": 15.9,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z00000200000001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 108.91,
    "out:DHW (DH)": 36.98,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 15.9,
    "out:Total Energy Use Pre PV": 161.9,
    "out:Total Energy Use Post PV": 161.9,
    "out:Primary Energy": 119.73,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 8.38,
    "out:CO2 Operational/m2": 54.66,
    "out:Total CO2/m2": 63.04,
    "out:Total CO2 (tons)": 324.22,
    "out:Klimatpaverkan": -5.02,
    "out:Initial Cost/MSEK": 3.212,
    "out:Running cost/(Apt SEK y)": 50297,
    "out:Running Cost over RSP/MSEK": 16.519,
    "out:LCP/MSEK": -0.462,
    "out:ROI% old": 12.23,
    "out:Payback discounted": 10,
    "out:Atemp": 5143,
    "out:Umean": 0.62,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "F",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 162,
    "out:Energy use kWh/m2": 162,
    "out:Energy savings %": 16,
    "out:Op energy cost/MSEK": 121,
    "out:El price/MSEK": 72,
    "out:Heating price/MSEK": 42,
    "out:DH+ DHW/kWh/m2": 146,
    "out:El bought/kWh/m2": 1113,
    "out:Return %": 11,
    "out:Return/kSEK/y": 351,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 133718,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 250448,
    "out:Balancing hydronic system cost": 128574.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 121683,
    "out:DH kr savings": 121683,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 560100,
    "out:% savings (space heating)": 14.95,
    "out:Total_Water Heating (DH)": 190162,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 108.91,
    "out:Etvv": 21.25,
    "out:Ef": 15.9,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z00000200010000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 113.81,
    "out:DHW (DH)": 43.5,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 10.73,
    "out:Total Energy Use Pre PV": 172.9,
    "out:Total Energy Use Post PV": 167.73,
    "out:Primary Energy": 116.47,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 20.34,
    "out:CO2 Operational/m2": 27.62,
    "out:Total CO2/m2": 47.96,
    "out:Total CO2 (tons)": 246.64,
    "out:Klimatpaverkan": -82.6,
    "out:Initial Cost/MSEK": 3.90275,
    "out:Running cost/(Apt SEK y)": 51823,
    "out:Running Cost over RSP/MSEK": 17.038,
    "out:LCP/MSEK": -1.672,
    "out:ROI% old": 8.16,
    "out:Payback discounted": 15,
    "out:Atemp": 5143,
    "out:Umean": 0.62,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "F",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 168,
    "out:Energy use kWh/m2": 168,
    "out:Energy savings %": 11.9,
    "out:Op energy cost/MSEK": 126,
    "out:El price/MSEK": 73,
    "out:Heating price/MSEK": 45,
    "out:DH+ DHW/kWh/m2": 157,
    "out:El bought/kWh/m2": 758.1,
    "out:Return %": 7,
    "out:Return/kSEK/y": 287,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 61.265983,
    "out:PV (m2 panels)": 321,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 57484.06,
    "out:PV (kWh sold)": 30886.05,
    "out:PV (% sold (El))": 53.73,
    "out:PV (kWh self-consumed)": 26598,
    "out:PV (ratio sold/self-consumed)": 1.16,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 77145,
    "out:EL kWh savings": 26598,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 154798,
    "out:DH kr savings": 70202,
    "out:El kr savings": 45217,
    "out:El kr sold": 39380,
    "out:El kr saved": 45217,
    "out:El kr return": 84596,
    "out:% solar/total": 33,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 585302,
    "out:% savings (space heating)": 11.13,
    "out:Total_Water Heating (DH)": 223720,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 113.81,
    "out:Etvv": 25,
    "out:Ef": 10.73,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z00000200010000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 108.91,
    "out:DHW (DH)": 43.5,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 10.73,
    "out:Total Energy Use Pre PV": 167.9,
    "out:Total Energy Use Post PV": 162.73,
    "out:Primary Energy": 113.04,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 20.34,
    "out:CO2 Operational/m2": 25.82,
    "out:Total CO2/m2": 46.16,
    "out:Total CO2 (tons)": 237.39,
    "out:Klimatpaverkan": -91.85,
    "out:Initial Cost/MSEK": 4.031375,
    "out:Running cost/(Apt SEK y)": 50214,
    "out:Running Cost over RSP/MSEK": 16.51,
    "out:LCP/MSEK": -1.272,
    "out:ROI% old": 9.78,
    "out:Payback discounted": 12,
    "out:Atemp": 5143,
    "out:Umean": 0.62,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "F",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 163,
    "out:Energy use kWh/m2": 163,
    "out:Energy savings %": 15.3,
    "out:Op energy cost/MSEK": 122,
    "out:El price/MSEK": 71,
    "out:Heating price/MSEK": 44,
    "out:DH+ DHW/kWh/m2": 152,
    "out:El bought/kWh/m2": 758.1,
    "out:Return %": 9,
    "out:Return/kSEK/y": 355,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 61.265983,
    "out:PV (m2 panels)": 321,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 57484.06,
    "out:PV (kWh sold)": 30886.05,
    "out:PV (% sold (El))": 53.73,
    "out:PV (kWh self-consumed)": 26598,
    "out:PV (ratio sold/self-consumed)": 1.16,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 102860,
    "out:EL kWh savings": 26598,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 128574.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 178199,
    "out:DH kr savings": 93603,
    "out:El kr savings": 45217,
    "out:El kr sold": 39380,
    "out:El kr saved": 45217,
    "out:El kr return": 84596,
    "out:% solar/total": 33,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 560100,
    "out:% savings (space heating)": 14.95,
    "out:Total_Water Heating (DH)": 223720,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 108.91,
    "out:Etvv": 25,
    "out:Ef": 10.73,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z00000200010001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 113.81,
    "out:DHW (DH)": 36.98,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 10.73,
    "out:Total Energy Use Pre PV": 166.9,
    "out:Total Energy Use Post PV": 161.73,
    "out:Primary Energy": 113.85,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 20.74,
    "out:CO2 Operational/m2": 25.46,
    "out:Total CO2/m2": 46.2,
    "out:Total CO2 (tons)": 237.6,
    "out:Klimatpaverkan": -91.64,
    "out:Initial Cost/MSEK": 4.15325,
    "out:Running cost/(Apt SEK y)": 49892,
    "out:Running Cost over RSP/MSEK": 16.404,
    "out:LCP/MSEK": -1.288,
    "out:ROI% old": 9.86,
    "out:Payback discounted": 12,
    "out:Atemp": 5143,
    "out:Umean": 0.62,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "F",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 162,
    "out:Energy use kWh/m2": 162,
    "out:Energy savings %": 16,
    "out:Op energy cost/MSEK": 121,
    "out:El price/MSEK": 71,
    "out:Heating price/MSEK": 43,
    "out:DH+ DHW/kWh/m2": 151,
    "out:El bought/kWh/m2": 758.1,
    "out:Return %": 9,
    "out:Return/kSEK/y": 368,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 61.265983,
    "out:PV (m2 panels)": 321,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 57484.06,
    "out:PV (kWh sold)": 30886.05,
    "out:PV (% sold (El))": 53.73,
    "out:PV (kWh self-consumed)": 26598,
    "out:PV (ratio sold/self-consumed)": 1.16,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 108003,
    "out:EL kWh savings": 26598,
    "out:Sewage heat recovery cost": 250448,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 182879,
    "out:DH kr savings": 98283,
    "out:El kr savings": 45217,
    "out:El kr sold": 39380,
    "out:El kr saved": 45217,
    "out:El kr return": 84596,
    "out:% solar/total": 33,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 585302,
    "out:% savings (space heating)": 11.13,
    "out:Total_Water Heating (DH)": 190162,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 113.81,
    "out:Etvv": 21.25,
    "out:Ef": 10.73,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z00000200010001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 108.91,
    "out:DHW (DH)": 36.98,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 10.73,
    "out:Total Energy Use Pre PV": 161.9,
    "out:Total Energy Use Post PV": 156.73,
    "out:Primary Energy": 110.42,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 20.74,
    "out:CO2 Operational/m2": 23.66,
    "out:Total CO2/m2": 44.4,
    "out:Total CO2 (tons)": 228.35,
    "out:Klimatpaverkan": -100.89,
    "out:Initial Cost/MSEK": 4.281875,
    "out:Running cost/(Apt SEK y)": 48283,
    "out:Running Cost over RSP/MSEK": 15.875,
    "out:LCP/MSEK": -0.888,
    "out:ROI% old": 11.32,
    "out:Payback discounted": 11,
    "out:Atemp": 5143,
    "out:Umean": 0.62,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "F",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 157,
    "out:Energy use kWh/m2": 157,
    "out:Energy savings %": 19.7,
    "out:Op energy cost/MSEK": 117,
    "out:El price/MSEK": 68,
    "out:Heating price/MSEK": 42,
    "out:DH+ DHW/kWh/m2": 146,
    "out:El bought/kWh/m2": 758.1,
    "out:Return %": 10,
    "out:Return/kSEK/y": 436,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 61.265983,
    "out:PV (m2 panels)": 321,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 57484.06,
    "out:PV (kWh sold)": 30886.05,
    "out:PV (% sold (El))": 53.73,
    "out:PV (kWh self-consumed)": 26598,
    "out:PV (ratio sold/self-consumed)": 1.16,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 133718,
    "out:EL kWh savings": 26598,
    "out:Sewage heat recovery cost": 250448,
    "out:Balancing hydronic system cost": 128574.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 206280,
    "out:DH kr savings": 121683,
    "out:El kr savings": 45217,
    "out:El kr sold": 39380,
    "out:El kr saved": 45217,
    "out:El kr return": 84596,
    "out:% solar/total": 33,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 560100,
    "out:% savings (space heating)": 14.95,
    "out:Total_Water Heating (DH)": 190162,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 108.91,
    "out:Etvv": 21.25,
    "out:Ef": 10.73,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z00000200020000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 113.81,
    "out:DHW (DH)": 43.5,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 9.88,
    "out:Total Energy Use Pre PV": 172.9,
    "out:Total Energy Use Post PV": 166.88,
    "out:Primary Energy": 114.95,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 32.69,
    "out:CO2 Operational/m2": -25.06,
    "out:Total CO2/m2": 7.63,
    "out:Total CO2 (tons)": 39.24,
    "out:Klimatpaverkan": -290,
    "out:Initial Cost/MSEK": 4.972625,
    "out:Running cost/(Apt SEK y)": 50034,
    "out:Running Cost over RSP/MSEK": 16.467,
    "out:LCP/MSEK": -2.171,
    "out:ROI% old": 8.05,
    "out:Payback discounted": 15,
    "out:Atemp": 5143,
    "out:Umean": 0.62,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "F",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 167,
    "out:Energy use kWh/m2": 167,
    "out:Energy savings %": 12.6,
    "out:Op energy cost/MSEK": 122,
    "out:El price/MSEK": 70,
    "out:Heating price/MSEK": 45,
    "out:DH+ DHW/kWh/m2": 157,
    "out:El bought/kWh/m2": 696.7,
    "out:Return %": 7,
    "out:Return/kSEK/y": 362,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.33,
    "out:PV (kWp installed)": 122.531965,
    "out:PV (m2 panels)": 643,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 114968.11,
    "out:PV (kWh sold)": 84004.99,
    "out:PV (% sold (El))": 73.07,
    "out:PV (kWh self-consumed)": 30963.12,
    "out:PV (ratio sold/self-consumed)": 2.71,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 77145,
    "out:EL kWh savings": 30963,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 229946,
    "out:DH kr savings": 70202,
    "out:El kr savings": 52637,
    "out:El kr sold": 107106,
    "out:El kr saved": 52637,
    "out:El kr return": 159744,
    "out:% solar/total": 38,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 585302,
    "out:% savings (space heating)": 11.13,
    "out:Total_Water Heating (DH)": 223720,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 113.81,
    "out:Etvv": 25,
    "out:Ef": 9.88,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z00000200020000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 108.91,
    "out:DHW (DH)": 43.5,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 9.88,
    "out:Total Energy Use Pre PV": 167.9,
    "out:Total Energy Use Post PV": 161.88,
    "out:Primary Energy": 111.52,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 32.69,
    "out:CO2 Operational/m2": -26.86,
    "out:Total CO2/m2": 5.83,
    "out:Total CO2 (tons)": 29.99,
    "out:Klimatpaverkan": -299.25,
    "out:Initial Cost/MSEK": 5.101125,
    "out:Running cost/(Apt SEK y)": 48425,
    "out:Running Cost over RSP/MSEK": 15.938,
    "out:LCP/MSEK": -1.77,
    "out:ROI% old": 9.33,
    "out:Payback discounted": 13,
    "out:Atemp": 5143,
    "out:Umean": 0.62,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "F",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 162,
    "out:Energy use kWh/m2": 162,
    "out:Energy savings %": 16,
    "out:Op energy cost/MSEK": 118,
    "out:El price/MSEK": 68,
    "out:Heating price/MSEK": 44,
    "out:DH+ DHW/kWh/m2": 152,
    "out:El bought/kWh/m2": 696.7,
    "out:Return %": 8,
    "out:Return/kSEK/y": 430,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.33,
    "out:PV (kWp installed)": 122.531965,
    "out:PV (m2 panels)": 643,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 114968.11,
    "out:PV (kWh sold)": 84004.99,
    "out:PV (% sold (El))": 73.07,
    "out:PV (kWh self-consumed)": 30963.12,
    "out:PV (ratio sold/self-consumed)": 2.71,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 102860,
    "out:EL kWh savings": 30963,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 128574.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 253346,
    "out:DH kr savings": 93603,
    "out:El kr savings": 52637,
    "out:El kr sold": 107106,
    "out:El kr saved": 52637,
    "out:El kr return": 159744,
    "out:% solar/total": 38,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 560100,
    "out:% savings (space heating)": 14.95,
    "out:Total_Water Heating (DH)": 223720,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 108.91,
    "out:Etvv": 25,
    "out:Ef": 9.88,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z00000200020001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 113.81,
    "out:DHW (DH)": 36.98,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 9.88,
    "out:Total Energy Use Pre PV": 166.9,
    "out:Total Energy Use Post PV": 160.88,
    "out:Primary Energy": 112.32,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 33.09,
    "out:CO2 Operational/m2": -27.22,
    "out:Total CO2/m2": 5.87,
    "out:Total CO2 (tons)": 30.2,
    "out:Klimatpaverkan": -299.04,
    "out:Initial Cost/MSEK": 5.223,
    "out:Running cost/(Apt SEK y)": 48103,
    "out:Running Cost over RSP/MSEK": 15.832,
    "out:LCP/MSEK": -1.786,
    "out:ROI% old": 9.4,
    "out:Payback discounted": 13,
    "out:Atemp": 5143,
    "out:Umean": 0.62,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "F",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 161,
    "out:Energy use kWh/m2": 161,
    "out:Energy savings %": 16.8,
    "out:Op energy cost/MSEK": 118,
    "out:El price/MSEK": 67,
    "out:Heating price/MSEK": 43,
    "out:DH+ DHW/kWh/m2": 151,
    "out:El bought/kWh/m2": 696.7,
    "out:Return %": 8,
    "out:Return/kSEK/y": 444,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.33,
    "out:PV (kWp installed)": 122.531965,
    "out:PV (m2 panels)": 643,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 114968.11,
    "out:PV (kWh sold)": 84004.99,
    "out:PV (% sold (El))": 73.07,
    "out:PV (kWh self-consumed)": 30963.12,
    "out:PV (ratio sold/self-consumed)": 2.71,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 108003,
    "out:EL kWh savings": 30963,
    "out:Sewage heat recovery cost": 250448,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 258026,
    "out:DH kr savings": 98283,
    "out:El kr savings": 52637,
    "out:El kr sold": 107106,
    "out:El kr saved": 52637,
    "out:El kr return": 159744,
    "out:% solar/total": 38,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 585302,
    "out:% savings (space heating)": 11.13,
    "out:Total_Water Heating (DH)": 190162,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 113.81,
    "out:Etvv": 21.25,
    "out:Ef": 9.88,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z00000200020001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 108.91,
    "out:DHW (DH)": 36.98,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 9.88,
    "out:Total Energy Use Pre PV": 161.9,
    "out:Total Energy Use Post PV": 155.88,
    "out:Primary Energy": 108.89,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 33.09,
    "out:CO2 Operational/m2": -29.02,
    "out:Total CO2/m2": 4.07,
    "out:Total CO2 (tons)": 20.95,
    "out:Klimatpaverkan": -308.29,
    "out:Initial Cost/MSEK": 5.351625,
    "out:Running cost/(Apt SEK y)": 46494,
    "out:Running Cost over RSP/MSEK": 15.304,
    "out:LCP/MSEK": -1.386,
    "out:ROI% old": 10.59,
    "out:Payback discounted": 11,
    "out:Atemp": 5143,
    "out:Umean": 0.62,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "F",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 156,
    "out:Energy use kWh/m2": 156,
    "out:Energy savings %": 20.5,
    "out:Op energy cost/MSEK": 114,
    "out:El price/MSEK": 65,
    "out:Heating price/MSEK": 42,
    "out:DH+ DHW/kWh/m2": 146,
    "out:El bought/kWh/m2": 696.7,
    "out:Return %": 10,
    "out:Return/kSEK/y": 511,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.33,
    "out:PV (kWp installed)": 122.531965,
    "out:PV (m2 panels)": 643,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 114968.11,
    "out:PV (kWh sold)": 84004.99,
    "out:PV (% sold (El))": 73.07,
    "out:PV (kWh self-consumed)": 30963.12,
    "out:PV (ratio sold/self-consumed)": 2.71,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 133718,
    "out:EL kWh savings": 30963,
    "out:Sewage heat recovery cost": 250448,
    "out:Balancing hydronic system cost": 128574.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 281427,
    "out:DH kr savings": 121683,
    "out:El kr savings": 52637,
    "out:El kr sold": 107106,
    "out:El kr saved": 52637,
    "out:El kr return": 159744,
    "out:% solar/total": 38,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 560100,
    "out:% savings (space heating)": 14.95,
    "out:Total_Water Heating (DH)": 190162,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 108.91,
    "out:Etvv": 21.25,
    "out:Ef": 9.88,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z00000200100000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 113.81,
    "out:DHW (DH)": 34.8,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 15.9,
    "out:Total Energy Use Pre PV": 164.9,
    "out:Total Energy Use Post PV": 164.9,
    "out:Primary Energy": 122.28,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 7.98,
    "out:CO2 Operational/m2": 55.74,
    "out:Total CO2/m2": 63.72,
    "out:Total CO2 (tons)": 327.71,
    "out:Klimatpaverkan": -1.53,
    "out:Initial Cost/MSEK": 2.943875,
    "out:Running cost/(Apt SEK y)": 51262,
    "out:Running Cost over RSP/MSEK": 16.836,
    "out:LCP/MSEK": -0.511,
    "out:ROI% old": 11.81,
    "out:Payback discounted": 10,
    "out:Atemp": 5143,
    "out:Umean": 0.62,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "F",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 165,
    "out:Energy use kWh/m2": 165,
    "out:Energy savings %": 13.9,
    "out:Op energy cost/MSEK": 123,
    "out:El price/MSEK": 74,
    "out:Heating price/MSEK": 43,
    "out:DH+ DHW/kWh/m2": 149,
    "out:El bought/kWh/m2": 1113,
    "out:Return %": 11,
    "out:Return/kSEK/y": 311,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 118289,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 107643,
    "out:DH kr savings": 107643,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 585302,
    "out:% savings (space heating)": 11.13,
    "out:Total_Water Heating (DH)": 178976,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 113.81,
    "out:Etvv": 20,
    "out:Ef": 15.9,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z00000200100000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 108.91,
    "out:DHW (DH)": 34.8,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 15.9,
    "out:Total Energy Use Pre PV": 159.9,
    "out:Total Energy Use Post PV": 159.9,
    "out:Primary Energy": 118.85,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 7.98,
    "out:CO2 Operational/m2": 53.94,
    "out:Total CO2/m2": 61.92,
    "out:Total CO2 (tons)": 318.45,
    "out:Klimatpaverkan": -10.79,
    "out:Initial Cost/MSEK": 3.072375,
    "out:Running cost/(Apt SEK y)": 49653,
    "out:Running Cost over RSP/MSEK": 16.307,
    "out:LCP/MSEK": -0.111,
    "out:ROI% old": 13.77,
    "out:Payback discounted": 9,
    "out:Atemp": 5143,
    "out:Umean": 0.62,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "F",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 160,
    "out:Energy use kWh/m2": 160,
    "out:Energy savings %": 17.5,
    "out:Op energy cost/MSEK": 120,
    "out:El price/MSEK": 71,
    "out:Heating price/MSEK": 41,
    "out:DH+ DHW/kWh/m2": 144,
    "out:El bought/kWh/m2": 1113,
    "out:Return %": 12,
    "out:Return/kSEK/y": 378,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 144004,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 128574.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 131044,
    "out:DH kr savings": 131044,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 560100,
    "out:% savings (space heating)": 14.95,
    "out:Total_Water Heating (DH)": 178976,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 108.91,
    "out:Etvv": 20,
    "out:Ef": 15.9,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z00000200100001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 113.81,
    "out:DHW (DH)": 29.58,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 15.9,
    "out:Total Energy Use Pre PV": 158.9,
    "out:Total Energy Use Post PV": 158.9,
    "out:Primary Energy": 120.18,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 8.38,
    "out:CO2 Operational/m2": 53.58,
    "out:Total CO2/m2": 61.96,
    "out:Total CO2 (tons)": 318.67,
    "out:Klimatpaverkan": -10.57,
    "out:Initial Cost/MSEK": 3.19425,
    "out:Running cost/(Apt SEK y)": 49331,
    "out:Running Cost over RSP/MSEK": 16.201,
    "out:LCP/MSEK": -0.127,
    "out:ROI% old": 13.72,
    "out:Payback discounted": 9,
    "out:Atemp": 5143,
    "out:Umean": 0.62,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "F",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 159,
    "out:Energy use kWh/m2": 159,
    "out:Energy savings %": 18.2,
    "out:Op energy cost/MSEK": 119,
    "out:El price/MSEK": 71,
    "out:Heating price/MSEK": 41,
    "out:DH+ DHW/kWh/m2": 143,
    "out:El bought/kWh/m2": 1113,
    "out:Return %": 12,
    "out:Return/kSEK/y": 392,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 149147,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 250448,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 135724,
    "out:DH kr savings": 135724,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 585302,
    "out:% savings (space heating)": 11.13,
    "out:Total_Water Heating (DH)": 152130,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 113.81,
    "out:Etvv": 17,
    "out:Ef": 15.9,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z00000200100001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 108.91,
    "out:DHW (DH)": 29.58,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 15.9,
    "out:Total Energy Use Pre PV": 153.9,
    "out:Total Energy Use Post PV": 153.9,
    "out:Primary Energy": 116.75,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 8.38,
    "out:CO2 Operational/m2": 51.78,
    "out:Total CO2/m2": 60.16,
    "out:Total CO2 (tons)": 309.41,
    "out:Klimatpaverkan": -19.83,
    "out:Initial Cost/MSEK": 3.322875,
    "out:Running cost/(Apt SEK y)": 47722,
    "out:Running Cost over RSP/MSEK": 15.672,
    "out:LCP/MSEK": 0.274,
    "out:ROI% old": 15.46,
    "out:Payback discounted": 8,
    "out:Atemp": 5143,
    "out:Umean": 0.62,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "F",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 154,
    "out:Energy use kWh/m2": 154,
    "out:Energy savings %": 22.1,
    "out:Op energy cost/MSEK": 115,
    "out:El price/MSEK": 69,
    "out:Heating price/MSEK": 40,
    "out:DH+ DHW/kWh/m2": 138,
    "out:El bought/kWh/m2": 1113,
    "out:Return %": 14,
    "out:Return/kSEK/y": 460,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 174862,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 250448,
    "out:Balancing hydronic system cost": 128574.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 159124,
    "out:DH kr savings": 159124,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 560100,
    "out:% savings (space heating)": 14.95,
    "out:Total_Water Heating (DH)": 152130,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 108.91,
    "out:Etvv": 17,
    "out:Ef": 15.9,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z00000200110000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 113.81,
    "out:DHW (DH)": 34.8,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 10.73,
    "out:Total Energy Use Pre PV": 164.9,
    "out:Total Energy Use Post PV": 159.73,
    "out:Primary Energy": 112.97,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 20.34,
    "out:CO2 Operational/m2": 24.74,
    "out:Total CO2/m2": 45.08,
    "out:Total CO2 (tons)": 231.83,
    "out:Klimatpaverkan": -97.41,
    "out:Initial Cost/MSEK": 4.013625,
    "out:Running cost/(Apt SEK y)": 49248,
    "out:Running Cost over RSP/MSEK": 16.192,
    "out:LCP/MSEK": -0.937,
    "out:ROI% old": 10.95,
    "out:Payback discounted": 11,
    "out:Atemp": 5143,
    "out:Umean": 0.62,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "F",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 160,
    "out:Energy use kWh/m2": 160,
    "out:Energy savings %": 17.5,
    "out:Op energy cost/MSEK": 120,
    "out:El price/MSEK": 70,
    "out:Heating price/MSEK": 43,
    "out:DH+ DHW/kWh/m2": 149,
    "out:El bought/kWh/m2": 758.1,
    "out:Return %": 10,
    "out:Return/kSEK/y": 395,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 61.265983,
    "out:PV (m2 panels)": 321,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 57484.06,
    "out:PV (kWh sold)": 30886.05,
    "out:PV (% sold (El))": 53.73,
    "out:PV (kWh self-consumed)": 26598,
    "out:PV (ratio sold/self-consumed)": 1.16,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 118289,
    "out:EL kWh savings": 26598,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 192239,
    "out:DH kr savings": 107643,
    "out:El kr savings": 45217,
    "out:El kr sold": 39380,
    "out:El kr saved": 45217,
    "out:El kr return": 84596,
    "out:% solar/total": 33,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 585302,
    "out:% savings (space heating)": 11.13,
    "out:Total_Water Heating (DH)": 178976,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 113.81,
    "out:Etvv": 20,
    "out:Ef": 10.73,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z00000200110000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 108.91,
    "out:DHW (DH)": 34.8,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 10.73,
    "out:Total Energy Use Pre PV": 159.9,
    "out:Total Energy Use Post PV": 154.73,
    "out:Primary Energy": 109.54,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 20.34,
    "out:CO2 Operational/m2": 22.94,
    "out:Total CO2/m2": 43.28,
    "out:Total CO2 (tons)": 222.58,
    "out:Klimatpaverkan": -106.66,
    "out:Initial Cost/MSEK": 4.14225,
    "out:Running cost/(Apt SEK y)": 47639,
    "out:Running Cost over RSP/MSEK": 15.663,
    "out:LCP/MSEK": -0.537,
    "out:ROI% old": 12.44,
    "out:Payback discounted": 10,
    "out:Atemp": 5143,
    "out:Umean": 0.62,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "F",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 155,
    "out:Energy use kWh/m2": 155,
    "out:Energy savings %": 21.3,
    "out:Op energy cost/MSEK": 116,
    "out:El price/MSEK": 68,
    "out:Heating price/MSEK": 41,
    "out:DH+ DHW/kWh/m2": 144,
    "out:El bought/kWh/m2": 758.1,
    "out:Return %": 11,
    "out:Return/kSEK/y": 463,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 61.265983,
    "out:PV (m2 panels)": 321,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 57484.06,
    "out:PV (kWh sold)": 30886.05,
    "out:PV (% sold (El))": 53.73,
    "out:PV (kWh self-consumed)": 26598,
    "out:PV (ratio sold/self-consumed)": 1.16,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 144004,
    "out:EL kWh savings": 26598,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 128574.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 215640,
    "out:DH kr savings": 131044,
    "out:El kr savings": 45217,
    "out:El kr sold": 39380,
    "out:El kr saved": 45217,
    "out:El kr return": 84596,
    "out:% solar/total": 33,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 560100,
    "out:% savings (space heating)": 14.95,
    "out:Total_Water Heating (DH)": 178976,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 108.91,
    "out:Etvv": 20,
    "out:Ef": 10.73,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z00000200110001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 113.81,
    "out:DHW (DH)": 29.58,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 10.73,
    "out:Total Energy Use Pre PV": 158.9,
    "out:Total Energy Use Post PV": 153.73,
    "out:Primary Energy": 110.87,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 20.74,
    "out:CO2 Operational/m2": 22.58,
    "out:Total CO2/m2": 43.32,
    "out:Total CO2 (tons)": 222.79,
    "out:Klimatpaverkan": -106.45,
    "out:Initial Cost/MSEK": 4.264125,
    "out:Running cost/(Apt SEK y)": 47317,
    "out:Running Cost over RSP/MSEK": 15.558,
    "out:LCP/MSEK": -0.553,
    "out:ROI% old": 12.43,
    "out:Payback discounted": 10,
    "out:Atemp": 5143,
    "out:Umean": 0.62,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "F",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 154,
    "out:Energy use kWh/m2": 154,
    "out:Energy savings %": 22.1,
    "out:Op energy cost/MSEK": 115,
    "out:El price/MSEK": 67,
    "out:Heating price/MSEK": 41,
    "out:DH+ DHW/kWh/m2": 143,
    "out:El bought/kWh/m2": 758.1,
    "out:Return %": 11,
    "out:Return/kSEK/y": 477,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 61.265983,
    "out:PV (m2 panels)": 321,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 57484.06,
    "out:PV (kWh sold)": 30886.05,
    "out:PV (% sold (El))": 53.73,
    "out:PV (kWh self-consumed)": 26598,
    "out:PV (ratio sold/self-consumed)": 1.16,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 149147,
    "out:EL kWh savings": 26598,
    "out:Sewage heat recovery cost": 250448,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 220320,
    "out:DH kr savings": 135724,
    "out:El kr savings": 45217,
    "out:El kr sold": 39380,
    "out:El kr saved": 45217,
    "out:El kr return": 84596,
    "out:% solar/total": 33,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 585302,
    "out:% savings (space heating)": 11.13,
    "out:Total_Water Heating (DH)": 152130,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 113.81,
    "out:Etvv": 17,
    "out:Ef": 10.73,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z00000200110001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 108.91,
    "out:DHW (DH)": 29.58,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 10.73,
    "out:Total Energy Use Pre PV": 153.9,
    "out:Total Energy Use Post PV": 148.73,
    "out:Primary Energy": 107.44,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 20.74,
    "out:CO2 Operational/m2": 20.78,
    "out:Total CO2/m2": 41.52,
    "out:Total CO2 (tons)": 213.53,
    "out:Klimatpaverkan": -115.71,
    "out:Initial Cost/MSEK": 4.392625,
    "out:Running cost/(Apt SEK y)": 45708,
    "out:Running Cost over RSP/MSEK": 15.029,
    "out:LCP/MSEK": -0.152,
    "out:ROI% old": 13.79,
    "out:Payback discounted": 9,
    "out:Atemp": 5143,
    "out:Umean": 0.62,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "F",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 149,
    "out:Energy use kWh/m2": 149,
    "out:Energy savings %": 26.2,
    "out:Op energy cost/MSEK": 111,
    "out:El price/MSEK": 65,
    "out:Heating price/MSEK": 40,
    "out:DH+ DHW/kWh/m2": 138,
    "out:El bought/kWh/m2": 758.1,
    "out:Return %": 12,
    "out:Return/kSEK/y": 544,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 61.265983,
    "out:PV (m2 panels)": 321,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 57484.06,
    "out:PV (kWh sold)": 30886.05,
    "out:PV (% sold (El))": 53.73,
    "out:PV (kWh self-consumed)": 26598,
    "out:PV (ratio sold/self-consumed)": 1.16,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 174862,
    "out:EL kWh savings": 26598,
    "out:Sewage heat recovery cost": 250448,
    "out:Balancing hydronic system cost": 128574.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 243721,
    "out:DH kr savings": 159124,
    "out:El kr savings": 45217,
    "out:El kr sold": 39380,
    "out:El kr saved": 45217,
    "out:El kr return": 84596,
    "out:% solar/total": 33,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 560100,
    "out:% savings (space heating)": 14.95,
    "out:Total_Water Heating (DH)": 152130,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 108.91,
    "out:Etvv": 17,
    "out:Ef": 10.73,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z00000200120000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 113.81,
    "out:DHW (DH)": 34.8,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 9.88,
    "out:Total Energy Use Pre PV": 164.9,
    "out:Total Energy Use Post PV": 158.88,
    "out:Primary Energy": 111.45,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 32.69,
    "out:CO2 Operational/m2": -27.94,
    "out:Total CO2/m2": 4.75,
    "out:Total CO2 (tons)": 24.43,
    "out:Klimatpaverkan": -304.81,
    "out:Initial Cost/MSEK": 5.083375,
    "out:Running cost/(Apt SEK y)": 47459,
    "out:Running Cost over RSP/MSEK": 15.621,
    "out:LCP/MSEK": -1.435,
    "out:ROI% old": 10.25,
    "out:Payback discounted": 12,
    "out:Atemp": 5143,
    "out:Umean": 0.62,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "F",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 159,
    "out:Energy use kWh/m2": 159,
    "out:Energy savings %": 18.2,
    "out:Op energy cost/MSEK": 116,
    "out:El price/MSEK": 66,
    "out:Heating price/MSEK": 43,
    "out:DH+ DHW/kWh/m2": 149,
    "out:El bought/kWh/m2": 696.7,
    "out:Return %": 9,
    "out:Return/kSEK/y": 471,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.33,
    "out:PV (kWp installed)": 122.531965,
    "out:PV (m2 panels)": 643,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 114968.11,
    "out:PV (kWh sold)": 84004.99,
    "out:PV (% sold (El))": 73.07,
    "out:PV (kWh self-consumed)": 30963.12,
    "out:PV (ratio sold/self-consumed)": 2.71,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 118289,
    "out:EL kWh savings": 30963,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 267387,
    "out:DH kr savings": 107643,
    "out:El kr savings": 52637,
    "out:El kr sold": 107106,
    "out:El kr saved": 52637,
    "out:El kr return": 159744,
    "out:% solar/total": 38,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 585302,
    "out:% savings (space heating)": 11.13,
    "out:Total_Water Heating (DH)": 178976,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 113.81,
    "out:Etvv": 20,
    "out:Ef": 9.88,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z00000200120000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 108.91,
    "out:DHW (DH)": 34.8,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 9.88,
    "out:Total Energy Use Pre PV": 159.9,
    "out:Total Energy Use Post PV": 153.88,
    "out:Primary Energy": 108.02,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 32.69,
    "out:CO2 Operational/m2": -29.74,
    "out:Total CO2/m2": 2.95,
    "out:Total CO2 (tons)": 15.18,
    "out:Klimatpaverkan": -314.06,
    "out:Initial Cost/MSEK": 5.212,
    "out:Running cost/(Apt SEK y)": 45850,
    "out:Running Cost over RSP/MSEK": 15.092,
    "out:LCP/MSEK": -1.035,
    "out:ROI% old": 11.45,
    "out:Payback discounted": 11,
    "out:Atemp": 5143,
    "out:Umean": 0.62,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "F",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 154,
    "out:Energy use kWh/m2": 154,
    "out:Energy savings %": 22.1,
    "out:Op energy cost/MSEK": 112,
    "out:El price/MSEK": 64,
    "out:Heating price/MSEK": 41,
    "out:DH+ DHW/kWh/m2": 144,
    "out:El bought/kWh/m2": 696.7,
    "out:Return %": 10,
    "out:Return/kSEK/y": 538,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.33,
    "out:PV (kWp installed)": 122.531965,
    "out:PV (m2 panels)": 643,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 114968.11,
    "out:PV (kWh sold)": 84004.99,
    "out:PV (% sold (El))": 73.07,
    "out:PV (kWh self-consumed)": 30963.12,
    "out:PV (ratio sold/self-consumed)": 2.71,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 144004,
    "out:EL kWh savings": 30963,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 128574.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 290787,
    "out:DH kr savings": 131044,
    "out:El kr savings": 52637,
    "out:El kr sold": 107106,
    "out:El kr saved": 52637,
    "out:El kr return": 159744,
    "out:% solar/total": 38,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 560100,
    "out:% savings (space heating)": 14.95,
    "out:Total_Water Heating (DH)": 178976,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 108.91,
    "out:Etvv": 20,
    "out:Ef": 9.88,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z00000200120001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 113.81,
    "out:DHW (DH)": 29.58,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 9.88,
    "out:Total Energy Use Pre PV": 158.9,
    "out:Total Energy Use Post PV": 152.88,
    "out:Primary Energy": 109.35,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 33.09,
    "out:CO2 Operational/m2": -30.1,
    "out:Total CO2/m2": 2.99,
    "out:Total CO2 (tons)": 15.39,
    "out:Klimatpaverkan": -313.85,
    "out:Initial Cost/MSEK": 5.333875,
    "out:Running cost/(Apt SEK y)": 45528,
    "out:Running Cost over RSP/MSEK": 14.986,
    "out:LCP/MSEK": -1.051,
    "out:ROI% old": 11.47,
    "out:Payback discounted": 11,
    "out:Atemp": 5143,
    "out:Umean": 0.62,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "F",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 153,
    "out:Energy use kWh/m2": 153,
    "out:Energy savings %": 22.9,
    "out:Op energy cost/MSEK": 111,
    "out:El price/MSEK": 64,
    "out:Heating price/MSEK": 41,
    "out:DH+ DHW/kWh/m2": 143,
    "out:El bought/kWh/m2": 696.7,
    "out:Return %": 10,
    "out:Return/kSEK/y": 552,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.33,
    "out:PV (kWp installed)": 122.531965,
    "out:PV (m2 panels)": 643,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 114968.11,
    "out:PV (kWh sold)": 84004.99,
    "out:PV (% sold (El))": 73.07,
    "out:PV (kWh self-consumed)": 30963.12,
    "out:PV (ratio sold/self-consumed)": 2.71,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 149147,
    "out:EL kWh savings": 30963,
    "out:Sewage heat recovery cost": 250448,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 295467,
    "out:DH kr savings": 135724,
    "out:El kr savings": 52637,
    "out:El kr sold": 107106,
    "out:El kr saved": 52637,
    "out:El kr return": 159744,
    "out:% solar/total": 38,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 585302,
    "out:% savings (space heating)": 11.13,
    "out:Total_Water Heating (DH)": 152130,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 113.81,
    "out:Etvv": 17,
    "out:Ef": 9.88,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z00000200120001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 108.91,
    "out:DHW (DH)": 29.58,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 9.88,
    "out:Total Energy Use Pre PV": 153.9,
    "out:Total Energy Use Post PV": 147.88,
    "out:Primary Energy": 105.92,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 33.09,
    "out:CO2 Operational/m2": -31.9,
    "out:Total CO2/m2": 1.19,
    "out:Total CO2 (tons)": 6.14,
    "out:Klimatpaverkan": -323.1,
    "out:Initial Cost/MSEK": 5.4625,
    "out:Running cost/(Apt SEK y)": 43919,
    "out:Running Cost over RSP/MSEK": 14.457,
    "out:LCP/MSEK": -0.651,
    "out:ROI% old": 12.58,
    "out:Payback discounted": 10,
    "out:Atemp": 5143,
    "out:Umean": 0.62,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "F",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 148,
    "out:Energy use kWh/m2": 148,
    "out:Energy savings %": 27,
    "out:Op energy cost/MSEK": 107,
    "out:El price/MSEK": 61,
    "out:Heating price/MSEK": 40,
    "out:DH+ DHW/kWh/m2": 138,
    "out:El bought/kWh/m2": 696.7,
    "out:Return %": 11,
    "out:Return/kSEK/y": 619,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.33,
    "out:PV (kWp installed)": 122.531965,
    "out:PV (m2 panels)": 643,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 114968.11,
    "out:PV (kWh sold)": 84004.99,
    "out:PV (% sold (El))": 73.07,
    "out:PV (kWh self-consumed)": 30963.12,
    "out:PV (ratio sold/self-consumed)": 2.71,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 174862,
    "out:EL kWh savings": 30963,
    "out:Sewage heat recovery cost": 250448,
    "out:Balancing hydronic system cost": 128574.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 318868,
    "out:DH kr savings": 159124,
    "out:El kr savings": 52637,
    "out:El kr sold": 107106,
    "out:El kr saved": 52637,
    "out:El kr return": 159744,
    "out:% solar/total": 38,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 560100,
    "out:% savings (space heating)": 14.95,
    "out:Total_Water Heating (DH)": 152130,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 108.91,
    "out:Etvv": 17,
    "out:Ef": 9.88,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z00000201000000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 67.64,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 45.37,
    "out:Electricity (inc PV)": 43.29,
    "out:Total Energy Use Pre PV": 113.37,
    "out:Total Energy Use Post PV": 111.29,
    "out:Primary Energy": 117.13,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 10.83,
    "out:CO2 Operational/m2": 30.47,
    "out:Total CO2/m2": 41.3,
    "out:Total CO2 (tons)": 212.41,
    "out:Klimatpaverkan": -116.83,
    "out:Initial Cost/MSEK": 7.308875,
    "out:Running cost/(Apt SEK y)": 31329,
    "out:Running Cost over RSP/MSEK": 10.26,
    "out:LCP/MSEK": 1.7,
    "out:ROI% old": 17.61,
    "out:Payback discounted": 7,
    "out:Atemp": 5143,
    "out:Umean": 0.62,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 111,
    "out:Energy use kWh/m2": 111,
    "out:Energy savings %": 69.4,
    "out:Op energy cost/MSEK": 73,
    "out:El price/MSEK": 51,
    "out:Heating price/MSEK": 19,
    "out:DH+ DHW/kWh/m2": 68,
    "out:El bought/kWh/m2": 3176,
    "out:Return %": 16,
    "out:Return/kSEK/y": 1148,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 1479000,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 73950,
    "out:EAHP (replacement cost)": 1105400,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 27.17,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 534872,
    "out:EL kWh savings": -140855,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 247280,
    "out:DH kr savings": 486733,
    "out:El kr savings": -239454,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 347872,
    "out:% savings (space heating)": 47.18,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 56.01,
    "out:Etvv": 0,
    "out:Ef": 43.29,
    "out:heatPumpQuantity": 10,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000201000000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 63,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 45.29,
    "out:Electricity (inc PV)": 43.16,
    "out:Total Energy Use Pre PV": 108.29,
    "out:Total Energy Use Post PV": 106.16,
    "out:Primary Energy": 113.76,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 10.83,
    "out:CO2 Operational/m2": 28.66,
    "out:Total CO2/m2": 39.49,
    "out:Total CO2 (tons)": 203.1,
    "out:Klimatpaverkan": -126.14,
    "out:Initial Cost/MSEK": 7.4375,
    "out:Running cost/(Apt SEK y)": 29704,
    "out:Running Cost over RSP/MSEK": 9.726,
    "out:LCP/MSEK": 2.106,
    "out:ROI% old": 18.33,
    "out:Payback discounted": 7,
    "out:Atemp": 5143,
    "out:Umean": 0.62,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 106,
    "out:Energy use kWh/m2": 106,
    "out:Energy savings %": 77.4,
    "out:Op energy cost/MSEK": 69,
    "out:El price/MSEK": 48,
    "out:Heating price/MSEK": 18,
    "out:DH+ DHW/kWh/m2": 63,
    "out:El bought/kWh/m2": 3170.4,
    "out:Return %": 16,
    "out:Return/kSEK/y": 1216,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 1479000,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 73950,
    "out:EAHP (replacement cost)": 1105400,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 27.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 560587,
    "out:EL kWh savings": -140193,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 128574.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 271806,
    "out:DH kr savings": 510134,
    "out:El kr savings": -238328,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 324013,
    "out:% savings (space heating)": 50.8,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 51.54,
    "out:Etvv": 0,
    "out:Ef": 43.16,
    "out:heatPumpQuantity": 10,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000201000001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 63.42,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 44.67,
    "out:Electricity (inc PV)": 42.83,
    "out:Total Energy Use Pre PV": 107.67,
    "out:Total Energy Use Post PV": 105.83,
    "out:Primary Energy": 114.74,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 11.24,
    "out:CO2 Operational/m2": 28.58,
    "out:Total CO2/m2": 39.81,
    "out:Total CO2 (tons)": 204.75,
    "out:Klimatpaverkan": -124.49,
    "out:Initial Cost/MSEK": 7.559375,
    "out:Running cost/(Apt SEK y)": 29574,
    "out:Running Cost over RSP/MSEK": 9.684,
    "out:LCP/MSEK": 2.026,
    "out:ROI% old": 18.11,
    "out:Payback discounted": 7,
    "out:Atemp": 5143,
    "out:Umean": 0.62,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 106,
    "out:Energy use kWh/m2": 106,
    "out:Energy savings %": 77.4,
    "out:Op energy cost/MSEK": 69,
    "out:El price/MSEK": 48,
    "out:Heating price/MSEK": 18,
    "out:DH+ DHW/kWh/m2": 63,
    "out:El bought/kWh/m2": 3127,
    "out:Return %": 16,
    "out:Return/kSEK/y": 1222,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 1479000,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 73950,
    "out:EAHP (replacement cost)": 1105400,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 26.47,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 560587,
    "out:EL kWh savings": -138492,
    "out:Sewage heat recovery cost": 250448,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 274697,
    "out:DH kr savings": 510134,
    "out:El kr savings": -235437,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 326190,
    "out:% savings (space heating)": 50.47,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 53.78,
    "out:Etvv": 0,
    "out:Ef": 42.83,
    "out:heatPumpQuantity": 10,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000201000001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 58.84,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 44.58,
    "out:Electricity (inc PV)": 42.69,
    "out:Total Energy Use Pre PV": 103.58,
    "out:Total Energy Use Post PV": 101.69,
    "out:Primary Energy": 111.38,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 11.24,
    "out:CO2 Operational/m2": 27.12,
    "out:Total CO2/m2": 38.36,
    "out:Total CO2 (tons)": 197.28,
    "out:Klimatpaverkan": -131.96,
    "out:Initial Cost/MSEK": 7.687875,
    "out:Running cost/(Apt SEK y)": 28267,
    "out:Running Cost over RSP/MSEK": 9.254,
    "out:LCP/MSEK": 2.327,
    "out:ROI% old": 18.61,
    "out:Payback discounted": 7,
    "out:Atemp": 5143,
    "out:Umean": 0.62,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 102,
    "out:Energy use kWh/m2": 102,
    "out:Energy savings %": 84.3,
    "out:Op energy cost/MSEK": 66,
    "out:El price/MSEK": 46,
    "out:Heating price/MSEK": 17,
    "out:DH+ DHW/kWh/m2": 59,
    "out:El bought/kWh/m2": 3120.3,
    "out:Return %": 17,
    "out:Return/kSEK/y": 1277,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 1479000,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 73950,
    "out:EAHP (replacement cost)": 1105400,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 26.38,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 581159,
    "out:EL kWh savings": -137775,
    "out:Sewage heat recovery cost": 250448,
    "out:Balancing hydronic system cost": 128574.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 294637,
    "out:DH kr savings": 528855,
    "out:El kr savings": -234218,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 302616,
    "out:% savings (space heating)": 54.05,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 49.34,
    "out:Etvv": 0,
    "out:Ef": 42.69,
    "out:heatPumpQuantity": 10,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000201010000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 67.64,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 45.37,
    "out:Electricity (inc PV)": 33.76,
    "out:Total Energy Use Pre PV": 113.37,
    "out:Total Energy Use Post PV": 101.76,
    "out:Primary Energy": 99.97,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 23.19,
    "out:CO2 Operational/m2": 22.17,
    "out:Total CO2/m2": 45.35,
    "out:Total CO2 (tons)": 233.25,
    "out:Klimatpaverkan": -95.99,
    "out:Initial Cost/MSEK": 8.37875,
    "out:Running cost/(Apt SEK y)": 29078,
    "out:Running Cost over RSP/MSEK": 9.54,
    "out:LCP/MSEK": 1.35,
    "out:ROI% old": 16.59,
    "out:Payback discounted": 7,
    "out:Atemp": 5143,
    "out:Umean": 0.62,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 102,
    "out:Energy use kWh/m2": 102,
    "out:Energy savings %": 84.3,
    "out:Op energy cost/MSEK": 69,
    "out:El price/MSEK": 46,
    "out:Heating price/MSEK": 19,
    "out:DH+ DHW/kWh/m2": 68,
    "out:El bought/kWh/m2": 2521.7,
    "out:Return %": 15,
    "out:Return/kSEK/y": 1243,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 1479000,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 73950,
    "out:EAHP (replacement cost)": 1105400,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 27.17,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 61.265983,
    "out:PV (m2 panels)": 321,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 57484.06,
    "out:PV (kWh sold)": 7436.9,
    "out:PV (% sold (El))": 12.94,
    "out:PV (kWh self-consumed)": 50047.16,
    "out:PV (ratio sold/self-consumed)": 0.15,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 534872,
    "out:EL kWh savings": -91842,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 340084,
    "out:DH kr savings": 486733,
    "out:El kr savings": -156132,
    "out:El kr sold": 9482,
    "out:El kr saved": 85080,
    "out:El kr return": 94562,
    "out:% solar/total": 61,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 347872,
    "out:% savings (space heating)": 47.18,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 56.01,
    "out:Etvv": 0,
    "out:Ef": 33.76,
    "out:heatPumpQuantity": 10,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000201010000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 63,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 45.29,
    "out:Electricity (inc PV)": 33.64,
    "out:Total Energy Use Pre PV": 108.29,
    "out:Total Energy Use Post PV": 96.64,
    "out:Primary Energy": 96.63,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 23.19,
    "out:CO2 Operational/m2": 20.32,
    "out:Total CO2/m2": 43.51,
    "out:Total CO2 (tons)": 223.75,
    "out:Klimatpaverkan": -105.49,
    "out:Initial Cost/MSEK": 8.50725,
    "out:Running cost/(Apt SEK y)": 27452,
    "out:Running Cost over RSP/MSEK": 9.006,
    "out:LCP/MSEK": 1.756,
    "out:ROI% old": 17.23,
    "out:Payback discounted": 7,
    "out:Atemp": 5143,
    "out:Umean": 0.62,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 97,
    "out:Energy use kWh/m2": 97,
    "out:Energy savings %": 93.8,
    "out:Op energy cost/MSEK": 65,
    "out:El price/MSEK": 44,
    "out:Heating price/MSEK": 18,
    "out:DH+ DHW/kWh/m2": 63,
    "out:El bought/kWh/m2": 2516.6,
    "out:Return %": 15,
    "out:Return/kSEK/y": 1311,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 1479000,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 73950,
    "out:EAHP (replacement cost)": 1105400,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 27.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 61.265983,
    "out:PV (m2 panels)": 321,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 57484.06,
    "out:PV (kWh sold)": 7474.38,
    "out:PV (% sold (El))": 13,
    "out:PV (kWh self-consumed)": 50009.68,
    "out:PV (ratio sold/self-consumed)": 0.15,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 560587,
    "out:EL kWh savings": -91247,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 128574.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 364544,
    "out:DH kr savings": 510134,
    "out:El kr savings": -155120,
    "out:El kr sold": 9530,
    "out:El kr saved": 85016,
    "out:El kr return": 94546,
    "out:% solar/total": 61,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 324013,
    "out:% savings (space heating)": 50.8,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 51.54,
    "out:Etvv": 0,
    "out:Ef": 33.64,
    "out:heatPumpQuantity": 10,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000201010001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 63.42,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 44.67,
    "out:Electricity (inc PV)": 33.34,
    "out:Total Energy Use Pre PV": 107.67,
    "out:Total Energy Use Post PV": 96.34,
    "out:Primary Energy": 97.67,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 23.59,
    "out:CO2 Operational/m2": 19.95,
    "out:Total CO2/m2": 43.54,
    "out:Total CO2 (tons)": 223.93,
    "out:Klimatpaverkan": -105.31,
    "out:Initial Cost/MSEK": 8.629125,
    "out:Running cost/(Apt SEK y)": 27326,
    "out:Running Cost over RSP/MSEK": 8.965,
    "out:LCP/MSEK": 1.675,
    "out:ROI% old": 17.06,
    "out:Payback discounted": 7,
    "out:Atemp": 5143,
    "out:Umean": 0.62,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 96,
    "out:Energy use kWh/m2": 96,
    "out:Energy savings %": 95.8,
    "out:Op energy cost/MSEK": 65,
    "out:El price/MSEK": 44,
    "out:Heating price/MSEK": 18,
    "out:DH+ DHW/kWh/m2": 63,
    "out:El bought/kWh/m2": 2476.7,
    "out:Return %": 15,
    "out:Return/kSEK/y": 1316,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 1479000,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 73950,
    "out:EAHP (replacement cost)": 1105400,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 26.47,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 61.265983,
    "out:PV (m2 panels)": 321,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 57484.06,
    "out:PV (kWh sold)": 7773.32,
    "out:PV (% sold (El))": 13.52,
    "out:PV (kWh self-consumed)": 49710.73,
    "out:PV (ratio sold/self-consumed)": 0.16,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 560587,
    "out:EL kWh savings": -89718,
    "out:Sewage heat recovery cost": 250448,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 367524,
    "out:DH kr savings": 510134,
    "out:El kr savings": -152521,
    "out:El kr sold": 9911,
    "out:El kr saved": 84508,
    "out:El kr return": 94419,
    "out:% solar/total": 61,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 326190,
    "out:% savings (space heating)": 50.47,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 53.78,
    "out:Etvv": 0,
    "out:Ef": 33.34,
    "out:heatPumpQuantity": 10,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000201010001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 58.84,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 44.58,
    "out:Electricity (inc PV)": 33.22,
    "out:Total Energy Use Pre PV": 103.58,
    "out:Total Energy Use Post PV": 92.22,
    "out:Primary Energy": 94.33,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 23.59,
    "out:CO2 Operational/m2": 18.46,
    "out:Total CO2/m2": 42.04,
    "out:Total CO2 (tons)": 216.23,
    "out:Klimatpaverkan": -113.01,
    "out:Initial Cost/MSEK": 8.75775,
    "out:Running cost/(Apt SEK y)": 26020,
    "out:Running Cost over RSP/MSEK": 8.536,
    "out:LCP/MSEK": 1.976,
    "out:ROI% old": 17.51,
    "out:Payback discounted": 7,
    "out:Atemp": 5143,
    "out:Umean": 0.62,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 92,
    "out:Energy use kWh/m2": 92,
    "out:Energy savings %": 104.3,
    "out:Op energy cost/MSEK": 62,
    "out:El price/MSEK": 42,
    "out:Heating price/MSEK": 17,
    "out:DH+ DHW/kWh/m2": 59,
    "out:El bought/kWh/m2": 2470.6,
    "out:Return %": 16,
    "out:Return/kSEK/y": 1371,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 1479000,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 73950,
    "out:EAHP (replacement cost)": 1105400,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 26.38,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 61.265983,
    "out:PV (m2 panels)": 321,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 57484.06,
    "out:PV (kWh sold)": 7820.25,
    "out:PV (% sold (El))": 13.6,
    "out:PV (kWh self-consumed)": 49663.8,
    "out:PV (ratio sold/self-consumed)": 0.16,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 581159,
    "out:EL kWh savings": -89075,
    "out:Sewage heat recovery cost": 250448,
    "out:Balancing hydronic system cost": 128574.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 387399,
    "out:DH kr savings": 528855,
    "out:El kr savings": -151427,
    "out:El kr sold": 9971,
    "out:El kr saved": 84428,
    "out:El kr return": 94399,
    "out:% solar/total": 61,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 302616,
    "out:% savings (space heating)": 54.05,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 49.34,
    "out:Etvv": 0,
    "out:Ef": 33.22,
    "out:heatPumpQuantity": 10,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000201020000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 67.64,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 45.37,
    "out:Electricity (inc PV)": 30.5,
    "out:Total Energy Use Pre PV": 113.37,
    "out:Total Energy Use Post PV": 98.5,
    "out:Primary Energy": 94.11,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 35.54,
    "out:CO2 Operational/m2": -17.3,
    "out:Total CO2/m2": 18.24,
    "out:Total CO2 (tons)": 93.78,
    "out:Klimatpaverkan": -235.46,
    "out:Initial Cost/MSEK": 9.4485,
    "out:Running cost/(Apt SEK y)": 27153,
    "out:Running Cost over RSP/MSEK": 8.925,
    "out:LCP/MSEK": 0.896,
    "out:ROI% old": 15.64,
    "out:Payback discounted": 8,
    "out:Atemp": 5143,
    "out:Umean": 0.62,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 99,
    "out:Energy use kWh/m2": 99,
    "out:Energy savings %": 89.9,
    "out:Op energy cost/MSEK": 65,
    "out:El price/MSEK": 42,
    "out:Heating price/MSEK": 19,
    "out:DH+ DHW/kWh/m2": 68,
    "out:El bought/kWh/m2": 2275.2,
    "out:Return %": 14,
    "out:Return/kSEK/y": 1323,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 1479000,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 73950,
    "out:EAHP (replacement cost)": 1105400,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 27.17,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.33,
    "out:PV (kWp installed)": 122.531965,
    "out:PV (m2 panels)": 643,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 114968.11,
    "out:PV (kWh sold)": 47155.78,
    "out:PV (% sold (El))": 41.02,
    "out:PV (kWh self-consumed)": 67812.33,
    "out:PV (ratio sold/self-consumed)": 0.7,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 534872,
    "out:EL kWh savings": -75093,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 419198,
    "out:DH kr savings": 486733,
    "out:El kr savings": -127659,
    "out:El kr sold": 60124,
    "out:El kr saved": 115281,
    "out:El kr return": 175405,
    "out:% solar/total": 83,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 347872,
    "out:% savings (space heating)": 47.18,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 56.01,
    "out:Etvv": 0,
    "out:Ef": 30.5,
    "out:heatPumpQuantity": 10,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000201020000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 63,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 45.29,
    "out:Electricity (inc PV)": 30.4,
    "out:Total Energy Use Pre PV": 108.29,
    "out:Total Energy Use Post PV": 93.4,
    "out:Primary Energy": 90.79,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 35.54,
    "out:CO2 Operational/m2": -19.19,
    "out:Total CO2/m2": 16.35,
    "out:Total CO2 (tons)": 84.09,
    "out:Klimatpaverkan": -245.15,
    "out:Initial Cost/MSEK": 9.577,
    "out:Running cost/(Apt SEK y)": 25528,
    "out:Running Cost over RSP/MSEK": 8.391,
    "out:LCP/MSEK": 1.302,
    "out:ROI% old": 16.23,
    "out:Payback discounted": 8,
    "out:Atemp": 5143,
    "out:Umean": 0.62,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 93,
    "out:Energy use kWh/m2": 93,
    "out:Energy savings %": 102.2,
    "out:Op energy cost/MSEK": 61,
    "out:El price/MSEK": 40,
    "out:Heating price/MSEK": 18,
    "out:DH+ DHW/kWh/m2": 63,
    "out:El bought/kWh/m2": 2270.7,
    "out:Return %": 15,
    "out:Return/kSEK/y": 1392,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 1479000,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 73950,
    "out:EAHP (replacement cost)": 1105400,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 27.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.33,
    "out:PV (kWp installed)": 122.531965,
    "out:PV (m2 panels)": 643,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 114968.11,
    "out:PV (kWh sold)": 47232.84,
    "out:PV (% sold (El))": 41.08,
    "out:PV (kWh self-consumed)": 67735.27,
    "out:PV (ratio sold/self-consumed)": 0.7,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 560587,
    "out:EL kWh savings": -74560,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 128574.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 443604,
    "out:DH kr savings": 510134,
    "out:El kr savings": -126752,
    "out:El kr sold": 60222,
    "out:El kr saved": 115150,
    "out:El kr return": 175372,
    "out:% solar/total": 83,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 324013,
    "out:% savings (space heating)": 50.8,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 51.54,
    "out:Etvv": 0,
    "out:Ef": 30.4,
    "out:heatPumpQuantity": 10,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000201020001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 63.42,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 44.67,
    "out:Electricity (inc PV)": 30.13,
    "out:Total Energy Use Pre PV": 107.67,
    "out:Total Energy Use Post PV": 93.13,
    "out:Primary Energy": 91.88,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 35.94,
    "out:CO2 Operational/m2": -19.86,
    "out:Total CO2/m2": 16.08,
    "out:Total CO2 (tons)": 82.7,
    "out:Klimatpaverkan": -246.54,
    "out:Initial Cost/MSEK": 9.698875,
    "out:Running cost/(Apt SEK y)": 25405,
    "out:Running Cost over RSP/MSEK": 8.351,
    "out:LCP/MSEK": 1.22,
    "out:ROI% old": 16.08,
    "out:Payback discounted": 8,
    "out:Atemp": 5143,
    "out:Umean": 0.62,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 93,
    "out:Energy use kWh/m2": 93,
    "out:Energy savings %": 102.2,
    "out:Op energy cost/MSEK": 61,
    "out:El price/MSEK": 40,
    "out:Heating price/MSEK": 18,
    "out:DH+ DHW/kWh/m2": 63,
    "out:El bought/kWh/m2": 2235.2,
    "out:Return %": 14,
    "out:Return/kSEK/y": 1397,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 1479000,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 73950,
    "out:EAHP (replacement cost)": 1105400,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 26.47,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.33,
    "out:PV (kWp installed)": 122.531965,
    "out:PV (m2 panels)": 643,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 114968.11,
    "out:PV (kWh sold)": 47838.81,
    "out:PV (% sold (El))": 41.61,
    "out:PV (kWh self-consumed)": 67129.3,
    "out:PV (ratio sold/self-consumed)": 0.71,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 560587,
    "out:EL kWh savings": -73191,
    "out:Sewage heat recovery cost": 250448,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 446704,
    "out:DH kr savings": 510134,
    "out:El kr savings": -124424,
    "out:El kr sold": 60994,
    "out:El kr saved": 114120,
    "out:El kr return": 175114,
    "out:% solar/total": 82,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 326190,
    "out:% savings (space heating)": 50.47,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 53.78,
    "out:Etvv": 0,
    "out:Ef": 30.13,
    "out:heatPumpQuantity": 10,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000201020001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 58.84,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 44.58,
    "out:Electricity (inc PV)": 30.02,
    "out:Total Energy Use Pre PV": 103.58,
    "out:Total Energy Use Post PV": 89.02,
    "out:Primary Energy": 88.57,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 35.94,
    "out:CO2 Operational/m2": -21.41,
    "out:Total CO2/m2": 14.54,
    "out:Total CO2 (tons)": 74.75,
    "out:Klimatpaverkan": -254.49,
    "out:Initial Cost/MSEK": 9.8275,
    "out:Running cost/(Apt SEK y)": 24099,
    "out:Running Cost over RSP/MSEK": 7.921,
    "out:LCP/MSEK": 1.52,
    "out:ROI% old": 16.5,
    "out:Payback discounted": 7,
    "out:Atemp": 5143,
    "out:Umean": 0.62,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 89,
    "out:Energy use kWh/m2": 89,
    "out:Energy savings %": 111.2,
    "out:Op energy cost/MSEK": 58,
    "out:El price/MSEK": 38,
    "out:Heating price/MSEK": 17,
    "out:DH+ DHW/kWh/m2": 59,
    "out:El bought/kWh/m2": 2229.7,
    "out:Return %": 15,
    "out:Return/kSEK/y": 1452,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 1479000,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 73950,
    "out:EAHP (replacement cost)": 1105400,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 26.38,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.33,
    "out:PV (kWp installed)": 122.531965,
    "out:PV (m2 panels)": 643,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 114968.11,
    "out:PV (kWh sold)": 47932.75,
    "out:PV (% sold (El))": 41.69,
    "out:PV (kWh self-consumed)": 67035.36,
    "out:PV (ratio sold/self-consumed)": 0.72,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 581159,
    "out:EL kWh savings": -72614,
    "out:Sewage heat recovery cost": 250448,
    "out:Balancing hydronic system cost": 128574.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 466525,
    "out:DH kr savings": 528855,
    "out:El kr savings": -123444,
    "out:El kr sold": 61114,
    "out:El kr saved": 113960,
    "out:El kr return": 175074,
    "out:% solar/total": 82,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 302616,
    "out:% savings (space heating)": 54.05,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 49.34,
    "out:Etvv": 0,
    "out:Ef": 30.02,
    "out:heatPumpQuantity": 10,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000201100000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 62.05,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 44.43,
    "out:Electricity (inc PV)": 42.67,
    "out:Total Energy Use Pre PV": 106.43,
    "out:Total Energy Use Post PV": 104.67,
    "out:Primary Energy": 113.94,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 10.83,
    "out:CO2 Operational/m2": 28.18,
    "out:Total CO2/m2": 39.02,
    "out:Total CO2 (tons)": 200.67,
    "out:Klimatpaverkan": -128.57,
    "out:Initial Cost/MSEK": 7.41975,
    "out:Running cost/(Apt SEK y)": 29202,
    "out:Running Cost over RSP/MSEK": 9.561,
    "out:LCP/MSEK": 2.288,
    "out:ROI% old": 18.69,
    "out:Payback discounted": 7,
    "out:Atemp": 5143,
    "out:Umean": 0.62,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 105,
    "out:Energy use kWh/m2": 105,
    "out:Energy savings %": 79,
    "out:Op energy cost/MSEK": 68,
    "out:El price/MSEK": 48,
    "out:Heating price/MSEK": 18,
    "out:DH+ DHW/kWh/m2": 62,
    "out:El bought/kWh/m2": 3110,
    "out:Return %": 17,
    "out:Return/kSEK/y": 1237,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 1479000,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 73950,
    "out:EAHP (replacement cost)": 1105400,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 26.23,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 565730,
    "out:EL kWh savings": -137691,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 280739,
    "out:DH kr savings": 514814,
    "out:El kr savings": -234075,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 319112,
    "out:% savings (space heating)": 51.55,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 53.04,
    "out:Etvv": 0,
    "out:Ef": 42.67,
    "out:heatPumpQuantity": 10,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000201100000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 57.48,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 44.33,
    "out:Electricity (inc PV)": 42.53,
    "out:Total Energy Use Pre PV": 101.33,
    "out:Total Energy Use Post PV": 99.53,
    "out:Primary Energy": 110.58,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 10.83,
    "out:CO2 Operational/m2": 26.37,
    "out:Total CO2/m2": 37.2,
    "out:Total CO2 (tons)": 191.34,
    "out:Klimatpaverkan": -137.9,
    "out:Initial Cost/MSEK": 7.548375,
    "out:Running cost/(Apt SEK y)": 27572,
    "out:Running Cost over RSP/MSEK": 9.026,
    "out:LCP/MSEK": 2.695,
    "out:ROI% old": 19.39,
    "out:Payback discounted": 6,
    "out:Atemp": 5143,
    "out:Umean": 0.62,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 100,
    "out:Energy use kWh/m2": 100,
    "out:Energy savings %": 88,
    "out:Op energy cost/MSEK": 64,
    "out:El price/MSEK": 45,
    "out:Heating price/MSEK": 16,
    "out:DH+ DHW/kWh/m2": 57,
    "out:El bought/kWh/m2": 3102.9,
    "out:Return %": 17,
    "out:Return/kSEK/y": 1306,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 1479000,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 73950,
    "out:EAHP (replacement cost)": 1105400,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 26.13,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 591445,
    "out:EL kWh savings": -136955,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 128574.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 305392,
    "out:DH kr savings": 538215,
    "out:El kr savings": -232823,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 295631,
    "out:% savings (space heating)": 55.11,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 48.62,
    "out:Etvv": 0,
    "out:Ef": 42.53,
    "out:heatPumpQuantity": 10,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000201100001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 58.8,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 43.83,
    "out:Electricity (inc PV)": 42.29,
    "out:Total Energy Use Pre PV": 102.83,
    "out:Total Energy Use Post PV": 101.29,
    "out:Primary Energy": 112.04,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 11.24,
    "out:CO2 Operational/m2": 27.03,
    "out:Total CO2/m2": 38.26,
    "out:Total CO2 (tons)": 196.77,
    "out:Klimatpaverkan": -132.47,
    "out:Initial Cost/MSEK": 7.67025,
    "out:Running cost/(Apt SEK y)": 28112,
    "out:Running Cost over RSP/MSEK": 9.204,
    "out:LCP/MSEK": 2.395,
    "out:ROI% old": 18.75,
    "out:Payback discounted": 7,
    "out:Atemp": 5143,
    "out:Umean": 0.62,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 101,
    "out:Energy use kWh/m2": 101,
    "out:Energy savings %": 86.1,
    "out:Op energy cost/MSEK": 66,
    "out:El price/MSEK": 46,
    "out:Heating price/MSEK": 17,
    "out:DH+ DHW/kWh/m2": 59,
    "out:El bought/kWh/m2": 3068.2,
    "out:Return %": 17,
    "out:Return/kSEK/y": 1283,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 1479000,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 73950,
    "out:EAHP (replacement cost)": 1105400,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 25.63,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 581159,
    "out:EL kWh savings": -135742,
    "out:Sewage heat recovery cost": 250448,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 298093,
    "out:DH kr savings": 528855,
    "out:El kr savings": -230762,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 302407,
    "out:% savings (space heating)": 54.08,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 51.3,
    "out:Etvv": 0,
    "out:Ef": 42.29,
    "out:heatPumpQuantity": 10,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000201100001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 54.28,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 43.72,
    "out:Electricity (inc PV)": 42.14,
    "out:Total Energy Use Pre PV": 97.72,
    "out:Total Energy Use Post PV": 96.14,
    "out:Primary Energy": 108.68,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 11.24,
    "out:CO2 Operational/m2": 25.21,
    "out:Total CO2/m2": 36.45,
    "out:Total CO2 (tons)": 187.44,
    "out:Klimatpaverkan": -141.8,
    "out:Initial Cost/MSEK": 7.79875,
    "out:Running cost/(Apt SEK y)": 26479,
    "out:Running Cost over RSP/MSEK": 8.667,
    "out:LCP/MSEK": 2.803,
    "out:ROI% old": 19.42,
    "out:Payback discounted": 6,
    "out:Atemp": 5143,
    "out:Umean": 0.62,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 96,
    "out:Energy use kWh/m2": 96,
    "out:Energy savings %": 95.8,
    "out:Op energy cost/MSEK": 62,
    "out:El price/MSEK": 44,
    "out:Heating price/MSEK": 15,
    "out:DH+ DHW/kWh/m2": 54,
    "out:El bought/kWh/m2": 3060.2,
    "out:Return %": 17,
    "out:Return/kSEK/y": 1352,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 1479000,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 73950,
    "out:EAHP (replacement cost)": 1105400,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 25.52,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 606874,
    "out:EL kWh savings": -134961,
    "out:Sewage heat recovery cost": 250448,
    "out:Balancing hydronic system cost": 128574.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 322822,
    "out:DH kr savings": 552255,
    "out:El kr savings": -229434,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 279157,
    "out:% savings (space heating)": 57.61,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 46.9,
    "out:Etvv": 0,
    "out:Ef": 42.14,
    "out:heatPumpQuantity": 10,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000201110000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 62.05,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 44.43,
    "out:Electricity (inc PV)": 33.2,
    "out:Total Energy Use Pre PV": 106.43,
    "out:Total Energy Use Post PV": 95.2,
    "out:Primary Energy": 96.9,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 23.19,
    "out:CO2 Operational/m2": 19.45,
    "out:Total CO2/m2": 42.63,
    "out:Total CO2 (tons)": 219.27,
    "out:Klimatpaverkan": -109.97,
    "out:Initial Cost/MSEK": 8.4895,
    "out:Running cost/(Apt SEK y)": 26955,
    "out:Running Cost over RSP/MSEK": 8.843,
    "out:LCP/MSEK": 1.936,
    "out:ROI% old": 17.54,
    "out:Payback discounted": 7,
    "out:Atemp": 5143,
    "out:Umean": 0.62,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 95,
    "out:Energy use kWh/m2": 95,
    "out:Energy savings %": 97.9,
    "out:Op energy cost/MSEK": 64,
    "out:El price/MSEK": 43,
    "out:Heating price/MSEK": 18,
    "out:DH+ DHW/kWh/m2": 62,
    "out:El bought/kWh/m2": 2461.2,
    "out:Return %": 16,
    "out:Return/kSEK/y": 1332,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 1479000,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 73950,
    "out:EAHP (replacement cost)": 1105400,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 26.23,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 61.265983,
    "out:PV (m2 panels)": 321,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 57484.06,
    "out:PV (kWh sold)": 7892.12,
    "out:PV (% sold (El))": 13.73,
    "out:PV (kWh self-consumed)": 49591.94,
    "out:PV (ratio sold/self-consumed)": 0.16,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 565730,
    "out:EL kWh savings": -88999,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 373578,
    "out:DH kr savings": 514814,
    "out:El kr savings": -151298,
    "out:El kr sold": 10062,
    "out:El kr saved": 84306,
    "out:El kr return": 94369,
    "out:% solar/total": 61,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 319112,
    "out:% savings (space heating)": 51.55,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 53.04,
    "out:Etvv": 0,
    "out:Ef": 33.2,
    "out:heatPumpQuantity": 10,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000201110000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 57.48,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 44.33,
    "out:Electricity (inc PV)": 33.08,
    "out:Total Energy Use Pre PV": 101.33,
    "out:Total Energy Use Post PV": 90.08,
    "out:Primary Energy": 93.57,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 23.19,
    "out:CO2 Operational/m2": 17.59,
    "out:Total CO2/m2": 40.77,
    "out:Total CO2 (tons)": 209.7,
    "out:Klimatpaverkan": -119.54,
    "out:Initial Cost/MSEK": 8.618125,
    "out:Running cost/(Apt SEK y)": 25326,
    "out:Running Cost over RSP/MSEK": 8.308,
    "out:LCP/MSEK": 2.343,
    "out:ROI% old": 18.17,
    "out:Payback discounted": 7,
    "out:Atemp": 5143,
    "out:Umean": 0.62,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 90,
    "out:Energy use kWh/m2": 90,
    "out:Energy savings %": 108.9,
    "out:Op energy cost/MSEK": 60,
    "out:El price/MSEK": 41,
    "out:Heating price/MSEK": 16,
    "out:DH+ DHW/kWh/m2": 57,
    "out:El bought/kWh/m2": 2454.7,
    "out:Return %": 16,
    "out:Return/kSEK/y": 1400,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 1479000,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 73950,
    "out:EAHP (replacement cost)": 1105400,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 26.13,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 61.265983,
    "out:PV (m2 panels)": 321,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 57484.06,
    "out:PV (kWh sold)": 7942.28,
    "out:PV (% sold (El))": 13.82,
    "out:PV (kWh self-consumed)": 49541.77,
    "out:PV (ratio sold/self-consumed)": 0.16,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 591445,
    "out:EL kWh savings": -88338,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 128574.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 398167,
    "out:DH kr savings": 538215,
    "out:El kr savings": -150175,
    "out:El kr sold": 10126,
    "out:El kr saved": 84221,
    "out:El kr return": 94347,
    "out:% solar/total": 61,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 295631,
    "out:% savings (space heating)": 55.11,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 48.62,
    "out:Etvv": 0,
    "out:Ef": 33.08,
    "out:heatPumpQuantity": 10,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000201110001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 58.8,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 43.83,
    "out:Electricity (inc PV)": 32.86,
    "out:Total Energy Use Pre PV": 102.83,
    "out:Total Energy Use Post PV": 91.86,
    "out:Primary Energy": 95.06,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 23.59,
    "out:CO2 Operational/m2": 18,
    "out:Total CO2/m2": 41.59,
    "out:Total CO2 (tons)": 213.91,
    "out:Klimatpaverkan": -115.33,
    "out:Initial Cost/MSEK": 8.74,
    "out:Running cost/(Apt SEK y)": 25868,
    "out:Running Cost over RSP/MSEK": 8.486,
    "out:LCP/MSEK": 2.043,
    "out:ROI% old": 17.62,
    "out:Payback discounted": 7,
    "out:Atemp": 5143,
    "out:Umean": 0.62,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 92,
    "out:Energy use kWh/m2": 92,
    "out:Energy savings %": 104.3,
    "out:Op energy cost/MSEK": 61,
    "out:El price/MSEK": 42,
    "out:Heating price/MSEK": 17,
    "out:DH+ DHW/kWh/m2": 59,
    "out:El bought/kWh/m2": 2422.9,
    "out:Return %": 16,
    "out:Return/kSEK/y": 1377,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 1479000,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 73950,
    "out:EAHP (replacement cost)": 1105400,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 25.63,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 61.265983,
    "out:PV (m2 panels)": 321,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 57484.06,
    "out:PV (kWh sold)": 8192.14,
    "out:PV (% sold (El))": 14.25,
    "out:PV (kWh self-consumed)": 49291.91,
    "out:PV (ratio sold/self-consumed)": 0.17,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 581159,
    "out:EL kWh savings": -87251,
    "out:Sewage heat recovery cost": 250448,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 390973,
    "out:DH kr savings": 528855,
    "out:El kr savings": -148326,
    "out:El kr sold": 10445,
    "out:El kr saved": 83796,
    "out:El kr return": 94241,
    "out:% solar/total": 60,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 302407,
    "out:% savings (space heating)": 54.08,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 51.3,
    "out:Etvv": 0,
    "out:Ef": 32.86,
    "out:heatPumpQuantity": 10,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000201110001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 54.28,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 43.72,
    "out:Electricity (inc PV)": 32.73,
    "out:Total Energy Use Pre PV": 97.72,
    "out:Total Energy Use Post PV": 86.73,
    "out:Primary Energy": 91.74,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 23.59,
    "out:CO2 Operational/m2": 16.13,
    "out:Total CO2/m2": 39.72,
    "out:Total CO2 (tons)": 204.29,
    "out:Klimatpaverkan": -124.95,
    "out:Initial Cost/MSEK": 8.868625,
    "out:Running cost/(Apt SEK y)": 24236,
    "out:Running Cost over RSP/MSEK": 7.95,
    "out:LCP/MSEK": 2.45,
    "out:ROI% old": 18.23,
    "out:Payback discounted": 7,
    "out:Atemp": 5143,
    "out:Umean": 0.62,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 87,
    "out:Energy use kWh/m2": 87,
    "out:Energy savings %": 116.1,
    "out:Op energy cost/MSEK": 57,
    "out:El price/MSEK": 39,
    "out:Heating price/MSEK": 15,
    "out:DH+ DHW/kWh/m2": 54,
    "out:El bought/kWh/m2": 2415.6,
    "out:Return %": 16,
    "out:Return/kSEK/y": 1446,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 1479000,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 73950,
    "out:EAHP (replacement cost)": 1105400,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 25.52,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 61.265983,
    "out:PV (m2 panels)": 321,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 57484.06,
    "out:PV (kWh sold)": 8250.73,
    "out:PV (% sold (El))": 14.35,
    "out:PV (kWh self-consumed)": 49233.33,
    "out:PV (ratio sold/self-consumed)": 0.17,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 606874,
    "out:EL kWh savings": -86551,
    "out:Sewage heat recovery cost": 250448,
    "out:Balancing hydronic system cost": 128574.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 415639,
    "out:DH kr savings": 552255,
    "out:El kr savings": -147136,
    "out:El kr sold": 10520,
    "out:El kr saved": 83697,
    "out:El kr return": 94216,
    "out:% solar/total": 60,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 279157,
    "out:% savings (space heating)": 57.61,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 46.9,
    "out:Etvv": 0,
    "out:Ef": 32.73,
    "out:heatPumpQuantity": 10,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000201120000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 62.05,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 44.43,
    "out:Electricity (inc PV)": 30.01,
    "out:Total Energy Use Pre PV": 106.43,
    "out:Total Energy Use Post PV": 92.01,
    "out:Primary Energy": 91.14,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 35.54,
    "out:CO2 Operational/m2": -20.49,
    "out:Total CO2/m2": 15.05,
    "out:Total CO2 (tons)": 77.42,
    "out:Klimatpaverkan": -251.82,
    "out:Initial Cost/MSEK": 9.559375,
    "out:Running cost/(Apt SEK y)": 25035,
    "out:Running Cost over RSP/MSEK": 8.229,
    "out:LCP/MSEK": 1.48,
    "out:ROI% old": 16.5,
    "out:Payback discounted": 7,
    "out:Atemp": 5143,
    "out:Umean": 0.62,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 92,
    "out:Energy use kWh/m2": 92,
    "out:Energy savings %": 104.3,
    "out:Op energy cost/MSEK": 60,
    "out:El price/MSEK": 39,
    "out:Heating price/MSEK": 18,
    "out:DH+ DHW/kWh/m2": 62,
    "out:El bought/kWh/m2": 2221.3,
    "out:Return %": 15,
    "out:Return/kSEK/y": 1412,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 1479000,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 73950,
    "out:EAHP (replacement cost)": 1105400,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 26.23,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.33,
    "out:PV (kWp installed)": 122.531965,
    "out:PV (m2 panels)": 643,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 114968.11,
    "out:PV (kWh sold)": 48076.13,
    "out:PV (% sold (El))": 41.82,
    "out:PV (kWh self-consumed)": 66891.98,
    "out:PV (ratio sold/self-consumed)": 0.72,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 565730,
    "out:EL kWh savings": -72546,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 452782,
    "out:DH kr savings": 514814,
    "out:El kr savings": -123329,
    "out:El kr sold": 61297,
    "out:El kr saved": 113716,
    "out:El kr return": 175013,
    "out:% solar/total": 82,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 319112,
    "out:% savings (space heating)": 51.55,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 53.04,
    "out:Etvv": 0,
    "out:Ef": 30.01,
    "out:heatPumpQuantity": 10,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000201120000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 57.48,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 44.33,
    "out:Electricity (inc PV)": 29.89,
    "out:Total Energy Use Pre PV": 101.33,
    "out:Total Energy Use Post PV": 86.89,
    "out:Primary Energy": 87.84,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 35.54,
    "out:CO2 Operational/m2": -22.4,
    "out:Total CO2/m2": 13.14,
    "out:Total CO2 (tons)": 67.6,
    "out:Klimatpaverkan": -261.64,
    "out:Initial Cost/MSEK": 9.687875,
    "out:Running cost/(Apt SEK y)": 23406,
    "out:Running Cost over RSP/MSEK": 7.694,
    "out:LCP/MSEK": 1.887,
    "out:ROI% old": 17.07,
    "out:Payback discounted": 7,
    "out:Atemp": 5143,
    "out:Umean": 0.62,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 87,
    "out:Energy use kWh/m2": 87,
    "out:Energy savings %": 116.1,
    "out:Op energy cost/MSEK": 56,
    "out:El price/MSEK": 37,
    "out:Heating price/MSEK": 16,
    "out:DH+ DHW/kWh/m2": 57,
    "out:El bought/kWh/m2": 2215.5,
    "out:Return %": 15,
    "out:Return/kSEK/y": 1481,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 1479000,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 73950,
    "out:EAHP (replacement cost)": 1105400,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 26.13,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.33,
    "out:PV (kWp installed)": 122.531965,
    "out:PV (m2 panels)": 643,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 114968.11,
    "out:PV (kWh sold)": 48175.62,
    "out:PV (% sold (El))": 41.9,
    "out:PV (kWh self-consumed)": 66792.49,
    "out:PV (ratio sold/self-consumed)": 0.72,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 591445,
    "out:EL kWh savings": -71954,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 128574.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 477316,
    "out:DH kr savings": 538215,
    "out:El kr savings": -122322,
    "out:El kr sold": 61424,
    "out:El kr saved": 113547,
    "out:El kr return": 174971,
    "out:% solar/total": 82,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 295631,
    "out:% savings (space heating)": 55.11,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 48.62,
    "out:Etvv": 0,
    "out:Ef": 29.89,
    "out:heatPumpQuantity": 10,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000201120001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 58.8,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 43.83,
    "out:Electricity (inc PV)": 29.7,
    "out:Total Energy Use Pre PV": 102.83,
    "out:Total Energy Use Post PV": 88.7,
    "out:Primary Energy": 89.37,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 35.94,
    "out:CO2 Operational/m2": -22.22,
    "out:Total CO2/m2": 13.72,
    "out:Total CO2 (tons)": 70.58,
    "out:Klimatpaverkan": -258.66,
    "out:Initial Cost/MSEK": 9.80975,
    "out:Running cost/(Apt SEK y)": 23951,
    "out:Running Cost over RSP/MSEK": 7.873,
    "out:LCP/MSEK": 1.586,
    "out:ROI% old": 16.6,
    "out:Payback discounted": 7,
    "out:Atemp": 5143,
    "out:Umean": 0.62,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 89,
    "out:Energy use kWh/m2": 89,
    "out:Energy savings %": 111.2,
    "out:Op energy cost/MSEK": 58,
    "out:El price/MSEK": 38,
    "out:Heating price/MSEK": 17,
    "out:DH+ DHW/kWh/m2": 59,
    "out:El bought/kWh/m2": 2187.2,
    "out:Return %": 15,
    "out:Return/kSEK/y": 1458,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 1479000,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 73950,
    "out:EAHP (replacement cost)": 1105400,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 25.63,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.33,
    "out:PV (kWp installed)": 122.531965,
    "out:PV (m2 panels)": 643,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 114968.11,
    "out:PV (kWh sold)": 48665.4,
    "out:PV (% sold (El))": 42.33,
    "out:PV (kWh self-consumed)": 66302.72,
    "out:PV (ratio sold/self-consumed)": 0.73,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 581159,
    "out:EL kWh savings": -70980,
    "out:Sewage heat recovery cost": 250448,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 470237,
    "out:DH kr savings": 528855,
    "out:El kr savings": -120666,
    "out:El kr sold": 62048,
    "out:El kr saved": 112715,
    "out:El kr return": 174763,
    "out:% solar/total": 81,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 302407,
    "out:% savings (space heating)": 54.08,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 51.3,
    "out:Etvv": 0,
    "out:Ef": 29.7,
    "out:heatPumpQuantity": 10,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000201120001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 54.28,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 43.72,
    "out:Electricity (inc PV)": 29.58,
    "out:Total Energy Use Pre PV": 97.72,
    "out:Total Energy Use Post PV": 83.58,
    "out:Primary Energy": 86.07,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 35.94,
    "out:CO2 Operational/m2": -24.14,
    "out:Total CO2/m2": 11.8,
    "out:Total CO2 (tons)": 60.67,
    "out:Klimatpaverkan": -268.57,
    "out:Initial Cost/MSEK": 9.938375,
    "out:Running cost/(Apt SEK y)": 22319,
    "out:Running Cost over RSP/MSEK": 7.337,
    "out:LCP/MSEK": 1.994,
    "out:ROI% old": 17.15,
    "out:Payback discounted": 7,
    "out:Atemp": 5143,
    "out:Umean": 0.62,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 84,
    "out:Energy use kWh/m2": 84,
    "out:Energy savings %": 123.8,
    "out:Op energy cost/MSEK": 54,
    "out:El price/MSEK": 36,
    "out:Heating price/MSEK": 15,
    "out:DH+ DHW/kWh/m2": 54,
    "out:El bought/kWh/m2": 2180.6,
    "out:Return %": 15,
    "out:Return/kSEK/y": 1526,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 1479000,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 73950,
    "out:EAHP (replacement cost)": 1105400,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 25.52,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.33,
    "out:PV (kWp installed)": 122.531965,
    "out:PV (m2 panels)": 643,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 114968.11,
    "out:PV (kWh sold)": 48779.57,
    "out:PV (% sold (El))": 42.43,
    "out:PV (kWh self-consumed)": 66188.54,
    "out:PV (ratio sold/self-consumed)": 0.74,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 606874,
    "out:EL kWh savings": -70353,
    "out:Sewage heat recovery cost": 250448,
    "out:Balancing hydronic system cost": 128574.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 494850,
    "out:DH kr savings": 552255,
    "out:El kr savings": -119600,
    "out:El kr sold": 62194,
    "out:El kr saved": 112521,
    "out:El kr return": 174714,
    "out:% solar/total": 81,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 279157,
    "out:% savings (space heating)": 57.61,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 46.9,
    "out:Etvv": 0,
    "out:Ef": 29.58,
    "out:heatPumpQuantity": 10,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000202000000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 16.1,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 60.99,
    "out:Electricity (inc PV)": 57.1,
    "out:Total Energy Use Pre PV": 76.99,
    "out:Total Energy Use Post PV": 73.1,
    "out:Primary Energy": 110.08,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 11.39,
    "out:CO2 Operational/m2": 13.81,
    "out:Total CO2/m2": 25.21,
    "out:Total CO2 (tons)": 129.63,
    "out:Klimatpaverkan": -199.61,
    "out:Initial Cost/MSEK": 9.960875,
    "out:Running cost/(Apt SEK y)": 17845,
    "out:Running Cost over RSP/MSEK": 5.815,
    "out:LCP/MSEK": 3.493,
    "out:ROI% old": 19.3,
    "out:Payback discounted": 6,
    "out:Atemp": 5143,
    "out:Umean": 0.62,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 73,
    "out:Energy use kWh/m2": 73,
    "out:Energy savings %": 157.5,
    "out:Op energy cost/MSEK": 40,
    "out:El price/MSEK": 34,
    "out:Heating price/MSEK": 5,
    "out:DH+ DHW/kWh/m2": 16,
    "out:El bought/kWh/m2": 4269.2,
    "out:Return %": 17,
    "out:Return/kSEK/y": 1714,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 2958000,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 147900,
    "out:EAHP (replacement cost)": 2204400,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 42.79,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 802308,
    "out:EL kWh savings": -211908,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 369856,
    "out:DH kr savings": 730100,
    "out:El kr savings": -360244,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 82813,
    "out:% savings (space heating)": 87.43,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 10.42,
    "out:Etvv": 0,
    "out:Ef": 57.1,
    "out:heatPumpQuantity": 20,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000202000000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 13.05,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 60.43,
    "out:Electricity (inc PV)": 56.36,
    "out:Total Energy Use Pre PV": 73.43,
    "out:Total Energy Use Post PV": 69.36,
    "out:Primary Energy": 107.03,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 11.39,
    "out:CO2 Operational/m2": 12.66,
    "out:Total CO2/m2": 24.05,
    "out:Total CO2 (tons)": 123.69,
    "out:Klimatpaverkan": -205.55,
    "out:Initial Cost/MSEK": 10.0895,
    "out:Running cost/(Apt SEK y)": 16763,
    "out:Running Cost over RSP/MSEK": 5.46,
    "out:LCP/MSEK": 3.72,
    "out:ROI% old": 19.55,
    "out:Payback discounted": 6,
    "out:Atemp": 5143,
    "out:Umean": 0.62,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 69,
    "out:Energy use kWh/m2": 69,
    "out:Energy savings %": 172.5,
    "out:Op energy cost/MSEK": 37,
    "out:El price/MSEK": 33,
    "out:Heating price/MSEK": 4,
    "out:DH+ DHW/kWh/m2": 13,
    "out:El bought/kWh/m2": 4230,
    "out:Return %": 17,
    "out:Return/kSEK/y": 1760,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 2958000,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 147900,
    "out:EAHP (replacement cost)": 2204400,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 42.23,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 817737,
    "out:EL kWh savings": -208107,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 128574.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 390359,
    "out:DH kr savings": 744141,
    "out:El kr savings": -353781,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 67121,
    "out:% savings (space heating)": 89.81,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 7.96,
    "out:Etvv": 0,
    "out:Ef": 56.36,
    "out:heatPumpQuantity": 20,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000202000001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 13.94,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 59.67,
    "out:Electricity (inc PV)": 56.27,
    "out:Total Energy Use Pre PV": 73.67,
    "out:Total Energy Use Post PV": 70.27,
    "out:Primary Energy": 107.88,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 11.8,
    "out:CO2 Operational/m2": 12.92,
    "out:Total CO2/m2": 24.71,
    "out:Total CO2 (tons)": 127.1,
    "out:Klimatpaverkan": -202.14,
    "out:Initial Cost/MSEK": 10.211375,
    "out:Running cost/(Apt SEK y)": 16926,
    "out:Running Cost over RSP/MSEK": 5.514,
    "out:LCP/MSEK": 3.544,
    "out:ROI% old": 19.24,
    "out:Payback discounted": 6,
    "out:Atemp": 5143,
    "out:Umean": 0.62,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 70,
    "out:Energy use kWh/m2": 70,
    "out:Energy savings %": 168.6,
    "out:Op energy cost/MSEK": 38,
    "out:El price/MSEK": 33,
    "out:Heating price/MSEK": 4,
    "out:DH+ DHW/kWh/m2": 14,
    "out:El bought/kWh/m2": 4176.6,
    "out:Return %": 17,
    "out:Return/kSEK/y": 1753,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 2958000,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 147900,
    "out:EAHP (replacement cost)": 2204400,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 41.47,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 812594,
    "out:EL kWh savings": -207597,
    "out:Sewage heat recovery cost": 250448,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 386545,
    "out:DH kr savings": 739460,
    "out:El kr savings": -352916,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 71706,
    "out:% savings (space heating)": 89.11,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 9.44,
    "out:Etvv": 0,
    "out:Ef": 56.27,
    "out:heatPumpQuantity": 20,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000202000001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 11.09,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 59.05,
    "out:Electricity (inc PV)": 55.48,
    "out:Total Energy Use Pre PV": 70.05,
    "out:Total Energy Use Post PV": 66.48,
    "out:Primary Energy": 104.85,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 11.8,
    "out:CO2 Operational/m2": 11.75,
    "out:Total CO2/m2": 23.55,
    "out:Total CO2 (tons)": 121.12,
    "out:Klimatpaverkan": -208.12,
    "out:Initial Cost/MSEK": 10.339875,
    "out:Running cost/(Apt SEK y)": 15832,
    "out:Running Cost over RSP/MSEK": 5.155,
    "out:LCP/MSEK": 3.775,
    "out:ROI% old": 19.5,
    "out:Payback discounted": 6,
    "out:Atemp": 5143,
    "out:Umean": 0.62,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 66,
    "out:Energy use kWh/m2": 66,
    "out:Energy savings %": 184.8,
    "out:Op energy cost/MSEK": 35,
    "out:El price/MSEK": 31,
    "out:Heating price/MSEK": 3,
    "out:DH+ DHW/kWh/m2": 11,
    "out:El bought/kWh/m2": 4133.2,
    "out:Return %": 17,
    "out:Return/kSEK/y": 1799,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 2958000,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 147900,
    "out:EAHP (replacement cost)": 2204400,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 40.85,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 828023,
    "out:EL kWh savings": -203576,
    "out:Sewage heat recovery cost": 250448,
    "out:Balancing hydronic system cost": 128574.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 407421,
    "out:DH kr savings": 753501,
    "out:El kr savings": -346079,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 57024,
    "out:% savings (space heating)": 91.34,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 7.12,
    "out:Etvv": 0,
    "out:Ef": 55.48,
    "out:heatPumpQuantity": 20,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000202010000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 16.1,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 60.99,
    "out:Electricity (inc PV)": 46.53,
    "out:Total Energy Use Pre PV": 76.99,
    "out:Total Energy Use Post PV": 62.53,
    "out:Primary Energy": 91.05,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 23.75,
    "out:CO2 Operational/m2": 10.52,
    "out:Total CO2/m2": 34.26,
    "out:Total CO2 (tons)": 176.22,
    "out:Klimatpaverkan": -153.02,
    "out:Initial Cost/MSEK": 11.03075,
    "out:Running cost/(Apt SEK y)": 15540,
    "out:Running Cost over RSP/MSEK": 5.078,
    "out:LCP/MSEK": 3.16,
    "out:ROI% old": 18.38,
    "out:Payback discounted": 7,
    "out:Atemp": 5143,
    "out:Umean": 0.62,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 63,
    "out:Energy use kWh/m2": 63,
    "out:Energy savings %": 198.4,
    "out:Op energy cost/MSEK": 35,
    "out:El price/MSEK": 30,
    "out:Heating price/MSEK": 5,
    "out:DH+ DHW/kWh/m2": 16,
    "out:El bought/kWh/m2": 3554.7,
    "out:Return %": 16,
    "out:Return/kSEK/y": 1811,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 2958000,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 147900,
    "out:EAHP (replacement cost)": 2204400,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 42.79,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 61.265983,
    "out:PV (m2 panels)": 321,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 57484.06,
    "out:PV (kWh sold)": 2121,
    "out:PV (% sold (El))": 3.69,
    "out:PV (kWh self-consumed)": 55363.06,
    "out:PV (ratio sold/self-consumed)": 0.04,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 802308,
    "out:EL kWh savings": -157519,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 465022,
    "out:DH kr savings": 730100,
    "out:El kr savings": -267783,
    "out:El kr sold": 2704,
    "out:El kr saved": 94117,
    "out:El kr return": 96821,
    "out:% solar/total": 68,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 82813,
    "out:% savings (space heating)": 87.43,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 10.42,
    "out:Etvv": 0,
    "out:Ef": 46.53,
    "out:heatPumpQuantity": 20,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000202010000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 13.05,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 60.43,
    "out:Electricity (inc PV)": 45.83,
    "out:Total Energy Use Pre PV": 73.43,
    "out:Total Energy Use Post PV": 58.83,
    "out:Primary Energy": 88.07,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 23.75,
    "out:CO2 Operational/m2": 9.25,
    "out:Total CO2/m2": 32.99,
    "out:Total CO2 (tons)": 169.68,
    "out:Klimatpaverkan": -159.56,
    "out:Initial Cost/MSEK": 11.15925,
    "out:Running cost/(Apt SEK y)": 14459,
    "out:Running Cost over RSP/MSEK": 4.724,
    "out:LCP/MSEK": 3.386,
    "out:ROI% old": 18.62,
    "out:Payback discounted": 7,
    "out:Atemp": 5143,
    "out:Umean": 0.62,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 59,
    "out:Energy use kWh/m2": 59,
    "out:Energy savings %": 218.6,
    "out:Op energy cost/MSEK": 33,
    "out:El price/MSEK": 28,
    "out:Heating price/MSEK": 4,
    "out:DH+ DHW/kWh/m2": 13,
    "out:El bought/kWh/m2": 3516.8,
    "out:Return %": 17,
    "out:Return/kSEK/y": 1857,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 2958000,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 147900,
    "out:EAHP (replacement cost)": 2204400,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 42.23,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 61.265983,
    "out:PV (m2 panels)": 321,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 57484.06,
    "out:PV (kWh sold)": 2249.39,
    "out:PV (% sold (El))": 3.91,
    "out:PV (kWh self-consumed)": 55234.67,
    "out:PV (ratio sold/self-consumed)": 0.04,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 817737,
    "out:EL kWh savings": -153926,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 128574.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 485334,
    "out:DH kr savings": 744141,
    "out:El kr savings": -261674,
    "out:El kr sold": 2868,
    "out:El kr saved": 93899,
    "out:El kr return": 96767,
    "out:% solar/total": 68,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 67121,
    "out:% savings (space heating)": 89.81,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 7.96,
    "out:Etvv": 0,
    "out:Ef": 45.83,
    "out:heatPumpQuantity": 20,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000202010001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 13.94,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 59.67,
    "out:Electricity (inc PV)": 45.74,
    "out:Total Energy Use Pre PV": 73.67,
    "out:Total Energy Use Post PV": 59.74,
    "out:Primary Energy": 88.93,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 24.15,
    "out:CO2 Operational/m2": 9.34,
    "out:Total CO2/m2": 33.49,
    "out:Total CO2 (tons)": 172.22,
    "out:Klimatpaverkan": -157.02,
    "out:Initial Cost/MSEK": 11.281125,
    "out:Running cost/(Apt SEK y)": 14624,
    "out:Running Cost over RSP/MSEK": 4.778,
    "out:LCP/MSEK": 3.21,
    "out:ROI% old": 18.35,
    "out:Payback discounted": 7,
    "out:Atemp": 5143,
    "out:Umean": 0.62,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 60,
    "out:Energy use kWh/m2": 60,
    "out:Energy savings %": 213.3,
    "out:Op energy cost/MSEK": 33,
    "out:El price/MSEK": 28,
    "out:Heating price/MSEK": 4,
    "out:DH+ DHW/kWh/m2": 14,
    "out:El bought/kWh/m2": 3465.3,
    "out:Return %": 16,
    "out:Return/kSEK/y": 1850,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 2958000,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 147900,
    "out:EAHP (replacement cost)": 2204400,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 41.47,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 61.265983,
    "out:PV (m2 panels)": 321,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 57484.06,
    "out:PV (kWh sold)": 2431.35,
    "out:PV (% sold (El))": 4.23,
    "out:PV (kWh self-consumed)": 55052.71,
    "out:PV (ratio sold/self-consumed)": 0.04,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 812594,
    "out:EL kWh savings": -153445,
    "out:Sewage heat recovery cost": 250448,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 481703,
    "out:DH kr savings": 739460,
    "out:El kr savings": -260857,
    "out:El kr sold": 3100,
    "out:El kr saved": 93590,
    "out:El kr return": 96690,
    "out:% solar/total": 67,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 71706,
    "out:% savings (space heating)": 89.11,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 9.44,
    "out:Etvv": 0,
    "out:Ef": 45.74,
    "out:heatPumpQuantity": 20,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000202010001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 11.09,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 59.05,
    "out:Electricity (inc PV)": 45,
    "out:Total Energy Use Pre PV": 70.05,
    "out:Total Energy Use Post PV": 56,
    "out:Primary Energy": 85.98,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 24.15,
    "out:CO2 Operational/m2": 8.03,
    "out:Total CO2/m2": 32.18,
    "out:Total CO2 (tons)": 165.52,
    "out:Klimatpaverkan": -163.72,
    "out:Initial Cost/MSEK": 11.40975,
    "out:Running cost/(Apt SEK y)": 13531,
    "out:Running Cost over RSP/MSEK": 4.42,
    "out:LCP/MSEK": 3.44,
    "out:ROI% old": 18.59,
    "out:Payback discounted": 7,
    "out:Atemp": 5143,
    "out:Umean": 0.62,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 56,
    "out:Energy use kWh/m2": 56,
    "out:Energy savings %": 235.7,
    "out:Op energy cost/MSEK": 30,
    "out:El price/MSEK": 27,
    "out:Heating price/MSEK": 3,
    "out:DH+ DHW/kWh/m2": 11,
    "out:El bought/kWh/m2": 3423.5,
    "out:Return %": 17,
    "out:Return/kSEK/y": 1896,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 2958000,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 147900,
    "out:EAHP (replacement cost)": 2204400,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 40.85,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 61.265983,
    "out:PV (m2 panels)": 321,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 57484.06,
    "out:PV (kWh sold)": 2584.67,
    "out:PV (% sold (El))": 4.5,
    "out:PV (kWh self-consumed)": 54899.38,
    "out:PV (ratio sold/self-consumed)": 0.05,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 828023,
    "out:EL kWh savings": -149655,
    "out:Sewage heat recovery cost": 250448,
    "out:Balancing hydronic system cost": 128574.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 502383,
    "out:DH kr savings": 753501,
    "out:El kr savings": -254413,
    "out:El kr sold": 3295,
    "out:El kr saved": 93329,
    "out:El kr return": 96624,
    "out:% solar/total": 67,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 57024,
    "out:% savings (space heating)": 91.34,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 7.12,
    "out:Etvv": 0,
    "out:Ef": 45,
    "out:heatPumpQuantity": 20,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000202020000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 16.1,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 60.99,
    "out:Electricity (inc PV)": 41.87,
    "out:Total Energy Use Pre PV": 76.99,
    "out:Total Energy Use Post PV": 57.87,
    "out:Primary Energy": 82.67,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 36.1,
    "out:CO2 Operational/m2": -20.68,
    "out:Total CO2/m2": 15.42,
    "out:Total CO2 (tons)": 79.3,
    "out:Klimatpaverkan": -249.94,
    "out:Initial Cost/MSEK": 12.1005,
    "out:Running cost/(Apt SEK y)": 13530,
    "out:Running Cost over RSP/MSEK": 4.435,
    "out:LCP/MSEK": 2.733,
    "out:ROI% old": 17.51,
    "out:Payback discounted": 7,
    "out:Atemp": 5143,
    "out:Umean": 0.62,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 58,
    "out:Energy use kWh/m2": 58,
    "out:Energy savings %": 224.1,
    "out:Op energy cost/MSEK": 31,
    "out:El price/MSEK": 26,
    "out:Heating price/MSEK": 5,
    "out:DH+ DHW/kWh/m2": 16,
    "out:El bought/kWh/m2": 3191.5,
    "out:Return %": 16,
    "out:Return/kSEK/y": 1896,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 2958000,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 147900,
    "out:EAHP (replacement cost)": 2204400,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 42.79,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.33,
    "out:PV (kWp installed)": 122.531965,
    "out:PV (m2 panels)": 643,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 114968.11,
    "out:PV (kWh sold)": 33478.68,
    "out:PV (% sold (El))": 29.12,
    "out:PV (kWh self-consumed)": 81489.43,
    "out:PV (ratio sold/self-consumed)": 0.41,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 802308,
    "out:EL kWh savings": -133579,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 545701,
    "out:DH kr savings": 730100,
    "out:El kr savings": -227085,
    "out:El kr sold": 42685,
    "out:El kr saved": 138532,
    "out:El kr return": 181217,
    "out:% solar/total": 100,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 82813,
    "out:% savings (space heating)": 87.43,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 10.42,
    "out:Etvv": 0,
    "out:Ef": 41.87,
    "out:heatPumpQuantity": 20,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000202020000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 13.05,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 60.43,
    "out:Electricity (inc PV)": 41.25,
    "out:Total Energy Use Pre PV": 73.43,
    "out:Total Energy Use Post PV": 54.25,
    "out:Primary Energy": 79.83,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 36.1,
    "out:CO2 Operational/m2": -22.27,
    "out:Total CO2/m2": 13.83,
    "out:Total CO2 (tons)": 71.14,
    "out:Klimatpaverkan": -258.1,
    "out:Initial Cost/MSEK": 12.229,
    "out:Running cost/(Apt SEK y)": 12453,
    "out:Running Cost over RSP/MSEK": 4.081,
    "out:LCP/MSEK": 2.959,
    "out:ROI% old": 17.74,
    "out:Payback discounted": 7,
    "out:Atemp": 5143,
    "out:Umean": 0.62,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 54,
    "out:Energy use kWh/m2": 54,
    "out:Energy savings %": 248.1,
    "out:Op energy cost/MSEK": 29,
    "out:El price/MSEK": 24,
    "out:Heating price/MSEK": 4,
    "out:DH+ DHW/kWh/m2": 13,
    "out:El bought/kWh/m2": 3157.9,
    "out:Return %": 16,
    "out:Return/kSEK/y": 1941,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 2958000,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 147900,
    "out:EAHP (replacement cost)": 2204400,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 42.23,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.33,
    "out:PV (kWp installed)": 122.531965,
    "out:PV (m2 panels)": 643,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 114968.11,
    "out:PV (kWh sold)": 33924.18,
    "out:PV (% sold (El))": 29.51,
    "out:PV (kWh self-consumed)": 81043.94,
    "out:PV (ratio sold/self-consumed)": 0.42,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 817737,
    "out:EL kWh savings": -130395,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 128574.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 565722,
    "out:DH kr savings": 744141,
    "out:El kr savings": -221672,
    "out:El kr sold": 43253,
    "out:El kr saved": 137775,
    "out:El kr return": 181028,
    "out:% solar/total": 99,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 67121,
    "out:% savings (space heating)": 89.81,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 7.96,
    "out:Etvv": 0,
    "out:Ef": 41.25,
    "out:heatPumpQuantity": 20,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000202020001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 13.94,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 59.67,
    "out:Electricity (inc PV)": 41.17,
    "out:Total Energy Use Pre PV": 73.67,
    "out:Total Energy Use Post PV": 55.17,
    "out:Primary Energy": 80.72,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 36.5,
    "out:CO2 Operational/m2": -22.6,
    "out:Total CO2/m2": 13.9,
    "out:Total CO2 (tons)": 71.5,
    "out:Klimatpaverkan": -257.74,
    "out:Initial Cost/MSEK": 12.350875,
    "out:Running cost/(Apt SEK y)": 12622,
    "out:Running Cost over RSP/MSEK": 4.138,
    "out:LCP/MSEK": 2.781,
    "out:ROI% old": 17.5,
    "out:Payback discounted": 7,
    "out:Atemp": 5143,
    "out:Umean": 0.62,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 55,
    "out:Energy use kWh/m2": 55,
    "out:Energy savings %": 241.8,
    "out:Op energy cost/MSEK": 29,
    "out:El price/MSEK": 24,
    "out:Heating price/MSEK": 4,
    "out:DH+ DHW/kWh/m2": 14,
    "out:El bought/kWh/m2": 3112.3,
    "out:Return %": 16,
    "out:Return/kSEK/y": 1934,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 2958000,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 147900,
    "out:EAHP (replacement cost)": 2204400,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 41.47,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.33,
    "out:PV (kWp installed)": 122.531965,
    "out:PV (m2 panels)": 643,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 114968.11,
    "out:PV (kWh sold)": 34534.28,
    "out:PV (% sold (El))": 30.04,
    "out:PV (kWh self-consumed)": 80433.83,
    "out:PV (ratio sold/self-consumed)": 0.43,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 812594,
    "out:EL kWh savings": -129969,
    "out:Sewage heat recovery cost": 250448,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 562544,
    "out:DH kr savings": 739460,
    "out:El kr savings": -220948,
    "out:El kr sold": 44031,
    "out:El kr saved": 136738,
    "out:El kr return": 180769,
    "out:% solar/total": 98,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 71706,
    "out:% savings (space heating)": 89.11,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 9.44,
    "out:Etvv": 0,
    "out:Ef": 41.17,
    "out:heatPumpQuantity": 20,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000202020001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 11.09,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 59.05,
    "out:Electricity (inc PV)": 40.52,
    "out:Total Energy Use Pre PV": 70.05,
    "out:Total Energy Use Post PV": 51.52,
    "out:Primary Energy": 77.92,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 36.5,
    "out:CO2 Operational/m2": -24.25,
    "out:Total CO2/m2": 12.25,
    "out:Total CO2 (tons)": 63.03,
    "out:Klimatpaverkan": -266.21,
    "out:Initial Cost/MSEK": 12.4795,
    "out:Running cost/(Apt SEK y)": 11533,
    "out:Running Cost over RSP/MSEK": 3.78,
    "out:LCP/MSEK": 3.01,
    "out:ROI% old": 17.73,
    "out:Payback discounted": 7,
    "out:Atemp": 5143,
    "out:Umean": 0.62,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 52,
    "out:Energy use kWh/m2": 52,
    "out:Energy savings %": 261.5,
    "out:Op energy cost/MSEK": 27,
    "out:El price/MSEK": 23,
    "out:Heating price/MSEK": 3,
    "out:DH+ DHW/kWh/m2": 11,
    "out:El bought/kWh/m2": 3075.3,
    "out:Return %": 16,
    "out:Return/kSEK/y": 1980,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 2958000,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 147900,
    "out:EAHP (replacement cost)": 2204400,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 40.85,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.33,
    "out:PV (kWp installed)": 122.531965,
    "out:PV (m2 panels)": 643,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 114968.11,
    "out:PV (kWh sold)": 35037.29,
    "out:PV (% sold (El))": 30.48,
    "out:PV (kWh self-consumed)": 79930.82,
    "out:PV (ratio sold/self-consumed)": 0.44,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 828023,
    "out:EL kWh savings": -126608,
    "out:Sewage heat recovery cost": 250448,
    "out:Balancing hydronic system cost": 128574.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 582940,
    "out:DH kr savings": 753501,
    "out:El kr savings": -215233,
    "out:El kr sold": 44673,
    "out:El kr saved": 135882,
    "out:El kr return": 180555,
    "out:% solar/total": 98,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 57024,
    "out:% savings (space heating)": 91.34,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 7.12,
    "out:Etvv": 0,
    "out:Ef": 40.52,
    "out:heatPumpQuantity": 20,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000202100000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 13.26,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 59.21,
    "out:Electricity (inc PV)": 55.98,
    "out:Total Energy Use Pre PV": 72.21,
    "out:Total Energy Use Post PV": 68.98,
    "out:Primary Energy": 107.15,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 11.39,
    "out:CO2 Operational/m2": 12.5,
    "out:Total CO2/m2": 23.89,
    "out:Total CO2 (tons)": 122.87,
    "out:Klimatpaverkan": -206.37,
    "out:Initial Cost/MSEK": 10.07175,
    "out:Running cost/(Apt SEK y)": 16510,
    "out:Running Cost over RSP/MSEK": 5.377,
    "out:LCP/MSEK": 3.82,
    "out:ROI% old": 19.7,
    "out:Payback discounted": 6,
    "out:Atemp": 5143,
    "out:Umean": 0.62,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 69,
    "out:Energy use kWh/m2": 69,
    "out:Energy savings %": 172.5,
    "out:Op energy cost/MSEK": 37,
    "out:El price/MSEK": 32,
    "out:Heating price/MSEK": 4,
    "out:DH+ DHW/kWh/m2": 13,
    "out:El bought/kWh/m2": 4145,
    "out:Return %": 18,
    "out:Return/kSEK/y": 1770,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 2958000,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 147900,
    "out:EAHP (replacement cost)": 2204400,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 41.01,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 817737,
    "out:EL kWh savings": -206139,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 393705,
    "out:DH kr savings": 744141,
    "out:El kr savings": -350436,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 68190,
    "out:% savings (space heating)": 89.65,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 9.13,
    "out:Etvv": 0,
    "out:Ef": 55.98,
    "out:heatPumpQuantity": 20,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000202100000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 10.47,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 58.57,
    "out:Electricity (inc PV)": 55.18,
    "out:Total Energy Use Pre PV": 68.57,
    "out:Total Energy Use Post PV": 65.18,
    "out:Primary Energy": 104.13,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 11.39,
    "out:CO2 Operational/m2": 11.33,
    "out:Total CO2/m2": 22.73,
    "out:Total CO2 (tons)": 116.88,
    "out:Klimatpaverkan": -212.36,
    "out:Initial Cost/MSEK": 10.200375,
    "out:Running cost/(Apt SEK y)": 15411,
    "out:Running Cost over RSP/MSEK": 5.017,
    "out:LCP/MSEK": 4.052,
    "out:ROI% old": 19.96,
    "out:Payback discounted": 6,
    "out:Atemp": 5143,
    "out:Umean": 0.62,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 65,
    "out:Energy use kWh/m2": 65,
    "out:Energy savings %": 189.2,
    "out:Op energy cost/MSEK": 34,
    "out:El price/MSEK": 31,
    "out:Heating price/MSEK": 3,
    "out:DH+ DHW/kWh/m2": 10,
    "out:El bought/kWh/m2": 4100.1,
    "out:Return %": 18,
    "out:Return/kSEK/y": 1817,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 2958000,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 147900,
    "out:EAHP (replacement cost)": 2204400,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 40.37,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 833166,
    "out:EL kWh savings": -202038,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 128574.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 414716,
    "out:DH kr savings": 758181,
    "out:El kr savings": -343465,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 53870,
    "out:% savings (space heating)": 91.82,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 6.86,
    "out:Etvv": 0,
    "out:Ef": 55.18,
    "out:heatPumpQuantity": 20,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000202100001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 11.7,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 58.1,
    "out:Electricity (inc PV)": 55.29,
    "out:Total Energy Use Pre PV": 70.1,
    "out:Total Energy Use Post PV": 67.29,
    "out:Primary Energy": 105.41,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 11.8,
    "out:CO2 Operational/m2": 11.99,
    "out:Total CO2/m2": 23.79,
    "out:Total CO2 (tons)": 122.33,
    "out:Klimatpaverkan": -206.91,
    "out:Initial Cost/MSEK": 10.32225,
    "out:Running cost/(Apt SEK y)": 15958,
    "out:Running Cost over RSP/MSEK": 5.197,
    "out:LCP/MSEK": 3.75,
    "out:ROI% old": 19.48,
    "out:Payback discounted": 6,
    "out:Atemp": 5143,
    "out:Umean": 0.62,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 67,
    "out:Energy use kWh/m2": 67,
    "out:Energy savings %": 180.6,
    "out:Op energy cost/MSEK": 35,
    "out:El price/MSEK": 31,
    "out:Heating price/MSEK": 3,
    "out:DH+ DHW/kWh/m2": 12,
    "out:El bought/kWh/m2": 4067.3,
    "out:Return %": 17,
    "out:Return/kSEK/y": 1794,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 2958000,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 147900,
    "out:EAHP (replacement cost)": 2204400,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 39.9,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 822880,
    "out:EL kWh savings": -202591,
    "out:Sewage heat recovery cost": 250448,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 404416,
    "out:DH kr savings": 748821,
    "out:El kr savings": -344405,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 60170,
    "out:% savings (space heating)": 90.86,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 8.4,
    "out:Etvv": 0,
    "out:Ef": 55.29,
    "out:heatPumpQuantity": 20,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000202100001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 9.08,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 57.41,
    "out:Electricity (inc PV)": 54.46,
    "out:Total Energy Use Pre PV": 66.41,
    "out:Total Energy Use Post PV": 63.46,
    "out:Primary Energy": 102.4,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 11.8,
    "out:CO2 Operational/m2": 10.82,
    "out:Total CO2/m2": 22.62,
    "out:Total CO2 (tons)": 116.31,
    "out:Klimatpaverkan": -212.93,
    "out:Initial Cost/MSEK": 10.45075,
    "out:Running cost/(Apt SEK y)": 14848,
    "out:Running Cost over RSP/MSEK": 4.833,
    "out:LCP/MSEK": 3.986,
    "out:ROI% old": 19.73,
    "out:Payback discounted": 6,
    "out:Atemp": 5143,
    "out:Umean": 0.62,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 63,
    "out:Energy use kWh/m2": 63,
    "out:Energy savings %": 198.4,
    "out:Op energy cost/MSEK": 33,
    "out:El price/MSEK": 30,
    "out:Heating price/MSEK": 3,
    "out:DH+ DHW/kWh/m2": 9,
    "out:El bought/kWh/m2": 4018.9,
    "out:Return %": 18,
    "out:Return/kSEK/y": 1840,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 2958000,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 147900,
    "out:EAHP (replacement cost)": 2204400,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 39.21,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 838309,
    "out:EL kWh savings": -198292,
    "out:Sewage heat recovery cost": 250448,
    "out:Balancing hydronic system cost": 128574.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 425765,
    "out:DH kr savings": 762861,
    "out:El kr savings": -337096,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 46705,
    "out:% savings (space heating)": 92.91,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 6.26,
    "out:Etvv": 0,
    "out:Ef": 54.46,
    "out:heatPumpQuantity": 20,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000202110000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 13.26,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 59.21,
    "out:Electricity (inc PV)": 45.47,
    "out:Total Energy Use Pre PV": 72.21,
    "out:Total Energy Use Post PV": 58.47,
    "out:Primary Energy": 88.23,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 23.75,
    "out:CO2 Operational/m2": 8.81,
    "out:Total CO2/m2": 32.56,
    "out:Total CO2 (tons)": 167.47,
    "out:Klimatpaverkan": -161.77,
    "out:Initial Cost/MSEK": 11.1415,
    "out:Running cost/(Apt SEK y)": 14209,
    "out:Running Cost over RSP/MSEK": 4.642,
    "out:LCP/MSEK": 3.485,
    "out:ROI% old": 18.75,
    "out:Payback discounted": 7,
    "out:Atemp": 5143,
    "out:Umean": 0.62,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 58,
    "out:Energy use kWh/m2": 58,
    "out:Energy savings %": 224.1,
    "out:Op energy cost/MSEK": 32,
    "out:El price/MSEK": 28,
    "out:Heating price/MSEK": 4,
    "out:DH+ DHW/kWh/m2": 13,
    "out:El bought/kWh/m2": 3434.8,
    "out:Return %": 17,
    "out:Return/kSEK/y": 1867,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 2958000,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 147900,
    "out:EAHP (replacement cost)": 2204400,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 41.01,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 61.265983,
    "out:PV (m2 panels)": 321,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 57484.06,
    "out:PV (kWh sold)": 2542.69,
    "out:PV (% sold (El))": 4.42,
    "out:PV (kWh self-consumed)": 54941.36,
    "out:PV (ratio sold/self-consumed)": 0.05,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 817737,
    "out:EL kWh savings": -152069,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 488865,
    "out:DH kr savings": 744141,
    "out:El kr savings": -258518,
    "out:El kr sold": 3242,
    "out:El kr saved": 93400,
    "out:El kr return": 96642,
    "out:% solar/total": 67,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 68190,
    "out:% savings (space heating)": 89.65,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 9.13,
    "out:Etvv": 0,
    "out:Ef": 45.47,
    "out:heatPumpQuantity": 20,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000202110000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 10.47,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 58.57,
    "out:Electricity (inc PV)": 44.72,
    "out:Total Energy Use Pre PV": 68.57,
    "out:Total Energy Use Post PV": 54.72,
    "out:Primary Energy": 85.29,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 23.75,
    "out:CO2 Operational/m2": 7.5,
    "out:Total CO2/m2": 31.25,
    "out:Total CO2 (tons)": 160.7,
    "out:Klimatpaverkan": -168.54,
    "out:Initial Cost/MSEK": 11.270125,
    "out:Running cost/(Apt SEK y)": 13112,
    "out:Running Cost over RSP/MSEK": 4.282,
    "out:LCP/MSEK": 3.717,
    "out:ROI% old": 19,
    "out:Payback discounted": 6,
    "out:Atemp": 5143,
    "out:Umean": 0.62,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 55,
    "out:Energy use kWh/m2": 55,
    "out:Energy savings %": 241.8,
    "out:Op energy cost/MSEK": 29,
    "out:El price/MSEK": 26,
    "out:Heating price/MSEK": 3,
    "out:DH+ DHW/kWh/m2": 10,
    "out:El bought/kWh/m2": 3391.6,
    "out:Return %": 17,
    "out:Return/kSEK/y": 1913,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 2958000,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 147900,
    "out:EAHP (replacement cost)": 2204400,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 40.37,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 61.265983,
    "out:PV (m2 panels)": 321,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 57484.06,
    "out:PV (kWh sold)": 2704.19,
    "out:PV (% sold (El))": 4.7,
    "out:PV (kWh self-consumed)": 54779.87,
    "out:PV (ratio sold/self-consumed)": 0.05,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 833166,
    "out:EL kWh savings": -148208,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 128574.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 509676,
    "out:DH kr savings": 758181,
    "out:El kr savings": -251953,
    "out:El kr sold": 3448,
    "out:El kr saved": 93126,
    "out:El kr return": 96574,
    "out:% solar/total": 67,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 53870,
    "out:% savings (space heating)": 91.82,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 6.86,
    "out:Etvv": 0,
    "out:Ef": 44.72,
    "out:heatPumpQuantity": 20,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000202110001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 11.7,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 58.1,
    "out:Electricity (inc PV)": 44.82,
    "out:Total Energy Use Pre PV": 70.1,
    "out:Total Energy Use Post PV": 56.82,
    "out:Primary Energy": 86.55,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 24.15,
    "out:CO2 Operational/m2": 8.04,
    "out:Total CO2/m2": 32.19,
    "out:Total CO2 (tons)": 165.57,
    "out:Klimatpaverkan": -163.67,
    "out:Initial Cost/MSEK": 11.392,
    "out:Running cost/(Apt SEK y)": 13659,
    "out:Running Cost over RSP/MSEK": 4.462,
    "out:LCP/MSEK": 3.415,
    "out:ROI% old": 18.57,
    "out:Payback discounted": 7,
    "out:Atemp": 5143,
    "out:Umean": 0.62,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 57,
    "out:Energy use kWh/m2": 57,
    "out:Energy savings %": 229.8,
    "out:Op energy cost/MSEK": 31,
    "out:El price/MSEK": 27,
    "out:Heating price/MSEK": 3,
    "out:DH+ DHW/kWh/m2": 12,
    "out:El bought/kWh/m2": 3360.1,
    "out:Return %": 17,
    "out:Return/kSEK/y": 1890,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 2958000,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 147900,
    "out:EAHP (replacement cost)": 2204400,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 39.9,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 61.265983,
    "out:PV (m2 panels)": 321,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 57484.06,
    "out:PV (kWh sold)": 2825.29,
    "out:PV (% sold (El))": 4.91,
    "out:PV (kWh self-consumed)": 54658.76,
    "out:PV (ratio sold/self-consumed)": 0.05,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 822880,
    "out:EL kWh savings": -148728,
    "out:Sewage heat recovery cost": 250448,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 499586,
    "out:DH kr savings": 748821,
    "out:El kr savings": -252837,
    "out:El kr sold": 3602,
    "out:El kr saved": 92920,
    "out:El kr return": 96522,
    "out:% solar/total": 67,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 60170,
    "out:% savings (space heating)": 90.86,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 8.4,
    "out:Etvv": 0,
    "out:Ef": 44.82,
    "out:heatPumpQuantity": 20,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000202110001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 9.08,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 57.41,
    "out:Electricity (inc PV)": 44.03,
    "out:Total Energy Use Pre PV": 66.41,
    "out:Total Energy Use Post PV": 53.03,
    "out:Primary Energy": 83.64,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 24.15,
    "out:CO2 Operational/m2": 6.7,
    "out:Total CO2/m2": 30.85,
    "out:Total CO2 (tons)": 158.65,
    "out:Klimatpaverkan": -170.59,
    "out:Initial Cost/MSEK": 11.520625,
    "out:Running cost/(Apt SEK y)": 12552,
    "out:Running Cost over RSP/MSEK": 4.099,
    "out:LCP/MSEK": 3.649,
    "out:ROI% old": 18.81,
    "out:Payback discounted": 7,
    "out:Atemp": 5143,
    "out:Umean": 0.62,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 53,
    "out:Energy use kWh/m2": 53,
    "out:Energy savings %": 254.7,
    "out:Op energy cost/MSEK": 28,
    "out:El price/MSEK": 25,
    "out:Heating price/MSEK": 3,
    "out:DH+ DHW/kWh/m2": 9,
    "out:El bought/kWh/m2": 3313.7,
    "out:Return %": 17,
    "out:Return/kSEK/y": 1937,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 2958000,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 147900,
    "out:EAHP (replacement cost)": 2204400,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 39.21,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 61.265983,
    "out:PV (m2 panels)": 321,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 57484.06,
    "out:PV (kWh sold)": 3010.18,
    "out:PV (% sold (El))": 5.24,
    "out:PV (kWh self-consumed)": 54473.87,
    "out:PV (ratio sold/self-consumed)": 0.06,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 838309,
    "out:EL kWh savings": -144686,
    "out:Sewage heat recovery cost": 250448,
    "out:Balancing hydronic system cost": 128574.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 520732,
    "out:DH kr savings": 762861,
    "out:El kr savings": -245967,
    "out:El kr sold": 3838,
    "out:El kr saved": 92606,
    "out:El kr return": 96444,
    "out:% solar/total": 67,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 46705,
    "out:% savings (space heating)": 92.91,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 6.26,
    "out:Etvv": 0,
    "out:Ef": 44.03,
    "out:heatPumpQuantity": 20,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000202120000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 13.26,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 59.21,
    "out:Electricity (inc PV)": 40.93,
    "out:Total Energy Use Pre PV": 72.21,
    "out:Total Energy Use Post PV": 53.93,
    "out:Primary Energy": 80.07,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 36.1,
    "out:CO2 Operational/m2": -23.37,
    "out:Total CO2/m2": 12.73,
    "out:Total CO2 (tons)": 65.45,
    "out:Klimatpaverkan": -263.79,
    "out:Initial Cost/MSEK": 12.211375,
    "out:Running cost/(Apt SEK y)": 12210,
    "out:Running Cost over RSP/MSEK": 4.002,
    "out:LCP/MSEK": 3.055,
    "out:ROI% old": 17.86,
    "out:Payback discounted": 7,
    "out:Atemp": 5143,
    "out:Umean": 0.62,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 54,
    "out:Energy use kWh/m2": 54,
    "out:Energy savings %": 248.1,
    "out:Op energy cost/MSEK": 28,
    "out:El price/MSEK": 24,
    "out:Heating price/MSEK": 4,
    "out:DH+ DHW/kWh/m2": 13,
    "out:El bought/kWh/m2": 3085.4,
    "out:Return %": 16,
    "out:Return/kSEK/y": 1951,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 2958000,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 147900,
    "out:EAHP (replacement cost)": 2204400,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 41.01,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.33,
    "out:PV (kWp installed)": 122.531965,
    "out:PV (m2 panels)": 643,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 114968.11,
    "out:PV (kWh sold)": 34900.16,
    "out:PV (% sold (El))": 30.36,
    "out:PV (kWh self-consumed)": 80067.95,
    "out:PV (ratio sold/self-consumed)": 0.44,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 817737,
    "out:EL kWh savings": -128749,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 569764,
    "out:DH kr savings": 744141,
    "out:El kr savings": -218874,
    "out:El kr sold": 44498,
    "out:El kr saved": 136116,
    "out:El kr return": 180613,
    "out:% solar/total": 98,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 68190,
    "out:% savings (space heating)": 89.65,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 9.13,
    "out:Etvv": 0,
    "out:Ef": 40.93,
    "out:heatPumpQuantity": 20,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000202120000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 10.47,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 58.57,
    "out:Electricity (inc PV)": 40.27,
    "out:Total Energy Use Pre PV": 68.57,
    "out:Total Energy Use Post PV": 50.27,
    "out:Primary Energy": 77.28,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 36.1,
    "out:CO2 Operational/m2": -25.05,
    "out:Total CO2/m2": 11.06,
    "out:Total CO2 (tons)": 56.86,
    "out:Klimatpaverkan": -272.38,
    "out:Initial Cost/MSEK": 12.339875,
    "out:Running cost/(Apt SEK y)": 11116,
    "out:Running Cost over RSP/MSEK": 3.643,
    "out:LCP/MSEK": 3.286,
    "out:ROI% old": 18.09,
    "out:Payback discounted": 7,
    "out:Atemp": 5143,
    "out:Umean": 0.62,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 50,
    "out:Energy use kWh/m2": 50,
    "out:Energy savings %": 276,
    "out:Op energy cost/MSEK": 26,
    "out:El price/MSEK": 22,
    "out:Heating price/MSEK": 3,
    "out:DH+ DHW/kWh/m2": 10,
    "out:El bought/kWh/m2": 3047.1,
    "out:Return %": 16,
    "out:Return/kSEK/y": 1997,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 2958000,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 147900,
    "out:EAHP (replacement cost)": 2204400,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 40.37,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.33,
    "out:PV (kWp installed)": 122.531965,
    "out:PV (m2 panels)": 643,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 114968.11,
    "out:PV (kWh sold)": 35424.49,
    "out:PV (% sold (El))": 30.81,
    "out:PV (kWh self-consumed)": 79543.62,
    "out:PV (ratio sold/self-consumed)": 0.45,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 833166,
    "out:EL kWh savings": -125324,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 128574.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 590297,
    "out:DH kr savings": 758181,
    "out:El kr savings": -213051,
    "out:El kr sold": 45166,
    "out:El kr saved": 135224,
    "out:El kr return": 180390,
    "out:% solar/total": 97,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 53870,
    "out:% savings (space heating)": 91.82,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 6.86,
    "out:Etvv": 0,
    "out:Ef": 40.27,
    "out:heatPumpQuantity": 20,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000202120001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 11.7,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 58.1,
    "out:Electricity (inc PV)": 40.36,
    "out:Total Energy Use Pre PV": 70.1,
    "out:Total Energy Use Post PV": 52.36,
    "out:Primary Energy": 78.52,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 36.5,
    "out:CO2 Operational/m2": -24.76,
    "out:Total CO2/m2": 11.74,
    "out:Total CO2 (tons)": 60.39,
    "out:Klimatpaverkan": -268.85,
    "out:Initial Cost/MSEK": 12.46175,
    "out:Running cost/(Apt SEK y)": 11666,
    "out:Running Cost over RSP/MSEK": 3.825,
    "out:LCP/MSEK": 2.983,
    "out:ROI% old": 17.7,
    "out:Payback discounted": 7,
    "out:Atemp": 5143,
    "out:Umean": 0.62,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 52,
    "out:Energy use kWh/m2": 52,
    "out:Energy savings %": 261.5,
    "out:Op energy cost/MSEK": 27,
    "out:El price/MSEK": 23,
    "out:Heating price/MSEK": 3,
    "out:DH+ DHW/kWh/m2": 12,
    "out:El bought/kWh/m2": 3019.3,
    "out:Return %": 16,
    "out:Return/kSEK/y": 1974,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 2958000,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 147900,
    "out:EAHP (replacement cost)": 2204400,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 39.9,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.33,
    "out:PV (kWp installed)": 122.531965,
    "out:PV (m2 panels)": 643,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 114968.11,
    "out:PV (kWh sold)": 35809.34,
    "out:PV (% sold (El))": 31.15,
    "out:PV (kWh self-consumed)": 79158.77,
    "out:PV (ratio sold/self-consumed)": 0.45,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 822880,
    "out:EL kWh savings": -125785,
    "out:Sewage heat recovery cost": 250448,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 580643,
    "out:DH kr savings": 748821,
    "out:El kr savings": -213835,
    "out:El kr sold": 45657,
    "out:El kr saved": 134570,
    "out:El kr return": 180227,
    "out:% solar/total": 97,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 60170,
    "out:% savings (space heating)": 90.86,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 8.4,
    "out:Etvv": 0,
    "out:Ef": 40.36,
    "out:heatPumpQuantity": 20,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000202120001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 9.08,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 57.41,
    "out:Electricity (inc PV)": 39.66,
    "out:Total Energy Use Pre PV": 66.41,
    "out:Total Energy Use Post PV": 48.66,
    "out:Primary Energy": 75.77,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 36.5,
    "out:CO2 Operational/m2": -26.49,
    "out:Total CO2/m2": 10.02,
    "out:Total CO2 (tons)": 51.51,
    "out:Klimatpaverkan": -277.73,
    "out:Initial Cost/MSEK": 12.59,
    "out:Running cost/(Apt SEK y)": 10563,
    "out:Running Cost over RSP/MSEK": 3.462,
    "out:LCP/MSEK": 3.217,
    "out:ROI% old": 17.94,
    "out:Payback discounted": 7,
    "out:Atemp": 5143,
    "out:Umean": 0.62,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 49,
    "out:Energy use kWh/m2": 49,
    "out:Energy savings %": 283.7,
    "out:Op energy cost/MSEK": 24,
    "out:El price/MSEK": 21,
    "out:Heating price/MSEK": 3,
    "out:DH+ DHW/kWh/m2": 9,
    "out:El bought/kWh/m2": 2978.2,
    "out:Return %": 16,
    "out:Return/kSEK/y": 2020,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 2958000,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 147900,
    "out:EAHP (replacement cost)": 2204400,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 39.21,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.33,
    "out:PV (kWp installed)": 122.531965,
    "out:PV (m2 panels)": 643,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 114968.11,
    "out:PV (kWh sold)": 36381.79,
    "out:PV (% sold (El))": 31.65,
    "out:PV (kWh self-consumed)": 78586.32,
    "out:PV (ratio sold/self-consumed)": 0.46,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 838309,
    "out:EL kWh savings": -122198,
    "out:Sewage heat recovery cost": 250448,
    "out:Balancing hydronic system cost": 128574.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 601511,
    "out:DH kr savings": 762861,
    "out:El kr savings": -207737,
    "out:El kr sold": 46387,
    "out:El kr saved": 133597,
    "out:El kr return": 179984,
    "out:% solar/total": 96,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 46705,
    "out:% savings (space heating)": 92.91,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 6.26,
    "out:Etvv": 0,
    "out:Ef": 39.66,
    "out:heatPumpQuantity": 20,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000000000000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 112.54,
    "out:DHW (DH)": 43.5,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 15.9,
    "out:Total Energy Use Pre PV": 171.9,
    "out:Total Energy Use Post PV": 171.9,
    "out:Primary Energy": 124.9,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 8.96,
    "out:CO2 Operational/m2": 58.26,
    "out:Total CO2/m2": 67.22,
    "out:Total CO2 (tons)": 345.7,
    "out:Klimatpaverkan": 16.46,
    "out:Initial Cost/MSEK": 3.2675,
    "out:Running cost/(Apt SEK y)": 53515,
    "out:Running Cost over RSP/MSEK": 17.577,
    "out:LCP/MSEK": -1.575,
    "out:ROI% old": 7.4,
    "out:Payback discounted": 16,
    "out:Atemp": 5143,
    "out:Umean": 0.57,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 172,
    "out:Energy use kWh/m2": 172,
    "out:Energy savings %": 9.3,
    "out:Op energy cost/MSEK": 129,
    "out:El price/MSEK": 77,
    "out:Heating price/MSEK": 45,
    "out:DH+ DHW/kWh/m2": 156,
    "out:El bought/kWh/m2": 1113,
    "out:Return %": 7,
    "out:Return/kSEK/y": 216,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 2,
    "out:Payback Time": 50,
    "out:DH kWh savings": 82288,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 74882,
    "out:DH kr savings": 74882,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 578785,
    "out:% savings (space heating)": 12.12,
    "out:Total_Water Heating (DH)": 223720,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 112.54,
    "out:Etvv": 25,
    "out:Ef": 15.9,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z01000000000000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 107.7,
    "out:DHW (DH)": 43.5,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 15.9,
    "out:Total Energy Use Pre PV": 166.9,
    "out:Total Energy Use Post PV": 166.9,
    "out:Primary Energy": 121.51,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 8.96,
    "out:CO2 Operational/m2": 56.46,
    "out:Total CO2/m2": 65.42,
    "out:Total CO2 (tons)": 336.45,
    "out:Klimatpaverkan": 7.21,
    "out:Initial Cost/MSEK": 3.396125,
    "out:Running cost/(Apt SEK y)": 51906,
    "out:Running Cost over RSP/MSEK": 17.048,
    "out:LCP/MSEK": -1.175,
    "out:ROI% old": 9.34,
    "out:Payback discounted": 13,
    "out:Atemp": 5143,
    "out:Umean": 0.57,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 167,
    "out:Energy use kWh/m2": 167,
    "out:Energy savings %": 12.6,
    "out:Op energy cost/MSEK": 125,
    "out:El price/MSEK": 75,
    "out:Heating price/MSEK": 43,
    "out:DH+ DHW/kWh/m2": 151,
    "out:El bought/kWh/m2": 1113,
    "out:Return %": 8,
    "out:Return/kSEK/y": 284,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 108003,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 128574.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 98283,
    "out:DH kr savings": 98283,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 553879,
    "out:% savings (space heating)": 15.9,
    "out:Total_Water Heating (DH)": 223720,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 107.7,
    "out:Etvv": 25,
    "out:Ef": 15.9,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z01000000000001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 112.54,
    "out:DHW (DH)": 36.98,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 15.9,
    "out:Total Energy Use Pre PV": 165.9,
    "out:Total Energy Use Post PV": 165.9,
    "out:Primary Energy": 122.27,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 9.36,
    "out:CO2 Operational/m2": 56.1,
    "out:Total CO2/m2": 65.46,
    "out:Total CO2 (tons)": 336.66,
    "out:Klimatpaverkan": 7.42,
    "out:Initial Cost/MSEK": 3.518,
    "out:Running cost/(Apt SEK y)": 51584,
    "out:Running Cost over RSP/MSEK": 16.942,
    "out:LCP/MSEK": -1.191,
    "out:ROI% old": 9.45,
    "out:Payback discounted": 13,
    "out:Atemp": 5143,
    "out:Umean": 0.57,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 166,
    "out:Energy use kWh/m2": 166,
    "out:Energy savings %": 13.3,
    "out:Op energy cost/MSEK": 124,
    "out:El price/MSEK": 74,
    "out:Heating price/MSEK": 43,
    "out:DH+ DHW/kWh/m2": 150,
    "out:El bought/kWh/m2": 1113,
    "out:Return %": 8,
    "out:Return/kSEK/y": 297,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 113146,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 250448,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 102963,
    "out:DH kr savings": 102963,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 578785,
    "out:% savings (space heating)": 12.12,
    "out:Total_Water Heating (DH)": 190162,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 112.54,
    "out:Etvv": 21.25,
    "out:Ef": 15.9,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z01000000000001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 107.7,
    "out:DHW (DH)": 36.98,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 15.9,
    "out:Total Energy Use Pre PV": 160.9,
    "out:Total Energy Use Post PV": 160.9,
    "out:Primary Energy": 118.88,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 9.36,
    "out:CO2 Operational/m2": 54.3,
    "out:Total CO2/m2": 63.66,
    "out:Total CO2 (tons)": 327.41,
    "out:Klimatpaverkan": -1.83,
    "out:Initial Cost/MSEK": 3.6465,
    "out:Running cost/(Apt SEK y)": 49975,
    "out:Running Cost over RSP/MSEK": 16.413,
    "out:LCP/MSEK": -0.79,
    "out:ROI% old": 11.19,
    "out:Payback discounted": 11,
    "out:Atemp": 5143,
    "out:Umean": 0.57,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 161,
    "out:Energy use kWh/m2": 161,
    "out:Energy savings %": 16.8,
    "out:Op energy cost/MSEK": 120,
    "out:El price/MSEK": 72,
    "out:Heating price/MSEK": 42,
    "out:DH+ DHW/kWh/m2": 145,
    "out:El bought/kWh/m2": 1113,
    "out:Return %": 10,
    "out:Return/kSEK/y": 365,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 138861,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 250448,
    "out:Balancing hydronic system cost": 128574.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 126364,
    "out:DH kr savings": 126363,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 553879,
    "out:% savings (space heating)": 15.9,
    "out:Total_Water Heating (DH)": 190162,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 107.7,
    "out:Etvv": 21.25,
    "out:Ef": 15.9,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z01000000010000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 112.54,
    "out:DHW (DH)": 43.5,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 10.73,
    "out:Total Energy Use Pre PV": 171.9,
    "out:Total Energy Use Post PV": 166.73,
    "out:Primary Energy": 115.59,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 21.32,
    "out:CO2 Operational/m2": 27.26,
    "out:Total CO2/m2": 48.58,
    "out:Total CO2 (tons)": 249.83,
    "out:Klimatpaverkan": -79.41,
    "out:Initial Cost/MSEK": 4.33725,
    "out:Running cost/(Apt SEK y)": 51501,
    "out:Running Cost over RSP/MSEK": 16.933,
    "out:LCP/MSEK": -2.001,
    "out:ROI% old": 7.7,
    "out:Payback discounted": 16,
    "out:Atemp": 5143,
    "out:Umean": 0.57,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 167,
    "out:Energy use kWh/m2": 167,
    "out:Energy savings %": 12.6,
    "out:Op energy cost/MSEK": 125,
    "out:El price/MSEK": 73,
    "out:Heating price/MSEK": 45,
    "out:DH+ DHW/kWh/m2": 156,
    "out:El bought/kWh/m2": 758.1,
    "out:Return %": 7,
    "out:Return/kSEK/y": 301,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 61.265983,
    "out:PV (m2 panels)": 321,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 57484.06,
    "out:PV (kWh sold)": 30886.05,
    "out:PV (% sold (El))": 53.73,
    "out:PV (kWh self-consumed)": 26598,
    "out:PV (ratio sold/self-consumed)": 1.16,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 82288,
    "out:EL kWh savings": 26598,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 159478,
    "out:DH kr savings": 74882,
    "out:El kr savings": 45217,
    "out:El kr sold": 39380,
    "out:El kr saved": 45217,
    "out:El kr return": 84596,
    "out:% solar/total": 33,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 578785,
    "out:% savings (space heating)": 12.12,
    "out:Total_Water Heating (DH)": 223720,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 112.54,
    "out:Etvv": 25,
    "out:Ef": 10.73,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z01000000010000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 107.7,
    "out:DHW (DH)": 43.5,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 10.73,
    "out:Total Energy Use Pre PV": 166.9,
    "out:Total Energy Use Post PV": 161.73,
    "out:Primary Energy": 112.2,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 21.32,
    "out:CO2 Operational/m2": 25.46,
    "out:Total CO2/m2": 46.78,
    "out:Total CO2 (tons)": 240.57,
    "out:Klimatpaverkan": -88.67,
    "out:Initial Cost/MSEK": 4.465875,
    "out:Running cost/(Apt SEK y)": 49892,
    "out:Running Cost over RSP/MSEK": 16.404,
    "out:LCP/MSEK": -1.601,
    "out:ROI% old": 9.17,
    "out:Payback discounted": 13,
    "out:Atemp": 5143,
    "out:Umean": 0.57,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 162,
    "out:Energy use kWh/m2": 162,
    "out:Energy savings %": 16,
    "out:Op energy cost/MSEK": 121,
    "out:El price/MSEK": 71,
    "out:Heating price/MSEK": 43,
    "out:DH+ DHW/kWh/m2": 151,
    "out:El bought/kWh/m2": 758.1,
    "out:Return %": 8,
    "out:Return/kSEK/y": 368,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 61.265983,
    "out:PV (m2 panels)": 321,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 57484.06,
    "out:PV (kWh sold)": 30886.05,
    "out:PV (% sold (El))": 53.73,
    "out:PV (kWh self-consumed)": 26598,
    "out:PV (ratio sold/self-consumed)": 1.16,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 108003,
    "out:EL kWh savings": 26598,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 128574.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 182879,
    "out:DH kr savings": 98283,
    "out:El kr savings": 45217,
    "out:El kr sold": 39380,
    "out:El kr saved": 45217,
    "out:El kr return": 84596,
    "out:% solar/total": 33,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 553879,
    "out:% savings (space heating)": 15.9,
    "out:Total_Water Heating (DH)": 223720,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 107.7,
    "out:Etvv": 25,
    "out:Ef": 10.73,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z01000000010001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 112.54,
    "out:DHW (DH)": 36.98,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 10.73,
    "out:Total Energy Use Pre PV": 165.9,
    "out:Total Energy Use Post PV": 160.73,
    "out:Primary Energy": 112.96,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 21.72,
    "out:CO2 Operational/m2": 25.1,
    "out:Total CO2/m2": 46.82,
    "out:Total CO2 (tons)": 240.79,
    "out:Klimatpaverkan": -88.45,
    "out:Initial Cost/MSEK": 4.58775,
    "out:Running cost/(Apt SEK y)": 49570,
    "out:Running Cost over RSP/MSEK": 16.298,
    "out:LCP/MSEK": -1.617,
    "out:ROI% old": 9.25,
    "out:Payback discounted": 13,
    "out:Atemp": 5143,
    "out:Umean": 0.57,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 161,
    "out:Energy use kWh/m2": 161,
    "out:Energy savings %": 16.8,
    "out:Op energy cost/MSEK": 120,
    "out:El price/MSEK": 70,
    "out:Heating price/MSEK": 43,
    "out:DH+ DHW/kWh/m2": 150,
    "out:El bought/kWh/m2": 758.1,
    "out:Return %": 8,
    "out:Return/kSEK/y": 382,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 61.265983,
    "out:PV (m2 panels)": 321,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 57484.06,
    "out:PV (kWh sold)": 30886.05,
    "out:PV (% sold (El))": 53.73,
    "out:PV (kWh self-consumed)": 26598,
    "out:PV (ratio sold/self-consumed)": 1.16,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 113146,
    "out:EL kWh savings": 26598,
    "out:Sewage heat recovery cost": 250448,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 187559,
    "out:DH kr savings": 102963,
    "out:El kr savings": 45217,
    "out:El kr sold": 39380,
    "out:El kr saved": 45217,
    "out:El kr return": 84596,
    "out:% solar/total": 33,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 578785,
    "out:% savings (space heating)": 12.12,
    "out:Total_Water Heating (DH)": 190162,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 112.54,
    "out:Etvv": 21.25,
    "out:Ef": 10.73,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z01000000010001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 107.7,
    "out:DHW (DH)": 36.98,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 10.73,
    "out:Total Energy Use Pre PV": 160.9,
    "out:Total Energy Use Post PV": 155.73,
    "out:Primary Energy": 109.57,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 21.72,
    "out:CO2 Operational/m2": 23.3,
    "out:Total CO2/m2": 45.02,
    "out:Total CO2 (tons)": 231.53,
    "out:Klimatpaverkan": -97.71,
    "out:Initial Cost/MSEK": 4.716375,
    "out:Running cost/(Apt SEK y)": 47961,
    "out:Running Cost over RSP/MSEK": 15.769,
    "out:LCP/MSEK": -1.216,
    "out:ROI% old": 10.6,
    "out:Payback discounted": 11,
    "out:Atemp": 5143,
    "out:Umean": 0.57,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 156,
    "out:Energy use kWh/m2": 156,
    "out:Energy savings %": 20.5,
    "out:Op energy cost/MSEK": 116,
    "out:El price/MSEK": 68,
    "out:Heating price/MSEK": 42,
    "out:DH+ DHW/kWh/m2": 145,
    "out:El bought/kWh/m2": 758.1,
    "out:Return %": 10,
    "out:Return/kSEK/y": 450,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 61.265983,
    "out:PV (m2 panels)": 321,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 57484.06,
    "out:PV (kWh sold)": 30886.05,
    "out:PV (% sold (El))": 53.73,
    "out:PV (kWh self-consumed)": 26598,
    "out:PV (ratio sold/self-consumed)": 1.16,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 138861,
    "out:EL kWh savings": 26598,
    "out:Sewage heat recovery cost": 250448,
    "out:Balancing hydronic system cost": 128574.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 210960,
    "out:DH kr savings": 126363,
    "out:El kr savings": 45217,
    "out:El kr sold": 39380,
    "out:El kr saved": 45217,
    "out:El kr return": 84596,
    "out:% solar/total": 33,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 553879,
    "out:% savings (space heating)": 15.9,
    "out:Total_Water Heating (DH)": 190162,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 107.7,
    "out:Etvv": 21.25,
    "out:Ef": 10.73,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z01000000020000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 112.54,
    "out:DHW (DH)": 43.5,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 9.88,
    "out:Total Energy Use Pre PV": 171.9,
    "out:Total Energy Use Post PV": 165.88,
    "out:Primary Energy": 114.06,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 33.67,
    "out:CO2 Operational/m2": -25.42,
    "out:Total CO2/m2": 8.25,
    "out:Total CO2 (tons)": 42.43,
    "out:Klimatpaverkan": -286.81,
    "out:Initial Cost/MSEK": 5.407125,
    "out:Running cost/(Apt SEK y)": 49712,
    "out:Running Cost over RSP/MSEK": 16.361,
    "out:LCP/MSEK": -2.499,
    "out:ROI% old": 7.68,
    "out:Payback discounted": 16,
    "out:Atemp": 5143,
    "out:Umean": 0.57,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 166,
    "out:Energy use kWh/m2": 166,
    "out:Energy savings %": 13.3,
    "out:Op energy cost/MSEK": 122,
    "out:El price/MSEK": 69,
    "out:Heating price/MSEK": 45,
    "out:DH+ DHW/kWh/m2": 156,
    "out:El bought/kWh/m2": 696.7,
    "out:Return %": 7,
    "out:Return/kSEK/y": 376,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.33,
    "out:PV (kWp installed)": 122.531965,
    "out:PV (m2 panels)": 643,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 114968.11,
    "out:PV (kWh sold)": 84004.99,
    "out:PV (% sold (El))": 73.07,
    "out:PV (kWh self-consumed)": 30963.12,
    "out:PV (ratio sold/self-consumed)": 2.71,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 82288,
    "out:EL kWh savings": 30963,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 234626,
    "out:DH kr savings": 74882,
    "out:El kr savings": 52637,
    "out:El kr sold": 107106,
    "out:El kr saved": 52637,
    "out:El kr return": 159744,
    "out:% solar/total": 38,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 578785,
    "out:% savings (space heating)": 12.12,
    "out:Total_Water Heating (DH)": 223720,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 112.54,
    "out:Etvv": 25,
    "out:Ef": 9.88,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z01000000020000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 107.7,
    "out:DHW (DH)": 43.5,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 9.88,
    "out:Total Energy Use Pre PV": 166.9,
    "out:Total Energy Use Post PV": 160.88,
    "out:Primary Energy": 110.67,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 33.67,
    "out:CO2 Operational/m2": -27.22,
    "out:Total CO2/m2": 6.45,
    "out:Total CO2 (tons)": 33.17,
    "out:Klimatpaverkan": -296.07,
    "out:Initial Cost/MSEK": 5.535625,
    "out:Running cost/(Apt SEK y)": 48103,
    "out:Running Cost over RSP/MSEK": 15.832,
    "out:LCP/MSEK": -2.099,
    "out:ROI% old": 8.87,
    "out:Payback discounted": 14,
    "out:Atemp": 5143,
    "out:Umean": 0.57,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 161,
    "out:Energy use kWh/m2": 161,
    "out:Energy savings %": 16.8,
    "out:Op energy cost/MSEK": 118,
    "out:El price/MSEK": 67,
    "out:Heating price/MSEK": 43,
    "out:DH+ DHW/kWh/m2": 151,
    "out:El bought/kWh/m2": 696.7,
    "out:Return %": 8,
    "out:Return/kSEK/y": 444,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.33,
    "out:PV (kWp installed)": 122.531965,
    "out:PV (m2 panels)": 643,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 114968.11,
    "out:PV (kWh sold)": 84004.99,
    "out:PV (% sold (El))": 73.07,
    "out:PV (kWh self-consumed)": 30963.12,
    "out:PV (ratio sold/self-consumed)": 2.71,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 108003,
    "out:EL kWh savings": 30963,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 128574.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 258026,
    "out:DH kr savings": 98283,
    "out:El kr savings": 52637,
    "out:El kr sold": 107106,
    "out:El kr saved": 52637,
    "out:El kr return": 159744,
    "out:% solar/total": 38,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 553879,
    "out:% savings (space heating)": 15.9,
    "out:Total_Water Heating (DH)": 223720,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 107.7,
    "out:Etvv": 25,
    "out:Ef": 9.88,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z01000000020001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 112.54,
    "out:DHW (DH)": 36.98,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 9.88,
    "out:Total Energy Use Pre PV": 165.9,
    "out:Total Energy Use Post PV": 159.88,
    "out:Primary Energy": 111.44,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 34.07,
    "out:CO2 Operational/m2": -27.58,
    "out:Total CO2/m2": 6.49,
    "out:Total CO2 (tons)": 33.39,
    "out:Klimatpaverkan": -295.85,
    "out:Initial Cost/MSEK": 5.6575,
    "out:Running cost/(Apt SEK y)": 47781,
    "out:Running Cost over RSP/MSEK": 15.727,
    "out:LCP/MSEK": -2.115,
    "out:ROI% old": 8.94,
    "out:Payback discounted": 14,
    "out:Atemp": 5143,
    "out:Umean": 0.57,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 160,
    "out:Energy use kWh/m2": 160,
    "out:Energy savings %": 17.5,
    "out:Op energy cost/MSEK": 117,
    "out:El price/MSEK": 67,
    "out:Heating price/MSEK": 43,
    "out:DH+ DHW/kWh/m2": 150,
    "out:El bought/kWh/m2": 696.7,
    "out:Return %": 8,
    "out:Return/kSEK/y": 457,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.33,
    "out:PV (kWp installed)": 122.531965,
    "out:PV (m2 panels)": 643,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 114968.11,
    "out:PV (kWh sold)": 84004.99,
    "out:PV (% sold (El))": 73.07,
    "out:PV (kWh self-consumed)": 30963.12,
    "out:PV (ratio sold/self-consumed)": 2.71,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 113146,
    "out:EL kWh savings": 30963,
    "out:Sewage heat recovery cost": 250448,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 262707,
    "out:DH kr savings": 102963,
    "out:El kr savings": 52637,
    "out:El kr sold": 107106,
    "out:El kr saved": 52637,
    "out:El kr return": 159744,
    "out:% solar/total": 38,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 578785,
    "out:% savings (space heating)": 12.12,
    "out:Total_Water Heating (DH)": 190162,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 112.54,
    "out:Etvv": 21.25,
    "out:Ef": 9.88,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z01000000020001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 107.7,
    "out:DHW (DH)": 36.98,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 9.88,
    "out:Total Energy Use Pre PV": 160.9,
    "out:Total Energy Use Post PV": 154.88,
    "out:Primary Energy": 108.05,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 34.07,
    "out:CO2 Operational/m2": -29.38,
    "out:Total CO2/m2": 4.69,
    "out:Total CO2 (tons)": 24.13,
    "out:Klimatpaverkan": -305.11,
    "out:Initial Cost/MSEK": 5.786125,
    "out:Running cost/(Apt SEK y)": 46172,
    "out:Running Cost over RSP/MSEK": 15.198,
    "out:LCP/MSEK": -1.715,
    "out:ROI% old": 10.05,
    "out:Payback discounted": 12,
    "out:Atemp": 5143,
    "out:Umean": 0.57,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 155,
    "out:Energy use kWh/m2": 155,
    "out:Energy savings %": 21.3,
    "out:Op energy cost/MSEK": 113,
    "out:El price/MSEK": 64,
    "out:Heating price/MSEK": 42,
    "out:DH+ DHW/kWh/m2": 145,
    "out:El bought/kWh/m2": 696.7,
    "out:Return %": 9,
    "out:Return/kSEK/y": 525,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.33,
    "out:PV (kWp installed)": 122.531965,
    "out:PV (m2 panels)": 643,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 114968.11,
    "out:PV (kWh sold)": 84004.99,
    "out:PV (% sold (El))": 73.07,
    "out:PV (kWh self-consumed)": 30963.12,
    "out:PV (ratio sold/self-consumed)": 2.71,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 138861,
    "out:EL kWh savings": 30963,
    "out:Sewage heat recovery cost": 250448,
    "out:Balancing hydronic system cost": 128574.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 286107,
    "out:DH kr savings": 126363,
    "out:El kr savings": 52637,
    "out:El kr sold": 107106,
    "out:El kr saved": 52637,
    "out:El kr return": 159744,
    "out:% solar/total": 38,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 553879,
    "out:% savings (space heating)": 15.9,
    "out:Total_Water Heating (DH)": 190162,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 107.7,
    "out:Etvv": 21.25,
    "out:Ef": 9.88,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z01000000100000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 112.54,
    "out:DHW (DH)": 34.8,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 15.9,
    "out:Total Energy Use Pre PV": 162.9,
    "out:Total Energy Use Post PV": 162.9,
    "out:Primary Energy": 121.4,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 8.96,
    "out:CO2 Operational/m2": 55.02,
    "out:Total CO2/m2": 63.98,
    "out:Total CO2 (tons)": 329.04,
    "out:Klimatpaverkan": -0.2,
    "out:Initial Cost/MSEK": 3.378375,
    "out:Running cost/(Apt SEK y)": 50619,
    "out:Running Cost over RSP/MSEK": 16.625,
    "out:LCP/MSEK": -0.734,
    "out:ROI% old": 11.18,
    "out:Payback discounted": 11,
    "out:Atemp": 5143,
    "out:Umean": 0.57,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 163,
    "out:Energy use kWh/m2": 163,
    "out:Energy savings %": 15.3,
    "out:Op energy cost/MSEK": 122,
    "out:El price/MSEK": 73,
    "out:Heating price/MSEK": 42,
    "out:DH+ DHW/kWh/m2": 147,
    "out:El bought/kWh/m2": 1113,
    "out:Return %": 10,
    "out:Return/kSEK/y": 338,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 128575,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 117003,
    "out:DH kr savings": 117003,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 578785,
    "out:% savings (space heating)": 12.12,
    "out:Total_Water Heating (DH)": 178976,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 112.54,
    "out:Etvv": 20,
    "out:Ef": 15.9,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z01000000100000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 107.7,
    "out:DHW (DH)": 34.8,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 15.9,
    "out:Total Energy Use Pre PV": 157.9,
    "out:Total Energy Use Post PV": 157.9,
    "out:Primary Energy": 118.01,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 8.96,
    "out:CO2 Operational/m2": 53.22,
    "out:Total CO2/m2": 62.18,
    "out:Total CO2 (tons)": 319.78,
    "out:Klimatpaverkan": -9.46,
    "out:Initial Cost/MSEK": 3.506875,
    "out:Running cost/(Apt SEK y)": 49010,
    "out:Running Cost over RSP/MSEK": 16.096,
    "out:LCP/MSEK": -0.333,
    "out:ROI% old": 12.93,
    "out:Payback discounted": 9,
    "out:Atemp": 5143,
    "out:Umean": 0.57,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 158,
    "out:Energy use kWh/m2": 158,
    "out:Energy savings %": 19,
    "out:Op energy cost/MSEK": 118,
    "out:El price/MSEK": 71,
    "out:Heating price/MSEK": 41,
    "out:DH+ DHW/kWh/m2": 142,
    "out:El bought/kWh/m2": 1113,
    "out:Return %": 12,
    "out:Return/kSEK/y": 406,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 154290,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 128574.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 140404,
    "out:DH kr savings": 140404,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 553879,
    "out:% savings (space heating)": 15.9,
    "out:Total_Water Heating (DH)": 178976,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 107.7,
    "out:Etvv": 20,
    "out:Ef": 15.9,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z01000000100001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 112.54,
    "out:DHW (DH)": 29.58,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 15.9,
    "out:Total Energy Use Pre PV": 157.9,
    "out:Total Energy Use Post PV": 157.9,
    "out:Primary Energy": 119.3,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 9.36,
    "out:CO2 Operational/m2": 53.22,
    "out:Total CO2/m2": 62.58,
    "out:Total CO2 (tons)": 321.85,
    "out:Klimatpaverkan": -7.39,
    "out:Initial Cost/MSEK": 3.62875,
    "out:Running cost/(Apt SEK y)": 49010,
    "out:Running Cost over RSP/MSEK": 16.096,
    "out:LCP/MSEK": -0.455,
    "out:ROI% old": 12.49,
    "out:Payback discounted": 10,
    "out:Atemp": 5143,
    "out:Umean": 0.57,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 158,
    "out:Energy use kWh/m2": 158,
    "out:Energy savings %": 19,
    "out:Op energy cost/MSEK": 118,
    "out:El price/MSEK": 71,
    "out:Heating price/MSEK": 41,
    "out:DH+ DHW/kWh/m2": 142,
    "out:El bought/kWh/m2": 1113,
    "out:Return %": 11,
    "out:Return/kSEK/y": 406,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 154290,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 250448,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 140404,
    "out:DH kr savings": 140404,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 578785,
    "out:% savings (space heating)": 12.12,
    "out:Total_Water Heating (DH)": 152130,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 112.54,
    "out:Etvv": 17,
    "out:Ef": 15.9,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z01000000100001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 107.7,
    "out:DHW (DH)": 29.58,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 15.9,
    "out:Total Energy Use Pre PV": 152.9,
    "out:Total Energy Use Post PV": 152.9,
    "out:Primary Energy": 115.91,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 9.36,
    "out:CO2 Operational/m2": 51.42,
    "out:Total CO2/m2": 60.78,
    "out:Total CO2 (tons)": 312.59,
    "out:Klimatpaverkan": -16.65,
    "out:Initial Cost/MSEK": 3.757375,
    "out:Running cost/(Apt SEK y)": 47400,
    "out:Running Cost over RSP/MSEK": 15.567,
    "out:LCP/MSEK": -0.055,
    "out:ROI% old": 14.08,
    "out:Payback discounted": 9,
    "out:Atemp": 5143,
    "out:Umean": 0.57,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 153,
    "out:Energy use kWh/m2": 153,
    "out:Energy savings %": 22.9,
    "out:Op energy cost/MSEK": 114,
    "out:El price/MSEK": 68,
    "out:Heating price/MSEK": 39,
    "out:DH+ DHW/kWh/m2": 137,
    "out:El bought/kWh/m2": 1113,
    "out:Return %": 13,
    "out:Return/kSEK/y": 473,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 180005,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 250448,
    "out:Balancing hydronic system cost": 128574.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 163805,
    "out:DH kr savings": 163805,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 553879,
    "out:% savings (space heating)": 15.9,
    "out:Total_Water Heating (DH)": 152130,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 107.7,
    "out:Etvv": 17,
    "out:Ef": 15.9,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z01000000110000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 112.54,
    "out:DHW (DH)": 34.8,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 10.73,
    "out:Total Energy Use Pre PV": 162.9,
    "out:Total Energy Use Post PV": 157.73,
    "out:Primary Energy": 112.09,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 21.32,
    "out:CO2 Operational/m2": 24.02,
    "out:Total CO2/m2": 45.34,
    "out:Total CO2 (tons)": 233.17,
    "out:Klimatpaverkan": -96.07,
    "out:Initial Cost/MSEK": 4.448125,
    "out:Running cost/(Apt SEK y)": 48605,
    "out:Running Cost over RSP/MSEK": 15.981,
    "out:LCP/MSEK": -1.16,
    "out:ROI% old": 10.56,
    "out:Payback discounted": 11,
    "out:Atemp": 5143,
    "out:Umean": 0.57,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 158,
    "out:Energy use kWh/m2": 158,
    "out:Energy savings %": 19,
    "out:Op energy cost/MSEK": 118,
    "out:El price/MSEK": 69,
    "out:Heating price/MSEK": 42,
    "out:DH+ DHW/kWh/m2": 147,
    "out:El bought/kWh/m2": 758.1,
    "out:Return %": 9,
    "out:Return/kSEK/y": 423,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 61.265983,
    "out:PV (m2 panels)": 321,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 57484.06,
    "out:PV (kWh sold)": 30886.05,
    "out:PV (% sold (El))": 53.73,
    "out:PV (kWh self-consumed)": 26598,
    "out:PV (ratio sold/self-consumed)": 1.16,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 128575,
    "out:EL kWh savings": 26598,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 201600,
    "out:DH kr savings": 117003,
    "out:El kr savings": 45217,
    "out:El kr sold": 39380,
    "out:El kr saved": 45217,
    "out:El kr return": 84596,
    "out:% solar/total": 33,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 578785,
    "out:% savings (space heating)": 12.12,
    "out:Total_Water Heating (DH)": 178976,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 112.54,
    "out:Etvv": 20,
    "out:Ef": 10.73,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z01000000110000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 107.7,
    "out:DHW (DH)": 34.8,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 10.73,
    "out:Total Energy Use Pre PV": 157.9,
    "out:Total Energy Use Post PV": 152.73,
    "out:Primary Energy": 108.7,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 21.32,
    "out:CO2 Operational/m2": 22.22,
    "out:Total CO2/m2": 43.54,
    "out:Total CO2 (tons)": 223.91,
    "out:Klimatpaverkan": -105.33,
    "out:Initial Cost/MSEK": 4.57675,
    "out:Running cost/(Apt SEK y)": 46995,
    "out:Running Cost over RSP/MSEK": 15.452,
    "out:LCP/MSEK": -0.759,
    "out:ROI% old": 11.92,
    "out:Payback discounted": 10,
    "out:Atemp": 5143,
    "out:Umean": 0.57,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 153,
    "out:Energy use kWh/m2": 153,
    "out:Energy savings %": 22.9,
    "out:Op energy cost/MSEK": 114,
    "out:El price/MSEK": 67,
    "out:Heating price/MSEK": 41,
    "out:DH+ DHW/kWh/m2": 142,
    "out:El bought/kWh/m2": 758.1,
    "out:Return %": 11,
    "out:Return/kSEK/y": 490,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 61.265983,
    "out:PV (m2 panels)": 321,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 57484.06,
    "out:PV (kWh sold)": 30886.05,
    "out:PV (% sold (El))": 53.73,
    "out:PV (kWh self-consumed)": 26598,
    "out:PV (ratio sold/self-consumed)": 1.16,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 154290,
    "out:EL kWh savings": 26598,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 128574.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 225000,
    "out:DH kr savings": 140404,
    "out:El kr savings": 45217,
    "out:El kr sold": 39380,
    "out:El kr saved": 45217,
    "out:El kr return": 84596,
    "out:% solar/total": 33,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 553879,
    "out:% savings (space heating)": 15.9,
    "out:Total_Water Heating (DH)": 178976,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 107.7,
    "out:Etvv": 20,
    "out:Ef": 10.73,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z01000000110001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 112.54,
    "out:DHW (DH)": 29.58,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 10.73,
    "out:Total Energy Use Pre PV": 157.9,
    "out:Total Energy Use Post PV": 152.73,
    "out:Primary Energy": 109.99,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 21.72,
    "out:CO2 Operational/m2": 22.22,
    "out:Total CO2/m2": 43.94,
    "out:Total CO2 (tons)": 225.98,
    "out:Klimatpaverkan": -103.26,
    "out:Initial Cost/MSEK": 4.698625,
    "out:Running cost/(Apt SEK y)": 46995,
    "out:Running Cost over RSP/MSEK": 15.452,
    "out:LCP/MSEK": -0.881,
    "out:ROI% old": 11.61,
    "out:Payback discounted": 10,
    "out:Atemp": 5143,
    "out:Umean": 0.57,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 153,
    "out:Energy use kWh/m2": 153,
    "out:Energy savings %": 22.9,
    "out:Op energy cost/MSEK": 114,
    "out:El price/MSEK": 67,
    "out:Heating price/MSEK": 41,
    "out:DH+ DHW/kWh/m2": 142,
    "out:El bought/kWh/m2": 758.1,
    "out:Return %": 10,
    "out:Return/kSEK/y": 490,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 61.265983,
    "out:PV (m2 panels)": 321,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 57484.06,
    "out:PV (kWh sold)": 30886.05,
    "out:PV (% sold (El))": 53.73,
    "out:PV (kWh self-consumed)": 26598,
    "out:PV (ratio sold/self-consumed)": 1.16,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 154290,
    "out:EL kWh savings": 26598,
    "out:Sewage heat recovery cost": 250448,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 225000,
    "out:DH kr savings": 140404,
    "out:El kr savings": 45217,
    "out:El kr sold": 39380,
    "out:El kr saved": 45217,
    "out:El kr return": 84596,
    "out:% solar/total": 33,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 578785,
    "out:% savings (space heating)": 12.12,
    "out:Total_Water Heating (DH)": 152130,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 112.54,
    "out:Etvv": 17,
    "out:Ef": 10.73,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z01000000110001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 107.7,
    "out:DHW (DH)": 29.58,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 10.73,
    "out:Total Energy Use Pre PV": 152.9,
    "out:Total Energy Use Post PV": 147.73,
    "out:Primary Energy": 106.6,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 21.72,
    "out:CO2 Operational/m2": 20.42,
    "out:Total CO2/m2": 42.14,
    "out:Total CO2 (tons)": 216.72,
    "out:Klimatpaverkan": -112.52,
    "out:Initial Cost/MSEK": 4.827125,
    "out:Running cost/(Apt SEK y)": 45386,
    "out:Running Cost over RSP/MSEK": 14.923,
    "out:LCP/MSEK": -0.481,
    "out:ROI% old": 12.86,
    "out:Payback discounted": 9,
    "out:Atemp": 5143,
    "out:Umean": 0.57,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 148,
    "out:Energy use kWh/m2": 148,
    "out:Energy savings %": 27,
    "out:Op energy cost/MSEK": 110,
    "out:El price/MSEK": 64,
    "out:Heating price/MSEK": 39,
    "out:DH+ DHW/kWh/m2": 137,
    "out:El bought/kWh/m2": 758.1,
    "out:Return %": 12,
    "out:Return/kSEK/y": 558,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 61.265983,
    "out:PV (m2 panels)": 321,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 57484.06,
    "out:PV (kWh sold)": 30886.05,
    "out:PV (% sold (El))": 53.73,
    "out:PV (kWh self-consumed)": 26598,
    "out:PV (ratio sold/self-consumed)": 1.16,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 180005,
    "out:EL kWh savings": 26598,
    "out:Sewage heat recovery cost": 250448,
    "out:Balancing hydronic system cost": 128574.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 248401,
    "out:DH kr savings": 163805,
    "out:El kr savings": 45217,
    "out:El kr sold": 39380,
    "out:El kr saved": 45217,
    "out:El kr return": 84596,
    "out:% solar/total": 33,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 553879,
    "out:% savings (space heating)": 15.9,
    "out:Total_Water Heating (DH)": 152130,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 107.7,
    "out:Etvv": 17,
    "out:Ef": 10.73,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z01000000120000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 112.54,
    "out:DHW (DH)": 34.8,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 9.88,
    "out:Total Energy Use Pre PV": 162.9,
    "out:Total Energy Use Post PV": 156.88,
    "out:Primary Energy": 110.56,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 33.67,
    "out:CO2 Operational/m2": -28.66,
    "out:Total CO2/m2": 5.01,
    "out:Total CO2 (tons)": 25.77,
    "out:Klimatpaverkan": -303.47,
    "out:Initial Cost/MSEK": 5.517875,
    "out:Running cost/(Apt SEK y)": 46815,
    "out:Running Cost over RSP/MSEK": 15.409,
    "out:LCP/MSEK": -1.658,
    "out:ROI% old": 9.99,
    "out:Payback discounted": 12,
    "out:Atemp": 5143,
    "out:Umean": 0.57,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 157,
    "out:Energy use kWh/m2": 157,
    "out:Energy savings %": 19.7,
    "out:Op energy cost/MSEK": 114,
    "out:El price/MSEK": 65,
    "out:Heating price/MSEK": 42,
    "out:DH+ DHW/kWh/m2": 147,
    "out:El bought/kWh/m2": 696.7,
    "out:Return %": 9,
    "out:Return/kSEK/y": 498,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.33,
    "out:PV (kWp installed)": 122.531965,
    "out:PV (m2 panels)": 643,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 114968.11,
    "out:PV (kWh sold)": 84004.99,
    "out:PV (% sold (El))": 73.07,
    "out:PV (kWh self-consumed)": 30963.12,
    "out:PV (ratio sold/self-consumed)": 2.71,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 128575,
    "out:EL kWh savings": 30963,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 276747,
    "out:DH kr savings": 117003,
    "out:El kr savings": 52637,
    "out:El kr sold": 107106,
    "out:El kr saved": 52637,
    "out:El kr return": 159744,
    "out:% solar/total": 38,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 578785,
    "out:% savings (space heating)": 12.12,
    "out:Total_Water Heating (DH)": 178976,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 112.54,
    "out:Etvv": 20,
    "out:Ef": 9.88,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z01000000120000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 107.7,
    "out:DHW (DH)": 34.8,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 9.88,
    "out:Total Energy Use Pre PV": 157.9,
    "out:Total Energy Use Post PV": 151.88,
    "out:Primary Energy": 107.17,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 33.67,
    "out:CO2 Operational/m2": -30.46,
    "out:Total CO2/m2": 3.21,
    "out:Total CO2 (tons)": 16.51,
    "out:Klimatpaverkan": -312.73,
    "out:Initial Cost/MSEK": 5.6465,
    "out:Running cost/(Apt SEK y)": 45206,
    "out:Running Cost over RSP/MSEK": 14.88,
    "out:LCP/MSEK": -1.258,
    "out:ROI% old": 11.1,
    "out:Payback discounted": 11,
    "out:Atemp": 5143,
    "out:Umean": 0.57,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 152,
    "out:Energy use kWh/m2": 152,
    "out:Energy savings %": 23.7,
    "out:Op energy cost/MSEK": 111,
    "out:El price/MSEK": 63,
    "out:Heating price/MSEK": 41,
    "out:DH+ DHW/kWh/m2": 142,
    "out:El bought/kWh/m2": 696.7,
    "out:Return %": 10,
    "out:Return/kSEK/y": 565,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.33,
    "out:PV (kWp installed)": 122.531965,
    "out:PV (m2 panels)": 643,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 114968.11,
    "out:PV (kWh sold)": 84004.99,
    "out:PV (% sold (El))": 73.07,
    "out:PV (kWh self-consumed)": 30963.12,
    "out:PV (ratio sold/self-consumed)": 2.71,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 154290,
    "out:EL kWh savings": 30963,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 128574.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 300148,
    "out:DH kr savings": 140404,
    "out:El kr savings": 52637,
    "out:El kr sold": 107106,
    "out:El kr saved": 52637,
    "out:El kr return": 159744,
    "out:% solar/total": 38,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 553879,
    "out:% savings (space heating)": 15.9,
    "out:Total_Water Heating (DH)": 178976,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 107.7,
    "out:Etvv": 20,
    "out:Ef": 9.88,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z01000000120001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 112.54,
    "out:DHW (DH)": 29.58,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 9.88,
    "out:Total Energy Use Pre PV": 157.9,
    "out:Total Energy Use Post PV": 151.88,
    "out:Primary Energy": 108.46,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 34.07,
    "out:CO2 Operational/m2": -30.46,
    "out:Total CO2/m2": 3.61,
    "out:Total CO2 (tons)": 18.58,
    "out:Klimatpaverkan": -310.66,
    "out:Initial Cost/MSEK": 5.768375,
    "out:Running cost/(Apt SEK y)": 45206,
    "out:Running Cost over RSP/MSEK": 14.88,
    "out:LCP/MSEK": -1.38,
    "out:ROI% old": 10.87,
    "out:Payback discounted": 11,
    "out:Atemp": 5143,
    "out:Umean": 0.57,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 152,
    "out:Energy use kWh/m2": 152,
    "out:Energy savings %": 23.7,
    "out:Op energy cost/MSEK": 111,
    "out:El price/MSEK": 63,
    "out:Heating price/MSEK": 41,
    "out:DH+ DHW/kWh/m2": 142,
    "out:El bought/kWh/m2": 696.7,
    "out:Return %": 10,
    "out:Return/kSEK/y": 565,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.33,
    "out:PV (kWp installed)": 122.531965,
    "out:PV (m2 panels)": 643,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 114968.11,
    "out:PV (kWh sold)": 84004.99,
    "out:PV (% sold (El))": 73.07,
    "out:PV (kWh self-consumed)": 30963.12,
    "out:PV (ratio sold/self-consumed)": 2.71,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 154290,
    "out:EL kWh savings": 30963,
    "out:Sewage heat recovery cost": 250448,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 300148,
    "out:DH kr savings": 140404,
    "out:El kr savings": 52637,
    "out:El kr sold": 107106,
    "out:El kr saved": 52637,
    "out:El kr return": 159744,
    "out:% solar/total": 38,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 578785,
    "out:% savings (space heating)": 12.12,
    "out:Total_Water Heating (DH)": 152130,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 112.54,
    "out:Etvv": 17,
    "out:Ef": 9.88,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z01000000120001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 107.7,
    "out:DHW (DH)": 29.58,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 9.88,
    "out:Total Energy Use Pre PV": 152.9,
    "out:Total Energy Use Post PV": 146.88,
    "out:Primary Energy": 105.07,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 34.07,
    "out:CO2 Operational/m2": -32.26,
    "out:Total CO2/m2": 1.81,
    "out:Total CO2 (tons)": 9.32,
    "out:Klimatpaverkan": -319.92,
    "out:Initial Cost/MSEK": 5.897,
    "out:Running cost/(Apt SEK y)": 43597,
    "out:Running Cost over RSP/MSEK": 14.351,
    "out:LCP/MSEK": -0.979,
    "out:ROI% old": 11.91,
    "out:Payback discounted": 10,
    "out:Atemp": 5143,
    "out:Umean": 0.57,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 147,
    "out:Energy use kWh/m2": 147,
    "out:Energy savings %": 27.9,
    "out:Op energy cost/MSEK": 107,
    "out:El price/MSEK": 61,
    "out:Heating price/MSEK": 39,
    "out:DH+ DHW/kWh/m2": 137,
    "out:El bought/kWh/m2": 696.7,
    "out:Return %": 11,
    "out:Return/kSEK/y": 633,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.33,
    "out:PV (kWp installed)": 122.531965,
    "out:PV (m2 panels)": 643,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 114968.11,
    "out:PV (kWh sold)": 84004.99,
    "out:PV (% sold (El))": 73.07,
    "out:PV (kWh self-consumed)": 30963.12,
    "out:PV (ratio sold/self-consumed)": 2.71,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 180005,
    "out:EL kWh savings": 30963,
    "out:Sewage heat recovery cost": 250448,
    "out:Balancing hydronic system cost": 128574.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 323548,
    "out:DH kr savings": 163805,
    "out:El kr savings": 52637,
    "out:El kr sold": 107106,
    "out:El kr saved": 52637,
    "out:El kr return": 159744,
    "out:% solar/total": 38,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 553879,
    "out:% savings (space heating)": 15.9,
    "out:Total_Water Heating (DH)": 152130,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 107.7,
    "out:Etvv": 17,
    "out:Ef": 9.88,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z01000001000000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 66.39,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 45.37,
    "out:Electricity (inc PV)": 43.28,
    "out:Total Energy Use Pre PV": 111.37,
    "out:Total Energy Use Post PV": 109.28,
    "out:Primary Energy": 116.24,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 11.81,
    "out:CO2 Operational/m2": 29.75,
    "out:Total CO2/m2": 41.56,
    "out:Total CO2 (tons)": 213.74,
    "out:Klimatpaverkan": -115.5,
    "out:Initial Cost/MSEK": 7.743375,
    "out:Running cost/(Apt SEK y)": 30685,
    "out:Running Cost over RSP/MSEK": 10.048,
    "out:LCP/MSEK": 1.478,
    "out:ROI% old": 17.01,
    "out:Payback discounted": 7,
    "out:Atemp": 5143,
    "out:Umean": 0.57,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 109,
    "out:Energy use kWh/m2": 109,
    "out:Energy savings %": 72.5,
    "out:Op energy cost/MSEK": 72,
    "out:El price/MSEK": 50,
    "out:Heating price/MSEK": 19,
    "out:DH+ DHW/kWh/m2": 66,
    "out:El bought/kWh/m2": 3175.6,
    "out:Return %": 15,
    "out:Return/kSEK/y": 1175,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 1479000,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 73950,
    "out:EAHP (replacement cost)": 1105400,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 27.17,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 545158,
    "out:EL kWh savings": -140825,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 256691,
    "out:DH kr savings": 496094,
    "out:El kr savings": -239402,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 341438,
    "out:% savings (space heating)": 48.16,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 54.77,
    "out:Etvv": 0,
    "out:Ef": 43.28,
    "out:heatPumpQuantity": 10,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000001000000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 61.81,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 45.29,
    "out:Electricity (inc PV)": 43.15,
    "out:Total Energy Use Pre PV": 107.29,
    "out:Total Energy Use Post PV": 105.15,
    "out:Primary Energy": 112.92,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 11.81,
    "out:CO2 Operational/m2": 28.3,
    "out:Total CO2/m2": 40.11,
    "out:Total CO2 (tons)": 206.28,
    "out:Klimatpaverkan": -122.96,
    "out:Initial Cost/MSEK": 7.872,
    "out:Running cost/(Apt SEK y)": 29381,
    "out:Running Cost over RSP/MSEK": 9.619,
    "out:LCP/MSEK": 1.778,
    "out:ROI% old": 17.51,
    "out:Payback discounted": 7,
    "out:Atemp": 5143,
    "out:Umean": 0.57,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 105,
    "out:Energy use kWh/m2": 105,
    "out:Energy savings %": 79,
    "out:Op energy cost/MSEK": 69,
    "out:El price/MSEK": 48,
    "out:Heating price/MSEK": 18,
    "out:DH+ DHW/kWh/m2": 62,
    "out:El bought/kWh/m2": 3170.1,
    "out:Return %": 16,
    "out:Return/kSEK/y": 1230,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 1479000,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 73950,
    "out:EAHP (replacement cost)": 1105400,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 27.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 565730,
    "out:EL kWh savings": -140160,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 128574.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 276542,
    "out:DH kr savings": 514814,
    "out:El kr savings": -238272,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 317868,
    "out:% savings (space heating)": 51.73,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 50.35,
    "out:Etvv": 0,
    "out:Ef": 43.15,
    "out:heatPumpQuantity": 10,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000001000001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 62.17,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 44.67,
    "out:Electricity (inc PV)": 42.82,
    "out:Total Energy Use Pre PV": 106.67,
    "out:Total Energy Use Post PV": 104.82,
    "out:Primary Energy": 113.85,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 12.21,
    "out:CO2 Operational/m2": 28.22,
    "out:Total CO2/m2": 40.43,
    "out:Total CO2 (tons)": 207.93,
    "out:Klimatpaverkan": -121.31,
    "out:Initial Cost/MSEK": 7.993875,
    "out:Running cost/(Apt SEK y)": 29252,
    "out:Running Cost over RSP/MSEK": 9.578,
    "out:LCP/MSEK": 1.698,
    "out:ROI% old": 17.32,
    "out:Payback discounted": 7,
    "out:Atemp": 5143,
    "out:Umean": 0.57,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 105,
    "out:Energy use kWh/m2": 105,
    "out:Energy savings %": 79,
    "out:Op energy cost/MSEK": 68,
    "out:El price/MSEK": 48,
    "out:Heating price/MSEK": 18,
    "out:DH+ DHW/kWh/m2": 62,
    "out:El bought/kWh/m2": 3126.7,
    "out:Return %": 15,
    "out:Return/kSEK/y": 1235,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 1479000,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 73950,
    "out:EAHP (replacement cost)": 1105400,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 26.47,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 565730,
    "out:EL kWh savings": -138457,
    "out:Sewage heat recovery cost": 250448,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 279437,
    "out:DH kr savings": 514814,
    "out:El kr savings": -235377,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 319746,
    "out:% savings (space heating)": 51.45,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 52.54,
    "out:Etvv": 0,
    "out:Ef": 42.82,
    "out:heatPumpQuantity": 10,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000001000001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 57.64,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 44.57,
    "out:Electricity (inc PV)": 42.68,
    "out:Total Energy Use Pre PV": 102.57,
    "out:Total Energy Use Post PV": 100.68,
    "out:Primary Energy": 110.54,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 12.21,
    "out:CO2 Operational/m2": 26.76,
    "out:Total CO2/m2": 38.98,
    "out:Total CO2 (tons)": 200.46,
    "out:Klimatpaverkan": -128.78,
    "out:Initial Cost/MSEK": 8.122375,
    "out:Running cost/(Apt SEK y)": 27944,
    "out:Running Cost over RSP/MSEK": 9.148,
    "out:LCP/MSEK": 1.999,
    "out:ROI% old": 17.8,
    "out:Payback discounted": 7,
    "out:Atemp": 5143,
    "out:Umean": 0.57,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 101,
    "out:Energy use kWh/m2": 101,
    "out:Energy savings %": 86.1,
    "out:Op energy cost/MSEK": 65,
    "out:El price/MSEK": 46,
    "out:Heating price/MSEK": 17,
    "out:DH+ DHW/kWh/m2": 58,
    "out:El bought/kWh/m2": 3120,
    "out:Return %": 16,
    "out:Return/kSEK/y": 1290,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 1479000,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 73950,
    "out:EAHP (replacement cost)": 1105400,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 26.37,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 586302,
    "out:EL kWh savings": -137735,
    "out:Sewage heat recovery cost": 250448,
    "out:Balancing hydronic system cost": 128574.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 299384,
    "out:DH kr savings": 533535,
    "out:El kr savings": -234150,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 296467,
    "out:% savings (space heating)": 54.98,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 48.16,
    "out:Etvv": 0,
    "out:Ef": 42.68,
    "out:heatPumpQuantity": 10,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000001010000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 66.39,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 45.37,
    "out:Electricity (inc PV)": 33.75,
    "out:Total Energy Use Pre PV": 111.37,
    "out:Total Energy Use Post PV": 99.75,
    "out:Primary Energy": 99.09,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 24.17,
    "out:CO2 Operational/m2": 21.44,
    "out:Total CO2/m2": 45.61,
    "out:Total CO2 (tons)": 234.57,
    "out:Klimatpaverkan": -94.67,
    "out:Initial Cost/MSEK": 8.81325,
    "out:Running cost/(Apt SEK y)": 28433,
    "out:Running Cost over RSP/MSEK": 9.328,
    "out:LCP/MSEK": 1.128,
    "out:ROI% old": 16.11,
    "out:Payback discounted": 8,
    "out:Atemp": 5143,
    "out:Umean": 0.57,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 100,
    "out:Energy use kWh/m2": 100,
    "out:Energy savings %": 88,
    "out:Op energy cost/MSEK": 67,
    "out:El price/MSEK": 45,
    "out:Heating price/MSEK": 19,
    "out:DH+ DHW/kWh/m2": 66,
    "out:El bought/kWh/m2": 2521.4,
    "out:Return %": 14,
    "out:Return/kSEK/y": 1270,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 1479000,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 73950,
    "out:EAHP (replacement cost)": 1105400,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 27.17,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 61.265983,
    "out:PV (m2 panels)": 321,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 57484.06,
    "out:PV (kWh sold)": 7439.23,
    "out:PV (% sold (El))": 12.94,
    "out:PV (kWh self-consumed)": 50044.83,
    "out:PV (ratio sold/self-consumed)": 0.15,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 545158,
    "out:EL kWh savings": -91815,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 349493,
    "out:DH kr savings": 496094,
    "out:El kr savings": -156086,
    "out:El kr sold": 9485,
    "out:El kr saved": 85076,
    "out:El kr return": 94561,
    "out:% solar/total": 61,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 341438,
    "out:% savings (space heating)": 48.16,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 54.77,
    "out:Etvv": 0,
    "out:Ef": 33.75,
    "out:heatPumpQuantity": 10,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000001010000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 61.81,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 45.29,
    "out:Electricity (inc PV)": 33.64,
    "out:Total Energy Use Pre PV": 107.29,
    "out:Total Energy Use Post PV": 95.64,
    "out:Primary Energy": 95.79,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 24.17,
    "out:CO2 Operational/m2": 19.96,
    "out:Total CO2/m2": 44.12,
    "out:Total CO2 (tons)": 226.92,
    "out:Klimatpaverkan": -102.32,
    "out:Initial Cost/MSEK": 8.94175,
    "out:Running cost/(Apt SEK y)": 27130,
    "out:Running Cost over RSP/MSEK": 8.9,
    "out:LCP/MSEK": 1.427,
    "out:ROI% old": 16.57,
    "out:Payback discounted": 7,
    "out:Atemp": 5143,
    "out:Umean": 0.57,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 96,
    "out:Energy use kWh/m2": 96,
    "out:Energy savings %": 95.8,
    "out:Op energy cost/MSEK": 64,
    "out:El price/MSEK": 44,
    "out:Heating price/MSEK": 18,
    "out:DH+ DHW/kWh/m2": 62,
    "out:El bought/kWh/m2": 2516.3,
    "out:Return %": 15,
    "out:Return/kSEK/y": 1324,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 1479000,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 73950,
    "out:EAHP (replacement cost)": 1105400,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 27.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 61.265983,
    "out:PV (m2 panels)": 321,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 57484.06,
    "out:PV (kWh sold)": 7476.49,
    "out:PV (% sold (El))": 13.01,
    "out:PV (kWh self-consumed)": 50007.57,
    "out:PV (ratio sold/self-consumed)": 0.15,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 565730,
    "out:EL kWh savings": -91217,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 128574.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 369277,
    "out:DH kr savings": 514814,
    "out:El kr savings": -155069,
    "out:El kr sold": 9533,
    "out:El kr saved": 85013,
    "out:El kr return": 94545,
    "out:% solar/total": 61,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 317868,
    "out:% savings (space heating)": 51.73,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 50.35,
    "out:Etvv": 0,
    "out:Ef": 33.64,
    "out:heatPumpQuantity": 10,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000001010001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 62.17,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 44.67,
    "out:Electricity (inc PV)": 33.34,
    "out:Total Energy Use Pre PV": 106.67,
    "out:Total Energy Use Post PV": 95.34,
    "out:Primary Energy": 96.78,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 24.57,
    "out:CO2 Operational/m2": 19.59,
    "out:Total CO2/m2": 44.16,
    "out:Total CO2 (tons)": 227.11,
    "out:Klimatpaverkan": -102.13,
    "out:Initial Cost/MSEK": 9.063625,
    "out:Running cost/(Apt SEK y)": 27004,
    "out:Running Cost over RSP/MSEK": 8.859,
    "out:LCP/MSEK": 1.347,
    "out:ROI% old": 16.41,
    "out:Payback discounted": 7,
    "out:Atemp": 5143,
    "out:Umean": 0.57,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 95,
    "out:Energy use kWh/m2": 95,
    "out:Energy savings %": 97.9,
    "out:Op energy cost/MSEK": 64,
    "out:El price/MSEK": 43,
    "out:Heating price/MSEK": 18,
    "out:DH+ DHW/kWh/m2": 62,
    "out:El bought/kWh/m2": 2476.4,
    "out:Return %": 15,
    "out:Return/kSEK/y": 1330,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 1479000,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 73950,
    "out:EAHP (replacement cost)": 1105400,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 26.47,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 61.265983,
    "out:PV (m2 panels)": 321,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 57484.06,
    "out:PV (kWh sold)": 7775.42,
    "out:PV (% sold (El))": 13.53,
    "out:PV (kWh self-consumed)": 49708.64,
    "out:PV (ratio sold/self-consumed)": 0.16,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 565730,
    "out:EL kWh savings": -89687,
    "out:Sewage heat recovery cost": 250448,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 372260,
    "out:DH kr savings": 514814,
    "out:El kr savings": -152468,
    "out:El kr sold": 9914,
    "out:El kr saved": 84505,
    "out:El kr return": 94418,
    "out:% solar/total": 61,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 319746,
    "out:% savings (space heating)": 51.45,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 52.54,
    "out:Etvv": 0,
    "out:Ef": 33.34,
    "out:heatPumpQuantity": 10,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000001010001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 57.64,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 44.57,
    "out:Electricity (inc PV)": 33.21,
    "out:Total Energy Use Pre PV": 102.57,
    "out:Total Energy Use Post PV": 91.21,
    "out:Primary Energy": 93.49,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 24.57,
    "out:CO2 Operational/m2": 18.09,
    "out:Total CO2/m2": 42.66,
    "out:Total CO2 (tons)": 219.41,
    "out:Klimatpaverkan": -109.83,
    "out:Initial Cost/MSEK": 9.19225,
    "out:Running cost/(Apt SEK y)": 25697,
    "out:Running Cost over RSP/MSEK": 8.429,
    "out:LCP/MSEK": 1.647,
    "out:ROI% old": 16.85,
    "out:Payback discounted": 7,
    "out:Atemp": 5143,
    "out:Umean": 0.57,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 91,
    "out:Energy use kWh/m2": 91,
    "out:Energy savings %": 106.6,
    "out:Op energy cost/MSEK": 61,
    "out:El price/MSEK": 41,
    "out:Heating price/MSEK": 17,
    "out:DH+ DHW/kWh/m2": 58,
    "out:El bought/kWh/m2": 2470.3,
    "out:Return %": 15,
    "out:Return/kSEK/y": 1385,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 1479000,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 73950,
    "out:EAHP (replacement cost)": 1105400,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 26.37,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 61.265983,
    "out:PV (m2 panels)": 321,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 57484.06,
    "out:PV (kWh sold)": 7822.31,
    "out:PV (% sold (El))": 13.61,
    "out:PV (kWh self-consumed)": 49661.74,
    "out:PV (ratio sold/self-consumed)": 0.16,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 586302,
    "out:EL kWh savings": -89039,
    "out:Sewage heat recovery cost": 250448,
    "out:Balancing hydronic system cost": 128574.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 392142,
    "out:DH kr savings": 533535,
    "out:El kr savings": -151366,
    "out:El kr sold": 9973,
    "out:El kr saved": 84425,
    "out:El kr return": 94398,
    "out:% solar/total": 61,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 296467,
    "out:% savings (space heating)": 54.98,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 48.16,
    "out:Etvv": 0,
    "out:Ef": 33.21,
    "out:heatPumpQuantity": 10,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000001020000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 66.39,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 45.37,
    "out:Electricity (inc PV)": 30.5,
    "out:Total Energy Use Pre PV": 111.37,
    "out:Total Energy Use Post PV": 96.5,
    "out:Primary Energy": 93.23,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 36.52,
    "out:CO2 Operational/m2": -18.03,
    "out:Total CO2/m2": 18.49,
    "out:Total CO2 (tons)": 95.09,
    "out:Klimatpaverkan": -234.15,
    "out:Initial Cost/MSEK": 9.883,
    "out:Running cost/(Apt SEK y)": 26508,
    "out:Running Cost over RSP/MSEK": 8.713,
    "out:LCP/MSEK": 0.673,
    "out:ROI% old": 15.26,
    "out:Payback discounted": 8,
    "out:Atemp": 5143,
    "out:Umean": 0.57,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 96,
    "out:Energy use kWh/m2": 96,
    "out:Energy savings %": 95.8,
    "out:Op energy cost/MSEK": 64,
    "out:El price/MSEK": 42,
    "out:Heating price/MSEK": 19,
    "out:DH+ DHW/kWh/m2": 66,
    "out:El bought/kWh/m2": 2275,
    "out:Return %": 14,
    "out:Return/kSEK/y": 1351,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 1479000,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 73950,
    "out:EAHP (replacement cost)": 1105400,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 27.17,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.33,
    "out:PV (kWp installed)": 122.531965,
    "out:PV (m2 panels)": 643,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 114968.11,
    "out:PV (kWh sold)": 47160.58,
    "out:PV (% sold (El))": 41.02,
    "out:PV (kWh self-consumed)": 67807.53,
    "out:PV (ratio sold/self-consumed)": 0.7,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 545158,
    "out:EL kWh savings": -75069,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 428606,
    "out:DH kr savings": 496094,
    "out:El kr savings": -127618,
    "out:El kr sold": 60130,
    "out:El kr saved": 115273,
    "out:El kr return": 175403,
    "out:% solar/total": 83,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 341438,
    "out:% savings (space heating)": 48.16,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 54.77,
    "out:Etvv": 0,
    "out:Ef": 30.5,
    "out:heatPumpQuantity": 10,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000001020000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 61.81,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 45.29,
    "out:Electricity (inc PV)": 30.39,
    "out:Total Energy Use Pre PV": 107.29,
    "out:Total Energy Use Post PV": 92.39,
    "out:Primary Energy": 89.95,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 36.52,
    "out:CO2 Operational/m2": -19.55,
    "out:Total CO2/m2": 16.96,
    "out:Total CO2 (tons)": 87.25,
    "out:Klimatpaverkan": -241.99,
    "out:Initial Cost/MSEK": 10.0115,
    "out:Running cost/(Apt SEK y)": 25205,
    "out:Running Cost over RSP/MSEK": 8.284,
    "out:LCP/MSEK": 0.973,
    "out:ROI% old": 15.67,
    "out:Payback discounted": 8,
    "out:Atemp": 5143,
    "out:Umean": 0.57,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 92,
    "out:Energy use kWh/m2": 92,
    "out:Energy savings %": 104.3,
    "out:Op energy cost/MSEK": 60,
    "out:El price/MSEK": 40,
    "out:Heating price/MSEK": 18,
    "out:DH+ DHW/kWh/m2": 62,
    "out:El bought/kWh/m2": 2270.5,
    "out:Return %": 14,
    "out:Return/kSEK/y": 1405,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 1479000,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 73950,
    "out:EAHP (replacement cost)": 1105400,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 27.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.33,
    "out:PV (kWp installed)": 122.531965,
    "out:PV (m2 panels)": 643,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 114968.11,
    "out:PV (kWh sold)": 47237.18,
    "out:PV (% sold (El))": 41.09,
    "out:PV (kWh self-consumed)": 67730.93,
    "out:PV (ratio sold/self-consumed)": 0.7,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 565730,
    "out:EL kWh savings": -74534,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 128574.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 448335,
    "out:DH kr savings": 514814,
    "out:El kr savings": -126707,
    "out:El kr sold": 60227,
    "out:El kr saved": 115143,
    "out:El kr return": 175370,
    "out:% solar/total": 83,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 317868,
    "out:% savings (space heating)": 51.73,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 50.35,
    "out:Etvv": 0,
    "out:Ef": 30.39,
    "out:heatPumpQuantity": 10,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000001020001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 62.17,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 44.67,
    "out:Electricity (inc PV)": 30.13,
    "out:Total Energy Use Pre PV": 106.67,
    "out:Total Energy Use Post PV": 92.13,
    "out:Primary Energy": 91,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 36.92,
    "out:CO2 Operational/m2": -20.23,
    "out:Total CO2/m2": 16.69,
    "out:Total CO2 (tons)": 85.86,
    "out:Klimatpaverkan": -243.38,
    "out:Initial Cost/MSEK": 10.133375,
    "out:Running cost/(Apt SEK y)": 25082,
    "out:Running Cost over RSP/MSEK": 8.244,
    "out:LCP/MSEK": 0.891,
    "out:ROI% old": 15.54,
    "out:Payback discounted": 8,
    "out:Atemp": 5143,
    "out:Umean": 0.57,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 92,
    "out:Energy use kWh/m2": 92,
    "out:Energy savings %": 104.3,
    "out:Op energy cost/MSEK": 60,
    "out:El price/MSEK": 39,
    "out:Heating price/MSEK": 18,
    "out:DH+ DHW/kWh/m2": 62,
    "out:El bought/kWh/m2": 2234.9,
    "out:Return %": 14,
    "out:Return/kSEK/y": 1410,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 1479000,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 73950,
    "out:EAHP (replacement cost)": 1105400,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 26.47,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.33,
    "out:PV (kWp installed)": 122.531965,
    "out:PV (m2 panels)": 643,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 114968.11,
    "out:PV (kWh sold)": 47843.01,
    "out:PV (% sold (El))": 41.61,
    "out:PV (kWh self-consumed)": 67125.1,
    "out:PV (ratio sold/self-consumed)": 0.71,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 565730,
    "out:EL kWh savings": -73163,
    "out:Sewage heat recovery cost": 250448,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 451438,
    "out:DH kr savings": 514814,
    "out:El kr savings": -124376,
    "out:El kr sold": 61000,
    "out:El kr saved": 114113,
    "out:El kr return": 175113,
    "out:% solar/total": 82,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 319746,
    "out:% savings (space heating)": 51.45,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 52.54,
    "out:Etvv": 0,
    "out:Ef": 30.13,
    "out:heatPumpQuantity": 10,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000001020001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 57.64,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 44.57,
    "out:Electricity (inc PV)": 30.01,
    "out:Total Energy Use Pre PV": 102.57,
    "out:Total Energy Use Post PV": 88.01,
    "out:Primary Energy": 87.73,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 36.92,
    "out:CO2 Operational/m2": -21.77,
    "out:Total CO2/m2": 15.15,
    "out:Total CO2 (tons)": 77.92,
    "out:Klimatpaverkan": -251.32,
    "out:Initial Cost/MSEK": 10.262,
    "out:Running cost/(Apt SEK y)": 23776,
    "out:Running Cost over RSP/MSEK": 7.815,
    "out:LCP/MSEK": 1.192,
    "out:ROI% old": 15.94,
    "out:Payback discounted": 8,
    "out:Atemp": 5143,
    "out:Umean": 0.57,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 88,
    "out:Energy use kWh/m2": 88,
    "out:Energy savings %": 113.6,
    "out:Op energy cost/MSEK": 57,
    "out:El price/MSEK": 38,
    "out:Heating price/MSEK": 17,
    "out:DH+ DHW/kWh/m2": 58,
    "out:El bought/kWh/m2": 2229.4,
    "out:Return %": 14,
    "out:Return/kSEK/y": 1465,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 1479000,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 73950,
    "out:EAHP (replacement cost)": 1105400,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 26.37,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.33,
    "out:PV (kWp installed)": 122.531965,
    "out:PV (m2 panels)": 643,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 114968.11,
    "out:PV (kWh sold)": 47936.87,
    "out:PV (% sold (El))": 41.7,
    "out:PV (kWh self-consumed)": 67031.25,
    "out:PV (ratio sold/self-consumed)": 0.72,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 586302,
    "out:EL kWh savings": -72582,
    "out:Sewage heat recovery cost": 250448,
    "out:Balancing hydronic system cost": 128574.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 471265,
    "out:DH kr savings": 533535,
    "out:El kr savings": -123390,
    "out:El kr sold": 61120,
    "out:El kr saved": 113953,
    "out:El kr return": 175073,
    "out:% solar/total": 82,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 296467,
    "out:% savings (space heating)": 54.98,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 48.16,
    "out:Etvv": 0,
    "out:Ef": 30.01,
    "out:heatPumpQuantity": 10,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000001100000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 60.8,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 44.42,
    "out:Electricity (inc PV)": 42.67,
    "out:Total Energy Use Pre PV": 105.42,
    "out:Total Energy Use Post PV": 103.67,
    "out:Primary Energy": 113.06,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 11.81,
    "out:CO2 Operational/m2": 27.82,
    "out:Total CO2/m2": 39.64,
    "out:Total CO2 (tons)": 203.85,
    "out:Klimatpaverkan": -125.39,
    "out:Initial Cost/MSEK": 7.85425,
    "out:Running cost/(Apt SEK y)": 28879,
    "out:Running Cost over RSP/MSEK": 9.455,
    "out:LCP/MSEK": 1.959,
    "out:ROI% old": 17.85,
    "out:Payback discounted": 7,
    "out:Atemp": 5143,
    "out:Umean": 0.57,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 104,
    "out:Energy use kWh/m2": 104,
    "out:Energy savings %": 80.8,
    "out:Op energy cost/MSEK": 67,
    "out:El price/MSEK": 47,
    "out:Heating price/MSEK": 17,
    "out:DH+ DHW/kWh/m2": 61,
    "out:El bought/kWh/m2": 3109.7,
    "out:Return %": 16,
    "out:Return/kSEK/y": 1251,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 1479000,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 73950,
    "out:EAHP (replacement cost)": 1105400,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 26.23,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 570873,
    "out:EL kWh savings": -137654,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 285482,
    "out:DH kr savings": 519494,
    "out:El kr savings": -234012,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 312669,
    "out:% savings (space heating)": 52.52,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 51.8,
    "out:Etvv": 0,
    "out:Ef": 42.67,
    "out:heatPumpQuantity": 10,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000001100000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 56.29,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 44.32,
    "out:Electricity (inc PV)": 42.52,
    "out:Total Energy Use Pre PV": 100.32,
    "out:Total Energy Use Post PV": 98.52,
    "out:Primary Energy": 109.74,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 11.81,
    "out:CO2 Operational/m2": 26.01,
    "out:Total CO2/m2": 37.82,
    "out:Total CO2 (tons)": 194.52,
    "out:Klimatpaverkan": -134.72,
    "out:Initial Cost/MSEK": 7.982875,
    "out:Running cost/(Apt SEK y)": 27249,
    "out:Running Cost over RSP/MSEK": 8.92,
    "out:LCP/MSEK": 2.367,
    "out:ROI% old": 18.52,
    "out:Payback discounted": 7,
    "out:Atemp": 5143,
    "out:Umean": 0.57,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 99,
    "out:Energy use kWh/m2": 99,
    "out:Energy savings %": 89.9,
    "out:Op energy cost/MSEK": 64,
    "out:El price/MSEK": 45,
    "out:Heating price/MSEK": 16,
    "out:DH+ DHW/kWh/m2": 56,
    "out:El bought/kWh/m2": 3102.6,
    "out:Return %": 17,
    "out:Return/kSEK/y": 1319,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 1479000,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 73950,
    "out:EAHP (replacement cost)": 1105400,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 26.12,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 596588,
    "out:EL kWh savings": -136915,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 128574.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 310140,
    "out:DH kr savings": 542895,
    "out:El kr savings": -232755,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 289484,
    "out:% savings (space heating)": 56.04,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 47.43,
    "out:Etvv": 0,
    "out:Ef": 42.52,
    "out:heatPumpQuantity": 10,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000001100001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 57.55,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 43.83,
    "out:Electricity (inc PV)": 42.29,
    "out:Total Energy Use Pre PV": 101.83,
    "out:Total Energy Use Post PV": 100.29,
    "out:Primary Energy": 111.15,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 12.21,
    "out:CO2 Operational/m2": 26.66,
    "out:Total CO2/m2": 38.88,
    "out:Total CO2 (tons)": 199.96,
    "out:Klimatpaverkan": -129.28,
    "out:Initial Cost/MSEK": 8.10475,
    "out:Running cost/(Apt SEK y)": 27790,
    "out:Running Cost over RSP/MSEK": 9.098,
    "out:LCP/MSEK": 2.067,
    "out:ROI% old": 17.93,
    "out:Payback discounted": 7,
    "out:Atemp": 5143,
    "out:Umean": 0.57,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 100,
    "out:Energy use kWh/m2": 100,
    "out:Energy savings %": 88,
    "out:Op energy cost/MSEK": 65,
    "out:El price/MSEK": 46,
    "out:Heating price/MSEK": 17,
    "out:DH+ DHW/kWh/m2": 58,
    "out:El bought/kWh/m2": 3067.9,
    "out:Return %": 16,
    "out:Return/kSEK/y": 1297,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 1479000,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 73950,
    "out:EAHP (replacement cost)": 1105400,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 25.63,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 586302,
    "out:EL kWh savings": -135700,
    "out:Sewage heat recovery cost": 250448,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 302844,
    "out:DH kr savings": 533535,
    "out:El kr savings": -230691,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 295970,
    "out:% savings (space heating)": 55.06,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 50.05,
    "out:Etvv": 0,
    "out:Ef": 42.29,
    "out:heatPumpQuantity": 10,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000001100001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 53.09,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 43.71,
    "out:Electricity (inc PV)": 42.13,
    "out:Total Energy Use Pre PV": 96.71,
    "out:Total Energy Use Post PV": 95.13,
    "out:Primary Energy": 107.84,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 12.21,
    "out:CO2 Operational/m2": 24.85,
    "out:Total CO2/m2": 37.06,
    "out:Total CO2 (tons)": 190.62,
    "out:Klimatpaverkan": -138.62,
    "out:Initial Cost/MSEK": 8.23325,
    "out:Running cost/(Apt SEK y)": 26156,
    "out:Running Cost over RSP/MSEK": 8.561,
    "out:LCP/MSEK": 2.475,
    "out:ROI% old": 18.58,
    "out:Payback discounted": 7,
    "out:Atemp": 5143,
    "out:Umean": 0.57,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 95,
    "out:Energy use kWh/m2": 95,
    "out:Energy savings %": 97.9,
    "out:Op energy cost/MSEK": 61,
    "out:El price/MSEK": 43,
    "out:Heating price/MSEK": 15,
    "out:DH+ DHW/kWh/m2": 53,
    "out:El bought/kWh/m2": 3059.8,
    "out:Return %": 17,
    "out:Return/kSEK/y": 1365,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 1479000,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 73950,
    "out:EAHP (replacement cost)": 1105400,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 25.51,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 612017,
    "out:EL kWh savings": -134916,
    "out:Sewage heat recovery cost": 250448,
    "out:Balancing hydronic system cost": 128574.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 327579,
    "out:DH kr savings": 556935,
    "out:El kr savings": -229357,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 273025,
    "out:% savings (space heating)": 58.54,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 45.72,
    "out:Etvv": 0,
    "out:Ef": 42.13,
    "out:heatPumpQuantity": 10,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000001110000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 60.8,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 44.42,
    "out:Electricity (inc PV)": 33.2,
    "out:Total Energy Use Pre PV": 105.42,
    "out:Total Energy Use Post PV": 94.2,
    "out:Primary Energy": 96.02,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 24.17,
    "out:CO2 Operational/m2": 19.09,
    "out:Total CO2/m2": 43.25,
    "out:Total CO2 (tons)": 222.44,
    "out:Klimatpaverkan": -106.8,
    "out:Initial Cost/MSEK": 8.924,
    "out:Running cost/(Apt SEK y)": 26633,
    "out:Running Cost over RSP/MSEK": 8.737,
    "out:LCP/MSEK": 1.608,
    "out:ROI% old": 16.86,
    "out:Payback discounted": 7,
    "out:Atemp": 5143,
    "out:Umean": 0.57,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 94,
    "out:Energy use kWh/m2": 94,
    "out:Energy savings %": 100,
    "out:Op energy cost/MSEK": 63,
    "out:El price/MSEK": 43,
    "out:Heating price/MSEK": 17,
    "out:DH+ DHW/kWh/m2": 61,
    "out:El bought/kWh/m2": 2460.9,
    "out:Return %": 15,
    "out:Return/kSEK/y": 1345,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 1479000,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 73950,
    "out:EAHP (replacement cost)": 1105400,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 26.23,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 61.265983,
    "out:PV (m2 panels)": 321,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 57484.06,
    "out:PV (kWh sold)": 7894.28,
    "out:PV (% sold (El))": 13.73,
    "out:PV (kWh self-consumed)": 49589.78,
    "out:PV (ratio sold/self-consumed)": 0.16,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 570873,
    "out:EL kWh savings": -88966,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 378318,
    "out:DH kr savings": 519494,
    "out:El kr savings": -151242,
    "out:El kr sold": 10065,
    "out:El kr saved": 84303,
    "out:El kr return": 94368,
    "out:% solar/total": 61,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 312669,
    "out:% savings (space heating)": 52.52,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 51.8,
    "out:Etvv": 0,
    "out:Ef": 33.2,
    "out:heatPumpQuantity": 10,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000001110000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 56.29,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 44.32,
    "out:Electricity (inc PV)": 33.07,
    "out:Total Energy Use Pre PV": 100.32,
    "out:Total Energy Use Post PV": 89.07,
    "out:Primary Energy": 92.73,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 24.17,
    "out:CO2 Operational/m2": 17.22,
    "out:Total CO2/m2": 41.39,
    "out:Total CO2 (tons)": 212.87,
    "out:Klimatpaverkan": -116.37,
    "out:Initial Cost/MSEK": 9.052625,
    "out:Running cost/(Apt SEK y)": 25003,
    "out:Running Cost over RSP/MSEK": 8.202,
    "out:LCP/MSEK": 2.015,
    "out:ROI% old": 17.47,
    "out:Payback discounted": 7,
    "out:Atemp": 5143,
    "out:Umean": 0.57,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 89,
    "out:Energy use kWh/m2": 89,
    "out:Energy savings %": 111.2,
    "out:Op energy cost/MSEK": 59,
    "out:El price/MSEK": 40,
    "out:Heating price/MSEK": 16,
    "out:DH+ DHW/kWh/m2": 56,
    "out:El bought/kWh/m2": 2454.4,
    "out:Return %": 16,
    "out:Return/kSEK/y": 1414,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 1479000,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 73950,
    "out:EAHP (replacement cost)": 1105400,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 26.12,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 61.265983,
    "out:PV (m2 panels)": 321,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 57484.06,
    "out:PV (kWh sold)": 7944.46,
    "out:PV (% sold (El))": 13.82,
    "out:PV (kWh self-consumed)": 49539.6,
    "out:PV (ratio sold/self-consumed)": 0.16,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 596588,
    "out:EL kWh savings": -88302,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 128574.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 402910,
    "out:DH kr savings": 542895,
    "out:El kr savings": -150114,
    "out:El kr sold": 10129,
    "out:El kr saved": 84217,
    "out:El kr return": 94347,
    "out:% solar/total": 61,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 289484,
    "out:% savings (space heating)": 56.04,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 47.43,
    "out:Etvv": 0,
    "out:Ef": 33.07,
    "out:heatPumpQuantity": 10,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000001110001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 57.55,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 43.83,
    "out:Electricity (inc PV)": 32.86,
    "out:Total Energy Use Pre PV": 101.83,
    "out:Total Energy Use Post PV": 90.86,
    "out:Primary Energy": 94.18,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 24.57,
    "out:CO2 Operational/m2": 17.64,
    "out:Total CO2/m2": 42.21,
    "out:Total CO2 (tons)": 217.08,
    "out:Klimatpaverkan": -112.16,
    "out:Initial Cost/MSEK": 9.1745,
    "out:Running cost/(Apt SEK y)": 25546,
    "out:Running Cost over RSP/MSEK": 8.38,
    "out:LCP/MSEK": 1.714,
    "out:ROI% old": 16.95,
    "out:Payback discounted": 7,
    "out:Atemp": 5143,
    "out:Umean": 0.57,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 91,
    "out:Energy use kWh/m2": 91,
    "out:Energy savings %": 106.6,
    "out:Op energy cost/MSEK": 60,
    "out:El price/MSEK": 41,
    "out:Heating price/MSEK": 17,
    "out:DH+ DHW/kWh/m2": 58,
    "out:El bought/kWh/m2": 2422.6,
    "out:Return %": 15,
    "out:Return/kSEK/y": 1391,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 1479000,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 73950,
    "out:EAHP (replacement cost)": 1105400,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 25.63,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 61.265983,
    "out:PV (m2 panels)": 321,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 57484.06,
    "out:PV (kWh sold)": 8194.56,
    "out:PV (% sold (El))": 14.26,
    "out:PV (kWh self-consumed)": 49289.5,
    "out:PV (ratio sold/self-consumed)": 0.17,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 586302,
    "out:EL kWh savings": -87213,
    "out:Sewage heat recovery cost": 250448,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 395720,
    "out:DH kr savings": 533535,
    "out:El kr savings": -148263,
    "out:El kr sold": 10448,
    "out:El kr saved": 83792,
    "out:El kr return": 94240,
    "out:% solar/total": 60,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 295970,
    "out:% savings (space heating)": 55.06,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 50.05,
    "out:Etvv": 0,
    "out:Ef": 32.86,
    "out:heatPumpQuantity": 10,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000001110001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 53.09,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 43.71,
    "out:Electricity (inc PV)": 32.72,
    "out:Total Energy Use Pre PV": 96.71,
    "out:Total Energy Use Post PV": 85.72,
    "out:Primary Energy": 90.9,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 24.57,
    "out:CO2 Operational/m2": 15.77,
    "out:Total CO2/m2": 40.34,
    "out:Total CO2 (tons)": 207.46,
    "out:Klimatpaverkan": -121.78,
    "out:Initial Cost/MSEK": 9.303125,
    "out:Running cost/(Apt SEK y)": 23913,
    "out:Running Cost over RSP/MSEK": 7.844,
    "out:LCP/MSEK": 2.122,
    "out:ROI% old": 17.54,
    "out:Payback discounted": 7,
    "out:Atemp": 5143,
    "out:Umean": 0.57,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 86,
    "out:Energy use kWh/m2": 86,
    "out:Energy savings %": 118.6,
    "out:Op energy cost/MSEK": 57,
    "out:El price/MSEK": 39,
    "out:Heating price/MSEK": 15,
    "out:DH+ DHW/kWh/m2": 53,
    "out:El bought/kWh/m2": 2415.2,
    "out:Return %": 16,
    "out:Return/kSEK/y": 1460,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 1479000,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 73950,
    "out:EAHP (replacement cost)": 1105400,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 25.51,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 61.265983,
    "out:PV (m2 panels)": 321,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 57484.06,
    "out:PV (kWh sold)": 8253.41,
    "out:PV (% sold (El))": 14.36,
    "out:PV (kWh self-consumed)": 49230.65,
    "out:PV (ratio sold/self-consumed)": 0.17,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 612017,
    "out:EL kWh savings": -86510,
    "out:Sewage heat recovery cost": 250448,
    "out:Balancing hydronic system cost": 128574.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 420391,
    "out:DH kr savings": 556935,
    "out:El kr savings": -147067,
    "out:El kr sold": 10523,
    "out:El kr saved": 83692,
    "out:El kr return": 94215,
    "out:% solar/total": 60,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 273025,
    "out:% savings (space heating)": 58.54,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 45.72,
    "out:Etvv": 0,
    "out:Ef": 32.72,
    "out:heatPumpQuantity": 10,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000001120000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 60.8,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 44.42,
    "out:Electricity (inc PV)": 30,
    "out:Total Energy Use Pre PV": 105.42,
    "out:Total Energy Use Post PV": 91,
    "out:Primary Energy": 90.26,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 36.52,
    "out:CO2 Operational/m2": -20.85,
    "out:Total CO2/m2": 15.67,
    "out:Total CO2 (tons)": 80.58,
    "out:Klimatpaverkan": -248.66,
    "out:Initial Cost/MSEK": 9.993875,
    "out:Running cost/(Apt SEK y)": 24712,
    "out:Running Cost over RSP/MSEK": 8.123,
    "out:LCP/MSEK": 1.152,
    "out:ROI% old": 15.93,
    "out:Payback discounted": 8,
    "out:Atemp": 5143,
    "out:Umean": 0.57,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 91,
    "out:Energy use kWh/m2": 91,
    "out:Energy savings %": 106.6,
    "out:Op energy cost/MSEK": 59,
    "out:El price/MSEK": 39,
    "out:Heating price/MSEK": 17,
    "out:DH+ DHW/kWh/m2": 61,
    "out:El bought/kWh/m2": 2221.1,
    "out:Return %": 14,
    "out:Return/kSEK/y": 1426,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 1479000,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 73950,
    "out:EAHP (replacement cost)": 1105400,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 26.23,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.33,
    "out:PV (kWp installed)": 122.531965,
    "out:PV (m2 panels)": 643,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 114968.11,
    "out:PV (kWh sold)": 48080.42,
    "out:PV (% sold (El))": 41.82,
    "out:PV (kWh self-consumed)": 66887.69,
    "out:PV (ratio sold/self-consumed)": 0.72,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 570873,
    "out:EL kWh savings": -72517,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 457519,
    "out:DH kr savings": 519494,
    "out:El kr savings": -123278,
    "out:El kr sold": 61303,
    "out:El kr saved": 113709,
    "out:El kr return": 175012,
    "out:% solar/total": 82,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 312669,
    "out:% savings (space heating)": 52.52,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 51.8,
    "out:Etvv": 0,
    "out:Ef": 30,
    "out:heatPumpQuantity": 10,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000001120000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 56.29,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 44.32,
    "out:Electricity (inc PV)": 29.88,
    "out:Total Energy Use Pre PV": 100.32,
    "out:Total Energy Use Post PV": 85.88,
    "out:Primary Energy": 87,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 36.52,
    "out:CO2 Operational/m2": -22.76,
    "out:Total CO2/m2": 13.76,
    "out:Total CO2 (tons)": 70.76,
    "out:Klimatpaverkan": -258.48,
    "out:Initial Cost/MSEK": 10.122375,
    "out:Running cost/(Apt SEK y)": 23083,
    "out:Running Cost over RSP/MSEK": 7.588,
    "out:LCP/MSEK": 1.559,
    "out:ROI% old": 16.49,
    "out:Payback discounted": 7,
    "out:Atemp": 5143,
    "out:Umean": 0.57,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 86,
    "out:Energy use kWh/m2": 86,
    "out:Energy savings %": 118.6,
    "out:Op energy cost/MSEK": 55,
    "out:El price/MSEK": 37,
    "out:Heating price/MSEK": 16,
    "out:DH+ DHW/kWh/m2": 56,
    "out:El bought/kWh/m2": 2215.3,
    "out:Return %": 15,
    "out:Return/kSEK/y": 1494,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 1479000,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 73950,
    "out:EAHP (replacement cost)": 1105400,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 26.12,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.33,
    "out:PV (kWp installed)": 122.531965,
    "out:PV (m2 panels)": 643,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 114968.11,
    "out:PV (kWh sold)": 48179.9,
    "out:PV (% sold (El))": 41.91,
    "out:PV (kWh self-consumed)": 66788.21,
    "out:PV (ratio sold/self-consumed)": 0.72,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 596588,
    "out:EL kWh savings": -71923,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 128574.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 482056,
    "out:DH kr savings": 542895,
    "out:El kr savings": -122268,
    "out:El kr sold": 61429,
    "out:El kr saved": 113540,
    "out:El kr return": 174969,
    "out:% solar/total": 82,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 289484,
    "out:% savings (space heating)": 56.04,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 47.43,
    "out:Etvv": 0,
    "out:Ef": 29.88,
    "out:heatPumpQuantity": 10,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000001120001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 57.55,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 43.83,
    "out:Electricity (inc PV)": 29.69,
    "out:Total Energy Use Pre PV": 101.83,
    "out:Total Energy Use Post PV": 87.69,
    "out:Primary Energy": 88.49,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 36.92,
    "out:CO2 Operational/m2": -22.58,
    "out:Total CO2/m2": 14.34,
    "out:Total CO2 (tons)": 73.74,
    "out:Klimatpaverkan": -255.5,
    "out:Initial Cost/MSEK": 10.24425,
    "out:Running cost/(Apt SEK y)": 23629,
    "out:Running Cost over RSP/MSEK": 7.767,
    "out:LCP/MSEK": 1.258,
    "out:ROI% old": 16.04,
    "out:Payback discounted": 8,
    "out:Atemp": 5143,
    "out:Umean": 0.57,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 88,
    "out:Energy use kWh/m2": 88,
    "out:Energy savings %": 113.6,
    "out:Op energy cost/MSEK": 57,
    "out:El price/MSEK": 37,
    "out:Heating price/MSEK": 17,
    "out:DH+ DHW/kWh/m2": 58,
    "out:El bought/kWh/m2": 2186.9,
    "out:Return %": 14,
    "out:Return/kSEK/y": 1472,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 1479000,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 73950,
    "out:EAHP (replacement cost)": 1105400,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 25.63,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.33,
    "out:PV (kWp installed)": 122.531965,
    "out:PV (m2 panels)": 643,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 114968.11,
    "out:PV (kWh sold)": 48670.1,
    "out:PV (% sold (El))": 42.33,
    "out:PV (kWh self-consumed)": 66298.01,
    "out:PV (ratio sold/self-consumed)": 0.73,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 586302,
    "out:EL kWh savings": -70947,
    "out:Sewage heat recovery cost": 250448,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 474980,
    "out:DH kr savings": 533535,
    "out:El kr savings": -120609,
    "out:El kr sold": 62054,
    "out:El kr saved": 112707,
    "out:El kr return": 174761,
    "out:% solar/total": 81,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 295970,
    "out:% savings (space heating)": 55.06,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 50.05,
    "out:Etvv": 0,
    "out:Ef": 29.69,
    "out:heatPumpQuantity": 10,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000001120001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 53.09,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 43.71,
    "out:Electricity (inc PV)": 29.57,
    "out:Total Energy Use Pre PV": 96.71,
    "out:Total Energy Use Post PV": 82.57,
    "out:Primary Energy": 85.23,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 36.92,
    "out:CO2 Operational/m2": -24.51,
    "out:Total CO2/m2": 12.41,
    "out:Total CO2 (tons)": 63.83,
    "out:Klimatpaverkan": -265.41,
    "out:Initial Cost/MSEK": 10.372875,
    "out:Running cost/(Apt SEK y)": 21996,
    "out:Running Cost over RSP/MSEK": 7.231,
    "out:LCP/MSEK": 1.665,
    "out:ROI% old": 16.58,
    "out:Payback discounted": 7,
    "out:Atemp": 5143,
    "out:Umean": 0.57,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 83,
    "out:Energy use kWh/m2": 83,
    "out:Energy savings %": 126.5,
    "out:Op energy cost/MSEK": 53,
    "out:El price/MSEK": 35,
    "out:Heating price/MSEK": 15,
    "out:DH+ DHW/kWh/m2": 53,
    "out:El bought/kWh/m2": 2180.3,
    "out:Return %": 15,
    "out:Return/kSEK/y": 1540,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 1479000,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 73950,
    "out:EAHP (replacement cost)": 1105400,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 25.51,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.33,
    "out:PV (kWp installed)": 122.531965,
    "out:PV (m2 panels)": 643,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 114968.11,
    "out:PV (kWh sold)": 48784.77,
    "out:PV (% sold (El))": 42.43,
    "out:PV (kWh self-consumed)": 66183.35,
    "out:PV (ratio sold/self-consumed)": 0.74,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 612017,
    "out:EL kWh savings": -70317,
    "out:Sewage heat recovery cost": 250448,
    "out:Balancing hydronic system cost": 128574.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 499598,
    "out:DH kr savings": 556935,
    "out:El kr savings": -119538,
    "out:El kr sold": 62201,
    "out:El kr saved": 112512,
    "out:El kr return": 174712,
    "out:% solar/total": 81,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 273025,
    "out:% savings (space heating)": 58.54,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 45.72,
    "out:Etvv": 0,
    "out:Ef": 29.57,
    "out:heatPumpQuantity": 10,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000002000000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 15.13,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 60.9,
    "out:Electricity (inc PV)": 56.97,
    "out:Total Energy Use Pre PV": 75.9,
    "out:Total Energy Use Post PV": 71.97,
    "out:Primary Energy": 109.26,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 12.37,
    "out:CO2 Operational/m2": 13.44,
    "out:Total CO2/m2": 25.81,
    "out:Total CO2 (tons)": 132.75,
    "out:Klimatpaverkan": -196.49,
    "out:Initial Cost/MSEK": 10.395375,
    "out:Running cost/(Apt SEK y)": 17504,
    "out:Running Cost over RSP/MSEK": 5.703,
    "out:LCP/MSEK": 3.171,
    "out:ROI% old": 18.64,
    "out:Payback discounted": 7,
    "out:Atemp": 5143,
    "out:Umean": 0.57,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 72,
    "out:Energy use kWh/m2": 72,
    "out:Energy savings %": 161.1,
    "out:Op energy cost/MSEK": 39,
    "out:El price/MSEK": 34,
    "out:Heating price/MSEK": 4,
    "out:DH+ DHW/kWh/m2": 15,
    "out:El bought/kWh/m2": 4262.8,
    "out:Return %": 17,
    "out:Return/kSEK/y": 1729,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 2958000,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 147900,
    "out:EAHP (replacement cost)": 2204400,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 42.7,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 807451,
    "out:EL kWh savings": -211204,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 375733,
    "out:DH kr savings": 734780,
    "out:El kr savings": -359048,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 77838,
    "out:% savings (space heating)": 88.18,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 9.61,
    "out:Etvv": 0,
    "out:Ef": 56.97,
    "out:heatPumpQuantity": 20,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000002000000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 12.18,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 60.33,
    "out:Electricity (inc PV)": 56.21,
    "out:Total Energy Use Pre PV": 72.33,
    "out:Total Energy Use Post PV": 68.21,
    "out:Primary Energy": 106.26,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 12.37,
    "out:CO2 Operational/m2": 12.28,
    "out:Total CO2/m2": 24.66,
    "out:Total CO2 (tons)": 126.81,
    "out:Klimatpaverkan": -202.43,
    "out:Initial Cost/MSEK": 10.524,
    "out:Running cost/(Apt SEK y)": 16420,
    "out:Running Cost over RSP/MSEK": 5.347,
    "out:LCP/MSEK": 3.398,
    "out:ROI% old": 18.9,
    "out:Payback discounted": 7,
    "out:Atemp": 5143,
    "out:Umean": 0.57,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 68,
    "out:Energy use kWh/m2": 68,
    "out:Energy savings %": 176.5,
    "out:Op energy cost/MSEK": 36,
    "out:El price/MSEK": 32,
    "out:Heating price/MSEK": 3,
    "out:DH+ DHW/kWh/m2": 12,
    "out:El bought/kWh/m2": 4222.8,
    "out:Return %": 17,
    "out:Return/kSEK/y": 1774,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 2958000,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 147900,
    "out:EAHP (replacement cost)": 2204400,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 42.13,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 822880,
    "out:EL kWh savings": -207292,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 128574.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 396424,
    "out:DH kr savings": 748821,
    "out:El kr savings": -352397,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 62645,
    "out:% savings (space heating)": 90.49,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 7.28,
    "out:Etvv": 0,
    "out:Ef": 56.21,
    "out:heatPumpQuantity": 20,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000002000001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 13.02,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 59.56,
    "out:Electricity (inc PV)": 56.12,
    "out:Total Energy Use Pre PV": 72.56,
    "out:Total Energy Use Post PV": 69.12,
    "out:Primary Energy": 107.07,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 12.78,
    "out:CO2 Operational/m2": 12.54,
    "out:Total CO2/m2": 25.32,
    "out:Total CO2 (tons)": 130.21,
    "out:Klimatpaverkan": -199.03,
    "out:Initial Cost/MSEK": 10.645875,
    "out:Running cost/(Apt SEK y)": 16582,
    "out:Running Cost over RSP/MSEK": 5.401,
    "out:LCP/MSEK": 3.222,
    "out:ROI% old": 18.61,
    "out:Payback discounted": 7,
    "out:Atemp": 5143,
    "out:Umean": 0.57,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 69,
    "out:Energy use kWh/m2": 69,
    "out:Energy savings %": 172.5,
    "out:Op energy cost/MSEK": 37,
    "out:El price/MSEK": 32,
    "out:Heating price/MSEK": 4,
    "out:DH+ DHW/kWh/m2": 13,
    "out:El bought/kWh/m2": 4169.2,
    "out:Return %": 17,
    "out:Return/kSEK/y": 1767,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 2958000,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 147900,
    "out:EAHP (replacement cost)": 2204400,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 41.36,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 817737,
    "out:EL kWh savings": -206830,
    "out:Sewage heat recovery cost": 250448,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 392530,
    "out:DH kr savings": 744141,
    "out:El kr savings": -351610,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 66977,
    "out:% savings (space heating)": 89.83,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 8.66,
    "out:Etvv": 0,
    "out:Ef": 56.12,
    "out:heatPumpQuantity": 20,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000002000001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 10.27,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 58.93,
    "out:Electricity (inc PV)": 55.31,
    "out:Total Energy Use Pre PV": 68.93,
    "out:Total Energy Use Post PV": 65.31,
    "out:Primary Energy": 104.1,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 12.78,
    "out:CO2 Operational/m2": 11.38,
    "out:Total CO2/m2": 24.15,
    "out:Total CO2 (tons)": 124.23,
    "out:Klimatpaverkan": -205.01,
    "out:Initial Cost/MSEK": 10.774375,
    "out:Running cost/(Apt SEK y)": 15485,
    "out:Running Cost over RSP/MSEK": 5.041,
    "out:LCP/MSEK": 3.454,
    "out:ROI% old": 18.87,
    "out:Payback discounted": 7,
    "out:Atemp": 5143,
    "out:Umean": 0.57,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 65,
    "out:Energy use kWh/m2": 65,
    "out:Energy savings %": 189.2,
    "out:Op energy cost/MSEK": 34,
    "out:El price/MSEK": 31,
    "out:Heating price/MSEK": 3,
    "out:DH+ DHW/kWh/m2": 10,
    "out:El bought/kWh/m2": 4124.8,
    "out:Return %": 17,
    "out:Return/kSEK/y": 1814,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 2958000,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 147900,
    "out:EAHP (replacement cost)": 2204400,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 40.73,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 833166,
    "out:EL kWh savings": -202669,
    "out:Sewage heat recovery cost": 250448,
    "out:Balancing hydronic system cost": 128574.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 413643,
    "out:DH kr savings": 758181,
    "out:El kr savings": -344538,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 52833,
    "out:% savings (space heating)": 91.98,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 6.49,
    "out:Etvv": 0,
    "out:Ef": 55.31,
    "out:heatPumpQuantity": 20,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000002010000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 15.13,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 60.9,
    "out:Electricity (inc PV)": 46.4,
    "out:Total Energy Use Pre PV": 75.9,
    "out:Total Energy Use Post PV": 61.4,
    "out:Primary Energy": 90.24,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 24.73,
    "out:CO2 Operational/m2": 10.13,
    "out:Total CO2/m2": 34.85,
    "out:Total CO2 (tons)": 179.25,
    "out:Klimatpaverkan": -149.99,
    "out:Initial Cost/MSEK": 11.46525,
    "out:Running cost/(Apt SEK y)": 15199,
    "out:Running Cost over RSP/MSEK": 4.966,
    "out:LCP/MSEK": 2.837,
    "out:ROI% old": 17.82,
    "out:Payback discounted": 7,
    "out:Atemp": 5143,
    "out:Umean": 0.57,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 61,
    "out:Energy use kWh/m2": 61,
    "out:Energy savings %": 208.2,
    "out:Op energy cost/MSEK": 34,
    "out:El price/MSEK": 29,
    "out:Heating price/MSEK": 4,
    "out:DH+ DHW/kWh/m2": 15,
    "out:El bought/kWh/m2": 3548.5,
    "out:Return %": 16,
    "out:Return/kSEK/y": 1826,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 2958000,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 147900,
    "out:EAHP (replacement cost)": 2204400,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 42.7,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 61.265983,
    "out:PV (m2 panels)": 321,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 57484.06,
    "out:PV (kWh sold)": 2141.45,
    "out:PV (% sold (El))": 3.73,
    "out:PV (kWh self-consumed)": 55342.61,
    "out:PV (ratio sold/self-consumed)": 0.04,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 807451,
    "out:EL kWh savings": -156853,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 470860,
    "out:DH kr savings": 734780,
    "out:El kr savings": -266651,
    "out:El kr sold": 2730,
    "out:El kr saved": 94082,
    "out:El kr return": 96813,
    "out:% solar/total": 68,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 77838,
    "out:% savings (space heating)": 88.18,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 9.61,
    "out:Etvv": 0,
    "out:Ef": 46.4,
    "out:heatPumpQuantity": 20,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000002010000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 12.18,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 60.33,
    "out:Electricity (inc PV)": 45.68,
    "out:Total Energy Use Pre PV": 72.33,
    "out:Total Energy Use Post PV": 57.68,
    "out:Primary Energy": 87.32,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 24.73,
    "out:CO2 Operational/m2": 8.85,
    "out:Total CO2/m2": 33.58,
    "out:Total CO2 (tons)": 172.69,
    "out:Klimatpaverkan": -156.55,
    "out:Initial Cost/MSEK": 11.59375,
    "out:Running cost/(Apt SEK y)": 14116,
    "out:Running Cost over RSP/MSEK": 4.611,
    "out:LCP/MSEK": 3.064,
    "out:ROI% old": 18.06,
    "out:Payback discounted": 7,
    "out:Atemp": 5143,
    "out:Umean": 0.57,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 58,
    "out:Energy use kWh/m2": 58,
    "out:Energy savings %": 224.1,
    "out:Op energy cost/MSEK": 32,
    "out:El price/MSEK": 28,
    "out:Heating price/MSEK": 3,
    "out:DH+ DHW/kWh/m2": 12,
    "out:El bought/kWh/m2": 3509.8,
    "out:Return %": 16,
    "out:Return/kSEK/y": 1871,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 2958000,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 147900,
    "out:EAHP (replacement cost)": 2204400,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 42.13,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 61.265983,
    "out:PV (m2 panels)": 321,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 57484.06,
    "out:PV (kWh sold)": 2273.53,
    "out:PV (% sold (El))": 3.96,
    "out:PV (kWh self-consumed)": 55210.53,
    "out:PV (ratio sold/self-consumed)": 0.04,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 822880,
    "out:EL kWh savings": -153157,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 128574.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 491352,
    "out:DH kr savings": 748821,
    "out:El kr savings": -260367,
    "out:El kr sold": 2899,
    "out:El kr saved": 93858,
    "out:El kr return": 96757,
    "out:% solar/total": 68,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 62645,
    "out:% savings (space heating)": 90.49,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 7.28,
    "out:Etvv": 0,
    "out:Ef": 45.68,
    "out:heatPumpQuantity": 20,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000002010001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 13.02,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 59.56,
    "out:Electricity (inc PV)": 45.59,
    "out:Total Energy Use Pre PV": 72.56,
    "out:Total Energy Use Post PV": 58.59,
    "out:Primary Energy": 88.14,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 25.13,
    "out:CO2 Operational/m2": 8.94,
    "out:Total CO2/m2": 34.07,
    "out:Total CO2 (tons)": 175.21,
    "out:Klimatpaverkan": -154.03,
    "out:Initial Cost/MSEK": 11.715625,
    "out:Running cost/(Apt SEK y)": 14280,
    "out:Running Cost over RSP/MSEK": 4.666,
    "out:LCP/MSEK": 2.888,
    "out:ROI% old": 17.81,
    "out:Payback discounted": 7,
    "out:Atemp": 5143,
    "out:Umean": 0.57,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 59,
    "out:Energy use kWh/m2": 59,
    "out:Energy savings %": 218.6,
    "out:Op energy cost/MSEK": 32,
    "out:El price/MSEK": 28,
    "out:Heating price/MSEK": 4,
    "out:DH+ DHW/kWh/m2": 13,
    "out:El bought/kWh/m2": 3458.1,
    "out:Return %": 16,
    "out:Return/kSEK/y": 1864,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 2958000,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 147900,
    "out:EAHP (replacement cost)": 2204400,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 41.36,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 61.265983,
    "out:PV (m2 panels)": 321,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 57484.06,
    "out:PV (kWh sold)": 2457.1,
    "out:PV (% sold (El))": 4.27,
    "out:PV (kWh self-consumed)": 55026.96,
    "out:PV (ratio sold/self-consumed)": 0.04,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 817737,
    "out:EL kWh savings": -152721,
    "out:Sewage heat recovery cost": 250448,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 487648,
    "out:DH kr savings": 744141,
    "out:El kr savings": -259625,
    "out:El kr sold": 3133,
    "out:El kr saved": 93546,
    "out:El kr return": 96679,
    "out:% solar/total": 67,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 66977,
    "out:% savings (space heating)": 89.83,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 8.66,
    "out:Etvv": 0,
    "out:Ef": 45.59,
    "out:heatPumpQuantity": 20,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000002010001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 10.27,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 58.93,
    "out:Electricity (inc PV)": 44.83,
    "out:Total Energy Use Pre PV": 68.93,
    "out:Total Energy Use Post PV": 54.83,
    "out:Primary Energy": 85.24,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 25.13,
    "out:CO2 Operational/m2": 7.63,
    "out:Total CO2/m2": 32.76,
    "out:Total CO2 (tons)": 168.48,
    "out:Klimatpaverkan": -160.76,
    "out:Initial Cost/MSEK": 11.84425,
    "out:Running cost/(Apt SEK y)": 13185,
    "out:Running Cost over RSP/MSEK": 4.306,
    "out:LCP/MSEK": 3.119,
    "out:ROI% old": 18.05,
    "out:Payback discounted": 7,
    "out:Atemp": 5143,
    "out:Umean": 0.57,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 55,
    "out:Energy use kWh/m2": 55,
    "out:Energy savings %": 241.8,
    "out:Op energy cost/MSEK": 30,
    "out:El price/MSEK": 26,
    "out:Heating price/MSEK": 3,
    "out:DH+ DHW/kWh/m2": 10,
    "out:El bought/kWh/m2": 3415.4,
    "out:Return %": 16,
    "out:Return/kSEK/y": 1910,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 2958000,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 147900,
    "out:EAHP (replacement cost)": 2204400,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 40.73,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 61.265983,
    "out:PV (m2 panels)": 321,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 57484.06,
    "out:PV (kWh sold)": 2614.66,
    "out:PV (% sold (El))": 4.55,
    "out:PV (kWh self-consumed)": 54869.4,
    "out:PV (ratio sold/self-consumed)": 0.05,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 833166,
    "out:EL kWh savings": -148801,
    "out:Sewage heat recovery cost": 250448,
    "out:Balancing hydronic system cost": 128574.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 508553,
    "out:DH kr savings": 758181,
    "out:El kr savings": -252962,
    "out:El kr sold": 3334,
    "out:El kr saved": 93278,
    "out:El kr return": 96612,
    "out:% solar/total": 67,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 52833,
    "out:% savings (space heating)": 91.98,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 6.49,
    "out:Etvv": 0,
    "out:Ef": 44.83,
    "out:heatPumpQuantity": 20,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000002020000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 15.13,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 60.9,
    "out:Electricity (inc PV)": 41.76,
    "out:Total Energy Use Pre PV": 75.9,
    "out:Total Energy Use Post PV": 56.76,
    "out:Primary Energy": 81.89,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 37.08,
    "out:CO2 Operational/m2": -21.12,
    "out:Total CO2/m2": 15.96,
    "out:Total CO2 (tons)": 82.06,
    "out:Klimatpaverkan": -247.18,
    "out:Initial Cost/MSEK": 12.535,
    "out:Running cost/(Apt SEK y)": 13190,
    "out:Running Cost over RSP/MSEK": 4.323,
    "out:LCP/MSEK": 2.411,
    "out:ROI% old": 17.03,
    "out:Payback discounted": 7,
    "out:Atemp": 5143,
    "out:Umean": 0.57,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 57,
    "out:Energy use kWh/m2": 57,
    "out:Energy savings %": 229.8,
    "out:Op energy cost/MSEK": 30,
    "out:El price/MSEK": 25,
    "out:Heating price/MSEK": 4,
    "out:DH+ DHW/kWh/m2": 15,
    "out:El bought/kWh/m2": 3186,
    "out:Return %": 15,
    "out:Return/kSEK/y": 1910,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 2958000,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 147900,
    "out:EAHP (replacement cost)": 2204400,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 42.7,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.33,
    "out:PV (kWp installed)": 122.531965,
    "out:PV (m2 panels)": 643,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 114968.11,
    "out:PV (kWh sold)": 33550.76,
    "out:PV (% sold (El))": 29.18,
    "out:PV (kWh self-consumed)": 81417.35,
    "out:PV (ratio sold/self-consumed)": 0.41,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 807451,
    "out:EL kWh savings": -132989,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 551476,
    "out:DH kr savings": 734780,
    "out:El kr savings": -226082,
    "out:El kr sold": 42777,
    "out:El kr saved": 138409,
    "out:El kr return": 181187,
    "out:% solar/total": 100,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 77838,
    "out:% savings (space heating)": 88.18,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 9.61,
    "out:Etvv": 0,
    "out:Ef": 41.76,
    "out:heatPumpQuantity": 20,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000002020000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 12.18,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 60.33,
    "out:Electricity (inc PV)": 41.12,
    "out:Total Energy Use Pre PV": 72.33,
    "out:Total Energy Use Post PV": 53.12,
    "out:Primary Energy": 79.11,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 37.08,
    "out:CO2 Operational/m2": -22.72,
    "out:Total CO2/m2": 14.36,
    "out:Total CO2 (tons)": 73.85,
    "out:Klimatpaverkan": -255.39,
    "out:Initial Cost/MSEK": 12.66375,
    "out:Running cost/(Apt SEK y)": 12110,
    "out:Running Cost over RSP/MSEK": 3.969,
    "out:LCP/MSEK": 2.636,
    "out:ROI% old": 17.26,
    "out:Payback discounted": 7,
    "out:Atemp": 5143,
    "out:Umean": 0.57,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 53,
    "out:Energy use kWh/m2": 53,
    "out:Energy savings %": 254.7,
    "out:Op energy cost/MSEK": 28,
    "out:El price/MSEK": 24,
    "out:Heating price/MSEK": 3,
    "out:DH+ DHW/kWh/m2": 12,
    "out:El bought/kWh/m2": 3151.8,
    "out:Return %": 15,
    "out:Return/kSEK/y": 1955,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 2958000,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 147900,
    "out:EAHP (replacement cost)": 2204400,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 42.13,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.33,
    "out:PV (kWp installed)": 122.531965,
    "out:PV (m2 panels)": 643,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 114968.11,
    "out:PV (kWh sold)": 34006.13,
    "out:PV (% sold (El))": 29.58,
    "out:PV (kWh self-consumed)": 80961.98,
    "out:PV (ratio sold/self-consumed)": 0.42,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 822880,
    "out:EL kWh savings": -129714,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 128574.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 571665,
    "out:DH kr savings": 748821,
    "out:El kr savings": -220513,
    "out:El kr sold": 43358,
    "out:El kr saved": 137635,
    "out:El kr return": 180993,
    "out:% solar/total": 99,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 62645,
    "out:% savings (space heating)": 90.49,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 7.28,
    "out:Etvv": 0,
    "out:Ef": 41.12,
    "out:heatPumpQuantity": 20,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000002020001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 13.02,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 59.56,
    "out:Electricity (inc PV)": 41.05,
    "out:Total Energy Use Pre PV": 72.56,
    "out:Total Energy Use Post PV": 54.05,
    "out:Primary Energy": 79.95,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 37.48,
    "out:CO2 Operational/m2": -23.06,
    "out:Total CO2/m2": 14.43,
    "out:Total CO2 (tons)": 74.19,
    "out:Klimatpaverkan": -255.05,
    "out:Initial Cost/MSEK": 12.785,
    "out:Running cost/(Apt SEK y)": 12279,
    "out:Running Cost over RSP/MSEK": 4.025,
    "out:LCP/MSEK": 2.459,
    "out:ROI% old": 17.03,
    "out:Payback discounted": 7,
    "out:Atemp": 5143,
    "out:Umean": 0.57,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 54,
    "out:Energy use kWh/m2": 54,
    "out:Energy savings %": 248.1,
    "out:Op energy cost/MSEK": 28,
    "out:El price/MSEK": 24,
    "out:Heating price/MSEK": 4,
    "out:DH+ DHW/kWh/m2": 13,
    "out:El bought/kWh/m2": 3106,
    "out:Return %": 15,
    "out:Return/kSEK/y": 1948,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 2958000,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 147900,
    "out:EAHP (replacement cost)": 2204400,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 41.36,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.33,
    "out:PV (kWp installed)": 122.531965,
    "out:PV (m2 panels)": 643,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 114968.11,
    "out:PV (kWh sold)": 34619.21,
    "out:PV (% sold (El))": 30.11,
    "out:PV (kWh self-consumed)": 80348.9,
    "out:PV (ratio sold/self-consumed)": 0.43,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 817737,
    "out:EL kWh savings": -129327,
    "out:Sewage heat recovery cost": 250448,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 568424,
    "out:DH kr savings": 744141,
    "out:El kr savings": -219856,
    "out:El kr sold": 44139,
    "out:El kr saved": 136593,
    "out:El kr return": 180733,
    "out:% solar/total": 98,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 66977,
    "out:% savings (space heating)": 89.83,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 8.66,
    "out:Etvv": 0,
    "out:Ef": 41.05,
    "out:heatPumpQuantity": 20,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000002020001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 10.27,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 58.93,
    "out:Electricity (inc PV)": 40.37,
    "out:Total Energy Use Pre PV": 68.93,
    "out:Total Energy Use Post PV": 50.37,
    "out:Primary Energy": 77.21,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 37.48,
    "out:CO2 Operational/m2": -24.72,
    "out:Total CO2/m2": 12.76,
    "out:Total CO2 (tons)": 65.64,
    "out:Klimatpaverkan": -263.6,
    "out:Initial Cost/MSEK": 12.91375,
    "out:Running cost/(Apt SEK y)": 11187,
    "out:Running Cost over RSP/MSEK": 3.666,
    "out:LCP/MSEK": 2.689,
    "out:ROI% old": 17.26,
    "out:Payback discounted": 7,
    "out:Atemp": 5143,
    "out:Umean": 0.57,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 50,
    "out:Energy use kWh/m2": 50,
    "out:Energy savings %": 276,
    "out:Op energy cost/MSEK": 26,
    "out:El price/MSEK": 22,
    "out:Heating price/MSEK": 3,
    "out:DH+ DHW/kWh/m2": 10,
    "out:El bought/kWh/m2": 3068.2,
    "out:Return %": 15,
    "out:Return/kSEK/y": 1994,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 2958000,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 147900,
    "out:EAHP (replacement cost)": 2204400,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 40.73,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.33,
    "out:PV (kWp installed)": 122.531965,
    "out:PV (m2 panels)": 643,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 114968.11,
    "out:PV (kWh sold)": 35135.08,
    "out:PV (% sold (El))": 30.56,
    "out:PV (kWh self-consumed)": 79833.03,
    "out:PV (ratio sold/self-consumed)": 0.44,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 833166,
    "out:EL kWh savings": -125851,
    "out:Sewage heat recovery cost": 250448,
    "out:Balancing hydronic system cost": 128574.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 589032,
    "out:DH kr savings": 758181,
    "out:El kr savings": -213946,
    "out:El kr sold": 44797,
    "out:El kr saved": 135716,
    "out:El kr return": 180513,
    "out:% solar/total": 98,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 52833,
    "out:% savings (space heating)": 91.98,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 6.49,
    "out:Etvv": 0,
    "out:Ef": 40.37,
    "out:heatPumpQuantity": 20,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000002100000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 12.36,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 59.1,
    "out:Electricity (inc PV)": 55.83,
    "out:Total Energy Use Pre PV": 71.1,
    "out:Total Energy Use Post PV": 67.83,
    "out:Primary Energy": 106.35,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 12.37,
    "out:CO2 Operational/m2": 12.12,
    "out:Total CO2/m2": 24.5,
    "out:Total CO2 (tons)": 125.98,
    "out:Klimatpaverkan": -203.26,
    "out:Initial Cost/MSEK": 10.50625,
    "out:Running cost/(Apt SEK y)": 16165,
    "out:Running Cost over RSP/MSEK": 5.264,
    "out:LCP/MSEK": 3.499,
    "out:ROI% old": 19.04,
    "out:Payback discounted": 6,
    "out:Atemp": 5143,
    "out:Umean": 0.57,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 68,
    "out:Energy use kWh/m2": 68,
    "out:Energy savings %": 176.5,
    "out:Op energy cost/MSEK": 36,
    "out:El price/MSEK": 32,
    "out:Heating price/MSEK": 3,
    "out:DH+ DHW/kWh/m2": 12,
    "out:El bought/kWh/m2": 4137.2,
    "out:Return %": 17,
    "out:Return/kSEK/y": 1785,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 2958000,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 147900,
    "out:EAHP (replacement cost)": 2204400,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 40.9,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 822880,
    "out:EL kWh savings": -205347,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 399731,
    "out:DH kr savings": 748821,
    "out:El kr savings": -349089,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 63555,
    "out:% savings (space heating)": 90.35,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 8.37,
    "out:Etvv": 0,
    "out:Ef": 55.83,
    "out:heatPumpQuantity": 20,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000002100000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 9.68,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 58.45,
    "out:Electricity (inc PV)": 55,
    "out:Total Energy Use Pre PV": 68.45,
    "out:Total Energy Use Post PV": 65,
    "out:Primary Energy": 103.38,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 12.37,
    "out:CO2 Operational/m2": 11.32,
    "out:Total CO2/m2": 23.69,
    "out:Total CO2 (tons)": 121.83,
    "out:Klimatpaverkan": -207.41,
    "out:Initial Cost/MSEK": 10.634875,
    "out:Running cost/(Apt SEK y)": 15385,
    "out:Running Cost over RSP/MSEK": 5.008,
    "out:LCP/MSEK": 3.626,
    "out:ROI% old": 19.16,
    "out:Payback discounted": 6,
    "out:Atemp": 5143,
    "out:Umean": 0.57,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 65,
    "out:Energy use kWh/m2": 65,
    "out:Energy savings %": 189.2,
    "out:Op energy cost/MSEK": 34,
    "out:El price/MSEK": 31,
    "out:Heating price/MSEK": 3,
    "out:DH+ DHW/kWh/m2": 10,
    "out:El bought/kWh/m2": 4091.3,
    "out:Return %": 17,
    "out:Return/kSEK/y": 1818,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 2958000,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 147900,
    "out:EAHP (replacement cost)": 2204400,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 40.25,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 833166,
    "out:EL kWh savings": -201099,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 128574.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 416313,
    "out:DH kr savings": 758181,
    "out:El kr savings": -341868,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 49777,
    "out:% savings (space heating)": 92.44,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 6.25,
    "out:Etvv": 0,
    "out:Ef": 55,
    "out:heatPumpQuantity": 20,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000002100001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 10.84,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 57.98,
    "out:Electricity (inc PV)": 55.13,
    "out:Total Energy Use Pre PV": 68.98,
    "out:Total Energy Use Post PV": 66.13,
    "out:Primary Energy": 104.6,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 12.78,
    "out:CO2 Operational/m2": 11.61,
    "out:Total CO2/m2": 24.39,
    "out:Total CO2 (tons)": 125.43,
    "out:Klimatpaverkan": -203.81,
    "out:Initial Cost/MSEK": 10.75675,
    "out:Running cost/(Apt SEK y)": 15610,
    "out:Running Cost over RSP/MSEK": 5.083,
    "out:LCP/MSEK": 3.43,
    "out:ROI% old": 18.84,
    "out:Payback discounted": 7,
    "out:Atemp": 5143,
    "out:Umean": 0.57,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 66,
    "out:Energy use kWh/m2": 66,
    "out:Energy savings %": 184.8,
    "out:Op energy cost/MSEK": 35,
    "out:El price/MSEK": 31,
    "out:Heating price/MSEK": 3,
    "out:DH+ DHW/kWh/m2": 11,
    "out:El bought/kWh/m2": 4058.6,
    "out:Return %": 17,
    "out:Return/kSEK/y": 1808,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 2958000,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 147900,
    "out:EAHP (replacement cost)": 2204400,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 39.78,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 828023,
    "out:EL kWh savings": -201743,
    "out:Sewage heat recovery cost": 250448,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 410537,
    "out:DH kr savings": 753501,
    "out:El kr savings": -342963,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 55767,
    "out:% savings (space heating)": 91.53,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 7.68,
    "out:Etvv": 0,
    "out:Ef": 55.13,
    "out:heatPumpQuantity": 20,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000002100001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 8.34,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 57.27,
    "out:Electricity (inc PV)": 54.26,
    "out:Total Energy Use Pre PV": 65.27,
    "out:Total Energy Use Post PV": 62.26,
    "out:Primary Energy": 101.66,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 12.78,
    "out:CO2 Operational/m2": 10.44,
    "out:Total CO2/m2": 23.22,
    "out:Total CO2 (tons)": 119.4,
    "out:Klimatpaverkan": -209.84,
    "out:Initial Cost/MSEK": 10.88525,
    "out:Running cost/(Apt SEK y)": 14497,
    "out:Running Cost over RSP/MSEK": 4.717,
    "out:LCP/MSEK": 3.666,
    "out:ROI% old": 19.1,
    "out:Payback discounted": 6,
    "out:Atemp": 5143,
    "out:Umean": 0.57,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 62,
    "out:Energy use kWh/m2": 62,
    "out:Energy savings %": 203.2,
    "out:Op energy cost/MSEK": 32,
    "out:El price/MSEK": 29,
    "out:Heating price/MSEK": 2,
    "out:DH+ DHW/kWh/m2": 8,
    "out:El bought/kWh/m2": 4009,
    "out:Return %": 17,
    "out:Return/kSEK/y": 1855,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 2958000,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 147900,
    "out:EAHP (replacement cost)": 2204400,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 39.07,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 843452,
    "out:EL kWh savings": -197280,
    "out:Sewage heat recovery cost": 250448,
    "out:Balancing hydronic system cost": 128574.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 432165,
    "out:DH kr savings": 767541,
    "out:El kr savings": -335377,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 42884,
    "out:% savings (space heating)": 93.49,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 5.7,
    "out:Etvv": 0,
    "out:Ef": 54.26,
    "out:heatPumpQuantity": 20,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000002110000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 12.36,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 59.1,
    "out:Electricity (inc PV)": 45.32,
    "out:Total Energy Use Pre PV": 71.1,
    "out:Total Energy Use Post PV": 57.32,
    "out:Primary Energy": 87.44,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 24.73,
    "out:CO2 Operational/m2": 8.41,
    "out:Total CO2/m2": 33.14,
    "out:Total CO2 (tons)": 170.44,
    "out:Klimatpaverkan": -158.8,
    "out:Initial Cost/MSEK": 11.576,
    "out:Running cost/(Apt SEK y)": 13865,
    "out:Running Cost over RSP/MSEK": 4.529,
    "out:LCP/MSEK": 3.164,
    "out:ROI% old": 18.19,
    "out:Payback discounted": 7,
    "out:Atemp": 5143,
    "out:Umean": 0.57,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 57,
    "out:Energy use kWh/m2": 57,
    "out:Energy savings %": 229.8,
    "out:Op energy cost/MSEK": 31,
    "out:El price/MSEK": 27,
    "out:Heating price/MSEK": 3,
    "out:DH+ DHW/kWh/m2": 12,
    "out:El bought/kWh/m2": 3427.3,
    "out:Return %": 16,
    "out:Return/kSEK/y": 1882,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 2958000,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 147900,
    "out:EAHP (replacement cost)": 2204400,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 40.9,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 61.265983,
    "out:PV (m2 panels)": 321,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 57484.06,
    "out:PV (kWh sold)": 2570.39,
    "out:PV (% sold (El))": 4.47,
    "out:PV (kWh self-consumed)": 54913.67,
    "out:PV (ratio sold/self-consumed)": 0.05,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 822880,
    "out:EL kWh savings": -151323,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 494850,
    "out:DH kr savings": 748821,
    "out:El kr savings": -257248,
    "out:El kr sold": 3277,
    "out:El kr saved": 93353,
    "out:El kr return": 96630,
    "out:% solar/total": 67,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 63555,
    "out:% savings (space heating)": 90.35,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 8.37,
    "out:Etvv": 0,
    "out:Ef": 45.32,
    "out:heatPumpQuantity": 20,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000002110000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 9.68,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 58.45,
    "out:Electricity (inc PV)": 44.55,
    "out:Total Energy Use Pre PV": 68.45,
    "out:Total Energy Use Post PV": 54.55,
    "out:Primary Energy": 84.56,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 24.73,
    "out:CO2 Operational/m2": 7.45,
    "out:Total CO2/m2": 32.18,
    "out:Total CO2 (tons)": 165.5,
    "out:Klimatpaverkan": -163.74,
    "out:Initial Cost/MSEK": 11.704625,
    "out:Running cost/(Apt SEK y)": 13086,
    "out:Running Cost over RSP/MSEK": 4.274,
    "out:LCP/MSEK": 3.291,
    "out:ROI% old": 18.3,
    "out:Payback discounted": 7,
    "out:Atemp": 5143,
    "out:Umean": 0.57,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 55,
    "out:Energy use kWh/m2": 55,
    "out:Energy savings %": 241.8,
    "out:Op energy cost/MSEK": 29,
    "out:El price/MSEK": 26,
    "out:Heating price/MSEK": 3,
    "out:DH+ DHW/kWh/m2": 10,
    "out:El bought/kWh/m2": 3383.2,
    "out:Return %": 16,
    "out:Return/kSEK/y": 1914,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 2958000,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 147900,
    "out:EAHP (replacement cost)": 2204400,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 40.25,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 61.265983,
    "out:PV (m2 panels)": 321,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 57484.06,
    "out:PV (kWh sold)": 2736.34,
    "out:PV (% sold (El))": 4.76,
    "out:PV (kWh self-consumed)": 54747.72,
    "out:PV (ratio sold/self-consumed)": 0.05,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 833166,
    "out:EL kWh savings": -147324,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 128574.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 511219,
    "out:DH kr savings": 758181,
    "out:El kr savings": -250451,
    "out:El kr sold": 3489,
    "out:El kr saved": 93071,
    "out:El kr return": 96560,
    "out:% solar/total": 67,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 49777,
    "out:% savings (space heating)": 92.44,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 6.25,
    "out:Etvv": 0,
    "out:Ef": 44.55,
    "out:heatPumpQuantity": 20,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000002110001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 10.84,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 57.98,
    "out:Electricity (inc PV)": 44.66,
    "out:Total Energy Use Pre PV": 68.98,
    "out:Total Energy Use Post PV": 55.66,
    "out:Primary Energy": 85.77,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 25.13,
    "out:CO2 Operational/m2": 7.64,
    "out:Total CO2/m2": 32.76,
    "out:Total CO2 (tons)": 168.51,
    "out:Klimatpaverkan": -160.73,
    "out:Initial Cost/MSEK": 11.8265,
    "out:Running cost/(Apt SEK y)": 13312,
    "out:Running Cost over RSP/MSEK": 4.348,
    "out:LCP/MSEK": 3.094,
    "out:ROI% old": 18.02,
    "out:Payback discounted": 7,
    "out:Atemp": 5143,
    "out:Umean": 0.57,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 56,
    "out:Energy use kWh/m2": 56,
    "out:Energy savings %": 235.7,
    "out:Op energy cost/MSEK": 30,
    "out:El price/MSEK": 26,
    "out:Heating price/MSEK": 3,
    "out:DH+ DHW/kWh/m2": 11,
    "out:El bought/kWh/m2": 3351.8,
    "out:Return %": 16,
    "out:Return/kSEK/y": 1905,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 2958000,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 147900,
    "out:EAHP (replacement cost)": 2204400,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 39.78,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 61.265983,
    "out:PV (m2 panels)": 321,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 57484.06,
    "out:PV (kWh sold)": 2857.96,
    "out:PV (% sold (El))": 4.97,
    "out:PV (kWh self-consumed)": 54626.1,
    "out:PV (ratio sold/self-consumed)": 0.05,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 828023,
    "out:EL kWh savings": -147930,
    "out:Sewage heat recovery cost": 250448,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 505664,
    "out:DH kr savings": 753501,
    "out:El kr savings": -251481,
    "out:El kr sold": 3644,
    "out:El kr saved": 92864,
    "out:El kr return": 96508,
    "out:% solar/total": 67,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 55767,
    "out:% savings (space heating)": 91.53,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 7.68,
    "out:Etvv": 0,
    "out:Ef": 44.66,
    "out:heatPumpQuantity": 20,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000002110001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 8.34,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 57.27,
    "out:Electricity (inc PV)": 43.85,
    "out:Total Energy Use Pre PV": 65.27,
    "out:Total Energy Use Post PV": 51.85,
    "out:Primary Energy": 82.92,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 25.13,
    "out:CO2 Operational/m2": 6.28,
    "out:Total CO2/m2": 31.41,
    "out:Total CO2 (tons)": 161.56,
    "out:Klimatpaverkan": -167.68,
    "out:Initial Cost/MSEK": 11.955125,
    "out:Running cost/(Apt SEK y)": 12201,
    "out:Running Cost over RSP/MSEK": 3.984,
    "out:LCP/MSEK": 3.33,
    "out:ROI% old": 18.27,
    "out:Payback discounted": 7,
    "out:Atemp": 5143,
    "out:Umean": 0.57,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 52,
    "out:Energy use kWh/m2": 52,
    "out:Energy savings %": 261.5,
    "out:Op energy cost/MSEK": 27,
    "out:El price/MSEK": 25,
    "out:Heating price/MSEK": 2,
    "out:DH+ DHW/kWh/m2": 8,
    "out:El bought/kWh/m2": 3304.2,
    "out:Return %": 16,
    "out:Return/kSEK/y": 1951,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 2958000,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 147900,
    "out:EAHP (replacement cost)": 2204400,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 39.07,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 61.265983,
    "out:PV (m2 panels)": 321,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 57484.06,
    "out:PV (kWh sold)": 3048.81,
    "out:PV (% sold (El))": 5.3,
    "out:PV (kWh self-consumed)": 54435.24,
    "out:PV (ratio sold/self-consumed)": 0.06,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 843452,
    "out:EL kWh savings": -143738,
    "out:Sewage heat recovery cost": 250448,
    "out:Balancing hydronic system cost": 128574.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 527075,
    "out:DH kr savings": 767541,
    "out:El kr savings": -244354,
    "out:El kr sold": 3887,
    "out:El kr saved": 92540,
    "out:El kr return": 96427,
    "out:% solar/total": 67,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 42884,
    "out:% savings (space heating)": 93.49,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 5.7,
    "out:Etvv": 0,
    "out:Ef": 43.85,
    "out:heatPumpQuantity": 20,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000002120000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 12.36,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 59.1,
    "out:Electricity (inc PV)": 40.81,
    "out:Total Energy Use Pre PV": 71.1,
    "out:Total Energy Use Post PV": 52.81,
    "out:Primary Energy": 79.31,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 37.08,
    "out:CO2 Operational/m2": -23.84,
    "out:Total CO2/m2": 13.24,
    "out:Total CO2 (tons)": 68.11,
    "out:Klimatpaverkan": -261.13,
    "out:Initial Cost/MSEK": 12.64625,
    "out:Running cost/(Apt SEK y)": 11866,
    "out:Running Cost over RSP/MSEK": 3.889,
    "out:LCP/MSEK": 2.733,
    "out:ROI% old": 17.37,
    "out:Payback discounted": 7,
    "out:Atemp": 5143,
    "out:Umean": 0.57,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 53,
    "out:Energy use kWh/m2": 53,
    "out:Energy savings %": 254.7,
    "out:Op energy cost/MSEK": 27,
    "out:El price/MSEK": 23,
    "out:Heating price/MSEK": 3,
    "out:DH+ DHW/kWh/m2": 12,
    "out:El bought/kWh/m2": 3078.7,
    "out:Return %": 16,
    "out:Return/kSEK/y": 1966,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 2958000,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 147900,
    "out:EAHP (replacement cost)": 2204400,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 40.9,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.33,
    "out:PV (kWp installed)": 122.531965,
    "out:PV (m2 panels)": 643,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 114968.11,
    "out:PV (kWh sold)": 34990.73,
    "out:PV (% sold (El))": 30.44,
    "out:PV (kWh self-consumed)": 79977.38,
    "out:PV (ratio sold/self-consumed)": 0.44,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 822880,
    "out:EL kWh savings": -128087,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 575686,
    "out:DH kr savings": 748821,
    "out:El kr savings": -217748,
    "out:El kr sold": 44613,
    "out:El kr saved": 135962,
    "out:El kr return": 180575,
    "out:% solar/total": 98,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 63555,
    "out:% savings (space heating)": 90.35,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 8.37,
    "out:Etvv": 0,
    "out:Ef": 40.81,
    "out:heatPumpQuantity": 20,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000002120000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 9.68,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 58.45,
    "out:Electricity (inc PV)": 40.12,
    "out:Total Energy Use Pre PV": 68.45,
    "out:Total Energy Use Post PV": 50.12,
    "out:Primary Energy": 76.58,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 37.08,
    "out:CO2 Operational/m2": -25.16,
    "out:Total CO2/m2": 11.92,
    "out:Total CO2 (tons)": 61.3,
    "out:Klimatpaverkan": -267.94,
    "out:Initial Cost/MSEK": 12.775,
    "out:Running cost/(Apt SEK y)": 11091,
    "out:Running Cost over RSP/MSEK": 3.635,
    "out:LCP/MSEK": 2.859,
    "out:ROI% old": 17.48,
    "out:Payback discounted": 7,
    "out:Atemp": 5143,
    "out:Umean": 0.57,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 50,
    "out:Energy use kWh/m2": 50,
    "out:Energy savings %": 276,
    "out:Op energy cost/MSEK": 26,
    "out:El price/MSEK": 22,
    "out:Heating price/MSEK": 3,
    "out:DH+ DHW/kWh/m2": 10,
    "out:El bought/kWh/m2": 3039.7,
    "out:Return %": 16,
    "out:Return/kSEK/y": 1998,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 2958000,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 147900,
    "out:EAHP (replacement cost)": 2204400,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 40.25,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.33,
    "out:PV (kWp installed)": 122.531965,
    "out:PV (m2 panels)": 643,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 114968.11,
    "out:PV (kWh sold)": 35527.32,
    "out:PV (% sold (El))": 30.9,
    "out:PV (kWh self-consumed)": 79440.79,
    "out:PV (ratio sold/self-consumed)": 0.45,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 833166,
    "out:EL kWh savings": -124540,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 128574.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 591761,
    "out:DH kr savings": 758181,
    "out:El kr savings": -211718,
    "out:El kr sold": 45297,
    "out:El kr saved": 135049,
    "out:El kr return": 180347,
    "out:% solar/total": 97,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 49777,
    "out:% savings (space heating)": 92.44,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 6.25,
    "out:Etvv": 0,
    "out:Ef": 40.12,
    "out:heatPumpQuantity": 20,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000002120001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 10.84,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 57.98,
    "out:Electricity (inc PV)": 40.22,
    "out:Total Energy Use Pre PV": 68.98,
    "out:Total Energy Use Post PV": 51.22,
    "out:Primary Energy": 77.77,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 37.48,
    "out:CO2 Operational/m2": -25.24,
    "out:Total CO2/m2": 12.25,
    "out:Total CO2 (tons)": 62.98,
    "out:Klimatpaverkan": -266.26,
    "out:Initial Cost/MSEK": 12.89625,
    "out:Running cost/(Apt SEK y)": 11320,
    "out:Running Cost over RSP/MSEK": 3.711,
    "out:LCP/MSEK": 2.662,
    "out:ROI% old": 17.23,
    "out:Payback discounted": 7,
    "out:Atemp": 5143,
    "out:Umean": 0.57,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 51,
    "out:Energy use kWh/m2": 51,
    "out:Energy savings %": 268.6,
    "out:Op energy cost/MSEK": 26,
    "out:El price/MSEK": 22,
    "out:Heating price/MSEK": 3,
    "out:DH+ DHW/kWh/m2": 11,
    "out:El bought/kWh/m2": 3011.9,
    "out:Return %": 15,
    "out:Return/kSEK/y": 1988,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 2958000,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 147900,
    "out:EAHP (replacement cost)": 2204400,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 39.78,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.33,
    "out:PV (kWp installed)": 122.531965,
    "out:PV (m2 panels)": 643,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 114968.11,
    "out:PV (kWh sold)": 35912.04,
    "out:PV (% sold (El))": 31.24,
    "out:PV (kWh self-consumed)": 79056.07,
    "out:PV (ratio sold/self-consumed)": 0.45,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 828023,
    "out:EL kWh savings": -125077,
    "out:Sewage heat recovery cost": 250448,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 586657,
    "out:DH kr savings": 753501,
    "out:El kr savings": -212632,
    "out:El kr sold": 45788,
    "out:El kr saved": 134395,
    "out:El kr return": 180183,
    "out:% solar/total": 97,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 55767,
    "out:% savings (space heating)": 91.53,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 7.68,
    "out:Etvv": 0,
    "out:Ef": 40.22,
    "out:heatPumpQuantity": 20,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000002120001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 8.34,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 57.27,
    "out:Electricity (inc PV)": 39.5,
    "out:Total Energy Use Pre PV": 65.27,
    "out:Total Energy Use Post PV": 47.5,
    "out:Primary Energy": 75.08,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 37.48,
    "out:CO2 Operational/m2": -26.98,
    "out:Total CO2/m2": 10.5,
    "out:Total CO2 (tons)": 54.01,
    "out:Klimatpaverkan": -275.23,
    "out:Initial Cost/MSEK": 13.025,
    "out:Running cost/(Apt SEK y)": 10213,
    "out:Running Cost over RSP/MSEK": 3.347,
    "out:LCP/MSEK": 2.897,
    "out:ROI% old": 17.46,
    "out:Payback discounted": 7,
    "out:Atemp": 5143,
    "out:Umean": 0.57,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 47,
    "out:Energy use kWh/m2": 47,
    "out:Energy savings %": 300,
    "out:Op energy cost/MSEK": 23,
    "out:El price/MSEK": 21,
    "out:Heating price/MSEK": 2,
    "out:DH+ DHW/kWh/m2": 8,
    "out:El bought/kWh/m2": 2969.9,
    "out:Return %": 16,
    "out:Return/kSEK/y": 2035,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 2958000,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 147900,
    "out:EAHP (replacement cost)": 2204400,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 39.07,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.33,
    "out:PV (kWp installed)": 122.531965,
    "out:PV (m2 panels)": 643,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 114968.11,
    "out:PV (kWh sold)": 36499.31,
    "out:PV (% sold (El))": 31.75,
    "out:PV (kWh self-consumed)": 78468.8,
    "out:PV (ratio sold/self-consumed)": 0.47,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 843452,
    "out:EL kWh savings": -121355,
    "out:Sewage heat recovery cost": 250448,
    "out:Balancing hydronic system cost": 128574.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 607775,
    "out:DH kr savings": 767541,
    "out:El kr savings": -206303,
    "out:El kr sold": 46537,
    "out:El kr saved": 133397,
    "out:El kr return": 179934,
    "out:% solar/total": 96,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 42884,
    "out:% savings (space heating)": 93.49,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 5.7,
    "out:Etvv": 0,
    "out:Ef": 39.5,
    "out:heatPumpQuantity": 20,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000100000000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 101.23,
    "out:DHW (DH)": 43.5,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 15.9,
    "out:Total Energy Use Pre PV": 160.9,
    "out:Total Energy Use Post PV": 160.9,
    "out:Primary Energy": 116.98,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 10.98,
    "out:CO2 Operational/m2": 54.3,
    "out:Total CO2/m2": 65.28,
    "out:Total CO2 (tons)": 335.72,
    "out:Klimatpaverkan": 6.48,
    "out:Initial Cost/MSEK": 4.089,
    "out:Running cost/(Apt SEK y)": 49975,
    "out:Running Cost over RSP/MSEK": 16.413,
    "out:LCP/MSEK": -1.233,
    "out:ROI% old": 9.98,
    "out:Payback discounted": 12,
    "out:Atemp": 5143,
    "out:Umean": 0.42,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "F",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 161,
    "out:Energy use kWh/m2": 161,
    "out:Energy savings %": 16.8,
    "out:Op energy cost/MSEK": 120,
    "out:El price/MSEK": 72,
    "out:Heating price/MSEK": 42,
    "out:DH+ DHW/kWh/m2": 145,
    "out:El bought/kWh/m2": 1113,
    "out:Return %": 9,
    "out:Return/kSEK/y": 365,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 138861,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 126364,
    "out:DH kr savings": 126363,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 520638,
    "out:% savings (space heating)": 20.95,
    "out:Total_Water Heating (DH)": 223720,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 101.23,
    "out:Etvv": 25,
    "out:Ef": 15.9,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z01000100000000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 96.9,
    "out:DHW (DH)": 43.5,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 15.9,
    "out:Total Energy Use Pre PV": 155.9,
    "out:Total Energy Use Post PV": 155.9,
    "out:Primary Energy": 113.95,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 10.98,
    "out:CO2 Operational/m2": 52.5,
    "out:Total CO2/m2": 63.48,
    "out:Total CO2 (tons)": 326.46,
    "out:Klimatpaverkan": -2.78,
    "out:Initial Cost/MSEK": 4.217625,
    "out:Running cost/(Apt SEK y)": 48366,
    "out:Running Cost over RSP/MSEK": 15.884,
    "out:LCP/MSEK": -0.833,
    "out:ROI% old": 11.47,
    "out:Payback discounted": 11,
    "out:Atemp": 5143,
    "out:Umean": 0.42,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "F",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 156,
    "out:Energy use kWh/m2": 156,
    "out:Energy savings %": 20.5,
    "out:Op energy cost/MSEK": 116,
    "out:El price/MSEK": 70,
    "out:Heating price/MSEK": 40,
    "out:DH+ DHW/kWh/m2": 140,
    "out:El bought/kWh/m2": 1113,
    "out:Return %": 10,
    "out:Return/kSEK/y": 433,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 164576,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 128574.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 149764,
    "out:DH kr savings": 149764,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 498375,
    "out:% savings (space heating)": 24.33,
    "out:Total_Water Heating (DH)": 223720,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 96.9,
    "out:Etvv": 25,
    "out:Ef": 15.9,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z01000100000001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 101.23,
    "out:DHW (DH)": 36.98,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 15.9,
    "out:Total Energy Use Pre PV": 153.9,
    "out:Total Energy Use Post PV": 153.9,
    "out:Primary Energy": 114.36,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 11.38,
    "out:CO2 Operational/m2": 51.78,
    "out:Total CO2/m2": 63.16,
    "out:Total CO2 (tons)": 324.83,
    "out:Klimatpaverkan": -4.41,
    "out:Initial Cost/MSEK": 4.3395,
    "out:Running cost/(Apt SEK y)": 47722,
    "out:Running Cost over RSP/MSEK": 15.672,
    "out:LCP/MSEK": -0.743,
    "out:ROI% old": 11.84,
    "out:Payback discounted": 10,
    "out:Atemp": 5143,
    "out:Umean": 0.42,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "F",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 154,
    "out:Energy use kWh/m2": 154,
    "out:Energy savings %": 22.1,
    "out:Op energy cost/MSEK": 115,
    "out:El price/MSEK": 69,
    "out:Heating price/MSEK": 40,
    "out:DH+ DHW/kWh/m2": 138,
    "out:El bought/kWh/m2": 1113,
    "out:Return %": 11,
    "out:Return/kSEK/y": 460,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 174862,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 250448,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 159124,
    "out:DH kr savings": 159124,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 520638,
    "out:% savings (space heating)": 20.95,
    "out:Total_Water Heating (DH)": 190162,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 101.23,
    "out:Etvv": 21.25,
    "out:Ef": 15.9,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z01000100000001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 96.9,
    "out:DHW (DH)": 36.98,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 15.9,
    "out:Total Energy Use Pre PV": 149.9,
    "out:Total Energy Use Post PV": 149.9,
    "out:Primary Energy": 111.33,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 11.38,
    "out:CO2 Operational/m2": 50.34,
    "out:Total CO2/m2": 61.72,
    "out:Total CO2 (tons)": 317.42,
    "out:Klimatpaverkan": -11.82,
    "out:Initial Cost/MSEK": 4.468,
    "out:Running cost/(Apt SEK y)": 46435,
    "out:Running Cost over RSP/MSEK": 15.249,
    "out:LCP/MSEK": -0.448,
    "out:ROI% old": 12.85,
    "out:Payback discounted": 9,
    "out:Atemp": 5143,
    "out:Umean": 0.42,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "F",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 150,
    "out:Energy use kWh/m2": 150,
    "out:Energy savings %": 25.3,
    "out:Op energy cost/MSEK": 112,
    "out:El price/MSEK": 67,
    "out:Heating price/MSEK": 38,
    "out:DH+ DHW/kWh/m2": 134,
    "out:El bought/kWh/m2": 1113,
    "out:Return %": 11,
    "out:Return/kSEK/y": 514,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 195434,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 250448,
    "out:Balancing hydronic system cost": 128574.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 177845,
    "out:DH kr savings": 177845,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 498375,
    "out:% savings (space heating)": 24.33,
    "out:Total_Water Heating (DH)": 190162,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 96.9,
    "out:Etvv": 21.25,
    "out:Ef": 15.9,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z01000100010000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 101.23,
    "out:DHW (DH)": 43.5,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 10.73,
    "out:Total Energy Use Pre PV": 160.9,
    "out:Total Energy Use Post PV": 155.73,
    "out:Primary Energy": 107.67,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 23.33,
    "out:CO2 Operational/m2": 23.3,
    "out:Total CO2/m2": 46.64,
    "out:Total CO2 (tons)": 239.85,
    "out:Klimatpaverkan": -89.39,
    "out:Initial Cost/MSEK": 5.15875,
    "out:Running cost/(Apt SEK y)": 47961,
    "out:Running Cost over RSP/MSEK": 15.769,
    "out:LCP/MSEK": -1.659,
    "out:ROI% old": 9.69,
    "out:Payback discounted": 12,
    "out:Atemp": 5143,
    "out:Umean": 0.42,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "F",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 156,
    "out:Energy use kWh/m2": 156,
    "out:Energy savings %": 20.5,
    "out:Op energy cost/MSEK": 116,
    "out:El price/MSEK": 68,
    "out:Heating price/MSEK": 42,
    "out:DH+ DHW/kWh/m2": 145,
    "out:El bought/kWh/m2": 758.1,
    "out:Return %": 9,
    "out:Return/kSEK/y": 450,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 61.265983,
    "out:PV (m2 panels)": 321,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 57484.06,
    "out:PV (kWh sold)": 30886.05,
    "out:PV (% sold (El))": 53.73,
    "out:PV (kWh self-consumed)": 26598,
    "out:PV (ratio sold/self-consumed)": 1.16,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 138861,
    "out:EL kWh savings": 26598,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 210960,
    "out:DH kr savings": 126363,
    "out:El kr savings": 45217,
    "out:El kr sold": 39380,
    "out:El kr saved": 45217,
    "out:El kr return": 84596,
    "out:% solar/total": 33,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 520638,
    "out:% savings (space heating)": 20.95,
    "out:Total_Water Heating (DH)": 223720,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 101.23,
    "out:Etvv": 25,
    "out:Ef": 10.73,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z01000100010000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 96.9,
    "out:DHW (DH)": 43.5,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 10.73,
    "out:Total Energy Use Pre PV": 155.9,
    "out:Total Energy Use Post PV": 150.73,
    "out:Primary Energy": 104.64,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 23.33,
    "out:CO2 Operational/m2": 21.5,
    "out:Total CO2/m2": 44.84,
    "out:Total CO2 (tons)": 230.59,
    "out:Klimatpaverkan": -98.65,
    "out:Initial Cost/MSEK": 5.287375,
    "out:Running cost/(Apt SEK y)": 46352,
    "out:Running Cost over RSP/MSEK": 15.24,
    "out:LCP/MSEK": -1.259,
    "out:ROI% old": 10.89,
    "out:Payback discounted": 11,
    "out:Atemp": 5143,
    "out:Umean": 0.42,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "F",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 151,
    "out:Energy use kWh/m2": 151,
    "out:Energy savings %": 24.5,
    "out:Op energy cost/MSEK": 113,
    "out:El price/MSEK": 66,
    "out:Heating price/MSEK": 40,
    "out:DH+ DHW/kWh/m2": 140,
    "out:El bought/kWh/m2": 758.1,
    "out:Return %": 10,
    "out:Return/kSEK/y": 517,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 61.265983,
    "out:PV (m2 panels)": 321,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 57484.06,
    "out:PV (kWh sold)": 30886.05,
    "out:PV (% sold (El))": 53.73,
    "out:PV (kWh self-consumed)": 26598,
    "out:PV (ratio sold/self-consumed)": 1.16,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 164576,
    "out:EL kWh savings": 26598,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 128574.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 234360,
    "out:DH kr savings": 149764,
    "out:El kr savings": 45217,
    "out:El kr sold": 39380,
    "out:El kr saved": 45217,
    "out:El kr return": 84596,
    "out:% solar/total": 33,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 498375,
    "out:% savings (space heating)": 24.33,
    "out:Total_Water Heating (DH)": 223720,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 96.9,
    "out:Etvv": 25,
    "out:Ef": 10.73,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z01000100010001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 101.23,
    "out:DHW (DH)": 36.98,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 10.73,
    "out:Total Energy Use Pre PV": 153.9,
    "out:Total Energy Use Post PV": 148.73,
    "out:Primary Energy": 105.05,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 23.74,
    "out:CO2 Operational/m2": 20.78,
    "out:Total CO2/m2": 44.52,
    "out:Total CO2 (tons)": 228.95,
    "out:Klimatpaverkan": -100.29,
    "out:Initial Cost/MSEK": 5.40925,
    "out:Running cost/(Apt SEK y)": 45708,
    "out:Running Cost over RSP/MSEK": 15.029,
    "out:LCP/MSEK": -1.169,
    "out:ROI% old": 11.2,
    "out:Payback discounted": 11,
    "out:Atemp": 5143,
    "out:Umean": 0.42,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "F",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 149,
    "out:Energy use kWh/m2": 149,
    "out:Energy savings %": 26.2,
    "out:Op energy cost/MSEK": 111,
    "out:El price/MSEK": 65,
    "out:Heating price/MSEK": 40,
    "out:DH+ DHW/kWh/m2": 138,
    "out:El bought/kWh/m2": 758.1,
    "out:Return %": 10,
    "out:Return/kSEK/y": 544,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 61.265983,
    "out:PV (m2 panels)": 321,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 57484.06,
    "out:PV (kWh sold)": 30886.05,
    "out:PV (% sold (El))": 53.73,
    "out:PV (kWh self-consumed)": 26598,
    "out:PV (ratio sold/self-consumed)": 1.16,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 174862,
    "out:EL kWh savings": 26598,
    "out:Sewage heat recovery cost": 250448,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 243721,
    "out:DH kr savings": 159124,
    "out:El kr savings": 45217,
    "out:El kr sold": 39380,
    "out:El kr saved": 45217,
    "out:El kr return": 84596,
    "out:% solar/total": 33,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 520638,
    "out:% savings (space heating)": 20.95,
    "out:Total_Water Heating (DH)": 190162,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 101.23,
    "out:Etvv": 21.25,
    "out:Ef": 10.73,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z01000100010001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 96.9,
    "out:DHW (DH)": 36.98,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 10.73,
    "out:Total Energy Use Pre PV": 149.9,
    "out:Total Energy Use Post PV": 144.73,
    "out:Primary Energy": 102.02,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 23.74,
    "out:CO2 Operational/m2": 19.34,
    "out:Total CO2/m2": 43.08,
    "out:Total CO2 (tons)": 221.55,
    "out:Klimatpaverkan": -107.69,
    "out:Initial Cost/MSEK": 5.537875,
    "out:Running cost/(Apt SEK y)": 44421,
    "out:Running Cost over RSP/MSEK": 14.606,
    "out:LCP/MSEK": -0.874,
    "out:ROI% old": 12.03,
    "out:Payback discounted": 10,
    "out:Atemp": 5143,
    "out:Umean": 0.42,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "F",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 145,
    "out:Energy use kWh/m2": 145,
    "out:Energy savings %": 29.7,
    "out:Op energy cost/MSEK": 108,
    "out:El price/MSEK": 63,
    "out:Heating price/MSEK": 38,
    "out:DH+ DHW/kWh/m2": 134,
    "out:El bought/kWh/m2": 758.1,
    "out:Return %": 11,
    "out:Return/kSEK/y": 598,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 61.265983,
    "out:PV (m2 panels)": 321,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 57484.06,
    "out:PV (kWh sold)": 30886.05,
    "out:PV (% sold (El))": 53.73,
    "out:PV (kWh self-consumed)": 26598,
    "out:PV (ratio sold/self-consumed)": 1.16,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 195434,
    "out:EL kWh savings": 26598,
    "out:Sewage heat recovery cost": 250448,
    "out:Balancing hydronic system cost": 128574.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 262441,
    "out:DH kr savings": 177845,
    "out:El kr savings": 45217,
    "out:El kr sold": 39380,
    "out:El kr saved": 45217,
    "out:El kr return": 84596,
    "out:% solar/total": 33,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 498375,
    "out:% savings (space heating)": 24.33,
    "out:Total_Water Heating (DH)": 190162,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 96.9,
    "out:Etvv": 21.25,
    "out:Ef": 10.73,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z01000100020000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 101.23,
    "out:DHW (DH)": 43.5,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 9.88,
    "out:Total Energy Use Pre PV": 160.9,
    "out:Total Energy Use Post PV": 154.88,
    "out:Primary Energy": 106.15,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 35.69,
    "out:CO2 Operational/m2": -29.38,
    "out:Total CO2/m2": 6.31,
    "out:Total CO2 (tons)": 32.45,
    "out:Klimatpaverkan": -296.79,
    "out:Initial Cost/MSEK": 6.228625,
    "out:Running cost/(Apt SEK y)": 46172,
    "out:Running Cost over RSP/MSEK": 15.198,
    "out:LCP/MSEK": -2.157,
    "out:ROI% old": 9.34,
    "out:Payback discounted": 13,
    "out:Atemp": 5143,
    "out:Umean": 0.42,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "F",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 155,
    "out:Energy use kWh/m2": 155,
    "out:Energy savings %": 21.3,
    "out:Op energy cost/MSEK": 113,
    "out:El price/MSEK": 64,
    "out:Heating price/MSEK": 42,
    "out:DH+ DHW/kWh/m2": 145,
    "out:El bought/kWh/m2": 696.7,
    "out:Return %": 8,
    "out:Return/kSEK/y": 525,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.33,
    "out:PV (kWp installed)": 122.531965,
    "out:PV (m2 panels)": 643,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 114968.11,
    "out:PV (kWh sold)": 84004.99,
    "out:PV (% sold (El))": 73.07,
    "out:PV (kWh self-consumed)": 30963.12,
    "out:PV (ratio sold/self-consumed)": 2.71,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 138861,
    "out:EL kWh savings": 30963,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 286107,
    "out:DH kr savings": 126363,
    "out:El kr savings": 52637,
    "out:El kr sold": 107106,
    "out:El kr saved": 52637,
    "out:El kr return": 159744,
    "out:% solar/total": 38,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 520638,
    "out:% savings (space heating)": 20.95,
    "out:Total_Water Heating (DH)": 223720,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 101.23,
    "out:Etvv": 25,
    "out:Ef": 9.88,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z01000100020000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 96.9,
    "out:DHW (DH)": 43.5,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 9.88,
    "out:Total Energy Use Pre PV": 155.9,
    "out:Total Energy Use Post PV": 149.88,
    "out:Primary Energy": 103.12,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 35.69,
    "out:CO2 Operational/m2": -31.18,
    "out:Total CO2/m2": 4.51,
    "out:Total CO2 (tons)": 23.19,
    "out:Klimatpaverkan": -306.05,
    "out:Initial Cost/MSEK": 6.357125,
    "out:Running cost/(Apt SEK y)": 44563,
    "out:Running Cost over RSP/MSEK": 14.669,
    "out:LCP/MSEK": -1.757,
    "out:ROI% old": 10.34,
    "out:Payback discounted": 12,
    "out:Atemp": 5143,
    "out:Umean": 0.42,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "F",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 150,
    "out:Energy use kWh/m2": 150,
    "out:Energy savings %": 25.3,
    "out:Op energy cost/MSEK": 109,
    "out:El price/MSEK": 62,
    "out:Heating price/MSEK": 40,
    "out:DH+ DHW/kWh/m2": 140,
    "out:El bought/kWh/m2": 696.7,
    "out:Return %": 9,
    "out:Return/kSEK/y": 592,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.33,
    "out:PV (kWp installed)": 122.531965,
    "out:PV (m2 panels)": 643,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 114968.11,
    "out:PV (kWh sold)": 84004.99,
    "out:PV (% sold (El))": 73.07,
    "out:PV (kWh self-consumed)": 30963.12,
    "out:PV (ratio sold/self-consumed)": 2.71,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 164576,
    "out:EL kWh savings": 30963,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 128574.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 309508,
    "out:DH kr savings": 149764,
    "out:El kr savings": 52637,
    "out:El kr sold": 107106,
    "out:El kr saved": 52637,
    "out:El kr return": 159744,
    "out:% solar/total": 38,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 498375,
    "out:% savings (space heating)": 24.33,
    "out:Total_Water Heating (DH)": 223720,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 96.9,
    "out:Etvv": 25,
    "out:Ef": 9.88,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z01000100020001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 101.23,
    "out:DHW (DH)": 36.98,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 9.88,
    "out:Total Energy Use Pre PV": 153.9,
    "out:Total Energy Use Post PV": 147.88,
    "out:Primary Energy": 103.52,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 36.09,
    "out:CO2 Operational/m2": -31.9,
    "out:Total CO2/m2": 4.19,
    "out:Total CO2 (tons)": 21.55,
    "out:Klimatpaverkan": -307.69,
    "out:Initial Cost/MSEK": 6.479,
    "out:Running cost/(Apt SEK y)": 43919,
    "out:Running Cost over RSP/MSEK": 14.457,
    "out:LCP/MSEK": -1.667,
    "out:ROI% old": 10.61,
    "out:Payback discounted": 11,
    "out:Atemp": 5143,
    "out:Umean": 0.42,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "F",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 148,
    "out:Energy use kWh/m2": 148,
    "out:Energy savings %": 27,
    "out:Op energy cost/MSEK": 107,
    "out:El price/MSEK": 61,
    "out:Heating price/MSEK": 40,
    "out:DH+ DHW/kWh/m2": 138,
    "out:El bought/kWh/m2": 696.7,
    "out:Return %": 10,
    "out:Return/kSEK/y": 619,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.33,
    "out:PV (kWp installed)": 122.531965,
    "out:PV (m2 panels)": 643,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 114968.11,
    "out:PV (kWh sold)": 84004.99,
    "out:PV (% sold (El))": 73.07,
    "out:PV (kWh self-consumed)": 30963.12,
    "out:PV (ratio sold/self-consumed)": 2.71,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 174862,
    "out:EL kWh savings": 30963,
    "out:Sewage heat recovery cost": 250448,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 318868,
    "out:DH kr savings": 159124,
    "out:El kr savings": 52637,
    "out:El kr sold": 107106,
    "out:El kr saved": 52637,
    "out:El kr return": 159744,
    "out:% solar/total": 38,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 520638,
    "out:% savings (space heating)": 20.95,
    "out:Total_Water Heating (DH)": 190162,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 101.23,
    "out:Etvv": 21.25,
    "out:Ef": 9.88,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z01000100020001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 96.9,
    "out:DHW (DH)": 36.98,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 9.88,
    "out:Total Energy Use Pre PV": 149.9,
    "out:Total Energy Use Post PV": 143.88,
    "out:Primary Energy": 100.49,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 36.09,
    "out:CO2 Operational/m2": -33.34,
    "out:Total CO2/m2": 2.75,
    "out:Total CO2 (tons)": 14.15,
    "out:Klimatpaverkan": -315.09,
    "out:Initial Cost/MSEK": 6.607625,
    "out:Running cost/(Apt SEK y)": 42632,
    "out:Running Cost over RSP/MSEK": 14.034,
    "out:LCP/MSEK": -1.373,
    "out:ROI% old": 11.32,
    "out:Payback discounted": 11,
    "out:Atemp": 5143,
    "out:Umean": 0.42,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "F",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 144,
    "out:Energy use kWh/m2": 144,
    "out:Energy savings %": 30.6,
    "out:Op energy cost/MSEK": 104,
    "out:El price/MSEK": 60,
    "out:Heating price/MSEK": 38,
    "out:DH+ DHW/kWh/m2": 134,
    "out:El bought/kWh/m2": 696.7,
    "out:Return %": 10,
    "out:Return/kSEK/y": 673,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.33,
    "out:PV (kWp installed)": 122.531965,
    "out:PV (m2 panels)": 643,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 114968.11,
    "out:PV (kWh sold)": 84004.99,
    "out:PV (% sold (El))": 73.07,
    "out:PV (kWh self-consumed)": 30963.12,
    "out:PV (ratio sold/self-consumed)": 2.71,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 195434,
    "out:EL kWh savings": 30963,
    "out:Sewage heat recovery cost": 250448,
    "out:Balancing hydronic system cost": 128574.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 337589,
    "out:DH kr savings": 177845,
    "out:El kr savings": 52637,
    "out:El kr sold": 107106,
    "out:El kr saved": 52637,
    "out:El kr return": 159744,
    "out:% solar/total": 38,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 498375,
    "out:% savings (space heating)": 24.33,
    "out:Total_Water Heating (DH)": 190162,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 96.9,
    "out:Etvv": 21.25,
    "out:Ef": 9.88,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z01000100100000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 101.23,
    "out:DHW (DH)": 34.8,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 15.9,
    "out:Total Energy Use Pre PV": 151.9,
    "out:Total Energy Use Post PV": 151.9,
    "out:Primary Energy": 113.48,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 10.98,
    "out:CO2 Operational/m2": 51.06,
    "out:Total CO2/m2": 62.04,
    "out:Total CO2 (tons)": 319.06,
    "out:Klimatpaverkan": -10.18,
    "out:Initial Cost/MSEK": 4.199875,
    "out:Running cost/(Apt SEK y)": 47079,
    "out:Running Cost over RSP/MSEK": 15.461,
    "out:LCP/MSEK": -0.392,
    "out:ROI% old": 12.95,
    "out:Payback discounted": 9,
    "out:Atemp": 5143,
    "out:Umean": 0.42,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "F",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 152,
    "out:Energy use kWh/m2": 152,
    "out:Energy savings %": 23.7,
    "out:Op energy cost/MSEK": 113,
    "out:El price/MSEK": 68,
    "out:Heating price/MSEK": 39,
    "out:DH+ DHW/kWh/m2": 136,
    "out:El bought/kWh/m2": 1113,
    "out:Return %": 12,
    "out:Return/kSEK/y": 487,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 185148,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 168485,
    "out:DH kr savings": 168485,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 520638,
    "out:% savings (space heating)": 20.95,
    "out:Total_Water Heating (DH)": 178976,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 101.23,
    "out:Etvv": 20,
    "out:Ef": 15.9,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z01000100100000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 96.9,
    "out:DHW (DH)": 34.8,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 15.9,
    "out:Total Energy Use Pre PV": 147.9,
    "out:Total Energy Use Post PV": 147.9,
    "out:Primary Energy": 110.45,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 10.98,
    "out:CO2 Operational/m2": 49.62,
    "out:Total CO2/m2": 60.6,
    "out:Total CO2 (tons)": 311.65,
    "out:Klimatpaverkan": -17.59,
    "out:Initial Cost/MSEK": 4.328375,
    "out:Running cost/(Apt SEK y)": 45791,
    "out:Running Cost over RSP/MSEK": 15.038,
    "out:LCP/MSEK": -0.097,
    "out:ROI% old": 13.97,
    "out:Payback discounted": 9,
    "out:Atemp": 5143,
    "out:Umean": 0.42,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "F",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 148,
    "out:Energy use kWh/m2": 148,
    "out:Energy savings %": 27,
    "out:Op energy cost/MSEK": 110,
    "out:El price/MSEK": 66,
    "out:Heating price/MSEK": 38,
    "out:DH+ DHW/kWh/m2": 132,
    "out:El bought/kWh/m2": 1113,
    "out:Return %": 12,
    "out:Return/kSEK/y": 541,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 205720,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 128574.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 187205,
    "out:DH kr savings": 187205,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 498375,
    "out:% savings (space heating)": 24.33,
    "out:Total_Water Heating (DH)": 178976,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 96.9,
    "out:Etvv": 20,
    "out:Ef": 15.9,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z01000100100001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 101.23,
    "out:DHW (DH)": 29.58,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 15.9,
    "out:Total Energy Use Pre PV": 146.9,
    "out:Total Energy Use Post PV": 146.9,
    "out:Primary Energy": 111.38,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 11.38,
    "out:CO2 Operational/m2": 49.26,
    "out:Total CO2/m2": 60.64,
    "out:Total CO2 (tons)": 311.87,
    "out:Klimatpaverkan": -17.37,
    "out:Initial Cost/MSEK": 4.45025,
    "out:Running cost/(Apt SEK y)": 45470,
    "out:Running Cost over RSP/MSEK": 14.932,
    "out:LCP/MSEK": -0.113,
    "out:ROI% old": 13.92,
    "out:Payback discounted": 9,
    "out:Atemp": 5143,
    "out:Umean": 0.42,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "F",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 147,
    "out:Energy use kWh/m2": 147,
    "out:Energy savings %": 27.9,
    "out:Op energy cost/MSEK": 109,
    "out:El price/MSEK": 66,
    "out:Heating price/MSEK": 38,
    "out:DH+ DHW/kWh/m2": 131,
    "out:El bought/kWh/m2": 1113,
    "out:Return %": 12,
    "out:Return/kSEK/y": 554,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 210863,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 250448,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 191885,
    "out:DH kr savings": 191885,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 520638,
    "out:% savings (space heating)": 20.95,
    "out:Total_Water Heating (DH)": 152130,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 101.23,
    "out:Etvv": 17,
    "out:Ef": 15.9,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z01000100100001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 96.9,
    "out:DHW (DH)": 29.58,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 15.9,
    "out:Total Energy Use Pre PV": 141.9,
    "out:Total Energy Use Post PV": 141.9,
    "out:Primary Energy": 108.35,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 11.38,
    "out:CO2 Operational/m2": 47.46,
    "out:Total CO2/m2": 58.84,
    "out:Total CO2 (tons)": 302.61,
    "out:Klimatpaverkan": -26.63,
    "out:Initial Cost/MSEK": 4.578875,
    "out:Running cost/(Apt SEK y)": 43860,
    "out:Running Cost over RSP/MSEK": 14.403,
    "out:LCP/MSEK": 0.287,
    "out:ROI% old": 15.18,
    "out:Payback discounted": 8,
    "out:Atemp": 5143,
    "out:Umean": 0.42,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "F",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 142,
    "out:Energy use kWh/m2": 142,
    "out:Energy savings %": 32.4,
    "out:Op energy cost/MSEK": 106,
    "out:El price/MSEK": 63,
    "out:Heating price/MSEK": 36,
    "out:DH+ DHW/kWh/m2": 126,
    "out:El bought/kWh/m2": 1113,
    "out:Return %": 14,
    "out:Return/kSEK/y": 622,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 236578,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 250448,
    "out:Balancing hydronic system cost": 128574.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 215286,
    "out:DH kr savings": 215286,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 498375,
    "out:% savings (space heating)": 24.33,
    "out:Total_Water Heating (DH)": 152130,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 96.9,
    "out:Etvv": 17,
    "out:Ef": 15.9,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z01000100110000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 101.23,
    "out:DHW (DH)": 34.8,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 10.73,
    "out:Total Energy Use Pre PV": 151.9,
    "out:Total Energy Use Post PV": 146.73,
    "out:Primary Energy": 104.17,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 23.33,
    "out:CO2 Operational/m2": 20.06,
    "out:Total CO2/m2": 43.4,
    "out:Total CO2 (tons)": 223.18,
    "out:Klimatpaverkan": -106.06,
    "out:Initial Cost/MSEK": 5.269625,
    "out:Running cost/(Apt SEK y)": 45064,
    "out:Running Cost over RSP/MSEK": 14.817,
    "out:LCP/MSEK": -0.818,
    "out:ROI% old": 12.07,
    "out:Payback discounted": 10,
    "out:Atemp": 5143,
    "out:Umean": 0.42,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "F",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 147,
    "out:Energy use kWh/m2": 147,
    "out:Energy savings %": 27.9,
    "out:Op energy cost/MSEK": 109,
    "out:El price/MSEK": 64,
    "out:Heating price/MSEK": 39,
    "out:DH+ DHW/kWh/m2": 136,
    "out:El bought/kWh/m2": 758.1,
    "out:Return %": 11,
    "out:Return/kSEK/y": 571,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 61.265983,
    "out:PV (m2 panels)": 321,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 57484.06,
    "out:PV (kWh sold)": 30886.05,
    "out:PV (% sold (El))": 53.73,
    "out:PV (kWh self-consumed)": 26598,
    "out:PV (ratio sold/self-consumed)": 1.16,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 185148,
    "out:EL kWh savings": 26598,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 253081,
    "out:DH kr savings": 168485,
    "out:El kr savings": 45217,
    "out:El kr sold": 39380,
    "out:El kr saved": 45217,
    "out:El kr return": 84596,
    "out:% solar/total": 33,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 520638,
    "out:% savings (space heating)": 20.95,
    "out:Total_Water Heating (DH)": 178976,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 101.23,
    "out:Etvv": 20,
    "out:Ef": 10.73,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z01000100110000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 96.9,
    "out:DHW (DH)": 34.8,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 10.73,
    "out:Total Energy Use Pre PV": 147.9,
    "out:Total Energy Use Post PV": 142.73,
    "out:Primary Energy": 101.14,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 23.33,
    "out:CO2 Operational/m2": 18.62,
    "out:Total CO2/m2": 41.96,
    "out:Total CO2 (tons)": 215.78,
    "out:Klimatpaverkan": -113.46,
    "out:Initial Cost/MSEK": 5.39825,
    "out:Running cost/(Apt SEK y)": 43777,
    "out:Running Cost over RSP/MSEK": 14.394,
    "out:LCP/MSEK": -0.523,
    "out:ROI% old": 12.9,
    "out:Payback discounted": 9,
    "out:Atemp": 5143,
    "out:Umean": 0.42,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "F",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 143,
    "out:Energy use kWh/m2": 143,
    "out:Energy savings %": 31.5,
    "out:Op energy cost/MSEK": 106,
    "out:El price/MSEK": 62,
    "out:Heating price/MSEK": 38,
    "out:DH+ DHW/kWh/m2": 132,
    "out:El bought/kWh/m2": 758.1,
    "out:Return %": 12,
    "out:Return/kSEK/y": 625,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 61.265983,
    "out:PV (m2 panels)": 321,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 57484.06,
    "out:PV (kWh sold)": 30886.05,
    "out:PV (% sold (El))": 53.73,
    "out:PV (kWh self-consumed)": 26598,
    "out:PV (ratio sold/self-consumed)": 1.16,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 205720,
    "out:EL kWh savings": 26598,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 128574.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 271802,
    "out:DH kr savings": 187205,
    "out:El kr savings": 45217,
    "out:El kr sold": 39380,
    "out:El kr saved": 45217,
    "out:El kr return": 84596,
    "out:% solar/total": 33,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 498375,
    "out:% savings (space heating)": 24.33,
    "out:Total_Water Heating (DH)": 178976,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 96.9,
    "out:Etvv": 20,
    "out:Ef": 10.73,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z01000100110001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 101.23,
    "out:DHW (DH)": 29.58,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 10.73,
    "out:Total Energy Use Pre PV": 146.9,
    "out:Total Energy Use Post PV": 141.73,
    "out:Primary Energy": 102.07,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 23.74,
    "out:CO2 Operational/m2": 18.26,
    "out:Total CO2/m2": 42,
    "out:Total CO2 (tons)": 215.99,
    "out:Klimatpaverkan": -113.25,
    "out:Initial Cost/MSEK": 5.520125,
    "out:Running cost/(Apt SEK y)": 43455,
    "out:Running Cost over RSP/MSEK": 14.288,
    "out:LCP/MSEK": -0.539,
    "out:ROI% old": 12.89,
    "out:Payback discounted": 9,
    "out:Atemp": 5143,
    "out:Umean": 0.42,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "F",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 142,
    "out:Energy use kWh/m2": 142,
    "out:Energy savings %": 32.4,
    "out:Op energy cost/MSEK": 105,
    "out:El price/MSEK": 62,
    "out:Heating price/MSEK": 38,
    "out:DH+ DHW/kWh/m2": 131,
    "out:El bought/kWh/m2": 758.1,
    "out:Return %": 12,
    "out:Return/kSEK/y": 639,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 61.265983,
    "out:PV (m2 panels)": 321,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 57484.06,
    "out:PV (kWh sold)": 30886.05,
    "out:PV (% sold (El))": 53.73,
    "out:PV (kWh self-consumed)": 26598,
    "out:PV (ratio sold/self-consumed)": 1.16,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 210863,
    "out:EL kWh savings": 26598,
    "out:Sewage heat recovery cost": 250448,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 276482,
    "out:DH kr savings": 191885,
    "out:El kr savings": 45217,
    "out:El kr sold": 39380,
    "out:El kr saved": 45217,
    "out:El kr return": 84596,
    "out:% solar/total": 33,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 520638,
    "out:% savings (space heating)": 20.95,
    "out:Total_Water Heating (DH)": 152130,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 101.23,
    "out:Etvv": 17,
    "out:Ef": 10.73,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z01000100110001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 96.9,
    "out:DHW (DH)": 29.58,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 10.73,
    "out:Total Energy Use Pre PV": 141.9,
    "out:Total Energy Use Post PV": 136.73,
    "out:Primary Energy": 99.04,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 23.74,
    "out:CO2 Operational/m2": 16.46,
    "out:Total CO2/m2": 40.2,
    "out:Total CO2 (tons)": 206.74,
    "out:Klimatpaverkan": -122.5,
    "out:Initial Cost/MSEK": 5.648625,
    "out:Running cost/(Apt SEK y)": 41846,
    "out:Running Cost over RSP/MSEK": 13.759,
    "out:LCP/MSEK": -0.139,
    "out:ROI% old": 13.93,
    "out:Payback discounted": 9,
    "out:Atemp": 5143,
    "out:Umean": 0.42,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "F",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 137,
    "out:Energy use kWh/m2": 137,
    "out:Energy savings %": 37.2,
    "out:Op energy cost/MSEK": 102,
    "out:El price/MSEK": 59,
    "out:Heating price/MSEK": 36,
    "out:DH+ DHW/kWh/m2": 126,
    "out:El bought/kWh/m2": 758.1,
    "out:Return %": 13,
    "out:Return/kSEK/y": 706,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 61.265983,
    "out:PV (m2 panels)": 321,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 57484.06,
    "out:PV (kWh sold)": 30886.05,
    "out:PV (% sold (El))": 53.73,
    "out:PV (kWh self-consumed)": 26598,
    "out:PV (ratio sold/self-consumed)": 1.16,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 236578,
    "out:EL kWh savings": 26598,
    "out:Sewage heat recovery cost": 250448,
    "out:Balancing hydronic system cost": 128574.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 299882,
    "out:DH kr savings": 215286,
    "out:El kr savings": 45217,
    "out:El kr sold": 39380,
    "out:El kr saved": 45217,
    "out:El kr return": 84596,
    "out:% solar/total": 33,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 498375,
    "out:% savings (space heating)": 24.33,
    "out:Total_Water Heating (DH)": 152130,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 96.9,
    "out:Etvv": 17,
    "out:Ef": 10.73,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z01000100120000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 101.23,
    "out:DHW (DH)": 34.8,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 9.88,
    "out:Total Energy Use Pre PV": 151.9,
    "out:Total Energy Use Post PV": 145.88,
    "out:Primary Energy": 102.65,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 35.69,
    "out:CO2 Operational/m2": -32.62,
    "out:Total CO2/m2": 3.07,
    "out:Total CO2 (tons)": 15.78,
    "out:Klimatpaverkan": -313.46,
    "out:Initial Cost/MSEK": 6.339375,
    "out:Running cost/(Apt SEK y)": 43275,
    "out:Running Cost over RSP/MSEK": 14.246,
    "out:LCP/MSEK": -1.316,
    "out:ROI% old": 11.32,
    "out:Payback discounted": 11,
    "out:Atemp": 5143,
    "out:Umean": 0.42,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "F",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 146,
    "out:Energy use kWh/m2": 146,
    "out:Energy savings %": 28.8,
    "out:Op energy cost/MSEK": 106,
    "out:El price/MSEK": 60,
    "out:Heating price/MSEK": 39,
    "out:DH+ DHW/kWh/m2": 136,
    "out:El bought/kWh/m2": 696.7,
    "out:Return %": 10,
    "out:Return/kSEK/y": 646,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.33,
    "out:PV (kWp installed)": 122.531965,
    "out:PV (m2 panels)": 643,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 114968.11,
    "out:PV (kWh sold)": 84004.99,
    "out:PV (% sold (El))": 73.07,
    "out:PV (kWh self-consumed)": 30963.12,
    "out:PV (ratio sold/self-consumed)": 2.71,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 185148,
    "out:EL kWh savings": 30963,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 328228,
    "out:DH kr savings": 168485,
    "out:El kr savings": 52637,
    "out:El kr sold": 107106,
    "out:El kr saved": 52637,
    "out:El kr return": 159744,
    "out:% solar/total": 38,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 520638,
    "out:% savings (space heating)": 20.95,
    "out:Total_Water Heating (DH)": 178976,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 101.23,
    "out:Etvv": 20,
    "out:Ef": 9.88,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z01000100120000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 96.9,
    "out:DHW (DH)": 34.8,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 9.88,
    "out:Total Energy Use Pre PV": 147.9,
    "out:Total Energy Use Post PV": 141.88,
    "out:Primary Energy": 99.62,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 35.69,
    "out:CO2 Operational/m2": -34.06,
    "out:Total CO2/m2": 1.63,
    "out:Total CO2 (tons)": 8.38,
    "out:Klimatpaverkan": -320.86,
    "out:Initial Cost/MSEK": 6.468,
    "out:Running cost/(Apt SEK y)": 41988,
    "out:Running Cost over RSP/MSEK": 13.823,
    "out:LCP/MSEK": -1.022,
    "out:ROI% old": 12.03,
    "out:Payback discounted": 10,
    "out:Atemp": 5143,
    "out:Umean": 0.42,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "F",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 142,
    "out:Energy use kWh/m2": 142,
    "out:Energy savings %": 32.4,
    "out:Op energy cost/MSEK": 103,
    "out:El price/MSEK": 59,
    "out:Heating price/MSEK": 38,
    "out:DH+ DHW/kWh/m2": 132,
    "out:El bought/kWh/m2": 696.7,
    "out:Return %": 11,
    "out:Return/kSEK/y": 700,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.33,
    "out:PV (kWp installed)": 122.531965,
    "out:PV (m2 panels)": 643,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 114968.11,
    "out:PV (kWh sold)": 84004.99,
    "out:PV (% sold (El))": 73.07,
    "out:PV (kWh self-consumed)": 30963.12,
    "out:PV (ratio sold/self-consumed)": 2.71,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 205720,
    "out:EL kWh savings": 30963,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 128574.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 346949,
    "out:DH kr savings": 187205,
    "out:El kr savings": 52637,
    "out:El kr sold": 107106,
    "out:El kr saved": 52637,
    "out:El kr return": 159744,
    "out:% solar/total": 38,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 498375,
    "out:% savings (space heating)": 24.33,
    "out:Total_Water Heating (DH)": 178976,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 96.9,
    "out:Etvv": 20,
    "out:Ef": 9.88,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z01000100120001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 101.23,
    "out:DHW (DH)": 29.58,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 9.88,
    "out:Total Energy Use Pre PV": 146.9,
    "out:Total Energy Use Post PV": 140.88,
    "out:Primary Energy": 100.55,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 36.09,
    "out:CO2 Operational/m2": -34.42,
    "out:Total CO2/m2": 1.67,
    "out:Total CO2 (tons)": 8.59,
    "out:Klimatpaverkan": -320.65,
    "out:Initial Cost/MSEK": 6.589875,
    "out:Running cost/(Apt SEK y)": 41666,
    "out:Running Cost over RSP/MSEK": 13.717,
    "out:LCP/MSEK": -1.038,
    "out:ROI% old": 12.04,
    "out:Payback discounted": 10,
    "out:Atemp": 5143,
    "out:Umean": 0.42,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "F",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 141,
    "out:Energy use kWh/m2": 141,
    "out:Energy savings %": 33.3,
    "out:Op energy cost/MSEK": 102,
    "out:El price/MSEK": 58,
    "out:Heating price/MSEK": 38,
    "out:DH+ DHW/kWh/m2": 131,
    "out:El bought/kWh/m2": 696.7,
    "out:Return %": 11,
    "out:Return/kSEK/y": 714,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.33,
    "out:PV (kWp installed)": 122.531965,
    "out:PV (m2 panels)": 643,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 114968.11,
    "out:PV (kWh sold)": 84004.99,
    "out:PV (% sold (El))": 73.07,
    "out:PV (kWh self-consumed)": 30963.12,
    "out:PV (ratio sold/self-consumed)": 2.71,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 210863,
    "out:EL kWh savings": 30963,
    "out:Sewage heat recovery cost": 250448,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 351629,
    "out:DH kr savings": 191885,
    "out:El kr savings": 52637,
    "out:El kr sold": 107106,
    "out:El kr saved": 52637,
    "out:El kr return": 159744,
    "out:% solar/total": 38,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 520638,
    "out:% savings (space heating)": 20.95,
    "out:Total_Water Heating (DH)": 152130,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 101.23,
    "out:Etvv": 17,
    "out:Ef": 9.88,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z01000100120001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 96.9,
    "out:DHW (DH)": 29.58,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 9.88,
    "out:Total Energy Use Pre PV": 141.9,
    "out:Total Energy Use Post PV": 135.88,
    "out:Primary Energy": 97.52,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 36.09,
    "out:CO2 Operational/m2": -36.22,
    "out:Total CO2/m2": -0.13,
    "out:Total CO2 (tons)": -0.66,
    "out:Klimatpaverkan": -329.9,
    "out:Initial Cost/MSEK": 6.7185,
    "out:Running cost/(Apt SEK y)": 40057,
    "out:Running Cost over RSP/MSEK": 13.188,
    "out:LCP/MSEK": -0.637,
    "out:ROI% old": 12.93,
    "out:Payback discounted": 9,
    "out:Atemp": 5143,
    "out:Umean": 0.42,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "F",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 136,
    "out:Energy use kWh/m2": 136,
    "out:Energy savings %": 38.2,
    "out:Op energy cost/MSEK": 98,
    "out:El price/MSEK": 56,
    "out:Heating price/MSEK": 36,
    "out:DH+ DHW/kWh/m2": 126,
    "out:El bought/kWh/m2": 696.7,
    "out:Return %": 12,
    "out:Return/kSEK/y": 782,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.33,
    "out:PV (kWp installed)": 122.531965,
    "out:PV (m2 panels)": 643,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 114968.11,
    "out:PV (kWh sold)": 84004.99,
    "out:PV (% sold (El))": 73.07,
    "out:PV (kWh self-consumed)": 30963.12,
    "out:PV (ratio sold/self-consumed)": 2.71,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 236578,
    "out:EL kWh savings": 30963,
    "out:Sewage heat recovery cost": 250448,
    "out:Balancing hydronic system cost": 128574.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 375030,
    "out:DH kr savings": 215286,
    "out:El kr savings": 52637,
    "out:El kr sold": 107106,
    "out:El kr saved": 52637,
    "out:El kr return": 159744,
    "out:% solar/total": 38,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 498375,
    "out:% savings (space heating)": 24.33,
    "out:Total_Water Heating (DH)": 152130,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 96.9,
    "out:Etvv": 17,
    "out:Ef": 9.88,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z01000101000000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 56.06,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 45.07,
    "out:Electricity (inc PV)": 42.83,
    "out:Total Energy Use Pre PV": 101.07,
    "out:Total Energy Use Post PV": 98.83,
    "out:Primary Energy": 108.56,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 13.83,
    "out:CO2 Operational/m2": 26.11,
    "out:Total CO2/m2": 39.94,
    "out:Total CO2 (tons)": 205.41,
    "out:Klimatpaverkan": -123.83,
    "out:Initial Cost/MSEK": 8.564875,
    "out:Running cost/(Apt SEK y)": 27405,
    "out:Running Cost over RSP/MSEK": 8.97,
    "out:LCP/MSEK": 1.734,
    "out:ROI% old": 17.18,
    "out:Payback discounted": 7,
    "out:Atemp": 5143,
    "out:Umean": 0.42,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 99,
    "out:Energy use kWh/m2": 99,
    "out:Energy savings %": 89.9,
    "out:Op energy cost/MSEK": 64,
    "out:El price/MSEK": 45,
    "out:Heating price/MSEK": 16,
    "out:DH+ DHW/kWh/m2": 56,
    "out:El bought/kWh/m2": 3154.9,
    "out:Return %": 15,
    "out:Return/kSEK/y": 1313,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 1479000,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 73950,
    "out:EAHP (replacement cost)": 1105400,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 26.87,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 596588,
    "out:EL kWh savings": -138515,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 307419,
    "out:DH kr savings": 542895,
    "out:El kr savings": -235476,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 288318,
    "out:% savings (space heating)": 56.22,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 44.94,
    "out:Etvv": 0,
    "out:Ef": 42.83,
    "out:heatPumpQuantity": 10,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000101000000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 52.01,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 44.98,
    "out:Electricity (inc PV)": 42.7,
    "out:Total Energy Use Pre PV": 96.98,
    "out:Total Energy Use Post PV": 94.7,
    "out:Primary Energy": 105.6,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 13.83,
    "out:CO2 Operational/m2": 24.66,
    "out:Total CO2/m2": 38.49,
    "out:Total CO2 (tons)": 197.95,
    "out:Klimatpaverkan": -131.29,
    "out:Initial Cost/MSEK": 8.6935,
    "out:Running cost/(Apt SEK y)": 26099,
    "out:Running Cost over RSP/MSEK": 8.541,
    "out:LCP/MSEK": 2.034,
    "out:ROI% old": 17.63,
    "out:Payback discounted": 7,
    "out:Atemp": 5143,
    "out:Umean": 0.42,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 95,
    "out:Energy use kWh/m2": 95,
    "out:Energy savings %": 97.9,
    "out:Op energy cost/MSEK": 61,
    "out:El price/MSEK": 43,
    "out:Heating price/MSEK": 15,
    "out:DH+ DHW/kWh/m2": 52,
    "out:El bought/kWh/m2": 3148.9,
    "out:Return %": 16,
    "out:Return/kSEK/y": 1368,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 1479000,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 73950,
    "out:EAHP (replacement cost)": 1105400,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 26.78,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 617160,
    "out:EL kWh savings": -137825,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 128574.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 327313,
    "out:DH kr savings": 561616,
    "out:El kr savings": -234303,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 267511,
    "out:% savings (space heating)": 59.38,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 41.06,
    "out:Etvv": 0,
    "out:Ef": 42.7,
    "out:heatPumpQuantity": 10,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000101000001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 52.02,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 44.32,
    "out:Electricity (inc PV)": 42.34,
    "out:Total Energy Use Pre PV": 96.32,
    "out:Total Energy Use Post PV": 94.34,
    "out:Primary Energy": 106.18,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 14.23,
    "out:CO2 Operational/m2": 24.57,
    "out:Total CO2/m2": 38.8,
    "out:Total CO2 (tons)": 199.56,
    "out:Klimatpaverkan": -129.68,
    "out:Initial Cost/MSEK": 8.815375,
    "out:Running cost/(Apt SEK y)": 25961,
    "out:Running Cost over RSP/MSEK": 8.496,
    "out:LCP/MSEK": 1.958,
    "out:ROI% old": 17.46,
    "out:Payback discounted": 7,
    "out:Atemp": 5143,
    "out:Umean": 0.42,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 94,
    "out:Energy use kWh/m2": 94,
    "out:Energy savings %": 100,
    "out:Op energy cost/MSEK": 60,
    "out:El price/MSEK": 43,
    "out:Heating price/MSEK": 15,
    "out:DH+ DHW/kWh/m2": 52,
    "out:El bought/kWh/m2": 3102.2,
    "out:Return %": 16,
    "out:Return/kSEK/y": 1374,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 1479000,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 73950,
    "out:EAHP (replacement cost)": 1105400,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 26.12,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 617160,
    "out:EL kWh savings": -135997,
    "out:Sewage heat recovery cost": 250448,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 330421,
    "out:DH kr savings": 561616,
    "out:El kr savings": -231194,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 267532,
    "out:% savings (space heating)": 59.38,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 42.81,
    "out:Etvv": 0,
    "out:Ef": 42.34,
    "out:heatPumpQuantity": 10,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000101000001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 48.03,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 44.22,
    "out:Electricity (inc PV)": 42.2,
    "out:Total Energy Use Pre PV": 92.22,
    "out:Total Energy Use Post PV": 90.2,
    "out:Primary Energy": 103.23,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 14.23,
    "out:CO2 Operational/m2": 23.12,
    "out:Total CO2/m2": 37.35,
    "out:Total CO2 (tons)": 192.09,
    "out:Klimatpaverkan": -137.15,
    "out:Initial Cost/MSEK": 8.943875,
    "out:Running cost/(Apt SEK y)": 24652,
    "out:Running Cost over RSP/MSEK": 8.066,
    "out:LCP/MSEK": 2.259,
    "out:ROI% old": 17.89,
    "out:Payback discounted": 7,
    "out:Atemp": 5143,
    "out:Umean": 0.42,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 90,
    "out:Energy use kWh/m2": 90,
    "out:Energy savings %": 108.9,
    "out:Op energy cost/MSEK": 57,
    "out:El price/MSEK": 41,
    "out:Heating price/MSEK": 14,
    "out:DH+ DHW/kWh/m2": 48,
    "out:El bought/kWh/m2": 3095.1,
    "out:Return %": 16,
    "out:Return/kSEK/y": 1429,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 1479000,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 73950,
    "out:EAHP (replacement cost)": 1105400,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 26.02,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 637732,
    "out:EL kWh savings": -135249,
    "out:Sewage heat recovery cost": 250448,
    "out:Balancing hydronic system cost": 128574.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 350413,
    "out:DH kr savings": 580336,
    "out:El kr savings": -229923,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 247001,
    "out:% savings (space heating)": 62.5,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 38.96,
    "out:Etvv": 0,
    "out:Ef": 42.2,
    "out:heatPumpQuantity": 10,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000101010000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 56.06,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 45.07,
    "out:Electricity (inc PV)": 33.35,
    "out:Total Energy Use Pre PV": 101.07,
    "out:Total Energy Use Post PV": 89.35,
    "out:Primary Energy": 91.49,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 26.18,
    "out:CO2 Operational/m2": 17.67,
    "out:Total CO2/m2": 43.85,
    "out:Total CO2 (tons)": 225.54,
    "out:Klimatpaverkan": -103.7,
    "out:Initial Cost/MSEK": 9.63475,
    "out:Running cost/(Apt SEK y)": 25155,
    "out:Running Cost over RSP/MSEK": 8.251,
    "out:LCP/MSEK": 1.383,
    "out:ROI% old": 16.34,
    "out:Payback discounted": 7,
    "out:Atemp": 5143,
    "out:Umean": 0.42,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 89,
    "out:Energy use kWh/m2": 89,
    "out:Energy savings %": 111.2,
    "out:Op energy cost/MSEK": 59,
    "out:El price/MSEK": 41,
    "out:Heating price/MSEK": 16,
    "out:DH+ DHW/kWh/m2": 56,
    "out:El bought/kWh/m2": 2502.3,
    "out:Return %": 15,
    "out:Return/kSEK/y": 1407,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 1479000,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 73950,
    "out:EAHP (replacement cost)": 1105400,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 26.87,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 61.265983,
    "out:PV (m2 panels)": 321,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 57484.06,
    "out:PV (kWh sold)": 7580.23,
    "out:PV (% sold (El))": 13.19,
    "out:PV (kWh self-consumed)": 49903.82,
    "out:PV (ratio sold/self-consumed)": 0.15,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 596588,
    "out:EL kWh savings": -89739,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 400003,
    "out:DH kr savings": 542895,
    "out:El kr savings": -152557,
    "out:El kr sold": 9665,
    "out:El kr saved": 84836,
    "out:El kr return": 94501,
    "out:% solar/total": 61,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 288318,
    "out:% savings (space heating)": 56.22,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 44.94,
    "out:Etvv": 0,
    "out:Ef": 33.35,
    "out:heatPumpQuantity": 10,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000101010000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 52.01,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 44.98,
    "out:Electricity (inc PV)": 33.23,
    "out:Total Energy Use Pre PV": 96.98,
    "out:Total Energy Use Post PV": 85.23,
    "out:Primary Energy": 88.55,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 26.18,
    "out:CO2 Operational/m2": 16.18,
    "out:Total CO2/m2": 42.36,
    "out:Total CO2 (tons)": 217.87,
    "out:Klimatpaverkan": -111.37,
    "out:Initial Cost/MSEK": 9.76325,
    "out:Running cost/(Apt SEK y)": 23850,
    "out:Running Cost over RSP/MSEK": 7.822,
    "out:LCP/MSEK": 1.684,
    "out:ROI% old": 16.75,
    "out:Payback discounted": 7,
    "out:Atemp": 5143,
    "out:Umean": 0.42,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 85,
    "out:Energy use kWh/m2": 85,
    "out:Energy savings %": 121.2,
    "out:Op energy cost/MSEK": 56,
    "out:El price/MSEK": 39,
    "out:Heating price/MSEK": 15,
    "out:DH+ DHW/kWh/m2": 52,
    "out:El bought/kWh/m2": 2496.8,
    "out:Return %": 15,
    "out:Return/kSEK/y": 1462,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 1479000,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 73950,
    "out:EAHP (replacement cost)": 1105400,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 26.78,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 61.265983,
    "out:PV (m2 panels)": 321,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 57484.06,
    "out:PV (kWh sold)": 7621.57,
    "out:PV (% sold (El))": 13.26,
    "out:PV (kWh self-consumed)": 49862.48,
    "out:PV (ratio sold/self-consumed)": 0.15,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 617160,
    "out:EL kWh savings": -89120,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 128574.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 419830,
    "out:DH kr savings": 561616,
    "out:El kr savings": -151503,
    "out:El kr sold": 9718,
    "out:El kr saved": 84766,
    "out:El kr return": 94484,
    "out:% solar/total": 61,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 267511,
    "out:% savings (space heating)": 59.38,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 41.06,
    "out:Etvv": 0,
    "out:Ef": 33.23,
    "out:heatPumpQuantity": 10,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000101010001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 52.02,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 44.32,
    "out:Electricity (inc PV)": 32.91,
    "out:Total Energy Use Pre PV": 96.32,
    "out:Total Energy Use Post PV": 84.91,
    "out:Primary Energy": 89.2,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 26.59,
    "out:CO2 Operational/m2": 15.78,
    "out:Total CO2/m2": 42.37,
    "out:Total CO2 (tons)": 217.89,
    "out:Klimatpaverkan": -111.35,
    "out:Initial Cost/MSEK": 9.885125,
    "out:Running cost/(Apt SEK y)": 23714,
    "out:Running Cost over RSP/MSEK": 7.778,
    "out:LCP/MSEK": 1.606,
    "out:ROI% old": 16.61,
    "out:Payback discounted": 7,
    "out:Atemp": 5143,
    "out:Umean": 0.42,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 85,
    "out:Energy use kWh/m2": 85,
    "out:Energy savings %": 121.2,
    "out:Op energy cost/MSEK": 56,
    "out:El price/MSEK": 39,
    "out:Heating price/MSEK": 15,
    "out:DH+ DHW/kWh/m2": 52,
    "out:El bought/kWh/m2": 2454,
    "out:Return %": 15,
    "out:Return/kSEK/y": 1468,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 1479000,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 73950,
    "out:EAHP (replacement cost)": 1105400,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 26.12,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 61.265983,
    "out:PV (m2 panels)": 321,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 57484.06,
    "out:PV (kWh sold)": 7947.49,
    "out:PV (% sold (El))": 13.83,
    "out:PV (kWh self-consumed)": 49536.56,
    "out:PV (ratio sold/self-consumed)": 0.16,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 617160,
    "out:EL kWh savings": -87479,
    "out:Sewage heat recovery cost": 250448,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 423034,
    "out:DH kr savings": 561616,
    "out:El kr savings": -148714,
    "out:El kr sold": 10133,
    "out:El kr saved": 84212,
    "out:El kr return": 94345,
    "out:% solar/total": 61,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 267532,
    "out:% savings (space heating)": 59.38,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 42.81,
    "out:Etvv": 0,
    "out:Ef": 32.91,
    "out:heatPumpQuantity": 10,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000101010001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 48.03,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 44.22,
    "out:Electricity (inc PV)": 32.78,
    "out:Total Energy Use Pre PV": 92.22,
    "out:Total Energy Use Post PV": 80.78,
    "out:Primary Energy": 86.27,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 26.59,
    "out:CO2 Operational/m2": 14.28,
    "out:Total CO2/m2": 40.87,
    "out:Total CO2 (tons)": 210.17,
    "out:Klimatpaverkan": -119.07,
    "out:Initial Cost/MSEK": 10.01375,
    "out:Running cost/(Apt SEK y)": 22406,
    "out:Running Cost over RSP/MSEK": 7.348,
    "out:LCP/MSEK": 1.907,
    "out:ROI% old": 17.01,
    "out:Payback discounted": 7,
    "out:Atemp": 5143,
    "out:Umean": 0.42,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 81,
    "out:Energy use kWh/m2": 81,
    "out:Energy savings %": 132.1,
    "out:Op energy cost/MSEK": 53,
    "out:El price/MSEK": 37,
    "out:Heating price/MSEK": 14,
    "out:DH+ DHW/kWh/m2": 48,
    "out:El bought/kWh/m2": 2447.5,
    "out:Return %": 15,
    "out:Return/kSEK/y": 1523,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 1479000,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 73950,
    "out:EAHP (replacement cost)": 1105400,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 26.02,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 61.265983,
    "out:PV (m2 panels)": 321,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 57484.06,
    "out:PV (kWh sold)": 7998.42,
    "out:PV (% sold (El))": 13.91,
    "out:PV (kWh self-consumed)": 49485.64,
    "out:PV (ratio sold/self-consumed)": 0.16,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 637732,
    "out:EL kWh savings": -86809,
    "out:Sewage heat recovery cost": 250448,
    "out:Balancing hydronic system cost": 128574.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 442959,
    "out:DH kr savings": 580336,
    "out:El kr savings": -147575,
    "out:El kr sold": 10198,
    "out:El kr saved": 84126,
    "out:El kr return": 94324,
    "out:% solar/total": 61,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 247001,
    "out:% savings (space heating)": 62.5,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 38.96,
    "out:Etvv": 0,
    "out:Ef": 32.78,
    "out:heatPumpQuantity": 10,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000101020000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 56.06,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 45.07,
    "out:Electricity (inc PV)": 30.13,
    "out:Total Energy Use Pre PV": 101.07,
    "out:Total Energy Use Post PV": 86.13,
    "out:Primary Energy": 85.7,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 38.54,
    "out:CO2 Operational/m2": -21.95,
    "out:Total CO2/m2": 16.59,
    "out:Total CO2 (tons)": 85.31,
    "out:Klimatpaverkan": -243.93,
    "out:Initial Cost/MSEK": 10.7045,
    "out:Running cost/(Apt SEK y)": 23231,
    "out:Running Cost over RSP/MSEK": 7.636,
    "out:LCP/MSEK": 0.929,
    "out:ROI% old": 15.53,
    "out:Payback discounted": 8,
    "out:Atemp": 5143,
    "out:Umean": 0.42,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 86,
    "out:Energy use kWh/m2": 86,
    "out:Energy savings %": 118.6,
    "out:Op energy cost/MSEK": 56,
    "out:El price/MSEK": 37,
    "out:Heating price/MSEK": 16,
    "out:DH+ DHW/kWh/m2": 56,
    "out:El bought/kWh/m2": 2258,
    "out:Return %": 14,
    "out:Return/kSEK/y": 1488,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 1479000,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 73950,
    "out:EAHP (replacement cost)": 1105400,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 26.87,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.33,
    "out:PV (kWp installed)": 122.531965,
    "out:PV (m2 panels)": 643,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 114968.11,
    "out:PV (kWh sold)": 47449.12,
    "out:PV (% sold (El))": 41.27,
    "out:PV (kWh self-consumed)": 67518.99,
    "out:PV (ratio sold/self-consumed)": 0.7,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 596588,
    "out:EL kWh savings": -73210,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 478936,
    "out:DH kr savings": 542895,
    "out:El kr savings": -124456,
    "out:El kr sold": 60498,
    "out:El kr saved": 114782,
    "out:El kr return": 175280,
    "out:% solar/total": 83,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 288318,
    "out:% savings (space heating)": 56.22,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 44.94,
    "out:Etvv": 0,
    "out:Ef": 30.13,
    "out:heatPumpQuantity": 10,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000101020000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 52.01,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 44.98,
    "out:Electricity (inc PV)": 30.03,
    "out:Total Energy Use Pre PV": 96.98,
    "out:Total Energy Use Post PV": 82.03,
    "out:Primary Energy": 82.79,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 38.54,
    "out:CO2 Operational/m2": -23.48,
    "out:Total CO2/m2": 15.05,
    "out:Total CO2 (tons)": 77.43,
    "out:Klimatpaverkan": -251.81,
    "out:Initial Cost/MSEK": 10.833,
    "out:Running cost/(Apt SEK y)": 21927,
    "out:Running Cost over RSP/MSEK": 7.207,
    "out:LCP/MSEK": 1.229,
    "out:ROI% old": 15.91,
    "out:Payback discounted": 8,
    "out:Atemp": 5143,
    "out:Umean": 0.42,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 82,
    "out:Energy use kWh/m2": 82,
    "out:Energy savings %": 129.3,
    "out:Op energy cost/MSEK": 53,
    "out:El price/MSEK": 35,
    "out:Heating price/MSEK": 15,
    "out:DH+ DHW/kWh/m2": 52,
    "out:El bought/kWh/m2": 2253.1,
    "out:Return %": 14,
    "out:Return/kSEK/y": 1543,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 1479000,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 73950,
    "out:EAHP (replacement cost)": 1105400,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 26.78,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.33,
    "out:PV (kWp installed)": 122.531965,
    "out:PV (m2 panels)": 643,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 114968.11,
    "out:PV (kWh sold)": 47532.85,
    "out:PV (% sold (El))": 41.34,
    "out:PV (kWh self-consumed)": 67435.26,
    "out:PV (ratio sold/self-consumed)": 0.7,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 617160,
    "out:EL kWh savings": -72654,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 128574.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 498708,
    "out:DH kr savings": 561616,
    "out:El kr savings": -123512,
    "out:El kr sold": 60604,
    "out:El kr saved": 114640,
    "out:El kr return": 175244,
    "out:% solar/total": 82,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 267511,
    "out:% savings (space heating)": 59.38,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 41.06,
    "out:Etvv": 0,
    "out:Ef": 30.03,
    "out:heatPumpQuantity": 10,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000101020001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 52.02,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 44.32,
    "out:Electricity (inc PV)": 29.74,
    "out:Total Energy Use Pre PV": 96.32,
    "out:Total Energy Use Post PV": 81.74,
    "out:Primary Energy": 83.5,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 38.94,
    "out:CO2 Operational/m2": -24.21,
    "out:Total CO2/m2": 14.73,
    "out:Total CO2 (tons)": 75.77,
    "out:Klimatpaverkan": -253.47,
    "out:Initial Cost/MSEK": 10.954875,
    "out:Running cost/(Apt SEK y)": 21795,
    "out:Running Cost over RSP/MSEK": 7.164,
    "out:LCP/MSEK": 1.15,
    "out:ROI% old": 15.79,
    "out:Payback discounted": 8,
    "out:Atemp": 5143,
    "out:Umean": 0.42,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 82,
    "out:Energy use kWh/m2": 82,
    "out:Energy savings %": 129.3,
    "out:Op energy cost/MSEK": 52,
    "out:El price/MSEK": 35,
    "out:Heating price/MSEK": 15,
    "out:DH+ DHW/kWh/m2": 52,
    "out:El bought/kWh/m2": 2214.9,
    "out:Return %": 14,
    "out:Return/kSEK/y": 1549,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 1479000,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 73950,
    "out:EAHP (replacement cost)": 1105400,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 26.12,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.33,
    "out:PV (kWp installed)": 122.531965,
    "out:PV (m2 panels)": 643,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 114968.11,
    "out:PV (kWh sold)": 48185.89,
    "out:PV (% sold (El))": 41.91,
    "out:PV (kWh self-consumed)": 66782.22,
    "out:PV (ratio sold/self-consumed)": 0.72,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 617160,
    "out:EL kWh savings": -71185,
    "out:Sewage heat recovery cost": 250448,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 502039,
    "out:DH kr savings": 561616,
    "out:El kr savings": -121014,
    "out:El kr sold": 61437,
    "out:El kr saved": 113530,
    "out:El kr return": 174967,
    "out:% solar/total": 82,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 267532,
    "out:% savings (space heating)": 59.38,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 42.81,
    "out:Etvv": 0,
    "out:Ef": 29.74,
    "out:heatPumpQuantity": 10,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000101020001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 48.03,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 44.22,
    "out:Electricity (inc PV)": 29.62,
    "out:Total Energy Use Pre PV": 92.22,
    "out:Total Energy Use Post PV": 77.62,
    "out:Primary Energy": 80.6,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 38.94,
    "out:CO2 Operational/m2": -25.76,
    "out:Total CO2/m2": 13.18,
    "out:Total CO2 (tons)": 67.79,
    "out:Klimatpaverkan": -261.45,
    "out:Initial Cost/MSEK": 11.0835,
    "out:Running cost/(Apt SEK y)": 20487,
    "out:Running Cost over RSP/MSEK": 6.734,
    "out:LCP/MSEK": 1.451,
    "out:ROI% old": 16.16,
    "out:Payback discounted": 8,
    "out:Atemp": 5143,
    "out:Umean": 0.42,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 78,
    "out:Energy use kWh/m2": 78,
    "out:Energy savings %": 141,
    "out:Op energy cost/MSEK": 49,
    "out:El price/MSEK": 33,
    "out:Heating price/MSEK": 14,
    "out:DH+ DHW/kWh/m2": 48,
    "out:El bought/kWh/m2": 2209.1,
    "out:Return %": 14,
    "out:Return/kSEK/y": 1603,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 1479000,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 73950,
    "out:EAHP (replacement cost)": 1105400,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 26.02,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.33,
    "out:PV (kWp installed)": 122.531965,
    "out:PV (m2 panels)": 643,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 114968.11,
    "out:PV (kWh sold)": 48286.21,
    "out:PV (% sold (El))": 42,
    "out:PV (kWh self-consumed)": 66681.9,
    "out:PV (ratio sold/self-consumed)": 0.72,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 637732,
    "out:EL kWh savings": -70584,
    "out:Sewage heat recovery cost": 250448,
    "out:Balancing hydronic system cost": 128574.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 521908,
    "out:DH kr savings": 580336,
    "out:El kr savings": -119993,
    "out:El kr sold": 61565,
    "out:El kr saved": 113359,
    "out:El kr return": 174924,
    "out:% solar/total": 82,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 247001,
    "out:% savings (space heating)": 62.5,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 38.96,
    "out:Etvv": 0,
    "out:Ef": 29.62,
    "out:heatPumpQuantity": 10,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000101100000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 50.7,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 44.06,
    "out:Electricity (inc PV)": 42.18,
    "out:Total Energy Use Pre PV": 95.06,
    "out:Total Energy Use Post PV": 93.18,
    "out:Primary Energy": 105.39,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 13.83,
    "out:CO2 Operational/m2": 24.18,
    "out:Total CO2/m2": 38.01,
    "out:Total CO2 (tons)": 195.47,
    "out:Klimatpaverkan": -133.77,
    "out:Initial Cost/MSEK": 8.67575,
    "out:Running cost/(Apt SEK y)": 25585,
    "out:Running Cost over RSP/MSEK": 8.373,
    "out:LCP/MSEK": 2.221,
    "out:ROI% old": 17.94,
    "out:Payback discounted": 7,
    "out:Atemp": 5143,
    "out:Umean": 0.42,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 93,
    "out:Energy use kWh/m2": 93,
    "out:Energy savings %": 102.2,
    "out:Op energy cost/MSEK": 60,
    "out:El price/MSEK": 42,
    "out:Heating price/MSEK": 15,
    "out:DH+ DHW/kWh/m2": 51,
    "out:El bought/kWh/m2": 3084,
    "out:Return %": 16,
    "out:Return/kSEK/y": 1389,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 1479000,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 73950,
    "out:EAHP (replacement cost)": 1105400,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 25.86,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 622303,
    "out:EL kWh savings": -135144,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 336551,
    "out:DH kr savings": 566296,
    "out:El kr savings": -229745,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 260754,
    "out:% savings (space heating)": 60.41,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 42.11,
    "out:Etvv": 0,
    "out:Ef": 42.18,
    "out:heatPumpQuantity": 10,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000101100000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 46.73,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 43.95,
    "out:Electricity (inc PV)": 42.03,
    "out:Total Energy Use Pre PV": 90.95,
    "out:Total Energy Use Post PV": 89.03,
    "out:Primary Energy": 102.44,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 13.83,
    "out:CO2 Operational/m2": 22.72,
    "out:Total CO2/m2": 36.55,
    "out:Total CO2 (tons)": 187.99,
    "out:Klimatpaverkan": -141.25,
    "out:Initial Cost/MSEK": 8.804375,
    "out:Running cost/(Apt SEK y)": 24275,
    "out:Running Cost over RSP/MSEK": 7.942,
    "out:LCP/MSEK": 2.522,
    "out:ROI% old": 18.38,
    "out:Payback discounted": 7,
    "out:Atemp": 5143,
    "out:Umean": 0.42,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 89,
    "out:Energy use kWh/m2": 89,
    "out:Energy savings %": 111.2,
    "out:Op energy cost/MSEK": 56,
    "out:El price/MSEK": 41,
    "out:Heating price/MSEK": 13,
    "out:DH+ DHW/kWh/m2": 47,
    "out:El bought/kWh/m2": 3076.5,
    "out:Return %": 16,
    "out:Return/kSEK/y": 1444,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 1479000,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 73950,
    "out:EAHP (replacement cost)": 1105400,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 25.75,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 642875,
    "out:EL kWh savings": -134376,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 128574.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 356578,
    "out:DH kr savings": 585016,
    "out:El kr savings": -228439,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 240326,
    "out:% savings (space heating)": 63.51,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 38.27,
    "out:Etvv": 0,
    "out:Ef": 42.03,
    "out:heatPumpQuantity": 10,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000101100001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 47.6,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 43.42,
    "out:Electricity (inc PV)": 41.77,
    "out:Total Energy Use Pre PV": 91.42,
    "out:Total Energy Use Post PV": 89.77,
    "out:Primary Energy": 103.5,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 14.23,
    "out:CO2 Operational/m2": 23.01,
    "out:Total CO2/m2": 37.24,
    "out:Total CO2 (tons)": 191.55,
    "out:Klimatpaverkan": -137.69,
    "out:Initial Cost/MSEK": 8.92625,
    "out:Running cost/(Apt SEK y)": 24486,
    "out:Running Cost over RSP/MSEK": 8.012,
    "out:LCP/MSEK": 2.331,
    "out:ROI% old": 18.02,
    "out:Payback discounted": 7,
    "out:Atemp": 5143,
    "out:Umean": 0.42,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 90,
    "out:Energy use kWh/m2": 90,
    "out:Energy savings %": 108.9,
    "out:Op energy cost/MSEK": 57,
    "out:El price/MSEK": 41,
    "out:Heating price/MSEK": 14,
    "out:DH+ DHW/kWh/m2": 48,
    "out:El bought/kWh/m2": 3039.1,
    "out:Return %": 16,
    "out:Return/kSEK/y": 1436,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 1479000,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 73950,
    "out:EAHP (replacement cost)": 1105400,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 25.22,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 637732,
    "out:EL kWh savings": -133067,
    "out:Sewage heat recovery cost": 250448,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 354122,
    "out:DH kr savings": 580336,
    "out:El kr savings": -226214,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 244800,
    "out:% savings (space heating)": 62.83,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 40.44,
    "out:Etvv": 0,
    "out:Ef": 41.77,
    "out:heatPumpQuantity": 10,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000101100001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 43.67,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 43.29,
    "out:Electricity (inc PV)": 41.61,
    "out:Total Energy Use Pre PV": 87.29,
    "out:Total Energy Use Post PV": 85.61,
    "out:Primary Energy": 100.55,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 14.23,
    "out:CO2 Operational/m2": 21.55,
    "out:Total CO2/m2": 35.79,
    "out:Total CO2 (tons)": 184.06,
    "out:Klimatpaverkan": -145.18,
    "out:Initial Cost/MSEK": 9.05475,
    "out:Running cost/(Apt SEK y)": 23173,
    "out:Running Cost over RSP/MSEK": 7.581,
    "out:LCP/MSEK": 2.634,
    "out:ROI% old": 18.44,
    "out:Payback discounted": 7,
    "out:Atemp": 5143,
    "out:Umean": 0.42,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 86,
    "out:Energy use kWh/m2": 86,
    "out:Energy savings %": 118.6,
    "out:Op energy cost/MSEK": 54,
    "out:El price/MSEK": 39,
    "out:Heating price/MSEK": 13,
    "out:DH+ DHW/kWh/m2": 44,
    "out:El bought/kWh/m2": 3030.6,
    "out:Return %": 16,
    "out:Return/kSEK/y": 1491,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 1479000,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 73950,
    "out:EAHP (replacement cost)": 1105400,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 25.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 658304,
    "out:EL kWh savings": -132248,
    "out:Sewage heat recovery cost": 250448,
    "out:Balancing hydronic system cost": 128574.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 374235,
    "out:DH kr savings": 599057,
    "out:El kr savings": -224822,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 224606,
    "out:% savings (space heating)": 65.9,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 36.64,
    "out:Etvv": 0,
    "out:Ef": 41.61,
    "out:heatPumpQuantity": 10,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000101110000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 50.7,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 44.06,
    "out:Electricity (inc PV)": 32.76,
    "out:Total Energy Use Pre PV": 95.06,
    "out:Total Energy Use Post PV": 83.76,
    "out:Primary Energy": 88.44,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 26.18,
    "out:CO2 Operational/m2": 15.26,
    "out:Total CO2/m2": 41.45,
    "out:Total CO2 (tons)": 213.16,
    "out:Klimatpaverkan": -116.08,
    "out:Initial Cost/MSEK": 9.7455,
    "out:Running cost/(Apt SEK y)": 23340,
    "out:Running Cost over RSP/MSEK": 7.655,
    "out:LCP/MSEK": 1.868,
    "out:ROI% old": 17.02,
    "out:Payback discounted": 7,
    "out:Atemp": 5143,
    "out:Umean": 0.42,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 84,
    "out:Energy use kWh/m2": 84,
    "out:Energy savings %": 123.8,
    "out:Op energy cost/MSEK": 55,
    "out:El price/MSEK": 38,
    "out:Heating price/MSEK": 15,
    "out:DH+ DHW/kWh/m2": 51,
    "out:El bought/kWh/m2": 2437.4,
    "out:Return %": 15,
    "out:Return/kSEK/y": 1484,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 1479000,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 73950,
    "out:EAHP (replacement cost)": 1105400,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 25.86,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 61.265983,
    "out:PV (m2 panels)": 321,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 57484.06,
    "out:PV (kWh sold)": 8077.63,
    "out:PV (% sold (El))": 14.05,
    "out:PV (kWh self-consumed)": 49406.43,
    "out:PV (ratio sold/self-consumed)": 0.16,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 622303,
    "out:EL kWh savings": -86715,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 429180,
    "out:DH kr savings": 566296,
    "out:El kr savings": -147415,
    "out:El kr sold": 10299,
    "out:El kr saved": 83991,
    "out:El kr return": 94290,
    "out:% solar/total": 60,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 260754,
    "out:% savings (space heating)": 60.41,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 42.11,
    "out:Etvv": 0,
    "out:Ef": 32.76,
    "out:heatPumpQuantity": 10,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000101110000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 46.73,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 43.95,
    "out:Electricity (inc PV)": 32.63,
    "out:Total Energy Use Pre PV": 90.95,
    "out:Total Energy Use Post PV": 79.63,
    "out:Primary Energy": 85.52,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 26.18,
    "out:CO2 Operational/m2": 13.76,
    "out:Total CO2/m2": 39.94,
    "out:Total CO2 (tons)": 205.42,
    "out:Klimatpaverkan": -123.82,
    "out:Initial Cost/MSEK": 9.874125,
    "out:Running cost/(Apt SEK y)": 22030,
    "out:Running Cost over RSP/MSEK": 7.225,
    "out:LCP/MSEK": 2.17,
    "out:ROI% old": 17.43,
    "out:Payback discounted": 7,
    "out:Atemp": 5143,
    "out:Umean": 0.42,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 80,
    "out:Energy use kWh/m2": 80,
    "out:Energy savings %": 135,
    "out:Op energy cost/MSEK": 52,
    "out:El price/MSEK": 36,
    "out:Heating price/MSEK": 13,
    "out:DH+ DHW/kWh/m2": 47,
    "out:El bought/kWh/m2": 2430.5,
    "out:Return %": 16,
    "out:Return/kSEK/y": 1539,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 1479000,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 73950,
    "out:EAHP (replacement cost)": 1105400,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 25.75,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 61.265983,
    "out:PV (m2 panels)": 321,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 57484.06,
    "out:PV (kWh sold)": 8132.35,
    "out:PV (% sold (El))": 14.15,
    "out:PV (kWh self-consumed)": 49351.7,
    "out:PV (ratio sold/self-consumed)": 0.16,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 642875,
    "out:EL kWh savings": -86026,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 128574.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 449140,
    "out:DH kr savings": 585016,
    "out:El kr savings": -146245,
    "out:El kr sold": 10369,
    "out:El kr saved": 83898,
    "out:El kr return": 94267,
    "out:% solar/total": 60,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 240326,
    "out:% savings (space heating)": 63.51,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 38.27,
    "out:Etvv": 0,
    "out:Ef": 32.63,
    "out:heatPumpQuantity": 10,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000101110001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 47.6,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 43.42,
    "out:Electricity (inc PV)": 32.4,
    "out:Total Energy Use Pre PV": 91.42,
    "out:Total Energy Use Post PV": 80.4,
    "out:Primary Energy": 86.63,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 26.59,
    "out:CO2 Operational/m2": 13.79,
    "out:Total CO2/m2": 40.37,
    "out:Total CO2 (tons)": 207.64,
    "out:Klimatpaverkan": -121.6,
    "out:Initial Cost/MSEK": 9.996,
    "out:Running cost/(Apt SEK y)": 22244,
    "out:Running Cost over RSP/MSEK": 7.295,
    "out:LCP/MSEK": 1.978,
    "out:ROI% old": 17.11,
    "out:Payback discounted": 7,
    "out:Atemp": 5143,
    "out:Umean": 0.42,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 80,
    "out:Energy use kWh/m2": 80,
    "out:Energy savings %": 135,
    "out:Op energy cost/MSEK": 53,
    "out:El price/MSEK": 36,
    "out:Heating price/MSEK": 14,
    "out:DH+ DHW/kWh/m2": 48,
    "out:El bought/kWh/m2": 2396.4,
    "out:Return %": 15,
    "out:Return/kSEK/y": 1530,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 1479000,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 73950,
    "out:EAHP (replacement cost)": 1105400,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 25.22,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 61.265983,
    "out:PV (m2 panels)": 321,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 57484.06,
    "out:PV (kWh sold)": 8405.21,
    "out:PV (% sold (El))": 14.62,
    "out:PV (kWh self-consumed)": 49078.84,
    "out:PV (ratio sold/self-consumed)": 0.17,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 637732,
    "out:EL kWh savings": -84855,
    "out:Sewage heat recovery cost": 250448,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 446800,
    "out:DH kr savings": 580336,
    "out:El kr savings": -144253,
    "out:El kr sold": 10717,
    "out:El kr saved": 83434,
    "out:El kr return": 94151,
    "out:% solar/total": 60,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 244800,
    "out:% savings (space heating)": 62.83,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 40.44,
    "out:Etvv": 0,
    "out:Ef": 32.4,
    "out:heatPumpQuantity": 10,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000101110001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 43.67,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 43.29,
    "out:Electricity (inc PV)": 32.26,
    "out:Total Energy Use Pre PV": 87.29,
    "out:Total Energy Use Post PV": 76.26,
    "out:Primary Energy": 83.71,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 26.59,
    "out:CO2 Operational/m2": 12.27,
    "out:Total CO2/m2": 38.86,
    "out:Total CO2 (tons)": 199.84,
    "out:Klimatpaverkan": -129.4,
    "out:Initial Cost/MSEK": 10.124625,
    "out:Running cost/(Apt SEK y)": 20932,
    "out:Running Cost over RSP/MSEK": 6.864,
    "out:LCP/MSEK": 2.28,
    "out:ROI% old": 17.5,
    "out:Payback discounted": 7,
    "out:Atemp": 5143,
    "out:Umean": 0.42,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 76,
    "out:Energy use kWh/m2": 76,
    "out:Energy savings %": 147.4,
    "out:Op energy cost/MSEK": 49,
    "out:El price/MSEK": 35,
    "out:Heating price/MSEK": 13,
    "out:DH+ DHW/kWh/m2": 44,
    "out:El bought/kWh/m2": 2388.6,
    "out:Return %": 16,
    "out:Return/kSEK/y": 1585,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 1479000,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 73950,
    "out:EAHP (replacement cost)": 1105400,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 25.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 61.265983,
    "out:PV (m2 panels)": 321,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 57484.06,
    "out:PV (kWh sold)": 8468.1,
    "out:PV (% sold (El))": 14.73,
    "out:PV (kWh self-consumed)": 49015.95,
    "out:PV (ratio sold/self-consumed)": 0.17,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 658304,
    "out:EL kWh savings": -84122,
    "out:Sewage heat recovery cost": 250448,
    "out:Balancing hydronic system cost": 128574.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 466846,
    "out:DH kr savings": 599057,
    "out:El kr savings": -143008,
    "out:El kr sold": 10797,
    "out:El kr saved": 83327,
    "out:El kr return": 94124,
    "out:% solar/total": 60,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 224606,
    "out:% savings (space heating)": 65.9,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 36.64,
    "out:Etvv": 0,
    "out:Ef": 32.26,
    "out:heatPumpQuantity": 10,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000101120000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 50.7,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 44.06,
    "out:Electricity (inc PV)": 29.61,
    "out:Total Energy Use Pre PV": 95.06,
    "out:Total Energy Use Post PV": 80.61,
    "out:Primary Energy": 82.77,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 38.54,
    "out:CO2 Operational/m2": -24.85,
    "out:Total CO2/m2": 13.69,
    "out:Total CO2 (tons)": 70.39,
    "out:Klimatpaverkan": -258.85,
    "out:Initial Cost/MSEK": 10.815375,
    "out:Running cost/(Apt SEK y)": 21421,
    "out:Running Cost over RSP/MSEK": 7.042,
    "out:LCP/MSEK": 1.412,
    "out:ROI% old": 16.15,
    "out:Payback discounted": 8,
    "out:Atemp": 5143,
    "out:Umean": 0.42,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 81,
    "out:Energy use kWh/m2": 81,
    "out:Energy savings %": 132.1,
    "out:Op energy cost/MSEK": 51,
    "out:El price/MSEK": 34,
    "out:Heating price/MSEK": 15,
    "out:DH+ DHW/kWh/m2": 51,
    "out:El bought/kWh/m2": 2200.1,
    "out:Return %": 14,
    "out:Return/kSEK/y": 1564,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 1479000,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 73950,
    "out:EAHP (replacement cost)": 1105400,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 25.86,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.33,
    "out:PV (kWp installed)": 122.531965,
    "out:PV (m2 panels)": 643,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 114968.11,
    "out:PV (kWh sold)": 48441.77,
    "out:PV (% sold (El))": 42.13,
    "out:PV (kWh self-consumed)": 66526.34,
    "out:PV (ratio sold/self-consumed)": 0.73,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 622303,
    "out:EL kWh savings": -70500,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 508209,
    "out:DH kr savings": 566296,
    "out:El kr savings": -119850,
    "out:El kr sold": 61763,
    "out:El kr saved": 113095,
    "out:El kr return": 174858,
    "out:% solar/total": 81,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 260754,
    "out:% savings (space heating)": 60.41,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 42.11,
    "out:Etvv": 0,
    "out:Ef": 29.61,
    "out:heatPumpQuantity": 10,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000101120000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 46.73,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 43.95,
    "out:Electricity (inc PV)": 29.49,
    "out:Total Energy Use Pre PV": 90.95,
    "out:Total Energy Use Post PV": 76.49,
    "out:Primary Energy": 79.87,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 38.54,
    "out:CO2 Operational/m2": -26.41,
    "out:Total CO2/m2": 12.13,
    "out:Total CO2 (tons)": 62.38,
    "out:Klimatpaverkan": -266.86,
    "out:Initial Cost/MSEK": 10.943875,
    "out:Running cost/(Apt SEK y)": 20113,
    "out:Running Cost over RSP/MSEK": 6.612,
    "out:LCP/MSEK": 1.714,
    "out:ROI% old": 16.52,
    "out:Payback discounted": 7,
    "out:Atemp": 5143,
    "out:Umean": 0.42,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 76,
    "out:Energy use kWh/m2": 76,
    "out:Energy savings %": 147.4,
    "out:Op energy cost/MSEK": 48,
    "out:El price/MSEK": 32,
    "out:Heating price/MSEK": 13,
    "out:DH+ DHW/kWh/m2": 47,
    "out:El bought/kWh/m2": 2193.9,
    "out:Return %": 15,
    "out:Return/kSEK/y": 1619,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 1479000,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 73950,
    "out:EAHP (replacement cost)": 1105400,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 25.75,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.33,
    "out:PV (kWp installed)": 122.531965,
    "out:PV (m2 panels)": 643,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 114968.11,
    "out:PV (kWh sold)": 48548.65,
    "out:PV (% sold (El))": 42.23,
    "out:PV (kWh self-consumed)": 66419.46,
    "out:PV (ratio sold/self-consumed)": 0.73,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 642875,
    "out:EL kWh savings": -69883,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 128574.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 528115,
    "out:DH kr savings": 585016,
    "out:El kr savings": -118801,
    "out:El kr sold": 61900,
    "out:El kr saved": 112913,
    "out:El kr return": 174813,
    "out:% solar/total": 81,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 240326,
    "out:% savings (space heating)": 63.51,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 38.27,
    "out:Etvv": 0,
    "out:Ef": 29.49,
    "out:heatPumpQuantity": 10,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000101120001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 47.6,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 43.42,
    "out:Electricity (inc PV)": 29.28,
    "out:Total Energy Use Pre PV": 91.42,
    "out:Total Energy Use Post PV": 77.28,
    "out:Primary Energy": 81.02,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 38.94,
    "out:CO2 Operational/m2": -26.64,
    "out:Total CO2/m2": 12.3,
    "out:Total CO2 (tons)": 63.28,
    "out:Klimatpaverkan": -265.96,
    "out:Initial Cost/MSEK": 11.06575,
    "out:Running cost/(Apt SEK y)": 20329,
    "out:Running Cost over RSP/MSEK": 6.683,
    "out:LCP/MSEK": 1.52,
    "out:ROI% old": 16.25,
    "out:Payback discounted": 8,
    "out:Atemp": 5143,
    "out:Umean": 0.42,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 77,
    "out:Energy use kWh/m2": 77,
    "out:Energy savings %": 144.2,
    "out:Op energy cost/MSEK": 49,
    "out:El price/MSEK": 33,
    "out:Heating price/MSEK": 14,
    "out:DH+ DHW/kWh/m2": 48,
    "out:El bought/kWh/m2": 2163.5,
    "out:Return %": 15,
    "out:Return/kSEK/y": 1610,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 1479000,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 73950,
    "out:EAHP (replacement cost)": 1105400,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 25.22,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.33,
    "out:PV (kWp installed)": 122.531965,
    "out:PV (m2 panels)": 643,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 114968.11,
    "out:PV (kWh sold)": 49078.73,
    "out:PV (% sold (El))": 42.69,
    "out:PV (kWh self-consumed)": 65889.38,
    "out:PV (ratio sold/self-consumed)": 0.74,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 637732,
    "out:EL kWh savings": -68833,
    "out:Sewage heat recovery cost": 250448,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 525896,
    "out:DH kr savings": 580336,
    "out:El kr savings": -117016,
    "out:El kr sold": 62575,
    "out:El kr saved": 112012,
    "out:El kr return": 174587,
    "out:% solar/total": 81,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 244800,
    "out:% savings (space heating)": 62.83,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 40.44,
    "out:Etvv": 0,
    "out:Ef": 29.28,
    "out:heatPumpQuantity": 10,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000101120001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 43.67,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 43.29,
    "out:Electricity (inc PV)": 29.16,
    "out:Total Energy Use Pre PV": 87.29,
    "out:Total Energy Use Post PV": 73.16,
    "out:Primary Energy": 78.13,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 38.94,
    "out:CO2 Operational/m2": -28.21,
    "out:Total CO2/m2": 10.73,
    "out:Total CO2 (tons)": 55.18,
    "out:Klimatpaverkan": -274.06,
    "out:Initial Cost/MSEK": 11.194375,
    "out:Running cost/(Apt SEK y)": 19017,
    "out:Running Cost over RSP/MSEK": 6.252,
    "out:LCP/MSEK": 1.823,
    "out:ROI% old": 16.61,
    "out:Payback discounted": 7,
    "out:Atemp": 5143,
    "out:Umean": 0.42,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 73,
    "out:Energy use kWh/m2": 73,
    "out:Energy savings %": 157.5,
    "out:Op energy cost/MSEK": 46,
    "out:El price/MSEK": 31,
    "out:Heating price/MSEK": 13,
    "out:DH+ DHW/kWh/m2": 44,
    "out:El bought/kWh/m2": 2156.5,
    "out:Return %": 15,
    "out:Return/kSEK/y": 1665,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 1479000,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 73950,
    "out:EAHP (replacement cost)": 1105400,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 25.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.33,
    "out:PV (kWp installed)": 122.531965,
    "out:PV (m2 panels)": 643,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 114968.11,
    "out:PV (kWh sold)": 49200.32,
    "out:PV (% sold (El))": 42.79,
    "out:PV (kWh self-consumed)": 65767.79,
    "out:PV (ratio sold/self-consumed)": 0.75,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 658304,
    "out:EL kWh savings": -68176,
    "out:Sewage heat recovery cost": 250448,
    "out:Balancing hydronic system cost": 128574.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 545887,
    "out:DH kr savings": 599057,
    "out:El kr savings": -115900,
    "out:El kr sold": 62730,
    "out:El kr saved": 111805,
    "out:El kr return": 174536,
    "out:% solar/total": 80,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 224606,
    "out:% savings (space heating)": 65.9,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 36.64,
    "out:Etvv": 0,
    "out:Ef": 29.16,
    "out:heatPumpQuantity": 10,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000102000000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 9.06,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 59.31,
    "out:Electricity (inc PV)": 54.91,
    "out:Total Energy Use Pre PV": 68.31,
    "out:Total Energy Use Post PV": 63.91,
    "out:Primary Energy": 102.4,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 14.39,
    "out:CO2 Operational/m2": 11.07,
    "out:Total CO2/m2": 25.46,
    "out:Total CO2 (tons)": 130.95,
    "out:Klimatpaverkan": -198.29,
    "out:Initial Cost/MSEK": 11.216875,
    "out:Running cost/(Apt SEK y)": 15244,
    "out:Running Cost over RSP/MSEK": 4.961,
    "out:LCP/MSEK": 3.091,
    "out:ROI% old": 18.22,
    "out:Payback discounted": 7,
    "out:Atemp": 5143,
    "out:Umean": 0.42,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 64,
    "out:Energy use kWh/m2": 64,
    "out:Energy savings %": 193.8,
    "out:Op energy cost/MSEK": 34,
    "out:El price/MSEK": 31,
    "out:Heating price/MSEK": 3,
    "out:DH+ DHW/kWh/m2": 9,
    "out:El bought/kWh/m2": 4152,
    "out:Return %": 16,
    "out:Return/kSEK/y": 1824,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 2958000,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 147900,
    "out:EAHP (replacement cost)": 2204400,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 41.11,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 838309,
    "out:EL kWh savings": -200644,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 421766,
    "out:DH kr savings": 762861,
    "out:El kr savings": -341095,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 46571,
    "out:% savings (space heating)": 92.93,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 5.08,
    "out:Etvv": 0,
    "out:Ef": 54.91,
    "out:heatPumpQuantity": 20,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000102000000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 6.91,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 58.65,
    "out:Electricity (inc PV)": 54.01,
    "out:Total Energy Use Pre PV": 65.65,
    "out:Total Energy Use Post PV": 61.01,
    "out:Primary Energy": 99.83,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 14.39,
    "out:CO2 Operational/m2": 10.26,
    "out:Total CO2/m2": 24.65,
    "out:Total CO2 (tons)": 126.8,
    "out:Klimatpaverkan": -202.44,
    "out:Initial Cost/MSEK": 11.3455,
    "out:Running cost/(Apt SEK y)": 14462,
    "out:Running Cost over RSP/MSEK": 4.705,
    "out:LCP/MSEK": 3.219,
    "out:ROI% old": 18.34,
    "out:Payback discounted": 7,
    "out:Atemp": 5143,
    "out:Umean": 0.42,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 61,
    "out:Energy use kWh/m2": 61,
    "out:Energy savings %": 208.2,
    "out:Op energy cost/MSEK": 32,
    "out:El price/MSEK": 29,
    "out:Heating price/MSEK": 2,
    "out:DH+ DHW/kWh/m2": 7,
    "out:El bought/kWh/m2": 4105.6,
    "out:Return %": 16,
    "out:Return/kSEK/y": 1856,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 2958000,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 147900,
    "out:EAHP (replacement cost)": 2204400,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 40.45,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 848595,
    "out:EL kWh savings": -196018,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 128574.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 438991,
    "out:DH kr savings": 772221,
    "out:El kr savings": -333230,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 35552,
    "out:% savings (space heating)": 94.6,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 3.72,
    "out:Etvv": 0,
    "out:Ef": 54.01,
    "out:heatPumpQuantity": 20,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000102000001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 7.44,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 57.83,
    "out:Electricity (inc PV)": 53.95,
    "out:Total Energy Use Pre PV": 64.83,
    "out:Total Energy Use Post PV": 60.95,
    "out:Primary Energy": 100.26,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 14.79,
    "out:CO2 Operational/m2": 10.15,
    "out:Total CO2/m2": 24.95,
    "out:Total CO2 (tons)": 128.31,
    "out:Klimatpaverkan": -200.93,
    "out:Initial Cost/MSEK": 11.467375,
    "out:Running cost/(Apt SEK y)": 14291,
    "out:Running Cost over RSP/MSEK": 4.649,
    "out:LCP/MSEK": 3.153,
    "out:ROI% old": 18.21,
    "out:Payback discounted": 7,
    "out:Atemp": 5143,
    "out:Umean": 0.42,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 61,
    "out:Energy use kWh/m2": 61,
    "out:Energy savings %": 208.2,
    "out:Op energy cost/MSEK": 31,
    "out:El price/MSEK": 29,
    "out:Heating price/MSEK": 2,
    "out:DH+ DHW/kWh/m2": 7,
    "out:El bought/kWh/m2": 4047.9,
    "out:Return %": 16,
    "out:Return/kSEK/y": 1864,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 2958000,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 147900,
    "out:EAHP (replacement cost)": 2204400,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 39.63,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 848595,
    "out:EL kWh savings": -195699,
    "out:Sewage heat recovery cost": 250448,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 439534,
    "out:DH kr savings": 772221,
    "out:El kr savings": -332688,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 38251,
    "out:% savings (space heating)": 94.19,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 4.5,
    "out:Etvv": 0,
    "out:Ef": 53.95,
    "out:heatPumpQuantity": 20,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000102000001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 5.56,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 57.08,
    "out:Electricity (inc PV)": 53.01,
    "out:Total Energy Use Pre PV": 63.08,
    "out:Total Energy Use Post PV": 59.01,
    "out:Primary Energy": 97.71,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 14.79,
    "out:CO2 Operational/m2": 9.7,
    "out:Total CO2/m2": 24.49,
    "out:Total CO2 (tons)": 125.95,
    "out:Klimatpaverkan": -203.29,
    "out:Initial Cost/MSEK": 11.595875,
    "out:Running cost/(Apt SEK y)": 13814,
    "out:Running Cost over RSP/MSEK": 4.493,
    "out:LCP/MSEK": 3.18,
    "out:ROI% old": 18.2,
    "out:Payback discounted": 7,
    "out:Atemp": 5143,
    "out:Umean": 0.42,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 59,
    "out:Energy use kWh/m2": 59,
    "out:Energy savings %": 218.6,
    "out:Op energy cost/MSEK": 30,
    "out:El price/MSEK": 28,
    "out:Heating price/MSEK": 2,
    "out:DH+ DHW/kWh/m2": 6,
    "out:El bought/kWh/m2": 3995.8,
    "out:Return %": 16,
    "out:Return/kSEK/y": 1884,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 2958000,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 147900,
    "out:EAHP (replacement cost)": 2204400,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 38.88,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 853738,
    "out:EL kWh savings": -190869,
    "out:Sewage heat recovery cost": 250448,
    "out:Balancing hydronic system cost": 128574.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 452425,
    "out:DH kr savings": 776902,
    "out:El kr savings": -324477,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 28614,
    "out:% savings (space heating)": 95.66,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 3.27,
    "out:Etvv": 0,
    "out:Ef": 53.01,
    "out:heatPumpQuantity": 20,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000102010000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 9.06,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 59.31,
    "out:Electricity (inc PV)": 44.46,
    "out:Total Energy Use Pre PV": 68.31,
    "out:Total Energy Use Post PV": 53.46,
    "out:Primary Energy": 83.59,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 26.75,
    "out:CO2 Operational/m2": 7.41,
    "out:Total CO2/m2": 34.16,
    "out:Total CO2 (tons)": 175.67,
    "out:Klimatpaverkan": -153.57,
    "out:Initial Cost/MSEK": 12.28675,
    "out:Running cost/(Apt SEK y)": 12942,
    "out:Running Cost over RSP/MSEK": 4.226,
    "out:LCP/MSEK": 2.756,
    "out:ROI% old": 17.49,
    "out:Payback discounted": 7,
    "out:Atemp": 5143,
    "out:Umean": 0.42,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 53,
    "out:Energy use kWh/m2": 53,
    "out:Energy savings %": 254.7,
    "out:Op energy cost/MSEK": 29,
    "out:El price/MSEK": 26,
    "out:Heating price/MSEK": 3,
    "out:DH+ DHW/kWh/m2": 9,
    "out:El bought/kWh/m2": 3441.5,
    "out:Return %": 16,
    "out:Return/kSEK/y": 1920,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 2958000,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 147900,
    "out:EAHP (replacement cost)": 2204400,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 41.11,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 61.265983,
    "out:PV (m2 panels)": 321,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 57484.06,
    "out:PV (kWh sold)": 2517.81,
    "out:PV (% sold (El))": 4.38,
    "out:PV (kWh self-consumed)": 54966.25,
    "out:PV (ratio sold/self-consumed)": 0.05,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 838309,
    "out:EL kWh savings": -146896,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 516348,
    "out:DH kr savings": 762861,
    "out:El kr savings": -249724,
    "out:El kr sold": 3210,
    "out:El kr saved": 93443,
    "out:El kr return": 96653,
    "out:% solar/total": 67,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 46571,
    "out:% savings (space heating)": 92.93,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 5.08,
    "out:Etvv": 0,
    "out:Ef": 44.46,
    "out:heatPumpQuantity": 20,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000102010000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 6.91,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 58.65,
    "out:Electricity (inc PV)": 43.62,
    "out:Total Energy Use Pre PV": 65.65,
    "out:Total Energy Use Post PV": 50.62,
    "out:Primary Energy": 81.11,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 26.75,
    "out:CO2 Operational/m2": 6.45,
    "out:Total CO2/m2": 33.19,
    "out:Total CO2 (tons)": 170.71,
    "out:Klimatpaverkan": -158.53,
    "out:Initial Cost/MSEK": 12.41525,
    "out:Running cost/(Apt SEK y)": 12163,
    "out:Running Cost over RSP/MSEK": 3.97,
    "out:LCP/MSEK": 2.884,
    "out:ROI% old": 17.6,
    "out:Payback discounted": 7,
    "out:Atemp": 5143,
    "out:Umean": 0.42,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 51,
    "out:Energy use kWh/m2": 51,
    "out:Energy savings %": 268.6,
    "out:Op energy cost/MSEK": 27,
    "out:El price/MSEK": 25,
    "out:Heating price/MSEK": 2,
    "out:DH+ DHW/kWh/m2": 7,
    "out:El bought/kWh/m2": 3396.9,
    "out:Return %": 16,
    "out:Return/kSEK/y": 1953,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 2958000,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 147900,
    "out:EAHP (replacement cost)": 2204400,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 40.45,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 61.265983,
    "out:PV (m2 panels)": 321,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 57484.06,
    "out:PV (kWh sold)": 2684.1,
    "out:PV (% sold (El))": 4.67,
    "out:PV (kWh self-consumed)": 54799.95,
    "out:PV (ratio sold/self-consumed)": 0.05,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 848595,
    "out:EL kWh savings": -142554,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 128574.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 533301,
    "out:DH kr savings": 772221,
    "out:El kr savings": -242343,
    "out:El kr sold": 3422,
    "out:El kr saved": 93160,
    "out:El kr return": 96582,
    "out:% solar/total": 67,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 35552,
    "out:% savings (space heating)": 94.6,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 3.72,
    "out:Etvv": 0,
    "out:Ef": 43.62,
    "out:heatPumpQuantity": 20,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000102010001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 7.44,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 57.83,
    "out:Electricity (inc PV)": 43.56,
    "out:Total Energy Use Pre PV": 64.83,
    "out:Total Energy Use Post PV": 50.56,
    "out:Primary Energy": 81.56,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 27.15,
    "out:CO2 Operational/m2": 6.14,
    "out:Total CO2/m2": 33.29,
    "out:Total CO2 (tons)": 171.19,
    "out:Klimatpaverkan": -158.05,
    "out:Initial Cost/MSEK": 12.5375,
    "out:Running cost/(Apt SEK y)": 11993,
    "out:Running Cost over RSP/MSEK": 3.915,
    "out:LCP/MSEK": 2.816,
    "out:ROI% old": 17.49,
    "out:Payback discounted": 7,
    "out:Atemp": 5143,
    "out:Umean": 0.42,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 51,
    "out:Energy use kWh/m2": 51,
    "out:Energy savings %": 268.6,
    "out:Op energy cost/MSEK": 27,
    "out:El price/MSEK": 24,
    "out:Heating price/MSEK": 2,
    "out:DH+ DHW/kWh/m2": 7,
    "out:El bought/kWh/m2": 3341.5,
    "out:Return %": 16,
    "out:Return/kSEK/y": 1960,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 2958000,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 147900,
    "out:EAHP (replacement cost)": 2204400,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 39.63,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 61.265983,
    "out:PV (m2 panels)": 321,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 57484.06,
    "out:PV (kWh sold)": 2898.46,
    "out:PV (% sold (El))": 5.04,
    "out:PV (kWh self-consumed)": 54585.6,
    "out:PV (ratio sold/self-consumed)": 0.05,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 848595,
    "out:EL kWh savings": -142255,
    "out:Sewage heat recovery cost": 250448,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 534083,
    "out:DH kr savings": 772221,
    "out:El kr savings": -241834,
    "out:El kr sold": 3696,
    "out:El kr saved": 92796,
    "out:El kr return": 96491,
    "out:% solar/total": 67,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 38251,
    "out:% savings (space heating)": 94.19,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 4.5,
    "out:Etvv": 0,
    "out:Ef": 43.56,
    "out:heatPumpQuantity": 20,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000102010001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 5.56,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 57.08,
    "out:Electricity (inc PV)": 42.68,
    "out:Total Energy Use Pre PV": 63.08,
    "out:Total Energy Use Post PV": 48.68,
    "out:Primary Energy": 79.12,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 27.15,
    "out:CO2 Operational/m2": 5.49,
    "out:Total CO2/m2": 32.64,
    "out:Total CO2 (tons)": 167.86,
    "out:Klimatpaverkan": -161.38,
    "out:Initial Cost/MSEK": 12.66625,
    "out:Running cost/(Apt SEK y)": 11518,
    "out:Running Cost over RSP/MSEK": 3.76,
    "out:LCP/MSEK": 2.843,
    "out:ROI% old": 17.49,
    "out:Payback discounted": 7,
    "out:Atemp": 5143,
    "out:Umean": 0.42,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 49,
    "out:Energy use kWh/m2": 49,
    "out:Energy savings %": 283.7,
    "out:Op energy cost/MSEK": 26,
    "out:El price/MSEK": 24,
    "out:Heating price/MSEK": 2,
    "out:DH+ DHW/kWh/m2": 6,
    "out:El bought/kWh/m2": 3291.6,
    "out:Return %": 16,
    "out:Return/kSEK/y": 1980,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 2958000,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 147900,
    "out:EAHP (replacement cost)": 2204400,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 38.88,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 61.265983,
    "out:PV (m2 panels)": 321,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 57484.06,
    "out:PV (kWh sold)": 3100.76,
    "out:PV (% sold (El))": 5.39,
    "out:PV (kWh self-consumed)": 54383.29,
    "out:PV (ratio sold/self-consumed)": 0.06,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 853738,
    "out:EL kWh savings": -137738,
    "out:Sewage heat recovery cost": 250448,
    "out:Balancing hydronic system cost": 128574.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 546701,
    "out:DH kr savings": 776902,
    "out:El kr savings": -234154,
    "out:El kr sold": 3953,
    "out:El kr saved": 92452,
    "out:El kr return": 96405,
    "out:% solar/total": 67,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 28614,
    "out:% savings (space heating)": 95.66,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 3.27,
    "out:Etvv": 0,
    "out:Ef": 42.68,
    "out:heatPumpQuantity": 20,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000102020000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 9.06,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 59.31,
    "out:Electricity (inc PV)": 40.04,
    "out:Total Energy Use Pre PV": 68.31,
    "out:Total Energy Use Post PV": 49.04,
    "out:Primary Energy": 75.63,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 39.1,
    "out:CO2 Operational/m2": -24.72,
    "out:Total CO2/m2": 14.38,
    "out:Total CO2 (tons)": 73.94,
    "out:Klimatpaverkan": -255.3,
    "out:Initial Cost/MSEK": 13.35625,
    "out:Running cost/(Apt SEK y)": 10943,
    "out:Running Cost over RSP/MSEK": 3.586,
    "out:LCP/MSEK": 2.327,
    "out:ROI% old": 16.77,
    "out:Payback discounted": 7,
    "out:Atemp": 5143,
    "out:Umean": 0.42,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 49,
    "out:Energy use kWh/m2": 49,
    "out:Energy savings %": 283.7,
    "out:Op energy cost/MSEK": 25,
    "out:El price/MSEK": 22,
    "out:Heating price/MSEK": 3,
    "out:DH+ DHW/kWh/m2": 9,
    "out:El bought/kWh/m2": 3091.3,
    "out:Return %": 15,
    "out:Return/kSEK/y": 2004,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 2958000,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 147900,
    "out:EAHP (replacement cost)": 2204400,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 41.11,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.33,
    "out:PV (kWp installed)": 122.531965,
    "out:PV (m2 panels)": 643,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 114968.11,
    "out:PV (kWh sold)": 34818.73,
    "out:PV (% sold (El))": 30.29,
    "out:PV (kWh self-consumed)": 80149.38,
    "out:PV (ratio sold/self-consumed)": 0.43,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 838309,
    "out:EL kWh savings": -124160,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 596182,
    "out:DH kr savings": 762861,
    "out:El kr savings": -211073,
    "out:El kr sold": 44394,
    "out:El kr saved": 136254,
    "out:El kr return": 180648,
    "out:% solar/total": 98,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 46571,
    "out:% savings (space heating)": 92.93,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 5.08,
    "out:Etvv": 0,
    "out:Ef": 40.04,
    "out:heatPumpQuantity": 20,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000102020000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 6.91,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 58.65,
    "out:Electricity (inc PV)": 39.29,
    "out:Total Energy Use Pre PV": 65.65,
    "out:Total Energy Use Post PV": 46.29,
    "out:Primary Energy": 73.33,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 39.1,
    "out:CO2 Operational/m2": -26.05,
    "out:Total CO2/m2": 13.05,
    "out:Total CO2 (tons)": 67.1,
    "out:Klimatpaverkan": -262.14,
    "out:Initial Cost/MSEK": 13.485,
    "out:Running cost/(Apt SEK y)": 10167,
    "out:Running Cost over RSP/MSEK": 3.331,
    "out:LCP/MSEK": 2.453,
    "out:ROI% old": 16.88,
    "out:Payback discounted": 7,
    "out:Atemp": 5143,
    "out:Umean": 0.42,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 46,
    "out:Energy use kWh/m2": 46,
    "out:Energy savings %": 308.7,
    "out:Op energy cost/MSEK": 23,
    "out:El price/MSEK": 21,
    "out:Heating price/MSEK": 2,
    "out:DH+ DHW/kWh/m2": 7,
    "out:El bought/kWh/m2": 3051.8,
    "out:Return %": 15,
    "out:Return/kSEK/y": 2037,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 2958000,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 147900,
    "out:EAHP (replacement cost)": 2204400,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 40.45,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.33,
    "out:PV (kWp installed)": 122.531965,
    "out:PV (m2 panels)": 643,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 114968.11,
    "out:PV (kWh sold)": 35359.99,
    "out:PV (% sold (El))": 30.76,
    "out:PV (kWh self-consumed)": 79608.12,
    "out:PV (ratio sold/self-consumed)": 0.44,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 848595,
    "out:EL kWh savings": -120303,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 128574.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 612791,
    "out:DH kr savings": 772221,
    "out:El kr savings": -204514,
    "out:El kr sold": 45084,
    "out:El kr saved": 135334,
    "out:El kr return": 180418,
    "out:% solar/total": 97,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 35552,
    "out:% savings (space heating)": 94.6,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 3.72,
    "out:Etvv": 0,
    "out:Ef": 39.29,
    "out:heatPumpQuantity": 20,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000102020001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 7.44,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 57.83,
    "out:Electricity (inc PV)": 39.24,
    "out:Total Energy Use Pre PV": 64.83,
    "out:Total Energy Use Post PV": 46.24,
    "out:Primary Energy": 73.78,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 39.5,
    "out:CO2 Operational/m2": -26.82,
    "out:Total CO2/m2": 12.68,
    "out:Total CO2 (tons)": 65.22,
    "out:Klimatpaverkan": -264.02,
    "out:Initial Cost/MSEK": 13.6075,
    "out:Running cost/(Apt SEK y)": 10002,
    "out:Running Cost over RSP/MSEK": 3.278,
    "out:LCP/MSEK": 2.384,
    "out:ROI% old": 16.79,
    "out:Payback discounted": 7,
    "out:Atemp": 5143,
    "out:Umean": 0.42,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 46,
    "out:Energy use kWh/m2": 46,
    "out:Energy savings %": 308.7,
    "out:Op energy cost/MSEK": 23,
    "out:El price/MSEK": 21,
    "out:Heating price/MSEK": 2,
    "out:DH+ DHW/kWh/m2": 7,
    "out:El bought/kWh/m2": 3002.8,
    "out:Return %": 15,
    "out:Return/kSEK/y": 2044,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 2958000,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 147900,
    "out:EAHP (replacement cost)": 2204400,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 39.63,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.33,
    "out:PV (kWp installed)": 122.531965,
    "out:PV (m2 panels)": 643,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 114968.11,
    "out:PV (kWh sold)": 36038.72,
    "out:PV (% sold (El))": 31.35,
    "out:PV (kWh self-consumed)": 78929.39,
    "out:PV (ratio sold/self-consumed)": 0.46,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 848595,
    "out:EL kWh savings": -120037,
    "out:Sewage heat recovery cost": 250448,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 614108,
    "out:DH kr savings": 772221,
    "out:El kr savings": -204062,
    "out:El kr sold": 45949,
    "out:El kr saved": 134180,
    "out:El kr return": 180129,
    "out:% solar/total": 97,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 38251,
    "out:% savings (space heating)": 94.19,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 4.5,
    "out:Etvv": 0,
    "out:Ef": 39.24,
    "out:heatPumpQuantity": 20,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000102020001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 5.56,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 57.08,
    "out:Electricity (inc PV)": 38.46,
    "out:Total Energy Use Pre PV": 63.08,
    "out:Total Energy Use Post PV": 44.46,
    "out:Primary Energy": 71.52,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 39.5,
    "out:CO2 Operational/m2": -27.88,
    "out:Total CO2/m2": 11.62,
    "out:Total CO2 (tons)": 59.78,
    "out:Klimatpaverkan": -269.46,
    "out:Initial Cost/MSEK": 13.735,
    "out:Running cost/(Apt SEK y)": 9531,
    "out:Running Cost over RSP/MSEK": 3.124,
    "out:LCP/MSEK": 2.41,
    "out:ROI% old": 16.79,
    "out:Payback discounted": 7,
    "out:Atemp": 5143,
    "out:Umean": 0.42,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 44,
    "out:Energy use kWh/m2": 44,
    "out:Energy savings %": 327.3,
    "out:Op energy cost/MSEK": 22,
    "out:El price/MSEK": 20,
    "out:Heating price/MSEK": 2,
    "out:DH+ DHW/kWh/m2": 6,
    "out:El bought/kWh/m2": 2958.7,
    "out:Return %": 15,
    "out:Return/kSEK/y": 2064,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 2958000,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 147900,
    "out:EAHP (replacement cost)": 2204400,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 38.88,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.33,
    "out:PV (kWp installed)": 122.531965,
    "out:PV (m2 panels)": 643,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 114968.11,
    "out:PV (kWh sold)": 36656.24,
    "out:PV (% sold (El))": 31.88,
    "out:PV (kWh self-consumed)": 78311.88,
    "out:PV (ratio sold/self-consumed)": 0.47,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 853738,
    "out:EL kWh savings": -116021,
    "out:Sewage heat recovery cost": 250448,
    "out:Balancing hydronic system cost": 128574.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 626402,
    "out:DH kr savings": 776902,
    "out:El kr savings": -197236,
    "out:El kr sold": 46737,
    "out:El kr saved": 133130,
    "out:El kr return": 179867,
    "out:% solar/total": 96,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 28614,
    "out:% savings (space heating)": 95.66,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 3.27,
    "out:Etvv": 0,
    "out:Ef": 38.46,
    "out:heatPumpQuantity": 20,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000102100000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 6.95,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 57.32,
    "out:Electricity (inc PV)": 53.63,
    "out:Total Energy Use Pre PV": 64.32,
    "out:Total Energy Use Post PV": 60.63,
    "out:Primary Energy": 99.55,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 14.39,
    "out:CO2 Operational/m2": 10.09,
    "out:Total CO2/m2": 24.48,
    "out:Total CO2 (tons)": 125.89,
    "out:Klimatpaverkan": -203.35,
    "out:Initial Cost/MSEK": 11.32775,
    "out:Running cost/(Apt SEK y)": 14184,
    "out:Running Cost over RSP/MSEK": 4.615,
    "out:LCP/MSEK": 3.327,
    "out:ROI% old": 18.48,
    "out:Payback discounted": 7,
    "out:Atemp": 5143,
    "out:Umean": 0.42,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 61,
    "out:Energy use kWh/m2": 61,
    "out:Energy savings %": 208.2,
    "out:Op energy cost/MSEK": 31,
    "out:El price/MSEK": 29,
    "out:Heating price/MSEK": 2,
    "out:DH+ DHW/kWh/m2": 7,
    "out:El bought/kWh/m2": 4012.2,
    "out:Return %": 16,
    "out:Return/kSEK/y": 1868,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 2958000,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 147900,
    "out:EAHP (replacement cost)": 2204400,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 39.12,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 848595,
    "out:EL kWh savings": -194027,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 442375,
    "out:DH kr savings": 772221,
    "out:El kr savings": -329846,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 35724,
    "out:% savings (space heating)": 94.58,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 4.32,
    "out:Etvv": 0,
    "out:Ef": 53.63,
    "out:heatPumpQuantity": 20,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000102100000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 5.17,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 56.54,
    "out:Electricity (inc PV)": 52.68,
    "out:Total Energy Use Pre PV": 61.54,
    "out:Total Energy Use Post PV": 57.68,
    "out:Primary Energy": 97.01,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 14.39,
    "out:CO2 Operational/m2": 9.26,
    "out:Total CO2/m2": 23.66,
    "out:Total CO2 (tons)": 121.66,
    "out:Klimatpaverkan": -207.58,
    "out:Initial Cost/MSEK": 11.456375,
    "out:Running cost/(Apt SEK y)": 13380,
    "out:Running Cost over RSP/MSEK": 4.351,
    "out:LCP/MSEK": 3.462,
    "out:ROI% old": 18.6,
    "out:Payback discounted": 7,
    "out:Atemp": 5143,
    "out:Umean": 0.42,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 58,
    "out:Energy use kWh/m2": 58,
    "out:Energy savings %": 224.1,
    "out:Op energy cost/MSEK": 29,
    "out:El price/MSEK": 28,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 5,
    "out:El bought/kWh/m2": 3958,
    "out:Return %": 17,
    "out:Return/kSEK/y": 1902,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 2958000,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 147900,
    "out:EAHP (replacement cost)": 2204400,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 38.34,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 858881,
    "out:EL kWh savings": -189136,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 128574.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 460051,
    "out:DH kr savings": 781582,
    "out:El kr savings": -321530,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 26588,
    "out:% savings (space heating)": 95.96,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 3.13,
    "out:Etvv": 0,
    "out:Ef": 52.68,
    "out:heatPumpQuantity": 20,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000102100001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 5.88,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 56.06,
    "out:Electricity (inc PV)": 52.84,
    "out:Total Energy Use Pre PV": 62.06,
    "out:Total Energy Use Post PV": 58.84,
    "out:Primary Energy": 97.86,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 14.79,
    "out:CO2 Operational/m2": 9.56,
    "out:Total CO2/m2": 24.35,
    "out:Total CO2 (tons)": 125.25,
    "out:Klimatpaverkan": -203.99,
    "out:Initial Cost/MSEK": 11.57825,
    "out:Running cost/(Apt SEK y)": 13600,
    "out:Running Cost over RSP/MSEK": 4.424,
    "out:LCP/MSEK": 3.267,
    "out:ROI% old": 18.32,
    "out:Payback discounted": 7,
    "out:Atemp": 5143,
    "out:Umean": 0.42,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 59,
    "out:Energy use kWh/m2": 59,
    "out:Energy savings %": 218.6,
    "out:Op energy cost/MSEK": 30,
    "out:El price/MSEK": 28,
    "out:Heating price/MSEK": 2,
    "out:DH+ DHW/kWh/m2": 6,
    "out:El bought/kWh/m2": 3924,
    "out:Return %": 16,
    "out:Return/kSEK/y": 1893,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 2958000,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 147900,
    "out:EAHP (replacement cost)": 2204400,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 37.86,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 853738,
    "out:EL kWh savings": -189970,
    "out:Sewage heat recovery cost": 250448,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 453953,
    "out:DH kr savings": 776902,
    "out:El kr savings": -322949,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 30258,
    "out:% savings (space heating)": 95.41,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 3.93,
    "out:Etvv": 0,
    "out:Ef": 52.84,
    "out:heatPumpQuantity": 20,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000102100001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 4.34,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 55.21,
    "out:Electricity (inc PV)": 51.86,
    "out:Total Energy Use Pre PV": 59.21,
    "out:Total Energy Use Post PV": 55.86,
    "out:Primary Energy": 95.32,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 14.79,
    "out:CO2 Operational/m2": 8.73,
    "out:Total CO2/m2": 23.52,
    "out:Total CO2 (tons)": 120.98,
    "out:Klimatpaverkan": -208.26,
    "out:Initial Cost/MSEK": 11.70675,
    "out:Running cost/(Apt SEK y)": 12781,
    "out:Running Cost over RSP/MSEK": 4.155,
    "out:LCP/MSEK": 3.407,
    "out:ROI% old": 18.44,
    "out:Payback discounted": 7,
    "out:Atemp": 5143,
    "out:Umean": 0.42,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 56,
    "out:Energy use kWh/m2": 56,
    "out:Energy savings %": 235.7,
    "out:Op energy cost/MSEK": 28,
    "out:El price/MSEK": 26,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 4,
    "out:El bought/kWh/m2": 3865,
    "out:Return %": 16,
    "out:Return/kSEK/y": 1927,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 2958000,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 147900,
    "out:EAHP (replacement cost)": 2204400,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 37.01,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 864024,
    "out:EL kWh savings": -184947,
    "out:Sewage heat recovery cost": 250448,
    "out:Balancing hydronic system cost": 128574.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 471852,
    "out:DH kr savings": 786262,
    "out:El kr savings": -314410,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 22305,
    "out:% savings (space heating)": 96.61,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 2.82,
    "out:Etvv": 0,
    "out:Ef": 51.86,
    "out:heatPumpQuantity": 20,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000102110000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 6.95,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 57.32,
    "out:Electricity (inc PV)": 43.26,
    "out:Total Energy Use Pre PV": 64.32,
    "out:Total Energy Use Post PV": 50.26,
    "out:Primary Energy": 80.89,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 26.75,
    "out:CO2 Operational/m2": 5.94,
    "out:Total CO2/m2": 32.69,
    "out:Total CO2 (tons)": 168.11,
    "out:Klimatpaverkan": -161.13,
    "out:Initial Cost/MSEK": 12.3975,
    "out:Running cost/(Apt SEK y)": 11888,
    "out:Running Cost over RSP/MSEK": 3.881,
    "out:LCP/MSEK": 2.991,
    "out:ROI% old": 17.73,
    "out:Payback discounted": 7,
    "out:Atemp": 5143,
    "out:Umean": 0.42,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 50,
    "out:Energy use kWh/m2": 50,
    "out:Energy savings %": 276,
    "out:Op energy cost/MSEK": 27,
    "out:El price/MSEK": 24,
    "out:Heating price/MSEK": 2,
    "out:DH+ DHW/kWh/m2": 7,
    "out:El bought/kWh/m2": 3307.2,
    "out:Return %": 16,
    "out:Return/kSEK/y": 1965,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 2958000,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 147900,
    "out:EAHP (replacement cost)": 2204400,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 39.12,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 61.265983,
    "out:PV (m2 panels)": 321,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 57484.06,
    "out:PV (kWh sold)": 3036.49,
    "out:PV (% sold (El))": 5.28,
    "out:PV (kWh self-consumed)": 54447.57,
    "out:PV (ratio sold/self-consumed)": 0.06,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 848595,
    "out:EL kWh savings": -140690,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 536919,
    "out:DH kr savings": 772221,
    "out:El kr savings": -239174,
    "out:El kr sold": 3872,
    "out:El kr saved": 92561,
    "out:El kr return": 96432,
    "out:% solar/total": 67,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 35724,
    "out:% savings (space heating)": 94.58,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 4.32,
    "out:Etvv": 0,
    "out:Ef": 43.26,
    "out:heatPumpQuantity": 20,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000102110000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 5.17,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 56.54,
    "out:Electricity (inc PV)": 42.37,
    "out:Total Energy Use Pre PV": 61.54,
    "out:Total Energy Use Post PV": 47.37,
    "out:Primary Energy": 78.45,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 26.75,
    "out:CO2 Operational/m2": 4.92,
    "out:Total CO2/m2": 31.66,
    "out:Total CO2 (tons)": 162.84,
    "out:Klimatpaverkan": -166.4,
    "out:Initial Cost/MSEK": 12.52625,
    "out:Running cost/(Apt SEK y)": 11086,
    "out:Running Cost over RSP/MSEK": 3.618,
    "out:LCP/MSEK": 3.125,
    "out:ROI% old": 17.85,
    "out:Payback discounted": 7,
    "out:Atemp": 5143,
    "out:Umean": 0.42,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 47,
    "out:Energy use kWh/m2": 47,
    "out:Energy savings %": 300,
    "out:Op energy cost/MSEK": 25,
    "out:El price/MSEK": 23,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 5,
    "out:El bought/kWh/m2": 3255.5,
    "out:Return %": 16,
    "out:Return/kSEK/y": 1998,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 2958000,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 147900,
    "out:EAHP (replacement cost)": 2204400,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 38.34,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 61.265983,
    "out:PV (m2 panels)": 321,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 57484.06,
    "out:PV (kWh sold)": 3252.35,
    "out:PV (% sold (El))": 5.66,
    "out:PV (kWh self-consumed)": 54231.7,
    "out:PV (ratio sold/self-consumed)": 0.06,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 858881,
    "out:EL kWh savings": -136120,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 128574.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 554325,
    "out:DH kr savings": 781582,
    "out:El kr savings": -231403,
    "out:El kr sold": 4147,
    "out:El kr saved": 92194,
    "out:El kr return": 96341,
    "out:% solar/total": 66,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 26588,
    "out:% savings (space heating)": 95.96,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 3.13,
    "out:Etvv": 0,
    "out:Ef": 42.37,
    "out:heatPumpQuantity": 20,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000102110001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 5.88,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 56.06,
    "out:Electricity (inc PV)": 42.52,
    "out:Total Energy Use Pre PV": 62.06,
    "out:Total Energy Use Post PV": 48.52,
    "out:Primary Energy": 79.28,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 27.15,
    "out:CO2 Operational/m2": 5.08,
    "out:Total CO2/m2": 32.23,
    "out:Total CO2 (tons)": 165.76,
    "out:Klimatpaverkan": -163.48,
    "out:Initial Cost/MSEK": 12.6475,
    "out:Running cost/(Apt SEK y)": 11308,
    "out:Running Cost over RSP/MSEK": 3.691,
    "out:LCP/MSEK": 2.93,
    "out:ROI% old": 17.6,
    "out:Payback discounted": 7,
    "out:Atemp": 5143,
    "out:Umean": 0.42,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 49,
    "out:Energy use kWh/m2": 49,
    "out:Energy savings %": 283.7,
    "out:Op energy cost/MSEK": 25,
    "out:El price/MSEK": 23,
    "out:Heating price/MSEK": 2,
    "out:DH+ DHW/kWh/m2": 6,
    "out:El bought/kWh/m2": 3223,
    "out:Return %": 16,
    "out:Return/kSEK/y": 1989,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 2958000,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 147900,
    "out:EAHP (replacement cost)": 2204400,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 37.86,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 61.265983,
    "out:PV (m2 panels)": 321,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 57484.06,
    "out:PV (kWh sold)": 3392.41,
    "out:PV (% sold (El))": 5.9,
    "out:PV (kWh self-consumed)": 54091.64,
    "out:PV (ratio sold/self-consumed)": 0.06,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 853738,
    "out:EL kWh savings": -136898,
    "out:Sewage heat recovery cost": 250448,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 548500,
    "out:DH kr savings": 776902,
    "out:El kr savings": -232727,
    "out:El kr sold": 4325,
    "out:El kr saved": 91956,
    "out:El kr return": 96281,
    "out:% solar/total": 66,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 30258,
    "out:% savings (space heating)": 95.41,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 3.93,
    "out:Etvv": 0,
    "out:Ef": 42.52,
    "out:heatPumpQuantity": 20,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000102110001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 4.34,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 55.21,
    "out:Electricity (inc PV)": 41.61,
    "out:Total Energy Use Pre PV": 59.21,
    "out:Total Energy Use Post PV": 45.61,
    "out:Primary Energy": 76.87,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 27.15,
    "out:CO2 Operational/m2": 4.02,
    "out:Total CO2/m2": 31.16,
    "out:Total CO2 (tons)": 160.27,
    "out:Klimatpaverkan": -168.97,
    "out:Initial Cost/MSEK": 12.77625,
    "out:Running cost/(Apt SEK y)": 10491,
    "out:Running Cost over RSP/MSEK": 3.423,
    "out:LCP/MSEK": 3.069,
    "out:ROI% old": 17.72,
    "out:Payback discounted": 7,
    "out:Atemp": 5143,
    "out:Umean": 0.42,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 46,
    "out:Energy use kWh/m2": 46,
    "out:Energy savings %": 308.7,
    "out:Op energy cost/MSEK": 23,
    "out:El price/MSEK": 22,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 4,
    "out:El bought/kWh/m2": 3166.7,
    "out:Return %": 16,
    "out:Return/kSEK/y": 2023,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 2958000,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 147900,
    "out:EAHP (replacement cost)": 2204400,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 37.01,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 61.265983,
    "out:PV (m2 panels)": 321,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 57484.06,
    "out:PV (kWh sold)": 3644.24,
    "out:PV (% sold (El))": 6.34,
    "out:PV (kWh self-consumed)": 53839.82,
    "out:PV (ratio sold/self-consumed)": 0.07,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 864024,
    "out:EL kWh savings": -132219,
    "out:Sewage heat recovery cost": 250448,
    "out:Balancing hydronic system cost": 128574.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 566136,
    "out:DH kr savings": 786262,
    "out:El kr savings": -224772,
    "out:El kr sold": 4646,
    "out:El kr saved": 91528,
    "out:El kr return": 96174,
    "out:% solar/total": 66,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 22305,
    "out:% savings (space heating)": 96.61,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 2.82,
    "out:Etvv": 0,
    "out:Ef": 41.61,
    "out:heatPumpQuantity": 20,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000102120000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 6.95,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 57.32,
    "out:Electricity (inc PV)": 38.97,
    "out:Total Energy Use Pre PV": 64.32,
    "out:Total Energy Use Post PV": 45.97,
    "out:Primary Energy": 73.17,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 39.1,
    "out:CO2 Operational/m2": -27.3,
    "out:Total CO2/m2": 11.8,
    "out:Total CO2 (tons)": 60.7,
    "out:Klimatpaverkan": -268.54,
    "out:Initial Cost/MSEK": 13.4675,
    "out:Running cost/(Apt SEK y)": 9900,
    "out:Running Cost over RSP/MSEK": 3.245,
    "out:LCP/MSEK": 2.557,
    "out:ROI% old": 17,
    "out:Payback discounted": 7,
    "out:Atemp": 5143,
    "out:Umean": 0.42,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 46,
    "out:Energy use kWh/m2": 46,
    "out:Energy savings %": 308.7,
    "out:Op energy cost/MSEK": 23,
    "out:El price/MSEK": 20,
    "out:Heating price/MSEK": 2,
    "out:DH+ DHW/kWh/m2": 7,
    "out:El bought/kWh/m2": 2972.5,
    "out:Return %": 15,
    "out:Return/kSEK/y": 2048,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 2958000,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 147900,
    "out:EAHP (replacement cost)": 2204400,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 39.12,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.33,
    "out:PV (kWp installed)": 122.531965,
    "out:PV (m2 panels)": 643,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 114968.11,
    "out:PV (kWh sold)": 36461.84,
    "out:PV (% sold (El))": 31.71,
    "out:PV (kWh self-consumed)": 78506.27,
    "out:PV (ratio sold/self-consumed)": 0.46,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 848595,
    "out:EL kWh savings": -118646,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 617012,
    "out:DH kr savings": 772221,
    "out:El kr savings": -201698,
    "out:El kr sold": 46489,
    "out:El kr saved": 133461,
    "out:El kr return": 179950,
    "out:% solar/total": 96,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 35724,
    "out:% savings (space heating)": 94.58,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 4.32,
    "out:Etvv": 0,
    "out:Ef": 38.97,
    "out:heatPumpQuantity": 20,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000102120000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 5.17,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 56.54,
    "out:Electricity (inc PV)": 38.18,
    "out:Total Energy Use Pre PV": 61.54,
    "out:Total Energy Use Post PV": 43.18,
    "out:Primary Energy": 70.92,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 39.1,
    "out:CO2 Operational/m2": -28.75,
    "out:Total CO2/m2": 10.35,
    "out:Total CO2 (tons)": 53.24,
    "out:Klimatpaverkan": -276,
    "out:Initial Cost/MSEK": 13.59625,
    "out:Running cost/(Apt SEK y)": 9102,
    "out:Running Cost over RSP/MSEK": 2.983,
    "out:LCP/MSEK": 2.69,
    "out:ROI% old": 17.11,
    "out:Payback discounted": 7,
    "out:Atemp": 5143,
    "out:Umean": 0.42,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 43,
    "out:Energy use kWh/m2": 43,
    "out:Energy savings %": 337.2,
    "out:Op energy cost/MSEK": 21,
    "out:El price/MSEK": 19,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 5,
    "out:El bought/kWh/m2": 2926.7,
    "out:Return %": 15,
    "out:Return/kSEK/y": 2082,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 2958000,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 147900,
    "out:EAHP (replacement cost)": 2204400,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 38.34,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.33,
    "out:PV (kWp installed)": 122.531965,
    "out:PV (m2 panels)": 643,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 114968.11,
    "out:PV (kWh sold)": 37106.55,
    "out:PV (% sold (El))": 32.28,
    "out:PV (kWh self-consumed)": 77861.56,
    "out:PV (ratio sold/self-consumed)": 0.48,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 858881,
    "out:EL kWh savings": -114582,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 128574.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 634103,
    "out:DH kr savings": 781582,
    "out:El kr savings": -194790,
    "out:El kr sold": 47311,
    "out:El kr saved": 132365,
    "out:El kr return": 179676,
    "out:% solar/total": 95,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 26588,
    "out:% savings (space heating)": 95.96,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 3.13,
    "out:Etvv": 0,
    "out:Ef": 38.18,
    "out:heatPumpQuantity": 20,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000102120001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 5.88,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 56.06,
    "out:Electricity (inc PV)": 38.31,
    "out:Total Energy Use Pre PV": 62.06,
    "out:Total Energy Use Post PV": 44.31,
    "out:Primary Energy": 71.71,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 39.5,
    "out:CO2 Operational/m2": -28.85,
    "out:Total CO2/m2": 10.65,
    "out:Total CO2 (tons)": 54.78,
    "out:Klimatpaverkan": -274.46,
    "out:Initial Cost/MSEK": 13.7175,
    "out:Running cost/(Apt SEK y)": 9326,
    "out:Running Cost over RSP/MSEK": 3.057,
    "out:LCP/MSEK": 2.494,
    "out:ROI% old": 16.88,
    "out:Payback discounted": 7,
    "out:Atemp": 5143,
    "out:Umean": 0.42,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 44,
    "out:Energy use kWh/m2": 44,
    "out:Energy savings %": 327.3,
    "out:Op energy cost/MSEK": 21,
    "out:El price/MSEK": 19,
    "out:Heating price/MSEK": 2,
    "out:DH+ DHW/kWh/m2": 6,
    "out:El bought/kWh/m2": 2898,
    "out:Return %": 15,
    "out:Return/kSEK/y": 2072,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 2958000,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 147900,
    "out:EAHP (replacement cost)": 2204400,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 37.86,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.33,
    "out:PV (kWp installed)": 122.531965,
    "out:PV (m2 panels)": 643,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 114968.11,
    "out:PV (kWh sold)": 37515.12,
    "out:PV (% sold (El))": 32.63,
    "out:PV (kWh self-consumed)": 77452.99,
    "out:PV (ratio sold/self-consumed)": 0.48,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 853738,
    "out:EL kWh savings": -115275,
    "out:Sewage heat recovery cost": 250448,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 628766,
    "out:DH kr savings": 776902,
    "out:El kr savings": -195967,
    "out:El kr sold": 47832,
    "out:El kr saved": 131670,
    "out:El kr return": 179502,
    "out:% solar/total": 95,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 30258,
    "out:% savings (space heating)": 95.41,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 3.93,
    "out:Etvv": 0,
    "out:Ef": 38.31,
    "out:heatPumpQuantity": 20,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000102120001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 4.34,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 55.21,
    "out:Electricity (inc PV)": 37.5,
    "out:Total Energy Use Pre PV": 59.21,
    "out:Total Energy Use Post PV": 41.5,
    "out:Primary Energy": 69.48,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 39.5,
    "out:CO2 Operational/m2": -30.37,
    "out:Total CO2/m2": 9.13,
    "out:Total CO2 (tons)": 46.94,
    "out:Klimatpaverkan": -282.3,
    "out:Initial Cost/MSEK": 13.84625,
    "out:Running cost/(Apt SEK y)": 8514,
    "out:Running Cost over RSP/MSEK": 2.791,
    "out:LCP/MSEK": 2.632,
    "out:ROI% old": 17,
    "out:Payback discounted": 7,
    "out:Atemp": 5143,
    "out:Umean": 0.42,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 42,
    "out:Energy use kWh/m2": 42,
    "out:Energy savings %": 347.6,
    "out:Op energy cost/MSEK": 19,
    "out:El price/MSEK": 18,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 4,
    "out:El bought/kWh/m2": 2848.2,
    "out:Return %": 15,
    "out:Return/kSEK/y": 2106,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 2958000,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 147900,
    "out:EAHP (replacement cost)": 2204400,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 37.01,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.33,
    "out:PV (kWp installed)": 122.531965,
    "out:PV (m2 panels)": 643,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 114968.11,
    "out:PV (kWh sold)": 38228.53,
    "out:PV (% sold (El))": 33.25,
    "out:PV (kWh self-consumed)": 76739.58,
    "out:PV (ratio sold/self-consumed)": 0.5,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 864024,
    "out:EL kWh savings": -111110,
    "out:Sewage heat recovery cost": 250448,
    "out:Balancing hydronic system cost": 128574.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 646116,
    "out:DH kr savings": 786262,
    "out:El kr savings": -188887,
    "out:El kr sold": 48741,
    "out:El kr saved": 130457,
    "out:El kr return": 179199,
    "out:% solar/total": 94,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 22305,
    "out:% savings (space heating)": 96.61,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 2.82,
    "out:Etvv": 0,
    "out:Ef": 37.5,
    "out:heatPumpQuantity": 20,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000200000000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 97.83,
    "out:DHW (DH)": 43.5,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 15.9,
    "out:Total Energy Use Pre PV": 156.9,
    "out:Total Energy Use Post PV": 156.9,
    "out:Primary Energy": 114.6,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 16.94,
    "out:CO2 Operational/m2": 52.86,
    "out:Total CO2/m2": 69.8,
    "out:Total CO2 (tons)": 358.99,
    "out:Klimatpaverkan": 29.75,
    "out:Initial Cost/MSEK": 6.1005,
    "out:Running cost/(Apt SEK y)": 48688,
    "out:Running Cost over RSP/MSEK": 15.99,
    "out:LCP/MSEK": -2.821,
    "out:ROI% old": 7.68,
    "out:Payback discounted": 16,
    "out:Atemp": 5143,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "F",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 157,
    "out:Energy use kWh/m2": 157,
    "out:Energy savings %": 19.7,
    "out:Op energy cost/MSEK": 117,
    "out:El price/MSEK": 70,
    "out:Heating price/MSEK": 40,
    "out:DH+ DHW/kWh/m2": 141,
    "out:El bought/kWh/m2": 1113,
    "out:Return %": 7,
    "out:Return/kSEK/y": 419,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 2,
    "out:Payback Time": 50,
    "out:DH kWh savings": 159433,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 145084,
    "out:DH kr savings": 145084,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 503135,
    "out:% savings (space heating)": 23.6,
    "out:Total_Water Heating (DH)": 223720,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 97.83,
    "out:Etvv": 25,
    "out:Ef": 15.9,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z01000200000000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 93.66,
    "out:DHW (DH)": 43.5,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 15.9,
    "out:Total Energy Use Pre PV": 152.9,
    "out:Total Energy Use Post PV": 152.9,
    "out:Primary Energy": 111.68,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 16.94,
    "out:CO2 Operational/m2": 51.42,
    "out:Total CO2/m2": 68.36,
    "out:Total CO2 (tons)": 351.58,
    "out:Klimatpaverkan": 22.34,
    "out:Initial Cost/MSEK": 6.229125,
    "out:Running cost/(Apt SEK y)": 47400,
    "out:Running Cost over RSP/MSEK": 15.567,
    "out:LCP/MSEK": -2.527,
    "out:ROI% old": 8.49,
    "out:Payback discounted": 14,
    "out:Atemp": 5143,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "F",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 153,
    "out:Energy use kWh/m2": 153,
    "out:Energy savings %": 22.9,
    "out:Op energy cost/MSEK": 114,
    "out:El price/MSEK": 68,
    "out:Heating price/MSEK": 39,
    "out:DH+ DHW/kWh/m2": 137,
    "out:El bought/kWh/m2": 1113,
    "out:Return %": 8,
    "out:Return/kSEK/y": 473,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 180005,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 128574.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 163805,
    "out:DH kr savings": 163805,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 481668,
    "out:% savings (space heating)": 26.86,
    "out:Total_Water Heating (DH)": 223720,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 93.66,
    "out:Etvv": 25,
    "out:Ef": 15.9,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z01000200000001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 97.83,
    "out:DHW (DH)": 36.98,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 15.9,
    "out:Total Energy Use Pre PV": 150.9,
    "out:Total Energy Use Post PV": 150.9,
    "out:Primary Energy": 111.98,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 17.35,
    "out:CO2 Operational/m2": 50.7,
    "out:Total CO2/m2": 68.04,
    "out:Total CO2 (tons)": 349.95,
    "out:Klimatpaverkan": 20.71,
    "out:Initial Cost/MSEK": 6.351,
    "out:Running cost/(Apt SEK y)": 46757,
    "out:Running Cost over RSP/MSEK": 15.355,
    "out:LCP/MSEK": -2.437,
    "out:ROI% old": 8.8,
    "out:Payback discounted": 14,
    "out:Atemp": 5143,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "F",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 151,
    "out:Energy use kWh/m2": 151,
    "out:Energy savings %": 24.5,
    "out:Op energy cost/MSEK": 113,
    "out:El price/MSEK": 67,
    "out:Heating price/MSEK": 39,
    "out:DH+ DHW/kWh/m2": 135,
    "out:El bought/kWh/m2": 1113,
    "out:Return %": 8,
    "out:Return/kSEK/y": 500,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 190291,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 250448,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 173165,
    "out:DH kr savings": 173165,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 503135,
    "out:% savings (space heating)": 23.6,
    "out:Total_Water Heating (DH)": 190162,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 97.83,
    "out:Etvv": 21.25,
    "out:Ef": 15.9,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z01000200000001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 93.66,
    "out:DHW (DH)": 36.98,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 15.9,
    "out:Total Energy Use Pre PV": 146.9,
    "out:Total Energy Use Post PV": 146.9,
    "out:Primary Energy": 109.05,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 17.35,
    "out:CO2 Operational/m2": 49.26,
    "out:Total CO2/m2": 66.6,
    "out:Total CO2 (tons)": 342.54,
    "out:Klimatpaverkan": 13.3,
    "out:Initial Cost/MSEK": 6.4795,
    "out:Running cost/(Apt SEK y)": 45470,
    "out:Running Cost over RSP/MSEK": 14.932,
    "out:LCP/MSEK": -2.142,
    "out:ROI% old": 9.56,
    "out:Payback discounted": 13,
    "out:Atemp": 5143,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "F",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 147,
    "out:Energy use kWh/m2": 147,
    "out:Energy savings %": 27.9,
    "out:Op energy cost/MSEK": 109,
    "out:El price/MSEK": 66,
    "out:Heating price/MSEK": 38,
    "out:DH+ DHW/kWh/m2": 131,
    "out:El bought/kWh/m2": 1113,
    "out:Return %": 9,
    "out:Return/kSEK/y": 554,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 210863,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 250448,
    "out:Balancing hydronic system cost": 128574.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 191885,
    "out:DH kr savings": 191885,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 481668,
    "out:% savings (space heating)": 26.86,
    "out:Total_Water Heating (DH)": 190162,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 93.66,
    "out:Etvv": 21.25,
    "out:Ef": 15.9,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z01000200010000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 97.83,
    "out:DHW (DH)": 43.5,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 10.73,
    "out:Total Energy Use Pre PV": 156.9,
    "out:Total Energy Use Post PV": 151.73,
    "out:Primary Energy": 105.29,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 29.3,
    "out:CO2 Operational/m2": 21.86,
    "out:Total CO2/m2": 51.16,
    "out:Total CO2 (tons)": 263.11,
    "out:Klimatpaverkan": -66.13,
    "out:Initial Cost/MSEK": 7.17025,
    "out:Running cost/(Apt SEK y)": 46674,
    "out:Running Cost over RSP/MSEK": 15.346,
    "out:LCP/MSEK": -3.247,
    "out:ROI% old": 7.82,
    "out:Payback discounted": 16,
    "out:Atemp": 5143,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "F",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 152,
    "out:Energy use kWh/m2": 152,
    "out:Energy savings %": 23.7,
    "out:Op energy cost/MSEK": 113,
    "out:El price/MSEK": 66,
    "out:Heating price/MSEK": 40,
    "out:DH+ DHW/kWh/m2": 141,
    "out:El bought/kWh/m2": 758.1,
    "out:Return %": 7,
    "out:Return/kSEK/y": 504,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 61.265983,
    "out:PV (m2 panels)": 321,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 57484.06,
    "out:PV (kWh sold)": 30886.05,
    "out:PV (% sold (El))": 53.73,
    "out:PV (kWh self-consumed)": 26598,
    "out:PV (ratio sold/self-consumed)": 1.16,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 159433,
    "out:EL kWh savings": 26598,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 229680,
    "out:DH kr savings": 145084,
    "out:El kr savings": 45217,
    "out:El kr sold": 39380,
    "out:El kr saved": 45217,
    "out:El kr return": 84596,
    "out:% solar/total": 33,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 503135,
    "out:% savings (space heating)": 23.6,
    "out:Total_Water Heating (DH)": 223720,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 97.83,
    "out:Etvv": 25,
    "out:Ef": 10.73,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z01000200010000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 93.66,
    "out:DHW (DH)": 43.5,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 10.73,
    "out:Total Energy Use Pre PV": 152.9,
    "out:Total Energy Use Post PV": 147.73,
    "out:Primary Energy": 102.37,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 29.3,
    "out:CO2 Operational/m2": 20.42,
    "out:Total CO2/m2": 49.72,
    "out:Total CO2 (tons)": 255.71,
    "out:Klimatpaverkan": -73.53,
    "out:Initial Cost/MSEK": 7.298875,
    "out:Running cost/(Apt SEK y)": 45386,
    "out:Running Cost over RSP/MSEK": 14.923,
    "out:LCP/MSEK": -2.953,
    "out:ROI% old": 8.51,
    "out:Payback discounted": 14,
    "out:Atemp": 5143,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "F",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 148,
    "out:Energy use kWh/m2": 148,
    "out:Energy savings %": 27,
    "out:Op energy cost/MSEK": 110,
    "out:El price/MSEK": 64,
    "out:Heating price/MSEK": 39,
    "out:DH+ DHW/kWh/m2": 137,
    "out:El bought/kWh/m2": 758.1,
    "out:Return %": 8,
    "out:Return/kSEK/y": 558,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 61.265983,
    "out:PV (m2 panels)": 321,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 57484.06,
    "out:PV (kWh sold)": 30886.05,
    "out:PV (% sold (El))": 53.73,
    "out:PV (kWh self-consumed)": 26598,
    "out:PV (ratio sold/self-consumed)": 1.16,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 180005,
    "out:EL kWh savings": 26598,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 128574.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 248401,
    "out:DH kr savings": 163805,
    "out:El kr savings": 45217,
    "out:El kr sold": 39380,
    "out:El kr saved": 45217,
    "out:El kr return": 84596,
    "out:% solar/total": 33,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 481668,
    "out:% savings (space heating)": 26.86,
    "out:Total_Water Heating (DH)": 223720,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 93.66,
    "out:Etvv": 25,
    "out:Ef": 10.73,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z01000200010001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 97.83,
    "out:DHW (DH)": 36.98,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 10.73,
    "out:Total Energy Use Pre PV": 150.9,
    "out:Total Energy Use Post PV": 145.73,
    "out:Primary Energy": 102.67,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 29.7,
    "out:CO2 Operational/m2": 19.7,
    "out:Total CO2/m2": 49.4,
    "out:Total CO2 (tons)": 254.07,
    "out:Klimatpaverkan": -75.17,
    "out:Initial Cost/MSEK": 7.42075,
    "out:Running cost/(Apt SEK y)": 44743,
    "out:Running Cost over RSP/MSEK": 14.711,
    "out:LCP/MSEK": -2.863,
    "out:ROI% old": 8.77,
    "out:Payback discounted": 14,
    "out:Atemp": 5143,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "F",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 146,
    "out:Energy use kWh/m2": 146,
    "out:Energy savings %": 28.8,
    "out:Op energy cost/MSEK": 109,
    "out:El price/MSEK": 63,
    "out:Heating price/MSEK": 39,
    "out:DH+ DHW/kWh/m2": 135,
    "out:El bought/kWh/m2": 758.1,
    "out:Return %": 8,
    "out:Return/kSEK/y": 585,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 61.265983,
    "out:PV (m2 panels)": 321,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 57484.06,
    "out:PV (kWh sold)": 30886.05,
    "out:PV (% sold (El))": 53.73,
    "out:PV (kWh self-consumed)": 26598,
    "out:PV (ratio sold/self-consumed)": 1.16,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 190291,
    "out:EL kWh savings": 26598,
    "out:Sewage heat recovery cost": 250448,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 257761,
    "out:DH kr savings": 173165,
    "out:El kr savings": 45217,
    "out:El kr sold": 39380,
    "out:El kr saved": 45217,
    "out:El kr return": 84596,
    "out:% solar/total": 33,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 503135,
    "out:% savings (space heating)": 23.6,
    "out:Total_Water Heating (DH)": 190162,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 97.83,
    "out:Etvv": 21.25,
    "out:Ef": 10.73,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z01000200010001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 93.66,
    "out:DHW (DH)": 36.98,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 10.73,
    "out:Total Energy Use Pre PV": 146.9,
    "out:Total Energy Use Post PV": 141.73,
    "out:Primary Energy": 99.74,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 29.7,
    "out:CO2 Operational/m2": 18.26,
    "out:Total CO2/m2": 47.96,
    "out:Total CO2 (tons)": 246.67,
    "out:Klimatpaverkan": -82.57,
    "out:Initial Cost/MSEK": 7.549375,
    "out:Running cost/(Apt SEK y)": 43455,
    "out:Running Cost over RSP/MSEK": 14.288,
    "out:LCP/MSEK": -2.568,
    "out:ROI% old": 9.43,
    "out:Payback discounted": 13,
    "out:Atemp": 5143,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "F",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 142,
    "out:Energy use kWh/m2": 142,
    "out:Energy savings %": 32.4,
    "out:Op energy cost/MSEK": 105,
    "out:El price/MSEK": 62,
    "out:Heating price/MSEK": 38,
    "out:DH+ DHW/kWh/m2": 131,
    "out:El bought/kWh/m2": 758.1,
    "out:Return %": 8,
    "out:Return/kSEK/y": 639,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 61.265983,
    "out:PV (m2 panels)": 321,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 57484.06,
    "out:PV (kWh sold)": 30886.05,
    "out:PV (% sold (El))": 53.73,
    "out:PV (kWh self-consumed)": 26598,
    "out:PV (ratio sold/self-consumed)": 1.16,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 210863,
    "out:EL kWh savings": 26598,
    "out:Sewage heat recovery cost": 250448,
    "out:Balancing hydronic system cost": 128574.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 276482,
    "out:DH kr savings": 191885,
    "out:El kr savings": 45217,
    "out:El kr sold": 39380,
    "out:El kr saved": 45217,
    "out:El kr return": 84596,
    "out:% solar/total": 33,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 481668,
    "out:% savings (space heating)": 26.86,
    "out:Total_Water Heating (DH)": 190162,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 93.66,
    "out:Etvv": 21.25,
    "out:Ef": 10.73,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z01000200020000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 97.83,
    "out:DHW (DH)": 43.5,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 9.88,
    "out:Total Energy Use Pre PV": 156.9,
    "out:Total Energy Use Post PV": 150.88,
    "out:Primary Energy": 103.76,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 41.65,
    "out:CO2 Operational/m2": -30.82,
    "out:Total CO2/m2": 10.83,
    "out:Total CO2 (tons)": 55.71,
    "out:Klimatpaverkan": -273.53,
    "out:Initial Cost/MSEK": 8.240125,
    "out:Running cost/(Apt SEK y)": 44884,
    "out:Running Cost over RSP/MSEK": 14.775,
    "out:LCP/MSEK": -3.746,
    "out:ROI% old": 7.79,
    "out:Payback discounted": 16,
    "out:Atemp": 5143,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "F",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 151,
    "out:Energy use kWh/m2": 151,
    "out:Energy savings %": 24.5,
    "out:Op energy cost/MSEK": 110,
    "out:El price/MSEK": 63,
    "out:Heating price/MSEK": 40,
    "out:DH+ DHW/kWh/m2": 141,
    "out:El bought/kWh/m2": 696.7,
    "out:Return %": 7,
    "out:Return/kSEK/y": 579,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.33,
    "out:PV (kWp installed)": 122.531965,
    "out:PV (m2 panels)": 643,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 114968.11,
    "out:PV (kWh sold)": 84004.99,
    "out:PV (% sold (El))": 73.07,
    "out:PV (kWh self-consumed)": 30963.12,
    "out:PV (ratio sold/self-consumed)": 2.71,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 159433,
    "out:EL kWh savings": 30963,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 304828,
    "out:DH kr savings": 145084,
    "out:El kr savings": 52637,
    "out:El kr sold": 107106,
    "out:El kr saved": 52637,
    "out:El kr return": 159744,
    "out:% solar/total": 38,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 503135,
    "out:% savings (space heating)": 23.6,
    "out:Total_Water Heating (DH)": 223720,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 97.83,
    "out:Etvv": 25,
    "out:Ef": 9.88,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z01000200020000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 93.66,
    "out:DHW (DH)": 43.5,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 9.88,
    "out:Total Energy Use Pre PV": 152.9,
    "out:Total Energy Use Post PV": 146.88,
    "out:Primary Energy": 100.84,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 41.65,
    "out:CO2 Operational/m2": -32.26,
    "out:Total CO2/m2": 9.39,
    "out:Total CO2 (tons)": 48.31,
    "out:Klimatpaverkan": -280.93,
    "out:Initial Cost/MSEK": 8.368625,
    "out:Running cost/(Apt SEK y)": 43597,
    "out:Running Cost over RSP/MSEK": 14.351,
    "out:LCP/MSEK": -3.451,
    "out:ROI% old": 8.39,
    "out:Payback discounted": 14,
    "out:Atemp": 5143,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "F",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 147,
    "out:Energy use kWh/m2": 147,
    "out:Energy savings %": 27.9,
    "out:Op energy cost/MSEK": 107,
    "out:El price/MSEK": 61,
    "out:Heating price/MSEK": 39,
    "out:DH+ DHW/kWh/m2": 137,
    "out:El bought/kWh/m2": 696.7,
    "out:Return %": 8,
    "out:Return/kSEK/y": 633,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.33,
    "out:PV (kWp installed)": 122.531965,
    "out:PV (m2 panels)": 643,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 114968.11,
    "out:PV (kWh sold)": 84004.99,
    "out:PV (% sold (El))": 73.07,
    "out:PV (kWh self-consumed)": 30963.12,
    "out:PV (ratio sold/self-consumed)": 2.71,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 180005,
    "out:EL kWh savings": 30963,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 128574.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 323548,
    "out:DH kr savings": 163805,
    "out:El kr savings": 52637,
    "out:El kr sold": 107106,
    "out:El kr saved": 52637,
    "out:El kr return": 159744,
    "out:% solar/total": 38,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 481668,
    "out:% savings (space heating)": 26.86,
    "out:Total_Water Heating (DH)": 223720,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 93.66,
    "out:Etvv": 25,
    "out:Ef": 9.88,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z01000200020001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 97.83,
    "out:DHW (DH)": 36.98,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 9.88,
    "out:Total Energy Use Pre PV": 150.9,
    "out:Total Energy Use Post PV": 144.88,
    "out:Primary Energy": 101.14,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 42.05,
    "out:CO2 Operational/m2": -32.98,
    "out:Total CO2/m2": 9.07,
    "out:Total CO2 (tons)": 46.67,
    "out:Klimatpaverkan": -282.57,
    "out:Initial Cost/MSEK": 8.4905,
    "out:Running cost/(Apt SEK y)": 42953,
    "out:Running Cost over RSP/MSEK": 14.14,
    "out:LCP/MSEK": -3.361,
    "out:ROI% old": 8.63,
    "out:Payback discounted": 14,
    "out:Atemp": 5143,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "F",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 145,
    "out:Energy use kWh/m2": 145,
    "out:Energy savings %": 29.7,
    "out:Op energy cost/MSEK": 105,
    "out:El price/MSEK": 60,
    "out:Heating price/MSEK": 39,
    "out:DH+ DHW/kWh/m2": 135,
    "out:El bought/kWh/m2": 696.7,
    "out:Return %": 8,
    "out:Return/kSEK/y": 660,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.33,
    "out:PV (kWp installed)": 122.531965,
    "out:PV (m2 panels)": 643,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 114968.11,
    "out:PV (kWh sold)": 84004.99,
    "out:PV (% sold (El))": 73.07,
    "out:PV (kWh self-consumed)": 30963.12,
    "out:PV (ratio sold/self-consumed)": 2.71,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 190291,
    "out:EL kWh savings": 30963,
    "out:Sewage heat recovery cost": 250448,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 332908,
    "out:DH kr savings": 173165,
    "out:El kr savings": 52637,
    "out:El kr sold": 107106,
    "out:El kr saved": 52637,
    "out:El kr return": 159744,
    "out:% solar/total": 38,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 503135,
    "out:% savings (space heating)": 23.6,
    "out:Total_Water Heating (DH)": 190162,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 97.83,
    "out:Etvv": 21.25,
    "out:Ef": 9.88,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z01000200020001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 93.66,
    "out:DHW (DH)": 36.98,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 9.88,
    "out:Total Energy Use Pre PV": 146.9,
    "out:Total Energy Use Post PV": 140.88,
    "out:Primary Energy": 98.22,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 42.05,
    "out:CO2 Operational/m2": -34.42,
    "out:Total CO2/m2": 7.63,
    "out:Total CO2 (tons)": 39.27,
    "out:Klimatpaverkan": -289.97,
    "out:Initial Cost/MSEK": 8.619125,
    "out:Running cost/(Apt SEK y)": 41666,
    "out:Running Cost over RSP/MSEK": 13.717,
    "out:LCP/MSEK": -3.067,
    "out:ROI% old": 9.2,
    "out:Payback discounted": 13,
    "out:Atemp": 5143,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "F",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 141,
    "out:Energy use kWh/m2": 141,
    "out:Energy savings %": 33.3,
    "out:Op energy cost/MSEK": 102,
    "out:El price/MSEK": 58,
    "out:Heating price/MSEK": 38,
    "out:DH+ DHW/kWh/m2": 131,
    "out:El bought/kWh/m2": 696.7,
    "out:Return %": 8,
    "out:Return/kSEK/y": 714,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.33,
    "out:PV (kWp installed)": 122.531965,
    "out:PV (m2 panels)": 643,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 114968.11,
    "out:PV (kWh sold)": 84004.99,
    "out:PV (% sold (El))": 73.07,
    "out:PV (kWh self-consumed)": 30963.12,
    "out:PV (ratio sold/self-consumed)": 2.71,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 210863,
    "out:EL kWh savings": 30963,
    "out:Sewage heat recovery cost": 250448,
    "out:Balancing hydronic system cost": 128574.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 351629,
    "out:DH kr savings": 191885,
    "out:El kr savings": 52637,
    "out:El kr sold": 107106,
    "out:El kr saved": 52637,
    "out:El kr return": 159744,
    "out:% solar/total": 38,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 481668,
    "out:% savings (space heating)": 26.86,
    "out:Total_Water Heating (DH)": 190162,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 93.66,
    "out:Etvv": 21.25,
    "out:Ef": 9.88,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z01000200100000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 97.83,
    "out:DHW (DH)": 34.8,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 15.9,
    "out:Total Energy Use Pre PV": 148.9,
    "out:Total Energy Use Post PV": 148.9,
    "out:Primary Energy": 111.1,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 16.94,
    "out:CO2 Operational/m2": 49.98,
    "out:Total CO2/m2": 66.92,
    "out:Total CO2 (tons)": 344.18,
    "out:Klimatpaverkan": 14.94,
    "out:Initial Cost/MSEK": 6.211375,
    "out:Running cost/(Apt SEK y)": 46113,
    "out:Running Cost over RSP/MSEK": 15.144,
    "out:LCP/MSEK": -2.086,
    "out:ROI% old": 9.49,
    "out:Payback discounted": 13,
    "out:Atemp": 5143,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "F",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 149,
    "out:Energy use kWh/m2": 149,
    "out:Energy savings %": 26.2,
    "out:Op energy cost/MSEK": 111,
    "out:El price/MSEK": 67,
    "out:Heating price/MSEK": 38,
    "out:DH+ DHW/kWh/m2": 133,
    "out:El bought/kWh/m2": 1113,
    "out:Return %": 8,
    "out:Return/kSEK/y": 527,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 200577,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 182525,
    "out:DH kr savings": 182525,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 503135,
    "out:% savings (space heating)": 23.6,
    "out:Total_Water Heating (DH)": 178976,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 97.83,
    "out:Etvv": 20,
    "out:Ef": 15.9,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z01000200100000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 93.66,
    "out:DHW (DH)": 34.8,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 15.9,
    "out:Total Energy Use Pre PV": 143.9,
    "out:Total Energy Use Post PV": 143.9,
    "out:Primary Energy": 108.18,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 16.94,
    "out:CO2 Operational/m2": 48.18,
    "out:Total CO2/m2": 65.12,
    "out:Total CO2 (tons)": 334.92,
    "out:Klimatpaverkan": 5.68,
    "out:Initial Cost/MSEK": 6.339875,
    "out:Running cost/(Apt SEK y)": 44504,
    "out:Running Cost over RSP/MSEK": 14.615,
    "out:LCP/MSEK": -1.686,
    "out:ROI% old": 10.49,
    "out:Payback discounted": 11,
    "out:Atemp": 5143,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "F",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 144,
    "out:Energy use kWh/m2": 144,
    "out:Energy savings %": 30.6,
    "out:Op energy cost/MSEK": 107,
    "out:El price/MSEK": 64,
    "out:Heating price/MSEK": 37,
    "out:DH+ DHW/kWh/m2": 128,
    "out:El bought/kWh/m2": 1113,
    "out:Return %": 9,
    "out:Return/kSEK/y": 595,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 226292,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 128574.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 205926,
    "out:DH kr savings": 205926,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 481668,
    "out:% savings (space heating)": 26.86,
    "out:Total_Water Heating (DH)": 178976,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 93.66,
    "out:Etvv": 20,
    "out:Ef": 15.9,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z01000200100001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 97.83,
    "out:DHW (DH)": 29.58,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 15.9,
    "out:Total Energy Use Pre PV": 142.9,
    "out:Total Energy Use Post PV": 142.9,
    "out:Primary Energy": 109,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 17.35,
    "out:CO2 Operational/m2": 47.82,
    "out:Total CO2/m2": 65.16,
    "out:Total CO2 (tons)": 335.13,
    "out:Klimatpaverkan": 5.89,
    "out:Initial Cost/MSEK": 6.46175,
    "out:Running cost/(Apt SEK y)": 44182,
    "out:Running Cost over RSP/MSEK": 14.509,
    "out:LCP/MSEK": -1.702,
    "out:ROI% old": 10.52,
    "out:Payback discounted": 11,
    "out:Atemp": 5143,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "F",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 143,
    "out:Energy use kWh/m2": 143,
    "out:Energy savings %": 31.5,
    "out:Op energy cost/MSEK": 106,
    "out:El price/MSEK": 64,
    "out:Heating price/MSEK": 36,
    "out:DH+ DHW/kWh/m2": 127,
    "out:El bought/kWh/m2": 1113,
    "out:Return %": 9,
    "out:Return/kSEK/y": 608,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 231435,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 250448,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 210606,
    "out:DH kr savings": 210606,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 503135,
    "out:% savings (space heating)": 23.6,
    "out:Total_Water Heating (DH)": 152130,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 97.83,
    "out:Etvv": 17,
    "out:Ef": 15.9,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z01000200100001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 93.66,
    "out:DHW (DH)": 29.58,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 15.9,
    "out:Total Energy Use Pre PV": 138.9,
    "out:Total Energy Use Post PV": 138.9,
    "out:Primary Energy": 106.08,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 17.35,
    "out:CO2 Operational/m2": 46.38,
    "out:Total CO2/m2": 63.72,
    "out:Total CO2 (tons)": 327.73,
    "out:Klimatpaverkan": -1.51,
    "out:Initial Cost/MSEK": 6.590375,
    "out:Running cost/(Apt SEK y)": 42895,
    "out:Running Cost over RSP/MSEK": 14.086,
    "out:LCP/MSEK": -1.407,
    "out:ROI% old": 11.24,
    "out:Payback discounted": 11,
    "out:Atemp": 5143,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "F",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 139,
    "out:Energy use kWh/m2": 139,
    "out:Energy savings %": 35.3,
    "out:Op energy cost/MSEK": 103,
    "out:El price/MSEK": 62,
    "out:Heating price/MSEK": 35,
    "out:DH+ DHW/kWh/m2": 123,
    "out:El bought/kWh/m2": 1113,
    "out:Return %": 10,
    "out:Return/kSEK/y": 662,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 252007,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 250448,
    "out:Balancing hydronic system cost": 128574.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 229326,
    "out:DH kr savings": 229326,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 481668,
    "out:% savings (space heating)": 26.86,
    "out:Total_Water Heating (DH)": 152130,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 93.66,
    "out:Etvv": 17,
    "out:Ef": 15.9,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z01000200110000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 97.83,
    "out:DHW (DH)": 34.8,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 10.73,
    "out:Total Energy Use Pre PV": 148.9,
    "out:Total Energy Use Post PV": 143.73,
    "out:Primary Energy": 101.79,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 29.3,
    "out:CO2 Operational/m2": 18.98,
    "out:Total CO2/m2": 48.28,
    "out:Total CO2 (tons)": 248.3,
    "out:Klimatpaverkan": -80.94,
    "out:Initial Cost/MSEK": 7.281125,
    "out:Running cost/(Apt SEK y)": 44099,
    "out:Running Cost over RSP/MSEK": 14.5,
    "out:LCP/MSEK": -2.512,
    "out:ROI% old": 9.36,
    "out:Payback discounted": 13,
    "out:Atemp": 5143,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "F",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 144,
    "out:Energy use kWh/m2": 144,
    "out:Energy savings %": 30.6,
    "out:Op energy cost/MSEK": 107,
    "out:El price/MSEK": 63,
    "out:Heating price/MSEK": 38,
    "out:DH+ DHW/kWh/m2": 133,
    "out:El bought/kWh/m2": 758.1,
    "out:Return %": 8,
    "out:Return/kSEK/y": 612,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 61.265983,
    "out:PV (m2 panels)": 321,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 57484.06,
    "out:PV (kWh sold)": 30886.05,
    "out:PV (% sold (El))": 53.73,
    "out:PV (kWh self-consumed)": 26598,
    "out:PV (ratio sold/self-consumed)": 1.16,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 200577,
    "out:EL kWh savings": 26598,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 267121,
    "out:DH kr savings": 182525,
    "out:El kr savings": 45217,
    "out:El kr sold": 39380,
    "out:El kr saved": 45217,
    "out:El kr return": 84596,
    "out:% solar/total": 33,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 503135,
    "out:% savings (space heating)": 23.6,
    "out:Total_Water Heating (DH)": 178976,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 97.83,
    "out:Etvv": 20,
    "out:Ef": 10.73,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z01000200110000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 93.66,
    "out:DHW (DH)": 34.8,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 10.73,
    "out:Total Energy Use Pre PV": 143.9,
    "out:Total Energy Use Post PV": 138.73,
    "out:Primary Energy": 98.87,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 29.3,
    "out:CO2 Operational/m2": 17.18,
    "out:Total CO2/m2": 46.48,
    "out:Total CO2 (tons)": 239.04,
    "out:Klimatpaverkan": -90.2,
    "out:Initial Cost/MSEK": 7.40975,
    "out:Running cost/(Apt SEK y)": 42490,
    "out:Running Cost over RSP/MSEK": 13.971,
    "out:LCP/MSEK": -2.112,
    "out:ROI% old": 10.21,
    "out:Payback discounted": 12,
    "out:Atemp": 5143,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "F",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 139,
    "out:Energy use kWh/m2": 139,
    "out:Energy savings %": 35.3,
    "out:Op energy cost/MSEK": 103,
    "out:El price/MSEK": 60,
    "out:Heating price/MSEK": 37,
    "out:DH+ DHW/kWh/m2": 128,
    "out:El bought/kWh/m2": 758.1,
    "out:Return %": 9,
    "out:Return/kSEK/y": 679,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 61.265983,
    "out:PV (m2 panels)": 321,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 57484.06,
    "out:PV (kWh sold)": 30886.05,
    "out:PV (% sold (El))": 53.73,
    "out:PV (kWh self-consumed)": 26598,
    "out:PV (ratio sold/self-consumed)": 1.16,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 226292,
    "out:EL kWh savings": 26598,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 128574.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 290522,
    "out:DH kr savings": 205926,
    "out:El kr savings": 45217,
    "out:El kr sold": 39380,
    "out:El kr saved": 45217,
    "out:El kr return": 84596,
    "out:% solar/total": 33,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 481668,
    "out:% savings (space heating)": 26.86,
    "out:Total_Water Heating (DH)": 178976,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 93.66,
    "out:Etvv": 20,
    "out:Ef": 10.73,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z01000200110001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 97.83,
    "out:DHW (DH)": 29.58,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 10.73,
    "out:Total Energy Use Pre PV": 142.9,
    "out:Total Energy Use Post PV": 137.73,
    "out:Primary Energy": 99.69,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 29.7,
    "out:CO2 Operational/m2": 16.82,
    "out:Total CO2/m2": 46.52,
    "out:Total CO2 (tons)": 239.26,
    "out:Klimatpaverkan": -89.98,
    "out:Initial Cost/MSEK": 7.531625,
    "out:Running cost/(Apt SEK y)": 42168,
    "out:Running Cost over RSP/MSEK": 13.865,
    "out:LCP/MSEK": -2.128,
    "out:ROI% old": 10.25,
    "out:Payback discounted": 12,
    "out:Atemp": 5143,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "F",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 138,
    "out:Energy use kWh/m2": 138,
    "out:Energy savings %": 36.2,
    "out:Op energy cost/MSEK": 102,
    "out:El price/MSEK": 60,
    "out:Heating price/MSEK": 36,
    "out:DH+ DHW/kWh/m2": 127,
    "out:El bought/kWh/m2": 758.1,
    "out:Return %": 9,
    "out:Return/kSEK/y": 693,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 61.265983,
    "out:PV (m2 panels)": 321,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 57484.06,
    "out:PV (kWh sold)": 30886.05,
    "out:PV (% sold (El))": 53.73,
    "out:PV (kWh self-consumed)": 26598,
    "out:PV (ratio sold/self-consumed)": 1.16,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 231435,
    "out:EL kWh savings": 26598,
    "out:Sewage heat recovery cost": 250448,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 295202,
    "out:DH kr savings": 210606,
    "out:El kr savings": 45217,
    "out:El kr sold": 39380,
    "out:El kr saved": 45217,
    "out:El kr return": 84596,
    "out:% solar/total": 33,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 503135,
    "out:% savings (space heating)": 23.6,
    "out:Total_Water Heating (DH)": 152130,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 97.83,
    "out:Etvv": 17,
    "out:Ef": 10.73,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z01000200110001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 93.66,
    "out:DHW (DH)": 29.58,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 10.73,
    "out:Total Energy Use Pre PV": 138.9,
    "out:Total Energy Use Post PV": 133.73,
    "out:Primary Energy": 96.77,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 29.7,
    "out:CO2 Operational/m2": 15.38,
    "out:Total CO2/m2": 45.08,
    "out:Total CO2 (tons)": 231.85,
    "out:Klimatpaverkan": -97.39,
    "out:Initial Cost/MSEK": 7.660125,
    "out:Running cost/(Apt SEK y)": 40881,
    "out:Running Cost over RSP/MSEK": 13.442,
    "out:LCP/MSEK": -1.833,
    "out:ROI% old": 10.87,
    "out:Payback discounted": 11,
    "out:Atemp": 5143,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "F",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 134,
    "out:Energy use kWh/m2": 134,
    "out:Energy savings %": 40.3,
    "out:Op energy cost/MSEK": 99,
    "out:El price/MSEK": 58,
    "out:Heating price/MSEK": 35,
    "out:DH+ DHW/kWh/m2": 123,
    "out:El bought/kWh/m2": 758.1,
    "out:Return %": 10,
    "out:Return/kSEK/y": 747,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 61.265983,
    "out:PV (m2 panels)": 321,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 57484.06,
    "out:PV (kWh sold)": 30886.05,
    "out:PV (% sold (El))": 53.73,
    "out:PV (kWh self-consumed)": 26598,
    "out:PV (ratio sold/self-consumed)": 1.16,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 252007,
    "out:EL kWh savings": 26598,
    "out:Sewage heat recovery cost": 250448,
    "out:Balancing hydronic system cost": 128574.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 313923,
    "out:DH kr savings": 229326,
    "out:El kr savings": 45217,
    "out:El kr sold": 39380,
    "out:El kr saved": 45217,
    "out:El kr return": 84596,
    "out:% solar/total": 33,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 481668,
    "out:% savings (space heating)": 26.86,
    "out:Total_Water Heating (DH)": 152130,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 93.66,
    "out:Etvv": 17,
    "out:Ef": 10.73,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z01000200120000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 97.83,
    "out:DHW (DH)": 34.8,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 9.88,
    "out:Total Energy Use Pre PV": 148.9,
    "out:Total Energy Use Post PV": 142.88,
    "out:Primary Energy": 100.26,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 41.65,
    "out:CO2 Operational/m2": -33.7,
    "out:Total CO2/m2": 7.95,
    "out:Total CO2 (tons)": 40.9,
    "out:Klimatpaverkan": -288.34,
    "out:Initial Cost/MSEK": 8.350875,
    "out:Running cost/(Apt SEK y)": 42310,
    "out:Running Cost over RSP/MSEK": 13.928,
    "out:LCP/MSEK": -3.01,
    "out:ROI% old": 9.14,
    "out:Payback discounted": 13,
    "out:Atemp": 5143,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "F",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 143,
    "out:Energy use kWh/m2": 143,
    "out:Energy savings %": 31.5,
    "out:Op energy cost/MSEK": 104,
    "out:El price/MSEK": 59,
    "out:Heating price/MSEK": 38,
    "out:DH+ DHW/kWh/m2": 133,
    "out:El bought/kWh/m2": 696.7,
    "out:Return %": 8,
    "out:Return/kSEK/y": 687,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.33,
    "out:PV (kWp installed)": 122.531965,
    "out:PV (m2 panels)": 643,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 114968.11,
    "out:PV (kWh sold)": 84004.99,
    "out:PV (% sold (El))": 73.07,
    "out:PV (kWh self-consumed)": 30963.12,
    "out:PV (ratio sold/self-consumed)": 2.71,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 200577,
    "out:EL kWh savings": 30963,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 342269,
    "out:DH kr savings": 182525,
    "out:El kr savings": 52637,
    "out:El kr sold": 107106,
    "out:El kr saved": 52637,
    "out:El kr return": 159744,
    "out:% solar/total": 38,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 503135,
    "out:% savings (space heating)": 23.6,
    "out:Total_Water Heating (DH)": 178976,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 97.83,
    "out:Etvv": 20,
    "out:Ef": 9.88,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z01000200120000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 93.66,
    "out:DHW (DH)": 34.8,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 9.88,
    "out:Total Energy Use Pre PV": 143.9,
    "out:Total Energy Use Post PV": 137.88,
    "out:Primary Energy": 97.34,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 41.65,
    "out:CO2 Operational/m2": -35.5,
    "out:Total CO2/m2": 6.15,
    "out:Total CO2 (tons)": 31.64,
    "out:Klimatpaverkan": -297.6,
    "out:Initial Cost/MSEK": 8.4795,
    "out:Running cost/(Apt SEK y)": 40701,
    "out:Running Cost over RSP/MSEK": 13.399,
    "out:LCP/MSEK": -2.61,
    "out:ROI% old": 9.89,
    "out:Payback discounted": 12,
    "out:Atemp": 5143,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "F",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 138,
    "out:Energy use kWh/m2": 138,
    "out:Energy savings %": 36.2,
    "out:Op energy cost/MSEK": 100,
    "out:El price/MSEK": 57,
    "out:Heating price/MSEK": 37,
    "out:DH+ DHW/kWh/m2": 128,
    "out:El bought/kWh/m2": 696.7,
    "out:Return %": 9,
    "out:Return/kSEK/y": 754,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.33,
    "out:PV (kWp installed)": 122.531965,
    "out:PV (m2 panels)": 643,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 114968.11,
    "out:PV (kWh sold)": 84004.99,
    "out:PV (% sold (El))": 73.07,
    "out:PV (kWh self-consumed)": 30963.12,
    "out:PV (ratio sold/self-consumed)": 2.71,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 226292,
    "out:EL kWh savings": 30963,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 128574.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 365669,
    "out:DH kr savings": 205926,
    "out:El kr savings": 52637,
    "out:El kr sold": 107106,
    "out:El kr saved": 52637,
    "out:El kr return": 159744,
    "out:% solar/total": 38,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 481668,
    "out:% savings (space heating)": 26.86,
    "out:Total_Water Heating (DH)": 178976,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 93.66,
    "out:Etvv": 20,
    "out:Ef": 9.88,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z01000200120001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 97.83,
    "out:DHW (DH)": 29.58,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 9.88,
    "out:Total Energy Use Pre PV": 142.9,
    "out:Total Energy Use Post PV": 136.88,
    "out:Primary Energy": 98.16,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 42.05,
    "out:CO2 Operational/m2": -35.86,
    "out:Total CO2/m2": 6.2,
    "out:Total CO2 (tons)": 31.86,
    "out:Klimatpaverkan": -297.38,
    "out:Initial Cost/MSEK": 8.601375,
    "out:Running cost/(Apt SEK y)": 40379,
    "out:Running Cost over RSP/MSEK": 13.294,
    "out:LCP/MSEK": -2.626,
    "out:ROI% old": 9.92,
    "out:Payback discounted": 12,
    "out:Atemp": 5143,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "F",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 137,
    "out:Energy use kWh/m2": 137,
    "out:Energy savings %": 37.2,
    "out:Op energy cost/MSEK": 99,
    "out:El price/MSEK": 56,
    "out:Heating price/MSEK": 36,
    "out:DH+ DHW/kWh/m2": 127,
    "out:El bought/kWh/m2": 696.7,
    "out:Return %": 9,
    "out:Return/kSEK/y": 768,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.33,
    "out:PV (kWp installed)": 122.531965,
    "out:PV (m2 panels)": 643,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 114968.11,
    "out:PV (kWh sold)": 84004.99,
    "out:PV (% sold (El))": 73.07,
    "out:PV (kWh self-consumed)": 30963.12,
    "out:PV (ratio sold/self-consumed)": 2.71,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 231435,
    "out:EL kWh savings": 30963,
    "out:Sewage heat recovery cost": 250448,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 370350,
    "out:DH kr savings": 210606,
    "out:El kr savings": 52637,
    "out:El kr sold": 107106,
    "out:El kr saved": 52637,
    "out:El kr return": 159744,
    "out:% solar/total": 38,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 503135,
    "out:% savings (space heating)": 23.6,
    "out:Total_Water Heating (DH)": 152130,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 97.83,
    "out:Etvv": 17,
    "out:Ef": 9.88,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z01000200120001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 93.66,
    "out:DHW (DH)": 29.58,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 9.88,
    "out:Total Energy Use Pre PV": 138.9,
    "out:Total Energy Use Post PV": 132.88,
    "out:Primary Energy": 95.24,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 42.05,
    "out:CO2 Operational/m2": -37.3,
    "out:Total CO2/m2": 4.76,
    "out:Total CO2 (tons)": 24.46,
    "out:Klimatpaverkan": -304.78,
    "out:Initial Cost/MSEK": 8.73,
    "out:Running cost/(Apt SEK y)": 39091,
    "out:Running Cost over RSP/MSEK": 12.871,
    "out:LCP/MSEK": -2.332,
    "out:ROI% old": 10.47,
    "out:Payback discounted": 12,
    "out:Atemp": 5143,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "F",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 133,
    "out:Energy use kWh/m2": 133,
    "out:Energy savings %": 41.4,
    "out:Op energy cost/MSEK": 96,
    "out:El price/MSEK": 55,
    "out:Heating price/MSEK": 35,
    "out:DH+ DHW/kWh/m2": 123,
    "out:El bought/kWh/m2": 696.7,
    "out:Return %": 9,
    "out:Return/kSEK/y": 822,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.33,
    "out:PV (kWp installed)": 122.531965,
    "out:PV (m2 panels)": 643,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 114968.11,
    "out:PV (kWh sold)": 84004.99,
    "out:PV (% sold (El))": 73.07,
    "out:PV (kWh self-consumed)": 30963.12,
    "out:PV (ratio sold/self-consumed)": 2.71,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 252007,
    "out:EL kWh savings": 30963,
    "out:Sewage heat recovery cost": 250448,
    "out:Balancing hydronic system cost": 128574.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 389070,
    "out:DH kr savings": 229326,
    "out:El kr savings": 52637,
    "out:El kr sold": 107106,
    "out:El kr saved": 52637,
    "out:El kr return": 159744,
    "out:% solar/total": 38,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 481668,
    "out:% savings (space heating)": 26.86,
    "out:Total_Water Heating (DH)": 152130,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 93.66,
    "out:Etvv": 17,
    "out:Ef": 9.88,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z01000201000000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 52.77,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 45.04,
    "out:Electricity (inc PV)": 42.77,
    "out:Total Energy Use Pre PV": 98.04,
    "out:Total Energy Use Post PV": 95.77,
    "out:Primary Energy": 106.21,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 19.8,
    "out:CO2 Operational/m2": 25.02,
    "out:Total CO2/m2": 44.82,
    "out:Total CO2 (tons)": 230.51,
    "out:Klimatpaverkan": -98.73,
    "out:Initial Cost/MSEK": 10.576375,
    "out:Running cost/(Apt SEK y)": 26432,
    "out:Running Cost over RSP/MSEK": 8.65,
    "out:LCP/MSEK": 0.042,
    "out:ROI% old": 14.34,
    "out:Payback discounted": 8,
    "out:Atemp": 5143,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 96,
    "out:Energy use kWh/m2": 96,
    "out:Energy savings %": 95.8,
    "out:Op energy cost/MSEK": 62,
    "out:El price/MSEK": 44,
    "out:Heating price/MSEK": 15,
    "out:DH+ DHW/kWh/m2": 53,
    "out:El bought/kWh/m2": 3152.5,
    "out:Return %": 13,
    "out:Return/kSEK/y": 1354,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 1479000,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 73950,
    "out:EAHP (replacement cost)": 1105400,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 26.84,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 612017,
    "out:EL kWh savings": -138169,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 322047,
    "out:DH kr savings": 556935,
    "out:El kr savings": -234888,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 271383,
    "out:% savings (space heating)": 58.79,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 41.76,
    "out:Etvv": 0,
    "out:Ef": 42.77,
    "out:heatPumpQuantity": 10,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000201000000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 48.88,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 44.95,
    "out:Electricity (inc PV)": 42.63,
    "out:Total Energy Use Pre PV": 93.95,
    "out:Total Energy Use Post PV": 91.63,
    "out:Primary Energy": 103.36,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 19.8,
    "out:CO2 Operational/m2": 23.57,
    "out:Total CO2/m2": 43.37,
    "out:Total CO2 (tons)": 223.04,
    "out:Klimatpaverkan": -106.2,
    "out:Initial Cost/MSEK": 10.705,
    "out:Running cost/(Apt SEK y)": 25126,
    "out:Running Cost over RSP/MSEK": 8.221,
    "out:LCP/MSEK": 0.343,
    "out:ROI% old": 14.74,
    "out:Payback discounted": 8,
    "out:Atemp": 5143,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 92,
    "out:Energy use kWh/m2": 92,
    "out:Energy savings %": 104.3,
    "out:Op energy cost/MSEK": 58,
    "out:El price/MSEK": 42,
    "out:Heating price/MSEK": 14,
    "out:DH+ DHW/kWh/m2": 49,
    "out:El bought/kWh/m2": 3146.4,
    "out:Return %": 13,
    "out:Return/kSEK/y": 1409,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 1479000,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 73950,
    "out:EAHP (replacement cost)": 1105400,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 26.75,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 632589,
    "out:EL kWh savings": -137461,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 128574.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 341972,
    "out:DH kr savings": 575656,
    "out:El kr savings": -233684,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 251413,
    "out:% savings (space heating)": 61.83,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 38.04,
    "out:Etvv": 0,
    "out:Ef": 42.63,
    "out:heatPumpQuantity": 10,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000201000001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 48.76,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 44.27,
    "out:Electricity (inc PV)": 42.27,
    "out:Total Energy Use Pre PV": 93.27,
    "out:Total Energy Use Post PV": 91.27,
    "out:Primary Energy": 103.84,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 20.2,
    "out:CO2 Operational/m2": 23.48,
    "out:Total CO2/m2": 43.68,
    "out:Total CO2 (tons)": 224.65,
    "out:Klimatpaverkan": -104.59,
    "out:Initial Cost/MSEK": 10.826875,
    "out:Running cost/(Apt SEK y)": 24986,
    "out:Running Cost over RSP/MSEK": 8.176,
    "out:LCP/MSEK": 0.266,
    "out:ROI% old": 14.64,
    "out:Payback discounted": 8,
    "out:Atemp": 5143,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 91,
    "out:Energy use kWh/m2": 91,
    "out:Energy savings %": 106.6,
    "out:Op energy cost/MSEK": 58,
    "out:El price/MSEK": 42,
    "out:Heating price/MSEK": 14,
    "out:DH+ DHW/kWh/m2": 49,
    "out:El bought/kWh/m2": 3099.1,
    "out:Return %": 13,
    "out:Return/kSEK/y": 1415,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 1479000,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 73950,
    "out:EAHP (replacement cost)": 1105400,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 26.07,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 632589,
    "out:EL kWh savings": -135611,
    "out:Sewage heat recovery cost": 250448,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 345117,
    "out:DH kr savings": 575656,
    "out:El kr savings": -230539,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 250787,
    "out:% savings (space heating)": 61.92,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 39.65,
    "out:Etvv": 0,
    "out:Ef": 42.27,
    "out:heatPumpQuantity": 10,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000201000001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 44.94,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 44.17,
    "out:Electricity (inc PV)": 42.12,
    "out:Total Energy Use Pre PV": 89.17,
    "out:Total Energy Use Post PV": 87.12,
    "out:Primary Energy": 100.99,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 20.2,
    "out:CO2 Operational/m2": 22.03,
    "out:Total CO2/m2": 42.23,
    "out:Total CO2 (tons)": 217.17,
    "out:Klimatpaverkan": -112.07,
    "out:Initial Cost/MSEK": 10.955375,
    "out:Running cost/(Apt SEK y)": 23677,
    "out:Running Cost over RSP/MSEK": 7.745,
    "out:LCP/MSEK": 0.568,
    "out:ROI% old": 15.03,
    "out:Payback discounted": 8,
    "out:Atemp": 5143,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 87,
    "out:Energy use kWh/m2": 87,
    "out:Energy savings %": 116.1,
    "out:Op energy cost/MSEK": 55,
    "out:El price/MSEK": 40,
    "out:Heating price/MSEK": 13,
    "out:DH+ DHW/kWh/m2": 45,
    "out:El bought/kWh/m2": 3091.7,
    "out:Return %": 13,
    "out:Return/kSEK/y": 1469,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 1479000,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 73950,
    "out:EAHP (replacement cost)": 1105400,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 25.97,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 653161,
    "out:EL kWh savings": -134838,
    "out:Sewage heat recovery cost": 250448,
    "out:Balancing hydronic system cost": 128574.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 365151,
    "out:DH kr savings": 594376,
    "out:El kr savings": -229225,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 231105,
    "out:% savings (space heating)": 64.91,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 35.97,
    "out:Etvv": 0,
    "out:Ef": 42.12,
    "out:heatPumpQuantity": 10,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000201010000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 52.77,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 45.04,
    "out:Electricity (inc PV)": 33.29,
    "out:Total Energy Use Pre PV": 98.04,
    "out:Total Energy Use Post PV": 86.29,
    "out:Primary Energy": 89.15,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 32.15,
    "out:CO2 Operational/m2": 16.57,
    "out:Total CO2/m2": 48.72,
    "out:Total CO2 (tons)": 250.56,
    "out:Klimatpaverkan": -78.68,
    "out:Initial Cost/MSEK": 11.64625,
    "out:Running cost/(Apt SEK y)": 24182,
    "out:Running Cost over RSP/MSEK": 7.931,
    "out:LCP/MSEK": -0.308,
    "out:ROI% old": 13.91,
    "out:Payback discounted": 9,
    "out:Atemp": 5143,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 86,
    "out:Energy use kWh/m2": 86,
    "out:Energy savings %": 118.6,
    "out:Op energy cost/MSEK": 57,
    "out:El price/MSEK": 39,
    "out:Heating price/MSEK": 15,
    "out:DH+ DHW/kWh/m2": 53,
    "out:El bought/kWh/m2": 2500.2,
    "out:Return %": 12,
    "out:Return/kSEK/y": 1448,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 1479000,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 73950,
    "out:EAHP (replacement cost)": 1105400,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 26.84,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 61.265983,
    "out:PV (m2 panels)": 321,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 57484.06,
    "out:PV (kWh sold)": 7596.36,
    "out:PV (% sold (El))": 13.21,
    "out:PV (kWh self-consumed)": 49887.7,
    "out:PV (ratio sold/self-consumed)": 0.15,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 612017,
    "out:EL kWh savings": -89429,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 414592,
    "out:DH kr savings": 556935,
    "out:El kr savings": -152029,
    "out:El kr sold": 9685,
    "out:El kr saved": 84809,
    "out:El kr return": 94494,
    "out:% solar/total": 61,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 271383,
    "out:% savings (space heating)": 58.79,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 41.76,
    "out:Etvv": 0,
    "out:Ef": 33.29,
    "out:heatPumpQuantity": 10,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000201010000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 48.88,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 44.95,
    "out:Electricity (inc PV)": 33.16,
    "out:Total Energy Use Pre PV": 93.95,
    "out:Total Energy Use Post PV": 82.16,
    "out:Primary Energy": 86.33,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 32.15,
    "out:CO2 Operational/m2": 15.08,
    "out:Total CO2/m2": 47.23,
    "out:Total CO2 (tons)": 242.88,
    "out:Klimatpaverkan": -86.36,
    "out:Initial Cost/MSEK": 11.77475,
    "out:Running cost/(Apt SEK y)": 22877,
    "out:Running Cost over RSP/MSEK": 7.502,
    "out:LCP/MSEK": -0.008,
    "out:ROI% old": 14.28,
    "out:Payback discounted": 9,
    "out:Atemp": 5143,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 82,
    "out:Energy use kWh/m2": 82,
    "out:Energy savings %": 129.3,
    "out:Op energy cost/MSEK": 54,
    "out:El price/MSEK": 38,
    "out:Heating price/MSEK": 14,
    "out:DH+ DHW/kWh/m2": 49,
    "out:El bought/kWh/m2": 2494.5,
    "out:Return %": 13,
    "out:Return/kSEK/y": 1503,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 1479000,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 73950,
    "out:EAHP (replacement cost)": 1105400,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 26.75,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 61.265983,
    "out:PV (m2 panels)": 321,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 57484.06,
    "out:PV (kWh sold)": 7638.86,
    "out:PV (% sold (El))": 13.29,
    "out:PV (kWh self-consumed)": 49845.19,
    "out:PV (ratio sold/self-consumed)": 0.15,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 632589,
    "out:EL kWh savings": -88793,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 128574.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 434448,
    "out:DH kr savings": 575656,
    "out:El kr savings": -150948,
    "out:El kr sold": 9740,
    "out:El kr saved": 84737,
    "out:El kr return": 94476,
    "out:% solar/total": 61,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 251413,
    "out:% savings (space heating)": 61.83,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 38.04,
    "out:Etvv": 0,
    "out:Ef": 33.16,
    "out:heatPumpQuantity": 10,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000201010001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 48.76,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 44.27,
    "out:Electricity (inc PV)": 32.84,
    "out:Total Energy Use Pre PV": 93.27,
    "out:Total Energy Use Post PV": 81.84,
    "out:Primary Energy": 86.87,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 32.55,
    "out:CO2 Operational/m2": 14.67,
    "out:Total CO2/m2": 47.22,
    "out:Total CO2 (tons)": 242.87,
    "out:Klimatpaverkan": -86.37,
    "out:Initial Cost/MSEK": 11.896625,
    "out:Running cost/(Apt SEK y)": 22740,
    "out:Running Cost over RSP/MSEK": 7.458,
    "out:LCP/MSEK": -0.085,
    "out:ROI% old": 14.18,
    "out:Payback discounted": 9,
    "out:Atemp": 5143,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 82,
    "out:Energy use kWh/m2": 82,
    "out:Energy savings %": 129.3,
    "out:Op energy cost/MSEK": 54,
    "out:El price/MSEK": 37,
    "out:Heating price/MSEK": 14,
    "out:DH+ DHW/kWh/m2": 49,
    "out:El bought/kWh/m2": 2451.2,
    "out:Return %": 13,
    "out:Return/kSEK/y": 1509,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 1479000,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 73950,
    "out:EAHP (replacement cost)": 1105400,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 26.07,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 61.265983,
    "out:PV (m2 panels)": 321,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 57484.06,
    "out:PV (kWh sold)": 7969.77,
    "out:PV (% sold (El))": 13.86,
    "out:PV (kWh self-consumed)": 49514.28,
    "out:PV (ratio sold/self-consumed)": 0.16,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 632589,
    "out:EL kWh savings": -87133,
    "out:Sewage heat recovery cost": 250448,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 437691,
    "out:DH kr savings": 575656,
    "out:El kr savings": -148126,
    "out:El kr sold": 10161,
    "out:El kr saved": 84174,
    "out:El kr return": 94336,
    "out:% solar/total": 61,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 250787,
    "out:% savings (space heating)": 61.92,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 39.65,
    "out:Etvv": 0,
    "out:Ef": 32.84,
    "out:heatPumpQuantity": 10,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000201010001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 44.94,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 44.17,
    "out:Electricity (inc PV)": 32.71,
    "out:Total Energy Use Pre PV": 89.17,
    "out:Total Energy Use Post PV": 77.71,
    "out:Primary Energy": 84.06,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 32.55,
    "out:CO2 Operational/m2": 13.17,
    "out:Total CO2/m2": 45.72,
    "out:Total CO2 (tons)": 235.14,
    "out:Klimatpaverkan": -94.1,
    "out:Initial Cost/MSEK": 12.02525,
    "out:Running cost/(Apt SEK y)": 21431,
    "out:Running Cost over RSP/MSEK": 7.028,
    "out:LCP/MSEK": 0.216,
    "out:ROI% old": 14.54,
    "out:Payback discounted": 8,
    "out:Atemp": 5143,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 78,
    "out:Energy use kWh/m2": 78,
    "out:Energy savings %": 141,
    "out:Op energy cost/MSEK": 50,
    "out:El price/MSEK": 35,
    "out:Heating price/MSEK": 13,
    "out:DH+ DHW/kWh/m2": 45,
    "out:El bought/kWh/m2": 2444.4,
    "out:Return %": 13,
    "out:Return/kSEK/y": 1564,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 1479000,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 73950,
    "out:EAHP (replacement cost)": 1105400,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 25.97,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 61.265983,
    "out:PV (m2 panels)": 321,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 57484.06,
    "out:PV (kWh sold)": 8022.36,
    "out:PV (% sold (El))": 13.96,
    "out:PV (kWh self-consumed)": 49461.69,
    "out:PV (ratio sold/self-consumed)": 0.16,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 653161,
    "out:EL kWh savings": -86441,
    "out:Sewage heat recovery cost": 250448,
    "out:Balancing hydronic system cost": 128574.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 457656,
    "out:DH kr savings": 594376,
    "out:El kr savings": -146949,
    "out:El kr sold": 10229,
    "out:El kr saved": 84085,
    "out:El kr return": 94313,
    "out:% solar/total": 60,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 231105,
    "out:% savings (space heating)": 64.91,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 35.97,
    "out:Etvv": 0,
    "out:Ef": 32.71,
    "out:heatPumpQuantity": 10,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000201020000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 52.77,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 45.04,
    "out:Electricity (inc PV)": 30.08,
    "out:Total Energy Use Pre PV": 98.04,
    "out:Total Energy Use Post PV": 83.08,
    "out:Primary Energy": 83.38,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 44.5,
    "out:CO2 Operational/m2": -23.07,
    "out:Total CO2/m2": 21.44,
    "out:Total CO2 (tons)": 110.24,
    "out:Klimatpaverkan": -219,
    "out:Initial Cost/MSEK": 12.71625,
    "out:Running cost/(Apt SEK y)": 22259,
    "out:Running Cost over RSP/MSEK": 7.316,
    "out:LCP/MSEK": -0.763,
    "out:ROI% old": 13.43,
    "out:Payback discounted": 9,
    "out:Atemp": 5143,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 83,
    "out:Energy use kWh/m2": 83,
    "out:Energy savings %": 126.5,
    "out:Op energy cost/MSEK": 53,
    "out:El price/MSEK": 36,
    "out:Heating price/MSEK": 15,
    "out:DH+ DHW/kWh/m2": 53,
    "out:El bought/kWh/m2": 2256.1,
    "out:Return %": 12,
    "out:Return/kSEK/y": 1529,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 1479000,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 73950,
    "out:EAHP (replacement cost)": 1105400,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 26.84,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.33,
    "out:PV (kWp installed)": 122.531965,
    "out:PV (m2 panels)": 643,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 114968.11,
    "out:PV (kWh sold)": 47481.79,
    "out:PV (% sold (El))": 41.3,
    "out:PV (kWh self-consumed)": 67486.32,
    "out:PV (ratio sold/self-consumed)": 0.7,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 612017,
    "out:EL kWh savings": -72931,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 493492,
    "out:DH kr savings": 556935,
    "out:El kr savings": -123983,
    "out:El kr sold": 60539,
    "out:El kr saved": 114727,
    "out:El kr return": 175266,
    "out:% solar/total": 83,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 271383,
    "out:% savings (space heating)": 58.79,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 41.76,
    "out:Etvv": 0,
    "out:Ef": 30.08,
    "out:heatPumpQuantity": 10,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000201020000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 48.88,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 44.95,
    "out:Electricity (inc PV)": 29.97,
    "out:Total Energy Use Pre PV": 93.95,
    "out:Total Energy Use Post PV": 78.97,
    "out:Primary Energy": 80.57,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 44.5,
    "out:CO2 Operational/m2": -24.6,
    "out:Total CO2/m2": 19.9,
    "out:Total CO2 (tons)": 102.35,
    "out:Klimatpaverkan": -226.89,
    "out:Initial Cost/MSEK": 12.845,
    "out:Running cost/(Apt SEK y)": 20954,
    "out:Running Cost over RSP/MSEK": 6.887,
    "out:LCP/MSEK": -0.463,
    "out:ROI% old": 13.77,
    "out:Payback discounted": 9,
    "out:Atemp": 5143,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 79,
    "out:Energy use kWh/m2": 79,
    "out:Energy savings %": 138,
    "out:Op energy cost/MSEK": 50,
    "out:El price/MSEK": 34,
    "out:Heating price/MSEK": 14,
    "out:DH+ DHW/kWh/m2": 49,
    "out:El bought/kWh/m2": 2251,
    "out:Return %": 12,
    "out:Return/kSEK/y": 1584,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 1479000,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 73950,
    "out:EAHP (replacement cost)": 1105400,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 26.75,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.33,
    "out:PV (kWp installed)": 122.531965,
    "out:PV (m2 panels)": 643,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 114968.11,
    "out:PV (kWh sold)": 47567.89,
    "out:PV (% sold (El))": 41.37,
    "out:PV (kWh self-consumed)": 67400.22,
    "out:PV (ratio sold/self-consumed)": 0.71,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 632589,
    "out:EL kWh savings": -72362,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 128574.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 513290,
    "out:DH kr savings": 575656,
    "out:El kr savings": -123015,
    "out:El kr sold": 60649,
    "out:El kr saved": 114580,
    "out:El kr return": 175229,
    "out:% solar/total": 82,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 251413,
    "out:% savings (space heating)": 61.83,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 38.04,
    "out:Etvv": 0,
    "out:Ef": 29.97,
    "out:heatPumpQuantity": 10,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000201020001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 48.76,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 44.27,
    "out:Electricity (inc PV)": 29.68,
    "out:Total Energy Use Pre PV": 93.27,
    "out:Total Energy Use Post PV": 78.68,
    "out:Primary Energy": 81.18,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 44.9,
    "out:CO2 Operational/m2": -25.34,
    "out:Total CO2/m2": 19.57,
    "out:Total CO2 (tons)": 100.63,
    "out:Klimatpaverkan": -228.61,
    "out:Initial Cost/MSEK": 12.96625,
    "out:Running cost/(Apt SEK y)": 20820,
    "out:Running Cost over RSP/MSEK": 6.844,
    "out:LCP/MSEK": -0.541,
    "out:ROI% old": 13.69,
    "out:Payback discounted": 9,
    "out:Atemp": 5143,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 79,
    "out:Energy use kWh/m2": 79,
    "out:Energy savings %": 138,
    "out:Op energy cost/MSEK": 50,
    "out:El price/MSEK": 34,
    "out:Heating price/MSEK": 14,
    "out:DH+ DHW/kWh/m2": 49,
    "out:El bought/kWh/m2": 2212.4,
    "out:Return %": 12,
    "out:Return/kSEK/y": 1589,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 1479000,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 73950,
    "out:EAHP (replacement cost)": 1105400,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 26.07,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.33,
    "out:PV (kWp installed)": 122.531965,
    "out:PV (m2 panels)": 643,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 114968.11,
    "out:PV (kWh sold)": 48229.8,
    "out:PV (% sold (El))": 41.95,
    "out:PV (kWh self-consumed)": 66738.31,
    "out:PV (ratio sold/self-consumed)": 0.72,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 632589,
    "out:EL kWh savings": -70875,
    "out:Sewage heat recovery cost": 250448,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 516662,
    "out:DH kr savings": 575656,
    "out:El kr savings": -120487,
    "out:El kr sold": 61493,
    "out:El kr saved": 113455,
    "out:El kr return": 174948,
    "out:% solar/total": 82,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 250787,
    "out:% savings (space heating)": 61.92,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 39.65,
    "out:Etvv": 0,
    "out:Ef": 29.68,
    "out:heatPumpQuantity": 10,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000201020001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 44.94,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 44.17,
    "out:Electricity (inc PV)": 29.56,
    "out:Total Energy Use Pre PV": 89.17,
    "out:Total Energy Use Post PV": 74.56,
    "out:Primary Energy": 78.39,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 44.9,
    "out:CO2 Operational/m2": -26.89,
    "out:Total CO2/m2": 18.01,
    "out:Total CO2 (tons)": 92.64,
    "out:Klimatpaverkan": -236.6,
    "out:Initial Cost/MSEK": 13.095,
    "out:Running cost/(Apt SEK y)": 19512,
    "out:Running Cost over RSP/MSEK": 6.414,
    "out:LCP/MSEK": -0.24,
    "out:ROI% old": 14.02,
    "out:Payback discounted": 9,
    "out:Atemp": 5143,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 75,
    "out:Energy use kWh/m2": 75,
    "out:Energy savings %": 150.7,
    "out:Op energy cost/MSEK": 47,
    "out:El price/MSEK": 32,
    "out:Heating price/MSEK": 13,
    "out:DH+ DHW/kWh/m2": 45,
    "out:El bought/kWh/m2": 2206.4,
    "out:Return %": 13,
    "out:Return/kSEK/y": 1644,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 1479000,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 73950,
    "out:EAHP (replacement cost)": 1105400,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 25.97,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.33,
    "out:PV (kWp installed)": 122.531965,
    "out:PV (m2 panels)": 643,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 114968.11,
    "out:PV (kWh sold)": 48333.29,
    "out:PV (% sold (El))": 42.04,
    "out:PV (kWh self-consumed)": 66634.82,
    "out:PV (ratio sold/self-consumed)": 0.73,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 653161,
    "out:EL kWh savings": -70254,
    "out:Sewage heat recovery cost": 250448,
    "out:Balancing hydronic system cost": 128574.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 536569,
    "out:DH kr savings": 594376,
    "out:El kr savings": -119432,
    "out:El kr sold": 61625,
    "out:El kr saved": 113279,
    "out:El kr return": 174904,
    "out:% solar/total": 81,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 231105,
    "out:% savings (space heating)": 64.91,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 35.97,
    "out:Etvv": 0,
    "out:Ef": 29.56,
    "out:heatPumpQuantity": 10,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000201100000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 47.46,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 44.01,
    "out:Electricity (inc PV)": 42.1,
    "out:Total Energy Use Pre PV": 91.01,
    "out:Total Energy Use Post PV": 89.1,
    "out:Primary Energy": 103.05,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 19.8,
    "out:CO2 Operational/m2": 22.73,
    "out:Total CO2/m2": 42.52,
    "out:Total CO2 (tons)": 218.7,
    "out:Klimatpaverkan": -110.54,
    "out:Initial Cost/MSEK": 10.68725,
    "out:Running cost/(Apt SEK y)": 24287,
    "out:Running Cost over RSP/MSEK": 7.946,
    "out:LCP/MSEK": 0.636,
    "out:ROI% old": 15.14,
    "out:Payback discounted": 8,
    "out:Atemp": 5143,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 89,
    "out:Energy use kWh/m2": 89,
    "out:Energy savings %": 111.2,
    "out:Op energy cost/MSEK": 56,
    "out:El price/MSEK": 41,
    "out:Heating price/MSEK": 13,
    "out:DH+ DHW/kWh/m2": 47,
    "out:El bought/kWh/m2": 3080.6,
    "out:Return %": 14,
    "out:Return/kSEK/y": 1444,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 1479000,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 73950,
    "out:EAHP (replacement cost)": 1105400,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 25.81,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 642875,
    "out:EL kWh savings": -134742,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 355954,
    "out:DH kr savings": 585016,
    "out:El kr savings": -229062,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 244085,
    "out:% savings (space heating)": 62.94,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 38.96,
    "out:Etvv": 0,
    "out:Ef": 42.1,
    "out:heatPumpQuantity": 10,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000201100000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 43.65,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 43.9,
    "out:Electricity (inc PV)": 41.94,
    "out:Total Energy Use Pre PV": 87.9,
    "out:Total Energy Use Post PV": 85.94,
    "out:Primary Energy": 100.21,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 19.8,
    "out:CO2 Operational/m2": 21.63,
    "out:Total CO2/m2": 41.43,
    "out:Total CO2 (tons)": 213.07,
    "out:Klimatpaverkan": -116.17,
    "out:Initial Cost/MSEK": 10.815875,
    "out:Running cost/(Apt SEK y)": 23299,
    "out:Running Cost over RSP/MSEK": 7.621,
    "out:LCP/MSEK": 0.832,
    "out:ROI% old": 15.38,
    "out:Payback discounted": 8,
    "out:Atemp": 5143,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 86,
    "out:Energy use kWh/m2": 86,
    "out:Energy savings %": 118.6,
    "out:Op energy cost/MSEK": 54,
    "out:El price/MSEK": 39,
    "out:Heating price/MSEK": 13,
    "out:DH+ DHW/kWh/m2": 44,
    "out:El bought/kWh/m2": 3072.8,
    "out:Return %": 14,
    "out:Return/kSEK/y": 1485,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 1479000,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 73950,
    "out:EAHP (replacement cost)": 1105400,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 25.7,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 658304,
    "out:EL kWh savings": -133949,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 128574.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 371343,
    "out:DH kr savings": 599057,
    "out:El kr savings": -227714,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 224497,
    "out:% savings (space heating)": 65.91,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 35.29,
    "out:Etvv": 0,
    "out:Ef": 41.94,
    "out:heatPumpQuantity": 10,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000201100001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 44.39,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 43.36,
    "out:Electricity (inc PV)": 41.69,
    "out:Total Energy Use Pre PV": 87.36,
    "out:Total Energy Use Post PV": 85.69,
    "out:Primary Energy": 101.16,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 20.2,
    "out:CO2 Operational/m2": 21.56,
    "out:Total CO2/m2": 41.76,
    "out:Total CO2 (tons)": 214.77,
    "out:Klimatpaverkan": -114.47,
    "out:Initial Cost/MSEK": 10.93775,
    "out:Running cost/(Apt SEK y)": 23186,
    "out:Running Cost over RSP/MSEK": 7.585,
    "out:LCP/MSEK": 0.746,
    "out:ROI% old": 15.26,
    "out:Payback discounted": 8,
    "out:Atemp": 5143,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 86,
    "out:Energy use kWh/m2": 86,
    "out:Energy savings %": 118.6,
    "out:Op energy cost/MSEK": 54,
    "out:El price/MSEK": 39,
    "out:Heating price/MSEK": 13,
    "out:DH+ DHW/kWh/m2": 44,
    "out:El bought/kWh/m2": 3035,
    "out:Return %": 14,
    "out:Return/kSEK/y": 1490,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 1479000,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 73950,
    "out:EAHP (replacement cost)": 1105400,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 25.16,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 658304,
    "out:EL kWh savings": -132628,
    "out:Sewage heat recovery cost": 250448,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 373589,
    "out:DH kr savings": 599057,
    "out:El kr savings": -225468,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 228288,
    "out:% savings (space heating)": 65.34,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 37.32,
    "out:Etvv": 0,
    "out:Ef": 41.69,
    "out:heatPumpQuantity": 10,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000201100001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 40.63,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 43.23,
    "out:Electricity (inc PV)": 41.52,
    "out:Total Energy Use Pre PV": 84.23,
    "out:Total Energy Use Post PV": 82.52,
    "out:Primary Energy": 98.32,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 20.2,
    "out:CO2 Operational/m2": 20.47,
    "out:Total CO2/m2": 40.66,
    "out:Total CO2 (tons)": 209.13,
    "out:Klimatpaverkan": -120.11,
    "out:Initial Cost/MSEK": 11.06625,
    "out:Running cost/(Apt SEK y)": 22195,
    "out:Running Cost over RSP/MSEK": 7.259,
    "out:LCP/MSEK": 0.944,
    "out:ROI% old": 15.5,
    "out:Payback discounted": 8,
    "out:Atemp": 5143,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 83,
    "out:Energy use kWh/m2": 83,
    "out:Energy savings %": 126.5,
    "out:Op energy cost/MSEK": 51,
    "out:El price/MSEK": 38,
    "out:Heating price/MSEK": 12,
    "out:DH+ DHW/kWh/m2": 41,
    "out:El bought/kWh/m2": 3026.2,
    "out:Return %": 14,
    "out:Return/kSEK/y": 1532,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 1479000,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 73950,
    "out:EAHP (replacement cost)": 1105400,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 25.03,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 673733,
    "out:EL kWh savings": -131785,
    "out:Sewage heat recovery cost": 250448,
    "out:Balancing hydronic system cost": 128574.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 389062,
    "out:DH kr savings": 613097,
    "out:El kr savings": -224035,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 208953,
    "out:% savings (space heating)": 68.27,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 33.68,
    "out:Etvv": 0,
    "out:Ef": 41.52,
    "out:heatPumpQuantity": 10,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000201110000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 47.46,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 44.01,
    "out:Electricity (inc PV)": 32.69,
    "out:Total Energy Use Pre PV": 91.01,
    "out:Total Energy Use Post PV": 79.69,
    "out:Primary Energy": 86.12,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 32.15,
    "out:CO2 Operational/m2": 13.79,
    "out:Total CO2/m2": 45.94,
    "out:Total CO2 (tons)": 236.28,
    "out:Klimatpaverkan": -92.96,
    "out:Initial Cost/MSEK": 11.757,
    "out:Running cost/(Apt SEK y)": 22042,
    "out:Running Cost over RSP/MSEK": 7.229,
    "out:LCP/MSEK": 0.283,
    "out:ROI% old": 14.63,
    "out:Payback discounted": 8,
    "out:Atemp": 5143,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 80,
    "out:Energy use kWh/m2": 80,
    "out:Energy savings %": 135,
    "out:Op energy cost/MSEK": 52,
    "out:El price/MSEK": 36,
    "out:Heating price/MSEK": 13,
    "out:DH+ DHW/kWh/m2": 47,
    "out:El bought/kWh/m2": 2434.2,
    "out:Return %": 13,
    "out:Return/kSEK/y": 1538,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 1479000,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 73950,
    "out:EAHP (replacement cost)": 1105400,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 25.81,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 61.265983,
    "out:PV (m2 panels)": 321,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 57484.06,
    "out:PV (kWh sold)": 8102.48,
    "out:PV (% sold (El))": 14.1,
    "out:PV (kWh self-consumed)": 49381.58,
    "out:PV (ratio sold/self-consumed)": 0.16,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 642875,
    "out:EL kWh savings": -86355,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 448544,
    "out:DH kr savings": 585016,
    "out:El kr savings": -146803,
    "out:El kr sold": 10331,
    "out:El kr saved": 83949,
    "out:El kr return": 94279,
    "out:% solar/total": 60,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 244085,
    "out:% savings (space heating)": 62.94,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 38.96,
    "out:Etvv": 0,
    "out:Ef": 32.69,
    "out:heatPumpQuantity": 10,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000201110000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 43.65,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 43.9,
    "out:Electricity (inc PV)": 32.55,
    "out:Total Energy Use Pre PV": 87.9,
    "out:Total Energy Use Post PV": 76.55,
    "out:Primary Energy": 83.3,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 32.15,
    "out:CO2 Operational/m2": 12.65,
    "out:Total CO2/m2": 44.79,
    "out:Total CO2 (tons)": 230.37,
    "out:Klimatpaverkan": -98.87,
    "out:Initial Cost/MSEK": 11.885625,
    "out:Running cost/(Apt SEK y)": 21054,
    "out:Running Cost over RSP/MSEK": 6.904,
    "out:LCP/MSEK": 0.479,
    "out:ROI% old": 14.86,
    "out:Payback discounted": 8,
    "out:Atemp": 5143,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 77,
    "out:Energy use kWh/m2": 77,
    "out:Energy savings %": 144.2,
    "out:Op energy cost/MSEK": 50,
    "out:El price/MSEK": 35,
    "out:Heating price/MSEK": 13,
    "out:DH+ DHW/kWh/m2": 44,
    "out:El bought/kWh/m2": 2427.2,
    "out:Return %": 13,
    "out:Return/kSEK/y": 1580,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 1479000,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 73950,
    "out:EAHP (replacement cost)": 1105400,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 25.7,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 61.265983,
    "out:PV (m2 panels)": 321,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 57484.06,
    "out:PV (kWh sold)": 8158.58,
    "out:PV (% sold (El))": 14.19,
    "out:PV (kWh self-consumed)": 49325.48,
    "out:PV (ratio sold/self-consumed)": 0.17,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 658304,
    "out:EL kWh savings": -85644,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 128574.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 463863,
    "out:DH kr savings": 599057,
    "out:El kr savings": -145596,
    "out:El kr sold": 10402,
    "out:El kr saved": 83853,
    "out:El kr return": 94255,
    "out:% solar/total": 60,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 224497,
    "out:% savings (space heating)": 65.91,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 35.29,
    "out:Etvv": 0,
    "out:Ef": 32.55,
    "out:heatPumpQuantity": 10,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000201110001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 44.39,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 43.36,
    "out:Electricity (inc PV)": 32.32,
    "out:Total Energy Use Pre PV": 87.36,
    "out:Total Energy Use Post PV": 76.32,
    "out:Primary Energy": 84.3,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 32.55,
    "out:CO2 Operational/m2": 12.31,
    "out:Total CO2/m2": 44.86,
    "out:Total CO2 (tons)": 230.72,
    "out:Klimatpaverkan": -98.52,
    "out:Initial Cost/MSEK": 12.0075,
    "out:Running cost/(Apt SEK y)": 20945,
    "out:Running Cost over RSP/MSEK": 6.868,
    "out:LCP/MSEK": 0.393,
    "out:ROI% old": 14.75,
    "out:Payback discounted": 8,
    "out:Atemp": 5143,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 76,
    "out:Energy use kWh/m2": 76,
    "out:Energy savings %": 147.4,
    "out:Op energy cost/MSEK": 49,
    "out:El price/MSEK": 35,
    "out:Heating price/MSEK": 13,
    "out:DH+ DHW/kWh/m2": 44,
    "out:El bought/kWh/m2": 2392.6,
    "out:Return %": 13,
    "out:Return/kSEK/y": 1584,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 1479000,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 73950,
    "out:EAHP (replacement cost)": 1105400,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 25.16,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 61.265983,
    "out:PV (m2 panels)": 321,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 57484.06,
    "out:PV (kWh sold)": 8435.32,
    "out:PV (% sold (El))": 14.67,
    "out:PV (kWh self-consumed)": 49048.73,
    "out:PV (ratio sold/self-consumed)": 0.17,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 658304,
    "out:EL kWh savings": -84462,
    "out:Sewage heat recovery cost": 250448,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 466226,
    "out:DH kr savings": 599057,
    "out:El kr savings": -143586,
    "out:El kr sold": 10755,
    "out:El kr saved": 83383,
    "out:El kr return": 94138,
    "out:% solar/total": 60,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 228288,
    "out:% savings (space heating)": 65.34,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 37.32,
    "out:Etvv": 0,
    "out:Ef": 32.32,
    "out:heatPumpQuantity": 10,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000201110001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 40.63,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 43.23,
    "out:Electricity (inc PV)": 32.18,
    "out:Total Energy Use Pre PV": 84.23,
    "out:Total Energy Use Post PV": 73.18,
    "out:Primary Energy": 81.5,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 32.55,
    "out:CO2 Operational/m2": 11.15,
    "out:Total CO2/m2": 43.7,
    "out:Total CO2 (tons)": 224.76,
    "out:Klimatpaverkan": -104.48,
    "out:Initial Cost/MSEK": 12.136125,
    "out:Running cost/(Apt SEK y)": 19954,
    "out:Running Cost over RSP/MSEK": 6.543,
    "out:LCP/MSEK": 0.59,
    "out:ROI% old": 14.98,
    "out:Payback discounted": 8,
    "out:Atemp": 5143,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 73,
    "out:Energy use kWh/m2": 73,
    "out:Energy savings %": 157.5,
    "out:Op energy cost/MSEK": 47,
    "out:El price/MSEK": 33,
    "out:Heating price/MSEK": 12,
    "out:DH+ DHW/kWh/m2": 41,
    "out:El bought/kWh/m2": 2384.6,
    "out:Return %": 13,
    "out:Return/kSEK/y": 1626,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 1479000,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 73950,
    "out:EAHP (replacement cost)": 1105400,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 25.03,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 61.265983,
    "out:PV (m2 panels)": 321,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 57484.06,
    "out:PV (kWh sold)": 8500.17,
    "out:PV (% sold (El))": 14.79,
    "out:PV (kWh self-consumed)": 48983.88,
    "out:PV (ratio sold/self-consumed)": 0.17,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 673733,
    "out:EL kWh savings": -83709,
    "out:Sewage heat recovery cost": 250448,
    "out:Balancing hydronic system cost": 128574.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 481630,
    "out:DH kr savings": 613097,
    "out:El kr savings": -142305,
    "out:El kr sold": 10838,
    "out:El kr saved": 83273,
    "out:El kr return": 94110,
    "out:% solar/total": 60,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 208953,
    "out:% savings (space heating)": 68.27,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 33.68,
    "out:Etvv": 0,
    "out:Ef": 32.18,
    "out:heatPumpQuantity": 10,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000201120000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 47.46,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 44.01,
    "out:Electricity (inc PV)": 29.55,
    "out:Total Energy Use Pre PV": 91.01,
    "out:Total Energy Use Post PV": 76.55,
    "out:Primary Energy": 80.45,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 44.5,
    "out:CO2 Operational/m2": -26.35,
    "out:Total CO2/m2": 18.16,
    "out:Total CO2 (tons)": 93.38,
    "out:Klimatpaverkan": -235.86,
    "out:Initial Cost/MSEK": 12.82625,
    "out:Running cost/(Apt SEK y)": 20124,
    "out:Running Cost over RSP/MSEK": 6.615,
    "out:LCP/MSEK": -0.173,
    "out:ROI% old": 14.09,
    "out:Payback discounted": 9,
    "out:Atemp": 5143,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 77,
    "out:Energy use kWh/m2": 77,
    "out:Energy savings %": 144.2,
    "out:Op energy cost/MSEK": 48,
    "out:El price/MSEK": 33,
    "out:Heating price/MSEK": 13,
    "out:DH+ DHW/kWh/m2": 47,
    "out:El bought/kWh/m2": 2197.3,
    "out:Return %": 13,
    "out:Return/kSEK/y": 1619,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 1479000,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 73950,
    "out:EAHP (replacement cost)": 1105400,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 25.81,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.33,
    "out:PV (kWp installed)": 122.531965,
    "out:PV (m2 panels)": 643,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 114968.11,
    "out:PV (kWh sold)": 48490.32,
    "out:PV (% sold (El))": 42.18,
    "out:PV (kWh self-consumed)": 66477.79,
    "out:PV (ratio sold/self-consumed)": 0.73,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 642875,
    "out:EL kWh savings": -70177,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 527540,
    "out:DH kr savings": 585016,
    "out:El kr savings": -119301,
    "out:El kr sold": 61825,
    "out:El kr saved": 113012,
    "out:El kr return": 174837,
    "out:% solar/total": 81,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 244085,
    "out:% savings (space heating)": 62.94,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 38.96,
    "out:Etvv": 0,
    "out:Ef": 29.55,
    "out:heatPumpQuantity": 10,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000201120000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 43.65,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 43.9,
    "out:Electricity (inc PV)": 29.42,
    "out:Total Energy Use Pre PV": 87.9,
    "out:Total Energy Use Post PV": 73.42,
    "out:Primary Energy": 77.66,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 44.5,
    "out:CO2 Operational/m2": -27.55,
    "out:Total CO2/m2": 16.96,
    "out:Total CO2 (tons)": 87.2,
    "out:Klimatpaverkan": -242.04,
    "out:Initial Cost/MSEK": 12.955,
    "out:Running cost/(Apt SEK y)": 19137,
    "out:Running Cost over RSP/MSEK": 6.291,
    "out:LCP/MSEK": 0.023,
    "out:ROI% old": 14.31,
    "out:Payback discounted": 8,
    "out:Atemp": 5143,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 73,
    "out:Energy use kWh/m2": 73,
    "out:Energy savings %": 157.5,
    "out:Op energy cost/MSEK": 46,
    "out:El price/MSEK": 31,
    "out:Heating price/MSEK": 13,
    "out:DH+ DHW/kWh/m2": 44,
    "out:El bought/kWh/m2": 2191,
    "out:Return %": 13,
    "out:Return/kSEK/y": 1660,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 1479000,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 73950,
    "out:EAHP (replacement cost)": 1105400,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 25.7,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.33,
    "out:PV (kWp installed)": 122.531965,
    "out:PV (m2 panels)": 643,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 114968.11,
    "out:PV (kWh sold)": 48599.87,
    "out:PV (% sold (El))": 42.27,
    "out:PV (kWh self-consumed)": 66368.24,
    "out:PV (ratio sold/self-consumed)": 0.73,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 658304,
    "out:EL kWh savings": -69541,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 128574.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 542802,
    "out:DH kr savings": 599057,
    "out:El kr savings": -118219,
    "out:El kr sold": 61965,
    "out:El kr saved": 112826,
    "out:El kr return": 174791,
    "out:% solar/total": 81,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 224497,
    "out:% savings (space heating)": 65.91,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 35.29,
    "out:Etvv": 0,
    "out:Ef": 29.42,
    "out:heatPumpQuantity": 10,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000201120001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 44.39,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 43.36,
    "out:Electricity (inc PV)": 29.22,
    "out:Total Energy Use Pre PV": 87.36,
    "out:Total Energy Use Post PV": 73.22,
    "out:Primary Energy": 78.71,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 44.9,
    "out:CO2 Operational/m2": -28.14,
    "out:Total CO2/m2": 16.76,
    "out:Total CO2 (tons)": 86.22,
    "out:Klimatpaverkan": -243.02,
    "out:Initial Cost/MSEK": 13.0775,
    "out:Running cost/(Apt SEK y)": 19030,
    "out:Running Cost over RSP/MSEK": 6.256,
    "out:LCP/MSEK": -0.065,
    "out:ROI% old": 14.22,
    "out:Payback discounted": 9,
    "out:Atemp": 5143,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 73,
    "out:Energy use kWh/m2": 73,
    "out:Energy savings %": 157.5,
    "out:Op energy cost/MSEK": 46,
    "out:El price/MSEK": 31,
    "out:Heating price/MSEK": 13,
    "out:DH+ DHW/kWh/m2": 44,
    "out:El bought/kWh/m2": 2160.1,
    "out:Return %": 13,
    "out:Return/kSEK/y": 1665,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 1479000,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 73950,
    "out:EAHP (replacement cost)": 1105400,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 25.16,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.33,
    "out:PV (kWp installed)": 122.531965,
    "out:PV (m2 panels)": 643,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 114968.11,
    "out:PV (kWh sold)": 49136.94,
    "out:PV (% sold (El))": 42.74,
    "out:PV (kWh self-consumed)": 65831.18,
    "out:PV (ratio sold/self-consumed)": 0.75,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 658304,
    "out:EL kWh savings": -68481,
    "out:Sewage heat recovery cost": 250448,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 545289,
    "out:DH kr savings": 599057,
    "out:El kr savings": -116418,
    "out:El kr sold": 62650,
    "out:El kr saved": 111913,
    "out:El kr return": 174563,
    "out:% solar/total": 81,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 228288,
    "out:% savings (space heating)": 65.34,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 37.32,
    "out:Etvv": 0,
    "out:Ef": 29.22,
    "out:heatPumpQuantity": 10,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000201120001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 40.63,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 43.23,
    "out:Electricity (inc PV)": 29.08,
    "out:Total Energy Use Pre PV": 84.23,
    "out:Total Energy Use Post PV": 70.08,
    "out:Primary Energy": 75.93,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 44.9,
    "out:CO2 Operational/m2": -29.36,
    "out:Total CO2/m2": 15.55,
    "out:Total CO2 (tons)": 79.95,
    "out:Klimatpaverkan": -249.29,
    "out:Initial Cost/MSEK": 13.20625,
    "out:Running cost/(Apt SEK y)": 18040,
    "out:Running Cost over RSP/MSEK": 5.931,
    "out:LCP/MSEK": 0.132,
    "out:ROI% old": 14.43,
    "out:Payback discounted": 8,
    "out:Atemp": 5143,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 70,
    "out:Energy use kWh/m2": 70,
    "out:Energy savings %": 168.6,
    "out:Op energy cost/MSEK": 43,
    "out:El price/MSEK": 29,
    "out:Heating price/MSEK": 12,
    "out:DH+ DHW/kWh/m2": 41,
    "out:El bought/kWh/m2": 2153,
    "out:Return %": 13,
    "out:Return/kSEK/y": 1706,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 1479000,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 73950,
    "out:EAHP (replacement cost)": 1105400,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 25.03,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.33,
    "out:PV (kWp installed)": 122.531965,
    "out:PV (m2 panels)": 643,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 114968.11,
    "out:PV (kWh sold)": 49262.33,
    "out:PV (% sold (El))": 42.85,
    "out:PV (kWh self-consumed)": 65705.78,
    "out:PV (ratio sold/self-consumed)": 0.75,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 673733,
    "out:EL kWh savings": -67806,
    "out:Sewage heat recovery cost": 250448,
    "out:Balancing hydronic system cost": 128574.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 560637,
    "out:DH kr savings": 613097,
    "out:El kr savings": -115270,
    "out:El kr sold": 62809,
    "out:El kr saved": 111700,
    "out:El kr return": 174509,
    "out:% solar/total": 80,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 208953,
    "out:% savings (space heating)": 68.27,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 33.68,
    "out:Etvv": 0,
    "out:Ef": 29.08,
    "out:heatPumpQuantity": 10,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000202000000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 7.26,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 58.83,
    "out:Electricity (inc PV)": 54.23,
    "out:Total Energy Use Pre PV": 65.83,
    "out:Total Energy Use Post PV": 61.23,
    "out:Primary Energy": 100.37,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 20.36,
    "out:CO2 Operational/m2": 10.29,
    "out:Total CO2/m2": 30.64,
    "out:Total CO2 (tons)": 157.59,
    "out:Klimatpaverkan": -171.65,
    "out:Initial Cost/MSEK": 13.22875,
    "out:Running cost/(Apt SEK y)": 14499,
    "out:Running Cost over RSP/MSEK": 4.717,
    "out:LCP/MSEK": 1.324,
    "out:ROI% old": 15.72,
    "out:Payback discounted": 8,
    "out:Atemp": 5143,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 61,
    "out:Energy use kWh/m2": 61,
    "out:Energy savings %": 208.2,
    "out:Op energy cost/MSEK": 32,
    "out:El price/MSEK": 29,
    "out:Heating price/MSEK": 2,
    "out:DH+ DHW/kWh/m2": 7,
    "out:El bought/kWh/m2": 4117.9,
    "out:Return %": 14,
    "out:Return/kSEK/y": 1855,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 2958000,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 147900,
    "out:EAHP (replacement cost)": 2204400,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 40.63,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 848595,
    "out:EL kWh savings": -197120,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 437118,
    "out:DH kr savings": 772221,
    "out:El kr savings": -335103,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 37327,
    "out:% savings (space heating)": 94.33,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 3.94,
    "out:Etvv": 0,
    "out:Ef": 54.23,
    "out:heatPumpQuantity": 20,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000202000000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 5.44,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 58.11,
    "out:Electricity (inc PV)": 53.29,
    "out:Total Energy Use Pre PV": 63.11,
    "out:Total Energy Use Post PV": 58.29,
    "out:Primary Energy": 97.92,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 20.36,
    "out:CO2 Operational/m2": 9.47,
    "out:Total CO2/m2": 29.83,
    "out:Total CO2 (tons)": 153.4,
    "out:Klimatpaverkan": -175.84,
    "out:Initial Cost/MSEK": 13.3575,
    "out:Running cost/(Apt SEK y)": 13706,
    "out:Running Cost over RSP/MSEK": 4.457,
    "out:LCP/MSEK": 1.455,
    "out:ROI% old": 15.84,
    "out:Payback discounted": 8,
    "out:Atemp": 5143,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 58,
    "out:Energy use kWh/m2": 58,
    "out:Energy savings %": 224.1,
    "out:Op energy cost/MSEK": 30,
    "out:El price/MSEK": 28,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 5,
    "out:El bought/kWh/m2": 4067.9,
    "out:Return %": 14,
    "out:Return/kSEK/y": 1888,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 2958000,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 147900,
    "out:EAHP (replacement cost)": 2204400,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 39.91,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 858881,
    "out:EL kWh savings": -192320,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 128574.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 454637,
    "out:DH kr savings": 781582,
    "out:El kr savings": -326945,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 27977,
    "out:% savings (space heating)": 95.75,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 2.84,
    "out:Etvv": 0,
    "out:Ef": 53.29,
    "out:heatPumpQuantity": 20,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000202000001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 5.86,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 57.27,
    "out:Electricity (inc PV)": 53.23,
    "out:Total Energy Use Pre PV": 63.27,
    "out:Total Energy Use Post PV": 59.23,
    "out:Primary Energy": 98.25,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 20.76,
    "out:CO2 Operational/m2": 9.72,
    "out:Total CO2/m2": 30.48,
    "out:Total CO2 (tons)": 156.75,
    "out:Klimatpaverkan": -172.49,
    "out:Initial Cost/MSEK": 13.47875,
    "out:Running cost/(Apt SEK y)": 13853,
    "out:Running Cost over RSP/MSEK": 4.506,
    "out:LCP/MSEK": 1.284,
    "out:ROI% old": 15.65,
    "out:Payback discounted": 8,
    "out:Atemp": 5143,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 59,
    "out:Energy use kWh/m2": 59,
    "out:Energy savings %": 218.6,
    "out:Op energy cost/MSEK": 30,
    "out:El price/MSEK": 28,
    "out:Heating price/MSEK": 2,
    "out:DH+ DHW/kWh/m2": 6,
    "out:El bought/kWh/m2": 4009.1,
    "out:Return %": 14,
    "out:Return/kSEK/y": 1882,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 2958000,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 147900,
    "out:EAHP (replacement cost)": 2204400,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 39.07,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 853738,
    "out:EL kWh savings": -191998,
    "out:Sewage heat recovery cost": 250448,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 450504,
    "out:DH kr savings": 776902,
    "out:El kr savings": -326397,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 30143,
    "out:% savings (space heating)": 95.42,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 3.47,
    "out:Etvv": 0,
    "out:Ef": 53.23,
    "out:heatPumpQuantity": 20,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000202000001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 4.33,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 56.47,
    "out:Electricity (inc PV)": 52.27,
    "out:Total Energy Use Pre PV": 60.47,
    "out:Total Energy Use Post PV": 56.27,
    "out:Primary Energy": 95.82,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 20.76,
    "out:CO2 Operational/m2": 8.89,
    "out:Total CO2/m2": 29.65,
    "out:Total CO2 (tons)": 152.5,
    "out:Klimatpaverkan": -176.74,
    "out:Initial Cost/MSEK": 13.6075,
    "out:Running cost/(Apt SEK y)": 13043,
    "out:Running Cost over RSP/MSEK": 4.24,
    "out:LCP/MSEK": 1.421,
    "out:ROI% old": 15.78,
    "out:Payback discounted": 8,
    "out:Atemp": 5143,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 56,
    "out:Energy use kWh/m2": 56,
    "out:Energy savings %": 235.7,
    "out:Op energy cost/MSEK": 28,
    "out:El price/MSEK": 27,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 4,
    "out:El bought/kWh/m2": 3953,
    "out:Return %": 14,
    "out:Return/kSEK/y": 1916,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 2958000,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 147900,
    "out:EAHP (replacement cost)": 2204400,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 38.27,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 864024,
    "out:EL kWh savings": -187051,
    "out:Sewage heat recovery cost": 250448,
    "out:Balancing hydronic system cost": 128574.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 468276,
    "out:DH kr savings": 786262,
    "out:El kr savings": -317986,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 22284,
    "out:% savings (space heating)": 96.62,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 2.47,
    "out:Etvv": 0,
    "out:Ef": 52.27,
    "out:heatPumpQuantity": 20,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000202010000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 7.26,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 58.83,
    "out:Electricity (inc PV)": 43.82,
    "out:Total Energy Use Pre PV": 65.83,
    "out:Total Energy Use Post PV": 50.82,
    "out:Primary Energy": 81.63,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 32.71,
    "out:CO2 Operational/m2": 6.51,
    "out:Total CO2/m2": 39.22,
    "out:Total CO2 (tons)": 201.72,
    "out:Klimatpaverkan": -127.52,
    "out:Initial Cost/MSEK": 14.29875,
    "out:Running cost/(Apt SEK y)": 12199,
    "out:Running Cost over RSP/MSEK": 3.982,
    "out:LCP/MSEK": 0.988,
    "out:ROI% old": 15.27,
    "out:Payback discounted": 8,
    "out:Atemp": 5143,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 51,
    "out:Energy use kWh/m2": 51,
    "out:Energy savings %": 268.6,
    "out:Op energy cost/MSEK": 27,
    "out:El price/MSEK": 25,
    "out:Heating price/MSEK": 2,
    "out:DH+ DHW/kWh/m2": 7,
    "out:El bought/kWh/m2": 3408.7,
    "out:Return %": 14,
    "out:Return/kSEK/y": 1952,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 2958000,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 147900,
    "out:EAHP (replacement cost)": 2204400,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 40.63,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 61.265983,
    "out:PV (m2 panels)": 321,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 57484.06,
    "out:PV (kWh sold)": 2639.49,
    "out:PV (% sold (El))": 4.59,
    "out:PV (kWh self-consumed)": 54844.57,
    "out:PV (ratio sold/self-consumed)": 0.05,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 848595,
    "out:EL kWh savings": -143587,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 531489,
    "out:DH kr savings": 772221,
    "out:El kr savings": -244098,
    "out:El kr sold": 3365,
    "out:El kr saved": 93236,
    "out:El kr return": 96601,
    "out:% solar/total": 67,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 37327,
    "out:% savings (space heating)": 94.33,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 3.94,
    "out:Etvv": 0,
    "out:Ef": 43.82,
    "out:heatPumpQuantity": 20,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000202010000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 5.44,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 58.11,
    "out:Electricity (inc PV)": 42.95,
    "out:Total Energy Use Pre PV": 63.11,
    "out:Total Energy Use Post PV": 47.95,
    "out:Primary Energy": 79.29,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 32.71,
    "out:CO2 Operational/m2": 5.53,
    "out:Total CO2/m2": 38.24,
    "out:Total CO2 (tons)": 196.65,
    "out:Klimatpaverkan": -132.59,
    "out:Initial Cost/MSEK": 14.42625,
    "out:Running cost/(Apt SEK y)": 11408,
    "out:Running Cost over RSP/MSEK": 3.723,
    "out:LCP/MSEK": 1.12,
    "out:ROI% old": 15.39,
    "out:Payback discounted": 8,
    "out:Atemp": 5143,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 48,
    "out:Energy use kWh/m2": 48,
    "out:Energy savings %": 291.7,
    "out:Op energy cost/MSEK": 25,
    "out:El price/MSEK": 24,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 5,
    "out:El bought/kWh/m2": 3360.7,
    "out:Return %": 14,
    "out:Return/kSEK/y": 1985,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 2958000,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 147900,
    "out:EAHP (replacement cost)": 2204400,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 39.91,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 61.265983,
    "out:PV (m2 panels)": 321,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 57484.06,
    "out:PV (kWh sold)": 2823,
    "out:PV (% sold (El))": 4.91,
    "out:PV (kWh self-consumed)": 54661.06,
    "out:PV (ratio sold/self-consumed)": 0.05,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 858881,
    "out:EL kWh savings": -139094,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 128574.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 548721,
    "out:DH kr savings": 781582,
    "out:El kr savings": -236460,
    "out:El kr sold": 3599,
    "out:El kr saved": 92924,
    "out:El kr return": 96523,
    "out:% solar/total": 67,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 27977,
    "out:% savings (space heating)": 95.75,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 2.84,
    "out:Etvv": 0,
    "out:Ef": 42.95,
    "out:heatPumpQuantity": 20,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000202010001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 5.86,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 57.27,
    "out:Electricity (inc PV)": 42.89,
    "out:Total Energy Use Pre PV": 63.27,
    "out:Total Energy Use Post PV": 48.89,
    "out:Primary Energy": 79.63,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 33.11,
    "out:CO2 Operational/m2": 5.56,
    "out:Total CO2/m2": 38.68,
    "out:Total CO2 (tons)": 198.91,
    "out:Klimatpaverkan": -130.33,
    "out:Initial Cost/MSEK": 14.54875,
    "out:Running cost/(Apt SEK y)": 11558,
    "out:Running Cost over RSP/MSEK": 3.772,
    "out:LCP/MSEK": 0.948,
    "out:ROI% old": 15.22,
    "out:Payback discounted": 8,
    "out:Atemp": 5143,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 49,
    "out:Energy use kWh/m2": 49,
    "out:Energy savings %": 283.7,
    "out:Op energy cost/MSEK": 26,
    "out:El price/MSEK": 24,
    "out:Heating price/MSEK": 2,
    "out:DH+ DHW/kWh/m2": 6,
    "out:El bought/kWh/m2": 3304.3,
    "out:Return %": 14,
    "out:Return/kSEK/y": 1978,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 2958000,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 147900,
    "out:EAHP (replacement cost)": 2204400,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 39.07,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 61.265983,
    "out:PV (m2 panels)": 321,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 57484.06,
    "out:PV (kWh sold)": 3048.34,
    "out:PV (% sold (El))": 5.3,
    "out:PV (kWh self-consumed)": 54435.71,
    "out:PV (ratio sold/self-consumed)": 0.06,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 853738,
    "out:EL kWh savings": -138793,
    "out:Sewage heat recovery cost": 250448,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 544840,
    "out:DH kr savings": 776902,
    "out:El kr savings": -235948,
    "out:El kr sold": 3887,
    "out:El kr saved": 92541,
    "out:El kr return": 96427,
    "out:% solar/total": 67,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 30143,
    "out:% savings (space heating)": 95.42,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 3.47,
    "out:Etvv": 0,
    "out:Ef": 42.89,
    "out:heatPumpQuantity": 20,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000202010001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 4.33,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 56.47,
    "out:Electricity (inc PV)": 41.99,
    "out:Total Energy Use Pre PV": 60.47,
    "out:Total Energy Use Post PV": 45.99,
    "out:Primary Energy": 77.31,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 33.11,
    "out:CO2 Operational/m2": 4.53,
    "out:Total CO2/m2": 37.64,
    "out:Total CO2 (tons)": 193.59,
    "out:Klimatpaverkan": -135.65,
    "out:Initial Cost/MSEK": 14.6775,
    "out:Running cost/(Apt SEK y)": 10749,
    "out:Running Cost over RSP/MSEK": 3.507,
    "out:LCP/MSEK": 1.084,
    "out:ROI% old": 15.34,
    "out:Payback discounted": 8,
    "out:Atemp": 5143,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 46,
    "out:Energy use kWh/m2": 46,
    "out:Energy savings %": 308.7,
    "out:Op energy cost/MSEK": 24,
    "out:El price/MSEK": 23,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 4,
    "out:El bought/kWh/m2": 3250.7,
    "out:Return %": 14,
    "out:Return/kSEK/y": 2012,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 2958000,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 147900,
    "out:EAHP (replacement cost)": 2204400,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 38.27,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 61.265983,
    "out:PV (m2 panels)": 321,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 57484.06,
    "out:PV (kWh sold)": 3272.81,
    "out:PV (% sold (El))": 5.69,
    "out:PV (kWh self-consumed)": 54211.25,
    "out:PV (ratio sold/self-consumed)": 0.06,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 864024,
    "out:EL kWh savings": -134177,
    "out:Sewage heat recovery cost": 250448,
    "out:Balancing hydronic system cost": 128574.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 562334,
    "out:DH kr savings": 786262,
    "out:El kr savings": -228100,
    "out:El kr sold": 4173,
    "out:El kr saved": 92159,
    "out:El kr return": 96332,
    "out:% solar/total": 66,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 22284,
    "out:% savings (space heating)": 96.62,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 2.47,
    "out:Etvv": 0,
    "out:Ef": 41.99,
    "out:heatPumpQuantity": 20,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000202020000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 7.26,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 58.83,
    "out:Electricity (inc PV)": 39.47,
    "out:Total Energy Use Pre PV": 65.83,
    "out:Total Energy Use Post PV": 46.47,
    "out:Primary Energy": 73.8,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 45.06,
    "out:CO2 Operational/m2": -25.89,
    "out:Total CO2/m2": 19.17,
    "out:Total CO2 (tons)": 98.61,
    "out:Klimatpaverkan": -230.63,
    "out:Initial Cost/MSEK": 15.3675,
    "out:Running cost/(Apt SEK y)": 10202,
    "out:Running Cost over RSP/MSEK": 3.343,
    "out:LCP/MSEK": 0.559,
    "out:ROI% old": 14.81,
    "out:Payback discounted": 8,
    "out:Atemp": 5143,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 46,
    "out:Energy use kWh/m2": 46,
    "out:Energy savings %": 308.7,
    "out:Op energy cost/MSEK": 23,
    "out:El price/MSEK": 21,
    "out:Heating price/MSEK": 2,
    "out:DH+ DHW/kWh/m2": 7,
    "out:El bought/kWh/m2": 3062.3,
    "out:Return %": 13,
    "out:Return/kSEK/y": 2035,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 2958000,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 147900,
    "out:EAHP (replacement cost)": 2204400,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 40.63,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.33,
    "out:PV (kWp installed)": 122.531965,
    "out:PV (m2 panels)": 643,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 114968.11,
    "out:PV (kWh sold)": 35216.05,
    "out:PV (% sold (El))": 30.63,
    "out:PV (kWh self-consumed)": 79752.06,
    "out:PV (ratio sold/self-consumed)": 0.44,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 848595,
    "out:EL kWh savings": -121221,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 611046,
    "out:DH kr savings": 772221,
    "out:El kr savings": -206075,
    "out:El kr sold": 44900,
    "out:El kr saved": 135578,
    "out:El kr return": 180479,
    "out:% solar/total": 98,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 37327,
    "out:% savings (space heating)": 94.33,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 3.94,
    "out:Etvv": 0,
    "out:Ef": 39.47,
    "out:heatPumpQuantity": 20,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000202020000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 5.44,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 58.11,
    "out:Electricity (inc PV)": 38.69,
    "out:Total Energy Use Pre PV": 63.11,
    "out:Total Energy Use Post PV": 43.69,
    "out:Primary Energy": 71.64,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 45.06,
    "out:CO2 Operational/m2": -27.27,
    "out:Total CO2/m2": 17.79,
    "out:Total CO2 (tons)": 91.5,
    "out:Klimatpaverkan": -237.74,
    "out:Initial Cost/MSEK": 15.49625,
    "out:Running cost/(Apt SEK y)": 9415,
    "out:Running Cost over RSP/MSEK": 3.085,
    "out:LCP/MSEK": 0.688,
    "out:ROI% old": 14.92,
    "out:Payback discounted": 8,
    "out:Atemp": 5143,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 44,
    "out:Energy use kWh/m2": 44,
    "out:Energy savings %": 327.3,
    "out:Op energy cost/MSEK": 21,
    "out:El price/MSEK": 20,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 5,
    "out:El bought/kWh/m2": 3019.8,
    "out:Return %": 13,
    "out:Return/kSEK/y": 2068,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 2958000,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 147900,
    "out:EAHP (replacement cost)": 2204400,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 39.91,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.33,
    "out:PV (kWp installed)": 122.531965,
    "out:PV (m2 panels)": 643,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 114968.11,
    "out:PV (kWh sold)": 35802.11,
    "out:PV (% sold (El))": 31.14,
    "out:PV (kWh self-consumed)": 79166,
    "out:PV (ratio sold/self-consumed)": 0.45,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 858881,
    "out:EL kWh savings": -117227,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 128574.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 627943,
    "out:DH kr savings": 781582,
    "out:El kr savings": -199286,
    "out:El kr sold": 45648,
    "out:El kr saved": 134582,
    "out:El kr return": 180230,
    "out:% solar/total": 97,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 27977,
    "out:% savings (space heating)": 95.75,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 2.84,
    "out:Etvv": 0,
    "out:Ef": 38.69,
    "out:heatPumpQuantity": 20,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000202020001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 5.86,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 57.27,
    "out:Electricity (inc PV)": 38.64,
    "out:Total Energy Use Pre PV": 63.27,
    "out:Total Energy Use Post PV": 44.64,
    "out:Primary Energy": 71.99,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 45.46,
    "out:CO2 Operational/m2": -27.7,
    "out:Total CO2/m2": 17.77,
    "out:Total CO2 (tons)": 91.37,
    "out:Klimatpaverkan": -237.87,
    "out:Initial Cost/MSEK": 15.61875,
    "out:Running cost/(Apt SEK y)": 9569,
    "out:Running Cost over RSP/MSEK": 3.136,
    "out:LCP/MSEK": 0.514,
    "out:ROI% old": 14.76,
    "out:Payback discounted": 8,
    "out:Atemp": 5143,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 45,
    "out:Energy use kWh/m2": 45,
    "out:Energy savings %": 317.8,
    "out:Op energy cost/MSEK": 22,
    "out:El price/MSEK": 20,
    "out:Heating price/MSEK": 2,
    "out:DH+ DHW/kWh/m2": 6,
    "out:El bought/kWh/m2": 2970,
    "out:Return %": 13,
    "out:Return/kSEK/y": 2062,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 2958000,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 147900,
    "out:EAHP (replacement cost)": 2204400,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 39.07,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.33,
    "out:PV (kWp installed)": 122.531965,
    "out:PV (m2 panels)": 643,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 114968.11,
    "out:PV (kWh sold)": 36497.87,
    "out:PV (% sold (El))": 31.75,
    "out:PV (kWh self-consumed)": 78470.24,
    "out:PV (ratio sold/self-consumed)": 0.47,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 853738,
    "out:EL kWh savings": -116960,
    "out:Sewage heat recovery cost": 250448,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 624605,
    "out:DH kr savings": 776902,
    "out:El kr savings": -198832,
    "out:El kr sold": 46535,
    "out:El kr saved": 133399,
    "out:El kr return": 179934,
    "out:% solar/total": 96,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 30143,
    "out:% savings (space heating)": 95.42,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 3.47,
    "out:Etvv": 0,
    "out:Ef": 38.64,
    "out:heatPumpQuantity": 20,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000202020001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 4.33,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 56.47,
    "out:Electricity (inc PV)": 37.84,
    "out:Total Energy Use Pre PV": 60.47,
    "out:Total Energy Use Post PV": 41.84,
    "out:Primary Energy": 69.85,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 45.46,
    "out:CO2 Operational/m2": -29.18,
    "out:Total CO2/m2": 16.29,
    "out:Total CO2 (tons)": 83.78,
    "out:Klimatpaverkan": -245.46,
    "out:Initial Cost/MSEK": 15.7475,
    "out:Running cost/(Apt SEK y)": 8766,
    "out:Running Cost over RSP/MSEK": 2.872,
    "out:LCP/MSEK": 0.649,
    "out:ROI% old": 14.87,
    "out:Payback discounted": 8,
    "out:Atemp": 5143,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 42,
    "out:Energy use kWh/m2": 42,
    "out:Energy savings %": 347.6,
    "out:Op energy cost/MSEK": 20,
    "out:El price/MSEK": 19,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 4,
    "out:El bought/kWh/m2": 2922.5,
    "out:Return %": 13,
    "out:Return/kSEK/y": 2096,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 2958000,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 147900,
    "out:EAHP (replacement cost)": 2204400,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 38.27,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.33,
    "out:PV (kWp installed)": 122.531965,
    "out:PV (m2 panels)": 643,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 114968.11,
    "out:PV (kWh sold)": 37166.66,
    "out:PV (% sold (El))": 32.33,
    "out:PV (kWh self-consumed)": 77801.45,
    "out:PV (ratio sold/self-consumed)": 0.48,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 864024,
    "out:EL kWh savings": -112853,
    "out:Sewage heat recovery cost": 250448,
    "out:Balancing hydronic system cost": 128574.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 641799,
    "out:DH kr savings": 786262,
    "out:El kr savings": -191850,
    "out:El kr sold": 47387,
    "out:El kr saved": 132262,
    "out:El kr return": 179650,
    "out:% solar/total": 95,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 22284,
    "out:% savings (space heating)": 96.62,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 2.47,
    "out:Etvv": 0,
    "out:Ef": 37.84,
    "out:heatPumpQuantity": 20,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000202100000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 5.45,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 56.74,
    "out:Electricity (inc PV)": 52.9,
    "out:Total Energy Use Pre PV": 61.74,
    "out:Total Energy Use Post PV": 57.9,
    "out:Primary Energy": 97.54,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 20.36,
    "out:CO2 Operational/m2": 9.29,
    "out:Total CO2/m2": 29.65,
    "out:Total CO2 (tons)": 152.47,
    "out:Klimatpaverkan": -176.77,
    "out:Initial Cost/MSEK": 13.33875,
    "out:Running cost/(Apt SEK y)": 13420,
    "out:Running Cost over RSP/MSEK": 4.364,
    "out:LCP/MSEK": 1.566,
    "out:ROI% old": 15.96,
    "out:Payback discounted": 8,
    "out:Atemp": 5143,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 58,
    "out:Energy use kWh/m2": 58,
    "out:Energy savings %": 224.1,
    "out:Op energy cost/MSEK": 29,
    "out:El price/MSEK": 28,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 5,
    "out:El bought/kWh/m2": 3971.6,
    "out:Return %": 14,
    "out:Return/kSEK/y": 1900,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 2958000,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 147900,
    "out:EAHP (replacement cost)": 2204400,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 38.54,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 858881,
    "out:EL kWh savings": -190275,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 458114,
    "out:DH kr savings": 781582,
    "out:El kr savings": -323468,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 28049,
    "out:% savings (space heating)": 95.74,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 3.33,
    "out:Etvv": 0,
    "out:Ef": 52.9,
    "out:heatPumpQuantity": 20,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000202100000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 4.02,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 55.91,
    "out:Electricity (inc PV)": 51.93,
    "out:Total Energy Use Pre PV": 59.91,
    "out:Total Energy Use Post PV": 55.93,
    "out:Primary Energy": 95.12,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 20.36,
    "out:CO2 Operational/m2": 8.82,
    "out:Total CO2/m2": 29.18,
    "out:Total CO2 (tons)": 150.05,
    "out:Klimatpaverkan": -179.19,
    "out:Initial Cost/MSEK": 13.4675,
    "out:Running cost/(Apt SEK y)": 12926,
    "out:Running Cost over RSP/MSEK": 4.202,
    "out:LCP/MSEK": 1.6,
    "out:ROI% old": 15.98,
    "out:Payback discounted": 8,
    "out:Atemp": 5143,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 56,
    "out:Energy use kWh/m2": 56,
    "out:Energy savings %": 235.7,
    "out:Op energy cost/MSEK": 28,
    "out:El price/MSEK": 27,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 4,
    "out:El bought/kWh/m2": 3913.6,
    "out:Return %": 14,
    "out:Return/kSEK/y": 1921,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 2958000,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 147900,
    "out:EAHP (replacement cost)": 2204400,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 37.71,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 864024,
    "out:EL kWh savings": -185282,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 128574.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 471283,
    "out:DH kr savings": 786262,
    "out:El kr savings": -314979,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 20655,
    "out:% savings (space heating)": 96.86,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 2.36,
    "out:Etvv": 0,
    "out:Ef": 51.93,
    "out:heatPumpQuantity": 20,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000202100001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 4.58,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 55.42,
    "out:Electricity (inc PV)": 52.09,
    "out:Total Energy Use Pre PV": 60.42,
    "out:Total Energy Use Post PV": 57.09,
    "out:Primary Energy": 95.86,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 20.76,
    "out:CO2 Operational/m2": 9.12,
    "out:Total CO2/m2": 29.87,
    "out:Total CO2 (tons)": 153.64,
    "out:Klimatpaverkan": -175.6,
    "out:Initial Cost/MSEK": 13.59,
    "out:Running cost/(Apt SEK y)": 13146,
    "out:Running Cost over RSP/MSEK": 4.275,
    "out:LCP/MSEK": 1.404,
    "out:ROI% old": 15.76,
    "out:Payback discounted": 8,
    "out:Atemp": 5143,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 57,
    "out:Energy use kWh/m2": 57,
    "out:Energy savings %": 229.8,
    "out:Op energy cost/MSEK": 29,
    "out:El price/MSEK": 27,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 5,
    "out:El bought/kWh/m2": 3879.4,
    "out:Return %": 14,
    "out:Return/kSEK/y": 1912,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 2958000,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 147900,
    "out:EAHP (replacement cost)": 2204400,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 37.22,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 858881,
    "out:EL kWh savings": -186105,
    "out:Sewage heat recovery cost": 250448,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 465202,
    "out:DH kr savings": 781582,
    "out:El kr savings": -316379,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 23572,
    "out:% savings (space heating)": 96.42,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 3,
    "out:Etvv": 0,
    "out:Ef": 52.09,
    "out:heatPumpQuantity": 20,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000202100001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 3.35,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 54.53,
    "out:Electricity (inc PV)": 51.1,
    "out:Total Energy Use Pre PV": 57.53,
    "out:Total Energy Use Post PV": 54.1,
    "out:Primary Energy": 93.44,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 20.76,
    "out:CO2 Operational/m2": 8.28,
    "out:Total CO2/m2": 29.04,
    "out:Total CO2 (tons)": 149.33,
    "out:Klimatpaverkan": -179.91,
    "out:Initial Cost/MSEK": 13.71875,
    "out:Running cost/(Apt SEK y)": 12317,
    "out:Running Cost over RSP/MSEK": 4.003,
    "out:LCP/MSEK": 1.547,
    "out:ROI% old": 15.9,
    "out:Payback discounted": 8,
    "out:Atemp": 5143,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 54,
    "out:Energy use kWh/m2": 54,
    "out:Energy savings %": 248.1,
    "out:Op energy cost/MSEK": 27,
    "out:El price/MSEK": 26,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 3,
    "out:El bought/kWh/m2": 3817,
    "out:Return %": 14,
    "out:Return/kSEK/y": 1947,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 2958000,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 147900,
    "out:EAHP (replacement cost)": 2204400,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 36.33,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 869167,
    "out:EL kWh savings": -181012,
    "out:Sewage heat recovery cost": 250448,
    "out:Balancing hydronic system cost": 128574.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 483222,
    "out:DH kr savings": 790942,
    "out:El kr savings": -307720,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 17213,
    "out:% savings (space heating)": 97.39,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 2.1,
    "out:Etvv": 0,
    "out:Ef": 51.1,
    "out:heatPumpQuantity": 20,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000202110000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 5.45,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 56.74,
    "out:Electricity (inc PV)": 42.57,
    "out:Total Energy Use Pre PV": 61.74,
    "out:Total Energy Use Post PV": 47.57,
    "out:Primary Energy": 78.96,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 32.71,
    "out:CO2 Operational/m2": 4.99,
    "out:Total CO2/m2": 37.7,
    "out:Total CO2 (tons)": 193.91,
    "out:Klimatpaverkan": -135.33,
    "out:Initial Cost/MSEK": 14.40875,
    "out:Running cost/(Apt SEK y)": 11126,
    "out:Running Cost over RSP/MSEK": 3.631,
    "out:LCP/MSEK": 1.23,
    "out:ROI% old": 15.5,
    "out:Payback discounted": 8,
    "out:Atemp": 5143,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 48,
    "out:Energy use kWh/m2": 48,
    "out:Energy savings %": 291.7,
    "out:Op energy cost/MSEK": 25,
    "out:El price/MSEK": 23,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 5,
    "out:El bought/kWh/m2": 3268.5,
    "out:Return %": 14,
    "out:Return/kSEK/y": 1997,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 2958000,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 147900,
    "out:EAHP (replacement cost)": 2204400,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 38.54,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 61.265983,
    "out:PV (m2 panels)": 321,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 57484.06,
    "out:PV (kWh sold)": 3197.27,
    "out:PV (% sold (El))": 5.56,
    "out:PV (kWh self-consumed)": 54286.78,
    "out:PV (ratio sold/self-consumed)": 0.06,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 858881,
    "out:EL kWh savings": -137183,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 552447,
    "out:DH kr savings": 781582,
    "out:El kr savings": -233212,
    "out:El kr sold": 4077,
    "out:El kr saved": 92288,
    "out:El kr return": 96364,
    "out:% solar/total": 66,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 28049,
    "out:% savings (space heating)": 95.74,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 3.33,
    "out:Etvv": 0,
    "out:Ef": 42.57,
    "out:heatPumpQuantity": 20,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000202110000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 4.02,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 55.91,
    "out:Electricity (inc PV)": 41.67,
    "out:Total Energy Use Pre PV": 59.91,
    "out:Total Energy Use Post PV": 45.67,
    "out:Primary Energy": 76.65,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 32.71,
    "out:CO2 Operational/m2": 4.3,
    "out:Total CO2/m2": 37.01,
    "out:Total CO2 (tons)": 190.35,
    "out:Klimatpaverkan": -138.89,
    "out:Initial Cost/MSEK": 14.5375,
    "out:Running cost/(Apt SEK y)": 10634,
    "out:Running Cost over RSP/MSEK": 3.47,
    "out:LCP/MSEK": 1.262,
    "out:ROI% old": 15.53,
    "out:Payback discounted": 8,
    "out:Atemp": 5143,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 46,
    "out:Energy use kWh/m2": 46,
    "out:Energy savings %": 308.7,
    "out:Op energy cost/MSEK": 24,
    "out:El price/MSEK": 22,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 4,
    "out:El bought/kWh/m2": 3213,
    "out:Return %": 14,
    "out:Return/kSEK/y": 2017,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 2958000,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 147900,
    "out:EAHP (replacement cost)": 2204400,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 37.71,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 61.265983,
    "out:PV (m2 panels)": 321,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 57484.06,
    "out:PV (kWh sold)": 3435.98,
    "out:PV (% sold (El))": 5.98,
    "out:PV (kWh self-consumed)": 54048.08,
    "out:PV (ratio sold/self-consumed)": 0.06,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 864024,
    "out:EL kWh savings": -132530,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 128574.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 565342,
    "out:DH kr savings": 786262,
    "out:El kr savings": -225301,
    "out:El kr sold": 4381,
    "out:El kr saved": 91882,
    "out:El kr return": 96263,
    "out:% solar/total": 66,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 20655,
    "out:% savings (space heating)": 96.86,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 2.36,
    "out:Etvv": 0,
    "out:Ef": 41.67,
    "out:heatPumpQuantity": 20,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000202110001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 4.58,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 55.42,
    "out:Electricity (inc PV)": 41.82,
    "out:Total Energy Use Pre PV": 60.42,
    "out:Total Energy Use Post PV": 46.82,
    "out:Primary Energy": 77.37,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 33.11,
    "out:CO2 Operational/m2": 4.46,
    "out:Total CO2/m2": 37.57,
    "out:Total CO2 (tons)": 193.23,
    "out:Klimatpaverkan": -136.01,
    "out:Initial Cost/MSEK": 14.66,
    "out:Running cost/(Apt SEK y)": 10855,
    "out:Running Cost over RSP/MSEK": 3.543,
    "out:LCP/MSEK": 1.066,
    "out:ROI% old": 15.32,
    "out:Payback discounted": 8,
    "out:Atemp": 5143,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 47,
    "out:Energy use kWh/m2": 47,
    "out:Energy savings %": 300,
    "out:Op energy cost/MSEK": 24,
    "out:El price/MSEK": 23,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 5,
    "out:El bought/kWh/m2": 3180.4,
    "out:Return %": 14,
    "out:Return/kSEK/y": 2008,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 2958000,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 147900,
    "out:EAHP (replacement cost)": 2204400,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 37.22,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 61.265983,
    "out:PV (m2 panels)": 321,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 57484.06,
    "out:PV (kWh sold)": 3581.92,
    "out:PV (% sold (El))": 6.23,
    "out:PV (kWh self-consumed)": 53902.14,
    "out:PV (ratio sold/self-consumed)": 0.07,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 858881,
    "out:EL kWh savings": -133297,
    "out:Sewage heat recovery cost": 250448,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 559545,
    "out:DH kr savings": 781582,
    "out:El kr savings": -226604,
    "out:El kr sold": 4567,
    "out:El kr saved": 91634,
    "out:El kr return": 96201,
    "out:% solar/total": 66,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 23572,
    "out:% savings (space heating)": 96.42,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 3,
    "out:Etvv": 0,
    "out:Ef": 41.82,
    "out:heatPumpQuantity": 20,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000202110001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 3.35,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 54.53,
    "out:Electricity (inc PV)": 40.9,
    "out:Total Energy Use Pre PV": 57.53,
    "out:Total Energy Use Post PV": 43.9,
    "out:Primary Energy": 75.08,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 33.11,
    "out:CO2 Operational/m2": 3.37,
    "out:Total CO2/m2": 36.48,
    "out:Total CO2 (tons)": 187.6,
    "out:Klimatpaverkan": -141.64,
    "out:Initial Cost/MSEK": 14.7875,
    "out:Running cost/(Apt SEK y)": 10029,
    "out:Running Cost over RSP/MSEK": 3.272,
    "out:LCP/MSEK": 1.21,
    "out:ROI% old": 15.45,
    "out:Payback discounted": 8,
    "out:Atemp": 5143,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 44,
    "out:Energy use kWh/m2": 44,
    "out:Energy savings %": 327.3,
    "out:Op energy cost/MSEK": 22,
    "out:El price/MSEK": 21,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 3,
    "out:El bought/kWh/m2": 3121.2,
    "out:Return %": 14,
    "out:Return/kSEK/y": 2043,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 2958000,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 147900,
    "out:EAHP (replacement cost)": 2204400,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 36.33,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 61.265983,
    "out:PV (m2 panels)": 321,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 57484.06,
    "out:PV (kWh sold)": 3855.42,
    "out:PV (% sold (El))": 6.71,
    "out:PV (kWh self-consumed)": 53628.63,
    "out:PV (ratio sold/self-consumed)": 0.07,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 869167,
    "out:EL kWh savings": -128562,
    "out:Sewage heat recovery cost": 250448,
    "out:Balancing hydronic system cost": 128574.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 577302,
    "out:DH kr savings": 790942,
    "out:El kr savings": -218555,
    "out:El kr sold": 4916,
    "out:El kr saved": 91169,
    "out:El kr return": 96084,
    "out:% solar/total": 66,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 17213,
    "out:% savings (space heating)": 97.39,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 2.1,
    "out:Etvv": 0,
    "out:Ef": 40.9,
    "out:heatPumpQuantity": 20,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000202120000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 5.45,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 56.74,
    "out:Electricity (inc PV)": 38.36,
    "out:Total Energy Use Pre PV": 61.74,
    "out:Total Energy Use Post PV": 43.36,
    "out:Primary Energy": 71.38,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 45.06,
    "out:CO2 Operational/m2": -28.56,
    "out:Total CO2/m2": 16.5,
    "out:Total CO2 (tons)": 84.86,
    "out:Klimatpaverkan": -244.38,
    "out:Initial Cost/MSEK": 15.47875,
    "out:Running cost/(Apt SEK y)": 9140,
    "out:Running Cost over RSP/MSEK": 2.995,
    "out:LCP/MSEK": 0.795,
    "out:ROI% old": 15.02,
    "out:Payback discounted": 8,
    "out:Atemp": 5143,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 43,
    "out:Energy use kWh/m2": 43,
    "out:Energy savings %": 337.2,
    "out:Op energy cost/MSEK": 21,
    "out:El price/MSEK": 19,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 5,
    "out:El bought/kWh/m2": 2938.2,
    "out:Return %": 13,
    "out:Return/kSEK/y": 2080,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 2958000,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 147900,
    "out:EAHP (replacement cost)": 2204400,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 38.54,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.33,
    "out:PV (kWp installed)": 122.531965,
    "out:PV (m2 panels)": 643,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 114968.11,
    "out:PV (kWh sold)": 36943.95,
    "out:PV (% sold (El))": 32.13,
    "out:PV (kWh self-consumed)": 78024.16,
    "out:PV (ratio sold/self-consumed)": 0.47,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 858881,
    "out:EL kWh savings": -115528,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 632287,
    "out:DH kr savings": 781582,
    "out:El kr savings": -196398,
    "out:El kr sold": 47104,
    "out:El kr saved": 132641,
    "out:El kr return": 179745,
    "out:% solar/total": 95,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 28049,
    "out:% savings (space heating)": 95.74,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 3.33,
    "out:Etvv": 0,
    "out:Ef": 38.36,
    "out:heatPumpQuantity": 20,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000202120000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 4.02,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 55.91,
    "out:Electricity (inc PV)": 37.56,
    "out:Total Energy Use Pre PV": 59.91,
    "out:Total Energy Use Post PV": 41.56,
    "out:Primary Energy": 69.25,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 45.06,
    "out:CO2 Operational/m2": -29.71,
    "out:Total CO2/m2": 15.35,
    "out:Total CO2 (tons)": 78.96,
    "out:Klimatpaverkan": -250.28,
    "out:Initial Cost/MSEK": 15.6075,
    "out:Running cost/(Apt SEK y)": 8653,
    "out:Running Cost over RSP/MSEK": 2.836,
    "out:LCP/MSEK": 0.826,
    "out:ROI% old": 15.04,
    "out:Payback discounted": 8,
    "out:Atemp": 5143,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 42,
    "out:Energy use kWh/m2": 42,
    "out:Energy savings %": 347.6,
    "out:Op energy cost/MSEK": 20,
    "out:El price/MSEK": 18,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 4,
    "out:El bought/kWh/m2": 2889.2,
    "out:Return %": 13,
    "out:Return/kSEK/y": 2100,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 2958000,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 147900,
    "out:EAHP (replacement cost)": 2204400,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 37.71,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.33,
    "out:PV (kWp installed)": 122.531965,
    "out:PV (m2 panels)": 643,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 114968.11,
    "out:PV (kWh sold)": 37640.42,
    "out:PV (% sold (El))": 32.74,
    "out:PV (kWh self-consumed)": 77327.69,
    "out:PV (ratio sold/self-consumed)": 0.49,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 864024,
    "out:EL kWh savings": -111387,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 128574.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 644895,
    "out:DH kr savings": 786262,
    "out:El kr savings": -189359,
    "out:El kr sold": 47992,
    "out:El kr saved": 131457,
    "out:El kr return": 179449,
    "out:% solar/total": 95,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 20655,
    "out:% savings (space heating)": 96.86,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 2.36,
    "out:Etvv": 0,
    "out:Ef": 37.56,
    "out:heatPumpQuantity": 20,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000202120001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 4.58,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 55.42,
    "out:Electricity (inc PV)": 37.69,
    "out:Total Energy Use Pre PV": 60.42,
    "out:Total Energy Use Post PV": 42.69,
    "out:Primary Energy": 69.95,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 45.46,
    "out:CO2 Operational/m2": -29.82,
    "out:Total CO2/m2": 15.65,
    "out:Total CO2 (tons)": 80.48,
    "out:Klimatpaverkan": -248.76,
    "out:Initial Cost/MSEK": 15.72875,
    "out:Running cost/(Apt SEK y)": 8877,
    "out:Running Cost over RSP/MSEK": 2.91,
    "out:LCP/MSEK": 0.63,
    "out:ROI% old": 14.86,
    "out:Payback discounted": 8,
    "out:Atemp": 5143,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 43,
    "out:Energy use kWh/m2": 43,
    "out:Energy savings %": 337.2,
    "out:Op energy cost/MSEK": 20,
    "out:El price/MSEK": 19,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 5,
    "out:El bought/kWh/m2": 2860.3,
    "out:Return %": 13,
    "out:Return/kSEK/y": 2091,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 2958000,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 147900,
    "out:EAHP (replacement cost)": 2204400,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 37.22,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.33,
    "out:PV (kWp installed)": 122.531965,
    "out:PV (m2 panels)": 643,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 114968.11,
    "out:PV (kWh sold)": 38053.83,
    "out:PV (% sold (El))": 33.1,
    "out:PV (kWh self-consumed)": 76914.28,
    "out:PV (ratio sold/self-consumed)": 0.49,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 858881,
    "out:EL kWh savings": -112070,
    "out:Sewage heat recovery cost": 250448,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 639582,
    "out:DH kr savings": 781582,
    "out:El kr savings": -190518,
    "out:El kr sold": 48519,
    "out:El kr saved": 130754,
    "out:El kr return": 179273,
    "out:% solar/total": 94,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 23572,
    "out:% savings (space heating)": 96.42,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 3,
    "out:Etvv": 0,
    "out:Ef": 37.69,
    "out:heatPumpQuantity": 20,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000202120001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 3.35,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 54.53,
    "out:Electricity (inc PV)": 36.87,
    "out:Total Energy Use Pre PV": 57.53,
    "out:Total Energy Use Post PV": 39.87,
    "out:Primary Energy": 67.84,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 45.46,
    "out:CO2 Operational/m2": -31.39,
    "out:Total CO2/m2": 14.07,
    "out:Total CO2 (tons)": 72.38,
    "out:Klimatpaverkan": -256.86,
    "out:Initial Cost/MSEK": 15.8575,
    "out:Running cost/(Apt SEK y)": 8056,
    "out:Running Cost over RSP/MSEK": 2.641,
    "out:LCP/MSEK": 0.771,
    "out:ROI% old": 14.98,
    "out:Payback discounted": 8,
    "out:Atemp": 5143,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 40,
    "out:Energy use kWh/m2": 40,
    "out:Energy savings %": 370,
    "out:Op energy cost/MSEK": 18,
    "out:El price/MSEK": 17,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 3,
    "out:El bought/kWh/m2": 2807.9,
    "out:Return %": 13,
    "out:Return/kSEK/y": 2126,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 2958000,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 147900,
    "out:EAHP (replacement cost)": 2204400,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 36.33,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.33,
    "out:PV (kWp installed)": 122.531965,
    "out:PV (m2 panels)": 643,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 114968.11,
    "out:PV (kWh sold)": 38811.8,
    "out:PV (% sold (El))": 33.76,
    "out:PV (kWh self-consumed)": 76156.32,
    "out:PV (ratio sold/self-consumed)": 0.51,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 869167,
    "out:EL kWh savings": -107856,
    "out:Sewage heat recovery cost": 250448,
    "out:Balancing hydronic system cost": 128574.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 657072,
    "out:DH kr savings": 790942,
    "out:El kr savings": -183354,
    "out:El kr sold": 49485,
    "out:El kr saved": 129466,
    "out:El kr return": 178951,
    "out:% solar/total": 93,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 17213,
    "out:% savings (space heating)": 97.39,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 2.1,
    "out:Etvv": 0,
    "out:Ef": 36.87,
    "out:heatPumpQuantity": 20,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000000000000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 107.43,
    "out:DHW (DH)": 43.5,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 15.9,
    "out:Total Energy Use Pre PV": 166.9,
    "out:Total Energy Use Post PV": 166.9,
    "out:Primary Energy": 121.32,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 10.96,
    "out:CO2 Operational/m2": 56.46,
    "out:Total CO2/m2": 67.42,
    "out:Total CO2 (tons)": 346.74,
    "out:Klimatpaverkan": 17.5,
    "out:Initial Cost/MSEK": 4.013375,
    "out:Running cost/(Apt SEK y)": 51906,
    "out:Running Cost over RSP/MSEK": 17.048,
    "out:LCP/MSEK": -1.792,
    "out:ROI% old": 7.91,
    "out:Payback discounted": 15,
    "out:Atemp": 5143,
    "out:Umean": 0.49,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 167,
    "out:Energy use kWh/m2": 167,
    "out:Energy savings %": 12.6,
    "out:Op energy cost/MSEK": 125,
    "out:El price/MSEK": 75,
    "out:Heating price/MSEK": 43,
    "out:DH+ DHW/kWh/m2": 151,
    "out:El bought/kWh/m2": 1113,
    "out:Return %": 7,
    "out:Return/kSEK/y": 284,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 2,
    "out:Payback Time": 50,
    "out:DH kWh savings": 108003,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 98283,
    "out:DH kr savings": 98283,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 552491,
    "out:% savings (space heating)": 16.11,
    "out:Total_Water Heating (DH)": 223720,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 107.43,
    "out:Etvv": 25,
    "out:Ef": 15.9,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z02000000000000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 102.82,
    "out:DHW (DH)": 43.5,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 15.9,
    "out:Total Energy Use Pre PV": 161.9,
    "out:Total Energy Use Post PV": 161.9,
    "out:Primary Energy": 118.09,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 10.96,
    "out:CO2 Operational/m2": 54.66,
    "out:Total CO2/m2": 65.62,
    "out:Total CO2 (tons)": 337.48,
    "out:Klimatpaverkan": 8.24,
    "out:Initial Cost/MSEK": 4.142,
    "out:Running cost/(Apt SEK y)": 50297,
    "out:Running Cost over RSP/MSEK": 16.519,
    "out:LCP/MSEK": -1.392,
    "out:ROI% old": 9.49,
    "out:Payback discounted": 13,
    "out:Atemp": 5143,
    "out:Umean": 0.49,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 162,
    "out:Energy use kWh/m2": 162,
    "out:Energy savings %": 16,
    "out:Op energy cost/MSEK": 121,
    "out:El price/MSEK": 72,
    "out:Heating price/MSEK": 42,
    "out:DH+ DHW/kWh/m2": 146,
    "out:El bought/kWh/m2": 1113,
    "out:Return %": 8,
    "out:Return/kSEK/y": 351,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 133718,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 128574.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 121683,
    "out:DH kr savings": 121683,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 528780,
    "out:% savings (space heating)": 19.71,
    "out:Total_Water Heating (DH)": 223720,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 102.82,
    "out:Etvv": 25,
    "out:Ef": 15.9,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z02000000000001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 107.43,
    "out:DHW (DH)": 36.98,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 15.9,
    "out:Total Energy Use Pre PV": 159.9,
    "out:Total Energy Use Post PV": 159.9,
    "out:Primary Energy": 118.69,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 11.37,
    "out:CO2 Operational/m2": 53.94,
    "out:Total CO2/m2": 65.3,
    "out:Total CO2 (tons)": 335.85,
    "out:Klimatpaverkan": 6.61,
    "out:Initial Cost/MSEK": 4.263875,
    "out:Running cost/(Apt SEK y)": 49653,
    "out:Running Cost over RSP/MSEK": 16.307,
    "out:LCP/MSEK": -1.302,
    "out:ROI% old": 9.92,
    "out:Payback discounted": 12,
    "out:Atemp": 5143,
    "out:Umean": 0.49,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 160,
    "out:Energy use kWh/m2": 160,
    "out:Energy savings %": 17.5,
    "out:Op energy cost/MSEK": 120,
    "out:El price/MSEK": 71,
    "out:Heating price/MSEK": 41,
    "out:DH+ DHW/kWh/m2": 144,
    "out:El bought/kWh/m2": 1113,
    "out:Return %": 9,
    "out:Return/kSEK/y": 378,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 144004,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 250448,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 131044,
    "out:DH kr savings": 131044,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 552491,
    "out:% savings (space heating)": 16.11,
    "out:Total_Water Heating (DH)": 190162,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 107.43,
    "out:Etvv": 21.25,
    "out:Ef": 15.9,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z02000000000001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 102.82,
    "out:DHW (DH)": 36.98,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 15.9,
    "out:Total Energy Use Pre PV": 155.9,
    "out:Total Energy Use Post PV": 155.9,
    "out:Primary Energy": 115.47,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 11.37,
    "out:CO2 Operational/m2": 52.5,
    "out:Total CO2/m2": 63.86,
    "out:Total CO2 (tons)": 328.44,
    "out:Klimatpaverkan": -0.8,
    "out:Initial Cost/MSEK": 4.392375,
    "out:Running cost/(Apt SEK y)": 48366,
    "out:Running Cost over RSP/MSEK": 15.884,
    "out:LCP/MSEK": -1.007,
    "out:ROI% old": 11.01,
    "out:Payback discounted": 11,
    "out:Atemp": 5143,
    "out:Umean": 0.49,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 156,
    "out:Energy use kWh/m2": 156,
    "out:Energy savings %": 20.5,
    "out:Op energy cost/MSEK": 116,
    "out:El price/MSEK": 70,
    "out:Heating price/MSEK": 40,
    "out:DH+ DHW/kWh/m2": 140,
    "out:El bought/kWh/m2": 1113,
    "out:Return %": 10,
    "out:Return/kSEK/y": 433,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 164576,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 250448,
    "out:Balancing hydronic system cost": 128574.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 149764,
    "out:DH kr savings": 149764,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 528780,
    "out:% savings (space heating)": 19.71,
    "out:Total_Water Heating (DH)": 190162,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 102.82,
    "out:Etvv": 21.25,
    "out:Ef": 15.9,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z02000000010000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 107.43,
    "out:DHW (DH)": 43.5,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 10.73,
    "out:Total Energy Use Pre PV": 166.9,
    "out:Total Energy Use Post PV": 161.73,
    "out:Primary Energy": 112.01,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 23.32,
    "out:CO2 Operational/m2": 25.46,
    "out:Total CO2/m2": 48.78,
    "out:Total CO2 (tons)": 250.87,
    "out:Klimatpaverkan": -78.37,
    "out:Initial Cost/MSEK": 5.083125,
    "out:Running cost/(Apt SEK y)": 49892,
    "out:Running Cost over RSP/MSEK": 16.404,
    "out:LCP/MSEK": -2.218,
    "out:ROI% old": 8.05,
    "out:Payback discounted": 15,
    "out:Atemp": 5143,
    "out:Umean": 0.49,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 162,
    "out:Energy use kWh/m2": 162,
    "out:Energy savings %": 16,
    "out:Op energy cost/MSEK": 121,
    "out:El price/MSEK": 71,
    "out:Heating price/MSEK": 43,
    "out:DH+ DHW/kWh/m2": 151,
    "out:El bought/kWh/m2": 758.1,
    "out:Return %": 7,
    "out:Return/kSEK/y": 368,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 61.265983,
    "out:PV (m2 panels)": 321,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 57484.06,
    "out:PV (kWh sold)": 30886.05,
    "out:PV (% sold (El))": 53.73,
    "out:PV (kWh self-consumed)": 26598,
    "out:PV (ratio sold/self-consumed)": 1.16,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 108003,
    "out:EL kWh savings": 26598,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 182879,
    "out:DH kr savings": 98283,
    "out:El kr savings": 45217,
    "out:El kr sold": 39380,
    "out:El kr saved": 45217,
    "out:El kr return": 84596,
    "out:% solar/total": 33,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 552491,
    "out:% savings (space heating)": 16.11,
    "out:Total_Water Heating (DH)": 223720,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 107.43,
    "out:Etvv": 25,
    "out:Ef": 10.73,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z02000000010000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 102.82,
    "out:DHW (DH)": 43.5,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 10.73,
    "out:Total Energy Use Pre PV": 161.9,
    "out:Total Energy Use Post PV": 156.73,
    "out:Primary Energy": 108.78,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 23.32,
    "out:CO2 Operational/m2": 23.66,
    "out:Total CO2/m2": 46.98,
    "out:Total CO2 (tons)": 241.61,
    "out:Klimatpaverkan": -87.63,
    "out:Initial Cost/MSEK": 5.21175,
    "out:Running cost/(Apt SEK y)": 48283,
    "out:Running Cost over RSP/MSEK": 15.875,
    "out:LCP/MSEK": -1.818,
    "out:ROI% old": 9.3,
    "out:Payback discounted": 13,
    "out:Atemp": 5143,
    "out:Umean": 0.49,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 157,
    "out:Energy use kWh/m2": 157,
    "out:Energy savings %": 19.7,
    "out:Op energy cost/MSEK": 117,
    "out:El price/MSEK": 68,
    "out:Heating price/MSEK": 42,
    "out:DH+ DHW/kWh/m2": 146,
    "out:El bought/kWh/m2": 758.1,
    "out:Return %": 8,
    "out:Return/kSEK/y": 436,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 61.265983,
    "out:PV (m2 panels)": 321,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 57484.06,
    "out:PV (kWh sold)": 30886.05,
    "out:PV (% sold (El))": 53.73,
    "out:PV (kWh self-consumed)": 26598,
    "out:PV (ratio sold/self-consumed)": 1.16,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 133718,
    "out:EL kWh savings": 26598,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 128574.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 206280,
    "out:DH kr savings": 121683,
    "out:El kr savings": 45217,
    "out:El kr sold": 39380,
    "out:El kr saved": 45217,
    "out:El kr return": 84596,
    "out:% solar/total": 33,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 528780,
    "out:% savings (space heating)": 19.71,
    "out:Total_Water Heating (DH)": 223720,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 102.82,
    "out:Etvv": 25,
    "out:Ef": 10.73,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z02000000010001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 107.43,
    "out:DHW (DH)": 36.98,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 10.73,
    "out:Total Energy Use Pre PV": 159.9,
    "out:Total Energy Use Post PV": 154.73,
    "out:Primary Energy": 109.38,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 23.72,
    "out:CO2 Operational/m2": 22.94,
    "out:Total CO2/m2": 46.66,
    "out:Total CO2 (tons)": 239.97,
    "out:Klimatpaverkan": -89.27,
    "out:Initial Cost/MSEK": 5.333625,
    "out:Running cost/(Apt SEK y)": 47639,
    "out:Running Cost over RSP/MSEK": 15.663,
    "out:LCP/MSEK": -1.728,
    "out:ROI% old": 9.66,
    "out:Payback discounted": 13,
    "out:Atemp": 5143,
    "out:Umean": 0.49,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 155,
    "out:Energy use kWh/m2": 155,
    "out:Energy savings %": 21.3,
    "out:Op energy cost/MSEK": 116,
    "out:El price/MSEK": 68,
    "out:Heating price/MSEK": 41,
    "out:DH+ DHW/kWh/m2": 144,
    "out:El bought/kWh/m2": 758.1,
    "out:Return %": 9,
    "out:Return/kSEK/y": 463,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 61.265983,
    "out:PV (m2 panels)": 321,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 57484.06,
    "out:PV (kWh sold)": 30886.05,
    "out:PV (% sold (El))": 53.73,
    "out:PV (kWh self-consumed)": 26598,
    "out:PV (ratio sold/self-consumed)": 1.16,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 144004,
    "out:EL kWh savings": 26598,
    "out:Sewage heat recovery cost": 250448,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 215640,
    "out:DH kr savings": 131044,
    "out:El kr savings": 45217,
    "out:El kr sold": 39380,
    "out:El kr saved": 45217,
    "out:El kr return": 84596,
    "out:% solar/total": 33,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 552491,
    "out:% savings (space heating)": 16.11,
    "out:Total_Water Heating (DH)": 190162,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 107.43,
    "out:Etvv": 21.25,
    "out:Ef": 10.73,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z02000000010001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 102.82,
    "out:DHW (DH)": 36.98,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 10.73,
    "out:Total Energy Use Pre PV": 155.9,
    "out:Total Energy Use Post PV": 150.73,
    "out:Primary Energy": 106.16,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 23.72,
    "out:CO2 Operational/m2": 21.5,
    "out:Total CO2/m2": 45.22,
    "out:Total CO2 (tons)": 232.57,
    "out:Klimatpaverkan": -96.67,
    "out:Initial Cost/MSEK": 5.46225,
    "out:Running cost/(Apt SEK y)": 46352,
    "out:Running Cost over RSP/MSEK": 15.24,
    "out:LCP/MSEK": -1.433,
    "out:ROI% old": 10.54,
    "out:Payback discounted": 11,
    "out:Atemp": 5143,
    "out:Umean": 0.49,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 151,
    "out:Energy use kWh/m2": 151,
    "out:Energy savings %": 24.5,
    "out:Op energy cost/MSEK": 113,
    "out:El price/MSEK": 66,
    "out:Heating price/MSEK": 40,
    "out:DH+ DHW/kWh/m2": 140,
    "out:El bought/kWh/m2": 758.1,
    "out:Return %": 9,
    "out:Return/kSEK/y": 517,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 61.265983,
    "out:PV (m2 panels)": 321,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 57484.06,
    "out:PV (kWh sold)": 30886.05,
    "out:PV (% sold (El))": 53.73,
    "out:PV (kWh self-consumed)": 26598,
    "out:PV (ratio sold/self-consumed)": 1.16,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 164576,
    "out:EL kWh savings": 26598,
    "out:Sewage heat recovery cost": 250448,
    "out:Balancing hydronic system cost": 128574.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 234360,
    "out:DH kr savings": 149764,
    "out:El kr savings": 45217,
    "out:El kr sold": 39380,
    "out:El kr saved": 45217,
    "out:El kr return": 84596,
    "out:% solar/total": 33,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 528780,
    "out:% savings (space heating)": 19.71,
    "out:Total_Water Heating (DH)": 190162,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 102.82,
    "out:Etvv": 21.25,
    "out:Ef": 10.73,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z02000000020000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 107.43,
    "out:DHW (DH)": 43.5,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 9.88,
    "out:Total Energy Use Pre PV": 166.9,
    "out:Total Energy Use Post PV": 160.88,
    "out:Primary Energy": 110.48,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 35.67,
    "out:CO2 Operational/m2": -27.22,
    "out:Total CO2/m2": 8.45,
    "out:Total CO2 (tons)": 43.47,
    "out:Klimatpaverkan": -285.77,
    "out:Initial Cost/MSEK": 6.153,
    "out:Running cost/(Apt SEK y)": 48103,
    "out:Running Cost over RSP/MSEK": 15.832,
    "out:LCP/MSEK": -2.716,
    "out:ROI% old": 7.98,
    "out:Payback discounted": 15,
    "out:Atemp": 5143,
    "out:Umean": 0.49,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 161,
    "out:Energy use kWh/m2": 161,
    "out:Energy savings %": 16.8,
    "out:Op energy cost/MSEK": 118,
    "out:El price/MSEK": 67,
    "out:Heating price/MSEK": 43,
    "out:DH+ DHW/kWh/m2": 151,
    "out:El bought/kWh/m2": 696.7,
    "out:Return %": 7,
    "out:Return/kSEK/y": 444,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.33,
    "out:PV (kWp installed)": 122.531965,
    "out:PV (m2 panels)": 643,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 114968.11,
    "out:PV (kWh sold)": 84004.99,
    "out:PV (% sold (El))": 73.07,
    "out:PV (kWh self-consumed)": 30963.12,
    "out:PV (ratio sold/self-consumed)": 2.71,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 108003,
    "out:EL kWh savings": 30963,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 258026,
    "out:DH kr savings": 98283,
    "out:El kr savings": 52637,
    "out:El kr sold": 107106,
    "out:El kr saved": 52637,
    "out:El kr return": 159744,
    "out:% solar/total": 38,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 552491,
    "out:% savings (space heating)": 16.11,
    "out:Total_Water Heating (DH)": 223720,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 107.43,
    "out:Etvv": 25,
    "out:Ef": 9.88,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z02000000020000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 102.82,
    "out:DHW (DH)": 43.5,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 9.88,
    "out:Total Energy Use Pre PV": 161.9,
    "out:Total Energy Use Post PV": 155.88,
    "out:Primary Energy": 107.25,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 35.67,
    "out:CO2 Operational/m2": -29.02,
    "out:Total CO2/m2": 6.65,
    "out:Total CO2 (tons)": 34.21,
    "out:Klimatpaverkan": -295.03,
    "out:Initial Cost/MSEK": 6.2815,
    "out:Running cost/(Apt SEK y)": 46494,
    "out:Running Cost over RSP/MSEK": 15.304,
    "out:LCP/MSEK": -2.316,
    "out:ROI% old": 9.02,
    "out:Payback discounted": 13,
    "out:Atemp": 5143,
    "out:Umean": 0.49,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 156,
    "out:Energy use kWh/m2": 156,
    "out:Energy savings %": 20.5,
    "out:Op energy cost/MSEK": 114,
    "out:El price/MSEK": 65,
    "out:Heating price/MSEK": 42,
    "out:DH+ DHW/kWh/m2": 146,
    "out:El bought/kWh/m2": 696.7,
    "out:Return %": 8,
    "out:Return/kSEK/y": 511,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.33,
    "out:PV (kWp installed)": 122.531965,
    "out:PV (m2 panels)": 643,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 114968.11,
    "out:PV (kWh sold)": 84004.99,
    "out:PV (% sold (El))": 73.07,
    "out:PV (kWh self-consumed)": 30963.12,
    "out:PV (ratio sold/self-consumed)": 2.71,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 133718,
    "out:EL kWh savings": 30963,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 128574.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 281427,
    "out:DH kr savings": 121683,
    "out:El kr savings": 52637,
    "out:El kr sold": 107106,
    "out:El kr saved": 52637,
    "out:El kr return": 159744,
    "out:% solar/total": 38,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 528780,
    "out:% savings (space heating)": 19.71,
    "out:Total_Water Heating (DH)": 223720,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 102.82,
    "out:Etvv": 25,
    "out:Ef": 9.88,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z02000000020001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 107.43,
    "out:DHW (DH)": 36.98,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 9.88,
    "out:Total Energy Use Pre PV": 159.9,
    "out:Total Energy Use Post PV": 153.88,
    "out:Primary Energy": 107.86,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 36.07,
    "out:CO2 Operational/m2": -29.74,
    "out:Total CO2/m2": 6.33,
    "out:Total CO2 (tons)": 32.58,
    "out:Klimatpaverkan": -296.66,
    "out:Initial Cost/MSEK": 6.403375,
    "out:Running cost/(Apt SEK y)": 45850,
    "out:Running Cost over RSP/MSEK": 15.092,
    "out:LCP/MSEK": -2.226,
    "out:ROI% old": 9.32,
    "out:Payback discounted": 13,
    "out:Atemp": 5143,
    "out:Umean": 0.49,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 154,
    "out:Energy use kWh/m2": 154,
    "out:Energy savings %": 22.1,
    "out:Op energy cost/MSEK": 112,
    "out:El price/MSEK": 64,
    "out:Heating price/MSEK": 41,
    "out:DH+ DHW/kWh/m2": 144,
    "out:El bought/kWh/m2": 696.7,
    "out:Return %": 8,
    "out:Return/kSEK/y": 538,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.33,
    "out:PV (kWp installed)": 122.531965,
    "out:PV (m2 panels)": 643,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 114968.11,
    "out:PV (kWh sold)": 84004.99,
    "out:PV (% sold (El))": 73.07,
    "out:PV (kWh self-consumed)": 30963.12,
    "out:PV (ratio sold/self-consumed)": 2.71,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 144004,
    "out:EL kWh savings": 30963,
    "out:Sewage heat recovery cost": 250448,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 290787,
    "out:DH kr savings": 131044,
    "out:El kr savings": 52637,
    "out:El kr sold": 107106,
    "out:El kr saved": 52637,
    "out:El kr return": 159744,
    "out:% solar/total": 38,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 552491,
    "out:% savings (space heating)": 16.11,
    "out:Total_Water Heating (DH)": 190162,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 107.43,
    "out:Etvv": 21.25,
    "out:Ef": 9.88,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z02000000020001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 102.82,
    "out:DHW (DH)": 36.98,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 9.88,
    "out:Total Energy Use Pre PV": 155.9,
    "out:Total Energy Use Post PV": 149.88,
    "out:Primary Energy": 104.63,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 36.07,
    "out:CO2 Operational/m2": -31.18,
    "out:Total CO2/m2": 4.89,
    "out:Total CO2 (tons)": 25.17,
    "out:Klimatpaverkan": -304.07,
    "out:Initial Cost/MSEK": 6.532,
    "out:Running cost/(Apt SEK y)": 44563,
    "out:Running Cost over RSP/MSEK": 14.669,
    "out:LCP/MSEK": -1.932,
    "out:ROI% old": 10.06,
    "out:Payback discounted": 12,
    "out:Atemp": 5143,
    "out:Umean": 0.49,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 150,
    "out:Energy use kWh/m2": 150,
    "out:Energy savings %": 25.3,
    "out:Op energy cost/MSEK": 109,
    "out:El price/MSEK": 62,
    "out:Heating price/MSEK": 40,
    "out:DH+ DHW/kWh/m2": 140,
    "out:El bought/kWh/m2": 696.7,
    "out:Return %": 9,
    "out:Return/kSEK/y": 592,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.33,
    "out:PV (kWp installed)": 122.531965,
    "out:PV (m2 panels)": 643,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 114968.11,
    "out:PV (kWh sold)": 84004.99,
    "out:PV (% sold (El))": 73.07,
    "out:PV (kWh self-consumed)": 30963.12,
    "out:PV (ratio sold/self-consumed)": 2.71,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 164576,
    "out:EL kWh savings": 30963,
    "out:Sewage heat recovery cost": 250448,
    "out:Balancing hydronic system cost": 128574.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 309508,
    "out:DH kr savings": 149764,
    "out:El kr savings": 52637,
    "out:El kr sold": 107106,
    "out:El kr saved": 52637,
    "out:El kr return": 159744,
    "out:% solar/total": 38,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 528780,
    "out:% savings (space heating)": 19.71,
    "out:Total_Water Heating (DH)": 190162,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 102.82,
    "out:Etvv": 21.25,
    "out:Ef": 9.88,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z02000000100000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 107.43,
    "out:DHW (DH)": 34.8,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 15.9,
    "out:Total Energy Use Pre PV": 157.9,
    "out:Total Energy Use Post PV": 157.9,
    "out:Primary Energy": 117.82,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 10.96,
    "out:CO2 Operational/m2": 53.22,
    "out:Total CO2/m2": 64.18,
    "out:Total CO2 (tons)": 330.08,
    "out:Klimatpaverkan": 0.84,
    "out:Initial Cost/MSEK": 4.12425,
    "out:Running cost/(Apt SEK y)": 49010,
    "out:Running Cost over RSP/MSEK": 16.096,
    "out:LCP/MSEK": -0.951,
    "out:ROI% old": 10.99,
    "out:Payback discounted": 11,
    "out:Atemp": 5143,
    "out:Umean": 0.49,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 158,
    "out:Energy use kWh/m2": 158,
    "out:Energy savings %": 19,
    "out:Op energy cost/MSEK": 118,
    "out:El price/MSEK": 71,
    "out:Heating price/MSEK": 41,
    "out:DH+ DHW/kWh/m2": 142,
    "out:El bought/kWh/m2": 1113,
    "out:Return %": 10,
    "out:Return/kSEK/y": 406,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 154290,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 140404,
    "out:DH kr savings": 140404,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 552491,
    "out:% savings (space heating)": 16.11,
    "out:Total_Water Heating (DH)": 178976,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 107.43,
    "out:Etvv": 20,
    "out:Ef": 15.9,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z02000000100000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 102.82,
    "out:DHW (DH)": 34.8,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 15.9,
    "out:Total Energy Use Pre PV": 153.9,
    "out:Total Energy Use Post PV": 153.9,
    "out:Primary Energy": 114.59,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 10.96,
    "out:CO2 Operational/m2": 51.78,
    "out:Total CO2/m2": 62.74,
    "out:Total CO2 (tons)": 322.67,
    "out:Klimatpaverkan": -6.57,
    "out:Initial Cost/MSEK": 4.25275,
    "out:Running cost/(Apt SEK y)": 47722,
    "out:Running Cost over RSP/MSEK": 15.672,
    "out:LCP/MSEK": -0.656,
    "out:ROI% old": 12.08,
    "out:Payback discounted": 10,
    "out:Atemp": 5143,
    "out:Umean": 0.49,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 154,
    "out:Energy use kWh/m2": 154,
    "out:Energy savings %": 22.1,
    "out:Op energy cost/MSEK": 115,
    "out:El price/MSEK": 69,
    "out:Heating price/MSEK": 40,
    "out:DH+ DHW/kWh/m2": 138,
    "out:El bought/kWh/m2": 1113,
    "out:Return %": 11,
    "out:Return/kSEK/y": 460,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 174862,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 128574.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 159124,
    "out:DH kr savings": 159124,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 528780,
    "out:% savings (space heating)": 19.71,
    "out:Total_Water Heating (DH)": 178976,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 102.82,
    "out:Etvv": 20,
    "out:Ef": 15.9,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z02000000100001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 107.43,
    "out:DHW (DH)": 29.58,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 15.9,
    "out:Total Energy Use Pre PV": 152.9,
    "out:Total Energy Use Post PV": 152.9,
    "out:Primary Energy": 115.72,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 11.37,
    "out:CO2 Operational/m2": 51.42,
    "out:Total CO2/m2": 62.78,
    "out:Total CO2 (tons)": 322.89,
    "out:Klimatpaverkan": -6.35,
    "out:Initial Cost/MSEK": 4.374625,
    "out:Running cost/(Apt SEK y)": 47400,
    "out:Running Cost over RSP/MSEK": 15.567,
    "out:LCP/MSEK": -0.672,
    "out:ROI% old": 12.09,
    "out:Payback discounted": 10,
    "out:Atemp": 5143,
    "out:Umean": 0.49,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 153,
    "out:Energy use kWh/m2": 153,
    "out:Energy savings %": 22.9,
    "out:Op energy cost/MSEK": 114,
    "out:El price/MSEK": 68,
    "out:Heating price/MSEK": 39,
    "out:DH+ DHW/kWh/m2": 137,
    "out:El bought/kWh/m2": 1113,
    "out:Return %": 11,
    "out:Return/kSEK/y": 473,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 180005,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 250448,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 163805,
    "out:DH kr savings": 163805,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 552491,
    "out:% savings (space heating)": 16.11,
    "out:Total_Water Heating (DH)": 152130,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 107.43,
    "out:Etvv": 17,
    "out:Ef": 15.9,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z02000000100001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 102.82,
    "out:DHW (DH)": 29.58,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 15.9,
    "out:Total Energy Use Pre PV": 147.9,
    "out:Total Energy Use Post PV": 147.9,
    "out:Primary Energy": 112.49,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 11.37,
    "out:CO2 Operational/m2": 49.62,
    "out:Total CO2/m2": 60.98,
    "out:Total CO2 (tons)": 313.63,
    "out:Klimatpaverkan": -15.61,
    "out:Initial Cost/MSEK": 4.50325,
    "out:Running cost/(Apt SEK y)": 45791,
    "out:Running Cost over RSP/MSEK": 15.038,
    "out:LCP/MSEK": -0.272,
    "out:ROI% old": 13.42,
    "out:Payback discounted": 9,
    "out:Atemp": 5143,
    "out:Umean": 0.49,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 148,
    "out:Energy use kWh/m2": 148,
    "out:Energy savings %": 27,
    "out:Op energy cost/MSEK": 110,
    "out:El price/MSEK": 66,
    "out:Heating price/MSEK": 38,
    "out:DH+ DHW/kWh/m2": 132,
    "out:El bought/kWh/m2": 1113,
    "out:Return %": 12,
    "out:Return/kSEK/y": 541,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 205720,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 250448,
    "out:Balancing hydronic system cost": 128574.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 187205,
    "out:DH kr savings": 187205,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 528780,
    "out:% savings (space heating)": 19.71,
    "out:Total_Water Heating (DH)": 152130,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 102.82,
    "out:Etvv": 17,
    "out:Ef": 15.9,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z02000000110000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 107.43,
    "out:DHW (DH)": 34.8,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 10.73,
    "out:Total Energy Use Pre PV": 157.9,
    "out:Total Energy Use Post PV": 152.73,
    "out:Primary Energy": 108.51,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 23.32,
    "out:CO2 Operational/m2": 22.22,
    "out:Total CO2/m2": 45.54,
    "out:Total CO2 (tons)": 234.2,
    "out:Klimatpaverkan": -95.04,
    "out:Initial Cost/MSEK": 5.194,
    "out:Running cost/(Apt SEK y)": 46995,
    "out:Running Cost over RSP/MSEK": 15.452,
    "out:LCP/MSEK": -1.377,
    "out:ROI% old": 10.5,
    "out:Payback discounted": 12,
    "out:Atemp": 5143,
    "out:Umean": 0.49,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 153,
    "out:Energy use kWh/m2": 153,
    "out:Energy savings %": 22.9,
    "out:Op energy cost/MSEK": 114,
    "out:El price/MSEK": 67,
    "out:Heating price/MSEK": 41,
    "out:DH+ DHW/kWh/m2": 142,
    "out:El bought/kWh/m2": 758.1,
    "out:Return %": 9,
    "out:Return/kSEK/y": 490,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 61.265983,
    "out:PV (m2 panels)": 321,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 57484.06,
    "out:PV (kWh sold)": 30886.05,
    "out:PV (% sold (El))": 53.73,
    "out:PV (kWh self-consumed)": 26598,
    "out:PV (ratio sold/self-consumed)": 1.16,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 154290,
    "out:EL kWh savings": 26598,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 225000,
    "out:DH kr savings": 140404,
    "out:El kr savings": 45217,
    "out:El kr sold": 39380,
    "out:El kr saved": 45217,
    "out:El kr return": 84596,
    "out:% solar/total": 33,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 552491,
    "out:% savings (space heating)": 16.11,
    "out:Total_Water Heating (DH)": 178976,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 107.43,
    "out:Etvv": 20,
    "out:Ef": 10.73,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z02000000110000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 102.82,
    "out:DHW (DH)": 34.8,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 10.73,
    "out:Total Energy Use Pre PV": 153.9,
    "out:Total Energy Use Post PV": 148.73,
    "out:Primary Energy": 105.28,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 23.32,
    "out:CO2 Operational/m2": 20.78,
    "out:Total CO2/m2": 44.1,
    "out:Total CO2 (tons)": 226.8,
    "out:Klimatpaverkan": -102.44,
    "out:Initial Cost/MSEK": 5.322625,
    "out:Running cost/(Apt SEK y)": 45708,
    "out:Running Cost over RSP/MSEK": 15.029,
    "out:LCP/MSEK": -1.082,
    "out:ROI% old": 11.38,
    "out:Payback discounted": 11,
    "out:Atemp": 5143,
    "out:Umean": 0.49,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 149,
    "out:Energy use kWh/m2": 149,
    "out:Energy savings %": 26.2,
    "out:Op energy cost/MSEK": 111,
    "out:El price/MSEK": 65,
    "out:Heating price/MSEK": 40,
    "out:DH+ DHW/kWh/m2": 138,
    "out:El bought/kWh/m2": 758.1,
    "out:Return %": 10,
    "out:Return/kSEK/y": 544,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 61.265983,
    "out:PV (m2 panels)": 321,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 57484.06,
    "out:PV (kWh sold)": 30886.05,
    "out:PV (% sold (El))": 53.73,
    "out:PV (kWh self-consumed)": 26598,
    "out:PV (ratio sold/self-consumed)": 1.16,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 174862,
    "out:EL kWh savings": 26598,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 128574.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 243721,
    "out:DH kr savings": 159124,
    "out:El kr savings": 45217,
    "out:El kr sold": 39380,
    "out:El kr saved": 45217,
    "out:El kr return": 84596,
    "out:% solar/total": 33,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 528780,
    "out:% savings (space heating)": 19.71,
    "out:Total_Water Heating (DH)": 178976,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 102.82,
    "out:Etvv": 20,
    "out:Ef": 10.73,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z02000000110001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 107.43,
    "out:DHW (DH)": 29.58,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 10.73,
    "out:Total Energy Use Pre PV": 152.9,
    "out:Total Energy Use Post PV": 147.73,
    "out:Primary Energy": 106.41,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 23.72,
    "out:CO2 Operational/m2": 20.42,
    "out:Total CO2/m2": 44.14,
    "out:Total CO2 (tons)": 227.01,
    "out:Klimatpaverkan": -102.23,
    "out:Initial Cost/MSEK": 5.4445,
    "out:Running cost/(Apt SEK y)": 45386,
    "out:Running Cost over RSP/MSEK": 14.923,
    "out:LCP/MSEK": -1.098,
    "out:ROI% old": 11.4,
    "out:Payback discounted": 11,
    "out:Atemp": 5143,
    "out:Umean": 0.49,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 148,
    "out:Energy use kWh/m2": 148,
    "out:Energy savings %": 27,
    "out:Op energy cost/MSEK": 110,
    "out:El price/MSEK": 64,
    "out:Heating price/MSEK": 39,
    "out:DH+ DHW/kWh/m2": 137,
    "out:El bought/kWh/m2": 758.1,
    "out:Return %": 10,
    "out:Return/kSEK/y": 558,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 61.265983,
    "out:PV (m2 panels)": 321,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 57484.06,
    "out:PV (kWh sold)": 30886.05,
    "out:PV (% sold (El))": 53.73,
    "out:PV (kWh self-consumed)": 26598,
    "out:PV (ratio sold/self-consumed)": 1.16,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 180005,
    "out:EL kWh savings": 26598,
    "out:Sewage heat recovery cost": 250448,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 248401,
    "out:DH kr savings": 163805,
    "out:El kr savings": 45217,
    "out:El kr sold": 39380,
    "out:El kr saved": 45217,
    "out:El kr return": 84596,
    "out:% solar/total": 33,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 552491,
    "out:% savings (space heating)": 16.11,
    "out:Total_Water Heating (DH)": 152130,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 107.43,
    "out:Etvv": 17,
    "out:Ef": 10.73,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z02000000110001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 102.82,
    "out:DHW (DH)": 29.58,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 10.73,
    "out:Total Energy Use Pre PV": 147.9,
    "out:Total Energy Use Post PV": 142.73,
    "out:Primary Energy": 103.18,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 23.72,
    "out:CO2 Operational/m2": 18.62,
    "out:Total CO2/m2": 42.34,
    "out:Total CO2 (tons)": 217.76,
    "out:Klimatpaverkan": -111.48,
    "out:Initial Cost/MSEK": 5.573,
    "out:Running cost/(Apt SEK y)": 43777,
    "out:Running Cost over RSP/MSEK": 14.394,
    "out:LCP/MSEK": -0.698,
    "out:ROI% old": 12.5,
    "out:Payback discounted": 10,
    "out:Atemp": 5143,
    "out:Umean": 0.49,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 143,
    "out:Energy use kWh/m2": 143,
    "out:Energy savings %": 31.5,
    "out:Op energy cost/MSEK": 106,
    "out:El price/MSEK": 62,
    "out:Heating price/MSEK": 38,
    "out:DH+ DHW/kWh/m2": 132,
    "out:El bought/kWh/m2": 758.1,
    "out:Return %": 11,
    "out:Return/kSEK/y": 625,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 61.265983,
    "out:PV (m2 panels)": 321,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 57484.06,
    "out:PV (kWh sold)": 30886.05,
    "out:PV (% sold (El))": 53.73,
    "out:PV (kWh self-consumed)": 26598,
    "out:PV (ratio sold/self-consumed)": 1.16,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 205720,
    "out:EL kWh savings": 26598,
    "out:Sewage heat recovery cost": 250448,
    "out:Balancing hydronic system cost": 128574.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 271802,
    "out:DH kr savings": 187205,
    "out:El kr savings": 45217,
    "out:El kr sold": 39380,
    "out:El kr saved": 45217,
    "out:El kr return": 84596,
    "out:% solar/total": 33,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 528780,
    "out:% savings (space heating)": 19.71,
    "out:Total_Water Heating (DH)": 152130,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 102.82,
    "out:Etvv": 17,
    "out:Ef": 10.73,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z02000000120000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 107.43,
    "out:DHW (DH)": 34.8,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 9.88,
    "out:Total Energy Use Pre PV": 157.9,
    "out:Total Energy Use Post PV": 151.88,
    "out:Primary Energy": 106.98,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 35.67,
    "out:CO2 Operational/m2": -30.46,
    "out:Total CO2/m2": 5.21,
    "out:Total CO2 (tons)": 26.8,
    "out:Klimatpaverkan": -302.44,
    "out:Initial Cost/MSEK": 6.26375,
    "out:Running cost/(Apt SEK y)": 45206,
    "out:Running Cost over RSP/MSEK": 14.88,
    "out:LCP/MSEK": -1.875,
    "out:ROI% old": 10.01,
    "out:Payback discounted": 12,
    "out:Atemp": 5143,
    "out:Umean": 0.49,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 152,
    "out:Energy use kWh/m2": 152,
    "out:Energy savings %": 23.7,
    "out:Op energy cost/MSEK": 111,
    "out:El price/MSEK": 63,
    "out:Heating price/MSEK": 41,
    "out:DH+ DHW/kWh/m2": 142,
    "out:El bought/kWh/m2": 696.7,
    "out:Return %": 9,
    "out:Return/kSEK/y": 565,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.33,
    "out:PV (kWp installed)": 122.531965,
    "out:PV (m2 panels)": 643,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 114968.11,
    "out:PV (kWh sold)": 84004.99,
    "out:PV (% sold (El))": 73.07,
    "out:PV (kWh self-consumed)": 30963.12,
    "out:PV (ratio sold/self-consumed)": 2.71,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 154290,
    "out:EL kWh savings": 30963,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 300148,
    "out:DH kr savings": 140404,
    "out:El kr savings": 52637,
    "out:El kr sold": 107106,
    "out:El kr saved": 52637,
    "out:El kr return": 159744,
    "out:% solar/total": 38,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 552491,
    "out:% savings (space heating)": 16.11,
    "out:Total_Water Heating (DH)": 178976,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 107.43,
    "out:Etvv": 20,
    "out:Ef": 9.88,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z02000000120000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 102.82,
    "out:DHW (DH)": 34.8,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 9.88,
    "out:Total Energy Use Pre PV": 153.9,
    "out:Total Energy Use Post PV": 147.88,
    "out:Primary Energy": 103.75,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 35.67,
    "out:CO2 Operational/m2": -31.9,
    "out:Total CO2/m2": 3.77,
    "out:Total CO2 (tons)": 19.4,
    "out:Klimatpaverkan": -309.84,
    "out:Initial Cost/MSEK": 6.392375,
    "out:Running cost/(Apt SEK y)": 43919,
    "out:Running Cost over RSP/MSEK": 14.457,
    "out:LCP/MSEK": -1.581,
    "out:ROI% old": 10.75,
    "out:Payback discounted": 11,
    "out:Atemp": 5143,
    "out:Umean": 0.49,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 148,
    "out:Energy use kWh/m2": 148,
    "out:Energy savings %": 27,
    "out:Op energy cost/MSEK": 107,
    "out:El price/MSEK": 61,
    "out:Heating price/MSEK": 40,
    "out:DH+ DHW/kWh/m2": 138,
    "out:El bought/kWh/m2": 696.7,
    "out:Return %": 10,
    "out:Return/kSEK/y": 619,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.33,
    "out:PV (kWp installed)": 122.531965,
    "out:PV (m2 panels)": 643,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 114968.11,
    "out:PV (kWh sold)": 84004.99,
    "out:PV (% sold (El))": 73.07,
    "out:PV (kWh self-consumed)": 30963.12,
    "out:PV (ratio sold/self-consumed)": 2.71,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 174862,
    "out:EL kWh savings": 30963,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 128574.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 318868,
    "out:DH kr savings": 159124,
    "out:El kr savings": 52637,
    "out:El kr sold": 107106,
    "out:El kr saved": 52637,
    "out:El kr return": 159744,
    "out:% solar/total": 38,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 528780,
    "out:% savings (space heating)": 19.71,
    "out:Total_Water Heating (DH)": 178976,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 102.82,
    "out:Etvv": 20,
    "out:Ef": 9.88,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z02000000120001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 107.43,
    "out:DHW (DH)": 29.58,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 9.88,
    "out:Total Energy Use Pre PV": 152.9,
    "out:Total Energy Use Post PV": 146.88,
    "out:Primary Energy": 104.88,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 36.07,
    "out:CO2 Operational/m2": -32.26,
    "out:Total CO2/m2": 3.81,
    "out:Total CO2 (tons)": 19.62,
    "out:Klimatpaverkan": -309.62,
    "out:Initial Cost/MSEK": 6.51425,
    "out:Running cost/(Apt SEK y)": 43597,
    "out:Running Cost over RSP/MSEK": 14.351,
    "out:LCP/MSEK": -1.597,
    "out:ROI% old": 10.78,
    "out:Payback discounted": 11,
    "out:Atemp": 5143,
    "out:Umean": 0.49,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 147,
    "out:Energy use kWh/m2": 147,
    "out:Energy savings %": 27.9,
    "out:Op energy cost/MSEK": 107,
    "out:El price/MSEK": 61,
    "out:Heating price/MSEK": 39,
    "out:DH+ DHW/kWh/m2": 137,
    "out:El bought/kWh/m2": 696.7,
    "out:Return %": 10,
    "out:Return/kSEK/y": 633,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.33,
    "out:PV (kWp installed)": 122.531965,
    "out:PV (m2 panels)": 643,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 114968.11,
    "out:PV (kWh sold)": 84004.99,
    "out:PV (% sold (El))": 73.07,
    "out:PV (kWh self-consumed)": 30963.12,
    "out:PV (ratio sold/self-consumed)": 2.71,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 180005,
    "out:EL kWh savings": 30963,
    "out:Sewage heat recovery cost": 250448,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 323548,
    "out:DH kr savings": 163805,
    "out:El kr savings": 52637,
    "out:El kr sold": 107106,
    "out:El kr saved": 52637,
    "out:El kr return": 159744,
    "out:% solar/total": 38,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 552491,
    "out:% savings (space heating)": 16.11,
    "out:Total_Water Heating (DH)": 152130,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 107.43,
    "out:Etvv": 17,
    "out:Ef": 9.88,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z02000000120001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 102.82,
    "out:DHW (DH)": 29.58,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 9.88,
    "out:Total Energy Use Pre PV": 147.9,
    "out:Total Energy Use Post PV": 141.88,
    "out:Primary Energy": 101.65,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 36.07,
    "out:CO2 Operational/m2": -34.06,
    "out:Total CO2/m2": 2.01,
    "out:Total CO2 (tons)": 10.36,
    "out:Klimatpaverkan": -318.88,
    "out:Initial Cost/MSEK": 6.642875,
    "out:Running cost/(Apt SEK y)": 41988,
    "out:Running Cost over RSP/MSEK": 13.823,
    "out:LCP/MSEK": -1.196,
    "out:ROI% old": 11.71,
    "out:Payback discounted": 10,
    "out:Atemp": 5143,
    "out:Umean": 0.49,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 142,
    "out:Energy use kWh/m2": 142,
    "out:Energy savings %": 32.4,
    "out:Op energy cost/MSEK": 103,
    "out:El price/MSEK": 59,
    "out:Heating price/MSEK": 38,
    "out:DH+ DHW/kWh/m2": 132,
    "out:El bought/kWh/m2": 696.7,
    "out:Return %": 11,
    "out:Return/kSEK/y": 700,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.33,
    "out:PV (kWp installed)": 122.531965,
    "out:PV (m2 panels)": 643,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 114968.11,
    "out:PV (kWh sold)": 84004.99,
    "out:PV (% sold (El))": 73.07,
    "out:PV (kWh self-consumed)": 30963.12,
    "out:PV (ratio sold/self-consumed)": 2.71,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 205720,
    "out:EL kWh savings": 30963,
    "out:Sewage heat recovery cost": 250448,
    "out:Balancing hydronic system cost": 128574.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 346949,
    "out:DH kr savings": 187205,
    "out:El kr savings": 52637,
    "out:El kr sold": 107106,
    "out:El kr saved": 52637,
    "out:El kr return": 159744,
    "out:% solar/total": 38,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 528780,
    "out:% savings (space heating)": 19.71,
    "out:Total_Water Heating (DH)": 152130,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 102.82,
    "out:Etvv": 17,
    "out:Ef": 9.88,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z02000001000000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 61.37,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 45.34,
    "out:Electricity (inc PV)": 43.21,
    "out:Total Energy Use Pre PV": 106.34,
    "out:Total Energy Use Post PV": 104.21,
    "out:Primary Energy": 112.7,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 13.81,
    "out:CO2 Operational/m2": 27.94,
    "out:Total CO2/m2": 41.76,
    "out:Total CO2 (tons)": 214.76,
    "out:Klimatpaverkan": -114.48,
    "out:Initial Cost/MSEK": 8.48925,
    "out:Running cost/(Apt SEK y)": 29069,
    "out:Running Cost over RSP/MSEK": 9.517,
    "out:LCP/MSEK": 1.263,
    "out:ROI% old": 16.41,
    "out:Payback discounted": 7,
    "out:Atemp": 5143,
    "out:Umean": 0.49,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 104,
    "out:Energy use kWh/m2": 104,
    "out:Energy savings %": 80.8,
    "out:Op energy cost/MSEK": 68,
    "out:El price/MSEK": 48,
    "out:Heating price/MSEK": 17,
    "out:DH+ DHW/kWh/m2": 61,
    "out:El bought/kWh/m2": 3173.6,
    "out:Return %": 15,
    "out:Return/kSEK/y": 1243,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 1479000,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 73950,
    "out:EAHP (replacement cost)": 1105400,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 27.14,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 570873,
    "out:EL kWh savings": -140456,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 280720,
    "out:DH kr savings": 519494,
    "out:El kr savings": -238775,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 315644,
    "out:% savings (space heating)": 52.07,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 49.89,
    "out:Etvv": 0,
    "out:Ef": 43.21,
    "out:heatPumpQuantity": 10,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000001000000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 57.04,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 45.25,
    "out:Electricity (inc PV)": 43.08,
    "out:Total Energy Use Pre PV": 102.25,
    "out:Total Energy Use Post PV": 100.08,
    "out:Primary Energy": 109.54,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 13.81,
    "out:CO2 Operational/m2": 26.49,
    "out:Total CO2/m2": 40.31,
    "out:Total CO2 (tons)": 207.3,
    "out:Klimatpaverkan": -121.94,
    "out:Initial Cost/MSEK": 8.617875,
    "out:Running cost/(Apt SEK y)": 27765,
    "out:Running Cost over RSP/MSEK": 9.088,
    "out:LCP/MSEK": 1.563,
    "out:ROI% old": 16.88,
    "out:Payback discounted": 7,
    "out:Atemp": 5143,
    "out:Umean": 0.49,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 100,
    "out:Energy use kWh/m2": 100,
    "out:Energy savings %": 88,
    "out:Op energy cost/MSEK": 65,
    "out:El price/MSEK": 46,
    "out:Heating price/MSEK": 16,
    "out:DH+ DHW/kWh/m2": 57,
    "out:El bought/kWh/m2": 3167.7,
    "out:Return %": 15,
    "out:Return/kSEK/y": 1298,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 1479000,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 73950,
    "out:EAHP (replacement cost)": 1105400,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 27.05,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 591445,
    "out:EL kWh savings": -139765,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 128574.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 300615,
    "out:DH kr savings": 538215,
    "out:El kr savings": -237600,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 293343,
    "out:% savings (space heating)": 55.46,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 45.72,
    "out:Etvv": 0,
    "out:Ef": 43.08,
    "out:heatPumpQuantity": 10,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000001000001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 57.2,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 44.62,
    "out:Electricity (inc PV)": 42.74,
    "out:Total Energy Use Pre PV": 101.62,
    "out:Total Energy Use Post PV": 99.74,
    "out:Primary Energy": 110.32,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 14.22,
    "out:CO2 Operational/m2": 26.41,
    "out:Total CO2/m2": 40.63,
    "out:Total CO2 (tons)": 208.94,
    "out:Klimatpaverkan": -120.3,
    "out:Initial Cost/MSEK": 8.73975,
    "out:Running cost/(Apt SEK y)": 27633,
    "out:Running Cost over RSP/MSEK": 9.046,
    "out:LCP/MSEK": 1.484,
    "out:ROI% old": 16.71,
    "out:Payback discounted": 7,
    "out:Atemp": 5143,
    "out:Umean": 0.49,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 100,
    "out:Energy use kWh/m2": 100,
    "out:Energy savings %": 88,
    "out:Op energy cost/MSEK": 64,
    "out:El price/MSEK": 45,
    "out:Heating price/MSEK": 16,
    "out:DH+ DHW/kWh/m2": 57,
    "out:El bought/kWh/m2": 3123.6,
    "out:Return %": 15,
    "out:Return/kSEK/y": 1303,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 1479000,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 73950,
    "out:EAHP (replacement cost)": 1105400,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 26.42,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 591445,
    "out:EL kWh savings": -138038,
    "out:Sewage heat recovery cost": 250448,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 303550,
    "out:DH kr savings": 538215,
    "out:El kr savings": -234665,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 294200,
    "out:% savings (space heating)": 55.33,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 47.69,
    "out:Etvv": 0,
    "out:Ef": 42.74,
    "out:heatPumpQuantity": 10,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000001000001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 52.92,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 44.52,
    "out:Electricity (inc PV)": 42.6,
    "out:Total Energy Use Pre PV": 97.52,
    "out:Total Energy Use Post PV": 95.6,
    "out:Primary Energy": 107.16,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 14.22,
    "out:CO2 Operational/m2": 24.96,
    "out:Total CO2/m2": 39.17,
    "out:Total CO2 (tons)": 201.47,
    "out:Klimatpaverkan": -127.77,
    "out:Initial Cost/MSEK": 8.86825,
    "out:Running cost/(Apt SEK y)": 26325,
    "out:Running Cost over RSP/MSEK": 8.616,
    "out:LCP/MSEK": 1.785,
    "out:ROI% old": 17.16,
    "out:Payback discounted": 7,
    "out:Atemp": 5143,
    "out:Umean": 0.49,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 96,
    "out:Energy use kWh/m2": 96,
    "out:Energy savings %": 95.8,
    "out:Op energy cost/MSEK": 61,
    "out:El price/MSEK": 44,
    "out:Heating price/MSEK": 15,
    "out:DH+ DHW/kWh/m2": 53,
    "out:El bought/kWh/m2": 3116.6,
    "out:Return %": 15,
    "out:Return/kSEK/y": 1358,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 1479000,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 73950,
    "out:EAHP (replacement cost)": 1105400,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 26.32,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 612017,
    "out:EL kWh savings": -137294,
    "out:Sewage heat recovery cost": 250448,
    "out:Balancing hydronic system cost": 128574.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 323536,
    "out:DH kr savings": 556935,
    "out:El kr savings": -233400,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 272175,
    "out:% savings (space heating)": 58.67,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 43.56,
    "out:Etvv": 0,
    "out:Ef": 42.6,
    "out:heatPumpQuantity": 10,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000001010000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 61.37,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 45.34,
    "out:Electricity (inc PV)": 33.69,
    "out:Total Energy Use Pre PV": 106.34,
    "out:Total Energy Use Post PV": 94.69,
    "out:Primary Energy": 95.56,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 26.17,
    "out:CO2 Operational/m2": 19.63,
    "out:Total CO2/m2": 45.79,
    "out:Total CO2 (tons)": 235.51,
    "out:Klimatpaverkan": -93.73,
    "out:Initial Cost/MSEK": 9.559125,
    "out:Running cost/(Apt SEK y)": 26818,
    "out:Running Cost over RSP/MSEK": 8.797,
    "out:LCP/MSEK": 0.913,
    "out:ROI% old": 15.65,
    "out:Payback discounted": 8,
    "out:Atemp": 5143,
    "out:Umean": 0.49,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 95,
    "out:Energy use kWh/m2": 95,
    "out:Energy savings %": 97.9,
    "out:Op energy cost/MSEK": 63,
    "out:El price/MSEK": 43,
    "out:Heating price/MSEK": 17,
    "out:DH+ DHW/kWh/m2": 61,
    "out:El bought/kWh/m2": 2519.5,
    "out:Return %": 14,
    "out:Return/kSEK/y": 1338,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 1479000,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 73950,
    "out:EAHP (replacement cost)": 1105400,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 27.14,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 61.265983,
    "out:PV (m2 panels)": 321,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 57484.06,
    "out:PV (kWh sold)": 7453.17,
    "out:PV (% sold (El))": 12.97,
    "out:PV (kWh self-consumed)": 50030.89,
    "out:PV (ratio sold/self-consumed)": 0.15,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 570873,
    "out:EL kWh savings": -91483,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 373476,
    "out:DH kr savings": 519494,
    "out:El kr savings": -155521,
    "out:El kr sold": 9503,
    "out:El kr saved": 85053,
    "out:El kr return": 94555,
    "out:% solar/total": 61,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 315644,
    "out:% savings (space heating)": 52.07,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 49.89,
    "out:Etvv": 0,
    "out:Ef": 33.69,
    "out:heatPumpQuantity": 10,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000001010000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 57.04,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 45.25,
    "out:Electricity (inc PV)": 33.57,
    "out:Total Energy Use Pre PV": 102.25,
    "out:Total Energy Use Post PV": 90.57,
    "out:Primary Energy": 92.43,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 26.17,
    "out:CO2 Operational/m2": 18.14,
    "out:Total CO2/m2": 44.3,
    "out:Total CO2 (tons)": 227.86,
    "out:Klimatpaverkan": -101.38,
    "out:Initial Cost/MSEK": 9.687625,
    "out:Running cost/(Apt SEK y)": 25514,
    "out:Running Cost over RSP/MSEK": 8.369,
    "out:LCP/MSEK": 1.213,
    "out:ROI% old": 16.07,
    "out:Payback discounted": 8,
    "out:Atemp": 5143,
    "out:Umean": 0.49,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 91,
    "out:Energy use kWh/m2": 91,
    "out:Energy savings %": 106.6,
    "out:Op energy cost/MSEK": 60,
    "out:El price/MSEK": 41,
    "out:Heating price/MSEK": 16,
    "out:DH+ DHW/kWh/m2": 57,
    "out:El bought/kWh/m2": 2514.1,
    "out:Return %": 14,
    "out:Return/kSEK/y": 1392,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 1479000,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 73950,
    "out:EAHP (replacement cost)": 1105400,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 27.05,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 61.265983,
    "out:PV (m2 panels)": 321,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 57484.06,
    "out:PV (kWh sold)": 7492.52,
    "out:PV (% sold (El))": 13.03,
    "out:PV (kWh self-consumed)": 49991.54,
    "out:PV (ratio sold/self-consumed)": 0.15,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 591445,
    "out:EL kWh savings": -90862,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 128574.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 393303,
    "out:DH kr savings": 538215,
    "out:El kr savings": -154465,
    "out:El kr sold": 9553,
    "out:El kr saved": 84986,
    "out:El kr return": 94539,
    "out:% solar/total": 61,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 293343,
    "out:% savings (space heating)": 55.46,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 45.72,
    "out:Etvv": 0,
    "out:Ef": 33.57,
    "out:heatPumpQuantity": 10,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000001010001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 57.2,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 44.62,
    "out:Electricity (inc PV)": 33.27,
    "out:Total Energy Use Pre PV": 101.62,
    "out:Total Energy Use Post PV": 90.27,
    "out:Primary Energy": 93.26,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 26.57,
    "out:CO2 Operational/m2": 17.76,
    "out:Total CO2/m2": 44.33,
    "out:Total CO2 (tons)": 228.01,
    "out:Klimatpaverkan": -101.23,
    "out:Initial Cost/MSEK": 9.8095,
    "out:Running cost/(Apt SEK y)": 25386,
    "out:Running Cost over RSP/MSEK": 8.327,
    "out:LCP/MSEK": 1.132,
    "out:ROI% old": 15.93,
    "out:Payback discounted": 8,
    "out:Atemp": 5143,
    "out:Umean": 0.49,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 90,
    "out:Energy use kWh/m2": 90,
    "out:Energy savings %": 108.9,
    "out:Op energy cost/MSEK": 60,
    "out:El price/MSEK": 41,
    "out:Heating price/MSEK": 16,
    "out:DH+ DHW/kWh/m2": 57,
    "out:El bought/kWh/m2": 2473.6,
    "out:Return %": 14,
    "out:Return/kSEK/y": 1398,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 1479000,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 73950,
    "out:EAHP (replacement cost)": 1105400,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 26.42,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 61.265983,
    "out:PV (m2 panels)": 321,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 57484.06,
    "out:PV (kWh sold)": 7796.95,
    "out:PV (% sold (El))": 13.56,
    "out:PV (kWh self-consumed)": 49687.11,
    "out:PV (ratio sold/self-consumed)": 0.16,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 591445,
    "out:EL kWh savings": -89311,
    "out:Sewage heat recovery cost": 250448,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 396328,
    "out:DH kr savings": 538215,
    "out:El kr savings": -151828,
    "out:El kr sold": 9941,
    "out:El kr saved": 84468,
    "out:El kr return": 94409,
    "out:% solar/total": 61,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 294200,
    "out:% savings (space heating)": 55.33,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 47.69,
    "out:Etvv": 0,
    "out:Ef": 33.27,
    "out:heatPumpQuantity": 10,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000001010001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 52.92,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 44.52,
    "out:Electricity (inc PV)": 33.14,
    "out:Total Energy Use Pre PV": 97.52,
    "out:Total Energy Use Post PV": 86.14,
    "out:Primary Energy": 90.14,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 26.57,
    "out:CO2 Operational/m2": 16.26,
    "out:Total CO2/m2": 42.83,
    "out:Total CO2 (tons)": 220.3,
    "out:Klimatpaverkan": -108.94,
    "out:Initial Cost/MSEK": 9.938125,
    "out:Running cost/(Apt SEK y)": 24078,
    "out:Running Cost over RSP/MSEK": 7.897,
    "out:LCP/MSEK": 1.434,
    "out:ROI% old": 16.35,
    "out:Payback discounted": 7,
    "out:Atemp": 5143,
    "out:Umean": 0.49,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 86,
    "out:Energy use kWh/m2": 86,
    "out:Energy savings %": 118.6,
    "out:Op energy cost/MSEK": 57,
    "out:El price/MSEK": 39,
    "out:Heating price/MSEK": 15,
    "out:DH+ DHW/kWh/m2": 53,
    "out:El bought/kWh/m2": 2467.2,
    "out:Return %": 15,
    "out:Return/kSEK/y": 1453,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 1479000,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 73950,
    "out:EAHP (replacement cost)": 1105400,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 26.32,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 61.265983,
    "out:PV (m2 panels)": 321,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 57484.06,
    "out:PV (kWh sold)": 7845.69,
    "out:PV (% sold (El))": 13.65,
    "out:PV (kWh self-consumed)": 49638.37,
    "out:PV (ratio sold/self-consumed)": 0.16,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 612017,
    "out:EL kWh savings": -88643,
    "out:Sewage heat recovery cost": 250448,
    "out:Balancing hydronic system cost": 128574.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 416246,
    "out:DH kr savings": 556935,
    "out:El kr savings": -150692,
    "out:El kr sold": 10003,
    "out:El kr saved": 84385,
    "out:El kr return": 94388,
    "out:% solar/total": 61,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 272175,
    "out:% savings (space heating)": 58.67,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 43.56,
    "out:Etvv": 0,
    "out:Ef": 33.14,
    "out:heatPumpQuantity": 10,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000001020000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 61.37,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 45.34,
    "out:Electricity (inc PV)": 30.44,
    "out:Total Energy Use Pre PV": 106.34,
    "out:Total Energy Use Post PV": 91.44,
    "out:Primary Energy": 89.71,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 38.52,
    "out:CO2 Operational/m2": -19.86,
    "out:Total CO2/m2": 18.66,
    "out:Total CO2 (tons)": 95.96,
    "out:Klimatpaverkan": -233.28,
    "out:Initial Cost/MSEK": 10.628875,
    "out:Running cost/(Apt SEK y)": 24893,
    "out:Running Cost over RSP/MSEK": 8.182,
    "out:LCP/MSEK": 0.458,
    "out:ROI% old": 14.9,
    "out:Payback discounted": 8,
    "out:Atemp": 5143,
    "out:Umean": 0.49,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 91,
    "out:Energy use kWh/m2": 91,
    "out:Energy savings %": 106.6,
    "out:Op energy cost/MSEK": 60,
    "out:El price/MSEK": 39,
    "out:Heating price/MSEK": 17,
    "out:DH+ DHW/kWh/m2": 61,
    "out:El bought/kWh/m2": 2273.3,
    "out:Return %": 13,
    "out:Return/kSEK/y": 1418,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 1479000,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 73950,
    "out:EAHP (replacement cost)": 1105400,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 27.14,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.33,
    "out:PV (kWp installed)": 122.531965,
    "out:PV (m2 panels)": 643,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 114968.11,
    "out:PV (kWh sold)": 47189.25,
    "out:PV (% sold (El))": 41.05,
    "out:PV (kWh self-consumed)": 67778.86,
    "out:PV (ratio sold/self-consumed)": 0.7,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 570873,
    "out:EL kWh savings": -74772,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 452549,
    "out:DH kr savings": 519494,
    "out:El kr savings": -127112,
    "out:El kr sold": 60166,
    "out:El kr saved": 115224,
    "out:El kr return": 175390,
    "out:% solar/total": 83,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 315644,
    "out:% savings (space heating)": 52.07,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 49.89,
    "out:Etvv": 0,
    "out:Ef": 30.44,
    "out:heatPumpQuantity": 10,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000001020000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 57.04,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 45.25,
    "out:Electricity (inc PV)": 30.33,
    "out:Total Energy Use Pre PV": 102.25,
    "out:Total Energy Use Post PV": 87.33,
    "out:Primary Energy": 86.6,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 38.52,
    "out:CO2 Operational/m2": -21.39,
    "out:Total CO2/m2": 17.13,
    "out:Total CO2 (tons)": 88.1,
    "out:Klimatpaverkan": -241.14,
    "out:Initial Cost/MSEK": 10.757375,
    "out:Running cost/(Apt SEK y)": 23590,
    "out:Running Cost over RSP/MSEK": 7.753,
    "out:LCP/MSEK": 0.758,
    "out:ROI% old": 15.29,
    "out:Payback discounted": 8,
    "out:Atemp": 5143,
    "out:Umean": 0.49,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 87,
    "out:Energy use kWh/m2": 87,
    "out:Energy savings %": 116.1,
    "out:Op energy cost/MSEK": 57,
    "out:El price/MSEK": 38,
    "out:Heating price/MSEK": 16,
    "out:DH+ DHW/kWh/m2": 57,
    "out:El bought/kWh/m2": 2268.5,
    "out:Return %": 14,
    "out:Return/kSEK/y": 1473,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 1479000,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 73950,
    "out:EAHP (replacement cost)": 1105400,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 27.05,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.33,
    "out:PV (kWp installed)": 122.531965,
    "out:PV (m2 panels)": 643,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 114968.11,
    "out:PV (kWh sold)": 47270.09,
    "out:PV (% sold (El))": 41.12,
    "out:PV (kWh self-consumed)": 67698.02,
    "out:PV (ratio sold/self-consumed)": 0.7,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 591445,
    "out:EL kWh savings": -74215,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 128574.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 472319,
    "out:DH kr savings": 538215,
    "out:El kr savings": -126165,
    "out:El kr sold": 60269,
    "out:El kr saved": 115087,
    "out:El kr return": 175356,
    "out:% solar/total": 83,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 293343,
    "out:% savings (space heating)": 55.46,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 45.72,
    "out:Etvv": 0,
    "out:Ef": 30.33,
    "out:heatPumpQuantity": 10,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000001020001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 57.2,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 44.62,
    "out:Electricity (inc PV)": 30.06,
    "out:Total Energy Use Pre PV": 101.62,
    "out:Total Energy Use Post PV": 87.06,
    "out:Primary Energy": 87.49,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 38.92,
    "out:CO2 Operational/m2": -22.08,
    "out:Total CO2/m2": 16.85,
    "out:Total CO2 (tons)": 86.65,
    "out:Klimatpaverkan": -242.59,
    "out:Initial Cost/MSEK": 10.87925,
    "out:Running cost/(Apt SEK y)": 23464,
    "out:Running Cost over RSP/MSEK": 7.713,
    "out:LCP/MSEK": 0.677,
    "out:ROI% old": 15.17,
    "out:Payback discounted": 8,
    "out:Atemp": 5143,
    "out:Umean": 0.49,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 87,
    "out:Energy use kWh/m2": 87,
    "out:Energy savings %": 116.1,
    "out:Op energy cost/MSEK": 56,
    "out:El price/MSEK": 37,
    "out:Heating price/MSEK": 16,
    "out:DH+ DHW/kWh/m2": 57,
    "out:El bought/kWh/m2": 2232.4,
    "out:Return %": 14,
    "out:Return/kSEK/y": 1478,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 1479000,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 73950,
    "out:EAHP (replacement cost)": 1105400,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 26.42,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.33,
    "out:PV (kWp installed)": 122.531965,
    "out:PV (m2 panels)": 643,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 114968.11,
    "out:PV (kWh sold)": 47886.17,
    "out:PV (% sold (El))": 41.65,
    "out:PV (kWh self-consumed)": 67081.95,
    "out:PV (ratio sold/self-consumed)": 0.71,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 591445,
    "out:EL kWh savings": -72826,
    "out:Sewage heat recovery cost": 250448,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 475466,
    "out:DH kr savings": 538215,
    "out:El kr savings": -123803,
    "out:El kr sold": 61055,
    "out:El kr saved": 114039,
    "out:El kr return": 175094,
    "out:% solar/total": 82,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 294200,
    "out:% savings (space heating)": 55.33,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 47.69,
    "out:Etvv": 0,
    "out:Ef": 30.06,
    "out:heatPumpQuantity": 10,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000001020001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 52.92,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 44.52,
    "out:Electricity (inc PV)": 29.94,
    "out:Total Energy Use Pre PV": 97.52,
    "out:Total Energy Use Post PV": 82.94,
    "out:Primary Energy": 84.39,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 38.92,
    "out:CO2 Operational/m2": -23.62,
    "out:Total CO2/m2": 15.3,
    "out:Total CO2 (tons)": 78.69,
    "out:Klimatpaverkan": -250.55,
    "out:Initial Cost/MSEK": 11.007875,
    "out:Running cost/(Apt SEK y)": 22157,
    "out:Running Cost over RSP/MSEK": 7.283,
    "out:LCP/MSEK": 0.978,
    "out:ROI% old": 15.55,
    "out:Payback discounted": 8,
    "out:Atemp": 5143,
    "out:Umean": 0.49,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 83,
    "out:Energy use kWh/m2": 83,
    "out:Energy savings %": 126.5,
    "out:Op energy cost/MSEK": 53,
    "out:El price/MSEK": 35,
    "out:Heating price/MSEK": 15,
    "out:DH+ DHW/kWh/m2": 53,
    "out:El bought/kWh/m2": 2226.7,
    "out:Return %": 14,
    "out:Return/kSEK/y": 1533,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 1479000,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 73950,
    "out:EAHP (replacement cost)": 1105400,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 26.32,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.33,
    "out:PV (kWp installed)": 122.531965,
    "out:PV (m2 panels)": 643,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 114968.11,
    "out:PV (kWh sold)": 47983.54,
    "out:PV (% sold (El))": 41.74,
    "out:PV (kWh self-consumed)": 66984.57,
    "out:PV (ratio sold/self-consumed)": 0.72,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 612017,
    "out:EL kWh savings": -72227,
    "out:Sewage heat recovery cost": 250448,
    "out:Balancing hydronic system cost": 128574.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 495328,
    "out:DH kr savings": 556935,
    "out:El kr savings": -122786,
    "out:El kr sold": 61179,
    "out:El kr saved": 113874,
    "out:El kr return": 175053,
    "out:% solar/total": 82,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 272175,
    "out:% savings (space heating)": 58.67,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 43.56,
    "out:Etvv": 0,
    "out:Ef": 29.94,
    "out:heatPumpQuantity": 10,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000001100000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 55.84,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 44.38,
    "out:Electricity (inc PV)": 42.58,
    "out:Total Energy Use Pre PV": 100.38,
    "out:Total Energy Use Post PV": 98.58,
    "out:Primary Energy": 109.52,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 13.81,
    "out:CO2 Operational/m2": 26.02,
    "out:Total CO2/m2": 39.83,
    "out:Total CO2 (tons)": 204.85,
    "out:Klimatpaverkan": -124.39,
    "out:Initial Cost/MSEK": 8.600125,
    "out:Running cost/(Apt SEK y)": 27260,
    "out:Running Cost over RSP/MSEK": 8.923,
    "out:LCP/MSEK": 1.746,
    "out:ROI% old": 17.19,
    "out:Payback discounted": 7,
    "out:Atemp": 5143,
    "out:Umean": 0.49,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 99,
    "out:Energy use kWh/m2": 99,
    "out:Energy savings %": 89.9,
    "out:Op energy cost/MSEK": 64,
    "out:El price/MSEK": 45,
    "out:Heating price/MSEK": 16,
    "out:DH+ DHW/kWh/m2": 56,
    "out:El bought/kWh/m2": 3106.3,
    "out:Return %": 15,
    "out:Return/kSEK/y": 1319,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 1479000,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 73950,
    "out:EAHP (replacement cost)": 1105400,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 26.18,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 596588,
    "out:EL kWh savings": -137220,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 309621,
    "out:DH kr savings": 542895,
    "out:El kr savings": -233274,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 287199,
    "out:% savings (space heating)": 56.39,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 46.97,
    "out:Etvv": 0,
    "out:Ef": 42.58,
    "out:heatPumpQuantity": 10,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000001100000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 51.58,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 44.27,
    "out:Electricity (inc PV)": 42.43,
    "out:Total Energy Use Pre PV": 96.27,
    "out:Total Energy Use Post PV": 94.43,
    "out:Primary Energy": 106.37,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 13.81,
    "out:CO2 Operational/m2": 24.56,
    "out:Total CO2/m2": 38.38,
    "out:Total CO2 (tons)": 197.38,
    "out:Klimatpaverkan": -131.86,
    "out:Initial Cost/MSEK": 8.72875,
    "out:Running cost/(Apt SEK y)": 25951,
    "out:Running Cost over RSP/MSEK": 8.493,
    "out:LCP/MSEK": 2.047,
    "out:ROI% old": 17.64,
    "out:Payback discounted": 7,
    "out:Atemp": 5143,
    "out:Umean": 0.49,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 94,
    "out:Energy use kWh/m2": 94,
    "out:Energy savings %": 100,
    "out:Op energy cost/MSEK": 60,
    "out:El price/MSEK": 43,
    "out:Heating price/MSEK": 15,
    "out:DH+ DHW/kWh/m2": 52,
    "out:El bought/kWh/m2": 3099,
    "out:Return %": 16,
    "out:Return/kSEK/y": 1374,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 1479000,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 73950,
    "out:EAHP (replacement cost)": 1105400,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 26.07,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 617160,
    "out:EL kWh savings": -136458,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 128574.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 329638,
    "out:DH kr savings": 561616,
    "out:El kr savings": -231978,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 265276,
    "out:% savings (space heating)": 59.72,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 42.85,
    "out:Etvv": 0,
    "out:Ef": 42.43,
    "out:heatPumpQuantity": 10,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000001100001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 52.64,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 43.77,
    "out:Electricity (inc PV)": 42.19,
    "out:Total Energy Use Pre PV": 96.77,
    "out:Total Energy Use Post PV": 95.19,
    "out:Primary Energy": 107.62,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 14.22,
    "out:CO2 Operational/m2": 24.86,
    "out:Total CO2/m2": 39.07,
    "out:Total CO2 (tons)": 200.95,
    "out:Klimatpaverkan": -128.29,
    "out:Initial Cost/MSEK": 8.850625,
    "out:Running cost/(Apt SEK y)": 26168,
    "out:Running Cost over RSP/MSEK": 8.565,
    "out:LCP/MSEK": 1.854,
    "out:ROI% old": 17.28,
    "out:Payback discounted": 7,
    "out:Atemp": 5143,
    "out:Umean": 0.49,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 95,
    "out:Energy use kWh/m2": 95,
    "out:Energy savings %": 97.9,
    "out:Op energy cost/MSEK": 61,
    "out:El price/MSEK": 43,
    "out:Heating price/MSEK": 15,
    "out:DH+ DHW/kWh/m2": 53,
    "out:El bought/kWh/m2": 3063.6,
    "out:Return %": 15,
    "out:Return/kSEK/y": 1365,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 1479000,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 73950,
    "out:EAHP (replacement cost)": 1105400,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 25.57,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 612017,
    "out:EL kWh savings": -135229,
    "out:Sewage heat recovery cost": 250448,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 327046,
    "out:DH kr savings": 556935,
    "out:El kr savings": -229889,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 270707,
    "out:% savings (space heating)": 58.9,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 45.24,
    "out:Etvv": 0,
    "out:Ef": 42.19,
    "out:heatPumpQuantity": 10,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000001100001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 48.42,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 43.65,
    "out:Electricity (inc PV)": 42.04,
    "out:Total Energy Use Pre PV": 91.65,
    "out:Total Energy Use Post PV": 90.04,
    "out:Primary Energy": 104.47,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 14.22,
    "out:CO2 Operational/m2": 23.04,
    "out:Total CO2/m2": 37.26,
    "out:Total CO2 (tons)": 191.61,
    "out:Klimatpaverkan": -137.63,
    "out:Initial Cost/MSEK": 8.979125,
    "out:Running cost/(Apt SEK y)": 24534,
    "out:Running Cost over RSP/MSEK": 8.028,
    "out:LCP/MSEK": 2.262,
    "out:ROI% old": 17.89,
    "out:Payback discounted": 7,
    "out:Atemp": 5143,
    "out:Umean": 0.49,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 90,
    "out:Energy use kWh/m2": 90,
    "out:Energy savings %": 108.9,
    "out:Op energy cost/MSEK": 57,
    "out:El price/MSEK": 41,
    "out:Heating price/MSEK": 14,
    "out:DH+ DHW/kWh/m2": 48,
    "out:El bought/kWh/m2": 3055.2,
    "out:Return %": 16,
    "out:Return/kSEK/y": 1434,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 1479000,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 73950,
    "out:EAHP (replacement cost)": 1105400,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 25.45,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 637732,
    "out:EL kWh savings": -134420,
    "out:Sewage heat recovery cost": 250448,
    "out:Balancing hydronic system cost": 128574.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 351822,
    "out:DH kr savings": 580336,
    "out:El kr savings": -228514,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 249036,
    "out:% savings (space heating)": 62.19,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 41.15,
    "out:Etvv": 0,
    "out:Ef": 42.04,
    "out:heatPumpQuantity": 10,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000001110000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 55.84,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 44.38,
    "out:Electricity (inc PV)": 33.12,
    "out:Total Energy Use Pre PV": 100.38,
    "out:Total Energy Use Post PV": 89.12,
    "out:Primary Energy": 92.5,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 26.17,
    "out:CO2 Operational/m2": 17.26,
    "out:Total CO2/m2": 43.42,
    "out:Total CO2 (tons)": 223.33,
    "out:Klimatpaverkan": -105.91,
    "out:Initial Cost/MSEK": 9.669875,
    "out:Running cost/(Apt SEK y)": 25014,
    "out:Running Cost over RSP/MSEK": 8.205,
    "out:LCP/MSEK": 1.394,
    "out:ROI% old": 16.35,
    "out:Payback discounted": 7,
    "out:Atemp": 5143,
    "out:Umean": 0.49,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 89,
    "out:Energy use kWh/m2": 89,
    "out:Energy savings %": 111.2,
    "out:Op energy cost/MSEK": 59,
    "out:El price/MSEK": 40,
    "out:Heating price/MSEK": 16,
    "out:DH+ DHW/kWh/m2": 56,
    "out:El bought/kWh/m2": 2457.8,
    "out:Return %": 15,
    "out:Return/kSEK/y": 1413,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 1479000,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 73950,
    "out:EAHP (replacement cost)": 1105400,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 26.18,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 61.265983,
    "out:PV (m2 panels)": 321,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 57484.06,
    "out:PV (kWh sold)": 7918.23,
    "out:PV (% sold (El))": 13.77,
    "out:PV (kWh self-consumed)": 49565.83,
    "out:PV (ratio sold/self-consumed)": 0.16,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 596588,
    "out:EL kWh savings": -88576,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 402411,
    "out:DH kr savings": 542895,
    "out:El kr savings": -150579,
    "out:El kr sold": 10096,
    "out:El kr saved": 84262,
    "out:El kr return": 94358,
    "out:% solar/total": 61,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 287199,
    "out:% savings (space heating)": 56.39,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 46.97,
    "out:Etvv": 0,
    "out:Ef": 33.12,
    "out:heatPumpQuantity": 10,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000001110000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 51.58,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 44.27,
    "out:Electricity (inc PV)": 32.99,
    "out:Total Energy Use Pre PV": 96.27,
    "out:Total Energy Use Post PV": 84.99,
    "out:Primary Energy": 89.37,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 26.17,
    "out:CO2 Operational/m2": 15.75,
    "out:Total CO2/m2": 41.92,
    "out:Total CO2 (tons)": 215.59,
    "out:Klimatpaverkan": -113.65,
    "out:Initial Cost/MSEK": 9.7985,
    "out:Running cost/(Apt SEK y)": 23705,
    "out:Running Cost over RSP/MSEK": 7.775,
    "out:LCP/MSEK": 1.696,
    "out:ROI% old": 16.76,
    "out:Payback discounted": 7,
    "out:Atemp": 5143,
    "out:Umean": 0.49,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 85,
    "out:Energy use kWh/m2": 85,
    "out:Energy savings %": 121.2,
    "out:Op energy cost/MSEK": 56,
    "out:El price/MSEK": 39,
    "out:Heating price/MSEK": 15,
    "out:DH+ DHW/kWh/m2": 52,
    "out:El bought/kWh/m2": 2451,
    "out:Return %": 15,
    "out:Return/kSEK/y": 1468,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 1479000,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 73950,
    "out:EAHP (replacement cost)": 1105400,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 26.07,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 61.265983,
    "out:PV (m2 panels)": 321,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 57484.06,
    "out:PV (kWh sold)": 7970.64,
    "out:PV (% sold (El))": 13.87,
    "out:PV (kWh self-consumed)": 49513.42,
    "out:PV (ratio sold/self-consumed)": 0.16,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 617160,
    "out:EL kWh savings": -87892,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 128574.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 422361,
    "out:DH kr savings": 561616,
    "out:El kr savings": -149417,
    "out:El kr sold": 10163,
    "out:El kr saved": 84173,
    "out:El kr return": 94335,
    "out:% solar/total": 61,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 265276,
    "out:% savings (space heating)": 59.72,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 42.85,
    "out:Etvv": 0,
    "out:Ef": 32.99,
    "out:heatPumpQuantity": 10,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000001110001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 52.64,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 43.77,
    "out:Electricity (inc PV)": 32.78,
    "out:Total Energy Use Pre PV": 96.77,
    "out:Total Energy Use Post PV": 85.78,
    "out:Primary Energy": 90.67,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 26.57,
    "out:CO2 Operational/m2": 15.8,
    "out:Total CO2/m2": 42.37,
    "out:Total CO2 (tons)": 217.93,
    "out:Klimatpaverkan": -111.31,
    "out:Initial Cost/MSEK": 9.920375,
    "out:Running cost/(Apt SEK y)": 23924,
    "out:Running Cost over RSP/MSEK": 7.847,
    "out:LCP/MSEK": 1.501,
    "out:ROI% old": 16.45,
    "out:Payback discounted": 7,
    "out:Atemp": 5143,
    "out:Umean": 0.49,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 86,
    "out:Energy use kWh/m2": 86,
    "out:Energy savings %": 118.6,
    "out:Op energy cost/MSEK": 57,
    "out:El price/MSEK": 39,
    "out:Heating price/MSEK": 15,
    "out:DH+ DHW/kWh/m2": 53,
    "out:El bought/kWh/m2": 2418.7,
    "out:Return %": 15,
    "out:Return/kSEK/y": 1459,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 1479000,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 73950,
    "out:EAHP (replacement cost)": 1105400,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 25.57,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 61.265983,
    "out:PV (m2 panels)": 321,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 57484.06,
    "out:PV (kWh sold)": 8225.46,
    "out:PV (% sold (El))": 14.31,
    "out:PV (kWh self-consumed)": 49258.6,
    "out:PV (ratio sold/self-consumed)": 0.17,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 612017,
    "out:EL kWh savings": -86791,
    "out:Sewage heat recovery cost": 250448,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 419878,
    "out:DH kr savings": 556935,
    "out:El kr savings": -147545,
    "out:El kr sold": 10487,
    "out:El kr saved": 83740,
    "out:El kr return": 94227,
    "out:% solar/total": 60,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 270707,
    "out:% savings (space heating)": 58.9,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 45.24,
    "out:Etvv": 0,
    "out:Ef": 32.78,
    "out:heatPumpQuantity": 10,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000001110001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 48.42,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 43.65,
    "out:Electricity (inc PV)": 32.63,
    "out:Total Energy Use Pre PV": 91.65,
    "out:Total Energy Use Post PV": 80.63,
    "out:Primary Energy": 87.55,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 26.57,
    "out:CO2 Operational/m2": 13.93,
    "out:Total CO2/m2": 40.5,
    "out:Total CO2 (tons)": 208.29,
    "out:Klimatpaverkan": -120.95,
    "out:Initial Cost/MSEK": 10.049,
    "out:Running cost/(Apt SEK y)": 22291,
    "out:Running Cost over RSP/MSEK": 7.311,
    "out:LCP/MSEK": 1.909,
    "out:ROI% old": 17,
    "out:Payback discounted": 7,
    "out:Atemp": 5143,
    "out:Umean": 0.49,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 81,
    "out:Energy use kWh/m2": 81,
    "out:Energy savings %": 132.1,
    "out:Op energy cost/MSEK": 53,
    "out:El price/MSEK": 37,
    "out:Heating price/MSEK": 14,
    "out:DH+ DHW/kWh/m2": 48,
    "out:El bought/kWh/m2": 2411.1,
    "out:Return %": 15,
    "out:Return/kSEK/y": 1528,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 1479000,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 73950,
    "out:EAHP (replacement cost)": 1105400,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 25.45,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 61.265983,
    "out:PV (m2 panels)": 321,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 57484.06,
    "out:PV (kWh sold)": 8286.89,
    "out:PV (% sold (El))": 14.42,
    "out:PV (kWh self-consumed)": 49197.16,
    "out:PV (ratio sold/self-consumed)": 0.17,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 637732,
    "out:EL kWh savings": -86066,
    "out:Sewage heat recovery cost": 250448,
    "out:Balancing hydronic system cost": 128574.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 444590,
    "out:DH kr savings": 580336,
    "out:El kr savings": -146312,
    "out:El kr sold": 10566,
    "out:El kr saved": 83635,
    "out:El kr return": 94201,
    "out:% solar/total": 60,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 249036,
    "out:% savings (space heating)": 62.19,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 41.15,
    "out:Etvv": 0,
    "out:Ef": 32.63,
    "out:heatPumpQuantity": 10,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000001120000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 55.84,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 44.38,
    "out:Electricity (inc PV)": 29.93,
    "out:Total Energy Use Pre PV": 100.38,
    "out:Total Energy Use Post PV": 85.93,
    "out:Primary Energy": 86.76,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 38.52,
    "out:CO2 Operational/m2": -22.7,
    "out:Total CO2/m2": 15.82,
    "out:Total CO2 (tons)": 81.35,
    "out:Klimatpaverkan": -247.89,
    "out:Initial Cost/MSEK": 10.73975,
    "out:Running cost/(Apt SEK y)": 23094,
    "out:Running Cost over RSP/MSEK": 7.591,
    "out:LCP/MSEK": 0.938,
    "out:ROI% old": 15.53,
    "out:Payback discounted": 8,
    "out:Atemp": 5143,
    "out:Umean": 0.49,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 86,
    "out:Energy use kWh/m2": 86,
    "out:Energy savings %": 118.6,
    "out:Op energy cost/MSEK": 55,
    "out:El price/MSEK": 37,
    "out:Heating price/MSEK": 16,
    "out:DH+ DHW/kWh/m2": 56,
    "out:El bought/kWh/m2": 2218.3,
    "out:Return %": 14,
    "out:Return/kSEK/y": 1494,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 1479000,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 73950,
    "out:EAHP (replacement cost)": 1105400,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 26.18,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.33,
    "out:PV (kWp installed)": 122.531965,
    "out:PV (m2 panels)": 643,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 114968.11,
    "out:PV (kWh sold)": 48128.04,
    "out:PV (% sold (El))": 41.86,
    "out:PV (kWh self-consumed)": 66840.07,
    "out:PV (ratio sold/self-consumed)": 0.72,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 596588,
    "out:EL kWh savings": -72168,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 481573,
    "out:DH kr savings": 542895,
    "out:El kr savings": -122685,
    "out:El kr sold": 61363,
    "out:El kr saved": 113628,
    "out:El kr return": 174991,
    "out:% solar/total": 82,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 287199,
    "out:% savings (space heating)": 56.39,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 46.97,
    "out:Etvv": 0,
    "out:Ef": 29.93,
    "out:heatPumpQuantity": 10,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000001120000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 51.58,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 44.27,
    "out:Electricity (inc PV)": 29.81,
    "out:Total Energy Use Pre PV": 96.27,
    "out:Total Energy Use Post PV": 81.81,
    "out:Primary Energy": 83.66,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 38.52,
    "out:CO2 Operational/m2": -24.26,
    "out:Total CO2/m2": 14.26,
    "out:Total CO2 (tons)": 73.35,
    "out:Klimatpaverkan": -255.89,
    "out:Initial Cost/MSEK": 10.86825,
    "out:Running cost/(Apt SEK y)": 21786,
    "out:Running Cost over RSP/MSEK": 7.161,
    "out:LCP/MSEK": 1.24,
    "out:ROI% old": 15.92,
    "out:Payback discounted": 8,
    "out:Atemp": 5143,
    "out:Umean": 0.49,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 82,
    "out:Energy use kWh/m2": 82,
    "out:Energy savings %": 129.3,
    "out:Op energy cost/MSEK": 52,
    "out:El price/MSEK": 35,
    "out:Heating price/MSEK": 15,
    "out:DH+ DHW/kWh/m2": 52,
    "out:El bought/kWh/m2": 2212.3,
    "out:Return %": 14,
    "out:Return/kSEK/y": 1549,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 1479000,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 73950,
    "out:EAHP (replacement cost)": 1105400,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 26.07,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.33,
    "out:PV (kWp installed)": 122.531965,
    "out:PV (m2 panels)": 643,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 114968.11,
    "out:PV (kWh sold)": 48231.5,
    "out:PV (% sold (El))": 41.95,
    "out:PV (kWh self-consumed)": 66736.61,
    "out:PV (ratio sold/self-consumed)": 0.72,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 617160,
    "out:EL kWh savings": -71555,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 128574.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 501467,
    "out:DH kr savings": 561616,
    "out:El kr savings": -121643,
    "out:El kr sold": 61495,
    "out:El kr saved": 113452,
    "out:El kr return": 174947,
    "out:% solar/total": 82,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 265276,
    "out:% savings (space heating)": 59.72,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 42.85,
    "out:Etvv": 0,
    "out:Ef": 29.81,
    "out:heatPumpQuantity": 10,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000001120001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 52.64,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 43.77,
    "out:Electricity (inc PV)": 29.62,
    "out:Total Energy Use Pre PV": 96.77,
    "out:Total Energy Use Post PV": 82.62,
    "out:Primary Energy": 84.99,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 38.92,
    "out:CO2 Operational/m2": -24.45,
    "out:Total CO2/m2": 14.47,
    "out:Total CO2 (tons)": 74.43,
    "out:Klimatpaverkan": -254.81,
    "out:Initial Cost/MSEK": 10.990125,
    "out:Running cost/(Apt SEK y)": 22007,
    "out:Running Cost over RSP/MSEK": 7.234,
    "out:LCP/MSEK": 1.044,
    "out:ROI% old": 15.64,
    "out:Payback discounted": 8,
    "out:Atemp": 5143,
    "out:Umean": 0.49,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 83,
    "out:Energy use kWh/m2": 83,
    "out:Energy savings %": 126.5,
    "out:Op energy cost/MSEK": 53,
    "out:El price/MSEK": 35,
    "out:Heating price/MSEK": 15,
    "out:DH+ DHW/kWh/m2": 53,
    "out:El bought/kWh/m2": 2183.4,
    "out:Return %": 14,
    "out:Return/kSEK/y": 1540,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 1479000,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 73950,
    "out:EAHP (replacement cost)": 1105400,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 25.57,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.33,
    "out:PV (kWp installed)": 122.531965,
    "out:PV (m2 panels)": 643,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 114968.11,
    "out:PV (kWh sold)": 48730.37,
    "out:PV (% sold (El))": 42.39,
    "out:PV (kWh self-consumed)": 66237.74,
    "out:PV (ratio sold/self-consumed)": 0.74,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 612017,
    "out:EL kWh savings": -70568,
    "out:Sewage heat recovery cost": 250448,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 499101,
    "out:DH kr savings": 556935,
    "out:El kr savings": -119966,
    "out:El kr sold": 62131,
    "out:El kr saved": 112604,
    "out:El kr return": 174735,
    "out:% solar/total": 81,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 270707,
    "out:% savings (space heating)": 58.9,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 45.24,
    "out:Etvv": 0,
    "out:Ef": 29.62,
    "out:heatPumpQuantity": 10,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000001120001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 48.42,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 43.65,
    "out:Electricity (inc PV)": 29.49,
    "out:Total Energy Use Pre PV": 91.65,
    "out:Total Energy Use Post PV": 77.49,
    "out:Primary Energy": 81.9,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 38.92,
    "out:CO2 Operational/m2": -26.38,
    "out:Total CO2/m2": 12.54,
    "out:Total CO2 (tons)": 64.5,
    "out:Klimatpaverkan": -264.74,
    "out:Initial Cost/MSEK": 11.11875,
    "out:Running cost/(Apt SEK y)": 20374,
    "out:Running Cost over RSP/MSEK": 6.698,
    "out:LCP/MSEK": 1.452,
    "out:ROI% old": 16.15,
    "out:Payback discounted": 8,
    "out:Atemp": 5143,
    "out:Umean": 0.49,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 77,
    "out:Energy use kWh/m2": 77,
    "out:Energy savings %": 144.2,
    "out:Op energy cost/MSEK": 49,
    "out:El price/MSEK": 33,
    "out:Heating price/MSEK": 14,
    "out:DH+ DHW/kWh/m2": 48,
    "out:El bought/kWh/m2": 2176.6,
    "out:Return %": 14,
    "out:Return/kSEK/y": 1608,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 1479000,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 73950,
    "out:EAHP (replacement cost)": 1105400,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 25.45,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.33,
    "out:PV (kWp installed)": 122.531965,
    "out:PV (m2 panels)": 643,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 114968.11,
    "out:PV (kWh sold)": 48849.71,
    "out:PV (% sold (El))": 42.49,
    "out:PV (kWh self-consumed)": 66118.4,
    "out:PV (ratio sold/self-consumed)": 0.74,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 637732,
    "out:EL kWh savings": -69919,
    "out:Sewage heat recovery cost": 250448,
    "out:Balancing hydronic system cost": 128574.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 523758,
    "out:DH kr savings": 580336,
    "out:El kr savings": -118861,
    "out:El kr sold": 62283,
    "out:El kr saved": 112401,
    "out:El kr return": 174685,
    "out:% solar/total": 81,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 249036,
    "out:% savings (space heating)": 62.19,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 41.15,
    "out:Etvv": 0,
    "out:Ef": 29.49,
    "out:heatPumpQuantity": 10,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000002000000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 11.78,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 60.37,
    "out:Electricity (inc PV)": 56.21,
    "out:Total Energy Use Pre PV": 72.37,
    "out:Total Energy Use Post PV": 68.21,
    "out:Primary Energy": 106.07,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 14.38,
    "out:CO2 Operational/m2": 12.29,
    "out:Total CO2/m2": 26.66,
    "out:Total CO2 (tons)": 137.13,
    "out:Klimatpaverkan": -192.11,
    "out:Initial Cost/MSEK": 11.14125,
    "out:Running cost/(Apt SEK y)": 16428,
    "out:Running Cost over RSP/MSEK": 5.35,
    "out:LCP/MSEK": 2.778,
    "out:ROI% old": 17.85,
    "out:Payback discounted": 7,
    "out:Atemp": 5143,
    "out:Umean": 0.49,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 68,
    "out:Energy use kWh/m2": 68,
    "out:Energy savings %": 176.5,
    "out:Op energy cost/MSEK": 36,
    "out:El price/MSEK": 32,
    "out:Heating price/MSEK": 3,
    "out:DH+ DHW/kWh/m2": 12,
    "out:El bought/kWh/m2": 4225.6,
    "out:Return %": 16,
    "out:Return/kSEK/y": 1774,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 2958000,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 147900,
    "out:EAHP (replacement cost)": 2204400,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 42.17,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 822880,
    "out:EL kWh savings": -207337,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 396348,
    "out:DH kr savings": 748821,
    "out:El kr savings": -352473,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 60574,
    "out:% savings (space heating)": 90.8,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 6.98,
    "out:Etvv": 0,
    "out:Ef": 56.21,
    "out:heatPumpQuantity": 20,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000002000000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 9.23,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 59.74,
    "out:Electricity (inc PV)": 55.36,
    "out:Total Energy Use Pre PV": 68.74,
    "out:Total Energy Use Post PV": 64.36,
    "out:Primary Energy": 103.28,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 14.38,
    "out:CO2 Operational/m2": 11.13,
    "out:Total CO2/m2": 25.5,
    "out:Total CO2 (tons)": 131.15,
    "out:Klimatpaverkan": -198.09,
    "out:Initial Cost/MSEK": 11.269875,
    "out:Running cost/(Apt SEK y)": 15333,
    "out:Running Cost over RSP/MSEK": 4.99,
    "out:LCP/MSEK": 3.009,
    "out:ROI% old": 18.1,
    "out:Payback discounted": 7,
    "out:Atemp": 5143,
    "out:Umean": 0.49,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 64,
    "out:Energy use kWh/m2": 64,
    "out:Energy savings %": 193.8,
    "out:Op energy cost/MSEK": 34,
    "out:El price/MSEK": 31,
    "out:Heating price/MSEK": 3,
    "out:DH+ DHW/kWh/m2": 9,
    "out:El bought/kWh/m2": 4181.8,
    "out:Return %": 16,
    "out:Return/kSEK/y": 1820,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 2958000,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 147900,
    "out:EAHP (replacement cost)": 2204400,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 41.54,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 838309,
    "out:EL kWh savings": -202957,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 128574.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 417833,
    "out:DH kr savings": 762861,
    "out:El kr savings": -345028,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 47473,
    "out:% savings (space heating)": 92.79,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 5.18,
    "out:Etvv": 0,
    "out:Ef": 55.36,
    "out:heatPumpQuantity": 20,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000002000001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 9.9,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 58.96,
    "out:Electricity (inc PV)": 55.31,
    "out:Total Energy Use Pre PV": 68.96,
    "out:Total Energy Use Post PV": 65.31,
    "out:Primary Energy": 103.91,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 14.78,
    "out:CO2 Operational/m2": 11.38,
    "out:Total CO2/m2": 26.16,
    "out:Total CO2 (tons)": 134.54,
    "out:Klimatpaverkan": -194.7,
    "out:Initial Cost/MSEK": 11.39175,
    "out:Running cost/(Apt SEK y)": 15491,
    "out:Running Cost over RSP/MSEK": 5.043,
    "out:LCP/MSEK": 2.834,
    "out:ROI% old": 17.84,
    "out:Payback discounted": 7,
    "out:Atemp": 5143,
    "out:Umean": 0.49,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 65,
    "out:Energy use kWh/m2": 65,
    "out:Energy savings %": 189.2,
    "out:Op energy cost/MSEK": 34,
    "out:El price/MSEK": 31,
    "out:Heating price/MSEK": 3,
    "out:DH+ DHW/kWh/m2": 10,
    "out:El bought/kWh/m2": 4127,
    "out:Return %": 16,
    "out:Return/kSEK/y": 1813,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 2958000,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 147900,
    "out:EAHP (replacement cost)": 2204400,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 40.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 833166,
    "out:EL kWh savings": -202663,
    "out:Sewage heat recovery cost": 250448,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 413654,
    "out:DH kr savings": 758181,
    "out:El kr savings": -344527,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 50918,
    "out:% savings (space heating)": 92.27,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 6.23,
    "out:Etvv": 0,
    "out:Ef": 55.31,
    "out:heatPumpQuantity": 20,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000002000001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 7.58,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 58.26,
    "out:Electricity (inc PV)": 54.4,
    "out:Total Energy Use Pre PV": 66.26,
    "out:Total Energy Use Post PV": 62.4,
    "out:Primary Energy": 101.14,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 14.78,
    "out:CO2 Operational/m2": 10.57,
    "out:Total CO2/m2": 25.35,
    "out:Total CO2 (tons)": 130.37,
    "out:Klimatpaverkan": -198.87,
    "out:Initial Cost/MSEK": 11.52025,
    "out:Running cost/(Apt SEK y)": 14703,
    "out:Running Cost over RSP/MSEK": 4.784,
    "out:LCP/MSEK": 2.964,
    "out:ROI% old": 17.96,
    "out:Payback discounted": 7,
    "out:Atemp": 5143,
    "out:Umean": 0.49,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 62,
    "out:Energy use kWh/m2": 62,
    "out:Energy savings %": 203.2,
    "out:Op energy cost/MSEK": 32,
    "out:El price/MSEK": 30,
    "out:Heating price/MSEK": 2,
    "out:DH+ DHW/kWh/m2": 8,
    "out:El bought/kWh/m2": 4078.5,
    "out:Return %": 16,
    "out:Return/kSEK/y": 1846,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 2958000,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 147900,
    "out:EAHP (replacement cost)": 2204400,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 40.06,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 843452,
    "out:EL kWh savings": -198029,
    "out:Sewage heat recovery cost": 250448,
    "out:Balancing hydronic system cost": 128574.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 430892,
    "out:DH kr savings": 767541,
    "out:El kr savings": -336649,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 38976,
    "out:% savings (space heating)": 94.08,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 4.59,
    "out:Etvv": 0,
    "out:Ef": 54.4,
    "out:heatPumpQuantity": 20,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000002010000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 11.78,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 60.37,
    "out:Electricity (inc PV)": 45.69,
    "out:Total Energy Use Pre PV": 72.37,
    "out:Total Energy Use Post PV": 57.69,
    "out:Primary Energy": 87.12,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 26.73,
    "out:CO2 Operational/m2": 8.86,
    "out:Total CO2/m2": 35.59,
    "out:Total CO2 (tons)": 183.05,
    "out:Klimatpaverkan": -146.19,
    "out:Initial Cost/MSEK": 12.211125,
    "out:Running cost/(Apt SEK y)": 14124,
    "out:Running Cost over RSP/MSEK": 4.614,
    "out:LCP/MSEK": 2.444,
    "out:ROI% old": 17.15,
    "out:Payback discounted": 7,
    "out:Atemp": 5143,
    "out:Umean": 0.49,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 58,
    "out:Energy use kWh/m2": 58,
    "out:Energy savings %": 224.1,
    "out:Op energy cost/MSEK": 32,
    "out:El price/MSEK": 28,
    "out:Heating price/MSEK": 3,
    "out:DH+ DHW/kWh/m2": 12,
    "out:El bought/kWh/m2": 3512.5,
    "out:Return %": 15,
    "out:Return/kSEK/y": 1871,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 2958000,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 147900,
    "out:EAHP (replacement cost)": 2204400,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 42.17,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 61.265983,
    "out:PV (m2 panels)": 321,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 57484.06,
    "out:PV (kWh sold)": 2264.09,
    "out:PV (% sold (El))": 3.94,
    "out:PV (kWh self-consumed)": 55219.96,
    "out:PV (ratio sold/self-consumed)": 0.04,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 822880,
    "out:EL kWh savings": -153200,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 491268,
    "out:DH kr savings": 748821,
    "out:El kr savings": -260439,
    "out:El kr sold": 2887,
    "out:El kr saved": 93874,
    "out:El kr return": 96761,
    "out:% solar/total": 68,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 60574,
    "out:% savings (space heating)": 90.8,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 6.98,
    "out:Etvv": 0,
    "out:Ef": 45.69,
    "out:heatPumpQuantity": 20,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000002010000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 9.23,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 59.74,
    "out:Electricity (inc PV)": 44.89,
    "out:Total Energy Use Pre PV": 68.74,
    "out:Total Energy Use Post PV": 53.89,
    "out:Primary Energy": 84.42,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 26.73,
    "out:CO2 Operational/m2": 7.56,
    "out:Total CO2/m2": 34.29,
    "out:Total CO2 (tons)": 176.37,
    "out:Klimatpaverkan": -152.87,
    "out:Initial Cost/MSEK": 12.339625,
    "out:Running cost/(Apt SEK y)": 13030,
    "out:Running Cost over RSP/MSEK": 4.254,
    "out:LCP/MSEK": 2.675,
    "out:ROI% old": 17.38,
    "out:Payback discounted": 7,
    "out:Atemp": 5143,
    "out:Umean": 0.49,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 54,
    "out:Energy use kWh/m2": 54,
    "out:Energy savings %": 248.1,
    "out:Op energy cost/MSEK": 29,
    "out:El price/MSEK": 26,
    "out:Heating price/MSEK": 3,
    "out:DH+ DHW/kWh/m2": 9,
    "out:El bought/kWh/m2": 3470.3,
    "out:Return %": 16,
    "out:Return/kSEK/y": 1917,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 2958000,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 147900,
    "out:EAHP (replacement cost)": 2204400,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 41.54,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 61.265983,
    "out:PV (m2 panels)": 321,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 57484.06,
    "out:PV (kWh sold)": 2413.13,
    "out:PV (% sold (El))": 4.2,
    "out:PV (kWh self-consumed)": 55070.93,
    "out:PV (ratio sold/self-consumed)": 0.04,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 838309,
    "out:EL kWh savings": -149072,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 128574.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 512515,
    "out:DH kr savings": 762861,
    "out:El kr savings": -253423,
    "out:El kr sold": 3077,
    "out:El kr saved": 93621,
    "out:El kr return": 96697,
    "out:% solar/total": 67,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 47473,
    "out:% savings (space heating)": 92.79,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 5.18,
    "out:Etvv": 0,
    "out:Ef": 44.89,
    "out:heatPumpQuantity": 20,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000002010001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 9.9,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 58.96,
    "out:Electricity (inc PV)": 44.83,
    "out:Total Energy Use Pre PV": 68.96,
    "out:Total Energy Use Post PV": 54.83,
    "out:Primary Energy": 85.05,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 27.13,
    "out:CO2 Operational/m2": 7.64,
    "out:Total CO2/m2": 34.77,
    "out:Total CO2 (tons)": 178.83,
    "out:Klimatpaverkan": -150.41,
    "out:Initial Cost/MSEK": 12.4615,
    "out:Running cost/(Apt SEK y)": 13191,
    "out:Running Cost over RSP/MSEK": 4.308,
    "out:LCP/MSEK": 2.5,
    "out:ROI% old": 17.15,
    "out:Payback discounted": 7,
    "out:Atemp": 5143,
    "out:Umean": 0.49,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 55,
    "out:Energy use kWh/m2": 55,
    "out:Energy savings %": 241.8,
    "out:Op energy cost/MSEK": 30,
    "out:El price/MSEK": 26,
    "out:Heating price/MSEK": 3,
    "out:DH+ DHW/kWh/m2": 10,
    "out:El bought/kWh/m2": 3417.5,
    "out:Return %": 15,
    "out:Return/kSEK/y": 1910,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 2958000,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 147900,
    "out:EAHP (replacement cost)": 2204400,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 40.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 61.265983,
    "out:PV (m2 panels)": 321,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 57484.06,
    "out:PV (kWh sold)": 2606.87,
    "out:PV (% sold (El))": 4.53,
    "out:PV (kWh self-consumed)": 54877.19,
    "out:PV (ratio sold/self-consumed)": 0.05,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 833166,
    "out:EL kWh savings": -148795,
    "out:Sewage heat recovery cost": 250448,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 508553,
    "out:DH kr savings": 758181,
    "out:El kr savings": -252951,
    "out:El kr sold": 3324,
    "out:El kr saved": 93291,
    "out:El kr return": 96615,
    "out:% solar/total": 67,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 50918,
    "out:% savings (space heating)": 92.27,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 6.23,
    "out:Etvv": 0,
    "out:Ef": 44.83,
    "out:heatPumpQuantity": 20,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000002010001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 7.58,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 58.26,
    "out:Electricity (inc PV)": 43.98,
    "out:Total Energy Use Pre PV": 66.26,
    "out:Total Energy Use Post PV": 51.98,
    "out:Primary Energy": 82.38,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 27.13,
    "out:CO2 Operational/m2": 6.66,
    "out:Total CO2/m2": 33.79,
    "out:Total CO2 (tons)": 173.8,
    "out:Klimatpaverkan": -155.44,
    "out:Initial Cost/MSEK": 12.59,
    "out:Running cost/(Apt SEK y)": 12405,
    "out:Running Cost over RSP/MSEK": 4.05,
    "out:LCP/MSEK": 2.629,
    "out:ROI% old": 17.27,
    "out:Payback discounted": 7,
    "out:Atemp": 5143,
    "out:Umean": 0.49,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 52,
    "out:Energy use kWh/m2": 52,
    "out:Energy savings %": 261.5,
    "out:Op energy cost/MSEK": 28,
    "out:El price/MSEK": 25,
    "out:Heating price/MSEK": 2,
    "out:DH+ DHW/kWh/m2": 8,
    "out:El bought/kWh/m2": 3370.8,
    "out:Return %": 15,
    "out:Return/kSEK/y": 1943,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 2958000,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 147900,
    "out:EAHP (replacement cost)": 2204400,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 40.06,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 61.265983,
    "out:PV (m2 panels)": 321,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 57484.06,
    "out:PV (kWh sold)": 2783.72,
    "out:PV (% sold (El))": 4.84,
    "out:PV (kWh self-consumed)": 54700.34,
    "out:PV (ratio sold/self-consumed)": 0.05,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 843452,
    "out:EL kWh savings": -144440,
    "out:Sewage heat recovery cost": 250448,
    "out:Balancing hydronic system cost": 128574.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 525543,
    "out:DH kr savings": 767541,
    "out:El kr savings": -245547,
    "out:El kr sold": 3549,
    "out:El kr saved": 92991,
    "out:El kr return": 96540,
    "out:% solar/total": 67,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 38976,
    "out:% savings (space heating)": 94.08,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 4.59,
    "out:Etvv": 0,
    "out:Ef": 43.98,
    "out:heatPumpQuantity": 20,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000002020000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 11.78,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 60.37,
    "out:Electricity (inc PV)": 41.13,
    "out:Total Energy Use Pre PV": 72.37,
    "out:Total Energy Use Post PV": 53.13,
    "out:Primary Energy": 78.92,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 39.08,
    "out:CO2 Operational/m2": -22.68,
    "out:Total CO2/m2": 16.4,
    "out:Total CO2 (tons)": 84.33,
    "out:Klimatpaverkan": -244.91,
    "out:Initial Cost/MSEK": 13.28125,
    "out:Running cost/(Apt SEK y)": 12118,
    "out:Running Cost over RSP/MSEK": 3.972,
    "out:LCP/MSEK": 2.016,
    "out:ROI% old": 16.45,
    "out:Payback discounted": 7,
    "out:Atemp": 5143,
    "out:Umean": 0.49,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 53,
    "out:Energy use kWh/m2": 53,
    "out:Energy savings %": 254.7,
    "out:Op energy cost/MSEK": 28,
    "out:El price/MSEK": 24,
    "out:Heating price/MSEK": 3,
    "out:DH+ DHW/kWh/m2": 12,
    "out:El bought/kWh/m2": 3154.2,
    "out:Return %": 15,
    "out:Return/kSEK/y": 1955,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 2958000,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 147900,
    "out:EAHP (replacement cost)": 2204400,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 42.17,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.33,
    "out:PV (kWp installed)": 122.531965,
    "out:PV (m2 panels)": 643,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 114968.11,
    "out:PV (kWh sold)": 33974.07,
    "out:PV (% sold (El))": 29.55,
    "out:PV (kWh self-consumed)": 80994.04,
    "out:PV (ratio sold/self-consumed)": 0.42,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 822880,
    "out:EL kWh savings": -129751,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 571561,
    "out:DH kr savings": 748821,
    "out:El kr savings": -220577,
    "out:El kr sold": 43317,
    "out:El kr saved": 137690,
    "out:El kr return": 181007,
    "out:% solar/total": 99,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 60574,
    "out:% savings (space heating)": 90.8,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 6.98,
    "out:Etvv": 0,
    "out:Ef": 41.13,
    "out:heatPumpQuantity": 20,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000002020000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 9.23,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 59.74,
    "out:Electricity (inc PV)": 40.42,
    "out:Total Energy Use Pre PV": 68.74,
    "out:Total Energy Use Post PV": 49.42,
    "out:Primary Energy": 76.37,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 39.08,
    "out:CO2 Operational/m2": -24.33,
    "out:Total CO2/m2": 14.75,
    "out:Total CO2 (tons)": 75.86,
    "out:Klimatpaverkan": -253.38,
    "out:Initial Cost/MSEK": 13.41,
    "out:Running cost/(Apt SEK y)": 11028,
    "out:Running Cost over RSP/MSEK": 3.614,
    "out:LCP/MSEK": 2.246,
    "out:ROI% old": 16.68,
    "out:Payback discounted": 7,
    "out:Atemp": 5143,
    "out:Umean": 0.49,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 49,
    "out:Energy use kWh/m2": 49,
    "out:Energy savings %": 283.7,
    "out:Op energy cost/MSEK": 25,
    "out:El price/MSEK": 22,
    "out:Heating price/MSEK": 3,
    "out:DH+ DHW/kWh/m2": 9,
    "out:El bought/kWh/m2": 3116.8,
    "out:Return %": 15,
    "out:Return/kSEK/y": 2001,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 2958000,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 147900,
    "out:EAHP (replacement cost)": 2204400,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 41.54,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.33,
    "out:PV (kWp installed)": 122.531965,
    "out:PV (m2 panels)": 643,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 114968.11,
    "out:PV (kWh sold)": 34474.08,
    "out:PV (% sold (El))": 29.99,
    "out:PV (kWh self-consumed)": 80494.03,
    "out:PV (ratio sold/self-consumed)": 0.43,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 838309,
    "out:EL kWh savings": -126091,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 128574.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 592461,
    "out:DH kr savings": 762861,
    "out:El kr savings": -214355,
    "out:El kr sold": 43954,
    "out:El kr saved": 136840,
    "out:El kr return": 180794,
    "out:% solar/total": 98,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 47473,
    "out:% savings (space heating)": 92.79,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 5.18,
    "out:Etvv": 0,
    "out:Ef": 40.42,
    "out:heatPumpQuantity": 20,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000002020001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 9.9,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 58.96,
    "out:Electricity (inc PV)": 40.37,
    "out:Total Energy Use Pre PV": 68.96,
    "out:Total Energy Use Post PV": 50.37,
    "out:Primary Energy": 77.02,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 39.48,
    "out:CO2 Operational/m2": -24.69,
    "out:Total CO2/m2": 14.79,
    "out:Total CO2 (tons)": 76.09,
    "out:Klimatpaverkan": -253.15,
    "out:Initial Cost/MSEK": 13.53125,
    "out:Running cost/(Apt SEK y)": 11193,
    "out:Running Cost over RSP/MSEK": 3.668,
    "out:LCP/MSEK": 2.069,
    "out:ROI% old": 16.47,
    "out:Payback discounted": 7,
    "out:Atemp": 5143,
    "out:Umean": 0.49,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 50,
    "out:Energy use kWh/m2": 50,
    "out:Energy savings %": 276,
    "out:Op energy cost/MSEK": 26,
    "out:El price/MSEK": 22,
    "out:Heating price/MSEK": 3,
    "out:DH+ DHW/kWh/m2": 10,
    "out:El bought/kWh/m2": 3070.1,
    "out:Return %": 15,
    "out:Return/kSEK/y": 1994,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 2958000,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 147900,
    "out:EAHP (replacement cost)": 2204400,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 40.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.33,
    "out:PV (kWp installed)": 122.531965,
    "out:PV (m2 panels)": 643,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 114968.11,
    "out:PV (kWh sold)": 35109.67,
    "out:PV (% sold (El))": 30.54,
    "out:PV (kWh self-consumed)": 79858.44,
    "out:PV (ratio sold/self-consumed)": 0.44,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 833166,
    "out:EL kWh savings": -125845,
    "out:Sewage heat recovery cost": 250448,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 589009,
    "out:DH kr savings": 758181,
    "out:El kr savings": -213937,
    "out:El kr sold": 44765,
    "out:El kr saved": 135759,
    "out:El kr return": 180524,
    "out:% solar/total": 98,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 50918,
    "out:% savings (space heating)": 92.27,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 6.23,
    "out:Etvv": 0,
    "out:Ef": 40.37,
    "out:heatPumpQuantity": 20,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000002020001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 7.58,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 58.26,
    "out:Electricity (inc PV)": 39.62,
    "out:Total Energy Use Pre PV": 66.26,
    "out:Total Energy Use Post PV": 47.62,
    "out:Primary Energy": 74.52,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 39.48,
    "out:CO2 Operational/m2": -26.05,
    "out:Total CO2/m2": 13.43,
    "out:Total CO2 (tons)": 69.08,
    "out:Klimatpaverkan": -260.16,
    "out:Initial Cost/MSEK": 13.66,
    "out:Running cost/(Apt SEK y)": 10411,
    "out:Running Cost over RSP/MSEK": 3.412,
    "out:LCP/MSEK": 2.197,
    "out:ROI% old": 16.58,
    "out:Payback discounted": 7,
    "out:Atemp": 5143,
    "out:Umean": 0.49,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 48,
    "out:Energy use kWh/m2": 48,
    "out:Energy savings %": 291.7,
    "out:Op energy cost/MSEK": 24,
    "out:El price/MSEK": 21,
    "out:Heating price/MSEK": 2,
    "out:DH+ DHW/kWh/m2": 8,
    "out:El bought/kWh/m2": 3028.8,
    "out:Return %": 15,
    "out:Return/kSEK/y": 2027,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 2958000,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 147900,
    "out:EAHP (replacement cost)": 2204400,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 40.06,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.33,
    "out:PV (kWp installed)": 122.531965,
    "out:PV (m2 panels)": 643,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 114968.11,
    "out:PV (kWh sold)": 35678.07,
    "out:PV (% sold (El))": 31.03,
    "out:PV (kWh self-consumed)": 79290.04,
    "out:PV (ratio sold/self-consumed)": 0.45,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 843452,
    "out:EL kWh savings": -121979,
    "out:Sewage heat recovery cost": 250448,
    "out:Balancing hydronic system cost": 128574.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 605667,
    "out:DH kr savings": 767541,
    "out:El kr savings": -207364,
    "out:El kr sold": 45490,
    "out:El kr saved": 134793,
    "out:El kr return": 180283,
    "out:% solar/total": 97,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 38976,
    "out:% savings (space heating)": 94.08,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 4.59,
    "out:Etvv": 0,
    "out:Ef": 39.62,
    "out:heatPumpQuantity": 20,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000002100000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 9.32,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 58.47,
    "out:Electricity (inc PV)": 55,
    "out:Total Energy Use Pre PV": 67.47,
    "out:Total Energy Use Post PV": 64,
    "out:Primary Energy": 103.19,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 14.38,
    "out:CO2 Operational/m2": 10.96,
    "out:Total CO2/m2": 25.33,
    "out:Total CO2 (tons)": 130.29,
    "out:Klimatpaverkan": -198.95,
    "out:Initial Cost/MSEK": 11.252125,
    "out:Running cost/(Apt SEK y)": 15069,
    "out:Running Cost over RSP/MSEK": 4.904,
    "out:LCP/MSEK": 3.113,
    "out:ROI% old": 18.24,
    "out:Payback discounted": 7,
    "out:Atemp": 5143,
    "out:Umean": 0.49,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 64,
    "out:Energy use kWh/m2": 64,
    "out:Energy savings %": 193.8,
    "out:Op energy cost/MSEK": 33,
    "out:El price/MSEK": 30,
    "out:Heating price/MSEK": 3,
    "out:DH+ DHW/kWh/m2": 9,
    "out:El bought/kWh/m2": 4093.2,
    "out:Return %": 16,
    "out:Return/kSEK/y": 1831,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 2958000,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 147900,
    "out:EAHP (replacement cost)": 2204400,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 40.28,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 838309,
    "out:EL kWh savings": -201078,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 421028,
    "out:DH kr savings": 762861,
    "out:El kr savings": -341833,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 47919,
    "out:% savings (space heating)": 92.72,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 5.99,
    "out:Etvv": 0,
    "out:Ef": 55,
    "out:heatPumpQuantity": 20,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000002100000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 7.08,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 57.76,
    "out:Electricity (inc PV)": 54.08,
    "out:Total Energy Use Pre PV": 64.76,
    "out:Total Energy Use Post PV": 61.08,
    "out:Primary Energy": 100.43,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 14.38,
    "out:CO2 Operational/m2": 10.14,
    "out:Total CO2/m2": 24.52,
    "out:Total CO2 (tons)": 126.1,
    "out:Klimatpaverkan": -203.14,
    "out:Initial Cost/MSEK": 11.38075,
    "out:Running cost/(Apt SEK y)": 14276,
    "out:Running Cost over RSP/MSEK": 4.644,
    "out:LCP/MSEK": 3.244,
    "out:ROI% old": 18.36,
    "out:Payback discounted": 7,
    "out:Atemp": 5143,
    "out:Umean": 0.49,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 61,
    "out:Energy use kWh/m2": 61,
    "out:Energy savings %": 208.2,
    "out:Op energy cost/MSEK": 31,
    "out:El price/MSEK": 29,
    "out:Heating price/MSEK": 2,
    "out:DH+ DHW/kWh/m2": 7,
    "out:El bought/kWh/m2": 4043,
    "out:Return %": 16,
    "out:Return/kSEK/y": 1864,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 2958000,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 147900,
    "out:EAHP (replacement cost)": 2204400,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 39.56,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 848595,
    "out:EL kWh savings": -196362,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 128574.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 438405,
    "out:DH kr savings": 772221,
    "out:El kr savings": -333816,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 36400,
    "out:% savings (space heating)": 94.47,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 4.41,
    "out:Etvv": 0,
    "out:Ef": 54.08,
    "out:heatPumpQuantity": 20,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000002100001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 8.01,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 57.29,
    "out:Electricity (inc PV)": 54.25,
    "out:Total Energy Use Pre PV": 65.29,
    "out:Total Energy Use Post PV": 62.25,
    "out:Primary Energy": 101.47,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 14.78,
    "out:CO2 Operational/m2": 10.44,
    "out:Total CO2/m2": 25.22,
    "out:Total CO2 (tons)": 129.71,
    "out:Klimatpaverkan": -199.53,
    "out:Initial Cost/MSEK": 11.502625,
    "out:Running cost/(Apt SEK y)": 14501,
    "out:Running Cost over RSP/MSEK": 4.719,
    "out:LCP/MSEK": 3.048,
    "out:ROI% old": 18.07,
    "out:Payback discounted": 7,
    "out:Atemp": 5143,
    "out:Umean": 0.49,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 62,
    "out:Energy use kWh/m2": 62,
    "out:Energy savings %": 203.2,
    "out:Op energy cost/MSEK": 32,
    "out:El price/MSEK": 29,
    "out:Heating price/MSEK": 2,
    "out:DH+ DHW/kWh/m2": 8,
    "out:El bought/kWh/m2": 4010.3,
    "out:Return %": 16,
    "out:Return/kSEK/y": 1855,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 2958000,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 147900,
    "out:EAHP (replacement cost)": 2204400,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 39.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 843452,
    "out:EL kWh savings": -197224,
    "out:Sewage heat recovery cost": 250448,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 432260,
    "out:DH kr savings": 767541,
    "out:El kr savings": -335281,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 41187,
    "out:% savings (space heating)": 93.75,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 5.47,
    "out:Etvv": 0,
    "out:Ef": 54.25,
    "out:heatPumpQuantity": 20,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000002100001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 6,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 56.5,
    "out:Electricity (inc PV)": 53.29,
    "out:Total Energy Use Pre PV": 62.5,
    "out:Total Energy Use Post PV": 59.29,
    "out:Primary Energy": 98.73,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 14.78,
    "out:CO2 Operational/m2": 9.62,
    "out:Total CO2/m2": 24.4,
    "out:Total CO2 (tons)": 125.47,
    "out:Klimatpaverkan": -203.77,
    "out:Initial Cost/MSEK": 11.631125,
    "out:Running cost/(Apt SEK y)": 13693,
    "out:Running Cost over RSP/MSEK": 4.454,
    "out:LCP/MSEK": 3.184,
    "out:ROI% old": 18.2,
    "out:Payback discounted": 7,
    "out:Atemp": 5143,
    "out:Umean": 0.49,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 59,
    "out:Energy use kWh/m2": 59,
    "out:Energy savings %": 218.6,
    "out:Op energy cost/MSEK": 30,
    "out:El price/MSEK": 28,
    "out:Heating price/MSEK": 2,
    "out:DH+ DHW/kWh/m2": 6,
    "out:El bought/kWh/m2": 3955.2,
    "out:Return %": 16,
    "out:Return/kSEK/y": 1889,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 2958000,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 147900,
    "out:EAHP (replacement cost)": 2204400,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 38.3,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 853738,
    "out:EL kWh savings": -192318,
    "out:Sewage heat recovery cost": 250448,
    "out:Balancing hydronic system cost": 128574.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 449961,
    "out:DH kr savings": 776902,
    "out:El kr savings": -326940,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 30836,
    "out:% savings (space heating)": 95.32,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 4,
    "out:Etvv": 0,
    "out:Ef": 53.29,
    "out:heatPumpQuantity": 20,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000002110000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 9.32,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 58.47,
    "out:Electricity (inc PV)": 44.54,
    "out:Total Energy Use Pre PV": 67.47,
    "out:Total Energy Use Post PV": 53.54,
    "out:Primary Energy": 84.37,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 26.73,
    "out:CO2 Operational/m2": 7.1,
    "out:Total CO2/m2": 33.83,
    "out:Total CO2 (tons)": 173.99,
    "out:Klimatpaverkan": -155.25,
    "out:Initial Cost/MSEK": 12.321875,
    "out:Running cost/(Apt SEK y)": 12770,
    "out:Running Cost over RSP/MSEK": 4.17,
    "out:LCP/MSEK": 2.777,
    "out:ROI% old": 17.51,
    "out:Payback discounted": 7,
    "out:Atemp": 5143,
    "out:Umean": 0.49,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 54,
    "out:Energy use kWh/m2": 54,
    "out:Energy savings %": 248.1,
    "out:Op energy cost/MSEK": 29,
    "out:El price/MSEK": 26,
    "out:Heating price/MSEK": 3,
    "out:DH+ DHW/kWh/m2": 9,
    "out:El bought/kWh/m2": 3385,
    "out:Return %": 16,
    "out:Return/kSEK/y": 1928,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 2958000,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 147900,
    "out:EAHP (replacement cost)": 2204400,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 40.28,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 61.265983,
    "out:PV (m2 panels)": 321,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 57484.06,
    "out:PV (kWh sold)": 2729.21,
    "out:PV (% sold (El))": 4.75,
    "out:PV (kWh self-consumed)": 54754.84,
    "out:PV (ratio sold/self-consumed)": 0.05,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 838309,
    "out:EL kWh savings": -147305,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 515923,
    "out:DH kr savings": 762861,
    "out:El kr savings": -250418,
    "out:El kr sold": 3480,
    "out:El kr saved": 93083,
    "out:El kr return": 96563,
    "out:% solar/total": 67,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 47919,
    "out:% savings (space heating)": 92.72,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 5.99,
    "out:Etvv": 0,
    "out:Ef": 44.54,
    "out:heatPumpQuantity": 20,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000002110000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 7.08,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 57.76,
    "out:Electricity (inc PV)": 43.68,
    "out:Total Energy Use Pre PV": 64.76,
    "out:Total Energy Use Post PV": 50.68,
    "out:Primary Energy": 81.71,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 26.73,
    "out:CO2 Operational/m2": 6.11,
    "out:Total CO2/m2": 32.84,
    "out:Total CO2 (tons)": 168.89,
    "out:Klimatpaverkan": -160.35,
    "out:Initial Cost/MSEK": 12.4505,
    "out:Running cost/(Apt SEK y)": 11979,
    "out:Running Cost over RSP/MSEK": 3.91,
    "out:LCP/MSEK": 2.908,
    "out:ROI% old": 17.62,
    "out:Payback discounted": 7,
    "out:Atemp": 5143,
    "out:Umean": 0.49,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 51,
    "out:Energy use kWh/m2": 51,
    "out:Energy savings %": 268.6,
    "out:Op energy cost/MSEK": 27,
    "out:El price/MSEK": 24,
    "out:Heating price/MSEK": 2,
    "out:DH+ DHW/kWh/m2": 7,
    "out:El bought/kWh/m2": 3336.8,
    "out:Return %": 16,
    "out:Return/kSEK/y": 1961,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 2958000,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 147900,
    "out:EAHP (replacement cost)": 2204400,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 39.56,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 61.265983,
    "out:PV (m2 panels)": 321,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 57484.06,
    "out:PV (kWh sold)": 2917.21,
    "out:PV (% sold (El))": 5.07,
    "out:PV (kWh self-consumed)": 54566.84,
    "out:PV (ratio sold/self-consumed)": 0.05,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 848595,
    "out:EL kWh savings": -142877,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 128574.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 533049,
    "out:DH kr savings": 772221,
    "out:El kr savings": -242891,
    "out:El kr sold": 3719,
    "out:El kr saved": 92764,
    "out:El kr return": 96483,
    "out:% solar/total": 67,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 36400,
    "out:% savings (space heating)": 94.47,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 4.41,
    "out:Etvv": 0,
    "out:Ef": 43.68,
    "out:heatPumpQuantity": 20,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000002110001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 8.01,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 57.29,
    "out:Electricity (inc PV)": 43.84,
    "out:Total Energy Use Pre PV": 65.29,
    "out:Total Energy Use Post PV": 51.84,
    "out:Primary Energy": 82.73,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 27.13,
    "out:CO2 Operational/m2": 6.29,
    "out:Total CO2/m2": 33.42,
    "out:Total CO2 (tons)": 171.89,
    "out:Klimatpaverkan": -157.35,
    "out:Initial Cost/MSEK": 12.5725,
    "out:Running cost/(Apt SEK y)": 12205,
    "out:Running Cost over RSP/MSEK": 3.985,
    "out:LCP/MSEK": 2.712,
    "out:ROI% old": 17.37,
    "out:Payback discounted": 7,
    "out:Atemp": 5143,
    "out:Umean": 0.49,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 52,
    "out:Energy use kWh/m2": 52,
    "out:Energy savings %": 261.5,
    "out:Op energy cost/MSEK": 27,
    "out:El price/MSEK": 25,
    "out:Heating price/MSEK": 2,
    "out:DH+ DHW/kWh/m2": 8,
    "out:El bought/kWh/m2": 3305.4,
    "out:Return %": 16,
    "out:Return/kSEK/y": 1951,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 2958000,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 147900,
    "out:EAHP (replacement cost)": 2204400,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 39.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 61.265983,
    "out:PV (m2 panels)": 321,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 57484.06,
    "out:PV (kWh sold)": 3043.82,
    "out:PV (% sold (El))": 5.3,
    "out:PV (kWh self-consumed)": 54440.23,
    "out:PV (ratio sold/self-consumed)": 0.06,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 843452,
    "out:EL kWh savings": -143685,
    "out:Sewage heat recovery cost": 250448,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 527158,
    "out:DH kr savings": 767541,
    "out:El kr savings": -244264,
    "out:El kr sold": 3881,
    "out:El kr saved": 92548,
    "out:El kr return": 96429,
    "out:% solar/total": 67,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 41187,
    "out:% savings (space heating)": 93.75,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 5.47,
    "out:Etvv": 0,
    "out:Ef": 43.84,
    "out:heatPumpQuantity": 20,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000002110001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 6,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 56.5,
    "out:Electricity (inc PV)": 42.94,
    "out:Total Energy Use Pre PV": 62.5,
    "out:Total Energy Use Post PV": 48.94,
    "out:Primary Energy": 80.1,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 27.13,
    "out:CO2 Operational/m2": 5.26,
    "out:Total CO2/m2": 32.39,
    "out:Total CO2 (tons)": 166.59,
    "out:Klimatpaverkan": -162.65,
    "out:Initial Cost/MSEK": 12.70125,
    "out:Running cost/(Apt SEK y)": 11399,
    "out:Running Cost over RSP/MSEK": 3.721,
    "out:LCP/MSEK": 2.847,
    "out:ROI% old": 17.49,
    "out:Payback discounted": 7,
    "out:Atemp": 5143,
    "out:Umean": 0.49,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 49,
    "out:Energy use kWh/m2": 49,
    "out:Energy savings %": 283.7,
    "out:Op energy cost/MSEK": 25,
    "out:El price/MSEK": 23,
    "out:Heating price/MSEK": 2,
    "out:DH+ DHW/kWh/m2": 6,
    "out:El bought/kWh/m2": 3252.7,
    "out:Return %": 16,
    "out:Return/kSEK/y": 1985,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 2958000,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 147900,
    "out:EAHP (replacement cost)": 2204400,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 38.3,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 61.265983,
    "out:PV (m2 panels)": 321,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 57484.06,
    "out:PV (kWh sold)": 3263.93,
    "out:PV (% sold (El))": 5.68,
    "out:PV (kWh self-consumed)": 54220.12,
    "out:PV (ratio sold/self-consumed)": 0.06,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 853738,
    "out:EL kWh savings": -139092,
    "out:Sewage heat recovery cost": 250448,
    "out:Balancing hydronic system cost": 128574.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 544607,
    "out:DH kr savings": 776902,
    "out:El kr savings": -236456,
    "out:El kr sold": 4162,
    "out:El kr saved": 92174,
    "out:El kr return": 96336,
    "out:% solar/total": 66,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 30836,
    "out:% savings (space heating)": 95.32,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 4,
    "out:Etvv": 0,
    "out:Ef": 42.94,
    "out:heatPumpQuantity": 20,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000002120000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 9.32,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 58.47,
    "out:Electricity (inc PV)": 40.11,
    "out:Total Energy Use Pre PV": 67.47,
    "out:Total Energy Use Post PV": 49.11,
    "out:Primary Energy": 76.4,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 39.08,
    "out:CO2 Operational/m2": -25.5,
    "out:Total CO2/m2": 13.59,
    "out:Total CO2 (tons)": 69.87,
    "out:Klimatpaverkan": -259.37,
    "out:Initial Cost/MSEK": 13.39125,
    "out:Running cost/(Apt SEK y)": 10775,
    "out:Running Cost over RSP/MSEK": 3.531,
    "out:LCP/MSEK": 2.346,
    "out:ROI% old": 16.79,
    "out:Payback discounted": 7,
    "out:Atemp": 5143,
    "out:Umean": 0.49,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 49,
    "out:Energy use kWh/m2": 49,
    "out:Energy savings %": 283.7,
    "out:Op energy cost/MSEK": 25,
    "out:El price/MSEK": 22,
    "out:Heating price/MSEK": 3,
    "out:DH+ DHW/kWh/m2": 9,
    "out:El bought/kWh/m2": 3041.3,
    "out:Return %": 15,
    "out:Return/kSEK/y": 2011,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 2958000,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 147900,
    "out:EAHP (replacement cost)": 2204400,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 40.28,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.33,
    "out:PV (kWp installed)": 122.531965,
    "out:PV (m2 panels)": 643,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 114968.11,
    "out:PV (kWh sold)": 35504.63,
    "out:PV (% sold (El))": 30.88,
    "out:PV (kWh self-consumed)": 79463.48,
    "out:PV (ratio sold/self-consumed)": 0.45,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 838309,
    "out:EL kWh savings": -124523,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 596441,
    "out:DH kr savings": 762861,
    "out:El kr savings": -211688,
    "out:El kr sold": 45268,
    "out:El kr saved": 135088,
    "out:El kr return": 180356,
    "out:% solar/total": 97,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 47919,
    "out:% savings (space heating)": 92.72,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 5.99,
    "out:Etvv": 0,
    "out:Ef": 40.11,
    "out:heatPumpQuantity": 20,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000002120000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 7.08,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 57.76,
    "out:Electricity (inc PV)": 39.35,
    "out:Total Energy Use Pre PV": 64.76,
    "out:Total Energy Use Post PV": 46.35,
    "out:Primary Energy": 73.91,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 39.08,
    "out:CO2 Operational/m2": -26.88,
    "out:Total CO2/m2": 12.2,
    "out:Total CO2 (tons)": 62.73,
    "out:Klimatpaverkan": -266.51,
    "out:Initial Cost/MSEK": 13.52,
    "out:Running cost/(Apt SEK y)": 9988,
    "out:Running Cost over RSP/MSEK": 3.273,
    "out:LCP/MSEK": 2.476,
    "out:ROI% old": 16.9,
    "out:Payback discounted": 7,
    "out:Atemp": 5143,
    "out:Umean": 0.49,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 46,
    "out:Energy use kWh/m2": 46,
    "out:Energy savings %": 308.7,
    "out:Op energy cost/MSEK": 23,
    "out:El price/MSEK": 21,
    "out:Heating price/MSEK": 2,
    "out:DH+ DHW/kWh/m2": 7,
    "out:El bought/kWh/m2": 2998.6,
    "out:Return %": 15,
    "out:Return/kSEK/y": 2044,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 2958000,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 147900,
    "out:EAHP (replacement cost)": 2204400,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 39.56,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.33,
    "out:PV (kWp installed)": 122.531965,
    "out:PV (m2 panels)": 643,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 114968.11,
    "out:PV (kWh sold)": 36096.93,
    "out:PV (% sold (El))": 31.4,
    "out:PV (kWh self-consumed)": 78871.18,
    "out:PV (ratio sold/self-consumed)": 0.46,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 848595,
    "out:EL kWh savings": -120590,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 128574.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 613242,
    "out:DH kr savings": 772221,
    "out:El kr savings": -205003,
    "out:El kr sold": 46024,
    "out:El kr saved": 134081,
    "out:El kr return": 180105,
    "out:% solar/total": 96,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 36400,
    "out:% savings (space heating)": 94.47,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 4.41,
    "out:Etvv": 0,
    "out:Ef": 39.35,
    "out:heatPumpQuantity": 20,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000002120001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 8.01,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 57.29,
    "out:Electricity (inc PV)": 39.49,
    "out:Total Energy Use Pre PV": 65.29,
    "out:Total Energy Use Post PV": 47.49,
    "out:Primary Energy": 74.9,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 39.48,
    "out:CO2 Operational/m2": -26.96,
    "out:Total CO2/m2": 12.52,
    "out:Total CO2 (tons)": 64.4,
    "out:Klimatpaverkan": -264.84,
    "out:Initial Cost/MSEK": 13.6425,
    "out:Running cost/(Apt SEK y)": 10216,
    "out:Running Cost over RSP/MSEK": 3.349,
    "out:LCP/MSEK": 2.278,
    "out:ROI% old": 16.67,
    "out:Payback discounted": 7,
    "out:Atemp": 5143,
    "out:Umean": 0.49,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 47,
    "out:Energy use kWh/m2": 47,
    "out:Energy savings %": 300,
    "out:Op energy cost/MSEK": 23,
    "out:El price/MSEK": 21,
    "out:Heating price/MSEK": 2,
    "out:DH+ DHW/kWh/m2": 8,
    "out:El bought/kWh/m2": 2970.9,
    "out:Return %": 15,
    "out:Return/kSEK/y": 2035,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 2958000,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 147900,
    "out:EAHP (replacement cost)": 2204400,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 39.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.33,
    "out:PV (kWp installed)": 122.531965,
    "out:PV (m2 panels)": 643,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 114968.11,
    "out:PV (kWh sold)": 36484.17,
    "out:PV (% sold (El))": 31.73,
    "out:PV (kWh self-consumed)": 78483.94,
    "out:PV (ratio sold/self-consumed)": 0.46,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 843452,
    "out:EL kWh savings": -121308,
    "out:Sewage heat recovery cost": 250448,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 607835,
    "out:DH kr savings": 767541,
    "out:El kr savings": -206223,
    "out:El kr sold": 46517,
    "out:El kr saved": 133423,
    "out:El kr return": 179940,
    "out:% solar/total": 96,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 41187,
    "out:% savings (space heating)": 93.75,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 5.47,
    "out:Etvv": 0,
    "out:Ef": 39.49,
    "out:heatPumpQuantity": 20,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000002120001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 6,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 56.5,
    "out:Electricity (inc PV)": 38.69,
    "out:Total Energy Use Pre PV": 62.5,
    "out:Total Energy Use Post PV": 44.69,
    "out:Primary Energy": 72.45,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 39.48,
    "out:CO2 Operational/m2": -28.43,
    "out:Total CO2/m2": 11.06,
    "out:Total CO2 (tons)": 56.87,
    "out:Klimatpaverkan": -272.37,
    "out:Initial Cost/MSEK": 13.77125,
    "out:Running cost/(Apt SEK y)": 9415,
    "out:Running Cost over RSP/MSEK": 3.086,
    "out:LCP/MSEK": 2.412,
    "out:ROI% old": 16.79,
    "out:Payback discounted": 7,
    "out:Atemp": 5143,
    "out:Umean": 0.49,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 45,
    "out:Energy use kWh/m2": 45,
    "out:Energy savings %": 317.8,
    "out:Op energy cost/MSEK": 22,
    "out:El price/MSEK": 20,
    "out:Heating price/MSEK": 2,
    "out:DH+ DHW/kWh/m2": 6,
    "out:El bought/kWh/m2": 2924.3,
    "out:Return %": 15,
    "out:Return/kSEK/y": 2068,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 2958000,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 147900,
    "out:EAHP (replacement cost)": 2204400,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 38.3,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.33,
    "out:PV (kWp installed)": 122.531965,
    "out:PV (m2 panels)": 643,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 114968.11,
    "out:PV (kWh sold)": 37140.58,
    "out:PV (% sold (El))": 32.31,
    "out:PV (kWh self-consumed)": 77827.53,
    "out:PV (ratio sold/self-consumed)": 0.48,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 853738,
    "out:EL kWh savings": -117225,
    "out:Sewage heat recovery cost": 250448,
    "out:Balancing hydronic system cost": 128574.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 624973,
    "out:DH kr savings": 776902,
    "out:El kr savings": -199283,
    "out:El kr sold": 47354,
    "out:El kr saved": 132307,
    "out:El kr return": 179661,
    "out:% solar/total": 95,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 30836,
    "out:% savings (space heating)": 95.32,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 4,
    "out:Etvv": 0,
    "out:Ef": 38.69,
    "out:heatPumpQuantity": 20,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000100000000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 95.97,
    "out:DHW (DH)": 43.5,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 15.9,
    "out:Total Energy Use Pre PV": 154.9,
    "out:Total Energy Use Post PV": 154.9,
    "out:Primary Energy": 113.3,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 12.98,
    "out:CO2 Operational/m2": 52.14,
    "out:Total CO2/m2": 65.12,
    "out:Total CO2 (tons)": 334.91,
    "out:Klimatpaverkan": 5.67,
    "out:Initial Cost/MSEK": 4.834875,
    "out:Running cost/(Apt SEK y)": 48044,
    "out:Running Cost over RSP/MSEK": 15.778,
    "out:LCP/MSEK": -1.344,
    "out:ROI% old": 10.31,
    "out:Payback discounted": 12,
    "out:Atemp": 5143,
    "out:Umean": 0.34,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "F",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 155,
    "out:Energy use kWh/m2": 155,
    "out:Energy savings %": 21.3,
    "out:Op energy cost/MSEK": 116,
    "out:El price/MSEK": 69,
    "out:Heating price/MSEK": 40,
    "out:DH+ DHW/kWh/m2": 139,
    "out:El bought/kWh/m2": 1113,
    "out:Return %": 9,
    "out:Return/kSEK/y": 446,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 169719,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 154444,
    "out:DH kr savings": 154444,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 493581,
    "out:% savings (space heating)": 25.05,
    "out:Total_Water Heating (DH)": 223720,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 95.97,
    "out:Etvv": 25,
    "out:Ef": 15.9,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z02000100000000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 91.88,
    "out:DHW (DH)": 43.5,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 15.9,
    "out:Total Energy Use Pre PV": 150.9,
    "out:Total Energy Use Post PV": 150.9,
    "out:Primary Energy": 110.44,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 12.98,
    "out:CO2 Operational/m2": 50.7,
    "out:Total CO2/m2": 63.68,
    "out:Total CO2 (tons)": 327.5,
    "out:Klimatpaverkan": -1.74,
    "out:Initial Cost/MSEK": 4.9635,
    "out:Running cost/(Apt SEK y)": 46757,
    "out:Running Cost over RSP/MSEK": 15.355,
    "out:LCP/MSEK": -1.05,
    "out:ROI% old": 11.26,
    "out:Payback discounted": 11,
    "out:Atemp": 5143,
    "out:Umean": 0.34,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "F",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 151,
    "out:Energy use kWh/m2": 151,
    "out:Energy savings %": 24.5,
    "out:Op energy cost/MSEK": 113,
    "out:El price/MSEK": 67,
    "out:Heating price/MSEK": 39,
    "out:DH+ DHW/kWh/m2": 135,
    "out:El bought/kWh/m2": 1113,
    "out:Return %": 10,
    "out:Return/kSEK/y": 500,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 190291,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 128574.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 173165,
    "out:DH kr savings": 173165,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 472548,
    "out:% savings (space heating)": 28.25,
    "out:Total_Water Heating (DH)": 223720,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 91.88,
    "out:Etvv": 25,
    "out:Ef": 15.9,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z02000100000001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 95.97,
    "out:DHW (DH)": 36.98,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 15.9,
    "out:Total Energy Use Pre PV": 148.9,
    "out:Total Energy Use Post PV": 148.9,
    "out:Primary Energy": 110.68,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 13.38,
    "out:CO2 Operational/m2": 49.98,
    "out:Total CO2/m2": 63.36,
    "out:Total CO2 (tons)": 325.87,
    "out:Klimatpaverkan": -3.37,
    "out:Initial Cost/MSEK": 5.085375,
    "out:Running cost/(Apt SEK y)": 46113,
    "out:Running Cost over RSP/MSEK": 15.144,
    "out:LCP/MSEK": -0.96,
    "out:ROI% old": 11.59,
    "out:Payback discounted": 10,
    "out:Atemp": 5143,
    "out:Umean": 0.34,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "F",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 149,
    "out:Energy use kWh/m2": 149,
    "out:Energy savings %": 26.2,
    "out:Op energy cost/MSEK": 111,
    "out:El price/MSEK": 67,
    "out:Heating price/MSEK": 38,
    "out:DH+ DHW/kWh/m2": 133,
    "out:El bought/kWh/m2": 1113,
    "out:Return %": 10,
    "out:Return/kSEK/y": 527,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 200577,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 250448,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 182525,
    "out:DH kr savings": 182525,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 493581,
    "out:% savings (space heating)": 25.05,
    "out:Total_Water Heating (DH)": 190162,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 95.97,
    "out:Etvv": 21.25,
    "out:Ef": 15.9,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z02000100000001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 91.88,
    "out:DHW (DH)": 36.98,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 15.9,
    "out:Total Energy Use Pre PV": 144.9,
    "out:Total Energy Use Post PV": 144.9,
    "out:Primary Energy": 107.81,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 13.38,
    "out:CO2 Operational/m2": 48.54,
    "out:Total CO2/m2": 61.92,
    "out:Total CO2 (tons)": 318.46,
    "out:Klimatpaverkan": -10.78,
    "out:Initial Cost/MSEK": 5.213875,
    "out:Running cost/(Apt SEK y)": 44826,
    "out:Running Cost over RSP/MSEK": 14.72,
    "out:LCP/MSEK": -0.665,
    "out:ROI% old": 12.46,
    "out:Payback discounted": 10,
    "out:Atemp": 5143,
    "out:Umean": 0.34,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "F",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 145,
    "out:Energy use kWh/m2": 145,
    "out:Energy savings %": 29.7,
    "out:Op energy cost/MSEK": 108,
    "out:El price/MSEK": 65,
    "out:Heating price/MSEK": 37,
    "out:DH+ DHW/kWh/m2": 129,
    "out:El bought/kWh/m2": 1113,
    "out:Return %": 11,
    "out:Return/kSEK/y": 581,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 221149,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 250448,
    "out:Balancing hydronic system cost": 128574.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 201246,
    "out:DH kr savings": 201246,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 472548,
    "out:% savings (space heating)": 28.25,
    "out:Total_Water Heating (DH)": 190162,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 91.88,
    "out:Etvv": 21.25,
    "out:Ef": 15.9,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z02000100010000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 95.97,
    "out:DHW (DH)": 43.5,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 10.73,
    "out:Total Energy Use Pre PV": 154.9,
    "out:Total Energy Use Post PV": 149.73,
    "out:Primary Energy": 103.99,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 25.34,
    "out:CO2 Operational/m2": 21.14,
    "out:Total CO2/m2": 46.48,
    "out:Total CO2 (tons)": 239.03,
    "out:Klimatpaverkan": -90.21,
    "out:Initial Cost/MSEK": 5.904625,
    "out:Running cost/(Apt SEK y)": 46030,
    "out:Running Cost over RSP/MSEK": 15.134,
    "out:LCP/MSEK": -1.77,
    "out:ROI% old": 10,
    "out:Payback discounted": 12,
    "out:Atemp": 5143,
    "out:Umean": 0.34,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "F",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 150,
    "out:Energy use kWh/m2": 150,
    "out:Energy savings %": 25.3,
    "out:Op energy cost/MSEK": 112,
    "out:El price/MSEK": 65,
    "out:Heating price/MSEK": 40,
    "out:DH+ DHW/kWh/m2": 139,
    "out:El bought/kWh/m2": 758.1,
    "out:Return %": 9,
    "out:Return/kSEK/y": 531,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 61.265983,
    "out:PV (m2 panels)": 321,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 57484.06,
    "out:PV (kWh sold)": 30886.05,
    "out:PV (% sold (El))": 53.73,
    "out:PV (kWh self-consumed)": 26598,
    "out:PV (ratio sold/self-consumed)": 1.16,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 169719,
    "out:EL kWh savings": 26598,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 239041,
    "out:DH kr savings": 154444,
    "out:El kr savings": 45217,
    "out:El kr sold": 39380,
    "out:El kr saved": 45217,
    "out:El kr return": 84596,
    "out:% solar/total": 33,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 493581,
    "out:% savings (space heating)": 25.05,
    "out:Total_Water Heating (DH)": 223720,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 95.97,
    "out:Etvv": 25,
    "out:Ef": 10.73,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z02000100010000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 91.88,
    "out:DHW (DH)": 43.5,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 10.73,
    "out:Total Energy Use Pre PV": 150.9,
    "out:Total Energy Use Post PV": 145.73,
    "out:Primary Energy": 101.13,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 25.34,
    "out:CO2 Operational/m2": 19.7,
    "out:Total CO2/m2": 45.04,
    "out:Total CO2 (tons)": 231.63,
    "out:Klimatpaverkan": -97.61,
    "out:Initial Cost/MSEK": 6.03325,
    "out:Running cost/(Apt SEK y)": 44743,
    "out:Running Cost over RSP/MSEK": 14.711,
    "out:LCP/MSEK": -1.475,
    "out:ROI% old": 10.79,
    "out:Payback discounted": 11,
    "out:Atemp": 5143,
    "out:Umean": 0.34,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "F",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 146,
    "out:Energy use kWh/m2": 146,
    "out:Energy savings %": 28.8,
    "out:Op energy cost/MSEK": 109,
    "out:El price/MSEK": 63,
    "out:Heating price/MSEK": 39,
    "out:DH+ DHW/kWh/m2": 135,
    "out:El bought/kWh/m2": 758.1,
    "out:Return %": 10,
    "out:Return/kSEK/y": 585,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 61.265983,
    "out:PV (m2 panels)": 321,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 57484.06,
    "out:PV (kWh sold)": 30886.05,
    "out:PV (% sold (El))": 53.73,
    "out:PV (kWh self-consumed)": 26598,
    "out:PV (ratio sold/self-consumed)": 1.16,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 190291,
    "out:EL kWh savings": 26598,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 128574.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 257761,
    "out:DH kr savings": 173165,
    "out:El kr savings": 45217,
    "out:El kr sold": 39380,
    "out:El kr saved": 45217,
    "out:El kr return": 84596,
    "out:% solar/total": 33,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 472548,
    "out:% savings (space heating)": 28.25,
    "out:Total_Water Heating (DH)": 223720,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 91.88,
    "out:Etvv": 25,
    "out:Ef": 10.73,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z02000100010001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 95.97,
    "out:DHW (DH)": 36.98,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 10.73,
    "out:Total Energy Use Pre PV": 148.9,
    "out:Total Energy Use Post PV": 143.73,
    "out:Primary Energy": 101.37,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 25.74,
    "out:CO2 Operational/m2": 18.98,
    "out:Total CO2/m2": 44.72,
    "out:Total CO2 (tons)": 229.99,
    "out:Klimatpaverkan": -99.25,
    "out:Initial Cost/MSEK": 6.155125,
    "out:Running cost/(Apt SEK y)": 44099,
    "out:Running Cost over RSP/MSEK": 14.5,
    "out:LCP/MSEK": -1.386,
    "out:ROI% old": 11.07,
    "out:Payback discounted": 11,
    "out:Atemp": 5143,
    "out:Umean": 0.34,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "F",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 144,
    "out:Energy use kWh/m2": 144,
    "out:Energy savings %": 30.6,
    "out:Op energy cost/MSEK": 107,
    "out:El price/MSEK": 63,
    "out:Heating price/MSEK": 38,
    "out:DH+ DHW/kWh/m2": 133,
    "out:El bought/kWh/m2": 758.1,
    "out:Return %": 10,
    "out:Return/kSEK/y": 612,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 61.265983,
    "out:PV (m2 panels)": 321,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 57484.06,
    "out:PV (kWh sold)": 30886.05,
    "out:PV (% sold (El))": 53.73,
    "out:PV (kWh self-consumed)": 26598,
    "out:PV (ratio sold/self-consumed)": 1.16,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 200577,
    "out:EL kWh savings": 26598,
    "out:Sewage heat recovery cost": 250448,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 267121,
    "out:DH kr savings": 182525,
    "out:El kr savings": 45217,
    "out:El kr sold": 39380,
    "out:El kr saved": 45217,
    "out:El kr return": 84596,
    "out:% solar/total": 33,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 493581,
    "out:% savings (space heating)": 25.05,
    "out:Total_Water Heating (DH)": 190162,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 95.97,
    "out:Etvv": 21.25,
    "out:Ef": 10.73,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z02000100010001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 91.88,
    "out:DHW (DH)": 36.98,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 10.73,
    "out:Total Energy Use Pre PV": 144.9,
    "out:Total Energy Use Post PV": 139.73,
    "out:Primary Energy": 98.5,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 25.74,
    "out:CO2 Operational/m2": 17.54,
    "out:Total CO2/m2": 43.28,
    "out:Total CO2 (tons)": 222.59,
    "out:Klimatpaverkan": -106.65,
    "out:Initial Cost/MSEK": 6.28375,
    "out:Running cost/(Apt SEK y)": 42812,
    "out:Running Cost over RSP/MSEK": 14.077,
    "out:LCP/MSEK": -1.091,
    "out:ROI% old": 11.8,
    "out:Payback discounted": 10,
    "out:Atemp": 5143,
    "out:Umean": 0.34,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "F",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 140,
    "out:Energy use kWh/m2": 140,
    "out:Energy savings %": 34.3,
    "out:Op energy cost/MSEK": 104,
    "out:El price/MSEK": 61,
    "out:Heating price/MSEK": 37,
    "out:DH+ DHW/kWh/m2": 129,
    "out:El bought/kWh/m2": 758.1,
    "out:Return %": 11,
    "out:Return/kSEK/y": 666,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 61.265983,
    "out:PV (m2 panels)": 321,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 57484.06,
    "out:PV (kWh sold)": 30886.05,
    "out:PV (% sold (El))": 53.73,
    "out:PV (kWh self-consumed)": 26598,
    "out:PV (ratio sold/self-consumed)": 1.16,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 221149,
    "out:EL kWh savings": 26598,
    "out:Sewage heat recovery cost": 250448,
    "out:Balancing hydronic system cost": 128574.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 285842,
    "out:DH kr savings": 201246,
    "out:El kr savings": 45217,
    "out:El kr sold": 39380,
    "out:El kr saved": 45217,
    "out:El kr return": 84596,
    "out:% solar/total": 33,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 472548,
    "out:% savings (space heating)": 28.25,
    "out:Total_Water Heating (DH)": 190162,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 91.88,
    "out:Etvv": 21.25,
    "out:Ef": 10.73,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z02000100020000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 95.97,
    "out:DHW (DH)": 43.5,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 9.88,
    "out:Total Energy Use Pre PV": 154.9,
    "out:Total Energy Use Post PV": 148.88,
    "out:Primary Energy": 102.46,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 37.69,
    "out:CO2 Operational/m2": -31.54,
    "out:Total CO2/m2": 6.15,
    "out:Total CO2 (tons)": 31.63,
    "out:Klimatpaverkan": -297.61,
    "out:Initial Cost/MSEK": 6.9745,
    "out:Running cost/(Apt SEK y)": 44241,
    "out:Running Cost over RSP/MSEK": 14.563,
    "out:LCP/MSEK": -2.268,
    "out:ROI% old": 9.64,
    "out:Payback discounted": 13,
    "out:Atemp": 5143,
    "out:Umean": 0.34,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "F",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 149,
    "out:Energy use kWh/m2": 149,
    "out:Energy savings %": 26.2,
    "out:Op energy cost/MSEK": 108,
    "out:El price/MSEK": 62,
    "out:Heating price/MSEK": 40,
    "out:DH+ DHW/kWh/m2": 139,
    "out:El bought/kWh/m2": 696.7,
    "out:Return %": 9,
    "out:Return/kSEK/y": 606,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.33,
    "out:PV (kWp installed)": 122.531965,
    "out:PV (m2 panels)": 643,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 114968.11,
    "out:PV (kWh sold)": 84004.99,
    "out:PV (% sold (El))": 73.07,
    "out:PV (kWh self-consumed)": 30963.12,
    "out:PV (ratio sold/self-consumed)": 2.71,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 169719,
    "out:EL kWh savings": 30963,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 314188,
    "out:DH kr savings": 154444,
    "out:El kr savings": 52637,
    "out:El kr sold": 107106,
    "out:El kr saved": 52637,
    "out:El kr return": 159744,
    "out:% solar/total": 38,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 493581,
    "out:% savings (space heating)": 25.05,
    "out:Total_Water Heating (DH)": 223720,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 95.97,
    "out:Etvv": 25,
    "out:Ef": 9.88,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z02000100020000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 91.88,
    "out:DHW (DH)": 43.5,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 9.88,
    "out:Total Energy Use Pre PV": 150.9,
    "out:Total Energy Use Post PV": 144.88,
    "out:Primary Energy": 99.6,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 37.69,
    "out:CO2 Operational/m2": -32.98,
    "out:Total CO2/m2": 4.71,
    "out:Total CO2 (tons)": 24.23,
    "out:Klimatpaverkan": -305.01,
    "out:Initial Cost/MSEK": 7.103,
    "out:Running cost/(Apt SEK y)": 42953,
    "out:Running Cost over RSP/MSEK": 14.14,
    "out:LCP/MSEK": -1.974,
    "out:ROI% old": 10.32,
    "out:Payback discounted": 12,
    "out:Atemp": 5143,
    "out:Umean": 0.34,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "F",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 145,
    "out:Energy use kWh/m2": 145,
    "out:Energy savings %": 29.7,
    "out:Op energy cost/MSEK": 105,
    "out:El price/MSEK": 60,
    "out:Heating price/MSEK": 39,
    "out:DH+ DHW/kWh/m2": 135,
    "out:El bought/kWh/m2": 696.7,
    "out:Return %": 9,
    "out:Return/kSEK/y": 660,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.33,
    "out:PV (kWp installed)": 122.531965,
    "out:PV (m2 panels)": 643,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 114968.11,
    "out:PV (kWh sold)": 84004.99,
    "out:PV (% sold (El))": 73.07,
    "out:PV (kWh self-consumed)": 30963.12,
    "out:PV (ratio sold/self-consumed)": 2.71,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 190291,
    "out:EL kWh savings": 30963,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 128574.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 332908,
    "out:DH kr savings": 173165,
    "out:El kr savings": 52637,
    "out:El kr sold": 107106,
    "out:El kr saved": 52637,
    "out:El kr return": 159744,
    "out:% solar/total": 38,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 472548,
    "out:% savings (space heating)": 28.25,
    "out:Total_Water Heating (DH)": 223720,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 91.88,
    "out:Etvv": 25,
    "out:Ef": 9.88,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z02000100020001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 95.97,
    "out:DHW (DH)": 36.98,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 9.88,
    "out:Total Energy Use Pre PV": 148.9,
    "out:Total Energy Use Post PV": 142.88,
    "out:Primary Energy": 99.84,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 38.09,
    "out:CO2 Operational/m2": -33.7,
    "out:Total CO2/m2": 4.39,
    "out:Total CO2 (tons)": 22.59,
    "out:Klimatpaverkan": -306.65,
    "out:Initial Cost/MSEK": 7.224875,
    "out:Running cost/(Apt SEK y)": 42310,
    "out:Running Cost over RSP/MSEK": 13.928,
    "out:LCP/MSEK": -1.884,
    "out:ROI% old": 10.56,
    "out:Payback discounted": 11,
    "out:Atemp": 5143,
    "out:Umean": 0.34,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "F",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 143,
    "out:Energy use kWh/m2": 143,
    "out:Energy savings %": 31.5,
    "out:Op energy cost/MSEK": 104,
    "out:El price/MSEK": 59,
    "out:Heating price/MSEK": 38,
    "out:DH+ DHW/kWh/m2": 133,
    "out:El bought/kWh/m2": 696.7,
    "out:Return %": 10,
    "out:Return/kSEK/y": 687,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.33,
    "out:PV (kWp installed)": 122.531965,
    "out:PV (m2 panels)": 643,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 114968.11,
    "out:PV (kWh sold)": 84004.99,
    "out:PV (% sold (El))": 73.07,
    "out:PV (kWh self-consumed)": 30963.12,
    "out:PV (ratio sold/self-consumed)": 2.71,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 200577,
    "out:EL kWh savings": 30963,
    "out:Sewage heat recovery cost": 250448,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 342269,
    "out:DH kr savings": 182525,
    "out:El kr savings": 52637,
    "out:El kr sold": 107106,
    "out:El kr saved": 52637,
    "out:El kr return": 159744,
    "out:% solar/total": 38,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 493581,
    "out:% savings (space heating)": 25.05,
    "out:Total_Water Heating (DH)": 190162,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 95.97,
    "out:Etvv": 21.25,
    "out:Ef": 9.88,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z02000100020001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 91.88,
    "out:DHW (DH)": 36.98,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 9.88,
    "out:Total Energy Use Pre PV": 144.9,
    "out:Total Energy Use Post PV": 138.88,
    "out:Primary Energy": 96.98,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 38.09,
    "out:CO2 Operational/m2": -35.14,
    "out:Total CO2/m2": 2.95,
    "out:Total CO2 (tons)": 15.19,
    "out:Klimatpaverkan": -314.05,
    "out:Initial Cost/MSEK": 7.3535,
    "out:Running cost/(Apt SEK y)": 41022,
    "out:Running Cost over RSP/MSEK": 13.505,
    "out:LCP/MSEK": -1.59,
    "out:ROI% old": 11.2,
    "out:Payback discounted": 11,
    "out:Atemp": 5143,
    "out:Umean": 0.34,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "F",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 139,
    "out:Energy use kWh/m2": 139,
    "out:Energy savings %": 35.3,
    "out:Op energy cost/MSEK": 100,
    "out:El price/MSEK": 57,
    "out:Heating price/MSEK": 37,
    "out:DH+ DHW/kWh/m2": 129,
    "out:El bought/kWh/m2": 696.7,
    "out:Return %": 10,
    "out:Return/kSEK/y": 741,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.33,
    "out:PV (kWp installed)": 122.531965,
    "out:PV (m2 panels)": 643,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 114968.11,
    "out:PV (kWh sold)": 84004.99,
    "out:PV (% sold (El))": 73.07,
    "out:PV (kWh self-consumed)": 30963.12,
    "out:PV (ratio sold/self-consumed)": 2.71,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 221149,
    "out:EL kWh savings": 30963,
    "out:Sewage heat recovery cost": 250448,
    "out:Balancing hydronic system cost": 128574.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 360989,
    "out:DH kr savings": 201246,
    "out:El kr savings": 52637,
    "out:El kr sold": 107106,
    "out:El kr saved": 52637,
    "out:El kr return": 159744,
    "out:% solar/total": 38,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 472548,
    "out:% savings (space heating)": 28.25,
    "out:Total_Water Heating (DH)": 190162,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 91.88,
    "out:Etvv": 21.25,
    "out:Ef": 9.88,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z02000100100000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 95.97,
    "out:DHW (DH)": 34.8,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 15.9,
    "out:Total Energy Use Pre PV": 146.9,
    "out:Total Energy Use Post PV": 146.9,
    "out:Primary Energy": 109.8,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 12.98,
    "out:CO2 Operational/m2": 49.26,
    "out:Total CO2/m2": 62.24,
    "out:Total CO2 (tons)": 320.09,
    "out:Klimatpaverkan": -9.15,
    "out:Initial Cost/MSEK": 4.94575,
    "out:Running cost/(Apt SEK y)": 45470,
    "out:Running Cost over RSP/MSEK": 14.932,
    "out:LCP/MSEK": -0.609,
    "out:ROI% old": 12.53,
    "out:Payback discounted": 10,
    "out:Atemp": 5143,
    "out:Umean": 0.34,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "F",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 147,
    "out:Energy use kWh/m2": 147,
    "out:Energy savings %": 27.9,
    "out:Op energy cost/MSEK": 109,
    "out:El price/MSEK": 66,
    "out:Heating price/MSEK": 38,
    "out:DH+ DHW/kWh/m2": 131,
    "out:El bought/kWh/m2": 1113,
    "out:Return %": 11,
    "out:Return/kSEK/y": 554,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 210863,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 191885,
    "out:DH kr savings": 191885,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 493581,
    "out:% savings (space heating)": 25.05,
    "out:Total_Water Heating (DH)": 178976,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 95.97,
    "out:Etvv": 20,
    "out:Ef": 15.9,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z02000100100000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 91.88,
    "out:DHW (DH)": 34.8,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 15.9,
    "out:Total Energy Use Pre PV": 142.9,
    "out:Total Energy Use Post PV": 142.9,
    "out:Primary Energy": 106.94,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 12.98,
    "out:CO2 Operational/m2": 47.82,
    "out:Total CO2/m2": 60.8,
    "out:Total CO2 (tons)": 312.69,
    "out:Klimatpaverkan": -16.55,
    "out:Initial Cost/MSEK": 5.07425,
    "out:Running cost/(Apt SEK y)": 44182,
    "out:Running Cost over RSP/MSEK": 14.509,
    "out:LCP/MSEK": -0.314,
    "out:ROI% old": 13.4,
    "out:Payback discounted": 9,
    "out:Atemp": 5143,
    "out:Umean": 0.34,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "F",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 143,
    "out:Energy use kWh/m2": 143,
    "out:Energy savings %": 31.5,
    "out:Op energy cost/MSEK": 106,
    "out:El price/MSEK": 64,
    "out:Heating price/MSEK": 36,
    "out:DH+ DHW/kWh/m2": 127,
    "out:El bought/kWh/m2": 1113,
    "out:Return %": 12,
    "out:Return/kSEK/y": 608,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 231435,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 128574.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 210606,
    "out:DH kr savings": 210606,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 472548,
    "out:% savings (space heating)": 28.25,
    "out:Total_Water Heating (DH)": 178976,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 91.88,
    "out:Etvv": 20,
    "out:Ef": 15.9,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z02000100100001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 95.97,
    "out:DHW (DH)": 29.58,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 15.9,
    "out:Total Energy Use Pre PV": 141.9,
    "out:Total Energy Use Post PV": 141.9,
    "out:Primary Energy": 107.7,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 13.38,
    "out:CO2 Operational/m2": 47.46,
    "out:Total CO2/m2": 60.84,
    "out:Total CO2 (tons)": 312.91,
    "out:Klimatpaverkan": -16.33,
    "out:Initial Cost/MSEK": 5.196125,
    "out:Running cost/(Apt SEK y)": 43860,
    "out:Running Cost over RSP/MSEK": 14.403,
    "out:LCP/MSEK": -0.33,
    "out:ROI% old": 13.38,
    "out:Payback discounted": 9,
    "out:Atemp": 5143,
    "out:Umean": 0.34,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "F",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 142,
    "out:Energy use kWh/m2": 142,
    "out:Energy savings %": 32.4,
    "out:Op energy cost/MSEK": 106,
    "out:El price/MSEK": 63,
    "out:Heating price/MSEK": 36,
    "out:DH+ DHW/kWh/m2": 126,
    "out:El bought/kWh/m2": 1113,
    "out:Return %": 12,
    "out:Return/kSEK/y": 622,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 236578,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 250448,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 215286,
    "out:DH kr savings": 215286,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 493581,
    "out:% savings (space heating)": 25.05,
    "out:Total_Water Heating (DH)": 152130,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 95.97,
    "out:Etvv": 17,
    "out:Ef": 15.9,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z02000100100001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 91.88,
    "out:DHW (DH)": 29.58,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 15.9,
    "out:Total Energy Use Pre PV": 136.9,
    "out:Total Energy Use Post PV": 136.9,
    "out:Primary Energy": 104.84,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 13.38,
    "out:CO2 Operational/m2": 45.66,
    "out:Total CO2/m2": 59.04,
    "out:Total CO2 (tons)": 303.65,
    "out:Klimatpaverkan": -25.59,
    "out:Initial Cost/MSEK": 5.32475,
    "out:Running cost/(Apt SEK y)": 42251,
    "out:Running Cost over RSP/MSEK": 13.874,
    "out:LCP/MSEK": 0.07,
    "out:ROI% old": 14.47,
    "out:Payback discounted": 8,
    "out:Atemp": 5143,
    "out:Umean": 0.34,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "F",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 137,
    "out:Energy use kWh/m2": 137,
    "out:Energy savings %": 37.2,
    "out:Op energy cost/MSEK": 102,
    "out:El price/MSEK": 61,
    "out:Heating price/MSEK": 35,
    "out:DH+ DHW/kWh/m2": 121,
    "out:El bought/kWh/m2": 1113,
    "out:Return %": 13,
    "out:Return/kSEK/y": 689,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 262293,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 250448,
    "out:Balancing hydronic system cost": 128574.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 238687,
    "out:DH kr savings": 238687,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 472548,
    "out:% savings (space heating)": 28.25,
    "out:Total_Water Heating (DH)": 152130,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 91.88,
    "out:Etvv": 17,
    "out:Ef": 15.9,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z02000100110000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 95.97,
    "out:DHW (DH)": 34.8,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 10.73,
    "out:Total Energy Use Pre PV": 146.9,
    "out:Total Energy Use Post PV": 141.73,
    "out:Primary Energy": 100.49,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 25.34,
    "out:CO2 Operational/m2": 18.26,
    "out:Total CO2/m2": 43.6,
    "out:Total CO2 (tons)": 224.22,
    "out:Klimatpaverkan": -105.02,
    "out:Initial Cost/MSEK": 6.0155,
    "out:Running cost/(Apt SEK y)": 43455,
    "out:Running Cost over RSP/MSEK": 14.288,
    "out:LCP/MSEK": -1.035,
    "out:ROI% old": 11.83,
    "out:Payback discounted": 10,
    "out:Atemp": 5143,
    "out:Umean": 0.34,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "F",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 142,
    "out:Energy use kWh/m2": 142,
    "out:Energy savings %": 32.4,
    "out:Op energy cost/MSEK": 105,
    "out:El price/MSEK": 62,
    "out:Heating price/MSEK": 38,
    "out:DH+ DHW/kWh/m2": 131,
    "out:El bought/kWh/m2": 758.1,
    "out:Return %": 11,
    "out:Return/kSEK/y": 639,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 61.265983,
    "out:PV (m2 panels)": 321,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 57484.06,
    "out:PV (kWh sold)": 30886.05,
    "out:PV (% sold (El))": 53.73,
    "out:PV (kWh self-consumed)": 26598,
    "out:PV (ratio sold/self-consumed)": 1.16,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 210863,
    "out:EL kWh savings": 26598,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 276482,
    "out:DH kr savings": 191885,
    "out:El kr savings": 45217,
    "out:El kr sold": 39380,
    "out:El kr saved": 45217,
    "out:El kr return": 84596,
    "out:% solar/total": 33,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 493581,
    "out:% savings (space heating)": 25.05,
    "out:Total_Water Heating (DH)": 178976,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 95.97,
    "out:Etvv": 20,
    "out:Ef": 10.73,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z02000100110000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 91.88,
    "out:DHW (DH)": 34.8,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 10.73,
    "out:Total Energy Use Pre PV": 142.9,
    "out:Total Energy Use Post PV": 137.73,
    "out:Primary Energy": 97.63,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 25.34,
    "out:CO2 Operational/m2": 16.82,
    "out:Total CO2/m2": 42.16,
    "out:Total CO2 (tons)": 216.81,
    "out:Klimatpaverkan": -112.43,
    "out:Initial Cost/MSEK": 6.144125,
    "out:Running cost/(Apt SEK y)": 42168,
    "out:Running Cost over RSP/MSEK": 13.865,
    "out:LCP/MSEK": -0.74,
    "out:ROI% old": 12.56,
    "out:Payback discounted": 10,
    "out:Atemp": 5143,
    "out:Umean": 0.34,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "F",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 138,
    "out:Energy use kWh/m2": 138,
    "out:Energy savings %": 36.2,
    "out:Op energy cost/MSEK": 102,
    "out:El price/MSEK": 60,
    "out:Heating price/MSEK": 36,
    "out:DH+ DHW/kWh/m2": 127,
    "out:El bought/kWh/m2": 758.1,
    "out:Return %": 11,
    "out:Return/kSEK/y": 693,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 61.265983,
    "out:PV (m2 panels)": 321,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 57484.06,
    "out:PV (kWh sold)": 30886.05,
    "out:PV (% sold (El))": 53.73,
    "out:PV (kWh self-consumed)": 26598,
    "out:PV (ratio sold/self-consumed)": 1.16,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 231435,
    "out:EL kWh savings": 26598,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 128574.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 295202,
    "out:DH kr savings": 210606,
    "out:El kr savings": 45217,
    "out:El kr sold": 39380,
    "out:El kr saved": 45217,
    "out:El kr return": 84596,
    "out:% solar/total": 33,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 472548,
    "out:% savings (space heating)": 28.25,
    "out:Total_Water Heating (DH)": 178976,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 91.88,
    "out:Etvv": 20,
    "out:Ef": 10.73,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z02000100110001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 95.97,
    "out:DHW (DH)": 29.58,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 10.73,
    "out:Total Energy Use Pre PV": 141.9,
    "out:Total Energy Use Post PV": 136.73,
    "out:Primary Energy": 98.39,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 25.74,
    "out:CO2 Operational/m2": 16.46,
    "out:Total CO2/m2": 42.2,
    "out:Total CO2 (tons)": 217.03,
    "out:Klimatpaverkan": -112.21,
    "out:Initial Cost/MSEK": 6.266,
    "out:Running cost/(Apt SEK y)": 41846,
    "out:Running Cost over RSP/MSEK": 13.759,
    "out:LCP/MSEK": -0.756,
    "out:ROI% old": 12.56,
    "out:Payback discounted": 10,
    "out:Atemp": 5143,
    "out:Umean": 0.34,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "F",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 137,
    "out:Energy use kWh/m2": 137,
    "out:Energy savings %": 37.2,
    "out:Op energy cost/MSEK": 102,
    "out:El price/MSEK": 59,
    "out:Heating price/MSEK": 36,
    "out:DH+ DHW/kWh/m2": 126,
    "out:El bought/kWh/m2": 758.1,
    "out:Return %": 11,
    "out:Return/kSEK/y": 706,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 61.265983,
    "out:PV (m2 panels)": 321,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 57484.06,
    "out:PV (kWh sold)": 30886.05,
    "out:PV (% sold (El))": 53.73,
    "out:PV (kWh self-consumed)": 26598,
    "out:PV (ratio sold/self-consumed)": 1.16,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 236578,
    "out:EL kWh savings": 26598,
    "out:Sewage heat recovery cost": 250448,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 299882,
    "out:DH kr savings": 215286,
    "out:El kr savings": 45217,
    "out:El kr sold": 39380,
    "out:El kr saved": 45217,
    "out:El kr return": 84596,
    "out:% solar/total": 33,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 493581,
    "out:% savings (space heating)": 25.05,
    "out:Total_Water Heating (DH)": 152130,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 95.97,
    "out:Etvv": 17,
    "out:Ef": 10.73,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z02000100110001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 91.88,
    "out:DHW (DH)": 29.58,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 10.73,
    "out:Total Energy Use Pre PV": 136.9,
    "out:Total Energy Use Post PV": 131.73,
    "out:Primary Energy": 95.53,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 25.74,
    "out:CO2 Operational/m2": 14.66,
    "out:Total CO2/m2": 40.4,
    "out:Total CO2 (tons)": 207.77,
    "out:Klimatpaverkan": -121.47,
    "out:Initial Cost/MSEK": 6.3945,
    "out:Running cost/(Apt SEK y)": 40237,
    "out:Running Cost over RSP/MSEK": 13.23,
    "out:LCP/MSEK": -0.356,
    "out:ROI% old": 13.49,
    "out:Payback discounted": 9,
    "out:Atemp": 5143,
    "out:Umean": 0.34,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "F",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 132,
    "out:Energy use kWh/m2": 132,
    "out:Energy savings %": 42.4,
    "out:Op energy cost/MSEK": 98,
    "out:El price/MSEK": 57,
    "out:Heating price/MSEK": 35,
    "out:DH+ DHW/kWh/m2": 121,
    "out:El bought/kWh/m2": 758.1,
    "out:Return %": 12,
    "out:Return/kSEK/y": 774,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 61.265983,
    "out:PV (m2 panels)": 321,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 57484.06,
    "out:PV (kWh sold)": 30886.05,
    "out:PV (% sold (El))": 53.73,
    "out:PV (kWh self-consumed)": 26598,
    "out:PV (ratio sold/self-consumed)": 1.16,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 262293,
    "out:EL kWh savings": 26598,
    "out:Sewage heat recovery cost": 250448,
    "out:Balancing hydronic system cost": 128574.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 323283,
    "out:DH kr savings": 238687,
    "out:El kr savings": 45217,
    "out:El kr sold": 39380,
    "out:El kr saved": 45217,
    "out:El kr return": 84596,
    "out:% solar/total": 33,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 472548,
    "out:% savings (space heating)": 28.25,
    "out:Total_Water Heating (DH)": 152130,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 91.88,
    "out:Etvv": 17,
    "out:Ef": 10.73,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z02000100120000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 95.97,
    "out:DHW (DH)": 34.8,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 9.88,
    "out:Total Energy Use Pre PV": 146.9,
    "out:Total Energy Use Post PV": 140.88,
    "out:Primary Energy": 98.96,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 37.69,
    "out:CO2 Operational/m2": -34.42,
    "out:Total CO2/m2": 3.27,
    "out:Total CO2 (tons)": 16.82,
    "out:Klimatpaverkan": -312.42,
    "out:Initial Cost/MSEK": 7.08525,
    "out:Running cost/(Apt SEK y)": 41666,
    "out:Running Cost over RSP/MSEK": 13.717,
    "out:LCP/MSEK": -1.533,
    "out:ROI% old": 11.19,
    "out:Payback discounted": 11,
    "out:Atemp": 5143,
    "out:Umean": 0.34,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "F",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 141,
    "out:Energy use kWh/m2": 141,
    "out:Energy savings %": 33.3,
    "out:Op energy cost/MSEK": 102,
    "out:El price/MSEK": 58,
    "out:Heating price/MSEK": 38,
    "out:DH+ DHW/kWh/m2": 131,
    "out:El bought/kWh/m2": 696.7,
    "out:Return %": 10,
    "out:Return/kSEK/y": 714,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.33,
    "out:PV (kWp installed)": 122.531965,
    "out:PV (m2 panels)": 643,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 114968.11,
    "out:PV (kWh sold)": 84004.99,
    "out:PV (% sold (El))": 73.07,
    "out:PV (kWh self-consumed)": 30963.12,
    "out:PV (ratio sold/self-consumed)": 2.71,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 210863,
    "out:EL kWh savings": 30963,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 351629,
    "out:DH kr savings": 191885,
    "out:El kr savings": 52637,
    "out:El kr sold": 107106,
    "out:El kr saved": 52637,
    "out:El kr return": 159744,
    "out:% solar/total": 38,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 493581,
    "out:% savings (space heating)": 25.05,
    "out:Total_Water Heating (DH)": 178976,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 95.97,
    "out:Etvv": 20,
    "out:Ef": 9.88,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z02000100120000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 91.88,
    "out:DHW (DH)": 34.8,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 9.88,
    "out:Total Energy Use Pre PV": 142.9,
    "out:Total Energy Use Post PV": 136.88,
    "out:Primary Energy": 96.1,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 37.69,
    "out:CO2 Operational/m2": -35.86,
    "out:Total CO2/m2": 1.83,
    "out:Total CO2 (tons)": 9.42,
    "out:Klimatpaverkan": -319.82,
    "out:Initial Cost/MSEK": 7.213875,
    "out:Running cost/(Apt SEK y)": 40379,
    "out:Running Cost over RSP/MSEK": 13.294,
    "out:LCP/MSEK": -1.238,
    "out:ROI% old": 11.83,
    "out:Payback discounted": 10,
    "out:Atemp": 5143,
    "out:Umean": 0.34,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "F",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 137,
    "out:Energy use kWh/m2": 137,
    "out:Energy savings %": 37.2,
    "out:Op energy cost/MSEK": 99,
    "out:El price/MSEK": 56,
    "out:Heating price/MSEK": 36,
    "out:DH+ DHW/kWh/m2": 127,
    "out:El bought/kWh/m2": 696.7,
    "out:Return %": 11,
    "out:Return/kSEK/y": 768,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.33,
    "out:PV (kWp installed)": 122.531965,
    "out:PV (m2 panels)": 643,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 114968.11,
    "out:PV (kWh sold)": 84004.99,
    "out:PV (% sold (El))": 73.07,
    "out:PV (kWh self-consumed)": 30963.12,
    "out:PV (ratio sold/self-consumed)": 2.71,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 231435,
    "out:EL kWh savings": 30963,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 128574.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 370350,
    "out:DH kr savings": 210606,
    "out:El kr savings": 52637,
    "out:El kr sold": 107106,
    "out:El kr saved": 52637,
    "out:El kr return": 159744,
    "out:% solar/total": 38,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 472548,
    "out:% savings (space heating)": 28.25,
    "out:Total_Water Heating (DH)": 178976,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 91.88,
    "out:Etvv": 20,
    "out:Ef": 9.88,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z02000100120001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 95.97,
    "out:DHW (DH)": 29.58,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 9.88,
    "out:Total Energy Use Pre PV": 141.9,
    "out:Total Energy Use Post PV": 135.88,
    "out:Primary Energy": 96.86,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 38.09,
    "out:CO2 Operational/m2": -36.22,
    "out:Total CO2/m2": 1.87,
    "out:Total CO2 (tons)": 9.63,
    "out:Klimatpaverkan": -319.61,
    "out:Initial Cost/MSEK": 7.33575,
    "out:Running cost/(Apt SEK y)": 40057,
    "out:Running Cost over RSP/MSEK": 13.188,
    "out:LCP/MSEK": -1.255,
    "out:ROI% old": 11.84,
    "out:Payback discounted": 10,
    "out:Atemp": 5143,
    "out:Umean": 0.34,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "F",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 136,
    "out:Energy use kWh/m2": 136,
    "out:Energy savings %": 38.2,
    "out:Op energy cost/MSEK": 98,
    "out:El price/MSEK": 56,
    "out:Heating price/MSEK": 36,
    "out:DH+ DHW/kWh/m2": 126,
    "out:El bought/kWh/m2": 696.7,
    "out:Return %": 11,
    "out:Return/kSEK/y": 782,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.33,
    "out:PV (kWp installed)": 122.531965,
    "out:PV (m2 panels)": 643,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 114968.11,
    "out:PV (kWh sold)": 84004.99,
    "out:PV (% sold (El))": 73.07,
    "out:PV (kWh self-consumed)": 30963.12,
    "out:PV (ratio sold/self-consumed)": 2.71,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 236578,
    "out:EL kWh savings": 30963,
    "out:Sewage heat recovery cost": 250448,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 375030,
    "out:DH kr savings": 215286,
    "out:El kr savings": 52637,
    "out:El kr sold": 107106,
    "out:El kr saved": 52637,
    "out:El kr return": 159744,
    "out:% solar/total": 38,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 493581,
    "out:% savings (space heating)": 25.05,
    "out:Total_Water Heating (DH)": 152130,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 95.97,
    "out:Etvv": 17,
    "out:Ef": 9.88,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z02000100120001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 91.88,
    "out:DHW (DH)": 29.58,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 9.88,
    "out:Total Energy Use Pre PV": 136.9,
    "out:Total Energy Use Post PV": 130.88,
    "out:Primary Energy": 94,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 38.09,
    "out:CO2 Operational/m2": -38.02,
    "out:Total CO2/m2": 0.07,
    "out:Total CO2 (tons)": 0.38,
    "out:Klimatpaverkan": -328.86,
    "out:Initial Cost/MSEK": 7.464375,
    "out:Running cost/(Apt SEK y)": 38448,
    "out:Running Cost over RSP/MSEK": 12.659,
    "out:LCP/MSEK": -0.854,
    "out:ROI% old": 12.65,
    "out:Payback discounted": 10,
    "out:Atemp": 5143,
    "out:Umean": 0.34,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "F",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 131,
    "out:Energy use kWh/m2": 131,
    "out:Energy savings %": 43.5,
    "out:Op energy cost/MSEK": 94,
    "out:El price/MSEK": 54,
    "out:Heating price/MSEK": 35,
    "out:DH+ DHW/kWh/m2": 121,
    "out:El bought/kWh/m2": 696.7,
    "out:Return %": 11,
    "out:Return/kSEK/y": 849,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.33,
    "out:PV (kWp installed)": 122.531965,
    "out:PV (m2 panels)": 643,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 114968.11,
    "out:PV (kWh sold)": 84004.99,
    "out:PV (% sold (El))": 73.07,
    "out:PV (kWh self-consumed)": 30963.12,
    "out:PV (ratio sold/self-consumed)": 2.71,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 262293,
    "out:EL kWh savings": 30963,
    "out:Sewage heat recovery cost": 250448,
    "out:Balancing hydronic system cost": 128574.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 398430,
    "out:DH kr savings": 238687,
    "out:El kr savings": 52637,
    "out:El kr sold": 107106,
    "out:El kr saved": 52637,
    "out:El kr return": 159744,
    "out:% solar/total": 38,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 472548,
    "out:% savings (space heating)": 28.25,
    "out:Total_Water Heating (DH)": 152130,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 91.88,
    "out:Etvv": 17,
    "out:Ef": 9.88,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z02000101000000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 51.01,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 45.01,
    "out:Electricity (inc PV)": 42.72,
    "out:Total Energy Use Pre PV": 96.01,
    "out:Total Energy Use Post PV": 93.72,
    "out:Primary Energy": 104.94,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 15.83,
    "out:CO2 Operational/m2": 24.3,
    "out:Total CO2/m2": 40.13,
    "out:Total CO2 (tons)": 206.41,
    "out:Klimatpaverkan": -122.83,
    "out:Initial Cost/MSEK": 9.31075,
    "out:Running cost/(Apt SEK y)": 25782,
    "out:Running Cost over RSP/MSEK": 8.437,
    "out:LCP/MSEK": 1.521,
    "out:ROI% old": 16.62,
    "out:Payback discounted": 7,
    "out:Atemp": 5143,
    "out:Umean": 0.34,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 94,
    "out:Energy use kWh/m2": 94,
    "out:Energy savings %": 100,
    "out:Op energy cost/MSEK": 60,
    "out:El price/MSEK": 43,
    "out:Heating price/MSEK": 15,
    "out:DH+ DHW/kWh/m2": 51,
    "out:El bought/kWh/m2": 3150.5,
    "out:Return %": 15,
    "out:Return/kSEK/y": 1381,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 1479000,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 73950,
    "out:EAHP (replacement cost)": 1105400,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 26.81,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 622303,
    "out:EL kWh savings": -137918,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 331836,
    "out:DH kr savings": 566296,
    "out:El kr savings": -234460,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 262319,
    "out:% savings (space heating)": 60.17,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 40.07,
    "out:Etvv": 0,
    "out:Ef": 42.72,
    "out:heatPumpQuantity": 10,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000101000000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 47.21,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 44.92,
    "out:Electricity (inc PV)": 42.58,
    "out:Total Energy Use Pre PV": 91.92,
    "out:Total Energy Use Post PV": 89.58,
    "out:Primary Energy": 102.14,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 15.83,
    "out:CO2 Operational/m2": 22.85,
    "out:Total CO2/m2": 38.68,
    "out:Total CO2 (tons)": 198.94,
    "out:Klimatpaverkan": -130.3,
    "out:Initial Cost/MSEK": 9.439375,
    "out:Running cost/(Apt SEK y)": 24477,
    "out:Running Cost over RSP/MSEK": 8.008,
    "out:LCP/MSEK": 1.822,
    "out:ROI% old": 17.04,
    "out:Payback discounted": 7,
    "out:Atemp": 5143,
    "out:Umean": 0.34,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 90,
    "out:Energy use kWh/m2": 90,
    "out:Energy savings %": 108.9,
    "out:Op energy cost/MSEK": 57,
    "out:El price/MSEK": 41,
    "out:Heating price/MSEK": 13,
    "out:DH+ DHW/kWh/m2": 47,
    "out:El bought/kWh/m2": 3144.3,
    "out:Return %": 15,
    "out:Return/kSEK/y": 1436,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 1479000,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 73950,
    "out:EAHP (replacement cost)": 1105400,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 26.72,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 642875,
    "out:EL kWh savings": -137198,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 128574.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 351779,
    "out:DH kr savings": 585016,
    "out:El kr savings": -233237,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 242801,
    "out:% savings (space heating)": 63.13,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 36.44,
    "out:Etvv": 0,
    "out:Ef": 42.58,
    "out:heatPumpQuantity": 10,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000101000001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 47.03,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 44.24,
    "out:Electricity (inc PV)": 42.21,
    "out:Total Energy Use Pre PV": 91.24,
    "out:Total Energy Use Post PV": 89.21,
    "out:Primary Energy": 102.57,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 16.24,
    "out:CO2 Operational/m2": 22.76,
    "out:Total CO2/m2": 38.99,
    "out:Total CO2 (tons)": 200.54,
    "out:Klimatpaverkan": -128.7,
    "out:Initial Cost/MSEK": 9.56125,
    "out:Running cost/(Apt SEK y)": 24335,
    "out:Running Cost over RSP/MSEK": 7.962,
    "out:LCP/MSEK": 1.746,
    "out:ROI% old": 16.89,
    "out:Payback discounted": 7,
    "out:Atemp": 5143,
    "out:Umean": 0.34,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 89,
    "out:Energy use kWh/m2": 89,
    "out:Energy savings %": 111.2,
    "out:Op energy cost/MSEK": 56,
    "out:El price/MSEK": 41,
    "out:Heating price/MSEK": 13,
    "out:DH+ DHW/kWh/m2": 47,
    "out:El bought/kWh/m2": 3096.5,
    "out:Return %": 15,
    "out:Return/kSEK/y": 1442,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 1479000,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 73950,
    "out:EAHP (replacement cost)": 1105400,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 26.04,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 642875,
    "out:EL kWh savings": -135333,
    "out:Sewage heat recovery cost": 250448,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 354951,
    "out:DH kr savings": 585016,
    "out:El kr savings": -230065,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 241854,
    "out:% savings (space heating)": 63.28,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 37.97,
    "out:Etvv": 0,
    "out:Ef": 42.21,
    "out:heatPumpQuantity": 10,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000101000001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 43.29,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 44.13,
    "out:Electricity (inc PV)": 42.06,
    "out:Total Energy Use Pre PV": 87.13,
    "out:Total Energy Use Post PV": 85.06,
    "out:Primary Energy": 99.78,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 16.24,
    "out:CO2 Operational/m2": 21.3,
    "out:Total CO2/m2": 37.54,
    "out:Total CO2 (tons)": 193.07,
    "out:Klimatpaverkan": -136.17,
    "out:Initial Cost/MSEK": 9.68975,
    "out:Running cost/(Apt SEK y)": 23025,
    "out:Running Cost over RSP/MSEK": 7.531,
    "out:LCP/MSEK": 2.048,
    "out:ROI% old": 17.3,
    "out:Payback discounted": 7,
    "out:Atemp": 5143,
    "out:Umean": 0.34,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 85,
    "out:Energy use kWh/m2": 85,
    "out:Energy savings %": 121.2,
    "out:Op energy cost/MSEK": 53,
    "out:El price/MSEK": 39,
    "out:Heating price/MSEK": 12,
    "out:DH+ DHW/kWh/m2": 43,
    "out:El bought/kWh/m2": 3089.1,
    "out:Return %": 15,
    "out:Return/kSEK/y": 1497,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 1479000,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 73950,
    "out:EAHP (replacement cost)": 1105400,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 25.93,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 663447,
    "out:EL kWh savings": -134550,
    "out:Sewage heat recovery cost": 250448,
    "out:Balancing hydronic system cost": 128574.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 375001,
    "out:DH kr savings": 603737,
    "out:El kr savings": -228736,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 222622,
    "out:% savings (space heating)": 66.2,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 34.39,
    "out:Etvv": 0,
    "out:Ef": 42.06,
    "out:heatPumpQuantity": 10,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000101010000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 51.01,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 45.01,
    "out:Electricity (inc PV)": 33.24,
    "out:Total Energy Use Pre PV": 96.01,
    "out:Total Energy Use Post PV": 84.24,
    "out:Primary Energy": 87.89,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 28.19,
    "out:CO2 Operational/m2": 15.83,
    "out:Total CO2/m2": 44.02,
    "out:Total CO2 (tons)": 226.39,
    "out:Klimatpaverkan": -102.85,
    "out:Initial Cost/MSEK": 10.380625,
    "out:Running cost/(Apt SEK y)": 23533,
    "out:Running Cost over RSP/MSEK": 7.718,
    "out:LCP/MSEK": 1.171,
    "out:ROI% old": 15.9,
    "out:Payback discounted": 8,
    "out:Atemp": 5143,
    "out:Umean": 0.34,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 84,
    "out:Energy use kWh/m2": 84,
    "out:Energy savings %": 123.8,
    "out:Op energy cost/MSEK": 56,
    "out:El price/MSEK": 38,
    "out:Heating price/MSEK": 15,
    "out:DH+ DHW/kWh/m2": 51,
    "out:El bought/kWh/m2": 2498.3,
    "out:Return %": 14,
    "out:Return/kSEK/y": 1476,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 1479000,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 73950,
    "out:EAHP (replacement cost)": 1105400,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 26.81,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 61.265983,
    "out:PV (m2 panels)": 321,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 57484.06,
    "out:PV (kWh sold)": 7610.28,
    "out:PV (% sold (El))": 13.24,
    "out:PV (kWh self-consumed)": 49873.78,
    "out:PV (ratio sold/self-consumed)": 0.15,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 622303,
    "out:EL kWh savings": -89203,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 424354,
    "out:DH kr savings": 566296,
    "out:El kr savings": -151644,
    "out:El kr sold": 9703,
    "out:El kr saved": 84785,
    "out:El kr return": 94489,
    "out:% solar/total": 61,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 262319,
    "out:% savings (space heating)": 60.17,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 40.07,
    "out:Etvv": 0,
    "out:Ef": 33.24,
    "out:heatPumpQuantity": 10,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000101010000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 47.21,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 44.92,
    "out:Electricity (inc PV)": 33.12,
    "out:Total Energy Use Pre PV": 91.92,
    "out:Total Energy Use Post PV": 80.12,
    "out:Primary Energy": 85.12,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 28.19,
    "out:CO2 Operational/m2": 14.34,
    "out:Total CO2/m2": 42.53,
    "out:Total CO2 (tons)": 218.71,
    "out:Klimatpaverkan": -110.53,
    "out:Initial Cost/MSEK": 10.509125,
    "out:Running cost/(Apt SEK y)": 22227,
    "out:Running Cost over RSP/MSEK": 7.289,
    "out:LCP/MSEK": 1.471,
    "out:ROI% old": 16.29,
    "out:Payback discounted": 7,
    "out:Atemp": 5143,
    "out:Umean": 0.34,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 80,
    "out:Energy use kWh/m2": 80,
    "out:Energy savings %": 135,
    "out:Op energy cost/MSEK": 52,
    "out:El price/MSEK": 37,
    "out:Heating price/MSEK": 13,
    "out:DH+ DHW/kWh/m2": 47,
    "out:El bought/kWh/m2": 2492.6,
    "out:Return %": 15,
    "out:Return/kSEK/y": 1530,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 1479000,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 73950,
    "out:EAHP (replacement cost)": 1105400,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 26.72,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 61.265983,
    "out:PV (m2 panels)": 321,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 57484.06,
    "out:PV (kWh sold)": 7653.3,
    "out:PV (% sold (El))": 13.31,
    "out:PV (kWh self-consumed)": 49830.76,
    "out:PV (ratio sold/self-consumed)": 0.15,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 642875,
    "out:EL kWh savings": -88557,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 128574.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 444228,
    "out:DH kr savings": 585016,
    "out:El kr savings": -150546,
    "out:El kr sold": 9758,
    "out:El kr saved": 84712,
    "out:El kr return": 94470,
    "out:% solar/total": 61,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 242801,
    "out:% savings (space heating)": 63.13,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 36.44,
    "out:Etvv": 0,
    "out:Ef": 33.12,
    "out:heatPumpQuantity": 10,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000101010001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 47.03,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 44.24,
    "out:Electricity (inc PV)": 32.79,
    "out:Total Energy Use Pre PV": 91.24,
    "out:Total Energy Use Post PV": 79.79,
    "out:Primary Energy": 85.61,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 28.59,
    "out:CO2 Operational/m2": 13.93,
    "out:Total CO2/m2": 42.52,
    "out:Total CO2 (tons)": 218.68,
    "out:Klimatpaverkan": -110.56,
    "out:Initial Cost/MSEK": 10.631,
    "out:Running cost/(Apt SEK y)": 22089,
    "out:Running Cost over RSP/MSEK": 7.244,
    "out:LCP/MSEK": 1.394,
    "out:ROI% old": 16.16,
    "out:Payback discounted": 8,
    "out:Atemp": 5143,
    "out:Umean": 0.34,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 80,
    "out:Energy use kWh/m2": 80,
    "out:Energy savings %": 135,
    "out:Op energy cost/MSEK": 52,
    "out:El price/MSEK": 36,
    "out:Heating price/MSEK": 13,
    "out:DH+ DHW/kWh/m2": 47,
    "out:El bought/kWh/m2": 2448.8,
    "out:Return %": 14,
    "out:Return/kSEK/y": 1536,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 1479000,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 73950,
    "out:EAHP (replacement cost)": 1105400,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 26.04,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 61.265983,
    "out:PV (m2 panels)": 321,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 57484.06,
    "out:PV (kWh sold)": 7988.02,
    "out:PV (% sold (El))": 13.9,
    "out:PV (kWh self-consumed)": 49496.04,
    "out:PV (ratio sold/self-consumed)": 0.16,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 642875,
    "out:EL kWh savings": -86884,
    "out:Sewage heat recovery cost": 250448,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 447499,
    "out:DH kr savings": 585016,
    "out:El kr savings": -147702,
    "out:El kr sold": 10185,
    "out:El kr saved": 84143,
    "out:El kr return": 94328,
    "out:% solar/total": 61,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 241854,
    "out:% savings (space heating)": 63.28,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 37.97,
    "out:Etvv": 0,
    "out:Ef": 32.79,
    "out:heatPumpQuantity": 10,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000101010001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 43.29,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 44.13,
    "out:Electricity (inc PV)": 32.66,
    "out:Total Energy Use Pre PV": 87.13,
    "out:Total Energy Use Post PV": 75.66,
    "out:Primary Energy": 82.85,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 28.59,
    "out:CO2 Operational/m2": 12.43,
    "out:Total CO2/m2": 41.02,
    "out:Total CO2 (tons)": 210.94,
    "out:Klimatpaverkan": -118.3,
    "out:Initial Cost/MSEK": 10.759625,
    "out:Running cost/(Apt SEK y)": 20780,
    "out:Running Cost over RSP/MSEK": 6.814,
    "out:LCP/MSEK": 1.696,
    "out:ROI% old": 16.54,
    "out:Payback discounted": 7,
    "out:Atemp": 5143,
    "out:Umean": 0.34,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 76,
    "out:Energy use kWh/m2": 76,
    "out:Energy savings %": 147.4,
    "out:Op energy cost/MSEK": 49,
    "out:El price/MSEK": 35,
    "out:Heating price/MSEK": 12,
    "out:DH+ DHW/kWh/m2": 43,
    "out:El bought/kWh/m2": 2442,
    "out:Return %": 15,
    "out:Return/kSEK/y": 1591,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 1479000,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 73950,
    "out:EAHP (replacement cost)": 1105400,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 25.93,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 61.265983,
    "out:PV (m2 panels)": 321,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 57484.06,
    "out:PV (kWh sold)": 8041.2,
    "out:PV (% sold (El))": 13.99,
    "out:PV (kWh self-consumed)": 49442.86,
    "out:PV (ratio sold/self-consumed)": 0.16,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 663447,
    "out:EL kWh savings": -86183,
    "out:Sewage heat recovery cost": 250448,
    "out:Balancing hydronic system cost": 128574.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 467478,
    "out:DH kr savings": 603737,
    "out:El kr savings": -146511,
    "out:El kr sold": 10253,
    "out:El kr saved": 84053,
    "out:El kr return": 94305,
    "out:% solar/total": 60,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 222622,
    "out:% savings (space heating)": 66.2,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 34.39,
    "out:Etvv": 0,
    "out:Ef": 32.66,
    "out:heatPumpQuantity": 10,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000101020000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 51.01,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 45.01,
    "out:Electricity (inc PV)": 30.04,
    "out:Total Energy Use Pre PV": 96.01,
    "out:Total Energy Use Post PV": 81.04,
    "out:Primary Energy": 82.12,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 40.54,
    "out:CO2 Operational/m2": -23.82,
    "out:Total CO2/m2": 16.72,
    "out:Total CO2 (tons)": 86,
    "out:Klimatpaverkan": -243.24,
    "out:Initial Cost/MSEK": 11.450375,
    "out:Running cost/(Apt SEK y)": 21610,
    "out:Running Cost over RSP/MSEK": 7.103,
    "out:LCP/MSEK": 0.716,
    "out:ROI% old": 15.18,
    "out:Payback discounted": 8,
    "out:Atemp": 5143,
    "out:Umean": 0.34,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 81,
    "out:Energy use kWh/m2": 81,
    "out:Energy savings %": 132.1,
    "out:Op energy cost/MSEK": 52,
    "out:El price/MSEK": 35,
    "out:Heating price/MSEK": 15,
    "out:DH+ DHW/kWh/m2": 51,
    "out:El bought/kWh/m2": 2254.4,
    "out:Return %": 14,
    "out:Return/kSEK/y": 1556,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 1479000,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 73950,
    "out:EAHP (replacement cost)": 1105400,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 26.81,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.33,
    "out:PV (kWp installed)": 122.531965,
    "out:PV (m2 panels)": 643,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 114968.11,
    "out:PV (kWh sold)": 47509.97,
    "out:PV (% sold (El))": 41.32,
    "out:PV (kWh self-consumed)": 67458.14,
    "out:PV (ratio sold/self-consumed)": 0.7,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 622303,
    "out:EL kWh savings": -72729,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 503232,
    "out:DH kr savings": 566296,
    "out:El kr savings": -123639,
    "out:El kr sold": 60575,
    "out:El kr saved": 114679,
    "out:El kr return": 175254,
    "out:% solar/total": 82,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 262319,
    "out:% savings (space heating)": 60.17,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 40.07,
    "out:Etvv": 0,
    "out:Ef": 30.04,
    "out:heatPumpQuantity": 10,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000101020000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 47.21,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 44.92,
    "out:Electricity (inc PV)": 29.93,
    "out:Total Energy Use Pre PV": 91.92,
    "out:Total Energy Use Post PV": 76.93,
    "out:Primary Energy": 79.38,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 40.54,
    "out:CO2 Operational/m2": -25.35,
    "out:Total CO2/m2": 15.19,
    "out:Total CO2 (tons)": 78.1,
    "out:Klimatpaverkan": -251.14,
    "out:Initial Cost/MSEK": 11.578875,
    "out:Running cost/(Apt SEK y)": 20305,
    "out:Running Cost over RSP/MSEK": 6.674,
    "out:LCP/MSEK": 1.016,
    "out:ROI% old": 15.54,
    "out:Payback discounted": 8,
    "out:Atemp": 5143,
    "out:Umean": 0.34,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 77,
    "out:Energy use kWh/m2": 77,
    "out:Energy savings %": 144.2,
    "out:Op energy cost/MSEK": 49,
    "out:El price/MSEK": 33,
    "out:Heating price/MSEK": 13,
    "out:DH+ DHW/kWh/m2": 47,
    "out:El bought/kWh/m2": 2249.3,
    "out:Return %": 14,
    "out:Return/kSEK/y": 1611,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 1479000,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 73950,
    "out:EAHP (replacement cost)": 1105400,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 26.72,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.33,
    "out:PV (kWp installed)": 122.531965,
    "out:PV (m2 panels)": 643,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 114968.11,
    "out:PV (kWh sold)": 47597.16,
    "out:PV (% sold (El))": 41.4,
    "out:PV (kWh self-consumed)": 67370.95,
    "out:PV (ratio sold/self-consumed)": 0.71,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 642875,
    "out:EL kWh savings": -72150,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 128574.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 523047,
    "out:DH kr savings": 585016,
    "out:El kr savings": -122655,
    "out:El kr sold": 60686,
    "out:El kr saved": 114531,
    "out:El kr return": 175217,
    "out:% solar/total": 82,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 242801,
    "out:% savings (space heating)": 63.13,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 36.44,
    "out:Etvv": 0,
    "out:Ef": 29.93,
    "out:heatPumpQuantity": 10,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000101020001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 47.03,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 44.24,
    "out:Electricity (inc PV)": 29.64,
    "out:Total Energy Use Pre PV": 91.24,
    "out:Total Energy Use Post PV": 76.64,
    "out:Primary Energy": 79.93,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 40.94,
    "out:CO2 Operational/m2": -26.1,
    "out:Total CO2/m2": 14.85,
    "out:Total CO2 (tons)": 76.35,
    "out:Klimatpaverkan": -252.89,
    "out:Initial Cost/MSEK": 11.70075,
    "out:Running cost/(Apt SEK y)": 20169,
    "out:Running Cost over RSP/MSEK": 6.63,
    "out:LCP/MSEK": 0.938,
    "out:ROI% old": 15.43,
    "out:Payback discounted": 8,
    "out:Atemp": 5143,
    "out:Umean": 0.34,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 77,
    "out:Energy use kWh/m2": 77,
    "out:Energy savings %": 144.2,
    "out:Op energy cost/MSEK": 48,
    "out:El price/MSEK": 33,
    "out:Heating price/MSEK": 13,
    "out:DH+ DHW/kWh/m2": 47,
    "out:El bought/kWh/m2": 2210.3,
    "out:Return %": 14,
    "out:Return/kSEK/y": 1617,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 1479000,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 73950,
    "out:EAHP (replacement cost)": 1105400,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 26.04,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.33,
    "out:PV (kWp installed)": 122.531965,
    "out:PV (m2 panels)": 643,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 114968.11,
    "out:PV (kWh sold)": 48265.74,
    "out:PV (% sold (El))": 41.98,
    "out:PV (kWh self-consumed)": 66702.37,
    "out:PV (ratio sold/self-consumed)": 0.72,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 642875,
    "out:EL kWh savings": -70651,
    "out:Sewage heat recovery cost": 250448,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 526448,
    "out:DH kr savings": 585016,
    "out:El kr savings": -120107,
    "out:El kr sold": 61539,
    "out:El kr saved": 113394,
    "out:El kr return": 174933,
    "out:% solar/total": 82,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 241854,
    "out:% savings (space heating)": 63.28,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 37.97,
    "out:Etvv": 0,
    "out:Ef": 29.64,
    "out:heatPumpQuantity": 10,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000101020001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 43.29,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 44.13,
    "out:Electricity (inc PV)": 29.52,
    "out:Total Energy Use Pre PV": 87.13,
    "out:Total Energy Use Post PV": 72.52,
    "out:Primary Energy": 77.2,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 40.94,
    "out:CO2 Operational/m2": -27.65,
    "out:Total CO2/m2": 13.29,
    "out:Total CO2 (tons)": 68.35,
    "out:Klimatpaverkan": -260.89,
    "out:Initial Cost/MSEK": 11.829375,
    "out:Running cost/(Apt SEK y)": 18861,
    "out:Running Cost over RSP/MSEK": 6.2,
    "out:LCP/MSEK": 1.24,
    "out:ROI% old": 15.78,
    "out:Payback discounted": 8,
    "out:Atemp": 5143,
    "out:Umean": 0.34,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 73,
    "out:Energy use kWh/m2": 73,
    "out:Energy savings %": 157.5,
    "out:Op energy cost/MSEK": 45,
    "out:El price/MSEK": 31,
    "out:Heating price/MSEK": 12,
    "out:DH+ DHW/kWh/m2": 43,
    "out:El bought/kWh/m2": 2204.2,
    "out:Return %": 14,
    "out:Return/kSEK/y": 1672,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 1479000,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 73950,
    "out:EAHP (replacement cost)": 1105400,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 25.93,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.33,
    "out:PV (kWp installed)": 122.531965,
    "out:PV (m2 panels)": 643,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 114968.11,
    "out:PV (kWh sold)": 48370.31,
    "out:PV (% sold (El))": 42.07,
    "out:PV (kWh self-consumed)": 66597.8,
    "out:PV (ratio sold/self-consumed)": 0.73,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 663447,
    "out:EL kWh savings": -70023,
    "out:Sewage heat recovery cost": 250448,
    "out:Balancing hydronic system cost": 128574.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 546370,
    "out:DH kr savings": 603737,
    "out:El kr savings": -119039,
    "out:El kr sold": 61672,
    "out:El kr saved": 113216,
    "out:El kr return": 174888,
    "out:% solar/total": 81,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 222622,
    "out:% savings (space heating)": 66.2,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 34.39,
    "out:Etvv": 0,
    "out:Ef": 29.52,
    "out:heatPumpQuantity": 10,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000101100000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 45.73,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 43.97,
    "out:Electricity (inc PV)": 42.04,
    "out:Total Energy Use Pre PV": 89.97,
    "out:Total Energy Use Post PV": 88.04,
    "out:Primary Energy": 101.78,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 15.83,
    "out:CO2 Operational/m2": 22.36,
    "out:Total CO2/m2": 38.2,
    "out:Total CO2 (tons)": 196.44,
    "out:Klimatpaverkan": -132.8,
    "out:Initial Cost/MSEK": 9.421625,
    "out:Running cost/(Apt SEK y)": 23957,
    "out:Running Cost over RSP/MSEK": 7.838,
    "out:LCP/MSEK": 2.01,
    "out:ROI% old": 17.33,
    "out:Payback discounted": 7,
    "out:Atemp": 5143,
    "out:Umean": 0.34,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 88,
    "out:Energy use kWh/m2": 88,
    "out:Energy savings %": 113.6,
    "out:Op energy cost/MSEK": 56,
    "out:El price/MSEK": 40,
    "out:Heating price/MSEK": 13,
    "out:DH+ DHW/kWh/m2": 46,
    "out:El bought/kWh/m2": 3077.9,
    "out:Return %": 15,
    "out:Return/kSEK/y": 1458,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 1479000,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 73950,
    "out:EAHP (replacement cost)": 1105400,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 25.77,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 648018,
    "out:EL kWh savings": -134456,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 361121,
    "out:DH kr savings": 589696,
    "out:El kr savings": -228575,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 235185,
    "out:% savings (space heating)": 64.29,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 37.29,
    "out:Etvv": 0,
    "out:Ef": 42.04,
    "out:heatPumpQuantity": 10,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000101100000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 42.01,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 43.86,
    "out:Electricity (inc PV)": 41.89,
    "out:Total Energy Use Pre PV": 85.86,
    "out:Total Energy Use Post PV": 83.89,
    "out:Primary Energy": 99,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 15.83,
    "out:CO2 Operational/m2": 20.91,
    "out:Total CO2/m2": 36.74,
    "out:Total CO2 (tons)": 188.96,
    "out:Klimatpaverkan": -140.28,
    "out:Initial Cost/MSEK": 9.55025,
    "out:Running cost/(Apt SEK y)": 22647,
    "out:Running Cost over RSP/MSEK": 7.407,
    "out:LCP/MSEK": 2.312,
    "out:ROI% old": 17.74,
    "out:Payback discounted": 7,
    "out:Atemp": 5143,
    "out:Umean": 0.34,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 84,
    "out:Energy use kWh/m2": 84,
    "out:Energy savings %": 123.8,
    "out:Op energy cost/MSEK": 52,
    "out:El price/MSEK": 38,
    "out:Heating price/MSEK": 12,
    "out:DH+ DHW/kWh/m2": 42,
    "out:El bought/kWh/m2": 3070,
    "out:Return %": 16,
    "out:Return/kSEK/y": 1513,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 1479000,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 73950,
    "out:EAHP (replacement cost)": 1105400,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 25.66,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 668590,
    "out:EL kWh savings": -133653,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 128574.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 381207,
    "out:DH kr savings": 608417,
    "out:El kr savings": -227209,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 216056,
    "out:% savings (space heating)": 67.19,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 33.71,
    "out:Etvv": 0,
    "out:Ef": 41.89,
    "out:heatPumpQuantity": 10,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000101100001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 42.68,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 43.31,
    "out:Electricity (inc PV)": 41.63,
    "out:Total Energy Use Pre PV": 86.31,
    "out:Total Energy Use Post PV": 84.63,
    "out:Primary Energy": 99.89,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 16.24,
    "out:CO2 Operational/m2": 21.2,
    "out:Total CO2/m2": 37.43,
    "out:Total CO2 (tons)": 192.51,
    "out:Klimatpaverkan": -136.73,
    "out:Initial Cost/MSEK": 9.672125,
    "out:Running cost/(Apt SEK y)": 22855,
    "out:Running Cost over RSP/MSEK": 7.476,
    "out:LCP/MSEK": 2.121,
    "out:ROI% old": 17.42,
    "out:Payback discounted": 7,
    "out:Atemp": 5143,
    "out:Umean": 0.34,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 85,
    "out:Energy use kWh/m2": 85,
    "out:Energy savings %": 121.2,
    "out:Op energy cost/MSEK": 53,
    "out:El price/MSEK": 39,
    "out:Heating price/MSEK": 12,
    "out:DH+ DHW/kWh/m2": 43,
    "out:El bought/kWh/m2": 3031.9,
    "out:Return %": 16,
    "out:Return/kSEK/y": 1504,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 1479000,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 73950,
    "out:EAHP (replacement cost)": 1105400,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 25.11,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 663447,
    "out:EL kWh savings": -132322,
    "out:Sewage heat recovery cost": 250448,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 378790,
    "out:DH kr savings": 603737,
    "out:El kr savings": -224947,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 219494,
    "out:% savings (space heating)": 66.67,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 35.66,
    "out:Etvv": 0,
    "out:Ef": 41.63,
    "out:heatPumpQuantity": 10,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000101100001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 39.01,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 43.19,
    "out:Electricity (inc PV)": 41.46,
    "out:Total Energy Use Pre PV": 82.19,
    "out:Total Energy Use Post PV": 80.46,
    "out:Primary Energy": 97.11,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 16.24,
    "out:CO2 Operational/m2": 19.74,
    "out:Total CO2/m2": 35.98,
    "out:Total CO2 (tons)": 185.02,
    "out:Klimatpaverkan": -144.22,
    "out:Initial Cost/MSEK": 9.800625,
    "out:Running cost/(Apt SEK y)": 21541,
    "out:Running Cost over RSP/MSEK": 7.044,
    "out:LCP/MSEK": 2.424,
    "out:ROI% old": 17.82,
    "out:Payback discounted": 7,
    "out:Atemp": 5143,
    "out:Umean": 0.34,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 80,
    "out:Energy use kWh/m2": 80,
    "out:Energy savings %": 135,
    "out:Op energy cost/MSEK": 50,
    "out:El price/MSEK": 37,
    "out:Heating price/MSEK": 11,
    "out:DH+ DHW/kWh/m2": 39,
    "out:El bought/kWh/m2": 3023,
    "out:Return %": 16,
    "out:Return/kSEK/y": 1559,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 1479000,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 73950,
    "out:EAHP (replacement cost)": 1105400,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 24.99,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 684019,
    "out:EL kWh savings": -131466,
    "out:Sewage heat recovery cost": 250448,
    "out:Balancing hydronic system cost": 128574.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 398965,
    "out:DH kr savings": 622457,
    "out:El kr savings": -223492,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 200604,
    "out:% savings (space heating)": 69.54,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 32.12,
    "out:Etvv": 0,
    "out:Ef": 41.46,
    "out:heatPumpQuantity": 10,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000101110000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 45.73,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 43.97,
    "out:Electricity (inc PV)": 32.64,
    "out:Total Energy Use Pre PV": 89.97,
    "out:Total Energy Use Post PV": 78.64,
    "out:Primary Energy": 84.85,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 28.19,
    "out:CO2 Operational/m2": 13.41,
    "out:Total CO2/m2": 41.6,
    "out:Total CO2 (tons)": 213.93,
    "out:Klimatpaverkan": -115.31,
    "out:Initial Cost/MSEK": 10.491375,
    "out:Running cost/(Apt SEK y)": 21713,
    "out:Running Cost over RSP/MSEK": 7.12,
    "out:LCP/MSEK": 1.657,
    "out:ROI% old": 16.54,
    "out:Payback discounted": 7,
    "out:Atemp": 5143,
    "out:Umean": 0.34,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 79,
    "out:Energy use kWh/m2": 79,
    "out:Energy savings %": 138,
    "out:Op energy cost/MSEK": 51,
    "out:El price/MSEK": 36,
    "out:Heating price/MSEK": 13,
    "out:DH+ DHW/kWh/m2": 46,
    "out:El bought/kWh/m2": 2431.8,
    "out:Return %": 15,
    "out:Return/kSEK/y": 1552,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 1479000,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 73950,
    "out:EAHP (replacement cost)": 1105400,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 25.77,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 61.265983,
    "out:PV (m2 panels)": 321,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 57484.06,
    "out:PV (kWh sold)": 8122.01,
    "out:PV (% sold (El))": 14.13,
    "out:PV (kWh self-consumed)": 49362.05,
    "out:PV (ratio sold/self-consumed)": 0.16,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 648018,
    "out:EL kWh savings": -86098,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 453685,
    "out:DH kr savings": 589696,
    "out:El kr savings": -146367,
    "out:El kr sold": 10356,
    "out:El kr saved": 83915,
    "out:El kr return": 94271,
    "out:% solar/total": 60,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 235185,
    "out:% savings (space heating)": 64.29,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 37.29,
    "out:Etvv": 0,
    "out:Ef": 32.64,
    "out:heatPumpQuantity": 10,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000101110000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 42.01,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 43.86,
    "out:Electricity (inc PV)": 32.5,
    "out:Total Energy Use Pre PV": 85.86,
    "out:Total Energy Use Post PV": 74.5,
    "out:Primary Energy": 82.1,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 28.19,
    "out:CO2 Operational/m2": 11.9,
    "out:Total CO2/m2": 40.09,
    "out:Total CO2 (tons)": 206.17,
    "out:Klimatpaverkan": -123.07,
    "out:Initial Cost/MSEK": 10.62,
    "out:Running cost/(Apt SEK y)": 20403,
    "out:Running Cost over RSP/MSEK": 6.69,
    "out:LCP/MSEK": 1.959,
    "out:ROI% old": 16.92,
    "out:Payback discounted": 7,
    "out:Atemp": 5143,
    "out:Umean": 0.34,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 75,
    "out:Energy use kWh/m2": 75,
    "out:Energy savings %": 150.7,
    "out:Op energy cost/MSEK": 48,
    "out:El price/MSEK": 34,
    "out:Heating price/MSEK": 12,
    "out:DH+ DHW/kWh/m2": 42,
    "out:El bought/kWh/m2": 2424.6,
    "out:Return %": 15,
    "out:Return/kSEK/y": 1607,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 1479000,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 73950,
    "out:EAHP (replacement cost)": 1105400,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 25.66,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 61.265983,
    "out:PV (m2 panels)": 321,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 57484.06,
    "out:PV (kWh sold)": 8178.88,
    "out:PV (% sold (El))": 14.23,
    "out:PV (kWh self-consumed)": 49305.17,
    "out:PV (ratio sold/self-consumed)": 0.17,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 668590,
    "out:EL kWh savings": -85379,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 128574.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 473701,
    "out:DH kr savings": 608417,
    "out:El kr savings": -145144,
    "out:El kr sold": 10428,
    "out:El kr saved": 83819,
    "out:El kr return": 94247,
    "out:% solar/total": 60,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 216056,
    "out:% savings (space heating)": 67.19,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 33.71,
    "out:Etvv": 0,
    "out:Ef": 32.5,
    "out:heatPumpQuantity": 10,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000101110001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 42.68,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 43.31,
    "out:Electricity (inc PV)": 32.27,
    "out:Total Energy Use Pre PV": 86.31,
    "out:Total Energy Use Post PV": 75.27,
    "out:Primary Energy": 83.04,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 28.59,
    "out:CO2 Operational/m2": 11.92,
    "out:Total CO2/m2": 40.51,
    "out:Total CO2 (tons)": 208.35,
    "out:Klimatpaverkan": -120.89,
    "out:Initial Cost/MSEK": 10.741875,
    "out:Running cost/(Apt SEK y)": 20614,
    "out:Running Cost over RSP/MSEK": 6.76,
    "out:LCP/MSEK": 1.767,
    "out:ROI% old": 16.64,
    "out:Payback discounted": 7,
    "out:Atemp": 5143,
    "out:Umean": 0.34,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 75,
    "out:Energy use kWh/m2": 75,
    "out:Energy savings %": 150.7,
    "out:Op energy cost/MSEK": 49,
    "out:El price/MSEK": 34,
    "out:Heating price/MSEK": 12,
    "out:DH+ DHW/kWh/m2": 43,
    "out:El bought/kWh/m2": 2389.8,
    "out:Return %": 15,
    "out:Return/kSEK/y": 1598,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 1479000,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 73950,
    "out:EAHP (replacement cost)": 1105400,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 25.11,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 61.265983,
    "out:PV (m2 panels)": 321,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 57484.06,
    "out:PV (kWh sold)": 8458.42,
    "out:PV (% sold (El))": 14.71,
    "out:PV (kWh self-consumed)": 49025.64,
    "out:PV (ratio sold/self-consumed)": 0.17,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 663447,
    "out:EL kWh savings": -84188,
    "out:Sewage heat recovery cost": 250448,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 471401,
    "out:DH kr savings": 603737,
    "out:El kr savings": -143120,
    "out:El kr sold": 10784,
    "out:El kr saved": 83344,
    "out:El kr return": 94128,
    "out:% solar/total": 60,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 219494,
    "out:% savings (space heating)": 66.67,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 35.66,
    "out:Etvv": 0,
    "out:Ef": 32.27,
    "out:heatPumpQuantity": 10,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000101110001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 39.01,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 43.19,
    "out:Electricity (inc PV)": 32.12,
    "out:Total Energy Use Pre PV": 82.19,
    "out:Total Energy Use Post PV": 71.12,
    "out:Primary Energy": 80.3,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 28.59,
    "out:CO2 Operational/m2": 10.4,
    "out:Total CO2/m2": 38.99,
    "out:Total CO2 (tons)": 200.53,
    "out:Klimatpaverkan": -128.71,
    "out:Initial Cost/MSEK": 10.8705,
    "out:Running cost/(Apt SEK y)": 19301,
    "out:Running Cost over RSP/MSEK": 6.328,
    "out:LCP/MSEK": 2.07,
    "out:ROI% old": 17.01,
    "out:Payback discounted": 7,
    "out:Atemp": 5143,
    "out:Umean": 0.34,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 71,
    "out:Energy use kWh/m2": 71,
    "out:Energy savings %": 164.8,
    "out:Op energy cost/MSEK": 45,
    "out:El price/MSEK": 32,
    "out:Heating price/MSEK": 11,
    "out:DH+ DHW/kWh/m2": 39,
    "out:El bought/kWh/m2": 2381.7,
    "out:Return %": 15,
    "out:Return/kSEK/y": 1653,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 1479000,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 73950,
    "out:EAHP (replacement cost)": 1105400,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 24.99,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 61.265983,
    "out:PV (m2 panels)": 321,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 57484.06,
    "out:PV (kWh sold)": 8523.69,
    "out:PV (% sold (El))": 14.83,
    "out:PV (kWh self-consumed)": 48960.37,
    "out:PV (ratio sold/self-consumed)": 0.17,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 684019,
    "out:EL kWh savings": -83423,
    "out:Sewage heat recovery cost": 250448,
    "out:Balancing hydronic system cost": 128574.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 491505,
    "out:DH kr savings": 622457,
    "out:El kr savings": -141820,
    "out:El kr sold": 10868,
    "out:El kr saved": 83233,
    "out:El kr return": 94100,
    "out:% solar/total": 60,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 200604,
    "out:% savings (space heating)": 69.54,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 32.12,
    "out:Etvv": 0,
    "out:Ef": 32.12,
    "out:heatPumpQuantity": 10,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000101120000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 45.73,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 43.97,
    "out:Electricity (inc PV)": 29.5,
    "out:Total Energy Use Pre PV": 89.97,
    "out:Total Energy Use Post PV": 75.5,
    "out:Primary Energy": 79.2,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 40.54,
    "out:CO2 Operational/m2": -26.75,
    "out:Total CO2/m2": 13.79,
    "out:Total CO2 (tons)": 70.93,
    "out:Klimatpaverkan": -258.31,
    "out:Initial Cost/MSEK": 11.56125,
    "out:Running cost/(Apt SEK y)": 19795,
    "out:Running Cost over RSP/MSEK": 6.507,
    "out:LCP/MSEK": 1.201,
    "out:ROI% old": 15.77,
    "out:Payback discounted": 8,
    "out:Atemp": 5143,
    "out:Umean": 0.34,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 76,
    "out:Energy use kWh/m2": 76,
    "out:Energy savings %": 147.4,
    "out:Op energy cost/MSEK": 47,
    "out:El price/MSEK": 32,
    "out:Heating price/MSEK": 13,
    "out:DH+ DHW/kWh/m2": 46,
    "out:El bought/kWh/m2": 2195.1,
    "out:Return %": 14,
    "out:Return/kSEK/y": 1633,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 1479000,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 73950,
    "out:EAHP (replacement cost)": 1105400,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 25.77,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.33,
    "out:PV (kWp installed)": 122.531965,
    "out:PV (m2 panels)": 643,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 114968.11,
    "out:PV (kWh sold)": 48528.45,
    "out:PV (% sold (El))": 42.21,
    "out:PV (kWh self-consumed)": 66439.66,
    "out:PV (ratio sold/self-consumed)": 0.73,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 648018,
    "out:EL kWh savings": -69947,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 532660,
    "out:DH kr savings": 589696,
    "out:El kr savings": -118910,
    "out:El kr sold": 61874,
    "out:El kr saved": 112947,
    "out:El kr return": 174821,
    "out:% solar/total": 81,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 235185,
    "out:% savings (space heating)": 64.29,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 37.29,
    "out:Etvv": 0,
    "out:Ef": 29.5,
    "out:heatPumpQuantity": 10,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000101120000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 42.01,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 43.86,
    "out:Electricity (inc PV)": 29.38,
    "out:Total Energy Use Pre PV": 85.86,
    "out:Total Energy Use Post PV": 71.38,
    "out:Primary Energy": 76.47,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 40.54,
    "out:CO2 Operational/m2": -28.31,
    "out:Total CO2/m2": 12.23,
    "out:Total CO2 (tons)": 62.9,
    "out:Klimatpaverkan": -266.34,
    "out:Initial Cost/MSEK": 11.68975,
    "out:Running cost/(Apt SEK y)": 18485,
    "out:Running Cost over RSP/MSEK": 6.077,
    "out:LCP/MSEK": 1.503,
    "out:ROI% old": 16.12,
    "out:Payback discounted": 8,
    "out:Atemp": 5143,
    "out:Umean": 0.34,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 71,
    "out:Energy use kWh/m2": 71,
    "out:Energy savings %": 164.8,
    "out:Op energy cost/MSEK": 44,
    "out:El price/MSEK": 30,
    "out:Heating price/MSEK": 12,
    "out:DH+ DHW/kWh/m2": 42,
    "out:El bought/kWh/m2": 2188.7,
    "out:Return %": 14,
    "out:Return/kSEK/y": 1688,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 1479000,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 73950,
    "out:EAHP (replacement cost)": 1105400,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 25.66,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.33,
    "out:PV (kWp installed)": 122.531965,
    "out:PV (m2 panels)": 643,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 114968.11,
    "out:PV (kWh sold)": 48639.51,
    "out:PV (% sold (El))": 42.31,
    "out:PV (kWh self-consumed)": 66328.6,
    "out:PV (ratio sold/self-consumed)": 0.73,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 668590,
    "out:EL kWh savings": -69302,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 128574.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 552618,
    "out:DH kr savings": 608417,
    "out:El kr savings": -117814,
    "out:El kr sold": 62015,
    "out:El kr saved": 112759,
    "out:El kr return": 174774,
    "out:% solar/total": 81,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 216056,
    "out:% savings (space heating)": 67.19,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 33.71,
    "out:Etvv": 0,
    "out:Ef": 29.38,
    "out:heatPumpQuantity": 10,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000101120001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 42.68,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 43.31,
    "out:Electricity (inc PV)": 29.17,
    "out:Total Energy Use Pre PV": 86.31,
    "out:Total Energy Use Post PV": 72.17,
    "out:Primary Energy": 77.46,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 40.94,
    "out:CO2 Operational/m2": -28.55,
    "out:Total CO2/m2": 12.39,
    "out:Total CO2 (tons)": 63.73,
    "out:Klimatpaverkan": -265.51,
    "out:Initial Cost/MSEK": 11.811625,
    "out:Running cost/(Apt SEK y)": 18699,
    "out:Running Cost over RSP/MSEK": 6.147,
    "out:LCP/MSEK": 1.31,
    "out:ROI% old": 15.87,
    "out:Payback discounted": 8,
    "out:Atemp": 5143,
    "out:Umean": 0.34,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 72,
    "out:Energy use kWh/m2": 72,
    "out:Energy savings %": 161.1,
    "out:Op energy cost/MSEK": 45,
    "out:El price/MSEK": 30,
    "out:Heating price/MSEK": 12,
    "out:DH+ DHW/kWh/m2": 43,
    "out:El bought/kWh/m2": 2157.6,
    "out:Return %": 14,
    "out:Return/kSEK/y": 1679,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 1479000,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 73950,
    "out:EAHP (replacement cost)": 1105400,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 25.11,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.33,
    "out:PV (kWp installed)": 122.531965,
    "out:PV (m2 panels)": 643,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 114968.11,
    "out:PV (kWh sold)": 49181.59,
    "out:PV (% sold (El))": 42.78,
    "out:PV (kWh self-consumed)": 65786.53,
    "out:PV (ratio sold/self-consumed)": 0.75,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 663447,
    "out:EL kWh savings": -68235,
    "out:Sewage heat recovery cost": 250448,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 550443,
    "out:DH kr savings": 603737,
    "out:El kr savings": -116000,
    "out:El kr sold": 62707,
    "out:El kr saved": 111837,
    "out:El kr return": 174544,
    "out:% solar/total": 80,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 219494,
    "out:% savings (space heating)": 66.67,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 35.66,
    "out:Etvv": 0,
    "out:Ef": 29.17,
    "out:heatPumpQuantity": 10,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000101120001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 39.01,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 43.19,
    "out:Electricity (inc PV)": 29.03,
    "out:Total Energy Use Pre PV": 82.19,
    "out:Total Energy Use Post PV": 68.03,
    "out:Primary Energy": 74.74,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 40.94,
    "out:CO2 Operational/m2": -30.13,
    "out:Total CO2/m2": 10.81,
    "out:Total CO2 (tons)": 55.61,
    "out:Klimatpaverkan": -273.63,
    "out:Initial Cost/MSEK": 11.94025,
    "out:Running cost/(Apt SEK y)": 17387,
    "out:Running Cost over RSP/MSEK": 5.716,
    "out:LCP/MSEK": 1.613,
    "out:ROI% old": 16.22,
    "out:Payback discounted": 8,
    "out:Atemp": 5143,
    "out:Umean": 0.34,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 68,
    "out:Energy use kWh/m2": 68,
    "out:Energy savings %": 176.5,
    "out:Op energy cost/MSEK": 42,
    "out:El price/MSEK": 29,
    "out:Heating price/MSEK": 11,
    "out:DH+ DHW/kWh/m2": 39,
    "out:El bought/kWh/m2": 2150.4,
    "out:Return %": 15,
    "out:Return/kSEK/y": 1734,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 1479000,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 73950,
    "out:EAHP (replacement cost)": 1105400,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 24.99,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.33,
    "out:PV (kWp installed)": 122.531965,
    "out:PV (m2 panels)": 643,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 114968.11,
    "out:PV (kWh sold)": 49307.74,
    "out:PV (% sold (El))": 42.89,
    "out:PV (kWh self-consumed)": 65660.37,
    "out:PV (ratio sold/self-consumed)": 0.75,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 684019,
    "out:EL kWh savings": -67550,
    "out:Sewage heat recovery cost": 250448,
    "out:Balancing hydronic system cost": 128574.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 570490,
    "out:DH kr savings": 622457,
    "out:El kr savings": -114834,
    "out:El kr sold": 62867,
    "out:El kr saved": 111623,
    "out:El kr return": 174490,
    "out:% solar/total": 80,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 200604,
    "out:% savings (space heating)": 69.54,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 32.12,
    "out:Etvv": 0,
    "out:Ef": 29.03,
    "out:heatPumpQuantity": 10,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000102000000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 6.36,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 58.54,
    "out:Electricity (inc PV)": 53.82,
    "out:Total Energy Use Pre PV": 64.54,
    "out:Total Energy Use Post PV": 59.82,
    "out:Primary Energy": 99.27,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 16.39,
    "out:CO2 Operational/m2": 9.89,
    "out:Total CO2/m2": 26.28,
    "out:Total CO2 (tons)": 135.16,
    "out:Klimatpaverkan": -194.08,
    "out:Initial Cost/MSEK": 11.96275,
    "out:Running cost/(Apt SEK y)": 14116,
    "out:Running Cost over RSP/MSEK": 4.591,
    "out:LCP/MSEK": 2.715,
    "out:ROI% old": 17.53,
    "out:Payback discounted": 7,
    "out:Atemp": 5143,
    "out:Umean": 0.34,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 60,
    "out:Energy use kWh/m2": 60,
    "out:Energy savings %": 213.3,
    "out:Op energy cost/MSEK": 31,
    "out:El price/MSEK": 29,
    "out:Heating price/MSEK": 2,
    "out:DH+ DHW/kWh/m2": 6,
    "out:El bought/kWh/m2": 4097.5,
    "out:Return %": 16,
    "out:Return/kSEK/y": 1871,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 2958000,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 147900,
    "out:EAHP (replacement cost)": 2204400,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 40.34,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 853738,
    "out:EL kWh savings": -195046,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 445323,
    "out:DH kr savings": 776902,
    "out:El kr savings": -331579,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 32726,
    "out:% savings (space heating)": 95.03,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 3.41,
    "out:Etvv": 0,
    "out:Ef": 53.82,
    "out:heatPumpQuantity": 20,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000102000000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 4.74,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 57.79,
    "out:Electricity (inc PV)": 52.88,
    "out:Total Energy Use Pre PV": 62.79,
    "out:Total Energy Use Post PV": 57.88,
    "out:Primary Energy": 96.89,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 16.39,
    "out:CO2 Operational/m2": 9.43,
    "out:Total CO2/m2": 25.82,
    "out:Total CO2 (tons)": 132.8,
    "out:Klimatpaverkan": -196.44,
    "out:Initial Cost/MSEK": 12.091375,
    "out:Running cost/(Apt SEK y)": 13639,
    "out:Running Cost over RSP/MSEK": 4.435,
    "out:LCP/MSEK": 2.743,
    "out:ROI% old": 17.53,
    "out:Payback discounted": 7,
    "out:Atemp": 5143,
    "out:Umean": 0.34,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 58,
    "out:Energy use kWh/m2": 58,
    "out:Energy savings %": 224.1,
    "out:Op energy cost/MSEK": 30,
    "out:El price/MSEK": 28,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 5,
    "out:El bought/kWh/m2": 4045.1,
    "out:Return %": 16,
    "out:Return/kSEK/y": 1891,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 2958000,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 147900,
    "out:EAHP (replacement cost)": 2204400,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 39.59,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 858881,
    "out:EL kWh savings": -190193,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 128574.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 458253,
    "out:DH kr savings": 781582,
    "out:El kr savings": -323329,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 24381,
    "out:% savings (space heating)": 96.3,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 2.43,
    "out:Etvv": 0,
    "out:Ef": 52.88,
    "out:heatPumpQuantity": 20,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000102000001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 5.11,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 56.94,
    "out:Electricity (inc PV)": 52.81,
    "out:Total Energy Use Pre PV": 61.94,
    "out:Total Energy Use Post PV": 57.81,
    "out:Primary Energy": 97.16,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 16.8,
    "out:CO2 Operational/m2": 9.32,
    "out:Total CO2/m2": 26.11,
    "out:Total CO2 (tons)": 134.29,
    "out:Klimatpaverkan": -194.95,
    "out:Initial Cost/MSEK": 12.21325,
    "out:Running cost/(Apt SEK y)": 13462,
    "out:Running Cost over RSP/MSEK": 4.377,
    "out:LCP/MSEK": 2.678,
    "out:ROI% old": 17.42,
    "out:Payback discounted": 7,
    "out:Atemp": 5143,
    "out:Umean": 0.34,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 58,
    "out:Energy use kWh/m2": 58,
    "out:Energy savings %": 224.1,
    "out:Op energy cost/MSEK": 29,
    "out:El price/MSEK": 28,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 5,
    "out:El bought/kWh/m2": 3985.6,
    "out:Return %": 16,
    "out:Return/kSEK/y": 1899,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 2958000,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 147900,
    "out:EAHP (replacement cost)": 2204400,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 38.74,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 858881,
    "out:EL kWh savings": -189848,
    "out:Sewage heat recovery cost": 250448,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 458840,
    "out:DH kr savings": 781582,
    "out:El kr savings": -322742,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 26285,
    "out:% savings (space heating)": 96.01,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 2.99,
    "out:Etvv": 0,
    "out:Ef": 52.81,
    "out:heatPumpQuantity": 20,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000102000001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 3.76,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 56.11,
    "out:Electricity (inc PV)": 51.85,
    "out:Total Energy Use Pre PV": 60.11,
    "out:Total Energy Use Post PV": 55.85,
    "out:Primary Energy": 94.79,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 16.8,
    "out:CO2 Operational/m2": 8.85,
    "out:Total CO2/m2": 25.64,
    "out:Total CO2 (tons)": 131.88,
    "out:Klimatpaverkan": -197.36,
    "out:Initial Cost/MSEK": 12.34175,
    "out:Running cost/(Apt SEK y)": 12967,
    "out:Running Cost over RSP/MSEK": 4.215,
    "out:LCP/MSEK": 2.712,
    "out:ROI% old": 17.42,
    "out:Payback discounted": 7,
    "out:Atemp": 5143,
    "out:Umean": 0.34,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 56,
    "out:Energy use kWh/m2": 56,
    "out:Energy savings %": 235.7,
    "out:Op energy cost/MSEK": 28,
    "out:El price/MSEK": 27,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 4,
    "out:El bought/kWh/m2": 3927.5,
    "out:Return %": 16,
    "out:Return/kSEK/y": 1919,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 2958000,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 147900,
    "out:EAHP (replacement cost)": 2204400,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 37.91,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 864024,
    "out:EL kWh savings": -184867,
    "out:Sewage heat recovery cost": 250448,
    "out:Balancing hydronic system cost": 128574.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 471987,
    "out:DH kr savings": 786262,
    "out:El kr savings": -314275,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 19353,
    "out:% savings (space heating)": 97.06,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 2.1,
    "out:Etvv": 0,
    "out:Ef": 51.85,
    "out:heatPumpQuantity": 20,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000102010000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 6.36,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 58.54,
    "out:Electricity (inc PV)": 43.44,
    "out:Total Energy Use Pre PV": 64.54,
    "out:Total Energy Use Post PV": 49.44,
    "out:Primary Energy": 80.58,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 28.75,
    "out:CO2 Operational/m2": 6.04,
    "out:Total CO2/m2": 34.79,
    "out:Total CO2 (tons)": 178.94,
    "out:Klimatpaverkan": -150.3,
    "out:Initial Cost/MSEK": 13.0325,
    "out:Running cost/(Apt SEK y)": 11817,
    "out:Running Cost over RSP/MSEK": 3.857,
    "out:LCP/MSEK": 2.38,
    "out:ROI% old": 16.89,
    "out:Payback discounted": 7,
    "out:Atemp": 5143,
    "out:Umean": 0.34,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 49,
    "out:Energy use kWh/m2": 49,
    "out:Energy savings %": 283.7,
    "out:Op energy cost/MSEK": 26,
    "out:El price/MSEK": 24,
    "out:Heating price/MSEK": 2,
    "out:DH+ DHW/kWh/m2": 6,
    "out:El bought/kWh/m2": 3389.1,
    "out:Return %": 15,
    "out:Return/kSEK/y": 1968,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 2958000,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 147900,
    "out:EAHP (replacement cost)": 2204400,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 40.34,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 61.265983,
    "out:PV (m2 panels)": 321,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 57484.06,
    "out:PV (kWh sold)": 2713.7,
    "out:PV (% sold (El))": 4.72,
    "out:PV (kWh self-consumed)": 54770.36,
    "out:PV (ratio sold/self-consumed)": 0.05,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 853738,
    "out:EL kWh savings": -141645,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 539566,
    "out:DH kr savings": 776902,
    "out:El kr savings": -240796,
    "out:El kr sold": 3460,
    "out:El kr saved": 93110,
    "out:El kr return": 96570,
    "out:% solar/total": 67,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 32726,
    "out:% savings (space heating)": 95.03,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 3.41,
    "out:Etvv": 0,
    "out:Ef": 43.44,
    "out:heatPumpQuantity": 20,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000102010000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 4.74,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 57.79,
    "out:Electricity (inc PV)": 42.56,
    "out:Total Energy Use Pre PV": 62.79,
    "out:Total Energy Use Post PV": 47.56,
    "out:Primary Energy": 78.31,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 28.75,
    "out:CO2 Operational/m2": 5.4,
    "out:Total CO2/m2": 34.15,
    "out:Total CO2 (tons)": 175.63,
    "out:Klimatpaverkan": -153.61,
    "out:Initial Cost/MSEK": 13.16125,
    "out:Running cost/(Apt SEK y)": 11341,
    "out:Running Cost over RSP/MSEK": 3.701,
    "out:LCP/MSEK": 2.407,
    "out:ROI% old": 16.9,
    "out:Payback discounted": 7,
    "out:Atemp": 5143,
    "out:Umean": 0.34,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 48,
    "out:Energy use kWh/m2": 48,
    "out:Energy savings %": 291.7,
    "out:Op energy cost/MSEK": 25,
    "out:El price/MSEK": 24,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 5,
    "out:El bought/kWh/m2": 3338.9,
    "out:Return %": 15,
    "out:Return/kSEK/y": 1988,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 2958000,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 147900,
    "out:EAHP (replacement cost)": 2204400,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 39.59,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 61.265983,
    "out:PV (m2 panels)": 321,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 57484.06,
    "out:PV (kWh sold)": 2908.86,
    "out:PV (% sold (El))": 5.06,
    "out:PV (kWh self-consumed)": 54575.19,
    "out:PV (ratio sold/self-consumed)": 0.05,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 858881,
    "out:EL kWh savings": -137107,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 128574.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 552208,
    "out:DH kr savings": 781582,
    "out:El kr savings": -233082,
    "out:El kr sold": 3709,
    "out:El kr saved": 92778,
    "out:El kr return": 96487,
    "out:% solar/total": 67,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 24381,
    "out:% savings (space heating)": 96.3,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 2.43,
    "out:Etvv": 0,
    "out:Ef": 42.56,
    "out:heatPumpQuantity": 20,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000102010001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 5.11,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 56.94,
    "out:Electricity (inc PV)": 42.5,
    "out:Total Energy Use Pre PV": 61.94,
    "out:Total Energy Use Post PV": 47.5,
    "out:Primary Energy": 78.59,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 29.15,
    "out:CO2 Operational/m2": 5.07,
    "out:Total CO2/m2": 34.22,
    "out:Total CO2 (tons)": 176.01,
    "out:Klimatpaverkan": -153.23,
    "out:Initial Cost/MSEK": 13.2825,
    "out:Running cost/(Apt SEK y)": 11167,
    "out:Running Cost over RSP/MSEK": 3.644,
    "out:LCP/MSEK": 2.342,
    "out:ROI% old": 16.81,
    "out:Payback discounted": 7,
    "out:Atemp": 5143,
    "out:Umean": 0.34,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 47,
    "out:Energy use kWh/m2": 47,
    "out:Energy savings %": 300,
    "out:Op energy cost/MSEK": 25,
    "out:El price/MSEK": 23,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 5,
    "out:El bought/kWh/m2": 3281.9,
    "out:Return %": 15,
    "out:Return/kSEK/y": 1995,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 2958000,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 147900,
    "out:EAHP (replacement cost)": 2204400,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 38.74,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 61.265983,
    "out:PV (m2 panels)": 321,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 57484.06,
    "out:PV (kWh sold)": 3141.1,
    "out:PV (% sold (El))": 5.46,
    "out:PV (kWh self-consumed)": 54342.96,
    "out:PV (ratio sold/self-consumed)": 0.06,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 858881,
    "out:EL kWh savings": -136785,
    "out:Sewage heat recovery cost": 250448,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 553053,
    "out:DH kr savings": 781582,
    "out:El kr savings": -232534,
    "out:El kr sold": 4005,
    "out:El kr saved": 92383,
    "out:El kr return": 96388,
    "out:% solar/total": 66,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 26285,
    "out:% savings (space heating)": 96.01,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 2.99,
    "out:Etvv": 0,
    "out:Ef": 42.5,
    "out:heatPumpQuantity": 20,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000102010001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 3.76,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 56.11,
    "out:Electricity (inc PV)": 41.59,
    "out:Total Energy Use Pre PV": 60.11,
    "out:Total Energy Use Post PV": 45.59,
    "out:Primary Energy": 76.34,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 29.15,
    "out:CO2 Operational/m2": 4.38,
    "out:Total CO2/m2": 33.53,
    "out:Total CO2 (tons)": 172.45,
    "out:Klimatpaverkan": -156.79,
    "out:Initial Cost/MSEK": 13.41125,
    "out:Running cost/(Apt SEK y)": 10674,
    "out:Running Cost over RSP/MSEK": 3.483,
    "out:LCP/MSEK": 2.375,
    "out:ROI% old": 16.82,
    "out:Payback discounted": 7,
    "out:Atemp": 5143,
    "out:Umean": 0.34,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 46,
    "out:Energy use kWh/m2": 46,
    "out:Energy savings %": 308.7,
    "out:Op energy cost/MSEK": 24,
    "out:El price/MSEK": 22,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 4,
    "out:El bought/kWh/m2": 3226.3,
    "out:Return %": 15,
    "out:Return/kSEK/y": 2016,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 2958000,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 147900,
    "out:EAHP (replacement cost)": 2204400,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 37.91,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 61.265983,
    "out:PV (m2 panels)": 321,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 57484.06,
    "out:PV (kWh sold)": 3377.88,
    "out:PV (% sold (El))": 5.88,
    "out:PV (kWh self-consumed)": 54106.17,
    "out:PV (ratio sold/self-consumed)": 0.06,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 864024,
    "out:EL kWh savings": -132145,
    "out:Sewage heat recovery cost": 250448,
    "out:Balancing hydronic system cost": 128574.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 565923,
    "out:DH kr savings": 786262,
    "out:El kr savings": -224646,
    "out:El kr sold": 4307,
    "out:El kr saved": 91980,
    "out:El kr return": 96287,
    "out:% solar/total": 66,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 19353,
    "out:% savings (space heating)": 97.06,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 2.1,
    "out:Etvv": 0,
    "out:Ef": 41.59,
    "out:heatPumpQuantity": 20,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000102020000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 6.36,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 58.54,
    "out:Electricity (inc PV)": 39.13,
    "out:Total Energy Use Pre PV": 64.54,
    "out:Total Energy Use Post PV": 45.13,
    "out:Primary Energy": 72.83,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 41.1,
    "out:CO2 Operational/m2": -26.52,
    "out:Total CO2/m2": 14.58,
    "out:Total CO2 (tons)": 74.99,
    "out:Klimatpaverkan": -254.25,
    "out:Initial Cost/MSEK": 14.1025,
    "out:Running cost/(Apt SEK y)": 9822,
    "out:Running Cost over RSP/MSEK": 3.218,
    "out:LCP/MSEK": 1.949,
    "out:ROI% old": 16.26,
    "out:Payback discounted": 8,
    "out:Atemp": 5143,
    "out:Umean": 0.34,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 45,
    "out:Energy use kWh/m2": 45,
    "out:Energy savings %": 317.8,
    "out:Op energy cost/MSEK": 22,
    "out:El price/MSEK": 20,
    "out:Heating price/MSEK": 2,
    "out:DH+ DHW/kWh/m2": 6,
    "out:El bought/kWh/m2": 3044.9,
    "out:Return %": 15,
    "out:Return/kSEK/y": 2051,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 2958000,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 147900,
    "out:EAHP (replacement cost)": 2204400,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 40.34,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.33,
    "out:PV (kWp installed)": 122.531965,
    "out:PV (m2 panels)": 643,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 114968.11,
    "out:PV (kWh sold)": 35454.97,
    "out:PV (% sold (El))": 30.84,
    "out:PV (kWh self-consumed)": 79513.14,
    "out:PV (ratio sold/self-consumed)": 0.45,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 853738,
    "out:EL kWh savings": -119494,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 618967,
    "out:DH kr savings": 776902,
    "out:El kr savings": -203139,
    "out:El kr sold": 45205,
    "out:El kr saved": 135172,
    "out:El kr return": 180377,
    "out:% solar/total": 97,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 32726,
    "out:% savings (space heating)": 95.03,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 3.41,
    "out:Etvv": 0,
    "out:Ef": 39.13,
    "out:heatPumpQuantity": 20,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000102020000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 4.74,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 57.79,
    "out:Electricity (inc PV)": 38.35,
    "out:Total Energy Use Pre PV": 62.79,
    "out:Total Energy Use Post PV": 43.35,
    "out:Primary Energy": 70.73,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 41.1,
    "out:CO2 Operational/m2": -27.58,
    "out:Total CO2/m2": 13.53,
    "out:Total CO2 (tons)": 69.56,
    "out:Klimatpaverkan": -259.68,
    "out:Initial Cost/MSEK": 14.23125,
    "out:Running cost/(Apt SEK y)": 9350,
    "out:Running Cost over RSP/MSEK": 3.064,
    "out:LCP/MSEK": 1.974,
    "out:ROI% old": 16.27,
    "out:Payback discounted": 8,
    "out:Atemp": 5143,
    "out:Umean": 0.34,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 43,
    "out:Energy use kWh/m2": 43,
    "out:Energy savings %": 337.2,
    "out:Op energy cost/MSEK": 21,
    "out:El price/MSEK": 20,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 5,
    "out:El bought/kWh/m2": 3000.5,
    "out:Return %": 15,
    "out:Return/kSEK/y": 2071,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 2958000,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 147900,
    "out:EAHP (replacement cost)": 2204400,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 39.59,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.33,
    "out:PV (kWp installed)": 122.531965,
    "out:PV (m2 panels)": 643,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 114968.11,
    "out:PV (kWh sold)": 36071.04,
    "out:PV (% sold (El))": 31.37,
    "out:PV (kWh self-consumed)": 78897.07,
    "out:PV (ratio sold/self-consumed)": 0.46,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 858881,
    "out:EL kWh savings": -115460,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 128574.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 631290,
    "out:DH kr savings": 781582,
    "out:El kr savings": -196283,
    "out:El kr sold": 45991,
    "out:El kr saved": 134125,
    "out:El kr return": 180116,
    "out:% solar/total": 96,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 24381,
    "out:% savings (space heating)": 96.3,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 2.43,
    "out:Etvv": 0,
    "out:Ef": 38.35,
    "out:heatPumpQuantity": 20,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000102020001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 5.11,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 56.94,
    "out:Electricity (inc PV)": 38.29,
    "out:Total Energy Use Pre PV": 61.94,
    "out:Total Energy Use Post PV": 43.29,
    "out:Primary Energy": 71.03,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 41.5,
    "out:CO2 Operational/m2": -28.37,
    "out:Total CO2/m2": 13.13,
    "out:Total CO2 (tons)": 67.52,
    "out:Klimatpaverkan": -261.72,
    "out:Initial Cost/MSEK": 14.3525,
    "out:Running cost/(Apt SEK y)": 9180,
    "out:Running Cost over RSP/MSEK": 3.008,
    "out:LCP/MSEK": 1.908,
    "out:ROI% old": 16.18,
    "out:Payback discounted": 8,
    "out:Atemp": 5143,
    "out:Umean": 0.34,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 43,
    "out:Energy use kWh/m2": 43,
    "out:Energy savings %": 337.2,
    "out:Op energy cost/MSEK": 21,
    "out:El price/MSEK": 19,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 5,
    "out:El bought/kWh/m2": 2950.1,
    "out:Return %": 14,
    "out:Return/kSEK/y": 2078,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 2958000,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 147900,
    "out:EAHP (replacement cost)": 2204400,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 38.74,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.33,
    "out:PV (kWp installed)": 122.531965,
    "out:PV (m2 panels)": 643,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 114968.11,
    "out:PV (kWh sold)": 36777.07,
    "out:PV (% sold (El))": 31.99,
    "out:PV (kWh self-consumed)": 78191.04,
    "out:PV (ratio sold/self-consumed)": 0.47,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 858881,
    "out:EL kWh savings": -115174,
    "out:Sewage heat recovery cost": 250448,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 632677,
    "out:DH kr savings": 781582,
    "out:El kr savings": -195795,
    "out:El kr sold": 46891,
    "out:El kr saved": 132925,
    "out:El kr return": 179816,
    "out:% solar/total": 96,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 26285,
    "out:% savings (space heating)": 96.01,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 2.99,
    "out:Etvv": 0,
    "out:Ef": 38.29,
    "out:heatPumpQuantity": 20,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000102020001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 3.76,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 56.11,
    "out:Electricity (inc PV)": 37.49,
    "out:Total Energy Use Pre PV": 60.11,
    "out:Total Energy Use Post PV": 41.49,
    "out:Primary Energy": 68.95,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 41.5,
    "out:CO2 Operational/m2": -29.52,
    "out:Total CO2/m2": 11.98,
    "out:Total CO2 (tons)": 61.62,
    "out:Klimatpaverkan": -267.62,
    "out:Initial Cost/MSEK": 14.48125,
    "out:Running cost/(Apt SEK y)": 8693,
    "out:Running Cost over RSP/MSEK": 2.849,
    "out:LCP/MSEK": 1.939,
    "out:ROI% old": 16.2,
    "out:Payback discounted": 8,
    "out:Atemp": 5143,
    "out:Umean": 0.34,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 41,
    "out:Energy use kWh/m2": 41,
    "out:Energy savings %": 358.5,
    "out:Op energy cost/MSEK": 20,
    "out:El price/MSEK": 18,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 4,
    "out:El bought/kWh/m2": 2900.9,
    "out:Return %": 14,
    "out:Return/kSEK/y": 2099,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 2958000,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 147900,
    "out:EAHP (replacement cost)": 2204400,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 37.91,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.33,
    "out:PV (kWp installed)": 122.531965,
    "out:PV (m2 panels)": 643,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 114968.11,
    "out:PV (kWh sold)": 37473.03,
    "out:PV (% sold (El))": 32.59,
    "out:PV (kWh self-consumed)": 77495.08,
    "out:PV (ratio sold/self-consumed)": 0.48,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 864024,
    "out:EL kWh savings": -111044,
    "out:Sewage heat recovery cost": 250448,
    "out:Balancing hydronic system cost": 128574.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 645265,
    "out:DH kr savings": 786262,
    "out:El kr savings": -188775,
    "out:El kr sold": 47778,
    "out:El kr saved": 131742,
    "out:El kr return": 179520,
    "out:% solar/total": 95,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 19353,
    "out:% savings (space heating)": 97.06,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 2.1,
    "out:Etvv": 0,
    "out:Ef": 37.49,
    "out:heatPumpQuantity": 20,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000102100000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 4.75,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 56.39,
    "out:Electricity (inc PV)": 52.47,
    "out:Total Energy Use Pre PV": 61.39,
    "out:Total Energy Use Post PV": 57.47,
    "out:Primary Energy": 96.46,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 16.39,
    "out:CO2 Operational/m2": 9.24,
    "out:Total CO2/m2": 25.64,
    "out:Total CO2 (tons)": 131.85,
    "out:Klimatpaverkan": -197.39,
    "out:Initial Cost/MSEK": 12.073625,
    "out:Running cost/(Apt SEK y)": 13347,
    "out:Running Cost over RSP/MSEK": 4.34,
    "out:LCP/MSEK": 2.855,
    "out:ROI% old": 17.66,
    "out:Payback discounted": 7,
    "out:Atemp": 5143,
    "out:Umean": 0.34,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 57,
    "out:Energy use kWh/m2": 57,
    "out:Energy savings %": 229.8,
    "out:Op energy cost/MSEK": 29,
    "out:El price/MSEK": 27,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 5,
    "out:El bought/kWh/m2": 3947.2,
    "out:Return %": 16,
    "out:Return/kSEK/y": 1903,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 2958000,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 147900,
    "out:EAHP (replacement cost)": 2204400,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 38.19,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 858881,
    "out:EL kWh savings": -188100,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 461811,
    "out:DH kr savings": 781582,
    "out:El kr savings": -319770,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 24425,
    "out:% savings (space heating)": 96.29,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 2.87,
    "out:Etvv": 0,
    "out:Ef": 52.47,
    "out:heatPumpQuantity": 20,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000102100000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 3.48,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 55.53,
    "out:Electricity (inc PV)": 51.5,
    "out:Total Energy Use Pre PV": 58.53,
    "out:Total Energy Use Post PV": 54.5,
    "out:Primary Energy": 94.09,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 16.39,
    "out:CO2 Operational/m2": 8.41,
    "out:Total CO2/m2": 24.8,
    "out:Total CO2 (tons)": 127.57,
    "out:Klimatpaverkan": -201.67,
    "out:Initial Cost/MSEK": 12.20225,
    "out:Running cost/(Apt SEK y)": 12526,
    "out:Running Cost over RSP/MSEK": 4.071,
    "out:LCP/MSEK": 2.996,
    "out:ROI% old": 17.79,
    "out:Payback discounted": 7,
    "out:Atemp": 5143,
    "out:Umean": 0.34,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 54,
    "out:Energy use kWh/m2": 54,
    "out:Energy savings %": 248.1,
    "out:Op energy cost/MSEK": 27,
    "out:El price/MSEK": 26,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 3,
    "out:El bought/kWh/m2": 3887.2,
    "out:Return %": 16,
    "out:Return/kSEK/y": 1938,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 2958000,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 147900,
    "out:EAHP (replacement cost)": 2204400,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 37.33,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 869167,
    "out:EL kWh savings": -183080,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 128574.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 479705,
    "out:DH kr savings": 790942,
    "out:El kr savings": -311237,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 17920,
    "out:% savings (space heating)": 97.28,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 2,
    "out:Etvv": 0,
    "out:Ef": 51.5,
    "out:heatPumpQuantity": 20,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000102100001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 3.98,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 55.04,
    "out:Electricity (inc PV)": 51.65,
    "out:Total Energy Use Pre PV": 59.04,
    "out:Total Energy Use Post PV": 55.65,
    "out:Primary Energy": 94.78,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 16.8,
    "out:CO2 Operational/m2": 8.71,
    "out:Total CO2/m2": 25.5,
    "out:Total CO2 (tons)": 131.15,
    "out:Klimatpaverkan": -198.09,
    "out:Initial Cost/MSEK": 12.324125,
    "out:Running cost/(Apt SEK y)": 12745,
    "out:Running Cost over RSP/MSEK": 4.143,
    "out:LCP/MSEK": 2.802,
    "out:ROI% old": 17.53,
    "out:Payback discounted": 7,
    "out:Atemp": 5143,
    "out:Umean": 0.34,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 56,
    "out:Energy use kWh/m2": 56,
    "out:Energy savings %": 235.7,
    "out:Op energy cost/MSEK": 28,
    "out:El price/MSEK": 26,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 4,
    "out:El bought/kWh/m2": 3852.7,
    "out:Return %": 16,
    "out:Return/kSEK/y": 1929,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 2958000,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 147900,
    "out:EAHP (replacement cost)": 2204400,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 36.84,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 864024,
    "out:EL kWh savings": -183881,
    "out:Sewage heat recovery cost": 250448,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 473664,
    "out:DH kr savings": 786262,
    "out:El kr savings": -312598,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 20475,
    "out:% savings (space heating)": 96.89,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 2.57,
    "out:Etvv": 0,
    "out:Ef": 51.65,
    "out:heatPumpQuantity": 20,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000102100001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 2.89,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 54.13,
    "out:Electricity (inc PV)": 50.66,
    "out:Total Energy Use Pre PV": 57.13,
    "out:Total Energy Use Post PV": 53.66,
    "out:Primary Energy": 92.42,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 16.8,
    "out:CO2 Operational/m2": 8.23,
    "out:Total CO2/m2": 25.02,
    "out:Total CO2 (tons)": 128.69,
    "out:Klimatpaverkan": -200.55,
    "out:Initial Cost/MSEK": 12.452625,
    "out:Running cost/(Apt SEK y)": 12234,
    "out:Running Cost over RSP/MSEK": 3.976,
    "out:LCP/MSEK": 2.84,
    "out:ROI% old": 17.54,
    "out:Payback discounted": 7,
    "out:Atemp": 5143,
    "out:Umean": 0.34,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 54,
    "out:Energy use kWh/m2": 54,
    "out:Energy savings %": 248.1,
    "out:Op energy cost/MSEK": 27,
    "out:El price/MSEK": 26,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 3,
    "out:El bought/kWh/m2": 3789.2,
    "out:Return %": 16,
    "out:Return/kSEK/y": 1950,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 2958000,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 147900,
    "out:EAHP (replacement cost)": 2204400,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 35.93,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 869167,
    "out:EL kWh savings": -178771,
    "out:Sewage heat recovery cost": 250448,
    "out:Balancing hydronic system cost": 128574.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 487031,
    "out:DH kr savings": 790942,
    "out:El kr savings": -303911,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 14848,
    "out:% savings (space heating)": 97.75,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 1.76,
    "out:Etvv": 0,
    "out:Ef": 50.66,
    "out:heatPumpQuantity": 20,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000102110000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 4.75,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 56.39,
    "out:Electricity (inc PV)": 42.18,
    "out:Total Energy Use Pre PV": 61.39,
    "out:Total Energy Use Post PV": 47.18,
    "out:Primary Energy": 77.93,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 28.75,
    "out:CO2 Operational/m2": 4.86,
    "out:Total CO2/m2": 33.6,
    "out:Total CO2 (tons)": 172.82,
    "out:Klimatpaverkan": -156.42,
    "out:Initial Cost/MSEK": 13.14375,
    "out:Running cost/(Apt SEK y)": 11054,
    "out:Running Cost over RSP/MSEK": 3.607,
    "out:LCP/MSEK": 2.518,
    "out:ROI% old": 17.02,
    "out:Payback discounted": 7,
    "out:Atemp": 5143,
    "out:Umean": 0.34,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 47,
    "out:Energy use kWh/m2": 47,
    "out:Energy savings %": 300,
    "out:Op energy cost/MSEK": 25,
    "out:El price/MSEK": 23,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 5,
    "out:El bought/kWh/m2": 3245.1,
    "out:Return %": 15,
    "out:Return/kSEK/y": 2000,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 2958000,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 147900,
    "out:EAHP (replacement cost)": 2204400,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 38.19,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 61.265983,
    "out:PV (m2 panels)": 321,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 57484.06,
    "out:PV (kWh sold)": 3296.59,
    "out:PV (% sold (El))": 5.73,
    "out:PV (kWh self-consumed)": 54187.46,
    "out:PV (ratio sold/self-consumed)": 0.06,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 858881,
    "out:EL kWh savings": -135155,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 556022,
    "out:DH kr savings": 781582,
    "out:El kr savings": -229763,
    "out:El kr sold": 4203,
    "out:El kr saved": 92119,
    "out:El kr return": 96322,
    "out:% solar/total": 66,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 24425,
    "out:% savings (space heating)": 96.29,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 2.87,
    "out:Etvv": 0,
    "out:Ef": 42.18,
    "out:heatPumpQuantity": 20,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000102110000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 3.48,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 55.53,
    "out:Electricity (inc PV)": 41.27,
    "out:Total Energy Use Pre PV": 58.53,
    "out:Total Energy Use Post PV": 44.27,
    "out:Primary Energy": 75.69,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 28.75,
    "out:CO2 Operational/m2": 3.79,
    "out:Total CO2/m2": 32.53,
    "out:Total CO2 (tons)": 167.33,
    "out:Klimatpaverkan": -161.91,
    "out:Initial Cost/MSEK": 13.2725,
    "out:Running cost/(Apt SEK y)": 10235,
    "out:Running Cost over RSP/MSEK": 3.339,
    "out:LCP/MSEK": 2.658,
    "out:ROI% old": 17.15,
    "out:Payback discounted": 7,
    "out:Atemp": 5143,
    "out:Umean": 0.34,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 44,
    "out:Energy use kWh/m2": 44,
    "out:Energy savings %": 327.3,
    "out:Op energy cost/MSEK": 23,
    "out:El price/MSEK": 22,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 3,
    "out:El bought/kWh/m2": 3187.9,
    "out:Return %": 15,
    "out:Return/kSEK/y": 2034,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 2958000,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 147900,
    "out:EAHP (replacement cost)": 2204400,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 37.33,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 61.265983,
    "out:PV (m2 panels)": 321,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 57484.06,
    "out:PV (kWh sold)": 3548.08,
    "out:PV (% sold (El))": 6.17,
    "out:PV (kWh self-consumed)": 53935.97,
    "out:PV (ratio sold/self-consumed)": 0.07,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 869167,
    "out:EL kWh savings": -130483,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 128574.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 573644,
    "out:DH kr savings": 790942,
    "out:El kr savings": -221821,
    "out:El kr sold": 4524,
    "out:El kr saved": 91691,
    "out:El kr return": 96215,
    "out:% solar/total": 66,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 17920,
    "out:% savings (space heating)": 97.28,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 2,
    "out:Etvv": 0,
    "out:Ef": 41.27,
    "out:heatPumpQuantity": 20,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000102110001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 3.98,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 55.04,
    "out:Electricity (inc PV)": 41.42,
    "out:Total Energy Use Pre PV": 59.04,
    "out:Total Energy Use Post PV": 45.42,
    "out:Primary Energy": 76.35,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 29.15,
    "out:CO2 Operational/m2": 3.94,
    "out:Total CO2/m2": 33.09,
    "out:Total CO2 (tons)": 170.19,
    "out:Klimatpaverkan": -159.05,
    "out:Initial Cost/MSEK": 13.39375,
    "out:Running cost/(Apt SEK y)": 10455,
    "out:Running Cost over RSP/MSEK": 3.412,
    "out:LCP/MSEK": 2.464,
    "out:ROI% old": 16.91,
    "out:Payback discounted": 7,
    "out:Atemp": 5143,
    "out:Umean": 0.34,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 45,
    "out:Energy use kWh/m2": 45,
    "out:Energy savings %": 317.8,
    "out:Op energy cost/MSEK": 23,
    "out:El price/MSEK": 22,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 4,
    "out:El bought/kWh/m2": 3155.1,
    "out:Return %": 15,
    "out:Return/kSEK/y": 2025,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 2958000,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 147900,
    "out:EAHP (replacement cost)": 2204400,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 36.84,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 61.265983,
    "out:PV (m2 panels)": 321,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 57484.06,
    "out:PV (kWh sold)": 3697.57,
    "out:PV (% sold (El))": 6.43,
    "out:PV (kWh self-consumed)": 53786.49,
    "out:PV (ratio sold/self-consumed)": 0.07,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 864024,
    "out:EL kWh savings": -131228,
    "out:Sewage heat recovery cost": 250448,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 567889,
    "out:DH kr savings": 786262,
    "out:El kr savings": -223087,
    "out:El kr sold": 4714,
    "out:El kr saved": 91437,
    "out:El kr return": 96151,
    "out:% solar/total": 66,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 20475,
    "out:% savings (space heating)": 96.89,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 2.57,
    "out:Etvv": 0,
    "out:Ef": 41.42,
    "out:heatPumpQuantity": 20,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000102110001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 2.89,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 54.13,
    "out:Electricity (inc PV)": 40.49,
    "out:Total Energy Use Pre PV": 57.13,
    "out:Total Energy Use Post PV": 43.49,
    "out:Primary Energy": 74.12,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 29.15,
    "out:CO2 Operational/m2": 3.19,
    "out:Total CO2/m2": 32.34,
    "out:Total CO2 (tons)": 166.34,
    "out:Klimatpaverkan": -162.9,
    "out:Initial Cost/MSEK": 13.5225,
    "out:Running cost/(Apt SEK y)": 9948,
    "out:Running Cost over RSP/MSEK": 3.246,
    "out:LCP/MSEK": 2.501,
    "out:ROI% old": 16.93,
    "out:Payback discounted": 7,
    "out:Atemp": 5143,
    "out:Umean": 0.34,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 43,
    "out:Energy use kWh/m2": 43,
    "out:Energy savings %": 337.2,
    "out:Op energy cost/MSEK": 22,
    "out:El price/MSEK": 21,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 3,
    "out:El bought/kWh/m2": 3094.7,
    "out:Return %": 15,
    "out:Return/kSEK/y": 2046,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 2958000,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 147900,
    "out:EAHP (replacement cost)": 2204400,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 35.93,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 61.265983,
    "out:PV (m2 panels)": 321,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 57484.06,
    "out:PV (kWh sold)": 3982.43,
    "out:PV (% sold (El))": 6.93,
    "out:PV (kWh self-consumed)": 53501.62,
    "out:PV (ratio sold/self-consumed)": 0.07,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 869167,
    "out:EL kWh savings": -126484,
    "out:Sewage heat recovery cost": 250448,
    "out:Balancing hydronic system cost": 128574.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 580997,
    "out:DH kr savings": 790942,
    "out:El kr savings": -215023,
    "out:El kr sold": 5078,
    "out:El kr saved": 90953,
    "out:El kr return": 96030,
    "out:% solar/total": 65,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 14848,
    "out:% savings (space heating)": 97.75,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 1.76,
    "out:Etvv": 0,
    "out:Ef": 40.49,
    "out:heatPumpQuantity": 20,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000102120000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 4.75,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 56.39,
    "out:Electricity (inc PV)": 38.01,
    "out:Total Energy Use Pre PV": 61.39,
    "out:Total Energy Use Post PV": 43.01,
    "out:Primary Energy": 70.43,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 41.1,
    "out:CO2 Operational/m2": -28.89,
    "out:Total CO2/m2": 12.21,
    "out:Total CO2 (tons)": 62.78,
    "out:Klimatpaverkan": -266.46,
    "out:Initial Cost/MSEK": 14.21375,
    "out:Running cost/(Apt SEK y)": 9071,
    "out:Running Cost over RSP/MSEK": 2.973,
    "out:LCP/MSEK": 2.083,
    "out:ROI% old": 16.38,
    "out:Payback discounted": 7,
    "out:Atemp": 5143,
    "out:Umean": 0.34,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 43,
    "out:Energy use kWh/m2": 43,
    "out:Energy savings %": 337.2,
    "out:Op energy cost/MSEK": 21,
    "out:El price/MSEK": 19,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 5,
    "out:El bought/kWh/m2": 2917.5,
    "out:Return %": 15,
    "out:Return/kSEK/y": 2083,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 2958000,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 147900,
    "out:EAHP (replacement cost)": 2204400,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 38.19,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.33,
    "out:PV (kWp installed)": 122.531965,
    "out:PV (m2 panels)": 643,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 114968.11,
    "out:PV (kWh sold)": 37236.52,
    "out:PV (% sold (El))": 32.39,
    "out:PV (kWh self-consumed)": 77731.59,
    "out:PV (ratio sold/self-consumed)": 0.48,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 858881,
    "out:EL kWh savings": -113723,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 635729,
    "out:DH kr savings": 781582,
    "out:El kr savings": -193329,
    "out:El kr sold": 47477,
    "out:El kr saved": 132144,
    "out:El kr return": 179620,
    "out:% solar/total": 95,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 24425,
    "out:% savings (space heating)": 96.29,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 2.87,
    "out:Etvv": 0,
    "out:Ef": 38.01,
    "out:heatPumpQuantity": 20,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000102120000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 3.48,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 55.53,
    "out:Electricity (inc PV)": 37.2,
    "out:Total Energy Use Pre PV": 58.53,
    "out:Total Energy Use Post PV": 40.2,
    "out:Primary Energy": 68.36,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 41.1,
    "out:CO2 Operational/m2": -30.43,
    "out:Total CO2/m2": 10.67,
    "out:Total CO2 (tons)": 54.88,
    "out:Klimatpaverkan": -274.36,
    "out:Initial Cost/MSEK": 14.34125,
    "out:Running cost/(Apt SEK y)": 8256,
    "out:Running Cost over RSP/MSEK": 2.706,
    "out:LCP/MSEK": 2.222,
    "out:ROI% old": 16.5,
    "out:Payback discounted": 7,
    "out:Atemp": 5143,
    "out:Umean": 0.34,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 40,
    "out:Energy use kWh/m2": 40,
    "out:Energy savings %": 370,
    "out:Op energy cost/MSEK": 19,
    "out:El price/MSEK": 18,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 3,
    "out:El bought/kWh/m2": 2867,
    "out:Return %": 15,
    "out:Return/kSEK/y": 2117,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 2958000,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 147900,
    "out:EAHP (replacement cost)": 2204400,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 37.33,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.33,
    "out:PV (kWp installed)": 122.531965,
    "out:PV (m2 panels)": 643,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 114968.11,
    "out:PV (kWh sold)": 37958.32,
    "out:PV (% sold (El))": 33.02,
    "out:PV (kWh self-consumed)": 77009.79,
    "out:PV (ratio sold/self-consumed)": 0.49,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 869167,
    "out:EL kWh savings": -109565,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 128574.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 653078,
    "out:DH kr savings": 790942,
    "out:El kr savings": -186261,
    "out:El kr sold": 48397,
    "out:El kr saved": 130917,
    "out:El kr return": 179314,
    "out:% solar/total": 94,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 17920,
    "out:% savings (space heating)": 97.28,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 2,
    "out:Etvv": 0,
    "out:Ef": 37.2,
    "out:heatPumpQuantity": 20,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000102120001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 3.98,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 55.04,
    "out:Electricity (inc PV)": 37.33,
    "out:Total Energy Use Pre PV": 59.04,
    "out:Total Energy Use Post PV": 41.33,
    "out:Primary Energy": 69,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 41.5,
    "out:CO2 Operational/m2": -30.54,
    "out:Total CO2/m2": 10.96,
    "out:Total CO2 (tons)": 56.37,
    "out:Klimatpaverkan": -272.87,
    "out:Initial Cost/MSEK": 14.46375,
    "out:Running cost/(Apt SEK y)": 8480,
    "out:Running Cost over RSP/MSEK": 2.78,
    "out:LCP/MSEK": 2.026,
    "out:ROI% old": 16.29,
    "out:Payback discounted": 7,
    "out:Atemp": 5143,
    "out:Umean": 0.34,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 41,
    "out:Energy use kWh/m2": 41,
    "out:Energy savings %": 358.5,
    "out:Op energy cost/MSEK": 19,
    "out:El price/MSEK": 18,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 4,
    "out:El bought/kWh/m2": 2837.9,
    "out:Return %": 15,
    "out:Return/kSEK/y": 2108,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 2958000,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 147900,
    "out:EAHP (replacement cost)": 2204400,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 36.84,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.33,
    "out:PV (kWp installed)": 122.531965,
    "out:PV (m2 panels)": 643,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 114968.11,
    "out:PV (kWh sold)": 38377.04,
    "out:PV (% sold (El))": 33.38,
    "out:PV (kWh self-consumed)": 76591.07,
    "out:PV (ratio sold/self-consumed)": 0.5,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 864024,
    "out:EL kWh savings": -110228,
    "out:Sewage heat recovery cost": 250448,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 647805,
    "out:DH kr savings": 786262,
    "out:El kr savings": -187387,
    "out:El kr sold": 48931,
    "out:El kr saved": 130205,
    "out:El kr return": 179136,
    "out:% solar/total": 94,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 20475,
    "out:% savings (space heating)": 96.89,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 2.57,
    "out:Etvv": 0,
    "out:Ef": 37.33,
    "out:heatPumpQuantity": 20,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000102120001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 2.89,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 54.13,
    "out:Electricity (inc PV)": 36.51,
    "out:Total Energy Use Pre PV": 57.13,
    "out:Total Energy Use Post PV": 39.51,
    "out:Primary Energy": 66.95,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 41.5,
    "out:CO2 Operational/m2": -31.78,
    "out:Total CO2/m2": 9.73,
    "out:Total CO2 (tons)": 50.02,
    "out:Klimatpaverkan": -279.22,
    "out:Initial Cost/MSEK": 14.5925,
    "out:Running cost/(Apt SEK y)": 7977,
    "out:Running Cost over RSP/MSEK": 2.615,
    "out:LCP/MSEK": 2.062,
    "out:ROI% old": 16.3,
    "out:Payback discounted": 7,
    "out:Atemp": 5143,
    "out:Umean": 0.34,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 40,
    "out:Energy use kWh/m2": 40,
    "out:Energy savings %": 370,
    "out:Op energy cost/MSEK": 18,
    "out:El price/MSEK": 17,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 3,
    "out:El bought/kWh/m2": 2784.5,
    "out:Return %": 15,
    "out:Return/kSEK/y": 2129,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 2958000,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 147900,
    "out:EAHP (replacement cost)": 2204400,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 35.93,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.33,
    "out:PV (kWp installed)": 122.531965,
    "out:PV (m2 panels)": 643,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 114968.11,
    "out:PV (kWh sold)": 39152.01,
    "out:PV (% sold (El))": 34.05,
    "out:PV (kWh self-consumed)": 75816.1,
    "out:PV (ratio sold/self-consumed)": 0.52,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 869167,
    "out:EL kWh savings": -106007,
    "out:Sewage heat recovery cost": 250448,
    "out:Balancing hydronic system cost": 128574.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 660650,
    "out:DH kr savings": 790942,
    "out:El kr savings": -180211,
    "out:El kr sold": 49919,
    "out:El kr saved": 128887,
    "out:El kr return": 178806,
    "out:% solar/total": 93,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 14848,
    "out:% savings (space heating)": 97.75,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 1.76,
    "out:Etvv": 0,
    "out:Ef": 36.51,
    "out:heatPumpQuantity": 20,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000200000000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 92.49,
    "out:DHW (DH)": 43.5,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 15.9,
    "out:Total Energy Use Pre PV": 151.9,
    "out:Total Energy Use Post PV": 151.9,
    "out:Primary Energy": 110.86,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 18.95,
    "out:CO2 Operational/m2": 51.06,
    "out:Total CO2/m2": 70,
    "out:Total CO2 (tons)": 360.02,
    "out:Klimatpaverkan": 30.78,
    "out:Initial Cost/MSEK": 6.846375,
    "out:Running cost/(Apt SEK y)": 47079,
    "out:Running Cost over RSP/MSEK": 15.461,
    "out:LCP/MSEK": -3.038,
    "out:ROI% old": 7.95,
    "out:Payback discounted": 15,
    "out:Atemp": 5143,
    "out:Umean": 0.29,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "F",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 152,
    "out:Energy use kWh/m2": 152,
    "out:Energy savings %": 23.7,
    "out:Op energy cost/MSEK": 113,
    "out:El price/MSEK": 68,
    "out:Heating price/MSEK": 39,
    "out:DH+ DHW/kWh/m2": 136,
    "out:El bought/kWh/m2": 1113,
    "out:Return %": 7,
    "out:Return/kSEK/y": 487,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 2,
    "out:Payback Time": 50,
    "out:DH kWh savings": 185148,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 168485,
    "out:DH kr savings": 168485,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 475651,
    "out:% savings (space heating)": 27.78,
    "out:Total_Water Heating (DH)": 223720,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 92.49,
    "out:Etvv": 25,
    "out:Ef": 15.9,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z02000200000000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 88.55,
    "out:DHW (DH)": 43.5,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 15.9,
    "out:Total Energy Use Pre PV": 147.9,
    "out:Total Energy Use Post PV": 147.9,
    "out:Primary Energy": 108.11,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 18.95,
    "out:CO2 Operational/m2": 49.62,
    "out:Total CO2/m2": 68.56,
    "out:Total CO2 (tons)": 352.62,
    "out:Klimatpaverkan": 23.38,
    "out:Initial Cost/MSEK": 6.975,
    "out:Running cost/(Apt SEK y)": 45791,
    "out:Running Cost over RSP/MSEK": 15.038,
    "out:LCP/MSEK": -2.744,
    "out:ROI% old": 8.67,
    "out:Payback discounted": 14,
    "out:Atemp": 5143,
    "out:Umean": 0.29,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "F",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 148,
    "out:Energy use kWh/m2": 148,
    "out:Energy savings %": 27,
    "out:Op energy cost/MSEK": 110,
    "out:El price/MSEK": 66,
    "out:Heating price/MSEK": 38,
    "out:DH+ DHW/kWh/m2": 132,
    "out:El bought/kWh/m2": 1113,
    "out:Return %": 8,
    "out:Return/kSEK/y": 541,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 205720,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 128574.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 187205,
    "out:DH kr savings": 187205,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 455433,
    "out:% savings (space heating)": 30.85,
    "out:Total_Water Heating (DH)": 223720,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 88.55,
    "out:Etvv": 25,
    "out:Ef": 15.9,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z02000200000001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 92.49,
    "out:DHW (DH)": 36.98,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 15.9,
    "out:Total Energy Use Pre PV": 144.9,
    "out:Total Energy Use Post PV": 144.9,
    "out:Primary Energy": 108.23,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 19.35,
    "out:CO2 Operational/m2": 48.54,
    "out:Total CO2/m2": 67.88,
    "out:Total CO2 (tons)": 349.13,
    "out:Klimatpaverkan": 19.89,
    "out:Initial Cost/MSEK": 7.096875,
    "out:Running cost/(Apt SEK y)": 44826,
    "out:Running Cost over RSP/MSEK": 14.72,
    "out:LCP/MSEK": -2.548,
    "out:ROI% old": 9.16,
    "out:Payback discounted": 13,
    "out:Atemp": 5143,
    "out:Umean": 0.29,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "F",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 145,
    "out:Energy use kWh/m2": 145,
    "out:Energy savings %": 29.7,
    "out:Op energy cost/MSEK": 108,
    "out:El price/MSEK": 65,
    "out:Heating price/MSEK": 37,
    "out:DH+ DHW/kWh/m2": 129,
    "out:El bought/kWh/m2": 1113,
    "out:Return %": 8,
    "out:Return/kSEK/y": 581,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 221149,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 250448,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 201246,
    "out:DH kr savings": 201246,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 475651,
    "out:% savings (space heating)": 27.78,
    "out:Total_Water Heating (DH)": 190162,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 92.49,
    "out:Etvv": 21.25,
    "out:Ef": 15.9,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z02000200000001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 88.55,
    "out:DHW (DH)": 36.98,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 15.9,
    "out:Total Energy Use Pre PV": 141.9,
    "out:Total Energy Use Post PV": 141.9,
    "out:Primary Energy": 105.48,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 19.35,
    "out:CO2 Operational/m2": 47.46,
    "out:Total CO2/m2": 66.8,
    "out:Total CO2 (tons)": 343.58,
    "out:Klimatpaverkan": 14.34,
    "out:Initial Cost/MSEK": 7.225375,
    "out:Running cost/(Apt SEK y)": 43860,
    "out:Running Cost over RSP/MSEK": 14.403,
    "out:LCP/MSEK": -2.359,
    "out:ROI% old": 9.62,
    "out:Payback discounted": 13,
    "out:Atemp": 5143,
    "out:Umean": 0.29,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "F",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 142,
    "out:Energy use kWh/m2": 142,
    "out:Energy savings %": 32.4,
    "out:Op energy cost/MSEK": 106,
    "out:El price/MSEK": 63,
    "out:Heating price/MSEK": 36,
    "out:DH+ DHW/kWh/m2": 126,
    "out:El bought/kWh/m2": 1113,
    "out:Return %": 9,
    "out:Return/kSEK/y": 622,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 236578,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 250448,
    "out:Balancing hydronic system cost": 128574.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 215286,
    "out:DH kr savings": 215286,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 455433,
    "out:% savings (space heating)": 30.85,
    "out:Total_Water Heating (DH)": 190162,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 88.55,
    "out:Etvv": 21.25,
    "out:Ef": 15.9,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z02000200010000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 92.49,
    "out:DHW (DH)": 43.5,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 10.73,
    "out:Total Energy Use Pre PV": 151.9,
    "out:Total Energy Use Post PV": 146.73,
    "out:Primary Energy": 101.55,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 31.3,
    "out:CO2 Operational/m2": 20.06,
    "out:Total CO2/m2": 51.36,
    "out:Total CO2 (tons)": 264.15,
    "out:Klimatpaverkan": -65.09,
    "out:Initial Cost/MSEK": 7.916125,
    "out:Running cost/(Apt SEK y)": 45064,
    "out:Running Cost over RSP/MSEK": 14.817,
    "out:LCP/MSEK": -3.464,
    "out:ROI% old": 8.03,
    "out:Payback discounted": 15,
    "out:Atemp": 5143,
    "out:Umean": 0.29,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "F",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 147,
    "out:Energy use kWh/m2": 147,
    "out:Energy savings %": 27.9,
    "out:Op energy cost/MSEK": 109,
    "out:El price/MSEK": 64,
    "out:Heating price/MSEK": 39,
    "out:DH+ DHW/kWh/m2": 136,
    "out:El bought/kWh/m2": 758.1,
    "out:Return %": 7,
    "out:Return/kSEK/y": 571,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 61.265983,
    "out:PV (m2 panels)": 321,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 57484.06,
    "out:PV (kWh sold)": 30886.05,
    "out:PV (% sold (El))": 53.73,
    "out:PV (kWh self-consumed)": 26598,
    "out:PV (ratio sold/self-consumed)": 1.16,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 185148,
    "out:EL kWh savings": 26598,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 253081,
    "out:DH kr savings": 168485,
    "out:El kr savings": 45217,
    "out:El kr sold": 39380,
    "out:El kr saved": 45217,
    "out:El kr return": 84596,
    "out:% solar/total": 33,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 475651,
    "out:% savings (space heating)": 27.78,
    "out:Total_Water Heating (DH)": 223720,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 92.49,
    "out:Etvv": 25,
    "out:Ef": 10.73,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z02000200010000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 88.55,
    "out:DHW (DH)": 43.5,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 10.73,
    "out:Total Energy Use Pre PV": 147.9,
    "out:Total Energy Use Post PV": 142.73,
    "out:Primary Energy": 98.8,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 31.3,
    "out:CO2 Operational/m2": 18.62,
    "out:Total CO2/m2": 49.92,
    "out:Total CO2 (tons)": 256.74,
    "out:Klimatpaverkan": -72.5,
    "out:Initial Cost/MSEK": 8.04475,
    "out:Running cost/(Apt SEK y)": 43777,
    "out:Running Cost over RSP/MSEK": 14.394,
    "out:LCP/MSEK": -3.17,
    "out:ROI% old": 8.66,
    "out:Payback discounted": 14,
    "out:Atemp": 5143,
    "out:Umean": 0.29,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "F",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 143,
    "out:Energy use kWh/m2": 143,
    "out:Energy savings %": 31.5,
    "out:Op energy cost/MSEK": 106,
    "out:El price/MSEK": 62,
    "out:Heating price/MSEK": 38,
    "out:DH+ DHW/kWh/m2": 132,
    "out:El bought/kWh/m2": 758.1,
    "out:Return %": 8,
    "out:Return/kSEK/y": 625,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 61.265983,
    "out:PV (m2 panels)": 321,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 57484.06,
    "out:PV (kWh sold)": 30886.05,
    "out:PV (% sold (El))": 53.73,
    "out:PV (kWh self-consumed)": 26598,
    "out:PV (ratio sold/self-consumed)": 1.16,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 205720,
    "out:EL kWh savings": 26598,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 128574.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 271802,
    "out:DH kr savings": 187205,
    "out:El kr savings": 45217,
    "out:El kr sold": 39380,
    "out:El kr saved": 45217,
    "out:El kr return": 84596,
    "out:% solar/total": 33,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 455433,
    "out:% savings (space heating)": 30.85,
    "out:Total_Water Heating (DH)": 223720,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 88.55,
    "out:Etvv": 25,
    "out:Ef": 10.73,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z02000200010001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 92.49,
    "out:DHW (DH)": 36.98,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 10.73,
    "out:Total Energy Use Pre PV": 144.9,
    "out:Total Energy Use Post PV": 139.73,
    "out:Primary Energy": 98.93,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 31.7,
    "out:CO2 Operational/m2": 17.54,
    "out:Total CO2/m2": 49.24,
    "out:Total CO2 (tons)": 253.26,
    "out:Klimatpaverkan": -75.98,
    "out:Initial Cost/MSEK": 8.166625,
    "out:Running cost/(Apt SEK y)": 42812,
    "out:Running Cost over RSP/MSEK": 14.077,
    "out:LCP/MSEK": -2.974,
    "out:ROI% old": 9.08,
    "out:Payback discounted": 13,
    "out:Atemp": 5143,
    "out:Umean": 0.29,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "F",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 140,
    "out:Energy use kWh/m2": 140,
    "out:Energy savings %": 34.3,
    "out:Op energy cost/MSEK": 104,
    "out:El price/MSEK": 61,
    "out:Heating price/MSEK": 37,
    "out:DH+ DHW/kWh/m2": 129,
    "out:El bought/kWh/m2": 758.1,
    "out:Return %": 8,
    "out:Return/kSEK/y": 666,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 61.265983,
    "out:PV (m2 panels)": 321,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 57484.06,
    "out:PV (kWh sold)": 30886.05,
    "out:PV (% sold (El))": 53.73,
    "out:PV (kWh self-consumed)": 26598,
    "out:PV (ratio sold/self-consumed)": 1.16,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 221149,
    "out:EL kWh savings": 26598,
    "out:Sewage heat recovery cost": 250448,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 285842,
    "out:DH kr savings": 201246,
    "out:El kr savings": 45217,
    "out:El kr sold": 39380,
    "out:El kr saved": 45217,
    "out:El kr return": 84596,
    "out:% solar/total": 33,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 475651,
    "out:% savings (space heating)": 27.78,
    "out:Total_Water Heating (DH)": 190162,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 92.49,
    "out:Etvv": 21.25,
    "out:Ef": 10.73,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z02000200010001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 88.55,
    "out:DHW (DH)": 36.98,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 10.73,
    "out:Total Energy Use Pre PV": 141.9,
    "out:Total Energy Use Post PV": 136.73,
    "out:Primary Energy": 96.17,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 31.7,
    "out:CO2 Operational/m2": 16.46,
    "out:Total CO2/m2": 48.16,
    "out:Total CO2 (tons)": 247.7,
    "out:Klimatpaverkan": -81.54,
    "out:Initial Cost/MSEK": 8.29525,
    "out:Running cost/(Apt SEK y)": 41846,
    "out:Running Cost over RSP/MSEK": 13.759,
    "out:LCP/MSEK": -2.785,
    "out:ROI% old": 9.49,
    "out:Payback discounted": 13,
    "out:Atemp": 5143,
    "out:Umean": 0.29,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "F",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 137,
    "out:Energy use kWh/m2": 137,
    "out:Energy savings %": 37.2,
    "out:Op energy cost/MSEK": 102,
    "out:El price/MSEK": 59,
    "out:Heating price/MSEK": 36,
    "out:DH+ DHW/kWh/m2": 126,
    "out:El bought/kWh/m2": 758.1,
    "out:Return %": 9,
    "out:Return/kSEK/y": 706,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 61.265983,
    "out:PV (m2 panels)": 321,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 57484.06,
    "out:PV (kWh sold)": 30886.05,
    "out:PV (% sold (El))": 53.73,
    "out:PV (kWh self-consumed)": 26598,
    "out:PV (ratio sold/self-consumed)": 1.16,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 236578,
    "out:EL kWh savings": 26598,
    "out:Sewage heat recovery cost": 250448,
    "out:Balancing hydronic system cost": 128574.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 299882,
    "out:DH kr savings": 215286,
    "out:El kr savings": 45217,
    "out:El kr sold": 39380,
    "out:El kr saved": 45217,
    "out:El kr return": 84596,
    "out:% solar/total": 33,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 455433,
    "out:% savings (space heating)": 30.85,
    "out:Total_Water Heating (DH)": 190162,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 88.55,
    "out:Etvv": 21.25,
    "out:Ef": 10.73,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z02000200020000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 92.49,
    "out:DHW (DH)": 43.5,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 9.88,
    "out:Total Energy Use Pre PV": 151.9,
    "out:Total Energy Use Post PV": 145.88,
    "out:Primary Energy": 100.02,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 43.65,
    "out:CO2 Operational/m2": -32.62,
    "out:Total CO2/m2": 11.03,
    "out:Total CO2 (tons)": 56.75,
    "out:Klimatpaverkan": -272.49,
    "out:Initial Cost/MSEK": 8.986,
    "out:Running cost/(Apt SEK y)": 43275,
    "out:Running Cost over RSP/MSEK": 14.246,
    "out:LCP/MSEK": -3.963,
    "out:ROI% old": 7.99,
    "out:Payback discounted": 15,
    "out:Atemp": 5143,
    "out:Umean": 0.29,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "F",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 146,
    "out:Energy use kWh/m2": 146,
    "out:Energy savings %": 28.8,
    "out:Op energy cost/MSEK": 106,
    "out:El price/MSEK": 60,
    "out:Heating price/MSEK": 39,
    "out:DH+ DHW/kWh/m2": 136,
    "out:El bought/kWh/m2": 696.7,
    "out:Return %": 7,
    "out:Return/kSEK/y": 646,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.33,
    "out:PV (kWp installed)": 122.531965,
    "out:PV (m2 panels)": 643,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 114968.11,
    "out:PV (kWh sold)": 84004.99,
    "out:PV (% sold (El))": 73.07,
    "out:PV (kWh self-consumed)": 30963.12,
    "out:PV (ratio sold/self-consumed)": 2.71,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 185148,
    "out:EL kWh savings": 30963,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 328228,
    "out:DH kr savings": 168485,
    "out:El kr savings": 52637,
    "out:El kr sold": 107106,
    "out:El kr saved": 52637,
    "out:El kr return": 159744,
    "out:% solar/total": 38,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 475651,
    "out:% savings (space heating)": 27.78,
    "out:Total_Water Heating (DH)": 223720,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 92.49,
    "out:Etvv": 25,
    "out:Ef": 9.88,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z02000200020000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 88.55,
    "out:DHW (DH)": 43.5,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 9.88,
    "out:Total Energy Use Pre PV": 147.9,
    "out:Total Energy Use Post PV": 141.88,
    "out:Primary Energy": 97.27,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 43.65,
    "out:CO2 Operational/m2": -34.06,
    "out:Total CO2/m2": 9.59,
    "out:Total CO2 (tons)": 49.34,
    "out:Klimatpaverkan": -279.9,
    "out:Initial Cost/MSEK": 9.1145,
    "out:Running cost/(Apt SEK y)": 41988,
    "out:Running Cost over RSP/MSEK": 13.823,
    "out:LCP/MSEK": -3.668,
    "out:ROI% old": 8.54,
    "out:Payback discounted": 14,
    "out:Atemp": 5143,
    "out:Umean": 0.29,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "F",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 142,
    "out:Energy use kWh/m2": 142,
    "out:Energy savings %": 32.4,
    "out:Op energy cost/MSEK": 103,
    "out:El price/MSEK": 59,
    "out:Heating price/MSEK": 38,
    "out:DH+ DHW/kWh/m2": 132,
    "out:El bought/kWh/m2": 696.7,
    "out:Return %": 8,
    "out:Return/kSEK/y": 700,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.33,
    "out:PV (kWp installed)": 122.531965,
    "out:PV (m2 panels)": 643,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 114968.11,
    "out:PV (kWh sold)": 84004.99,
    "out:PV (% sold (El))": 73.07,
    "out:PV (kWh self-consumed)": 30963.12,
    "out:PV (ratio sold/self-consumed)": 2.71,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 205720,
    "out:EL kWh savings": 30963,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 128574.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 346949,
    "out:DH kr savings": 187205,
    "out:El kr savings": 52637,
    "out:El kr sold": 107106,
    "out:El kr saved": 52637,
    "out:El kr return": 159744,
    "out:% solar/total": 38,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 455433,
    "out:% savings (space heating)": 30.85,
    "out:Total_Water Heating (DH)": 223720,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 88.55,
    "out:Etvv": 25,
    "out:Ef": 9.88,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z02000200020001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 92.49,
    "out:DHW (DH)": 36.98,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 9.88,
    "out:Total Energy Use Pre PV": 144.9,
    "out:Total Energy Use Post PV": 138.88,
    "out:Primary Energy": 97.4,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 44.05,
    "out:CO2 Operational/m2": -35.14,
    "out:Total CO2/m2": 8.92,
    "out:Total CO2 (tons)": 45.86,
    "out:Klimatpaverkan": -283.38,
    "out:Initial Cost/MSEK": 9.236375,
    "out:Running cost/(Apt SEK y)": 41022,
    "out:Running Cost over RSP/MSEK": 13.505,
    "out:LCP/MSEK": -3.473,
    "out:ROI% old": 8.91,
    "out:Payback discounted": 14,
    "out:Atemp": 5143,
    "out:Umean": 0.29,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "F",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 139,
    "out:Energy use kWh/m2": 139,
    "out:Energy savings %": 35.3,
    "out:Op energy cost/MSEK": 100,
    "out:El price/MSEK": 57,
    "out:Heating price/MSEK": 37,
    "out:DH+ DHW/kWh/m2": 129,
    "out:El bought/kWh/m2": 696.7,
    "out:Return %": 8,
    "out:Return/kSEK/y": 741,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.33,
    "out:PV (kWp installed)": 122.531965,
    "out:PV (m2 panels)": 643,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 114968.11,
    "out:PV (kWh sold)": 84004.99,
    "out:PV (% sold (El))": 73.07,
    "out:PV (kWh self-consumed)": 30963.12,
    "out:PV (ratio sold/self-consumed)": 2.71,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 221149,
    "out:EL kWh savings": 30963,
    "out:Sewage heat recovery cost": 250448,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 360989,
    "out:DH kr savings": 201246,
    "out:El kr savings": 52637,
    "out:El kr sold": 107106,
    "out:El kr saved": 52637,
    "out:El kr return": 159744,
    "out:% solar/total": 38,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 475651,
    "out:% savings (space heating)": 27.78,
    "out:Total_Water Heating (DH)": 190162,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 92.49,
    "out:Etvv": 21.25,
    "out:Ef": 9.88,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z02000200020001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 88.55,
    "out:DHW (DH)": 36.98,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 9.88,
    "out:Total Energy Use Pre PV": 141.9,
    "out:Total Energy Use Post PV": 135.88,
    "out:Primary Energy": 94.65,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 44.05,
    "out:CO2 Operational/m2": -36.22,
    "out:Total CO2/m2": 7.84,
    "out:Total CO2 (tons)": 40.3,
    "out:Klimatpaverkan": -288.94,
    "out:Initial Cost/MSEK": 9.365,
    "out:Running cost/(Apt SEK y)": 40057,
    "out:Running Cost over RSP/MSEK": 13.188,
    "out:LCP/MSEK": -3.284,
    "out:ROI% old": 9.28,
    "out:Payback discounted": 13,
    "out:Atemp": 5143,
    "out:Umean": 0.29,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "F",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 136,
    "out:Energy use kWh/m2": 136,
    "out:Energy savings %": 38.2,
    "out:Op energy cost/MSEK": 98,
    "out:El price/MSEK": 56,
    "out:Heating price/MSEK": 36,
    "out:DH+ DHW/kWh/m2": 126,
    "out:El bought/kWh/m2": 696.7,
    "out:Return %": 8,
    "out:Return/kSEK/y": 782,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.33,
    "out:PV (kWp installed)": 122.531965,
    "out:PV (m2 panels)": 643,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 114968.11,
    "out:PV (kWh sold)": 84004.99,
    "out:PV (% sold (El))": 73.07,
    "out:PV (kWh self-consumed)": 30963.12,
    "out:PV (ratio sold/self-consumed)": 2.71,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 236578,
    "out:EL kWh savings": 30963,
    "out:Sewage heat recovery cost": 250448,
    "out:Balancing hydronic system cost": 128574.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 375030,
    "out:DH kr savings": 215286,
    "out:El kr savings": 52637,
    "out:El kr sold": 107106,
    "out:El kr saved": 52637,
    "out:El kr return": 159744,
    "out:% solar/total": 38,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 455433,
    "out:% savings (space heating)": 30.85,
    "out:Total_Water Heating (DH)": 190162,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 88.55,
    "out:Etvv": 21.25,
    "out:Ef": 9.88,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z02000200100000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 92.49,
    "out:DHW (DH)": 34.8,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 15.9,
    "out:Total Energy Use Pre PV": 142.9,
    "out:Total Energy Use Post PV": 142.9,
    "out:Primary Energy": 107.36,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 18.95,
    "out:CO2 Operational/m2": 47.82,
    "out:Total CO2/m2": 66.76,
    "out:Total CO2 (tons)": 343.36,
    "out:Klimatpaverkan": 14.12,
    "out:Initial Cost/MSEK": 6.95725,
    "out:Running cost/(Apt SEK y)": 44182,
    "out:Running Cost over RSP/MSEK": 14.509,
    "out:LCP/MSEK": -2.197,
    "out:ROI% old": 9.77,
    "out:Payback discounted": 12,
    "out:Atemp": 5143,
    "out:Umean": 0.29,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "F",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 143,
    "out:Energy use kWh/m2": 143,
    "out:Energy savings %": 31.5,
    "out:Op energy cost/MSEK": 106,
    "out:El price/MSEK": 64,
    "out:Heating price/MSEK": 36,
    "out:DH+ DHW/kWh/m2": 127,
    "out:El bought/kWh/m2": 1113,
    "out:Return %": 9,
    "out:Return/kSEK/y": 608,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 231435,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 210606,
    "out:DH kr savings": 210606,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 475651,
    "out:% savings (space heating)": 27.78,
    "out:Total_Water Heating (DH)": 178976,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 92.49,
    "out:Etvv": 20,
    "out:Ef": 15.9,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z02000200100000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 88.55,
    "out:DHW (DH)": 34.8,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 15.9,
    "out:Total Energy Use Pre PV": 138.9,
    "out:Total Energy Use Post PV": 138.9,
    "out:Primary Energy": 104.61,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 18.95,
    "out:CO2 Operational/m2": 46.38,
    "out:Total CO2/m2": 65.32,
    "out:Total CO2 (tons)": 335.96,
    "out:Klimatpaverkan": 6.72,
    "out:Initial Cost/MSEK": 7.08575,
    "out:Running cost/(Apt SEK y)": 42895,
    "out:Running Cost over RSP/MSEK": 14.086,
    "out:LCP/MSEK": -1.902,
    "out:ROI% old": 10.45,
    "out:Payback discounted": 12,
    "out:Atemp": 5143,
    "out:Umean": 0.29,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "F",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 139,
    "out:Energy use kWh/m2": 139,
    "out:Energy savings %": 35.3,
    "out:Op energy cost/MSEK": 103,
    "out:El price/MSEK": 62,
    "out:Heating price/MSEK": 35,
    "out:DH+ DHW/kWh/m2": 123,
    "out:El bought/kWh/m2": 1113,
    "out:Return %": 9,
    "out:Return/kSEK/y": 662,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 252007,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 128574.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 229326,
    "out:DH kr savings": 229326,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 455433,
    "out:% savings (space heating)": 30.85,
    "out:Total_Water Heating (DH)": 178976,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 88.55,
    "out:Etvv": 20,
    "out:Ef": 15.9,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z02000200100001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 92.49,
    "out:DHW (DH)": 29.58,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 15.9,
    "out:Total Energy Use Pre PV": 137.9,
    "out:Total Energy Use Post PV": 137.9,
    "out:Primary Energy": 105.26,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 19.35,
    "out:CO2 Operational/m2": 46.02,
    "out:Total CO2/m2": 65.36,
    "out:Total CO2 (tons)": 336.17,
    "out:Klimatpaverkan": 6.93,
    "out:Initial Cost/MSEK": 7.207625,
    "out:Running cost/(Apt SEK y)": 42573,
    "out:Running Cost over RSP/MSEK": 13.98,
    "out:LCP/MSEK": -1.919,
    "out:ROI% old": 10.48,
    "out:Payback discounted": 12,
    "out:Atemp": 5143,
    "out:Umean": 0.29,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "F",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 138,
    "out:Energy use kWh/m2": 138,
    "out:Energy savings %": 36.2,
    "out:Op energy cost/MSEK": 102,
    "out:El price/MSEK": 62,
    "out:Heating price/MSEK": 35,
    "out:DH+ DHW/kWh/m2": 122,
    "out:El bought/kWh/m2": 1113,
    "out:Return %": 9,
    "out:Return/kSEK/y": 676,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 257150,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 250448,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 234006,
    "out:DH kr savings": 234006,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 475651,
    "out:% savings (space heating)": 27.78,
    "out:Total_Water Heating (DH)": 152130,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 92.49,
    "out:Etvv": 17,
    "out:Ef": 15.9,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z02000200100001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 88.55,
    "out:DHW (DH)": 29.58,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 15.9,
    "out:Total Energy Use Pre PV": 133.9,
    "out:Total Energy Use Post PV": 133.9,
    "out:Primary Energy": 102.51,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 19.35,
    "out:CO2 Operational/m2": 44.58,
    "out:Total CO2/m2": 63.93,
    "out:Total CO2 (tons)": 328.77,
    "out:Klimatpaverkan": -0.47,
    "out:Initial Cost/MSEK": 7.33625,
    "out:Running cost/(Apt SEK y)": 41286,
    "out:Running Cost over RSP/MSEK": 13.557,
    "out:LCP/MSEK": -1.624,
    "out:ROI% old": 11.12,
    "out:Payback discounted": 11,
    "out:Atemp": 5143,
    "out:Umean": 0.29,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "F",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 134,
    "out:Energy use kWh/m2": 134,
    "out:Energy savings %": 40.3,
    "out:Op energy cost/MSEK": 99,
    "out:El price/MSEK": 60,
    "out:Heating price/MSEK": 34,
    "out:DH+ DHW/kWh/m2": 118,
    "out:El bought/kWh/m2": 1113,
    "out:Return %": 10,
    "out:Return/kSEK/y": 730,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 277722,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 250448,
    "out:Balancing hydronic system cost": 128574.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 252727,
    "out:DH kr savings": 252727,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 455433,
    "out:% savings (space heating)": 30.85,
    "out:Total_Water Heating (DH)": 152130,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 88.55,
    "out:Etvv": 17,
    "out:Ef": 15.9,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z02000200110000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 92.49,
    "out:DHW (DH)": 34.8,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 10.73,
    "out:Total Energy Use Pre PV": 142.9,
    "out:Total Energy Use Post PV": 137.73,
    "out:Primary Energy": 98.05,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 31.3,
    "out:CO2 Operational/m2": 16.82,
    "out:Total CO2/m2": 48.12,
    "out:Total CO2 (tons)": 247.49,
    "out:Klimatpaverkan": -81.75,
    "out:Initial Cost/MSEK": 8.027,
    "out:Running cost/(Apt SEK y)": 42168,
    "out:Running Cost over RSP/MSEK": 13.865,
    "out:LCP/MSEK": -2.623,
    "out:ROI% old": 9.62,
    "out:Payback discounted": 13,
    "out:Atemp": 5143,
    "out:Umean": 0.29,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "F",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 138,
    "out:Energy use kWh/m2": 138,
    "out:Energy savings %": 36.2,
    "out:Op energy cost/MSEK": 102,
    "out:El price/MSEK": 60,
    "out:Heating price/MSEK": 36,
    "out:DH+ DHW/kWh/m2": 127,
    "out:El bought/kWh/m2": 758.1,
    "out:Return %": 9,
    "out:Return/kSEK/y": 693,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 61.265983,
    "out:PV (m2 panels)": 321,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 57484.06,
    "out:PV (kWh sold)": 30886.05,
    "out:PV (% sold (El))": 53.73,
    "out:PV (kWh self-consumed)": 26598,
    "out:PV (ratio sold/self-consumed)": 1.16,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 231435,
    "out:EL kWh savings": 26598,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 295202,
    "out:DH kr savings": 210606,
    "out:El kr savings": 45217,
    "out:El kr sold": 39380,
    "out:El kr saved": 45217,
    "out:El kr return": 84596,
    "out:% solar/total": 33,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 475651,
    "out:% savings (space heating)": 27.78,
    "out:Total_Water Heating (DH)": 178976,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 92.49,
    "out:Etvv": 20,
    "out:Ef": 10.73,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z02000200110000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 88.55,
    "out:DHW (DH)": 34.8,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 10.73,
    "out:Total Energy Use Pre PV": 138.9,
    "out:Total Energy Use Post PV": 133.73,
    "out:Primary Energy": 95.3,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 31.3,
    "out:CO2 Operational/m2": 15.38,
    "out:Total CO2/m2": 46.68,
    "out:Total CO2 (tons)": 240.08,
    "out:Klimatpaverkan": -89.16,
    "out:Initial Cost/MSEK": 8.155625,
    "out:Running cost/(Apt SEK y)": 40881,
    "out:Running Cost over RSP/MSEK": 13.442,
    "out:LCP/MSEK": -2.328,
    "out:ROI% old": 10.21,
    "out:Payback discounted": 12,
    "out:Atemp": 5143,
    "out:Umean": 0.29,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "F",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 134,
    "out:Energy use kWh/m2": 134,
    "out:Energy savings %": 40.3,
    "out:Op energy cost/MSEK": 99,
    "out:El price/MSEK": 58,
    "out:Heating price/MSEK": 35,
    "out:DH+ DHW/kWh/m2": 123,
    "out:El bought/kWh/m2": 758.1,
    "out:Return %": 9,
    "out:Return/kSEK/y": 747,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 61.265983,
    "out:PV (m2 panels)": 321,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 57484.06,
    "out:PV (kWh sold)": 30886.05,
    "out:PV (% sold (El))": 53.73,
    "out:PV (kWh self-consumed)": 26598,
    "out:PV (ratio sold/self-consumed)": 1.16,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 252007,
    "out:EL kWh savings": 26598,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 128574.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 313923,
    "out:DH kr savings": 229326,
    "out:El kr savings": 45217,
    "out:El kr sold": 39380,
    "out:El kr saved": 45217,
    "out:El kr return": 84596,
    "out:% solar/total": 33,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 455433,
    "out:% savings (space heating)": 30.85,
    "out:Total_Water Heating (DH)": 178976,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 88.55,
    "out:Etvv": 20,
    "out:Ef": 10.73,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z02000200110001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 92.49,
    "out:DHW (DH)": 29.58,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 10.73,
    "out:Total Energy Use Pre PV": 137.9,
    "out:Total Energy Use Post PV": 132.73,
    "out:Primary Energy": 95.95,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 31.7,
    "out:CO2 Operational/m2": 15.02,
    "out:Total CO2/m2": 46.72,
    "out:Total CO2 (tons)": 240.3,
    "out:Klimatpaverkan": -88.94,
    "out:Initial Cost/MSEK": 8.2775,
    "out:Running cost/(Apt SEK y)": 40559,
    "out:Running Cost over RSP/MSEK": 13.336,
    "out:LCP/MSEK": -2.345,
    "out:ROI% old": 10.24,
    "out:Payback discounted": 12,
    "out:Atemp": 5143,
    "out:Umean": 0.29,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "F",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 133,
    "out:Energy use kWh/m2": 133,
    "out:Energy savings %": 41.4,
    "out:Op energy cost/MSEK": 98,
    "out:El price/MSEK": 58,
    "out:Heating price/MSEK": 35,
    "out:DH+ DHW/kWh/m2": 122,
    "out:El bought/kWh/m2": 758.1,
    "out:Return %": 9,
    "out:Return/kSEK/y": 760,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 61.265983,
    "out:PV (m2 panels)": 321,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 57484.06,
    "out:PV (kWh sold)": 30886.05,
    "out:PV (% sold (El))": 53.73,
    "out:PV (kWh self-consumed)": 26598,
    "out:PV (ratio sold/self-consumed)": 1.16,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 257150,
    "out:EL kWh savings": 26598,
    "out:Sewage heat recovery cost": 250448,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 318603,
    "out:DH kr savings": 234006,
    "out:El kr savings": 45217,
    "out:El kr sold": 39380,
    "out:El kr saved": 45217,
    "out:El kr return": 84596,
    "out:% solar/total": 33,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 475651,
    "out:% savings (space heating)": 27.78,
    "out:Total_Water Heating (DH)": 152130,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 92.49,
    "out:Etvv": 17,
    "out:Ef": 10.73,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z02000200110001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 88.55,
    "out:DHW (DH)": 29.58,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 10.73,
    "out:Total Energy Use Pre PV": 133.9,
    "out:Total Energy Use Post PV": 128.73,
    "out:Primary Energy": 93.2,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 31.7,
    "out:CO2 Operational/m2": 13.58,
    "out:Total CO2/m2": 45.28,
    "out:Total CO2 (tons)": 232.89,
    "out:Klimatpaverkan": -96.35,
    "out:Initial Cost/MSEK": 8.406,
    "out:Running cost/(Apt SEK y)": 39272,
    "out:Running Cost over RSP/MSEK": 12.913,
    "out:LCP/MSEK": -2.05,
    "out:ROI% old": 10.8,
    "out:Payback discounted": 11,
    "out:Atemp": 5143,
    "out:Umean": 0.29,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "F",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 129,
    "out:Energy use kWh/m2": 129,
    "out:Energy savings %": 45.7,
    "out:Op energy cost/MSEK": 95,
    "out:El price/MSEK": 56,
    "out:Heating price/MSEK": 34,
    "out:DH+ DHW/kWh/m2": 118,
    "out:El bought/kWh/m2": 758.1,
    "out:Return %": 10,
    "out:Return/kSEK/y": 815,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 61.265983,
    "out:PV (m2 panels)": 321,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 57484.06,
    "out:PV (kWh sold)": 30886.05,
    "out:PV (% sold (El))": 53.73,
    "out:PV (kWh self-consumed)": 26598,
    "out:PV (ratio sold/self-consumed)": 1.16,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 277722,
    "out:EL kWh savings": 26598,
    "out:Sewage heat recovery cost": 250448,
    "out:Balancing hydronic system cost": 128574.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 337323,
    "out:DH kr savings": 252727,
    "out:El kr savings": 45217,
    "out:El kr sold": 39380,
    "out:El kr saved": 45217,
    "out:El kr return": 84596,
    "out:% solar/total": 33,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 455433,
    "out:% savings (space heating)": 30.85,
    "out:Total_Water Heating (DH)": 152130,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 88.55,
    "out:Etvv": 17,
    "out:Ef": 10.73,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z02000200120000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 92.49,
    "out:DHW (DH)": 34.8,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 9.88,
    "out:Total Energy Use Pre PV": 142.9,
    "out:Total Energy Use Post PV": 136.88,
    "out:Primary Energy": 96.52,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 43.65,
    "out:CO2 Operational/m2": -35.86,
    "out:Total CO2/m2": 7.79,
    "out:Total CO2 (tons)": 40.09,
    "out:Klimatpaverkan": -289.15,
    "out:Initial Cost/MSEK": 9.09675,
    "out:Running cost/(Apt SEK y)": 40379,
    "out:Running Cost over RSP/MSEK": 13.294,
    "out:LCP/MSEK": -3.121,
    "out:ROI% old": 9.38,
    "out:Payback discounted": 13,
    "out:Atemp": 5143,
    "out:Umean": 0.29,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "F",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 137,
    "out:Energy use kWh/m2": 137,
    "out:Energy savings %": 37.2,
    "out:Op energy cost/MSEK": 99,
    "out:El price/MSEK": 56,
    "out:Heating price/MSEK": 36,
    "out:DH+ DHW/kWh/m2": 127,
    "out:El bought/kWh/m2": 696.7,
    "out:Return %": 8,
    "out:Return/kSEK/y": 768,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.33,
    "out:PV (kWp installed)": 122.531965,
    "out:PV (m2 panels)": 643,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 114968.11,
    "out:PV (kWh sold)": 84004.99,
    "out:PV (% sold (El))": 73.07,
    "out:PV (kWh self-consumed)": 30963.12,
    "out:PV (ratio sold/self-consumed)": 2.71,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 231435,
    "out:EL kWh savings": 30963,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 370350,
    "out:DH kr savings": 210606,
    "out:El kr savings": 52637,
    "out:El kr sold": 107106,
    "out:El kr saved": 52637,
    "out:El kr return": 159744,
    "out:% solar/total": 38,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 475651,
    "out:% savings (space heating)": 27.78,
    "out:Total_Water Heating (DH)": 178976,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 92.49,
    "out:Etvv": 20,
    "out:Ef": 9.88,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z02000200120000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 88.55,
    "out:DHW (DH)": 34.8,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 9.88,
    "out:Total Energy Use Pre PV": 138.9,
    "out:Total Energy Use Post PV": 132.88,
    "out:Primary Energy": 93.77,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 43.65,
    "out:CO2 Operational/m2": -37.3,
    "out:Total CO2/m2": 6.35,
    "out:Total CO2 (tons)": 32.68,
    "out:Klimatpaverkan": -296.56,
    "out:Initial Cost/MSEK": 9.225375,
    "out:Running cost/(Apt SEK y)": 39091,
    "out:Running Cost over RSP/MSEK": 12.871,
    "out:LCP/MSEK": -2.827,
    "out:ROI% old": 9.91,
    "out:Payback discounted": 12,
    "out:Atemp": 5143,
    "out:Umean": 0.29,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "F",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 133,
    "out:Energy use kWh/m2": 133,
    "out:Energy savings %": 41.4,
    "out:Op energy cost/MSEK": 96,
    "out:El price/MSEK": 55,
    "out:Heating price/MSEK": 35,
    "out:DH+ DHW/kWh/m2": 123,
    "out:El bought/kWh/m2": 696.7,
    "out:Return %": 9,
    "out:Return/kSEK/y": 822,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.33,
    "out:PV (kWp installed)": 122.531965,
    "out:PV (m2 panels)": 643,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 114968.11,
    "out:PV (kWh sold)": 84004.99,
    "out:PV (% sold (El))": 73.07,
    "out:PV (kWh self-consumed)": 30963.12,
    "out:PV (ratio sold/self-consumed)": 2.71,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 252007,
    "out:EL kWh savings": 30963,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 128574.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 389070,
    "out:DH kr savings": 229326,
    "out:El kr savings": 52637,
    "out:El kr sold": 107106,
    "out:El kr saved": 52637,
    "out:El kr return": 159744,
    "out:% solar/total": 38,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 455433,
    "out:% savings (space heating)": 30.85,
    "out:Total_Water Heating (DH)": 178976,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 88.55,
    "out:Etvv": 20,
    "out:Ef": 9.88,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z02000200120001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 92.49,
    "out:DHW (DH)": 29.58,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 9.88,
    "out:Total Energy Use Pre PV": 137.9,
    "out:Total Energy Use Post PV": 131.88,
    "out:Primary Energy": 94.42,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 44.05,
    "out:CO2 Operational/m2": -37.66,
    "out:Total CO2/m2": 6.4,
    "out:Total CO2 (tons)": 32.9,
    "out:Klimatpaverkan": -296.34,
    "out:Initial Cost/MSEK": 9.34725,
    "out:Running cost/(Apt SEK y)": 38770,
    "out:Running Cost over RSP/MSEK": 12.765,
    "out:LCP/MSEK": -2.843,
    "out:ROI% old": 9.94,
    "out:Payback discounted": 12,
    "out:Atemp": 5143,
    "out:Umean": 0.29,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "F",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 132,
    "out:Energy use kWh/m2": 132,
    "out:Energy savings %": 42.4,
    "out:Op energy cost/MSEK": 95,
    "out:El price/MSEK": 54,
    "out:Heating price/MSEK": 35,
    "out:DH+ DHW/kWh/m2": 122,
    "out:El bought/kWh/m2": 696.7,
    "out:Return %": 9,
    "out:Return/kSEK/y": 836,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.33,
    "out:PV (kWp installed)": 122.531965,
    "out:PV (m2 panels)": 643,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 114968.11,
    "out:PV (kWh sold)": 84004.99,
    "out:PV (% sold (El))": 73.07,
    "out:PV (kWh self-consumed)": 30963.12,
    "out:PV (ratio sold/self-consumed)": 2.71,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 257150,
    "out:EL kWh savings": 30963,
    "out:Sewage heat recovery cost": 250448,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 393750,
    "out:DH kr savings": 234006,
    "out:El kr savings": 52637,
    "out:El kr sold": 107106,
    "out:El kr saved": 52637,
    "out:El kr return": 159744,
    "out:% solar/total": 38,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 475651,
    "out:% savings (space heating)": 27.78,
    "out:Total_Water Heating (DH)": 152130,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 92.49,
    "out:Etvv": 17,
    "out:Ef": 9.88,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z02000200120001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 88.55,
    "out:DHW (DH)": 29.58,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 9.88,
    "out:Total Energy Use Pre PV": 133.9,
    "out:Total Energy Use Post PV": 127.88,
    "out:Primary Energy": 91.67,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 44.05,
    "out:CO2 Operational/m2": -39.1,
    "out:Total CO2/m2": 4.96,
    "out:Total CO2 (tons)": 25.49,
    "out:Klimatpaverkan": -303.75,
    "out:Initial Cost/MSEK": 9.475875,
    "out:Running cost/(Apt SEK y)": 37482,
    "out:Running Cost over RSP/MSEK": 12.342,
    "out:LCP/MSEK": -2.548,
    "out:ROI% old": 10.44,
    "out:Payback discounted": 12,
    "out:Atemp": 5143,
    "out:Umean": 0.29,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "F",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 128,
    "out:Energy use kWh/m2": 128,
    "out:Energy savings %": 46.9,
    "out:Op energy cost/MSEK": 92,
    "out:El price/MSEK": 52,
    "out:Heating price/MSEK": 34,
    "out:DH+ DHW/kWh/m2": 118,
    "out:El bought/kWh/m2": 696.7,
    "out:Return %": 9,
    "out:Return/kSEK/y": 890,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.33,
    "out:PV (kWp installed)": 122.531965,
    "out:PV (m2 panels)": 643,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 114968.11,
    "out:PV (kWh sold)": 84004.99,
    "out:PV (% sold (El))": 73.07,
    "out:PV (kWh self-consumed)": 30963.12,
    "out:PV (ratio sold/self-consumed)": 2.71,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 277722,
    "out:EL kWh savings": 30963,
    "out:Sewage heat recovery cost": 250448,
    "out:Balancing hydronic system cost": 128574.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 412471,
    "out:DH kr savings": 252727,
    "out:El kr savings": 52637,
    "out:El kr sold": 107106,
    "out:El kr saved": 52637,
    "out:El kr return": 159744,
    "out:% solar/total": 38,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 455433,
    "out:% savings (space heating)": 30.85,
    "out:Total_Water Heating (DH)": 152130,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 88.55,
    "out:Etvv": 17,
    "out:Ef": 9.88,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z02000201000000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 47.67,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 44.96,
    "out:Electricity (inc PV)": 42.63,
    "out:Total Energy Use Pre PV": 92.96,
    "out:Total Energy Use Post PV": 90.63,
    "out:Primary Energy": 102.54,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 21.8,
    "out:CO2 Operational/m2": 23.21,
    "out:Total CO2/m2": 45.01,
    "out:Total CO2 (tons)": 231.49,
    "out:Klimatpaverkan": -97.75,
    "out:Initial Cost/MSEK": 11.32225,
    "out:Running cost/(Apt SEK y)": 24807,
    "out:Running Cost over RSP/MSEK": 8.116,
    "out:LCP/MSEK": -0.17,
    "out:ROI% old": 14.07,
    "out:Payback discounted": 9,
    "out:Atemp": 5143,
    "out:Umean": 0.29,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 91,
    "out:Energy use kWh/m2": 91,
    "out:Energy savings %": 106.6,
    "out:Op energy cost/MSEK": 58,
    "out:El price/MSEK": 42,
    "out:Heating price/MSEK": 14,
    "out:DH+ DHW/kWh/m2": 48,
    "out:El bought/kWh/m2": 3147.3,
    "out:Return %": 13,
    "out:Return/kSEK/y": 1422,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 1479000,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 73950,
    "out:EAHP (replacement cost)": 1105400,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 26.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 637732,
    "out:EL kWh savings": -137469,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 346638,
    "out:DH kr savings": 580336,
    "out:El kr savings": -233698,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 245181,
    "out:% savings (space heating)": 62.77,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 36.87,
    "out:Etvv": 0,
    "out:Ef": 42.63,
    "out:heatPumpQuantity": 10,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000201000000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 44.05,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 44.87,
    "out:Electricity (inc PV)": 42.48,
    "out:Total Energy Use Pre PV": 88.87,
    "out:Total Energy Use Post PV": 86.48,
    "out:Primary Energy": 99.86,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 21.8,
    "out:CO2 Operational/m2": 21.76,
    "out:Total CO2/m2": 43.56,
    "out:Total CO2 (tons)": 224.02,
    "out:Klimatpaverkan": -105.22,
    "out:Initial Cost/MSEK": 11.450875,
    "out:Running cost/(Apt SEK y)": 23501,
    "out:Running Cost over RSP/MSEK": 7.687,
    "out:LCP/MSEK": 0.131,
    "out:ROI% old": 14.45,
    "out:Payback discounted": 8,
    "out:Atemp": 5143,
    "out:Umean": 0.29,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 86,
    "out:Energy use kWh/m2": 86,
    "out:Energy savings %": 118.6,
    "out:Op energy cost/MSEK": 54,
    "out:El price/MSEK": 40,
    "out:Heating price/MSEK": 13,
    "out:DH+ DHW/kWh/m2": 44,
    "out:El bought/kWh/m2": 3140.8,
    "out:Return %": 13,
    "out:Return/kSEK/y": 1477,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 1479000,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 73950,
    "out:EAHP (replacement cost)": 1105400,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 26.67,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 658304,
    "out:EL kWh savings": -136725,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 128574.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 366624,
    "out:DH kr savings": 599057,
    "out:El kr savings": -232432,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 226526,
    "out:% savings (space heating)": 65.6,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 33.41,
    "out:Etvv": 0,
    "out:Ef": 42.48,
    "out:heatPumpQuantity": 10,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000201000001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 43.74,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 44.18,
    "out:Electricity (inc PV)": 42.12,
    "out:Total Energy Use Pre PV": 88.18,
    "out:Total Energy Use Post PV": 86.12,
    "out:Primary Energy": 100.18,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 22.2,
    "out:CO2 Operational/m2": 21.67,
    "out:Total CO2/m2": 43.87,
    "out:Total CO2 (tons)": 225.62,
    "out:Klimatpaverkan": -103.62,
    "out:Initial Cost/MSEK": 11.57275,
    "out:Running cost/(Apt SEK y)": 23357,
    "out:Running Cost over RSP/MSEK": 7.64,
    "out:LCP/MSEK": 0.056,
    "out:ROI% old": 14.35,
    "out:Payback discounted": 8,
    "out:Atemp": 5143,
    "out:Umean": 0.29,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 86,
    "out:Energy use kWh/m2": 86,
    "out:Energy savings %": 118.6,
    "out:Op energy cost/MSEK": 54,
    "out:El price/MSEK": 39,
    "out:Heating price/MSEK": 13,
    "out:DH+ DHW/kWh/m2": 44,
    "out:El bought/kWh/m2": 3092.3,
    "out:Return %": 13,
    "out:Return/kSEK/y": 1483,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 1479000,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 73950,
    "out:EAHP (replacement cost)": 1105400,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 25.98,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 658304,
    "out:EL kWh savings": -134832,
    "out:Sewage heat recovery cost": 250448,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 369842,
    "out:DH kr savings": 599057,
    "out:El kr savings": -229215,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 224945,
    "out:% savings (space heating)": 65.84,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 34.81,
    "out:Etvv": 0,
    "out:Ef": 42.12,
    "out:heatPumpQuantity": 10,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000201000001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 40.17,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 44.07,
    "out:Electricity (inc PV)": 41.96,
    "out:Total Energy Use Pre PV": 84.07,
    "out:Total Energy Use Post PV": 81.96,
    "out:Primary Energy": 97.5,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 22.2,
    "out:CO2 Operational/m2": 20.22,
    "out:Total CO2/m2": 42.42,
    "out:Total CO2 (tons)": 218.14,
    "out:Klimatpaverkan": -111.1,
    "out:Initial Cost/MSEK": 11.70125,
    "out:Running cost/(Apt SEK y)": 22046,
    "out:Running Cost over RSP/MSEK": 7.21,
    "out:LCP/MSEK": 0.358,
    "out:ROI% old": 14.72,
    "out:Payback discounted": 8,
    "out:Atemp": 5143,
    "out:Umean": 0.29,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 82,
    "out:Energy use kWh/m2": 82,
    "out:Energy savings %": 129.3,
    "out:Op energy cost/MSEK": 51,
    "out:El price/MSEK": 38,
    "out:Heating price/MSEK": 11,
    "out:DH+ DHW/kWh/m2": 40,
    "out:El bought/kWh/m2": 3084.7,
    "out:Return %": 13,
    "out:Return/kSEK/y": 1538,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 1479000,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 73950,
    "out:EAHP (replacement cost)": 1105400,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 25.87,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 678876,
    "out:EL kWh savings": -134023,
    "out:Sewage heat recovery cost": 250448,
    "out:Balancing hydronic system cost": 128574.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 389937,
    "out:DH kr savings": 617777,
    "out:El kr savings": -227840,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 206582,
    "out:% savings (space heating)": 68.63,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 31.4,
    "out:Etvv": 0,
    "out:Ef": 41.96,
    "out:heatPumpQuantity": 10,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000201010000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 47.67,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 44.96,
    "out:Electricity (inc PV)": 33.17,
    "out:Total Energy Use Pre PV": 92.96,
    "out:Total Energy Use Post PV": 81.17,
    "out:Primary Energy": 85.51,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 34.15,
    "out:CO2 Operational/m2": 14.73,
    "out:Total CO2/m2": 48.88,
    "out:Total CO2 (tons)": 251.36,
    "out:Klimatpaverkan": -77.88,
    "out:Initial Cost/MSEK": 12.392125,
    "out:Running cost/(Apt SEK y)": 22558,
    "out:Running Cost over RSP/MSEK": 7.397,
    "out:LCP/MSEK": -0.52,
    "out:ROI% old": 13.69,
    "out:Payback discounted": 9,
    "out:Atemp": 5143,
    "out:Umean": 0.29,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 81,
    "out:Energy use kWh/m2": 81,
    "out:Energy savings %": 132.1,
    "out:Op energy cost/MSEK": 53,
    "out:El price/MSEK": 37,
    "out:Heating price/MSEK": 14,
    "out:DH+ DHW/kWh/m2": 48,
    "out:El bought/kWh/m2": 2495.3,
    "out:Return %": 12,
    "out:Return/kSEK/y": 1516,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 1479000,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 73950,
    "out:EAHP (replacement cost)": 1105400,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 26.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 61.265983,
    "out:PV (m2 panels)": 321,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 57484.06,
    "out:PV (kWh sold)": 7632.77,
    "out:PV (% sold (El))": 13.28,
    "out:PV (kWh self-consumed)": 49851.29,
    "out:PV (ratio sold/self-consumed)": 0.15,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 637732,
    "out:EL kWh savings": -88800,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 439108,
    "out:DH kr savings": 580336,
    "out:El kr savings": -150960,
    "out:El kr sold": 9732,
    "out:El kr saved": 84747,
    "out:El kr return": 94479,
    "out:% solar/total": 61,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 245181,
    "out:% savings (space heating)": 62.77,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 36.87,
    "out:Etvv": 0,
    "out:Ef": 33.17,
    "out:heatPumpQuantity": 10,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000201010000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 44.05,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 44.87,
    "out:Electricity (inc PV)": 33.04,
    "out:Total Energy Use Pre PV": 88.87,
    "out:Total Energy Use Post PV": 77.04,
    "out:Primary Energy": 82.85,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 34.15,
    "out:CO2 Operational/m2": 13.23,
    "out:Total CO2/m2": 47.38,
    "out:Total CO2 (tons)": 243.68,
    "out:Klimatpaverkan": -85.56,
    "out:Initial Cost/MSEK": 12.52125,
    "out:Running cost/(Apt SEK y)": 21252,
    "out:Running Cost over RSP/MSEK": 6.968,
    "out:LCP/MSEK": -0.22,
    "out:ROI% old": 14.03,
    "out:Payback discounted": 9,
    "out:Atemp": 5143,
    "out:Umean": 0.29,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 77,
    "out:Energy use kWh/m2": 77,
    "out:Energy savings %": 144.2,
    "out:Op energy cost/MSEK": 50,
    "out:El price/MSEK": 35,
    "out:Heating price/MSEK": 13,
    "out:DH+ DHW/kWh/m2": 44,
    "out:El bought/kWh/m2": 2489.4,
    "out:Return %": 13,
    "out:Return/kSEK/y": 1571,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 1479000,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 73950,
    "out:EAHP (replacement cost)": 1105400,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 26.67,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 61.265983,
    "out:PV (m2 panels)": 321,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 57484.06,
    "out:PV (kWh sold)": 7677.2,
    "out:PV (% sold (El))": 13.36,
    "out:PV (kWh self-consumed)": 49806.85,
    "out:PV (ratio sold/self-consumed)": 0.15,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 658304,
    "out:EL kWh savings": -88132,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 128574.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 459021,
    "out:DH kr savings": 599057,
    "out:El kr savings": -149825,
    "out:El kr sold": 9788,
    "out:El kr saved": 84672,
    "out:El kr return": 94460,
    "out:% solar/total": 61,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 226526,
    "out:% savings (space heating)": 65.6,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 33.41,
    "out:Etvv": 0,
    "out:Ef": 33.04,
    "out:heatPumpQuantity": 10,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000201010001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 43.74,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 44.18,
    "out:Electricity (inc PV)": 32.71,
    "out:Total Energy Use Pre PV": 88.18,
    "out:Total Energy Use Post PV": 76.71,
    "out:Primary Energy": 83.24,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 34.55,
    "out:CO2 Operational/m2": 12.82,
    "out:Total CO2/m2": 47.37,
    "out:Total CO2 (tons)": 243.61,
    "out:Klimatpaverkan": -85.63,
    "out:Initial Cost/MSEK": 12.6425,
    "out:Running cost/(Apt SEK y)": 21111,
    "out:Running Cost over RSP/MSEK": 6.922,
    "out:LCP/MSEK": -0.296,
    "out:ROI% old": 13.95,
    "out:Payback discounted": 9,
    "out:Atemp": 5143,
    "out:Umean": 0.29,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 77,
    "out:Energy use kWh/m2": 77,
    "out:Energy savings %": 144.2,
    "out:Op energy cost/MSEK": 50,
    "out:El price/MSEK": 35,
    "out:Heating price/MSEK": 13,
    "out:DH+ DHW/kWh/m2": 44,
    "out:El bought/kWh/m2": 2445,
    "out:Return %": 12,
    "out:Return/kSEK/y": 1577,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 1479000,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 73950,
    "out:EAHP (replacement cost)": 1105400,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 25.98,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 61.265983,
    "out:PV (m2 panels)": 321,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 57484.06,
    "out:PV (kWh sold)": 8018.13,
    "out:PV (% sold (El))": 13.95,
    "out:PV (kWh self-consumed)": 49465.93,
    "out:PV (ratio sold/self-consumed)": 0.16,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 658304,
    "out:EL kWh savings": -86435,
    "out:Sewage heat recovery cost": 250448,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 462340,
    "out:DH kr savings": 599057,
    "out:El kr savings": -146940,
    "out:El kr sold": 10223,
    "out:El kr saved": 84092,
    "out:El kr return": 94315,
    "out:% solar/total": 60,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 224945,
    "out:% savings (space heating)": 65.84,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 34.81,
    "out:Etvv": 0,
    "out:Ef": 32.71,
    "out:heatPumpQuantity": 10,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000201010001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 40.17,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 44.07,
    "out:Electricity (inc PV)": 32.57,
    "out:Total Energy Use Pre PV": 84.07,
    "out:Total Energy Use Post PV": 72.57,
    "out:Primary Energy": 80.6,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 34.55,
    "out:CO2 Operational/m2": 11.31,
    "out:Total CO2/m2": 45.86,
    "out:Total CO2 (tons)": 235.86,
    "out:Klimatpaverkan": -93.38,
    "out:Initial Cost/MSEK": 12.77125,
    "out:Running cost/(Apt SEK y)": 19801,
    "out:Running Cost over RSP/MSEK": 6.492,
    "out:LCP/MSEK": 0.006,
    "out:ROI% old": 14.29,
    "out:Payback discounted": 8,
    "out:Atemp": 5143,
    "out:Umean": 0.29,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 73,
    "out:Energy use kWh/m2": 73,
    "out:Energy savings %": 157.5,
    "out:Op energy cost/MSEK": 47,
    "out:El price/MSEK": 33,
    "out:Heating price/MSEK": 11,
    "out:DH+ DHW/kWh/m2": 40,
    "out:El bought/kWh/m2": 2438,
    "out:Return %": 13,
    "out:Return/kSEK/y": 1632,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 1479000,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 73950,
    "out:EAHP (replacement cost)": 1105400,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 25.87,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 61.265983,
    "out:PV (m2 panels)": 321,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 57484.06,
    "out:PV (kWh sold)": 8073.06,
    "out:PV (% sold (El))": 14.04,
    "out:PV (kWh self-consumed)": 49410.99,
    "out:PV (ratio sold/self-consumed)": 0.16,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 678876,
    "out:EL kWh savings": -85711,
    "out:Sewage heat recovery cost": 250448,
    "out:Balancing hydronic system cost": 128574.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 482362,
    "out:DH kr savings": 617777,
    "out:El kr savings": -145708,
    "out:El kr sold": 10293,
    "out:El kr saved": 83999,
    "out:El kr return": 94292,
    "out:% solar/total": 60,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 206582,
    "out:% savings (space heating)": 68.63,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 31.4,
    "out:Etvv": 0,
    "out:Ef": 32.57,
    "out:heatPumpQuantity": 10,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000201020000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 47.67,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 44.96,
    "out:Electricity (inc PV)": 29.97,
    "out:Total Energy Use Pre PV": 92.96,
    "out:Total Energy Use Post PV": 77.97,
    "out:Primary Energy": 79.75,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 46.5,
    "out:CO2 Operational/m2": -24.95,
    "out:Total CO2/m2": 21.56,
    "out:Total CO2 (tons)": 110.86,
    "out:Klimatpaverkan": -218.38,
    "out:Initial Cost/MSEK": 13.46125,
    "out:Running cost/(Apt SEK y)": 20635,
    "out:Running Cost over RSP/MSEK": 6.782,
    "out:LCP/MSEK": -0.975,
    "out:ROI% old": 13.25,
    "out:Payback discounted": 9,
    "out:Atemp": 5143,
    "out:Umean": 0.29,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 78,
    "out:Energy use kWh/m2": 78,
    "out:Energy savings %": 141,
    "out:Op energy cost/MSEK": 49,
    "out:El price/MSEK": 33,
    "out:Heating price/MSEK": 14,
    "out:DH+ DHW/kWh/m2": 48,
    "out:El bought/kWh/m2": 2251.7,
    "out:Return %": 12,
    "out:Return/kSEK/y": 1597,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 1479000,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 73950,
    "out:EAHP (replacement cost)": 1105400,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 26.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.33,
    "out:PV (kWp installed)": 122.531965,
    "out:PV (m2 panels)": 643,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 114968.11,
    "out:PV (kWh sold)": 47555.53,
    "out:PV (% sold (El))": 41.36,
    "out:PV (kWh self-consumed)": 67412.58,
    "out:PV (ratio sold/self-consumed)": 0.71,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 637732,
    "out:EL kWh savings": -72368,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 517944,
    "out:DH kr savings": 580336,
    "out:El kr savings": -123026,
    "out:El kr sold": 60633,
    "out:El kr saved": 114601,
    "out:El kr return": 175235,
    "out:% solar/total": 82,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 245181,
    "out:% savings (space heating)": 62.77,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 36.87,
    "out:Etvv": 0,
    "out:Ef": 29.97,
    "out:heatPumpQuantity": 10,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000201020000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 44.05,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 44.87,
    "out:Electricity (inc PV)": 29.85,
    "out:Total Energy Use Pre PV": 88.87,
    "out:Total Energy Use Post PV": 73.85,
    "out:Primary Energy": 77.13,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 46.5,
    "out:CO2 Operational/m2": -26.49,
    "out:Total CO2/m2": 20.02,
    "out:Total CO2 (tons)": 102.94,
    "out:Klimatpaverkan": -226.3,
    "out:Initial Cost/MSEK": 13.59,
    "out:Running cost/(Apt SEK y)": 19329,
    "out:Running Cost over RSP/MSEK": 6.353,
    "out:LCP/MSEK": -0.674,
    "out:ROI% old": 13.58,
    "out:Payback discounted": 9,
    "out:Atemp": 5143,
    "out:Umean": 0.29,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 74,
    "out:Energy use kWh/m2": 74,
    "out:Energy savings %": 154.1,
    "out:Op energy cost/MSEK": 46,
    "out:El price/MSEK": 32,
    "out:Heating price/MSEK": 13,
    "out:DH+ DHW/kWh/m2": 44,
    "out:El bought/kWh/m2": 2246.5,
    "out:Return %": 12,
    "out:Return/kSEK/y": 1652,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 1479000,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 73950,
    "out:EAHP (replacement cost)": 1105400,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 26.67,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.33,
    "out:PV (kWp installed)": 122.531965,
    "out:PV (m2 panels)": 643,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 114968.11,
    "out:PV (kWh sold)": 47645.55,
    "out:PV (% sold (El))": 41.44,
    "out:PV (kWh self-consumed)": 67322.56,
    "out:PV (ratio sold/self-consumed)": 0.71,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 658304,
    "out:EL kWh savings": -71770,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 128574.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 537796,
    "out:DH kr savings": 599057,
    "out:El kr savings": -122009,
    "out:El kr sold": 60748,
    "out:El kr saved": 114448,
    "out:El kr return": 175196,
    "out:% solar/total": 82,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 226526,
    "out:% savings (space heating)": 65.6,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 33.41,
    "out:Etvv": 0,
    "out:Ef": 29.85,
    "out:heatPumpQuantity": 10,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000201020001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 43.74,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 44.18,
    "out:Electricity (inc PV)": 29.56,
    "out:Total Energy Use Pre PV": 88.18,
    "out:Total Energy Use Post PV": 73.56,
    "out:Primary Energy": 77.57,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 46.91,
    "out:CO2 Operational/m2": -27.24,
    "out:Total CO2/m2": 19.66,
    "out:Total CO2 (tons)": 101.13,
    "out:Klimatpaverkan": -228.11,
    "out:Initial Cost/MSEK": 13.7125,
    "out:Running cost/(Apt SEK y)": 19192,
    "out:Running Cost over RSP/MSEK": 6.309,
    "out:LCP/MSEK": -0.752,
    "out:ROI% old": 13.5,
    "out:Payback discounted": 9,
    "out:Atemp": 5143,
    "out:Umean": 0.29,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 74,
    "out:Energy use kWh/m2": 74,
    "out:Energy savings %": 154.1,
    "out:Op energy cost/MSEK": 46,
    "out:El price/MSEK": 31,
    "out:Heating price/MSEK": 13,
    "out:DH+ DHW/kWh/m2": 44,
    "out:El bought/kWh/m2": 2206.8,
    "out:Return %": 12,
    "out:Return/kSEK/y": 1658,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 1479000,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 73950,
    "out:EAHP (replacement cost)": 1105400,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 25.98,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.33,
    "out:PV (kWp installed)": 122.531965,
    "out:PV (m2 panels)": 643,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 114968.11,
    "out:PV (kWh sold)": 48324.96,
    "out:PV (% sold (El))": 42.03,
    "out:PV (kWh self-consumed)": 66643.15,
    "out:PV (ratio sold/self-consumed)": 0.73,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 658304,
    "out:EL kWh savings": -70249,
    "out:Sewage heat recovery cost": 250448,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 541247,
    "out:DH kr savings": 599057,
    "out:El kr savings": -119424,
    "out:El kr sold": 61614,
    "out:El kr saved": 113293,
    "out:El kr return": 174908,
    "out:% solar/total": 81,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 224945,
    "out:% savings (space heating)": 65.84,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 34.81,
    "out:Etvv": 0,
    "out:Ef": 29.56,
    "out:heatPumpQuantity": 10,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000201020001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 40.17,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 44.07,
    "out:Electricity (inc PV)": 29.43,
    "out:Total Energy Use Pre PV": 84.07,
    "out:Total Energy Use Post PV": 69.43,
    "out:Primary Energy": 74.96,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 46.91,
    "out:CO2 Operational/m2": -28.8,
    "out:Total CO2/m2": 18.1,
    "out:Total CO2 (tons)": 93.11,
    "out:Klimatpaverkan": -236.13,
    "out:Initial Cost/MSEK": 13.84125,
    "out:Running cost/(Apt SEK y)": 17883,
    "out:Running Cost over RSP/MSEK": 5.879,
    "out:LCP/MSEK": -0.451,
    "out:ROI% old": 13.82,
    "out:Payback discounted": 9,
    "out:Atemp": 5143,
    "out:Umean": 0.29,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 69,
    "out:Energy use kWh/m2": 69,
    "out:Energy savings %": 172.5,
    "out:Op energy cost/MSEK": 43,
    "out:El price/MSEK": 29,
    "out:Heating price/MSEK": 11,
    "out:DH+ DHW/kWh/m2": 40,
    "out:El bought/kWh/m2": 2200.6,
    "out:Return %": 12,
    "out:Return/kSEK/y": 1713,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 1479000,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 73950,
    "out:EAHP (replacement cost)": 1105400,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 25.87,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.33,
    "out:PV (kWp installed)": 122.531965,
    "out:PV (m2 panels)": 643,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 114968.11,
    "out:PV (kWh sold)": 48432.85,
    "out:PV (% sold (El))": 42.13,
    "out:PV (kWh self-consumed)": 66535.27,
    "out:PV (ratio sold/self-consumed)": 0.73,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 678876,
    "out:EL kWh savings": -69600,
    "out:Sewage heat recovery cost": 250448,
    "out:Balancing hydronic system cost": 128574.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 561209,
    "out:DH kr savings": 617777,
    "out:El kr savings": -118320,
    "out:El kr sold": 61752,
    "out:El kr saved": 113110,
    "out:El kr return": 174862,
    "out:% solar/total": 81,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 206582,
    "out:% savings (space heating)": 68.63,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 31.4,
    "out:Etvv": 0,
    "out:Ef": 29.43,
    "out:heatPumpQuantity": 10,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000201100000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 42.46,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 43.9,
    "out:Electricity (inc PV)": 41.94,
    "out:Total Energy Use Pre PV": 85.9,
    "out:Total Energy Use Post PV": 83.94,
    "out:Primary Energy": 99.39,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 21.8,
    "out:CO2 Operational/m2": 20.92,
    "out:Total CO2/m2": 42.71,
    "out:Total CO2 (tons)": 219.67,
    "out:Klimatpaverkan": -109.57,
    "out:Initial Cost/MSEK": 11.433125,
    "out:Running cost/(Apt SEK y)": 22656,
    "out:Running Cost over RSP/MSEK": 7.41,
    "out:LCP/MSEK": 0.426,
    "out:ROI% old": 14.82,
    "out:Payback discounted": 8,
    "out:Atemp": 5143,
    "out:Umean": 0.29,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 84,
    "out:Energy use kWh/m2": 84,
    "out:Energy savings %": 123.8,
    "out:Op energy cost/MSEK": 52,
    "out:El price/MSEK": 38,
    "out:Heating price/MSEK": 12,
    "out:DH+ DHW/kWh/m2": 42,
    "out:El bought/kWh/m2": 3073.3,
    "out:Return %": 13,
    "out:Return/kSEK/y": 1512,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 1479000,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 73950,
    "out:EAHP (replacement cost)": 1105400,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 25.71,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 668590,
    "out:EL kWh savings": -133937,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 380723,
    "out:DH kr savings": 608417,
    "out:El kr savings": -227694,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 218360,
    "out:% savings (space heating)": 66.84,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 34.13,
    "out:Etvv": 0,
    "out:Ef": 41.94,
    "out:heatPumpQuantity": 10,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000201100000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 38.91,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 43.79,
    "out:Electricity (inc PV)": 41.78,
    "out:Total Energy Use Pre PV": 82.79,
    "out:Total Energy Use Post PV": 80.78,
    "out:Primary Energy": 96.72,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 21.8,
    "out:CO2 Operational/m2": 19.82,
    "out:Total CO2/m2": 41.62,
    "out:Total CO2 (tons)": 214.03,
    "out:Klimatpaverkan": -115.21,
    "out:Initial Cost/MSEK": 11.56175,
    "out:Running cost/(Apt SEK y)": 21667,
    "out:Running Cost over RSP/MSEK": 7.085,
    "out:LCP/MSEK": 0.622,
    "out:ROI% old": 15.05,
    "out:Payback discounted": 8,
    "out:Atemp": 5143,
    "out:Umean": 0.29,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 81,
    "out:Energy use kWh/m2": 81,
    "out:Energy savings %": 132.1,
    "out:Op energy cost/MSEK": 50,
    "out:El price/MSEK": 37,
    "out:Heating price/MSEK": 11,
    "out:DH+ DHW/kWh/m2": 39,
    "out:El bought/kWh/m2": 3065.3,
    "out:Return %": 13,
    "out:Return/kSEK/y": 1554,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 1479000,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 73950,
    "out:EAHP (replacement cost)": 1105400,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 25.59,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 684019,
    "out:EL kWh savings": -133106,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 128574.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 396177,
    "out:DH kr savings": 622457,
    "out:El kr savings": -226280,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 200096,
    "out:% savings (space heating)": 69.62,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 30.74,
    "out:Etvv": 0,
    "out:Ef": 41.78,
    "out:heatPumpQuantity": 10,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000201100001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 39.44,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 43.24,
    "out:Electricity (inc PV)": 41.52,
    "out:Total Energy Use Pre PV": 82.24,
    "out:Total Energy Use Post PV": 80.52,
    "out:Primary Energy": 97.51,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 22.2,
    "out:CO2 Operational/m2": 19.75,
    "out:Total CO2/m2": 41.95,
    "out:Total CO2 (tons)": 215.73,
    "out:Klimatpaverkan": -113.51,
    "out:Initial Cost/MSEK": 11.683625,
    "out:Running cost/(Apt SEK y)": 21552,
    "out:Running Cost over RSP/MSEK": 7.048,
    "out:LCP/MSEK": 0.538,
    "out:ROI% old": 14.94,
    "out:Payback discounted": 8,
    "out:Atemp": 5143,
    "out:Umean": 0.29,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 81,
    "out:Energy use kWh/m2": 81,
    "out:Energy savings %": 132.1,
    "out:Op energy cost/MSEK": 50,
    "out:El price/MSEK": 37,
    "out:Heating price/MSEK": 11,
    "out:DH+ DHW/kWh/m2": 39,
    "out:El bought/kWh/m2": 3026.5,
    "out:Return %": 13,
    "out:Return/kSEK/y": 1559,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 1479000,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 73950,
    "out:EAHP (replacement cost)": 1105400,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 25.04,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 684019,
    "out:EL kWh savings": -131759,
    "out:Sewage heat recovery cost": 250448,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 398466,
    "out:DH kr savings": 622457,
    "out:El kr savings": -223991,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 202859,
    "out:% savings (space heating)": 69.2,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 32.53,
    "out:Etvv": 0,
    "out:Ef": 41.52,
    "out:heatPumpQuantity": 10,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000201100001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 35.94,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 43.11,
    "out:Electricity (inc PV)": 41.34,
    "out:Total Energy Use Pre PV": 79.11,
    "out:Total Energy Use Post PV": 77.34,
    "out:Primary Energy": 94.84,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 22.2,
    "out:CO2 Operational/m2": 18.65,
    "out:Total CO2/m2": 40.85,
    "out:Total CO2 (tons)": 210.08,
    "out:Klimatpaverkan": -119.16,
    "out:Initial Cost/MSEK": 11.812125,
    "out:Running cost/(Apt SEK y)": 20559,
    "out:Running Cost over RSP/MSEK": 6.722,
    "out:LCP/MSEK": 0.735,
    "out:ROI% old": 15.17,
    "out:Payback discounted": 8,
    "out:Atemp": 5143,
    "out:Umean": 0.29,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 77,
    "out:Energy use kWh/m2": 77,
    "out:Energy savings %": 144.2,
    "out:Op energy cost/MSEK": 47,
    "out:El price/MSEK": 35,
    "out:Heating price/MSEK": 10,
    "out:DH+ DHW/kWh/m2": 36,
    "out:El bought/kWh/m2": 3017.5,
    "out:Return %": 14,
    "out:Return/kSEK/y": 1600,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 1479000,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 73950,
    "out:EAHP (replacement cost)": 1105400,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 24.91,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 699448,
    "out:EL kWh savings": -130857,
    "out:Sewage heat recovery cost": 250448,
    "out:Balancing hydronic system cost": 128574.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 414041,
    "out:DH kr savings": 636498,
    "out:El kr savings": -222457,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 184835,
    "out:% savings (space heating)": 71.93,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 29.18,
    "out:Etvv": 0,
    "out:Ef": 41.34,
    "out:heatPumpQuantity": 10,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000201110000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 42.46,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 43.9,
    "out:Electricity (inc PV)": 32.55,
    "out:Total Energy Use Pre PV": 85.9,
    "out:Total Energy Use Post PV": 74.55,
    "out:Primary Energy": 82.48,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 34.15,
    "out:CO2 Operational/m2": 11.93,
    "out:Total CO2/m2": 46.08,
    "out:Total CO2 (tons)": 236.99,
    "out:Klimatpaverkan": -92.25,
    "out:Initial Cost/MSEK": 12.5025,
    "out:Running cost/(Apt SEK y)": 20412,
    "out:Running Cost over RSP/MSEK": 6.693,
    "out:LCP/MSEK": 0.074,
    "out:ROI% old": 14.37,
    "out:Payback discounted": 8,
    "out:Atemp": 5143,
    "out:Umean": 0.29,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 75,
    "out:Energy use kWh/m2": 75,
    "out:Energy savings %": 150.7,
    "out:Op energy cost/MSEK": 48,
    "out:El price/MSEK": 34,
    "out:Heating price/MSEK": 12,
    "out:DH+ DHW/kWh/m2": 42,
    "out:El bought/kWh/m2": 2427.6,
    "out:Return %": 13,
    "out:Return/kSEK/y": 1607,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 1479000,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 73950,
    "out:EAHP (replacement cost)": 1105400,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 25.71,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 61.265983,
    "out:PV (m2 panels)": 321,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 57484.06,
    "out:PV (kWh sold)": 8155,
    "out:PV (% sold (El))": 14.19,
    "out:PV (kWh self-consumed)": 49329.05,
    "out:PV (ratio sold/self-consumed)": 0.17,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 668590,
    "out:EL kWh savings": -85634,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 473237,
    "out:DH kr savings": 608417,
    "out:El kr savings": -145577,
    "out:El kr sold": 10398,
    "out:El kr saved": 83859,
    "out:El kr return": 94257,
    "out:% solar/total": 60,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 218360,
    "out:% savings (space heating)": 66.84,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 34.13,
    "out:Etvv": 0,
    "out:Ef": 32.55,
    "out:heatPumpQuantity": 10,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000201110000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 38.91,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 43.79,
    "out:Electricity (inc PV)": 32.41,
    "out:Total Energy Use Pre PV": 82.79,
    "out:Total Energy Use Post PV": 71.41,
    "out:Primary Energy": 79.85,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 34.15,
    "out:CO2 Operational/m2": 10.78,
    "out:Total CO2/m2": 44.93,
    "out:Total CO2 (tons)": 231.07,
    "out:Klimatpaverkan": -98.17,
    "out:Initial Cost/MSEK": 12.63125,
    "out:Running cost/(Apt SEK y)": 19423,
    "out:Running Cost over RSP/MSEK": 6.368,
    "out:LCP/MSEK": 0.27,
    "out:ROI% old": 14.59,
    "out:Payback discounted": 8,
    "out:Atemp": 5143,
    "out:Umean": 0.29,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 71,
    "out:Energy use kWh/m2": 71,
    "out:Energy savings %": 164.8,
    "out:Op energy cost/MSEK": 46,
    "out:El price/MSEK": 33,
    "out:Heating price/MSEK": 11,
    "out:DH+ DHW/kWh/m2": 39,
    "out:El bought/kWh/m2": 2420.2,
    "out:Return %": 13,
    "out:Return/kSEK/y": 1648,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 1479000,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 73950,
    "out:EAHP (replacement cost)": 1105400,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 25.59,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 61.265983,
    "out:PV (m2 panels)": 321,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 57484.06,
    "out:PV (kWh sold)": 8213.5,
    "out:PV (% sold (El))": 14.29,
    "out:PV (kWh self-consumed)": 49270.56,
    "out:PV (ratio sold/self-consumed)": 0.17,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 684019,
    "out:EL kWh savings": -84889,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 128574.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 488617,
    "out:DH kr savings": 622457,
    "out:El kr savings": -144312,
    "out:El kr sold": 10472,
    "out:El kr saved": 83760,
    "out:El kr return": 94232,
    "out:% solar/total": 60,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 200096,
    "out:% savings (space heating)": 69.62,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 30.74,
    "out:Etvv": 0,
    "out:Ef": 32.41,
    "out:heatPumpQuantity": 10,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000201110001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 39.44,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 43.24,
    "out:Electricity (inc PV)": 32.17,
    "out:Total Energy Use Pre PV": 82.24,
    "out:Total Energy Use Post PV": 71.17,
    "out:Primary Energy": 80.68,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 34.55,
    "out:CO2 Operational/m2": 10.43,
    "out:Total CO2/m2": 44.99,
    "out:Total CO2 (tons)": 231.37,
    "out:Klimatpaverkan": -97.87,
    "out:Initial Cost/MSEK": 12.75375,
    "out:Running cost/(Apt SEK y)": 19311,
    "out:Running Cost over RSP/MSEK": 6.332,
    "out:LCP/MSEK": 0.184,
    "out:ROI% old": 14.49,
    "out:Payback discounted": 8,
    "out:Atemp": 5143,
    "out:Umean": 0.29,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 71,
    "out:Energy use kWh/m2": 71,
    "out:Energy savings %": 164.8,
    "out:Op energy cost/MSEK": 45,
    "out:El price/MSEK": 32,
    "out:Heating price/MSEK": 11,
    "out:DH+ DHW/kWh/m2": 39,
    "out:El bought/kWh/m2": 2384.9,
    "out:Return %": 13,
    "out:Return/kSEK/y": 1653,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 1479000,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 73950,
    "out:EAHP (replacement cost)": 1105400,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 25.04,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 61.265983,
    "out:PV (m2 panels)": 321,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 57484.06,
    "out:PV (kWh sold)": 8497.84,
    "out:PV (% sold (El))": 14.78,
    "out:PV (kWh self-consumed)": 48986.21,
    "out:PV (ratio sold/self-consumed)": 0.17,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 684019,
    "out:EL kWh savings": -83685,
    "out:Sewage heat recovery cost": 250448,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 491027,
    "out:DH kr savings": 622457,
    "out:El kr savings": -142265,
    "out:El kr sold": 10835,
    "out:El kr saved": 83277,
    "out:El kr return": 94111,
    "out:% solar/total": 60,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 202859,
    "out:% savings (space heating)": 69.2,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 32.53,
    "out:Etvv": 0,
    "out:Ef": 32.17,
    "out:heatPumpQuantity": 10,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000201110001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 35.94,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 43.11,
    "out:Electricity (inc PV)": 32.02,
    "out:Total Energy Use Pre PV": 79.11,
    "out:Total Energy Use Post PV": 68.02,
    "out:Primary Energy": 78.05,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 34.55,
    "out:CO2 Operational/m2": 9.27,
    "out:Total CO2/m2": 43.83,
    "out:Total CO2 (tons)": 225.39,
    "out:Klimatpaverkan": -103.85,
    "out:Initial Cost/MSEK": 12.8825,
    "out:Running cost/(Apt SEK y)": 18319,
    "out:Running Cost over RSP/MSEK": 6.006,
    "out:LCP/MSEK": 0.381,
    "out:ROI% old": 14.71,
    "out:Payback discounted": 8,
    "out:Atemp": 5143,
    "out:Umean": 0.29,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 68,
    "out:Energy use kWh/m2": 68,
    "out:Energy savings %": 176.5,
    "out:Op energy cost/MSEK": 43,
    "out:El price/MSEK": 31,
    "out:Heating price/MSEK": 10,
    "out:DH+ DHW/kWh/m2": 36,
    "out:El bought/kWh/m2": 2376.7,
    "out:Return %": 13,
    "out:Return/kSEK/y": 1694,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 1479000,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 73950,
    "out:EAHP (replacement cost)": 1105400,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 24.91,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 61.265983,
    "out:PV (m2 panels)": 321,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 57484.06,
    "out:PV (kWh sold)": 8564.8,
    "out:PV (% sold (El))": 14.9,
    "out:PV (kWh self-consumed)": 48919.25,
    "out:PV (ratio sold/self-consumed)": 0.18,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 699448,
    "out:EL kWh savings": -82880,
    "out:Sewage heat recovery cost": 250448,
    "out:Balancing hydronic system cost": 128574.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 506522,
    "out:DH kr savings": 636498,
    "out:El kr savings": -140895,
    "out:El kr sold": 10920,
    "out:El kr saved": 83163,
    "out:El kr return": 94083,
    "out:% solar/total": 60,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 184835,
    "out:% savings (space heating)": 71.93,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 29.18,
    "out:Etvv": 0,
    "out:Ef": 32.02,
    "out:heatPumpQuantity": 10,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000201120000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 42.46,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 43.9,
    "out:Electricity (inc PV)": 29.42,
    "out:Total Energy Use Pre PV": 85.9,
    "out:Total Energy Use Post PV": 71.42,
    "out:Primary Energy": 76.85,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 46.5,
    "out:CO2 Operational/m2": -28.26,
    "out:Total CO2/m2": 18.24,
    "out:Total CO2 (tons)": 93.83,
    "out:Klimatpaverkan": -235.41,
    "out:Initial Cost/MSEK": 13.5725,
    "out:Running cost/(Apt SEK y)": 18495,
    "out:Running Cost over RSP/MSEK": 6.08,
    "out:LCP/MSEK": -0.383,
    "out:ROI% old": 13.88,
    "out:Payback discounted": 9,
    "out:Atemp": 5143,
    "out:Umean": 0.29,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 71,
    "out:Energy use kWh/m2": 71,
    "out:Energy savings %": 164.8,
    "out:Op energy cost/MSEK": 44,
    "out:El price/MSEK": 30,
    "out:Heating price/MSEK": 12,
    "out:DH+ DHW/kWh/m2": 42,
    "out:El bought/kWh/m2": 2191.4,
    "out:Return %": 12,
    "out:Return/kSEK/y": 1687,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 1479000,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 73950,
    "out:EAHP (replacement cost)": 1105400,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 25.71,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.33,
    "out:PV (kWp installed)": 122.531965,
    "out:PV (m2 panels)": 643,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 114968.11,
    "out:PV (kWh sold)": 48592.88,
    "out:PV (% sold (El))": 42.27,
    "out:PV (kWh self-consumed)": 66375.23,
    "out:PV (ratio sold/self-consumed)": 0.73,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 668590,
    "out:EL kWh savings": -69531,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 552170,
    "out:DH kr savings": 608417,
    "out:El kr savings": -118203,
    "out:El kr sold": 61956,
    "out:El kr saved": 112838,
    "out:El kr return": 174794,
    "out:% solar/total": 81,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 218360,
    "out:% savings (space heating)": 66.84,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 34.13,
    "out:Etvv": 0,
    "out:Ef": 29.42,
    "out:heatPumpQuantity": 10,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000201120000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 38.91,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 43.79,
    "out:Electricity (inc PV)": 29.29,
    "out:Total Energy Use Pre PV": 82.79,
    "out:Total Energy Use Post PV": 68.29,
    "out:Primary Energy": 74.24,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 46.5,
    "out:CO2 Operational/m2": -29.46,
    "out:Total CO2/m2": 17.04,
    "out:Total CO2 (tons)": 87.63,
    "out:Klimatpaverkan": -241.61,
    "out:Initial Cost/MSEK": 13.70125,
    "out:Running cost/(Apt SEK y)": 17506,
    "out:Running Cost over RSP/MSEK": 5.755,
    "out:LCP/MSEK": -0.187,
    "out:ROI% old": 14.09,
    "out:Payback discounted": 9,
    "out:Atemp": 5143,
    "out:Umean": 0.29,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 68,
    "out:Energy use kWh/m2": 68,
    "out:Energy savings %": 176.5,
    "out:Op energy cost/MSEK": 42,
    "out:El price/MSEK": 29,
    "out:Heating price/MSEK": 11,
    "out:DH+ DHW/kWh/m2": 39,
    "out:El bought/kWh/m2": 2184.8,
    "out:Return %": 13,
    "out:Return/kSEK/y": 1729,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 1479000,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 73950,
    "out:EAHP (replacement cost)": 1105400,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 25.59,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.33,
    "out:PV (kWp installed)": 122.531965,
    "out:PV (m2 panels)": 643,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 114968.11,
    "out:PV (kWh sold)": 48707.04,
    "out:PV (% sold (El))": 42.37,
    "out:PV (kWh self-consumed)": 66261.07,
    "out:PV (ratio sold/self-consumed)": 0.74,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 684019,
    "out:EL kWh savings": -68864,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 128574.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 567490,
    "out:DH kr savings": 622457,
    "out:El kr savings": -117069,
    "out:El kr sold": 62101,
    "out:El kr saved": 112644,
    "out:El kr return": 174745,
    "out:% solar/total": 81,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 200096,
    "out:% savings (space heating)": 69.62,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 30.74,
    "out:Etvv": 0,
    "out:Ef": 29.29,
    "out:heatPumpQuantity": 10,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000201120001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 39.44,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 43.24,
    "out:Electricity (inc PV)": 29.08,
    "out:Total Energy Use Pre PV": 82.24,
    "out:Total Energy Use Post PV": 68.08,
    "out:Primary Energy": 75.12,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 46.91,
    "out:CO2 Operational/m2": -30.07,
    "out:Total CO2/m2": 16.83,
    "out:Total CO2 (tons)": 86.57,
    "out:Klimatpaverkan": -242.67,
    "out:Initial Cost/MSEK": 13.82375,
    "out:Running cost/(Apt SEK y)": 17397,
    "out:Running Cost over RSP/MSEK": 5.719,
    "out:LCP/MSEK": -0.274,
    "out:ROI% old": 14,
    "out:Payback discounted": 9,
    "out:Atemp": 5143,
    "out:Umean": 0.29,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 68,
    "out:Energy use kWh/m2": 68,
    "out:Energy savings %": 176.5,
    "out:Op energy cost/MSEK": 42,
    "out:El price/MSEK": 29,
    "out:Heating price/MSEK": 11,
    "out:DH+ DHW/kWh/m2": 39,
    "out:El bought/kWh/m2": 2153.2,
    "out:Return %": 13,
    "out:Return/kSEK/y": 1733,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 1479000,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 73950,
    "out:EAHP (replacement cost)": 1105400,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 25.04,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.33,
    "out:PV (kWp installed)": 122.531965,
    "out:PV (m2 panels)": 643,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 114968.11,
    "out:PV (kWh sold)": 49257.83,
    "out:PV (% sold (El))": 42.84,
    "out:PV (kWh self-consumed)": 65710.28,
    "out:PV (ratio sold/self-consumed)": 0.75,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 684019,
    "out:EL kWh savings": -67785,
    "out:Sewage heat recovery cost": 250448,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 570027,
    "out:DH kr savings": 622457,
    "out:El kr savings": -115234,
    "out:El kr sold": 62804,
    "out:El kr saved": 111707,
    "out:El kr return": 174511,
    "out:% solar/total": 80,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 202859,
    "out:% savings (space heating)": 69.2,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 32.53,
    "out:Etvv": 0,
    "out:Ef": 29.08,
    "out:heatPumpQuantity": 10,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000201120001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 35.94,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 43.11,
    "out:Electricity (inc PV)": 28.94,
    "out:Total Energy Use Pre PV": 79.11,
    "out:Total Energy Use Post PV": 64.94,
    "out:Primary Energy": 72.52,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 46.91,
    "out:CO2 Operational/m2": -31.3,
    "out:Total CO2/m2": 15.61,
    "out:Total CO2 (tons)": 80.28,
    "out:Klimatpaverkan": -248.96,
    "out:Initial Cost/MSEK": 13.95125,
    "out:Running cost/(Apt SEK y)": 16406,
    "out:Running Cost over RSP/MSEK": 5.394,
    "out:LCP/MSEK": -0.076,
    "out:ROI% old": 14.21,
    "out:Payback discounted": 9,
    "out:Atemp": 5143,
    "out:Umean": 0.29,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 65,
    "out:Energy use kWh/m2": 65,
    "out:Energy savings %": 189.2,
    "out:Op energy cost/MSEK": 39,
    "out:El price/MSEK": 27,
    "out:Heating price/MSEK": 10,
    "out:DH+ DHW/kWh/m2": 36,
    "out:El bought/kWh/m2": 2145.9,
    "out:Return %": 13,
    "out:Return/kSEK/y": 1775,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 1479000,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 73950,
    "out:EAHP (replacement cost)": 1105400,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 24.91,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.33,
    "out:PV (kWp installed)": 122.531965,
    "out:PV (m2 panels)": 643,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 114968.11,
    "out:PV (kWh sold)": 49387.07,
    "out:PV (% sold (El))": 42.96,
    "out:PV (kWh self-consumed)": 65581.04,
    "out:PV (ratio sold/self-consumed)": 0.75,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 699448,
    "out:EL kWh savings": -67062,
    "out:Sewage heat recovery cost": 250448,
    "out:Balancing hydronic system cost": 128574.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 585461,
    "out:DH kr savings": 636498,
    "out:El kr savings": -114005,
    "out:El kr sold": 62969,
    "out:El kr saved": 111488,
    "out:El kr return": 174456,
    "out:% solar/total": 80,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 184835,
    "out:% savings (space heating)": 71.93,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 29.18,
    "out:Etvv": 0,
    "out:Ef": 28.94,
    "out:heatPumpQuantity": 10,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000202000000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 4.91,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 57.92,
    "out:Electricity (inc PV)": 53.03,
    "out:Total Energy Use Pre PV": 62.92,
    "out:Total Energy Use Post PV": 58.03,
    "out:Primary Energy": 97.24,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 22.36,
    "out:CO2 Operational/m2": 9.45,
    "out:Total CO2/m2": 31.8,
    "out:Total CO2 (tons)": 163.56,
    "out:Klimatpaverkan": -165.68,
    "out:Initial Cost/MSEK": 13.97375,
    "out:Running cost/(Apt SEK y)": 13666,
    "out:Running Cost over RSP/MSEK": 4.444,
    "out:LCP/MSEK": 0.852,
    "out:ROI% old": 15.16,
    "out:Payback discounted": 8,
    "out:Atemp": 5143,
    "out:Umean": 0.29,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 58,
    "out:Energy use kWh/m2": 58,
    "out:Energy savings %": 224.1,
    "out:Op energy cost/MSEK": 30,
    "out:El price/MSEK": 28,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 5,
    "out:El bought/kWh/m2": 4054.3,
    "out:Return %": 14,
    "out:Return/kSEK/y": 1890,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 2958000,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 147900,
    "out:EAHP (replacement cost)": 2204400,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 39.72,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 858881,
    "out:EL kWh savings": -190961,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 456949,
    "out:DH kr savings": 781582,
    "out:El kr savings": -324633,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 25260,
    "out:% savings (space heating)": 96.16,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 2.54,
    "out:Etvv": 0,
    "out:Ef": 53.03,
    "out:heatPumpQuantity": 20,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000202000000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 3.62,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 57.12,
    "out:Electricity (inc PV)": 52.08,
    "out:Total Energy Use Pre PV": 61.12,
    "out:Total Energy Use Post PV": 56.08,
    "out:Primary Energy": 94.97,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 22.36,
    "out:CO2 Operational/m2": 8.98,
    "out:Total CO2/m2": 31.34,
    "out:Total CO2 (tons)": 161.17,
    "out:Klimatpaverkan": -168.07,
    "out:Initial Cost/MSEK": 14.1025,
    "out:Running cost/(Apt SEK y)": 13178,
    "out:Running Cost over RSP/MSEK": 4.284,
    "out:LCP/MSEK": 0.883,
    "out:ROI% old": 15.18,
    "out:Payback discounted": 8,
    "out:Atemp": 5143,
    "out:Umean": 0.29,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 56,
    "out:Energy use kWh/m2": 56,
    "out:Energy savings %": 235.7,
    "out:Op energy cost/MSEK": 29,
    "out:El price/MSEK": 27,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 4,
    "out:El bought/kWh/m2": 3998.3,
    "out:Return %": 14,
    "out:Return/kSEK/y": 1910,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 2958000,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 147900,
    "out:EAHP (replacement cost)": 2204400,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 38.92,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 864024,
    "out:EL kWh savings": -186066,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 128574.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 469949,
    "out:DH kr savings": 786262,
    "out:El kr savings": -316313,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 18619,
    "out:% savings (space heating)": 97.17,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 1.75,
    "out:Etvv": 0,
    "out:Ef": 52.08,
    "out:heatPumpQuantity": 20,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000202000001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 3.91,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 56.25,
    "out:Electricity (inc PV)": 52,
    "out:Total Energy Use Pre PV": 60.25,
    "out:Total Energy Use Post PV": 56,
    "out:Primary Energy": 95.14,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 22.76,
    "out:CO2 Operational/m2": 8.86,
    "out:Total CO2/m2": 31.62,
    "out:Total CO2 (tons)": 162.65,
    "out:Klimatpaverkan": -166.59,
    "out:Initial Cost/MSEK": 14.225,
    "out:Running cost/(Apt SEK y)": 12996,
    "out:Running Cost over RSP/MSEK": 4.225,
    "out:LCP/MSEK": 0.819,
    "out:ROI% old": 15.11,
    "out:Payback discounted": 8,
    "out:Atemp": 5143,
    "out:Umean": 0.29,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 56,
    "out:Energy use kWh/m2": 56,
    "out:Energy savings %": 235.7,
    "out:Op energy cost/MSEK": 28,
    "out:El price/MSEK": 27,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 4,
    "out:El bought/kWh/m2": 3937.2,
    "out:Return %": 13,
    "out:Return/kSEK/y": 1918,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 2958000,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 147900,
    "out:EAHP (replacement cost)": 2204400,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 38.05,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 864024,
    "out:EL kWh savings": -185648,
    "out:Sewage heat recovery cost": 250448,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 470660,
    "out:DH kr savings": 786262,
    "out:El kr savings": -315602,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 20095,
    "out:% savings (space heating)": 96.95,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 2.2,
    "out:Etvv": 0,
    "out:Ef": 52,
    "out:heatPumpQuantity": 20,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000202000001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 2.84,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 55.38,
    "out:Electricity (inc PV)": 51.03,
    "out:Total Energy Use Pre PV": 58.38,
    "out:Total Energy Use Post PV": 54.03,
    "out:Primary Energy": 92.88,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 22.76,
    "out:CO2 Operational/m2": 8.39,
    "out:Total CO2/m2": 31.15,
    "out:Total CO2 (tons)": 160.2,
    "out:Klimatpaverkan": -169.04,
    "out:Initial Cost/MSEK": 14.35375,
    "out:Running cost/(Apt SEK y)": 12493,
    "out:Running Cost over RSP/MSEK": 4.06,
    "out:LCP/MSEK": 0.855,
    "out:ROI% old": 15.14,
    "out:Payback discounted": 8,
    "out:Atemp": 5143,
    "out:Umean": 0.29,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 54,
    "out:Energy use kWh/m2": 54,
    "out:Energy savings %": 248.1,
    "out:Op energy cost/MSEK": 27,
    "out:El price/MSEK": 26,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 3,
    "out:El bought/kWh/m2": 3876.4,
    "out:Return %": 14,
    "out:Return/kSEK/y": 1939,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 2958000,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 147900,
    "out:EAHP (replacement cost)": 2204400,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 37.18,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 869167,
    "out:EL kWh savings": -180649,
    "out:Sewage heat recovery cost": 250448,
    "out:Balancing hydronic system cost": 128574.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 483838,
    "out:DH kr savings": 790942,
    "out:El kr savings": -307104,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 14616,
    "out:% savings (space heating)": 97.78,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 1.48,
    "out:Etvv": 0,
    "out:Ef": 51.03,
    "out:heatPumpQuantity": 20,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000202010000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 4.91,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 57.92,
    "out:Electricity (inc PV)": 42.7,
    "out:Total Energy Use Pre PV": 62.92,
    "out:Total Energy Use Post PV": 47.7,
    "out:Primary Energy": 78.64,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 34.71,
    "out:CO2 Operational/m2": 5.45,
    "out:Total CO2/m2": 40.16,
    "out:Total CO2 (tons)": 206.56,
    "out:Klimatpaverkan": -122.68,
    "out:Initial Cost/MSEK": 15.04375,
    "out:Running cost/(Apt SEK y)": 11368,
    "out:Running Cost over RSP/MSEK": 3.71,
    "out:LCP/MSEK": 0.516,
    "out:ROI% old": 14.78,
    "out:Payback discounted": 8,
    "out:Atemp": 5143,
    "out:Umean": 0.29,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 48,
    "out:Energy use kWh/m2": 48,
    "out:Energy savings %": 291.7,
    "out:Op energy cost/MSEK": 25,
    "out:El price/MSEK": 24,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 5,
    "out:El bought/kWh/m2": 3347.6,
    "out:Return %": 13,
    "out:Return/kSEK/y": 1986,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 2958000,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 147900,
    "out:EAHP (replacement cost)": 2204400,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 39.72,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 61.265983,
    "out:PV (m2 panels)": 321,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 57484.06,
    "out:PV (kWh sold)": 2874.1,
    "out:PV (% sold (El))": 5,
    "out:PV (kWh self-consumed)": 54609.96,
    "out:PV (ratio sold/self-consumed)": 0.05,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 858881,
    "out:EL kWh savings": -137824,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 550946,
    "out:DH kr savings": 781582,
    "out:El kr savings": -234300,
    "out:El kr sold": 3664,
    "out:El kr saved": 92837,
    "out:El kr return": 96501,
    "out:% solar/total": 67,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 25260,
    "out:% savings (space heating)": 96.16,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 2.54,
    "out:Etvv": 0,
    "out:Ef": 42.7,
    "out:heatPumpQuantity": 20,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000202010000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 3.62,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 57.12,
    "out:Electricity (inc PV)": 41.81,
    "out:Total Energy Use Pre PV": 61.12,
    "out:Total Energy Use Post PV": 45.81,
    "out:Primary Energy": 76.49,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 34.71,
    "out:CO2 Operational/m2": 4.78,
    "out:Total CO2/m2": 39.5,
    "out:Total CO2 (tons)": 203.13,
    "out:Klimatpaverkan": -126.11,
    "out:Initial Cost/MSEK": 15.1725,
    "out:Running cost/(Apt SEK y)": 10882,
    "out:Running Cost over RSP/MSEK": 3.55,
    "out:LCP/MSEK": 0.546,
    "out:ROI% old": 14.8,
    "out:Payback discounted": 8,
    "out:Atemp": 5143,
    "out:Umean": 0.29,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 46,
    "out:Energy use kWh/m2": 46,
    "out:Energy savings %": 308.7,
    "out:Op energy cost/MSEK": 24,
    "out:El price/MSEK": 23,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 4,
    "out:El bought/kWh/m2": 3294,
    "out:Return %": 13,
    "out:Return/kSEK/y": 2007,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 2958000,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 147900,
    "out:EAHP (replacement cost)": 2204400,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 38.92,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 61.265983,
    "out:PV (m2 panels)": 321,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 57484.06,
    "out:PV (kWh sold)": 3090.8,
    "out:PV (% sold (El))": 5.38,
    "out:PV (kWh self-consumed)": 54393.25,
    "out:PV (ratio sold/self-consumed)": 0.06,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 864024,
    "out:EL kWh savings": -133260,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 128574.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 563660,
    "out:DH kr savings": 786262,
    "out:El kr savings": -226542,
    "out:El kr sold": 3941,
    "out:El kr saved": 92469,
    "out:El kr return": 96409,
    "out:% solar/total": 67,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 18619,
    "out:% savings (space heating)": 97.17,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 1.75,
    "out:Etvv": 0,
    "out:Ef": 41.81,
    "out:heatPumpQuantity": 20,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000202010001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 3.91,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 56.25,
    "out:Electricity (inc PV)": 41.74,
    "out:Total Energy Use Pre PV": 60.25,
    "out:Total Energy Use Post PV": 45.74,
    "out:Primary Energy": 76.67,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 35.11,
    "out:CO2 Operational/m2": 4.44,
    "out:Total CO2/m2": 39.55,
    "out:Total CO2 (tons)": 203.41,
    "out:Klimatpaverkan": -125.83,
    "out:Initial Cost/MSEK": 15.295,
    "out:Running cost/(Apt SEK y)": 10703,
    "out:Running Cost over RSP/MSEK": 3.492,
    "out:LCP/MSEK": 0.482,
    "out:ROI% old": 14.74,
    "out:Payback discounted": 8,
    "out:Atemp": 5143,
    "out:Umean": 0.29,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 46,
    "out:Energy use kWh/m2": 46,
    "out:Energy savings %": 308.7,
    "out:Op energy cost/MSEK": 24,
    "out:El price/MSEK": 22,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 4,
    "out:El bought/kWh/m2": 3235.6,
    "out:Return %": 13,
    "out:Return/kSEK/y": 2014,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 2958000,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 147900,
    "out:EAHP (replacement cost)": 2204400,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 38.05,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 61.265983,
    "out:PV (m2 panels)": 321,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 57484.06,
    "out:PV (kWh sold)": 3337.6,
    "out:PV (% sold (El))": 5.81,
    "out:PV (kWh self-consumed)": 54146.45,
    "out:PV (ratio sold/self-consumed)": 0.06,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 864024,
    "out:EL kWh savings": -132871,
    "out:Sewage heat recovery cost": 250448,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 564637,
    "out:DH kr savings": 786262,
    "out:El kr savings": -225881,
    "out:El kr sold": 4255,
    "out:El kr saved": 92049,
    "out:El kr return": 96304,
    "out:% solar/total": 66,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 20095,
    "out:% savings (space heating)": 96.95,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 2.2,
    "out:Etvv": 0,
    "out:Ef": 41.74,
    "out:heatPumpQuantity": 20,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000202010001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 2.84,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 55.38,
    "out:Electricity (inc PV)": 40.83,
    "out:Total Energy Use Pre PV": 58.38,
    "out:Total Energy Use Post PV": 43.83,
    "out:Primary Energy": 74.53,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 35.11,
    "out:CO2 Operational/m2": 3.72,
    "out:Total CO2/m2": 38.84,
    "out:Total CO2 (tons)": 199.74,
    "out:Klimatpaverkan": -129.5,
    "out:Initial Cost/MSEK": 15.4225,
    "out:Running cost/(Apt SEK y)": 10203,
    "out:Running Cost over RSP/MSEK": 3.329,
    "out:LCP/MSEK": 0.518,
    "out:ROI% old": 14.77,
    "out:Payback discounted": 8,
    "out:Atemp": 5143,
    "out:Umean": 0.29,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 44,
    "out:Energy use kWh/m2": 44,
    "out:Energy savings %": 327.3,
    "out:Op energy cost/MSEK": 23,
    "out:El price/MSEK": 22,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 3,
    "out:El bought/kWh/m2": 3177.6,
    "out:Return %": 13,
    "out:Return/kSEK/y": 2035,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 2958000,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 147900,
    "out:EAHP (replacement cost)": 2204400,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 37.18,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 61.265983,
    "out:PV (m2 panels)": 321,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 57484.06,
    "out:PV (kWh sold)": 3594.59,
    "out:PV (% sold (El))": 6.25,
    "out:PV (kWh self-consumed)": 53889.47,
    "out:PV (ratio sold/self-consumed)": 0.07,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 869167,
    "out:EL kWh savings": -128226,
    "out:Sewage heat recovery cost": 250448,
    "out:Balancing hydronic system cost": 128574.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 577542,
    "out:DH kr savings": 790942,
    "out:El kr savings": -217984,
    "out:El kr sold": 4583,
    "out:El kr saved": 91612,
    "out:El kr return": 96195,
    "out:% solar/total": 66,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 14616,
    "out:% savings (space heating)": 97.78,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 1.48,
    "out:Etvv": 0,
    "out:Ef": 40.83,
    "out:heatPumpQuantity": 20,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000202020000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 4.91,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 57.92,
    "out:Electricity (inc PV)": 38.47,
    "out:Total Energy Use Pre PV": 62.92,
    "out:Total Energy Use Post PV": 43.47,
    "out:Primary Energy": 71.03,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 47.06,
    "out:CO2 Operational/m2": -27.45,
    "out:Total CO2/m2": 19.61,
    "out:Total CO2 (tons)": 100.86,
    "out:Klimatpaverkan": -228.38,
    "out:Initial Cost/MSEK": 16.11375,
    "out:Running cost/(Apt SEK y)": 9376,
    "out:Running Cost over RSP/MSEK": 3.072,
    "out:LCP/MSEK": 0.083,
    "out:ROI% old": 14.36,
    "out:Payback discounted": 8,
    "out:Atemp": 5143,
    "out:Umean": 0.29,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 43,
    "out:Energy use kWh/m2": 43,
    "out:Energy savings %": 337.2,
    "out:Op energy cost/MSEK": 21,
    "out:El price/MSEK": 20,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 5,
    "out:El bought/kWh/m2": 3008.3,
    "out:Return %": 13,
    "out:Return/kSEK/y": 2070,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 2958000,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 147900,
    "out:EAHP (replacement cost)": 2204400,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 39.72,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.33,
    "out:PV (kWp installed)": 122.531965,
    "out:PV (m2 panels)": 643,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 114968.11,
    "out:PV (kWh sold)": 35962.56,
    "out:PV (% sold (El))": 31.28,
    "out:PV (kWh self-consumed)": 79005.55,
    "out:PV (ratio sold/self-consumed)": 0.46,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 858881,
    "out:EL kWh savings": -116098,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 630068,
    "out:DH kr savings": 781582,
    "out:El kr savings": -197366,
    "out:El kr sold": 45852,
    "out:El kr saved": 134309,
    "out:El kr return": 180162,
    "out:% solar/total": 97,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 25260,
    "out:% savings (space heating)": 96.16,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 2.54,
    "out:Etvv": 0,
    "out:Ef": 38.47,
    "out:heatPumpQuantity": 20,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000202020000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 3.62,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 57.12,
    "out:Electricity (inc PV)": 37.68,
    "out:Total Energy Use Pre PV": 61.12,
    "out:Total Energy Use Post PV": 41.68,
    "out:Primary Energy": 69.06,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 47.06,
    "out:CO2 Operational/m2": -28.56,
    "out:Total CO2/m2": 18.5,
    "out:Total CO2 (tons)": 95.15,
    "out:Klimatpaverkan": -234.09,
    "out:Initial Cost/MSEK": 16.2425,
    "out:Running cost/(Apt SEK y)": 8895,
    "out:Running Cost over RSP/MSEK": 2.914,
    "out:LCP/MSEK": 0.112,
    "out:ROI% old": 14.38,
    "out:Payback discounted": 8,
    "out:Atemp": 5143,
    "out:Umean": 0.29,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 42,
    "out:Energy use kWh/m2": 42,
    "out:Energy savings %": 347.6,
    "out:Op energy cost/MSEK": 20,
    "out:El price/MSEK": 19,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 4,
    "out:El bought/kWh/m2": 2960.8,
    "out:Return %": 13,
    "out:Return/kSEK/y": 2090,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 2958000,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 147900,
    "out:EAHP (replacement cost)": 2204400,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 38.92,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.33,
    "out:PV (kWp installed)": 122.531965,
    "out:PV (m2 panels)": 643,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 114968.11,
    "out:PV (kWh sold)": 36626.28,
    "out:PV (% sold (El))": 31.86,
    "out:PV (kWh self-consumed)": 78341.83,
    "out:PV (ratio sold/self-consumed)": 0.47,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 864024,
    "out:EL kWh savings": -112037,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 128574.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 642497,
    "out:DH kr savings": 786262,
    "out:El kr savings": -190463,
    "out:El kr sold": 46699,
    "out:El kr saved": 133181,
    "out:El kr return": 179880,
    "out:% solar/total": 96,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 18619,
    "out:% savings (space heating)": 97.17,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 1.75,
    "out:Etvv": 0,
    "out:Ef": 37.68,
    "out:heatPumpQuantity": 20,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000202020001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 3.91,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 56.25,
    "out:Electricity (inc PV)": 37.62,
    "out:Total Energy Use Pre PV": 60.25,
    "out:Total Energy Use Post PV": 41.62,
    "out:Primary Energy": 69.25,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 47.47,
    "out:CO2 Operational/m2": -29.39,
    "out:Total CO2/m2": 18.08,
    "out:Total CO2 (tons)": 92.97,
    "out:Klimatpaverkan": -236.27,
    "out:Initial Cost/MSEK": 16.36375,
    "out:Running cost/(Apt SEK y)": 8720,
    "out:Running Cost over RSP/MSEK": 2.858,
    "out:LCP/MSEK": 0.048,
    "out:ROI% old": 14.33,
    "out:Payback discounted": 8,
    "out:Atemp": 5143,
    "out:Umean": 0.29,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 42,
    "out:Energy use kWh/m2": 42,
    "out:Energy savings %": 347.6,
    "out:Op energy cost/MSEK": 20,
    "out:El price/MSEK": 19,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 4,
    "out:El bought/kWh/m2": 2909.1,
    "out:Return %": 13,
    "out:Return/kSEK/y": 2098,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 2958000,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 147900,
    "out:EAHP (replacement cost)": 2204400,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 38.05,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.33,
    "out:PV (kWp installed)": 122.531965,
    "out:PV (m2 panels)": 643,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 114968.11,
    "out:PV (kWh sold)": 37356.02,
    "out:PV (% sold (El))": 32.49,
    "out:PV (kWh self-consumed)": 77612.09,
    "out:PV (ratio sold/self-consumed)": 0.48,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 864024,
    "out:EL kWh savings": -111691,
    "out:Sewage heat recovery cost": 250448,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 644016,
    "out:DH kr savings": 786262,
    "out:El kr savings": -189874,
    "out:El kr sold": 47629,
    "out:El kr saved": 131941,
    "out:El kr return": 179569,
    "out:% solar/total": 95,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 20095,
    "out:% savings (space heating)": 96.95,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 2.2,
    "out:Etvv": 0,
    "out:Ef": 37.62,
    "out:heatPumpQuantity": 20,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000202020001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 2.84,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 55.38,
    "out:Electricity (inc PV)": 36.81,
    "out:Total Energy Use Pre PV": 58.38,
    "out:Total Energy Use Post PV": 39.81,
    "out:Primary Energy": 67.3,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 47.47,
    "out:CO2 Operational/m2": -30.58,
    "out:Total CO2/m2": 16.89,
    "out:Total CO2 (tons)": 86.86,
    "out:Klimatpaverkan": -242.38,
    "out:Initial Cost/MSEK": 16.4925,
    "out:Running cost/(Apt SEK y)": 8225,
    "out:Running Cost over RSP/MSEK": 2.696,
    "out:LCP/MSEK": 0.081,
    "out:ROI% old": 14.36,
    "out:Payback discounted": 8,
    "out:Atemp": 5143,
    "out:Umean": 0.29,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 40,
    "out:Energy use kWh/m2": 40,
    "out:Energy savings %": 370,
    "out:Op energy cost/MSEK": 19,
    "out:El price/MSEK": 18,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 3,
    "out:El bought/kWh/m2": 2857.9,
    "out:Return %": 13,
    "out:Return/kSEK/y": 2118,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 2958000,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 147900,
    "out:EAHP (replacement cost)": 2204400,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 37.18,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.33,
    "out:PV (kWp installed)": 122.531965,
    "out:PV (m2 panels)": 643,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 114968.11,
    "out:PV (kWh sold)": 38089.46,
    "out:PV (% sold (El))": 33.13,
    "out:PV (kWh self-consumed)": 76878.65,
    "out:PV (ratio sold/self-consumed)": 0.5,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 869167,
    "out:EL kWh savings": -107556,
    "out:Sewage heat recovery cost": 250448,
    "out:Balancing hydronic system cost": 128574.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 656660,
    "out:DH kr savings": 790942,
    "out:El kr savings": -182846,
    "out:El kr sold": 48564,
    "out:El kr saved": 130694,
    "out:El kr return": 179258,
    "out:% solar/total": 94,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 14616,
    "out:% savings (space heating)": 97.78,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 1.48,
    "out:Etvv": 0,
    "out:Ef": 36.81,
    "out:heatPumpQuantity": 20,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000202100000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 3.62,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 55.67,
    "out:Electricity (inc PV)": 51.65,
    "out:Total Energy Use Pre PV": 59.67,
    "out:Total Energy Use Post PV": 55.65,
    "out:Primary Energy": 94.44,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 22.36,
    "out:CO2 Operational/m2": 8.79,
    "out:Total CO2/m2": 31.15,
    "out:Total CO2 (tons)": 160.19,
    "out:Klimatpaverkan": -169.05,
    "out:Initial Cost/MSEK": 14.085,
    "out:Running cost/(Apt SEK y)": 12877,
    "out:Running Cost over RSP/MSEK": 4.186,
    "out:LCP/MSEK": 0.998,
    "out:ROI% old": 15.3,
    "out:Payback discounted": 8,
    "out:Atemp": 5143,
    "out:Umean": 0.29,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 56,
    "out:Energy use kWh/m2": 56,
    "out:Energy savings %": 235.7,
    "out:Op energy cost/MSEK": 28,
    "out:El price/MSEK": 27,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 4,
    "out:El bought/kWh/m2": 3897.1,
    "out:Return %": 14,
    "out:Return/kSEK/y": 1923,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 2958000,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 147900,
    "out:EAHP (replacement cost)": 2204400,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 37.47,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 864024,
    "out:EL kWh savings": -183865,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 473691,
    "out:DH kr savings": 786262,
    "out:El kr savings": -312571,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 18624,
    "out:% savings (space heating)": 97.17,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 2.1,
    "out:Etvv": 0,
    "out:Ef": 51.65,
    "out:heatPumpQuantity": 20,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000202100000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 2.62,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 54.79,
    "out:Electricity (inc PV)": 50.67,
    "out:Total Energy Use Pre PV": 57.79,
    "out:Total Energy Use Post PV": 53.67,
    "out:Primary Energy": 92.19,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 22.36,
    "out:CO2 Operational/m2": 8.31,
    "out:Total CO2/m2": 30.67,
    "out:Total CO2 (tons)": 157.74,
    "out:Klimatpaverkan": -171.5,
    "out:Initial Cost/MSEK": 14.21375,
    "out:Running cost/(Apt SEK y)": 12370,
    "out:Running Cost over RSP/MSEK": 4.02,
    "out:LCP/MSEK": 1.035,
    "out:ROI% old": 15.33,
    "out:Payback discounted": 8,
    "out:Atemp": 5143,
    "out:Umean": 0.29,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 54,
    "out:Energy use kWh/m2": 54,
    "out:Energy savings %": 248.1,
    "out:Op energy cost/MSEK": 27,
    "out:El price/MSEK": 26,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 3,
    "out:El bought/kWh/m2": 3835.1,
    "out:Return %": 14,
    "out:Return/kSEK/y": 1944,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 2958000,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 147900,
    "out:EAHP (replacement cost)": 2204400,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 36.59,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 869167,
    "out:EL kWh savings": -178834,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 128574.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 486924,
    "out:DH kr savings": 790942,
    "out:El kr savings": -304018,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 13455,
    "out:% savings (space heating)": 97.96,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 1.39,
    "out:Etvv": 0,
    "out:Ef": 50.67,
    "out:heatPumpQuantity": 20,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000202100001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 3.01,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 54.28,
    "out:Electricity (inc PV)": 50.81,
    "out:Total Energy Use Pre PV": 57.28,
    "out:Total Energy Use Post PV": 53.81,
    "out:Primary Energy": 92.77,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 22.76,
    "out:CO2 Operational/m2": 8.24,
    "out:Total CO2/m2": 31,
    "out:Total CO2 (tons)": 159.46,
    "out:Klimatpaverkan": -169.78,
    "out:Initial Cost/MSEK": 14.335,
    "out:Running cost/(Apt SEK y)": 12264,
    "out:Running Cost over RSP/MSEK": 3.986,
    "out:LCP/MSEK": 0.948,
    "out:ROI% old": 15.23,
    "out:Payback discounted": 8,
    "out:Atemp": 5143,
    "out:Umean": 0.29,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 54,
    "out:Energy use kWh/m2": 54,
    "out:Energy savings %": 248.1,
    "out:Op energy cost/MSEK": 27,
    "out:El price/MSEK": 26,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 3,
    "out:El bought/kWh/m2": 3799.4,
    "out:Return %": 14,
    "out:Return/kSEK/y": 1949,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 2958000,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 147900,
    "out:EAHP (replacement cost)": 2204400,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 36.08,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 869167,
    "out:EL kWh savings": -179564,
    "out:Sewage heat recovery cost": 250448,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 485683,
    "out:DH kr savings": 790942,
    "out:El kr savings": -305259,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 15473,
    "out:% savings (space heating)": 97.65,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 1.86,
    "out:Etvv": 0,
    "out:Ef": 50.81,
    "out:heatPumpQuantity": 20,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000202100001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 2.13,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 53.35,
    "out:Electricity (inc PV)": 49.82,
    "out:Total Energy Use Pre PV": 55.35,
    "out:Total Energy Use Post PV": 51.82,
    "out:Primary Energy": 90.52,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 22.76,
    "out:CO2 Operational/m2": 7.76,
    "out:Total CO2/m2": 30.52,
    "out:Total CO2 (tons)": 156.98,
    "out:Klimatpaverkan": -172.26,
    "out:Initial Cost/MSEK": 14.46375,
    "out:Running cost/(Apt SEK y)": 11750,
    "out:Running Cost over RSP/MSEK": 3.818,
    "out:LCP/MSEK": 0.988,
    "out:ROI% old": 15.26,
    "out:Payback discounted": 8,
    "out:Atemp": 5143,
    "out:Umean": 0.29,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 52,
    "out:Energy use kWh/m2": 52,
    "out:Energy savings %": 261.5,
    "out:Op energy cost/MSEK": 25,
    "out:El price/MSEK": 25,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 2,
    "out:El bought/kWh/m2": 3734.7,
    "out:Return %": 14,
    "out:Return/kSEK/y": 1970,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 2958000,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 147900,
    "out:EAHP (replacement cost)": 2204400,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 35.15,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 874310,
    "out:EL kWh savings": -174453,
    "out:Sewage heat recovery cost": 250448,
    "out:Balancing hydronic system cost": 128574.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 499052,
    "out:DH kr savings": 795622,
    "out:El kr savings": -296570,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 10936,
    "out:% savings (space heating)": 98.34,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 1.2,
    "out:Etvv": 0,
    "out:Ef": 49.82,
    "out:heatPumpQuantity": 20,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000202110000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 3.62,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 55.67,
    "out:Electricity (inc PV)": 41.41,
    "out:Total Energy Use Pre PV": 59.67,
    "out:Total Energy Use Post PV": 45.41,
    "out:Primary Energy": 76.01,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 34.71,
    "out:CO2 Operational/m2": 4.21,
    "out:Total CO2/m2": 38.92,
    "out:Total CO2 (tons)": 200.15,
    "out:Klimatpaverkan": -129.09,
    "out:Initial Cost/MSEK": 15.155,
    "out:Running cost/(Apt SEK y)": 10586,
    "out:Running Cost over RSP/MSEK": 3.454,
    "out:LCP/MSEK": 0.66,
    "out:ROI% old": 14.91,
    "out:Payback discounted": 8,
    "out:Atemp": 5143,
    "out:Umean": 0.29,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 45,
    "out:Energy use kWh/m2": 45,
    "out:Energy savings %": 317.8,
    "out:Op energy cost/MSEK": 24,
    "out:El price/MSEK": 22,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 4,
    "out:El bought/kWh/m2": 3197.4,
    "out:Return %": 13,
    "out:Return/kSEK/y": 2019,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 2958000,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 147900,
    "out:EAHP (replacement cost)": 2204400,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 37.47,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 61.265983,
    "out:PV (m2 panels)": 321,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 57484.06,
    "out:PV (kWh sold)": 3505.75,
    "out:PV (% sold (El))": 6.1,
    "out:PV (kWh self-consumed)": 53978.3,
    "out:PV (ratio sold/self-consumed)": 0.06,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 864024,
    "out:EL kWh savings": -131213,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 567670,
    "out:DH kr savings": 786262,
    "out:El kr savings": -223062,
    "out:El kr sold": 4470,
    "out:El kr saved": 91763,
    "out:El kr return": 96233,
    "out:% solar/total": 66,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 18624,
    "out:% savings (space heating)": 97.17,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 2.1,
    "out:Etvv": 0,
    "out:Ef": 41.41,
    "out:heatPumpQuantity": 20,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000202110000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 2.62,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 54.79,
    "out:Electricity (inc PV)": 40.5,
    "out:Total Energy Use Pre PV": 57.79,
    "out:Total Energy Use Post PV": 43.5,
    "out:Primary Energy": 73.88,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 34.71,
    "out:CO2 Operational/m2": 3.48,
    "out:Total CO2/m2": 38.19,
    "out:Total CO2 (tons)": 196.39,
    "out:Klimatpaverkan": -132.85,
    "out:Initial Cost/MSEK": 15.28375,
    "out:Running cost/(Apt SEK y)": 10082,
    "out:Running Cost over RSP/MSEK": 3.289,
    "out:LCP/MSEK": 0.696,
    "out:ROI% old": 14.94,
    "out:Payback discounted": 8,
    "out:Atemp": 5143,
    "out:Umean": 0.29,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 44,
    "out:Energy use kWh/m2": 44,
    "out:Energy savings %": 327.3,
    "out:Op energy cost/MSEK": 22,
    "out:El price/MSEK": 21,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 3,
    "out:El bought/kWh/m2": 3138.3,
    "out:Return %": 13,
    "out:Return/kSEK/y": 2040,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 2958000,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 147900,
    "out:EAHP (replacement cost)": 2204400,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 36.59,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 61.265983,
    "out:PV (m2 panels)": 321,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 57484.06,
    "out:PV (kWh sold)": 3775.16,
    "out:PV (% sold (El))": 6.57,
    "out:PV (kWh self-consumed)": 53708.9,
    "out:PV (ratio sold/self-consumed)": 0.07,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 869167,
    "out:EL kWh savings": -126542,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 128574.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 580634,
    "out:DH kr savings": 790942,
    "out:El kr savings": -215122,
    "out:El kr sold": 4813,
    "out:El kr saved": 91305,
    "out:El kr return": 96118,
    "out:% solar/total": 66,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 13455,
    "out:% savings (space heating)": 97.96,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 1.39,
    "out:Etvv": 0,
    "out:Ef": 40.5,
    "out:heatPumpQuantity": 20,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000202110001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 3.01,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 54.28,
    "out:Electricity (inc PV)": 40.64,
    "out:Total Energy Use Pre PV": 57.28,
    "out:Total Energy Use Post PV": 43.64,
    "out:Primary Energy": 74.44,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 35.11,
    "out:CO2 Operational/m2": 3.26,
    "out:Total CO2/m2": 38.37,
    "out:Total CO2 (tons)": 197.34,
    "out:Klimatpaverkan": -131.9,
    "out:Initial Cost/MSEK": 15.405,
    "out:Running cost/(Apt SEK y)": 9977,
    "out:Running Cost over RSP/MSEK": 3.255,
    "out:LCP/MSEK": 0.609,
    "out:ROI% old": 14.85,
    "out:Payback discounted": 8,
    "out:Atemp": 5143,
    "out:Umean": 0.29,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 44,
    "out:Energy use kWh/m2": 44,
    "out:Energy savings %": 327.3,
    "out:Op energy cost/MSEK": 22,
    "out:El price/MSEK": 21,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 3,
    "out:El bought/kWh/m2": 3104.4,
    "out:Return %": 13,
    "out:Return/kSEK/y": 2045,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 2958000,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 147900,
    "out:EAHP (replacement cost)": 2204400,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 36.08,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 61.265983,
    "out:PV (m2 panels)": 321,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 57484.06,
    "out:PV (kWh sold)": 3935.42,
    "out:PV (% sold (El))": 6.85,
    "out:PV (kWh self-consumed)": 53548.64,
    "out:PV (ratio sold/self-consumed)": 0.07,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 869167,
    "out:EL kWh savings": -127219,
    "out:Sewage heat recovery cost": 250448,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 579687,
    "out:DH kr savings": 790942,
    "out:El kr savings": -216272,
    "out:El kr sold": 5018,
    "out:El kr saved": 91033,
    "out:El kr return": 96050,
    "out:% solar/total": 65,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 15473,
    "out:% savings (space heating)": 97.65,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 1.86,
    "out:Etvv": 0,
    "out:Ef": 40.64,
    "out:heatPumpQuantity": 20,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000202110001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 2.13,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 53.35,
    "out:Electricity (inc PV)": 39.72,
    "out:Total Energy Use Pre PV": 55.35,
    "out:Total Energy Use Post PV": 41.72,
    "out:Primary Energy": 72.33,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 35.11,
    "out:CO2 Operational/m2": 2.49,
    "out:Total CO2/m2": 37.61,
    "out:Total CO2 (tons)": 193.4,
    "out:Klimatpaverkan": -135.84,
    "out:Initial Cost/MSEK": 15.53375,
    "out:Running cost/(Apt SEK y)": 9466,
    "out:Running Cost over RSP/MSEK": 3.088,
    "out:LCP/MSEK": 0.647,
    "out:ROI% old": 14.88,
    "out:Payback discounted": 8,
    "out:Atemp": 5143,
    "out:Umean": 0.29,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 42,
    "out:Energy use kWh/m2": 42,
    "out:Energy savings %": 347.6,
    "out:Op energy cost/MSEK": 21,
    "out:El price/MSEK": 20,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 2,
    "out:El bought/kWh/m2": 3043.2,
    "out:Return %": 13,
    "out:Return/kSEK/y": 2066,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 2958000,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 147900,
    "out:EAHP (replacement cost)": 2204400,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 35.15,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 61.265983,
    "out:PV (m2 panels)": 321,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 57484.06,
    "out:PV (kWh sold)": 4238.12,
    "out:PV (% sold (El))": 7.37,
    "out:PV (kWh self-consumed)": 53245.94,
    "out:PV (ratio sold/self-consumed)": 0.08,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 874310,
    "out:EL kWh savings": -122487,
    "out:Sewage heat recovery cost": 250448,
    "out:Balancing hydronic system cost": 128574.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 592798,
    "out:DH kr savings": 795622,
    "out:El kr savings": -208228,
    "out:El kr sold": 5404,
    "out:El kr saved": 90518,
    "out:El kr return": 95922,
    "out:% solar/total": 65,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 10936,
    "out:% savings (space heating)": 98.34,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 1.2,
    "out:Etvv": 0,
    "out:Ef": 39.72,
    "out:heatPumpQuantity": 20,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000202120000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 3.62,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 55.67,
    "out:Electricity (inc PV)": 37.33,
    "out:Total Energy Use Pre PV": 59.67,
    "out:Total Energy Use Post PV": 41.33,
    "out:Primary Energy": 68.66,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 47.06,
    "out:CO2 Operational/m2": -29.93,
    "out:Total CO2/m2": 17.13,
    "out:Total CO2 (tons)": 88.1,
    "out:Klimatpaverkan": -241.14,
    "out:Initial Cost/MSEK": 16.225,
    "out:Running cost/(Apt SEK y)": 8606,
    "out:Running Cost over RSP/MSEK": 2.821,
    "out:LCP/MSEK": 0.223,
    "out:ROI% old": 14.48,
    "out:Payback discounted": 8,
    "out:Atemp": 5143,
    "out:Umean": 0.29,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 41,
    "out:Energy use kWh/m2": 41,
    "out:Energy savings %": 358.5,
    "out:Op energy cost/MSEK": 20,
    "out:El price/MSEK": 18,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 4,
    "out:El bought/kWh/m2": 2875.3,
    "out:Return %": 13,
    "out:Return/kSEK/y": 2102,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 2958000,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 147900,
    "out:EAHP (replacement cost)": 2204400,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 37.47,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.33,
    "out:PV (kWp installed)": 122.531965,
    "out:PV (m2 panels)": 643,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 114968.11,
    "out:PV (kWh sold)": 37838.73,
    "out:PV (% sold (El))": 32.91,
    "out:PV (kWh self-consumed)": 77129.38,
    "out:PV (ratio sold/self-consumed)": 0.49,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 864024,
    "out:EL kWh savings": -110215,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 647141,
    "out:DH kr savings": 786262,
    "out:El kr savings": -187365,
    "out:El kr sold": 48244,
    "out:El kr saved": 131120,
    "out:El kr return": 179364,
    "out:% solar/total": 94,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 18624,
    "out:% savings (space heating)": 97.17,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 2.1,
    "out:Etvv": 0,
    "out:Ef": 37.33,
    "out:heatPumpQuantity": 20,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000202120000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 2.62,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 54.79,
    "out:Electricity (inc PV)": 36.52,
    "out:Total Energy Use Pre PV": 57.79,
    "out:Total Energy Use Post PV": 39.52,
    "out:Primary Energy": 66.71,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 47.06,
    "out:CO2 Operational/m2": -31.14,
    "out:Total CO2/m2": 15.92,
    "out:Total CO2 (tons)": 81.88,
    "out:Klimatpaverkan": -247.36,
    "out:Initial Cost/MSEK": 16.35375,
    "out:Running cost/(Apt SEK y)": 8107,
    "out:Running Cost over RSP/MSEK": 2.657,
    "out:LCP/MSEK": 0.258,
    "out:ROI% old": 14.51,
    "out:Payback discounted": 8,
    "out:Atemp": 5143,
    "out:Umean": 0.29,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 40,
    "out:Energy use kWh/m2": 40,
    "out:Energy savings %": 370,
    "out:Op energy cost/MSEK": 18,
    "out:El price/MSEK": 17,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 3,
    "out:El bought/kWh/m2": 2823,
    "out:Return %": 13,
    "out:Return/kSEK/y": 2123,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 2958000,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 147900,
    "out:EAHP (replacement cost)": 2204400,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 36.59,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.33,
    "out:PV (kWp installed)": 122.531965,
    "out:PV (m2 panels)": 643,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 114968.11,
    "out:PV (kWh sold)": 38592.08,
    "out:PV (% sold (El))": 33.57,
    "out:PV (kWh self-consumed)": 76376.03,
    "out:PV (ratio sold/self-consumed)": 0.51,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 869167,
    "out:EL kWh savings": -106058,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 128574.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 659848,
    "out:DH kr savings": 790942,
    "out:El kr savings": -180299,
    "out:El kr sold": 49205,
    "out:El kr saved": 129839,
    "out:El kr return": 179044,
    "out:% solar/total": 93,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 13455,
    "out:% savings (space heating)": 97.96,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 1.39,
    "out:Etvv": 0,
    "out:Ef": 36.52,
    "out:heatPumpQuantity": 20,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000202120001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 3.01,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 54.28,
    "out:Electricity (inc PV)": 36.64,
    "out:Total Energy Use Pre PV": 57.28,
    "out:Total Energy Use Post PV": 39.64,
    "out:Primary Energy": 67.25,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 47.47,
    "out:CO2 Operational/m2": -31.64,
    "out:Total CO2/m2": 15.83,
    "out:Total CO2 (tons)": 81.42,
    "out:Klimatpaverkan": -247.82,
    "out:Initial Cost/MSEK": 16.475,
    "out:Running cost/(Apt SEK y)": 8006,
    "out:Running Cost over RSP/MSEK": 2.624,
    "out:LCP/MSEK": 0.17,
    "out:ROI% old": 14.43,
    "out:Payback discounted": 8,
    "out:Atemp": 5143,
    "out:Umean": 0.29,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 40,
    "out:Energy use kWh/m2": 40,
    "out:Energy savings %": 370,
    "out:Op energy cost/MSEK": 18,
    "out:El price/MSEK": 17,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 3,
    "out:El bought/kWh/m2": 2793.1,
    "out:Return %": 13,
    "out:Return/kSEK/y": 2128,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 2958000,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 147900,
    "out:EAHP (replacement cost)": 2204400,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 36.08,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.33,
    "out:PV (kWp installed)": 122.531965,
    "out:PV (m2 panels)": 643,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 114968.11,
    "out:PV (kWh sold)": 39027.04,
    "out:PV (% sold (El))": 33.95,
    "out:PV (kWh self-consumed)": 75941.07,
    "out:PV (ratio sold/self-consumed)": 0.51,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 869167,
    "out:EL kWh savings": -106661,
    "out:Sewage heat recovery cost": 250448,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 659378,
    "out:DH kr savings": 790942,
    "out:El kr savings": -181323,
    "out:El kr sold": 49759,
    "out:El kr saved": 129100,
    "out:El kr return": 178859,
    "out:% solar/total": 93,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 15473,
    "out:% savings (space heating)": 97.65,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 1.86,
    "out:Etvv": 0,
    "out:Ef": 36.64,
    "out:heatPumpQuantity": 20,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000202120001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 2.13,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 53.35,
    "out:Electricity (inc PV)": 35.82,
    "out:Total Energy Use Pre PV": 55.35,
    "out:Total Energy Use Post PV": 37.82,
    "out:Primary Energy": 65.32,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 47.47,
    "out:CO2 Operational/m2": -32.89,
    "out:Total CO2/m2": 14.57,
    "out:Total CO2 (tons)": 74.96,
    "out:Klimatpaverkan": -254.28,
    "out:Initial Cost/MSEK": 16.60375,
    "out:Running cost/(Apt SEK y)": 7500,
    "out:Running Cost over RSP/MSEK": 2.459,
    "out:LCP/MSEK": 0.207,
    "out:ROI% old": 14.46,
    "out:Payback discounted": 8,
    "out:Atemp": 5143,
    "out:Umean": 0.29,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 38,
    "out:Energy use kWh/m2": 38,
    "out:Energy savings %": 394.7,
    "out:Op energy cost/MSEK": 17,
    "out:El price/MSEK": 16,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 2,
    "out:El bought/kWh/m2": 2738.8,
    "out:Return %": 13,
    "out:Return/kSEK/y": 2149,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 2958000,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 147900,
    "out:EAHP (replacement cost)": 2204400,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 35.15,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.33,
    "out:PV (kWp installed)": 122.531965,
    "out:PV (m2 panels)": 643,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 114968.11,
    "out:PV (kWh sold)": 39823.88,
    "out:PV (% sold (El))": 34.64,
    "out:PV (kWh self-consumed)": 75144.23,
    "out:PV (ratio sold/self-consumed)": 0.53,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 874310,
    "out:EL kWh savings": -102448,
    "out:Sewage heat recovery cost": 250448,
    "out:Balancing hydronic system cost": 128574.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 672236,
    "out:DH kr savings": 795622,
    "out:El kr savings": -174162,
    "out:El kr sold": 50775,
    "out:El kr saved": 127745,
    "out:El kr return": 178521,
    "out:% solar/total": 92,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 10936,
    "out:% savings (space heating)": 98.34,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 1.2,
    "out:Etvv": 0,
    "out:Ef": 35.82,
    "out:heatPumpQuantity": 20,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000000000000010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 98.03,
    "out:DHW (DH)": 43.5,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 20.5,
    "out:Total Energy Use Pre PV": 162.5,
    "out:Total Energy Use Post PV": 162.5,
    "out:Primary Energy": 123.02,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 1.2,
    "out:CO2 Operational/m2": 53.82,
    "out:Total CO2/m2": 55.02,
    "out:Total CO2 (tons)": 282.96,
    "out:Klimatpaverkan": -46.28,
    "out:Initial Cost/MSEK": 3.21075,
    "out:Running cost/(Apt SEK y)": 49967,
    "out:Running Cost over RSP/MSEK": 16.406,
    "out:LCP/MSEK": -0.348,
    "out:ROI% old": 12.74,
    "out:Payback discounted": 9,
    "out:Atemp": 5143,
    "out:Umean": 0.82,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 162,
    "out:Energy use kWh/m2": 162,
    "out:Energy savings %": 16,
    "out:Op energy cost/MSEK": 120,
    "out:El price/MSEK": 73,
    "out:Heating price/MSEK": 41,
    "out:DH+ DHW/kWh/m2": 142,
    "out:El bought/kWh/m2": 1434.9,
    "out:Return %": 11,
    "out:Return/kSEK/y": 365,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 875718.530813,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 154290,
    "out:EL kWh savings": -23653,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 100194,
    "out:DH kr savings": 140404,
    "out:El kr savings": -40210,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 504159,
    "out:% savings (space heating)": 23.45,
    "out:Total_Water Heating (DH)": 223720,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 128.05,
    "out:Etvv": 25,
    "out:Ef": 20.5,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000000000000110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 92.48,
    "out:DHW (DH)": 43.5,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 20.5,
    "out:Total Energy Use Pre PV": 156.5,
    "out:Total Energy Use Post PV": 156.5,
    "out:Primary Energy": 119.13,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 1.2,
    "out:CO2 Operational/m2": 51.66,
    "out:Total CO2/m2": 52.86,
    "out:Total CO2 (tons)": 271.86,
    "out:Klimatpaverkan": -57.38,
    "out:Initial Cost/MSEK": 3.339375,
    "out:Running cost/(Apt SEK y)": 48036,
    "out:Running Cost over RSP/MSEK": 15.772,
    "out:LCP/MSEK": 0.158,
    "out:ROI% old": 14.96,
    "out:Payback discounted": 8,
    "out:Atemp": 5143,
    "out:Umean": 0.82,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 156,
    "out:Energy use kWh/m2": 156,
    "out:Energy savings %": 20.5,
    "out:Op energy cost/MSEK": 115,
    "out:El price/MSEK": 70,
    "out:Heating price/MSEK": 39,
    "out:DH+ DHW/kWh/m2": 136,
    "out:El bought/kWh/m2": 1434.9,
    "out:Return %": 13,
    "out:Return/kSEK/y": 446,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 875718.530813,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 185148,
    "out:EL kWh savings": -23653,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 128574.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 128275,
    "out:DH kr savings": 168485,
    "out:El kr savings": -40210,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 475626,
    "out:% savings (space heating)": 27.78,
    "out:Total_Water Heating (DH)": 223720,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 122.51,
    "out:Etvv": 25,
    "out:Ef": 20.5,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000000000001010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 98.03,
    "out:DHW (DH)": 36.98,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 20.5,
    "out:Total Energy Use Pre PV": 155.5,
    "out:Total Energy Use Post PV": 155.5,
    "out:Primary Energy": 120.39,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 1.6,
    "out:CO2 Operational/m2": 51.3,
    "out:Total CO2/m2": 52.9,
    "out:Total CO2 (tons)": 272.07,
    "out:Klimatpaverkan": -57.17,
    "out:Initial Cost/MSEK": 3.46125,
    "out:Running cost/(Apt SEK y)": 47714,
    "out:Running Cost over RSP/MSEK": 15.666,
    "out:LCP/MSEK": 0.142,
    "out:ROI% old": 14.87,
    "out:Payback discounted": 8,
    "out:Atemp": 5143,
    "out:Umean": 0.82,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 155,
    "out:Energy use kWh/m2": 155,
    "out:Energy savings %": 21.3,
    "out:Op energy cost/MSEK": 115,
    "out:El price/MSEK": 69,
    "out:Heating price/MSEK": 39,
    "out:DH+ DHW/kWh/m2": 135,
    "out:El bought/kWh/m2": 1434.9,
    "out:Return %": 13,
    "out:Return/kSEK/y": 460,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 875718.530813,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 190291,
    "out:EL kWh savings": -23653,
    "out:Sewage heat recovery cost": 250448,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 132955,
    "out:DH kr savings": 173165,
    "out:El kr savings": -40210,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 504159,
    "out:% savings (space heating)": 23.45,
    "out:Total_Water Heating (DH)": 190162,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 128.05,
    "out:Etvv": 21.25,
    "out:Ef": 20.5,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000000000001110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 92.48,
    "out:DHW (DH)": 36.98,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 20.5,
    "out:Total Energy Use Pre PV": 149.5,
    "out:Total Energy Use Post PV": 149.5,
    "out:Primary Energy": 116.51,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 1.6,
    "out:CO2 Operational/m2": 49.14,
    "out:Total CO2/m2": 50.74,
    "out:Total CO2 (tons)": 260.96,
    "out:Klimatpaverkan": -68.28,
    "out:Initial Cost/MSEK": 3.58975,
    "out:Running cost/(Apt SEK y)": 45783,
    "out:Running Cost over RSP/MSEK": 15.031,
    "out:LCP/MSEK": 0.648,
    "out:ROI% old": 16.86,
    "out:Payback discounted": 7,
    "out:Atemp": 5143,
    "out:Umean": 0.82,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 149,
    "out:Energy use kWh/m2": 149,
    "out:Energy savings %": 26.2,
    "out:Op energy cost/MSEK": 110,
    "out:El price/MSEK": 67,
    "out:Heating price/MSEK": 37,
    "out:DH+ DHW/kWh/m2": 129,
    "out:El bought/kWh/m2": 1434.9,
    "out:Return %": 15,
    "out:Return/kSEK/y": 541,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 875718.530813,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 221149,
    "out:EL kWh savings": -23653,
    "out:Sewage heat recovery cost": 250448,
    "out:Balancing hydronic system cost": 128574.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 161036,
    "out:DH kr savings": 201246,
    "out:El kr savings": -40210,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 475626,
    "out:% savings (space heating)": 27.78,
    "out:Total_Water Heating (DH)": 190162,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 122.51,
    "out:Etvv": 21.25,
    "out:Ef": 20.5,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000000010000010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 98.03,
    "out:DHW (DH)": 43.5,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 14.33,
    "out:Total Energy Use Pre PV": 162.5,
    "out:Total Energy Use Post PV": 156.33,
    "out:Primary Energy": 111.92,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 13.55,
    "out:CO2 Operational/m2": 27.81,
    "out:Total CO2/m2": 41.36,
    "out:Total CO2 (tons)": 212.7,
    "out:Klimatpaverkan": -116.54,
    "out:Initial Cost/MSEK": 4.280625,
    "out:Running cost/(Apt SEK y)": 47901,
    "out:Running Cost over RSP/MSEK": 15.746,
    "out:LCP/MSEK": -0.758,
    "out:ROI% old": 11.76,
    "out:Payback discounted": 10,
    "out:Atemp": 5143,
    "out:Umean": 0.82,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 156,
    "out:Energy use kWh/m2": 156,
    "out:Energy savings %": 20.5,
    "out:Op energy cost/MSEK": 116,
    "out:El price/MSEK": 69,
    "out:Heating price/MSEK": 41,
    "out:DH+ DHW/kWh/m2": 142,
    "out:El bought/kWh/m2": 1013.1,
    "out:Return %": 11,
    "out:Return/kSEK/y": 452,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 61.265983,
    "out:PV (m2 panels)": 321,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 57484.06,
    "out:PV (kWh sold)": 25763.95,
    "out:PV (% sold (El))": 44.82,
    "out:PV (kWh self-consumed)": 31720.11,
    "out:PV (ratio sold/self-consumed)": 0.81,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 875718.530813,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 154290,
    "out:EL kWh savings": 8067,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 186968,
    "out:DH kr savings": 140404,
    "out:El kr savings": 13715,
    "out:El kr sold": 32849,
    "out:El kr saved": 53924,
    "out:El kr return": 86773,
    "out:% solar/total": 39,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 504159,
    "out:% savings (space heating)": 23.45,
    "out:Total_Water Heating (DH)": 223720,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 128.05,
    "out:Etvv": 25,
    "out:Ef": 14.33,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000000010000110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 92.48,
    "out:DHW (DH)": 43.5,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 14.33,
    "out:Total Energy Use Pre PV": 156.5,
    "out:Total Energy Use Post PV": 150.33,
    "out:Primary Energy": 108.03,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 13.55,
    "out:CO2 Operational/m2": 25.65,
    "out:Total CO2/m2": 39.2,
    "out:Total CO2 (tons)": 201.59,
    "out:Klimatpaverkan": -127.65,
    "out:Initial Cost/MSEK": 4.409125,
    "out:Running cost/(Apt SEK y)": 45970,
    "out:Running Cost over RSP/MSEK": 15.111,
    "out:LCP/MSEK": -0.251,
    "out:ROI% old": 13.47,
    "out:Payback discounted": 9,
    "out:Atemp": 5143,
    "out:Umean": 0.82,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 150,
    "out:Energy use kWh/m2": 150,
    "out:Energy savings %": 25.3,
    "out:Op energy cost/MSEK": 111,
    "out:El price/MSEK": 66,
    "out:Heating price/MSEK": 39,
    "out:DH+ DHW/kWh/m2": 136,
    "out:El bought/kWh/m2": 1013.1,
    "out:Return %": 12,
    "out:Return/kSEK/y": 533,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 61.265983,
    "out:PV (m2 panels)": 321,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 57484.06,
    "out:PV (kWh sold)": 25763.95,
    "out:PV (% sold (El))": 44.82,
    "out:PV (kWh self-consumed)": 31720.11,
    "out:PV (ratio sold/self-consumed)": 0.81,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 875718.530813,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 185148,
    "out:EL kWh savings": 8067,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 128574.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 215048,
    "out:DH kr savings": 168485,
    "out:El kr savings": 13715,
    "out:El kr sold": 32849,
    "out:El kr saved": 53924,
    "out:El kr return": 86773,
    "out:% solar/total": 39,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 475626,
    "out:% savings (space heating)": 27.78,
    "out:Total_Water Heating (DH)": 223720,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 122.51,
    "out:Etvv": 25,
    "out:Ef": 14.33,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000000010001010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 98.03,
    "out:DHW (DH)": 36.98,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 14.33,
    "out:Total Energy Use Pre PV": 155.5,
    "out:Total Energy Use Post PV": 149.33,
    "out:Primary Energy": 109.29,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 13.95,
    "out:CO2 Operational/m2": 25.29,
    "out:Total CO2/m2": 39.24,
    "out:Total CO2 (tons)": 201.81,
    "out:Klimatpaverkan": -127.43,
    "out:Initial Cost/MSEK": 4.531,
    "out:Running cost/(Apt SEK y)": 45648,
    "out:Running Cost over RSP/MSEK": 15.005,
    "out:LCP/MSEK": -0.267,
    "out:ROI% old": 13.44,
    "out:Payback discounted": 9,
    "out:Atemp": 5143,
    "out:Umean": 0.82,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 149,
    "out:Energy use kWh/m2": 149,
    "out:Energy savings %": 26.2,
    "out:Op energy cost/MSEK": 111,
    "out:El price/MSEK": 65,
    "out:Heating price/MSEK": 39,
    "out:DH+ DHW/kWh/m2": 135,
    "out:El bought/kWh/m2": 1013.1,
    "out:Return %": 12,
    "out:Return/kSEK/y": 547,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 61.265983,
    "out:PV (m2 panels)": 321,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 57484.06,
    "out:PV (kWh sold)": 25763.95,
    "out:PV (% sold (El))": 44.82,
    "out:PV (kWh self-consumed)": 31720.11,
    "out:PV (ratio sold/self-consumed)": 0.81,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 875718.530813,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 190291,
    "out:EL kWh savings": 8067,
    "out:Sewage heat recovery cost": 250448,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 219728,
    "out:DH kr savings": 173165,
    "out:El kr savings": 13715,
    "out:El kr sold": 32849,
    "out:El kr saved": 53924,
    "out:El kr return": 86773,
    "out:% solar/total": 39,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 504159,
    "out:% savings (space heating)": 23.45,
    "out:Total_Water Heating (DH)": 190162,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 128.05,
    "out:Etvv": 21.25,
    "out:Ef": 14.33,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000000010001110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 92.48,
    "out:DHW (DH)": 36.98,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 14.33,
    "out:Total Energy Use Pre PV": 149.5,
    "out:Total Energy Use Post PV": 143.33,
    "out:Primary Energy": 105.41,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 13.95,
    "out:CO2 Operational/m2": 23.13,
    "out:Total CO2/m2": 37.08,
    "out:Total CO2 (tons)": 190.7,
    "out:Klimatpaverkan": -138.54,
    "out:Initial Cost/MSEK": 4.659625,
    "out:Running cost/(Apt SEK y)": 43717,
    "out:Running Cost over RSP/MSEK": 14.371,
    "out:LCP/MSEK": 0.239,
    "out:ROI% old": 15.02,
    "out:Payback discounted": 8,
    "out:Atemp": 5143,
    "out:Umean": 0.82,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 143,
    "out:Energy use kWh/m2": 143,
    "out:Energy savings %": 31.5,
    "out:Op energy cost/MSEK": 106,
    "out:El price/MSEK": 63,
    "out:Heating price/MSEK": 37,
    "out:DH+ DHW/kWh/m2": 129,
    "out:El bought/kWh/m2": 1013.1,
    "out:Return %": 13,
    "out:Return/kSEK/y": 628,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 61.265983,
    "out:PV (m2 panels)": 321,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 57484.06,
    "out:PV (kWh sold)": 25763.95,
    "out:PV (% sold (El))": 44.82,
    "out:PV (kWh self-consumed)": 31720.11,
    "out:PV (ratio sold/self-consumed)": 0.81,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 875718.530813,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 221149,
    "out:EL kWh savings": 8067,
    "out:Sewage heat recovery cost": 250448,
    "out:Balancing hydronic system cost": 128574.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 247809,
    "out:DH kr savings": 201246,
    "out:El kr savings": 13715,
    "out:El kr sold": 32849,
    "out:El kr saved": 53924,
    "out:El kr return": 86773,
    "out:% solar/total": 39,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 475626,
    "out:% savings (space heating)": 27.78,
    "out:Total_Water Heating (DH)": 190162,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 122.51,
    "out:Etvv": 21.25,
    "out:Ef": 14.33,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000000020000010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 98.03,
    "out:DHW (DH)": 43.5,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 13.09,
    "out:Total Energy Use Pre PV": 162.5,
    "out:Total Energy Use Post PV": 155.09,
    "out:Primary Energy": 109.68,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 25.9,
    "out:CO2 Operational/m2": -22.87,
    "out:Total CO2/m2": 3.03,
    "out:Total CO2 (tons)": 15.58,
    "out:Klimatpaverkan": -313.66,
    "out:Initial Cost/MSEK": 5.350375,
    "out:Running cost/(Apt SEK y)": 46091,
    "out:Running Cost over RSP/MSEK": 15.168,
    "out:LCP/MSEK": -1.249,
    "out:ROI% old": 10.95,
    "out:Payback discounted": 11,
    "out:Atemp": 5143,
    "out:Umean": 0.82,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 155,
    "out:Energy use kWh/m2": 155,
    "out:Energy savings %": 21.3,
    "out:Op energy cost/MSEK": 112,
    "out:El price/MSEK": 65,
    "out:Heating price/MSEK": 41,
    "out:DH+ DHW/kWh/m2": 142,
    "out:El bought/kWh/m2": 923.7,
    "out:Return %": 10,
    "out:Return/kSEK/y": 528,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.33,
    "out:PV (kWp installed)": 122.531965,
    "out:PV (m2 panels)": 643,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 114968.11,
    "out:PV (kWh sold)": 76862.14,
    "out:PV (% sold (El))": 66.86,
    "out:PV (kWh self-consumed)": 38105.97,
    "out:PV (ratio sold/self-consumed)": 2.02,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 875718.530813,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 154290,
    "out:EL kWh savings": 14453,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 262974,
    "out:DH kr savings": 140404,
    "out:El kr savings": 24571,
    "out:El kr sold": 97999,
    "out:El kr saved": 64780,
    "out:El kr return": 162779,
    "out:% solar/total": 47,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 504159,
    "out:% savings (space heating)": 23.45,
    "out:Total_Water Heating (DH)": 223720,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 128.05,
    "out:Etvv": 25,
    "out:Ef": 13.09,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000000020000110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 92.48,
    "out:DHW (DH)": 43.5,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 13.09,
    "out:Total Energy Use Pre PV": 156.5,
    "out:Total Energy Use Post PV": 149.09,
    "out:Primary Energy": 105.8,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 25.9,
    "out:CO2 Operational/m2": -25.03,
    "out:Total CO2/m2": 0.87,
    "out:Total CO2 (tons)": 4.47,
    "out:Klimatpaverkan": -324.77,
    "out:Initial Cost/MSEK": 5.478875,
    "out:Running cost/(Apt SEK y)": 44160,
    "out:Running Cost over RSP/MSEK": 14.533,
    "out:LCP/MSEK": -0.743,
    "out:ROI% old": 12.35,
    "out:Payback discounted": 10,
    "out:Atemp": 5143,
    "out:Umean": 0.82,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 149,
    "out:Energy use kWh/m2": 149,
    "out:Energy savings %": 26.2,
    "out:Op energy cost/MSEK": 108,
    "out:El price/MSEK": 62,
    "out:Heating price/MSEK": 39,
    "out:DH+ DHW/kWh/m2": 136,
    "out:El bought/kWh/m2": 923.7,
    "out:Return %": 11,
    "out:Return/kSEK/y": 609,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.33,
    "out:PV (kWp installed)": 122.531965,
    "out:PV (m2 panels)": 643,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 114968.11,
    "out:PV (kWh sold)": 76862.14,
    "out:PV (% sold (El))": 66.86,
    "out:PV (kWh self-consumed)": 38105.97,
    "out:PV (ratio sold/self-consumed)": 2.02,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 875718.530813,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 185148,
    "out:EL kWh savings": 14453,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 128574.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 291055,
    "out:DH kr savings": 168485,
    "out:El kr savings": 24571,
    "out:El kr sold": 97999,
    "out:El kr saved": 64780,
    "out:El kr return": 162779,
    "out:% solar/total": 47,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 475626,
    "out:% savings (space heating)": 27.78,
    "out:Total_Water Heating (DH)": 223720,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 122.51,
    "out:Etvv": 25,
    "out:Ef": 13.09,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000000020001010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 98.03,
    "out:DHW (DH)": 36.98,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 13.09,
    "out:Total Energy Use Pre PV": 155.5,
    "out:Total Energy Use Post PV": 148.09,
    "out:Primary Energy": 107.06,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 26.3,
    "out:CO2 Operational/m2": -25.39,
    "out:Total CO2/m2": 0.91,
    "out:Total CO2 (tons)": 4.69,
    "out:Klimatpaverkan": -324.55,
    "out:Initial Cost/MSEK": 5.60075,
    "out:Running cost/(Apt SEK y)": 43839,
    "out:Running Cost over RSP/MSEK": 14.427,
    "out:LCP/MSEK": -0.759,
    "out:ROI% old": 12.35,
    "out:Payback discounted": 10,
    "out:Atemp": 5143,
    "out:Umean": 0.82,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 148,
    "out:Energy use kWh/m2": 148,
    "out:Energy savings %": 27,
    "out:Op energy cost/MSEK": 107,
    "out:El price/MSEK": 62,
    "out:Heating price/MSEK": 39,
    "out:DH+ DHW/kWh/m2": 135,
    "out:El bought/kWh/m2": 923.7,
    "out:Return %": 11,
    "out:Return/kSEK/y": 623,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.33,
    "out:PV (kWp installed)": 122.531965,
    "out:PV (m2 panels)": 643,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 114968.11,
    "out:PV (kWh sold)": 76862.14,
    "out:PV (% sold (El))": 66.86,
    "out:PV (kWh self-consumed)": 38105.97,
    "out:PV (ratio sold/self-consumed)": 2.02,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 875718.530813,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 190291,
    "out:EL kWh savings": 14453,
    "out:Sewage heat recovery cost": 250448,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 295735,
    "out:DH kr savings": 173165,
    "out:El kr savings": 24571,
    "out:El kr sold": 97999,
    "out:El kr saved": 64780,
    "out:El kr return": 162779,
    "out:% solar/total": 47,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 504159,
    "out:% savings (space heating)": 23.45,
    "out:Total_Water Heating (DH)": 190162,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 128.05,
    "out:Etvv": 21.25,
    "out:Ef": 13.09,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000000020001110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 92.48,
    "out:DHW (DH)": 36.98,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 13.09,
    "out:Total Energy Use Pre PV": 149.5,
    "out:Total Energy Use Post PV": 142.09,
    "out:Primary Energy": 103.17,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 26.3,
    "out:CO2 Operational/m2": -27.55,
    "out:Total CO2/m2": -1.25,
    "out:Total CO2 (tons)": -6.42,
    "out:Klimatpaverkan": -335.66,
    "out:Initial Cost/MSEK": 5.729375,
    "out:Running cost/(Apt SEK y)": 41908,
    "out:Running Cost over RSP/MSEK": 13.793,
    "out:LCP/MSEK": -0.253,
    "out:ROI% old": 13.65,
    "out:Payback discounted": 9,
    "out:Atemp": 5143,
    "out:Umean": 0.82,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 142,
    "out:Energy use kWh/m2": 142,
    "out:Energy savings %": 32.4,
    "out:Op energy cost/MSEK": 102,
    "out:El price/MSEK": 59,
    "out:Heating price/MSEK": 37,
    "out:DH+ DHW/kWh/m2": 129,
    "out:El bought/kWh/m2": 923.7,
    "out:Return %": 12,
    "out:Return/kSEK/y": 704,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.33,
    "out:PV (kWp installed)": 122.531965,
    "out:PV (m2 panels)": 643,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 114968.11,
    "out:PV (kWh sold)": 76862.14,
    "out:PV (% sold (El))": 66.86,
    "out:PV (kWh self-consumed)": 38105.97,
    "out:PV (ratio sold/self-consumed)": 2.02,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 875718.530813,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 221149,
    "out:EL kWh savings": 14453,
    "out:Sewage heat recovery cost": 250448,
    "out:Balancing hydronic system cost": 128574.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 323815,
    "out:DH kr savings": 201246,
    "out:El kr savings": 24571,
    "out:El kr sold": 97999,
    "out:El kr saved": 64780,
    "out:El kr return": 162779,
    "out:% solar/total": 47,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 475626,
    "out:% savings (space heating)": 27.78,
    "out:Total_Water Heating (DH)": 190162,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 122.51,
    "out:Etvv": 21.25,
    "out:Ef": 13.09,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000000100000010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 98.03,
    "out:DHW (DH)": 34.8,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 20.5,
    "out:Total Energy Use Pre PV": 153.5,
    "out:Total Energy Use Post PV": 153.5,
    "out:Primary Energy": 119.52,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 1.2,
    "out:CO2 Operational/m2": 50.58,
    "out:Total CO2/m2": 51.78,
    "out:Total CO2 (tons)": 266.3,
    "out:Klimatpaverkan": -62.94,
    "out:Initial Cost/MSEK": 3.321625,
    "out:Running cost/(Apt SEK y)": 47071,
    "out:Running Cost over RSP/MSEK": 15.454,
    "out:LCP/MSEK": 0.493,
    "out:ROI% old": 16.41,
    "out:Payback discounted": 7,
    "out:Atemp": 5143,
    "out:Umean": 0.82,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 153,
    "out:Energy use kWh/m2": 153,
    "out:Energy savings %": 22.9,
    "out:Op energy cost/MSEK": 113,
    "out:El price/MSEK": 69,
    "out:Heating price/MSEK": 38,
    "out:DH+ DHW/kWh/m2": 133,
    "out:El bought/kWh/m2": 1434.9,
    "out:Return %": 15,
    "out:Return/kSEK/y": 487,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 875718.530813,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 200577,
    "out:EL kWh savings": -23653,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 142316,
    "out:DH kr savings": 182525,
    "out:El kr savings": -40210,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 504159,
    "out:% savings (space heating)": 23.45,
    "out:Total_Water Heating (DH)": 178976,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 128.05,
    "out:Etvv": 20,
    "out:Ef": 20.5,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000000100000110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 92.48,
    "out:DHW (DH)": 34.8,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 20.5,
    "out:Total Energy Use Pre PV": 147.5,
    "out:Total Energy Use Post PV": 147.5,
    "out:Primary Energy": 115.63,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 1.2,
    "out:CO2 Operational/m2": 48.42,
    "out:Total CO2/m2": 49.62,
    "out:Total CO2 (tons)": 255.19,
    "out:Klimatpaverkan": -74.05,
    "out:Initial Cost/MSEK": 3.45025,
    "out:Running cost/(Apt SEK y)": 45140,
    "out:Running Cost over RSP/MSEK": 14.82,
    "out:LCP/MSEK": 0.999,
    "out:ROI% old": 18.42,
    "out:Payback discounted": 7,
    "out:Atemp": 5143,
    "out:Umean": 0.82,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 147,
    "out:Energy use kWh/m2": 147,
    "out:Energy savings %": 27.9,
    "out:Op energy cost/MSEK": 108,
    "out:El price/MSEK": 66,
    "out:Heating price/MSEK": 36,
    "out:DH+ DHW/kWh/m2": 127,
    "out:El bought/kWh/m2": 1434.9,
    "out:Return %": 16,
    "out:Return/kSEK/y": 568,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 875718.530813,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 231435,
    "out:EL kWh savings": -23653,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 128574.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 170396,
    "out:DH kr savings": 210606,
    "out:El kr savings": -40210,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 475626,
    "out:% savings (space heating)": 27.78,
    "out:Total_Water Heating (DH)": 178976,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 122.51,
    "out:Etvv": 20,
    "out:Ef": 20.5,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000000100001010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 98.03,
    "out:DHW (DH)": 29.58,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 20.5,
    "out:Total Energy Use Pre PV": 148.5,
    "out:Total Energy Use Post PV": 148.5,
    "out:Primary Energy": 117.42,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 1.6,
    "out:CO2 Operational/m2": 48.78,
    "out:Total CO2/m2": 50.38,
    "out:Total CO2 (tons)": 259.11,
    "out:Klimatpaverkan": -70.13,
    "out:Initial Cost/MSEK": 3.572125,
    "out:Running cost/(Apt SEK y)": 45461,
    "out:Running Cost over RSP/MSEK": 14.926,
    "out:LCP/MSEK": 0.771,
    "out:ROI% old": 17.37,
    "out:Payback discounted": 7,
    "out:Atemp": 5143,
    "out:Umean": 0.82,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 148,
    "out:Energy use kWh/m2": 148,
    "out:Energy savings %": 27,
    "out:Op energy cost/MSEK": 109,
    "out:El price/MSEK": 66,
    "out:Heating price/MSEK": 37,
    "out:DH+ DHW/kWh/m2": 128,
    "out:El bought/kWh/m2": 1434.9,
    "out:Return %": 16,
    "out:Return/kSEK/y": 555,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 875718.530813,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 226292,
    "out:EL kWh savings": -23653,
    "out:Sewage heat recovery cost": 250448,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 165716,
    "out:DH kr savings": 205926,
    "out:El kr savings": -40210,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 504159,
    "out:% savings (space heating)": 23.45,
    "out:Total_Water Heating (DH)": 152130,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 128.05,
    "out:Etvv": 17,
    "out:Ef": 20.5,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000000100001110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 92.48,
    "out:DHW (DH)": 29.58,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 20.5,
    "out:Total Energy Use Pre PV": 142.5,
    "out:Total Energy Use Post PV": 142.5,
    "out:Primary Energy": 113.53,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 1.6,
    "out:CO2 Operational/m2": 46.62,
    "out:Total CO2/m2": 48.22,
    "out:Total CO2 (tons)": 248,
    "out:Klimatpaverkan": -81.24,
    "out:Initial Cost/MSEK": 3.700625,
    "out:Running cost/(Apt SEK y)": 43530,
    "out:Running Cost over RSP/MSEK": 14.291,
    "out:LCP/MSEK": 1.278,
    "out:ROI% old": 19.22,
    "out:Payback discounted": 6,
    "out:Atemp": 5143,
    "out:Umean": 0.82,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 142,
    "out:Energy use kWh/m2": 142,
    "out:Energy savings %": 32.4,
    "out:Op energy cost/MSEK": 104,
    "out:El price/MSEK": 64,
    "out:Heating price/MSEK": 35,
    "out:DH+ DHW/kWh/m2": 122,
    "out:El bought/kWh/m2": 1434.9,
    "out:Return %": 17,
    "out:Return/kSEK/y": 636,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 875718.530813,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 257150,
    "out:EL kWh savings": -23653,
    "out:Sewage heat recovery cost": 250448,
    "out:Balancing hydronic system cost": 128574.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 193797,
    "out:DH kr savings": 234006,
    "out:El kr savings": -40210,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 475626,
    "out:% savings (space heating)": 27.78,
    "out:Total_Water Heating (DH)": 152130,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 122.51,
    "out:Etvv": 17,
    "out:Ef": 20.5,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000000110000010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 98.03,
    "out:DHW (DH)": 34.8,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 14.33,
    "out:Total Energy Use Pre PV": 153.5,
    "out:Total Energy Use Post PV": 147.33,
    "out:Primary Energy": 108.42,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 13.55,
    "out:CO2 Operational/m2": 24.57,
    "out:Total CO2/m2": 38.12,
    "out:Total CO2 (tons)": 196.04,
    "out:Klimatpaverkan": -133.2,
    "out:Initial Cost/MSEK": 4.391375,
    "out:Running cost/(Apt SEK y)": 45005,
    "out:Running Cost over RSP/MSEK": 14.794,
    "out:LCP/MSEK": 0.084,
    "out:ROI% old": 14.56,
    "out:Payback discounted": 8,
    "out:Atemp": 5143,
    "out:Umean": 0.82,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 147,
    "out:Energy use kWh/m2": 147,
    "out:Energy savings %": 27.9,
    "out:Op energy cost/MSEK": 109,
    "out:El price/MSEK": 65,
    "out:Heating price/MSEK": 38,
    "out:DH+ DHW/kWh/m2": 133,
    "out:El bought/kWh/m2": 1013.1,
    "out:Return %": 13,
    "out:Return/kSEK/y": 574,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 61.265983,
    "out:PV (m2 panels)": 321,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 57484.06,
    "out:PV (kWh sold)": 25763.95,
    "out:PV (% sold (El))": 44.82,
    "out:PV (kWh self-consumed)": 31720.11,
    "out:PV (ratio sold/self-consumed)": 0.81,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 875718.530813,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 200577,
    "out:EL kWh savings": 8067,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 229089,
    "out:DH kr savings": 182525,
    "out:El kr savings": 13715,
    "out:El kr sold": 32849,
    "out:El kr saved": 53924,
    "out:El kr return": 86773,
    "out:% solar/total": 39,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 504159,
    "out:% savings (space heating)": 23.45,
    "out:Total_Water Heating (DH)": 178976,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 128.05,
    "out:Etvv": 20,
    "out:Ef": 14.33,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000000110000110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 92.48,
    "out:DHW (DH)": 34.8,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 14.33,
    "out:Total Energy Use Pre PV": 147.5,
    "out:Total Energy Use Post PV": 141.33,
    "out:Primary Energy": 104.53,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 13.55,
    "out:CO2 Operational/m2": 22.41,
    "out:Total CO2/m2": 35.96,
    "out:Total CO2 (tons)": 184.93,
    "out:Klimatpaverkan": -144.31,
    "out:Initial Cost/MSEK": 4.52,
    "out:Running cost/(Apt SEK y)": 43074,
    "out:Running Cost over RSP/MSEK": 14.159,
    "out:LCP/MSEK": 0.59,
    "out:ROI% old": 16.15,
    "out:Payback discounted": 8,
    "out:Atemp": 5143,
    "out:Umean": 0.82,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 141,
    "out:Energy use kWh/m2": 141,
    "out:Energy savings %": 33.3,
    "out:Op energy cost/MSEK": 104,
    "out:El price/MSEK": 62,
    "out:Heating price/MSEK": 36,
    "out:DH+ DHW/kWh/m2": 127,
    "out:El bought/kWh/m2": 1013.1,
    "out:Return %": 14,
    "out:Return/kSEK/y": 655,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 61.265983,
    "out:PV (m2 panels)": 321,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 57484.06,
    "out:PV (kWh sold)": 25763.95,
    "out:PV (% sold (El))": 44.82,
    "out:PV (kWh self-consumed)": 31720.11,
    "out:PV (ratio sold/self-consumed)": 0.81,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 875718.530813,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 231435,
    "out:EL kWh savings": 8067,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 128574.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 257170,
    "out:DH kr savings": 210606,
    "out:El kr savings": 13715,
    "out:El kr sold": 32849,
    "out:El kr saved": 53924,
    "out:El kr return": 86773,
    "out:% solar/total": 39,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 475626,
    "out:% savings (space heating)": 27.78,
    "out:Total_Water Heating (DH)": 178976,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 122.51,
    "out:Etvv": 20,
    "out:Ef": 14.33,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000000110001010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 98.03,
    "out:DHW (DH)": 29.58,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 14.33,
    "out:Total Energy Use Pre PV": 148.5,
    "out:Total Energy Use Post PV": 142.33,
    "out:Primary Energy": 106.32,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 13.95,
    "out:CO2 Operational/m2": 22.77,
    "out:Total CO2/m2": 36.72,
    "out:Total CO2 (tons)": 188.85,
    "out:Klimatpaverkan": -140.39,
    "out:Initial Cost/MSEK": 4.641875,
    "out:Running cost/(Apt SEK y)": 43395,
    "out:Running Cost over RSP/MSEK": 14.265,
    "out:LCP/MSEK": 0.362,
    "out:ROI% old": 15.4,
    "out:Payback discounted": 8,
    "out:Atemp": 5143,
    "out:Umean": 0.82,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 142,
    "out:Energy use kWh/m2": 142,
    "out:Energy savings %": 32.4,
    "out:Op energy cost/MSEK": 105,
    "out:El price/MSEK": 62,
    "out:Heating price/MSEK": 37,
    "out:DH+ DHW/kWh/m2": 128,
    "out:El bought/kWh/m2": 1013.1,
    "out:Return %": 14,
    "out:Return/kSEK/y": 641,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 61.265983,
    "out:PV (m2 panels)": 321,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 57484.06,
    "out:PV (kWh sold)": 25763.95,
    "out:PV (% sold (El))": 44.82,
    "out:PV (kWh self-consumed)": 31720.11,
    "out:PV (ratio sold/self-consumed)": 0.81,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 875718.530813,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 226292,
    "out:EL kWh savings": 8067,
    "out:Sewage heat recovery cost": 250448,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 252489,
    "out:DH kr savings": 205926,
    "out:El kr savings": 13715,
    "out:El kr sold": 32849,
    "out:El kr saved": 53924,
    "out:El kr return": 86773,
    "out:% solar/total": 39,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 504159,
    "out:% savings (space heating)": 23.45,
    "out:Total_Water Heating (DH)": 152130,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 128.05,
    "out:Etvv": 17,
    "out:Ef": 14.33,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000000110001110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 92.48,
    "out:DHW (DH)": 29.58,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 14.33,
    "out:Total Energy Use Pre PV": 142.5,
    "out:Total Energy Use Post PV": 136.33,
    "out:Primary Energy": 102.43,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 13.95,
    "out:CO2 Operational/m2": 20.61,
    "out:Total CO2/m2": 34.56,
    "out:Total CO2 (tons)": 177.74,
    "out:Klimatpaverkan": -151.5,
    "out:Initial Cost/MSEK": 4.7705,
    "out:Running cost/(Apt SEK y)": 41464,
    "out:Running Cost over RSP/MSEK": 13.63,
    "out:LCP/MSEK": 0.868,
    "out:ROI% old": 16.89,
    "out:Payback discounted": 7,
    "out:Atemp": 5143,
    "out:Umean": 0.82,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 136,
    "out:Energy use kWh/m2": 136,
    "out:Energy savings %": 38.2,
    "out:Op energy cost/MSEK": 100,
    "out:El price/MSEK": 60,
    "out:Heating price/MSEK": 35,
    "out:DH+ DHW/kWh/m2": 122,
    "out:El bought/kWh/m2": 1013.1,
    "out:Return %": 15,
    "out:Return/kSEK/y": 722,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 61.265983,
    "out:PV (m2 panels)": 321,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 57484.06,
    "out:PV (kWh sold)": 25763.95,
    "out:PV (% sold (El))": 44.82,
    "out:PV (kWh self-consumed)": 31720.11,
    "out:PV (ratio sold/self-consumed)": 0.81,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 875718.530813,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 257150,
    "out:EL kWh savings": 8067,
    "out:Sewage heat recovery cost": 250448,
    "out:Balancing hydronic system cost": 128574.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 280570,
    "out:DH kr savings": 234006,
    "out:El kr savings": 13715,
    "out:El kr sold": 32849,
    "out:El kr saved": 53924,
    "out:El kr return": 86773,
    "out:% solar/total": 39,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 475626,
    "out:% savings (space heating)": 27.78,
    "out:Total_Water Heating (DH)": 152130,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 122.51,
    "out:Etvv": 17,
    "out:Ef": 14.33,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000000120000010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 98.03,
    "out:DHW (DH)": 34.8,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 13.09,
    "out:Total Energy Use Pre PV": 153.5,
    "out:Total Energy Use Post PV": 146.09,
    "out:Primary Energy": 106.18,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 25.9,
    "out:CO2 Operational/m2": -26.11,
    "out:Total CO2/m2": -0.21,
    "out:Total CO2 (tons)": -1.08,
    "out:Klimatpaverkan": -330.32,
    "out:Initial Cost/MSEK": 5.46125,
    "out:Running cost/(Apt SEK y)": 43195,
    "out:Running Cost over RSP/MSEK": 14.216,
    "out:LCP/MSEK": -0.408,
    "out:ROI% old": 13.22,
    "out:Payback discounted": 9,
    "out:Atemp": 5143,
    "out:Umean": 0.82,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 146,
    "out:Energy use kWh/m2": 146,
    "out:Energy savings %": 28.8,
    "out:Op energy cost/MSEK": 105,
    "out:El price/MSEK": 61,
    "out:Heating price/MSEK": 38,
    "out:DH+ DHW/kWh/m2": 133,
    "out:El bought/kWh/m2": 923.7,
    "out:Return %": 12,
    "out:Return/kSEK/y": 650,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.33,
    "out:PV (kWp installed)": 122.531965,
    "out:PV (m2 panels)": 643,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 114968.11,
    "out:PV (kWh sold)": 76862.14,
    "out:PV (% sold (El))": 66.86,
    "out:PV (kWh self-consumed)": 38105.97,
    "out:PV (ratio sold/self-consumed)": 2.02,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 875718.530813,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 200577,
    "out:EL kWh savings": 14453,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 305095,
    "out:DH kr savings": 182525,
    "out:El kr savings": 24571,
    "out:El kr sold": 97999,
    "out:El kr saved": 64780,
    "out:El kr return": 162779,
    "out:% solar/total": 47,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 504159,
    "out:% savings (space heating)": 23.45,
    "out:Total_Water Heating (DH)": 178976,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 128.05,
    "out:Etvv": 20,
    "out:Ef": 13.09,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000000120000110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 92.48,
    "out:DHW (DH)": 34.8,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 13.09,
    "out:Total Energy Use Pre PV": 147.5,
    "out:Total Energy Use Post PV": 140.09,
    "out:Primary Energy": 102.3,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 25.9,
    "out:CO2 Operational/m2": -28.27,
    "out:Total CO2/m2": -2.37,
    "out:Total CO2 (tons)": -12.19,
    "out:Klimatpaverkan": -341.43,
    "out:Initial Cost/MSEK": 5.58975,
    "out:Running cost/(Apt SEK y)": 41264,
    "out:Running Cost over RSP/MSEK": 13.581,
    "out:LCP/MSEK": 0.098,
    "out:ROI% old": 14.54,
    "out:Payback discounted": 8,
    "out:Atemp": 5143,
    "out:Umean": 0.82,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 140,
    "out:Energy use kWh/m2": 140,
    "out:Energy savings %": 34.3,
    "out:Op energy cost/MSEK": 101,
    "out:El price/MSEK": 58,
    "out:Heating price/MSEK": 36,
    "out:DH+ DHW/kWh/m2": 127,
    "out:El bought/kWh/m2": 923.7,
    "out:Return %": 13,
    "out:Return/kSEK/y": 731,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.33,
    "out:PV (kWp installed)": 122.531965,
    "out:PV (m2 panels)": 643,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 114968.11,
    "out:PV (kWh sold)": 76862.14,
    "out:PV (% sold (El))": 66.86,
    "out:PV (kWh self-consumed)": 38105.97,
    "out:PV (ratio sold/self-consumed)": 2.02,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 875718.530813,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 231435,
    "out:EL kWh savings": 14453,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 128574.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 333176,
    "out:DH kr savings": 210606,
    "out:El kr savings": 24571,
    "out:El kr sold": 97999,
    "out:El kr saved": 64780,
    "out:El kr return": 162779,
    "out:% solar/total": 47,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 475626,
    "out:% savings (space heating)": 27.78,
    "out:Total_Water Heating (DH)": 178976,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 122.51,
    "out:Etvv": 20,
    "out:Ef": 13.09,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000000120001010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 98.03,
    "out:DHW (DH)": 29.58,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 13.09,
    "out:Total Energy Use Pre PV": 148.5,
    "out:Total Energy Use Post PV": 141.09,
    "out:Primary Energy": 104.08,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 26.3,
    "out:CO2 Operational/m2": -27.91,
    "out:Total CO2/m2": -1.61,
    "out:Total CO2 (tons)": -8.27,
    "out:Klimatpaverkan": -337.51,
    "out:Initial Cost/MSEK": 5.711625,
    "out:Running cost/(Apt SEK y)": 41586,
    "out:Running Cost over RSP/MSEK": 13.687,
    "out:LCP/MSEK": -0.13,
    "out:ROI% old": 13.96,
    "out:Payback discounted": 9,
    "out:Atemp": 5143,
    "out:Umean": 0.82,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 141,
    "out:Energy use kWh/m2": 141,
    "out:Energy savings %": 33.3,
    "out:Op energy cost/MSEK": 102,
    "out:El price/MSEK": 59,
    "out:Heating price/MSEK": 37,
    "out:DH+ DHW/kWh/m2": 128,
    "out:El bought/kWh/m2": 923.7,
    "out:Return %": 13,
    "out:Return/kSEK/y": 717,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.33,
    "out:PV (kWp installed)": 122.531965,
    "out:PV (m2 panels)": 643,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 114968.11,
    "out:PV (kWh sold)": 76862.14,
    "out:PV (% sold (El))": 66.86,
    "out:PV (kWh self-consumed)": 38105.97,
    "out:PV (ratio sold/self-consumed)": 2.02,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 875718.530813,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 226292,
    "out:EL kWh savings": 14453,
    "out:Sewage heat recovery cost": 250448,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 328496,
    "out:DH kr savings": 205926,
    "out:El kr savings": 24571,
    "out:El kr sold": 97999,
    "out:El kr saved": 64780,
    "out:El kr return": 162779,
    "out:% solar/total": 47,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 504159,
    "out:% savings (space heating)": 23.45,
    "out:Total_Water Heating (DH)": 152130,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 128.05,
    "out:Etvv": 17,
    "out:Ef": 13.09,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000000120001110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 92.48,
    "out:DHW (DH)": 29.58,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 13.09,
    "out:Total Energy Use Pre PV": 142.5,
    "out:Total Energy Use Post PV": 135.09,
    "out:Primary Energy": 100.2,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 26.3,
    "out:CO2 Operational/m2": -30.07,
    "out:Total CO2/m2": -3.77,
    "out:Total CO2 (tons)": -19.38,
    "out:Klimatpaverkan": -348.62,
    "out:Initial Cost/MSEK": 5.84025,
    "out:Running cost/(Apt SEK y)": 39655,
    "out:Running Cost over RSP/MSEK": 13.052,
    "out:LCP/MSEK": 0.377,
    "out:ROI% old": 15.21,
    "out:Payback discounted": 8,
    "out:Atemp": 5143,
    "out:Umean": 0.82,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 135,
    "out:Energy use kWh/m2": 135,
    "out:Energy savings %": 39.3,
    "out:Op energy cost/MSEK": 97,
    "out:El price/MSEK": 56,
    "out:Heating price/MSEK": 35,
    "out:DH+ DHW/kWh/m2": 122,
    "out:El bought/kWh/m2": 923.7,
    "out:Return %": 14,
    "out:Return/kSEK/y": 798,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.33,
    "out:PV (kWp installed)": 122.531965,
    "out:PV (m2 panels)": 643,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 114968.11,
    "out:PV (kWh sold)": 76862.14,
    "out:PV (% sold (El))": 66.86,
    "out:PV (kWh self-consumed)": 38105.97,
    "out:PV (ratio sold/self-consumed)": 2.02,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 875718.530813,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 257150,
    "out:EL kWh savings": 14453,
    "out:Sewage heat recovery cost": 250448,
    "out:Balancing hydronic system cost": 128574.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 356576,
    "out:DH kr savings": 234006,
    "out:El kr savings": 24571,
    "out:El kr sold": 97999,
    "out:El kr saved": 64780,
    "out:El kr return": 162779,
    "out:% solar/total": 47,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 475626,
    "out:% savings (space heating)": 27.78,
    "out:Total_Water Heating (DH)": 152130,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 122.51,
    "out:Etvv": 17,
    "out:Ef": 13.09,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000100000000010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 87.05,
    "out:DHW (DH)": 43.5,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 20.5,
    "out:Total Energy Use Pre PV": 151.5,
    "out:Total Energy Use Post PV": 151.5,
    "out:Primary Energy": 115.34,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 3.21,
    "out:CO2 Operational/m2": 49.86,
    "out:Total CO2/m2": 53.08,
    "out:Total CO2 (tons)": 272.98,
    "out:Klimatpaverkan": -56.26,
    "out:Initial Cost/MSEK": 4.03225,
    "out:Running cost/(Apt SEK y)": 46427,
    "out:Running Cost over RSP/MSEK": 15.243,
    "out:LCP/MSEK": -0.006,
    "out:ROI% old": 14.26,
    "out:Payback discounted": 9,
    "out:Atemp": 5143,
    "out:Umean": 0.67,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FTX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 151,
    "out:Energy use kWh/m2": 151,
    "out:Energy savings %": 24.5,
    "out:Op energy cost/MSEK": 111,
    "out:El price/MSEK": 68,
    "out:Heating price/MSEK": 38,
    "out:DH+ DHW/kWh/m2": 131,
    "out:El bought/kWh/m2": 1434.9,
    "out:Return %": 13,
    "out:Return/kSEK/y": 514,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 875718.530813,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 210863,
    "out:EL kWh savings": -23653,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 151676,
    "out:DH kr savings": 191885,
    "out:El kr savings": -40210,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 447712,
    "out:% savings (space heating)": 32.02,
    "out:Total_Water Heating (DH)": 223720,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 117.08,
    "out:Etvv": 25,
    "out:Ef": 20.5,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000100000000110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 82,
    "out:DHW (DH)": 43.5,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 20.5,
    "out:Total Energy Use Pre PV": 146.5,
    "out:Total Energy Use Post PV": 146.5,
    "out:Primary Energy": 111.8,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 3.21,
    "out:CO2 Operational/m2": 48.06,
    "out:Total CO2/m2": 51.28,
    "out:Total CO2 (tons)": 263.72,
    "out:Klimatpaverkan": -65.52,
    "out:Initial Cost/MSEK": 4.160875,
    "out:Running cost/(Apt SEK y)": 44818,
    "out:Running Cost over RSP/MSEK": 14.714,
    "out:LCP/MSEK": 0.394,
    "out:ROI% old": 15.64,
    "out:Payback discounted": 8,
    "out:Atemp": 5143,
    "out:Umean": 0.67,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FTX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 146,
    "out:Energy use kWh/m2": 146,
    "out:Energy savings %": 28.8,
    "out:Op energy cost/MSEK": 108,
    "out:El price/MSEK": 65,
    "out:Heating price/MSEK": 36,
    "out:DH+ DHW/kWh/m2": 126,
    "out:El bought/kWh/m2": 1434.9,
    "out:Return %": 14,
    "out:Return/kSEK/y": 582,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 875718.530813,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 236578,
    "out:EL kWh savings": -23653,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 128574.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 175076,
    "out:DH kr savings": 215286,
    "out:El kr savings": -40210,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 421745,
    "out:% savings (space heating)": 35.96,
    "out:Total_Water Heating (DH)": 223720,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 112.03,
    "out:Etvv": 25,
    "out:Ef": 20.5,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000100000001010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 87.05,
    "out:DHW (DH)": 36.98,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 20.5,
    "out:Total Energy Use Pre PV": 144.5,
    "out:Total Energy Use Post PV": 144.5,
    "out:Primary Energy": 112.71,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 3.62,
    "out:CO2 Operational/m2": 47.34,
    "out:Total CO2/m2": 50.96,
    "out:Total CO2 (tons)": 262.09,
    "out:Klimatpaverkan": -67.15,
    "out:Initial Cost/MSEK": 4.28275,
    "out:Running cost/(Apt SEK y)": 44174,
    "out:Running Cost over RSP/MSEK": 14.502,
    "out:LCP/MSEK": 0.484,
    "out:ROI% old": 15.9,
    "out:Payback discounted": 8,
    "out:Atemp": 5143,
    "out:Umean": 0.67,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FTX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 144,
    "out:Energy use kWh/m2": 144,
    "out:Energy savings %": 30.6,
    "out:Op energy cost/MSEK": 106,
    "out:El price/MSEK": 65,
    "out:Heating price/MSEK": 36,
    "out:DH+ DHW/kWh/m2": 124,
    "out:El bought/kWh/m2": 1434.9,
    "out:Return %": 14,
    "out:Return/kSEK/y": 609,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 875718.530813,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 246864,
    "out:EL kWh savings": -23653,
    "out:Sewage heat recovery cost": 250448,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 184437,
    "out:DH kr savings": 224646,
    "out:El kr savings": -40210,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 447712,
    "out:% savings (space heating)": 32.02,
    "out:Total_Water Heating (DH)": 190162,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 117.08,
    "out:Etvv": 21.25,
    "out:Ef": 20.5,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000100000001110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 82,
    "out:DHW (DH)": 36.98,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 20.5,
    "out:Total Energy Use Pre PV": 139.5,
    "out:Total Energy Use Post PV": 139.5,
    "out:Primary Energy": 109.18,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 3.62,
    "out:CO2 Operational/m2": 45.54,
    "out:Total CO2/m2": 49.16,
    "out:Total CO2 (tons)": 252.83,
    "out:Klimatpaverkan": -76.41,
    "out:Initial Cost/MSEK": 4.41125,
    "out:Running cost/(Apt SEK y)": 42565,
    "out:Running Cost over RSP/MSEK": 13.974,
    "out:LCP/MSEK": 0.884,
    "out:ROI% old": 17.15,
    "out:Payback discounted": 7,
    "out:Atemp": 5143,
    "out:Umean": 0.67,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FTX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 139,
    "out:Energy use kWh/m2": 139,
    "out:Energy savings %": 35.3,
    "out:Op energy cost/MSEK": 102,
    "out:El price/MSEK": 62,
    "out:Heating price/MSEK": 34,
    "out:DH+ DHW/kWh/m2": 119,
    "out:El bought/kWh/m2": 1434.9,
    "out:Return %": 15,
    "out:Return/kSEK/y": 676,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 875718.530813,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 272579,
    "out:EL kWh savings": -23653,
    "out:Sewage heat recovery cost": 250448,
    "out:Balancing hydronic system cost": 128574.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 207837,
    "out:DH kr savings": 248047,
    "out:El kr savings": -40210,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 421745,
    "out:% savings (space heating)": 35.96,
    "out:Total_Water Heating (DH)": 190162,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 112.03,
    "out:Etvv": 21.25,
    "out:Ef": 20.5,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000100010000010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 87.05,
    "out:DHW (DH)": 43.5,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 14.33,
    "out:Total Energy Use Pre PV": 151.5,
    "out:Total Energy Use Post PV": 145.33,
    "out:Primary Energy": 104.23,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 15.57,
    "out:CO2 Operational/m2": 23.85,
    "out:Total CO2/m2": 39.42,
    "out:Total CO2 (tons)": 202.72,
    "out:Klimatpaverkan": -126.52,
    "out:Initial Cost/MSEK": 5.102125,
    "out:Running cost/(Apt SEK y)": 44361,
    "out:Running Cost over RSP/MSEK": 14.582,
    "out:LCP/MSEK": -0.415,
    "out:ROI% old": 13.12,
    "out:Payback discounted": 9,
    "out:Atemp": 5143,
    "out:Umean": 0.67,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FTX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 145,
    "out:Energy use kWh/m2": 145,
    "out:Energy savings %": 29.7,
    "out:Op energy cost/MSEK": 107,
    "out:El price/MSEK": 64,
    "out:Heating price/MSEK": 38,
    "out:DH+ DHW/kWh/m2": 131,
    "out:El bought/kWh/m2": 1013.1,
    "out:Return %": 12,
    "out:Return/kSEK/y": 601,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 61.265983,
    "out:PV (m2 panels)": 321,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 57484.06,
    "out:PV (kWh sold)": 25763.95,
    "out:PV (% sold (El))": 44.82,
    "out:PV (kWh self-consumed)": 31720.11,
    "out:PV (ratio sold/self-consumed)": 0.81,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 875718.530813,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 210863,
    "out:EL kWh savings": 8067,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 238449,
    "out:DH kr savings": 191885,
    "out:El kr savings": 13715,
    "out:El kr sold": 32849,
    "out:El kr saved": 53924,
    "out:El kr return": 86773,
    "out:% solar/total": 39,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 447712,
    "out:% savings (space heating)": 32.02,
    "out:Total_Water Heating (DH)": 223720,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 117.08,
    "out:Etvv": 25,
    "out:Ef": 14.33,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000100010000110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 82,
    "out:DHW (DH)": 43.5,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 14.33,
    "out:Total Energy Use Pre PV": 146.5,
    "out:Total Energy Use Post PV": 140.33,
    "out:Primary Energy": 100.7,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 15.57,
    "out:CO2 Operational/m2": 22.05,
    "out:Total CO2/m2": 37.62,
    "out:Total CO2 (tons)": 193.46,
    "out:Klimatpaverkan": -135.78,
    "out:Initial Cost/MSEK": 5.230625,
    "out:Running cost/(Apt SEK y)": 42752,
    "out:Running Cost over RSP/MSEK": 14.053,
    "out:LCP/MSEK": -0.015,
    "out:ROI% old": 14.24,
    "out:Payback discounted": 9,
    "out:Atemp": 5143,
    "out:Umean": 0.67,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FTX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 140,
    "out:Energy use kWh/m2": 140,
    "out:Energy savings %": 34.3,
    "out:Op energy cost/MSEK": 104,
    "out:El price/MSEK": 61,
    "out:Heating price/MSEK": 36,
    "out:DH+ DHW/kWh/m2": 126,
    "out:El bought/kWh/m2": 1013.1,
    "out:Return %": 13,
    "out:Return/kSEK/y": 668,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 61.265983,
    "out:PV (m2 panels)": 321,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 57484.06,
    "out:PV (kWh sold)": 25763.95,
    "out:PV (% sold (El))": 44.82,
    "out:PV (kWh self-consumed)": 31720.11,
    "out:PV (ratio sold/self-consumed)": 0.81,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 875718.530813,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 236578,
    "out:EL kWh savings": 8067,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 128574.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 261850,
    "out:DH kr savings": 215286,
    "out:El kr savings": 13715,
    "out:El kr sold": 32849,
    "out:El kr saved": 53924,
    "out:El kr return": 86773,
    "out:% solar/total": 39,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 421745,
    "out:% savings (space heating)": 35.96,
    "out:Total_Water Heating (DH)": 223720,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 112.03,
    "out:Etvv": 25,
    "out:Ef": 14.33,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000100010001010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 87.05,
    "out:DHW (DH)": 36.98,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 14.33,
    "out:Total Energy Use Pre PV": 144.5,
    "out:Total Energy Use Post PV": 138.33,
    "out:Primary Energy": 101.61,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 15.97,
    "out:CO2 Operational/m2": 21.33,
    "out:Total CO2/m2": 37.3,
    "out:Total CO2 (tons)": 191.83,
    "out:Klimatpaverkan": -137.41,
    "out:Initial Cost/MSEK": 5.3525,
    "out:Running cost/(Apt SEK y)": 42108,
    "out:Running Cost over RSP/MSEK": 13.842,
    "out:LCP/MSEK": 0.075,
    "out:ROI% old": 14.48,
    "out:Payback discounted": 8,
    "out:Atemp": 5143,
    "out:Umean": 0.67,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FTX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 138,
    "out:Energy use kWh/m2": 138,
    "out:Energy savings %": 36.2,
    "out:Op energy cost/MSEK": 102,
    "out:El price/MSEK": 61,
    "out:Heating price/MSEK": 36,
    "out:DH+ DHW/kWh/m2": 124,
    "out:El bought/kWh/m2": 1013.1,
    "out:Return %": 13,
    "out:Return/kSEK/y": 695,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 61.265983,
    "out:PV (m2 panels)": 321,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 57484.06,
    "out:PV (kWh sold)": 25763.95,
    "out:PV (% sold (El))": 44.82,
    "out:PV (kWh self-consumed)": 31720.11,
    "out:PV (ratio sold/self-consumed)": 0.81,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 875718.530813,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 246864,
    "out:EL kWh savings": 8067,
    "out:Sewage heat recovery cost": 250448,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 271210,
    "out:DH kr savings": 224646,
    "out:El kr savings": 13715,
    "out:El kr sold": 32849,
    "out:El kr saved": 53924,
    "out:El kr return": 86773,
    "out:% solar/total": 39,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 447712,
    "out:% savings (space heating)": 32.02,
    "out:Total_Water Heating (DH)": 190162,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 117.08,
    "out:Etvv": 21.25,
    "out:Ef": 14.33,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000100010001110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 82,
    "out:DHW (DH)": 36.98,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 14.33,
    "out:Total Energy Use Pre PV": 139.5,
    "out:Total Energy Use Post PV": 133.33,
    "out:Primary Energy": 98.07,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 15.97,
    "out:CO2 Operational/m2": 19.53,
    "out:Total CO2/m2": 35.5,
    "out:Total CO2 (tons)": 182.57,
    "out:Klimatpaverkan": -146.67,
    "out:Initial Cost/MSEK": 5.481125,
    "out:Running cost/(Apt SEK y)": 40499,
    "out:Running Cost over RSP/MSEK": 13.313,
    "out:LCP/MSEK": 0.475,
    "out:ROI% old": 15.52,
    "out:Payback discounted": 8,
    "out:Atemp": 5143,
    "out:Umean": 0.67,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FTX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 133,
    "out:Energy use kWh/m2": 133,
    "out:Energy savings %": 41.4,
    "out:Op energy cost/MSEK": 98,
    "out:El price/MSEK": 58,
    "out:Heating price/MSEK": 34,
    "out:DH+ DHW/kWh/m2": 119,
    "out:El bought/kWh/m2": 1013.1,
    "out:Return %": 14,
    "out:Return/kSEK/y": 763,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 61.265983,
    "out:PV (m2 panels)": 321,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 57484.06,
    "out:PV (kWh sold)": 25763.95,
    "out:PV (% sold (El))": 44.82,
    "out:PV (kWh self-consumed)": 31720.11,
    "out:PV (ratio sold/self-consumed)": 0.81,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 875718.530813,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 272579,
    "out:EL kWh savings": 8067,
    "out:Sewage heat recovery cost": 250448,
    "out:Balancing hydronic system cost": 128574.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 294611,
    "out:DH kr savings": 248047,
    "out:El kr savings": 13715,
    "out:El kr sold": 32849,
    "out:El kr saved": 53924,
    "out:El kr return": 86773,
    "out:% solar/total": 39,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 421745,
    "out:% savings (space heating)": 35.96,
    "out:Total_Water Heating (DH)": 190162,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 112.03,
    "out:Etvv": 21.25,
    "out:Ef": 14.33,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000100020000010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 87.05,
    "out:DHW (DH)": 43.5,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 13.09,
    "out:Total Energy Use Pre PV": 151.5,
    "out:Total Energy Use Post PV": 144.09,
    "out:Primary Energy": 102,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 27.92,
    "out:CO2 Operational/m2": -26.83,
    "out:Total CO2/m2": 1.09,
    "out:Total CO2 (tons)": 5.6,
    "out:Klimatpaverkan": -323.64,
    "out:Initial Cost/MSEK": 6.171875,
    "out:Running cost/(Apt SEK y)": 42551,
    "out:Running Cost over RSP/MSEK": 14.004,
    "out:LCP/MSEK": -0.907,
    "out:ROI% old": 12.19,
    "out:Payback discounted": 10,
    "out:Atemp": 5143,
    "out:Umean": 0.67,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FTX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 144,
    "out:Energy use kWh/m2": 144,
    "out:Energy savings %": 30.6,
    "out:Op energy cost/MSEK": 104,
    "out:El price/MSEK": 60,
    "out:Heating price/MSEK": 38,
    "out:DH+ DHW/kWh/m2": 131,
    "out:El bought/kWh/m2": 923.7,
    "out:Return %": 11,
    "out:Return/kSEK/y": 677,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.33,
    "out:PV (kWp installed)": 122.531965,
    "out:PV (m2 panels)": 643,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 114968.11,
    "out:PV (kWh sold)": 76862.14,
    "out:PV (% sold (El))": 66.86,
    "out:PV (kWh self-consumed)": 38105.97,
    "out:PV (ratio sold/self-consumed)": 2.02,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 875718.530813,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 210863,
    "out:EL kWh savings": 14453,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 314455,
    "out:DH kr savings": 191885,
    "out:El kr savings": 24571,
    "out:El kr sold": 97999,
    "out:El kr saved": 64780,
    "out:El kr return": 162779,
    "out:% solar/total": 47,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 447712,
    "out:% savings (space heating)": 32.02,
    "out:Total_Water Heating (DH)": 223720,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 117.08,
    "out:Etvv": 25,
    "out:Ef": 13.09,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000100020000110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 82,
    "out:DHW (DH)": 43.5,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 13.09,
    "out:Total Energy Use Pre PV": 146.5,
    "out:Total Energy Use Post PV": 139.09,
    "out:Primary Energy": 98.46,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 27.92,
    "out:CO2 Operational/m2": -28.63,
    "out:Total CO2/m2": -0.71,
    "out:Total CO2 (tons)": -3.66,
    "out:Klimatpaverkan": -332.9,
    "out:Initial Cost/MSEK": 6.300375,
    "out:Running cost/(Apt SEK y)": 40942,
    "out:Running Cost over RSP/MSEK": 13.475,
    "out:LCP/MSEK": -0.507,
    "out:ROI% old": 13.14,
    "out:Payback discounted": 9,
    "out:Atemp": 5143,
    "out:Umean": 0.67,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FTX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 139,
    "out:Energy use kWh/m2": 139,
    "out:Energy savings %": 35.3,
    "out:Op energy cost/MSEK": 100,
    "out:El price/MSEK": 58,
    "out:Heating price/MSEK": 36,
    "out:DH+ DHW/kWh/m2": 126,
    "out:El bought/kWh/m2": 923.7,
    "out:Return %": 12,
    "out:Return/kSEK/y": 744,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.33,
    "out:PV (kWp installed)": 122.531965,
    "out:PV (m2 panels)": 643,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 114968.11,
    "out:PV (kWh sold)": 76862.14,
    "out:PV (% sold (El))": 66.86,
    "out:PV (kWh self-consumed)": 38105.97,
    "out:PV (ratio sold/self-consumed)": 2.02,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 875718.530813,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 236578,
    "out:EL kWh savings": 14453,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 128574.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 337856,
    "out:DH kr savings": 215286,
    "out:El kr savings": 24571,
    "out:El kr sold": 97999,
    "out:El kr saved": 64780,
    "out:El kr return": 162779,
    "out:% solar/total": 47,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 421745,
    "out:% savings (space heating)": 35.96,
    "out:Total_Water Heating (DH)": 223720,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 112.03,
    "out:Etvv": 25,
    "out:Ef": 13.09,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000100020001010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 87.05,
    "out:DHW (DH)": 36.98,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 13.09,
    "out:Total Energy Use Pre PV": 144.5,
    "out:Total Energy Use Post PV": 137.09,
    "out:Primary Energy": 99.37,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 28.32,
    "out:CO2 Operational/m2": -29.35,
    "out:Total CO2/m2": -1.03,
    "out:Total CO2 (tons)": -5.29,
    "out:Klimatpaverkan": -334.53,
    "out:Initial Cost/MSEK": 6.42225,
    "out:Running cost/(Apt SEK y)": 40298,
    "out:Running Cost over RSP/MSEK": 13.264,
    "out:LCP/MSEK": -0.417,
    "out:ROI% old": 13.36,
    "out:Payback discounted": 9,
    "out:Atemp": 5143,
    "out:Umean": 0.67,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FTX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 137,
    "out:Energy use kWh/m2": 137,
    "out:Energy savings %": 37.2,
    "out:Op energy cost/MSEK": 98,
    "out:El price/MSEK": 57,
    "out:Heating price/MSEK": 36,
    "out:DH+ DHW/kWh/m2": 124,
    "out:El bought/kWh/m2": 923.7,
    "out:Return %": 12,
    "out:Return/kSEK/y": 771,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.33,
    "out:PV (kWp installed)": 122.531965,
    "out:PV (m2 panels)": 643,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 114968.11,
    "out:PV (kWh sold)": 76862.14,
    "out:PV (% sold (El))": 66.86,
    "out:PV (kWh self-consumed)": 38105.97,
    "out:PV (ratio sold/self-consumed)": 2.02,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 875718.530813,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 246864,
    "out:EL kWh savings": 14453,
    "out:Sewage heat recovery cost": 250448,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 347216,
    "out:DH kr savings": 224646,
    "out:El kr savings": 24571,
    "out:El kr sold": 97999,
    "out:El kr saved": 64780,
    "out:El kr return": 162779,
    "out:% solar/total": 47,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 447712,
    "out:% savings (space heating)": 32.02,
    "out:Total_Water Heating (DH)": 190162,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 117.08,
    "out:Etvv": 21.25,
    "out:Ef": 13.09,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000100020001110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 82,
    "out:DHW (DH)": 36.98,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 13.09,
    "out:Total Energy Use Pre PV": 139.5,
    "out:Total Energy Use Post PV": 132.09,
    "out:Primary Energy": 95.84,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 28.32,
    "out:CO2 Operational/m2": -31.15,
    "out:Total CO2/m2": -2.83,
    "out:Total CO2 (tons)": -14.55,
    "out:Klimatpaverkan": -343.79,
    "out:Initial Cost/MSEK": 6.550875,
    "out:Running cost/(Apt SEK y)": 38689,
    "out:Running Cost over RSP/MSEK": 12.735,
    "out:LCP/MSEK": -0.017,
    "out:ROI% old": 14.25,
    "out:Payback discounted": 9,
    "out:Atemp": 5143,
    "out:Umean": 0.67,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FTX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 132,
    "out:Energy use kWh/m2": 132,
    "out:Energy savings %": 42.4,
    "out:Op energy cost/MSEK": 95,
    "out:El price/MSEK": 55,
    "out:Heating price/MSEK": 34,
    "out:DH+ DHW/kWh/m2": 119,
    "out:El bought/kWh/m2": 923.7,
    "out:Return %": 13,
    "out:Return/kSEK/y": 839,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.33,
    "out:PV (kWp installed)": 122.531965,
    "out:PV (m2 panels)": 643,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 114968.11,
    "out:PV (kWh sold)": 76862.14,
    "out:PV (% sold (El))": 66.86,
    "out:PV (kWh self-consumed)": 38105.97,
    "out:PV (ratio sold/self-consumed)": 2.02,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 875718.530813,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 272579,
    "out:EL kWh savings": 14453,
    "out:Sewage heat recovery cost": 250448,
    "out:Balancing hydronic system cost": 128574.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 370617,
    "out:DH kr savings": 248047,
    "out:El kr savings": 24571,
    "out:El kr sold": 97999,
    "out:El kr saved": 64780,
    "out:El kr return": 162779,
    "out:% solar/total": 47,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 421745,
    "out:% savings (space heating)": 35.96,
    "out:Total_Water Heating (DH)": 190162,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 112.03,
    "out:Etvv": 21.25,
    "out:Ef": 13.09,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000100100000010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 87.05,
    "out:DHW (DH)": 34.8,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 20.5,
    "out:Total Energy Use Pre PV": 142.5,
    "out:Total Energy Use Post PV": 142.5,
    "out:Primary Energy": 111.84,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 3.21,
    "out:CO2 Operational/m2": 46.62,
    "out:Total CO2/m2": 49.84,
    "out:Total CO2 (tons)": 256.32,
    "out:Klimatpaverkan": -72.92,
    "out:Initial Cost/MSEK": 4.143125,
    "out:Running cost/(Apt SEK y)": 43530,
    "out:Running Cost over RSP/MSEK": 14.291,
    "out:LCP/MSEK": 0.835,
    "out:ROI% old": 17.17,
    "out:Payback discounted": 7,
    "out:Atemp": 5143,
    "out:Umean": 0.67,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FTX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 142,
    "out:Energy use kWh/m2": 142,
    "out:Energy savings %": 32.4,
    "out:Op energy cost/MSEK": 104,
    "out:El price/MSEK": 64,
    "out:Heating price/MSEK": 35,
    "out:DH+ DHW/kWh/m2": 122,
    "out:El bought/kWh/m2": 1434.9,
    "out:Return %": 15,
    "out:Return/kSEK/y": 636,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 875718.530813,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 257150,
    "out:EL kWh savings": -23653,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 193797,
    "out:DH kr savings": 234006,
    "out:El kr savings": -40210,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 447712,
    "out:% savings (space heating)": 32.02,
    "out:Total_Water Heating (DH)": 178976,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 117.08,
    "out:Etvv": 20,
    "out:Ef": 20.5,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000100100000110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 82,
    "out:DHW (DH)": 34.8,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 20.5,
    "out:Total Energy Use Pre PV": 137.5,
    "out:Total Energy Use Post PV": 137.5,
    "out:Primary Energy": 108.3,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 3.21,
    "out:CO2 Operational/m2": 44.82,
    "out:Total CO2/m2": 48.04,
    "out:Total CO2 (tons)": 247.06,
    "out:Klimatpaverkan": -82.18,
    "out:Initial Cost/MSEK": 4.27175,
    "out:Running cost/(Apt SEK y)": 41921,
    "out:Running Cost over RSP/MSEK": 13.762,
    "out:LCP/MSEK": 1.235,
    "out:ROI% old": 18.42,
    "out:Payback discounted": 7,
    "out:Atemp": 5143,
    "out:Umean": 0.67,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FTX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 137,
    "out:Energy use kWh/m2": 137,
    "out:Energy savings %": 37.2,
    "out:Op energy cost/MSEK": 101,
    "out:El price/MSEK": 61,
    "out:Heating price/MSEK": 34,
    "out:DH+ DHW/kWh/m2": 117,
    "out:El bought/kWh/m2": 1434.9,
    "out:Return %": 16,
    "out:Return/kSEK/y": 703,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 875718.530813,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 282865,
    "out:EL kWh savings": -23653,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 128574.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 217198,
    "out:DH kr savings": 257407,
    "out:El kr savings": -40210,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 421745,
    "out:% savings (space heating)": 35.96,
    "out:Total_Water Heating (DH)": 178976,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 112.03,
    "out:Etvv": 20,
    "out:Ef": 20.5,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000100100001010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 87.05,
    "out:DHW (DH)": 29.58,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 20.5,
    "out:Total Energy Use Pre PV": 137.5,
    "out:Total Energy Use Post PV": 137.5,
    "out:Primary Energy": 109.74,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 3.62,
    "out:CO2 Operational/m2": 44.82,
    "out:Total CO2/m2": 48.44,
    "out:Total CO2 (tons)": 249.13,
    "out:Klimatpaverkan": -80.11,
    "out:Initial Cost/MSEK": 4.393625,
    "out:Running cost/(Apt SEK y)": 41921,
    "out:Running Cost over RSP/MSEK": 13.762,
    "out:LCP/MSEK": 1.113,
    "out:ROI% old": 17.91,
    "out:Payback discounted": 7,
    "out:Atemp": 5143,
    "out:Umean": 0.67,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FTX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 137,
    "out:Energy use kWh/m2": 137,
    "out:Energy savings %": 37.2,
    "out:Op energy cost/MSEK": 101,
    "out:El price/MSEK": 61,
    "out:Heating price/MSEK": 34,
    "out:DH+ DHW/kWh/m2": 117,
    "out:El bought/kWh/m2": 1434.9,
    "out:Return %": 16,
    "out:Return/kSEK/y": 703,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 875718.530813,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 282865,
    "out:EL kWh savings": -23653,
    "out:Sewage heat recovery cost": 250448,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 217198,
    "out:DH kr savings": 257407,
    "out:El kr savings": -40210,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 447712,
    "out:% savings (space heating)": 32.02,
    "out:Total_Water Heating (DH)": 152130,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 117.08,
    "out:Etvv": 17,
    "out:Ef": 20.5,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000100100001110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 82,
    "out:DHW (DH)": 29.58,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 20.5,
    "out:Total Energy Use Pre PV": 132.5,
    "out:Total Energy Use Post PV": 132.5,
    "out:Primary Energy": 106.2,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 3.62,
    "out:CO2 Operational/m2": 43.02,
    "out:Total CO2/m2": 46.64,
    "out:Total CO2 (tons)": 239.87,
    "out:Klimatpaverkan": -89.37,
    "out:Initial Cost/MSEK": 4.522125,
    "out:Running cost/(Apt SEK y)": 40312,
    "out:Running Cost over RSP/MSEK": 13.233,
    "out:LCP/MSEK": 1.514,
    "out:ROI% old": 19.07,
    "out:Payback discounted": 6,
    "out:Atemp": 5143,
    "out:Umean": 0.67,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FTX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 132,
    "out:Energy use kWh/m2": 132,
    "out:Energy savings %": 42.4,
    "out:Op energy cost/MSEK": 97,
    "out:El price/MSEK": 59,
    "out:Heating price/MSEK": 32,
    "out:DH+ DHW/kWh/m2": 112,
    "out:El bought/kWh/m2": 1434.9,
    "out:Return %": 17,
    "out:Return/kSEK/y": 771,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 875718.530813,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 308580,
    "out:EL kWh savings": -23653,
    "out:Sewage heat recovery cost": 250448,
    "out:Balancing hydronic system cost": 128574.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 240598,
    "out:DH kr savings": 280808,
    "out:El kr savings": -40210,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 421745,
    "out:% savings (space heating)": 35.96,
    "out:Total_Water Heating (DH)": 152130,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 112.03,
    "out:Etvv": 17,
    "out:Ef": 20.5,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000100110000010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 87.05,
    "out:DHW (DH)": 34.8,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 14.33,
    "out:Total Energy Use Pre PV": 142.5,
    "out:Total Energy Use Post PV": 136.33,
    "out:Primary Energy": 100.73,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 15.57,
    "out:CO2 Operational/m2": 20.61,
    "out:Total CO2/m2": 36.18,
    "out:Total CO2 (tons)": 186.06,
    "out:Klimatpaverkan": -143.18,
    "out:Initial Cost/MSEK": 5.212875,
    "out:Running cost/(Apt SEK y)": 41464,
    "out:Running Cost over RSP/MSEK": 13.63,
    "out:LCP/MSEK": 0.426,
    "out:ROI% old": 15.45,
    "out:Payback discounted": 8,
    "out:Atemp": 5143,
    "out:Umean": 0.67,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FTX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 136,
    "out:Energy use kWh/m2": 136,
    "out:Energy savings %": 38.2,
    "out:Op energy cost/MSEK": 100,
    "out:El price/MSEK": 60,
    "out:Heating price/MSEK": 35,
    "out:DH+ DHW/kWh/m2": 122,
    "out:El bought/kWh/m2": 1013.1,
    "out:Return %": 14,
    "out:Return/kSEK/y": 722,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 61.265983,
    "out:PV (m2 panels)": 321,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 57484.06,
    "out:PV (kWh sold)": 25763.95,
    "out:PV (% sold (El))": 44.82,
    "out:PV (kWh self-consumed)": 31720.11,
    "out:PV (ratio sold/self-consumed)": 0.81,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 875718.530813,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 257150,
    "out:EL kWh savings": 8067,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 280570,
    "out:DH kr savings": 234006,
    "out:El kr savings": 13715,
    "out:El kr sold": 32849,
    "out:El kr saved": 53924,
    "out:El kr return": 86773,
    "out:% solar/total": 39,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 447712,
    "out:% savings (space heating)": 32.02,
    "out:Total_Water Heating (DH)": 178976,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 117.08,
    "out:Etvv": 20,
    "out:Ef": 14.33,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000100110000110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 82,
    "out:DHW (DH)": 34.8,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 14.33,
    "out:Total Energy Use Pre PV": 137.5,
    "out:Total Energy Use Post PV": 131.33,
    "out:Primary Energy": 97.2,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 15.57,
    "out:CO2 Operational/m2": 18.81,
    "out:Total CO2/m2": 34.38,
    "out:Total CO2 (tons)": 176.8,
    "out:Klimatpaverkan": -152.44,
    "out:Initial Cost/MSEK": 5.3415,
    "out:Running cost/(Apt SEK y)": 39855,
    "out:Running Cost over RSP/MSEK": 13.101,
    "out:LCP/MSEK": 0.826,
    "out:ROI% old": 16.5,
    "out:Payback discounted": 7,
    "out:Atemp": 5143,
    "out:Umean": 0.67,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FTX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 131,
    "out:Energy use kWh/m2": 131,
    "out:Energy savings %": 43.5,
    "out:Op energy cost/MSEK": 96,
    "out:El price/MSEK": 57,
    "out:Heating price/MSEK": 34,
    "out:DH+ DHW/kWh/m2": 117,
    "out:El bought/kWh/m2": 1013.1,
    "out:Return %": 15,
    "out:Return/kSEK/y": 790,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 61.265983,
    "out:PV (m2 panels)": 321,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 57484.06,
    "out:PV (kWh sold)": 25763.95,
    "out:PV (% sold (El))": 44.82,
    "out:PV (kWh self-consumed)": 31720.11,
    "out:PV (ratio sold/self-consumed)": 0.81,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 875718.530813,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 282865,
    "out:EL kWh savings": 8067,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 128574.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 303971,
    "out:DH kr savings": 257407,
    "out:El kr savings": 13715,
    "out:El kr sold": 32849,
    "out:El kr saved": 53924,
    "out:El kr return": 86773,
    "out:% solar/total": 39,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 421745,
    "out:% savings (space heating)": 35.96,
    "out:Total_Water Heating (DH)": 178976,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 112.03,
    "out:Etvv": 20,
    "out:Ef": 14.33,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000100110001010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 87.05,
    "out:DHW (DH)": 29.58,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 14.33,
    "out:Total Energy Use Pre PV": 137.5,
    "out:Total Energy Use Post PV": 131.33,
    "out:Primary Energy": 98.63,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 15.97,
    "out:CO2 Operational/m2": 18.81,
    "out:Total CO2/m2": 34.78,
    "out:Total CO2 (tons)": 178.87,
    "out:Klimatpaverkan": -150.37,
    "out:Initial Cost/MSEK": 5.463375,
    "out:Running cost/(Apt SEK y)": 39855,
    "out:Running Cost over RSP/MSEK": 13.101,
    "out:LCP/MSEK": 0.704,
    "out:ROI% old": 16.13,
    "out:Payback discounted": 8,
    "out:Atemp": 5143,
    "out:Umean": 0.67,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FTX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 131,
    "out:Energy use kWh/m2": 131,
    "out:Energy savings %": 43.5,
    "out:Op energy cost/MSEK": 96,
    "out:El price/MSEK": 57,
    "out:Heating price/MSEK": 34,
    "out:DH+ DHW/kWh/m2": 117,
    "out:El bought/kWh/m2": 1013.1,
    "out:Return %": 14,
    "out:Return/kSEK/y": 790,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 61.265983,
    "out:PV (m2 panels)": 321,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 57484.06,
    "out:PV (kWh sold)": 25763.95,
    "out:PV (% sold (El))": 44.82,
    "out:PV (kWh self-consumed)": 31720.11,
    "out:PV (ratio sold/self-consumed)": 0.81,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 875718.530813,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 282865,
    "out:EL kWh savings": 8067,
    "out:Sewage heat recovery cost": 250448,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 303971,
    "out:DH kr savings": 257407,
    "out:El kr savings": 13715,
    "out:El kr sold": 32849,
    "out:El kr saved": 53924,
    "out:El kr return": 86773,
    "out:% solar/total": 39,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 447712,
    "out:% savings (space heating)": 32.02,
    "out:Total_Water Heating (DH)": 152130,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 117.08,
    "out:Etvv": 17,
    "out:Ef": 14.33,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000100110001110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 82,
    "out:DHW (DH)": 29.58,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 14.33,
    "out:Total Energy Use Pre PV": 132.5,
    "out:Total Energy Use Post PV": 126.33,
    "out:Primary Energy": 95.1,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 15.97,
    "out:CO2 Operational/m2": 17.01,
    "out:Total CO2/m2": 32.98,
    "out:Total CO2 (tons)": 169.61,
    "out:Klimatpaverkan": -159.63,
    "out:Initial Cost/MSEK": 5.592,
    "out:Running cost/(Apt SEK y)": 38246,
    "out:Running Cost over RSP/MSEK": 12.573,
    "out:LCP/MSEK": 1.105,
    "out:ROI% old": 17.11,
    "out:Payback discounted": 7,
    "out:Atemp": 5143,
    "out:Umean": 0.67,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FTX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 126,
    "out:Energy use kWh/m2": 126,
    "out:Energy savings %": 49.2,
    "out:Op energy cost/MSEK": 93,
    "out:El price/MSEK": 55,
    "out:Heating price/MSEK": 32,
    "out:DH+ DHW/kWh/m2": 112,
    "out:El bought/kWh/m2": 1013.1,
    "out:Return %": 15,
    "out:Return/kSEK/y": 858,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 61.265983,
    "out:PV (m2 panels)": 321,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 57484.06,
    "out:PV (kWh sold)": 25763.95,
    "out:PV (% sold (El))": 44.82,
    "out:PV (kWh self-consumed)": 31720.11,
    "out:PV (ratio sold/self-consumed)": 0.81,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 875718.530813,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 308580,
    "out:EL kWh savings": 8067,
    "out:Sewage heat recovery cost": 250448,
    "out:Balancing hydronic system cost": 128574.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 327371,
    "out:DH kr savings": 280808,
    "out:El kr savings": 13715,
    "out:El kr sold": 32849,
    "out:El kr saved": 53924,
    "out:El kr return": 86773,
    "out:% solar/total": 39,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 421745,
    "out:% savings (space heating)": 35.96,
    "out:Total_Water Heating (DH)": 152130,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 112.03,
    "out:Etvv": 17,
    "out:Ef": 14.33,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000100120000010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 87.05,
    "out:DHW (DH)": 34.8,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 13.09,
    "out:Total Energy Use Pre PV": 142.5,
    "out:Total Energy Use Post PV": 135.09,
    "out:Primary Energy": 98.5,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 27.92,
    "out:CO2 Operational/m2": -30.07,
    "out:Total CO2/m2": -2.15,
    "out:Total CO2 (tons)": -11.06,
    "out:Klimatpaverkan": -340.3,
    "out:Initial Cost/MSEK": 6.28275,
    "out:Running cost/(Apt SEK y)": 39655,
    "out:Running Cost over RSP/MSEK": 13.052,
    "out:LCP/MSEK": -0.066,
    "out:ROI% old": 14.14,
    "out:Payback discounted": 9,
    "out:Atemp": 5143,
    "out:Umean": 0.67,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FTX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 135,
    "out:Energy use kWh/m2": 135,
    "out:Energy savings %": 39.3,
    "out:Op energy cost/MSEK": 97,
    "out:El price/MSEK": 56,
    "out:Heating price/MSEK": 35,
    "out:DH+ DHW/kWh/m2": 122,
    "out:El bought/kWh/m2": 923.7,
    "out:Return %": 13,
    "out:Return/kSEK/y": 798,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.33,
    "out:PV (kWp installed)": 122.531965,
    "out:PV (m2 panels)": 643,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 114968.11,
    "out:PV (kWh sold)": 76862.14,
    "out:PV (% sold (El))": 66.86,
    "out:PV (kWh self-consumed)": 38105.97,
    "out:PV (ratio sold/self-consumed)": 2.02,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 875718.530813,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 257150,
    "out:EL kWh savings": 14453,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 356576,
    "out:DH kr savings": 234006,
    "out:El kr savings": 24571,
    "out:El kr sold": 97999,
    "out:El kr saved": 64780,
    "out:El kr return": 162779,
    "out:% solar/total": 47,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 447712,
    "out:% savings (space heating)": 32.02,
    "out:Total_Water Heating (DH)": 178976,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 117.08,
    "out:Etvv": 20,
    "out:Ef": 13.09,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000100120000110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 82,
    "out:DHW (DH)": 34.8,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 13.09,
    "out:Total Energy Use Pre PV": 137.5,
    "out:Total Energy Use Post PV": 130.09,
    "out:Primary Energy": 94.96,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 27.92,
    "out:CO2 Operational/m2": -31.87,
    "out:Total CO2/m2": -3.95,
    "out:Total CO2 (tons)": -20.32,
    "out:Klimatpaverkan": -349.56,
    "out:Initial Cost/MSEK": 6.41125,
    "out:Running cost/(Apt SEK y)": 38046,
    "out:Running Cost over RSP/MSEK": 12.523,
    "out:LCP/MSEK": 0.334,
    "out:ROI% old": 15.03,
    "out:Payback discounted": 8,
    "out:Atemp": 5143,
    "out:Umean": 0.67,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FTX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 130,
    "out:Energy use kWh/m2": 130,
    "out:Energy savings %": 44.6,
    "out:Op energy cost/MSEK": 93,
    "out:El price/MSEK": 54,
    "out:Heating price/MSEK": 34,
    "out:DH+ DHW/kWh/m2": 117,
    "out:El bought/kWh/m2": 923.7,
    "out:Return %": 14,
    "out:Return/kSEK/y": 866,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.33,
    "out:PV (kWp installed)": 122.531965,
    "out:PV (m2 panels)": 643,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 114968.11,
    "out:PV (kWh sold)": 76862.14,
    "out:PV (% sold (El))": 66.86,
    "out:PV (kWh self-consumed)": 38105.97,
    "out:PV (ratio sold/self-consumed)": 2.02,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 875718.530813,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 282865,
    "out:EL kWh savings": 14453,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 128574.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 379977,
    "out:DH kr savings": 257407,
    "out:El kr savings": 24571,
    "out:El kr sold": 97999,
    "out:El kr saved": 64780,
    "out:El kr return": 162779,
    "out:% solar/total": 47,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 421745,
    "out:% savings (space heating)": 35.96,
    "out:Total_Water Heating (DH)": 178976,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 112.03,
    "out:Etvv": 20,
    "out:Ef": 13.09,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000100120001010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 87.05,
    "out:DHW (DH)": 29.58,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 13.09,
    "out:Total Energy Use Pre PV": 137.5,
    "out:Total Energy Use Post PV": 130.09,
    "out:Primary Energy": 96.4,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 28.32,
    "out:CO2 Operational/m2": -31.87,
    "out:Total CO2/m2": -3.55,
    "out:Total CO2 (tons)": -18.25,
    "out:Klimatpaverkan": -347.49,
    "out:Initial Cost/MSEK": 6.533125,
    "out:Running cost/(Apt SEK y)": 38046,
    "out:Running Cost over RSP/MSEK": 12.523,
    "out:LCP/MSEK": 0.213,
    "out:ROI% old": 14.75,
    "out:Payback discounted": 8,
    "out:Atemp": 5143,
    "out:Umean": 0.67,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FTX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 130,
    "out:Energy use kWh/m2": 130,
    "out:Energy savings %": 44.6,
    "out:Op energy cost/MSEK": 93,
    "out:El price/MSEK": 54,
    "out:Heating price/MSEK": 34,
    "out:DH+ DHW/kWh/m2": 117,
    "out:El bought/kWh/m2": 923.7,
    "out:Return %": 13,
    "out:Return/kSEK/y": 866,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.33,
    "out:PV (kWp installed)": 122.531965,
    "out:PV (m2 panels)": 643,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 114968.11,
    "out:PV (kWh sold)": 76862.14,
    "out:PV (% sold (El))": 66.86,
    "out:PV (kWh self-consumed)": 38105.97,
    "out:PV (ratio sold/self-consumed)": 2.02,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 875718.530813,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 282865,
    "out:EL kWh savings": 14453,
    "out:Sewage heat recovery cost": 250448,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 379977,
    "out:DH kr savings": 257407,
    "out:El kr savings": 24571,
    "out:El kr sold": 97999,
    "out:El kr saved": 64780,
    "out:El kr return": 162779,
    "out:% solar/total": 47,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 447712,
    "out:% savings (space heating)": 32.02,
    "out:Total_Water Heating (DH)": 152130,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 117.08,
    "out:Etvv": 17,
    "out:Ef": 13.09,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000100120001110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 82,
    "out:DHW (DH)": 29.58,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 13.09,
    "out:Total Energy Use Pre PV": 132.5,
    "out:Total Energy Use Post PV": 125.09,
    "out:Primary Energy": 92.86,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 28.32,
    "out:CO2 Operational/m2": -33.67,
    "out:Total CO2/m2": -5.35,
    "out:Total CO2 (tons)": -27.51,
    "out:Klimatpaverkan": -356.75,
    "out:Initial Cost/MSEK": 6.66175,
    "out:Running cost/(Apt SEK y)": 36436,
    "out:Running Cost over RSP/MSEK": 11.994,
    "out:LCP/MSEK": 0.613,
    "out:ROI% old": 15.6,
    "out:Payback discounted": 8,
    "out:Atemp": 5143,
    "out:Umean": 0.67,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FTX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 125,
    "out:Energy use kWh/m2": 125,
    "out:Energy savings %": 50.4,
    "out:Op energy cost/MSEK": 89,
    "out:El price/MSEK": 52,
    "out:Heating price/MSEK": 32,
    "out:DH+ DHW/kWh/m2": 112,
    "out:El bought/kWh/m2": 923.7,
    "out:Return %": 14,
    "out:Return/kSEK/y": 934,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.33,
    "out:PV (kWp installed)": 122.531965,
    "out:PV (m2 panels)": 643,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 114968.11,
    "out:PV (kWh sold)": 76862.14,
    "out:PV (% sold (El))": 66.86,
    "out:PV (kWh self-consumed)": 38105.97,
    "out:PV (ratio sold/self-consumed)": 2.02,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 875718.530813,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 308580,
    "out:EL kWh savings": 14453,
    "out:Sewage heat recovery cost": 250448,
    "out:Balancing hydronic system cost": 128574.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 403378,
    "out:DH kr savings": 280808,
    "out:El kr savings": 24571,
    "out:El kr sold": 97999,
    "out:El kr saved": 64780,
    "out:El kr return": 162779,
    "out:% solar/total": 47,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 421745,
    "out:% savings (space heating)": 35.96,
    "out:Total_Water Heating (DH)": 152130,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 112.03,
    "out:Etvv": 17,
    "out:Ef": 13.09,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000200000000010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 83.78,
    "out:DHW (DH)": 43.5,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 20.5,
    "out:Total Energy Use Pre PV": 147.5,
    "out:Total Energy Use Post PV": 147.5,
    "out:Primary Energy": 113.04,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 9.18,
    "out:CO2 Operational/m2": 48.42,
    "out:Total CO2/m2": 57.6,
    "out:Total CO2 (tons)": 296.25,
    "out:Klimatpaverkan": -32.99,
    "out:Initial Cost/MSEK": 6.04375,
    "out:Running cost/(Apt SEK y)": 45140,
    "out:Running Cost over RSP/MSEK": 14.82,
    "out:LCP/MSEK": -1.594,
    "out:ROI% old": 10.52,
    "out:Payback discounted": 11,
    "out:Atemp": 5143,
    "out:Umean": 0.62,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FTX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 147,
    "out:Energy use kWh/m2": 147,
    "out:Energy savings %": 27.9,
    "out:Op energy cost/MSEK": 108,
    "out:El price/MSEK": 66,
    "out:Heating price/MSEK": 36,
    "out:DH+ DHW/kWh/m2": 127,
    "out:El bought/kWh/m2": 1434.9,
    "out:Return %": 9,
    "out:Return/kSEK/y": 568,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 875718.530813,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 231435,
    "out:EL kWh savings": -23653,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 170396,
    "out:DH kr savings": 210606,
    "out:El kr savings": -40210,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 430876,
    "out:% savings (space heating)": 34.58,
    "out:Total_Water Heating (DH)": 223720,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 113.81,
    "out:Etvv": 25,
    "out:Ef": 20.5,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000200000000110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 78.88,
    "out:DHW (DH)": 43.5,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 20.5,
    "out:Total Energy Use Pre PV": 142.5,
    "out:Total Energy Use Post PV": 142.5,
    "out:Primary Energy": 109.61,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 9.18,
    "out:CO2 Operational/m2": 46.62,
    "out:Total CO2/m2": 55.8,
    "out:Total CO2 (tons)": 286.99,
    "out:Klimatpaverkan": -42.25,
    "out:Initial Cost/MSEK": 6.172375,
    "out:Running cost/(Apt SEK y)": 43530,
    "out:Running Cost over RSP/MSEK": 14.291,
    "out:LCP/MSEK": -1.194,
    "out:ROI% old": 11.52,
    "out:Payback discounted": 10,
    "out:Atemp": 5143,
    "out:Umean": 0.62,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FTX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 142,
    "out:Energy use kWh/m2": 142,
    "out:Energy savings %": 32.4,
    "out:Op energy cost/MSEK": 104,
    "out:El price/MSEK": 64,
    "out:Heating price/MSEK": 35,
    "out:DH+ DHW/kWh/m2": 122,
    "out:El bought/kWh/m2": 1434.9,
    "out:Return %": 10,
    "out:Return/kSEK/y": 636,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 875718.530813,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 257150,
    "out:EL kWh savings": -23653,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 128574.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 193797,
    "out:DH kr savings": 234006,
    "out:El kr savings": -40210,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 405674,
    "out:% savings (space heating)": 38.4,
    "out:Total_Water Heating (DH)": 223720,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 108.91,
    "out:Etvv": 25,
    "out:Ef": 20.5,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000200000001010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 83.78,
    "out:DHW (DH)": 36.98,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 20.5,
    "out:Total Energy Use Pre PV": 141.5,
    "out:Total Energy Use Post PV": 141.5,
    "out:Primary Energy": 110.42,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 9.58,
    "out:CO2 Operational/m2": 46.26,
    "out:Total CO2/m2": 55.84,
    "out:Total CO2 (tons)": 287.21,
    "out:Klimatpaverkan": -42.03,
    "out:Initial Cost/MSEK": 6.29425,
    "out:Running cost/(Apt SEK y)": 43209,
    "out:Running Cost over RSP/MSEK": 14.185,
    "out:LCP/MSEK": -1.21,
    "out:ROI% old": 11.54,
    "out:Payback discounted": 10,
    "out:Atemp": 5143,
    "out:Umean": 0.62,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FTX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 141,
    "out:Energy use kWh/m2": 141,
    "out:Energy savings %": 33.3,
    "out:Op energy cost/MSEK": 104,
    "out:El price/MSEK": 63,
    "out:Heating price/MSEK": 35,
    "out:DH+ DHW/kWh/m2": 121,
    "out:El bought/kWh/m2": 1434.9,
    "out:Return %": 10,
    "out:Return/kSEK/y": 649,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 875718.530813,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 262293,
    "out:EL kWh savings": -23653,
    "out:Sewage heat recovery cost": 250448,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 198477,
    "out:DH kr savings": 238687,
    "out:El kr savings": -40210,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 430876,
    "out:% savings (space heating)": 34.58,
    "out:Total_Water Heating (DH)": 190162,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 113.81,
    "out:Etvv": 21.25,
    "out:Ef": 20.5,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000200000001110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 78.88,
    "out:DHW (DH)": 36.98,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 20.5,
    "out:Total Energy Use Pre PV": 136.5,
    "out:Total Energy Use Post PV": 136.5,
    "out:Primary Energy": 106.99,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 9.58,
    "out:CO2 Operational/m2": 44.46,
    "out:Total CO2/m2": 54.04,
    "out:Total CO2 (tons)": 277.95,
    "out:Klimatpaverkan": -51.29,
    "out:Initial Cost/MSEK": 6.42275,
    "out:Running cost/(Apt SEK y)": 41599,
    "out:Running Cost over RSP/MSEK": 13.656,
    "out:LCP/MSEK": -0.81,
    "out:ROI% old": 12.48,
    "out:Payback discounted": 10,
    "out:Atemp": 5143,
    "out:Umean": 0.62,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FTX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 136,
    "out:Energy use kWh/m2": 136,
    "out:Energy savings %": 38.2,
    "out:Op energy cost/MSEK": 100,
    "out:El price/MSEK": 61,
    "out:Heating price/MSEK": 33,
    "out:DH+ DHW/kWh/m2": 116,
    "out:El bought/kWh/m2": 1434.9,
    "out:Return %": 11,
    "out:Return/kSEK/y": 717,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 875718.530813,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 288008,
    "out:EL kWh savings": -23653,
    "out:Sewage heat recovery cost": 250448,
    "out:Balancing hydronic system cost": 128574.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 221878,
    "out:DH kr savings": 262087,
    "out:El kr savings": -40210,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 405674,
    "out:% savings (space heating)": 38.4,
    "out:Total_Water Heating (DH)": 190162,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 108.91,
    "out:Etvv": 21.25,
    "out:Ef": 20.5,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000200010000010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 83.78,
    "out:DHW (DH)": 43.5,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 14.33,
    "out:Total Energy Use Pre PV": 147.5,
    "out:Total Energy Use Post PV": 141.33,
    "out:Primary Energy": 101.94,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 21.53,
    "out:CO2 Operational/m2": 22.41,
    "out:Total CO2/m2": 43.94,
    "out:Total CO2 (tons)": 225.99,
    "out:Klimatpaverkan": -103.25,
    "out:Initial Cost/MSEK": 7.113625,
    "out:Running cost/(Apt SEK y)": 43074,
    "out:Running Cost over RSP/MSEK": 14.159,
    "out:LCP/MSEK": -2.004,
    "out:ROI% old": 10.26,
    "out:Payback discounted": 12,
    "out:Atemp": 5143,
    "out:Umean": 0.62,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FTX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 141,
    "out:Energy use kWh/m2": 141,
    "out:Energy savings %": 33.3,
    "out:Op energy cost/MSEK": 104,
    "out:El price/MSEK": 62,
    "out:Heating price/MSEK": 36,
    "out:DH+ DHW/kWh/m2": 127,
    "out:El bought/kWh/m2": 1013.1,
    "out:Return %": 9,
    "out:Return/kSEK/y": 655,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 61.265983,
    "out:PV (m2 panels)": 321,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 57484.06,
    "out:PV (kWh sold)": 25763.95,
    "out:PV (% sold (El))": 44.82,
    "out:PV (kWh self-consumed)": 31720.11,
    "out:PV (ratio sold/self-consumed)": 0.81,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 875718.530813,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 231435,
    "out:EL kWh savings": 8067,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 257170,
    "out:DH kr savings": 210606,
    "out:El kr savings": 13715,
    "out:El kr sold": 32849,
    "out:El kr saved": 53924,
    "out:El kr return": 86773,
    "out:% solar/total": 39,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 430876,
    "out:% savings (space heating)": 34.58,
    "out:Total_Water Heating (DH)": 223720,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 113.81,
    "out:Etvv": 25,
    "out:Ef": 14.33,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000200010000110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 78.88,
    "out:DHW (DH)": 43.5,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 14.33,
    "out:Total Energy Use Pre PV": 142.5,
    "out:Total Energy Use Post PV": 136.33,
    "out:Primary Energy": 98.51,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 21.53,
    "out:CO2 Operational/m2": 20.61,
    "out:Total CO2/m2": 42.14,
    "out:Total CO2 (tons)": 216.73,
    "out:Klimatpaverkan": -112.51,
    "out:Initial Cost/MSEK": 7.242125,
    "out:Running cost/(Apt SEK y)": 41464,
    "out:Running Cost over RSP/MSEK": 13.63,
    "out:LCP/MSEK": -1.603,
    "out:ROI% old": 11.12,
    "out:Payback discounted": 11,
    "out:Atemp": 5143,
    "out:Umean": 0.62,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FTX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 136,
    "out:Energy use kWh/m2": 136,
    "out:Energy savings %": 38.2,
    "out:Op energy cost/MSEK": 100,
    "out:El price/MSEK": 60,
    "out:Heating price/MSEK": 35,
    "out:DH+ DHW/kWh/m2": 122,
    "out:El bought/kWh/m2": 1013.1,
    "out:Return %": 10,
    "out:Return/kSEK/y": 722,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 61.265983,
    "out:PV (m2 panels)": 321,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 57484.06,
    "out:PV (kWh sold)": 25763.95,
    "out:PV (% sold (El))": 44.82,
    "out:PV (kWh self-consumed)": 31720.11,
    "out:PV (ratio sold/self-consumed)": 0.81,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 875718.530813,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 257150,
    "out:EL kWh savings": 8067,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 128574.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 280570,
    "out:DH kr savings": 234006,
    "out:El kr savings": 13715,
    "out:El kr sold": 32849,
    "out:El kr saved": 53924,
    "out:El kr return": 86773,
    "out:% solar/total": 39,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 405674,
    "out:% savings (space heating)": 38.4,
    "out:Total_Water Heating (DH)": 223720,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 108.91,
    "out:Etvv": 25,
    "out:Ef": 14.33,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000200010001010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 83.78,
    "out:DHW (DH)": 36.98,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 14.33,
    "out:Total Energy Use Pre PV": 141.5,
    "out:Total Energy Use Post PV": 135.33,
    "out:Primary Energy": 99.32,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 21.93,
    "out:CO2 Operational/m2": 20.25,
    "out:Total CO2/m2": 42.18,
    "out:Total CO2 (tons)": 216.95,
    "out:Klimatpaverkan": -112.29,
    "out:Initial Cost/MSEK": 7.364,
    "out:Running cost/(Apt SEK y)": 41143,
    "out:Running Cost over RSP/MSEK": 13.525,
    "out:LCP/MSEK": -1.62,
    "out:ROI% old": 11.14,
    "out:Payback discounted": 11,
    "out:Atemp": 5143,
    "out:Umean": 0.62,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FTX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 135,
    "out:Energy use kWh/m2": 135,
    "out:Energy savings %": 39.3,
    "out:Op energy cost/MSEK": 100,
    "out:El price/MSEK": 59,
    "out:Heating price/MSEK": 35,
    "out:DH+ DHW/kWh/m2": 121,
    "out:El bought/kWh/m2": 1013.1,
    "out:Return %": 10,
    "out:Return/kSEK/y": 736,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 61.265983,
    "out:PV (m2 panels)": 321,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 57484.06,
    "out:PV (kWh sold)": 25763.95,
    "out:PV (% sold (El))": 44.82,
    "out:PV (kWh self-consumed)": 31720.11,
    "out:PV (ratio sold/self-consumed)": 0.81,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 875718.530813,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 262293,
    "out:EL kWh savings": 8067,
    "out:Sewage heat recovery cost": 250448,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 285250,
    "out:DH kr savings": 238687,
    "out:El kr savings": 13715,
    "out:El kr sold": 32849,
    "out:El kr saved": 53924,
    "out:El kr return": 86773,
    "out:% solar/total": 39,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 430876,
    "out:% savings (space heating)": 34.58,
    "out:Total_Water Heating (DH)": 190162,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 113.81,
    "out:Etvv": 21.25,
    "out:Ef": 14.33,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000200010001110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 78.88,
    "out:DHW (DH)": 36.98,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 14.33,
    "out:Total Energy Use Pre PV": 136.5,
    "out:Total Energy Use Post PV": 130.33,
    "out:Primary Energy": 95.89,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 21.93,
    "out:CO2 Operational/m2": 18.45,
    "out:Total CO2/m2": 40.38,
    "out:Total CO2 (tons)": 207.69,
    "out:Klimatpaverkan": -121.55,
    "out:Initial Cost/MSEK": 7.492625,
    "out:Running cost/(Apt SEK y)": 39533,
    "out:Running Cost over RSP/MSEK": 12.996,
    "out:LCP/MSEK": -1.219,
    "out:ROI% old": 11.96,
    "out:Payback discounted": 10,
    "out:Atemp": 5143,
    "out:Umean": 0.62,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FTX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 130,
    "out:Energy use kWh/m2": 130,
    "out:Energy savings %": 44.6,
    "out:Op energy cost/MSEK": 96,
    "out:El price/MSEK": 57,
    "out:Heating price/MSEK": 33,
    "out:DH+ DHW/kWh/m2": 116,
    "out:El bought/kWh/m2": 1013.1,
    "out:Return %": 11,
    "out:Return/kSEK/y": 804,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 61.265983,
    "out:PV (m2 panels)": 321,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 57484.06,
    "out:PV (kWh sold)": 25763.95,
    "out:PV (% sold (El))": 44.82,
    "out:PV (kWh self-consumed)": 31720.11,
    "out:PV (ratio sold/self-consumed)": 0.81,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 875718.530813,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 288008,
    "out:EL kWh savings": 8067,
    "out:Sewage heat recovery cost": 250448,
    "out:Balancing hydronic system cost": 128574.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 308651,
    "out:DH kr savings": 262087,
    "out:El kr savings": 13715,
    "out:El kr sold": 32849,
    "out:El kr saved": 53924,
    "out:El kr return": 86773,
    "out:% solar/total": 39,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 405674,
    "out:% savings (space heating)": 38.4,
    "out:Total_Water Heating (DH)": 190162,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 108.91,
    "out:Etvv": 21.25,
    "out:Ef": 14.33,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000200020000010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 83.78,
    "out:DHW (DH)": 43.5,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 13.09,
    "out:Total Energy Use Pre PV": 147.5,
    "out:Total Energy Use Post PV": 140.09,
    "out:Primary Energy": 99.71,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 33.88,
    "out:CO2 Operational/m2": -28.27,
    "out:Total CO2/m2": 5.61,
    "out:Total CO2 (tons)": 28.87,
    "out:Klimatpaverkan": -300.37,
    "out:Initial Cost/MSEK": 8.183375,
    "out:Running cost/(Apt SEK y)": 41264,
    "out:Running Cost over RSP/MSEK": 13.581,
    "out:LCP/MSEK": -2.496,
    "out:ROI% old": 9.93,
    "out:Payback discounted": 12,
    "out:Atemp": 5143,
    "out:Umean": 0.62,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FTX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 140,
    "out:Energy use kWh/m2": 140,
    "out:Energy savings %": 34.3,
    "out:Op energy cost/MSEK": 101,
    "out:El price/MSEK": 58,
    "out:Heating price/MSEK": 36,
    "out:DH+ DHW/kWh/m2": 127,
    "out:El bought/kWh/m2": 923.7,
    "out:Return %": 9,
    "out:Return/kSEK/y": 731,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.33,
    "out:PV (kWp installed)": 122.531965,
    "out:PV (m2 panels)": 643,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 114968.11,
    "out:PV (kWh sold)": 76862.14,
    "out:PV (% sold (El))": 66.86,
    "out:PV (kWh self-consumed)": 38105.97,
    "out:PV (ratio sold/self-consumed)": 2.02,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 875718.530813,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 231435,
    "out:EL kWh savings": 14453,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 333176,
    "out:DH kr savings": 210606,
    "out:El kr savings": 24571,
    "out:El kr sold": 97999,
    "out:El kr saved": 64780,
    "out:El kr return": 162779,
    "out:% solar/total": 47,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 430876,
    "out:% savings (space heating)": 34.58,
    "out:Total_Water Heating (DH)": 223720,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 113.81,
    "out:Etvv": 25,
    "out:Ef": 13.09,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000200020000110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 78.88,
    "out:DHW (DH)": 43.5,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 13.09,
    "out:Total Energy Use Pre PV": 142.5,
    "out:Total Energy Use Post PV": 135.09,
    "out:Primary Energy": 96.28,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 33.88,
    "out:CO2 Operational/m2": -30.07,
    "out:Total CO2/m2": 3.81,
    "out:Total CO2 (tons)": 19.61,
    "out:Klimatpaverkan": -309.63,
    "out:Initial Cost/MSEK": 8.311875,
    "out:Running cost/(Apt SEK y)": 39655,
    "out:Running Cost over RSP/MSEK": 13.052,
    "out:LCP/MSEK": -2.095,
    "out:ROI% old": 10.68,
    "out:Payback discounted": 11,
    "out:Atemp": 5143,
    "out:Umean": 0.62,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FTX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 135,
    "out:Energy use kWh/m2": 135,
    "out:Energy savings %": 39.3,
    "out:Op energy cost/MSEK": 97,
    "out:El price/MSEK": 56,
    "out:Heating price/MSEK": 35,
    "out:DH+ DHW/kWh/m2": 122,
    "out:El bought/kWh/m2": 923.7,
    "out:Return %": 10,
    "out:Return/kSEK/y": 798,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.33,
    "out:PV (kWp installed)": 122.531965,
    "out:PV (m2 panels)": 643,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 114968.11,
    "out:PV (kWh sold)": 76862.14,
    "out:PV (% sold (El))": 66.86,
    "out:PV (kWh self-consumed)": 38105.97,
    "out:PV (ratio sold/self-consumed)": 2.02,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 875718.530813,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 257150,
    "out:EL kWh savings": 14453,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 128574.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 356576,
    "out:DH kr savings": 234006,
    "out:El kr savings": 24571,
    "out:El kr sold": 97999,
    "out:El kr saved": 64780,
    "out:El kr return": 162779,
    "out:% solar/total": 47,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 405674,
    "out:% savings (space heating)": 38.4,
    "out:Total_Water Heating (DH)": 223720,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 108.91,
    "out:Etvv": 25,
    "out:Ef": 13.09,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000200020001010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 83.78,
    "out:DHW (DH)": 36.98,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 13.09,
    "out:Total Energy Use Pre PV": 141.5,
    "out:Total Energy Use Post PV": 134.09,
    "out:Primary Energy": 97.08,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 34.29,
    "out:CO2 Operational/m2": -30.43,
    "out:Total CO2/m2": 3.85,
    "out:Total CO2 (tons)": 19.83,
    "out:Klimatpaverkan": -309.41,
    "out:Initial Cost/MSEK": 8.43375,
    "out:Running cost/(Apt SEK y)": 39333,
    "out:Running Cost over RSP/MSEK": 12.946,
    "out:LCP/MSEK": -2.111,
    "out:ROI% old": 10.71,
    "out:Payback discounted": 11,
    "out:Atemp": 5143,
    "out:Umean": 0.62,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FTX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 134,
    "out:Energy use kWh/m2": 134,
    "out:Energy savings %": 40.3,
    "out:Op energy cost/MSEK": 96,
    "out:El price/MSEK": 56,
    "out:Heating price/MSEK": 35,
    "out:DH+ DHW/kWh/m2": 121,
    "out:El bought/kWh/m2": 923.7,
    "out:Return %": 10,
    "out:Return/kSEK/y": 812,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.33,
    "out:PV (kWp installed)": 122.531965,
    "out:PV (m2 panels)": 643,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 114968.11,
    "out:PV (kWh sold)": 76862.14,
    "out:PV (% sold (El))": 66.86,
    "out:PV (kWh self-consumed)": 38105.97,
    "out:PV (ratio sold/self-consumed)": 2.02,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 875718.530813,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 262293,
    "out:EL kWh savings": 14453,
    "out:Sewage heat recovery cost": 250448,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 361256,
    "out:DH kr savings": 238687,
    "out:El kr savings": 24571,
    "out:El kr sold": 97999,
    "out:El kr saved": 64780,
    "out:El kr return": 162779,
    "out:% solar/total": 47,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 430876,
    "out:% savings (space heating)": 34.58,
    "out:Total_Water Heating (DH)": 190162,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 113.81,
    "out:Etvv": 21.25,
    "out:Ef": 13.09,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000200020001110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 78.88,
    "out:DHW (DH)": 36.98,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 13.09,
    "out:Total Energy Use Pre PV": 136.5,
    "out:Total Energy Use Post PV": 129.09,
    "out:Primary Energy": 93.65,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 34.29,
    "out:CO2 Operational/m2": -32.23,
    "out:Total CO2/m2": 2.05,
    "out:Total CO2 (tons)": 10.57,
    "out:Klimatpaverkan": -318.67,
    "out:Initial Cost/MSEK": 8.562375,
    "out:Running cost/(Apt SEK y)": 37724,
    "out:Running Cost over RSP/MSEK": 12.418,
    "out:LCP/MSEK": -1.711,
    "out:ROI% old": 11.43,
    "out:Payback discounted": 11,
    "out:Atemp": 5143,
    "out:Umean": 0.62,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FTX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 129,
    "out:Energy use kWh/m2": 129,
    "out:Energy savings %": 45.7,
    "out:Op energy cost/MSEK": 92,
    "out:El price/MSEK": 53,
    "out:Heating price/MSEK": 33,
    "out:DH+ DHW/kWh/m2": 116,
    "out:El bought/kWh/m2": 923.7,
    "out:Return %": 10,
    "out:Return/kSEK/y": 880,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.33,
    "out:PV (kWp installed)": 122.531965,
    "out:PV (m2 panels)": 643,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 114968.11,
    "out:PV (kWh sold)": 76862.14,
    "out:PV (% sold (El))": 66.86,
    "out:PV (kWh self-consumed)": 38105.97,
    "out:PV (ratio sold/self-consumed)": 2.02,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 875718.530813,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 288008,
    "out:EL kWh savings": 14453,
    "out:Sewage heat recovery cost": 250448,
    "out:Balancing hydronic system cost": 128574.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 384657,
    "out:DH kr savings": 262087,
    "out:El kr savings": 24571,
    "out:El kr sold": 97999,
    "out:El kr saved": 64780,
    "out:El kr return": 162779,
    "out:% solar/total": 47,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 405674,
    "out:% savings (space heating)": 38.4,
    "out:Total_Water Heating (DH)": 190162,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 108.91,
    "out:Etvv": 21.25,
    "out:Ef": 13.09,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000200100000010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 83.78,
    "out:DHW (DH)": 34.8,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 20.5,
    "out:Total Energy Use Pre PV": 139.5,
    "out:Total Energy Use Post PV": 139.5,
    "out:Primary Energy": 109.54,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 9.18,
    "out:CO2 Operational/m2": 45.54,
    "out:Total CO2/m2": 54.72,
    "out:Total CO2 (tons)": 281.44,
    "out:Klimatpaverkan": -47.8,
    "out:Initial Cost/MSEK": 6.154625,
    "out:Running cost/(Apt SEK y)": 42565,
    "out:Running Cost over RSP/MSEK": 13.974,
    "out:LCP/MSEK": -0.859,
    "out:ROI% old": 12.29,
    "out:Payback discounted": 10,
    "out:Atemp": 5143,
    "out:Umean": 0.62,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FTX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 139,
    "out:Energy use kWh/m2": 139,
    "out:Energy savings %": 35.3,
    "out:Op energy cost/MSEK": 102,
    "out:El price/MSEK": 62,
    "out:Heating price/MSEK": 34,
    "out:DH+ DHW/kWh/m2": 119,
    "out:El bought/kWh/m2": 1434.9,
    "out:Return %": 11,
    "out:Return/kSEK/y": 676,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 875718.530813,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 272579,
    "out:EL kWh savings": -23653,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 207837,
    "out:DH kr savings": 248047,
    "out:El kr savings": -40210,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 430876,
    "out:% savings (space heating)": 34.58,
    "out:Total_Water Heating (DH)": 178976,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 113.81,
    "out:Etvv": 20,
    "out:Ef": 20.5,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000200100000110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 78.88,
    "out:DHW (DH)": 34.8,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 20.5,
    "out:Total Energy Use Pre PV": 134.5,
    "out:Total Energy Use Post PV": 134.5,
    "out:Primary Energy": 106.11,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 9.18,
    "out:CO2 Operational/m2": 43.74,
    "out:Total CO2/m2": 52.92,
    "out:Total CO2 (tons)": 272.18,
    "out:Klimatpaverkan": -57.06,
    "out:Initial Cost/MSEK": 6.28325,
    "out:Running cost/(Apt SEK y)": 40956,
    "out:Running Cost over RSP/MSEK": 13.445,
    "out:LCP/MSEK": -0.459,
    "out:ROI% old": 13.24,
    "out:Payback discounted": 9,
    "out:Atemp": 5143,
    "out:Umean": 0.62,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FTX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 134,
    "out:Energy use kWh/m2": 134,
    "out:Energy savings %": 40.3,
    "out:Op energy cost/MSEK": 98,
    "out:El price/MSEK": 60,
    "out:Heating price/MSEK": 33,
    "out:DH+ DHW/kWh/m2": 114,
    "out:El bought/kWh/m2": 1434.9,
    "out:Return %": 12,
    "out:Return/kSEK/y": 744,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 875718.530813,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 298294,
    "out:EL kWh savings": -23653,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 128574.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 231238,
    "out:DH kr savings": 271448,
    "out:El kr savings": -40210,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 405674,
    "out:% savings (space heating)": 38.4,
    "out:Total_Water Heating (DH)": 178976,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 108.91,
    "out:Etvv": 20,
    "out:Ef": 20.5,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000200100001010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 83.78,
    "out:DHW (DH)": 29.58,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 20.5,
    "out:Total Energy Use Pre PV": 133.5,
    "out:Total Energy Use Post PV": 133.5,
    "out:Primary Energy": 107.44,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 9.58,
    "out:CO2 Operational/m2": 43.38,
    "out:Total CO2/m2": 52.96,
    "out:Total CO2 (tons)": 272.4,
    "out:Klimatpaverkan": -56.84,
    "out:Initial Cost/MSEK": 6.405125,
    "out:Running cost/(Apt SEK y)": 40634,
    "out:Running Cost over RSP/MSEK": 13.339,
    "out:LCP/MSEK": -0.475,
    "out:ROI% old": 13.23,
    "out:Payback discounted": 9,
    "out:Atemp": 5143,
    "out:Umean": 0.62,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FTX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 133,
    "out:Energy use kWh/m2": 133,
    "out:Energy savings %": 41.4,
    "out:Op energy cost/MSEK": 97,
    "out:El price/MSEK": 60,
    "out:Heating price/MSEK": 32,
    "out:DH+ DHW/kWh/m2": 113,
    "out:El bought/kWh/m2": 1434.9,
    "out:Return %": 12,
    "out:Return/kSEK/y": 757,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 875718.530813,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 303437,
    "out:EL kWh savings": -23653,
    "out:Sewage heat recovery cost": 250448,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 235918,
    "out:DH kr savings": 276128,
    "out:El kr savings": -40210,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 430876,
    "out:% savings (space heating)": 34.58,
    "out:Total_Water Heating (DH)": 152130,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 113.81,
    "out:Etvv": 17,
    "out:Ef": 20.5,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000200100001110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 78.88,
    "out:DHW (DH)": 29.58,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 20.5,
    "out:Total Energy Use Pre PV": 128.5,
    "out:Total Energy Use Post PV": 128.5,
    "out:Primary Energy": 104.01,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 9.58,
    "out:CO2 Operational/m2": 41.58,
    "out:Total CO2/m2": 51.16,
    "out:Total CO2 (tons)": 263.14,
    "out:Klimatpaverkan": -66.1,
    "out:Initial Cost/MSEK": 6.533625,
    "out:Running cost/(Apt SEK y)": 39025,
    "out:Running Cost over RSP/MSEK": 12.81,
    "out:LCP/MSEK": -0.074,
    "out:ROI% old": 14.12,
    "out:Payback discounted": 9,
    "out:Atemp": 5143,
    "out:Umean": 0.62,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FTX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 128,
    "out:Energy use kWh/m2": 128,
    "out:Energy savings %": 46.9,
    "out:Op energy cost/MSEK": 94,
    "out:El price/MSEK": 57,
    "out:Heating price/MSEK": 31,
    "out:DH+ DHW/kWh/m2": 108,
    "out:El bought/kWh/m2": 1434.9,
    "out:Return %": 13,
    "out:Return/kSEK/y": 825,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 875718.530813,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 329152,
    "out:EL kWh savings": -23653,
    "out:Sewage heat recovery cost": 250448,
    "out:Balancing hydronic system cost": 128574.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 259319,
    "out:DH kr savings": 299528,
    "out:El kr savings": -40210,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 405674,
    "out:% savings (space heating)": 38.4,
    "out:Total_Water Heating (DH)": 152130,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 108.91,
    "out:Etvv": 17,
    "out:Ef": 20.5,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000200110000010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 83.78,
    "out:DHW (DH)": 34.8,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 14.33,
    "out:Total Energy Use Pre PV": 139.5,
    "out:Total Energy Use Post PV": 133.33,
    "out:Primary Energy": 98.44,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 21.53,
    "out:CO2 Operational/m2": 19.53,
    "out:Total CO2/m2": 41.06,
    "out:Total CO2 (tons)": 211.17,
    "out:Klimatpaverkan": -118.07,
    "out:Initial Cost/MSEK": 7.224375,
    "out:Running cost/(Apt SEK y)": 40499,
    "out:Running Cost over RSP/MSEK": 13.313,
    "out:LCP/MSEK": -1.268,
    "out:ROI% old": 11.78,
    "out:Payback discounted": 10,
    "out:Atemp": 5143,
    "out:Umean": 0.62,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FTX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 133,
    "out:Energy use kWh/m2": 133,
    "out:Energy savings %": 41.4,
    "out:Op energy cost/MSEK": 98,
    "out:El price/MSEK": 58,
    "out:Heating price/MSEK": 34,
    "out:DH+ DHW/kWh/m2": 119,
    "out:El bought/kWh/m2": 1013.1,
    "out:Return %": 11,
    "out:Return/kSEK/y": 763,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 61.265983,
    "out:PV (m2 panels)": 321,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 57484.06,
    "out:PV (kWh sold)": 25763.95,
    "out:PV (% sold (El))": 44.82,
    "out:PV (kWh self-consumed)": 31720.11,
    "out:PV (ratio sold/self-consumed)": 0.81,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 875718.530813,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 272579,
    "out:EL kWh savings": 8067,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 294611,
    "out:DH kr savings": 248047,
    "out:El kr savings": 13715,
    "out:El kr sold": 32849,
    "out:El kr saved": 53924,
    "out:El kr return": 86773,
    "out:% solar/total": 39,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 430876,
    "out:% savings (space heating)": 34.58,
    "out:Total_Water Heating (DH)": 178976,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 113.81,
    "out:Etvv": 20,
    "out:Ef": 14.33,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000200110000110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 78.88,
    "out:DHW (DH)": 34.8,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 14.33,
    "out:Total Energy Use Pre PV": 134.5,
    "out:Total Energy Use Post PV": 128.33,
    "out:Primary Energy": 95.01,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 21.53,
    "out:CO2 Operational/m2": 17.73,
    "out:Total CO2/m2": 39.26,
    "out:Total CO2 (tons)": 201.92,
    "out:Klimatpaverkan": -127.32,
    "out:Initial Cost/MSEK": 7.353,
    "out:Running cost/(Apt SEK y)": 38890,
    "out:Running Cost over RSP/MSEK": 12.784,
    "out:LCP/MSEK": -0.868,
    "out:ROI% old": 12.6,
    "out:Payback discounted": 10,
    "out:Atemp": 5143,
    "out:Umean": 0.62,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FTX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 128,
    "out:Energy use kWh/m2": 128,
    "out:Energy savings %": 46.9,
    "out:Op energy cost/MSEK": 94,
    "out:El price/MSEK": 56,
    "out:Heating price/MSEK": 33,
    "out:DH+ DHW/kWh/m2": 114,
    "out:El bought/kWh/m2": 1013.1,
    "out:Return %": 11,
    "out:Return/kSEK/y": 831,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 61.265983,
    "out:PV (m2 panels)": 321,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 57484.06,
    "out:PV (kWh sold)": 25763.95,
    "out:PV (% sold (El))": 44.82,
    "out:PV (kWh self-consumed)": 31720.11,
    "out:PV (ratio sold/self-consumed)": 0.81,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 875718.530813,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 298294,
    "out:EL kWh savings": 8067,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 128574.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 318011,
    "out:DH kr savings": 271448,
    "out:El kr savings": 13715,
    "out:El kr sold": 32849,
    "out:El kr saved": 53924,
    "out:El kr return": 86773,
    "out:% solar/total": 39,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 405674,
    "out:% savings (space heating)": 38.4,
    "out:Total_Water Heating (DH)": 178976,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 108.91,
    "out:Etvv": 20,
    "out:Ef": 14.33,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000200110001010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 83.78,
    "out:DHW (DH)": 29.58,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 14.33,
    "out:Total Energy Use Pre PV": 133.5,
    "out:Total Energy Use Post PV": 127.33,
    "out:Primary Energy": 96.34,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 21.93,
    "out:CO2 Operational/m2": 17.37,
    "out:Total CO2/m2": 39.3,
    "out:Total CO2 (tons)": 202.13,
    "out:Klimatpaverkan": -127.11,
    "out:Initial Cost/MSEK": 7.474875,
    "out:Running cost/(Apt SEK y)": 38568,
    "out:Running Cost over RSP/MSEK": 12.678,
    "out:LCP/MSEK": -0.884,
    "out:ROI% old": 12.6,
    "out:Payback discounted": 10,
    "out:Atemp": 5143,
    "out:Umean": 0.62,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FTX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 127,
    "out:Energy use kWh/m2": 127,
    "out:Energy savings %": 48,
    "out:Op energy cost/MSEK": 93,
    "out:El price/MSEK": 56,
    "out:Heating price/MSEK": 32,
    "out:DH+ DHW/kWh/m2": 113,
    "out:El bought/kWh/m2": 1013.1,
    "out:Return %": 11,
    "out:Return/kSEK/y": 844,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 61.265983,
    "out:PV (m2 panels)": 321,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 57484.06,
    "out:PV (kWh sold)": 25763.95,
    "out:PV (% sold (El))": 44.82,
    "out:PV (kWh self-consumed)": 31720.11,
    "out:PV (ratio sold/self-consumed)": 0.81,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 875718.530813,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 303437,
    "out:EL kWh savings": 8067,
    "out:Sewage heat recovery cost": 250448,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 322691,
    "out:DH kr savings": 276128,
    "out:El kr savings": 13715,
    "out:El kr sold": 32849,
    "out:El kr saved": 53924,
    "out:El kr return": 86773,
    "out:% solar/total": 39,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 430876,
    "out:% savings (space heating)": 34.58,
    "out:Total_Water Heating (DH)": 152130,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 113.81,
    "out:Etvv": 17,
    "out:Ef": 14.33,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000200110001110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 78.88,
    "out:DHW (DH)": 29.58,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 14.33,
    "out:Total Energy Use Pre PV": 128.5,
    "out:Total Energy Use Post PV": 122.33,
    "out:Primary Energy": 92.91,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 21.93,
    "out:CO2 Operational/m2": 15.57,
    "out:Total CO2/m2": 37.5,
    "out:Total CO2 (tons)": 192.88,
    "out:Klimatpaverkan": -136.36,
    "out:Initial Cost/MSEK": 7.6035,
    "out:Running cost/(Apt SEK y)": 36959,
    "out:Running Cost over RSP/MSEK": 12.149,
    "out:LCP/MSEK": -0.484,
    "out:ROI% old": 13.38,
    "out:Payback discounted": 9,
    "out:Atemp": 5143,
    "out:Umean": 0.62,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FTX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 122,
    "out:Energy use kWh/m2": 122,
    "out:Energy savings %": 54.1,
    "out:Op energy cost/MSEK": 89,
    "out:El price/MSEK": 53,
    "out:Heating price/MSEK": 31,
    "out:DH+ DHW/kWh/m2": 108,
    "out:El bought/kWh/m2": 1013.1,
    "out:Return %": 12,
    "out:Return/kSEK/y": 912,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 61.265983,
    "out:PV (m2 panels)": 321,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 57484.06,
    "out:PV (kWh sold)": 25763.95,
    "out:PV (% sold (El))": 44.82,
    "out:PV (kWh self-consumed)": 31720.11,
    "out:PV (ratio sold/self-consumed)": 0.81,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 875718.530813,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 329152,
    "out:EL kWh savings": 8067,
    "out:Sewage heat recovery cost": 250448,
    "out:Balancing hydronic system cost": 128574.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 346092,
    "out:DH kr savings": 299528,
    "out:El kr savings": 13715,
    "out:El kr sold": 32849,
    "out:El kr saved": 53924,
    "out:El kr return": 86773,
    "out:% solar/total": 39,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 405674,
    "out:% savings (space heating)": 38.4,
    "out:Total_Water Heating (DH)": 152130,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 108.91,
    "out:Etvv": 17,
    "out:Ef": 14.33,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000200120000010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 83.78,
    "out:DHW (DH)": 34.8,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 13.09,
    "out:Total Energy Use Pre PV": 139.5,
    "out:Total Energy Use Post PV": 132.09,
    "out:Primary Energy": 96.21,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 33.88,
    "out:CO2 Operational/m2": -31.15,
    "out:Total CO2/m2": 2.73,
    "out:Total CO2 (tons)": 14.05,
    "out:Klimatpaverkan": -315.19,
    "out:Initial Cost/MSEK": 8.29425,
    "out:Running cost/(Apt SEK y)": 38689,
    "out:Running Cost over RSP/MSEK": 12.735,
    "out:LCP/MSEK": -1.76,
    "out:ROI% old": 11.25,
    "out:Payback discounted": 11,
    "out:Atemp": 5143,
    "out:Umean": 0.62,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FTX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 132,
    "out:Energy use kWh/m2": 132,
    "out:Energy savings %": 42.4,
    "out:Op energy cost/MSEK": 95,
    "out:El price/MSEK": 55,
    "out:Heating price/MSEK": 34,
    "out:DH+ DHW/kWh/m2": 119,
    "out:El bought/kWh/m2": 923.7,
    "out:Return %": 10,
    "out:Return/kSEK/y": 839,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.33,
    "out:PV (kWp installed)": 122.531965,
    "out:PV (m2 panels)": 643,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 114968.11,
    "out:PV (kWh sold)": 76862.14,
    "out:PV (% sold (El))": 66.86,
    "out:PV (kWh self-consumed)": 38105.97,
    "out:PV (ratio sold/self-consumed)": 2.02,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 875718.530813,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 272579,
    "out:EL kWh savings": 14453,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 370617,
    "out:DH kr savings": 248047,
    "out:El kr savings": 24571,
    "out:El kr sold": 97999,
    "out:El kr saved": 64780,
    "out:El kr return": 162779,
    "out:% solar/total": 47,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 430876,
    "out:% savings (space heating)": 34.58,
    "out:Total_Water Heating (DH)": 178976,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 113.81,
    "out:Etvv": 20,
    "out:Ef": 13.09,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000200120000110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 78.88,
    "out:DHW (DH)": 34.8,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 13.09,
    "out:Total Energy Use Pre PV": 134.5,
    "out:Total Energy Use Post PV": 127.09,
    "out:Primary Energy": 92.78,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 33.88,
    "out:CO2 Operational/m2": -32.95,
    "out:Total CO2/m2": 0.93,
    "out:Total CO2 (tons)": 4.8,
    "out:Klimatpaverkan": -324.44,
    "out:Initial Cost/MSEK": 8.42275,
    "out:Running cost/(Apt SEK y)": 37080,
    "out:Running Cost over RSP/MSEK": 12.206,
    "out:LCP/MSEK": -1.36,
    "out:ROI% old": 11.98,
    "out:Payback discounted": 10,
    "out:Atemp": 5143,
    "out:Umean": 0.62,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FTX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 127,
    "out:Energy use kWh/m2": 127,
    "out:Energy savings %": 48,
    "out:Op energy cost/MSEK": 91,
    "out:El price/MSEK": 53,
    "out:Heating price/MSEK": 33,
    "out:DH+ DHW/kWh/m2": 114,
    "out:El bought/kWh/m2": 923.7,
    "out:Return %": 11,
    "out:Return/kSEK/y": 907,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.33,
    "out:PV (kWp installed)": 122.531965,
    "out:PV (m2 panels)": 643,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 114968.11,
    "out:PV (kWh sold)": 76862.14,
    "out:PV (% sold (El))": 66.86,
    "out:PV (kWh self-consumed)": 38105.97,
    "out:PV (ratio sold/self-consumed)": 2.02,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 875718.530813,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 298294,
    "out:EL kWh savings": 14453,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 128574.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 394017,
    "out:DH kr savings": 271448,
    "out:El kr savings": 24571,
    "out:El kr sold": 97999,
    "out:El kr saved": 64780,
    "out:El kr return": 162779,
    "out:% solar/total": 47,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 405674,
    "out:% savings (space heating)": 38.4,
    "out:Total_Water Heating (DH)": 178976,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 108.91,
    "out:Etvv": 20,
    "out:Ef": 13.09,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000200120001010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 83.78,
    "out:DHW (DH)": 29.58,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 13.09,
    "out:Total Energy Use Pre PV": 133.5,
    "out:Total Energy Use Post PV": 126.09,
    "out:Primary Energy": 94.11,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 34.29,
    "out:CO2 Operational/m2": -33.31,
    "out:Total CO2/m2": 0.97,
    "out:Total CO2 (tons)": 5.01,
    "out:Klimatpaverkan": -324.23,
    "out:Initial Cost/MSEK": 8.544625,
    "out:Running cost/(Apt SEK y)": 36758,
    "out:Running Cost over RSP/MSEK": 12.1,
    "out:LCP/MSEK": -1.376,
    "out:ROI% old": 11.99,
    "out:Payback discounted": 10,
    "out:Atemp": 5143,
    "out:Umean": 0.62,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FTX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 126,
    "out:Energy use kWh/m2": 126,
    "out:Energy savings %": 49.2,
    "out:Op energy cost/MSEK": 90,
    "out:El price/MSEK": 52,
    "out:Heating price/MSEK": 32,
    "out:DH+ DHW/kWh/m2": 113,
    "out:El bought/kWh/m2": 923.7,
    "out:Return %": 11,
    "out:Return/kSEK/y": 920,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.33,
    "out:PV (kWp installed)": 122.531965,
    "out:PV (m2 panels)": 643,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 114968.11,
    "out:PV (kWh sold)": 76862.14,
    "out:PV (% sold (El))": 66.86,
    "out:PV (kWh self-consumed)": 38105.97,
    "out:PV (ratio sold/self-consumed)": 2.02,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 875718.530813,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 303437,
    "out:EL kWh savings": 14453,
    "out:Sewage heat recovery cost": 250448,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 398698,
    "out:DH kr savings": 276128,
    "out:El kr savings": 24571,
    "out:El kr sold": 97999,
    "out:El kr saved": 64780,
    "out:El kr return": 162779,
    "out:% solar/total": 47,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 430876,
    "out:% savings (space heating)": 34.58,
    "out:Total_Water Heating (DH)": 152130,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 113.81,
    "out:Etvv": 17,
    "out:Ef": 13.09,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000200120001110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 78.88,
    "out:DHW (DH)": 29.58,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 13.09,
    "out:Total Energy Use Pre PV": 128.5,
    "out:Total Energy Use Post PV": 121.09,
    "out:Primary Energy": 90.68,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 34.29,
    "out:CO2 Operational/m2": -35.11,
    "out:Total CO2/m2": -0.82,
    "out:Total CO2 (tons)": -4.24,
    "out:Klimatpaverkan": -333.48,
    "out:Initial Cost/MSEK": 8.67325,
    "out:Running cost/(Apt SEK y)": 35149,
    "out:Running Cost over RSP/MSEK": 11.571,
    "out:LCP/MSEK": -0.976,
    "out:ROI% old": 12.68,
    "out:Payback discounted": 10,
    "out:Atemp": 5143,
    "out:Umean": 0.62,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FTX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 121,
    "out:Energy use kWh/m2": 121,
    "out:Energy savings %": 55.4,
    "out:Op energy cost/MSEK": 86,
    "out:El price/MSEK": 50,
    "out:Heating price/MSEK": 31,
    "out:DH+ DHW/kWh/m2": 108,
    "out:El bought/kWh/m2": 923.7,
    "out:Return %": 11,
    "out:Return/kSEK/y": 988,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.33,
    "out:PV (kWp installed)": 122.531965,
    "out:PV (m2 panels)": 643,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 114968.11,
    "out:PV (kWh sold)": 76862.14,
    "out:PV (% sold (El))": 66.86,
    "out:PV (kWh self-consumed)": 38105.97,
    "out:PV (ratio sold/self-consumed)": 2.02,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 875718.530813,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 329152,
    "out:EL kWh savings": 14453,
    "out:Sewage heat recovery cost": 250448,
    "out:Balancing hydronic system cost": 128574.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 422098,
    "out:DH kr savings": 299528,
    "out:El kr savings": 24571,
    "out:El kr sold": 97999,
    "out:El kr saved": 64780,
    "out:El kr return": 162779,
    "out:% solar/total": 47,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 405674,
    "out:% savings (space heating)": 38.4,
    "out:Total_Water Heating (DH)": 152130,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 108.91,
    "out:Etvv": 17,
    "out:Ef": 13.09,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000000000000010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 82.51,
    "out:DHW (DH)": 43.5,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 20.5,
    "out:Total Energy Use Pre PV": 146.5,
    "out:Total Energy Use Post PV": 146.5,
    "out:Primary Energy": 112.16,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 10.16,
    "out:CO2 Operational/m2": 48.06,
    "out:Total CO2/m2": 58.22,
    "out:Total CO2 (tons)": 299.43,
    "out:Klimatpaverkan": -29.81,
    "out:Initial Cost/MSEK": 6.47825,
    "out:Running cost/(Apt SEK y)": 44818,
    "out:Running Cost over RSP/MSEK": 14.714,
    "out:LCP/MSEK": -1.923,
    "out:ROI% old": 10.04,
    "out:Payback discounted": 12,
    "out:Atemp": 5143,
    "out:Umean": 0.57,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 146,
    "out:Energy use kWh/m2": 146,
    "out:Energy savings %": 28.8,
    "out:Op energy cost/MSEK": 108,
    "out:El price/MSEK": 65,
    "out:Heating price/MSEK": 36,
    "out:DH+ DHW/kWh/m2": 126,
    "out:El bought/kWh/m2": 1434.9,
    "out:Return %": 9,
    "out:Return/kSEK/y": 582,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 875718.530813,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 236578,
    "out:EL kWh savings": -23653,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 175076,
    "out:DH kr savings": 215286,
    "out:El kr savings": -40210,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 424359,
    "out:% savings (space heating)": 35.57,
    "out:Total_Water Heating (DH)": 223720,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 112.54,
    "out:Etvv": 25,
    "out:Ef": 20.5,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000000000000110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 77.67,
    "out:DHW (DH)": 43.5,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 20.5,
    "out:Total Energy Use Pre PV": 141.5,
    "out:Total Energy Use Post PV": 141.5,
    "out:Primary Energy": 108.77,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 10.16,
    "out:CO2 Operational/m2": 46.26,
    "out:Total CO2/m2": 56.42,
    "out:Total CO2 (tons)": 290.17,
    "out:Klimatpaverkan": -39.07,
    "out:Initial Cost/MSEK": 6.606875,
    "out:Running cost/(Apt SEK y)": 43209,
    "out:Running Cost over RSP/MSEK": 14.185,
    "out:LCP/MSEK": -1.523,
    "out:ROI% old": 10.99,
    "out:Payback discounted": 11,
    "out:Atemp": 5143,
    "out:Umean": 0.57,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 141,
    "out:Energy use kWh/m2": 141,
    "out:Energy savings %": 33.3,
    "out:Op energy cost/MSEK": 104,
    "out:El price/MSEK": 63,
    "out:Heating price/MSEK": 35,
    "out:DH+ DHW/kWh/m2": 121,
    "out:El bought/kWh/m2": 1434.9,
    "out:Return %": 10,
    "out:Return/kSEK/y": 649,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 875718.530813,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 262293,
    "out:EL kWh savings": -23653,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 128574.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 198477,
    "out:DH kr savings": 238687,
    "out:El kr savings": -40210,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 399453,
    "out:% savings (space heating)": 39.35,
    "out:Total_Water Heating (DH)": 223720,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 107.7,
    "out:Etvv": 25,
    "out:Ef": 20.5,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000000000001010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 82.51,
    "out:DHW (DH)": 36.98,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 20.5,
    "out:Total Energy Use Pre PV": 139.5,
    "out:Total Energy Use Post PV": 139.5,
    "out:Primary Energy": 109.53,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 10.56,
    "out:CO2 Operational/m2": 45.54,
    "out:Total CO2/m2": 56.1,
    "out:Total CO2 (tons)": 288.54,
    "out:Klimatpaverkan": -40.7,
    "out:Initial Cost/MSEK": 6.72875,
    "out:Running cost/(Apt SEK y)": 42565,
    "out:Running Cost over RSP/MSEK": 13.974,
    "out:LCP/MSEK": -1.433,
    "out:ROI% old": 11.24,
    "out:Payback discounted": 11,
    "out:Atemp": 5143,
    "out:Umean": 0.57,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 139,
    "out:Energy use kWh/m2": 139,
    "out:Energy savings %": 35.3,
    "out:Op energy cost/MSEK": 102,
    "out:El price/MSEK": 62,
    "out:Heating price/MSEK": 34,
    "out:DH+ DHW/kWh/m2": 119,
    "out:El bought/kWh/m2": 1434.9,
    "out:Return %": 10,
    "out:Return/kSEK/y": 676,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 875718.530813,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 272579,
    "out:EL kWh savings": -23653,
    "out:Sewage heat recovery cost": 250448,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 207837,
    "out:DH kr savings": 248047,
    "out:El kr savings": -40210,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 424359,
    "out:% savings (space heating)": 35.57,
    "out:Total_Water Heating (DH)": 190162,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 112.54,
    "out:Etvv": 21.25,
    "out:Ef": 20.5,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000000000001110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 77.67,
    "out:DHW (DH)": 36.98,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 20.5,
    "out:Total Energy Use Pre PV": 135.5,
    "out:Total Energy Use Post PV": 135.5,
    "out:Primary Energy": 106.14,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 10.56,
    "out:CO2 Operational/m2": 44.1,
    "out:Total CO2/m2": 54.66,
    "out:Total CO2 (tons)": 281.13,
    "out:Klimatpaverkan": -48.11,
    "out:Initial Cost/MSEK": 6.85725,
    "out:Running cost/(Apt SEK y)": 41278,
    "out:Running Cost over RSP/MSEK": 13.55,
    "out:LCP/MSEK": -1.139,
    "out:ROI% old": 11.91,
    "out:Payback discounted": 10,
    "out:Atemp": 5143,
    "out:Umean": 0.57,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 135,
    "out:Energy use kWh/m2": 135,
    "out:Energy savings %": 39.3,
    "out:Op energy cost/MSEK": 99,
    "out:El price/MSEK": 61,
    "out:Heating price/MSEK": 33,
    "out:DH+ DHW/kWh/m2": 115,
    "out:El bought/kWh/m2": 1434.9,
    "out:Return %": 11,
    "out:Return/kSEK/y": 730,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 875718.530813,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 293151,
    "out:EL kWh savings": -23653,
    "out:Sewage heat recovery cost": 250448,
    "out:Balancing hydronic system cost": 128574.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 226558,
    "out:DH kr savings": 266767,
    "out:El kr savings": -40210,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 399453,
    "out:% savings (space heating)": 39.35,
    "out:Total_Water Heating (DH)": 190162,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 107.7,
    "out:Etvv": 21.25,
    "out:Ef": 20.5,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000000010000010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 82.51,
    "out:DHW (DH)": 43.5,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 14.33,
    "out:Total Energy Use Pre PV": 146.5,
    "out:Total Energy Use Post PV": 140.33,
    "out:Primary Energy": 101.05,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 22.51,
    "out:CO2 Operational/m2": 22.05,
    "out:Total CO2/m2": 44.56,
    "out:Total CO2 (tons)": 229.17,
    "out:Klimatpaverkan": -100.07,
    "out:Initial Cost/MSEK": 7.548125,
    "out:Running cost/(Apt SEK y)": 42752,
    "out:Running Cost over RSP/MSEK": 14.053,
    "out:LCP/MSEK": -2.333,
    "out:ROI% old": 9.87,
    "out:Payback discounted": 12,
    "out:Atemp": 5143,
    "out:Umean": 0.57,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 140,
    "out:Energy use kWh/m2": 140,
    "out:Energy savings %": 34.3,
    "out:Op energy cost/MSEK": 104,
    "out:El price/MSEK": 61,
    "out:Heating price/MSEK": 36,
    "out:DH+ DHW/kWh/m2": 126,
    "out:El bought/kWh/m2": 1013.1,
    "out:Return %": 9,
    "out:Return/kSEK/y": 668,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 61.265983,
    "out:PV (m2 panels)": 321,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 57484.06,
    "out:PV (kWh sold)": 25763.95,
    "out:PV (% sold (El))": 44.82,
    "out:PV (kWh self-consumed)": 31720.11,
    "out:PV (ratio sold/self-consumed)": 0.81,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 875718.530813,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 236578,
    "out:EL kWh savings": 8067,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 261850,
    "out:DH kr savings": 215286,
    "out:El kr savings": 13715,
    "out:El kr sold": 32849,
    "out:El kr saved": 53924,
    "out:El kr return": 86773,
    "out:% solar/total": 39,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 424359,
    "out:% savings (space heating)": 35.57,
    "out:Total_Water Heating (DH)": 223720,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 112.54,
    "out:Etvv": 25,
    "out:Ef": 14.33,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000000010000110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 77.67,
    "out:DHW (DH)": 43.5,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 14.33,
    "out:Total Energy Use Pre PV": 141.5,
    "out:Total Energy Use Post PV": 135.33,
    "out:Primary Energy": 97.66,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 22.51,
    "out:CO2 Operational/m2": 20.25,
    "out:Total CO2/m2": 42.76,
    "out:Total CO2 (tons)": 219.91,
    "out:Klimatpaverkan": -109.33,
    "out:Initial Cost/MSEK": 7.676625,
    "out:Running cost/(Apt SEK y)": 41143,
    "out:Running Cost over RSP/MSEK": 13.525,
    "out:LCP/MSEK": -1.932,
    "out:ROI% old": 10.69,
    "out:Payback discounted": 11,
    "out:Atemp": 5143,
    "out:Umean": 0.57,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 135,
    "out:Energy use kWh/m2": 135,
    "out:Energy savings %": 39.3,
    "out:Op energy cost/MSEK": 100,
    "out:El price/MSEK": 59,
    "out:Heating price/MSEK": 35,
    "out:DH+ DHW/kWh/m2": 121,
    "out:El bought/kWh/m2": 1013.1,
    "out:Return %": 10,
    "out:Return/kSEK/y": 736,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 61.265983,
    "out:PV (m2 panels)": 321,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 57484.06,
    "out:PV (kWh sold)": 25763.95,
    "out:PV (% sold (El))": 44.82,
    "out:PV (kWh self-consumed)": 31720.11,
    "out:PV (ratio sold/self-consumed)": 0.81,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 875718.530813,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 262293,
    "out:EL kWh savings": 8067,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 128574.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 285250,
    "out:DH kr savings": 238687,
    "out:El kr savings": 13715,
    "out:El kr sold": 32849,
    "out:El kr saved": 53924,
    "out:El kr return": 86773,
    "out:% solar/total": 39,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 399453,
    "out:% savings (space heating)": 39.35,
    "out:Total_Water Heating (DH)": 223720,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 107.7,
    "out:Etvv": 25,
    "out:Ef": 14.33,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000000010001010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 82.51,
    "out:DHW (DH)": 36.98,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 14.33,
    "out:Total Energy Use Pre PV": 139.5,
    "out:Total Energy Use Post PV": 133.33,
    "out:Primary Energy": 98.43,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 22.91,
    "out:CO2 Operational/m2": 19.53,
    "out:Total CO2/m2": 42.44,
    "out:Total CO2 (tons)": 218.28,
    "out:Klimatpaverkan": -110.96,
    "out:Initial Cost/MSEK": 7.7985,
    "out:Running cost/(Apt SEK y)": 40499,
    "out:Running Cost over RSP/MSEK": 13.313,
    "out:LCP/MSEK": -1.842,
    "out:ROI% old": 10.91,
    "out:Payback discounted": 11,
    "out:Atemp": 5143,
    "out:Umean": 0.57,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 133,
    "out:Energy use kWh/m2": 133,
    "out:Energy savings %": 41.4,
    "out:Op energy cost/MSEK": 98,
    "out:El price/MSEK": 58,
    "out:Heating price/MSEK": 34,
    "out:DH+ DHW/kWh/m2": 119,
    "out:El bought/kWh/m2": 1013.1,
    "out:Return %": 10,
    "out:Return/kSEK/y": 763,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 61.265983,
    "out:PV (m2 panels)": 321,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 57484.06,
    "out:PV (kWh sold)": 25763.95,
    "out:PV (% sold (El))": 44.82,
    "out:PV (kWh self-consumed)": 31720.11,
    "out:PV (ratio sold/self-consumed)": 0.81,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 875718.530813,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 272579,
    "out:EL kWh savings": 8067,
    "out:Sewage heat recovery cost": 250448,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 294611,
    "out:DH kr savings": 248047,
    "out:El kr savings": 13715,
    "out:El kr sold": 32849,
    "out:El kr saved": 53924,
    "out:El kr return": 86773,
    "out:% solar/total": 39,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 424359,
    "out:% savings (space heating)": 35.57,
    "out:Total_Water Heating (DH)": 190162,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 112.54,
    "out:Etvv": 21.25,
    "out:Ef": 14.33,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000000010001110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 77.67,
    "out:DHW (DH)": 36.98,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 14.33,
    "out:Total Energy Use Pre PV": 135.5,
    "out:Total Energy Use Post PV": 129.33,
    "out:Primary Energy": 95.04,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 22.91,
    "out:CO2 Operational/m2": 18.09,
    "out:Total CO2/m2": 41,
    "out:Total CO2 (tons)": 210.87,
    "out:Klimatpaverkan": -118.37,
    "out:Initial Cost/MSEK": 7.927125,
    "out:Running cost/(Apt SEK y)": 39212,
    "out:Running Cost over RSP/MSEK": 12.89,
    "out:LCP/MSEK": -1.548,
    "out:ROI% old": 11.5,
    "out:Payback discounted": 11,
    "out:Atemp": 5143,
    "out:Umean": 0.57,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 129,
    "out:Energy use kWh/m2": 129,
    "out:Energy savings %": 45.7,
    "out:Op energy cost/MSEK": 95,
    "out:El price/MSEK": 57,
    "out:Heating price/MSEK": 33,
    "out:DH+ DHW/kWh/m2": 115,
    "out:El bought/kWh/m2": 1013.1,
    "out:Return %": 10,
    "out:Return/kSEK/y": 817,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 61.265983,
    "out:PV (m2 panels)": 321,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 57484.06,
    "out:PV (kWh sold)": 25763.95,
    "out:PV (% sold (El))": 44.82,
    "out:PV (kWh self-consumed)": 31720.11,
    "out:PV (ratio sold/self-consumed)": 0.81,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 875718.530813,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 293151,
    "out:EL kWh savings": 8067,
    "out:Sewage heat recovery cost": 250448,
    "out:Balancing hydronic system cost": 128574.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 313331,
    "out:DH kr savings": 266767,
    "out:El kr savings": 13715,
    "out:El kr sold": 32849,
    "out:El kr saved": 53924,
    "out:El kr return": 86773,
    "out:% solar/total": 39,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 399453,
    "out:% savings (space heating)": 39.35,
    "out:Total_Water Heating (DH)": 190162,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 107.7,
    "out:Etvv": 21.25,
    "out:Ef": 14.33,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000000020000010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 82.51,
    "out:DHW (DH)": 43.5,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 13.09,
    "out:Total Energy Use Pre PV": 146.5,
    "out:Total Energy Use Post PV": 139.09,
    "out:Primary Energy": 98.82,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 34.86,
    "out:CO2 Operational/m2": -28.63,
    "out:Total CO2/m2": 6.23,
    "out:Total CO2 (tons)": 32.05,
    "out:Klimatpaverkan": -297.19,
    "out:Initial Cost/MSEK": 8.617875,
    "out:Running cost/(Apt SEK y)": 40942,
    "out:Running Cost over RSP/MSEK": 13.475,
    "out:LCP/MSEK": -2.824,
    "out:ROI% old": 9.6,
    "out:Payback discounted": 13,
    "out:Atemp": 5143,
    "out:Umean": 0.57,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 139,
    "out:Energy use kWh/m2": 139,
    "out:Energy savings %": 35.3,
    "out:Op energy cost/MSEK": 100,
    "out:El price/MSEK": 58,
    "out:Heating price/MSEK": 36,
    "out:DH+ DHW/kWh/m2": 126,
    "out:El bought/kWh/m2": 923.7,
    "out:Return %": 9,
    "out:Return/kSEK/y": 744,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.33,
    "out:PV (kWp installed)": 122.531965,
    "out:PV (m2 panels)": 643,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 114968.11,
    "out:PV (kWh sold)": 76862.14,
    "out:PV (% sold (El))": 66.86,
    "out:PV (kWh self-consumed)": 38105.97,
    "out:PV (ratio sold/self-consumed)": 2.02,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 875718.530813,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 236578,
    "out:EL kWh savings": 14453,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 337856,
    "out:DH kr savings": 215286,
    "out:El kr savings": 24571,
    "out:El kr sold": 97999,
    "out:El kr saved": 64780,
    "out:El kr return": 162779,
    "out:% solar/total": 47,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 424359,
    "out:% savings (space heating)": 35.57,
    "out:Total_Water Heating (DH)": 223720,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 112.54,
    "out:Etvv": 25,
    "out:Ef": 13.09,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000000020000110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 77.67,
    "out:DHW (DH)": 43.5,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 13.09,
    "out:Total Energy Use Pre PV": 141.5,
    "out:Total Energy Use Post PV": 134.09,
    "out:Primary Energy": 95.43,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 34.86,
    "out:CO2 Operational/m2": -30.43,
    "out:Total CO2/m2": 4.43,
    "out:Total CO2 (tons)": 22.79,
    "out:Klimatpaverkan": -306.45,
    "out:Initial Cost/MSEK": 8.746375,
    "out:Running cost/(Apt SEK y)": 39333,
    "out:Running Cost over RSP/MSEK": 12.946,
    "out:LCP/MSEK": -2.424,
    "out:ROI% old": 10.33,
    "out:Payback discounted": 12,
    "out:Atemp": 5143,
    "out:Umean": 0.57,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 134,
    "out:Energy use kWh/m2": 134,
    "out:Energy savings %": 40.3,
    "out:Op energy cost/MSEK": 96,
    "out:El price/MSEK": 56,
    "out:Heating price/MSEK": 35,
    "out:DH+ DHW/kWh/m2": 121,
    "out:El bought/kWh/m2": 923.7,
    "out:Return %": 9,
    "out:Return/kSEK/y": 812,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.33,
    "out:PV (kWp installed)": 122.531965,
    "out:PV (m2 panels)": 643,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 114968.11,
    "out:PV (kWh sold)": 76862.14,
    "out:PV (% sold (El))": 66.86,
    "out:PV (kWh self-consumed)": 38105.97,
    "out:PV (ratio sold/self-consumed)": 2.02,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 875718.530813,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 262293,
    "out:EL kWh savings": 14453,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 128574.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 361256,
    "out:DH kr savings": 238687,
    "out:El kr savings": 24571,
    "out:El kr sold": 97999,
    "out:El kr saved": 64780,
    "out:El kr return": 162779,
    "out:% solar/total": 47,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 399453,
    "out:% savings (space heating)": 39.35,
    "out:Total_Water Heating (DH)": 223720,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 107.7,
    "out:Etvv": 25,
    "out:Ef": 13.09,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000000020001010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 82.51,
    "out:DHW (DH)": 36.98,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 13.09,
    "out:Total Energy Use Pre PV": 139.5,
    "out:Total Energy Use Post PV": 132.09,
    "out:Primary Energy": 96.19,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 35.27,
    "out:CO2 Operational/m2": -31.15,
    "out:Total CO2/m2": 4.11,
    "out:Total CO2 (tons)": 21.16,
    "out:Klimatpaverkan": -308.08,
    "out:Initial Cost/MSEK": 8.86825,
    "out:Running cost/(Apt SEK y)": 38689,
    "out:Running Cost over RSP/MSEK": 12.735,
    "out:LCP/MSEK": -2.334,
    "out:ROI% old": 10.53,
    "out:Payback discounted": 11,
    "out:Atemp": 5143,
    "out:Umean": 0.57,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 132,
    "out:Energy use kWh/m2": 132,
    "out:Energy savings %": 42.4,
    "out:Op energy cost/MSEK": 95,
    "out:El price/MSEK": 55,
    "out:Heating price/MSEK": 34,
    "out:DH+ DHW/kWh/m2": 119,
    "out:El bought/kWh/m2": 923.7,
    "out:Return %": 9,
    "out:Return/kSEK/y": 839,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.33,
    "out:PV (kWp installed)": 122.531965,
    "out:PV (m2 panels)": 643,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 114968.11,
    "out:PV (kWh sold)": 76862.14,
    "out:PV (% sold (El))": 66.86,
    "out:PV (kWh self-consumed)": 38105.97,
    "out:PV (ratio sold/self-consumed)": 2.02,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 875718.530813,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 272579,
    "out:EL kWh savings": 14453,
    "out:Sewage heat recovery cost": 250448,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 370617,
    "out:DH kr savings": 248047,
    "out:El kr savings": 24571,
    "out:El kr sold": 97999,
    "out:El kr saved": 64780,
    "out:El kr return": 162779,
    "out:% solar/total": 47,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 424359,
    "out:% savings (space heating)": 35.57,
    "out:Total_Water Heating (DH)": 190162,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 112.54,
    "out:Etvv": 21.25,
    "out:Ef": 13.09,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000000020001110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 77.67,
    "out:DHW (DH)": 36.98,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 13.09,
    "out:Total Energy Use Pre PV": 135.5,
    "out:Total Energy Use Post PV": 128.09,
    "out:Primary Energy": 92.8,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 35.27,
    "out:CO2 Operational/m2": -32.59,
    "out:Total CO2/m2": 2.67,
    "out:Total CO2 (tons)": 13.75,
    "out:Klimatpaverkan": -315.49,
    "out:Initial Cost/MSEK": 8.996875,
    "out:Running cost/(Apt SEK y)": 37402,
    "out:Running Cost over RSP/MSEK": 12.312,
    "out:LCP/MSEK": -2.04,
    "out:ROI% old": 11.05,
    "out:Payback discounted": 11,
    "out:Atemp": 5143,
    "out:Umean": 0.57,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 128,
    "out:Energy use kWh/m2": 128,
    "out:Energy savings %": 46.9,
    "out:Op energy cost/MSEK": 91,
    "out:El price/MSEK": 53,
    "out:Heating price/MSEK": 33,
    "out:DH+ DHW/kWh/m2": 115,
    "out:El bought/kWh/m2": 923.7,
    "out:Return %": 10,
    "out:Return/kSEK/y": 893,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.33,
    "out:PV (kWp installed)": 122.531965,
    "out:PV (m2 panels)": 643,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 114968.11,
    "out:PV (kWh sold)": 76862.14,
    "out:PV (% sold (El))": 66.86,
    "out:PV (kWh self-consumed)": 38105.97,
    "out:PV (ratio sold/self-consumed)": 2.02,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 875718.530813,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 293151,
    "out:EL kWh savings": 14453,
    "out:Sewage heat recovery cost": 250448,
    "out:Balancing hydronic system cost": 128574.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 389337,
    "out:DH kr savings": 266767,
    "out:El kr savings": 24571,
    "out:El kr sold": 97999,
    "out:El kr saved": 64780,
    "out:El kr return": 162779,
    "out:% solar/total": 47,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 399453,
    "out:% savings (space heating)": 39.35,
    "out:Total_Water Heating (DH)": 190162,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 107.7,
    "out:Etvv": 21.25,
    "out:Ef": 13.09,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000000100000010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 82.51,
    "out:DHW (DH)": 34.8,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 20.5,
    "out:Total Energy Use Pre PV": 137.5,
    "out:Total Energy Use Post PV": 137.5,
    "out:Primary Energy": 108.66,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 10.16,
    "out:CO2 Operational/m2": 44.82,
    "out:Total CO2/m2": 54.98,
    "out:Total CO2 (tons)": 282.77,
    "out:Klimatpaverkan": -46.47,
    "out:Initial Cost/MSEK": 6.589125,
    "out:Running cost/(Apt SEK y)": 41921,
    "out:Running Cost over RSP/MSEK": 13.762,
    "out:LCP/MSEK": -1.082,
    "out:ROI% old": 11.94,
    "out:Payback discounted": 10,
    "out:Atemp": 5143,
    "out:Umean": 0.57,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 137,
    "out:Energy use kWh/m2": 137,
    "out:Energy savings %": 37.2,
    "out:Op energy cost/MSEK": 101,
    "out:El price/MSEK": 61,
    "out:Heating price/MSEK": 34,
    "out:DH+ DHW/kWh/m2": 117,
    "out:El bought/kWh/m2": 1434.9,
    "out:Return %": 11,
    "out:Return/kSEK/y": 703,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 875718.530813,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 282865,
    "out:EL kWh savings": -23653,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 217198,
    "out:DH kr savings": 257407,
    "out:El kr savings": -40210,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 424359,
    "out:% savings (space heating)": 35.57,
    "out:Total_Water Heating (DH)": 178976,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 112.54,
    "out:Etvv": 20,
    "out:Ef": 20.5,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000000100000110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 77.67,
    "out:DHW (DH)": 34.8,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 20.5,
    "out:Total Energy Use Pre PV": 132.5,
    "out:Total Energy Use Post PV": 132.5,
    "out:Primary Energy": 105.27,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 10.16,
    "out:CO2 Operational/m2": 43.02,
    "out:Total CO2/m2": 53.18,
    "out:Total CO2 (tons)": 273.51,
    "out:Klimatpaverkan": -55.73,
    "out:Initial Cost/MSEK": 6.71775,
    "out:Running cost/(Apt SEK y)": 40312,
    "out:Running Cost over RSP/MSEK": 13.233,
    "out:LCP/MSEK": -0.682,
    "out:ROI% old": 12.84,
    "out:Payback discounted": 9,
    "out:Atemp": 5143,
    "out:Umean": 0.57,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 132,
    "out:Energy use kWh/m2": 132,
    "out:Energy savings %": 42.4,
    "out:Op energy cost/MSEK": 97,
    "out:El price/MSEK": 59,
    "out:Heating price/MSEK": 32,
    "out:DH+ DHW/kWh/m2": 112,
    "out:El bought/kWh/m2": 1434.9,
    "out:Return %": 11,
    "out:Return/kSEK/y": 771,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 875718.530813,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 308580,
    "out:EL kWh savings": -23653,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 128574.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 240598,
    "out:DH kr savings": 280808,
    "out:El kr savings": -40210,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 399453,
    "out:% savings (space heating)": 39.35,
    "out:Total_Water Heating (DH)": 178976,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 107.7,
    "out:Etvv": 20,
    "out:Ef": 20.5,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000000100001010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 82.51,
    "out:DHW (DH)": 29.58,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 20.5,
    "out:Total Energy Use Pre PV": 132.5,
    "out:Total Energy Use Post PV": 132.5,
    "out:Primary Energy": 106.56,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 10.56,
    "out:CO2 Operational/m2": 43.02,
    "out:Total CO2/m2": 53.58,
    "out:Total CO2 (tons)": 275.58,
    "out:Klimatpaverkan": -53.66,
    "out:Initial Cost/MSEK": 6.839625,
    "out:Running cost/(Apt SEK y)": 40312,
    "out:Running Cost over RSP/MSEK": 13.233,
    "out:LCP/MSEK": -0.804,
    "out:ROI% old": 12.61,
    "out:Payback discounted": 10,
    "out:Atemp": 5143,
    "out:Umean": 0.57,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 132,
    "out:Energy use kWh/m2": 132,
    "out:Energy savings %": 42.4,
    "out:Op energy cost/MSEK": 97,
    "out:El price/MSEK": 59,
    "out:Heating price/MSEK": 32,
    "out:DH+ DHW/kWh/m2": 112,
    "out:El bought/kWh/m2": 1434.9,
    "out:Return %": 11,
    "out:Return/kSEK/y": 771,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 875718.530813,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 308580,
    "out:EL kWh savings": -23653,
    "out:Sewage heat recovery cost": 250448,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 240598,
    "out:DH kr savings": 280808,
    "out:El kr savings": -40210,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 424359,
    "out:% savings (space heating)": 35.57,
    "out:Total_Water Heating (DH)": 152130,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 112.54,
    "out:Etvv": 17,
    "out:Ef": 20.5,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000000100001110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 77.67,
    "out:DHW (DH)": 29.58,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 20.5,
    "out:Total Energy Use Pre PV": 127.5,
    "out:Total Energy Use Post PV": 127.5,
    "out:Primary Energy": 103.17,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 10.56,
    "out:CO2 Operational/m2": 41.22,
    "out:Total CO2/m2": 51.78,
    "out:Total CO2 (tons)": 266.32,
    "out:Klimatpaverkan": -62.92,
    "out:Initial Cost/MSEK": 6.968125,
    "out:Running cost/(Apt SEK y)": 38703,
    "out:Running Cost over RSP/MSEK": 12.704,
    "out:LCP/MSEK": -0.403,
    "out:ROI% old": 13.46,
    "out:Payback discounted": 9,
    "out:Atemp": 5143,
    "out:Umean": 0.57,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 127,
    "out:Energy use kWh/m2": 127,
    "out:Energy savings %": 48,
    "out:Op energy cost/MSEK": 93,
    "out:El price/MSEK": 57,
    "out:Heating price/MSEK": 31,
    "out:DH+ DHW/kWh/m2": 107,
    "out:El bought/kWh/m2": 1434.9,
    "out:Return %": 12,
    "out:Return/kSEK/y": 838,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 875718.530813,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 334295,
    "out:EL kWh savings": -23653,
    "out:Sewage heat recovery cost": 250448,
    "out:Balancing hydronic system cost": 128574.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 263999,
    "out:DH kr savings": 304208,
    "out:El kr savings": -40210,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 399453,
    "out:% savings (space heating)": 39.35,
    "out:Total_Water Heating (DH)": 152130,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 107.7,
    "out:Etvv": 17,
    "out:Ef": 20.5,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000000110000010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 82.51,
    "out:DHW (DH)": 34.8,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 14.33,
    "out:Total Energy Use Pre PV": 137.5,
    "out:Total Energy Use Post PV": 131.33,
    "out:Primary Energy": 97.55,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 22.51,
    "out:CO2 Operational/m2": 18.81,
    "out:Total CO2/m2": 41.32,
    "out:Total CO2 (tons)": 212.51,
    "out:Klimatpaverkan": -116.73,
    "out:Initial Cost/MSEK": 7.658875,
    "out:Running cost/(Apt SEK y)": 39855,
    "out:Running Cost over RSP/MSEK": 13.101,
    "out:LCP/MSEK": -1.491,
    "out:ROI% old": 11.5,
    "out:Payback discounted": 11,
    "out:Atemp": 5143,
    "out:Umean": 0.57,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 131,
    "out:Energy use kWh/m2": 131,
    "out:Energy savings %": 43.5,
    "out:Op energy cost/MSEK": 96,
    "out:El price/MSEK": 57,
    "out:Heating price/MSEK": 34,
    "out:DH+ DHW/kWh/m2": 117,
    "out:El bought/kWh/m2": 1013.1,
    "out:Return %": 10,
    "out:Return/kSEK/y": 790,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 61.265983,
    "out:PV (m2 panels)": 321,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 57484.06,
    "out:PV (kWh sold)": 25763.95,
    "out:PV (% sold (El))": 44.82,
    "out:PV (kWh self-consumed)": 31720.11,
    "out:PV (ratio sold/self-consumed)": 0.81,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 875718.530813,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 282865,
    "out:EL kWh savings": 8067,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 303971,
    "out:DH kr savings": 257407,
    "out:El kr savings": 13715,
    "out:El kr sold": 32849,
    "out:El kr saved": 53924,
    "out:El kr return": 86773,
    "out:% solar/total": 39,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 424359,
    "out:% savings (space heating)": 35.57,
    "out:Total_Water Heating (DH)": 178976,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 112.54,
    "out:Etvv": 20,
    "out:Ef": 14.33,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000000110000110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 77.67,
    "out:DHW (DH)": 34.8,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 14.33,
    "out:Total Energy Use Pre PV": 132.5,
    "out:Total Energy Use Post PV": 126.33,
    "out:Primary Energy": 94.16,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 22.51,
    "out:CO2 Operational/m2": 17.01,
    "out:Total CO2/m2": 39.52,
    "out:Total CO2 (tons)": 203.25,
    "out:Klimatpaverkan": -125.99,
    "out:Initial Cost/MSEK": 7.7875,
    "out:Running cost/(Apt SEK y)": 38246,
    "out:Running Cost over RSP/MSEK": 12.573,
    "out:LCP/MSEK": -1.091,
    "out:ROI% old": 12.28,
    "out:Payback discounted": 10,
    "out:Atemp": 5143,
    "out:Umean": 0.57,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 126,
    "out:Energy use kWh/m2": 126,
    "out:Energy savings %": 49.2,
    "out:Op energy cost/MSEK": 93,
    "out:El price/MSEK": 55,
    "out:Heating price/MSEK": 32,
    "out:DH+ DHW/kWh/m2": 112,
    "out:El bought/kWh/m2": 1013.1,
    "out:Return %": 11,
    "out:Return/kSEK/y": 858,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 61.265983,
    "out:PV (m2 panels)": 321,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 57484.06,
    "out:PV (kWh sold)": 25763.95,
    "out:PV (% sold (El))": 44.82,
    "out:PV (kWh self-consumed)": 31720.11,
    "out:PV (ratio sold/self-consumed)": 0.81,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 875718.530813,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 308580,
    "out:EL kWh savings": 8067,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 128574.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 327371,
    "out:DH kr savings": 280808,
    "out:El kr savings": 13715,
    "out:El kr sold": 32849,
    "out:El kr saved": 53924,
    "out:El kr return": 86773,
    "out:% solar/total": 39,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 399453,
    "out:% savings (space heating)": 39.35,
    "out:Total_Water Heating (DH)": 178976,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 107.7,
    "out:Etvv": 20,
    "out:Ef": 14.33,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000000110001010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 82.51,
    "out:DHW (DH)": 29.58,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 14.33,
    "out:Total Energy Use Pre PV": 132.5,
    "out:Total Energy Use Post PV": 126.33,
    "out:Primary Energy": 95.45,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 22.91,
    "out:CO2 Operational/m2": 17.01,
    "out:Total CO2/m2": 39.92,
    "out:Total CO2 (tons)": 205.32,
    "out:Klimatpaverkan": -123.92,
    "out:Initial Cost/MSEK": 7.909375,
    "out:Running cost/(Apt SEK y)": 38246,
    "out:Running Cost over RSP/MSEK": 12.573,
    "out:LCP/MSEK": -1.213,
    "out:ROI% old": 12.1,
    "out:Payback discounted": 10,
    "out:Atemp": 5143,
    "out:Umean": 0.57,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 126,
    "out:Energy use kWh/m2": 126,
    "out:Energy savings %": 49.2,
    "out:Op energy cost/MSEK": 93,
    "out:El price/MSEK": 55,
    "out:Heating price/MSEK": 32,
    "out:DH+ DHW/kWh/m2": 112,
    "out:El bought/kWh/m2": 1013.1,
    "out:Return %": 11,
    "out:Return/kSEK/y": 858,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 61.265983,
    "out:PV (m2 panels)": 321,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 57484.06,
    "out:PV (kWh sold)": 25763.95,
    "out:PV (% sold (El))": 44.82,
    "out:PV (kWh self-consumed)": 31720.11,
    "out:PV (ratio sold/self-consumed)": 0.81,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 875718.530813,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 308580,
    "out:EL kWh savings": 8067,
    "out:Sewage heat recovery cost": 250448,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 327371,
    "out:DH kr savings": 280808,
    "out:El kr savings": 13715,
    "out:El kr sold": 32849,
    "out:El kr saved": 53924,
    "out:El kr return": 86773,
    "out:% solar/total": 39,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 424359,
    "out:% savings (space heating)": 35.57,
    "out:Total_Water Heating (DH)": 152130,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 112.54,
    "out:Etvv": 17,
    "out:Ef": 14.33,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000000110001110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 77.67,
    "out:DHW (DH)": 29.58,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 14.33,
    "out:Total Energy Use Pre PV": 127.5,
    "out:Total Energy Use Post PV": 121.33,
    "out:Primary Energy": 92.06,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 22.91,
    "out:CO2 Operational/m2": 15.21,
    "out:Total CO2/m2": 38.12,
    "out:Total CO2 (tons)": 196.06,
    "out:Klimatpaverkan": -133.18,
    "out:Initial Cost/MSEK": 8.038,
    "out:Running cost/(Apt SEK y)": 36637,
    "out:Running Cost over RSP/MSEK": 12.044,
    "out:LCP/MSEK": -0.813,
    "out:ROI% old": 12.84,
    "out:Payback discounted": 9,
    "out:Atemp": 5143,
    "out:Umean": 0.57,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 121,
    "out:Energy use kWh/m2": 121,
    "out:Energy savings %": 55.4,
    "out:Op energy cost/MSEK": 89,
    "out:El price/MSEK": 53,
    "out:Heating price/MSEK": 31,
    "out:DH+ DHW/kWh/m2": 107,
    "out:El bought/kWh/m2": 1013.1,
    "out:Return %": 12,
    "out:Return/kSEK/y": 925,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 61.265983,
    "out:PV (m2 panels)": 321,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 57484.06,
    "out:PV (kWh sold)": 25763.95,
    "out:PV (% sold (El))": 44.82,
    "out:PV (kWh self-consumed)": 31720.11,
    "out:PV (ratio sold/self-consumed)": 0.81,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 875718.530813,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 334295,
    "out:EL kWh savings": 8067,
    "out:Sewage heat recovery cost": 250448,
    "out:Balancing hydronic system cost": 128574.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 350772,
    "out:DH kr savings": 304208,
    "out:El kr savings": 13715,
    "out:El kr sold": 32849,
    "out:El kr saved": 53924,
    "out:El kr return": 86773,
    "out:% solar/total": 39,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 399453,
    "out:% savings (space heating)": 39.35,
    "out:Total_Water Heating (DH)": 152130,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 107.7,
    "out:Etvv": 17,
    "out:Ef": 14.33,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000000120000010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 82.51,
    "out:DHW (DH)": 34.8,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 13.09,
    "out:Total Energy Use Pre PV": 137.5,
    "out:Total Energy Use Post PV": 130.09,
    "out:Primary Energy": 95.32,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 34.86,
    "out:CO2 Operational/m2": -31.87,
    "out:Total CO2/m2": 2.99,
    "out:Total CO2 (tons)": 15.39,
    "out:Klimatpaverkan": -313.85,
    "out:Initial Cost/MSEK": 8.72875,
    "out:Running cost/(Apt SEK y)": 38046,
    "out:Running Cost over RSP/MSEK": 12.523,
    "out:LCP/MSEK": -1.983,
    "out:ROI% old": 11.04,
    "out:Payback discounted": 11,
    "out:Atemp": 5143,
    "out:Umean": 0.57,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 130,
    "out:Energy use kWh/m2": 130,
    "out:Energy savings %": 44.6,
    "out:Op energy cost/MSEK": 93,
    "out:El price/MSEK": 54,
    "out:Heating price/MSEK": 34,
    "out:DH+ DHW/kWh/m2": 117,
    "out:El bought/kWh/m2": 923.7,
    "out:Return %": 10,
    "out:Return/kSEK/y": 866,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.33,
    "out:PV (kWp installed)": 122.531965,
    "out:PV (m2 panels)": 643,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 114968.11,
    "out:PV (kWh sold)": 76862.14,
    "out:PV (% sold (El))": 66.86,
    "out:PV (kWh self-consumed)": 38105.97,
    "out:PV (ratio sold/self-consumed)": 2.02,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 875718.530813,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 282865,
    "out:EL kWh savings": 14453,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 379977,
    "out:DH kr savings": 257407,
    "out:El kr savings": 24571,
    "out:El kr sold": 97999,
    "out:El kr saved": 64780,
    "out:El kr return": 162779,
    "out:% solar/total": 47,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 424359,
    "out:% savings (space heating)": 35.57,
    "out:Total_Water Heating (DH)": 178976,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 112.54,
    "out:Etvv": 20,
    "out:Ef": 13.09,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000000120000110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 77.67,
    "out:DHW (DH)": 34.8,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 13.09,
    "out:Total Energy Use Pre PV": 132.5,
    "out:Total Energy Use Post PV": 125.09,
    "out:Primary Energy": 91.93,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 34.86,
    "out:CO2 Operational/m2": -33.67,
    "out:Total CO2/m2": 1.19,
    "out:Total CO2 (tons)": 6.13,
    "out:Klimatpaverkan": -323.11,
    "out:Initial Cost/MSEK": 8.85725,
    "out:Running cost/(Apt SEK y)": 36436,
    "out:Running Cost over RSP/MSEK": 11.994,
    "out:LCP/MSEK": -1.583,
    "out:ROI% old": 11.73,
    "out:Payback discounted": 10,
    "out:Atemp": 5143,
    "out:Umean": 0.57,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 125,
    "out:Energy use kWh/m2": 125,
    "out:Energy savings %": 50.4,
    "out:Op energy cost/MSEK": 89,
    "out:El price/MSEK": 52,
    "out:Heating price/MSEK": 32,
    "out:DH+ DHW/kWh/m2": 112,
    "out:El bought/kWh/m2": 923.7,
    "out:Return %": 11,
    "out:Return/kSEK/y": 934,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.33,
    "out:PV (kWp installed)": 122.531965,
    "out:PV (m2 panels)": 643,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 114968.11,
    "out:PV (kWh sold)": 76862.14,
    "out:PV (% sold (El))": 66.86,
    "out:PV (kWh self-consumed)": 38105.97,
    "out:PV (ratio sold/self-consumed)": 2.02,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 875718.530813,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 308580,
    "out:EL kWh savings": 14453,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 128574.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 403378,
    "out:DH kr savings": 280808,
    "out:El kr savings": 24571,
    "out:El kr sold": 97999,
    "out:El kr saved": 64780,
    "out:El kr return": 162779,
    "out:% solar/total": 47,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 399453,
    "out:% savings (space heating)": 39.35,
    "out:Total_Water Heating (DH)": 178976,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 107.7,
    "out:Etvv": 20,
    "out:Ef": 13.09,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000000120001010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 82.51,
    "out:DHW (DH)": 29.58,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 13.09,
    "out:Total Energy Use Pre PV": 132.5,
    "out:Total Energy Use Post PV": 125.09,
    "out:Primary Energy": 93.22,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 35.27,
    "out:CO2 Operational/m2": -33.67,
    "out:Total CO2/m2": 1.59,
    "out:Total CO2 (tons)": 8.2,
    "out:Klimatpaverkan": -321.04,
    "out:Initial Cost/MSEK": 8.979125,
    "out:Running cost/(Apt SEK y)": 36436,
    "out:Running Cost over RSP/MSEK": 11.994,
    "out:LCP/MSEK": -1.705,
    "out:ROI% old": 11.57,
    "out:Payback discounted": 10,
    "out:Atemp": 5143,
    "out:Umean": 0.57,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 125,
    "out:Energy use kWh/m2": 125,
    "out:Energy savings %": 50.4,
    "out:Op energy cost/MSEK": 89,
    "out:El price/MSEK": 52,
    "out:Heating price/MSEK": 32,
    "out:DH+ DHW/kWh/m2": 112,
    "out:El bought/kWh/m2": 923.7,
    "out:Return %": 10,
    "out:Return/kSEK/y": 934,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.33,
    "out:PV (kWp installed)": 122.531965,
    "out:PV (m2 panels)": 643,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 114968.11,
    "out:PV (kWh sold)": 76862.14,
    "out:PV (% sold (El))": 66.86,
    "out:PV (kWh self-consumed)": 38105.97,
    "out:PV (ratio sold/self-consumed)": 2.02,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 875718.530813,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 308580,
    "out:EL kWh savings": 14453,
    "out:Sewage heat recovery cost": 250448,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 403378,
    "out:DH kr savings": 280808,
    "out:El kr savings": 24571,
    "out:El kr sold": 97999,
    "out:El kr saved": 64780,
    "out:El kr return": 162779,
    "out:% solar/total": 47,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 424359,
    "out:% savings (space heating)": 35.57,
    "out:Total_Water Heating (DH)": 152130,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 112.54,
    "out:Etvv": 17,
    "out:Ef": 13.09,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000000120001110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 77.67,
    "out:DHW (DH)": 29.58,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 13.09,
    "out:Total Energy Use Pre PV": 127.5,
    "out:Total Energy Use Post PV": 120.09,
    "out:Primary Energy": 89.83,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 35.27,
    "out:CO2 Operational/m2": -35.47,
    "out:Total CO2/m2": -0.21,
    "out:Total CO2 (tons)": -1.06,
    "out:Klimatpaverkan": -330.3,
    "out:Initial Cost/MSEK": 9.10775,
    "out:Running cost/(Apt SEK y)": 34827,
    "out:Running Cost over RSP/MSEK": 11.466,
    "out:LCP/MSEK": -1.304,
    "out:ROI% old": 12.24,
    "out:Payback discounted": 10,
    "out:Atemp": 5143,
    "out:Umean": 0.57,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 120,
    "out:Energy use kWh/m2": 120,
    "out:Energy savings %": 56.7,
    "out:Op energy cost/MSEK": 85,
    "out:El price/MSEK": 49,
    "out:Heating price/MSEK": 31,
    "out:DH+ DHW/kWh/m2": 107,
    "out:El bought/kWh/m2": 923.7,
    "out:Return %": 11,
    "out:Return/kSEK/y": 1001,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.33,
    "out:PV (kWp installed)": 122.531965,
    "out:PV (m2 panels)": 643,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 114968.11,
    "out:PV (kWh sold)": 76862.14,
    "out:PV (% sold (El))": 66.86,
    "out:PV (kWh self-consumed)": 38105.97,
    "out:PV (ratio sold/self-consumed)": 2.02,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 875718.530813,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 334295,
    "out:EL kWh savings": 14453,
    "out:Sewage heat recovery cost": 250448,
    "out:Balancing hydronic system cost": 128574.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 426778,
    "out:DH kr savings": 304208,
    "out:El kr savings": 24571,
    "out:El kr sold": 97999,
    "out:El kr saved": 64780,
    "out:El kr return": 162779,
    "out:% solar/total": 47,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 399453,
    "out:% savings (space heating)": 39.35,
    "out:Total_Water Heating (DH)": 152130,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 107.7,
    "out:Etvv": 17,
    "out:Ef": 13.09,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000100000000010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 71.21,
    "out:DHW (DH)": 43.5,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 20.5,
    "out:Total Energy Use Pre PV": 135.5,
    "out:Total Energy Use Post PV": 135.5,
    "out:Primary Energy": 104.24,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 12.18,
    "out:CO2 Operational/m2": 44.1,
    "out:Total CO2/m2": 56.28,
    "out:Total CO2 (tons)": 289.45,
    "out:Klimatpaverkan": -39.79,
    "out:Initial Cost/MSEK": 7.29975,
    "out:Running cost/(Apt SEK y)": 41278,
    "out:Running Cost over RSP/MSEK": 13.55,
    "out:LCP/MSEK": -1.581,
    "out:ROI% old": 11.19,
    "out:Payback discounted": 11,
    "out:Atemp": 5143,
    "out:Umean": 0.42,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FTX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 135,
    "out:Energy use kWh/m2": 135,
    "out:Energy savings %": 39.3,
    "out:Op energy cost/MSEK": 99,
    "out:El price/MSEK": 61,
    "out:Heating price/MSEK": 33,
    "out:DH+ DHW/kWh/m2": 115,
    "out:El bought/kWh/m2": 1434.9,
    "out:Return %": 10,
    "out:Return/kSEK/y": 730,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 875718.530813,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 293151,
    "out:EL kWh savings": -23653,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 226558,
    "out:DH kr savings": 266767,
    "out:El kr savings": -40210,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 366212,
    "out:% savings (space heating)": 44.39,
    "out:Total_Water Heating (DH)": 223720,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 101.23,
    "out:Etvv": 25,
    "out:Ef": 20.5,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000100000000110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 66.88,
    "out:DHW (DH)": 43.5,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 20.5,
    "out:Total Energy Use Pre PV": 130.5,
    "out:Total Energy Use Post PV": 130.5,
    "out:Primary Energy": 101.21,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 12.18,
    "out:CO2 Operational/m2": 42.3,
    "out:Total CO2/m2": 54.48,
    "out:Total CO2 (tons)": 280.19,
    "out:Klimatpaverkan": -49.05,
    "out:Initial Cost/MSEK": 7.428375,
    "out:Running cost/(Apt SEK y)": 39668,
    "out:Running Cost over RSP/MSEK": 13.021,
    "out:LCP/MSEK": -1.181,
    "out:ROI% old": 12.01,
    "out:Payback discounted": 10,
    "out:Atemp": 5143,
    "out:Umean": 0.42,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FTX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 130,
    "out:Energy use kWh/m2": 130,
    "out:Energy savings %": 44.6,
    "out:Op energy cost/MSEK": 95,
    "out:El price/MSEK": 58,
    "out:Heating price/MSEK": 32,
    "out:DH+ DHW/kWh/m2": 110,
    "out:El bought/kWh/m2": 1434.9,
    "out:Return %": 11,
    "out:Return/kSEK/y": 798,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 875718.530813,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 318866,
    "out:EL kWh savings": -23653,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 128574.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 249959,
    "out:DH kr savings": 290168,
    "out:El kr savings": -40210,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 343949,
    "out:% savings (space heating)": 47.77,
    "out:Total_Water Heating (DH)": 223720,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 96.9,
    "out:Etvv": 25,
    "out:Ef": 20.5,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000100000001010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 71.21,
    "out:DHW (DH)": 36.98,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 20.5,
    "out:Total Energy Use Pre PV": 128.5,
    "out:Total Energy Use Post PV": 128.5,
    "out:Primary Energy": 101.62,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 12.58,
    "out:CO2 Operational/m2": 41.58,
    "out:Total CO2/m2": 54.16,
    "out:Total CO2 (tons)": 278.56,
    "out:Klimatpaverkan": -50.68,
    "out:Initial Cost/MSEK": 7.55025,
    "out:Running cost/(Apt SEK y)": 39025,
    "out:Running Cost over RSP/MSEK": 12.81,
    "out:LCP/MSEK": -1.091,
    "out:ROI% old": 12.22,
    "out:Payback discounted": 10,
    "out:Atemp": 5143,
    "out:Umean": 0.42,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FTX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 128,
    "out:Energy use kWh/m2": 128,
    "out:Energy savings %": 46.9,
    "out:Op energy cost/MSEK": 94,
    "out:El price/MSEK": 57,
    "out:Heating price/MSEK": 31,
    "out:DH+ DHW/kWh/m2": 108,
    "out:El bought/kWh/m2": 1434.9,
    "out:Return %": 11,
    "out:Return/kSEK/y": 825,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 875718.530813,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 329152,
    "out:EL kWh savings": -23653,
    "out:Sewage heat recovery cost": 250448,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 259319,
    "out:DH kr savings": 299528,
    "out:El kr savings": -40210,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 366212,
    "out:% savings (space heating)": 44.39,
    "out:Total_Water Heating (DH)": 190162,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 101.23,
    "out:Etvv": 21.25,
    "out:Ef": 20.5,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000100000001110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 66.88,
    "out:DHW (DH)": 36.98,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 20.5,
    "out:Total Energy Use Pre PV": 124.5,
    "out:Total Energy Use Post PV": 124.5,
    "out:Primary Energy": 98.59,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 12.58,
    "out:CO2 Operational/m2": 40.14,
    "out:Total CO2/m2": 52.72,
    "out:Total CO2 (tons)": 271.15,
    "out:Klimatpaverkan": -58.09,
    "out:Initial Cost/MSEK": 7.67875,
    "out:Running cost/(Apt SEK y)": 37738,
    "out:Running Cost over RSP/MSEK": 12.387,
    "out:LCP/MSEK": -0.796,
    "out:ROI% old": 12.8,
    "out:Payback discounted": 9,
    "out:Atemp": 5143,
    "out:Umean": 0.42,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FTX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 124,
    "out:Energy use kWh/m2": 124,
    "out:Energy savings %": 51.6,
    "out:Op energy cost/MSEK": 90,
    "out:El price/MSEK": 56,
    "out:Heating price/MSEK": 30,
    "out:DH+ DHW/kWh/m2": 104,
    "out:El bought/kWh/m2": 1434.9,
    "out:Return %": 11,
    "out:Return/kSEK/y": 879,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 875718.530813,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 349724,
    "out:EL kWh savings": -23653,
    "out:Sewage heat recovery cost": 250448,
    "out:Balancing hydronic system cost": 128574.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 278039,
    "out:DH kr savings": 318249,
    "out:El kr savings": -40210,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 343949,
    "out:% savings (space heating)": 47.77,
    "out:Total_Water Heating (DH)": 190162,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 96.9,
    "out:Etvv": 21.25,
    "out:Ef": 20.5,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000100010000010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 71.21,
    "out:DHW (DH)": 43.5,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 14.33,
    "out:Total Energy Use Pre PV": 135.5,
    "out:Total Energy Use Post PV": 129.33,
    "out:Primary Energy": 93.14,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 24.53,
    "out:CO2 Operational/m2": 18.09,
    "out:Total CO2/m2": 42.62,
    "out:Total CO2 (tons)": 219.19,
    "out:Klimatpaverkan": -110.05,
    "out:Initial Cost/MSEK": 8.369625,
    "out:Running cost/(Apt SEK y)": 39212,
    "out:Running Cost over RSP/MSEK": 12.89,
    "out:LCP/MSEK": -1.99,
    "out:ROI% old": 10.89,
    "out:Payback discounted": 11,
    "out:Atemp": 5143,
    "out:Umean": 0.42,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FTX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 129,
    "out:Energy use kWh/m2": 129,
    "out:Energy savings %": 45.7,
    "out:Op energy cost/MSEK": 95,
    "out:El price/MSEK": 57,
    "out:Heating price/MSEK": 33,
    "out:DH+ DHW/kWh/m2": 115,
    "out:El bought/kWh/m2": 1013.1,
    "out:Return %": 10,
    "out:Return/kSEK/y": 817,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 61.265983,
    "out:PV (m2 panels)": 321,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 57484.06,
    "out:PV (kWh sold)": 25763.95,
    "out:PV (% sold (El))": 44.82,
    "out:PV (kWh self-consumed)": 31720.11,
    "out:PV (ratio sold/self-consumed)": 0.81,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 875718.530813,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 293151,
    "out:EL kWh savings": 8067,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 313331,
    "out:DH kr savings": 266767,
    "out:El kr savings": 13715,
    "out:El kr sold": 32849,
    "out:El kr saved": 53924,
    "out:El kr return": 86773,
    "out:% solar/total": 39,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 366212,
    "out:% savings (space heating)": 44.39,
    "out:Total_Water Heating (DH)": 223720,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 101.23,
    "out:Etvv": 25,
    "out:Ef": 14.33,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000100010000110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 66.88,
    "out:DHW (DH)": 43.5,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 14.33,
    "out:Total Energy Use Pre PV": 130.5,
    "out:Total Energy Use Post PV": 124.33,
    "out:Primary Energy": 90.11,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 24.53,
    "out:CO2 Operational/m2": 16.29,
    "out:Total CO2/m2": 40.82,
    "out:Total CO2 (tons)": 209.93,
    "out:Klimatpaverkan": -119.31,
    "out:Initial Cost/MSEK": 8.498125,
    "out:Running cost/(Apt SEK y)": 37602,
    "out:Running Cost over RSP/MSEK": 12.361,
    "out:LCP/MSEK": -1.59,
    "out:ROI% old": 11.61,
    "out:Payback discounted": 10,
    "out:Atemp": 5143,
    "out:Umean": 0.42,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FTX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 124,
    "out:Energy use kWh/m2": 124,
    "out:Energy savings %": 51.6,
    "out:Op energy cost/MSEK": 91,
    "out:El price/MSEK": 54,
    "out:Heating price/MSEK": 32,
    "out:DH+ DHW/kWh/m2": 110,
    "out:El bought/kWh/m2": 1013.1,
    "out:Return %": 10,
    "out:Return/kSEK/y": 885,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 61.265983,
    "out:PV (m2 panels)": 321,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 57484.06,
    "out:PV (kWh sold)": 25763.95,
    "out:PV (% sold (El))": 44.82,
    "out:PV (kWh self-consumed)": 31720.11,
    "out:PV (ratio sold/self-consumed)": 0.81,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 875718.530813,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 318866,
    "out:EL kWh savings": 8067,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 128574.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 336732,
    "out:DH kr savings": 290168,
    "out:El kr savings": 13715,
    "out:El kr sold": 32849,
    "out:El kr saved": 53924,
    "out:El kr return": 86773,
    "out:% solar/total": 39,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 343949,
    "out:% savings (space heating)": 47.77,
    "out:Total_Water Heating (DH)": 223720,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 96.9,
    "out:Etvv": 25,
    "out:Ef": 14.33,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000100010001010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 71.21,
    "out:DHW (DH)": 36.98,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 14.33,
    "out:Total Energy Use Pre PV": 128.5,
    "out:Total Energy Use Post PV": 122.33,
    "out:Primary Energy": 90.52,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 24.93,
    "out:CO2 Operational/m2": 15.57,
    "out:Total CO2/m2": 40.5,
    "out:Total CO2 (tons)": 208.3,
    "out:Klimatpaverkan": -120.94,
    "out:Initial Cost/MSEK": 8.62,
    "out:Running cost/(Apt SEK y)": 36959,
    "out:Running Cost over RSP/MSEK": 12.149,
    "out:LCP/MSEK": -1.5,
    "out:ROI% old": 11.8,
    "out:Payback discounted": 10,
    "out:Atemp": 5143,
    "out:Umean": 0.42,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FTX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 122,
    "out:Energy use kWh/m2": 122,
    "out:Energy savings %": 54.1,
    "out:Op energy cost/MSEK": 89,
    "out:El price/MSEK": 53,
    "out:Heating price/MSEK": 31,
    "out:DH+ DHW/kWh/m2": 108,
    "out:El bought/kWh/m2": 1013.1,
    "out:Return %": 11,
    "out:Return/kSEK/y": 912,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 61.265983,
    "out:PV (m2 panels)": 321,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 57484.06,
    "out:PV (kWh sold)": 25763.95,
    "out:PV (% sold (El))": 44.82,
    "out:PV (kWh self-consumed)": 31720.11,
    "out:PV (ratio sold/self-consumed)": 0.81,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 875718.530813,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 329152,
    "out:EL kWh savings": 8067,
    "out:Sewage heat recovery cost": 250448,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 346092,
    "out:DH kr savings": 299528,
    "out:El kr savings": 13715,
    "out:El kr sold": 32849,
    "out:El kr saved": 53924,
    "out:El kr return": 86773,
    "out:% solar/total": 39,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 366212,
    "out:% savings (space heating)": 44.39,
    "out:Total_Water Heating (DH)": 190162,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 101.23,
    "out:Etvv": 21.25,
    "out:Ef": 14.33,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000100010001110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 66.88,
    "out:DHW (DH)": 36.98,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 14.33,
    "out:Total Energy Use Pre PV": 124.5,
    "out:Total Energy Use Post PV": 118.33,
    "out:Primary Energy": 87.49,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 24.93,
    "out:CO2 Operational/m2": 14.13,
    "out:Total CO2/m2": 39.06,
    "out:Total CO2 (tons)": 200.89,
    "out:Klimatpaverkan": -128.35,
    "out:Initial Cost/MSEK": 8.748625,
    "out:Running cost/(Apt SEK y)": 35671,
    "out:Running Cost over RSP/MSEK": 11.726,
    "out:LCP/MSEK": -1.206,
    "out:ROI% old": 12.32,
    "out:Payback discounted": 10,
    "out:Atemp": 5143,
    "out:Umean": 0.42,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FTX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 118,
    "out:Energy use kWh/m2": 118,
    "out:Energy savings %": 59.3,
    "out:Op energy cost/MSEK": 86,
    "out:El price/MSEK": 52,
    "out:Heating price/MSEK": 30,
    "out:DH+ DHW/kWh/m2": 104,
    "out:El bought/kWh/m2": 1013.1,
    "out:Return %": 11,
    "out:Return/kSEK/y": 966,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 61.265983,
    "out:PV (m2 panels)": 321,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 57484.06,
    "out:PV (kWh sold)": 25763.95,
    "out:PV (% sold (El))": 44.82,
    "out:PV (kWh self-consumed)": 31720.11,
    "out:PV (ratio sold/self-consumed)": 0.81,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 875718.530813,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 349724,
    "out:EL kWh savings": 8067,
    "out:Sewage heat recovery cost": 250448,
    "out:Balancing hydronic system cost": 128574.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 364813,
    "out:DH kr savings": 318249,
    "out:El kr savings": 13715,
    "out:El kr sold": 32849,
    "out:El kr saved": 53924,
    "out:El kr return": 86773,
    "out:% solar/total": 39,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 343949,
    "out:% savings (space heating)": 47.77,
    "out:Total_Water Heating (DH)": 190162,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 96.9,
    "out:Etvv": 21.25,
    "out:Ef": 14.33,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000100020000010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 71.21,
    "out:DHW (DH)": 43.5,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 13.09,
    "out:Total Energy Use Pre PV": 135.5,
    "out:Total Energy Use Post PV": 128.09,
    "out:Primary Energy": 90.91,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 36.88,
    "out:CO2 Operational/m2": -32.59,
    "out:Total CO2/m2": 4.29,
    "out:Total CO2 (tons)": 22.07,
    "out:Klimatpaverkan": -307.17,
    "out:Initial Cost/MSEK": 9.439375,
    "out:Running cost/(Apt SEK y)": 37402,
    "out:Running Cost over RSP/MSEK": 12.312,
    "out:LCP/MSEK": -2.482,
    "out:ROI% old": 10.53,
    "out:Payback discounted": 11,
    "out:Atemp": 5143,
    "out:Umean": 0.42,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FTX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 128,
    "out:Energy use kWh/m2": 128,
    "out:Energy savings %": 46.9,
    "out:Op energy cost/MSEK": 91,
    "out:El price/MSEK": 53,
    "out:Heating price/MSEK": 33,
    "out:DH+ DHW/kWh/m2": 115,
    "out:El bought/kWh/m2": 923.7,
    "out:Return %": 9,
    "out:Return/kSEK/y": 893,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.33,
    "out:PV (kWp installed)": 122.531965,
    "out:PV (m2 panels)": 643,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 114968.11,
    "out:PV (kWh sold)": 76862.14,
    "out:PV (% sold (El))": 66.86,
    "out:PV (kWh self-consumed)": 38105.97,
    "out:PV (ratio sold/self-consumed)": 2.02,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 875718.530813,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 293151,
    "out:EL kWh savings": 14453,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 389337,
    "out:DH kr savings": 266767,
    "out:El kr savings": 24571,
    "out:El kr sold": 97999,
    "out:El kr saved": 64780,
    "out:El kr return": 162779,
    "out:% solar/total": 47,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 366212,
    "out:% savings (space heating)": 44.39,
    "out:Total_Water Heating (DH)": 223720,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 101.23,
    "out:Etvv": 25,
    "out:Ef": 13.09,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000100020000110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 66.88,
    "out:DHW (DH)": 43.5,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 13.09,
    "out:Total Energy Use Pre PV": 130.5,
    "out:Total Energy Use Post PV": 123.09,
    "out:Primary Energy": 87.88,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 36.88,
    "out:CO2 Operational/m2": -34.39,
    "out:Total CO2/m2": 2.49,
    "out:Total CO2 (tons)": 12.81,
    "out:Klimatpaverkan": -316.43,
    "out:Initial Cost/MSEK": 9.567875,
    "out:Running cost/(Apt SEK y)": 35793,
    "out:Running Cost over RSP/MSEK": 11.783,
    "out:LCP/MSEK": -2.082,
    "out:ROI% old": 11.18,
    "out:Payback discounted": 11,
    "out:Atemp": 5143,
    "out:Umean": 0.42,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FTX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 123,
    "out:Energy use kWh/m2": 123,
    "out:Energy savings %": 52.8,
    "out:Op energy cost/MSEK": 87,
    "out:El price/MSEK": 51,
    "out:Heating price/MSEK": 32,
    "out:DH+ DHW/kWh/m2": 110,
    "out:El bought/kWh/m2": 923.7,
    "out:Return %": 10,
    "out:Return/kSEK/y": 961,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.33,
    "out:PV (kWp installed)": 122.531965,
    "out:PV (m2 panels)": 643,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 114968.11,
    "out:PV (kWh sold)": 76862.14,
    "out:PV (% sold (El))": 66.86,
    "out:PV (kWh self-consumed)": 38105.97,
    "out:PV (ratio sold/self-consumed)": 2.02,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 875718.530813,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 318866,
    "out:EL kWh savings": 14453,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 128574.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 412738,
    "out:DH kr savings": 290168,
    "out:El kr savings": 24571,
    "out:El kr sold": 97999,
    "out:El kr saved": 64780,
    "out:El kr return": 162779,
    "out:% solar/total": 47,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 343949,
    "out:% savings (space heating)": 47.77,
    "out:Total_Water Heating (DH)": 223720,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 96.9,
    "out:Etvv": 25,
    "out:Ef": 13.09,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000100020001010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 71.21,
    "out:DHW (DH)": 36.98,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 13.09,
    "out:Total Energy Use Pre PV": 128.5,
    "out:Total Energy Use Post PV": 121.09,
    "out:Primary Energy": 88.28,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 37.28,
    "out:CO2 Operational/m2": -35.11,
    "out:Total CO2/m2": 2.17,
    "out:Total CO2 (tons)": 11.18,
    "out:Klimatpaverkan": -318.06,
    "out:Initial Cost/MSEK": 9.68975,
    "out:Running cost/(Apt SEK y)": 35149,
    "out:Running Cost over RSP/MSEK": 11.571,
    "out:LCP/MSEK": -1.992,
    "out:ROI% old": 11.35,
    "out:Payback discounted": 11,
    "out:Atemp": 5143,
    "out:Umean": 0.42,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FTX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 121,
    "out:Energy use kWh/m2": 121,
    "out:Energy savings %": 55.4,
    "out:Op energy cost/MSEK": 86,
    "out:El price/MSEK": 50,
    "out:Heating price/MSEK": 31,
    "out:DH+ DHW/kWh/m2": 108,
    "out:El bought/kWh/m2": 923.7,
    "out:Return %": 10,
    "out:Return/kSEK/y": 988,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.33,
    "out:PV (kWp installed)": 122.531965,
    "out:PV (m2 panels)": 643,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 114968.11,
    "out:PV (kWh sold)": 76862.14,
    "out:PV (% sold (El))": 66.86,
    "out:PV (kWh self-consumed)": 38105.97,
    "out:PV (ratio sold/self-consumed)": 2.02,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 875718.530813,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 329152,
    "out:EL kWh savings": 14453,
    "out:Sewage heat recovery cost": 250448,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 422098,
    "out:DH kr savings": 299528,
    "out:El kr savings": 24571,
    "out:El kr sold": 97999,
    "out:El kr saved": 64780,
    "out:El kr return": 162779,
    "out:% solar/total": 47,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 366212,
    "out:% savings (space heating)": 44.39,
    "out:Total_Water Heating (DH)": 190162,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 101.23,
    "out:Etvv": 21.25,
    "out:Ef": 13.09,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000100020001110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 66.88,
    "out:DHW (DH)": 36.98,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 13.09,
    "out:Total Energy Use Pre PV": 124.5,
    "out:Total Energy Use Post PV": 117.09,
    "out:Primary Energy": 85.25,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 37.28,
    "out:CO2 Operational/m2": -36.55,
    "out:Total CO2/m2": 0.73,
    "out:Total CO2 (tons)": 3.77,
    "out:Klimatpaverkan": -325.47,
    "out:Initial Cost/MSEK": 9.818375,
    "out:Running cost/(Apt SEK y)": 33862,
    "out:Running Cost over RSP/MSEK": 11.148,
    "out:LCP/MSEK": -1.698,
    "out:ROI% old": 11.82,
    "out:Payback discounted": 10,
    "out:Atemp": 5143,
    "out:Umean": 0.42,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FTX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 117,
    "out:Energy use kWh/m2": 117,
    "out:Energy savings %": 60.7,
    "out:Op energy cost/MSEK": 83,
    "out:El price/MSEK": 48,
    "out:Heating price/MSEK": 30,
    "out:DH+ DHW/kWh/m2": 104,
    "out:El bought/kWh/m2": 923.7,
    "out:Return %": 11,
    "out:Return/kSEK/y": 1042,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.33,
    "out:PV (kWp installed)": 122.531965,
    "out:PV (m2 panels)": 643,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 114968.11,
    "out:PV (kWh sold)": 76862.14,
    "out:PV (% sold (El))": 66.86,
    "out:PV (kWh self-consumed)": 38105.97,
    "out:PV (ratio sold/self-consumed)": 2.02,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 875718.530813,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 349724,
    "out:EL kWh savings": 14453,
    "out:Sewage heat recovery cost": 250448,
    "out:Balancing hydronic system cost": 128574.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 440819,
    "out:DH kr savings": 318249,
    "out:El kr savings": 24571,
    "out:El kr sold": 97999,
    "out:El kr saved": 64780,
    "out:El kr return": 162779,
    "out:% solar/total": 47,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 343949,
    "out:% savings (space heating)": 47.77,
    "out:Total_Water Heating (DH)": 190162,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 96.9,
    "out:Etvv": 21.25,
    "out:Ef": 13.09,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000100100000010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 71.21,
    "out:DHW (DH)": 34.8,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 20.5,
    "out:Total Energy Use Pre PV": 126.5,
    "out:Total Energy Use Post PV": 126.5,
    "out:Primary Energy": 100.74,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 12.18,
    "out:CO2 Operational/m2": 40.86,
    "out:Total CO2/m2": 53.04,
    "out:Total CO2 (tons)": 272.79,
    "out:Klimatpaverkan": -56.45,
    "out:Initial Cost/MSEK": 7.410625,
    "out:Running cost/(Apt SEK y)": 38381,
    "out:Running Cost over RSP/MSEK": 12.598,
    "out:LCP/MSEK": -0.74,
    "out:ROI% old": 12.86,
    "out:Payback discounted": 9,
    "out:Atemp": 5143,
    "out:Umean": 0.42,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FTX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 126,
    "out:Energy use kWh/m2": 126,
    "out:Energy savings %": 49.2,
    "out:Op energy cost/MSEK": 92,
    "out:El price/MSEK": 57,
    "out:Heating price/MSEK": 30,
    "out:DH+ DHW/kWh/m2": 106,
    "out:El bought/kWh/m2": 1434.9,
    "out:Return %": 11,
    "out:Return/kSEK/y": 852,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 875718.530813,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 339438,
    "out:EL kWh savings": -23653,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 268679,
    "out:DH kr savings": 308889,
    "out:El kr savings": -40210,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 366212,
    "out:% savings (space heating)": 44.39,
    "out:Total_Water Heating (DH)": 178976,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 101.23,
    "out:Etvv": 20,
    "out:Ef": 20.5,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000100100000110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 66.88,
    "out:DHW (DH)": 34.8,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 20.5,
    "out:Total Energy Use Pre PV": 122.5,
    "out:Total Energy Use Post PV": 122.5,
    "out:Primary Energy": 97.71,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 12.18,
    "out:CO2 Operational/m2": 39.42,
    "out:Total CO2/m2": 51.6,
    "out:Total CO2 (tons)": 265.38,
    "out:Klimatpaverkan": -63.86,
    "out:Initial Cost/MSEK": 7.53925,
    "out:Running cost/(Apt SEK y)": 37094,
    "out:Running Cost over RSP/MSEK": 12.175,
    "out:LCP/MSEK": -0.445,
    "out:ROI% old": 13.44,
    "out:Payback discounted": 9,
    "out:Atemp": 5143,
    "out:Umean": 0.42,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FTX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 122,
    "out:Energy use kWh/m2": 122,
    "out:Energy savings %": 54.1,
    "out:Op energy cost/MSEK": 89,
    "out:El price/MSEK": 55,
    "out:Heating price/MSEK": 29,
    "out:DH+ DHW/kWh/m2": 102,
    "out:El bought/kWh/m2": 1434.9,
    "out:Return %": 12,
    "out:Return/kSEK/y": 906,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 875718.530813,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 360010,
    "out:EL kWh savings": -23653,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 128574.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 287400,
    "out:DH kr savings": 327609,
    "out:El kr savings": -40210,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 343949,
    "out:% savings (space heating)": 47.77,
    "out:Total_Water Heating (DH)": 178976,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 96.9,
    "out:Etvv": 20,
    "out:Ef": 20.5,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000100100001010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 71.21,
    "out:DHW (DH)": 29.58,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 20.5,
    "out:Total Energy Use Pre PV": 121.5,
    "out:Total Energy Use Post PV": 121.5,
    "out:Primary Energy": 98.64,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 12.58,
    "out:CO2 Operational/m2": 39.06,
    "out:Total CO2/m2": 51.64,
    "out:Total CO2 (tons)": 265.6,
    "out:Klimatpaverkan": -63.64,
    "out:Initial Cost/MSEK": 7.661125,
    "out:Running cost/(Apt SEK y)": 36772,
    "out:Running Cost over RSP/MSEK": 12.069,
    "out:LCP/MSEK": -0.462,
    "out:ROI% old": 13.43,
    "out:Payback discounted": 9,
    "out:Atemp": 5143,
    "out:Umean": 0.42,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FTX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 121,
    "out:Energy use kWh/m2": 121,
    "out:Energy savings %": 55.4,
    "out:Op energy cost/MSEK": 88,
    "out:El price/MSEK": 54,
    "out:Heating price/MSEK": 29,
    "out:DH+ DHW/kWh/m2": 101,
    "out:El bought/kWh/m2": 1434.9,
    "out:Return %": 12,
    "out:Return/kSEK/y": 919,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 875718.530813,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 365153,
    "out:EL kWh savings": -23653,
    "out:Sewage heat recovery cost": 250448,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 292080,
    "out:DH kr savings": 332289,
    "out:El kr savings": -40210,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 366212,
    "out:% savings (space heating)": 44.39,
    "out:Total_Water Heating (DH)": 152130,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 101.23,
    "out:Etvv": 17,
    "out:Ef": 20.5,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000100100001110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 66.88,
    "out:DHW (DH)": 29.58,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 20.5,
    "out:Total Energy Use Pre PV": 116.5,
    "out:Total Energy Use Post PV": 116.5,
    "out:Primary Energy": 95.61,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 12.58,
    "out:CO2 Operational/m2": 37.26,
    "out:Total CO2/m2": 49.84,
    "out:Total CO2 (tons)": 256.34,
    "out:Klimatpaverkan": -72.9,
    "out:Initial Cost/MSEK": 7.789625,
    "out:Running cost/(Apt SEK y)": 35163,
    "out:Running Cost over RSP/MSEK": 11.541,
    "out:LCP/MSEK": -0.061,
    "out:ROI% old": 14.17,
    "out:Payback discounted": 9,
    "out:Atemp": 5143,
    "out:Umean": 0.42,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FTX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 116,
    "out:Energy use kWh/m2": 116,
    "out:Energy savings %": 62.1,
    "out:Op energy cost/MSEK": 84,
    "out:El price/MSEK": 52,
    "out:Heating price/MSEK": 27,
    "out:DH+ DHW/kWh/m2": 96,
    "out:El bought/kWh/m2": 1434.9,
    "out:Return %": 13,
    "out:Return/kSEK/y": 987,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 875718.530813,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 390868,
    "out:EL kWh savings": -23653,
    "out:Sewage heat recovery cost": 250448,
    "out:Balancing hydronic system cost": 128574.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 315480,
    "out:DH kr savings": 355690,
    "out:El kr savings": -40210,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 343949,
    "out:% savings (space heating)": 47.77,
    "out:Total_Water Heating (DH)": 152130,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 96.9,
    "out:Etvv": 17,
    "out:Ef": 20.5,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000100110000010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 71.21,
    "out:DHW (DH)": 34.8,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 14.33,
    "out:Total Energy Use Pre PV": 126.5,
    "out:Total Energy Use Post PV": 120.33,
    "out:Primary Energy": 89.64,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 24.53,
    "out:CO2 Operational/m2": 14.85,
    "out:Total CO2/m2": 39.38,
    "out:Total CO2 (tons)": 202.53,
    "out:Klimatpaverkan": -126.71,
    "out:Initial Cost/MSEK": 8.480375,
    "out:Running cost/(Apt SEK y)": 36315,
    "out:Running Cost over RSP/MSEK": 11.938,
    "out:LCP/MSEK": -1.149,
    "out:ROI% old": 12.35,
    "out:Payback discounted": 10,
    "out:Atemp": 5143,
    "out:Umean": 0.42,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FTX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 120,
    "out:Energy use kWh/m2": 120,
    "out:Energy savings %": 56.7,
    "out:Op energy cost/MSEK": 88,
    "out:El price/MSEK": 52,
    "out:Heating price/MSEK": 30,
    "out:DH+ DHW/kWh/m2": 106,
    "out:El bought/kWh/m2": 1013.1,
    "out:Return %": 11,
    "out:Return/kSEK/y": 939,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 61.265983,
    "out:PV (m2 panels)": 321,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 57484.06,
    "out:PV (kWh sold)": 25763.95,
    "out:PV (% sold (El))": 44.82,
    "out:PV (kWh self-consumed)": 31720.11,
    "out:PV (ratio sold/self-consumed)": 0.81,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 875718.530813,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 339438,
    "out:EL kWh savings": 8067,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 355452,
    "out:DH kr savings": 308889,
    "out:El kr savings": 13715,
    "out:El kr sold": 32849,
    "out:El kr saved": 53924,
    "out:El kr return": 86773,
    "out:% solar/total": 39,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 366212,
    "out:% savings (space heating)": 44.39,
    "out:Total_Water Heating (DH)": 178976,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 101.23,
    "out:Etvv": 20,
    "out:Ef": 14.33,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000100110000110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 66.88,
    "out:DHW (DH)": 34.8,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 14.33,
    "out:Total Energy Use Pre PV": 122.5,
    "out:Total Energy Use Post PV": 116.33,
    "out:Primary Energy": 86.61,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 24.53,
    "out:CO2 Operational/m2": 13.41,
    "out:Total CO2/m2": 37.94,
    "out:Total CO2 (tons)": 195.12,
    "out:Klimatpaverkan": -134.12,
    "out:Initial Cost/MSEK": 8.609,
    "out:Running cost/(Apt SEK y)": 35028,
    "out:Running Cost over RSP/MSEK": 11.515,
    "out:LCP/MSEK": -0.855,
    "out:ROI% old": 12.87,
    "out:Payback discounted": 9,
    "out:Atemp": 5143,
    "out:Umean": 0.42,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FTX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 116,
    "out:Energy use kWh/m2": 116,
    "out:Energy savings %": 62.1,
    "out:Op energy cost/MSEK": 85,
    "out:El price/MSEK": 51,
    "out:Heating price/MSEK": 29,
    "out:DH+ DHW/kWh/m2": 102,
    "out:El bought/kWh/m2": 1013.1,
    "out:Return %": 12,
    "out:Return/kSEK/y": 993,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 61.265983,
    "out:PV (m2 panels)": 321,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 57484.06,
    "out:PV (kWh sold)": 25763.95,
    "out:PV (% sold (El))": 44.82,
    "out:PV (kWh self-consumed)": 31720.11,
    "out:PV (ratio sold/self-consumed)": 0.81,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 875718.530813,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 360010,
    "out:EL kWh savings": 8067,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 128574.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 374173,
    "out:DH kr savings": 327609,
    "out:El kr savings": 13715,
    "out:El kr sold": 32849,
    "out:El kr saved": 53924,
    "out:El kr return": 86773,
    "out:% solar/total": 39,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 343949,
    "out:% savings (space heating)": 47.77,
    "out:Total_Water Heating (DH)": 178976,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 96.9,
    "out:Etvv": 20,
    "out:Ef": 14.33,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000100110001010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 71.21,
    "out:DHW (DH)": 29.58,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 14.33,
    "out:Total Energy Use Pre PV": 121.5,
    "out:Total Energy Use Post PV": 115.33,
    "out:Primary Energy": 87.54,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 24.93,
    "out:CO2 Operational/m2": 13.05,
    "out:Total CO2/m2": 37.98,
    "out:Total CO2 (tons)": 195.34,
    "out:Klimatpaverkan": -133.9,
    "out:Initial Cost/MSEK": 8.730875,
    "out:Running cost/(Apt SEK y)": 34706,
    "out:Running Cost over RSP/MSEK": 11.409,
    "out:LCP/MSEK": -0.871,
    "out:ROI% old": 12.86,
    "out:Payback discounted": 9,
    "out:Atemp": 5143,
    "out:Umean": 0.42,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FTX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 115,
    "out:Energy use kWh/m2": 115,
    "out:Energy savings %": 63.5,
    "out:Op energy cost/MSEK": 84,
    "out:El price/MSEK": 50,
    "out:Heating price/MSEK": 29,
    "out:DH+ DHW/kWh/m2": 101,
    "out:El bought/kWh/m2": 1013.1,
    "out:Return %": 12,
    "out:Return/kSEK/y": 1006,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 61.265983,
    "out:PV (m2 panels)": 321,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 57484.06,
    "out:PV (kWh sold)": 25763.95,
    "out:PV (% sold (El))": 44.82,
    "out:PV (kWh self-consumed)": 31720.11,
    "out:PV (ratio sold/self-consumed)": 0.81,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 875718.530813,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 365153,
    "out:EL kWh savings": 8067,
    "out:Sewage heat recovery cost": 250448,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 378853,
    "out:DH kr savings": 332289,
    "out:El kr savings": 13715,
    "out:El kr sold": 32849,
    "out:El kr saved": 53924,
    "out:El kr return": 86773,
    "out:% solar/total": 39,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 366212,
    "out:% savings (space heating)": 44.39,
    "out:Total_Water Heating (DH)": 152130,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 101.23,
    "out:Etvv": 17,
    "out:Ef": 14.33,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000100110001110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 66.88,
    "out:DHW (DH)": 29.58,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 14.33,
    "out:Total Energy Use Pre PV": 116.5,
    "out:Total Energy Use Post PV": 110.33,
    "out:Primary Energy": 84.51,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 24.93,
    "out:CO2 Operational/m2": 11.25,
    "out:Total CO2/m2": 36.18,
    "out:Total CO2 (tons)": 186.08,
    "out:Klimatpaverkan": -143.16,
    "out:Initial Cost/MSEK": 8.8595,
    "out:Running cost/(Apt SEK y)": 33097,
    "out:Running Cost over RSP/MSEK": 10.88,
    "out:LCP/MSEK": -0.47,
    "out:ROI% old": 13.53,
    "out:Payback discounted": 9,
    "out:Atemp": 5143,
    "out:Umean": 0.42,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FTX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 110,
    "out:Energy use kWh/m2": 110,
    "out:Energy savings %": 70.9,
    "out:Op energy cost/MSEK": 80,
    "out:El price/MSEK": 48,
    "out:Heating price/MSEK": 27,
    "out:DH+ DHW/kWh/m2": 96,
    "out:El bought/kWh/m2": 1013.1,
    "out:Return %": 12,
    "out:Return/kSEK/y": 1074,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 61.265983,
    "out:PV (m2 panels)": 321,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 57484.06,
    "out:PV (kWh sold)": 25763.95,
    "out:PV (% sold (El))": 44.82,
    "out:PV (kWh self-consumed)": 31720.11,
    "out:PV (ratio sold/self-consumed)": 0.81,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 875718.530813,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 390868,
    "out:EL kWh savings": 8067,
    "out:Sewage heat recovery cost": 250448,
    "out:Balancing hydronic system cost": 128574.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 402254,
    "out:DH kr savings": 355690,
    "out:El kr savings": 13715,
    "out:El kr sold": 32849,
    "out:El kr saved": 53924,
    "out:El kr return": 86773,
    "out:% solar/total": 39,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 343949,
    "out:% savings (space heating)": 47.77,
    "out:Total_Water Heating (DH)": 152130,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 96.9,
    "out:Etvv": 17,
    "out:Ef": 14.33,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000100120000010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 71.21,
    "out:DHW (DH)": 34.8,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 13.09,
    "out:Total Energy Use Pre PV": 126.5,
    "out:Total Energy Use Post PV": 119.09,
    "out:Primary Energy": 87.41,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 36.88,
    "out:CO2 Operational/m2": -35.83,
    "out:Total CO2/m2": 1.05,
    "out:Total CO2 (tons)": 5.41,
    "out:Klimatpaverkan": -323.83,
    "out:Initial Cost/MSEK": 9.55025,
    "out:Running cost/(Apt SEK y)": 34505,
    "out:Running Cost over RSP/MSEK": 11.36,
    "out:LCP/MSEK": -1.641,
    "out:ROI% old": 11.83,
    "out:Payback discounted": 10,
    "out:Atemp": 5143,
    "out:Umean": 0.42,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FTX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 119,
    "out:Energy use kWh/m2": 119,
    "out:Energy savings %": 58,
    "out:Op energy cost/MSEK": 84,
    "out:El price/MSEK": 49,
    "out:Heating price/MSEK": 30,
    "out:DH+ DHW/kWh/m2": 106,
    "out:El bought/kWh/m2": 923.7,
    "out:Return %": 11,
    "out:Return/kSEK/y": 1015,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.33,
    "out:PV (kWp installed)": 122.531965,
    "out:PV (m2 panels)": 643,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 114968.11,
    "out:PV (kWh sold)": 76862.14,
    "out:PV (% sold (El))": 66.86,
    "out:PV (kWh self-consumed)": 38105.97,
    "out:PV (ratio sold/self-consumed)": 2.02,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 875718.530813,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 339438,
    "out:EL kWh savings": 14453,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 431458,
    "out:DH kr savings": 308889,
    "out:El kr savings": 24571,
    "out:El kr sold": 97999,
    "out:El kr saved": 64780,
    "out:El kr return": 162779,
    "out:% solar/total": 47,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 366212,
    "out:% savings (space heating)": 44.39,
    "out:Total_Water Heating (DH)": 178976,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 101.23,
    "out:Etvv": 20,
    "out:Ef": 13.09,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000100120000110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 66.88,
    "out:DHW (DH)": 34.8,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 13.09,
    "out:Total Energy Use Pre PV": 122.5,
    "out:Total Energy Use Post PV": 115.09,
    "out:Primary Energy": 84.38,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 36.88,
    "out:CO2 Operational/m2": -37.27,
    "out:Total CO2/m2": -0.39,
    "out:Total CO2 (tons)": -2,
    "out:Klimatpaverkan": -331.24,
    "out:Initial Cost/MSEK": 9.67875,
    "out:Running cost/(Apt SEK y)": 33218,
    "out:Running Cost over RSP/MSEK": 10.937,
    "out:LCP/MSEK": -1.346,
    "out:ROI% old": 12.3,
    "out:Payback discounted": 10,
    "out:Atemp": 5143,
    "out:Umean": 0.42,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FTX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 115,
    "out:Energy use kWh/m2": 115,
    "out:Energy savings %": 63.5,
    "out:Op energy cost/MSEK": 81,
    "out:El price/MSEK": 47,
    "out:Heating price/MSEK": 29,
    "out:DH+ DHW/kWh/m2": 102,
    "out:El bought/kWh/m2": 923.7,
    "out:Return %": 11,
    "out:Return/kSEK/y": 1069,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.33,
    "out:PV (kWp installed)": 122.531965,
    "out:PV (m2 panels)": 643,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 114968.11,
    "out:PV (kWh sold)": 76862.14,
    "out:PV (% sold (El))": 66.86,
    "out:PV (kWh self-consumed)": 38105.97,
    "out:PV (ratio sold/self-consumed)": 2.02,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 875718.530813,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 360010,
    "out:EL kWh savings": 14453,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 128574.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 450179,
    "out:DH kr savings": 327609,
    "out:El kr savings": 24571,
    "out:El kr sold": 97999,
    "out:El kr saved": 64780,
    "out:El kr return": 162779,
    "out:% solar/total": 47,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 343949,
    "out:% savings (space heating)": 47.77,
    "out:Total_Water Heating (DH)": 178976,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 96.9,
    "out:Etvv": 20,
    "out:Ef": 13.09,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000100120001010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 71.21,
    "out:DHW (DH)": 29.58,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 13.09,
    "out:Total Energy Use Pre PV": 121.5,
    "out:Total Energy Use Post PV": 114.09,
    "out:Primary Energy": 85.31,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 37.28,
    "out:CO2 Operational/m2": -37.63,
    "out:Total CO2/m2": -0.35,
    "out:Total CO2 (tons)": -1.78,
    "out:Klimatpaverkan": -331.02,
    "out:Initial Cost/MSEK": 9.800625,
    "out:Running cost/(Apt SEK y)": 32896,
    "out:Running Cost over RSP/MSEK": 10.831,
    "out:LCP/MSEK": -1.362,
    "out:ROI% old": 12.3,
    "out:Payback discounted": 10,
    "out:Atemp": 5143,
    "out:Umean": 0.42,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FTX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 114,
    "out:Energy use kWh/m2": 114,
    "out:Energy savings %": 64.9,
    "out:Op energy cost/MSEK": 80,
    "out:El price/MSEK": 47,
    "out:Heating price/MSEK": 29,
    "out:DH+ DHW/kWh/m2": 101,
    "out:El bought/kWh/m2": 923.7,
    "out:Return %": 11,
    "out:Return/kSEK/y": 1082,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.33,
    "out:PV (kWp installed)": 122.531965,
    "out:PV (m2 panels)": 643,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 114968.11,
    "out:PV (kWh sold)": 76862.14,
    "out:PV (% sold (El))": 66.86,
    "out:PV (kWh self-consumed)": 38105.97,
    "out:PV (ratio sold/self-consumed)": 2.02,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 875718.530813,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 365153,
    "out:EL kWh savings": 14453,
    "out:Sewage heat recovery cost": 250448,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 454859,
    "out:DH kr savings": 332289,
    "out:El kr savings": 24571,
    "out:El kr sold": 97999,
    "out:El kr saved": 64780,
    "out:El kr return": 162779,
    "out:% solar/total": 47,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 366212,
    "out:% savings (space heating)": 44.39,
    "out:Total_Water Heating (DH)": 152130,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 101.23,
    "out:Etvv": 17,
    "out:Ef": 13.09,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000100120001110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 66.88,
    "out:DHW (DH)": 29.58,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 13.09,
    "out:Total Energy Use Pre PV": 116.5,
    "out:Total Energy Use Post PV": 109.09,
    "out:Primary Energy": 82.28,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 37.28,
    "out:CO2 Operational/m2": -39.43,
    "out:Total CO2/m2": -2.15,
    "out:Total CO2 (tons)": -11.04,
    "out:Klimatpaverkan": -340.28,
    "out:Initial Cost/MSEK": 9.92925,
    "out:Running cost/(Apt SEK y)": 31287,
    "out:Running Cost over RSP/MSEK": 10.302,
    "out:LCP/MSEK": -0.962,
    "out:ROI% old": 12.9,
    "out:Payback discounted": 9,
    "out:Atemp": 5143,
    "out:Umean": 0.42,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FTX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 109,
    "out:Energy use kWh/m2": 109,
    "out:Energy savings %": 72.5,
    "out:Op energy cost/MSEK": 77,
    "out:El price/MSEK": 44,
    "out:Heating price/MSEK": 27,
    "out:DH+ DHW/kWh/m2": 96,
    "out:El bought/kWh/m2": 923.7,
    "out:Return %": 12,
    "out:Return/kSEK/y": 1150,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.33,
    "out:PV (kWp installed)": 122.531965,
    "out:PV (m2 panels)": 643,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 114968.11,
    "out:PV (kWh sold)": 76862.14,
    "out:PV (% sold (El))": 66.86,
    "out:PV (kWh self-consumed)": 38105.97,
    "out:PV (ratio sold/self-consumed)": 2.02,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 875718.530813,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 390868,
    "out:EL kWh savings": 14453,
    "out:Sewage heat recovery cost": 250448,
    "out:Balancing hydronic system cost": 128574.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 478260,
    "out:DH kr savings": 355690,
    "out:El kr savings": 24571,
    "out:El kr sold": 97999,
    "out:El kr saved": 64780,
    "out:El kr return": 162779,
    "out:% solar/total": 47,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 343949,
    "out:% savings (space heating)": 47.77,
    "out:Total_Water Heating (DH)": 152130,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 96.9,
    "out:Etvv": 17,
    "out:Ef": 13.09,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000200000000010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 67.8,
    "out:DHW (DH)": 43.5,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 20.5,
    "out:Total Energy Use Pre PV": 131.5,
    "out:Total Energy Use Post PV": 131.5,
    "out:Primary Energy": 101.86,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 18.14,
    "out:CO2 Operational/m2": 42.66,
    "out:Total CO2/m2": 60.8,
    "out:Total CO2 (tons)": 312.71,
    "out:Klimatpaverkan": -16.53,
    "out:Initial Cost/MSEK": 9.31125,
    "out:Running cost/(Apt SEK y)": 39990,
    "out:Running Cost over RSP/MSEK": 13.127,
    "out:LCP/MSEK": -3.169,
    "out:ROI% old": 9.42,
    "out:Payback discounted": 13,
    "out:Atemp": 5143,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FTX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 131,
    "out:Energy use kWh/m2": 131,
    "out:Energy savings %": 43.5,
    "out:Op energy cost/MSEK": 96,
    "out:El price/MSEK": 59,
    "out:Heating price/MSEK": 32,
    "out:DH+ DHW/kWh/m2": 111,
    "out:El bought/kWh/m2": 1434.9,
    "out:Return %": 8,
    "out:Return/kSEK/y": 784,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 875718.530813,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 313723,
    "out:EL kWh savings": -23653,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 245278,
    "out:DH kr savings": 285488,
    "out:El kr savings": -40210,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 348709,
    "out:% savings (space heating)": 47.05,
    "out:Total_Water Heating (DH)": 223720,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 97.83,
    "out:Etvv": 25,
    "out:Ef": 20.5,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000200000000110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 63.63,
    "out:DHW (DH)": 43.5,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 20.5,
    "out:Total Energy Use Pre PV": 127.5,
    "out:Total Energy Use Post PV": 127.5,
    "out:Primary Energy": 98.94,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 18.14,
    "out:CO2 Operational/m2": 41.22,
    "out:Total CO2/m2": 59.36,
    "out:Total CO2 (tons)": 305.31,
    "out:Klimatpaverkan": -23.93,
    "out:Initial Cost/MSEK": 9.439875,
    "out:Running cost/(Apt SEK y)": 38703,
    "out:Running Cost over RSP/MSEK": 12.704,
    "out:LCP/MSEK": -2.875,
    "out:ROI% old": 9.93,
    "out:Payback discounted": 12,
    "out:Atemp": 5143,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FTX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 127,
    "out:Energy use kWh/m2": 127,
    "out:Energy savings %": 48,
    "out:Op energy cost/MSEK": 93,
    "out:El price/MSEK": 57,
    "out:Heating price/MSEK": 31,
    "out:DH+ DHW/kWh/m2": 107,
    "out:El bought/kWh/m2": 1434.9,
    "out:Return %": 9,
    "out:Return/kSEK/y": 838,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 875718.530813,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 334295,
    "out:EL kWh savings": -23653,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 128574.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 263999,
    "out:DH kr savings": 304208,
    "out:El kr savings": -40210,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 327242,
    "out:% savings (space heating)": 50.31,
    "out:Total_Water Heating (DH)": 223720,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 93.66,
    "out:Etvv": 25,
    "out:Ef": 20.5,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000200000001010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 67.8,
    "out:DHW (DH)": 36.98,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 20.5,
    "out:Total Energy Use Pre PV": 125.5,
    "out:Total Energy Use Post PV": 125.5,
    "out:Primary Energy": 99.24,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 18.54,
    "out:CO2 Operational/m2": 40.5,
    "out:Total CO2/m2": 59.05,
    "out:Total CO2 (tons)": 303.67,
    "out:Klimatpaverkan": -25.57,
    "out:Initial Cost/MSEK": 9.56175,
    "out:Running cost/(Apt SEK y)": 38059,
    "out:Running Cost over RSP/MSEK": 12.493,
    "out:LCP/MSEK": -2.785,
    "out:ROI% old": 10.12,
    "out:Payback discounted": 12,
    "out:Atemp": 5143,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FTX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 125,
    "out:Energy use kWh/m2": 125,
    "out:Energy savings %": 50.4,
    "out:Op energy cost/MSEK": 91,
    "out:El price/MSEK": 56,
    "out:Heating price/MSEK": 30,
    "out:DH+ DHW/kWh/m2": 105,
    "out:El bought/kWh/m2": 1434.9,
    "out:Return %": 9,
    "out:Return/kSEK/y": 865,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 875718.530813,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 344581,
    "out:EL kWh savings": -23653,
    "out:Sewage heat recovery cost": 250448,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 273359,
    "out:DH kr savings": 313569,
    "out:El kr savings": -40210,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 348709,
    "out:% savings (space heating)": 47.05,
    "out:Total_Water Heating (DH)": 190162,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 97.83,
    "out:Etvv": 21.25,
    "out:Ef": 20.5,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000200000001110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 63.63,
    "out:DHW (DH)": 36.98,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 20.5,
    "out:Total Energy Use Pre PV": 121.5,
    "out:Total Energy Use Post PV": 121.5,
    "out:Primary Energy": 96.31,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 18.54,
    "out:CO2 Operational/m2": 39.06,
    "out:Total CO2/m2": 57.61,
    "out:Total CO2 (tons)": 296.27,
    "out:Klimatpaverkan": -32.97,
    "out:Initial Cost/MSEK": 9.69025,
    "out:Running cost/(Apt SEK y)": 36772,
    "out:Running Cost over RSP/MSEK": 12.069,
    "out:LCP/MSEK": -2.491,
    "out:ROI% old": 10.61,
    "out:Payback discounted": 11,
    "out:Atemp": 5143,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FTX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 121,
    "out:Energy use kWh/m2": 121,
    "out:Energy savings %": 55.4,
    "out:Op energy cost/MSEK": 88,
    "out:El price/MSEK": 54,
    "out:Heating price/MSEK": 29,
    "out:DH+ DHW/kWh/m2": 101,
    "out:El bought/kWh/m2": 1434.9,
    "out:Return %": 9,
    "out:Return/kSEK/y": 919,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 875718.530813,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 365153,
    "out:EL kWh savings": -23653,
    "out:Sewage heat recovery cost": 250448,
    "out:Balancing hydronic system cost": 128574.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 292080,
    "out:DH kr savings": 332289,
    "out:El kr savings": -40210,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 327242,
    "out:% savings (space heating)": 50.31,
    "out:Total_Water Heating (DH)": 190162,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 93.66,
    "out:Etvv": 21.25,
    "out:Ef": 20.5,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000200010000010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 67.8,
    "out:DHW (DH)": 43.5,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 14.33,
    "out:Total Energy Use Pre PV": 131.5,
    "out:Total Energy Use Post PV": 125.33,
    "out:Primary Energy": 90.76,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 30.49,
    "out:CO2 Operational/m2": 16.65,
    "out:Total CO2/m2": 47.14,
    "out:Total CO2 (tons)": 242.45,
    "out:Klimatpaverkan": -86.79,
    "out:Initial Cost/MSEK": 10.381125,
    "out:Running cost/(Apt SEK y)": 37924,
    "out:Running Cost over RSP/MSEK": 12.467,
    "out:LCP/MSEK": -3.579,
    "out:ROI% old": 9.36,
    "out:Payback discounted": 13,
    "out:Atemp": 5143,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FTX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 125,
    "out:Energy use kWh/m2": 125,
    "out:Energy savings %": 50.4,
    "out:Op energy cost/MSEK": 92,
    "out:El price/MSEK": 55,
    "out:Heating price/MSEK": 32,
    "out:DH+ DHW/kWh/m2": 111,
    "out:El bought/kWh/m2": 1013.1,
    "out:Return %": 8,
    "out:Return/kSEK/y": 871,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 61.265983,
    "out:PV (m2 panels)": 321,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 57484.06,
    "out:PV (kWh sold)": 25763.95,
    "out:PV (% sold (El))": 44.82,
    "out:PV (kWh self-consumed)": 31720.11,
    "out:PV (ratio sold/self-consumed)": 0.81,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 875718.530813,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 313723,
    "out:EL kWh savings": 8067,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 332052,
    "out:DH kr savings": 285488,
    "out:El kr savings": 13715,
    "out:El kr sold": 32849,
    "out:El kr saved": 53924,
    "out:El kr return": 86773,
    "out:% solar/total": 39,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 348709,
    "out:% savings (space heating)": 47.05,
    "out:Total_Water Heating (DH)": 223720,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 97.83,
    "out:Etvv": 25,
    "out:Ef": 14.33,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000200010000110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 63.63,
    "out:DHW (DH)": 43.5,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 14.33,
    "out:Total Energy Use Pre PV": 127.5,
    "out:Total Energy Use Post PV": 121.33,
    "out:Primary Energy": 87.84,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 30.49,
    "out:CO2 Operational/m2": 15.21,
    "out:Total CO2/m2": 45.7,
    "out:Total CO2 (tons)": 235.05,
    "out:Klimatpaverkan": -94.19,
    "out:Initial Cost/MSEK": 10.509625,
    "out:Running cost/(Apt SEK y)": 36637,
    "out:Running Cost over RSP/MSEK": 12.044,
    "out:LCP/MSEK": -3.284,
    "out:ROI% old": 9.82,
    "out:Payback discounted": 12,
    "out:Atemp": 5143,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FTX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 121,
    "out:Energy use kWh/m2": 121,
    "out:Energy savings %": 55.4,
    "out:Op energy cost/MSEK": 89,
    "out:El price/MSEK": 53,
    "out:Heating price/MSEK": 31,
    "out:DH+ DHW/kWh/m2": 107,
    "out:El bought/kWh/m2": 1013.1,
    "out:Return %": 9,
    "out:Return/kSEK/y": 925,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 61.265983,
    "out:PV (m2 panels)": 321,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 57484.06,
    "out:PV (kWh sold)": 25763.95,
    "out:PV (% sold (El))": 44.82,
    "out:PV (kWh self-consumed)": 31720.11,
    "out:PV (ratio sold/self-consumed)": 0.81,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 875718.530813,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 334295,
    "out:EL kWh savings": 8067,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 128574.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 350772,
    "out:DH kr savings": 304208,
    "out:El kr savings": 13715,
    "out:El kr sold": 32849,
    "out:El kr saved": 53924,
    "out:El kr return": 86773,
    "out:% solar/total": 39,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 327242,
    "out:% savings (space heating)": 50.31,
    "out:Total_Water Heating (DH)": 223720,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 93.66,
    "out:Etvv": 25,
    "out:Ef": 14.33,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000200010001010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 67.8,
    "out:DHW (DH)": 36.98,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 14.33,
    "out:Total Energy Use Pre PV": 125.5,
    "out:Total Energy Use Post PV": 119.33,
    "out:Primary Energy": 88.13,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 30.9,
    "out:CO2 Operational/m2": 14.49,
    "out:Total CO2/m2": 45.38,
    "out:Total CO2 (tons)": 233.41,
    "out:Klimatpaverkan": -95.83,
    "out:Initial Cost/MSEK": 10.6315,
    "out:Running cost/(Apt SEK y)": 35993,
    "out:Running Cost over RSP/MSEK": 11.832,
    "out:LCP/MSEK": -3.195,
    "out:ROI% old": 9.99,
    "out:Payback discounted": 12,
    "out:Atemp": 5143,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FTX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 119,
    "out:Energy use kWh/m2": 119,
    "out:Energy savings %": 58,
    "out:Op energy cost/MSEK": 87,
    "out:El price/MSEK": 52,
    "out:Heating price/MSEK": 30,
    "out:DH+ DHW/kWh/m2": 105,
    "out:El bought/kWh/m2": 1013.1,
    "out:Return %": 9,
    "out:Return/kSEK/y": 952,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 61.265983,
    "out:PV (m2 panels)": 321,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 57484.06,
    "out:PV (kWh sold)": 25763.95,
    "out:PV (% sold (El))": 44.82,
    "out:PV (kWh self-consumed)": 31720.11,
    "out:PV (ratio sold/self-consumed)": 0.81,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 875718.530813,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 344581,
    "out:EL kWh savings": 8067,
    "out:Sewage heat recovery cost": 250448,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 360132,
    "out:DH kr savings": 313569,
    "out:El kr savings": 13715,
    "out:El kr sold": 32849,
    "out:El kr saved": 53924,
    "out:El kr return": 86773,
    "out:% solar/total": 39,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 348709,
    "out:% savings (space heating)": 47.05,
    "out:Total_Water Heating (DH)": 190162,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 97.83,
    "out:Etvv": 21.25,
    "out:Ef": 14.33,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000200010001110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 63.63,
    "out:DHW (DH)": 36.98,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 14.33,
    "out:Total Energy Use Pre PV": 121.5,
    "out:Total Energy Use Post PV": 115.33,
    "out:Primary Energy": 85.21,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 30.9,
    "out:CO2 Operational/m2": 13.05,
    "out:Total CO2/m2": 43.94,
    "out:Total CO2 (tons)": 226.01,
    "out:Klimatpaverkan": -103.23,
    "out:Initial Cost/MSEK": 10.760125,
    "out:Running cost/(Apt SEK y)": 34706,
    "out:Running Cost over RSP/MSEK": 11.409,
    "out:LCP/MSEK": -2.9,
    "out:ROI% old": 10.44,
    "out:Payback discounted": 12,
    "out:Atemp": 5143,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FTX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 115,
    "out:Energy use kWh/m2": 115,
    "out:Energy savings %": 63.5,
    "out:Op energy cost/MSEK": 84,
    "out:El price/MSEK": 50,
    "out:Heating price/MSEK": 29,
    "out:DH+ DHW/kWh/m2": 101,
    "out:El bought/kWh/m2": 1013.1,
    "out:Return %": 9,
    "out:Return/kSEK/y": 1006,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 61.265983,
    "out:PV (m2 panels)": 321,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 57484.06,
    "out:PV (kWh sold)": 25763.95,
    "out:PV (% sold (El))": 44.82,
    "out:PV (kWh self-consumed)": 31720.11,
    "out:PV (ratio sold/self-consumed)": 0.81,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 875718.530813,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 365153,
    "out:EL kWh savings": 8067,
    "out:Sewage heat recovery cost": 250448,
    "out:Balancing hydronic system cost": 128574.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 378853,
    "out:DH kr savings": 332289,
    "out:El kr savings": 13715,
    "out:El kr sold": 32849,
    "out:El kr saved": 53924,
    "out:El kr return": 86773,
    "out:% solar/total": 39,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 327242,
    "out:% savings (space heating)": 50.31,
    "out:Total_Water Heating (DH)": 190162,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 93.66,
    "out:Etvv": 21.25,
    "out:Ef": 14.33,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000200020000010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 67.8,
    "out:DHW (DH)": 43.5,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 13.09,
    "out:Total Energy Use Pre PV": 131.5,
    "out:Total Energy Use Post PV": 124.09,
    "out:Primary Energy": 88.52,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 42.85,
    "out:CO2 Operational/m2": -34.03,
    "out:Total CO2/m2": 8.81,
    "out:Total CO2 (tons)": 45.33,
    "out:Klimatpaverkan": -283.91,
    "out:Initial Cost/MSEK": 11.450875,
    "out:Running cost/(Apt SEK y)": 36115,
    "out:Running Cost over RSP/MSEK": 11.889,
    "out:LCP/MSEK": -4.071,
    "out:ROI% old": 9.21,
    "out:Payback discounted": 13,
    "out:Atemp": 5143,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FTX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 124,
    "out:Energy use kWh/m2": 124,
    "out:Energy savings %": 51.6,
    "out:Op energy cost/MSEK": 88,
    "out:El price/MSEK": 51,
    "out:Heating price/MSEK": 32,
    "out:DH+ DHW/kWh/m2": 111,
    "out:El bought/kWh/m2": 923.7,
    "out:Return %": 8,
    "out:Return/kSEK/y": 947,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.33,
    "out:PV (kWp installed)": 122.531965,
    "out:PV (m2 panels)": 643,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 114968.11,
    "out:PV (kWh sold)": 76862.14,
    "out:PV (% sold (El))": 66.86,
    "out:PV (kWh self-consumed)": 38105.97,
    "out:PV (ratio sold/self-consumed)": 2.02,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 875718.530813,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 313723,
    "out:EL kWh savings": 14453,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 408058,
    "out:DH kr savings": 285488,
    "out:El kr savings": 24571,
    "out:El kr sold": 97999,
    "out:El kr saved": 64780,
    "out:El kr return": 162779,
    "out:% solar/total": 47,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 348709,
    "out:% savings (space heating)": 47.05,
    "out:Total_Water Heating (DH)": 223720,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 97.83,
    "out:Etvv": 25,
    "out:Ef": 13.09,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000200020000110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 63.63,
    "out:DHW (DH)": 43.5,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 13.09,
    "out:Total Energy Use Pre PV": 127.5,
    "out:Total Energy Use Post PV": 120.09,
    "out:Primary Energy": 85.6,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 42.85,
    "out:CO2 Operational/m2": -35.47,
    "out:Total CO2/m2": 7.37,
    "out:Total CO2 (tons)": 37.93,
    "out:Klimatpaverkan": -291.31,
    "out:Initial Cost/MSEK": 11.579375,
    "out:Running cost/(Apt SEK y)": 34827,
    "out:Running Cost over RSP/MSEK": 11.466,
    "out:LCP/MSEK": -3.776,
    "out:ROI% old": 9.63,
    "out:Payback discounted": 13,
    "out:Atemp": 5143,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FTX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 120,
    "out:Energy use kWh/m2": 120,
    "out:Energy savings %": 56.7,
    "out:Op energy cost/MSEK": 85,
    "out:El price/MSEK": 49,
    "out:Heating price/MSEK": 31,
    "out:DH+ DHW/kWh/m2": 107,
    "out:El bought/kWh/m2": 923.7,
    "out:Return %": 9,
    "out:Return/kSEK/y": 1001,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.33,
    "out:PV (kWp installed)": 122.531965,
    "out:PV (m2 panels)": 643,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 114968.11,
    "out:PV (kWh sold)": 76862.14,
    "out:PV (% sold (El))": 66.86,
    "out:PV (kWh self-consumed)": 38105.97,
    "out:PV (ratio sold/self-consumed)": 2.02,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 875718.530813,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 334295,
    "out:EL kWh savings": 14453,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 128574.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 426778,
    "out:DH kr savings": 304208,
    "out:El kr savings": 24571,
    "out:El kr sold": 97999,
    "out:El kr saved": 64780,
    "out:El kr return": 162779,
    "out:% solar/total": 47,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 327242,
    "out:% savings (space heating)": 50.31,
    "out:Total_Water Heating (DH)": 223720,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 93.66,
    "out:Etvv": 25,
    "out:Ef": 13.09,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000200020001010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 67.8,
    "out:DHW (DH)": 36.98,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 13.09,
    "out:Total Energy Use Pre PV": 125.5,
    "out:Total Energy Use Post PV": 118.09,
    "out:Primary Energy": 85.9,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 43.25,
    "out:CO2 Operational/m2": -36.19,
    "out:Total CO2/m2": 7.06,
    "out:Total CO2 (tons)": 36.29,
    "out:Klimatpaverkan": -292.95,
    "out:Initial Cost/MSEK": 11.70125,
    "out:Running cost/(Apt SEK y)": 34184,
    "out:Running Cost over RSP/MSEK": 11.254,
    "out:LCP/MSEK": -3.686,
    "out:ROI% old": 9.79,
    "out:Payback discounted": 12,
    "out:Atemp": 5143,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FTX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 118,
    "out:Energy use kWh/m2": 118,
    "out:Energy savings %": 59.3,
    "out:Op energy cost/MSEK": 84,
    "out:El price/MSEK": 48,
    "out:Heating price/MSEK": 30,
    "out:DH+ DHW/kWh/m2": 105,
    "out:El bought/kWh/m2": 923.7,
    "out:Return %": 9,
    "out:Return/kSEK/y": 1028,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.33,
    "out:PV (kWp installed)": 122.531965,
    "out:PV (m2 panels)": 643,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 114968.11,
    "out:PV (kWh sold)": 76862.14,
    "out:PV (% sold (El))": 66.86,
    "out:PV (kWh self-consumed)": 38105.97,
    "out:PV (ratio sold/self-consumed)": 2.02,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 875718.530813,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 344581,
    "out:EL kWh savings": 14453,
    "out:Sewage heat recovery cost": 250448,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 436139,
    "out:DH kr savings": 313569,
    "out:El kr savings": 24571,
    "out:El kr sold": 97999,
    "out:El kr saved": 64780,
    "out:El kr return": 162779,
    "out:% solar/total": 47,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 348709,
    "out:% savings (space heating)": 47.05,
    "out:Total_Water Heating (DH)": 190162,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 97.83,
    "out:Etvv": 21.25,
    "out:Ef": 13.09,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000200020001110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 63.63,
    "out:DHW (DH)": 36.98,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 13.09,
    "out:Total Energy Use Pre PV": 121.5,
    "out:Total Energy Use Post PV": 114.09,
    "out:Primary Energy": 82.98,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 43.25,
    "out:CO2 Operational/m2": -37.63,
    "out:Total CO2/m2": 5.62,
    "out:Total CO2 (tons)": 28.89,
    "out:Klimatpaverkan": -300.35,
    "out:Initial Cost/MSEK": 11.829875,
    "out:Running cost/(Apt SEK y)": 32896,
    "out:Running Cost over RSP/MSEK": 10.831,
    "out:LCP/MSEK": -3.392,
    "out:ROI% old": 10.19,
    "out:Payback discounted": 12,
    "out:Atemp": 5143,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FTX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 114,
    "out:Energy use kWh/m2": 114,
    "out:Energy savings %": 64.9,
    "out:Op energy cost/MSEK": 80,
    "out:El price/MSEK": 47,
    "out:Heating price/MSEK": 29,
    "out:DH+ DHW/kWh/m2": 101,
    "out:El bought/kWh/m2": 923.7,
    "out:Return %": 9,
    "out:Return/kSEK/y": 1082,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.33,
    "out:PV (kWp installed)": 122.531965,
    "out:PV (m2 panels)": 643,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 114968.11,
    "out:PV (kWh sold)": 76862.14,
    "out:PV (% sold (El))": 66.86,
    "out:PV (kWh self-consumed)": 38105.97,
    "out:PV (ratio sold/self-consumed)": 2.02,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 875718.530813,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 365153,
    "out:EL kWh savings": 14453,
    "out:Sewage heat recovery cost": 250448,
    "out:Balancing hydronic system cost": 128574.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 454859,
    "out:DH kr savings": 332289,
    "out:El kr savings": 24571,
    "out:El kr sold": 97999,
    "out:El kr saved": 64780,
    "out:El kr return": 162779,
    "out:% solar/total": 47,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 327242,
    "out:% savings (space heating)": 50.31,
    "out:Total_Water Heating (DH)": 190162,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 93.66,
    "out:Etvv": 21.25,
    "out:Ef": 13.09,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000200100000010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 67.8,
    "out:DHW (DH)": 34.8,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 20.5,
    "out:Total Energy Use Pre PV": 123.5,
    "out:Total Energy Use Post PV": 123.5,
    "out:Primary Energy": 98.36,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 18.14,
    "out:CO2 Operational/m2": 39.78,
    "out:Total CO2/m2": 57.92,
    "out:Total CO2 (tons)": 297.9,
    "out:Klimatpaverkan": -31.34,
    "out:Initial Cost/MSEK": 9.422125,
    "out:Running cost/(Apt SEK y)": 37416,
    "out:Running Cost over RSP/MSEK": 12.281,
    "out:LCP/MSEK": -2.434,
    "out:ROI% old": 10.6,
    "out:Payback discounted": 11,
    "out:Atemp": 5143,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FTX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 123,
    "out:Energy use kWh/m2": 123,
    "out:Energy savings %": 52.8,
    "out:Op energy cost/MSEK": 90,
    "out:El price/MSEK": 55,
    "out:Heating price/MSEK": 30,
    "out:DH+ DHW/kWh/m2": 103,
    "out:El bought/kWh/m2": 1434.9,
    "out:Return %": 9,
    "out:Return/kSEK/y": 892,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 875718.530813,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 354867,
    "out:EL kWh savings": -23653,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 282719,
    "out:DH kr savings": 322929,
    "out:El kr savings": -40210,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 348709,
    "out:% savings (space heating)": 47.05,
    "out:Total_Water Heating (DH)": 178976,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 97.83,
    "out:Etvv": 20,
    "out:Ef": 20.5,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000200100000110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 63.63,
    "out:DHW (DH)": 34.8,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 20.5,
    "out:Total Energy Use Pre PV": 118.5,
    "out:Total Energy Use Post PV": 118.5,
    "out:Primary Energy": 95.44,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 18.14,
    "out:CO2 Operational/m2": 37.98,
    "out:Total CO2/m2": 56.12,
    "out:Total CO2 (tons)": 288.65,
    "out:Klimatpaverkan": -40.59,
    "out:Initial Cost/MSEK": 9.55075,
    "out:Running cost/(Apt SEK y)": 35807,
    "out:Running Cost over RSP/MSEK": 11.752,
    "out:LCP/MSEK": -2.034,
    "out:ROI% old": 11.24,
    "out:Payback discounted": 11,
    "out:Atemp": 5143,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FTX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 118,
    "out:Energy use kWh/m2": 118,
    "out:Energy savings %": 59.3,
    "out:Op energy cost/MSEK": 86,
    "out:El price/MSEK": 53,
    "out:Heating price/MSEK": 28,
    "out:DH+ DHW/kWh/m2": 98,
    "out:El bought/kWh/m2": 1434.9,
    "out:Return %": 10,
    "out:Return/kSEK/y": 960,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 875718.530813,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 380582,
    "out:EL kWh savings": -23653,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 128574.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 306120,
    "out:DH kr savings": 346330,
    "out:El kr savings": -40210,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 327242,
    "out:% savings (space heating)": 50.31,
    "out:Total_Water Heating (DH)": 178976,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 93.66,
    "out:Etvv": 20,
    "out:Ef": 20.5,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000200100001010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 67.8,
    "out:DHW (DH)": 29.58,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 20.5,
    "out:Total Energy Use Pre PV": 117.5,
    "out:Total Energy Use Post PV": 117.5,
    "out:Primary Energy": 96.26,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 18.54,
    "out:CO2 Operational/m2": 37.62,
    "out:Total CO2/m2": 56.17,
    "out:Total CO2 (tons)": 288.86,
    "out:Klimatpaverkan": -40.38,
    "out:Initial Cost/MSEK": 9.672625,
    "out:Running cost/(Apt SEK y)": 35485,
    "out:Running Cost over RSP/MSEK": 11.646,
    "out:LCP/MSEK": -2.05,
    "out:ROI% old": 11.26,
    "out:Payback discounted": 11,
    "out:Atemp": 5143,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FTX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 117,
    "out:Energy use kWh/m2": 117,
    "out:Energy savings %": 60.7,
    "out:Op energy cost/MSEK": 85,
    "out:El price/MSEK": 53,
    "out:Heating price/MSEK": 28,
    "out:DH+ DHW/kWh/m2": 97,
    "out:El bought/kWh/m2": 1434.9,
    "out:Return %": 10,
    "out:Return/kSEK/y": 974,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 875718.530813,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 385725,
    "out:EL kWh savings": -23653,
    "out:Sewage heat recovery cost": 250448,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 310800,
    "out:DH kr savings": 351010,
    "out:El kr savings": -40210,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 348709,
    "out:% savings (space heating)": 47.05,
    "out:Total_Water Heating (DH)": 152130,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 97.83,
    "out:Etvv": 17,
    "out:Ef": 20.5,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000200100001110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 63.63,
    "out:DHW (DH)": 29.58,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 20.5,
    "out:Total Energy Use Pre PV": 113.5,
    "out:Total Energy Use Post PV": 113.5,
    "out:Primary Energy": 93.34,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 18.54,
    "out:CO2 Operational/m2": 36.18,
    "out:Total CO2/m2": 54.73,
    "out:Total CO2 (tons)": 281.46,
    "out:Klimatpaverkan": -47.78,
    "out:Initial Cost/MSEK": 9.801125,
    "out:Running cost/(Apt SEK y)": 34197,
    "out:Running Cost over RSP/MSEK": 11.223,
    "out:LCP/MSEK": -1.755,
    "out:ROI% old": 11.73,
    "out:Payback discounted": 10,
    "out:Atemp": 5143,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FTX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 113,
    "out:Energy use kWh/m2": 113,
    "out:Energy savings %": 66.4,
    "out:Op energy cost/MSEK": 82,
    "out:El price/MSEK": 51,
    "out:Heating price/MSEK": 27,
    "out:DH+ DHW/kWh/m2": 93,
    "out:El bought/kWh/m2": 1434.9,
    "out:Return %": 10,
    "out:Return/kSEK/y": 1028,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 875718.530813,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 406297,
    "out:EL kWh savings": -23653,
    "out:Sewage heat recovery cost": 250448,
    "out:Balancing hydronic system cost": 128574.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 329521,
    "out:DH kr savings": 369730,
    "out:El kr savings": -40210,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 327242,
    "out:% savings (space heating)": 50.31,
    "out:Total_Water Heating (DH)": 152130,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 93.66,
    "out:Etvv": 17,
    "out:Ef": 20.5,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000200110000010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 67.8,
    "out:DHW (DH)": 34.8,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 14.33,
    "out:Total Energy Use Pre PV": 123.5,
    "out:Total Energy Use Post PV": 117.33,
    "out:Primary Energy": 87.26,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 30.49,
    "out:CO2 Operational/m2": 13.77,
    "out:Total CO2/m2": 44.26,
    "out:Total CO2 (tons)": 227.64,
    "out:Klimatpaverkan": -101.6,
    "out:Initial Cost/MSEK": 10.491875,
    "out:Running cost/(Apt SEK y)": 35350,
    "out:Running Cost over RSP/MSEK": 11.62,
    "out:LCP/MSEK": -2.843,
    "out:ROI% old": 10.41,
    "out:Payback discounted": 12,
    "out:Atemp": 5143,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FTX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 117,
    "out:Energy use kWh/m2": 117,
    "out:Energy savings %": 60.7,
    "out:Op energy cost/MSEK": 86,
    "out:El price/MSEK": 51,
    "out:Heating price/MSEK": 30,
    "out:DH+ DHW/kWh/m2": 103,
    "out:El bought/kWh/m2": 1013.1,
    "out:Return %": 9,
    "out:Return/kSEK/y": 979,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 61.265983,
    "out:PV (m2 panels)": 321,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 57484.06,
    "out:PV (kWh sold)": 25763.95,
    "out:PV (% sold (El))": 44.82,
    "out:PV (kWh self-consumed)": 31720.11,
    "out:PV (ratio sold/self-consumed)": 0.81,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 875718.530813,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 354867,
    "out:EL kWh savings": 8067,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 369493,
    "out:DH kr savings": 322929,
    "out:El kr savings": 13715,
    "out:El kr sold": 32849,
    "out:El kr saved": 53924,
    "out:El kr return": 86773,
    "out:% solar/total": 39,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 348709,
    "out:% savings (space heating)": 47.05,
    "out:Total_Water Heating (DH)": 178976,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 97.83,
    "out:Etvv": 20,
    "out:Ef": 14.33,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000200110000110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 63.63,
    "out:DHW (DH)": 34.8,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 14.33,
    "out:Total Energy Use Pre PV": 118.5,
    "out:Total Energy Use Post PV": 112.33,
    "out:Primary Energy": 84.34,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 30.49,
    "out:CO2 Operational/m2": 11.97,
    "out:Total CO2/m2": 42.46,
    "out:Total CO2 (tons)": 218.39,
    "out:Klimatpaverkan": -110.85,
    "out:Initial Cost/MSEK": 10.6205,
    "out:Running cost/(Apt SEK y)": 33740,
    "out:Running Cost over RSP/MSEK": 11.092,
    "out:LCP/MSEK": -2.443,
    "out:ROI% old": 11,
    "out:Payback discounted": 11,
    "out:Atemp": 5143,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FTX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 112,
    "out:Energy use kWh/m2": 112,
    "out:Energy savings %": 67.9,
    "out:Op energy cost/MSEK": 82,
    "out:El price/MSEK": 49,
    "out:Heating price/MSEK": 28,
    "out:DH+ DHW/kWh/m2": 98,
    "out:El bought/kWh/m2": 1013.1,
    "out:Return %": 10,
    "out:Return/kSEK/y": 1047,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 61.265983,
    "out:PV (m2 panels)": 321,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 57484.06,
    "out:PV (kWh sold)": 25763.95,
    "out:PV (% sold (El))": 44.82,
    "out:PV (kWh self-consumed)": 31720.11,
    "out:PV (ratio sold/self-consumed)": 0.81,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 875718.530813,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 380582,
    "out:EL kWh savings": 8067,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 128574.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 392893,
    "out:DH kr savings": 346330,
    "out:El kr savings": 13715,
    "out:El kr sold": 32849,
    "out:El kr saved": 53924,
    "out:El kr return": 86773,
    "out:% solar/total": 39,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 327242,
    "out:% savings (space heating)": 50.31,
    "out:Total_Water Heating (DH)": 178976,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 93.66,
    "out:Etvv": 20,
    "out:Ef": 14.33,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000200110001010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 67.8,
    "out:DHW (DH)": 29.58,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 14.33,
    "out:Total Energy Use Pre PV": 117.5,
    "out:Total Energy Use Post PV": 111.33,
    "out:Primary Energy": 85.16,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 30.9,
    "out:CO2 Operational/m2": 11.61,
    "out:Total CO2/m2": 42.5,
    "out:Total CO2 (tons)": 218.6,
    "out:Klimatpaverkan": -110.64,
    "out:Initial Cost/MSEK": 10.742375,
    "out:Running cost/(Apt SEK y)": 33419,
    "out:Running Cost over RSP/MSEK": 10.986,
    "out:LCP/MSEK": -2.459,
    "out:ROI% old": 11.02,
    "out:Payback discounted": 11,
    "out:Atemp": 5143,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FTX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 111,
    "out:Energy use kWh/m2": 111,
    "out:Energy savings %": 69.4,
    "out:Op energy cost/MSEK": 81,
    "out:El price/MSEK": 48,
    "out:Heating price/MSEK": 28,
    "out:DH+ DHW/kWh/m2": 97,
    "out:El bought/kWh/m2": 1013.1,
    "out:Return %": 10,
    "out:Return/kSEK/y": 1060,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 61.265983,
    "out:PV (m2 panels)": 321,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 57484.06,
    "out:PV (kWh sold)": 25763.95,
    "out:PV (% sold (El))": 44.82,
    "out:PV (kWh self-consumed)": 31720.11,
    "out:PV (ratio sold/self-consumed)": 0.81,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 875718.530813,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 385725,
    "out:EL kWh savings": 8067,
    "out:Sewage heat recovery cost": 250448,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 397573,
    "out:DH kr savings": 351010,
    "out:El kr savings": 13715,
    "out:El kr sold": 32849,
    "out:El kr saved": 53924,
    "out:El kr return": 86773,
    "out:% solar/total": 39,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 348709,
    "out:% savings (space heating)": 47.05,
    "out:Total_Water Heating (DH)": 152130,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 97.83,
    "out:Etvv": 17,
    "out:Ef": 14.33,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000200110001110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 63.63,
    "out:DHW (DH)": 29.58,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 14.33,
    "out:Total Energy Use Pre PV": 113.5,
    "out:Total Energy Use Post PV": 107.33,
    "out:Primary Energy": 82.24,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 30.9,
    "out:CO2 Operational/m2": 10.17,
    "out:Total CO2/m2": 41.06,
    "out:Total CO2 (tons)": 211.2,
    "out:Klimatpaverkan": -118.04,
    "out:Initial Cost/MSEK": 10.871,
    "out:Running cost/(Apt SEK y)": 32131,
    "out:Running Cost over RSP/MSEK": 10.563,
    "out:LCP/MSEK": -2.165,
    "out:ROI% old": 11.44,
    "out:Payback discounted": 11,
    "out:Atemp": 5143,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FTX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 107,
    "out:Energy use kWh/m2": 107,
    "out:Energy savings %": 75.7,
    "out:Op energy cost/MSEK": 78,
    "out:El price/MSEK": 47,
    "out:Heating price/MSEK": 27,
    "out:DH+ DHW/kWh/m2": 93,
    "out:El bought/kWh/m2": 1013.1,
    "out:Return %": 10,
    "out:Return/kSEK/y": 1114,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 61.265983,
    "out:PV (m2 panels)": 321,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 57484.06,
    "out:PV (kWh sold)": 25763.95,
    "out:PV (% sold (El))": 44.82,
    "out:PV (kWh self-consumed)": 31720.11,
    "out:PV (ratio sold/self-consumed)": 0.81,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 875718.530813,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 406297,
    "out:EL kWh savings": 8067,
    "out:Sewage heat recovery cost": 250448,
    "out:Balancing hydronic system cost": 128574.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 416294,
    "out:DH kr savings": 369730,
    "out:El kr savings": 13715,
    "out:El kr sold": 32849,
    "out:El kr saved": 53924,
    "out:El kr return": 86773,
    "out:% solar/total": 39,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 327242,
    "out:% savings (space heating)": 50.31,
    "out:Total_Water Heating (DH)": 152130,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 93.66,
    "out:Etvv": 17,
    "out:Ef": 14.33,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000200120000010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 67.8,
    "out:DHW (DH)": 34.8,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 13.09,
    "out:Total Energy Use Pre PV": 123.5,
    "out:Total Energy Use Post PV": 116.09,
    "out:Primary Energy": 85.02,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 42.85,
    "out:CO2 Operational/m2": -36.91,
    "out:Total CO2/m2": 5.93,
    "out:Total CO2 (tons)": 30.52,
    "out:Klimatpaverkan": -298.72,
    "out:Initial Cost/MSEK": 11.56175,
    "out:Running cost/(Apt SEK y)": 33540,
    "out:Running Cost over RSP/MSEK": 11.042,
    "out:LCP/MSEK": -3.335,
    "out:ROI% old": 10.16,
    "out:Payback discounted": 12,
    "out:Atemp": 5143,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FTX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 116,
    "out:Energy use kWh/m2": 116,
    "out:Energy savings %": 62.1,
    "out:Op energy cost/MSEK": 82,
    "out:El price/MSEK": 48,
    "out:Heating price/MSEK": 30,
    "out:DH+ DHW/kWh/m2": 103,
    "out:El bought/kWh/m2": 923.7,
    "out:Return %": 9,
    "out:Return/kSEK/y": 1055,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.33,
    "out:PV (kWp installed)": 122.531965,
    "out:PV (m2 panels)": 643,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 114968.11,
    "out:PV (kWh sold)": 76862.14,
    "out:PV (% sold (El))": 66.86,
    "out:PV (kWh self-consumed)": 38105.97,
    "out:PV (ratio sold/self-consumed)": 2.02,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 875718.530813,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 354867,
    "out:EL kWh savings": 14453,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 445499,
    "out:DH kr savings": 322929,
    "out:El kr savings": 24571,
    "out:El kr sold": 97999,
    "out:El kr saved": 64780,
    "out:El kr return": 162779,
    "out:% solar/total": 47,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 348709,
    "out:% savings (space heating)": 47.05,
    "out:Total_Water Heating (DH)": 178976,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 97.83,
    "out:Etvv": 20,
    "out:Ef": 13.09,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000200120000110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 63.63,
    "out:DHW (DH)": 34.8,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 13.09,
    "out:Total Energy Use Pre PV": 118.5,
    "out:Total Energy Use Post PV": 111.09,
    "out:Primary Energy": 82.1,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 42.85,
    "out:CO2 Operational/m2": -38.71,
    "out:Total CO2/m2": 4.13,
    "out:Total CO2 (tons)": 21.27,
    "out:Klimatpaverkan": -307.97,
    "out:Initial Cost/MSEK": 11.69025,
    "out:Running cost/(Apt SEK y)": 31931,
    "out:Running Cost over RSP/MSEK": 10.514,
    "out:LCP/MSEK": -2.935,
    "out:ROI% old": 10.7,
    "out:Payback discounted": 11,
    "out:Atemp": 5143,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FTX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 111,
    "out:Energy use kWh/m2": 111,
    "out:Energy savings %": 69.4,
    "out:Op energy cost/MSEK": 78,
    "out:El price/MSEK": 45,
    "out:Heating price/MSEK": 28,
    "out:DH+ DHW/kWh/m2": 98,
    "out:El bought/kWh/m2": 923.7,
    "out:Return %": 10,
    "out:Return/kSEK/y": 1123,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.33,
    "out:PV (kWp installed)": 122.531965,
    "out:PV (m2 panels)": 643,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 114968.11,
    "out:PV (kWh sold)": 76862.14,
    "out:PV (% sold (El))": 66.86,
    "out:PV (kWh self-consumed)": 38105.97,
    "out:PV (ratio sold/self-consumed)": 2.02,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 875718.530813,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 380582,
    "out:EL kWh savings": 14453,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 128574.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 468899,
    "out:DH kr savings": 346330,
    "out:El kr savings": 24571,
    "out:El kr sold": 97999,
    "out:El kr saved": 64780,
    "out:El kr return": 162779,
    "out:% solar/total": 47,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 327242,
    "out:% savings (space heating)": 50.31,
    "out:Total_Water Heating (DH)": 178976,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 93.66,
    "out:Etvv": 20,
    "out:Ef": 13.09,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000200120001010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 67.8,
    "out:DHW (DH)": 29.58,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 13.09,
    "out:Total Energy Use Pre PV": 117.5,
    "out:Total Energy Use Post PV": 110.09,
    "out:Primary Energy": 82.92,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 43.25,
    "out:CO2 Operational/m2": -39.07,
    "out:Total CO2/m2": 4.18,
    "out:Total CO2 (tons)": 21.48,
    "out:Klimatpaverkan": -307.76,
    "out:Initial Cost/MSEK": 11.812125,
    "out:Running cost/(Apt SEK y)": 31609,
    "out:Running Cost over RSP/MSEK": 10.408,
    "out:LCP/MSEK": -2.951,
    "out:ROI% old": 10.72,
    "out:Payback discounted": 11,
    "out:Atemp": 5143,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FTX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 110,
    "out:Energy use kWh/m2": 110,
    "out:Energy savings %": 70.9,
    "out:Op energy cost/MSEK": 77,
    "out:El price/MSEK": 45,
    "out:Heating price/MSEK": 28,
    "out:DH+ DHW/kWh/m2": 97,
    "out:El bought/kWh/m2": 923.7,
    "out:Return %": 10,
    "out:Return/kSEK/y": 1136,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.33,
    "out:PV (kWp installed)": 122.531965,
    "out:PV (m2 panels)": 643,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 114968.11,
    "out:PV (kWh sold)": 76862.14,
    "out:PV (% sold (El))": 66.86,
    "out:PV (kWh self-consumed)": 38105.97,
    "out:PV (ratio sold/self-consumed)": 2.02,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 875718.530813,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 385725,
    "out:EL kWh savings": 14453,
    "out:Sewage heat recovery cost": 250448,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 473580,
    "out:DH kr savings": 351010,
    "out:El kr savings": 24571,
    "out:El kr sold": 97999,
    "out:El kr saved": 64780,
    "out:El kr return": 162779,
    "out:% solar/total": 47,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 348709,
    "out:% savings (space heating)": 47.05,
    "out:Total_Water Heating (DH)": 152130,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 97.83,
    "out:Etvv": 17,
    "out:Ef": 13.09,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000200120001110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 63.63,
    "out:DHW (DH)": 29.58,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 13.09,
    "out:Total Energy Use Pre PV": 113.5,
    "out:Total Energy Use Post PV": 106.09,
    "out:Primary Energy": 80,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 43.25,
    "out:CO2 Operational/m2": -40.51,
    "out:Total CO2/m2": 2.74,
    "out:Total CO2 (tons)": 14.08,
    "out:Klimatpaverkan": -315.16,
    "out:Initial Cost/MSEK": 11.94075,
    "out:Running cost/(Apt SEK y)": 30322,
    "out:Running Cost over RSP/MSEK": 9.985,
    "out:LCP/MSEK": -2.656,
    "out:ROI% old": 11.11,
    "out:Payback discounted": 11,
    "out:Atemp": 5143,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FTX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 106,
    "out:Energy use kWh/m2": 106,
    "out:Energy savings %": 77.4,
    "out:Op energy cost/MSEK": 74,
    "out:El price/MSEK": 43,
    "out:Heating price/MSEK": 27,
    "out:DH+ DHW/kWh/m2": 93,
    "out:El bought/kWh/m2": 923.7,
    "out:Return %": 10,
    "out:Return/kSEK/y": 1190,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.33,
    "out:PV (kWp installed)": 122.531965,
    "out:PV (m2 panels)": 643,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 114968.11,
    "out:PV (kWh sold)": 76862.14,
    "out:PV (% sold (El))": 66.86,
    "out:PV (kWh self-consumed)": 38105.97,
    "out:PV (ratio sold/self-consumed)": 2.02,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 875718.530813,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 406297,
    "out:EL kWh savings": 14453,
    "out:Sewage heat recovery cost": 250448,
    "out:Balancing hydronic system cost": 128574.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 492300,
    "out:DH kr savings": 369730,
    "out:El kr savings": 24571,
    "out:El kr sold": 97999,
    "out:El kr saved": 64780,
    "out:El kr return": 162779,
    "out:% solar/total": 47,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 327242,
    "out:% savings (space heating)": 50.31,
    "out:Total_Water Heating (DH)": 152130,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 93.66,
    "out:Etvv": 17,
    "out:Ef": 13.09,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000000000000010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 77.4,
    "out:DHW (DH)": 43.5,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 20.5,
    "out:Total Energy Use Pre PV": 141.5,
    "out:Total Energy Use Post PV": 141.5,
    "out:Primary Energy": 108.58,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 12.16,
    "out:CO2 Operational/m2": 46.26,
    "out:Total CO2/m2": 58.42,
    "out:Total CO2 (tons)": 300.47,
    "out:Klimatpaverkan": -28.77,
    "out:Initial Cost/MSEK": 7.224125,
    "out:Running cost/(Apt SEK y)": 43209,
    "out:Running Cost over RSP/MSEK": 14.185,
    "out:LCP/MSEK": -2.14,
    "out:ROI% old": 10.05,
    "out:Payback discounted": 12,
    "out:Atemp": 5143,
    "out:Umean": 0.49,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 141,
    "out:Energy use kWh/m2": 141,
    "out:Energy savings %": 33.3,
    "out:Op energy cost/MSEK": 104,
    "out:El price/MSEK": 63,
    "out:Heating price/MSEK": 35,
    "out:DH+ DHW/kWh/m2": 121,
    "out:El bought/kWh/m2": 1434.9,
    "out:Return %": 9,
    "out:Return/kSEK/y": 649,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 875718.530813,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 262293,
    "out:EL kWh savings": -23653,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 198477,
    "out:DH kr savings": 238687,
    "out:El kr savings": -40210,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 398065,
    "out:% savings (space heating)": 39.56,
    "out:Total_Water Heating (DH)": 223720,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 107.43,
    "out:Etvv": 25,
    "out:Ef": 20.5,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000000000000110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 72.79,
    "out:DHW (DH)": 43.5,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 20.5,
    "out:Total Energy Use Pre PV": 136.5,
    "out:Total Energy Use Post PV": 136.5,
    "out:Primary Energy": 105.35,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 12.16,
    "out:CO2 Operational/m2": 44.46,
    "out:Total CO2/m2": 56.62,
    "out:Total CO2 (tons)": 291.21,
    "out:Klimatpaverkan": -38.03,
    "out:Initial Cost/MSEK": 7.35275,
    "out:Running cost/(Apt SEK y)": 41599,
    "out:Running Cost over RSP/MSEK": 13.656,
    "out:LCP/MSEK": -1.74,
    "out:ROI% old": 10.91,
    "out:Payback discounted": 11,
    "out:Atemp": 5143,
    "out:Umean": 0.49,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 136,
    "out:Energy use kWh/m2": 136,
    "out:Energy savings %": 38.2,
    "out:Op energy cost/MSEK": 100,
    "out:El price/MSEK": 61,
    "out:Heating price/MSEK": 33,
    "out:DH+ DHW/kWh/m2": 116,
    "out:El bought/kWh/m2": 1434.9,
    "out:Return %": 10,
    "out:Return/kSEK/y": 717,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 875718.530813,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 288008,
    "out:EL kWh savings": -23653,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 128574.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 221878,
    "out:DH kr savings": 262087,
    "out:El kr savings": -40210,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 374354,
    "out:% savings (space heating)": 43.16,
    "out:Total_Water Heating (DH)": 223720,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 102.82,
    "out:Etvv": 25,
    "out:Ef": 20.5,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000000000001010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 77.4,
    "out:DHW (DH)": 36.98,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 20.5,
    "out:Total Energy Use Pre PV": 134.5,
    "out:Total Energy Use Post PV": 134.5,
    "out:Primary Energy": 105.95,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 12.56,
    "out:CO2 Operational/m2": 43.74,
    "out:Total CO2/m2": 56.31,
    "out:Total CO2 (tons)": 289.58,
    "out:Klimatpaverkan": -39.66,
    "out:Initial Cost/MSEK": 7.474625,
    "out:Running cost/(Apt SEK y)": 40956,
    "out:Running Cost over RSP/MSEK": 13.445,
    "out:LCP/MSEK": -1.65,
    "out:ROI% old": 11.13,
    "out:Payback discounted": 11,
    "out:Atemp": 5143,
    "out:Umean": 0.49,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 134,
    "out:Energy use kWh/m2": 134,
    "out:Energy savings %": 40.3,
    "out:Op energy cost/MSEK": 98,
    "out:El price/MSEK": 60,
    "out:Heating price/MSEK": 33,
    "out:DH+ DHW/kWh/m2": 114,
    "out:El bought/kWh/m2": 1434.9,
    "out:Return %": 10,
    "out:Return/kSEK/y": 744,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 875718.530813,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 298294,
    "out:EL kWh savings": -23653,
    "out:Sewage heat recovery cost": 250448,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 231238,
    "out:DH kr savings": 271448,
    "out:El kr savings": -40210,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 398065,
    "out:% savings (space heating)": 39.56,
    "out:Total_Water Heating (DH)": 190162,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 107.43,
    "out:Etvv": 21.25,
    "out:Ef": 20.5,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000000000001110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 72.79,
    "out:DHW (DH)": 36.98,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 20.5,
    "out:Total Energy Use Pre PV": 130.5,
    "out:Total Energy Use Post PV": 130.5,
    "out:Primary Energy": 102.73,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 12.56,
    "out:CO2 Operational/m2": 42.3,
    "out:Total CO2/m2": 54.87,
    "out:Total CO2 (tons)": 282.17,
    "out:Klimatpaverkan": -47.07,
    "out:Initial Cost/MSEK": 7.603125,
    "out:Running cost/(Apt SEK y)": 39668,
    "out:Running Cost over RSP/MSEK": 13.021,
    "out:LCP/MSEK": -1.356,
    "out:ROI% old": 11.74,
    "out:Payback discounted": 10,
    "out:Atemp": 5143,
    "out:Umean": 0.49,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 130,
    "out:Energy use kWh/m2": 130,
    "out:Energy savings %": 44.6,
    "out:Op energy cost/MSEK": 95,
    "out:El price/MSEK": 58,
    "out:Heating price/MSEK": 32,
    "out:DH+ DHW/kWh/m2": 110,
    "out:El bought/kWh/m2": 1434.9,
    "out:Return %": 10,
    "out:Return/kSEK/y": 798,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 875718.530813,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 318866,
    "out:EL kWh savings": -23653,
    "out:Sewage heat recovery cost": 250448,
    "out:Balancing hydronic system cost": 128574.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 249959,
    "out:DH kr savings": 290168,
    "out:El kr savings": -40210,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 374354,
    "out:% savings (space heating)": 43.16,
    "out:Total_Water Heating (DH)": 190162,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 102.82,
    "out:Etvv": 21.25,
    "out:Ef": 20.5,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000000010000010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 77.4,
    "out:DHW (DH)": 43.5,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 14.33,
    "out:Total Energy Use Pre PV": 141.5,
    "out:Total Energy Use Post PV": 135.33,
    "out:Primary Energy": 97.48,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 24.51,
    "out:CO2 Operational/m2": 20.25,
    "out:Total CO2/m2": 44.76,
    "out:Total CO2 (tons)": 230.21,
    "out:Klimatpaverkan": -99.03,
    "out:Initial Cost/MSEK": 8.294,
    "out:Running cost/(Apt SEK y)": 41143,
    "out:Running Cost over RSP/MSEK": 13.525,
    "out:LCP/MSEK": -2.55,
    "out:ROI% old": 9.89,
    "out:Payback discounted": 12,
    "out:Atemp": 5143,
    "out:Umean": 0.49,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 135,
    "out:Energy use kWh/m2": 135,
    "out:Energy savings %": 39.3,
    "out:Op energy cost/MSEK": 100,
    "out:El price/MSEK": 59,
    "out:Heating price/MSEK": 35,
    "out:DH+ DHW/kWh/m2": 121,
    "out:El bought/kWh/m2": 1013.1,
    "out:Return %": 9,
    "out:Return/kSEK/y": 736,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 61.265983,
    "out:PV (m2 panels)": 321,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 57484.06,
    "out:PV (kWh sold)": 25763.95,
    "out:PV (% sold (El))": 44.82,
    "out:PV (kWh self-consumed)": 31720.11,
    "out:PV (ratio sold/self-consumed)": 0.81,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 875718.530813,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 262293,
    "out:EL kWh savings": 8067,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 285250,
    "out:DH kr savings": 238687,
    "out:El kr savings": 13715,
    "out:El kr sold": 32849,
    "out:El kr saved": 53924,
    "out:El kr return": 86773,
    "out:% solar/total": 39,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 398065,
    "out:% savings (space heating)": 39.56,
    "out:Total_Water Heating (DH)": 223720,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 107.43,
    "out:Etvv": 25,
    "out:Ef": 14.33,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000000010000110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 72.79,
    "out:DHW (DH)": 43.5,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 14.33,
    "out:Total Energy Use Pre PV": 136.5,
    "out:Total Energy Use Post PV": 130.33,
    "out:Primary Energy": 94.25,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 24.51,
    "out:CO2 Operational/m2": 18.45,
    "out:Total CO2/m2": 42.96,
    "out:Total CO2 (tons)": 220.95,
    "out:Klimatpaverkan": -108.29,
    "out:Initial Cost/MSEK": 8.4225,
    "out:Running cost/(Apt SEK y)": 39533,
    "out:Running Cost over RSP/MSEK": 12.996,
    "out:LCP/MSEK": -2.149,
    "out:ROI% old": 10.64,
    "out:Payback discounted": 11,
    "out:Atemp": 5143,
    "out:Umean": 0.49,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 130,
    "out:Energy use kWh/m2": 130,
    "out:Energy savings %": 44.6,
    "out:Op energy cost/MSEK": 96,
    "out:El price/MSEK": 57,
    "out:Heating price/MSEK": 33,
    "out:DH+ DHW/kWh/m2": 116,
    "out:El bought/kWh/m2": 1013.1,
    "out:Return %": 10,
    "out:Return/kSEK/y": 804,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 61.265983,
    "out:PV (m2 panels)": 321,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 57484.06,
    "out:PV (kWh sold)": 25763.95,
    "out:PV (% sold (El))": 44.82,
    "out:PV (kWh self-consumed)": 31720.11,
    "out:PV (ratio sold/self-consumed)": 0.81,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 875718.530813,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 288008,
    "out:EL kWh savings": 8067,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 128574.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 308651,
    "out:DH kr savings": 262087,
    "out:El kr savings": 13715,
    "out:El kr sold": 32849,
    "out:El kr saved": 53924,
    "out:El kr return": 86773,
    "out:% solar/total": 39,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 374354,
    "out:% savings (space heating)": 43.16,
    "out:Total_Water Heating (DH)": 223720,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 102.82,
    "out:Etvv": 25,
    "out:Ef": 14.33,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000000010001010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 77.4,
    "out:DHW (DH)": 36.98,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 14.33,
    "out:Total Energy Use Pre PV": 134.5,
    "out:Total Energy Use Post PV": 128.33,
    "out:Primary Energy": 94.85,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 24.91,
    "out:CO2 Operational/m2": 17.73,
    "out:Total CO2/m2": 42.64,
    "out:Total CO2 (tons)": 219.32,
    "out:Klimatpaverkan": -109.92,
    "out:Initial Cost/MSEK": 8.544375,
    "out:Running cost/(Apt SEK y)": 38890,
    "out:Running Cost over RSP/MSEK": 12.784,
    "out:LCP/MSEK": -2.059,
    "out:ROI% old": 10.84,
    "out:Payback discounted": 11,
    "out:Atemp": 5143,
    "out:Umean": 0.49,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 128,
    "out:Energy use kWh/m2": 128,
    "out:Energy savings %": 46.9,
    "out:Op energy cost/MSEK": 94,
    "out:El price/MSEK": 56,
    "out:Heating price/MSEK": 33,
    "out:DH+ DHW/kWh/m2": 114,
    "out:El bought/kWh/m2": 1013.1,
    "out:Return %": 10,
    "out:Return/kSEK/y": 831,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 61.265983,
    "out:PV (m2 panels)": 321,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 57484.06,
    "out:PV (kWh sold)": 25763.95,
    "out:PV (% sold (El))": 44.82,
    "out:PV (kWh self-consumed)": 31720.11,
    "out:PV (ratio sold/self-consumed)": 0.81,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 875718.530813,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 298294,
    "out:EL kWh savings": 8067,
    "out:Sewage heat recovery cost": 250448,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 318011,
    "out:DH kr savings": 271448,
    "out:El kr savings": 13715,
    "out:El kr sold": 32849,
    "out:El kr saved": 53924,
    "out:El kr return": 86773,
    "out:% solar/total": 39,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 398065,
    "out:% savings (space heating)": 39.56,
    "out:Total_Water Heating (DH)": 190162,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 107.43,
    "out:Etvv": 21.25,
    "out:Ef": 14.33,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000000010001110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 72.79,
    "out:DHW (DH)": 36.98,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 14.33,
    "out:Total Energy Use Pre PV": 130.5,
    "out:Total Energy Use Post PV": 124.33,
    "out:Primary Energy": 91.62,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 24.91,
    "out:CO2 Operational/m2": 16.29,
    "out:Total CO2/m2": 41.2,
    "out:Total CO2 (tons)": 211.91,
    "out:Klimatpaverkan": -117.33,
    "out:Initial Cost/MSEK": 8.673,
    "out:Running cost/(Apt SEK y)": 37602,
    "out:Running Cost over RSP/MSEK": 12.361,
    "out:LCP/MSEK": -1.765,
    "out:ROI% old": 11.38,
    "out:Payback discounted": 11,
    "out:Atemp": 5143,
    "out:Umean": 0.49,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 124,
    "out:Energy use kWh/m2": 124,
    "out:Energy savings %": 51.6,
    "out:Op energy cost/MSEK": 91,
    "out:El price/MSEK": 54,
    "out:Heating price/MSEK": 32,
    "out:DH+ DHW/kWh/m2": 110,
    "out:El bought/kWh/m2": 1013.1,
    "out:Return %": 10,
    "out:Return/kSEK/y": 885,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 61.265983,
    "out:PV (m2 panels)": 321,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 57484.06,
    "out:PV (kWh sold)": 25763.95,
    "out:PV (% sold (El))": 44.82,
    "out:PV (kWh self-consumed)": 31720.11,
    "out:PV (ratio sold/self-consumed)": 0.81,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 875718.530813,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 318866,
    "out:EL kWh savings": 8067,
    "out:Sewage heat recovery cost": 250448,
    "out:Balancing hydronic system cost": 128574.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 336732,
    "out:DH kr savings": 290168,
    "out:El kr savings": 13715,
    "out:El kr sold": 32849,
    "out:El kr saved": 53924,
    "out:El kr return": 86773,
    "out:% solar/total": 39,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 374354,
    "out:% savings (space heating)": 43.16,
    "out:Total_Water Heating (DH)": 190162,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 102.82,
    "out:Etvv": 21.25,
    "out:Ef": 14.33,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000000020000010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 77.4,
    "out:DHW (DH)": 43.5,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 13.09,
    "out:Total Energy Use Pre PV": 141.5,
    "out:Total Energy Use Post PV": 134.09,
    "out:Primary Energy": 95.24,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 36.87,
    "out:CO2 Operational/m2": -30.43,
    "out:Total CO2/m2": 6.43,
    "out:Total CO2 (tons)": 33.09,
    "out:Klimatpaverkan": -296.15,
    "out:Initial Cost/MSEK": 9.36375,
    "out:Running cost/(Apt SEK y)": 39333,
    "out:Running Cost over RSP/MSEK": 12.946,
    "out:LCP/MSEK": -3.041,
    "out:ROI% old": 9.65,
    "out:Payback discounted": 13,
    "out:Atemp": 5143,
    "out:Umean": 0.49,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 134,
    "out:Energy use kWh/m2": 134,
    "out:Energy savings %": 40.3,
    "out:Op energy cost/MSEK": 96,
    "out:El price/MSEK": 56,
    "out:Heating price/MSEK": 35,
    "out:DH+ DHW/kWh/m2": 121,
    "out:El bought/kWh/m2": 923.7,
    "out:Return %": 9,
    "out:Return/kSEK/y": 812,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.33,
    "out:PV (kWp installed)": 122.531965,
    "out:PV (m2 panels)": 643,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 114968.11,
    "out:PV (kWh sold)": 76862.14,
    "out:PV (% sold (El))": 66.86,
    "out:PV (kWh self-consumed)": 38105.97,
    "out:PV (ratio sold/self-consumed)": 2.02,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 875718.530813,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 262293,
    "out:EL kWh savings": 14453,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 361256,
    "out:DH kr savings": 238687,
    "out:El kr savings": 24571,
    "out:El kr sold": 97999,
    "out:El kr saved": 64780,
    "out:El kr return": 162779,
    "out:% solar/total": 47,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 398065,
    "out:% savings (space heating)": 39.56,
    "out:Total_Water Heating (DH)": 223720,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 107.43,
    "out:Etvv": 25,
    "out:Ef": 13.09,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000000020000110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 72.79,
    "out:DHW (DH)": 43.5,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 13.09,
    "out:Total Energy Use Pre PV": 136.5,
    "out:Total Energy Use Post PV": 129.09,
    "out:Primary Energy": 92.01,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 36.87,
    "out:CO2 Operational/m2": -32.23,
    "out:Total CO2/m2": 4.63,
    "out:Total CO2 (tons)": 23.83,
    "out:Klimatpaverkan": -305.41,
    "out:Initial Cost/MSEK": 9.49225,
    "out:Running cost/(Apt SEK y)": 37724,
    "out:Running Cost over RSP/MSEK": 12.418,
    "out:LCP/MSEK": -2.641,
    "out:ROI% old": 10.31,
    "out:Payback discounted": 12,
    "out:Atemp": 5143,
    "out:Umean": 0.49,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 129,
    "out:Energy use kWh/m2": 129,
    "out:Energy savings %": 45.7,
    "out:Op energy cost/MSEK": 92,
    "out:El price/MSEK": 53,
    "out:Heating price/MSEK": 33,
    "out:DH+ DHW/kWh/m2": 116,
    "out:El bought/kWh/m2": 923.7,
    "out:Return %": 9,
    "out:Return/kSEK/y": 880,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.33,
    "out:PV (kWp installed)": 122.531965,
    "out:PV (m2 panels)": 643,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 114968.11,
    "out:PV (kWh sold)": 76862.14,
    "out:PV (% sold (El))": 66.86,
    "out:PV (kWh self-consumed)": 38105.97,
    "out:PV (ratio sold/self-consumed)": 2.02,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 875718.530813,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 288008,
    "out:EL kWh savings": 14453,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 128574.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 384657,
    "out:DH kr savings": 262087,
    "out:El kr savings": 24571,
    "out:El kr sold": 97999,
    "out:El kr saved": 64780,
    "out:El kr return": 162779,
    "out:% solar/total": 47,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 374354,
    "out:% savings (space heating)": 43.16,
    "out:Total_Water Heating (DH)": 223720,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 102.82,
    "out:Etvv": 25,
    "out:Ef": 13.09,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000000020001010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 77.4,
    "out:DHW (DH)": 36.98,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 13.09,
    "out:Total Energy Use Pre PV": 134.5,
    "out:Total Energy Use Post PV": 127.09,
    "out:Primary Energy": 92.62,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 37.27,
    "out:CO2 Operational/m2": -32.95,
    "out:Total CO2/m2": 4.32,
    "out:Total CO2 (tons)": 22.2,
    "out:Klimatpaverkan": -307.04,
    "out:Initial Cost/MSEK": 9.614125,
    "out:Running cost/(Apt SEK y)": 37080,
    "out:Running Cost over RSP/MSEK": 12.206,
    "out:LCP/MSEK": -2.551,
    "out:ROI% old": 10.5,
    "out:Payback discounted": 12,
    "out:Atemp": 5143,
    "out:Umean": 0.49,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 127,
    "out:Energy use kWh/m2": 127,
    "out:Energy savings %": 48,
    "out:Op energy cost/MSEK": 91,
    "out:El price/MSEK": 53,
    "out:Heating price/MSEK": 33,
    "out:DH+ DHW/kWh/m2": 114,
    "out:El bought/kWh/m2": 923.7,
    "out:Return %": 9,
    "out:Return/kSEK/y": 907,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.33,
    "out:PV (kWp installed)": 122.531965,
    "out:PV (m2 panels)": 643,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 114968.11,
    "out:PV (kWh sold)": 76862.14,
    "out:PV (% sold (El))": 66.86,
    "out:PV (kWh self-consumed)": 38105.97,
    "out:PV (ratio sold/self-consumed)": 2.02,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 875718.530813,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 298294,
    "out:EL kWh savings": 14453,
    "out:Sewage heat recovery cost": 250448,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 394017,
    "out:DH kr savings": 271448,
    "out:El kr savings": 24571,
    "out:El kr sold": 97999,
    "out:El kr saved": 64780,
    "out:El kr return": 162779,
    "out:% solar/total": 47,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 398065,
    "out:% savings (space heating)": 39.56,
    "out:Total_Water Heating (DH)": 190162,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 107.43,
    "out:Etvv": 21.25,
    "out:Ef": 13.09,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000000020001110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 72.79,
    "out:DHW (DH)": 36.98,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 13.09,
    "out:Total Energy Use Pre PV": 130.5,
    "out:Total Energy Use Post PV": 123.09,
    "out:Primary Energy": 89.39,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 37.27,
    "out:CO2 Operational/m2": -34.39,
    "out:Total CO2/m2": 2.88,
    "out:Total CO2 (tons)": 14.79,
    "out:Klimatpaverkan": -314.45,
    "out:Initial Cost/MSEK": 9.74275,
    "out:Running cost/(Apt SEK y)": 35793,
    "out:Running Cost over RSP/MSEK": 11.783,
    "out:LCP/MSEK": -2.257,
    "out:ROI% old": 10.98,
    "out:Payback discounted": 11,
    "out:Atemp": 5143,
    "out:Umean": 0.49,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 123,
    "out:Energy use kWh/m2": 123,
    "out:Energy savings %": 52.8,
    "out:Op energy cost/MSEK": 87,
    "out:El price/MSEK": 51,
    "out:Heating price/MSEK": 32,
    "out:DH+ DHW/kWh/m2": 110,
    "out:El bought/kWh/m2": 923.7,
    "out:Return %": 10,
    "out:Return/kSEK/y": 961,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.33,
    "out:PV (kWp installed)": 122.531965,
    "out:PV (m2 panels)": 643,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 114968.11,
    "out:PV (kWh sold)": 76862.14,
    "out:PV (% sold (El))": 66.86,
    "out:PV (kWh self-consumed)": 38105.97,
    "out:PV (ratio sold/self-consumed)": 2.02,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 875718.530813,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 318866,
    "out:EL kWh savings": 14453,
    "out:Sewage heat recovery cost": 250448,
    "out:Balancing hydronic system cost": 128574.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 412738,
    "out:DH kr savings": 290168,
    "out:El kr savings": 24571,
    "out:El kr sold": 97999,
    "out:El kr saved": 64780,
    "out:El kr return": 162779,
    "out:% solar/total": 47,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 374354,
    "out:% savings (space heating)": 43.16,
    "out:Total_Water Heating (DH)": 190162,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 102.82,
    "out:Etvv": 21.25,
    "out:Ef": 13.09,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000000100000010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 77.4,
    "out:DHW (DH)": 34.8,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 20.5,
    "out:Total Energy Use Pre PV": 132.5,
    "out:Total Energy Use Post PV": 132.5,
    "out:Primary Energy": 105.08,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 12.16,
    "out:CO2 Operational/m2": 43.02,
    "out:Total CO2/m2": 55.18,
    "out:Total CO2 (tons)": 283.81,
    "out:Klimatpaverkan": -45.43,
    "out:Initial Cost/MSEK": 7.335,
    "out:Running cost/(Apt SEK y)": 40312,
    "out:Running Cost over RSP/MSEK": 13.233,
    "out:LCP/MSEK": -1.299,
    "out:ROI% old": 11.76,
    "out:Payback discounted": 10,
    "out:Atemp": 5143,
    "out:Umean": 0.49,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 132,
    "out:Energy use kWh/m2": 132,
    "out:Energy savings %": 42.4,
    "out:Op energy cost/MSEK": 97,
    "out:El price/MSEK": 59,
    "out:Heating price/MSEK": 32,
    "out:DH+ DHW/kWh/m2": 112,
    "out:El bought/kWh/m2": 1434.9,
    "out:Return %": 11,
    "out:Return/kSEK/y": 771,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 875718.530813,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 308580,
    "out:EL kWh savings": -23653,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 240598,
    "out:DH kr savings": 280808,
    "out:El kr savings": -40210,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 398065,
    "out:% savings (space heating)": 39.56,
    "out:Total_Water Heating (DH)": 178976,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 107.43,
    "out:Etvv": 20,
    "out:Ef": 20.5,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000000100000110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 72.79,
    "out:DHW (DH)": 34.8,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 20.5,
    "out:Total Energy Use Pre PV": 128.5,
    "out:Total Energy Use Post PV": 128.5,
    "out:Primary Energy": 101.85,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 12.16,
    "out:CO2 Operational/m2": 41.58,
    "out:Total CO2/m2": 53.74,
    "out:Total CO2 (tons)": 276.4,
    "out:Klimatpaverkan": -52.84,
    "out:Initial Cost/MSEK": 7.463625,
    "out:Running cost/(Apt SEK y)": 39025,
    "out:Running Cost over RSP/MSEK": 12.81,
    "out:LCP/MSEK": -1.004,
    "out:ROI% old": 12.36,
    "out:Payback discounted": 10,
    "out:Atemp": 5143,
    "out:Umean": 0.49,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 128,
    "out:Energy use kWh/m2": 128,
    "out:Energy savings %": 46.9,
    "out:Op energy cost/MSEK": 94,
    "out:El price/MSEK": 57,
    "out:Heating price/MSEK": 31,
    "out:DH+ DHW/kWh/m2": 108,
    "out:El bought/kWh/m2": 1434.9,
    "out:Return %": 11,
    "out:Return/kSEK/y": 825,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 875718.530813,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 329152,
    "out:EL kWh savings": -23653,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 128574.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 259319,
    "out:DH kr savings": 299528,
    "out:El kr savings": -40210,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 374354,
    "out:% savings (space heating)": 43.16,
    "out:Total_Water Heating (DH)": 178976,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 102.82,
    "out:Etvv": 20,
    "out:Ef": 20.5,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000000100001010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 77.4,
    "out:DHW (DH)": 29.58,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 20.5,
    "out:Total Energy Use Pre PV": 127.5,
    "out:Total Energy Use Post PV": 127.5,
    "out:Primary Energy": 102.98,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 12.56,
    "out:CO2 Operational/m2": 41.22,
    "out:Total CO2/m2": 53.79,
    "out:Total CO2 (tons)": 276.62,
    "out:Klimatpaverkan": -52.62,
    "out:Initial Cost/MSEK": 7.5855,
    "out:Running cost/(Apt SEK y)": 38703,
    "out:Running Cost over RSP/MSEK": 12.704,
    "out:LCP/MSEK": -1.021,
    "out:ROI% old": 12.36,
    "out:Payback discounted": 10,
    "out:Atemp": 5143,
    "out:Umean": 0.49,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 127,
    "out:Energy use kWh/m2": 127,
    "out:Energy savings %": 48,
    "out:Op energy cost/MSEK": 93,
    "out:El price/MSEK": 57,
    "out:Heating price/MSEK": 31,
    "out:DH+ DHW/kWh/m2": 107,
    "out:El bought/kWh/m2": 1434.9,
    "out:Return %": 11,
    "out:Return/kSEK/y": 838,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 875718.530813,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 334295,
    "out:EL kWh savings": -23653,
    "out:Sewage heat recovery cost": 250448,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 263999,
    "out:DH kr savings": 304208,
    "out:El kr savings": -40210,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 398065,
    "out:% savings (space heating)": 39.56,
    "out:Total_Water Heating (DH)": 152130,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 107.43,
    "out:Etvv": 17,
    "out:Ef": 20.5,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000000100001110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 72.79,
    "out:DHW (DH)": 29.58,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 20.5,
    "out:Total Energy Use Pre PV": 122.5,
    "out:Total Energy Use Post PV": 122.5,
    "out:Primary Energy": 99.75,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 12.56,
    "out:CO2 Operational/m2": 39.42,
    "out:Total CO2/m2": 51.99,
    "out:Total CO2 (tons)": 267.36,
    "out:Klimatpaverkan": -61.88,
    "out:Initial Cost/MSEK": 7.714,
    "out:Running cost/(Apt SEK y)": 37094,
    "out:Running Cost over RSP/MSEK": 12.175,
    "out:LCP/MSEK": -0.62,
    "out:ROI% old": 13.14,
    "out:Payback discounted": 9,
    "out:Atemp": 5143,
    "out:Umean": 0.49,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 122,
    "out:Energy use kWh/m2": 122,
    "out:Energy savings %": 54.1,
    "out:Op energy cost/MSEK": 89,
    "out:El price/MSEK": 55,
    "out:Heating price/MSEK": 29,
    "out:DH+ DHW/kWh/m2": 102,
    "out:El bought/kWh/m2": 1434.9,
    "out:Return %": 12,
    "out:Return/kSEK/y": 906,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 875718.530813,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 360010,
    "out:EL kWh savings": -23653,
    "out:Sewage heat recovery cost": 250448,
    "out:Balancing hydronic system cost": 128574.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 287400,
    "out:DH kr savings": 327609,
    "out:El kr savings": -40210,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 374354,
    "out:% savings (space heating)": 43.16,
    "out:Total_Water Heating (DH)": 152130,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 102.82,
    "out:Etvv": 17,
    "out:Ef": 20.5,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000000110000010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 77.4,
    "out:DHW (DH)": 34.8,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 14.33,
    "out:Total Energy Use Pre PV": 132.5,
    "out:Total Energy Use Post PV": 126.33,
    "out:Primary Energy": 93.98,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 24.51,
    "out:CO2 Operational/m2": 17.01,
    "out:Total CO2/m2": 41.52,
    "out:Total CO2 (tons)": 213.55,
    "out:Klimatpaverkan": -115.69,
    "out:Initial Cost/MSEK": 8.40475,
    "out:Running cost/(Apt SEK y)": 38246,
    "out:Running Cost over RSP/MSEK": 12.573,
    "out:LCP/MSEK": -1.708,
    "out:ROI% old": 11.38,
    "out:Payback discounted": 11,
    "out:Atemp": 5143,
    "out:Umean": 0.49,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 126,
    "out:Energy use kWh/m2": 126,
    "out:Energy savings %": 49.2,
    "out:Op energy cost/MSEK": 93,
    "out:El price/MSEK": 55,
    "out:Heating price/MSEK": 32,
    "out:DH+ DHW/kWh/m2": 112,
    "out:El bought/kWh/m2": 1013.1,
    "out:Return %": 10,
    "out:Return/kSEK/y": 858,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 61.265983,
    "out:PV (m2 panels)": 321,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 57484.06,
    "out:PV (kWh sold)": 25763.95,
    "out:PV (% sold (El))": 44.82,
    "out:PV (kWh self-consumed)": 31720.11,
    "out:PV (ratio sold/self-consumed)": 0.81,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 875718.530813,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 308580,
    "out:EL kWh savings": 8067,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 327371,
    "out:DH kr savings": 280808,
    "out:El kr savings": 13715,
    "out:El kr sold": 32849,
    "out:El kr saved": 53924,
    "out:El kr return": 86773,
    "out:% solar/total": 39,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 398065,
    "out:% savings (space heating)": 39.56,
    "out:Total_Water Heating (DH)": 178976,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 107.43,
    "out:Etvv": 20,
    "out:Ef": 14.33,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000000110000110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 72.79,
    "out:DHW (DH)": 34.8,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 14.33,
    "out:Total Energy Use Pre PV": 128.5,
    "out:Total Energy Use Post PV": 122.33,
    "out:Primary Energy": 90.75,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 24.51,
    "out:CO2 Operational/m2": 15.57,
    "out:Total CO2/m2": 40.08,
    "out:Total CO2 (tons)": 206.14,
    "out:Klimatpaverkan": -123.1,
    "out:Initial Cost/MSEK": 8.533375,
    "out:Running cost/(Apt SEK y)": 36959,
    "out:Running Cost over RSP/MSEK": 12.149,
    "out:LCP/MSEK": -1.414,
    "out:ROI% old": 11.92,
    "out:Payback discounted": 10,
    "out:Atemp": 5143,
    "out:Umean": 0.49,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 122,
    "out:Energy use kWh/m2": 122,
    "out:Energy savings %": 54.1,
    "out:Op energy cost/MSEK": 89,
    "out:El price/MSEK": 53,
    "out:Heating price/MSEK": 31,
    "out:DH+ DHW/kWh/m2": 108,
    "out:El bought/kWh/m2": 1013.1,
    "out:Return %": 11,
    "out:Return/kSEK/y": 912,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 61.265983,
    "out:PV (m2 panels)": 321,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 57484.06,
    "out:PV (kWh sold)": 25763.95,
    "out:PV (% sold (El))": 44.82,
    "out:PV (kWh self-consumed)": 31720.11,
    "out:PV (ratio sold/self-consumed)": 0.81,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 875718.530813,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 329152,
    "out:EL kWh savings": 8067,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 128574.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 346092,
    "out:DH kr savings": 299528,
    "out:El kr savings": 13715,
    "out:El kr sold": 32849,
    "out:El kr saved": 53924,
    "out:El kr return": 86773,
    "out:% solar/total": 39,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 374354,
    "out:% savings (space heating)": 43.16,
    "out:Total_Water Heating (DH)": 178976,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 102.82,
    "out:Etvv": 20,
    "out:Ef": 14.33,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000000110001010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 77.4,
    "out:DHW (DH)": 29.58,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 14.33,
    "out:Total Energy Use Pre PV": 127.5,
    "out:Total Energy Use Post PV": 121.33,
    "out:Primary Energy": 91.88,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 24.91,
    "out:CO2 Operational/m2": 15.21,
    "out:Total CO2/m2": 40.12,
    "out:Total CO2 (tons)": 206.36,
    "out:Klimatpaverkan": -122.88,
    "out:Initial Cost/MSEK": 8.65525,
    "out:Running cost/(Apt SEK y)": 36637,
    "out:Running Cost over RSP/MSEK": 12.044,
    "out:LCP/MSEK": -1.43,
    "out:ROI% old": 11.93,
    "out:Payback discounted": 10,
    "out:Atemp": 5143,
    "out:Umean": 0.49,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 121,
    "out:Energy use kWh/m2": 121,
    "out:Energy savings %": 55.4,
    "out:Op energy cost/MSEK": 89,
    "out:El price/MSEK": 53,
    "out:Heating price/MSEK": 31,
    "out:DH+ DHW/kWh/m2": 107,
    "out:El bought/kWh/m2": 1013.1,
    "out:Return %": 11,
    "out:Return/kSEK/y": 925,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 61.265983,
    "out:PV (m2 panels)": 321,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 57484.06,
    "out:PV (kWh sold)": 25763.95,
    "out:PV (% sold (El))": 44.82,
    "out:PV (kWh self-consumed)": 31720.11,
    "out:PV (ratio sold/self-consumed)": 0.81,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 875718.530813,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 334295,
    "out:EL kWh savings": 8067,
    "out:Sewage heat recovery cost": 250448,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 350772,
    "out:DH kr savings": 304208,
    "out:El kr savings": 13715,
    "out:El kr sold": 32849,
    "out:El kr saved": 53924,
    "out:El kr return": 86773,
    "out:% solar/total": 39,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 398065,
    "out:% savings (space heating)": 39.56,
    "out:Total_Water Heating (DH)": 152130,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 107.43,
    "out:Etvv": 17,
    "out:Ef": 14.33,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000000110001110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 72.79,
    "out:DHW (DH)": 29.58,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 14.33,
    "out:Total Energy Use Pre PV": 122.5,
    "out:Total Energy Use Post PV": 116.33,
    "out:Primary Energy": 88.65,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 24.91,
    "out:CO2 Operational/m2": 13.41,
    "out:Total CO2/m2": 38.32,
    "out:Total CO2 (tons)": 197.1,
    "out:Klimatpaverkan": -132.14,
    "out:Initial Cost/MSEK": 8.783875,
    "out:Running cost/(Apt SEK y)": 35028,
    "out:Running Cost over RSP/MSEK": 11.515,
    "out:LCP/MSEK": -1.03,
    "out:ROI% old": 12.61,
    "out:Payback discounted": 10,
    "out:Atemp": 5143,
    "out:Umean": 0.49,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 116,
    "out:Energy use kWh/m2": 116,
    "out:Energy savings %": 62.1,
    "out:Op energy cost/MSEK": 85,
    "out:El price/MSEK": 51,
    "out:Heating price/MSEK": 29,
    "out:DH+ DHW/kWh/m2": 102,
    "out:El bought/kWh/m2": 1013.1,
    "out:Return %": 11,
    "out:Return/kSEK/y": 993,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 61.265983,
    "out:PV (m2 panels)": 321,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 57484.06,
    "out:PV (kWh sold)": 25763.95,
    "out:PV (% sold (El))": 44.82,
    "out:PV (kWh self-consumed)": 31720.11,
    "out:PV (ratio sold/self-consumed)": 0.81,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 875718.530813,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 360010,
    "out:EL kWh savings": 8067,
    "out:Sewage heat recovery cost": 250448,
    "out:Balancing hydronic system cost": 128574.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 374173,
    "out:DH kr savings": 327609,
    "out:El kr savings": 13715,
    "out:El kr sold": 32849,
    "out:El kr saved": 53924,
    "out:El kr return": 86773,
    "out:% solar/total": 39,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 374354,
    "out:% savings (space heating)": 43.16,
    "out:Total_Water Heating (DH)": 152130,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 102.82,
    "out:Etvv": 17,
    "out:Ef": 14.33,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000000120000010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 77.4,
    "out:DHW (DH)": 34.8,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 13.09,
    "out:Total Energy Use Pre PV": 132.5,
    "out:Total Energy Use Post PV": 125.09,
    "out:Primary Energy": 91.74,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 36.87,
    "out:CO2 Operational/m2": -33.67,
    "out:Total CO2/m2": 3.19,
    "out:Total CO2 (tons)": 16.43,
    "out:Klimatpaverkan": -312.81,
    "out:Initial Cost/MSEK": 9.474625,
    "out:Running cost/(Apt SEK y)": 36436,
    "out:Running Cost over RSP/MSEK": 11.994,
    "out:LCP/MSEK": -2.2,
    "out:ROI% old": 10.97,
    "out:Payback discounted": 11,
    "out:Atemp": 5143,
    "out:Umean": 0.49,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 125,
    "out:Energy use kWh/m2": 125,
    "out:Energy savings %": 50.4,
    "out:Op energy cost/MSEK": 89,
    "out:El price/MSEK": 52,
    "out:Heating price/MSEK": 32,
    "out:DH+ DHW/kWh/m2": 112,
    "out:El bought/kWh/m2": 923.7,
    "out:Return %": 10,
    "out:Return/kSEK/y": 934,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.33,
    "out:PV (kWp installed)": 122.531965,
    "out:PV (m2 panels)": 643,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 114968.11,
    "out:PV (kWh sold)": 76862.14,
    "out:PV (% sold (El))": 66.86,
    "out:PV (kWh self-consumed)": 38105.97,
    "out:PV (ratio sold/self-consumed)": 2.02,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 875718.530813,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 308580,
    "out:EL kWh savings": 14453,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 403378,
    "out:DH kr savings": 280808,
    "out:El kr savings": 24571,
    "out:El kr sold": 97999,
    "out:El kr saved": 64780,
    "out:El kr return": 162779,
    "out:% solar/total": 47,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 398065,
    "out:% savings (space heating)": 39.56,
    "out:Total_Water Heating (DH)": 178976,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 107.43,
    "out:Etvv": 20,
    "out:Ef": 13.09,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000000120000110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 72.79,
    "out:DHW (DH)": 34.8,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 13.09,
    "out:Total Energy Use Pre PV": 128.5,
    "out:Total Energy Use Post PV": 121.09,
    "out:Primary Energy": 88.51,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 36.87,
    "out:CO2 Operational/m2": -35.11,
    "out:Total CO2/m2": 1.75,
    "out:Total CO2 (tons)": 9.02,
    "out:Klimatpaverkan": -320.22,
    "out:Initial Cost/MSEK": 9.603125,
    "out:Running cost/(Apt SEK y)": 35149,
    "out:Running Cost over RSP/MSEK": 11.571,
    "out:LCP/MSEK": -1.905,
    "out:ROI% old": 11.45,
    "out:Payback discounted": 11,
    "out:Atemp": 5143,
    "out:Umean": 0.49,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 121,
    "out:Energy use kWh/m2": 121,
    "out:Energy savings %": 55.4,
    "out:Op energy cost/MSEK": 86,
    "out:El price/MSEK": 50,
    "out:Heating price/MSEK": 31,
    "out:DH+ DHW/kWh/m2": 108,
    "out:El bought/kWh/m2": 923.7,
    "out:Return %": 10,
    "out:Return/kSEK/y": 988,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.33,
    "out:PV (kWp installed)": 122.531965,
    "out:PV (m2 panels)": 643,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 114968.11,
    "out:PV (kWh sold)": 76862.14,
    "out:PV (% sold (El))": 66.86,
    "out:PV (kWh self-consumed)": 38105.97,
    "out:PV (ratio sold/self-consumed)": 2.02,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 875718.530813,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 329152,
    "out:EL kWh savings": 14453,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 128574.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 422098,
    "out:DH kr savings": 299528,
    "out:El kr savings": 24571,
    "out:El kr sold": 97999,
    "out:El kr saved": 64780,
    "out:El kr return": 162779,
    "out:% solar/total": 47,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 374354,
    "out:% savings (space heating)": 43.16,
    "out:Total_Water Heating (DH)": 178976,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 102.82,
    "out:Etvv": 20,
    "out:Ef": 13.09,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000000120001010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 77.4,
    "out:DHW (DH)": 29.58,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 13.09,
    "out:Total Energy Use Pre PV": 127.5,
    "out:Total Energy Use Post PV": 120.09,
    "out:Primary Energy": 89.64,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 37.27,
    "out:CO2 Operational/m2": -35.47,
    "out:Total CO2/m2": 1.8,
    "out:Total CO2 (tons)": 9.24,
    "out:Klimatpaverkan": -320,
    "out:Initial Cost/MSEK": 9.725,
    "out:Running cost/(Apt SEK y)": 34827,
    "out:Running Cost over RSP/MSEK": 11.466,
    "out:LCP/MSEK": -1.922,
    "out:ROI% old": 11.46,
    "out:Payback discounted": 11,
    "out:Atemp": 5143,
    "out:Umean": 0.49,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 120,
    "out:Energy use kWh/m2": 120,
    "out:Energy savings %": 56.7,
    "out:Op energy cost/MSEK": 85,
    "out:El price/MSEK": 49,
    "out:Heating price/MSEK": 31,
    "out:DH+ DHW/kWh/m2": 107,
    "out:El bought/kWh/m2": 923.7,
    "out:Return %": 10,
    "out:Return/kSEK/y": 1001,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.33,
    "out:PV (kWp installed)": 122.531965,
    "out:PV (m2 panels)": 643,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 114968.11,
    "out:PV (kWh sold)": 76862.14,
    "out:PV (% sold (El))": 66.86,
    "out:PV (kWh self-consumed)": 38105.97,
    "out:PV (ratio sold/self-consumed)": 2.02,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 875718.530813,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 334295,
    "out:EL kWh savings": 14453,
    "out:Sewage heat recovery cost": 250448,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 426778,
    "out:DH kr savings": 304208,
    "out:El kr savings": 24571,
    "out:El kr sold": 97999,
    "out:El kr saved": 64780,
    "out:El kr return": 162779,
    "out:% solar/total": 47,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 398065,
    "out:% savings (space heating)": 39.56,
    "out:Total_Water Heating (DH)": 152130,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 107.43,
    "out:Etvv": 17,
    "out:Ef": 13.09,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000000120001110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 72.79,
    "out:DHW (DH)": 29.58,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 13.09,
    "out:Total Energy Use Pre PV": 122.5,
    "out:Total Energy Use Post PV": 115.09,
    "out:Primary Energy": 86.41,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 37.27,
    "out:CO2 Operational/m2": -37.27,
    "out:Total CO2/m2": 0,
    "out:Total CO2 (tons)": -0.02,
    "out:Klimatpaverkan": -329.26,
    "out:Initial Cost/MSEK": 9.853625,
    "out:Running cost/(Apt SEK y)": 33218,
    "out:Running Cost over RSP/MSEK": 10.937,
    "out:LCP/MSEK": -1.521,
    "out:ROI% old": 12.08,
    "out:Payback discounted": 10,
    "out:Atemp": 5143,
    "out:Umean": 0.49,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 115,
    "out:Energy use kWh/m2": 115,
    "out:Energy savings %": 63.5,
    "out:Op energy cost/MSEK": 81,
    "out:El price/MSEK": 47,
    "out:Heating price/MSEK": 29,
    "out:DH+ DHW/kWh/m2": 102,
    "out:El bought/kWh/m2": 923.7,
    "out:Return %": 11,
    "out:Return/kSEK/y": 1069,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.33,
    "out:PV (kWp installed)": 122.531965,
    "out:PV (m2 panels)": 643,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 114968.11,
    "out:PV (kWh sold)": 76862.14,
    "out:PV (% sold (El))": 66.86,
    "out:PV (kWh self-consumed)": 38105.97,
    "out:PV (ratio sold/self-consumed)": 2.02,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 875718.530813,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 360010,
    "out:EL kWh savings": 14453,
    "out:Sewage heat recovery cost": 250448,
    "out:Balancing hydronic system cost": 128574.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 450179,
    "out:DH kr savings": 327609,
    "out:El kr savings": 24571,
    "out:El kr sold": 97999,
    "out:El kr saved": 64780,
    "out:El kr return": 162779,
    "out:% solar/total": 47,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 374354,
    "out:% savings (space heating)": 43.16,
    "out:Total_Water Heating (DH)": 152130,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 102.82,
    "out:Etvv": 17,
    "out:Ef": 13.09,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000100000000010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 65.94,
    "out:DHW (DH)": 43.5,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 20.5,
    "out:Total Energy Use Pre PV": 129.5,
    "out:Total Energy Use Post PV": 129.5,
    "out:Primary Energy": 100.56,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 14.18,
    "out:CO2 Operational/m2": 41.94,
    "out:Total CO2/m2": 56.12,
    "out:Total CO2 (tons)": 288.63,
    "out:Klimatpaverkan": -40.61,
    "out:Initial Cost/MSEK": 8.045625,
    "out:Running cost/(Apt SEK y)": 39347,
    "out:Running Cost over RSP/MSEK": 12.916,
    "out:LCP/MSEK": -1.692,
    "out:ROI% old": 11.28,
    "out:Payback discounted": 11,
    "out:Atemp": 5143,
    "out:Umean": 0.34,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FTX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 129,
    "out:Energy use kWh/m2": 129,
    "out:Energy savings %": 45.7,
    "out:Op energy cost/MSEK": 94,
    "out:El price/MSEK": 58,
    "out:Heating price/MSEK": 31,
    "out:DH+ DHW/kWh/m2": 109,
    "out:El bought/kWh/m2": 1434.9,
    "out:Return %": 10,
    "out:Return/kSEK/y": 811,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 875718.530813,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 324009,
    "out:EL kWh savings": -23653,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 254639,
    "out:DH kr savings": 294848,
    "out:El kr savings": -40210,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 339155,
    "out:% savings (space heating)": 48.5,
    "out:Total_Water Heating (DH)": 223720,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 95.97,
    "out:Etvv": 25,
    "out:Ef": 20.5,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000100000000110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 61.86,
    "out:DHW (DH)": 43.5,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 20.5,
    "out:Total Energy Use Pre PV": 125.5,
    "out:Total Energy Use Post PV": 125.5,
    "out:Primary Energy": 97.7,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 14.18,
    "out:CO2 Operational/m2": 40.5,
    "out:Total CO2/m2": 54.68,
    "out:Total CO2 (tons)": 281.23,
    "out:Klimatpaverkan": -48.01,
    "out:Initial Cost/MSEK": 8.17425,
    "out:Running cost/(Apt SEK y)": 38059,
    "out:Running Cost over RSP/MSEK": 12.493,
    "out:LCP/MSEK": -1.398,
    "out:ROI% old": 11.84,
    "out:Payback discounted": 10,
    "out:Atemp": 5143,
    "out:Umean": 0.34,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FTX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 125,
    "out:Energy use kWh/m2": 125,
    "out:Energy savings %": 50.4,
    "out:Op energy cost/MSEK": 91,
    "out:El price/MSEK": 56,
    "out:Heating price/MSEK": 30,
    "out:DH+ DHW/kWh/m2": 105,
    "out:El bought/kWh/m2": 1434.9,
    "out:Return %": 11,
    "out:Return/kSEK/y": 865,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 875718.530813,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 344581,
    "out:EL kWh savings": -23653,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 128574.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 273359,
    "out:DH kr savings": 313569,
    "out:El kr savings": -40210,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 318122,
    "out:% savings (space heating)": 51.7,
    "out:Total_Water Heating (DH)": 223720,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 91.88,
    "out:Etvv": 25,
    "out:Ef": 20.5,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000100000001010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 65.94,
    "out:DHW (DH)": 36.98,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 20.5,
    "out:Total Energy Use Pre PV": 123.5,
    "out:Total Energy Use Post PV": 123.5,
    "out:Primary Energy": 97.93,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 14.58,
    "out:CO2 Operational/m2": 39.78,
    "out:Total CO2/m2": 54.36,
    "out:Total CO2 (tons)": 279.59,
    "out:Klimatpaverkan": -49.65,
    "out:Initial Cost/MSEK": 8.296125,
    "out:Running cost/(Apt SEK y)": 37416,
    "out:Running Cost over RSP/MSEK": 12.281,
    "out:LCP/MSEK": -1.308,
    "out:ROI% old": 12.03,
    "out:Payback discounted": 10,
    "out:Atemp": 5143,
    "out:Umean": 0.34,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FTX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 123,
    "out:Energy use kWh/m2": 123,
    "out:Energy savings %": 52.8,
    "out:Op energy cost/MSEK": 90,
    "out:El price/MSEK": 55,
    "out:Heating price/MSEK": 30,
    "out:DH+ DHW/kWh/m2": 103,
    "out:El bought/kWh/m2": 1434.9,
    "out:Return %": 11,
    "out:Return/kSEK/y": 892,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 875718.530813,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 354867,
    "out:EL kWh savings": -23653,
    "out:Sewage heat recovery cost": 250448,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 282719,
    "out:DH kr savings": 322929,
    "out:El kr savings": -40210,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 339155,
    "out:% savings (space heating)": 48.5,
    "out:Total_Water Heating (DH)": 190162,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 95.97,
    "out:Etvv": 21.25,
    "out:Ef": 20.5,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000100000001110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 61.86,
    "out:DHW (DH)": 36.98,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 20.5,
    "out:Total Energy Use Pre PV": 119.5,
    "out:Total Energy Use Post PV": 119.5,
    "out:Primary Energy": 95.07,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 14.58,
    "out:CO2 Operational/m2": 38.34,
    "out:Total CO2/m2": 52.92,
    "out:Total CO2 (tons)": 272.19,
    "out:Klimatpaverkan": -57.05,
    "out:Initial Cost/MSEK": 8.424625,
    "out:Running cost/(Apt SEK y)": 36128,
    "out:Running Cost over RSP/MSEK": 11.858,
    "out:LCP/MSEK": -1.013,
    "out:ROI% old": 12.57,
    "out:Payback discounted": 10,
    "out:Atemp": 5143,
    "out:Umean": 0.34,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FTX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 119,
    "out:Energy use kWh/m2": 119,
    "out:Energy savings %": 58,
    "out:Op energy cost/MSEK": 86,
    "out:El price/MSEK": 53,
    "out:Heating price/MSEK": 28,
    "out:DH+ DHW/kWh/m2": 99,
    "out:El bought/kWh/m2": 1434.9,
    "out:Return %": 11,
    "out:Return/kSEK/y": 947,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 875718.530813,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 375439,
    "out:EL kWh savings": -23653,
    "out:Sewage heat recovery cost": 250448,
    "out:Balancing hydronic system cost": 128574.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 301440,
    "out:DH kr savings": 341649,
    "out:El kr savings": -40210,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 318122,
    "out:% savings (space heating)": 51.7,
    "out:Total_Water Heating (DH)": 190162,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 91.88,
    "out:Etvv": 21.25,
    "out:Ef": 20.5,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000100010000010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 65.94,
    "out:DHW (DH)": 43.5,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 14.33,
    "out:Total Energy Use Pre PV": 129.5,
    "out:Total Energy Use Post PV": 123.33,
    "out:Primary Energy": 89.46,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 26.53,
    "out:CO2 Operational/m2": 15.93,
    "out:Total CO2/m2": 42.46,
    "out:Total CO2 (tons)": 218.37,
    "out:Klimatpaverkan": -110.87,
    "out:Initial Cost/MSEK": 9.1155,
    "out:Running cost/(Apt SEK y)": 37281,
    "out:Running Cost over RSP/MSEK": 12.255,
    "out:LCP/MSEK": -2.102,
    "out:ROI% old": 10.99,
    "out:Payback discounted": 11,
    "out:Atemp": 5143,
    "out:Umean": 0.34,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FTX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 123,
    "out:Energy use kWh/m2": 123,
    "out:Energy savings %": 52.8,
    "out:Op energy cost/MSEK": 90,
    "out:El price/MSEK": 54,
    "out:Heating price/MSEK": 31,
    "out:DH+ DHW/kWh/m2": 109,
    "out:El bought/kWh/m2": 1013.1,
    "out:Return %": 10,
    "out:Return/kSEK/y": 898,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 61.265983,
    "out:PV (m2 panels)": 321,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 57484.06,
    "out:PV (kWh sold)": 25763.95,
    "out:PV (% sold (El))": 44.82,
    "out:PV (kWh self-consumed)": 31720.11,
    "out:PV (ratio sold/self-consumed)": 0.81,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 875718.530813,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 324009,
    "out:EL kWh savings": 8067,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 341412,
    "out:DH kr savings": 294848,
    "out:El kr savings": 13715,
    "out:El kr sold": 32849,
    "out:El kr saved": 53924,
    "out:El kr return": 86773,
    "out:% solar/total": 39,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 339155,
    "out:% savings (space heating)": 48.5,
    "out:Total_Water Heating (DH)": 223720,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 95.97,
    "out:Etvv": 25,
    "out:Ef": 14.33,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000100010000110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 61.86,
    "out:DHW (DH)": 43.5,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 14.33,
    "out:Total Energy Use Pre PV": 125.5,
    "out:Total Energy Use Post PV": 119.33,
    "out:Primary Energy": 86.6,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 26.53,
    "out:CO2 Operational/m2": 14.49,
    "out:Total CO2/m2": 41.02,
    "out:Total CO2 (tons)": 210.97,
    "out:Klimatpaverkan": -118.27,
    "out:Initial Cost/MSEK": 9.244,
    "out:Running cost/(Apt SEK y)": 35993,
    "out:Running Cost over RSP/MSEK": 11.832,
    "out:LCP/MSEK": -1.807,
    "out:ROI% old": 11.49,
    "out:Payback discounted": 11,
    "out:Atemp": 5143,
    "out:Umean": 0.34,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FTX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 119,
    "out:Energy use kWh/m2": 119,
    "out:Energy savings %": 58,
    "out:Op energy cost/MSEK": 87,
    "out:El price/MSEK": 52,
    "out:Heating price/MSEK": 30,
    "out:DH+ DHW/kWh/m2": 105,
    "out:El bought/kWh/m2": 1013.1,
    "out:Return %": 10,
    "out:Return/kSEK/y": 952,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 61.265983,
    "out:PV (m2 panels)": 321,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 57484.06,
    "out:PV (kWh sold)": 25763.95,
    "out:PV (% sold (El))": 44.82,
    "out:PV (kWh self-consumed)": 31720.11,
    "out:PV (ratio sold/self-consumed)": 0.81,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 875718.530813,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 344581,
    "out:EL kWh savings": 8067,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 128574.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 360132,
    "out:DH kr savings": 313569,
    "out:El kr savings": 13715,
    "out:El kr sold": 32849,
    "out:El kr saved": 53924,
    "out:El kr return": 86773,
    "out:% solar/total": 39,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 318122,
    "out:% savings (space heating)": 51.7,
    "out:Total_Water Heating (DH)": 223720,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 91.88,
    "out:Etvv": 25,
    "out:Ef": 14.33,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000100010001010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 65.94,
    "out:DHW (DH)": 36.98,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 14.33,
    "out:Total Energy Use Pre PV": 123.5,
    "out:Total Energy Use Post PV": 117.33,
    "out:Primary Energy": 86.83,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 26.93,
    "out:CO2 Operational/m2": 13.77,
    "out:Total CO2/m2": 40.7,
    "out:Total CO2 (tons)": 209.33,
    "out:Klimatpaverkan": -119.91,
    "out:Initial Cost/MSEK": 9.365875,
    "out:Running cost/(Apt SEK y)": 35350,
    "out:Running Cost over RSP/MSEK": 11.62,
    "out:LCP/MSEK": -1.717,
    "out:ROI% old": 11.67,
    "out:Payback discounted": 10,
    "out:Atemp": 5143,
    "out:Umean": 0.34,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FTX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 117,
    "out:Energy use kWh/m2": 117,
    "out:Energy savings %": 60.7,
    "out:Op energy cost/MSEK": 86,
    "out:El price/MSEK": 51,
    "out:Heating price/MSEK": 30,
    "out:DH+ DHW/kWh/m2": 103,
    "out:El bought/kWh/m2": 1013.1,
    "out:Return %": 10,
    "out:Return/kSEK/y": 979,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 61.265983,
    "out:PV (m2 panels)": 321,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 57484.06,
    "out:PV (kWh sold)": 25763.95,
    "out:PV (% sold (El))": 44.82,
    "out:PV (kWh self-consumed)": 31720.11,
    "out:PV (ratio sold/self-consumed)": 0.81,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 875718.530813,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 354867,
    "out:EL kWh savings": 8067,
    "out:Sewage heat recovery cost": 250448,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 369493,
    "out:DH kr savings": 322929,
    "out:El kr savings": 13715,
    "out:El kr sold": 32849,
    "out:El kr saved": 53924,
    "out:El kr return": 86773,
    "out:% solar/total": 39,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 339155,
    "out:% savings (space heating)": 48.5,
    "out:Total_Water Heating (DH)": 190162,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 95.97,
    "out:Etvv": 21.25,
    "out:Ef": 14.33,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000100010001110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 61.86,
    "out:DHW (DH)": 36.98,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 14.33,
    "out:Total Energy Use Pre PV": 119.5,
    "out:Total Energy Use Post PV": 113.33,
    "out:Primary Energy": 83.97,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 26.93,
    "out:CO2 Operational/m2": 12.33,
    "out:Total CO2/m2": 39.26,
    "out:Total CO2 (tons)": 201.93,
    "out:Klimatpaverkan": -127.31,
    "out:Initial Cost/MSEK": 9.4945,
    "out:Running cost/(Apt SEK y)": 34062,
    "out:Running Cost over RSP/MSEK": 11.197,
    "out:LCP/MSEK": -1.423,
    "out:ROI% old": 12.14,
    "out:Payback discounted": 10,
    "out:Atemp": 5143,
    "out:Umean": 0.34,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FTX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 113,
    "out:Energy use kWh/m2": 113,
    "out:Energy savings %": 66.4,
    "out:Op energy cost/MSEK": 82,
    "out:El price/MSEK": 49,
    "out:Heating price/MSEK": 28,
    "out:DH+ DHW/kWh/m2": 99,
    "out:El bought/kWh/m2": 1013.1,
    "out:Return %": 11,
    "out:Return/kSEK/y": 1033,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 61.265983,
    "out:PV (m2 panels)": 321,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 57484.06,
    "out:PV (kWh sold)": 25763.95,
    "out:PV (% sold (El))": 44.82,
    "out:PV (kWh self-consumed)": 31720.11,
    "out:PV (ratio sold/self-consumed)": 0.81,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 875718.530813,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 375439,
    "out:EL kWh savings": 8067,
    "out:Sewage heat recovery cost": 250448,
    "out:Balancing hydronic system cost": 128574.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 388213,
    "out:DH kr savings": 341649,
    "out:El kr savings": 13715,
    "out:El kr sold": 32849,
    "out:El kr saved": 53924,
    "out:El kr return": 86773,
    "out:% solar/total": 39,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 318122,
    "out:% savings (space heating)": 51.7,
    "out:Total_Water Heating (DH)": 190162,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 91.88,
    "out:Etvv": 21.25,
    "out:Ef": 14.33,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000100020000010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 65.94,
    "out:DHW (DH)": 43.5,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 13.09,
    "out:Total Energy Use Pre PV": 129.5,
    "out:Total Energy Use Post PV": 122.09,
    "out:Primary Energy": 87.22,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 38.88,
    "out:CO2 Operational/m2": -34.75,
    "out:Total CO2/m2": 4.13,
    "out:Total CO2 (tons)": 21.25,
    "out:Klimatpaverkan": -307.99,
    "out:Initial Cost/MSEK": 10.18525,
    "out:Running cost/(Apt SEK y)": 35471,
    "out:Running Cost over RSP/MSEK": 11.677,
    "out:LCP/MSEK": -2.593,
    "out:ROI% old": 10.65,
    "out:Payback discounted": 11,
    "out:Atemp": 5143,
    "out:Umean": 0.34,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FTX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 122,
    "out:Energy use kWh/m2": 122,
    "out:Energy savings %": 54.1,
    "out:Op energy cost/MSEK": 87,
    "out:El price/MSEK": 50,
    "out:Heating price/MSEK": 31,
    "out:DH+ DHW/kWh/m2": 109,
    "out:El bought/kWh/m2": 923.7,
    "out:Return %": 10,
    "out:Return/kSEK/y": 974,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.33,
    "out:PV (kWp installed)": 122.531965,
    "out:PV (m2 panels)": 643,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 114968.11,
    "out:PV (kWh sold)": 76862.14,
    "out:PV (% sold (El))": 66.86,
    "out:PV (kWh self-consumed)": 38105.97,
    "out:PV (ratio sold/self-consumed)": 2.02,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 875718.530813,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 324009,
    "out:EL kWh savings": 14453,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 417418,
    "out:DH kr savings": 294848,
    "out:El kr savings": 24571,
    "out:El kr sold": 97999,
    "out:El kr saved": 64780,
    "out:El kr return": 162779,
    "out:% solar/total": 47,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 339155,
    "out:% savings (space heating)": 48.5,
    "out:Total_Water Heating (DH)": 223720,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 95.97,
    "out:Etvv": 25,
    "out:Ef": 13.09,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000100020000110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 61.86,
    "out:DHW (DH)": 43.5,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 13.09,
    "out:Total Energy Use Pre PV": 125.5,
    "out:Total Energy Use Post PV": 118.09,
    "out:Primary Energy": 84.36,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 38.88,
    "out:CO2 Operational/m2": -36.19,
    "out:Total CO2/m2": 2.69,
    "out:Total CO2 (tons)": 13.85,
    "out:Klimatpaverkan": -315.39,
    "out:Initial Cost/MSEK": 10.31375,
    "out:Running cost/(Apt SEK y)": 34184,
    "out:Running Cost over RSP/MSEK": 11.254,
    "out:LCP/MSEK": -2.299,
    "out:ROI% old": 11.1,
    "out:Payback discounted": 11,
    "out:Atemp": 5143,
    "out:Umean": 0.34,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FTX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 118,
    "out:Energy use kWh/m2": 118,
    "out:Energy savings %": 59.3,
    "out:Op energy cost/MSEK": 84,
    "out:El price/MSEK": 48,
    "out:Heating price/MSEK": 30,
    "out:DH+ DHW/kWh/m2": 105,
    "out:El bought/kWh/m2": 923.7,
    "out:Return %": 10,
    "out:Return/kSEK/y": 1028,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.33,
    "out:PV (kWp installed)": 122.531965,
    "out:PV (m2 panels)": 643,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 114968.11,
    "out:PV (kWh sold)": 76862.14,
    "out:PV (% sold (El))": 66.86,
    "out:PV (kWh self-consumed)": 38105.97,
    "out:PV (ratio sold/self-consumed)": 2.02,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 875718.530813,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 344581,
    "out:EL kWh savings": 14453,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 128574.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 436139,
    "out:DH kr savings": 313569,
    "out:El kr savings": 24571,
    "out:El kr sold": 97999,
    "out:El kr saved": 64780,
    "out:El kr return": 162779,
    "out:% solar/total": 47,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 318122,
    "out:% savings (space heating)": 51.7,
    "out:Total_Water Heating (DH)": 223720,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 91.88,
    "out:Etvv": 25,
    "out:Ef": 13.09,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000100020001010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 65.94,
    "out:DHW (DH)": 36.98,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 13.09,
    "out:Total Energy Use Pre PV": 123.5,
    "out:Total Energy Use Post PV": 116.09,
    "out:Primary Energy": 84.6,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 39.29,
    "out:CO2 Operational/m2": -36.91,
    "out:Total CO2/m2": 2.37,
    "out:Total CO2 (tons)": 12.21,
    "out:Klimatpaverkan": -317.03,
    "out:Initial Cost/MSEK": 10.435625,
    "out:Running cost/(Apt SEK y)": 33540,
    "out:Running Cost over RSP/MSEK": 11.042,
    "out:LCP/MSEK": -2.209,
    "out:ROI% old": 11.26,
    "out:Payback discounted": 11,
    "out:Atemp": 5143,
    "out:Umean": 0.34,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FTX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 116,
    "out:Energy use kWh/m2": 116,
    "out:Energy savings %": 62.1,
    "out:Op energy cost/MSEK": 82,
    "out:El price/MSEK": 48,
    "out:Heating price/MSEK": 30,
    "out:DH+ DHW/kWh/m2": 103,
    "out:El bought/kWh/m2": 923.7,
    "out:Return %": 10,
    "out:Return/kSEK/y": 1055,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.33,
    "out:PV (kWp installed)": 122.531965,
    "out:PV (m2 panels)": 643,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 114968.11,
    "out:PV (kWh sold)": 76862.14,
    "out:PV (% sold (El))": 66.86,
    "out:PV (kWh self-consumed)": 38105.97,
    "out:PV (ratio sold/self-consumed)": 2.02,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 875718.530813,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 354867,
    "out:EL kWh savings": 14453,
    "out:Sewage heat recovery cost": 250448,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 445499,
    "out:DH kr savings": 322929,
    "out:El kr savings": 24571,
    "out:El kr sold": 97999,
    "out:El kr saved": 64780,
    "out:El kr return": 162779,
    "out:% solar/total": 47,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 339155,
    "out:% savings (space heating)": 48.5,
    "out:Total_Water Heating (DH)": 190162,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 95.97,
    "out:Etvv": 21.25,
    "out:Ef": 13.09,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000100020001110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 61.86,
    "out:DHW (DH)": 36.98,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 13.09,
    "out:Total Energy Use Pre PV": 119.5,
    "out:Total Energy Use Post PV": 112.09,
    "out:Primary Energy": 81.74,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 39.29,
    "out:CO2 Operational/m2": -38.35,
    "out:Total CO2/m2": 0.93,
    "out:Total CO2 (tons)": 4.81,
    "out:Klimatpaverkan": -324.43,
    "out:Initial Cost/MSEK": 10.56425,
    "out:Running cost/(Apt SEK y)": 32253,
    "out:Running Cost over RSP/MSEK": 10.619,
    "out:LCP/MSEK": -1.915,
    "out:ROI% old": 11.7,
    "out:Payback discounted": 10,
    "out:Atemp": 5143,
    "out:Umean": 0.34,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FTX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 112,
    "out:Energy use kWh/m2": 112,
    "out:Energy savings %": 67.9,
    "out:Op energy cost/MSEK": 79,
    "out:El price/MSEK": 46,
    "out:Heating price/MSEK": 28,
    "out:DH+ DHW/kWh/m2": 99,
    "out:El bought/kWh/m2": 923.7,
    "out:Return %": 11,
    "out:Return/kSEK/y": 1109,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.33,
    "out:PV (kWp installed)": 122.531965,
    "out:PV (m2 panels)": 643,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 114968.11,
    "out:PV (kWh sold)": 76862.14,
    "out:PV (% sold (El))": 66.86,
    "out:PV (kWh self-consumed)": 38105.97,
    "out:PV (ratio sold/self-consumed)": 2.02,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 875718.530813,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 375439,
    "out:EL kWh savings": 14453,
    "out:Sewage heat recovery cost": 250448,
    "out:Balancing hydronic system cost": 128574.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 464219,
    "out:DH kr savings": 341649,
    "out:El kr savings": 24571,
    "out:El kr sold": 97999,
    "out:El kr saved": 64780,
    "out:El kr return": 162779,
    "out:% solar/total": 47,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 318122,
    "out:% savings (space heating)": 51.7,
    "out:Total_Water Heating (DH)": 190162,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 91.88,
    "out:Etvv": 21.25,
    "out:Ef": 13.09,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000100100000010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 65.94,
    "out:DHW (DH)": 34.8,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 20.5,
    "out:Total Energy Use Pre PV": 121.5,
    "out:Total Energy Use Post PV": 121.5,
    "out:Primary Energy": 97.06,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 14.18,
    "out:CO2 Operational/m2": 39.06,
    "out:Total CO2/m2": 53.24,
    "out:Total CO2 (tons)": 273.82,
    "out:Klimatpaverkan": -55.42,
    "out:Initial Cost/MSEK": 8.1565,
    "out:Running cost/(Apt SEK y)": 36772,
    "out:Running Cost over RSP/MSEK": 12.069,
    "out:LCP/MSEK": -0.957,
    "out:ROI% old": 12.61,
    "out:Payback discounted": 10,
    "out:Atemp": 5143,
    "out:Umean": 0.34,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FTX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 121,
    "out:Energy use kWh/m2": 121,
    "out:Energy savings %": 55.4,
    "out:Op energy cost/MSEK": 88,
    "out:El price/MSEK": 54,
    "out:Heating price/MSEK": 29,
    "out:DH+ DHW/kWh/m2": 101,
    "out:El bought/kWh/m2": 1434.9,
    "out:Return %": 11,
    "out:Return/kSEK/y": 919,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 875718.530813,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 365153,
    "out:EL kWh savings": -23653,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 292080,
    "out:DH kr savings": 332289,
    "out:El kr savings": -40210,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 339155,
    "out:% savings (space heating)": 48.5,
    "out:Total_Water Heating (DH)": 178976,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 95.97,
    "out:Etvv": 20,
    "out:Ef": 20.5,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000100100000110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 61.86,
    "out:DHW (DH)": 34.8,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 20.5,
    "out:Total Energy Use Pre PV": 117.5,
    "out:Total Energy Use Post PV": 117.5,
    "out:Primary Energy": 94.2,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 14.18,
    "out:CO2 Operational/m2": 37.62,
    "out:Total CO2/m2": 51.8,
    "out:Total CO2 (tons)": 266.42,
    "out:Klimatpaverkan": -62.82,
    "out:Initial Cost/MSEK": 8.285125,
    "out:Running cost/(Apt SEK y)": 35485,
    "out:Running Cost over RSP/MSEK": 11.646,
    "out:LCP/MSEK": -0.662,
    "out:ROI% old": 13.14,
    "out:Payback discounted": 9,
    "out:Atemp": 5143,
    "out:Umean": 0.34,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FTX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 117,
    "out:Energy use kWh/m2": 117,
    "out:Energy savings %": 60.7,
    "out:Op energy cost/MSEK": 85,
    "out:El price/MSEK": 53,
    "out:Heating price/MSEK": 28,
    "out:DH+ DHW/kWh/m2": 97,
    "out:El bought/kWh/m2": 1434.9,
    "out:Return %": 12,
    "out:Return/kSEK/y": 974,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 875718.530813,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 385725,
    "out:EL kWh savings": -23653,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 128574.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 310800,
    "out:DH kr savings": 351010,
    "out:El kr savings": -40210,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 318122,
    "out:% savings (space heating)": 51.7,
    "out:Total_Water Heating (DH)": 178976,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 91.88,
    "out:Etvv": 20,
    "out:Ef": 20.5,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000100100001010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 65.94,
    "out:DHW (DH)": 29.58,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 20.5,
    "out:Total Energy Use Pre PV": 116.5,
    "out:Total Energy Use Post PV": 116.5,
    "out:Primary Energy": 94.96,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 14.58,
    "out:CO2 Operational/m2": 37.26,
    "out:Total CO2/m2": 51.84,
    "out:Total CO2 (tons)": 266.63,
    "out:Klimatpaverkan": -62.61,
    "out:Initial Cost/MSEK": 8.407,
    "out:Running cost/(Apt SEK y)": 35163,
    "out:Running Cost over RSP/MSEK": 11.541,
    "out:LCP/MSEK": -0.678,
    "out:ROI% old": 13.13,
    "out:Payback discounted": 9,
    "out:Atemp": 5143,
    "out:Umean": 0.34,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FTX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 116,
    "out:Energy use kWh/m2": 116,
    "out:Energy savings %": 62.1,
    "out:Op energy cost/MSEK": 84,
    "out:El price/MSEK": 52,
    "out:Heating price/MSEK": 27,
    "out:DH+ DHW/kWh/m2": 96,
    "out:El bought/kWh/m2": 1434.9,
    "out:Return %": 12,
    "out:Return/kSEK/y": 987,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 875718.530813,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 390868,
    "out:EL kWh savings": -23653,
    "out:Sewage heat recovery cost": 250448,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 315480,
    "out:DH kr savings": 355690,
    "out:El kr savings": -40210,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 339155,
    "out:% savings (space heating)": 48.5,
    "out:Total_Water Heating (DH)": 152130,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 95.97,
    "out:Etvv": 17,
    "out:Ef": 20.5,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000100100001110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 61.86,
    "out:DHW (DH)": 29.58,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 20.5,
    "out:Total Energy Use Pre PV": 111.5,
    "out:Total Energy Use Post PV": 111.5,
    "out:Primary Energy": 92.1,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 14.58,
    "out:CO2 Operational/m2": 35.46,
    "out:Total CO2/m2": 50.04,
    "out:Total CO2 (tons)": 257.38,
    "out:Klimatpaverkan": -71.86,
    "out:Initial Cost/MSEK": 8.5355,
    "out:Running cost/(Apt SEK y)": 33554,
    "out:Running Cost over RSP/MSEK": 11.012,
    "out:LCP/MSEK": -0.278,
    "out:ROI% old": 13.82,
    "out:Payback discounted": 9,
    "out:Atemp": 5143,
    "out:Umean": 0.34,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FTX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 111,
    "out:Energy use kWh/m2": 111,
    "out:Energy savings %": 69.4,
    "out:Op energy cost/MSEK": 80,
    "out:El price/MSEK": 50,
    "out:Heating price/MSEK": 26,
    "out:DH+ DHW/kWh/m2": 91,
    "out:El bought/kWh/m2": 1434.9,
    "out:Return %": 12,
    "out:Return/kSEK/y": 1055,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 875718.530813,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 416583,
    "out:EL kWh savings": -23653,
    "out:Sewage heat recovery cost": 250448,
    "out:Balancing hydronic system cost": 128574.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 338881,
    "out:DH kr savings": 379090,
    "out:El kr savings": -40210,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 318122,
    "out:% savings (space heating)": 51.7,
    "out:Total_Water Heating (DH)": 152130,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 91.88,
    "out:Etvv": 17,
    "out:Ef": 20.5,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000100110000010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 65.94,
    "out:DHW (DH)": 34.8,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 14.33,
    "out:Total Energy Use Pre PV": 121.5,
    "out:Total Energy Use Post PV": 115.33,
    "out:Primary Energy": 85.96,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 26.53,
    "out:CO2 Operational/m2": 13.05,
    "out:Total CO2/m2": 39.58,
    "out:Total CO2 (tons)": 203.56,
    "out:Klimatpaverkan": -125.68,
    "out:Initial Cost/MSEK": 9.22625,
    "out:Running cost/(Apt SEK y)": 34706,
    "out:Running Cost over RSP/MSEK": 11.409,
    "out:LCP/MSEK": -1.366,
    "out:ROI% old": 12.17,
    "out:Payback discounted": 10,
    "out:Atemp": 5143,
    "out:Umean": 0.34,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FTX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 115,
    "out:Energy use kWh/m2": 115,
    "out:Energy savings %": 63.5,
    "out:Op energy cost/MSEK": 84,
    "out:El price/MSEK": 50,
    "out:Heating price/MSEK": 29,
    "out:DH+ DHW/kWh/m2": 101,
    "out:El bought/kWh/m2": 1013.1,
    "out:Return %": 11,
    "out:Return/kSEK/y": 1006,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 61.265983,
    "out:PV (m2 panels)": 321,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 57484.06,
    "out:PV (kWh sold)": 25763.95,
    "out:PV (% sold (El))": 44.82,
    "out:PV (kWh self-consumed)": 31720.11,
    "out:PV (ratio sold/self-consumed)": 0.81,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 875718.530813,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 365153,
    "out:EL kWh savings": 8067,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 378853,
    "out:DH kr savings": 332289,
    "out:El kr savings": 13715,
    "out:El kr sold": 32849,
    "out:El kr saved": 53924,
    "out:El kr return": 86773,
    "out:% solar/total": 39,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 339155,
    "out:% savings (space heating)": 48.5,
    "out:Total_Water Heating (DH)": 178976,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 95.97,
    "out:Etvv": 20,
    "out:Ef": 14.33,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000100110000110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 61.86,
    "out:DHW (DH)": 34.8,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 14.33,
    "out:Total Energy Use Pre PV": 117.5,
    "out:Total Energy Use Post PV": 111.33,
    "out:Primary Energy": 83.1,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 26.53,
    "out:CO2 Operational/m2": 11.61,
    "out:Total CO2/m2": 38.14,
    "out:Total CO2 (tons)": 196.16,
    "out:Klimatpaverkan": -133.08,
    "out:Initial Cost/MSEK": 9.354875,
    "out:Running cost/(Apt SEK y)": 33419,
    "out:Running Cost over RSP/MSEK": 10.986,
    "out:LCP/MSEK": -1.072,
    "out:ROI% old": 12.65,
    "out:Payback discounted": 10,
    "out:Atemp": 5143,
    "out:Umean": 0.34,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FTX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 111,
    "out:Energy use kWh/m2": 111,
    "out:Energy savings %": 69.4,
    "out:Op energy cost/MSEK": 81,
    "out:El price/MSEK": 48,
    "out:Heating price/MSEK": 28,
    "out:DH+ DHW/kWh/m2": 97,
    "out:El bought/kWh/m2": 1013.1,
    "out:Return %": 11,
    "out:Return/kSEK/y": 1060,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 61.265983,
    "out:PV (m2 panels)": 321,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 57484.06,
    "out:PV (kWh sold)": 25763.95,
    "out:PV (% sold (El))": 44.82,
    "out:PV (kWh self-consumed)": 31720.11,
    "out:PV (ratio sold/self-consumed)": 0.81,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 875718.530813,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 385725,
    "out:EL kWh savings": 8067,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 128574.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 397573,
    "out:DH kr savings": 351010,
    "out:El kr savings": 13715,
    "out:El kr sold": 32849,
    "out:El kr saved": 53924,
    "out:El kr return": 86773,
    "out:% solar/total": 39,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 318122,
    "out:% savings (space heating)": 51.7,
    "out:Total_Water Heating (DH)": 178976,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 91.88,
    "out:Etvv": 20,
    "out:Ef": 14.33,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000100110001010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 65.94,
    "out:DHW (DH)": 29.58,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 14.33,
    "out:Total Energy Use Pre PV": 116.5,
    "out:Total Energy Use Post PV": 110.33,
    "out:Primary Energy": 83.86,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 26.93,
    "out:CO2 Operational/m2": 11.25,
    "out:Total CO2/m2": 38.18,
    "out:Total CO2 (tons)": 196.37,
    "out:Klimatpaverkan": -132.87,
    "out:Initial Cost/MSEK": 9.47675,
    "out:Running cost/(Apt SEK y)": 33097,
    "out:Running Cost over RSP/MSEK": 10.88,
    "out:LCP/MSEK": -1.088,
    "out:ROI% old": 12.65,
    "out:Payback discounted": 10,
    "out:Atemp": 5143,
    "out:Umean": 0.34,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FTX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 110,
    "out:Energy use kWh/m2": 110,
    "out:Energy savings %": 70.9,
    "out:Op energy cost/MSEK": 80,
    "out:El price/MSEK": 48,
    "out:Heating price/MSEK": 27,
    "out:DH+ DHW/kWh/m2": 96,
    "out:El bought/kWh/m2": 1013.1,
    "out:Return %": 11,
    "out:Return/kSEK/y": 1074,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 61.265983,
    "out:PV (m2 panels)": 321,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 57484.06,
    "out:PV (kWh sold)": 25763.95,
    "out:PV (% sold (El))": 44.82,
    "out:PV (kWh self-consumed)": 31720.11,
    "out:PV (ratio sold/self-consumed)": 0.81,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 875718.530813,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 390868,
    "out:EL kWh savings": 8067,
    "out:Sewage heat recovery cost": 250448,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 402254,
    "out:DH kr savings": 355690,
    "out:El kr savings": 13715,
    "out:El kr sold": 32849,
    "out:El kr saved": 53924,
    "out:El kr return": 86773,
    "out:% solar/total": 39,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 339155,
    "out:% savings (space heating)": 48.5,
    "out:Total_Water Heating (DH)": 152130,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 95.97,
    "out:Etvv": 17,
    "out:Ef": 14.33,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000100110001110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 61.86,
    "out:DHW (DH)": 29.58,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 14.33,
    "out:Total Energy Use Pre PV": 111.5,
    "out:Total Energy Use Post PV": 105.33,
    "out:Primary Energy": 81,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 26.93,
    "out:CO2 Operational/m2": 9.45,
    "out:Total CO2/m2": 36.38,
    "out:Total CO2 (tons)": 187.12,
    "out:Klimatpaverkan": -142.12,
    "out:Initial Cost/MSEK": 9.605375,
    "out:Running cost/(Apt SEK y)": 31488,
    "out:Running Cost over RSP/MSEK": 10.351,
    "out:LCP/MSEK": -0.687,
    "out:ROI% old": 13.26,
    "out:Payback discounted": 9,
    "out:Atemp": 5143,
    "out:Umean": 0.34,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FTX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 105,
    "out:Energy use kWh/m2": 105,
    "out:Energy savings %": 79,
    "out:Op energy cost/MSEK": 76,
    "out:El price/MSEK": 46,
    "out:Heating price/MSEK": 26,
    "out:DH+ DHW/kWh/m2": 91,
    "out:El bought/kWh/m2": 1013.1,
    "out:Return %": 12,
    "out:Return/kSEK/y": 1141,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 61.265983,
    "out:PV (m2 panels)": 321,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 57484.06,
    "out:PV (kWh sold)": 25763.95,
    "out:PV (% sold (El))": 44.82,
    "out:PV (kWh self-consumed)": 31720.11,
    "out:PV (ratio sold/self-consumed)": 0.81,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 875718.530813,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 416583,
    "out:EL kWh savings": 8067,
    "out:Sewage heat recovery cost": 250448,
    "out:Balancing hydronic system cost": 128574.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 425654,
    "out:DH kr savings": 379090,
    "out:El kr savings": 13715,
    "out:El kr sold": 32849,
    "out:El kr saved": 53924,
    "out:El kr return": 86773,
    "out:% solar/total": 39,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 318122,
    "out:% savings (space heating)": 51.7,
    "out:Total_Water Heating (DH)": 152130,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 91.88,
    "out:Etvv": 17,
    "out:Ef": 14.33,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000100120000010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 65.94,
    "out:DHW (DH)": 34.8,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 13.09,
    "out:Total Energy Use Pre PV": 121.5,
    "out:Total Energy Use Post PV": 114.09,
    "out:Primary Energy": 83.72,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 38.88,
    "out:CO2 Operational/m2": -37.63,
    "out:Total CO2/m2": 1.25,
    "out:Total CO2 (tons)": 6.44,
    "out:Klimatpaverkan": -322.8,
    "out:Initial Cost/MSEK": 10.296125,
    "out:Running cost/(Apt SEK y)": 32896,
    "out:Running Cost over RSP/MSEK": 10.831,
    "out:LCP/MSEK": -1.858,
    "out:ROI% old": 11.71,
    "out:Payback discounted": 10,
    "out:Atemp": 5143,
    "out:Umean": 0.34,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FTX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 114,
    "out:Energy use kWh/m2": 114,
    "out:Energy savings %": 64.9,
    "out:Op energy cost/MSEK": 80,
    "out:El price/MSEK": 47,
    "out:Heating price/MSEK": 29,
    "out:DH+ DHW/kWh/m2": 101,
    "out:El bought/kWh/m2": 923.7,
    "out:Return %": 11,
    "out:Return/kSEK/y": 1082,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.33,
    "out:PV (kWp installed)": 122.531965,
    "out:PV (m2 panels)": 643,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 114968.11,
    "out:PV (kWh sold)": 76862.14,
    "out:PV (% sold (El))": 66.86,
    "out:PV (kWh self-consumed)": 38105.97,
    "out:PV (ratio sold/self-consumed)": 2.02,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 875718.530813,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 365153,
    "out:EL kWh savings": 14453,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 454859,
    "out:DH kr savings": 332289,
    "out:El kr savings": 24571,
    "out:El kr sold": 97999,
    "out:El kr saved": 64780,
    "out:El kr return": 162779,
    "out:% solar/total": 47,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 339155,
    "out:% savings (space heating)": 48.5,
    "out:Total_Water Heating (DH)": 178976,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 95.97,
    "out:Etvv": 20,
    "out:Ef": 13.09,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000100120000110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 61.86,
    "out:DHW (DH)": 34.8,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 13.09,
    "out:Total Energy Use Pre PV": 117.5,
    "out:Total Energy Use Post PV": 110.09,
    "out:Primary Energy": 80.86,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 38.88,
    "out:CO2 Operational/m2": -39.07,
    "out:Total CO2/m2": -0.19,
    "out:Total CO2 (tons)": -0.96,
    "out:Klimatpaverkan": -330.2,
    "out:Initial Cost/MSEK": 10.424625,
    "out:Running cost/(Apt SEK y)": 31609,
    "out:Running Cost over RSP/MSEK": 10.408,
    "out:LCP/MSEK": -1.563,
    "out:ROI% old": 12.14,
    "out:Payback discounted": 10,
    "out:Atemp": 5143,
    "out:Umean": 0.34,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FTX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 110,
    "out:Energy use kWh/m2": 110,
    "out:Energy savings %": 70.9,
    "out:Op energy cost/MSEK": 77,
    "out:El price/MSEK": 45,
    "out:Heating price/MSEK": 28,
    "out:DH+ DHW/kWh/m2": 97,
    "out:El bought/kWh/m2": 923.7,
    "out:Return %": 11,
    "out:Return/kSEK/y": 1136,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.33,
    "out:PV (kWp installed)": 122.531965,
    "out:PV (m2 panels)": 643,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 114968.11,
    "out:PV (kWh sold)": 76862.14,
    "out:PV (% sold (El))": 66.86,
    "out:PV (kWh self-consumed)": 38105.97,
    "out:PV (ratio sold/self-consumed)": 2.02,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 875718.530813,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 385725,
    "out:EL kWh savings": 14453,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 128574.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 473580,
    "out:DH kr savings": 351010,
    "out:El kr savings": 24571,
    "out:El kr sold": 97999,
    "out:El kr saved": 64780,
    "out:El kr return": 162779,
    "out:% solar/total": 47,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 318122,
    "out:% savings (space heating)": 51.7,
    "out:Total_Water Heating (DH)": 178976,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 91.88,
    "out:Etvv": 20,
    "out:Ef": 13.09,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000100120001010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 65.94,
    "out:DHW (DH)": 29.58,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 13.09,
    "out:Total Energy Use Pre PV": 116.5,
    "out:Total Energy Use Post PV": 109.09,
    "out:Primary Energy": 81.62,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 39.29,
    "out:CO2 Operational/m2": -39.43,
    "out:Total CO2/m2": -0.15,
    "out:Total CO2 (tons)": -0.75,
    "out:Klimatpaverkan": -329.99,
    "out:Initial Cost/MSEK": 10.5465,
    "out:Running cost/(Apt SEK y)": 31287,
    "out:Running Cost over RSP/MSEK": 10.302,
    "out:LCP/MSEK": -1.579,
    "out:ROI% old": 12.15,
    "out:Payback discounted": 10,
    "out:Atemp": 5143,
    "out:Umean": 0.34,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FTX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 109,
    "out:Energy use kWh/m2": 109,
    "out:Energy savings %": 72.5,
    "out:Op energy cost/MSEK": 77,
    "out:El price/MSEK": 44,
    "out:Heating price/MSEK": 27,
    "out:DH+ DHW/kWh/m2": 96,
    "out:El bought/kWh/m2": 923.7,
    "out:Return %": 11,
    "out:Return/kSEK/y": 1150,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.33,
    "out:PV (kWp installed)": 122.531965,
    "out:PV (m2 panels)": 643,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 114968.11,
    "out:PV (kWh sold)": 76862.14,
    "out:PV (% sold (El))": 66.86,
    "out:PV (kWh self-consumed)": 38105.97,
    "out:PV (ratio sold/self-consumed)": 2.02,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 875718.530813,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 390868,
    "out:EL kWh savings": 14453,
    "out:Sewage heat recovery cost": 250448,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 478260,
    "out:DH kr savings": 355690,
    "out:El kr savings": 24571,
    "out:El kr sold": 97999,
    "out:El kr saved": 64780,
    "out:El kr return": 162779,
    "out:% solar/total": 47,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 339155,
    "out:% savings (space heating)": 48.5,
    "out:Total_Water Heating (DH)": 152130,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 95.97,
    "out:Etvv": 17,
    "out:Ef": 13.09,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000100120001110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 61.86,
    "out:DHW (DH)": 29.58,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 13.09,
    "out:Total Energy Use Pre PV": 111.5,
    "out:Total Energy Use Post PV": 104.09,
    "out:Primary Energy": 78.76,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 39.29,
    "out:CO2 Operational/m2": -41.23,
    "out:Total CO2/m2": -1.95,
    "out:Total CO2 (tons)": -10,
    "out:Klimatpaverkan": -339.24,
    "out:Initial Cost/MSEK": 10.675125,
    "out:Running cost/(Apt SEK y)": 29678,
    "out:Running Cost over RSP/MSEK": 9.773,
    "out:LCP/MSEK": -1.179,
    "out:ROI% old": 12.71,
    "out:Payback discounted": 10,
    "out:Atemp": 5143,
    "out:Umean": 0.34,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FTX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 104,
    "out:Energy use kWh/m2": 104,
    "out:Energy savings %": 80.8,
    "out:Op energy cost/MSEK": 73,
    "out:El price/MSEK": 42,
    "out:Heating price/MSEK": 26,
    "out:DH+ DHW/kWh/m2": 91,
    "out:El bought/kWh/m2": 923.7,
    "out:Return %": 11,
    "out:Return/kSEK/y": 1217,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.33,
    "out:PV (kWp installed)": 122.531965,
    "out:PV (m2 panels)": 643,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 114968.11,
    "out:PV (kWh sold)": 76862.14,
    "out:PV (% sold (El))": 66.86,
    "out:PV (kWh self-consumed)": 38105.97,
    "out:PV (ratio sold/self-consumed)": 2.02,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 875718.530813,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 416583,
    "out:EL kWh savings": 14453,
    "out:Sewage heat recovery cost": 250448,
    "out:Balancing hydronic system cost": 128574.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 501660,
    "out:DH kr savings": 379090,
    "out:El kr savings": 24571,
    "out:El kr sold": 97999,
    "out:El kr saved": 64780,
    "out:El kr return": 162779,
    "out:% solar/total": 47,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 318122,
    "out:% savings (space heating)": 51.7,
    "out:Total_Water Heating (DH)": 152130,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 91.88,
    "out:Etvv": 17,
    "out:Ef": 13.09,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000200000000010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 62.46,
    "out:DHW (DH)": 43.5,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 20.5,
    "out:Total Energy Use Pre PV": 126.5,
    "out:Total Energy Use Post PV": 126.5,
    "out:Primary Energy": 98.12,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 20.14,
    "out:CO2 Operational/m2": 40.86,
    "out:Total CO2/m2": 61.01,
    "out:Total CO2 (tons)": 313.75,
    "out:Klimatpaverkan": -15.49,
    "out:Initial Cost/MSEK": 10.057125,
    "out:Running cost/(Apt SEK y)": 38381,
    "out:Running Cost over RSP/MSEK": 12.598,
    "out:LCP/MSEK": -3.386,
    "out:ROI% old": 9.48,
    "out:Payback discounted": 13,
    "out:Atemp": 5143,
    "out:Umean": 0.29,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FTX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 126,
    "out:Energy use kWh/m2": 126,
    "out:Energy savings %": 49.2,
    "out:Op energy cost/MSEK": 92,
    "out:El price/MSEK": 57,
    "out:Heating price/MSEK": 30,
    "out:DH+ DHW/kWh/m2": 106,
    "out:El bought/kWh/m2": 1434.9,
    "out:Return %": 8,
    "out:Return/kSEK/y": 852,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 875718.530813,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 339438,
    "out:EL kWh savings": -23653,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 268679,
    "out:DH kr savings": 308889,
    "out:El kr savings": -40210,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 321225,
    "out:% savings (space heating)": 51.23,
    "out:Total_Water Heating (DH)": 223720,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 92.49,
    "out:Etvv": 25,
    "out:Ef": 20.5,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000200000000110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 58.53,
    "out:DHW (DH)": 43.5,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 20.5,
    "out:Total Energy Use Pre PV": 122.5,
    "out:Total Energy Use Post PV": 122.5,
    "out:Primary Energy": 95.37,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 20.14,
    "out:CO2 Operational/m2": 39.42,
    "out:Total CO2/m2": 59.57,
    "out:Total CO2 (tons)": 306.35,
    "out:Klimatpaverkan": -22.89,
    "out:Initial Cost/MSEK": 10.18575,
    "out:Running cost/(Apt SEK y)": 37094,
    "out:Running Cost over RSP/MSEK": 12.175,
    "out:LCP/MSEK": -3.092,
    "out:ROI% old": 9.95,
    "out:Payback discounted": 12,
    "out:Atemp": 5143,
    "out:Umean": 0.29,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FTX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 122,
    "out:Energy use kWh/m2": 122,
    "out:Energy savings %": 54.1,
    "out:Op energy cost/MSEK": 89,
    "out:El price/MSEK": 55,
    "out:Heating price/MSEK": 29,
    "out:DH+ DHW/kWh/m2": 102,
    "out:El bought/kWh/m2": 1434.9,
    "out:Return %": 9,
    "out:Return/kSEK/y": 906,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 875718.530813,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 360010,
    "out:EL kWh savings": -23653,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 128574.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 287400,
    "out:DH kr savings": 327609,
    "out:El kr savings": -40210,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 301007,
    "out:% savings (space heating)": 54.29,
    "out:Total_Water Heating (DH)": 223720,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 88.55,
    "out:Etvv": 25,
    "out:Ef": 20.5,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000200000001010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 62.46,
    "out:DHW (DH)": 36.98,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 20.5,
    "out:Total Energy Use Pre PV": 119.5,
    "out:Total Energy Use Post PV": 119.5,
    "out:Primary Energy": 95.49,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 20.54,
    "out:CO2 Operational/m2": 38.34,
    "out:Total CO2/m2": 58.89,
    "out:Total CO2 (tons)": 302.86,
    "out:Klimatpaverkan": -26.38,
    "out:Initial Cost/MSEK": 10.307625,
    "out:Running cost/(Apt SEK y)": 36128,
    "out:Running Cost over RSP/MSEK": 11.858,
    "out:LCP/MSEK": -2.896,
    "out:ROI% old": 10.27,
    "out:Payback discounted": 12,
    "out:Atemp": 5143,
    "out:Umean": 0.29,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FTX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 119,
    "out:Energy use kWh/m2": 119,
    "out:Energy savings %": 58,
    "out:Op energy cost/MSEK": 86,
    "out:El price/MSEK": 53,
    "out:Heating price/MSEK": 28,
    "out:DH+ DHW/kWh/m2": 99,
    "out:El bought/kWh/m2": 1434.9,
    "out:Return %": 9,
    "out:Return/kSEK/y": 947,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 875718.530813,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 375439,
    "out:EL kWh savings": -23653,
    "out:Sewage heat recovery cost": 250448,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 301440,
    "out:DH kr savings": 341649,
    "out:El kr savings": -40210,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 321225,
    "out:% savings (space heating)": 51.23,
    "out:Total_Water Heating (DH)": 190162,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 92.49,
    "out:Etvv": 21.25,
    "out:Ef": 20.5,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000200000001110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 58.53,
    "out:DHW (DH)": 36.98,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 20.5,
    "out:Total Energy Use Pre PV": 116.5,
    "out:Total Energy Use Post PV": 116.5,
    "out:Primary Energy": 92.74,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 20.54,
    "out:CO2 Operational/m2": 37.26,
    "out:Total CO2/m2": 57.81,
    "out:Total CO2 (tons)": 297.31,
    "out:Klimatpaverkan": -31.93,
    "out:Initial Cost/MSEK": 10.436125,
    "out:Running cost/(Apt SEK y)": 35163,
    "out:Running Cost over RSP/MSEK": 11.541,
    "out:LCP/MSEK": -2.708,
    "out:ROI% old": 10.58,
    "out:Payback discounted": 11,
    "out:Atemp": 5143,
    "out:Umean": 0.29,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FTX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 116,
    "out:Energy use kWh/m2": 116,
    "out:Energy savings %": 62.1,
    "out:Op energy cost/MSEK": 84,
    "out:El price/MSEK": 52,
    "out:Heating price/MSEK": 27,
    "out:DH+ DHW/kWh/m2": 96,
    "out:El bought/kWh/m2": 1434.9,
    "out:Return %": 9,
    "out:Return/kSEK/y": 987,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 875718.530813,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 390868,
    "out:EL kWh savings": -23653,
    "out:Sewage heat recovery cost": 250448,
    "out:Balancing hydronic system cost": 128574.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 315480,
    "out:DH kr savings": 355690,
    "out:El kr savings": -40210,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 301007,
    "out:% savings (space heating)": 54.29,
    "out:Total_Water Heating (DH)": 190162,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 88.55,
    "out:Etvv": 21.25,
    "out:Ef": 20.5,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000200010000010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 62.46,
    "out:DHW (DH)": 43.5,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 14.33,
    "out:Total Energy Use Pre PV": 126.5,
    "out:Total Energy Use Post PV": 120.33,
    "out:Primary Energy": 87.02,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 32.49,
    "out:CO2 Operational/m2": 14.85,
    "out:Total CO2/m2": 47.34,
    "out:Total CO2 (tons)": 243.49,
    "out:Klimatpaverkan": -85.75,
    "out:Initial Cost/MSEK": 11.127,
    "out:Running cost/(Apt SEK y)": 36315,
    "out:Running Cost over RSP/MSEK": 11.938,
    "out:LCP/MSEK": -3.796,
    "out:ROI% old": 9.41,
    "out:Payback discounted": 13,
    "out:Atemp": 5143,
    "out:Umean": 0.29,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FTX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 120,
    "out:Energy use kWh/m2": 120,
    "out:Energy savings %": 56.7,
    "out:Op energy cost/MSEK": 88,
    "out:El price/MSEK": 52,
    "out:Heating price/MSEK": 30,
    "out:DH+ DHW/kWh/m2": 106,
    "out:El bought/kWh/m2": 1013.1,
    "out:Return %": 8,
    "out:Return/kSEK/y": 939,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 61.265983,
    "out:PV (m2 panels)": 321,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 57484.06,
    "out:PV (kWh sold)": 25763.95,
    "out:PV (% sold (El))": 44.82,
    "out:PV (kWh self-consumed)": 31720.11,
    "out:PV (ratio sold/self-consumed)": 0.81,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 875718.530813,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 339438,
    "out:EL kWh savings": 8067,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 355452,
    "out:DH kr savings": 308889,
    "out:El kr savings": 13715,
    "out:El kr sold": 32849,
    "out:El kr saved": 53924,
    "out:El kr return": 86773,
    "out:% solar/total": 39,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 321225,
    "out:% savings (space heating)": 51.23,
    "out:Total_Water Heating (DH)": 223720,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 92.49,
    "out:Etvv": 25,
    "out:Ef": 14.33,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000200010000110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 58.53,
    "out:DHW (DH)": 43.5,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 14.33,
    "out:Total Energy Use Pre PV": 122.5,
    "out:Total Energy Use Post PV": 116.33,
    "out:Primary Energy": 84.27,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 32.49,
    "out:CO2 Operational/m2": 13.41,
    "out:Total CO2/m2": 45.9,
    "out:Total CO2 (tons)": 236.09,
    "out:Klimatpaverkan": -93.15,
    "out:Initial Cost/MSEK": 11.2555,
    "out:Running cost/(Apt SEK y)": 35028,
    "out:Running Cost over RSP/MSEK": 11.515,
    "out:LCP/MSEK": -3.501,
    "out:ROI% old": 9.84,
    "out:Payback discounted": 12,
    "out:Atemp": 5143,
    "out:Umean": 0.29,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FTX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 116,
    "out:Energy use kWh/m2": 116,
    "out:Energy savings %": 62.1,
    "out:Op energy cost/MSEK": 85,
    "out:El price/MSEK": 51,
    "out:Heating price/MSEK": 29,
    "out:DH+ DHW/kWh/m2": 102,
    "out:El bought/kWh/m2": 1013.1,
    "out:Return %": 9,
    "out:Return/kSEK/y": 993,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 61.265983,
    "out:PV (m2 panels)": 321,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 57484.06,
    "out:PV (kWh sold)": 25763.95,
    "out:PV (% sold (El))": 44.82,
    "out:PV (kWh self-consumed)": 31720.11,
    "out:PV (ratio sold/self-consumed)": 0.81,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 875718.530813,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 360010,
    "out:EL kWh savings": 8067,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 128574.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 374173,
    "out:DH kr savings": 327609,
    "out:El kr savings": 13715,
    "out:El kr sold": 32849,
    "out:El kr saved": 53924,
    "out:El kr return": 86773,
    "out:% solar/total": 39,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 301007,
    "out:% savings (space heating)": 54.29,
    "out:Total_Water Heating (DH)": 223720,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 88.55,
    "out:Etvv": 25,
    "out:Ef": 14.33,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000200010001010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 62.46,
    "out:DHW (DH)": 36.98,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 14.33,
    "out:Total Energy Use Pre PV": 119.5,
    "out:Total Energy Use Post PV": 113.33,
    "out:Primary Energy": 84.39,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 32.9,
    "out:CO2 Operational/m2": 12.33,
    "out:Total CO2/m2": 45.23,
    "out:Total CO2 (tons)": 232.6,
    "out:Klimatpaverkan": -96.64,
    "out:Initial Cost/MSEK": 11.377375,
    "out:Running cost/(Apt SEK y)": 34062,
    "out:Running Cost over RSP/MSEK": 11.197,
    "out:LCP/MSEK": -3.306,
    "out:ROI% old": 10.14,
    "out:Payback discounted": 12,
    "out:Atemp": 5143,
    "out:Umean": 0.29,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FTX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 113,
    "out:Energy use kWh/m2": 113,
    "out:Energy savings %": 66.4,
    "out:Op energy cost/MSEK": 82,
    "out:El price/MSEK": 49,
    "out:Heating price/MSEK": 28,
    "out:DH+ DHW/kWh/m2": 99,
    "out:El bought/kWh/m2": 1013.1,
    "out:Return %": 9,
    "out:Return/kSEK/y": 1033,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 61.265983,
    "out:PV (m2 panels)": 321,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 57484.06,
    "out:PV (kWh sold)": 25763.95,
    "out:PV (% sold (El))": 44.82,
    "out:PV (kWh self-consumed)": 31720.11,
    "out:PV (ratio sold/self-consumed)": 0.81,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 875718.530813,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 375439,
    "out:EL kWh savings": 8067,
    "out:Sewage heat recovery cost": 250448,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 388213,
    "out:DH kr savings": 341649,
    "out:El kr savings": 13715,
    "out:El kr sold": 32849,
    "out:El kr saved": 53924,
    "out:El kr return": 86773,
    "out:% solar/total": 39,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 321225,
    "out:% savings (space heating)": 51.23,
    "out:Total_Water Heating (DH)": 190162,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 92.49,
    "out:Etvv": 21.25,
    "out:Ef": 14.33,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000200010001110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 58.53,
    "out:DHW (DH)": 36.98,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 14.33,
    "out:Total Energy Use Pre PV": 116.5,
    "out:Total Energy Use Post PV": 110.33,
    "out:Primary Energy": 81.64,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 32.9,
    "out:CO2 Operational/m2": 11.25,
    "out:Total CO2/m2": 44.15,
    "out:Total CO2 (tons)": 227.05,
    "out:Klimatpaverkan": -102.19,
    "out:Initial Cost/MSEK": 11.506,
    "out:Running cost/(Apt SEK y)": 33097,
    "out:Running Cost over RSP/MSEK": 10.88,
    "out:LCP/MSEK": -3.117,
    "out:ROI% old": 10.42,
    "out:Payback discounted": 12,
    "out:Atemp": 5143,
    "out:Umean": 0.29,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FTX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 110,
    "out:Energy use kWh/m2": 110,
    "out:Energy savings %": 70.9,
    "out:Op energy cost/MSEK": 80,
    "out:El price/MSEK": 48,
    "out:Heating price/MSEK": 27,
    "out:DH+ DHW/kWh/m2": 96,
    "out:El bought/kWh/m2": 1013.1,
    "out:Return %": 9,
    "out:Return/kSEK/y": 1074,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 61.265983,
    "out:PV (m2 panels)": 321,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 57484.06,
    "out:PV (kWh sold)": 25763.95,
    "out:PV (% sold (El))": 44.82,
    "out:PV (kWh self-consumed)": 31720.11,
    "out:PV (ratio sold/self-consumed)": 0.81,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 875718.530813,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 390868,
    "out:EL kWh savings": 8067,
    "out:Sewage heat recovery cost": 250448,
    "out:Balancing hydronic system cost": 128574.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 402254,
    "out:DH kr savings": 355690,
    "out:El kr savings": 13715,
    "out:El kr sold": 32849,
    "out:El kr saved": 53924,
    "out:El kr return": 86773,
    "out:% solar/total": 39,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 301007,
    "out:% savings (space heating)": 54.29,
    "out:Total_Water Heating (DH)": 190162,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 88.55,
    "out:Etvv": 21.25,
    "out:Ef": 14.33,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000200020000010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 62.46,
    "out:DHW (DH)": 43.5,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 13.09,
    "out:Total Energy Use Pre PV": 126.5,
    "out:Total Energy Use Post PV": 119.09,
    "out:Primary Energy": 84.78,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 44.85,
    "out:CO2 Operational/m2": -35.83,
    "out:Total CO2/m2": 9.02,
    "out:Total CO2 (tons)": 46.37,
    "out:Klimatpaverkan": -282.87,
    "out:Initial Cost/MSEK": 12.19675,
    "out:Running cost/(Apt SEK y)": 34505,
    "out:Running Cost over RSP/MSEK": 11.36,
    "out:LCP/MSEK": -4.287,
    "out:ROI% old": 9.26,
    "out:Payback discounted": 13,
    "out:Atemp": 5143,
    "out:Umean": 0.29,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FTX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 119,
    "out:Energy use kWh/m2": 119,
    "out:Energy savings %": 58,
    "out:Op energy cost/MSEK": 84,
    "out:El price/MSEK": 49,
    "out:Heating price/MSEK": 30,
    "out:DH+ DHW/kWh/m2": 106,
    "out:El bought/kWh/m2": 923.7,
    "out:Return %": 8,
    "out:Return/kSEK/y": 1015,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.33,
    "out:PV (kWp installed)": 122.531965,
    "out:PV (m2 panels)": 643,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 114968.11,
    "out:PV (kWh sold)": 76862.14,
    "out:PV (% sold (El))": 66.86,
    "out:PV (kWh self-consumed)": 38105.97,
    "out:PV (ratio sold/self-consumed)": 2.02,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 875718.530813,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 339438,
    "out:EL kWh savings": 14453,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 431458,
    "out:DH kr savings": 308889,
    "out:El kr savings": 24571,
    "out:El kr sold": 97999,
    "out:El kr saved": 64780,
    "out:El kr return": 162779,
    "out:% solar/total": 47,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 321225,
    "out:% savings (space heating)": 51.23,
    "out:Total_Water Heating (DH)": 223720,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 92.49,
    "out:Etvv": 25,
    "out:Ef": 13.09,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000200020000110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 58.53,
    "out:DHW (DH)": 43.5,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 13.09,
    "out:Total Energy Use Pre PV": 122.5,
    "out:Total Energy Use Post PV": 115.09,
    "out:Primary Energy": 82.03,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 44.85,
    "out:CO2 Operational/m2": -37.27,
    "out:Total CO2/m2": 7.58,
    "out:Total CO2 (tons)": 38.97,
    "out:Klimatpaverkan": -290.27,
    "out:Initial Cost/MSEK": 12.32525,
    "out:Running cost/(Apt SEK y)": 33218,
    "out:Running Cost over RSP/MSEK": 10.937,
    "out:LCP/MSEK": -3.993,
    "out:ROI% old": 9.66,
    "out:Payback discounted": 13,
    "out:Atemp": 5143,
    "out:Umean": 0.29,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FTX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 115,
    "out:Energy use kWh/m2": 115,
    "out:Energy savings %": 63.5,
    "out:Op energy cost/MSEK": 81,
    "out:El price/MSEK": 47,
    "out:Heating price/MSEK": 29,
    "out:DH+ DHW/kWh/m2": 102,
    "out:El bought/kWh/m2": 923.7,
    "out:Return %": 9,
    "out:Return/kSEK/y": 1069,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.33,
    "out:PV (kWp installed)": 122.531965,
    "out:PV (m2 panels)": 643,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 114968.11,
    "out:PV (kWh sold)": 76862.14,
    "out:PV (% sold (El))": 66.86,
    "out:PV (kWh self-consumed)": 38105.97,
    "out:PV (ratio sold/self-consumed)": 2.02,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 875718.530813,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 360010,
    "out:EL kWh savings": 14453,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 128574.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 450179,
    "out:DH kr savings": 327609,
    "out:El kr savings": 24571,
    "out:El kr sold": 97999,
    "out:El kr saved": 64780,
    "out:El kr return": 162779,
    "out:% solar/total": 47,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 301007,
    "out:% savings (space heating)": 54.29,
    "out:Total_Water Heating (DH)": 223720,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 88.55,
    "out:Etvv": 25,
    "out:Ef": 13.09,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000200020001010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 62.46,
    "out:DHW (DH)": 36.98,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 13.09,
    "out:Total Energy Use Pre PV": 119.5,
    "out:Total Energy Use Post PV": 112.09,
    "out:Primary Energy": 82.16,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 45.25,
    "out:CO2 Operational/m2": -38.35,
    "out:Total CO2/m2": 6.9,
    "out:Total CO2 (tons)": 35.48,
    "out:Klimatpaverkan": -293.76,
    "out:Initial Cost/MSEK": 12.447125,
    "out:Running cost/(Apt SEK y)": 32253,
    "out:Running Cost over RSP/MSEK": 10.619,
    "out:LCP/MSEK": -3.797,
    "out:ROI% old": 9.93,
    "out:Payback discounted": 12,
    "out:Atemp": 5143,
    "out:Umean": 0.29,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FTX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 112,
    "out:Energy use kWh/m2": 112,
    "out:Energy savings %": 67.9,
    "out:Op energy cost/MSEK": 79,
    "out:El price/MSEK": 46,
    "out:Heating price/MSEK": 28,
    "out:DH+ DHW/kWh/m2": 99,
    "out:El bought/kWh/m2": 923.7,
    "out:Return %": 9,
    "out:Return/kSEK/y": 1109,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.33,
    "out:PV (kWp installed)": 122.531965,
    "out:PV (m2 panels)": 643,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 114968.11,
    "out:PV (kWh sold)": 76862.14,
    "out:PV (% sold (El))": 66.86,
    "out:PV (kWh self-consumed)": 38105.97,
    "out:PV (ratio sold/self-consumed)": 2.02,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 875718.530813,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 375439,
    "out:EL kWh savings": 14453,
    "out:Sewage heat recovery cost": 250448,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 464219,
    "out:DH kr savings": 341649,
    "out:El kr savings": 24571,
    "out:El kr sold": 97999,
    "out:El kr saved": 64780,
    "out:El kr return": 162779,
    "out:% solar/total": 47,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 321225,
    "out:% savings (space heating)": 51.23,
    "out:Total_Water Heating (DH)": 190162,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 92.49,
    "out:Etvv": 21.25,
    "out:Ef": 13.09,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000200020001110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 58.53,
    "out:DHW (DH)": 36.98,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 13.09,
    "out:Total Energy Use Pre PV": 116.5,
    "out:Total Energy Use Post PV": 109.09,
    "out:Primary Energy": 79.41,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 45.25,
    "out:CO2 Operational/m2": -39.43,
    "out:Total CO2/m2": 5.82,
    "out:Total CO2 (tons)": 29.93,
    "out:Klimatpaverkan": -299.31,
    "out:Initial Cost/MSEK": 12.57625,
    "out:Running cost/(Apt SEK y)": 31287,
    "out:Running Cost over RSP/MSEK": 10.302,
    "out:LCP/MSEK": -3.609,
    "out:ROI% old": 10.19,
    "out:Payback discounted": 12,
    "out:Atemp": 5143,
    "out:Umean": 0.29,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FTX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 109,
    "out:Energy use kWh/m2": 109,
    "out:Energy savings %": 72.5,
    "out:Op energy cost/MSEK": 77,
    "out:El price/MSEK": 44,
    "out:Heating price/MSEK": 27,
    "out:DH+ DHW/kWh/m2": 96,
    "out:El bought/kWh/m2": 923.7,
    "out:Return %": 9,
    "out:Return/kSEK/y": 1150,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.33,
    "out:PV (kWp installed)": 122.531965,
    "out:PV (m2 panels)": 643,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 114968.11,
    "out:PV (kWh sold)": 76862.14,
    "out:PV (% sold (El))": 66.86,
    "out:PV (kWh self-consumed)": 38105.97,
    "out:PV (ratio sold/self-consumed)": 2.02,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 875718.530813,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 390868,
    "out:EL kWh savings": 14453,
    "out:Sewage heat recovery cost": 250448,
    "out:Balancing hydronic system cost": 128574.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 478260,
    "out:DH kr savings": 355690,
    "out:El kr savings": 24571,
    "out:El kr sold": 97999,
    "out:El kr saved": 64780,
    "out:El kr return": 162779,
    "out:% solar/total": 47,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 301007,
    "out:% savings (space heating)": 54.29,
    "out:Total_Water Heating (DH)": 190162,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 88.55,
    "out:Etvv": 21.25,
    "out:Ef": 13.09,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000200100000010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 62.46,
    "out:DHW (DH)": 34.8,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 20.5,
    "out:Total Energy Use Pre PV": 117.5,
    "out:Total Energy Use Post PV": 117.5,
    "out:Primary Energy": 94.62,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 20.14,
    "out:CO2 Operational/m2": 37.62,
    "out:Total CO2/m2": 57.77,
    "out:Total CO2 (tons)": 297.09,
    "out:Klimatpaverkan": -32.15,
    "out:Initial Cost/MSEK": 10.168,
    "out:Running cost/(Apt SEK y)": 35485,
    "out:Running Cost over RSP/MSEK": 11.646,
    "out:LCP/MSEK": -2.545,
    "out:ROI% old": 10.71,
    "out:Payback discounted": 11,
    "out:Atemp": 5143,
    "out:Umean": 0.29,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FTX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 117,
    "out:Energy use kWh/m2": 117,
    "out:Energy savings %": 60.7,
    "out:Op energy cost/MSEK": 85,
    "out:El price/MSEK": 53,
    "out:Heating price/MSEK": 28,
    "out:DH+ DHW/kWh/m2": 97,
    "out:El bought/kWh/m2": 1434.9,
    "out:Return %": 10,
    "out:Return/kSEK/y": 974,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 875718.530813,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 385725,
    "out:EL kWh savings": -23653,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 310800,
    "out:DH kr savings": 351010,
    "out:El kr savings": -40210,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 321225,
    "out:% savings (space heating)": 51.23,
    "out:Total_Water Heating (DH)": 178976,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 92.49,
    "out:Etvv": 20,
    "out:Ef": 20.5,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000200100000110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 58.53,
    "out:DHW (DH)": 34.8,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 20.5,
    "out:Total Energy Use Pre PV": 113.5,
    "out:Total Energy Use Post PV": 113.5,
    "out:Primary Energy": 91.87,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 20.14,
    "out:CO2 Operational/m2": 36.18,
    "out:Total CO2/m2": 56.33,
    "out:Total CO2 (tons)": 289.68,
    "out:Klimatpaverkan": -39.56,
    "out:Initial Cost/MSEK": 10.296625,
    "out:Running cost/(Apt SEK y)": 34197,
    "out:Running Cost over RSP/MSEK": 11.223,
    "out:LCP/MSEK": -2.251,
    "out:ROI% old": 11.16,
    "out:Payback discounted": 11,
    "out:Atemp": 5143,
    "out:Umean": 0.29,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FTX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 113,
    "out:Energy use kWh/m2": 113,
    "out:Energy savings %": 66.4,
    "out:Op energy cost/MSEK": 82,
    "out:El price/MSEK": 51,
    "out:Heating price/MSEK": 27,
    "out:DH+ DHW/kWh/m2": 93,
    "out:El bought/kWh/m2": 1434.9,
    "out:Return %": 10,
    "out:Return/kSEK/y": 1028,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 875718.530813,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 406297,
    "out:EL kWh savings": -23653,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 128574.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 329521,
    "out:DH kr savings": 369730,
    "out:El kr savings": -40210,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 301007,
    "out:% savings (space heating)": 54.29,
    "out:Total_Water Heating (DH)": 178976,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 88.55,
    "out:Etvv": 20,
    "out:Ef": 20.5,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000200100001010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 62.46,
    "out:DHW (DH)": 29.58,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 20.5,
    "out:Total Energy Use Pre PV": 112.5,
    "out:Total Energy Use Post PV": 112.5,
    "out:Primary Energy": 92.52,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 20.54,
    "out:CO2 Operational/m2": 35.82,
    "out:Total CO2/m2": 56.37,
    "out:Total CO2 (tons)": 289.9,
    "out:Klimatpaverkan": -39.34,
    "out:Initial Cost/MSEK": 10.4185,
    "out:Running cost/(Apt SEK y)": 33876,
    "out:Running Cost over RSP/MSEK": 11.117,
    "out:LCP/MSEK": -2.267,
    "out:ROI% old": 11.18,
    "out:Payback discounted": 11,
    "out:Atemp": 5143,
    "out:Umean": 0.29,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FTX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 112,
    "out:Energy use kWh/m2": 112,
    "out:Energy savings %": 67.9,
    "out:Op energy cost/MSEK": 81,
    "out:El price/MSEK": 50,
    "out:Heating price/MSEK": 26,
    "out:DH+ DHW/kWh/m2": 92,
    "out:El bought/kWh/m2": 1434.9,
    "out:Return %": 10,
    "out:Return/kSEK/y": 1041,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 875718.530813,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 411440,
    "out:EL kWh savings": -23653,
    "out:Sewage heat recovery cost": 250448,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 334201,
    "out:DH kr savings": 374410,
    "out:El kr savings": -40210,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 321225,
    "out:% savings (space heating)": 51.23,
    "out:Total_Water Heating (DH)": 152130,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 92.49,
    "out:Etvv": 17,
    "out:Ef": 20.5,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000200100001110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 58.53,
    "out:DHW (DH)": 29.58,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 20.5,
    "out:Total Energy Use Pre PV": 108.5,
    "out:Total Energy Use Post PV": 108.5,
    "out:Primary Energy": 89.77,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 20.54,
    "out:CO2 Operational/m2": 34.38,
    "out:Total CO2/m2": 54.93,
    "out:Total CO2 (tons)": 282.5,
    "out:Klimatpaverkan": -46.74,
    "out:Initial Cost/MSEK": 10.547,
    "out:Running cost/(Apt SEK y)": 32588,
    "out:Running Cost over RSP/MSEK": 10.694,
    "out:LCP/MSEK": -1.972,
    "out:ROI% old": 11.61,
    "out:Payback discounted": 10,
    "out:Atemp": 5143,
    "out:Umean": 0.29,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FTX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 108,
    "out:Energy use kWh/m2": 108,
    "out:Energy savings %": 74.1,
    "out:Op energy cost/MSEK": 78,
    "out:El price/MSEK": 48,
    "out:Heating price/MSEK": 25,
    "out:DH+ DHW/kWh/m2": 88,
    "out:El bought/kWh/m2": 1434.9,
    "out:Return %": 10,
    "out:Return/kSEK/y": 1095,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 875718.530813,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 432012,
    "out:EL kWh savings": -23653,
    "out:Sewage heat recovery cost": 250448,
    "out:Balancing hydronic system cost": 128574.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 352921,
    "out:DH kr savings": 393131,
    "out:El kr savings": -40210,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 301007,
    "out:% savings (space heating)": 54.29,
    "out:Total_Water Heating (DH)": 152130,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 88.55,
    "out:Etvv": 17,
    "out:Ef": 20.5,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000200110000010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 62.46,
    "out:DHW (DH)": 34.8,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 14.33,
    "out:Total Energy Use Pre PV": 117.5,
    "out:Total Energy Use Post PV": 111.33,
    "out:Primary Energy": 83.52,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 32.49,
    "out:CO2 Operational/m2": 11.61,
    "out:Total CO2/m2": 44.1,
    "out:Total CO2 (tons)": 226.83,
    "out:Klimatpaverkan": -102.41,
    "out:Initial Cost/MSEK": 11.23775,
    "out:Running cost/(Apt SEK y)": 33419,
    "out:Running Cost over RSP/MSEK": 10.986,
    "out:LCP/MSEK": -2.955,
    "out:ROI% old": 10.53,
    "out:Payback discounted": 11,
    "out:Atemp": 5143,
    "out:Umean": 0.29,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FTX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 111,
    "out:Energy use kWh/m2": 111,
    "out:Energy savings %": 69.4,
    "out:Op energy cost/MSEK": 81,
    "out:El price/MSEK": 48,
    "out:Heating price/MSEK": 28,
    "out:DH+ DHW/kWh/m2": 97,
    "out:El bought/kWh/m2": 1013.1,
    "out:Return %": 9,
    "out:Return/kSEK/y": 1060,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 61.265983,
    "out:PV (m2 panels)": 321,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 57484.06,
    "out:PV (kWh sold)": 25763.95,
    "out:PV (% sold (El))": 44.82,
    "out:PV (kWh self-consumed)": 31720.11,
    "out:PV (ratio sold/self-consumed)": 0.81,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 875718.530813,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 385725,
    "out:EL kWh savings": 8067,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 397573,
    "out:DH kr savings": 351010,
    "out:El kr savings": 13715,
    "out:El kr sold": 32849,
    "out:El kr saved": 53924,
    "out:El kr return": 86773,
    "out:% solar/total": 39,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 321225,
    "out:% savings (space heating)": 51.23,
    "out:Total_Water Heating (DH)": 178976,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 92.49,
    "out:Etvv": 20,
    "out:Ef": 14.33,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000200110000110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 58.53,
    "out:DHW (DH)": 34.8,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 14.33,
    "out:Total Energy Use Pre PV": 113.5,
    "out:Total Energy Use Post PV": 107.33,
    "out:Primary Energy": 80.77,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 32.49,
    "out:CO2 Operational/m2": 10.17,
    "out:Total CO2/m2": 42.66,
    "out:Total CO2 (tons)": 219.42,
    "out:Klimatpaverkan": -109.82,
    "out:Initial Cost/MSEK": 11.366375,
    "out:Running cost/(Apt SEK y)": 32131,
    "out:Running Cost over RSP/MSEK": 10.563,
    "out:LCP/MSEK": -2.66,
    "out:ROI% old": 10.94,
    "out:Payback discounted": 11,
    "out:Atemp": 5143,
    "out:Umean": 0.29,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FTX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 107,
    "out:Energy use kWh/m2": 107,
    "out:Energy savings %": 75.7,
    "out:Op energy cost/MSEK": 78,
    "out:El price/MSEK": 47,
    "out:Heating price/MSEK": 27,
    "out:DH+ DHW/kWh/m2": 93,
    "out:El bought/kWh/m2": 1013.1,
    "out:Return %": 10,
    "out:Return/kSEK/y": 1114,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 61.265983,
    "out:PV (m2 panels)": 321,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 57484.06,
    "out:PV (kWh sold)": 25763.95,
    "out:PV (% sold (El))": 44.82,
    "out:PV (kWh self-consumed)": 31720.11,
    "out:PV (ratio sold/self-consumed)": 0.81,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 875718.530813,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 406297,
    "out:EL kWh savings": 8067,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 128574.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 416294,
    "out:DH kr savings": 369730,
    "out:El kr savings": 13715,
    "out:El kr sold": 32849,
    "out:El kr saved": 53924,
    "out:El kr return": 86773,
    "out:% solar/total": 39,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 301007,
    "out:% savings (space heating)": 54.29,
    "out:Total_Water Heating (DH)": 178976,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 88.55,
    "out:Etvv": 20,
    "out:Ef": 14.33,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000200110001010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 62.46,
    "out:DHW (DH)": 29.58,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 14.33,
    "out:Total Energy Use Pre PV": 112.5,
    "out:Total Energy Use Post PV": 106.33,
    "out:Primary Energy": 81.42,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 32.9,
    "out:CO2 Operational/m2": 9.81,
    "out:Total CO2/m2": 42.71,
    "out:Total CO2 (tons)": 219.64,
    "out:Klimatpaverkan": -109.6,
    "out:Initial Cost/MSEK": 11.48825,
    "out:Running cost/(Apt SEK y)": 31810,
    "out:Running Cost over RSP/MSEK": 10.457,
    "out:LCP/MSEK": -2.676,
    "out:ROI% old": 10.96,
    "out:Payback discounted": 11,
    "out:Atemp": 5143,
    "out:Umean": 0.29,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FTX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 106,
    "out:Energy use kWh/m2": 106,
    "out:Energy savings %": 77.4,
    "out:Op energy cost/MSEK": 77,
    "out:El price/MSEK": 46,
    "out:Heating price/MSEK": 26,
    "out:DH+ DHW/kWh/m2": 92,
    "out:El bought/kWh/m2": 1013.1,
    "out:Return %": 10,
    "out:Return/kSEK/y": 1128,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 61.265983,
    "out:PV (m2 panels)": 321,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 57484.06,
    "out:PV (kWh sold)": 25763.95,
    "out:PV (% sold (El))": 44.82,
    "out:PV (kWh self-consumed)": 31720.11,
    "out:PV (ratio sold/self-consumed)": 0.81,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 875718.530813,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 411440,
    "out:EL kWh savings": 8067,
    "out:Sewage heat recovery cost": 250448,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 420974,
    "out:DH kr savings": 374410,
    "out:El kr savings": 13715,
    "out:El kr sold": 32849,
    "out:El kr saved": 53924,
    "out:El kr return": 86773,
    "out:% solar/total": 39,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 321225,
    "out:% savings (space heating)": 51.23,
    "out:Total_Water Heating (DH)": 152130,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 92.49,
    "out:Etvv": 17,
    "out:Ef": 14.33,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000200110001110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 58.53,
    "out:DHW (DH)": 29.58,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 14.33,
    "out:Total Energy Use Pre PV": 108.5,
    "out:Total Energy Use Post PV": 102.33,
    "out:Primary Energy": 78.67,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 32.9,
    "out:CO2 Operational/m2": 8.37,
    "out:Total CO2/m2": 41.27,
    "out:Total CO2 (tons)": 212.23,
    "out:Klimatpaverkan": -117.01,
    "out:Initial Cost/MSEK": 11.616875,
    "out:Running cost/(Apt SEK y)": 30522,
    "out:Running Cost over RSP/MSEK": 10.034,
    "out:LCP/MSEK": -2.382,
    "out:ROI% old": 11.36,
    "out:Payback discounted": 11,
    "out:Atemp": 5143,
    "out:Umean": 0.29,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FTX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 102,
    "out:Energy use kWh/m2": 102,
    "out:Energy savings %": 84.3,
    "out:Op energy cost/MSEK": 74,
    "out:El price/MSEK": 44,
    "out:Heating price/MSEK": 25,
    "out:DH+ DHW/kWh/m2": 88,
    "out:El bought/kWh/m2": 1013.1,
    "out:Return %": 10,
    "out:Return/kSEK/y": 1182,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 61.265983,
    "out:PV (m2 panels)": 321,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 57484.06,
    "out:PV (kWh sold)": 25763.95,
    "out:PV (% sold (El))": 44.82,
    "out:PV (kWh self-consumed)": 31720.11,
    "out:PV (ratio sold/self-consumed)": 0.81,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 875718.530813,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 432012,
    "out:EL kWh savings": 8067,
    "out:Sewage heat recovery cost": 250448,
    "out:Balancing hydronic system cost": 128574.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 439695,
    "out:DH kr savings": 393131,
    "out:El kr savings": 13715,
    "out:El kr sold": 32849,
    "out:El kr saved": 53924,
    "out:El kr return": 86773,
    "out:% solar/total": 39,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 301007,
    "out:% savings (space heating)": 54.29,
    "out:Total_Water Heating (DH)": 152130,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 88.55,
    "out:Etvv": 17,
    "out:Ef": 14.33,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000200120000010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 62.46,
    "out:DHW (DH)": 34.8,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 13.09,
    "out:Total Energy Use Pre PV": 117.5,
    "out:Total Energy Use Post PV": 110.09,
    "out:Primary Energy": 81.28,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 44.85,
    "out:CO2 Operational/m2": -39.07,
    "out:Total CO2/m2": 5.78,
    "out:Total CO2 (tons)": 29.71,
    "out:Klimatpaverkan": -299.53,
    "out:Initial Cost/MSEK": 12.307625,
    "out:Running cost/(Apt SEK y)": 31609,
    "out:Running Cost over RSP/MSEK": 10.408,
    "out:LCP/MSEK": -3.446,
    "out:ROI% old": 10.29,
    "out:Payback discounted": 12,
    "out:Atemp": 5143,
    "out:Umean": 0.29,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FTX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 110,
    "out:Energy use kWh/m2": 110,
    "out:Energy savings %": 70.9,
    "out:Op energy cost/MSEK": 77,
    "out:El price/MSEK": 45,
    "out:Heating price/MSEK": 28,
    "out:DH+ DHW/kWh/m2": 97,
    "out:El bought/kWh/m2": 923.7,
    "out:Return %": 9,
    "out:Return/kSEK/y": 1136,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.33,
    "out:PV (kWp installed)": 122.531965,
    "out:PV (m2 panels)": 643,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 114968.11,
    "out:PV (kWh sold)": 76862.14,
    "out:PV (% sold (El))": 66.86,
    "out:PV (kWh self-consumed)": 38105.97,
    "out:PV (ratio sold/self-consumed)": 2.02,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 875718.530813,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 385725,
    "out:EL kWh savings": 14453,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 473580,
    "out:DH kr savings": 351010,
    "out:El kr savings": 24571,
    "out:El kr sold": 97999,
    "out:El kr saved": 64780,
    "out:El kr return": 162779,
    "out:% solar/total": 47,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 321225,
    "out:% savings (space heating)": 51.23,
    "out:Total_Water Heating (DH)": 178976,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 92.49,
    "out:Etvv": 20,
    "out:Ef": 13.09,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000200120000110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 58.53,
    "out:DHW (DH)": 34.8,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 13.09,
    "out:Total Energy Use Pre PV": 113.5,
    "out:Total Energy Use Post PV": 106.09,
    "out:Primary Energy": 78.53,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 44.85,
    "out:CO2 Operational/m2": -40.51,
    "out:Total CO2/m2": 4.34,
    "out:Total CO2 (tons)": 22.3,
    "out:Klimatpaverkan": -306.94,
    "out:Initial Cost/MSEK": 12.436125,
    "out:Running cost/(Apt SEK y)": 30322,
    "out:Running Cost over RSP/MSEK": 9.985,
    "out:LCP/MSEK": -3.152,
    "out:ROI% old": 10.67,
    "out:Payback discounted": 11,
    "out:Atemp": 5143,
    "out:Umean": 0.29,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FTX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 106,
    "out:Energy use kWh/m2": 106,
    "out:Energy savings %": 77.4,
    "out:Op energy cost/MSEK": 74,
    "out:El price/MSEK": 43,
    "out:Heating price/MSEK": 27,
    "out:DH+ DHW/kWh/m2": 93,
    "out:El bought/kWh/m2": 923.7,
    "out:Return %": 10,
    "out:Return/kSEK/y": 1190,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.33,
    "out:PV (kWp installed)": 122.531965,
    "out:PV (m2 panels)": 643,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 114968.11,
    "out:PV (kWh sold)": 76862.14,
    "out:PV (% sold (El))": 66.86,
    "out:PV (kWh self-consumed)": 38105.97,
    "out:PV (ratio sold/self-consumed)": 2.02,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 875718.530813,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 406297,
    "out:EL kWh savings": 14453,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 128574.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 492300,
    "out:DH kr savings": 369730,
    "out:El kr savings": 24571,
    "out:El kr sold": 97999,
    "out:El kr saved": 64780,
    "out:El kr return": 162779,
    "out:% solar/total": 47,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 301007,
    "out:% savings (space heating)": 54.29,
    "out:Total_Water Heating (DH)": 178976,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 88.55,
    "out:Etvv": 20,
    "out:Ef": 13.09,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000200120001010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 62.46,
    "out:DHW (DH)": 29.58,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 13.09,
    "out:Total Energy Use Pre PV": 112.5,
    "out:Total Energy Use Post PV": 105.09,
    "out:Primary Energy": 79.18,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 45.25,
    "out:CO2 Operational/m2": -40.87,
    "out:Total CO2/m2": 4.38,
    "out:Total CO2 (tons)": 22.52,
    "out:Klimatpaverkan": -306.72,
    "out:Initial Cost/MSEK": 12.5575,
    "out:Running cost/(Apt SEK y)": 30000,
    "out:Running Cost over RSP/MSEK": 9.879,
    "out:LCP/MSEK": -3.167,
    "out:ROI% old": 10.68,
    "out:Payback discounted": 11,
    "out:Atemp": 5143,
    "out:Umean": 0.29,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FTX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 105,
    "out:Energy use kWh/m2": 105,
    "out:Energy savings %": 79,
    "out:Op energy cost/MSEK": 73,
    "out:El price/MSEK": 43,
    "out:Heating price/MSEK": 26,
    "out:DH+ DHW/kWh/m2": 92,
    "out:El bought/kWh/m2": 923.7,
    "out:Return %": 10,
    "out:Return/kSEK/y": 1204,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.33,
    "out:PV (kWp installed)": 122.531965,
    "out:PV (m2 panels)": 643,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 114968.11,
    "out:PV (kWh sold)": 76862.14,
    "out:PV (% sold (El))": 66.86,
    "out:PV (kWh self-consumed)": 38105.97,
    "out:PV (ratio sold/self-consumed)": 2.02,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 875718.530813,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 411440,
    "out:EL kWh savings": 14453,
    "out:Sewage heat recovery cost": 250448,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 496980,
    "out:DH kr savings": 374410,
    "out:El kr savings": 24571,
    "out:El kr sold": 97999,
    "out:El kr saved": 64780,
    "out:El kr return": 162779,
    "out:% solar/total": 47,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 321225,
    "out:% savings (space heating)": 51.23,
    "out:Total_Water Heating (DH)": 152130,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 92.49,
    "out:Etvv": 17,
    "out:Ef": 13.09,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000200120001110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 58.53,
    "out:DHW (DH)": 29.58,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 13.09,
    "out:Total Energy Use Pre PV": 108.5,
    "out:Total Energy Use Post PV": 101.09,
    "out:Primary Energy": 76.43,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 45.25,
    "out:CO2 Operational/m2": -42.31,
    "out:Total CO2/m2": 2.94,
    "out:Total CO2 (tons)": 15.11,
    "out:Klimatpaverkan": -314.13,
    "out:Initial Cost/MSEK": 12.68625,
    "out:Running cost/(Apt SEK y)": 28713,
    "out:Running Cost over RSP/MSEK": 9.456,
    "out:LCP/MSEK": -2.873,
    "out:ROI% old": 11.05,
    "out:Payback discounted": 11,
    "out:Atemp": 5143,
    "out:Umean": 0.29,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FTX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 101,
    "out:Energy use kWh/m2": 101,
    "out:Energy savings %": 86.1,
    "out:Op energy cost/MSEK": 70,
    "out:El price/MSEK": 41,
    "out:Heating price/MSEK": 25,
    "out:DH+ DHW/kWh/m2": 88,
    "out:El bought/kWh/m2": 923.7,
    "out:Return %": 10,
    "out:Return/kSEK/y": 1258,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.33,
    "out:PV (kWp installed)": 122.531965,
    "out:PV (m2 panels)": 643,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 114968.11,
    "out:PV (kWh sold)": 76862.14,
    "out:PV (% sold (El))": 66.86,
    "out:PV (kWh self-consumed)": 38105.97,
    "out:PV (ratio sold/self-consumed)": 2.02,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 875718.530813,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 432012,
    "out:EL kWh savings": 14453,
    "out:Sewage heat recovery cost": 250448,
    "out:Balancing hydronic system cost": 128574.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 515701,
    "out:DH kr savings": 393131,
    "out:El kr savings": 24571,
    "out:El kr sold": 97999,
    "out:El kr saved": 64780,
    "out:El kr return": 162779,
    "out:% solar/total": 47,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 301007,
    "out:% savings (space heating)": 54.29,
    "out:Total_Water Heating (DH)": 152130,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 88.55,
    "out:Etvv": 17,
    "out:Ef": 13.09,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000000000000001",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 73.27,
    "out:DHW (DH)": 43.5,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 20.5,
    "out:Total Energy Use Pre PV": 137.5,
    "out:Total Energy Use Post PV": 137.5,
    "out:Primary Energy": 105.68,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 30.13,
    "out:CO2 Operational/m2": 44.82,
    "out:Total CO2/m2": 74.95,
    "out:Total CO2 (tons)": 385.47,
    "out:Klimatpaverkan": 56.23,
    "out:Initial Cost/MSEK": 10.189875,
    "out:Running cost/(Apt SEK y)": 41921,
    "out:Running Cost over RSP/MSEK": 13.762,
    "out:LCP/MSEK": -4.683,
    "out:ROI% old": 7.72,
    "out:Payback discounted": 16,
    "out:Atemp": 5143,
    "out:Umean": 0.46,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 137,
    "out:Energy use kWh/m2": 137,
    "out:Energy savings %": 37.2,
    "out:Op energy cost/MSEK": 101,
    "out:El price/MSEK": 61,
    "out:Heating price/MSEK": 34,
    "out:DH+ DHW/kWh/m2": 117,
    "out:El bought/kWh/m2": 1434.9,
    "out:Return %": 7,
    "out:Return/kSEK/y": 703,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 1773600,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.05,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 2,
    "out:Payback Time": 50,
    "out:DH kWh savings": 282865,
    "out:EL kWh savings": -23653,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 217198,
    "out:DH kr savings": 257407,
    "out:El kr savings": -40210,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 376807,
    "out:% savings (space heating)": 42.79,
    "out:Total_Water Heating (DH)": 223720,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 103.29,
    "out:Etvv": 25,
    "out:Ef": 20.5,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000000000000101",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 68.84,
    "out:DHW (DH)": 43.5,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 20.5,
    "out:Total Energy Use Pre PV": 132.5,
    "out:Total Energy Use Post PV": 132.5,
    "out:Primary Energy": 102.59,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 30.13,
    "out:CO2 Operational/m2": 43.02,
    "out:Total CO2/m2": 73.15,
    "out:Total CO2 (tons)": 376.21,
    "out:Klimatpaverkan": 46.97,
    "out:Initial Cost/MSEK": 10.3185,
    "out:Running cost/(Apt SEK y)": 40312,
    "out:Running Cost over RSP/MSEK": 13.233,
    "out:LCP/MSEK": -4.282,
    "out:ROI% old": 8.36,
    "out:Payback discounted": 14,
    "out:Atemp": 5143,
    "out:Umean": 0.46,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 132,
    "out:Energy use kWh/m2": 132,
    "out:Energy savings %": 42.4,
    "out:Op energy cost/MSEK": 97,
    "out:El price/MSEK": 59,
    "out:Heating price/MSEK": 32,
    "out:DH+ DHW/kWh/m2": 112,
    "out:El bought/kWh/m2": 1434.9,
    "out:Return %": 7,
    "out:Return/kSEK/y": 771,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 1773600,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.05,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 2,
    "out:Payback Time": 50,
    "out:DH kWh savings": 308580,
    "out:EL kWh savings": -23653,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 128574.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 240598,
    "out:DH kr savings": 280808,
    "out:El kr savings": -40210,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 354063,
    "out:% savings (space heating)": 46.24,
    "out:Total_Water Heating (DH)": 223720,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 98.87,
    "out:Etvv": 25,
    "out:Ef": 20.5,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000000000001001",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 73.27,
    "out:DHW (DH)": 36.98,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 20.5,
    "out:Total Energy Use Pre PV": 130.5,
    "out:Total Energy Use Post PV": 130.5,
    "out:Primary Energy": 103.06,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 30.53,
    "out:CO2 Operational/m2": 42.3,
    "out:Total CO2/m2": 72.83,
    "out:Total CO2 (tons)": 374.58,
    "out:Klimatpaverkan": 45.34,
    "out:Initial Cost/MSEK": 10.440375,
    "out:Running cost/(Apt SEK y)": 39668,
    "out:Running Cost over RSP/MSEK": 13.021,
    "out:LCP/MSEK": -4.193,
    "out:ROI% old": 8.55,
    "out:Payback discounted": 14,
    "out:Atemp": 5143,
    "out:Umean": 0.46,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 130,
    "out:Energy use kWh/m2": 130,
    "out:Energy savings %": 44.6,
    "out:Op energy cost/MSEK": 95,
    "out:El price/MSEK": 58,
    "out:Heating price/MSEK": 32,
    "out:DH+ DHW/kWh/m2": 110,
    "out:El bought/kWh/m2": 1434.9,
    "out:Return %": 8,
    "out:Return/kSEK/y": 798,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 1773600,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.05,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 2,
    "out:Payback Time": 50,
    "out:DH kWh savings": 318866,
    "out:EL kWh savings": -23653,
    "out:Sewage heat recovery cost": 250448,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 249959,
    "out:DH kr savings": 290168,
    "out:El kr savings": -40210,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 376807,
    "out:% savings (space heating)": 42.79,
    "out:Total_Water Heating (DH)": 190162,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 103.29,
    "out:Etvv": 21.25,
    "out:Ef": 20.5,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000000000001101",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 68.84,
    "out:DHW (DH)": 36.98,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 20.5,
    "out:Total Energy Use Pre PV": 126.5,
    "out:Total Energy Use Post PV": 126.5,
    "out:Primary Energy": 99.96,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 30.53,
    "out:CO2 Operational/m2": 40.86,
    "out:Total CO2/m2": 71.39,
    "out:Total CO2 (tons)": 367.17,
    "out:Klimatpaverkan": 37.93,
    "out:Initial Cost/MSEK": 10.568875,
    "out:Running cost/(Apt SEK y)": 38381,
    "out:Running Cost over RSP/MSEK": 12.598,
    "out:LCP/MSEK": -3.898,
    "out:ROI% old": 9.02,
    "out:Payback discounted": 13,
    "out:Atemp": 5143,
    "out:Umean": 0.46,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 126,
    "out:Energy use kWh/m2": 126,
    "out:Energy savings %": 49.2,
    "out:Op energy cost/MSEK": 92,
    "out:El price/MSEK": 57,
    "out:Heating price/MSEK": 30,
    "out:DH+ DHW/kWh/m2": 106,
    "out:El bought/kWh/m2": 1434.9,
    "out:Return %": 8,
    "out:Return/kSEK/y": 852,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 1773600,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.05,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 339438,
    "out:EL kWh savings": -23653,
    "out:Sewage heat recovery cost": 250448,
    "out:Balancing hydronic system cost": 128574.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 268679,
    "out:DH kr savings": 308889,
    "out:El kr savings": -40210,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 354063,
    "out:% savings (space heating)": 46.24,
    "out:Total_Water Heating (DH)": 190162,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 98.87,
    "out:Etvv": 21.25,
    "out:Ef": 20.5,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000000010000001",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 73.27,
    "out:DHW (DH)": 43.5,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 14.33,
    "out:Total Energy Use Pre PV": 137.5,
    "out:Total Energy Use Post PV": 131.33,
    "out:Primary Energy": 94.58,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 42.48,
    "out:CO2 Operational/m2": 18.81,
    "out:Total CO2/m2": 61.29,
    "out:Total CO2 (tons)": 315.21,
    "out:Klimatpaverkan": -14.03,
    "out:Initial Cost/MSEK": 11.25975,
    "out:Running cost/(Apt SEK y)": 39855,
    "out:Running Cost over RSP/MSEK": 13.101,
    "out:LCP/MSEK": -5.092,
    "out:ROI% old": 7.83,
    "out:Payback discounted": 15,
    "out:Atemp": 5143,
    "out:Umean": 0.46,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 131,
    "out:Energy use kWh/m2": 131,
    "out:Energy savings %": 43.5,
    "out:Op energy cost/MSEK": 96,
    "out:El price/MSEK": 57,
    "out:Heating price/MSEK": 34,
    "out:DH+ DHW/kWh/m2": 117,
    "out:El bought/kWh/m2": 1013.1,
    "out:Return %": 7,
    "out:Return/kSEK/y": 790,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 61.265983,
    "out:PV (m2 panels)": 321,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 57484.06,
    "out:PV (kWh sold)": 25763.95,
    "out:PV (% sold (El))": 44.82,
    "out:PV (kWh self-consumed)": 31720.11,
    "out:PV (ratio sold/self-consumed)": 0.81,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 1773600,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.05,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 282865,
    "out:EL kWh savings": 8067,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 303971,
    "out:DH kr savings": 257407,
    "out:El kr savings": 13715,
    "out:El kr sold": 32849,
    "out:El kr saved": 53924,
    "out:El kr return": 86773,
    "out:% solar/total": 39,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 376807,
    "out:% savings (space heating)": 42.79,
    "out:Total_Water Heating (DH)": 223720,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 103.29,
    "out:Etvv": 25,
    "out:Ef": 14.33,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000000010000101",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 68.84,
    "out:DHW (DH)": 43.5,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 14.33,
    "out:Total Energy Use Pre PV": 132.5,
    "out:Total Energy Use Post PV": 126.33,
    "out:Primary Energy": 91.49,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 42.48,
    "out:CO2 Operational/m2": 17.01,
    "out:Total CO2/m2": 59.49,
    "out:Total CO2 (tons)": 305.95,
    "out:Klimatpaverkan": -23.29,
    "out:Initial Cost/MSEK": 11.38825,
    "out:Running cost/(Apt SEK y)": 38246,
    "out:Running Cost over RSP/MSEK": 12.573,
    "out:LCP/MSEK": -4.692,
    "out:ROI% old": 8.4,
    "out:Payback discounted": 14,
    "out:Atemp": 5143,
    "out:Umean": 0.46,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 126,
    "out:Energy use kWh/m2": 126,
    "out:Energy savings %": 49.2,
    "out:Op energy cost/MSEK": 93,
    "out:El price/MSEK": 55,
    "out:Heating price/MSEK": 32,
    "out:DH+ DHW/kWh/m2": 112,
    "out:El bought/kWh/m2": 1013.1,
    "out:Return %": 8,
    "out:Return/kSEK/y": 858,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 61.265983,
    "out:PV (m2 panels)": 321,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 57484.06,
    "out:PV (kWh sold)": 25763.95,
    "out:PV (% sold (El))": 44.82,
    "out:PV (kWh self-consumed)": 31720.11,
    "out:PV (ratio sold/self-consumed)": 0.81,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 1773600,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.05,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 308580,
    "out:EL kWh savings": 8067,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 128574.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 327371,
    "out:DH kr savings": 280808,
    "out:El kr savings": 13715,
    "out:El kr sold": 32849,
    "out:El kr saved": 53924,
    "out:El kr return": 86773,
    "out:% solar/total": 39,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 354063,
    "out:% savings (space heating)": 46.24,
    "out:Total_Water Heating (DH)": 223720,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 98.87,
    "out:Etvv": 25,
    "out:Ef": 14.33,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000000010001001",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 73.27,
    "out:DHW (DH)": 36.98,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 14.33,
    "out:Total Energy Use Pre PV": 130.5,
    "out:Total Energy Use Post PV": 124.33,
    "out:Primary Energy": 91.96,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 42.88,
    "out:CO2 Operational/m2": 16.29,
    "out:Total CO2/m2": 59.17,
    "out:Total CO2 (tons)": 304.32,
    "out:Klimatpaverkan": -24.92,
    "out:Initial Cost/MSEK": 11.510125,
    "out:Running cost/(Apt SEK y)": 37602,
    "out:Running Cost over RSP/MSEK": 12.361,
    "out:LCP/MSEK": -4.602,
    "out:ROI% old": 8.57,
    "out:Payback discounted": 14,
    "out:Atemp": 5143,
    "out:Umean": 0.46,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 124,
    "out:Energy use kWh/m2": 124,
    "out:Energy savings %": 51.6,
    "out:Op energy cost/MSEK": 91,
    "out:El price/MSEK": 54,
    "out:Heating price/MSEK": 32,
    "out:DH+ DHW/kWh/m2": 110,
    "out:El bought/kWh/m2": 1013.1,
    "out:Return %": 8,
    "out:Return/kSEK/y": 885,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 61.265983,
    "out:PV (m2 panels)": 321,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 57484.06,
    "out:PV (kWh sold)": 25763.95,
    "out:PV (% sold (El))": 44.82,
    "out:PV (kWh self-consumed)": 31720.11,
    "out:PV (ratio sold/self-consumed)": 0.81,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 1773600,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.05,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 318866,
    "out:EL kWh savings": 8067,
    "out:Sewage heat recovery cost": 250448,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 336732,
    "out:DH kr savings": 290168,
    "out:El kr savings": 13715,
    "out:El kr sold": 32849,
    "out:El kr saved": 53924,
    "out:El kr return": 86773,
    "out:% solar/total": 39,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 376807,
    "out:% savings (space heating)": 42.79,
    "out:Total_Water Heating (DH)": 190162,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 103.29,
    "out:Etvv": 21.25,
    "out:Ef": 14.33,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000000010001101",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 68.84,
    "out:DHW (DH)": 36.98,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 14.33,
    "out:Total Energy Use Pre PV": 126.5,
    "out:Total Energy Use Post PV": 120.33,
    "out:Primary Energy": 88.86,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 42.88,
    "out:CO2 Operational/m2": 14.85,
    "out:Total CO2/m2": 57.73,
    "out:Total CO2 (tons)": 296.91,
    "out:Klimatpaverkan": -32.33,
    "out:Initial Cost/MSEK": 11.63875,
    "out:Running cost/(Apt SEK y)": 36315,
    "out:Running Cost over RSP/MSEK": 11.938,
    "out:LCP/MSEK": -4.308,
    "out:ROI% old": 9,
    "out:Payback discounted": 13,
    "out:Atemp": 5143,
    "out:Umean": 0.46,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 120,
    "out:Energy use kWh/m2": 120,
    "out:Energy savings %": 56.7,
    "out:Op energy cost/MSEK": 88,
    "out:El price/MSEK": 52,
    "out:Heating price/MSEK": 30,
    "out:DH+ DHW/kWh/m2": 106,
    "out:El bought/kWh/m2": 1013.1,
    "out:Return %": 8,
    "out:Return/kSEK/y": 939,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 61.265983,
    "out:PV (m2 panels)": 321,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 57484.06,
    "out:PV (kWh sold)": 25763.95,
    "out:PV (% sold (El))": 44.82,
    "out:PV (kWh self-consumed)": 31720.11,
    "out:PV (ratio sold/self-consumed)": 0.81,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 1773600,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.05,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 339438,
    "out:EL kWh savings": 8067,
    "out:Sewage heat recovery cost": 250448,
    "out:Balancing hydronic system cost": 128574.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 355452,
    "out:DH kr savings": 308889,
    "out:El kr savings": 13715,
    "out:El kr sold": 32849,
    "out:El kr saved": 53924,
    "out:El kr return": 86773,
    "out:% solar/total": 39,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 354063,
    "out:% savings (space heating)": 46.24,
    "out:Total_Water Heating (DH)": 190162,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 98.87,
    "out:Etvv": 21.25,
    "out:Ef": 14.33,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000000020000001",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 73.27,
    "out:DHW (DH)": 43.5,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 13.09,
    "out:Total Energy Use Pre PV": 137.5,
    "out:Total Energy Use Post PV": 130.09,
    "out:Primary Energy": 92.35,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 54.83,
    "out:CO2 Operational/m2": -31.87,
    "out:Total CO2/m2": 22.96,
    "out:Total CO2 (tons)": 118.09,
    "out:Klimatpaverkan": -211.15,
    "out:Initial Cost/MSEK": 12.3295,
    "out:Running cost/(Apt SEK y)": 38046,
    "out:Running Cost over RSP/MSEK": 12.523,
    "out:LCP/MSEK": -5.584,
    "out:ROI% old": 7.82,
    "out:Payback discounted": 16,
    "out:Atemp": 5143,
    "out:Umean": 0.46,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 130,
    "out:Energy use kWh/m2": 130,
    "out:Energy savings %": 44.6,
    "out:Op energy cost/MSEK": 93,
    "out:El price/MSEK": 54,
    "out:Heating price/MSEK": 34,
    "out:DH+ DHW/kWh/m2": 117,
    "out:El bought/kWh/m2": 923.7,
    "out:Return %": 7,
    "out:Return/kSEK/y": 866,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.33,
    "out:PV (kWp installed)": 122.531965,
    "out:PV (m2 panels)": 643,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 114968.11,
    "out:PV (kWh sold)": 76862.14,
    "out:PV (% sold (El))": 66.86,
    "out:PV (kWh self-consumed)": 38105.97,
    "out:PV (ratio sold/self-consumed)": 2.02,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 1773600,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.05,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 282865,
    "out:EL kWh savings": 14453,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 379977,
    "out:DH kr savings": 257407,
    "out:El kr savings": 24571,
    "out:El kr sold": 97999,
    "out:El kr saved": 64780,
    "out:El kr return": 162779,
    "out:% solar/total": 47,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 376807,
    "out:% savings (space heating)": 42.79,
    "out:Total_Water Heating (DH)": 223720,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 103.29,
    "out:Etvv": 25,
    "out:Ef": 13.09,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000000020000101",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 68.84,
    "out:DHW (DH)": 43.5,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 13.09,
    "out:Total Energy Use Pre PV": 132.5,
    "out:Total Energy Use Post PV": 125.09,
    "out:Primary Energy": 89.25,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 54.83,
    "out:CO2 Operational/m2": -33.67,
    "out:Total CO2/m2": 21.16,
    "out:Total CO2 (tons)": 108.83,
    "out:Klimatpaverkan": -220.41,
    "out:Initial Cost/MSEK": 12.458,
    "out:Running cost/(Apt SEK y)": 36436,
    "out:Running Cost over RSP/MSEK": 11.994,
    "out:LCP/MSEK": -5.183,
    "out:ROI% old": 8.34,
    "out:Payback discounted": 15,
    "out:Atemp": 5143,
    "out:Umean": 0.46,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 125,
    "out:Energy use kWh/m2": 125,
    "out:Energy savings %": 50.4,
    "out:Op energy cost/MSEK": 89,
    "out:El price/MSEK": 52,
    "out:Heating price/MSEK": 32,
    "out:DH+ DHW/kWh/m2": 112,
    "out:El bought/kWh/m2": 923.7,
    "out:Return %": 7,
    "out:Return/kSEK/y": 934,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.33,
    "out:PV (kWp installed)": 122.531965,
    "out:PV (m2 panels)": 643,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 114968.11,
    "out:PV (kWh sold)": 76862.14,
    "out:PV (% sold (El))": 66.86,
    "out:PV (kWh self-consumed)": 38105.97,
    "out:PV (ratio sold/self-consumed)": 2.02,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 1773600,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.05,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 308580,
    "out:EL kWh savings": 14453,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 128574.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 403378,
    "out:DH kr savings": 280808,
    "out:El kr savings": 24571,
    "out:El kr sold": 97999,
    "out:El kr saved": 64780,
    "out:El kr return": 162779,
    "out:% solar/total": 47,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 354063,
    "out:% savings (space heating)": 46.24,
    "out:Total_Water Heating (DH)": 223720,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 98.87,
    "out:Etvv": 25,
    "out:Ef": 13.09,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000000020001001",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 73.27,
    "out:DHW (DH)": 36.98,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 13.09,
    "out:Total Energy Use Pre PV": 130.5,
    "out:Total Energy Use Post PV": 123.09,
    "out:Primary Energy": 89.72,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 55.23,
    "out:CO2 Operational/m2": -34.39,
    "out:Total CO2/m2": 20.84,
    "out:Total CO2 (tons)": 107.2,
    "out:Klimatpaverkan": -222.04,
    "out:Initial Cost/MSEK": 12.58,
    "out:Running cost/(Apt SEK y)": 35793,
    "out:Running Cost over RSP/MSEK": 11.783,
    "out:LCP/MSEK": -5.094,
    "out:ROI% old": 8.5,
    "out:Payback discounted": 14,
    "out:Atemp": 5143,
    "out:Umean": 0.46,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 123,
    "out:Energy use kWh/m2": 123,
    "out:Energy savings %": 52.8,
    "out:Op energy cost/MSEK": 87,
    "out:El price/MSEK": 51,
    "out:Heating price/MSEK": 32,
    "out:DH+ DHW/kWh/m2": 110,
    "out:El bought/kWh/m2": 923.7,
    "out:Return %": 8,
    "out:Return/kSEK/y": 961,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.33,
    "out:PV (kWp installed)": 122.531965,
    "out:PV (m2 panels)": 643,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 114968.11,
    "out:PV (kWh sold)": 76862.14,
    "out:PV (% sold (El))": 66.86,
    "out:PV (kWh self-consumed)": 38105.97,
    "out:PV (ratio sold/self-consumed)": 2.02,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 1773600,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.05,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 318866,
    "out:EL kWh savings": 14453,
    "out:Sewage heat recovery cost": 250448,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 412738,
    "out:DH kr savings": 290168,
    "out:El kr savings": 24571,
    "out:El kr sold": 97999,
    "out:El kr saved": 64780,
    "out:El kr return": 162779,
    "out:% solar/total": 47,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 376807,
    "out:% savings (space heating)": 42.79,
    "out:Total_Water Heating (DH)": 190162,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 103.29,
    "out:Etvv": 21.25,
    "out:Ef": 13.09,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000000020001101",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 68.84,
    "out:DHW (DH)": 36.98,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 13.09,
    "out:Total Energy Use Pre PV": 126.5,
    "out:Total Energy Use Post PV": 119.09,
    "out:Primary Energy": 86.63,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 55.23,
    "out:CO2 Operational/m2": -35.83,
    "out:Total CO2/m2": 19.4,
    "out:Total CO2 (tons)": 99.79,
    "out:Klimatpaverkan": -229.45,
    "out:Initial Cost/MSEK": 12.70875,
    "out:Running cost/(Apt SEK y)": 34505,
    "out:Running Cost over RSP/MSEK": 11.36,
    "out:LCP/MSEK": -4.799,
    "out:ROI% old": 8.89,
    "out:Payback discounted": 14,
    "out:Atemp": 5143,
    "out:Umean": 0.46,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 119,
    "out:Energy use kWh/m2": 119,
    "out:Energy savings %": 58,
    "out:Op energy cost/MSEK": 84,
    "out:El price/MSEK": 49,
    "out:Heating price/MSEK": 30,
    "out:DH+ DHW/kWh/m2": 106,
    "out:El bought/kWh/m2": 923.7,
    "out:Return %": 8,
    "out:Return/kSEK/y": 1015,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.33,
    "out:PV (kWp installed)": 122.531965,
    "out:PV (m2 panels)": 643,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 114968.11,
    "out:PV (kWh sold)": 76862.14,
    "out:PV (% sold (El))": 66.86,
    "out:PV (kWh self-consumed)": 38105.97,
    "out:PV (ratio sold/self-consumed)": 2.02,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 1773600,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.05,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 339438,
    "out:EL kWh savings": 14453,
    "out:Sewage heat recovery cost": 250448,
    "out:Balancing hydronic system cost": 128574.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 431458,
    "out:DH kr savings": 308889,
    "out:El kr savings": 24571,
    "out:El kr sold": 97999,
    "out:El kr saved": 64780,
    "out:El kr return": 162779,
    "out:% solar/total": 47,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 354063,
    "out:% savings (space heating)": 46.24,
    "out:Total_Water Heating (DH)": 190162,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 98.87,
    "out:Etvv": 21.25,
    "out:Ef": 13.09,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000000100000001",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 73.27,
    "out:DHW (DH)": 34.8,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 20.5,
    "out:Total Energy Use Pre PV": 128.5,
    "out:Total Energy Use Post PV": 128.5,
    "out:Primary Energy": 102.18,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 30.13,
    "out:CO2 Operational/m2": 41.58,
    "out:Total CO2/m2": 71.71,
    "out:Total CO2 (tons)": 368.81,
    "out:Klimatpaverkan": 39.57,
    "out:Initial Cost/MSEK": 10.30075,
    "out:Running cost/(Apt SEK y)": 39025,
    "out:Running Cost over RSP/MSEK": 12.81,
    "out:LCP/MSEK": -3.842,
    "out:ROI% old": 8.96,
    "out:Payback discounted": 13,
    "out:Atemp": 5143,
    "out:Umean": 0.46,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 128,
    "out:Energy use kWh/m2": 128,
    "out:Energy savings %": 46.9,
    "out:Op energy cost/MSEK": 94,
    "out:El price/MSEK": 57,
    "out:Heating price/MSEK": 31,
    "out:DH+ DHW/kWh/m2": 108,
    "out:El bought/kWh/m2": 1434.9,
    "out:Return %": 8,
    "out:Return/kSEK/y": 825,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 1773600,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.05,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 329152,
    "out:EL kWh savings": -23653,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 259319,
    "out:DH kr savings": 299528,
    "out:El kr savings": -40210,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 376807,
    "out:% savings (space heating)": 42.79,
    "out:Total_Water Heating (DH)": 178976,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 103.29,
    "out:Etvv": 20,
    "out:Ef": 20.5,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000000100000101",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 68.84,
    "out:DHW (DH)": 34.8,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 20.5,
    "out:Total Energy Use Pre PV": 124.5,
    "out:Total Energy Use Post PV": 124.5,
    "out:Primary Energy": 99.09,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 30.13,
    "out:CO2 Operational/m2": 40.14,
    "out:Total CO2/m2": 70.27,
    "out:Total CO2 (tons)": 361.4,
    "out:Klimatpaverkan": 32.16,
    "out:Initial Cost/MSEK": 10.429375,
    "out:Running cost/(Apt SEK y)": 37738,
    "out:Running Cost over RSP/MSEK": 12.387,
    "out:LCP/MSEK": -3.547,
    "out:ROI% old": 9.43,
    "out:Payback discounted": 13,
    "out:Atemp": 5143,
    "out:Umean": 0.46,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 124,
    "out:Energy use kWh/m2": 124,
    "out:Energy savings %": 51.6,
    "out:Op energy cost/MSEK": 90,
    "out:El price/MSEK": 56,
    "out:Heating price/MSEK": 30,
    "out:DH+ DHW/kWh/m2": 104,
    "out:El bought/kWh/m2": 1434.9,
    "out:Return %": 8,
    "out:Return/kSEK/y": 879,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 1773600,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.05,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 349724,
    "out:EL kWh savings": -23653,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 128574.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 278039,
    "out:DH kr savings": 318249,
    "out:El kr savings": -40210,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 354063,
    "out:% savings (space heating)": 46.24,
    "out:Total_Water Heating (DH)": 178976,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 98.87,
    "out:Etvv": 20,
    "out:Ef": 20.5,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000000100001001",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 73.27,
    "out:DHW (DH)": 29.58,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 20.5,
    "out:Total Energy Use Pre PV": 123.5,
    "out:Total Energy Use Post PV": 123.5,
    "out:Primary Energy": 100.08,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 30.53,
    "out:CO2 Operational/m2": 39.78,
    "out:Total CO2/m2": 70.31,
    "out:Total CO2 (tons)": 361.62,
    "out:Klimatpaverkan": 32.38,
    "out:Initial Cost/MSEK": 10.55125,
    "out:Running cost/(Apt SEK y)": 37416,
    "out:Running Cost over RSP/MSEK": 12.281,
    "out:LCP/MSEK": -3.563,
    "out:ROI% old": 9.46,
    "out:Payback discounted": 13,
    "out:Atemp": 5143,
    "out:Umean": 0.46,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 123,
    "out:Energy use kWh/m2": 123,
    "out:Energy savings %": 52.8,
    "out:Op energy cost/MSEK": 90,
    "out:El price/MSEK": 55,
    "out:Heating price/MSEK": 30,
    "out:DH+ DHW/kWh/m2": 103,
    "out:El bought/kWh/m2": 1434.9,
    "out:Return %": 8,
    "out:Return/kSEK/y": 892,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 1773600,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.05,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 354867,
    "out:EL kWh savings": -23653,
    "out:Sewage heat recovery cost": 250448,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 282719,
    "out:DH kr savings": 322929,
    "out:El kr savings": -40210,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 376807,
    "out:% savings (space heating)": 42.79,
    "out:Total_Water Heating (DH)": 152130,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 103.29,
    "out:Etvv": 17,
    "out:Ef": 20.5,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000000100001101",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 68.84,
    "out:DHW (DH)": 29.58,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 20.5,
    "out:Total Energy Use Pre PV": 118.5,
    "out:Total Energy Use Post PV": 118.5,
    "out:Primary Energy": 96.99,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 30.53,
    "out:CO2 Operational/m2": 37.98,
    "out:Total CO2/m2": 68.51,
    "out:Total CO2 (tons)": 352.36,
    "out:Klimatpaverkan": 23.12,
    "out:Initial Cost/MSEK": 10.67975,
    "out:Running cost/(Apt SEK y)": 35807,
    "out:Running Cost over RSP/MSEK": 11.752,
    "out:LCP/MSEK": -3.163,
    "out:ROI% old": 10.05,
    "out:Payback discounted": 12,
    "out:Atemp": 5143,
    "out:Umean": 0.46,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 118,
    "out:Energy use kWh/m2": 118,
    "out:Energy savings %": 59.3,
    "out:Op energy cost/MSEK": 86,
    "out:El price/MSEK": 53,
    "out:Heating price/MSEK": 28,
    "out:DH+ DHW/kWh/m2": 98,
    "out:El bought/kWh/m2": 1434.9,
    "out:Return %": 9,
    "out:Return/kSEK/y": 960,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 1773600,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.05,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 380582,
    "out:EL kWh savings": -23653,
    "out:Sewage heat recovery cost": 250448,
    "out:Balancing hydronic system cost": 128574.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 306120,
    "out:DH kr savings": 346330,
    "out:El kr savings": -40210,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 354063,
    "out:% savings (space heating)": 46.24,
    "out:Total_Water Heating (DH)": 152130,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 98.87,
    "out:Etvv": 17,
    "out:Ef": 20.5,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000000110000001",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 73.27,
    "out:DHW (DH)": 34.8,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 14.33,
    "out:Total Energy Use Pre PV": 128.5,
    "out:Total Energy Use Post PV": 122.33,
    "out:Primary Energy": 91.08,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 42.48,
    "out:CO2 Operational/m2": 15.57,
    "out:Total CO2/m2": 58.05,
    "out:Total CO2 (tons)": 298.55,
    "out:Klimatpaverkan": -30.69,
    "out:Initial Cost/MSEK": 11.3705,
    "out:Running cost/(Apt SEK y)": 36959,
    "out:Running Cost over RSP/MSEK": 12.149,
    "out:LCP/MSEK": -4.251,
    "out:ROI% old": 8.95,
    "out:Payback discounted": 13,
    "out:Atemp": 5143,
    "out:Umean": 0.46,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 122,
    "out:Energy use kWh/m2": 122,
    "out:Energy savings %": 54.1,
    "out:Op energy cost/MSEK": 89,
    "out:El price/MSEK": 53,
    "out:Heating price/MSEK": 31,
    "out:DH+ DHW/kWh/m2": 108,
    "out:El bought/kWh/m2": 1013.1,
    "out:Return %": 8,
    "out:Return/kSEK/y": 912,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 61.265983,
    "out:PV (m2 panels)": 321,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 57484.06,
    "out:PV (kWh sold)": 25763.95,
    "out:PV (% sold (El))": 44.82,
    "out:PV (kWh self-consumed)": 31720.11,
    "out:PV (ratio sold/self-consumed)": 0.81,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 1773600,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.05,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 329152,
    "out:EL kWh savings": 8067,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 346092,
    "out:DH kr savings": 299528,
    "out:El kr savings": 13715,
    "out:El kr sold": 32849,
    "out:El kr saved": 53924,
    "out:El kr return": 86773,
    "out:% solar/total": 39,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 376807,
    "out:% savings (space heating)": 42.79,
    "out:Total_Water Heating (DH)": 178976,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 103.29,
    "out:Etvv": 20,
    "out:Ef": 14.33,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000000110000101",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 68.84,
    "out:DHW (DH)": 34.8,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 14.33,
    "out:Total Energy Use Pre PV": 124.5,
    "out:Total Energy Use Post PV": 118.33,
    "out:Primary Energy": 87.99,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 42.48,
    "out:CO2 Operational/m2": 14.13,
    "out:Total CO2/m2": 56.61,
    "out:Total CO2 (tons)": 291.14,
    "out:Klimatpaverkan": -38.1,
    "out:Initial Cost/MSEK": 11.499125,
    "out:Running cost/(Apt SEK y)": 35671,
    "out:Running Cost over RSP/MSEK": 11.726,
    "out:LCP/MSEK": -3.956,
    "out:ROI% old": 9.37,
    "out:Payback discounted": 13,
    "out:Atemp": 5143,
    "out:Umean": 0.46,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 118,
    "out:Energy use kWh/m2": 118,
    "out:Energy savings %": 59.3,
    "out:Op energy cost/MSEK": 86,
    "out:El price/MSEK": 52,
    "out:Heating price/MSEK": 30,
    "out:DH+ DHW/kWh/m2": 104,
    "out:El bought/kWh/m2": 1013.1,
    "out:Return %": 8,
    "out:Return/kSEK/y": 966,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 61.265983,
    "out:PV (m2 panels)": 321,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 57484.06,
    "out:PV (kWh sold)": 25763.95,
    "out:PV (% sold (El))": 44.82,
    "out:PV (kWh self-consumed)": 31720.11,
    "out:PV (ratio sold/self-consumed)": 0.81,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 1773600,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.05,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 349724,
    "out:EL kWh savings": 8067,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 128574.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 364813,
    "out:DH kr savings": 318249,
    "out:El kr savings": 13715,
    "out:El kr sold": 32849,
    "out:El kr saved": 53924,
    "out:El kr return": 86773,
    "out:% solar/total": 39,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 354063,
    "out:% savings (space heating)": 46.24,
    "out:Total_Water Heating (DH)": 178976,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 98.87,
    "out:Etvv": 20,
    "out:Ef": 14.33,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000000110001001",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 73.27,
    "out:DHW (DH)": 29.58,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 14.33,
    "out:Total Energy Use Pre PV": 123.5,
    "out:Total Energy Use Post PV": 117.33,
    "out:Primary Energy": 88.98,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 42.88,
    "out:CO2 Operational/m2": 13.77,
    "out:Total CO2/m2": 56.65,
    "out:Total CO2 (tons)": 291.36,
    "out:Klimatpaverkan": -37.88,
    "out:Initial Cost/MSEK": 11.621,
    "out:Running cost/(Apt SEK y)": 35350,
    "out:Running Cost over RSP/MSEK": 11.62,
    "out:LCP/MSEK": -3.972,
    "out:ROI% old": 9.4,
    "out:Payback discounted": 13,
    "out:Atemp": 5143,
    "out:Umean": 0.46,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 117,
    "out:Energy use kWh/m2": 117,
    "out:Energy savings %": 60.7,
    "out:Op energy cost/MSEK": 86,
    "out:El price/MSEK": 51,
    "out:Heating price/MSEK": 30,
    "out:DH+ DHW/kWh/m2": 103,
    "out:El bought/kWh/m2": 1013.1,
    "out:Return %": 8,
    "out:Return/kSEK/y": 979,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 61.265983,
    "out:PV (m2 panels)": 321,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 57484.06,
    "out:PV (kWh sold)": 25763.95,
    "out:PV (% sold (El))": 44.82,
    "out:PV (kWh self-consumed)": 31720.11,
    "out:PV (ratio sold/self-consumed)": 0.81,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 1773600,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.05,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 354867,
    "out:EL kWh savings": 8067,
    "out:Sewage heat recovery cost": 250448,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 369493,
    "out:DH kr savings": 322929,
    "out:El kr savings": 13715,
    "out:El kr sold": 32849,
    "out:El kr saved": 53924,
    "out:El kr return": 86773,
    "out:% solar/total": 39,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 376807,
    "out:% savings (space heating)": 42.79,
    "out:Total_Water Heating (DH)": 152130,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 103.29,
    "out:Etvv": 17,
    "out:Ef": 14.33,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000000110001101",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 68.84,
    "out:DHW (DH)": 29.58,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 14.33,
    "out:Total Energy Use Pre PV": 118.5,
    "out:Total Energy Use Post PV": 112.33,
    "out:Primary Energy": 85.89,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 42.88,
    "out:CO2 Operational/m2": 11.97,
    "out:Total CO2/m2": 54.85,
    "out:Total CO2 (tons)": 282.1,
    "out:Klimatpaverkan": -47.14,
    "out:Initial Cost/MSEK": 11.749625,
    "out:Running cost/(Apt SEK y)": 33740,
    "out:Running Cost over RSP/MSEK": 11.092,
    "out:LCP/MSEK": -3.572,
    "out:ROI% old": 9.94,
    "out:Payback discounted": 12,
    "out:Atemp": 5143,
    "out:Umean": 0.46,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 112,
    "out:Energy use kWh/m2": 112,
    "out:Energy savings %": 67.9,
    "out:Op energy cost/MSEK": 82,
    "out:El price/MSEK": 49,
    "out:Heating price/MSEK": 28,
    "out:DH+ DHW/kWh/m2": 98,
    "out:El bought/kWh/m2": 1013.1,
    "out:Return %": 9,
    "out:Return/kSEK/y": 1047,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 61.265983,
    "out:PV (m2 panels)": 321,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 57484.06,
    "out:PV (kWh sold)": 25763.95,
    "out:PV (% sold (El))": 44.82,
    "out:PV (kWh self-consumed)": 31720.11,
    "out:PV (ratio sold/self-consumed)": 0.81,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 1773600,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.05,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 380582,
    "out:EL kWh savings": 8067,
    "out:Sewage heat recovery cost": 250448,
    "out:Balancing hydronic system cost": 128574.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 392893,
    "out:DH kr savings": 346330,
    "out:El kr savings": 13715,
    "out:El kr sold": 32849,
    "out:El kr saved": 53924,
    "out:El kr return": 86773,
    "out:% solar/total": 39,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 354063,
    "out:% savings (space heating)": 46.24,
    "out:Total_Water Heating (DH)": 152130,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 98.87,
    "out:Etvv": 17,
    "out:Ef": 14.33,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000000120000001",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 73.27,
    "out:DHW (DH)": 34.8,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 13.09,
    "out:Total Energy Use Pre PV": 128.5,
    "out:Total Energy Use Post PV": 121.09,
    "out:Primary Energy": 88.85,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 54.83,
    "out:CO2 Operational/m2": -35.11,
    "out:Total CO2/m2": 19.72,
    "out:Total CO2 (tons)": 101.43,
    "out:Klimatpaverkan": -227.81,
    "out:Initial Cost/MSEK": 12.440375,
    "out:Running cost/(Apt SEK y)": 35149,
    "out:Running Cost over RSP/MSEK": 11.571,
    "out:LCP/MSEK": -4.743,
    "out:ROI% old": 8.84,
    "out:Payback discounted": 14,
    "out:Atemp": 5143,
    "out:Umean": 0.46,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 121,
    "out:Energy use kWh/m2": 121,
    "out:Energy savings %": 55.4,
    "out:Op energy cost/MSEK": 86,
    "out:El price/MSEK": 50,
    "out:Heating price/MSEK": 31,
    "out:DH+ DHW/kWh/m2": 108,
    "out:El bought/kWh/m2": 923.7,
    "out:Return %": 8,
    "out:Return/kSEK/y": 988,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.33,
    "out:PV (kWp installed)": 122.531965,
    "out:PV (m2 panels)": 643,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 114968.11,
    "out:PV (kWh sold)": 76862.14,
    "out:PV (% sold (El))": 66.86,
    "out:PV (kWh self-consumed)": 38105.97,
    "out:PV (ratio sold/self-consumed)": 2.02,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 1773600,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.05,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 329152,
    "out:EL kWh savings": 14453,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 422098,
    "out:DH kr savings": 299528,
    "out:El kr savings": 24571,
    "out:El kr sold": 97999,
    "out:El kr saved": 64780,
    "out:El kr return": 162779,
    "out:% solar/total": 47,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 376807,
    "out:% savings (space heating)": 42.79,
    "out:Total_Water Heating (DH)": 178976,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 103.29,
    "out:Etvv": 20,
    "out:Ef": 13.09,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000000120000101",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 68.84,
    "out:DHW (DH)": 34.8,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 13.09,
    "out:Total Energy Use Pre PV": 124.5,
    "out:Total Energy Use Post PV": 117.09,
    "out:Primary Energy": 85.75,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 54.83,
    "out:CO2 Operational/m2": -36.55,
    "out:Total CO2/m2": 18.28,
    "out:Total CO2 (tons)": 94.02,
    "out:Klimatpaverkan": -235.22,
    "out:Initial Cost/MSEK": 12.56875,
    "out:Running cost/(Apt SEK y)": 33862,
    "out:Running Cost over RSP/MSEK": 11.148,
    "out:LCP/MSEK": -4.448,
    "out:ROI% old": 9.23,
    "out:Payback discounted": 13,
    "out:Atemp": 5143,
    "out:Umean": 0.46,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 117,
    "out:Energy use kWh/m2": 117,
    "out:Energy savings %": 60.7,
    "out:Op energy cost/MSEK": 83,
    "out:El price/MSEK": 48,
    "out:Heating price/MSEK": 30,
    "out:DH+ DHW/kWh/m2": 104,
    "out:El bought/kWh/m2": 923.7,
    "out:Return %": 8,
    "out:Return/kSEK/y": 1042,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.33,
    "out:PV (kWp installed)": 122.531965,
    "out:PV (m2 panels)": 643,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 114968.11,
    "out:PV (kWh sold)": 76862.14,
    "out:PV (% sold (El))": 66.86,
    "out:PV (kWh self-consumed)": 38105.97,
    "out:PV (ratio sold/self-consumed)": 2.02,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 1773600,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.05,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 349724,
    "out:EL kWh savings": 14453,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 128574.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 440819,
    "out:DH kr savings": 318249,
    "out:El kr savings": 24571,
    "out:El kr sold": 97999,
    "out:El kr saved": 64780,
    "out:El kr return": 162779,
    "out:% solar/total": 47,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 354063,
    "out:% savings (space heating)": 46.24,
    "out:Total_Water Heating (DH)": 178976,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 98.87,
    "out:Etvv": 20,
    "out:Ef": 13.09,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000000120001001",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 73.27,
    "out:DHW (DH)": 29.58,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 13.09,
    "out:Total Energy Use Pre PV": 123.5,
    "out:Total Energy Use Post PV": 116.09,
    "out:Primary Energy": 86.75,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 55.23,
    "out:CO2 Operational/m2": -36.91,
    "out:Total CO2/m2": 18.32,
    "out:Total CO2 (tons)": 94.24,
    "out:Klimatpaverkan": -235,
    "out:Initial Cost/MSEK": 12.69125,
    "out:Running cost/(Apt SEK y)": 33540,
    "out:Running Cost over RSP/MSEK": 11.042,
    "out:LCP/MSEK": -4.465,
    "out:ROI% old": 9.26,
    "out:Payback discounted": 13,
    "out:Atemp": 5143,
    "out:Umean": 0.46,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 116,
    "out:Energy use kWh/m2": 116,
    "out:Energy savings %": 62.1,
    "out:Op energy cost/MSEK": 82,
    "out:El price/MSEK": 48,
    "out:Heating price/MSEK": 30,
    "out:DH+ DHW/kWh/m2": 103,
    "out:El bought/kWh/m2": 923.7,
    "out:Return %": 8,
    "out:Return/kSEK/y": 1055,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.33,
    "out:PV (kWp installed)": 122.531965,
    "out:PV (m2 panels)": 643,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 114968.11,
    "out:PV (kWh sold)": 76862.14,
    "out:PV (% sold (El))": 66.86,
    "out:PV (kWh self-consumed)": 38105.97,
    "out:PV (ratio sold/self-consumed)": 2.02,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 1773600,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.05,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 354867,
    "out:EL kWh savings": 14453,
    "out:Sewage heat recovery cost": 250448,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 445499,
    "out:DH kr savings": 322929,
    "out:El kr savings": 24571,
    "out:El kr sold": 97999,
    "out:El kr saved": 64780,
    "out:El kr return": 162779,
    "out:% solar/total": 47,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 376807,
    "out:% savings (space heating)": 42.79,
    "out:Total_Water Heating (DH)": 152130,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 103.29,
    "out:Etvv": 17,
    "out:Ef": 13.09,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000000120001101",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 68.84,
    "out:DHW (DH)": 29.58,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 13.09,
    "out:Total Energy Use Pre PV": 118.5,
    "out:Total Energy Use Post PV": 111.09,
    "out:Primary Energy": 83.65,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 55.23,
    "out:CO2 Operational/m2": -38.71,
    "out:Total CO2/m2": 16.52,
    "out:Total CO2 (tons)": 84.98,
    "out:Klimatpaverkan": -244.26,
    "out:Initial Cost/MSEK": 12.81875,
    "out:Running cost/(Apt SEK y)": 31931,
    "out:Running Cost over RSP/MSEK": 10.514,
    "out:LCP/MSEK": -4.063,
    "out:ROI% old": 9.76,
    "out:Payback discounted": 12,
    "out:Atemp": 5143,
    "out:Umean": 0.46,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 111,
    "out:Energy use kWh/m2": 111,
    "out:Energy savings %": 69.4,
    "out:Op energy cost/MSEK": 78,
    "out:El price/MSEK": 45,
    "out:Heating price/MSEK": 28,
    "out:DH+ DHW/kWh/m2": 98,
    "out:El bought/kWh/m2": 923.7,
    "out:Return %": 9,
    "out:Return/kSEK/y": 1123,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.33,
    "out:PV (kWp installed)": 122.531965,
    "out:PV (m2 panels)": 643,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 114968.11,
    "out:PV (kWh sold)": 76862.14,
    "out:PV (% sold (El))": 66.86,
    "out:PV (kWh self-consumed)": 38105.97,
    "out:PV (ratio sold/self-consumed)": 2.02,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 1773600,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.05,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 380582,
    "out:EL kWh savings": 14453,
    "out:Sewage heat recovery cost": 250448,
    "out:Balancing hydronic system cost": 128574.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 468899,
    "out:DH kr savings": 346330,
    "out:El kr savings": 24571,
    "out:El kr sold": 97999,
    "out:El kr saved": 64780,
    "out:El kr return": 162779,
    "out:% solar/total": 47,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 354063,
    "out:% savings (space heating)": 46.24,
    "out:Total_Water Heating (DH)": 152130,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 98.87,
    "out:Etvv": 17,
    "out:Ef": 13.09,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000100000000001",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 61.75,
    "out:DHW (DH)": 43.5,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 20.5,
    "out:Total Energy Use Pre PV": 125.5,
    "out:Total Energy Use Post PV": 125.5,
    "out:Primary Energy": 97.63,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 32.14,
    "out:CO2 Operational/m2": 40.5,
    "out:Total CO2/m2": 72.65,
    "out:Total CO2 (tons)": 373.63,
    "out:Klimatpaverkan": 44.39,
    "out:Initial Cost/MSEK": 11.011375,
    "out:Running cost/(Apt SEK y)": 38059,
    "out:Running Cost over RSP/MSEK": 12.493,
    "out:LCP/MSEK": -4.235,
    "out:ROI% old": 8.79,
    "out:Payback discounted": 14,
    "out:Atemp": 5143,
    "out:Umean": 0.31,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FTX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 125,
    "out:Energy use kWh/m2": 125,
    "out:Energy savings %": 50.4,
    "out:Op energy cost/MSEK": 91,
    "out:El price/MSEK": 56,
    "out:Heating price/MSEK": 30,
    "out:DH+ DHW/kWh/m2": 105,
    "out:El bought/kWh/m2": 1434.9,
    "out:Return %": 8,
    "out:Return/kSEK/y": 865,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 1773600,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.05,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 2,
    "out:Payback Time": 50,
    "out:DH kWh savings": 344581,
    "out:EL kWh savings": -23653,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 273359,
    "out:DH kr savings": 313569,
    "out:El kr savings": -40210,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 317605,
    "out:% savings (space heating)": 51.77,
    "out:Total_Water Heating (DH)": 223720,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 91.78,
    "out:Etvv": 25,
    "out:Ef": 20.5,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000100000000101",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 57.86,
    "out:DHW (DH)": 43.5,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 20.5,
    "out:Total Energy Use Pre PV": 121.5,
    "out:Total Energy Use Post PV": 121.5,
    "out:Primary Energy": 94.9,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 32.14,
    "out:CO2 Operational/m2": 39.06,
    "out:Total CO2/m2": 71.21,
    "out:Total CO2 (tons)": 366.23,
    "out:Klimatpaverkan": 36.99,
    "out:Initial Cost/MSEK": 11.14,
    "out:Running cost/(Apt SEK y)": 36772,
    "out:Running Cost over RSP/MSEK": 12.069,
    "out:LCP/MSEK": -3.94,
    "out:ROI% old": 9.23,
    "out:Payback discounted": 13,
    "out:Atemp": 5143,
    "out:Umean": 0.31,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FTX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 121,
    "out:Energy use kWh/m2": 121,
    "out:Energy savings %": 55.4,
    "out:Op energy cost/MSEK": 88,
    "out:El price/MSEK": 54,
    "out:Heating price/MSEK": 29,
    "out:DH+ DHW/kWh/m2": 101,
    "out:El bought/kWh/m2": 1434.9,
    "out:Return %": 8,
    "out:Return/kSEK/y": 919,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 1773600,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.05,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 365153,
    "out:EL kWh savings": -23653,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 128574.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 292080,
    "out:DH kr savings": 332289,
    "out:El kr savings": -40210,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 297551,
    "out:% savings (space heating)": 54.82,
    "out:Total_Water Heating (DH)": 223720,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 87.88,
    "out:Etvv": 25,
    "out:Ef": 20.5,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000100000001001",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 61.75,
    "out:DHW (DH)": 36.98,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 20.5,
    "out:Total Energy Use Pre PV": 119.5,
    "out:Total Energy Use Post PV": 119.5,
    "out:Primary Energy": 95,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 32.55,
    "out:CO2 Operational/m2": 38.34,
    "out:Total CO2/m2": 70.89,
    "out:Total CO2 (tons)": 364.59,
    "out:Klimatpaverkan": 35.35,
    "out:Initial Cost/MSEK": 11.261875,
    "out:Running cost/(Apt SEK y)": 36128,
    "out:Running Cost over RSP/MSEK": 11.858,
    "out:LCP/MSEK": -3.851,
    "out:ROI% old": 9.4,
    "out:Payback discounted": 13,
    "out:Atemp": 5143,
    "out:Umean": 0.31,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FTX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 119,
    "out:Energy use kWh/m2": 119,
    "out:Energy savings %": 58,
    "out:Op energy cost/MSEK": 86,
    "out:El price/MSEK": 53,
    "out:Heating price/MSEK": 28,
    "out:DH+ DHW/kWh/m2": 99,
    "out:El bought/kWh/m2": 1434.9,
    "out:Return %": 8,
    "out:Return/kSEK/y": 947,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 1773600,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.05,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 375439,
    "out:EL kWh savings": -23653,
    "out:Sewage heat recovery cost": 250448,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 301440,
    "out:DH kr savings": 341649,
    "out:El kr savings": -40210,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 317605,
    "out:% savings (space heating)": 51.77,
    "out:Total_Water Heating (DH)": 190162,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 91.78,
    "out:Etvv": 21.25,
    "out:Ef": 20.5,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000100000001101",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 57.86,
    "out:DHW (DH)": 36.98,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 20.5,
    "out:Total Energy Use Pre PV": 115.5,
    "out:Total Energy Use Post PV": 115.5,
    "out:Primary Energy": 92.27,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 32.55,
    "out:CO2 Operational/m2": 36.9,
    "out:Total CO2/m2": 69.45,
    "out:Total CO2 (tons)": 357.19,
    "out:Klimatpaverkan": 27.95,
    "out:Initial Cost/MSEK": 11.390375,
    "out:Running cost/(Apt SEK y)": 34841,
    "out:Running Cost over RSP/MSEK": 11.435,
    "out:LCP/MSEK": -3.556,
    "out:ROI% old": 9.83,
    "out:Payback discounted": 12,
    "out:Atemp": 5143,
    "out:Umean": 0.31,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FTX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 115,
    "out:Energy use kWh/m2": 115,
    "out:Energy savings %": 63.5,
    "out:Op energy cost/MSEK": 83,
    "out:El price/MSEK": 52,
    "out:Heating price/MSEK": 27,
    "out:DH+ DHW/kWh/m2": 95,
    "out:El bought/kWh/m2": 1434.9,
    "out:Return %": 9,
    "out:Return/kSEK/y": 1001,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 1773600,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.05,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 396011,
    "out:EL kWh savings": -23653,
    "out:Sewage heat recovery cost": 250448,
    "out:Balancing hydronic system cost": 128574.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 320160,
    "out:DH kr savings": 360370,
    "out:El kr savings": -40210,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 297551,
    "out:% savings (space heating)": 54.82,
    "out:Total_Water Heating (DH)": 190162,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 87.88,
    "out:Etvv": 21.25,
    "out:Ef": 20.5,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000100010000001",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 61.75,
    "out:DHW (DH)": 43.5,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 14.33,
    "out:Total Energy Use Pre PV": 125.5,
    "out:Total Energy Use Post PV": 119.33,
    "out:Primary Energy": 86.52,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 44.5,
    "out:CO2 Operational/m2": 14.49,
    "out:Total CO2/m2": 58.99,
    "out:Total CO2 (tons)": 303.37,
    "out:Klimatpaverkan": -25.87,
    "out:Initial Cost/MSEK": 12.08125,
    "out:Running cost/(Apt SEK y)": 35993,
    "out:Running Cost over RSP/MSEK": 11.832,
    "out:LCP/MSEK": -4.644,
    "out:ROI% old": 8.79,
    "out:Payback discounted": 14,
    "out:Atemp": 5143,
    "out:Umean": 0.31,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FTX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 119,
    "out:Energy use kWh/m2": 119,
    "out:Energy savings %": 58,
    "out:Op energy cost/MSEK": 87,
    "out:El price/MSEK": 52,
    "out:Heating price/MSEK": 30,
    "out:DH+ DHW/kWh/m2": 105,
    "out:El bought/kWh/m2": 1013.1,
    "out:Return %": 8,
    "out:Return/kSEK/y": 952,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 61.265983,
    "out:PV (m2 panels)": 321,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 57484.06,
    "out:PV (kWh sold)": 25763.95,
    "out:PV (% sold (El))": 44.82,
    "out:PV (kWh self-consumed)": 31720.11,
    "out:PV (ratio sold/self-consumed)": 0.81,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 1773600,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.05,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 344581,
    "out:EL kWh savings": 8067,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 360132,
    "out:DH kr savings": 313569,
    "out:El kr savings": 13715,
    "out:El kr sold": 32849,
    "out:El kr saved": 53924,
    "out:El kr return": 86773,
    "out:% solar/total": 39,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 317605,
    "out:% savings (space heating)": 51.77,
    "out:Total_Water Heating (DH)": 223720,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 91.78,
    "out:Etvv": 25,
    "out:Ef": 14.33,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000100010000101",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 57.86,
    "out:DHW (DH)": 43.5,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 14.33,
    "out:Total Energy Use Pre PV": 121.5,
    "out:Total Energy Use Post PV": 115.33,
    "out:Primary Energy": 83.8,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 44.5,
    "out:CO2 Operational/m2": 13.05,
    "out:Total CO2/m2": 57.55,
    "out:Total CO2 (tons)": 295.97,
    "out:Klimatpaverkan": -33.27,
    "out:Initial Cost/MSEK": 12.20975,
    "out:Running cost/(Apt SEK y)": 34706,
    "out:Running Cost over RSP/MSEK": 11.409,
    "out:LCP/MSEK": -4.35,
    "out:ROI% old": 9.2,
    "out:Payback discounted": 13,
    "out:Atemp": 5143,
    "out:Umean": 0.31,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FTX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 115,
    "out:Energy use kWh/m2": 115,
    "out:Energy savings %": 63.5,
    "out:Op energy cost/MSEK": 84,
    "out:El price/MSEK": 50,
    "out:Heating price/MSEK": 29,
    "out:DH+ DHW/kWh/m2": 101,
    "out:El bought/kWh/m2": 1013.1,
    "out:Return %": 8,
    "out:Return/kSEK/y": 1006,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 61.265983,
    "out:PV (m2 panels)": 321,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 57484.06,
    "out:PV (kWh sold)": 25763.95,
    "out:PV (% sold (El))": 44.82,
    "out:PV (kWh self-consumed)": 31720.11,
    "out:PV (ratio sold/self-consumed)": 0.81,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 1773600,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.05,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 365153,
    "out:EL kWh savings": 8067,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 128574.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 378853,
    "out:DH kr savings": 332289,
    "out:El kr savings": 13715,
    "out:El kr sold": 32849,
    "out:El kr saved": 53924,
    "out:El kr return": 86773,
    "out:% solar/total": 39,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 297551,
    "out:% savings (space heating)": 54.82,
    "out:Total_Water Heating (DH)": 223720,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 87.88,
    "out:Etvv": 25,
    "out:Ef": 14.33,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000100010001001",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 61.75,
    "out:DHW (DH)": 36.98,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 14.33,
    "out:Total Energy Use Pre PV": 119.5,
    "out:Total Energy Use Post PV": 113.33,
    "out:Primary Energy": 83.9,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 44.9,
    "out:CO2 Operational/m2": 12.33,
    "out:Total CO2/m2": 57.23,
    "out:Total CO2 (tons)": 294.33,
    "out:Klimatpaverkan": -34.91,
    "out:Initial Cost/MSEK": 12.331625,
    "out:Running cost/(Apt SEK y)": 34062,
    "out:Running Cost over RSP/MSEK": 11.197,
    "out:LCP/MSEK": -4.26,
    "out:ROI% old": 9.35,
    "out:Payback discounted": 13,
    "out:Atemp": 5143,
    "out:Umean": 0.31,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FTX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 113,
    "out:Energy use kWh/m2": 113,
    "out:Energy savings %": 66.4,
    "out:Op energy cost/MSEK": 82,
    "out:El price/MSEK": 49,
    "out:Heating price/MSEK": 28,
    "out:DH+ DHW/kWh/m2": 99,
    "out:El bought/kWh/m2": 1013.1,
    "out:Return %": 8,
    "out:Return/kSEK/y": 1033,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 61.265983,
    "out:PV (m2 panels)": 321,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 57484.06,
    "out:PV (kWh sold)": 25763.95,
    "out:PV (% sold (El))": 44.82,
    "out:PV (kWh self-consumed)": 31720.11,
    "out:PV (ratio sold/self-consumed)": 0.81,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 1773600,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.05,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 375439,
    "out:EL kWh savings": 8067,
    "out:Sewage heat recovery cost": 250448,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 388213,
    "out:DH kr savings": 341649,
    "out:El kr savings": 13715,
    "out:El kr sold": 32849,
    "out:El kr saved": 53924,
    "out:El kr return": 86773,
    "out:% solar/total": 39,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 317605,
    "out:% savings (space heating)": 51.77,
    "out:Total_Water Heating (DH)": 190162,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 91.78,
    "out:Etvv": 21.25,
    "out:Ef": 14.33,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000100010001101",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 57.86,
    "out:DHW (DH)": 36.98,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 14.33,
    "out:Total Energy Use Pre PV": 115.5,
    "out:Total Energy Use Post PV": 109.33,
    "out:Primary Energy": 81.17,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 44.9,
    "out:CO2 Operational/m2": 10.89,
    "out:Total CO2/m2": 55.79,
    "out:Total CO2 (tons)": 286.93,
    "out:Klimatpaverkan": -42.31,
    "out:Initial Cost/MSEK": 12.46025,
    "out:Running cost/(Apt SEK y)": 32775,
    "out:Running Cost over RSP/MSEK": 10.774,
    "out:LCP/MSEK": -3.965,
    "out:ROI% old": 9.74,
    "out:Payback discounted": 12,
    "out:Atemp": 5143,
    "out:Umean": 0.31,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FTX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 109,
    "out:Energy use kWh/m2": 109,
    "out:Energy savings %": 72.5,
    "out:Op energy cost/MSEK": 79,
    "out:El price/MSEK": 48,
    "out:Heating price/MSEK": 27,
    "out:DH+ DHW/kWh/m2": 95,
    "out:El bought/kWh/m2": 1013.1,
    "out:Return %": 9,
    "out:Return/kSEK/y": 1087,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 61.265983,
    "out:PV (m2 panels)": 321,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 57484.06,
    "out:PV (kWh sold)": 25763.95,
    "out:PV (% sold (El))": 44.82,
    "out:PV (kWh self-consumed)": 31720.11,
    "out:PV (ratio sold/self-consumed)": 0.81,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 1773600,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.05,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 396011,
    "out:EL kWh savings": 8067,
    "out:Sewage heat recovery cost": 250448,
    "out:Balancing hydronic system cost": 128574.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 406934,
    "out:DH kr savings": 360370,
    "out:El kr savings": 13715,
    "out:El kr sold": 32849,
    "out:El kr saved": 53924,
    "out:El kr return": 86773,
    "out:% solar/total": 39,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 297551,
    "out:% savings (space heating)": 54.82,
    "out:Total_Water Heating (DH)": 190162,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 87.88,
    "out:Etvv": 21.25,
    "out:Ef": 14.33,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000100020000001",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 61.75,
    "out:DHW (DH)": 43.5,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 13.09,
    "out:Total Energy Use Pre PV": 125.5,
    "out:Total Energy Use Post PV": 118.09,
    "out:Primary Energy": 84.29,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 56.85,
    "out:CO2 Operational/m2": -36.19,
    "out:Total CO2/m2": 20.66,
    "out:Total CO2 (tons)": 106.25,
    "out:Klimatpaverkan": -222.99,
    "out:Initial Cost/MSEK": 13.15125,
    "out:Running cost/(Apt SEK y)": 34184,
    "out:Running Cost over RSP/MSEK": 11.254,
    "out:LCP/MSEK": -5.136,
    "out:ROI% old": 8.71,
    "out:Payback discounted": 14,
    "out:Atemp": 5143,
    "out:Umean": 0.31,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FTX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 118,
    "out:Energy use kWh/m2": 118,
    "out:Energy savings %": 59.3,
    "out:Op energy cost/MSEK": 84,
    "out:El price/MSEK": 48,
    "out:Heating price/MSEK": 30,
    "out:DH+ DHW/kWh/m2": 105,
    "out:El bought/kWh/m2": 923.7,
    "out:Return %": 8,
    "out:Return/kSEK/y": 1028,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.33,
    "out:PV (kWp installed)": 122.531965,
    "out:PV (m2 panels)": 643,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 114968.11,
    "out:PV (kWh sold)": 76862.14,
    "out:PV (% sold (El))": 66.86,
    "out:PV (kWh self-consumed)": 38105.97,
    "out:PV (ratio sold/self-consumed)": 2.02,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 1773600,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.05,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 344581,
    "out:EL kWh savings": 14453,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 436139,
    "out:DH kr savings": 313569,
    "out:El kr savings": 24571,
    "out:El kr sold": 97999,
    "out:El kr saved": 64780,
    "out:El kr return": 162779,
    "out:% solar/total": 47,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 317605,
    "out:% savings (space heating)": 51.77,
    "out:Total_Water Heating (DH)": 223720,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 91.78,
    "out:Etvv": 25,
    "out:Ef": 13.09,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000100020000101",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 57.86,
    "out:DHW (DH)": 43.5,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 13.09,
    "out:Total Energy Use Pre PV": 121.5,
    "out:Total Energy Use Post PV": 114.09,
    "out:Primary Energy": 81.56,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 56.85,
    "out:CO2 Operational/m2": -37.63,
    "out:Total CO2/m2": 19.22,
    "out:Total CO2 (tons)": 98.85,
    "out:Klimatpaverkan": -230.39,
    "out:Initial Cost/MSEK": 13.28,
    "out:Running cost/(Apt SEK y)": 32896,
    "out:Running Cost over RSP/MSEK": 10.831,
    "out:LCP/MSEK": -4.842,
    "out:ROI% old": 9.08,
    "out:Payback discounted": 13,
    "out:Atemp": 5143,
    "out:Umean": 0.31,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FTX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 114,
    "out:Energy use kWh/m2": 114,
    "out:Energy savings %": 64.9,
    "out:Op energy cost/MSEK": 80,
    "out:El price/MSEK": 47,
    "out:Heating price/MSEK": 29,
    "out:DH+ DHW/kWh/m2": 101,
    "out:El bought/kWh/m2": 923.7,
    "out:Return %": 8,
    "out:Return/kSEK/y": 1082,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.33,
    "out:PV (kWp installed)": 122.531965,
    "out:PV (m2 panels)": 643,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 114968.11,
    "out:PV (kWh sold)": 76862.14,
    "out:PV (% sold (El))": 66.86,
    "out:PV (kWh self-consumed)": 38105.97,
    "out:PV (ratio sold/self-consumed)": 2.02,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 1773600,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.05,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 365153,
    "out:EL kWh savings": 14453,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 128574.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 454859,
    "out:DH kr savings": 332289,
    "out:El kr savings": 24571,
    "out:El kr sold": 97999,
    "out:El kr saved": 64780,
    "out:El kr return": 162779,
    "out:% solar/total": 47,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 297551,
    "out:% savings (space heating)": 54.82,
    "out:Total_Water Heating (DH)": 223720,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 87.88,
    "out:Etvv": 25,
    "out:Ef": 13.09,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000100020001001",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 61.75,
    "out:DHW (DH)": 36.98,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 13.09,
    "out:Total Energy Use Pre PV": 119.5,
    "out:Total Energy Use Post PV": 112.09,
    "out:Primary Energy": 81.66,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 57.25,
    "out:CO2 Operational/m2": -38.35,
    "out:Total CO2/m2": 18.9,
    "out:Total CO2 (tons)": 97.21,
    "out:Klimatpaverkan": -232.03,
    "out:Initial Cost/MSEK": 13.40125,
    "out:Running cost/(Apt SEK y)": 32253,
    "out:Running Cost over RSP/MSEK": 10.619,
    "out:LCP/MSEK": -4.752,
    "out:ROI% old": 9.22,
    "out:Payback discounted": 13,
    "out:Atemp": 5143,
    "out:Umean": 0.31,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FTX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 112,
    "out:Energy use kWh/m2": 112,
    "out:Energy savings %": 67.9,
    "out:Op energy cost/MSEK": 79,
    "out:El price/MSEK": 46,
    "out:Heating price/MSEK": 28,
    "out:DH+ DHW/kWh/m2": 99,
    "out:El bought/kWh/m2": 923.7,
    "out:Return %": 8,
    "out:Return/kSEK/y": 1109,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.33,
    "out:PV (kWp installed)": 122.531965,
    "out:PV (m2 panels)": 643,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 114968.11,
    "out:PV (kWh sold)": 76862.14,
    "out:PV (% sold (El))": 66.86,
    "out:PV (kWh self-consumed)": 38105.97,
    "out:PV (ratio sold/self-consumed)": 2.02,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 1773600,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.05,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 375439,
    "out:EL kWh savings": 14453,
    "out:Sewage heat recovery cost": 250448,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 464219,
    "out:DH kr savings": 341649,
    "out:El kr savings": 24571,
    "out:El kr sold": 97999,
    "out:El kr saved": 64780,
    "out:El kr return": 162779,
    "out:% solar/total": 47,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 317605,
    "out:% savings (space heating)": 51.77,
    "out:Total_Water Heating (DH)": 190162,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 91.78,
    "out:Etvv": 21.25,
    "out:Ef": 13.09,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000100020001101",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 57.86,
    "out:DHW (DH)": 36.98,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 13.09,
    "out:Total Energy Use Pre PV": 115.5,
    "out:Total Energy Use Post PV": 108.09,
    "out:Primary Energy": 78.94,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 57.25,
    "out:CO2 Operational/m2": -39.79,
    "out:Total CO2/m2": 17.46,
    "out:Total CO2 (tons)": 89.81,
    "out:Klimatpaverkan": -239.43,
    "out:Initial Cost/MSEK": 13.53,
    "out:Running cost/(Apt SEK y)": 30965,
    "out:Running Cost over RSP/MSEK": 10.196,
    "out:LCP/MSEK": -4.457,
    "out:ROI% old": 9.58,
    "out:Payback discounted": 13,
    "out:Atemp": 5143,
    "out:Umean": 0.31,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FTX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 108,
    "out:Energy use kWh/m2": 108,
    "out:Energy savings %": 74.1,
    "out:Op energy cost/MSEK": 76,
    "out:El price/MSEK": 44,
    "out:Heating price/MSEK": 27,
    "out:DH+ DHW/kWh/m2": 95,
    "out:El bought/kWh/m2": 923.7,
    "out:Return %": 9,
    "out:Return/kSEK/y": 1163,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.33,
    "out:PV (kWp installed)": 122.531965,
    "out:PV (m2 panels)": 643,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 114968.11,
    "out:PV (kWh sold)": 76862.14,
    "out:PV (% sold (El))": 66.86,
    "out:PV (kWh self-consumed)": 38105.97,
    "out:PV (ratio sold/self-consumed)": 2.02,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 1773600,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.05,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 396011,
    "out:EL kWh savings": 14453,
    "out:Sewage heat recovery cost": 250448,
    "out:Balancing hydronic system cost": 128574.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 482940,
    "out:DH kr savings": 360370,
    "out:El kr savings": 24571,
    "out:El kr sold": 97999,
    "out:El kr saved": 64780,
    "out:El kr return": 162779,
    "out:% solar/total": 47,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 297551,
    "out:% savings (space heating)": 54.82,
    "out:Total_Water Heating (DH)": 190162,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 87.88,
    "out:Etvv": 21.25,
    "out:Ef": 13.09,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000100100000001",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 61.75,
    "out:DHW (DH)": 34.8,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 20.5,
    "out:Total Energy Use Pre PV": 117.5,
    "out:Total Energy Use Post PV": 117.5,
    "out:Primary Energy": 94.13,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 32.14,
    "out:CO2 Operational/m2": 37.62,
    "out:Total CO2/m2": 69.77,
    "out:Total CO2 (tons)": 358.82,
    "out:Klimatpaverkan": 29.58,
    "out:Initial Cost/MSEK": 11.12225,
    "out:Running cost/(Apt SEK y)": 35485,
    "out:Running Cost over RSP/MSEK": 11.646,
    "out:LCP/MSEK": -3.5,
    "out:ROI% old": 9.79,
    "out:Payback discounted": 12,
    "out:Atemp": 5143,
    "out:Umean": 0.31,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FTX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 117,
    "out:Energy use kWh/m2": 117,
    "out:Energy savings %": 60.7,
    "out:Op energy cost/MSEK": 85,
    "out:El price/MSEK": 53,
    "out:Heating price/MSEK": 28,
    "out:DH+ DHW/kWh/m2": 97,
    "out:El bought/kWh/m2": 1434.9,
    "out:Return %": 9,
    "out:Return/kSEK/y": 974,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 1773600,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.05,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 385725,
    "out:EL kWh savings": -23653,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 310800,
    "out:DH kr savings": 351010,
    "out:El kr savings": -40210,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 317605,
    "out:% savings (space heating)": 51.77,
    "out:Total_Water Heating (DH)": 178976,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 91.78,
    "out:Etvv": 20,
    "out:Ef": 20.5,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000100100000101",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 57.86,
    "out:DHW (DH)": 34.8,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 20.5,
    "out:Total Energy Use Pre PV": 113.5,
    "out:Total Energy Use Post PV": 113.5,
    "out:Primary Energy": 91.4,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 32.14,
    "out:CO2 Operational/m2": 36.18,
    "out:Total CO2/m2": 68.33,
    "out:Total CO2 (tons)": 351.42,
    "out:Klimatpaverkan": 22.18,
    "out:Initial Cost/MSEK": 11.250875,
    "out:Running cost/(Apt SEK y)": 34197,
    "out:Running Cost over RSP/MSEK": 11.223,
    "out:LCP/MSEK": -3.205,
    "out:ROI% old": 10.22,
    "out:Payback discounted": 12,
    "out:Atemp": 5143,
    "out:Umean": 0.31,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FTX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 113,
    "out:Energy use kWh/m2": 113,
    "out:Energy savings %": 66.4,
    "out:Op energy cost/MSEK": 82,
    "out:El price/MSEK": 51,
    "out:Heating price/MSEK": 27,
    "out:DH+ DHW/kWh/m2": 93,
    "out:El bought/kWh/m2": 1434.9,
    "out:Return %": 9,
    "out:Return/kSEK/y": 1028,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 1773600,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.05,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 406297,
    "out:EL kWh savings": -23653,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 128574.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 329521,
    "out:DH kr savings": 369730,
    "out:El kr savings": -40210,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 297551,
    "out:% savings (space heating)": 54.82,
    "out:Total_Water Heating (DH)": 178976,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 87.88,
    "out:Etvv": 20,
    "out:Ef": 20.5,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000100100001001",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 61.75,
    "out:DHW (DH)": 29.58,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 20.5,
    "out:Total Energy Use Pre PV": 111.5,
    "out:Total Energy Use Post PV": 111.5,
    "out:Primary Energy": 92.03,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 32.55,
    "out:CO2 Operational/m2": 35.46,
    "out:Total CO2/m2": 68.01,
    "out:Total CO2 (tons)": 349.78,
    "out:Klimatpaverkan": 20.54,
    "out:Initial Cost/MSEK": 11.37275,
    "out:Running cost/(Apt SEK y)": 33554,
    "out:Running Cost over RSP/MSEK": 11.012,
    "out:LCP/MSEK": -3.115,
    "out:ROI% old": 10.37,
    "out:Payback discounted": 12,
    "out:Atemp": 5143,
    "out:Umean": 0.31,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FTX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 111,
    "out:Energy use kWh/m2": 111,
    "out:Energy savings %": 69.4,
    "out:Op energy cost/MSEK": 80,
    "out:El price/MSEK": 50,
    "out:Heating price/MSEK": 26,
    "out:DH+ DHW/kWh/m2": 91,
    "out:El bought/kWh/m2": 1434.9,
    "out:Return %": 9,
    "out:Return/kSEK/y": 1055,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 1773600,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.05,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 416583,
    "out:EL kWh savings": -23653,
    "out:Sewage heat recovery cost": 250448,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 338881,
    "out:DH kr savings": 379090,
    "out:El kr savings": -40210,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 317605,
    "out:% savings (space heating)": 51.77,
    "out:Total_Water Heating (DH)": 152130,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 91.78,
    "out:Etvv": 17,
    "out:Ef": 20.5,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000100100001101",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 57.86,
    "out:DHW (DH)": 29.58,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 20.5,
    "out:Total Energy Use Pre PV": 107.5,
    "out:Total Energy Use Post PV": 107.5,
    "out:Primary Energy": 89.3,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 32.55,
    "out:CO2 Operational/m2": 34.02,
    "out:Total CO2/m2": 66.57,
    "out:Total CO2 (tons)": 342.38,
    "out:Klimatpaverkan": 13.14,
    "out:Initial Cost/MSEK": 11.50125,
    "out:Running cost/(Apt SEK y)": 32266,
    "out:Running Cost over RSP/MSEK": 10.589,
    "out:LCP/MSEK": -2.821,
    "out:ROI% old": 10.78,
    "out:Payback discounted": 11,
    "out:Atemp": 5143,
    "out:Umean": 0.31,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FTX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 107,
    "out:Energy use kWh/m2": 107,
    "out:Energy savings %": 75.7,
    "out:Op energy cost/MSEK": 77,
    "out:El price/MSEK": 48,
    "out:Heating price/MSEK": 25,
    "out:DH+ DHW/kWh/m2": 87,
    "out:El bought/kWh/m2": 1434.9,
    "out:Return %": 10,
    "out:Return/kSEK/y": 1109,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 1773600,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.05,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 437155,
    "out:EL kWh savings": -23653,
    "out:Sewage heat recovery cost": 250448,
    "out:Balancing hydronic system cost": 128574.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 357602,
    "out:DH kr savings": 397811,
    "out:El kr savings": -40210,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 297551,
    "out:% savings (space heating)": 54.82,
    "out:Total_Water Heating (DH)": 152130,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 87.88,
    "out:Etvv": 17,
    "out:Ef": 20.5,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000100110000001",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 61.75,
    "out:DHW (DH)": 34.8,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 14.33,
    "out:Total Energy Use Pre PV": 117.5,
    "out:Total Energy Use Post PV": 111.33,
    "out:Primary Energy": 83.02,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 44.5,
    "out:CO2 Operational/m2": 11.61,
    "out:Total CO2/m2": 56.11,
    "out:Total CO2 (tons)": 288.56,
    "out:Klimatpaverkan": -40.68,
    "out:Initial Cost/MSEK": 12.192,
    "out:Running cost/(Apt SEK y)": 33419,
    "out:Running Cost over RSP/MSEK": 10.986,
    "out:LCP/MSEK": -3.909,
    "out:ROI% old": 9.71,
    "out:Payback discounted": 12,
    "out:Atemp": 5143,
    "out:Umean": 0.31,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FTX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 111,
    "out:Energy use kWh/m2": 111,
    "out:Energy savings %": 69.4,
    "out:Op energy cost/MSEK": 81,
    "out:El price/MSEK": 48,
    "out:Heating price/MSEK": 28,
    "out:DH+ DHW/kWh/m2": 97,
    "out:El bought/kWh/m2": 1013.1,
    "out:Return %": 9,
    "out:Return/kSEK/y": 1060,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 61.265983,
    "out:PV (m2 panels)": 321,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 57484.06,
    "out:PV (kWh sold)": 25763.95,
    "out:PV (% sold (El))": 44.82,
    "out:PV (kWh self-consumed)": 31720.11,
    "out:PV (ratio sold/self-consumed)": 0.81,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 1773600,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.05,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 385725,
    "out:EL kWh savings": 8067,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 397573,
    "out:DH kr savings": 351010,
    "out:El kr savings": 13715,
    "out:El kr sold": 32849,
    "out:El kr saved": 53924,
    "out:El kr return": 86773,
    "out:% solar/total": 39,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 317605,
    "out:% savings (space heating)": 51.77,
    "out:Total_Water Heating (DH)": 178976,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 91.78,
    "out:Etvv": 20,
    "out:Ef": 14.33,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000100110000101",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 57.86,
    "out:DHW (DH)": 34.8,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 14.33,
    "out:Total Energy Use Pre PV": 113.5,
    "out:Total Energy Use Post PV": 107.33,
    "out:Primary Energy": 80.3,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 44.5,
    "out:CO2 Operational/m2": 10.17,
    "out:Total CO2/m2": 54.67,
    "out:Total CO2 (tons)": 281.16,
    "out:Klimatpaverkan": -48.08,
    "out:Initial Cost/MSEK": 12.320625,
    "out:Running cost/(Apt SEK y)": 32131,
    "out:Running Cost over RSP/MSEK": 10.563,
    "out:LCP/MSEK": -3.614,
    "out:ROI% old": 10.09,
    "out:Payback discounted": 12,
    "out:Atemp": 5143,
    "out:Umean": 0.31,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FTX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 107,
    "out:Energy use kWh/m2": 107,
    "out:Energy savings %": 75.7,
    "out:Op energy cost/MSEK": 78,
    "out:El price/MSEK": 47,
    "out:Heating price/MSEK": 27,
    "out:DH+ DHW/kWh/m2": 93,
    "out:El bought/kWh/m2": 1013.1,
    "out:Return %": 9,
    "out:Return/kSEK/y": 1114,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 61.265983,
    "out:PV (m2 panels)": 321,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 57484.06,
    "out:PV (kWh sold)": 25763.95,
    "out:PV (% sold (El))": 44.82,
    "out:PV (kWh self-consumed)": 31720.11,
    "out:PV (ratio sold/self-consumed)": 0.81,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 1773600,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.05,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 406297,
    "out:EL kWh savings": 8067,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 128574.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 416294,
    "out:DH kr savings": 369730,
    "out:El kr savings": 13715,
    "out:El kr sold": 32849,
    "out:El kr saved": 53924,
    "out:El kr return": 86773,
    "out:% solar/total": 39,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 297551,
    "out:% savings (space heating)": 54.82,
    "out:Total_Water Heating (DH)": 178976,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 87.88,
    "out:Etvv": 20,
    "out:Ef": 14.33,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000100110001001",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 61.75,
    "out:DHW (DH)": 29.58,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 14.33,
    "out:Total Energy Use Pre PV": 111.5,
    "out:Total Energy Use Post PV": 105.33,
    "out:Primary Energy": 80.92,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 44.9,
    "out:CO2 Operational/m2": 9.45,
    "out:Total CO2/m2": 54.35,
    "out:Total CO2 (tons)": 279.52,
    "out:Klimatpaverkan": -49.72,
    "out:Initial Cost/MSEK": 12.4425,
    "out:Running cost/(Apt SEK y)": 31488,
    "out:Running Cost over RSP/MSEK": 10.351,
    "out:LCP/MSEK": -3.525,
    "out:ROI% old": 10.24,
    "out:Payback discounted": 12,
    "out:Atemp": 5143,
    "out:Umean": 0.31,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FTX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 105,
    "out:Energy use kWh/m2": 105,
    "out:Energy savings %": 79,
    "out:Op energy cost/MSEK": 76,
    "out:El price/MSEK": 46,
    "out:Heating price/MSEK": 26,
    "out:DH+ DHW/kWh/m2": 91,
    "out:El bought/kWh/m2": 1013.1,
    "out:Return %": 9,
    "out:Return/kSEK/y": 1141,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 61.265983,
    "out:PV (m2 panels)": 321,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 57484.06,
    "out:PV (kWh sold)": 25763.95,
    "out:PV (% sold (El))": 44.82,
    "out:PV (kWh self-consumed)": 31720.11,
    "out:PV (ratio sold/self-consumed)": 0.81,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 1773600,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.05,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 416583,
    "out:EL kWh savings": 8067,
    "out:Sewage heat recovery cost": 250448,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 425654,
    "out:DH kr savings": 379090,
    "out:El kr savings": 13715,
    "out:El kr sold": 32849,
    "out:El kr saved": 53924,
    "out:El kr return": 86773,
    "out:% solar/total": 39,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 317605,
    "out:% savings (space heating)": 51.77,
    "out:Total_Water Heating (DH)": 152130,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 91.78,
    "out:Etvv": 17,
    "out:Ef": 14.33,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000100110001101",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 57.86,
    "out:DHW (DH)": 29.58,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 14.33,
    "out:Total Energy Use Pre PV": 107.5,
    "out:Total Energy Use Post PV": 101.33,
    "out:Primary Energy": 78.2,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 44.9,
    "out:CO2 Operational/m2": 8.01,
    "out:Total CO2/m2": 52.91,
    "out:Total CO2 (tons)": 272.12,
    "out:Klimatpaverkan": -57.12,
    "out:Initial Cost/MSEK": 12.57125,
    "out:Running cost/(Apt SEK y)": 30200,
    "out:Running Cost over RSP/MSEK": 9.928,
    "out:LCP/MSEK": -3.23,
    "out:ROI% old": 10.61,
    "out:Payback discounted": 11,
    "out:Atemp": 5143,
    "out:Umean": 0.31,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FTX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 101,
    "out:Energy use kWh/m2": 101,
    "out:Energy savings %": 86.1,
    "out:Op energy cost/MSEK": 73,
    "out:El price/MSEK": 44,
    "out:Heating price/MSEK": 25,
    "out:DH+ DHW/kWh/m2": 87,
    "out:El bought/kWh/m2": 1013.1,
    "out:Return %": 10,
    "out:Return/kSEK/y": 1195,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 61.265983,
    "out:PV (m2 panels)": 321,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 57484.06,
    "out:PV (kWh sold)": 25763.95,
    "out:PV (% sold (El))": 44.82,
    "out:PV (kWh self-consumed)": 31720.11,
    "out:PV (ratio sold/self-consumed)": 0.81,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 1773600,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.05,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 437155,
    "out:EL kWh savings": 8067,
    "out:Sewage heat recovery cost": 250448,
    "out:Balancing hydronic system cost": 128574.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 444375,
    "out:DH kr savings": 397811,
    "out:El kr savings": 13715,
    "out:El kr sold": 32849,
    "out:El kr saved": 53924,
    "out:El kr return": 86773,
    "out:% solar/total": 39,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 297551,
    "out:% savings (space heating)": 54.82,
    "out:Total_Water Heating (DH)": 152130,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 87.88,
    "out:Etvv": 17,
    "out:Ef": 14.33,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000100120000001",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 61.75,
    "out:DHW (DH)": 34.8,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 13.09,
    "out:Total Energy Use Pre PV": 117.5,
    "out:Total Energy Use Post PV": 110.09,
    "out:Primary Energy": 80.79,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 56.85,
    "out:CO2 Operational/m2": -39.07,
    "out:Total CO2/m2": 17.78,
    "out:Total CO2 (tons)": 91.44,
    "out:Klimatpaverkan": -237.8,
    "out:Initial Cost/MSEK": 13.26125,
    "out:Running cost/(Apt SEK y)": 31609,
    "out:Running Cost over RSP/MSEK": 10.408,
    "out:LCP/MSEK": -4.4,
    "out:ROI% old": 9.55,
    "out:Payback discounted": 13,
    "out:Atemp": 5143,
    "out:Umean": 0.31,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FTX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 110,
    "out:Energy use kWh/m2": 110,
    "out:Energy savings %": 70.9,
    "out:Op energy cost/MSEK": 77,
    "out:El price/MSEK": 45,
    "out:Heating price/MSEK": 28,
    "out:DH+ DHW/kWh/m2": 97,
    "out:El bought/kWh/m2": 923.7,
    "out:Return %": 9,
    "out:Return/kSEK/y": 1136,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.33,
    "out:PV (kWp installed)": 122.531965,
    "out:PV (m2 panels)": 643,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 114968.11,
    "out:PV (kWh sold)": 76862.14,
    "out:PV (% sold (El))": 66.86,
    "out:PV (kWh self-consumed)": 38105.97,
    "out:PV (ratio sold/self-consumed)": 2.02,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 1773600,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.05,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 385725,
    "out:EL kWh savings": 14453,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 473580,
    "out:DH kr savings": 351010,
    "out:El kr savings": 24571,
    "out:El kr sold": 97999,
    "out:El kr saved": 64780,
    "out:El kr return": 162779,
    "out:% solar/total": 47,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 317605,
    "out:% savings (space heating)": 51.77,
    "out:Total_Water Heating (DH)": 178976,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 91.78,
    "out:Etvv": 20,
    "out:Ef": 13.09,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000100120000101",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 57.86,
    "out:DHW (DH)": 34.8,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 13.09,
    "out:Total Energy Use Pre PV": 113.5,
    "out:Total Energy Use Post PV": 106.09,
    "out:Primary Energy": 78.06,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 56.85,
    "out:CO2 Operational/m2": -40.51,
    "out:Total CO2/m2": 16.34,
    "out:Total CO2 (tons)": 84.04,
    "out:Klimatpaverkan": -245.2,
    "out:Initial Cost/MSEK": 13.39,
    "out:Running cost/(Apt SEK y)": 30322,
    "out:Running Cost over RSP/MSEK": 9.985,
    "out:LCP/MSEK": -4.106,
    "out:ROI% old": 9.91,
    "out:Payback discounted": 12,
    "out:Atemp": 5143,
    "out:Umean": 0.31,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FTX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 106,
    "out:Energy use kWh/m2": 106,
    "out:Energy savings %": 77.4,
    "out:Op energy cost/MSEK": 74,
    "out:El price/MSEK": 43,
    "out:Heating price/MSEK": 27,
    "out:DH+ DHW/kWh/m2": 93,
    "out:El bought/kWh/m2": 923.7,
    "out:Return %": 9,
    "out:Return/kSEK/y": 1190,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.33,
    "out:PV (kWp installed)": 122.531965,
    "out:PV (m2 panels)": 643,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 114968.11,
    "out:PV (kWh sold)": 76862.14,
    "out:PV (% sold (El))": 66.86,
    "out:PV (kWh self-consumed)": 38105.97,
    "out:PV (ratio sold/self-consumed)": 2.02,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 1773600,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.05,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 406297,
    "out:EL kWh savings": 14453,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 128574.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 492300,
    "out:DH kr savings": 369730,
    "out:El kr savings": 24571,
    "out:El kr sold": 97999,
    "out:El kr saved": 64780,
    "out:El kr return": 162779,
    "out:% solar/total": 47,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 297551,
    "out:% savings (space heating)": 54.82,
    "out:Total_Water Heating (DH)": 178976,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 87.88,
    "out:Etvv": 20,
    "out:Ef": 13.09,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000100120001001",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 61.75,
    "out:DHW (DH)": 29.58,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 13.09,
    "out:Total Energy Use Pre PV": 111.5,
    "out:Total Energy Use Post PV": 104.09,
    "out:Primary Energy": 78.69,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 57.25,
    "out:CO2 Operational/m2": -41.23,
    "out:Total CO2/m2": 16.02,
    "out:Total CO2 (tons)": 82.4,
    "out:Klimatpaverkan": -246.84,
    "out:Initial Cost/MSEK": 13.5125,
    "out:Running cost/(Apt SEK y)": 29678,
    "out:Running Cost over RSP/MSEK": 9.773,
    "out:LCP/MSEK": -4.017,
    "out:ROI% old": 10.04,
    "out:Payback discounted": 12,
    "out:Atemp": 5143,
    "out:Umean": 0.31,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FTX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 104,
    "out:Energy use kWh/m2": 104,
    "out:Energy savings %": 80.8,
    "out:Op energy cost/MSEK": 73,
    "out:El price/MSEK": 42,
    "out:Heating price/MSEK": 26,
    "out:DH+ DHW/kWh/m2": 91,
    "out:El bought/kWh/m2": 923.7,
    "out:Return %": 9,
    "out:Return/kSEK/y": 1217,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.33,
    "out:PV (kWp installed)": 122.531965,
    "out:PV (m2 panels)": 643,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 114968.11,
    "out:PV (kWh sold)": 76862.14,
    "out:PV (% sold (El))": 66.86,
    "out:PV (kWh self-consumed)": 38105.97,
    "out:PV (ratio sold/self-consumed)": 2.02,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 1773600,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.05,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 416583,
    "out:EL kWh savings": 14453,
    "out:Sewage heat recovery cost": 250448,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 501660,
    "out:DH kr savings": 379090,
    "out:El kr savings": 24571,
    "out:El kr sold": 97999,
    "out:El kr saved": 64780,
    "out:El kr return": 162779,
    "out:% solar/total": 47,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 317605,
    "out:% savings (space heating)": 51.77,
    "out:Total_Water Heating (DH)": 152130,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 91.78,
    "out:Etvv": 17,
    "out:Ef": 13.09,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000100120001101",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 57.86,
    "out:DHW (DH)": 29.58,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 13.09,
    "out:Total Energy Use Pre PV": 107.5,
    "out:Total Energy Use Post PV": 100.09,
    "out:Primary Energy": 75.96,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 57.25,
    "out:CO2 Operational/m2": -42.67,
    "out:Total CO2/m2": 14.58,
    "out:Total CO2 (tons)": 75,
    "out:Klimatpaverkan": -254.24,
    "out:Initial Cost/MSEK": 13.64125,
    "out:Running cost/(Apt SEK y)": 28391,
    "out:Running Cost over RSP/MSEK": 9.35,
    "out:LCP/MSEK": -3.722,
    "out:ROI% old": 10.39,
    "out:Payback discounted": 12,
    "out:Atemp": 5143,
    "out:Umean": 0.31,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FTX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 100,
    "out:Energy use kWh/m2": 100,
    "out:Energy savings %": 88,
    "out:Op energy cost/MSEK": 70,
    "out:El price/MSEK": 40,
    "out:Heating price/MSEK": 25,
    "out:DH+ DHW/kWh/m2": 87,
    "out:El bought/kWh/m2": 923.7,
    "out:Return %": 9,
    "out:Return/kSEK/y": 1272,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.33,
    "out:PV (kWp installed)": 122.531965,
    "out:PV (m2 panels)": 643,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 114968.11,
    "out:PV (kWh sold)": 76862.14,
    "out:PV (% sold (El))": 66.86,
    "out:PV (kWh self-consumed)": 38105.97,
    "out:PV (ratio sold/self-consumed)": 2.02,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 1773600,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.05,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 437155,
    "out:EL kWh savings": 14453,
    "out:Sewage heat recovery cost": 250448,
    "out:Balancing hydronic system cost": 128574.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 520381,
    "out:DH kr savings": 397811,
    "out:El kr savings": 24571,
    "out:El kr sold": 97999,
    "out:El kr saved": 64780,
    "out:El kr return": 162779,
    "out:% solar/total": 47,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 297551,
    "out:% savings (space heating)": 54.82,
    "out:Total_Water Heating (DH)": 152130,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 87.88,
    "out:Etvv": 17,
    "out:Ef": 13.09,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000200000000001",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 58.3,
    "out:DHW (DH)": 43.5,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 20.5,
    "out:Total Energy Use Pre PV": 122.5,
    "out:Total Energy Use Post PV": 122.5,
    "out:Primary Energy": 95.2,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 38.11,
    "out:CO2 Operational/m2": 39.42,
    "out:Total CO2/m2": 77.53,
    "out:Total CO2 (tons)": 398.75,
    "out:Klimatpaverkan": 69.51,
    "out:Initial Cost/MSEK": 13.0225,
    "out:Running cost/(Apt SEK y)": 37094,
    "out:Running Cost over RSP/MSEK": 12.175,
    "out:LCP/MSEK": -5.929,
    "out:ROI% old": 7.78,
    "out:Payback discounted": 15,
    "out:Atemp": 5143,
    "out:Umean": 0.26,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FTX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 122,
    "out:Energy use kWh/m2": 122,
    "out:Energy savings %": 54.1,
    "out:Op energy cost/MSEK": 89,
    "out:El price/MSEK": 55,
    "out:Heating price/MSEK": 29,
    "out:DH+ DHW/kWh/m2": 102,
    "out:El bought/kWh/m2": 1434.9,
    "out:Return %": 7,
    "out:Return/kSEK/y": 906,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 1773600,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.05,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 2,
    "out:Payback Time": 50,
    "out:DH kWh savings": 360010,
    "out:EL kWh savings": -23653,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 287400,
    "out:DH kr savings": 327609,
    "out:El kr savings": -40210,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 299812,
    "out:% savings (space heating)": 54.48,
    "out:Total_Water Heating (DH)": 223720,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 88.32,
    "out:Etvv": 25,
    "out:Ef": 20.5,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000200000000101",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 54.55,
    "out:DHW (DH)": 43.5,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 20.5,
    "out:Total Energy Use Pre PV": 118.5,
    "out:Total Energy Use Post PV": 118.5,
    "out:Primary Energy": 92.59,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 38.11,
    "out:CO2 Operational/m2": 37.98,
    "out:Total CO2/m2": 76.09,
    "out:Total CO2 (tons)": 391.35,
    "out:Klimatpaverkan": 62.11,
    "out:Initial Cost/MSEK": 13.15125,
    "out:Running cost/(Apt SEK y)": 35807,
    "out:Running Cost over RSP/MSEK": 11.752,
    "out:LCP/MSEK": -5.634,
    "out:ROI% old": 8.17,
    "out:Payback discounted": 15,
    "out:Atemp": 5143,
    "out:Umean": 0.26,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FTX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 118,
    "out:Energy use kWh/m2": 118,
    "out:Energy savings %": 59.3,
    "out:Op energy cost/MSEK": 86,
    "out:El price/MSEK": 53,
    "out:Heating price/MSEK": 28,
    "out:DH+ DHW/kWh/m2": 98,
    "out:El bought/kWh/m2": 1434.9,
    "out:Return %": 7,
    "out:Return/kSEK/y": 960,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 1773600,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.05,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 2,
    "out:Payback Time": 50,
    "out:DH kWh savings": 380582,
    "out:EL kWh savings": -23653,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 128574.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 306120,
    "out:DH kr savings": 346330,
    "out:El kr savings": -40210,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 280567,
    "out:% savings (space heating)": 57.4,
    "out:Total_Water Heating (DH)": 223720,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 84.58,
    "out:Etvv": 25,
    "out:Ef": 20.5,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000200000001001",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 58.3,
    "out:DHW (DH)": 36.98,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 20.5,
    "out:Total Energy Use Pre PV": 115.5,
    "out:Total Energy Use Post PV": 115.5,
    "out:Primary Energy": 92.58,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 38.51,
    "out:CO2 Operational/m2": 36.9,
    "out:Total CO2/m2": 75.42,
    "out:Total CO2 (tons)": 387.86,
    "out:Klimatpaverkan": 58.62,
    "out:Initial Cost/MSEK": 13.27375,
    "out:Running cost/(Apt SEK y)": 34841,
    "out:Running Cost over RSP/MSEK": 11.435,
    "out:LCP/MSEK": -5.439,
    "out:ROI% old": 8.43,
    "out:Payback discounted": 14,
    "out:Atemp": 5143,
    "out:Umean": 0.26,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FTX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 115,
    "out:Energy use kWh/m2": 115,
    "out:Energy savings %": 63.5,
    "out:Op energy cost/MSEK": 83,
    "out:El price/MSEK": 52,
    "out:Heating price/MSEK": 27,
    "out:DH+ DHW/kWh/m2": 95,
    "out:El bought/kWh/m2": 1434.9,
    "out:Return %": 8,
    "out:Return/kSEK/y": 1001,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 1773600,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.05,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 2,
    "out:Payback Time": 50,
    "out:DH kWh savings": 396011,
    "out:EL kWh savings": -23653,
    "out:Sewage heat recovery cost": 250448,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 320160,
    "out:DH kr savings": 360370,
    "out:El kr savings": -40210,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 299812,
    "out:% savings (space heating)": 54.48,
    "out:Total_Water Heating (DH)": 190162,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 88.32,
    "out:Etvv": 21.25,
    "out:Ef": 20.5,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000200000001101",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 54.55,
    "out:DHW (DH)": 36.98,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 20.5,
    "out:Total Energy Use Pre PV": 112.5,
    "out:Total Energy Use Post PV": 112.5,
    "out:Primary Energy": 89.96,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 38.51,
    "out:CO2 Operational/m2": 35.82,
    "out:Total CO2/m2": 74.34,
    "out:Total CO2 (tons)": 382.31,
    "out:Klimatpaverkan": 53.07,
    "out:Initial Cost/MSEK": 13.4025,
    "out:Running cost/(Apt SEK y)": 33876,
    "out:Running Cost over RSP/MSEK": 11.117,
    "out:LCP/MSEK": -5.251,
    "out:ROI% old": 8.69,
    "out:Payback discounted": 14,
    "out:Atemp": 5143,
    "out:Umean": 0.26,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FTX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 112,
    "out:Energy use kWh/m2": 112,
    "out:Energy savings %": 67.9,
    "out:Op energy cost/MSEK": 81,
    "out:El price/MSEK": 50,
    "out:Heating price/MSEK": 26,
    "out:DH+ DHW/kWh/m2": 92,
    "out:El bought/kWh/m2": 1434.9,
    "out:Return %": 8,
    "out:Return/kSEK/y": 1041,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 1773600,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.05,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 2,
    "out:Payback Time": 50,
    "out:DH kWh savings": 411440,
    "out:EL kWh savings": -23653,
    "out:Sewage heat recovery cost": 250448,
    "out:Balancing hydronic system cost": 128574.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 334201,
    "out:DH kr savings": 374410,
    "out:El kr savings": -40210,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 280567,
    "out:% savings (space heating)": 57.4,
    "out:Total_Water Heating (DH)": 190162,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 84.58,
    "out:Etvv": 21.25,
    "out:Ef": 20.5,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000200010000001",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 58.3,
    "out:DHW (DH)": 43.5,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 14.33,
    "out:Total Energy Use Pre PV": 122.5,
    "out:Total Energy Use Post PV": 116.33,
    "out:Primary Energy": 84.1,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 50.46,
    "out:CO2 Operational/m2": 13.41,
    "out:Total CO2/m2": 63.87,
    "out:Total CO2 (tons)": 328.49,
    "out:Klimatpaverkan": -0.75,
    "out:Initial Cost/MSEK": 14.0925,
    "out:Running cost/(Apt SEK y)": 35028,
    "out:Running Cost over RSP/MSEK": 11.515,
    "out:LCP/MSEK": -6.338,
    "out:ROI% old": 7.86,
    "out:Payback discounted": 15,
    "out:Atemp": 5143,
    "out:Umean": 0.26,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FTX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 116,
    "out:Energy use kWh/m2": 116,
    "out:Energy savings %": 62.1,
    "out:Op energy cost/MSEK": 85,
    "out:El price/MSEK": 51,
    "out:Heating price/MSEK": 29,
    "out:DH+ DHW/kWh/m2": 102,
    "out:El bought/kWh/m2": 1013.1,
    "out:Return %": 7,
    "out:Return/kSEK/y": 993,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 61.265983,
    "out:PV (m2 panels)": 321,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 57484.06,
    "out:PV (kWh sold)": 25763.95,
    "out:PV (% sold (El))": 44.82,
    "out:PV (kWh self-consumed)": 31720.11,
    "out:PV (ratio sold/self-consumed)": 0.81,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 1773600,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.05,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 360010,
    "out:EL kWh savings": 8067,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 374173,
    "out:DH kr savings": 327609,
    "out:El kr savings": 13715,
    "out:El kr sold": 32849,
    "out:El kr saved": 53924,
    "out:El kr return": 86773,
    "out:% solar/total": 39,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 299812,
    "out:% savings (space heating)": 54.48,
    "out:Total_Water Heating (DH)": 223720,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 88.32,
    "out:Etvv": 25,
    "out:Ef": 14.33,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000200010000101",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 54.55,
    "out:DHW (DH)": 43.5,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 14.33,
    "out:Total Energy Use Pre PV": 118.5,
    "out:Total Energy Use Post PV": 112.33,
    "out:Primary Energy": 81.48,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 50.46,
    "out:CO2 Operational/m2": 11.97,
    "out:Total CO2/m2": 62.43,
    "out:Total CO2 (tons)": 321.09,
    "out:Klimatpaverkan": -8.15,
    "out:Initial Cost/MSEK": 14.22125,
    "out:Running cost/(Apt SEK y)": 33740,
    "out:Running Cost over RSP/MSEK": 11.092,
    "out:LCP/MSEK": -6.044,
    "out:ROI% old": 8.21,
    "out:Payback discounted": 15,
    "out:Atemp": 5143,
    "out:Umean": 0.26,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FTX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 112,
    "out:Energy use kWh/m2": 112,
    "out:Energy savings %": 67.9,
    "out:Op energy cost/MSEK": 82,
    "out:El price/MSEK": 49,
    "out:Heating price/MSEK": 28,
    "out:DH+ DHW/kWh/m2": 98,
    "out:El bought/kWh/m2": 1013.1,
    "out:Return %": 7,
    "out:Return/kSEK/y": 1047,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 61.265983,
    "out:PV (m2 panels)": 321,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 57484.06,
    "out:PV (kWh sold)": 25763.95,
    "out:PV (% sold (El))": 44.82,
    "out:PV (kWh self-consumed)": 31720.11,
    "out:PV (ratio sold/self-consumed)": 0.81,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 1773600,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.05,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 380582,
    "out:EL kWh savings": 8067,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 128574.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 392893,
    "out:DH kr savings": 346330,
    "out:El kr savings": 13715,
    "out:El kr sold": 32849,
    "out:El kr saved": 53924,
    "out:El kr return": 86773,
    "out:% solar/total": 39,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 280567,
    "out:% savings (space heating)": 57.4,
    "out:Total_Water Heating (DH)": 223720,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 84.58,
    "out:Etvv": 25,
    "out:Ef": 14.33,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000200010001001",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 58.3,
    "out:DHW (DH)": 36.98,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 14.33,
    "out:Total Energy Use Pre PV": 115.5,
    "out:Total Energy Use Post PV": 109.33,
    "out:Primary Energy": 81.48,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 50.86,
    "out:CO2 Operational/m2": 10.89,
    "out:Total CO2/m2": 61.75,
    "out:Total CO2 (tons)": 317.6,
    "out:Klimatpaverkan": -11.64,
    "out:Initial Cost/MSEK": 14.34375,
    "out:Running cost/(Apt SEK y)": 32775,
    "out:Running Cost over RSP/MSEK": 10.774,
    "out:LCP/MSEK": -5.849,
    "out:ROI% old": 8.46,
    "out:Payback discounted": 14,
    "out:Atemp": 5143,
    "out:Umean": 0.26,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FTX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 109,
    "out:Energy use kWh/m2": 109,
    "out:Energy savings %": 72.5,
    "out:Op energy cost/MSEK": 79,
    "out:El price/MSEK": 48,
    "out:Heating price/MSEK": 27,
    "out:DH+ DHW/kWh/m2": 95,
    "out:El bought/kWh/m2": 1013.1,
    "out:Return %": 8,
    "out:Return/kSEK/y": 1087,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 61.265983,
    "out:PV (m2 panels)": 321,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 57484.06,
    "out:PV (kWh sold)": 25763.95,
    "out:PV (% sold (El))": 44.82,
    "out:PV (kWh self-consumed)": 31720.11,
    "out:PV (ratio sold/self-consumed)": 0.81,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 1773600,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.05,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 396011,
    "out:EL kWh savings": 8067,
    "out:Sewage heat recovery cost": 250448,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 406934,
    "out:DH kr savings": 360370,
    "out:El kr savings": 13715,
    "out:El kr sold": 32849,
    "out:El kr saved": 53924,
    "out:El kr return": 86773,
    "out:% solar/total": 39,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 299812,
    "out:% savings (space heating)": 54.48,
    "out:Total_Water Heating (DH)": 190162,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 88.32,
    "out:Etvv": 21.25,
    "out:Ef": 14.33,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000200010001101",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 54.55,
    "out:DHW (DH)": 36.98,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 14.33,
    "out:Total Energy Use Pre PV": 112.5,
    "out:Total Energy Use Post PV": 106.33,
    "out:Primary Energy": 78.86,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 50.86,
    "out:CO2 Operational/m2": 9.81,
    "out:Total CO2/m2": 60.67,
    "out:Total CO2 (tons)": 312.05,
    "out:Klimatpaverkan": -17.19,
    "out:Initial Cost/MSEK": 14.47125,
    "out:Running cost/(Apt SEK y)": 31810,
    "out:Running Cost over RSP/MSEK": 10.457,
    "out:LCP/MSEK": -5.659,
    "out:ROI% old": 8.7,
    "out:Payback discounted": 14,
    "out:Atemp": 5143,
    "out:Umean": 0.26,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FTX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 106,
    "out:Energy use kWh/m2": 106,
    "out:Energy savings %": 77.4,
    "out:Op energy cost/MSEK": 77,
    "out:El price/MSEK": 46,
    "out:Heating price/MSEK": 26,
    "out:DH+ DHW/kWh/m2": 92,
    "out:El bought/kWh/m2": 1013.1,
    "out:Return %": 8,
    "out:Return/kSEK/y": 1128,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 61.265983,
    "out:PV (m2 panels)": 321,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 57484.06,
    "out:PV (kWh sold)": 25763.95,
    "out:PV (% sold (El))": 44.82,
    "out:PV (kWh self-consumed)": 31720.11,
    "out:PV (ratio sold/self-consumed)": 0.81,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 1773600,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.05,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 411440,
    "out:EL kWh savings": 8067,
    "out:Sewage heat recovery cost": 250448,
    "out:Balancing hydronic system cost": 128574.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 420974,
    "out:DH kr savings": 374410,
    "out:El kr savings": 13715,
    "out:El kr sold": 32849,
    "out:El kr saved": 53924,
    "out:El kr return": 86773,
    "out:% solar/total": 39,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 280567,
    "out:% savings (space heating)": 57.4,
    "out:Total_Water Heating (DH)": 190162,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 84.58,
    "out:Etvv": 21.25,
    "out:Ef": 14.33,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000200020000001",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 58.3,
    "out:DHW (DH)": 43.5,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 13.09,
    "out:Total Energy Use Pre PV": 122.5,
    "out:Total Energy Use Post PV": 115.09,
    "out:Primary Energy": 81.87,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 62.82,
    "out:CO2 Operational/m2": -37.27,
    "out:Total CO2/m2": 25.54,
    "out:Total CO2 (tons)": 131.37,
    "out:Klimatpaverkan": -197.87,
    "out:Initial Cost/MSEK": 15.1625,
    "out:Running cost/(Apt SEK y)": 33218,
    "out:Running Cost over RSP/MSEK": 10.937,
    "out:LCP/MSEK": -6.83,
    "out:ROI% old": 7.85,
    "out:Payback discounted": 15,
    "out:Atemp": 5143,
    "out:Umean": 0.26,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FTX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 115,
    "out:Energy use kWh/m2": 115,
    "out:Energy savings %": 63.5,
    "out:Op energy cost/MSEK": 81,
    "out:El price/MSEK": 47,
    "out:Heating price/MSEK": 29,
    "out:DH+ DHW/kWh/m2": 102,
    "out:El bought/kWh/m2": 923.7,
    "out:Return %": 7,
    "out:Return/kSEK/y": 1069,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.33,
    "out:PV (kWp installed)": 122.531965,
    "out:PV (m2 panels)": 643,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 114968.11,
    "out:PV (kWh sold)": 76862.14,
    "out:PV (% sold (El))": 66.86,
    "out:PV (kWh self-consumed)": 38105.97,
    "out:PV (ratio sold/self-consumed)": 2.02,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 1773600,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.05,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 360010,
    "out:EL kWh savings": 14453,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 450179,
    "out:DH kr savings": 327609,
    "out:El kr savings": 24571,
    "out:El kr sold": 97999,
    "out:El kr saved": 64780,
    "out:El kr return": 162779,
    "out:% solar/total": 47,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 299812,
    "out:% savings (space heating)": 54.48,
    "out:Total_Water Heating (DH)": 223720,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 88.32,
    "out:Etvv": 25,
    "out:Ef": 13.09,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000200020000101",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 54.55,
    "out:DHW (DH)": 43.5,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 13.09,
    "out:Total Energy Use Pre PV": 118.5,
    "out:Total Energy Use Post PV": 111.09,
    "out:Primary Energy": 79.25,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 62.82,
    "out:CO2 Operational/m2": -38.71,
    "out:Total CO2/m2": 24.1,
    "out:Total CO2 (tons)": 123.97,
    "out:Klimatpaverkan": -205.27,
    "out:Initial Cost/MSEK": 15.29125,
    "out:Running cost/(Apt SEK y)": 31931,
    "out:Running Cost over RSP/MSEK": 10.514,
    "out:LCP/MSEK": -6.536,
    "out:ROI% old": 8.18,
    "out:Payback discounted": 15,
    "out:Atemp": 5143,
    "out:Umean": 0.26,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FTX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 111,
    "out:Energy use kWh/m2": 111,
    "out:Energy savings %": 69.4,
    "out:Op energy cost/MSEK": 78,
    "out:El price/MSEK": 45,
    "out:Heating price/MSEK": 28,
    "out:DH+ DHW/kWh/m2": 98,
    "out:El bought/kWh/m2": 923.7,
    "out:Return %": 7,
    "out:Return/kSEK/y": 1123,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.33,
    "out:PV (kWp installed)": 122.531965,
    "out:PV (m2 panels)": 643,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 114968.11,
    "out:PV (kWh sold)": 76862.14,
    "out:PV (% sold (El))": 66.86,
    "out:PV (kWh self-consumed)": 38105.97,
    "out:PV (ratio sold/self-consumed)": 2.02,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 1773600,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.05,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 380582,
    "out:EL kWh savings": 14453,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 128574.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 468899,
    "out:DH kr savings": 346330,
    "out:El kr savings": 24571,
    "out:El kr sold": 97999,
    "out:El kr saved": 64780,
    "out:El kr return": 162779,
    "out:% solar/total": 47,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 280567,
    "out:% savings (space heating)": 57.4,
    "out:Total_Water Heating (DH)": 223720,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 84.58,
    "out:Etvv": 25,
    "out:Ef": 13.09,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000200020001001",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 58.3,
    "out:DHW (DH)": 36.98,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 13.09,
    "out:Total Energy Use Pre PV": 115.5,
    "out:Total Energy Use Post PV": 108.09,
    "out:Primary Energy": 79.24,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 63.22,
    "out:CO2 Operational/m2": -39.79,
    "out:Total CO2/m2": 23.43,
    "out:Total CO2 (tons)": 120.48,
    "out:Klimatpaverkan": -208.76,
    "out:Initial Cost/MSEK": 15.4125,
    "out:Running cost/(Apt SEK y)": 30965,
    "out:Running Cost over RSP/MSEK": 10.196,
    "out:LCP/MSEK": -6.34,
    "out:ROI% old": 8.41,
    "out:Payback discounted": 14,
    "out:Atemp": 5143,
    "out:Umean": 0.26,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FTX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 108,
    "out:Energy use kWh/m2": 108,
    "out:Energy savings %": 74.1,
    "out:Op energy cost/MSEK": 76,
    "out:El price/MSEK": 44,
    "out:Heating price/MSEK": 27,
    "out:DH+ DHW/kWh/m2": 95,
    "out:El bought/kWh/m2": 923.7,
    "out:Return %": 8,
    "out:Return/kSEK/y": 1163,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.33,
    "out:PV (kWp installed)": 122.531965,
    "out:PV (m2 panels)": 643,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 114968.11,
    "out:PV (kWh sold)": 76862.14,
    "out:PV (% sold (El))": 66.86,
    "out:PV (kWh self-consumed)": 38105.97,
    "out:PV (ratio sold/self-consumed)": 2.02,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 1773600,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.05,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 396011,
    "out:EL kWh savings": 14453,
    "out:Sewage heat recovery cost": 250448,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 482940,
    "out:DH kr savings": 360370,
    "out:El kr savings": 24571,
    "out:El kr sold": 97999,
    "out:El kr saved": 64780,
    "out:El kr return": 162779,
    "out:% solar/total": 47,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 299812,
    "out:% savings (space heating)": 54.48,
    "out:Total_Water Heating (DH)": 190162,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 88.32,
    "out:Etvv": 21.25,
    "out:Ef": 13.09,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000200020001101",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 54.55,
    "out:DHW (DH)": 36.98,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 13.09,
    "out:Total Energy Use Pre PV": 112.5,
    "out:Total Energy Use Post PV": 105.09,
    "out:Primary Energy": 76.62,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 63.22,
    "out:CO2 Operational/m2": -40.87,
    "out:Total CO2/m2": 22.35,
    "out:Total CO2 (tons)": 114.93,
    "out:Klimatpaverkan": -214.31,
    "out:Initial Cost/MSEK": 15.54125,
    "out:Running cost/(Apt SEK y)": 30000,
    "out:Running Cost over RSP/MSEK": 9.879,
    "out:LCP/MSEK": -6.151,
    "out:ROI% old": 8.63,
    "out:Payback discounted": 14,
    "out:Atemp": 5143,
    "out:Umean": 0.26,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FTX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 105,
    "out:Energy use kWh/m2": 105,
    "out:Energy savings %": 79,
    "out:Op energy cost/MSEK": 73,
    "out:El price/MSEK": 43,
    "out:Heating price/MSEK": 26,
    "out:DH+ DHW/kWh/m2": 92,
    "out:El bought/kWh/m2": 923.7,
    "out:Return %": 8,
    "out:Return/kSEK/y": 1204,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.33,
    "out:PV (kWp installed)": 122.531965,
    "out:PV (m2 panels)": 643,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 114968.11,
    "out:PV (kWh sold)": 76862.14,
    "out:PV (% sold (El))": 66.86,
    "out:PV (kWh self-consumed)": 38105.97,
    "out:PV (ratio sold/self-consumed)": 2.02,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 1773600,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.05,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 411440,
    "out:EL kWh savings": 14453,
    "out:Sewage heat recovery cost": 250448,
    "out:Balancing hydronic system cost": 128574.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 496980,
    "out:DH kr savings": 374410,
    "out:El kr savings": 24571,
    "out:El kr sold": 97999,
    "out:El kr saved": 64780,
    "out:El kr return": 162779,
    "out:% solar/total": 47,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 280567,
    "out:% savings (space heating)": 57.4,
    "out:Total_Water Heating (DH)": 190162,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 84.58,
    "out:Etvv": 21.25,
    "out:Ef": 13.09,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000200100000001",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 58.3,
    "out:DHW (DH)": 34.8,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 20.5,
    "out:Total Energy Use Pre PV": 113.5,
    "out:Total Energy Use Post PV": 113.5,
    "out:Primary Energy": 91.7,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 38.11,
    "out:CO2 Operational/m2": 36.18,
    "out:Total CO2/m2": 74.29,
    "out:Total CO2 (tons)": 382.09,
    "out:Klimatpaverkan": 52.85,
    "out:Initial Cost/MSEK": 13.13375,
    "out:Running cost/(Apt SEK y)": 34197,
    "out:Running Cost over RSP/MSEK": 11.223,
    "out:LCP/MSEK": -5.088,
    "out:ROI% old": 8.75,
    "out:Payback discounted": 14,
    "out:Atemp": 5143,
    "out:Umean": 0.26,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FTX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 113,
    "out:Energy use kWh/m2": 113,
    "out:Energy savings %": 66.4,
    "out:Op energy cost/MSEK": 82,
    "out:El price/MSEK": 51,
    "out:Heating price/MSEK": 27,
    "out:DH+ DHW/kWh/m2": 93,
    "out:El bought/kWh/m2": 1434.9,
    "out:Return %": 8,
    "out:Return/kSEK/y": 1028,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 1773600,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.05,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 406297,
    "out:EL kWh savings": -23653,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 329521,
    "out:DH kr savings": 369730,
    "out:El kr savings": -40210,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 299812,
    "out:% savings (space heating)": 54.48,
    "out:Total_Water Heating (DH)": 178976,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 88.32,
    "out:Etvv": 20,
    "out:Ef": 20.5,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000200100000101",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 54.55,
    "out:DHW (DH)": 34.8,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 20.5,
    "out:Total Energy Use Pre PV": 109.5,
    "out:Total Energy Use Post PV": 109.5,
    "out:Primary Energy": 89.09,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 38.11,
    "out:CO2 Operational/m2": 34.74,
    "out:Total CO2/m2": 72.85,
    "out:Total CO2 (tons)": 374.68,
    "out:Klimatpaverkan": 45.44,
    "out:Initial Cost/MSEK": 13.2625,
    "out:Running cost/(Apt SEK y)": 32910,
    "out:Running Cost over RSP/MSEK": 10.8,
    "out:LCP/MSEK": -4.794,
    "out:ROI% old": 9.12,
    "out:Payback discounted": 13,
    "out:Atemp": 5143,
    "out:Umean": 0.26,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FTX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 109,
    "out:Energy use kWh/m2": 109,
    "out:Energy savings %": 72.5,
    "out:Op energy cost/MSEK": 79,
    "out:El price/MSEK": 49,
    "out:Heating price/MSEK": 25,
    "out:DH+ DHW/kWh/m2": 89,
    "out:El bought/kWh/m2": 1434.9,
    "out:Return %": 8,
    "out:Return/kSEK/y": 1082,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 1773600,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.05,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 426869,
    "out:EL kWh savings": -23653,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 128574.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 348241,
    "out:DH kr savings": 388451,
    "out:El kr savings": -40210,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 280567,
    "out:% savings (space heating)": 57.4,
    "out:Total_Water Heating (DH)": 178976,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 84.58,
    "out:Etvv": 20,
    "out:Ef": 20.5,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000200100001001",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 58.3,
    "out:DHW (DH)": 29.58,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 20.5,
    "out:Total Energy Use Pre PV": 108.5,
    "out:Total Energy Use Post PV": 108.5,
    "out:Primary Energy": 89.6,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 38.51,
    "out:CO2 Operational/m2": 34.38,
    "out:Total CO2/m2": 72.9,
    "out:Total CO2 (tons)": 374.9,
    "out:Klimatpaverkan": 45.66,
    "out:Initial Cost/MSEK": 13.38375,
    "out:Running cost/(Apt SEK y)": 32588,
    "out:Running Cost over RSP/MSEK": 10.694,
    "out:LCP/MSEK": -4.809,
    "out:ROI% old": 9.15,
    "out:Payback discounted": 13,
    "out:Atemp": 5143,
    "out:Umean": 0.26,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FTX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 108,
    "out:Energy use kWh/m2": 108,
    "out:Energy savings %": 74.1,
    "out:Op energy cost/MSEK": 78,
    "out:El price/MSEK": 48,
    "out:Heating price/MSEK": 25,
    "out:DH+ DHW/kWh/m2": 88,
    "out:El bought/kWh/m2": 1434.9,
    "out:Return %": 8,
    "out:Return/kSEK/y": 1095,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 1773600,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.05,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 432012,
    "out:EL kWh savings": -23653,
    "out:Sewage heat recovery cost": 250448,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 352921,
    "out:DH kr savings": 393131,
    "out:El kr savings": -40210,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 299812,
    "out:% savings (space heating)": 54.48,
    "out:Total_Water Heating (DH)": 152130,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 88.32,
    "out:Etvv": 17,
    "out:Ef": 20.5,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000200100001101",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 54.55,
    "out:DHW (DH)": 29.58,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 20.5,
    "out:Total Energy Use Pre PV": 104.5,
    "out:Total Energy Use Post PV": 104.5,
    "out:Primary Energy": 86.99,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 38.51,
    "out:CO2 Operational/m2": 32.94,
    "out:Total CO2/m2": 71.46,
    "out:Total CO2 (tons)": 367.49,
    "out:Klimatpaverkan": 38.25,
    "out:Initial Cost/MSEK": 13.5125,
    "out:Running cost/(Apt SEK y)": 31301,
    "out:Running Cost over RSP/MSEK": 10.271,
    "out:LCP/MSEK": -4.515,
    "out:ROI% old": 9.51,
    "out:Payback discounted": 13,
    "out:Atemp": 5143,
    "out:Umean": 0.26,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FTX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 104,
    "out:Energy use kWh/m2": 104,
    "out:Energy savings %": 80.8,
    "out:Op energy cost/MSEK": 75,
    "out:El price/MSEK": 47,
    "out:Heating price/MSEK": 24,
    "out:DH+ DHW/kWh/m2": 84,
    "out:El bought/kWh/m2": 1434.9,
    "out:Return %": 9,
    "out:Return/kSEK/y": 1149,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 1773600,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.05,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 452584,
    "out:EL kWh savings": -23653,
    "out:Sewage heat recovery cost": 250448,
    "out:Balancing hydronic system cost": 128574.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 371642,
    "out:DH kr savings": 411851,
    "out:El kr savings": -40210,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 280567,
    "out:% savings (space heating)": 57.4,
    "out:Total_Water Heating (DH)": 152130,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 84.58,
    "out:Etvv": 17,
    "out:Ef": 20.5,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000200110000001",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 58.3,
    "out:DHW (DH)": 34.8,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 14.33,
    "out:Total Energy Use Pre PV": 113.5,
    "out:Total Energy Use Post PV": 107.33,
    "out:Primary Energy": 80.6,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 50.46,
    "out:CO2 Operational/m2": 10.17,
    "out:Total CO2/m2": 60.63,
    "out:Total CO2 (tons)": 311.83,
    "out:Klimatpaverkan": -17.41,
    "out:Initial Cost/MSEK": 14.20375,
    "out:Running cost/(Apt SEK y)": 32131,
    "out:Running Cost over RSP/MSEK": 10.563,
    "out:LCP/MSEK": -5.497,
    "out:ROI% old": 8.76,
    "out:Payback discounted": 14,
    "out:Atemp": 5143,
    "out:Umean": 0.26,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FTX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 107,
    "out:Energy use kWh/m2": 107,
    "out:Energy savings %": 75.7,
    "out:Op energy cost/MSEK": 78,
    "out:El price/MSEK": 47,
    "out:Heating price/MSEK": 27,
    "out:DH+ DHW/kWh/m2": 93,
    "out:El bought/kWh/m2": 1013.1,
    "out:Return %": 8,
    "out:Return/kSEK/y": 1114,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 61.265983,
    "out:PV (m2 panels)": 321,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 57484.06,
    "out:PV (kWh sold)": 25763.95,
    "out:PV (% sold (El))": 44.82,
    "out:PV (kWh self-consumed)": 31720.11,
    "out:PV (ratio sold/self-consumed)": 0.81,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 1773600,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.05,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 406297,
    "out:EL kWh savings": 8067,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 416294,
    "out:DH kr savings": 369730,
    "out:El kr savings": 13715,
    "out:El kr sold": 32849,
    "out:El kr saved": 53924,
    "out:El kr return": 86773,
    "out:% solar/total": 39,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 299812,
    "out:% savings (space heating)": 54.48,
    "out:Total_Water Heating (DH)": 178976,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 88.32,
    "out:Etvv": 20,
    "out:Ef": 14.33,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000200110000101",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 54.55,
    "out:DHW (DH)": 34.8,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 14.33,
    "out:Total Energy Use Pre PV": 109.5,
    "out:Total Energy Use Post PV": 103.33,
    "out:Primary Energy": 77.98,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 50.46,
    "out:CO2 Operational/m2": 8.73,
    "out:Total CO2/m2": 59.19,
    "out:Total CO2 (tons)": 304.42,
    "out:Klimatpaverkan": -24.82,
    "out:Initial Cost/MSEK": 14.3325,
    "out:Running cost/(Apt SEK y)": 30844,
    "out:Running Cost over RSP/MSEK": 10.14,
    "out:LCP/MSEK": -5.203,
    "out:ROI% old": 9.1,
    "out:Payback discounted": 13,
    "out:Atemp": 5143,
    "out:Umean": 0.26,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FTX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 103,
    "out:Energy use kWh/m2": 103,
    "out:Energy savings %": 82.5,
    "out:Op energy cost/MSEK": 75,
    "out:El price/MSEK": 45,
    "out:Heating price/MSEK": 25,
    "out:DH+ DHW/kWh/m2": 89,
    "out:El bought/kWh/m2": 1013.1,
    "out:Return %": 8,
    "out:Return/kSEK/y": 1168,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 61.265983,
    "out:PV (m2 panels)": 321,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 57484.06,
    "out:PV (kWh sold)": 25763.95,
    "out:PV (% sold (El))": 44.82,
    "out:PV (kWh self-consumed)": 31720.11,
    "out:PV (ratio sold/self-consumed)": 0.81,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 1773600,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.05,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 426869,
    "out:EL kWh savings": 8067,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 128574.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 435014,
    "out:DH kr savings": 388451,
    "out:El kr savings": 13715,
    "out:El kr sold": 32849,
    "out:El kr saved": 53924,
    "out:El kr return": 86773,
    "out:% solar/total": 39,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 280567,
    "out:% savings (space heating)": 57.4,
    "out:Total_Water Heating (DH)": 178976,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 84.58,
    "out:Etvv": 20,
    "out:Ef": 14.33,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000200110001001",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 58.3,
    "out:DHW (DH)": 29.58,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 14.33,
    "out:Total Energy Use Pre PV": 108.5,
    "out:Total Energy Use Post PV": 102.33,
    "out:Primary Energy": 78.5,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 50.86,
    "out:CO2 Operational/m2": 8.37,
    "out:Total CO2/m2": 59.23,
    "out:Total CO2 (tons)": 304.64,
    "out:Klimatpaverkan": -24.6,
    "out:Initial Cost/MSEK": 14.45375,
    "out:Running cost/(Apt SEK y)": 30522,
    "out:Running Cost over RSP/MSEK": 10.034,
    "out:LCP/MSEK": -5.218,
    "out:ROI% old": 9.13,
    "out:Payback discounted": 13,
    "out:Atemp": 5143,
    "out:Umean": 0.26,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FTX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 102,
    "out:Energy use kWh/m2": 102,
    "out:Energy savings %": 84.3,
    "out:Op energy cost/MSEK": 74,
    "out:El price/MSEK": 44,
    "out:Heating price/MSEK": 25,
    "out:DH+ DHW/kWh/m2": 88,
    "out:El bought/kWh/m2": 1013.1,
    "out:Return %": 8,
    "out:Return/kSEK/y": 1182,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 61.265983,
    "out:PV (m2 panels)": 321,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 57484.06,
    "out:PV (kWh sold)": 25763.95,
    "out:PV (% sold (El))": 44.82,
    "out:PV (kWh self-consumed)": 31720.11,
    "out:PV (ratio sold/self-consumed)": 0.81,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 1773600,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.05,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 432012,
    "out:EL kWh savings": 8067,
    "out:Sewage heat recovery cost": 250448,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 439695,
    "out:DH kr savings": 393131,
    "out:El kr savings": 13715,
    "out:El kr sold": 32849,
    "out:El kr saved": 53924,
    "out:El kr return": 86773,
    "out:% solar/total": 39,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 299812,
    "out:% savings (space heating)": 54.48,
    "out:Total_Water Heating (DH)": 152130,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 88.32,
    "out:Etvv": 17,
    "out:Ef": 14.33,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000200110001101",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 54.55,
    "out:DHW (DH)": 29.58,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 14.33,
    "out:Total Energy Use Pre PV": 104.5,
    "out:Total Energy Use Post PV": 98.33,
    "out:Primary Energy": 75.88,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 50.86,
    "out:CO2 Operational/m2": 6.93,
    "out:Total CO2/m2": 57.79,
    "out:Total CO2 (tons)": 297.23,
    "out:Klimatpaverkan": -32.01,
    "out:Initial Cost/MSEK": 14.5825,
    "out:Running cost/(Apt SEK y)": 29235,
    "out:Running Cost over RSP/MSEK": 9.611,
    "out:LCP/MSEK": -4.924,
    "out:ROI% old": 9.46,
    "out:Payback discounted": 13,
    "out:Atemp": 5143,
    "out:Umean": 0.26,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FTX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 98,
    "out:Energy use kWh/m2": 98,
    "out:Energy savings %": 91.8,
    "out:Op energy cost/MSEK": 71,
    "out:El price/MSEK": 43,
    "out:Heating price/MSEK": 24,
    "out:DH+ DHW/kWh/m2": 84,
    "out:El bought/kWh/m2": 1013.1,
    "out:Return %": 8,
    "out:Return/kSEK/y": 1236,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 61.265983,
    "out:PV (m2 panels)": 321,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 57484.06,
    "out:PV (kWh sold)": 25763.95,
    "out:PV (% sold (El))": 44.82,
    "out:PV (kWh self-consumed)": 31720.11,
    "out:PV (ratio sold/self-consumed)": 0.81,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 1773600,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.05,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 452584,
    "out:EL kWh savings": 8067,
    "out:Sewage heat recovery cost": 250448,
    "out:Balancing hydronic system cost": 128574.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 458415,
    "out:DH kr savings": 411851,
    "out:El kr savings": 13715,
    "out:El kr sold": 32849,
    "out:El kr saved": 53924,
    "out:El kr return": 86773,
    "out:% solar/total": 39,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 280567,
    "out:% savings (space heating)": 57.4,
    "out:Total_Water Heating (DH)": 152130,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 84.58,
    "out:Etvv": 17,
    "out:Ef": 14.33,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000200120000001",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 58.3,
    "out:DHW (DH)": 34.8,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 13.09,
    "out:Total Energy Use Pre PV": 113.5,
    "out:Total Energy Use Post PV": 106.09,
    "out:Primary Energy": 78.37,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 62.82,
    "out:CO2 Operational/m2": -40.51,
    "out:Total CO2/m2": 22.3,
    "out:Total CO2 (tons)": 114.71,
    "out:Klimatpaverkan": -214.53,
    "out:Initial Cost/MSEK": 15.27375,
    "out:Running cost/(Apt SEK y)": 30322,
    "out:Running Cost over RSP/MSEK": 9.985,
    "out:LCP/MSEK": -5.989,
    "out:ROI% old": 8.68,
    "out:Payback discounted": 14,
    "out:Atemp": 5143,
    "out:Umean": 0.26,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FTX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 106,
    "out:Energy use kWh/m2": 106,
    "out:Energy savings %": 77.4,
    "out:Op energy cost/MSEK": 74,
    "out:El price/MSEK": 43,
    "out:Heating price/MSEK": 27,
    "out:DH+ DHW/kWh/m2": 93,
    "out:El bought/kWh/m2": 923.7,
    "out:Return %": 8,
    "out:Return/kSEK/y": 1190,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.33,
    "out:PV (kWp installed)": 122.531965,
    "out:PV (m2 panels)": 643,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 114968.11,
    "out:PV (kWh sold)": 76862.14,
    "out:PV (% sold (El))": 66.86,
    "out:PV (kWh self-consumed)": 38105.97,
    "out:PV (ratio sold/self-consumed)": 2.02,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 1773600,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.05,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 406297,
    "out:EL kWh savings": 14453,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 492300,
    "out:DH kr savings": 369730,
    "out:El kr savings": 24571,
    "out:El kr sold": 97999,
    "out:El kr saved": 64780,
    "out:El kr return": 162779,
    "out:% solar/total": 47,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 299812,
    "out:% savings (space heating)": 54.48,
    "out:Total_Water Heating (DH)": 178976,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 88.32,
    "out:Etvv": 20,
    "out:Ef": 13.09,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000200120000101",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 54.55,
    "out:DHW (DH)": 34.8,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 13.09,
    "out:Total Energy Use Pre PV": 109.5,
    "out:Total Energy Use Post PV": 102.09,
    "out:Primary Energy": 75.75,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 62.82,
    "out:CO2 Operational/m2": -41.95,
    "out:Total CO2/m2": 20.86,
    "out:Total CO2 (tons)": 107.3,
    "out:Klimatpaverkan": -221.94,
    "out:Initial Cost/MSEK": 15.4025,
    "out:Running cost/(Apt SEK y)": 29034,
    "out:Running Cost over RSP/MSEK": 9.562,
    "out:LCP/MSEK": -5.695,
    "out:ROI% old": 9,
    "out:Payback discounted": 13,
    "out:Atemp": 5143,
    "out:Umean": 0.26,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FTX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 102,
    "out:Energy use kWh/m2": 102,
    "out:Energy savings %": 84.3,
    "out:Op energy cost/MSEK": 71,
    "out:El price/MSEK": 41,
    "out:Heating price/MSEK": 25,
    "out:DH+ DHW/kWh/m2": 89,
    "out:El bought/kWh/m2": 923.7,
    "out:Return %": 8,
    "out:Return/kSEK/y": 1244,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.33,
    "out:PV (kWp installed)": 122.531965,
    "out:PV (m2 panels)": 643,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 114968.11,
    "out:PV (kWh sold)": 76862.14,
    "out:PV (% sold (El))": 66.86,
    "out:PV (kWh self-consumed)": 38105.97,
    "out:PV (ratio sold/self-consumed)": 2.02,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 1773600,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.05,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 426869,
    "out:EL kWh savings": 14453,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 128574.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 511021,
    "out:DH kr savings": 388451,
    "out:El kr savings": 24571,
    "out:El kr sold": 97999,
    "out:El kr saved": 64780,
    "out:El kr return": 162779,
    "out:% solar/total": 47,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 280567,
    "out:% savings (space heating)": 57.4,
    "out:Total_Water Heating (DH)": 178976,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 84.58,
    "out:Etvv": 20,
    "out:Ef": 13.09,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000200120001001",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 58.3,
    "out:DHW (DH)": 29.58,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 13.09,
    "out:Total Energy Use Pre PV": 108.5,
    "out:Total Energy Use Post PV": 101.09,
    "out:Primary Energy": 76.27,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 63.22,
    "out:CO2 Operational/m2": -42.31,
    "out:Total CO2/m2": 20.91,
    "out:Total CO2 (tons)": 107.52,
    "out:Klimatpaverkan": -221.72,
    "out:Initial Cost/MSEK": 15.52375,
    "out:Running cost/(Apt SEK y)": 28713,
    "out:Running Cost over RSP/MSEK": 9.456,
    "out:LCP/MSEK": -5.71,
    "out:ROI% old": 9.03,
    "out:Payback discounted": 13,
    "out:Atemp": 5143,
    "out:Umean": 0.26,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FTX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 101,
    "out:Energy use kWh/m2": 101,
    "out:Energy savings %": 86.1,
    "out:Op energy cost/MSEK": 70,
    "out:El price/MSEK": 41,
    "out:Heating price/MSEK": 25,
    "out:DH+ DHW/kWh/m2": 88,
    "out:El bought/kWh/m2": 923.7,
    "out:Return %": 8,
    "out:Return/kSEK/y": 1258,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.33,
    "out:PV (kWp installed)": 122.531965,
    "out:PV (m2 panels)": 643,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 114968.11,
    "out:PV (kWh sold)": 76862.14,
    "out:PV (% sold (El))": 66.86,
    "out:PV (kWh self-consumed)": 38105.97,
    "out:PV (ratio sold/self-consumed)": 2.02,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 1773600,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.05,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 432012,
    "out:EL kWh savings": 14453,
    "out:Sewage heat recovery cost": 250448,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 515701,
    "out:DH kr savings": 393131,
    "out:El kr savings": 24571,
    "out:El kr sold": 97999,
    "out:El kr saved": 64780,
    "out:El kr return": 162779,
    "out:% solar/total": 47,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 299812,
    "out:% savings (space heating)": 54.48,
    "out:Total_Water Heating (DH)": 152130,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 88.32,
    "out:Etvv": 17,
    "out:Ef": 13.09,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000200120001101",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 54.55,
    "out:DHW (DH)": 29.58,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 13.09,
    "out:Total Energy Use Pre PV": 104.5,
    "out:Total Energy Use Post PV": 97.09,
    "out:Primary Energy": 73.65,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 63.22,
    "out:CO2 Operational/m2": -43.75,
    "out:Total CO2/m2": 19.47,
    "out:Total CO2 (tons)": 100.11,
    "out:Klimatpaverkan": -229.13,
    "out:Initial Cost/MSEK": 15.6525,
    "out:Running cost/(Apt SEK y)": 27425,
    "out:Running Cost over RSP/MSEK": 9.033,
    "out:LCP/MSEK": -5.416,
    "out:ROI% old": 9.34,
    "out:Payback discounted": 13,
    "out:Atemp": 5143,
    "out:Umean": 0.26,
    "out:Roof surface/m2": 1285.749896,
    "out:Facade surface/m2": 1827.836787,
    "out:Window surface/m2": 442.856747,
    "out:Ventilation system": "FTX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 97,
    "out:Energy use kWh/m2": 97,
    "out:Energy savings %": 93.8,
    "out:Op energy cost/MSEK": 67,
    "out:El price/MSEK": 39,
    "out:Heating price/MSEK": 24,
    "out:DH+ DHW/kWh/m2": 84,
    "out:El bought/kWh/m2": 923.7,
    "out:Return %": 8,
    "out:Return/kSEK/y": 1312,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 134063.5473,
    "out:EAHP (pipework)": 133653.701664,
    "out:EAHP (natural ventilation grills)": 199946.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.33,
    "out:PV (kWp installed)": 122.531965,
    "out:PV (m2 panels)": 643,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 114968.11,
    "out:PV (kWh sold)": 76862.14,
    "out:PV (% sold (El))": 66.86,
    "out:PV (kWh self-consumed)": 38105.97,
    "out:PV (ratio sold/self-consumed)": 2.02,
    "out:FTX (AHU)": 458662.46875,
    "out:FTX (Ductwork)": 1773600,
    "out:FTX (Diffusers)": 260507.8125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 226907.519735,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 42,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 518700,
    "out:DH (pipework)": 577500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.05,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 1085526.493445,
    "out:FTX (Facade)": 4995800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 452584,
    "out:EL kWh savings": 14453,
    "out:Sewage heat recovery cost": 250448,
    "out:Balancing hydronic system cost": 128574.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 534421,
    "out:DH kr savings": 411851,
    "out:El kr savings": 24571,
    "out:El kr sold": 97999,
    "out:El kr saved": 64780,
    "out:El kr return": 162779,
    "out:% solar/total": 47,
    "out:Total_El consumption (without PV)": 81774,
    "out:Total_Space Heating (DH)": 280567,
    "out:% savings (space heating)": 57.4,
    "out:Total_Water Heating (DH)": 152130,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 11,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 1349900,
    "out:Eupp": 84.58,
    "out:Etvv": 17,
    "out:Ef": 13.09,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  }
]